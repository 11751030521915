class BookingDetailPriceView extends MUIView
{
    private calculatedPriceLabel:MUILabel = null;
    private priceTextField:MUITextField = null;
    private chargeAmmountTextField:MUITextField = null;
    private minSpendTextField:MUITextField = null;
    private depositLabel:MUILabel = null;

    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;    
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;    

    awakeFromHTML(){
        this.calculatedPriceLabel = MUIOutlet(this, 'calculated_price_lbl','MUILabel');

        this.priceTextField = MUIOutlet(this, 'price_tf','MUITextField');
        this.priceTextField.formatter = this.nf;
        this.priceTextField.setOnChangeText(this, function(textfield, value){
            this._item.price = this.nf.numberFromString(value) | 0;
        });
        
        this.chargeAmmountTextField = MUIOutlet(this, "charge-ammount-tf", "MUITextField");
        this.chargeAmmountTextField.formatter = this.nf;
        this.chargeAmmountTextField.setOnChangeText(this, function(control, value){
            this._item.ammountToPay = this.nf.numberFromString(value) | 0;
        });

        this.minSpendTextField = MUIOutlet(this, 'min_spend_tf','MUITextField');
        this.minSpendTextField.formatter = this.nf;
        this.minSpendTextField.setOnChangeText(this, function(textfield, value){
            this._item.minimumFee = this.nf.numberFromString(value) | 0;
        });

        this.depositLabel = MUIOutlet(this, 'paid_tf','MUILabel');
    }
    
    private _item:Booking = null;
    set booking(booking:Booking){
        if (this._item != null) {
            this._item.removeObserver(this, "calculatedPrice");        
            this._item = null;
        }
        this._item = booking;

        this.updateUI();  
        if (this._item != null) {
            this._item.addObserver(this, "calculatedPrice", null);
            this._item.addObserver(this, "payImport", null);
        }        
        
    }

    private updateUI(){                        
        this.calculatedPriceLabel.text = this._item.calculatedPrice ? this.cf.stringFromNumber(this._item.calculatedPrice) : null;
        this.priceTextField.text = this._item.price ? this.nf.stringFromNumber(this._item.price) : null;
        // this.minSpendTextField.text = this.nf.stringFromNumber(this._item.minimumFee);
        // this.depositLabel.text = this.nf.stringFromNumber(this._item.payImport);
    }

    public observeValueForKeyPath(keypath, type, object){
        if (type != "did") return;
        switch (keypath) {
            case "calculatedPrice":
                this.calculatedPriceLabel.text = this.nf.stringFromNumber(this._item.calculatedPrice);
                break;

            case "payImport":
                // this.depositLabel.text = this.nf.stringFromNumber(this._item.payImport);
                break;
        }
    }
}
