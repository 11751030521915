

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class RateName_ManagedObject

class RateName_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: language
    set language(value:number) {
        this.setValueForKey(value, 'language');
    }
    get language():number {
        return this.valueForKey('language');
    }
    set languagePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'language');
    }
    get languagePrimitiveValue():number {
        return this.primitiveValueForKey('language');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }
    // Relationship: rate
    set rate(value:Rate) {
        this.setValueForKey(value, 'rate');
    }
    get rate():Rate {
        return this.valueForKey('rate') as Rate;
    }
}
