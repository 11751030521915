

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Place_ManagedObject

class Place_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: alias
    set alias(value:string) {
        this.setValueForKey(value, 'alias');
    }
    get alias():string {
        return this.valueForKey('alias');
    }
    set aliasPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'alias');
    }
    get aliasPrimitiveValue():string {
        return this.primitiveValueForKey('alias');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Relationship: children
    protected _children:MIOManagedObjectSet = null;
    get children():MIOManagedObjectSet {
        return this.valueForKey('children');
    }
    addChildrenObject(value:Place) {
        this._addObjectForKey(value, 'children');
    }
    removeChildrenObject(value:Place) {
        this._removeObjectForKey(value, 'children');
    }

    // Relationship: integrators
    protected _integrators:MIOManagedObjectSet = null;
    get integrators():MIOManagedObjectSet {
        return this.valueForKey('integrators');
    }
    addIntegratorsObject(value:IntegratorConfigTags) {
        this._addObjectForKey(value, 'integrators');
    }
    removeIntegratorsObject(value:IntegratorConfigTags) {
        this._removeObjectForKey(value, 'integrators');
    }
    // Relationship: parent
    set parent(value:Place) {
        this.setValueForKey(value, 'parent');
    }
    get parent():Place {
        return this.valueForKey('parent') as Place;
    }
}
