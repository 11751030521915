/**
 * Created by miguel on 2/2/17.
 */




class ProductFormatRateViewController extends MUIViewController
{

    private _fetchedResultsController:MIOFetchedResultsController = null;
    private _productFormat:ProductFormat = null;
    private searchTextField:MUITextField = null;
    private searchString:string = null;

    private backButton:MUIButton = null;
    private addButton:MUIButton = null;
    private tableView:MUITableView = null;


    private pageController:MUIPageController = null;

    private pfrvc:ProductFormatRateViewController = null;  //ProductFormatRate ViewController

    get preferredContentSize()
    {
        return new MIOSize(500, 400);
    }

    viewDidLoad()
    {
        super.viewDidLoad();

        this.searchTextField = MUIOutlet(this, 'search-bar','MUITextField');

        this.searchTextField.setOnChangeText(this, function (textfield, value) {
            
            if (this.delayTimer != null) return;

            this.delayTimer = MIOTimer.scheduledTimerWithTimeInterval(500, false, this, function(timer){

                this.searchString = this.searchTextField.text.length > 0 ? this.searchTextField.text : null;
                this.fetchedResultsController = null;
                this.tableView.reloadData();
                this.delayTimer = null;                
            });
        });
            
        this.tableView = MUIOutlet(this, 'table-view', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        
    }

    set productFormat(value)
    {
        this._productFormat = value;
        this.updateUI();
    }

    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private updateUI()
    {
        if(!(this.viewIsLoaded && this._productFormat != null)) return;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableview)
    {
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section)
    {
        var sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    canSelectCellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath)
    {
        return false;
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath) 
    {
        var cell:ProductFormatRateCell = tableview.dequeueReusableCellWithIdentifier('ProductFormatRateCell');
    
        var rate:Rate = this.fetchedResultsController.objectAtIndexPath(indexPath) as Rate;
    
        cell.configureCell(this._productFormat, rate);
        //cell.item = item;
    
        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
    
        return cell;
    }
        
    set fetchedResultsController(value)
    {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController()
    {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        var ad = MUIWebApplication.sharedInstance().delegate;
    
        var fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('Rate');
    
        fetchRequest.sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true),
                                            MIOSortDescriptor.sortDescriptorWithKey('name', true)];
    
        if (this.searchString != null)
            fetchRequest.predicate = MIOPredicate.predicateWithFormat('name CONTAINS "' + this.searchString + '"');
    
        var fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller)
    {
        this.tableView.reloadData();
    }
}