



class ReportResultRangeCell extends MUITableViewCell
{
    label1 = null;
    label2 = null;
    label3 = null;
    label4 = null; 
    label5 = null;
    name = null;

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
   
    awakeFromHTML()
    {
        this.label1    = MUIOutlet(this, 'rrv_c5_label1_lbl', 'MUILabel');
        this.label2    = MUIOutlet(this, 'rrv_c5_label2_lbl', 'MUILabel');
        this.label3    = MUIOutlet(this, 'rrv_c5_label3_lbl', 'MUILabel');
        this.label4    = MUIOutlet(this, 'rrv_c5_label4_lbl', 'MUILabel');
        this.label5    = MUIOutlet(this, 'rrv_c5_label5_lbl', 'MUILabel');
        this.name    = MUIOutlet(this, 'rrv_c5_name_lbl', 'MUILabel');
    }

    set item(i)
    {
        this.label1.text = this.cf.stringFromNumber(i.line_totalpricebase);
        this.label2.text = this.cf.stringFromNumber(i.line_totaltaxes);
        this.label3.text = this.cf.stringFromNumber(i.line_totalprice);
        this.label4.text = this.cf.stringFromNumber(i.line_totalinvited);
        this.label5.text = this.cf.stringFromNumber(i.line_totaldeleted);
        this.name.text   = i.interval;
    }
}
