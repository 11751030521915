

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class EmployeePayroll_ManagedObject

class EmployeePayroll_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: beginDate
    set beginDate(value:Date) {
        this.setValueForKey(value, 'beginDate');
    }
    get beginDate():Date {
        return this.valueForKey('beginDate');
    }
    set beginDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'beginDate');
    }
    get beginDatePrimitiveValue():Date {
        return this.primitiveValueForKey('beginDate');
    }

    // Property: endDate
    set endDate(value:Date) {
        this.setValueForKey(value, 'endDate');
    }
    get endDate():Date {
        return this.valueForKey('endDate');
    }
    set endDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'endDate');
    }
    get endDatePrimitiveValue():Date {
        return this.primitiveValueForKey('endDate');
    }

    // Property: grossAmount
    set grossAmount(value:number) {
        this.setValueForKey(value, 'grossAmount');
    }
    get grossAmount():number {
        return this.valueForKey('grossAmount');
    }
    set grossAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'grossAmount');
    }
    get grossAmountPrimitiveValue():number {
        return this.primitiveValueForKey('grossAmount');
    }

    // Property: period
    set period(value:number) {
        this.setValueForKey(value, 'period');
    }
    get period():number {
        return this.valueForKey('period');
    }
    set periodPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'period');
    }
    get periodPrimitiveValue():number {
        return this.primitiveValueForKey('period');
    }
    // Relationship: concepts
    set concepts(value:EmployeePayrollConcept) {
        this.setValueForKey(value, 'concepts');
    }
    get concepts():EmployeePayrollConcept {
        return this.valueForKey('concepts') as EmployeePayrollConcept;
    }
    // Relationship: worker
    set worker(value:Employee) {
        this.setValueForKey(value, 'worker');
    }
    get worker():Employee {
        return this.valueForKey('worker') as Employee;
    }
}
