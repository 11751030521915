
class PartnerListCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    private emailLabel:MUILabel = null;    

    awakeFromHTML() {
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        //this.emailLabel = MUIOutlet(this, "email-lbl", "MUILabel");
        
        this.separatorStyle = UITableViewCellSeparatorStyle.None;
    }

    set item(i:Partner){
        this.nameLabel.text = i.name;
        //this.emailLabel.text = i.email;
    }
   
}