class SalesInvoiceListViewController extends InvoiceListViewController
{
    static newInstance():SalesInvoiceListViewController {
        let vc = new SalesInvoiceListViewController("invoice-list-view");        
        vc.initWithResource("layout/invoices/InvoiceListView.html");        
        return vc;
    }
    
    get entityName():string { return "SalesInvoice"; }

    viewDidLoad() {
        super.viewDidLoad();
        
        this.titleLabel.text = MIOLocalizeString("SALES INVOICES", "SALES INVOICES");
    }

    protected showSelectedInvoice()
    {
        let vc = SalesInvoiceDetailViewController.newInstance();
        vc.item = this.selectedItem;
        this.splitViewController.showDetailViewController(vc);
    }

    protected addInvoice(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("OPTIONS", "OPTIONS"), MIOLocalizeString("SELECT AN OPTION", "SELECT AN OPTION"), MUIAlertViewStyle.Default);

        avc.addComboBoxWithConfigurationHandler( this, function(this:SalesInvoiceListViewController, control:MUIComboBox) {
            let predicate = MIOPredicate.predicateWithFormat("entityNameType = 'SalesInvoice' && entityType >= 0");
            let sds = [MIOSortDescriptor.sortDescriptorWithKey("orderIndex", true), MIOSortDescriptor.sortDescriptorWithKey("prefix", false)];
            DBHelper.sharedInstance().addObserverForEntity(avc, "NumberSerialSequence", predicate, sds, null, function (objects:NumberSerialSequence[]) {
                control.removeAllItems();                
                for (let i = 0; i < objects.length; i++) {
                    let obj = objects[i];                    
                    control.addItem(obj.name, i);
                }
            });

        });

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Default, this, function(this:SalesInvoiceListViewController){            
            let seqIndex = avc.comboBoxes[0].getSelectedItem();
            let seq = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, seqIndex, "NumberSerialSequence") as NumberSerialSequence;

            this.showLegalEntitySelectionViewController ( seq );
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null) );

        avc.addCompletionHandler(this, function () {
            // Release the observers            
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, 'NumberSerialSequence');
        });

        this.presentViewController(avc, true);
    }

    protected createNewInvoice(entity:LegalEntity, numberSequence: NumberSerialSequence){
        DBHelper.nextDocumentNumber(numberSequence.entityNameType, numberSequence.entityType, (documentID:string, documentPrefix:string, documentNumber:number, error:string) => {
            if (error != null) {
                AppHelper.showErrorMessage(this, MIOLocalizeString("ERROR", "ERROR"), error);
                return;
            }

            this._createNewInvoice(entity, documentID, numberSequence);
        });
    }

    protected _createNewInvoice(entity:LegalEntity, documentNumber:string,numberSequence:NumberSerialSequence){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let inv = MIOEntityDescription.insertNewObjectForEntityForName( this.entityName, ad.managedObjectContext ) as Invoice;

        inv.identifier = MIOUUID.UUID().UUIDString;
        inv.documentNumber = documentNumber;
        inv.legalEntity = entity;
        inv.numberSerial = numberSequence;
        //inv.legalTaxFactor = entity.legalTax != null ? entity.legalTax.value : 0;
        inv.date = new Date();
        
        this.selectedItem = inv;

        DBHelper.saveMainContext();

        // this.selectedItem = inv;
        // let ip = this.fetchedResultsController.indexPathForObject( inv );
        // let cell = this.tableView.cellAtIndexPath( ip )
        // if (cell != null) cell.selected = true; 

        this.showSelectedInvoice();
    }
}