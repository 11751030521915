

class TimeRangePresetListCell extends UITableViewCell
{
    private nameLabel:MUILabel = null

    awakeFromHTML(){
        // this.groupTextField = MUIOutlet(this, "group-tf", "MUITextField");
        // this.groupTextField.setOnChangeText(this, function(control, value){
        //     this.item.group = value;
        // });

        this.nameLabel = MUIOutlet(this, "preset-lbl", "MUILabel");

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private _item:TimeRangePreset = null;
    set item(item:TimeRangePreset){
        this._item = item;
        this.nameLabel.text = item.name;
    }
}