
/// <reference path="RuleCell.ts" />


class OcuppationRuleCell extends RuleCell
{
    private minPax:MUITextField = null;
    private maxPax:MUITextField = null;
    private limitTextField:MUITextField = null;
    private channelComboBox:MUIComboBox = null;

    awakeFromHTML(){

        this.minPax = MUIOutlet(this, "min-pax-tf", "MUITextField");
        this.minPax.setOnChangeText(this, function(control, value){
            let v = value.length > 0 ? value : null;
            this._item.minPax = v;
        });

        this.maxPax = MUIOutlet(this, "max-pax-tf", "MUITextField");
        this.maxPax.setOnChangeText(this, function(control, value){
            let v = value.length > 0 ? value : null;
            this._item.maxPax = v;
        });

        this.limitTextField = MUIOutlet(this, "count-limit-tf", "MUITextField");        
        this.limitTextField.setOnChangeText(this, function(control, value){
            let v = value.length > 0 ? value : null;
            this._item.limit = v;
        });

        this.channelComboBox = MUIOutlet(this, "channel-cb", "MUIComboBox");
        this.setupChannelComboBox(this.channelComboBox);
        this.channelComboBox.setOnChangeAction(this, function(control, value){
            let channel = DBHelper.queryObjectFromMainContext("BookingChannel", MIOPredicate.predicateWithFormat("identifier == " + value));
            this._item.channelID = channel.identifier;
            this._item.channelName = channel.name;
        });
    
        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    private _item:BookingZoneOcupationRule = null;
    set item (item:BookingZoneOcupationRule){
        this._item = item;
        this.minPax.text = item.minPax;
        this.maxPax.text = item.maxPax;
        this.limitTextField.text = item.limit; 
        
        let sds = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        let predicate = MIOPredicate.predicateWithFormat("deletedAt == null");
        DBHelper.queryObjectsWithCompletion("BookingChannel", sds, predicate, [], this, function(objects){
            this.fillBookingChannelComboBox(this.channelComboBox, objects, item.channelID);
        });  
    }

}