class ProductFormatRateCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;
    private priceTextField:MUITextField = null;
    
    private _separator = null;

    private _rate:Rate = null;
    private _productFormatRate:ProductFormatRate = null;
    private _productFormat:ProductFormat = null;

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;

    awakeFromHTML()
    {
        this.nameLabel = MUIOutlet(this,'pfrv_tv_cell_lbl', 'MUILabel');
        this.priceTextField = MUIOutlet(this, 'pfrv_tv_cell_text','MUITextField');
        this.priceTextField.formatter = this.cf;
        this.priceTextField.setOnChangeText(this, function(textfield, value)
        {
            //get new textfield text (price change)
            let price = this.cf.numberFromString(value);
            if (price == null && this._productFormatRate != null){
                //remove productFormatRate if price null
                this._productFormat.removeProductFormatRatesObject(this._productFormatRate); 
                DBHelper.deleteObjectFromMainContext(this._productFormatRate, true);
            }
            else if(this._productFormatRate != null)
                this._productFormatRate.price = price; //if a productFormatRate exists then set it to new value
            else
            {
                let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
                this._productFormatRate = MIOEntityDescription.insertNewObjectForEntityForName("ProductFormatRate", moc) as ProductFormatRate;
                this._productFormatRate.identifier = MIOUUID.UUID().UUIDString;
                this._productFormatRate.rate = this._rate;
                this._productFormatRate.productFormat = this._productFormat;
                this._productFormatRate.price = price;
                this._productFormat.addProductFormatRatesObject(this._productFormatRate);
            }     
        });
    }

    //configurecell called in parent controller before returning cell
    configureCell(productFormat:ProductFormat, rate:Rate)
    {
        this._productFormat = productFormat;
        this._rate = rate;
        //this.priceTextField = null;

        //this get ProductFormatRate from ProductFormat and uses the ID from Rate, returns one productformatrate in an array
        var formatRates = this._productFormat.productFormatRates.filterWithPredicate(MIOPredicate.predicateWithFormat('rate.identifier = ' + rate.identifier));
        //var formatRates = this._productFormat.productFormatRates.allObjects;

        //should not have more than one productFormatRate per rate
        if(formatRates.length > 1)
            throw new Error('No es posible que tenga dos veces el mismo rate');
        
        //if length is 1, set _productFormateRate else null (if no object then no format)
        this._productFormatRate = formatRates.length == 1 ? formatRates[0]: null;    

        this.nameLabel.text = rate.name;
        this.priceTextField.text = this._productFormatRate != null ? this.cf.stringFromNumber(this._productFormatRate.price): null;
    }

}
