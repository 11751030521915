//
// Generated class RuleResult
//

/// <reference path="RuleResult_ManagedObject.ts" />

class RuleResult extends RuleResult_ManagedObject
{

}
