

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class SaleSource_ManagedObject

class SaleSource_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Relationship: tickets
    protected _tickets:MIOManagedObjectSet = null;
    get tickets():MIOManagedObjectSet {
        return this.valueForKey('tickets');
    }
    addTicketsObject(value:Ticket) {
        this._addObjectForKey(value, 'tickets');
    }
    removeTicketsObject(value:Ticket) {
        this._removeObjectForKey(value, 'tickets');
    }
}
