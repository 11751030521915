interface WhatsappParamCellDelegate
{
    paramDidChangeAtIndex(index:number, value:string);
}


class WhatsappParamCell extends UITableViewCell
{
    delegate:WhatsappParamCellDelegate = null;

    private orderIndexLabel:MUILabel = null;
    private paramTypeComboBox:MUIComboBox = null;

    awakeFromHTML(){
        this.orderIndexLabel = MUIOutlet(this, "order-index-lbl", "MUILabel");
        this.paramTypeComboBox = MUIOutlet(this, "param-type-cb", "MUIComboBox");
        this.paramTypeComboBox.setOnChangeAction(this, function(this:WhatsappParamCell, control:MUIComboBox, value:string){
            this.delegate?.paramDidChangeAtIndex(this._order_index, value);
        });

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }
    
    private _order_index:number;
    setItem(orderIndex:number, value:any[]){    
        this._order_index = orderIndex;

        this.orderIndexLabel.text = orderIndex.toString();
        this.paramTypeComboBox.selectItem( value );
    }
}