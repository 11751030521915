
class UserAccessViewController extends BaseTableViewController
{
    static newInstance(): UserAccessViewController {
        let vc = new UserAccessViewController('users-view');
        vc.initWithResource("layout/users/UsersView.html");
        return vc;
    }

    private saveButton:MUIButton = null;
    private addButton:MUIButton = null;
    private actionButton:MUIButton = null;        

    private segmentedControl:MUISegmentedControl = null;
    private permissionsTableView:UITableView = null;

    private permissionsDataSource:ManagerPermissionsDataSource = null;
    private reportPermissionsDataSource:ReportsPermissionsDataSource = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        });

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function(){
            this.inviteUserAction();
        });

        this.actionButton = MUIOutlet(this, "action-btn", "MUIButton");
        this.actionButton.setAction(this, function(){
            this.showActionsAlert();
        });

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;      
        
        this.segmentedControl = MUIOutlet(this, "segmented-control", "MUISegmentedControl");
        this.segmentedControl.setAction(this, this.changePermissionsDataSource);

        this.permissionsTableView = MUIOutlet(this, "permissions-table-view", "UITableView");
        this.permissionsDataSource = new ManagerPermissionsDataSource();
        this.permissionsDataSource.initWithTableView(this.permissionsTableView);
        this.permissionsTableView.dataSource = this.permissionsDataSource;
        this.permissionsTableView.delegate = this.permissionsDataSource;   
        
        this.reportPermissionsDataSource = new ReportsPermissionsDataSource();
        this.reportPermissionsDataSource.initWithTableView(this.permissionsTableView);
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as User;

        let loginEmail = MIOUserDefaults.standardUserDefaults().valueForKey("LastLoginEmail");

        let cellIdentifier = "UserCell";
        if (item.isAdmin == true) {
            cellIdentifier = "AdminUserCell";
        }
        // else if (item.permisions.isInvited == true) {
        //     cellIdentifier = "InvitedUserCell";
        // }
        else if (item.email == loginEmail) {
            cellIdentifier = "CurrentUserCell";
        }

        let cell = tableview.dequeueReusableCellWithIdentifier(cellIdentifier) as UserAccessCell;
        cell.item = item;        
        cell.selected = (this.selectedItem?.identifier == item.identifier);

        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView,indexPath:MIOIndexPath){
        this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath) as User;
        this.permissionsDataSource.user = this.selectedItem;
        this.reportPermissionsDataSource.user = this.selectedItem;
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
        return UITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as User;
        let loginEmail = MIOUserDefaults.standardUserDefaults().valueForKey("LastLoginEmail");

        if (editingStyle == UITableViewCellEditingStyle.Delete) {

            if (item.isAdmin == true || item.email == loginEmail) {
                this.exemptUser(item);
            } else {
                this.removeUser(item);
            }
        }
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;        
        
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        
        let predicateFormat = null
        if (this.searchString != null) {
            predicateFormat = "name CONTAINS '" + this.searchString + "' OR email CONTAINS '" + this.searchString + "'";
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("User", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["reportAccess"];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    private changePermissionsDataSource(control:MUISegmentedControl, index){
        switch (index){
            case 0:
                this.permissionsTableView.dataSource = this.permissionsDataSource;
                this.permissionsTableView.delegate = this.permissionsDataSource;   
                this.permissionsTableView.reloadData();
                break;

            case 1:                    
                this.permissionsTableView.dataSource = this.reportPermissionsDataSource;
                this.permissionsTableView.delegate = this.reportPermissionsDataSource;   
                this.permissionsTableView.reloadData();
                break;            
        }
    }

    private inviteUserAction(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("USERS","USERS"), MIOLocalizeString("SEND INVITATION", "SEND INVITATION"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.placeholderText = "Email";
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle("OK", MUIAlertActionStyle.Default, this, function(this:UserAccessViewController){
            let email = avc.textFields[0].text.trim();
            this.checkUserExists(email);
        }));
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private checkUserExists(email:string){        
        if (email.length == 0) {
            AppHelper.showAlert(this, "WARNING", "THERE'S NO VALID EMAIL TO SEND");
            return;
        }        

        DBHelper.queryObjectWithCompletion( "User", null, MIOPredicate.predicateWithFormat( "email = %@", email ), [], this, function (this:UserAccessViewController, object:any){
            if (object != null) {
                AppHelper.showAlert(this, "WARNING", "THE USER IS ALREADY LINKED WITH THIS BUSINESS");
                return;
            }

            this.sendUserInvitation(email);
        });
    }

    private sendUserInvitation(email:string){        
        let businessID = (MUIWebApplication.sharedInstance().delegate as AppDelegate).selectedIdentifier;
        DualLinkAuthKit.sharedInstance().linkBusinessUser(businessID, null, email, (code:number, data:any) => {
            if (code == 200 && data != null) {
                // this.invalidateFetch();
                this.addUser(data);
            }            
            else if (code == 400) {
                AppHelper.showInfoMessage(this, "WARNING", "User email not found in Dual Link DB. Please register before send the invitation.");
            }
            else {
                AppHelper.showErrorMessage(this, "ERROR", "THE SERVER RETURN AN UNEXPECTED ERROR. PLEASE TRY AGAIN LATER");
            }
        });
    }

    private addUser(userInfo:any) {

        let name = userInfo["userName"];
        let email = userInfo["userEmail"];
        let userID = userInfo["userID"];

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let user = MIOEntityDescription.insertNewObjectForEntityForName("User", ad.managedObjectContext) as User;
        user.identifier = userID;
        user.email = email;
        user.name = name;
        user.permisions = "0";

        DBHelper.saveMainContext();

        // this.invalidateFetch();
    }

    private exemptUser(user:User) {
        let avc = new MUIAlertViewController();

        if (user.isAdmin == true) {
            avc.initWithTitle(MIOLocalizeString("WARNING","WARNING"), MIOLocalizeString("CANNOT REMOVE ADMIN USER", "CANNOT REMOVE ADMIN USER"), MUIAlertViewStyle.Default);
        } else if (user.email == MIOUserDefaults.standardUserDefaults().valueForKey("LastLoginEmail")) {
            avc.initWithTitle(MIOLocalizeString("WARNING","WARNING"), MIOLocalizeString("CANNOT REMOVE CURRENTLY LOGGED IN USER","CANNOT REMOVE CURRENTLY LOGGED IN USER"), MUIAlertViewStyle.Default);
        } else {
            avc.initWithTitle(MIOLocalizeString("WARNING","WARNING"), MIOLocalizeString("CANNOT REMOVE SPECIAL USER","CANNOT REMOVE SPECIAL USER"), MUIAlertViewStyle.Default);
        }
        
        avc.addAction(MUIAlertAction.alertActionWithTitle("OK", MUIAlertActionStyle.Default, null, null));
        this.presentViewController(avc, true);
    }

    private removeUser(user:User){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("WARNING","WARNING"), "ARE YOU SURE TO REMOVE THE USER?", MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle("OK", MUIAlertActionStyle.Default, this, function(){
            this.removeManagerUser(user);
        }));
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private removeManagerUser(user:User){

        let businessID = (MUIWebApplication.sharedInstance().delegate as AppDelegate).selectedIdentifier;        
        DualLinkAuthKit.sharedInstance().unlinkBusinessUser(businessID, user.identifier, (code:number, data:any) => {
            if (code == 200) {
                DBHelper.deleteObjectFromMainContext(user, true);
                // this.invalidateFetch();
            }
            else {
                AppHelper.showErrorMessage(this, "ERROR", "THE SERVER RETURN AN UNEXPECTED ERROR. PLEASE TRY AGAIN LATER");
            }
        });
    }

    private showActionsAlert(){
        if (this.selectedItem == null) return;

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("OPTIONS", "OPTIONS"), MIOLocalizeString("CHOOSE AN ACTION", "CHOOSE AN ACTION"), MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("SET ALL ACCESS", "SET ALL ACCESS"), MUIAlertActionStyle.Default, this, function(){
            this.selectedItem.setAllPermissions();
            this.permissionsTableView.reloadData();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("REMOVE ALL ACCESS", "REMOVE ALL ACCESS"), MUIAlertActionStyle.Default, this, function(){
            this.selectedItem.unsetAllPermissions();
            this.permissionsTableView.reloadData();
        }));
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);   
    }
}