
class ProductPresetsGroupDetailViewController extends BaseTableViewController
{

    private nameTextField:MUITextField = null;
    private permissionsSwitch:MUISwitchButton = null;

    
    viewDidLoad(){
        super.viewDidLoad();

        this.nameTextField = MUIOutlet(this, 'name-tf', 'MUITextField');
        this.nameTextField.setOnChangeText(this, function(control, value:string){
            //this._timeRangePreset.name = value;
        })

        this.permissionsSwitch = MUIOutlet(this, "permissions-switch-btn", "MUISwitchButton");
        this.permissionsSwitch.setOnChangeValue(this, function(control, value:boolean){
            //this.productModifierCategory.multipleSelection = value;
        });
        
        this.tableView = MUIOutlet(this,"table-view","UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;                
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private _presetGroup:PresetGroup = null;
    set item(item:PresetGroup){        

        this._presetGroup = item;
        this.updateUI();
    }   

    private updateUI(){
        if (this._presetGroup == null) return;
        if (this.viewIsLoaded == false) return;

        //set variables from item here

        this._fetchedResultsController = null;
        this.tableView.reloadData();        
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){

        let cell:ProductPresetsGroupDetailCell = tableview.dequeueReusableCellWithIdentifier("ProductPresetsGroupDetailCell") as ProductPresetsGroupDetailCell;

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as PresetGroup;
        cell.item = item;
        cell.selectionStyle = MUITableViewCellSelectionStyle.None;
        return cell;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        //this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath);   
    }

    // editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {         
    //     return MUITableViewCellEditingStyle.Delete;
    // }

    // commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {        

    //     if (editingStyle == UITableViewCellEditingStyle.Delete && this.productModifierCategory.productModifierCategoryType != ProductModifierCategoryType.Category) {
    //         let item = this.fetchedResultsController.objectAtIndexPath(indexPath);    
    //         this.removeProductModifier(item);
    //     }
    // } 

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
        let fetchRequest:MIOFetchRequest = null;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true), MIOSortDescriptor.sortDescriptorWithKey("orderIndex", true)]; 
        let filterString = "preset.identifier == " + (this._presetGroup.identifier ? this._presetGroup.identifier : "null");
        fetchRequest = DBHelper.listFetchRequestWithEntityName("presetItem", sortDescriptors, filterString);
        fetchRequest.relationshipKeyPathsForPrefetching = ['preset'];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;        
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){        
        this.tableView.reloadData();
    }

    //show notice functions
    private showSettingsPopup() {
        // let avc = new MUIAlertViewController();
        // avc.initWithTitle(MIOLocalizeString('ACTIONS', 'ACTIONS'), MIOLocalizeString('CHOOSE AN ACTION', 'CHOOSE AN ACTION'), MUIAlertViewStyle.Default);
        // avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DELETE MODIFIER CATEGORY', 'DELETE MODIFIER CATEGORY'), MUIAlertActionStyle.Destructive, this, function(){
            
        //     this.removeAllProductModifiers();

        //     if(this._fetchedResultsController.resultObjects.length == 0) {
        //         this.removeProductModifierCategory(this.productModifierCategory);
        //     }

        //     //pop current detail view controller
            
        // }));
                
        // avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL', 'Cancel'), MUIAlertActionStyle.Cancel, null, null));
       
        // this.presentViewController(avc, true);
    }

    private showAddPreset(){
        // let avc = new MUIAlertViewController();
        // avc.initWithTitle(MIOLocalizeString('ADD PRODUCT MODIFIER',"ADD PRODUCT MODIFIER"), MIOLocalizeString('CREATE A PRODUCT MODIFIER',"CREATE A PRODUCT MODIFIER"), MUIAlertViewStyle.Default);    

        // avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
        //     textField.setPlaceholderText(MIOLocalizeString("NAME", "NAME"));
        // });

        // avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK',"OK"), MUIAlertActionStyle.Default, this, function(){                        
        //     let name = avc.textFields[0].text;
        //     this.createProductModifier(name, this.productModifierCategory);

        //     DBHelper.saveMainContext();
        //     this.updateUI();
        // }));

        // avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL',"CANCEL"), MUIAlertActionStyle.Cancel, null, null));
        // this.presentViewController(avc, true);    
    }

}