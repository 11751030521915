

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class License_ManagedObject

class License_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: active
    set active(value:boolean) {
        this.setValueForKey(value, 'active');
    }
    get active():boolean {
        return this.valueForKey('active');
    }
    set activePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'active');
    }
    get activePrimitiveValue():boolean {
        return this.primitiveValueForKey('active');
    }

    // Property: applicationName
    set applicationName(value:string) {
        this.setValueForKey(value, 'applicationName');
    }
    get applicationName():string {
        return this.valueForKey('applicationName');
    }
    set applicationNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'applicationName');
    }
    get applicationNamePrimitiveValue():string {
        return this.primitiveValueForKey('applicationName');
    }

    // Property: deactivationDate
    set deactivationDate(value:Date) {
        this.setValueForKey(value, 'deactivationDate');
    }
    get deactivationDate():Date {
        return this.valueForKey('deactivationDate');
    }
    set deactivationDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'deactivationDate');
    }
    get deactivationDatePrimitiveValue():Date {
        return this.primitiveValueForKey('deactivationDate');
    }

    // Property: discountFormat
    set discountFormat(value:string) {
        this.setValueForKey(value, 'discountFormat');
    }
    get discountFormat():string {
        return this.valueForKey('discountFormat');
    }
    set discountFormatPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'discountFormat');
    }
    get discountFormatPrimitiveValue():string {
        return this.primitiveValueForKey('discountFormat');
    }

    // Property: discountValue
    set discountValue(value:number) {
        this.setValueForKey(value, 'discountValue');
    }
    get discountValue():number {
        return this.valueForKey('discountValue');
    }
    set discountValuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'discountValue');
    }
    get discountValuePrimitiveValue():number {
        return this.primitiveValueForKey('discountValue');
    }

    // Property: distributorFeeFormat
    set distributorFeeFormat(value:string) {
        this.setValueForKey(value, 'distributorFeeFormat');
    }
    get distributorFeeFormat():string {
        return this.valueForKey('distributorFeeFormat');
    }
    set distributorFeeFormatPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'distributorFeeFormat');
    }
    get distributorFeeFormatPrimitiveValue():string {
        return this.primitiveValueForKey('distributorFeeFormat');
    }

    // Property: distributorFeeValue
    set distributorFeeValue(value:number) {
        this.setValueForKey(value, 'distributorFeeValue');
    }
    get distributorFeeValue():number {
        return this.valueForKey('distributorFeeValue');
    }
    set distributorFeeValuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'distributorFeeValue');
    }
    get distributorFeeValuePrimitiveValue():number {
        return this.primitiveValueForKey('distributorFeeValue');
    }

    // Property: expirationDate
    set expirationDate(value:Date) {
        this.setValueForKey(value, 'expirationDate');
    }
    get expirationDate():Date {
        return this.valueForKey('expirationDate');
    }
    set expirationDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'expirationDate');
    }
    get expirationDatePrimitiveValue():Date {
        return this.primitiveValueForKey('expirationDate');
    }

    // Property: nextRenewalDate
    set nextRenewalDate(value:Date) {
        this.setValueForKey(value, 'nextRenewalDate');
    }
    get nextRenewalDate():Date {
        return this.valueForKey('nextRenewalDate');
    }
    set nextRenewalDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'nextRenewalDate');
    }
    get nextRenewalDatePrimitiveValue():Date {
        return this.primitiveValueForKey('nextRenewalDate');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: startDate
    set startDate(value:Date) {
        this.setValueForKey(value, 'startDate');
    }
    get startDate():Date {
        return this.valueForKey('startDate');
    }
    set startDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'startDate');
    }
    get startDatePrimitiveValue():Date {
        return this.primitiveValueForKey('startDate');
    }

    // Property: total
    set total(value:number) {
        this.setValueForKey(value, 'total');
    }
    get total():number {
        return this.valueForKey('total');
    }
    set totalPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'total');
    }
    get totalPrimitiveValue():number {
        return this.primitiveValueForKey('total');
    }
    // Relationship: business
    set business(value:BusinessLicense) {
        this.setValueForKey(value, 'business');
    }
    get business():BusinessLicense {
        return this.valueForKey('business') as BusinessLicense;
    }
    // Relationship: offer
    set offer(value:Offer) {
        this.setValueForKey(value, 'offer');
    }
    get offer():Offer {
        return this.valueForKey('offer') as Offer;
    }
    // Relationship: product
    set product(value:LicenseProduct) {
        this.setValueForKey(value, 'product');
    }
    get product():LicenseProduct {
        return this.valueForKey('product') as LicenseProduct;
    }

    // Relationship: tokens
    protected _tokens:MIOManagedObjectSet = null;
    get tokens():MIOManagedObjectSet {
        return this.valueForKey('tokens');
    }
    addTokensObject(value:LicenseToken) {
        this._addObjectForKey(value, 'tokens');
    }
    removeTokensObject(value:LicenseToken) {
        this._removeObjectForKey(value, 'tokens');
    }
    // Relationship: type
    set type(value:LicenseType) {
        this.setValueForKey(value, 'type');
    }
    get type():LicenseType {
        return this.valueForKey('type') as LicenseType;
    }
}
