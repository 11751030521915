

/// <reference path="StockNote.ts" />

// Generated class InternalOrder_ManagedObject

class InternalOrder_ManagedObject extends StockNote
{
    // Relationship: internalOrderResponse
    set internalOrderResponse(value:InternalOrderResponse) {
        this.setValueForKey(value, 'internalOrderResponse');
    }
    get internalOrderResponse():InternalOrderResponse {
        return this.valueForKey('internalOrderResponse') as InternalOrderResponse;
    }
}
