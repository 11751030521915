//
// Generated class LegalTax
//

/// <reference path="LegalTax_ManagedObject.ts" />

class LegalTax extends LegalTax_ManagedObject
{

}
