
class VIPSourceCell extends MUITableViewCell
{
    private nameTextField:MUITextField = null;
    private contactNameTextField:MUITextField = null;
    private emailTextField:MUITextField = null;
    private phoneTextField:MUITextField = null;
    private slotsLabel: MUILabel = null;
    private addSlotsButton:MUIButton = null;

    awakeFromHTML(){
        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.contactNameTextField = MUIOutlet(this, "contact-tf", "MUITextField");
        this.emailTextField = MUIOutlet(this, "email-tf", "MUITextField");
        this.phoneTextField = MUIOutlet(this, "phone-tf", "MUITextField");
        this.slotsLabel = MUIOutlet(this, "slots-lbl", "MUILabel");
        this.addSlotsButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addSlotsButton.setAction(this, function(){

        });

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    private _item:BookingSource = null;
    set item(value:BookingSource){
        this._item = value;
        this.nameTextField.text = value.name;
        this.contactNameTextField.text = value.contactName;
        this.emailTextField.text = value.email;
        this.phoneTextField.text = value.phone;
        this.slotsLabel.text = value.slots + "/" + value.slotsMax;        
    }
}