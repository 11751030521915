

class WarehouseDetailViewController extends MUIViewController
{

  static newInstance() : WarehouseDetailViewController {
    let vc = new WarehouseDetailViewController('warehouse-detail-view');
    vc.initWithResource('layout/warehouse/WarehouseDetailView.html');
    return vc;
  }

  private backButton:MUIButton = null;
  private addButton:MUIButton = null;
  private saveButton:MUIButton = null;
  private nameTextField:MUITextField = null;
  private tableView:UITableView = null;

  viewDidLoad(){
      super.viewDidLoad();

      this.backButton = MUIOutlet(this, "back-btn", "MUIButton");
        this.backButton.setAction(this, function(this:SyncLogEditViewController) { 
            this.navigationController.popViewController(true);
        });

      this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
      this.addButton.setAction(this, function(){
          this.addProductWarehouse();
      });

      this.saveButton = MUIOutlet(this, 'save-btn', 'MUIButton');
      this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
      })

      this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
      this.nameTextField.setOnChangeText(this, function(control, value){
          this._warehouse.name = value;
      });

      this.tableView = MUIOutlet(this,"table-view", "UITableView");
      this.tableView.dataSource = this;
      this.tableView.delegate = this;
  }

    viewWillAppear(animate?) {
        super.viewWillAppear(animate);        
        this.updateUI();
    }

    private _warehouse:Warehouse = null;
    set item(i:Warehouse) {
        this._warehouse = i;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._warehouse == null) return;
      
        this.nameTextField.text = this._warehouse.name;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }
    
    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('WarehouseDetailCell') as WarehouseDetailCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as WarehouseLocation;
        cell.item = item;
        cell.selectionStyle = UITableViewCellSelectionStyle.None;
        return cell;
    }
        
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
        return UITableViewCellEditingStyle.Delete;
    }
    
    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as WarehouseLocation;    

        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            DBHelper.deleteObjectFromMainContext(item, true);
        }
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
        let predicateFormat = "warehouseID == " + this._warehouse.identifier;
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("WarehouseLocation", sortDescriptors, predicateFormat);    
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    
    private addProductWarehouse(){

        var avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('NEW LOCATION','NEW LOCATION'), MIOLocalizeString('CHOOSE A NAME','CHOOSE A NAME '), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD','ADD'), MUIAlertActionStyle.Default, this, function(){
            let nameTF =  avc.textFields[0];
            let name = nameTF.text.trim();

            if(name.length > 0){
                let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
                let warehouseLocation = MIOEntityDescription.insertNewObjectForEntityForName("WarehouseLocation", moc) as WarehouseLocation;
                warehouseLocation.identifier = MIOUUID.UUID().UUIDString;
                warehouseLocation.name = name;
                warehouseLocation.warehouseID = this._warehouse.identifier;
                moc.save();
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('CHECK NAME','CHECK NAME'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CLOSE','CLOSE'), MUIAlertActionStyle.Cancel, this, function(){}));
                
                this.presentViewController(error, true);
            }
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, this, function(){}));
        
        this.presentViewController(avc, true);
    }
}
