


class EventDetailBudgetViewController extends MUIViewController
{

    private _event:DLEvent = null;
    private searchTextField:MUITextField = null;
    private searchString:string = null;
    private activityIndicatorView:MUIActivityIndicatorView = null;
    private tableView:MUITableView = null;
    private _fetchedResultsController:MIOFetchedResultsController = null;

    viewDidLoad()
    {
        super.viewDidLoad();
/*
        this.activityIndicatorView = MUIOutlet(this, 'activity-indicator','MUIActivityIndicatorView');
        this.activityIndicatorView.setHidden(true);

        this.searchTextField = MUIOutlet(this, "search_bar", "MUITextField");
        this.searchTextField.setOnChangeText(this, function (textfield, value) {

            clearTimeout(this.delayTimer);
            var instance = this;

            instance.delayTimer = setTimeout(function() {

                //instance.resetFetchedResultsController();
                instance.fetchedResultsController = null;

                if (value.length > 0)
                    instance.searchString = value;
                else
                    instance.searchString = null;

                instance.tableView.reloadData();

            }, 1000, instance);

        });

        this.tableView = MUIOutlet(this, 'tableview', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    */
    }

    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
      //  this.updateUI();
    }
    set event(value)
    {
        this._event = value;
    //    this.updateUI();
    }

    get entityName(){
        return 'Budget';
    }

    private updateUI()
    {
        if(this.viewIsLoaded && this._event != null)
        {
            //this.resetFetchedResultsController();
            this.fetchedResultsController = null;
            this.tableView.reloadData();
        }
    }

    numberOfSections(tableview)
    {
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section)
    {
        var sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath)
    {
        var cell:EventBudgetCell = tableview.dequeueReusableCellWithIdentifier('EventBudgetCell') as EventBudgetCell;
    
        // var item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Budget;
    
        // cell.item = item;
    
        // cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
        // cell.setAccessoryType(MUITableViewCellAccessoryType.DisclosureIndicator);
    
        return cell;
    }
        
    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath)
    {
        var obj = this.fetchedResultsController.objectAtIndexPath(indexPath);
    
        //if(!this._cdvc) {
        //    this._cdvc = this.splitViewController.detailViewController.rootViewController;
        //}
    
        //this._cdvc.client = client;
    
    }
    
    set fetchedResultsController(value)
    {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController()
    {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        var ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("date", true)];
    
        let queryString = 'event.identifier = ' + this._event.identifier;
        
        if (this.searchString != null && this.searchString != '')
            queryString += ' AND date CONTAINS "' + this.searchString + '"';

        var fetchRequest = DBHelper.listFetchRequestWithEntityName(this.entityName, sortDescriptors, queryString);
        var fetchedResultsController = new MIOFetchedResultsController();
        
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller)
    {
        this.tableView.reloadData();
    }
}