//
// Generated class BankAccount
//

/// <reference path="BankAccount_ManagedObject.ts" />

class BankAccount extends BankAccount_ManagedObject
{

}
