

class TagView extends MUIView
{
    delegate = null;

    private prototype = null;

    initWithLayer(layer, owner, options?){
        super.initWithLayer(layer, owner, options);
        this.prototype = MUILayerGetFirstElementWithTag(this.layer, "DIV");        
        this.prototype.style.display = "none";
    }

    private tags = [];
    private views = [];
    addTag(tag:string){
        this.tags.addObject(tag);
        let v = new MUIButton();
        let newLayer = this.prototype.cloneNode(true);
        newLayer.style.display = "";
        v.initWithLayer(newLayer, this);
        v.awakeFromHTML();      
        v.title = tag;
        v.type = MUIButtonType.PushOnPushOff;
        v.setAction(this, this.tagDidClick);        
        this.views.addObject(v);

        this.addSubview(v);  
    }

    removeTag(tag:string){
        let index = this.tags.indexOfObject(tag);
        if (index < 0) return;        
        this.tags.removeObjectAtIndex(index);
        this.views.removeObjectAtIndex(index);
    }

    removeAll(){
        for (let index = 0; index < this.views.length; index++){
            let v = this.views[index] as MUIView;
            v.removeFromSuperview();
        }

        this.views = [];
        this.tags = [];
    }

    selectTagAtIndex(index){
        let v = this.views[index] as MUIButton;
        v.selected = true;
    }

    deselectTagAtIndex(index){
        let v = this.views[index];
        v.selected = true;
    }

    private tagDidClick(button:MUIButton){
        if (this.delegate == null) return;

        let index = this.views.indexOfObject(button);
        this.delegate.didSelectTagAtIndex(index, button.title);
    }

}