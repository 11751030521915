

class WineCellarConfigViewController extends MUIViewController
{

  static newInstance() : WineCellarConfigViewController {
    let vc = new WineCellarConfigViewController('wine-cellar-config-view');
    vc.initWithResource('layout/winecellar/WineCellarConfigView.html');
    return vc;
  }

  private addButton:MUIButton = null;
  private saveButton:MUIButton = null;
  private tableView:UITableView = null;
  private _tempWineCellarHash:string = null;

  viewDidLoad(){
      super.viewDidLoad();

      this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
      this.addButton.setAction(this, function(){
          //this.addWineCellar();


        let vc = new AddWineCellarViewController("add-wine-cellar-view");
        vc.initWithResource("layout/winecellar/AddWineCellarView.html");
        vc.delegate = this;

        let nc = new MUINavigationController('wineCellar_nav_controller');
        nc.initWithRootViewController(vc);
        this.presentViewController(nc, true);
      });

      this.saveButton = MUIOutlet(this, 'save-btn', 'MUIButton');
      this.saveButton.setAction( this, function() {
        DBHelper.saveMainContext();
    })

      this.tableView = MUIOutlet(this,"table-view", "UITableView");
      this.tableView.dataSource = this;
      this.tableView.delegate = this;
  }

    viewWillAppear(animate?) {
        super.viewWillAppear(animate);        
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;
      
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }
    
    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('WineCellarConfigCell') as WineCellarConfigCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as WineCellar;
        cell.item = item;
        cell.selectionStyle = UITableViewCellSelectionStyle.None; 
        return cell;
    }
        
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        // let vc = WarehouseDetailViewController.newInstance();
        // vc.item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Warehouse;
        // this.navigationController.pushViewController(vc, true);
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
        return UITableViewCellEditingStyle.Delete;
    }
    
    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as WineCellar;    

        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            // DBHelper.deleteObjectFromMainContext(item, true);

            this.deleteWineCellar(item);
        }
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("WineCellar", sortDescriptors, null);
        // fetchRequest.relationshipKeyPathsForPrefetching = ['ProductAttributeType']; //relationship not implemented       
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    
    private addWineCellar(){

        var avc = new MUIAlertViewController();

        avc.initWithTitle(MIOLocalizeString('NEW WINE CELLAR','NEW WINE CELLAR'), MIOLocalizeString('CHOOSE A NAME','CHOOSE A NAME '), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });

        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
            comboBox.removeAllItems();
            comboBox.addItem(MIOLocalizeString("CATEGORY STOCK", "CATEGORY STOCK"), WineCellarType.StockCategory);
            comboBox.addItem(MIOLocalizeString("ATTRIBUTE", "ATTRIBUTE"), WineCellarType.ProductAttribute);                      
        });


        // MAKE THIS CONDITIONAL ACCORDING TO THE SELECTION IN THE COMBOBOX ABOVE
        // avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
        //     DBHelper.sharedInstance().addObserverForEntity(comboBox, 'ProductAttributeType', null, null, null, function (objects:ProductAttributeType[]) {
        //         comboBox.removeAllItems();
        //         comboBox.addItem(MIOLocalizeString("SELECT ITEM", "SELECT ITEM"), -1);
        //         for (let i = 0; i < objects.length; i++)
        //             comboBox.addItem(objects[i].name, i);
        //     });

        //     comboBox.enabled = false;

        // });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD','ADD'), MUIAlertActionStyle.Default, this, function(){
            let nameTF =  avc.textFields[0];
            let name = nameTF.text.trim();
            let type = avc.comboBoxes[0].getSelectedItem();
            let selectedAttributeType = avc.comboBoxes[1].getSelectedItem();

            if(name.length > 0){
                let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
                let ws = MIOEntityDescription.insertNewObjectForEntityForName("WineCellar", moc) as WineCellar;
                ws.identifier = MIOUUID.UUID().UUIDString;
                ws.name = name;
                ws.type = type;
                ws.orderIndex = this.fetchedResultsController.fetchedObjects.length;

                // IF TYPE IS ATTRIBUTE, AND IF ATTRIBUTETYPE IS SELECTED, THEN ADD THAT ATTRUBUTETYPE TO THIS WINECELLAR

                moc.save();
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('CHECK NAME','CHECK NAME'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CLOSE','CLOSE'), MUIAlertActionStyle.Cancel, this, function(){}));
                
                this.presentViewController(error, true);
            }
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, this, function(){}));

        avc.addCompletionHandler(this, function () {
            // Release the observers
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, 'ProductAttributeType');
        });

        this.presentViewController(avc, true);
    }


    private deleteWineCellar(wineCellar:WineCellar) {

        let sds = [MIOSortDescriptor.sortDescriptorWithKey("orderIndex", true)];
        let predicate = MIOPredicate.predicateWithFormat("wineCellarDict != null");
        
        DBHelper.queryObjectsWithCompletion("Product", sds, predicate, [], this, function(objects){
            if (objects == null) return;
            let productsWithWineCellar:Product[] = objects;

            for (let index=0;index<productsWithWineCellar.length;index++) {
                //update all winecellar Dict and Hash for each product
                this.removeItemFromWineCellarDict(wineCellar, productsWithWineCellar[index].wineCellarDict);
                //this.updateWineCellarHashfromDict(productsWithWineCellar[index].wineCellarHash , productsWithWineCellar[index].wineCellarDict);
                let isMyObjectEmpty = !Object.keys(productsWithWineCellar[index].wineCellarDict).length;
                if (isMyObjectEmpty || this.fetchedResultsController.resultObjects.length <= 1) {
                    productsWithWineCellar[index].wineCellarDict = null;
                    productsWithWineCellar[index].wineCellarHash = "";
                }
                let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
                moc.save();
            }
            
            DBHelper.deleteObjectFromMainContext(wineCellar, true);
            
        });

    }

    private removeItemFromWineCellarDict(wineCellar:WineCellar, wineCellarDict:any){
        if(wineCellar && wineCellarDict) {
            delete wineCellarDict[wineCellar.identifier];
        } 
    }

    // CURRENTLY UNUSED
    private updateWineCellarHashfromDict(wineCellarHash:string, wineCellarDict:any) {
        
        if(wineCellarHash && wineCellarDict) {
 
            this._tempWineCellarHash = "";
            let count = 0;
            let maxCount = Object.keys(this._tempWineCellarDict).length;

            for (let i=0; i<this.tableView.rows.length; i++) {

                if (this.tableView.rows[i]._wineCellar.type == WineCellarType.ProductAttribute) {
                    if (this.tableView.rows[i]._selectedProductAttribute) {
                        this._tempWineCellarHash += (this.tableView.rows[i]._selectedProductAttribute.name);
                        if ( i < this.tableView.rows.length-1 && count < maxCount-1) {
                            this._tempWineCellarHash += " | ";
                            count ++;
                        }
                    }
                } else if (this.tableView.rows[i]._wineCellar.type == WineCellarType.StockCategory) {
                    if (this.tableView.rows[i]._selectedStockCategory) {
                        this._tempWineCellarHash += (this.tableView.rows[i]._selectedStockCategory.name);
                        if ( i < this.tableView.rows.length-1 && count < maxCount-1) {
                            this._tempWineCellarHash += " | ";
                            count ++;
                        }
                    } 
                }
            }   

        }

        // GET ALL RELEVANT PRODUCTS.wineCellarDict
        // GET ALL RELEVANT WineCellars in one query
        // GET ALL ProductAtributes/StockCategory
        // 

        // for (let i=0; i<this.tableView.rows.length; i++) {
        //     if (this.tableView.rows[i]._wineCellar.type == WineCellarType.ProductAttribute) {
        //         if (this.tableView.rows[i]._selectedProductAttribute) {
        //             this._tempWineCellarHash += (this.tableView.rows[i]._selectedProductAttribute.name);
        //             if ( i < this.tableView.rows.length-1 && count < maxCount-1) {
        //                 this._tempWineCellarHash += " | ";
        //                 count ++;
        //             }
        //         }
        //     } else if (this.tableView.rows[i]._wineCellar.type == WineCellarType.StockCategory) {
        //         if (this.tableView.rows[i]._selectedStockCategory) {
        //             this._tempWineCellarHash += (this.tableView.rows[i]._selectedStockCategory.name);
        //             if ( i < this.tableView.rows.length-1 && count < maxCount-1) {
        //                 this._tempWineCellarHash += " | ";
        //                 count ++;
        //             }
        //         } 
        //     }
        // }
    }

    private updateWineCellarHash() {
        
    }



}
