//
// Generated class SupplierPaymentIntallment
//

/// <reference path="SupplierPaymentIntallment_ManagedObject.ts" />

class SupplierPaymentIntallment extends SupplierPaymentIntallment_ManagedObject
{

}
