
class DBConsumptionLogCell extends UITableViewCell
{
    private dateLabel:MUILabel = null;
    private parentLabel:MUILabel = null;
    private nameLabel:MUILabel = null;
    private docLabel:MUILabel = null;
    private typeLabel:MUILabel = null;
    private quantityLabel:MUILabel = null;
    private measureLabel:MUILabel = null;
    private costLabel:MUILabel = null;
    private computedCostLabel:MUILabel = null;
    private warehouseLabel:MUILabel = null;

    awakeFromHTML() {
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");
        this.parentLabel = MUIOutlet(this, "parent-lbl", "MUILabel");
        this.nameLabel = MUIOutlet(this, "product-lbl", "MUILabel");
        this.docLabel = MUIOutlet(this, "doc-lbl", "MUILabel");
        this.typeLabel = MUIOutlet(this, "type-lbl", "MUILabel");
        this.quantityLabel = MUIOutlet( this, "quantity-lbl", "MUILabel");        
        this.measureLabel = MUIOutlet( this, "measure-lbl", "MUILabel");
        this.costLabel = MUIOutlet( this, "cost-lbl", "MUILabel");
        this.warehouseLabel = MUIOutlet( this, "warehouse-lbl", "MUILabel");
    }

    set item(item:StockConsumptionAnnotation) {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        this.dateLabel.text = ad.dateTimeFormatter.stringFromDate(item.date);
        this.parentLabel.text = item.parent?.productName;
        this.nameLabel.text = item.productName;
        this.docLabel.text = item.sourceLegalDocumentReference ?? item.sourceDocumentReference;
        this.typeLabel.text = String(item.type);
        this.quantityLabel.text = ad.numberFormatter.stringFromNumber(item.minTotalQuantity);
        this.measureLabel.text = MeasureUnits.measureUnitTypeString(item.minMeasureUnitType);
        this.costLabel.text = ad.numberFormatter.stringFromNumber(item.computedCost);
        this.warehouseLabel.text = item.warehouseName;
    }
}