

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class PriceGroupConfiguration_ManagedObject

class PriceGroupConfiguration_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: formatID
    set formatID(value:string) {
        this.setValueForKey(value, 'formatID');
    }
    get formatID():string {
        return this.valueForKey('formatID');
    }
    set formatIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'formatID');
    }
    get formatIDPrimitiveValue():string {
        return this.primitiveValueForKey('formatID');
    }

    // Property: formatName
    set formatName(value:string) {
        this.setValueForKey(value, 'formatName');
    }
    get formatName():string {
        return this.valueForKey('formatName');
    }
    set formatNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'formatName');
    }
    get formatNamePrimitiveValue():string {
        return this.primitiveValueForKey('formatName');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }
    // Relationship: group
    set group(value:PriceGroup) {
        this.setValueForKey(value, 'group');
    }
    get group():PriceGroup {
        return this.valueForKey('group') as PriceGroup;
    }
}
