//
// Generated class InternalInputNote
//

/// <reference path="InternalInputNote_ManagedObject.ts" />

class InternalInputNote extends InternalInputNote_ManagedObject
{

}
