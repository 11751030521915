//
// Generated class BookingSource
//

/// <reference path="BookingSource_ManagedObject.ts" />


class BookingSource extends BookingSource_ManagedObject
{
    static insertNewSource(name:string, email?:string, phone?:string, context?:MIOManagedObjectContext){
        
        let moc = context != null ? context : MUIWebApplication.sharedInstance().delegate.managedObjectContext;

        let src = MIOEntityDescription.insertNewObjectForEntityForName("BookingSource", moc) as BookingSource;        
        src.identifier = MIOUUID.UUID().UUIDString;
        src.name = name;        
        if (email) src.email = email;
        
        src.address = MIOEntityDescription.insertNewObjectForEntityForName("Address", moc) as Address;
        src.address.identifier = MIOUUID.UUID().UUIDString;        
        src.address.country = AppHelper.sharedInstance().defaultCountry.name;        
        src.address.countryISOa2 = AppHelper.sharedInstance().defaultCountry.iso2;
        src.address.countryISOa3 = AppHelper.sharedInstance().defaultCountry.iso3;

        src.phone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", moc) as PhoneNumber;
        src.phone.identifier = MIOUUID.UUID().UUIDString;
        if (src) src.phone.number = phone;

        moc.save();

        return src;
    }
}
