//
// Generated class LoanPaymentInstallment
//

/// <reference path="LoanPaymentInstallment_ManagedObject.ts" />

class LoanPaymentInstallment extends LoanPaymentInstallment_ManagedObject
{

}
