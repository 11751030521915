
/// <reference path="../notes/NoteDetailHeaderView.ts" />


class DeliveryOrderDetailHeader extends NoteDetailHeaderView
{
    private documentLabel:MUILabel = null;
    private destinationLabel:MUILabel = null;    

    private externalReferenceTextField:MUITextField = null;
    private externalReferenceLabel:MUILabel = null;

    private documentDateLabel:MUILabel = null;    
    private documentDateTextField:MUITextField = null;
    private documentDateButton:MUIButton = null;

    private stockDateLabel:MUILabel = null;
    private stockDateTextField:MUITextField = null;
    private stockDateButton:MUIButton = null;

    private dtf = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter as MIODateFormatter;

    awakeFromHTML(){
        super.awakeFromHTML();
        
        this.documentLabel = MUIOutlet(this, "document-id-lbl", "MUILabel");
        this.destinationLabel = MUIOutlet(this, "destination-lbl", "MUILabel");        
        
        this.externalReferenceLabel = MUIOutlet(this, "external-reference-lbl", "MUILabel");
        this.externalReferenceTextField = MUIOutlet(this, "external-refence-tf", "MUITextField");
                
        this.documentDateLabel = MUIOutlet(this, "document-date-lbl", "MUILabel");
        this.documentDateTextField = MUIOutlet(this, "document-date-tf", "MUITextField");
        this.setupDocumentDateTextField(this.documentDateTextField);
        this.documentDateButton = MUIOutlet(this, "document-date-btn", "MUIButton");
        this.setupCalendarButton(this.documentDateButton, NoteDetailHeaderViewDateType.DocumentDate);
        
        this.stockDateLabel = MUIOutlet(this, "stock-date-lbl", "MUILabel");
        this.stockDateTextField = MUIOutlet(this, "stock-date-tf", "MUITextField");
        this.setupValidationDateTextField(this.stockDateTextField);
        this.stockDateButton = MUIOutlet(this, "stock-date-btn", "MUIButton");
        this.setupCalendarButton(this.stockDateButton, NoteDetailHeaderViewDateType.ValidationDate);
    }

    set note (item:StockNote){
        this.stockNote = item;

        this.documentLabel.text = item.documentID;
        this.destinationLabel.text = item.destinationName;        
        
        if (this.externalReferenceLabel != null) this.externalReferenceLabel.text = item.externalReference;
        if (this.externalReferenceTextField != null) this.externalReferenceTextField.text = item.externalReference;
        
        let documentDateString = this.dtf.stringFromDate(item.documentDate);
        if (this.documentDateLabel != null) this.documentDateLabel.text = documentDateString;
        if (this.documentDateTextField != null) this.documentDateTextField.text = documentDateString;

        let validationDateString = this.dtf.stringFromDate(item.stockDate);
        if (this.stockDateLabel != null) this.stockDateLabel.text = validationDateString;
        if (this.stockDateTextField != null) this.stockDateTextField.text = validationDateString;
    }

    protected dateDidChange(type:NoteDetailHeaderViewDateType, date:Date){
        switch(type){
            case NoteDetailHeaderViewDateType.DocumentDate:
            this.documentDateTextField.text = this.dtf.stringFromDate(date);
            break;

            case NoteDetailHeaderViewDateType.ValidationDate:
            this.stockDateTextField.text = this.dtf.stringFromDate(date);
            break;
        }
    }

}
