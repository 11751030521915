

/// <reference path="CoreEntity.ts" />

// Generated class ErrorLog_ManagedObject

class ErrorLog_ManagedObject extends CoreEntity
{

    // Property: appID
    set appID(value:string) {
        this.setValueForKey(value, 'appID');
    }
    get appID():string {
        return this.valueForKey('appID');
    }
    set appIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'appID');
    }
    get appIDPrimitiveValue():string {
        return this.primitiveValueForKey('appID');
    }

    // Property: message
    set message(value:string) {
        this.setValueForKey(value, 'message');
    }
    get message():string {
        return this.valueForKey('message');
    }
    set messagePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'message');
    }
    get messagePrimitiveValue():string {
        return this.primitiveValueForKey('message');
    }

    // Property: module
    set module(value:number) {
        this.setValueForKey(value, 'module');
    }
    get module():number {
        return this.valueForKey('module');
    }
    set modulePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'module');
    }
    get modulePrimitiveValue():number {
        return this.primitiveValueForKey('module');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }
}
