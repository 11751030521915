//
// Generated class SaleSource
//

/// <reference path="SaleSource_ManagedObject.ts" />

class SaleSource extends SaleSource_ManagedObject
{

}
