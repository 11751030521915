

/// <reference path="AccountAnnotation.ts" />

// Generated class TaxAnnotation_ManagedObject

class TaxAnnotation_ManagedObject extends AccountAnnotation
{

    // Property: taxQuantity
    set taxQuantity(value:number) {
        this.setValueForKey(value, 'taxQuantity');
    }
    get taxQuantity():number {
        return this.valueForKey('taxQuantity');
    }
    set taxQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'taxQuantity');
    }
    get taxQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('taxQuantity');
    }
    // Relationship: tax
    set tax(value:Tax) {
        this.setValueForKey(value, 'tax');
    }
    get tax():Tax {
        return this.valueForKey('tax') as Tax;
    }
}
