//
// Generated class IntegratorMappingConfig
//

/// <reference path="IntegratorMappingConfig_ManagedObject.ts" />

class IntegratorMappingConfig extends IntegratorMappingConfig_ManagedObject
{

}
