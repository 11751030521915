
/// <reference path="StockTemplateLineCell.ts" />

class StockTemplateCategoryLineCell extends StockTemplateLineCell
{
    set item (item:StockTemplateCategoryItem | StockTemplateStockCategoryItem){        
        this.nameLabel.text = (item instanceof StockTemplateCategoryItem) ? 
                (item as StockTemplateCategoryItem).category.name : 
                (item as StockTemplateStockCategoryItem).stockCategory.name;
    }
}