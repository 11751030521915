

/// <reference path="BusinessLog.ts" />

// Generated class LeaveReasonLog_ManagedObject

class LeaveReasonLog_ManagedObject extends BusinessLog
{

    // Property: authorizeBy
    set authorizeBy(value:string) {
        this.setValueForKey(value, 'authorizeBy');
    }
    get authorizeBy():string {
        return this.valueForKey('authorizeBy');
    }
    set authorizeByPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'authorizeBy');
    }
    get authorizeByPrimitiveValue():string {
        return this.primitiveValueForKey('authorizeBy');
    }

    // Property: leaveDate
    set leaveDate(value:Date) {
        this.setValueForKey(value, 'leaveDate');
    }
    get leaveDate():Date {
        return this.valueForKey('leaveDate');
    }
    set leaveDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'leaveDate');
    }
    get leaveDatePrimitiveValue():Date {
        return this.primitiveValueForKey('leaveDate');
    }
    // Relationship: type
    set type(value:LeaveReasonType) {
        this.setValueForKey(value, 'type');
    }
    get type():LeaveReasonType {
        return this.valueForKey('type') as LeaveReasonType;
    }
}
