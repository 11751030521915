
class ReportScriptViewController extends MUIViewController
{
    private languageComboBox:MUIComboBox = null;
    private titleLabel:MUILabel = null;
    private saveButton:MUIButton = null;    
    private editorView:MUICodeEditor = null;        
    private logToolbar:MUIView = null;
    private logDisplayButton:MUIButton = null;
    private logExportButton:MUIButton = null;
    private runButton:MUIButton = null;
    private resultsView:ReportView = null;    
    private optionsButton:MUIButton = null;
    private optionsView:ReportScriptFilterView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.languageComboBox = MUIOutlet(this, "script-type-cb", "MUIComboBox");
        this.languageComboBox.setOnChangeAction(this, function(control, value){
            this.reportScript.type = parseInt(value);
            this.updateScriptLanguage();
        });

        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            this.reportScript.content = this.editorView.text;
            DBHelper.saveMainContext();
        });
        
        this.editorView = MUIOutlet(this, "editor-view", "MUICodeEditor");  
        this.editorView.onTextChange(this, function(control, text:string){
            this.reportScript.content = text;
        });

        let tapGesture = new MUIPanGestureRecognizer();
        tapGesture.initWithTarget(this, this.tapGesture);

        this.logToolbar = MUIOutlet(this, "toolbar", "MUIView");
        this.logToolbar.addGestureRecognizer(tapGesture);

        this.logDisplayButton = MUIOutlet(this, "log-display-btn", "MUIButton");  
        this.logExportButton = MUIOutlet(this, "export-btn", "MUIButton");
        this.logExportButton.setAction(this, function(){
            ReportHelper.sharedInstance().showExportActions("log", "LOG", "Script", this.resultsData, null, null);
        });

        this.runButton = MUIOutlet(this, "run-btn", "MUIButton");
        this.runButton.setAction(this, this.runScriptAction);
        this.logDisplayButton.setAction(this, this.toogleDisplayLogViewAction);      
        this.resultsView = MUIOutlet(this, "log-view", "ReportView");
        this.resultsView.setHeight(0);
        
        this.optionsButton = MUIOutlet(this, "options-btn", "MUIButton");
        this.optionsButton.setAction(this, this.toggleOptionsAction);
        this.optionsView = MUIOutlet(this, "options-view", "ReportScriptFilterView");        
        this.optionsView.setWidth(0);
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private reportScript:ReportScriptItem = null;
    set item(item:ReportScriptItem){
        this.reportScript = item;  
        this.updateUI();      
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this.reportScript == null) return;
        
        this.titleLabel.text = this.reportScript.name;        
        this.editorView.text = this.reportScript.content ? this.reportScript.content : "";    
        
        this.updateScriptLanguage();
    }

    private updateScriptLanguage(){
        let type = ReportScriptItemType.None;
        if (this.reportScript.type == null || this.reportScript.type == ReportScriptItemType.None){
            type = this.reportScript.scriptTypeFromExtensionName();
        }
        else {
            type = this.reportScript.type;
        }        

        this.languageComboBox.selectItem(type);

        switch(type) {
            case ReportScriptItemType.SQL:
            this.editorView.language = MUICodeEditorLanguage.SQL;
            break;

            case ReportScriptItemType.PHP:
            this.editorView.language = MUICodeEditorLanguage.PHP;
            break;

            case ReportScriptItemType.Python:
            this.editorView.language = MUICodeEditorLanguage.Python;
            break;

            case ReportScriptItemType.Javascript:
            this.editorView.language = MUICodeEditorLanguage.Javascript;
            break;

            case ReportScriptItemType.TypeScript:
            this.editorView.language = MUICodeEditorLanguage.TypeScript;
            break;

            case ReportScriptItemType.Swift:
            this.editorView.language = MUICodeEditorLanguage.Swift;
            break;
        }
    }

    private logHeight = 200;
    private tapGesture(gesture:MUIPanGestureRecognizer){
        if (this.isDisplayLogView == false) return;
        
        if (gesture.state == MUIGestureRecognizerState.Changed){
            let p = gesture.translationInView(this.view);
            let offset = this.logHeight + p.y;
            this.resultsView.setHeight(offset);
        }
        else if (gesture.state == MUIGestureRecognizerState.Ended){
            let p = gesture.translationInView(this.view);
            this.logHeight = this.logHeight + p.y;
            this.editorView.setNeedsDisplay();
        }
    }

    private isDisplayLogView = false;
    private toogleDisplayLogViewAction(sender:any){
        if (this.isDisplayLogView == true) {
            this.hideLogView();            
        }
        else {
            this.showLogView();
        }            
    }

    private showLogView(){
        if (this.isDisplayLogView == true) return;
        
        MUIView.animateWithDuration(0.15, this, function(){
            this.isDisplayLogView = true;
            this.resultsView.setHeight(this.logHeight);
        }, function(){
            this.editorView.setNeedsDisplay();
        });
    }

    private hideLogView(){
        if (this.isDisplayLogView == false) return;

        MUIView.animateWithDuration(0.15, this, function(){
            this.isDisplayLogView = false;
            this.resultsView.setHeight(0);
        }, function(){
            this.editorView.setNeedsDisplay();
        });
    }

    private resultsData = null;
    private runScriptAction(){   
        // this.showLogView();
        // this.resultsView.clear();
        // this.resultsView.logText("Executing script ...");
        
        // let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        // let ws = ad.webService as WebService;                

        // ws.executeNewReportScript(this.reportScript.name, null, "verbose", this, function (code, data) {
        //     if (code != 200) {
        //         this.resultsView.logText("Error executing script (" + code + ")");
        //         return;
        //     }
        //     try {                
        //         let json = JSON.parse(data.replace(/(\r\n|\n|\r)/gm, ""));    
        //         this.resultsView.parseResponse(json);                
        //         this.resultsData = json;
        //     } catch (error) {
        //         this.resultsView.logText(data);
        //     }   
        //     //this.resultsView.parseResponse(data["data"]);
        // });
    }

    private isOpstionsDisplay = false;
    private toggleOptionsAction(){
        if (this.isOpstionsDisplay == true) {
            this.hideOptionsView();            
        }
        else {
            this.showOptionsView();
        }            
    }

    private showOptionsView(){
        if (this.isOpstionsDisplay == true) return;
        
        MUIView.animateWithDuration(0.15, this, function(){
            this.isOpstionsDisplay = true;
            this.optionsView.setWidth(320);
        }, function(){
            this.editorView.setNeedsDisplay();
        });
    }

    private hideOptionsView(){
        if (this.isOpstionsDisplay == false) return;

        MUIView.animateWithDuration(0.15, this, function(){
            this.isOpstionsDisplay = false;
            this.optionsView.setWidth(0);
        }, function(){
            this.editorView.setNeedsDisplay();
        });
    }

}