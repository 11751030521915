

/// <reference path="AccountAnnotation.ts" />

// Generated class SalesAnnotation_ManagedObject

class SalesAnnotation_ManagedObject extends AccountAnnotation
{

    // Property: discount
    set discount(value:number) {
        this.setValueForKey(value, 'discount');
    }
    get discount():number {
        return this.valueForKey('discount');
    }
    set discountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'discount');
    }
    get discountPrimitiveValue():number {
        return this.primitiveValueForKey('discount');
    }

    // Property: invitation
    set invitation(value:number) {
        this.setValueForKey(value, 'invitation');
    }
    get invitation():number {
        return this.valueForKey('invitation');
    }
    set invitationPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'invitation');
    }
    get invitationPrimitiveValue():number {
        return this.primitiveValueForKey('invitation');
    }
}
