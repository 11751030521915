

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class IntegratorMapping_ManagedObject

class IntegratorMapping_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: sourceEntityName
    set sourceEntityName(value:string) {
        this.setValueForKey(value, 'sourceEntityName');
    }
    get sourceEntityName():string {
        return this.valueForKey('sourceEntityName');
    }
    set sourceEntityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceEntityName');
    }
    get sourceEntityNamePrimitiveValue():string {
        return this.primitiveValueForKey('sourceEntityName');
    }

    // Property: sourceID
    set sourceID(value:string) {
        this.setValueForKey(value, 'sourceID');
    }
    get sourceID():string {
        return this.valueForKey('sourceID');
    }
    set sourceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceID');
    }
    get sourceIDPrimitiveValue():string {
        return this.primitiveValueForKey('sourceID');
    }
    // Relationship: mappingInfo
    set mappingInfo(value:IntegratorMappingInfo) {
        this.setValueForKey(value, 'mappingInfo');
    }
    get mappingInfo():IntegratorMappingInfo {
        return this.valueForKey('mappingInfo') as IntegratorMappingInfo;
    }
    // Relationship: targetEntity
    set targetEntity(value:IntegratorMappingTargetEntity) {
        this.setValueForKey(value, 'targetEntity');
    }
    get targetEntity():IntegratorMappingTargetEntity {
        return this.valueForKey('targetEntity') as IntegratorMappingTargetEntity;
    }
}
