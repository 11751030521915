/// <reference path="../base/BaseTableViewController.ts" />

class LoanListViewController extends BaseTableViewController
{    
    static newInstance() : LoanListViewController {
        let vc = new LoanListViewController("loan-list-view");
        vc.initWithResource("layout/loans/LoanListView.html");
        return vc;
    }

    private addButton:MUIButton = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, this.showAddLoanAddOptionsAlert);

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        
        this.tableView.reloadData();
    }
    
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath)
    {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Loan;
        
        let cell = tableview.dequeueReusableCellWithIdentifier("LoanCell") as LoanCell; 
        cell.loan = item;
        cell.selected = (item == this.selectedItem);
        
        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath);
        this.showSelectedItem();
    }

    private showSelectedItem(){
        let vc = LoanDetailViewController.newInstance();
        vc.item = this.selectedItem;
        this.splitViewController.showDetailViewController(vc);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let pf = this.searchString != null ? "name CONTAINS '" + this.searchString + "'" : null;
        let sds = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

        let fetchRequest = DBHelper.listFetchRequestWithEntityName( "Loan", sds, pf );
        fetchRequest.relationshipKeyPathsForPrefetching = ["bankAccount"];
        // fetchRequest.fetchLimit = 100;

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    private showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Loan", NoItemSelectedViewControllerType.Loan);        
        this.splitViewController.showDetailViewController(vc);
    }

    private showAddLoanAddOptionsAlert() 
    {
        let avc = new MUIAlertViewController();
        avc.initWithTitle( MIOLocalizeString("LOAN_OPTIONS", "LOAN_OPTIONS"), MIOLocalizeString("CHOOSE_LOAN_OPTIONS","CHOOSE_LOAN_OPTIONS"), MUIAlertViewStyle.ActionSheet);

        avc.addAction(MUIAlertAction.alertActionWithTitle( MIOLocalizeString("BANK_LOAN", "BANK_LOAN"), MUIAlertActionStyle.Default, this, function(){
            this.showAddLoanAlert(LoanClassnameType.bank);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle( MIOLocalizeString("LOAN", "LOAN"), MUIAlertActionStyle.Default, this, function(){
            this.showAddLoanAlert(LoanClassnameType.loan);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle( MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null) );

        this.presentViewController(avc, true);
    }

    private showAddLoanAlert( type:LoanClassnameType ) : void
    {
        let avc = new MUIAlertViewController();
        avc.initWithTitle( MIOLocalizeString("ADD_LOAN", "ADD_LOAN"), MIOLocalizeString("FILL_IN","FILL_IN"), MUIAlertViewStyle.ActionSheet);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("REFERENCE","REFERENCE"));            
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("TITLE","TITLE"));
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("AMOUNT","AMOUNT"));
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("START_DATE","START_DATE"));
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("END_DATE","END_DATE"));
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("GRACE_PERIOD","GRACE_PERIOD"));
        });

        if (type == LoanClassnameType.bank) {
            avc.addComboBoxWithConfigurationHandler( this, function(comboBox:MUIComboBox){
                let sds = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];                
                DBHelper.sharedInstance().addObserverForEntity(avc, 'Bank', null, sds, null, function (objects:Bank[]) {
                    comboBox.removeAllItems();
                    comboBox.addItem(MIOLocalizeString("CHOOSE_BANK", "CHOOSE_BANK"), -1);
                    for (let i = 0; i < objects.length; i++) {
                        let bank = objects[i];
                        comboBox.addItem(bank.name, i);                    
                    }
                });
            });
        }

        avc.addComboBoxWithConfigurationHandler( this, function(comboBox:MUIComboBox){
            let sds = [MIOSortDescriptor.sortDescriptorWithKey("bank.name", true), MIOSortDescriptor.sortDescriptorWithKey("info.number", true)];            
            DBHelper.sharedInstance().addObserverForEntity(avc, 'BankAccount', null, sds, ["info", "bank"], function (objects:BankAccount[]) {
                comboBox.removeAllItems();
                comboBox.addItem(MIOLocalizeString("CHOOSE_BANK_ACCOUNT", "CHOOSE_BANK_ACCOUNT"), -1);
                for (let i = 0; i < objects.length; i++) {
                    let acc = objects[i];
                    comboBox.addItem(acc.bank.name + ": " + acc.info.number, i);
                }                
            });
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle( MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Default, this, function()
        {
            let reference = avc.textFields[0].text;
            let name = avc.textFields[1].text;
            let amount_str = avc.textFields[2].text;            
            let start_date_str = avc.textFields[3].text;
            let end_date_str = avc.textFields[4].text;
            let grace_period_str = avc.textFields[5].text;

            let bank:Bank = null;
            let bank_account:BankAccount = null;

            if (type == LoanClassnameType.loan){                
                let bank_account_index = parseInt(avc.comboBoxes[0].selectedItemIndex);
                bank_account = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, bank_account_index, 'BankAccount') as BankAccount;
            }
            else {
                let bank_index = parseInt(avc.comboBoxes[0].selectedItemIndex);
                let bank_account_index = parseInt(avc.comboBoxes[1].selectedItemIndex);    
                bank = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, bank_index, 'Bank') as Bank;
                bank_account = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, bank_account_index, 'BankAccount') as BankAccount;
            }
                        
            let amount = (MUIWebApplication.sharedInstance().delegate as AppDelegate).currencyFormatter.numberFromString(amount_str);
            let start_date = (MUIWebApplication.sharedInstance().delegate as AppDelegate).dateFormatter.dateFromString(start_date_str);
            let end_date = (MUIWebApplication.sharedInstance().delegate as AppDelegate).dateFormatter.dateFromString(end_date_str);
            let grace_period = parseInt(grace_period_str);

            DLDB.sharedInstance().addLoan( type, name, amount, start_date, end_date, reference, grace_period, bank_account, bank );
            DBHelper.saveMainContext();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle( MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null) );

        avc.addCompletionHandler(this, function () {
            // Release the observers            
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, 'Bank');
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, 'BankAccount');
        });

        this.presentViewController(avc, true);
    }     
}