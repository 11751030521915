//
// Generated class DeliveryNote
//

/// <reference path="DeliveryNote_ManagedObject.ts" />

class DeliveryNote extends DeliveryNote_ManagedObject
{

}
