//
// Generated class WarehouseInputNoteLine
//

/// <reference path="WarehouseInputNoteLine_ManagedObject.ts" />

class WarehouseInputNoteLine extends WarehouseInputNoteLine_ManagedObject
{

}
