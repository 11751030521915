//
// Generated class AdyenPaymentEntity
//

/// <reference path="AdyenPaymentEntity_ManagedObject.ts" />

enum AdyenPaymentEntityCompanyType
{
    individual = 0,
    organization = 1
    soleProperty = 2,    
}

enum AdyenPaymentEntityStatus
{
    none = 0,
    created = 1,    // when everything is fully created
    validated = 2,  // when signed the contract
    ready = 3,      // when the account is ready to be used

    error = -1,
}


class AdyenPaymentEntity extends AdyenPaymentEntity_ManagedObject
{
    static stringForCompanyType(type:AdyenPaymentEntityCompanyType):string {

        switch (type){
            case AdyenPaymentEntityCompanyType.individual: return MIOLocalizeString("INDIVIDUAL", "INDIVIDUAL");
            case AdyenPaymentEntityCompanyType.organization: return MIOLocalizeString("ORGANIZATION", "ORGANIZATION");
            case AdyenPaymentEntityCompanyType.soleProperty: return MIOLocalizeString("SOLE PROPERTY", "SOLE PROPERTY");
        }        
    }

    static supportedCompanyTypes() : AdyenPaymentEntityCompanyType[] {
        return [
            AdyenPaymentEntityCompanyType.organization,
            AdyenPaymentEntityCompanyType.soleProperty
        ];        
    }
}
