
class ProductWarehouseDataSource extends MIOObject
{
    private tableView:MUITableView = null;
    initWithTableView(tableview:MUITableView){
        this.tableView = tableview;
    }

    private _product:Product = null;
    set product(product:Product){
        this._product = product;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableview:MUITableView){
        return this.fetchedResultsController.sections.length;
        // return this.fetchedResultsController.fetchedObjects.length;
    }

    numberOfRowsInSection(tableview:MUITableView, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
        // let item = this.tableView.dataSource.fetchedResultsController.fetchedObjects[section];
        // let sec = this.fetchedResultsController.sections[section];
        // return sec.numberOfObjects();
        // let predicate = MIOPredicate.predicateWithFormat("productID == '" + this._product.identifier + "' AND warehouseID == '" + item.identifier + "'");
        // DBHelper.queryObjectsWithCompletion("ProductWarehouseLocation", null, predicate, [], this, function(this, objects){
        //     return objects.length + 1;           
        // });  
    }

    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath){        
        let cell = tableview.dequeueReusableCellWithIdentifier("WarehouseCell") as ProductWarehouseStockCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ProductWarehouse;
        cell.item = item;            
        return cell;
    }

    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {   
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {        

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            let noteline = this.fetchedResultsController.objectAtIndexPath(indexPath);    
            DBHelper.deleteObjectFromMainContext(noteline, true);
        }
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
        
        let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        let predicateFormat = 'product.identifier = ' + this._product.identifier;
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("ProductWarehouse", sd, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["warehouse", "product"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;            
        
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }
}