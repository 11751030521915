

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ProductModifier_ManagedObject

class ProductModifier_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: allergenIndex
    set allergenIndex(value:number) {
        this.setValueForKey(value, 'allergenIndex');
    }
    get allergenIndex():number {
        return this.valueForKey('allergenIndex');
    }
    set allergenIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'allergenIndex');
    }
    get allergenIndexPrimitiveValue():number {
        return this.primitiveValueForKey('allergenIndex');
    }

    // Property: consumptionMeasureUnit
    set consumptionMeasureUnit(value:number) {
        this.setValueForKey(value, 'consumptionMeasureUnit');
    }
    get consumptionMeasureUnit():number {
        return this.valueForKey('consumptionMeasureUnit');
    }
    set consumptionMeasureUnitPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'consumptionMeasureUnit');
    }
    get consumptionMeasureUnitPrimitiveValue():number {
        return this.primitiveValueForKey('consumptionMeasureUnit');
    }

    // Property: consumptionQuantity
    set consumptionQuantity(value:number) {
        this.setValueForKey(value, 'consumptionQuantity');
    }
    get consumptionQuantity():number {
        return this.valueForKey('consumptionQuantity');
    }
    set consumptionQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'consumptionQuantity');
    }
    get consumptionQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('consumptionQuantity');
    }

    // Property: idIn
    set idIn(value:number) {
        this.setValueForKey(value, 'idIn');
    }
    get idIn():number {
        return this.valueForKey('idIn');
    }
    set idInPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'idIn');
    }
    get idInPrimitiveValue():number {
        return this.primitiveValueForKey('idIn');
    }

    // Property: isDefault
    set isDefault(value:boolean) {
        this.setValueForKey(value, 'isDefault');
    }
    get isDefault():boolean {
        return this.valueForKey('isDefault');
    }
    set isDefaultPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isDefault');
    }
    get isDefaultPrimitiveValue():boolean {
        return this.primitiveValueForKey('isDefault');
    }

    // Property: isVisible
    set isVisible(value:boolean) {
        this.setValueForKey(value, 'isVisible');
    }
    get isVisible():boolean {
        return this.valueForKey('isVisible');
    }
    set isVisiblePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isVisible');
    }
    get isVisiblePrimitiveValue():boolean {
        return this.primitiveValueForKey('isVisible');
    }

    // Property: lossInProduction
    set lossInProduction(value:number) {
        this.setValueForKey(value, 'lossInProduction');
    }
    get lossInProduction():number {
        return this.valueForKey('lossInProduction');
    }
    set lossInProductionPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lossInProduction');
    }
    get lossInProductionPrimitiveValue():number {
        return this.primitiveValueForKey('lossInProduction');
    }

    // Property: lossMeasureUnit
    set lossMeasureUnit(value:number) {
        this.setValueForKey(value, 'lossMeasureUnit');
    }
    get lossMeasureUnit():number {
        return this.valueForKey('lossMeasureUnit');
    }
    set lossMeasureUnitPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lossMeasureUnit');
    }
    get lossMeasureUnitPrimitiveValue():number {
        return this.primitiveValueForKey('lossMeasureUnit');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: priceFormat
    set priceFormat(value:string) {
        this.setValueForKey(value, 'priceFormat');
    }
    get priceFormat():string {
        return this.valueForKey('priceFormat');
    }
    set priceFormatPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'priceFormat');
    }
    get priceFormatPrimitiveValue():string {
        return this.primitiveValueForKey('priceFormat');
    }

    // Property: productModifierCount
    set productModifierCount(value:number) {
        this.setValueForKey(value, 'productModifierCount');
    }
    get productModifierCount():number {
        return this.valueForKey('productModifierCount');
    }
    set productModifierCountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productModifierCount');
    }
    get productModifierCountPrimitiveValue():number {
        return this.primitiveValueForKey('productModifierCount');
    }

    // Relationship: modifierLines
    protected _modifierLines:MIOManagedObjectSet = null;
    get modifierLines():MIOManagedObjectSet {
        return this.valueForKey('modifierLines');
    }
    addModifierLinesObject(value:ModifierLine) {
        this._addObjectForKey(value, 'modifierLines');
    }
    removeModifierLinesObject(value:ModifierLine) {
        this._removeObjectForKey(value, 'modifierLines');
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
    // Relationship: productFormat
    set productFormat(value:ProductFormat) {
        this.setValueForKey(value, 'productFormat');
    }
    get productFormat():ProductFormat {
        return this.valueForKey('productFormat') as ProductFormat;
    }

    // Relationship: productMenus
    protected _productMenus:MIOManagedObjectSet = null;
    get productMenus():MIOManagedObjectSet {
        return this.valueForKey('productMenus');
    }
    addProductMenusObject(value:ProductMenu) {
        this._addObjectForKey(value, 'productMenus');
    }
    removeProductMenusObject(value:ProductMenu) {
        this._removeObjectForKey(value, 'productMenus');
    }
    // Relationship: productModifierCategory
    set productModifierCategory(value:ProductModifierCategory) {
        this.setValueForKey(value, 'productModifierCategory');
    }
    get productModifierCategory():ProductModifierCategory {
        return this.valueForKey('productModifierCategory') as ProductModifierCategory;
    }

    // Relationship: productModifierPlaces
    protected _productModifierPlaces:MIOManagedObjectSet = null;
    get productModifierPlaces():MIOManagedObjectSet {
        return this.valueForKey('productModifierPlaces');
    }
    addProductModifierPlacesObject(value:ProductModifierPlace) {
        this._addObjectForKey(value, 'productModifierPlaces');
    }
    removeProductModifierPlacesObject(value:ProductModifierPlace) {
        this._removeObjectForKey(value, 'productModifierPlaces');
    }

    // Relationship: productModifierRates
    protected _productModifierRates:MIOManagedObjectSet = null;
    get productModifierRates():MIOManagedObjectSet {
        return this.valueForKey('productModifierRates');
    }
    addProductModifierRatesObject(value:ProductModifierRate) {
        this._addObjectForKey(value, 'productModifierRates');
    }
    removeProductModifierRatesObject(value:ProductModifierRate) {
        this._removeObjectForKey(value, 'productModifierRates');
    }
    // Relationship: tax
    set tax(value:Tax) {
        this.setValueForKey(value, 'tax');
    }
    get tax():Tax {
        return this.valueForKey('tax') as Tax;
    }
}
