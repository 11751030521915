

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ArchivedTicketLinesGroup_ManagedObject

class ArchivedTicketLinesGroup_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: bulkMeasureUnit
    set bulkMeasureUnit(value:number) {
        this.setValueForKey(value, 'bulkMeasureUnit');
    }
    get bulkMeasureUnit():number {
        return this.valueForKey('bulkMeasureUnit');
    }
    set bulkMeasureUnitPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'bulkMeasureUnit');
    }
    get bulkMeasureUnitPrimitiveValue():number {
        return this.primitiveValueForKey('bulkMeasureUnit');
    }

    // Property: bulkQuantity
    set bulkQuantity(value:number) {
        this.setValueForKey(value, 'bulkQuantity');
    }
    get bulkQuantity():number {
        return this.valueForKey('bulkQuantity');
    }
    set bulkQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'bulkQuantity');
    }
    get bulkQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('bulkQuantity');
    }

    // Property: categoryID
    set categoryID(value:string) {
        this.setValueForKey(value, 'categoryID');
    }
    get categoryID():string {
        return this.valueForKey('categoryID');
    }
    set categoryIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'categoryID');
    }
    get categoryIDPrimitiveValue():string {
        return this.primitiveValueForKey('categoryID');
    }

    // Property: categoryName
    set categoryName(value:string) {
        this.setValueForKey(value, 'categoryName');
    }
    get categoryName():string {
        return this.valueForKey('categoryName');
    }
    set categoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'categoryName');
    }
    get categoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('categoryName');
    }

    // Property: formatID
    set formatID(value:string) {
        this.setValueForKey(value, 'formatID');
    }
    get formatID():string {
        return this.valueForKey('formatID');
    }
    set formatIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'formatID');
    }
    get formatIDPrimitiveValue():string {
        return this.primitiveValueForKey('formatID');
    }

    // Property: formatName
    set formatName(value:string) {
        this.setValueForKey(value, 'formatName');
    }
    get formatName():string {
        return this.valueForKey('formatName');
    }
    set formatNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'formatName');
    }
    get formatNamePrimitiveValue():string {
        return this.primitiveValueForKey('formatName');
    }

    // Property: menuID
    set menuID(value:string) {
        this.setValueForKey(value, 'menuID');
    }
    get menuID():string {
        return this.valueForKey('menuID');
    }
    set menuIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'menuID');
    }
    get menuIDPrimitiveValue():string {
        return this.primitiveValueForKey('menuID');
    }

    // Property: menuName
    set menuName(value:string) {
        this.setValueForKey(value, 'menuName');
    }
    get menuName():string {
        return this.valueForKey('menuName');
    }
    set menuNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'menuName');
    }
    get menuNamePrimitiveValue():string {
        return this.primitiveValueForKey('menuName');
    }

    // Property: noteID
    set noteID(value:string) {
        this.setValueForKey(value, 'noteID');
    }
    get noteID():string {
        return this.valueForKey('noteID');
    }
    set noteIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'noteID');
    }
    get noteIDPrimitiveValue():string {
        return this.primitiveValueForKey('noteID');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: originDocumentID
    set originDocumentID(value:string) {
        this.setValueForKey(value, 'originDocumentID');
    }
    get originDocumentID():string {
        return this.valueForKey('originDocumentID');
    }
    set originDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'originDocumentID');
    }
    get originDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('originDocumentID');
    }

    // Property: productBasePrice
    set productBasePrice(value:number) {
        this.setValueForKey(value, 'productBasePrice');
    }
    get productBasePrice():number {
        return this.valueForKey('productBasePrice');
    }
    set productBasePricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productBasePrice');
    }
    get productBasePricePrimitiveValue():number {
        return this.primitiveValueForKey('productBasePrice');
    }

    // Property: productContainerMeasureType
    set productContainerMeasureType(value:number) {
        this.setValueForKey(value, 'productContainerMeasureType');
    }
    get productContainerMeasureType():number {
        return this.valueForKey('productContainerMeasureType');
    }
    set productContainerMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productContainerMeasureType');
    }
    get productContainerMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('productContainerMeasureType');
    }

    // Property: productContainerQuantity
    set productContainerQuantity(value:number) {
        this.setValueForKey(value, 'productContainerQuantity');
    }
    get productContainerQuantity():number {
        return this.valueForKey('productContainerQuantity');
    }
    set productContainerQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productContainerQuantity');
    }
    get productContainerQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('productContainerQuantity');
    }

    // Property: productFormatID
    set productFormatID(value:string) {
        this.setValueForKey(value, 'productFormatID');
    }
    get productFormatID():string {
        return this.valueForKey('productFormatID');
    }
    set productFormatIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productFormatID');
    }
    get productFormatIDPrimitiveValue():string {
        return this.primitiveValueForKey('productFormatID');
    }

    // Property: productID
    set productID(value:string) {
        this.setValueForKey(value, 'productID');
    }
    get productID():string {
        return this.valueForKey('productID');
    }
    set productIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productID');
    }
    get productIDPrimitiveValue():string {
        return this.primitiveValueForKey('productID');
    }

    // Property: productMeasureType
    set productMeasureType(value:number) {
        this.setValueForKey(value, 'productMeasureType');
    }
    get productMeasureType():number {
        return this.valueForKey('productMeasureType');
    }
    set productMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productMeasureType');
    }
    get productMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('productMeasureType');
    }

    // Property: productName
    set productName(value:string) {
        this.setValueForKey(value, 'productName');
    }
    get productName():string {
        return this.valueForKey('productName');
    }
    set productNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productName');
    }
    get productNamePrimitiveValue():string {
        return this.primitiveValueForKey('productName');
    }

    // Property: productPrice
    set productPrice(value:number) {
        this.setValueForKey(value, 'productPrice');
    }
    get productPrice():number {
        return this.valueForKey('productPrice');
    }
    set productPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productPrice');
    }
    get productPricePrimitiveValue():number {
        return this.primitiveValueForKey('productPrice');
    }

    // Property: productRatePrice
    set productRatePrice(value:number) {
        this.setValueForKey(value, 'productRatePrice');
    }
    get productRatePrice():number {
        return this.valueForKey('productRatePrice');
    }
    set productRatePricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productRatePrice');
    }
    get productRatePricePrimitiveValue():number {
        return this.primitiveValueForKey('productRatePrice');
    }

    // Property: rate
    set rate(value:string) {
        this.setValueForKey(value, 'rate');
    }
    get rate():string {
        return this.valueForKey('rate');
    }
    set ratePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'rate');
    }
    get ratePrimitiveValue():string {
        return this.primitiveValueForKey('rate');
    }

    // Property: rateID
    set rateID(value:string) {
        this.setValueForKey(value, 'rateID');
    }
    get rateID():string {
        return this.valueForKey('rateID');
    }
    set rateIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'rateID');
    }
    get rateIDPrimitiveValue():string {
        return this.primitiveValueForKey('rateID');
    }

    // Property: subtaxesDict
    set subtaxesDict(value:any) {
        this.setValueForKey(value, 'subtaxesDict');
    }
    get subtaxesDict():any {
        return this.valueForKey('subtaxesDict');
    }
    set subtaxesDictPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'subtaxesDict');
    }
    get subtaxesDictPrimitiveValue():any {
        return this.primitiveValueForKey('subtaxesDict');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: tax
    set tax(value:number) {
        this.setValueForKey(value, 'tax');
    }
    get tax():number {
        return this.valueForKey('tax');
    }
    set taxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'tax');
    }
    get taxPrimitiveValue():number {
        return this.primitiveValueForKey('tax');
    }

    // Property: taxID
    set taxID(value:string) {
        this.setValueForKey(value, 'taxID');
    }
    get taxID():string {
        return this.valueForKey('taxID');
    }
    set taxIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'taxID');
    }
    get taxIDPrimitiveValue():string {
        return this.primitiveValueForKey('taxID');
    }

    // Property: taxName
    set taxName(value:string) {
        this.setValueForKey(value, 'taxName');
    }
    get taxName():string {
        return this.valueForKey('taxName');
    }
    set taxNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'taxName');
    }
    get taxNamePrimitiveValue():string {
        return this.primitiveValueForKey('taxName');
    }

    // Property: totalDiscount
    set totalDiscount(value:number) {
        this.setValueForKey(value, 'totalDiscount');
    }
    get totalDiscount():number {
        return this.valueForKey('totalDiscount');
    }
    set totalDiscountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalDiscount');
    }
    get totalDiscountPrimitiveValue():number {
        return this.primitiveValueForKey('totalDiscount');
    }

    // Property: totalInvited
    set totalInvited(value:number) {
        this.setValueForKey(value, 'totalInvited');
    }
    get totalInvited():number {
        return this.valueForKey('totalInvited');
    }
    set totalInvitedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalInvited');
    }
    get totalInvitedPrimitiveValue():number {
        return this.primitiveValueForKey('totalInvited');
    }

    // Property: totalPrice
    set totalPrice(value:number) {
        this.setValueForKey(value, 'totalPrice');
    }
    get totalPrice():number {
        return this.valueForKey('totalPrice');
    }
    set totalPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalPrice');
    }
    get totalPricePrimitiveValue():number {
        return this.primitiveValueForKey('totalPrice');
    }

    // Property: totalPriceBase
    set totalPriceBase(value:number) {
        this.setValueForKey(value, 'totalPriceBase');
    }
    get totalPriceBase():number {
        return this.valueForKey('totalPriceBase');
    }
    set totalPriceBasePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalPriceBase');
    }
    get totalPriceBasePrimitiveValue():number {
        return this.primitiveValueForKey('totalPriceBase');
    }

    // Property: totalTaxes
    set totalTaxes(value:number) {
        this.setValueForKey(value, 'totalTaxes');
    }
    get totalTaxes():number {
        return this.valueForKey('totalTaxes');
    }
    set totalTaxesPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalTaxes');
    }
    get totalTaxesPrimitiveValue():number {
        return this.primitiveValueForKey('totalTaxes');
    }

    // Property: wasCloned
    set wasCloned(value:boolean) {
        this.setValueForKey(value, 'wasCloned');
    }
    get wasCloned():boolean {
        return this.valueForKey('wasCloned');
    }
    set wasClonedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'wasCloned');
    }
    get wasClonedPrimitiveValue():boolean {
        return this.primitiveValueForKey('wasCloned');
    }
    // Relationship: archivedDocument
    set archivedDocument(value:ArchivedDocument) {
        this.setValueForKey(value, 'archivedDocument');
    }
    get archivedDocument():ArchivedDocument {
        return this.valueForKey('archivedDocument') as ArchivedDocument;
    }

    // Relationship: archivedTicketLines
    protected _archivedTicketLines:MIOManagedObjectSet = null;
    get archivedTicketLines():MIOManagedObjectSet {
        return this.valueForKey('archivedTicketLines');
    }
    addArchivedTicketLinesObject(value:ArchivedTicketLine) {
        this._addObjectForKey(value, 'archivedTicketLines');
    }
    removeArchivedTicketLinesObject(value:ArchivedTicketLine) {
        this._removeObjectForKey(value, 'archivedTicketLines');
    }

    // Relationship: childs
    protected _childs:MIOManagedObjectSet = null;
    get childs():MIOManagedObjectSet {
        return this.valueForKey('childs');
    }
    addChildsObject(value:ArchivedTicketLinesGroup) {
        this._addObjectForKey(value, 'childs');
    }
    removeChildsObject(value:ArchivedTicketLinesGroup) {
        this._removeObjectForKey(value, 'childs');
    }
    // Relationship: parent
    set parent(value:ArchivedTicketLinesGroup) {
        this.setValueForKey(value, 'parent');
    }
    get parent():ArchivedTicketLinesGroup {
        return this.valueForKey('parent') as ArchivedTicketLinesGroup;
    }
}
