//
// Generated class EmployeePayrollTemplate
//

/// <reference path="EmployeePayrollTemplate_ManagedObject.ts" />

class EmployeePayrollTemplate extends EmployeePayrollTemplate_ManagedObject
{

}
