//
// Generated class RuleSet
//

/// <reference path="RuleSet_ManagedObject.ts" />

class RuleSet extends RuleSet_ManagedObject
{

}
