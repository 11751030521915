//
// Generated class ProductModifier
//

/// <reference path="ProductModifier_ManagedObject.ts" />

class ProductModifier extends ProductModifier_ManagedObject
{
    
}
