//
// Generated class ProductComponentItem
//

/// <reference path="ProductComponentItem_ManagedObject.ts" />

class ProductComponentItem extends ProductComponentItem_ManagedObject
{

}
