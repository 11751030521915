

class TimeRangeCell extends UITableViewCell
{
    private nameLabel:MUILabel = null
    private maxOrdersTF:MUITextField = null;
    // private startLabel:MUILabel = null
    // private endLabel:MUILabel = null
    // private durationLabel:MUILabel = null

    private if = MUIWebApplication.sharedInstance().delegate.integerFormatter as MIONumberFormatter;

    awakeFromHTML(){
        
        this.nameLabel = MUIOutlet(this, "time-lbl", "MUILabel");

        this.maxOrdersTF = MUIOutlet(this, "max-orders-tf", "MUITextField");
        this.maxOrdersTF.formatter = this.if;
        this.maxOrdersTF.setOnChangeText(this, function(control, value){
            this._item.maxOrder = value;
        });

        // this.startLabel = MUIOutlet(this, "start-lbl", "MUILabel");
        // this.endLabel = MUIOutlet(this, "end-lbl", "MUILabel");
        // this.durationLabel = MUIOutlet(this, "duration-lbl", "MUILabel");

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private _item:TimeRange = null;
    set item(item:TimeRange){
        this._item = item;
        this.nameLabel.text = item.name;
        // this.maxOrdersTF.text = item.maxOrder;
        // this.startLabel.text = item.startHour;
        // this.endLabel.text = item.endHour;
        // this.durationLabel.text = this.nf.stringFromNumber(item.duration);
    }
}