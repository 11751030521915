
/// <reference path="ReportFilterCell.ts" />

enum ReportCalendarFilterCellDateType
{
    from,
    to
}

class ReportCalendarFilterCell extends ReportFilterCell
{        
    private calendarButton:MUIButton = null;
    private beginDateTextField:MUITextField = null;
    private endDateTextField:MUITextField = null;

    awakeFromHTML(): void {
        super.awakeFromHTML();

        this.calendarButton = MUIOutlet(this, "calendar-btn", "MUIButton");
        this.calendarButton.setAction(this, function(this:ReportCalendarFilterCell) {
            AppHelper.sharedInstance().presentDateSelectionViewController(null, this);
        });

        this.beginDateTextField = MUIOutlet(this, "begin-date-tf", "MUITextField");
        this.beginDateTextField.setOnChangeText(this, function(textField:MUITextField, value:string) {
            if (value.trim().length == 0) return;
            this.dateDidChange(value.trim(), ReportCalendarFilterCellDateType.from);
        });

        this.endDateTextField = MUIOutlet(this, "end-date-tf", "MUITextField");
        this.endDateTextField.setOnChangeText(this, function(textField:MUITextField, value:string) {
            if (value.trim().length == 0) return;
            this.dateDidChange(value.trim(), ReportCalendarFilterCellDateType.to);
        });
    }

    setFilter(filter: any): void {
        super.setFilter(filter);
    }

    datesDidSelect(dateTimeFrom:Date, dateTimeTo:Date) {
        let rh = ReportHelper.sharedInstance();
        rh.selectedDateTimeFrom = dateTimeFrom;
        rh.selectedDateTimeTo = dateTimeTo;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let fromDate = ad.dateTimeFormatter.stringFromDate(dateTimeFrom);
        let toDate = ad.dateTimeFormatter.stringFromDate(dateTimeTo);
        this.beginDateTextField.text = fromDate;
        this.endDateTextField.text = toDate;

        this.updateDate();
    }

    private dateDidChange(value:string, type:ReportCalendarFilterCellDateType) {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let date = ad.dateTimeFormatter.dateFromString( value );
        if (date == null) return;
        
        let rh = ReportHelper.sharedInstance();                

        switch (type) {
            case ReportCalendarFilterCellDateType.from: rh.selectedDateTimeFrom = date; break;
            case ReportCalendarFilterCellDateType.to: rh.selectedDateTimeTo = date; break;
        }
    }

    private updateDate() {        
        let rh = ReportHelper.sharedInstance();

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let from = ad.dateTimeFormatter.stringFromDate(rh.selectedDateTimeFrom);
        let to = ad.dateTimeFormatter.stringFromDate(rh.selectedDateTimeTo);

        this.filterItem["Value"] = from + "," + to;
    }

}