
class CategorySelectionViewController extends MUIViewController
{
    static newInstance(): CategorySelectionViewController {
        let vc = new CategorySelectionViewController("category-selection-view");
        vc.initWithResource("layout/base/CategorySelectionView.html");
        return vc;
    }

    completionBlock:any = null;

    private segmentedControl:MUISegmentedControl = null;
    private tableView:UITableView = null;

    get preferredContentSize(){
        return new MIOSize(320, 500);
    }

    viewDidLoad(): void {
        this.segmentedControl = MUIOutlet(this, "segmented-control", "MUISegmentedControl");
        this.segmentedControl.setAction(this, function (this:CategorySelectionViewController, control:MUISegmentedControl, index:number) {
            this.tableView.reloadData();
        });
        
        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView) {        
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number) {
        let sec = this.fetchedResultsController.sections[section];
        let rows = sec.numberOfObjects();
        return rows;
    }

    
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath) {        
        let cell = tableview.dequeueReusableCellWithIdentifier('CategorySelectionCell') as SelectCell;        
        cell.accessoryType = UITableViewCellAccessoryType.None;

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        cell.title = item.valueForKey("name");        
        cell.selected = false;

        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {
            
        if (this.completionBlock) {
            let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
            if (this.fetchedResultsController == this._stockFetchedResultsController) {
                this.completionBlock(item, null);
            }
            else if (this.fetchedResultsController == this._salesFetchedResultsController) {
                this.completionBlock(null, item);
            }
        }         

        this.dismissViewController(true);
    }
    
    resetResultController(){
        if (this._salesFetchedResultsController != null) this._salesFetchedResultsController.delegate = null;    
        if (this._stockFetchedResultsController != null) this._stockFetchedResultsController.delegate = null;    
        
        this._salesFetchedResultsController = null;
        this._stockFetchedResultsController = null;
    }

    
    private _stockFetchedResultsController:MIOFetchedResultsController = null;
    private _salesFetchedResultsController:MIOFetchedResultsController = null;
                
    get fetchedResultsController() {

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        if (this.segmentedControl.selectedSegmentedIndex == 1) {
            if (this._salesFetchedResultsController != null) return this._salesFetchedResultsController;

            let fetch = DBHelper.listFetchRequestWithEntityName("ProductCategory", [MIOSortDescriptor.sortDescriptorWithKey("name", true)], null);
            let frc = new MIOFetchedResultsController();            
            frc.initWithFetchRequest(fetch, ad.managedObjectContext, null);
            frc.delegate = this;            
    
            frc.performFetch();
                    
            this._salesFetchedResultsController = frc;
            return this._salesFetchedResultsController;    
        }
        else if (this.segmentedControl.selectedSegmentedIndex == 0) {
            if (this._stockFetchedResultsController != null) return this._stockFetchedResultsController;

            let fetch = DBHelper.listFetchRequestWithEntityName("StockCategory", [MIOSortDescriptor.sortDescriptorWithKey("name", true)], null);
            let frc = new MIOFetchedResultsController();
            frc.initWithFetchRequest(fetch, ad.managedObjectContext, null);
            frc.delegate = this;            
    
            frc.performFetch();
                    
            this._stockFetchedResultsController = frc;
            return this._stockFetchedResultsController;        
        }

        return null;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        this.tableView.reloadData();
    }

}