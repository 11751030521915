
class CashDeskSessionLineIconCell extends MUIReportTableViewCell
{
    private iconView:MUIView = null;
    
    awakeFromHTML(){
        this.iconView = MUIOutlet(this, "icon-view", "MUIView");
    }

    private _style:string = null;
    set iconStyle(style:string){
        if (this._style != null) MUICoreLayerRemoveStyle(this.iconView.layer, this._style);
        MUICoreLayerAddStyle(this.iconView.layer, style);
        this._style = style;
    }
} 