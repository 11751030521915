

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class CheckPoint_ManagedObject

class CheckPoint_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: beginDate
    set beginDate(value:Date) {
        this.setValueForKey(value, 'beginDate');
    }
    get beginDate():Date {
        return this.valueForKey('beginDate');
    }
    set beginDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'beginDate');
    }
    get beginDatePrimitiveValue():Date {
        return this.primitiveValueForKey('beginDate');
    }

    // Property: endDate
    set endDate(value:Date) {
        this.setValueForKey(value, 'endDate');
    }
    get endDate():Date {
        return this.valueForKey('endDate');
    }
    set endDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'endDate');
    }
    get endDatePrimitiveValue():Date {
        return this.primitiveValueForKey('endDate');
    }

    // Property: isOpen
    set isOpen(value:boolean) {
        this.setValueForKey(value, 'isOpen');
    }
    get isOpen():boolean {
        return this.valueForKey('isOpen');
    }
    set isOpenPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isOpen');
    }
    get isOpenPrimitiveValue():boolean {
        return this.primitiveValueForKey('isOpen');
    }

    // Property: workerID
    set workerID(value:string) {
        this.setValueForKey(value, 'workerID');
    }
    get workerID():string {
        return this.valueForKey('workerID');
    }
    set workerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerID');
    }
    get workerIDPrimitiveValue():string {
        return this.primitiveValueForKey('workerID');
    }

    // Property: workerName
    set workerName(value:string) {
        this.setValueForKey(value, 'workerName');
    }
    get workerName():string {
        return this.valueForKey('workerName');
    }
    set workerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerName');
    }
    get workerNamePrimitiveValue():string {
        return this.primitiveValueForKey('workerName');
    }

    // Relationship: cashDeskLines
    protected _cashDeskLines:MIOManagedObjectSet = null;
    get cashDeskLines():MIOManagedObjectSet {
        return this.valueForKey('cashDeskLines');
    }
    addCashDeskLinesObject(value:CashDeskLine) {
        this._addObjectForKey(value, 'cashDeskLines');
    }
    removeCashDeskLinesObject(value:CashDeskLine) {
        this._removeObjectForKey(value, 'cashDeskLines');
    }
    // Relationship: session
    set session(value:CashDeskSession) {
        this.setValueForKey(value, 'session');
    }
    get session():CashDeskSession {
        return this.valueForKey('session') as CashDeskSession;
    }
}
