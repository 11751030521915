//
// Generated class Printer
//

/// <reference path="Printer_ManagedObject.ts" />

class Printer extends Printer_ManagedObject
{

}
