class DBTaxesCoder extends DBXLSXCoder
{
    mainEntity() {
        return { entity: "Tax", predicateFormat: "deletedAt == null" }
    }

    columns() : any[] {
        let cols = [
            { title:"TAX ID", width: "10%", align: "center" },
            { title:"TAX NAME", width: "10%", align: "center" },
            { title:"TAX VALUE", width: "20%", align: "center" },
        ]
        return cols;
    }

    protected importRow(row:any){
                
        let taxID       = row["TAX ID"];
        let taxName     = row["TAX NAME"];        
        let taxValue    = row["TAX VALUE"];

        if (taxValue == null) { return; }

        let tax = this.queryEntityByIDOrName("Tax", taxID, taxName) as Tax;
        if (tax == null) {
            tax = MIOEntityDescription.insertNewObjectForEntityForName("Tax", DBHelper.mainManagedObjectContext) as Tax;
            tax.identifier = this.parseOrCreateIdentifier(taxID);
            tax.name = taxName;
        }
            
        tax.taxQuantity = taxValue;

        this.appendObject(tax);
        MIOLog("ADDING TAX: " + this.rowIndex + "/" + this.rows.length + ": " + taxName);
    }

    protected exportTitle() : string { return "Tax"; }

    protected exportSortDescriptors() { 
        return [MIOSortDescriptor.sortDescriptorWithKey("name", false)]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let tax = object as Tax;

        let item = {
            "TAX ID"           : tax.identifier,
            "TAX NAME"         : tax.name,
            "TAX VALUE"        : tax.taxQuantity
        }

        return item;
    }
}