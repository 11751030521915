

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class BookingModifierCategory_BookingZone_ManagedObject

class BookingModifierCategory_BookingZone_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: bookingZoneID
    set bookingZoneID(value:string) {
        this.setValueForKey(value, 'bookingZoneID');
    }
    get bookingZoneID():string {
        return this.valueForKey('bookingZoneID');
    }
    set bookingZoneIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'bookingZoneID');
    }
    get bookingZoneIDPrimitiveValue():string {
        return this.primitiveValueForKey('bookingZoneID');
    }
    // Relationship: bookingModifierCategory
    set bookingModifierCategory(value:BookingModifierCategory) {
        this.setValueForKey(value, 'bookingModifierCategory');
    }
    get bookingModifierCategory():BookingModifierCategory {
        return this.valueForKey('bookingModifierCategory') as BookingModifierCategory;
    }
}
