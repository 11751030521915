class LowStockListViewController extends NoteListViewController
{
    viewWillAppear(animated:boolean){
        super.viewWillAppear(animated);

        //use the notification for the processed stockNote, recieve the object(the processed stockNote) and if "processed" reload listView
        MIONotificationCenter.defaultCenter().addObserver(this, "StockNoteDidProcess", function(notification:MIONotification){
            let note = notification.object;
            this.fetchedResultsController = null;
            this.tableView.reloadData();
        });
    }

    viewWillDisappear(animated:boolean){
        super.viewWillDisappear(animated);
        MIONotificationCenter.defaultCenter().removeObserver(this, "StockNoteDidProcess");
    }


    titleForHeaderInSection(tableView:MUITableView, section:number) {
        let ip = MIOIndexPath.indexForRowInSection(0, section);
        let item = this.fetchedResultsController.objectAtIndexPath(ip) as StockNote;
        return item.statusString;
    }

    cellAtIndexPath(tableView, indexPath:MIOIndexPath){
        let cell = tableView.dequeueReusableCellWithIdentifier('LowStockCell') as LowStockCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as StockNote;
    
        cell.item = item;
        cell.selected = this.selectedNote == item ? true : false;

        return cell;
    }
        
    set fetchedResultsController(value){
        this.setFetchedResultsController(value);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
    
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('status', true), 
                                MIOSortDescriptor.sortDescriptorWithKey('creationDate', false)];
                
        let predicateFormat = "type = " + StockNoteType.LowStockOrder
        if (ad.selectedIdentifierType == "place") predicateFormat += " AND placeID == '" + ad.selectedIdentifier + "'";
        if(this.searchString != null) {
            predicateFormat += " AND documentID CONTAINS '" + this.searchString + "'";
		}
		
		if (this.filterPredicateFormat != null) {
            predicateFormat += " AND " + this.filterPredicateFormat;
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("StockNote", sortDescriptors, predicateFormat);
        fetchRequest.fetchLimit = 50;
        //fetchRequest.relationshipKeyPathsForPrefetching = ['warehouseOrigin', 'warehouseDestination'];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "status");
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
	}
	
	private _filters = null;
    get filters(){
        if (this._filters != null) return this._filters;

		this._filters = [FilterOption.filterWithTitle("FromDate", "From day", "validationDate", FilterFieldType.Date, null, FilterControllerComparatorType.MajorOrEqualComparator, null, "00:00:00"),
		FilterOption.filterWithTitle("ToDate", "To day", "validationDate", FilterFieldType.Date, null, FilterControllerComparatorType.MinorOrEqualComparator, null, "23:59:59")];

        return this._filters;
    }
    
    private detailViewController:LowStockDetailViewController = null;
    protected showSelectedItem(){
        if(this.detailViewController == null) {
            let vc = new LowStockDetailViewController('low-stock-products-detail-view');
            vc.initWithResource('layout/lowstockproducts/LowStockProductsDetailView.html');   
            this.detailViewController = vc;
        }
        
        this.detailViewController.note = this.selectedNote;
        this.splitViewController.showDetailViewController(this.detailViewController);
    }

    protected showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("LowStockProducts", NoItemSelectedViewControllerType.LowStockProducts);
        this.splitViewController.showDetailViewController(vc);
    }
}