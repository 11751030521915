
class SerialSequenceViewController extends MUIViewController
{
    static newInstance() : SerialSequenceViewController {
        let vc = new SerialSequenceViewController("serial-sequence-view");
        vc.initWithResource("layout/serialsequence/SerialSequenceView.html");
        return vc;
    }

    private addButton:MUIButton = null;
    private saveButton:MUIButton = null;
    private tableView:UITableView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function(){
            this.addNewSerialSequence();
        })

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        })

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }  

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

     private updateUI(){
        if (this.viewIsLoaded == false) return;

        this.tableView.reloadData();
    }

    numberOfSections(tableview:UITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('SerialSequenceCell') as SerialSequenceCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as NumberSerialSequence;
        cell.item = item;
            
        return cell;
    }        

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
        return UITableViewCellEditingStyle.Delete;
    }
    
    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as NumberSerialSequence;

        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            DBHelper.deleteObjectFromMainContext(item, true);
        }
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let filterString = null; //'appID == null';
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('name', true)];
        var fetchRequest = DBHelper.listFetchRequestWithEntityName('NumberSerialSequence', sortDescriptors, filterString);
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    private addNewSerialSequence(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('SERIAL SEQUENCE','SERIAL SEQUENCE'), MIOLocalizeString('ADD NEW SERIAL SEQUENCE','ADD NEW SERIAL SEQUENCE'), MUIAlertViewStyle.Default);
        
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("PREFIX","PREFIX"));
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("ENTITY NAME","ENTITY NAME"));
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("ENTITY TYPE", "ENTITY TYPE"));
        });


        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','DONE'), MUIAlertActionStyle.Default, this, function(){
            
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

            //get main variables from view
            let name = avc.textFields[0].text.trim();
            let prefix = avc.textFields[1].text.trim();
            let entity_name = avc.textFields[2].text.trim();
            let entity_type = parseInt( avc.textFields[3].text );

            //check for a name and prefix 
            if(!name || !prefix) {
                //not valid, need name
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('PLEASE ENTER A VALID NAME AND PREFIX','PLEASE ENTER A VALID NAME AND PREFIX'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                this.presentViewController(error, true);
                return;
            }

            // create new serialSequence
            let newSerialSequence = MIOEntityDescription.insertNewObjectForEntityForName("NumberSerialSequence", moc) as NumberSerialSequence;
            newSerialSequence.identifier = MIOUUID.UUID().UUIDString;
            newSerialSequence.name = name;
            newSerialSequence.prefix = prefix;
            newSerialSequence.entityNameType = entity_name;
            newSerialSequence.entityType = entity_type;

            moc.save();

            this.updateUI();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Cancel, this, function(){}));
        
        this.presentViewController(avc, true);
    };
}