class ProductLanguageViewController extends MUIViewController
{
    static newInstance() : ProductLanguageViewController { 
        let vc = new ProductLanguageViewController("product-languages-view");
        vc.initWithResource("layout/products/ProductLanguagesView.html");
        return vc;
    }    

    private closeButton:MUIButton = null;
    private tableView:UITableView = null;

    modalSize = new MIOSize(400, 400); 

    get preferredContentSize(){
        return this.modalSize
    }

    viewDidLoad(){
        super.viewDidLoad();

        this.closeButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.closeButton.setAction(this, function(){
            DBHelper.saveMainContext();
            this.dismissViewController(true);
        });      

        this.tableView = MUIOutlet(this, "table-view", "UITableView");        
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.tableView.reloadData();
    }

    viewDidAppear(animated:boolean){
        super.viewDidAppear(animated);
        this.updateUI();
    }

    private _object:MIOManagedObject = null;
    private _key:string = null;
    setItem(object:MIOManagedObject, key:string) {
        this._object = object;
        this._key = key;

        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._object == null) return;

        this.fetchedResultsController = null;
        this.mapValues( this.fetchedResultsController.fetchedObjects as LocalizedValue[] );
        this.tableView.reloadData();
    }
    
    numberOfSections(tableView:UITableView){
        return 1;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        return this._languages_values != null ? AppHelper.sharedInstance().aditionalLanguages.length : 0;
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("LanguageTextFieldCell") as ProductLanguageCell;

        let lang = AppHelper.sharedInstance().aditionalLanguages[indexPath.row];
        cell.delegate = this;
        cell.language = lang;
        cell.setItem( this._languages_values[lang], this._object, this._key, lang );

        return cell;
    }

    protected _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value) {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        
        let entity_name = this._object.objectID.entity.name;
        let identifier = this._object.valueForKey("identifier") as string;
        let pf = "entityIdentifier == '" + identifier + "' AND entityTypeName == '" + entity_name + "' AND key == '" + this._key + "'";
        let fetchRequest = DBHelper.listFetchRequestWithEntityName( "LocalizedValue", null, pf );
       
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest( fetchRequest, ad.managedObjectContext, null );
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }
    
    private _languages_values = null;

    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        
        this.mapValues( controller.fetchedObjects as LocalizedValue[] );
        this.tableView.reloadData();
    }

    private mapValues( values:LocalizedValue[] ){
        this._languages_values = {};
        for (let v of values){
            this._languages_values[v.language] = v;
        }
    }

    localizedValueCellDidInsert( cell:ProductLanguageCell, localizedValue:LocalizedValue ) {
        this._languages_values[ localizedValue.language ] = localizedValue;
    }


}