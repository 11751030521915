
class TicketPreviewDataSource extends MIOObject
{
    private tableView:UITableView = null;
    initWithTableView(tableView:UITableView){
        this.tableView = tableView;
    }

    private lines = [];    
    set xml(xml){    
        let item = MIOKeyedUnarchiver.unarchiveTopLevelObjectWithData(xml);
        let report = item[0];
        let tables = report["tables"];
        let t = tables["ProductLinesTable"];
        let lines = t["rows"];        

        this.lines = [];
        for (let index = 0; index < lines.length; index++){
            let l = lines[index];
            if (l.length == 0) continue;
            let line = {};
            line["Title"] = l[0].text;
            line["Quantity"] = l[1].text;
            line["UnitPrice"] = l[2].text;
            line["Total"] = l[3].text;            

            this.lines.push(line);
        }

        //this.lines = _MIOSortDescriptorSortObjects(this.lines, [MIOSortDescriptor.sortDescriptorWithKey("Title", true)]);

        this.tableView.reloadData();
    }

    numberOfSections(tableView:MUITableView){
        return 1;
    }
    
    numberOfRowsInSection(tableView:MUITableView, section:number){
        return this.lines.count;
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let cell = tableView.dequeueReusableCellWithIdentifier("LineCell") as TicketLineCell;
        let item = this.lines[indexPath.row];

        cell.item = item;

        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        
    }

}


//
// MKReport dummy interfaces
//

class MRKReport extends MIOObject implements MIOCoding
{
    tables = null;
    initWithCoder(coder:MIOCoder){
        this.tables = coder.decodeObjectForKey("mTables");
    }
}

class MRKReportTable extends MIOObject implements MIOCoding
{
    identifier = null;
    columnTitles = null;
    rows = null;    

    initWithCoder(coder:MIOCoder){           
        this.identifier = coder.decodeObjectForKey("identifier");
        this.columnTitles = coder.decodeObjectForKey("columnTitles");
        this.rows = coder.decodeObjectForKey("mRows");
    }
}

class MRKReportTableCell extends MIOObject implements MIOCoding
{
    text = null;
    initWithCoder(coder:MIOCoder){
        this.text = coder.decodeObjectForKey("text");
    }
}