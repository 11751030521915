

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Preset_ManagedObject

class Preset_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Relationship: presetRelations
    protected _presetRelations:MIOManagedObjectSet = null;
    get presetRelations():MIOManagedObjectSet {
        return this.valueForKey('presetRelations');
    }
    addPresetRelationsObject(value:PresetRelation) {
        this._addObjectForKey(value, 'presetRelations');
    }
    removePresetRelationsObject(value:PresetRelation) {
        this._removeObjectForKey(value, 'presetRelations');
    }

    // Relationship: rootItems
    protected _rootItems:MIOManagedObjectSet = null;
    get rootItems():MIOManagedObjectSet {
        return this.valueForKey('rootItems');
    }
    addRootItemsObject(value:PresetItem) {
        this._addObjectForKey(value, 'rootItems');
    }
    removeRootItemsObject(value:PresetItem) {
        this._removeObjectForKey(value, 'rootItems');
    }
}
