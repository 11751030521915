
class LowStockCell extends UITableViewCell
{
    private titleLabel:MUILabel = null;
    private dateLabel:MUILabel = null;        
    
    awakeFromHTML(){
        this.dateLabel = MUIOutlet(this, 'date-lbl', 'MUILabel');
        this.titleLabel = MUIOutlet(this, 'title-lbl','MUILabel');                
    }

    set item(item:StockNote){                        
        let df = MUIWebApplication.sharedInstance().delegate.dateFormatter;
        this.dateLabel.text = df.stringFromDate(item.validationDate);
        this.titleLabel.text = item.documentID;
    }
}