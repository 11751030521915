//
// Generated class DBMappingEntityLocationCategory
//

/// <reference path="DBMappingEntityLocationCategory_ManagedObject.ts" />

class DBMappingEntityLocationCategory extends DBMappingEntityLocationCategory_ManagedObject
{

}
