/**
 * Created by miguel on 23/2/17.
 */


class LapsopayLocationCell extends MUITableViewCell
{
    nameLabel = null;
    lapsopayIDTextField = null;

    row = -1;
    section = -1;
    delegate = null;

    private _separator = null;

    awakeFromHTML()
    {
        this.nameLabel = MUIOutlet(this, "lpdv_tv_table_name_lbl", "MUILabel");
        this.lapsopayIDTextField = MUIOutlet(this, "lpdv_tv_table_id_text", "MUITextField");
        this.lapsopayIDTextField.setOnChangeText(this, function (textfield, value) {

            clearTimeout(this.delayTimer);
            var instance = this;

            instance.delayTimer = setTimeout(function() {
                if(instance.delegate != null)
                {
                    instance.delegate.cellTextDidChange(instance, textfield.text);
                }
            }, 1000, instance);
        });
        this._separator = MUIOutlet(this, "lpdv_tv_separator", "MUIView");
    }

    // setSelected(value)
    // {
    //     super.setSelected(value);
    //
    //     if (value == true)
    //         this._separator.setBackgroundRGBColor(0, 191, 191);
    //     else
    //         this._separator.setBackgroundRGBColor(237, 237, 242);
    // }

    set item(i)
    {
        this.nameLabel.text = i.name;
        this.lapsopayIDTextField.text = i.lapsopayId;
    }

}