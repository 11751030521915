//
// Generated class StockCoreAnnotation
//

/// <reference path="StockCoreAnnotation_ManagedObject.ts" />

class StockCoreAnnotation extends StockCoreAnnotation_ManagedObject
{

}
