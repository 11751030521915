/**
 * Created by miguel on 16/3/17.
 */

class ArchivedDocumentDetailCell extends UITableViewCell
{
    private imageView = null;
    private nameLabel:MUILabel = null;
    private taxLabel:MUILabel = null;
    private unitPriceLabel:MUILabel = null;
    private quantityLabel:MUILabel = null;
    private priceLabel:MUILabel = null;
	private discountLabel:MUILabel = null;
	private invitedLabel:MUILabel = null;

    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    
    awakeFromHTML(){
        this.imageView = MUIOutlet(this, "image-view", "MUIView");
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.taxLabel = MUIOutlet(this, 'tax-lbl','MUILabel');
        this.unitPriceLabel = MUIOutlet(this, 'unitary-price-lbl','MUILabel');
        this.priceLabel = MUIOutlet(this, "price-lbl", "MUILabel");
        this.discountLabel = MUIOutlet(this, "discount-lbl", "MUILabel");
		this.quantityLabel = MUIOutlet(this, 'quantity-lbl','MUILabel');
		this.invitedLabel = MUIOutlet(this, 'invited-lbl', 'MUILabel');
        
        this.separatorStyle = UITableViewCellSeparatorStyle.None;
        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    set item(i){

        let name = i["Prefix"] ? i["Prefix"] + i["Title"] : i["Title"];

        let bulkQ = i["BulkQuantity"];
        if(bulkQ)
        {
            name = name + " (" + bulkQ + ")";
        }
        this.nameLabel.text = name;

        this.taxLabel.text = i["TaxName"];
		this.unitPriceLabel.text = this.cf.stringFromNumber(i["UnitPrice"]);
		
        let discount = i["TotalDiscount"];
        if (discount > 0) this.discountLabel.text = this.nf.stringFromNumber(discount);
		else this.discountLabel.text = null;
		
		let invited = i['TotalInvited'];
		if (invited > 0) this.invitedLabel.text = this.cf.stringFromNumber(invited);
		else this.invitedLabel.text = null;
		            
        this.quantityLabel.text = this.nf.stringFromNumber(i["Quantity"]);
        this.priceLabel.text = this.cf.stringFromNumber(i["Total"]);

        if (i["Style"] == "title") {
            MUICoreLayerAddStyle(this.nameLabel.layer, "strong");
            MUICoreLayerAddStyle(this.taxLabel.layer, "strong");
            MUICoreLayerAddStyle(this.unitPriceLabel.layer, "strong");
            MUICoreLayerAddStyle(this.priceLabel.layer, "strong");
            MUICoreLayerAddStyle(this.discountLabel.layer, "strong");
            MUICoreLayerAddStyle(this.quantityLabel.layer, "strong");
            MUICoreLayerAddStyle(this.invitedLabel.layer, "strong");
        }
    }

}