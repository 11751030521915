

class BookingIntermediaryDetailViewController extends MUIViewController
{
    static newInstance():BookingIntermediaryDetailViewController {
        let vc = new BookingIntermediaryDetailViewController('booking-intermediary-detail-view');
        vc.initWithResource('layout/bookings/intermediary/BookingIntermediaryDetailView.html');        
        return vc;
    }

    private saveButton:MUIButton = null;
    private optionsButton:MUIButton = null;

    private nameTextField:MUITextField = null;
    private businessIDField:MUITextField = null;
    private emailTextField:MUITextField = null;
    private feeTextField:MUITextField = null;    
    private phoneTextField:MUITextField = null;

    private address1TextField:MUITextField = null;
    private cityTextField:MUITextField = null;
    private stateTextField:MUITextField = null;
    private postalCodeTextField:MUITextField = null;
    private countryDropDown:MUIButton = null;

    private tagView:TagView = null;

    private addAgentButton:MUIButton = null;
    private agentTableView:UITableView = null;

    viewDidLoad()
    {
        super.viewDidLoad();

        this.saveButton = MUIOutlet(this, 'save-btn', 'MUIButton');
        this.saveButton.setAction(this, this.saveAction);

        this.optionsButton = MUIOutlet(this, 'options-btn','MUIButton');
        //this.optionsButton.setHidden(true);
        this.optionsButton.setAction(this, this.showOptionsAction);

        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.businessIDField = MUIOutlet(this, "business-id-tf", "MUITextField");
        this.feeTextField = MUIOutlet(this, "fee-tf", "MUITextField");
        this.phoneTextField = MUIOutlet(this, "phone-tf", "MUITextField");
        this.emailTextField = MUIOutlet(this, "email-tf", "MUITextField");
    
        this.address1TextField = MUIOutlet(this, "address-tf", "MUITextField");
        this.cityTextField = MUIOutlet(this, "city-tf", "MUITextField");
        this.stateTextField = MUIOutlet(this, "state-tf", "MUITextField");
        this.postalCodeTextField = MUIOutlet(this, "postal-code-tf", "MUITextField");
        this.countryDropDown = MUIOutlet(this, "country-dd", "MUIButton");
        this.countryDropDown.setAction(this, function(){
            AppHelper.sharedInstance().showSelectCountryViewControllerFromView(this.countryDropDown, null, this, function(controller:SelectEntityViewController, country:Country){
                this.address.country = country.name;
                this.address.countryISOa2 = country.iso2;
                this.address.countryISOa3 = country.iso3;
                this.countryDropDown.title = MIOLocalizeString(country.name, country.name);
            });
        });   

        this.tagView = MUIOutlet(this, 'tag-view', 'TagView');
        this.tagView.delegate = this;

        this.addAgentButton = MUIOutlet(this, "add-contact-btn", "MUIButton");
        this.addAgentButton.setAction(this, this.addAgentAction);

        this.agentTableView = MUIOutlet(this, "agent-table-view", "UITableView");
        this.agentTableView.dataSource = this;
        this.agentTableView.delegate = this;
    }

    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private sourceTags = MIOSet.set();
    private _bookingSource:BookingSource = null;
    set bookingSource(value) {
        this._bookingSource = value;
        let array = value.tags == null ? [] : value.tags.split(',');
        for (let t of array) {
            this.sourceTags.addObject(t);
        }
        this.updateUI();
    }

    private updateUI()
    {
        if(!this._bookingSource || !this.viewIsLoaded) return;

        this.nameTextField.text = this._bookingSource.name;
        this.businessIDField.text = this._bookingSource.document;
        this.feeTextField.text = this._bookingSource.feeFormat;
        this.emailTextField.text = this._bookingSource.email;
        this.phoneTextField.text = this.phone.number;
        this.address1TextField.text = this.address.address1;
        this.cityTextField.text = this.address.city;
        this.postalCodeTextField.text = this.address.postalCode;
        this.stateTextField.text = this.address.state;
        this.countryDropDown.title = getCountryName( this.address.countryISOa2 );

        let predicate = MIOPredicate.predicateWithFormat('type = ' + TagType.BookingSource);
        DBHelper.queryObjectsWithCompletion("Tag", null, predicate, [], this, function(objects){
            this.reloadTags(objects);
        });                

        this.agentTableView.reloadData();
    }

    private saveAction(){
        this._bookingSource.name = this.nameTextField.text.trim();
        this._bookingSource.document = this.businessIDField.text.trim();
        this._bookingSource.email = this.emailTextField.text.trim();
        this._bookingSource.feeFormat = this.feeTextField.text.trim();
        this.phone.number = this.phoneTextField.text.trim();        
        this.address.address1 = this.address1TextField.text.trim();
        this.address.city = this.cityTextField.text.trim();
        this.address.postalCode = this.postalCodeTextField.text.trim();
        this.address.state = this.stateTextField.text.trim();
        

        DBHelper.saveMainContext();

    }

    get address(){
        if(this._bookingSource.address == null){
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            this._bookingSource.address = MIOEntityDescription.insertNewObjectForEntityForName("Address", ad.managedObjectContext) as Address;
            this._bookingSource.address.identifier = MIOUUID.UUID().UUIDString;
        }
        return this._bookingSource.address;
    }

    get phone(){
        if(this._bookingSource.phone == null){
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            this._bookingSource.phone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", ad.managedObjectContext) as PhoneNumber;
            this._bookingSource.phone.identifier = MIOUUID.UUID().UUIDString;
        }
        return this._bookingSource.phone;
    }

    private reloadTags(tags){
        this.tagView.removeAll();        
        for (let index = 0; index < tags.length; index++){
            let tag = tags[index] as Tag;
            this.tagView.addTag(tag.name);
            if (this.sourceTags.containsObject(tag.name)) {
                this.tagView.selectTagAtIndex(index);
            }
        }
    }

    didSelectTagAtIndex(index, name:string){                
        if (this.sourceTags.containsObject(name)){
            this.sourceTags.removeObject(name);
        }
        else {
            this.sourceTags.addObject(name);                        
        }

        this._bookingSource.tags = this.sourceTags.allObjects.join(',');
    }

    // AGENTS TABLE VIEW

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {
        let cell = tableView.dequeueReusableCellWithIdentifier("ContactCell") as ContactCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Contact;

        cell.item = item;

        return cell;

    }    

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {   
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {        

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            let obj = this.fetchedResultsController.objectAtIndexPath(indexPath);    
            DBHelper.deleteObjectFromMainContext(obj, true);
        }
    }  

    protected _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value:MIOFetchedResultsController){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController() : MIOFetchedResultsController {
        if (this._fetchedResultsController != null)
        return this._fetchedResultsController;

    let ad = MUIWebApplication.sharedInstance().delegate;

    let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];
    let predicateFormat = "legalEntity.identifier == " + this._bookingSource.identifier;
    let fetchRequest = DBHelper.listFetchRequestWithEntityName("Contact", sortDescriptors, predicateFormat);
    // fetchRequest.relationshipKeyPathsForPrefetching = [""];
    
    let fetchedResultsController = new MIOFetchedResultsController();
    fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
    fetchedResultsController.delegate = this;

    fetchedResultsController.performFetch();

    this._fetchedResultsController = fetchedResultsController;    
    return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        this.agentTableView.reloadData();
    }

    private addAgentAction(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ADD CONTACT", "ADD CONTACT"), MIOLocalizeString("TYPE THE NAME", "TYPE THE NAME"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
            textField.placeHolder = MIOLocalizeString("NAME", "NAME");
        });
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Default, this, function(){
            let name = avc.textFields[0].text.trim();
            this.addAgent(name);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private addAgent(name:string){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let agent = MIOEntityDescription.insertNewObjectForEntityForName("Contact", ad.managedObjectContext) as Contact;
        agent.identifier = MIOUUID.UUID().UUIDString;
        agent.name = name;
        agent.legalEntity = this._bookingSource;

        DBHelper.saveMainContext();
    }

    private showOptionsAction(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('ACTIONS','ACITONS'), MIOLocalizeString('OPTIONS','OPTIONS'), MUIAlertViewStyle.Default);
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("DELETE", "DELETE"), MUIAlertActionStyle.Destructive, this, function(this:BookingIntermediaryDetailViewController){            
            DBHelper.deleteObjectFromMainContext(this._bookingSource, true);
        }));
 
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "cancel"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);

    }
}
