
/// <reference path="BaseViewController.ts" />


class BaseTableViewController extends BaseViewController
{
    protected searchTextField:MUITextField = null;
    protected tableView:UITableView = null;
    
    protected searchString:string = null;
    private searchTimer:MIOTimer = null;

    protected selectedItem = null;

    //
    // Search textfield setup
    //

    protected setupSearchTextField(){
        if (this.searchTextField == null) return;

        this.searchTextField.setOnChangeText(this, function(textfield:string, value:string){            
            this.searchString = value.length > 0 ? value : null;
            if (this.searchTimer != null) this.searchTimer.invalidate();

            this.searchTimer = MIOTimer.scheduledTimerWithTimeInterval(500, false, this, function(timer:MIOTimer){
                this.searchTimer = null;
                this.invalidateFetch();
            });
        });         
    }

    //
    // Tableview delegate
    //

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {
        return null;
    }    

    protected invalidateData(){
        this.selectedItem = null;
        this.searchString = null;
        this.setFetchedResultsController(null);
        super.invalidateData();
    }    

    protected reloadData(){
        super.reloadData();
        this.tableView.reloadData();
    }

}   