
enum LegalEntityDefaultSelectionType
{    
    client = 0,
    supplier = 1,
    intermediary = 2,
}

class LegalEntitySelectionViewController extends EntityListViewController
{
    static newInstance() : LegalEntitySelectionViewController {
        let vc = new LegalEntitySelectionViewController("legal-entity-selection-view");
        vc.initWithResource("layout/base/LegalEntitySelectionView.html");

        return vc;
    }

    defaultSelectionType = LegalEntityDefaultSelectionType.client;
    completionBlock:any = null;

    private closeButton:MUIButton = null;
    private segmentedControl:MUISegmentedControl = null;
    private addButton:MUIButton = null;

    get preferredContentSize() {
        return new MIOSize(500, 500);
    }

    viewDidLoad(): void 
    {
        this.closeButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.closeButton.setAction(this, function(this:LegalEntitySelectionViewController){
            this.dismissViewController(true);
        });

        this.segmentedControl = MUIOutlet(this, "segmented-control", "MUISegmentedControl");
        this.segmentedControl.setAction(this, function (this:LegalEntitySelectionViewController, control:MUISegmentedControl, index:number) {
            this.defaultSelectionType = index;
            this.setFetchedResultsController( null );
            this.tableView.reloadData();
        });

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function(this:LegalEntitySelectionViewController) {
            let vc = AddNewSupplierViewController.newInstance();
            this.presentViewController(vc, true);
        });

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();
        
        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.tableView.reloadData();
    }

    viewWillAppear(animated?: boolean): void {
        super.viewWillAppear(animated);        

        this.segmentedControl.selectSegmentedAtIndex( this.defaultSelectionType );
    }
    
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath) {        
        let cell = tableview.dequeueReusableCellWithIdentifier('SelectionCell') as SelectCell;
        cell.accessoryType = UITableViewCellAccessoryType.None;

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        cell.title = item.valueForKey("name");        
        cell.selected = false;

        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {
            
        if (this.completionBlock) {
            let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
            this.completionBlock(item);
        }
        this.dismissViewController(true);
    }
    
    
    get fetchedResultsController() {
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        let entity = null;
        switch( this.defaultSelectionType) {
            case 0: entity = "Client"; break;
            case 1: entity = "Supplier"; break;
            case 2: entity = "Intermediary"; break;
        }

        let predicateFormat = this.searchString != null ? "name contains '" + this.searchString + "'" : null;

        let fetch = DBHelper.listFetchRequestWithEntityName( entity, [MIOSortDescriptor.sortDescriptorWithKey("name", true)], predicateFormat );
        fetch.relationshipKeyPathsForPrefetching = ["legalEntityTax"];
        fetch.fetchLimit = 100;

        let frc = new MIOFetchedResultsController();            
        frc.initWithFetchRequest(fetch, ad.managedObjectContext, null);
        frc.delegate = this;            

        frc.performFetch();

        this._fetchedResultsController = frc;
        return frc;
    }
}