

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ProductImage_ManagedObject

class ProductImage_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: image
    set image(value:string) {
        this.setValueForKey(value, 'image');
    }
    get image():string {
        return this.valueForKey('image');
    }
    set imagePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'image');
    }
    get imagePrimitiveValue():string {
        return this.primitiveValueForKey('image');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
}
