

/// <reference path="Printer.ts" />

// Generated class TCPPrinter_ManagedObject

class TCPPrinter_ManagedObject extends Printer
{

    // Property: host
    set host(value:string) {
        this.setValueForKey(value, 'host');
    }
    get host():string {
        return this.valueForKey('host');
    }
    set hostPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'host');
    }
    get hostPrimitiveValue():string {
        return this.primitiveValueForKey('host');
    }

    // Property: port
    set port(value:number) {
        this.setValueForKey(value, 'port');
    }
    get port():number {
        return this.valueForKey('port');
    }
    set portPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'port');
    }
    get portPrimitiveValue():number {
        return this.primitiveValueForKey('port');
    }
}
