class ProductInputFormatStockCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;
    private barcodeTextField:MUITextField = null;
    private quantityTextField:MUITextField = null;

    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter as MIONumberFormatter;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.barcodeTextField = MUIOutlet(this, "barcode-tf", "MUITextField");
        this.barcodeTextField.setOnChangeText(this, function(control, value){
            this.inputFormat.barcode = value;
        });
        this.quantityTextField = MUIOutlet(this, "input-format-quantity-tf", "MUITextField");
        this.quantityTextField.formatter = this.nf;
        this.quantityTextField.setOnChangeText(this, function(control, value){
            let q = this.nf.numberFromString(value);
            this.inputFormat.quantity = q;
        });

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    private inputFormat:StockInputFormat = null;
    set item(inputFormat:StockInputFormat){
        this.inputFormat = inputFormat;        

        this.nameLabel.text = inputFormat.name;
        this.barcodeTextField.text = inputFormat.barcode;
        this.quantityTextField.text = this.nf.stringFromNumber(inputFormat.quantity);
    }
}