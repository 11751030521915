

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ManagerStockAnnotation_ManagedObject

class ManagerStockAnnotation_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: cashDeskID
    set cashDeskID(value:string) {
        this.setValueForKey(value, 'cashDeskID');
    }
    get cashDeskID():string {
        return this.valueForKey('cashDeskID');
    }
    set cashDeskIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'cashDeskID');
    }
    get cashDeskIDPrimitiveValue():string {
        return this.primitiveValueForKey('cashDeskID');
    }

    // Property: cashDeskName
    set cashDeskName(value:string) {
        this.setValueForKey(value, 'cashDeskName');
    }
    get cashDeskName():string {
        return this.valueForKey('cashDeskName');
    }
    set cashDeskNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'cashDeskName');
    }
    get cashDeskNamePrimitiveValue():string {
        return this.primitiveValueForKey('cashDeskName');
    }

    // Property: concept
    set concept(value:string) {
        this.setValueForKey(value, 'concept');
    }
    get concept():string {
        return this.valueForKey('concept');
    }
    set conceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'concept');
    }
    get conceptPrimitiveValue():string {
        return this.primitiveValueForKey('concept');
    }

    // Property: consumptionMeasureType
    set consumptionMeasureType(value:number) {
        this.setValueForKey(value, 'consumptionMeasureType');
    }
    get consumptionMeasureType():number {
        return this.valueForKey('consumptionMeasureType');
    }
    set consumptionMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'consumptionMeasureType');
    }
    get consumptionMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('consumptionMeasureType');
    }

    // Property: consumptionQuantity
    set consumptionQuantity(value:number) {
        this.setValueForKey(value, 'consumptionQuantity');
    }
    get consumptionQuantity():number {
        return this.valueForKey('consumptionQuantity');
    }
    set consumptionQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'consumptionQuantity');
    }
    get consumptionQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('consumptionQuantity');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: destinationName
    set destinationName(value:string) {
        this.setValueForKey(value, 'destinationName');
    }
    get destinationName():string {
        return this.valueForKey('destinationName');
    }
    set destinationNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'destinationName');
    }
    get destinationNamePrimitiveValue():string {
        return this.primitiveValueForKey('destinationName');
    }

    // Property: factor
    set factor(value:number) {
        this.setValueForKey(value, 'factor');
    }
    get factor():number {
        return this.valueForKey('factor');
    }
    set factorPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'factor');
    }
    get factorPrimitiveValue():number {
        return this.primitiveValueForKey('factor');
    }

    // Property: formatID
    set formatID(value:string) {
        this.setValueForKey(value, 'formatID');
    }
    get formatID():string {
        return this.valueForKey('formatID');
    }
    set formatIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'formatID');
    }
    get formatIDPrimitiveValue():string {
        return this.primitiveValueForKey('formatID');
    }

    // Property: formatName
    set formatName(value:string) {
        this.setValueForKey(value, 'formatName');
    }
    get formatName():string {
        return this.valueForKey('formatName');
    }
    set formatNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'formatName');
    }
    get formatNamePrimitiveValue():string {
        return this.primitiveValueForKey('formatName');
    }

    // Property: indentLevel
    set indentLevel(value:number) {
        this.setValueForKey(value, 'indentLevel');
    }
    get indentLevel():number {
        return this.valueForKey('indentLevel');
    }
    set indentLevelPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'indentLevel');
    }
    get indentLevelPrimitiveValue():number {
        return this.primitiveValueForKey('indentLevel');
    }

    // Property: locationCategoryID
    set locationCategoryID(value:string) {
        this.setValueForKey(value, 'locationCategoryID');
    }
    get locationCategoryID():string {
        return this.valueForKey('locationCategoryID');
    }
    set locationCategoryIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationCategoryID');
    }
    get locationCategoryIDPrimitiveValue():string {
        return this.primitiveValueForKey('locationCategoryID');
    }

    // Property: locationCategoryName
    set locationCategoryName(value:string) {
        this.setValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryName():string {
        return this.valueForKey('locationCategoryName');
    }
    set locationCategoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationCategoryName');
    }

    // Property: lossMeasureType
    set lossMeasureType(value:number) {
        this.setValueForKey(value, 'lossMeasureType');
    }
    get lossMeasureType():number {
        return this.valueForKey('lossMeasureType');
    }
    set lossMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lossMeasureType');
    }
    get lossMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('lossMeasureType');
    }

    // Property: lossQuantity
    set lossQuantity(value:number) {
        this.setValueForKey(value, 'lossQuantity');
    }
    get lossQuantity():number {
        return this.valueForKey('lossQuantity');
    }
    set lossQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lossQuantity');
    }
    get lossQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('lossQuantity');
    }

    // Property: menuID
    set menuID(value:string) {
        this.setValueForKey(value, 'menuID');
    }
    get menuID():string {
        return this.valueForKey('menuID');
    }
    set menuIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'menuID');
    }
    get menuIDPrimitiveValue():string {
        return this.primitiveValueForKey('menuID');
    }

    // Property: menuName
    set menuName(value:string) {
        this.setValueForKey(value, 'menuName');
    }
    get menuName():string {
        return this.valueForKey('menuName');
    }
    set menuNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'menuName');
    }
    get menuNamePrimitiveValue():string {
        return this.primitiveValueForKey('menuName');
    }

    // Property: minMeasureInputQuantity
    set minMeasureInputQuantity(value:number) {
        this.setValueForKey(value, 'minMeasureInputQuantity');
    }
    get minMeasureInputQuantity():number {
        return this.valueForKey('minMeasureInputQuantity');
    }
    set minMeasureInputQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minMeasureInputQuantity');
    }
    get minMeasureInputQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('minMeasureInputQuantity');
    }

    // Property: minMeasureOutputQuantity
    set minMeasureOutputQuantity(value:number) {
        this.setValueForKey(value, 'minMeasureOutputQuantity');
    }
    get minMeasureOutputQuantity():number {
        return this.valueForKey('minMeasureOutputQuantity');
    }
    set minMeasureOutputQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minMeasureOutputQuantity');
    }
    get minMeasureOutputQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('minMeasureOutputQuantity');
    }

    // Property: minMeasureQuantity
    set minMeasureQuantity(value:number) {
        this.setValueForKey(value, 'minMeasureQuantity');
    }
    get minMeasureQuantity():number {
        return this.valueForKey('minMeasureQuantity');
    }
    set minMeasureQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minMeasureQuantity');
    }
    get minMeasureQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('minMeasureQuantity');
    }

    // Property: minMeasureType
    set minMeasureType(value:number) {
        this.setValueForKey(value, 'minMeasureType');
    }
    get minMeasureType():number {
        return this.valueForKey('minMeasureType');
    }
    set minMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minMeasureType');
    }
    get minMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('minMeasureType');
    }

    // Property: originName
    set originName(value:string) {
        this.setValueForKey(value, 'originName');
    }
    get originName():string {
        return this.valueForKey('originName');
    }
    set originNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'originName');
    }
    get originNamePrimitiveValue():string {
        return this.primitiveValueForKey('originName');
    }

    // Property: productCategoryID
    set productCategoryID(value:string) {
        this.setValueForKey(value, 'productCategoryID');
    }
    get productCategoryID():string {
        return this.valueForKey('productCategoryID');
    }
    set productCategoryIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productCategoryID');
    }
    get productCategoryIDPrimitiveValue():string {
        return this.primitiveValueForKey('productCategoryID');
    }

    // Property: productCategoryName
    set productCategoryName(value:string) {
        this.setValueForKey(value, 'productCategoryName');
    }
    get productCategoryName():string {
        return this.valueForKey('productCategoryName');
    }
    set productCategoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productCategoryName');
    }
    get productCategoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('productCategoryName');
    }

    // Property: productCategoryTags
    set productCategoryTags(value:string) {
        this.setValueForKey(value, 'productCategoryTags');
    }
    get productCategoryTags():string {
        return this.valueForKey('productCategoryTags');
    }
    set productCategoryTagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productCategoryTags');
    }
    get productCategoryTagsPrimitiveValue():string {
        return this.primitiveValueForKey('productCategoryTags');
    }

    // Property: productElaborationType
    set productElaborationType(value:number) {
        this.setValueForKey(value, 'productElaborationType');
    }
    get productElaborationType():number {
        return this.valueForKey('productElaborationType');
    }
    set productElaborationTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productElaborationType');
    }
    get productElaborationTypePrimitiveValue():number {
        return this.primitiveValueForKey('productElaborationType');
    }

    // Property: productID
    set productID(value:string) {
        this.setValueForKey(value, 'productID');
    }
    get productID():string {
        return this.valueForKey('productID');
    }
    set productIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productID');
    }
    get productIDPrimitiveValue():string {
        return this.primitiveValueForKey('productID');
    }

    // Property: productMesureType
    set productMesureType(value:number) {
        this.setValueForKey(value, 'productMesureType');
    }
    get productMesureType():number {
        return this.valueForKey('productMesureType');
    }
    set productMesureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productMesureType');
    }
    get productMesureTypePrimitiveValue():number {
        return this.primitiveValueForKey('productMesureType');
    }

    // Property: productName
    set productName(value:string) {
        this.setValueForKey(value, 'productName');
    }
    get productName():string {
        return this.valueForKey('productName');
    }
    set productNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productName');
    }
    get productNamePrimitiveValue():string {
        return this.primitiveValueForKey('productName');
    }

    // Property: productQuantity
    set productQuantity(value:number) {
        this.setValueForKey(value, 'productQuantity');
    }
    get productQuantity():number {
        return this.valueForKey('productQuantity');
    }
    set productQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productQuantity');
    }
    get productQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('productQuantity');
    }

    // Property: productTags
    set productTags(value:string) {
        this.setValueForKey(value, 'productTags');
    }
    get productTags():string {
        return this.valueForKey('productTags');
    }
    set productTagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productTags');
    }
    get productTagsPrimitiveValue():string {
        return this.primitiveValueForKey('productTags');
    }

    // Property: sessionDate
    set sessionDate(value:Date) {
        this.setValueForKey(value, 'sessionDate');
    }
    get sessionDate():Date {
        return this.valueForKey('sessionDate');
    }
    set sessionDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'sessionDate');
    }
    get sessionDatePrimitiveValue():Date {
        return this.primitiveValueForKey('sessionDate');
    }

    // Property: sourceDocumentID
    set sourceDocumentID(value:string) {
        this.setValueForKey(value, 'sourceDocumentID');
    }
    get sourceDocumentID():string {
        return this.valueForKey('sourceDocumentID');
    }
    set sourceDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceDocumentID');
    }
    get sourceDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('sourceDocumentID');
    }

    // Property: sourceDocumentLineID
    set sourceDocumentLineID(value:string) {
        this.setValueForKey(value, 'sourceDocumentLineID');
    }
    get sourceDocumentLineID():string {
        return this.valueForKey('sourceDocumentLineID');
    }
    set sourceDocumentLineIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceDocumentLineID');
    }
    get sourceDocumentLineIDPrimitiveValue():string {
        return this.primitiveValueForKey('sourceDocumentLineID');
    }

    // Property: sourceDocumentReference
    set sourceDocumentReference(value:string) {
        this.setValueForKey(value, 'sourceDocumentReference');
    }
    get sourceDocumentReference():string {
        return this.valueForKey('sourceDocumentReference');
    }
    set sourceDocumentReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceDocumentReference');
    }
    get sourceDocumentReferencePrimitiveValue():string {
        return this.primitiveValueForKey('sourceDocumentReference');
    }

    // Property: subtype
    set subtype(value:number) {
        this.setValueForKey(value, 'subtype');
    }
    get subtype():number {
        return this.valueForKey('subtype');
    }
    set subtypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'subtype');
    }
    get subtypePrimitiveValue():number {
        return this.primitiveValueForKey('subtype');
    }

    // Property: totalMeasureType
    set totalMeasureType(value:number) {
        this.setValueForKey(value, 'totalMeasureType');
    }
    get totalMeasureType():number {
        return this.valueForKey('totalMeasureType');
    }
    set totalMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalMeasureType');
    }
    get totalMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('totalMeasureType');
    }

    // Property: totalQuantity
    set totalQuantity(value:number) {
        this.setValueForKey(value, 'totalQuantity');
    }
    get totalQuantity():number {
        return this.valueForKey('totalQuantity');
    }
    set totalQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalQuantity');
    }
    get totalQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('totalQuantity');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: warehouseID
    set warehouseID(value:string) {
        this.setValueForKey(value, 'warehouseID');
    }
    get warehouseID():string {
        return this.valueForKey('warehouseID');
    }
    set warehouseIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'warehouseID');
    }
    get warehouseIDPrimitiveValue():string {
        return this.primitiveValueForKey('warehouseID');
    }

    // Property: warehouseName
    set warehouseName(value:string) {
        this.setValueForKey(value, 'warehouseName');
    }
    get warehouseName():string {
        return this.valueForKey('warehouseName');
    }
    set warehouseNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'warehouseName');
    }
    get warehouseNamePrimitiveValue():string {
        return this.primitiveValueForKey('warehouseName');
    }

    // Property: workerID
    set workerID(value:string) {
        this.setValueForKey(value, 'workerID');
    }
    get workerID():string {
        return this.valueForKey('workerID');
    }
    set workerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerID');
    }
    get workerIDPrimitiveValue():string {
        return this.primitiveValueForKey('workerID');
    }

    // Property: workerName
    set workerName(value:string) {
        this.setValueForKey(value, 'workerName');
    }
    get workerName():string {
        return this.valueForKey('workerName');
    }
    set workerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerName');
    }
    get workerNamePrimitiveValue():string {
        return this.primitiveValueForKey('workerName');
    }
    // Relationship: parent
    set parent(value:ManagerStockAnnotation) {
        this.setValueForKey(value, 'parent');
    }
    get parent():ManagerStockAnnotation {
        return this.valueForKey('parent') as ManagerStockAnnotation;
    }

    // Relationship: subLines
    protected _subLines:MIOManagedObjectSet = null;
    get subLines():MIOManagedObjectSet {
        return this.valueForKey('subLines');
    }
    addSubLinesObject(value:ManagerStockAnnotation) {
        this._addObjectForKey(value, 'subLines');
    }
    removeSubLinesObject(value:ManagerStockAnnotation) {
        this._removeObjectForKey(value, 'subLines');
    }
}
