


class CashDeskSessionSummaryCell extends MUITableViewCell
{
    inputTypeLabel:MUILabel = null;
    cashLabel:MUILabel = null;
    iconView:MUIView = null;    

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter; 
    
    awakeFromHTML(){
        this.inputTypeLabel = MUIOutlet(this, 'cdssv_cdc2_type_lbl', 'MUILabel');
        this.cashLabel = MUIOutlet(this, 'cdssv_cdc2_total_lbl', 'MUILabel');
        // this.iconView = MUIOutlet(this, 'cdssv_coin_type_icon','MUIView');
    }
    
    
    set item(i){
        this.inputTypeLabel.text = MIOLocalizeString(i.title, i.title);
        
        var quantity ='';
        if(i.isCurrency == false)
           quantity = i.value; 
        else if(i.value != null || i.value != '')
          quantity= (this.cf.stringFromNumber(i.value)|| '0');

        this.cashLabel.text =  quantity
        // this.iconView.setHidden(i.section != 0 || (i.isTotal == true));
    }
}
