

/// <reference path="CoreEntity.ts" />

// Generated class IntegratorSyncEntityQueue_ManagedObject

class IntegratorSyncEntityQueue_ManagedObject extends CoreEntity
{

    // Property: integratorConcept
    set integratorConcept(value:string) {
        this.setValueForKey(value, 'integratorConcept');
    }
    get integratorConcept():string {
        return this.valueForKey('integratorConcept');
    }
    set integratorConceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'integratorConcept');
    }
    get integratorConceptPrimitiveValue():string {
        return this.primitiveValueForKey('integratorConcept');
    }

    // Property: integratorDate
    set integratorDate(value:Date) {
        this.setValueForKey(value, 'integratorDate');
    }
    get integratorDate():Date {
        return this.valueForKey('integratorDate');
    }
    set integratorDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'integratorDate');
    }
    get integratorDatePrimitiveValue():Date {
        return this.primitiveValueForKey('integratorDate');
    }

    // Property: integratorError
    set integratorError(value:string) {
        this.setValueForKey(value, 'integratorError');
    }
    get integratorError():string {
        return this.valueForKey('integratorError');
    }
    set integratorErrorPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'integratorError');
    }
    get integratorErrorPrimitiveValue():string {
        return this.primitiveValueForKey('integratorError');
    }

    // Property: integratorReferenceConcept
    set integratorReferenceConcept(value:string) {
        this.setValueForKey(value, 'integratorReferenceConcept');
    }
    get integratorReferenceConcept():string {
        return this.valueForKey('integratorReferenceConcept');
    }
    set integratorReferenceConceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'integratorReferenceConcept');
    }
    get integratorReferenceConceptPrimitiveValue():string {
        return this.primitiveValueForKey('integratorReferenceConcept');
    }

    // Property: integratorReferenceDocumentID
    set integratorReferenceDocumentID(value:string) {
        this.setValueForKey(value, 'integratorReferenceDocumentID');
    }
    get integratorReferenceDocumentID():string {
        return this.valueForKey('integratorReferenceDocumentID');
    }
    set integratorReferenceDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'integratorReferenceDocumentID');
    }
    get integratorReferenceDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('integratorReferenceDocumentID');
    }

    // Property: integratorReferenceID
    set integratorReferenceID(value:string) {
        this.setValueForKey(value, 'integratorReferenceID');
    }
    get integratorReferenceID():string {
        return this.valueForKey('integratorReferenceID');
    }
    set integratorReferenceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'integratorReferenceID');
    }
    get integratorReferenceIDPrimitiveValue():string {
        return this.primitiveValueForKey('integratorReferenceID');
    }

    // Property: integratorStatus
    set integratorStatus(value:number) {
        this.setValueForKey(value, 'integratorStatus');
    }
    get integratorStatus():number {
        return this.valueForKey('integratorStatus');
    }
    set integratorStatusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'integratorStatus');
    }
    get integratorStatusPrimitiveValue():number {
        return this.primitiveValueForKey('integratorStatus');
    }

    // Property: integratorSyncDate
    set integratorSyncDate(value:Date) {
        this.setValueForKey(value, 'integratorSyncDate');
    }
    get integratorSyncDate():Date {
        return this.valueForKey('integratorSyncDate');
    }
    set integratorSyncDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'integratorSyncDate');
    }
    get integratorSyncDatePrimitiveValue():Date {
        return this.primitiveValueForKey('integratorSyncDate');
    }

    // Property: integratorTransactionID
    set integratorTransactionID(value:string) {
        this.setValueForKey(value, 'integratorTransactionID');
    }
    get integratorTransactionID():string {
        return this.valueForKey('integratorTransactionID');
    }
    set integratorTransactionIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'integratorTransactionID');
    }
    get integratorTransactionIDPrimitiveValue():string {
        return this.primitiveValueForKey('integratorTransactionID');
    }

    // Property: lines
    set lines(value:string) {
        this.setValueForKey(value, 'lines');
    }
    get lines():string {
        return this.valueForKey('lines');
    }
    set linesPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'lines');
    }
    get linesPrimitiveValue():string {
        return this.primitiveValueForKey('lines');
    }

    // Property: responseRaw
    set responseRaw(value:string) {
        this.setValueForKey(value, 'responseRaw');
    }
    get responseRaw():string {
        return this.valueForKey('responseRaw');
    }
    set responseRawPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'responseRaw');
    }
    get responseRawPrimitiveValue():string {
        return this.primitiveValueForKey('responseRaw');
    }

    // Property: responseReference
    set responseReference(value:string) {
        this.setValueForKey(value, 'responseReference');
    }
    get responseReference():string {
        return this.valueForKey('responseReference');
    }
    set responseReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'responseReference');
    }
    get responseReferencePrimitiveValue():string {
        return this.primitiveValueForKey('responseReference');
    }
    // Relationship: integratorConfig
    set integratorConfig(value:IntegratorConfig) {
        this.setValueForKey(value, 'integratorConfig');
    }
    get integratorConfig():IntegratorConfig {
        return this.valueForKey('integratorConfig') as IntegratorConfig;
    }
    // Relationship: integratorSync
    set integratorSync(value:IntegratorSync) {
        this.setValueForKey(value, 'integratorSync');
    }
    get integratorSync():IntegratorSync {
        return this.valueForKey('integratorSync') as IntegratorSync;
    }
}
