

class CashDeskCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    private totalLabel:MUILabel = null;    

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, 'title-lbl', 'MUILabel');
        this.totalLabel = MUIOutlet(this, 'total-lbl', 'MUILabel');  
        
        // this.selectionStyle = UITableViewCellSelectionStyle.Default;
    }

    set item(cdb:CashDeskBalance){
        this.nameLabel.text = cdb.cashdesk.name;
        this.totalLabel.text = this.cf.stringFromNumber(cdb.salesAmount);
    }

    set item2(item2:any){
        this.nameLabel.text = item2["Name"];
        this.totalLabel.text = this.cf.stringFromNumber(item2["Total"]);
    }

    setCustomData(name:string, amount:number){
        this.nameLabel.text = name;
        this.totalLabel.text = this.cf.stringFromNumber(amount);
    }
}
