//
// Generated class TaxMenuAnnotation
//

/// <reference path="TaxMenuAnnotation_ManagedObject.ts" />

class TaxMenuAnnotation extends TaxMenuAnnotation_ManagedObject
{

}
