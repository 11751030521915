//
// Generated class ManagerSection
//

/// <reference path="ManagerSection_ManagedObject.ts" />

class ManagerSection extends ManagerSection_ManagedObject
{

}
