//
// Generated class Configuration
//

/// <reference path="Configuration_ManagedObject.ts" />

enum ConfigurationValueType
{
    String = 0, 
    Boolean = 1,
    Number = 2,
    JSON = 3,
    XML = 4,
    Entity = 5
}

class Configuration extends Configuration_ManagedObject
{
    get stringValue():string {
        let stringValue = this.removeQuotes(this.value);
        return stringValue;
    }

    set stringValue(value:string) {
        this.value = this.addQuotes(value);
    }

    get boolValue():boolean {
        return this.value == "true";
    }

    set boolValue(value:boolean) {
        this.value = value ? "true" : "false";
    }

    private removeQuotes(value) {        
        // return value.replace(/['"]+/g, '');
        return value;
    }

    private addQuotes(value) {
        // return '"' + value + '"';
        return value;
    }
}
