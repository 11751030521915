//
// Generated class PresetCategory
//

/// <reference path="PresetCategory_ManagedObject.ts" />

class PresetCategory extends PresetCategory_ManagedObject
{

}
