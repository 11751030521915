class SearchBookingCell extends MUITableViewCell
{
    private dateLabel:MUILabel = null;
    private statusLabel:MUILabel = null;
    private paxLabel:MUILabel = null;

    private df = MUIWebApplication.sharedInstance().delegate.dateFormatter;

    awakeFromHTML(){
        this.dateLabel = MUIOutlet(this, 'date-lbl', 'MUILabel');
        this.statusLabel = MUIOutlet(this, 'status-lbl', 'MUILabel');
        this.paxLabel = MUIOutlet(this, 'pax-lbl','MUILabel');
    }

    set item(i){
        this.dateLabel.text = this.df.stringFromDate(i.date);
        this.paxLabel.text = i.pax;
        this.statusLabel.text = i.statusString;
    }
}
