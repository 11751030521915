
enum ClientDocumentViewControllerType {
    Tickets,
    Invoices
}


class ClientDocumentViewController extends MUIViewController
{
    type = ClientDocumentViewControllerType.Tickets;

	private documentIDFilterTextField:ColumnFilterTextField = null;
	private dateFilterTextField:ColumnFilterTextField = null;
	private paxFilterTextField:ColumnFilterTextField = null;
	private locationFilterTextField:ColumnFilterTextField = null;
	private amountFilterTextField:ColumnFilterTextField = null;

	private tableView:MUITableView = null;    
	
    private columnFilterController:ColumnFilterController = null;
    
    viewDidLoad(){
        super.viewDidLoad();

        this.columnFilterController = new ColumnFilterController();
		this.columnFilterController.initWithDelegate(this);
		
		this.documentIDFilterTextField = MUIOutlet(this, "ticket-cf-tf", "ColumnFilterTextField");
        this.documentIDFilterTextField.filterController = this.columnFilterController;
		this.documentIDFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "legalDocumentID", null, null);
		
		this.dateFilterTextField = MUIOutlet(this, "date-cf-tf", "ColumnFilterTextField");
        this.dateFilterTextField.filterController = this.columnFilterController;
		this.dateFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "date", null, null);
		
		this.locationFilterTextField = MUIOutlet(this, "location-cf-tf", "ColumnFilterTextField");
        this.locationFilterTextField.filterController = this.columnFilterController;
		this.locationFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "locationCategoryName", null, null);

		this.paxFilterTextField = MUIOutlet(this, "pax-cf-tf", "ColumnFilterTextField");
        this.paxFilterTextField.filterController = this.columnFilterController;        
		this.paxFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "numberOfPeople", null, null);

		this.amountFilterTextField = MUIOutlet(this, "amount-cf-tf", "ColumnFilterTextField");
        this.amountFilterTextField.filterController = this.columnFilterController;
		this.amountFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Currency, "totalPrice", null, null);
		
        this.tableView = MUIOutlet(this, 'table-view', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewDidAppear(animate?){
        super.viewDidAppear(animate);
        this.updateUI();
    }

    private _client:Client = null;
    set client(value){
        this._client = value;
        this.updateUI();
    }

    private updateUI(){
        if(this.viewIsLoaded == false || this._client == null) return;
        
        this.fetchedResultsController = null;
        this.tableView.reloadData();        
    }

    numberOfSections(tableview:MUITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview:MUITableView, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("DocumentCell") as ClientDocumentCell;
    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ArchivedDocument;
    
        cell.item = item;
        cell.delegate = this;
      
        return cell;
    }
    
    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        let archivedDocument = this.fetchedResultsController.objectAtIndexPath(indexPath) as ArchivedDocument;

        // let ad = MUIWebApplication.sharedInstance().delegate;
        // let ws = ad.webService;

        // ws.getTicketByDocumentId(ad.selectedPlace, archivedDocument.documentID, this, function(code, json, ticket:Ticket){
        //    if(code == 200) 
        //    {
        //       this.ticketDetailViewController.ticket = ticket;
        //       this.navigationController.pushViewController(this.ticketDetailViewController, true);
        //    }
        //    else{
        //        AppHelper.showErrorMessage(this, 'Error', 'Cant find ticket!');
        //    }
        // }, ad.managedObjectContext);
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('date', true)];
        
        let entityName = "";
        let predicateFormat = "";
        if (this.type == ClientDocumentViewControllerType.Tickets) {
            entityName = "ArchivedTicket";
            predicateFormat += "linkedLegalEntityID == " + this._client.identifier;    
        } 
        else if (this.type == ClientDocumentViewControllerType.Invoices) {
            entityName = "ArchivedInvoice";
            predicateFormat += "invoiceLegalEntityID == " + this._client.identifier;
        }                		
		
		//  Column filter
		let filterFormat = this.columnFilterController.filterPredicateFormat();
		if (filterFormat != null) {
			predicateFormat += " AND (" + filterFormat + ")";
		}

        let fetchRequest = DBHelper.listFetchRequestWithEntityName(entityName, sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["nextDocument"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }
	
	filterPredicateDidChange(controller:ColumnFilterController){        
        this.fetchedResultsController = null;
        this.tableView.reloadData();        
    }
}