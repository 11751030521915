/**
 * Created by godshadow on 11/07/16.
 */

class NoShowCell extends MUITableViewCell
{
    private paxLabel:MUILabel = null;
    private nameLabel:MUILabel = null;    
    private sourceLabel:MUILabel = null;
    private penalizedLabel:MUILabel = null;
    private amountTextField:MUITextField = null;
    private payButton:MUIButton = null;
    private closeButton:MUIButton = null;
    
    awakeFromHTML(){     

        this.paxLabel = MUIOutlet(this, "pax-lbl", "MUILabel");
        this.nameLabel = MUIOutlet(this, "name-lbl", 'MUILabel');
        this.sourceLabel = MUIOutlet(this, "source-lbl", "MUILabel");
        this.penalizedLabel = MUIOutlet(this, "penalized-lbl", 'MUILabel');
        this.amountTextField = MUIOutlet(this, "amount-tf", 'MUITextField');
        this.payButton = MUIOutlet(this, "pay-btn", 'MUIButton');
        this.payButton.setAction(this, function(){
            this.chargeAmount();
        });

        this.closeButton = MUIOutlet(this, "close-btn", 'MUIButton');
        this.closeButton.setAction(this, function(){
            this._booking.isPayed = true;
            DBHelper.saveMainContext();
        });

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    private _booking:Booking = null;
    set booking(item:Booking){
        this._booking = item;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;        

        this.nameLabel.text = item.clientName;
        this.paxLabel.text = String(item.pax);
        this.sourceLabel.text = null;
        this.penalizedLabel.text = ad.currencyFormatter.stringFromNumber( item.penalizedImport );
        
        if (item.hasPaymentToken == false){
            this.amountTextField.setAlpha(0);
            this.payButton.setEnabled(false);
        }
        else{
            this.amountTextField.setAlpha(1);
            this.payButton.setEnabled(true);
        }
        
        let [r,g,b] = Booking.colorForStatus(item.status);
        this.nameLabel.setTextRGBColor(r, g, b);
        this.paxLabel.setTextRGBColor(r, g, b);
    }

    private chargeAmount(){
        if (this.amountTextField.text.length == 0) {
            this.showAlert("Payment error", "Invalid ammount to make the charge.");
            return;
        }

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let cf = ad.currencyFormatter;
    
        let value = cf.numberFromString(this.amountTextField.text);
        if (value == null || value == 0){
            this.showAlert("Payment error", "Invalid ammount to make the charge.");
            return;
        }
                
        let concept = this._booking.day + " " + this._booking.clientName + " CANCELLATION / NO SHOW";
        ad.webService.chargeAmountToBooking(this._booking.identifier, this.amountTextField.text, concept, this, function(code, data){
            if (code == 200 && data["status"] == "OK") {
                this.showAlert("Payment message", "The transacion was successfull.");
            }
            else {
                this.showAlert("Payment error", "The transacion couldn't complete.");
            }
        });
    }

    private showAlert(title:string, message:string){
        AppHelper.showAlert(null, title, message);
    }
    
}
