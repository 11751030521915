

class OnlineOrderDetailViewController extends MUIViewController
{
    // private noEditHeaderView:OutputNoteDetailHeaderView = null;
    // private editHeaderView:OutputNoteDetailHeaderView = null;
    private statusComboBox:MUIComboBox = null;
    private dateLabel:MUILabel = null;
    private documentIDLabel:MUILabel = null;
    private clientNameLabel:MUILabel = null;
    private clientPhoneLabel:MUILabel = null;
    private address1Label:MUILabel = null;
    private address2Label:MUILabel = null;    
    
    private tableView:UITableView = null;

    private totalLabel:MUILabel = null;

    viewDidLoad(){
        super.viewDidLoad();

        // this.noEditHeaderView = MUIOutlet(this, "no-edit-header-view", "OutputNoteDetailHeaderView");
        // this.editHeaderView = MUIOutlet(this, "edit-header-view", "OutputNoteDetailHeaderView");

        this.statusComboBox = MUIOutlet(this, "status-cb", "MUIComboBox");
        this.statusComboBox.hidden = true;

        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");
        this.documentIDLabel = MUIOutlet(this, "doc-id-lbl", "MUILabel");
        this.clientNameLabel = MUIOutlet(this, "client-name-lbl", "MUILabel");
        this.clientPhoneLabel = MUIOutlet(this, "client-phone-lbl", "MUILabel");
        this.address1Label = MUIOutlet(this, "address1-lbl", "MUILabel");
        this.address2Label = MUIOutlet(this, "address2-lbl", "MUILabel");    
    
        this.tableView = MUIOutlet(this, 'table-view', 'UITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.totalLabel = MUIOutlet(this, "order-total-lbl", "MUILabel");

        this.updateUI();
    }

    viewWillAppear(animated:boolean){
        super.viewWillAppear();
        this.updateUI();
    }

    private _onlineOrder:OnlineOrder = null;
    set onlineOrder(order:OnlineOrder) {
        this._onlineOrder = order;
        this.updateUI()
    }

    protected updateUI(){        
        if (this.viewIsLoaded == false || this._onlineOrder == null) return;        

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        // this.statusComboBox.selectItem(this._onlineOrder)        

        this.dateLabel.text = this._onlineOrder.sessionDeliveryDay + " " + this._onlineOrder.sessionDeliveryTime;
        this.documentIDLabel.text = this._onlineOrder.orderNumber.toString();
        this.clientNameLabel.text = this._onlineOrder.clientName;        
        this.clientPhoneLabel.text = this._onlineOrder.clientPhone;
        this.address1Label.text = this._onlineOrder.clientAddress;        
        this.address2Label.text = this._onlineOrder.clientAddress2;

        this.totalLabel.text = ad.currencyFormatter.stringFromNumber(this._onlineOrder.total);

        // this.editHeaderView.note = this.stockNote;
        // this.noEditHeaderView.note = this.stockNote;
        // this.editHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? true : false;
        // this.noEditHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? false : true;        

        this.fetchedResultsController = null;
        this.sort_lines(this.fetchedResultsController.fetchedObjects);
    }
    
    numberOfSections(tableview){
        // let sections = this.fetchedResultsController.sections.length;
        // //sif (this.allowAddingLines == true) sections++;
        // return sections;
        return 1;
    }
    
    numberOfRowsInSection(tableview, section){
        // if (section == this.fetchedResultsController.sections.length) return 1;

        // let sec = this.fetchedResultsController.sections[section];
        // return sec.numberOfObjects();

        return this.items.count;
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell:OnlineOrderLineCell = null;
                
        // if (indexPath.section == this.fetchedResultsController.sections.length) {
        //     cell = tableview.dequeueReusableCellWithIdentifier('AddLineCell') as OnlineOrderLineCell;
        //     //cell.setLine(this.stockNote);
        //     // cell.delegate = this;
        //     return cell;
        // }

        // let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as OnlineOrderLine;
        let item = this.items[indexPath.row] as OnlineOrderLine;
        // if (this.noteStatus == StockNoteStatus.None || this.noteStatus == StockNoteStatus.Pending) {
        //     cell = tableview.dequeueReusableCellWithIdentifier('EditLineCell') as NoteLineCell;
        //     cell.delegate = this;
        //     // if (this.noteStatus == StockNoteStatus.Pending){
        //     //     //cell.setAccessoryType(MUITableViewCellAccessoryType.None);
        //     // }
        // }
        // else if (this.noteStatus == StockNoteStatus.Processed){
            cell = tableview.dequeueReusableCellWithIdentifier('LineCell') as OnlineOrderLineCell;
            //cell.delegate = null;
        // }                                                                    
        
        //cell.note = this.stockNote;
        cell.line = item;
                
        return cell;
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {   
        // if (this.noteStatus == StockNoteStatus.Processed || this.noteStatus == StockNoteStatus.Pending)
        //     return MUITableViewCellEditingStyle.None;
        
        // if (indexPath.section == this.fetchedResultsController.sections.length) {
        //     return MUITableViewCellEditingStyle.Insert;
        // }
        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {        

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            let noteline = this.fetchedResultsController.objectAtIndexPath(indexPath);    
            //this.removeNoteLine(noteline);
        }
    }  

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        //let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true)];
        let predicateFormat = "order.identifier == " + this._onlineOrder.identifier;
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("OnlineOrderLine", null, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["product", "tax", "product.productPlace", "modifier"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }    

    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        // this.tableView.reloadData();
        this.sort_lines(this.fetchedResultsController.fetchedObjects);
        if (this.addedNewLine == true) {
            //this.tableView.scrollToBottom(false);
            this.addedNewLine = false;
            // set cell focus 
            let ip = MIOIndexPath.indexForRowInSection(0, this.fetchedResultsController.sections.length);
            let cell = this.tableView.cellAtIndexPath(ip) as NoteLineCell;
            if (cell != null) cell.becomeFirstResponder();
        }    

    }

    protected addedNewLine = false;

    private items:OnlineOrderLine[] = [];
    private sort_lines(objects:OnlineOrderLine[]){                
        this.items = _MIOSortDescriptorSortObjects(objects, [MIOSortDescriptor.sortDescriptorWithKey("parentPath", true), MIOSortDescriptor.sortDescriptorWithKey("name", true)]);
        this.tableView.reloadData();
    }
}