//
// Generated class AccountingSequence
//

/// <reference path="AccountingSequence_ManagedObject.ts" />

class AccountingSequence extends AccountingSequence_ManagedObject
{

}
