//
// Generated class ProductFormatPlace
//

/// <reference path="ProductFormatPlace_ManagedObject.ts" />

class ProductFormatPlace extends ProductFormatPlace_ManagedObject
{

}
