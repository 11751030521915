

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Format_ManagedObject

class Format_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: invitationValue
    set invitationValue(value:number) {
        this.setValueForKey(value, 'invitationValue');
    }
    get invitationValue():number {
        return this.valueForKey('invitationValue');
    }
    set invitationValuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'invitationValue');
    }
    get invitationValuePrimitiveValue():number {
        return this.primitiveValueForKey('invitationValue');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: waReference
    set waReference(value:string) {
        this.setValueForKey(value, 'waReference');
    }
    get waReference():string {
        return this.valueForKey('waReference');
    }
    set waReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'waReference');
    }
    get waReferencePrimitiveValue():string {
        return this.primitiveValueForKey('waReference');
    }

    // Relationship: productFormats
    protected _productFormats:MIOManagedObjectSet = null;
    get productFormats():MIOManagedObjectSet {
        return this.valueForKey('productFormats');
    }
    addProductFormatsObject(value:ProductFormat) {
        this._addObjectForKey(value, 'productFormats');
    }
    removeProductFormatsObject(value:ProductFormat) {
        this._removeObjectForKey(value, 'productFormats');
    }
}
