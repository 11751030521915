/**
 * Created by miguel on 10/2/17.
 */


class ProductCategoryDetailViewController extends MUIViewController
{
    static newInstance():ProductCategoryDetailViewController {
        let vc = new ProductCategoryDetailViewController('add-new-category-view');        
        vc.initWithResource("layout/products/AddNewCategoryView.html")
        return vc;
    }

    private titleLabel:MUILabel = null;
    private saveButton:MUIButton = null;
    private settingsButton:MUIButton = null;

    private categoryImage:MUIImageView = null;
    private nameTextField:MUITextField = null;
    private languageButton:MUIButton = null;
    private forSaleCheckButton:MUISwitchButton = null;

    private segmentedControl:MUISegmentedControl = null;
    private pageController:MUIPageController = null;

    private anctvc:AddNewCategoryTaxViewController = null;
    private ancovc:AddNewCategoryOrderViewController = null;
    private ancavc:AddNewCategoryGroupViewController = null;
    private sectionViewController:CategorySectionViewController = null;
    private tagsViewController:CategoryTagsViewController = null;

    get preferredContentSize()
    {
        return new MIOSize(500, 500);
    }

    viewDidLoad()
    {
        super.viewDidLoad();

        this.titleLabel = MUIOutlet(this, "title", 'MUILabel');
        this.saveButton = MUIOutlet(this, "save-btn", 'MUIButton');
        this.saveButton.setAction(this, function (this:ProductCategoryDetailViewController) {
           DBHelper.saveMainContext();
        });

        this.settingsButton = MUIOutlet(this, 'settings-btn', 'MUIButton');
        this.settingsButton.setAction(this, function(this:ProductCategoryDetailViewController) {
            this.showActions();
        });

        this.categoryImage = MUIOutlet(this, 'category-image','MUIImageView');
        let tapGesture = new MUIGestureRecognizer();
        tapGesture.initWithTarget(this, this.imageTapGesture);
        this.categoryImage.addGestureRecognizer(tapGesture);

        this.nameTextField = MUIOutlet(this, 'ancv_pb_category_text', 'MUITextField');
        this.nameTextField.setOnChangeText(this, function(this:ProductCategoryDetailViewController, textfield:MUITextField, value:string) {
            let str = value.length > 0 ? value.trim() : null;
            this.category.name = str;
        });

        this.languageButton = MUIOutlet(this, "language-btn", "MUIButton");
        this.languageButton.setAction(this, function(){
            let vc = ProductLanguageViewController.newInstance(); 
            vc.setItem(this.category, "name");
            
            this.presentViewController( vc, true );
        });

        this.forSaleCheckButton = MUIOutlet(this, 'ancv_pb_switch_btn', 'MUISwitchButton');
        this.forSaleCheckButton.setOnChangeValue(this, function (this:ProductCategoryDetailViewController, control:MUISwitchButton, value:boolean){
            this.category.isAvailable = value;
            DBHelper.saveMainContext();
        });

        this.segmentedControl = MUIOutlet(this, "ancv_segmented_control", "MUISegmentedControl");
        this.segmentedControl.setAction(this, function (control, index) {

            this.pageController.showPageAtIndex(index);
        });

        //SEGMENTED BUTTON
        this.pageController = MUIOutlet(this, "ancpage_controller", "MUIPageController");

        this.anctvc = new AddNewCategoryTaxViewController("add-new-category-tax-view");
        this.anctvc.initWithResource("layout/products/AddNewCategoryTaxView.html");
        this.pageController.addPageViewController(this.anctvc);

        this.ancavc = new AddNewCategoryGroupViewController("add-new-category-group-view");
        this.ancavc.initWithResource("layout/products/AddNewCategoryGroupView.html");
        this.pageController.addPageViewController(this.ancavc);

        this.ancovc = new AddNewCategoryOrderViewController("add-new-category-order-view");
        this.ancovc.initWithResource("layout/products/AddNewCategoryOrderView.html");
        this.pageController.addPageViewController(this.ancovc);

        this.sectionViewController = CategorySectionViewController.newInstace();
        this.pageController.addPageViewController(this.sectionViewController);

        this.tagsViewController = CategoryTagsViewController.newInstance();
        this.pageController.addPageViewController(this.tagsViewController);
    }

    viewWillAppear(animate?) {
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private category:ProductCategory = null;
    set item(category:ProductCategory){
        this.category = category;
        this.updateUI();
    }

    private updateUI() {
        if(this.viewIsLoaded == false || this.category == null) return;
        
        this.titleLabel.text = MIOLocalizeString("CATEGORY DETAIL", "CATEGORY DETAIL");
        //this.categoryImage.setImage(this._category.imageURL);
        
        // this.okButton.setHidden(true);
        // this.closeButton.setHidden(true);
        this.categoryImage.setImage(this.category.image ? this.category.image.url : null);    
        this.nameTextField.text = this.category.name;
        this.forSaleCheckButton.setOn(this.category.isAvailable);
        //Hide the delete category button for now, until we have delete rules
        //this.deleteButton.setHidden(false);
        //this.saveButton.setHidden(false);


        this.anctvc.category    = this.category;
        this.sectionViewController.category    = this.category;
        this.ancavc.category    = this.category;
        this.ancovc.category    = this.category;
        this.tagsViewController.item = this.category;
    }

    //     if(this._mode == CategoryMode.AddCategory)
    //     {
    //         let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
    //         this._category = MIOEntityDescription.insertNewObjectForEntityForName("ProductCategory", moc) as ProductCategory;
    //         this._category.identifier = MIOUUID.UUID().UUIDString;
    //         this._category.grouping = 0;
    //         this._category.isAvailable = true;
    //         this._category.showInBar = true;
    //         this._category.showInTables = true;
    //         this._category.showInOnline = true;
            
    //         this._category.parentCategory = this._parentCategory ? this._parentCategory.identifier : null;
        
    //     }
        
    //     this.updateUI();

    //     this.segmentedControl.selectSegmentedAtIndex(0);
    //     this.pageController.showPageAtIndex(0);
    // }

    private imageTapGesture(gesture:MUIGestureRecognizer){
        if (gesture.state != MUIGestureRecognizerState.Began) return;

        const ctx = { "Category": this.category };
        AppHelper.sharedInstance().showImageSelectorPanel(ctx, ( image:DBImage, context:any ) => {
            const category = context["Category"] as ProductCategory;
            category.image = image;
            this.categoryImage.setImage( image.url );
        });        
    }

    private showActions() {
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('CHOOSE AN ACTION', "CHOOSE AN ACTION"), MIOLocalizeString("SELECT THE OPERATION","SELECT THE OPERATION"), MUIAlertViewStyle.Default);

        // avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("MASSIVE OPERATION", "MASSIVE OPERATION"), MUIAlertActionStyle.Default, this, function(){
        //     //TODO
        // }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("DELETE CATEGORY", "DELETE CATEGORY"), MUIAlertActionStyle.Destructive, this, function(){
            this.showRemoveActions();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));
        this.presentViewController(avc, true);
    }

    private showRemoveActions() {
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('CHOOSE AN ACTION', "CHOOSE AN ACTION"), MIOLocalizeString("SELECT THE OPERATION","SELECT THE OPERATION"), MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("DELETE ONLY CATEGORY", "DELETE ONLY CATEGORY"), MUIAlertActionStyle.Destructive, this, function(){
            this.removeCategory();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("DELETE CATEGORY AND PRODUCTS", "DELETE CATEGORY AND PRODUCTS"), MUIAlertActionStyle.Destructive, this, function(this:ProductCategoryDetailViewController){
            this.removeCategoryAndProducts();
        }));
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("DELETE CATEGORY AND MOVE PRODUCTS", "DELETE CATEGORY AND MOVE PRODUCTS"), MUIAlertActionStyle.Destructive, this, function(){
            // this.removeCategory();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));
        this.presentViewController(avc, true);
    }


    private removeCategory() {
        MIONotificationCenter.defaultCenter().postNotification("CategoryWillRemoveNotification", this.category);
        DBHelper.deleteObjectFromMainContext(this.category, true);
    }

    private removeCategoryAndProducts(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        ad.webService.removeCategoryAndProducts(this.category, (error:string) => {
            if (error != null) { 
                AppHelper.showErrorMessage(null, MIOLocalizeString("ERROR DELETING CATEGORY", "ERROR DELETING CATEGORY") , error);
                return;
            }            
            
            AppHelper.showInfoMessage(null, MIOLocalizeString("OPERATION SUCCESSFUL", "OPERATION SUCCESSFUL"), "");            
        });
    }

}