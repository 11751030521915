

// Generated class MapLocation_ManagedObject

class MapLocation_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: angle
    set angle(value:number) {
        this.setValueForKey(value, 'angle');
    }
    get angle():number {
        return this.valueForKey('angle');
    }
    set anglePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'angle');
    }
    get anglePrimitiveValue():number {
        return this.primitiveValueForKey('angle');
    }

    // Property: x
    set x(value:number) {
        this.setValueForKey(value, 'x');
    }
    get x():number {
        return this.valueForKey('x');
    }
    set xPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'x');
    }
    get xPrimitiveValue():number {
        return this.primitiveValueForKey('x');
    }

    // Property: y
    set y(value:number) {
        this.setValueForKey(value, 'y');
    }
    get y():number {
        return this.valueForKey('y');
    }
    set yPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'y');
    }
    get yPrimitiveValue():number {
        return this.primitiveValueForKey('y');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: qrCode
    set qrCode(value:string) {
        this.setValueForKey(value, 'qrCode');
    }
    get qrCode():string {
        return this.valueForKey('qrCode');
    }
    set qrCodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'qrCode');
    }
    get qrCodePrimitiveValue():string {
        return this.primitiveValueForKey('qrCode');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: isOccupied
    set isOccupied(value:boolean) {
        this.setValueForKey(value, 'isOccupied');
    }
    get isOccupied():boolean {
        return this.valueForKey('isOccupied');
    }
    set isOccupiedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isOccupied');
    }
    get isOccupiedPrimitiveValue():boolean {
        return this.primitiveValueForKey('isOccupied');
    }
    // Relationship: locationCategory
    set locationCategory(value:MapLocationCategory) {
        this.setValueForKey(value, 'locationCategory');
    }
    get locationCategory():MapLocationCategory {
        return this.valueForKey('locationCategory') as MapLocationCategory;
    }

    // Property: createdAt
    set createdAt(value:Date) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():Date {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():Date {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:Date) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():Date {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:Date) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():Date {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('deletedAt');
    }
}
