//
// Generated class Interval
//

/// <reference path="Interval_ManagedObject.ts" />

class Interval extends Interval_ManagedObject
{

}
