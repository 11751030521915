
/// <reference path="coders/DBInvoiceHeadersCoder.ts" />
/// <reference path="coders/DBInvoiceLinesCoder.ts" />


enum DBImportFileType
{
    products,
    clients,
    suppliers,
    supplierProducts,
    rates,
    productRates,
    formats,    
    formatRates,
    productFormats,
    invoiceHeaders,
    invoiceLines,
    taxes,
    tags,
    stockCategories,
    employees,
    stockInputFormats,
    locationCategory,
    location,
    components,
    productSources,
    ellaborationCenters,
    productModifierCategories,
    productModifiers,
    productAndProductModifierCategory,
    productFormatAndProductModifierCategory,
    tcpPrinters,
    bookings,
    cards,
    users
}

class DBImportViewController extends BaseViewController
{
    private importFileButton:MUIButton = null;
    private importButton:MUIButton = null;
    private exportFileButton:MUIButton = null;
    private reportTableView: MUIReportTableView = null;

    private ad: AppDelegate = MUIWebApplication.sharedInstance().delegate;
    private filterController:ColumnFilterController = null;    

    private items = null;
    private filterItems = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.importFileButton = MUIOutlet(this, "import-file-btn", "MUIButton");
        this.importFileButton.setAction(this, this.showImportFileOptionType);

        this.exportFileButton = MUIOutlet(this, "export-file-btn", "MUIButton");
        this.exportFileButton.setAction(this, this.showExportFileOptionType);


        this.importButton = MUIOutlet(this, "import-btn", "MUIButton");
        this.importButton.setEnabled(false);
        this.importButton.setAction(this, function(this:DBImportViewController){
            this.importItems(this.filterItems);
        });

        this.filterController = new ColumnFilterController();
        this.filterController.initWithDelegate(this);

        this.reportTableView = MUIOutlet(this, 'report-table-view', 'MUIReportTableView');
        this.reportTableView.dataSource = this;
        this.reportTableView.delegate = this;
    }

    numberOfRows(reportTableView: MUIReportTableView) {
        if (this.filterItems == null) return 0;
        return this.filterItems.length;                
    }

    cellAtIndexPath(reportTableView: MUIReportTableView, column: MUIReportTableViewColumn, indexPath: MIOIndexPath) {
        let cell = reportTableView.dequeueReusableCellWithIdentifier("TitleCell") as ReportResultsCell;
        let item = this.filterItems[indexPath.row];
        cell.bold = false;
        this.configureCellAtIndexPath(cell, column, indexPath, item);
        return cell;
    }

    configureCellAtIndexPath(cell: ReportResultsCell, column: MUIReportTableViewColumn, indexPath: MIOIndexPath, item) {

        let text = "";
        if (column.serverName != null) {
            text = this.textAtIndexPath(column, indexPath, item);
            if (column.formatter != null) {
                if (column.formatter instanceof MIODateFormatter) {
                    let value = this.ad.serverDateTimeFormatter.dateFromString(text);
                    text = column.formatter.stringForObjectValue(value);
                }
                else {
                    text = column.formatter.stringForObjectValue(text);
                }
            }
        }

        cell.title = text ? text : "";
        cell.titleAligment = column.alignment;
    }

    textAtIndexPath(column: MUIReportTableViewColumn, indexPath: MIOIndexPath, item) {

        let value = item[column.serverName];

        if (column.formatter instanceof MIONumberFormatter) {
            value = ReportItem.parseValue(ReportItemValueType.Float, value);
        }
        else if (column.formatter instanceof MIODateFormatter) {
            value = ReportItem.parseValue(ReportItemValueType.Date, value);
        }        

        return value;
    }

    private showImportFileOptionType(){

        const avc = new MUIAlertViewController();        
        avc.initWithTitle('Import', 'Select what type of file you want to import', MUIAlertViewStyle.Default);
        
        avc.addAction(MUIAlertAction.alertActionWithTitle('Load taxes file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.taxes);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load product sources file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.productSources);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load products file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.products);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load ellaboration centers file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.ellaborationCenters);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load clients file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.clients);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load suppliers file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.suppliers);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load supplier products file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.supplierProducts);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load rates file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.rates);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load product rates file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.productRates);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load formats file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.formats);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load product formats file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.productFormats);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load format rates file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.formatRates);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load invoice headers file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.invoiceHeaders);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load invoice lines file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.invoiceLines);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load tags file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.tags);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load stock categories file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.stockCategories);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load employees file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.employees);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load stock input formats file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.stockInputFormats);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load location category file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.locationCategory);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load location file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.location);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load components / ingredients file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.components);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load modifier categories file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.productModifierCategories);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load modifiers file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.productModifiers);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load product and product modifier categories file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.productAndProductModifierCategory);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load product format and product modifier categories file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.productFormatAndProductModifierCategory);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load tcp printers file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.tcpPrinters);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load bookings file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.bookings);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load cards file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.cards);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Load users file', MUIAlertActionStyle.Default, this, function(){
            this.openFile(DBImportFileType.users);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Cancel', MUIAlertActionStyle.Cancel, null, null));
        
        this.presentViewController(avc, true);
    }

    private showExportFileOptionType(){

        const avc = new MUIAlertViewController();        
        avc.initWithTitle('Export', 'Select what type of file you want to export', MUIAlertViewStyle.Default);
        
        avc.addAction(MUIAlertAction.alertActionWithTitle('Export taxes file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.taxes);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export product sources file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.productSources);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export products file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.products);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export ellaboration centers file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.ellaborationCenters);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export clients file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.clients);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export suppliers file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.suppliers);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export suppliers products file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.supplierProducts);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export rates file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.rates);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export product rates file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.productRates);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export formats file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.formats);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export product formats file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.productFormats);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export format rates file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.formatRates);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export tags file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.tags);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export stock categories file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.stockCategories);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export employees file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.employees);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export stock input formats file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.stockInputFormats);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export location category file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.locationCategory);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export location file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.location);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export components / ingredients file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.components);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export modfier categories file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.productModifierCategories);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export modfiers file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.productModifiers);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export product and product modfier categories file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.productAndProductModifierCategory);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export product format and product modfier categories file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.productFormatAndProductModifierCategory);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export tcp printers file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.tcpPrinters);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export cards file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.cards);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Export users file', MUIAlertActionStyle.Default, this, function(){
            this.exportItems(DBImportFileType.users);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL', 'CANCEL'), MUIAlertActionStyle.Cancel, null, null));
        
        this.presentViewController(avc, true);
    }

    
    openFile(fileType:DBImportFileType){
        this.importButton.setEnabled(false); 

        let panel = MUIOpenPanel.openPanel();
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        panel.beginSheetModalForWindow(ad.window, this, function(this:DBImportViewController, result:number){
            if (result == MIOFileHandlingPanel.OKButton) {
               let file = panel.files[0];
               // Open the document.
               this.parseFileItems(fileType, file);
            }       
         });
    }

    private addColumnToReportView(column:MUIReportTableViewColumn){        
        if (this.reportTableView == null) return;
        this.reportTableView.addColumn(column);
        let colFilter = column.columnHeaderView() as ReportColumnHeader;
        colFilter.columnFilterTextField.filterController = this.filterController;
        let type = this.filterTypeFromColumnType(column.type);
        colFilter.columnFilterTextField.setOnFilterChange(type, column.serverName, null, null);    
        colFilter.setAction(this, this.sortDescriptorsDidChange, column);
    }

    private filterTypeFromColumnType(colType:MUIReportTableViewColumnType) {
        switch (colType) {
            case MUIReportTableViewColumnType.Number:
            return ColumnFilterTextFieldType.Number;

            case MUIReportTableViewColumnType.Date:
            return ColumnFilterTextFieldType.Date;
        }

        return ColumnFilterTextFieldType.String;
    }

    sortDescriptorsDidChange(column: MUIReportTableViewColumn) {

        if (column.serverName == null) return;

        if (column.ascending == true) {
            column.ascending = false;
            this.filterItems = _MIOSortDescriptorSortObjects(this.filterItems, [MIOSortDescriptor.sortDescriptorWithKey(column.serverName, true)]);
        }
        else {
            column.ascending = true;
            this.filterItems = _MIOSortDescriptorSortObjects(this.filterItems, [MIOSortDescriptor.sortDescriptorWithKey(column.serverName, false)]);
        }

        this.reportTableView.reloadData();
    }

    filterPredicateDidChange(controller:ColumnFilterController){
        let filterPredicateFormat = this.filterController.filterPredicateFormat();
        if (filterPredicateFormat != null) {
            this.filterItems = _MIOPredicateFilterObjects(this.items, MIOPredicate.predicateWithFormat(filterPredicateFormat));
        }
        else {
            this.filterItems = this.items;
        }
                
        this.reportTableView.reloadData();  
    }

    private importer:DBCoder = null;
    private parseFileItems(fileType:DBImportFileType, file)
    {        
        switch(fileType) {
            case DBImportFileType.products: this.importer = new DBProductsCoder(); break;
            case DBImportFileType.clients: this.importer = new DBClientsCoder(); break;
            case DBImportFileType.suppliers: this.importer = new DBSuppliersCoder(); break;
            case DBImportFileType.supplierProducts: this.importer = new DBSupplierProductsCoder(); break;
            case DBImportFileType.rates: this.importer = new DBRatesCoder(); break;
            case DBImportFileType.productRates: this.importer = new DBProductRatesCoder(); break;
            case DBImportFileType.formatRates: this.importer = new DBFormatRatesCoder(); break;
            case DBImportFileType.formats: this.importer = new DBFormatsCoder(); break;
            case DBImportFileType.productFormats: this.importer = new DBProductFormatsCoder(); break;            
            case DBImportFileType.invoiceHeaders: this.importer = new DBInvoiceHeadersCoder(); break;
            case DBImportFileType.invoiceLines: this.importer = new DBInvoiceLinesCoder(); break;
            case DBImportFileType.taxes: this.importer = new DBTaxesCoder(); break;
            case DBImportFileType.tags: this.importer = new DBTagsCoder(); break;
            case DBImportFileType.stockCategories: this.importer = new DBStockCategoryCoder(); break;
            case DBImportFileType.employees: this.importer = new DBWorkersCoder(); break;
            case DBImportFileType.stockInputFormats: this.importer = new DBStockInputFormatsCoder(); break;
            case DBImportFileType.locationCategory: this.importer = new DBLocationCategoryCoder(); break;
            case DBImportFileType.location: this.importer = new DBLocationCoder(); break;
            case DBImportFileType.components: this.importer = new DBComponentsCoder(); break;
            case DBImportFileType.productSources: this.importer = new DBProductSourceCoder(); break;
            case DBImportFileType.ellaborationCenters: this.importer = new DBEllaborationCentersCoder(); break;
            case DBImportFileType.productModifierCategories: this.importer = new DBProductModifierCategoryCoder(); break;
            case DBImportFileType.productModifiers: this.importer = new DBProductModifierCoder(); break;
            case DBImportFileType.productAndProductModifierCategory: this.importer = new DBProductAndProductModifierCategoryCoder(); break;
            case DBImportFileType.productFormatAndProductModifierCategory: this.importer = new DBProductFormatAndProductModifierCategoryCoder(); break;
            case DBImportFileType.tcpPrinters: this.importer = new DBTCPPrinterCoder(); break;
            case DBImportFileType.bookings: this.importer = new DBBookingsCoder(); break;
            case DBImportFileType.cards: this.importer = new DBCardsCoder(); break;
            case DBImportFileType.users: this.importer = new DBUsersCoder(); break;
        }
        this.importer.init();

        this.reportTableView.removeAllColumns();
        let columns = this.importer.reportColumns();
        for (let index = 0; index < columns.length; index++) {
            let c = columns[index];
            this.addColumnToReportView(c);
        }

         this.importer.parseFile(file, (items) => {
            this.items = items;
            this.filterItems = items;
            this.importButton.setEnabled(true);
            this.reportTableView.reloadData();    
        });        
    }

    private importItems(items) {
        if (this.importer == null) return;
        this.importer.importItems(items);
    }

    private exportItems(type:DBImportFileType) {
        let exporter:DBCoder = null;
        switch(type) {
            case DBImportFileType.products:  exporter = new DBProductsCoder(); break;
            case DBImportFileType.clients:   exporter = new DBClientsCoder(); break;
            case DBImportFileType.suppliers: exporter = new DBSuppliersCoder(); break;
            case DBImportFileType.supplierProducts: exporter = new DBSupplierProductsCoder(); break;
            case DBImportFileType.rates: exporter = new DBRatesCoder(); break;
            case DBImportFileType.productRates: exporter = new DBProductRatesCoder(); break;
            case DBImportFileType.formats:  exporter = new DBFormatsCoder(); break;
            case DBImportFileType.productFormats:  exporter = new DBProductFormatsCoder(); break;
            case DBImportFileType.formatRates: exporter = new DBFormatRatesCoder(); break;
            case DBImportFileType.taxes: exporter = new DBTaxesCoder(); break;
            case DBImportFileType.tags: exporter = new DBTagsCoder(); break;
            case DBImportFileType.stockCategories: exporter = new DBStockCategoryCoder(); break;
            case DBImportFileType.employees: exporter = new DBWorkersCoder(); break;
            case DBImportFileType.stockInputFormats: exporter = new DBStockInputFormatsCoder(); break;
            case DBImportFileType.locationCategory: exporter = new DBLocationCategoryCoder(); break;
            case DBImportFileType.location: exporter = new DBLocationCoder(); break;
            case DBImportFileType.components: exporter = new DBComponentsCoder(); break;
            case DBImportFileType.productSources: exporter = new DBProductSourceCoder(); break;
            case DBImportFileType.ellaborationCenters: exporter = new DBEllaborationCentersCoder(); break;
            case DBImportFileType.productModifierCategories: exporter = new DBProductModifierCategoryCoder(); break;
            case DBImportFileType.productModifiers: exporter = new DBProductModifierCoder(); break;
            case DBImportFileType.productAndProductModifierCategory: exporter = new DBProductAndProductModifierCategoryCoder(); break;
            case DBImportFileType.productFormatAndProductModifierCategory: exporter = new DBProductFormatAndProductModifierCategoryCoder(); break;
            case DBImportFileType.tcpPrinters: exporter = new DBTCPPrinterCoder(); break;
            case DBImportFileType.cards: exporter = new DBCardsCoder(); break;
            case DBImportFileType.users: exporter = new DBUsersCoder(); break;
        }

        exporter.export();
    }

}