

/// <reference path="MemoLog.ts" />

// Generated class BusinessLog_ManagedObject

class BusinessLog_ManagedObject extends MemoLog
{
    // Relationship: business
    set business(value:BusinessLicense) {
        this.setValueForKey(value, 'business');
    }
    get business():BusinessLicense {
        return this.valueForKey('business') as BusinessLicense;
    }
}
