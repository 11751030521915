class BookingModifierCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;
    
    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    set item (item:BookingModifier){
        this.nameLabel.text = item.name;
    }
}