

/// <reference path="ClientPaymentInstallment.ts" />

// Generated class LicensePaymentInstallment_ManagedObject

class LicensePaymentInstallment_ManagedObject extends ClientPaymentInstallment
{

    // Property: businessName
    set businessName(value:string) {
        this.setValueForKey(value, 'businessName');
    }
    get businessName():string {
        return this.valueForKey('businessName');
    }
    set businessNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'businessName');
    }
    get businessNamePrimitiveValue():string {
        return this.primitiveValueForKey('businessName');
    }
    // Relationship: business
    set business(value:BusinessLicense) {
        this.setValueForKey(value, 'business');
    }
    get business():BusinessLicense {
        return this.valueForKey('business') as BusinessLicense;
    }

    // Relationship: tokens
    protected _tokens:MIOManagedObjectSet = null;
    get tokens():MIOManagedObjectSet {
        return this.valueForKey('tokens');
    }
    addTokensObject(value:LicenseToken) {
        this._addObjectForKey(value, 'tokens');
    }
    removeTokensObject(value:LicenseToken) {
        this._removeObjectForKey(value, 'tokens');
    }
}
