//
// Generated class InvitationRule
//

/// <reference path="InvitationRule_ManagedObject.ts" />

class InvitationRule extends InvitationRule_ManagedObject
{

}
