
class WarehouseButtonCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    
    
    awakeFromHTML()
    {
        this.nameLabel = MUIOutlet(this, 'wv_tv_allwarehouses_btn', 'MUILabel');
    }
    
    set title(title)
    {
        this.nameLabel.text = title;
    }
}