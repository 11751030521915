//
// Generated class PrinterLink
//

/// <reference path="PrinterLink_ManagedObject.ts" />

class PrinterLink extends PrinterLink_ManagedObject
{

}
