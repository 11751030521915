
class WarehouseOrderDetailViewController extends NoteDetailViewController
{
    static newInstance() : WarehouseOrderDetailViewController {
        let vc = new WarehouseOrderDetailViewController("warehouse-order-detail-view");
        vc.initWithResource("layout/warehouse_order/WarehouseOrderDetailView.html");
        return vc;
    }

    private allReceiptButton:MUIButton = null;

    private editHeaderView:WarehouseOrderDetailHeaderView = null;
    private noEditHeaderView:WarehouseOrderDetailHeaderView = null;    
    
    viewDidLoad(){
        super.viewDidLoad();

        this.editHeaderView = MUIOutlet(this, "edit-header-view", "WarehouseOrderDetailHeaderView");
        this.noEditHeaderView = MUIOutlet(this, "no-edit-header-view", "WarehouseOrderDetailHeaderView");        

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();
    }

    protected updateUI(){
                
        this.editHeaderView.note = this.stockNote;
        this.noEditHeaderView.note = this.stockNote;
        this.editHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? true : false;
        this.noEditHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? false : true;                        

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {   
        if (this.noteStatus == StockNoteStatus.Processed) return MUITableViewCellEditingStyle.None;
        
        if (indexPath.section == this.fetchedResultsController.sections.length) {
            return MUITableViewCellEditingStyle.Insert;
        }

        if (this.stockNote.status == StockNoteStatus.Pending && this.stockNote.type == StockNoteType.InternalOrderResponse){
            let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as StockNoteLine;
            if (item.estimatedQuantity != null && item.estimatedQuantity != 0){
                return MUITableViewCellEditingStyle.None;                
            }
        }
        
        return MUITableViewCellEditingStyle.Delete;
    }


    set fetchedResultsController(value){
        this.setFetchedResultsController(value);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true)];

        let predicateFormat = 'note.identifier == ' + this.stockNote.identifier;
        if (this.searchString != null) {
            predicateFormat += " AND productName CONTAINS '" + this.searchString + "'";
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("WarehouseOrderLine", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["tax", "inputFormat", "product", "product.defaultWarehouse"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }    
}