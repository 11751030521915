//
// Generated class Ticket
//

/// <reference path="Ticket_ManagedObject.ts" />

class Ticket extends Ticket_ManagedObject
{

}
