class BookingRelationZoneCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;
    
    private nf:MIONumberFormatter = MUIWebApplication.sharedInstance().delegate.numberFormatter;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, 'title-lbl', 'MUILabel');
    }

    private _zone:RelationBookingZone = null;
    set zone(relationZone:RelationBookingZone){
        this._zone = relationZone;
        this.nameLabel.text = relationZone.name + '        Pax: ' + relationZone.pax + '      Price: ' + this.nf.stringFromNumber(relationZone.pax * this._zone.price);                
    }
}
