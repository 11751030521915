//
// Generated class CashDeskSessionBalance
//

/// <reference path="CashDeskSessionBalance_ManagedObject.ts" />

class CashDeskSessionBalance extends CashDeskSessionBalance_ManagedObject
{

}
