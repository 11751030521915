//
// Generated class SyncLogApp
//

/// <reference path="SyncLogApp_ManagedObject.ts" />

class SyncLogApp extends SyncLogApp_ManagedObject
{

}
