//
// Generated class PaymentIntallment
//

/// <reference path="PaymentIntallment_ManagedObject.ts" />

class PaymentIntallment extends PaymentIntallment_ManagedObject
{

}
