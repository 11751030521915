

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Room_ManagedObject

class Room_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: referenceID
    set referenceID(value:string) {
        this.setValueForKey(value, 'referenceID');
    }
    get referenceID():string {
        return this.valueForKey('referenceID');
    }
    set referenceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'referenceID');
    }
    get referenceIDPrimitiveValue():string {
        return this.primitiveValueForKey('referenceID');
    }
    // Relationship: roomZone
    set roomZone(value:RoomZone) {
        this.setValueForKey(value, 'roomZone');
    }
    get roomZone():RoomZone {
        return this.valueForKey('roomZone') as RoomZone;
    }
}
