

/// <reference path="Invoice.ts" />

// Generated class PurchaseInvoice_ManagedObject

class PurchaseInvoice_ManagedObject extends Invoice
{

    // Property: expenditureLedgerAccountName
    set expenditureLedgerAccountName(value:string) {
        this.setValueForKey(value, 'expenditureLedgerAccountName');
    }
    get expenditureLedgerAccountName():string {
        return this.valueForKey('expenditureLedgerAccountName');
    }
    set expenditureLedgerAccountNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'expenditureLedgerAccountName');
    }
    get expenditureLedgerAccountNamePrimitiveValue():string {
        return this.primitiveValueForKey('expenditureLedgerAccountName');
    }

    // Property: externalReference
    set externalReference(value:string) {
        this.setValueForKey(value, 'externalReference');
    }
    get externalReference():string {
        return this.valueForKey('externalReference');
    }
    set externalReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'externalReference');
    }
    get externalReferencePrimitiveValue():string {
        return this.primitiveValueForKey('externalReference');
    }
    // Relationship: expenditureLedgerAccount
    set expenditureLedgerAccount(value:LedgerAccount) {
        this.setValueForKey(value, 'expenditureLedgerAccount');
    }
    get expenditureLedgerAccount():LedgerAccount {
        return this.valueForKey('expenditureLedgerAccount') as LedgerAccount;
    }
}
