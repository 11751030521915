

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Booking_BookingZone_ManagedObject

class Booking_BookingZone_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: pax
    set pax(value:number) {
        this.setValueForKey(value, 'pax');
    }
    get pax():number {
        return this.valueForKey('pax');
    }
    set paxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'pax');
    }
    get paxPrimitiveValue():number {
        return this.primitiveValueForKey('pax');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: zoneName
    set zoneName(value:string) {
        this.setValueForKey(value, 'zoneName');
    }
    get zoneName():string {
        return this.valueForKey('zoneName');
    }
    set zoneNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'zoneName');
    }
    get zoneNamePrimitiveValue():string {
        return this.primitiveValueForKey('zoneName');
    }
    // Relationship: bookingZone
    set bookingZone(value:BookingZone) {
        this.setValueForKey(value, 'bookingZone');
    }
    get bookingZone():BookingZone {
        return this.valueForKey('bookingZone') as BookingZone;
    }
}
