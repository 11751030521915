

class StockCategoryConfigViewController extends MUIViewController
{

    static newInstance() : StockCategoryConfigViewController {
        let vc = new StockCategoryConfigViewController('stock-category-config-view');
        vc.initWithResource('layout/stockcategory/StockCategoryConfigView.html');
        return vc;
    }

    private addButton:MUIButton = null;
    private backButton:MUIButton = null;
    private titleLabel:MUILabel = null;
    private tableView:UITableView = null;

    parentCategory:StockCategory = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function(){
            this.addStockCategory();
        });

        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");
        this.backButton.setAction(this, function(control){
            this.navigationController.popViewController(true);
        });

        this.titleLabel = MUIOutlet(this, 'title-lbl', 'MUILabel');

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(animate?) {
        super.viewWillAppear(animate);        
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        if (this.parentCategory) {
            this.titleLabel.text = MIOLocalizeString("STOCK CATEGORY PARENT: ","STOCK CATEGORY PARENT: ") +  this.parentCategory.name;
            this.backButton.hidden = false;
        } else {
            this.titleLabel.text = MIOLocalizeString("STOCK CATEGORY DETAIL","STOCK CATEGORY DETAIL");
            this.backButton.hidden = true;
        }
      
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }
    
    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('StockCategoryConfigCell') as StockCategoryConfigCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as StockCategory;
        cell.item = item;
        cell.selectionStyle = UITableViewCellSelectionStyle.None; 
        return cell;
    }
        
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as StockCategory;

        if (item) {
            let vc = StockCategoryConfigViewController.newInstance();
            vc.parentCategory = item;                    
            this.navigationController.pushViewController(vc, true);
        }
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
        return UITableViewCellEditingStyle.Delete;
    }
    
    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as StockCategory;    

        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            this.deleteStockCategory(item);
        }
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('orderindex', true),
                                MIOSortDescriptor.sortDescriptorWithKey('name', true)
                            ];
        let predicate = "superCategory.identifier == " + (this.parentCategory ? this.parentCategory.identifier : "null");
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("StockCategory", sortDescriptors, predicate);
        fetchRequest.relationshipKeyPathsForPrefetching = ['subCategories', 'superCategories'];       
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    
    private addStockCategory(){

        var avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('NEW STOCK CATEGORY','NEW STOCK CATEGORY'), MIOLocalizeString('CHOOSE A NAME','CHOOSE A NAME '), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD','ADD'), MUIAlertActionStyle.Default, this, function(){
            let nameTF =  avc.textFields[0];
            let name = nameTF.text.trim();

            if(name.length > 0){
                let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
                let ws = MIOEntityDescription.insertNewObjectForEntityForName("StockCategory", moc) as StockCategory;
                ws.identifier = MIOUUID.UUID().UUIDString;
                ws.name = name;
                if (this.parentCategory) ws.superCategory = this.parentCategory;
                moc.save();
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('CHECK NAME','CHECK NAME'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CLOSE','CLOSE'), MUIAlertActionStyle.Cancel, this, function(){}));
                
                this.presentViewController(error, true);
            }
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, this, function(){}));
        
        this.presentViewController(avc, true);
    }


    private deleteStockCategory(delObject:StockCategory) {

        //NOTE: must delete all ProductAttributes related to the deleted ProductAttributeType
        let predicate = MIOPredicate.predicateWithFormat("superCategory.identifier == '" + delObject.identifier + "'");
        DBHelper.queryObjectsWithCompletion("StockCategory", null, predicate, ["subCategories", "superCategory"], this, function(objects){
        
            if (objects.length > 0){
                
                //reconect the StockCategory child objects to the superCategory for the deleted object
                for (let index = 0; index < objects.length; index++){
                    if (delObject.superCategory) {
                        objects[index].superCategory = delObject.superCategory;
                    } else {
                        objects[index].superCategory = null;
                    }
                }
            }

            DBHelper.deleteObjectFromMainContext(delObject, true);

            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
            moc.save();
        });

    }
}
