//
// Generated class Service
//

/// <reference path="Service_ManagedObject.ts" />

class Service extends Service_ManagedObject
{

}
