

class WarehouseOrderListViewController extends NoteListViewController
{    
    static newInstance() : WarehouseOrderListViewController {
        let vc = new WarehouseOrderListViewController('warehouse-order-list-view');
        vc.initWithResource("layout/warehouse_order/WarehouseOrderListView.html");
        return vc;
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('WarehouseOrderListCell') as WarehouseOrderListCell;    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        
        cell.item = item;
        cell.selected = this.selectedNote == item ? true : false;

        return cell;
    }
        
    protected showSelectedItem(){

        let vc = WarehouseOrderDetailViewController.newInstance();
        vc.note = this.selectedNote;
        this.splitViewController.showDetailViewController(vc);
    }

    protected showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("StockOrder", NoItemSelectedViewControllerType.StockOrder);
        this.splitViewController.showDetailViewController(vc);
    }
            
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("documentID", false)];
        if (this.selectedStatus == StockNoteStatus.Processed) {
            sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("creationDate", false)];
        }   

        let predicateFormat = this.selectedStatus != 0 ? "status == " + this.selectedStatus : "status == 0 OR status == 3";
        predicateFormat += " AND placeID == '" + ad.selectedIdentifier + "'";        
        if(this.searchString != null) {
            predicateFormat += " AND documentID CONTAINS '" + this.searchString + "'";
        }

        if (this.filterPredicateFormat != null) {
            predicateFormat += " AND " + this.filterPredicateFormat;
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("WarehouseOrder", sortDescriptors, predicateFormat);
        fetchRequest.fetchLimit = 50;
        fetchRequest.relationshipKeyPathsForPrefetching = ["originWarehouse", "destinationWarehouse"];
            
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
	}    
	
	private _filters = null;
    get filters(){
        if (this._filters != null) return this._filters;

        this._filters = [FilterOption.filterWithTitle("FromDate", "From day", "stockDate", FilterFieldType.Date, null, FilterControllerComparatorType.MajorOrEqualComparator, null, "00:00:00"),
                FilterOption.filterWithTitle("ToDate", "To day", "stockDate", FilterFieldType.Date, null, FilterControllerComparatorType.MinorOrEqualComparator, null, "23:59:59"),                
                FilterOption.filterWithFetchRequest("Origin", "From warehouse", "originWarehouse.identifier", FilterFieldType.DropDown, "Warehouse", "name", "identifier", MIOLocalizeString("SELECT ORIGIN WAREHOUSE","Select origin warehouse")),
                FilterOption.filterWithFetchRequest("Destination", "To warehouse", "destinationWarehouse.identifier", FilterFieldType.DropDown, "Warehouse", "name", "identifier", MIOLocalizeString("SELECT DESTINATION WAREHOUSE","Select destination warehouse"))];

        return this._filters;
    }
	
}