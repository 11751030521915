

class DBImportController
{
    protected ad: AppDelegate = MUIWebApplication.sharedInstance().delegate;

    parseFile(file, completion)
    {
        let reader = new FileReader();

        let instance = this;
        reader.onload = function(e) {
            let data = reader.result;
            let workbook = XLSX.read(data, {
                type: 'binary'
            });
        
            workbook.SheetNames.forEach(function(sheetName) {
                // Here is your object
                let worksheet = workbook.Sheets[sheetName];                
                let result = [];
                let row;
                let rowNum;
                let colNum;
                let range = XLSX.utils.decode_range(worksheet['!ref']);
                for(rowNum = range.s.r; rowNum <= range.e.r; rowNum++){
                    row = [];
                    for(colNum=range.s.c; colNum<=range.e.c; colNum++){
                        let nextCell = worksheet[
                            XLSX.utils.encode_cell({r: rowNum, c: colNum})
                        ];
                        if( typeof nextCell === 'undefined' ){
                            row.push(void 0);
                        } else row.push(nextCell.w);
                    }
                    result.push(row);
                }                
                let items = instance.parseItems.call(instance, result);
                completion(items);
            });
        };

        reader.onerror = function(ex) {
            console.log(ex);
        };
        
        reader.readAsBinaryString(file);     
    }

    columns(): any[] {
        return [];
    }

    reportColumns() : MUIReportTableViewColumn[] {
        let reportColumns = [];
        let cols = this.columns();
        for (let index = 0; index < cols.length; index++) {
            let c = cols[index];
            let title = c["title"];
            let width = c["width"];
            let align = c["align"];
            let formatter = c["formatter"];
            let localziedTitle = MIOLocalizeString(title, title);
            let rc = MUIReportTableViewColumn.labelColumnWithTitle(localziedTitle, width, 100, align, title, formatter);
            reportColumns.addObject(rc);
        }
        
        return reportColumns;
    }

    private parseItems(rows)
    {
        let columns = this.columns();
        let header_indexes = [];                
        let first_row = true;        
        let items = [];

        for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
            const r = rows[rowIndex];
            if (first_row) {
                for (let index = 0; index < r.length; index++){                      
                    let title = r[index]?.trim().toUpperCase();
                    let foundIndex = -1;
                    for (let colIndex = 0; colIndex < columns.length; colIndex++) {
                        let c = columns[colIndex];
                        if (c["title"] == title) {
                            foundIndex = colIndex;
                            break;
                        }
                    }                    
                    if ( foundIndex > -1 ) {
                        header_indexes[foundIndex] = index;
                        first_row = false;
                    }
                }
            }
            else {
                let item = {};                
                
                for (let colIndex = 0; colIndex <= columns.length; colIndex++) {
                    if (r[header_indexes[colIndex]] == null) continue;
                    let c = columns[colIndex];
                    let title = c["title"];
                    let value = r[header_indexes[colIndex]];
                    let formatter = c["formatter"];
                    if (formatter != null && ( typeof value === "string" ) ) {                        
                        if (formatter == this.ad.percentNumberFormatter) {
                            value = value.replace("%", "");
                            value = parseFloat(value);
                        }
                        else if (formatter instanceof MIONumberFormatter) {
                            value = parseFloat(value);
                        }     
                    }
                    item[title] = value;
                }
                items.addObject(item);
            }                            
        }

        return items;
    }

    private entityCache = {};
    protected entities() { return []; }
    queryEntity(entityName:string, predicateFormat:string){
        let objects = this.entityCache[entityName];
        if (objects == null || objects.length == 0) return null;
        if (predicateFormat == null) return objects[0];

        let objs = _MIOPredicateFilterObjects(objects, MIOPredicate.predicateWithFormat(predicateFormat));
        if (objs.length == 0) return null;

        return objs[0];
    }

    queryEntityByField(entityName:string, field:string, value:any, comparator:string = "=") : any {
        if (value == null) return null;
        let v = value;
        if (typeof value === 'string') {
            if (value.length == 0) return null;
            v = "\"" + value + "\"";
        }        
        let predicateFormat = field + comparator + v;
        return this.queryEntity(entityName, predicateFormat);
    }

    appendObject(obj:MIOManagedObject) {
        let entity_name = obj.entity.name;
        let objects = this.entityCache[entity_name];
        if (objects == null) {
            objects = [];
        }

        objects.push(obj);
        this.entityCache[entity_name] = objects;        
    }

    private entitiesCount = 0;
    importItems(items){
        this.rows = items;

        let query_entities = this.entities();
        this.entitiesCount = query_entities.length;

        for (let index = 0; index < query_entities.length; index++){
            let item = query_entities[index];
            let entity_name = item["entity"];
            if (entity_name == null) continue;
            let pf = item["predicateFormat"];
            let rels = item["relations"] ?? [];

            let predicateFormat = pf != null ? MIOPredicate.predicateWithFormat(pf) : null;
            DBHelper.queryObjectsWithCompletion(entity_name, null, predicateFormat, rels, this, function(this:DBImportController, objs:any[]) {
                this.entityCache[entity_name] = objs;
                this.entitiesCount--;
                if (this.entitiesCount == 0) {
                    this.rowIndex = 0;
                    this.parseNextRow();        
                }
            });
        }
    }

    protected rows = null;
    protected rowIndex = 0;
    protected parseNextRow() {
        let pvc = ProgressAlertViewController.newInstance();
        AppHelper.sharedInstance().presentViewController(pvc, true);

        for (let index = this.rowIndex; index < this.rows.length; index++ ){
            pvc.title = MIOLocalizeString("IMPORTING...", "IMPORTING...") + " " + index + "/" + this.rows.length;
            let row = this.rows[index];
            this.importRow(row);
        }

        DBHelper.saveMainContextWithCompletion(this, function() {
            pvc.dismissViewController(true);
            AppHelper.showInfoMessage(null, "DBIMPORT", "THE IMPORT OF THE ITEMS IS DONE");
        });
    }

    protected importRow(row){}

    parseBoolValue(value:any):boolean {
        if (value == null) return false;

        let result = false;        
        if (typeof value === 'string') {
            if (value.toLowerCase() == "1") result = true;
            else if (value.toLowerCase() == "true") result = true;
            else if (value.toLowerCase() == "yes") result = true;
        }
        else if (typeof value === 'number') {
            if (value == 1) result = true;
        }

        return result;
    }
    
    queryEntityProduct(reference:string, name:string) : Product {
        let product:Product = null;
        if ( reference != null ) product = this.queryEntity("Product", "reference = \"" + reference + "\"") as Product;
        if ( product == null ) product = this.queryEntity("Product", "name = \"" + name + "\"") as Product;
        return product;
    }

}
