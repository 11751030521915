
class ProductPresetsGroupListViewController extends EntityListViewController
{

    private closeButton:MUIButton = null;
    private addButton:MUIButton = null;
    private ppgdvc:ProductPresetsGroupDetailViewController = null;
    

    viewDidLoad(){
        super.viewDidLoad();

        this.closeButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.closeButton.setAction(this, function(){
            this.dismissViewController(true);
        }); 

        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function(){
           this.addPreset();
        });

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);

        this.updateUI();

        // MIONotificationCenter.defaultCenter().addObserver(this, "UpdateTimeRangePresetList", function(note:MIONotification){
        //     this.setFetchedResultsController(null);
        //     this.tableView.reloadData();
        // });
    }

    updateUI(){
        if (this.viewIsLoaded == false) return;

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }
    
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let cell = tableView.dequeueReusableCellWithIdentifier("ProductPresetsGroupListCell") as ProductPresetsGroupListCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as PresetGroup;
        cell.item = item;
        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath) as PresetGroup;
        this.showItemDetail(selectedItem);
    }

    // editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) { 
    //     return UITableViewCellEditingStyle.Delete;
    // }

    // commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
    //     let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
    //     if (editingStyle == UITableViewCellEditingStyle.Delete) {
    //         DBHelper.deleteObjectFromMainContext(item, true);
    //     }
    // }

    private showItemDetail(item:PresetGroup){     
        if (item == null) return;
        
        if(this.ppgdvc == null) {
            this.ppgdvc = new ProductPresetsGroupDetailViewController("product-presets-group-detail-view");
            this.ppgdvc.initWithResource("layout/productpresets/ProductPresetsDetailView.html");
        }
        
        this.ppgdvc.item = item;
        this.navigationController.pushViewController(this.ppgdvc, true);
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
        let fetchRequest:MIOFetchRequest = null;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true), MIOSortDescriptor.sortDescriptorWithKey("orderIndex", true)]; 
        let predicate = null;
        //let predicate = MIOPredicate.predicateWithFormat('timeRangeGroup.type == "' + this._timeRangeGroupType + '"');   
        fetchRequest = DBHelper.listFetchRequestWithEntityName("PresetGroup", sortDescriptors, predicate);
        //fetchRequest.relationshipKeyPathsForPrefetching = ['timeRangeGroups'];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    

    private addPreset() {

        // let avc = new MUIAlertViewController();
        // avc.initWithTitle(MIOLocalizeString('NEW TIME RANGE PRESET','NEW TIME RANGE PRESET'), MIOLocalizeString('CHOOSE A NAME','CHOOSE A NAME'), MUIAlertViewStyle.Default);
        // avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
        //     textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        // });
        // avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD','ADD'), MUIAlertActionStyle.Default, this, function(){
        //     let nameTF =  avc.textFields[0];
        //     let name = nameTF.text.trim();

        //     if(name.length > 0){
        //         let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        //         let newTimeRangePreset = MIOEntityDescription.insertNewObjectForEntityForName("TimeRangePreset", moc) as TimeRangePreset;
        //         newTimeRangePreset.identifier = MIOUUID.UUID().UUIDString;
        //         newTimeRangePreset.name = name;
        //         moc.save()
        //     }
        //     else {
        //         let error = new MUIAlertViewController();
        //         error.initWithTitle(MIOLocalizeString('ERROR', 'Error'), MIOLocalizeString('CHECK NAME','CHECK NAME'), MUIAlertViewStyle.Default);
        //         error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','DONE'), MUIAlertActionStyle.Cancel, this, function(){}));
        //         this.presentViewController(error, true);
        //     }
        // }));
        // avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Cancel, this, function(){}));
        
        // this.presentViewController(avc, true);
    }

   
}