
class UserAccessControlViewController extends MUIViewController
{
    static newInstance() : UserAccessControlViewController {
        let vc = new UserAccessControlViewController("user-access-control-view");
        vc.initWithResource("layout/users/UserAccessControlView.html");
        return vc;
    }

    private tableView:UITableView = null;

    delegate = null;

    get preferredContentSize(): MIOSize {
        return new MIOSize(320, 500);
    }

    viewDidLoad() {
        
        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.tableView.reloadData();
    }

    private _sectionIndex = 0;
    set sectionIndex(sectionIndex:number) {
        this._sectionIndex = sectionIndex;
        if (this.tableView) this.tableView.reloadData();
    }

    private userAccessControlTypes = [
        "ACCESS_CONTROL_VIEW", 
        "ACCESS_CONTROL_CREATE",         
        "ACCESS_CONTROL_UPDATE", 
        "ACCESS_CONTROL_DELETE", 
        "ACCESS_CONTROL_PROCESS",
        "ACCESS_CONTROL_UNPROCESS"
    ];

    private userAccessControlMapping = {
        "ACCESS_CONTROL_VIEW": UserAccessControlBit.view,
        "ACCESS_CONTROL_CREATE": UserAccessControlBit.create,         
        "ACCESS_CONTROL_UPDATE": UserAccessControlBit.update, 
        "ACCESS_CONTROL_DELETE": UserAccessControlBit.delete, 
        "ACCESS_CONTROL_PROCESS": UserAccessControlBit.process,
        "ACCESS_CONTROL_UNPROCESS": UserAccessControlBit.unprocess
    }

    private get user() : User {
        let user = this.delegate._user;
        return user;
    }

    numberOfSections(tableView:UITableView) {
        return 1;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number) {        
        return this.userAccessControlTypes.count;
    }
    
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {
        
        let cell = tableView.dequeueReusableCellWithIdentifier('UserAccessControlCell') as UserAccessControlCell;
        let key = this.userAccessControlTypes[indexPath.row];
        cell.title = MIOLocalizeString(key, key);
        
        let flag = this.user.checkAccessControlFlag(this._sectionIndex, this.userAccessControlMapping[key]);
        cell.accessoryType = flag ? UITableViewCellAccessoryType.Checkmark : UITableViewCellAccessoryType.None;
        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {    
        let key = this.userAccessControlTypes[indexPath.row];
        this.user.toggleAccessControlFlag(this._sectionIndex, this.userAccessControlMapping[key]);
        let cell = tableView.cellAtIndexPath(indexPath);
        let flag = this.user.checkAccessControlFlag(this._sectionIndex, this.userAccessControlMapping[key]);
        cell.accessoryType = flag ? UITableViewCellAccessoryType.Checkmark : UITableViewCellAccessoryType.None;
    }



}