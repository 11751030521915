class WineCellarProductsViewController extends MUIViewController
{

    static newInstance() : WineCellarProductsViewController {
        let vc = new WineCellarProductsViewController("wine-cellar-products-view");
        vc.initWithResource("layout/winecellar/WineCellarProductsView.html");
        return vc;
    }

    private titleBar:MUIView = null;
    private filterColumnTemplate:MUIView = null;

    private refFilterTextField:ColumnFilterTextField = null; 

    private columnFilterController:ColumnFilterController = null;

    //TODO: free when change business workspace
    static wineCellars:WineCellar[] = [];

    viewDidLoad(){
        super.viewDidLoad();
        
        this.titleBar = MUIOutlet(this, "title-bar", "MUIView");
        this.filterColumnTemplate = MUIOutlet(this, "repeat-title-item", "ColumnFilterTextField");
        this.filterColumnTemplate.hidden = true;
        
        this.columnFilterController = new ColumnFilterController();
        this.columnFilterController.initWithDelegate(this);

        this.refFilterTextField = MUIOutlet(this, "ref-filter-tf", "ColumnFilterTextField");
        this.refFilterTextField.filterController = this.columnFilterController;
        this.refFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "reference", null, null);

        WineCellarProductsViewController.wineCellars = DBCacheHelper.sharedInstance().objectsForEntityName("WineCellar", [MIOSortDescriptor.sortDescriptorWithKey("orderIndex", true)], null);

        // Create the columns we need to display        
        for (const wc of WineCellarProductsViewController.wineCellars){
            let c = this.filterColumnTemplate.copy();
            c.hidden = false;
            c.placeholderText = wc.name;
            c.filterController = this.columnFilterController;
            c.setOnFilterChange(ColumnFilterTextFieldType.String, wc.name, null, null);
            this.titleBar.addSubview(c);
        }
        
        this.tableView = MUIOutlet(this, "table-view", "UITableView");        
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(){
        super.viewWillAppear();
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        this.fetchedResultsController = null;
        const objs = this.fetchedResultsController.fetchedObjects.count;
        this.tableView.reloadData();
    }
 
    numberOfSections(tableView:UITableView){
        //return this.fetchedResultsController.sections.length;
        return 1;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        // let sec = this.fetchedResultsController.sections[section];
        // return sec.numberOfObjects();
        return this.filter_products.length;
    }


    // titleForHeaderInSection(tableview:UITableView, section:number) {
    //     let ip = MIOIndexPath.indexForRowInSection(0, section);
    //     let item = this.fetchedResultsController.objectAtIndexPath(ip) as Product;
    //     return item.wineCellarHash;
    // }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){        
        let item = this.filter_products[ indexPath.row ];
        //let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Product;
        let cell = tableview.dequeueReusableCellWithIdentifier('WineCellarProductCell') as WineCellarProductCell;
        cell.wineCellars = this.wine_cellars;
        cell.item = item["product"];
        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let item = this.filter_products[ indexPath.row ];
        //let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Product;        
        // let vc = SyncLogEditViewController.newInstance();
        // vc.item = item;
        // this.navigationController.pushViewController(vc, true);
    }

    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Product;

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            this.removeWineCellarProduct(item);
        }
    }

    protected _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value) {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        //predicate should filter only products that have a WineCellar configuration
        let predicate = null;
    
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        
        let sds = [ 
            MIOSortDescriptor.sortDescriptorWithKey("wineCellarHash", true),
            MIOSortDescriptor.sortDescriptorWithKey("name", true)
        ];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName( "Product", sds, "wineCellarDict != null" );
        fetchRequest.fetchLimit = 150;
       
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest( fetchRequest, ad.managedObjectContext, null );
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        this.mapFilters(controller.fetchedObjects as Product[]);
        this.tableView.reloadData();
    }

    private filter_products = []

    private mapFilters(products:Product[]) {        
        this.filter_products = [];
        for (const p of products){
            let item = { "product": p };
            let cellar_components = p.wineCellarHash.split("#");
            let index = 0;
            for (let i = 0; i < WineCellarProductsViewController.wineCellars.length; i++){
                let key = WineCellarProductsViewController.wineCellars[i].name;
                item[ key ] = cellar_components[i];
                index++;
            }    
            
            this.filter_products.addObject(item);
        }        
    }

    filterPredicateDidChange(controller:ColumnFilterController){        

        //let predicateFormat = this.columnFilterController.filterPredicateFormat();

        //this.tableView.reloadData();
    }

    private removeWineCellarProduct(item:Product) {        
    }

}

class TitleBarColumn extends MUIView 
{
    private titleLabel:MUILabel = null;

    initWithLayer(layer, owner, options?){
        super.initWithLayer(layer, owner, options);
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
    }

    set title(value:string){
        this.titleLabel.text = value;
    }
}