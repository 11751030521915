

/// <reference path="IntegratorConfig.ts" />

// Generated class IntegratorConfigTags_ManagedObject

class IntegratorConfigTags_ManagedObject extends IntegratorConfig
{

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }
}
