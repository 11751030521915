
enum SelectDocumentViewControllerType 
{
    posReceipt,
    posInvoice,
    salesInvoice,
    purchaseInvoice,
    budget,
    supplierNote
}

interface SelectDocumentDelegate
{
    documentsDidSelected(controller:SelectDocumentViewController, documents:MIOManagedObject[]):void;
}

class SelectDocumentViewController extends MUIViewController
{
    delegate:SelectDocumentDelegate = null;
    documentType = SelectDocumentViewControllerType.posReceipt;
    
    static newInstance(): SelectDocumentViewController {
        let vc = new SelectDocumentViewController("select-documents-view");
        vc.initWithResource("layout/archived_document/SelectDocumentsView.html");
        return vc;
    }

    private closeButton:MUIButton = null;   
    private optionsButton:MUIButton = null;
    private createButton:MUIButton = null;
    
    private legalNameDropdown:MUIButton = null;
    private editClientButton:MUIButton = null;
    private dateFilterTextField:ColumnFilterTextField = null;    
    private documentIDFilterTextField:ColumnFilterTextField = null;    
    private legalNameFilterTextField:ColumnFilterTextField = null;    
    private totalFilterTextField:ColumnFilterTextField = null;    
    private tableView:UITableView = null; 

    private documentTableView:UITableView = null;
    private totalLabel:MUILabel = null;

    private columnFilterController:ColumnFilterController = null;    
    private selectedDocumentDataSource:SelectedDocumentDataSource = null;    

    get preferredContentSize(){
        return new MIOSize(750, 700);
    }

    viewDidLoad(){
        super.viewDidLoad();

        this.columnFilterController = new ColumnFilterController();
        this.columnFilterController.initWithDelegate(this);

        this.closeButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.closeButton.setAction(this, function(){
            this.dismissViewController(true);
        });      
        
        this.optionsButton = MUIOutlet( this, "options-btn", "MUIButton" );
        this.optionsButton.setAction( this, this.showOptions );

        this.createButton = MUIOutlet(this, "create-btn", "MUIButton");
        this.createButton.setAction(this, this.createAction);

        this.legalNameDropdown = MUIOutlet(this, "legal-entity-dd", "MUIButton");
        this.legalNameDropdown.setAction(this, this.selectLegalEntityAction);        

        this.editClientButton = MUIOutlet(this, "edit-client-btn", "MUIButton");
        this.editClientButton.enabled = false;
        this.editClientButton.setAction(this, this.editClientAction);

        this.dateFilterTextField = MUIOutlet(this, "date-filter-tf", "ColumnFilterTextField");
        this.dateFilterTextField.filterController = this.columnFilterController;
        this.dateFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.DateTime, "date", null, null);

        this.documentIDFilterTextField = MUIOutlet(this, "document-filter-tf", "ColumnFilterTextField");
        this.documentIDFilterTextField.filterController = this.columnFilterController;
        this.documentIDFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "_document_id_", null, null);

        this.legalNameFilterTextField = MUIOutlet(this, "client-filter-tf", "ColumnFilterTextField");
        this.legalNameFilterTextField.filterController = this.columnFilterController;
        this.legalNameFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "_legal_entity_name_", null, null);

        this.totalFilterTextField = MUIOutlet(this, "total-filter-tf", "ColumnFilterTextField");
        this.totalFilterTextField.filterController = this.columnFilterController;
        this.totalFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "totalAmount", null, null);

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;                

        this.documentTableView = MUIOutlet(this, "documents-table-view", "UITableView");
        this.selectedDocumentDataSource = new SelectedDocumentDataSource();
        this.selectedDocumentDataSource.init();
        this.documentTableView.dataSource = this.selectedDocumentDataSource;
        this.documentTableView.delegate = this.selectedDocumentDataSource;
        this.selectedDocumentDataSource.documents = this.selectedDocuments;
        this.selectedDocumentDataSource.delegate = this;

        this.totalLabel = MUIOutlet(this, "selected-total-lbl", "MUILabel");
        this.totalLabel.text = this.selectedTicketTotalString();
    }
    
    private documentIDKey = "legalDocumentID";
    private totalAmountKey = "totalPrice";
    private entityName = "ArchivedDocument";
    private predicateFormat = null;
    private relations = [];

    viewWillAppear(animated?:boolean){
        super.viewWillAppear(animated);

        switch (this.documentType) {
            case SelectDocumentViewControllerType.posReceipt:
                this.documentIDFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "legalDocumentID", null, null);
                this.legalNameFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "linkedLegalEntityName", null, null);
                this.totalFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "totalPrice", null, null);
                this.entityName = "ArchivedTicket";
                this.documentIDKey = "legalDocumentID";
                this.totalAmountKey = "totalPrice";
                this.predicateFormat = "legalDocumentID != null AND nextDocument == null AND modifiedType != 1 AND totalPrice != 0";
                break;
            case SelectDocumentViewControllerType.posInvoice: 
                this.documentIDFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "legalDocumentID", null, null);
                this.legalNameFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "invoiceLegalEntityName", null, null);
                this.totalFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "totalPrice", null, null);
                this.entityName = "ArchivedInvoice";
                this.documentIDKey = "legalDocumentID";
                this.totalAmountKey = "totalPrice";
                this.predicateFormat = "legalDocumentID != null AND nextDocument == null AND modifiedType != 1 AND totalPrice != 0";
                break;
            case SelectDocumentViewControllerType.supplierNote:
                this.documentIDFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "documentID", null, null);
                this.legalNameFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "originName", null, null);
                this.totalFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "totalAmount", null, null);
                this.entityName = "SupplierNote";
                this.documentIDKey = "documentID"; 
                this.totalAmountKey = "totalAmount";
                this.predicateFormat = "nextDocument == null";
                break;

            case SelectDocumentViewControllerType.salesInvoice:
                this.documentIDFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "documentNumber", null, null);
                this.legalNameFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "legalEntity.name", null, null);
                this.totalFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "totalAmount", null, null);
                this.entityName = "SalesInvoice";
                this.documentIDKey = "documentNumber";
                this.totalAmountKey = "totalAmount";
                this.predicateFormat = null; //"nextDocument == null";
                this.relations = ["legalEntity"];
                break;

            case SelectDocumentViewControllerType.purchaseInvoice:
                this.documentIDFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "documentNumber", null, null);
                this.legalNameFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "legalEntity.name", null, null);
                this.totalFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "totalAmount", null, null);
                this.entityName = "PurchaseInvoice";
                this.documentIDKey = "documentNumber";
                this.totalAmountKey = "totalAmount";
                this.predicateFormat = null; //"nextDocument == null";
                this.relations = ["legalEntity"];
                break;
    
        }


        this.tableView.reloadData();
    }

    viewWillDisappear(animated?:boolean){
        super.viewWillDisappear(animated);
        this.fetchedResultsController = null;
    }

    //
    // Tableview delegate
    //

    numberOfSections(tableView:UITableView){
        // return this.fetchedResultsController.sections.length + 1;
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        // if (this.fetchedResultsController.sections.length == section) return 1;

        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {
        // if (this.fetchedResultsController.sections.length == indexPath.section) return tableView.dequeueReusableCellWithIdentifier("MoreCell");

        let cell = tableView.dequeueReusableCellWithIdentifier("DocumentCell") as SelectDocumentCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as MIOManagedObject;
        
        cell.item = item;
        cell.delegate = this;
        cell.selectedItem = this.selectedDocuments.containsObject(item);

        return cell;
    }   
    
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        if (indexPath.section < this.fetchedResultsController.sections.length) return;
        
        this.fetchedResultsController.performNextFetch();        
    }

    private selectedDocuments = [];
    private selectedDocumentsTotal = 0;        
    addDocumentFromCell(cell:SelectDocumentCell, item:MIOManagedObject){
        if (this.selectedDocuments.containsObject(item) == true) {
            this.selectedDocuments.removeObject(item);
            this.selectedDocumentsTotal -= item.valueForKey( this.totalAmountKey );
            cell.selectedItem = false;
        }
        else {
            this.selectedDocuments.addObject(item);
            this.selectedDocumentsTotal += item.valueForKey( this.totalAmountKey );
            cell.selectedItem = true;
        }

        this.documentTableView.reloadData();                
        this.totalLabel.text = this.selectedTicketTotalString();
    }

    removeSelectedDocument(item:MIOManagedObject){
        this.selectedDocuments.removeObject(item);
        this.selectedDocumentsTotal -= item.valueForKey( this.totalAmountKey );
        this.documentTableView.reloadData();
        if (this.selectedDocuments.length == 0) this.selectedDocumentsTotal = 0;
        this.totalLabel.text = this.selectedTicketTotalString();

        let ip = this.fetchedResultsController.indexPathForObject(item);
        if (ip != null) {
            let cell = this.tableView.cellAtIndexPath(ip) as SelectDocumentCell;
            cell.selectedItem = false;
        }
    }

    private _fetchedResultsController:MWSFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let filterFormat = this.columnFilterController.filterPredicateFormat();
        let predicates = [];

        if (this.predicateFormat != null) predicates.push( this.predicateFormat );                
        if (filterFormat != null) predicates.push( filterFormat );
        if (this.customPredicate != null) predicates.push( this.customPredicate );
        

        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey("date", false), 
        MIOSortDescriptor.sortDescriptorWithKey(this.documentIDKey, false)];
    
        let fetchRequest = DBHelper.listFetchRequestWithEntityName(this.entityName, sortDescriptors, predicates.join(" AND ") );
        fetchRequest.relationshipKeyPathsForPrefetching = this.relations;
        fetchRequest.fetchLimit = 50;  
                
        let fetchedResultsController = new MWSFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.webPersistentStore = ad.webPersistentStore;
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        this.tableView.reloadData();
    }

    filterPredicateDidChange(controller:ColumnFilterController){        
        this.fetchedResultsController = null;
        this.tableView.reloadData();        
    }
    
    private selectLegalEntityAction(){
        switch (this.documentType) {
            case SelectDocumentViewControllerType.posInvoice: this.selectClientAction(); break;
            case SelectDocumentViewControllerType.posReceipt: this.selectClientAction(); break;
            case SelectDocumentViewControllerType.supplierNote: this.selectSupplierAction(); break;
            case SelectDocumentViewControllerType.salesInvoice: this.selectClientAction(); break;
            case SelectDocumentViewControllerType.purchaseInvoice: this.selectSupplierAction(); break;
        }
    }

    private selectedClient:Client = null;
    private selectClientAction(){
        AppHelper.sharedInstance().showSelectClientViewControllerFromView(this.legalNameDropdown, null, true, this, function(this:SelectDocumentViewController, controller:any, client:Client){
            this.legalNameDropdown.title = client.name;
            this.selectedClient = client;
            this.editClientButton.enabled = true;
            this.filterByClient( client );
        }, this, function(){
            let vc = new AddNewClientViewController("add-new-client-view");
            vc.initWithResource("layout/clients/AddNewClientView.html");
            vc.delegate = this;
            this.presentViewController(vc, true);
        });
    }

    clientDidInserted(client:Client){
        this.legalNameDropdown.title = client.name;
        this.selectedClient = client;
        this.editClientButton.enabled = true;
    }

    private editClientAction(){
        let vc = new AddNewClientViewController('add-new-client-view');        
        vc.initWithResource('layout/clients/AddNewClientView.html');
        vc.client = this.selectedClient;
        this.presentViewController(vc, true);
    }

    private selectedSupplier:Supplier = null;
    private selectSupplierAction(){
        AppHelper.sharedInstance().showSelectSupplierViewControllerFromView(this.legalNameDropdown, null, true, this, function(this:SelectDocumentViewController, controller:any, supplier:Supplier){
            this.legalNameDropdown.title = supplier.name;
            this.selectedSupplier = supplier;
            this.filterBySupplier(supplier);
        });
    }

    private selectedTicketTotalString(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        return ad.currencyFormatter.stringFromNumber(this.selectedDocumentsTotal);
    }

    private createAction( ){
        if (this.delegate != null) { this.delegate.documentsDidSelected(this, this.selectedDocuments); return; }

        if ( (this.documentType == SelectDocumentViewControllerType.posInvoice || this.documentType == SelectDocumentViewControllerType.posReceipt) && this.selectedClient == null) {
            AppHelper.showErrorMessage(this, "Error", "Please select a client.");
            return;
        }

        if ( this.documentType == SelectDocumentViewControllerType.supplierNote && this.selectedSupplier == null) {
            AppHelper.showErrorMessage(this, "Error", "Please select a client.");
            return;
        }

        if (this.selectedDocuments.length == 0){
            AppHelper.showErrorMessage(this, "Error", "Please select a ticket or tickets");
            return;
        }

        if (this.selectedDocumentsTotal == 0) {
            AppHelper.showErrorMessage(this, "Error", "Sorry. It's not possible to make an invoce of 0");
            return;
        }

        this.createButton.enabled = false;
        this.closeButton.enabled = false;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        if (this.documentType == SelectDocumentViewControllerType.posInvoice || this.documentType == SelectDocumentViewControllerType.posReceipt) {
            
            let date = this.custom_date;
            let serie = this.custom_number_serial;
            let nullify_serie = this.custom_nullify_number_serial;


            ad.webService.createInvoiceFromMultipleTickets(this.selectedDocuments, this.selectedClient, date, serie, nullify_serie, (json:any, error:string) => {
                this.createButton.enabled = true;
                this.closeButton.enabled = true;                
                if (error != null) {
                    AppHelper.showErrorMessage( null, "Invoice error", error );
                    return;
                }

                MIONotificationCenter.defaultCenter().postNotification("ReloadAndShowPOSInvoiceNotification", json["ID"], null);
                this.dismissViewController(true);
            });        
        }
        else if (this.documentType == SelectDocumentViewControllerType.supplierNote) {

            ad.webService.createInvoiceFromMultipleSupplierNotes(this.selectedDocuments, this.selectedSupplier, null, null, (json:any, error:string) => {
                this.createButton.enabled = true;
                this.closeButton.enabled = true;
                if (error != null) {
                    AppHelper.showErrorMessage(null, "Purchase Invoice error", error );
                    return;
                }
                
                MIONotificationCenter.defaultCenter().postNotification("ReloadAndShowPurchaseInvoiceNotification", json["id"], null);
                this.dismissViewController(true);
            });
        }
    }

    private customPredicate = null;
    private filterByClient(client:Client){
        this.customPredicate = this.documentType == SelectDocumentViewControllerType.posReceipt ? "linkedLegalEntityID = " + client.identifier : null;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    private filterBySupplier(supplier:Supplier){
        this.customPredicate = "originEntity.identifier = " + supplier.identifier;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    private custom_date:Date = null;
    private custom_number_serial:NumberSerialSequence = null;
    private custom_number_serial_index = -1;
    private custom_nullify_number_serial:NumberSerialSequence = null;
    private custom_nullify_number_serial_index = -1;

    private default_number_serial_index = -1;

    private showOptions(){

        DBHelper.queryObjectsWithCompletion( "NumberSerialSequence", null, MIOPredicate.predicateWithFormat( "deletedAt = null" ), [], this, function( objects:NumberSerialSequence[]){         

            let avc = new MUIAlertViewController();
            avc.initWithTitle(MIOLocalizeString("OPTIONS", "OPTIONS"), MIOLocalizeString("CHOOSE INVOICE OPTIONS", "CHOOSE INVOICE OPTIONS"), MUIAlertViewStyle.Default);

            avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
                textField.placeholderText = MIOLocalizeString("DATE", "DATE");
            });
            
            avc.addComboBoxWithConfigurationHandler(this, function (comboBox: MUIComboBox) {                
                comboBox.removeAllItems();                
                for (let i = 0; i < objects.length; i++) {                    
                    let item = objects[i];
                    if (item.entityNameType != "ArchivedInvoice") continue;
                    comboBox.addItem( item.name, i ); 
                    if ( item.entityType == 102 ) this.default_number_serial_index = i;  // 102: Means the default one for invoces. why? I dunno
                    if ( this.custom_number_serial != null && item.identifier == this.custom_number_serial.identifier ) this.custom_number_serial_index = i;
                }               
                comboBox.selectItem( this.custom_number_serial_index > -1 ? this.custom_number_serial_index : this.default_number_serial_index ); 
            });

            avc.addComboBoxWithConfigurationHandler(this, function (comboBox: MUIComboBox) {            
                comboBox.removeAllItems();                
                for (let i = 0; i < objects.length; i++) {
                    let item = objects[i];
                    if (item.entityNameType != "ArchivedTicket") continue;
                    comboBox.addItem( item.name, i ); 
                    // if ( item.entityType == 102 ) this.default_number_serial_index = i;  // 102: Means the default one for invoces. why? I dunno
                    // if ( this.custom_number_serial != null && item.identifier == this.custom_number_serial.identifier ) this.custom_number_serial_index = i;
                }               
                // comboBox.selectItem( this.custom_number_serial_index > -1 ? this.custom_number_serial_index : this.default_number_serial_index ); 
            });

            avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("DONE", "DONE"), MUIAlertActionStyle.Default, this, function(this:SelectDocumentViewController){            

                let date_str = avc.textFields[0].text;
                let date = date_str.trim() == 0 ? null : (MUIWebApplication.sharedInstance().delegate as AppDelegate).dateTimeFormatter.dateFromString( date_str );

                let number_series_index = avc.comboBoxes[ 0 ].getSelectedItem();
                let number_series = objects[ number_series_index ];

                let nullify_number_series_index = avc.comboBoxes[ 1 ].getSelectedItem();
                let nullify_number_series = objects[ nullify_number_series_index ];

                this.custom_date = date;
                this.custom_number_serial = number_series;
                this.custom_number_serial_index = number_series_index;
                this.custom_nullify_number_serial = nullify_number_series;
                this.custom_nullify_number_serial_index = number_series_index;
            }));

            avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));
            
            avc.addCompletionHandler(this, function () {
                // Release the observers                            
            });

            this.presentViewController(avc, true);
        
        } );
    }

}
