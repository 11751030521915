

class MenuListCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    private imageView:MUIImageView = null;
    private _separator = null;

    awakeFromHTML()
    {
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");

        this.separatorStyle = UITableViewCellSeparatorStyle.None;
    }

    set item(i:Menu)
    {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this.nameLabel.text = i.name + (i.placeID != null && i.placeID.toLowerCase() != ad.selectedIdentifier.toLowerCase() ? " 🔒" : "" );
    }

}