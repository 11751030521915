//
// Generated class Margin
//

/// <reference path="Margin_ManagedObject.ts" />

class Margin extends Margin_ManagedObject
{

}
