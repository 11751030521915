/// <reference path="WebService.ts" />


interface WebService
{    
    nullifyInvoice( doc:Invoice, completion:any ) : void ;    
}

WebService.prototype.nullifyInvoice = function( doc:Invoice, completion:any ) {
    // if ( this.lock_doc_id.containsObject( doc.identifier ) ) return;
    // this.lock_doc_id.addObject(doc.identifier);        

    const url_str = this.apiURL + "/entity/" + this.scheme + "/DBDocument/" + doc.identifier + "/void"
    let r = new DLRequest (url_str, { "recoverStock": false }, "POST");
    r.execute( (json:any, error:string) => {
        // this.lock_doc_id.removeObject( doc.identifier );

        if (error != null) {
            AppHelper.showErrorMessage( null, MIOLocalizeString("ERROR", "ERROR"), error );
        }
        else {
            let doc_number = json["DocumentNumber"];
            let msg = MIOLocalizeString( "VOID INVOICE CREATED SUCCESFULLY", "VOID INVOICE CREATED SUCCESFULLY");
            AppHelper.showInfoMessage( null, MIOLocalizeString("INFO", "INFO"), doc_number + " " + msg );
        }

        completion(json, error);
    });
}

