
class OnlineOrderListHeaderView extends MUIView
{
    private label:MUILabel = null;

    awakeFromHTML(){
        this.label = MUIOutlet(this, "header-lbl", "MUILabel");
    }

    set text(text:string){
        this.label.text = text;
    }
}