
class BankListViewController extends BaseTableViewController
{
    static newInstance() : BankListViewController {
        let vc = new BankListViewController("bank-list-view")
        vc.initWithResource("layout/bankaccount/BankListView.html")
        return vc;
    }

    private addButton:MUIButton = null;    

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, this.addBankAction);

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.tableView.reloadData();
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){                                
        let cell = tableview.dequeueReusableCellWithIdentifier('BankCell') as BankCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Bank;
        
        cell.item = item;
        cell.selected = (item.identifier == this.selectedItem?.identifier);
        
        return cell;            
    }
    
    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath) as Bank;
        
        let vc = BankDetailViewController.newInstance();
        vc.item = this.selectedItem;

        let nc = new MUINavigationController();
        nc.initWithRootViewController(vc);

        this.splitViewController.showDetailViewController(nc);
    }

    protected _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value:MIOFetchedResultsController){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let sds = [ MIOSortDescriptor.sortDescriptorWithKey("name", false) ];

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Bank", sds, null);
                    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }

    private addBankAction() {

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ADD BANK", "ADD BANK"), MIOLocalizeString("ADD BANK NAME", "ADD BANK NAME"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
            textField.placeholderText = MIOLocalizeString("NAME", "NAME");
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Default, this, function(this:BankListViewController) {
            let name = avc.textFields[0].text;
            this.addBank(name);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }    

    private addBank(name:string) {
        if (name == null) { AppHelper.showErrorMessage(this, MIOLocalizeString("BANK ERROR CREATION","BANK ERROR CREATION"), MIOLocalizeString("THE NAME IS EMPTY","THE NAME IS EMPTY")); return;}

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let bank = MIOEntityDescription.insertNewObjectForEntityForName("Bank", ad.managedObjectContext) as Bank;
        bank.identifier = (new MIOUUID()).UUIDString;
        bank.name = name;

        ad.managedObjectContext.save();
    }

    // private showNoItemSelectionViewController(){
    //     let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("BankAccount", NoItemSelectedViewControllerType.BankAccount);
    //     this.navigationController.splitViewController.showDetailViewController(vc);   
    // }

}