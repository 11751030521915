

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class TipConfiguration_ManagedObject

class TipConfiguration_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: appAreaID
    set appAreaID(value:number) {
        this.setValueForKey(value, 'appAreaID');
    }
    get appAreaID():number {
        return this.valueForKey('appAreaID');
    }
    set appAreaIDPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'appAreaID');
    }
    get appAreaIDPrimitiveValue():number {
        return this.primitiveValueForKey('appAreaID');
    }

    // Property: appSubAreaID
    set appSubAreaID(value:number) {
        this.setValueForKey(value, 'appSubAreaID');
    }
    get appSubAreaID():number {
        return this.valueForKey('appSubAreaID');
    }
    set appSubAreaIDPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'appSubAreaID');
    }
    get appSubAreaIDPrimitiveValue():number {
        return this.primitiveValueForKey('appSubAreaID');
    }

    // Property: isDefault
    set isDefault(value:boolean) {
        this.setValueForKey(value, 'isDefault');
    }
    get isDefault():boolean {
        return this.valueForKey('isDefault');
    }
    set isDefaultPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isDefault');
    }
    get isDefaultPrimitiveValue():boolean {
        return this.primitiveValueForKey('isDefault');
    }

    // Property: isVisible
    set isVisible(value:boolean) {
        this.setValueForKey(value, 'isVisible');
    }
    get isVisible():boolean {
        return this.valueForKey('isVisible');
    }
    set isVisiblePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isVisible');
    }
    get isVisiblePrimitiveValue():boolean {
        return this.primitiveValueForKey('isVisible');
    }

    // Property: zoneID
    set zoneID(value:string) {
        this.setValueForKey(value, 'zoneID');
    }
    get zoneID():string {
        return this.valueForKey('zoneID');
    }
    set zoneIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'zoneID');
    }
    get zoneIDPrimitiveValue():string {
        return this.primitiveValueForKey('zoneID');
    }
    // Relationship: tip
    set tip(value:Tip) {
        this.setValueForKey(value, 'tip');
    }
    get tip():Tip {
        return this.valueForKey('tip') as Tip;
    }
}
