//
// Generated class ProductCurrentStock
//

/// <reference path="ProductCurrentStock_ManagedObject.ts" />

class ProductCurrentStock extends ProductCurrentStock_ManagedObject
{

}
