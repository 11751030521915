/**
 * Created by crisan on 12/12/16.
 */



class LapsopayPlace extends MIOManagedObject
{
    identifier = null;

    name = null;
    lapsopayID = null;


    setObject(object)
    {
        this.identifier = object["id"];
        this.name = object["name"];
        this.lapsopayID = object["lapsopayID"];
    }
}