


class DashboardStatusCollectionCell extends MUICollectionViewCell {

    private openLabel: MUILabel = null;
    private closedLabel: MUILabel = null;
    private totalLabel: MUILabel = null;

    private isAwake = false;
    awakeFromHTML() {
        if (this.isAwake == true) return;
        this.openLabel = MUIOutlet(this, 'open-lbl', 'MUILabel');
        this.closedLabel = MUIOutlet(this, 'closed-lbl', 'MUILabel');
        this.totalLabel = MUIOutlet(this, 'total-lbl', 'MUILabel');
        this.isAwake = true;
    }

    private report:ReportItem = null;
    set item(report:ReportItem){
        this.report = report;
        this.updateUI();
    }

    private updateItem(items){
        let open = items[0];
        let closed = items[1];

        let openAmount = open["price"] ? parseFloat(open["price"]) : 0;
        let closeAmount = closed["price"] ? parseFloat(closed["price"]) : 0;

        let total = openAmount + closeAmount;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this.openLabel.text = ad.currencyFormatter.stringFromNumber(openAmount);
        this.closedLabel.text = ad.currencyFormatter.stringFromNumber(closeAmount);
        this.totalLabel.text = ad.currencyFormatter.stringFromNumber(total);

    }

    private isShow = false;
    show(){
        this.isShow = true;
        this.updateUI();
    }

    hide() {
        this.isShow = false;
    }

    private updateUI(){
        if (this.isShow == false || this.isAwake == false) return;
        
        ReportHelper.sharedInstance().executeReportQuery(this.report, null, this, function(code, items){
            if (code == 200) this.updateItem(items);            
        });
    }
    
    get reportViewController():ReportResultsViewController {
        
        if (this.report.nextReportID == null) return;
        let report = ReportHelper.sharedInstance().listReportByID[this.report.nextReportID];

        if (report == null) return;

        let columns = report.columns;
        if (columns.length == 0) return null;

        let rvc = new ReportResultsViewController("report-results-view");
        rvc.initWithResource("layout/reports/ReportResultsView.html");
        rvc.backButtonHidden = false;

        rvc.report = report;
        
        for (var index = 0; index < columns.length; index++) {
            let col = columns[index];
            rvc.addColumn(col);
        }

        rvc.columnSubtotalsIndexes = report.subtotals;
        
        return rvc;
    }
}