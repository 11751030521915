

class ReportColumnVisibilityViewController extends MUIViewController
{    
    static newInstance() : ReportColumnVisibilityViewController {
        let vc = new ReportColumnVisibilityViewController("report-column-visiblity-view");
        vc.initWithResource("layout/reports/ReportColumnVisibilityView.html");
        return vc;
    }    

    get preferredContentSize(): MIOSize {
        return new MIOSize(320, 500);
    }

    delegate:any = null;

    private applyButton:MUIButton = null;
    private tableView:UITableView = null;
    viewDidLoad() {
        this.applyButton = MUIOutlet(this, "apply-btn", "MUIButton");
        this.applyButton.setAction(this, this.applyAction);

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(animated?: any): void {
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private reportID:string = null;
    private options:ReportOption[] = [];
    private userOtionsMap = {};
    setColumnOptions(reportID:string, options:ReportOption[]) {
        this.reportID = reportID;
        this.options = options;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;
        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView){
        return this.options.count;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        return this.options[section].columns.length;
    }

    titleForHeaderInSection?(tableView:UITableView, section){
        return MIOLocalizeString(this.options[section].key, this.options[section].key);
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {
        let cell = tableView.dequeueReusableCellWithIdentifier("ColumnCell") as ReportColumnVisibilityCell;
        let col = this.options[indexPath.section].columns[indexPath.row];
        cell.item = col;
        return cell;
    }   
    
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let cell = tableView.cellAtIndexPath(indexPath) as UITableViewCell;
        let opt = this.options[indexPath.section];
        let col = opt.columns[indexPath.row];
        col.hidden = !col.hidden;        
        cell.accessoryType = col.hidden ? UITableViewCellAccessoryType.None : UITableViewCellAccessoryType.Checkmark;
        if (col.userOption == null) {
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            col.userOption = MIOEntityDescription.insertNewObjectForEntityForName("UserReportOption", ad.managedObjectContext) as UserReportOption;
            col.userOption.reportID = this.reportID;
            col.userOption.reportItemKey = opt.key;
            col.userOption.reportColumnKey = col.key;         
            col.userOption.user = ad.selectedUser;
        }
        col.userOption.hidden = col.hidden;
    }

    private applyAction(){
        DBHelper.saveMainContext();
        if (this.delegate != null) this.delegate.applyVisibilityOptions(this.options);
        this.dismissViewController(true);
    }
}