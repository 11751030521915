
class DBMappingMatchesDataSource extends MIOObject
{
    integrator:string = null;
    enviroment:string = null;
    entity:string = null;
    mappingEntity:string = null;

    private tableView:MUITableView = null;    

    initWithTableView(tableView:MUITableView){
        this.tableView = tableView;
    }
    
    private searchName:string = null;
    searchByName(name:string){
        this.searchCode = null;
        this.searchName = name;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    private searchCode:string = null;
    searchByCode(code:string){
        this.searchCode = code;
        this.searchName = null;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    private _item:DBMappingEntity = null;    
    set item(item:DBMappingEntity){
        this._item = item;

        if (item.foreignID == null) this.searchByName(item.ownName);            
        else this.searchByCode(item.foreignID);                
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableView:MUITableView, section) {
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableView:MUITableView, indexPath: MIOIndexPath) {
        let cell = tableView.dequeueReusableCellWithIdentifier("MatchCell") as DBMatchCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as DBMappingEntity;
        
        cell.setItems(item, this._item);

        return cell;
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey("foreignName", true)];

        let predicateFormat = "integrator == '" + this.integrator + "' AND enviroment == '" + this.enviroment + "'";
        predicateFormat += " AND foreignEntityName == '" + this.mappingEntity + "'";
        predicateFormat += " AND ownID == null";
        
        if (this.searchName != null) {
            let names = this.searchName.toLocaleLowerCase().split(" ");
            let array = [];
            for (let index = 0; index < names.length; index++){
                let n = names[index].trim();
                let v = parseFloat(n);
                if (isNaN(v) == true && n != "y" && n != "con" && n != "de" && n != "&") {
                    array.push('foreignName CONTAINS "' + n + '"');
                }                
            }

            if (array.length > 0){
                predicateFormat += " AND (" + array.join(" OR ") + ")";
            }                        
        }
        else if (this.searchCode != null) predicateFormat += " AND foreignID == '" + this.searchCode + "'";

        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("IntegratorsMappings");
        fetchRequest.sortDescriptors = sortDescriptors;
        fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);
        fetchRequest.fetchLimit = 100;
        
        let fetchedResultsController = new MIOFetchedResultsController();        
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }
}