
class DistributorAgentCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    private emailLabel:MUILabel = null;
    private phoneLabel:MUILabel = null;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "agent-name-lbl", "MUILabel");
        this.emailLabel = MUIOutlet(this, "agent-email-lbl", "MUILabel");
        this.phoneLabel = MUIOutlet(this, "agent-phone-lbl", "MUILabel");

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    set item (item:Contact) {
        this.nameLabel.text = item.name;
        this.emailLabel.text = item.email;
        this.phoneLabel.text = item.phone;
    }
}