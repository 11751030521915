
class AdyenPaymentEntityCell extends UITableViewCell
{
    private nameTextField:MUITextField = null;
    private typeLabel:MUILabel = null;
    private infoButton:MUIButton = null;
    private reviewLinkButton:MUIButton = null;

    awakeFromHTML(){
        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.nameTextField.setOnChangeText(this, function(textField:MUITextField, value:string) {
            this._item.name = value;
        });

        // this.typeLabel = MUIOutlet(this, "type-lbl", "MUILabel");
        this.infoButton = MUIOutlet(this, "info-btn", "MUIButton");
        this.infoButton.setAction(this, this.infoAction );

        // this.reviewLinkButton = MUIOutlet(this, "review-link-btn", "MUIButton");
        // this.reviewLinkButton.setAction(this, this.reviewLinkAction );
        // this.reviewLinkButton.enabled = false;

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    } 
    
    set item(item:AdyenPaymentEntity) {
        this.setItem(item);
    }

    protected _item:AdyenPaymentEntity = null;
    setItem(item:AdyenPaymentEntity){
        this._item = item;
        this.nameTextField.text = item.name;  
        // this.signUpButton.enabled = item.status == AdyenPaymentEntityStatus.none;
        // this.reviewLinkButton.enabled = item.status == AdyenPaymentEntityStatus.created;
    }

    private infoAction()
    {
        let vc = AdyenDetailViewController.newInstance();
        vc.paymentEntity = this._item;
        AppHelper.sharedInstance().presentViewController(vc, true);

        // this.signUpButton.enabled = false;
        // let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        // ad.webService.adyenSignUp( (error) => {
        //     this.signUpButton.enabled = true;
        //     if (error != null) {
        //         AppHelper.sharedInstance().showError(error);
        //         return;
        //     }

        //     this.signUpButton.enabled = false;
        //     this.reviewLinkButton.enabled = true;
        // });
    }

}