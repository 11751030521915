

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class DeliveryPostalCode_ManagedObject

class DeliveryPostalCode_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: postalCode
    set postalCode(value:string) {
        this.setValueForKey(value, 'postalCode');
    }
    get postalCode():string {
        return this.valueForKey('postalCode');
    }
    set postalCodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'postalCode');
    }
    get postalCodePrimitiveValue():string {
        return this.primitiveValueForKey('postalCode');
    }
    // Relationship: deliveryRoute
    set deliveryRoute(value:DeliveryRoute) {
        this.setValueForKey(value, 'deliveryRoute');
    }
    get deliveryRoute():DeliveryRoute {
        return this.valueForKey('deliveryRoute') as DeliveryRoute;
    }
}
