class DBComponentsCoder extends DBXLSXCoder
{
    protected mainEntity() {
        return { entity: "Component", predicateFormat: "deletedAt == null", relations:["parent", "children"] };
    }

    columns() : any[] {
        let cols = [
            { title: "COMPONENT ID", width: "10%", align: "center" },
            { title: "PARENT PRODUCT ID", width: "20%", align: "center" },
            { title: "PARENT PRODUCT NAME", width: "20%", align: "center" },
            { title: "PRODUCT ID", width: "20%", align: "center" },
            { title: "PRODUCT NAME", width: "10%", align: "center" },
            { title: "TOTAL MEASURE TYPE", width: "10%", align: "center" },
            { title: "TOTAL QUANTITY", width: "10%", align: "center", formatter: this.ad.numberFormatter },
            { title: "LOSS MEASURE TYPE", width: "10%", align: "center" },
            { title: "LOSS QUANTITY", width: "10%", align: "center", formatter: this.ad.numberFormatter },
            { title: "CONSUMPTION MEASURE TYPE", width: "10%", align: "center" },
            { title: "CONSUMPTION QUANTITY", width: "10%", align: "center", formatter: this.ad.numberFormatter },
            { title: "IGNORE BULK", width: "10%", align: "center" },
            // { title: "COST PERCENTAGE", width: "10%", align: "center" },

        ]
        return cols;
    }

    aditionalImportEntities(){
        return [            
            { entity: "Product", predicateFormat: "deletedAt == null" }
        ]
    }

    protected importRow(row:any){
        
        let identifier       = row["COMPONENT ID"];
        let parentID         = row["PARENT PRODUCT ID"];
        let parentName       = row["PARENT PRODUCT NAME"];
        let childrenID       = row["PRODUCT ID"];
        let childrenName     = row["PRODUCT NAME"];
        let totalType        = row["TOTAL MEASURE TYPE"];
        let totalQty         = row["TOTAL QUANTITY"];
        let lossType         = row["LOSS MEASURE TYPE"];
        let lossQty          = row["LOSS QUANTITY"];
        let consumptionType  = row["CONSUMPTION MEASURE TYPE"];
        let consumptionQty   = row["CONSUMPTION QUANTITY"];
        let ignoreBulk       = row["IGNORE BULK"];
        // let costPercentage   = row["COST PERCENTAGE"];

        let parent = this.queryEntityByIDOrName("Product", parentID, parentName) as Product;
        if (parent == null) return;

        let children = this.queryEntityByIDOrName("Product", childrenID, childrenName) as Product;
        if (children == null) return;

               
        let component:Component = this.queryEntityByField("Component", "identifier", identifier) as Component;
        if ( component == null ) component = this.queryEntity("Component", "parent.identifier == " + parent.identifier + " and children.identifier == " + children.identifier) as Component;        
        if ( component == null) { 
            component = MIOEntityDescription.insertNewObjectForEntityForName("Component", DBHelper.mainManagedObjectContext) as Component;
            component.identifier = this.parseOrCreateIdentifier(identifier)
        }

        component.parent = parent;
        component.children = children;
        component.totalMeasureType = MeasureUnits.measureUnitFromString(totalType);
        component.totalQuantity = totalQty;
        component.lossMeasureType = MeasureUnits.measureUnitFromString(lossType);
        component.lossQuantity = lossQty;
        component.consumptionMeasureType = MeasureUnits.measureUnitFromString(consumptionType);
        component.consumptionQuantity = consumptionQty;
        component.ignoreBulk = this.parseBoolValue(ignoreBulk);        


        // Validate consumptions

        if (consumptionQty == 0 && totalQty > 0) {
            component.consumptionQuantity = lossQty - totalQty;
            component.consumptionMeasureType = component.totalMeasureType;
        } 
        else if (consumptionQty > 0 && totalQty == 0) {
            component.totalQuantity = consumptionQty + lossQty;
            component.totalMeasureType = component.consumptionMeasureType;
        }

        this.appendObject(component);
        MIOLog("ADDING COMPONENT: " + this.rowIndex + "/" + this.rows.length + ": " + parent.name + " -> " + children.name );
    }

    protected exportTitle() : string { return "Components"; }
                
    protected exportSortDescriptors() { 
        return [
            MIOSortDescriptor.sortDescriptorWithKey("parent.name", false), 
            MIOSortDescriptor.sortDescriptorWithKey("children.name", false)
        ]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let comp = object as Component;

        let item = {
            "COMPONENT ID": comp.identifier,
            "PARENT PRODUCT ID": comp.parent.identifier,
            "PARENT PRODUCT NAME": comp.parent.name,
            "PRODUCT ID": comp.children.identifier,
            "PRODUCT NAME": comp.children.name,
            "TOTAL MEASURE TYPE": comp.totalMeasureType,
            "TOTAL QUANTITY": comp.totalQuantity,
            "LOSS MEASURE TYPE": comp.lossMeasureType,
            "LOSS QUANTITY": comp.lossQuantity,
            "CONSUMPTION MEASURE TYPE": comp.consumptionMeasureType,
            "CONSUMPTION QUANTITY": comp.consumptionQuantity,
            "IGNORE BULK": comp.ignoreBulk
        }

        return item;
    }
}