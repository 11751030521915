//
// Generated class LicensePaymentInstallment
//

/// <reference path="LicensePaymentInstallment_ManagedObject.ts" />

class LicensePaymentInstallment extends LicensePaymentInstallment_ManagedObject
{

}
