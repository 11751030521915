

interface PartyDateCellDelegate {
    imageDidClick(cell:PartyDateCell);
}

class PartyDateCell extends UITableViewCell
{
    delegate = null;

    private imageView:MUIImageView = null;
    private startDateLabel:MUITextField = null;
    private startDateButton:MUIButton = null;
    private endDateLabel:MUITextField = null;
    private endDateButton:MUIButton = null;
    private extenalReferenceTextField:MUITextField = null;

    private dtf = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter as MIODateFormatter;    

    awakeFromHTML(){
        
        let tapGesture = new MUITapGestureRecognizer();
        tapGesture.initWithTarget(this, function(gesture:MUIGestureRecognizer){
            if (gesture.state == MUIGestureRecognizerState.Ended) this.delegate.imageDidClick(this);
        });        

        this.imageView = MUIOutlet(this, "image-view", "MUIImageView");
        this.imageView.addGestureRecognizer(tapGesture);

        this.startDateLabel = MUIOutlet(this, "start-date-lbl", "MUITextField");
        this.startDateLabel.setOnChangeText(this, function(control, value){
            this._partyEvent.beginDate = this.dtf.dateFromString(value);
        });
        this.endDateLabel = MUIOutlet(this, "end-date-lbl", "MUITextField");
        this.endDateLabel.setOnChangeText(this, function(control, value){
            this._partyEvent.endDate = this.dtf.dateFromString(value);
        });
        this.extenalReferenceTextField = MUIOutlet(this, "ext-ref-tf", "MUITextField");
        this.extenalReferenceTextField.setOnChangeText(this, function(control, value){
            this._partyEvent.externalReference = value;
        });

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private _partyEvent:PartyEvent = null;
    set item(partyEvent:PartyEvent){
        this._partyEvent = partyEvent; 

        this.imageView.setImage(partyEvent.image?.url);
        this.startDateLabel.text = this.dtf.stringFromDate(partyEvent.beginDateTime);
        this.endDateLabel.text = this.dtf.stringFromDate(partyEvent.endDateTime);
        this.extenalReferenceTextField.text = partyEvent.externalReference;
    }
    get item(){
        return this._partyEvent;
    }
}