

/// <reference path="PaymentIntallment.ts" />

// Generated class LegalEntityPaymentIntallment_ManagedObject

class LegalEntityPaymentIntallment_ManagedObject extends PaymentIntallment
{

    // Property: documentNumber
    set documentNumber(value:string) {
        this.setValueForKey(value, 'documentNumber');
    }
    get documentNumber():string {
        return this.valueForKey('documentNumber');
    }
    set documentNumberPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentNumber');
    }
    get documentNumberPrimitiveValue():string {
        return this.primitiveValueForKey('documentNumber');
    }

    // Property: legalEntityName
    set legalEntityName(value:string) {
        this.setValueForKey(value, 'legalEntityName');
    }
    get legalEntityName():string {
        return this.valueForKey('legalEntityName');
    }
    set legalEntityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalEntityName');
    }
    get legalEntityNamePrimitiveValue():string {
        return this.primitiveValueForKey('legalEntityName');
    }
    // Relationship: invoice
    set invoice(value:Invoice) {
        this.setValueForKey(value, 'invoice');
    }
    get invoice():Invoice {
        return this.valueForKey('invoice') as Invoice;
    }
    // Relationship: legalEntity
    set legalEntity(value:LegalEntity) {
        this.setValueForKey(value, 'legalEntity');
    }
    get legalEntity():LegalEntity {
        return this.valueForKey('legalEntity') as LegalEntity;
    }
}
