

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class CustomConcept_ManagedObject

class CustomConcept_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: concept
    set concept(value:string) {
        this.setValueForKey(value, 'concept');
    }
    get concept():string {
        return this.valueForKey('concept');
    }
    set conceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'concept');
    }
    get conceptPrimitiveValue():string {
        return this.primitiveValueForKey('concept');
    }

    // Property: stockRecoverOption
    set stockRecoverOption(value:number) {
        this.setValueForKey(value, 'stockRecoverOption');
    }
    get stockRecoverOption():number {
        return this.valueForKey('stockRecoverOption');
    }
    set stockRecoverOptionPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'stockRecoverOption');
    }
    get stockRecoverOptionPrimitiveValue():number {
        return this.primitiveValueForKey('stockRecoverOption');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }
}
