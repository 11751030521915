

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Printer_ManagedObject

class Printer_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: beeperOpensCashdesk
    set beeperOpensCashdesk(value:boolean) {
        this.setValueForKey(value, 'beeperOpensCashdesk');
    }
    get beeperOpensCashdesk():boolean {
        return this.valueForKey('beeperOpensCashdesk');
    }
    set beeperOpensCashdeskPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'beeperOpensCashdesk');
    }
    get beeperOpensCashdeskPrimitiveValue():boolean {
        return this.primitiveValueForKey('beeperOpensCashdesk');
    }

    // Property: categoryTagKitchenOrderPrintOption
    set categoryTagKitchenOrderPrintOption(value:number) {
        this.setValueForKey(value, 'categoryTagKitchenOrderPrintOption');
    }
    get categoryTagKitchenOrderPrintOption():number {
        return this.valueForKey('categoryTagKitchenOrderPrintOption');
    }
    set categoryTagKitchenOrderPrintOptionPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'categoryTagKitchenOrderPrintOption');
    }
    get categoryTagKitchenOrderPrintOptionPrimitiveValue():number {
        return this.primitiveValueForKey('categoryTagKitchenOrderPrintOption');
    }

    // Property: codePage
    set codePage(value:number) {
        this.setValueForKey(value, 'codePage');
    }
    get codePage():number {
        return this.valueForKey('codePage');
    }
    set codePagePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'codePage');
    }
    get codePagePrimitiveValue():number {
        return this.primitiveValueForKey('codePage');
    }

    // Property: enableBeeper
    set enableBeeper(value:boolean) {
        this.setValueForKey(value, 'enableBeeper');
    }
    get enableBeeper():boolean {
        return this.valueForKey('enableBeeper');
    }
    set enableBeeperPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'enableBeeper');
    }
    get enableBeeperPrimitiveValue():boolean {
        return this.primitiveValueForKey('enableBeeper');
    }

    // Property: filterSummariesByPrintProfile
    set filterSummariesByPrintProfile(value:number) {
        this.setValueForKey(value, 'filterSummariesByPrintProfile');
    }
    get filterSummariesByPrintProfile():number {
        return this.valueForKey('filterSummariesByPrintProfile');
    }
    set filterSummariesByPrintProfilePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'filterSummariesByPrintProfile');
    }
    get filterSummariesByPrintProfilePrimitiveValue():number {
        return this.primitiveValueForKey('filterSummariesByPrintProfile');
    }

    // Property: groupDifferentSources
    set groupDifferentSources(value:boolean) {
        this.setValueForKey(value, 'groupDifferentSources');
    }
    get groupDifferentSources():boolean {
        return this.valueForKey('groupDifferentSources');
    }
    set groupDifferentSourcesPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'groupDifferentSources');
    }
    get groupDifferentSourcesPrimitiveValue():boolean {
        return this.primitiveValueForKey('groupDifferentSources');
    }

    // Property: image
    set image(value:string) {
        this.setValueForKey(value, 'image');
    }
    get image():string {
        return this.valueForKey('image');
    }
    set imagePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'image');
    }
    get imagePrimitiveValue():string {
        return this.primitiveValueForKey('image');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: numberOfChars
    set numberOfChars(value:number) {
        this.setValueForKey(value, 'numberOfChars');
    }
    get numberOfChars():number {
        return this.valueForKey('numberOfChars');
    }
    set numberOfCharsPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'numberOfChars');
    }
    get numberOfCharsPrimitiveValue():number {
        return this.primitiveValueForKey('numberOfChars');
    }

    // Property: overrideCategoryTagKitchenOrderPrintOption
    set overrideCategoryTagKitchenOrderPrintOption(value:boolean) {
        this.setValueForKey(value, 'overrideCategoryTagKitchenOrderPrintOption');
    }
    get overrideCategoryTagKitchenOrderPrintOption():boolean {
        return this.valueForKey('overrideCategoryTagKitchenOrderPrintOption');
    }
    set overrideCategoryTagKitchenOrderPrintOptionPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'overrideCategoryTagKitchenOrderPrintOption');
    }
    get overrideCategoryTagKitchenOrderPrintOptionPrimitiveValue():boolean {
        return this.primitiveValueForKey('overrideCategoryTagKitchenOrderPrintOption');
    }

    // Property: printerMode
    set printerMode(value:number) {
        this.setValueForKey(value, 'printerMode');
    }
    get printerMode():number {
        return this.valueForKey('printerMode');
    }
    set printerModePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'printerMode');
    }
    get printerModePrimitiveValue():number {
        return this.primitiveValueForKey('printerMode');
    }

    // Property: stringEncoding
    set stringEncoding(value:number) {
        this.setValueForKey(value, 'stringEncoding');
    }
    get stringEncoding():number {
        return this.valueForKey('stringEncoding');
    }
    set stringEncodingPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'stringEncoding');
    }
    get stringEncodingPrimitiveValue():number {
        return this.primitiveValueForKey('stringEncoding');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: useSlipPrinter
    set useSlipPrinter(value:boolean) {
        this.setValueForKey(value, 'useSlipPrinter');
    }
    get useSlipPrinter():boolean {
        return this.valueForKey('useSlipPrinter');
    }
    set useSlipPrinterPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'useSlipPrinter');
    }
    get useSlipPrinterPrimitiveValue():boolean {
        return this.primitiveValueForKey('useSlipPrinter');
    }

    // Relationship: printerLinks
    protected _printerLinks:MIOManagedObjectSet = null;
    get printerLinks():MIOManagedObjectSet {
        return this.valueForKey('printerLinks');
    }
    addPrinterLinksObject(value:PrinterLink) {
        this._addObjectForKey(value, 'printerLinks');
    }
    removePrinterLinksObject(value:PrinterLink) {
        this._removeObjectForKey(value, 'printerLinks');
    }

    // Relationship: profileSummaries
    protected _profileSummaries:MIOManagedObjectSet = null;
    get profileSummaries():MIOManagedObjectSet {
        return this.valueForKey('profileSummaries');
    }
    addProfileSummariesObject(value:ProfileSummary) {
        this._addObjectForKey(value, 'profileSummaries');
    }
    removeProfileSummariesObject(value:ProfileSummary) {
        this._removeObjectForKey(value, 'profileSummaries');
    }
}
