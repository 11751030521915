//
// Generated class TaxAnnotation
//

/// <reference path="TaxAnnotation_ManagedObject.ts" />

class TaxAnnotation extends TaxAnnotation_ManagedObject
{

}
