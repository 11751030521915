/**
 * Created by miguel on 31/1/17.
 */

class ClientAccountViewController extends MUIViewController {    

    private tableView: UITableView = null;

    private backButton: MUIButton = null;
    private addButton: MUIButton = null;
    private removeButton: MUIButton = null;
    private quantityTextField: MUITextField = null;
    private totalLabel: MUILabel = null;

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    private action = null;

    showBackButton = true;
    showBookingOptions = false;

    viewDidLoad() {
        super.viewDidLoad();

        // if (this.showBackButton) {
        //     this.backButton = MUIOutlet(this, 'cav_nb_back_btn', 'MUIButton');
        //     this.backButton.setAction(this, function () {
        //         this.navigationController.popViewController(true);

        //     });
        // }

        this.addButton = MUIOutlet(this, 'add-money-btn', 'MUIButton');
        this.addButton.setAction(this, function () {
            this.action = AccountLineType.AddFunds;
            this.showPayMethodView();
        });

        this.removeButton = MUIOutlet(this, 'add-debt-btn', 'MUIButton');
        this.removeButton.setAction(this, function () {
            this.action = AccountLineType.RemoveFunds;
            this.showPayMethodView();
        });

        this.quantityTextField = MUIOutlet(this, 'add-btn', 'MUITextField');
        this.quantityTextField.formatter = this.cf;

        this.totalLabel = MUIOutlet(this, 'total-lbl', 'MUILabel');

        this.tableView = MUIOutlet(this, "tableview", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.updateUI();
    }

    viewWillAppear(animate?) {
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private _client: Client = null;
    set client(value:Client) {
        this._client = value;
        if (this._booking != null) this._booking.removeObserver(this, "client");
        this.updateUI();
    }

    private _booking:Booking = null;
    set booking(value:Booking){
        if (this._booking != null) this._booking.removeObserver(this, "client");
        this._booking = value;
        if (this._booking != null) this._booking.addObserver(this, "client");

        this._client = value.client;
        
        this.updateUI();
    }

    public observeValueForKeyPath(keypath:string, type, object){
        if (type != "did") return;
        switch (keypath) {
            case "client":
                this._client = this._booking.client;
                break;
        }
    }

    private updateUI() {
        if (!this.viewIsLoaded || this._client == null) return;

        this.quantityTextField.text = null;  
        //this.totalLabel.text = this._client.account != null ? this.cf.stringFromNumber(this._client.account.balance) : null;
        
        // if (this._booking != null){
        //this.totalLabel.text = this.cf.stringFromNumber(this._client.totalAccount);
        //}
        // else {
        //     this.totalLabel.text = '0';
        // }

        this.fetchedResultsController = null;
        this.tableView.reloadData();
        this.updateBalance(this.fetchedResultsController.fetchedObjects);
    }

    private showPayMethodView() {
        if (this._client == null) return;

        let value = this.cf.numberFromString(this.quantityTextField.text);
        if (value != null && value > 0){
            AppHelper.sharedInstance().showSelectPaymethodViewControllerFromView(null, null, this, function(controller:SelectEntityViewController, paymethod:PayMethod) {
                this.createEconomicLine(paymethod);
            });
        }
        else {
            let avc = new MUIAlertViewController();
            avc.initWithTitle( MIOLocalizeString("FUNDS", "FUNDS"),  MIOLocalizeString( "INVALID VALUE TO ADD OR REMOVE FUNDS", 'INVALID VALUE TO ADD OR REMOVE FUNDS', MUIAlertViewStyle.Default);
            avc.addAction(MUIAlertAction.alertActionWithTitle('OK', MUIAlertActionStyle.Default, null,null));            
            this.presentViewController(avc, true);
        }
    }

    public payMethodDidSelected(controller:SelectEntityViewController, paymethod: PayMethod) {
        if (paymethod == null) throw new Error("Undefined payMethod");        
        this.createEconomicLine(paymethod);
    }

    private createEconomicLine(payMethod:PayMethod){

        let dh = DBHelper.sharedInstance();
        let money = this.cf.numberFromString(this.quantityTextField.text);
        if (this.action == AccountLineType.RemoveFunds) money = -money;
        dh.createEconomicLine(money, payMethod, this._client, this.action, null);

        //this.totalLabel.text = this.cf.stringFromNumber(this._client.totalAccount);
        this.quantityTextField.text = null;
    }

    numberOfSections(tableview) {
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview, section) {
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview: UITableView, indexPath: MIOIndexPath) {
        let cell: ClientAccountCell = tableview.dequeueReusableCellWithIdentifier("ClientAccountCell") as ClientAccountCell;                

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        cell.item = item;
        cell.booking = this._booking;
        cell.showBookingOptions = this.showBookingOptions;

        return cell;
    }

    private _fetchedResultsController: MIOFetchedResultsController = null;
    set fetchedResultsController(value) {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('date', false)];

        let predicate = 'economicEntity.identifier == ' + this._client.identifier;

        // if (this._booking != null) predicate += " AND bookingID == " + this._booking.identifier;

        let fetchRequest = DBHelper.listFetchRequestWithEntityName('EconomicAccountLine', sortDescriptors, predicate);
        fetchRequest.relationshipKeyPathsForPrefetching = ["payMethod", "booking"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;

        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        this.tableView.reloadData();

        //if (this._booking != null) this._booking.payImport = total;
        this.updateBalance(controller.fetchedObjects);
    }

    private updateBalance(objects){
        let total = 0;
        for (let index = 0; index < objects.length; index++){
            let line:EconomicAccountLine = objects[index];
            total += line.value;
        }

        this.totalLabel.text = this.cf.stringFromNumber(total);
    }
}
