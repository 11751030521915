//
// Generated class GenericEventInvitationRule
//

/// <reference path="GenericEventInvitationRule_ManagedObject.ts" />

class GenericEventInvitationRule extends GenericEventInvitationRule_ManagedObject
{

}
