
class PartyLocationCell extends MUITableViewCell
{
    private titleLabel:MUILabel = null;
    private rateComboBox:MUILabel = null;

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "location-category-lbl", "MUILabel");

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    set item(item:MapLocationCategory){
        this.titleLabel.text = item.name;
    }
}