//
// Generated class CoreAnnotation
//

/// <reference path="CoreAnnotation_ManagedObject.ts" />

class CoreAnnotation extends CoreAnnotation_ManagedObject
{

}
