
class BookingsZoneDaySetupCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, 'name-lbl', 'MUILabel');
    }
        
    set zone(zone:BookingZone){        
        this.nameLabel.text = zone.name;
    }
}
