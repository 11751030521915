/// <reference path="../notes/NoteLineCell.ts" />

class SupplierNoteLineCell extends NoteLineCell
{
    private orderedQuantityLabel:MUILabel = null;

    awakeFromHTML(){
        super.awakeFromHTML();

        this.orderedQuantityLabel = MUIOutlet(this, "ordered-quantity-lbl", "MUILabel");

        this.reset();
    }
    
    setLine(item:StockNoteLine){
        super.setLine(item);
        let supplier = this.delegate.supplierFromNote(this) as Supplier;
        let sp = _MIOPredicateFilterObjects(item.product.supplierProducts.allObjects, MIOPredicate.predicateWithFormat("supplier.identifier == " + supplier.identifier)); 
        this.productName = sp.length > 0 ? sp[0].supplierReferenceName : item.productName;
        if ( this.productName == null || this.productName.length == 0 ) this.productName = item.productName;
        if (this.orderedQuantityLabel) this.orderedQuantityLabel.text = item.estimatedQuantity > 0 ? this.nf.stringFromNumber(item.estimatedQuantity) : null;
        this.productLabel.text = this.productName + " " + this.product.productMeasureDescription();
    }

    productDidSelect(controller:SelectEntityViewController, product:Product, supplierProduct?:SupplierProduct){        
        super.productDidSelect(controller, product, supplierProduct);

        if (supplierProduct != null) {
            this.productNetPriceAmount = DBHelper.calculateCostFromSupplierProduct(product, supplierProduct);
            this.discountFormat = supplierProduct.discountString;
        }
        else {            
            let supplier = this.stockNote.originEntity as Supplier;
            [this.productNetPriceAmount, this.discountFormat] = DBHelper.costFromProductAndSupplier(product, supplier);
        }                
        
        this.priceAmount = this.productNetPriceAmount;
        // if (this.priceAmount > 0) this.priceManualInput = true;
        
        this.priceTextField.enabled = true;
        this.priceTextField.text = this.cf.stringFromNumber(this.priceAmount);

        this.productPriceTextField.enabled = true;
        this.productPriceTextField.text = this.cf.stringFromNumber(this.productNetPriceAmount);
    }

    protected inputFormatDidSelect(controller:SelectInputFormatViewController, inputFormat:StockInputFormat, inputType:MeasureUnitType){
        
        let supplier = this.delegate.supplierFromNote(this) as Supplier;
        if (inputFormat != null) {
            let price = 0;
            let discount = 0;
            [price, discount] = DBHelper.costFromProductAndSupplier(this.product, supplier);
            this.priceAmount  = price * inputFormat.quantity;
            // [this.totalPrice, this.discountValue] = DBHelper.calculateTotalFromStockLine(this.quantity, this.price, this.discount);
            // this.discount = (this.discountValue * inputFormat.quantity).toString();
        }
        else {
            [this.priceAmount] = DBHelper.costFromProductAndSupplier(this.product, supplier);
        }
        // if (this.priceAmount > 0) this.priceManualInput = true;

        super.inputFormatDidSelect(controller, inputFormat, inputType);
    }    

    enterDidPress(){
        if (this.stockNoteLine != null) return;        
        if (this.quantity == 0 || this.quantity == null) return;
                
        let line = DBHelper.createSupplierNoteLine(this.product, this.tax, this.inputFormat, this.inputType, this.quantity, this.productQuantity, this.priceAmount, this.productNetPriceAmount, this.baseAmount, this.discountFormat, this.discountAmount, this.warehouse, this.stockNote);
        if (line != null) {
            line.minMeasureType = this.minMeasureUnitType;
            line.minMeasureQuantity = this.minMeasureQuantity;            
            this.lineDidInserted(line);            
            this.reset();
        }
    }
    
    reset(){
        super.reset();        
        if (this.orderedQuantityLabel != null) this.orderedQuantityLabel.text = null;
    }
}
