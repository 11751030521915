
class TimeRangePresetDetailViewController extends BaseTableViewController 
{
    private backButton:MUIButton = null;
    private saveButton:MUIButton = null;
    private addButton:MUIButton = null;
    private nameTextfield:MUITextField = null;
    private segmentedControl:MUISegmentedControl = null;
    private dateRangeButton:MUIButton = null;

    private groupDetailViewController:TimeRangeGroupDetailViewController = null;
    private isAlwaysAvailable = true;

    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");
        this.backButton.setAction(this, function(control){
            this.navigationController.popViewController(true);
        });

        this.saveButton = MUIOutlet(this, 'save-btn', 'MUIButton');
        this.saveButton.setAction(this, function (){       
            DBHelper.saveMainContext();     
            // DBHelper.saveMainContextWithCompletion(this, function(){
            //     this.updateUI();
            // });
        });

        // this.settingsButton = MUIOutlet(this, 'settings-btn', 'MUIButton');
        // this.settingsButton.setAction(this, function (){            
        //     this.showSettingsPopup();
        // }); 

        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function(this:TimeRangePresetDetailViewController){
            this.addNewTimeRanges();
        } );

        this.nameTextfield = MUIOutlet(this, 'preset-name-tf', 'MUITextField');
        this.nameTextfield.setOnChangeText(this, function(this:TimeRangePresetDetailViewController, control:MUITextField, value:string){
            this._time_range_preset.name = value;
        })

        // this.segmentedControl = MUIOutlet(this, 'segmented-control', 'MUISegmentedControl');
        // this.segmentedControl.setAction(this, function (control, index){
        //     this.isAlwaysAvailable = index == 0 ? true : false;
        //     this.updateUI();
        // });

        // this.dateRangeButton = MUIOutlet(this, 'set-date-range-btn', 'MUIButton');
        // this.dateRangeButton.setAction(this, function (controll, value) {
        //     this.dateRangeSelect();
        // })
        
        this.tableView = MUIOutlet(this,"table-view","UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;                
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);        
        this.updateUI();
    }

    private _time_range_preset: TimeRangePreset = null;
    set item(item:TimeRangePreset){
        this._time_range_preset = item;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._time_range_preset == null) return;

        this.nameTextfield.text = this._time_range_preset.name;

        // this.dateRangeButton.title = this.isAlwaysAvailable ? MIOLocalizeString("SELECT DATE", "SELECT DATE") : "11.10.20 - 12.01.20";
        // this.dateRangeButton.enabled = this.isAlwaysAvailable ? false : true;

        this.fetchedResultsController = null;
        this.tableView.reloadData();        
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath)
    {
        let cell = tableview.dequeueReusableCellWithIdentifier("TimeRangeGroupCell") as TimeRangeGroupCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as TimeRangeGroup;
        cell.item = item;
        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){ 
        let selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath);
        this.showItemDetail(selectedItem); 
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {         
        return UITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as TimeRangeGroup;
        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            //NOTE: needs to be finished, remove object and all its relations
            //alert("remove this item from: this._timeRangePreset.timeRangeGroups\n(When relations work)");
            //this.deleteTimeRangeGroup(item);
            //DBHelper.deleteObjectFromMainContext(item , true);
            alert("fix delete funtion");
        }
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;        

        let sortDescriptors = null;
        // let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("startHour", true), MIOSortDescriptor.sortDescriptorWithKey("name", true)]; 
                        
        let ids = this._time_range_preset.timeRangeGroups.map( (item:TimeRangePreset) => item.identifier );
        let pf = "identifier IN ['" + ids.join("','") + "']";

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("TimeRangeGroup", sortDescriptors, pf);
        //fetchRequest.relationshipKeyPathsForPrefetching = ['timeRangePresets'];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;        
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        this.tableView.reloadData();
    }


    private showItemDetail(item:TimeRangeGroup){     
        if (item == null) return;
        
        if(this.trgvc == null) {
            this.trgvc = new TimeRangeGroupDetailViewController("time-range-group-detail-view");
            this.trgvc.initWithResource("layout/timerangepreset/TimeRangeGroupDetailView.html");
        }
        
       this.trgvc.item = item;    
        this.navigationController.pushViewController(this.trgvc, true);
    }

    private addNewTimeRanges()
    {
        let vc = TimeRangeGroupSelectViewController.newInstance();
        vc.delegate = this;
        vc.timeRangePresetItem = this._time_range_preset;
        this.presentViewController(vc, true);
    }

    private addNewTimeRangeGroup() {

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('TIME RANGE','TIME RANGE'), MIOLocalizeString('CREATE NEW TIME RANGE GROUP','CREATE NEW TIME RANGE GROUP'), MUIAlertViewStyle.Default);
        
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("START TIME (EG. 9:00)","START TIME (EG. 9:00)"));
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("END TIME (EG. 22:00","END TIME (EG. 22:00"));
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("TIME INTERVAL IN MINUTES (EG. 15, 30, ETC.)","TIME INTERVAL IN MINUTES (EG. 15, 30, ETC.)"));
        });
        
        // avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
        //     textField.setPlaceholderText("Quantity");
        //     let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        //     textField.formatter = ad.numberFormatter;
        // });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','DONE'), MUIAlertActionStyle.Default, this, function(){
            
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

            //get main variables from view
            let name = avc.textFields[0].text.trim();
            let startTime = avc.textFields[1].text;
            let endTime = avc.textFields[2].text
            let interval = ad.numberFormatter.numberFromString(avc.textFields[3].text);

            //convert to date objects for comparison
            let startDate:any = ad.timeFormatter.dateFromString(startTime);
            let endDate:any = ad.timeFormatter.dateFromString(endTime);

            //check for a name
            if(!name) {
                //not valid, need name
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('PLEASE ENTER A VALID NAME','PLEASE ENTER A VALID NAME'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                this.presentViewController(error, true);
                return;
            }

            //get the interval in minutes
            let minDifference = null;
            if( endDate - startDate == 0 ){
                //not valid
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('START AND END TIME MUST BE DIFFERENT','START AND END TIME MUST BE DIFFERENT'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                this.presentViewController(error, true);
                return;
            } else if ( endDate - startDate > 0 ) {
                minDifference =  (endDate - startDate) / 60000; //millisecons to min
            } else {
                //next day
                endDate.setDate(endDate.getDate() + 1);
                minDifference =  (endDate - startDate) / 60000; //millisecons to min
            }

            //check interval validity
            if( minDifference % interval != 0 ){
                //throw some error and return
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('UNACCEPTABLE TIME INTERVAL','UNACCEPTABLE TIME INTERVAL'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                this.presentViewController(error, true);
                return;
            }

            //check for TimeRangeGroup overlaps
            let isValid = this.checkTimeRangeValidity(startDate, endDate, this._time_range_preset.timeRangeGroups.allObjects);
            if (!isValid) {
                //alert error and return
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('TIME RANGES CANNOT OVERLAP','TIME RANGES CANNOT OVERLAP'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                this.presentViewController(error, true);
                return;
            }

            // Creat new TimeRangeGroup
            let newTimeRangeGroup = MIOEntityDescription.insertNewObjectForEntityForName("TimeRangeGroup", moc) as TimeRangeGroup;
            newTimeRangeGroup.name = name;
            newTimeRangeGroup.identifier = MIOUUID.UUID().UUIDString;
            newTimeRangeGroup.timeRangePresets = this._time_range_preset;
            newTimeRangeGroup.startHour = avc.textFields[1].text;
            newTimeRangeGroup.endHour = avc.textFields[2].text;
            newTimeRangeGroup.duration = avc.textFields[3].text;

            moc.save() //save added here to ensure that group is saved before ranges (workaround for error in API)

            // Creat TimeRanges
            let newTimeRange:TimeRange;
            let newDate:Date;
            for (var index = 0; index < (minDifference/interval); index++) {
                newTimeRange = MIOEntityDescription.insertNewObjectForEntityForName("TimeRange", moc) as TimeRange;
                newDate = new Date(startDate.getTime() + (interval*60000*index))
                newTimeRange.identifier =  MIOUUID.UUID().UUIDString;
                newTimeRange.hour = ad.timeFormatter.stringFromDate(newDate);
                newTimeRange.name = newTimeRange.hour;
                newTimeRange.groupName = name;
                newTimeRange.nextDay = this.sameDay(startDate, newDate);
                newTimeRange.duration = interval;
                newTimeRange.timeRangeGroup = newTimeRangeGroup;
            }

            moc.save() //see above note one saves

            this.updateUI();

        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Cancel, this, function(){}));
        
        this.presentViewController(avc, true);
    }

    

    //show notice functions
    private showSettingsPopup() {
        // let avc = new MUIAlertViewController();
        // avc.initWithTitle(MIOLocalizeString('ACTIONS', 'ACTIONS'), MIOLocalizeString('CHOOSE AN ACTION', 'CHOOSE AN ACTION'), MUIAlertViewStyle.Default);
        // avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DELETE MODIFIER CATEGORY', 'DELETE MODIFIER CATEGORY'), MUIAlertActionStyle.Destructive, this, function(){
            
        //     this.removeAllProductModifiers();

        //     if(this.fetchedResultsController.resultObjects.length == 0) {
        //         this.removeProductModifierCategory(this.productModifierCategory);
        //     }

        //     //pop current detail view controller
            
        // }));
                
        // avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL', 'Cancel'), MUIAlertActionStyle.Cancel, null, null));
       
        // this.presentViewController(avc, true);
    }

    //checks if two dates are in the same day
    private sameDay(d1:Date, d2:Date) {
        return !(d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate());
    }

    //checks if a time range (startDate and endDate) have overlaps with TimeRangeGroups (passed as an array of TimeRangeGroup)
    private checkTimeRangeValidity(startDate, endDate, ranges:any) {

        if (ranges == null) return true;

        let isValid = true;
        let tempStartDate = null;
        let tempEndDate = null;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        //loop through timeRanges that exist and return false if overlap
        for(var index = 0; index < ranges.length; index++) {

            tempStartDate = ad.timeFormatter.dateFromString(ranges[index].startHour);
            tempEndDate = ad.timeFormatter.dateFromString(ranges[index].endHour);

            //check for nextDay endTime
            if (tempStartDate - tempEndDate > 0) {
                tempEndDate.setDate(tempEndDate.getDate() + 1); //add a day to the end date
            }

            // CHECKS FOR POSITIVE CASES (new date ranges is completly before or after existing ranges)
            if ( !((startDate < tempStartDate && endDate < tempStartDate) || (tempEndDate < startDate && tempEndDate < endDate)) ) {
                isValid = false;
            }

            // CHECKS FOR NEGATIVE CASES (Either check for positive cases or negative)
            // if( (timetempStartDate < start && start < timetempEndDate) || (timetempStartDate < end && end < timetempEndDate) ) {
            //     isValid = false;
            // } else if (start < timetempStartDate && timetempEndDate < end) {
            //     isValid = false;
            // }
        }

        return isValid; //no overlaping cases found, return true
    }

    private dateRangeSelect() {
        alert("Call date picker");
    }

    private deleteTimeRangeGroup(group:TimeRangeGroup){

        //delete TimeRanges found in TimeRangeGroup
        let predicate = MIOPredicate.predicateWithFormat("timeRangeGroup.identifier == " + group.identifier);            
        DBHelper.queryObjectsWithCompletion("TimeRange", [MIOSortDescriptor.sortDescriptorWithKey("name", true)], predicate, ["timeRangeGroup"], this, function(objects){
            
            for (let index = 0; index < objects.length; index++){
                DBHelper.deleteObjectFromMainContext(objects[index] as TimeRange, false);
            }

            DBHelper.deleteObjectFromMainContext(group as TimeRangeGroup, false)
            DBHelper.saveMainContext();
        });
    }

}
