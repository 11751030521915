//
// Generated class BusinessLog
//

/// <reference path="BusinessLog_ManagedObject.ts" />

class BusinessLog extends BusinessLog_ManagedObject
{

}
