class ServiceStatusViewController extends MUIViewController {

    static newInstance(): ServiceStatusViewController {
        let vc = new ServiceStatusViewController('service-status-view');
        vc.initWithResource('layout/servicestatus/ServiceStatusView.html');
        return vc;
    }

    private addButton: MUIButton = null;
    private saveButton: MUIButton = null;
    private scriptButton: MUIButton = null;

    viewDidLoad() {
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function() {
            this.addWineCellar();
        });

        this.saveButton = MUIOutlet(this, 'save-btn', 'MUIButton');
        this.saveButton.setAction(this, function() {
            DBHelper.saveMainContext();
        })

        this.scriptButton = MUIOutlet(this, 'script-btn', 'MUIButton');
        this.scriptButton.setAction(this, function() {
            this.downloadData();

            if (this.script == null) return;

            if (this.script.filters.count > 0) {
                this.showFiltersViewController();
            }
            else {
                this.showResultsViewController();                
            }    
        })

        //   this.tableView = MUIOutlet(this,"table-view", "UITableView");
        //   this.tableView.dataSource = this;
        //   this.tableView.delegate = this;
    }

    viewWillAppear(animate ? ) {
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private updateUI() {
        if (this.viewIsLoaded == false) return;

        // this.fetchedResultsController = null;
        // this.tableView.reloadData();
    }

    // numberOfSections(tableview){
    //     return this.fetchedResultsController.sections.length;
    // }

    // numberOfRowsInSection(tableview, section){
    //     let sec = this.fetchedResultsController.sections[section];
    //     return sec.numberOfObjects();
    // }

    // cellAtIndexPath(tableview, indexPath:MIOIndexPath){
    //     let cell = tableview.dequeueReusableCellWithIdentifier('WineCellarConfigCell') as WineCellarConfigCell;
    //     let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as WineCellar;
    //     cell.item = item;
    //     cell.selectionStyle = UITableViewCellSelectionStyle.None; 
    //     return cell;
    // }

    // didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
    //     // let vc = WarehouseDetailViewController.newInstance();
    //     // vc.item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Warehouse;
    //     // this.navigationController.pushViewController(vc, true);
    // }

    // editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
    //     return UITableViewCellEditingStyle.Delete;
    // }

    // commitEditingStyleForRowAtIndexPath(tableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
    //     let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as WineCellar;    

    //     if (editingStyle == UITableViewCellEditingStyle.Delete) {
    //         // DBHelper.deleteObjectFromMainContext(item, true);

    //         this.deleteWineCellar(item);
    //     }
    // }

    private _fetchedResultsController: MIOFetchedResultsController = null;
    set fetchedResultsController(value) {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("WineCellar", sortDescriptors, null);
        // fetchRequest.relationshipKeyPathsForPrefetching = ['ProductAttributeType']; //relationship not implemented       

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();
        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller) {
        this.tableView.reloadData();
    }



    private script:DBScript = null;
    private setScript(script:DBScript){

        this.script = script;

        this.summaryViewController.script = script;
        this.sqlViewController.script = script;
        this.scriptViewController.script = script;

        this.runButton.setEnabled(script ? true : false);
    }   


    keysAndValues = null;
    private items = [];
    private filterItems = [];
    private downloadData() {

        let ad: AppDelegate = MUIWebApplication.sharedInstance().delegate;
        let ws: WebService = ad.webService;

        if (this.script == null) return;

        //this.activityIndicator.setHidden(true);                                

        ws.executeScript(this.script, this.keysAndValues, this, function(code, result) {

            if (code == 200 && result == true) {
                ws.downloadScriptResults(this.script, this, function(code2, objects) {
                    if (code2 == 200) {
                        this.items = objects;
                        this.filterItems = objects;
                        this.reloadFilterItems();
                    }
                });
            }
            //this.activityIndicator.setHidden(true);                                
        });

    }


    // updateTicketPAX(identifier: string, pax: number, target, completion) {

    //     let request = null;
    //     let body = {}
    //     body["AppID"] = this.identifier;
    //     body["AppType"] = this.identifierType;
    //     body["filters"] = {
    //         "id": identifier,
    //         "pax": pax
    //     };

    //     let url = MIOURL.urlWithString(this.scriptURL + "/" + this.identifierType + "/" + this.identifier + "/python/update_pax.py");
    //     request = new MWSJSONRequest();
    //     request.initWithURL(url, body, "POST");

    //     let token = MIOUserDefaults.standardUserDefaults().valueForKey("LoginToken");
    //     request.setHeaderValue("Bearer " + token, "Authorization");
    //     if (SettingsHelper.sharedInstance().isNewServersLoaded) request.setHeaderValue("new-login", "DL-AUTH-SERVER");

    //     request.execute(this, function(code, json) {
    //         if (target != null && completion != null)
    //             completion.call(target, code, json["data"], json);
    //     });
    // }



    // calculateBookingPrice(dayString: string, pax: number, zoneID: string, completion: any) {
    //     let body = {
    //         "filters": {
    //             "__report_output_type__": 1,
    //             "day": dayString,
    //             "pax": pax,
    //             "zone": zoneID
    //         }
    //     }

    //     const url = this.scriptURL + "/schema/" + this.scheme + "/python/booking_price_rules.py"
    //     const r = new WebServiceRequest(url, body, "POST");
    //     r.execute(completion);
    // }


}