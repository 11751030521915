//
// Generated class WorkerHoliday
//

/// <reference path="WorkerHoliday_ManagedObject.ts" />

class WorkerHoliday extends WorkerHoliday_ManagedObject
{

}
