

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class LegalTax_ManagedObject

class LegalTax_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: format
    set format(value:string) {
        this.setValueForKey(value, 'format');
    }
    get format():string {
        return this.valueForKey('format');
    }
    set formatPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'format');
    }
    get formatPrimitiveValue():string {
        return this.primitiveValueForKey('format');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: value
    set value(value:number) {
        this.setValueForKey(value, 'value');
    }
    get value():number {
        return this.valueForKey('value');
    }
    set valuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'value');
    }
    get valuePrimitiveValue():number {
        return this.primitiveValueForKey('value');
    }
    // Relationship: type
    set type(value:LegalTaxType) {
        this.setValueForKey(value, 'type');
    }
    get type():LegalTaxType {
        return this.valueForKey('type') as LegalTaxType;
    }
}
