



class ReportResultOutputCell extends MUITableViewCell
{
    label1 = null;
    label2 = null;
    label3 = null;
    label4 = null;
    label5 = null;
    label6 = null;
    name = null;

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;

    awakeFromHTML()
    {
        this.label1    = MUIOutlet(this, 'rrv_c3_label1_lbl', 'MUILabel');
        this.label2    = MUIOutlet(this, 'rrv_c3_label2_lbl', 'MUILabel');
        this.label3    = MUIOutlet(this, 'rrv_c3_label3_lbl', 'MUILabel');
        this.label4    = MUIOutlet(this, 'rrv_c3_label4_lbl', 'MUILabel');
        this.label5    = MUIOutlet(this, 'rrv_c3_label5_lbl', 'MUILabel');
        this.label6    = MUIOutlet(this, 'rrv_c3_label6_lbl', 'MUILabel');
        this.name      = MUIOutlet(this, 'rrv_c3_name_lbl', 'MUILabel');
    }

    set item(i)
    {
        this.label1.text = (i.stotaltaxes ? this.cf.stringFromNumber(parseFloat(i.stotaltaxes)):'');
        this.label2.text = (i.stotalpricebase ? this.cf.stringFromNumber(parseFloat(i.stotalpricebase)):'');
        this.label3.text = i.squantity ? this.nf.stringFromNumber(parseFloat(i.squantity)) :'';
        this.label4.text = (i.btotaltaxes ? this.cf.stringFromNumber(parseFloat(i.btotaltaxes)):'');
        this.label5.text = (i.btotalpricebase ? this.cf.stringFromNumber(parseFloat(i.btotalpricebase)):'');
        this.label6.text = i.bquantity ? this.nf.stringFromNumber(parseFloat(i.bquantity)) :'';
        this.name.text = i[Object.keys(i)[0]];
    }
}
