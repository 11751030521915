/**
 * Created by crisan on 7/12/16.
 */




class SupplierCell extends UITableViewCell 
{
    private nameLabel:MUILabel = null;
    private emailLabel:MUILabel = null;    

    awakeFromHTML() {
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.emailLabel = MUIOutlet(this, "email-lbl", "MUILabel");
        
        this.separatorStyle = UITableViewCellSeparatorStyle.None;
    }

    set item(i:Supplier){
        this.nameLabel.text = i.name;
        this.emailLabel.text = i.email;
    }

}