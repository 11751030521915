class BookingDetailAllergensView extends MUIView
{
    private allergensTextArea:MUITextArea = null;
    private allergensCheckButton:MUICheckButton = null;    

    awakeFromHTML(){
        this.allergensCheckButton = MUIOutlet(this, 'allergies_check','MUICheckButton');

        this.allergensTextArea = MUIOutlet(this, 'allergies_comments_area','MUITextArea');
        this.allergensTextArea.setOnChangeText(this, function(textarea, value){
            this._item.allergens = value.trim().length > 0 ? value.trim() : null;
        });
    }
    
    private _item:Booking = null;
    set booking(i:Booking){
        this._item = i;
        this.allergensTextArea.text = i.allergens ? i.allergens:'';
    }
}
