//
// Generated class DocumentTemplate
//

/// <reference path="DocumentTemplate_ManagedObject.ts" />

class DocumentTemplate extends DocumentTemplate_ManagedObject
{

}
