//
// Generated class CategoryTag
//

/// <reference path="CategoryTag_ManagedObject.ts" />

class CategoryTag extends CategoryTag_ManagedObject
{
    
}
