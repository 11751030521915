//
// Generated class FileURL
//

/// <reference path="FileURL_ManagedObject.ts" />

class FileURL extends FileURL_ManagedObject
{

}
