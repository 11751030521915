

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Booking_BookingWorker_ManagedObject

class Booking_BookingWorker_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: workerID
    set workerID(value:string) {
        this.setValueForKey(value, 'workerID');
    }
    get workerID():string {
        return this.valueForKey('workerID');
    }
    set workerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerID');
    }
    get workerIDPrimitiveValue():string {
        return this.primitiveValueForKey('workerID');
    }

    // Property: workerName
    set workerName(value:string) {
        this.setValueForKey(value, 'workerName');
    }
    get workerName():string {
        return this.valueForKey('workerName');
    }
    set workerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerName');
    }
    get workerNamePrimitiveValue():string {
        return this.primitiveValueForKey('workerName');
    }
    // Relationship: booking
    set booking(value:Booking) {
        this.setValueForKey(value, 'booking');
    }
    get booking():Booking {
        return this.valueForKey('booking') as Booking;
    }
}
