class OnlinePaymentViewController extends MUIViewController
{
    private cancelButton:MUIButton = null;
    private webView:MUIWebView = null;

    public get preferredContentSize():MIOSize {
        return new MIOSize(640, 610);
    }

    viewDidLoad(){
        super.viewDidLoad();

        this.cancelButton = MUIOutlet(this, "cancel-btn", "MUIButton");
        this.cancelButton.setAction(this, function(this:OnlinePaymentViewController){
            this.dismissViewController(true);
        });
        this.webView = MUIOutlet(this, "web-view", "MUIWebView");
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _context:any = null;
    set context(context:any){
        this._context = context;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._context == null) return;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let url = this._context["OnlineURL"];
        let onlineOrderID = this._context["OnlineOrderID"];
        let stockNoteID = this._context["StockNoteID"];

        this.webView.setURL(url + "?type=payment&entity-name=OnlineOrder&entity-id=" + onlineOrderID + "&notify-schema=" +  ad.selectedIdentifier + ":SupplierOrder:" + stockNoteID);
    }
}