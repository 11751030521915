
class ProductAdminDataSource extends MIOObject
{
    private tableView:UITableView = null;
    private filterController:ColumnFilterController = null;
    private places_by_ID = {};

    initWithTableView(tableView:UITableView, filterController:ColumnFilterController){
        this.tableView = tableView;
        this.filterController = filterController;
    }    

    setPlaces(places){
        this.places_by_ID = places;
    }

    reloadData(){
        this._product_count = -1;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){        
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    titleForHeaderInSection(tableView:UITableView, section:number) {
        let ip = MIOIndexPath.indexForRowInSection(0, section);
        let item = this.fetchedResultsController.objectAtIndexPath(ip) as ProductComponentItem;
        return item.categoryName;
    }

    private _last_product_id = null
    private _product_count = -1;
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let item = this.fetchedResultsController.objectAtIndexPath( indexPath ) as ProductPlace;

        let cell:ProductAdminCell = null;
        cell = tableview.dequeueReusableCellWithIdentifier("ProductAdminCell") as ProductAdminCell;
                
        cell.setItem( item, this.places_by_ID[item.placeID] );

        if (this._last_product_id != item.product.identifier) {
            this._last_product_id = item.product.identifier;
            this._product_count++;
        }

        if ( this._product_count % 2 != 0 ) {
            MUICoreLayerAddStyle(cell.layer, "bg-grey");
        }
        
        return cell;
    }


    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
                
        let sds = [ MIOSortDescriptor.sortDescriptorWithKey("product.category.name", true), 
                    MIOSortDescriptor.sortDescriptorWithKey("product.name", true)];

        let predicateFormat = "placeID != '" + ad.selectedIdentifier + "' && deletedAt = null && product.category != null"
        let filterPredicateFormat = this.filterController.filterPredicateFormat();
        if (filterPredicateFormat != null){
            predicateFormat += " AND (" + filterPredicateFormat + ")";
        }
            

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("ProductPlace", sds, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = [ "product", "product.category" ];
        //fetchRequest.fetchLimit = 500;

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }
}