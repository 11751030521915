//
// Generated class ReconliceTransaction
//

/// <reference path="ReconliceTransaction_ManagedObject.ts" />

class ReconliceTransaction extends ReconliceTransaction_ManagedObject
{

}
