//
// Generated class ZonePrintProfileConfiguration
//

/// <reference path="ZonePrintProfileConfiguration_ManagedObject.ts" />

class ZonePrintProfileConfiguration extends ZonePrintProfileConfiguration_ManagedObject
{

}
