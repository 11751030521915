/**
 * Created by miguel on 15/3/17.
 */

class CalendarRangeViewController extends MUIViewController
{   
    delegate = null;
    dateTimeFrom:Date = null;
    dateTimeTo:Date = null;
    
    private dateFromButton:MUIButton = null;
    private dayFromComboBox:MUIComboBox = null;
    private monthFromComboBox:MUIComboBox = null;
    private yearFromComboBox:MUIComboBox = null;

    private dateToButton:MUIButton = null;
    private dayToComboBox:MUIComboBox = null;
    private monthToComboBox:MUIComboBox = null;
    private yearToComboBox:MUIComboBox = null;

    private df = MUIWebApplication.sharedInstance().delegate.dateFormatter;    
    
    get preferredContentSize(){
        return new MIOSize(540, 400);
    }
    
    viewDidLoad() { 
        super.viewDidLoad();

        this.dateFromButton = MUIOutlet(this, "date-from-btn", "MUIButton");
        this.dateFromButton.setAction(this, function(){
            let dp = new MUIDatePickerController();
            dp.init();        
            dp.delegate = this;
            dp.tag = 1;
            dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

            let pc = dp.popoverPresentationController;
            pc.sourceRect = this.dateFromButton.frame;
            pc.sourceView = this.dateFromButton;

            this.delegate.presentViewController(dp, true);
        });

        this.dayFromComboBox = MUIOutlet(this, "day-from-cb", "MUIComboBox");
        this.dayFromComboBox.setOnChangeAction(this, this.dateFromDidChange);
        this.monthFromComboBox = MUIOutlet(this, "month-from-cb", "MUIComboBox");
        this.monthFromComboBox.setOnChangeAction(this, this.dateFromDidChange);
        this.yearFromComboBox = MUIOutlet(this, "year-from-cb", "MUIComboBox");
        this.yearFromComboBox.setOnChangeAction(this, this.dateFromDidChange);

        this.dateToButton = MUIOutlet(this, "date-to-btn", "MUIButton");
        this.dateToButton.setAction(this, function(){
            let dp = new MUIDatePickerController();
            dp.init();        
            dp.delegate = this;
            dp.tag = 2;
            dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

            let pc = dp.popoverPresentationController;
            pc.sourceRect = this.dateToButton.frame;
            pc.sourceView = this.dateToButton;

            this.delegate.presentViewController(dp, true);
        });


        this.dayToComboBox = MUIOutlet(this, "day-to-cb", "MUIComboBox");
        this.dayToComboBox.setOnChangeAction(this, this.dateToDidChange);
        this.monthToComboBox = MUIOutlet(this, "month-to-cb", "MUIComboBox");
        this.monthToComboBox.setOnChangeAction(this, this.dateToDidChange);
        this.yearToComboBox = MUIOutlet(this, "year-to-cb", "MUIComboBox");
        this.yearToComboBox.setOnChangeAction(this, this.dateToDidChange);

        this.fillDaysInCombox(this.dayFromComboBox);
        this.fillDaysInCombox(this.dayToComboBox);

        this.fillMonthsInCombox(this.monthFromComboBox);
        this.fillMonthsInCombox(this.monthToComboBox);

        this.fillYearsInCombox(this.yearFromComboBox);
        this.fillYearsInCombox(this.yearToComboBox);

        let today = MIODateToday();
        this.dateTimeFrom = MIODateCopy(today);
        this.dateTimeTo = MIODateCopy(today);
        
        this.dayFromComboBox.selectItem(today.getDate());
        this.dayToComboBox.selectItem(today.getDate());

        this.monthFromComboBox.selectItem(MIODateGetMonthFromDate(today));
        this.monthToComboBox.selectItem(MIODateGetMonthFromDate(today));

        this.yearFromComboBox.selectItem(MIODateGetYearFromDate(today));
        this.yearToComboBox.selectItem(MIODateGetYearFromDate(today));
    }

    didSelectDate(datePickerController, date){
        if (datePickerController.tag == 1) {
            this.dayFromComboBox.selectItem(date.getDate());    
            this.monthFromComboBox.selectItem(MIODateGetMonthFromDate(date));    
            this.yearFromComboBox.selectItem(MIODateGetYearFromDate(date));    
            this.dateTimeFrom = MIODateFromString(MIODateGetDateString(date) + " 00:00:00");
        }
        else if (datePickerController.tag == 2) {
            this.dayToComboBox.selectItem(date.getDate());    
            this.monthToComboBox.selectItem(MIODateGetMonthFromDate(date));    
            this.yearToComboBox.selectItem(MIODateGetYearFromDate(date));    
            this.dateTimeTo = MIODateFromString(MIODateGetDateString(date) + " 23:59:59");
        }
    }

    private dateFromDidChange(){
        let d = this.dayFromComboBox.getSelectedItem();
        let m = this.monthFromComboBox.getSelectedItem();
        let y = this.yearFromComboBox.getSelectedItem();

        this.dateTimeFrom = MIODateFromString(y + "-" + (parseInt(m) + 1) + "-" + d + " 00:00:00");
    }

    private dateToDidChange(){
        let d = this.dayToComboBox.getSelectedItem();
        let m = this.monthToComboBox.getSelectedItem();
        let y = this.yearToComboBox.getSelectedItem();

        this.dateTimeTo = MIODateFromString(y + "-" + (parseInt(m) + 1) + "-" + d + " 23:59:59");
    }

    private fillDaysInCombox(comboBox:MUIComboBox){
        comboBox.removeAllItems();
        for (let index = 1; index < 32; index++){
            comboBox.addItem(index);
        }
    }

    private fillMonthsInCombox(comboBox:MUIComboBox){
        comboBox.removeAllItems();
        for (let index = 0; index < 12; index++){
            comboBox.addItem(MIODateGetStringForMonth(index), index);
        }
    }

    private fillYearsInCombox(comboBox:MUIComboBox){
        comboBox.removeAllItems();
        let today = MIODateToday();
        let minYears = MIODateGetYearFromDate(today) - 7;
        let maxYears = MIODateGetYearFromDate(today) + 5;
        for (let index = minYears; index < maxYears; index++){
            comboBox.addItem(index);
        }
    }

}
