

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class PaymentDevice_ManagedObject

class PaymentDevice_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: config
    set config(value:any) {
        this.setValueForKey(value, 'config');
    }
    get config():any {
        return this.valueForKey('config');
    }
    set configPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'config');
    }
    get configPrimitiveValue():any {
        return this.primitiveValueForKey('config');
    }

    // Property: defaultAppID
    set defaultAppID(value:string) {
        this.setValueForKey(value, 'defaultAppID');
    }
    get defaultAppID():string {
        return this.valueForKey('defaultAppID');
    }
    set defaultAppIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'defaultAppID');
    }
    get defaultAppIDPrimitiveValue():string {
        return this.primitiveValueForKey('defaultAppID');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }
    // Relationship: paymentEntity
    set paymentEntity(value:PaymentEntity) {
        this.setValueForKey(value, 'paymentEntity');
    }
    get paymentEntity():PaymentEntity {
        return this.valueForKey('paymentEntity') as PaymentEntity;
    }
}
