

/// <reference path="StockCoreAnnotation.ts" />

// Generated class StockCostAnnotation_ManagedObject

class StockCostAnnotation_ManagedObject extends StockCoreAnnotation
{

    // Property: legalEntityName
    set legalEntityName(value:string) {
        this.setValueForKey(value, 'legalEntityName');
    }
    get legalEntityName():string {
        return this.valueForKey('legalEntityName');
    }
    set legalEntityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalEntityName');
    }
    get legalEntityNamePrimitiveValue():string {
        return this.primitiveValueForKey('legalEntityName');
    }

    // Property: totalStock
    set totalStock(value:number) {
        this.setValueForKey(value, 'totalStock');
    }
    get totalStock():number {
        return this.valueForKey('totalStock');
    }
    set totalStockPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalStock');
    }
    get totalStockPrimitiveValue():number {
        return this.primitiveValueForKey('totalStock');
    }

    // Relationship: children
    protected _children:MIOManagedObjectSet = null;
    get children():MIOManagedObjectSet {
        return this.valueForKey('children');
    }
    addChildrenObject(value:StockCostAnnotation) {
        this._addObjectForKey(value, 'children');
    }
    removeChildrenObject(value:StockCostAnnotation) {
        this._removeObjectForKey(value, 'children');
    }
    // Relationship: legalEntity
    set legalEntity(value:LegalEntity) {
        this.setValueForKey(value, 'legalEntity');
    }
    get legalEntity():LegalEntity {
        return this.valueForKey('legalEntity') as LegalEntity;
    }
    // Relationship: parent
    set parent(value:StockCostAnnotation) {
        this.setValueForKey(value, 'parent');
    }
    get parent():StockCostAnnotation {
        return this.valueForKey('parent') as StockCostAnnotation;
    }
}
