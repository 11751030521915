

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Menu_ManagedObject

class Menu_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: idIn
    set idIn(value:number) {
        this.setValueForKey(value, 'idIn');
    }
    get idIn():number {
        return this.valueForKey('idIn');
    }
    set idInPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'idIn');
    }
    get idInPrimitiveValue():number {
        return this.primitiveValueForKey('idIn');
    }

    // Property: imageID
    set imageID(value:string) {
        this.setValueForKey(value, 'imageID');
    }
    get imageID():string {
        return this.valueForKey('imageID');
    }
    set imageIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'imageID');
    }
    get imageIDPrimitiveValue():string {
        return this.primitiveValueForKey('imageID');
    }

    // Property: isAbsorbent
    set isAbsorbent(value:boolean) {
        this.setValueForKey(value, 'isAbsorbent');
    }
    get isAbsorbent():boolean {
        return this.valueForKey('isAbsorbent');
    }
    set isAbsorbentPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isAbsorbent');
    }
    get isAbsorbentPrimitiveValue():boolean {
        return this.primitiveValueForKey('isAbsorbent');
    }

    // Property: isActive
    set isActive(value:boolean) {
        this.setValueForKey(value, 'isActive');
    }
    get isActive():boolean {
        return this.valueForKey('isActive');
    }
    set isActivePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isActive');
    }
    get isActivePrimitiveValue():boolean {
        return this.primitiveValueForKey('isActive');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: waReference
    set waReference(value:string) {
        this.setValueForKey(value, 'waReference');
    }
    get waReference():string {
        return this.valueForKey('waReference');
    }
    set waReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'waReference');
    }
    get waReferencePrimitiveValue():string {
        return this.primitiveValueForKey('waReference');
    }

    // Relationship: categories
    protected _categories:MIOManagedObjectSet = null;
    get categories():MIOManagedObjectSet {
        return this.valueForKey('categories');
    }
    addCategoriesObject(value:MenuCategory) {
        this._addObjectForKey(value, 'categories');
    }
    removeCategoriesObject(value:MenuCategory) {
        this._removeObjectForKey(value, 'categories');
    }
    // Relationship: image
    set image(value:DBImage) {
        this.setValueForKey(value, 'image');
    }
    get image():DBImage {
        return this.valueForKey('image') as DBImage;
    }

    // Relationship: linkedProductFormats
    protected _linkedProductFormats:MIOManagedObjectSet = null;
    get linkedProductFormats():MIOManagedObjectSet {
        return this.valueForKey('linkedProductFormats');
    }
    addLinkedProductFormatsObject(value:ProductFormat) {
        this._addObjectForKey(value, 'linkedProductFormats');
    }
    removeLinkedProductFormatsObject(value:ProductFormat) {
        this._removeObjectForKey(value, 'linkedProductFormats');
    }

    // Relationship: linkedProducts
    protected _linkedProducts:MIOManagedObjectSet = null;
    get linkedProducts():MIOManagedObjectSet {
        return this.valueForKey('linkedProducts');
    }
    addLinkedProductsObject(value:Product) {
        this._addObjectForKey(value, 'linkedProducts');
    }
    removeLinkedProductsObject(value:Product) {
        this._removeObjectForKey(value, 'linkedProducts');
    }

    // Relationship: menuLines
    protected _menuLines:MIOManagedObjectSet = null;
    get menuLines():MIOManagedObjectSet {
        return this.valueForKey('menuLines');
    }
    addMenuLinesObject(value:MenuLine) {
        this._addObjectForKey(value, 'menuLines');
    }
    removeMenuLinesObject(value:MenuLine) {
        this._removeObjectForKey(value, 'menuLines');
    }

    // Relationship: menuRates
    protected _menuRates:MIOManagedObjectSet = null;
    get menuRates():MIOManagedObjectSet {
        return this.valueForKey('menuRates');
    }
    addMenuRatesObject(value:MenuRate) {
        this._addObjectForKey(value, 'menuRates');
    }
    removeMenuRatesObject(value:MenuRate) {
        this._removeObjectForKey(value, 'menuRates');
    }

    // Relationship: presetMenus
    protected _presetMenus:MIOManagedObjectSet = null;
    get presetMenus():MIOManagedObjectSet {
        return this.valueForKey('presetMenus');
    }
    addPresetMenusObject(value:PresetMenu) {
        this._addObjectForKey(value, 'presetMenus');
    }
    removePresetMenusObject(value:PresetMenu) {
        this._removeObjectForKey(value, 'presetMenus');
    }
    // Relationship: tax
    set tax(value:Tax) {
        this.setValueForKey(value, 'tax');
    }
    get tax():Tax {
        return this.valueForKey('tax') as Tax;
    }
}
