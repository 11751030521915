//
// Generated class ProxyPaymentEntity
//

/// <reference path="ProxyPaymentEntity_ManagedObject.ts" />

class ProxyPaymentEntity extends ProxyPaymentEntity_ManagedObject
{

}
