
class CashDeskSessionDocumentCell extends MUITableViewCell
{
    private dateLabel:MUILabel = null;
    private innoviceIcon:MUIView = null;
    private documentIDLabel:MUILabel = null;
    private nameLabel:MUILabel = null;
    private locationLabel:MUILabel = null;
    private amountLabel:MUILabel = null;

    awakeFromHTML(){
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");
        //this.innoviceIcon = MUIOutlet(this, "inovice-icon", "MUIView");
        this.documentIDLabel = MUIOutlet(this, "document-id-lbl", "MUILabel");
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel")
        this.locationLabel = MUIOutlet(this, "location-category-lbl", "MUILabel");
        this.amountLabel = MUIOutlet(this, "total-lbl", "MUILabel");
    }

    set item(item:ArchivedDocument){
        let tf = MUIWebApplication.sharedInstance().delegate.timeFormatter as MIODateFormatter;
        let cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;
        
        this.dateLabel.text = tf.stringFromDate(item.date);
        this.documentIDLabel.text = item.legalDocumentID;
        this.amountLabel.text = cf.stringFromNumber(item.totalPrice);
        this.locationLabel.text = null;
        this.nameLabel.text = null;

        if (item instanceof ArchivedTicket) {
            let t = item as ArchivedTicket;
            // this.innoviceIcon.hidden = true;

            if (t.linkedLegalEntityName != null && t.roomName != null) {
                this.nameLabel.text = item.linkedLegalEntityName + " (" + item.roomName + ")";
            }
            else if (item.linkedLegalEntityName != null && item.roomName == null){
                this.nameLabel.text = item.linkedLegalEntityName;
            }
            else if (item.linkedLegalEntityName == null && item.roomName != null){
                this.nameLabel.text = "Room " + item.roomName;
            }
            
            this.locationLabel.text = t.zoneString;
        }
        else if (item instanceof ArchivedInvoice) {
            let i = item as ArchivedInvoice;
            // this.innoviceIcon.hidden = false;
            this.nameLabel.text = item.invoiceLegalEntityName;            
        }        		        
        
    }
}