interface AccountingEntryCellDelegate
{
    addEntryDidPress(cell:AccountingEntryCell):void;
    amountDidUpdate(cell:AccountingEntryCell):void;
}

class AccountingEntryCell extends UITableViewCell 
{    
    delegate:AccountingEntryCellDelegate = null;

    private accountTextField:MUITextField = null;
    private accountLabel:MUILabel = null;
    private accountDropdown:MUIButton = null;
    private accountNameLabel:MUILabel = null;    

    private referenceTextField:MUITextField = null;
    private referenceLabel:MUILabel = null;
    private amountTextField:MUITextField = null;    
    private debitTextField:MUITextField = null;
    private debitLabel:MUILabel = null;    
    private credictTextField:MUITextField = null;
    private credictLabel:MUILabel = null;

    private cf = ( MUIWebApplication.sharedInstance().delegate as AppDelegate ).currencyFormatter;

    private _account:LedgerAccount = null;
    private _account_name:string = null;
    private _account_number:string = null;
    private _reference:string = null;
    private _amount:number = 0;

    awakeFromHTML() 
    {
        this.accountLabel = MUIOutlet(this, "account-lbl", "MUILabel");
        this.accountTextField = MUIOutlet(this, "account-tf", "MUITextField");
        if (this.accountTextField) this.accountTextField.setOnChangeText(this, function(this:AccountingEntryCell, control:MUITextField, value:string){
            this.showSelectedLedgerAccountViewController( value.length > 0 ? value : null, true );
        });        

        this.accountNameLabel = MUIOutlet(this, "account-name-lbl", "MUILabel");
        this.accountDropdown = MUIOutlet(this, "account-dd", "MUIButton");
        if (this.accountDropdown) this.accountDropdown.setAction(this, function(){
            this.showSelectedLedgerAccountViewController( null, false );
        });

        this.referenceLabel = MUIOutlet(this, "reference-lbl", "MUILabel");
        this.referenceTextField = MUIOutlet(this, "reference-tf", "MUITextField");
        this.setupTextFieldEvents(this.referenceTextField);
        if (this.referenceTextField) this.referenceTextField.setOnChangeText(this, function(textField:MUITextField, value:string){
            this.reference = value.trim().length > 0 ? value : null;
        });
                        
        this.amountTextField = MUIOutlet(this, "amount-tf", "MUITextField"); 
        this.setupTextFieldEvents(this.amountTextField);
        if (this.amountTextField) this.amountTextField.setOnChangeText(this, function(textField:MUITextField, value:string){
            this._amount = this.cf.numberFromString(value);
        });        

        this.debitLabel = MUIOutlet(this, "debit-lbl", "MUILabel");
        this.debitTextField = MUIOutlet(this, "debit-tf", "MUITextField");
        this.setupTextFieldEvents(this.debitTextField);
        if (this.debitTextField) {
            //this.debitTextField.formatter = this.cf;
            this.debitTextField.setOnChangeText(this, function(this:AccountingEntryCell, textField:MUITextField, value:string){            
                this._item.debitAmount = this.cf.numberFromString(value);
                this.delegate?.amountDidUpdate(this);
            });
        }

        this.credictLabel = MUIOutlet(this, "credict-lbl", "MUILabel");
        this.credictTextField = MUIOutlet(this, "credict-tf", "MUITextField");
        this.setupTextFieldEvents(this.credictTextField);
        if (this.credictTextField) {
            //this.credictTextField.formatter = this.cf;
            this.credictTextField.setOnChangeText(this, function(this:AccountingEntryCell, textField:MUITextField, value:string){            
                this._item.creditAmount = this.cf.numberFromString(value);
                this.delegate?.amountDidUpdate(this);
            });
        }

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private _item:AccountingEntry = null;
    set item(item:AccountingEntry)
    {
        this._item = item;
        this.account = item.account;

        if (this.accountLabel)this.accountLabel.text = item.accountNumber;
        if (this.accountTextField)this.accountTextField.text = item.accountNumber;
        
        if (this.accountNameLabel)this.accountNameLabel.text = item.accountName;
        if (this.accountDropdown)this.accountDropdown.title = item.accountName;
        
        if (this.referenceLabel)this.referenceLabel.text = item.reference;
        if (this.referenceTextField)this.referenceTextField.text = item.reference;
        
        if (this.debitLabel)this.debitLabel.text = this.cf.stringFromNumber(item.debitAmount);
        if (this.debitTextField)this.debitTextField.text = this.cf.stringFromNumber(item.debitAmount);

        if (this.credictLabel)this.credictLabel.text = this.cf.stringFromNumber(item.creditAmount);
        if (this.credictTextField)this.credictTextField.text = this.cf.stringFromNumber(item.creditAmount);
    }

    updateValues(amount:number, account:LedgerAccount) 
    {
        this.amount = amount;
        this.account = account;
        this.accountDropdown.title = account?.description;
        this.accountTextField.text = account?.prefix;
    }

    set amount(amount:number){
        this._amount = amount;
        if (this.amountTextField) this.amountTextField.text = this.cf.stringFromNumber(amount);        
    }
    get amount():number{ return this._amount; }
    

    private showSelectedLedgerAccountViewController( searchString:string, hideSearchBar:boolean = false){        
        AppHelper.sharedInstance().showSelectLedgerAccountViewControllerFromView(this.accountDropdown, null, searchString, hideSearchBar, null, this, this.updateLedgerAccount);
    }

    private updateLedgerAccount(controller:SelectViewController, account:LedgerAccount){
        this.account = account;

        this.accountDropdown.title = account.description;
        this.accountTextField.text = account.prefix;

        // if (account != null) {
        //     MUICoreLayerRemoveStyle(this.dateLabel.layer, "alert");
        //     MUICoreLayerRemoveStyle(this.conceptLabel.layer, "alert");
        //     MUICoreLayerRemoveStyle(this.amountLabel.layer, "alert");            
        // }
    }
    
    set account(account:LedgerAccount){
        this._account = account;
        this._account_name = account?.name;
        this._account_number = account?.prefix;

        this.updateAccountingEntry();
    }
    get account():LedgerAccount{ return this._account; }    

    set reference(reference:string){
        this._reference = reference;
        this.updateAccountingEntry();
    }
    get reference():string{ return this._reference; }

    private updateAccountingEntry(){
        if (this._item == null) return;

        this._item.account = this._account;
        this._item.accountName = this._account_name;
        this._item.accountNumber = this._account_number;
        this._item.reference = this._reference;
        // this._item.debitAmount = this._debitAmount;
        // this._item.credictAmount = this._credictAmount
    }

    protected setupTextFieldEvents(textField:MUITextField){
        if (textField == null) return;
        
        textField.setOnBeginEditing(this, function(control:MUITextField, value:string){
            control.selectAll(null);
        });

        textField.setOnEnterPress(this, function(control:MUITextField){
            control.resignFirstResponder();
            this.enterDidPress()
        });
    }

    private enterDidPress(){
        this.delegate?.addEntryDidPress(this);
    }

}