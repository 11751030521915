
class DBSynEntityHeaderView extends MUIView
{
    dateLabel:MUILabel = null;
    referenceLabel:MUILabel = null;
    conceptLabel:MUILabel = null;

    awakeFromHTML(){
        this.dateLabel = MUIOutlet(this, "date-header-lbl", "MUILabel");
        this.referenceLabel = MUIOutlet(this, "reference-header-lbl", "MUILabel");
        this.conceptLabel = MUIOutlet(this, "concept-header-lbl", "MUILabel");
    }
    
    set item(item:DBSyncEntity) {        
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        
        this.dateLabel.text = ad.dateFormatter.stringFromDate(item.integratorDate);
        this.referenceLabel.text = item.integratorReferenceDocumentID;
        this.conceptLabel.text = item.integratorReferenceConcept;
    }
}