//
// Generated class ProductFormatRate
//

/// <reference path="ProductFormatRate_ManagedObject.ts" />

class ProductFormatRate extends ProductFormatRate_ManagedObject
{

}
