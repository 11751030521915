

/// <reference path="AccountLine.ts" />

// Generated class LoyaltyAccountLine_ManagedObject

class LoyaltyAccountLine_ManagedObject extends AccountLine
{
    // Relationship: economicEntity
    set economicEntity(value:EconomicEntity) {
        this.setValueForKey(value, 'economicEntity');
    }
    get economicEntity():EconomicEntity {
        return this.valueForKey('economicEntity') as EconomicEntity;
    }
}
