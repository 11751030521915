//
// Generated class StockAnnotation
//

/// <reference path="StockAnnotation_ManagedObject.ts" />

class StockAnnotation extends StockAnnotation_ManagedObject
{

}
