

class RoomBookingListCell extends UITableViewCell
{
    private roomLabel:MUILabel = null;
    private clientLabel: MUILabel = null;
    private checkInDateLabel:MUILabel = null;
    private checkOutDateLabel:MUILabel = null;
    private paxLabel: MUILabel = null;
    private optionComboBox:MUIComboBox = null;
    private maxCreditTextField:MUITextField = null;  

    awakeFromHTML(): void {
        this.roomLabel = MUIOutlet(this, "room-lbl", "MUILabel");
        this.clientLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.checkInDateLabel = MUIOutlet(this, "checkin-date-lbl", "MUILabel");
        this.checkOutDateLabel = MUIOutlet(this, "checkout-date-lbl", "MUILabel");
        this.paxLabel = MUIOutlet(this, "pax-lbl", "MUILabel");
        this.optionComboBox = MUIOutlet(this, "type-cb", "MUIComboBox");
        this.maxCreditTextField = MUIOutlet(this, "max-credit-tf", "MUITextField");

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    set item(item:RoomGuest) {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        this.roomLabel.text = item.roomBooking.room.name;
        this.clientLabel.text = item.name;
        this.checkInDateLabel.text = ad.dateFormatter.stringFromDate(item.roomBooking.beginDate);
        this.checkOutDateLabel.text = ad.dateFormatter.stringFromDate(item.roomBooking.endDate);
        this.paxLabel.text = String(item.roomBooking.guests.count);
        this.maxCreditTextField.text = ad.currencyFormatter.stringFromNumber( item.roomBooking.roomBookingGroup.maxCredit );
    }
}
