
/// <reference path="../base/BaseTableViewController.ts" />

class LicensesListViewController extends BaseTableViewController
{    
    static newInstance() : LicensesListViewController {
        let vc = new LicensesListViewController("licenses-list-view");
        vc.initWithResource("layout/licenses/LicensesListView.html");
        return vc;
    }

    private addButton:MUIButton = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, this.addBusinessLicense);

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        
        this.tableView.reloadData();
    }

    viewWillAppear(animated?: boolean): void {
        super.viewWillAppear( animated );

        MIONotificationCenter.defaultCenter().addObserver( this, "BusinessLicenseDidDelete", function(this:LicensesListViewController, object:any, userInfo:any){
            if ( this.selectedItem != object ) return;
            this.showNoItemSelected();
            this.selectedItem = null;
        });
    }

    viewDidDisappear(animated?: boolean): void {
        super.viewDidDisappear( animated );

        MIONotificationCenter.defaultCenter().removeObserver( this, "BusinessLicenseDidDelete" );
    }
    
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        
        let cell = tableview.dequeueReusableCellWithIdentifier("LicenseCell") as LicenseCell; 
        cell.item = item;   
        cell.selected = (item == this.selectedItem);
        
        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath);
        this.showSelectedItem();
    }

    private showSelectedItem(){
        let vc = LicensesDetailViewController.newInstance();
        vc.item = this.selectedItem;
        this.splitViewController.showDetailViewController(vc);        
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let pf = null;
        if (this.searchString != null) {
            pf = "name CONTAINS '" + this.searchString + "'";            
        }        

        let sds = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName( "BusinessLicense", sds, pf );            
        fetchRequest.relationshipKeyPathsForPrefetching = ["client"];
        fetchRequest.fetchLimit = 100;

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    private showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("License", NoItemSelectedViewControllerType.License);        
        this.splitViewController.showDetailViewController(vc);
    }

    private addBusinessLicense() {
        AppHelper.sharedInstance().showSelectClientViewControllerFromView(this.addButton, null, true, this, function(this:LicensesListViewController, controller:SelectViewController, value:Client){
            this.showNameAlert(value);
        }, this, function(){
            this.showAddClientViewController();
        });
    }

    private createLicence(client:Client, name:string){
        // TODO: Check if alreaady exists
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let lic = MIOEntityDescription.insertNewObjectForEntityForName( "BusinessLicense", ad.managedObjectContext) as BusinessLicense;

        lic.client = client;
        lic.active = true;
        lic.name = name;

        this.selectedItem = lic;

        DBHelper.saveMainContext();   
        
        this.showSelectedItem();
    }

    private showAddClientViewController(){
        
        let vc = new AddNewClientViewController('add-new-client-view');            
        vc.initWithResource('layout/clients/AddNewClientView.html');
        // vc.delegate = this;
        
        this.presentViewController(vc, true);
    }        

    private showNameAlert(client:Client) {
        let avc = new MUIAlertViewController();
        avc.initWithTitle("NAME", "NAME", MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(control:MUITextField){
            control.placeholderText = "NAME";
        });

        avc.addAction( MUIAlertAction.alertActionWithTitle("OK", MUIAlertActionStyle.Default, this, function(this:LicensesListViewController){
            this.createLicence(client, avc.textFields[0].text);
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle("CANCEL", MUIAlertActionStyle.Cancel, null, null) ); 
        
        this.presentViewController(avc, true);
    }
    
}