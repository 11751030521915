//
// Generated class LedgerAccount
//

/// <reference path="LedgerAccount_ManagedObject.ts" />

enum LedgerAccountType
{
    debit = 0,
    credit = 1
}

class LedgerAccount extends LedgerAccount_ManagedObject
{
    get description():string {
        return this.name + " (" + this.prefix + ")"
    }

    set normalizedAccountPrefix(value:string){
        let v = value.trim();
        let components = v.split('.');
        if (components.length == 1) { this.prefix = v; return; }
        
        let last = components[components.length - 1];
        let number = parseInt(last) ?? 0;
        components.pop();
        this.prefix = components.join('.') + '.' + number;
    }
}
