//
// Generated class SalesInvoice
//

/// <reference path="SalesInvoice_ManagedObject.ts" />

class SalesInvoice extends SalesInvoice_ManagedObject
{

}
