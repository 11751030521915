let weekdays = {
    "en" : [
        {'id':0, 'name':'Monday'},
        {'id':0, 'name':'Tuesday'},
        {'id':0, 'name':'Wednesday'},
        {'id':0, 'name':'Thursday'},
        {'id':0, 'name':'Friday'},
        {'id':0, 'name':'Saturday'},
        {'id':0, 'name':'Sunday'}
    ],
    "es" : [
        {'id':0, 'name':'Lunes'},
        {'id':0, 'name':'Martes'},
        {'id':0, 'name':'Miércoles'},
        {'id':0, 'name':'Jueves'},
        {'id':0, 'name':'Viernes'},
        {'id':0, 'name':'Sábado'},
        {'id':0, 'name':'Domingo'}
    ]
};

let NoWarehouse = '@@ ALMACEN GENERAL @@';