//
// Generated class SupplierPaymentInstallment
//

/// <reference path="SupplierPaymentInstallment_ManagedObject.ts" />

class SupplierPaymentInstallment extends SupplierPaymentInstallment_ManagedObject
{

}
