class CashDeskSessionCountViewController extends MUIViewController
{       
    private backButton:MUIButton = null;
    private segmentedControl:MUISegmentedControl = null;
    private closeSessionButton:MUIButton = null;
    
    private pageController:MUIPageController = null;

    private summaryViewController:CashDeskSessionCountSummaryViewController = null;
    private ticketsViewController:MUIViewController = null;
    private operationsViewController:CashDeskSessionOperationListViewController = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");
        this.backButton.setAction(this, function(control){
            this.navigationController.popViewController(true);
        });
        
        this.segmentedControl = MUIOutlet(this, "segmented-control", "MUISegmentedControl");
        this.segmentedControl.setAction(this, function(control, index){
            this.pageController.showPageAtIndex(index);
        });

        this.closeSessionButton = MUIOutlet(this, 'close-btn', "MUIButton");
        this.closeSessionButton.setAction(this, function(control){
            this.closeSessionButton.enabled = false;
            this.summaryViewController.closeSession(this, function(){
                this.closeSessionButton.enabled = true;
            });
        });

        this.pageController = MUIOutlet(this, "page-controller", "MUIPageController");
        
        this.summaryViewController = new CashDeskSessionCountSummaryViewController("cashdesk-session-count-summary-view");
        this.summaryViewController.initWithResource("layout/cashdesk/CashDeskSessionCountSummaryView.html");
        this.summaryViewController.session = this.session;
        this.summaryViewController.navigationController = this.navigationController;
        this.pageController.addPageViewController(this.summaryViewController);

        // this.operationsViewController = new CashDeskSessionOperationListViewController("");
        // this.operationsViewController.initWithResource("layout/cashdesk/CashDeskSessionCountSummaryView.html");
        // this.operationsViewController.session = this.session;
        // this.operationsViewController.navigationController = this.navigationController;
        // this.pageController.addPageViewController(this.operationsViewController);
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
    }

    private _session:CashDeskSession = null;
    get session(){return this._session;}
    set session(value){
        this._session = value;
        if (this.summaryViewController != null) this.summaryViewController.session = value;
        if (this.operationsViewController != null) this.operationsViewController.session = value;
    }
}