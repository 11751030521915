

class AddChildPlaceViewController extends MUIViewController
{
    private cancelButton:MUIButton = null;
    private createButton:MUIButton = null;

    private nameTextField:MUITextField = null;


    get preferredContentSize(){
        return new MIOSize(600, 435);
    }

    viewDidLoad(){
        
        this.cancelButton = MUIOutlet(this, "cancel-btn", "MUIButton");
        this.cancelButton.setAction(this, function(this:AddChildPlaceViewController){
            this.dismissViewController(true);
        });

        this.createButton = MUIOutlet(this, "create-btn", "MUIButton");
        this.createButton.enabled = false;
    }
}