

/// <reference path="Rule.ts" />

// Generated class RuleSet_ManagedObject

class RuleSet_ManagedObject extends Rule
{

    // Property: contextEntityID
    set contextEntityID(value:string) {
        this.setValueForKey(value, 'contextEntityID');
    }
    get contextEntityID():string {
        return this.valueForKey('contextEntityID');
    }
    set contextEntityIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'contextEntityID');
    }
    get contextEntityIDPrimitiveValue():string {
        return this.primitiveValueForKey('contextEntityID');
    }

    // Property: ruleType
    set ruleType(value:number) {
        this.setValueForKey(value, 'ruleType');
    }
    get ruleType():number {
        return this.valueForKey('ruleType');
    }
    set ruleTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'ruleType');
    }
    get ruleTypePrimitiveValue():number {
        return this.primitiveValueForKey('ruleType');
    }

    // Relationship: rules
    protected _rules:MIOManagedObjectSet = null;
    get rules():MIOManagedObjectSet {
        return this.valueForKey('rules');
    }
    addRulesObject(value:Rule) {
        this._addObjectForKey(value, 'rules');
    }
    removeRulesObject(value:Rule) {
        this._removeObjectForKey(value, 'rules');
    }
}
