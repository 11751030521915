

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class UserReportOption_ManagedObject

class UserReportOption_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: hidden
    set hidden(value:boolean) {
        this.setValueForKey(value, 'hidden');
    }
    get hidden():boolean {
        return this.valueForKey('hidden');
    }
    set hiddenPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'hidden');
    }
    get hiddenPrimitiveValue():boolean {
        return this.primitiveValueForKey('hidden');
    }

    // Property: reportColumnKey
    set reportColumnKey(value:string) {
        this.setValueForKey(value, 'reportColumnKey');
    }
    get reportColumnKey():string {
        return this.valueForKey('reportColumnKey');
    }
    set reportColumnKeyPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'reportColumnKey');
    }
    get reportColumnKeyPrimitiveValue():string {
        return this.primitiveValueForKey('reportColumnKey');
    }

    // Property: reportID
    set reportID(value:string) {
        this.setValueForKey(value, 'reportID');
    }
    get reportID():string {
        return this.valueForKey('reportID');
    }
    set reportIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'reportID');
    }
    get reportIDPrimitiveValue():string {
        return this.primitiveValueForKey('reportID');
    }

    // Property: reportItemKey
    set reportItemKey(value:string) {
        this.setValueForKey(value, 'reportItemKey');
    }
    get reportItemKey():string {
        return this.valueForKey('reportItemKey');
    }
    set reportItemKeyPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'reportItemKey');
    }
    get reportItemKeyPrimitiveValue():string {
        return this.primitiveValueForKey('reportItemKey');
    }
    // Relationship: user
    set user(value:User) {
        this.setValueForKey(value, 'user');
    }
    get user():User {
        return this.valueForKey('user') as User;
    }
}
