

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class LocalizedValue_ManagedObject

class LocalizedValue_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: entityIdentifier
    set entityIdentifier(value:string) {
        this.setValueForKey(value, 'entityIdentifier');
    }
    get entityIdentifier():string {
        return this.valueForKey('entityIdentifier');
    }
    set entityIdentifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'entityIdentifier');
    }
    get entityIdentifierPrimitiveValue():string {
        return this.primitiveValueForKey('entityIdentifier');
    }

    // Property: entityTypeName
    set entityTypeName(value:string) {
        this.setValueForKey(value, 'entityTypeName');
    }
    get entityTypeName():string {
        return this.valueForKey('entityTypeName');
    }
    set entityTypeNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'entityTypeName');
    }
    get entityTypeNamePrimitiveValue():string {
        return this.primitiveValueForKey('entityTypeName');
    }

    // Property: key
    set key(value:string) {
        this.setValueForKey(value, 'key');
    }
    get key():string {
        return this.valueForKey('key');
    }
    set keyPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'key');
    }
    get keyPrimitiveValue():string {
        return this.primitiveValueForKey('key');
    }

    // Property: language
    set language(value:string) {
        this.setValueForKey(value, 'language');
    }
    get language():string {
        return this.valueForKey('language');
    }
    set languagePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'language');
    }
    get languagePrimitiveValue():string {
        return this.primitiveValueForKey('language');
    }

    // Property: value
    set value(value:string) {
        this.setValueForKey(value, 'value');
    }
    get value():string {
        return this.valueForKey('value');
    }
    set valuePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'value');
    }
    get valuePrimitiveValue():string {
        return this.primitiveValueForKey('value');
    }
}
