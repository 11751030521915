//
// Generated class WarehousePrinterLink
//

/// <reference path="WarehousePrinterLink_ManagedObject.ts" />

class WarehousePrinterLink extends WarehousePrinterLink_ManagedObject
{

}
