//
// Generated class ClientDebtBalance
//

/// <reference path="ClientDebtBalance_ManagedObject.ts" />

class ClientDebtBalance extends ClientDebtBalance_ManagedObject
{

}
