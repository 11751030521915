//
// Generated class WarehouseOrder
//

/// <reference path="WarehouseOrder_ManagedObject.ts" />

class WarehouseOrder extends WarehouseOrder_ManagedObject
{

}
