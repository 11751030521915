

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Configuration_ManagedObject

class Configuration_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: relationEntityName
    set relationEntityName(value:string) {
        this.setValueForKey(value, 'relationEntityName');
    }
    get relationEntityName():string {
        return this.valueForKey('relationEntityName');
    }
    set relationEntityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'relationEntityName');
    }
    get relationEntityNamePrimitiveValue():string {
        return this.primitiveValueForKey('relationEntityName');
    }

    // Property: relationEntityTitleKey
    set relationEntityTitleKey(value:string) {
        this.setValueForKey(value, 'relationEntityTitleKey');
    }
    get relationEntityTitleKey():string {
        return this.valueForKey('relationEntityTitleKey');
    }
    set relationEntityTitleKeyPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'relationEntityTitleKey');
    }
    get relationEntityTitleKeyPrimitiveValue():string {
        return this.primitiveValueForKey('relationEntityTitleKey');
    }

    // Property: relationEntityValueKey
    set relationEntityValueKey(value:string) {
        this.setValueForKey(value, 'relationEntityValueKey');
    }
    get relationEntityValueKey():string {
        return this.valueForKey('relationEntityValueKey');
    }
    set relationEntityValueKeyPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'relationEntityValueKey');
    }
    get relationEntityValueKeyPrimitiveValue():string {
        return this.primitiveValueForKey('relationEntityValueKey');
    }

    // Property: relationEntityValueTitle
    set relationEntityValueTitle(value:string) {
        this.setValueForKey(value, 'relationEntityValueTitle');
    }
    get relationEntityValueTitle():string {
        return this.valueForKey('relationEntityValueTitle');
    }
    set relationEntityValueTitlePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'relationEntityValueTitle');
    }
    get relationEntityValueTitlePrimitiveValue():string {
        return this.primitiveValueForKey('relationEntityValueTitle');
    }

    // Property: section
    set section(value:string) {
        this.setValueForKey(value, 'section');
    }
    get section():string {
        return this.valueForKey('section');
    }
    set sectionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'section');
    }
    get sectionPrimitiveValue():string {
        return this.primitiveValueForKey('section');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: value
    set value(value:any) {
        this.setValueForKey(value, 'value');
    }
    get value():any {
        return this.valueForKey('value');
    }
    set valuePrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'value');
    }
    get valuePrimitiveValue():any {
        return this.primitiveValueForKey('value');
    }
}
