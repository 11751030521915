//
// Generated class OnlinePlatform
//

/// <reference path="OnlinePlatform_ManagedObject.ts" />

class OnlinePlatform extends OnlinePlatform_ManagedObject
{

}
