

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Application_ManagedObject

class Application_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: deviceID
    set deviceID(value:string) {
        this.setValueForKey(value, 'deviceID');
    }
    get deviceID():string {
        return this.valueForKey('deviceID');
    }
    set deviceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deviceID');
    }
    get deviceIDPrimitiveValue():string {
        return this.primitiveValueForKey('deviceID');
    }

    // Property: error
    set error(value:string) {
        this.setValueForKey(value, 'error');
    }
    get error():string {
        return this.valueForKey('error');
    }
    set errorPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'error');
    }
    get errorPrimitiveValue():string {
        return this.primitiveValueForKey('error');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: pushNotificationToken
    set pushNotificationToken(value:string) {
        this.setValueForKey(value, 'pushNotificationToken');
    }
    get pushNotificationToken():string {
        return this.valueForKey('pushNotificationToken');
    }
    set pushNotificationTokenPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'pushNotificationToken');
    }
    get pushNotificationTokenPrimitiveValue():string {
        return this.primitiveValueForKey('pushNotificationToken');
    }

    // Property: replacing
    set replacing(value:number) {
        this.setValueForKey(value, 'replacing');
    }
    get replacing():number {
        return this.valueForKey('replacing');
    }
    set replacingPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'replacing');
    }
    get replacingPrimitiveValue():number {
        return this.primitiveValueForKey('replacing');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }
}
