
class WorkerSessionCell extends MUITableViewCell
{
    private titleLabel:MUILabel = null;
    private beginDateTextField:MUITextField = null;
    private beginDateButton:MUIButton = null;
    private endDateTextField:MUITextField = null;
    private endDateButton:MUIButton = null;
    private hoursLabel:MUILabel = null;

    private dtf = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter as MIODateFormatter;

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
        this.beginDateTextField = MUIOutlet(this, "begin-date-tf", "MUITextField");
        //this.beginDateTextField.formatter = this.dtf;
        this.beginDateTextField.setOnChangeText(this, function(control, value){
            let date = this.dtf.dateFromString(value);
            this.workSession.beginDate = date;
            this.updateHours();
        });

        this.beginDateButton = MUIOutlet(this, "begin-date-btn", "MUIButton");
        this.beginDateButton.setAction(this, function(){
            let dp = new MUIDatePickerController();
            dp.init();        
            dp.delegate = this;
            dp.tag = 1;
            dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

            let pc = dp.popoverPresentationController;
            pc.sourceRect = this.beginDateButton.frame;
            pc.sourceView = this.beginDateButton;

            AppHelper.sharedInstance().presentViewController(dp, true);
        });
        
        this.endDateTextField = MUIOutlet(this, "end-date-tf", "MUITextField");
        //this.endDateTextField.formatter = this.dtf;
        this.endDateTextField.setOnChangeText(this, function(control, value){
            let date = this.dtf.dateFromString(value);
            this.workSession.endDate = date;
            this.updateHours();
        });

        this.endDateButton = MUIOutlet(this, "end-date-btn", "MUIButton");
        this.endDateButton.setAction(this, function(){
            let dp = new MUIDatePickerController();
            dp.init();        
            dp.delegate = this;
            dp.tag = 2;
            dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

            let pc = dp.popoverPresentationController;
            pc.sourceRect = this.endDateButton.frame;
            pc.sourceView = this.endDateButton;

            AppHelper.sharedInstance().presentViewController(dp, true);
        });

        this.hoursLabel = MUIOutlet(this, "hours-lbl", "MUILabel");

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    private workSession:WorkSession = null;
    setItem(item:WorkSession, index){
        this.workSession = item;

        this.titleLabel.text = MIOLocalizeString("WORK SESSION", "Session") + " " + index;
        this.beginDateTextField.text = this.dtf.stringFromDate(item.beginDate);
        this.endDateTextField.text = this.dtf.stringFromDate(item.endDate);        

        this.updateHours();
    }
    
    //update the total hours working field
    private updateHours(){        

        //calculate and format total session time for each line
        let total = this.workSession.workedHours;
        let time = total % 1;
        let minutes:any = Math.floor(time * 60);
        minutes = ("0" + minutes).slice(-2);
        let hours:any = Math.floor(total);
        if (hours < 10) {
            hours = ("0" + hours).slice(-2);
        }
        
        this.hoursLabel.text =  hours + ":" + minutes;
    }

    didSelectDate(datePickerController, date){
        if (datePickerController.tag == 1) {
                        
            let h = this.workSession.beginDate.getHours();
            let m = this.workSession.beginDate.getMinutes();            

            this.workSession.beginDate = date;
            this.workSession.beginDate.setHours(h);
            this.workSession.beginDate.setMinutes(m);

            this.beginDateTextField.text = this.dtf.stringFromDate(this.workSession.beginDate);
        }
        else if (datePickerController.tag == 2) {            
            
            let h = 0;
            let m = 0;

            if (this.workSession.endDate == null) {
                let now = MIODateNow();
                h = now.getHours();
                m = now.getMinutes();                
            }
            else {
                h = this.workSession.endDate.getHours();
                m = this.workSession.endDate.getMinutes();
            }

            this.workSession.endDate = date;
            this.workSession.endDate.setHours(h);
            this.workSession.endDate.setMinutes(m);

            this.endDateTextField.text = this.dtf.stringFromDate(this.workSession.endDate);        
        }

        this.updateHours();
    }
}