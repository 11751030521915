

class SupplierContactCell extends UITableViewCell
{
    private nameTextField:MUITextField = null;
    private phoneTextField:MUITextField = null;
    private emailTextField:MUITextField = null;
    private titleTextField:MUITextField = null;
    private commentsTextField:MUITextField = null;

    awakeFromHTML(): void {        
        this.nameTextField = MUIOutlet(this, "contact-name-tf", "MUITextField");
        this.nameTextField.setOnChangeText(this, function(this:SupplierContactCell, textField:MUITextField, value:string) {
            this.contact.name = value.trim();
        });
        this.phoneTextField = MUIOutlet(this, "contact-phone-tf", "MUITextField");
        this.phoneTextField.setOnChangeText(this, function(this:SupplierContactCell, textField:MUITextField, value:string) {
            let v = value.trim();
            this.contact.phone = v.length > 0 ? v : null;
        });

        this.emailTextField = MUIOutlet(this, "contact-email-tf", "MUITextField");
        this.emailTextField.setOnChangeText(this, function(this:SupplierContactCell, textField:MUITextField, value:string) {
            let v = value.trim();
            this.contact.email = v.length > 0 ? v : null;
        });

        this.titleTextField = MUIOutlet(this, "contact-department-tf", "MUITextField");
        this.titleTextField.setOnChangeText(this, function(this:SupplierContactCell, textField:MUITextField, value:string) {
            let v = value.trim();
            this.contact.title = v.length > 0 ? v : null;
        });

        this.commentsTextField = MUIOutlet(this, "comments-tf", "MUITextField");
        this.commentsTextField.setOnChangeText(this, function(this:SupplierContactCell, textField:MUITextField, value:string) {
            let v = value.trim();
            this.contact.comments = v.length > 0 ? v : null;
        });


        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private contact:Contact = null;
    set item(item:Contact){
        this.contact = item;
        this.nameTextField.text = item.name;
        this.phoneTextField.text = item.phone;
        this.emailTextField.text = item.email;
        this.titleTextField.text = item.title;
        this.commentsTextField.text = item.comments;
    }
}