//
// Generated class WorkerLocation
//

/// <reference path="WorkerLocation_ManagedObject.ts" />

class WorkerLocation extends WorkerLocation_ManagedObject
{

}
