/**
 * Created by miguel on 15/3/17.
 */

 enum CalendarSingleViewControllerMode {
     Day,
     Week
 }

class CalendarSingleViewController extends MUIViewController
{    
    dateTimeFrom:Date = null;
    dateTimeTo:Date = null;

    private calendarNavBar:CalendarNavBar = null;
    private calendarView:MUICalendarView = null;

    private selectedDays = MIOSet.set();

    get preferredContentSize(){
        return new MIOSize(500, 413);
    }

    viewDidLoad() { 
        super.viewDidLoad();

        this.calendarNavBar = MUIOutlet(this, "calendar-nav-bar", "CalendarNavBar");
        this.calendarNavBar.delegate = this;

        this.calendarView = MUIOutlet(this, 'calendar-view', 'MUICalendarView');
        this.calendarView.dataSource = this;
        this.calendarView.delegate = this;    
        this.calendarView.setNavBarHidden(true);
    }
    
    private _mode = CalendarSingleViewControllerMode.Day;
    set mode(value){
        this._mode = value;
    }

    monthAndDateDidChange(month, year){
        this.calendarView.setMonthAndYear(month, year);
    }

    didSelectDayCellAtDate(calendarView:MUICalendarView, date:Date){
        if (this._mode == CalendarSingleViewControllerMode.Day){
            this.dayDidChange(date);
        }
        else if (this._mode == CalendarSingleViewControllerMode.Week){
            this.selectWeekFromDate(date);
        }
    }

    private dayDidChange(date:Date){

        this.deselectCells();

        this.selectedDays.addObject(MIODateCopy(date));

        let from = MIODateGetDateString(date) + " 00:00:00";
        let to = MIODateGetDateString(date) + " 23:59:59";
        
        this.dateTimeFrom = MIODateFromString(from);
        this.dateTimeTo = MIODateFromString(to);        
    }

    private deselectCells(){
        for (let index = 0; index < this.selectedDays.count; index++){
            let d = this.selectedDays.objectAtIndex(index);
            let cell = this.calendarView.cellDayAtDate(d);            
            if (cell != null) cell.setSelected(false);
        }

        this.selectedDays.removeAllObjects();
    }

    private selectWeekFromDate(date:Date){

        this.deselectCells();
        
        let firstDayOfTheWeek = MIODateGetFirstDayOfTheWeek(date);
        let lastDayOfTheWeek = MIODateGetLastDayOfTheWeek(date);
        let currentDate = MIODateCopy(firstDayOfTheWeek);

        while(currentDate <= lastDayOfTheWeek) {
            this.selectedDays.addObject(MIODateCopy(currentDate));
            let cell = this.calendarView.cellDayAtDate(currentDate);
            if (cell != null) cell.setSelected(true);
            currentDate.setDate(currentDate.getDate() + 1);            
        }

        let from = MIODateGetDateString(firstDayOfTheWeek) + " 00:00:00";
        let to = MIODateGetDateString(lastDayOfTheWeek) + " 23:59:59";
        
        this.dateTimeFrom = MIODateFromString(from);
        this.dateTimeTo = MIODateFromString(to);        
    }
}
