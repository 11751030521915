

class EventDetailViewController extends MUIViewController
{
    private ruleTypeComboBox:MUIComboBox = null;
    private segmentedControl:MUISegmentedControl = null;
    private saveButton:MUIButton = null; 
    private addButton:MUIButton = null;   

    private pageController:MUIPageController = null;
    
    private infoVC:EventDetailInfoViewController = null;    
    private actionVC:EventDetailActionViewController = null;
    private budgetVC:EventDetailBudgetViewController = null;
    private zoneSetupViewController:PartyDetailZoneSetupViewController = null;
    private tablemapVC:EventDetailMapViewController = null;
    private codesVC:OfferCodeDetailViewController = null;
    //private cashDesksVC:EventDetailMapViewController = null;

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    private df = MUIWebApplication.sharedInstance().delegate.dateFormatter;

    viewDidLoad(){
        super.viewDidLoad();

        this.ruleTypeComboBox = MUIOutlet(this, "rule-type-cb", "MUIComboBox");
        this.ruleTypeComboBox.hidden = true;
        this.ruleTypeComboBox.setOnChangeAction(this, this.setRuleTypeAction);
        
        this.segmentedControl = MUIOutlet(this, 'segmented_control', 'MUISegmentedControl');
        this.segmentedControl.setAction(this, function (control, index) { 
            this.ruleTypeComboBox.hidden = index == 3 ? false : true;
            this.addButton.hidden = index == 3 ? false : true;
            this.pageController.showPageAtIndex(index);
        });

        this.saveButton = MUIOutlet(this, 'save-btn','MUIButton');
        this.saveButton.setAction(this, function(){
           DBHelper.saveMainContext();
        });   
        
        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.hidden = true;
        this.addButton.setAction(this, this.addButtonAction);

        this.pageController = MUIOutlet(this, 'pdpage_controller', 'MUIPageController');

        this.infoVC = new EventDetailInfoViewController("event-detail-info-view");
        this.infoVC.initWithResource("layout/event/EventDetailInfoView.html");
        //this.eventInfoVC.navigationController = this.navigationController;        
        this.pageController.addPageViewController(this.infoVC);
       
        this.budgetVC = new EventDetailBudgetViewController("event-detail-budget-view");
        this.budgetVC.initWithResource("layout/event/EventDetailBudgetView.html");
        //this.eventBudgetVC.navigationController = this.navigationController;        
        this.pageController.addPageViewController(this.budgetVC);       

        this.actionVC = new EventDetailActionViewController("event-detail-action-view");
        this.actionVC.initWithResource("layout/event/EventDetailActionView.html");
        //this.eventActionVC.navigationController = this.navigationController;        
        this.pageController.addPageViewController(this.actionVC);

        this.zoneSetupViewController = new PartyDetailZoneSetupViewController("parties-booking-zone-setup");
        this.zoneSetupViewController.initWithResource("layout/parties/PartiesBookingZoneSetupView.html");
        this.pageController.addPageViewController(this.zoneSetupViewController);

        this.tablemapVC = new EventDetailMapViewController("event-detail-map-view");
        this.tablemapVC.initWithResource("layout/event/EventDetailMapView.html");
        //this.eventMapVC.navigationController = this.navigationController;        
        this.pageController.addPageViewController(this.tablemapVC);

        this.codesVC = new OfferCodeDetailViewController("offer-code-detail-view");
        this.codesVC.initWithResource("layout/offers/OfferCodeDetailView.html");
        this.pageController.addPageViewController(this.codesVC);
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI(); 
    }

    private _event:BookingEvent = null;
    set event(value:BookingEvent){
        this._event = value;
        this.updateUI();
    }

    private updateUI(){
        if(this.viewIsLoaded == false || this._event == null) return;

        this.infoVC.event = this._event;
        this.zoneSetupViewController.event = this._event;
     } 

     private setRuleTypeAction(control, index){
         this.zoneSetupViewController.ruleType = parseInt(index);
     }

     private addButtonAction(){
         this.zoneSetupViewController.addRule();
     }

}