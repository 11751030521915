

/// <reference path="CoreEntity.ts" />

// Generated class ManagerSection_ManagedObject

class ManagerSection_ManagedObject extends CoreEntity
{

    // Property: bitPosition
    set bitPosition(value:number) {
        this.setValueForKey(value, 'bitPosition');
    }
    get bitPosition():number {
        return this.valueForKey('bitPosition');
    }
    set bitPositionPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'bitPosition');
    }
    get bitPositionPrimitiveValue():number {
        return this.primitiveValueForKey('bitPosition');
    }

    // Property: isActive
    set isActive(value:boolean) {
        this.setValueForKey(value, 'isActive');
    }
    get isActive():boolean {
        return this.valueForKey('isActive');
    }
    set isActivePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isActive');
    }
    get isActivePrimitiveValue():boolean {
        return this.primitiveValueForKey('isActive');
    }

    // Property: isBeta
    set isBeta(value:boolean) {
        this.setValueForKey(value, 'isBeta');
    }
    get isBeta():boolean {
        return this.valueForKey('isBeta');
    }
    set isBetaPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isBeta');
    }
    get isBetaPrimitiveValue():boolean {
        return this.primitiveValueForKey('isBeta');
    }

    // Property: isDesktop
    set isDesktop(value:boolean) {
        this.setValueForKey(value, 'isDesktop');
    }
    get isDesktop():boolean {
        return this.valueForKey('isDesktop');
    }
    set isDesktopPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isDesktop');
    }
    get isDesktopPrimitiveValue():boolean {
        return this.primitiveValueForKey('isDesktop');
    }

    // Property: isDuallink
    set isDuallink(value:boolean) {
        this.setValueForKey(value, 'isDuallink');
    }
    get isDuallink():boolean {
        return this.valueForKey('isDuallink');
    }
    set isDuallinkPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isDuallink');
    }
    get isDuallinkPrimitiveValue():boolean {
        return this.primitiveValueForKey('isDuallink');
    }

    // Property: isPad
    set isPad(value:boolean) {
        this.setValueForKey(value, 'isPad');
    }
    get isPad():boolean {
        return this.valueForKey('isPad');
    }
    set isPadPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isPad');
    }
    get isPadPrimitiveValue():boolean {
        return this.primitiveValueForKey('isPad');
    }

    // Property: isPhone
    set isPhone(value:boolean) {
        this.setValueForKey(value, 'isPhone');
    }
    get isPhone():boolean {
        return this.valueForKey('isPhone');
    }
    set isPhonePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isPhone');
    }
    get isPhonePrimitiveValue():boolean {
        return this.primitiveValueForKey('isPhone');
    }

    // Property: isReseller
    set isReseller(value:boolean) {
        this.setValueForKey(value, 'isReseller');
    }
    get isReseller():boolean {
        return this.valueForKey('isReseller');
    }
    set isResellerPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isReseller');
    }
    get isResellerPrimitiveValue():boolean {
        return this.primitiveValueForKey('isReseller');
    }

    // Property: module
    set module(value:string) {
        this.setValueForKey(value, 'module');
    }
    get module():string {
        return this.valueForKey('module');
    }
    set modulePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'module');
    }
    get modulePrimitiveValue():string {
        return this.primitiveValueForKey('module');
    }

    // Property: moduleIndex
    set moduleIndex(value:number) {
        this.setValueForKey(value, 'moduleIndex');
    }
    get moduleIndex():number {
        return this.valueForKey('moduleIndex');
    }
    set moduleIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'moduleIndex');
    }
    get moduleIndexPrimitiveValue():number {
        return this.primitiveValueForKey('moduleIndex');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }
}
