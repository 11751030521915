//
// Generated class Party
//

/// <reference path="Party_ManagedObject.ts" />

class Party extends Party_ManagedObject
{

}
