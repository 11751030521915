//
// Generated class RuleSetGuard
//

/// <reference path="RuleSetGuard_ManagedObject.ts" />

class RuleSetGuard extends RuleSetGuard_ManagedObject
{

}
