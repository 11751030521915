//
// Generated class OnlinePreset
//

/// <reference path="OnlinePreset_ManagedObject.ts" />

class OnlinePreset extends OnlinePreset_ManagedObject
{

}
