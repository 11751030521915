//
// Generated class ProductionProduct
//

/// <reference path="ProductionProduct_ManagedObject.ts" />

class ProductionProduct extends ProductionProduct_ManagedObject
{

}
