//
// Generated class TicketPrinterLink
//

/// <reference path="TicketPrinterLink_ManagedObject.ts" />

class TicketPrinterLink extends TicketPrinterLink_ManagedObject
{

}
