//
// Generated class SupportFile
//

/// <reference path="SupportFile_ManagedObject.ts" />

class SupportFile extends SupportFile_ManagedObject
{

}
