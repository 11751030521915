

// Generated class Migrate_ManagedObject

class Migrate_ManagedObject extends MIOManagedObject
{

    // Property: createdAt
    set createdAt(value:Date) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():Date {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():Date {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: entityID
    set entityID(value:string) {
        this.setValueForKey(value, 'entityID');
    }
    get entityID():string {
        return this.valueForKey('entityID');
    }
    set entityIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'entityID');
    }
    get entityIDPrimitiveValue():string {
        return this.primitiveValueForKey('entityID');
    }

    // Property: entityType
    set entityType(value:string) {
        this.setValueForKey(value, 'entityType');
    }
    get entityType():string {
        return this.valueForKey('entityType');
    }
    set entityTypePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'entityType');
    }
    get entityTypePrimitiveValue():string {
        return this.primitiveValueForKey('entityType');
    }

    // Property: info
    set info(value:any) {
        this.setValueForKey(value, 'info');
    }
    get info():any {
        return this.valueForKey('info');
    }
    set infoPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'info');
    }
    get infoPrimitiveValue():any {
        return this.primitiveValueForKey('info');
    }

    // Property: migratedVersion
    set migratedVersion(value:number) {
        this.setValueForKey(value, 'migratedVersion');
    }
    get migratedVersion():number {
        return this.valueForKey('migratedVersion');
    }
    set migratedVersionPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'migratedVersion');
    }
    get migratedVersionPrimitiveValue():number {
        return this.primitiveValueForKey('migratedVersion');
    }

    // Property: source
    set source(value:string) {
        this.setValueForKey(value, 'source');
    }
    get source():string {
        return this.valueForKey('source');
    }
    set sourcePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'source');
    }
    get sourcePrimitiveValue():string {
        return this.primitiveValueForKey('source');
    }

    // Property: targetSchema
    set targetSchema(value:string) {
        this.setValueForKey(value, 'targetSchema');
    }
    get targetSchema():string {
        return this.valueForKey('targetSchema');
    }
    set targetSchemaPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'targetSchema');
    }
    get targetSchemaPrimitiveValue():string {
        return this.primitiveValueForKey('targetSchema');
    }

    // Property: updatedAt
    set updatedAt(value:Date) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():Date {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('updatedAt');
    }
}
