

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class CategoryPlace_ManagedObject

class CategoryPlace_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: isAvailable
    set isAvailable(value:boolean) {
        this.setValueForKey(value, 'isAvailable');
    }
    get isAvailable():boolean {
        return this.valueForKey('isAvailable');
    }
    set isAvailablePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isAvailable');
    }
    get isAvailablePrimitiveValue():boolean {
        return this.primitiveValueForKey('isAvailable');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: showInBar
    set showInBar(value:boolean) {
        this.setValueForKey(value, 'showInBar');
    }
    get showInBar():boolean {
        return this.valueForKey('showInBar');
    }
    set showInBarPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'showInBar');
    }
    get showInBarPrimitiveValue():boolean {
        return this.primitiveValueForKey('showInBar');
    }

    // Property: showInOnline
    set showInOnline(value:boolean) {
        this.setValueForKey(value, 'showInOnline');
    }
    get showInOnline():boolean {
        return this.valueForKey('showInOnline');
    }
    set showInOnlinePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'showInOnline');
    }
    get showInOnlinePrimitiveValue():boolean {
        return this.primitiveValueForKey('showInOnline');
    }

    // Property: showInTables
    set showInTables(value:boolean) {
        this.setValueForKey(value, 'showInTables');
    }
    get showInTables():boolean {
        return this.valueForKey('showInTables');
    }
    set showInTablesPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'showInTables');
    }
    get showInTablesPrimitiveValue():boolean {
        return this.primitiveValueForKey('showInTables');
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
}
