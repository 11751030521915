
function getCountryName ( iso_code: string ) {
    return (g_country[ iso_code ] || { "name": "" }).name ;
}

const g_country = {
   "AF":{
      "identifier":1,
      "name":"Afghanistan",
      "iso2":"AF",
      "iso3":"AFG",
      "ison":"4"
   },
   "AL":{
      "identifier":2,
      "name":"Albania",
      "iso2":"AL",
      "iso3":"ALB",
      "ison":"8"
   },
   "DZ":{
      "identifier":3,
      "name":"Algeria",
      "iso2":"DZ",
      "iso3":"DZA",
      "ison":"12"
   },
   "AS":{
      "identifier":4,
      "name":"American Samoa",
      "iso2":"AS",
      "iso3":"ASM",
      "ison":"16"
   },
   "AD":{
      "identifier":5,
      "name":"Andorra",
      "iso2":"AD",
      "iso3":"AND",
      "ison":"20"
   },
   "AO":{
      "identifier":6,
      "name":"Angola",
      "iso2":"AO",
      "iso3":"AGO",
      "ison":"24"
   },
   "AI":{
      "identifier":7,
      "name":"Anguilla",
      "iso2":"AI",
      "iso3":"AIA",
      "ison":"660"
   },
   "AQ":{
      "identifier":8,
      "name":"Antarctica",
      "iso2":"AQ",
      "iso3":"ATA",
      "ison":"10"
   },
   "AG":{
      "identifier":9,
      "name":"Antigua and Barbuda",
      "iso2":"AG",
      "iso3":"ATG",
      "ison":"28"
   },
   "AR":{
      "identifier":10,
      "name":"Argentina",
      "iso2":"AR",
      "iso3":"ARG",
      "ison":"32"
   },
   "AM":{
      "identifier":11,
      "name":"Armenia",
      "iso2":"AM",
      "iso3":"ARM",
      "ison":"51"
   },
   "AW":{
      "identifier":12,
      "name":"Aruba",
      "iso2":"AW",
      "iso3":"ABW",
      "ison":"533"
   },
   "--":{
      "identifier":272,
      "name":"Zaire",
      "iso2":"--",
      "iso3":"-- ",
      "ison":"--"
   },
   "AU":{
      "identifier":14,
      "name":"Australia",
      "iso2":"AU",
      "iso3":"AUS",
      "ison":"36"
   },
   "AT":{
      "identifier":15,
      "name":"Austria",
      "iso2":"AT",
      "iso3":"AUT",
      "ison":"40"
   },
   "AZ":{
      "identifier":16,
      "name":"Azerbaijan",
      "iso2":"AZ",
      "iso3":"AZE",
      "ison":"31"
   },
   "BS":{
      "identifier":17,
      "name":"The Bahamas",
      "iso2":"BS",
      "iso3":"BHS",
      "ison":"44"
   },
   "BH":{
      "identifier":18,
      "name":"Bahrain",
      "iso2":"BH",
      "iso3":"BHR",
      "ison":"48"
   },
   "BD":{
      "identifier":20,
      "name":"Bangladesh",
      "iso2":"BD",
      "iso3":"BGD",
      "ison":"50"
   },
   "BB":{
      "identifier":21,
      "name":"Barbados",
      "iso2":"BB",
      "iso3":"BRB",
      "ison":"52"
   },
   "BY":{
      "identifier":23,
      "name":"Belarus",
      "iso2":"BY",
      "iso3":"BLR",
      "ison":"112"
   },
   "BE":{
      "identifier":24,
      "name":"Belgium",
      "iso2":"BE",
      "iso3":"BEL",
      "ison":"56"
   },
   "BZ":{
      "identifier":25,
      "name":"Belize",
      "iso2":"BZ",
      "iso3":"BLZ",
      "ison":"84"
   },
   "BJ":{
      "identifier":26,
      "name":"Benin",
      "iso2":"BJ",
      "iso3":"BEN",
      "ison":"204"
   },
   "BM":{
      "identifier":27,
      "name":"Bermuda",
      "iso2":"BM",
      "iso3":"BMU",
      "ison":"60"
   },
   "BT":{
      "identifier":28,
      "name":"Bhutan",
      "iso2":"BT",
      "iso3":"BTN",
      "ison":"64"
   },
   "BO":{
      "identifier":29,
      "name":"Bolivia",
      "iso2":"BO",
      "iso3":"BOL",
      "ison":"68"
   },
   "BA":{
      "identifier":30,
      "name":"Bosnia and Herzegovina",
      "iso2":"BA",
      "iso3":"BIH",
      "ison":"70"
   },
   "BW":{
      "identifier":31,
      "name":"Botswana",
      "iso2":"BW",
      "iso3":"BWA",
      "ison":"72"
   },
   "BV":{
      "identifier":32,
      "name":"Bouvet Island",
      "iso2":"BV",
      "iso3":"BVT",
      "ison":"74"
   },
   "BR":{
      "identifier":33,
      "name":"Brazil",
      "iso2":"BR",
      "iso3":"BRA",
      "ison":"76"
   },
   "IO":{
      "identifier":34,
      "name":"British Indian Ocean Territory",
      "iso2":"IO",
      "iso3":"IOT",
      "ison":"86"
   },
   "VG":{
      "identifier":35,
      "name":"British Virgin Islands",
      "iso2":"VG",
      "iso3":"VGB",
      "ison":"92"
   },
   "BN":{
      "identifier":36,
      "name":"Brunei Darussalam",
      "iso2":"BN",
      "iso3":"BRN",
      "ison":"96"
   },
   "BG":{
      "identifier":37,
      "name":"Bulgaria",
      "iso2":"BG",
      "iso3":"BGR",
      "ison":"100"
   },
   "BF":{
      "identifier":38,
      "name":"Burkina Faso",
      "iso2":"BF",
      "iso3":"BFA",
      "ison":"854"
   },
   "MM":{
      "identifier":39,
      "name":"Burma",
      "iso2":"MM",
      "iso3":"MMR",
      "ison":"104"
   },
   "BI":{
      "identifier":40,
      "name":"Burundi",
      "iso2":"BI",
      "iso3":"BDI",
      "ison":"108"
   },
   "KH":{
      "identifier":41,
      "name":"Cambodia",
      "iso2":"KH",
      "iso3":"KHM",
      "ison":"116"
   },
   "CM":{
      "identifier":42,
      "name":"Cameroon",
      "iso2":"CM",
      "iso3":"CMR",
      "ison":"120"
   },
   "CA":{
      "identifier":43,
      "name":"Canada",
      "iso2":"CA",
      "iso3":"CAN",
      "ison":"124"
   },
   "CV":{
      "identifier":44,
      "name":"Cape Verde",
      "iso2":"CV",
      "iso3":"CPV",
      "ison":"132"
   },
   "KY":{
      "identifier":45,
      "name":"Cayman Islands",
      "iso2":"KY",
      "iso3":"CYM",
      "ison":"136"
   },
   "CF":{
      "identifier":46,
      "name":"Central African Republic",
      "iso2":"CF",
      "iso3":"CAF",
      "ison":"140"
   },
   "TD":{
      "identifier":47,
      "name":"Chad",
      "iso2":"TD",
      "iso3":"TCD",
      "ison":"148"
   },
   "CL":{
      "identifier":48,
      "name":"Chile",
      "iso2":"CL",
      "iso3":"CHL",
      "ison":"152"
   },
   "CN":{
      "identifier":49,
      "name":"China",
      "iso2":"CN",
      "iso3":"CHN",
      "ison":"156"
   },
   "CX":{
      "identifier":50,
      "name":"Christmas Island",
      "iso2":"CX",
      "iso3":"CXR",
      "ison":"162"
   },
   "CC":{
      "identifier":52,
      "name":"Cocos (Keeling) Islands",
      "iso2":"CC",
      "iso3":"CCK",
      "ison":"166"
   },
   "CO":{
      "identifier":53,
      "name":"Colombia",
      "iso2":"CO",
      "iso3":"COL",
      "ison":"170"
   },
   "KM":{
      "identifier":54,
      "name":"Comoros",
      "iso2":"KM",
      "iso3":"COM",
      "ison":"174"
   },
   "CD":{
      "identifier":55,
      "name":"Congo, Democratic Republic of the",
      "iso2":"CD",
      "iso3":"COD",
      "ison":"180"
   },
   "CG":{
      "identifier":56,
      "name":"Congo, Republic of the",
      "iso2":"CG",
      "iso3":"COG",
      "ison":"178"
   },
   "CK":{
      "identifier":57,
      "name":"Cook Islands",
      "iso2":"CK",
      "iso3":"COK",
      "ison":"184"
   },
   "CR":{
      "identifier":59,
      "name":"Costa Rica",
      "iso2":"CR",
      "iso3":"CRI",
      "ison":"188"
   },
   "CI":{
      "identifier":60,
      "name":"Cote d'Ivoire",
      "iso2":"CI",
      "iso3":"CIV",
      "ison":"384"
   },
   "HR":{
      "identifier":61,
      "name":"Croatia",
      "iso2":"HR",
      "iso3":"HRV",
      "ison":"191"
   },
   "CU":{
      "identifier":62,
      "name":"Cuba",
      "iso2":"CU",
      "iso3":"CUB",
      "ison":"192"
   },
   "CY":{
      "identifier":63,
      "name":"Cyprus",
      "iso2":"CY",
      "iso3":"CYP",
      "ison":"196"
   },
   "CZ":{
      "identifier":64,
      "name":"Czech Republic",
      "iso2":"CZ",
      "iso3":"CZE",
      "ison":"203"
   },
   "DK":{
      "identifier":65,
      "name":"Denmark",
      "iso2":"DK",
      "iso3":"DNK",
      "ison":"208"
   },
   "DJ":{
      "identifier":66,
      "name":"Djibouti",
      "iso2":"DJ",
      "iso3":"DJI",
      "ison":"262"
   },
   "DM":{
      "identifier":67,
      "name":"Dominica",
      "iso2":"DM",
      "iso3":"DMA",
      "ison":"212"
   },
   "DO":{
      "identifier":68,
      "name":"Dominican Republic",
      "iso2":"DO",
      "iso3":"DOM",
      "ison":"214"
   },
   "TL":{
      "identifier":69,
      "name":"East Timor",
      "iso2":"TL",
      "iso3":"TLS",
      "ison":"626"
   },
   "EC":{
      "identifier":70,
      "name":"Ecuador",
      "iso2":"EC",
      "iso3":"ECU",
      "ison":"218"
   },
   "EG":{
      "identifier":71,
      "name":"Egypt",
      "iso2":"EG",
      "iso3":"EGY",
      "ison":"818"
   },
   "SV":{
      "identifier":72,
      "name":"El Salvador",
      "iso2":"SV",
      "iso3":"SLV",
      "ison":"222"
   },
   "GQ":{
      "identifier":73,
      "name":"Equatorial Guinea",
      "iso2":"GQ",
      "iso3":"GNQ",
      "ison":"226"
   },
   "ER":{
      "identifier":74,
      "name":"Eritrea",
      "iso2":"ER",
      "iso3":"ERI",
      "ison":"232"
   },
   "EE":{
      "identifier":75,
      "name":"Estonia",
      "iso2":"EE",
      "iso3":"EST",
      "ison":"233"
   },
   "ET":{
      "identifier":76,
      "name":"Ethiopia",
      "iso2":"ET",
      "iso3":"ETH",
      "ison":"231"
   },
   "FK":{
      "identifier":78,
      "name":"Falkland Islands (Islas Malvinas)",
      "iso2":"FK",
      "iso3":"FLK",
      "ison":"238"
   },
   "FO":{
      "identifier":79,
      "name":"Faroe Islands",
      "iso2":"FO",
      "iso3":"FRO",
      "ison":"234"
   },
   "FJ":{
      "identifier":80,
      "name":"Fiji",
      "iso2":"FJ",
      "iso3":"FJI",
      "ison":"242"
   },
   "FI":{
      "identifier":81,
      "name":"Finland",
      "iso2":"FI",
      "iso3":"FIN",
      "ison":"246"
   },
   "FR":{
      "identifier":82,
      "name":"France",
      "iso2":"FR",
      "iso3":"FRA",
      "ison":"250"
   },
   "GF":{
      "identifier":84,
      "name":"French Guiana",
      "iso2":"GF",
      "iso3":"GUF",
      "ison":"254"
   },
   "PF":{
      "identifier":85,
      "name":"French Polynesia",
      "iso2":"PF",
      "iso3":"PYF",
      "ison":"258"
   },
   "TF":{
      "identifier":86,
      "name":"French Southern and Antarctic Lands",
      "iso2":"TF",
      "iso3":"ATF",
      "ison":"260"
   },
   "GA":{
      "identifier":87,
      "name":"Gabon",
      "iso2":"GA",
      "iso3":"GAB",
      "ison":"266"
   },
   "GM":{
      "identifier":88,
      "name":"The Gambia",
      "iso2":"GM",
      "iso3":"GMB",
      "ison":"270"
   },
   "GE":{
      "identifier":90,
      "name":"Georgia",
      "iso2":"GE",
      "iso3":"GEO",
      "ison":"268"
   },
   "DE":{
      "identifier":91,
      "name":"Germany",
      "iso2":"DE",
      "iso3":"DEU",
      "ison":"276"
   },
   "GH":{
      "identifier":92,
      "name":"Ghana",
      "iso2":"GH",
      "iso3":"GHA",
      "ison":"288"
   },
   "GI":{
      "identifier":93,
      "name":"Gibraltar",
      "iso2":"GI",
      "iso3":"GIB",
      "ison":"292"
   },
   "GR":{
      "identifier":95,
      "name":"Greece",
      "iso2":"GR",
      "iso3":"GRC",
      "ison":"300"
   },
   "GL":{
      "identifier":96,
      "name":"Greenland",
      "iso2":"GL",
      "iso3":"GRL",
      "ison":"304"
   },
   "GD":{
      "identifier":97,
      "name":"Grenada",
      "iso2":"GD",
      "iso3":"GRD",
      "ison":"308"
   },
   "GP":{
      "identifier":98,
      "name":"Guadeloupe",
      "iso2":"GP",
      "iso3":"GLP",
      "ison":"312"
   },
   "GU":{
      "identifier":99,
      "name":"Guam",
      "iso2":"GU",
      "iso3":"GUM",
      "ison":"316"
   },
   "GT":{
      "identifier":100,
      "name":"Guatemala",
      "iso2":"GT",
      "iso3":"GTM",
      "ison":"320"
   },
   "GN":{
      "identifier":102,
      "name":"Guinea",
      "iso2":"GN",
      "iso3":"GIN",
      "ison":"324"
   },
   "GW":{
      "identifier":103,
      "name":"Guinea-Bissau",
      "iso2":"GW",
      "iso3":"GNB",
      "ison":"624"
   },
   "GY":{
      "identifier":104,
      "name":"Guyana",
      "iso2":"GY",
      "iso3":"GUY",
      "ison":"328"
   },
   "HT":{
      "identifier":105,
      "name":"Haiti",
      "iso2":"HT",
      "iso3":"HTI",
      "ison":"332"
   },
   "HM":{
      "identifier":106,
      "name":"Heard Island and McDonald Islands",
      "iso2":"HM",
      "iso3":"HMD",
      "ison":"334"
   },
   "VA":{
      "identifier":107,
      "name":"Holy See (Vatican City)",
      "iso2":"VA",
      "iso3":"VAT",
      "ison":"336"
   },
   "HN":{
      "identifier":108,
      "name":"Honduras",
      "iso2":"HN",
      "iso3":"HND",
      "ison":"340"
   },
   "HK":{
      "identifier":109,
      "name":"Hong Kong (SAR)",
      "iso2":"HK",
      "iso3":"HKG",
      "ison":"344"
   },
   "HU":{
      "identifier":111,
      "name":"Hungary",
      "iso2":"HU",
      "iso3":"HUN",
      "ison":"348"
   },
   "IS":{
      "identifier":112,
      "name":"Iceland",
      "iso2":"IS",
      "iso3":"ISL",
      "ison":"352"
   },
   "IN":{
      "identifier":113,
      "name":"India",
      "iso2":"IN",
      "iso3":"IND",
      "ison":"356"
   },
   "ID":{
      "identifier":114,
      "name":"Indonesia",
      "iso2":"ID",
      "iso3":"IDN",
      "ison":"360"
   },
   "IR":{
      "identifier":115,
      "name":"Iran",
      "iso2":"IR",
      "iso3":"IRN",
      "ison":"364"
   },
   "IQ":{
      "identifier":116,
      "name":"Iraq",
      "iso2":"IQ",
      "iso3":"IRQ",
      "ison":"368"
   },
   "IE":{
      "identifier":117,
      "name":"Ireland",
      "iso2":"IE",
      "iso3":"IRL",
      "ison":"372"
   },
   "IL":{
      "identifier":118,
      "name":"Israel",
      "iso2":"IL",
      "iso3":"ISR",
      "ison":"376"
   },
   "IT":{
      "identifier":119,
      "name":"Italy",
      "iso2":"IT",
      "iso3":"ITA",
      "ison":"380"
   },
   "JM":{
      "identifier":120,
      "name":"Jamaica",
      "iso2":"JM",
      "iso3":"JAM",
      "ison":"388"
   },
   "JP":{
      "identifier":122,
      "name":"Japan",
      "iso2":"JP",
      "iso3":"JPN",
      "ison":"392"
   },
   "JO":{
      "identifier":126,
      "name":"Jordan",
      "iso2":"JO",
      "iso3":"JOR",
      "ison":"400"
   },
   "KZ":{
      "identifier":128,
      "name":"Kazakhstan",
      "iso2":"KZ",
      "iso3":"KAZ",
      "ison":"398"
   },
   "KE":{
      "identifier":129,
      "name":"Kenya",
      "iso2":"KE",
      "iso3":"KEN",
      "ison":"404"
   },
   "KI":{
      "identifier":131,
      "name":"Kiribati",
      "iso2":"KI",
      "iso3":"KIR",
      "ison":"296"
   },
   "KP":{
      "identifier":132,
      "name":"Korea, North",
      "iso2":"KP",
      "iso3":"PRK",
      "ison":"408"
   },
   "KR":{
      "identifier":133,
      "name":"Korea, South",
      "iso2":"KR",
      "iso3":"KOR",
      "ison":"410"
   },
   "KW":{
      "identifier":134,
      "name":"Kuwait",
      "iso2":"KW",
      "iso3":"KWT",
      "ison":"414"
   },
   "KG":{
      "identifier":135,
      "name":"Kyrgyzstan",
      "iso2":"KG",
      "iso3":"KGZ",
      "ison":"417"
   },
   "LA":{
      "identifier":136,
      "name":"Laos",
      "iso2":"LA",
      "iso3":"LAO",
      "ison":"418"
   },
   "LV":{
      "identifier":137,
      "name":"Latvia",
      "iso2":"LV",
      "iso3":"LVA",
      "ison":"428"
   },
   "LB":{
      "identifier":138,
      "name":"Lebanon",
      "iso2":"LB",
      "iso3":"LBN",
      "ison":"422"
   },
   "LS":{
      "identifier":139,
      "name":"Lesotho",
      "iso2":"LS",
      "iso3":"LSO",
      "ison":"426"
   },
   "LR":{
      "identifier":140,
      "name":"Liberia",
      "iso2":"LR",
      "iso3":"LBR",
      "ison":"430"
   },
   "LY":{
      "identifier":141,
      "name":"Libya",
      "iso2":"LY",
      "iso3":"LBY",
      "ison":"434"
   },
   "LI":{
      "identifier":142,
      "name":"Liechtenstein",
      "iso2":"LI",
      "iso3":"LIE",
      "ison":"438"
   },
   "LT":{
      "identifier":143,
      "name":"Lithuania",
      "iso2":"LT",
      "iso3":"LTU",
      "ison":"440"
   },
   "LU":{
      "identifier":144,
      "name":"Luxembourg",
      "iso2":"LU",
      "iso3":"LUX",
      "ison":"442"
   },
   "MO":{
      "identifier":145,
      "name":"Macao",
      "iso2":"MO",
      "iso3":"MAC",
      "ison":"446"
   },
   "MK":{
      "identifier":146,
      "name":"Macedonia, The Former Yugoslav Republic of",
      "iso2":"MK",
      "iso3":"MKD",
      "ison":"807"
   },
   "MG":{
      "identifier":147,
      "name":"Madagascar",
      "iso2":"MG",
      "iso3":"MDG",
      "ison":"450"
   },
   "MW":{
      "identifier":148,
      "name":"Malawi",
      "iso2":"MW",
      "iso3":"MWI",
      "ison":"454"
   },
   "MY":{
      "identifier":149,
      "name":"Malaysia",
      "iso2":"MY",
      "iso3":"MYS",
      "ison":"458"
   },
   "MV":{
      "identifier":150,
      "name":"Maldives",
      "iso2":"MV",
      "iso3":"MDV",
      "ison":"462"
   },
   "ML":{
      "identifier":151,
      "name":"Mali",
      "iso2":"ML",
      "iso3":"MLI",
      "ison":"466"
   },
   "MT":{
      "identifier":152,
      "name":"Malta",
      "iso2":"MT",
      "iso3":"MLT",
      "ison":"470"
   },
   "MH":{
      "identifier":154,
      "name":"Marshall Islands",
      "iso2":"MH",
      "iso3":"MHL",
      "ison":"584"
   },
   "MQ":{
      "identifier":155,
      "name":"Martinique",
      "iso2":"MQ",
      "iso3":"MTQ",
      "ison":"474"
   },
   "MR":{
      "identifier":156,
      "name":"Mauritania",
      "iso2":"MR",
      "iso3":"MRT",
      "ison":"478"
   },
   "MU":{
      "identifier":157,
      "name":"Mauritius",
      "iso2":"MU",
      "iso3":"MUS",
      "ison":"480"
   },
   "YT":{
      "identifier":158,
      "name":"Mayotte",
      "iso2":"YT",
      "iso3":"MYT",
      "ison":"175"
   },
   "MX":{
      "identifier":159,
      "name":"Mexico",
      "iso2":"MX",
      "iso3":"MEX",
      "ison":"484"
   },
   "FM":{
      "identifier":160,
      "name":"Micronesia, Federated States of",
      "iso2":"FM",
      "iso3":"FSM",
      "ison":"583"
   },
   "MD":{
      "identifier":163,
      "name":"Moldova",
      "iso2":"MD",
      "iso3":"MDA",
      "ison":"498"
   },
   "MC":{
      "identifier":164,
      "name":"Monaco",
      "iso2":"MC",
      "iso3":"MCO",
      "ison":"492"
   },
   "MN":{
      "identifier":165,
      "name":"Mongolia",
      "iso2":"MN",
      "iso3":"MNG",
      "ison":"496"
   },
   "MS":{
      "identifier":167,
      "name":"Montserrat",
      "iso2":"MS",
      "iso3":"MSR",
      "ison":"500"
   },
   "MA":{
      "identifier":168,
      "name":"Morocco",
      "iso2":"MA",
      "iso3":"MAR",
      "ison":"504"
   },
   "MZ":{
      "identifier":169,
      "name":"Mozambique",
      "iso2":"MZ",
      "iso3":"MOZ",
      "ison":"508"
   },
   "NA":{
      "identifier":171,
      "name":"Namibia",
      "iso2":"NA",
      "iso3":"NAM",
      "ison":"516"
   },
   "NR":{
      "identifier":172,
      "name":"Nauru",
      "iso2":"NR",
      "iso3":"NRU",
      "ison":"520"
   },
   "NP":{
      "identifier":174,
      "name":"Nepal",
      "iso2":"NP",
      "iso3":"NPL",
      "ison":"524"
   },
   "NL":{
      "identifier":175,
      "name":"Netherlands",
      "iso2":"NL",
      "iso3":"NLD",
      "ison":"528"
   },
   "AN":{
      "identifier":176,
      "name":"Netherlands Antilles",
      "iso2":"AN",
      "iso3":"ANT",
      "ison":"530"
   },
   "NC":{
      "identifier":177,
      "name":"New Caledonia",
      "iso2":"NC",
      "iso3":"NCL",
      "ison":"540"
   },
   "NZ":{
      "identifier":178,
      "name":"New Zealand",
      "iso2":"NZ",
      "iso3":"NZL",
      "ison":"554"
   },
   "NI":{
      "identifier":179,
      "name":"Nicaragua",
      "iso2":"NI",
      "iso3":"NIC",
      "ison":"558"
   },
   "NE":{
      "identifier":180,
      "name":"Niger",
      "iso2":"NE",
      "iso3":"NER",
      "ison":"562"
   },
   "NG":{
      "identifier":181,
      "name":"Nigeria",
      "iso2":"NG",
      "iso3":"NGA",
      "ison":"566"
   },
   "NU":{
      "identifier":182,
      "name":"Niue",
      "iso2":"NU",
      "iso3":"NIU",
      "ison":"570"
   },
   "NF":{
      "identifier":183,
      "name":"Norfolk Island",
      "iso2":"NF",
      "iso3":"NFK",
      "ison":"574"
   },
   "MP":{
      "identifier":184,
      "name":"Northern Mariana Islands",
      "iso2":"MP",
      "iso3":"MNP",
      "ison":"580"
   },
   "NO":{
      "identifier":185,
      "name":"Norway",
      "iso2":"NO",
      "iso3":"NOR",
      "ison":"578"
   },
   "OM":{
      "identifier":186,
      "name":"Oman",
      "iso2":"OM",
      "iso3":"OMN",
      "ison":"512"
   },
   "PK":{
      "identifier":187,
      "name":"Pakistan",
      "iso2":"PK",
      "iso3":"PAK",
      "ison":"586"
   },
   "PW":{
      "identifier":188,
      "name":"Palau",
      "iso2":"PW",
      "iso3":"PLW",
      "ison":"585"
   },
   "PA":{
      "identifier":190,
      "name":"Panama",
      "iso2":"PA",
      "iso3":"PAN",
      "ison":"591"
   },
   "PG":{
      "identifier":191,
      "name":"Papua New Guinea",
      "iso2":"PG",
      "iso3":"PNG",
      "ison":"598"
   },
   "PY":{
      "identifier":193,
      "name":"Paraguay",
      "iso2":"PY",
      "iso3":"PRY",
      "ison":"600"
   },
   "PE":{
      "identifier":194,
      "name":"Peru",
      "iso2":"PE",
      "iso3":"PER",
      "ison":"604"
   },
   "PH":{
      "identifier":195,
      "name":"Philippines",
      "iso2":"PH",
      "iso3":"PHL",
      "ison":"608"
   },
   "PN":{
      "identifier":196,
      "name":"Pitcairn Islands",
      "iso2":"PN",
      "iso3":"PCN",
      "ison":"612"
   },
   "PL":{
      "identifier":197,
      "name":"Poland",
      "iso2":"PL",
      "iso3":"POL",
      "ison":"616"
   },
   "PT":{
      "identifier":198,
      "name":"Portugal",
      "iso2":"PT",
      "iso3":"PRT",
      "ison":"620"
   },
   "PR":{
      "identifier":199,
      "name":"Puerto Rico",
      "iso2":"PR",
      "iso3":"PRI",
      "ison":"630"
   },
   "QA":{
      "identifier":200,
      "name":"Qatar",
      "iso2":"QA",
      "iso3":"QAT",
      "ison":"634"
   },
   "RE":{
      "identifier":201,
      "name":"R?union",
      "iso2":"RE",
      "iso3":"REU",
      "ison":"638"
   },
   "RO":{
      "identifier":202,
      "name":"Romania",
      "iso2":"RO",
      "iso3":"ROU",
      "ison":"642"
   },
   "RU":{
      "identifier":203,
      "name":"Russia",
      "iso2":"RU",
      "iso3":"RUS",
      "ison":"643"
   },
   "RW":{
      "identifier":204,
      "name":"Rwanda",
      "iso2":"RW",
      "iso3":"RWA",
      "ison":"646"
   },
   "SH":{
      "identifier":205,
      "name":"Saint Helena",
      "iso2":"SH",
      "iso3":"SHN",
      "ison":"654"
   },
   "KN":{
      "identifier":206,
      "name":"Saint Kitts and Nevis",
      "iso2":"KN",
      "iso3":"KNA",
      "ison":"659"
   },
   "LC":{
      "identifier":207,
      "name":"Saint Lucia",
      "iso2":"LC",
      "iso3":"LCA",
      "ison":"662"
   },
   "PM":{
      "identifier":208,
      "name":"Saint Pierre and Miquelon",
      "iso2":"PM",
      "iso3":"SPM",
      "ison":"666"
   },
   "VC":{
      "identifier":209,
      "name":"Saint Vincent and the Grenadines",
      "iso2":"VC",
      "iso3":"VCT",
      "ison":"670"
   },
   "WS":{
      "identifier":210,
      "name":"Samoa",
      "iso2":"WS",
      "iso3":"WSM",
      "ison":"882"
   },
   "SM":{
      "identifier":211,
      "name":"San Marino",
      "iso2":"SM",
      "iso3":"SMR",
      "ison":"674"
   },
   "ST":{
      "identifier":212,
      "name":"S?o Tom? and Pr?ncipe",
      "iso2":"ST",
      "iso3":"STP",
      "ison":"678"
   },
   "SA":{
      "identifier":213,
      "name":"Saudi Arabia",
      "iso2":"SA",
      "iso3":"SAU",
      "ison":"682"
   },
   "SN":{
      "identifier":214,
      "name":"Senegal",
      "iso2":"SN",
      "iso3":"SEN",
      "ison":"686"
   },
   "SC":{
      "identifier":217,
      "name":"Seychelles",
      "iso2":"SC",
      "iso3":"SYC",
      "ison":"690"
   },
   "SL":{
      "identifier":218,
      "name":"Sierra Leone",
      "iso2":"SL",
      "iso3":"SLE",
      "ison":"694"
   },
   "SG":{
      "identifier":219,
      "name":"Singapore",
      "iso2":"SG",
      "iso3":"SGP",
      "ison":"702"
   },
   "SK":{
      "identifier":220,
      "name":"Slovakia",
      "iso2":"SK",
      "iso3":"SVK",
      "ison":"703"
   },
   "SI":{
      "identifier":221,
      "name":"Slovenia",
      "iso2":"SI",
      "iso3":"SVN",
      "ison":"705"
   },
   "SB":{
      "identifier":222,
      "name":"Solomon Islands",
      "iso2":"SB",
      "iso3":"SLB",
      "ison":"90"
   },
   "SO":{
      "identifier":223,
      "name":"Somalia",
      "iso2":"SO",
      "iso3":"SOM",
      "ison":"706"
   },
   "ZA":{
      "identifier":224,
      "name":"South Africa",
      "iso2":"ZA",
      "iso3":"ZAF",
      "ison":"710"
   },
   "GS":{
      "identifier":225,
      "name":"South Georgia and the South Sandwich Islands",
      "iso2":"GS",
      "iso3":"SGS",
      "ison":"239"
   },
   "ES":{
      "identifier":226,
      "name":"Spain",
      "iso2":"ES",
      "iso3":"ESP",
      "ison":"724"
   },
   "LK":{
      "identifier":228,
      "name":"Sri Lanka",
      "iso2":"LK",
      "iso3":"LKA",
      "ison":"144"
   },
   "SD":{
      "identifier":229,
      "name":"Sudan",
      "iso2":"SD",
      "iso3":"SDN",
      "ison":"736"
   },
   "SR":{
      "identifier":230,
      "name":"Suriname",
      "iso2":"SR",
      "iso3":"SUR",
      "ison":"740"
   },
   "SJ":{
      "identifier":231,
      "name":"Svalbard",
      "iso2":"SJ",
      "iso3":"SJM",
      "ison":"744"
   },
   "SZ":{
      "identifier":232,
      "name":"Swaziland",
      "iso2":"SZ",
      "iso3":"SWZ",
      "ison":"748"
   },
   "SE":{
      "identifier":233,
      "name":"Sweden",
      "iso2":"SE",
      "iso3":"SWE",
      "ison":"752"
   },
   "CH":{
      "identifier":234,
      "name":"Switzerland",
      "iso2":"CH",
      "iso3":"CHE",
      "ison":"756"
   },
   "SY":{
      "identifier":235,
      "name":"Syria",
      "iso2":"SY",
      "iso3":"SYR",
      "ison":"760"
   },
   "TW":{
      "identifier":236,
      "name":"Taiwan",
      "iso2":"TW",
      "iso3":"TWN",
      "ison":"158"
   },
   "TJ":{
      "identifier":237,
      "name":"Tajikistan",
      "iso2":"TJ",
      "iso3":"TJK",
      "ison":"762"
   },
   "TZ":{
      "identifier":238,
      "name":"Tanzania",
      "iso2":"TZ",
      "iso3":"TZA",
      "ison":"834"
   },
   "TH":{
      "identifier":239,
      "name":"Thailand",
      "iso2":"TH",
      "iso3":"THA",
      "ison":"764"
   },
   "TG":{
      "identifier":240,
      "name":"Togo",
      "iso2":"TG",
      "iso3":"TGO",
      "ison":"768"
   },
   "TK":{
      "identifier":241,
      "name":"Tokelau",
      "iso2":"TK",
      "iso3":"TKL",
      "ison":"772"
   },
   "TO":{
      "identifier":242,
      "name":"Tonga",
      "iso2":"TO",
      "iso3":"TON",
      "ison":"776"
   },
   "TT":{
      "identifier":243,
      "name":"Trinidad and Tobago",
      "iso2":"TT",
      "iso3":"TTO",
      "ison":"780"
   },
   "TN":{
      "identifier":245,
      "name":"Tunisia",
      "iso2":"TN",
      "iso3":"TUN",
      "ison":"788"
   },
   "TR":{
      "identifier":246,
      "name":"Turkey",
      "iso2":"TR",
      "iso3":"TUR",
      "ison":"792"
   },
   "TM":{
      "identifier":247,
      "name":"Turkmenistan",
      "iso2":"TM",
      "iso3":"TKM",
      "ison":"795"
   },
   "TC":{
      "identifier":248,
      "name":"Turks and Caicos Islands",
      "iso2":"TC",
      "iso3":"TCA",
      "ison":"796"
   },
   "TV":{
      "identifier":249,
      "name":"Tuvalu",
      "iso2":"TV",
      "iso3":"TUV",
      "ison":"798"
   },
   "UG":{
      "identifier":250,
      "name":"Uganda",
      "iso2":"UG",
      "iso3":"UGA",
      "ison":"800"
   },
   "UA":{
      "identifier":251,
      "name":"Ukraine",
      "iso2":"UA",
      "iso3":"UKR",
      "ison":"804"
   },
   "AE":{
      "identifier":252,
      "name":"United Arab Emirates",
      "iso2":"AE",
      "iso3":"ARE",
      "ison":"784"
   },
   "GB":{
      "identifier":253,
      "name":"United Kingdom",
      "iso2":"GB",
      "iso3":"GBR",
      "ison":"826"
   },
   "US":{
      "identifier":254,
      "name":"United States",
      "iso2":"US",
      "iso3":"USA",
      "ison":"840"
   },
   "UM":{
      "identifier":255,
      "name":"United States Minor Outlying Islands",
      "iso2":"UM",
      "iso3":"UMI",
      "ison":"581"
   },
   "UY":{
      "identifier":256,
      "name":"Uruguay",
      "iso2":"UY",
      "iso3":"URY",
      "ison":"858"
   },
   "UZ":{
      "identifier":257,
      "name":"Uzbekistan",
      "iso2":"UZ",
      "iso3":"UZB",
      "ison":"860"
   },
   "VU":{
      "identifier":258,
      "name":"Vanuatu",
      "iso2":"VU",
      "iso3":"VUT",
      "ison":"548"
   },
   "VE":{
      "identifier":259,
      "name":"Venezuela",
      "iso2":"VE",
      "iso3":"VEN",
      "ison":"862"
   },
   "VN":{
      "identifier":260,
      "name":"Vietnam",
      "iso2":"VN",
      "iso3":"VNM",
      "ison":"704"
   },
   "VI":{
      "identifier":261,
      "name":"Virgin Islands",
      "iso2":"VI",
      "iso3":"VIR",
      "ison":"850"
   },
   "WF":{
      "identifier":265,
      "name":"Wallis and Futuna",
      "iso2":"WF",
      "iso3":"WLF",
      "ison":"876"
   },
   "EH":{
      "identifier":267,
      "name":"Western Sahara",
      "iso2":"EH",
      "iso3":"ESH",
      "ison":"732"
   },
   "YE":{
      "identifier":270,
      "name":"Yemen",
      "iso2":"YE",
      "iso3":"YEM",
      "ison":"887"
   },
   "YU":{
      "identifier":271,
      "name":"Yugoslavia",
      "iso2":"YU",
      "iso3":"YUG",
      "ison":"891"
   },
   "ZM":{
      "identifier":273,
      "name":"Zambia",
      "iso2":"ZM",
      "iso3":"ZWB",
      "ison":"894"
   },
   "ZW":{
      "identifier":274,
      "name":"Zimbabwe",
      "iso2":"ZW",
      "iso3":"ZWE",
      "ison":"716"
   },
   "PS":{
      "identifier":275,
      "name":"Palestinian Territory, Occupied",
      "iso2":"PS",
      "iso3":"PSE",
      "ison":"275"
   }
}