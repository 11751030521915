//
// Generated class BookingModifier
//

/// <reference path="BookingModifier_ManagedObject.ts" />

class BookingModifier extends BookingModifier_ManagedObject
{

}
