//
// Generated class PromotionalCode
//

/// <reference path="PromotionalCode_ManagedObject.ts" />

class PromotionalCode extends PromotionalCode_ManagedObject
{

}
