//
// Generated class FilterTicketGroupLine
//

/// <reference path="FilterTicketGroupLine_ManagedObject.ts" />

class FilterTicketGroupLine extends FilterTicketGroupLine_ManagedObject
{
    identifier    = null;
    ticket        = null;
    id_product    = null;
    productname   = null;
    id_app         = null;
    bulkmeasureunit = null;
    bulkquantity = null;
    origindocumentid = null;
   // id_tax      = null;
    tax_name      = null;
    tax_quantity  = null;
    quantity      = null;
    unitprice     = null;
    orderindex    = null;


    category_name  = null;
    format_name    = null;
    menu_name      = null;
    product_name   = null;
    product_price  = null;
    rate           = null;
    tags           = null;
    totalpricebase = null;
    totaltaxes     = null;
    totalprice     = null;
    totaldiscount  = null;
    totalinvited   = null;
    totaldeleted   = null;
    parent         = null;



    //Used only for manager purpose
    
    lines:MIOSet = null;

    setObject(object, order, moc)
    {
        this.ticket = object['ticket'];
        this.identifier  = object['id'];
         
        this.tax_name     = object['tax'];
        this.tax_quantity     = object['tax'];
        this.category_name = object['categoryname'];
        this.format_name    = object['formatname'];
        this.menu_name      = object['menuname'];
        this.product_name   = object['productname'];
        this.product_price  = object['productprice'];
        this.id_product     = object['productid'];
        this.rate           = object['rate'];
        this.tags           = object['tags'];
        this.totalpricebase = object['totalpricebase'];
        this.totaltaxes     = object['totaltaxes'];
        this.totalprice     = object['totalprice'];
        this.totaldiscount  = object['totaldiscount'];
        this.totalinvited   = object['totalinvited'];
        this.totaldeleted   = object['totaldeleted'];
        this.parent         = object['parent'];
        
        this.orderindex  = object['orderindex'] || order;

        let lines = object['archivedTicketLines'] || [];
        this.quantity    = object['quantity'] ? object['quantity'] : lines.length;
        this.lines = MIOSet.set();

        if(lines){
            var instance = this;
            
            lines.forEach(function (line, index) {
                var p = MIOEntityDescription.insertNewObjectForEntityForName("FilterTicketLine", moc) as FilterTicketLine;
                line['group'] = instance;
                p.setObject(line, index, moc);
                instance.lines.addObject(p);
            }, instance);
        }
    }
}
