//
// Generated class ProductSource
//

/// <reference path="ProductSource_ManagedObject.ts" />

class ProductSource extends ProductSource_ManagedObject
{
   
}
