
/**
 * Created by miguel on 21/04/17.
 */


class WarehouseCell extends UITableViewCell
{
    nameLabel:MUILabel = null;
    _separator = null;

    awakeFromHTML()
    {
        this.nameLabel = MUIOutlet(this, "wv_tv_cell_lbl", "MUILabel");
  //      this._separator = MUIOutlet(this, "wv_tv_separator1", "MUIView");
    }

    set item(i)
    {
        this.nameLabel.text = i.name;
    }

}