

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ArchivedTicketLineModifier_ManagedObject

class ArchivedTicketLineModifier_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: baseModifierString
    set baseModifierString(value:string) {
        this.setValueForKey(value, 'baseModifierString');
    }
    get baseModifierString():string {
        return this.valueForKey('baseModifierString');
    }
    set baseModifierStringPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'baseModifierString');
    }
    get baseModifierStringPrimitiveValue():string {
        return this.primitiveValueForKey('baseModifierString');
    }

    // Property: categoryID
    set categoryID(value:string) {
        this.setValueForKey(value, 'categoryID');
    }
    get categoryID():string {
        return this.valueForKey('categoryID');
    }
    set categoryIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'categoryID');
    }
    get categoryIDPrimitiveValue():string {
        return this.primitiveValueForKey('categoryID');
    }

    // Property: categoryName
    set categoryName(value:string) {
        this.setValueForKey(value, 'categoryName');
    }
    get categoryName():string {
        return this.valueForKey('categoryName');
    }
    set categoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'categoryName');
    }
    get categoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('categoryName');
    }

    // Property: modifierID
    set modifierID(value:string) {
        this.setValueForKey(value, 'modifierID');
    }
    get modifierID():string {
        return this.valueForKey('modifierID');
    }
    set modifierIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'modifierID');
    }
    get modifierIDPrimitiveValue():string {
        return this.primitiveValueForKey('modifierID');
    }

    // Property: modifierString
    set modifierString(value:string) {
        this.setValueForKey(value, 'modifierString');
    }
    get modifierString():string {
        return this.valueForKey('modifierString');
    }
    set modifierStringPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'modifierString');
    }
    get modifierStringPrimitiveValue():string {
        return this.primitiveValueForKey('modifierString');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: productCategoryID
    set productCategoryID(value:string) {
        this.setValueForKey(value, 'productCategoryID');
    }
    get productCategoryID():string {
        return this.valueForKey('productCategoryID');
    }
    set productCategoryIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productCategoryID');
    }
    get productCategoryIDPrimitiveValue():string {
        return this.primitiveValueForKey('productCategoryID');
    }

    // Property: productCategoryName
    set productCategoryName(value:string) {
        this.setValueForKey(value, 'productCategoryName');
    }
    get productCategoryName():string {
        return this.valueForKey('productCategoryName');
    }
    set productCategoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productCategoryName');
    }
    get productCategoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('productCategoryName');
    }

    // Property: productContainerMeasureType
    set productContainerMeasureType(value:number) {
        this.setValueForKey(value, 'productContainerMeasureType');
    }
    get productContainerMeasureType():number {
        return this.valueForKey('productContainerMeasureType');
    }
    set productContainerMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productContainerMeasureType');
    }
    get productContainerMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('productContainerMeasureType');
    }

    // Property: productContainerQuantity
    set productContainerQuantity(value:number) {
        this.setValueForKey(value, 'productContainerQuantity');
    }
    get productContainerQuantity():number {
        return this.valueForKey('productContainerQuantity');
    }
    set productContainerQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productContainerQuantity');
    }
    get productContainerQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('productContainerQuantity');
    }

    // Property: productID
    set productID(value:string) {
        this.setValueForKey(value, 'productID');
    }
    get productID():string {
        return this.valueForKey('productID');
    }
    set productIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productID');
    }
    get productIDPrimitiveValue():string {
        return this.primitiveValueForKey('productID');
    }

    // Property: productMeasureType
    set productMeasureType(value:number) {
        this.setValueForKey(value, 'productMeasureType');
    }
    get productMeasureType():number {
        return this.valueForKey('productMeasureType');
    }
    set productMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productMeasureType');
    }
    get productMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('productMeasureType');
    }

    // Property: productName
    set productName(value:string) {
        this.setValueForKey(value, 'productName');
    }
    get productName():string {
        return this.valueForKey('productName');
    }
    set productNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productName');
    }
    get productNamePrimitiveValue():string {
        return this.primitiveValueForKey('productName');
    }

    // Property: quantity
    set quantity(value:number) {
        this.setValueForKey(value, 'quantity');
    }
    get quantity():number {
        return this.valueForKey('quantity');
    }
    set quantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'quantity');
    }
    get quantityPrimitiveValue():number {
        return this.primitiveValueForKey('quantity');
    }

    // Property: subtaxesDict
    set subtaxesDict(value:any) {
        this.setValueForKey(value, 'subtaxesDict');
    }
    get subtaxesDict():any {
        return this.valueForKey('subtaxesDict');
    }
    set subtaxesDictPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'subtaxesDict');
    }
    get subtaxesDictPrimitiveValue():any {
        return this.primitiveValueForKey('subtaxesDict');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: taxID
    set taxID(value:string) {
        this.setValueForKey(value, 'taxID');
    }
    get taxID():string {
        return this.valueForKey('taxID');
    }
    set taxIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'taxID');
    }
    get taxIDPrimitiveValue():string {
        return this.primitiveValueForKey('taxID');
    }

    // Property: taxName
    set taxName(value:string) {
        this.setValueForKey(value, 'taxName');
    }
    get taxName():string {
        return this.valueForKey('taxName');
    }
    set taxNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'taxName');
    }
    get taxNamePrimitiveValue():string {
        return this.primitiveValueForKey('taxName');
    }

    // Property: taxQuantity
    set taxQuantity(value:number) {
        this.setValueForKey(value, 'taxQuantity');
    }
    get taxQuantity():number {
        return this.valueForKey('taxQuantity');
    }
    set taxQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'taxQuantity');
    }
    get taxQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('taxQuantity');
    }

    // Property: totalDiscount
    set totalDiscount(value:number) {
        this.setValueForKey(value, 'totalDiscount');
    }
    get totalDiscount():number {
        return this.valueForKey('totalDiscount');
    }
    set totalDiscountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalDiscount');
    }
    get totalDiscountPrimitiveValue():number {
        return this.primitiveValueForKey('totalDiscount');
    }

    // Property: totalInvited
    set totalInvited(value:number) {
        this.setValueForKey(value, 'totalInvited');
    }
    get totalInvited():number {
        return this.valueForKey('totalInvited');
    }
    set totalInvitedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalInvited');
    }
    get totalInvitedPrimitiveValue():number {
        return this.primitiveValueForKey('totalInvited');
    }

    // Property: totalPrice
    set totalPrice(value:number) {
        this.setValueForKey(value, 'totalPrice');
    }
    get totalPrice():number {
        return this.valueForKey('totalPrice');
    }
    set totalPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalPrice');
    }
    get totalPricePrimitiveValue():number {
        return this.primitiveValueForKey('totalPrice');
    }

    // Property: totalPriceBase
    set totalPriceBase(value:number) {
        this.setValueForKey(value, 'totalPriceBase');
    }
    get totalPriceBase():number {
        return this.valueForKey('totalPriceBase');
    }
    set totalPriceBasePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalPriceBase');
    }
    get totalPriceBasePrimitiveValue():number {
        return this.primitiveValueForKey('totalPriceBase');
    }

    // Property: totalPriceWithoutTip
    set totalPriceWithoutTip(value:number) {
        this.setValueForKey(value, 'totalPriceWithoutTip');
    }
    get totalPriceWithoutTip():number {
        return this.valueForKey('totalPriceWithoutTip');
    }
    set totalPriceWithoutTipPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalPriceWithoutTip');
    }
    get totalPriceWithoutTipPrimitiveValue():number {
        return this.primitiveValueForKey('totalPriceWithoutTip');
    }

    // Property: totalTaxes
    set totalTaxes(value:number) {
        this.setValueForKey(value, 'totalTaxes');
    }
    get totalTaxes():number {
        return this.valueForKey('totalTaxes');
    }
    set totalTaxesPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalTaxes');
    }
    get totalTaxesPrimitiveValue():number {
        return this.primitiveValueForKey('totalTaxes');
    }

    // Property: totalTip
    set totalTip(value:number) {
        this.setValueForKey(value, 'totalTip');
    }
    get totalTip():number {
        return this.valueForKey('totalTip');
    }
    set totalTipPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalTip');
    }
    get totalTipPrimitiveValue():number {
        return this.primitiveValueForKey('totalTip');
    }

    // Property: warehouseID
    set warehouseID(value:string) {
        this.setValueForKey(value, 'warehouseID');
    }
    get warehouseID():string {
        return this.valueForKey('warehouseID');
    }
    set warehouseIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'warehouseID');
    }
    get warehouseIDPrimitiveValue():string {
        return this.primitiveValueForKey('warehouseID');
    }
    // Relationship: archivedTicketLine
    set archivedTicketLine(value:ArchivedTicketLine) {
        this.setValueForKey(value, 'archivedTicketLine');
    }
    get archivedTicketLine():ArchivedTicketLine {
        return this.valueForKey('archivedTicketLine') as ArchivedTicketLine;
    }
}
