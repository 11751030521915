

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class LedgerAccount_ManagedObject

class LedgerAccount_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: prefix
    set prefix(value:string) {
        this.setValueForKey(value, 'prefix');
    }
    get prefix():string {
        return this.valueForKey('prefix');
    }
    set prefixPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'prefix');
    }
    get prefixPrimitiveValue():string {
        return this.primitiveValueForKey('prefix');
    }

    // Property: scope
    set scope(value:number) {
        this.setValueForKey(value, 'scope');
    }
    get scope():number {
        return this.valueForKey('scope');
    }
    set scopePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'scope');
    }
    get scopePrimitiveValue():number {
        return this.primitiveValueForKey('scope');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Relationship: children
    protected _children:MIOManagedObjectSet = null;
    get children():MIOManagedObjectSet {
        return this.valueForKey('children');
    }
    addChildrenObject(value:LedgerAccount) {
        this._addObjectForKey(value, 'children');
    }
    removeChildrenObject(value:LedgerAccount) {
        this._removeObjectForKey(value, 'children');
    }
    // Relationship: parent
    set parent(value:LedgerAccount) {
        this.setValueForKey(value, 'parent');
    }
    get parent():LedgerAccount {
        return this.valueForKey('parent') as LedgerAccount;
    }
}
