
class ReportsPermissionsDataSource extends MIOObject
{
    private tableView:UITableView = null;
    initWithTableView(tableView:UITableView){
        this.tableView = tableView;
    }

    private _user:User = null;
    get user(){return this._user;}
    set user(value:User) {
        this._user = value;
        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableView:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    titleForHeaderInSection(tableView:UITableView, section:number) {
        let ip = MIOIndexPath.indexForRowInSection(0, section);
        let item = this.fetchedResultsController.objectAtIndexPath(ip); 
        if (item.section == null) return "";
        return MIOLocalizeString(item.section.toUpperCase(), item.section);
    }

    cellAtIndexPath(tableView:UITableView, indexPath: MIOIndexPath) {
        let cell = tableView.dequeueReusableCellWithIdentifier("ReportPermissionCell") as ReportsPermissionCell;
        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Report;
        cell.title = item.name;
        
        cell.accessoryType = this._user.hasReportID(item.identifier) ? UITableViewCellAccessoryType.Checkmark : UITableViewCellAccessoryType.None;
        return cell;
    }

    // canSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
    //     let item = this.fetchedResultsController.objectAtIndexPath(indexPath);

    //     let loginEmail = MIOUserDefaults.standardUserDefaults().valueForKey("LastLoginEmail");                        
    //     if (this._user.email == loginEmail && item.bitMask == 47) return false;

    //     return true;
    // }    

    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        let cell = tableView.cellAtIndexPath(indexPath) as ReportsPermissionCell;

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Report;
        
        //if user currently has permission, remove them
        if (this._user.hasReportID(item.identifier)) {
            // Remove
            this._user.removeReportID(item.identifier);
            cell.accessoryType = UITableViewCellAccessoryType.None;
        }
        else {
            //add permission to the user.buinessPermission
            this._user.addReportID(item.identifier);
            cell.accessoryType = UITableViewCellAccessoryType.Checkmark;
        }        
    }

    private _fetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    // get fetchedResultsController(){
    //     if (this._fetchedResultsController != null)
    //         return this._fetchedResultsController;
    
    //     let ad = MUIWebApplication.sharedInstance().delegate;
    
    //     let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('ManagerSection');
    //     fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('moduleIndex', true),
    //                                     MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true)];
    
    //     let predicateValues = [];
    //     //predicateValues.push("bitMask & " + SettingsHelper.sharedInstance().permisisons + " > 0");
                
    //     if (predicateValues.length > 0) fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateValues.join(" AND "));

    //     let fetchedResultsController = new MIOFetchedResultsController();
    //     fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "moduleIndex");
    //     fetchedResultsController.delegate = this;
    
    //     fetchedResultsController.performFetch();
    
    //     this._fetchedResultsController = fetchedResultsController;    
    //     return this._fetchedResultsController;
    // }


    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('Report');
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('section', true),
                                        MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true),
                                        MIOSortDescriptor.sortDescriptorWithKey('name', true)];

        //let predicateFormat = "isVisible == true";
        //if (this.searchString != null) predicateFormat += " AND title CONTAINS '" + this.searchString + "'";
        //fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, 'section');
        fetchedResultsController.delegate = this;        

        fetchedResultsController.performFetch(); 
        
        this._fetchedResultsController = fetchedResultsController;   
        return this._fetchedResultsController;
    }



    
    controllerDidChangeContent(controller:MIOFetchedResultsController){        
        this.tableView.reloadData();
    }
}