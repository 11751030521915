//
// Generated class SubTax
//

/// <reference path="SubTax_ManagedObject.ts" />

class SubTax extends SubTax_ManagedObject
{

}
