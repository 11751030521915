

/// <reference path="ClientAccountViewController.ts" />
/// <reference path="ClientVIPPointsViewController.ts" />
/// <reference path="../../model/PhoneNumber.ts" />
/// <reference path="../../model/Address.ts" />

class ClientDataViewController extends MUIViewController
{
    private nameTextField:MUITextField = null;
    private aliasTextField:MUITextField = null;
    private emailTextField:MUITextField = null;
    private documentTextField:MUITextField = null;
    private address1TextField:MUITextField = null;    
    private cityTextField:MUITextField = null;
    private stateTextField:MUITextField = null;
    private countryDropdown:MUIButton = null;
    private postalCodeTextField:MUITextField = null;
    private phoneTextField:MUITextField = null;
    private mobilePhoneTextField:MUITextField = null;

    // private totalAccountLabel:MUILabel = null;
    // private totalVipPointsLabel:MUILabel = null;

    private privacyCheckButton:MUICheckButton = null;
    private termsCheckButton:MUICheckButton = null;
    private newsCheckButton:MUICheckButton = null;

    private tagView:TagView = null;

    private moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;    
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;

    viewDidLoad(){
        super.viewDidLoad();

        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.nameTextField.setOnChangeText(this, function(textfield, value) {
            this._client.name = value;            
        });

        this.aliasTextField = MUIOutlet(this, "alias-tf", "MUITextField");
        this.aliasTextField.setOnChangeText(this, function(this:ClientDataViewController, textfield:MUITextField, value:string) {
            this._client.aliasName = value.trim().length > 0 ? value.trim() : null;
        });

        this.emailTextField = MUIOutlet(this, "email-tf", "MUITextField");
        this.emailTextField.setOnChangeText(this, function(textfield, value) {
            this._client.email = value;
        });

        this.documentTextField = MUIOutlet(this, "documentid-tf", "MUITextField");
        this.documentTextField.setOnChangeText(this, function(textfield, value) {
            this._client.document = value;
        });

        this.address1TextField = MUIOutlet(this, "address-tf", "MUITextField");
        this.address1TextField.setOnChangeText(this, function(textfield, value) {
            this.address.address1 = value;            
        });

        this.cityTextField = MUIOutlet(this, "city-tf", "MUITextField");
        this.cityTextField.setOnChangeText(this, function(textfield, value) {
            this.address.city = value;
        });

        this.stateTextField = MUIOutlet(this, "state-tf", "MUITextField");
        this.stateTextField.setOnChangeText(this, function(textfield, value) {
            this.address.state = value;
        });

        this.countryDropdown = MUIOutlet(this, "country-dd", "MUIButton");
        this.countryDropdown.setAction(this, function(){
            AppHelper.sharedInstance().showSelectCountryViewControllerFromView(this.countryDropdown, null, this, function(controller:SelectEntityViewController, country:Country){
                this.address.country = country.name;
                this.address.countryISOa2 = country.iso2;
                this.address.countryISOa3 = country.iso3;
                this.countryDropdown.title = country.name;
            });
        });   

        this.postalCodeTextField = MUIOutlet(this, "postal-code-tf", "MUITextField");
        this.postalCodeTextField.setOnChangeText(this, function(textfield, value) {
            this.address.postalCode = value;
        });

        this.phoneTextField = MUIOutlet(this, "phone-tf", "MUITextField");
        this.phoneTextField.setOnChangeText(this, function(textfield, value) {
            this.phone.number = value;
        });

        this.mobilePhoneTextField = MUIOutlet(this, "mobile-tf", "MUITextField");
        this.mobilePhoneTextField.setOnChangeText(this, function(textfield, value) {
            this.mobilePhone.number = value;
        });

        // this.totalAccountLabel = MUIOutlet(this, 'total-account-lbl', 'MUILabel');
        // this.totalVipPointsLabel = MUIOutlet(this, 'total-vip-lbl', 'MUILabel');

        this.privacyCheckButton = MUIOutlet(this, "privacy-cb", "MUICheckButton");
        this.privacyCheckButton.setOnChangeValue(this, function(control, value){
            this._client.privacyPolicyAccepted = value;
        });
        
        this.termsCheckButton = MUIOutlet(this, "terms-cb", "MUICheckButton");
        this.termsCheckButton.setOnChangeValue(this, function(control, value){
            this._client.termsAndConditionsAccepted = value;
        });
        
        this.newsCheckButton = MUIOutlet(this, "news-cb", "MUICheckButton");
        this.newsCheckButton.setOnChangeValue(this, function(control, value){
            this._client.agreeToSendAdvertising = value;
        });
        
        this.tagView = MUIOutlet(this, 'tag-view', 'TagView');
        this.tagView.delegate = this;
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private clientTags  = [];
    private _client:Client = null;
    set client(value:Client){
        this._client = value;
        this.clientTags = value.tags == null ? [] : value.tags.split(',');
        this.updateUI();
    }

    private updateUI(){        
        if (this.viewIsLoaded == false || this._client == null) return;

        this.nameTextField.text = this._client.name;
        this.aliasTextField.text = this._client.aliasName;
        this.emailTextField.text = this._client.email;
        this.documentTextField.text = this._client.document;

        this.updateAddress();
        this.phoneTextField.text = this.phone.number;
        this.mobilePhoneTextField.text = this.mobilePhone.number;
        
        // this.totalAccountLabel.text = this.cf.stringFromNumber(this._client.accountBalance?.amount || 0);
        // this.totalVipPointsLabel.text = this.nf.stringFromNumber(this._client.loyaltyBalance?.amount || 0);
    
        this.privacyCheckButton.on = this._client.privacyPolicyAccepted;
        this.termsCheckButton.on = this._client.termsAndConditionsAccepted;
        this.newsCheckButton.on = this._client.agreeToSendAdvertising;

        let predicate = MIOPredicate.predicateWithFormat('type = ' + TagType.Client);
        DBHelper.queryObjectsWithCompletion("Tag", null, predicate, [], this, function(objects){
            this.reloadTags(objects);
        });                
    }

    private updateAddress(){
        this.address1TextField.text = this.address.address1;
        //this.address2TextField.text = address.address2;
        this.countryDropdown.title = getCountryName( this.address.countryISOa2 ); // countryName;
        this.cityTextField.text = this.address.city;
        this.stateTextField.text = this.address.state;
        this.postalCodeTextField.text = this.address.postalCode;
    }


    get address(){
        if(this._client.address == null){
            this._client.address = MIOEntityDescription.insertNewObjectForEntityForName("Address", this.moc) as Address;
            this._client.address.identifier = MIOUUID.UUID().UUIDString;
        }
        return this._client.address;
    }

    get phone(){
        if(this._client.phone == null){
            this._client.phone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", this.moc) as PhoneNumber;
            this._client.phone.identifier = MIOUUID.UUID().UUIDString;
        }
        return this._client.phone;
    }

    get mobilePhone(){
        if(this._client.mobilePhone == null){
            this._client.mobilePhone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", this.moc) as PhoneNumber;
            this._client.mobilePhone.identifier = MIOUUID.UUID().UUIDString;
        }
        return this._client.mobilePhone;
    }

    private reloadTags(tags){
        this.tagView.removeAll();
        for (let index = 0; index < tags.length; index++){
            let tag = tags[index] as Tag;
            this.tagView.addTag(tag.name);
            if (this.clientTags.containsObject(tag.name)) {
                this.tagView.selectTagAtIndex(index);
            }
        }
    }

    didSelectTagAtIndex(index, name:string){        
        let i = this.clientTags.indexOfObject(name);
        if (i < 0){
            this.clientTags.addObject(name);            
        }
        else {
            this.clientTags.removeObject(name);
        }

        this._client.tags = this.clientTags.join(',');
    }

}
