//
// Generated class RelationBookingDay
//

/// <reference path="RelationBookingDay_ManagedObject.ts" />

class RelationBookingDay extends RelationBookingDay_ManagedObject
{

}
