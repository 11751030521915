
class ProductAttributeSelectCell extends UITableViewCell
{
    private imageView:MUIImageView = null;
    private nameLabel:MUILabel = null;  

    awakeFromHTML(){
        this.imageView = MUIOutlet(this, "attribute-img", "MUIImageView");
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
    }

    private _productAttribute = null;
    set item (item:ProductAttribute){
        if (item == null) return;

        this._productAttribute = item;
        this.nameLabel.text = item.name;
        this.imageView.setImage(item.image?.url);

    }

    set title(title:string) {
        this.nameLabel.text = title;
    }

    
}