//
// Generated class RuleKit
//

/// <reference path="RuleKit_ManagedObject.ts" />

class RuleKit extends RuleKit_ManagedObject
{

}
