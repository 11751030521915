
class SelectMeassureViewController extends MUIViewController
{    
    target = null;
    completion = null;
    showContainers = false;

    private closeButton:MUIButton = null;
    private doneButton:MUIButton = null;
    private addButton:MUIButton = null;

    private searchBar:MUITextField = null;

    private tableView:UITableView = null;

    get preferredContentSize(){
        return new MIOSize(320, 500);
    }
    
    viewDidLoad(){
        super.viewDidLoad();

        this.closeButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.closeButton.setHidden(true);

        this.doneButton = MUIOutlet(this, "done-btn", "MUIButton");
        this.doneButton.setHidden(true);

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setHidden(true);

        this.searchBar = MUIOutlet(this, "search-bar", "MUITextField");
        this.searchBar.setHidden(true);
        
        this.tableView = MUIOutlet(this, "table-view", "UITableView");        
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private unit:MeasureUnitType = null;    
    setType(type:MeasureUnitType){        
        this.unit = type;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;
        this.tableView.reloadData();
    }

    numberOfSections(tableview) {
        let sections = 3;
        if (this.showContainers == true) sections++;
        return sections;
    }
    
    numberOfRowsInSection(tableview, section) {
        if (section == 0 || section == 3) return 1;
        if (section == 2) return 3;
        return 2;
    }

    titleForHeaderInSection?(tableView:UITableView, section){
        let title = null;
        switch (section){
            case 0: 
            title = MIOLocalizeString("UNITS", "UNITS");
            break;

            case 1:
            title = MIOLocalizeString("MASS", "MASS");
            break;

            case 2:
            title = MIOLocalizeString("VOLUME", "VOLUME");
            break;            

            case 3:
            title = MIOLocalizeString("CONTAINERS", "CONTAINERS");
            break;            

        }

        return title;
    }
    
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath) {        
        let cell = tableview.dequeueReusableCellWithIdentifier('SelectCell') as SelectCell;
                
        if (indexPath.section == 0){
            cell.title = MeasureUnits.toString(MeasureUnitType.Unit);
        }
        else if (indexPath.section == 1 && indexPath.row == 0) {
            cell.title = MeasureUnits.toString(MeasureUnitType.MassKilogram);
        }
        else if (indexPath.section == 1 && indexPath.row == 1) {
            cell.title = MeasureUnits.toString(MeasureUnitType.MassGram);
        }
        else if (indexPath.section == 2 && indexPath.row == 0) {
            cell.title = MeasureUnits.toString(MeasureUnitType.VolumeLitre);
        }
        else if (indexPath.section == 2 && indexPath.row == 1) {
            cell.title = MeasureUnits.toString(MeasureUnitType.VolumeCentilitre);
        }
        else if (indexPath.section == 2 && indexPath.row == 2) {
            cell.title = MeasureUnits.toString(MeasureUnitType.VolumeMilliliter);
        }
        else if (indexPath.section == 3){
            cell.title = MeasureUnits.toString(MeasureUnitType.Container);
        }
        
        cell.accessoryType = UITableViewCellAccessoryType.None;

        return cell;
    }
    
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {    

        if (this.target == null || this.completion == null) return;        
        
        let unit = 0;
        if (indexPath.section == 0){
            unit = MeasureUnitType.Unit;
        }
        else if (indexPath.section == 1 && indexPath.row == 0) {
            unit = MeasureUnitType.MassKilogram;
        }
        else if (indexPath.section == 1 && indexPath.row == 1) {
            unit = MeasureUnitType.MassGram;
        }
        else if (indexPath.section == 2 && indexPath.row == 0) {
            unit = MeasureUnitType.VolumeLitre;
        }
        else if (indexPath.section == 2 && indexPath.row == 1) {
            unit = MeasureUnitType.VolumeCentilitre;
        }
        else if (indexPath.section == 2 && indexPath.row == 2) {
            unit = MeasureUnitType.VolumeMilliliter;
        }
        else if (indexPath.section == 3) {
            unit = MeasureUnitType.Container;
        }
        
        this.completion.call(this.target, unit);
        this.tableView.deselectRowAtIndexPath(indexPath, true);
        this.dismissViewController(true);
    }

}