//
// Generated class BankAccountInfo
//

/// <reference path="BankAccountInfo_ManagedObject.ts" />

class BankAccountInfo extends BankAccountInfo_ManagedObject
{

}
