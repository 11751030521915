//
// Generated class InvitationResponsible
//

/// <reference path="InvitationResponsible_ManagedObject.ts" />

class InvitationResponsible extends InvitationResponsible_ManagedObject
{

}
