

// Generated class FileContent_ManagedObject

class FileContent_ManagedObject extends MIOManagedObject
{

    // Property: action
    set action(value:string) {
        this.setValueForKey(value, 'action');
    }
    get action():string {
        return this.valueForKey('action');
    }
    set actionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'action');
    }
    get actionPrimitiveValue():string {
        return this.primitiveValueForKey('action');
    }

    // Property: createdAt
    set createdAt(value:Date) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():Date {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():Date {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: delta
    set delta(value:any) {
        this.setValueForKey(value, 'delta');
    }
    get delta():any {
        return this.valueForKey('delta');
    }
    set deltaPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'delta');
    }
    get deltaPrimitiveValue():any {
        return this.primitiveValueForKey('delta');
    }

    // Property: entityID
    set entityID(value:string) {
        this.setValueForKey(value, 'entityID');
    }
    get entityID():string {
        return this.valueForKey('entityID');
    }
    set entityIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'entityID');
    }
    get entityIDPrimitiveValue():string {
        return this.primitiveValueForKey('entityID');
    }

    // Property: entityType
    set entityType(value:string) {
        this.setValueForKey(value, 'entityType');
    }
    get entityType():string {
        return this.valueForKey('entityType');
    }
    set entityTypePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'entityType');
    }
    get entityTypePrimitiveValue():string {
        return this.primitiveValueForKey('entityType');
    }

    // Property: fileID
    set fileID(value:string) {
        this.setValueForKey(value, 'fileID');
    }
    get fileID():string {
        return this.valueForKey('fileID');
    }
    set fileIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'fileID');
    }
    get fileIDPrimitiveValue():string {
        return this.primitiveValueForKey('fileID');
    }

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }
}
