

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class FavouriteProduct_ManagedObject

class FavouriteProduct_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: formatID
    set formatID(value:string) {
        this.setValueForKey(value, 'formatID');
    }
    get formatID():string {
        return this.valueForKey('formatID');
    }
    set formatIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'formatID');
    }
    get formatIDPrimitiveValue():string {
        return this.primitiveValueForKey('formatID');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: productID
    set productID(value:string) {
        this.setValueForKey(value, 'productID');
    }
    get productID():string {
        return this.valueForKey('productID');
    }
    set productIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productID');
    }
    get productIDPrimitiveValue():string {
        return this.primitiveValueForKey('productID');
    }

    // Relationship: favouriteProductModifiers
    protected _favouriteProductModifiers:MIOManagedObjectSet = null;
    get favouriteProductModifiers():MIOManagedObjectSet {
        return this.valueForKey('favouriteProductModifiers');
    }
    addFavouriteProductModifiersObject(value:FavouriteProductModifier) {
        this._addObjectForKey(value, 'favouriteProductModifiers');
    }
    removeFavouriteProductModifiersObject(value:FavouriteProductModifier) {
        this._removeObjectForKey(value, 'favouriteProductModifiers');
    }
}
