



class ReportResultCell extends MUITableViewCell
{
    nameLabel     = null;
    quantityLabel = null;
    paxLabel      = null;
    taxLabel      = null;
    discountLabel = null;
    invitedLabel  = null;
    baseLabel     = null;
    totalLabel    = null;
    deletedLabel  = null;

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;

    awakeFromHTML()
    {
        this.nameLabel     = MUIOutlet(this, 'rrv_c1_name_lbl', 'MUILabel');
        this.quantityLabel = MUIOutlet(this, 'rrv_c1_quantity_lbl', 'MUILabel');
        this.paxLabel      = MUIOutlet(this, 'rrv_c1_pax_lbl','MUILabel');
        this.discountLabel = MUIOutlet(this, 'rrv_c1_discount_lbl','MUILabel');
        this.baseLabel     = MUIOutlet(this, 'rrv_c1_base_lbl','MUILabel');
        this.taxLabel      = MUIOutlet(this, 'rrv_c1_tax_lbl','MUILabel');
        this.totalLabel    = MUIOutlet(this, 'rrv_c1_total_lbl','MUILabel');
    }

    set item(i)
    {
        this.nameLabel.text     = i[Object.keys(i)[0]];
        this.quantityLabel.text = i.quantity ? this.nf.stringFromNumber(parseFloat(i.quantity)) :'';
        this.paxLabel.text      = i.line_pax ? i.line_pax: '0';
        this.discountLabel.text = i.line_totaldiscount ? this.cf.stringFromNumber(parseFloat(i.line_totaldiscount)) :'';
        this.baseLabel.text     = i.line_totalpricebase ? this.cf.stringFromNumber(parseFloat(i.line_totalpricebase)) :'';
        this.taxLabel.text      = i.line_totaltaxes ? this.cf.stringFromNumber(parseFloat(i.line_totaltaxes)) :'';
        this.totalLabel.text    = i.line_totalprice ? this.cf.stringFromNumber(parseFloat(i.line_totalprice)) :'';
    }
}
