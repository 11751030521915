//
// Generated class EventInvitationRule
//

/// <reference path="EventInvitationRule_ManagedObject.ts" />

class EventInvitationRule extends EventInvitationRule_ManagedObject
{

}
