//
// Generated class Invoice
//

/// <reference path="Invoice_ManagedObject.ts" />

class Invoice extends Invoice_ManagedObject
{
    get sectionDateString(): string {
        if (this.date == null) return null;
        
        let m = (this.date.getMonth() + 1).toString();
        let y = this.date.getFullYear();

        return y + "-" + (m[1]?m:"0" + m[0]);
    }

    get monthString():string {
        if (this.date == null) return null;        
        let m = this.date.getMonth();        
        return MIODateGetStringForMonth(m);
    }
}
