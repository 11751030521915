//
// Generated class DBSyncEntityQueue
//

/// <reference path="DBSyncEntityQueue_ManagedObject.ts" />

class DBSyncEntityQueue extends DBSyncEntityQueue_ManagedObject
{

}
