class QRLocationCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    private qrLabel:MUILabel = null;
    private urlLabel:MUILabel = null;

    awakeFromHTML(): void {
        this.nameLabel = MUIOutlet( this, "name-lbl", "MUILabel" );
        this.qrLabel = MUIOutlet( this, "code-lbl", "MUILabel" );
        this.urlLabel = MUIOutlet( this, "url-lbl", "MUILabel" );
    }

    set item( item:Location  ) {
        this.nameLabel.text = item.name;
        this.qrLabel.text = item.qrCode;
        this.urlLabel.text = item.qrURLString;
    }
}