
/// <reference path="../base/BaseViewController.ts" />
/// <reference path="ProductRatesDetailCell.ts" />

class ProductRatesDetailViewController extends BaseViewController
{
    private settingsButton:MUIButton = null;
    private saveButton:MUIButton = null;
    private nameTextfield:MUITextField = null;
    private rateImageView:MUIImageView = null;
    private startDateButton:MUIButton = null;
    private endDateButton:MUIButton = null;
    private generalRateButton:MUIButton = null;
    private roundingButton:MUIButton = null;
    private segmentedControl:MUISegmentedControl = null;
    private tableView: UITableView = null;

    private df:MIODateFormatter = MUIWebApplication.sharedInstance().delegate.dateFormatter;

    private roundString(value){
        switch(value){
            case RoundType.RoundTypeNone:
                return MIOLocalizeString('NO ROUNDING','NO ROUNDING');
            case RoundType.RoundTypeNormal:
                return MIOLocalizeString('NORMAL','NORMAL');
            case RoundType.RoundTypeUp:
                return MIOLocalizeString('ROUND UP','ROUND UP');
            case RoundType.RoundTypeDown:
                return MIOLocalizeString('ROUND DOWN','ROUND DOWN');
            default:
                return MIOLocalizeString('NO ROUNDING','NO ROUNDING');
        }
    }

    viewDidLoad(){
        super.viewDidLoad();

        this.settingsButton = MUIOutlet(this, 'settings-btn', 'MUIButton');
        this.settingsButton.setAction(this, this.showSettingsPopup); 

        this.saveButton = MUIOutlet(this, 'save-btn', 'MUIButton');
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        })

        this.rateImageView = MUIOutlet(this, 'rate-img','MUIImageView');

        this.nameTextfield = MUIOutlet(this, 'name-tf', 'MUITextField');
        this.nameTextfield.setOnChangeText(this, function(textfield, value){
            this._rate.name = value;
        });

        this.startDateButton = MUIOutlet(this, 'start-date-btn', 'MUIButton');
        this.startDateButton.setAction(this, function(){
            let dp = new MUIDatePickerController();
            dp.init();        
            dp.delegate = this;
            dp.tag = 1;
            dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

            let pc = dp.popoverPresentationController;
            pc.sourceRect = this.startDateButton.frame;
            pc.sourceView = this.startDateButton;

            this.presentViewController(dp, true);
        });

        this.endDateButton = MUIOutlet(this, 'end-date-btn', 'MUIButton');
        this.endDateButton.setAction(this, function(){
            let dp = new MUIDatePickerController();
            dp.init();        
            dp.delegate = this;
            dp.tag = 2;
            dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

            let pc = dp.popoverPresentationController;
            pc.sourceRect = this.endDateButton.frame;
            pc.sourceView = this.endDateButton;

            this.presentViewController(dp, true);
        });

        this.generalRateButton = MUIOutlet(this, "general-rate-btn", "MUIButton");
        this.generalRateButton.setAction(this, function(){
            this.showGeneralRateViewController(this.generalRateButton);
        })

        this.roundingButton = MUIOutlet(this, "rounding-btn", "MUIButton");
        this.roundingButton.setAction(this, function(){
            this.showRoundingPopup();
        })

        this.segmentedControl = MUIOutlet(this, 'segmented-control', 'MUISegmentedControl');
        this.segmentedControl.setAction(this, this.segmentedControlDidChange);
        
        this.tableView = MUIOutlet(this, 'table-view', 'UITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;     
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _rate:Rate = null;
    set item(item:Rate){
        this._rate = item;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._rate == null) return;

        // Watiting for fix: image must be changed to relationship in DataModel
        // if (this._rate.image)
        //     this.rateImageView.setImage(this._rate.image)

        this.nameTextfield.text = this._rate.name;

        if (this._rate.beginDate)
            this.startDateButton.title = this.df.stringFromDate(this._rate.beginDate);

        if (this._rate.endDate)
            this.endDateButton.title = this.df.stringFromDate(this._rate.endDate);

        this.generalRateButton.title = (this._rate.rule)?this._rate.rule:"---";
        this.roundingButton.title = (this._rate.roundType)?this.roundString(this._rate.roundType):MIOLocalizeString("NONE","NONE");

        this.fetchedResultsController = null;
        this.tableView.reloadData();        
    }

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    titleForHeaderInSection(tableView:UITableView, section:number){
        if (this.fetchedResultsController.sections.length < 2) return null;
        
        let ip = MIOIndexPath.indexForRowInSection(0, section);
        let item = this.fetchedResultsController.objectAtIndexPath(ip) as Product; 
        if (item.category == null) return MIOLocalizeString("NO CATEGORY","NO CATEGORY");
        return item.category.name;
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        let cell = null;
        
        switch (this.segmentedControl.selectedSegmentedIndex){
        case 0:
            cell = tableView.dequeueReusableCellWithIdentifier("ProductRatesDetailCell") as ProductRatesDetailCell;
            cell.rate = this._rate
            cell.product = item; 
            break;

        case 1:
            cell = tableView.dequeueReusableCellWithIdentifier("MenuRatesDetailCell") as MenuRatesDetailCell;
            cell.rate = this._rate
            cell.menu = item;
            break;

        case 2:
            cell = tableView.dequeueReusableCellWithIdentifier("ModifierRatesDetailCell") as ModifierRatesDetailCell;
            cell.rate = this._rate
            cell.modifier = item;
            break;
        }
        
        cell.selectionStyle = UITableViewCellSelectionStyle.None;
        return cell;
    }

    // didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
    //     alert ("Clicked on cell");
    //     let selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath);   
    // }

    // editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) { 
    //     return UITableViewCellEditingStyle.Delete;
    // }

    // commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
    //     let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
    //     if (editingStyle == UITableViewCellEditingStyle.Delete) {
    //         DBHelper.deleteObjectFromMainContext(item, true);
    //     }
    // }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
        let fetchRequest:MIOFetchRequest = null;
        let sectionName = null;
        switch(this.segmentedControl.selectedSegmentedIndex){
            case 0:
                fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("Product");
                fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('category.identifier', true),
                                                MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true),
                                                MIOSortDescriptor.sortDescriptorWithKey('name', true)];
                fetchRequest.predicate = MIOPredicate.predicateWithFormat("deletedAt == null && isEnableForSell == true");
                fetchRequest.relationshipKeyPathsForPrefetching = ['category', 'image', 'imageID', 'productRates', 'productFormats'];
                sectionName = 'category.identifier';
                break;

            case 1:
                fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("Menu");
                fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true),
                                                MIOSortDescriptor.sortDescriptorWithKey('name', true)];
                fetchRequest.predicate = MIOPredicate.predicateWithFormat("deletedAt == null");
                fetchRequest.relationshipKeyPathsForPrefetching = ['menuRates', 'tax'];
                sectionName = null;
                break;

            case 2:
                fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("ProductModifier");
                fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true),
                                                MIOSortDescriptor.sortDescriptorWithKey('name', true)];
                fetchRequest.predicate = MIOPredicate.predicateWithFormat("deletedAt == null");
                fetchRequest.relationshipKeyPathsForPrefetching = ['productModifierRates', 'tax'];
                sectionName = null;
                break;
        }

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, sectionName);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    didSelectDate(datePickerController:MUIDatePickerController, date:Date){
        if (datePickerController.tag == 1){
            this._rate.beginDate = date;
            this.startDateButton.title = this.df.stringFromDate(date);
        } else if (datePickerController.tag == 2){
            this._rate.endDate = date;
            this.endDateButton.title = this.df.stringFromDate(date);
        }
    }

    // private addImage(imageID:string, imageURL:string){
    //     this._itemURL.stringValue = imageURL;
    //     this._itemID.stringValue = imageID;
    //     this.logoImageView.setImage(imageURL);
    // }

    private showSettingsPopup() {

        alert("Show massive operation");
        // let avc = new MUIAlertViewController();
        // avc.initWithTitle(MIOLocalizeString('ACTIONS', 'ACTIONS'), MIOLocalizeString('CHOOSE AN ACTION', 'CHOOSE AN ACTION'), MUIAlertViewStyle.Default);
        // avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DELETE MODIFIER CATEGORY', 'DELETE MODIFIER CATEGORY'), MUIAlertActionStyle.Destructive, this, function(){
            
        //     this.removeAllProductModifiers();

        //     if(this._fetchedResultsController.resultObjects.length == 0) {
        //         this.removeProductModifierCategory(this.productModifierCategory);
        //     }

        //     //pop current detail view controller
            
        // }));
                
        // avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL', 'Cancel'), MUIAlertActionStyle.Cancel, null, null));
       
        // this.presentViewController(avc, true);
    }

    private showGeneralRateViewController(button:MUIButton){

        let vc = new ProductRatesRuleViewController("product-rates-rule-view");            
        vc.initWithResource("layout/productrates/ProductRatesRuleView.html");
        vc.delegate = this;
        vc.modalPresentationStyle = MUIModalPresentationStyle.Popover;
        vc.item = this._rate;

        let pc = vc.popoverPresentationController;
        pc.sourceRect = button.frame;
        pc.sourceView = button;

        AppHelper.sharedInstance().showSelectViewControllerFromView(button, vc, this, null);
    }

    private showRoundingPopup() {
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('ROUNDING', 'ROUNDING'), MIOLocalizeString('SELECT A TYPE OF ROUNDING', 'SELECT A TYPE OF ROUNDING'), MUIAlertViewStyle.Default);
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("NONE","NONE"), MUIAlertActionStyle.Default, this, function(){
            this.roundingButton.title = MIOLocalizeString("NONE", "NONE");
            this._rate.roundType = RoundType.RoundTypeNone;
            this.updateUI();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("NORMAL ROUNDING","NORMAL ROUNDING"), MUIAlertActionStyle.Default, this, function(){
            this.roundingButton.title = MIOLocalizeString("NORMAL", "NORMAL");
            this._rate.roundType = RoundType.RoundTypeNormal;
            this.updateUI();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("ROUND UP","ROUND UP"), MUIAlertActionStyle.Default, this, function(){
            this.roundingButton.title = MIOLocalizeString("ROUND UP", "ROUND UP");
            this._rate.roundType = RoundType.RoundTypeUp;
            this.updateUI();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("ROUND DOWN","ROUND DOWN"), MUIAlertActionStyle.Default, this, function(){
            this.roundingButton.title = MIOLocalizeString("ROUND DOWN", "ROUND DOWN");
            this._rate.roundType = RoundType.RoundTypeDown;
            this.updateUI();
        }));
                
        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL', 'Cancel'), MUIAlertActionStyle.Cancel, null, null));
       
        this.presentViewController(avc, true);
    }

    private segmentedControlDidChange(control:MUISegmentedControl) {
        this.fetchedResultsController = null;
        this.tableView.reloadData();     
    }

}