

class WeekdayCell extends MUITableViewCell
{
    nameLabel = null;

    awakeFromHTML()
    {
        this.nameLabel = MUIOutlet(this, 'gldv_cell_lbl', 'MUILabel');
        this.separatorStyle = MUITableViewCellSeparatorStyle.None;
    }

}
