/**
 * Created by miguel on 10/2/17.
 */




class AddNewProductSourceViewController extends MUIViewController
{
    private tableView:MUITableView = null;
    private _fetchedResultsController:MIOFetchedResultsController = null;
    
    private selectedSection = -1;
    private selectedRow = -1;
    private _product:Product = null;

    viewDidLoad()
    {
        super.viewDidLoad();

        this.tableView = MUIOutlet(this, 'anpsv_c1_tableview', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
        this.tableView.reloadData();
    }

    private clear() {
        this.selectedRow = -1;
        this.selectedSection = -1;
        if(this.tableView != null && this._product != null)
        {   
            this.fetchedResultsController = null;
            this.tableView.reloadData();
        }
    }

    set product(value)
    {
        this._product = value;
        this.clear();
    }

    numberOfSections(tableview)
    {
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview, section)
    {
        var sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath)
    {
        var cell:AddNewProductSourceCell = tableview.dequeueReusableCellWithIdentifier("AddNewProductSourceCell");

        var item = this.fetchedResultsController.objectAtIndexPath(indexPath);

        cell.item = item;

        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
        cell.selectionStyle = MUITableViewCellSelectionStyle.None;

        //if(this.selectedSection == indexPath.section && this.selectedRow == indexPath.row)
        if(this._product && this._product.source == item)
            cell.setAccessoryType(MUITableViewCellAccessoryType.Checkmark);    
        else
            cell.setAccessoryType(MUITableViewCellAccessoryType.None);      
        
        return cell;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath)
    {
        var obj:ProductSource = this.fetchedResultsController.objectAtIndexPath(indexPath);
        
        if(this._product != null)
            this._product.source = obj;

        if(this.selectedSection != -1 && this.selectedRow != -1){
            this.tableView.cellAtIndexPath(MIOIndexPath.indexForRowInSection(this.selectedRow, this.selectedSection)).setAccessoryType(MUITableViewCellAccessoryType.None);    
        }
        this.selectedRow = indexPath.row;
        this.selectedSection = indexPath.section;

        this.tableView.cellAtIndexPath(indexPath).setAccessoryType(MUITableViewCellAccessoryType.Checkmark);    

       // MIONotificationCenter.defaultCenter().postNotification("AddNewProductSourceDidChange", obj);
    }

    set fetchedResultsController(value)
    {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController()
    {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
        
        var ad = MUIWebApplication.sharedInstance().delegate;
        
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('name', true)];
    
        var fetchRequest = DBHelper.listFetchRequestWithEntityName('ProductSource', sortDescriptors, null);
        var fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;

        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller)
    {
        this.tableView.reloadData();
    }
}