

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class VenueEvent_ManagedObject

class VenueEvent_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: beginDateTime
    set beginDateTime(value:Date) {
        this.setValueForKey(value, 'beginDateTime');
    }
    get beginDateTime():Date {
        return this.valueForKey('beginDateTime');
    }
    set beginDateTimePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'beginDateTime');
    }
    get beginDateTimePrimitiveValue():Date {
        return this.primitiveValueForKey('beginDateTime');
    }

    // Property: endDateTime
    set endDateTime(value:Date) {
        this.setValueForKey(value, 'endDateTime');
    }
    get endDateTime():Date {
        return this.valueForKey('endDateTime');
    }
    set endDateTimePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'endDateTime');
    }
    get endDateTimePrimitiveValue():Date {
        return this.primitiveValueForKey('endDateTime');
    }

    // Property: isActive
    set isActive(value:boolean) {
        this.setValueForKey(value, 'isActive');
    }
    get isActive():boolean {
        return this.valueForKey('isActive');
    }
    set isActivePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isActive');
    }
    get isActivePrimitiveValue():boolean {
        return this.primitiveValueForKey('isActive');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: presetGroupID
    set presetGroupID(value:string) {
        this.setValueForKey(value, 'presetGroupID');
    }
    get presetGroupID():string {
        return this.valueForKey('presetGroupID');
    }
    set presetGroupIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'presetGroupID');
    }
    get presetGroupIDPrimitiveValue():string {
        return this.primitiveValueForKey('presetGroupID');
    }

    // Property: rateID
    set rateID(value:string) {
        this.setValueForKey(value, 'rateID');
    }
    get rateID():string {
        return this.valueForKey('rateID');
    }
    set rateIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'rateID');
    }
    get rateIDPrimitiveValue():string {
        return this.primitiveValueForKey('rateID');
    }

    // Property: ratesInfo
    set ratesInfo(value:string) {
        this.setValueForKey(value, 'ratesInfo');
    }
    get ratesInfo():string {
        return this.valueForKey('ratesInfo');
    }
    set ratesInfoPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ratesInfo');
    }
    get ratesInfoPrimitiveValue():string {
        return this.primitiveValueForKey('ratesInfo');
    }

    // Relationship: bookings
    protected _bookings:MIOManagedObjectSet = null;
    get bookings():MIOManagedObjectSet {
        return this.valueForKey('bookings');
    }
    addBookingsObject(value:Booking) {
        this._addObjectForKey(value, 'bookings');
    }
    removeBookingsObject(value:Booking) {
        this._removeObjectForKey(value, 'bookings');
    }
    // Relationship: eventInvitationRule
    set eventInvitationRule(value:EventInvitationRule) {
        this.setValueForKey(value, 'eventInvitationRule');
    }
    get eventInvitationRule():EventInvitationRule {
        return this.valueForKey('eventInvitationRule') as EventInvitationRule;
    }

    // Relationship: invitationLines
    protected _invitationLines:MIOManagedObjectSet = null;
    get invitationLines():MIOManagedObjectSet {
        return this.valueForKey('invitationLines');
    }
    addInvitationLinesObject(value:InvitationLine) {
        this._addObjectForKey(value, 'invitationLines');
    }
    removeInvitationLinesObject(value:InvitationLine) {
        this._removeObjectForKey(value, 'invitationLines');
    }
    // Relationship: offer
    set offer(value:Offer) {
        this.setValueForKey(value, 'offer');
    }
    get offer():Offer {
        return this.valueForKey('offer') as Offer;
    }

    // Relationship: tickets
    protected _tickets:MIOManagedObjectSet = null;
    get tickets():MIOManagedObjectSet {
        return this.valueForKey('tickets');
    }
    addTicketsObject(value:EventTicket) {
        this._addObjectForKey(value, 'tickets');
    }
    removeTicketsObject(value:EventTicket) {
        this._removeObjectForKey(value, 'tickets');
    }

    // Relationship: timeRangePresetConfigurations
    protected _timeRangePresetConfigurations:MIOManagedObjectSet = null;
    get timeRangePresetConfigurations():MIOManagedObjectSet {
        return this.valueForKey('timeRangePresetConfigurations');
    }
    addTimeRangePresetConfigurationsObject(value:TimeRangePresetConfiguration) {
        this._addObjectForKey(value, 'timeRangePresetConfigurations');
    }
    removeTimeRangePresetConfigurationsObject(value:TimeRangePresetConfiguration) {
        this._removeObjectForKey(value, 'timeRangePresetConfigurations');
    }
}
