//
// Generated class ProductListItem
//

/// <reference path="ProductListItem_ManagedObject.ts" />

enum ProductListItemType {
    none = -1,
    category = 0,
    product = 1,
    stockCategory = 2
}

class ProductListItem extends ProductListItem_ManagedObject
{

}
