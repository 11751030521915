

/// <reference path="PrinterLink.ts" />

// Generated class WarehousePrinterLink_ManagedObject

class WarehousePrinterLink_ManagedObject extends PrinterLink
{

    // Property: warehouseID
    set warehouseID(value:string) {
        this.setValueForKey(value, 'warehouseID');
    }
    get warehouseID():string {
        return this.valueForKey('warehouseID');
    }
    set warehouseIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'warehouseID');
    }
    get warehouseIDPrimitiveValue():string {
        return this.primitiveValueForKey('warehouseID');
    }
}
