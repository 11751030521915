

/// <reference path="Channel.ts" />

// Generated class TicketChannel_ManagedObject

class TicketChannel_ManagedObject extends Channel
{

    // Property: integratorType
    set integratorType(value:number) {
        this.setValueForKey(value, 'integratorType');
    }
    get integratorType():number {
        return this.valueForKey('integratorType');
    }
    set integratorTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'integratorType');
    }
    get integratorTypePrimitiveValue():number {
        return this.primitiveValueForKey('integratorType');
    }
}
