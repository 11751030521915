

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class StockAnnotation_ManagedObject

class StockAnnotation_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: cheffLineID
    set cheffLineID(value:string) {
        this.setValueForKey(value, 'cheffLineID');
    }
    get cheffLineID():string {
        return this.valueForKey('cheffLineID');
    }
    set cheffLineIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'cheffLineID');
    }
    get cheffLineIDPrimitiveValue():string {
        return this.primitiveValueForKey('cheffLineID');
    }

    // Property: concept
    set concept(value:string) {
        this.setValueForKey(value, 'concept');
    }
    get concept():string {
        return this.valueForKey('concept');
    }
    set conceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'concept');
    }
    get conceptPrimitiveValue():string {
        return this.primitiveValueForKey('concept');
    }

    // Property: cost
    set cost(value:number) {
        this.setValueForKey(value, 'cost');
    }
    get cost():number {
        return this.valueForKey('cost');
    }
    set costPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'cost');
    }
    get costPrimitiveValue():number {
        return this.primitiveValueForKey('cost');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: lossQuantity
    set lossQuantity(value:number) {
        this.setValueForKey(value, 'lossQuantity');
    }
    get lossQuantity():number {
        return this.valueForKey('lossQuantity');
    }
    set lossQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lossQuantity');
    }
    get lossQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('lossQuantity');
    }

    // Property: measureUnit
    set measureUnit(value:number) {
        this.setValueForKey(value, 'measureUnit');
    }
    get measureUnit():number {
        return this.valueForKey('measureUnit');
    }
    set measureUnitPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'measureUnit');
    }
    get measureUnitPrimitiveValue():number {
        return this.primitiveValueForKey('measureUnit');
    }

    // Property: minMeasureQuantity
    set minMeasureQuantity(value:number) {
        this.setValueForKey(value, 'minMeasureQuantity');
    }
    get minMeasureQuantity():number {
        return this.valueForKey('minMeasureQuantity');
    }
    set minMeasureQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minMeasureQuantity');
    }
    get minMeasureQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('minMeasureQuantity');
    }

    // Property: minMeasureType
    set minMeasureType(value:number) {
        this.setValueForKey(value, 'minMeasureType');
    }
    get minMeasureType():number {
        return this.valueForKey('minMeasureType');
    }
    set minMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minMeasureType');
    }
    get minMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('minMeasureType');
    }

    // Property: productcontainerquantity
    set productcontainerquantity(value:number) {
        this.setValueForKey(value, 'productcontainerquantity');
    }
    get productcontainerquantity():number {
        return this.valueForKey('productcontainerquantity');
    }
    set productcontainerquantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productcontainerquantity');
    }
    get productcontainerquantityPrimitiveValue():number {
        return this.primitiveValueForKey('productcontainerquantity');
    }

    // Property: productID
    set productID(value:string) {
        this.setValueForKey(value, 'productID');
    }
    get productID():string {
        return this.valueForKey('productID');
    }
    set productIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productID');
    }
    get productIDPrimitiveValue():string {
        return this.primitiveValueForKey('productID');
    }

    // Property: quantity
    set quantity(value:number) {
        this.setValueForKey(value, 'quantity');
    }
    get quantity():number {
        return this.valueForKey('quantity');
    }
    set quantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'quantity');
    }
    get quantityPrimitiveValue():number {
        return this.primitiveValueForKey('quantity');
    }

    // Property: sourceDocumentID
    set sourceDocumentID(value:string) {
        this.setValueForKey(value, 'sourceDocumentID');
    }
    get sourceDocumentID():string {
        return this.valueForKey('sourceDocumentID');
    }
    set sourceDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceDocumentID');
    }
    get sourceDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('sourceDocumentID');
    }

    // Property: sourceDocumentLineID
    set sourceDocumentLineID(value:string) {
        this.setValueForKey(value, 'sourceDocumentLineID');
    }
    get sourceDocumentLineID():string {
        return this.valueForKey('sourceDocumentLineID');
    }
    set sourceDocumentLineIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceDocumentLineID');
    }
    get sourceDocumentLineIDPrimitiveValue():string {
        return this.primitiveValueForKey('sourceDocumentLineID');
    }

    // Property: subtype
    set subtype(value:number) {
        this.setValueForKey(value, 'subtype');
    }
    get subtype():number {
        return this.valueForKey('subtype');
    }
    set subtypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'subtype');
    }
    get subtypePrimitiveValue():number {
        return this.primitiveValueForKey('subtype');
    }

    // Property: totalQuantity
    set totalQuantity(value:number) {
        this.setValueForKey(value, 'totalQuantity');
    }
    get totalQuantity():number {
        return this.valueForKey('totalQuantity');
    }
    set totalQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalQuantity');
    }
    get totalQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('totalQuantity');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: warehouseID
    set warehouseID(value:string) {
        this.setValueForKey(value, 'warehouseID');
    }
    get warehouseID():string {
        return this.valueForKey('warehouseID');
    }
    set warehouseIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'warehouseID');
    }
    get warehouseIDPrimitiveValue():string {
        return this.primitiveValueForKey('warehouseID');
    }

    // Relationship: childStockAnnotations
    protected _childStockAnnotations:MIOManagedObjectSet = null;
    get childStockAnnotations():MIOManagedObjectSet {
        return this.valueForKey('childStockAnnotations');
    }
    addChildStockAnnotationsObject(value:StockAnnotation) {
        this._addObjectForKey(value, 'childStockAnnotations');
    }
    removeChildStockAnnotationsObject(value:StockAnnotation) {
        this._removeObjectForKey(value, 'childStockAnnotations');
    }
    // Relationship: parentStockAnnotation
    set parentStockAnnotation(value:StockAnnotation) {
        this.setValueForKey(value, 'parentStockAnnotation');
    }
    get parentStockAnnotation():StockAnnotation {
        return this.valueForKey('parentStockAnnotation') as StockAnnotation;
    }
}
