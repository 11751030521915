

// Generated class StockProduct_ManagedObject

class StockProduct_ManagedObject extends MIOManagedObject
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: isAvailable
    set isAvailable(value:boolean) {
        this.setValueForKey(value, 'isAvailable');
    }
    get isAvailable():boolean {
        return this.valueForKey('isAvailable');
    }
    set isAvailablePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isAvailable');
    }
    get isAvailablePrimitiveValue():boolean {
        return this.primitiveValueForKey('isAvailable');
    }

    // Property: costPrice
    set costPrice(value:number) {
        this.setValueForKey(value, 'costPrice');
    }
    get costPrice():number {
        return this.valueForKey('costPrice');
    }
    set costPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'costPrice');
    }
    get costPricePrimitiveValue():number {
        return this.primitiveValueForKey('costPrice');
    }

    // Property: measureUnitType
    set measureUnitType(value:number) {
        this.setValueForKey(value, 'measureUnitType');
    }
    get measureUnitType():number {
        return this.valueForKey('measureUnitType');
    }
    set measureUnitTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'measureUnitType');
    }
    get measureUnitTypePrimitiveValue():number {
        return this.primitiveValueForKey('measureUnitType');
    }

    // Property: quantity
    set quantity(value:number) {
        this.setValueForKey(value, 'quantity');
    }
    get quantity():number {
        return this.valueForKey('quantity');
    }
    set quantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'quantity');
    }
    get quantityPrimitiveValue():number {
        return this.primitiveValueForKey('quantity');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: stock
    set stock(value:number) {
        this.setValueForKey(value, 'stock');
    }
    get stock():number {
        return this.valueForKey('stock');
    }
    set stockPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'stock');
    }
    get stockPrimitiveValue():number {
        return this.primitiveValueForKey('stock');
    }

    // Property: min
    set min(value:number) {
        this.setValueForKey(value, 'min');
    }
    get min():number {
        return this.valueForKey('min');
    }
    set minPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'min');
    }
    get minPrimitiveValue():number {
        return this.primitiveValueForKey('min');
    }

    // Property: max
    set max(value:number) {
        this.setValueForKey(value, 'max');
    }
    get max():number {
        return this.valueForKey('max');
    }
    set maxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'max');
    }
    get maxPrimitiveValue():number {
        return this.primitiveValueForKey('max');
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
    // Relationship: warehouse
    set warehouse(value:Warehouse) {
        this.setValueForKey(value, 'warehouse');
    }
    get warehouse():Warehouse {
        return this.valueForKey('warehouse') as Warehouse;
    }
}
