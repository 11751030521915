

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class EllaborationConsumptionWarehouse_ManagedObject

class EllaborationConsumptionWarehouse_ManagedObject extends CoreEntityUpdatedBy
{
    // Relationship: consumptionProfile
    set consumptionProfile(value:ConsumptionProfile) {
        this.setValueForKey(value, 'consumptionProfile');
    }
    get consumptionProfile():ConsumptionProfile {
        return this.valueForKey('consumptionProfile') as ConsumptionProfile;
    }
    // Relationship: ellaborationCenter
    set ellaborationCenter(value:EllaborationCenter) {
        this.setValueForKey(value, 'ellaborationCenter');
    }
    get ellaborationCenter():EllaborationCenter {
        return this.valueForKey('ellaborationCenter') as EllaborationCenter;
    }
    // Relationship: warehouse
    set warehouse(value:Warehouse) {
        this.setValueForKey(value, 'warehouse');
    }
    get warehouse():Warehouse {
        return this.valueForKey('warehouse') as Warehouse;
    }
}
