

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class BookingGuestList_ManagedObject

class BookingGuestList_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: accessLevel
    set accessLevel(value:number) {
        this.setValueForKey(value, 'accessLevel');
    }
    get accessLevel():number {
        return this.valueForKey('accessLevel');
    }
    set accessLevelPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'accessLevel');
    }
    get accessLevelPrimitiveValue():number {
        return this.primitiveValueForKey('accessLevel');
    }

    // Property: activeFrom
    set activeFrom(value:Date) {
        this.setValueForKey(value, 'activeFrom');
    }
    get activeFrom():Date {
        return this.valueForKey('activeFrom');
    }
    set activeFromPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'activeFrom');
    }
    get activeFromPrimitiveValue():Date {
        return this.primitiveValueForKey('activeFrom');
    }

    // Property: activeTo
    set activeTo(value:Date) {
        this.setValueForKey(value, 'activeTo');
    }
    get activeTo():Date {
        return this.valueForKey('activeTo');
    }
    set activeToPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'activeTo');
    }
    get activeToPrimitiveValue():Date {
        return this.primitiveValueForKey('activeTo');
    }

    // Property: hourFrom
    set hourFrom(value:string) {
        this.setValueForKey(value, 'hourFrom');
    }
    get hourFrom():string {
        return this.valueForKey('hourFrom');
    }
    set hourFromPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'hourFrom');
    }
    get hourFromPrimitiveValue():string {
        return this.primitiveValueForKey('hourFrom');
    }

    // Property: hourTo
    set hourTo(value:string) {
        this.setValueForKey(value, 'hourTo');
    }
    get hourTo():string {
        return this.valueForKey('hourTo');
    }
    set hourToPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'hourTo');
    }
    get hourToPrimitiveValue():string {
        return this.primitiveValueForKey('hourTo');
    }

    // Property: isPrivate
    set isPrivate(value:boolean) {
        this.setValueForKey(value, 'isPrivate');
    }
    get isPrivate():boolean {
        return this.valueForKey('isPrivate');
    }
    set isPrivatePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isPrivate');
    }
    get isPrivatePrimitiveValue():boolean {
        return this.primitiveValueForKey('isPrivate');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: weekdays
    set weekdays(value:number) {
        this.setValueForKey(value, 'weekdays');
    }
    get weekdays():number {
        return this.valueForKey('weekdays');
    }
    set weekdaysPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'weekdays');
    }
    get weekdaysPrimitiveValue():number {
        return this.primitiveValueForKey('weekdays');
    }

    // Relationship: guests
    protected _guests:MIOManagedObjectSet = null;
    get guests():MIOManagedObjectSet {
        return this.valueForKey('guests');
    }
    addGuestsObject(value:BookingGuest) {
        this._addObjectForKey(value, 'guests');
    }
    removeGuestsObject(value:BookingGuest) {
        this._removeObjectForKey(value, 'guests');
    }
}
