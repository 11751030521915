

/// <reference path="EconomicEntity.ts" />

// Generated class Card_ManagedObject

class Card_ManagedObject extends EconomicEntity
{

    // Property: barcode
    set barcode(value:string) {
        this.setValueForKey(value, 'barcode');
    }
    get barcode():string {
        return this.valueForKey('barcode');
    }
    set barcodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'barcode');
    }
    get barcodePrimitiveValue():string {
        return this.primitiveValueForKey('barcode');
    }

    // Property: cardType
    set cardType(value:number) {
        this.setValueForKey(value, 'cardType');
    }
    get cardType():number {
        return this.valueForKey('cardType');
    }
    set cardTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'cardType');
    }
    get cardTypePrimitiveValue():number {
        return this.primitiveValueForKey('cardType');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: desingType
    set desingType(value:number) {
        this.setValueForKey(value, 'desingType');
    }
    get desingType():number {
        return this.valueForKey('desingType');
    }
    set desingTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'desingType');
    }
    get desingTypePrimitiveValue():number {
        return this.primitiveValueForKey('desingType');
    }

    // Property: discountConcept
    set discountConcept(value:string) {
        this.setValueForKey(value, 'discountConcept');
    }
    get discountConcept():string {
        return this.valueForKey('discountConcept');
    }
    set discountConceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'discountConcept');
    }
    get discountConceptPrimitiveValue():string {
        return this.primitiveValueForKey('discountConcept');
    }

    // Property: effectiveDate
    set effectiveDate(value:Date) {
        this.setValueForKey(value, 'effectiveDate');
    }
    get effectiveDate():Date {
        return this.valueForKey('effectiveDate');
    }
    set effectiveDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'effectiveDate');
    }
    get effectiveDatePrimitiveValue():Date {
        return this.primitiveValueForKey('effectiveDate');
    }

    // Property: enableCardAccounts
    set enableCardAccounts(value:boolean) {
        this.setValueForKey(value, 'enableCardAccounts');
    }
    get enableCardAccounts():boolean {
        return this.valueForKey('enableCardAccounts');
    }
    set enableCardAccountsPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'enableCardAccounts');
    }
    get enableCardAccountsPrimitiveValue():boolean {
        return this.primitiveValueForKey('enableCardAccounts');
    }

    // Property: enableCardDebts
    set enableCardDebts(value:boolean) {
        this.setValueForKey(value, 'enableCardDebts');
    }
    get enableCardDebts():boolean {
        return this.valueForKey('enableCardDebts');
    }
    set enableCardDebtsPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'enableCardDebts');
    }
    get enableCardDebtsPrimitiveValue():boolean {
        return this.primitiveValueForKey('enableCardDebts');
    }

    // Property: enableCardParkedTickets
    set enableCardParkedTickets(value:boolean) {
        this.setValueForKey(value, 'enableCardParkedTickets');
    }
    get enableCardParkedTickets():boolean {
        return this.valueForKey('enableCardParkedTickets');
    }
    set enableCardParkedTicketsPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'enableCardParkedTickets');
    }
    get enableCardParkedTicketsPrimitiveValue():boolean {
        return this.primitiveValueForKey('enableCardParkedTickets');
    }

    // Property: expirationDate
    set expirationDate(value:Date) {
        this.setValueForKey(value, 'expirationDate');
    }
    get expirationDate():Date {
        return this.valueForKey('expirationDate');
    }
    set expirationDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'expirationDate');
    }
    get expirationDatePrimitiveValue():Date {
        return this.primitiveValueForKey('expirationDate');
    }

    // Property: invitationConcept
    set invitationConcept(value:string) {
        this.setValueForKey(value, 'invitationConcept');
    }
    get invitationConcept():string {
        return this.valueForKey('invitationConcept');
    }
    set invitationConceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invitationConcept');
    }
    get invitationConceptPrimitiveValue():string {
        return this.primitiveValueForKey('invitationConcept');
    }

    // Property: isAvailable
    set isAvailable(value:boolean) {
        this.setValueForKey(value, 'isAvailable');
    }
    get isAvailable():boolean {
        return this.valueForKey('isAvailable');
    }
    set isAvailablePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isAvailable');
    }
    get isAvailablePrimitiveValue():boolean {
        return this.primitiveValueForKey('isAvailable');
    }

    // Property: responsibleID
    set responsibleID(value:string) {
        this.setValueForKey(value, 'responsibleID');
    }
    get responsibleID():string {
        return this.valueForKey('responsibleID');
    }
    set responsibleIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'responsibleID');
    }
    get responsibleIDPrimitiveValue():string {
        return this.primitiveValueForKey('responsibleID');
    }

    // Property: responsibleName
    set responsibleName(value:string) {
        this.setValueForKey(value, 'responsibleName');
    }
    get responsibleName():string {
        return this.valueForKey('responsibleName');
    }
    set responsibleNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'responsibleName');
    }
    get responsibleNamePrimitiveValue():string {
        return this.primitiveValueForKey('responsibleName');
    }

    // Property: responsibleType
    set responsibleType(value:number) {
        this.setValueForKey(value, 'responsibleType');
    }
    get responsibleType():number {
        return this.valueForKey('responsibleType');
    }
    set responsibleTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'responsibleType');
    }
    get responsibleTypePrimitiveValue():number {
        return this.primitiveValueForKey('responsibleType');
    }

    // Property: specificDiscount
    set specificDiscount(value:any) {
        this.setValueForKey(value, 'specificDiscount');
    }
    get specificDiscount():any {
        return this.valueForKey('specificDiscount');
    }
    set specificDiscountPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'specificDiscount');
    }
    get specificDiscountPrimitiveValue():any {
        return this.primitiveValueForKey('specificDiscount');
    }

    // Property: specificRate
    set specificRate(value:any) {
        this.setValueForKey(value, 'specificRate');
    }
    get specificRate():any {
        return this.valueForKey('specificRate');
    }
    set specificRatePrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'specificRate');
    }
    get specificRatePrimitiveValue():any {
        return this.primitiveValueForKey('specificRate');
    }
    // Relationship: legalEntity
    set legalEntity(value:LegalEntity) {
        this.setValueForKey(value, 'legalEntity');
    }
    get legalEntity():LegalEntity {
        return this.valueForKey('legalEntity') as LegalEntity;
    }
}
