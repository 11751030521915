//
// Generated class SyncLog
//

/// <reference path="SyncLog_ManagedObject.ts" />

class SyncLog extends SyncLog_ManagedObject
{

}
