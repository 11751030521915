//
// Generated class TimeRangePresetConfiguration
//

/// <reference path="TimeRangePresetConfiguration_ManagedObject.ts" />

enum TimeRangePresetConfigurationType {
    Default = 0,
    Booking = 1,
    Takeaway = 2,
    Delivery = 3
};
class TimeRangePresetConfiguration extends TimeRangePresetConfiguration_ManagedObject
{

}
