

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Currency_ManagedObject

class Currency_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: currencyCode
    set currencyCode(value:string) {
        this.setValueForKey(value, 'currencyCode');
    }
    get currencyCode():string {
        return this.valueForKey('currencyCode');
    }
    set currencyCodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'currencyCode');
    }
    get currencyCodePrimitiveValue():string {
        return this.primitiveValueForKey('currencyCode');
    }

    // Property: factor
    set factor(value:number) {
        this.setValueForKey(value, 'factor');
    }
    get factor():number {
        return this.valueForKey('factor');
    }
    set factorPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'factor');
    }
    get factorPrimitiveValue():number {
        return this.primitiveValueForKey('factor');
    }

    // Property: givesChange
    set givesChange(value:boolean) {
        this.setValueForKey(value, 'givesChange');
    }
    get givesChange():boolean {
        return this.valueForKey('givesChange');
    }
    set givesChangePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'givesChange');
    }
    get givesChangePrimitiveValue():boolean {
        return this.primitiveValueForKey('givesChange');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Relationship: payMethods
    protected _payMethods:MIOManagedObjectSet = null;
    get payMethods():MIOManagedObjectSet {
        return this.valueForKey('payMethods');
    }
    addPayMethodsObject(value:PayMethod) {
        this._addObjectForKey(value, 'payMethods');
    }
    removePayMethodsObject(value:PayMethod) {
        this._removeObjectForKey(value, 'payMethods');
    }
}
