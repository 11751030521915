


class ChannelCell extends MUITableViewCell
{
    private nameTextField:MUITextField = null;    
    
    awakeFromHTML(){        
        this.nameTextField = MUIOutlet(this, 'title-tf', 'MUITextField');
        this.nameTextField.setOnChangeText(this, function(textfield, value){
            this._item.name = value;
        });        
    }

    private _item:BookingChannel = null;
    set item(i:BookingChannel){
        this._item = i;
        this.nameTextField.text = i.name;
    }
}
