

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class RoomBooking_ManagedObject

class RoomBooking_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: beginDate
    set beginDate(value:Date) {
        this.setValueForKey(value, 'beginDate');
    }
    get beginDate():Date {
        return this.valueForKey('beginDate');
    }
    set beginDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'beginDate');
    }
    get beginDatePrimitiveValue():Date {
        return this.primitiveValueForKey('beginDate');
    }

    // Property: endDate
    set endDate(value:Date) {
        this.setValueForKey(value, 'endDate');
    }
    get endDate():Date {
        return this.valueForKey('endDate');
    }
    set endDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'endDate');
    }
    get endDatePrimitiveValue():Date {
        return this.primitiveValueForKey('endDate');
    }

    // Property: nights
    set nights(value:number) {
        this.setValueForKey(value, 'nights');
    }
    get nights():number {
        return this.valueForKey('nights');
    }
    set nightsPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'nights');
    }
    get nightsPrimitiveValue():number {
        return this.primitiveValueForKey('nights');
    }

    // Property: referenceID
    set referenceID(value:string) {
        this.setValueForKey(value, 'referenceID');
    }
    get referenceID():string {
        return this.valueForKey('referenceID');
    }
    set referenceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'referenceID');
    }
    get referenceIDPrimitiveValue():string {
        return this.primitiveValueForKey('referenceID');
    }

    // Relationship: guests
    protected _guests:MIOManagedObjectSet = null;
    get guests():MIOManagedObjectSet {
        return this.valueForKey('guests');
    }
    addGuestsObject(value:RoomGuest) {
        this._addObjectForKey(value, 'guests');
    }
    removeGuestsObject(value:RoomGuest) {
        this._removeObjectForKey(value, 'guests');
    }
    // Relationship: mainGuest
    set mainGuest(value:RoomGuest) {
        this.setValueForKey(value, 'mainGuest');
    }
    get mainGuest():RoomGuest {
        return this.valueForKey('mainGuest') as RoomGuest;
    }
    // Relationship: room
    set room(value:Room) {
        this.setValueForKey(value, 'room');
    }
    get room():Room {
        return this.valueForKey('room') as Room;
    }
    // Relationship: roomBookingGroup
    set roomBookingGroup(value:RoomBookingGroup) {
        this.setValueForKey(value, 'roomBookingGroup');
    }
    get roomBookingGroup():RoomBookingGroup {
        return this.valueForKey('roomBookingGroup') as RoomBookingGroup;
    }
}
