
class LicensesDetailInfoDataSource extends MIOObject
{
    private tableView:UITableView = null;
    private delegate:LicenseDetailInfoCellDelegate = null;
    initWithTableView(tableView:UITableView, delegate:LicenseDetailInfoCellDelegate) {
        this.tableView = tableView;
        this.delegate = delegate;
    }

    private license:License = null;
    set item(item:License) {
        this.license = item;
        this.tableView.reloadData();
    }

    numberOfSections(tableview:UITableView){
        return 1;
    }
    
    numberOfRowsInSection(tableview:UITableView, section:number){
        return 1;
    }
    
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("LicenseDetailInfoCell") as LicenseDetailInfoCell;        
 
        cell.item = this.license;     
        cell.delegate = this.delegate;   
        
        return cell;
    }
}

interface LicenseDetailInfoCellDelegate 
{
    didChangePrice(license:License, price:number):void;
    didChangeDiscount(license:License, discount:string):void;
    didChangeTotal(license:License, total:number):void;
    
}

