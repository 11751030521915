//
// Generated class SupportTicket
//

/// <reference path="SupportTicket_ManagedObject.ts" />

class SupportTicket extends SupportTicket_ManagedObject
{

}
