
enum CalendarMultipleViewControllerMode {
    Months,
    Years
}
class CalendarMultipleViewLayout extends MUICollectionViewFlowLayout 
{
    static layout(){
        let l = new CalendarMultipleViewLayout();
        l.init();
        return l;
    }

    invalidateLayout(){
        this.minimumLineSpacing = 0;
        this.minimumInteritemSpacing = 0;
        let w = this.collectionView.getWidth() / 3;
        let h = this.collectionView.getHeight() / 4;        
        this.collectionView.collectionViewLayout.itemSize = new MIOSize(w, h);
    }
}

class CalendarMultipleViewController extends MUIViewController
{    
    delegate = null;
    dateTimeFrom:Date = null;
    dateTimeTo:Date = null;

    private calendarNavBar:CalendarNavBar = null;
    private collectionView:MUICollectionView = null;
    
    private df = MUIWebApplication.sharedInstance().delegate.dateFormatter;
    private year = null;

    get preferredContentSize(){
        return new MIOSize(500, 413);
    }

    viewDidLoad() { 
        super.viewDidLoad();

        this.calendarNavBar = MUIOutlet(this, "calendar-nav-bar", "CalendarNavBar");
        this.calendarNavBar.delegate = this;        

        this.collectionView = MUIOutlet(this, "collection-view", 'MUICollectionView');
        this.collectionView.dataSource = this;
        this.collectionView.delegate = this;     
        this.collectionView.collectionViewLayout = CalendarMultipleViewLayout.layout();
        this.collectionView.calculateLayout = true;

        this.year = MIODateGetYearFromDate(MIODateToday());
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _mode = CalendarMultipleViewControllerMode.Months;
    set mode(value){
        if (this._mode == value) return;
        this._mode = value;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        let minYear = this.year - 7;
        let maxYear = this.year + 5;
        this.years = [];
        for (let index = minYear; index < maxYear; index++){
            this.years.addObject(index);
        }

        this.calendarNavBar.setHidden(this._mode == CalendarMultipleViewControllerMode.Years);

        this.collectionView.collectionViewLayout.invalidateLayout();
        this.collectionView.reloadData();        
    }
    
    monthAndDateDidChange(month, year){
        this.year = year;
        this.updateUI();
    }

    numberOfSections(collectionView:MUICollectionView) {
        return 1;
    }

    numberOfItemsInSection(collectionView:MUICollectionView, section) {
        if (this._mode == CalendarMultipleViewControllerMode.Months) return 12;
        else return this.years.count;
    }

    private years = null;
    cellForItemAtIndexPath(collectionView:MUICollectionView, indexPath:MIOIndexPath) {
        let cell: CalendarMultipleCell = collectionView.dequeueReusableCellWithIdentifier('TitleCell');
        if (this._mode == CalendarMultipleViewControllerMode.Months){
            cell.title = MIODateGetStringForMonth(indexPath.row);
            cell.value = indexPath.row;
        }
        else {
            cell.title = this.years[indexPath.row];
            cell.value = this.years[indexPath.row];
        }
        return cell;
    }

    didSelectCellAtIndexPath(collectionView:MUICollectionView, indexPath:MIOIndexPath){
        let cell:CalendarMultipleCell = collectionView.cellAtIndexPath(indexPath);

        if (this._mode == CalendarMultipleViewControllerMode.Months){
            let month = cell.value;            
            this.dateTimeFrom = MIODateFromString(this.year + "-" + (month + 1) + "-" + 1 + " 00:00:00");
            let lastDayOfTheMonth = MIODateGetLastDayOfTheMonth(month, this.year);
            this.dateTimeTo = MIODateFromString(this.year + "-" + (month + 1) + "-" + lastDayOfTheMonth + " 23:59:59");
        }
        else if (this._mode == CalendarMultipleViewControllerMode.Years){
            let year = cell.value;
            this.dateTimeFrom = MIODateFromString(year + "-01-01 00:00:00");
            this.dateTimeTo = MIODateFromString(year + "-12-31 23:59:59");
        }
    }
}
