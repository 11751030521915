//
// Generated class BookingPriceRule
//

/// <reference path="BookingPriceRule_ManagedObject.ts" />

enum BookingPriceRuleType
{
    ByTable,
    ByPax    
}

class BookingPriceRule extends BookingPriceRule_ManagedObject
{

}
