

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Warehouse_ManagedObject

class Warehouse_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: contactName
    set contactName(value:string) {
        this.setValueForKey(value, 'contactName');
    }
    get contactName():string {
        return this.valueForKey('contactName');
    }
    set contactNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'contactName');
    }
    get contactNamePrimitiveValue():string {
        return this.primitiveValueForKey('contactName');
    }

    // Property: document
    set document(value:string) {
        this.setValueForKey(value, 'document');
    }
    get document():string {
        return this.valueForKey('document');
    }
    set documentPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'document');
    }
    get documentPrimitiveValue():string {
        return this.primitiveValueForKey('document');
    }

    // Property: image
    set image(value:string) {
        this.setValueForKey(value, 'image');
    }
    get image():string {
        return this.valueForKey('image');
    }
    set imagePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'image');
    }
    get imagePrimitiveValue():string {
        return this.primitiveValueForKey('image');
    }

    // Property: mail
    set mail(value:string) {
        this.setValueForKey(value, 'mail');
    }
    get mail():string {
        return this.valueForKey('mail');
    }
    set mailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'mail');
    }
    get mailPrimitiveValue():string {
        return this.primitiveValueForKey('mail');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }
    // Relationship: address
    set address(value:Address) {
        this.setValueForKey(value, 'address');
    }
    get address():Address {
        return this.valueForKey('address') as Address;
    }

    // Relationship: ellaborationConsumptionWarehouses
    protected _ellaborationConsumptionWarehouses:MIOManagedObjectSet = null;
    get ellaborationConsumptionWarehouses():MIOManagedObjectSet {
        return this.valueForKey('ellaborationConsumptionWarehouses');
    }
    addEllaborationConsumptionWarehousesObject(value:EllaborationConsumptionWarehouse) {
        this._addObjectForKey(value, 'ellaborationConsumptionWarehouses');
    }
    removeEllaborationConsumptionWarehousesObject(value:EllaborationConsumptionWarehouse) {
        this._removeObjectForKey(value, 'ellaborationConsumptionWarehouses');
    }
    // Relationship: phone
    set phone(value:PhoneNumber) {
        this.setValueForKey(value, 'phone');
    }
    get phone():PhoneNumber {
        return this.valueForKey('phone') as PhoneNumber;
    }

    // Relationship: productWarehouses
    protected _productWarehouses:MIOManagedObjectSet = null;
    get productWarehouses():MIOManagedObjectSet {
        return this.valueForKey('productWarehouses');
    }
    addProductWarehousesObject(value:ProductWarehouse) {
        this._addObjectForKey(value, 'productWarehouses');
    }
    removeProductWarehousesObject(value:ProductWarehouse) {
        this._removeObjectForKey(value, 'productWarehouses');
    }
}
