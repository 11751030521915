


class BusinessCell extends MUITableViewCell 
{    
    private nameLabel:MUILabel = null;        

    awakeFromHTML() {
        this.nameLabel = MUIOutlet(this, 'name-lbl', 'MUILabel');
    }
    
    set item(b) {                
        this.nameLabel.text = b["name"];
    }
}
