

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class PromotionalCodeLine_ManagedObject

class PromotionalCodeLine_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: sourceDocumentID
    set sourceDocumentID(value:string) {
        this.setValueForKey(value, 'sourceDocumentID');
    }
    get sourceDocumentID():string {
        return this.valueForKey('sourceDocumentID');
    }
    set sourceDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceDocumentID');
    }
    get sourceDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('sourceDocumentID');
    }

    // Property: usedAt
    set usedAt(value:Date) {
        this.setValueForKey(value, 'usedAt');
    }
    get usedAt():Date {
        return this.valueForKey('usedAt');
    }
    set usedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'usedAt');
    }
    get usedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('usedAt');
    }
    // Relationship: promotionalCode
    set promotionalCode(value:PromotionalCode) {
        this.setValueForKey(value, 'promotionalCode');
    }
    get promotionalCode():PromotionalCode {
        return this.valueForKey('promotionalCode') as PromotionalCode;
    }
}
