



class ProductRateViewController extends MUIViewController
{
    private _fetchedResultsController = null;
    private tableView:MUITableView = null;
    private productNameLabel:MUILabel = null;
    private productPriceLabel:MUILabel = null;
    private productWithoutTaxLabel:MUILabel = null;

    private titleLabel:MUILabel = null;
    private backButton:MUIButton = null;

    private _product:Product = null;

    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;

    public get preferredContentSize()
    {
        return new MIOSize(400, 400);
    }

    viewDidLoad()
    {
        super.viewDidLoad();

        this.tableView = MUIOutlet(this,"rsv_c1_tableview","MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        //this.tableView.reloadData();

        this.titleLabel = MUIOutlet(this,"rsv_nb_rates_lbl","MUILabel");
        this.titleLabel.text = MIOLocalizeString("PRODUCT RATES", "PRODUCT RATES");

        this.productPriceLabel = MUIOutlet(this, 'rsv_cb_cost_text', 'MUILabel');
        this.productWithoutTaxLabel = MUIOutlet(this, 'rsv_cb_iva_text', 'MUILabel');

        this.productNameLabel = MUIOutlet(this, 'rsv_tb_title_lbl', 'MUILabel');

        this.backButton = MUIOutlet(this, 'rsv_nb_close_btn', 'MUIButton');
        this.backButton.setAction(this, function () {

            if(this._product.hasChanges) {
                MIONotificationCenter.defaultCenter().postNotification("ProductChangeValue", null);
            }
            let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
            ad.managedObjectContext.save();
            this.dismissViewController(true);

        });
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    set product(value)
    {
        this._product = value;
        this.updateUI();
    }

    private updateUI()
    {
        if (this._product == null) return;
        if (this.viewIsLoaded == false) return;

        this.productNameLabel.text = this._product.name;
        this.productPriceLabel.text = this.nf.stringFromNumber(this._product.price);
        if(this._product.tax != null && this._product.price != null) {
            let priceWithoutTax = this._product.price * ( 1 - this._product.tax.taxQuantity)
            this.productWithoutTaxLabel.text =  this.nf.stringFromNumber(priceWithoutTax);
        }
        
        this.fetchedResultsController = null;
        this.tableView.reloadData();

    }

    numberOfSections(tableview)
    {
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview, section)
    {
        var sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath)
    {
        var cell:ProductRateCell = tableview.dequeueReusableCellWithIdentifier("ProductRateCell");

        var item = this.fetchedResultsController.objectAtIndexPath(indexPath);

        let filtered = this._product.productRates.filterWithPredicate(MIOPredicate.predicateWithFormat("rate.identifier == " + item.identifier));
        // if(filtered.length > 0)  
        //     cell.productRate = filtered[0];
        
        // cell.product = this._product;
        // cell.item = item;
        // cell.delegate = this;
 
        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
        cell.selectionStyle = MUITableViewCellSelectionStyle.None;
        
        return cell;
    }

    set fetchedResultsController(value)
    {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController()
    {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        var ad = MUIWebApplication.sharedInstance().delegate;

        var fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("Rate");
        fetchRequest.sortDescriptors = [
                        MIOSortDescriptor.sortDescriptorWithKey("orderIndex", true),
                        MIOSortDescriptor.sortDescriptorWithKey("name", true)];

        var fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;

        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller)
    {
        this.tableView.reloadData(); 
    }
}