


class ReportBuilderViewController extends MUIViewController 
{
    private itemTableView:MUITableView = null;
    private queryTableView:MUITableView = null;

    private _itemFetchedResultsController:MIOFetchedResultsController = null;

    viewDidLoad(){
        super.viewDidLoad();

        
    }

    numberOfSections(tableview)
    {
        if (tableview === this.itemTableView)
            return this.itemFetchedResultsController.sections.length;
        
        return 0;
    }
    
    numberOfRowsInSection(tableview, section)
    {
        if (tableview === this.itemTableView) {
            var sec = this.itemFetchedResultsController.sections[section];
            return sec.numberOfObjects();
        }

        return 0;
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath)
    {
        if (tableview === this.itemTableView)  {

            var cell = tableview.dequeueReusableCellWithIdentifier('InvoiceSupplierCell');
            this.configureItemCell(cell, indexPath);
            return cell;
        }
    
        return null;
    }

    configureItemCell(cell, indexPath:MIOIndexPath) {

        var item = this.itemFetchedResultsController.objectAtIndexPath(indexPath);        
        cell.item = item;                        
        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;        
    }

    get itemFetchedResultsController(){

        if (this._itemFetchedResultsController != null){
            return this._itemFetchedResultsController;
        }



        return this._itemFetchedResultsController;
    }
}