
class OnlineOrderListViewController extends EntityListViewController
{
    private segmentedControl:MUISegmentedControl = null;
    private headerView:OnlineOrderListHeaderView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.segmentedControl = MUIOutlet(this, "segmented-control", "MUISegmentedControl");
        this.segmentedControl.setAction(this, this.segmentedControlDidChange);

        this.headerView = MUIOutlet(this, "header-view", "OnlineOrderListHeaderView");
        this.headerView.hidden = true;

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.delegate = this;
        this.tableView.dataSource = this;
    }

    viewForHeaderInSection(tableview:UITableView, section:number){
        if (this.headerView == null) return null;

        let h = this.headerView.copy() as OnlineOrderListHeaderView;
        h.awakeFromHTML();
        h.hidden = false;

        let ip = MIOIndexPath.indexForRowInSection(0, section);
        let item = this.fetchedResultsController.objectAtIndexPath(ip) as OnlineOrder;        
        switch (this.segmentedControl.selectedSegmentedIndex){
            case 0: h.text = item.sessionDeliveryTime; break;
            case 1: h.text = item.sessionDeliveryDay + " " + item.sessionDeliveryTime; break;
            case 2: h.text = item.sessionDeliveryDay; break;
        }         

        return h;
    }                

    // titleForHeaderInSection?(tableView:MUITableView, section:number):string {
    //     let ip = MIOIndexPath.indexForRowInSection(0, section);
    //     let item = this.fetchedResultsController.objectAtIndexPath(ip) as OnlineOrder;    
        
    //     return this.segmentedControl.selectedSegmentedIndex == 0 ? item.sessionDeliveryTime : item.sessionDeliveryDay + " " + item.sessionDeliveryTime;
    // }


    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let is_today = this.segmentedControl.selectedSegmentedIndex == 0;
        let cellIdentifier = /*is_today ? "OnlineOrderTodayListCell" :*/ "OnlineOrderListCell";

        let cell = tableview.dequeueReusableCellWithIdentifier(cellIdentifier) as OnlineOrderListCell;    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as OnlineOrder;
        
        cell.isToday = is_today;

        cell.item = item;
        return cell;
    }


    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as OnlineOrder;
            
        let vc = new OnlineOrderDetailViewController("online-order-detail-view");
        vc.initWithResource("layout/online_orders/OnlineOrderDetailView.html");

        vc.onlineOrder = item;
        this.splitViewController.showDetailViewController(vc);
    }

            
    set fetchedResultsController(value){
        this.setFetchedResultsController(value);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [
            MIOSortDescriptor.sortDescriptorWithKey("sessionDeliveryDay", false),
            MIOSortDescriptor.sortDescriptorWithKey("sessionDeliveryTime", false)
        ];
        
        let predicateFormat = [];
        switch (this.segmentedControl.selectedSegmentedIndex){
            case 0: // Today
                predicateFormat.push ( "createdTPVOrderID != null" );
                predicateFormat.push ( "sessionDeliveryDay == '" + MIODateGetDateString( new Date()) + "'" );
                break;

            case 1: // Pending
                predicateFormat.push ( "createdTPVOrderID == null" );
                predicateFormat.push ( "sessionDeliveryDay == '" + MIODateGetDateString( new Date()) + "'" );
                break;

            case 2:
                predicateFormat.push ( "createdTPVOrderID != null" );
                predicateFormat.push ( "sessionDeliveryDay != '" + MIODateGetDateString( new Date()) + "'" );
                break;
        
        }

        // if (ad.selectedIdentifierType == "place") predicateFormat += " AND placeID == '" + ad.selectedIdentifier + "'";
        if(this.searchString != null) {
            predicateFormat.push ( "documentID CONTAINS '" + this.searchString + "'" );
		}
		
		// if (this.filterPredicateFormat != null) {
        //     predicateFormat += " AND " + this.filterPredicateFormat;
        // }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("OnlineOrder", sortDescriptors, predicateFormat.join(" AND "));
        fetchRequest.fetchLimit = 100;
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, this.segmentedControl.selectedSegmentedIndex == 0 ? "sessionDeliveryTime" : "sessionDeliveryDay");
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    private segmentedControlDidChange(control:MUISegmentedControl) {
        this.invalidateData();
    }
}