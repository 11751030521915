class WorkerRoleCell extends UITableViewCell 
{
    nameTextField:MUITextField = null;

    awakeFromHTML() {
        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.nameTextField.setOnChangeText(this, function(this:WorkerRoleCell, control:MUITextField, value:string){
            this.role.name = value.length > 0 ? value : null;
        });
    }

    private role:WorkerRole = null;
    set item(i:WorkerRole) {
        this.role = i;
        this.nameTextField.text = i.name;
    }
}