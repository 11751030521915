

/// <reference path="../base/EntityListViewController.ts" />

/// <reference path="../AppHelper.ts" />

enum DebtFilterType
{
    Debt,
    Payed,
    Discarded,
    Void,
    All
}

enum PayDebtType
{
    All,
    ByImport,
    LineTotal,
    LinePartial
}

class ClientDebtViewController extends MUIViewController
{    
    static newInstance(): ClientDebtViewController {
        let vc = new ClientDebtViewController("client-debt-view");
        vc.initWithResource("layout/clients/ClientDebtView.html");
        return vc;
    }

    private totalDebtLabel:MUILabel = null;
	private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;   


	private tableView:MUITableView = null;   

	private columnFilterController:ColumnFilterController = null;

	private dateFilterTextField:ColumnFilterTextField = null;
	private payedDateFilterTextField:ColumnFilterTextField = null;
	private pendingFilterTextField:ColumnFilterTextField = null;
	private valueFilterTextField:ColumnFilterTextField = null;
	private ticketFilterTextField:ColumnFilterTextField = null;

    viewDidLoad(){
		super.viewDidLoad();
		
		this.columnFilterController = new ColumnFilterController();
		this.columnFilterController.initWithDelegate(this);

		this.dateFilterTextField = MUIOutlet(this, "date-cf-tf", "ColumnFilterTextField");
		this.dateFilterTextField.filterController = this.columnFilterController;
		this.dateFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "creationDate", null, null);

		this.payedDateFilterTextField = MUIOutlet(this, "payed-date-cf-tf", "ColumnFilterTextField");
		this.payedDateFilterTextField.filterController = this.columnFilterController;
		this.payedDateFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "payedDate", null, null);

		this.pendingFilterTextField = MUIOutlet(this, "pending-cf-tf", "ColumnFilterTextField");
		this.pendingFilterTextField.filterController = this.columnFilterController;
		this.pendingFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "pendingValue", null, null);

		this.valueFilterTextField = MUIOutlet(this, "value-cf-tf", "ColumnFilterTextField");
		this.valueFilterTextField.filterController = this.columnFilterController;
		this.valueFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "debtValue", null, null);

		this.ticketFilterTextField = MUIOutlet(this, "ticket-cf-tf", "ColumnFilterTextField");
		this.ticketFilterTextField.filterController = this.columnFilterController;
		this.ticketFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "legalDocumentID", null, null);

        this.totalDebtLabel = MUIOutlet(this, 'total-debt-lbl','MUILabel');    

        this.tableView = MUIOutlet(this, 'table-view', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;                        
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _client:Client = null;
    set client(value){
        this._client = value;
        this.updateUI();
    }

    private _filter = DebtFilterType.Debt;
    set filter(value){
        this._filter = value;
        this.updateUI();
    }

    private updateUI(){
        if(this.viewIsLoaded == false || this._client == null) return;
        
        this.updateClientDebt();
        this.fetchedResultsController = null;
        this.tableView.reloadData();        
    }

    private updateClientDebt(){
        this.totalDebtLabel.text = this._client.debtBalance != null ? this.cf.stringFromNumber(this._client.debtBalance.amount) : this.cf.stringFromNumber(0);
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('ClientDebtCell') as ClientDebtCell;    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as DebtLine;
    
        cell.item = item;
        cell.delegate = this;            
        
        //cell.setAccessoryType(MUITableViewCellAccessoryType.DisclosureIndicator);
        return cell;
    }
    
    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        let debtLine = this.fetchedResultsController.objectAtIndexPath(indexPath) as DebtLine;
        //this.showDocumentViewController(debtLine);
    }

    get entityName(){
        return 'DebtLine';
    }

	private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
    
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('creationDate', false)];
    
        let predicateFormat = "economicEntity.identifier == " + this._client.identifier;
        if (this._filter == DebtFilterType.Debt) predicateFormat += " AND isDiscarded == false AND isVoid = false AND payedDate == null";
        else if (this._filter == DebtFilterType.Discarded) predicateFormat += " AND isDiscarded == true";
        else if (this._filter == DebtFilterType.Void) predicateFormat += " AND isVoid == true";
		else if (this._filter == DebtFilterType.Payed) predicateFormat += " AND isDiscarded == false AND isVoid == false AND payedDate != null";
		
		//  Column filter
		let filterFormat = this.columnFilterController.filterPredicateFormat();
		if (filterFormat != null) {
			predicateFormat += " AND " + filterFormat;
		}

        let fetchRequest = DBHelper.listFetchRequestWithEntityName(this.entityName, sortDescriptors, predicateFormat);
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
	}
	
	filterPredicateDidChange(controller:ColumnFilterController){        
        this.fetchedResultsController = null;
        this.tableView.reloadData();        
    }

    private documentDetailViewController:ArchivedDocumentDetailViewController = null;
    private showDocumentViewController(){
        if (this.documentDetailViewController == null) {
            // this.documentDetailViewController = new TicketDetailViewController("ticket-detail-view");
            // this.documentDetailViewController.initWithResource("layout/tickets/TicketDetailView.html");
        }

        //this.navigationController.pushViewController(this.documentDetailViewController, true);
    }

    // 
    // Delegate
    //

    public showOptions(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('DEBTS','DEBTS'), MIOLocalizeString('OPTIONS','OPTIONS'), MUIAlertViewStyle.Default);
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("PAY ALL", "PAY ALL"), MUIAlertActionStyle.Default, this, function(){            
            this.showPaymentOptions(null, PayDebtType.All);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("PAY WITH IMPORT", "PAY WITH IMPORT"), MUIAlertActionStyle.Default, this, function(){            
            this.showPaymentOptions(null, PayDebtType.ByImport);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);

    }
    
    public showPayDebtOptions(debtLine:DebtLine){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('DEBTS','DEBTS'), MIOLocalizeString( 'PAYMENT DEBT OPTIONS','PAYMENT DEBT OPTIONS' ), MUIAlertViewStyle.Default);
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("TOTAL", "TOTAL"), MUIAlertActionStyle.Default, this, function(){            
            this.showPaymentOptions(debtLine, PayDebtType.LineTotal);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("PARTIAL", "PARTIAL"), MUIAlertActionStyle.Default, this, function(){            
            this.showPaymentOptions(debtLine, PayDebtType.LinePartial);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    public showPaymentOptions(debtLine:DebtLine, type:PayDebtType){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('DEBT','DEBT'), MIOLocalizeString('CANCEL CLIENT DEBT','CANCEL CLIENT DEBT'), MUIAlertViewStyle.Default);
           
        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
               
           DBHelper.sharedInstance().addObserverForEntity(avc, 'PayMethod', null, null, null, function (objects) {
               comboBox.removeAllItems();
               let payments = _MIOSortDescriptorSortObjects(objects, [MIOSortDescriptor.sortDescriptorWithKey("name", true)]);
               for (let i = 0; i < payments.length; i++)
                   if(payments[i].isDisabled == false) 
                     comboBox.addItem(payments[i].name, i);
           
               comboBox.addItem(MIOLocalizeString('CLIENT ACCOUNT','CLIENT ACCOUNT'), DebtPaymentMethod.ClientAccount);
               comboBox.addItem(MIOLocalizeString('POINTS','POINTS'), DebtPaymentMethod.ClientLoyalty);                
           });
       });
       
       if(type == PayDebtType.LinePartial || type == PayDebtType.ByImport){
           avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
               textField.setPlaceholderText(MIOLocalizeString('QUANTITY',"QUANTITY"));
               textField.formatter = this.cf.numberFormatter;
           });
       }

       avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('PAY','PAY'), MUIAlertActionStyle.Default, this, function(){
           let payMethodComboBox:MUIComboBox = avc.comboBoxes[0];
           let selectedPayMethod = parseInt(payMethodComboBox.getSelectedItem());
           
           let payMethodType = null;
           let payMethod = null;

            if (selectedPayMethod == DebtPaymentMethod.ClientAccount || selectedPayMethod == DebtPaymentMethod.ClientLoyalty){
                payMethod = null;
                payMethodType = selectedPayMethod;
            }
            else {
                payMethod = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, selectedPayMethod, 'PayMethod');
                payMethodType = DebtPaymentMethod.PayMethodEntity;
            }
    
            let quantity = null;
            if (type == PayDebtType.LinePartial || type == PayDebtType.ByImport){
                let quantityTextField = avc.textFields[0];
                quantity = this.cf.numberFromString(quantityTextField.text);
            }

            this.payDebtLine(debtLine, quantity, type, payMethodType, payMethod);
       }));
       
       avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, null, null));

       avc.addCompletionHandler(this, function (){
           DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, 'PayMethod');
       });

       this.presentViewController(avc, true);
    }

    private payDebtLine(line:DebtLine, amount:number, type:PayDebtType, payMethodType:DebtPaymentMethod, payMethod:PayMethod){

        let ad = MUIWebApplication.sharedInstance().delegate;
        let ws = ad.webService as WebService;

        let pm_id = null;
        switch( payMethodType ) {
            case DebtPaymentMethod.PayMethodEntity: pm_id = payMethod.identifier; break;
            case DebtPaymentMethod.ClientAccount: pm_id = "99999999-1111-0000-0000-111111111111"; break;
            case DebtPaymentMethod.ClientLoyalty: pm_id = "99999999-2222-0000-0000-111111111111"; break;
        }
                
        ws.payDebtLine(line, pm_id, amount, (json:any, error:string) => {
            if (error == null) {
                AppHelper.showInfoMessage( this, MIOLocalizeString( "INFO", "INFO" ), MIOLocalizeString( "THE DEBT WAS SUCCESSFULLY PAID", "THE DEBT WAS SUCCESSFULLY PAID" ) );
                this.updateUI();
            }
            else {
                AppHelper.showErrorMessage( this, MIOLocalizeString( "ERROR", "ERROR" ), MIOLocalizeString( "SOMETHING WENT WRONG", "SOMETHING WENT WRONG" ) );
            }
        });


        // switch (type){
        //     case PayDebtType.LineTotal:
        //     this.payTotalDebtLine(payMethodType, payMethod, line);
        //     break;

        //     case PayDebtType.LinePartial:
        //     this.payPartialDebtLine(payMethodType, payMethod, quantity, line);
        //     break;

        //     case PayDebtType.All:
        //     this.payDebtByAmount(payMethodType, payMethod, this._client.debtBalance.amount);
        //     break;

        //     case PayDebtType.ByImport:
        //     this.payDebtByAmount(payMethodType, payMethod, quantity);
        //     break;
        // }
    }

    discardDebtFromCell(cell:ClientDebtCell, line:DebtLine){
        this.discardDebt(line);
    }

    //
    // WebServices
    //
    
    private discardDebt(line:DebtLine){
        let ad = MUIWebApplication.sharedInstance().delegate;
        let ws = ad.webService as WebService;

        ws.discardDebtLine(line, (data:any, error:string) => {
            if ( error == null ) {
                AppHelper.showInfoMessage( this, MIOLocalizeString( "INFO", "INFO" ), MIOLocalizeString( "THE DEBT WAS SUCCESSFULLY DISCARDED", "THE DEBT WAS SUCCESSFULLY DISCARDED" ) );
                this.updateClientDebt();
            }
            else {
                AppHelper.showErrorMessage( this, MIOLocalizeString( "ERROR", "ERROR" ), MIOLocalizeString( "SOMETHING WENT WRONG", "SOMETHING WENT WRONG" ) );
            }
        });
    }

}