
class InputNoteCell extends UITableViewCell
{
    private documentLabel:MUILabel = null;
    private conceptLabel:MUILabel = null;
    private dateLabel:MUILabel = null;
    private destinationLabel:MUILabel = null;    
    
    private dtf = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter;

    awakeFromHTML(){
        this.documentLabel = MUIOutlet(this, 'doc-id-lbl', 'MUILabel');
        this.dateLabel = MUIOutlet(this, 'date-lbl', 'MUILabel');
        this.conceptLabel = MUIOutlet(this, 'concept-lbl', 'MUILabel');
        this.destinationLabel = MUIOutlet(this, 'destination-lbl', 'MUILabel');
    }

    set item(i:StockNote){
        this.documentLabel.text = i.documentID;
        this.dateLabel.text = this.dtf.stringFromDate(i.stockDate);
        this.conceptLabel.text = i.originName;
        this.destinationLabel.text = i.destinationName;
    }
}