//
// Generated class VenueEvent
//

/// <reference path="VenueEvent_ManagedObject.ts" />

class VenueEvent extends VenueEvent_ManagedObject
{

}
