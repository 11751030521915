//
// Generated class DBScript
//

/// <reference path="DBScript_ManagedObject.ts" />

class DBScript extends DBScript_ManagedObject
{

}
