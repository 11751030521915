class EditUserPasswordViewController extends MUIViewController
{  
    private backButton:MUIButton = null;
    private currentPasswordTextField:MUITextField = null;
    private newPasswordTextField:MUITextField = null;
    private retypePasswordTextField:MUITextField = null;
    private saveButton:MUIButton = null;

    public delegate = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");
        this.backButton.setAction(this, function (){            
            this.navigationController.popViewController(true);
        });

        this.currentPasswordTextField = MUIOutlet(this, "current-password-tf", "MUITextField");
        this.currentPasswordTextField.setOnChangeText(this, function(control, value:string){
            if(value.length > 0) {
                
            }
        });

        this.newPasswordTextField = MUIOutlet(this, "new-password-tf", "MUITextField");
        this.newPasswordTextField.setOnChangeText(this, function(control, value:string){
            if(value.length > 0) {

            }
        });

        this.retypePasswordTextField = MUIOutlet(this, "retype-new-password-tf", "MUITextField");
        this.retypePasswordTextField.setOnChangeText(this, function(control, value:string){
            if(value.length > 0) {
                
            }
        });

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            // DBHelper.saveMainContext();
            this.navigationController.popViewController(true);
        });
    }

    viewWillAppear(){
        super.viewWillAppear();
        this.updateUI();
    }

    private _user:User = null;
    set user(user:User){
        this._user = user;
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

    }




}
