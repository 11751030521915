class DBOutputNoteLinesCoder extends DBXLSXCoder
{
    stockNoteID:string = null;

    protected mainEntity(){
        return { entity: "CustomOutputNoteLine", predicateFormat: "deletedAt == null and note.identifier = " + this.stockNoteID, relations: ["product", "product.stockCategory"] }
    }

    protected columns() : any[] {    
        let cols = [
            { title: "DOCUMENT ID", width: "10%", align: "center" },
            { title: "STOCK CATEGORY", width: "10%", align: "center" },
            { title: "NAME", width: "10%", align: "center" },
            { title: "PRODUCT QUANTITY", width: "10%", align: "center", formatter: this.ad.numberFormatter },
            { title: "TOTAL QUANTITY", width: "10%", align: "center", formatter: this.ad.numberFormatter },
            { title: "TOTAL COST", width: "10%", align: "center", formatter: this.ad.currencyFormatter },
        ]
        return cols;
    }

    // protected importRow(row:any){
        
    //     let formatID        = row["FORMAT ID"];
    //     let formatName      = row["FORMAT NAME"] as string;
    //     let invitationValue = row["INVITATION VALUE"];     

    //     if (formatName == null || formatName?.length > 0) return;

    //     let format = this.queryEntityByIDOrName("Format", formatID, formatName) as Format;
    //     if (formatID?.length > 0 && format == null){
    //         format = MIOEntityDescription.insertNewObjectForEntityForName("Format", DBHelper.mainManagedObjectContext) as Format;
    //         format.identifier = this.parseOrCreateIdentifier(formatID);
    //         format.name = formatName;  
    //         this.appendObject(format);
    //     }
        
    //     format.invitationValue = invitationValue;

    //     MIOLog("ADDING FORMAT: " + this.rowIndex + "/" + this.rows.length + ": " + formatName);
    // }

    // 
    // Export
    // 
    protected exportTitle(): string { return "output note lines"; }
    protected exportSortDescriptors() { 
        return [ 
            MIOSortDescriptor.sortDescriptorWithKey('product.stockCategory.name', true), 
            MIOSortDescriptor.sortDescriptorWithKey('product.name', true)
        ]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let line = object as CustomOutputNoteLine;

        let nf = (MUIWebApplication.sharedInstance().delegate as AppDelegate).numberFormatter;
        let total = null;
        if (line.productMeasureType == MeasureUnitType.Container){
            total = nf.stringFromNumber(line.productQuantity * line.productContainerQuantity) + " " + MeasureUnits.toString(line.productContainerMeasureType)
        }
        else {
            total = nf.stringFromNumber(line.productQuantity) + " " + MeasureUnits.toString(line.productMeasureType);
        }

        let item = {
            "DOCUMENT ID"     : line.note.documentID,
            "STOCK CATEGORY"  : line.product.stockCategory?.name,
            "NAME"            : line.product.productNameDescription(),
            "PRODUCT QUANTITY": line.productQuantity,
            "TOTAL QUANTITY"  : total,
            "TOTAL COST"      : line.product.computedCost * line.productQuantity 
        }

        return item;
    }
}