

/// <reference path="CoreAccounting.ts" />

// Generated class DBDocument_ManagedObject

class DBDocument_ManagedObject extends CoreAccounting
{

    // Property: baseAmount
    set baseAmount(value:number) {
        this.setValueForKey(value, 'baseAmount');
    }
    get baseAmount():number {
        return this.valueForKey('baseAmount');
    }
    set baseAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'baseAmount');
    }
    get baseAmountPrimitiveValue():number {
        return this.primitiveValueForKey('baseAmount');
    }

    // Property: comments
    set comments(value:string) {
        this.setValueForKey(value, 'comments');
    }
    get comments():string {
        return this.valueForKey('comments');
    }
    set commentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'comments');
    }
    get commentsPrimitiveValue():string {
        return this.primitiveValueForKey('comments');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: discountAmount
    set discountAmount(value:number) {
        this.setValueForKey(value, 'discountAmount');
    }
    get discountAmount():number {
        return this.valueForKey('discountAmount');
    }
    set discountAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'discountAmount');
    }
    get discountAmountPrimitiveValue():number {
        return this.primitiveValueForKey('discountAmount');
    }

    // Property: documentNumber
    set documentNumber(value:string) {
        this.setValueForKey(value, 'documentNumber');
    }
    get documentNumber():string {
        return this.valueForKey('documentNumber');
    }
    set documentNumberPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentNumber');
    }
    get documentNumberPrimitiveValue():string {
        return this.primitiveValueForKey('documentNumber');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: totalAmount
    set totalAmount(value:number) {
        this.setValueForKey(value, 'totalAmount');
    }
    get totalAmount():number {
        return this.valueForKey('totalAmount');
    }
    set totalAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalAmount');
    }
    get totalAmountPrimitiveValue():number {
        return this.primitiveValueForKey('totalAmount');
    }

    // Relationship: cashDeskLines
    protected _cashDeskLines:MIOManagedObjectSet = null;
    get cashDeskLines():MIOManagedObjectSet {
        return this.valueForKey('cashDeskLines');
    }
    addCashDeskLinesObject(value:CashDeskLine) {
        this._addObjectForKey(value, 'cashDeskLines');
    }
    removeCashDeskLinesObject(value:CashDeskLine) {
        this._removeObjectForKey(value, 'cashDeskLines');
    }
    // Relationship: legalEntity
    set legalEntity(value:LegalEntity) {
        this.setValueForKey(value, 'legalEntity');
    }
    get legalEntity():LegalEntity {
        return this.valueForKey('legalEntity') as LegalEntity;
    }

    // Relationship: lines
    protected _lines:MIOManagedObjectSet = null;
    get lines():MIOManagedObjectSet {
        return this.valueForKey('lines');
    }
    addLinesObject(value:DBDocumentLine) {
        this._addObjectForKey(value, 'lines');
    }
    removeLinesObject(value:DBDocumentLine) {
        this._removeObjectForKey(value, 'lines');
    }
    // Relationship: nextDocument
    set nextDocument(value:DBDocument) {
        this.setValueForKey(value, 'nextDocument');
    }
    get nextDocument():DBDocument {
        return this.valueForKey('nextDocument') as DBDocument;
    }
    // Relationship: numberSerial
    set numberSerial(value:NumberSerialSequence) {
        this.setValueForKey(value, 'numberSerial');
    }
    get numberSerial():NumberSerialSequence {
        return this.valueForKey('numberSerial') as NumberSerialSequence;
    }

    // Relationship: prevDocuments
    protected _prevDocuments:MIOManagedObjectSet = null;
    get prevDocuments():MIOManagedObjectSet {
        return this.valueForKey('prevDocuments');
    }
    addPrevDocumentsObject(value:DBDocument) {
        this._addObjectForKey(value, 'prevDocuments');
    }
    removePrevDocumentsObject(value:DBDocument) {
        this._removeObjectForKey(value, 'prevDocuments');
    }
}
