//
// Generated class IntegratorSAPStockNoteLine
//

/// <reference path="IntegratorSAPStockNoteLine_ManagedObject.ts" />

class IntegratorSAPStockNoteLine extends IntegratorSAPStockNoteLine_ManagedObject
{

}
