

class DBToolSummaryViewController extends MUIViewController
{    
    private nameTextField:MUITextField = null;
    private connectionCombox:MUIComboBox = null;
    private addButton:MUIButton = null;
    
    private tableView:MUITableView = null; 

    viewDidLoad(){
        super.viewDidLoad();

        this.nameTextField = MUIOutlet(this, 'name-tf', 'MUITextField');
        this.nameTextField.setOnChangeText(this, function(control, value){
            this._script.name = value;
        });
        
        // this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        // this.addButton.setAction(this, function(){
        //     this.addFilter();
        // });

        this.tableView = MUIOutlet(this, 'tableview', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    private filters = [];
    private _script:DBScript = null;
    get script():DBScript {
        return this._script;
    }
    set script(script:DBScript){
        this._script = script;

        if (script == null) {
            this.nameTextField.text = "";            
            this.filters = [];
            
        }
        else {
            this.nameTextField.text = script.name;            
            this.filters = script.filters.sortedArrayUsingDescriptors([MIOSortDescriptor.sortDescriptorWithKey("type", false)]);
        }

        this.tableView.reloadData();
    }
    
    numberOfSections(tableview){
        return 1;
    }
    
    numberOfRowsInSection(tableview, section){
        return this.filters.length + 1;
    }
    
    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('DBToolCell') as DBToolCell;

        if (indexPath.row < this.filters.count){
            cell.filter = this.filters.objectAtIndex(indexPath.row);
        }
    
        return cell;
    }

    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {
        if (indexPath.row < this.filters.count){
            return MUITableViewCellEditingStyle.Delete;
        }
        else {
            return MUITableViewCellEditingStyle.Insert;
        }
    }

    commitEditingStyleForRowAtIndexPath(tableView:MUITableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {        

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            let item = this.filters.objectAtIndex(indexPath.row);
            this.removeFilter(item);
        }
        else if (editingStyle == MUITableViewCellEditingStyle.Insert) {
            let cell = tableView.cellAtIndexPath(indexPath);
            this.addFilterWithValues(cell.values);
        }
    }
        
    private addFilterWithValues(values){
        let moc:MIOManagedObjectContext = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        let filter = MIOEntityDescription.insertNewObjectForEntityForName("DBFilter", moc) as DBFilter;
        filter.key = values["Key"];
        filter.dataType = values["DataType"];
        filter.title = values["Title"];
        filter.type = values["Type"];
        filter.script = this._script;
        this._script.addFiltersObject(filter);
        moc.save();

        this.filters = this._script.filters.sortedArrayUsingDescriptors([MIOSortDescriptor.sortDescriptorWithKey("type", false)]);

        this.tableView.reloadData();
    }

    private removeFilter(filter:DBFilter){        
        this._script.removeFiltersObject(filter);

        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext as MIOManagedObjectContext;
        moc.deleteObject(filter);
        moc.save();

        this.tableView.reloadData();
    }
}