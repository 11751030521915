
class DBSyncTransactionsDataSource extends MIOObject
{
    dateFrom:string = null;
    dateTo:string = null;

    private tableView:UITableView = null;    
    private headerView:DBSynEntityHeaderView = null;

    initWithTableView(tableView:UITableView, headerView:DBSynEntityHeaderView){
        this.tableView = tableView;
        this.headerView = headerView;
        this.headerView.hidden = true;
    }    
    
    private config:IntegratorConfig = null; 
    setConfigValues(config:IntegratorConfig){
        this.config = config;
        this.invalidateData();
    }
    
    invalidateData(){
        this.fetchedResultsController = null;
    }

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){        
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    viewForHeaderInSection(tableview:UITableView, section){
        if (this.headerView == null) return null;

        let h = this.headerView.copy() as DBSynEntityHeaderView;
        h.awakeFromHTML();
        h.hidden = false;

        let item = this.fetchedResultsController.objectAtIndexPath(MIOIndexPath.indexForRowInSection(0, section)) as DBSyncEntity;

        h.item = item;

        return h;
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("SyncCell") as DBSyncEntityCell;
        cell.syncType = DBSyncEntityCellType.transaction;

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as IntegratorRawQueue;
        cell.item = item;

        return cell;
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value:MIOFetchedResultsController){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){                
        if (this._fetchedResultsController != null) return this._fetchedResultsController;            

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
    
        let predicateFormat = "integratorConfig.identifier = " + this.config.identifier + " AND integratorStatus in [-1,2] AND integratorTransactionID != null";
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName(this.config.queueEntityName);
        fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("integratorDate", false)];
        fetchRequest.relationshipKeyPathsForPrefetching = ["integratorSync"];
        fetchRequest.fetchLimit = 100;
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "integratorDateString");
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }
}