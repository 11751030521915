//
// Generated class SalesInvoiceTemplate
//

/// <reference path="SalesInvoiceTemplate_ManagedObject.ts" />

class SalesInvoiceTemplate extends SalesInvoiceTemplate_ManagedObject
{

}
