//
// Generated class DBMappingEntityWorker
//

/// <reference path="DBMappingEntityWorker_ManagedObject.ts" />

class DBMappingEntityWorker extends DBMappingEntityWorker_ManagedObject
{

}
