//
// Generated class WarehouseOrderLine
//

/// <reference path="WarehouseOrderLine_ManagedObject.ts" />

class WarehouseOrderLine extends WarehouseOrderLine_ManagedObject
{

}
