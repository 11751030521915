class DBTCPPrinterCoder extends DBXLSXCoder
{
    mainEntity() {
        return { entity: "TCPPrinter", predicateFormat: "deletedAt == null" }
    }

    columns() : any[] {
        let cols = [
            { title:"PRINTER ID", width: "10%", align: "center" },
            { title:"PRINTER NAME", width: "10%", align: "center" },
            { title:"PRINTER HOST", width: "10%", align: "center" },
            { title:"PRINTER PORT", width: "10%", align: "center", formatter: this.ad.integerFormatter},
            { title:"APP ID", width: "10%", align: "center" },
            { title:"CATEGORY TAG KITCHEN ORDER", width: "20%", align: "center", formatter: this.ad.integerFormatter },            
            { title:"BEEPER OPENS CASHDESK", width: "20%", align: "center" },
            { title:"CODE PAGE", width: "20%", align: "center", formatter: this.ad.integerFormatter },
            { title:"ENABLE BEEPER", width: "20%", align: "center" },
            { title:"FILTER SUMMARIES BY PRINT PROFILE", width: "20%", align: "center", formatter: this.ad.integerFormatter },
            { title:"GROUP DIFFERENT SOURCES", width: "20%", align: "center" },            
            { title:"NUMBER OF CHARS", width: "20%", align: "center", formatter: this.ad.integerFormatter },
            { title:"OVERRIDE CATEGORY TAG KITCHEN ORDER", width: "20%", align: "center" },
            { title:"PRINTER MODE", width: "20%", align: "center", formatter: this.ad.integerFormatter },
            { title:"STRING ENCODING", width: "20%", align: "center", formatter: this.ad.integerFormatter },            
            { title:"TYPE", width: "20%", align: "center", formatter: this.ad.integerFormatter },
            { title:"USER SLIP PRINTER", width: "20%", align: "center" }
        ]
        return cols;
    }

    protected importRow(row:any){
                
        let prnID = row["PRINTER ID"];
        let prnName = row["PRINTER NAME"];
        let host = row["PRINTER HOST"];
        let port = row["PRINTER PORT"];
        let appID = row["APP ID"];
        let kitchenOrder = row["CATEGORY TAG KITCHEN ORDER"];
        let openCash = row["BEEPER OPENS CASHDESK"];
        let code = row["CODE PAGE"];
        let enableBeeper = row["ENABLE BEEPER"];
        let filterSummaries = row["FILTER SUMMARIES BY PRINT PROFILE"];
        let group = row["GROUP DIFFERENT SOURCES"];
        let chars = row["NUMBER OF CHARS"];
        let override = row["OVERRIDE CATEGORY TAG KITCHEN ORDER"];
        let prnMode = row["PRINTER MODE"];
        let encoding = row["STRING ENCODING"];
        let type = row["TYPE"];
        let usesSlipPrinter = row["USER SLIP PRINTER"];
        
        if (appID == null) { return; }
        if (prnName == null || prnName?.length == 0) { return; }
                
        let prn = this.queryEntityByField("TCPPrinter", "identifier", prnID) as TCPPrinter;
        if  (prn != null) prn = this.queryEntity("TCPPrinter", "name = '" + prnName + "' and appID = '" + appID.toUpperCase() + "") as TCPPrinter;
        if (prn == null) {
            prn = MIOEntityDescription.insertNewObjectForEntityForName("TCPPrinter", DBHelper.mainManagedObjectContext) as TCPPrinter;
            prn.identifier = this.parseOrCreateIdentifier(prnID);
            this.appendObject(prn);
        }
            
        prn.name = prnName;
        prn.host = host;
        prn.port = port;
        prn.appID = appID;
        prn.categoryTagKitchenOrderPrintOption = kitchenOrder;
        prn.beeperOpensCashdesk = this.parseBoolValue( openCash );
        prn.codePage = code;
        prn.enableBeeper = this.parseBoolValue( enableBeeper );
        prn.filterSummariesByPrintProfile = filterSummaries;
        prn.groupDifferentSources = this.parseBoolValue( group );
        prn.numberOfChars = chars;
        prn.overrideCategoryTagKitchenOrderPrintOption = this.parseBoolValue( override );
        prn.printerMode = prnMode;
        prn.stringEncoding = encoding;
        prn.type = type;
        prn.useSlipPrinter = this.parseBoolValue( usesSlipPrinter );
        
        MIOLog("ADDING PRINTER: " + this.rowIndex + "/" + this.rows.length + ": " + prnName);
    }

    protected exportTitle() : string { return "TCP Printers"; }

    protected exportSortDescriptors() { 
        return [MIOSortDescriptor.sortDescriptorWithKey("name", false)]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let prn = object as TCPPrinter;

        let item = {
            "PRINTER ID": prn.identifier,
            "PRINTER NAME": prn.name,
            "PRINTER HOST": prn.host,
            "PRINTER PORT": prn.port,
            "APP ID": prn.appID,
            "CATEGORY TAG KITCHEN ORDER": prn.categoryTagKitchenOrderPrintOption,
            "BEEPER OPENS CASHDESK": prn.beeperOpensCashdesk,
            "CODE PAGE": prn.codePage,
            "ENABLE BEEPER": prn.enableBeeper,
            "FILTER SUMMARIES BY PRINT PROFILE": prn.filterSummariesByPrintProfile,
            "GROUP DIFFERENT SOURCES": prn.groupDifferentSources,
            "NUMBER OF CHARS": prn.numberOfChars,
            "OVERRIDE CATEGORY TAG KITCHEN ORDER": prn.overrideCategoryTagKitchenOrderPrintOption,
            "PRINTER MODE": prn.printerMode,
            "STRING ENCODING": prn.stringEncoding,
            "TYPE": prn.type,
            "USER SLIP PRINTER": prn.useSlipPrinter
        };

        return item;
    }
}