//
// Generated class StockTemplateStockCategoryItem
//

/// <reference path="StockTemplateStockCategoryItem_ManagedObject.ts" />

class StockTemplateStockCategoryItem extends StockTemplateStockCategoryItem_ManagedObject
{
    get typeString() {
        return MIOLocalizeString("STOCK_TEMPLATE_STOCK_CATEGORY_ITEM","STOCK_TEMPLATE_STOCK_CATEGORY_ITEM");
    }
}
