
/// <reference path="../../node_modules/@types/googlemaps/index.d.ts" />
class MapsViewController extends MUIViewController
{
    private _gmap             = null;
    private _backButton       = null;
    private _saveButton       = null;
    private _searchTextField  = null;
    private _addressTextField = null;
    private _infoWindow       = null;
    private _marker           = null;
    private _searchString     = null;
    
    private _selectedMarker   = {};
    private _position         = null;
    private _mapOptions       = {};

    delegate = null;

    get preferredContentSize()
    {
        return new MIOSize(400, 600);
    }

    viewDidLoad()
    {
        super.viewDidLoad();
        //let google = new Google();
        this._mapOptions = {
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: true,
            zoom: 8,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: true,
            streetViewControl: false,
            rotateControl: false
        };

        this._addressTextField = MUIOutlet(this, 'mv_address_lbl', 'MUILabel');

        this._searchTextField = MUIOutlet(this, 'mv_c1_search_bar', 'MUITextField');
        this._searchTextField.setOnChangeText(this, function (textfield, value) 
        {

            clearTimeout(this.delayTimer);
            var instance = this;

            instance.delayTimer = setTimeout(function() {
                
                var geocoder = new google.maps.Geocoder();
                // Hacemos la petición indicando la dirección e invocamos la función
                // geocodeResult enviando todo el resultado obtenido
                var address = value;
                geocoder.geocode({ 'address': address,  "region": "ES"}, function(results,status){
                 
                    if (status == google.maps.GeocoderStatus.OK) {

                        //Decode street short_name
                        var position = results[0].geometry.location;
                        instance._mapOptions['center'] = position;

                        instance._getAddressFromPosition(instance, position);
                        // var mapdiv = document.getElementById("map");
                        instance._gmap.setOptions(instance._mapOptions);
                        // fitBounds acercará el mapa con el zoom adecuado de acuerdo a lo buscado
                        instance._gmap.fitBounds(results[0].geometry.viewport);

                        //Display address
                        instance._displayAddress(results[0].formatted_address, results[0].address_components);
                        // Dibujamos un marcador con la ubicación del primer resultado obtenido
                        //let google = new Google();
                        instance._infoWindow = new google.maps.InfoWindow();
                        instance._marker = new google.maps.Marker({
                            position: position,
                            draggable: true,
                            map: this._gmap,
                            title:"Place"
                        });
                        instance._getCoords(instance._marker);
                        instance._marker.setMap(instance._gmap);

                        google.maps.event.addListener(instance._marker, 'click', function()
                        {
                            instance._openInfoWindow(instance._marker);
                        });

                        google.maps.event.addListener(instance._marker, "dragend", function() 
                        { 
                            instance._getAddressFromPosition(instance, instance._marker.getPosition());
                        });
                        
                     }
                     else {
                        // En caso de no haber resultados o que haya ocurrido un error
                        // lanzamos un mensaje con el error
                        alert(MIOLocalizeString("GEOCODING WAS NOT SUCCESSFUL DUE TO","Geocoding was not successful due to: ") + status);
                     }
              });

            }, 1000, instance);
        });

        this._backButton = MUIOutlet(this, 'mv_nb_back_btn', 'MUIButton');
        this._backButton.setAction(this, function()
        {
            this.navigationController.popViewController(true);
        });
        this._saveButton = MUIOutlet(this, 'mv_nb_save_btn', 'MUIButton');
        this._saveButton.setAction(this, function()
        {
            if(this.delegate) {
                this.delegate.geocodeDidChange(this._selectedMarker);
            }
            this.navigationController.popViewController(true);
        });
    }

    viewDidAppear(animate?)
    {
        super.viewDidAppear(animate)
        //let google = new Google();
        var mapLayer = document.getElementById('mv_google_map');
        if(!this._position)
            this._position = new google.maps.LatLng(-34.397,150.644);

        this._searchTextField.text = '';

        this._mapOptions['center'] = this._position;
        this._gmap = new google.maps.Map(mapLayer, this._mapOptions);
        this._marker = new google.maps.Marker({
            position: this._position,
            draggable: true,
            map: this._gmap,
            title:"Place"
        });

    }

    viewWillDisappear()
    {
        super.viewWillDisappear();

        this._gmap = null;
    }    

    public initWithCoordenates(lat, lng)
    {
      //  let google = new Google();
        this._position = new google.maps.LatLng(lat, lng);
    }

    private _openInfoWindow(marker) {
        console.log('openWindow');
        var markerLatLng = marker.getPosition();
        this._infoWindow.setContent([
            'La posicion del marcador es:',
            markerLatLng.lat(),
            ', ',
            markerLatLng.lng(),
            'Arrástrame.'
        ].join(''));
        this._infoWindow.open(this._gmap, marker);
    }

    private _getCoords(marker)
    {
        var markerLatLng = marker.getPosition();
        this._selectedMarker['lat']  = markerLatLng.lat();
        this._selectedMarker['lng'] = markerLatLng.lng();
        console.log('Lat: ' + this._selectedMarker['lat']+ ' Lng: ' + this._selectedMarker['lng']);        
    }

    private _displayAddress(address, addressComponents)
    {   
        this._selectedMarker['address'] =  [
                        (addressComponents[1] && addressComponents[1].short_name || ''),
                        (addressComponents[0] && addressComponents[0].short_name || '')].join(' ');   
        this._selectedMarker['city'] =  (addressComponents[2] && addressComponents[2].short_name || '');
        this._selectedMarker['state'] =  (addressComponents[3] && addressComponents[3].long_name || '');                     
        this._selectedMarker['country'] = (addressComponents[5] && addressComponents[5].long_name || '');   
        this._selectedMarker['postalCode'] = (addressComponents[6] && addressComponents[6].long_name || '');                   
        this._selectedMarker['countryCode'] = (addressComponents[5] && addressComponents[5].short_name || '');
        this._addressTextField.text = this._selectedMarker['address'] + ' ' + this._selectedMarker['city'] + ' ' + this._selectedMarker['postalCode'] + ' ' + this._selectedMarker['state'] + ' ' +this._selectedMarker['country'] + '('+this._selectedMarker['countryCode']+')';
    }

    private _getAddressFromPosition(instance, position) {
       // let google = new Google();
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: position }, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {                    
                instance._getCoords(instance._marker); 
                instance._displayAddress(results[0].formatted_address, results[0].address_components);
            }
        });
    }
}