class StockExistenceCell extends MUITableViewCell
{
    dateLabel       = null;
    expirationLabel = null;
    priceLabel      = null;
    containersLabel = null;
    quantityLabel   = null;
    extradataLabel  = null;


    private _separator = null;

    awakeFromHTML()
    {
        this.dateLabel       = MUIOutlet(this, 'sedv_tv_date_title', 'MUILabel');
        this.expirationLabel = MUIOutlet(this, 'sedv_tv_expiration_title', 'MUILabel');
        this.priceLabel      = MUIOutlet(this, 'sedv_tv_total_unit_price_title','MUILabel');
        this.quantityLabel   = MUIOutlet(this, 'sedv_tv_quantity_title','MUILabel');
        this.containersLabel = MUIOutlet(this, 'sedv_tv_containers_title','MUILabel');
        this.extradataLabel  = MUIOutlet(this, 'sedv_tv_extra_data_title','MUILabel');
       
        this._separator      = MUIOutlet(this, 'sedv_tv_separator', 'MUIView');
    }
   setSelected(value)
    {
        super.setSelected(value);
        if (value == true)
            this._separator.setBackgroundRGBColor(0, 191, 191);
        else
            this._separator.setBackgroundRGBColor(237, 237, 242);
    }
    set item(i)
    {
        this.dateLabel.text = i.date;
        this.expirationLabel.text = i.expirationdate;
        this.priceLabel.text = i.cost/i.originalquantity;
        this.quantityLabel.text = i.originalquantity + ' / ' + i.quantity;
        this.containersLabel.text='';
        this.extradataLabel = '';
    }
}
