

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ProductMenu_ManagedObject

class ProductMenu_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: automaticInsertion
    set automaticInsertion(value:boolean) {
        this.setValueForKey(value, 'automaticInsertion');
    }
    get automaticInsertion():boolean {
        return this.valueForKey('automaticInsertion');
    }
    set automaticInsertionPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'automaticInsertion');
    }
    get automaticInsertionPrimitiveValue():boolean {
        return this.primitiveValueForKey('automaticInsertion');
    }

    // Property: idIn
    set idIn(value:number) {
        this.setValueForKey(value, 'idIn');
    }
    get idIn():number {
        return this.valueForKey('idIn');
    }
    set idInPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'idIn');
    }
    get idInPrimitiveValue():number {
        return this.primitiveValueForKey('idIn');
    }

    // Property: modifyMenuPrice
    set modifyMenuPrice(value:string) {
        this.setValueForKey(value, 'modifyMenuPrice');
    }
    get modifyMenuPrice():string {
        return this.valueForKey('modifyMenuPrice');
    }
    set modifyMenuPricePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'modifyMenuPrice');
    }
    get modifyMenuPricePrimitiveValue():string {
        return this.primitiveValueForKey('modifyMenuPrice');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: productMenuCount
    set productMenuCount(value:number) {
        this.setValueForKey(value, 'productMenuCount');
    }
    get productMenuCount():number {
        return this.valueForKey('productMenuCount');
    }
    set productMenuCountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productMenuCount');
    }
    get productMenuCountPrimitiveValue():number {
        return this.primitiveValueForKey('productMenuCount');
    }
    // Relationship: menuCategory
    set menuCategory(value:MenuCategory) {
        this.setValueForKey(value, 'menuCategory');
    }
    get menuCategory():MenuCategory {
        return this.valueForKey('menuCategory') as MenuCategory;
    }

    // Relationship: menuLines
    protected _menuLines:MIOManagedObjectSet = null;
    get menuLines():MIOManagedObjectSet {
        return this.valueForKey('menuLines');
    }
    addMenuLinesObject(value:MenuProductLine) {
        this._addObjectForKey(value, 'menuLines');
    }
    removeMenuLinesObject(value:MenuProductLine) {
        this._removeObjectForKey(value, 'menuLines');
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
    // Relationship: productFormat
    set productFormat(value:ProductFormat) {
        this.setValueForKey(value, 'productFormat');
    }
    get productFormat():ProductFormat {
        return this.valueForKey('productFormat') as ProductFormat;
    }

    // Relationship: productModifiers
    protected _productModifiers:MIOManagedObjectSet = null;
    get productModifiers():MIOManagedObjectSet {
        return this.valueForKey('productModifiers');
    }
    addProductModifiersObject(value:ProductModifier) {
        this._addObjectForKey(value, 'productModifiers');
    }
    removeProductModifiersObject(value:ProductModifier) {
        this._removeObjectForKey(value, 'productModifiers');
    }
}
