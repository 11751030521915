


class ReplicaConfigDataSource extends MIOObject
{
    private tableView:UITableView = null;
    private configs = [
        {"key": "products", "name":"PRODUCTS"},
        {"key": "product_modifiers", "name":"PRODUCT MODIFIERS"},
        {"key": "rates", "name":"RATES"},
        {"key": "invitations", "name":"INVITATIONS"},
        {"key": "worker_roles", "name":"WORKER ROLES"}, 
        {"key":"suppliers", "name":"SUPPLIERS"}, 
        {"key":"clients", "name": "CLIENTS"} 
        // {"key":"archived_documents", "name":"ARCHIVED DOCUMENTS"},
        // {"key":"cashdesks", "name":"CASHDESKS"},         
        // {"key":"stock_notes", "name":"STOCK NOTES"}
    ];    

    initWithTableView(tableView:UITableView){
        this.tableView = tableView;
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        this.tableView.reloadData();
    }

    private _place:Place = null;
    set item( place: Place ) {
        this._place = place;
        this.fetchedResultsController = null;
        this.map_config( this.fetchedResultsController.fetchedObjects);        
        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView){
        return 1;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        return this.configs.count;
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {
        let cell = tableView.dequeueReusableCellWithIdentifier('PlaceTagCell') as PlaceTagCell;
        let item = this.configs[indexPath.row];
        cell.item = item;
        
        cell.config = this.tags[ item[ "key" ] ];

        return cell;
    }

    didSelectCellAtIndexPath( tableView:UITableView, indexPath: MIOIndexPath ) {
        let item = this.configs[indexPath.row];
        
        let config = this.tags[ item[ "key" ] ];

        if (config == null) {
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            let c = MIOEntityDescription.insertNewObjectForEntityForName( "ReplicaConfig", ad.managedObjectContext) as ReplicaConfig;
            c.tags = item[ "key" ];
            c.targetSchema = this._place.identifier;
            DBHelper.saveMainContext();
        }
        else {
            DBHelper.deleteObjectFromMainContext( config, true );
        }
    }   

    protected _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController( value:MIOFetchedResultsController ) {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

        // if (ad.selectedIdentifierType == "place") predicateFormat += " AND placeID == '" + ad.selectedIdentifier + "'";
        // if(this.searchString != null) {
        //     predicateFormat += " AND documentID CONTAINS '" + this.searchString + "'";
		// }
		
		// if (this.filterPredicateFormat != null) {
        //     predicateFormat += " AND " + this.filterPredicateFormat;
        // }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName( "ReplicaConfig", sortDescriptors, "targetSchema = " + this._place.identifier );
        fetchRequest.fetchLimit = 100;
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        this.map_config( controller.fetchedObjects);        
        this.tableView.reloadData();
    }

    private tags = {};
    private map_config( objects: ReplicaConfig[]) {
        for (let c of objects) {
            this.tags[c.tags] = c;
        }
        this.tableView.reloadData();
    }



}