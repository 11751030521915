

class StockExistencesDetailViewController extends MUIViewController
{
    private _tableView = null;

    private _warehouse = null;
    private _product   = null;

    private _fetchedResultsController = null;
    
    viewDidLoad()
    {
        super.viewDidLoad();    

        this._tableView = MUIOutlet(this, 'sedv_tableview', 'MUITableView');
        this._tableView.dataSource = this;
        this._tableView.delegate = this;
       // this._updateUI();
        
    }

    set warehouse(value)
    {
        this._warehouse = value;
        this._updateUI();
    }

    set product(value)
    {
        this._product = value;
        this._updateUI();
    }

    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
        this._updateUI();
    }

    private _updateUI()
    {
        if(this.viewIsLoaded && this._product != null) {
            this.fetchedResultsController = null;
            this._tableView.reloadData();
        }
    }

    numberOfSections(tableview)
    {
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section)
    {
        var sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath)
    {
        var cell = tableview.dequeueReusableCellWithIdentifier('StockExistenceCell');
    
        var item = this.fetchedResultsController.objectAtIndexPath(indexPath);
    
        cell.item = item;
    
        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
    
        return cell;
    }
    
    canSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath)
    {
        return true;
    }
    
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath)
    {
        var obj = this.fetchedResultsController.objectAtIndexPath(indexPath);    
    }
    
    set fetchedResultsController(value)
    {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController()
    {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        var ad = MUIWebApplication.sharedInstance().delegate;
    
        var fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('StockExistence');
    
        fetchRequest.sortDescriptors = [ /*MIOSortDescriptor.sortDescriptorWithKey('categoryName', true),*/
                                            MIOSortDescriptor.sortDescriptorWithKey('date', true)];
    
        let filter = [];
        
        if(this._product != null)
            filter.push('product == ' + this._product.identifier);
        if(this._warehouse != null)
            filter.push('warehouse == ' + this._warehouse.identifier);

        if(filter.length > 0) {
            fetchRequest.predicate = MIOPredicate.predicateWithFormat(filter.join(' and '));
        }    

        var fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller)
    {
        this._tableView.reloadData();
    }
}