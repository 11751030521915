



class ModifierPriceViewController extends MUIViewController
{

    private okButton:MUIButton = null;
    private removeButton:MUIButton = null;
    private addSubSegmented:MUISegmentedControl = null;
    private quantityTextField:MUITextField = null;
    private percentageCheckButton:MUISwitchButton = null;

    private _add = true;
    private _quantity = null;
    private _percentage = false;

    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;
    
    delegate = null;

    get preferredContentSize()
    {
        return new MIOSize(300, 300);
    }

    viewDidLoad()
    {
        super.viewDidLoad();

        this.okButton = MUIOutlet(this, 'mpv_nb_ok_btn', 'MUIButton');
        this.okButton.setAction(this, function () {

           this.dismissViewController(true);

        });

        this.removeButton = MUIOutlet(this, 'mpv_c3_delete_btn', 'MUIButton');
        this.removeButton.setAction(this, function(){
            this.dismissViewController(true);
        });

        this.quantityTextField = MUIOutlet(this, 'mpv_b1_quantity_box', 'MUITextField');
        this.quantityTextField.formatter = this.nf;

        this.percentageCheckButton = MUIOutlet(this, 'mpv_b2_percentage_switch_btn', 'MUISwitchButton');
        this.percentageCheckButton.setOnChangeValue(this, function (value) {
            this._percentage = value.on;
        });

        this.addSubSegmented = MUIOutlet(this, 'mpv_segmented_control', 'MUISegmentedControl');
        this.addSubSegmented.setAction(this, function (control, index) {

            this._add = index == 0;

        });
    }

    viewWillAppear(animate?) {

        super.viewWillAppear(animate);
        this._updateUI();
    }


    private _updateUI()
    {
        this.quantityTextField.text = null;
        this.addSubSegmented.selectSegmentedAtIndex(0);
        this.percentageCheckButton.setOn(this._percentage);
    }

}