//
// Generated class FixedDiscount
//

/// <reference path="FixedDiscount_ManagedObject.ts" />

class FixedDiscount extends FixedDiscount_ManagedObject
{

}
