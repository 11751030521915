
class ProductFormatRatesCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    private basePriceLabel:MUILabel = null;
    private specificRateTextfield:MUITextField = null;

    private ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;
    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter as MIONumberFormatter;

    awakeFromHTML()
    {
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.basePriceLabel = MUIOutlet(this, "base-price-lbl", "MUILabel");

        this.specificRateTextfield = MUIOutlet(this, "specific-rate-tf", "MUITextField");
        this.specificRateTextfield.formatter = this.nf;
        this.specificRateTextfield.setOnChangeText(this, function(control, value:number){
          
            if(this._productFormatRate && value) {
                this._productFormatRate.price = value;
            } else if (this._productFormatRate && !value) {
                this.ad.managedObjectContext.deleteObject(this._productFormatRate);
            } else {
                this._productFormatRate = MIOEntityDescription.insertNewObjectForEntityForName("ProductFormatRate", this.ad.managedObjectContext) as ProductFormatRate;
                this._productFormatRate.identifier = new MIOUUID().UUIDString;
                this._productFormatRate.productFormat = this._productFormat;
                this._productFormatRate.rate = this._rate;
                this._productFormatRate.price = value;
            }

                // ad.managedObjectContext.save();
        });
    }

    private _productFormat:ProductFormat = null;
    set item(i:ProductFormat){
        this._productFormat = i;
        this.nameLabel.text = i.format.name;
        this.basePriceLabel.text = this.cf.stringFromNumber(i.price);
    }

    private _rate:Rate = null;
    set rate(r:Rate){
        this._rate = r;
    }

    private _productFormatRate:ProductFormatRate = null;
    set productFormatRate(j:ProductFormatRate){
        this._productFormatRate = j;
        this.specificRateTextfield.text = this.cf.stringFromNumber(j.price);
    }

}