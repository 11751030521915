class SyncLogCell extends UITableViewCell
{
    private indexLabel:MUILabel = null;
    private statusLabel:MUILabel = null;
    private errorLabel:MUILabel = null;
    private appLabel:MUILabel = null;
    // private fileLabel:MUILabel = null;

    private in = MUIWebApplication.sharedInstance().delegate.integerFormatter as MIONumberFormatter;

    awakeFromHTML(){        
        this.indexLabel = MUIOutlet(this, 'index-lbl', 'MUILabel');
        this.statusLabel = MUIOutlet(this, 'status-lbl', 'MUILabel');
        this.errorLabel = MUIOutlet(this, 'error-lbl', 'MUILabel');
        this.appLabel = MUIOutlet(this, 'app-lbl', 'MUILabel');
        // this.fileLabel = MUIOutlet(this, 'file-lbl', 'MUILabel');
    }

    set item(i:SyncLog){
        this.indexLabel.text = i.orderIndex.toString() ;

        switch(i.status){
            case 0: this.statusLabel.text = "Not synced"; break;
            case 1: this.statusLabel.text = "Sync in process"; break;
            case 2: this.statusLabel.text = "Synchronized"; break;
            case -1: this.statusLabel.text = "Error"; break;
        }

        this.errorLabel.text = i.error
        this.appLabel.text = i.appID;
        // this.fileLabel.text = i.fileURL;

    }
}
