

class VoiceRecordingViewController extends MUIViewController {
    private closeButton: MUIButton = null;
    private startRecordingButton: MUIButton = null;
    private stopRecordingButton: MUIButton = null;

    get preferredContentSize() { return new MIOSize(320, 400); }
    private voices = speechSynthesis.getVoices();

    viewDidLoad() {
        
        this.closeButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.closeButton.setAction(this, function () {
            this.dismissViewController(true);
        });

        this.startRecordingButton = MUIOutlet(this, "record-start-btn", "MUIButton");
        this.startRecordingButton.setAction(this, this.startRecording);

        this.stopRecordingButton = MUIOutlet(this, "record-stop-btn", "MUIButton");
        this.stopRecordingButton.setAction(this, this.stopRecording);
    }

    private stopRecording() {
        this.shouldStop = true;
    }

    private stopped = false;
    private shouldStop = false;
    private startRecording() {

        //this.text2Speech("¿En que te puedo ayudar?");

        let _instance = this;
        const _handleSuccess = function (stream) {
            _instance.handleSuccess.call(_instance, stream);
        }

        navigator.mediaDevices.getUserMedia({ audio: true, video: false })
            .then(_handleSuccess);
    }

    private mediaRecorder = null;
    private recordedChunks = [];
    private handleSuccess = function (stream) {
        const options = { mimeType: 'audio/webm' }; 
        //const options = { mimeType: 'audio/wav' };

        // this.mediaRecorder = new MediaRecorder(stream, options);

        // let _instance = this;
        // this.mediaRecorder.addEventListener('dataavailable', function (e) {
        //     MIOLog("DATA INCOMING!!!");
        //     if (e.data.size > 0) {
        //         _instance.recordedChunks.push(e.data);
        //     }

        //     if (_instance.shouldStop === true && _instance.stopped === false) {
        //         _instance.mediaRecorder.stop();
        //         _instance.stopped = true;
        //     }
        // });

        this.mediaRecorder.onerror = function (e) {
            MIOLog("ERROR!!!");
            MIOLog(e);
        }

        this.mediaRecorder.onstart = function (e) {
            MIOLog("STARTED RECORDING!!!");
            MIOLog(e);
        }

        this.mediaRecorder.addEventListener('stop', function () {
            // const downloadLink = document.getElementById('download');
            // downloadLink.href = URL.createObjectURL(new Blob(_instance.recordedChunks));
            // downloadLink.download = 'acetest.wav';
            // _instance.text2Speech("Grabación parada.");
            // _instance.sendAudio(new Blob(_instance.recordedChunks, { type: "application/octet-stream"}));

        });

        this.mediaRecorder.start(1000);
    };

    private _voice: SpeechSynthesisVoice = null;
    private get voice(): SpeechSynthesisVoice {
        if (this._voice != null) return this._voice;

        let voices = speechSynthesis.getVoices();
        for (let i = 0; i < voices.length; i++) {
            if (voices[i].name == "Monica") {
                return voices[i];
            }
        }
    }
    private text2Speech(text: string) {

        let ssu = new SpeechSynthesisUtterance();
        ssu.lang = "es-ES"
        if (this.voice != null) ssu.voice = this.voice;

        ssu.text = text;
        speechSynthesis.speak(ssu);
    }

    private sendAudio(blob:Blob) {

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let scriptURL = ad.webService.scriptURL;
        let identifier = ad.selectedIdentifier;
        let identifierType = ad.selectedIdentifierType;
        
        let url = scriptURL + "/voice/" + identifierType + "/" + identifier + "/speechrecognizer";        

        let oReq = new XMLHttpRequest();
        oReq.open("POST", url, true);
        oReq.onload = function (oEvent) {
            // Uploaded.
        };

        //var blob = new Blob(['abc123'], { type: 'text/plain' });

        oReq.send(blob);
    }

}