//
// Generated class NumberSerialSequence
//

/// <reference path="NumberSerialSequence_ManagedObject.ts" />

class NumberSerialSequence extends NumberSerialSequence_ManagedObject
{

}
