
class GuestCell extends MUITableViewCell
{        
    private nameTextField:MUITextField   = null;
    private nameLabel:MUILabel           = null;    
    private paxTextField:MUITextField    = null;    
    private paxAttendLabel:MUILabel      = null;
    
    private recommendedButton:MUIButton  = null;
    private listTypeButton:MUIButton        = null; 
    private sourceButton:MUIButton          = null;
    private commentsTextField:MUITextField  = null;

    awakeFromHTML()
    {
        this.nameTextField      = MUIOutlet(this, "guest-list-tf", 'MUITextField');
        this.nameLabel          = MUIOutlet(this, "name-lbl", "MUILabel");
        this.paxTextField       = MUIOutlet(this, "pax-tf", "MUITextField");
        this.paxAttendLabel     = MUIOutlet(this, "attend-pax-lbl", 'MUILabel');
        // this.recommendedButton      = MUIOutlet(this, 'gv_tv_hour_lbl','MUILabel');
        // this.listTypeButton  = MUIOutlet(this, "list-lbl", 'MUILabel');
        // this.sourceButton    = MUIOutlet(this, "source-lbl", 'MUILabel');
        this.commentsTextField  = MUIOutlet(this, "comments-tf", 'MUITextField');
        
    }

    set item(i:BookingGuest)
    {
        this.nameLabel.text = i.name;
        this.paxTextField.text = i.pax;
        this.paxAttendLabel.text = String(i.paxAttend);
        this.commentsTextField.text = i.comments;
        
        // this.listTypeLabel.text = i.guestList?.name;
        // this.sourceLabel.text = i.source?.name;

        if (i.status == 1)
        {
            // FINISHED / SHOW
            this.nameLabel.setTextRGBColor(7, 173, 30);
            this.paxTextField.setTextRGBColor(7, 173, 30);
            
            // this.listTypeLabel.setTextRGBColor(7, 173, 30);
            // this.sourceLabel.setTextRGBColor(7, 173, 30);
        }
        else if (i.status == 2)
        {
            // NO SHOW
            this.nameLabel.setTextRGBColor(167, 1, 203);
            this.paxTextField.setTextRGBColor(167, 1, 203);
           
            // this.listTypeLabel.setTextRGBColor(167, 1, 203);
            // this.sourceLabel.setTextRGBColor(167, 1, 203);
        }
        else
        {
            this.nameLabel.setTextRGBColor(0, 0, 0);
            this.paxTextField.setTextRGBColor(0, 0, 0);
           
            // this.listTypeLabel.setTextRGBColor(0, 0, 0);
            // this.sourceLabel.setTextRGBColor(0, 0, 0);
        }
    }
}