
class StockOrderLineCell extends NoteLineCell
{
    private orderedInputFormatLabel:MUILabel = null;
    private orderedQuantityLabel:MUILabel = null;

    awakeFromHTML(){
        super.awakeFromHTML();

        this.orderedInputFormatLabel = MUIOutlet(this, "ordered-input-format-lbl", "MUILabel");
        this.orderedQuantityLabel = MUIOutlet(this, "ordered-quantity-lbl", "MUILabel");

        this.inputFormatDropdown = MUIOutlet(this, "input-format-dd", "MUIButton");
        this.setupInputFormatDropdown(this.inputFormatDropdown);
        this.inputFormatLabel = MUIOutlet(this, "input-format-lbl", "MUILabel");
    
        this.quantityTextField = MUIOutlet(this, 'quantity-tf', 'MUITextField');   
        this.setupQuantityTextField(this.quantityTextField);        
        this.quantityLabel = MUIOutlet(this, "quantity-lbl", "MUILabel");

        this.totalLabel = MUIOutlet(this, "total-price-lbl", "MUILabel");

        this.reset();
        
        this.selectionStyle = UITableViewCellSelectionStyle.None;    
    }

    setLine(item:StockNoteLine){
        super.setLine(item);    
        
        // this.productLabel.text = this.productNameString();
            
        if (this.orderedInputFormatLabel != null)this.orderedInputFormatLabel.text = item.estimatedQuantity != null ? this.inputFormatString() : null;
        
        if (this.orderedQuantityLabel != null) this.orderedQuantityLabel.text = item.estimatedQuantity != null ?  this.nf.stringFromNumber(item.estimatedQuantity) : null;

        if (this.inputFormatLabel != null) this.inputFormatLabel.text = this.inputFormatString();
        if (this.inputFormatDropdown != null) {
            this.inputFormatDropdown.title = this.inputFormatString();
            this.inputFormatDropdown.enabled = true;
        }
        
        let q = this.nf.stringFromNumber(item.quantity);
        if (this.quantityLabel != null) this.quantityLabel.text = q;
        if (this.quantityTextField != null) {
            this.quantityTextField.text = q;
            this.quantityTextField.enabled = true;
        }        

        if (this.totalLabel != null) this.totalLabel.text = this.productQuantityString();
    }

    // productDidSelect(controller:SelectEntityViewController, product:Product){
    //     this.setProduct(product);
    //     [this.productPrice, this.discount] = DBHelper.priceFromProductAndSupplier(product, this.stockNote.originEntity);
    //     this.price = this.productPrice;
        
    //     this.productTextField.text = this.productNameString();
                    
    //     this.inputFormatDropdown.enabled = true;
    //     this.inputFormatDropdown.title = product.defaultInputFormatString();                

    //     this.quantityTextField.enabled = true;
    //     this.quantityTextField.becomeFirstResponder();
    // }

    quantityDidChange(quantity){
        super.quantityDidChange(quantity);
        if (this.totalLabel != null) this.totalLabel.text = this.totalPriceString();
    }

    enterDidPress(){
        if (this.stockNoteLine != null) return;        
        if (this.quantity == 0 || this.quantity == null) return;
                
        let line = DBHelper.createSupplierNoteLine(this.product, this.tax, this.inputFormat, this.inputType, this.quantity, this.productQuantity, this.priceAmount, this.productNetPriceAmount, this.totalAmount, this.warehouse, this.stockNote);
        if (line != null) {
            this.lineDidInserted(line);
            this.reset();
        }
    }
    
    // reset(){
    //     this.product = null;        
    //     this.inputFormat = null;
    //     this.quantity = 0;
    //     this.productQuantity = 0;
    //     this.measureQuantity = 0;
        
    //     if (this.productTextField != null) this.productTextField.text = null;

    //     if (this.orderedInputFormatLabel != null) this.orderedInputFormatLabel.text = null;
    //     if (this.orderedQuantityLabel != null) this.orderedQuantityLabel.text = null;

    //     if (this.inputFormatDropdown != null) {
    //         this.inputFormatDropdown.title = null;
    //         this.inputFormatDropdown.enabled = false;            
    //     }        

    //     if (this.quantityTextField != null) {
    //         this.quantityTextField.text = null;
    //         this.quantityTextField.enabled = false;
    //     }        

    //     if (this.totalLabel != null) this.totalLabel.text = null;
    // }    

}
