class InstallmentsViewCell extends UITableViewCell
{
    private dueDateLabel:MUILabel = null;    
    private conceptLabel:MUILabel = null;
    private amountLabel:MUILabel = null; 
    private documentLabel:MUILabel = null;
    private payMethodDropdown:MUIButton = null;
    private paymentDateLabel:MUILabel = null;
    private beneficiaryLabel:MUILabel = null;

    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;
    private df = MUIWebApplication.sharedInstance().delegate.dateFormatter as MIODateFormatter;

    awakeFromHTML(){

        this.dueDateLabel = MUIOutlet(this, "due-date-lbl", "MUILabel"); 
        this.conceptLabel = MUIOutlet(this, "concept-lbl", "MUILabel");
        this.amountLabel = MUIOutlet(this, "amount-lbl", "MUILabel");             
        this.documentLabel = MUIOutlet(this, "document-lbl", "MUILabel");
        this.payMethodDropdown = MUIOutlet(this, "pay-method-dd", "MUIButton");
        this.payMethodDropdown.setAction(this, function(){
            this.showSelectEntityViewController(this.payMethodDropdown, PaymentInstallment)
        });
        this.paymentDateLabel = MUIOutlet(this, "payment-date-lbl", "MUILabel"); 
        this.beneficiaryLabel = MUIOutlet(this, "beneficiary-lbl", "MUILabel")        
    }

    private _paymentInstallment = null;
    set item(item:PaymentInstallment){
        this._paymentInstallment = item;
        this.dueDateLabel.text = this.df.stringFromDate(item.dueDate);
        this.conceptLabel.text = item.concept;
        this.amountLabel.text = this.nf.stringFromNumber(item.amount);
        this.documentLabel.text = item.operationID;
        this.payMethodDropdown.title = item.paymentMethod.name;
        this.paymentDateLabel.text = this.df.stringFromDate(item.paymentDate);
        this.beneficiaryLabel.text = item.paymentEntity.name;
    }

    private showSelectEntityViewController(dropdownButton, entityName) {
        let predicateFormat = null;        
        let vc = AppHelper.sharedInstance().showCustomSelectViewControllerFromView(dropdownButton, entityName, "name", predicateFormat, this, function(controller, object:MIOManagedObject){

            let title = object.valueForKey("name");
            //let value = object.valueForKey("identifier");
            
            dropdownButton.title = title;
            this._paymentInstallment.paymentEntity = object;
        }, true);        
    }

}