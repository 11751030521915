class BookingHistoryCell extends MUITableViewCell
{
    private dateLabel:MUILabel = null;
    private importLabel:MUILabel = null;
    private separator:MUIView = null;

    private df = MUIWebApplication.sharedInstance().delegate.dateFormatter;
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;

    awakeFromHTML()
    {
        this.dateLabel = MUIOutlet(this, 'bdv_date_lbl', 'MUILabel');
        this.importLabel = MUIOutlet(this, 'bdv_import_lbl', 'MUILabel');
        this.separator = MUIOutlet(this, 'bdv_date_lbl', 'MUIView');
    }
    setSelected(value)
    {
        super.setSelected(value);
        if (value == true)
            this.separator.setBackgroundRGBColor(123, 186, 159);
        else
            this.separator.setBackgroundRGBColor(233, 233, 233);
    }
    set item(i)
    {
        this.dateLabel.text = this.df.stringFromDate(i.date);
        this.importLabel.text = this.cf.stringFromNumber(i.price);

        if (i.status == BookingStatus.BookingRequest)
        {
            // REQUEST
            this.dateLabel.setTextRGBColor(255, 142, 9);
            this.importLabel.setTextRGBColor(255, 142, 9);
        }
        else if (i.status == BookingStatus.BookingAssigned)
        {
            // ASSIGNED
            this.dateLabel.setTextRGBColor(65, 93, 188);
            this.importLabel.setTextRGBColor(65, 93, 188);
        }
        else if (i.status == BookingStatus.BookingConfirmed)  // CONFIRMED
        {
            this.dateLabel.setTextRGBColor(0, 128, 64);
            this.importLabel.setTextRGBColor(0, 128, 64);
        }        
        else if (i.status == BookingStatus.BookingFinished)
        {
            // FINISHED / SHOW
            this.dateLabel.setTextRGBColor(7, 173, 30);
            this.importLabel.setTextRGBColor(7, 173, 30);
        }
        else if (i.status == BookingStatus.BookingWaiting)
        {
            // WAITING LIST
            this.dateLabel.setTextRGBColor(211, 190, 15);
            this.importLabel.setTextRGBColor(211, 190, 15);
        }
        else if (i.status == BookingStatus.BookingCancelled || i.status == BookingStatus.BookingDenied || i.status == BookingStatus.BookingDeleted)
        {
            this.dateLabel.setTextRGBColor(200, 120, 120);
            this.importLabel.setTextRGBColor(200, 120, 120);
        }
        else if (i.status == BookingStatus.BookingNoShow)
        {
            // NO SHOW
            this.dateLabel.setTextRGBColor(167, 1, 203);
            this.importLabel.setTextRGBColor(167, 1, 203);
        }
        else
        {
            this.dateLabel.setTextRGBColor(0, 0, 0);
            this.importLabel.setTextRGBColor(0, 0, 0);
        }
    }
}
