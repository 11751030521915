class WorkerClockInViewController extends BaseViewController
{
    public static newInstance() : WorkerClockInViewController {
        let vc = new WorkerClockInViewController("clock-in-view");
        vc.initWithResource("layout/clockin/ClockInView.html");

        return vc;
    } 

    viewDidLoad()
    {
        super.viewDidLoad();
    }
    
    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath)
    {
        // var cell:ClientVIPPointsCell = tableview.dequeueReusableCellWithIdentifier('ClientVIPPointsCell') as ClientVIPPointsCell;
    
        // var item = this.fetchedResultsController.objectAtIndexPath(indexPath);
    
        // cell.item = item;
    
        // cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
    
        // return cell;
    }
        
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath)
    {
       
    }
    
    set fetchedResultsController(value)
    {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController()
    {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        // let ad = MUIWebApplication.sharedInstance().delegate;
    
        // let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('date', false)];
    
        // let fetchRequest = DBHelper.listFetchRequestWithEntityName('LoyaltyAccountLine', sortDescriptors, 'client.identifier == ' + this._client.identifier);
        // let fetchedResultsController = new MIOFetchedResultsController();

        // fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        // fetchedResultsController.delegate = this;
    
        // fetchedResultsController.performFetch();
    
        // this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }   
 
}