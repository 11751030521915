
/// <reference path="../base/BaseTableViewController.ts" />

class ApplicationLicenseListViewController extends BaseTableViewController
{    
    static newInstance() : ApplicationLicenseListViewController {
        let vc = new ApplicationLicenseListViewController("application-license-view");
        vc.initWithResource("layout/licenses/ApplicationLicenseView.html");
        return vc;
    }

    viewDidLoad(){
        super.viewDidLoad();

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;   
        
        this.tableView.reloadData();
    }
    
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        let cell = tableview.dequeueReusableCellWithIdentifier("ApplicationLicenseCell") as ApplicationLicenseCell; 
        cell.item = item;        
        
        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath) as License;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("License");
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];        
        // fetchRequest.fetchLimit = 100;

        if (this.searchString != null) {
            let predicateFormat = "name CONTAINS '" + this.searchString + "'";
            fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);
        }        

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();
        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    private showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("License", NoItemSelectedViewControllerType.License);        
        this.splitViewController.showDetailViewController(vc);
    }

}