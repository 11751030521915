//
// Generated class NumberSeries
//

/// <reference path="NumberSeries_ManagedObject.ts" />

class NumberSeries extends NumberSeries_ManagedObject
{

}
