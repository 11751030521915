

/// <reference path="PaymentAction.ts" />

// Generated class FreeProduct_ManagedObject

class FreeProduct_ManagedObject extends PaymentAction
{

    // Property: autoAddIfNotPresent
    set autoAddIfNotPresent(value:boolean) {
        this.setValueForKey(value, 'autoAddIfNotPresent');
    }
    get autoAddIfNotPresent():boolean {
        return this.valueForKey('autoAddIfNotPresent');
    }
    set autoAddIfNotPresentPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'autoAddIfNotPresent');
    }
    get autoAddIfNotPresentPrimitiveValue():boolean {
        return this.primitiveValueForKey('autoAddIfNotPresent');
    }

    // Property: outQuantity
    set outQuantity(value:number) {
        this.setValueForKey(value, 'outQuantity');
    }
    get outQuantity():number {
        return this.valueForKey('outQuantity');
    }
    set outQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'outQuantity');
    }
    get outQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('outQuantity');
    }
    // Relationship: freeProduct
    set freeProduct(value:Product) {
        this.setValueForKey(value, 'freeProduct');
    }
    get freeProduct():Product {
        return this.valueForKey('freeProduct') as Product;
    }
}
