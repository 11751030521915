

/// <reference path="ReconliceTransaction.ts" />

// Generated class SalaryReconcileTransaction_ManagedObject

class SalaryReconcileTransaction_ManagedObject extends ReconliceTransaction
{

    // Relationship: salaryConcepts
    protected _salaryConcepts:MIOManagedObjectSet = null;
    get salaryConcepts():MIOManagedObjectSet {
        return this.valueForKey('salaryConcepts');
    }
    addSalaryConceptsObject(value:EmployeeSalaryConcept) {
        this._addObjectForKey(value, 'salaryConcepts');
    }
    removeSalaryConceptsObject(value:EmployeeSalaryConcept) {
        this._removeObjectForKey(value, 'salaryConcepts');
    }
}
