//
// Generated class EmailTemplate
//

/// <reference path="EmailTemplate_ManagedObject.ts" />

class EmailTemplate extends EmailTemplate_ManagedObject
{

}
