

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class PayMethodConfig_ManagedObject

class PayMethodConfig_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: includeInCashDeskTotals
    set includeInCashDeskTotals(value:boolean) {
        this.setValueForKey(value, 'includeInCashDeskTotals');
    }
    get includeInCashDeskTotals():boolean {
        return this.valueForKey('includeInCashDeskTotals');
    }
    set includeInCashDeskTotalsPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'includeInCashDeskTotals');
    }
    get includeInCashDeskTotalsPrimitiveValue():boolean {
        return this.primitiveValueForKey('includeInCashDeskTotals');
    }

    // Property: includeInfoInCashDeskReport
    set includeInfoInCashDeskReport(value:boolean) {
        this.setValueForKey(value, 'includeInfoInCashDeskReport');
    }
    get includeInfoInCashDeskReport():boolean {
        return this.valueForKey('includeInfoInCashDeskReport');
    }
    set includeInfoInCashDeskReportPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'includeInfoInCashDeskReport');
    }
    get includeInfoInCashDeskReportPrimitiveValue():boolean {
        return this.primitiveValueForKey('includeInfoInCashDeskReport');
    }

    // Property: invoiceSerieID
    set invoiceSerieID(value:string) {
        this.setValueForKey(value, 'invoiceSerieID');
    }
    get invoiceSerieID():string {
        return this.valueForKey('invoiceSerieID');
    }
    set invoiceSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invoiceSerieID');
    }
    get invoiceSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('invoiceSerieID');
    }

    // Property: isDisabled
    set isDisabled(value:boolean) {
        this.setValueForKey(value, 'isDisabled');
    }
    get isDisabled():boolean {
        return this.valueForKey('isDisabled');
    }
    set isDisabledPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isDisabled');
    }
    get isDisabledPrimitiveValue():boolean {
        return this.primitiveValueForKey('isDisabled');
    }

    // Property: nullifyInvoiceSerieID
    set nullifyInvoiceSerieID(value:string) {
        this.setValueForKey(value, 'nullifyInvoiceSerieID');
    }
    get nullifyInvoiceSerieID():string {
        return this.valueForKey('nullifyInvoiceSerieID');
    }
    set nullifyInvoiceSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'nullifyInvoiceSerieID');
    }
    get nullifyInvoiceSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('nullifyInvoiceSerieID');
    }

    // Property: nullifyTicketSerieID
    set nullifyTicketSerieID(value:string) {
        this.setValueForKey(value, 'nullifyTicketSerieID');
    }
    get nullifyTicketSerieID():string {
        return this.valueForKey('nullifyTicketSerieID');
    }
    set nullifyTicketSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'nullifyTicketSerieID');
    }
    get nullifyTicketSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('nullifyTicketSerieID');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: ticketSerieID
    set ticketSerieID(value:string) {
        this.setValueForKey(value, 'ticketSerieID');
    }
    get ticketSerieID():string {
        return this.valueForKey('ticketSerieID');
    }
    set ticketSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ticketSerieID');
    }
    get ticketSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('ticketSerieID');
    }

    // Property: useDifferentSeries
    set useDifferentSeries(value:boolean) {
        this.setValueForKey(value, 'useDifferentSeries');
    }
    get useDifferentSeries():boolean {
        return this.valueForKey('useDifferentSeries');
    }
    set useDifferentSeriesPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'useDifferentSeries');
    }
    get useDifferentSeriesPrimitiveValue():boolean {
        return this.primitiveValueForKey('useDifferentSeries');
    }
    // Relationship: payMethod
    set payMethod(value:PayMethod) {
        this.setValueForKey(value, 'payMethod');
    }
    get payMethod():PayMethod {
        return this.valueForKey('payMethod') as PayMethod;
    }
}
