//
// Generated class BuyOrder
//

/// <reference path="BuyOrder_ManagedObject.ts" />

class BuyOrder extends BuyOrder_ManagedObject
{

}
