//
// Generated class ContainerProduct
//

/// <reference path="ContainerProduct_ManagedObject.ts" />

class ContainerProduct extends ContainerProduct_ManagedObject
{

}
