//
// Generated class Annotation
//

/// <reference path="Annotation_ManagedObject.ts" />

class Annotation extends Annotation_ManagedObject
{

}
