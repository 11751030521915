//
// Generated class SubCashDesk
//

/// <reference path="SubCashDesk_ManagedObject.ts" />

class SubCashDesk extends SubCashDesk_ManagedObject
{

}
