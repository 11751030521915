

/// <reference path="CoreAnnotation.ts" />

// Generated class StockCoreAnnotation_ManagedObject

class StockCoreAnnotation_ManagedObject extends CoreAnnotation
{

    // Property: minTotalQuantity
    set minTotalQuantity(value:number) {
        this.setValueForKey(value, 'minTotalQuantity');
    }
    get minTotalQuantity():number {
        return this.valueForKey('minTotalQuantity');
    }
    set minTotalQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minTotalQuantity');
    }
    get minTotalQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('minTotalQuantity');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: warehouseName
    set warehouseName(value:string) {
        this.setValueForKey(value, 'warehouseName');
    }
    get warehouseName():string {
        return this.valueForKey('warehouseName');
    }
    set warehouseNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'warehouseName');
    }
    get warehouseNamePrimitiveValue():string {
        return this.primitiveValueForKey('warehouseName');
    }
    // Relationship: warehouse
    set warehouse(value:Warehouse) {
        this.setValueForKey(value, 'warehouse');
    }
    get warehouse():Warehouse {
        return this.valueForKey('warehouse') as Warehouse;
    }
}
