
/// <reference path="../external/Currency.ts" />


class PlaceSettingCurrencyCell extends MUITableViewCell
{
    private currencyComboBox:MUIComboBox = null;
    
    private _separator = null;
    private _item = null;

    awakeFromHTML()
    {
        this.currencyComboBox = MUIOutlet(this, 'psv_currency_cb', 'MUIComboBox');
        this.currencyComboBox.setOnChangeAction(this,function(combobox, value)
        {
            this._item.setValueForKey('currency',value == '0' ? null : parseInt(value));
        });
        this._separator = MUIOutlet(this, 'psv_currency_separator', 'MUIView');
    }



    set item(i)
    {
        this._item = i;
        this.currencyComboBox.removeAllItems();
        if(this._item != null && this._item.currency < 1){
            this.currencyComboBox.addItem(MIOLocalizeString('SELECT','Select...'), 0);
        }
        currencies.forEach(element => {
            this.currencyComboBox.addItem(element.name, element.id);
        });
        if(this._item != null) 
            this.currencyComboBox.selectItem(this._item.currency);
    }
}
