
class ReportColumnHeader extends MUIView 
{
    columnFilterTextField:ColumnFilterTextField = null;
    private sortButton:MUIButton = null;

    awakeFromHTML(){
        this.columnFilterTextField = MUIOutlet(this, "search-column", "ColumnFilterTextField");
        this.sortButton = MUIOutlet(this, "sort-btn", "MUIButton");
        if (this.sortButton != null)this.sortButton.setAction(this, this.sortButtonDidClick);
    }

    set title (value:string){
        if (this.columnFilterTextField != null)this.columnFilterTextField.placeholderText = MIOLocalizeString(value.toUpperCase(), value);
    }

    private action = null;
    private target = null;
    private column:MUIReportTableViewColumn = null;
    setAction(target, action, column:MUIReportTableViewColumn){
        this.target = target;
        this.action = action;
        this.column = column;
    }

    private sortButtonDidClick(){
        if (this.action == null || this.target == null) return;

        MUICoreLayerRemoveStyle(this.sortButton.layer, "order-sort");
        MUICoreLayerRemoveStyle(this.sortButton.layer, "order-sort-asc");
        MUICoreLayerRemoveStyle(this.sortButton.layer, "order-sort-desc");

        if (this.column.ascending == true) {
            MUICoreLayerAddStyle(this.sortButton.layer, "order-sort-asc");
        }
        else if (this.column.ascending == false) {
            MUICoreLayerAddStyle(this.sortButton.layer, "order-sort-desc");
        }

        this.action.call(this.target, this.column);
    }
}
