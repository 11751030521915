//
// Generated class ArchivedTicketLineModifier
//

/// <reference path="ArchivedTicketLineModifier_ManagedObject.ts" />

class ArchivedTicketLineModifier extends ArchivedTicketLineModifier_ManagedObject
{

}
