//
// Generated class IntermediaryLicenseRate
//

/// <reference path="IntermediaryLicenseRate_ManagedObject.ts" />

class IntermediaryLicenseRate extends IntermediaryLicenseRate_ManagedObject
{

}
