

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Rate_ManagedObject

class Rate_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: beginDate
    set beginDate(value:Date) {
        this.setValueForKey(value, 'beginDate');
    }
    get beginDate():Date {
        return this.valueForKey('beginDate');
    }
    set beginDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'beginDate');
    }
    get beginDatePrimitiveValue():Date {
        return this.primitiveValueForKey('beginDate');
    }

    // Property: endDate
    set endDate(value:Date) {
        this.setValueForKey(value, 'endDate');
    }
    get endDate():Date {
        return this.valueForKey('endDate');
    }
    set endDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'endDate');
    }
    get endDatePrimitiveValue():Date {
        return this.primitiveValueForKey('endDate');
    }

    // Property: idIn
    set idIn(value:number) {
        this.setValueForKey(value, 'idIn');
    }
    get idIn():number {
        return this.valueForKey('idIn');
    }
    set idInPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'idIn');
    }
    get idInPrimitiveValue():number {
        return this.primitiveValueForKey('idIn');
    }

    // Property: image
    set image(value:string) {
        this.setValueForKey(value, 'image');
    }
    get image():string {
        return this.valueForKey('image');
    }
    set imagePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'image');
    }
    get imagePrimitiveValue():string {
        return this.primitiveValueForKey('image');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: priceType
    set priceType(value:number) {
        this.setValueForKey(value, 'priceType');
    }
    get priceType():number {
        return this.valueForKey('priceType');
    }
    set priceTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'priceType');
    }
    get priceTypePrimitiveValue():number {
        return this.primitiveValueForKey('priceType');
    }

    // Property: roundType
    set roundType(value:number) {
        this.setValueForKey(value, 'roundType');
    }
    get roundType():number {
        return this.valueForKey('roundType');
    }
    set roundTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'roundType');
    }
    get roundTypePrimitiveValue():number {
        return this.primitiveValueForKey('roundType');
    }

    // Property: rule
    set rule(value:string) {
        this.setValueForKey(value, 'rule');
    }
    get rule():string {
        return this.valueForKey('rule');
    }
    set rulePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'rule');
    }
    get rulePrimitiveValue():string {
        return this.primitiveValueForKey('rule');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Relationship: intervals
    protected _intervals:MIOManagedObjectSet = null;
    get intervals():MIOManagedObjectSet {
        return this.valueForKey('intervals');
    }
    addIntervalsObject(value:RateInterval) {
        this._addObjectForKey(value, 'intervals');
    }
    removeIntervalsObject(value:RateInterval) {
        this._removeObjectForKey(value, 'intervals');
    }

    // Relationship: locationCategories
    protected _locationCategories:MIOManagedObjectSet = null;
    get locationCategories():MIOManagedObjectSet {
        return this.valueForKey('locationCategories');
    }
    addLocationCategoriesObject(value:LocationCategory) {
        this._addObjectForKey(value, 'locationCategories');
    }
    removeLocationCategoriesObject(value:LocationCategory) {
        this._removeObjectForKey(value, 'locationCategories');
    }

    // Relationship: menuRates
    protected _menuRates:MIOManagedObjectSet = null;
    get menuRates():MIOManagedObjectSet {
        return this.valueForKey('menuRates');
    }
    addMenuRatesObject(value:MenuRate) {
        this._addObjectForKey(value, 'menuRates');
    }
    removeMenuRatesObject(value:MenuRate) {
        this._removeObjectForKey(value, 'menuRates');
    }

    // Relationship: onlinePlatforms
    protected _onlinePlatforms:MIOManagedObjectSet = null;
    get onlinePlatforms():MIOManagedObjectSet {
        return this.valueForKey('onlinePlatforms');
    }
    addOnlinePlatformsObject(value:OnlinePlatform) {
        this._addObjectForKey(value, 'onlinePlatforms');
    }
    removeOnlinePlatformsObject(value:OnlinePlatform) {
        this._removeObjectForKey(value, 'onlinePlatforms');
    }

    // Relationship: productFormatRates
    protected _productFormatRates:MIOManagedObjectSet = null;
    get productFormatRates():MIOManagedObjectSet {
        return this.valueForKey('productFormatRates');
    }
    addProductFormatRatesObject(value:ProductFormatRate) {
        this._addObjectForKey(value, 'productFormatRates');
    }
    removeProductFormatRatesObject(value:ProductFormatRate) {
        this._removeObjectForKey(value, 'productFormatRates');
    }

    // Relationship: productModifierRates
    protected _productModifierRates:MIOManagedObjectSet = null;
    get productModifierRates():MIOManagedObjectSet {
        return this.valueForKey('productModifierRates');
    }
    addProductModifierRatesObject(value:ProductModifierRate) {
        this._addObjectForKey(value, 'productModifierRates');
    }
    removeProductModifierRatesObject(value:ProductModifierRate) {
        this._removeObjectForKey(value, 'productModifierRates');
    }

    // Relationship: productRates
    protected _productRates:MIOManagedObjectSet = null;
    get productRates():MIOManagedObjectSet {
        return this.valueForKey('productRates');
    }
    addProductRatesObject(value:ProductRate) {
        this._addObjectForKey(value, 'productRates');
    }
    removeProductRatesObject(value:ProductRate) {
        this._removeObjectForKey(value, 'productRates');
    }

    // Relationship: ticketLines
    protected _ticketLines:MIOManagedObjectSet = null;
    get ticketLines():MIOManagedObjectSet {
        return this.valueForKey('ticketLines');
    }
    addTicketLinesObject(value:TicketLine) {
        this._addObjectForKey(value, 'ticketLines');
    }
    removeTicketLinesObject(value:TicketLine) {
        this._removeObjectForKey(value, 'ticketLines');
    }
}
