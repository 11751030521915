class SettingsHelper extends MIOObject
{
    private static _sharedInstance: SettingsHelper = null;
    static sharedInstance(): SettingsHelper {

        if (this._sharedInstance == null) {
            this._sharedInstance = new SettingsHelper();
            this._sharedInstance.init();
        }

        return this._sharedInstance;
    }

    init(){
        super.init();

        MIONotificationCenter.defaultCenter().addObserver(this, MWSPersistentStoreDidUpdateEntity, this.entityDidUpdateNotification);
    }

    private _config = null;
    get config(){
        return this._config;
    }

    private _isNewServersLoaded = false;
    get isNewServersLoaded(){
        return this._isNewServersLoaded;
    }

    private _isNewSync = false;
    get isNewSync() { return this._isNewSync; }

    private _ignoreServerAuthURLs = false;
    get ignoreServerAuthURLs():boolean {
        return this._ignoreServerAuthURLs;
    }

    loadAppSettings(target?, completion?){
        
        this._config = {}

        let configData = MIOBundle.mainBundle().pathForResourceOfType("config", "plist");
        this._config = MIOPropertyListSerialization.propertyListWithData(configData, 0, 0, null);

        this._isNewSync = this._config["UseNewSync"] || false;
        this._ignoreServerAuthURLs = this._config["IgnoreServerAuthURLs"] || false;

        if (this._config["UseNewServerConfigFile"] != null && this._config["UseNewServerConfigFile"] == true) {
            this._isNewServersLoaded = true; 
        }        
        else {
            let appData = MIOBundle.mainBundle().pathForResourceOfType("app", "plist");
            let items = MIOPropertyListSerialization.propertyListWithData(appData, 0, 0, null);
            for (let key of items) {
                let value = items[key];
                this._config[key] = value;
            }            
        }                

        if (target != null && completion != null) completion.call(target, this._config);        
    }
    
    loadConfig(target, completion) {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        DBHelper.queryObjectsWithCompletion("Configuration", null, null, [], this, function(items){
            //this._config = {}
            for (let index = 0; index < items.length; index++){
                let cfg = items[index] as Configuration;
                this._config[cfg.name] = cfg.value; // (cfg.value != null && cfg.value.length > 0) ? JSON.parse(cfg.value) : null;
            }
            
            if (target != null && completion != null) completion.call(target, this._config);
        });
    }

    loadPermissions(target, completion){

        let userID = DualLinkAuthKit.sharedInstance().userID;
        DBHelper.queryObjectsWithCompletion("User", null, MIOPredicate.predicateWithFormat("identifier == " + userID), ["reportOptions", "reportAccess"], this, function(objects){
            let usr = objects[0] as User;
            this._permissions = usr.permisions;
            if (target != null && completion != null) completion.call(target, usr);
        });
    }

    private _permissions = null;
    get permisisons(){
        return this._permissions;
    }
    
    entityDidUpdateNotification(note:MIONotification){
        let object = note.object as MIOManagedObject;
        let entityName = object.entity.name;

        if (entityName != "Configuration") return;  
        
        let cfg = object as Configuration;
        this._config[cfg.name]  = cfg.value;

        MIONotificationCenter.defaultCenter().postNotification("ConfigurationDidChange", this._config);
    }

    private _templateLanguages = null;
    get templateLanguages(){
        if (this._templateLanguages == null) {
            let langs = this._config["booking-available-language-template"] || [];
            this._templateLanguages = [];

            let languages = DBCacheHelper.sharedInstance().objectsForEntityName("Language", [MIOSortDescriptor.sortDescriptorWithKey("name", true)], null);            
            for (let index = 0; index < languages.length; index++){
                let lang = languages[index] as Language;
                if (langs.indexOf(lang.iso2) != -1) this._templateLanguages.push(lang);
            }

        }

        return this._templateLanguages;
    }

    get currentLanguage(){
        return MIOCoreGetBrowserLanguage();
    }

    private supportedLanguages = ["en", "es", "nl", "ar", "fr"];
    get currentSupportedLanguage(){
        let lang = this.currentLanguage;
        if (this.supportedLanguages.indexOf(lang) == -1) return "en";
        return lang;
    }
    
    get configStockUIAddLineTop():boolean {
        let cfg = DBHelper.queryObjectFromMainContext("Configuration", MIOPredicate.predicateWithFormat("name = 'add-stock-line-on-top'")) as Configuration;        
        return cfg != null ? cfg.boolValue : false;
    }

    configurationBoolValue(key:string, defaultValue?:boolean) {
        let cfg = DBHelper.queryObjectFromMainContext("Configuration", MIOPredicate.predicateWithFormat("name = '" + key + "'")) as Configuration;
        return cfg != null ? cfg.boolValue : ( defaultValue ?? false);
    }

    get invoiceCustomerChangeType() : InvoiceCustomerChangeType {
        let cfg = DBHelper.queryObjectFromMainContext("Configuration", MIOPredicate.predicateWithFormat("name = 'invoce-customer-change-type'")) as Configuration;           
        return cfg != null ? cfg.value : InvoiceCustomerChangeType.beforePosted;
    }

}

enum InvoiceCustomerChangeType
{
    beforePosted = 0,
    allow        = 1,
    forbidden    = 2    
}
