

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class IntegratorMappingTargetEntity_ManagedObject

class IntegratorMappingTargetEntity_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: targetEntityName
    set targetEntityName(value:string) {
        this.setValueForKey(value, 'targetEntityName');
    }
    get targetEntityName():string {
        return this.valueForKey('targetEntityName');
    }
    set targetEntityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'targetEntityName');
    }
    get targetEntityNamePrimitiveValue():string {
        return this.primitiveValueForKey('targetEntityName');
    }

    // Property: targetID
    set targetID(value:string) {
        this.setValueForKey(value, 'targetID');
    }
    get targetID():string {
        return this.valueForKey('targetID');
    }
    set targetIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'targetID');
    }
    get targetIDPrimitiveValue():string {
        return this.primitiveValueForKey('targetID');
    }

    // Property: targetUserInfo
    set targetUserInfo(value:any) {
        this.setValueForKey(value, 'targetUserInfo');
    }
    get targetUserInfo():any {
        return this.valueForKey('targetUserInfo');
    }
    set targetUserInfoPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'targetUserInfo');
    }
    get targetUserInfoPrimitiveValue():any {
        return this.primitiveValueForKey('targetUserInfo');
    }

    // Relationship: mappings
    protected _mappings:MIOManagedObjectSet = null;
    get mappings():MIOManagedObjectSet {
        return this.valueForKey('mappings');
    }
    addMappingsObject(value:IntegratorMapping) {
        this._addObjectForKey(value, 'mappings');
    }
    removeMappingsObject(value:IntegratorMapping) {
        this._removeObjectForKey(value, 'mappings');
    }
}
