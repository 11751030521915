/**
 * Created by miguel on 23/2/17.
 */



 class PartyTicketCell extends UITableViewCell
 {
     private nameLabel:MUILabel = null;
     private channelLabel:MUILabel = null;
     private sourceLabel:MUILabel = null;
     private codeLabel:MUILabel = null;
 
     awakeFromHTML(){
         this.nameLabel = MUIOutlet(this, 'name-lbl', 'MUILabel');
         this.codeLabel = MUIOutlet(this, "code-lbl", "MUILabel");
         this.channelLabel = MUIOutlet(this, 'channel-lbl', 'MUILabel');
         this.sourceLabel = MUIOutlet(this, "source-lbl", "MUILabel");
     }
 
     set item(i:EventTicket){
         let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
 
         this.nameLabel.text = i.name;
        //  this.dateLabel.text = ad.dateTimeFormatter.stringFromDate(i.beginDateTime);
        this.channelLabel.text = i.channelName;
        this.codeLabel.text = i.code;
        this.sourceLabel.text = i.source?.name;
        
     }
 }
 