//
// Generated class ServiceCategory
//

/// <reference path="ServiceCategory_ManagedObject.ts" />

class ServiceCategory extends ServiceCategory_ManagedObject
{

}
