

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class TicketLine_ManagedObject

class TicketLine_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: affectedByPromotionalCode
    set affectedByPromotionalCode(value:boolean) {
        this.setValueForKey(value, 'affectedByPromotionalCode');
    }
    get affectedByPromotionalCode():boolean {
        return this.valueForKey('affectedByPromotionalCode');
    }
    set affectedByPromotionalCodePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'affectedByPromotionalCode');
    }
    get affectedByPromotionalCodePrimitiveValue():boolean {
        return this.primitiveValueForKey('affectedByPromotionalCode');
    }

    // Property: areaID
    set areaID(value:number) {
        this.setValueForKey(value, 'areaID');
    }
    get areaID():number {
        return this.valueForKey('areaID');
    }
    set areaIDPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'areaID');
    }
    get areaIDPrimitiveValue():number {
        return this.primitiveValueForKey('areaID');
    }

    // Property: cachedPrices
    set cachedPrices(value:any) {
        this.setValueForKey(value, 'cachedPrices');
    }
    get cachedPrices():any {
        return this.valueForKey('cachedPrices');
    }
    set cachedPricesPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'cachedPrices');
    }
    get cachedPricesPrimitiveValue():any {
        return this.primitiveValueForKey('cachedPrices');
    }

    // Property: cover
    set cover(value:string) {
        this.setValueForKey(value, 'cover');
    }
    get cover():string {
        return this.valueForKey('cover');
    }
    set coverPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'cover');
    }
    get coverPrimitiveValue():string {
        return this.primitiveValueForKey('cover');
    }

    // Property: customConcept
    set customConcept(value:string) {
        this.setValueForKey(value, 'customConcept');
    }
    get customConcept():string {
        return this.valueForKey('customConcept');
    }
    set customConceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'customConcept');
    }
    get customConceptPrimitiveValue():string {
        return this.primitiveValueForKey('customConcept');
    }

    // Property: customConceptID
    set customConceptID(value:string) {
        this.setValueForKey(value, 'customConceptID');
    }
    get customConceptID():string {
        return this.valueForKey('customConceptID');
    }
    set customConceptIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'customConceptID');
    }
    get customConceptIDPrimitiveValue():string {
        return this.primitiveValueForKey('customConceptID');
    }

    // Property: customDescription
    set customDescription(value:string) {
        this.setValueForKey(value, 'customDescription');
    }
    get customDescription():string {
        return this.valueForKey('customDescription');
    }
    set customDescriptionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'customDescription');
    }
    get customDescriptionPrimitiveValue():string {
        return this.primitiveValueForKey('customDescription');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: deviceID
    set deviceID(value:number) {
        this.setValueForKey(value, 'deviceID');
    }
    get deviceID():number {
        return this.valueForKey('deviceID');
    }
    set deviceIDPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'deviceID');
    }
    get deviceIDPrimitiveValue():number {
        return this.primitiveValueForKey('deviceID');
    }

    // Property: discount
    set discount(value:number) {
        this.setValueForKey(value, 'discount');
    }
    get discount():number {
        return this.valueForKey('discount');
    }
    set discountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'discount');
    }
    get discountPrimitiveValue():number {
        return this.primitiveValueForKey('discount');
    }

    // Property: discountDate
    set discountDate(value:Date) {
        this.setValueForKey(value, 'discountDate');
    }
    get discountDate():Date {
        return this.valueForKey('discountDate');
    }
    set discountDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'discountDate');
    }
    get discountDatePrimitiveValue():Date {
        return this.primitiveValueForKey('discountDate');
    }

    // Property: discountWorkerID
    set discountWorkerID(value:string) {
        this.setValueForKey(value, 'discountWorkerID');
    }
    get discountWorkerID():string {
        return this.valueForKey('discountWorkerID');
    }
    set discountWorkerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'discountWorkerID');
    }
    get discountWorkerIDPrimitiveValue():string {
        return this.primitiveValueForKey('discountWorkerID');
    }

    // Property: eventID
    set eventID(value:string) {
        this.setValueForKey(value, 'eventID');
    }
    get eventID():string {
        return this.valueForKey('eventID');
    }
    set eventIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'eventID');
    }
    get eventIDPrimitiveValue():string {
        return this.primitiveValueForKey('eventID');
    }

    // Property: externalInfo
    set externalInfo(value:string) {
        this.setValueForKey(value, 'externalInfo');
    }
    get externalInfo():string {
        return this.valueForKey('externalInfo');
    }
    set externalInfoPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'externalInfo');
    }
    get externalInfoPrimitiveValue():string {
        return this.primitiveValueForKey('externalInfo');
    }

    // Property: idIn
    set idIn(value:number) {
        this.setValueForKey(value, 'idIn');
    }
    get idIn():number {
        return this.valueForKey('idIn');
    }
    set idInPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'idIn');
    }
    get idInPrimitiveValue():number {
        return this.primitiveValueForKey('idIn');
    }

    // Property: isGift
    set isGift(value:boolean) {
        this.setValueForKey(value, 'isGift');
    }
    get isGift():boolean {
        return this.valueForKey('isGift');
    }
    set isGiftPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isGift');
    }
    get isGiftPrimitiveValue():boolean {
        return this.primitiveValueForKey('isGift');
    }

    // Property: locationID
    set locationID(value:string) {
        this.setValueForKey(value, 'locationID');
    }
    get locationID():string {
        return this.valueForKey('locationID');
    }
    set locationIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationID');
    }
    get locationIDPrimitiveValue():string {
        return this.primitiveValueForKey('locationID');
    }

    // Property: responsibleCardCode
    set responsibleCardCode(value:string) {
        this.setValueForKey(value, 'responsibleCardCode');
    }
    get responsibleCardCode():string {
        return this.valueForKey('responsibleCardCode');
    }
    set responsibleCardCodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'responsibleCardCode');
    }
    get responsibleCardCodePrimitiveValue():string {
        return this.primitiveValueForKey('responsibleCardCode');
    }

    // Property: responsibleID
    set responsibleID(value:string) {
        this.setValueForKey(value, 'responsibleID');
    }
    get responsibleID():string {
        return this.valueForKey('responsibleID');
    }
    set responsibleIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'responsibleID');
    }
    get responsibleIDPrimitiveValue():string {
        return this.primitiveValueForKey('responsibleID');
    }

    // Property: responsibleName
    set responsibleName(value:string) {
        this.setValueForKey(value, 'responsibleName');
    }
    get responsibleName():string {
        return this.valueForKey('responsibleName');
    }
    set responsibleNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'responsibleName');
    }
    get responsibleNamePrimitiveValue():string {
        return this.primitiveValueForKey('responsibleName');
    }

    // Property: responsibleType
    set responsibleType(value:number) {
        this.setValueForKey(value, 'responsibleType');
    }
    get responsibleType():number {
        return this.valueForKey('responsibleType');
    }
    set responsibleTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'responsibleType');
    }
    get responsibleTypePrimitiveValue():number {
        return this.primitiveValueForKey('responsibleType');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: statusDate
    set statusDate(value:Date) {
        this.setValueForKey(value, 'statusDate');
    }
    get statusDate():Date {
        return this.valueForKey('statusDate');
    }
    set statusDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'statusDate');
    }
    get statusDatePrimitiveValue():Date {
        return this.primitiveValueForKey('statusDate');
    }

    // Property: statusWorkerID
    set statusWorkerID(value:string) {
        this.setValueForKey(value, 'statusWorkerID');
    }
    get statusWorkerID():string {
        return this.valueForKey('statusWorkerID');
    }
    set statusWorkerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'statusWorkerID');
    }
    get statusWorkerIDPrimitiveValue():string {
        return this.primitiveValueForKey('statusWorkerID');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: wasConsumed
    set wasConsumed(value:boolean) {
        this.setValueForKey(value, 'wasConsumed');
    }
    get wasConsumed():boolean {
        return this.valueForKey('wasConsumed');
    }
    set wasConsumedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'wasConsumed');
    }
    get wasConsumedPrimitiveValue():boolean {
        return this.primitiveValueForKey('wasConsumed');
    }

    // Property: waTransactionID
    set waTransactionID(value:string) {
        this.setValueForKey(value, 'waTransactionID');
    }
    get waTransactionID():string {
        return this.valueForKey('waTransactionID');
    }
    set waTransactionIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'waTransactionID');
    }
    get waTransactionIDPrimitiveValue():string {
        return this.primitiveValueForKey('waTransactionID');
    }

    // Property: workerName
    set workerName(value:string) {
        this.setValueForKey(value, 'workerName');
    }
    get workerName():string {
        return this.valueForKey('workerName');
    }
    set workerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerName');
    }
    get workerNamePrimitiveValue():string {
        return this.primitiveValueForKey('workerName');
    }
    // Relationship: rate
    set rate(value:Rate) {
        this.setValueForKey(value, 'rate');
    }
    get rate():Rate {
        return this.valueForKey('rate') as Rate;
    }
    // Relationship: tax
    set tax(value:Tax) {
        this.setValueForKey(value, 'tax');
    }
    get tax():Tax {
        return this.valueForKey('tax') as Tax;
    }
    // Relationship: ticket
    set ticket(value:Ticket) {
        this.setValueForKey(value, 'ticket');
    }
    get ticket():Ticket {
        return this.valueForKey('ticket') as Ticket;
    }
    // Relationship: worker
    set worker(value:Employee) {
        this.setValueForKey(value, 'worker');
    }
    get worker():Employee {
        return this.valueForKey('worker') as Employee;
    }
}
