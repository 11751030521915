

/// <reference path="PaymentInstallment.ts" />

// Generated class LoanPaymentInstallment_ManagedObject

class LoanPaymentInstallment_ManagedObject extends PaymentInstallment
{

    // Property: capitalAmount
    set capitalAmount(value:number) {
        this.setValueForKey(value, 'capitalAmount');
    }
    get capitalAmount():number {
        return this.valueForKey('capitalAmount');
    }
    set capitalAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'capitalAmount');
    }
    get capitalAmountPrimitiveValue():number {
        return this.primitiveValueForKey('capitalAmount');
    }

    // Property: interestAmount
    set interestAmount(value:number) {
        this.setValueForKey(value, 'interestAmount');
    }
    get interestAmount():number {
        return this.valueForKey('interestAmount');
    }
    set interestAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'interestAmount');
    }
    get interestAmountPrimitiveValue():number {
        return this.primitiveValueForKey('interestAmount');
    }

    // Property: interestPercentage
    set interestPercentage(value:number) {
        this.setValueForKey(value, 'interestPercentage');
    }
    get interestPercentage():number {
        return this.valueForKey('interestPercentage');
    }
    set interestPercentagePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'interestPercentage');
    }
    get interestPercentagePrimitiveValue():number {
        return this.primitiveValueForKey('interestPercentage');
    }
    // Relationship: loan
    set loan(value:Loan) {
        this.setValueForKey(value, 'loan');
    }
    get loan():Loan {
        return this.valueForKey('loan') as Loan;
    }
}
