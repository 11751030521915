//
// Generated class EmployeeSalaryTransaction
//

/// <reference path="EmployeeSalaryTransaction_ManagedObject.ts" />

class EmployeeSalaryTransaction extends EmployeeSalaryTransaction_ManagedObject
{

}
