//
// Generated class SourceMessage
//

/// <reference path="SourceMessage_ManagedObject.ts" />

class SourceMessage extends SourceMessage_ManagedObject
{

}
