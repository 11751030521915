//
// Generated class RuleResultOnDate
//

/// <reference path="RuleResultOnDate_ManagedObject.ts" />

class RuleResultOnDate extends RuleResultOnDate_ManagedObject
{

}
