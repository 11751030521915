//
// Generated class MapLocation
//

/// <reference path="MapLocation_ManagedObject.ts" />

class MapLocation extends MapLocation_ManagedObject
{

}
