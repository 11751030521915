

/// <reference path="../../model/LoyaltyAccountLine.ts" />
/// <reference path="../../model/EconomicAccountLine.ts" />
/// <reference path="../../model/DebtLine.ts" />

/// <reference path="ClientDebtViewController.ts" />


enum DebtPaymentMethod {
    ClientAccount = -1,
    ClientLoyalty = -2,
    PayMethodEntity = -3
}

class ClientDetailViewController extends MUIViewController
{
    private debtFilterComboBox:MUIComboBox = null;
    private segmentedControl:MUISegmentedControl = null;    
    private saveButton:MUIButton = null;
    private optionsButton:MUIButton = null;
    private sectionsPageController:MUIPageController = null;

    private dataVC:ClientDataViewController = null;
    private debtVC:ClientDebtViewController = null;
    private ticketVC:ClientDocumentViewController = null;
    private invoiceVC:ClientDocumentViewController = null;
    private bookingsVC:ClientBookingsViewController = null;
    private accountVC:ClientAccountViewController = null;
    private loyaltyVC:ClientLoyaltyViewController = null;
    private accountingViewController:ClientAccountingViewController = null;

    viewDidLoad(){
        super.viewDidLoad();
        
        this.debtFilterComboBox = MUIOutlet(this, "debt-filter-cb", "MUIComboBox");
        this.debtFilterComboBox.setHidden(true);
        this.debtFilterComboBox.setOnChangeAction(this, function(control, value){
            this.debtVC.filter = parseInt(value);
        });
        
        //SEGMENTED BUTTON
        this.segmentedControl = MUIOutlet(this, 'segmented-ctl', 'MUISegmentedControl');
        this.segmentedControl.setAction(this, function (control, index) {
            this.sectionsPageController.showPageAtIndex(index);
            this.debtFilterComboBox.hidden = index == 1 ? false : true;
        });

        this.saveButton = MUIOutlet(this, 'save-btn','MUIButton');
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        });

        this.optionsButton = MUIOutlet(this, 'options-btn','MUIButton');
        //this.optionsButton.setHidden(true);
        this.optionsButton.setAction(this, this.showClientActions);
        
        this.sectionsPageController = MUIOutlet(this, 'pdpage_controller', 'MUIPageController');
        this.dataVC = new ClientDataViewController("client-data-view");
        this.dataVC.initWithResource("layout/clients/ClientDataView.html");
        this.dataVC.navigationController = this.navigationController;
        this.sectionsPageController.addPageViewController(this.dataVC);
       
        this.debtVC = ClientDebtViewController.newInstance();
        this.debtVC.navigationController = this.navigationController;
        this.sectionsPageController.addPageViewController(this.debtVC);

        this.ticketVC = new ClientDocumentViewController("client-document-view");
        this.ticketVC.initWithResource("layout/clients/ClientDocumentView.html");
        this.ticketVC.type = ClientDocumentViewControllerType.Tickets;
        this.ticketVC.navigationController = this.navigationController;
        this.sectionsPageController.addPageViewController(this.ticketVC);
       
        this.invoiceVC = new ClientDocumentViewController("client-document-view");
        this.invoiceVC.initWithResource("layout/clients/ClientDocumentView.html");
        this.invoiceVC.type = ClientDocumentViewControllerType.Invoices;
        this.invoiceVC.navigationController = this.navigationController;        
        this.sectionsPageController.addPageViewController(this.invoiceVC);
       
        this.bookingsVC = new ClientBookingsViewController("client-booking-view");
        this.bookingsVC.initWithResource("layout/clients/ClientBookingView.html");
        this.bookingsVC.navigationController = this.navigationController;  
        this.sectionsPageController.addPageViewController(this.bookingsVC);
        
        this.loyaltyVC = new ClientLoyaltyViewController('client-loyalty-view');
        this.loyaltyVC.initWithResource('layout/clients/ClientLoyaltyView.html');
        this.loyaltyVC.navigationController = this.navigationController;  
        this.sectionsPageController.addPageViewController(this.loyaltyVC);

        this.accountVC = new ClientAccountViewController('client-account-view');
        this.accountVC.initWithResource('layout/clients/ClientAccountView.html');
        this.accountVC.navigationController = this.navigationController;  
        this.sectionsPageController.addPageViewController(this.accountVC); 

        this.accountingViewController = ClientAccountingViewController.newInstance();
        this.accountingViewController.navigationController = this.navigationController;
        this.sectionsPageController.addPageViewController( this.accountingViewController );
    }

    // viewWillAppear(animate?){
    //     super.viewWillAppear(animate);
    //     this.updateUI(); 
    // }

    private _client:Client = null;
    set client(value:Client){
        this._client = value; 
        let relationships = ["address", "phone", "mobilePhone", "accountBalance","loyaltyBalance","debtBalance", "defaultBankAccount"];
        DBHelper.queryObjectsWithCompletion("Client", null, MIOPredicate.predicateWithFormat("identifier == " + value.identifier), relationships, this, function(clients){
            this.updateUI();
        });        
    }
     
    private updateUI(){
        if(this.viewIsLoaded == false || this._client == null) return;

        this.dataVC.client = this._client;
        this.debtVC.client = this._client;        
        this.ticketVC.client = this._client;
        this.invoiceVC.client = this._client;
        this.bookingsVC.client = this._client;
        this.accountVC.client = this._client;
        this.loyaltyVC.client = this._client;
        this.accountingViewController.client = this._client;
     }

     private showClientActions(){
         if (this.segmentedControl.selectedSegmentedIndex == 1){
            this.debtVC.showOptions();
            return;
         }
         
         let avc = new MUIAlertViewController();
         avc.initWithTitle(MIOLocalizeString('ACTIONS','ACITONS'), MIOLocalizeString('OPTIONS','OPTIONS'), MUIAlertViewStyle.Default);
         
         avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("DELETE", "DELETE"), MUIAlertActionStyle.Destructive, this, function(){            
             DBHelper.deleteObjectFromMainContext(this._client, true);
         }));
  
         avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "cancel"), MUIAlertActionStyle.Cancel, null, null));
 
         this.presentViewController(avc, true);

     }
     
}