//
// Generated class PayMethodConfig
//

/// <reference path="PayMethodConfig_ManagedObject.ts" />

class PayMethodConfig extends PayMethodConfig_ManagedObject
{

}
