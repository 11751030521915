//
// Generated class TimetableSession
//

/// <reference path="TimetableSession_ManagedObject.ts" />

class TimetableSession extends TimetableSession_ManagedObject
{

}
