
class DeliveryOrderCell extends UITableViewCell
{
    private documentIDLabel:MUILabel = null;  
    private documentDateLabel:MUILabel = null;  
    private destinationNameLabel:MUILabel = null;

    awakeFromHTML(){
        this.documentIDLabel = MUIOutlet(this, "document-id-lbl", "MUILabel");
        this.documentDateLabel = MUIOutlet(this, "date-lbl", "MUILabel");
        this.destinationNameLabel = MUIOutlet(this, "destination-lbl", "MUILabel");
    }

    set item (item:StockNote){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        this.documentIDLabel.text = item.documentID;
        this.documentDateLabel.text = ad.dateFormatter.stringFromDate(item.stockDate);
        // this.destinationNameLabel.text = item.destinationName;
    }
}