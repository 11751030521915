/// <reference path="../notes/NoteLineCell.ts" />

class InventoryNoteLineCell extends NoteLineCell
{
    enterDidPress(){
        if (this.stockNoteLine != null) return;        
        if (this.quantity == null) return;
                
        let line = DBHelper.createInventoryNoteLine(this.product, this.inputFormat, this.inputType, this.quantity, this.productQuantity, this.minMeasureQuantity, this.stockNote.destinationWarehouse, this.stockNote);
        if (line != null) {
            line.minMeasureType = this.minMeasureUnitType;
            line.minMeasureQuantity = this.minMeasureQuantity;
            line.warehouse = this.stockNote.destinationWarehouse;
            line.warehouseName = this.stockNote.destinationWarehouse?.name;
            this.lineDidInserted(line);
            this.reset();
        }
    }
}
