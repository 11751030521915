
class TableMapViewController extends BaseViewController implements TableMapViewDataSource
{
    private locationCategoryDropdown:MUIButton = null;
    private titleLabel:MUILabel = null;
    private addLocationButton:MUIButton = null;
    private tableMapView:TableMapView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.locationCategoryDropdown = MUIOutlet(this, "zone-dd", "MUIButton");
        this.locationCategoryDropdown.setAction(this, function(this:TableMapViewController){
            AppHelper.sharedInstance().showSelectLocationCateogryViewControllerFromView( this.locationCategoryDropdown, null, this, this.categoryDidSelected, this.showAddLocationCategoryAlert );
        });

        this.titleLabel = MUIOutlet(this, "map-name-lbl", "MUILabel");

        this.addLocationButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addLocationButton.setAction(this, function(){
            this.showAddLocationAlert();
        });

        this.tableMapView = MUIOutlet(this, "table-map", "TableMapView");
        this.tableMapView.dataSource = this;
    }

    private selectedCategory:LocationCategory = null;
    private categoryDidSelected(controller:SelectEntityViewController, category:LocationCategory){
        this.locationCategoryDropdown.title = category.name;
        this.titleLabel.text = category.name;
        this.selectedCategory = category;
        this.invalidateData();
    }

    protected canReloadData():boolean{ 
        return (this.selectedCategory != null);
    }

    get fetchedResultsController() : MIOFetchedResultsController {
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let predicateFormat = "category.identifier == " + this.selectedCategory.identifier;
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Location", null, predicateFormat);        
        //fetchRequest.relationshipKeyPathsForPrefetching = ["category"];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    protected reloadData(){
        this.tableMapView.reloadData();
    }

    viewForBackground(tableMapView:TableMapView):MUIView {
        let type = this.selectedCategory.backgroundType;
        let url = TableMapBackgroundTypeURL(type);

        let iv = new MUIImageView();
        iv.initWithFrame(MIORect.rectWithValues(0, 0, tableMapView.getWidth(), tableMapView.getHeight()));
        iv.setImage(url);

        return iv;
    }

    numberOfTables(tableMapView:TableMapView):number {
        let info = this.fetchedResultsController.sections[0] as MIOFetchSection;
        return info.numberOfObjects();
    }
    
    viewAtIndex(tableMapView:TableMapView, index:number):MUIView {

        let v = tableMapView.dequeueReusableItemWithIdentifier("TableMapItem");

        let ip = MIOIndexPath.indexForRowInSection(index, 0);
        let l = this.fetchedResultsController.objectAtIndexPath(ip);

        v.location = l;

        let tapGesture = new MUITapGestureRecognizer();
        tapGesture.initWithTarget(this, this.tableMapItemDidTap);
        v.addGestureRecognizer(tapGesture);

        return v;
    }

    tableMapItemDidTap(gesture:MUIGestureRecognizer){
        if (gesture.state != MUIGestureRecognizerState.Ended) return;
        let item = gesture.view as TableMapItem;

        item.toggleSelected();
    }

    private showAddLocationCategoryAlert(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle("Add Location Category", "Please enter the name of the new location category", MUIAlertViewStyle.Alert);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
            textField.setPlaceholderText("Name");
        });
        
        avc.addAction(MUIAlertAction.alertActionWithTitle('OK', MUIAlertActionStyle.Default, this, function(){
            this.addLocationCategory(avc.textFields[0].text);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('OK', MUIAlertActionStyle.Cancel, null, null));
        
        this.presentViewController(avc, true);
    }

    private addLocationCategory(name:string){
        
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        let cat = MIOEntityDescription.insertNewObjectForEntityForName( "LocationCategory", moc ) as LocationCategory;
        cat.name = name;

        DBHelper.saveMainContext();
    }

    private showAddLocationAlert(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle("Add Location", "Please enter the name of the new location", MUIAlertViewStyle.Alert);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
            textField.setPlaceholderText("Name");
        });
        
        avc.addAction(MUIAlertAction.alertActionWithTitle('OK', MUIAlertActionStyle.Default, this, function(){
            this.addLocation( avc.textFields[0].text );
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('OK', MUIAlertActionStyle.Cancel, null, null));
        
        this.presentViewController(avc, true);
    }

    private addLocation(name:string){
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        let loc = MIOEntityDescription.insertNewObjectForEntityForName( "Location", moc ) as Location;
        loc.name = name;
        loc.type = MapItemType.table;
        loc.category = this.selectedCategory;

        DBHelper.saveMainContext();
        this.invalidateData();
    }

}