
class CashDeskSessionListHeaderView extends MUIView
{
    private titleLabel:MUILabel = null;    

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");        
    }

    set title(value){
        this.titleLabel.text = value;
    }
}

class CashDeskSessionListFooterView extends MUIView
{    
    private totalLabel:MUILabel = null;    

    awakeFromHTML(){
        this.totalLabel = MUIOutlet(this, "total-lbl", "MUILabel");        
    }

    set total(value){
        this.totalLabel.text = value;
    }

}