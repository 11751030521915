//
// Generated class IntegratorRawQueue
//

/// <reference path="IntegratorRawQueue_ManagedObject.ts" />

class IntegratorRawQueue extends IntegratorRawQueue_ManagedObject
{

}
