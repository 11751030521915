

/// <reference path="StockNote.ts" />

// Generated class BuyOrder_ManagedObject

class BuyOrder_ManagedObject extends StockNote
{

    // Property: commentsBySupplier
    set commentsBySupplier(value:any) {
        this.setValueForKey(value, 'commentsBySupplier');
    }
    get commentsBySupplier():any {
        return this.valueForKey('commentsBySupplier');
    }
    set commentsBySupplierPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'commentsBySupplier');
    }
    get commentsBySupplierPrimitiveValue():any {
        return this.primitiveValueForKey('commentsBySupplier');
    }
}
