//
// Generated class LocationCategoryAutoProduct
//

/// <reference path="LocationCategoryAutoProduct_ManagedObject.ts" />

class LocationCategoryAutoProduct extends LocationCategoryAutoProduct_ManagedObject
{

}
