
class OnlineOrderLineCell extends UITableViewCell
{    
    private productLabel:MUILabel = null;
    private taxLabel:MUILabel = null;
    private productPrice:MUILabel = null;
    // private quantityTextField:MUITextField = null;
    private quantityLabel:MUILabel = null;
    private discountLabel:MUILabel = null;
    private totalLabel:MUILabel = null;
    

    protected nf = (MUIWebApplication.sharedInstance().delegate as AppDelegate).integerFormatter;
    protected cf = MUIWebApplication.sharedInstance().delegate.longCurrencyFormatter;
        
    awakeFromHTML(){
        super.awakeFromHTML();
    
        this.productLabel = MUIOutlet(this, "product-lbl", "MUILabel");
        this.taxLabel = MUIOutlet(this, "tax-lbl", "MUILabel");
        this.productPrice = MUIOutlet(this, "unit-price-lbl", "MUILabel")
        this.quantityLabel = MUIOutlet(this, "quantity-lbl", "MUILabel");
        this.discountLabel = MUIOutlet(this, "discount-lbl", "MUILabel");        
        this.totalLabel = MUIOutlet(this, "total-lbl", "MUILabel");

        this.reset();

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }
    
    private _line:OnlineOrderLine = null;
    set line(item:OnlineOrderLine){
        this._line = item;
                
        let q = this.nf.stringFromNumber(item.quantity);             

        if (item.product != null) {
            MUICoreLayerAddStyle(this.productLabel.layer, "strong");
            this.productLabel.text = item.concept;
            this.productPrice.text = this.cf.stringFromNumber(item.product.price);
            this.quantityLabel.text = q;
        }
        else if (item.modifier != null) {            
            MUICoreLayerRemoveStyle(this.productLabel.layer, "strong");
            this.productLabel.text = ". " + q + " x " + item.concept;
            this.productPrice.text = item.modifier.priceFormat;
            this.quantityLabel.text = null;
        }

        this.taxLabel.text = item.tax?.name;
        this.discountLabel.text = item.discount != 0 ? this.cf.stringFromNumber(item.discount) : null;

        // if (this.quantityTextField != null) {
        //     this.quantityTextField.text = q
        //     this.quantityTextField.enabled = true;
        // }
        
        // this.productQuantityLabel.text = this.productQuantityString();
        this.totalLabel.text = this.cf.stringFromNumber(item.total);
    }
    
    productDidSelect(controller:SelectEntityViewController, product:Product){
        // this.setProduct(product);
        // this.productTextField.text = this.productNameString();
                
        // this.inputFormatDropdown.enabled = true;
        // this.inputFormatDropdown.title = product.defaultInputFormatString();                

        // this.quantityTextField.enabled = true;
        // this.quantityTextField.becomeFirstResponder();
    }

    // protected inputFormatDidSelect(controller:SelectInputFormatViewController, inputFormat:StockInputFormat, inputType:MeasureUnitType){
    //     super.inputFormatDidSelect(controller, inputFormat, inputType);
    //     this.inputFormatDropdown.title = this.inputFormatString();
    //     this.productQuantityLabel.text = this.productQuantityString();        
    // }
    
    // protected quantityDidChange(quantity){        
    //     super.quantityDidChange(quantity);        
    //     this.productQuantityLabel.text = this.productQuantityString();        
    // }

    enterDidPress(){
        // if (this.stockNoteLine != null) return;        
        // if (this.quantity == 0 || this.quantity == null) return;
                
        // let line = DBHelper.createOutputNoteLine(this.product, this.inputFormat, this.inputType, this.quantity, this.productQuantity, this.measureQuantity, this.stockNote);
        // if (line != null) {
        //     this.lineDidInserted(line);
        //     this.reset();
        // }
    }
    
    reset(){
        // this.product = null;        
        // this.inputFormat = null;
        // this.quantity = 0;
        // this.productQuantity = 0;
        // this.measureQuantity = 0;
        
        // if (this.productTextField != null) this.productTextField.text = null;

        // if (this.inputFormatDropdown != null) {
        //     this.inputFormatDropdown.title = null;
        //     this.inputFormatDropdown.enabled = false;            
        // }
        
        // if (this.quantityTextField != null) {
        //     this.quantityTextField.text = null;
        //     this.quantityTextField.enabled = false;
        // }
        // this.productQuantityLabel.text = null;
    }
}