


/// <reference path="../../../model/Rate.ts" />


class ProductRateCell extends MUITableViewCell
{
    private image:MUIView = null;       
    private nameLabel:MUILabel = null;
    private priceTextField:MUITextField = null;

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;        

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.priceTextField = MUIOutlet(this, 'price-tf', 'MUITextField');
        this.priceTextField.formatter = this.cf;        
        this.priceTextField.setOnChangeText(this, this.updateProductRatePrice);

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    private productRate:ProductRate = null;
    private rate:Rate = null;
    private product:Product = null;
    private _item = null;
    set item(i){
        this._item = i;
        this.product = i["Product"];
        this.rate = i["Rate"];
        this.productRate = i["ProductRate"];
        this.nameLabel.text = this.rate.name;
        this.priceTextField.setPlaceholderText(this.cf.stringFromNumber(this.product.price));
        this.priceTextField.text = this.productRate != null ? this.cf.stringFromNumber(this.productRate.price) : null;
    }

    private updateProductRatePrice(control:MUITextField, value:string){
        if (value.length > 0 && this.productRate != null) {
            this.productRate.price = this.cf.numberFromString(value);
        }
        else if (value.length > 0 && this.productRate == null) {            
            this.productRate = MIOEntityDescription.insertNewObjectForEntityForName("ProductRate", DBHelper.mainManagedObjectContext) as ProductRate;
            this.productRate.rate = this.rate;
            this.productRate.product = this.product;
            this.productRate.tax = this.product.tax;
            this.productRate.price = this.cf.numberFromString(value);
            this.product.addProductRatesObject(this.productRate);
        }
        else if (value.length == 0 && this.productRate != null) {
            DBHelper.deleteObjectFromMainContext(this.productRate, false);
            this._item["ProductRate"] = null;
        }
    }
}