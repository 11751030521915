//
// Generated class StockTemplate
//

/// <reference path="StockTemplate_ManagedObject.ts" />

enum StockTemplateType
{
    None            = 0,
    Inventory       = 1,
    SupplierOrder   = 2 
}

class StockTemplate extends StockTemplate_ManagedObject
{

}
