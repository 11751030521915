/**
 * Created by miguel on 22/2/17.
 */



class EditorLeftViewController extends MUIViewController
{
   

    viewDidLoad()
    {
        
    }
}