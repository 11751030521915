

class WarehouseViewController extends EntityListViewController
{
    private backButton:MUIButton = null;
    private addButton:MUIButton = null;

    private _product:Product = null;
    private _productWarehouses = [];
    private warehouseNames = {};
    private selectedWarehouses = {};

    public delegate = null;

    get preferredContentSize(){
        return new MIOSize(320, 500);
    }

    viewDidLoad(){
        super.viewDidLoad();

        this.activityIndicatorView = MUIOutlet(this, 'activity-indicator','MUIActivityIndicatorView');

        this.addButton = MUIOutlet(this, 'wv_nb_add_btn','MUIButton');
        this.addButton.setAction(this, function(){
            this.showAddWarehouseView();
        });

        this.tableView = MUIOutlet(this,"wv_c1_tableview", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        this.tableView.reloadData();

        this.backButton = MUIOutlet(this, 'wv_nb_back_btn', 'MUIButton');
        this.backButton.setAction(this, function () {
            if(this.navigationController != null)
                this.navigationController.popViewController(true);
            else
                this.dismissViewController(true);

            if(this.delegate != null && typeof this.delegate.selectedWarehouses === "function") {
                this.delegate.selectedWarehouses(this.selectedWarehouses);
            }
        });

    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        
        if(this.navigationController != null) {
        
            this.backButton.setHidden(false);
            this.backButton.setTitle(MIOLocalizeString('BACK', 'BACK'));
        }
        else {
            this.backButton.setHidden(true);
        }
        this.warehouseNames = {};
        this.fetchedResultsController = null;        
        this.tableView.reloadData();
    }

    set product(value){
        this._product = value;
        if(this._product != null)
        {
            let pw = this._product.productWarehouses;
            for(let i = 0; i < pw.length; i++)
            {
                let item = pw.objectAtIndex(i);
                this.selectedWarehouses[item.warehouse.identifier] = {selected:true, warehouse:item.warehouse};
            }
        }
        if(this.tableView)
            this.tableView.reloadData();
    }

    private showButtons()
    {
        return this._product == null;
    }

    numberOfSections(tableview:UITableView){
        return this._product != null ? this.fetchedResultsController.sections.length : this.fetchedResultsController.sections.length + 1;
    }

    numberOfRowsInSection(tableview:UITableView, section){
        let sec = null;
        if(this.showButtons()) {
            if(section == 0) return 2;
            else {
                sec = this.fetchedResultsController.sections[section - 1];
                return sec.numberOfObjects();
            }
        }
        sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell:WarehouseCell | WarehouseButtonCell = null;
        let newIndexPath:MIOIndexPath = null;
        let item:Warehouse = null;

        if(this.showButtons()) {
            if(indexPath.section == 0) {
                cell = tableview.dequeueReusableCellWithIdentifier("WarehouseButtonCell") as WarehouseButtonCell;
                cell.separatorStyle = UITableViewCellSeparatorStyle.None;
                cell.setSelected(false);
                if (indexPath.row == 0) {
                    cell.title = MIOLocalizeString("_ALL_WAREHOUSES_", "All Warehouses");
                }
                else {
                    cell.title = MIOLocalizeString("_DEFAULT_WAREHOUSE_", "Default Warehouse");
                }                
            }
            else {
                cell = tableview.dequeueReusableCellWithIdentifier("WarehouseCell") as WarehouseCell;
                newIndexPath = MIOIndexPath.indexForRowInSection(indexPath.row, indexPath.section -1);    
                item = this.fetchedResultsController.objectAtIndexPath(newIndexPath);
                cell.item = item;
            }
            return cell;
        }
        
        cell = tableview.dequeueReusableCellWithIdentifier("WarehouseCell") as WarehouseCell;
        item = this.fetchedResultsController.objectAtIndexPath(indexPath);

        cell.item = item;
        cell.separatorStyle = UITableViewCellSeparatorStyle.None;
        cell.setSelected(false);

        if(this.navigationController != null) {
            cell.selectionStyle = UITableViewCellSelectionStyle.None;
            let warehouseObjectIndex =  Object.keys(this.selectedWarehouses).indexOf(item.identifier);
            
            if(warehouseObjectIndex >= 0 && this.selectedWarehouses[item.identifier].selected == true) {
                this.selectedWarehouses[item.identifier] = {selected : true, warehouse : item};
                cell.setAccessoryType(UITableViewCellAccessoryType.Checkmark); 
            }
            else {
                this.selectedWarehouses[item.identifier] = {selected : false};
                cell.setAccessoryType(UITableViewCellAccessoryType.None);     
            }
        }
        return cell;
    }


    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let warehouse = null;
        let defaultWarehouse = false;

        if(this.showButtons()){
            if(indexPath.section == 0)
            {
                warehouse = indexPath.row == 0 ? null : -1;
                defaultWarehouse = indexPath.row == 0 ? false : true;
            }
            else {
                let newIndexPath = MIOIndexPath.indexForRowInSection(indexPath.row, indexPath.section -1);
                warehouse = this.fetchedResultsController.objectAtIndexPath(newIndexPath);
                defaultWarehouse = false;
            }
        }
        else {
            warehouse = this.fetchedResultsController.objectAtIndexPath(indexPath);
            defaultWarehouse = false;
        }

        if(this.navigationController != null) {
           let warehouseObjectIndex =  Object.keys(this.selectedWarehouses).indexOf(warehouse.identifier);
            
            if(warehouseObjectIndex < 0 || this.selectedWarehouses[warehouse.identifier].selected == false) {
                this.selectedWarehouses[warehouse.identifier] = { selected : true, warehouse: warehouse};
                this.tableView.cellAtIndexPath(indexPath).setAccessoryType(UITableViewCellAccessoryType.Checkmark);
            }
            else {
                this.selectedWarehouses[warehouse.identifier] = {selected : false};
                this.tableView.cellAtIndexPath(indexPath).setAccessoryType(UITableViewCellAccessoryType.None);
            } 
            this.tableView.deselectRowAtIndexPath(indexPath, true);
        }
        else {
            if( this.delegate != null && typeof this.delegate.warehouseDidChange == "function") {
                this.delegate.warehouseDidChange(this, warehouse, defaultWarehouse);
            }
            this.dismissViewController(true);
        }
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Warehouse", sortDescriptors, null);
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;            
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    private showAddWarehouseView(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('ADD NEW WARWEHOUSE','Add new Warehouse'), 'Type name ', MUIAlertViewStyle.Default);
        
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("Name");
        });
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','Done'), MUIAlertActionStyle.Default, this, function(){
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
            
            let nameTF = avc.textFields[0];
            let name = nameTF.text;
            if(name.length <= 0) {
                AppHelper.showErrorMessage(avc, 'Error','Check name ');
                return;
            }
            
            let warehouse:Warehouse = MIOEntityDescription.insertNewObjectForEntityForName("Warehouse", moc) as Warehouse;
            warehouse.identifier = MIOUUID.UUID().UUIDString;
            warehouse.name = name;
            moc.save();
               
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Cancel, this, function(){}));

        this.presentViewController(avc, true);
    }

}