

// Generated class Language_ManagedObject

class Language_ManagedObject extends MIOManagedObject
{

    // Property: deletedAt
    set deletedAt(value:Date) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():Date {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('deletedAt');
    }

    // Property: identifier
    set identifier(value:number) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():number {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():number {
        return this.primitiveValueForKey('identifier');
    }

    // Property: iso2
    set iso2(value:string) {
        this.setValueForKey(value, 'iso2');
    }
    get iso2():string {
        return this.valueForKey('iso2');
    }
    set iso2PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'iso2');
    }
    get iso2PrimitiveValue():string {
        return this.primitiveValueForKey('iso2');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }
}
