//
// Generated class Distributor
//

/// <reference path="Distributor_ManagedObject.ts" />

class Distributor extends Distributor_ManagedObject
{

}
