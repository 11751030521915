class DBRatesCoder extends DBXLSXCoder
{
    protected mainEntity() { 
        return { entity: "Rate", predicateFormat: "deletedAt == null" };
    }

    columns() : any[] {
        let cols = [
            { title: "RATE IDENTIFIER", width: "30%", align: "center" },
            { title: "RATE NAME", width: "30%", align: "center" },
            { title: "RULE FORMAT", width: "10%", align: "center" },
            { title: "ROUND TYPE", width: "10%", align: "center" }
        ]
        return cols;
    }

    protected importRow(row){
                
        let rateID      = row["RATE IDENTIFIER"];
        let name        = row["RATE NAME"];
        let ruleFormat  = row["RULE FORMAT"];
        let roundType   = row["ROUND TYPE"];

        let rate:Rate = this.queryEntityByIDOrName("Rate", rateID, name);
        if (rate == null) {
            rate = MIOEntityDescription.insertNewObjectForEntityForName("Rate", DBHelper.mainManagedObjectContext) as Rate;
            rate.identifier = this.parseOrCreateIdentifier(rateID);
        }

        rate.name = name;
        rate.roundType = roundType;
        rate.rule = ruleFormat;

        this.appendObject(rate);
        MIOLog("ADDING RATE: " + this.rowIndex + "/" + this.rows.length + ": " + name);
    }

    protected exportTitle() : string { return "Rates"; }
                
    protected exportSortDescriptors() { 
        return [MIOSortDescriptor.sortDescriptorWithKey("name", false)]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let rate = object as Rate;
        let item = {
            "RATE IDENTIFIER" : rate.identifier,
            "RATE NAME"       : rate.name,
            "RULE FORMAT"     : rate.rule,
            "ROUND TYPE"      : rate.roundType
        }

        return item;
    }
}