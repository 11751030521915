//
// Generated class SupplierOrder
//

/// <reference path="SupplierOrder_ManagedObject.ts" />

class SupplierOrder extends SupplierOrder_ManagedObject
{

}
