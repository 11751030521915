//
// Generated class IntegratorMailchimpQueue
//

/// <reference path="IntegratorMailchimpQueue_ManagedObject.ts" />

class IntegratorMailchimpQueue extends IntegratorMailchimpQueue_ManagedObject
{

}
