
class LoanDetailViewController extends BaseTableViewController
{    
    static newInstance() : LoanDetailViewController {
        let vc = new LoanDetailViewController("loan-detail-view");
        vc.initWithResource("layout/loans/LoanDetailView.html");
        return vc;
    }

    private titleLabel:MUILabel = null;  

    private actionButton:MUIButton = null;

    private referenceLabel:MUILabel = null;
    private totalAmountLabel:MUILabel = null;  

    viewDidLoad(){
        super.viewDidLoad();

        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");

        this.actionButton = MUIOutlet(this, "action-btn", "MUIButton");
        this.actionButton.setAction(this, this.showActions);

        this.referenceLabel = MUIOutlet(this, "reference-lbl", "MUILabel");
        this.totalAmountLabel = MUIOutlet(this, "total-amount-lbl", "MUILabel");

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;          
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _loan:Loan = null;
    set item(loan:Loan) {
        this._loan = loan;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._loan == null) return;

        this.titleLabel.text = this._loan.name;
        this.referenceLabel.text = this._loan.reference;
        this.totalAmountLabel.text = (MUIWebApplication.sharedInstance().delegate as AppDelegate).currencyFormatter.stringFromNumber( this._loan.capitalAmount );

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let cell = tableView.dequeueReusableCellWithIdentifier('LoanInstallmentCell') as LoanInstallmentCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as LoanPaymentInstallment;
        
        // cell.item = item;

        return cell;            
    }
    
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){        
        this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath);
        this.showSelectedItemViewController();
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('date', true)];
        let predicateFormat = 'loan.identifier == ' + this._loan.identifier;
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("LoanPaymentInstallment", sortDescriptors, predicateFormat);
        // fetchRequest.relationshipKeyPathsForPrefetching = [];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    private showActions()
    {
        let avc = new MUIAlertViewController();
        avc.initWithTitle( MIOLocalizeString("OPTIONS", "OPTIONS"), MIOLocalizeString("CHOOSE_AN_OPTION","CHOOSE_AN_OPTION"), MUIAlertViewStyle.ActionSheet);

        avc.addAction(MUIAlertAction.alertActionWithTitle( MIOLocalizeString("IMPORT_INSTALLMENTS", "IMPORT_INSTALLMENTS"), MUIAlertActionStyle.Default, this, function(this:LoanDetailViewController){
            this.importLoanInstallments();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle( MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null) );

        this.presentViewController(avc, true);
    }

    private importLoanInstallments(){
        let panel = MUIOpenPanel.openPanel();
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        panel.beginSheetModalForWindow(ad.window, this, function(this:LoanDetailViewController, result:number){
            if (result == MIOFileHandlingPanel.OKButton) {
               let file = panel.files[0];
               let ws = ad.webService;
       
               DLDB.sharedInstance().importLoadInstallments(this._loan, file, (result:boolean) => {
                   if (result == false){
                        AppHelper.showErrorMessage(this, "ERROR", "Couldn't upload image. Please try again later.");
                   }
                   else {
                        AppHelper.showInfoMessage(this, "OPERATION_STATUS", "OPERATION_SUCCESSFULLY_COMPLETED");
                   }
               });       
            }       
         });
    }    
}