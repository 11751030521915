//
// Generated class CategoryPlace
//

/// <reference path="CategoryPlace_ManagedObject.ts" />

class CategoryPlace extends CategoryPlace_ManagedObject
{

}
