

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ServiceCategory_ManagedObject

class ServiceCategory_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Relationship: services
    protected _services:MIOManagedObjectSet = null;
    get services():MIOManagedObjectSet {
        return this.valueForKey('services');
    }
    addServicesObject(value:Service) {
        this._addObjectForKey(value, 'services');
    }
    removeServicesObject(value:Service) {
        this._removeObjectForKey(value, 'services');
    }
}
