

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Department_ManagedObject

class Department_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Relationship: workers
    protected _workers:MIOManagedObjectSet = null;
    get workers():MIOManagedObjectSet {
        return this.valueForKey('workers');
    }
    addWorkersObject(value:Employee) {
        this._addObjectForKey(value, 'workers');
    }
    removeWorkersObject(value:Employee) {
        this._removeObjectForKey(value, 'workers');
    }
}
