//
// Generated class DeliveryTimeRangePresetConfiguration
//

/// <reference path="DeliveryTimeRangePresetConfiguration_ManagedObject.ts" />

class DeliveryTimeRangePresetConfiguration extends DeliveryTimeRangePresetConfiguration_ManagedObject
{

}
