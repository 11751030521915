//
// Generated class CashDeskSummaryItem
//

/// <reference path="CashDeskSummaryItem_ManagedObject.ts" />

class CashDeskSummaryItem extends CashDeskSummaryItem_ManagedObject
{
    headerType = 0;
    cellType = 0;
    formatCellType = 0;

    col1:string = null;
    col2:string = null;
    col3:string = null;
    col4:string = null;
    col5:string = null;
    col6:string = null;
    col7:string = null;
    col8:string = null;

    value1 = null;
    value2 = null;
    value3 = null;
    value4 = null;
    value5 = null;
    value6 = null;
    value7 = null;
    value8 = null;

    setObject(object){
        this.title = object['title'];
        this.section = object['section'].toString();
        this.isTotal = object['isTotal'];
        this.isSubtotal = object['isSubtotal'];
        this.cashDeskSession = object['cashdesksession'];
        this.isHeader = object['isHeader'] ? object['isHeader'] : false;
        this.isCurrency = (object['isCurrency'] === false ? false : true);
        this.headerType = object["headerType"] ? object["headerType"] : 0;
        this.cellType = object["cellType"] ? object["cellType"] : 0;
        this.formatCellType = object["formatCellType"] ? object["formatCellType"] : 0;

        this.col1 = object['col1'];
        this.col2 = object['col2'];
        this.col3 = object['col3'];
        this.col4 = object['col4'];
        this.col5 = object['col5'];
        this.col6 = object['col6'];  
        this.col7 = object['col7']; 
        this.col8 = object['col8'];       
        
        this.value1 = object['val1'];
        this.value2 = object['val2'];
        this.value3 = object['val3'];
        this.value4 = object['val4'];
        this.value5 = object['val5'];
        this.value6 = object['val6'];
        this.value7 = object['val7'];
        this.value8 = object['val8'];
    }
}
