

/// <reference path="AccountBalance.ts" />

// Generated class CashDeskBalance_ManagedObject

class CashDeskBalance_ManagedObject extends AccountBalance
{

    // Property: collectedAmount
    set collectedAmount(value:number) {
        this.setValueForKey(value, 'collectedAmount');
    }
    get collectedAmount():number {
        return this.valueForKey('collectedAmount');
    }
    set collectedAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'collectedAmount');
    }
    get collectedAmountPrimitiveValue():number {
        return this.primitiveValueForKey('collectedAmount');
    }

    // Property: salesAmount
    set salesAmount(value:number) {
        this.setValueForKey(value, 'salesAmount');
    }
    get salesAmount():number {
        return this.valueForKey('salesAmount');
    }
    set salesAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'salesAmount');
    }
    get salesAmountPrimitiveValue():number {
        return this.primitiveValueForKey('salesAmount');
    }

    // Property: sessionDate
    set sessionDate(value:Date) {
        this.setValueForKey(value, 'sessionDate');
    }
    get sessionDate():Date {
        return this.valueForKey('sessionDate');
    }
    set sessionDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'sessionDate');
    }
    get sessionDatePrimitiveValue():Date {
        return this.primitiveValueForKey('sessionDate');
    }
    // Relationship: cashdesk
    set cashdesk(value:CashDesk) {
        this.setValueForKey(value, 'cashdesk');
    }
    get cashdesk():CashDesk {
        return this.valueForKey('cashdesk') as CashDesk;
    }
}
