//
// Generated class Booking_BookingZone
//

/// <reference path="Booking_BookingZone_ManagedObject.ts" />

class Booking_BookingZone extends Booking_BookingZone_ManagedObject
{

}
