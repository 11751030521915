/**
 * Created by godshadow on 04/08/16.
 */

enum SetupType 
{
    Company,
    Settings,
    VAT,
    LegalTax,
    LegalTaxType,
    PayMethods,
    PayEntities,
    TimeRangePreset,
    Departments,
    Warehouse,
    Online,
    StockCustomConcept,
    Format,
    Tags,
    ProductSource,
    AttributeType,
    BookingsChannels,
    BookingsRecommendation,
    BookingsZones,
    BookingsZonesSetup,
    CommunicationTemplate,
    SerialSequence,
    AccountSequence,
    Licenses,
    ElaborationCenter,
    KitchenOrder,
    CustomConpcept,
    Changelog,
    LedgerAccount,
    LicenseType,
    QRLocationType,
    WineCellar,
    ProductAttributeType,
    StockCategory,
    ServiceStatus,
    LeaveReasonType,
    AnalyticAccount
}
class SetupViewController extends BaseViewController
{
    private tableView:UITableView = null;

    private _current = {};
    private noDetailViewController = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.tableView.reloadData();
    }

    numberOfSections( tableview:UITableView ){
        return 1;
    }

    numberOfRowsInSection(tableview:UITableView, section:number){
        return this.items.length;
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("SetupCell") as SetupCell;

        let s = this.items[indexPath.row];
        cell.title = s.name;

        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        this._current = this.items[indexPath.row];
        this._showCurrentItem();
    }

    private _showCurrentItem(){
        let sdvc = null;

        switch(this._current['type'])
        {
            case SetupType.Company:
                sdvc = CompanyViewController.newInstance();                
                break;

            case SetupType.Settings:
                sdvc = new PlaceSettingsViewController('place-settings-view');
                sdvc.initWithResource('layout/mainsettings/PlaceSettingsView.html');
                break;

            case SetupType.LicenseType: sdvc = LicenseTypeViewController.newInstance(); break;
            case SetupType.LeaveReasonType: sdvc = LeaveReasonTypeViewController.newInstance(); break;

            case SetupType.Departments:
                sdvc = new DepartmentViewController("single-edit-view");
                sdvc.initWithResource("layout/base/SingleEditView.html");
                break;

            case SetupType.CustomConpcept:
                sdvc = new CustomConceptViewController("single-edit-view");
                sdvc.initWithResource("layout/base/SingleEditView.html");
                break;    

            case SetupType.VAT:
                let vlvc = VATListViewController.newInstance();
                sdvc = new MUINavigationController();
                sdvc.initWithRootViewController(vlvc);
                break;

            case SetupType.LegalTax    : sdvc = LegalTaxViewController.newInstance()     ; break;
            case SetupType.LegalTaxType: sdvc = LegalTaxTypeViewController.newInstance() ; break;
            case SetupType.PayMethods  : sdvc = PaymethodListViewController.newInstance(); break;
            case SetupType.PayEntities : sdvc = PaymentEntityViewController.newInstance(); break;

            case SetupType.BookingsChannels:
                sdvc = new ChannelViewController('channel-view');
                sdvc.initWithResource('layout/bookings/channel/ChannelView.html');
                break;

            case SetupType.Warehouse:
                let wlvc = WarehouseListViewController.newInstance();
                sdvc = new MUINavigationController();
                sdvc.initWithRootViewController(wlvc);
                break;

            case SetupType.Online:
                let onvc = new OnlineSettingsViewController('online-settings-view');
                onvc.initWithResource('layout/onlinesettings/OnlineSettingsView.html');
                sdvc = new MUINavigationController('online_nav_controller');
                sdvc.initWithRootViewController(onvc);
                break;

            case SetupType.StockCustomConcept:
                sdvc = new StockCustomConceptViewController('single-edit-view');
                sdvc.initWithResource('layout/base/SingleEditView.html');
                break;

            // case SetupType.StockCategory:
            //     sdvc = StockCategoryViewController.newInstance();
            //     break;

             case SetupType.BookingsZones:
                sdvc = new BookingsZonesSetupViewController('bookings-zones-setup-view');
                sdvc.initWithResource('layout/bookings/zones/BookingsZonesSetupView.html');
                break;

            case SetupType.ProductSource:
                sdvc = new ProductSourceSetupViewController('single-edit-view');
                sdvc.initWithResource('layout/base/SingleEditView.html');
                break;

            case SetupType.Format:
                sdvc = SettingsFormatViewController.newInstance();                
                break;

            case SetupType.Tags:
                sdvc = new TagsConfigurationViewController("tags-configuration-view");
                sdvc.initWithResource("layout/tags/TagsConfigurationView.html");
                break;   

            case SetupType.AttributeType:
                let atvc:AttributeTypeViewController = new AttributeTypeViewController('attribute-type-view');
                atvc.initWithResource('layout/modals/attributetype/AttributeTypeView.html');
                sdvc = new MUINavigationController('sat_nav_controller');
                sdvc.initWithRootViewController(atvc);
                break;

            // case SetupType.BookingsZonesDays:
            //     sdvc = new BookingsZonesDaySetupViewController("bookings-zones-day-setup-view");
            //     sdvc.initWithResource('layout/bookings/zones/BookingsZonesDaySetupView.html');
            //     break;

            case SetupType.BookingsZonesSetup:
                sdvc = new BookingZoneSetupViewController("booking-zone-setup");
                sdvc.initWithResource("layout/bookingzones/BookingZoneSetupView.html");
                break;


            case SetupType.TimeRangePreset:
                let trpvc = new TimeRangePresetListViewController("time-range-preset-list-view");
                trpvc.initWithResource("layout/timerangepreset/TimeRangePresetListView.html");
                sdvc = new MUINavigationController('timerangepreset_nav_controller');
                sdvc.initWithRootViewController(trpvc);
                break;

            case SetupType.BookingsRecommendation:
                sdvc = new BookingRecommendationViewController("single-edit-view");
                sdvc.initWithResource("layout/base/SingleEditView.html");
                break;

            case SetupType.CommunicationTemplate: sdvc = CommunicationTemplateViewController.newInstance(); break;
            case SetupType.SerialSequence: sdvc = SerialSequenceViewController.newInstance(); break;
            case SetupType.AccountSequence: sdvc = AccountSerialSequenceViewController.newInstance(); break;

            case SetupType.Licenses:
                sdvc = new LicensesDetailViewController("licenses-detail-view");
                sdvc.initWithResource("layout/licenses/LicensesDetailView.html");
                break;

            case SetupType.ElaborationCenter:
                sdvc = new ElaborationCenterSetupViewController('single-edit-view');
                sdvc.initWithResource('layout/base/SingleEditView.html');
                break;

            case SetupType.KitchenOrder:
                sdvc = new KitchenOrderSetupViewController('single-edit-view');
                sdvc.initWithResource('layout/base/SingleEditView.html');
                break;

            case SetupType.Changelog:
                sdvc = new ChangelogViewController('changelog-view');
                sdvc.initWithResource('layout/changelog/ChangelogView.html');
                break;

            case SetupType.LedgerAccount: sdvc = LedgerAccountViewController.newInstance(); break;
            case SetupType.AnalyticAccount: sdvc = AnalyticAccountViewController.newInstance(); break;
            case SetupType.QRLocationType: sdvc = QRLocationViewController.newInstance(); break;
            case SetupType.WineCellar: sdvc = WineCellarConfigViewController.newInstance(); break;

            case SetupType.ProductAttributeType:
                let patcvc = ProductAttributeTypeConfigViewController.newInstance();
                sdvc = new MUINavigationController();
                sdvc.initWithRootViewController(patcvc);
                break;

            case SetupType.StockCategory:
                let sccvc = StockCategoryConfigViewController.newInstance();
                sdvc = new MUINavigationController();
                sdvc.initWithRootViewController(sccvc);
                break;

            case SetupType.ServiceStatus: sdvc = ServiceStatusViewController.newInstance(); break;
        }

        this.splitViewController.showDetailViewController(sdvc);
    }

    private _showNoItemSelected(){
        this._current = null;
        if(!this.noDetailViewController) {
            this.noDetailViewController = new MUIViewController("setup-no-select-view");
            this.noDetailViewController.initWithResource("layout/setup/SetupNoSelectView.html");
        }
        this.splitViewController.showDetailViewController(this.noDetailViewController);
    }

    private _items = null;
    private get items(){
        if (this._items != null) return this._items;

        this.addItem('COMPANY', SetupType.Company);
        this.addItem('GENERAL', SetupType.Settings);
        if ( ( MUIWebApplication.sharedInstance().delegate as AppDelegate ).dlOptionEnabled ) {
            this.addItem('LICENSE TYPE', SetupType.LicenseType);
            this.addItem('LEAVE REASON TYPE', SetupType.LeaveReasonType);
        }
        this.addItem('VAT', SetupType.VAT);
        this.addItem('LEGAL TAX', SetupType.LegalTax);
        this.addItem('LEGAL TAX TYPE', SetupType.LegalTaxType);
        this.addItem('LEDGER ACCOUNTS', SetupType.LedgerAccount);
        this.addItem('ANALYTIC ACCOUNTS', SetupType.AnalyticAccount);
        this.addItem('PAY METHODS', SetupType.PayMethods);
        this.addItem('PAYMENT ENTITIES', SetupType.PayEntities);
        //this.addItem('TIME RANGES', SetupType.TimeRanges);
        this.addItem('DEPARTMENTS', SetupType.Departments);
        this.addItem('WAREHOUSES', SetupType.Warehouse);
        this.addItem('WINE CELLAR', SetupType.WineCellar);
        this.addItem('PRODUCT ATTRIBUTE TYPE', SetupType.ProductAttributeType);
        this.addItem('STOCK CATEGORY', SetupType.StockCategory);
        this.addItem('CUSTOM CONCEPT', SetupType.CustomConpcept);
        //this.addItem('SCHEDULE MANAGEMENT', SetupType.TimeRangePreset);
        this.addItem('ONLINE', SetupType.Online);
        this.addItem('SERVICE STATUS', SetupType.ServiceStatus);
        this.addItem('PRODUCT SOURCE', SetupType.ProductSource);
        this.addItem('FORMATS', SetupType.Format);
        this.addItem('ELABORATION CENTER', SetupType.ElaborationCenter);
        this.addItem('TAGS', SetupType.Tags);
        this.addItem('ATTRIBUTES', SetupType.AttributeType);
        this.addItem('STOCK CUSTOM CONCEPTS', SetupType.StockCustomConcept);
        // this.addItem('STOCK CATEGORY', SetupType.StockCategory);
        //this.addItem('BOOKING ZONES', SetupType.BookingsZones);
        //this.addItem('BOOKING ZONES PER DAY', SetupType.BookingsZonesDays);
        // this.addItem('SERIAL SEQUENCE', SetupType.SerialSequence);
        this.addItem('BOOKING ZONES SETUP', SetupType.BookingsZonesSetup);
        this.addItem('BOOKING CHANNELS', SetupType.BookingsChannels);
        this.addItem('BOOKING RECOMENDATIONS', SetupType.BookingsRecommendation);
        this.addItem('COMMUNICATION TEMPLATES', SetupType.CommunicationTemplate);
        this.addItem('NUMBER SERIAL SEQUENCE', SetupType.SerialSequence);
        this.addItem('ACCOUNT NUMBER SERIAL SEQUENCE', SetupType.AccountSequence);
        this.addItem('LICENSES', SetupType.Licenses);
        this.addItem('KITCHEN ORDER', SetupType.KitchenOrder);
        this.addItem('CHANGELOG', SetupType.Changelog);
        this.addItem('QR TABLE URL', SetupType.QRLocationType);

        return this._items;
    }

    private addItem(name:string, type, section?){
        if (this._items == null) this._items = [];

        let item = {};
        item["name"] = name;
        item["type"] = type;
        if (section != null) item["section"] = section;

        this._items.push(item);
    }

}
