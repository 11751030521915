class DBStockCategoryCoder extends DBXLSXCoder
{
    mainEntity() {
        return { entity: "StockCategory", predicateFormat: "deletedAt == null" }
    }

    columns() : any[] {
        let cols = [
            { title:"ROOT STOCK CATEGORY ID", width: "10%", align: "center" },
            { title:"ROOT STOCK CATEGORY NAME", width: "10%", align: "center" },
            { title:"LEVEL1 STOCK CATEGORY ID", width: "10%", align: "center" },
            { title:"LEVEL1 STOCK CATEGORY NAME", width: "10%", align: "center" },
            { title:"LEVEL2 STOCK CATEGORY ID", width: "10%", align: "center" },
            { title:"LEVEL2 STOCK CATEGORY NAME", width: "10%", align: "center" },
            { title: "PLACE ID", width: "10%", align: "center" }
        ]
        return cols;
    }

    protected importRow(row:any){
                
        let rooID      = row["ROOT STOCK CATEGORY ID"];
        let rootName   = row["ROOT STOCK CATEGORY NAME"];        

        let level1ID   = row["LEVEL1 STOCK CATEGORY ID"];
        let level1Name = row["LEVEL1 STOCK CATEGORY NAME"];

        let level2ID   = row["LEVEL2 STOCK CATEGORY ID"];
        let level2Name = row["LEVEL2 STOCK CATEGORY NAME"];

        if ( rootName == null || rootName.length == 0 ) { return; }

        let rootStockCategory = this.queryEntityByIDOrName("StockCategory", rooID, rootName) as StockCategory;
        if (rootStockCategory == null && rootName.length > 0) {
            rootStockCategory = MIOEntityDescription.insertNewObjectForEntityForName("StockCategory", DBHelper.mainManagedObjectContext) as StockCategory;
            rootStockCategory.identifier = this.parseOrCreateIdentifier(rooID);
            rootStockCategory.name = rootName;
            this.appendObject(rootStockCategory);
        }

        let level1StockCategory = this.queryEntityByIDOrName("StockCategory", level1ID, level1Name) as StockCategory;
        if (level1StockCategory == null && level1Name?.length > 0) {
            level1StockCategory = MIOEntityDescription.insertNewObjectForEntityForName("StockCategory", DBHelper.mainManagedObjectContext) as StockCategory;
            level1StockCategory.identifier = this.parseOrCreateIdentifier(level1ID);
            level1StockCategory.name = level1Name;
            this.appendObject(level1StockCategory);            
        }

        if (level1StockCategory != null) level1StockCategory.superCategory = rootStockCategory;

        let level2StockCategory = this.queryEntityByIDOrName("StockCategory", level2ID, level2Name) as StockCategory;
        if (level2StockCategory == null && level2Name?.length > 0) {
            level2StockCategory = MIOEntityDescription.insertNewObjectForEntityForName("StockCategory", DBHelper.mainManagedObjectContext) as StockCategory;
            level2StockCategory.identifier = this.parseOrCreateIdentifier(level2ID);
            level2StockCategory.name = level2Name;
            this.appendObject(level2StockCategory);            
        }

        if (level1StockCategory != null && level2StockCategory != null) level2StockCategory.superCategory = level1StockCategory;
                
        MIOLog("ADDING STOCK CATEGORY: " + this.rowIndex + "/" + this.rows.length + ": " + rootName + (level1Name?.length > 0 ? "/" + level1Name : "") + (level2Name?.length > 0 ? "/" + level2Name : ""));
    }

    protected exportTitle() : string { return "Stock Categories"; }

    protected exportSortDescriptors() { 
        return [MIOSortDescriptor.sortDescriptorWithKey("name", false)]; 
    }

    protected parseObjects(objects:MIOManagedObject[]) : any[] { 
        let items = [];

        let objs = _MIOPredicateFilterObjects(objects, MIOPredicate.predicateWithFormat("superCategory == null"));

        for (let index1 = 0; index1 < objs.length; index1++) {
            let sc1 = objs[index1] as StockCategory;
            
            if (sc1.subCategories.count == 0) {
                let item = this.parseStockCategories(sc1, null, null);
                items.addObject(item);
            }
            else {
                for (let index2 = 0; index2 < sc1.subCategories.count; index2++) {
                    let sc2 = sc1.subCategories.objectAtIndex(index2) as StockCategory;
                    if (sc2.subCategories.count == 0) {
                        let item = this.parseStockCategories(sc1, sc2, null);
                        items.addObject(item);
                    }
                    else {
                        for (let index3 = 0; index3 < sc2.subCategories.count; index3++) {
                            let sc3 = sc2.subCategories.objectAtIndex(index3) as StockCategory;
                            let item = this.parseStockCategories(sc1, sc2, sc3);
                            items.addObject(item);
                        }
                    }
                }
            }                        
        }

        return items;
    }


    protected parseStockCategories(sc1:StockCategory, sc2:StockCategory, sc3:StockCategory): any {
        return {
            "ROOT STOCK CATEGORY ID"     : sc1.identifier,
            "ROOT STOCK CATEGORY NAME"   : sc1.name,
            "LEVEL1 STOCK CATEGORY ID"   : sc2?.identifier,
            "LEVEL1 STOCK CATEGORY NAME" : sc2?.name,
            "LEVEL2 STOCK CATEGORY ID"   : sc3?.identifier,
            "LEVEL2 STOCK CATEGORY NAME" : sc3?.name,
            "PLACE ID"                   : sc1.placeID
        }
    }
}