//
// Generated class CustomOutputNote
//

/// <reference path="CustomOutputNote_ManagedObject.ts" />

class CustomOutputNote extends CustomOutputNote_ManagedObject
{

}
