class DLRequest extends MWSJSONRequest
{
    constructor(url:string, body:any = null, method:string = "GET") {
        super();
        this.initWithURL( MIOURL.urlWithString(url), body, method );

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;        
        this.setHeaderValue(ad.selectedIdentifier, "DL-PLACE-ID");
        this.setHeaderValue(ad.selectedUser.identifier.toUpperCase(), "DL-WORKER-ID");        
        this.setHeaderValue(ad.selectedUser.email, "DL-WORKER-NAME");
    }

    execute(completion:any) {
        super.execute(this, function(code:number, json:any){
            if (code != 200) { completion(null, "Error Code: " + String(code)); return; }
            if (json == null) { completion(null, "Invalid response"); return; }
            let status = json["status"];
            if (status == null) { completion(null, "Missing status response"); return; }            
            if (typeof status == "string" && status.toLowerCase() != "ok") { completion(null, "ERROR: " + json["error"]); return; }
            if (typeof status == "number" && status != 0) { completion(null, "ERROR: " + json["error"]); return; }
            let data = json["data"];
            if (data == null) { completion(null, null); return; }
            
            completion(data, null);
        });
    }
}
