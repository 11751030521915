

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class StockTemplate_ManagedObject

class StockTemplate_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: scope
    set scope(value:number) {
        this.setValueForKey(value, 'scope');
    }
    get scope():number {
        return this.valueForKey('scope');
    }
    set scopePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'scope');
    }
    get scopePrimitiveValue():number {
        return this.primitiveValueForKey('scope');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Relationship: items
    protected _items:MIOManagedObjectSet = null;
    get items():MIOManagedObjectSet {
        return this.valueForKey('items');
    }
    addItemsObject(value:StockTemplateItem) {
        this._addObjectForKey(value, 'items');
    }
    removeItemsObject(value:StockTemplateItem) {
        this._removeObjectForKey(value, 'items');
    }
}
