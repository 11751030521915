

class CashDeskSessionLinesViewController extends MUIViewController
{        
    private tableView:MUITableView = null;

    private typeFilterTextField:ColumnFilterTextField = null;
	private conceptFilterTextField:ColumnFilterTextField = null; 
	private numberFilterTextField:ColumnFilterTextField = null;
    private locationFilterTextField:ColumnFilterTextField = null;   
	private paymethodFilterTextField:ColumnFilterTextField = null;
	private infoFilterTextField:ColumnFilterTextField = null;
    private amountFilterTextField:ColumnFilterTextField = null;

    private columnFilterController:ColumnFilterController = null;

    viewDidLoad(){
        super.viewDidLoad();    
        
        this.columnFilterController = new ColumnFilterController();
        this.columnFilterController.initWithDelegate(this);

        this.typeFilterTextField = MUIOutlet(this, "type-filter-tf", "ColumnFilterTextField");
        this.typeFilterTextField.filterController = this.columnFilterController;
        this.typeFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "type", null, null);

        this.conceptFilterTextField = MUIOutlet(this, "concept-filter-tf", "ColumnFilterTextField");
        this.conceptFilterTextField.filterController = this.columnFilterController;
		this.conceptFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "concept", null, null);
		
		this.numberFilterTextField = MUIOutlet(this, "number-filter-tf", "ColumnFilterTextField");
		this.numberFilterTextField.filterController = this.columnFilterController;
		this.numberFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "legalDocumentID", null, null);

        this.locationFilterTextField = MUIOutlet(this, "location-filter-tf", "ColumnFilterTextField");
        this.locationFilterTextField.filterController = this.columnFilterController;
        this.locationFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "locationCategoryName", null, null);

        this.paymethodFilterTextField = MUIOutlet(this, "paytmethod-filter-tf", "ColumnFilterTextField");
        this.paymethodFilterTextField.filterController = this.columnFilterController;
		this.paymethodFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "payMethodName", null, null);
		
		this.infoFilterTextField = MUIOutlet(this, "info-filter-tf", "ColumnFilterTextField");
        this.infoFilterTextField.filterController = this.columnFilterController;
        this.infoFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "payMethodSubtype", null, null);
        
        this.amountFilterTextField = MUIOutlet(this, "amount-filter-tf", "ColumnFilterTextField");
        this.amountFilterTextField.filterController = this.columnFilterController;
        this.amountFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "money", null, null);

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        
    }

    viewWillAppear(animted?){
        super.viewWillAppear(animted);
        this.updateUI();
    }

    private _session:CashDeskSession = null;
    set session(i:CashDeskSession){
        this._session = i;
        this.updateUI();
    }

    private updateUI(){
        if (this._session == null || this.viewIsLoaded == false) return;
        this.fetchedResultsController = null;
        this.tableView.reloadData();            
    }

    numberOfSections(tableview:MUITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview:MUITableView, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("LineCell") as CashDeskSessionLineCell;
        let item:CashDeskLine = this.fetchedResultsController.objectAtIndexPath(indexPath);

        cell.item = item;

        return cell;
    }
         
    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('CashDeskLine');
        fetchRequest.relationshipKeyPathsForPrefetching = ["session", "payMethod.image"];
    
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('date', false)];                                                
        
        let predicateFormat = "deletedAt == null AND session.identifier == " + this._session.identifier;

        predicateFormat += " AND type != 16"; //CashDeskOperationTax
        predicateFormat += " AND type != 17"; //CashDeskOperationTaxBase
        predicateFormat += " AND type != 18"; //CashDeskOperationDiscount
        predicateFormat += " AND type != 19"; //CashDeskOperationPeople

        let filterFormat = this.columnFilterController.filterPredicateFormat();
        if (filterFormat != null) {
            predicateFormat += " AND (" + filterFormat + ")";
        }

        fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);   
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }    

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();            
    }

    filterPredicateDidChange(controller:ColumnFilterController){
        this.fetchedResultsController = null;
        this.tableView.reloadData();        
    }

}