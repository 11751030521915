//
// Generated class Currency
//

/// <reference path="Currency_ManagedObject.ts" />

class Currency extends Currency_ManagedObject
{

}
