
class DBToolFilterCell extends MUITableViewCell
{
    private titleLabel:MUILabel = null;
    private valueTextField:MUITextField = null;    
    private valueSwitch:MUISwitchButton = null;
    private calendarButton:MUIButton = null;
    
    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, 'title-lbl', 'MUILabel');
        this.valueTextField = MUIOutlet(this, 'value-tf', 'MUITextField');
        if (this.valueTextField != null) this.valueTextField.setOnChangeText(this, function(control, value){            
            this.changeValue(value);
        });

        this.valueSwitch = MUIOutlet(this, "value-sw", "MUISwitchButton");
        if (this.valueSwitch != null) this.valueSwitch.setOnChangeValue(this, function(control, value){
            this.values[this.filter.key] = value ? "true" : "false";
        });     
        
        this.calendarButton = MUIOutlet(this, "calendar-btn", "MUIButton");
        if (this.calendarButton != null) this.calendarButton.setAction(this, function(){
            let dp = new MUIDatePickerController();
            dp.init();        
            dp.delegate = this;
            dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

            let pc = dp.popoverPresentationController;
            pc.sourceRect = this.calendarButton.frame;
            pc.sourceView = this.calendarButton;

            AppHelper.sharedInstance().presentViewController(dp, true);
        });

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    private filter:DBFilter = null;    
    private values = null;
    setFilter(filter:DBFilter, values) {
        this.filter = filter;
        this.values = values;

        this.titleLabel.text = filter.title;
        if (this.valueTextField != null) this.valueTextField.text = values[filter.key];
        if (this.valueSwitch != null) this.valueSwitch.on = values[filter.key] == "true" ? true : false;
    }

    didSelectDate(datePickerController, date){

        if (this.valueTextField == null) return;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let ds = ad.dateFormatter.stringFromDate(date);
        this.valueTextField.text = ds;
        this.values[this.filter.key] = ad.serverDateFormatter.stringFromDate(date);
    }

    private changeValue(value:string){
        if (this.filter.dataType == DBFilterDataType.Date) {
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            let date = ad.dateFormatter.dateFromString(value);
            if (date != null) this.values[this.filter.key] = ad.serverDateFormatter.stringFromDate(date);
        }
        else {
            this.values[this.filter.key] = value;    
        }
    }

}