
interface PurchaseOrderTemplateSelectionViewControllerDelegate
{
    processStockNotesWithComments( controller: PurchaseOrderTemplateSelectionViewController );
}

class PurchaseOrderTemplateSelectionViewController extends MUIViewController
{
    static newInstance():PurchaseOrderTemplateSelectionViewController
    {
        let vc = new PurchaseOrderTemplateSelectionViewController('purchase-order-template-selection-view');
        vc.initWithResource("layout/purchase_order/PurchaseOrderTemplateSelectionView.html");
        return vc;
    }
    
    get preferredContentSize() {
        return new MIOSize(500, 500);
    }

    delegate:PurchaseOrderTemplateSelectionViewControllerDelegate = null;

    private closeButton:MUIButton = null;
    private sendButton:MUIButton = null;
    private tableView:UITableView = null;

    viewDidLoad()
    {
        this.closeButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.closeButton.setAction(this, function(){
            this.dismissViewController(true);
        });

        this.sendButton = MUIOutlet(this, "send-btn", "MUIButton");
        this.sendButton.setAction(this, this.sendAction);

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(animated?: boolean): void {
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _stockNote:StockNote = null;
    set stockNote (value:StockNote){
        this._stockNote = value;        
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._stockNote == null) return;
        this.mapSuppliers();
        this.tableView.reloadData();
    }

    numberOfRowsInSection(tableView:UITableView, section:number){
        return this._suppliers.length;
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let item = this._suppliers[indexPath.row];
        let identifier = item[ "Type" ] == 0 ? "PurchaseOrderTemplateCell" : "PurchaseOrderTemplateSubCell";

        let cell = tableView.dequeueReusableCellWithIdentifier(identifier) as PurchaseOrderTemplateCell;
        if (item[ "Type" ] == 0) cell.delegate = this;
        cell.item = item;
                
        return cell;
    }

    private _suppliers:[]
    mapSuppliers(){        
        let suppliers_map = {};        
        for (let index = 0; index < this._stockNote.lines.length; index++) {
            let line = this._stockNote.lines.objectAtIndex(index) as StockNoteLine;
            if (line.legalEntity == null) continue;
            suppliers_map[line.legalEntity.identifier] = line.legalEntity.name;
        }

        this._suppliers = [];
        for (let key in suppliers_map) {
            let name = suppliers_map[key];
            this._suppliers.addObject( { "OrderPath": name + ".0", "Type": 0, "ID": key, "Name": name, "HasComments": false } );
        }
        
        this._suppliers = _MIOSortDescriptorSortObjects(this._suppliers, "OrderPath", true);

        this.tableView.reloadData();
    }

    addComments( cell:PurchaseOrderTemplateCell, item:any )
    {
        if (item[ "HasComments" ] == true) return;
        item[ "HasComments" ] = true;
        let index = this._suppliers.indexOf(item);
        this._suppliers.insertObjectAtIndex( { "OrderPath": item[ "Name" ] + ".1", "Type": 1, "ID": item[ "ID" ], "Name": item[ "Name" ], "HasComments": false }, index + 1 );
        this.tableView.reloadData();
    }

    private sendAction(){

        let comments = {}
        for (let l of this._suppliers){
            if (l[ "Type" ] == 0) continue;
            let comment = l[ "Comments" ]?.trim();
            if (comment?.length == 0) continue;
            comments[ (l[ "ID" ] as String).toUpperCase() ] = comment;
        }

        (this._stockNote as BuyOrder).commentsBySupplier = comments;
        this.delegate?.processStockNotesWithComments(this);
        this.dismissViewController(true);
    }

}