/**
 * Created by miguel on 15/3/17.
 */

class CashDeskListViewController extends BaseTableViewController
{   
    private calendarButton:MUIButton = null; 
    private dateLabel:MUILabel = null;
    private totalLabel:MUILabel = null;
    
    private sdtf = MUIWebApplication.sharedInstance().delegate.serverDateTimeFormatter;

    viewDidLoad() { 
        super.viewDidLoad();

        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel")
        this.totalLabel = MUIOutlet(this, "total-lbl", "MUILabel");
        
        let tapGesture = new MUITapGestureRecognizer();
        tapGesture.initWithTarget(this, function(gesture:MUIGestureRecognizer){
            if (gesture.state == MUIGestureRecognizerState.Ended) {
                if (this.selectedItem != null) this.tableView.deselectRowAtIndexPath(this.selectedIndexPath, true);
                this.selectedItem = null;
                this.selectedIndexPath = null;
                this.showItem();        
            };
        });        
        this.totalLabel.addGestureRecognizer(tapGesture);
        

        this.calendarButton = MUIOutlet(this, 'calendar-btn','MUIButton');
        this.calendarButton.setAction(this, function(){
            let ah:AppHelper = AppHelper.sharedInstance();
            ah.presentDateSelectionViewController(this);
        });

        this.tableView = MUIOutlet(this, 'table-view', 'UITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this; 

        this.updateDate();
        this.invalidateFetch();
    }

    private hasSummaryCell = false;
    numberOfSections(tableView:UITableView){        
        // if (this.fetchedResultsController.fetchedObjects.length < 2) {
        //     this.hasSummaryCell = false;
        //     return this.fetchedResultsController.sections.length;
        // }
        // else {
        //     this.hasSummaryCell = true;
        //     return this.fetchedResultsController.sections.length + 1;
        // }        
        return 1;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        // if (this.hasSummaryCell == true && section == 0) return 1;

        // let sec = this.fetchedResultsController.sections[section - (this.hasSummaryCell ? 1 : 0)];
        // return sec.numberOfObjects();

        // let sec = this.fetchedResultsController.sections[section];
        // return sec.numberOfObjects(); 

        return this.cashDeskItems.count;
    }


    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        // let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as CashDeskBalance;
        let item = this.cashDeskItems[indexPath.row];
        let cell = tableview.dequeueReusableCellWithIdentifier('CashDeskLevel2Cell') as CashDeskCell;
        cell.item2 = item;    
        return cell;
        // if (this.hasSummaryCell == true && indexPath.section == 0) {
        //     let cell = tableview.dequeueReusableCellWithIdentifier('CashDeskLevel1Cell') as CashDeskCell;
        //     this.configureSummaryCell(cell, 0);
        //     return cell;
        // }
        // else { 
        //     let ip = MIOIndexPath.indexForRowInSection(indexPath.row, indexPath.section - (this.hasSummaryCell ? 1 : 0));        
        //     let item = this.fetchedResultsController.objectAtIndexPath(ip) as CashDeskBalance;
        //     let cell = tableview.dequeueReusableCellWithIdentifier('CashDeskLevel2Cell') as CashDeskCell;
        //     cell.item = item;    
        //     // cell.selectionStyle = this.fetchedResultsController.fetchedObjects.length > 1 ? UITableViewCellSelectionStyle.Default : UITableViewCellSelectionStyle.None;
        //     return cell;
        // }    
                
        // let cell = null;
        // if(item.type == 0)
        //     cell = tableview.dequeueReusableCellWithIdentifier('CashDeskLevel1Cell');
        // else if(item.type == 1)
        //     cell = tableview.dequeueReusableCellWithIdentifier('CashDeskLevel2Cell');
        // else
        //     cell = tableview.dequeueReusableCellWithIdentifier('CashDeskLevel3Cell');        
        
        //console.log(item.indexPathString);

        //return null;
    }    
        
    // canSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
    //     if (this.fetchedResultsController.fetchedObjects.length > 1) return true;        
    //     else return false;
    // }

    private selectedIndexPath:MIOIndexPath = null;
    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        // if (indexPath.section == 0) this.selectedItem = null;
        // else {
        //     let ip = MIOIndexPath.indexForRowInSection(indexPath.row, indexPath.section - 1);
        //     this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath) as CashDesk;
        // }        
        this.selectedIndexPath = indexPath;
        this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath) as CashDesk;
        this.showItem();
    }
        
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
        let sdt = ad.serverDateTimeFormatter;

        let rh = ReportHelper.sharedInstance();
        let dateTimeFrom = sdt.stringFromDate(rh.selectedDateTimeFrom);
        let dateTimeTo = sdt.stringFromDate(rh.selectedDateTimeTo);

        let predicateFormat = "sessionDate >= '" + dateTimeFrom + "' AND sessionDate <= '" + dateTimeTo + "')"; 

        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('CashDeskBalance');        
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('cashdesk.name', true)];
        fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);        
        fetchRequest.relationshipKeyPathsForPrefetching = ["cashdesk"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }
        
    protected invalidateData(){        
        this.updateDate();        
        super.invalidateData();
    }

    protected reloadData(){
        this.cashDeskMap(this.fetchedResultsController.fetchedObjects);
        super.reloadData();
        this.updateTotal();
    }

    datesDidSelect(dateTimeFrom, dateTimeTo) {
        let rh = ReportHelper.sharedInstance();
        rh.selectedDateTimeFrom = dateTimeFrom;
        rh.selectedDateTimeTo = dateTimeTo;  
        this.updateDate();
        this.invalidateData();
        this.showItem();
    }

    private showItem(){                        
        if (MIOCoreIsPhone()) return;

        // let vc = new CashDeskDetailViewController("cash-desk-detail-view");
        // vc.initWithResource("layout/cashdesk/CashDeskDetailView.html");    
        // vc.cashDesk = this.selectedItem;

        // let nc = new MUINavigationController();
        // nc.initWithRootViewController(vc);

        // this.splitViewController.showDetailViewController(nc);                    

        let nc = this.splitViewController.detailViewController as MUINavigationController;
        let vc = nc.rootViewController as CashDeskDetailViewController;
        vc.cashDesk = this.selectedItem?.cashdesk;
    }

    private showNoItemSelectedView(){        
        if (MIOCoreIsPhone() == false){
            let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("CashDesk", NoItemSelectedViewControllerType.CashDesk);
            this.splitViewController.showDetailViewController(vc);
        }
    }

    private updateDate(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;    
        
        let rh = ReportHelper.sharedInstance();
        let dayFrom = MIODateGetDateString(rh.selectedDateTimeFrom);
        let dayTo = MIODateGetDateString(rh.selectedDateTimeTo);

        if (dayFrom == dayTo) {
            this.dateLabel.text = ad.longDateFormatter.stringFromDate(rh.selectedDateTimeFrom);
        }
        else {
            this.dateLabel.text = ad.dateFormatter.stringFromDate(rh.selectedDateTimeFrom) + ' - ' + ad.dateFormatter.stringFromDate(rh.selectedDateTimeTo);
        }
    }

    private updateTotal(){    
        this.totalLabel.text = "0";

        let total = 0;
        for (let index = 0; index < this.fetchedResultsController.fetchedObjects.length; index++) {            
            let balance = this.fetchedResultsController.fetchedObjects[index] as CashDeskBalance;
            total += balance.salesAmount;
        }        

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this.totalLabel.text = ad.currencyFormatter.stringFromNumber(total);        

        if (this.hasSummaryCell == false) return;

        let ip = MIOIndexPath.indexForRowInSection(0, 0);
        let cell = this.tableView.cellAtIndexPath(ip) as CashDeskCell;
        this.configureSummaryCell(cell, total);
    }

    private configureSummaryCell(cell:CashDeskCell, amount:number) {
        if (cell == null) return;                
        // cell.setCustomData(MIOLocalizeString("ALL CASHDESKS", "ALL CASHDESKS"), amount);
    }

    private cashDeskItems = [];
    public cashDeskMap(balances:CashDeskBalance[]){

        let cashdesks = {};
        for (let b of balances) {
            let i = cashdesks[b.cashdesk.identifier];
            if (i == null) cashdesks[b.cashdesk.identifier] = {"Name" : b.cashdesk.name, "Total" : 0};
            cashdesks[b.cashdesk.identifier]["Total"] += b.salesAmount;            
        }
        
        let array = Object.keys(cashdesks).map( function(key){ return cashdesks[key]; } );
        this.cashDeskItems = _MIOSortDescriptorSortObjects(array, [MIOSortDescriptor.sortDescriptorWithKey("Name", true)]);
        this.tableView.reloadData();
    }
}