class ReportColumnVisibilityCell extends UITableViewCell
{
    private titleLabel:MUILabel = null;
    
    awakeFromHTML(): void {
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }
    
    set item(column:ReportColumnOption){
        this.titleLabel.text = MIOLocalizeString(column.title,column.title);
        this.accessoryType = column.hidden ? UITableViewCellAccessoryType.None : UITableViewCellAccessoryType.Checkmark;
    }
}