
class PartnerDetailViewController extends MUIViewController
{
    private saveButton:MUIButton = null;
    
    private logoImageView:MUIImageView = null;
    
    private nameTextField:MUITextField = null;
    private emailTextField:MUITextField = null;

    private addressTextField:MUITextField = null;   
    private phoneTextField:MUITextField = null;
    private mobileTextField:MUITextField = null;
    private cityTextField:MUITextField = null;
    private stateTextField:MUITextField = null;
    private postalCodeTextField:MUITextField = null;
    private countryDropdown:MUIButton = null;

    private onlineImageView:MUIImageView = null;
    private onlineDetailImageView:MUIImageView = null;

    private descriptionTextField:MUITextField = null;
    private promotionTextField:MUITextField = null;
    private offer1TextField:MUITextField = null;
    private offer2TextField:MUITextField = null;
    private offer3TextField:MUITextField = null;

    viewDidLoad(){
        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        });

        this.logoImageView = MUIOutlet(this, "logo-iv", "MUIImageView");
        let logoTapGesture = new MUIGestureRecognizer();
        logoTapGesture.initWithTarget(this, this.logoImageTapGesture);
        this.logoImageView.addGestureRecognizer(logoTapGesture);

        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.nameTextField.setOnChangeText(this, function(this:PartnerDetailViewController, textField:MUITextField){
            this.partner.name = textField.text;
        });

        this.emailTextField = MUIOutlet(this, "email-tf", "MUITextField");
        this.emailTextField.setOnChangeText(this, function(this:PartnerDetailViewController, textField:MUITextField){
            this.partner.email = textField.text;
        });        

        // Address         
        this.setupTextField(this, "addressTextField",   "address-tf",       "address.address1");
        this.setupTextField(this, "phoneTextField",     "phone-tf",         "phone.number");  
        this.setupTextField(this, "mobileTextField",    "mobile-tf",        "mobilePhone.number");
        this.setupTextField(this, "cityTextField",      "city-tf",          "address.city");
        this.setupTextField(this, "stateTextField",     "state-tf",         "address.state");
        this.setupTextField(this, "postalCodeTextField","postal-code-tf",   "address.postalCode");
        this.countryDropdown = MUIOutlet(this, "country-dd", "MUIButton");
        this.countryDropdown.setAction(this, function(){
            AppHelper.sharedInstance().showSelectCountryViewControllerFromView(this.countryDropdown, null, this, function(controller:PartnerDetailViewController, country:Country){               
                this.address.countryISON = country.ison;
                this.address.countryISOa2 = country.iso2;
                this.address.countryISOa3 = country.iso3;
                this.countryDropdown.title = country.name;
            });
        });

        // Additional info

        this.onlineImageView = MUIOutlet(this, "online-iv", "MUIImageView");
        let imageTapGesture = new MUIGestureRecognizer();
        imageTapGesture.initWithTarget(this, this.imageTapGesture);
        this.onlineImageView.addGestureRecognizer(imageTapGesture);

        this.onlineDetailImageView = MUIOutlet(this, "online-detail-iv", "MUIImageView");
        let detailImagetapGesture = new MUIGestureRecognizer();
        detailImagetapGesture.initWithTarget(this, this.detailImageTapGesture);
        this.onlineDetailImageView.addGestureRecognizer(detailImagetapGesture);

        this.descriptionTextField = MUIOutlet(this, "web-description-tf", "MUITextField");
        this.descriptionTextField.setOnChangeText(this, function(this:PartnerDetailViewController, textField:MUITextField) {
            this.partner.parterDescription = textField.text.length > 0 ? textField.text : null;
        });

        this.promotionTextField = MUIOutlet(this, "web-promotion-tf", "MUITextField");
        this.promotionTextField.setOnChangeText(this, function(this:PartnerDetailViewController, textField:MUITextField) {
            this.partner.promotion = textField.text.length > 0 ? textField.text : null;
        });

        this.offer1TextField = MUIOutlet(this, "web-offer-1-tf", "MUITextField");
        this.offer1TextField.setOnChangeText(this, function(this:PartnerDetailViewController, textField:MUITextField) {
            this.partner.offer1 = textField.text.length > 0 ? textField.text : null;
        });

        this.offer2TextField = MUIOutlet(this, "web-offer-2-tf", "MUITextField");
        this.offer2TextField.setOnChangeText(this, function(this:PartnerDetailViewController, textField:MUITextField) {
            this.partner.offer2 = textField.text.length > 0 ? textField.text : null;
        });

        this.offer3TextField = MUIOutlet(this, "web-offer-3-tf", "MUITextField");
        this.offer3TextField.setOnChangeText(this, function(this:PartnerDetailViewController, textField:MUITextField) {
            this.partner.offer3 = textField.text.length > 0 ? textField.text : null;
        });
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private partner:Partner = null;
    set item(partner:Partner){
        this.partner = partner;
        this.updateUI()
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this.partner == null) return;

        this.nameTextField.text = this.partner.name;
        this.emailTextField.text = this.partner.email;

        this.phoneTextField.text = this.phone.number;
        this.mobileTextField.text = this.mobilePhone.number;

        this.addressTextField.text = this.address.address1;
        this.cityTextField.text = this.address.city;
        this.stateTextField.text = this.address.state;
        this.postalCodeTextField.text = this.address.postalCode;
        this.countryDropdown.title = getCountryName( this.address.countryISOa2 );

        this.logoImageView.setImage( this.partner.logo?.url );
        this.onlineImageView.setImage( this.partner.image?.url );
        this.onlineDetailImageView.setImage( this.partner.detailImage?.url );

        this.descriptionTextField.text = this.partner.parterDescription;
        this.promotionTextField.text = this.partner.promotion;
        this.offer1TextField.text = this.partner.offer1;
        this.offer3TextField.text = this.partner.offer2;
        this.offer3TextField.text = this.partner.offer3;
    }

    private logoImageTapGesture(gesture:MUIGestureRecognizer) {
        if (gesture.state != MUIGestureRecognizerState.Began) return;

        const ctx = { "Partner": this.partner };
        AppHelper.sharedInstance().showImageSelectorPanel(ctx, ( image:DBImage, context:any ) => {
            const partner = context["Partner"];
            partner.logo = image;
            this.logoImageView.setImage(image.url);
            DBHelper.saveMainContext();
        });
    }


    private imageTapGesture(gesture:MUIGestureRecognizer) {
        if (gesture.state != MUIGestureRecognizerState.Began) return;

        AppHelper.sharedInstance().showImageSelectorPanel(this, (image:DBImage) => {
            this.partner.image = image;
            this.onlineImageView.setImage(image.url);
            DBHelper.saveMainContext();
        });
    }

    private detailImageTapGesture(gesture:MUIGestureRecognizer) {
        if (gesture.state != MUIGestureRecognizerState.Began) return;

        AppHelper.sharedInstance().showImageSelectorPanel(null, (image:DBImage) => {
            this.partner.detailImage = image;
            this.onlineDetailImageView.setImage(image.url);
            DBHelper.saveMainContext();
        });
    }

    private setupTextField(controller:any, control:string, outlet:string, keyPath:string){        
        controller[control] = MUIOutlet(controller, outlet, "MUITextField");
        let textField = controller[control];

        textField.setOnChangeText(this, function(this:PartnerDetailViewController, tf:MUITextField){
            let value = textField.text.length > 0 ? textField.text : null;
            let splits = keyPath.split(".");            
            let fn1 = controller[ splits[0] ];
            fn1[ splits[1] ] = value;
        });

    }

    private moc = (MUIWebApplication.sharedInstance().delegate as AppDelegate).managedObjectContext;
    get address():Address {
        if(this.partner.address == null){
            this.partner.address = MIOEntityDescription.insertNewObjectForEntityForName("Address", this.moc) as Address;
            this.partner.address.identifier = MIOUUID.UUID().UUIDString;
        }
        return this.partner.address;
    }

    get phone(){
        if(this.partner.phone == null){
            this.partner.phone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", this.moc) as PhoneNumber;
            this.partner.phone.identifier = MIOUUID.UUID().UUIDString;
        }
        return this.partner.phone;
    }

    get mobilePhone(){
        if(this.partner.mobilePhone == null){
            this.partner.mobilePhone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", this.moc) as PhoneNumber;
            this.partner.mobilePhone.identifier = MIOUUID.UUID().UUIDString;
        }
        return this.partner.mobilePhone;
    }

}