


class WelcomeViewController extends MUIViewController
{
    public viewDidLoad()
    {
        super.viewDidLoad();
        
    }
}