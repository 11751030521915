//
// Generated class PresetProductModifier
//

/// <reference path="PresetProductModifier_ManagedObject.ts" />

class PresetProductModifier extends PresetProductModifier_ManagedObject
{

}
