//
// Generated class DBMappingEntityClient
//

/// <reference path="DBMappingEntityClient_ManagedObject.ts" />

class DBMappingEntityClient extends DBMappingEntityClient_ManagedObject
{

}
