
class SupportTicketCell extends UITableViewCell
{
    private businessLabel:MUILabel = null;
    private titleLabel:MUILabel = null;

    awakeFromHTML(){
        this.businessLabel = MUIOutlet( this, "business-lbl", "MUILabel" );
        this.titleLabel = MUIOutlet( this, "title-lbl", "MUILabel" );
    }

    set item( item:SupportTicket ) {
        this.businessLabel.text = item.business?.name;
        this.titleLabel.text = item.title ?? "UNKNOWN";
    }
}