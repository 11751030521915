

class CompanyViewController extends MUIViewController
{
    static newInstance(): CompanyViewController {
        let vc = new CompanyViewController("company-view");
        vc.initWithResource("layout/company/CompanyView.html");
        return vc;
    }

    private saveButton:MUIButton = null;

    private logoImageView:MUIImageView = null;
    private nameTextField:MUITextField = null;
    private emailTextField:MUITextField = null;
    private phoneTextField:MUITextField = null;
    private businessIDTextField:MUITextField = null;
    
    private address1TextField:MUITextField = null;
    private address2TextField:MUITextField = null;
    private stateTextField:MUITextField = null;
    private cityTextField:MUITextField = null;
    private postalCodeTextField:MUITextField = null;
    private countryDropdown:MUIButton = null;

    private websiteTextField:MUITextField = null;

    private receiptDisclaimerTextView:MUITextArea = null;
    private invoiceDisclaimerTextView:MUITextArea = null;
    private supplierOrderDisclaimerTextView:MUITextArea = null;
    private supplierNoteDisclaimerTextView:MUITextArea = null;

    private businessDescriptionTextView:MUITextArea = null;
    private openingHoursTextView:MUITextArea = null;

    viewDidLoad(){
        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function() {
            DBHelper.saveMainContext();
        });

        this.logoImageView = MUIOutlet(this, "company-img", "MUIImageView");
        let tapGesture = new MUIGestureRecognizer();
        tapGesture.initWithTarget(this, this.imageTapGesture);
        this.logoImageView.addGestureRecognizer(tapGesture);

        // this.nameTextField = AppHelper.setupDataTextField(this, "name-tf", (value:string) => { this.company.name = value; } );
        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.nameTextField.setOnChangeText(this, function(this:CompanyViewController, control:MUIControl, value:string){
            this.company.name = value;
        } );
        
        // this.emailTextField = AppHelper.setupDataTextField(this, "email-tf", (value:string) => { this.company.email = value; } );
        this.emailTextField = MUIOutlet(this, "email-tf", "MUITextField");
        this.emailTextField.setOnChangeText(this, function(this:CompanyViewController, control:MUIControl, value:string) { 
            this.company.email = value; 
        } );
        
        this.phoneTextField = MUIOutlet(this, "phone-tf", "MUITextField");
        this.phoneTextField.setOnChangeText(this, function (this:CompanyViewController, control:MUIControl, value:string) { 
            if (this.company.phone == null) {
                this.company.phone = PhoneNumber.newPhoneNumber();
            }
            this.company.phone.number = value; 
        } );

        this.businessIDTextField = MUIOutlet(this, "company-id-tf", "MUITextField");
        this.businessIDTextField.setOnChangeText(this, function(this:CompanyViewController, control:MUITextField, value:string){
            this.company.document = value.length > 0 ? value.trim() : null;
        });

        this.address1TextField = MUIOutlet(this, "address1-tf", "MUITextField");
        this.address1TextField.setOnChangeText(this, function (this:CompanyViewController, control:MUIControl, value:string){ 
            if (this.company.address == null) {
                this.company.address = Address.newAddress();
            }
            this.company.address.address1 = value; 
        } );

        this.address2TextField = MUIOutlet(this, "address2-tf", "MUITextField");
        this.address2TextField.setOnChangeText(this, function (this:CompanyViewController, control:MUIControl, value:string){ 
            if (this.company.address == null) {
                this.company.address = Address.newAddress();
            }
            this.company.address.address2 = value; 
        } );
        
        this.stateTextField = MUIOutlet(this, "state-tf", "MUITextField");
        this.stateTextField.setOnChangeText(this, function(this:CompanyViewController, control:MUIControl, value:string){ 
            if (this.company.address == null) {
                this.company.address = Address.newAddress();
            }
            this.company.address.state = value;
        } );        

        this.cityTextField = MUIOutlet(this, "city-tf", "MUITextField");
        this.cityTextField.setOnChangeText(this, function(this:CompanyViewController, control:MUIControl, value:string){
            if (this.company.address == null) {
                this.company.address = Address.newAddress();
            }
            this.company.address.city = value; 
        } );        
        
        this.postalCodeTextField = MUIOutlet(this, "postal-code-tf", "MUITextField");
        this.postalCodeTextField.setOnChangeText(this, function(this:CompanyViewController, control:MUIControl, value:string) {
            if (this.company.address == null) {
                this.company.address = Address.newAddress();
            }
            this.company.address.postalCode = value; 
        } );

        this.countryDropdown = MUIOutlet(this, "country-dd", "MUIButton");
        this.countryDropdown.setAction(this, function(){
            AppHelper.sharedInstance().showSelectCountryViewControllerFromView(this.countryDropdown, null, this, function(controller:CompanyViewController, country:Country){
                this.company.address.country = country.name;
                this.company.address.countryISON = country.ison;
                this.company.address.countryISOa2 = country.iso2;
                this.company.address.countryISOa3 = country.iso3;
                this.countryDropdown.title = country.name;
            });
        });   

        this.websiteTextField = MUIOutlet(this, "website-tf", "MUITextField");
        this.websiteTextField.setOnChangeText(this, function(this:CompanyViewController, control:MUIControl, value:string) {
            this.company.web = value.trim().length > 0 ? value : null ; 
        } );

        
        this.receiptDisclaimerTextView = MUIOutlet(this, "receipt-disclaimer-ta", "MUITextArea");
        this.receiptDisclaimerTextView.setOnChangeText(this, function(this:CompanyViewController, control:MUIControl, value:string) {
            this.company.receiptDisclaimer = value.trim().length > 0 ? value : null;
        } );
        this.invoiceDisclaimerTextView = MUIOutlet(this, "invoice-disclaimer-ta", "MUITextArea");
        this.invoiceDisclaimerTextView.setOnChangeText(this, function(this:CompanyViewController, control:MUIControl, value:string) {
            this.company.invoiceDisclaimer = value.trim().length > 0 ? value : null;
        } );
        this.supplierOrderDisclaimerTextView = MUIOutlet(this, "supplier-order-disclaimer-ta", "MUITextArea");
        this.supplierOrderDisclaimerTextView.setOnChangeText(this, function(this:CompanyViewController, control:MUIControl, value:string) {
            this.company.supplierOrderDisclaimer = value.trim().length > 0 ? value : null;
        } );
        this.supplierNoteDisclaimerTextView = MUIOutlet(this, "supplier-note-disclaimer-ta", "MUITextArea");
        this.supplierNoteDisclaimerTextView.setOnChangeText(this, function(this:CompanyViewController, control:MUIControl, value:string) {
            this.company.supplierNoteDisclaimer = value.trim().length > 0 ? value : null;
        } );

        this.businessDescriptionTextView = MUIOutlet(this, "business-description-ta", "MUITextArea");
        this.businessDescriptionTextView.setOnChangeText(this, function(this:CompanyViewController, control:MUIControl, value:string) {
            this.company.businessDescription = value.trim().length > 0 ? value : null;
        });

        this.openingHoursTextView = MUIOutlet(this, "opening-hours-ta", "MUITextArea");
        this.openingHoursTextView.setOnChangeText(this, function(this:CompanyViewController, control:MUIControl, value:string) {
            this.company.openingHours = value.trim().length > 0 ? value : null;
        });

        this.fetchCompany();
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private company:Company = null;
    private updateUI(){
        if (this.viewIsLoaded == false || this.company == null) return;

        this.logoImageView.setImage (this.company.image?.url);
        this.nameTextField.text = this.company.name;
        this.emailTextField.text = this.company.email;
        this.phoneTextField.text = this.company.phone?.number;
        this.businessIDTextField.text = this.company.document;

        this.address1TextField.text = this.company.address?.address1;
        this.address2TextField.text = this.company.address?.address2;
        this.stateTextField.text = this.company.address?.state;
        this.cityTextField.text = this.company.address?.city;
        this.postalCodeTextField.text = this.company.address?.postalCode;
        this.countryDropdown.title =  this.company.address?.country;

        this.websiteTextField.text = this.company.web;

        this.receiptDisclaimerTextView.text = this.company.receiptDisclaimer;
        this.invoiceDisclaimerTextView.text = this.company.invoiceDisclaimer;
        this.supplierOrderDisclaimerTextView.text = this.company.supplierOrderDisclaimer;
        this.supplierNoteDisclaimerTextView.text = this.company.supplierNoteDisclaimer;

        this.businessDescriptionTextView.text = this.company.businessDescription;
        this.openingHoursTextView.text = this.company.openingHours;
    }

    private fetchCompany(){
        DBHelper.queryObjectWithCompletion("Company", null, MIOPredicate.predicateWithFormat("deletedAt = null"), ["address", "phone", "image"], this, function(this:CompanyViewController, company:Company){            
            
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

            this.company = company;
            
            // if ( company == null ) {
            //     this.company = MIOEntityDescription.insertNewObjectForEntityForName("Company", ad.managedObjectContext) as Company;
            //     DBHelper.saveMainContext();
            // } 

            if (this.company.address == null) {
                let addr = MIOEntityDescription.insertNewObjectForEntityForName("Address", ad.managedObjectContext) as Address;
                this.company.address = addr;                
                DBHelper.saveMainContext();
            }
            
            this.updateUI();
        });
    }

    private imageTapGesture(gesture:MUIGestureRecognizer){
        if (gesture.state != MUIGestureRecognizerState.Began) return;

        AppHelper.sharedInstance().showImageSelectorPanel(null, ( image:DBImage, context:any ) => {
            this.company.image = image;
            this.company.imageID = image?.identifier;
            this.logoImageView.setImage( image?.url );
        });        
    }

}