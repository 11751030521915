class AddOnlineConfigurationsViewController extends MUIViewController
{        
    private cancelButton:MUIButton = null;
    private doneButton:MUIButton = null;
    private configNameTextField:MUITextField = null;
    private presetButton:MUIButton = null;
    private businessAreaButton:MUIButton = null;
    private switchButton:MUISwitchButton = null;
    private fromDateTextField:MUITextField = null;
    private fromDateCalendarButton:MUIButton = null;
    private toDateTextField:MUITextField = null;
    private toDateCalendarButton:MUIButton = null;
    //private tableView:UITableView = null;

    private monButton:MUIButton = null;
    private tueButton:MUIButton = null;
    private wedButton:MUIButton = null;
    private thuButton:MUIButton = null;
    private friButton:MUIButton = null;
    private satButton:MUIButton = null;
    private sunButton:MUIButton = null;

    private name:string = null;
    private fromDate:any = null;
    private toDate:any = null;
    private timeRangePreset:TimeRangePreset = null;
    private businessArea:BusinessArea = null;
    private weekdays:number = 127; //All seven days set active

    private ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
    private df:MIOFormatter =  this.ad.dateFormatter;
    private serverFormatter:MIOFormatter = this.ad.serverDateTimeFormatter;

    get preferredContentSize(){
        return new MIOSize(500, 350);
    }

    viewDidLoad(){
        super.viewDidLoad();

        this.cancelButton = MUIOutlet(this, "cancel-btn", "MUIButton");
        this.cancelButton.setAction(this, function(control){
            this.dissmiss();
        });

        this.doneButton = MUIOutlet(this, "done-btn", "MUIButton");
        this.doneButton.setAction(this, function(){

            if (!this.name) {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('PLEASE ADD A NAME','PLEASE ADD A NAME'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                this.presentViewController(error, true);
                return;
            } else if (this.switchButton.on) {
                //if always on, save and close
                this.saveTimeRangePresetConfiguration();
                this.dissmiss();
            } else if (!this.toDate || !this.fromDate) {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('PLEASE ADD DATE','PLEASE ADD DATE'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                this.presentViewController(error, true);
                return;
            } else if (this.toDate - this.fromDate < 0 ){
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('SELECTED DATES ARE NOT ACCEPTABLE','SELECTED DATES ARE NOT ACCEPTABLE'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                this.presentViewController(error, true);
                return;
            } else {
                this.saveTimeRangePresetConfiguration();
                this.dissmiss();
            }
        });

        this.configNameTextField = MUIOutlet(this, 'config-name-tf', 'MUITextField');
        this.configNameTextField.setOnChangeText(this, function(control, value:string){
            this.name = value;
        })

        this.presetButton = MUIOutlet(this, "preset-btn", "MUIButton");
        this.presetButton.setAction(this, function(){
            let vc = AppHelper.sharedInstance().selectViewController("TimeRangePreset", "name", false, false, null, this, false) as SelectEntityViewController;
            vc.allowSearch = false;
            vc.allowAddEntity = false;
            vc.addTarget = this;       
            this.presentViewController(vc, true);
        });

        this.businessAreaButton = MUIOutlet(this, "business-area-btn", "MUIButton");
        this.businessAreaButton.setAction(this, function(){
            let vc = AppHelper.sharedInstance().selectViewController("BusinessArea", "name", false, false, null, this, false) as SelectEntityViewController;
            vc.allowSearch = false;
            vc.allowAddEntity = false;
            vc.addTarget = this;       
            this.presentViewController(vc, true);
        });

        this.switchButton = MUIOutlet(this, "switch-btn", "MUISwitchButton");
        this.switchButton.setOnChangeValue(this, function(switchButton, value){
            if(value) {
                this.fromDate = null;
                this.toDate = null;
                this.fromDateTextField.text = null;
                this.toDateTextField.text = null;
            }
        });  

        this.fromDateTextField = MUIOutlet(this, "from-date-tf", "MUITextField");
        this.fromDateTextField.formatter = this.df; 
        // this.setupValueTextField(this.fromDateTextField);
        this.fromDateCalendarButton = MUIOutlet(this, "from-date-calendar-btn", "MUIButton");
        this.setupCalendarButton(this.fromDateCalendarButton);

        this.toDateTextField = MUIOutlet(this, "to-date-tf", "MUITextField");
        this.toDateTextField.formatter = this.df; 
        // this.setupValueTextField(this.toDateTextField);
        this.toDateCalendarButton = MUIOutlet(this, "to-date-calendar-btn", "MUIButton");
        this.setupCalendarButton(this.toDateCalendarButton);

        this.monButton = MUIOutlet(this, "mon-btn", "MUIButton");
        this.monButton.setAction(this, function(control){
            //toggle bitmask for correct day
            this.weekdays ^= WeekDays.Monday;
            this.updateWeekdays(this.weekdays);
        });
        this.tueButton = MUIOutlet(this, "tue-btn", "MUIButton");
        this.tueButton.setAction(this, function(control){
            this.weekdays ^= WeekDays.Tuesdays;
            this.updateWeekdays(this.weekdays);
        });
        this.wedButton = MUIOutlet(this, "wed-btn", "MUIButton");
        this.wedButton.setAction(this, function(control){
            this.weekdays ^= WeekDays.Wednesday;
            this.updateWeekdays(this.weekdays);
        });
        this.thuButton = MUIOutlet(this, "thu-btn", "MUIButton");
        this.thuButton.setAction(this, function(control){
            this.weekdays ^= WeekDays.Thursday;
            this.updateWeekdays(this.weekdays);
        });
        this.friButton = MUIOutlet(this, "fri-btn", "MUIButton");
        this.friButton.setAction(this, function(control){
            this.weekdays ^= WeekDays.Friday;
            this.updateWeekdays(this.weekdays);
        });
        this.satButton = MUIOutlet(this, "sat-btn", "MUIButton");
        this.satButton.setAction(this, function(control){
            this.weekdays ^= WeekDays.Saturday;
            this.updateWeekdays(this.weekdays);
        });
        this.sunButton = MUIOutlet(this, "sun-btn", "MUIButton");
        this.sunButton.setAction(this, function(control){
            this.weekdays ^= WeekDays.Sunday;
            this.updateWeekdays(this.weekdays);
        });

        // this.tagView = MUIOutlet(this, "tag-view", "TagView");
        // this.tagView.delegate = this;

        // TABLE VIEW HIDDEN UNTIL NEEDED FOR BOOKINGS
        // this.tableView = MUIOutlet(this, "table-view", "UITableView");
        // this.tableView.dataSource = this;
        // this.tableView.delegate = this;
    }

    viewWillAppear(animate?) {
        super.viewWillAppear(animate);        
        this.updateUI();
    }

    private _config:TimeRangePresetConfiguration = null; 
    set item(item:TimeRangePresetConfiguration){     //either TakeAwayTimeRangePresetConfiguration or DeliveryTimeRangePresetConfiguration
        this._config = item;

        this.name = this._config.name;
        this.fromDate = this._config.fromDate;
        this.toDate = this._config.toDate;
        this.timeRangePreset = this._config.timeRangePreset;
        this.businessArea = this._config.businessArea
        this.weekdays = this._config.weekdays;

        this.updateUI();
    }

    private _configurationType:TimeRangePresetConfigurationType = null;
    set configurationType (item:TimeRangePresetConfigurationType){
        this._configurationType = item;
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        this.configNameTextField.text = this.name;
        if (this.timeRangePreset) { this.presetButton.title = this.timeRangePreset.name; }
        if (this.businessArea) { this.businessAreaButton.title = this.businessArea.name; }
        this.fromDateTextField.text = this.df.stringForObjectValue(this.fromDate);
        this.toDateTextField.text = this.df.stringForObjectValue(this.toDate);
        if (!this.fromDate && !this.toDate) { this.switchButton.on = true;}
        this.updateWeekdays(this.weekdays);

        //this.fetchedResultsController = null;
        //this.tableView.reloadData();
    }

    private showDatePickerController(button:MUIButton){
        let dp = new MUIDatePickerController();
        dp.init();        
        dp.delegate = this;
        dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

        let pc = dp.popoverPresentationController;
        pc.sourceRect = button.frame;
        pc.sourceView = button;

        AppHelper.sharedInstance().presentViewController(dp, true);
    }

    didSelectDate(datePickerController, date:any){ //used type any to compare date objects

        //switch off switch button if date is selected
        this.switchButton.on = false;

        let source = datePickerController.popoverPresentationController.sourceView;
        
        if(source == this.fromDateCalendarButton) {
            this.fromDateTextField.text = this.df.stringForObjectValue(date);
           this.fromDate = date;

        } else if (source == this.toDateCalendarButton && this.fromDate != null){
            this.toDateTextField.text = this.df.stringForObjectValue(date);
            this.toDate = date;
        }
    }

    private showSelectEntityViewController(button:MUIButton) {

        let predicateFormat = null;
        // if (this._config.relationEntityName == "TimeRangePreset") {
        //     let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        //     predicateFormat = "placeID == " + ad.selectedIdentifier;
        // }
        
        let vc = AppHelper.sharedInstance().showCustomSelectViewControllerFromView(button, "TimeRangePreset", "Title", predicateFormat, this, function(controller, object:MIOManagedObject){
            let title = object.valueForKey(this._item.relationEntityTitleKey);
            let value = object.valueForKey(this._item.relationEntityValueKey);
            button = title;
            // this._item.stringValue = value;
            // this._item.relationEntityValueTitle = title;
        });        
    }
    
    fetchRequestForController(controller:SelectEntityViewController):MIOFetchRequest{

        if (controller.identifier == "TimeRangePreset"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("TimeRangePreset", sd, null);
            fetchRequest.fetchLimit = 50;            
            return fetchRequest;

        } else if (controller.identifier == "BusinessArea"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("BusinessArea", sd, null);
            fetchRequest.fetchLimit = 50;            
            return fetchRequest;
        }

        return null;
    }

    didSelectObjectFromSelectViewController(controller:SelectEntityViewController, item:MIOManagedObject){
        let dismiss = true;

        if (controller.identifier == "TimeRangePreset") {
            let trp = item as TimeRangePreset;
            this.presetButton.title = trp.name;
            this.timeRangePreset = trp;            
        }
        else if (controller.identifier == "BusinessArea") {   
            let ba = item as BusinessArea;
            this.businessAreaButton.title = ba.name;
            this.businessArea = ba;
        }

        return dismiss;
    }

    // TABLE VIEW HIDDEN UNTIL NEEDED FOR BOOKINGS (NOT NEEDED FOR ONLINEPRESETS)

    // numberOfSections(tableView:UITableView){
    //     return this.fetchedResultsController.sections.length;
    // }
    
    // numberOfRowsInSection(tableView:UITableView, section:number){
    //     let sec = this.fetchedResultsController.sections[section];
    //     return sec.numberOfObjects();
    // }

    // titleForHeaderInSection(tableView:UITableView, section:number) {}
    
    // cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){        

    //     let cell = tableView.dequeueReusableCellWithIdentifier("AddOnlineConfigurationCell") as AddOnlineConfigurationCell;
    //     let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as any;   //change any to the proper type                   
    //     cell.item = item;
    //     return cell;
    // }

    // didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){      
    //     //cell selection
    // }

    // private _fetchedResultsController = null;
    // set fetchedResultsController(value){
    //     if (value == null && this._fetchedResultsController != null)
    //         this._fetchedResultsController.delegate = null;
    
    //     this._fetchedResultsController = value;
    // }
    
    // get fetchedResultsController(){
    //     if (this._fetchedResultsController != null)
    //         return this._fetchedResultsController;
    
    //     let ad = MUIWebApplication.sharedInstance().delegate;
    
    //     let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('OnlinePreset');    
    //     //fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('section', true)];
    //     fetchRequest.predicate = MIOPredicate.predicateWithFormat("deletedAt == null");
    
    //     let fetchedResultsController = new MIOFetchedResultsController();
    //     fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
    //     fetchedResultsController.delegate = this;
    
    //     fetchedResultsController.performFetch();
    
    //     this._fetchedResultsController = fetchedResultsController;    
    //     return this._fetchedResultsController;
    // }
    
    // controllerDidChangeContent(controller:MIOFetchedResultsController){
    //     this.tableView.reloadData();
    // }

    // private setupValueTextField(textField:MUITextField){
    //     if (textField == null) return;

    //     textField.setOnChangeText(this, function(control, value:string){
	// 		let v = value.length > 0 ? value : null;			
    //         this._filter.valueString = v;
    //         this._filter.value = v;
    //         if (v == null) return;

    //         if (this._filter.fieldType == FilterFieldType.Date && this._filter.defaultTimeValue != null) {
    //             let date = this.formatter.getObjectValueForString(v);
    //             this._filter.value = "'" + this.serverFormatter.stringForObjectValue(date) + " " + this._filter.defaultTimeValue + "'";
	// 		}            
	// 		else if (this.formatter != null) this._filter.value = String(this.formatter.getObjectValueForString(v));
    //     });
    // }

    private setupCalendarButton(button:MUIButton){
        if (button == null) return;

        // set up fromDateCalendarButton
        if (button == this.fromDateCalendarButton) {

            button.setAction(this, function(){
                this.showDatePickerController(button);
            });

        // set up toDateCalendarButton
        } else if (button == this.toDateCalendarButton) {

            button.setAction(this, function(){

                if (this.fromDate == null){
                    let error = new MUIAlertViewController();
                    error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('PLEASE CHOOSE FROM DATE','PLEASE CHOOSE FROM DATE'), MUIAlertViewStyle.Default);
                    error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                    this.presentViewController(error, true);
                    return;
                } else {
                    this.showDatePickerController(button);
                }
            });
        }
    }

    // private bitIsActive (value:number, mask:number){
    //     if ((mask & value) == mask) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    private updateWeekdays(value:number) {

        // finds and updates css clases for days of the week, value must be 0-127
        let mondayIsTrue = ((value & WeekDays.Monday) > 0);
        let tuesdayIsTrue = ((value & WeekDays.Tuesdays) > 0);
        let wednesdayIsTrue = ((value & WeekDays.Wednesday) > 0);
        let thursdayIsTrue = ((value & WeekDays.Thursday) > 0);
        let fridayIsTrue = ((value & WeekDays.Friday) > 0);
        let saturdayIsTrue = ((value & WeekDays.Saturday) > 0);
        let sundayIsTrue = ((value & WeekDays.Sunday) > 0);
        
        if (mondayIsTrue) { this.monButton.layer.classList.remove('off');
        } else { this.monButton.layer.classList.add('off'); }

        if (tuesdayIsTrue) { this.tueButton.layer.classList.remove('off');
        } else { this.tueButton.layer.classList.add('off'); }

        if (wednesdayIsTrue) { this.wedButton.layer.classList.remove('off');
        } else { this.wedButton.layer.classList.add('off'); }

        if (thursdayIsTrue) { this.thuButton.layer.classList.remove('off');
        } else { this.thuButton.layer.classList.add('off'); }

        if (fridayIsTrue) { this.friButton.layer.classList.remove('off');
        } else { this.friButton.layer.classList.add('off'); }

        if (saturdayIsTrue) { this.satButton.layer.classList.remove('off');
        } else { this.satButton.layer.classList.add('off'); }

        if (sundayIsTrue) { this.sunButton.layer.classList.remove('off');
        } else { this.sunButton.layer.classList.add('off'); }
    }

    private saveTimeRangePresetConfiguration() {
        
        if (this._config) {
            this._config.name = this.name;
            this._config.timeRangePreset = this.timeRangePreset;
            this._config.businessArea = this.businessArea;
            this._config.fromDate = this.fromDate;
            this._config.toDate = this.toDate;
            this._config.weekdays = this.weekdays;

        } else {
            // insert TakeAwayTimeRangePresetConfiguration or DeliveryTimeRangePresetConfiguration
            let _newConfig = null;
            if (this._configurationType == TimeRangePresetConfigurationType.Takeaway) {
                _newConfig = MIOEntityDescription.insertNewObjectForEntityForName("TakeAwayTimeRangePresetConfiguration", this.ad.managedObjectContext) as TakeAwayTimeRangePresetConfiguration;
              
            } else if (this._configurationType == TimeRangePresetConfigurationType.Delivery) {
                _newConfig = MIOEntityDescription.insertNewObjectForEntityForName("DeliveryTimeRangePresetConfiguration", this.ad.managedObjectContext) as DeliveryTimeRangePresetConfiguration;
            }
            
            _newConfig.identifier = MIOUUID.UUID().UUIDString;
            _newConfig.name = this.name;
            _newConfig.timeRangePreset = this.timeRangePreset;
            _newConfig.businessArea = this.businessArea;
            _newConfig.fromDate = this.fromDate;
            _newConfig.toDate = this.toDate;
            _newConfig.weekdays = this.weekdays;
            _newConfig.channel = null;
        }

        this.ad.managedObjectContext.save();
    }

    private dissmiss(){
        if(this.navigationController != null) { this.navigationController.popViewController(); }
        else { this.dismissViewController(true); }
    }

}