


class BookingsZonesSetupCell extends MUITableViewCell
{
    delegate = null;

    private nameTextField:MUITextField = null;
    private addButton:MUIButton = null;

    awakeFromHTML(){
        this.nameTextField = MUIOutlet(this, 'name-tf', 'MUITextField');
        this.nameTextField.setOnChangeText(this, function(textfield, value){
            this.item.name = value;
        });
        
        // this.addButton = MUIOutlet(this, 'add-btn','MUIButton');
        // this.addButton.setAction(this, function(){ 
        //    if(this.delegate) this.delegate.showAddZoneDialog(this.item);
        // });        
    }

    private _item:BookingZone = null;
    get item() {return this._item;}
    set item(i:BookingZone){
        this._item = i;
        this.nameTextField.text = i.name;
    }
}
