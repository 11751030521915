

/// <reference path="StockNoteLine.ts" />

// Generated class DeliveryOrderLine_ManagedObject

class DeliveryOrderLine_ManagedObject extends StockNoteLine
{

    // Property: measureFormat
    set measureFormat(value:string) {
        this.setValueForKey(value, 'measureFormat');
    }
    get measureFormat():string {
        return this.valueForKey('measureFormat');
    }
    set measureFormatPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'measureFormat');
    }
    get measureFormatPrimitiveValue():string {
        return this.primitiveValueForKey('measureFormat');
    }

    // Property: orderProductName
    set orderProductName(value:string) {
        this.setValueForKey(value, 'orderProductName');
    }
    get orderProductName():string {
        return this.valueForKey('orderProductName');
    }
    set orderProductNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderProductName');
    }
    get orderProductNamePrimitiveValue():string {
        return this.primitiveValueForKey('orderProductName');
    }
    // Relationship: orderProduct
    set orderProduct(value:Product) {
        this.setValueForKey(value, 'orderProduct');
    }
    get orderProduct():Product {
        return this.valueForKey('orderProduct') as Product;
    }
}
