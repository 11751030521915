

class PartyDetailSetupViewController extends MUIViewController
{
    private zonesTableView:MUITableView = null;
    private devicesTableView:MUITableView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.zonesTableView = MUIOutlet(this, "zones-table-view", "MUITableView");
        this.zonesTableView.dataSource = this;                

        this.devicesTableView = MUIOutlet(this, "devices-table-view", "MUITableView");
        this.devicesTableView.dataSource = this;

        this.zonesTableView.reloadData();
        this.devicesTableView.reloadData();
    }

    set party(party:Party){

    }

    numberOfSections(tableview:MUITableView){
        if (tableview === this.zonesTableView){
            return this.zonesFetchedResultsController.sections.length;
        }
        else if (tableview === this.devicesTableView){
            return this.devicesFetchedResultsController.sections.length;
        }

        return 0;
    }
    
    numberOfRowsInSection(tableview:MUITableView, section){
        if (tableview === this.zonesTableView){
            let sec = this.zonesFetchedResultsController.sections[section];
            return sec.numberOfObjects();
        }
        else if (tableview === this.devicesTableView){
            let sec = this.devicesFetchedResultsController.sections[section];
            return sec.numberOfObjects();
        }

        return 0;
    }
    
    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath){
        let cell = null;
        
        if (tableview === this.zonesTableView) {
            cell = tableview.dequeueReusableCellWithIdentifier("LocationCategoryCell") as PartyLocationCell;
            let item = this.zonesFetchedResultsController.objectAtIndexPath(indexPath) as MapLocationCategory;
            cell.item = item;            
        } 
        else if (tableview === this.devicesTableView) {
            cell = tableview.dequeueReusableCellWithIdentifier("CashDeskCell") as PartyCashDeskCell;
            let item = this.devicesFetchedResultsController.objectAtIndexPath(indexPath) as CashDesk;
            cell.item = item;            
        } 
            
        return cell;
    }    

    private _zonesFetchedResultsController:MIOFetchedResultsController = null;
    set zonesFetchedResultsController(value){
        if (value == null && this._zonesFetchedResultsController != null)
            this._zonesFetchedResultsController.delegate = null;
    
        this._zonesFetchedResultsController = value;
    }
    
    get zonesFetchedResultsController(){
        if (this._zonesFetchedResultsController != null)
            return this._zonesFetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("BookingZone", sortDescriptors, null);
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._zonesFetchedResultsController = fetchedResultsController;    
        return this._zonesFetchedResultsController;
    }

    private _devicesFetchedResultsController:MIOFetchedResultsController = null;
    set devicesFetchedResultsController(value){
        if (value == null && this._devicesFetchedResultsController != null)
            this._devicesFetchedResultsController.delegate = null;
    
        this._devicesFetchedResultsController = value;
    }
    
    get devicesFetchedResultsController(){
        if (this._devicesFetchedResultsController != null)
            return this._devicesFetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("CashDesk", sortDescriptors, null);
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._devicesFetchedResultsController = fetchedResultsController;    
        return this._devicesFetchedResultsController;
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){
        if (controller === this.zonesFetchedResultsController){
            this.zonesTableView.reloadData();
        }        
        else if (controller === this.devicesFetchedResultsController){
            this.devicesTableView.reloadData();
        }        
    }
}