
enum NoteDetailHeaderViewDateType
{
    CreateDate,
    DocumentDate,
    ValidationDate,
}

class NoteDetailHeaderView extends MUIView
{
    protected processButton:MUIButton = null;
    protected stockNote:StockNote = null;

    awakeFromHTML(){
        this.processButton = MUIOutlet(this, "change-status-btn", "MUIButton");
        if (this.processButton != null) this.processButton.setAction(this, this.processStockNote);
    }

    protected setupDocumentDateTextField(textField:MUITextField){
        if (textField == null) return;

        textField.setOnChangeText(this, function(control:MUITextField, value:string){
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            this.stockNote.documentDate = ad.dateTimeFormatter.dateFromString(value);    
        });
    }

    protected setupValidationDateTextField(textField:MUITextField){
        if (textField == null) return;        

        textField.setOnChangeText(this, function(control:MUITextField, value:string){
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            this.stockNote.stockDate = ad.dateTimeFormatter.dateFromString(value);
        });
    }

    protected setupCalendarButton(button:MUIButton, type:NoteDetailHeaderViewDateType){
        if (button == null) return;

        button.setAction(this, function(){

            let dp = new MUIDatePickerController();
            dp.init();        
            dp.delegate = this;        
            dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;
            dp.tag = type;

            let pc = dp.popoverPresentationController;
            pc.sourceRect = button.frame;
            pc.sourceView = button;

            AppHelper.sharedInstance().presentViewController(dp, true);
        });
    }

    didSelectDate(datePickerController:MUIDatePickerController, date:Date) {
        let source = datePickerController.popoverPresentationController.sourceView;
        if (datePickerController.tag == NoteDetailHeaderViewDateType.DocumentDate) {
            let newDate = this.newDateFromValue(this.stockNote.documentDate, date);            
            this.stockNote.documentDate = newDate;
            this.dateDidChange(datePickerController.tag, newDate);
        }
        else if (datePickerController.tag == NoteDetailHeaderViewDateType.ValidationDate) {    
            let newDate = this.newDateFromValue(this.stockNote.stockDate, date);                    
            this.stockNote.stockDate = date;
            this.dateDidChange(datePickerController.tag, newDate);
        }        
    }

    private newDateFromValue(oldDate:Date, newDate:Date):Date {
        if (oldDate == null) {
            let now = MIODateNow();            
            newDate.setHours(now.getHours());
            newDate.setMinutes(now.getMinutes());
            newDate.setSeconds(now.getSeconds());
            return newDate;
        }
        else {
            newDate.setHours(oldDate.getHours());
            newDate.setMinutes(oldDate.getMinutes());
            newDate.setSeconds(oldDate.getSeconds());
            return newDate;
        }
    }

    protected dateDidChange(type:NoteDetailHeaderViewDateType, date:Date){

    }

    protected processStockNote(){
        //check that stock note exists
        if (this.stockNote == null) {
            return;
        }

        //check that the stock note has lines ("El Documento esta Vacío, Añade una Línea")
        if (this.stockNote.lines.length <= 0) {
            AppHelper.showErrorMessage(null, "INFO", "CAN'T PROCESS AN EMPTY DOCUMENT");
            return;
        }

        let sectionID = StockNote.sectionIDFromStockNote( this.stockNote );
        if (sectionID == null) return;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let section = DBHelper.queryObject(ad.managedObjectContext, "ManagerSection", MIOPredicate.predicateWithFormat("identifier = " + sectionID)) as ManagerSection;

        if (this.stockNote.status != StockNoteStatus.Processed) {
            let nextStatus = ad.selectedUser.checkAccessControlFlag(section.bitPosition, UserAccessControlBit.process) == false ? StockNoteStatus.Pending : StockNoteStatus.Processed;
            if (this.customStockNoteBeforeChangeStatus(nextStatus) == false) return;

            //set date if not found
            let now = MIODateNow();

            //SHOULD THESE TWO BE SWITCHED???
            if (this.stockNote.stockDate == null) this.stockNote.stockDate = now;
            this.stockNote.validationDate = now;

            // if (this.stockNote instanceof InventoryNote) {
            //     this.checkIfCanProcessInventoryNote();
            // }
            // else {            
                this.changeProcessNoteStatus(nextStatus);
            // }
        } 
        else {
            if (ad.selectedUser.checkAccessControlFlag(section.bitPosition, UserAccessControlBit.unprocess) == false) {
                AppHelper.showErrorMessage(null, "Info", "You don't have permission to unprocess this note");
                return;
            }

            this.showUnporcessAlert();
        }        
    }

    protected customStockNoteBeforeChangeStatus(status:number) : boolean {
        return true;
    }

    private updateUI(){
        MIONotificationCenter.defaultCenter().postNotification("StockNoteDidProcess", this.stockNote);
    }

    private showUnporcessAlert(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle("Warning", "Are you sure you want to unprocess this document?", MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("UNPROCESS", "UNPROCESS"), MUIAlertActionStyle.Destructive, this, function(){
            this.changeProcessNoteStatus(StockNoteStatus.Unprocessed);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        AppHelper.sharedInstance().presentViewController(avc, true);
    }

    private changeProcessNoteStatus(status:StockNoteStatus){
        this.stockNote.status = status;
        DBHelper.saveMainContextWithCompletion(this, function(this:NoteDetailHeaderView) {
            AppHelper.showInfoMessage(null, "Info", "The operation was sucessfull");
            this.updateUI();    
        });
    }

    private checkIfCanProcessInventoryNote(){

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.canProcessStockNote(this.stockNote, this, function(this:NoteDetailHeaderView, data:any, error:string){
            if ( error == null || (error != null && error.length == 0) ) {
                this.changeProcessNoteStatus(StockNoteStatus.Processed);
            }
            else if (data != null && data.lenght > 0) {
                AppHelper.showErrorMessage(null, "Error", "Couldn't unprocess. The inventory (" + data[0]["sourceDocumentReference"] + ") must be unprocess first!");                
            }
            else {
                AppHelper.showErrorMessage(null, "Error", "Couldn't unprocess. " + error);                
            }
        });   

    }
    
}