//
// Generated class Company
//

/// <reference path="Company_ManagedObject.ts" />

class Company extends Company_ManagedObject
{

}
