//
// Generated class DBSyncEntity
//

/// <reference path="DBSyncEntity_ManagedObject.ts" />

class DBSyncEntity extends DBSyncEntity_ManagedObject
{

}
