//
// Generated class Room
//

/// <reference path="Room_ManagedObject.ts" />

class Room extends Room_ManagedObject
{

}
