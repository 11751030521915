
interface MIOReportRowProtocol 
{
    reportRow():any;
    reportMainCellKey():any;
}

interface MIOReportRenderProtocol
{
    render():any;
}        

enum MIOReportOutputType
{
    Display = 0,
    JSON = 1,
    CSV = 2
}

class MIOReport extends MIOObject implements MIOReportRenderProtocol
{
    title:string = null;
    outputType = MIOReportOutputType.JSON;
    items:MIOReportRenderProtocol[] = [];

    initWithTitle(title:string, output_type = MIOReportOutputType.JSON){
        this.title = title;
        this.outputType = output_type;
        this.items = [];
    }

    addTable(title:string): MIOReportTable {
        let table = new MIOReportTable();
        table.initWithTitle(title, this);
        this.items.addObject(table);
        return table;
    }

    render():any{
        let data = [];
        for (let i of this.items){
            data.addObject(i.render());
        }

        return data;
    }
}

class MIOReportTableColumn extends MIOObject
{
    title:string = null;
    key:string = null;
    columnType:string = "string";
    columnAlign:string = "left";
    columnFilter:boolean = false;
    subTotal:boolean = false;
    maxLen:number = 0;

    initWithTitle(title:string, key:string, type:string = "string", subtotal:boolean = false, align:string = "center", column_filter:boolean = false) {
        this.title = title;
        this.key = key
        this.columnType = type
        this.columnAlign = align
        this.columnFilter = column_filter
        this.subTotal = subtotal
        this.maxLen = title.length;
    }

}

class MIOReportTableRow extends MIOObject
{
    cells:string[] = null;

    init() {
        this.cells = [];
    }

    addCellItem(item) {
        this.cells.addObject(item);
    }    
}

class MIOReportTable extends MIOObject implements MIOReportRenderProtocol
{
    title:string = null;
    report:MIOReport = null;
    keys = [];
    columns:MIOReportTableColumn[] = [];
    columnsByKey = {};
    rows:MIOReportTableRow[] = [];

    currentRow = null;

    initWithTitle(title, report = null) {
        this.title = title;
        this.report = report;
        this.keys = [];
        this.columns = [];
        this.columnsByKey = {};
        this.rows = [];
    }

    addColumn(title:string, key:string, type:string = "string", subtotal:boolean = false,  align:string = "left", column_filter:boolean = false) {
        let col = new MIOReportTableColumn();
        col.initWithTitle(title, key, type, subtotal, align, column_filter);
        this.columns.addObject(col);
        this.columnsByKey[key] = col
        this.keys.addObject(key);
    }

    newRow(): MIOReportTableRow {
        let row = new MIOReportTableRow();
        row.init();
        this.rows.addObject(row);
        this.currentRow = row;
        return row;
    }

    addRow(row: MIOReportRowProtocol) {
        let new_row = new MIOReportTableRow();
        this.rows.addObject(new_row);
        this.currentRow = new_row;

        let intent_level_key = row.reportMainCellKey();
        let main_key = intent_level_key["key"];
        let level = intent_level_key["indent_level"];

        let items = row.reportRow();
        for (let k of this.keys) {
            if (items[k] == null) {
                this.addCellItemForKey(k, "", 0);
                continue;

            }
            let item = items[k];
            let indent_level = 0;
            if (k == main_key) indent_level = level;                
            this.addCellItemForKey(k, item, indent_level);
        }       
    }

    addCellItemForKey(key:string, item:any, indent_level:number = 0) {
        if (item == null) {this.currentRow.addCellItem(""); return; }
        let buffer = (item instanceof String) ? item : item.toString();
        if (this.report.outputType == MIOReportOutputType.Display) {
            buffer = ""
            for(let i = 0; i <= indent_level; i++) { buffer += "  "; }                
            buffer += item;            
        }
        this.currentRow.addCellItem(buffer);
        // Calculate len. Only for UI
        let col_len = this.columnsByKey[key].maxLen;
        let buffer_len = buffer.length;
        if (buffer_len > col_len) {
            this.columnsByKey[key].maxLen = buffer_len;
        }
    }

    render():any {
        switch (this.report.outputType) {
            case MIOReportOutputType.Display:        
                //this.renderForDisplay();
                break;

            case MIOReportOutputType.JSON: return this.renderForJSON();                
            case MIOReportOutputType.CSV:
                //this.renderForCSV();
                break;
        }
    }

    private renderForJSON():any {
        let table = {"Title":this.title, "Type": "table", "Columns":[], "ColumnTypes":{}, "Rows":[], "SubTotals": {}};

        let columns = [];
        for (let c of this.columns) {
            let col = {"Title": c.title, "Key": c.key, "Type": c.columnType, "Align": c.columnAlign};
            columns.addObject(col);
        }
        table["Columns"] = columns;

        let rows = [];
        for (let r of this.rows){
            let row_item = {};
            for (let i=0; i < this.columns.length; i++) {
                let key = this.columns[i].key;
                let value = r.cells[i];
                row_item[key] = value;
            }
            rows.addObject(row_item);
        }
        table["Rows"] = rows;
        return table;
    }

}
