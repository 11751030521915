//
// Generated class DBMappingEntityCashDesk
//

/// <reference path="DBMappingEntityCashDesk_ManagedObject.ts" />

class DBMappingEntityCashDesk extends DBMappingEntityCashDesk_ManagedObject
{

}
