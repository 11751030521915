//
// Generated class EconomicEntity
//

/// <reference path="EconomicEntity_ManagedObject.ts" />

class EconomicEntity extends EconomicEntity_ManagedObject
{

}
