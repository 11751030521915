//
// Generated class LocalizedValue
//

/// <reference path="LocalizedValue_ManagedObject.ts" />

class LocalizedValue extends LocalizedValue_ManagedObject
{

}
