

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Rule_ManagedObject

class Rule_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: cut
    set cut(value:boolean) {
        this.setValueForKey(value, 'cut');
    }
    get cut():boolean {
        return this.valueForKey('cut');
    }
    set cutPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'cut');
    }
    get cutPrimitiveValue():boolean {
        return this.primitiveValueForKey('cut');
    }

    // Property: fn
    set fn(value:number) {
        this.setValueForKey(value, 'fn');
    }
    get fn():number {
        return this.valueForKey('fn');
    }
    set fnPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'fn');
    }
    get fnPrimitiveValue():number {
        return this.primitiveValueForKey('fn');
    }

    // Property: isAnd
    set isAnd(value:boolean) {
        this.setValueForKey(value, 'isAnd');
    }
    get isAnd():boolean {
        return this.valueForKey('isAnd');
    }
    set isAndPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isAnd');
    }
    get isAndPrimitiveValue():boolean {
        return this.primitiveValueForKey('isAnd');
    }

    // Property: op
    set op(value:number) {
        this.setValueForKey(value, 'op');
    }
    get op():number {
        return this.valueForKey('op');
    }
    set opPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'op');
    }
    get opPrimitiveValue():number {
        return this.primitiveValueForKey('op');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: value
    set value(value:string) {
        this.setValueForKey(value, 'value');
    }
    get value():string {
        return this.valueForKey('value');
    }
    set valuePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'value');
    }
    get valuePrimitiveValue():string {
        return this.primitiveValueForKey('value');
    }

    // Property: value2
    set value2(value:string) {
        this.setValueForKey(value, 'value2');
    }
    get value2():string {
        return this.valueForKey('value2');
    }
    set value2PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'value2');
    }
    get value2PrimitiveValue():string {
        return this.primitiveValueForKey('value2');
    }

    // Property: valueType
    set valueType(value:number) {
        this.setValueForKey(value, 'valueType');
    }
    get valueType():number {
        return this.valueForKey('valueType');
    }
    set valueTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'valueType');
    }
    get valueTypePrimitiveValue():number {
        return this.primitiveValueForKey('valueType');
    }
    // Relationship: ruleSet
    set ruleSet(value:RuleSet) {
        this.setValueForKey(value, 'ruleSet');
    }
    get ruleSet():RuleSet {
        return this.valueForKey('ruleSet') as RuleSet;
    }
}
