

class ProductAdminListViewController extends BaseTableViewController
{
    static newInstance() : ProductAdminListViewController {
        let vc = new ProductAdminListViewController('product-admin-view');
        vc.initWithResource("layout/productadmin/ProductAdminView.html");
        return vc;
    }
    
    private segmentedControl:MUISegmentedControl = null;
    private saveButton:MUIButton = null;

    private venueTextField:ColumnFilterTextField = null;
    private categoryTextField:ColumnFilterTextField = null;
    private productTextField:ColumnFilterTextField = null;

    private filterController:ColumnFilterController = null;
    private places_by_ID = {};

    private productDataSource:ProductAdminDataSource = null;
    private productFormatDataSource:ProductFormatAdminDataSource = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.segmentedControl = MUIOutlet(this, "segmented-control", "MUISegmentedControl");
        this.segmentedControl.setAction(this, this.changeDataSourceAction);

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        });

        this.filterController = new ColumnFilterController();
        this.filterController.initWithDelegate(this);
        this.filterController.customKeyPredicateFunction["venue-name"] = (value:any) : string|null => {
            let array = value.toLowerCase()
                             .replaceAll("venue-name contains ", "")
                             .replaceAll("'", "")
                             .split("or")
                             .map((item:string) => { return item.trim() } );
            
            let places = [];

            for (let id in this.places_by_ID) {
                let name = ( this.places_by_ID[ id ] ).toLowerCase();
                for (let v of array) {
                    if ( name.indexOf(v) >= 0 ) {
                        places.push(id);
                        break;
                    }
                }                
            }

            if (places.length == 0) return null;
            
            return "placeID in ['" + places.join("','") + "']";
        }

        this.venueTextField = MUIOutlet(this, "venue-filter-tf", "ColumnFilterTextField");
        this.venueTextField.filterController = this.filterController;
		this.venueTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "venue-name", null, null);

        this.categoryTextField = MUIOutlet(this, "category-filter-tf", "ColumnFilterTextField");
        this.categoryTextField.filterController = this.filterController;
		this.categoryTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "product.category.name", null, null);

        this.productTextField = MUIOutlet(this, "product-filter-tf", "ColumnFilterTextField");
        this.productTextField.filterController = this.filterController;
		this.productTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "product.name", null, null);

        // this.importButton = MUIOutlet(this, "import-btn", "MUIButton");
        // this.importButton.setAction(this, function(){
        //     this.openPanel();
        // });      

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.productDataSource = new ProductAdminDataSource();
        this.productDataSource.initWithTableView(this.tableView, this.filterController);
        this.tableView.dataSource = this.productDataSource;
        this.tableView.delegate = this.productDataSource;

        this.productFormatDataSource = new ProductFormatAdminDataSource();
        this.productFormatDataSource.initWithTableView(this.tableView, this.filterController);

        const places = DBHelper.queryObjectsFromMainContext("Place", "deletedAt = null", null);
        for (let p of places) {
            this.places_by_ID[p.identifier] = p.name;
        }
        
        this.productDataSource.setPlaces(this.places_by_ID);
        this.productFormatDataSource.setPlaces(this.places_by_ID);
    }

    private changeDataSourceAction(sender){
        switch(this.segmentedControl.selectedSegmentedIndex){
            case 0:
                this.tableView.dataSource = this.productDataSource;
                this.tableView.delegate = this.productDataSource;                
                break;

            case 1:
                break;    

            case 2:
                this.tableView.dataSource = this.productFormatDataSource;
                this.tableView.delegate = this.productFormatDataSource;
                break;                
        }

        this.tableView.reloadData();
    }

    filterPredicateDidChange(controller:ColumnFilterController){
        this.productDataSource.reloadData();
    }

    private openPanel(){
        let panel = MUIOpenPanel.openPanel();
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        panel.beginSheetModalForWindow(ad.window, this, function(result:number){
            if (result == MIOFileHandlingPanel.OKButton) {
               let file = panel.files[0];
               // Open  the document.
                this.importProductsFromFile(file);
            }       
         });      
    }

    private importProductsFromFile(file){
        let reader = new FileReader();

        let instance = this;
        reader.onload = function(e) {
            let data = reader.result;
            let workbook = XLSX.read(data, {
                type: 'binary'
            });
        
            workbook.SheetNames.forEach(function(sheetName) {
                // Here is your object
                let worksheet = workbook.Sheets[sheetName];                
                let result = [];
                let row;
                let rowNum;
                let colNum;
                let range = XLSX.utils.decode_range(worksheet['!ref']);
                for(rowNum = range.s.r; rowNum <= range.e.r; rowNum++){
                   row = [];
                    for(colNum=range.s.c; colNum<=range.e.c; colNum++){
                       let nextCell = worksheet[
                          XLSX.utils.encode_cell({r: rowNum, c: colNum})
                       ];
                       if( typeof nextCell === 'undefined' ){
                          row.push(void 0);
                       } else row.push(nextCell.w);
                    }
                    result.push(row);
                }                
                instance.importRows.call(instance, result);
            })
        };

        reader.onerror = function(ex) {
            console.log(ex);
        };
      
        reader.readAsBinaryString(file);  
    }

    private importRows(rows){
        let isProductRow = false;

        for (let rowIndex = 0; rowIndex < rows.length; rowIndex++){
            let row = rows[rowIndex];
                        
            if (row[0] != "GROUP" && isProductRow == false) continue;
            else if (row[0] == "GROUP") isProductRow = true;
            else this.parseRow(row);
        }
    }

    private parseRow(row){
        let subCategory = row[0];
        let category = row[1];
        let superCategory = row[2];
        let salesTax = row[3];
        let productName = row[4];
        let kitchenName = row[5];
        let contaninerQuantity = row[6];
        let measure = row[7];
        let customMeasureName = row[8];
        let purchaseTax = row[9];
        let costPrice = row[10];
        let format = row[11];
        let price = row[12];
        let tags = row[13];
        
        let isContainer = contaninerQuantity > 0 ? true : false;
        let containerMeasure = isContainer ? measure : null;
        let productMeasure = isContainer ? -1 : measure;
        
        if (isContainer) {
            MIOLog(productName + ": " + contaninerQuantity + " " + containerMeasure + " of " + customMeasureName + "(" + productMeasure + ")");
        }
        else {
            MIOLog(productName + ": " + customMeasureName);
        }
        
    }
}