

/// <reference path="TimeRangePresetConfiguration.ts" />

// Generated class BookingTimeRangePresetConfiguration_ManagedObject

class BookingTimeRangePresetConfiguration_ManagedObject extends TimeRangePresetConfiguration
{

    // Property: paymentPeriodInMinutes
    set paymentPeriodInMinutes(value:number) {
        this.setValueForKey(value, 'paymentPeriodInMinutes');
    }
    get paymentPeriodInMinutes():number {
        return this.valueForKey('paymentPeriodInMinutes');
    }
    set paymentPeriodInMinutesPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'paymentPeriodInMinutes');
    }
    get paymentPeriodInMinutesPrimitiveValue():number {
        return this.primitiveValueForKey('paymentPeriodInMinutes');
    }

    // Relationship: bookingZones
    protected _bookingZones:MIOManagedObjectSet = null;
    get bookingZones():MIOManagedObjectSet {
        return this.valueForKey('bookingZones');
    }
    addBookingZonesObject(value:BookingZoneConfiguration) {
        this._addObjectForKey(value, 'bookingZones');
    }
    removeBookingZonesObject(value:BookingZoneConfiguration) {
        this._removeObjectForKey(value, 'bookingZones');
    }
    // Relationship: event
    set event(value:VenueEvent) {
        this.setValueForKey(value, 'event');
    }
    get event():VenueEvent {
        return this.valueForKey('event') as VenueEvent;
    }

    // Relationship: paymentRules
    protected _paymentRules:MIOManagedObjectSet = null;
    get paymentRules():MIOManagedObjectSet {
        return this.valueForKey('paymentRules');
    }
    addPaymentRulesObject(value:RuleResult) {
        this._addObjectForKey(value, 'paymentRules');
    }
    removePaymentRulesObject(value:RuleResult) {
        this._removeObjectForKey(value, 'paymentRules');
    }
}
