/**
 * Created by crisan on 7/12/16.
 */



/// <reference path="../../model/ProductListItem.ts" />


class ProductCategoryCell extends UITableViewCell
{
    private imageView:MUIImageView = null;
    private nameLabel:MUILabel = null;    
    
    awakeFromHTML(){
        this.imageView = MUIOutlet(this, 'pclv_category_image','MUIImageView');        
        this.nameLabel = MUIOutlet(this, "pclv_category_lbl", "MUILabel");        
    }

    set item(i:ProductCategory){
        this.nameLabel.text = i.name;
        this.imageView.setImage( i.image ? i.image.url : null );

        this.imageView.layer.style.marginLeft = ( i.indentLevel * 20 ) + "px";
        // this.nameLabel.layer.style.paddingLeft = ( i.indentLevel * 20 ) + "px";
    }

}