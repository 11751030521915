//
// Generated class LowOrder
//

/// <reference path="LowOrder_ManagedObject.ts" />

class LowOrder extends LowOrder_ManagedObject
{

}
