interface String
{ 
    valueFromPercentageString(value:number):number; 
}

    
String.prototype.valueFromPercentageString = function(total:number) : number {
    let ad = ( MUIWebApplication.sharedInstance().delegate ) as AppDelegate;
    let v = ad.percentNumberFormatter.numberFromString( this );
    if ( this.hasSuffix("%") == false ) {        
        return total - v;
    }
    else {                    
        return ( v * total ) / 100;
    }    
}