

/// <reference path="AccountBalance.ts" />

// Generated class LedgerAccountBalance_ManagedObject

class LedgerAccountBalance_ManagedObject extends AccountBalance
{

    // Property: credit
    set credit(value:number) {
        this.setValueForKey(value, 'credit');
    }
    get credit():number {
        return this.valueForKey('credit');
    }
    set creditPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'credit');
    }
    get creditPrimitiveValue():number {
        return this.primitiveValueForKey('credit');
    }

    // Property: debit
    set debit(value:number) {
        this.setValueForKey(value, 'debit');
    }
    get debit():number {
        return this.valueForKey('debit');
    }
    set debitPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'debit');
    }
    get debitPrimitiveValue():number {
        return this.primitiveValueForKey('debit');
    }

    // Property: entryCount
    set entryCount(value:number) {
        this.setValueForKey(value, 'entryCount');
    }
    get entryCount():number {
        return this.valueForKey('entryCount');
    }
    set entryCountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'entryCount');
    }
    get entryCountPrimitiveValue():number {
        return this.primitiveValueForKey('entryCount');
    }
}
