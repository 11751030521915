//
// Generated class StockAnnotationSummary
//

/// <reference path="StockAnnotationSummary_ManagedObject.ts" />

class StockAnnotationSummary extends StockAnnotationSummary_ManagedObject
{

}
