//
// Generated class OnlineOrderLine
//

/// <reference path="OnlineOrderLine_ManagedObject.ts" />

class OnlineOrderLine extends OnlineOrderLine_ManagedObject
{
    get parentPath():string{
        return (this.parent ? this.parent.parentPath + "." : "") + this.identifier;
    }

    get cellIndentation():number {
        return this.follow_parent(0);
    }

    private follow_parent(index:number){
        if (this.parent == null) return 0;
        else return this.parent.follow_parent(index + 1);
    }
}
