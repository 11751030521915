//
// Generated class PromotionalCodeLine
//

/// <reference path="PromotionalCodeLine_ManagedObject.ts" />

class PromotionalCodeLine extends PromotionalCodeLine_ManagedObject
{

}
