//
// Generated class Business
//

/// <reference path="Business_ManagedObject.ts" />

enum BusinessType
{
    Place,
    Company,
    Group
}

class Business extends Business_ManagedObject
{
    get indexPath():string {
        return this._followIndexPath(this.parent, this.name);
    }

    private _followIndexPath(parent:Business, name:string):string{
        if (parent == null) return name;
        return this._followIndexPath(parent.parent, parent.name) + "/" + name;
    }

    get typeString():string{
        switch (this.type){
            case BusinessType.Place:
            return "place";
            break;

            case BusinessType.Company:
            return "company";
            break;

            case BusinessType.Group:
            return "group";
            break;
        }
    }
}
