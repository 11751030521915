class ProductSupplierCell extends UITableViewCell
{    
    private nameLabel:MUILabel = null;
    private nameTextField:MUITextField = null;
    private referenceTextField:MUITextField = null;
    private referenceNameTextField:MUITextField = null;
    private inputFormatDropdown:MUIButton = null;
    private lastPriceLabel:MUILabel = null;
    private priceTextField:MUITextField = null;
    private discountTextField:MUITextField = null;
            
    private cf = MUIWebApplication.sharedInstance().delegate.longCurrencyFormatter;    

    awakeFromHTML(){        
        this.nameLabel = MUIOutlet(this, 'name-lbl', 'MUILabel');
        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.setupSupplierTextField(this.nameTextField, this, this.supplierDidSelect);

        this.referenceTextField = MUIOutlet(this, 'reference-tf', 'MUITextField');
        this.setupReferenceTextField(this.referenceTextField);

        this.referenceNameTextField = MUIOutlet(this, 'reference-name-tf', 'MUITextField');
        this.setupReferenceNameTextField(this.referenceNameTextField);

        this.inputFormatDropdown = MUIOutlet( this, "input-format-dd", "MUIButton" );
        this.inputFormatDropdown.setAction( this, function( this:ProductSupplierCell ) {
            AppHelper.sharedInstance().showSelectStockInputFormatViewControllerFromView( this.inputFormatDropdown, this._product, ( controller:any, object:StockInputFormat ) => {
                this.inputFormat = object;
                this.inputFormatDropdown.title = object?.name;
                this.updateProductSupplier();
            } );
        } ) ;

        this.priceTextField = MUIOutlet(this, 'price-tf', 'MUITextField');
        this.setupPriceTextField(this.priceTextField);

        this.lastPriceLabel = MUIOutlet(this, "last-price-lbl", "MUILabel");
        this.discountTextField = MUIOutlet(this, "discount-tf", "MUITextField");
        this.discountTextField.formatter = MUIWebApplication.sharedInstance().delegate.percentNumberFormatter;
        this.setupDiscountTextField(this.discountTextField);
        
        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private reference:string = null;
    private referenceName:string = null;
    private inputFormat:StockInputFormat = null;
    private price = null;
    private productPrice = null;
    private discount = null;

    private _item:SupplierProduct = null;
    set item(i:SupplierProduct){
        this._item = i;
            
        this.reset();
        
        this.reference = i.supplierReference;
        this.referenceName = i.supplierReferenceName;
        this.inputFormat = i.inputFormat;
        this.price = i.price;
        this.productPrice = i.productPrice;
        this.discount = i.discountString;

        this.nameLabel.text = i.supplier != null ? i.supplier.name : MIOLocalizeString("SUPPLIER DELETED", "SUPPLIER DELETED");
        this.referenceTextField.text = i.supplierReference;
        this.referenceNameTextField.text = i.supplierReferenceName;
        this.inputFormatDropdown.title = i.inputFormatName;
        this.lastPriceLabel.text = this.cf.stringFromNumber(i.computedLastPrice);
        this.priceTextField.text = this.cf.stringFromNumber(i.price);
        this.discountTextField.text = i.discountString;
    }

    private _product:Product = null;
    set product(product:Product){
        this._product = product;
    }

    private supplier:Supplier = null;
    supplierDidSelect( controller:SelectEntityViewController, supplier:Supplier ) {
        this.supplier = supplier;        
        this.nameTextField.text = supplier.name;
        this.referenceTextField.becomeFirstResponder();
    }

    private enterDidPress(){
        if (this._item != null) return;
        if (this.supplier == null) {
            AppHelper.showErrorMessage(null, MIOLocalizeString("ERROR", "ERROR"), MIOLocalizeString("SELECT THE SUPPLIER AGAIN AND TRY AGAIN", "SELECT THE SUPPLIER AGAIN AND TRY AGAIN"));
            return;
        }
                
        if (this.price == null) {
            AppHelper.showErrorMessage( null, MIOLocalizeString( "ERROR", "ERROR" ), MIOLocalizeString( "COULDN'T ADD A PRODUCT WITHOUT PRICE", "COULDN'T ADD A PRODUCT WITHOUT PRICE" ) );
            return;
        }
        
        // check duplicate item        
        let pf = "deletedAt = null && product.identifier = " + this._product.identifier + " && supplier.identifier = " + this.supplier.identifier;
        // pf += this.inputFormat != null ? " and inputFormat.identifier = " + this.inputFormat.identifier : " and inputFormat = null";
        
        DBHelper.queryObjectWithCompletion( "SupplierProduct", null, MIOPredicate.predicateWithFormat( pf ), [], this, function( this:ProductSupplierCell, object:SupplierProduct ) {

            if ( object != null ) {
                AppHelper.showErrorMessage( null, MIOLocalizeString( "ERROR", "ERROR" ), MIOLocalizeString( "COULDN'T ADD A PRODUCT THAT IS ALREADY ADDED", "COULDN'T ADD A PRODUCT THAT IS ALREADY ADDED" ) );
                return;
            }
    
            let sp = DBHelper.createSupplierProduct( this.supplier, this._product, this.price, this.productPrice, this.reference, this.discount );
            sp.supplierReferenceName = this.referenceName;
            // sp.inputFormat = this.inputFormat;
            // sp.inputFormatName = this.inputFormat?.name;
            DBHelper.saveMainContext();
            this.reset();
    
        } );
    }

    private setupSupplierTextField(textField:MUITextField, target, completion){
        if (textField == null) return;
        textField.setOnChangeText(this, function(control, value:string){
            AppHelper.sharedInstance().showSelectSupplierViewControllerFromView(textField, value, false, target, completion);
        });
    }

    private setupReferenceTextField(textField:MUITextField){
        textField.setOnChangeText(this, function(textfield, value){
            this.reference = value;
            this.updateProductSupplier();
        });
        
        textField.setOnEnterPress(this, function(control:MUITextField){
            this.enterDidPress();
        });
    }

    private setupReferenceNameTextField(textField:MUITextField){
        textField.setOnChangeText(this, function( this:ProductSupplierCell, textfield:MUITextField, value:string ){
            this.referenceName = value;
            this.updateProductSupplier();
        });
        
        textField.setOnEnterPress(this, function(control:MUITextField){
            this.enterDidPress();
        });
    }

    private setupPriceTextField(textField:MUITextField){
        textField.formatter = this.cf;
        textField.setOnChangeText(this, function(textfield, value:string){
            this.price = this.cf.numberFromString(value);
            
            this.productPrice = this.price;
            this.updateProductSupplier();
        });
        
        textField.setOnEnterPress(this, function(control:MUITextField){
            this.enterDidPress();
        });
    }

    private setupDiscountTextField(textField:MUITextField){
        textField.setOnChangeText(this, function(textfield, value){
            this.discount = value;
            this.updateProductSupplier();
        });

        textField.setOnEnterPress(this, function(control:MUITextField){
            this.enterDidPress();
        });
    }

    private reset()
    {
        if (this.nameTextField) this.nameTextField.text = null;
        this.referenceTextField.text = null;
        this.lastPriceLabel.text = null;
        this.priceTextField.text = null;
        this.discountTextField.text = null;
    }

    private updateProductSupplier()
    {
        if (this._item == null) return;
        
        this._item.supplierReference = this.reference;
        this._item.supplierReferenceName = this.referenceName;
        this._item.inputFormat = this.inputFormat;
        this._item.inputFormatName = this.inputFormat?.name;
        this._item.price = this.price;
        this._item.productPrice = this.productPrice;        
        this._item.discountString = this.discount;
    }
}
