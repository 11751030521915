//
// Generated class RateInterval
//

/// <reference path="RateInterval_ManagedObject.ts" />

class RateInterval extends RateInterval_ManagedObject
{

}
