

class TableMapItem extends MUIView
{
    private imageView:MUIImageView = null;
    private titleLabel:MUILabel = null;

    awakeFromHTML(){        
        this.imageView = MUIOutlet(this, "table-map-image-view", "MUIImageView");
        this.titleLabel = MUIOutlet(this, "table-map-title-lbl", "MUILabel");

        this.userInteraction = true;
    }

    set location(loc:any){
        this.layer.style.top = (100 - loc.y) + "%";
        this.layer.style.left = loc.x + "%";
        this.imageView.layer.style.transform = "rotate( -" + loc.angle + "rad)"
        
        let url = TableMapLocationTypeURL(loc.type);
        this.imageView.setImage(url);

        this.titleLabel.text = TableMapIsOcupiable(loc.type) ? loc.name : null;
    }    

    private _selected:boolean = false;
    get selected():boolean { return this._selected; }
    set selected(value:boolean){
        if (value == true) {
            this.imageView.layer.style.borderRadius = "10px";
            this.imageView.layer.style.boxShadow = "0 0 2px 2px green";
        }
        else {
            this.imageView.layer.style.borderRadius = "";
            this.imageView.layer.style.boxShadow = "";
        }
        this._selected = value;
    }    

    toggleSelected(){
        this.selected = !this.selected;
    }
}