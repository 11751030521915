

/// <reference path="PaymentEntity.ts" />

// Generated class AdyenPaymentEntity_ManagedObject

class AdyenPaymentEntity_ManagedObject extends PaymentEntity
{

    // Property: companyCity
    set companyCity(value:string) {
        this.setValueForKey(value, 'companyCity');
    }
    get companyCity():string {
        return this.valueForKey('companyCity');
    }
    set companyCityPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'companyCity');
    }
    get companyCityPrimitiveValue():string {
        return this.primitiveValueForKey('companyCity');
    }

    // Property: companyCountry
    set companyCountry(value:string) {
        this.setValueForKey(value, 'companyCountry');
    }
    get companyCountry():string {
        return this.valueForKey('companyCountry');
    }
    set companyCountryPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'companyCountry');
    }
    get companyCountryPrimitiveValue():string {
        return this.primitiveValueForKey('companyCountry');
    }

    // Property: companyPostalCode
    set companyPostalCode(value:string) {
        this.setValueForKey(value, 'companyPostalCode');
    }
    get companyPostalCode():string {
        return this.valueForKey('companyPostalCode');
    }
    set companyPostalCodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'companyPostalCode');
    }
    get companyPostalCodePrimitiveValue():string {
        return this.primitiveValueForKey('companyPostalCode');
    }

    // Property: companyStreet
    set companyStreet(value:string) {
        this.setValueForKey(value, 'companyStreet');
    }
    get companyStreet():string {
        return this.valueForKey('companyStreet');
    }
    set companyStreetPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'companyStreet');
    }
    get companyStreetPrimitiveValue():string {
        return this.primitiveValueForKey('companyStreet');
    }

    // Property: companyType
    set companyType(value:number) {
        this.setValueForKey(value, 'companyType');
    }
    get companyType():number {
        return this.valueForKey('companyType');
    }
    set companyTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'companyType');
    }
    get companyTypePrimitiveValue():number {
        return this.primitiveValueForKey('companyType');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: useDiferentAddress
    set useDiferentAddress(value:boolean) {
        this.setValueForKey(value, 'useDiferentAddress');
    }
    get useDiferentAddress():boolean {
        return this.valueForKey('useDiferentAddress');
    }
    set useDiferentAddressPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'useDiferentAddress');
    }
    get useDiferentAddressPrimitiveValue():boolean {
        return this.primitiveValueForKey('useDiferentAddress');
    }

    // Relationship: stores
    protected _stores:MIOManagedObjectSet = null;
    get stores():MIOManagedObjectSet {
        return this.valueForKey('stores');
    }
    addStoresObject(value:AdyenPaymentEntityStore) {
        this._addObjectForKey(value, 'stores');
    }
    removeStoresObject(value:AdyenPaymentEntityStore) {
        this._removeObjectForKey(value, 'stores');
    }
}
