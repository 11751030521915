//
// Generated class PresetRelation
//

/// <reference path="PresetRelation_ManagedObject.ts" />

class PresetRelation extends PresetRelation_ManagedObject
{

}
