//
// Generated class EconomicAccountLine
//

/// <reference path="EconomicAccountLine_ManagedObject.ts" />

class EconomicAccountLine extends EconomicAccountLine_ManagedObject
{

}
