

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Annotation_ManagedObject

class Annotation_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: accountID
    set accountID(value:string) {
        this.setValueForKey(value, 'accountID');
    }
    get accountID():string {
        return this.valueForKey('accountID');
    }
    set accountIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'accountID');
    }
    get accountIDPrimitiveValue():string {
        return this.primitiveValueForKey('accountID');
    }

    // Property: base
    set base(value:number) {
        this.setValueForKey(value, 'base');
    }
    get base():number {
        return this.valueForKey('base');
    }
    set basePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'base');
    }
    get basePrimitiveValue():number {
        return this.primitiveValueForKey('base');
    }

    // Property: bookingID
    set bookingID(value:string) {
        this.setValueForKey(value, 'bookingID');
    }
    get bookingID():string {
        return this.valueForKey('bookingID');
    }
    set bookingIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'bookingID');
    }
    get bookingIDPrimitiveValue():string {
        return this.primitiveValueForKey('bookingID');
    }

    // Property: bulkMeasureUnit
    set bulkMeasureUnit(value:number) {
        this.setValueForKey(value, 'bulkMeasureUnit');
    }
    get bulkMeasureUnit():number {
        return this.valueForKey('bulkMeasureUnit');
    }
    set bulkMeasureUnitPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'bulkMeasureUnit');
    }
    get bulkMeasureUnitPrimitiveValue():number {
        return this.primitiveValueForKey('bulkMeasureUnit');
    }

    // Property: bulkQuantity
    set bulkQuantity(value:number) {
        this.setValueForKey(value, 'bulkQuantity');
    }
    get bulkQuantity():number {
        return this.valueForKey('bulkQuantity');
    }
    set bulkQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'bulkQuantity');
    }
    get bulkQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('bulkQuantity');
    }

    // Property: cashDeskID
    set cashDeskID(value:string) {
        this.setValueForKey(value, 'cashDeskID');
    }
    get cashDeskID():string {
        return this.valueForKey('cashDeskID');
    }
    set cashDeskIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'cashDeskID');
    }
    get cashDeskIDPrimitiveValue():string {
        return this.primitiveValueForKey('cashDeskID');
    }

    // Property: categoryID
    set categoryID(value:string) {
        this.setValueForKey(value, 'categoryID');
    }
    get categoryID():string {
        return this.valueForKey('categoryID');
    }
    set categoryIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'categoryID');
    }
    get categoryIDPrimitiveValue():string {
        return this.primitiveValueForKey('categoryID');
    }

    // Property: categoryName
    set categoryName(value:string) {
        this.setValueForKey(value, 'categoryName');
    }
    get categoryName():string {
        return this.valueForKey('categoryName');
    }
    set categoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'categoryName');
    }
    get categoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('categoryName');
    }

    // Property: clientID
    set clientID(value:string) {
        this.setValueForKey(value, 'clientID');
    }
    get clientID():string {
        return this.valueForKey('clientID');
    }
    set clientIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientID');
    }
    get clientIDPrimitiveValue():string {
        return this.primitiveValueForKey('clientID');
    }

    // Property: clientName
    set clientName(value:string) {
        this.setValueForKey(value, 'clientName');
    }
    get clientName():string {
        return this.valueForKey('clientName');
    }
    set clientNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientName');
    }
    get clientNamePrimitiveValue():string {
        return this.primitiveValueForKey('clientName');
    }

    // Property: closed
    set closed(value:boolean) {
        this.setValueForKey(value, 'closed');
    }
    get closed():boolean {
        return this.valueForKey('closed');
    }
    set closedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'closed');
    }
    get closedPrimitiveValue():boolean {
        return this.primitiveValueForKey('closed');
    }

    // Property: closeDate
    set closeDate(value:Date) {
        this.setValueForKey(value, 'closeDate');
    }
    get closeDate():Date {
        return this.valueForKey('closeDate');
    }
    set closeDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'closeDate');
    }
    get closeDatePrimitiveValue():Date {
        return this.primitiveValueForKey('closeDate');
    }

    // Property: concept
    set concept(value:string) {
        this.setValueForKey(value, 'concept');
    }
    get concept():string {
        return this.valueForKey('concept');
    }
    set conceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'concept');
    }
    get conceptPrimitiveValue():string {
        return this.primitiveValueForKey('concept');
    }

    // Property: consumptionQuantity
    set consumptionQuantity(value:number) {
        this.setValueForKey(value, 'consumptionQuantity');
    }
    get consumptionQuantity():number {
        return this.valueForKey('consumptionQuantity');
    }
    set consumptionQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'consumptionQuantity');
    }
    get consumptionQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('consumptionQuantity');
    }

    // Property: consumptionUnit
    set consumptionUnit(value:number) {
        this.setValueForKey(value, 'consumptionUnit');
    }
    get consumptionUnit():number {
        return this.valueForKey('consumptionUnit');
    }
    set consumptionUnitPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'consumptionUnit');
    }
    get consumptionUnitPrimitiveValue():number {
        return this.primitiveValueForKey('consumptionUnit');
    }

    // Property: containerConsumption
    set containerConsumption(value:number) {
        this.setValueForKey(value, 'containerConsumption');
    }
    get containerConsumption():number {
        return this.valueForKey('containerConsumption');
    }
    set containerConsumptionPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'containerConsumption');
    }
    get containerConsumptionPrimitiveValue():number {
        return this.primitiveValueForKey('containerConsumption');
    }

    // Property: containerQuantity
    set containerQuantity(value:number) {
        this.setValueForKey(value, 'containerQuantity');
    }
    get containerQuantity():number {
        return this.valueForKey('containerQuantity');
    }
    set containerQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'containerQuantity');
    }
    get containerQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('containerQuantity');
    }

    // Property: containerUnit
    set containerUnit(value:number) {
        this.setValueForKey(value, 'containerUnit');
    }
    get containerUnit():number {
        return this.valueForKey('containerUnit');
    }
    set containerUnitPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'containerUnit');
    }
    get containerUnitPrimitiveValue():number {
        return this.primitiveValueForKey('containerUnit');
    }

    // Property: cost
    set cost(value:number) {
        this.setValueForKey(value, 'cost');
    }
    get cost():number {
        return this.valueForKey('cost');
    }
    set costPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'cost');
    }
    get costPrimitiveValue():number {
        return this.primitiveValueForKey('cost');
    }

    // Property: costProductAveragePrice
    set costProductAveragePrice(value:number) {
        this.setValueForKey(value, 'costProductAveragePrice');
    }
    get costProductAveragePrice():number {
        return this.valueForKey('costProductAveragePrice');
    }
    set costProductAveragePricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'costProductAveragePrice');
    }
    get costProductAveragePricePrimitiveValue():number {
        return this.primitiveValueForKey('costProductAveragePrice');
    }

    // Property: costProductComponentsPrice
    set costProductComponentsPrice(value:number) {
        this.setValueForKey(value, 'costProductComponentsPrice');
    }
    get costProductComponentsPrice():number {
        return this.valueForKey('costProductComponentsPrice');
    }
    set costProductComponentsPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'costProductComponentsPrice');
    }
    get costProductComponentsPricePrimitiveValue():number {
        return this.primitiveValueForKey('costProductComponentsPrice');
    }

    // Property: costProductLastPrice
    set costProductLastPrice(value:number) {
        this.setValueForKey(value, 'costProductLastPrice');
    }
    get costProductLastPrice():number {
        return this.valueForKey('costProductLastPrice');
    }
    set costProductLastPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'costProductLastPrice');
    }
    get costProductLastPricePrimitiveValue():number {
        return this.primitiveValueForKey('costProductLastPrice');
    }

    // Property: costProductPrice
    set costProductPrice(value:number) {
        this.setValueForKey(value, 'costProductPrice');
    }
    get costProductPrice():number {
        return this.valueForKey('costProductPrice');
    }
    set costProductPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'costProductPrice');
    }
    get costProductPricePrimitiveValue():number {
        return this.primitiveValueForKey('costProductPrice');
    }

    // Property: currencyID
    set currencyID(value:string) {
        this.setValueForKey(value, 'currencyID');
    }
    get currencyID():string {
        return this.valueForKey('currencyID');
    }
    set currencyIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'currencyID');
    }
    get currencyIDPrimitiveValue():string {
        return this.primitiveValueForKey('currencyID');
    }

    // Property: currencyName
    set currencyName(value:string) {
        this.setValueForKey(value, 'currencyName');
    }
    get currencyName():string {
        return this.valueForKey('currencyName');
    }
    set currencyNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'currencyName');
    }
    get currencyNamePrimitiveValue():string {
        return this.primitiveValueForKey('currencyName');
    }

    // Property: customDescription
    set customDescription(value:string) {
        this.setValueForKey(value, 'customDescription');
    }
    get customDescription():string {
        return this.valueForKey('customDescription');
    }
    set customDescriptionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'customDescription');
    }
    get customDescriptionPrimitiveValue():string {
        return this.primitiveValueForKey('customDescription');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: debtLineID
    set debtLineID(value:string) {
        this.setValueForKey(value, 'debtLineID');
    }
    get debtLineID():string {
        return this.valueForKey('debtLineID');
    }
    set debtLineIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'debtLineID');
    }
    get debtLineIDPrimitiveValue():string {
        return this.primitiveValueForKey('debtLineID');
    }

    // Property: discount
    set discount(value:number) {
        this.setValueForKey(value, 'discount');
    }
    get discount():number {
        return this.valueForKey('discount');
    }
    set discountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'discount');
    }
    get discountPrimitiveValue():number {
        return this.primitiveValueForKey('discount');
    }

    // Property: discountRate
    set discountRate(value:number) {
        this.setValueForKey(value, 'discountRate');
    }
    get discountRate():number {
        return this.valueForKey('discountRate');
    }
    set discountRatePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'discountRate');
    }
    get discountRatePrimitiveValue():number {
        return this.primitiveValueForKey('discountRate');
    }

    // Property: documentClientID
    set documentClientID(value:string) {
        this.setValueForKey(value, 'documentClientID');
    }
    get documentClientID():string {
        return this.valueForKey('documentClientID');
    }
    set documentClientIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentClientID');
    }
    get documentClientIDPrimitiveValue():string {
        return this.primitiveValueForKey('documentClientID');
    }

    // Property: documentClientName
    set documentClientName(value:string) {
        this.setValueForKey(value, 'documentClientName');
    }
    get documentClientName():string {
        return this.valueForKey('documentClientName');
    }
    set documentClientNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentClientName');
    }
    get documentClientNamePrimitiveValue():string {
        return this.primitiveValueForKey('documentClientName');
    }

    // Property: documentExternalRef
    set documentExternalRef(value:string) {
        this.setValueForKey(value, 'documentExternalRef');
    }
    get documentExternalRef():string {
        return this.valueForKey('documentExternalRef');
    }
    set documentExternalRefPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentExternalRef');
    }
    get documentExternalRefPrimitiveValue():string {
        return this.primitiveValueForKey('documentExternalRef');
    }

    // Property: documentID
    set documentID(value:string) {
        this.setValueForKey(value, 'documentID');
    }
    get documentID():string {
        return this.valueForKey('documentID');
    }
    set documentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentID');
    }
    get documentIDPrimitiveValue():string {
        return this.primitiveValueForKey('documentID');
    }

    // Property: documentLegalDate
    set documentLegalDate(value:Date) {
        this.setValueForKey(value, 'documentLegalDate');
    }
    get documentLegalDate():Date {
        return this.valueForKey('documentLegalDate');
    }
    set documentLegalDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'documentLegalDate');
    }
    get documentLegalDatePrimitiveValue():Date {
        return this.primitiveValueForKey('documentLegalDate');
    }

    // Property: documentLegalPrefix
    set documentLegalPrefix(value:string) {
        this.setValueForKey(value, 'documentLegalPrefix');
    }
    get documentLegalPrefix():string {
        return this.valueForKey('documentLegalPrefix');
    }
    set documentLegalPrefixPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentLegalPrefix');
    }
    get documentLegalPrefixPrimitiveValue():string {
        return this.primitiveValueForKey('documentLegalPrefix');
    }

    // Property: documentLegalRef
    set documentLegalRef(value:string) {
        this.setValueForKey(value, 'documentLegalRef');
    }
    get documentLegalRef():string {
        return this.valueForKey('documentLegalRef');
    }
    set documentLegalRefPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentLegalRef');
    }
    get documentLegalRefPrimitiveValue():string {
        return this.primitiveValueForKey('documentLegalRef');
    }

    // Property: documentRef
    set documentRef(value:string) {
        this.setValueForKey(value, 'documentRef');
    }
    get documentRef():string {
        return this.valueForKey('documentRef');
    }
    set documentRefPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentRef');
    }
    get documentRefPrimitiveValue():string {
        return this.primitiveValueForKey('documentRef');
    }

    // Property: documentType
    set documentType(value:number) {
        this.setValueForKey(value, 'documentType');
    }
    get documentType():number {
        return this.valueForKey('documentType');
    }
    set documentTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'documentType');
    }
    get documentTypePrimitiveValue():number {
        return this.primitiveValueForKey('documentType');
    }

    // Property: documentTypeName
    set documentTypeName(value:string) {
        this.setValueForKey(value, 'documentTypeName');
    }
    get documentTypeName():string {
        return this.valueForKey('documentTypeName');
    }
    set documentTypeNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentTypeName');
    }
    get documentTypeNamePrimitiveValue():string {
        return this.primitiveValueForKey('documentTypeName');
    }

    // Property: externalPlaceID
    set externalPlaceID(value:string) {
        this.setValueForKey(value, 'externalPlaceID');
    }
    get externalPlaceID():string {
        return this.valueForKey('externalPlaceID');
    }
    set externalPlaceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'externalPlaceID');
    }
    get externalPlaceIDPrimitiveValue():string {
        return this.primitiveValueForKey('externalPlaceID');
    }

    // Property: externalPlaceName
    set externalPlaceName(value:string) {
        this.setValueForKey(value, 'externalPlaceName');
    }
    get externalPlaceName():string {
        return this.valueForKey('externalPlaceName');
    }
    set externalPlaceNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'externalPlaceName');
    }
    get externalPlaceNamePrimitiveValue():string {
        return this.primitiveValueForKey('externalPlaceName');
    }

    // Property: formatID
    set formatID(value:string) {
        this.setValueForKey(value, 'formatID');
    }
    get formatID():string {
        return this.valueForKey('formatID');
    }
    set formatIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'formatID');
    }
    get formatIDPrimitiveValue():string {
        return this.primitiveValueForKey('formatID');
    }

    // Property: formatName
    set formatName(value:string) {
        this.setValueForKey(value, 'formatName');
    }
    get formatName():string {
        return this.valueForKey('formatName');
    }
    set formatNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'formatName');
    }
    get formatNamePrimitiveValue():string {
        return this.primitiveValueForKey('formatName');
    }

    // Property: groupID
    set groupID(value:string) {
        this.setValueForKey(value, 'groupID');
    }
    get groupID():string {
        return this.valueForKey('groupID');
    }
    set groupIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'groupID');
    }
    get groupIDPrimitiveValue():string {
        return this.primitiveValueForKey('groupID');
    }

    // Property: groupSessionBeginDate
    set groupSessionBeginDate(value:Date) {
        this.setValueForKey(value, 'groupSessionBeginDate');
    }
    get groupSessionBeginDate():Date {
        return this.valueForKey('groupSessionBeginDate');
    }
    set groupSessionBeginDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'groupSessionBeginDate');
    }
    get groupSessionBeginDatePrimitiveValue():Date {
        return this.primitiveValueForKey('groupSessionBeginDate');
    }

    // Property: groupSessionID
    set groupSessionID(value:string) {
        this.setValueForKey(value, 'groupSessionID');
    }
    get groupSessionID():string {
        return this.valueForKey('groupSessionID');
    }
    set groupSessionIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'groupSessionID');
    }
    get groupSessionIDPrimitiveValue():string {
        return this.primitiveValueForKey('groupSessionID');
    }

    // Property: groupSessionName
    set groupSessionName(value:string) {
        this.setValueForKey(value, 'groupSessionName');
    }
    get groupSessionName():string {
        return this.valueForKey('groupSessionName');
    }
    set groupSessionNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'groupSessionName');
    }
    get groupSessionNamePrimitiveValue():string {
        return this.primitiveValueForKey('groupSessionName');
    }

    // Property: inProcessSeconds
    set inProcessSeconds(value:number) {
        this.setValueForKey(value, 'inProcessSeconds');
    }
    get inProcessSeconds():number {
        return this.valueForKey('inProcessSeconds');
    }
    set inProcessSecondsPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'inProcessSeconds');
    }
    get inProcessSecondsPrimitiveValue():number {
        return this.primitiveValueForKey('inProcessSeconds');
    }

    // Property: invitationConcept
    set invitationConcept(value:string) {
        this.setValueForKey(value, 'invitationConcept');
    }
    get invitationConcept():string {
        return this.valueForKey('invitationConcept');
    }
    set invitationConceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invitationConcept');
    }
    get invitationConceptPrimitiveValue():string {
        return this.primitiveValueForKey('invitationConcept');
    }

    // Property: invitationResponsibleID
    set invitationResponsibleID(value:string) {
        this.setValueForKey(value, 'invitationResponsibleID');
    }
    get invitationResponsibleID():string {
        return this.valueForKey('invitationResponsibleID');
    }
    set invitationResponsibleIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invitationResponsibleID');
    }
    get invitationResponsibleIDPrimitiveValue():string {
        return this.primitiveValueForKey('invitationResponsibleID');
    }

    // Property: invitationResponsibleName
    set invitationResponsibleName(value:string) {
        this.setValueForKey(value, 'invitationResponsibleName');
    }
    get invitationResponsibleName():string {
        return this.valueForKey('invitationResponsibleName');
    }
    set invitationResponsibleNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invitationResponsibleName');
    }
    get invitationResponsibleNamePrimitiveValue():string {
        return this.primitiveValueForKey('invitationResponsibleName');
    }

    // Property: invitationValue
    set invitationValue(value:number) {
        this.setValueForKey(value, 'invitationValue');
    }
    get invitationValue():number {
        return this.valueForKey('invitationValue');
    }
    set invitationValuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'invitationValue');
    }
    get invitationValuePrimitiveValue():number {
        return this.primitiveValueForKey('invitationValue');
    }

    // Property: invited
    set invited(value:number) {
        this.setValueForKey(value, 'invited');
    }
    get invited():number {
        return this.valueForKey('invited');
    }
    set invitedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'invited');
    }
    get invitedPrimitiveValue():number {
        return this.primitiveValueForKey('invited');
    }

    // Property: locationCategoryID
    set locationCategoryID(value:string) {
        this.setValueForKey(value, 'locationCategoryID');
    }
    get locationCategoryID():string {
        return this.valueForKey('locationCategoryID');
    }
    set locationCategoryIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationCategoryID');
    }
    get locationCategoryIDPrimitiveValue():string {
        return this.primitiveValueForKey('locationCategoryID');
    }

    // Property: locationCategoryName
    set locationCategoryName(value:string) {
        this.setValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryName():string {
        return this.valueForKey('locationCategoryName');
    }
    set locationCategoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationCategoryName');
    }

    // Property: locationID
    set locationID(value:string) {
        this.setValueForKey(value, 'locationID');
    }
    get locationID():string {
        return this.valueForKey('locationID');
    }
    set locationIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationID');
    }
    get locationIDPrimitiveValue():string {
        return this.primitiveValueForKey('locationID');
    }

    // Property: locationName
    set locationName(value:string) {
        this.setValueForKey(value, 'locationName');
    }
    get locationName():string {
        return this.valueForKey('locationName');
    }
    set locationNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationName');
    }
    get locationNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationName');
    }

    // Property: lossQuantity
    set lossQuantity(value:number) {
        this.setValueForKey(value, 'lossQuantity');
    }
    get lossQuantity():number {
        return this.valueForKey('lossQuantity');
    }
    set lossQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lossQuantity');
    }
    get lossQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('lossQuantity');
    }

    // Property: minimumFee
    set minimumFee(value:number) {
        this.setValueForKey(value, 'minimumFee');
    }
    get minimumFee():number {
        return this.valueForKey('minimumFee');
    }
    set minimumFeePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minimumFee');
    }
    get minimumFeePrimitiveValue():number {
        return this.primitiveValueForKey('minimumFee');
    }

    // Property: modifierBaseFormula
    set modifierBaseFormula(value:string) {
        this.setValueForKey(value, 'modifierBaseFormula');
    }
    get modifierBaseFormula():string {
        return this.valueForKey('modifierBaseFormula');
    }
    set modifierBaseFormulaPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'modifierBaseFormula');
    }
    get modifierBaseFormulaPrimitiveValue():string {
        return this.primitiveValueForKey('modifierBaseFormula');
    }

    // Property: ModifierCategoryID
    set ModifierCategoryID(value:string) {
        this.setValueForKey(value, 'ModifierCategoryID');
    }
    get ModifierCategoryID():string {
        return this.valueForKey('ModifierCategoryID');
    }
    set ModifierCategoryIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ModifierCategoryID');
    }
    get ModifierCategoryIDPrimitiveValue():string {
        return this.primitiveValueForKey('ModifierCategoryID');
    }

    // Property: modifierCategoryName
    set modifierCategoryName(value:string) {
        this.setValueForKey(value, 'modifierCategoryName');
    }
    get modifierCategoryName():string {
        return this.valueForKey('modifierCategoryName');
    }
    set modifierCategoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'modifierCategoryName');
    }
    get modifierCategoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('modifierCategoryName');
    }

    // Property: modifierFormula
    set modifierFormula(value:string) {
        this.setValueForKey(value, 'modifierFormula');
    }
    get modifierFormula():string {
        return this.valueForKey('modifierFormula');
    }
    set modifierFormulaPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'modifierFormula');
    }
    get modifierFormulaPrimitiveValue():string {
        return this.primitiveValueForKey('modifierFormula');
    }

    // Property: modifierID
    set modifierID(value:string) {
        this.setValueForKey(value, 'modifierID');
    }
    get modifierID():string {
        return this.valueForKey('modifierID');
    }
    set modifierIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'modifierID');
    }
    get modifierIDPrimitiveValue():string {
        return this.primitiveValueForKey('modifierID');
    }

    // Property: modifierName
    set modifierName(value:string) {
        this.setValueForKey(value, 'modifierName');
    }
    get modifierName():string {
        return this.valueForKey('modifierName');
    }
    set modifierNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'modifierName');
    }
    get modifierNamePrimitiveValue():string {
        return this.primitiveValueForKey('modifierName');
    }

    // Property: modifierQuantity
    set modifierQuantity(value:number) {
        this.setValueForKey(value, 'modifierQuantity');
    }
    get modifierQuantity():number {
        return this.valueForKey('modifierQuantity');
    }
    set modifierQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'modifierQuantity');
    }
    get modifierQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('modifierQuantity');
    }

    // Property: nextDocument
    set nextDocument(value:string) {
        this.setValueForKey(value, 'nextDocument');
    }
    get nextDocument():string {
        return this.valueForKey('nextDocument');
    }
    set nextDocumentPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'nextDocument');
    }
    get nextDocumentPrimitiveValue():string {
        return this.primitiveValueForKey('nextDocument');
    }

    // Property: nullifiedBy
    set nullifiedBy(value:string) {
        this.setValueForKey(value, 'nullifiedBy');
    }
    get nullifiedBy():string {
        return this.valueForKey('nullifiedBy');
    }
    set nullifiedByPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'nullifiedBy');
    }
    get nullifiedByPrimitiveValue():string {
        return this.primitiveValueForKey('nullifiedBy');
    }

    // Property: nullify
    set nullify(value:string) {
        this.setValueForKey(value, 'nullify');
    }
    get nullify():string {
        return this.valueForKey('nullify');
    }
    set nullifyPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'nullify');
    }
    get nullifyPrimitiveValue():string {
        return this.primitiveValueForKey('nullify');
    }

    // Property: numberSeriesID
    set numberSeriesID(value:string) {
        this.setValueForKey(value, 'numberSeriesID');
    }
    get numberSeriesID():string {
        return this.valueForKey('numberSeriesID');
    }
    set numberSeriesIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'numberSeriesID');
    }
    get numberSeriesIDPrimitiveValue():string {
        return this.primitiveValueForKey('numberSeriesID');
    }

    // Property: openDate
    set openDate(value:Date) {
        this.setValueForKey(value, 'openDate');
    }
    get openDate():Date {
        return this.valueForKey('openDate');
    }
    set openDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'openDate');
    }
    get openDatePrimitiveValue():Date {
        return this.primitiveValueForKey('openDate');
    }

    // Property: orderID
    set orderID(value:string) {
        this.setValueForKey(value, 'orderID');
    }
    get orderID():string {
        return this.valueForKey('orderID');
    }
    set orderIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderID');
    }
    get orderIDPrimitiveValue():string {
        return this.primitiveValueForKey('orderID');
    }

    // Property: orderName
    set orderName(value:string) {
        this.setValueForKey(value, 'orderName');
    }
    get orderName():string {
        return this.valueForKey('orderName');
    }
    set orderNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderName');
    }
    get orderNamePrimitiveValue():string {
        return this.primitiveValueForKey('orderName');
    }

    // Property: orderType
    set orderType(value:number) {
        this.setValueForKey(value, 'orderType');
    }
    get orderType():number {
        return this.valueForKey('orderType');
    }
    set orderTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderType');
    }
    get orderTypePrimitiveValue():number {
        return this.primitiveValueForKey('orderType');
    }

    // Property: parentID
    set parentID(value:string) {
        this.setValueForKey(value, 'parentID');
    }
    get parentID():string {
        return this.valueForKey('parentID');
    }
    set parentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'parentID');
    }
    get parentIDPrimitiveValue():string {
        return this.primitiveValueForKey('parentID');
    }

    // Property: parentRefID
    set parentRefID(value:string) {
        this.setValueForKey(value, 'parentRefID');
    }
    get parentRefID():string {
        return this.valueForKey('parentRefID');
    }
    set parentRefIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'parentRefID');
    }
    get parentRefIDPrimitiveValue():string {
        return this.primitiveValueForKey('parentRefID');
    }

    // Property: pax
    set pax(value:number) {
        this.setValueForKey(value, 'pax');
    }
    get pax():number {
        return this.valueForKey('pax');
    }
    set paxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'pax');
    }
    get paxPrimitiveValue():number {
        return this.primitiveValueForKey('pax');
    }

    // Property: payMethodID
    set payMethodID(value:string) {
        this.setValueForKey(value, 'payMethodID');
    }
    get payMethodID():string {
        return this.valueForKey('payMethodID');
    }
    set payMethodIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'payMethodID');
    }
    get payMethodIDPrimitiveValue():string {
        return this.primitiveValueForKey('payMethodID');
    }

    // Property: payMethodName
    set payMethodName(value:string) {
        this.setValueForKey(value, 'payMethodName');
    }
    get payMethodName():string {
        return this.valueForKey('payMethodName');
    }
    set payMethodNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'payMethodName');
    }
    get payMethodNamePrimitiveValue():string {
        return this.primitiveValueForKey('payMethodName');
    }

    // Property: payMethodSubType
    set payMethodSubType(value:string) {
        this.setValueForKey(value, 'payMethodSubType');
    }
    get payMethodSubType():string {
        return this.valueForKey('payMethodSubType');
    }
    set payMethodSubTypePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'payMethodSubType');
    }
    get payMethodSubTypePrimitiveValue():string {
        return this.primitiveValueForKey('payMethodSubType');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: priceOtherCurrency
    set priceOtherCurrency(value:number) {
        this.setValueForKey(value, 'priceOtherCurrency');
    }
    get priceOtherCurrency():number {
        return this.valueForKey('priceOtherCurrency');
    }
    set priceOtherCurrencyPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'priceOtherCurrency');
    }
    get priceOtherCurrencyPrimitiveValue():number {
        return this.primitiveValueForKey('priceOtherCurrency');
    }

    // Property: productPrice
    set productPrice(value:number) {
        this.setValueForKey(value, 'productPrice');
    }
    get productPrice():number {
        return this.valueForKey('productPrice');
    }
    set productPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productPrice');
    }
    get productPricePrimitiveValue():number {
        return this.primitiveValueForKey('productPrice');
    }

    // Property: rateID
    set rateID(value:string) {
        this.setValueForKey(value, 'rateID');
    }
    get rateID():string {
        return this.valueForKey('rateID');
    }
    set rateIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'rateID');
    }
    get rateIDPrimitiveValue():string {
        return this.primitiveValueForKey('rateID');
    }

    // Property: rateName
    set rateName(value:string) {
        this.setValueForKey(value, 'rateName');
    }
    get rateName():string {
        return this.valueForKey('rateName');
    }
    set rateNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'rateName');
    }
    get rateNamePrimitiveValue():string {
        return this.primitiveValueForKey('rateName');
    }

    // Property: refID
    set refID(value:string) {
        this.setValueForKey(value, 'refID');
    }
    get refID():string {
        return this.valueForKey('refID');
    }
    set refIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'refID');
    }
    get refIDPrimitiveValue():string {
        return this.primitiveValueForKey('refID');
    }

    // Property: relationRefID
    set relationRefID(value:string) {
        this.setValueForKey(value, 'relationRefID');
    }
    get relationRefID():string {
        return this.valueForKey('relationRefID');
    }
    set relationRefIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'relationRefID');
    }
    get relationRefIDPrimitiveValue():string {
        return this.primitiveValueForKey('relationRefID');
    }

    // Property: salesCount
    set salesCount(value:number) {
        this.setValueForKey(value, 'salesCount');
    }
    get salesCount():number {
        return this.valueForKey('salesCount');
    }
    set salesCountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'salesCount');
    }
    get salesCountPrimitiveValue():number {
        return this.primitiveValueForKey('salesCount');
    }

    // Property: sendToKitchenSeconds
    set sendToKitchenSeconds(value:number) {
        this.setValueForKey(value, 'sendToKitchenSeconds');
    }
    get sendToKitchenSeconds():number {
        return this.valueForKey('sendToKitchenSeconds');
    }
    set sendToKitchenSecondsPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'sendToKitchenSeconds');
    }
    get sendToKitchenSecondsPrimitiveValue():number {
        return this.primitiveValueForKey('sendToKitchenSeconds');
    }

    // Property: sessionBeginDate
    set sessionBeginDate(value:Date) {
        this.setValueForKey(value, 'sessionBeginDate');
    }
    get sessionBeginDate():Date {
        return this.valueForKey('sessionBeginDate');
    }
    set sessionBeginDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'sessionBeginDate');
    }
    get sessionBeginDatePrimitiveValue():Date {
        return this.primitiveValueForKey('sessionBeginDate');
    }

    // Property: sessionEndDate
    set sessionEndDate(value:Date) {
        this.setValueForKey(value, 'sessionEndDate');
    }
    get sessionEndDate():Date {
        return this.valueForKey('sessionEndDate');
    }
    set sessionEndDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'sessionEndDate');
    }
    get sessionEndDatePrimitiveValue():Date {
        return this.primitiveValueForKey('sessionEndDate');
    }

    // Property: sessionID
    set sessionID(value:string) {
        this.setValueForKey(value, 'sessionID');
    }
    get sessionID():string {
        return this.valueForKey('sessionID');
    }
    set sessionIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sessionID');
    }
    get sessionIDPrimitiveValue():string {
        return this.primitiveValueForKey('sessionID');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: statusWorkerID
    set statusWorkerID(value:string) {
        this.setValueForKey(value, 'statusWorkerID');
    }
    get statusWorkerID():string {
        return this.valueForKey('statusWorkerID');
    }
    set statusWorkerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'statusWorkerID');
    }
    get statusWorkerIDPrimitiveValue():string {
        return this.primitiveValueForKey('statusWorkerID');
    }

    // Property: subaccountID
    set subaccountID(value:string) {
        this.setValueForKey(value, 'subaccountID');
    }
    get subaccountID():string {
        return this.valueForKey('subaccountID');
    }
    set subaccountIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'subaccountID');
    }
    get subaccountIDPrimitiveValue():string {
        return this.primitiveValueForKey('subaccountID');
    }

    // Property: subCashDeskID
    set subCashDeskID(value:string) {
        this.setValueForKey(value, 'subCashDeskID');
    }
    get subCashDeskID():string {
        return this.valueForKey('subCashDeskID');
    }
    set subCashDeskIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'subCashDeskID');
    }
    get subCashDeskIDPrimitiveValue():string {
        return this.primitiveValueForKey('subCashDeskID');
    }

    // Property: subType
    set subType(value:number) {
        this.setValueForKey(value, 'subType');
    }
    get subType():number {
        return this.valueForKey('subType');
    }
    set subTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'subType');
    }
    get subTypePrimitiveValue():number {
        return this.primitiveValueForKey('subType');
    }

    // Property: subTypeName
    set subTypeName(value:string) {
        this.setValueForKey(value, 'subTypeName');
    }
    get subTypeName():string {
        return this.valueForKey('subTypeName');
    }
    set subTypeNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'subTypeName');
    }
    get subTypeNamePrimitiveValue():string {
        return this.primitiveValueForKey('subTypeName');
    }

    // Property: supplierID
    set supplierID(value:string) {
        this.setValueForKey(value, 'supplierID');
    }
    get supplierID():string {
        return this.valueForKey('supplierID');
    }
    set supplierIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'supplierID');
    }
    get supplierIDPrimitiveValue():string {
        return this.primitiveValueForKey('supplierID');
    }

    // Property: supplierName
    set supplierName(value:string) {
        this.setValueForKey(value, 'supplierName');
    }
    get supplierName():string {
        return this.valueForKey('supplierName');
    }
    set supplierNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'supplierName');
    }
    get supplierNamePrimitiveValue():string {
        return this.primitiveValueForKey('supplierName');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: tax
    set tax(value:number) {
        this.setValueForKey(value, 'tax');
    }
    get tax():number {
        return this.valueForKey('tax');
    }
    set taxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'tax');
    }
    get taxPrimitiveValue():number {
        return this.primitiveValueForKey('tax');
    }

    // Property: taxID
    set taxID(value:string) {
        this.setValueForKey(value, 'taxID');
    }
    get taxID():string {
        return this.valueForKey('taxID');
    }
    set taxIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'taxID');
    }
    get taxIDPrimitiveValue():string {
        return this.primitiveValueForKey('taxID');
    }

    // Property: taxName
    set taxName(value:string) {
        this.setValueForKey(value, 'taxName');
    }
    get taxName():string {
        return this.valueForKey('taxName');
    }
    set taxNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'taxName');
    }
    get taxNamePrimitiveValue():string {
        return this.primitiveValueForKey('taxName');
    }

    // Property: taxQuantity
    set taxQuantity(value:number) {
        this.setValueForKey(value, 'taxQuantity');
    }
    get taxQuantity():number {
        return this.valueForKey('taxQuantity');
    }
    set taxQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'taxQuantity');
    }
    get taxQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('taxQuantity');
    }

    // Property: tip
    set tip(value:number) {
        this.setValueForKey(value, 'tip');
    }
    get tip():number {
        return this.valueForKey('tip');
    }
    set tipPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'tip');
    }
    get tipPrimitiveValue():number {
        return this.primitiveValueForKey('tip');
    }

    // Property: tipFormula
    set tipFormula(value:string) {
        this.setValueForKey(value, 'tipFormula');
    }
    get tipFormula():string {
        return this.valueForKey('tipFormula');
    }
    set tipFormulaPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tipFormula');
    }
    get tipFormulaPrimitiveValue():string {
        return this.primitiveValueForKey('tipFormula');
    }

    // Property: tipID
    set tipID(value:string) {
        this.setValueForKey(value, 'tipID');
    }
    get tipID():string {
        return this.valueForKey('tipID');
    }
    set tipIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tipID');
    }
    get tipIDPrimitiveValue():string {
        return this.primitiveValueForKey('tipID');
    }

    // Property: totalConsumptionQuantity
    set totalConsumptionQuantity(value:number) {
        this.setValueForKey(value, 'totalConsumptionQuantity');
    }
    get totalConsumptionQuantity():number {
        return this.valueForKey('totalConsumptionQuantity');
    }
    set totalConsumptionQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalConsumptionQuantity');
    }
    get totalConsumptionQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('totalConsumptionQuantity');
    }

    // Property: totalCost
    set totalCost(value:number) {
        this.setValueForKey(value, 'totalCost');
    }
    get totalCost():number {
        return this.valueForKey('totalCost');
    }
    set totalCostPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalCost');
    }
    get totalCostPrimitiveValue():number {
        return this.primitiveValueForKey('totalCost');
    }

    // Property: totalDeleted
    set totalDeleted(value:number) {
        this.setValueForKey(value, 'totalDeleted');
    }
    get totalDeleted():number {
        return this.valueForKey('totalDeleted');
    }
    set totalDeletedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalDeleted');
    }
    get totalDeletedPrimitiveValue():number {
        return this.primitiveValueForKey('totalDeleted');
    }

    // Property: totalDiscount
    set totalDiscount(value:number) {
        this.setValueForKey(value, 'totalDiscount');
    }
    get totalDiscount():number {
        return this.valueForKey('totalDiscount');
    }
    set totalDiscountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalDiscount');
    }
    get totalDiscountPrimitiveValue():number {
        return this.primitiveValueForKey('totalDiscount');
    }

    // Property: totalInvited
    set totalInvited(value:number) {
        this.setValueForKey(value, 'totalInvited');
    }
    get totalInvited():number {
        return this.valueForKey('totalInvited');
    }
    set totalInvitedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalInvited');
    }
    get totalInvitedPrimitiveValue():number {
        return this.primitiveValueForKey('totalInvited');
    }

    // Property: totalPrice
    set totalPrice(value:number) {
        this.setValueForKey(value, 'totalPrice');
    }
    get totalPrice():number {
        return this.valueForKey('totalPrice');
    }
    set totalPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalPrice');
    }
    get totalPricePrimitiveValue():number {
        return this.primitiveValueForKey('totalPrice');
    }

    // Property: totalPricebase
    set totalPricebase(value:number) {
        this.setValueForKey(value, 'totalPricebase');
    }
    get totalPricebase():number {
        return this.valueForKey('totalPricebase');
    }
    set totalPricebasePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalPricebase');
    }
    get totalPricebasePrimitiveValue():number {
        return this.primitiveValueForKey('totalPricebase');
    }

    // Property: totalTax
    set totalTax(value:number) {
        this.setValueForKey(value, 'totalTax');
    }
    get totalTax():number {
        return this.valueForKey('totalTax');
    }
    set totalTaxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalTax');
    }
    get totalTaxPrimitiveValue():number {
        return this.primitiveValueForKey('totalTax');
    }

    // Property: totalTip
    set totalTip(value:number) {
        this.setValueForKey(value, 'totalTip');
    }
    get totalTip():number {
        return this.valueForKey('totalTip');
    }
    set totalTipPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalTip');
    }
    get totalTipPrimitiveValue():number {
        return this.primitiveValueForKey('totalTip');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: typeName
    set typeName(value:string) {
        this.setValueForKey(value, 'typeName');
    }
    get typeName():string {
        return this.valueForKey('typeName');
    }
    set typeNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'typeName');
    }
    get typeNamePrimitiveValue():string {
        return this.primitiveValueForKey('typeName');
    }

    // Property: warehouseID
    set warehouseID(value:string) {
        this.setValueForKey(value, 'warehouseID');
    }
    get warehouseID():string {
        return this.valueForKey('warehouseID');
    }
    set warehouseIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'warehouseID');
    }
    get warehouseIDPrimitiveValue():string {
        return this.primitiveValueForKey('warehouseID');
    }

    // Property: warehouseName
    set warehouseName(value:string) {
        this.setValueForKey(value, 'warehouseName');
    }
    get warehouseName():string {
        return this.valueForKey('warehouseName');
    }
    set warehouseNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'warehouseName');
    }
    get warehouseNamePrimitiveValue():string {
        return this.primitiveValueForKey('warehouseName');
    }

    // Property: wasConsumed
    set wasConsumed(value:boolean) {
        this.setValueForKey(value, 'wasConsumed');
    }
    get wasConsumed():boolean {
        return this.valueForKey('wasConsumed');
    }
    set wasConsumedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'wasConsumed');
    }
    get wasConsumedPrimitiveValue():boolean {
        return this.primitiveValueForKey('wasConsumed');
    }

    // Property: workerID
    set workerID(value:string) {
        this.setValueForKey(value, 'workerID');
    }
    get workerID():string {
        return this.valueForKey('workerID');
    }
    set workerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerID');
    }
    get workerIDPrimitiveValue():string {
        return this.primitiveValueForKey('workerID');
    }

    // Property: workerName
    set workerName(value:string) {
        this.setValueForKey(value, 'workerName');
    }
    get workerName():string {
        return this.valueForKey('workerName');
    }
    set workerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerName');
    }
    get workerNamePrimitiveValue():string {
        return this.primitiveValueForKey('workerName');
    }
}
