
enum DLRemoteNotificationServerStatus {
    None,
    ConnectionEstablished
}

class DLRemoteNotificationServer extends MIOObject
{ 
    private static _sharedInstance:DLRemoteNotificationServer = null;    
    public static sharedInstance():DLRemoteNotificationServer {
        if (DLRemoteNotificationServer._sharedInstance != null) {
            return DLRemoteNotificationServer._sharedInstance;
        }

        let instance = new DLRemoteNotificationServer();
        instance.init();
        
        DLRemoteNotificationServer._sharedInstance = instance;
        return DLRemoteNotificationServer._sharedInstance;
    }
    
    private webSocketURLString = "wss://rms.dual-link.com";
    init(){        
        if (DLRemoteNotificationServer._sharedInstance != null){
            throw new Error("DLRemoteNotificationServer: Instantiation failed. Use sharedInstance() instead of new.");
        }
        super.init();        
    }

    private _status = null;
    get status() {return this._status;}

    private token:string = null;
    private companyID:string = null;
    private placeID:string = null;

    private webSocket:MNKWebSocket = null;    
    login(token:string, companyID:string, placeID:string){
        this.token = token;
        this.companyID = companyID;
        this.placeID = placeID;

        if (this.webSocket == null){
             //this.webSocket = MNKWebSocket.webSocketWithURLString(this.webSocketURLString, this);
        }        
    }

    private rooms = {};
    addObserver(observer, name:string, completion){
        let room = this.rooms[name];
        if (room == null) {
            room = {};
            room["isRoomJoined"] = false;
            room["Observers"] = MIOSet.set();            
            this.rooms[name] = room;
        }

        let obs = {};
        obs["Target"] = observer;
        obs["Completion"] = completion;

        let observers:MIOSet = room["Observers"];        
        observers.addObject(obs);

        this.joinRooms();
    }

    postNotification(name:string, userInfo){
        let msg = {            
            "messageType": "emit",
            "channelID": name,
            "deviceID": MIOCoreGetDeviceUUID(),
            "data": userInfo
        }
    
        let data = JSON.stringify(msg)
        this.webSocket.send(data);
    }

    sendRequest(name:string, target?, completion?){

    }

    webSocketDidOpenConnection(webSocket:MNKWebSocket){
        MIOLog("Connection OK");
        let msg = {
            "messageType": "openConnection",            
            "accessToken": this.token,
            "deviceID" : MIOCoreGetDeviceUUID(),
            "companyID": this.companyID,
            "placeID": this.placeID            
        }        

        let data = JSON.stringify(msg)
        this.webSocket.send(data);
    }

    //
    // WebSocket delgate
    //

    webSocketDidCloseConnection(webSocket:MNKWebSocket){
        MIOLog("Connection Close");
    }

    webSocketDidFailConnection(webSocket:MNKWebSocket){
        MIOLog("Connection FAIL");
    }

    webSocketDidReceiveMessage(webSocket:MNKWebSocket, data:string){
        let msg = data["messageType"];

        switch (msg){
            case "successResponse":
                this.checkMessageType(data["successMessage"], data["data"]);
                break;
                
            case "errorResponse":
                this.checkMessageType(data["errorMessage"], null);
                break;

            case "message":
                this.checkMessageData(data["channelID"], data["data"], data["dataType"]);
                break;
        }
    }

    private checkMessageType(message:string, data){
        if (message == "CONNECTION_ESTABLISHED") {
            this._status = DLRemoteNotificationServerStatus.ConnectionEstablished;
            this.joinRooms();
        }
        else if (message == "ROOM_JOIN_SUCCESS"){
            let roomID = data as string;
            let room = this.rooms[roomID];
            room["isRoomJoined"] = true;
        }
    }

    private joinRooms(){
        for (let roomID in this.rooms){
            let room = this.rooms[roomID];
            let isJoined = room["isRoomJoined"];
            if (isJoined == true) continue;
            this.joinRoom(roomID);
        }
    }

    private joinRoom(roomID:string){
        if (this.status != DLRemoteNotificationServerStatus.ConnectionEstablished) return;

        let msg = {
            "messageType": "join",            
            "roomID": roomID,
            "deviceID" : MIOCoreGetDeviceUUID(),
            "timestamp": new Date().getTime()
        }        

        let data = JSON.stringify(msg)
        this.webSocket.send(data);
   
    }

    private checkMessageData(roomID:string, data, dataType:string){
        let room = this.rooms[roomID];

        let observers = room["Observers"] as MIOSet;
        for (let index = 0; index < observers.count; index++){
            let obs = observers.objectAtIndex(index);
            let target = obs["Target"];
            let completion = obs["Completion"];
    
            completion.call(target, data);
        }

    }
}
/*

"CONNECTION_ESTABLISHED"
"LOGIN_FAILED"
"DATABASE_ERROR"
"LOGIN_SUCCESSFUL"
"ROOM_EMIT_FAIL_NOT_JOINED"
"ROOM_EMIT_FAIL_DATABASE"
"ROOM_EMIT_SUCCESS"
"ROOM_JOIN_FAILED_ALREADY_JOINED"
"ROOM_JOIN_FAILED_DATABASE"
"ROOM_JOIN_SUCCESS"
"ROOM_LEAVE_FAILED_NOT_JOINED"
"ROOM_LEAVE_FAILED_DATABASE"
"ROOM_LEAVE_SUCCESS"
"UNKNOWN_OR_UNAUTHORIZED_QUERY"

*/


