

// Generated class ProductExistence_ManagedObject

class ProductExistence_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: currentstock
    set currentstock(value:number) {
        this.setValueForKey(value, 'currentstock');
    }
    get currentstock():number {
        return this.valueForKey('currentstock');
    }
    set currentstockPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'currentstock');
    }
    get currentstockPrimitiveValue():number {
        return this.primitiveValueForKey('currentstock');
    }
}
