//
// Generated class CheckPoint
//

/// <reference path="CheckPoint_ManagedObject.ts" />

class CheckPoint extends CheckPoint_ManagedObject
{

}
