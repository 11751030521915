
class CashDeskSessionLinePayMethodCell extends MUIReportTableViewCell
{
    private nameLabel:MUILabel = null;
    private iconView:MUIImageView = null;
    
    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "title-lbl", "MUILabel");
        this.iconView = MUIOutlet(this, "icon-view", "MUIImageView");
    }

    private _payMethod:PayMethod = null;
    set item(value:PayMethod){
        this._payMethod = value;        
        this.iconView.setImage(this._payMethod.image?.url);
    }


    private _style:string = null;
    set iconStyle(style:string){
        if (this._style != null) MUICoreLayerRemoveStyle(this.iconView.layer, this._style);
        MUICoreLayerAddStyle(this.iconView.layer, style);
        this._style = style;
    }
}