class WorkerSessionToolViewController extends MUIViewController
{
	private beginDateButton:MUIButton = null;
	private endDateButton:MUIButton = null;
	private timeIntervalButton:MUIButton = null;
	private filterButton:MUIButton = null;
	private adjustButton:MUIButton = null;

	viewDidLoad() {
		super.viewDidLoad()
		
		this.beginDateButton = MUIOutlet(this, 'btn-begin-date', 'MUIButton');
		this.endDateButton = MUIOutlet(this, 'btn-end-date', 'MUIButton');
		this.timeIntervalButton = MUIOutlet(this, 'dd-time-interval', 'MUIButton');
		this.filterButton = MUIOutlet(this, 'btn-filter', 'MUIButton');
		this.adjustButton = MUIOutlet(this, 'btn-adjust', 'MUIButton');

		this.beginDateButton.setAction(this, function() {
			this.this.showDatePickerController();
		});

		this.endDateButton.setAction(this, function() {
			this.this.showDatePickerController();
		});
	}	
}