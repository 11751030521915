
 class TagsConfigurationViewController extends MUIViewController
 {
    private addButton:MUIButton = null;
    private tableView:UITableView = null; 
    private tagView:TagView = null;

    private selectedCategory:TagType = null;                   
 
    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, this.addAction);

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.tagView = MUIOutlet(this, 'tag-view', 'TagView');
        this.tagView.delegate = this; 
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;
        this.tableView.reloadData();             
    }
     
    numberOfSections(tableview){
        return 1;
    }
    
    numberOfRowsInSection(tableview:UITableView, section:number){
        return Tag.tagTypesCount() - 1;
    }
    
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('TagsConfigurationCell') as TagsConfigurationCell;
        cell.name = Tag.tagStringByType(indexPath.row + 1);
        return cell;
    }
        
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        this.selectedCategory = indexPath.row + 1;
        this.updateTagView(this.selectedCategory);
    }

    private updateTagView(type:TagType){
        let predicate = MIOPredicate.predicateWithFormat("type = " + type + " and deletedAt = null");
        DBHelper.queryObjectsWithCompletion("Tag", null, predicate, [], this, function(this:TagsConfigurationViewController, objects){
            this.reloadTags(objects);
        });           
    }

    private _tags:Tag[] = [];
    private reloadTags(tags){
        this._tags = _MIOSortDescriptorSortObjects(tags, [MIOSortDescriptor.sortDescriptorWithKey("name", true)]);
        this.tagView.removeAll();        
        for (let index = 0; index < this._tags.length; index++){
            let tag = this._tags[index] as Tag;
            this.tagView.addTag(tag.name);
        }
    }

    didSelectTagAtIndex(index:number, name:string){ 
        let tag = this._tags[index];
        DBHelper.deleteObjectFromMainContext(tag as Tag, true);
        this._tags.removeObjectAtIndex(index);
        this.reloadTags(this._tags);
    }

    private addAction(){
        if (this.selectedCategory == null){
                
            let alert = new MUIAlertViewController();
            alert.initWithTitle(MIOLocalizeString('ALERT', 'ALERT'), MIOLocalizeString('PLEASE SELECT CATEGORY FIRST','PLEASE SELECT CATEGORY FIRST'), MUIAlertViewStyle.Default);
            alert.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, this, function(){}));
            this.presentViewController(alert, true);

        } else {
            this.addTagToCategory(this.selectedCategory);
        }
    }

    private addTagToCategory(category:TagType) {

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('NEW TAG','NEW TAG'), MIOLocalizeString('CHOOSE A NAME','CHOOSE A NAME'), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD','ADD'), MUIAlertActionStyle.Default, this, function(this:TagsConfigurationViewController){
            let nameTF =  avc.textFields[0];
            let name = nameTF.text.trim();

            if(name.length > 0){
                this.createNewTag(name);
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'Error'), MIOLocalizeString('CHECK NAME','CHECK NAME'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','DONE'), MUIAlertActionStyle.Cancel, this, function(){}));
                this.presentViewController(error, true);
            }
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Cancel, this, function(){}));
        
        this.presentViewController(avc, true);
    }
 
    private createNewTag(name:string){
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        let newTag = MIOEntityDescription.insertNewObjectForEntityForName("Tag", moc) as Tag;
        newTag.identifier = MIOUUID.UUID().UUIDString;
        newTag.name = name;
        newTag.type = this.selectedCategory;
        DBHelper.saveMainContext();
        
        this._tags.addObject(newTag);
        this.tagView.addTag(newTag.name);
    }

   
}
