
class UserAccessControlCell extends UITableViewCell
{
    private titleLabel:MUILabel = null;  

    awakeFromHTML() {
        this.titleLabel = MUIOutlet(this, 'title-lbl', 'MUILabel');
        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    set title(title:string) {
         this.titleLabel.text = title;
    }
}