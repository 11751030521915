

/// <reference path="RenderTemplate.ts" />

// Generated class DocumentTemplate_ManagedObject

class DocumentTemplate_ManagedObject extends RenderTemplate
{

    // Property: paymentOptionsText
    set paymentOptionsText(value:string) {
        this.setValueForKey(value, 'paymentOptionsText');
    }
    get paymentOptionsText():string {
        return this.valueForKey('paymentOptionsText');
    }
    set paymentOptionsTextPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'paymentOptionsText');
    }
    get paymentOptionsTextPrimitiveValue():string {
        return this.primitiveValueForKey('paymentOptionsText');
    }

    // Property: showPaymentOptionsText
    set showPaymentOptionsText(value:boolean) {
        this.setValueForKey(value, 'showPaymentOptionsText');
    }
    get showPaymentOptionsText():boolean {
        return this.valueForKey('showPaymentOptionsText');
    }
    set showPaymentOptionsTextPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'showPaymentOptionsText');
    }
    get showPaymentOptionsTextPrimitiveValue():boolean {
        return this.primitiveValueForKey('showPaymentOptionsText');
    }
}
