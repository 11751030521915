

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class SupportTicket_ManagedObject

class SupportTicket_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: assignedName
    set assignedName(value:string) {
        this.setValueForKey(value, 'assignedName');
    }
    get assignedName():string {
        return this.valueForKey('assignedName');
    }
    set assignedNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'assignedName');
    }
    get assignedNamePrimitiveValue():string {
        return this.primitiveValueForKey('assignedName');
    }

    // Property: businessName
    set businessName(value:string) {
        this.setValueForKey(value, 'businessName');
    }
    get businessName():string {
        return this.valueForKey('businessName');
    }
    set businessNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'businessName');
    }
    get businessNamePrimitiveValue():string {
        return this.primitiveValueForKey('businessName');
    }

    // Property: clientName
    set clientName(value:string) {
        this.setValueForKey(value, 'clientName');
    }
    get clientName():string {
        return this.valueForKey('clientName');
    }
    set clientNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientName');
    }
    get clientNamePrimitiveValue():string {
        return this.primitiveValueForKey('clientName');
    }

    // Property: message
    set message(value:string) {
        this.setValueForKey(value, 'message');
    }
    get message():string {
        return this.valueForKey('message');
    }
    set messagePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'message');
    }
    get messagePrimitiveValue():string {
        return this.primitiveValueForKey('message');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: subject
    set subject(value:string) {
        this.setValueForKey(value, 'subject');
    }
    get subject():string {
        return this.valueForKey('subject');
    }
    set subjectPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'subject');
    }
    get subjectPrimitiveValue():string {
        return this.primitiveValueForKey('subject');
    }
    // Relationship: agent
    set agent(value:Contact) {
        this.setValueForKey(value, 'agent');
    }
    get agent():Contact {
        return this.valueForKey('agent') as Contact;
    }
    // Relationship: assigned
    set assigned(value:Employee) {
        this.setValueForKey(value, 'assigned');
    }
    get assigned():Employee {
        return this.valueForKey('assigned') as Employee;
    }
    // Relationship: business
    set business(value:BusinessLicense) {
        this.setValueForKey(value, 'business');
    }
    get business():BusinessLicense {
        return this.valueForKey('business') as BusinessLicense;
    }
    // Relationship: client
    set client(value:Client) {
        this.setValueForKey(value, 'client');
    }
    get client():Client {
        return this.valueForKey('client') as Client;
    }
    // Relationship: distributor
    set distributor(value:Intermediary) {
        this.setValueForKey(value, 'distributor');
    }
    get distributor():Intermediary {
        return this.valueForKey('distributor') as Intermediary;
    }

    // Relationship: logs
    protected _logs:MIOManagedObjectSet = null;
    get logs():MIOManagedObjectSet {
        return this.valueForKey('logs');
    }
    addLogsObject(value:SupportTicketLog) {
        this._addObjectForKey(value, 'logs');
    }
    removeLogsObject(value:SupportTicketLog) {
        this._removeObjectForKey(value, 'logs');
    }

    // Relationship: sessions
    protected _sessions:MIOManagedObjectSet = null;
    get sessions():MIOManagedObjectSet {
        return this.valueForKey('sessions');
    }
    addSessionsObject(value:SupportSession) {
        this._addObjectForKey(value, 'sessions');
    }
    removeSessionsObject(value:SupportSession) {
        this._removeObjectForKey(value, 'sessions');
    }

    // Relationship: tasks
    protected _tasks:MIOManagedObjectSet = null;
    get tasks():MIOManagedObjectSet {
        return this.valueForKey('tasks');
    }
    addTasksObject(value:JobTask) {
        this._addObjectForKey(value, 'tasks');
    }
    removeTasksObject(value:JobTask) {
        this._removeObjectForKey(value, 'tasks');
    }
}
