
class LedgerAccountViewController extends MUIViewController
{

    static newInstance() : LedgerAccountViewController {
        let vc = new LedgerAccountViewController('ledger-account-view');
        vc.initWithResource('layout/ledgeraccount/LedgerAccountView.html');

        return vc;
    }

    private addButton:MUIButton = null;
    private exportButton:MUIButton = null;
    private saveButton:MUIButton = null;
    private tableView:UITableView = null;                    

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function(){
           AppHelper.sharedInstance().showAddLedgerAccountAlert(this);
        });

        this.exportButton = MUIOutlet(this, "export-btn", "MUIButton");
        this.exportButton.setAction(this, function(){ 
            this.exportAction();
        });

        this.saveButton = MUIOutlet(this, 'save-btn', 'MUIButton');
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        });

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);        
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }
    
    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('LedgerAccountCell') as LedgerAccountCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as LedgerAccount;
        cell.item = item;
        cell.selectionStyle = UITableViewCellSelectionStyle.None;
            
        return cell;
    }
        
    // didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
    // }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
        return UITableViewCellEditingStyle.Delete;
    }
    
    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Tax;    

        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            DBHelper.deleteObjectFromMainContext(item, true);
        }
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('prefix', true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("LedgerAccount", sortDescriptors, null);        
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    private exportAction() {
        let coder = new DBLedgerAccountsCoder();
        coder.export();
    }
}
