

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class SupportChatMessage_ManagedObject

class SupportChatMessage_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: fromName
    set fromName(value:string) {
        this.setValueForKey(value, 'fromName');
    }
    get fromName():string {
        return this.valueForKey('fromName');
    }
    set fromNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'fromName');
    }
    get fromNamePrimitiveValue():string {
        return this.primitiveValueForKey('fromName');
    }

    // Property: message
    set message(value:string) {
        this.setValueForKey(value, 'message');
    }
    get message():string {
        return this.valueForKey('message');
    }
    set messagePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'message');
    }
    get messagePrimitiveValue():string {
        return this.primitiveValueForKey('message');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }
    // Relationship: session
    set session(value:SupportSession) {
        this.setValueForKey(value, 'session');
    }
    get session():SupportSession {
        return this.valueForKey('session') as SupportSession;
    }
}
