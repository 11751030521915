

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class PayMethod_ManagedObject

class PayMethod_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: blockCurrencyChange
    set blockCurrencyChange(value:boolean) {
        this.setValueForKey(value, 'blockCurrencyChange');
    }
    get blockCurrencyChange():boolean {
        return this.valueForKey('blockCurrencyChange');
    }
    set blockCurrencyChangePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'blockCurrencyChange');
    }
    get blockCurrencyChangePrimitiveValue():boolean {
        return this.primitiveValueForKey('blockCurrencyChange');
    }

    // Property: eliminable
    set eliminable(value:boolean) {
        this.setValueForKey(value, 'eliminable');
    }
    get eliminable():boolean {
        return this.valueForKey('eliminable');
    }
    set eliminablePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'eliminable');
    }
    get eliminablePrimitiveValue():boolean {
        return this.primitiveValueForKey('eliminable');
    }

    // Property: idIn
    set idIn(value:number) {
        this.setValueForKey(value, 'idIn');
    }
    get idIn():number {
        return this.valueForKey('idIn');
    }
    set idInPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'idIn');
    }
    get idInPrimitiveValue():number {
        return this.primitiveValueForKey('idIn');
    }

    // Property: imageID
    set imageID(value:string) {
        this.setValueForKey(value, 'imageID');
    }
    get imageID():string {
        return this.valueForKey('imageID');
    }
    set imageIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'imageID');
    }
    get imageIDPrimitiveValue():string {
        return this.primitiveValueForKey('imageID');
    }

    // Property: includeInCashDeskTotals
    set includeInCashDeskTotals(value:boolean) {
        this.setValueForKey(value, 'includeInCashDeskTotals');
    }
    get includeInCashDeskTotals():boolean {
        return this.valueForKey('includeInCashDeskTotals');
    }
    set includeInCashDeskTotalsPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'includeInCashDeskTotals');
    }
    get includeInCashDeskTotalsPrimitiveValue():boolean {
        return this.primitiveValueForKey('includeInCashDeskTotals');
    }

    // Property: includeInfoInCashDeskReport
    set includeInfoInCashDeskReport(value:boolean) {
        this.setValueForKey(value, 'includeInfoInCashDeskReport');
    }
    get includeInfoInCashDeskReport():boolean {
        return this.valueForKey('includeInfoInCashDeskReport');
    }
    set includeInfoInCashDeskReportPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'includeInfoInCashDeskReport');
    }
    get includeInfoInCashDeskReportPrimitiveValue():boolean {
        return this.primitiveValueForKey('includeInfoInCashDeskReport');
    }

    // Property: invoiceSerieID
    set invoiceSerieID(value:string) {
        this.setValueForKey(value, 'invoiceSerieID');
    }
    get invoiceSerieID():string {
        return this.valueForKey('invoiceSerieID');
    }
    set invoiceSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invoiceSerieID');
    }
    get invoiceSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('invoiceSerieID');
    }

    // Property: isDisabled
    set isDisabled(value:boolean) {
        this.setValueForKey(value, 'isDisabled');
    }
    get isDisabled():boolean {
        return this.valueForKey('isDisabled');
    }
    set isDisabledPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isDisabled');
    }
    get isDisabledPrimitiveValue():boolean {
        return this.primitiveValueForKey('isDisabled');
    }

    // Property: limitDayMoneyPerClientForeigner
    set limitDayMoneyPerClientForeigner(value:number) {
        this.setValueForKey(value, 'limitDayMoneyPerClientForeigner');
    }
    get limitDayMoneyPerClientForeigner():number {
        return this.valueForKey('limitDayMoneyPerClientForeigner');
    }
    set limitDayMoneyPerClientForeignerPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'limitDayMoneyPerClientForeigner');
    }
    get limitDayMoneyPerClientForeignerPrimitiveValue():number {
        return this.primitiveValueForKey('limitDayMoneyPerClientForeigner');
    }

    // Property: limitDayMoneyPerClientNational
    set limitDayMoneyPerClientNational(value:number) {
        this.setValueForKey(value, 'limitDayMoneyPerClientNational');
    }
    get limitDayMoneyPerClientNational():number {
        return this.valueForKey('limitDayMoneyPerClientNational');
    }
    set limitDayMoneyPerClientNationalPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'limitDayMoneyPerClientNational');
    }
    get limitDayMoneyPerClientNationalPrimitiveValue():number {
        return this.primitiveValueForKey('limitDayMoneyPerClientNational');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: needsChange
    set needsChange(value:boolean) {
        this.setValueForKey(value, 'needsChange');
    }
    get needsChange():boolean {
        return this.valueForKey('needsChange');
    }
    set needsChangePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'needsChange');
    }
    get needsChangePrimitiveValue():boolean {
        return this.primitiveValueForKey('needsChange');
    }

    // Property: nullifyInvoiceSerieID
    set nullifyInvoiceSerieID(value:string) {
        this.setValueForKey(value, 'nullifyInvoiceSerieID');
    }
    get nullifyInvoiceSerieID():string {
        return this.valueForKey('nullifyInvoiceSerieID');
    }
    set nullifyInvoiceSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'nullifyInvoiceSerieID');
    }
    get nullifyInvoiceSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('nullifyInvoiceSerieID');
    }

    // Property: nullifyTicketSerieID
    set nullifyTicketSerieID(value:string) {
        this.setValueForKey(value, 'nullifyTicketSerieID');
    }
    get nullifyTicketSerieID():string {
        return this.valueForKey('nullifyTicketSerieID');
    }
    set nullifyTicketSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'nullifyTicketSerieID');
    }
    get nullifyTicketSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('nullifyTicketSerieID');
    }

    // Property: openCashDrawer
    set openCashDrawer(value:boolean) {
        this.setValueForKey(value, 'openCashDrawer');
    }
    get openCashDrawer():boolean {
        return this.valueForKey('openCashDrawer');
    }
    set openCashDrawerPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'openCashDrawer');
    }
    get openCashDrawerPrimitiveValue():boolean {
        return this.primitiveValueForKey('openCashDrawer');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: printCopies
    set printCopies(value:number) {
        this.setValueForKey(value, 'printCopies');
    }
    get printCopies():number {
        return this.valueForKey('printCopies');
    }
    set printCopiesPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'printCopies');
    }
    get printCopiesPrimitiveValue():number {
        return this.primitiveValueForKey('printCopies');
    }

    // Property: printTicket
    set printTicket(value:boolean) {
        this.setValueForKey(value, 'printTicket');
    }
    get printTicket():boolean {
        return this.valueForKey('printTicket');
    }
    set printTicketPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'printTicket');
    }
    get printTicketPrimitiveValue():boolean {
        return this.primitiveValueForKey('printTicket');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: ticketSerieID
    set ticketSerieID(value:string) {
        this.setValueForKey(value, 'ticketSerieID');
    }
    get ticketSerieID():string {
        return this.valueForKey('ticketSerieID');
    }
    set ticketSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ticketSerieID');
    }
    get ticketSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('ticketSerieID');
    }

    // Property: transactionConcept
    set transactionConcept(value:string) {
        this.setValueForKey(value, 'transactionConcept');
    }
    get transactionConcept():string {
        return this.valueForKey('transactionConcept');
    }
    set transactionConceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'transactionConcept');
    }
    get transactionConceptPrimitiveValue():string {
        return this.primitiveValueForKey('transactionConcept');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: useDefaultCurrency
    set useDefaultCurrency(value:boolean) {
        this.setValueForKey(value, 'useDefaultCurrency');
    }
    get useDefaultCurrency():boolean {
        return this.valueForKey('useDefaultCurrency');
    }
    set useDefaultCurrencyPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'useDefaultCurrency');
    }
    get useDefaultCurrencyPrimitiveValue():boolean {
        return this.primitiveValueForKey('useDefaultCurrency');
    }

    // Property: useDifferentSeries
    set useDifferentSeries(value:boolean) {
        this.setValueForKey(value, 'useDifferentSeries');
    }
    get useDifferentSeries():boolean {
        return this.valueForKey('useDifferentSeries');
    }
    set useDifferentSeriesPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'useDifferentSeries');
    }
    get useDifferentSeriesPrimitiveValue():boolean {
        return this.primitiveValueForKey('useDifferentSeries');
    }

    // Relationship: configs
    protected _configs:MIOManagedObjectSet = null;
    get configs():MIOManagedObjectSet {
        return this.valueForKey('configs');
    }
    addConfigsObject(value:PayMethodConfig) {
        this._addObjectForKey(value, 'configs');
    }
    removeConfigsObject(value:PayMethodConfig) {
        this._removeObjectForKey(value, 'configs');
    }
    // Relationship: defaultCurrency
    set defaultCurrency(value:Currency) {
        this.setValueForKey(value, 'defaultCurrency');
    }
    get defaultCurrency():Currency {
        return this.valueForKey('defaultCurrency') as Currency;
    }
    // Relationship: image
    set image(value:DBImage) {
        this.setValueForKey(value, 'image');
    }
    get image():DBImage {
        return this.valueForKey('image') as DBImage;
    }
    // Relationship: nullifyByPayMethod
    set nullifyByPayMethod(value:PayMethod) {
        this.setValueForKey(value, 'nullifyByPayMethod');
    }
    get nullifyByPayMethod():PayMethod {
        return this.valueForKey('nullifyByPayMethod') as PayMethod;
    }
    // Relationship: nullifyPayMethod
    set nullifyPayMethod(value:PayMethod) {
        this.setValueForKey(value, 'nullifyPayMethod');
    }
    get nullifyPayMethod():PayMethod {
        return this.valueForKey('nullifyPayMethod') as PayMethod;
    }
}
