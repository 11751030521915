
class BookingLogCell extends MUITableViewCell
{
    private dateLabel:MUILabel = null;
    private actionLabel:MUILabel = null;
    private userNameLabel:MUILabel = null;
    
    awakeFromHTML(){
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");
        this.actionLabel = MUIOutlet(this, "action-lbl", "MUILabel");
        this.userNameLabel = MUIOutlet(this, "user-lbl", "MUILabel");
    }

    private df:MIODateFormatter = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter;

    set item(value:BookingLog){
        this.dateLabel.text = this.df.stringFromDate(value.date);
        this.actionLabel.text = value.action;        
        if (value.workerName != null) 
            this.userNameLabel.text = value.workerName;
        else if (value.userName != null)
            this.userNameLabel.text = value.userName;
        else if (value.userEmail != null)
            this.userNameLabel.text = value.userEmail;
        else if (value.workerEmail != null)
            this.userNameLabel.text = value.workerEmail;
    }
}