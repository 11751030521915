
/// <reference path="DashboardCellView.ts" />

class DashboardSalesComparationCellView extends DashboardCellView 
{    
    static comparationCellView(): DashboardSalesComparationCellView {
        
        var dv = new DashboardSalesComparationCellView();
        dv.init();

        return dv;
    }
            
    init(){
        super.init();

        this.title = "Sales comparation";
    //    this.report = ReportHelper.sharedInstance().salesComparationReportQuery();
        this.labelKey = "title";
        this.valueKey = "value";
    }

}