

class SegmentedViewController extends MUIViewController
{
    private navBar:MUINavigationBar = null;
    private segmentedControl:MUISegmentedControl = null;
    private pageController:MUIPageController = null;
            
    viewDidLoad(){
        super.viewDidLoad();

        this.segmentedControl = new MUISegmentedControl();
        this.segmentedControl.init();

        this.navBar = new MUINavigationBar();
        this.navBar.init();
        this.view.addSubview(this.navBar);

        let navItem = new MUINavigationItem();
        navItem.init();
        navItem.titleView = this.segmentedControl;
        this.navBar.setItems([navItem], true);
        
        this.pageController = new MUIPageController();
        this.pageController.init();
        this.addChildViewController(this.pageController);
    }

    addViewControllerWithTitle(title:string, vc:MUIViewController){
        //this.segmentedControl
        this.pageController.addPageViewController(vc);
    }
}