
class PartyPriceRulesDataSource extends MIOObject
{
    private delegate = null;
    private tableView:MUITableView = null;

    initWithTableView(tableView:MUITableView, delegate:any){
        this.tableView = tableView;
        this.delegate = delegate;
    }

    private zone:BookingZone = null;
    private eventID:string = null;
    private partyID:string = null;
    setItems(zone:BookingZone, eventID:string, partyID:string){
        this.zone = zone;
        this.eventID = eventID;
        this.partyID = partyID;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as BookingPriceRule;

        let cell = tableview.dequeueReusableCellWithIdentifier('PriceRuleCell') as PriceRuleCell;            
        cell.item = item;        
            
        return cell;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        let obj = this.fetchedResultsController.objectAtIndexPath(indexPath);
        //this.zone = obj;
    }
    
    // editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
    //     return MUITableViewCellEditingStyle.Delete;
    // }

    // commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {
    //     let zone = this.fetchedResultsController.objectAtIndexPath(indexPath);    

    //     if (editingStyle == MUITableViewCellEditingStyle.Delete) {
    //         this.removeZone(zone);
    //     }
    // }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        //let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true)];
        let predicateFormat = "zone.identifier == " + this.zone.identifier;
        if (this.eventID != null) predicateFormat += " AND eventID == " + this.eventID;
        if (this.partyID != null) predicateFormat += " AND partyID == " + this.partyID;
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("BookingPriceRule", null, predicateFormat);
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }  
}