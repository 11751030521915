

/// <reference path="EconomicEntity.ts" />

// Generated class Tab_ManagedObject

class Tab_ManagedObject extends EconomicEntity
{

    // Property: barcode
    set barcode(value:string) {
        this.setValueForKey(value, 'barcode');
    }
    get barcode():string {
        return this.valueForKey('barcode');
    }
    set barcodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'barcode');
    }
    get barcodePrimitiveValue():string {
        return this.primitiveValueForKey('barcode');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }
}
