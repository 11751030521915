/// <reference path="../AppHelper.ts" />

class EditLicenseViewController extends MUIViewController
{
    isNewLicense = false;
    //variables for new html
    private closeButton:MUIButton = null;
    private _placeID:string= null;

    //private _license:License = new License();
    //private _licenseID:string = null;
    private _fetchedResultsController:MIOFetchedResultsController = null;

    private saveButton:MUIButton = null;
    private saveNewButton:MUIButton = null;
    //private renewButton:MUIButton = null;
    private deleteButton:MUIButton = null;
    private toggleActiveButton:MUIButton = null;
    private statusButton:MUILabel= null;
    //private cancelButton:MUIButton = null;

    //private isActiveSwitchButton:MUISwitchButton = null;
    //private isCanceledSwitchButton:MUISwitchButton = null;

    private appTypeComboBox:MUIComboBox = null;
    private licenseTypeComboBox:MUIComboBox = null;
    private deviceComboBox:MUIComboBox = null;
    //private appComboBox:MUIComboBox = null;
    private infoTextArea:MUITextArea = null;
    private providerNameText:MUITextField = null;

    private navigationTitleLabel:MUILabel = null;


    private beginDateTextField:MUITextField = null;
    private endDateTextField:MUITextField = null;
    private beginDateButton:MUIButton = null;
    private endDateButton:MUIButton = null;


    private ad: AppDelegate = MUIWebApplication.sharedInstance().delegate;
    private moc: MIOManagedObjectContext = this.ad.managedObjectContext;
    private df = this.ad.dateFormatter;


    get preferredContentSize()
    {
        return new MIOSize(500, 570);
    }

    viewDidLoad()
    {
        super.viewDidLoad();

        this.navigationTitleLabel = MUIOutlet(this,"navTitle","MUILabel");

            
        this.closeButton = MUIOutlet(this, "close-button", "MUIButton");
        this.closeButton.setAction(this,function(){
            this.dismissViewController(true);
        });

        this.saveButton = MUIOutlet(this, "save-button", "MUIButton");
        this.saveButton.setAction(this,function(){
            this.saveLicense();
            this.dismissViewController(true);
        });

        //saves the license and keeps edit license window open
        this.saveNewButton = MUIOutlet(this, "save-new-button", "MUIButton");
        this.saveNewButton.setAction(this,function(){
            this.saveLicense();
            this.resetLicense();
        });


        this.beginDateTextField = MUIOutlet(this, "beginDateTextField", "MUITextField");
        this.beginDateTextField.formatter = this.ad.dateFormatter;

        this.beginDateButton = MUIOutlet(this, 'beginCalendarBtn','MUIButton');
        this.beginDateButton.setAction(this, function()
        {
            let dp = new MUIDatePickerController();
            dp.init();        
            dp.delegate = this;
            dp.tag = 1;
            dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

            let pc = dp.popoverPresentationController;
            pc.sourceRect = this.beginDateButton.frame;
            pc.sourceView = this.beginDateButton;

            this.presentViewController(dp, true);
        });


        this.endDateTextField = MUIOutlet(this, "endDateTextField", "MUITextField");
        this.endDateTextField.formatter = this.ad.dateFormatter;

        this.endDateButton = MUIOutlet(this, 'endCalendarBtn','MUIButton');
        this.endDateButton.setAction(this, function()
        {
            let dp = new MUIDatePickerController();
            dp.init();        
            dp.delegate = this;
            dp.tag = 2;
            dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

            let pc = dp.popoverPresentationController;
            pc.sourceRect = this.endDateButton.frame;
            pc.sourceView = this.endDateButton;

            this.presentViewController(dp, true);
        });


        this.statusButton = MUIOutlet(this, 'status-button','MUILabel');
        
        this.licenseTypeComboBox = MUIOutlet(this, "licenseType", "MUIComboBox");        
        for (let key of Object.keys(License.getTypes)){
            this.licenseTypeComboBox.addItem(License.getTypes[key], key);
        }

        this.appTypeComboBox = MUIOutlet(this, "appType", "MUIComboBox");
        for (let key of Object.keys(License.getAppTypes)){
            this.appTypeComboBox.addItem(License.getAppTypes[key], key);
        }
        
        this.deviceComboBox = MUIOutlet(this, "device", "MUIComboBox");
        let predicate = MIOPredicate.predicateWithFormat('placeID = "' + this._placeID + '"');
        let dh = DBHelper.sharedInstance();
        dh.addObserverForEntity(this, "Application", predicate, null, null, function (objects) {
            AppHelper.fillApplicationComboBox(this.deviceComboBox, objects, this.isNewLicense ? null : this._license.app);
        });        


        this.infoTextArea = MUIOutlet(this, "infoTextarea", "MUITextArea");
        this.providerNameText = MUIOutlet(this, "provicerName", "MUITextField");


        this.deleteButton = MUIOutlet(this, "delete-button", "MUIButton");
        this.deleteButton.setAction(this,function(){
            this.removeLicense();
            this.dismissViewController(true);
        });

        this.toggleActiveButton = MUIOutlet(this, "toggle-active-button", "MUIButton");
        this.toggleActiveButton.setAction(this,function(){
            
            this.toggleActivateLicense();
        });

        /*
        this.isActiveSwitchButton = MUIOutlet(this, "isActive", "MUISwitchButton");
        this.isCanceledSwitchButton = MUIOutlet(this, "isCanceled", "MUISwitchButton");
        */

    }

    viewWillAppear(animate){
        super.viewWillAppear(animate);

         //check to see if we are passed a license (by selecting from the list of licenses) or if we are creating a new one
         if(this.isNewLicense) {
             this.navigationTitleLabel.text = "New license";
             this.deleteButton.layer.classList.add('hidden');
         }
         else {
             this.navigationTitleLabel.text = "Edit license";
             this.saveNewButton.layer.classList.add('hidden');
         }

         //check the status of the license and change the status lable accordingly
         if (this._license.isActive) {
            this.statusButton.layer.classList.add('active');
            this.statusButton.text = "Active";
        } else {
            this.statusButton.layer.classList.add('alert');
            this.statusButton.text = "Inactive";
        }
    }

    viewDidAppear(animate)
    {
        super.viewDidAppear(animate);
        //let request = new MIOFetchRequest();
        //request.initWithEntityName("Application");
        //request.predicate = MIOPredicate.predicateWithFormat('placeID = "' + this._placeID + '"');
        //var array = this.moc.executeFetch(request);
        //AppHelper.fillApplicationComboBox(this.deviceComboBox, array, this._license.app);



        this.updateUI();
    }

    viewWillDisappear(){
        super.viewWillDisappear();

        let dh = DBHelper.sharedInstance();
        dh.removeObserverForChangesInEntity(this, "Application");
    }

    private _license:License = null;
    set license(id)
    {
        if(id == null) {
            this._license = null;
        }
        if (id == this._license) return;
        this._license = id;
    }
    
    set placeID(id)
    {
        if(id == null) {
            this._placeID = null;
        }
        if (id == this._placeID) return;
        this._placeID = id;
    }

    /*
    set licenseID(id){
        
        if(id == null) {
            this._licenseID = null;
            this._license = null;
            this._fetchedResultsController = null;
        }
        if (id == this._licenseID) return;
        this._licenseID = id;

        this._fetchedResultsController = null;
        var fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('License');
        fetchRequest.relationshipKeyPathsForPrefetching = [];
        fetchRequest.predicate = MIOPredicate.predicateWithFormat('identifier = ' + this._licenseID );
        var fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, this.ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        this._fetchedResultsController = fetchedResultsController;
    
        let results = fetchedResultsController.performFetch();
    
        if (results.length > 0) this.updateLicense(results[0]);
    }
    */

    /*
    controllerDidChangeContent(controller){
        let results = this._fetchedResultsController.resultObjects;
        if (results.length > 0) this.updateLicense(results[0]);
    }
    */

    /*
    private updateLicense(license:License){
        if (this._licenseID != license.identifier) return;
        if (this._license == license) return;
        this._license = license;

        this.updateUI();
    }
    */

    private updateUI()
    {
        if (this._license == null) return;
        if (this.viewIsLoaded == false) return;

        //this.isActiveSwitchButton.setOn(this._license.isActive);
        //this.isCanceledSwitchButton.setOn(this._license.isCanceled);
        this.licenseTypeComboBox.selectItem(this._license.type);
        this.appTypeComboBox.selectItem(this._license.apptype);
        this.beginDateTextField.text = this.df.stringFromDate(this._license.beginDate);
        this.endDateTextField.text = this.df.stringFromDate(this._license.endDate);
        this.providerNameText.text = this._license.providerName;
        this.infoTextArea.text = this._license.info;
    }


    // make the change when a date is selected from the date picker
    didSelectDate(datePickerController, date){

        if (datePickerController.tag == 1){
            this.beginDateTextField.text = this.df.stringFromDate(date);
            //this._license.beginDate = date; 
        }else if(datePickerController.tag == 2){
            this.endDateTextField.text = this.df.stringFromDate(date);
            //this._license.endDate = date; 
        }
    }
    
    private saveLicense() 
    {
            
        this._license.beginDate = this.df.dateFromString(this.beginDateTextField.text);
        this._license.endDate = this.df.dateFromString(this.endDateTextField.text);
        //this._license.isActive = true;
        this._license.isCanceled = false;
        this._license.type = this.licenseTypeComboBox.getSelectedItem();
        this._license.apptype = this.appTypeComboBox.getSelectedItem();
        this._license.info = this.infoTextArea.text;
        this._license.providerName = this.providerNameText.text;
        this._license.place = this._placeID;
        
        let app = this.deviceComboBox.getSelectedItem();

        if(app == 'NULL'){
            this._license.app = null;
            this._license.appname = null;
        }else{
            this._license.app = app;
            this._license.appname = this.deviceComboBox.getSelectedItemText();
        }

        this.moc.save();
        this.updateUI();
    }
    
    private resetLicense() 
    {

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this.license = MIOEntityDescription.insertNewObjectForEntityForName("License", ad.managedObjectContext);
        this.isNewLicense = true;

        //this.isActiveSwitchButton.setOn(this._license.isActive);
        //this.isCanceledSwitchButton.setOn(this._license.isCanceled);
        this.licenseTypeComboBox.selectItem(this._license.type);
        this.appTypeComboBox.selectItem(this._license.apptype);
        this.beginDateTextField.text = this.df.stringFromDate(this._license.beginDate);
        this.endDateTextField.text = this.df.stringFromDate(this._license.endDate);
        this.providerNameText.text = this._license.providerName;
        this.infoTextArea.text = this._license.info;

    }

    private toggleActivateLicense()
    {
        if (this._license.isActive) {
            //alert("license is active! now change to inactive");
            this._license.isActive = false;
            this.statusButton.layer.classList.remove('active');
            this.statusButton.layer.classList.add('alert');
            this.statusButton.text = "Inactive";
        } else {
            //alert("license is NOT active! now change to activce");
            this._license.isActive = true;
            this.statusButton.layer.classList.remove('alert');
            this.statusButton.layer.classList.add('active');
            this.statusButton.text = "Active";
        }
    }

    /*
    private renewLicense() 
    {
        var newId = MIOUUID.UUID().UUIDString;

        this._license.nextLicense = newId;
        this.moc.save();

        var license:License = MIOEntityDescription.insertNewObjectForEntityForName('License', this.moc) as License;
        license.identifier = newId;
        license.beginDate = this.df.dateFromString(this.beginDateTextField.text);
        license.endDate = this.df.dateFromString(this.endDateTextField.text);
        license.isActive = this.isActiveSwitchButton.on;
        license.isCanceled = this.isCanceledSwitchButton.on;
        license.type = this.typeComboBox.getSelectedItem();
        license.apptype = this.appTypeComboBox.getSelectedItem();
        license.info = this.infoTextArea.text;
        license.providerName = this.providerNameText.text;
        license.place = this._license.place;
        license.previousLicense = this._license.identifier;
        license.app = this._license.app;
        license.appname = this._license.appname;
        this.moc.save();

        this.updateUI();
    }
    */

    private removeLicense()
    {
        this.moc.deleteObject(this._license);
        this.moc.save();
    }

}