/**
 * Created by miguel on 23/2/17.
 */



class EventDetailCell extends MUITableViewCell
{
    private _dateLabel = null;
    private _statusLabel = null;
    private _infoButton = null;
    
    private _separator = null;

    private _item = null;

    delegate = null;
    
    awakeFromHTML()
    {
        this._dateLabel = MUIOutlet(this, 'ecdv_tv_bkticket_date_lbl', 'MUILabel');
        this._statusLabel = MUIOutlet(this, 'ecdv_tv_total_lbl', 'MUILabel');
        this._infoButton = MUIOutlet(this, 'ecdv_tv_info_btn', 'MUIButton');
        this._infoButton.setAction(this, function()
        {
            if(this.delegate != null)
            {
                this.delegate.itemDidSelected(this._item);
            }
        });
        // this._separator = MUIOutlet(this, 'ecdv_tv_separator', 'MUIView');
    }

    setSelected(value)
    {
        // super.setSelected(value);
        // if (value == true)
        //     this._separator.setBackgroundRGBColor(0, 191, 191);
        // else
        //     this._separator.setBackgroundRGBColor(237, 237, 242);
    }
    
    set item(i)
    {
        this._item = i;

        this._dateLabel.text = i.name;
        this._statusLabel.text = '';//i.used + '/' + (i.total);
    }
}