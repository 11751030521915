//
// Generated class DBMappingEntityPlace
//

/// <reference path="DBMappingEntityPlace_ManagedObject.ts" />

class DBMappingEntityPlace extends DBMappingEntityPlace_ManagedObject
{

}
