
class DBSuppliersCoder extends DBXLSXCoder
{
    mainEntity() {
        return { entity: "Supplier", predicateFormat: "deletedAt == null", relations: ["address", "phone", "mobilePhone"] }
    }

    columns() : any[] {
        let cols = [
            { title:"SUPPLIER ID", width: "10%", align: "center" },
            { title:"SUPPLIER NAME", width: "10%", align: "center" },
            { title:"BUSINESS ID", width: "20%", align: "center" },
            { title:"EMAIL", width: "20%", align: "center"},
            { title:"PHONE ID", width: "10%", align: "center" },
            { title:"PHONE", width: "10%", align: "center" },
            { title:"MOBILE ID", width: "20%", align: "center" },
            { title:"MOBILE", width: "20%", align: "center" },
            { title:"ADDRESS ID", width: "10%", align: "center" },
            { title:"ADDRESS", width: "10%", align: "center" },
            { title:"ADDRESS 2", width: "10%", align: "center" },
            { title:"CITY", width: "10%", align: "center" },
            { title:"PROVINCE OR STATE", width: "10%", align: "center" },
            { title:"POSTAL CODE", width: "10%", align: "center" },
            { title:"COUNTRY ISO2", width: "10%", align: "center" },
            { title:"COMMENTS", width: "10%", align: "center" },
            { title:"ONLY SUPPLIER PRODUCT", width: "10%", align: "center" }
        ]
        return cols;
    }

    aditionalImportEntities(){
        return [            
            { entity: "Country", predicateFormat: null },
            { entity: "Address", predicateFormat: "deletedAt = null" },
            { entity: "PhoneNumber", predicateFormat: "deletedAt = null" }
        ]
    }

    protected importRow(row:any){
                
        let supplierID       = row["SUPPLIER ID"];
        let name             = row["SUPPLIER NAME"];        
        let businessID       = row["BUSINESS ID"];
        let email            = row["EMAIL"];
        let phoneID          = row["PHONE ID"];
        let phone            = row["PHONE"];
        let mobileID         = row["MOBILE ID"];
        let mobile           = row["MOBILE"];
        let addressID        = row["ADDRESS ID"];
        let address          = row["ADDRESS"];
        let addres2          = row["ADDRESS 2"];
        let city             = row["CITY"];
        let postalCode       = row["POSTAL CODE"];        
        let state            = row["PROVINCE OR STATE"];
        let countryISO2      = row["COUNTRY ISO2"];
        let comments         = row["COMMENTS"];
        let onlySupplierProducts = row["ONLY SUPPLIER PRODUCT"];

        let country = this.queryEntityByField("Country", "iso2", countryISO2) as Country;
        if (country == null) {
            country = AppHelper.sharedInstance().defaultCountry;
        }

        let supplier = this.queryEntityByIDOrName("Supplier", supplierID, name) as Supplier;
        if (supplier == null) {
            supplier = MIOEntityDescription.insertNewObjectForEntityForName("Supplier", DBHelper.mainManagedObjectContext) as Supplier;
            supplier.identifier = this.parseOrCreateIdentifier(supplierID);
            supplier.name = name;
        }

        if (supplier.address == null) {
            let addr = MIOEntityDescription.insertNewObjectForEntityForName("Address", DBHelper.mainManagedObjectContext) as Address;
            addr.identifier = this.parseOrCreateIdentifier(addressID);
            supplier.address = addr;    
        }

        if (supplier.phone == null) {
            let p = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", DBHelper.mainManagedObjectContext) as PhoneNumber;
            p.identifier = this.parseOrCreateIdentifier(phoneID);
            supplier.phone = p;
        }

        if (supplier.mobilePhone == null) {
            let m = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", DBHelper.mainManagedObjectContext) as PhoneNumber;
            m.identifier = this.parseOrCreateIdentifier(mobileID);
            supplier.mobilePhone = m;
        }
                
        supplier.document = businessID;
        supplier.email = email;
        supplier.phone.number = phone;
        supplier.mobilePhone.number = mobile;
        supplier.address.address1 = address?.trim();
        supplier.address.address2 = addres2?.trim();
        supplier.address.city = city;
        supplier.address.state = state;
        supplier.address.postalCode = postalCode;
        supplier.address.country = country?.name;
        supplier.address.countryISOa2 = country?.iso2;
        supplier.address.countryISOa3 = country?.iso3;        
        supplier.comments = comments;
        supplier.onlySupplierProducts = this.parseBoolValue(onlySupplierProducts);

        this.appendObject(supplier);
        MIOLog("ADDING SUPPLIER: " + this.rowIndex + "/" + this.rows.length + ": " + name);
    }

    protected exportTitle() : string { return "Suppliers"; }

    protected exportSortDescriptors() { 
        return [MIOSortDescriptor.sortDescriptorWithKey("name", false)]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let supplier = object as Supplier;

        let item = {
            "SUPPLIER ID"           : supplier.identifier,
            "SUPPLIER NAME"         : supplier.name,
            "BUSINESS ID"           : supplier.document, 
            "EMAIL"                 : supplier.email,
            "PHONE ID"              : supplier.phone?.identifier,
            "PHONE"                 : supplier.phone?.number,
            "MOBILE ID"             : supplier.mobilePhone?.identifier,
            "MOBILE"                : supplier.mobilePhone?.number,
            "ADDRESS ID"            : supplier.address?.identifier,
            "ADDRESS"               : supplier.address?.address1,
            "ADDRESS 2"             : supplier.address?.address2,
            "CITY"                  : supplier.address?.city,
            "PROVINCE OR STATE"     : supplier.address?.state,
            "POSTAL CODE"           : supplier.address?.postalCode,
            "COUNTRY ISO2"          : supplier.address?.countryISOa2,
            "COMMENTS"              : supplier.comments,
            "ONLY SUPPLIER PRODUCT" : supplier.onlySupplierProducts
        }

        return item;
    }
}