class DBToolCell extends MUITableViewCell
{
    values = {};

    private keyTextField:MUITextField = null;
    private dataTypeComboBox:MUIComboBox = null;    
    private titleTextField:MUITextField = null;   
    private typeComboBox:MUIComboBox = null;            

    awakeFromHTML(){        
        this.keyTextField = MUIOutlet(this, 'key-tf', 'MUITextField');
        this.keyTextField.setOnChangeText(this, function(control, value){
            this.values["Key"] = value;
            if (this.filter != null) this.filter.key = value;
        });
        this.dataTypeComboBox = MUIOutlet(this, 'data-type-cb', 'MUIComboBox');        
        this.dataTypeComboBox.setOnChangeAction(this, function(control, value){
            this.values["DataType"] = value;
            if (this.filter != null) this.filter.dataType = value;
        });
        this.titleTextField = MUIOutlet(this, 'title-tf', 'MUITextField');
        this.titleTextField.setOnChangeText(this, function(control, value){
            this.values["Title"] = value;
            if (this.filter != null) this.filter.title = value;
        });
        this.typeComboBox = MUIOutlet(this, 'type-cb', 'MUIComboBox');
        this.typeComboBox.setOnChangeAction(this, function(control, value){
            this.values["Type"] = value;
            if (this.filter != null) this.filter.type = value;
        });

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    private _filter = null;
    get filter() {return this._filter;}
    set filter(filter:DBFilter){
        this._filter = filter;

        this.keyTextField.text = filter.key;
        this.dataTypeComboBox.selectItem(filter.dataType);
        this.titleTextField.text = filter.title;          
        this.typeComboBox.selectItem(filter.type);
    }
}
