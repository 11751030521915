/**
 * Created by miguel on 15/3/17.
 */


enum CalendarMode {
    Day,
    Week,
    Month,
    Year,
    Custom
}
class CalendarViewController extends MUIViewController
{
    delegate = null;

    private closeButton:MUIButton = null;
    private filterComboBox:MUIComboBox = null;
    private segmentedControl: MUISegmentedControl = null;
    private applyButton:MUIButton = null;    
    private pagecontroller: MUIPageController = null;        

    private singleViewController:CalendarSingleViewController = null;
    private multipleViewController:CalendarMultipleViewController = null;
    private rangeViewController:CalendarRangeViewController = null;

    private df = MUIWebApplication.sharedInstance().delegate.dateFormatter;
    private sdf = MUIWebApplication.sharedInstance().delegate.serverDateTimeFormatter;

    get preferredContentSize(){
        // let vc = this.pagecontroller.childViewControllers[this.pagecontroller.selectedViewControllerIndex];
        // return vc.preferredContent Size;
        return new MIOSize(538, 562);
    }

    viewDidLoad() { 
        super.viewDidLoad();

        this.closeButton = MUIOutlet(this, 'cv_close_btn','MUIButton');
        this.closeButton.setAction(this, function(){
           this.dismissViewController(true);
        });

        this.filterComboBox = MUIOutlet(this, "page-index-cb", "MUIComboBox");
        this.filterComboBox.hidden = MIOCoreIsPhone() ? false : true;
        this.filterComboBox.setOnChangeAction(this, function(control, value){
            let index = parseInt(value);
            this.calendarSegmentedIndexDidChange(index);
        }) ;      

        this.segmentedControl = MUIOutlet(this, 'cv_segmentedcontrol', 'MUISegmentedControl');
        this.segmentedControl.hidden = MIOCoreIsPhone() ? true : false;
        this.segmentedControl.setAction(this, function (control, index) {
            this.calendarSegmentedIndexDidChange(index);
        });

        this.applyButton = MUIOutlet(this, 'cv_apply_btn','MUIButton');
        this.applyButton.setAction(this, function(){
          this.applyDates();
        });


       this.pagecontroller = MUIOutlet(this, 'cv_pagecontroller', 'MUIPageController');
        
        this.singleViewController = new CalendarSingleViewController('calendar-single-view');        
        this.singleViewController.initWithResource('layout/calendar/CalendarSingleView.html');
        this.pagecontroller.addPageViewController(this.singleViewController);        

        this.multipleViewController = new CalendarMultipleViewController('calendar-multiple-view');
        this.multipleViewController.delegate = this;
        this.multipleViewController.initWithResource('layout/calendar/CalendarMultipleView.html');
        this.pagecontroller.addPageViewController(this.multipleViewController);        

        this.rangeViewController = new CalendarRangeViewController('calendar-range-view');
        this.rangeViewController.delegate = this;
        this.rangeViewController.initWithResource('layout/calendar/CalendarRangeView.html');
        this.pagecontroller.addPageViewController(this.rangeViewController);        
    }

    // viewWillAppear(animated?){
    //     super.viewWillAppear(animated);
    //     this._mode = CalendarMode.Day;
    //     this.segmentedControl.selectSegmentedAtIndex(0);
    //     this.pagecontroller.showPageAtIndex(0);
    // }

    private _mode = CalendarMode.Day;
    get mode(){
        return this._mode;
    }

    private calendarSegmentedIndexDidChange(index){
        this._mode = index;

        switch(index){
            case 0:
                this.singleViewController.mode = CalendarSingleViewControllerMode.Day;
                this.pagecontroller.showPageAtIndex(0);
                break;

            case 1:
                this.singleViewController.mode = CalendarSingleViewControllerMode.Week;
                this.pagecontroller.showPageAtIndex(0);
                break;
            
            case 2:
                this.multipleViewController.mode = CalendarMultipleViewControllerMode.Months;
                this.pagecontroller.showPageAtIndex(1);
                break;
            
            case 3:
                this.multipleViewController.mode = CalendarMultipleViewControllerMode.Years;
                this.pagecontroller.showPageAtIndex(1);
                break;

            case 4:
                this.pagecontroller.showPageAtIndex(2);
                break;
        }
    }        

    private applyDates(){

        let dateFrom:Date = null;
        let dateTo:Date = null;

        switch (this._mode){
            case 0:
            case 1:
                dateFrom = this.singleViewController.dateTimeFrom;
                dateTo = this.singleViewController.dateTimeTo;
                break;

            case 2:
            case 3:
                dateFrom = this.multipleViewController.dateTimeFrom;
                dateTo = this.multipleViewController.dateTimeTo;
                break;

            case 4:
                dateFrom = this.rangeViewController.dateTimeFrom;
                dateTo = this.rangeViewController.dateTimeTo;
                break;
        }

        if (dateFrom == null || dateTo == null) {
            AppHelper.showErrorMessage(this, 'Error', 'La fecha selecionada es incorrecta.');            
        }
        else if(dateFrom.getTime() >= dateTo.getTime() ) {
            AppHelper.showErrorMessage(this, 'Error', 'La fecha final debe ser mayor que la inicial.');
        }
        else {
            if(this.delegate != null) this.delegate.datesDidSelect(dateFrom, dateTo);
            this.dismissViewController(true);
            //MIONotificationCenter.defaultCenter().postNotification('ReportDatesDidChange', this);          
        }          
    }
}
