

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ProductModifierRate_ManagedObject

class ProductModifierRate_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: price
    set price(value:string) {
        this.setValueForKey(value, 'price');
    }
    get price():string {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():string {
        return this.primitiveValueForKey('price');
    }
    // Relationship: productModifier
    set productModifier(value:ProductModifier) {
        this.setValueForKey(value, 'productModifier');
    }
    get productModifier():ProductModifier {
        return this.valueForKey('productModifier') as ProductModifier;
    }
    // Relationship: rate
    set rate(value:Rate) {
        this.setValueForKey(value, 'rate');
    }
    get rate():Rate {
        return this.valueForKey('rate') as Rate;
    }
    // Relationship: tax
    set tax(value:Tax) {
        this.setValueForKey(value, 'tax');
    }
    get tax():Tax {
        return this.valueForKey('tax') as Tax;
    }
}
