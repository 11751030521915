/**
 * Created by miguel on 22/2/17.
 */

/// <reference path="../../AppHelper.ts" />
/// <reference path="../../base/EntityListViewController.ts" />


class GuestlistListViewController extends BaseTableViewController
{       
    private addButton:MUIButton = null;    

    private nvc = null;
    private current:BookingGuestList = null;
    private noDetailViewController:MUIViewController = null;
    private dvc = null;

    private moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

    viewDidLoad(){
        super.viewDidLoad();
        this.tableView = MUIOutlet(this,"glv_tableview","MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        this.tableView.reloadData();

        this.searchTextField = MUIOutlet(this, "glv_tv_search_bar", "MUITextField");
        this.setupSearchTextField();

        this.addButton = MUIOutlet(this, 'glv_nb_add_btn', 'MUIButton');
        this.addButton.setAction(this, function (){
           this.addGuestlist();
        });        
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('GuestlistCell');
    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
    
        cell.item = item;    
        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
        
        if(this.current && item.identifier == this.current.identifier) 
        {
            cell.setSelected(true);
            this.showCurrentItem();
        }
               
        return cell;
    }
    
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        let obj = this.fetchedResultsController.objectAtIndexPath(indexPath);
        this.current = obj;
        this.showCurrentItem();
    }
    
    private showCurrentItem(){
        if(!this.dvc){
            this.dvc = new GuestlistDetailViewController('guestlist-detail-view');
            this.dvc.initWithResource("layout/bookings/guestlist/GuestlistDetailView.html");
        }
        this.splitViewController.showDetailViewController(this.dvc);
        this.dvc.guestlist = this.current;

    }
    private showNoItemSelected(){
        this.current = null;
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("GuestList", NoItemSelectedViewControllerType.GuestList);
        this.splitViewController.showDetailViewController(vc);
    }
    
    get entityName(){
        return 'BookingGuestList';
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('activeFrom', true)];
        
        let filterString = null;
        if (this.searchString != null)
            filterString = 'name CONTAINS "' + this.searchString + '"';
    
        let fetchRequest = DBHelper.listFetchRequestWithEntityName(this.entityName, sortDescriptors, filterString);
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, this.moc, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    private addGuestlist(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('NEW GUEST LIST','NEW GUEST LIST'), MIOLocalizeString('CHOOSE A NAME', 'CHOOSE A NAME'), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });
           
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, this, function(){
               let nameTF =  avc.textFields[0];
               let name = nameTF.text.trim();
               if(name.length > 0) {

                    this.current = MIOEntityDescription.insertNewObjectForEntityForName(this.entityName, this.moc) as BookingGuestList;
                    this.current.identifier = MIOUUID.UUID().UUIDString;
                    let d = new Date();
                    let id =  MIOUUID.UUID().UUIDString;
                    
                    this.current.name = name;
                    this.current.activeFrom = d;
                    this.current.activeTo = d;
                    this.current.hourFrom = '00:00:00';
                    this.current.hourTo = '23:59:00';
                    this.current.isPrivate = false;

                    this.moc.save();
               }
               else {
                   var error = new MUIAlertViewController();
                    error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('ERROR, CHECK NAME','ERROR, CHECK NAME'), MUIAlertViewStyle.Default);
                    error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, this, function(){}));
                    
                    this.presentViewController(error, true);
               }
           }));
           
           avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, this, function(){}));
           
           this.presentViewController(avc, true);
    }
        
}