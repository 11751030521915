//
// Generated class DBDocument
//

/// <reference path="DBDocument_ManagedObject.ts" />

enum DBDocumentStatus
{
    none,
    pending,
    processed,
    unprocessed
}

class DBDocument extends DBDocument_ManagedObject
{

}
