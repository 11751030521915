


class BookingsDayMapViewController extends MUIViewController
{    
    viewDidLoad() {
        super.viewDidLoad();

    }

}
