class OnlineSettingsViewController extends MUIViewController
{        
    private saveButton:MUIButton = null;
    private tableView:UITableView = null;
    private onlineSettingsObjects:[] = null;
    private onlineSettingsObjectsDict:any = null;


    viewDidLoad(){
        super.viewDidLoad();

        //this is the query that gets the Online settings
        this.getOnlineOrderSettings();

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        });

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        
    }

    viewWillAppear(){
        super.viewWillAppear();
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        //this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    private sectionRows = [4, 5, 3, 5, 5, 2]; //number of rows for each section
    numberOfSections(tableView:UITableView){
        //sections Diseño, General, Carta Online, Take Away, Delivery, Booking
        return this.sectionRows.length;
    }
        
    numberOfRowsInSection(tableView:UITableView, section:number){
        return this.sectionRows[section];
    }

    private sectionTitles = [MIOLocalizeString("DESIGN","DESIGN"),
                            MIOLocalizeString("GENERAL","GENERAL"),
                            MIOLocalizeString("DIGITAL_MENU","DIGITAL_MENU"),
                            MIOLocalizeString("TAKEAWAY","TAKEAWAY"),
                            MIOLocalizeString("DELIVERY","DELIVERY"),
                            MIOLocalizeString("BBOKINGS","BBOKINGS")];

    titleForHeaderInSection(tableView:UITableView, section:number) {          
        return this.sectionTitles[section];
    }
    
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){        

        let cell:any = null;
        //let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Configuration;
        
        switch (indexPath.section){
            case 0: //section Design
                switch (indexPath.row){
                    case 0:
                        cell = tableView.dequeueReusableCellWithIdentifier("OnlineSettingLogoCell") as OnlineSettingLogoCell;
                        cell.setName = MIOLocalizeString("COMPANY LOGO","COMPANY LOGO");
                        cell.setDesc ="Explanation, minimum size, etc.";
                        //cell.setItemURL = this.findObjectByKey(this.onlineSettingsObjects, "name", "onlineorder-companylogo-url")
                        //cell.setItemID = this.findObjectByKey(this.onlineSettingsObjects, "name", "onlineorder-companylogo-id")
                        cell.setItemURL = this.onlineSettingsObjectsDict?["onlineorder-companylogo-url"]:null;
                        cell.setItemID = this.onlineSettingsObjectsDict?["onlineorder-companylogo-id"]:null;
                    break;
        
                    case 1:
                        cell = tableView.dequeueReusableCellWithIdentifier("TextFieldColorSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("MAIN COLOR","MAIN COLOR");
                        cell.setDesc = "Explanation, where to use this color";
                        cell.item = this.onlineSettingsObjectsDict?["onlineorder-color-main"]:null;
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat(""));
                    break;
        
                    case 2:
                        cell = tableView.dequeueReusableCellWithIdentifier("TextFieldColorSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("SECONDARY COLOR","SECONDARY COLOR");
                        cell.setDesc = "Explanation, where to use this color";
                        cell.item = this.onlineSettingsObjectsDict?["onlineorder-color-secondary"]:null;
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat("name == onlineorder-color-secondary"));
                    break;
        
                    case 3:
                        cell = tableView.dequeueReusableCellWithIdentifier("TextFieldSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("FONT","FONT");
                        cell.setDesc = "Explanation, what fonts can be used, etc.";
                        cell.item = this.onlineSettingsObjectsDict?["onlineorder-font"]:null;
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat("name == onlineorder-font"));
                    break;

                }
            break;

            case 1: //section General
                switch (indexPath.row) {
                    case 0:
                        cell = tableView.dequeueReusableCellWithIdentifier("DropdownSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("WORKER","WORKER");
                        cell.setEntityName = "Employee"
                        cell.item = this.onlineSettingsObjectsDict?["onlineorder-worker"]:null;
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat("name == onlineorder-worker"));
                    break;

                    case 1:
                        cell = tableView.dequeueReusableCellWithIdentifier("DropdownSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("PRODUCT PRESET","PRODUCT PRESET");
                        cell.setEntityName = "Preset"
                        cell.item = this.onlineSettingsObjectsDict?["onlineorder-product-preset"]:null;
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat("name == onlineorder-product-preset"));
                    break;

                    case 2:
                        cell = tableView.dequeueReusableCellWithIdentifier("DropdownSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("SYNC APP","SYNC APP");
                        cell.setEntityName = "Application"
                        cell.item = this.onlineSettingsObjectsDict?["onlineorder-sync-app"]:null;
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat("name == onlineorder-sync-app"));
                    break;

                    case 3:
                        cell = tableView.dequeueReusableCellWithIdentifier("DropdownSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("DEFAULT PAYMENT ENTITY","DEFAULT PAYMENT ENTITY");
                        cell.setEntityName = "Paymententity"
                        cell.item = this.onlineSettingsObjectsDict?["default-payment-entity"]:null;
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat("name == default-payment-entity"));
                    break;

                    case 4:
                        cell = tableView.dequeueReusableCellWithIdentifier("PushSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("TIME RANGE PRESETS","TIME RANGE PRESETS");
                    break;
                }
            break;

            case 2:  //section Online menu
                switch (indexPath.row){
                    case 0:
                        cell = tableView.dequeueReusableCellWithIdentifier("DropdownSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("PRESET","PRESET");
                        cell.setEntityName = "Preset"
                        cell.item = this.onlineSettingsObjectsDict?["onlineorder-menu-preset"]:null;
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat("name == onlineorder-menu-preset"));
                    break;
        
                    case 1:
                        cell = tableView.dequeueReusableCellWithIdentifier("DropdownSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("RATE","RATE");
                        cell.setEntityName = "Rate";
                        cell.item = this.onlineSettingsObjectsDict?["onlineorder-menu-rate"]:null;
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat(""));
                    break;
        
                    case 2:
                        cell = tableView.dequeueReusableCellWithIdentifier("SwitchSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("ACTIVATE DIGITAL MENU","ACTIVATE DIGITAL MENU");
                        cell.setDesc = "www.zestalicante.es/cartadigital?implace";
                        cell.item = this.onlineSettingsObjectsDict?["onlineorder-menu-enabled"]:null;
                        //cell.item = this.findObjectByKey(this.onlineSettingsObjects, "onlineorder-menu-enabled");
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat("name == onlineorder-menu-enabled"));
                    break;

                }
            break;

            case 3:  //section Take away
                switch (indexPath.row){
                    case 0:
                        cell = tableView.dequeueReusableCellWithIdentifier("DropdownSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("PRESET","PRESET");
                        cell.setEntityName = "Preset";
                        cell.item = this.onlineSettingsObjectsDict?["onlineorder-takeaway-preset"]:null;
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat(""));
                    break;
        
                    case 1:
                        cell = tableView.dequeueReusableCellWithIdentifier("DropdownSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("RATE","RATE");
                        cell.setEntityName = "Rate";
                        cell.item = this.onlineSettingsObjectsDict?["onlineorder-takeaway-rate"]:null;
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat(""));
                    break;
        
                    case 2:
                        cell = tableView.dequeueReusableCellWithIdentifier("PushSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("TAKEAWAY PRESET CONFIGURATIONS","TAKEAWAY PRESET CONFIGURATIONS");
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat(""));
                    break;

                    case 3:
                        cell = tableView.dequeueReusableCellWithIdentifier("PushSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("TAKEAWAY CLOSED DAYS","TAKEAWAY CLOSED DAYS");
                        cell.setNameUnavailable = true;
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat(""));
                    break;
        
                    case 4:
                        cell = tableView.dequeueReusableCellWithIdentifier("SwitchSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("ACTIVATE TAKEAWAY","ACTIVATE TAKEAWAY");
                        cell.setDesc = "www.zestalicante.es/cartadigital?implace";
                        cell.item = this.onlineSettingsObjectsDict?["onlineorder-takeaway-enabled"]:null;
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat(""));
                    break;

                }
            break;

            case 4:  //section Delivery
                switch (indexPath.row){
                    case 0:
                        cell = tableView.dequeueReusableCellWithIdentifier("DropdownSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("PRESET","PRESET");
                        cell.setEntityName = "Preset";
                        cell.item = this.onlineSettingsObjectsDict?["onlineorder-delivery-preset"]:null;
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat(""));
                    break;
        
                    case 1:
                        cell = tableView.dequeueReusableCellWithIdentifier("DropdownSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("RATE","RATE");
                        cell.setEntityName = "Rate";
                        cell.item = this.onlineSettingsObjectsDict?["onlineorder-delivery-rate"]:null;
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat(""));
                    break;
        
                    case 2:
                        cell = tableView.dequeueReusableCellWithIdentifier("PushSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("DELIVERY PRESET CONFIGURATIONS","DELIVERY PRESET CONFIGURATIONS");
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat(""));
                    break;

                    case 3:
                        cell = tableView.dequeueReusableCellWithIdentifier("PushSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("DELIVERY CLOSED DAYS","DELIVERY CLOSED DAYS");
                        cell.setNameUnavailable = true;
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat(""));
                    break;
        
                    case 4:
                        cell = tableView.dequeueReusableCellWithIdentifier("SwitchSettingCell") as OnlineSettingCell;
                        cell.setName = MIOLocalizeString("ACTIVATE DELIVERY","ACTIVATE DELIVERY");
                        cell.setDesc = "www.zestalicante.es/cartadigital?implace";
                        cell.item = this.onlineSettingsObjectsDict?["onlineorder-delivery-enabled"]:null;
                        //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat(""));
                    break;
                }
            break;

            case 5:  //section Bookings
            switch (indexPath.row){
                case 0:
                    cell = tableView.dequeueReusableCellWithIdentifier("PushSettingCell") as OnlineSettingCell;
                    cell.setName = MIOLocalizeString("BOOKINGS_PRESET_CONFIGURATIONS","BOOKINGS_PRESET_CONFIGURATIONS");
                    //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat(""));
                break;

                case 1:
                    cell = tableView.dequeueReusableCellWithIdentifier("PushSettingCell") as OnlineSettingCell;
                    cell.setName = MIOLocalizeString("BOOKINGS_CLOSED_DAYS","BOOKINGS_CLOSED_DAYS");
                    cell.setNameUnavailable = true;
                    //cell.item = this.onlineSettingsObjectsDict.filterWithPredicate(MIOPredicate.predicateWithFormat(""));
                break;    
            }
            break;
        }
        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){      

        if (indexPath.section == 1 && indexPath.row == 4) {
            //schedule management
            let vc = new TimeRangePresetListViewController("time-range-preset-list-view");
            vc.initWithResource("layout/timerangepreset/TimeRangePresetListView.html");
            vc.timeRangeGroupType = null;
            this.navigationController.pushViewController(vc, true);

        } else if (indexPath.section == 3 && indexPath.row == 2) {
            //Takeaway configurations
            let vc = new OnlinePresetsConfigurationsViewController("online-presets-configurations-view");
            vc.initWithResource("layout/onlinesettings/OnlinePresetsConfigurationsView.html");
            vc.configurationType = TimeRangePresetConfigurationType.Takeaway;
            this.navigationController.pushViewController(vc, true);

        } else if (indexPath.section == 3 && indexPath.row == 3) {
            //Takeaway closed days
            // let vc = new OnlineClosedDaysViewController("online-closed-days-view");
            // vc.initWithResource("layout/onlinesettings/OnlineClosedDaysView.html");
            // vc.sectionTitle = MIOLocalizeString("TAKEAWAY CLOSED DAYS","TAKEAWAY CLOSED DAYS");
            // this.navigationController.pushViewController(vc, true);

        } else if (indexPath.section == 4 && indexPath.row == 2) {
            //Delivery configurations
            let vc = new OnlinePresetsConfigurationsViewController("online-presets-configurations-view");
            vc.initWithResource("layout/onlinesettings/OnlinePresetsConfigurationsView.html");
            vc.configurationType = TimeRangePresetConfigurationType.Delivery;
            this.navigationController.pushViewController(vc, true);

        } else if (indexPath.section == 4 && indexPath.row == 3) {
            //Delivery closed days
            // let vc = new OnlineClosedDaysViewController("online-closed-days-view");
            // vc.initWithResource("layout/onlinesettings/OnlineClosedDaysView.html");
            // vc.sectionTitle = MIOLocalizeString("DELIVERY CLOSED DAYS","DELIVERY CLOSED DAYS");
            // this.navigationController.pushViewController(vc, true);

        } 
    }

    private getOnlineOrderSettings() {

        let predicate = MIOPredicate.predicateWithFormat("deletedAt == null AND section == 'OnlineOrders'");
        DBHelper.queryObjectsWithCompletion("Configuration", null, predicate, [], this, function(objects){
            if (objects.length > 0){
                this.onlineSettingsObjects = objects; //used to find correct Configuration object using "findObjectByKey()"
                this.onlineSettingsObjectsDict = this.setupOnlineSettingsDict(objects); //used for referance as a dictionary
                this.tableView.reloadData();
            }
        });
    }

    //set up a dictionary now to avoid further looping why setting variables to cells
    private setupOnlineSettingsDict (array){
        let dict = {};
        for (var i = 0; i < array.length; i++) {
            dict[array[i].name] = array[i];
        }
        return dict;
    }

    private findObjectByKey(array:Configuration[], key) {
        for (var i = 0; i < array.length; i++) {
            if (array[i].name == key) {
                return array[i];
            }
        }
        return null;
    }

    // private _fetchedResultsController = null;
    // set fetchedResultsController(value){
    //     if (value == null && this._fetchedResultsController != null)
    //         this._fetchedResultsController.delegate = null;
    
    //     this._fetchedResultsController = value;
    // }
    
    // get fetchedResultsController(){
    //     if (this._fetchedResultsController != null)
    //         return this._fetchedResultsController;
    
    //     let ad = MUIWebApplication.sharedInstance().delegate;
    
    //     let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('Configuration');    
    //     //fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('section', true)];
    //     fetchRequest.predicate = MIOPredicate.predicateWithFormat("deletedAt == null AND section == 'OnlineOrders'");
    
    //     let fetchedResultsController = new MIOFetchedResultsController();
    //     fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
    //     fetchedResultsController.delegate = this;
    
    //     fetchedResultsController.performFetch();
    
    //     this._fetchedResultsController = fetchedResultsController;    
    //     return this._fetchedResultsController;
    // }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }

}