class LeaveReasonViewController extends MUIViewController
{
    static newInstance() : LeaveReasonViewController {
        let vc = new LeaveReasonViewController("leave-reason-view");
        vc.initWithResource("layout/leavereason/LeaveReasonView.html");
        return vc;
    }

    get preferredContentSize(){
        return new MIOSize(500, 500);
    }

    private closeButton:MUIButton = null;    
    private deactivateButton:MUIButton = null;

    private reasonTypeDropdown:MUIButton = null;
    private authorizeByTextField:MUITextField = null;
    private dateTextField:MUITextField = null;    
    private dateButton:MUIButton = null;
    private reasonTextArea:MUITextArea = null;

    private leaveReasonType:LeaveReasonType = null;
    private leaveAuthorizationBy:string = null;
    private leaveDate:Date = null;
    private leaveReason:string = null

    private df = ( MUIWebApplication.sharedInstance().delegate as AppDelegate ).dateFormatter;

    viewDidLoad(){
        super.viewDidLoad();

        this.closeButton = MUIOutlet( this, "close-btn", "MUIButton");
        this.closeButton.setAction( this, function( this:LeaveReasonViewController ) {
            this.dismissViewController( true );
        });

        this.deactivateButton = MUIOutlet( this, "deactivate-btn", "MUIButton");
        this.deactivateButton.setAction( this, function( this:LeaveReasonViewController ) {
            this.save();
        });

        this.reasonTypeDropdown = MUIOutlet(this, "concept-dd", "MUIButton");
        this.reasonTypeDropdown.setAction(this, function(this:LeaveReasonViewController) {
            AppHelper.sharedInstance().showSelectLeaveReasonTypeViewControllerFromView(this.reasonTypeDropdown, this._business.leaveReasonType, this, function(this:LeaveReasonViewController, controller:any, object:LeaveReasonType){
                this.leaveReasonType = object;
                this.reasonTypeDropdown.title = object?.title;
            });
        });


        this.authorizeByTextField = MUIOutlet( this, "name-tf", "MUITextField");
        this.authorizeByTextField.setOnChangeText( this, function( this:LeaveReasonViewController, control:MUITextField, value:string){
            this.leaveAuthorizationBy = value.trim().length > 0 ? value.trim() : null;
        });

        this.dateTextField = MUIOutlet( this, "date-tf", "MUITextField");
        this.dateTextField.formatter = this.df;
        this.dateTextField.setOnChangeText( this, function(this:LeaveReasonViewController, control:MUITextField, value:string){
            this.leaveDate = this.df.dateFromString( value );
        });


        this.dateButton = MUIOutlet( this, "date-btn", "MUIButton");
        this.dateButton.tag = 1;
        this.dateButton.setAction( this, function( this:LeaveReasonViewController) {
            this.showDatePickerController( this.dateButton );
        });

        this.reasonTextArea = MUIOutlet( this, "comments-ta", "MUITextArea");
        this.reasonTextArea.setOnChangeText( this, function( this:LeaveReasonViewController, control:MUITextArea, value:string){
            this.leaveReason = value.trim().length > 0 ? value.trim() : null;
        });
    }

    private _business:BusinessLicense = null;
    set business( business:BusinessLicense) {
        this._business = business;
    }

    private showDatePickerController( button: MUIButton ){
        let dp = new MUIDatePickerController();
        dp.init();        
        dp.delegate = this;
        dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;
        dp.tag = button.tag;

        let pc = dp.popoverPresentationController;
        pc.sourceRect = button.frame;
        pc.sourceView = button;

        AppHelper.sharedInstance().presentViewController(dp, true);
    }

    didSelectDate(controller:MUIDatePickerController, date:Date){
        switch (controller.tag) {
            case 1: 
                this.leaveDate = date;
                this.dateTextField.text = this.df.stringFromDate( date );
            break;
        }
    }


    private save() 
    {
        if (this.leaveReasonType == null) {
            AppHelper.showErrorMessage(this, MIOLocalizeString("ERROR", "ERROR"), MIOLocalizeString("PLEASE SELECT A REASON TYPE", "PLEASE SELECT A REASON TYPE"));
            return;
        }

        if (this.leaveAuthorizationBy == null) {
            AppHelper.showErrorMessage(this, MIOLocalizeString("ERROR", "ERROR"), MIOLocalizeString("PLEASE TYPE WHO GAVE THE ORDER", "PLEASE TYPE WHO GAVE THE ORDER"));
            return;
        }

        if (this.leaveDate == null) {
            AppHelper.showErrorMessage(this, MIOLocalizeString("ERROR", "ERROR"), MIOLocalizeString("PLEASE SELECT A DATE", "PLEASE SELECT A DATE"));
            return;
        }

        if (this.leaveReason == null) {
            AppHelper.showErrorMessage(this, MIOLocalizeString("ERROR", "ERROR"), MIOLocalizeString("PLEASE TYPE A REASON", "PLEASE TYPE A REASON"));
            return;
        }

        this._business.leaveReasonType = this.leaveReasonType;
        this._business.leaveAuthorizationBy = this.leaveAuthorizationBy;
        this._business.leaveDate = this.leaveDate;
        this._business.leaveReason = this.leaveReason;
        this._business.active = false;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let log = MIOEntityDescription.insertNewObjectForEntityForName( "LeaveReasonLog", ad.managedObjectContext ) as LeaveReasonLog;

        log.business = this._business;
        log.user = ad.selectedUser;
        log.userName = ad.selectedUser.name;
        log.date = new Date();
        log.message = this.leaveReason;
        log.leaveDate = this.leaveDate;
        log.authorizeBy = this.leaveAuthorizationBy;
        log.type = this.leaveReasonType;

        this.closeButton.enabled = false;
        this.deactivateButton.enabled = false;

        DBHelper.queryObjectsWithCompletion( "License", null, MIOPredicate.predicateWithFormat("business = " + this._business.identifier ), [], this, function(this:LeaveReasonViewController, licenses:License[]){
            this.closeButton.enabled = false;
            this.deactivateButton.enabled = false;
    
            for (let lic of licenses) {
                lic.active = false;
                lic.nextRenewalDate = null;
            }
            
            DBHelper.saveMainContext();
            this.dismissViewController( true );
        });        
    }
}