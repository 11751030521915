

/// <reference path="PresetItem.ts" />

// Generated class PresetProduct_ManagedObject

class PresetProduct_ManagedObject extends PresetItem
{

    // Property: formatID
    set formatID(value:string) {
        this.setValueForKey(value, 'formatID');
    }
    get formatID():string {
        return this.valueForKey('formatID');
    }
    set formatIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'formatID');
    }
    get formatIDPrimitiveValue():string {
        return this.primitiveValueForKey('formatID');
    }

    // Relationship: presetProductModifiers
    protected _presetProductModifiers:MIOManagedObjectSet = null;
    get presetProductModifiers():MIOManagedObjectSet {
        return this.valueForKey('presetProductModifiers');
    }
    addPresetProductModifiersObject(value:PresetProductModifier) {
        this._addObjectForKey(value, 'presetProductModifiers');
    }
    removePresetProductModifiersObject(value:PresetProductModifier) {
        this._removeObjectForKey(value, 'presetProductModifiers');
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
}
