//
// Generated class StockAnnotationView
//

/// <reference path="StockAnnotationView_ManagedObject.ts" />

class StockAnnotationView extends StockAnnotationView_ManagedObject
{

}
