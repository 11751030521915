

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class IntegratorSync_ManagedObject

class IntegratorSync_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: actionType
    set actionType(value:number) {
        this.setValueForKey(value, 'actionType');
    }
    get actionType():number {
        return this.valueForKey('actionType');
    }
    set actionTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'actionType');
    }
    get actionTypePrimitiveValue():number {
        return this.primitiveValueForKey('actionType');
    }

    // Property: batchID
    set batchID(value:number) {
        this.setValueForKey(value, 'batchID');
    }
    get batchID():number {
        return this.valueForKey('batchID');
    }
    set batchIDPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'batchID');
    }
    get batchIDPrimitiveValue():number {
        return this.primitiveValueForKey('batchID');
    }

    // Property: concept
    set concept(value:string) {
        this.setValueForKey(value, 'concept');
    }
    get concept():string {
        return this.valueForKey('concept');
    }
    set conceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'concept');
    }
    get conceptPrimitiveValue():string {
        return this.primitiveValueForKey('concept');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: enviroment
    set enviroment(value:string) {
        this.setValueForKey(value, 'enviroment');
    }
    get enviroment():string {
        return this.valueForKey('enviroment');
    }
    set enviromentPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'enviroment');
    }
    get enviromentPrimitiveValue():string {
        return this.primitiveValueForKey('enviroment');
    }

    // Property: error
    set error(value:string) {
        this.setValueForKey(value, 'error');
    }
    get error():string {
        return this.valueForKey('error');
    }
    set errorPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'error');
    }
    get errorPrimitiveValue():string {
        return this.primitiveValueForKey('error');
    }

    // Property: integratorSyncDate
    set integratorSyncDate(value:Date) {
        this.setValueForKey(value, 'integratorSyncDate');
    }
    get integratorSyncDate():Date {
        return this.valueForKey('integratorSyncDate');
    }
    set integratorSyncDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'integratorSyncDate');
    }
    get integratorSyncDatePrimitiveValue():Date {
        return this.primitiveValueForKey('integratorSyncDate');
    }

    // Property: integratorTransactionID
    set integratorTransactionID(value:string) {
        this.setValueForKey(value, 'integratorTransactionID');
    }
    get integratorTransactionID():string {
        return this.valueForKey('integratorTransactionID');
    }
    set integratorTransactionIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'integratorTransactionID');
    }
    get integratorTransactionIDPrimitiveValue():string {
        return this.primitiveValueForKey('integratorTransactionID');
    }

    // Property: processing
    set processing(value:boolean) {
        this.setValueForKey(value, 'processing');
    }
    get processing():boolean {
        return this.valueForKey('processing');
    }
    set processingPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'processing');
    }
    get processingPrimitiveValue():boolean {
        return this.primitiveValueForKey('processing');
    }

    // Property: referenceEntityID
    set referenceEntityID(value:string) {
        this.setValueForKey(value, 'referenceEntityID');
    }
    get referenceEntityID():string {
        return this.valueForKey('referenceEntityID');
    }
    set referenceEntityIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'referenceEntityID');
    }
    get referenceEntityIDPrimitiveValue():string {
        return this.primitiveValueForKey('referenceEntityID');
    }

    // Property: referenceEntityName
    set referenceEntityName(value:string) {
        this.setValueForKey(value, 'referenceEntityName');
    }
    get referenceEntityName():string {
        return this.valueForKey('referenceEntityName');
    }
    set referenceEntityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'referenceEntityName');
    }
    get referenceEntityNamePrimitiveValue():string {
        return this.primitiveValueForKey('referenceEntityName');
    }

    // Property: responseRaw
    set responseRaw(value:string) {
        this.setValueForKey(value, 'responseRaw');
    }
    get responseRaw():string {
        return this.valueForKey('responseRaw');
    }
    set responseRawPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'responseRaw');
    }
    get responseRawPrimitiveValue():string {
        return this.primitiveValueForKey('responseRaw');
    }

    // Property: responseReference
    set responseReference(value:string) {
        this.setValueForKey(value, 'responseReference');
    }
    get responseReference():string {
        return this.valueForKey('responseReference');
    }
    set responseReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'responseReference');
    }
    get responseReferencePrimitiveValue():string {
        return this.primitiveValueForKey('responseReference');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: userInfo
    set userInfo(value:any) {
        this.setValueForKey(value, 'userInfo');
    }
    get userInfo():any {
        return this.valueForKey('userInfo');
    }
    set userInfoPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'userInfo');
    }
    get userInfoPrimitiveValue():any {
        return this.primitiveValueForKey('userInfo');
    }
    // Relationship: integratorConfig
    set integratorConfig(value:IntegratorConfig) {
        this.setValueForKey(value, 'integratorConfig');
    }
    get integratorConfig():IntegratorConfig {
        return this.valueForKey('integratorConfig') as IntegratorConfig;
    }
    // Relationship: integratorLog
    set integratorLog(value:IntegratorLog) {
        this.setValueForKey(value, 'integratorLog');
    }
    get integratorLog():IntegratorLog {
        return this.valueForKey('integratorLog') as IntegratorLog;
    }
}
