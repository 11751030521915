//
// Generated class SupportChatMessage
//

/// <reference path="SupportChatMessage_ManagedObject.ts" />

class SupportChatMessage extends SupportChatMessage_ManagedObject
{

}
