

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Booking_ManagedObject

class Booking_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: allergens
    set allergens(value:string) {
        this.setValueForKey(value, 'allergens');
    }
    get allergens():string {
        return this.valueForKey('allergens');
    }
    set allergensPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'allergens');
    }
    get allergensPrimitiveValue():string {
        return this.primitiveValueForKey('allergens');
    }

    // Property: allergies
    set allergies(value:boolean) {
        this.setValueForKey(value, 'allergies');
    }
    get allergies():boolean {
        return this.valueForKey('allergies');
    }
    set allergiesPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'allergies');
    }
    get allergiesPrimitiveValue():boolean {
        return this.primitiveValueForKey('allergies');
    }

    // Property: amountToPay
    set amountToPay(value:string) {
        this.setValueForKey(value, 'amountToPay');
    }
    get amountToPay():string {
        return this.valueForKey('amountToPay');
    }
    set amountToPayPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'amountToPay');
    }
    get amountToPayPrimitiveValue():string {
        return this.primitiveValueForKey('amountToPay');
    }

    // Property: bookingEmail
    set bookingEmail(value:string) {
        this.setValueForKey(value, 'bookingEmail');
    }
    get bookingEmail():string {
        return this.valueForKey('bookingEmail');
    }
    set bookingEmailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'bookingEmail');
    }
    get bookingEmailPrimitiveValue():string {
        return this.primitiveValueForKey('bookingEmail');
    }

    // Property: bookingIndex
    set bookingIndex(value:number) {
        this.setValueForKey(value, 'bookingIndex');
    }
    get bookingIndex():number {
        return this.valueForKey('bookingIndex');
    }
    set bookingIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'bookingIndex');
    }
    get bookingIndexPrimitiveValue():number {
        return this.primitiveValueForKey('bookingIndex');
    }

    // Property: bookingName
    set bookingName(value:string) {
        this.setValueForKey(value, 'bookingName');
    }
    get bookingName():string {
        return this.valueForKey('bookingName');
    }
    set bookingNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'bookingName');
    }
    get bookingNamePrimitiveValue():string {
        return this.primitiveValueForKey('bookingName');
    }

    // Property: bookingPhone
    set bookingPhone(value:string) {
        this.setValueForKey(value, 'bookingPhone');
    }
    get bookingPhone():string {
        return this.valueForKey('bookingPhone');
    }
    set bookingPhonePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'bookingPhone');
    }
    get bookingPhonePrimitiveValue():string {
        return this.primitiveValueForKey('bookingPhone');
    }

    // Property: bookingZoneID
    set bookingZoneID(value:string) {
        this.setValueForKey(value, 'bookingZoneID');
    }
    get bookingZoneID():string {
        return this.valueForKey('bookingZoneID');
    }
    set bookingZoneIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'bookingZoneID');
    }
    get bookingZoneIDPrimitiveValue():string {
        return this.primitiveValueForKey('bookingZoneID');
    }

    // Property: bookingZoneName
    set bookingZoneName(value:string) {
        this.setValueForKey(value, 'bookingZoneName');
    }
    get bookingZoneName():string {
        return this.valueForKey('bookingZoneName');
    }
    set bookingZoneNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'bookingZoneName');
    }
    get bookingZoneNamePrimitiveValue():string {
        return this.primitiveValueForKey('bookingZoneName');
    }

    // Property: businessAreaID
    set businessAreaID(value:string) {
        this.setValueForKey(value, 'businessAreaID');
    }
    get businessAreaID():string {
        return this.valueForKey('businessAreaID');
    }
    set businessAreaIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'businessAreaID');
    }
    get businessAreaIDPrimitiveValue():string {
        return this.primitiveValueForKey('businessAreaID');
    }

    // Property: calculatedMinimumSpend
    set calculatedMinimumSpend(value:number) {
        this.setValueForKey(value, 'calculatedMinimumSpend');
    }
    get calculatedMinimumSpend():number {
        return this.valueForKey('calculatedMinimumSpend');
    }
    set calculatedMinimumSpendPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'calculatedMinimumSpend');
    }
    get calculatedMinimumSpendPrimitiveValue():number {
        return this.primitiveValueForKey('calculatedMinimumSpend');
    }

    // Property: calculatedModifierMinimumSpend
    set calculatedModifierMinimumSpend(value:number) {
        this.setValueForKey(value, 'calculatedModifierMinimumSpend');
    }
    get calculatedModifierMinimumSpend():number {
        return this.valueForKey('calculatedModifierMinimumSpend');
    }
    set calculatedModifierMinimumSpendPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'calculatedModifierMinimumSpend');
    }
    get calculatedModifierMinimumSpendPrimitiveValue():number {
        return this.primitiveValueForKey('calculatedModifierMinimumSpend');
    }

    // Property: calculatedModifierPrice
    set calculatedModifierPrice(value:number) {
        this.setValueForKey(value, 'calculatedModifierPrice');
    }
    get calculatedModifierPrice():number {
        return this.valueForKey('calculatedModifierPrice');
    }
    set calculatedModifierPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'calculatedModifierPrice');
    }
    get calculatedModifierPricePrimitiveValue():number {
        return this.primitiveValueForKey('calculatedModifierPrice');
    }

    // Property: calculatedPrice
    set calculatedPrice(value:number) {
        this.setValueForKey(value, 'calculatedPrice');
    }
    get calculatedPrice():number {
        return this.valueForKey('calculatedPrice');
    }
    set calculatedPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'calculatedPrice');
    }
    get calculatedPricePrimitiveValue():number {
        return this.primitiveValueForKey('calculatedPrice');
    }

    // Property: cancelledDate
    set cancelledDate(value:Date) {
        this.setValueForKey(value, 'cancelledDate');
    }
    get cancelledDate():Date {
        return this.valueForKey('cancelledDate');
    }
    set cancelledDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'cancelledDate');
    }
    get cancelledDatePrimitiveValue():Date {
        return this.primitiveValueForKey('cancelledDate');
    }

    // Property: channelID
    set channelID(value:string) {
        this.setValueForKey(value, 'channelID');
    }
    get channelID():string {
        return this.valueForKey('channelID');
    }
    set channelIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'channelID');
    }
    get channelIDPrimitiveValue():string {
        return this.primitiveValueForKey('channelID');
    }

    // Property: channelName
    set channelName(value:string) {
        this.setValueForKey(value, 'channelName');
    }
    get channelName():string {
        return this.valueForKey('channelName');
    }
    set channelNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'channelName');
    }
    get channelNamePrimitiveValue():string {
        return this.primitiveValueForKey('channelName');
    }

    // Property: chargeOnConfirm
    set chargeOnConfirm(value:boolean) {
        this.setValueForKey(value, 'chargeOnConfirm');
    }
    get chargeOnConfirm():boolean {
        return this.valueForKey('chargeOnConfirm');
    }
    set chargeOnConfirmPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'chargeOnConfirm');
    }
    get chargeOnConfirmPrimitiveValue():boolean {
        return this.primitiveValueForKey('chargeOnConfirm');
    }

    // Property: clientCity
    set clientCity(value:string) {
        this.setValueForKey(value, 'clientCity');
    }
    get clientCity():string {
        return this.valueForKey('clientCity');
    }
    set clientCityPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientCity');
    }
    get clientCityPrimitiveValue():string {
        return this.primitiveValueForKey('clientCity');
    }

    // Property: clientComments
    set clientComments(value:string) {
        this.setValueForKey(value, 'clientComments');
    }
    get clientComments():string {
        return this.valueForKey('clientComments');
    }
    set clientCommentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientComments');
    }
    get clientCommentsPrimitiveValue():string {
        return this.primitiveValueForKey('clientComments');
    }

    // Property: clientCountry
    set clientCountry(value:string) {
        this.setValueForKey(value, 'clientCountry');
    }
    get clientCountry():string {
        return this.valueForKey('clientCountry');
    }
    set clientCountryPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientCountry');
    }
    get clientCountryPrimitiveValue():string {
        return this.primitiveValueForKey('clientCountry');
    }

    // Property: clientEmail
    set clientEmail(value:string) {
        this.setValueForKey(value, 'clientEmail');
    }
    get clientEmail():string {
        return this.valueForKey('clientEmail');
    }
    set clientEmailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientEmail');
    }
    get clientEmailPrimitiveValue():string {
        return this.primitiveValueForKey('clientEmail');
    }

    // Property: clientName
    set clientName(value:string) {
        this.setValueForKey(value, 'clientName');
    }
    get clientName():string {
        return this.valueForKey('clientName');
    }
    set clientNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientName');
    }
    get clientNamePrimitiveValue():string {
        return this.primitiveValueForKey('clientName');
    }

    // Property: clientPhone
    set clientPhone(value:string) {
        this.setValueForKey(value, 'clientPhone');
    }
    get clientPhone():string {
        return this.valueForKey('clientPhone');
    }
    set clientPhonePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientPhone');
    }
    get clientPhonePrimitiveValue():string {
        return this.primitiveValueForKey('clientPhone');
    }

    // Property: clientPhone2
    set clientPhone2(value:string) {
        this.setValueForKey(value, 'clientPhone2');
    }
    get clientPhone2():string {
        return this.valueForKey('clientPhone2');
    }
    set clientPhone2PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientPhone2');
    }
    get clientPhone2PrimitiveValue():string {
        return this.primitiveValueForKey('clientPhone2');
    }

    // Property: clientPostalCode
    set clientPostalCode(value:string) {
        this.setValueForKey(value, 'clientPostalCode');
    }
    get clientPostalCode():string {
        return this.valueForKey('clientPostalCode');
    }
    set clientPostalCodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientPostalCode');
    }
    get clientPostalCodePrimitiveValue():string {
        return this.primitiveValueForKey('clientPostalCode');
    }

    // Property: colorB
    set colorB(value:number) {
        this.setValueForKey(value, 'colorB');
    }
    get colorB():number {
        return this.valueForKey('colorB');
    }
    set colorBPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'colorB');
    }
    get colorBPrimitiveValue():number {
        return this.primitiveValueForKey('colorB');
    }

    // Property: colorG
    set colorG(value:number) {
        this.setValueForKey(value, 'colorG');
    }
    get colorG():number {
        return this.valueForKey('colorG');
    }
    set colorGPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'colorG');
    }
    get colorGPrimitiveValue():number {
        return this.primitiveValueForKey('colorG');
    }

    // Property: colorIndex
    set colorIndex(value:number) {
        this.setValueForKey(value, 'colorIndex');
    }
    get colorIndex():number {
        return this.valueForKey('colorIndex');
    }
    set colorIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'colorIndex');
    }
    get colorIndexPrimitiveValue():number {
        return this.primitiveValueForKey('colorIndex');
    }

    // Property: colorR
    set colorR(value:number) {
        this.setValueForKey(value, 'colorR');
    }
    get colorR():number {
        return this.valueForKey('colorR');
    }
    set colorRPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'colorR');
    }
    get colorRPrimitiveValue():number {
        return this.primitiveValueForKey('colorR');
    }

    // Property: comments
    set comments(value:string) {
        this.setValueForKey(value, 'comments');
    }
    get comments():string {
        return this.valueForKey('comments');
    }
    set commentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'comments');
    }
    get commentsPrimitiveValue():string {
        return this.primitiveValueForKey('comments');
    }

    // Property: confirmationEmail
    set confirmationEmail(value:boolean) {
        this.setValueForKey(value, 'confirmationEmail');
    }
    get confirmationEmail():boolean {
        return this.valueForKey('confirmationEmail');
    }
    set confirmationEmailPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'confirmationEmail');
    }
    get confirmationEmailPrimitiveValue():boolean {
        return this.primitiveValueForKey('confirmationEmail');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: dateShow
    set dateShow(value:Date) {
        this.setValueForKey(value, 'dateShow');
    }
    get dateShow():Date {
        return this.valueForKey('dateShow');
    }
    set dateShowPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'dateShow');
    }
    get dateShowPrimitiveValue():Date {
        return this.primitiveValueForKey('dateShow');
    }

    // Property: day
    set day(value:string) {
        this.setValueForKey(value, 'day');
    }
    get day():string {
        return this.valueForKey('day');
    }
    set dayPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'day');
    }
    get dayPrimitiveValue():string {
        return this.primitiveValueForKey('day');
    }

    // Property: deposit
    set deposit(value:number) {
        this.setValueForKey(value, 'deposit');
    }
    get deposit():number {
        return this.valueForKey('deposit');
    }
    set depositPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'deposit');
    }
    get depositPrimitiveValue():number {
        return this.primitiveValueForKey('deposit');
    }

    // Property: duration
    set duration(value:number) {
        this.setValueForKey(value, 'duration');
    }
    get duration():number {
        return this.valueForKey('duration');
    }
    set durationPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'duration');
    }
    get durationPrimitiveValue():number {
        return this.primitiveValueForKey('duration');
    }

    // Property: eventName
    set eventName(value:string) {
        this.setValueForKey(value, 'eventName');
    }
    get eventName():string {
        return this.valueForKey('eventName');
    }
    set eventNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'eventName');
    }
    get eventNamePrimitiveValue():string {
        return this.primitiveValueForKey('eventName');
    }

    // Property: externalReference
    set externalReference(value:string) {
        this.setValueForKey(value, 'externalReference');
    }
    get externalReference():string {
        return this.valueForKey('externalReference');
    }
    set externalReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'externalReference');
    }
    get externalReferencePrimitiveValue():string {
        return this.primitiveValueForKey('externalReference');
    }

    // Property: hasPaymentToken
    set hasPaymentToken(value:boolean) {
        this.setValueForKey(value, 'hasPaymentToken');
    }
    get hasPaymentToken():boolean {
        return this.valueForKey('hasPaymentToken');
    }
    set hasPaymentTokenPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'hasPaymentToken');
    }
    get hasPaymentTokenPrimitiveValue():boolean {
        return this.primitiveValueForKey('hasPaymentToken');
    }

    // Property: hour
    set hour(value:string) {
        this.setValueForKey(value, 'hour');
    }
    get hour():string {
        return this.valueForKey('hour');
    }
    set hourPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'hour');
    }
    get hourPrimitiveValue():string {
        return this.primitiveValueForKey('hour');
    }

    // Property: information
    set information(value:string) {
        this.setValueForKey(value, 'information');
    }
    get information():string {
        return this.valueForKey('information');
    }
    set informationPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'information');
    }
    get informationPrimitiveValue():string {
        return this.primitiveValueForKey('information');
    }

    // Property: isWalkIn
    set isWalkIn(value:boolean) {
        this.setValueForKey(value, 'isWalkIn');
    }
    get isWalkIn():boolean {
        return this.valueForKey('isWalkIn');
    }
    set isWalkInPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isWalkIn');
    }
    get isWalkInPrimitiveValue():boolean {
        return this.primitiveValueForKey('isWalkIn');
    }

    // Property: language
    set language(value:string) {
        this.setValueForKey(value, 'language');
    }
    get language():string {
        return this.valueForKey('language');
    }
    set languagePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'language');
    }
    get languagePrimitiveValue():string {
        return this.primitiveValueForKey('language');
    }

    // Property: lastUpdateDate
    set lastUpdateDate(value:Date) {
        this.setValueForKey(value, 'lastUpdateDate');
    }
    get lastUpdateDate():Date {
        return this.valueForKey('lastUpdateDate');
    }
    set lastUpdateDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'lastUpdateDate');
    }
    get lastUpdateDatePrimitiveValue():Date {
        return this.primitiveValueForKey('lastUpdateDate');
    }

    // Property: minSpend
    set minSpend(value:number) {
        this.setValueForKey(value, 'minSpend');
    }
    get minSpend():number {
        return this.valueForKey('minSpend');
    }
    set minSpendPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minSpend');
    }
    get minSpendPrimitiveValue():number {
        return this.primitiveValueForKey('minSpend');
    }

    // Property: nationalities
    set nationalities(value:string) {
        this.setValueForKey(value, 'nationalities');
    }
    get nationalities():string {
        return this.valueForKey('nationalities');
    }
    set nationalitiesPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'nationalities');
    }
    get nationalitiesPrimitiveValue():string {
        return this.primitiveValueForKey('nationalities');
    }

    // Property: notifyMessage
    set notifyMessage(value:string) {
        this.setValueForKey(value, 'notifyMessage');
    }
    get notifyMessage():string {
        return this.valueForKey('notifyMessage');
    }
    set notifyMessagePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'notifyMessage');
    }
    get notifyMessagePrimitiveValue():string {
        return this.primitiveValueForKey('notifyMessage');
    }

    // Property: origin
    set origin(value:number) {
        this.setValueForKey(value, 'origin');
    }
    get origin():number {
        return this.valueForKey('origin');
    }
    set originPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'origin');
    }
    get originPrimitiveValue():number {
        return this.primitiveValueForKey('origin');
    }

    // Property: owner
    set owner(value:string) {
        this.setValueForKey(value, 'owner');
    }
    get owner():string {
        return this.valueForKey('owner');
    }
    set ownerPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'owner');
    }
    get ownerPrimitiveValue():string {
        return this.primitiveValueForKey('owner');
    }

    // Property: pax
    set pax(value:number) {
        this.setValueForKey(value, 'pax');
    }
    get pax():number {
        return this.valueForKey('pax');
    }
    set paxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'pax');
    }
    get paxPrimitiveValue():number {
        return this.primitiveValueForKey('pax');
    }

    // Property: paxAttend
    set paxAttend(value:number) {
        this.setValueForKey(value, 'paxAttend');
    }
    get paxAttend():number {
        return this.valueForKey('paxAttend');
    }
    set paxAttendPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'paxAttend');
    }
    get paxAttendPrimitiveValue():number {
        return this.primitiveValueForKey('paxAttend');
    }

    // Property: paxInvited
    set paxInvited(value:number) {
        this.setValueForKey(value, 'paxInvited');
    }
    get paxInvited():number {
        return this.valueForKey('paxInvited');
    }
    set paxInvitedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'paxInvited');
    }
    get paxInvitedPrimitiveValue():number {
        return this.primitiveValueForKey('paxInvited');
    }

    // Property: paymentCurrency
    set paymentCurrency(value:string) {
        this.setValueForKey(value, 'paymentCurrency');
    }
    get paymentCurrency():string {
        return this.valueForKey('paymentCurrency');
    }
    set paymentCurrencyPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'paymentCurrency');
    }
    get paymentCurrencyPrimitiveValue():string {
        return this.primitiveValueForKey('paymentCurrency');
    }

    // Property: paymentPeriodInMinutes
    set paymentPeriodInMinutes(value:number) {
        this.setValueForKey(value, 'paymentPeriodInMinutes');
    }
    get paymentPeriodInMinutes():number {
        return this.valueForKey('paymentPeriodInMinutes');
    }
    set paymentPeriodInMinutesPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'paymentPeriodInMinutes');
    }
    get paymentPeriodInMinutesPrimitiveValue():number {
        return this.primitiveValueForKey('paymentPeriodInMinutes');
    }

    // Property: paymentPeriodStartDate
    set paymentPeriodStartDate(value:Date) {
        this.setValueForKey(value, 'paymentPeriodStartDate');
    }
    get paymentPeriodStartDate():Date {
        return this.valueForKey('paymentPeriodStartDate');
    }
    set paymentPeriodStartDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'paymentPeriodStartDate');
    }
    get paymentPeriodStartDatePrimitiveValue():Date {
        return this.primitiveValueForKey('paymentPeriodStartDate');
    }

    // Property: penalizedImport
    set penalizedImport(value:number) {
        this.setValueForKey(value, 'penalizedImport');
    }
    get penalizedImport():number {
        return this.valueForKey('penalizedImport');
    }
    set penalizedImportPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'penalizedImport');
    }
    get penalizedImportPrimitiveValue():number {
        return this.primitiveValueForKey('penalizedImport');
    }

    // Property: preferedDays
    set preferedDays(value:string) {
        this.setValueForKey(value, 'preferedDays');
    }
    get preferedDays():string {
        return this.valueForKey('preferedDays');
    }
    set preferedDaysPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'preferedDays');
    }
    get preferedDaysPrimitiveValue():string {
        return this.primitiveValueForKey('preferedDays');
    }

    // Property: prescriptorName
    set prescriptorName(value:string) {
        this.setValueForKey(value, 'prescriptorName');
    }
    get prescriptorName():string {
        return this.valueForKey('prescriptorName');
    }
    set prescriptorNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'prescriptorName');
    }
    get prescriptorNamePrimitiveValue():string {
        return this.primitiveValueForKey('prescriptorName');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: privateComments
    set privateComments(value:string) {
        this.setValueForKey(value, 'privateComments');
    }
    get privateComments():string {
        return this.valueForKey('privateComments');
    }
    set privateCommentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'privateComments');
    }
    get privateCommentsPrimitiveValue():string {
        return this.primitiveValueForKey('privateComments');
    }

    // Property: recommendationName
    set recommendationName(value:string) {
        this.setValueForKey(value, 'recommendationName');
    }
    get recommendationName():string {
        return this.valueForKey('recommendationName');
    }
    set recommendationNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'recommendationName');
    }
    get recommendationNamePrimitiveValue():string {
        return this.primitiveValueForKey('recommendationName');
    }

    // Property: requestedStatus
    set requestedStatus(value:number) {
        this.setValueForKey(value, 'requestedStatus');
    }
    get requestedStatus():number {
        return this.valueForKey('requestedStatus');
    }
    set requestedStatusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'requestedStatus');
    }
    get requestedStatusPrimitiveValue():number {
        return this.primitiveValueForKey('requestedStatus');
    }

    // Property: sendEmail
    set sendEmail(value:number) {
        this.setValueForKey(value, 'sendEmail');
    }
    get sendEmail():number {
        return this.valueForKey('sendEmail');
    }
    set sendEmailPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'sendEmail');
    }
    get sendEmailPrimitiveValue():number {
        return this.primitiveValueForKey('sendEmail');
    }

    // Property: showTaxi
    set showTaxi(value:boolean) {
        this.setValueForKey(value, 'showTaxi');
    }
    get showTaxi():boolean {
        return this.valueForKey('showTaxi');
    }
    set showTaxiPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'showTaxi');
    }
    get showTaxiPrimitiveValue():boolean {
        return this.primitiveValueForKey('showTaxi');
    }

    // Property: sourceEmail
    set sourceEmail(value:string) {
        this.setValueForKey(value, 'sourceEmail');
    }
    get sourceEmail():string {
        return this.valueForKey('sourceEmail');
    }
    set sourceEmailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceEmail');
    }
    get sourceEmailPrimitiveValue():string {
        return this.primitiveValueForKey('sourceEmail');
    }

    // Property: sourceName
    set sourceName(value:string) {
        this.setValueForKey(value, 'sourceName');
    }
    get sourceName():string {
        return this.valueForKey('sourceName');
    }
    set sourceNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceName');
    }
    get sourceNamePrimitiveValue():string {
        return this.primitiveValueForKey('sourceName');
    }

    // Property: sourcePhone
    set sourcePhone(value:string) {
        this.setValueForKey(value, 'sourcePhone');
    }
    get sourcePhone():string {
        return this.valueForKey('sourcePhone');
    }
    set sourcePhonePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourcePhone');
    }
    get sourcePhonePrimitiveValue():string {
        return this.primitiveValueForKey('sourcePhone');
    }

    // Property: spokenLanguages
    set spokenLanguages(value:string) {
        this.setValueForKey(value, 'spokenLanguages');
    }
    get spokenLanguages():string {
        return this.valueForKey('spokenLanguages');
    }
    set spokenLanguagesPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'spokenLanguages');
    }
    get spokenLanguagesPrimitiveValue():string {
        return this.primitiveValueForKey('spokenLanguages');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: totalAmountToPay
    set totalAmountToPay(value:number) {
        this.setValueForKey(value, 'totalAmountToPay');
    }
    get totalAmountToPay():number {
        return this.valueForKey('totalAmountToPay');
    }
    set totalAmountToPayPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalAmountToPay');
    }
    get totalAmountToPayPrimitiveValue():number {
        return this.primitiveValueForKey('totalAmountToPay');
    }

    // Property: workerID
    set workerID(value:string) {
        this.setValueForKey(value, 'workerID');
    }
    get workerID():string {
        return this.valueForKey('workerID');
    }
    set workerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerID');
    }
    get workerIDPrimitiveValue():string {
        return this.primitiveValueForKey('workerID');
    }

    // Property: workerName
    set workerName(value:string) {
        this.setValueForKey(value, 'workerName');
    }
    get workerName():string {
        return this.valueForKey('workerName');
    }
    set workerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerName');
    }
    get workerNamePrimitiveValue():string {
        return this.primitiveValueForKey('workerName');
    }
    // Relationship: businessArea
    set businessArea(value:BusinessArea) {
        this.setValueForKey(value, 'businessArea');
    }
    get businessArea():BusinessArea {
        return this.valueForKey('businessArea') as BusinessArea;
    }
    // Relationship: client
    set client(value:Client) {
        this.setValueForKey(value, 'client');
    }
    get client():Client {
        return this.valueForKey('client') as Client;
    }
    // Relationship: event
    set event(value:VenueEvent) {
        this.setValueForKey(value, 'event');
    }
    get event():VenueEvent {
        return this.valueForKey('event') as VenueEvent;
    }
    // Relationship: group
    set group(value:BookingGroup) {
        this.setValueForKey(value, 'group');
    }
    get group():BookingGroup {
        return this.valueForKey('group') as BookingGroup;
    }

    // Relationship: locations
    protected _locations:MIOManagedObjectSet = null;
    get locations():MIOManagedObjectSet {
        return this.valueForKey('locations');
    }
    addLocationsObject(value:Booking_BookingLocation) {
        this._addObjectForKey(value, 'locations');
    }
    removeLocationsObject(value:Booking_BookingLocation) {
        this._removeObjectForKey(value, 'locations');
    }

    // Relationship: modifiers
    protected _modifiers:MIOManagedObjectSet = null;
    get modifiers():MIOManagedObjectSet {
        return this.valueForKey('modifiers');
    }
    addModifiersObject(value:Booking_BookingModifier) {
        this._addObjectForKey(value, 'modifiers');
    }
    removeModifiersObject(value:Booking_BookingModifier) {
        this._removeObjectForKey(value, 'modifiers');
    }
    // Relationship: order
    set order(value:Order) {
        this.setValueForKey(value, 'order');
    }
    get order():Order {
        return this.valueForKey('order') as Order;
    }
    // Relationship: prescriptor
    set prescriptor(value:BookingPrescriptor) {
        this.setValueForKey(value, 'prescriptor');
    }
    get prescriptor():BookingPrescriptor {
        return this.valueForKey('prescriptor') as BookingPrescriptor;
    }
    // Relationship: recommendation
    set recommendation(value:BookingRecommendation) {
        this.setValueForKey(value, 'recommendation');
    }
    get recommendation():BookingRecommendation {
        return this.valueForKey('recommendation') as BookingRecommendation;
    }

    // Relationship: services
    protected _services:MIOManagedObjectSet = null;
    get services():MIOManagedObjectSet {
        return this.valueForKey('services');
    }
    addServicesObject(value:Booking_BookingService) {
        this._addObjectForKey(value, 'services');
    }
    removeServicesObject(value:Booking_BookingService) {
        this._removeObjectForKey(value, 'services');
    }
    // Relationship: source
    set source(value:BookingSource) {
        this.setValueForKey(value, 'source');
    }
    get source():BookingSource {
        return this.valueForKey('source') as BookingSource;
    }

    // Relationship: workers
    protected _workers:MIOManagedObjectSet = null;
    get workers():MIOManagedObjectSet {
        return this.valueForKey('workers');
    }
    addWorkersObject(value:Booking_BookingWorker) {
        this._addObjectForKey(value, 'workers');
    }
    removeWorkersObject(value:Booking_BookingWorker) {
        this._removeObjectForKey(value, 'workers');
    }
}
