

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class EventTicket_ManagedObject

class EventTicket_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: beginDate
    set beginDate(value:Date) {
        this.setValueForKey(value, 'beginDate');
    }
    get beginDate():Date {
        return this.valueForKey('beginDate');
    }
    set beginDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'beginDate');
    }
    get beginDatePrimitiveValue():Date {
        return this.primitiveValueForKey('beginDate');
    }

    // Property: businessAreaName
    set businessAreaName(value:string) {
        this.setValueForKey(value, 'businessAreaName');
    }
    get businessAreaName():string {
        return this.valueForKey('businessAreaName');
    }
    set businessAreaNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'businessAreaName');
    }
    get businessAreaNamePrimitiveValue():string {
        return this.primitiveValueForKey('businessAreaName');
    }

    // Property: channelName
    set channelName(value:string) {
        this.setValueForKey(value, 'channelName');
    }
    get channelName():string {
        return this.valueForKey('channelName');
    }
    set channelNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'channelName');
    }
    get channelNamePrimitiveValue():string {
        return this.primitiveValueForKey('channelName');
    }

    // Property: code
    set code(value:string) {
        this.setValueForKey(value, 'code');
    }
    get code():string {
        return this.valueForKey('code');
    }
    set codePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'code');
    }
    get codePrimitiveValue():string {
        return this.primitiveValueForKey('code');
    }

    // Property: consumptionDate
    set consumptionDate(value:Date) {
        this.setValueForKey(value, 'consumptionDate');
    }
    get consumptionDate():Date {
        return this.valueForKey('consumptionDate');
    }
    set consumptionDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'consumptionDate');
    }
    get consumptionDatePrimitiveValue():Date {
        return this.primitiveValueForKey('consumptionDate');
    }

    // Property: email
    set email(value:string) {
        this.setValueForKey(value, 'email');
    }
    get email():string {
        return this.valueForKey('email');
    }
    set emailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'email');
    }
    get emailPrimitiveValue():string {
        return this.primitiveValueForKey('email');
    }

    // Property: endDate
    set endDate(value:Date) {
        this.setValueForKey(value, 'endDate');
    }
    get endDate():Date {
        return this.valueForKey('endDate');
    }
    set endDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'endDate');
    }
    get endDatePrimitiveValue():Date {
        return this.primitiveValueForKey('endDate');
    }

    // Property: eventName
    set eventName(value:string) {
        this.setValueForKey(value, 'eventName');
    }
    get eventName():string {
        return this.valueForKey('eventName');
    }
    set eventNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'eventName');
    }
    get eventNamePrimitiveValue():string {
        return this.primitiveValueForKey('eventName');
    }

    // Property: externalReference
    set externalReference(value:string) {
        this.setValueForKey(value, 'externalReference');
    }
    get externalReference():string {
        return this.valueForKey('externalReference');
    }
    set externalReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'externalReference');
    }
    get externalReferencePrimitiveValue():string {
        return this.primitiveValueForKey('externalReference');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: offerName
    set offerName(value:string) {
        this.setValueForKey(value, 'offerName');
    }
    get offerName():string {
        return this.valueForKey('offerName');
    }
    set offerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'offerName');
    }
    get offerNamePrimitiveValue():string {
        return this.primitiveValueForKey('offerName');
    }

    // Property: offerQuantity
    set offerQuantity(value:number) {
        this.setValueForKey(value, 'offerQuantity');
    }
    get offerQuantity():number {
        return this.valueForKey('offerQuantity');
    }
    set offerQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'offerQuantity');
    }
    get offerQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('offerQuantity');
    }

    // Property: phone
    set phone(value:string) {
        this.setValueForKey(value, 'phone');
    }
    get phone():string {
        return this.valueForKey('phone');
    }
    set phonePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'phone');
    }
    get phonePrimitiveValue():string {
        return this.primitiveValueForKey('phone');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: sourceName
    set sourceName(value:string) {
        this.setValueForKey(value, 'sourceName');
    }
    get sourceName():string {
        return this.valueForKey('sourceName');
    }
    set sourceNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceName');
    }
    get sourceNamePrimitiveValue():string {
        return this.primitiveValueForKey('sourceName');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }
    // Relationship: businessArea
    set businessArea(value:BusinessArea) {
        this.setValueForKey(value, 'businessArea');
    }
    get businessArea():BusinessArea {
        return this.valueForKey('businessArea') as BusinessArea;
    }
    // Relationship: channel
    set channel(value:TicketChannel) {
        this.setValueForKey(value, 'channel');
    }
    get channel():TicketChannel {
        return this.valueForKey('channel') as TicketChannel;
    }
    // Relationship: event
    set event(value:VenueEvent) {
        this.setValueForKey(value, 'event');
    }
    get event():VenueEvent {
        return this.valueForKey('event') as VenueEvent;
    }
    // Relationship: offer
    set offer(value:Offer) {
        this.setValueForKey(value, 'offer');
    }
    get offer():Offer {
        return this.valueForKey('offer') as Offer;
    }
    // Relationship: source
    set source(value:BookingSource) {
        this.setValueForKey(value, 'source');
    }
    get source():BookingSource {
        return this.valueForKey('source') as BookingSource;
    }
}
