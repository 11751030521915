//
// Generated class RoomBookingGroup
//

/// <reference path="RoomBookingGroup_ManagedObject.ts" />

class RoomBookingGroup extends RoomBookingGroup_ManagedObject
{

}
