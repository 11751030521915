//
// Generated class BankLoan
//

/// <reference path="BankLoan_ManagedObject.ts" />

class BankLoan extends BankLoan_ManagedObject
{

}
