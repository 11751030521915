class DBCardsCoder extends DBXLSXCoder
{
    mainEntity() {
        return { entity: "Card", predicateFormat: "deletedAt == null" }
    }

    columns() : any[] {
        let cols = [
            { title:"CARD ID", width: "10%", align: "center" },
            // { title:"CARD NAME", width: "10%", align: "center" },
            { title:"IS AVAILABLE", width: "10%", align: "center" },
            { title:"INVITATION CONCEPT", width: "10%", align: "center" },
            { title:"ENABLE ACCOUNTS", width: "10%", align: "center" },
            { title:"CODE", width: "20%", align: "center" },
            { title:"EFFECTIVE DATE", width: "20%", align: "center" },
            { title:"EXPIRATION DATE", width: "20%", align: "center" },            
            { title:"LEGAL ENTITY ID", width: "20%", align: "center" },
            { title:"LEGAL ENTITY NAME", width: "20%", align: "center" },
            { title:"LOYALTY AMOUNT", width: "20%", align: "center", formatter: this.ad.currencyFormatter },
        ]
        return cols;
    }

    protected aditionalImportEntities() { 
        return [
            { entity: "LegalEntity", predicateFormat: "deletedAt == null" }
        ]; 
    }

    protected importRow(row:any){
                
        let cardID            = row["CARD ID"];
        let cardName          = row["CARD NAME"];        
        let isAvailable       = row["IS AVAILABLE"];
        let invitationConcept = row["INVITATION CONCEPT"];
        let enableAccounts    = row["ENABLE ACCOUNTS"];
        let loyaltyAmount     = row["LOYALTY AMOUNT"];
        let code              = row["CODE"];
        let effectiveDate     = row["EFFECTIVE DATE"];
        let expritationDate   = row["EXPIRATION DATE"];
        let legalEntityID     = row["LEGAL ENTITY ID"];
        let legalEntityName   = row["LEGAL ENTITY NAME"];

 
        if (code == null) { return; }

        let legalEntity = this.queryEntityByIDOrName("LegalEntity", legalEntityID, legalEntityName) as LegalEntity;

        let now = new Date();

        let card = this.queryEntityByIDOrName("Card", cardID, cardName) as Card;
        if (card == null) {        
            card = MIOEntityDescription.insertNewObjectForEntityForName("Card", DBHelper.mainManagedObjectContext) as Card;
            card.identifier = this.parseOrCreateIdentifier(cardID);
            this.appendObject(card);

            card.date = now;
            // account line
            if (loyaltyAmount != null) {
                let line = MIOEntityDescription.insertNewObjectForEntityForName("LoyaltyAccountLine", DBHelper.mainManagedObjectContext) as LoyaltyAccountLine;
                line.value = loyaltyAmount;
                line.date = now;
                line.concept = "GENERATE CARD WITH " + String(loyaltyAmount);
                line.economicEntity = card;
            }
        }  
        
        // card.name
        card.isAvailable = this.parseBoolValue( isAvailable );
        card.invitationConcept = invitationConcept;
        card.enableCardAccounts = this.parseBoolValue( enableAccounts );
        card.barcode = code;
        card.effectiveDate = this.parseDateValue( effectiveDate );
        card.expirationDate = this.parseDateValue( expritationDate );
        card.legalEntity = legalEntity;

        MIOLog("ADDING WORKER: " + this.rowIndex + "/" + this.rows.length + ": " + code);
    }

    protected exportTitle() : string { return "Cards"; }

    protected exportSortDescriptors() { 
        return [MIOSortDescriptor.sortDescriptorWithKey("name", false)]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let card = object as Card;

        let item = {
            "CARD ID": card.identifier,
            "IS AVAILABLE": card.isAvailable,
            "INVITATION CONCEPT": card.invitationConcept,
            "ENABLE ACCOUNTS": card.enableCardAccounts,
            "CODE": card.barcode,
            "EFFECTIVE DATE": card.effectiveDate,
            "EXPIRATION DATE": card.expirationDate,
            "LEGAL ENTITY ID": card.legalEntity?.identifier,
            "LEGAL ENTITY NAME": card.legalEntity?.name    
        }

        return item;
    }
}

