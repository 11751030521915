//
// Generated class IntegratorMappingTargetEntity
//

/// <reference path="IntegratorMappingTargetEntity_ManagedObject.ts" />

class IntegratorMappingTargetEntity extends IntegratorMappingTargetEntity_ManagedObject
{

}
