

class WorkersPermissionsListNameCell extends MUIReportTableViewCell
{
    private titleLabel:MUILabel = null;

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
    }

    setItem(item:Employee, colIndex){
        if (colIndex == 0) this.titleLabel.text = item.name;
    }
}