//
// Generated class LegalEntityPaymentIntallment
//

/// <reference path="LegalEntityPaymentIntallment_ManagedObject.ts" />

class LegalEntityPaymentIntallment extends LegalEntityPaymentIntallment_ManagedObject
{

}
