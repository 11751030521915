/**
 * Created by miguel on 6/2/17.
 */

class ProductModifiersCategoryViewController extends EntityListViewController
{
    delegate = null;
    
    private backButton:MUIButton = null;
    private closeButton:MUIButton = null;
    private addButton:MUIButton = null;
    private titleLabel:MUILabel = null;
    
    private moc:MIOManagedObjectContext = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

    get preferredContentSize(){
        return new MIOSize(400, 650);
    }

    viewDidLoad(){
        super.viewDidLoad();
        
        this.backButton = MUIOutlet(this, 'back-btn', 'MUIButton');
        this.backButton.setAction(this, function (){            
            this.navigationController.popViewController(true);
        });        

        this.closeButton = MUIOutlet(this, 'close-btn', 'MUIButton');
        this.closeButton.setAction(this, function (){
            this.moc.save();            
            this.dismissViewController(true);            
        });

        this.titleLabel = MUIOutlet(this,"title-lbl","MUILabel");
        this.titleLabel.text = MIOLocalizeString("MODIFIER CATEGORIES", "Modifier categories");

        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setHidden(true);        
        
        this.searchTextField = MUIOutlet(this, 'search-bar','MUITextField');
        this.searchTextField.setOnChangeText(this, function(textfield:MUITextField, value:string){
            this.searchString = value.length > 0 ? value : null;

            if (this.searchTimer != null) this.searchTimer.invalidate();            
            this.searchTimer = MIOTimer.scheduledTimerWithTimeInterval(500, false, this, function(timer:MIOTimer){
                this.fetchedResultsController = null;
                this.tableView.reloadData(); 
            });
        });

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;                
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private modifiersCategories:MIOManagedObjectSet = null;    
    set modifiers(value){        
        this.modifiersCategories = value;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this.modifiersCategories == null) return;

        this.backButton.hidden = this.navigationController ? false : true;
        this.closeButton.hidden = this.navigationController ? true : false;

        this.fetchedResultsController = null;
        this.tableView.reloadData();        
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("ModifierCategoryCell") as ProductModifierCategoryCell;

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ProductModifierCategory;
        cell.item = item;        

        let result = this.modifiersCategories.filterWithPredicate(MIOPredicate.predicateWithFormat("identifier == " + item.identifier));        
        cell.setAccessoryType(result.length <= 0 ? UITableViewCellAccessoryType.None : UITableViewCellAccessoryType.Checkmark);

        cell.selectionStyle = UITableViewCellSelectionStyle.None;
        return cell;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        let modifiercategory:ProductModifierCategory = this.fetchedResultsController.objectAtIndexPath(indexPath);

        let filtered = this.modifiersCategories.filterWithPredicate(MIOPredicate.predicateWithFormat("identifier == " + modifiercategory.identifier));
        
        if(filtered.length == 0) {                                   
            this.tableView.cellAtIndexPath(indexPath).setAccessoryType(MUITableViewCellAccessoryType.Checkmark);            
            if (this.delegate != null) this.delegate.modifierCategoryDidSelect(modifiercategory);            
            // this.modifiersCategories.addObject(modifiercategory);
        }
        else {            
            this.tableView.cellAtIndexPath(indexPath).setAccessoryType(MUITableViewCellAccessoryType.None);
            if (this.delegate != null) this.delegate.modifierCategoryDidDeselect(modifiercategory);
            // this.modifiersCategories.removeObject(modifiercategory);
        }    
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

        let filterString = null;
        if (this.searchString != null && this.searchString.length > 0)
            filterString = "name CONTAINS '" + this.searchString + "'";

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("ProductModifierCategory", sortDescriptors, filterString);
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, this.moc, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller){
        this.tableView.reloadData(); 
    }
}