class SummaryNoteLineCell extends MUITableViewCell
{
    unitLabel = null;
    quantityLabel   = null;
    nameLabel  = null;
    _statusBarView  = null;

    private _separator = null;
    
    awakeFromHTML()
    {
        this.nameLabel = MUIOutlet(this, 'sedv_tv_product_lbl','MUILabel');
        this.unitLabel   = MUIOutlet(this, 'sedv_tv_unity_lbl', 'MUILabel');
        this.quantityLabel = MUIOutlet(this, 'sedv_tv_quantity_title','MUILabel');
        this._separator  = MUIOutlet(this, 'sedv_tv_separator', 'MUIView');
    }

    set item(i)
    {            
        //this.titleLabel.text = 'Nº '  + (i.prefix ? i.prefix + '-' + i.noteId : i.noteId);
        this.nameLabel.text = i.productName;
        this.unitLabel.text = MeasureUnits.toString(i.measureUnitId);
        this.quantityLabel.text = i.countQuantity;
    }
}
