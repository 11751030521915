


class SettingsFormatCell extends MUITableViewCell
{
    private nameTextField:MUITextField = null;    
    
    awakeFromHTML(){
        this.nameTextField = MUIOutlet(this, 'name-tf', 'MUITextField');
        this.nameTextField.setOnChangeText(this, function(textfield:MUITextField, value:string){
            this._item.name = value;
        });        
    }

    private _item:Warehouse = null;
    set item(i){
        this._item = i;
        this.nameTextField.text = i.name;        
    }
}
