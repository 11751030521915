class DBProductSourceCoder extends DBXLSXCoder
{
    protected mainEntity(){
        return { entity: "ProductSource", predicateFormat: "deletedAt == null" }
    }

    protected columns() : any[] {    
        let cols = [
            { title: "SOURCE ID", width: "10%", align: "center" },
            { title: "NAME", width: "10%", align: "center" },
            { title: "AUTO SEND ON QUICK PAY", width: "10%", align: "center" },
        ]
        return cols;
    }

    protected importRow(row:any){
        
        let identifier = row[ "SOURCE ID" ];
        let name = row[ "NAME" ];
        let auto = row[ "AUTO SEND ON QUICK PAY" ];
        
        let src = this.queryEntityByIDOrName("ProductSource", identifier, name) as ProductSource;
        if (src == null){
            src = MIOEntityDescription.insertNewObjectForEntityForName("ProductSource", DBHelper.mainManagedObjectContext) as ProductSource;
            src.identifier = this.parseOrCreateIdentifier(identifier);
            this.appendObject(src);
        }
        
        src.name = name;
        src.autoSendOnQuickPay = this.parseBoolValue( auto );

        MIOLog("ADDING PRODUCT SOURCE : " + this.rowIndex + "/" + this.rows.length + ": " + name);
    }

    // 
    // Export
    // 
    protected exportTitle(): string { return "product sources"; }
    protected exportSortDescriptors() { 
        return [MIOSortDescriptor.sortDescriptorWithKey("name", false)]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let src = object as ProductSource;

        let item = {
            "SOURCE ID" : src.identifier,
            "NAME" : src.name,
            "AUTO SEND ON QUICK PAY" : src.autoSendOnQuickPay
        }

        return item;
    }
}