//
// Generated class CheffLine
//

/// <reference path="CheffLine_ManagedObject.ts" />

class CheffLine extends CheffLine_ManagedObject
{

}
