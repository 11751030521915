//
// Generated class CustomOutputNoteLine
//

/// <reference path="CustomOutputNoteLine_ManagedObject.ts" />

class CustomOutputNoteLine extends CustomOutputNoteLine_ManagedObject
{

}
