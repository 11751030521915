/**
 * Created by miguel on 16/3/17.
 */


interface InvoiceLineDelegate 
{
    lineDidInserted( line:InvoiceLine) : void;
    lineDidRemove( line:InvoiceLine)   : void;
    lineDidUpdate( line:InvoiceLine)   : void;
}

class InvoiceLineCell extends UITableViewCell
{    
    delegate:InvoiceLineDelegate = null;

    protected productTextField:MUITextField = null;
    protected conceptLabel:MUILabel = null;
    protected infoButton:MUIButton = null;
    protected taxDropDown:MUIButton = null;
    protected taxLabel:MUILabel = null;
    protected legalTaxDropDown:MUIButton = null;
    protected legalTaxLabel:MUILabel = null;
    protected quantityLabel:MUILabel = null;
    protected quantityTextField:MUITextField = null;
    protected unitPriceLabel:MUILabel = null;    
    protected unitPriceTextField:MUITextField = null;    
    protected discountLabel:MUILabel = null;
    protected discountTextField:MUITextField = null;    
    protected totalLabel:MUILabel = null;
    protected totalTextField:MUITextField = null;
    
    protected cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    protected nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;
    protected pnf = MUIWebApplication.sharedInstance().delegate.percentNumberFormatter;

    protected product:Product = null;
    protected vat:Tax = null;
    protected vat_factor:number = 0;
    protected legal_tax:LegalTax = null;
    protected legal_tax_factor:number = 0;
        
    protected quantity:number = 0;
    protected price_amount:number = 0;
    protected base_amount:number = 0;
    protected discount_format:string = null;
    protected discount_amount:number = 0;
    protected vat_amount:number = 0;
    protected legal_tax_amount:number = 0;
    protected total_amount:number = 0;

    awakeFromHTML()
    {
        this.productTextField = MUIOutlet(this, 'product-tf', 'MUITextField');
        this.setupProductTextField(this.productTextField, this, this.productDidSelect);
        this.conceptLabel = MUIOutlet(this, "concept-lbl", "MUILabel");
        this.infoButton = MUIOutlet( this, "info-icon-btn", "MUIButton" );
        this.infoButton?.setAction( this, this.showInfoAlert );
        this.taxLabel = MUIOutlet(this, "tax-lbl", "MUILabel");
        this.taxDropDown = MUIOutlet(this, 'tax-dd','MUIButton');
        this.setupTaxDropdown(this.taxDropDown);               
        this.legalTaxLabel = MUIOutlet(this, "legal-tax-lbl", "MUILabel"); 
        this.legalTaxDropDown = MUIOutlet(this, 'legal-tax-dd','MUIButton');
        this.setupLegalTaxDropdown(this.legalTaxDropDown);        
        this.quantityLabel = MUIOutlet(this, 'quantity-lbl','MUILabel');
        this.quantityTextField = MUIOutlet(this, 'quantity-tf', "MUITextField");
        this.setupQuantityTextField(this.quantityTextField);
        this.unitPriceLabel = MUIOutlet(this, 'unit-price-lbl','MUILabel');
        this.unitPriceTextField = MUIOutlet(this, 'unit-price-tf', "MUITextField");
        this.setupUnitPriceTextField(this.unitPriceTextField);        
        this.discountLabel = MUIOutlet(this, 'discount-lbl','MUILabel');
        this.discountTextField = MUIOutlet(this, 'discount-tf','MUITextField');
        this.setupDiscountTextField(this.discountTextField);        
        this.totalLabel = MUIOutlet(this, 'total-lbl','MUILabel');
        this.totalTextField = MUIOutlet(this, 'total-tf', "MUITextField");
        this.setupTotalTextField(this.totalTextField);

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    invoice:Invoice = null;

    private line:DBDocumentLine = null;
    set item(i:DBDocumentLine)
    {
        this.line = i;
        
        this.conceptLabel.text = this.conceptString();
        this.quantity = i.quantity;
        if (this.quantityLabel) this.quantityLabel.text = this.nf.stringFromNumber(i.quantity);
        if (this.quantityTextField) this.quantityTextField.text = this.nf.stringFromNumber(i.quantity);

        if (i instanceof InvoiceLine) {

            this.product = i.product;
            this.vat = i.vat;
            this.vat_factor = i.vatFactor;
            this.legal_tax = i.legalTax;
            this.legal_tax_factor = i.legalTaxFactor;        
            this.price_amount = i.priceAmount;
            this.discount_format = i.discountFormat;
            this.discount_amount = i.discountAmount;
            this.base_amount = i.baseAmount;
            this.total_amount = i.totalAmount;

                
            if (this.unitPriceLabel) this.unitPriceLabel.text = this.cf.stringFromNumber(i.priceAmount);
            if (this.unitPriceTextField) this.unitPriceTextField.text = this.cf.stringFromNumber(i.priceAmount);
            if (this.taxDropDown) this.taxDropDown.title = i.vat?.name;
            if (this.taxLabel) this.taxLabel.text = i.vat?.name;
            if (this.legalTaxDropDown) this.legalTaxDropDown.title = i.legalTax?.name;
            if (this.legalTaxLabel) this.legalTaxLabel.text = i.legalTax?.name;
            if (this.discountLabel) {
                if (i.discountFormat != null) this.discountLabel.text = i.discountFormat;
                else if (i.discountAmount > 0) this.discountLabel.text = -this.cf.stringFromNumber(i.discountAmount);
                else this.discountLabel.text = null;
            }
            if (this.discountTextField) this.discountTextField.text = i.discountFormat;
            if (this.totalLabel) this.totalLabel.text = this.cf.stringFromNumber(i.baseAmount);
            if (this.totalTextField) this.totalTextField.text = this.cf.stringFromNumber(i.baseAmount);
        }
    }

    private searchTimer:MIOTimer = null;
    private searchString:string = null;
    protected setupProductTextField(textField:MUITextField, target:any, completion:any){
        if (textField == null) return;        
        textField.setOnChangeText(this, function(control:MUITextField, value:string){
            this.searchString = value;
            if (this.searchTimer != null) this.searchTimer.invalidate();
            this.searchTimer = MIOTimer.scheduledTimerWithTimeInterval(500, false, this, function(timer:MIOTimer){
                this.searchProductTimerFired(textField, this.searchString, target, completion);
                this.searchTimer = null;
            });
        });

        textField.setOnDidEndEditing(this, function(control:MUITextField, value:string){
            AppHelper.sharedInstance().dismissSelectStockProductViewController();
        });
    }

    protected searchProductTimerFired(textField:MUITextField, value:string, target, completion){        
        AppHelper.sharedInstance().showSelectProductViewControllerFromView(textField, value, null, target, completion, this, null);
    }

    protected productDidSelect(controller:SelectEntityViewController, product:Product, supplierProduct?:SupplierProduct){
        this.reset();
        this.product = product;

        // this.inputType = this.product.measureType;
        // this.inputFormat = null;
        // this.productMeasureType = this.product.measureType;
        // this.productContainerMeasureType = this.product.containerMeasureType;
        // this.productContainerQuantity = this.product.containerQuantity;

        // this.tax = DBHelper.stockTaxFromProduct(product);
        // this.taxDropDown.title = this.tax?.name;
        // this.warehouse = product.defaultWarehouse ?? AppHelper.sharedInstance().defaultWarehouse;

        // UI
        this.productTextField.text = this.product.productNameDescription();        

        // this.inputFormatDropdown.enabled = true;
        // this.inputFormatDropdown.title = product.defaultInputFormatString();        

        // this.quantityTextField.enabled = true;        
        this.quantityTextField.becomeFirstResponder();

        // if (this.productQuantityLabel) this.productQuantityLabel.text = this.productQuantityString();

        // if (this.priceTextField) {
        //     this.priceTextField.enabled = true;
        //     this.priceTextField.text = this.cf.stringFromNumber(this.priceAmount);
        // }

        // if (this.productPriceTextField) { 
        //     this.productPriceTextField.enabled = true;
        //     this.productPriceTextField.text = this.productPriceString();
        // }        

        // if (this.discountFormatTextField) {
        //     this.discountFormatTextField.enabled = true;        
        //     this.discountFormatTextField.text = this.discountFormat;
        // }

        // if (this.totalTextField) {
        //     this.totalTextField.enabled = true;
        //     this.totalTextField.text = this.totalPriceString();
        // }
    }

    protected reset(){        

        this.product = null;
        // this.productContainerMeasureType = MeasureUnitType.None;
        // this.productContainerQuantity = 0;
        // this.tax = null;
        // this.tax_factor = 0;
        this.legal_tax = null;
        this.legal_tax_factor = 0;
        this.quantity = 0;
        this.price_amount = 0;
        this.base_amount = 0;
        this.discount_format = null;
        this.discount_amount = 0;
        this.vat_amount = 0;
        this.legal_tax_amount = 0;
        this.total_amount = 0;
    
        if (this.productTextField != null) this.productTextField.text = null;        

        // if (this.inputFormatDropdown != null) {
        //     this.inputFormatDropdown.title = null;
        //     this.inputFormatDropdown.enabled = false;            
        // }        

        if (this.quantityTextField != null) {
            this.quantityTextField.text = null;
            // this.quantityTextField.enabled = false;
        }        

        // if (this.productQuantityLabel) this.productQuantityLabel.text = null;

        if (this.unitPriceTextField != null) {
            this.unitPriceTextField.text = null;
            // this.unitPriceTextField.enabled = false;
        }

        // if (this.productPriceTextField != null) {
        //     this.productPriceTextField.text = null;
        //     this.productPriceTextField.enabled = false;
        // }

        if (this.discountLabel) this.discountLabel.text = null;
        if (this.discountTextField != null) {
            this.discountTextField.text = null;
            // this.discountFormatTextField.enabled = false;
        }

        // if (this.discountAmountLabel) this.discountAmountLabel.text = null;

        if (this.totalTextField != null) {
            this.totalTextField.text = null;
            // this.totalTextField.enabled = false;
        }
    }

    get entityName() : string { return "DBDocumentLine"; }

    enterDidPress(){
        if (this.line != null) return;
        if (this.invoice == null) return;
        if (this.productTextField.text.length == 0) return;
        if (this.quantity == 0 || this.quantity == null) return;        
                        
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let line = MIOEntityDescription.insertNewObjectForEntityForName( this.entityName, ad.managedObjectContext ) as InvoiceLine;

        line.document = this.invoice;
        line.date = new Date();
        line.product = this.product;
        line.concept = this.product != null ? this.product.name : this.productTextField.text;

        this.updateLine(line);

        DBHelper.saveMainContext();

        this.delegate.lineDidInserted(line);
        
        this.reset();     

        this.productTextField.becomeFirstResponder();
    }


    protected setupTaxDropdown(button:MUIButton){
        if (button == null) return;
        button.setAction(this, function(){
            AppHelper.sharedInstance().showSelectTaxViewControllerFromView(button, null, null, this, function(this:InvoiceLineCell, controller:SelectViewController, tax:Tax){
                this.vat = tax;
                this.taxDropDown.title = tax?.name;
                this.vat_factor = tax != null ? tax.taxQuantity : 0;
                this.updateTotal();
                this.updateLine(this.line as InvoiceLine);
                this.delegate.lineDidUpdate(this.line as InvoiceLine);
            });
        });
    }

    protected setupLegalTaxDropdown(button:MUIButton){
        if (button == null) return;
        button.setAction(this, function(){
            AppHelper.sharedInstance().showSelectLegalEntityTaxViewControllerFromView(button, null, this, function(this:InvoiceLineCell, controller:SelectViewController, tax:LegalTax){
                this.legal_tax = tax;
                this.legalTaxDropDown.title = tax?.name;
                this.legal_tax_factor = tax != null ? tax.value : 0;
                this.updateTotal();
                this.updateLine(this.line as InvoiceLine);
                this.delegate.lineDidUpdate(this.line as InvoiceLine);
            });
        });
    }


    protected setupQuantityTextField(textField:MUITextField){
        this.setupTextField(textField, this.nf, (value:string) => {
            this.quantity = value.length > 0 ? this.nf.numberFromString(value) : 0
            this.updateTotal();
            this.updateLine(this.line as InvoiceLine);
            this.delegate.lineDidUpdate(this.line as InvoiceLine);
        });
    }

    protected setupUnitPriceTextField(textField:MUITextField){
        this.setupTextField(textField, this.cf, (value:string) => {
            this.price_amount = value.length > 0 ? this.cf.numberFromString(value) : 0;
            this.updateTotal();
            this.updateLine(this.line as InvoiceLine);
            this.delegate.lineDidUpdate(this.line as InvoiceLine);
        });
    }

    protected setupDiscountTextField(textField:MUITextField){
        this.setupTextField(textField, this.pnf, (value:string) => {
            if ( value.trim().length == 0 ) { 
                this.discount_format = null;
                this.discount_amount = 0;
                this.updateTotal();        
            }
            else {
                this.discount_format = value.trim();
                if ( value.hasSuffix("%") == false ) {                    
                    this.discount_amount = this.pnf.numberFromString ( this.discount_format );
                }
                else {            
                    let v = this.pnf.numberFromString ( this.discount_format );
                    this.discount_amount = ( this.price_amount * this.quantity ) * ( v / 100 );
                }                        
                this.updateTotal();
            }
            this.updateLine(this.line as InvoiceLine);
        });
    }

    protected setupTotalTextField(textField:MUITextField){
        this.setupTextField(textField, this.cf, (value:string) => {
            this.base_amount = value.length > 0 ? this.cf.numberFromString(value) : 0;            
            this.updateDiscount();
            this.updateLine(this.line as InvoiceLine);
            this.delegate.lineDidUpdate(this.line as InvoiceLine);
        });
    }

    protected setupTextField(textField:MUITextField, formatter:MIOFormatter, onChangeText:any){
        if (textField == null) return;        
        textField.formatter = formatter;
        textField.setOnChangeText(this, function(this:InvoiceLineCell, control:MUITextField, value:string){            
            onChangeText(value);
            this.updateLine(this.line as InvoiceLine);
            this.delegate.lineDidUpdate(this.line as InvoiceLine);
        });

        textField.setOnBeginEditing(this, function(control:MUITextField, value:string){
            control.selectAll(null);
        });

        textField.setOnEnterPress(this, function(control:MUITextField){
            control.resignFirstResponder();
            this.enterDidPress()
        });
    }

    private updateDiscount(){
        let cf = (MUIWebApplication.sharedInstance().delegate as AppDelegate).currencyFormatter;
        
        this.discount_amount = ( this.quantity * this.price_amount ) - this.base_amount;                
        this.vat_amount = this.base_amount * this.vat_factor;
        this.legal_tax_amount = this.base_amount * this.legal_tax_factor;
        this.total_amount = this.base_amount + this.vat_amount - this.legal_tax_amount;

        if (this.discountTextField) this.discountTextField.text = cf.stringFromNumber( this.discount_amount );
        if (this.discountLabel) this.discountLabel.text = cf.stringFromNumber( this.discount_amount );
    }

    private updateTotal() {
        let cf = (MUIWebApplication.sharedInstance().delegate as AppDelegate).currencyFormatter;

        this.base_amount = ( this.quantity * this.price_amount ) - this.discount_amount;
        this.vat_amount = this.base_amount * this.vat_factor;
        this.legal_tax_amount = this.base_amount * this.legal_tax_factor;
        this.total_amount = this.base_amount + this.vat_amount - this.legal_tax_amount;

        if (this.totalTextField) this.totalTextField.text = cf.stringFromNumber( this.base_amount );
        if (this.totalLabel) this.totalLabel.text = cf.stringFromNumber( this.base_amount );
    }

    private updateLine(line:InvoiceLine) {
                        
        if (line == null) return;

        line.quantity = this.quantity;
        line.priceAmount = this.price_amount;
        line.vat = this.vat;
        line.vatAmount = this.vat_amount;
        line.vatFactor = this.vat_factor;
        line.legalTax = this.legal_tax;
        line.legalTaxAmount = this.legal_tax_amount;
        line.legalTaxFactor = this.legal_tax_factor;
        line.baseAmount = this.base_amount;
        line.discountFormat = this.discount_format;
        line.discountAmount = this.discount_amount;
        line.totalAmount = this.total_amount;
    }

    protected showInfoAlert(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle( MIOLocalizeString ("INFO", "INFO" ), MIOLocalizeString( "EXTRA INFO", "EXTRA INFO" ), MUIAlertViewStyle.Default );
        
        avc.addTextFieldWithConfigurationHandler( this, function( this:InvoiceLineCell, control:MUITextField ) {
            control.text = this.line.concept;
        });

        avc.addTextFieldWithConfigurationHandler( this, function( this:InvoiceLineCell, control:MUITextField ) {
            control.text = this.line.comments;
        });

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString( "OK", "OK"), MUIAlertActionStyle.Default, this, function(this:InvoiceLineCell){
            let concept = avc.textFields[0].text.trim();
            if ( concept.lenght == 0 ) {
                AppHelper.showErrorMessage( null, MIOLocalizeString("ERROR", "ERROR"), MIOLocalizeString("THE CONCEPT FIELD CAN'T BE NULL OR EMPTY", "THE CONCEPT FIELD CAN'T BE NULL OR EMPTY"));
                return;
            }

            let comments = avc.textFields[1].text.trim();

            this.line.concept = concept;
            this.line.comments = comments.length == 0 ? null : comments;

            this.conceptLabel.text = this.conceptString();
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        AppHelper.sharedInstance().presentViewController( avc, true );
    }

    private conceptString() : string
    {
        return this.line.concept + (this.line.comments != null ? " " + this.line.comments : "" );
    }

}