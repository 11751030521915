//
// Generated class Rule
//

/// <reference path="Rule_ManagedObject.ts" />

class Rule extends Rule_ManagedObject
{

}
