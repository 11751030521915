

/// <reference path="Invoice.ts" />

// Generated class SalesInvoice_ManagedObject

class SalesInvoice_ManagedObject extends Invoice
{
    // Relationship: client
    set client(value:LegalEntity) {
        this.setValueForKey(value, 'client');
    }
    get client():LegalEntity {
        return this.valueForKey('client') as LegalEntity;
    }
}
