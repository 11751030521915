

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ProductAttribute_ManagedObject

class ProductAttribute_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: imageID
    set imageID(value:string) {
        this.setValueForKey(value, 'imageID');
    }
    get imageID():string {
        return this.valueForKey('imageID');
    }
    set imageIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'imageID');
    }
    get imageIDPrimitiveValue():string {
        return this.primitiveValueForKey('imageID');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }
    // Relationship: image
    set image(value:DBImage) {
        this.setValueForKey(value, 'image');
    }
    get image():DBImage {
        return this.valueForKey('image') as DBImage;
    }
    // Relationship: productAttributeType
    set productAttributeType(value:ProductAttributeType) {
        this.setValueForKey(value, 'productAttributeType');
    }
    get productAttributeType():ProductAttributeType {
        return this.valueForKey('productAttributeType') as ProductAttributeType;
    }

    // Relationship: products
    protected _products:MIOManagedObjectSet = null;
    get products():MIOManagedObjectSet {
        return this.valueForKey('products');
    }
    addProductsObject(value:Product) {
        this._addObjectForKey(value, 'products');
    }
    removeProductsObject(value:Product) {
        this._removeObjectForKey(value, 'products');
    }
}
