

/// <reference path="PaymentAction.ts" />

// Generated class LoadEconomicAccount_ManagedObject

class LoadEconomicAccount_ManagedObject extends PaymentAction
{

    // Property: amount
    set amount(value:number) {
        this.setValueForKey(value, 'amount');
    }
    get amount():number {
        return this.valueForKey('amount');
    }
    set amountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'amount');
    }
    get amountPrimitiveValue():number {
        return this.primitiveValueForKey('amount');
    }

    // Property: autoAssignBarcode
    set autoAssignBarcode(value:boolean) {
        this.setValueForKey(value, 'autoAssignBarcode');
    }
    get autoAssignBarcode():boolean {
        return this.valueForKey('autoAssignBarcode');
    }
    set autoAssignBarcodePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'autoAssignBarcode');
    }
    get autoAssignBarcodePrimitiveValue():boolean {
        return this.primitiveValueForKey('autoAssignBarcode');
    }

    // Property: createAnonymousCardIfNeeded
    set createAnonymousCardIfNeeded(value:boolean) {
        this.setValueForKey(value, 'createAnonymousCardIfNeeded');
    }
    get createAnonymousCardIfNeeded():boolean {
        return this.valueForKey('createAnonymousCardIfNeeded');
    }
    set createAnonymousCardIfNeededPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'createAnonymousCardIfNeeded');
    }
    get createAnonymousCardIfNeededPrimitiveValue():boolean {
        return this.primitiveValueForKey('createAnonymousCardIfNeeded');
    }

    // Property: rechargeLoyaltyAccount
    set rechargeLoyaltyAccount(value:boolean) {
        this.setValueForKey(value, 'rechargeLoyaltyAccount');
    }
    get rechargeLoyaltyAccount():boolean {
        return this.valueForKey('rechargeLoyaltyAccount');
    }
    set rechargeLoyaltyAccountPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'rechargeLoyaltyAccount');
    }
    get rechargeLoyaltyAccountPrimitiveValue():boolean {
        return this.primitiveValueForKey('rechargeLoyaltyAccount');
    }
}
