
class OutputNoteCell extends UITableViewCell
{
    private sourceLabel:MUILabel = null;
    private destinationLabel:MUILabel = null;    
    private dateLabel:MUILabel = null;
    
    private dtf = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter;

    awakeFromHTML(){
        this.destinationLabel = MUIOutlet(this, 'destination-lbl', 'MUILabel');
        this.dateLabel = MUIOutlet(this, 'date-lbl', 'MUILabel');        
        this.sourceLabel = MUIOutlet(this, 'source-lbl', 'MUILabel');          
    }

    set item(i:StockNote){
        this.destinationLabel.text = i.destinationName;
        this.dateLabel.text = this.dtf.stringFromDate(i.stockDate);
        this.sourceLabel.text = i.originName ? i.originName : MIOLocalizeString("_DEFAULT_WAREHOUSE_", "_DEFAULT_WAREHOUSE_"); 
    }
}