//
// Generated class ProductImage
//

/// <reference path="ProductImage_ManagedObject.ts" />

class ProductImage extends ProductImage_ManagedObject
{

}
