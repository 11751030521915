

/// <reference path="PaymentAction.ts" />

// Generated class GeneratePromotionalCode_ManagedObject

class GeneratePromotionalCode_ManagedObject extends PaymentAction
{

    // Property: numberOfCodes
    set numberOfCodes(value:number) {
        this.setValueForKey(value, 'numberOfCodes');
    }
    get numberOfCodes():number {
        return this.valueForKey('numberOfCodes');
    }
    set numberOfCodesPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'numberOfCodes');
    }
    get numberOfCodesPrimitiveValue():number {
        return this.primitiveValueForKey('numberOfCodes');
    }

    // Property: numberOfUsesPerCode
    set numberOfUsesPerCode(value:number) {
        this.setValueForKey(value, 'numberOfUsesPerCode');
    }
    get numberOfUsesPerCode():number {
        return this.valueForKey('numberOfUsesPerCode');
    }
    set numberOfUsesPerCodePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'numberOfUsesPerCode');
    }
    get numberOfUsesPerCodePrimitiveValue():number {
        return this.primitiveValueForKey('numberOfUsesPerCode');
    }
    // Relationship: offer
    set offer(value:Offer) {
        this.setValueForKey(value, 'offer');
    }
    get offer():Offer {
        return this.valueForKey('offer') as Offer;
    }
}
