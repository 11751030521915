

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class PrinterLink_ManagedObject

class PrinterLink_ManagedObject extends CoreEntityUpdatedBy
{
    // Relationship: printer
    set printer(value:Printer) {
        this.setValueForKey(value, 'printer');
    }
    get printer():Printer {
        return this.valueForKey('printer') as Printer;
    }
    // Relationship: printProfileConfiguration
    set printProfileConfiguration(value:PrintProfileConfiguration) {
        this.setValueForKey(value, 'printProfileConfiguration');
    }
    get printProfileConfiguration():PrintProfileConfiguration {
        return this.valueForKey('printProfileConfiguration') as PrintProfileConfiguration;
    }
}
