//
// Generated class ProductCostBalance
//

/// <reference path="ProductCostBalance_ManagedObject.ts" />

class ProductCostBalance extends ProductCostBalance_ManagedObject
{

}
