class WorkersAdminPOSDataSource extends MIOObject
{    
    private _tableView:UITableView = null;
    initWithTableView(tableView:UITableView){
        super.init();
        this._tableView = tableView;
    }

    private _employee:Employee = null;
    get employee(){return this._employee;}
    set employee(value:Employee) {
        if (value == null) return;
        this._employee = value; 
        this._workerRole = null;
        this._tableView.reloadData();
    }

    private _workerRole:WorkerRole = null;
    get role(){return this._workerRole;}
    set role(value:WorkerRole){
        if (value == null) return;
        this._workerRole = value;
        this._employee = null;
        this._tableView.reloadData();
    }

    numberOfRowsInSection(tableView:UITableView, section) {
        let rows = Employee.posPermisionsCount();
        return rows;
    }

    cellAtIndexPath(tableView:UITableView, indexPath: MIOIndexPath) {
        let cell = tableView.dequeueReusableCellWithIdentifier("PermissionCell") as WorkerPermissionCell;        
        
        if (this.employee != null) this.configureEmployeeCellAtIndexPath(cell, indexPath);
        else if (this.role != null) this.configureWorkerRolCellAtIndexPath(cell, indexPath);

        return cell;
    }

    private configureEmployeeCellAtIndexPath(cell:WorkerPermissionCell, indexPath: MIOIndexPath) {
        
        let permission = Employee.posPermisionAtIndex(indexPath.row);
        
        cell.title = Employee.stringForPOSPermission(permission);
        cell.accessoryType = this.employee.hasPOSPermision(permission) ? UITableViewCellAccessoryType.Checkmark : UITableViewCellAccessoryType.None;
    }

    private configureWorkerRolCellAtIndexPath(cell:WorkerPermissionCell, indexPath: MIOIndexPath) {
        
        let permission = Employee.posPermisionAtIndex(indexPath.row);
        
        cell.title = Employee.stringForPOSPermission(permission);
        cell.accessoryType = this.role.hasPOSPermision(permission) ? UITableViewCellAccessoryType.Checkmark : UITableViewCellAccessoryType.None;
    }

    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){

        let cell = tableView.cellAtIndexPath(indexPath) as WorkerPermissionCell;
        let permission = Employee.posPermisionAtIndex(indexPath.row);

        if (this.employee != null && this.employee.hasPOSPermision(permission) == true){
            this.employee.removePOSPermission(permission);
            cell.accessoryType = UITableViewCellAccessoryType.None;                
        }
        else if (this.employee != null && this.employee.hasPOSPermision(permission) == false){
            this.employee.addPOSPermission(permission);
            cell.accessoryType = UITableViewCellAccessoryType.Checkmark;
        }
        if (this.role != null && this.role.hasPOSPermision(permission) == true){
            this.role.removePOSPermission(permission);
            cell.accessoryType = UITableViewCellAccessoryType.None;                
        }
        else if (this.role != null && this.role.hasPOSPermision(permission) == false){
            this.role.addPOSPermission(permission);
            cell.accessoryType = UITableViewCellAccessoryType.Checkmark;
        }       
    }

}