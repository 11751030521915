
class TicketLinesDataSource extends MIOObject
{
    private tableView:UITableView = null;
    initWithTableView(tableView:UITableView){
        this.tableView = tableView;
    }

    private _ticket:ArchivedDocument = null;
    // private _filterValues = null;
    // set filterValues(values){
    //     this._filterValues = values;

    private lines = [];
    setItems(ticket, lines){
        this._ticket = ticket;
        this.lines = lines;
        this.tableView.reloadData();
    }

    numberOfSections(tableView:MUITableView){
        //return this.fetchedResultsController.sections.length;
        return 2;
    }
    
    numberOfRowsInSection(tableView:MUITableView, section:number){
        // let sec = this.fetchedResultsController.sections[section];
        // return sec.numberOfObjects();
        return section == 0 ? this.lines.count : 1;
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){

        if (indexPath.section == 0){
            let cell = tableView.dequeueReusableCellWithIdentifier("LineCell") as TicketLineCell;
            //let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
            let item = this.lines[indexPath.row];
    
            cell.item = item;        

            return cell;
        }
        else {
            let cell = tableView.dequeueReusableCellWithIdentifier("TotalLineCell") as TicketTotalLineCell;
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            cell.item = ad.currencyFormatter.stringFromNumber(this._ticket.totalPrice);
            return cell;
        }        
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        
    }

    /*private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('ArchivedTicketLinesGroup');    
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('orderindex', true)];    
        fetchRequest.relationshipKeyPathsForPrefetching = ["lines"];
        
        fetchRequest.predicate = MIOPredicate.predicateWithFormat("document.orderid == '" + this._filterValues["orderID"] + "'");
        
        let fetchedResultsController = new MIOFetchedResultsController(); 
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, 'section');
        fetchedResultsController.delegate = this;        

        fetchedResultsController.performFetch(); 
        
        this._fetchedResultsController = fetchedResultsController;   
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.mapLines(controller.fetchedObjects);
        this.tableView.reloadData();
    }
*/

}