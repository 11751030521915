//
// Generated class Channel
//

/// <reference path="Channel_ManagedObject.ts" />

class Channel extends Channel_ManagedObject
{

}
