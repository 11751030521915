class SupplierProductCell extends UITableViewCell
{    
    private nameLabel:MUILabel = null;
    private nameTextField:MUITextField = null;
    private referenceTextField:MUITextField = null;
    private referenceNameTextField:MUITextField = null;
    private inputFormatDropdown:MUIButton = null;
    private lastPriceLabel:MUILabel = null;
    private lastPriceDateLabel:MUILabel = null;
    private priceTextField:MUITextField = null;
    private discountTextField:MUITextField = null;    
    
    private cf = MUIWebApplication.sharedInstance().delegate.longCurrencyFormatter as MIONumberFormatter;
    private df =  MUIWebApplication.sharedInstance().delegate.dateFormatter;

    
    awakeFromHTML(){
        
        this.nameLabel = MUIOutlet(this, 'name-lbl', 'MUILabel');
        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.setupProductTextField(this.nameTextField);
                
        this.referenceTextField = MUIOutlet(this, 'reference-tf','MUITextField');
        this.setupTextField(this.referenceTextField, this, this.referenceDidChange);

        this.referenceNameTextField = MUIOutlet(this, 'reference-name-tf','MUITextField');
        this.setupTextField(this.referenceNameTextField, this, this.referenceNameDidChange);

        this.inputFormatDropdown = MUIOutlet( this, "input-format-dd", "MUIButton" );
        this.inputFormatDropdown.setAction( this, function( this:SupplierProductCell ) {
            AppHelper.sharedInstance().showSelectStockInputFormatViewControllerFromView( this.inputFormatDropdown, this.product, ( controller:any, object:StockInputFormat ) => {
                this.inputFormat = object;
                this.inputFormatDropdown.title = object?.name;
                this.updateSupplierProduct();
            } );
        } ) ;


        this.lastPriceLabel = MUIOutlet(this, "last-price-lbl", "MUILabel");
        this.lastPriceDateLabel = MUIOutlet(this, "last-price-date-lbl", "MUILabel");

        this.priceTextField = MUIOutlet(this, 'price-tf','MUITextField'); 
        this.priceTextField.formatter = this.cf;
        this.setupTextField(this.priceTextField, this, this.priceDidChange);

        this.discountTextField = MUIOutlet(this, "discount-tf", "MUITextField");
        this.discountTextField.formatter = MUIWebApplication.sharedInstance().delegate.percentNumberFormatter;
        this.setupTextField(this.discountTextField, this, this.discountDidChange);

        this.selectionStyle = UITableViewCellSelectionStyle.None;

        this.reset();
    }

    private product:Product = null;
    private reference:string = null;
    private referenceName:string = null;
    private inputFormat:StockInputFormat = null;
    private price:number = null;
    private productPrice:number = null;
    private discount = null;

    private _supplier:Supplier = null;
    set supplier(supplier:Supplier){
        this._supplier = supplier;
    }

    private supplierProduct:SupplierProduct = null;
    set item(item:SupplierProduct)
    {
        this.reset();

        this.supplierProduct = item;
        this.product = item.product;
        this.reference = item.supplierReference;
        this.price = item.price;
        this.productPrice = item.productPrice;
        this.discount = item.discountString;
        this.inputFormat = item.inputFormat;
        
        if (this.nameLabel != null) this.nameLabel.text = this.productName();
        if (this.nameTextField != null) this.nameTextField.text = this.productName();
        this.referenceTextField.text = item.supplierReference;
        this.referenceNameTextField.text = item.supplierReferenceName;
        this.inputFormatDropdown.title = item.inputFormatName;
        this.lastPriceLabel.text = this.cf.stringFromNumber(item.computedLastPrice);
        this.lastPriceDateLabel.text = item.computedLastPriceDate ? this.df.stringFromDate(item.computedLastPriceDate) : MIOLocalizeString("NO DATE","NO DATE");
        this.priceTextField.text = this.cf.stringFromNumber(item.price);
        this.discountTextField.text = this.discount;
    }

    private reset(){
        if (this.nameLabel != null) this.nameLabel.text = null;
        if (this.nameTextField != null) this.nameTextField.text = null;
        this.referenceTextField.text = null;
        this.referenceNameTextField.text = null;
        this.inputFormatDropdown.title = null;
        this.lastPriceLabel.text = null;
        this.priceTextField.text = null; 
        this.discountTextField.text = null;       
    }

    private setupProductTextField(textField:MUITextField){
        if (textField == null) return;
        textField.setOnChangeText(this, function(control, value){
            AppHelper.sharedInstance().showSelectProductViewControllerFromView(textField, value, {"SectionNameKeyPath":"productTypeString"}, this, this.productDidSelect, this, this.addProduct);
        });        
    }

    private productDidSelect(controller:SelectEntityViewController, product:Product){
        this.reset();
        this.product = product;
        this.nameTextField.text = product.name;
    }

    private addProduct(value:string){
        AppHelper.sharedInstance().showAddStockProductAlertViewController(value, null, this, this.productDidAdd);
    }

    private productDidAdd(product:Product){
        this.reset();
        this.product = product;
        this.nameTextField.text = product.name;
    }

    private setupTextField(textField:MUITextField, target, completion){
        textField.setOnChangeText(this, function(textfield, value){            
            completion.call(target, value);
        });    

        textField.setOnEnterPress(this, function(this:SupplierProductCell){              
            if (this.supplierProduct != null) return;
            if (this.product == null) {
                AppHelper.showErrorMessage(null, MIOLocalizeString("ERROR", "ERROR"), MIOLocalizeString("SELECT THE PRODUCT AGAIN AND TRY AGAIN", "SELECT THE PRODUCT AGAIN AND TRY AGAIN"));            
                return;
            }

            if (this.price == null) {
                AppHelper.showErrorMessage( null, MIOLocalizeString( "ERROR", "ERROR" ), MIOLocalizeString( "COULDN'T ADD A PRODUCT WITHOUT PRICE", "COULDN'T ADD A PRODUCT WITHOUT PRICE" ) );
                return;
            }
    
            // check duplicate item        
            let pf = "product.identifier = " + this.product.identifier + " and supplier.identifier = " + this._supplier.identifier;
            pf += this.inputFormat != null ? " and inputFormat.identifier = " + this.inputFormat.identifier : " and inputFormat = null";
            
            DBHelper.queryObjectWithCompletion( "SupplierProduct", null, MIOPredicate.predicateWithFormat( pf ), [], this, function( this:SupplierProductCell, object:SupplierProduct ) {

                if ( object != null ) {
                    AppHelper.showErrorMessage( null, MIOLocalizeString( "ERROR", "ERROR" ), MIOLocalizeString( "COULDN'T ADD A PRODUCT THAT IS ALREADY ADDED", "COULDN'T ADD A PRODUCT THAT IS ALREADY ADDED" ) );
                    return;
                }

                let sp = DBHelper.createSupplierProduct(this._supplier, this.product, this.price, this.productPrice, this.reference, this.discount);
                sp.supplierReferenceName = this.referenceName;
                sp.inputFormat = this.inputFormat;
                sp.inputFormatName = this.inputFormat?.name;
                DBHelper.saveMainContext();
                this.reset();    
            } );
        });
    }

    private referenceDidChange(value:string){
        let objs = this._supplier.supplierProducts.filterWithPredicate(MIOPredicate.predicateWithFormat("supplierReference == '" + value + "'"));
        if (objs.length > 0) {
            AppHelper.showErrorMessage(null, MIOLocalizeString("ERROR", "ERROR"), MIOLocalizeString("FOUND DULICATED REFERENCES, PLEASE CHECK.", "FOUND DULICATED REFERENCES, PLEASE CHECK."));            
        } 

        this.reference = value;
        this.updateSupplierProduct();
    }

    private referenceNameDidChange(value:string){
        this.referenceName = value.trim().length > 0 ? value : null;
        this.updateSupplierProduct();
    }

    private priceDidChange(value:string){        
        this.price = value.length > 0 ? this.cf.numberFromString(value) : null;
        this.productPrice = this.price; //DBHelper.calculateMinumCostFromProductPrice(this.productPrice, this.product);
        this.updateSupplierProduct();
    }

    private discountDidChange(value:string){
        this.discount = value;
        this.updateSupplierProduct();
    }

    private updateSupplierProduct(){
        if (this.supplierProduct == null) return;

        this.supplierProduct.supplierReference = this.reference;
        this.supplierProduct.supplierReferenceName = this.referenceName;
        this.supplierProduct.inputFormat = this.inputFormat;
        this.supplierProduct.inputFormatName = this.inputFormat?.name;
        this.supplierProduct.price = this.price;
        this.supplierProduct.productPrice = this.productPrice;
        this.supplierProduct.discountString = this.discount;
    }

    private productName(){
        if (this.supplierProduct.product != null) return this.supplierProduct.product.name;
        return MIOLocalizeString("DELETED PRODUCT!", "DELETED PRODUCT!");
    }
    
}
