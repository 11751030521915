//
// Generated class LicenseInvoiceBusinessLine
//

/// <reference path="LicenseInvoiceBusinessLine_ManagedObject.ts" />

class LicenseInvoiceBusinessLine extends LicenseInvoiceBusinessLine_ManagedObject
{

}
