
interface ProductLanguageCellDelegate
{
    localizedValueCellDidInsert( cell:ProductLanguageCell, localizedValue:LocalizedValue );
}

class ProductLanguageCell extends UITableViewCell
{
    delegate:ProductLanguageCellDelegate = null;

    private titleLabel:MUILabel = null;
    private valueTextField:MUITextField = null;
    private valueTextArea:MUITextArea = null;

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");

        this.valueTextField = MUIOutlet(this, "value-tf", "MUITextField");
        if (this.valueTextField) this.valueTextField.setOnChangeText(this, function(textField:MUITextField, value:string){
            let v = value.trim().length > 0 ? value.trim() : null;
            this.updateLocalizeValue( v );
        }

        this.valueTextArea = MUIOutlet(this, "value-tf", "MUITextField");
        if (this.valueTextArea) this.valueTextArea.setOnChangeText(this, function(areaTextField:MUITextArea, value:string){
            let v = value.trim().length > 0 ? value.trim() : null;
            this.updateLocalizeValue( v );
        }

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    set language(value:string){
        this.titleLabel.text = MIOLocalizeString( value, value );
    }

    private _localize_value: LocalizedValue = null;
    
    private _object: MIOManagedObject = null;
    private _key: string = null;
    private _language: string = null;
    setItem( value:LocalizedValue, object:MIOManagedObject, key:string, language:string ){
        this._localize_value = value;
        this._object = object;
        this._language = language;
        this._key = key;
        this.valueTextField.text = value?.value;
    }

    private updateLocalizeValue( value:string ) {
        if (this._localize_value == null){
            const ctx = (MUIWebApplication.sharedInstance().delegate as AppDelegate).managedObjectContext;
            this._localize_value = MIOEntityDescription.insertNewObjectForEntityForName("LocalizedValue", ctx) as LocalizedValue;
            this._localize_value.language = this._language;
            this._localize_value.key = this._key;
            this._localize_value.entityTypeName = this._object.objectID.entity.name;
            this._localize_value.entityIdentifier = this._object.valueForKey("identifier");
            this._localize_value.value = value;
            this.delegate.localizedValueCellDidInsert( this, this._localize_value );
        }
        else {
            this._localize_value.value = value;
        }        
    }
}


let flags =  {
    "en": "🇬🇧",
    "es": "🇪🇸",
    "fr": "🇫🇷",
    "eu": "🇪🇺",
    "de": "🇩🇪",
    "it": "🇮🇹",
    "pt": "🇵🇹",
    "ca": "🇨🇦",
    "gl": "🇬🇱",
    "ru": "🇷🇺",
    "nl": "🇳🇱",
    "ar": "🇦🇪"
}
