
class SyncLogEditViewController extends MUIViewController
{
    static newInstance() : SyncLogEditViewController {
        let vc = new SyncLogEditViewController("sync-log-edit-view");
        vc.initWithResource("layout/dbtool/SyncLogEditView.html");
        return vc;
    } 

    private backButton:MUIButton = null;
    private saveButton:MUIButton = null;
    private fileURLLabel:MUILabel = null;
    private codeEditorView:MUICodeEditor = null;

    viewDidLoad(): void {
        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");
        this.backButton.setAction(this, function(this:SyncLogEditViewController) { 
            this.navigationController.popViewController(true);
        });

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, this.saveAndUploadAction);

        this.fileURLLabel = MUIOutlet(this, "file-url-lbl", "MUILabel");
        this.codeEditorView = MUIOutlet(this, "code-editor-view", "MUICodeEditor");      
        this.codeEditorView.language = MUICodeEditorLanguage.JSON;
    }

    viewWillAppear(animated?: any) {
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private syncLog:SyncLog = null;
    private code:string = "";
    set item (item:SyncLog) {
        this.syncLog = item;        
        this.downloadFile();
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this.syncLog == null) return;

        this.fileURLLabel.text = this.syncLog.fileURL;
        this.codeEditorView.text = this.code;        
        // this.codeEditorView.beautify();
    }

    private saveAndUploadAction() {

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("WARNING", "WARNING"), MIOLocalizeString("ARE YOU SURE TO UPLOAD?", "ARE YOU SURE TO UPLOAD?"), MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Default, this, function(this:SyncLogEditViewController) {
            this.uploadFile();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));
        this.presentViewController(avc, true);    
    }
    

    private downloadFile(){

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.downloadSyncLogFile( this.syncLog.identifier, this, function(this:SyncLogEditViewController, code:number, json:any) {
            if (code != 200) {
                AppHelper.showErrorMessage(null, "ERROR", "COULDN'T DOWNLOAD THE FILE");
                return;
            }
            this.code = JSON.stringify(json["data"], null, 2);
            this.updateUI();
        });
    }

    private uploadFile() { 

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.uploadSyncLogfile( this.syncLog.identifier, this.codeEditorView.text, this, function(this:SyncLogEditViewController, code:number, data:any) {
            if (code != 200) {
                AppHelper.showErrorMessage(this, "ERROR", "SOMETHING WENT WRONG!");
            }
            else {
                if (data != null && data["error"] == null) {
                    AppHelper.showErrorMessage(this, "INFO", "THE FILE UPLOAD WAS SUCCESSFULL");
                }
                else {
                    AppHelper.showErrorMessage(this, "ERROR", data["error"]);
                }
            }
        });
    }
}