

// Generated class ProductComponentItem_ManagedObject

class ProductComponentItem_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: categoryID
    set categoryID(value:string) {
        this.setValueForKey(value, 'categoryID');
    }
    get categoryID():string {
        return this.valueForKey('categoryID');
    }
    set categoryIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'categoryID');
    }
    get categoryIDPrimitiveValue():string {
        return this.primitiveValueForKey('categoryID');
    }

    // Property: categoryName
    set categoryName(value:string) {
        this.setValueForKey(value, 'categoryName');
    }
    get categoryName():string {
        return this.valueForKey('categoryName');
    }
    set categoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'categoryName');
    }
    get categoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('categoryName');
    }

    // Property: parentID
    set parentID(value:string) {
        this.setValueForKey(value, 'parentID');
    }
    get parentID():string {
        return this.valueForKey('parentID');
    }
    set parentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'parentID');
    }
    get parentIDPrimitiveValue():string {
        return this.primitiveValueForKey('parentID');
    }

    // Property: parentName
    set parentName(value:string) {
        this.setValueForKey(value, 'parentName');
    }
    get parentName():string {
        return this.valueForKey('parentName');
    }
    set parentNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'parentName');
    }
    get parentNamePrimitiveValue():string {
        return this.primitiveValueForKey('parentName');
    }

    // Property: productID
    set productID(value:string) {
        this.setValueForKey(value, 'productID');
    }
    get productID():string {
        return this.valueForKey('productID');
    }
    set productIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productID');
    }
    get productIDPrimitiveValue():string {
        return this.primitiveValueForKey('productID');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: elaborationType
    set elaborationType(value:number) {
        this.setValueForKey(value, 'elaborationType');
    }
    get elaborationType():number {
        return this.valueForKey('elaborationType');
    }
    set elaborationTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'elaborationType');
    }
    get elaborationTypePrimitiveValue():number {
        return this.primitiveValueForKey('elaborationType');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: costPrice
    set costPrice(value:number) {
        this.setValueForKey(value, 'costPrice');
    }
    get costPrice():number {
        return this.valueForKey('costPrice');
    }
    set costPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'costPrice');
    }
    get costPricePrimitiveValue():number {
        return this.primitiveValueForKey('costPrice');
    }

    // Property: formatName
    set formatName(value:string) {
        this.setValueForKey(value, 'formatName');
    }
    get formatName():string {
        return this.valueForKey('formatName');
    }
    set formatNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'formatName');
    }
    get formatNamePrimitiveValue():string {
        return this.primitiveValueForKey('formatName');
    }

    // Property: productFormatPrice
    set productFormatPrice(value:number) {
        this.setValueForKey(value, 'productFormatPrice');
    }
    get productFormatPrice():number {
        return this.valueForKey('productFormatPrice');
    }
    set productFormatPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productFormatPrice');
    }
    get productFormatPricePrimitiveValue():number {
        return this.primitiveValueForKey('productFormatPrice');
    }
}
