
class RoomBookingListViewController extends BaseTableViewController
{
    private importButton:MUIButton = null;


    viewDidLoad(){
        super.viewDidLoad();

        this.importButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.importButton.setAction(this, this.importRoomBookingAction);

        this.tableView = MUIOutlet(this, 'table-view', 'UITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as RoomGuest;
        let cell = tableview.dequeueReusableCellWithIdentifier("RoomBookingMainGuestCell") as RoomBookingListCell;
        
        cell.item = item;        
    
        return cell;
    }    
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let sd = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];
        let predicateFormat = "roomBooking != null";

        // if (this.searchString != null) {
        //     predicateFormat += " AND name CONTAINS '" + this.searchString + "'";
        // }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("RoomGuest", sd, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["roomBooking", "roomBooking.room", "roomBooking.roomBookingGroup"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    private importRoomBookingAction(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("IMPORT BOOKING", "IMPORT BOOKING"), MIOLocalizeString("TYPE THE BOOKING REFERENCE", "TYPE THE BOOKING REFERENCE"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
            textField.placeholderText = MIOLocalizeString("REFERENCE", "REFERENCE");
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("IMPORT", "IMPORT"), MUIAlertActionStyle.Default, this, function(this:RoomBookingListViewController){
            let ref = avc.textFields[0].text;
            this.importRoomBooking(ref);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle("Cancel", MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }


    private importRoomBooking(ref:string){
        if (ref == null || ref?.trim().length == 0) { AppHelper.showErrorMessage(this, "IMPORT ERROR", "REFERENCE CAN'T BE NULL"); return; }

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        ad.webService.importRoomBooking(ref, null, this, function(code, data){
            this.fetchedResultsController = null;
            this.reloadData();
        });
    }
}