
class DashboardWidgetsLayout extends MUICollectionViewFlowLayout 
{

    static dashboardLayout() {
        let l = new DashboardWidgetsLayout();
        l.init();
        return l;
    }

    invalidateLayout(){

        this.minimumLineSpacing = 0;
        this.minimumInteritemSpacing = 0;
        let w = MIOCoreIsPhone() ? this.collectionView.getWidth() : this.collectionView.getWidth() / 2;
        let h = 200; //this.collectionView.getHeight() / 3;        
        this.collectionView.collectionViewLayout.itemSize = new MIOSize(w, h);
    }
}