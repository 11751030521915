

/// <reference path="Printer.ts" />

// Generated class DockPrinter_ManagedObject

class DockPrinter_ManagedObject extends Printer
{

    // Property: printerProtocol
    set printerProtocol(value:string) {
        this.setValueForKey(value, 'printerProtocol');
    }
    get printerProtocol():string {
        return this.valueForKey('printerProtocol');
    }
    set printerProtocolPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'printerProtocol');
    }
    get printerProtocolPrimitiveValue():string {
        return this.primitiveValueForKey('printerProtocol');
    }
}
