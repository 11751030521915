
class BusinessLicenseLogDataSource extends MIOObject
{
    private tableView:UITableView = null;    
    
    initWithTableView( tableView:UITableView ) {
        this.tableView = tableView;        
    }

    private business:BusinessLicense = null;
    set item(item:BusinessLicense) {
        this.business = item;
        this.tableView.reloadData();
    }

    numberOfSections(tableview:UITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath( tableView:UITableView, indexPath:MIOIndexPath ){
        let cell = tableView.dequeueReusableCellWithIdentifier("LicenseLogCell") as LicenseLogCell;
        let item = this.fetchedResultsController.objectAtIndexPath( indexPath ) as BusinessLog;

        cell.item = item;
        // cell.delegate = this.delegate;   
        
        return cell;
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
        return UITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        if ( (MUIWebApplication.sharedInstance().delegate as AppDelegate).selectedUser.isAdmin == false ) {
            AppHelper.showAlert(null, "WARNING", "YOU DON'T HAVE PERMISSIONS");
            return;
        }

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);    

        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            DBHelper.deleteObjectFromMainContext(item, true);
        }
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let sds = [MIOSortDescriptor.sortDescriptorWithKey("date", true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName( "BusinessLog", sds, "business.identifier == " + this.business.identifier );
        // fetchRequest.relationshipKeyPathsForPrefetching = ["license"];
        // fetchRequest.fetchLimit = 100;        

        // if (this.searchString != null) {
        //     let predicateFormat = "name CONTAINS '" + this.searchString + "'";
        //     fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);
        // }        

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }
}

