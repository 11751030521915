

class GlobalActionSelectViewController extends SelectViewController {

    entityName:string = null;
    entityKey:string = null;

    get preferredContentSize(){
        return new MIOSize(320, 500);
    }

    viewDidLoad(): void {
        super.viewDidLoad();
        
        this.doneButton.title = MIOLocalizeString("NEXT", "NEXT");
    }

    // To Override
    protected configureSelectCellAtIndexPath(cell:SelectCell, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        cell.title = item.valueForKey(this.entityKey)
    }    

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {
    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);

        if (this.delegate != null)
            this.delegate.didSelectItemFromSelectViewController(this, item);

        this.dismissViewController(true);
    }
    
    get fetchedResultsController() {
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
        
        let ad = MUIWebApplication.sharedInstance().delegate;
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey(this.entityKey, true)];
    
        let fetchRequest = DBHelper.listFetchRequestWithEntityName(this.entityName, sortDescriptors, null);
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    protected doneAction(){
        
    }

}
