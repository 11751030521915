//
// Generated class OnlineBookingPaymentTransaction
//

/// <reference path="OnlineBookingPaymentTransaction_ManagedObject.ts" />

class OnlineBookingPaymentTransaction extends OnlineBookingPaymentTransaction_ManagedObject
{

}
