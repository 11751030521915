class DBFormatsCoder extends DBXLSXCoder
{
    protected mainEntity(){
        return { entity: "Format", predicateFormat: "deletedAt == null" }        
    }

    protected columns() : any[] {    
        let cols = [
            { title: "FORMAT ID", width: "10%", align: "center" },
            { title: "FORMAT NAME", width: "10%", align: "center" },
            { title: "INVITATION VALUE", width: "10%", align: "center", formatter: this.ad.integerFormatter }    
        ]
        return cols;
    }

    protected importRow(row:any){
        
        let formatID        = row["FORMAT ID"];
        let formatName      = row["FORMAT NAME"] as string;
        let invitationValue = row["INVITATION VALUE"];     

        if (formatName == null || formatName?.length == 0) return;

        let format = this.queryEntityByIDOrName("Format", formatID, formatName) as Format;
        if (formatID?.length > 0 && format == null){
            format = MIOEntityDescription.insertNewObjectForEntityForName("Format", DBHelper.mainManagedObjectContext) as Format;
            format.identifier = this.parseOrCreateIdentifier(formatID);
            format.name = formatName;  
            this.appendObject(format);
        }
        
        format.invitationValue = invitationValue;

        MIOLog("ADDING FORMAT: " + this.rowIndex + "/" + this.rows.length + ": " + formatName);
    }

    // 
    // Export
    // 
    protected exportTitle(): string { return "formats"; }
    protected exportSortDescriptors() { 
        return [MIOSortDescriptor.sortDescriptorWithKey("name", false)]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let format = object as Format;

        let item = {
            "FORMAT ID"        : format.identifier,
            "FORMAT NAME"      : format.name,
            "INVITATION VALUE" : format.invitationValue,
            "PLACE ID"         : format.placeID
        }

        return item;
    }
}