class SupplierOnlineOrderCell extends UITableViewCell
{    
    private productLabel:MUILabel = null;
    private quantityTextField:MUITextField = null;
    private priceLabel:MUILabel = null;
    private totalLabel:MUILabel = null;
           
    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;
    private cf = MUIWebApplication.sharedInstance().delegate.longCurrencyFormatter as MIONumberFormatter;
    private pnf = MUIWebApplication.sharedInstance().delegate.percentNumberFormatter;
        
    awakeFromHTML(){
        this.productLabel = MUIOutlet(this, "product-lbl", "MUILabel"); 
        this.quantityTextField = MUIOutlet(this, "quantity-tf", "MUITextField");
        this.quantityTextField.setOnChangeText(this, this.quantityDidChange);
        this.priceLabel = MUIOutlet(this, "product-price-lbl", "MUILabel");
        this.totalLabel = MUIOutlet(this, "total-price-lbl", "MUILabel");

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private _item = null;
    set item(item:any){
        this._item = item;

        this.productLabel.text = item["name"];
        this.quantityTextField.text = item["quantity"];
        this.quantityTextField.formatter = this.nf;
        this.priceLabel.text = this.cf.stringFromNumber(item["price"]);

        this.updateUI();
    }

    private quantityDidChange(textField:MUITextField){
        let q = textField.text.length > 0 ? this.nf.numberFromString(textField.text) : 0;
        let total = this._item["price"] * q;
        this._item["total"] = total;

        this.updateUI();
    }

    private updateUI(){
        this.totalLabel.text = this.cf.stringFromNumber(this._item["total"]);
    }

    // protected productPriceString(){
    //     return this.cf.stringFromNumber(this.productPrice);
    // }

    // protected totalPriceString(){        
    //     return this.cf.stringFromNumber(this.totalPrice);
    // }

    //
    // Lines Updates
    //


    //
    // Delegate 
    //

    // private totalWillChange(total:number){
    //     if (this.delegate == null || typeof this.delegate.baseAmountWillChange !== "function") return;
    //     this.delegate.baseAmountWillChange(total);
    // }

    // private totalDidChange(total:number){
    //     if (this.delegate == null || typeof this.delegate.baseAmountDidChange !== "function") return;
    //     this.delegate.baseAmountDidChange(total);
    // }

    // private taxWillChange(tax:Tax){
    //     if (this.delegate == null || typeof this.delegate.taxWillChange !== "function") return;
    //     this.delegate.taxWillChange(tax);
    // }

    // private taxDidChange(tax:Tax){
    //     if (this.delegate == null || typeof this.delegate.taxDidChange !== "function") return;
    //     this.delegate.taxDidChange(tax);
    // }
}
