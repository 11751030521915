
class SupplierOnlineOrderViewController extends MUIViewController
{
    
    private documentLabel:MUILabel = null;
    private destinationLabel:MUILabel = null;
    private documentDateLabel:MUILabel = null;
    private validationDateLabel:MUILabel = null;
    
    private processButton:MUIButton = null;
    private tableView:UITableView = null;

    private importProductButton:MUIButton = null;
    private searchString:MUITextField = null;

    private dtf = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter as MIODateFormatter;
    
    viewDidLoad(){
        super.viewDidLoad();

        this.documentLabel = MUIOutlet(this, "document-id-lbl", "MUILabel");
        this.destinationLabel = MUIOutlet(this, "destination-lbl", "MUILabel");
        this.documentDateLabel = MUIOutlet(this, "document-date-lbl", "MUILabel");        
        this.validationDateLabel = MUIOutlet(this, "stock-date-lbl", "MUILabel");
        this.processButton = MUIOutlet(this, "change-status-btn", "MUIButton");
        this.processButton.setAction(this, this.processAction);

        // this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        // this.setupSearchTextField();

        // this.importProductButton = MUIOutlet(this, "import-products-btn", "MUIButton");
        // this.importProductButton.setAction(this, this.fetchSupplierProducts);

        this.tableView = MUIOutlet(this, 'table-view', 'UITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    protected updateUI(){        
        if (this.viewIsLoaded == false || this.stockNote == null) return;

        this.documentLabel.text = this.stockNote.documentID;
        this.destinationLabel.text = this.stockNote.destinationName;        
        
        let documentDateString = this.dtf.stringFromDate(this.stockNote.documentDate);
        this.documentDateLabel.text = documentDateString;
        
        this.validationDateLabel.text = null;

        // this.fetchedResultsController = null;
        // this.tableView.reloadData();                

        this.fetchOnlineProducts();
        this.fetchStockLines();
    }

    numberOfSections(tableView:UITableView){
        return 1;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        return this.products.length;
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){        
                
        let item = this.products[indexPath.row];        
        let cell = tableview.dequeueReusableCellWithIdentifier('EditLineCell') as SupplierOnlineOrderCell;
        cell.item = item;
        // cell.delegate = this;
        
        return cell;
    }

    private stockNote:StockNote = null;
    set note(note:StockNote){
        this.stockNote = note;
        this.updateUI();
    }

    private products = []
    private products_mapped = false;
    private onlineProducts = null;
    private fetchOnlineProducts(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let supplier = this.supplierFromNote(this.stockNote) as Supplier;
        ad.webService.fetchOnlineProducts(supplier.onlineURL, this, function(this:SupplierOnlineOrderViewController, products:any[]){
            this.onlineProducts = products;
            this.map_online_products();
        });
    }

    private stockLines = null;
    private fetchStockLines(){
        let predicateFormat = "note.identifier = " + this.stockNote.identifier + " AND deletedAt = null";
        let predicate = MIOPredicate.predicateWithFormat(predicateFormat);
        DBHelper.queryObjectsWithCompletion("StockNoteLine", null, predicate, ["product"], this, function(this:SupplierOnlineOrderViewController, lines:StockNoteLine[]){
            this.stockLines = lines;
            this.map_online_products();
        });
    }


    private map_online_products(){
        if (this.onlineProducts == null || this.stockLines == null) return;

        this.products = [];
        for (let p of this.onlineProducts){
            p["quantity"] = 0;
            p["total"] = 0;
            this.products.addObject(p);
        }
                
        this.tableView.reloadData();
    }

    protected fetchSupplierProducts() {
        let predicateFormat = "supplier.identifier = " + this.stockNote.destinationEntity.identifier;

        let predicate = MIOPredicate.predicateWithFormat(predicateFormat);
        DBHelper.queryObjectsWithCompletion("SupplierProduct", null, predicate, ["product"], this, function(supplierProducts:[SupplierProduct]){
            for(let i=0; i<supplierProducts.length; i++) {
                let [price, discount] = DBHelper.costFromProductAndSupplier(supplierProducts[i].product, supplierProducts[i].supplier);
                
                DBHelper.createSupplierOrderLine(supplierProducts[i].product,
                                                null,
                                                supplierProducts[i].product.measureType,
                                                0,                                                 
                                                0, 
                                                price,
                                                price,
                                                0,  
                                                null, 
                                                0,                                               
                                                this.stockNote);
            }
            DBHelper.saveMainContext();
        }); 
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('createdAt', true)];

        let predicateFormat = 'note.identifier == ' + this.stockNote.identifier;
        if (this.searchString != null) {
            predicateFormat += " AND productName CONTAINS '" + this.searchString + "'";
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("StockNoteLine", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["tax", "inputFormat"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }   
    
    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        this.tableView.reloadData();
    }

    supplierFromNote(sender){
        return this.stockNote.destinationEntity;
    }

    private processAction(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        
        let url = (this.stockNote.destinationEntity as Supplier).onlineURL;
        // let order = DLDB.sharedInstance().createOnlineOrderFromSupplierOrder(this.stockNote as SupplierOrder);
        
        // ad.webService.checkOutOnlineOrder(url, order, this, function(this:SupplierOnlineOrderViewController, paymentURL:string){
        //     this.showOnlinePaymentViewController(paymentURL, order);
        // });
    }    

    private showOnlinePaymentViewController(url:string, order:any){
        let vc = new OnlinePaymentViewController("online-payment-view");
        vc.initWithResource("layout/online_payment/OnlinePaymentView.html");
                
        vc.context = { "OnlineOrder": order, "StockNoteID": this.stockNote.identifier, "paymentURL": url };

        this.presentViewController(vc, true);
    }

}