
class MainViewController extends MUIViewController
{    
    private _contentView:MUIView = null;

    get contentView(){
        if (this._contentView != null) return this._contentView;

        this._contentView = new MUIView();
        this._contentView.init();
        this.view.addSubview(this._contentView);

        return this._contentView;
    }

    viewDidLoad(){
        super.viewDidLoad();

        this._contentView = MUIOutlet(this, "content-view", "MUIView");
        let vc = new LaunchScreenViewController("launch-screen-view");
        vc.initWithResource("layout/launchscreen/LaunchScreenView.html");
        this.contentView.addSubview(vc.view);
        this.addChildViewController(vc);
        this.currentViewController = vc;

        MIONotificationCenter.defaultCenter().addObserver(this, "WorkspaceDidChange", function (this:MainViewController, notification) {
            let business = notification.object;
            // let config = notification.userInfo;
            this.setWorkspaceData(business);
        });

        MIONotificationCenter.defaultCenter().addObserver(this, "ShowSection", function(notification:MIONotification){
            let section = notification.object as ManagerSection;
            this.showSectionViewController(section.identifier);
        });
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        if (ad.loginOK == false) {
            this.showLoginViewController();
        }
    }
    
    private showLoginViewController(){

        let vc:LoginViewController = null;

        if (MIOCoreIsPhone() == true){
            vc = new LoginViewController("login-view_phone");
            vc.initWithResource("layout/login/LoginView_phone.html");    
        }
        else {
            vc = new LoginViewController("login-view_desktop");
            vc.initWithResource("layout/login/LoginView_desktop.html");            
        }

        let nc = new MUINavigationController();
        nc.initWithRootViewController(vc);        
        if (MIOCoreIsPhone() == false) {
            nc.modalPresentationStyle = MUIModalPresentationStyle.FullScreen;
            nc.transitioningDelegate = this;
        }

        this.presentViewController(nc, true);
    }

    private currentViewControllerType = null;
    private currentViewController:MUIViewController = null;
    showSectionViewController(type:SectionViewControllerType){

        if (this.currentViewControllerType == type) return;

        let vc = SectionHelper.sharedInstance().sectionViewControllerForType(type);
        if (vc == null) return;        

        let oldVC = this.currentViewController;

        vc.onLoadView(this, function () {
            this._contentView.addSubview(vc.view);
            this.addChildViewController(vc);

            _MIUShowViewController(oldVC, vc, this, false, this, function () {
                this.removeChildViewController(oldVC);
                oldVC.view.removeFromSuperview();
            });
        });    

        this.currentViewController = vc;
        this.currentViewControllerType = type;
    }

    private setWorkspaceData(business:Business) {
        this.currentViewControllerType = null;
        this.workspaceDidChange(business); 
                
        if (SectionHelper.sharedInstance().canSeeDashBoard) this.showSectionViewController(SectionViewControllerType.DashBoard);
        else this.showSectionViewController(SectionViewControllerType.None);
    }

    // VIRTUAL
    protected workspaceDidChange(business:Business){}

    private loginShowAnimationController = null;
    private loginHideAnimationController = null;
    animationControllerForPresentedController(presentedViewController:MUIViewController, presentingViewController:MUIViewController, sourceController:MUIViewController){
        
        if (this.loginShowAnimationController == null) {
            this.loginShowAnimationController = new LoginShowAnimationController();
            this.loginShowAnimationController.init();
        }

        return this.loginShowAnimationController;    
    }

    animationControllerForDismissedController(dismissedController:MUIViewController){
        if (this.loginHideAnimationController == null) {
            this.loginHideAnimationController = new LoginHideAnimationController();
            this.loginHideAnimationController.init();
        }

        return this.loginHideAnimationController;
    }

}


/*
    ANIMATIONS
 */

class LoginShowAnimationController extends MIOObject
{
    transitionDuration(transitionContext){
        return 0.25;
    }

    animateTransition(transitionContext){
        // make view configurations before transitions
    }

    animationEnded(transitionCompleted){
        // make view configurations after transitions
    }

    // TODO: Not iOS like transitions. For now we use css animations
    animations(transitionContext){
        let animations = MUIClassListForAnimationType(MUIAnimationType.ZoomIn);
        return animations;
    }

}

class LoginHideAnimationController extends MIOObject
{
    transitionDuration(transitionContext){
        return 0.75;
    }

    animateTransition(transitionContext){
        // make view configurations after transitions
    }

    animationEnded(transitionCompleted){
        // make view configurations before transitions
    }

    // TODO: Not iOS like transitions. For now we use css animations
    animations(transitionContext){
        let animations = MUIClassListForAnimationType(MUIAnimationType.ZoomOut);
        return animations;
    }

}
