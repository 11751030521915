//
// Generated class DevicePrintProfileConfiguration
//

/// <reference path="DevicePrintProfileConfiguration_ManagedObject.ts" />

class DevicePrintProfileConfiguration extends DevicePrintProfileConfiguration_ManagedObject
{

}
