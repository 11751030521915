//
// Generated class ProductInventory
//

/// <reference path="ProductInventory_ManagedObject.ts" />

class ProductInventory extends ProductInventory_ManagedObject
{

}
