

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class LocationCategory_ManagedObject

class LocationCategory_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: backgroundType
    set backgroundType(value:number) {
        this.setValueForKey(value, 'backgroundType');
    }
    get backgroundType():number {
        return this.valueForKey('backgroundType');
    }
    set backgroundTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'backgroundType');
    }
    get backgroundTypePrimitiveValue():number {
        return this.primitiveValueForKey('backgroundType');
    }

    // Property: hidden
    set hidden(value:boolean) {
        this.setValueForKey(value, 'hidden');
    }
    get hidden():boolean {
        return this.valueForKey('hidden');
    }
    set hiddenPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'hidden');
    }
    get hiddenPrimitiveValue():boolean {
        return this.primitiveValueForKey('hidden');
    }

    // Property: idIn
    set idIn(value:number) {
        this.setValueForKey(value, 'idIn');
    }
    get idIn():number {
        return this.valueForKey('idIn');
    }
    set idInPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'idIn');
    }
    get idInPrimitiveValue():number {
        return this.primitiveValueForKey('idIn');
    }

    // Property: isAvailable
    set isAvailable(value:boolean) {
        this.setValueForKey(value, 'isAvailable');
    }
    get isAvailable():boolean {
        return this.valueForKey('isAvailable');
    }
    set isAvailablePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isAvailable');
    }
    get isAvailablePrimitiveValue():boolean {
        return this.primitiveValueForKey('isAvailable');
    }

    // Property: minimumFee
    set minimumFee(value:number) {
        this.setValueForKey(value, 'minimumFee');
    }
    get minimumFee():number {
        return this.valueForKey('minimumFee');
    }
    set minimumFeePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minimumFee');
    }
    get minimumFeePrimitiveValue():number {
        return this.primitiveValueForKey('minimumFee');
    }

    // Property: minimumFeeByPax
    set minimumFeeByPax(value:boolean) {
        this.setValueForKey(value, 'minimumFeeByPax');
    }
    get minimumFeeByPax():boolean {
        return this.valueForKey('minimumFeeByPax');
    }
    set minimumFeeByPaxPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'minimumFeeByPax');
    }
    get minimumFeeByPaxPrimitiveValue():boolean {
        return this.primitiveValueForKey('minimumFeeByPax');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Relationship: automaticProducts
    protected _automaticProducts:MIOManagedObjectSet = null;
    get automaticProducts():MIOManagedObjectSet {
        return this.valueForKey('automaticProducts');
    }
    addAutomaticProductsObject(value:LocationCategoryAutoProduct) {
        this._addObjectForKey(value, 'automaticProducts');
    }
    removeAutomaticProductsObject(value:LocationCategoryAutoProduct) {
        this._removeObjectForKey(value, 'automaticProducts');
    }
    // Relationship: businessArea
    set businessArea(value:BusinessArea) {
        this.setValueForKey(value, 'businessArea');
    }
    get businessArea():BusinessArea {
        return this.valueForKey('businessArea') as BusinessArea;
    }
    // Relationship: consumptionProfile
    set consumptionProfile(value:ConsumptionProfile) {
        this.setValueForKey(value, 'consumptionProfile');
    }
    get consumptionProfile():ConsumptionProfile {
        return this.valueForKey('consumptionProfile') as ConsumptionProfile;
    }

    // Relationship: locations
    protected _locations:MIOManagedObjectSet = null;
    get locations():MIOManagedObjectSet {
        return this.valueForKey('locations');
    }
    addLocationsObject(value:Location) {
        this._addObjectForKey(value, 'locations');
    }
    removeLocationsObject(value:Location) {
        this._removeObjectForKey(value, 'locations');
    }
    // Relationship: rate
    set rate(value:Rate) {
        this.setValueForKey(value, 'rate');
    }
    get rate():Rate {
        return this.valueForKey('rate') as Rate;
    }
}
