//
// Generated class LicenseType
//

/// <reference path="LicenseType_ManagedObject.ts" />

class LicenseType extends LicenseType_ManagedObject
{

}
