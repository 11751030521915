//
// Generated class GenericInvitationRule
//

/// <reference path="GenericInvitationRule_ManagedObject.ts" />

class GenericInvitationRule extends GenericInvitationRule_ManagedObject
{

}
