

class StockBlockTypeViewController extends MUIViewController
{    
    delegate = null;

    private noBlockButton:MUIButton = null;
    private blockByProductButton:MUIButton = null;
    private blockByComponentsButton:MUIButton = null;

    get preferredContentSize(){
        return new MIOSize(500, 400);
    }

    viewDidLoad(){
        super.viewDidLoad();

        this.noBlockButton = MUIOutlet(this, "no-block-btn", "MUIButton");
        this.noBlockButton.tag = StockBlockType.None;
        this.noBlockButton.setAction(this, this.optionDidSelected);

        this.blockByProductButton = MUIOutlet(this, "block-by-product-btn", "MUIButton");
        this.blockByProductButton.tag = StockBlockType.Product;
        this.blockByProductButton.setAction(this, this.optionDidSelected);

        this.blockByComponentsButton = MUIOutlet(this, "block-by-component-btn", "MUIButton");
        this.blockByComponentsButton.tag = StockBlockType.Components;
        this.blockByComponentsButton.setAction(this, this.optionDidSelected);
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();        
    }

    private _product:Product = null;
    set product(value){
        this._product = value;  
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._product == null) return;
        MUICoreLayerRemoveStyle(this.noBlockButton.layer, "checked");
        MUICoreLayerRemoveStyle(this.blockByProductButton.layer, "checked");
        MUICoreLayerRemoveStyle(this.blockByComponentsButton.layer, "checked");

        switch (this._product.stockBlockType) {
            case StockBlockType.None:
            MUICoreLayerAddStyle(this.noBlockButton.layer, "checked");
            break;

            case StockBlockType.Product:
            MUICoreLayerAddStyle(this.blockByProductButton.layer, "checked");
            break;

            case StockBlockType.Components:
            MUICoreLayerAddStyle(this.blockByComponentsButton.layer, "checked");
            break;
        }
    }

    private optionDidSelected(button:MUIButton){
        this._product.stockBlockType = button.tag;
        this.updateUI();
        if (this.delegate != null) this.delegate.stockBlockTypeDidUpdate();
        this.dismissViewController(true);
    }

}