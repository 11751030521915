//
// Generated class ModifierLine
//

/// <reference path="ModifierLine_ManagedObject.ts" />

class ModifierLine extends ModifierLine_ManagedObject
{

}
