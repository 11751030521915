
class SupplierOrderDetailViewController extends NoteDetailViewController
{
    private noEditHeaderView:SupplierOrderDetailHeader = null;
    private editHeaderView:SupplierOrderDetailHeader = null;   

    private previewButton:MUIButton = null;
    
    viewDidLoad(){
        super.viewDidLoad();

        this.noEditHeaderView = MUIOutlet(this, "no-edit-header-view", "SupplierOrderDetailHeader");
        this.editHeaderView = MUIOutlet(this, "edit-header-view", "SupplierOrderDetailHeader");
        
        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.previewButton = MUIOutlet(this, "preview-btn", "MUIButton");
        // this.previewButton.setAction(this, this.fetchSupplierProducts);
    }

    protected updateUI(){        
        this.editHeaderView.note = this.stockNote;
        this.noEditHeaderView.note = this.stockNote;
        this.editHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? true : false;
        this.noEditHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? false : true;

        this.previewButton.hidden = this.stockNote.status == StockNoteStatus.Processed ? true : false;

        this.invalidateData();
        this.tableView.reloadData();
    }

    protected fetchSupplierProducts() {
        let predicateFormat = "supplier.identifier = " + this.stockNote.destinationEntity.identifier;

        DBHelper.queryObjectsWithCompletion("SupplierProduct", null, MIOPredicate.predicateWithFormat(predicateFormat), ["product"], this, function(supplierProducts:[SupplierProduct]){
            for(let i=0; i<supplierProducts.length; i++) {
                let [price, discount] = DBHelper.costFromProductAndSupplier(supplierProducts[i].product, supplierProducts[i].supplier);
                
                if (price == null && discount == null) continue;

                DBHelper.createSupplierOrderLine(supplierProducts[i].product,
                                                null,
                                                supplierProducts[i].product.measureType,
                                                0,
                                                0, 
                                                price,
                                                price,
                                                0, 
                                                null, 
                                                0,                                               
                                                this.stockNote);
            }
            DBHelper.saveMainContext();
        }); 
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('createdAt', true)];

        let predicateFormat = 'note.identifier == ' + this.stockNote.identifier;
        if (this.searchString != null) {
            predicateFormat += " AND productName CONTAINS '" + this.searchString + "'";
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("SupplierOrderLine", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["tax", "inputFormat", "product", "product.defaultWarehouse"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }   

    // controllerDidChangeContent(controller:MIOFetchedResultsController){
    //     super.controllerDidChangeContent(controller);
    //     this.updateTotal();        
    // }
    
    supplierFromNote(sender:any){
        return this.stockNote.destinationEntity;
    }
    
    protected updateTotals(){
        // let [base, taxes, total] = this.computeTotals();
        this.totalLabel.text = this.cf.stringFromNumber(this.total);
    }

    protected customActionsForStatus(status:StockNoteStatus): any[] {

        let actions = []

        if (status == StockNoteStatus.None || status == StockNoteStatus.Pending){            
            let item = {
                "Type": "Action", 
                "Action": MUIAlertAction.alertActionWithTitle(MIOLocalizeString('IMPORT PRODUCTS', 'IMPORT PRODUCTS'), MUIAlertActionStyle.Default, this, function(this:SupplierOrderDetailViewController){
                    this.fetchSupplierProducts();
                })   
            }
            actions.addObject(item);
        }
    
        let acts = super.customActionsForStatus(status);
        for (let i = 0; i < acts.length; i++) actions.addObject(acts[i]);
        return actions;
    }

}