

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class PaymentTransaction_ManagedObject

class PaymentTransaction_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: amount
    set amount(value:number) {
        this.setValueForKey(value, 'amount');
    }
    get amount():number {
        return this.valueForKey('amount');
    }
    set amountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'amount');
    }
    get amountPrimitiveValue():number {
        return this.primitiveValueForKey('amount');
    }

    // Property: cardTokenID
    set cardTokenID(value:string) {
        this.setValueForKey(value, 'cardTokenID');
    }
    get cardTokenID():string {
        return this.valueForKey('cardTokenID');
    }
    set cardTokenIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'cardTokenID');
    }
    get cardTokenIDPrimitiveValue():string {
        return this.primitiveValueForKey('cardTokenID');
    }

    // Property: currencyReference
    set currencyReference(value:string) {
        this.setValueForKey(value, 'currencyReference');
    }
    get currencyReference():string {
        return this.valueForKey('currencyReference');
    }
    set currencyReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'currencyReference');
    }
    get currencyReferencePrimitiveValue():string {
        return this.primitiveValueForKey('currencyReference');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: documentNumber
    set documentNumber(value:string) {
        this.setValueForKey(value, 'documentNumber');
    }
    get documentNumber():string {
        return this.valueForKey('documentNumber');
    }
    set documentNumberPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentNumber');
    }
    get documentNumberPrimitiveValue():string {
        return this.primitiveValueForKey('documentNumber');
    }

    // Property: error
    set error(value:string) {
        this.setValueForKey(value, 'error');
    }
    get error():string {
        return this.valueForKey('error');
    }
    set errorPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'error');
    }
    get errorPrimitiveValue():string {
        return this.primitiveValueForKey('error');
    }

    // Property: expirationDate
    set expirationDate(value:Date) {
        this.setValueForKey(value, 'expirationDate');
    }
    get expirationDate():Date {
        return this.valueForKey('expirationDate');
    }
    set expirationDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'expirationDate');
    }
    get expirationDatePrimitiveValue():Date {
        return this.primitiveValueForKey('expirationDate');
    }

    // Property: externalTransactionID
    set externalTransactionID(value:string) {
        this.setValueForKey(value, 'externalTransactionID');
    }
    get externalTransactionID():string {
        return this.valueForKey('externalTransactionID');
    }
    set externalTransactionIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'externalTransactionID');
    }
    get externalTransactionIDPrimitiveValue():string {
        return this.primitiveValueForKey('externalTransactionID');
    }

    // Property: isRefunded
    set isRefunded(value:boolean) {
        this.setValueForKey(value, 'isRefunded');
    }
    get isRefunded():boolean {
        return this.valueForKey('isRefunded');
    }
    set isRefundedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isRefunded');
    }
    get isRefundedPrimitiveValue():boolean {
        return this.primitiveValueForKey('isRefunded');
    }

    // Property: legalEntityName
    set legalEntityName(value:string) {
        this.setValueForKey(value, 'legalEntityName');
    }
    get legalEntityName():string {
        return this.valueForKey('legalEntityName');
    }
    set legalEntityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalEntityName');
    }
    get legalEntityNamePrimitiveValue():string {
        return this.primitiveValueForKey('legalEntityName');
    }

    // Property: locationName
    set locationName(value:string) {
        this.setValueForKey(value, 'locationName');
    }
    get locationName():string {
        return this.valueForKey('locationName');
    }
    set locationNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationName');
    }
    get locationNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationName');
    }

    // Property: merchantReference
    set merchantReference(value:string) {
        this.setValueForKey(value, 'merchantReference');
    }
    get merchantReference():string {
        return this.valueForKey('merchantReference');
    }
    set merchantReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'merchantReference');
    }
    get merchantReferencePrimitiveValue():string {
        return this.primitiveValueForKey('merchantReference');
    }

    // Property: notifyData
    set notifyData(value:any) {
        this.setValueForKey(value, 'notifyData');
    }
    get notifyData():any {
        return this.valueForKey('notifyData');
    }
    set notifyDataPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'notifyData');
    }
    get notifyDataPrimitiveValue():any {
        return this.primitiveValueForKey('notifyData');
    }

    // Property: notifySchema
    set notifySchema(value:string) {
        this.setValueForKey(value, 'notifySchema');
    }
    get notifySchema():string {
        return this.valueForKey('notifySchema');
    }
    set notifySchemaPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'notifySchema');
    }
    get notifySchemaPrimitiveValue():string {
        return this.primitiveValueForKey('notifySchema');
    }

    // Property: orderNumber
    set orderNumber(value:string) {
        this.setValueForKey(value, 'orderNumber');
    }
    get orderNumber():string {
        return this.valueForKey('orderNumber');
    }
    set orderNumberPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderNumber');
    }
    get orderNumberPrimitiveValue():string {
        return this.primitiveValueForKey('orderNumber');
    }

    // Property: paymentDate
    set paymentDate(value:Date) {
        this.setValueForKey(value, 'paymentDate');
    }
    get paymentDate():Date {
        return this.valueForKey('paymentDate');
    }
    set paymentDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'paymentDate');
    }
    get paymentDatePrimitiveValue():Date {
        return this.primitiveValueForKey('paymentDate');
    }

    // Property: payMethodSubtype
    set payMethodSubtype(value:string) {
        this.setValueForKey(value, 'payMethodSubtype');
    }
    get payMethodSubtype():string {
        return this.valueForKey('payMethodSubtype');
    }
    set payMethodSubtypePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'payMethodSubtype');
    }
    get payMethodSubtypePrimitiveValue():string {
        return this.primitiveValueForKey('payMethodSubtype');
    }

    // Property: payMethodType
    set payMethodType(value:number) {
        this.setValueForKey(value, 'payMethodType');
    }
    get payMethodType():number {
        return this.valueForKey('payMethodType');
    }
    set payMethodTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'payMethodType');
    }
    get payMethodTypePrimitiveValue():number {
        return this.primitiveValueForKey('payMethodType');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: transactionReference
    set transactionReference(value:string) {
        this.setValueForKey(value, 'transactionReference');
    }
    get transactionReference():string {
        return this.valueForKey('transactionReference');
    }
    set transactionReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'transactionReference');
    }
    get transactionReferencePrimitiveValue():string {
        return this.primitiveValueForKey('transactionReference');
    }

    // Property: transactionSequence
    set transactionSequence(value:number) {
        this.setValueForKey(value, 'transactionSequence');
    }
    get transactionSequence():number {
        return this.valueForKey('transactionSequence');
    }
    set transactionSequencePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'transactionSequence');
    }
    get transactionSequencePrimitiveValue():number {
        return this.primitiveValueForKey('transactionSequence');
    }

    // Property: userInfo
    set userInfo(value:any) {
        this.setValueForKey(value, 'userInfo');
    }
    get userInfo():any {
        return this.valueForKey('userInfo');
    }
    set userInfoPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'userInfo');
    }
    get userInfoPrimitiveValue():any {
        return this.primitiveValueForKey('userInfo');
    }
    // Relationship: cashDeskLine
    set cashDeskLine(value:CashDeskLine) {
        this.setValueForKey(value, 'cashDeskLine');
    }
    get cashDeskLine():CashDeskLine {
        return this.valueForKey('cashDeskLine') as CashDeskLine;
    }
    // Relationship: legalEntity
    set legalEntity(value:LegalEntity) {
        this.setValueForKey(value, 'legalEntity');
    }
    get legalEntity():LegalEntity {
        return this.valueForKey('legalEntity') as LegalEntity;
    }
    // Relationship: location
    set location(value:Location) {
        this.setValueForKey(value, 'location');
    }
    get location():Location {
        return this.valueForKey('location') as Location;
    }
    // Relationship: paymentEntity
    set paymentEntity(value:PaymentEntity) {
        this.setValueForKey(value, 'paymentEntity');
    }
    get paymentEntity():PaymentEntity {
        return this.valueForKey('paymentEntity') as PaymentEntity;
    }
}
