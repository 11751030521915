class CardListViewController extends BaseTableViewController
{
    private segmentedControl:MUISegmentedControl = null;
    private saveButton:MUIButton = null;
    private addButton:MUIButton = null;

    private actionButton:MUIButton = null;
    private addRuleButton:MUIButton = null;

    private rulesTableView:MUITableView = null;

    private rulesDataSource:CardRulesDataSource = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.segmentedControl = MUIOutlet(this, "segmented-control", "MUISegmentedControl");
        this.segmentedControl.setAction(this, function(this:CardListViewController){
            this.setFetchedResultsController(null);
            this.tableView.reloadData();
        });

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        });

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function(){
            this.showAddNewCardOptionsAlert();
        });

        this.actionButton = MUIOutlet(this, "action-btn", "MUIButton");
        this.actionButton.setAction(this, function(){
            //this.deleteAll();
            //this.importCards();
        });

        this.addRuleButton = MUIOutlet(this, "add-rule-btn", "MUIButton");
        this.addRuleButton.setAction(this, function(){
            this.addCardRule();
        });

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.tableView = MUIOutlet(this, "cards-tableview", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        
        this.rulesTableView = MUIOutlet(this, "rules-tableview", "MUITableView");
        this.rulesDataSource = new CardRulesDataSource();
        this.rulesDataSource.initWithTableView(this.rulesTableView);        
        this.rulesTableView.dataSource = this.rulesDataSource;
        this.rulesTableView.delegate = this.rulesDataSource;        
    }
    
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let item:Card = this.fetchedResultsController.objectAtIndexPath(indexPath);

        let cell = null;
        switch(this.segmentedControl.selectedSegmentedIndex) {
        case 0:
            cell = tableview.dequeueReusableCellWithIdentifier("CardCell") as CardCell;
            cell.item = item;
            return cell;
        case 1:
            cell = tableview.dequeueReusableCellWithIdentifier("CardCell") as CardCell;
            cell.item = item;
            return cell;
        case 2:
            cell = tableview.dequeueReusableCellWithIdentifier("AnonymousCardCell") as AnonymousCardCell;        
            cell.item = item;    
            return cell;        
        }

        return null;
    }
        
    private selectedCard:Card = null;
    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Card;
        this.rulesDataSource.card = item;
        this.selectedCard = item;
    }

    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Card;

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            this.removeCard(item);
        }
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let sd = [MIOSortDescriptor.sortDescriptorWithKey('nameString', true)];
        let predicateFormat = null;

        switch (this.segmentedControl.selectedSegmentedIndex) {
            case 0: predicateFormat = "legalEntity != null"; break;
            case 1: predicateFormat = "legalEntity != null"; break;
            case 2: predicateFormat = "legalEntity == null"; break;
        }

        if (this.searchString != null) {
            predicateFormat = "name CONTAINS '" + this.searchString + "' OR barcode CONTAINS '" + this.searchString +"'";
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Card", sd, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ['legalEntity'];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    private showAddNewCardOptionsAlert(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ADD NEW CARD", "ADD NEW CARD"), MIOLocalizeString("CHOSE CARD TYPE", "CHOSE CARD TYPE"), MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("WORKER", "WORKER"), MUIAlertActionStyle.Default, this, function(){
            this.showSelectWorkersViewController();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("EXTERNAL", "EXTERNAL"), MUIAlertActionStyle.Default, this, function(){
            this.showAddNewCardAlert(null);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("ANONYMOUS", "ANONYMOUS"), MUIAlertActionStyle.Default, this, function(){
            this.showAddNewAnonymousCardAlert();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }    
    
    private showAddNewCardAlert(worker:Employee){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ADD NEW CARD","ADD NEW CARD"), MIOLocalizeString("FILL UP ALL NECESARRY INFORMATION","Fill up all necesarry information"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("CODE","CODE"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle("OK", MUIAlertActionStyle.Default, this, function(){
            let nameTextField = avc.textFields[0] as MUITextField;
            let codeTextField = avc.textFields[1] as MUITextField;
            let name = nameTextField.text;
            let code = codeTextField.text;

            this.addNewCard(name, code, worker);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL","CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private showAddNewAnonymousCardAlert(worker:Employee){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ADD NEW CARD","ADD NEW CARD"), MIOLocalizeString("FILL UP ALL NECESARRY INFORMATION","FILL UP ALL NECESARRY INFORMATION"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("PREFIX", "PREFIX"));
        });
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("START NUMBER", "START NUMBER"));
        });
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("END NUMBER", "END NUMBER"));
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("LOYALTY AMOUNT", "LOYALTY AMOUNT"));
        });

        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
            comboBox.removeAllItems();
            comboBox.addItem("EAN 13", PromotionalCodeType.EAN13);
            comboBox.addItem("EAN 8", PromotionalCodeType.EAN8);
            comboBox.addItem("Custom", PromotionalCodeType.Custom);                        

            // Set the last type code generator used.
            let value = MIOUserDefaults.standardUserDefaults().valueForKey("LastOfferTypeSelected");
            if (value == null) comboBox.selectItem(PromotionalCodeType.Custom);
            else comboBox.selectItem(value);
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("EXPIRATION DATE", "EXPIRATION DATE"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle( MIOLocalizeString("GENERATE", "GENERATE"), MUIAlertActionStyle.Default, this, function(this:CardListViewController){
            let prefix:string = avc.textFields[0].text;
            let startIndex = parseInt(avc.textFields[1].text);
            let endIndex = parseInt(avc.textFields[2].text);
            let type = avc.comboBoxes[0].getSelectedItem();
            let amount = avc.textFields[3].text;

            this.generateAnonymousCard(parseInt(type), prefix, startIndex, endIndex, amount);
        }));


        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL","CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }
    
    private showSelectWorkersViewController(){
        let vc = AppHelper.sharedInstance().selectViewController("Employee", "name", false, false, null, this) as SelectEntityViewController;
        vc.allowSearch = true;            
                 
        this.presentViewController(vc, true);
    }

    fetchRequestForController(controller:SelectEntityViewController):MIOFetchRequest{
        
        if (controller.identifier == "Employee"){       
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Employee", sd, null);
            return fetchRequest;
        }

        return null;
    }

    didSelectObjectFromSelectViewController(controller:SelectEntityViewController, item:MIOManagedObject){
        let dismiss = true;

        if (controller.identifier == "Employee") {
            let employee = item as Employee;
            this.showAddNewCardAlert(employee);
        }

        return dismiss;
    }

    private addNewCard(name:string, code:string, legalEntity:LegalEntity){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let card = MIOEntityDescription.insertNewObjectForEntityForName("Card", ad.managedObjectContext) as Card;

        // card.name = name;        
        card.cardType = CardType.None;
        card.barcode = code;
        card.legalEntity = legalEntity;

        ad.managedObjectContext.save();
    }

    private addCardRule(){      

        if (this.selectedCard == null) return;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;  
        let rule = MIOEntityDescription.insertNewObjectForEntityForName("OfferRule", ad.managedObjectContext) as OfferRule;
        // rule.conditionType = OfferRuleConditionType.IsPartyActive;
        // rule.conditionValue = "true";
        // rule.discount = 0;
        // rule.invitationLimitInSession = 2;
        rule.economicEntity = this.selectedCard;

        ad.managedObjectContext.save();
    }

    private deleteAll(){

       let objs = this.fetchedResultsController.fetchedObjects;

       let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

       for (let index = 0; index < objs.length; index++){
            let c = objs[index] as Card;

            let rule2 = MIOEntityDescription.insertNewObjectForEntityForName("OfferRule", ad.managedObjectContext) as OfferRule;
            rule2.conditionType = OfferRuleConditionType.IsPartyActive;
            rule2.conditionValue = "true";
            rule2.discount = 0;
            rule2.invitationLimitInSession = 2;
            rule2.economicEntity = c;            

            ad.managedObjectContext.save();

       }
    }

    private removeCard(card:Card){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.managedObjectContext.deleteObject(card);
        ad.managedObjectContext.save();
    }

    private importCards(){
        this.openPanel();
    }

    private openPanel(){
        let panel = MUIOpenPanel.openPanel();
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        panel.beginSheetModalForWindow(ad.window, this, function(result:number){
            if (result == MIOFileHandlingPanel.OKButton) {
               let file = panel.files[0];
               // Open  the document.
                this.importCardsFromFile(file);
            }       
         });      
    }

    private importCardsFromFile(file){
        let reader = new FileReader();

        let instance = this;
        reader.onload = function(e) {
            let data = reader.result;
            let workbook = XLSX.read(data, {
                type: 'binary'
            });
        
            workbook.SheetNames.forEach(function(sheetName) {
                // Here is your object
                let worksheet = workbook.Sheets[sheetName];                
                let result = [];
                let row;
                let rowNum;
                let colNum;
                let range = XLSX.utils.decode_range(worksheet['!ref']);
                for(rowNum = range.s.r; rowNum <= range.e.r; rowNum++){
                   row = [];
                    for(colNum=range.s.c; colNum<=range.e.c; colNum++){
                       let nextCell = worksheet[
                          XLSX.utils.encode_cell({r: rowNum, c: colNum})
                       ];
                       if( typeof nextCell === 'undefined' ){
                          row.push(void 0);
                       } else row.push(nextCell.w);
                    }
                    result.push(row);
                }                
                instance.importRows.call(instance, result);
            })
        };

        reader.onerror = function(ex) {
            console.log(ex);
        };
      
        reader.readAsBinaryString(file);  
    }

    private importRows(rows){
        let isProductRow = false;

        for (let rowIndex = 0; rowIndex < rows.length; rowIndex++){
            let row = rows[rowIndex];                        
            this.parseRow(row);
        }

        DBHelper.saveMainContext();
    }

    private parseRow(row){
        let firstName = row[0];
        let lastName = row[1];
        let email = row[2];
        let mobile = row[3];
        let code = row[4];

        let name = firstName + " " + lastName;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
                
        let client = MIOEntityDescription.insertNewObjectForEntityForName("Client", ad.managedObjectContext) as Client;
        client.name = name.trim();
        client.email = email;        

        let card = MIOEntityDescription.insertNewObjectForEntityForName("Card", ad.managedObjectContext) as Card;
        
        card.cardType = CardType.External;
        card.barcode = code;
        card.legalEntity = client;                
    }

    generateAnonymousCard(type:number, prefix:string, startIndex:number, endIndex:number, loyaltyAmount:string){
        
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let a = loyaltyAmount.length == 0 ? null : ad.currencyFormatter.numberFromString( loyaltyAmount );        

        let moc:MIOManagedObjectContext = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        
        for(let index = startIndex; index < (endIndex + 1); index++){
            let card = MIOEntityDescription.insertNewObjectForEntityForName("Card", moc) as Card;
            card.barcode = AppHelper.sharedInstance().generateCodeType(type, prefix, index);
            card.enableCardAccounts = true;
            
            if (a != null) {
                let line = MIOEntityDescription.insertNewObjectForEntityForName("LoyaltyAccountLine", moc) as LoyaltyAccountLine;
                line.value = a;
                line.date = new Date();
                line.concept = "GENERATE ANONYMOUS CARD WITH " + String(loyaltyAmount);
                line.economicEntity = card;
            }
        }

        moc.save();
    }
}
