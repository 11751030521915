class ApplicationLicenseCell extends UITableViewCell
{

    private statusDot:MUIView = null;
    private appNameLabel:MUILabel = null;
    private deviceNameLabel:MUILabel = null;
    private startDate:MUILabel = null;
    private endDate:MUILabel = null;
    private periodLabel:MUILabel = null;
    private autoRenewButton:MUIButton = null;
    private priceLabel:MUILabel = null;
    private discountLabel:MUILabel = null;
    private totalLabel:MUILabel = null;    

    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;  
    private df:MIODateFormatter = MUIWebApplication.sharedInstance().delegate.dateFormatter;

    awakeFromHTML(){
        this.statusDot = MUIOutlet(this, "status-view", "MUIView");
        this.appNameLabel = MUIOutlet(this, "app-lbl", "MUILabel");
        this.deviceNameLabel = MUIOutlet(this, "device-name-lbl", "MUILabel");
        this.startDate = MUIOutlet(this, "start-date-lbl", "MUILabel");
        this.endDate = MUIOutlet(this, "end-date-lbl", "MUILabel");
        this.periodLabel = MUIOutlet(this, "period-lbl", "MUILabel");
        this.autoRenewButton = MUIOutlet(this, "renew-checkmark", "MUIView");
        this.priceLabel = MUIOutlet(this, "price-lbl", "MUILabel");
        this.discountLabel = MUIOutlet(this, "discount-lbl", "MUILabel");
        this.totalLabel = MUIOutlet(this, "total-lbl", "MUILabel");
    }

    set item(app:License){
        if (app.active == true){
            MUICoreLayerAddStyle(this.statusDot.layer, "active");
        } else {
            MUICoreLayerAddStyle(this.statusDot.layer, "alert");
        }
        if (app.application?.name) this.appNameLabel.text = app.application.name;
        if (app.application?.name) this.deviceNameLabel.text = app.application.name;
        this.startDate.text = this.df.stringFromDate(app.renewalStartDate); 
        this.endDate.text = this.df.stringFromDate(app.renewalEndDate); 
        this.periodLabel.text = this.nf.stringFromNumber(app.periodicity);
        // this.autoRenewButton = 
        // this.priceLabel = 
        // this.discountLabel = 
        // this.totalLabel = 
    }

}
