
declare var epson:any;

class PrinterHelper extends MIOObject 
{
    private static _sharedInstance: PrinterHelper = null;
    static sharedInstance(): PrinterHelper {

        if (this._sharedInstance == null) {
            this._sharedInstance = new PrinterHelper();
            this._sharedInstance.init();
        }

        return this._sharedInstance;
    }
    
    printArchivedDocument(document:ArchivedDocument) {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.downloadPDF(document);
    }

    printTest2(){
        //epson_test();
    }

    private printer = null;
    private ePosDev = new epson.ePOSDevice();

    connect() {
        this.ePosDev.connect('10.0.1.152', 8008, this.cbConnect);
    }

    cbConnect = (data) => {
        if(data == 'OK') {
            this.ePosDev.createDevice('local_printer', this.ePosDev.DEVICE_TYPE_PRINTER, {'crypto' : true, 'buffer' : false}, this.cbCreateDevice_printer);
        } else {
            alert(data);
        }
    }

    cbCreateDevice_printer = (devobj, retcode) => {
        if( retcode == 'OK' ) {
            this.printer = devobj;
            this.executeAddedCode();
        } else {
            alert(retcode);
        }
    }
    
    executeAddedCode() {

        this.printer.addTextAlign(this.printer.ALIGN_CENTER);
        this.printer.addText('Hello,\tWorld!\n');
        this.printer.addCut(this.printer.CUT_FEED);    
        this.printer.addFeedLine(10);

        this.printer.send();
    }
}

