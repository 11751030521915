//
// Generated class DockPrinter
//

/// <reference path="DockPrinter_ManagedObject.ts" />

class DockPrinter extends DockPrinter_ManagedObject
{

}
