

/// <reference path="Interval.ts" />

// Generated class RateInterval_ManagedObject

class RateInterval_ManagedObject extends Interval
{
    // Relationship: rate
    set rate(value:Rate) {
        this.setValueForKey(value, 'rate');
    }
    get rate():Rate {
        return this.valueForKey('rate') as Rate;
    }
}
