

/// <reference path="CoreEntity.ts" />

// Generated class AccountBalance_ManagedObject

class AccountBalance_ManagedObject extends CoreEntity
{

    // Property: amount
    set amount(value:number) {
        this.setValueForKey(value, 'amount');
    }
    get amount():number {
        return this.valueForKey('amount');
    }
    set amountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'amount');
    }
    get amountPrimitiveValue():number {
        return this.primitiveValueForKey('amount');
    }
}
