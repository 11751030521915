

/// <reference path="CoreEntity.ts" />

// Generated class CoreEntityUpdatedBy_ManagedObject

class CoreEntityUpdatedBy_ManagedObject extends CoreEntity
{

    // Property: appID
    set appID(value:string) {
        this.setValueForKey(value, 'appID');
    }
    get appID():string {
        return this.valueForKey('appID');
    }
    set appIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'appID');
    }
    get appIDPrimitiveValue():string {
        return this.primitiveValueForKey('appID');
    }

    // Property: placeID
    set placeID(value:string) {
        this.setValueForKey(value, 'placeID');
    }
    get placeID():string {
        return this.valueForKey('placeID');
    }
    set placeIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'placeID');
    }
    get placeIDPrimitiveValue():string {
        return this.primitiveValueForKey('placeID');
    }

    // Property: updatedByAppID
    set updatedByAppID(value:string) {
        this.setValueForKey(value, 'updatedByAppID');
    }
    get updatedByAppID():string {
        return this.valueForKey('updatedByAppID');
    }
    set updatedByAppIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedByAppID');
    }
    get updatedByAppIDPrimitiveValue():string {
        return this.primitiveValueForKey('updatedByAppID');
    }
}
