

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class WineCellar_ManagedObject

class WineCellar_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: appearsOnWineList
    set appearsOnWineList(value:boolean) {
        this.setValueForKey(value, 'appearsOnWineList');
    }
    get appearsOnWineList():boolean {
        return this.valueForKey('appearsOnWineList');
    }
    set appearsOnWineListPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'appearsOnWineList');
    }
    get appearsOnWineListPrimitiveValue():boolean {
        return this.primitiveValueForKey('appearsOnWineList');
    }

    // Property: generateIndex
    set generateIndex(value:boolean) {
        this.setValueForKey(value, 'generateIndex');
    }
    get generateIndex():boolean {
        return this.valueForKey('generateIndex');
    }
    set generateIndexPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'generateIndex');
    }
    get generateIndexPrimitiveValue():boolean {
        return this.primitiveValueForKey('generateIndex');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: productAttributeTypeID
    set productAttributeTypeID(value:string) {
        this.setValueForKey(value, 'productAttributeTypeID');
    }
    get productAttributeTypeID():string {
        return this.valueForKey('productAttributeTypeID');
    }
    set productAttributeTypeIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productAttributeTypeID');
    }
    get productAttributeTypeIDPrimitiveValue():string {
        return this.primitiveValueForKey('productAttributeTypeID');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }
}
