//
// Generated class Language
//

/// <reference path="Language_ManagedObject.ts" />

class Language extends Language_ManagedObject
{

    get localizeName():string{
        return MIOLocalizeString(this.name, this.name);
    }

}
