

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class BookingModifier_ManagedObject

class BookingModifier_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: isDefault
    set isDefault(value:boolean) {
        this.setValueForKey(value, 'isDefault');
    }
    get isDefault():boolean {
        return this.valueForKey('isDefault');
    }
    set isDefaultPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isDefault');
    }
    get isDefaultPrimitiveValue():boolean {
        return this.primitiveValueForKey('isDefault');
    }

    // Property: minimumSpend
    set minimumSpend(value:string) {
        this.setValueForKey(value, 'minimumSpend');
    }
    get minimumSpend():string {
        return this.valueForKey('minimumSpend');
    }
    set minimumSpendPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'minimumSpend');
    }
    get minimumSpendPrimitiveValue():string {
        return this.primitiveValueForKey('minimumSpend');
    }

    // Property: minimumSpendByPax
    set minimumSpendByPax(value:boolean) {
        this.setValueForKey(value, 'minimumSpendByPax');
    }
    get minimumSpendByPax():boolean {
        return this.valueForKey('minimumSpendByPax');
    }
    set minimumSpendByPaxPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'minimumSpendByPax');
    }
    get minimumSpendByPaxPrimitiveValue():boolean {
        return this.primitiveValueForKey('minimumSpendByPax');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: price
    set price(value:string) {
        this.setValueForKey(value, 'price');
    }
    get price():string {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():string {
        return this.primitiveValueForKey('price');
    }

    // Property: priceByPax
    set priceByPax(value:number) {
        this.setValueForKey(value, 'priceByPax');
    }
    get priceByPax():number {
        return this.valueForKey('priceByPax');
    }
    set priceByPaxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'priceByPax');
    }
    get priceByPaxPrimitiveValue():number {
        return this.primitiveValueForKey('priceByPax');
    }
    // Relationship: bookingModifierCategory
    set bookingModifierCategory(value:BookingModifierCategory) {
        this.setValueForKey(value, 'bookingModifierCategory');
    }
    get bookingModifierCategory():BookingModifierCategory {
        return this.valueForKey('bookingModifierCategory') as BookingModifierCategory;
    }
}
