
class ProductRatesListCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    private imageView:MUIImageView = null; 

    awakeFromHTML()
    {
        // this.imageView = MUIOutlet(this, "image-view", "MUIImageView");
        this.nameLabel = MUIOutlet(this, "rate-name-lbl", "MUILabel");
        //this.separatorStyle = MUITableViewCellSeparatorStyle.None;
    }

    private _item:any = null;
    set item(i:any)
    {
        this._item = i;
        // if (i.image) {
        //     this.imageView.setImage(i.image);
        // }
        this.nameLabel.text = i.name;
    }

}