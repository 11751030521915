
enum BusinessViewControllerActionType
{
    Create,
    Edit
}

class BusinessViewController extends MUIViewController
{
    private backButton:MUIButton = null;
    private titleLabel:MUILabel = null;
    private createButton:MUIButton = null;
    private saveButton:MUIButton = null;
    
    private nameTextField:MUITextField = null;
    private emailTextField:MUITextField = null;

    private phoneCountryCodeTextField:MUITextField = null;
    private phoneNumberTextField:MUITextField = null;    
    
    private addressTextField:MUITextField = null;
    private cityTextField:MUITextField = null;
    private stateTextField:MUITextField = null;
    private postalCodeTextField:MUITextField = null;
    private countryDropDown:MUIButton = null;                    

    get preferredContentSize(){
        return new MIOSize(400, 600);
    }
    
    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, 'back-btn', 'MUIButton');
        this.backButton.setAction(this, function(){
            this.navigationController.popViewController(true);
        });

        this.titleLabel = MUIOutlet(this, 'title-lbl', 'MUILabel');

        this.saveButton = MUIOutlet(this, 'save-btn', 'MUIButton');        
        this.saveButton.setAction(this, function(){
            this.saveAction();
            this.navigationController.popViewController(true);
        });

        this.createButton = MUIOutlet(this, 'create-btn', 'MUIButton');
        this.createButton.setAction(this, function(){
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        //     if(this.delegate && this._mode == 'place') {
        //         console.log('Save place');//save place & pop
        //         ad.webService.insertPlace(this._currentModel, this, function (code, json) {

        //             if (code < 200 || code >= 300) {
        //                 //TODO: mostrar errores
        //                 AppHelper.showErrorMessage(this,'Error', 'An error occurred while creating place');
                        
        //                 return;
        //             }
        //             else {
        //                 //Refrescamos con la nueva estructura
        //                 ad.webService.userData(this, function () {
        //                     this.navigationController.popViewController(true);
        //                 });
        //             }
        //         });
        //     }
        //     if(this.delegate && this._mode == 'company'){
        //         console.log('Save Company');
        //         ad.webService.insertCompany(this._currentModel, this, function (code, json) {

        //             if (code < 200 || code >= 300) {
        //                 //TODO: mostrar errores
        //                 AppHelper.showErrorMessage(this,'Error', 'An error occurred while creating a company');
        //                 return;
        //             }
        //             else {
        //                 //Refrescamos con la nueva estructura
        //                 ad.webService.userData(this, function () {
        //                     this.navigationController.popViewController(true);
        //                 });
        //             }
        //         });
        //     }
        });


        // this._mapButton = MUIOutlet(this, 'apcv_c1_map_cell', 'MUIButton');
        // this._mapButton.setAction(this, function()
        // {
        //     if(!this._mapvc) {
        //         this._mapvc = new MapsViewController('maps_view');
        //         this._mapvc.initWithResource('layout/modals/workspace/MapsView.html');
        //     }
        //     this._mapvc.delegate = this;
        //     this._mapvc.initWithCoordenates(this._currentModel.lat, this._currentModel.lng);

        //     this.navigationController.pushViewController(this._mapvc);
        // });

        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.nameTextField.setOnChangeText(this, function(textField:MUITextField, value:string){
            this.values["Name"] = value;
        });

        this.emailTextField = MUIOutlet(this, "email-tf", "MUITextField");
        this.emailTextField.setOnChangeText(this, function(textField:MUITextField, value:string){
            this.values["Email"] = value;
        });

        // this.phoneCountryCodeTextField = MUIOutlet(this, 'phone-country-code-tf', 'MUITextField');
        // this.phoneCountryCodeTextField.setOnChangeText(this, function(textfield, value){
        //     this._currentModel.setValueForKey(value, 'phonecountrycode');            
        // });

        this.phoneNumberTextField = MUIOutlet(this, 'phone-tf', 'MUITextField');
        this.phoneNumberTextField.setOnChangeText(this, function(textfield, value){
            this.values["Phone"] = value;
        });

        this.addressTextField = MUIOutlet(this, 'address-tf', 'MUITextField');
        this.addressTextField.setOnChangeText(this, function(textfield, value){
            this.values["Address"] = value;
        });

        this.cityTextField = MUIOutlet(this, 'city-tf', 'MUITextField');
        this.cityTextField.setOnChangeText(this, function(textfield, value){
            this.values["City"] = value;
        });

        this.stateTextField = MUIOutlet(this, 'state-tf', 'MUITextField');
        this.stateTextField.setOnChangeText(this, function(textfield, value){
            this.values["State"] = value;
        });

        this.countryDropDown = MUIOutlet(this, "country-dd", "MUIButton");
        this.countryDropDown.setAction(this, function(comboBox, value){            
            AppHelper.sharedInstance().showSelectCountryViewControllerFromView(this.countryDropDown, null, this, function(controller:SelectEntityViewController, country:Country){                
                this.values["CountryISO2"] = country.iso2;
                this.values["CountryName"] = country.name;
                this.countryDropDown.title = country.name; 
            });
        });

    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private action = BusinessViewControllerActionType.Create;    
    private type = null;
    set mode(value:BusinessType){   
        this.action = BusinessViewControllerActionType.Create;             
        this.type = value;
        this.updateUI();
    }

    private values = {};
    private business:Business = null;
    set item(value:Business){
        this.action = BusinessViewControllerActionType.Edit;
        this.type = value.type;
        this.business = value;

        this.values["Name"] = value.name;
        this.values["Email"] = value.email;
        this.values["Phone"] = value.phone;
        this.values["Address"] = value.address;
        this.values["State"] = value.state;
        this.values["City"] = value.city;
        this.values["PostalCode"] = value.postalCode;
        this.values["CountryName"] = getCountryName( value.countryISO2 ) // value.countryName;
        this.values["CountryISO2"] = value.countryISO2;

        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;
        if (this.action == BusinessViewControllerActionType.Create && this.type == null) return;
        if (this.action == BusinessViewControllerActionType.Edit && this.business == null) return;

        this.createButton.hidden = this.action == BusinessViewControllerActionType.Create ? false : true;
        this.saveButton.hidden = this.action == BusinessViewControllerActionType.Edit ? false : true;

        this.titleLabel.text = this.action == BusinessViewControllerActionType.Create ? MIOLocalizeString("NEW", "NEW") : MIOLocalizeString("EDIT", "EDIT");
        
        this.nameTextField.text = this.values["Name"];
        this.emailTextField.text = this.values["Email"];        

        // this._phoneCountryCodeTextField.text = this._currentModel.valueForKey('phonecountrycode');
        this.phoneNumberTextField.text = this.values["Phone"];
        this.addressTextField.text = this.values["Address"];
        this.stateTextField.text = this.values["State"];
        this.cityTextField.text = this.values["City"];
        
        this.countryDropDown.title = this.values["CountryName"];
    }

    private saveAction(){        
        this.business.name = this.values["Name"];
        this.business.email = this.values["Email"];
        this.business.phone = this.values["Phone"];
        this.business.address = this.values["Address"];
        this.business.state = this.values["State"];
        this.business.city = this.values["City"];
        this.business.countryISO2 = this.values["CountryISO2"];
        this.business.countryName = this.values["CountryName"];

        DBHelper.saveMainContext();
    }
}