/// <reference path="Booking_ManagedObject.ts" />



enum BookingStatus {
    BookingRequest,
    BookingAssigned,
    BookingReserved,
    BookingConfirmed,
    BookingFinished,
    BookingWaiting,
    BookingCancelled,
    BookingDenied,
    BookingNoShow,
    BookingNoCC,
    BookingDeleted,
    BookingCCCanceled,
    BookingShow,
    BookingSitting
};


class Booking extends Booking_ManagedObject
{
    
    get totalPax(){
        return(this.pax || 0) - (this.paxInvited || 0);
    }
    
    get statusString(){
        switch(this.status){
            case BookingStatus.BookingRequest: return 'Request';
            case BookingStatus.BookingAssigned: return 'Assigned';
            case BookingStatus.BookingReserved: return 'Reserved';
            case BookingStatus.BookingConfirmed: return 'Confirmed';
            case BookingStatus.BookingFinished: return 'Finished';
            case BookingStatus.BookingWaiting: return 'Waiting';
            case BookingStatus.BookingCancelled: return 'Cancelled';
            case BookingStatus.BookingDenied: return 'Denied';
            case BookingStatus.BookingNoShow: return 'No Show';
            case BookingStatus.BookingNoCC: return 'No CC';
            case BookingStatus.BookingDeleted: return 'Deleted';
            case BookingStatus.BookingCCCanceled: return 'CC Cancelled';
            case BookingStatus.BookingShow: return 'Show';
            case BookingStatus.BookingSitting: return 'Sitting';
        }
    }

    static colorForStatus(status:BookingStatus) : [number, number, number] {
        
        switch (status) {
            case BookingStatus.BookingRequest: return [255, 142, 9];
            case BookingStatus.BookingAssigned: return [65, 93, 188];
            case BookingStatus.BookingConfirmed: return [0, 128, 64];
            // case BookingStatus.BookingFinished: 
            case BookingStatus.BookingShow: return [128, 237, 11];
            case BookingStatus.BookingWaiting: return [211, 190, 15];
            // case BookingStatus.BookingCancelled:
            // case BookingStatus.BookingDenied:
            case BookingStatus.BookingDeleted: return [200, 120, 120];
            case BookingStatus.BookingNoShow: return [167, 1, 203];
            case BookingStatus.BookingNoCC: return [237, 88, 141];
            case BookingStatus.BookingCCCanceled: return [204, 102, 0];
        }        

        return [0, 0, 0];
    }

}
