//
// Generated class Menu
//

/// <reference path="Menu_ManagedObject.ts" />

class Menu extends Menu_ManagedObject
{

}
