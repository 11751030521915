//
// Generated class SalesInvoiceLine
//

/// <reference path="SalesInvoiceLine_ManagedObject.ts" />

class SalesInvoiceLine extends SalesInvoiceLine_ManagedObject
{

}
