

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class WorkerRole_ManagedObject

class WorkerRole_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: bookingPermissions
    set bookingPermissions(value:number) {
        this.setValueForKey(value, 'bookingPermissions');
    }
    get bookingPermissions():number {
        return this.valueForKey('bookingPermissions');
    }
    set bookingPermissionsPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'bookingPermissions');
    }
    get bookingPermissionsPrimitiveValue():number {
        return this.primitiveValueForKey('bookingPermissions');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: permissions
    set permissions(value:number) {
        this.setValueForKey(value, 'permissions');
    }
    get permissions():number {
        return this.valueForKey('permissions');
    }
    set permissionsPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'permissions');
    }
    get permissionsPrimitiveValue():number {
        return this.primitiveValueForKey('permissions');
    }

    // Relationship: workers
    protected _workers:MIOManagedObjectSet = null;
    get workers():MIOManagedObjectSet {
        return this.valueForKey('workers');
    }
    addWorkersObject(value:Employee) {
        this._addObjectForKey(value, 'workers');
    }
    removeWorkersObject(value:Employee) {
        this._removeObjectForKey(value, 'workers');
    }
}
