

/// <reference path="IntegratorSyncEntityQueue.ts" />

// Generated class IntegratorMailchimpQueue_ManagedObject

class IntegratorMailchimpQueue_ManagedObject extends IntegratorSyncEntityQueue
{

    // Property: email
    set email(value:string) {
        this.setValueForKey(value, 'email');
    }
    get email():string {
        return this.valueForKey('email');
    }
    set emailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'email');
    }
    get emailPrimitiveValue():string {
        return this.primitiveValueForKey('email');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }
}
