//
// Generated class SalaryReconcileTransaction
//

/// <reference path="SalaryReconcileTransaction_ManagedObject.ts" />

class SalaryReconcileTransaction extends SalaryReconcileTransaction_ManagedObject
{

}
