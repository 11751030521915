class OnlineSettingLogoCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    private descLabel:MUILabel = null;
    private logoImageView:MUIImageView = null;
    private uploadImgButton:MUIButton = null;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.descLabel = MUIOutlet(this, "desc-lbl", "MUILabel");

        this.uploadImgButton = MUIOutlet(this, "upload-img-btn", "MUIButton");
        this.logoImageView = this.setupImageView("company-img");
       
        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private _name:string = null;
    set setName (text:string) {
        this._name = text;
        if (text == null) return;
        this.nameLabel.text = MIOLocalizeString(text, text);
    }

    private _desc:string = null;
    set setDesc (text:string) {
        this._desc = text;
        if (text == null) return;
        this.descLabel.text = MIOLocalizeString(text, text);
    }

    private _itemURL:Configuration = null;
    set setItemURL (item:Configuration){
        this._itemURL = item;
        if (item == null) return;

        if(this._itemURL.stringValue != null && this._itemURL.stringValue.length > 0) {
            this.logoImageView.setImage(this._itemURL.stringValue)
        } else {
            this.logoImageView.setImage("images/manager-3_0/manager-blank-logo.png");
        }
    }

    private _itemID:Configuration = null;
    set setItemID (item:Configuration){
        this._itemID = item;
    }

    private setupImageView(outlet:string): MUIImageView {
        let imageView = MUIOutlet(this, outlet,'MUIImageView');
        if (imageView == null) return null;

        let tapGesture = new MUIGestureRecognizer();
        tapGesture.initWithTarget(this, this.imageTapGesture);
        imageView.addGestureRecognizer(tapGesture);
        this.uploadImgButton.addGestureRecognizer(tapGesture);
        return imageView;
    }

    private addImage(imageID:string, imageURL:string){
        this._itemURL.stringValue = imageURL;
        this._itemID.stringValue = imageID;
        this.logoImageView.setImage(imageURL);
    }

    private imageTapGesture(gesture:MUIGestureRecognizer){
        if (gesture.state != MUIGestureRecognizerState.Began) return;
        
        
        // let panel = MUIOpenPanel.openPanel();
        // let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        // panel.beginSheetModalForWindow(ad.window, this, function(result:number){
        //     if (result == MIOFileHandlingPanel.OKButton) {
        //         let file = panel.files[0];               
        //         let ws = ad.webService;
       
        //         ws.uploadImage(file, this, function(code, data){
        //             if (code == 200){
        //                 let imageID = data["id"];
        //                 let url = data["url"];
        //                 this.addImage(imageID, url);
        //             }
        //             else {
        //                 AppHelper.showErrorMessage(this, "Error", "Couldn't upload image. Please try again later.");
        //             }
        //         });       
        //     }       
        // });      
    }

}