
interface SetZoneDaysSetupViewControllerDelegate 
{
    setZonesBetweenDates(controller:SetZoneDaysSetupViewController, beginDate:Date, endDate:Date, weekdays);
}

class SetZoneDaysSetupViewController extends MUIViewController
{
    delegate = null;

    private closeButton:MUIButton = null;
    private setButton:MUIButton = null;

    private beginDateTextField:MUITextField = null;
    private beginDateButton:MUIButton = null;
    private endDateTextField:MUITextField = null;
    private endDateButton:MUIButton = null;

    private monButton:MUIButton = null;
    private tueButton:MUIButton = null;
    private wedButton:MUIButton = null;
    private thuButton:MUIButton = null;
    private friButton:MUIButton = null;
    private satButton:MUIButton = null;
    private sunButton:MUIButton = null;

    private df:MIODateFormatter = MUIWebApplication.sharedInstance().delegate.dateFormatter;

    viewDidLoad(){
        super.viewDidLoad();

        this.closeButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.closeButton.setAction(this, function(){
            this.dismissViewController(true);
        }); 

        this.setButton = MUIOutlet(this, "set-btn", "MUIButton");
        this.setButton.setAction(this, function(){
            this.callDelegate();
            this.dismissViewController(true);            
        });
        
        this.beginDateTextField = MUIOutlet(this, "begin-date-tf", "MUITextField");
        this.beginDateButton = MUIOutlet(this, "begin-date-btn", "MUIButton");
        this.beginDateButton.setAction(this, function(){
            let dp = new MUIDatePickerController();
            dp.init();        
            dp.delegate = this;
            dp.tag = 1;
            dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

            let pc = dp.popoverPresentationController;
            pc.sourceRect = this.beginDateButton.frame;
            pc.sourceView = this.beginDateButton;

            this.presentViewController(dp, true);
        });

        this.endDateTextField = MUIOutlet(this, "end-date-tf", "MUITextField");
        this.endDateButton = MUIOutlet(this, "end-date-btn", "MUIButton");
        this.endDateButton.setAction(this, function(){
            let dp = new MUIDatePickerController();
            dp.init();        
            dp.delegate = this;
            dp.tag = 2;
            dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

            let pc = dp.popoverPresentationController;
            pc.sourceRect = this.endDateButton.frame;
            pc.sourceView = this.endDateButton;

            this.presentViewController(dp, true);
        });

        this.monButton = MUIOutlet(this, "mo-btn", "MUIButton", "PushOnPushOff");
        this.monButton.setAction(this, this.changeButtonStatus);
        this.tueButton = MUIOutlet(this, "tu-btn", "MUIButton");
        this.tueButton.setAction(this, this.changeButtonStatus);        
        this.wedButton = MUIOutlet(this, "we-btn", "MUIButton");
        this.wedButton.setAction(this, this.changeButtonStatus);
        this.thuButton = MUIOutlet(this, "th-btn", "MUIButton");
        this.thuButton.setAction(this, this.changeButtonStatus);
        this.friButton = MUIOutlet(this, "fr-btn", "MUIButton");
        this.friButton.setAction(this, this.changeButtonStatus);
        this.satButton = MUIOutlet(this, "sa-btn", "MUIButton");
        this.satButton.setAction(this, this.changeButtonStatus);
        this.sunButton = MUIOutlet(this, "su-btn", "MUIButton");
        this.sunButton.setAction(this, this.changeButtonStatus);        
    }

    changeButtonStatus(button:MUIButton){
        //let value = button.selected;
        //button.selected = !value;        
    }

    didSelectDate(datePickerController:MUIDatePickerController, date:Date){

        if (datePickerController.tag == 1){
            this.beginDateTextField.text = this.df.stringFromDate(date);
        }
        else if (datePickerController.tag == 2){
            this.endDateTextField.text = this.df.stringFromDate(date);
        }
    }

    callDelegate(){

        let sdf:MIODateFormatter = MUIWebApplication.sharedInstance().delegate.serverDateFormatter;

        let beginDate = this.df.dateFromString(this.beginDateTextField.text);
        let endDate = this.df.dateFromString(this.endDateTextField.text);

        let weekdays = 0;
        if (this.monButton.selected) weekdays |= WeekDays.Monday;
        if (this.tueButton.selected) weekdays |= WeekDays.Tuesdays;
        if (this.wedButton.selected) weekdays |= WeekDays.Wednesday;
        if (this.thuButton.selected) weekdays |= WeekDays.Thursday;
        if (this.friButton.selected) weekdays |= WeekDays.Friday;
        if (this.satButton.selected) weekdays |= WeekDays.Saturday;
        if (this.sunButton.selected) weekdays |= WeekDays.Sunday;

        if (this.delegate != null) 
            this.delegate.setZonesBetweenDates(this, beginDate, endDate, weekdays);                
    }
}