class GlobalActionsSwitchCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    
    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    set name (text:string) {
        this.nameLabel.text = MIOLocalizeString(text, text);
    }

    set item (item:any) {
        this.nameLabel.text = MIOLocalizeString(item.name, item.name);
    }

}