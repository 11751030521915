

/// <reference path="RuleResult.ts" />

// Generated class RuleResultOnDate_ManagedObject

class RuleResultOnDate_ManagedObject extends RuleResult
{

    // Property: date
    set date(value:string) {
        this.setValueForKey(value, 'date');
    }
    get date():string {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():string {
        return this.primitiveValueForKey('date');
    }
}
