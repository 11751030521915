

class ProductAttributeViewController extends MUIViewController
{

    // static newInstance() : ProductAttributeViewController {
    //     let vc = new ProductAttributeViewController('product-attribute-view');
    //     vc.initWithResource('layout/productattribute/ProductAttributeView.html');
    //     return vc;
    // }

    private addButton:MUIButton = null;
    private tableView:UITableView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function(){
            this.addProductAttributeType();
        });

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(animate?) {
        super.viewWillAppear(animate);        
        this.updateUI();
    }

    private _productAttributeType;
    set item (item:ProductAttributeType) {
        this._productAttributeType = item;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;
      
        //filter buy this._productAttributeType in fetchedController
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }
    
    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('ProductAttributeCell') as ProductAttributeCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ProductAttribute;
        cell.item = item;
        cell.selectionStyle = UITableViewCellSelectionStyle.None; 
        return cell;
    }
        
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){}

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
        return UITableViewCellEditingStyle.Delete;
    }
    
    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Tax;    

        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            DBHelper.deleteObjectFromMainContext(item, true);
        }
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('name', true) ];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("ProductAttribute", sortDescriptors, null);
        fetchRequest.relationshipKeyPathsForPrefetching = ['ProductAttributeType'];      

        if(this._productAttributeType) {
            fetchRequest.predicate = MIOPredicate.predicateWithFormat("productAttributeType.identifier == " + this._productAttributeType.identifier);
        }
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    
    private addProductAttributeType(){

        alert("add project attribute");

        // var avc = new MUIAlertViewController();
        // avc.initWithTitle(MIOLocalizeString('NEW PRODUCT ATTRIBUTE TYPE','NEW PRODUCT ATTRIBUTE TYPE'), MIOLocalizeString('CHOOSE A NAME','CHOOSE A NAME '), MUIAlertViewStyle.Default);
        // avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
        //     textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        // });

        // avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD','ADD'), MUIAlertActionStyle.Default, this, function(){
        //     let nameTF =  avc.textFields[0];
        //     let name = nameTF.text.trim();

        //     if(name.length > 0){
        //         let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        //         let ws = MIOEntityDescription.insertNewObjectForEntityForName("ProductAttributeType", moc) as ProductAttributeType;
        //         ws.identifier = MIOUUID.UUID().UUIDString;
        //         ws.name = name;
        //         moc.save();
        //     }
        //     else {
        //         let error = new MUIAlertViewController();
        //         error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('CHECK NAME','CHECK NAME'), MUIAlertViewStyle.Default);
        //         error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CLOSE','CLOSE'), MUIAlertActionStyle.Cancel, this, function(){}));
                
        //         this.presentViewController(error, true);
        //     }
        // }));
        // avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, this, function(){}));
        
        // this.presentViewController(avc, true);
    }
}
