
class DBPayMethodCoder extends DBXLSXCoder
{
    protected mainEntity() { 
        return { entity: "PayMethod", predicateFormat: "deletedAt == null", relations:["category"] };
    }

    columns() : any[] {
        let cols = [
            { title: "PAYMETHOD ID", width: "30%", align: "center" },
            { title: "PAYMETHOD NAME", width: "30%", align: "center" },
            { title: "BLOCK CURRENCY CHANGE", width: "30%", align: "center" },
            { title: "ELIMINABLE", width: "30%", align: "center" },
            { title: "IMAGE ID", width: "30%", align: "center"  },
            { title: "INCLUDE IN CASHDESK TOTALS", width: "30%", align: "center" },
            { title: "INCLUDE INFO IN CASHDESK REPORT", width: "30%", align: "center" },
            { title: "INVOICE SERIE ID", width: "30%", align: "center" },
            { title: "IS DISABLED", width: "30%", align: "center" },
            { title: "LIMIT DAY MONEY PER FOREIGNER CLIENT", width: "30%", align: "center", formatter: this.ad.currencyFormatter },
            { title: "LIMIT DAY MONEY PER NATIONAL CLIENT", width: "30%", align: "center", formatter: this.ad.currencyFormatter },
            { title: "NEEDS CHANGE", width: "30%", align: "center" },
            { title: "VOID INVOICE SERIE ID", width: "30%", align: "center" },            
            { title: "OPEN CASH DRAWER", width: "30%", align: "center" },
            { title: "ORDER INDEX", width: "30%", align: "center", formatter: this.ad.integerFormatter },
            { title: "PRINT COPIES", width: "30%", align: "center", formatter: this.ad.integerFormatter },
            { title: "PRINT TICKET", width: "30%", align: "center" },
            { title: "TAGS", width: "30%", align: "center" },
            { title: "RECEIPT SERIE ID", width: "30%", align: "center" },
            { title: "TRANSACTION CONCEPT", width: "30%", align: "center" },
            { title: "TYPE", width: "30%", align: "center", formatter: this.ad.integerFormatter },
            { title: "USE DEFAULT CURRENCY", width: "30%", align: "center" },
            { title: "USE DIFFERENT SERIES", width: "30%", align: "center" },

            { title: "DEFAULT CURRENCY ID", width: "30%", align: "center" },
            { title: "DEFAULT CURRENCY NAME", width: "30%", align: "center" },

        ]
        return cols;
    }

    protected aditionalImportEntities(){
        return [
            { entity: "Currency", predicateFormat: "deletedAt == null" }
        ]
    }


    protected importRow(row:any){
                
        let pm_id = row[ "PAYMETHOD ID" ];
        let pm_name = row[ "PAYMETHOD NAME" ];
        let block_currency_change = row[ "BLOCK CURRENCY CHANGE" ];
        let eliminable = row[ "ELIMINABLE" ];
        let image_id = row[ "IMAGE ID" ];
        let include_in_cashdesk_totals = this.parseBoolValue( row[ "INCLUDE IN CASHDESK TOTALS" ] );
        let include_info_in_cashdesk_report = row[ "INCLUDE INFO IN CASHDESK REPORT" ];
        let invoice_serie_id = row[ "INVOICE SERIE ID" ];
        let is_disable = this.parseBoolValue( row[ "IS DISABLED" ] );
        let limit_amount_foreigner = row[ "LIMIT DAY MONEY PER FOREIGNER CLIENT" ];
        let limit_amount_national = row[ "LIMIT DAY MONEY PER NATIONAL CLIENT" ];
        let needs_change = row[ "NEEDS CHANGE" ];
        let void_invoice_series_id = row[ "VOID INVOICE SERIE ID" ];
        let open_drawer = row[ "OPEN CASH DRAWER" ];
        let order_index = row[ "ORDER INDEX" ];
        let print_copies = row[ "PRINT COPIES" ];
        let print_tickets = row[ "PRINT TICKET" ];
        let tags = row[ "TAGS" ];
        let receipt_serie_id = row[ "RECEIPT SERIE ID" ];
        let tx_concept = row[ "TRANSACTION CONCEPT" ];
        let type = row[ "TYPE" ];
        let use_default_currency = this.parseBoolValue( row[ "USE DEFAULT CURRENCY" ] );
        let user_different_series = this.parseBoolValue( row[ "USE DIFFERENT SERIES" ] );

        let default_currency_id = row[ "DEFAULT CURRENCY ID" ];
        let default_currency_name = row[ "DEFAULT CURRENCY NAME" ];
        
        if (pm_name == null) { return }

        let df_currency = this.queryEntityByIDOrName("Currency", default_currency_id, default_currency_name) as Currency;

        let pm = this.queryEntityByIDOrName("PayMethod", pm_id, pm_name) as PayMethod;
        if (pm == null) {
            pm = MIOEntityDescription.insertNewObjectForEntityForName("PayMethod", DBHelper.mainManagedObjectContext) as PayMethod;
            pm.identifier = this.parseOrCreateIdentifier(pm_id);
            this.appendObject(pm);
        }
        
        pm.name = name;
        pm.defaultCurrency = df_currency;




        MIOLog("ADDING PAY METHOD: " + this.rowIndex + "/" + this.rows.length + ": " + pm_name);
    }

    protected exportTitle() : string { return "Locations"; }
                
    protected exportSortDescriptors() { 
        return [MIOSortDescriptor.sortDescriptorWithKey("name", false)]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let loc = object as Location;
        let item = {
            "LOCATION CATEGORY ID": loc.category.identifier,
            "LOCATION CATEGORY NAME": loc.category.name,
            "LOCATION ID": loc.identifier,
            "LOCATION NAME": loc.name,
            "TYPE": loc.type,
            "IS TAKEN": loc.isOccupied,
            "ANGLE": loc.angle,
            "X": loc.x,
            "Y": loc.y,
            "TAGS": loc.tags
            }

        return item;
    }
}