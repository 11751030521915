
/// <reference path="ProductDetailGeneralViewController.ts" />

class ProductDetailViewController extends MUIViewController
{            
    static newInstance () : ProductDetailViewController {
        let vc = new ProductDetailViewController("products-detail-view");
        vc.initWithResource("layout/products/ProductDetailView.html");
        return vc;
    }


    private saveButton:MUIButton = null;
    private actionButton:MUIButton = null;

    private pageController:MUIPageController = null;
    private segmentedControl:MUISegmentedControl = null;

    private generalViewController:ProductDetailGeneralViewController = null;    
    private informationViewController:ProductDetailInformationViewController = null;    
    private componentsViewController:ProductDetailIngredientsViewController = null;    

    viewDidLoad(){
        super.viewDidLoad();

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this,function() {
            DBHelper.saveMainContextWithCompletion(this, function(){
                MIONotificationCenter.defaultCenter().postNotification("ItemDidChange", this._item);
            });
        });

        this.actionButton = MUIOutlet(this, "action-btn", "MUIButton");
        this.actionButton.setAction(this, function(){
           this.showActionAlert();
        });        

        this.segmentedControl = MUIOutlet(this, "segmented-control", "MUISegmentedControl");
        this.segmentedControl.setAction(this, function (control, index){            
            this.setPageIndex(index);
        });
        
        this.pageController = MUIOutlet(this, "page-controller", "MUIPageController");

        this.generalViewController = ProductDetailGeneralViewController.newInstance();
        this.pageController.addPageViewController(this.generalViewController);

        this.componentsViewController = new ProductDetailIngredientsViewController("product-ingredients-info-view");
        this.componentsViewController.initWithResource("layout/products/ProductIngredientsInfoView.html");
        this.pageController.addPageViewController(this.componentsViewController);

        this.informationViewController = new ProductDetailInformationViewController("product-information-view");
        this.informationViewController.initWithResource("layout/products/ProductInformationView.html");
        this.pageController.addPageViewController(this.informationViewController);

        MIONotificationCenter.defaultCenter().addObserver(this, "ProductReloadNotification", function(notification:MIONotification){
            if (this._item != null) this.item = this._item;
        });
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        // this.updateUI();

        let lastPageIndex = MIOUserDefaults.standardUserDefaults().valueForKey("LastProductDetailPageIndex");
        if (lastPageIndex != null) {
            let index = parseInt(lastPageIndex);
            this.segmentedControl.selectSegmentedAtIndex(index);
            this.setPageIndex(index);
        }
    }

    viewDidAppear(animate?){
        super.viewDidAppear(animate);
        this.updateUI();
    }
    
    private product:Product = null;    
    set item(product:Product){                                   
        this.product = product;

        let predicate = MIOPredicate.predicateWithFormat("identifier = " + product.identifier + " and deletedAt = null");
        let rels = [
            'category',
            "stockCategory",
            'source',
            'productPlace',                                                              
            'defaultProductFormat.format',
            // 'productFormats.format',
            "image",
            "costBalance",
        ];
        
        DBHelper.queryObjectWithCompletion( "Product", null, predicate, rels, this, function(p:Product){
            this.updateUI();
        } );

        this.updateUI();
    }   
    
    private _productType = ProductType.None;
    set productType(value:ProductType){
        this._productType = value;

        if (this.generalViewController != null) this.generalViewController.productType = value;
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this.product == null) return;
                
        this.generalViewController.productType = this._productType;
        
        this.generalViewController.product = this.product;        
        this.componentsViewController.product = this.product;
        this.informationViewController.product = this.product;
    }

    private setPageIndex(index){  
        MIOUserDefaults.standardUserDefaults().setValueForKey("LastProductDetailPageIndex", index);
        this.pageController.showPageAtIndex(index);
    }
    
    private showActionAlert(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ACTIONS","ACTIONS"), MIOLocalizeString("CHOOSE AN ACTION","CHOOSE AN ACTION"), MUIAlertViewStyle.Default);
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("DELETE","DELETE"), MUIAlertActionStyle.Destructive, this, function(this:ProductDetailViewController){
            this.deleteProduct();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL","CANCEL"), MUIAlertActionStyle.Default, null, null));

        this.presentViewController(avc, true);
    }

    private deleteProduct(){                
        DBHelper.deleteObjectFromMainContext(this.product, true);
        MIONotificationCenter.defaultCenter().postNotification('ProductDidDeleted', this.product);      
        this.product = null;
    }
}


