/**
 * Created by miguel on 23/2/17.
 */

class OfferProductCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    private formatLabel:MUILabel = null;
    
    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, 'product-lbl', 'MUILabel');
        this.formatLabel = MUIOutlet(this, "format-lbl", "MUILabel");

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    set item(offerProduct:OfferProduct){
        if (offerProduct.product != null) {
            this.nameLabel.text = offerProduct.product.name;
        }
        else if (offerProduct.category != null) {
            this.nameLabel.text = offerProduct.category.name;
        }
        else if (offerProduct.menu != null){
            this.nameLabel.text = offerProduct.menu.name;
        }
        else {
            this.nameLabel.text = MIOLocalizeString("UNKOWN", "UNKOWN");
        }
        
        this.formatLabel.text = offerProduct.format != null ? offerProduct.format.name : null;
    }
}
