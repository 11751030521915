

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class PaymentInstallment_ManagedObject

class PaymentInstallment_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: amount
    set amount(value:number) {
        this.setValueForKey(value, 'amount');
    }
    get amount():number {
        return this.valueForKey('amount');
    }
    set amountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'amount');
    }
    get amountPrimitiveValue():number {
        return this.primitiveValueForKey('amount');
    }

    // Property: cancellationDate
    set cancellationDate(value:Date) {
        this.setValueForKey(value, 'cancellationDate');
    }
    get cancellationDate():Date {
        return this.valueForKey('cancellationDate');
    }
    set cancellationDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'cancellationDate');
    }
    get cancellationDatePrimitiveValue():Date {
        return this.primitiveValueForKey('cancellationDate');
    }

    // Property: comments
    set comments(value:string) {
        this.setValueForKey(value, 'comments');
    }
    get comments():string {
        return this.valueForKey('comments');
    }
    set commentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'comments');
    }
    get commentsPrimitiveValue():string {
        return this.primitiveValueForKey('comments');
    }

    // Property: concept
    set concept(value:string) {
        this.setValueForKey(value, 'concept');
    }
    get concept():string {
        return this.valueForKey('concept');
    }
    set conceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'concept');
    }
    get conceptPrimitiveValue():string {
        return this.primitiveValueForKey('concept');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: dueDate
    set dueDate(value:Date) {
        this.setValueForKey(value, 'dueDate');
    }
    get dueDate():Date {
        return this.valueForKey('dueDate');
    }
    set dueDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'dueDate');
    }
    get dueDatePrimitiveValue():Date {
        return this.primitiveValueForKey('dueDate');
    }

    // Property: emailCount
    set emailCount(value:number) {
        this.setValueForKey(value, 'emailCount');
    }
    get emailCount():number {
        return this.valueForKey('emailCount');
    }
    set emailCountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'emailCount');
    }
    get emailCountPrimitiveValue():number {
        return this.primitiveValueForKey('emailCount');
    }

    // Property: operationReference
    set operationReference(value:string) {
        this.setValueForKey(value, 'operationReference');
    }
    get operationReference():string {
        return this.valueForKey('operationReference');
    }
    set operationReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'operationReference');
    }
    get operationReferencePrimitiveValue():string {
        return this.primitiveValueForKey('operationReference');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: paidAmount
    set paidAmount(value:number) {
        this.setValueForKey(value, 'paidAmount');
    }
    get paidAmount():number {
        return this.valueForKey('paidAmount');
    }
    set paidAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'paidAmount');
    }
    get paidAmountPrimitiveValue():number {
        return this.primitiveValueForKey('paidAmount');
    }

    // Property: paymentDate
    set paymentDate(value:Date) {
        this.setValueForKey(value, 'paymentDate');
    }
    get paymentDate():Date {
        return this.valueForKey('paymentDate');
    }
    set paymentDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'paymentDate');
    }
    get paymentDatePrimitiveValue():Date {
        return this.primitiveValueForKey('paymentDate');
    }

    // Property: returnedCode
    set returnedCode(value:string) {
        this.setValueForKey(value, 'returnedCode');
    }
    get returnedCode():string {
        return this.valueForKey('returnedCode');
    }
    set returnedCodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'returnedCode');
    }
    get returnedCodePrimitiveValue():string {
        return this.primitiveValueForKey('returnedCode');
    }

    // Property: returnedOperationReference
    set returnedOperationReference(value:string) {
        this.setValueForKey(value, 'returnedOperationReference');
    }
    get returnedOperationReference():string {
        return this.valueForKey('returnedOperationReference');
    }
    set returnedOperationReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'returnedOperationReference');
    }
    get returnedOperationReferencePrimitiveValue():string {
        return this.primitiveValueForKey('returnedOperationReference');
    }

    // Property: returnedReason
    set returnedReason(value:string) {
        this.setValueForKey(value, 'returnedReason');
    }
    get returnedReason():string {
        return this.valueForKey('returnedReason');
    }
    set returnedReasonPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'returnedReason');
    }
    get returnedReasonPrimitiveValue():string {
        return this.primitiveValueForKey('returnedReason');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: transactionReference
    set transactionReference(value:string) {
        this.setValueForKey(value, 'transactionReference');
    }
    get transactionReference():string {
        return this.valueForKey('transactionReference');
    }
    set transactionReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'transactionReference');
    }
    get transactionReferencePrimitiveValue():string {
        return this.primitiveValueForKey('transactionReference');
    }
    // Relationship: next
    set next(value:PaymentInstallment) {
        this.setValueForKey(value, 'next');
    }
    get next():PaymentInstallment {
        return this.valueForKey('next') as PaymentInstallment;
    }
    // Relationship: paymentEntity
    set paymentEntity(value:PaymentEntity) {
        this.setValueForKey(value, 'paymentEntity');
    }
    get paymentEntity():PaymentEntity {
        return this.valueForKey('paymentEntity') as PaymentEntity;
    }
    // Relationship: paymentMethod
    set paymentMethod(value:PayMethod) {
        this.setValueForKey(value, 'paymentMethod');
    }
    get paymentMethod():PayMethod {
        return this.valueForKey('paymentMethod') as PayMethod;
    }
    // Relationship: previous
    set previous(value:PaymentInstallment) {
        this.setValueForKey(value, 'previous');
    }
    get previous():PaymentInstallment {
        return this.valueForKey('previous') as PaymentInstallment;
    }
}
