/**
 * Created by miguel on 23/2/17.
 */



class EventCell extends UITableViewCell
{
    private nameLabel = null;
    private dateLabel = null;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, 'name-lbl', 'MUILabel');
        this.dateLabel = MUIOutlet(this, 'date-lbl', 'MUILabel');        
    }

    set item(i:VenueEvent){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        this.nameLabel.text = i.name;
        this.dateLabel.text = ad.dateTimeFormatter.stringFromDate(i.beginDateTime);
    }
}
