

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class DebtLine_ManagedObject

class DebtLine_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: creationDate
    set creationDate(value:Date) {
        this.setValueForKey(value, 'creationDate');
    }
    get creationDate():Date {
        return this.valueForKey('creationDate');
    }
    set creationDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'creationDate');
    }
    get creationDatePrimitiveValue():Date {
        return this.primitiveValueForKey('creationDate');
    }

    // Property: debtValue
    set debtValue(value:number) {
        this.setValueForKey(value, 'debtValue');
    }
    get debtValue():number {
        return this.valueForKey('debtValue');
    }
    set debtValuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'debtValue');
    }
    get debtValuePrimitiveValue():number {
        return this.primitiveValueForKey('debtValue');
    }

    // Property: documentID
    set documentID(value:string) {
        this.setValueForKey(value, 'documentID');
    }
    get documentID():string {
        return this.valueForKey('documentID');
    }
    set documentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentID');
    }
    get documentIDPrimitiveValue():string {
        return this.primitiveValueForKey('documentID');
    }

    // Property: isDiscarded
    set isDiscarded(value:boolean) {
        this.setValueForKey(value, 'isDiscarded');
    }
    get isDiscarded():boolean {
        return this.valueForKey('isDiscarded');
    }
    set isDiscardedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isDiscarded');
    }
    get isDiscardedPrimitiveValue():boolean {
        return this.primitiveValueForKey('isDiscarded');
    }

    // Property: isMoved
    set isMoved(value:boolean) {
        this.setValueForKey(value, 'isMoved');
    }
    get isMoved():boolean {
        return this.valueForKey('isMoved');
    }
    set isMovedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isMoved');
    }
    get isMovedPrimitiveValue():boolean {
        return this.primitiveValueForKey('isMoved');
    }

    // Property: isVoid
    set isVoid(value:boolean) {
        this.setValueForKey(value, 'isVoid');
    }
    get isVoid():boolean {
        return this.valueForKey('isVoid');
    }
    set isVoidPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isVoid');
    }
    get isVoidPrimitiveValue():boolean {
        return this.primitiveValueForKey('isVoid');
    }

    // Property: legalDocumentID
    set legalDocumentID(value:string) {
        this.setValueForKey(value, 'legalDocumentID');
    }
    get legalDocumentID():string {
        return this.valueForKey('legalDocumentID');
    }
    set legalDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalDocumentID');
    }
    get legalDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('legalDocumentID');
    }

    // Property: payedDate
    set payedDate(value:Date) {
        this.setValueForKey(value, 'payedDate');
    }
    get payedDate():Date {
        return this.valueForKey('payedDate');
    }
    set payedDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'payedDate');
    }
    get payedDatePrimitiveValue():Date {
        return this.primitiveValueForKey('payedDate');
    }

    // Property: payedValue
    set payedValue(value:number) {
        this.setValueForKey(value, 'payedValue');
    }
    get payedValue():number {
        return this.valueForKey('payedValue');
    }
    set payedValuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'payedValue');
    }
    get payedValuePrimitiveValue():number {
        return this.primitiveValueForKey('payedValue');
    }

    // Property: tipID
    set tipID(value:string) {
        this.setValueForKey(value, 'tipID');
    }
    get tipID():string {
        return this.valueForKey('tipID');
    }
    set tipIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tipID');
    }
    get tipIDPrimitiveValue():string {
        return this.primitiveValueForKey('tipID');
    }

    // Property: tipModifierString
    set tipModifierString(value:string) {
        this.setValueForKey(value, 'tipModifierString');
    }
    get tipModifierString():string {
        return this.valueForKey('tipModifierString');
    }
    set tipModifierStringPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tipModifierString');
    }
    get tipModifierStringPrimitiveValue():string {
        return this.primitiveValueForKey('tipModifierString');
    }
    // Relationship: economicEntity
    set economicEntity(value:EconomicEntity) {
        this.setValueForKey(value, 'economicEntity');
    }
    get economicEntity():EconomicEntity {
        return this.valueForKey('economicEntity') as EconomicEntity;
    }
    // Relationship: economicEntityParked
    set economicEntityParked(value:EconomicEntity) {
        this.setValueForKey(value, 'economicEntityParked');
    }
    get economicEntityParked():EconomicEntity {
        return this.valueForKey('economicEntityParked') as EconomicEntity;
    }
}
