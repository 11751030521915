

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Location_ManagedObject

class Location_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: angle
    set angle(value:number) {
        this.setValueForKey(value, 'angle');
    }
    get angle():number {
        return this.valueForKey('angle');
    }
    set anglePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'angle');
    }
    get anglePrimitiveValue():number {
        return this.primitiveValueForKey('angle');
    }

    // Property: h
    set h(value:number) {
        this.setValueForKey(value, 'h');
    }
    get h():number {
        return this.valueForKey('h');
    }
    set hPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'h');
    }
    get hPrimitiveValue():number {
        return this.primitiveValueForKey('h');
    }

    // Property: idIn
    set idIn(value:number) {
        this.setValueForKey(value, 'idIn');
    }
    get idIn():number {
        return this.valueForKey('idIn');
    }
    set idInPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'idIn');
    }
    get idInPrimitiveValue():number {
        return this.primitiveValueForKey('idIn');
    }

    // Property: isOccupied
    set isOccupied(value:boolean) {
        this.setValueForKey(value, 'isOccupied');
    }
    get isOccupied():boolean {
        return this.valueForKey('isOccupied');
    }
    set isOccupiedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isOccupied');
    }
    get isOccupiedPrimitiveValue():boolean {
        return this.primitiveValueForKey('isOccupied');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: qrCode
    set qrCode(value:string) {
        this.setValueForKey(value, 'qrCode');
    }
    get qrCode():string {
        return this.valueForKey('qrCode');
    }
    set qrCodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'qrCode');
    }
    get qrCodePrimitiveValue():string {
        return this.primitiveValueForKey('qrCode');
    }

    // Property: sndID
    set sndID(value:string) {
        this.setValueForKey(value, 'sndID');
    }
    get sndID():string {
        return this.valueForKey('sndID');
    }
    set sndIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sndID');
    }
    get sndIDPrimitiveValue():string {
        return this.primitiveValueForKey('sndID');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: w
    set w(value:number) {
        this.setValueForKey(value, 'w');
    }
    get w():number {
        return this.valueForKey('w');
    }
    set wPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'w');
    }
    get wPrimitiveValue():number {
        return this.primitiveValueForKey('w');
    }

    // Property: x
    set x(value:number) {
        this.setValueForKey(value, 'x');
    }
    get x():number {
        return this.valueForKey('x');
    }
    set xPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'x');
    }
    get xPrimitiveValue():number {
        return this.primitiveValueForKey('x');
    }

    // Property: y
    set y(value:number) {
        this.setValueForKey(value, 'y');
    }
    get y():number {
        return this.valueForKey('y');
    }
    set yPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'y');
    }
    get yPrimitiveValue():number {
        return this.primitiveValueForKey('y');
    }
    // Relationship: category
    set category(value:LocationCategory) {
        this.setValueForKey(value, 'category');
    }
    get category():LocationCategory {
        return this.valueForKey('category') as LocationCategory;
    }
    // Relationship: extraOrder
    set extraOrder(value:Order) {
        this.setValueForKey(value, 'extraOrder');
    }
    get extraOrder():Order {
        return this.valueForKey('extraOrder') as Order;
    }

    // Relationship: groupRules
    protected _groupRules:MIOManagedObjectSet = null;
    get groupRules():MIOManagedObjectSet {
        return this.valueForKey('groupRules');
    }
    addGroupRulesObject(value:LocationGroupRule) {
        this._addObjectForKey(value, 'groupRules');
    }
    removeGroupRulesObject(value:LocationGroupRule) {
        this._removeObjectForKey(value, 'groupRules');
    }
    // Relationship: order
    set order(value:Order) {
        this.setValueForKey(value, 'order');
    }
    get order():Order {
        return this.valueForKey('order') as Order;
    }
}
