

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class StockCategory_ManagedObject

class StockCategory_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: image
    set image(value:string) {
        this.setValueForKey(value, 'image');
    }
    get image():string {
        return this.valueForKey('image');
    }
    set imagePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'image');
    }
    get imagePrimitiveValue():string {
        return this.primitiveValueForKey('image');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: purchaseLedgerAccountName
    set purchaseLedgerAccountName(value:string) {
        this.setValueForKey(value, 'purchaseLedgerAccountName');
    }
    get purchaseLedgerAccountName():string {
        return this.valueForKey('purchaseLedgerAccountName');
    }
    set purchaseLedgerAccountNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'purchaseLedgerAccountName');
    }
    get purchaseLedgerAccountNamePrimitiveValue():string {
        return this.primitiveValueForKey('purchaseLedgerAccountName');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Relationship: products
    protected _products:MIOManagedObjectSet = null;
    get products():MIOManagedObjectSet {
        return this.valueForKey('products');
    }
    addProductsObject(value:Product) {
        this._addObjectForKey(value, 'products');
    }
    removeProductsObject(value:Product) {
        this._removeObjectForKey(value, 'products');
    }
    // Relationship: purchaseLedgerAccount
    set purchaseLedgerAccount(value:LedgerAccount) {
        this.setValueForKey(value, 'purchaseLedgerAccount');
    }
    get purchaseLedgerAccount():LedgerAccount {
        return this.valueForKey('purchaseLedgerAccount') as LedgerAccount;
    }
    // Relationship: purchaseTax
    set purchaseTax(value:Tax) {
        this.setValueForKey(value, 'purchaseTax');
    }
    get purchaseTax():Tax {
        return this.valueForKey('purchaseTax') as Tax;
    }

    // Relationship: subCategories
    protected _subCategories:MIOManagedObjectSet = null;
    get subCategories():MIOManagedObjectSet {
        return this.valueForKey('subCategories');
    }
    addSubCategoriesObject(value:StockCategory) {
        this._addObjectForKey(value, 'subCategories');
    }
    removeSubCategoriesObject(value:StockCategory) {
        this._removeObjectForKey(value, 'subCategories');
    }
    // Relationship: superCategory
    set superCategory(value:StockCategory) {
        this.setValueForKey(value, 'superCategory');
    }
    get superCategory():StockCategory {
        return this.valueForKey('superCategory') as StockCategory;
    }
}
