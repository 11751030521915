

class DBTagsCoder extends DBXLSXCoder
{
    mainEntity() {
        return { entity: "Tag", predicateFormat: "deletedAt == null" }
    }

    columns() : any[] {
        let cols = [
            { title:"TAG ID", width: "10%", align: "center" },
            { title:"TAG NAME", width: "10%", align: "center" },
            { title:"TAG TYPE", width: "10%", align: "center", formatter: this.ad.integerFormatter }
        ]
        return cols;
    }


    protected importRow(row:any){
                
        let tagID       = row["TAG ID"];
        let tagName     = row["TAG NAME"];
        let tagType     = row["TAG TYPE"];

        let tag = this.queryEntityByIDOrName("Tag", tagID, tagName) as Tag;
        if (tag == null) {
            tag = MIOEntityDescription.insertNewObjectForEntityForName("Tag", DBHelper.mainManagedObjectContext) as Tag;
            tag.identifier = this.parseOrCreateIdentifier(tagID);
            tag.name = tagName;
        }

        tag.type = tagType;
                
        this.appendObject(tag);
        MIOLog("ADDING TAG: " + this.rowIndex + "/" + this.rows.length + ": " + tagName);
    }

    protected exportTitle() : string { return "Tags"; }

    protected exportSortDescriptors() { 
        return [MIOSortDescriptor.sortDescriptorWithKey("name", false)]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let tag = object as Tag;

        let item = {
            "TAG ID"           : tag.identifier,
            "TAG NAME"         : tag.name,
            "TAG TYPE"         : tag.type
        }

        return item;
    }
}