

/// <reference path="DBDocumentLine.ts" />

// Generated class InvoiceLine_ManagedObject

class InvoiceLine_ManagedObject extends DBDocumentLine
{

    // Property: baseAmount
    set baseAmount(value:number) {
        this.setValueForKey(value, 'baseAmount');
    }
    get baseAmount():number {
        return this.valueForKey('baseAmount');
    }
    set baseAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'baseAmount');
    }
    get baseAmountPrimitiveValue():number {
        return this.primitiveValueForKey('baseAmount');
    }

    // Property: bulkMeasureUnit
    set bulkMeasureUnit(value:number) {
        this.setValueForKey(value, 'bulkMeasureUnit');
    }
    get bulkMeasureUnit():number {
        return this.valueForKey('bulkMeasureUnit');
    }
    set bulkMeasureUnitPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'bulkMeasureUnit');
    }
    get bulkMeasureUnitPrimitiveValue():number {
        return this.primitiveValueForKey('bulkMeasureUnit');
    }

    // Property: bulkQuantity
    set bulkQuantity(value:number) {
        this.setValueForKey(value, 'bulkQuantity');
    }
    get bulkQuantity():number {
        return this.valueForKey('bulkQuantity');
    }
    set bulkQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'bulkQuantity');
    }
    get bulkQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('bulkQuantity');
    }

    // Property: discountAmount
    set discountAmount(value:number) {
        this.setValueForKey(value, 'discountAmount');
    }
    get discountAmount():number {
        return this.valueForKey('discountAmount');
    }
    set discountAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'discountAmount');
    }
    get discountAmountPrimitiveValue():number {
        return this.primitiveValueForKey('discountAmount');
    }

    // Property: discountFormat
    set discountFormat(value:string) {
        this.setValueForKey(value, 'discountFormat');
    }
    get discountFormat():string {
        return this.valueForKey('discountFormat');
    }
    set discountFormatPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'discountFormat');
    }
    get discountFormatPrimitiveValue():string {
        return this.primitiveValueForKey('discountFormat');
    }

    // Property: invitedAmount
    set invitedAmount(value:number) {
        this.setValueForKey(value, 'invitedAmount');
    }
    get invitedAmount():number {
        return this.valueForKey('invitedAmount');
    }
    set invitedAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'invitedAmount');
    }
    get invitedAmountPrimitiveValue():number {
        return this.primitiveValueForKey('invitedAmount');
    }

    // Property: legalTaxAmount
    set legalTaxAmount(value:number) {
        this.setValueForKey(value, 'legalTaxAmount');
    }
    get legalTaxAmount():number {
        return this.valueForKey('legalTaxAmount');
    }
    set legalTaxAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'legalTaxAmount');
    }
    get legalTaxAmountPrimitiveValue():number {
        return this.primitiveValueForKey('legalTaxAmount');
    }

    // Property: legalTaxFactor
    set legalTaxFactor(value:number) {
        this.setValueForKey(value, 'legalTaxFactor');
    }
    get legalTaxFactor():number {
        return this.valueForKey('legalTaxFactor');
    }
    set legalTaxFactorPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'legalTaxFactor');
    }
    get legalTaxFactorPrimitiveValue():number {
        return this.primitiveValueForKey('legalTaxFactor');
    }

    // Property: priceAmount
    set priceAmount(value:number) {
        this.setValueForKey(value, 'priceAmount');
    }
    get priceAmount():number {
        return this.valueForKey('priceAmount');
    }
    set priceAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'priceAmount');
    }
    get priceAmountPrimitiveValue():number {
        return this.primitiveValueForKey('priceAmount');
    }

    // Property: taxesAmount
    set taxesAmount(value:number) {
        this.setValueForKey(value, 'taxesAmount');
    }
    get taxesAmount():number {
        return this.valueForKey('taxesAmount');
    }
    set taxesAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'taxesAmount');
    }
    get taxesAmountPrimitiveValue():number {
        return this.primitiveValueForKey('taxesAmount');
    }

    // Property: totalAmount
    set totalAmount(value:number) {
        this.setValueForKey(value, 'totalAmount');
    }
    get totalAmount():number {
        return this.valueForKey('totalAmount');
    }
    set totalAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalAmount');
    }
    get totalAmountPrimitiveValue():number {
        return this.primitiveValueForKey('totalAmount');
    }

    // Property: vatAmount
    set vatAmount(value:number) {
        this.setValueForKey(value, 'vatAmount');
    }
    get vatAmount():number {
        return this.valueForKey('vatAmount');
    }
    set vatAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'vatAmount');
    }
    get vatAmountPrimitiveValue():number {
        return this.primitiveValueForKey('vatAmount');
    }

    // Property: vatFactor
    set vatFactor(value:number) {
        this.setValueForKey(value, 'vatFactor');
    }
    get vatFactor():number {
        return this.valueForKey('vatFactor');
    }
    set vatFactorPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'vatFactor');
    }
    get vatFactorPrimitiveValue():number {
        return this.primitiveValueForKey('vatFactor');
    }
    // Relationship: currency
    set currency(value:Currency) {
        this.setValueForKey(value, 'currency');
    }
    get currency():Currency {
        return this.valueForKey('currency') as Currency;
    }
    // Relationship: legalTax
    set legalTax(value:LegalTax) {
        this.setValueForKey(value, 'legalTax');
    }
    get legalTax():LegalTax {
        return this.valueForKey('legalTax') as LegalTax;
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
    // Relationship: rate
    set rate(value:Rate) {
        this.setValueForKey(value, 'rate');
    }
    get rate():Rate {
        return this.valueForKey('rate') as Rate;
    }

    // Relationship: taxes
    protected _taxes:MIOManagedObjectSet = null;
    get taxes():MIOManagedObjectSet {
        return this.valueForKey('taxes');
    }
    addTaxesObject(value:Tax) {
        this._addObjectForKey(value, 'taxes');
    }
    removeTaxesObject(value:Tax) {
        this._removeObjectForKey(value, 'taxes');
    }
    // Relationship: vat
    set vat(value:Tax) {
        this.setValueForKey(value, 'vat');
    }
    get vat():Tax {
        return this.valueForKey('vat') as Tax;
    }
}
