

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Company_ManagedObject

class Company_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: businessDescription
    set businessDescription(value:string) {
        this.setValueForKey(value, 'businessDescription');
    }
    get businessDescription():string {
        return this.valueForKey('businessDescription');
    }
    set businessDescriptionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'businessDescription');
    }
    get businessDescriptionPrimitiveValue():string {
        return this.primitiveValueForKey('businessDescription');
    }

    // Property: businessName
    set businessName(value:string) {
        this.setValueForKey(value, 'businessName');
    }
    get businessName():string {
        return this.valueForKey('businessName');
    }
    set businessNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'businessName');
    }
    get businessNamePrimitiveValue():string {
        return this.primitiveValueForKey('businessName');
    }

    // Property: document
    set document(value:string) {
        this.setValueForKey(value, 'document');
    }
    get document():string {
        return this.valueForKey('document');
    }
    set documentPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'document');
    }
    get documentPrimitiveValue():string {
        return this.primitiveValueForKey('document');
    }

    // Property: email
    set email(value:string) {
        this.setValueForKey(value, 'email');
    }
    get email():string {
        return this.valueForKey('email');
    }
    set emailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'email');
    }
    get emailPrimitiveValue():string {
        return this.primitiveValueForKey('email');
    }

    // Property: imageID
    set imageID(value:string) {
        this.setValueForKey(value, 'imageID');
    }
    get imageID():string {
        return this.valueForKey('imageID');
    }
    set imageIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'imageID');
    }
    get imageIDPrimitiveValue():string {
        return this.primitiveValueForKey('imageID');
    }

    // Property: invoiceDisclaimer
    set invoiceDisclaimer(value:string) {
        this.setValueForKey(value, 'invoiceDisclaimer');
    }
    get invoiceDisclaimer():string {
        return this.valueForKey('invoiceDisclaimer');
    }
    set invoiceDisclaimerPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invoiceDisclaimer');
    }
    get invoiceDisclaimerPrimitiveValue():string {
        return this.primitiveValueForKey('invoiceDisclaimer');
    }

    // Property: legalEmail
    set legalEmail(value:string) {
        this.setValueForKey(value, 'legalEmail');
    }
    get legalEmail():string {
        return this.valueForKey('legalEmail');
    }
    set legalEmailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalEmail');
    }
    get legalEmailPrimitiveValue():string {
        return this.primitiveValueForKey('legalEmail');
    }

    // Property: legalEmailInfo
    set legalEmailInfo(value:string) {
        this.setValueForKey(value, 'legalEmailInfo');
    }
    get legalEmailInfo():string {
        return this.valueForKey('legalEmailInfo');
    }
    set legalEmailInfoPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalEmailInfo');
    }
    get legalEmailInfoPrimitiveValue():string {
        return this.primitiveValueForKey('legalEmailInfo');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: openingHours
    set openingHours(value:string) {
        this.setValueForKey(value, 'openingHours');
    }
    get openingHours():string {
        return this.valueForKey('openingHours');
    }
    set openingHoursPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'openingHours');
    }
    get openingHoursPrimitiveValue():string {
        return this.primitiveValueForKey('openingHours');
    }

    // Property: receiptDisclaimer
    set receiptDisclaimer(value:string) {
        this.setValueForKey(value, 'receiptDisclaimer');
    }
    get receiptDisclaimer():string {
        return this.valueForKey('receiptDisclaimer');
    }
    set receiptDisclaimerPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'receiptDisclaimer');
    }
    get receiptDisclaimerPrimitiveValue():string {
        return this.primitiveValueForKey('receiptDisclaimer');
    }

    // Property: supplierNoteDisclaimer
    set supplierNoteDisclaimer(value:string) {
        this.setValueForKey(value, 'supplierNoteDisclaimer');
    }
    get supplierNoteDisclaimer():string {
        return this.valueForKey('supplierNoteDisclaimer');
    }
    set supplierNoteDisclaimerPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'supplierNoteDisclaimer');
    }
    get supplierNoteDisclaimerPrimitiveValue():string {
        return this.primitiveValueForKey('supplierNoteDisclaimer');
    }

    // Property: supplierOrderConditions
    set supplierOrderConditions(value:string) {
        this.setValueForKey(value, 'supplierOrderConditions');
    }
    get supplierOrderConditions():string {
        return this.valueForKey('supplierOrderConditions');
    }
    set supplierOrderConditionsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'supplierOrderConditions');
    }
    get supplierOrderConditionsPrimitiveValue():string {
        return this.primitiveValueForKey('supplierOrderConditions');
    }

    // Property: supplierOrderDisclaimer
    set supplierOrderDisclaimer(value:string) {
        this.setValueForKey(value, 'supplierOrderDisclaimer');
    }
    get supplierOrderDisclaimer():string {
        return this.valueForKey('supplierOrderDisclaimer');
    }
    set supplierOrderDisclaimerPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'supplierOrderDisclaimer');
    }
    get supplierOrderDisclaimerPrimitiveValue():string {
        return this.primitiveValueForKey('supplierOrderDisclaimer');
    }

    // Property: web
    set web(value:string) {
        this.setValueForKey(value, 'web');
    }
    get web():string {
        return this.valueForKey('web');
    }
    set webPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'web');
    }
    get webPrimitiveValue():string {
        return this.primitiveValueForKey('web');
    }
    // Relationship: address
    set address(value:Address) {
        this.setValueForKey(value, 'address');
    }
    get address():Address {
        return this.valueForKey('address') as Address;
    }
    // Relationship: image
    set image(value:DBImage) {
        this.setValueForKey(value, 'image');
    }
    get image():DBImage {
        return this.valueForKey('image') as DBImage;
    }
    // Relationship: legalPhone
    set legalPhone(value:PhoneNumber) {
        this.setValueForKey(value, 'legalPhone');
    }
    get legalPhone():PhoneNumber {
        return this.valueForKey('legalPhone') as PhoneNumber;
    }
    // Relationship: logoEmail
    set logoEmail(value:DBImage) {
        this.setValueForKey(value, 'logoEmail');
    }
    get logoEmail():DBImage {
        return this.valueForKey('logoEmail') as DBImage;
    }
    // Relationship: mobilePhone
    set mobilePhone(value:PhoneNumber) {
        this.setValueForKey(value, 'mobilePhone');
    }
    get mobilePhone():PhoneNumber {
        return this.valueForKey('mobilePhone') as PhoneNumber;
    }
    // Relationship: phone
    set phone(value:PhoneNumber) {
        this.setValueForKey(value, 'phone');
    }
    get phone():PhoneNumber {
        return this.valueForKey('phone') as PhoneNumber;
    }
}
