//
// Generated class PriceGroupConfiguration
//

/// <reference path="PriceGroupConfiguration_ManagedObject.ts" />

class PriceGroupConfiguration extends PriceGroupConfiguration_ManagedObject
{

}
