
class LegalTaxCell extends UITableViewCell
{
    private nameTextField:MUITextField = null;
    private typeDropDown:MUIButton = null;
    private amountTextField:MUITextField = null;

    private pnf = MUIWebApplication.sharedInstance().delegate.percentNumberFormatter as MIONumberFormatter;
    
    awakeFromHTML(){
        this.nameTextField = MUIOutlet(this, 'name-tf', 'MUITextField');
        this.nameTextField.setOnChangeText(this, function(textfield, value){
            this._legalTax.name = value.trim();
        });  

        this.typeDropDown = MUIOutlet(this, 'type-dd', 'MUIButton');
        this.typeDropDown.setAction(this, function (){            
            this.showSelectTypeViewController(this.typeDropDown);
        });

        this.amountTextField = MUIOutlet(this, 'amount-tf', 'MUITextField');
        this.amountTextField.formatter = this.pnf;
        this.amountTextField.setOnChangeText(this, function(textfield, value){
            let v = this.pnf.numberFromString(value.trim());
            this._legalTax.value = v / 100;
        });      
    }
    
    private _legalTax:LegalTax = null;
    set item(i:LegalTax){
        this._legalTax = i;
        this.nameTextField.text = i.name;
        this.typeDropDown.title = (i.type) ? i.type?.name : MIOLocalizeString('SELECT', 'SELECT');
        this.amountTextField.text = this.pnf.stringFromNumber( i.value * 100 );
    }

    private showSelectTypeViewController(button:MUIButton) {
        let vc = AppHelper.sharedInstance().showCustomSelectViewControllerFromView(button, 'LegalTaxType', 'name', null, this, function(controller, object:LegalTaxType){
            //returns object, test value then update LegalTaxType

            if (object) {
                button.title = object.valueForKey('name');
                this._legalTax.type = object
            } else {
                button.title = MIOLocalizeString("SELECT","SELECT");
                this._legalTax.type = null;
            }

        }, true );        
    }

}
