

class VIPCardListViewController extends MUIViewController
{
    private segmentedControl:MUISegmentedControl = null;
    private addButton:MUIButton = null;
    private tableView:MUITableView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.segmentedControl = MUIOutlet(this, "segmented-ctl", "MUISegmentedControl");
        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function(){
            let vc = new VIPCardAddViewController("vip-cards-add-invitation-view");
            vc.initWithResource("layout/vip-cards/VIPCardsAddInvitationView.html");
            vc.delegate = this;
            this.presentViewController(vc, true);
        });
        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.tableView.reloadData();
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell:VIPCardCell = tableview.dequeueReusableCellWithIdentifier("CardCell");

        let item:VIPCard = this.fetchedResultsController.objectAtIndexPath(indexPath);
        cell.item = item;        

        return cell;
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("VIPCard");
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

        // if (this.searchString != null)
        //     fetchRequest.predicate = MIOPredicate.predicateWithFormat("name CONTAINS \"" + this.searchString + "\"");

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;

        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    sendVIPCardEmails(emails){

        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        for (let index = 0; index < emails.count; index++){
            let card = MIOEntityDescription.insertNewObjectForEntityForName("VIPCard", moc) as VIPCard;                        
            card.email = emails[index];
        }

        moc.save();
    }
}