//
// Generated class Reseller
//

/// <reference path="Reseller_ManagedObject.ts" />

class Reseller extends Reseller_ManagedObject
{

}
