/**
 * Created by miguel on 23/2/17.
 */



class OfferDetailCell extends MUITableViewCell
{
    nameLabel:MUILabel = null;
    
    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, 'name-lbl', 'MUILabel');
    }

    set item(product:Product){
        this.nameLabel.text = product.name;
    }
}
