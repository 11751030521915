/**
 * Created by miguel on 22/2/17.
 */


class InvoiceListViewController extends MasterListViewController
{
    static newInstance():InvoiceListViewController {
        let vc = new InvoiceListViewController("invoice-list-view");        
        vc.initWithResource("layout/invoices/InvoiceListView.html");        
        return vc;
    }

    protected titleLabel:MUILabel = null;
    protected addButton:MUIButton = null;
	private filterButton:MUIButton = null;
    

    viewDidLoad(){
        super.viewDidLoad();
		
        this.searchTextField = MUIOutlet( this, "search-bar", "MUITextField" );
        this.setupSearchTextField();

        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");        
        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, this.addInvoice);

		this.filterButton = MUIOutlet(this, "filter-btn", "MUIButton");
		this.filterButton.setAction(this, function() {
			AppHelper.sharedInstance().showFilterViewController(this, this.filters, this.filtersDidChange);
		});

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        //trying a change in tableview to UItableView
        this.tableView = MUIOutlet(this, "tableview", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        MIONotificationCenter.defaultCenter().addObserver(this, "InvoiceWillDelete", function(this:InvoiceListViewController, note:MIONotification){
            if (this.selectedItem != note.object) return;
            this.selectedItem = null;
            let ip = this.fetchedResultsController.indexPathForObject(note.object);
            let cell = this.tableView.cellAtIndexPath(ip);
            if (cell != null) cell.selected = false;
            this.showNoItemSelected();
        });
    }    

    titleForHeaderInSection(tableView:UITableView, section:number){
        if (this.fetchedResultsController.sections.length < 2) return null;
        
        let ip = MIOIndexPath.indexForRowInSection(0, section);
        let item = this.fetchedResultsController.objectAtIndexPath(ip) as Invoice;
                
        return item.monthString;
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("InvoiceCell") as InvoiceCell;

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Invoice;
        cell.item = item;
        cell.selected = (this.selectedItem?.identifier == item.identifier);

        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let obj = this.fetchedResultsController.objectAtIndexPath(indexPath);
        this.selectedItem = obj;

        this.showSelectedInvoice();
    }

    protected showSelectedInvoice()
    {
        let vc = InvoiceDetailViewController.newInstance();
        vc.item = this.selectedItem;
        this.splitViewController.showDetailViewController(vc);
    }

    protected showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Invoice", NoItemSelectedViewControllerType.Invoice);
        this.splitViewController.showDetailViewController(vc);
	}
	
    get entityName():string { return "Invoice"; }

	protected _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value:MIOFetchedResultsController){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
            
        let sds = [ 
            MIOSortDescriptor.sortDescriptorWithKey("date", false), 
            MIOSortDescriptor.sortDescriptorWithKey("documentNumber", false)
        ];

        let predicates = ["deletedAt == null"]
        if (this.searchString != null) {
            predicates.addObject ("(documentNumber CONTAINS '" + this.searchString + "'" + " OR legalEntity.name CONTAINS '" + this.searchString + "'" + ")");
        }
        if (this.dateTimeFrom != null && this.dateTimeTo != null) {
            predicates.addObject ("(date >= '" + this.dateTimeFrom + "' AND date <= '" + this.dateTimeTo + "')");
		}
		if (this.filterPredicateFormat != null) {
            predicates.addObject( this.filterPredicateFormat );
        }
                
        let predicateFormat = predicates.join(" AND ");
    
        let fetchRequest = DBHelper.listFetchRequestWithEntityName(this.entityName, sds, predicateFormat);
        fetchRequest.fetchLimit = 500;
        fetchRequest.relationshipKeyPathsForPrefetching = ["legalEntity", "nextDocument"];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "sectionDateString");
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    private dateTimeFrom:string = null;
    private dateTimeTo:string = null;    
    datesDidSelect(dateTimeFrom, dateTimeTo) {
        this.dateTimeFrom = MIODateGetDateString(dateTimeFrom) + " 00:00:00";
        this.dateTimeTo = MIODateGetDateString(dateTimeTo) + " 23:59:59";
        
        //this.showNoItemSelectedView();
        this.setFetchedResultsController(null);
        this.tableView.reloadData();
	}
	
	private _filters = null;
    get filters(){
        if (this._filters != null) return this._filters;

        this._filters = [
                FilterOption.filterWithTitle("FromDate", "From day", "date", FilterFieldType.Date, null, FilterControllerComparatorType.MajorOrEqualComparator, null, "00:00:00"),
				FilterOption.filterWithTitle("ToDate", "To day", "date", FilterFieldType.Date, null, FilterControllerComparatorType.MinorOrEqualComparator, null, "23:59:59"),
				FilterOption.filterWithTitle("Amount", "Amount", "totalPrice", FilterFieldType.Number, null, null),            
                FilterOption.filterWithFetchRequest("Client", "Client", "legalEntity.identifier", FilterFieldType.DropDown, "Client", "name", "identifier", MIOLocalizeString("SELECT CLIENT","Select Client"))];                
        return this._filters;
	}
	
	protected filterPredicateFormat:string = null;
    protected filtersDidChange(filtersPredicateFormat){
        if(filtersPredicateFormat != null) {
            MUICoreLayerAddStyle(this.filterButton.layer, 'filter');
            MUICoreLayerRemoveStyle(this.filterButton.layer, 'filter-off');
        } else {
            MUICoreLayerRemoveStyle(this.filterButton.layer, 'filter');
            MUICoreLayerAddStyle(this.filterButton.layer, 'filter-off');
        }

        this.filterPredicateFormat = filtersPredicateFormat;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
	}

    protected showLegalEntitySelectionViewController( numberSequence:NumberSerialSequence = null, type:LegalEntityDefaultSelectionType = LegalEntityDefaultSelectionType.client, userInfo:any = null ) {
        let vc = LegalEntitySelectionViewController.newInstance();
        vc.defaultSelectionType = type;
        vc.completionBlock = (item:LegalEntity) => {
            this.createNewInvoice( item, numberSequence, userInfo );
        };
        
        this.presentViewController(vc, true);
    }

    protected createNewInvoice( entity:LegalEntity, numberSequence: NumberSerialSequence, userInfo:any ) {}
    protected addInvoice() {}

}