
/// <reference path="DBXLSXCoder.ts" />


class DBInvoiceLinesCoder extends DBXLSXCoder
{
    protected mainEntity(): {} {
        return { entity: "InvoiceLine", predicateFormat: "deletedAt == null", relations: ["product", "tax"] };
    }

    protected columns() : any[] {
        let cols = [
            { title: "INVOICE NUMBER", width: "10%", align: "center" },
            { title: "DATE", width: "10%", align: "center" },
            { title: "PRODUCT REFERENCE", width: "20%", align: "center" },
            { title: "CONCEPT", width: "20%", align: "center" },
            { title: "QUANTITY", width: "10%", align: "center" },
            { title: "TAX NAME", width: "10%", align: "center" },
            { title: "BASE AMOUNT", width: "10%", align: "center", formatter: this.ad.currencyFormatter },
            { title: "TAX AMOUNT", width: "10%", align: "center", formatter: this.ad.currencyFormatter },
            { title: "DISCOUNT AMOUNT", width: "10%", align: "center", formatter: this.ad.currencyFormatter },
            { title: "TOTAL AMOUNT", width: "10%", align: "center", formatter: this.ad.currencyFormatter },
            { title: "COMMENTS", width: "10%", align: "center" },
            { title: "TAGS", width: "10%", align: "center" }
        ]
        return cols;
    }

    protected aditionalImportEntities(){
        return [
            { entity: "Invoice", predicateFormat: "deletedAt == null" },
            { entity: "Tax", predicateFormat: "deletedAt == null" },
            { entity: "Tag", predicateFormat: "type == " + TagType.ProductOrMenu },
            { entity: "Product", predicateFormat: "deletedAt == null" }
        ]
    }

    protected importRow(row:any){
        
        let invoiceNumber    = row["INVOICE NUMBER"];
        let dateValue        = row["DATE"];
        let productReference = row["PRODUCT REFERENCE"];
        let concept          = row["CONCEPT"];
        let quantity         = row["QUANTITY"];
        let taxName          = row["TAX NAME"];
        let base             = row["BASE AMOUNT"];        
        let taxAmount        = row["TAX AMOUNT"];
        let total            = row["TOTAL AMOUNT"];
        let discount         = row["DISCOUNT AMOUNT"];
        let comments         = row["COMMENTS"];
        let tagValues        = row["TAGS"];        

        if (total == null) return;
        if (quantity == null) return;
        if (concept == null) return;

        let tax = this.queryEntityByField("Tax", "name", taxName) as Tax;
        if (tax == null) {
            // Throw error
            return;
        }        

        let tagsComponents = tagValues != null ? tagValues.split(",") : [];
        let tags = new MIOSet();
        for (let index = 0; index < tagsComponents.length; index++) {
            let tagName = tagsComponents[index];
            let tag = this.queryEntityByField("Tag", "name", tagName) as Tag;
            if (tagName != null && tag == null) { 
                tag = MIOEntityDescription.insertNewObjectForEntityForName("Tag", DBHelper.mainManagedObjectContext) as Tag;
                tag.identifier = new MIOUUID().UUIDString;
    
                tag.name = tagName;
                tag.type = TagType.ProductOrMenu;
                this.appendObject(tag);
            }    
            tags.addObject(tagName);
        }

        let invoice = this.queryEntityByField("Invoice", "documentNumber", invoiceNumber) as Invoice;
        if (invoice == null) {
            // Throw error
            return;
        }

        let product = this.queryEntityByField("Product", "reference", productReference) as Product;
        if (product == null) product = this.queryEntityByField("Product", "name", concept) as Product;

        let line = this.queryEntity("InvoiceLine", "concept = %@ and document.identifier = %@", concept, invoice.identifier) as InvoiceLine;
        if (line == null){
            line = MIOEntityDescription.insertNewObjectForEntityForName("InvoiceLine", DBHelper.mainManagedObjectContext) as InvoiceLine;        
            line.identifier = new MIOUUID().UUIDString;
            line.document = invoice;
            this.appendObject(line);
        }

        let date = this.parseDateValue(dateValue);
        if (date == null) { date = invoice.date; }

        line.date = date;
        line.concept = concept;
        line.product = product;
        line.quantity = quantity;
        line.tax = tax;
        line.taxFactor = tax.taxQuantity;
        line.totalAmount = total ?? 0;

        line.taxAmount = taxAmount != null ? taxAmount : total - (total / (1 + tax.taxQuantity) );
        line.priceAmount = base != null ? base : total - line.taxAmount;
        
        line.discountAmount = discount ?? 0;
        line.comments = comments;

        if (product != null && product.tags != null && product.tags.length > 0) {
            let productTags = product.tags.split(",");
            for (let index = 0; index < productTags.length; index++) {
                let pt = productTags[index];
                tags.addObject(pt);
            }            
        }
        
        line.tags = tags.count > 0 ? tags.allObjects.join(",") : null;

        MIOLog("ADDING INVOICE LINE: " + this.rowIndex + "/" + this.rows.length + ": " + invoiceNumber + " " + concept);
    }   
}