//
// Generated class AccountAnnotation
//

/// <reference path="AccountAnnotation_ManagedObject.ts" />

class AccountAnnotation extends AccountAnnotation_ManagedObject
{

}
