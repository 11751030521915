//
// Generated class WhatsappTemplate
//

/// <reference path="WhatsappTemplate_ManagedObject.ts" />

class WhatsappTemplate extends WhatsappTemplate_ManagedObject
{

}
