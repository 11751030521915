

/// <reference path="CheffLine.ts" />

// Generated class MenuProductLine_ManagedObject

class MenuProductLine_ManagedObject extends CheffLine
{
    // Relationship: menuLine
    set menuLine(value:MenuLine) {
        this.setValueForKey(value, 'menuLine');
    }
    get menuLine():MenuLine {
        return this.valueForKey('menuLine') as MenuLine;
    }
    // Relationship: productMenu
    set productMenu(value:ProductMenu) {
        this.setValueForKey(value, 'productMenu');
    }
    get productMenu():ProductMenu {
        return this.valueForKey('productMenu') as ProductMenu;
    }
    // Relationship: separatedMenuLine
    set separatedMenuLine(value:SeparatedMenuLine) {
        this.setValueForKey(value, 'separatedMenuLine');
    }
    get separatedMenuLine():SeparatedMenuLine {
        return this.valueForKey('separatedMenuLine') as SeparatedMenuLine;
    }
}
