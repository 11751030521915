

class WorkersPermissionsListRoleCell extends MUIReportTableViewCell
{
    private comboBox:MUIComboBox = null;
    private button:MUIButton = null;

    awakeFromHTML(){
        this.comboBox = MUIOutlet(this, "combobox", "MUIComboBox");
        this.button = MUIOutlet(this, "button", "MUIButton");
    }

    setItem(item:Employee, colIndex){
        if (colIndex == 1) this.button.setHidden(true);
        else if (colIndex == 2) this.button.setHidden(false);
        //this.roleComboBox.selectItem(item.roleID);
    }
}