

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Product_ManagedObject

class Product_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: additionalCosts
    set additionalCosts(value:number) {
        this.setValueForKey(value, 'additionalCosts');
    }
    get additionalCosts():number {
        return this.valueForKey('additionalCosts');
    }
    set additionalCostsPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'additionalCosts');
    }
    get additionalCostsPrimitiveValue():number {
        return this.primitiveValueForKey('additionalCosts');
    }

    // Property: additionalProductCosts
    set additionalProductCosts(value:number) {
        this.setValueForKey(value, 'additionalProductCosts');
    }
    get additionalProductCosts():number {
        return this.valueForKey('additionalProductCosts');
    }
    set additionalProductCostsPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'additionalProductCosts');
    }
    get additionalProductCostsPrimitiveValue():number {
        return this.primitiveValueForKey('additionalProductCosts');
    }

    // Property: additionalStorageCosts
    set additionalStorageCosts(value:number) {
        this.setValueForKey(value, 'additionalStorageCosts');
    }
    get additionalStorageCosts():number {
        return this.valueForKey('additionalStorageCosts');
    }
    set additionalStorageCostsPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'additionalStorageCosts');
    }
    get additionalStorageCostsPrimitiveValue():number {
        return this.primitiveValueForKey('additionalStorageCosts');
    }

    // Property: allergens
    set allergens(value:number) {
        this.setValueForKey(value, 'allergens');
    }
    get allergens():number {
        return this.valueForKey('allergens');
    }
    set allergensPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'allergens');
    }
    get allergensPrimitiveValue():number {
        return this.primitiveValueForKey('allergens');
    }

    // Property: allowSubproductSale
    set allowSubproductSale(value:boolean) {
        this.setValueForKey(value, 'allowSubproductSale');
    }
    get allowSubproductSale():boolean {
        return this.valueForKey('allowSubproductSale');
    }
    set allowSubproductSalePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'allowSubproductSale');
    }
    get allowSubproductSalePrimitiveValue():boolean {
        return this.primitiveValueForKey('allowSubproductSale');
    }

    // Property: askForExternalInfo
    set askForExternalInfo(value:boolean) {
        this.setValueForKey(value, 'askForExternalInfo');
    }
    get askForExternalInfo():boolean {
        return this.valueForKey('askForExternalInfo');
    }
    set askForExternalInfoPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'askForExternalInfo');
    }
    get askForExternalInfoPrimitiveValue():boolean {
        return this.primitiveValueForKey('askForExternalInfo');
    }

    // Property: barcode
    set barcode(value:string) {
        this.setValueForKey(value, 'barcode');
    }
    get barcode():string {
        return this.valueForKey('barcode');
    }
    set barcodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'barcode');
    }
    get barcodePrimitiveValue():string {
        return this.primitiveValueForKey('barcode');
    }

    // Property: barcode2
    set barcode2(value:string) {
        this.setValueForKey(value, 'barcode2');
    }
    get barcode2():string {
        return this.valueForKey('barcode2');
    }
    set barcode2PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'barcode2');
    }
    get barcode2PrimitiveValue():string {
        return this.primitiveValueForKey('barcode2');
    }

    // Property: barcodeRequiredOption
    set barcodeRequiredOption(value:number) {
        this.setValueForKey(value, 'barcodeRequiredOption');
    }
    get barcodeRequiredOption():number {
        return this.valueForKey('barcodeRequiredOption');
    }
    set barcodeRequiredOptionPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'barcodeRequiredOption');
    }
    get barcodeRequiredOptionPrimitiveValue():number {
        return this.primitiveValueForKey('barcodeRequiredOption');
    }

    // Property: brand
    set brand(value:string) {
        this.setValueForKey(value, 'brand');
    }
    get brand():string {
        return this.valueForKey('brand');
    }
    set brandPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'brand');
    }
    get brandPrimitiveValue():string {
        return this.primitiveValueForKey('brand');
    }

    // Property: componentsInfo
    set componentsInfo(value:string) {
        this.setValueForKey(value, 'componentsInfo');
    }
    get componentsInfo():string {
        return this.valueForKey('componentsInfo');
    }
    set componentsInfoPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'componentsInfo');
    }
    get componentsInfoPrimitiveValue():string {
        return this.primitiveValueForKey('componentsInfo');
    }

    // Property: componentsRation
    set componentsRation(value:number) {
        this.setValueForKey(value, 'componentsRation');
    }
    get componentsRation():number {
        return this.valueForKey('componentsRation');
    }
    set componentsRationPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'componentsRation');
    }
    get componentsRationPrimitiveValue():number {
        return this.primitiveValueForKey('componentsRation');
    }

    // Property: computedCost
    set computedCost(value:number) {
        this.setValueForKey(value, 'computedCost');
    }
    get computedCost():number {
        return this.valueForKey('computedCost');
    }
    set computedCostPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'computedCost');
    }
    get computedCostPrimitiveValue():number {
        return this.primitiveValueForKey('computedCost');
    }

    // Property: computedCostDate
    set computedCostDate(value:Date) {
        this.setValueForKey(value, 'computedCostDate');
    }
    get computedCostDate():Date {
        return this.valueForKey('computedCostDate');
    }
    set computedCostDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'computedCostDate');
    }
    get computedCostDatePrimitiveValue():Date {
        return this.primitiveValueForKey('computedCostDate');
    }

    // Property: computedCostFactor
    set computedCostFactor(value:number) {
        this.setValueForKey(value, 'computedCostFactor');
    }
    get computedCostFactor():number {
        return this.valueForKey('computedCostFactor');
    }
    set computedCostFactorPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'computedCostFactor');
    }
    get computedCostFactorPrimitiveValue():number {
        return this.primitiveValueForKey('computedCostFactor');
    }

    // Property: computedMinCost
    set computedMinCost(value:number) {
        this.setValueForKey(value, 'computedMinCost');
    }
    get computedMinCost():number {
        return this.valueForKey('computedMinCost');
    }
    set computedMinCostPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'computedMinCost');
    }
    get computedMinCostPrimitiveValue():number {
        return this.primitiveValueForKey('computedMinCost');
    }

    // Property: conservation
    set conservation(value:string) {
        this.setValueForKey(value, 'conservation');
    }
    get conservation():string {
        return this.valueForKey('conservation');
    }
    set conservationPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'conservation');
    }
    get conservationPrimitiveValue():string {
        return this.primitiveValueForKey('conservation');
    }

    // Property: cost
    set cost(value:number) {
        this.setValueForKey(value, 'cost');
    }
    get cost():number {
        return this.valueForKey('cost');
    }
    set costPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'cost');
    }
    get costPrimitiveValue():number {
        return this.primitiveValueForKey('cost');
    }

    // Property: costAveragePrice
    set costAveragePrice(value:number) {
        this.setValueForKey(value, 'costAveragePrice');
    }
    get costAveragePrice():number {
        return this.valueForKey('costAveragePrice');
    }
    set costAveragePricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'costAveragePrice');
    }
    get costAveragePricePrimitiveValue():number {
        return this.primitiveValueForKey('costAveragePrice');
    }

    // Property: costComponents
    set costComponents(value:number) {
        this.setValueForKey(value, 'costComponents');
    }
    get costComponents():number {
        return this.valueForKey('costComponents');
    }
    set costComponentsPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'costComponents');
    }
    get costComponentsPrimitiveValue():number {
        return this.primitiveValueForKey('costComponents');
    }

    // Property: costLastPrice
    set costLastPrice(value:number) {
        this.setValueForKey(value, 'costLastPrice');
    }
    get costLastPrice():number {
        return this.valueForKey('costLastPrice');
    }
    set costLastPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'costLastPrice');
    }
    get costLastPricePrimitiveValue():number {
        return this.primitiveValueForKey('costLastPrice');
    }

    // Property: costPrice
    set costPrice(value:number) {
        this.setValueForKey(value, 'costPrice');
    }
    get costPrice():number {
        return this.valueForKey('costPrice');
    }
    set costPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'costPrice');
    }
    get costPricePrimitiveValue():number {
        return this.primitiveValueForKey('costPrice');
    }

    // Property: costProductAveragePrice
    set costProductAveragePrice(value:number) {
        this.setValueForKey(value, 'costProductAveragePrice');
    }
    get costProductAveragePrice():number {
        return this.valueForKey('costProductAveragePrice');
    }
    set costProductAveragePricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'costProductAveragePrice');
    }
    get costProductAveragePricePrimitiveValue():number {
        return this.primitiveValueForKey('costProductAveragePrice');
    }

    // Property: costProductComponentsPrice
    set costProductComponentsPrice(value:number) {
        this.setValueForKey(value, 'costProductComponentsPrice');
    }
    get costProductComponentsPrice():number {
        return this.valueForKey('costProductComponentsPrice');
    }
    set costProductComponentsPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'costProductComponentsPrice');
    }
    get costProductComponentsPricePrimitiveValue():number {
        return this.primitiveValueForKey('costProductComponentsPrice');
    }

    // Property: costProductLastPrice
    set costProductLastPrice(value:number) {
        this.setValueForKey(value, 'costProductLastPrice');
    }
    get costProductLastPrice():number {
        return this.valueForKey('costProductLastPrice');
    }
    set costProductLastPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'costProductLastPrice');
    }
    get costProductLastPricePrimitiveValue():number {
        return this.primitiveValueForKey('costProductLastPrice');
    }

    // Property: costProductPrice
    set costProductPrice(value:number) {
        this.setValueForKey(value, 'costProductPrice');
    }
    get costProductPrice():number {
        return this.valueForKey('costProductPrice');
    }
    set costProductPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'costProductPrice');
    }
    get costProductPricePrimitiveValue():number {
        return this.primitiveValueForKey('costProductPrice');
    }

    // Property: costType
    set costType(value:number) {
        this.setValueForKey(value, 'costType');
    }
    get costType():number {
        return this.valueForKey('costType');
    }
    set costTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'costType');
    }
    get costTypePrimitiveValue():number {
        return this.primitiveValueForKey('costType');
    }

    // Property: defaultInputFormatName
    set defaultInputFormatName(value:string) {
        this.setValueForKey(value, 'defaultInputFormatName');
    }
    get defaultInputFormatName():string {
        return this.valueForKey('defaultInputFormatName');
    }
    set defaultInputFormatNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'defaultInputFormatName');
    }
    get defaultInputFormatNamePrimitiveValue():string {
        return this.primitiveValueForKey('defaultInputFormatName');
    }

    // Property: defaultProductFormatName
    set defaultProductFormatName(value:string) {
        this.setValueForKey(value, 'defaultProductFormatName');
    }
    get defaultProductFormatName():string {
        return this.valueForKey('defaultProductFormatName');
    }
    set defaultProductFormatNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'defaultProductFormatName');
    }
    get defaultProductFormatNamePrimitiveValue():string {
        return this.primitiveValueForKey('defaultProductFormatName');
    }

    // Property: defaultSupplierName
    set defaultSupplierName(value:string) {
        this.setValueForKey(value, 'defaultSupplierName');
    }
    get defaultSupplierName():string {
        return this.valueForKey('defaultSupplierName');
    }
    set defaultSupplierNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'defaultSupplierName');
    }
    get defaultSupplierNamePrimitiveValue():string {
        return this.primitiveValueForKey('defaultSupplierName');
    }

    // Property: defaultWarehouseName
    set defaultWarehouseName(value:string) {
        this.setValueForKey(value, 'defaultWarehouseName');
    }
    get defaultWarehouseName():string {
        return this.valueForKey('defaultWarehouseName');
    }
    set defaultWarehouseNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'defaultWarehouseName');
    }
    get defaultWarehouseNamePrimitiveValue():string {
        return this.primitiveValueForKey('defaultWarehouseName');
    }

    // Property: detailedInfo
    set detailedInfo(value:string) {
        this.setValueForKey(value, 'detailedInfo');
    }
    get detailedInfo():string {
        return this.valueForKey('detailedInfo');
    }
    set detailedInfoPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'detailedInfo');
    }
    get detailedInfoPrimitiveValue():string {
        return this.primitiveValueForKey('detailedInfo');
    }

    // Property: discountDisabled
    set discountDisabled(value:boolean) {
        this.setValueForKey(value, 'discountDisabled');
    }
    get discountDisabled():boolean {
        return this.valueForKey('discountDisabled');
    }
    set discountDisabledPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'discountDisabled');
    }
    get discountDisabledPrimitiveValue():boolean {
        return this.primitiveValueForKey('discountDisabled');
    }

    // Property: elaboration
    set elaboration(value:string) {
        this.setValueForKey(value, 'elaboration');
    }
    get elaboration():string {
        return this.valueForKey('elaboration');
    }
    set elaborationPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'elaboration');
    }
    get elaborationPrimitiveValue():string {
        return this.primitiveValueForKey('elaboration');
    }

    // Property: enabledForDelivery
    set enabledForDelivery(value:boolean) {
        this.setValueForKey(value, 'enabledForDelivery');
    }
    get enabledForDelivery():boolean {
        return this.valueForKey('enabledForDelivery');
    }
    set enabledForDeliveryPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'enabledForDelivery');
    }
    get enabledForDeliveryPrimitiveValue():boolean {
        return this.primitiveValueForKey('enabledForDelivery');
    }

    // Property: enabledForTakeAway
    set enabledForTakeAway(value:boolean) {
        this.setValueForKey(value, 'enabledForTakeAway');
    }
    get enabledForTakeAway():boolean {
        return this.valueForKey('enabledForTakeAway');
    }
    set enabledForTakeAwayPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'enabledForTakeAway');
    }
    get enabledForTakeAwayPrimitiveValue():boolean {
        return this.primitiveValueForKey('enabledForTakeAway');
    }

    // Property: enabledForWaiter
    set enabledForWaiter(value:boolean) {
        this.setValueForKey(value, 'enabledForWaiter');
    }
    get enabledForWaiter():boolean {
        return this.valueForKey('enabledForWaiter');
    }
    set enabledForWaiterPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'enabledForWaiter');
    }
    get enabledForWaiterPrimitiveValue():boolean {
        return this.primitiveValueForKey('enabledForWaiter');
    }

    // Property: expiration
    set expiration(value:string) {
        this.setValueForKey(value, 'expiration');
    }
    get expiration():string {
        return this.valueForKey('expiration');
    }
    set expirationPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'expiration');
    }
    get expirationPrimitiveValue():string {
        return this.primitiveValueForKey('expiration');
    }

    // Property: expirationDateRequiredOption
    set expirationDateRequiredOption(value:number) {
        this.setValueForKey(value, 'expirationDateRequiredOption');
    }
    get expirationDateRequiredOption():number {
        return this.valueForKey('expirationDateRequiredOption');
    }
    set expirationDateRequiredOptionPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'expirationDateRequiredOption');
    }
    get expirationDateRequiredOptionPrimitiveValue():number {
        return this.primitiveValueForKey('expirationDateRequiredOption');
    }

    // Property: foodTraits
    set foodTraits(value:number) {
        this.setValueForKey(value, 'foodTraits');
    }
    get foodTraits():number {
        return this.valueForKey('foodTraits');
    }
    set foodTraitsPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'foodTraits');
    }
    get foodTraitsPrimitiveValue():number {
        return this.primitiveValueForKey('foodTraits');
    }

    // Property: formatRequired
    set formatRequired(value:boolean) {
        this.setValueForKey(value, 'formatRequired');
    }
    get formatRequired():boolean {
        return this.valueForKey('formatRequired');
    }
    set formatRequiredPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'formatRequired');
    }
    get formatRequiredPrimitiveValue():boolean {
        return this.primitiveValueForKey('formatRequired');
    }

    // Property: groupID
    set groupID(value:string) {
        this.setValueForKey(value, 'groupID');
    }
    get groupID():string {
        return this.valueForKey('groupID');
    }
    set groupIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'groupID');
    }
    get groupIDPrimitiveValue():string {
        return this.primitiveValueForKey('groupID');
    }

    // Property: groupName
    set groupName(value:string) {
        this.setValueForKey(value, 'groupName');
    }
    get groupName():string {
        return this.valueForKey('groupName');
    }
    set groupNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'groupName');
    }
    get groupNamePrimitiveValue():string {
        return this.primitiveValueForKey('groupName');
    }

    // Property: groupPrice
    set groupPrice(value:number) {
        this.setValueForKey(value, 'groupPrice');
    }
    get groupPrice():number {
        return this.valueForKey('groupPrice');
    }
    set groupPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'groupPrice');
    }
    get groupPricePrimitiveValue():number {
        return this.primitiveValueForKey('groupPrice');
    }

    // Property: idIn
    set idIn(value:number) {
        this.setValueForKey(value, 'idIn');
    }
    get idIn():number {
        return this.valueForKey('idIn');
    }
    set idInPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'idIn');
    }
    get idInPrimitiveValue():number {
        return this.primitiveValueForKey('idIn');
    }

    // Property: imageID
    set imageID(value:string) {
        this.setValueForKey(value, 'imageID');
    }
    get imageID():string {
        return this.valueForKey('imageID');
    }
    set imageIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'imageID');
    }
    get imageIDPrimitiveValue():string {
        return this.primitiveValueForKey('imageID');
    }

    // Property: info
    set info(value:string) {
        this.setValueForKey(value, 'info');
    }
    get info():string {
        return this.valueForKey('info');
    }
    set infoPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'info');
    }
    get infoPrimitiveValue():string {
        return this.primitiveValueForKey('info');
    }

    // Property: invitationValue
    set invitationValue(value:number) {
        this.setValueForKey(value, 'invitationValue');
    }
    get invitationValue():number {
        return this.valueForKey('invitationValue');
    }
    set invitationValuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'invitationValue');
    }
    get invitationValuePrimitiveValue():number {
        return this.primitiveValueForKey('invitationValue');
    }

    // Property: isBulk
    set isBulk(value:boolean) {
        this.setValueForKey(value, 'isBulk');
    }
    get isBulk():boolean {
        return this.valueForKey('isBulk');
    }
    set isBulkPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isBulk');
    }
    get isBulkPrimitiveValue():boolean {
        return this.primitiveValueForKey('isBulk');
    }

    // Property: isEnableForSell
    set isEnableForSell(value:boolean) {
        this.setValueForKey(value, 'isEnableForSell');
    }
    get isEnableForSell():boolean {
        return this.valueForKey('isEnableForSell');
    }
    set isEnableForSellPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isEnableForSell');
    }
    get isEnableForSellPrimitiveValue():boolean {
        return this.primitiveValueForKey('isEnableForSell');
    }

    // Property: isEnableForStock
    set isEnableForStock(value:boolean) {
        this.setValueForKey(value, 'isEnableForStock');
    }
    get isEnableForStock():boolean {
        return this.valueForKey('isEnableForStock');
    }
    set isEnableForStockPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isEnableForStock');
    }
    get isEnableForStockPrimitiveValue():boolean {
        return this.primitiveValueForKey('isEnableForStock');
    }

    // Property: isFavourite
    set isFavourite(value:boolean) {
        this.setValueForKey(value, 'isFavourite');
    }
    get isFavourite():boolean {
        return this.valueForKey('isFavourite');
    }
    set isFavouritePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isFavourite');
    }
    get isFavouritePrimitiveValue():boolean {
        return this.primitiveValueForKey('isFavourite');
    }

    // Property: isSeasonProduct
    set isSeasonProduct(value:boolean) {
        this.setValueForKey(value, 'isSeasonProduct');
    }
    get isSeasonProduct():boolean {
        return this.valueForKey('isSeasonProduct');
    }
    set isSeasonProductPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isSeasonProduct');
    }
    get isSeasonProductPrimitiveValue():boolean {
        return this.primitiveValueForKey('isSeasonProduct');
    }

    // Property: isSpentForToday
    set isSpentForToday(value:boolean) {
        this.setValueForKey(value, 'isSpentForToday');
    }
    get isSpentForToday():boolean {
        return this.valueForKey('isSpentForToday');
    }
    set isSpentForTodayPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isSpentForToday');
    }
    get isSpentForTodayPrimitiveValue():boolean {
        return this.primitiveValueForKey('isSpentForToday');
    }

    // Property: isSubproductsEnabled
    set isSubproductsEnabled(value:boolean) {
        this.setValueForKey(value, 'isSubproductsEnabled');
    }
    get isSubproductsEnabled():boolean {
        return this.valueForKey('isSubproductsEnabled');
    }
    set isSubproductsEnabledPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isSubproductsEnabled');
    }
    get isSubproductsEnabledPrimitiveValue():boolean {
        return this.primitiveValueForKey('isSubproductsEnabled');
    }

    // Property: isTaxVariable
    set isTaxVariable(value:boolean) {
        this.setValueForKey(value, 'isTaxVariable');
    }
    get isTaxVariable():boolean {
        return this.valueForKey('isTaxVariable');
    }
    set isTaxVariablePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isTaxVariable');
    }
    get isTaxVariablePrimitiveValue():boolean {
        return this.primitiveValueForKey('isTaxVariable');
    }

    // Property: isTrackingProduct
    set isTrackingProduct(value:boolean) {
        this.setValueForKey(value, 'isTrackingProduct');
    }
    get isTrackingProduct():boolean {
        return this.valueForKey('isTrackingProduct');
    }
    set isTrackingProductPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isTrackingProduct');
    }
    get isTrackingProductPrimitiveValue():boolean {
        return this.primitiveValueForKey('isTrackingProduct');
    }

    // Property: kitchenName
    set kitchenName(value:string) {
        this.setValueForKey(value, 'kitchenName');
    }
    get kitchenName():string {
        return this.valueForKey('kitchenName');
    }
    set kitchenNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'kitchenName');
    }
    get kitchenNamePrimitiveValue():string {
        return this.primitiveValueForKey('kitchenName');
    }

    // Property: lossInStorage
    set lossInStorage(value:number) {
        this.setValueForKey(value, 'lossInStorage');
    }
    get lossInStorage():number {
        return this.valueForKey('lossInStorage');
    }
    set lossInStoragePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lossInStorage');
    }
    get lossInStoragePrimitiveValue():number {
        return this.primitiveValueForKey('lossInStorage');
    }

    // Property: max
    set max(value:number) {
        this.setValueForKey(value, 'max');
    }
    get max():number {
        return this.valueForKey('max');
    }
    set maxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'max');
    }
    get maxPrimitiveValue():number {
        return this.primitiveValueForKey('max');
    }

    // Property: measureUnitType
    set measureUnitType(value:number) {
        this.setValueForKey(value, 'measureUnitType');
    }
    get measureUnitType():number {
        return this.valueForKey('measureUnitType');
    }
    set measureUnitTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'measureUnitType');
    }
    get measureUnitTypePrimitiveValue():number {
        return this.primitiveValueForKey('measureUnitType');
    }

    // Property: min
    set min(value:number) {
        this.setValueForKey(value, 'min');
    }
    get min():number {
        return this.valueForKey('min');
    }
    set minPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'min');
    }
    get minPrimitiveValue():number {
        return this.primitiveValueForKey('min');
    }

    // Property: modifiersRequired
    set modifiersRequired(value:boolean) {
        this.setValueForKey(value, 'modifiersRequired');
    }
    get modifiersRequired():boolean {
        return this.valueForKey('modifiersRequired');
    }
    set modifiersRequiredPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'modifiersRequired');
    }
    get modifiersRequiredPrimitiveValue():boolean {
        return this.primitiveValueForKey('modifiersRequired');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderedQuantity
    set orderedQuantity(value:number) {
        this.setValueForKey(value, 'orderedQuantity');
    }
    get orderedQuantity():number {
        return this.valueForKey('orderedQuantity');
    }
    set orderedQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderedQuantity');
    }
    get orderedQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('orderedQuantity');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: originalPrice
    set originalPrice(value:number) {
        this.setValueForKey(value, 'originalPrice');
    }
    get originalPrice():number {
        return this.valueForKey('originalPrice');
    }
    set originalPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'originalPrice');
    }
    get originalPricePrimitiveValue():number {
        return this.primitiveValueForKey('originalPrice');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: publicReference
    set publicReference(value:string) {
        this.setValueForKey(value, 'publicReference');
    }
    get publicReference():string {
        return this.valueForKey('publicReference');
    }
    set publicReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'publicReference');
    }
    get publicReferencePrimitiveValue():string {
        return this.primitiveValueForKey('publicReference');
    }

    // Property: purchaseLedgerAccountName
    set purchaseLedgerAccountName(value:string) {
        this.setValueForKey(value, 'purchaseLedgerAccountName');
    }
    get purchaseLedgerAccountName():string {
        return this.valueForKey('purchaseLedgerAccountName');
    }
    set purchaseLedgerAccountNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'purchaseLedgerAccountName');
    }
    get purchaseLedgerAccountNamePrimitiveValue():string {
        return this.primitiveValueForKey('purchaseLedgerAccountName');
    }

    // Property: quantity
    set quantity(value:number) {
        this.setValueForKey(value, 'quantity');
    }
    get quantity():number {
        return this.valueForKey('quantity');
    }
    set quantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'quantity');
    }
    get quantityPrimitiveValue():number {
        return this.primitiveValueForKey('quantity');
    }

    // Property: reference
    set reference(value:string) {
        this.setValueForKey(value, 'reference');
    }
    get reference():string {
        return this.valueForKey('reference');
    }
    set referencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'reference');
    }
    get referencePrimitiveValue():string {
        return this.primitiveValueForKey('reference');
    }

    // Property: removeExistencesWhenFinishSession
    set removeExistencesWhenFinishSession(value:boolean) {
        this.setValueForKey(value, 'removeExistencesWhenFinishSession');
    }
    get removeExistencesWhenFinishSession():boolean {
        return this.valueForKey('removeExistencesWhenFinishSession');
    }
    set removeExistencesWhenFinishSessionPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'removeExistencesWhenFinishSession');
    }
    get removeExistencesWhenFinishSessionPrimitiveValue():boolean {
        return this.primitiveValueForKey('removeExistencesWhenFinishSession');
    }

    // Property: salesLedgerAccountName
    set salesLedgerAccountName(value:string) {
        this.setValueForKey(value, 'salesLedgerAccountName');
    }
    get salesLedgerAccountName():string {
        return this.valueForKey('salesLedgerAccountName');
    }
    set salesLedgerAccountNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'salesLedgerAccountName');
    }
    get salesLedgerAccountNamePrimitiveValue():string {
        return this.primitiveValueForKey('salesLedgerAccountName');
    }

    // Property: salesLimitForToday
    set salesLimitForToday(value:number) {
        this.setValueForKey(value, 'salesLimitForToday');
    }
    get salesLimitForToday():number {
        return this.valueForKey('salesLimitForToday');
    }
    set salesLimitForTodayPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'salesLimitForToday');
    }
    get salesLimitForTodayPrimitiveValue():number {
        return this.primitiveValueForKey('salesLimitForToday');
    }

    // Property: serialNumberRequiredOption
    set serialNumberRequiredOption(value:number) {
        this.setValueForKey(value, 'serialNumberRequiredOption');
    }
    get serialNumberRequiredOption():number {
        return this.valueForKey('serialNumberRequiredOption');
    }
    set serialNumberRequiredOptionPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'serialNumberRequiredOption');
    }
    get serialNumberRequiredOptionPrimitiveValue():number {
        return this.primitiveValueForKey('serialNumberRequiredOption');
    }

    // Property: stockBlockType
    set stockBlockType(value:number) {
        this.setValueForKey(value, 'stockBlockType');
    }
    get stockBlockType():number {
        return this.valueForKey('stockBlockType');
    }
    set stockBlockTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'stockBlockType');
    }
    get stockBlockTypePrimitiveValue():number {
        return this.primitiveValueForKey('stockBlockType');
    }

    // Property: stockContainerName
    set stockContainerName(value:string) {
        this.setValueForKey(value, 'stockContainerName');
    }
    get stockContainerName():string {
        return this.valueForKey('stockContainerName');
    }
    set stockContainerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'stockContainerName');
    }
    get stockContainerNamePrimitiveValue():string {
        return this.primitiveValueForKey('stockContainerName');
    }

    // Property: stockElaborationType
    set stockElaborationType(value:number) {
        this.setValueForKey(value, 'stockElaborationType');
    }
    get stockElaborationType():number {
        return this.valueForKey('stockElaborationType');
    }
    set stockElaborationTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'stockElaborationType');
    }
    get stockElaborationTypePrimitiveValue():number {
        return this.primitiveValueForKey('stockElaborationType');
    }

    // Property: stockInputUnitType
    set stockInputUnitType(value:number) {
        this.setValueForKey(value, 'stockInputUnitType');
    }
    get stockInputUnitType():number {
        return this.valueForKey('stockInputUnitType');
    }
    set stockInputUnitTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'stockInputUnitType');
    }
    get stockInputUnitTypePrimitiveValue():number {
        return this.primitiveValueForKey('stockInputUnitType');
    }

    // Property: stockName
    set stockName(value:string) {
        this.setValueForKey(value, 'stockName');
    }
    get stockName():string {
        return this.valueForKey('stockName');
    }
    set stockNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'stockName');
    }
    get stockNamePrimitiveValue():string {
        return this.primitiveValueForKey('stockName');
    }

    // Property: stockSetIDRequiredOption
    set stockSetIDRequiredOption(value:number) {
        this.setValueForKey(value, 'stockSetIDRequiredOption');
    }
    get stockSetIDRequiredOption():number {
        return this.valueForKey('stockSetIDRequiredOption');
    }
    set stockSetIDRequiredOptionPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'stockSetIDRequiredOption');
    }
    get stockSetIDRequiredOptionPrimitiveValue():number {
        return this.primitiveValueForKey('stockSetIDRequiredOption');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: ticketName
    set ticketName(value:string) {
        this.setValueForKey(value, 'ticketName');
    }
    get ticketName():string {
        return this.valueForKey('ticketName');
    }
    set ticketNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ticketName');
    }
    get ticketNamePrimitiveValue():string {
        return this.primitiveValueForKey('ticketName');
    }

    // Property: traceabilityIDRequiredOption
    set traceabilityIDRequiredOption(value:number) {
        this.setValueForKey(value, 'traceabilityIDRequiredOption');
    }
    get traceabilityIDRequiredOption():number {
        return this.valueForKey('traceabilityIDRequiredOption');
    }
    set traceabilityIDRequiredOptionPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'traceabilityIDRequiredOption');
    }
    get traceabilityIDRequiredOptionPrimitiveValue():number {
        return this.primitiveValueForKey('traceabilityIDRequiredOption');
    }

    // Property: usesUnitaryExistences
    set usesUnitaryExistences(value:boolean) {
        this.setValueForKey(value, 'usesUnitaryExistences');
    }
    get usesUnitaryExistences():boolean {
        return this.valueForKey('usesUnitaryExistences');
    }
    set usesUnitaryExistencesPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'usesUnitaryExistences');
    }
    get usesUnitaryExistencesPrimitiveValue():boolean {
        return this.primitiveValueForKey('usesUnitaryExistences');
    }

    // Property: waReference
    set waReference(value:string) {
        this.setValueForKey(value, 'waReference');
    }
    get waReference():string {
        return this.valueForKey('waReference');
    }
    set waReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'waReference');
    }
    get waReferencePrimitiveValue():string {
        return this.primitiveValueForKey('waReference');
    }

    // Property: weightEmpty
    set weightEmpty(value:number) {
        this.setValueForKey(value, 'weightEmpty');
    }
    get weightEmpty():number {
        return this.valueForKey('weightEmpty');
    }
    set weightEmptyPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'weightEmpty');
    }
    get weightEmptyPrimitiveValue():number {
        return this.primitiveValueForKey('weightEmpty');
    }

    // Property: weightFull
    set weightFull(value:number) {
        this.setValueForKey(value, 'weightFull');
    }
    get weightFull():number {
        return this.valueForKey('weightFull');
    }
    set weightFullPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'weightFull');
    }
    get weightFullPrimitiveValue():number {
        return this.primitiveValueForKey('weightFull');
    }

    // Property: wineCellarDict
    set wineCellarDict(value:any) {
        this.setValueForKey(value, 'wineCellarDict');
    }
    get wineCellarDict():any {
        return this.valueForKey('wineCellarDict');
    }
    set wineCellarDictPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'wineCellarDict');
    }
    get wineCellarDictPrimitiveValue():any {
        return this.primitiveValueForKey('wineCellarDict');
    }

    // Property: wineCellarHash
    set wineCellarHash(value:string) {
        this.setValueForKey(value, 'wineCellarHash');
    }
    get wineCellarHash():string {
        return this.valueForKey('wineCellarHash');
    }
    set wineCellarHashPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'wineCellarHash');
    }
    get wineCellarHashPrimitiveValue():string {
        return this.primitiveValueForKey('wineCellarHash');
    }

    // Relationship: automaticInlocationCategories
    protected _automaticInlocationCategories:MIOManagedObjectSet = null;
    get automaticInlocationCategories():MIOManagedObjectSet {
        return this.valueForKey('automaticInlocationCategories');
    }
    addAutomaticInlocationCategoriesObject(value:LocationCategoryAutoProduct) {
        this._addObjectForKey(value, 'automaticInlocationCategories');
    }
    removeAutomaticInlocationCategoriesObject(value:LocationCategoryAutoProduct) {
        this._removeObjectForKey(value, 'automaticInlocationCategories');
    }

    // Relationship: base
    protected _base:MIOManagedObjectSet = null;
    get base():MIOManagedObjectSet {
        return this.valueForKey('base');
    }
    addBaseObject(value:Component) {
        this._addObjectForKey(value, 'base');
    }
    removeBaseObject(value:Component) {
        this._removeObjectForKey(value, 'base');
    }
    // Relationship: category
    set category(value:ProductCategory) {
        this.setValueForKey(value, 'category');
    }
    get category():ProductCategory {
        return this.valueForKey('category') as ProductCategory;
    }

    // Relationship: components
    protected _components:MIOManagedObjectSet = null;
    get components():MIOManagedObjectSet {
        return this.valueForKey('components');
    }
    addComponentsObject(value:Component) {
        this._addObjectForKey(value, 'components');
    }
    removeComponentsObject(value:Component) {
        this._removeObjectForKey(value, 'components');
    }
    // Relationship: defaultInputFormat
    set defaultInputFormat(value:StockInputFormat) {
        this.setValueForKey(value, 'defaultInputFormat');
    }
    get defaultInputFormat():StockInputFormat {
        return this.valueForKey('defaultInputFormat') as StockInputFormat;
    }
    // Relationship: defaultProductFormat
    set defaultProductFormat(value:ProductFormat) {
        this.setValueForKey(value, 'defaultProductFormat');
    }
    get defaultProductFormat():ProductFormat {
        return this.valueForKey('defaultProductFormat') as ProductFormat;
    }
    // Relationship: defaultSupplier
    set defaultSupplier(value:Supplier) {
        this.setValueForKey(value, 'defaultSupplier');
    }
    get defaultSupplier():Supplier {
        return this.valueForKey('defaultSupplier') as Supplier;
    }
    // Relationship: defaultWarehouse
    set defaultWarehouse(value:Warehouse) {
        this.setValueForKey(value, 'defaultWarehouse');
    }
    get defaultWarehouse():Warehouse {
        return this.valueForKey('defaultWarehouse') as Warehouse;
    }
    // Relationship: ellaborationCenter
    set ellaborationCenter(value:EllaborationCenter) {
        this.setValueForKey(value, 'ellaborationCenter');
    }
    get ellaborationCenter():EllaborationCenter {
        return this.valueForKey('ellaborationCenter') as EllaborationCenter;
    }

    // Relationship: freeProducts
    protected _freeProducts:MIOManagedObjectSet = null;
    get freeProducts():MIOManagedObjectSet {
        return this.valueForKey('freeProducts');
    }
    addFreeProductsObject(value:FreeProduct) {
        this._addObjectForKey(value, 'freeProducts');
    }
    removeFreeProductsObject(value:FreeProduct) {
        this._removeObjectForKey(value, 'freeProducts');
    }
    // Relationship: image
    set image(value:DBImage) {
        this.setValueForKey(value, 'image');
    }
    get image():DBImage {
        return this.valueForKey('image') as DBImage;
    }

    // Relationship: lines
    protected _lines:MIOManagedObjectSet = null;
    get lines():MIOManagedObjectSet {
        return this.valueForKey('lines');
    }
    addLinesObject(value:ProductLine) {
        this._addObjectForKey(value, 'lines');
    }
    removeLinesObject(value:ProductLine) {
        this._removeObjectForKey(value, 'lines');
    }
    // Relationship: linkedMenu
    set linkedMenu(value:Menu) {
        this.setValueForKey(value, 'linkedMenu');
    }
    get linkedMenu():Menu {
        return this.valueForKey('linkedMenu') as Menu;
    }

    // Relationship: presetProducts
    protected _presetProducts:MIOManagedObjectSet = null;
    get presetProducts():MIOManagedObjectSet {
        return this.valueForKey('presetProducts');
    }
    addPresetProductsObject(value:PresetProduct) {
        this._addObjectForKey(value, 'presetProducts');
    }
    removePresetProductsObject(value:PresetProduct) {
        this._removeObjectForKey(value, 'presetProducts');
    }

    // Relationship: productAttributes
    protected _productAttributes:MIOManagedObjectSet = null;
    get productAttributes():MIOManagedObjectSet {
        return this.valueForKey('productAttributes');
    }
    addProductAttributesObject(value:ProductAttribute) {
        this._addObjectForKey(value, 'productAttributes');
    }
    removeProductAttributesObject(value:ProductAttribute) {
        this._removeObjectForKey(value, 'productAttributes');
    }

    // Relationship: productAttributeTypes
    protected _productAttributeTypes:MIOManagedObjectSet = null;
    get productAttributeTypes():MIOManagedObjectSet {
        return this.valueForKey('productAttributeTypes');
    }
    addProductAttributeTypesObject(value:ProductAttributeType) {
        this._addObjectForKey(value, 'productAttributeTypes');
    }
    removeProductAttributeTypesObject(value:ProductAttributeType) {
        this._removeObjectForKey(value, 'productAttributeTypes');
    }

    // Relationship: productFormats
    protected _productFormats:MIOManagedObjectSet = null;
    get productFormats():MIOManagedObjectSet {
        return this.valueForKey('productFormats');
    }
    addProductFormatsObject(value:ProductFormat) {
        this._addObjectForKey(value, 'productFormats');
    }
    removeProductFormatsObject(value:ProductFormat) {
        this._removeObjectForKey(value, 'productFormats');
    }

    // Relationship: productImages
    protected _productImages:MIOManagedObjectSet = null;
    get productImages():MIOManagedObjectSet {
        return this.valueForKey('productImages');
    }
    addProductImagesObject(value:ProductImage) {
        this._addObjectForKey(value, 'productImages');
    }
    removeProductImagesObject(value:ProductImage) {
        this._removeObjectForKey(value, 'productImages');
    }

    // Relationship: productModifiers
    protected _productModifiers:MIOManagedObjectSet = null;
    get productModifiers():MIOManagedObjectSet {
        return this.valueForKey('productModifiers');
    }
    addProductModifiersObject(value:ProductModifier) {
        this._addObjectForKey(value, 'productModifiers');
    }
    removeProductModifiersObject(value:ProductModifier) {
        this._removeObjectForKey(value, 'productModifiers');
    }

    // Relationship: productModifiersCategories
    protected _productModifiersCategories:MIOManagedObjectSet = null;
    get productModifiersCategories():MIOManagedObjectSet {
        return this.valueForKey('productModifiersCategories');
    }
    addProductModifiersCategoriesObject(value:ProductModifierCategory) {
        this._addObjectForKey(value, 'productModifiersCategories');
    }
    removeProductModifiersCategoriesObject(value:ProductModifierCategory) {
        this._removeObjectForKey(value, 'productModifiersCategories');
    }

    // Relationship: productPlace
    protected _productPlace:MIOManagedObjectSet = null;
    get productPlace():MIOManagedObjectSet {
        return this.valueForKey('productPlace');
    }
    addProductPlaceObject(value:ProductPlace) {
        this._addObjectForKey(value, 'productPlace');
    }
    removeProductPlaceObject(value:ProductPlace) {
        this._removeObjectForKey(value, 'productPlace');
    }

    // Relationship: productRates
    protected _productRates:MIOManagedObjectSet = null;
    get productRates():MIOManagedObjectSet {
        return this.valueForKey('productRates');
    }
    addProductRatesObject(value:ProductRate) {
        this._addObjectForKey(value, 'productRates');
    }
    removeProductRatesObject(value:ProductRate) {
        this._removeObjectForKey(value, 'productRates');
    }

    // Relationship: productsMenu
    protected _productsMenu:MIOManagedObjectSet = null;
    get productsMenu():MIOManagedObjectSet {
        return this.valueForKey('productsMenu');
    }
    addProductsMenuObject(value:ProductMenu) {
        this._addObjectForKey(value, 'productsMenu');
    }
    removeProductsMenuObject(value:ProductMenu) {
        this._removeObjectForKey(value, 'productsMenu');
    }

    // Relationship: productWarehouses
    protected _productWarehouses:MIOManagedObjectSet = null;
    get productWarehouses():MIOManagedObjectSet {
        return this.valueForKey('productWarehouses');
    }
    addProductWarehousesObject(value:ProductWarehouse) {
        this._addObjectForKey(value, 'productWarehouses');
    }
    removeProductWarehousesObject(value:ProductWarehouse) {
        this._removeObjectForKey(value, 'productWarehouses');
    }
    // Relationship: purchaseLedgerAccount
    set purchaseLedgerAccount(value:LedgerAccount) {
        this.setValueForKey(value, 'purchaseLedgerAccount');
    }
    get purchaseLedgerAccount():LedgerAccount {
        return this.valueForKey('purchaseLedgerAccount') as LedgerAccount;
    }

    // Relationship: recommendedProducts
    protected _recommendedProducts:MIOManagedObjectSet = null;
    get recommendedProducts():MIOManagedObjectSet {
        return this.valueForKey('recommendedProducts');
    }
    addRecommendedProductsObject(value:Product) {
        this._addObjectForKey(value, 'recommendedProducts');
    }
    removeRecommendedProductsObject(value:Product) {
        this._removeObjectForKey(value, 'recommendedProducts');
    }
    // Relationship: salesLedgerAccount
    set salesLedgerAccount(value:LedgerAccount) {
        this.setValueForKey(value, 'salesLedgerAccount');
    }
    get salesLedgerAccount():LedgerAccount {
        return this.valueForKey('salesLedgerAccount') as LedgerAccount;
    }
    // Relationship: source
    set source(value:ProductSource) {
        this.setValueForKey(value, 'source');
    }
    get source():ProductSource {
        return this.valueForKey('source') as ProductSource;
    }
    // Relationship: stockCategory
    set stockCategory(value:StockCategory) {
        this.setValueForKey(value, 'stockCategory');
    }
    get stockCategory():StockCategory {
        return this.valueForKey('stockCategory') as StockCategory;
    }

    // Relationship: stockInputFormats
    protected _stockInputFormats:MIOManagedObjectSet = null;
    get stockInputFormats():MIOManagedObjectSet {
        return this.valueForKey('stockInputFormats');
    }
    addStockInputFormatsObject(value:StockInputFormat) {
        this._addObjectForKey(value, 'stockInputFormats');
    }
    removeStockInputFormatsObject(value:StockInputFormat) {
        this._removeObjectForKey(value, 'stockInputFormats');
    }

    // Relationship: subProducts
    protected _subProducts:MIOManagedObjectSet = null;
    get subProducts():MIOManagedObjectSet {
        return this.valueForKey('subProducts');
    }
    addSubProductsObject(value:Product) {
        this._addObjectForKey(value, 'subProducts');
    }
    removeSubProductsObject(value:Product) {
        this._removeObjectForKey(value, 'subProducts');
    }
    // Relationship: superProduct
    set superProduct(value:Product) {
        this.setValueForKey(value, 'superProduct');
    }
    get superProduct():Product {
        return this.valueForKey('superProduct') as Product;
    }

    // Relationship: supplierProducts
    protected _supplierProducts:MIOManagedObjectSet = null;
    get supplierProducts():MIOManagedObjectSet {
        return this.valueForKey('supplierProducts');
    }
    addSupplierProductsObject(value:SupplierProduct) {
        this._addObjectForKey(value, 'supplierProducts');
    }
    removeSupplierProductsObject(value:SupplierProduct) {
        this._removeObjectForKey(value, 'supplierProducts');
    }
    // Relationship: tax
    set tax(value:Tax) {
        this.setValueForKey(value, 'tax');
    }
    get tax():Tax {
        return this.valueForKey('tax') as Tax;
    }
    // Relationship: taxBuy
    set taxBuy(value:Tax) {
        this.setValueForKey(value, 'taxBuy');
    }
    get taxBuy():Tax {
        return this.valueForKey('taxBuy') as Tax;
    }
}
