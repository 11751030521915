

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class CashDeskLine_ManagedObject

class CashDeskLine_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: bookingID
    set bookingID(value:string) {
        this.setValueForKey(value, 'bookingID');
    }
    get bookingID():string {
        return this.valueForKey('bookingID');
    }
    set bookingIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'bookingID');
    }
    get bookingIDPrimitiveValue():string {
        return this.primitiveValueForKey('bookingID');
    }

    // Property: byUser
    set byUser(value:boolean) {
        this.setValueForKey(value, 'byUser');
    }
    get byUser():boolean {
        return this.valueForKey('byUser');
    }
    set byUserPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'byUser');
    }
    get byUserPrimitiveValue():boolean {
        return this.primitiveValueForKey('byUser');
    }

    // Property: cashdeskOperationID
    set cashdeskOperationID(value:string) {
        this.setValueForKey(value, 'cashdeskOperationID');
    }
    get cashdeskOperationID():string {
        return this.valueForKey('cashdeskOperationID');
    }
    set cashdeskOperationIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'cashdeskOperationID');
    }
    get cashdeskOperationIDPrimitiveValue():string {
        return this.primitiveValueForKey('cashdeskOperationID');
    }

    // Property: comments
    set comments(value:string) {
        this.setValueForKey(value, 'comments');
    }
    get comments():string {
        return this.valueForKey('comments');
    }
    set commentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'comments');
    }
    get commentsPrimitiveValue():string {
        return this.primitiveValueForKey('comments');
    }

    // Property: concept
    set concept(value:string) {
        this.setValueForKey(value, 'concept');
    }
    get concept():string {
        return this.valueForKey('concept');
    }
    set conceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'concept');
    }
    get conceptPrimitiveValue():string {
        return this.primitiveValueForKey('concept');
    }

    // Property: currencyCode
    set currencyCode(value:string) {
        this.setValueForKey(value, 'currencyCode');
    }
    get currencyCode():string {
        return this.valueForKey('currencyCode');
    }
    set currencyCodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'currencyCode');
    }
    get currencyCodePrimitiveValue():string {
        return this.primitiveValueForKey('currencyCode');
    }

    // Property: currencyFactor
    set currencyFactor(value:number) {
        this.setValueForKey(value, 'currencyFactor');
    }
    get currencyFactor():number {
        return this.valueForKey('currencyFactor');
    }
    set currencyFactorPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'currencyFactor');
    }
    get currencyFactorPrimitiveValue():number {
        return this.primitiveValueForKey('currencyFactor');
    }

    // Property: currencyName
    set currencyName(value:string) {
        this.setValueForKey(value, 'currencyName');
    }
    get currencyName():string {
        return this.valueForKey('currencyName');
    }
    set currencyNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'currencyName');
    }
    get currencyNamePrimitiveValue():string {
        return this.primitiveValueForKey('currencyName');
    }

    // Property: customConceptID
    set customConceptID(value:string) {
        this.setValueForKey(value, 'customConceptID');
    }
    get customConceptID():string {
        return this.valueForKey('customConceptID');
    }
    set customConceptIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'customConceptID');
    }
    get customConceptIDPrimitiveValue():string {
        return this.primitiveValueForKey('customConceptID');
    }

    // Property: customDescription
    set customDescription(value:string) {
        this.setValueForKey(value, 'customDescription');
    }
    get customDescription():string {
        return this.valueForKey('customDescription');
    }
    set customDescriptionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'customDescription');
    }
    get customDescriptionPrimitiveValue():string {
        return this.primitiveValueForKey('customDescription');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: debtLineID
    set debtLineID(value:string) {
        this.setValueForKey(value, 'debtLineID');
    }
    get debtLineID():string {
        return this.valueForKey('debtLineID');
    }
    set debtLineIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'debtLineID');
    }
    get debtLineIDPrimitiveValue():string {
        return this.primitiveValueForKey('debtLineID');
    }

    // Property: documentID
    set documentID(value:string) {
        this.setValueForKey(value, 'documentID');
    }
    get documentID():string {
        return this.valueForKey('documentID');
    }
    set documentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentID');
    }
    get documentIDPrimitiveValue():string {
        return this.primitiveValueForKey('documentID');
    }

    // Property: eventID
    set eventID(value:string) {
        this.setValueForKey(value, 'eventID');
    }
    get eventID():string {
        return this.valueForKey('eventID');
    }
    set eventIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'eventID');
    }
    get eventIDPrimitiveValue():string {
        return this.primitiveValueForKey('eventID');
    }

    // Property: eventName
    set eventName(value:string) {
        this.setValueForKey(value, 'eventName');
    }
    get eventName():string {
        return this.valueForKey('eventName');
    }
    set eventNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'eventName');
    }
    get eventNamePrimitiveValue():string {
        return this.primitiveValueForKey('eventName');
    }

    // Property: externalTransactionRefence
    set externalTransactionRefence(value:string) {
        this.setValueForKey(value, 'externalTransactionRefence');
    }
    get externalTransactionRefence():string {
        return this.valueForKey('externalTransactionRefence');
    }
    set externalTransactionRefencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'externalTransactionRefence');
    }
    get externalTransactionRefencePrimitiveValue():string {
        return this.primitiveValueForKey('externalTransactionRefence');
    }

    // Property: info
    set info(value:string) {
        this.setValueForKey(value, 'info');
    }
    get info():string {
        return this.valueForKey('info');
    }
    set infoPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'info');
    }
    get infoPrimitiveValue():string {
        return this.primitiveValueForKey('info');
    }

    // Property: legalDocumentID
    set legalDocumentID(value:string) {
        this.setValueForKey(value, 'legalDocumentID');
    }
    get legalDocumentID():string {
        return this.valueForKey('legalDocumentID');
    }
    set legalDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalDocumentID');
    }
    get legalDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('legalDocumentID');
    }

    // Property: locationCategoryID
    set locationCategoryID(value:string) {
        this.setValueForKey(value, 'locationCategoryID');
    }
    get locationCategoryID():string {
        return this.valueForKey('locationCategoryID');
    }
    set locationCategoryIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationCategoryID');
    }
    get locationCategoryIDPrimitiveValue():string {
        return this.primitiveValueForKey('locationCategoryID');
    }

    // Property: locationCategoryName
    set locationCategoryName(value:string) {
        this.setValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryName():string {
        return this.valueForKey('locationCategoryName');
    }
    set locationCategoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationCategoryName');
    }

    // Property: locationID
    set locationID(value:string) {
        this.setValueForKey(value, 'locationID');
    }
    get locationID():string {
        return this.valueForKey('locationID');
    }
    set locationIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationID');
    }
    get locationIDPrimitiveValue():string {
        return this.primitiveValueForKey('locationID');
    }

    // Property: locationName
    set locationName(value:string) {
        this.setValueForKey(value, 'locationName');
    }
    get locationName():string {
        return this.valueForKey('locationName');
    }
    set locationNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationName');
    }
    get locationNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationName');
    }

    // Property: money
    set money(value:number) {
        this.setValueForKey(value, 'money');
    }
    get money():number {
        return this.valueForKey('money');
    }
    set moneyPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'money');
    }
    get moneyPrimitiveValue():number {
        return this.primitiveValueForKey('money');
    }

    // Property: moneyOtherCurrency
    set moneyOtherCurrency(value:number) {
        this.setValueForKey(value, 'moneyOtherCurrency');
    }
    get moneyOtherCurrency():number {
        return this.valueForKey('moneyOtherCurrency');
    }
    set moneyOtherCurrencyPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'moneyOtherCurrency');
    }
    get moneyOtherCurrencyPrimitiveValue():number {
        return this.primitiveValueForKey('moneyOtherCurrency');
    }

    // Property: paymentType
    set paymentType(value:number) {
        this.setValueForKey(value, 'paymentType');
    }
    get paymentType():number {
        return this.valueForKey('paymentType');
    }
    set paymentTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'paymentType');
    }
    get paymentTypePrimitiveValue():number {
        return this.primitiveValueForKey('paymentType');
    }

    // Property: payMethodName
    set payMethodName(value:string) {
        this.setValueForKey(value, 'payMethodName');
    }
    get payMethodName():string {
        return this.valueForKey('payMethodName');
    }
    set payMethodNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'payMethodName');
    }
    get payMethodNamePrimitiveValue():string {
        return this.primitiveValueForKey('payMethodName');
    }

    // Property: payMethodSubtype
    set payMethodSubtype(value:string) {
        this.setValueForKey(value, 'payMethodSubtype');
    }
    get payMethodSubtype():string {
        return this.valueForKey('payMethodSubtype');
    }
    set payMethodSubtypePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'payMethodSubtype');
    }
    get payMethodSubtypePrimitiveValue():string {
        return this.primitiveValueForKey('payMethodSubtype');
    }

    // Property: responsibleCardCode
    set responsibleCardCode(value:string) {
        this.setValueForKey(value, 'responsibleCardCode');
    }
    get responsibleCardCode():string {
        return this.valueForKey('responsibleCardCode');
    }
    set responsibleCardCodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'responsibleCardCode');
    }
    get responsibleCardCodePrimitiveValue():string {
        return this.primitiveValueForKey('responsibleCardCode');
    }

    // Property: responsibleID
    set responsibleID(value:string) {
        this.setValueForKey(value, 'responsibleID');
    }
    get responsibleID():string {
        return this.valueForKey('responsibleID');
    }
    set responsibleIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'responsibleID');
    }
    get responsibleIDPrimitiveValue():string {
        return this.primitiveValueForKey('responsibleID');
    }

    // Property: responsibleName
    set responsibleName(value:string) {
        this.setValueForKey(value, 'responsibleName');
    }
    get responsibleName():string {
        return this.valueForKey('responsibleName');
    }
    set responsibleNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'responsibleName');
    }
    get responsibleNamePrimitiveValue():string {
        return this.primitiveValueForKey('responsibleName');
    }

    // Property: responsibleType
    set responsibleType(value:number) {
        this.setValueForKey(value, 'responsibleType');
    }
    get responsibleType():number {
        return this.valueForKey('responsibleType');
    }
    set responsibleTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'responsibleType');
    }
    get responsibleTypePrimitiveValue():number {
        return this.primitiveValueForKey('responsibleType');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: tax
    set tax(value:number) {
        this.setValueForKey(value, 'tax');
    }
    get tax():number {
        return this.valueForKey('tax');
    }
    set taxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'tax');
    }
    get taxPrimitiveValue():number {
        return this.primitiveValueForKey('tax');
    }

    // Property: taxID
    set taxID(value:string) {
        this.setValueForKey(value, 'taxID');
    }
    get taxID():string {
        return this.valueForKey('taxID');
    }
    set taxIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'taxID');
    }
    get taxIDPrimitiveValue():string {
        return this.primitiveValueForKey('taxID');
    }

    // Property: taxName
    set taxName(value:string) {
        this.setValueForKey(value, 'taxName');
    }
    get taxName():string {
        return this.valueForKey('taxName');
    }
    set taxNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'taxName');
    }
    get taxNamePrimitiveValue():string {
        return this.primitiveValueForKey('taxName');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: workerID
    set workerID(value:string) {
        this.setValueForKey(value, 'workerID');
    }
    get workerID():string {
        return this.valueForKey('workerID');
    }
    set workerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerID');
    }
    get workerIDPrimitiveValue():string {
        return this.primitiveValueForKey('workerID');
    }

    // Property: workerName
    set workerName(value:string) {
        this.setValueForKey(value, 'workerName');
    }
    get workerName():string {
        return this.valueForKey('workerName');
    }
    set workerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerName');
    }
    get workerNamePrimitiveValue():string {
        return this.primitiveValueForKey('workerName');
    }
    // Relationship: archivedDocument
    set archivedDocument(value:ArchivedDocument) {
        this.setValueForKey(value, 'archivedDocument');
    }
    get archivedDocument():ArchivedDocument {
        return this.valueForKey('archivedDocument') as ArchivedDocument;
    }
    // Relationship: cashDesk
    set cashDesk(value:CashDesk) {
        this.setValueForKey(value, 'cashDesk');
    }
    get cashDesk():CashDesk {
        return this.valueForKey('cashDesk') as CashDesk;
    }
    // Relationship: checkPoint
    set checkPoint(value:CheckPoint) {
        this.setValueForKey(value, 'checkPoint');
    }
    get checkPoint():CheckPoint {
        return this.valueForKey('checkPoint') as CheckPoint;
    }
    // Relationship: currency
    set currency(value:Currency) {
        this.setValueForKey(value, 'currency');
    }
    get currency():Currency {
        return this.valueForKey('currency') as Currency;
    }
    // Relationship: payMethod
    set payMethod(value:PayMethod) {
        this.setValueForKey(value, 'payMethod');
    }
    get payMethod():PayMethod {
        return this.valueForKey('payMethod') as PayMethod;
    }
    // Relationship: session
    set session(value:CashDeskSession) {
        this.setValueForKey(value, 'session');
    }
    get session():CashDeskSession {
        return this.valueForKey('session') as CashDeskSession;
    }
    // Relationship: subCashDesk
    set subCashDesk(value:SubCashDesk) {
        this.setValueForKey(value, 'subCashDesk');
    }
    get subCashDesk():SubCashDesk {
        return this.valueForKey('subCashDesk') as SubCashDesk;
    }
    // Relationship: transaction
    set transaction(value:PaymentTransaction) {
        this.setValueForKey(value, 'transaction');
    }
    get transaction():PaymentTransaction {
        return this.valueForKey('transaction') as PaymentTransaction;
    }
}
