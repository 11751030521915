

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class LicenseToken_ManagedObject

class LicenseToken_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: distributorFeeAmount
    set distributorFeeAmount(value:number) {
        this.setValueForKey(value, 'distributorFeeAmount');
    }
    get distributorFeeAmount():number {
        return this.valueForKey('distributorFeeAmount');
    }
    set distributorFeeAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'distributorFeeAmount');
    }
    get distributorFeeAmountPrimitiveValue():number {
        return this.primitiveValueForKey('distributorFeeAmount');
    }

    // Property: distributorFeeFormat
    set distributorFeeFormat(value:string) {
        this.setValueForKey(value, 'distributorFeeFormat');
    }
    get distributorFeeFormat():string {
        return this.valueForKey('distributorFeeFormat');
    }
    set distributorFeeFormatPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'distributorFeeFormat');
    }
    get distributorFeeFormatPrimitiveValue():string {
        return this.primitiveValueForKey('distributorFeeFormat');
    }

    // Property: dueDate
    set dueDate(value:Date) {
        this.setValueForKey(value, 'dueDate');
    }
    get dueDate():Date {
        return this.valueForKey('dueDate');
    }
    set dueDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'dueDate');
    }
    get dueDatePrimitiveValue():Date {
        return this.primitiveValueForKey('dueDate');
    }

    // Property: enabled
    set enabled(value:boolean) {
        this.setValueForKey(value, 'enabled');
    }
    get enabled():boolean {
        return this.valueForKey('enabled');
    }
    set enabledPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'enabled');
    }
    get enabledPrimitiveValue():boolean {
        return this.primitiveValueForKey('enabled');
    }

    // Property: endDate
    set endDate(value:Date) {
        this.setValueForKey(value, 'endDate');
    }
    get endDate():Date {
        return this.valueForKey('endDate');
    }
    set endDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'endDate');
    }
    get endDatePrimitiveValue():Date {
        return this.primitiveValueForKey('endDate');
    }

    // Property: isPaid
    set isPaid(value:boolean) {
        this.setValueForKey(value, 'isPaid');
    }
    get isPaid():boolean {
        return this.valueForKey('isPaid');
    }
    set isPaidPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isPaid');
    }
    get isPaidPrimitiveValue():boolean {
        return this.primitiveValueForKey('isPaid');
    }

    // Property: priceAmount
    set priceAmount(value:number) {
        this.setValueForKey(value, 'priceAmount');
    }
    get priceAmount():number {
        return this.valueForKey('priceAmount');
    }
    set priceAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'priceAmount');
    }
    get priceAmountPrimitiveValue():number {
        return this.primitiveValueForKey('priceAmount');
    }

    // Property: startDate
    set startDate(value:Date) {
        this.setValueForKey(value, 'startDate');
    }
    get startDate():Date {
        return this.valueForKey('startDate');
    }
    set startDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'startDate');
    }
    get startDatePrimitiveValue():Date {
        return this.primitiveValueForKey('startDate');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }
    // Relationship: distributor
    set distributor(value:Distributor) {
        this.setValueForKey(value, 'distributor');
    }
    get distributor():Distributor {
        return this.valueForKey('distributor') as Distributor;
    }

    // Relationship: installments
    protected _installments:MIOManagedObjectSet = null;
    get installments():MIOManagedObjectSet {
        return this.valueForKey('installments');
    }
    addInstallmentsObject(value:LicensePaymentInstallment) {
        this._addObjectForKey(value, 'installments');
    }
    removeInstallmentsObject(value:LicensePaymentInstallment) {
        this._removeObjectForKey(value, 'installments');
    }

    // Relationship: invoiceLines
    protected _invoiceLines:MIOManagedObjectSet = null;
    get invoiceLines():MIOManagedObjectSet {
        return this.valueForKey('invoiceLines');
    }
    addInvoiceLinesObject(value:LicenseInvoiceLine) {
        this._addObjectForKey(value, 'invoiceLines');
    }
    removeInvoiceLinesObject(value:LicenseInvoiceLine) {
        this._removeObjectForKey(value, 'invoiceLines');
    }
    // Relationship: license
    set license(value:License) {
        this.setValueForKey(value, 'license');
    }
    get license():License {
        return this.valueForKey('license') as License;
    }
    // Relationship: offer
    set offer(value:Offer) {
        this.setValueForKey(value, 'offer');
    }
    get offer():Offer {
        return this.valueForKey('offer') as Offer;
    }
}
