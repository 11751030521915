//
// Generated class TCPPrinter
//

/// <reference path="TCPPrinter_ManagedObject.ts" />

class TCPPrinter extends TCPPrinter_ManagedObject
{

}
