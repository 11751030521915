
class LicensesDetailApplicationViewController extends MUIViewController implements LicenseDetailInfoCellDelegate
{    
    static newInstance(): LicensesDetailApplicationViewController {
        let vc = new LicensesDetailApplicationViewController('licenses-detail-applications-view');
        vc.initWithResource('layout/licenses/LicensesDetailApplicationsView.html');
        return vc;
    }

    delegate:any = null

    tableView:UITableView = null;

    private totalLabel:MUILabel = null;
    private distributorLabel:MUILabel = null;
    private profitLabel:MUILabel = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.totalLabel = MUIOutlet( this, "footer-total-lbl", "MUILabel" );
        this.distributorLabel = MUIOutlet( this, "footer-distributor-lbl", "MUILabel" );
        this.profitLabel = MUIOutlet( this, "footer-profit-lbl", "MUILabel" );
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }
    
    private businessLicense:BusinessLicense = null;
    set item(item:BusinessLicense) {
        this.businessLicense = item;
        this.updateUI();
    }

    private updateUI(){
        if(this.viewIsLoaded == false || this.businessLicense == null) return;
        
        this.updateTotals();

        this.fetchedResultsController = null;
        this.tableView.reloadData();          
    }

    numberOfSections(tableview:UITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = null;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as License;
        
        cell = tableview.dequeueReusableCellWithIdentifier("LicenseDetailCell") as LicenseDetailCell;        
        cell.item = item;
        cell.selected = (item == this.selectedItem);
        
        return cell;
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
        return UITableViewCellEditingStyle.Delete;
    }
    
    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as License;

        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            this.deleteLicense( item );
        }
    }

    selectedItem:License = null;
    didSelectCellAtIndexPath(tableView,indexPath:MIOIndexPath){
        this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath) as License;
        // this.showSelectedItem(this.businessLicense);
        
        this.delegate.didSelectLicense(this.selectedItem);
    }
    
    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("License");
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("active", false), MIOSortDescriptor.sortDescriptorWithKey("product.name", true)];
        fetchRequest.predicate = MIOPredicate.predicateWithFormat("deletedAt = null and business.identifier == " + this.businessLicense.identifier);
        fetchRequest.relationshipKeyPathsForPrefetching = ["type", "product"];
        // fetchRequest.fetchLimit = 100;        

        // if (this.searchString != null) {
        //     let predicateFormat = "name CONTAINS '" + this.searchString + "'";
        //     fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);
        // }        

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }

    private deleteLicense( item:License ){        

        this.businessLicense.totalAmount -= item.total;
        this.businessLicense.distributorAmount -= item.distributorFeeValue;

        DBHelper.deleteObjectFromMainContext( item, true );

        this.updateTotals();
    }

    updateTotals(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this.totalLabel.text = ad.currencyFormatter.stringFromNumber( this.businessLicense.totalAmount || 0 );
        this.distributorLabel.text = ad.currencyFormatter.stringFromNumber( this.businessLicense.distributorAmount || 0);
        this.profitLabel.text = ad.currencyFormatter.stringFromNumber( (this.businessLicense.totalAmount || 0) - (this.businessLicense.distributorAmount || 0) );
    }

}