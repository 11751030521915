class TicketLineCell extends UITableViewCell
{
    private titleLabel:MUILabel = null;
    private quantityLabel:MUILabel = null;
    private priceLabel:MUILabel = null;
    private totalLabel:MUILabel = null;

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
        this.quantityLabel = MUIOutlet(this, "quantity-lbl", "MUILabel");
        this.priceLabel = MUIOutlet(this, "price-lbl", "MUILabel");
        this.totalLabel = MUIOutlet(this, "total-lbl", "MUILabel");
    }

    set item(item){
        this.titleLabel.text = item["Title"];
        this.quantityLabel.text = item["Quantity"];
        this.priceLabel.text = item["UnitPrice"];
        this.totalLabel.text = item["Total"];
    }

    set line (item){
        this.titleLabel.text = null;
        this.quantityLabel.text = null;
        this.priceLabel.text = null;
        this.totalLabel.text = null;

        if (item.length > 0) this.titleLabel.text = item[0].text;
        if (item.length > 1) this.quantityLabel.text = item[1].text;
        if (item.length > 2) this.priceLabel.text = item[2].text;
        if (item.length > 3) this.totalLabel.text = item[3].text;
    }
}