
class VATDetailViewController extends MUIViewController
{
    private backButton:MUIButton = null;
    private addButton:MUIButton = null;
    private saveButton:MUIButton = null;
    private nameTextField:MUITextField = null;
    private valueTextField:MUITextField = null;
    private defaultTaxSwitch:MUISwitchButton = null;
    private tableView:UITableView = null;

    private pnf = MUIWebApplication.sharedInstance().delegate.percentNumberFormatter as MIONumberFormatter;

    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");
        this.backButton.setAction(this, function(){
            this.navigationController.popViewController();
        });

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function(this:VATDetailViewController){
            this.addSubTaxAction();
        });

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        })

        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.nameTextField.setOnChangeText(this, function(textField:MUITextField, value:string){
            this.tax.name = value;
        });
        
        this.valueTextField = MUIOutlet(this, "value-tf", "MUITextField");
        this.valueTextField.formatter = this.pnf;
        this.valueTextField.setOnChangeText(this, function(textField:MUITextField, value:string){
            let tax = this.pnf.numberFromString(value);
            if (tax != null) tax = tax / 100;
            this.tax.taxQuantity = tax;
        });

        this.defaultTaxSwitch = MUIOutlet(this, "default-tax-sw", "MUISwitchButton");
        this.defaultTaxSwitch.setOnChangeValue(this, function(control:MUIControl, value:boolean){
            this.tax.isDefault = value;
        });

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }  

    viewWillAppear(animated?){
        super.viewWillAppear(animated);        
        this.updateUI();
    }

    private tax:Tax = null;
    set item (item:Tax) {
        this.tax = item;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this.tax == null) return;

        this.nameTextField.text = this.tax.name;
        let tax = this.tax.taxQuantity * 100;
        this.valueTextField.text = this.pnf.stringFromNumber(tax);
        this.defaultTaxSwitch.on = this.tax.isDefault;

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;        
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();        
    }
    
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let cell = tableView.dequeueReusableCellWithIdentifier('SubTaxCell') as SubTaxCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as SubTax;        
        cell.item = item;
            
        return cell;
    }        

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
        return UITableViewCellEditingStyle.Delete;
    }
    
    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as SubTax;

        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            DBHelper.deleteObjectFromMainContext(item, true);
        }
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
        let predicateFormat = 'tax.identifier == "' + this.tax.identifier + '"';


        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];     
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("SubTax", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ['tax'];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }

    private addSubTaxAction() {
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("SUBTAX", "SUBTAX"), MIOLocalizeString("ADD SUBTAX", "ADD SUBTAX"), MUIAlertViewStyle.Default);


        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
            textField.placeholderText = MIOLocalizeString("NAME", "NAME");
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
            textField.placeholderText = MIOLocalizeString("PERCENTAGE", "PERCENTAGE");
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("ADD", "ADD"), MUIAlertActionStyle.Default, this, function(this:VATDetailViewController){
            let name = avc.textFields[0].text.trim();
            let quantity = this.pnf.numberFromString(avc.textFields[1].text);

            this.createSubTax(name, quantity);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private createSubTax(name:string, quantity:number){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let subTax = MIOEntityDescription.insertNewObjectForEntityForName("SubTax", ad.managedObjectContext) as SubTax;
        subTax.identifier = new MIOUUID().UUIDString;
        subTax.name = name;
        subTax.taxQuantity = quantity / 100;
        subTax.tax = this.tax;

        ad.managedObjectContext.save();
    }
}