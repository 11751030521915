//
// Generated class LicenseToken
//

/// <reference path="LicenseToken_ManagedObject.ts" />

class LicenseToken extends LicenseToken_ManagedObject
{

}
