//
// Generated class StockConsumptionAnnotation
//

/// <reference path="StockConsumptionAnnotation_ManagedObject.ts" />

class StockConsumptionAnnotation extends StockConsumptionAnnotation_ManagedObject
{

}
