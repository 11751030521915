
class SupplierReturnNoteCell extends UITableViewCell
{
    awakeFromHTML(){

    }

    set item (item:StockNote){

    }
}