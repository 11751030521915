//
// Generated class OrderQuestionOptions
//

/// <reference path="OrderQuestionOptions_ManagedObject.ts" />

class OrderQuestionOptions extends OrderQuestionOptions_ManagedObject
{

}
