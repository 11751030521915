

class AddWineCellarViewController extends MUIViewController
{
    public preferredContentSize = new MIOSize(400, 300);

    private closeButton:MUIButton = null;
    private nameTextField:MUITextField = null;
    private wineCellarTypeComboBox:MUIComboBox = null;
    private productAttributeButton:MUIButton = null;
    private doneButton:MUIButton = null;

    private _selectedProductAttributeType = null;
    delegate = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.closeButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.closeButton.setAction(this, function() {
            this.dismissViewController(true);
        });

        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.nameTextField.setOnChangeText(this, function() {});

        this.wineCellarTypeComboBox = MUIOutlet(this, 'winecellar-type-cb','MUIComboBox');
        this.wineCellarTypeComboBox.removeAllItems();
        this.wineCellarTypeComboBox.addItem(MIOLocalizeString('SELECT WINECELLAR TYPE', 'SELECT WINECELLAR TYPE'), -1); 
        this.wineCellarTypeComboBox.addItem(MIOLocalizeString("CATEGORY STOCK", "CATEGORY STOCK"), WineCellarType.StockCategory);
        this.wineCellarTypeComboBox.addItem(MIOLocalizeString("ATTRIBUTE", "ATTRIBUTE"), WineCellarType.ProductAttribute); 
        this.wineCellarTypeComboBox.setOnChangeAction(this, function(control:MUIComboBox, value){
            if (this.wineCellarTypeComboBox.getSelectedItem() == WineCellarType.ProductAttribute) {
                this.productAttributeButton.enabled = true;
            } else {
                this.productAttributeButton.enabled = false;
            }
        });

        this.productAttributeButton = MUIOutlet(this, "product-attribute-btn", "MUIButton");
        this.productAttributeButton.setAction(this, function(){
            let vc = AppHelper.sharedInstance().showCustomSelectViewControllerFromView(this.attributeButton, 'ProductAttributeType', 'name', null, this, function(controller, object:ProductAttributeType){
                if (object) {
                    this._selectedProductAttributeType = object;
                    this.productAttributeButton.title = object.name;
                }
            }, true );   
        });   
        this.productAttributeButton.enabled = false;

        this.doneButton = MUIOutlet(this, "done-btn", "MUIButton");
        this.doneButton.setAction(this, function(){

            if(this.nameTextField.text.length <= 0) {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('CHECK NAME','CHECK NAME'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CLOSE','CLOSE'), MUIAlertActionStyle.Cancel, this, function(){}));
                this.presentViewController(error, true);
                return;
            }

            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
            let ws = MIOEntityDescription.insertNewObjectForEntityForName("WineCellar", moc) as WineCellar;
            ws.identifier = MIOUUID.UUID().UUIDString;
            ws.orderIndex = this.delegate.fetchedResultsController.fetchedObjects.length + 1;
            ws.name = this.nameTextField.text;
            ws.type = this.wineCellarTypeComboBox.getSelectedItem();
            ws.productAttributeTypeID = this._selectedProductAttributeType?.identifier;

            moc.save();
            this.dismissViewController(true);
        });   
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private _product:Product = null;
    set product (product:Product) {
        if (product == null) return;

    //     this._product = product;
    //     this._tempWineCellarHash = this._product.wineCellarHash;

    //    try {
    //        this._tempWineCellarDict = JSON.parse(this._product.wineCellarDict); 
    //    } catch (ex) {
    //         // console.error(ex);
    //        this._tempWineCellarDict = this._product.wineCellarDict;
    //    }
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;        


    }

}