//
// Generated class Booking_BookingModifier
//

/// <reference path="Booking_BookingModifier_ManagedObject.ts" />

class Booking_BookingModifier extends Booking_BookingModifier_ManagedObject
{

}
