/**
 * Created by miguel on 15/2/17.
 */




class WarehouseSettingsCell extends MUITableViewCell
{
    nameLabel:MUILabel = null;
    measureComboBox:MUIComboBox = null;
    minTextField:MUITextField = null;
    maxTextField:MUITextField = null;
    defaultCheckButton:MUICheckButton = null;

    private _separator = null;
    private _item:ProductWarehouse;

    public delegate = null;

    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;

    awakeFromHTML()
    {
        
        this.nameLabel = MUIOutlet(this, "wsv_tv_name_lbl", "MUILabel");
        this.measureComboBox = MUIOutlet(this, "wsv_tv_measure_combobox", "MUIComboBox");
        this.measureComboBox.setOnChangeAction(this, function(combobox, value)
        {
            this._item.measureUnit = value;
        });
        
        this.minTextField = MUIOutlet(this, 'wsv_tv_min_text','MUITextField');
        this.minTextField.formatter = this.nf;
        this.minTextField.setOnChangeText(this, function(textfield, value)
        {
            this._item.minQuantity = this.nf.numberFromString(value);
        });

        this.maxTextField = MUIOutlet(this, 'wsv_tv_max_text', 'MUITextField');
        this.maxTextField.formatter = this.nf;
        this.maxTextField.setOnChangeText(this, function(textfield, value)
        {
           this._item.maxQuantity = this.nf.numberFromString(value);
        });

        this.defaultCheckButton = MUIOutlet(this, 'wsv_tv_switch_btn', 'MUISwitchButton');
        this.defaultCheckButton.setOnChangeValue(this, function (checkbox, value) {
            
            MIONotificationCenter.defaultCenter().postNotification('WarehouseIsDefault', this._item.identifier);
        });

        MIONotificationCenter.defaultCenter().addObserver(this, 'WarehouseIsDefault', function (notification) 
        {
                this.defaultCheckButton.setEnabled(notification.object != this._item.identifier);
                this._item.isDefault = notification.object == this._item.identifier;
                this.defaultCheckButton.setOn(notification.object == this._item.identifier);    
        });
        this._separator = MUIOutlet(this, "wsv_tv_separator", "MUIView");
    }

    set item(item:ProductWarehouse)
    {
        if (this._item != null) {
            this._item.removeObserver(this, "warehouse.isFault");        
            this._item = null;
        }
        this._item = item;
        this.updateUI();

        if (this._item != null) {
            this._item.addObserver(this, "warehouse.isFault", null);
        }
    }

    public observeValueForKeyPath(keypath, type, object){
        if (type != "did") return;
        switch (keypath) {
            case "warehouse.isFault":
                if(this._item.warehouse != null &&  this._item.warehouse.name != null)
                     this.nameLabel.text = this._item.warehouse.name;
                break;
        }
    }

    private updateUI()
    {
        if(this._item.warehouse != null)
            this.nameLabel.text = this._item.warehouse.name;

        this.minTextField.text = this.nf.stringFromNumber(this._item.minQuantity);
        this.maxTextField.text = this.nf.stringFromNumber(this._item.maxQuantity);
       
        if(this._item.product != null)
            this.initUnitComboBox(this._item.measureUnit);
       // this.measureButton.title = MeasureUnits.toString(i.getValue('measureunit'));
        let isDefault = this._item.isDefault;
         
        this.defaultCheckButton.setOn(isDefault);
        if(isDefault) this.defaultCheckButton.setEnabled(false);

    }
    private initUnitComboBox(defaultUnit = null)
    {
        this.measureComboBox.removeAllItems();

        let measureType = this.delegate.product.measureType;
        if(this.delegate.product.isContainer) {
            let label = 'Cont (' + 
                    this.delegate.product.quantity + 
                    ' ' + 
                    MeasureUnits.toString(this.delegate.product.measureUnitType) +
                    ')';

            this.measureComboBox.addItem(label, MeasureUnitType.Container);          
        }
        
        switch(measureType) {
            case MeasureType.Unit:
                this.measureComboBox.addItem('U', MeasureUnitType.Unit);
                break;
            case MeasureType.Volume:
                this.measureComboBox.addItem('L', MeasureUnitType.VolumeLitre);
                this.measureComboBox.addItem('cl', MeasureUnitType.VolumeCentilitre);
                break;
            case MeasureType.Mass:
                this.measureComboBox.addItem('Kg', MeasureUnitType.MassKilogram);
                this.measureComboBox.addItem('gr', MeasureUnitType.MassGram );
                break;        
            }
        
        if(!defaultUnit) {
            
            defaultUnit = (this.delegate.product.quantity > 0) ? 
                        MeasureUnitType.Container:
                        this.delegate.product.measureUnitType;
        }
        this.measureComboBox.selectItem(defaultUnit);
    }
}