//
// Generated class MenuCategory
//

/// <reference path="MenuCategory_ManagedObject.ts" />

class MenuCategory extends MenuCategory_ManagedObject
{

}
