
class LedgerAccountCell extends UITableViewCell
{
    private nameTextField:MUITextField = null;
    private prefixTextField:MUITextField  = null;
    private typeComboBox:MUIComboBox = null;

    private pnf = MUIWebApplication.sharedInstance().delegate.percentNumberFormatter as MIONumberFormatter;
    
    awakeFromHTML(){
        this.nameTextField = MUIOutlet(this, 'name-tf', 'MUITextField');
        this.nameTextField.setOnChangeText(this, function(textfield:MUITextField, value){
            this._ledgerAccount.name = value.trim();
        });

        this.prefixTextField = MUIOutlet(this, 'prefix-tf', 'MUITextField');
        this.prefixTextField.setOnChangeText(this, function(textfield:MUITextField, value){
            this._ledgerAccount.normalizedAccountPrefix = value;
        });

        this.typeComboBox = MUIOutlet(this, 'type-cb', 'MUIComboBox');
        this.typeComboBox.setOnChangeAction(this, function(this:LedgerAccountCell, control:MUIComboBox, value:string){
            this._ledgerAccount.type = parseInt(value);
        });
    }
    
    private _ledgerAccount:LedgerAccount = null;
    set item(i:LedgerAccount){
        this._ledgerAccount = i;
        this.nameTextField.text = i.name;
        this.prefixTextField.text = i.prefix;
        this.typeComboBox.selectItem(i.type);
    }
}
