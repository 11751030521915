//
// Generated class License
//

/// <reference path="License_ManagedObject.ts" />


class License extends License_ManagedObject
{
}
