//
// Generated class OrderPaymentTransaction
//

/// <reference path="OrderPaymentTransaction_ManagedObject.ts" />

class OrderPaymentTransaction extends OrderPaymentTransaction_ManagedObject
{

}
