



class ReportResultStockCell extends MUITableViewCell
{
    nameLabel     = null;
    quantityLabel = null;
    baseLabel    = null;
    taxLabel   = null
   
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;


    awakeFromHTML()
    {
        this.nameLabel     = MUIOutlet(this, 'rrv_c6_name_lbl', 'MUILabel');
        this.quantityLabel = MUIOutlet(this, 'rrv_c6_label3_lbl', 'MUILabel');
        this.baseLabel     = MUIOutlet(this, 'rrv_c6_label2_lbl', 'MUILabel');
        this.taxLabel      = MUIOutlet(this, 'rrv_c6_label1_lbl','MUILabel');
    }

    set item(i)
    {
        this.nameLabel.text     = i[Object.keys(i)[0]];
        this.quantityLabel.text = (i.finalquantity ? this.nf.stringFromNumber(parseFloat(i.finalquantity)) :'0'); 
        this.baseLabel.text    = (i.total ? this.cf.stringFromNumber(parseFloat(i.total)):''); 
        this.taxLabel.text  = (i.totaltaxes ? this.cf.stringFromNumber(parseFloat(i.totaltaxes)):''); 
    }
}
