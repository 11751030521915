class BookingCalendarZoneCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;
    private requestPaxLabel:MUILabel = null;
    private assignedPaxLabel:MUILabel = null;
    private totalLabel:MUILabel = null;    
        
    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, 'name_lbl', 'MUILabel');
        this.requestPaxLabel = MUIOutlet(this, 'pax1_lbl', 'MUILabel');
        this.assignedPaxLabel = MUIOutlet(this, 'pax2_lbl', 'MUILabel');
        this.totalLabel = MUIOutlet(this, 'pax3_lbl', 'MUILabel');
    }

    setZoneInfo(zoneInfo, zone:BookingZone){
        if (zoneInfo == null) {
            this.nameLabel.text = zone.name;
            return;
        }

        this.nameLabel.text = zoneInfo["Name"];        
        this.requestPaxLabel.text = zoneInfo["RequestPAX"] > 0 ? zoneInfo["RequestPAX"] : "-";
        this.assignedPaxLabel.text = zoneInfo["PAX"];
        
        let dif = zoneInfo["MaxPAX"] - zoneInfo["PAX"];
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this.totalLabel.text = ad.numberFormatter.stringFromNumber(dif);

        if (dif < 0) {
            this.totalLabel.setTextRGBColor(255, 0, 0);
        }
        else {
            this.totalLabel.setTextRGBColor(0, 0, 0);
        }
    }
}
