

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class PhoneNumber_ManagedObject

class PhoneNumber_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: countryCode
    set countryCode(value:string) {
        this.setValueForKey(value, 'countryCode');
    }
    get countryCode():string {
        return this.valueForKey('countryCode');
    }
    set countryCodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'countryCode');
    }
    get countryCodePrimitiveValue():string {
        return this.primitiveValueForKey('countryCode');
    }

    // Property: number
    set number(value:string) {
        this.setValueForKey(value, 'number');
    }
    get number():string {
        return this.valueForKey('number');
    }
    set numberPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'number');
    }
    get numberPrimitiveValue():string {
        return this.primitiveValueForKey('number');
    }
    // Relationship: company
    set company(value:Company) {
        this.setValueForKey(value, 'company');
    }
    get company():Company {
        return this.valueForKey('company') as Company;
    }
    // Relationship: companyMobile
    set companyMobile(value:Company) {
        this.setValueForKey(value, 'companyMobile');
    }
    get companyMobile():Company {
        return this.valueForKey('companyMobile') as Company;
    }
    // Relationship: legalEntityMobilePhone
    set legalEntityMobilePhone(value:LegalEntity) {
        this.setValueForKey(value, 'legalEntityMobilePhone');
    }
    get legalEntityMobilePhone():LegalEntity {
        return this.valueForKey('legalEntityMobilePhone') as LegalEntity;
    }
    // Relationship: legalEntityPhone
    set legalEntityPhone(value:LegalEntity) {
        this.setValueForKey(value, 'legalEntityPhone');
    }
    get legalEntityPhone():LegalEntity {
        return this.valueForKey('legalEntityPhone') as LegalEntity;
    }
    // Relationship: order
    set order(value:Order) {
        this.setValueForKey(value, 'order');
    }
    get order():Order {
        return this.valueForKey('order') as Order;
    }
    // Relationship: warehouse
    set warehouse(value:Warehouse) {
        this.setValueForKey(value, 'warehouse');
    }
    get warehouse():Warehouse {
        return this.valueForKey('warehouse') as Warehouse;
    }
}
