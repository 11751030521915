

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class PromotionalCode_ManagedObject

class PromotionalCode_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: beginDateTime
    set beginDateTime(value:Date) {
        this.setValueForKey(value, 'beginDateTime');
    }
    get beginDateTime():Date {
        return this.valueForKey('beginDateTime');
    }
    set beginDateTimePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'beginDateTime');
    }
    get beginDateTimePrimitiveValue():Date {
        return this.primitiveValueForKey('beginDateTime');
    }

    // Property: code
    set code(value:string) {
        this.setValueForKey(value, 'code');
    }
    get code():string {
        return this.valueForKey('code');
    }
    set codePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'code');
    }
    get codePrimitiveValue():string {
        return this.primitiveValueForKey('code');
    }

    // Property: codeCRC
    set codeCRC(value:number) {
        this.setValueForKey(value, 'codeCRC');
    }
    get codeCRC():number {
        return this.valueForKey('codeCRC');
    }
    set codeCRCPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'codeCRC');
    }
    get codeCRCPrimitiveValue():number {
        return this.primitiveValueForKey('codeCRC');
    }

    // Property: codeNumber
    set codeNumber(value:number) {
        this.setValueForKey(value, 'codeNumber');
    }
    get codeNumber():number {
        return this.valueForKey('codeNumber');
    }
    set codeNumberPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'codeNumber');
    }
    get codeNumberPrimitiveValue():number {
        return this.primitiveValueForKey('codeNumber');
    }

    // Property: codePrefix
    set codePrefix(value:string) {
        this.setValueForKey(value, 'codePrefix');
    }
    get codePrefix():string {
        return this.valueForKey('codePrefix');
    }
    set codePrefixPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'codePrefix');
    }
    get codePrefixPrimitiveValue():string {
        return this.primitiveValueForKey('codePrefix');
    }

    // Property: endDateTime
    set endDateTime(value:Date) {
        this.setValueForKey(value, 'endDateTime');
    }
    get endDateTime():Date {
        return this.valueForKey('endDateTime');
    }
    set endDateTimePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'endDateTime');
    }
    get endDateTimePrimitiveValue():Date {
        return this.primitiveValueForKey('endDateTime');
    }

    // Property: maxNumberOfUses
    set maxNumberOfUses(value:number) {
        this.setValueForKey(value, 'maxNumberOfUses');
    }
    get maxNumberOfUses():number {
        return this.valueForKey('maxNumberOfUses');
    }
    set maxNumberOfUsesPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'maxNumberOfUses');
    }
    get maxNumberOfUsesPrimitiveValue():number {
        return this.primitiveValueForKey('maxNumberOfUses');
    }
    // Relationship: event
    set event(value:VenueEvent) {
        this.setValueForKey(value, 'event');
    }
    get event():VenueEvent {
        return this.valueForKey('event') as VenueEvent;
    }

    // Relationship: lines
    protected _lines:MIOManagedObjectSet = null;
    get lines():MIOManagedObjectSet {
        return this.valueForKey('lines');
    }
    addLinesObject(value:PromotionalCodeLine) {
        this._addObjectForKey(value, 'lines');
    }
    removeLinesObject(value:PromotionalCodeLine) {
        this._removeObjectForKey(value, 'lines');
    }
    // Relationship: offer
    set offer(value:Offer) {
        this.setValueForKey(value, 'offer');
    }
    get offer():Offer {
        return this.valueForKey('offer') as Offer;
    }
}
