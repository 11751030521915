//
// Generated class LocationGroupRule
//

/// <reference path="LocationGroupRule_ManagedObject.ts" />

class LocationGroupRule extends LocationGroupRule_ManagedObject
{

}
