//
// Generated class VIPCard
//

/// <reference path="VIPCard_ManagedObject.ts" />

class VIPCard extends VIPCard_ManagedObject
{

}
