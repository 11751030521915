//
// Generated class CashDeskCurrency
//

/// <reference path="CashDeskCurrency_ManagedObject.ts" />

class CashDeskCurrency extends CashDeskCurrency_ManagedObject
{

}
