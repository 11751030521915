

class WarehouseProductCell extends MUITableViewCell {
    
    nameLabel: MUILabel = null;

    minLabel: MUILabel = null;
    maxLabel: MUILabel = null;
    priceLabel: MUILabel = null;
    totalLabel: MUILabel = null;
    amountLabel: MUILabel = null;
    
    editButton: MUIButton = null;
    
    warehouse = null;    

    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;


    awakeFromHTML() {

        this.nameLabel = MUIOutlet(this, 'sv_nb_tv_product_lbl', 'MUILabel');
        this.minLabel = MUIOutlet(this, 'sv_nb_tv_min_lbl', 'MUILabel');
        this.maxLabel = MUIOutlet(this, 'sv_nb_tv_max_lbl', 'MUILabel');
        this.priceLabel = MUIOutlet(this, 'sv_nb_tv_price_avg_lbl', 'MUILabel');
        this.totalLabel = MUIOutlet(this, 'sv_nb_tv_total_product_lbl', 'MUILabel');
        this.amountLabel = MUIOutlet(this, 'sv_nb_tv_containers_lbl', 'MUILabel');

        // this.editButton = MUIOutlet(this, 'sv_nb_tv_edit2_btn', 'MUIButton');
        // this.editButton.setAction(this, function () {
        //     this.showProductAlertViewController();
        // });            
    }

    private _item: StockAnnotationView = null;
    set item(i: StockAnnotationView) {
        this._item = i;
        this.updateUI();
    }

    private updateUI(){
        let i = this._item;

        this.nameLabel.text = i.name;

        this.minLabel.text = (i.productMin ? this.nf.stringFromNumber(i.productMin) : '-');
        this.maxLabel.text = (i.productMax ? this.nf.stringFromNumber(i.productMax) : '-');        
                
        this.priceLabel.text = this.cf.stringFromNumber(i.productCostPrice);         

        if (i.productMeasureType == MeasureUnitType.Container) {
            this.totalLabel.text = '<small>(' + this.nf.stringFromNumber(i.productContainerQuantity * i.productQuantity) + ' ' + MeasureUnits.toString(i.productContainerMeasureType) + ')</small> ' + this.nf.stringFromNumber(i.productQuantity) + ' Cont';
        }
        else {
            this.totalLabel.text = i.productQuantity + " " + MeasureUnits.toString(i.productMeasureType);
        }

        this.amountLabel.text = this.cf.stringFromNumber(i.productCostPrice * i.productQuantity);

        if (i.productMin < 0) {
            MUICoreLayerAddStyle(this.nameLabel.layer, "alert");            
            MUICoreLayerAddStyle(this.minLabel.layer, "alert");            
            MUICoreLayerAddStyle(this.maxLabel.layer, "alert");            
            MUICoreLayerAddStyle(this.priceLabel.layer, "alert");            
            MUICoreLayerAddStyle(this.totalLabel.layer, "alert");            
            MUICoreLayerAddStyle(this.amountLabel.layer, "alert");            
        }
        else {
            MUICoreLayerRemoveStyle(this.nameLabel.layer, "alert");            
            MUICoreLayerRemoveStyle(this.minLabel.layer, "alert");            
            MUICoreLayerRemoveStyle(this.maxLabel.layer, "alert");            
            MUICoreLayerRemoveStyle(this.priceLabel.layer, "alert");            
            MUICoreLayerRemoveStyle(this.totalLabel.layer, "alert");            
            MUICoreLayerRemoveStyle(this.amountLabel.layer, "alert");
        }
    }

    private convertCostFromMinimumMeasureUnitQuantity(quantity, toMeasureUnit, containerQuantity) : number
    {
        if (containerQuantity == null)
        {
            containerQuantity = 1;
        }

        let finalQuantity = null;
        switch (toMeasureUnit)
        {
            case MeasureUnitType.Percentage:
            case MeasureUnitType.Container:
            case MeasureUnitType.None:
            {
                finalQuantity = quantity;
                break;
            }

            case MeasureUnitType.Unit:
            case MeasureUnitType.MassGram:
            case MeasureUnitType.VolumeCentilitre:
            {
                finalQuantity = quantity * containerQuantity;
                break;
            }

            case MeasureUnitType.VolumeLitre:
            {
                // conversion inverted because is a cost
                finalQuantity = this.convertQuantity(quantity, toMeasureUnit, MeasureUnitType.VolumeCentilitre);
                finalQuantity = finalQuantity * containerQuantity;
                break;
            }

            case MeasureUnitType.MassKilogram:
            {
                // conversion inverted because is a cost
                finalQuantity = this.convertQuantity(quantity, toMeasureUnit, MeasureUnitType.MassGram);
                finalQuantity = finalQuantity * containerQuantity;
                break;
            }
        }

        return finalQuantity;
    }

    private convertQuantity(quantity, fromMeasureUnit, toMeasureUnit)
    {
        if (this.isCompatibleMeasureUnit(fromMeasureUnit, toMeasureUnit) == false)
        {
            return quantity;
        }

        if (fromMeasureUnit == toMeasureUnit)
        {
            return quantity;
        }

        if (toMeasureUnit == MeasureUnitType.Percentage)
        {
            return quantity * 0.01;
        }

        let fromEquivalence = this.equivalenceForMeasureUnit(fromMeasureUnit);
        let toEquivalence = this.equivalenceForMeasureUnit(toMeasureUnit);
        return quantity * (fromEquivalence / toEquivalence);
    }

    private isCompatibleMeasureUnit(measureUnit1, measureUnit2) : boolean
    {
        if (measureUnit1 == measureUnit2)
        {
            return true;
        }

        if (measureUnit1 == MeasureUnitType.Percentage
            || measureUnit2 == MeasureUnitType.Percentage)
        {
            return true;
        }

        if (measureUnit1 == MeasureUnitType.Container
            || measureUnit2 == MeasureUnitType.Container)
        {
            return true;
        }

        if ((measureUnit1 == MeasureUnitType.MassGram
             && measureUnit2 == MeasureUnitType.MassKilogram)
             || (measureUnit1 == MeasureUnitType.MassKilogram
                && measureUnit2 == MeasureUnitType.MassGram) )
        {
            return true;
        }

        if ((measureUnit1 == MeasureUnitType.VolumeCentilitre
            && measureUnit2 == MeasureUnitType.VolumeLitre)
            || (measureUnit1 == MeasureUnitType.VolumeLitre
               && measureUnit2 == MeasureUnitType.VolumeCentilitre) )
       {
           return true;
       }

       return false;
    }

    private equivalenceForMeasureUnit(measureUnit) : number
    {
        switch (measureUnit)
        {
            case MeasureUnitType.MassKilogram:
            {
                return 1000;
            }

            case MeasureUnitType.VolumeCentilitre:
            {
                return 0.01;
            }

            // case MeasureUnitType.Unit:
            // case MeasureUnitType.MassGram:
            // case MeasureUnitType.VolumeLitre:
            // case MeasureUnitType.Container:
            // case MeasureUnitType.Percentage:
            // case MeasureUnitType.None:
            default:
            {
                return 1;
            }
        }
    }

    private calculatePrice(annotation:StockAnnotationView)
    {
        /* TODO: Good code, Calculate price from product.costprice when product arrives in service
        let product = annotation.product;
        let currentCostInMinimumMU = product.costPrice;
        return this.convertCostFromMinimumMeasureUnitQuantity(currentCostInMinimumMU,product.measureUnitType,product.quantity);
        */

        // Code to show the view is working, but using annotation.costAveragePrice, wich is not the right cost
        // return this.convertCostFromMinimumMeasureUnitQuantity(annotation.costAveragePrice, annotation.productMeasureType, annotation.productQuantity);

        /* Old code, delete this
        let annotationMeasure = annotation.measureUnit;
        let productMeasure = annotation.productMeasureUnit;
        let factor = 1;

        if (annotationMeasure == productMeasure) factor = 1;
        else if (annotationMeasure == MeasureUnitType.MassKilogram && productMeasure == MeasureUnitType.MassGram) {
            factor = 1000;
        } else if (annotationMeasure == MeasureUnitType.MassGram && productMeasure == MeasureUnitType.MassKilogram) {
            factor = 0.001;
        } else if (annotationMeasure == MeasureUnitType.VolumeLitre && productMeasure == MeasureUnitType.VolumeCentilitre) {
            factor = 100;
        } else if (annotationMeasure == MeasureUnitType.VolumeCentilitre && productMeasure == MeasureUnitType.VolumeLitre) {
            factor = 0.01;
        } else {
            factor = 1;
            //throw new Error('Measure no controlada');
        }
        
        return annotation.costAveragePrice * factor;  
        */  
    }


    private editPrice() {
        // if(this.delegate)
        //   this.delegate.changeExistencesPrice(this._item);
        var avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('SET MIN/MAX RANGE','Set Min/Max range'), MIOLocalizeString('CHOOSE MIN/MAX RANGE','Choose min / max range '), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function (textField: MUITextField) {
            textField.setPlaceholderText('Min');
            textField.formatter = this.nf;
        });
        avc.addTextFieldWithConfigurationHandler(this, function (textField: MUITextField) {
            textField.setPlaceholderText('Max');
            textField.formatter = this.nf;
        });
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','Done'), MUIAlertActionStyle.Default, this, function () {
            let min = this.nf.numberFromString(avc.textFields[0].text);
            let max = this.nf.numberFromString(avc.textFields[1].text);
            if (this._item.warehouse == null) {
                let product: Product = this.moc.queryObject('Product', MIOPredicate.predicateWithFormat('identifier == ' + this._item.product));
                if (product) {
                    product.min = min;
                    product.max = max;
                    this.setRange(min, max);
                    this.moc.save();
                }
                else {
                    MIONotificationCenter.defaultCenter().addObserver(this,
                        "MIOWebServicePersistentStoreEntityDownloaded",
                        function (notification) {

                            let object: MIOManagedObject = notification.object;
                            if (object.entity.managedObjectClassName == "Product") {
                                // Check if the product we are looking for
                                let p: Product = object as Product;
                                if (p.identifier == this._item.product) {
                                    p.min = min;
                                    p.max = max;
                                    this.setRange(min, max);
                                    this.moc.save();
                                    // We don't need the notifcation any more
                                    MIONotificationCenter.defaultCenter().removeObserver(this, "MIOWebServicePersistentStoreEntityDownloaded");
                                }
                            }
                        });
                }
            }
            else {
                let productWarehouse: ProductWarehouse = this.moc.queryObject('ProductWarehouse', MIOPredicate.predicateWithFormat('product.identifier == ' + this._item.product + ' and warehouse.identifier == ' + this._item.warehouse));
                if (productWarehouse) {
                    productWarehouse.minQuantity = min;
                    productWarehouse.maxQuantity = max;
                    this.setRange(min, max);
                    this.moc.save();
                }
                else {
                    MIONotificationCenter.defaultCenter().addObserver(this,
                        "MIOWebServicePersistentStoreEntityDownloaded",
                        function (notification) {

                            let object: MIOManagedObject = notification.object;
                            if (object.entity.managedObjectClassName == "ProductWarehouse") {
                                // Check if the product we are looking for
                                let pw: ProductWarehouse = object as ProductWarehouse;
                                if (pw.product.identifier == this._item.product && pw.warehouse.identifier == this._item.warehouse) {
                                    pw.minQuantity = min;
                                    pw.maxQuantity = max;
                                    this.setRange(min, max);
                                    this.moc.save();
                                    // We don't need the notifcation any more
                                    MIONotificationCenter.defaultCenter().removeObserver(this, "MIOWebServicePersistentStoreEntityDownloaded");
                                }
                            }
                        });
                }
            }

        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, this, function () { }));

        //this.delegate.presentViewController(avc, true);
    }

    private setRange(min, max) {
        this._item.product.min = min;
        this._item.product.max = max;
        this.minLabel.text = this.nf.stringFromNumber(min);
        this.maxLabel.text = this.nf.stringFromNumber(max);
    }

    private showProductAlertViewController(completion) {

        var avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('PRODUCT','Product'), MIOLocalizeString('CONFIG','Config '), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function (textField: MUITextField) {
            
            textField.setPlaceholderText("Name");
            textField.text = this._item.name;
        });
        avc.addComboBoxWithConfigurationHandler(this, function (comboBox: MUIComboBox) {
            comboBox.addItem('U', MeasureUnitType.Unit);
            comboBox.addItem('Kg', MeasureUnitType.MassKilogram);
            comboBox.addItem('gr', MeasureUnitType.MassGram);
            comboBox.addItem('L', MeasureUnitType.VolumeLitre);
            comboBox.addItem('cl', MeasureUnitType.VolumeCentilitre);
            comboBox.selectItem(this._item.measureUnitType);
        });

        avc.addTextFieldWithConfigurationHandler(this, function (textField: MUITextField) {

            textField.setPlaceholderText("Quantity");
            textField.formatter = this.nf;
            textField.text = this._item.quantity != null ?this.nf.stringFromNumber(this._item.quantity) : '';
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','Done'), MUIAlertActionStyle.Default, this, function () {

            let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
            //let moc: MIOManagedObjectContext = ad.managedObjectContext;
            let data = {};
            let nameTF = avc.textFields[0];
            let name = nameTF.text;
            if (name != null && name.trim().length > 0 ) {
                this._item.product.name = name;
                data['name'] = name;
            }

            let quantityTF = avc.textFields[1];
            let quantity = this.nf.numberFromString(quantityTF.text);
            if (isNaN(quantity)) quantity = null;

            let measureUnitComboBox: MUIComboBox = avc.comboBoxes[0];
            let measureUnitType = measureUnitComboBox.getSelectedItem();

            this._item.product.quantity = quantity;
            this._item.product.measureUnitType = measureUnitType;
            data['quantity'] = quantity;
            data['measureunittype'] = measureUnitType;

            let ws:WebService = ad.webService;

            // ws.updateProduct(ad.selectedPlace, this._item.product,data, this, function(code, json){
           
            // });
            
            this.updateUI();            
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, this, function () {

            if (completion != null) {
                completion.call(this, 'Cancel', null);
            }

        }));

        avc.addCompletionHandler(this, function () {
            // Release the observers
            // DBHelper.sharedInstance().removeObserverForChangesInEntity(this, 'ProductCategory');
            // DBHelper.sharedInstance().removeObserverForChangesInEntity(this, 'Warehouse');
        });

        //this.delegate.presentViewController(avc, true);
    }
}
