//
// Generated class ArchivedDocumentTemplate
//

/// <reference path="ArchivedDocumentTemplate_ManagedObject.ts" />

class ArchivedDocumentTemplate extends ArchivedDocumentTemplate_ManagedObject
{

}
