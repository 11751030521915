class BusinessLicenseCell extends MUITableViewCell
{

    private begindateLabel:MUILabel = null;
    private enddateLabel:MUILabel = null;
    private appLabel:MUILabel = null;
    private appNameLabel:MUILabel = null;
    private typeLabel:MUILabel = null;
    private appTypeLabel:MUILabel = null;
    private infoLabel:MUILabel = null;
    

    private dtf:MIODateFormatter = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter;

    awakeFromHTML(){
        this.begindateLabel = MUIOutlet(this, "begin-date-lbl", "MUILabel");
        this.enddateLabel = MUIOutlet(this, "end-date-lbl", "MUILabel");
        this.appLabel = MUIOutlet(this, "app-id-lbl", "MUILabel");
        this.appNameLabel = MUIOutlet(this, "app-name-lbl", "MUILabel");
        this.typeLabel = MUIOutlet(this, "type-lbl", "MUILabel");
        this.appTypeLabel = MUIOutlet(this, "app-type-lbl", "MUILabel");
        this.infoLabel = MUIOutlet(this, "info-lbl", "MUILabel");        
    }

    set item(i:License){
        // this.begindateLabel.text = this.dtf.stringFromDate(i.beginDate);
        // this.enddateLabel.text = this.dtf.stringFromDate(i.endDate);
        // if (this.appLabel != null) this.appLabel.text = i.app;
        // this.appNameLabel.text = i.appname;
        // if (this.typeLabel != null) this.typeLabel.text = i.typeString;
        // this.appTypeLabel.text = i.appTypeString;
        // this.infoLabel.text = i.info;
        
    }
}