/**
 * Created by crisan on 7/12/16.
 */



/// <reference path="../../model/ProductListItem.ts" />


class ProductCell extends UITableViewCell
{
    productType = ProductType.Sales;

    private nameLabel:MUILabel = null;
    private priceLabel:MUILabel = null;    
    private imageView:MUIImageView = null;    

    awakeFromHTML(){
        this.imageView = MUIOutlet(this, "image-view", "MUIImageView");
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.priceLabel = MUIOutlet(this, "price-lbl", "MUILabel");                
    }

    set item(i:MIOManagedObject){

        let image = i.valueForKey("image");
        let name = i.valueForKey("name");
        let placeID = i.valueForKey( "placeID");
        
        this.imageView.setImage(image?.url);

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;        
        this.nameLabel.text = name + (placeID != null && placeID.toLowerCase() != ad.selectedIdentifier.toLowerCase() ? " 🔒" : "" );

        if (i instanceof Product) {
            let cf = ad.currencyFormatter;
            let price = (this.productType == ProductType.Sales ? i.price : 0) ; //i.costBalance?.lastPrice);
            this.priceLabel.text = cf.stringFromNumber(price);            

            if (i instanceof LicenseProduct) {
                this.nameLabel.setTextRGBColor(255, 99, 71);
            }
        }        
        else {
            this.priceLabel.text = null;
        }
    }

}
