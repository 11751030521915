

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class OnlineOrderLine_ManagedObject

class OnlineOrderLine_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: comments
    set comments(value:string) {
        this.setValueForKey(value, 'comments');
    }
    get comments():string {
        return this.valueForKey('comments');
    }
    set commentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'comments');
    }
    get commentsPrimitiveValue():string {
        return this.primitiveValueForKey('comments');
    }

    // Property: concept
    set concept(value:string) {
        this.setValueForKey(value, 'concept');
    }
    get concept():string {
        return this.valueForKey('concept');
    }
    set conceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'concept');
    }
    get conceptPrimitiveValue():string {
        return this.primitiveValueForKey('concept');
    }

    // Property: dbID
    set dbID(value:string) {
        this.setValueForKey(value, 'dbID');
    }
    get dbID():string {
        return this.valueForKey('dbID');
    }
    set dbIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'dbID');
    }
    get dbIDPrimitiveValue():string {
        return this.primitiveValueForKey('dbID');
    }

    // Property: discount
    set discount(value:number) {
        this.setValueForKey(value, 'discount');
    }
    get discount():number {
        return this.valueForKey('discount');
    }
    set discountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'discount');
    }
    get discountPrimitiveValue():number {
        return this.primitiveValueForKey('discount');
    }

    // Property: entityID
    set entityID(value:string) {
        this.setValueForKey(value, 'entityID');
    }
    get entityID():string {
        return this.valueForKey('entityID');
    }
    set entityIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'entityID');
    }
    get entityIDPrimitiveValue():string {
        return this.primitiveValueForKey('entityID');
    }

    // Property: imageURL
    set imageURL(value:string) {
        this.setValueForKey(value, 'imageURL');
    }
    get imageURL():string {
        return this.valueForKey('imageURL');
    }
    set imageURLPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'imageURL');
    }
    get imageURLPrimitiveValue():string {
        return this.primitiveValueForKey('imageURL');
    }

    // Property: invited
    set invited(value:boolean) {
        this.setValueForKey(value, 'invited');
    }
    get invited():boolean {
        return this.valueForKey('invited');
    }
    set invitedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'invited');
    }
    get invitedPrimitiveValue():boolean {
        return this.primitiveValueForKey('invited');
    }

    // Property: linePrice
    set linePrice(value:number) {
        this.setValueForKey(value, 'linePrice');
    }
    get linePrice():number {
        return this.valueForKey('linePrice');
    }
    set linePricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'linePrice');
    }
    get linePricePrimitiveValue():number {
        return this.primitiveValueForKey('linePrice');
    }

    // Property: quantity
    set quantity(value:number) {
        this.setValueForKey(value, 'quantity');
    }
    get quantity():number {
        return this.valueForKey('quantity');
    }
    set quantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'quantity');
    }
    get quantityPrimitiveValue():number {
        return this.primitiveValueForKey('quantity');
    }

    // Property: total
    set total(value:number) {
        this.setValueForKey(value, 'total');
    }
    get total():number {
        return this.valueForKey('total');
    }
    set totalPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'total');
    }
    get totalPrimitiveValue():number {
        return this.primitiveValueForKey('total');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: unitPrice
    set unitPrice(value:number) {
        this.setValueForKey(value, 'unitPrice');
    }
    get unitPrice():number {
        return this.valueForKey('unitPrice');
    }
    set unitPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'unitPrice');
    }
    get unitPricePrimitiveValue():number {
        return this.primitiveValueForKey('unitPrice');
    }

    // Relationship: lines
    protected _lines:MIOManagedObjectSet = null;
    get lines():MIOManagedObjectSet {
        return this.valueForKey('lines');
    }
    addLinesObject(value:OnlineOrderLine) {
        this._addObjectForKey(value, 'lines');
    }
    removeLinesObject(value:OnlineOrderLine) {
        this._removeObjectForKey(value, 'lines');
    }
    // Relationship: menu
    set menu(value:Menu) {
        this.setValueForKey(value, 'menu');
    }
    get menu():Menu {
        return this.valueForKey('menu') as Menu;
    }
    // Relationship: modifier
    set modifier(value:ProductModifier) {
        this.setValueForKey(value, 'modifier');
    }
    get modifier():ProductModifier {
        return this.valueForKey('modifier') as ProductModifier;
    }
    // Relationship: offer
    set offer(value:Offer) {
        this.setValueForKey(value, 'offer');
    }
    get offer():Offer {
        return this.valueForKey('offer') as Offer;
    }
    // Relationship: order
    set order(value:OnlineOrder) {
        this.setValueForKey(value, 'order');
    }
    get order():OnlineOrder {
        return this.valueForKey('order') as OnlineOrder;
    }
    // Relationship: parent
    set parent(value:OnlineOrderLine) {
        this.setValueForKey(value, 'parent');
    }
    get parent():OnlineOrderLine {
        return this.valueForKey('parent') as OnlineOrderLine;
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
    // Relationship: rate
    set rate(value:Rate) {
        this.setValueForKey(value, 'rate');
    }
    get rate():Rate {
        return this.valueForKey('rate') as Rate;
    }
    // Relationship: tax
    set tax(value:Tax) {
        this.setValueForKey(value, 'tax');
    }
    get tax():Tax {
        return this.valueForKey('tax') as Tax;
    }
}
