//
// Generated class ReportSettingsMenu
//

/// <reference path="ReportSettingsMenu_ManagedObject.ts" />

class ReportSettingsMenu extends ReportSettingsMenu_ManagedObject
{

}
