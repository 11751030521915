

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class PrintProfileConfiguration_ManagedObject

class PrintProfileConfiguration_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: autoPrintPaymentTicketOnOrder
    set autoPrintPaymentTicketOnOrder(value:boolean) {
        this.setValueForKey(value, 'autoPrintPaymentTicketOnOrder');
    }
    get autoPrintPaymentTicketOnOrder():boolean {
        return this.valueForKey('autoPrintPaymentTicketOnOrder');
    }
    set autoPrintPaymentTicketOnOrderPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'autoPrintPaymentTicketOnOrder');
    }
    get autoPrintPaymentTicketOnOrderPrimitiveValue():boolean {
        return this.primitiveValueForKey('autoPrintPaymentTicketOnOrder');
    }

    // Relationship: printerLinks
    protected _printerLinks:MIOManagedObjectSet = null;
    get printerLinks():MIOManagedObjectSet {
        return this.valueForKey('printerLinks');
    }
    addPrinterLinksObject(value:PrinterLink) {
        this._addObjectForKey(value, 'printerLinks');
    }
    removePrinterLinksObject(value:PrinterLink) {
        this._removeObjectForKey(value, 'printerLinks');
    }
    // Relationship: printProfile
    set printProfile(value:PrintProfile) {
        this.setValueForKey(value, 'printProfile');
    }
    get printProfile():PrintProfile {
        return this.valueForKey('printProfile') as PrintProfile;
    }
}
