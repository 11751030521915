
/// <reference path="../base/BaseTableViewController.ts" />


class SectionListViewController extends BaseTableViewController 
{
    private lastSectionButton:MUIButton = null;   
    private workspaceButton:MUIButton = null;       

    private header:MUIView = null;

    get preferredContentSize(){
        return new MIOSize(320, innerHeight);
    }   

    viewDidLoad(){
        super.viewDidLoad();
        this.transitioningDelegate = this;

        this.lastSectionButton = MUIOutlet(this, "last-section-btn", "MUIButton");
        this.lastSectionButton.setAction(this, function(){
            this.showLastSection();
        });

        this.workspaceButton = MUIOutlet(this, 'workspace-btn', 'MUIButton');
        this.workspaceButton.setAction(this, function(){            
            AppHelper.sharedInstance().presentWorkspaceViewController(this);            
        });

        this.sectionsButton = MUIOutlet(this, "sections-btn", "MUIButton");
        this.sectionsButton.setAction(this, function(){
            this.dismissViewController(true);
        });

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.header = MUIOutlet(this, "table-header", "MUIView");
        this.header.hidden = true;

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        //this.tableView.sectionHeaderHeight = 56;
    }

    private tableViewOffset:MIOPoint = null;
    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        //this.lastSectionButton.hidden = this.lastSelectedItem != null ? false : true;
        //this.lastSectionButton.hidden = MIOCoreIsPhone() == true ? true : false;
        this.lastSectionButton.hidden = true;
        this.workspaceButton.hidden = MIOCoreIsPhone() == true ? false : true;        
        if (this.tableViewOffset != null) this.tableView.contentOffset = this.tableViewOffset;
    }

    viewWillDisappear(animated?){
        super.viewWillDisappear(animated);
        this.tableViewOffset = this.tableView.contentOffset;
    }

    numberOfSections(tableview:UITableView){
        //return this.fetchedResultsController.sections.length;
        return this._filterItems.length;
    }
    
    numberOfRowsInSection(tableview:UITableView, section:number){
        // let sec = this.fetchedResultsController.sections[section];
        // return sec.numberOfObjects();
        let item = this._filterItems[section];
        let rows = item["Rows"];
        return rows.length;
    }

    // titleForHeaderInSection(tableView:MUITableView, section:number) {
    //     // let ip = MIOIndexPath.indexForRowInSection(0, section);
    //     // let item = this.fetchedResultsController.objectAtIndexPath(ip) as ManagerSection;
    //     let item = this._filterItems[section];        
    //     return MIOLocalizeString(item["Name"], item["Name"]);
    // }

    viewForHeaderInSection(tableview:UITableView, section:number){

        let item = this._filterItems[section];        
        let name = item["Name"];
        if (name == null || name.length == 0) return null;

        let newLayer = this.header.layer.cloneNode(true);
        newLayer.style.display = "";            
        let h = new MUIView();
        h.initWithLayer(newLayer, this);
        h.awakeFromHTML();                                    
        h.setHeight(56);
        h.layer.style.position = "relative";
        let label = h.viewWithTag(1) as MUILabel;
        label.text = MIOLocalizeString(item["Name"], item["Name"]);

        return h;
    }

    
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){        
        let cell = tableview.dequeueReusableCellWithIdentifier('SectionCell') as SectionCell;
        
        //let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ManagerSection;                        
        let section = this._filterItems[indexPath.section];
        let rows = section["Rows"];
        let item = rows[indexPath.row] as ManagerSection;
        cell.item = item;

        cell.selected = this.selectedItem == item ? true : false;
        return cell;
    }

    private lastSelectedItem:ManagerSection = null;
    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){        
        if (this.selectedItem != null) this.lastSelectedItem = this.selectedItem;
        //this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath) as ManagerSection;
        let section = this._filterItems[indexPath.section];
        let rows = section["Rows"];
        this.selectedItem = rows[indexPath.row] as ManagerSection;

        MIONotificationCenter.defaultCenter().postNotification("ShowSection", this.selectedItem);
        this.dismissViewController(true);
    }

    private showLastSection(){  
        this.selectedItem = this.lastSelectedItem;      
        this.lastSelectedItem = null;
        MIONotificationCenter.defaultCenter().postNotification("ShowSection", this.selectedItem);
        this.dismissViewController(true);
    }
         
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('ManagerSection');
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('moduleIndex', true),
                                        MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true)];
    
        let predicateValues = [];
        // predicateValues.push("bitPosition & " + SettingsHelper.sharedInstance().permisisons + " > 0");        
        if (ad.selectedUser.isBeta == false) predicateValues.push( "isBeta = false" );

        if (this.searchString != null) {
            predicateValues.push("name CONTAINS '" + this.searchString + "'");
        }

        if (MIOCoreIsPhone() == true) {
            predicateValues.push("isPhone == true");
        }   
        
        if (predicateValues.length > 0) fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateValues.join(" AND "));

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "moduleIndex");
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    // Filter items
    private showAll = false;
    private _filterItems = [];
    private filterItems(objects){
        let permisisons = SettingsHelper.sharedInstance().permisisons;        
        let currentSectionName = null;
        let currentSection = {"Name": null, "Rows" : []};
        this._filterItems = [];        
        for (let index = 0; index < objects.count; index++){            
            let section = objects[index] as ManagerSection;
            if (currentSectionName != section.module) { 
                if (currentSection != null) {
                    let rws = currentSection["Rows"];
                    if (rws.length > 0) this._filterItems.addObject(currentSection);
                }
                currentSectionName = section.module;
                currentSection = {"Name": section.module, "Rows" : []};
            }
            let bitIndex = section.bitPosition;
            let value = permisisons[bitIndex];
            if (value == "1" || this.showAll == true) {
                let rows = currentSection["Rows"];
                rows.addObject(section);
            }
        }
        
        if (currentSection != null){
            let rows = currentSection["Rows"];
            if (rows.length > 0) this._filterItems.addObject(currentSection);
        }
    }

    protected invalidateData(){
        super.invalidateData();
        this.tableViewOffset = null;
    }

    protected reloadData(){        
        this.filterItems(this.fetchedResultsController.fetchedObjects);        
        this.tableView.reloadData();
    }
    
    //
    // Transitioning delegate
    //

    private showAnimationController = null;
    private hideAnimationController = null;
    animationControllerForPresentedController(presentedViewController, presentingViewController, sourceController){
        if (this.showAnimationController == null) {
            this.showAnimationController = new SectionListShowAnimationController();
            this.showAnimationController.init();
        }

        return this.showAnimationController;
    }

    animationControllerForDismissedController(dismissedController)    {
        if (this.hideAnimationController == null) {
            this.hideAnimationController = new SectionHideShowAnimationController();
            this.hideAnimationController.init();
        }

        return this.hideAnimationController;
    }

}

//
//    ANIMATIONS
//

class SectionListShowAnimationController extends MIOObject
{
    transitionDuration(transitionContext){
        return 0.25;
    }

    animateTransition(transitionContext){
        // make view configurations before transitions
        let view = transitionContext["presentedView"] as MUIView;

        // view.setX(0);
        // view.layer.style.top = "30px";
        // view.layer.style.height = "100%";
    }

    animationEnded(transitionCompleted){
        // make view configurations after transitions
    }

    // TODO: Not iOS like transitions. For now we use css animations
    animations(transitionContext){
        let animations = MUIClassListForAnimationType(MUIAnimationType.SlideInLeft);
        return animations;
    }

}

class SectionHideShowAnimationController extends MIOObject
{
    transitionDuration(transitionContext){
        return 0.25;
    }

    animateTransition(transitionContext){
        // make view configurations after transitions
    }

    animationEnded(transitionCompleted){
        // make view configurations before transitions
    }

    // TODO: Not iOS like transitions. For now we use css animations
    animations(transitionContext){
        let animations = MUIClassListForAnimationType(MUIAnimationType.SlideOutLeft);
        return animations;
    }

}