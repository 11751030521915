//
// Generated class OfferRule
//

/// <reference path="OfferRule_ManagedObject.ts" />

enum OfferRuleConditionType{
    None,
    IsPartyActive,
    IsDayEqualTo
}

class OfferRule extends OfferRule_ManagedObject
{

}
