//
// Generated class ProductLine
//

/// <reference path="ProductLine_ManagedObject.ts" />

class ProductLine extends ProductLine_ManagedObject
{

}
