//
// Generated class LedgerAccountBalance
//

/// <reference path="LedgerAccountBalance_ManagedObject.ts" />

class LedgerAccountBalance extends LedgerAccountBalance_ManagedObject
{

}
