//
// Generated class SupplierOrderLine
//

/// <reference path="SupplierOrderLine_ManagedObject.ts" />

class SupplierOrderLine extends SupplierOrderLine_ManagedObject
{

}
