

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ProductInventory_ManagedObject

class ProductInventory_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: minMeasureUnitType
    set minMeasureUnitType(value:number) {
        this.setValueForKey(value, 'minMeasureUnitType');
    }
    get minMeasureUnitType():number {
        return this.valueForKey('minMeasureUnitType');
    }
    set minMeasureUnitTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minMeasureUnitType');
    }
    get minMeasureUnitTypePrimitiveValue():number {
        return this.primitiveValueForKey('minMeasureUnitType');
    }

    // Property: minTotalQuantity
    set minTotalQuantity(value:number) {
        this.setValueForKey(value, 'minTotalQuantity');
    }
    get minTotalQuantity():number {
        return this.valueForKey('minTotalQuantity');
    }
    set minTotalQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minTotalQuantity');
    }
    get minTotalQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('minTotalQuantity');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: productContainerMeasureType
    set productContainerMeasureType(value:number) {
        this.setValueForKey(value, 'productContainerMeasureType');
    }
    get productContainerMeasureType():number {
        return this.valueForKey('productContainerMeasureType');
    }
    set productContainerMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productContainerMeasureType');
    }
    get productContainerMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('productContainerMeasureType');
    }

    // Property: productContainerQuantity
    set productContainerQuantity(value:number) {
        this.setValueForKey(value, 'productContainerQuantity');
    }
    get productContainerQuantity():number {
        return this.valueForKey('productContainerQuantity');
    }
    set productContainerQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productContainerQuantity');
    }
    get productContainerQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('productContainerQuantity');
    }

    // Property: productMeasureType
    set productMeasureType(value:number) {
        this.setValueForKey(value, 'productMeasureType');
    }
    get productMeasureType():number {
        return this.valueForKey('productMeasureType');
    }
    set productMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productMeasureType');
    }
    get productMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('productMeasureType');
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
    // Relationship: stockConsumptionAnnotation
    set stockConsumptionAnnotation(value:StockConsumptionAnnotation) {
        this.setValueForKey(value, 'stockConsumptionAnnotation');
    }
    get stockConsumptionAnnotation():StockConsumptionAnnotation {
        return this.valueForKey('stockConsumptionAnnotation') as StockConsumptionAnnotation;
    }
}
