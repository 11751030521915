/**
 * Created by miguel on 10/2/17.
 */




class AddNewProductModifierViewController extends MUIViewController
{
    private tableView:MUITableView = null;
    private _fetchedResultsController:MIOFetchedResultsController = null;
    private selectedSection = -1;
    private selectedRow = -1;
    private _product:Product = null;

    private selectedModifiers:MIOSet = MIOSet.set();

    viewDidLoad()
    {
        super.viewDidLoad();

        this.tableView = MUIOutlet(this, 'anpmv_c1_tableview', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

    }

    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
        this.clear();
    }
    /**
     * clear
     */
    private clear() {
        this.selectedRow = -1;
        this.selectedSection = -1;
        
        if(this.viewIsLoaded && this._product != null) 
        {
            this.fetchedResultsController = null;
            this.tableView.reloadData();
        }
    }

    set product(value)
    {
        this._product = value;
        this.clear();
    }
    
    numberOfSections(tableview)
    {
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview, section)
    {
        var sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath)
    {
        var cell:AddNewProductModifierCell = tableview.dequeueReusableCellWithIdentifier("AddNewProductModifierCell");

        var item = this.fetchedResultsController.objectAtIndexPath(indexPath);

        cell.item = item;

        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
        cell.selectionStyle = MUITableViewCellSelectionStyle.None;
        
        if(this._product.productModifiersCategories.containsObject(item)) 
            cell.setAccessoryType(MUITableViewCellAccessoryType.Checkmark);    
        
        return cell;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath)
    {
        var obj = this.fetchedResultsController.objectAtIndexPath(indexPath);

        let isSelected = this._product.productModifiersCategories.containsObject(obj);
        
        if(!isSelected) {
            this._product.addProductModifiersCategoriesObject(obj);
            this.tableView.cellAtIndexPath(indexPath).setAccessoryType(MUITableViewCellAccessoryType.Checkmark);    
        }
        else {
            this._product.removeProductModifiersCategoriesObject(obj);
            this.tableView.cellAtIndexPath(indexPath).setAccessoryType(MUITableViewCellAccessoryType.None);    
        }
        
        //MIONotificationCenter.defaultCenter().postNotification("AddNewProductModifiersCategoryDidChange", this.selectedModifiers);
    }

    set fetchedResultsController(value)
    {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController()
    {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
        
        var ad = MUIWebApplication.sharedInstance().delegate;
        
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('name', true)];
    
        var fetchRequest = DBHelper.listFetchRequestWithEntityName('ProductModifierCategory', sortDescriptors, null);
        var fetchedResultsController = new MIOFetchedResultsController();
        
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;

        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller)
    {
        this.tableView.reloadData();
    }
}