

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class RoomBookingGroup_ManagedObject

class RoomBookingGroup_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: beginDate
    set beginDate(value:Date) {
        this.setValueForKey(value, 'beginDate');
    }
    get beginDate():Date {
        return this.valueForKey('beginDate');
    }
    set beginDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'beginDate');
    }
    get beginDatePrimitiveValue():Date {
        return this.primitiveValueForKey('beginDate');
    }

    // Property: endDate
    set endDate(value:Date) {
        this.setValueForKey(value, 'endDate');
    }
    get endDate():Date {
        return this.valueForKey('endDate');
    }
    set endDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'endDate');
    }
    get endDatePrimitiveValue():Date {
        return this.primitiveValueForKey('endDate');
    }

    // Property: maxCredit
    set maxCredit(value:number) {
        this.setValueForKey(value, 'maxCredit');
    }
    get maxCredit():number {
        return this.valueForKey('maxCredit');
    }
    set maxCreditPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'maxCredit');
    }
    get maxCreditPrimitiveValue():number {
        return this.primitiveValueForKey('maxCredit');
    }

    // Property: referenceID
    set referenceID(value:string) {
        this.setValueForKey(value, 'referenceID');
    }
    get referenceID():string {
        return this.valueForKey('referenceID');
    }
    set referenceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'referenceID');
    }
    get referenceIDPrimitiveValue():string {
        return this.primitiveValueForKey('referenceID');
    }
    // Relationship: client
    set client(value:Client) {
        this.setValueForKey(value, 'client');
    }
    get client():Client {
        return this.valueForKey('client') as Client;
    }

    // Relationship: roomBookings
    protected _roomBookings:MIOManagedObjectSet = null;
    get roomBookings():MIOManagedObjectSet {
        return this.valueForKey('roomBookings');
    }
    addRoomBookingsObject(value:RoomBooking) {
        this._addObjectForKey(value, 'roomBookings');
    }
    removeRoomBookingsObject(value:RoomBooking) {
        this._removeObjectForKey(value, 'roomBookings');
    }
}
