class DBBookingGuestCoder extends DBXLSXCoder
{
    appID:string = null;

    mainEntity() {
        return { entity: "BookingGuest", predicateFormat: "deletedAt == null" }
    }

    columns() : any[] {
        let cols = [
            { title:"NAME", width: "10%", align: "center" },
            { title:"PAX", width: "10%", align: "center", formatter: this.ad.integerFormatter},
            { title:"SOURCE", width: "10%", align: "center" },
            { title:"RECOMMENDED", width: "10%", align: "center" },
            { title:"GUEST LIST ID", width: "10%", align: "center" },
            { title:"GUEST LIST", width: "10%", align: "center" },
            { title:"COMMENTS", width: "10%", align: "center" }

        ]
        return cols;
    }

    aditionalImportEntities(){
        return [            
            { entity: "BookingGuestList", predicateFormat: "deletedAt == null" },            
            { entity: "BookingSource", predicateFormat: "deletedAt == null" },
            { entity: "BookingRecommendation", predicateFormat: "deletedAt == null" }
        ]
    }

    protected importRow(row:any){

        let name = row["NAME"];
        let pax = row["PAX"];
        let sourceName = row["SOURCE"];
        let recomendationName = row["RECOMMENDED"];
        let listID = row["GUEST LIST ID"];
        let listName = row["GUEST LIST"];
        let comments = row["COMMENTS"];
        
        if (name == null) return;
        if (listName == null) listName = "GENERAL";

        let source = this.queryEntityByField("BookingSource", "name", sourceName) as BookingSource;
        let list = this.queryEntityByField("BookingGuestList", "name", listName) as BookingGuestList;

        let recomendation = this.queryEntityByField("BookingRecommendation", "identifier", recomendationName) as BookingRecommendation;
        if (recomendation == null && recomendationName != null) {
            recomendation = MIOEntityDescription.insertNewObjectForEntityForName("BookingRecommendation", this.ad.managedObjectContext) as BookingRecommendation;
            recomendation.identifier = this.parseOrCreateIdentifier(listID);
            recomendation.name = recomendationName;
            this.appendObject(recomendation);
        }                    

        let today = new Date();
        let today_string = MIODateGetDateString( today );        

        let guest = this.queryEntity("BookingGuest", "name ='" + name + "' and date >= '" + today_string + " 00:00'") as BookingGuest;                
        // let guest = this.queryEntityByField("BookingGuest", "name", name) as BookingGuest;                
        if (guest == null) {
            guest = MIOEntityDescription.insertNewObjectForEntityForName("BookingGuest", DBHelper.mainManagedObjectContext) as BookingGuest;
            guest.identifier = MIOUUID.UUID().UUIDString;
            this.appendObject(guest);
        }
        
        guest.name = name;
        guest.source = source;
        guest.comments = recomendationName;
        guest.pax = pax;
        guest.guestList = list;
        guest.comments = comments;
        guest.date = today;
        guest.day = today_string;
        guest.recommendation = recomendation;
        guest.recommendationName = recomendation?.name;
        guest.status = 1;

        MIOLog("BOOKING GUEST: " + this.rowIndex + "/" + this.rows.length + ": " + guest.name);
    }

    protected exportTitle() : string { return "BookingGuest"; }

    protected exportSortDescriptors() { 
        return [
            MIOSortDescriptor.sortDescriptorWithKey("name", false)
        ]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let g = object as BookingGuest;

        let item = {
            "NAME": g.name,
            "PAX": g.pax,
            "SOURCE": g.source?.name,
            "RECOMMENDED": g.recommendation?.name,
            "GUEST LIST ID": g.guestList?.identifier,
            "GUEST LIST": g.guestList?.name,
        };

        return item;
    }
}



