//
// Generated class Place
//

/// <reference path="Place_ManagedObject.ts" />

class Place extends Place_ManagedObject
{

}
