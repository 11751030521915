

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class DeliveryRoute_ManagedObject

class DeliveryRoute_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: deliveryFee
    set deliveryFee(value:number) {
        this.setValueForKey(value, 'deliveryFee');
    }
    get deliveryFee():number {
        return this.valueForKey('deliveryFee');
    }
    set deliveryFeePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'deliveryFee');
    }
    get deliveryFeePrimitiveValue():number {
        return this.primitiveValueForKey('deliveryFee');
    }

    // Property: deliveryFeeIfLessThanMin
    set deliveryFeeIfLessThanMin(value:number) {
        this.setValueForKey(value, 'deliveryFeeIfLessThanMin');
    }
    get deliveryFeeIfLessThanMin():number {
        return this.valueForKey('deliveryFeeIfLessThanMin');
    }
    set deliveryFeeIfLessThanMinPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'deliveryFeeIfLessThanMin');
    }
    get deliveryFeeIfLessThanMinPrimitiveValue():number {
        return this.primitiveValueForKey('deliveryFeeIfLessThanMin');
    }

    // Property: dynamicPrice
    set dynamicPrice(value:boolean) {
        this.setValueForKey(value, 'dynamicPrice');
    }
    get dynamicPrice():boolean {
        return this.valueForKey('dynamicPrice');
    }
    set dynamicPricePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'dynamicPrice');
    }
    get dynamicPricePrimitiveValue():boolean {
        return this.primitiveValueForKey('dynamicPrice');
    }

    // Property: minDeliveryAmount
    set minDeliveryAmount(value:number) {
        this.setValueForKey(value, 'minDeliveryAmount');
    }
    get minDeliveryAmount():number {
        return this.valueForKey('minDeliveryAmount');
    }
    set minDeliveryAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minDeliveryAmount');
    }
    get minDeliveryAmountPrimitiveValue():number {
        return this.primitiveValueForKey('minDeliveryAmount');
    }

    // Property: minFreeDeliveryAmount
    set minFreeDeliveryAmount(value:number) {
        this.setValueForKey(value, 'minFreeDeliveryAmount');
    }
    get minFreeDeliveryAmount():number {
        return this.valueForKey('minFreeDeliveryAmount');
    }
    set minFreeDeliveryAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minFreeDeliveryAmount');
    }
    get minFreeDeliveryAmountPrimitiveValue():number {
        return this.primitiveValueForKey('minFreeDeliveryAmount');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Relationship: postalCodes
    protected _postalCodes:MIOManagedObjectSet = null;
    get postalCodes():MIOManagedObjectSet {
        return this.valueForKey('postalCodes');
    }
    addPostalCodesObject(value:DeliveryPostalCode) {
        this._addObjectForKey(value, 'postalCodes');
    }
    removePostalCodesObject(value:DeliveryPostalCode) {
        this._removeObjectForKey(value, 'postalCodes');
    }
}
