

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class OrderQuestionOptions_ManagedObject

class OrderQuestionOptions_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: optionsName
    set optionsName(value:string) {
        this.setValueForKey(value, 'optionsName');
    }
    get optionsName():string {
        return this.valueForKey('optionsName');
    }
    set optionsNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'optionsName');
    }
    get optionsNamePrimitiveValue():string {
        return this.primitiveValueForKey('optionsName');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: title
    set title(value:string) {
        this.setValueForKey(value, 'title');
    }
    get title():string {
        return this.valueForKey('title');
    }
    set titlePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'title');
    }
    get titlePrimitiveValue():string {
        return this.primitiveValueForKey('title');
    }
}
