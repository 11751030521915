//
// Generated class Booking_BookingService
//

/// <reference path="Booking_BookingService_ManagedObject.ts" />

class Booking_BookingService extends Booking_BookingService_ManagedObject
{

}
