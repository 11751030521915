//
// Generated class SupplierNote
//

/// <reference path="SupplierNote_ManagedObject.ts" />

class SupplierNote extends SupplierNote_ManagedObject
{

}
