//
// Generated class CashDeskLine
//

/// <reference path="CashDeskLine_ManagedObject.ts" />

enum CashDeskLinePaymentType 
{
    None                 = 0, // Para operaciones en las que no hay pago (por ejemplo invitaciones)
    Standard             = 1, // Pago estándar con cualquier forma de pago (o ninguna)
    EconomicAccount      = 2, // Pago con cuenta de cliente
    LoyaltyAccount       = 3, // Pago con "puntos vip"
    Offer                = 4, // Pago con offer
    // TODO: Review type 5 is not a booking deposit
    Deposit       = 5, // Pago con dinero pre-pagado en la reserva
}

enum CashDeskOperationType 
{
    None               = 0,
    Input              = 1, // 1- Entrada
    Output             = 2, // 2- Salida
    Debt               = 3, // 3- Deuda

    DebtNullify        = 4, // 4- Anulación de deuda

    Invite             = 5, // 5- Invitación (no computa para los totales)
    Deletion           = 6, // 6- Borrado (no computa para los totales)
    Open               = 7, // 7- Apertura de caja (Fondo de caja, entrada de dinero)
    Close              = 8, // 8- Cierre de caja (Efectivo retirado, salida de dinero)
    Transform          = 9, // 9- Transformacion en tickets personalizados (no computa para los totales)
    Tip                = 10, // 10- Propina

    EAMovement         = 13, //13 - Economic account movement (Add or remove funds)

    LAMovement         = 15, //15 - Loyalty account movement

    Tax                = 16, //16 - Tax annotation
    TaxBase            = 17, //17 - Tax Base annotation
    Discount           = 18, //18 - Discount annotation
    People             = 19, //19 - Number of people annotation

    DebtPayment        = 20, // 20 - Pago de Deuda

    Count          = 21, // 21 - Arqueo
    Inbalance      = 22, // 22 - Descuadre
    Adjust         = 23, // 23 - Ajuste de descuadre

    Park           = 24, // 24 - Aparcar ticket
    ParkNullify    = 25, // 25 - Anulación de ticket aparcado
    ParkPayment    = 26, // 26 - Pago de ticket aparcado

	DebtDiscarded  = 27, // 27 - Deuda descartada, incobrable

	CashDeskOperationOpenTickets    = 28, // 28 - Dinero en Tickets abiertos al cerrar la caja
    CashDeskOperationMoveToServer   = 29, // 29 - Pedido movido a otro servidor
    CashDeskOperationCountedTip     = 30,
    CashDeskOperationTipInbalance   = 31,
    CashDeskOperationSubtax         = 33, // Cantidad de subtaxes, sobre CashDeskOperationTaxBase
    CashDeskOperationTipFixed       = 34, // Propina fija (incluida en ventas del ticket)
    CashDeskOperationEAAdvancePayment = 35, // Pago con cuenta cliente facturada previamente
    DebtCancelled = 36,

    CashDeskOperationDebtCancelled  = 44, //No se esta utilizando. Se esta utilizando discarded
    
    CashDeskOperationDebtPaymentTip = 45,
    CashDeskOperationDebtNullifyTip = 46,
    CashDeskOperationDebtDiscardedTip = 47,
    CashDeskOperationDebtTip        = 48,

    CashDeskOperationExtraction  = 51, // Extraccion
};

class CashDeskLine extends CashDeskLine_ManagedObject
{
    private typeStrings = [
            "NA",
            "PAY IN", 
            "PAY OUT", 
            "DEBT", 
            "DEBT NULLIFY", 
            "COMPLEMENTARY", 
            "DELETED", 
            "OPEN CASHDESK", 
            "CLOSE CASHDESK", 
            "TICKET TRASNFORM", 
            "TIP", 
            "RESERVED",
            "RESERVED",  
            "ACCOUNT MOVEMENT",
            "RESERVED",
            "LOYALTY ACCOUNT", 
            "TAX", 
            "BASE AMOUNT", 
            "DISCOUNT", 
            "PAX", 
            "DEBT PAY", 
            "CASHDESK COUNT", 
            "INBALANCE", 
            "INBALANCE ADJUST", 
            "PARK TICKET",
            "PARK TICKET NULLIFY",
            "PARK TICKET PAY",
            "DEBT DISCARD",
            "OPEN TICKET AMOUNT",
            "ORDER MOVED TO OTHER MASTER",
            "COUNTED TIP",
            "TIP INBALANCE",
            "RESERVED",
            "SUBTAXES",
            "FIXED TIP",
            "DEPOSIT INVOICE",
            "DEBT CANCELED",
            "DEBT PAYMENT TIP",
            "DEBT NULLIFY TIP",            
            "DISCARDED TIP",
            "DEBT TIP",
            "RESERVED", // 49
            "RESERVED", // 50         
            "EXTRACTION"                    
        ];

    typeString(){
        let type = this.typeStrings[this.type];
        return MIOLocalizeString(type, type);
    }

    get amount():number {
        switch (this.type){
            case CashDeskOperationType.Output:
            case CashDeskOperationType.DebtNullify:
            return -this.money;                
            
            default: return this.money;
        }
    }

}
