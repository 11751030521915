

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class EmployeeLocation_ManagedObject

class EmployeeLocation_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: latitude
    set latitude(value:number) {
        this.setValueForKey(value, 'latitude');
    }
    get latitude():number {
        return this.valueForKey('latitude');
    }
    set latitudePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'latitude');
    }
    get latitudePrimitiveValue():number {
        return this.primitiveValueForKey('latitude');
    }

    // Property: longitude
    set longitude(value:number) {
        this.setValueForKey(value, 'longitude');
    }
    get longitude():number {
        return this.valueForKey('longitude');
    }
    set longitudePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'longitude');
    }
    get longitudePrimitiveValue():number {
        return this.primitiveValueForKey('longitude');
    }

    // Property: timestamp
    set timestamp(value:Date) {
        this.setValueForKey(value, 'timestamp');
    }
    get timestamp():Date {
        return this.valueForKey('timestamp');
    }
    set timestampPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'timestamp');
    }
    get timestampPrimitiveValue():Date {
        return this.primitiveValueForKey('timestamp');
    }
    // Relationship: employee
    set employee(value:Employee) {
        this.setValueForKey(value, 'employee');
    }
    get employee():Employee {
        return this.valueForKey('employee') as Employee;
    }
}
