

class ReportTimeFilterCell extends MUITableViewCell
{
    delegate = null;
    private _filter = null;

    private hourTextField = null;
    private minutesTextField = null;
    private dateButton = null;
    private titleLabel = null;
    private _date = new Date();
    
    
    private tf = MUIWebApplication.sharedInstance().delegate.timeFormatter;
    
    awakeFromHTML()
    {
        this.titleLabel = MUIOutlet(this, 'rfv_c5_lbl','MUILabel');
        
        this.hourTextField = MUIOutlet(this, 'rfv_c5_hour', 'MUITextField');
        this.hourTextField.text = "00:00";
        this.hourTextField.formatter = this.tf;
        this.hourTextField.setOnChangeText(this, function(control, text){

            if (this._date != null){
                let time = this.tf.dateFromString(this.hourTextField.text);
                this._date.setHours(time ? time.getHours() : 0 );
                this._date.setMinutes(time ? time.getMinutes() : 0 );
                this._date.setSeconds(0);
                this.didChangeTimeValue();
            }
        });

        /*this.minutesTextField = MUIOutlet(this, 'rfv_c5_minute', 'MUITextField');
        this.minutesTextField.text = "00";
        this.minutesTextField.formatter = this.nf;
        this.minutesTextField.setOnChangeText(this, function(control, text){

            if (this._date != null){

                this._date.setMinutes(this.nf.numberFromString(this.minutesTextField.text) || 0);
                this.didChangeTimeValue();
            }
        });*/
    }

    set filter(filter){

        this._filter = filter;
        this.titleLabel.text = filter["title"] + ":";
    }

    private didChangeTimeValue(){
    
        if (this.delegate != null)
            this.delegate.didChangeTimeValue.call(this.delegate, this, this._date, this._filter);
                
    }
}