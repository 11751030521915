interface WebService
{
    downloadReport( reportID:string, format:string, reportName:string, beginDate:Date, endDate:Date, filename:string, filters:any ) : void ;
    downloadCashDeskSesion( sesion:CashDeskSession, format:string ) : void ;
}

WebService.prototype.downloadReport = function( reportID:string, format:string = "xlsx", reportName:string, beginDate:Date, endDate:Date, filename:string, filters:any ) : void
{         
    let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

    let begin_date_str = ad.serverDateTimeFormatter.stringFromDate( beginDate );
    let end_date_str = ad.serverDateTimeFormatter.stringFromDate( endDate );    

    let body = { 
        "BusinessName": ad.selectedBusinessName,
        "ReportName" : reportName,
        "DateInterval": begin_date_str + " - " + end_date_str,
        "filters": { "__report_output_type__": 1 }
    }; 

    if ( filters != null ){
        for ( let key in filters ){
            let value = filters[key];
            body["filters"][key] = value != null ? filters[key] : "NULL";
        }
    }

    if ( filters["session_date"] == null ) {
        filters["session_date"] = begin_date_str + "," + end_date_str;
    }


    let cmd = new WebServiceCommand(this.templateURL, "/schema/" + this.scheme + "/report/report-id/" + reportID + "/" + format, true, null, filename);
    cmd.method = "POST";
    cmd.params = body;

    cmd.execute(this, function ( code:number, data:any ) {
        //TODO: Show error
        if (code != 200 ) {
            AppHelper.showErrorMessage(null, "ERROR", "COULDN'T EXPORT FILE");
        }
    });   
}

WebService.prototype.downloadCashDeskSesion = function( sesion:CashDeskSession, format:string = "xlsx" ) {
                   
    let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
    let date_str = ad.serverDateTimeFormatter.stringFromDate( sesion.serverSessionBeginDate );
    let filename = date_str + " " + sesion.cashDesk.name;

    this.downloadReport( "99000000-0000-2222-0001-000000000008", format, MIOLocalizeString( "CASHDESK SESSION REPORT", "CASHDESK SESSION REPORT" ), sesion.beginDate, sesion.endDate,  filename, { "id_session": sesion.identifier } );
}
