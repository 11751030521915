class WarehouseSelectGlobalActionsViewController extends MUIViewController
{  
    private closeButton:MUIButton = null;
    private selectButton:MUIButton = null;
    private tableView:UITableView = null;

    private selectedWarehouses:Warehouse[] = [];

    public delegate = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.closeButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.closeButton.setAction(this, function (){            
            this.dismissViewController(true);
        });

        this.selectButton = MUIOutlet(this, "select-btn", "MUIButton");
        this.selectButton.setAction(this, function (){            

        });
        
        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        this.tableView.allowsMultipleSelection = true;
    }

    viewWillAppear(){
        super.viewWillAppear();
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableView:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
                                              
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){        
        let cell = tableView.dequeueReusableCellWithIdentifier("GlobalActionsLabelCell") as GlobalActionsLabelCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Warehouse;                      
        cell.item = item;
        return cell;
    }   

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){ 

        
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        var ad = MUIWebApplication.sharedInstance().delegate;
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('name', true)];
    
        var fetchRequest = DBHelper.listFetchRequestWithEntityName('Warehouse', sortDescriptors, null);
        var fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }

  
}
