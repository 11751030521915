/// <reference path="PaymentEntityCell.ts" />


class RedsysPaymentEntityCell extends PaymentEntityCell
{    
    private merchantTextField:MUITextField = null;
    private terminalTextField:MUITextField = null;
    private secretTextField:MUITextField = null;
    private actionTextField:MUITextField = null;
    private okTextField:MUITextField = null;
    private koTextField:MUITextField = null;
    
    awakeFromHTML(){     
        super.awakeFromHTML();

        this.merchantTextField = MUIOutlet(this, "merchant-code-tf", "MUITextField");
        this.merchantTextField.setOnChangeText(this, function(textField:MUITextField, value:string) {
            this._config["MERCHANTCODE"] = value.trim().length > 0 ? value : null;
            this._item.setConfigValues(this._config);
        });
        
        this.terminalTextField = MUIOutlet(this, "terminal-tf", "MUITextField");
        this.terminalTextField.setOnChangeText(this, function(textField:MUITextField, value:string) {
            this._config["TERMINAL"] = value.trim().length > 0 ? value : null;
            this._item.setConfigValues(this._config);
        });
        
        this.secretTextField = MUIOutlet(this, "secret-tf", "MUITextField");
        this.secretTextField.setOnChangeText(this, function(textField:MUITextField, value:string) {
            this._config["SECRET_KEY"] = value.trim().length > 0 ? value : null;
            this._item.setConfigValues(this._config);
        });

        this.actionTextField = MUIOutlet(this, "action-tf", "MUITextField");        
        this.actionTextField.setOnChangeText(this, function(textField:MUITextField, value:string) {
            this._config["ACTION_URL"] = value.trim().length > 0 ? value : null;
            this._item.setConfigValues(this._config);
        });

        this.okTextField = MUIOutlet(this, "ok-tf", "MUITextField");        
        this.okTextField.setOnChangeText(this, function(textField:MUITextField, value:string) {
            this._config["OK_URL"] = value.trim().length > 0 ? value : null;
            this._item.setConfigValues(this._config);
        });

        this.koTextField = MUIOutlet(this, "ko-tf", "MUITextField");        
        this.koTextField.setOnChangeText(this, function(textField:MUITextField, value:string) {            
            this._config["KO_URL"] = value.trim().length > 0 ? value : null;
            this._item.setConfigValues(this._config);
        });

    } 
    
    private _config = null;
    set item(item:PaymentEntity){        
        this.setItem(item); 
                
        this._config = item.configValues();
        this.merchantTextField.text = this._config["MERCHANTCODE"];
        this.terminalTextField.text = this._config["TERMINAL"];
        this.secretTextField.text = this._config["SECRET_KEY"];
        this.actionTextField.text = this._config["ACTION_URL"];        
        this.okTextField.text = this._config["OK_URL"];        
        this.koTextField.text = this._config["KO_URL"];
    }

}