
/// <reference path="../base/BaseTableViewController.ts" />

/// <reference path="ReportFilterViewController.ts" />

class ReportListViewController extends BaseTableViewController
{    
    viewDidLoad(){
        super.viewDidLoad();

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        
    }        
    
    titleForHeaderInSection(tableview, section){
        let ip:MIOIndexPath = MIOIndexPath.indexForRowInSection(0, section); 
        let item = this.fetchedResultsController.objectAtIndexPath(ip) as LocalizedName;
        return MIOLocalizeString(item.report.section.toUpperCase(), item.report.section.toUpperCase());
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('ReportListCell') as ReportListCell;
    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as LocalizedName;
        cell.item = item;
        cell.selected = (this.selectedItem?.identifier == item.identifier);    

        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as LocalizedName;        
        this.selectedItem = item.report;
        
        let rvc = new ReportViewController("report-detail-view");
        rvc.initWithResource("layout/reports/ReportDetailView.html");            
        rvc.showSectionButton = false;
        rvc.showBackButton = false;
        
        if (MIOCoreIsPhone() == true) {
            rvc.showSplitBackButton = true;
            rvc.splitBackButtonTarget = this.splitViewController.displayModeButtonItem.target;
            rvc.splitBackButtonCompletion = this.splitViewController.displayModeButtonItem.action;            
        }

        rvc.report = item.report;

        let nc = new MUINavigationController();
        nc.initWithRootViewController(rvc);
        
        this.splitViewController.showDetailViewController(nc);    
    }

    set fetchedResultsController(value){
        this.setFetchedResultsController(value);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('LocalizedName');
        fetchRequest.relationshipKeyPathsForPrefetching = ["report"];
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('report.section', true),
                                        MIOSortDescriptor.sortDescriptorWithKey('report.orderIndex', true),
                                        MIOSortDescriptor.sortDescriptorWithKey('name', true)];

        // let predicateFormat = null;
        let lang = SettingsHelper.sharedInstance().currentSupportedLanguage;
        let predicateFormat = "report != null and report.visible == true and language = '" + lang + "'";
        let hidden_reports = ad.selectedUser.hiddenReportIDs();
        if ( hidden_reports.length > 0 ) {
            predicateFormat += " and report.identifier not in ['" + hidden_reports.join("','") + "']"
        }
        
        if (this.searchString != null) predicateFormat += " AND name CONTAINS '" + this.searchString + "'";
        if (predicateFormat != null) fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, 'report.section');
        fetchedResultsController.delegate = this;        

        fetchedResultsController.performFetch(); 
        
        this._fetchedResultsController = fetchedResultsController;   
        return this._fetchedResultsController;
    }
  
}
