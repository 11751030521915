
class PaymethodCell extends UITableViewCell
{
    private nameTextField:MUITextField = null;
    private typeLabel:MUILabel = null;

    awakeFromHTML(){
        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.nameTextField.setOnChangeText(this, function (control:MUITextField, value:string){
            this._item.name = value;
        });        
        
        this.typeLabel = MUIOutlet(this, "type-lbl", "MUILabel");

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private _item:PayMethod = null;
    set item(item:PayMethod){
        this._item = item;

        this.nameTextField.text = item.name;
        this.typeLabel.text = item.typeString();
    }

    private setupTextField(textField:MUITextField) {
        if (textField == null) return;

        textField.setOnChangeText(this, function (control:MUITextField, value:string){
            this._item.name = value;
        });        
    }



}