

// Generated class BookingLocationPriceRule_ManagedObject

class BookingLocationPriceRule_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: minpax
    set minpax(value:string) {
        this.setValueForKey(value, 'minpax');
    }
    get minpax():string {
        return this.valueForKey('minpax');
    }
    set minpaxPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'minpax');
    }
    get minpaxPrimitiveValue():string {
        return this.primitiveValueForKey('minpax');
    }

    // Property: maxpax
    set maxpax(value:string) {
        this.setValueForKey(value, 'maxpax');
    }
    get maxpax():string {
        return this.valueForKey('maxpax');
    }
    set maxpaxPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'maxpax');
    }
    get maxpaxPrimitiveValue():string {
        return this.primitiveValueForKey('maxpax');
    }

    // Property: priceType
    set priceType(value:number) {
        this.setValueForKey(value, 'priceType');
    }
    get priceType():number {
        return this.valueForKey('priceType');
    }
    set priceTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'priceType');
    }
    get priceTypePrimitiveValue():number {
        return this.primitiveValueForKey('priceType');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: createdAt
    set createdAt(value:string) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():string {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():string {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:string) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():string {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():string {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:string) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():string {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():string {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: locationType
    set locationType(value:BookingLocationType) {
        this.setValueForKey(value, 'locationType');
    }
    get locationType():BookingLocationType {
        return this.valueForKey('locationType') as BookingLocationType;
    }
}
