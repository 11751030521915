

class TimeRangeGroupCell extends UITableViewCell
{
    private nameLabel:MUILabel = null
    private startLabel:MUILabel = null
    private endLabel:MUILabel = null
    private durationLabel:MUILabel = null

    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;

    awakeFromHTML(){
        // this.groupTextField = MUIOutlet(this, "group-tf", "MUITextField");
        // this.groupTextField.setOnChangeText(this, function(control, value){
        //     this.item.group = value;
        // });

        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.startLabel = MUIOutlet(this, "start-lbl", "MUILabel");
        this.endLabel = MUIOutlet(this, "end-lbl", "MUILabel");
        this.durationLabel = MUIOutlet(this, "duration-lbl", "MUILabel");

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private _item:TimeRangeGroup = null;
    set item(item:TimeRangeGroup){
        this._item = item;
        this.nameLabel.text = item.name;
        this.startLabel.text = item.startHour;
        this.endLabel.text = item.endHour;
        this.durationLabel.text = this.nf.stringFromNumber(item.duration);
    }
}