//
// Generated class BluetoothPrinter
//

/// <reference path="BluetoothPrinter_ManagedObject.ts" />

class BluetoothPrinter extends BluetoothPrinter_ManagedObject
{

}
