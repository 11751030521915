
class RuleCell extends MUITableViewCell
{
    protected setupChannelComboBox(comboBox:MUIComboBox){
        DBHelper.sharedInstance().addObserverForEntity(this, "BookingChannel", null, null, null, function(objects){
            this.fillBookingChannelComboBox(this.channelComboBox, objects, null);
        });
    }

    protected fillBookingChannelComboBox(comboBox, objects, selectedChannelID){
        comboBox.removeAllItems();
        comboBox.addItem(MIOLocalizeString('NO CHANNEL','No Channel'), -1);  

        for (let i = 0; i < objects.length; i++) {
            let object = objects[i] as BookingChannel;
            comboBox.addItem(object.name, object.identifier);
        }

        if (selectedChannelID != null) comboBox.selectItem(selectedChannelID);
        else comboBox.selectItem("-1");
    }
}