

class GuestlistCell extends MUITableViewCell
{
    nameLabel = null;
    dateFrom = null;
    dateTo = null;

    private _separator = null;

    awakeFromHTML()
    {
        this.nameLabel = MUIOutlet(this, 'glv_tv_list_name_lbl', 'MUILabel');
        this.dateFrom = MUIOutlet(this, 'glv_tv_list_datefrom_lbl', 'MUILabel');
        this.dateTo = MUIOutlet(this, 'glv_tv_list_dateto_lbl', 'MUILabel');
    }
    
    // setSelected(value)
    // {
    //     super.setSelected(value);

    //     if (value == true)
    //         this._separator.setBackgroundRGBColor(0, 191, 191);
    //     else
    //         this._separator.setBackgroundRGBColor(237, 237, 242);
    // }
    
    set item(i)
    {
        this.nameLabel.text = i.name;
        this.dateFrom.text = i.dateFrom;
        this.dateTo.text = i.dateTo;
    }
}
