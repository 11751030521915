

class RememberPassViewController extends MUIViewController
{
    private cancelButton = null;
    private createButton = null;

    private emailTextField = null;

    viewDidLoad()
    {
        super.viewDidLoad();

        this.emailTextField = MUIOutlet(this, 'email-tf', 'MUITextField');
 
        this.cancelButton = MUIOutlet(this, 'cancel-btn','MUIButton');
        this.cancelButton.setAction(this, function(){            
            this.navigationController.popViewController(true);
        });

        this.createButton = MUIOutlet(this, 'recover-btn', 'MUIButton');
        this.createButton.setAction(this, function() {
            let ws = MUIWebApplication.sharedInstance().delegate.webService;

            ws.rememberpass(this.emailTextField.text, "es", this, function(error, result, data) {
                if(result)
                    this.navigationController.popViewController(true);
                else {
                    AppHelper.showErrorMessage(this, "ERROR", MIOLocalizeString('THERE WAS AN ERROR SENDING THE REMINDER','There was an error sending the reminder'));
                }
            });
            
        });

    }
}