

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class LeaveReasonType_ManagedObject

class LeaveReasonType_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: title
    set title(value:string) {
        this.setValueForKey(value, 'title');
    }
    get title():string {
        return this.valueForKey('title');
    }
    set titlePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'title');
    }
    get titlePrimitiveValue():string {
        return this.primitiveValueForKey('title');
    }
}
