/**
 * Created by miguel on 2/2/17.
 */




class ProductFormatComponentViewController extends MUIViewController
{
    private _productFormat = null;

    private _backButton = null;
    private _addButton = null;

    get preferredContentSize()
    {
        return new MIOSize(500, 400);
    }

    viewDidLoad()
    {
        super.viewDidLoad();
    }

    set productFormat(value)
    {
        this._productFormat = value;
        this._updateUI();
    }

    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
        this._updateUI();

    }


    private _updateUI()
    {
        if(!(this.viewIsLoaded && this._productFormat != null)) return;
    }

}