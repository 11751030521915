//
// Generated class ProductCategory
//

/// <reference path="ProductCategory_ManagedObject.ts" />

class ProductCategory extends ProductCategory_ManagedObject
{
    get namePath():string { return this.indentPathForKey( "name", "superCategory" )[0]; }
    get indentLevel():number { return this.indentPathForKey( "name", "superCategory" )[1]; }
}
