class DBProductModifierCategoryCoder extends DBXLSXCoder
{
    protected mainEntity(){
        return { entity: "ProductModifierCategory", predicateFormat: "deletedAt == null", relations:["category"] }
    }

    protected columns() : any[] {    
        let cols = [
            { title: "MODIFIER CATEGORY ID", width: "10%", align: "center" },
            { title: "NAME", width: "10%", align: "center" },
            { title: "MODIFIER CATEGORY TYPE", width: "10%", align: "center", formatter: this.ad.integerFormatter },
            { title: "ALLOW NEGATIVES", width: "10%", align: "center" },
            { title: "IGNORE MODIFIER SOURCE", width: "10%", align: "center" },
            { title: "INCREMENTAL", width: "10%", align: "center" },
            { title: "IS ABSORBENT", width: "10%", align: "center" },
            { title: "INCREMENTAL", width: "10%", align: "center" },
            { title: "IS REQUIRED", width: "10%", align: "center" },
            { title: "LIMIT QUANTITY", width: "10%", align: "center" , formatter: this.ad.integerFormatter},
            { title: "ORDER INDEX", width: "10%", align: "center", formatter: this.ad.integerFormatter },
            { title: "MULTIPLE SELECTION", width: "10%", align: "center" },
            { title: "CATEGORY ID", width: "10%", align: "center" },
            { title: "CATEGORY NAME", width: "10%", align: "center" }
        ]
        return cols;
    }

    protected aditionalImportEntities(){
        return [
            { entity: "ProductCategory", predicateFormat: "deletedAt == null" }
        ]
    }

    protected importRow(row:any){
        
        let mcID = row[ "MODIFIER CATEGORY ID" ];
        let mcName = row[ "NAME" ];
        let type = row[ "MODIFIER CATEGORY TYPE" ];
        let ignore = row[ "IGNORE MODIFIER SOURCE" ];
        let allow = row[ "ALLOW NEGATIVES" ];
        let incremental = row[ "INCREMENTAL" ];
        let isAbsorbent = row[ "IS ABSORBENT" ];        
        let isRequeired = row[ "IS REQUIRED" ];
        let limitQuantity = row[ "LIMIT QUANTITY" ];
        let orderIndex = row[ "ORDER INDEX" ];
        let multipleSelection = row[ "MULTIPLE SELECTION" ];
        let categoryID = row[ "CATEGORY ID" ];
        let categoryName = row[ "CATEGORY NAME" ];
        
        let category = this.queryEntityByIDOrName("ProductCategory", categoryID, categoryName) as ProductCategory;
        
        let mc = this.queryEntityByIDOrName("ProductModifierCategory", mcID, mcName) as ProductModifierCategory;
        if (mc == null){
            mc = MIOEntityDescription.insertNewObjectForEntityForName("ProductModifierCategory", DBHelper.mainManagedObjectContext) as ProductModifierCategory;
            mc.identifier = this.parseOrCreateIdentifier(mcID);
            mc.name = mcName;  
            this.appendObject(mc);
        }
        
        mc.productModifierCategoryType = type;
        mc.ignoreModifierSource = this.parseBoolValue( ignore );
        mc.allowNegatives = this.parseBoolValue( allow );
        mc.incremental = this.parseBoolValue( incremental );
        mc.isAbsorbent = this.parseBoolValue( isAbsorbent );
        mc.isRequired = this.parseBoolValue( isRequeired );
        mc.limitQuantity = limitQuantity;
        mc.orderIndex = orderIndex;
        mc.multipleSelection = this.parseBoolValue( multipleSelection );
        mc.category = category;

        MIOLog("ADDING MODIFIER CATEGORY : " + this.rowIndex + "/" + this.rows.length + ": " + mcName);
    }

    // 
    // Export
    // 
    protected exportTitle(): string { return "modifier categories"; }
    protected exportSortDescriptors() { 
        return [MIOSortDescriptor.sortDescriptorWithKey("name", false)]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let mc = object as ProductModifierCategory;

        let item = {
            "MODIFIER CATEGORY ID" : mc.identifier,
            "NAME" : mc.name,
            "MODIFIER CATEGORY TYPE" : mc.productModifierCategoryType,
            "IGNORE MODIFIER SOURCE" : mc.ignoreModifierSource,
            "ALLOW NEGATIVES" : mc.allowNegatives,
            "INCREMENTAL" : mc.incremental,
            "IS ABSORBENT" : mc.isAbsorbent,
            "IS REQUIRED" : mc.isRequired,
            "LIMIT QUANTITY" : mc.limitQuantity,
            "ORDER INDEX" : mc.orderIndex,
            "MULTIPLE SELECTION" : mc.multipleSelection,
            "CATEGORY ID" : mc.category?.identifier,
            "CATEGORY NAME" : mc.category?.name
        }

        return item;
    }
}