

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class WorkerLocation_ManagedObject

class WorkerLocation_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: latitude
    set latitude(value:number) {
        this.setValueForKey(value, 'latitude');
    }
    get latitude():number {
        return this.valueForKey('latitude');
    }
    set latitudePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'latitude');
    }
    get latitudePrimitiveValue():number {
        return this.primitiveValueForKey('latitude');
    }

    // Property: longitude
    set longitude(value:number) {
        this.setValueForKey(value, 'longitude');
    }
    get longitude():number {
        return this.valueForKey('longitude');
    }
    set longitudePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'longitude');
    }
    get longitudePrimitiveValue():number {
        return this.primitiveValueForKey('longitude');
    }

    // Property: timestamp
    set timestamp(value:number) {
        this.setValueForKey(value, 'timestamp');
    }
    get timestamp():number {
        return this.valueForKey('timestamp');
    }
    set timestampPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'timestamp');
    }
    get timestampPrimitiveValue():number {
        return this.primitiveValueForKey('timestamp');
    }

    // Property: workerID
    set workerID(value:string) {
        this.setValueForKey(value, 'workerID');
    }
    get workerID():string {
        return this.valueForKey('workerID');
    }
    set workerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerID');
    }
    get workerIDPrimitiveValue():string {
        return this.primitiveValueForKey('workerID');
    }
}
