
class DBLocationCategoryCoder extends DBXLSXCoder
{
    protected mainEntity() { 
        return { entity: "LocationCategory", predicateFormat: "deletedAt == null" };
    }

    columns() : any[] {
        let cols = [
            { title: "LOCATION CATEGORY ID", width: "30%", align: "center" },
            { title: "NAME", width: "30%", align: "center" },
            { title: "BACKGROUND TYPE", width: "30%", align: "center" },
            { title: "HIDDEN", width: "30%", align: "center" },
            { title: "IS AVAILABLE", width: "30%", align: "center" },
            { title: "TAGS", width: "30%", align: "center" },
        ]
        return cols;
    }

    protected importRow(row:any){
                
        let identifier  = row["LOCATION CATEGORY ID"]
        let name        = row["NAME"];
        let backgroundType = row["BACKGROUND TYPE"];
        let hidden      = row["HIDDEN"];
        let isAvailable = row["IS AVAILABLE"];
        let tags        = row["TAGS"];

        let category = this.queryEntityByIDOrName("LocationCategory", identifier, name) as LocationCategory;
        if (category == null) {
            category = MIOEntityDescription.insertNewObjectForEntityForName("LocationCategory", DBHelper.mainManagedObjectContext) as LocationCategory;
            category.identifier = this.parseOrCreateIdentifier(identifier);
            this.appendObject(category);
        }

        category.name = name;
        category.backgroundType =  backgroundType;
        category.hidden = this.parseBoolValue(hidden);
        category.isAvailable = this.parseBoolValue(isAvailable);
        category.tags = tags;
        
        MIOLog("ADDING LOCATION CATEGORY: " + this.rowIndex + "/" + this.rows.length + ": " + name);
    }

    protected exportTitle() : string { return "LocationCategories"; }
                
    protected exportSortDescriptors() { 
        return [MIOSortDescriptor.sortDescriptorWithKey("name", false)]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let lc = object as LocationCategory;
        let item = {
            "LOCATION CATEGORY ID": lc.identifier,
            "NAME": lc.name,
            "BACKGROUND TYPE": lc.backgroundType,
            "HIDDEN": lc.hidden,
            "IS AVAILABLE": lc.isAvailable,
            "TAGS": lc.tags    
        }

        return item;
    }
}