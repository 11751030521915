/**
 * Created by miguel on 22/2/17.
 */



 class SelectedDocumentCell extends UITableViewCell
 {
     private numLabel:MUILabel = null;
     private nextDocumentLabel:MUILabel = null;
     private nameLabel:MUILabel = null;
     private priceLabel:MUILabel = null;
 
     private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
 
     awakeFromHTML(){
         this.numLabel = MUIOutlet(this, "doc-id-lbl", "MUILabel");
         this.nextDocumentLabel = MUIOutlet(this, "next-doc-lbl", "MUILabel");
         this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
         this.priceLabel = MUIOutlet(this, "total-lbl", "MUILabel");
         
         this.separatorStyle = UITableViewCellSeparatorStyle.None;
     }
 
     private _item:MIOManagedObject = null;
     set item(i:MIOManagedObject){
         this._item = i;
 
         if (i instanceof ArchivedTicket) {
             this.numLabel.text = i.legalDocumentID ? i.legalDocumentID : i.documentID;
             let totalInvited = i.totalInvited ?? 0;
             let total = i.totalPrice ?? 0;    
             this.priceLabel.text = total == 0 ? this.cf.stringFromNumber(totalInvited) : this.cf.stringFromNumber(total);
         }
         else if (i instanceof ArchivedInvoice) {
             this.numLabel.text = i.legalDocumentID ? i.legalDocumentID : i.documentID;
             let totalInvited = i.totalInvited ?? 0;
             let total = i.totalPrice ?? 0;    
             this.priceLabel.text = total == 0 ? this.cf.stringFromNumber(totalInvited) : this.cf.stringFromNumber(total);
         }
         else if (i instanceof SupplierNote) {
            this.numLabel.text = i.externalReference ?? i.documentID;
            this.priceLabel.text = this.cf.stringFromNumber(i.totalAmount);
         }
     }
 
 }