//
// Generated class IntegratorSyncEntityQueue
//

/// <reference path="IntegratorSyncEntityQueue_ManagedObject.ts" />

class IntegratorSyncEntityQueue extends IntegratorSyncEntityQueue_ManagedObject
{
    get integratorDateString():string{
        return MIODateGetDateString(this.integratorDate);
    }
}
