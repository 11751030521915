//
// Generated class TicketChannel
//

/// <reference path="TicketChannel_ManagedObject.ts" />

class TicketChannel extends TicketChannel_ManagedObject
{

}
