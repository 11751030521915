

// Generated class ConsumptionCostBalance_ManagedObject

class ConsumptionCostBalance_ManagedObject extends MIOManagedObject
{

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: minTotalQuantity
    set minTotalQuantity(value:number) {
        this.setValueForKey(value, 'minTotalQuantity');
    }
    get minTotalQuantity():number {
        return this.valueForKey('minTotalQuantity');
    }
    set minTotalQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minTotalQuantity');
    }
    get minTotalQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('minTotalQuantity');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: productID
    set productID(value:string) {
        this.setValueForKey(value, 'productID');
    }
    get productID():string {
        return this.valueForKey('productID');
    }
    set productIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productID');
    }
    get productIDPrimitiveValue():string {
        return this.primitiveValueForKey('productID');
    }
    // Relationship: stockConsumptionAnnotation
    set stockConsumptionAnnotation(value:StockConsumptionAnnotation) {
        this.setValueForKey(value, 'stockConsumptionAnnotation');
    }
    get stockConsumptionAnnotation():StockConsumptionAnnotation {
        return this.valueForKey('stockConsumptionAnnotation') as StockConsumptionAnnotation;
    }
}
