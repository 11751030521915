class ProductFormatSaleCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;
    private consumptionLabel:MUILabel = null;
    private priceTextField:MUITextField = null;

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.consumptionLabel = MUIOutlet(this, "consumption-lbl", "MUILabel");
        this.priceTextField = MUIOutlet(this, "price-tf", "MUITextField");
        this.priceTextField.formatter = this.cf;
        this.priceTextField.setOnChangeText(this, function(control:MUITextField, value:string){            
            this.productFormat.price = this.cf.numberFromString(value);
        });

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    private productFormat:ProductFormat = null;
    set item(productFormat:ProductFormat){
        this.productFormat = productFormat;                

        this.nameLabel.text = this.productFormat.format?.name;
        this.priceTextField.text = this.cf.stringFromNumber(this.productFormat.price);

        this.consumptionLabel.text = productFormat.totalConsumptionQuantity + " " + MeasureUnits.toString(this.productFormat.totalConsumptionMeasureType);
    }
}