//
// Generated class SalesMenuAnnotation
//

/// <reference path="SalesMenuAnnotation_ManagedObject.ts" />

class SalesMenuAnnotation extends SalesMenuAnnotation_ManagedObject
{

}
