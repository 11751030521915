

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Expense_ManagedObject

class Expense_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: amount
    set amount(value:number) {
        this.setValueForKey(value, 'amount');
    }
    get amount():number {
        return this.valueForKey('amount');
    }
    set amountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'amount');
    }
    get amountPrimitiveValue():number {
        return this.primitiveValueForKey('amount');
    }

    // Property: concept
    set concept(value:string) {
        this.setValueForKey(value, 'concept');
    }
    get concept():string {
        return this.valueForKey('concept');
    }
    set conceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'concept');
    }
    get conceptPrimitiveValue():string {
        return this.primitiveValueForKey('concept');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }
    // Relationship: legalEntity
    set legalEntity(value:LegalEntity) {
        this.setValueForKey(value, 'legalEntity');
    }
    get legalEntity():LegalEntity {
        return this.valueForKey('legalEntity') as LegalEntity;
    }
    // Relationship: legalTax
    set legalTax(value:LegalTax) {
        this.setValueForKey(value, 'legalTax');
    }
    get legalTax():LegalTax {
        return this.valueForKey('legalTax') as LegalTax;
    }
    // Relationship: vatTax
    set vatTax(value:Tax) {
        this.setValueForKey(value, 'vatTax');
    }
    get vatTax():Tax {
        return this.valueForKey('vatTax') as Tax;
    }
}
