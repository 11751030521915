/**
 * Created by miguel on 10/2/17.
 */


class CategoryTagsViewController extends MUIViewController
{
    static newInstance():CategoryTagsViewController {
        let vc = new CategoryTagsViewController("add-new-category-tags-view");
        vc.initWithResource("layout/products/AddNewCategoryTagsView.html");
        return vc;
    }

    private tagView:TagView = null;

    viewDidLoad(){                
        this.tagView = MUIOutlet(this, 'tag-view', 'TagView');
        this.tagView.delegate = this; 
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }
    

    private categoryTags = null;
    private category:ProductCategory = null;
    set item(category:ProductCategory){
        this.category = category;
        this.categoryTags = (this.category.tags == null || this.category.tags?.length == 0) ? [] : this.category.tags.split(',');
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this.category == null) return;

        let predicate = MIOPredicate.predicateWithFormat('type = ' + TagType.ProductOrMenu + " and deletedAt = null");
        DBHelper.queryObjectsWithCompletion("Tag", null, predicate, [], this, function(this:CategoryTagsViewController, objects){
            this.reloadTags(objects);
        });                
    }
    
    private reloadTags(tags){
        this.tagView.removeAll();
        for (let index = 0; index < tags.length; index++){
            let tag = tags[index] as Tag;
            this.tagView.addTag(tag.name);
            if (this.categoryTags.containsObject(tag.name)) {
                this.tagView.selectTagAtIndex(index);
            }
        }
    }

    didSelectTagAtIndex(index, name:string){        
        let i = this.categoryTags.indexOfObject(name);
        if (i < 0){
            this.categoryTags.addObject(name);            
        }
        else {
            this.categoryTags.removeObject(name);
        }

        let array = []
        for (let c of this.categoryTags) {
            if (c == null || c.length == 0) continue;
            array.push (c);
        }

        this.category.tags = array.length > 0 ? array.join(',') : null;
    }

}
