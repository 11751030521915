//
// Generated class FileItem
//

/// <reference path="FileItem_ManagedObject.ts" />

class FileItem extends FileItem_ManagedObject
{

}
