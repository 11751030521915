
class OnlinePlatformViewController extends EntityListViewController
{
    private segmentedControl:MUISegmentedControl = null;
    // private headerView:OnlineOrderListHeaderView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.delegate = this;
        this.tableView.dataSource = this;
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){

        let cellIdentifier = "OnlinePlatformCell";

        let cell = tableview.dequeueReusableCellWithIdentifier(cellIdentifier); //as OnlineOrderListCell;    
        // let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as OnlineOrder;
        
        // cell.item = item;
        return cell;
    }

    set fetchedResultsController(value){
        this.setFetchedResultsController(value);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [
            MIOSortDescriptor.sortDescriptorWithKey("sessionDeliveryDay", false),
            MIOSortDescriptor.sortDescriptorWithKey("sessionDeliveryTime", false)
        ];
        
        let predicateFormat = [];
        predicateFormat.push ( "createdTPVOrderID != null" );
        predicateFormat.push ( "sessionDeliveryDay == '" + MIODateGetDateString( new Date) + "'" );

        // if (ad.selectedIdentifierType == "place") predicateFormat += " AND placeID == '" + ad.selectedIdentifier + "'";
        if(this.searchString != null) {
            predicateFormat.push ( "documentID CONTAINS '" + this.searchString + "'" );
		}
		
		// if (this.filterPredicateFormat != null) {
        //     predicateFormat += " AND " + this.filterPredicateFormat;
        // }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("OnlineOrder", sortDescriptors, predicateFormat.join(" AND "));
        fetchRequest.fetchLimit = 100;
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

}