

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class AppConfiguration_ManagedObject

class AppConfiguration_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: key
    set key(value:string) {
        this.setValueForKey(value, 'key');
    }
    get key():string {
        return this.valueForKey('key');
    }
    set keyPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'key');
    }
    get keyPrimitiveValue():string {
        return this.primitiveValueForKey('key');
    }

    // Property: value
    set value(value:any) {
        this.setValueForKey(value, 'value');
    }
    get value():any {
        return this.valueForKey('value');
    }
    set valuePrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'value');
    }
    get valuePrimitiveValue():any {
        return this.primitiveValueForKey('value');
    }
    // Relationship: posClientDevice
    set posClientDevice(value:PosClientDevice) {
        this.setValueForKey(value, 'posClientDevice');
    }
    get posClientDevice():PosClientDevice {
        return this.valueForKey('posClientDevice') as PosClientDevice;
    }
}
