
class PlaceListCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    private schemaLabel:MUILabel = null;
    private businessIDLabel:MUILabel = null;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.schemaLabel = MUIOutlet(this, "schema-lbl", "MUILabel");
        this.businessIDLabel = MUIOutlet(this, "business-id-lbl", "MUILabel");
    }

    set item(item:Place){
        this.nameLabel.text = item.name;
        this.schemaLabel.text = item.identifier;
        // this.businessIDLabel.text = item.businessID;
    }
}