//
// Generated class BookingLocationPriceRule
//

/// <reference path="BookingLocationPriceRule_ManagedObject.ts" />

class BookingLocationPriceRule extends BookingLocationPriceRule_ManagedObject
{

}
