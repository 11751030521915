class DBProductModifierCoder extends DBXLSXCoder
{
    protected mainEntity(){
        return { entity: "ProductModifier", predicateFormat: "deletedAt == null", relations:["product", "productFormat", "productModifierCategory", "tax"] }
    }

    protected columns() : any[] {    
        let cols = [
            { title: "MODIFIER ID", width: "10%", align: "center" },
            { title: "NAME", width: "10%", align: "center" },
            { title: "CONSUMPTION MEASURE", width: "10%", align: "center", formatter: this.ad.integerFormatter },
            { title: "CONSUMPTION QUANTITY", width: "10%", align: "center", formatter: this.ad.numberFormatter },
            { title: "LOSS MEASURE", width: "10%", align: "center", formatter: this.ad.integerFormatter },
            { title: "LOSS QUANTITY", width: "10%", align: "center", formatter: this.ad.numberFormatter },
            { title: "IS DEFAULT", width: "10%", align: "center" },
            { title: "IS VISIBLE", width: "10%", align: "center" },
            { title: "ORDER INDEX", width: "10%", align: "center", formatter: this.ad.integerFormatter },
            { title: "PRICE FORMAT", width: "10%", align: "center" },
            { title: "PRODUCT MODIFIER COUNT", width: "10%", align: "center", formatter: this.ad.numberFormatter },
            { title: "PRODUCT ID", width: "10%", align: "center" },
            { title: "PRODUCT NAME", width: "10%", align: "center" },
            { title: "PRODUCT MODIFIER CATEGORY ID", width: "10%", align: "center" },
            { title: "PRODUCT MODIFIER CATEGORY NAME", width: "10%", align: "center" },
            { title: "PRODUCT FORMAT ID", width: "10%", align: "center" },
            { title: "PRODUCT FORMAT NAME", width: "10%", align: "center" },
            { title: "TAX ID", width: "10%", align: "center" },
            { title: "TAX NAME", width: "10%", align: "center" }
        ]
        return cols;
    }

    protected aditionalImportEntities(){
        return [
            { entity: "Product", predicateFormat: "deletedAt == null" },
            { entity: "Tax", predicateFormat: "deletedAt == null" },
            { entity: "ProductModifierCategory", predicateFormat: "deletedAt == null" }, 
            { entity: "ProductFormat", predicateFormat: "deletedAt == null" },
        ]
    }

    protected importRow(row:any){
        
        let pmID = row[ "MODIFIER ID" ];
        let pmName = row[ "NAME" ];
        let consumptionMeasure = row[ "CONSUMPTION MEASURE" ];
        let consumptionQuantity = row[ "CONSUMPTION QUANTITY" ];
        let isDefault = row[ "IS DEFAULT" ];
        let isVisible = row[ "IS VISIBLE" ];
        let lossMeasure = row[ "LOSS MEASURE" ];
        let lossQuantity = row[ "LOSS QUANTITY" ];        
        let orderIndex = row[ "ORDER INDEX" ];
        let priceFormat = row[ "PRICE FORMAT" ];
        let modifierCount = row[ "PRODUCT MODIFIER COUNT" ];
        let productID = row[ "PRODUCT ID" ];
        let productName = row[ "PRODUCT NAME" ];
        let productFormatID = row[ "PRODUCT FORMAT ID" ];
        let productModifierCategoryID = row[ "PRODUCT MODIFIER CATEGORY ID" ];
        let productModifierCategoryName = row[ "PRODUCT MODIFIER CATEGORY NAME" ];
        let taxID = row[ "TAX ID" ];
        let taxName = row[ "TAX NAME" ];
    
        let tax = this.queryEntityByIDOrName("Tax", taxID, taxName) as Tax;
        let category = this.queryEntityByIDOrName("ProductModifierCategory", productModifierCategoryID, productModifierCategoryName) as ProductModifierCategory;
        let pf = this.queryEntityByField("ProductFormat", "identifier", productFormatID) as ProductFormat;
        let product = this.queryEntityByIDOrName("Product", productID, productName) as Product;
        
        let pm = this.queryEntityByField("ProductModifier", "identifier", pmID) as ProductModifier;
        if (pm == null){
            pm = MIOEntityDescription.insertNewObjectForEntityForName("ProductModifier", DBHelper.mainManagedObjectContext) as ProductModifier;
            pm.identifier = this.parseOrCreateIdentifier(pmID);
            this.appendObject(pm);
        }
        
        pm.name = pmName;
        pm.consumptionMeasureUnit = consumptionMeasure;
        pm.consumptionQuantity = consumptionQuantity;
        pm.lossMeasureUnit = lossMeasure;
        pm.lossInProduction = lossQuantity;
        pm.isDefault = this.parseBoolValue( isDefault );
        pm.isVisible = this.parseBoolValue( isVisible );
        pm.orderIndex = orderIndex;
        pm.priceFormat = priceFormat;
        pm.productModifierCount = modifierCount;        
        pm.productModifierCategory = category;
        pm.product = product;
        pm.productFormat = pf;
        pm.tax = tax;

        MIOLog("ADDING MODIFIER : " + this.rowIndex + "/" + this.rows.length + ": " + pmName);
    }

    // 
    // Export
    // 
    protected exportTitle(): string { return "product modifiers"; }
    protected exportSortDescriptors() { 
        return [
            MIOSortDescriptor.sortDescriptorWithKey("productModifierCategory.name", false), 
            MIOSortDescriptor.sortDescriptorWithKey("name", false)
        ]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let pm = object as ProductModifier;

        let item = {
            "MODIFIER ID": pm.identifier,
            "NAME": pm.name,
            "CONSUMPTION MEASURE": pm.consumptionMeasureUnit,
            "CONSUMPTION QUANTITY": pm.consumptionQuantity,
            "LOSS MEASURE": pm.lossMeasureUnit,
            "LOSS QUANTITY": pm.lossInProduction,            
            "IS DEFAULT": pm.isDefault,
            "IS VISIBLE": pm.isVisible,
            "ORDER INDEX": pm.orderIndex,
            "PRICE FORMAT": pm.priceFormat,
            "PRODUCT MODIFIER COUNT": pm.productModifierCount,
            "PRODUCT MODIFIER CATEGORY ID": pm.productModifierCategory.identifier,
            "PRODUCT MODIFIER CATEGORY NAME": pm.productModifierCategory.name,
            "PRODUCT ID": pm.product?.identifier,
            "PRODUCT NAME": pm.product?.name,
            "PRODUCT FORMAT ID": pm.productFormat?.identifier,
            "TAX ID": pm.tax?.identifier,
            "TAX NAME": pm.tax?.name
        }

        return item;
    }
}