

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class SupplierProduct_ManagedObject

class SupplierProduct_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: averagePrice
    set averagePrice(value:number) {
        this.setValueForKey(value, 'averagePrice');
    }
    get averagePrice():number {
        return this.valueForKey('averagePrice');
    }
    set averagePricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'averagePrice');
    }
    get averagePricePrimitiveValue():number {
        return this.primitiveValueForKey('averagePrice');
    }

    // Property: computedLastPrice
    set computedLastPrice(value:number) {
        this.setValueForKey(value, 'computedLastPrice');
    }
    get computedLastPrice():number {
        return this.valueForKey('computedLastPrice');
    }
    set computedLastPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'computedLastPrice');
    }
    get computedLastPricePrimitiveValue():number {
        return this.primitiveValueForKey('computedLastPrice');
    }

    // Property: computedLastPriceDate
    set computedLastPriceDate(value:Date) {
        this.setValueForKey(value, 'computedLastPriceDate');
    }
    get computedLastPriceDate():Date {
        return this.valueForKey('computedLastPriceDate');
    }
    set computedLastPriceDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'computedLastPriceDate');
    }
    get computedLastPriceDatePrimitiveValue():Date {
        return this.primitiveValueForKey('computedLastPriceDate');
    }

    // Property: discountString
    set discountString(value:string) {
        this.setValueForKey(value, 'discountString');
    }
    get discountString():string {
        return this.valueForKey('discountString');
    }
    set discountStringPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'discountString');
    }
    get discountStringPrimitiveValue():string {
        return this.primitiveValueForKey('discountString');
    }

    // Property: inputFormatName
    set inputFormatName(value:string) {
        this.setValueForKey(value, 'inputFormatName');
    }
    get inputFormatName():string {
        return this.valueForKey('inputFormatName');
    }
    set inputFormatNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'inputFormatName');
    }
    get inputFormatNamePrimitiveValue():string {
        return this.primitiveValueForKey('inputFormatName');
    }

    // Property: isDefault
    set isDefault(value:boolean) {
        this.setValueForKey(value, 'isDefault');
    }
    get isDefault():boolean {
        return this.valueForKey('isDefault');
    }
    set isDefaultPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isDefault');
    }
    get isDefaultPrimitiveValue():boolean {
        return this.primitiveValueForKey('isDefault');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: productAveragePrice
    set productAveragePrice(value:number) {
        this.setValueForKey(value, 'productAveragePrice');
    }
    get productAveragePrice():number {
        return this.valueForKey('productAveragePrice');
    }
    set productAveragePricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productAveragePrice');
    }
    get productAveragePricePrimitiveValue():number {
        return this.primitiveValueForKey('productAveragePrice');
    }

    // Property: productLastPrice
    set productLastPrice(value:number) {
        this.setValueForKey(value, 'productLastPrice');
    }
    get productLastPrice():number {
        return this.valueForKey('productLastPrice');
    }
    set productLastPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productLastPrice');
    }
    get productLastPricePrimitiveValue():number {
        return this.primitiveValueForKey('productLastPrice');
    }

    // Property: productPrice
    set productPrice(value:number) {
        this.setValueForKey(value, 'productPrice');
    }
    get productPrice():number {
        return this.valueForKey('productPrice');
    }
    set productPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productPrice');
    }
    get productPricePrimitiveValue():number {
        return this.primitiveValueForKey('productPrice');
    }

    // Property: quantity
    set quantity(value:number) {
        this.setValueForKey(value, 'quantity');
    }
    get quantity():number {
        return this.valueForKey('quantity');
    }
    set quantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'quantity');
    }
    get quantityPrimitiveValue():number {
        return this.primitiveValueForKey('quantity');
    }

    // Property: supplierName
    set supplierName(value:string) {
        this.setValueForKey(value, 'supplierName');
    }
    get supplierName():string {
        return this.valueForKey('supplierName');
    }
    set supplierNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'supplierName');
    }
    get supplierNamePrimitiveValue():string {
        return this.primitiveValueForKey('supplierName');
    }

    // Property: supplierReference
    set supplierReference(value:string) {
        this.setValueForKey(value, 'supplierReference');
    }
    get supplierReference():string {
        return this.valueForKey('supplierReference');
    }
    set supplierReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'supplierReference');
    }
    get supplierReferencePrimitiveValue():string {
        return this.primitiveValueForKey('supplierReference');
    }

    // Property: supplierReferenceName
    set supplierReferenceName(value:string) {
        this.setValueForKey(value, 'supplierReferenceName');
    }
    get supplierReferenceName():string {
        return this.valueForKey('supplierReferenceName');
    }
    set supplierReferenceNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'supplierReferenceName');
    }
    get supplierReferenceNamePrimitiveValue():string {
        return this.primitiveValueForKey('supplierReferenceName');
    }
    // Relationship: inputFormat
    set inputFormat(value:StockInputFormat) {
        this.setValueForKey(value, 'inputFormat');
    }
    get inputFormat():StockInputFormat {
        return this.valueForKey('inputFormat') as StockInputFormat;
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
    // Relationship: supplier
    set supplier(value:Supplier) {
        this.setValueForKey(value, 'supplier');
    }
    get supplier():Supplier {
        return this.valueForKey('supplier') as Supplier;
    }
}
