
class LowStockLineCell extends NoteLineCell
{
    private supplierDropdown:MUIButton = null;
    private supplierLabel:MUILabel = null;
    private estimatedLabel:MUILabel = null;
    protected maxStockQuantity = null;
    protected minStockQuantity = null;
    
    awakeFromHTML(){
        super.awakeFromHTML();

        this.supplierDropdown = MUIOutlet(this, "supplier-dd", "MUIButton");    
        this.setupWarehouseDropdown(this.supplierDropdown, this, this.supplierDidSelect);
        this.supplierLabel = MUIOutlet(this, "supplier-lbl", "MUILabel");    

        this.reset();
        
    }

    // setProduct(product:Product){
    //     super.setProduct(product);

    //     this.maxStockQuantity = product.maxStockQuantity;
    //     this.minStockQuantity = product.minStockQuantity; 
    // }

    private supplier:Supplier = null;
    setLine(item:StockNoteLine){
        super.setLine(item);                
        // this.supplier = item.legalEntity;

        if (this.supplierLabel != null) this.supplierLabel.text = item.legalEntityName ? item.legalEntityName : MIOLocalizeString("NONE", "None");
        if (this.supplierDropdown != null) {
            this.supplierDropdown.title = item.legalEntityName ? item.legalEntityName : MIOLocalizeString("NONE", "None");
            this.supplierDropdown.enabled = true;
        }

        this.estimatedLabel.text = this.estimatedQuantityString();
        
    }
    
    productDidSelect(controller:SelectEntityViewController, product:Product){
        // this.setProduct(product);
        
        // this.productTextField.text = this.productNameString();
                
        this.supplierDropdown.enabled = true;
        this.supplierDropdown.title = MIOLocalizeString("NONE", "None");

        this.inputFormatDropdown.enabled = true;
        this.inputFormatDropdown.title = product.defaultInputFormatString();  
        
        //if min and max stock is set, then show estimated quantity
        // if (product.minStockQuantity && product.maxStockQuantity && product.minStockQuantity > product.existences) {
        //     this.estimatedLabel.text = Math.ceil(product.maxStockQuantity - product.existences) + " " + this.inputFormatString();
        // } else {
        //     this.estimatedLabel.text = "N/A";
        // }
        
        this.quantityTextField.enabled = true;
        this.quantityTextField.becomeFirstResponder();
    }
    
    protected setupWarehouseDropdown(button:MUIButton, target, completion){
        if (button == null) return;
        button.setAction(this, function(){
            AppHelper.sharedInstance().showSelectSupplierViewControllerFromView(button, null, true, target, completion);
        });   
    }

    protected supplierDidSelect(controller:SelectEntityViewController, supplier:Supplier){
        this.supplierDropdown.title = supplier.name;
        this.supplier = supplier;

        if (this.stockNoteLine != null) {
            this.stockNoteLine.legalEntity = supplier;
            this.stockNoteLine.legalEntityName = supplier.name;        
        }
    }

    protected inputFormatDidSelect(controller:SelectInputFormatViewController, inputFormat:StockInputFormat, inputType:MeasureUnitType){
        super.inputFormatDidSelect(controller, inputFormat, inputType);        

        this.inputFormatDropdown.title = this.inputFormatString();
        this.productQuantityLabel.text = this.productQuantityString();        
    }
    
    protected quantityDidChange(quantity){        
        super.quantityDidChange(quantity);                
        this.productQuantityLabel.text = this.productQuantityString();
    }

    enterDidPress(){
        if (this.stockNoteLine != null) return;        
        if (this.quantity == 0 || this.quantity == null) return;
                
        let line = DBHelper.createLowStockNoteLine(this.product, this.inputFormat, this.inputType, this.quantity, this.productQuantity, this.supplier, this.stockNote);
        if (line != null) {            
            this.lineDidInserted(line);
            this.reset();
        }
    }
    
    reset(){

        if (this.supplierDropdown != null){
            this.supplierDropdown.title = null;
            this.supplierDropdown.enabled = false;
        }
    }

    private estimatedQuantityString(){
        //if min and max stock is set, then show estimated quantity
        // if (item.product.existences && item.product.minStockQuantity && item.product.maxStockQuantity && item.product.minStockQuantity > item.product.existences) {
    
        //     let itemBaseEstimate = Math.ceil(item.product.maxStockQuantity - item.product.existences);

        //     //sort thought the different measure types
        //     if (item.productMeasureType == 1 || item.productMeasureType == -1) {
        //         this.estimatedLabel.text =  itemBaseEstimate + " " + this.inputFormatString();
        //         if (item.inputFormatQuantity && item.inputFormatQuantity != 0) {
        //             //this.quantityTextField.text = Math.ceil( itemBaseEstimate / item.inputFormatQuantity );
        //             //this.quantity = Math.ceil( itemBaseEstimate / item.inputFormatQuantity );
        //         } else { 
        //             //this.quantityTextField.text = this.nf.stringFromNumber(itemBaseEstimate);
        //             //this.quantity =  itemBaseEstimate;
        //         }

        //     } else if (item.productMeasureType == 2) {
        //         this.estimatedLabel.text =  itemBaseEstimate + " " + this.inputFormatString();

        //     } else if (item.productMeasureType == 3) {
        //         this.estimatedLabel.text =  itemBaseEstimate + " " + this.inputFormatString();
                
        //     } else if (item.productMeasureType == 4) {
        //         this.estimatedLabel.text =  itemBaseEstimate + " " + this.inputFormatString();

        //     } else if (item.productMeasureType == 5) {
        //         this.estimatedLabel.text =  itemBaseEstimate + " " + this.inputFormatString();

        //     }else if (item.productMeasureType == -100) {
        //         this.estimatedLabel.text = "N/A";
                
        //     } else {
        //         this.estimatedLabel.text = "N/A";
        //     }
        // } else {
        //     this.estimatedLabel.text = "N/A";
        // }
        
        return this.nf.stringFromNumber(this.stockNoteLine.estimatedQuantity);
    }
}