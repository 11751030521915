

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ProductFormatRate_ManagedObject

class ProductFormatRate_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }
    // Relationship: productFormat
    set productFormat(value:ProductFormat) {
        this.setValueForKey(value, 'productFormat');
    }
    get productFormat():ProductFormat {
        return this.valueForKey('productFormat') as ProductFormat;
    }
    // Relationship: rate
    set rate(value:Rate) {
        this.setValueForKey(value, 'rate');
    }
    get rate():Rate {
        return this.valueForKey('rate') as Rate;
    }
    // Relationship: tax
    set tax(value:Tax) {
        this.setValueForKey(value, 'tax');
    }
    get tax():Tax {
        return this.valueForKey('tax') as Tax;
    }
}
