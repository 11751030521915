class LicenseTypeViewController extends BaseTableViewController
{
    static newInstance() : LicenseTypeViewController {
        let vc = new LicenseTypeViewController( "license-type-view" );
        vc.initWithResource( "layout/licenses/LicenseTypeView.html" );
        return vc;
    }

    private addButton:MUIButton = null;

    viewDidLoad(): void {
        this.addButton = MUIOutlet( this, "add-btn", "MUIButton");
        this.addButton.setAction( this, this.showAddLicenseTypeAlert );

        this.tableView = MUIOutlet( this, "table-view", "UITableView" );
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as LicenseType;
        
        let cell = tableview.dequeueReusableCellWithIdentifier("LicenseTypeCell") as LicenseTypeCell; 
        cell.item = item;
        
        return cell;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let sds = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName( "LicenseType", sds, null );
        // fetchRequest.relationshipKeyPathsForPrefetching = [""];
        // fetchRequest.fetchLimit = 100;

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    private showAddLicenseTypeAlert() {
        let avc = new MUIAlertViewController();
        avc.initWithTitle("LICENSE TYPE", "LICENSE TYPE", MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(control:MUITextField){
            control.placeholderText = "NAME";
        });

        avc.addComboBoxWithConfigurationHandler(this, function(control:MUIComboBox) {
            DBHelper.sharedInstance().addObserverForEntity(avc, "Rate", MIOPredicate.predicateWithFormat("deletedAt = null"), [MIOSortDescriptor.sortDescriptorWithKey("name", true)], null, function (objects:Rate[]) {
                control.removeAllItems();
                control.addItem( "NO RATE", -1);
                for (let i = 0; i < objects.length; i++){
                    let r = objects[i];                    
                    control.addItem(r.name, i);                    
                }
            });
        });

        avc.addTextFieldWithConfigurationHandler(this, function(control:MUITextField){
            control.placeholderText = "TIME PERIOD";
        });


        avc.addAction( MUIAlertAction.alertActionWithTitle("OK", MUIAlertActionStyle.Default, this, function(this:LicenseTypeViewController){
            let name = avc.textFields[0].text;
            let rateIndex = parseInt(avc.comboBoxes[0].getSelectedItem());
            let rate = rateIndex > -1 ? DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, rateIndex, 'Rate') as Rate : null;
            let period = avc.textFields[1].text;

            this.createLicenseType( name, rate, period );
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle("CANCEL", MUIAlertActionStyle.Cancel, null, null) ); 
        
        avc.addCompletionHandler( this, function(){
            // Release the observers            
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, 'Rate');
        });

        this.presentViewController(avc, true);        
    }

    private createLicenseType(name:string, rate:Rate, period:string ) {

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let p = ad.integerFormatter.numberFromString( period ) ?? 1;

        let type = MIOEntityDescription.insertNewObjectForEntityForName( "LicenseType", ad.managedObjectContext ) as LicenseType;
        type.name = name;
        type.rateName = rate?.name;
        type.rate = rate;        
        type.timePeriod = p;

        DBHelper.saveMainContext();
    }

}