

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class TimeRangePresetConfiguration_ManagedObject

class TimeRangePresetConfiguration_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: closedDates
    set closedDates(value:any) {
        this.setValueForKey(value, 'closedDates');
    }
    get closedDates():any {
        return this.valueForKey('closedDates');
    }
    set closedDatesPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'closedDates');
    }
    get closedDatesPrimitiveValue():any {
        return this.primitiveValueForKey('closedDates');
    }

    // Property: fromDate
    set fromDate(value:Date) {
        this.setValueForKey(value, 'fromDate');
    }
    get fromDate():Date {
        return this.valueForKey('fromDate');
    }
    set fromDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'fromDate');
    }
    get fromDatePrimitiveValue():Date {
        return this.primitiveValueForKey('fromDate');
    }

    // Property: isHoliday
    set isHoliday(value:boolean) {
        this.setValueForKey(value, 'isHoliday');
    }
    get isHoliday():boolean {
        return this.valueForKey('isHoliday');
    }
    set isHolidayPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isHoliday');
    }
    get isHolidayPrimitiveValue():boolean {
        return this.primitiveValueForKey('isHoliday');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: toDate
    set toDate(value:Date) {
        this.setValueForKey(value, 'toDate');
    }
    get toDate():Date {
        return this.valueForKey('toDate');
    }
    set toDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'toDate');
    }
    get toDatePrimitiveValue():Date {
        return this.primitiveValueForKey('toDate');
    }

    // Property: weekdays
    set weekdays(value:number) {
        this.setValueForKey(value, 'weekdays');
    }
    get weekdays():number {
        return this.valueForKey('weekdays');
    }
    set weekdaysPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'weekdays');
    }
    get weekdaysPrimitiveValue():number {
        return this.primitiveValueForKey('weekdays');
    }
    // Relationship: businessArea
    set businessArea(value:BusinessArea) {
        this.setValueForKey(value, 'businessArea');
    }
    get businessArea():BusinessArea {
        return this.valueForKey('businessArea') as BusinessArea;
    }
    // Relationship: channel
    set channel(value:Channel) {
        this.setValueForKey(value, 'channel');
    }
    get channel():Channel {
        return this.valueForKey('channel') as Channel;
    }
    // Relationship: timeRangePreset
    set timeRangePreset(value:TimeRangePreset) {
        this.setValueForKey(value, 'timeRangePreset');
    }
    get timeRangePreset():TimeRangePreset {
        return this.valueForKey('timeRangePreset') as TimeRangePreset;
    }
}
