class WarehouseGlobalActionsViewController extends MUIViewController
{  
    private backButton:MUIButton = null;
    private addButton:MUIButton = null;
    private nextButton:MUIButton = null;
    private tableView:UITableView = null;

    private selectedProductWarehouses:ProductWarehouse = null;

    public delegate = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");
        this.backButton.setAction(this, function (){            
            MIONotificationCenter.defaultCenter().removeObserver(this, "globalActionsDidSelectCategory"); 
            this.navigationController.popViewController(true);
        });

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function (){            
            
            let wsgavc = new WarehouseSelectGlobalActionsViewController("warehouse-select-global-actions-view");
            wsgavc.initWithResource("layout/globalactions/WarehouseSelectGlobalActionsView.html");
            wsgavc.delegate = this;
            wsgavc.setPreferredContentSize(new MIOSize(400, 550));  

            let nc = new MUINavigationController('warehouse_select_global_actions_nav_controller');
            nc.initWithRootViewController(wsgavc);
            this.presentViewController(nc, true);
        });
        
        this.nextButton = MUIOutlet(this, "next-btn", "MUIButton");
        this.nextButton.setAction(this, function (){   

            if (this.selectedProductWarehouses){
                let sgavc = new SelectCategoryActionsViewController('select-category-global-actions-view');
                sgavc.initWithResource("layout/globalactions/SelectCategoryGlobalActionsView.html");
                this.navigationController.pushViewController(sgavc, true);   
            } else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('NO PRODUCTWAREHOUSE SELECTED', 'NO PRODUCTWAREHOUSE SELECTED'), MIOLocalizeString('PLEASE MAKE SELECTION','PLEASE MAKE SELECTION'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                this.presentViewController(error, true);
            }
        });

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(){
        super.viewWillAppear();
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView){
        // return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableView:UITableView, section:number){
        // let sec = this.fetchedResultsController.sections[section];
        // return sec.numberOfObjects();
    }
                                              
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){        
        // let cell = tableView.dequeueReusableCellWithIdentifier("WarehouseGlobalActionsLabelCell") as WarehouseGlobalActionsLabelCell;
        // let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ProductWarehouse;                      
        // cell.item = item;
        // return cell;
    }   

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){ 

        
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        var ad = MUIWebApplication.sharedInstance().delegate;
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('name', true)];
    
        var fetchRequest = DBHelper.listFetchRequestWithEntityName('ProductWarehouse', sortDescriptors, null);
        var fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }

    
}
