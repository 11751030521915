



class AttributeViewController extends MUIViewController
{
    private tableView:MUITableView = null;
    private attributeTypeLabel:MUILabel = null;
    private backButton:MUIButton = null;
    private addButton:MUIButton = null;
    private searchTextField:MUITextField = null;
    private searchString:string = null;

    private _fetchedResultsController:MIOFetchedResultsController = null;

    private _attributeType:ProductAttributeType = null;

    get preferredContentSize()
    {
        return new MIOSize(320, 500);
    }

    viewDidLoad()
    {
        super.viewDidLoad();

        this.tableView = MUIOutlet(this,"av_c1_tableview","MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    
        this.attributeTypeLabel = MUIOutlet(this, 'av_nb_title_lbl', 'MUILabel');

        this.backButton = MUIOutlet(this, 'av_nb_back_btn', 'MUIButton');
        this.backButton.setAction(this, function () {

            this.navigationController.popViewController(true);

        });

        this.searchTextField = MUIOutlet(this,"av_tv_search_bar", "MUITextField", "SearchType");
        this.searchTextField.setOnChangeText(this, function(textfield, value){

            clearTimeout(this.delayTimer);
            var instance = this;
            
            instance.delayTimer = setTimeout(function() {
              
                instance.searchString = instance.searchTextField.text;
                if(instance.searchString != null && instance.searchString.length == 0)
                {
                    instance.searchString = null;
                }
                instance.fetchedResultsController = null;
                instance.tableView.reloadData();
               
            }, 500, instance);
        });

        this.addButton = MUIOutlet(this, 'av_nb_add_btn', 'MUIButton');
        this.addButton.setAction(this, function () {

            this.addAttribute();

        });
    }

    viewWillAppear(animate?) {

        super.viewWillAppear(animate);
        
        this.updateUI();
    }

    set attributeType(value)
    {
        this._attributeType = value;
        this.fetchedResultsController = null;
        this.updateUI();
    }


    private updateUI()
    {
        if (this._attributeType == null) return;
        if (this.viewIsLoaded == false) return;

        this.attributeTypeLabel.text = this._attributeType.name;
        this.tableView.reloadData();
    }

    private addAttribute()
    {
        var avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('ATTRIBUTE','Attribute'), MIOLocalizeString('CHOOSE A NAME','Choose a name '), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){

            textField.setPlaceholderText("Name");
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','Done'), MUIAlertActionStyle.Default, this, function()
        {
            let nameTF =  avc.textFields[0];
            let name = nameTF.text.trim();

            if(name.length > 0)
            {
                let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
                var at:ProductAttribute = MIOEntityDescription.insertNewObjectForEntityForName("ProductAttribute", moc) as ProductAttribute;
                at.identifier = MIOUUID.UUID().UUIDString;
                at.name = name;
                at.productAttributeType = this._attributeType;
                
                at.orderIndex = 1;
                moc.save();
                //this.attribute = at;
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'Error'), MIOLocalizeString('CHECK NAME','Check name.'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, this, function(){}));
                
                this.presentViewController(error, true);
            }
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, this, function(){}));
        
        this.presentViewController(avc, true);
    }

    private removeAttribute(at)
    {
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        moc.deleteObject(at);
        moc.save();
    }

    numberOfSections(tableview)
    {
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section)
    {
        var sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        
        var at = this.fetchedResultsController.objectAtIndexPath(indexPath);    

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            this.removeAttribute(at);
        }
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath)
    {
        var cell:AttributeCell = tableview.dequeueReusableCellWithIdentifier('AttributeCell');
    
        var item:ProductAttribute = this.fetchedResultsController.objectAtIndexPath(indexPath);
    
        cell.item = item;
    
        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
        cell.selectionStyle = MUITableViewCellSelectionStyle.None;
        cell.editing = true;
    
        return cell;
    }
    
    set fetchedResultsController(value)
    {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController()
    {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        var ad = MUIWebApplication.sharedInstance().delegate;
    
        var fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('ProductAttribute');
    
        fetchRequest.sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('orderindex', true),
                                            MIOSortDescriptor.sortDescriptorWithKey('name', true)];
    
        let filters = [];
        filters.push('( productAttributeTypeID == "' + this._attributeType.identifier + '")');
        if(this.searchString != null)
            filters.push('(name CONTAINS "'+ this.searchString+'" )')

        let filterString = filters.join(' AND ');

        fetchRequest.predicate = MIOPredicate.predicateWithFormat(filterString);
        
        
        var fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller)
    {
        this.tableView.reloadData();
    }
}