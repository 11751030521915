//
// Generated class ServerStatus
//

/// <reference path="ServerStatus_ManagedObject.ts" />

class ServerStatus extends ServerStatus_ManagedObject
{

}
