class ConsumptionProfileDetailViewController extends BaseTableViewController
{
    private settingsButton:MUIButton = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.settingsButton = MUIOutlet(this, 'settings-btn', 'MUIButton');
        this.settingsButton.setAction(this, function (){            
            this.showConsumptionProfileSettings();
        }); 

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;   
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);        
        this.updateUI();
    }

    private _application:Application = null;
    private _fetchRequest:MIOFetchRequest = null;
    private _predicate = null;
    private _sortDescriptors = null;
    private _relationship = null;
    set item(value:any){

        if(value instanceof Application){
            // set selected device
            this._application = value;
            this._fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("AppArea");
            this._predicate = null; // MIOPredicate.predicateWithFormat("rate.identifier == " + this._rate.identifier);
            this._sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("areaID", true)]; 
            this._relationship = null; // ["consumptionProfile"];
        } else {

            // No Application selected, use LocationCategory
            this._application = null;
            this._fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("LocationCategory");
            this._predicate = null;
            this._sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            this._relationship = null; // ["consumptionProfile"];
        }

        // query what you need here
        // let relationships = ["address", "phone", "mobilePhone", "accountBalance","loyaltyBalance","debtBalance"];
        // DBHelper.queryObjectsWithCompletion("Client", null, MIOPredicate.predicateWithFormat("identifier == " + value.identifier), relationships, this, function(clients){
        //     this.updateUI();
        // });        

        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        this._fetchedResultsController = null;
        this.tableView.reloadData();            
    }
    
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
        let cell = tableView.dequeueReusableCellWithIdentifier('ConsumptionProfileDetailCell') as ConsumptionProfileDetailCell;        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath); 

        cell.item = item;
        cell.accessoryType = UITableViewCellAccessoryType.None;
        cell.selectionStyle = UITableViewCellSelectionStyle.None;

        return cell;
    }
    
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        //No selection needed
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
        let fetchRequest = this._fetchRequest;
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("areaID", true)]; 
        fetchRequest.predicate = this._predicate;
        if (this._relationship) fetchRequest.relationshipKeyPathsForPrefetching = this._relationship;
                
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    private showConsumptionProfileSettings() {

        //alert("show Consumption Profile Settings");



        let mvc = new EditConsumptionProfileListViewController("edit-consumption-profile-list-view");
        mvc.initWithResource("layout/consumptionprofile/EditConsumptionProfileListView.html");
        
        let dvc = new EditConsumptionProfileDetailViewController("edit-consumption-profile-detail-view");
        dvc.initWithResource("layout/consumptionprofile/EditConsumptionProfileDetailView.html");
        
        //let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("EditAppConsumptionProfile", NoItemSelectedViewControllerType.AppConsumptionProfile);        

        let ecpvc = new MUISplitViewController();
        ecpvc.init();        
        ecpvc.setMasterViewController(mvc);
        ecpvc.setDetailViewController(dvc); 
        ecpvc.setPreferredContentSize(new MIOSize(800, 650));  

        let nc = new MUINavigationController('edit_consumption_profile_nav_controller');
        nc.initWithRootViewController(ecpvc);
        this.presentViewController(nc, true);

        // let moavc = new GlobalActionsViewController("global-actions-view");
        // moavc.initWithResource("layout/globalactions/GlobalActionsView.html");
        // moavc.delegate = this;

        // let nc = new MUINavigationController('global_actions_nav_controller');
        // nc.initWithRootViewController(moavc);
        // this.presentViewController(nc, true);


        // let mvc = ConsumptionProfileListViewController.newInstance();
        // let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("AppConsumptionProfile", NoItemSelectedViewControllerType.AppConsumptionProfile);        

        // this._appConsumptionProfileViewController = new MUISplitViewController();
        // this._appConsumptionProfileViewController.init();        
        // this._appConsumptionProfileViewController.setMasterViewController(mvc);
        // this._appConsumptionProfileViewController.setDetailViewController(dvc); 


        // if (this._appConsumptionProfileViewController != null) return this._appConsumptionProfileViewController;

        // //let mvc = ConsumptionProfileListViewController.newInstance();
        // ///let dvc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("AppConsumptionProfile", NoItemSelectedViewControllerType.AppConsumptionProfile);        

        // this._appConsumptionProfileViewController = new MUISplitViewController();
        // this._appConsumptionProfileViewController.init();        
        // this._appConsumptionProfileViewController.setMasterViewController(mvc);
        // this._appConsumptionProfileViewController.setDetailViewController(dvc);        

        // return this._appConsumptionProfileViewController;  
    }
    

}