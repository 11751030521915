

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class EllaborationCenter_ManagedObject

class EllaborationCenter_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Relationship: ellaborationConsumptionWarehouses
    protected _ellaborationConsumptionWarehouses:MIOManagedObjectSet = null;
    get ellaborationConsumptionWarehouses():MIOManagedObjectSet {
        return this.valueForKey('ellaborationConsumptionWarehouses');
    }
    addEllaborationConsumptionWarehousesObject(value:EllaborationConsumptionWarehouse) {
        this._addObjectForKey(value, 'ellaborationConsumptionWarehouses');
    }
    removeEllaborationConsumptionWarehousesObject(value:EllaborationConsumptionWarehouse) {
        this._removeObjectForKey(value, 'ellaborationConsumptionWarehouses');
    }

    // Relationship: productFormats
    protected _productFormats:MIOManagedObjectSet = null;
    get productFormats():MIOManagedObjectSet {
        return this.valueForKey('productFormats');
    }
    addProductFormatsObject(value:ProductFormat) {
        this._addObjectForKey(value, 'productFormats');
    }
    removeProductFormatsObject(value:ProductFormat) {
        this._removeObjectForKey(value, 'productFormats');
    }

    // Relationship: products
    protected _products:MIOManagedObjectSet = null;
    get products():MIOManagedObjectSet {
        return this.valueForKey('products');
    }
    addProductsObject(value:Product) {
        this._addObjectForKey(value, 'products');
    }
    removeProductsObject(value:Product) {
        this._removeObjectForKey(value, 'products');
    }
}
