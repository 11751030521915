class DBProductRatesCoder extends DBXLSXCoder
{
    columns() : any[] {
        let cols = [
            { title: "PRODUCT RATE ID", width: "10%", align: "center" },
            { title: "RATE ID", width: "10%", align: "center" },
            { title: "RATE NAME", width: "10%", align: "center" },
            { title: "PRODUCT ID", width: "20%", align: "center" },
            { title: "PRODUCT REFERENCE", width: "20%", align: "center" },
            { title: "PRODUCT NAME", width: "20%", align: "center" },
            { title: "PRICE", width: "10%", align: "center" },
            { title: "TAX NAME", width: "10%", align: "center" }
        ]
        return cols;
    }

    protected mainEntity() {
        return { entity: "ProductRate", predicateFormat: "deletedAt == null", relations:["rate", "product", "tax"] };
    }

    protected aditionalImportEntities(): any[] {
        return [
            { entity: "Rate", predicateFormat: "deletedAt == null" },
            { entity: "Product", predicateFormat: "deletedAt == null" },
            { entity: "Tax", predicateFormat: "deletedAt == null" }
        ]
    }

    protected importRow(row){
        
        let productRateID    = row["PRODUCT RATE ID"];
        let rateID           = row["RATE ID"];
        let rateName         = row["RATE NAME"];
        let productID        = row["PRODUCT ID"];
        let productReference = row["PRODUCT REFERENCE"];
        let productName      = row["PRODUCT NAME"];        
        let taxName          = row["TAX NAME"];
        let price            = row["PRICE"];
        
        let tax = this.queryEntityByField("Tax", "name", taxName) as Tax;

        let rate = this.queryEntityByIDOrName("Rate", rateID, rateName) as Rate;
        if (rate == null) {
            // TODO: throw an exception
            return;
        }

        let product = this.queryEntityProduct(productID, productReference, productName);
        if ( product == null ) {
            // TODO: throw an exception
            return;
        }

        let productRate = this.queryEntity("ProductRate", "product = '" + product.identifier + "' AND rate = '" + rate.identifier + "'") as ProductRate;
        if (productRate == null) { 
            productRate = MIOEntityDescription.insertNewObjectForEntityForName("ProductRate", DBHelper.mainManagedObjectContext) as ProductRate;
            productRate.identifier = this.parseOrCreateIdentifier(productRateID);
            productRate.product = product;
            productRate.rate = rate;
        }

        productRate.price = price;

        this.appendObject(productRate);
        MIOLog("ADDING PRODUCT RATE: " + this.rowIndex + "/" + this.rows.length + ": " + productName + " - " + rateName);
    }

    protected exportTitle() : string { return "Product Rates"; }
    
    protected exportSortDescriptors() { 
        return [MIOSortDescriptor.sortDescriptorWithKey("rate.name", false)]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let productRate = object as ProductRate;

        let item = {
            "RATE NAME"             : productRate.rate.name,
            "PRODUCT REFERENCE"     : productRate.product.reference,
            "PRODUCT NAME"          : productRate.product.name,
            "PRICE"                 : productRate.price,
            "TAX NAME"              : productRate.tax?.name
        }

        return item;
    }
}