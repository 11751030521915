
class AgentListViewController extends EntityListViewController
{
    public static newInstance(): AgentListViewController {
        let vc = new AgentListViewController('agent-list-view');
        vc.initWithResource("layout/agents/AgentListView.html");        
        return vc;
    }

    private saveButton:MUIButton = null;
    private addButton:MUIButton = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        });

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function(this:AgentListViewController){
            this.showAddAgentViewController();
        });

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Agent;
        let cell = tableview.dequeueReusableCellWithIdentifier("AgentListCell") as AgentListCell;
        
        cell.item = item;

        return cell;
    }

    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Agent;    

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            DBHelper.deleteObjectFromMainContext(item, true);
        }
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        const ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        
        let predicateFormat:string = null;
        if (this.searchString != null) {
            let predicates = [];
            predicates.addObject("name CONTAINS '" + this.searchString + "'");
            predicates.addObject("phone CONTAINS '" + this.searchString + "'");
            predicates.addObject("email CONTAINS '" + this.searchString + "'");
            predicateFormat = predicates.join(" OR ");
        }

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Agent", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["intermediary"];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }


    private showAddAgentViewController(){

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ADD NEW AGENT", "ADD NEW AGENT"), MIOLocalizeString("TYPE A NAME", "TYPE A NAME"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });
        
        avc.addComboBoxWithConfigurationHandler(this, function (comboBox: MUIComboBox) {
            DBHelper.sharedInstance().addObserverForEntity(avc, "Intermediary", null, null, null, function (objects) {
                comboBox.removeAllItems();
                comboBox.addItem(MIOLocalizeString("NONE", "NONE"), -1);
                for (let i = 0; i < objects.length; i++)
                    comboBox.addItem(objects[i].name, i);
            });
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Default, this, function(this:AgentListViewController){
            let name = avc.textFields[0].text;
            let intermediaryComboBox: MUIComboBox = avc.comboBoxes[0];
            let intermediaryIndex = intermediaryComboBox.getSelectedItem();
            let intermediary = null;
            if (intermediaryIndex == -1) {
                intermediary = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, intermediaryIndex, "Intermediary") as Intermediary;
            }

            this.addAgent(name, intermediary);
        }));
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        avc.addCompletionHandler(this, function () {
            // Release the observers
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, "Intermediary");
        });


        this.presentViewController(avc, true);   
    }

    private addAgent(name:string, intermediary:Intermediary){

        if (name.length == 0) {
            AppHelper.showErrorMessage(this, "ERROR CREATING AGENT", "THE NAME IS INVALID. TRY ANTOHER NAME.");
            return;
        }

        DBHelper.createAgent(name, intermediary);
        DBHelper.saveMainContext();
    }
}