//
// Generated class DocumentSequence
//

/// <reference path="DocumentSequence_ManagedObject.ts" />

class DocumentSequence extends DocumentSequence_ManagedObject
{

}
