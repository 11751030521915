//
// Generated class IntegratorMappingForeignEntity
//

/// <reference path="IntegratorMappingForeignEntity_ManagedObject.ts" />

class IntegratorMappingForeignEntity extends IntegratorMappingForeignEntity_ManagedObject
{

}
