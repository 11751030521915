//
// Generated class BookingTimeRangePresetConfiguration
//

/// <reference path="BookingTimeRangePresetConfiguration_ManagedObject.ts" />

class BookingTimeRangePresetConfiguration extends BookingTimeRangePresetConfiguration_ManagedObject
{

}
