//
// Generated class StockCategory
//

/// <reference path="StockCategory_ManagedObject.ts" />

class StockCategory extends StockCategory_ManagedObject
{
    get namePath():string { return this.indentPathForKey( "name", "superCategory" )[0]; }
    get indentLevel():number { return this.indentPathForKey( "name", "superCategory" )[1]; }
}
