/**
 * Created by miguel on 23/2/17.
 */


class OfferCodeDetailViewController extends MUIViewController
{
    private _codes = null;

    private tableView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.tableView = MUIOutlet(this, 'tableview', 'UITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        this._updateUI();
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this._updateUI();
    }
    
    private _offer = null;
    set offer(value:Offer){
        this._offer = value;
        this._updateUI();
    }

    private _filter = 0;
    set filter (value:number){
        this._filter = value;
        if (value < 0 || value > 2) this._filter = 2;
        this._updateUI();
    }

    private _updateUI(){
        if(this.viewIsLoaded && this._offer !=null){
            this.fetchedResultsController = null;
            this.tableView.reloadData();        
        }
    }
    
    public itemDidSelected(item){        
        var avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('CHOOSE THE ACTION',"Choose the action"), MIOLocalizeString('CHOOSE AN ACTION',"Choose an action "), MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('EXPORT',"Export"), MUIAlertActionStyle.Default, this, function(){
            console.log("Export clicked");             
        }));

        this.presentViewController(avc, true);
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
   
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('OfferCodeCell') as OfferCodeCell;            
        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        
        cell.item = item;
        cell.delegate = this;        
    
        return cell;
    }
      
   didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
       let obj = this.fetchedResultsController.objectAtIndexPath(indexPath);   
   }
   
   private _fetchedResultsController = null;
   set fetchedResultsController(value){
       if (value == null && this._fetchedResultsController != null)
           this._fetchedResultsController.delegate = null;
   
       this._fetchedResultsController = value;
   }
   
   get fetchedResultsController(){
       if (this._fetchedResultsController != null)
           return this._fetchedResultsController;
   
       let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
   
       let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('code', true)];
       let predicateFormat = 'offer.identifier == ' + this._offer.identifier;

    //    switch (this._filter) {
    //        case 0: // not consumed
    //             predicateFormat += " AND usedDate == null";
    //             break;

    //         case 1: // consumed
    //             predicateFormat += " AND usedDate != null";
    //             break;
    //    }

       let fetchRequest = DBHelper.listFetchRequestWithEntityName("PromotionalCode", sortDescriptors, predicateFormat);
       fetchRequest.fetchLimit = 100;

       let fetchedResultsController = new MIOFetchedResultsController();
       fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
       fetchedResultsController.delegate = this;
   
       fetchedResultsController.performFetch();
   
       this._fetchedResultsController = fetchedResultsController;
   
       return this._fetchedResultsController;
   }
   
   controllerDidChangeContent(controller){
       this.tableView.reloadData();
   }
}