

/// <reference path="StockNote.ts" />

// Generated class SupplierOrder_ManagedObject

class SupplierOrder_ManagedObject extends StockNote
{

    // Property: onlineOrderID
    set onlineOrderID(value:string) {
        this.setValueForKey(value, 'onlineOrderID');
    }
    get onlineOrderID():string {
        return this.valueForKey('onlineOrderID');
    }
    set onlineOrderIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'onlineOrderID');
    }
    get onlineOrderIDPrimitiveValue():string {
        return this.primitiveValueForKey('onlineOrderID');
    }

    // Property: onlineOrderNumber
    set onlineOrderNumber(value:string) {
        this.setValueForKey(value, 'onlineOrderNumber');
    }
    get onlineOrderNumber():string {
        return this.valueForKey('onlineOrderNumber');
    }
    set onlineOrderNumberPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'onlineOrderNumber');
    }
    get onlineOrderNumberPrimitiveValue():string {
        return this.primitiveValueForKey('onlineOrderNumber');
    }
}
