//
// Generated class SupplierProduct
//

/// <reference path="SupplierProduct_ManagedObject.ts" />

class SupplierProduct extends SupplierProduct_ManagedObject
{

}
