//
// Generated class TipConfiguration
//

/// <reference path="TipConfiguration_ManagedObject.ts" />

class TipConfiguration extends TipConfiguration_ManagedObject
{

}
