

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class PrintProfile_ManagedObject

class PrintProfile_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: isActive
    set isActive(value:boolean) {
        this.setValueForKey(value, 'isActive');
    }
    get isActive():boolean {
        return this.valueForKey('isActive');
    }
    set isActivePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isActive');
    }
    get isActivePrimitiveValue():boolean {
        return this.primitiveValueForKey('isActive');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Relationship: printProfileConfigurations
    protected _printProfileConfigurations:MIOManagedObjectSet = null;
    get printProfileConfigurations():MIOManagedObjectSet {
        return this.valueForKey('printProfileConfigurations');
    }
    addPrintProfileConfigurationsObject(value:PrintProfileConfiguration) {
        this._addObjectForKey(value, 'printProfileConfigurations');
    }
    removePrintProfileConfigurationsObject(value:PrintProfileConfiguration) {
        this._removeObjectForKey(value, 'printProfileConfigurations');
    }

    // Relationship: profileSummaries
    protected _profileSummaries:MIOManagedObjectSet = null;
    get profileSummaries():MIOManagedObjectSet {
        return this.valueForKey('profileSummaries');
    }
    addProfileSummariesObject(value:ProfileSummary) {
        this._addObjectForKey(value, 'profileSummaries');
    }
    removeProfileSummariesObject(value:ProfileSummary) {
        this._removeObjectForKey(value, 'profileSummaries');
    }
}
