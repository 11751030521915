
class DeliveryRoutesDetailCell extends MUITableViewCell
{
    private postalCodeLabel:MUILabel = null;

    awakeFromHTML(){
        this.postalCodeLabel = MUIOutlet(this, "postal-code-lbl", "MUILabel");
    }

    set item(i:DeliveryPostalCode){
        this.postalCodeLabel.text = i.postalCode;
    }

}