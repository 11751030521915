class ConsumptionProfileListViewController extends BaseTableViewController
{

    static newInstance() : ConsumptionProfileListViewController {
        let vc = new ConsumptionProfileListViewController("consumption-profile-list-view");
        vc.initWithResource("layout/consumptionprofile/ConsumptionProfileListView.html");
        return vc;
    }

    viewDidLoad(){
        super.viewDidLoad();

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;   
    }

//    viewWillAppear(animate?){
//        super.viewWillAppear(animate);
//        this.updateUI();
//    }

//    private updateUI(){
//        if (this.viewIsLoaded == false) return;
//        //this.fetchedResultsController = null;
//        this.tableView.reloadData();            
//    }
    
    numberOfSections(tableView:UITableView){
        return 2;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        if (section == 0) {
            return 1;
        } else {
            let sec = this.fetchedResultsController.sections[0];
            return sec.numberOfObjects();
        }
    }

    
    titleForHeaderInSection(tableView:UITableView, section:number) {   
        let title = null;
        switch (section){
            case 0: 
            title = MIOLocalizeString("LOCATION CATEGORIES","LOCATION CATEGORIES");
            break;

            case 1:
            title = MIOLocalizeString("DEVICES","DEVICES");
            break;
        }
        return title;
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {  
        let cell = null; 
        if (indexPath.section == 0){
            cell = tableView.dequeueReusableCellWithIdentifier('ConsumptionProfileListCell') as ConsumptionProfileListCell;
            cell.title = MIOLocalizeString("LOCATION CATEGORIES","LOCATION CATEGORIES");
            
        } else {
            let ip = MIOIndexPath.indexForRowInSection(indexPath.row, indexPath.section-1);
            let item = this.fetchedResultsController.objectAtIndexPath(ip) as Application;
            cell = tableView.dequeueReusableCellWithIdentifier("ConsumptionProfileListCell") as ConsumptionProfileListCell;
            cell.item = item;
            cell.accessoryType = UITableViewCellAccessoryType.None;
        }
        return cell;
    }
    
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        if (indexPath.section == 0){
            this.selectedItem = null;
        } else {
            let ip = MIOIndexPath.indexForRowInSection(indexPath.row, indexPath.section-1);
            this.selectedItem = this.fetchedResultsController.objectAtIndexPath(ip);
        }
        this.showSelectedConsumptionProfile(this.selectedItem);
    }

    // set fetchedResultsController(value){
    //     if (value == null && this._fetchedResultsController != null)
    //         this._fetchedResultsController.delegate = null;

    //     this._fetchedResultsController = value;
    // }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let sd = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];
        let predicateFormat = null;

        if (this.searchString != null) {
            predicateFormat = "name CONTAINS '" + this.searchString + "' OR barcode CONTAINS '" + this.searchString +"'";
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Application", sd, predicateFormat);        
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    private detailViewController:ConsumptionProfileDetailViewController = null;
    private showSelectedConsumptionProfile(item){

        if(this.detailViewController == null) {
            this.detailViewController  = new ConsumptionProfileDetailViewController("consumption-profile-detail-view");
            this.detailViewController.initWithResource("layout/consumptionprofile/ConsumptionProfileDetailView.html");
        }

        this.detailViewController.item = item;
        this.splitViewController.showDetailViewController(this.detailViewController);
    }

}