

enum DBSyncEntityCellType
{
    entity,
    queue,
    transaction
}

class DBSyncEntityCell extends UITableViewCell
{
    delegate = null;
    syncType = DBSyncEntityCellType.entity;

    private dateLabel:MUILabel = null;
    private referenceLabel:MUILabel = null;
    private conceptLabel:MUILabel = null;
    private statusLabel:MUILabel = null;
    private syncButton:MUIButton = null;    

    awakeFromHTML(){
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");
        this.referenceLabel = MUIOutlet(this, "ref-lbl", "MUILabel");
        this.conceptLabel = MUIOutlet(this, "concept-lbl", "MUILabel");
        this.statusLabel = MUIOutlet(this, "status-lbl", "MUILabel");
        
        this.syncButton = MUIOutlet(this, "sync-btn", "MUIButton");
        this.syncButton.setAction(this, function(this:DBSyncEntityCell){
            this.sync();
        });

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private _item:IntegratorSyncEntityQueue = null;
    set item(item:IntegratorSyncEntityQueue){
        this._item = item;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this.dateLabel.text = ad.dateTimeFormatter.stringFromDate(item.integratorDate);
        this.conceptLabel.text = item.integratorConcept;
        this.referenceLabel.text = null; //item.integratorReferenceDocumentID;   
        
        // if (item.integratorStatus == 0) {
        //     this.statusLabel.text = null;
        //     this.syncButton.hidden = false;
        //     MUICoreLayerRemoveStyle(this.dateLabel.layer, "active");
        //     MUICoreLayerRemoveStyle(this.conceptLabel.layer, "active");
        //     MUICoreLayerRemoveStyle(this.statusLabel.layer, "active");

        // }
        // else {            
        //     this.statusLabel.text = MIOLocalizeString("ADDED TO TRANSACTION", "ADDED TO TRANSACTION");
        //     this.syncButton.hidden = true;
        //     MUICoreLayerAddStyle(this.dateLabel.layer, "active");
        //     MUICoreLayerAddStyle(this.conceptLabel.layer, "active");
        //     MUICoreLayerAddStyle(this.statusLabel.layer, "active");
        // }        

        switch (this.syncType)
        {
            case DBSyncEntityCellType.entity:
                // this.syncButton.enabled = (item.status == IntegratorSyncStatus.None);        
                this.syncButton.title = MIOLocalizeString("ADD", "ADD");    
                break;

            case DBSyncEntityCellType.queue:                
                this.statusLabel.text = item.integratorStatus == 1 ? MIOLocalizeString("READY TO SYNC", "READY TO SYNC") : MIOLocalizeString("ADDED TO QUEUE", "ADDED TO QUEUE");
                this.syncButton.title = item.integratorStatus == 1 ? MIOLocalizeString("ADD", "ADD") : MIOLocalizeString("REMOVE", "REMOVE");
                this.syncButton.hidden = false;    
                break;

            case DBSyncEntityCellType.transaction:                                                                
                if (item.integratorStatus == 2) {
                    this.statusLabel.text = item.integratorSyncDate == null ? MIOLocalizeString("PENDING", "PENDING") : MIOLocalizeString("SYNCED", "SYNCED");
                    this.syncButton.title = item.integratorSyncDate == null ? MIOLocalizeString("TRY AGAIN", "TRY AGAIN") : null;
                    this.syncButton.hidden = item.integratorSyncDate == null ? false : true;
                    MUICoreLayerRemoveStyle(this.dateLabel.layer, "alert");
                    MUICoreLayerRemoveStyle(this.conceptLabel.layer, "alert");
                    MUICoreLayerRemoveStyle(this.statusLabel.layer, "alert");
                }
                else {
                    this.statusLabel.text = item.integratorError;
                    this.syncButton.title = MIOLocalizeString("TRY AGAIN", "TRY AGAIN");
                    this.syncButton.hidden = false;
                    MUICoreLayerAddStyle(this.dateLabel.layer, "alert");
                    MUICoreLayerAddStyle(this.conceptLabel.layer, "alert");
                    MUICoreLayerAddStyle(this.statusLabel.layer, "alert");
                }
                break;
        }                

        // this.errorLabel.text = item.error;
        
        // if (item.status == IntegratorsSyncStatus.NoSync && item.error == null) {
        //     // this.infoLabel.text = null;
        //     // this.infoLabel.layer.title = null;
        //     this.syncButton.hidden = false;
        //     // this.syncDateLabel.text = null;
        // }
        // else if (item.status == IntegratorsSyncStatus.NoSync && item.error != null) {
        //     if (item.data != null) {
        //         let txt = JSON.stringify(item.data);
        //         this.infoLabel.text = "more info";
        //         this.infoLabel.layer.title = txt;            
        //     }
        //     else {
        //         this.infoLabel.text = null;
        //         this.infoLabel.layer.title = null;    
        //     }
        //     this.syncButton.hidden = false;
        //     let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;            
        //     this.syncDateLabel.text = item.date != null ? ad.dateTimeFormatter.stringFromDate(item.date) : null;
        // }
        // else {
        //     this.infoLabel.text = item.data;
        //     this.infoLabel.layer.title = null;
        //     this.syncButton.hidden = true;
        //     let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;            
        //     this.syncDateLabel.text = item.date != null ? ad.dateTimeFormatter.stringFromDate(item.date) : null;
        // }      

        // if (item.error != null) {
        //     MUICoreLayerAddStyle(this.idLabel.layer, "alert");
        //     MUICoreLayerAddStyle(this.conceptLabel.layer, "alert");
        //     MUICoreLayerAddStyle(this.statusLabel.layer, "alert");
        //     MUICoreLayerAddStyle(this.errorLabel.layer, "alert");
        //     MUICoreLayerAddStyle(this.infoLabel.layer, "alert");
        //     MUICoreLayerAddStyle(this.syncDateLabel.layer, "alert");
        // }
        // else {
        //     MUICoreLayerRemoveStyle(this.idLabel.layer, "alert");
        //     MUICoreLayerRemoveStyle(this.conceptLabel.layer, "alert");
        //     MUICoreLayerRemoveStyle(this.statusLabel.layer, "alert");
        //     MUICoreLayerRemoveStyle(this.errorLabel.layer, "alert");
        //     MUICoreLayerRemoveStyle(this.infoLabel.layer, "alert");
        //     MUICoreLayerRemoveStyle(this.syncDateLabel.layer, "alert");
        // }
    }

    private sync(){
        if (this.syncType == DBSyncEntityCellType.queue) {
            if (this._item.integratorStatus == 1) {
                this._item.integratorStatus = 2;
                this._item.integratorSync.status = 2;
                this.statusLabel.text = MIOLocalizeString("ADDED TO QUEUE", "ADDED TO QUEUE");
                this.syncButton.title = MIOLocalizeString("REMOVE FROM QUEUE", "REMOVE FROM QUEUE");            
            }
            else if (this._item.integratorStatus == 2) {
                this._item.integratorStatus = 1;
                this._item.integratorSync.status = 1;
                this.statusLabel.text = MIOLocalizeString("ADDED TO QUEUE", "ADDED TO QUEUE");
                this.syncButton.title = MIOLocalizeString("REMOVE FROM QUEUE", "REMOVE FROM QUEUE");            
            }
        }
        else if (this.syncType == DBSyncEntityCellType.transaction) {
            this._item.integratorSync.integratorSyncDate = null;
            this._item.integratorSync.error = null;
        }

        DBHelper.saveMainContext();
    }

}
