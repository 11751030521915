
class CashDeskOperationCell extends MUITableViewCell
{
    private dateLabel:MUILabel = null;
    private conceptLabel:MUILabel = null;
    private paymethodImageView:MUIImageView = null;
    private paymethodNameLabel:MUILabel = null;
    private documentIDLabel:MUILabel = null;
	private importLabel:MUILabel = null;
	private locationLabel:MUILabel = null;
    private tipLabel:MUILabel = null;
    private totalLabel:MUILabel = null;
        
    awakeFromHTML(){
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");
        this.conceptLabel = MUIOutlet(this, "concept-lbl", "MUILabel");
        this.paymethodImageView = MUIOutlet(this, "icon-view", "MUIImageView")
        this.paymethodNameLabel = MUIOutlet(this, "payment-name-lbl", "MUILabel");        
		this.documentIDLabel = MUIOutlet(this, "document-id-lbl", "MUILabel");
		this.locationLabel = MUIOutlet(this, "location-lbl", "MUILabel");
        this.importLabel = MUIOutlet(this, "import-lbl", "MUILabel");
        this.tipLabel = MUIOutlet(this, "tip-lbl", "MUILabel");
        this.totalLabel = MUIOutlet(this, "total-lbl", "MUILabel");
    }

    set item(item:CashDeskOperation){
        let tf = MUIWebApplication.sharedInstance().delegate.timeFormatter as MIODateFormatter;
        let cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;

        this.dateLabel.text = tf.stringFromDate(item.date);        
        this.conceptLabel.text = item.concept;

        if (item.payMethod != null && item.payMethod.image != null) this.paymethodImageView.setImage(item.payMethod.image.url);
        else this.paymethodImageView.setImage(null);
        this.paymethodNameLabel.text = item.payMethodName;
		this.documentIDLabel.text = item.legalDocumentID;

		if (item.locationName != null || item.locationCategoryName != null) {
			this.locationLabel.text = item.locationCategoryName + " " + item.locationName;
		} else {
			this.locationLabel.text = null;
		}
		
        this.importLabel.text = cf.stringFromNumber(item.total - item.tip);
        this.tipLabel.text = cf.stringFromNumber(item.tip);
        this.totalLabel.text = cf.stringFromNumber(item.total);
    }
}