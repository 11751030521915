//
// Generated class BookingGuestList
//

/// <reference path="BookingGuestList_ManagedObject.ts" />

class BookingGuestList extends BookingGuestList_ManagedObject
{

    sdtf = MUIWebApplication.sharedInstance().delegate.serverDateTimeFormatter;
    
    private _activeFromString:string = null;
    
    /*set activeFrom(value:Date) {
        this.setValue('_activeFrom', value);
        this._activeFromString = this.sdtf.stringFromDate(value); // To cache the date info. It's easie than work with dates
    }
   
    get activeFromString()
    {
        return this._activeFromString;
    }
    private _activeToString:string = null;

    set activeTo(value:Date) {
        this.setValue('_activeTo', value);
        this._activeToString = this.sdtf.stringFromDate(value); // To cache the date info. It's easie than work with dates
    }

    get activeToString()
    {
        return this._activeToString;
    }
*/

    

    hourFromServer(hour)
    {
        if(hour != null) {
            var hourSplitted = hour.split(':');
            hour = hourSplitted.length > 2 ? hourSplitted[0] + ':' +hourSplitted[1] : '00:00';
        }

        return hour ? hour : '00:00' ;
    }

    hourToServer(hour)
    {
        if(!hour) return '00:00:00';
        var hourSplitted = hour.split(':');
        switch(hourSplitted.length)
        {
            case 2:
                return hour+':00';
            case 3: 
                return hour;
            default:
                return '00:00:00';          
        }
    }
}
