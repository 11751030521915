//
// Generated class ProductCombination
//

/// <reference path="ProductCombination_ManagedObject.ts" />

class ProductCombination extends ProductCombination_ManagedObject
{

}
