
class GeneralRateViewController extends MUIViewController
{
    private doneButton:MUIButton = null;
    private segmentedControl:MUISegmentedControl = null;
    private amountTextfield:MUITextField = null;
    private percentSwitch:MUISwitchButton = null;
    private removeButton:MUIButton = null;

    delegate = null;

    private rateVariable:any = "00.00";

    public preferredContentSize = new MIOSize(300, 320);

    viewDidLoad(){
        super.viewDidLoad();

        this.doneButton = MUIOutlet(this, 'done-btn', 'MUIButton');             
        this.doneButton.setAction(this, function (){
            if (this.delegate != null) this.delegate.generalRateDidUpdate(this.rateVariable);
            this.dismissViewController(true);
        });

        this.segmentedControl = MUIOutlet(this, 'segmented-control', 'MUISegmentedControl');
        this.segmentedControl.setAction(this, function (control, index){
            if (index == 0) {
                this.rateVariable = "Positive";
            } else if (index == 1) {
                this.rateVariable = "Negative";
            }
        });

        this.amountTextfield = MUIOutlet(this, 'amount-tf', 'MUITextField');
        this.amountTextfield.setOnChangeText(this, function(textfield, value)
        {
            this.rateVariable = value;
        });

        this.percentSwitch = MUIOutlet(this, "isPercent-switch-btn", "MUISwitchButton");
        this.percentSwitch.setOnChangeValue(this, function(control, value:boolean){
            if (value)
                this.rateVariable = "isPercent";
        });

        this.removeButton = MUIOutlet(this, "remove-btn", "MUIButton");
        this.removeButton.setAction(this, function(){
            //remove something?
        })

    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _rate:Rate = null;
    set item(item:Rate){
        this._rate = item;
        this.updateUI();
    }   

    private updateUI(){
        if (this.viewIsLoaded == false || this._rate == null) return;

        //set variables from item here
       this.amountTextfield.text = this._rate.rule;
    }

}