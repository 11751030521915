

// Generated class DBSyncEntity_ManagedObject

class DBSyncEntity_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: placeID
    set placeID(value:string) {
        this.setValueForKey(value, 'placeID');
    }
    get placeID():string {
        return this.valueForKey('placeID');
    }
    set placeIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'placeID');
    }
    get placeIDPrimitiveValue():string {
        return this.primitiveValueForKey('placeID');
    }

    // Property: integratorReferenceID
    set integratorReferenceID(value:string) {
        this.setValueForKey(value, 'integratorReferenceID');
    }
    get integratorReferenceID():string {
        return this.valueForKey('integratorReferenceID');
    }
    set integratorReferenceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'integratorReferenceID');
    }
    get integratorReferenceIDPrimitiveValue():string {
        return this.primitiveValueForKey('integratorReferenceID');
    }

    // Property: integratorReferenceConcept
    set integratorReferenceConcept(value:string) {
        this.setValueForKey(value, 'integratorReferenceConcept');
    }
    get integratorReferenceConcept():string {
        return this.valueForKey('integratorReferenceConcept');
    }
    set integratorReferenceConceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'integratorReferenceConcept');
    }
    get integratorReferenceConceptPrimitiveValue():string {
        return this.primitiveValueForKey('integratorReferenceConcept');
    }

    // Property: integratorReferenceDocumentID
    set integratorReferenceDocumentID(value:string) {
        this.setValueForKey(value, 'integratorReferenceDocumentID');
    }
    get integratorReferenceDocumentID():string {
        return this.valueForKey('integratorReferenceDocumentID');
    }
    set integratorReferenceDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'integratorReferenceDocumentID');
    }
    get integratorReferenceDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('integratorReferenceDocumentID');
    }

    // Property: integratorDate
    set integratorDate(value:Date) {
        this.setValueForKey(value, 'integratorDate');
    }
    get integratorDate():Date {
        return this.valueForKey('integratorDate');
    }
    set integratorDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'integratorDate');
    }
    get integratorDatePrimitiveValue():Date {
        return this.primitiveValueForKey('integratorDate');
    }

    // Property: integratorConcept
    set integratorConcept(value:string) {
        this.setValueForKey(value, 'integratorConcept');
    }
    get integratorConcept():string {
        return this.valueForKey('integratorConcept');
    }
    set integratorConceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'integratorConcept');
    }
    get integratorConceptPrimitiveValue():string {
        return this.primitiveValueForKey('integratorConcept');
    }

    // Property: integratorStatus
    set integratorStatus(value:number) {
        this.setValueForKey(value, 'integratorStatus');
    }
    get integratorStatus():number {
        return this.valueForKey('integratorStatus');
    }
    set integratorStatusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'integratorStatus');
    }
    get integratorStatusPrimitiveValue():number {
        return this.primitiveValueForKey('integratorStatus');
    }

    // Property: integratorError
    set integratorError(value:string) {
        this.setValueForKey(value, 'integratorError');
    }
    get integratorError():string {
        return this.valueForKey('integratorError');
    }
    set integratorErrorPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'integratorError');
    }
    get integratorErrorPrimitiveValue():string {
        return this.primitiveValueForKey('integratorError');
    }

    // Property: integratorEnv
    set integratorEnv(value:string) {
        this.setValueForKey(value, 'integratorEnv');
    }
    get integratorEnv():string {
        return this.valueForKey('integratorEnv');
    }
    set integratorEnvPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'integratorEnv');
    }
    get integratorEnvPrimitiveValue():string {
        return this.primitiveValueForKey('integratorEnv');
    }

    // Property: responseReference
    set responseReference(value:string) {
        this.setValueForKey(value, 'responseReference');
    }
    get responseReference():string {
        return this.valueForKey('responseReference');
    }
    set responseReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'responseReference');
    }
    get responseReferencePrimitiveValue():string {
        return this.primitiveValueForKey('responseReference');
    }

    // Property: responseRaw
    set responseRaw(value:string) {
        this.setValueForKey(value, 'responseRaw');
    }
    get responseRaw():string {
        return this.valueForKey('responseRaw');
    }
    set responseRawPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'responseRaw');
    }
    get responseRawPrimitiveValue():string {
        return this.primitiveValueForKey('responseRaw');
    }

    // Property: integratorSyncDate
    set integratorSyncDate(value:Date) {
        this.setValueForKey(value, 'integratorSyncDate');
    }
    get integratorSyncDate():Date {
        return this.valueForKey('integratorSyncDate');
    }
    set integratorSyncDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'integratorSyncDate');
    }
    get integratorSyncDatePrimitiveValue():Date {
        return this.primitiveValueForKey('integratorSyncDate');
    }

    // Property: createdAt
    set createdAt(value:Date) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():Date {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():Date {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:Date) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():Date {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:Date) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():Date {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: integratorSync
    set integratorSync(value:IntegratorSync) {
        this.setValueForKey(value, 'integratorSync');
    }
    get integratorSync():IntegratorSync {
        return this.valueForKey('integratorSync') as IntegratorSync;
    }
}
