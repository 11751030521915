class DBMappingViewController extends BaseViewController
{
    private mappingConfigComboBox:MUIComboBox = null;
    private mappingEntityComboBox:MUIComboBox = null;
    private addButton:MUIButton = null;

    private tableView:UITableView = null;
    private nameFilterTextField:ColumnFilterTextField = null;
    private foreignNameFilterTextField:ColumnFilterTextField = null;
    private foreignIDFilterTextField:ColumnFilterTextField = null;
    
    private matchesSearchBar:MUITextField = null;
    private matchesTableView:MUITableView = null;

    private matchesDataSource:DBMappingMatchesDataSource = null;
    private matchesSearchString:string = null;
    private matchesSearchTimer:MIOTimer = null;

    private columnFilterController:ColumnFilterController = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.columnFilterController = new ColumnFilterController();
        this.columnFilterController.initWithDelegate(this);

        this.nameFilterTextField = MUIOutlet(this, "ownname-filter-tf", "ColumnFilterTextField");
        this.nameFilterTextField.filterController = this.columnFilterController;
        this.nameFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "ownName", null, null);

        this.foreignNameFilterTextField = MUIOutlet(this, "foreignname-filter-tf", "ColumnFilterTextField");
        this.foreignNameFilterTextField.filterController = this.columnFilterController;
        this.foreignNameFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "foreignName", null, null);

        this.foreignIDFilterTextField = MUIOutlet(this, "foreignid-filter-tf", "ColumnFilterTextField");
        this.foreignIDFilterTextField.filterController = this.columnFilterController;
        this.foreignIDFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "foreignID", null, null);

        this.mappingConfigComboBox = MUIOutlet(this, "mapping-cb", "MUIComboBox");                
        this.mappingConfigComboBox.setOnChangeAction(this, function(this:DBMappingViewController, control, value){
            this.changeConfigMappings(value);
        });
        this.updateMappingConfigComboBox(this.configFetchedResultsController.fetchedObjects);

        // this.mappingEntityComboBox = MUIOutlet(this, "mapping-entity-cb", "MUIComboBox");                
        // this.mappingEntityComboBox.setOnChangeAction(this, function(this:DBMappingViewController, control, value){
        //     this.changeEntityMappings(value);
        // });
        // this.updateMappingConfigComboBox(this.configFetchedResultsController.fetchedObjects);


        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, this.showAddActions);

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        // this.searchBar = MUIOutlet(this, "search-bar", "MUITextField");
        // this.searchBar.setOnChangeText(this, function(control, value){
        //     this.searchByName(value);
        // });
        
        this.matchesSearchBar = MUIOutlet(this, "matches-search-bar", "MUITextField");
        this.matchesSearchBar.setOnChangeText(this, function(control, value){
            this.matchesSearchString = value.length > 0 ? value : null;
            if (this.matchesSearchTimer != null) this.matchesSearchTimer.invalidate();
            this.matchesSearchTimer = MIOTimer.scheduledTimerWithTimeInterval(500, false, this, function(timer:MIOTimer){
                this.matchesSearchTimer = null;
                this.matchesDataSource.searchByName(this.matchesSearchString);
            });
        });

        this.matchesTableView = MUIOutlet(this, "matches-table-view", "MUITableView");
        this.matchesDataSource = new DBMappingMatchesDataSource();
        this.matchesDataSource.initWithTableView(this.matchesTableView);
        this.matchesTableView.dataSource = this.matchesDataSource;
        this.matchesTableView.delegate = this.matchesDataSource;
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);

        MIONotificationCenter.defaultCenter().addObserver(this, "DBMappingItemDidChange", function(){
            this.selectedItemDidMapped();
        });
    }

    viewWillDisappear(animated?){
        super.viewWillDisappear(animated);

        MIONotificationCenter.defaultCenter().removeObserver(this, "DBMappingItemDidChange");
    }

    numberOfSections(tableview){
       return 2;
    }

    numberOfRowsInSection(tableView:UITableView, section:number) {
        if (this.items.length == 0) return 0;
        
        if (section == 0) return this.items.count;
        else if (section == 1) return 1;        
        return 0;
    }

    cellAtIndexPath(tableView:UITableView, indexPath: MIOIndexPath) {
        let cell = null;

        if (indexPath.section == 1) {
            // More cell
            cell = tableView.dequeueReusableCellWithIdentifier("MoreCell") as DBMappingMoreCell;            
        }
        else {

            cell = tableView.dequeueReusableCellWithIdentifier("MappingCell") as DBMappingCell;
            let item = this.items[indexPath.row] as IntegratorMappingView;
            cell.item = item;
        }

        return cell;
    }
    
    private selectedItem = null;
    private selectedIndexPath:MIOIndexPath = null;
    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){

        this.selectedIndexPath = null;

        if (indexPath.section == 0) {
            //let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Product;
            let item = this.items[indexPath.row] as DBMappingEntity;
            this.selectedItem = item;
            this.matchesDataSource.item = item;
            this.selectedIndexPath = indexPath;
        }
        else if (indexPath.section == 1){
            // Add more cells
            this.nextRequest();
        }
    }

    private _configFetchedResultsController:MIOFetchedResultsController = null;
    get configFetchedResultsController(){
        if (this._configFetchedResultsController != null) return this._configFetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;        
    
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("IntegratorConfig", sortDescriptors, null);
        //fetchRequest.predicate = MIOPredicate.predicateWithFormat("type == 1");

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._configFetchedResultsController = fetchedResultsController;   
        return this._configFetchedResultsController;
    }

    private _entityFetchedResultsController:MIOFetchedResultsController = null;
    get entityFetchedResultsController(){
        if (this._entityFetchedResultsController != null) return this._entityFetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;        
    
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("IntegratorMappingView", sortDescriptors, null);
        //fetchRequest.predicate = MIOPredicate.predicateWithFormat("type == 1");
        fetchRequest.userInfo = { "config": this.selectedConfig.identifier, "entityName": this.selectedConfig.sourceEntityName, "entityType": this.selectedConfig.sourceEntityType };

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._entityFetchedResultsController = fetchedResultsController;   
        return this._entityFetchedResultsController;
    }


    controllerDidChangeContent(controller:MIOFetchedResultsController){
        if (controller === this.configFetchedResultsController) this.updateMappingConfigComboBox(controller.fetchedObjects);
        // else if (controller === this.entityFetchedResultsController) this.updateMappingEntityComboBox(controller.fetchedObjects);
    }

    private updateMappingConfigComboBox(objects){
        this.mappingConfigComboBox.removeAllItems();
        this.mappingConfigComboBox.addItem(MIOLocalizeString("NONE","NONE"), -1);        
        
        for (let index = 0; index < objects.length; index++){
            let cfg = objects[index] as IntegratorConfig;
            this.mappingConfigComboBox.addItem(cfg.name, index);
        }
    }

    private updateMappingEntityComboBox(objects){
        this.mappingEntityComboBox.removeAllItems();
        this.mappingEntityComboBox.addItem("None", -1);        
        
        for (let index = 0; index < objects.length; index++){
            let e = objects[index] as IntegratorMappingForeignEntity;
            this.mappingEntityComboBox.addItem(e.name, index);
        }
    }


    // private updateMappingComboBox(){
    //     this.mappingComboBox.removeAllItems();
    //     this.mappingComboBox.addItem("Select mapping", -1);

    //     // TODO: Get from server
    //     this.mappingComboBox.addItem("SAP: Products - Materials (DEV)", 1);
    //     this.mappingComboBox.addItem("SAP: Products - Materials (PRO)", 2);
    //     this.mappingComboBox.addItem("SAP: Warehouses - CECOS (PRO)", 3);
    //     this.mappingComboBox.addItem("SAP: Places - Plants (PRO)", 4);
    //     this.mappingComboBox.addItem("CSI: Workers - CSI", 5);
    // }

    private selectedIntegrator:string = null;
    private selectedEnviroment:string = null;
    private selectedEntity:string = null;
    private selectedMappingEntity:string = null;    


    private selectedConfig:IntegratorConfig = null;
    private changeConfigMappings(index){
        let row = parseInt(index);

        let ip = MIOIndexPath.indexForRowInSection(row, 0);
        this.selectedConfig = this.configFetchedResultsController.objectAtIndexPath(ip) as IntegratorConfig;

        // this.updateMappingEntityComboBox(this.entityFetchedResultsController.fetchedObjects);

        this.resetRequest();
        this.nextRequest();

        // this.selectedEnviroment = cfg.enviroment;
        // this.selectedIntegrator = cfg.integrator;
        // this.selectedEntity = "DBMappingEntity" + cfg.ownEntityName;
        // this.selectedMappingEntity = cfg.mappingEntityName;

        // switch(index){
        //     case "1":
        //     this.selectedIntegrator = "SAP";
        //     this.selectedEnviroment = "DEV";
        //     this.selectedEntity = "DBMappingEntityProduct";
        //     this.selectedMappingEntity = "MATERIALS";
        //     break;

        //     case "2":
        //     this.selectedIntegrator = "SAP";
        //     this.selectedEnviroment = "PRO";
        //     this.selectedEntity = "DBMappingEntityProduct";
        //     this.selectedMappingEntity = "MATERIALS";
        //     break;

        //     case "3":
        //     this.selectedIntegrator = "SAP";
        //     this.selectedEnviroment = "PRO";
        //     this.selectedEntity = "DBMappingWarehouse";
        //     this.selectedMappingEntity = "WAREHOUSES";
        //     break;

        //     case "4":
        //     this.selectedIntegrator = "SAP";
        //     this.selectedEnviroment = "PRO";
        //     this.selectedEntity = "DBMapping";
        //     this.selectedMappingEntity = "PLANTS";            
        //     break;
        // }

        // this.matchesDataSource.integrator = this.selectedIntegrator;
        // this.matchesDataSource.enviroment = this.selectedEnviroment;
        // this.matchesDataSource.entity = this.selectedEntity;
        // this.matchesDataSource.mappingEntity = this.selectedMappingEntity;

        // this.invalidateFetch();
    }

    // private selectedForeignEntity:IntegratorMappingForeignEntity = null;
    // private changeEntityMappings(index){
    //     let row = parseInt(index);

    //     let ip = MIOIndexPath.indexForRowInSection(row, 0);
    //     this.selectedForeignEntity = this.entityFetchedResultsController.objectAtIndexPath(ip) as IntegratorMappingForeignEntity;

    //     this.resetRequest();
    //     this.nextRequest();
    // }

    private timer:MIOTimer = null;
    private searchString:string = null;
    private searchByName(name:string){
        this.searchString = name.length > 0 ? name : null;
        if (this.timer != null) this.timer.invalidate();
        this.timer = MIOTimer.scheduledTimerWithTimeInterval(500, false, this, function(timer){
            this.timer = null;
            this.resetRequest();
            this.nextRequest();
        });
    }

    private items = [];
    private itemOffset = 0;
    
    private newFetchRequest(identifier?:string){
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey("name", true)];     
        
        // let predicateFormat = "(integrator == '" + this.selectedIntegrator + "' OR integrator == null)";
        // predicateFormat += " AND (enviroment == '" + this.selectedEnviroment + "' OR enviroment == null)";        
        // if (identifier) predicateFormat += " AND identifier == " + identifier;      
        
        // let predicateFormat = "config.identifier == " + this.selectedConfig.identifier + " AND foreignEntityName == '" + this.selectedForeignEntity.name + "'";

        // let filterFormat = this.columnFilterController.filterPredicateFormat();
        // if (filterFormat != null) {
        //     predicateFormat += " AND (" + filterFormat + ")";
        // }

        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("IntegratorMappingView");
        fetchRequest.sortDescriptors = sortDescriptors;
        // fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);        
        fetchRequest.fetchLimit = 100;
        fetchRequest.fetchOffset = this.itemOffset;
        fetchRequest.userInfo = { "config": this.selectedConfig.identifier, "entityName": this.selectedConfig.sourceEntityName, "entityType": this.selectedConfig.sourceEntityType };

        return fetchRequest;
    }  
    
    private get fetchRequest(){
        return this.newFetchRequest();
    }

    private resetRequest(){
        this.items = [];
        this.itemOffset = 0;   
        this.needsReloadData();        
    }

    private requesting = false;
    private nextRequest(){     
        if (this.requesting == true) return;
                
        let ip = MIOIndexPath.indexForRowInSection(0, 1);
        let cell = this.tableView.cellAtIndexPath(ip) as DBMappingMoreCell;
        if (cell != null) cell.activityIndicator.startAnimating();
        
        let request = this.fetchRequest;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webPersistentStore.fetchObjects(request, ad.managedObjectContext, this, function(this:DBMappingViewController, objects){
            this.addItems(objects);
            this.itemOffset += 100;
            this.requesting = false;
            
            cell = this.tableView.cellAtIndexPath(ip) as DBMappingMoreCell;
            if (cell != null) {
                cell.activityIndicator.stopAnimating();
                //this.tableView.deselectCellAtIndexPath(ip);
            }
        });
    }

    private addedMoreCell = false;
    private addItems(objects){
        let indexPaths = [];
        let row = this.items.length;
        for (let index = 0; index < objects.count; index++){
            let ip = MIOIndexPath.indexForRowInSection(index + row, 0);
            indexPaths.addObject(ip);
            let item = objects[index];
            this.items.addObject(item);
        }

        // if (this.addedMoreCell == false){
        //     let moreip = MIOIndexPath.indexForRowInSection(0, 1);
        //     indexPaths.addObject(moreip);
        //     this.addedMoreCell = true;
        // }

        this.tableView.insertRowsAtIndexPaths(indexPaths, UITableViewRowAnimation.None);
    }

    private selectedItemDidMapped(){
        if (this.selectedItem == null) return;

        let off = this.itemOffset - 100;
        this.resetRequest();        
        this.itemOffset = off;        
        this.nextRequest();
        this.setFetchedResultsController(null);
        this.tableView.reloadData();

        // let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        // ad.managedObjectContext.executeFetch(this.newFetchRequest(this.selectedItem.identifier));

        // let cell = this.tableView.cellAtIndexPath(this.selectedIndexPath) as DBMappingCell;
        // let indexPath = this.tableView.indexPathForCell(cell);
        // let item = this.items[indexPath.row] as DBMappingEntity;
        // cell.item = item;        
    }

    // protected invalidateFetch(){

    // }

    protected invalidateFetch(){
        this.resetRequest();        
        this.nextRequest();
        super.invalidateFetch();
    }

    protected reloadData(){
        this.tableView.reloadData();
    }

    private showAddActions(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("MAPPING", "MAPPING"), MIOLocalizeString("SELECT MAPPING", "SELECT MAPPING"), MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("NEW CONFIG MAP", "NEW CONFIG MAP"), MUIAlertActionStyle.Default, this, function(this:DBMappingViewController){
            this.showAddConfigMappingAction();
        }));

        if (this.selectedConfig != null) {
            avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("NEW FOREIGN ENTITY CONFIG", "NEW FOREIGN ENTITY CONFIG"), MUIAlertActionStyle.Default, this, function(this:DBMappingViewController){
                this.showAddForeignEntityAction();
            }));
        }

        if (this.selectedConfig != null && this.selectedForeignEntity != null) {
            avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("NEW MAPPING RELATION", "NEW MAPPING RELATION"), MUIAlertActionStyle.Default, this, function(this:DBMappingViewController){
                this.showAddMappingRelationAction();
            }));
        }

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private showAddConfigMappingAction(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ADD MAPPING", "ADD MAPPING"), MIOLocalizeString("ADD NEW MAPPING RELATION", "ADD NEW MAPPING RELATION"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME", "NAME"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "Ok"), MUIAlertActionStyle.Default, this, function(this:DBMappingViewController){
            let name = avc.textFields[0].text;            
            //this.addNewMappingEntity(name, identifier);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private showAddForeignEntityAction(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ADD FOREIGN ENTITY", "ADD FOREIGN ENTITY"), MIOLocalizeString("ADD NEW FOREIGN ENTITY", "ADD NEW FOREIGN ENTITY"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME", "NAME"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Default, this, function(this:DBMappingViewController){
            let name = avc.textFields[0].text;            
            this.addNewForeignEntity(name);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private showAddMappingRelationAction(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ADD MAPPING", "ADD MAPPING"), MIOLocalizeString("ADD NEW MAPPING RELATION", "ADD NEW MAPPING RELATION"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME", "NAME"));
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("ID", "ID"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Default, this, function(this:DBMappingViewController){
            let name = avc.textFields[0].text;
            let identifier = avc.textFields[1].text;

            this.addNewMappingRelation(name, identifier);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private addNewForeignEntity(name:string){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate
        let fe = MIOEntityDescription.insertNewObjectForEntityForName("IntegratorMappingForeignEntity", ad.managedObjectContext) as IntegratorMappingForeignEntity;
        fe.name = name;
        this.selectedConfig.addForeignEntitiesObject(fe);

        DBHelper.saveMainContext();
    }

    private addNewMappingRelation(name:string, identifier:string){
        if (name == null || name.length == 0) return;
        if (identifier == null || identifier.length == 0) return;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate
        let me = MIOEntityDescription.insertNewObjectForEntityForName("DBMappingEntity", ad.managedObjectContext) as DBMappingEntity;

        me.foreignEntityName = this.selectedMappingEntity;
        me.foreignName = name;
        me.foreignID = identifier;
        me.enviroment = this.selectedEnviroment;
        me.integrator = this.selectedIntegrator;

        DBHelper.saveMainContext();

        this.matchesDataSource.searchByCode(me.foreignID);
    }

    filterPredicateDidChange(controller:ColumnFilterController){
        this.invalidateFetch();
    }


}