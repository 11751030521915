class EllaborationCenterGlobalActionsViewController extends MUIViewController
{  
    private backButton:MUIButton = null;
    private nextButton:MUIButton = null;
    private formatComboBox:MUIComboBox = null;
    private sourceComboBox:MUIComboBox = null;
    private tableView:MUITableView = null;

    private selectedProductSource:ProductSource = null;
    private selectedFormat:Format = null;
    private selectedEverything:boolean = false;
    private selectedEllaborationCenter:EllaborationCenter = null;

    public delegate = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");
        this.backButton.setAction(this, function (){           
            MIONotificationCenter.defaultCenter().removeObserver(this, "globalActionsDidSelectCategory");  
            this.navigationController.popViewController(true);
        });
        
        this.nextButton = MUIOutlet(this, "next-btn", "MUIButton");
        this.nextButton.setAction(this, function (){
            //Check to see if ellaborationCenter is selected
            if (this.selectedEllaborationCenter){
                let sgavc = new SelectCategoryGlobalActionsViewController('select-category-global-actions-view');
                sgavc.initWithResource("layout/globalactions/SelectCategoryGlobalActionsView.html");
                this.navigationController.pushViewController(sgavc, true);   
            } else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('NO ELLABORATION CENTER SELECTED', 'NO ELLABORATION CENTER SELECTED'), MIOLocalizeString('PLEASE MAKE SELECTION','PLEASE MAKE SELECTION'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                this.presentViewController(error, true);
            }
        });
        
        this.formatComboBox = MUIOutlet(this, 'format-cb','MUIComboBox');
        this.setupFormatComboBox(this.formatComboBox);

        this.sourceComboBox = MUIOutlet(this, 'product-source-cb','MUIComboBox');
        this.setupSourceComboBox(this.sourceComboBox);

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        //CAUTION: be sure to remove the observer to avoid repeat observers
        MIONotificationCenter.defaultCenter().addObserver(this, "globalActionsDidSelectCategory", function(notification:MIONotification){
            let categories = notification.object as ProductCategory[];
            this.applyEllaborationCenter(this.selectedProductSource, this.selectedFormat, this.selectedEverything, this.selectedEllaborationCenter, categories);
        }); 
    }

    viewWillAppear(){
        super.viewWillAppear();
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        this.selectedProductSource = null;
        this.selectedFormat = null;
        this.selectedEverything = false;
        this.selectedEllaborationCenter = null;
        this.sourceComboBox.selectItem(MIOLocalizeString("ANY","ANY")); 
        this.formatComboBox.selectItem(MIOLocalizeString("BASE PRODUCT","BASE PRODUCT")); 

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableView:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
                                              
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){        
        let cell = tableView.dequeueReusableCellWithIdentifier("GlobalActionsLabelCell") as GlobalActionsLabelCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as EllaborationCenter;                      
        cell.item = item;
        return cell;
    }   

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){ 
        this.selectedEllaborationCenter = this.fetchedResultsController.objectAtIndexPath(indexPath) as EllaborationCenter;
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('name', true)];
    
        let fetchRequest = DBHelper.listFetchRequestWithEntityName('EllaborationCenter', sortDescriptors, null);
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }

    private setupFormatComboBox(comboBox:MUIComboBox){

        let availableFormats = null;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        comboBox.removeAllItems();

        comboBox.addItem(MIOLocalizeString("BASE PRODUCT","BASE PRODUCT"));  
        //get items and store them in availableVariable
        let sortDescriptors = MIOSortDescriptor.sortDescriptorWithKey('name', true);  
        DBHelper.queryObjectsWithCompletion("Format", sortDescriptors, null, [], this, function(objects){
            availableFormats = objects; 
            for (let index = 0; index < objects.length; index++){
                let tr = objects[index] as Format;
                comboBox.addItem(tr.name);
            }
            comboBox.addItem(MIOLocalizeString("EVERYTHING","EVERYTHING"));
        });

        comboBox.setOnChangeAction(this, function(control, index){            
            let formatName = comboBox.getSelectedItem();
            this.selectedFormat = null;
            this.selectedEverything = false;
            // find if both option is selected, leave selectedFormat null if true 
            if(formatName == MIOLocalizeString("EVERYTHING","EVERYTHING")){
                this.selectedEverything = true;
                return;
            }
            // try to find the matching format, if no format found then selectedFormat is null
            for (let i = 0; i < availableFormats.length; i++){
                if (formatName == availableFormats[i].name) {
                    this.selectedFormat = availableFormats[i];
                    break;
                }
            }
        });
    }

    private setupSourceComboBox(comboBox:MUIComboBox){

        let availableProductSources = null;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        comboBox.removeAllItems();

        comboBox.addItem(MIOLocalizeString("ANY","ANY"));  
        //get items and store them in availableVariable
        let sortDescriptors = MIOSortDescriptor.sortDescriptorWithKey('name', true);  
        DBHelper.queryObjectsWithCompletion("ProductSource", sortDescriptors, null, [], this, function(objects){
            availableProductSources = objects; 
            for (let index = 0; index < objects.length; index++){
                let tr = objects[index] as Format;
                comboBox.addItem(tr.name);
            }
        });

        comboBox.setOnChangeAction(this, function(control, index){            
            let sourceName = comboBox.getSelectedItem();
            this.selectedProductSource = null;
            // try to find the matching format, if no format then it is null
            for (let i = 0; i < availableProductSources.length; i++){
                if (sourceName == availableProductSources[i].name) {
                    this.selectedProductSource = availableProductSources[i];
                    break;
                }
            }
        });
    }

    private applyEllaborationCenter(selectedProductSource:ProductSource, selectedFormat:Format, selectedEverything:boolean, selectedEllaborationCenter:EllaborationCenter, selectedProductCategories:ProductCategory[]) {

        let sortDescriptors = MIOSortDescriptor.sortDescriptorWithKey('name', true); 
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

        //EITHER CALL PRODUCT OR PRODUCTFORMAT
        
        if (!selectedFormat || selectedEverything){  // if no format selected, make changes on base product
            
            let productPredicateString = "";

            // setup predicate for various categories 
            if (selectedProductCategories.length > 0) {     
                productPredicateString += "(";
                for (let i = 0; i < selectedProductCategories.length; i++) {
                    if(i != 0) productPredicateString += " OR ";
                    productPredicateString += "category.identifier = '" + selectedProductCategories[i].identifier + "'";
                }
                productPredicateString += ")";
            } 
            
            // Check product source option
            if(selectedProductCategories.length > 0) productPredicateString += " AND "; 
            if(selectedProductSource) productPredicateString += "source.identifier = '" + selectedProductSource.identifier + "'"; 

            let predicate = (productPredicateString != "") ? MIOPredicate.predicateWithFormat(productPredicateString) : null;
            
            DBHelper.queryObjectsWithCompletion("Product", sortDescriptors, predicate, ["category", "source", "productFormats"], this, function(objects){
                for (let index = 0; index < objects.length; index++){                    
                    objects[index].ellaborationCenter = selectedEllaborationCenter; //set the new EllaborationCenter
                }
                moc.save();
            });
        }

        if(selectedFormat || selectedEverything){ // if format exists, make changes on productFormat
            
            let formatPredicateString = "";

            // setup predicate for various categories 
            if (selectedProductCategories.length > 0) {    
                formatPredicateString += "(";      
                for (let i = 0; i < selectedProductCategories.length; i++) {
                    if(i != 0) formatPredicateString += " OR ";
                    formatPredicateString += "product.category.identifier = '" + selectedProductCategories[i].identifier + "'";
                }
                formatPredicateString += ")";
            } 
            
            // Check product source option
            if(selectedProductCategories.length > 0) formatPredicateString += " AND "; 
            if(selectedProductSource) formatPredicateString += "productSource.identifier = '" + selectedProductSource.identifier + "'"; 

            let predicate = (formatPredicateString != "") ? MIOPredicate.predicateWithFormat(formatPredicateString) : null;

            DBHelper.queryObjectsWithCompletion("ProductFormat", sortDescriptors, predicate, ["product", "product.category", "format", "productSource"], this, function(objects){
                for (let index = 0; index < objects.length; index++){
                    objects[index].ellaborationCenter = selectedEllaborationCenter; //set the new EllaborationCenter
                }
                moc.save();
            });
        }
    }
    
}