

/// <reference path="../stocknote/StockNoteCell.ts" />

class InventoryNoteCell extends StockNoteCell
{
    set item (item:StockNote){
        this.setItem(item);        
        this.setTitle(item.destinationName ? item.destinationName : MIOLocalizeString("DEFAULT_WAREHOUSE", "DEFAULT_WAREHOUSE"));
    }
}
