//
// Generated class InternalOrder
//

/// <reference path="InternalOrder_ManagedObject.ts" />

class InternalOrder extends InternalOrder_ManagedObject
{

}
