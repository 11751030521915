

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Integrator_ManagedObject

class Integrator_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: configValues
    set configValues(value:any) {
        this.setValueForKey(value, 'configValues');
    }
    get configValues():any {
        return this.valueForKey('configValues');
    }
    set configValuesPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'configValues');
    }
    get configValuesPrimitiveValue():any {
        return this.primitiveValueForKey('configValues');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: type
    set type(value:string) {
        this.setValueForKey(value, 'type');
    }
    get type():string {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():string {
        return this.primitiveValueForKey('type');
    }

    // Relationship: configs
    protected _configs:MIOManagedObjectSet = null;
    get configs():MIOManagedObjectSet {
        return this.valueForKey('configs');
    }
    addConfigsObject(value:IntegratorConfig) {
        this._addObjectForKey(value, 'configs');
    }
    removeConfigsObject(value:IntegratorConfig) {
        this._removeObjectForKey(value, 'configs');
    }
}
