

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class StockNote_ManagedObject

class StockNote_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: baseAmount
    set baseAmount(value:number) {
        this.setValueForKey(value, 'baseAmount');
    }
    get baseAmount():number {
        return this.valueForKey('baseAmount');
    }
    set baseAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'baseAmount');
    }
    get baseAmountPrimitiveValue():number {
        return this.primitiveValueForKey('baseAmount');
    }

    // Property: comments
    set comments(value:string) {
        this.setValueForKey(value, 'comments');
    }
    get comments():string {
        return this.valueForKey('comments');
    }
    set commentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'comments');
    }
    get commentsPrimitiveValue():string {
        return this.primitiveValueForKey('comments');
    }

    // Property: creationDate
    set creationDate(value:Date) {
        this.setValueForKey(value, 'creationDate');
    }
    get creationDate():Date {
        return this.valueForKey('creationDate');
    }
    set creationDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'creationDate');
    }
    get creationDatePrimitiveValue():Date {
        return this.primitiveValueForKey('creationDate');
    }

    // Property: creatorDocumentID
    set creatorDocumentID(value:string) {
        this.setValueForKey(value, 'creatorDocumentID');
    }
    get creatorDocumentID():string {
        return this.valueForKey('creatorDocumentID');
    }
    set creatorDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'creatorDocumentID');
    }
    get creatorDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('creatorDocumentID');
    }

    // Property: destinationName
    set destinationName(value:string) {
        this.setValueForKey(value, 'destinationName');
    }
    get destinationName():string {
        return this.valueForKey('destinationName');
    }
    set destinationNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'destinationName');
    }
    get destinationNamePrimitiveValue():string {
        return this.primitiveValueForKey('destinationName');
    }

    // Property: discountAmount
    set discountAmount(value:number) {
        this.setValueForKey(value, 'discountAmount');
    }
    get discountAmount():number {
        return this.valueForKey('discountAmount');
    }
    set discountAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'discountAmount');
    }
    get discountAmountPrimitiveValue():number {
        return this.primitiveValueForKey('discountAmount');
    }

    // Property: documentDate
    set documentDate(value:Date) {
        this.setValueForKey(value, 'documentDate');
    }
    get documentDate():Date {
        return this.valueForKey('documentDate');
    }
    set documentDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'documentDate');
    }
    get documentDatePrimitiveValue():Date {
        return this.primitiveValueForKey('documentDate');
    }

    // Property: documentID
    set documentID(value:string) {
        this.setValueForKey(value, 'documentID');
    }
    get documentID():string {
        return this.valueForKey('documentID');
    }
    set documentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentID');
    }
    get documentIDPrimitiveValue():string {
        return this.primitiveValueForKey('documentID');
    }

    // Property: documentPrefix
    set documentPrefix(value:string) {
        this.setValueForKey(value, 'documentPrefix');
    }
    get documentPrefix():string {
        return this.valueForKey('documentPrefix');
    }
    set documentPrefixPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentPrefix');
    }
    get documentPrefixPrimitiveValue():string {
        return this.primitiveValueForKey('documentPrefix');
    }

    // Property: externalReference
    set externalReference(value:string) {
        this.setValueForKey(value, 'externalReference');
    }
    get externalReference():string {
        return this.valueForKey('externalReference');
    }
    set externalReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'externalReference');
    }
    get externalReferencePrimitiveValue():string {
        return this.primitiveValueForKey('externalReference');
    }

    // Property: externalReferenceID
    set externalReferenceID(value:string) {
        this.setValueForKey(value, 'externalReferenceID');
    }
    get externalReferenceID():string {
        return this.valueForKey('externalReferenceID');
    }
    set externalReferenceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'externalReferenceID');
    }
    get externalReferenceIDPrimitiveValue():string {
        return this.primitiveValueForKey('externalReferenceID');
    }

    // Property: isPartial
    set isPartial(value:boolean) {
        this.setValueForKey(value, 'isPartial');
    }
    get isPartial():boolean {
        return this.valueForKey('isPartial');
    }
    set isPartialPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isPartial');
    }
    get isPartialPrimitiveValue():boolean {
        return this.primitiveValueForKey('isPartial');
    }

    // Property: originName
    set originName(value:string) {
        this.setValueForKey(value, 'originName');
    }
    get originName():string {
        return this.valueForKey('originName');
    }
    set originNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'originName');
    }
    get originNamePrimitiveValue():string {
        return this.primitiveValueForKey('originName');
    }

    // Property: overrideWarehouseName
    set overrideWarehouseName(value:string) {
        this.setValueForKey(value, 'overrideWarehouseName');
    }
    get overrideWarehouseName():string {
        return this.valueForKey('overrideWarehouseName');
    }
    set overrideWarehouseNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'overrideWarehouseName');
    }
    get overrideWarehouseNamePrimitiveValue():string {
        return this.primitiveValueForKey('overrideWarehouseName');
    }

    // Property: parentDocumentID
    set parentDocumentID(value:string) {
        this.setValueForKey(value, 'parentDocumentID');
    }
    get parentDocumentID():string {
        return this.valueForKey('parentDocumentID');
    }
    set parentDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'parentDocumentID');
    }
    get parentDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('parentDocumentID');
    }

    // Property: sendEmail
    set sendEmail(value:number) {
        this.setValueForKey(value, 'sendEmail');
    }
    get sendEmail():number {
        return this.valueForKey('sendEmail');
    }
    set sendEmailPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'sendEmail');
    }
    get sendEmailPrimitiveValue():number {
        return this.primitiveValueForKey('sendEmail');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: stockDate
    set stockDate(value:Date) {
        this.setValueForKey(value, 'stockDate');
    }
    get stockDate():Date {
        return this.valueForKey('stockDate');
    }
    set stockDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'stockDate');
    }
    get stockDatePrimitiveValue():Date {
        return this.primitiveValueForKey('stockDate');
    }

    // Property: taxesAmount
    set taxesAmount(value:number) {
        this.setValueForKey(value, 'taxesAmount');
    }
    get taxesAmount():number {
        return this.valueForKey('taxesAmount');
    }
    set taxesAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'taxesAmount');
    }
    get taxesAmountPrimitiveValue():number {
        return this.primitiveValueForKey('taxesAmount');
    }

    // Property: totalAmount
    set totalAmount(value:number) {
        this.setValueForKey(value, 'totalAmount');
    }
    get totalAmount():number {
        return this.valueForKey('totalAmount');
    }
    set totalAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalAmount');
    }
    get totalAmountPrimitiveValue():number {
        return this.primitiveValueForKey('totalAmount');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: validationDate
    set validationDate(value:Date) {
        this.setValueForKey(value, 'validationDate');
    }
    get validationDate():Date {
        return this.valueForKey('validationDate');
    }
    set validationDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'validationDate');
    }
    get validationDatePrimitiveValue():Date {
        return this.primitiveValueForKey('validationDate');
    }

    // Property: validationWorkerID
    set validationWorkerID(value:string) {
        this.setValueForKey(value, 'validationWorkerID');
    }
    get validationWorkerID():string {
        return this.valueForKey('validationWorkerID');
    }
    set validationWorkerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'validationWorkerID');
    }
    get validationWorkerIDPrimitiveValue():string {
        return this.primitiveValueForKey('validationWorkerID');
    }

    // Property: validationWorkerName
    set validationWorkerName(value:string) {
        this.setValueForKey(value, 'validationWorkerName');
    }
    get validationWorkerName():string {
        return this.valueForKey('validationWorkerName');
    }
    set validationWorkerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'validationWorkerName');
    }
    get validationWorkerNamePrimitiveValue():string {
        return this.primitiveValueForKey('validationWorkerName');
    }

    // Property: workerID
    set workerID(value:string) {
        this.setValueForKey(value, 'workerID');
    }
    get workerID():string {
        return this.valueForKey('workerID');
    }
    set workerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerID');
    }
    get workerIDPrimitiveValue():string {
        return this.primitiveValueForKey('workerID');
    }

    // Property: workerName
    set workerName(value:string) {
        this.setValueForKey(value, 'workerName');
    }
    get workerName():string {
        return this.valueForKey('workerName');
    }
    set workerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerName');
    }
    get workerNamePrimitiveValue():string {
        return this.primitiveValueForKey('workerName');
    }
    // Relationship: creatorNote
    set creatorNote(value:StockNote) {
        this.setValueForKey(value, 'creatorNote');
    }
    get creatorNote():StockNote {
        return this.valueForKey('creatorNote') as StockNote;
    }
    // Relationship: destinationConcept
    set destinationConcept(value:StockCustomConcept) {
        this.setValueForKey(value, 'destinationConcept');
    }
    get destinationConcept():StockCustomConcept {
        return this.valueForKey('destinationConcept') as StockCustomConcept;
    }
    // Relationship: destinationEntity
    set destinationEntity(value:LegalEntity) {
        this.setValueForKey(value, 'destinationEntity');
    }
    get destinationEntity():LegalEntity {
        return this.valueForKey('destinationEntity') as LegalEntity;
    }
    // Relationship: destinationWarehouse
    set destinationWarehouse(value:Warehouse) {
        this.setValueForKey(value, 'destinationWarehouse');
    }
    get destinationWarehouse():Warehouse {
        return this.valueForKey('destinationWarehouse') as Warehouse;
    }

    // Relationship: lines
    protected _lines:MIOManagedObjectSet = null;
    get lines():MIOManagedObjectSet {
        return this.valueForKey('lines');
    }
    addLinesObject(value:StockNoteLine) {
        this._addObjectForKey(value, 'lines');
    }
    removeLinesObject(value:StockNoteLine) {
        this._removeObjectForKey(value, 'lines');
    }
    // Relationship: nextDocument
    set nextDocument(value:DBDocument) {
        this.setValueForKey(value, 'nextDocument');
    }
    get nextDocument():DBDocument {
        return this.valueForKey('nextDocument') as DBDocument;
    }

    // Relationship: nextStockNotes
    protected _nextStockNotes:MIOManagedObjectSet = null;
    get nextStockNotes():MIOManagedObjectSet {
        return this.valueForKey('nextStockNotes');
    }
    addNextStockNotesObject(value:StockNote) {
        this._addObjectForKey(value, 'nextStockNotes');
    }
    removeNextStockNotesObject(value:StockNote) {
        this._removeObjectForKey(value, 'nextStockNotes');
    }

    // Relationship: notes
    protected _notes:MIOManagedObjectSet = null;
    get notes():MIOManagedObjectSet {
        return this.valueForKey('notes');
    }
    addNotesObject(value:StockNote) {
        this._addObjectForKey(value, 'notes');
    }
    removeNotesObject(value:StockNote) {
        this._removeObjectForKey(value, 'notes');
    }
    // Relationship: originConcept
    set originConcept(value:StockCustomConcept) {
        this.setValueForKey(value, 'originConcept');
    }
    get originConcept():StockCustomConcept {
        return this.valueForKey('originConcept') as StockCustomConcept;
    }
    // Relationship: originEntity
    set originEntity(value:LegalEntity) {
        this.setValueForKey(value, 'originEntity');
    }
    get originEntity():LegalEntity {
        return this.valueForKey('originEntity') as LegalEntity;
    }
    // Relationship: originWarehouse
    set originWarehouse(value:Warehouse) {
        this.setValueForKey(value, 'originWarehouse');
    }
    get originWarehouse():Warehouse {
        return this.valueForKey('originWarehouse') as Warehouse;
    }
    // Relationship: overrideWarehouse
    set overrideWarehouse(value:Warehouse) {
        this.setValueForKey(value, 'overrideWarehouse');
    }
    get overrideWarehouse():Warehouse {
        return this.valueForKey('overrideWarehouse') as Warehouse;
    }
    // Relationship: parent
    set parent(value:StockNote) {
        this.setValueForKey(value, 'parent');
    }
    get parent():StockNote {
        return this.valueForKey('parent') as StockNote;
    }

    // Relationship: previousStockNotes
    protected _previousStockNotes:MIOManagedObjectSet = null;
    get previousStockNotes():MIOManagedObjectSet {
        return this.valueForKey('previousStockNotes');
    }
    addPreviousStockNotesObject(value:StockNote) {
        this._addObjectForKey(value, 'previousStockNotes');
    }
    removePreviousStockNotesObject(value:StockNote) {
        this._removeObjectForKey(value, 'previousStockNotes');
    }
}
