

/// <reference path="StockNote.ts" />

// Generated class InventoryNote_ManagedObject

class InventoryNote_ManagedObject extends StockNote
{
    // Relationship: template
    set template(value:StockTemplate) {
        this.setValueForKey(value, 'template');
    }
    get template():StockTemplate {
        return this.valueForKey('template') as StockTemplate;
    }
}
