

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class EmployeeSalaryTransaction_ManagedObject

class EmployeeSalaryTransaction_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: amount
    set amount(value:number) {
        this.setValueForKey(value, 'amount');
    }
    get amount():number {
        return this.valueForKey('amount');
    }
    set amountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'amount');
    }
    get amountPrimitiveValue():number {
        return this.primitiveValueForKey('amount');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Relationship: concepts
    protected _concepts:MIOManagedObjectSet = null;
    get concepts():MIOManagedObjectSet {
        return this.valueForKey('concepts');
    }
    addConceptsObject(value:EmployeeSalaryConcept) {
        this._addObjectForKey(value, 'concepts');
    }
    removeConceptsObject(value:EmployeeSalaryConcept) {
        this._removeObjectForKey(value, 'concepts');
    }
}
