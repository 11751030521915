/**
 * Created by miguel on 23/2/17.
 */



class LapsopayPlaceCell extends MUITableViewCell
{
    nameLabel = null;

    private _separator = null;

    awakeFromHTML()
    {
        this.nameLabel = MUIOutlet(this, "lplv_tv_place_name_lbl", "MUILabel");

        this._separator = MUIOutlet(this, "lplv_tv_separator", "MUIView");
    }

    setSelected(value)
    {
        super.setSelected(value);

        if (value == true)
            this._separator.setBackgroundRGBColor(0, 191, 191);
        else
            this._separator.setBackgroundRGBColor(237, 237, 242);
    }

    set item(i)
    {
        this.nameLabel.text = i.name;
    }

}