

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class LocalizedName_ManagedObject

class LocalizedName_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: language
    set language(value:string) {
        this.setValueForKey(value, 'language');
    }
    get language():string {
        return this.valueForKey('language');
    }
    set languagePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'language');
    }
    get languagePrimitiveValue():string {
        return this.primitiveValueForKey('language');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }
    // Relationship: report
    set report(value:Report) {
        this.setValueForKey(value, 'report');
    }
    get report():Report {
        return this.valueForKey('report') as Report;
    }
}
