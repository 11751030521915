

/// <reference path="BankAccountInfo.ts" />

// Generated class LegalEntityBankAccountInfo_ManagedObject

class LegalEntityBankAccountInfo_ManagedObject extends BankAccountInfo
{
    // Relationship: legalEntity
    set legalEntity(value:LegalEntity) {
        this.setValueForKey(value, 'legalEntity');
    }
    get legalEntity():LegalEntity {
        return this.valueForKey('legalEntity') as LegalEntity;
    }
}
