class SyncLogViewController extends BaseTableViewController
{

    private refreshButton:MUIButton = null;
    private appDropdownButton:MUIButton = null;
    private statusComboBox:MUIComboBox = null;
    private selectedAppID = null;
    private selectedStatus = -1;

    private orderIndexFilterTextField:ColumnFilterTextField = null; 

    private columnFilterController:ColumnFilterController = null;


    viewDidLoad(){
        super.viewDidLoad();
        
        this.refreshButton = MUIOutlet(this, "refresh-btn", "MUIButton");
        this.refreshButton.setAction(this, function(this:SyncLogViewController) {
            //TODO: This make crash the manager, because it reset everything
            // let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            // ad.managedObjectContext.reset();
            this.updateUI();
        });

        this.appDropdownButton = MUIOutlet(this, 'app-dropdown-btn', 'MUIButton');
        this.appDropdownButton.setAction(this, function(this:SyncLogViewController) {
            this.showAppSelectEntityViewController(this.appDropdownButton, "Application");
        });

        this.statusComboBox = MUIOutlet(this, "status-cb", "MUIComboBox");
        this.statusComboBox.setOnChangeAction(this, function(control, value){
            if (value == "default"){
                this.selectedStatus = null;
            } else {
                this.selectedStatus = parseInt(value);
            }
            this.updateUI();
        });

        this.columnFilterController = new ColumnFilterController();
        this.columnFilterController.initWithDelegate(this);

        this.orderIndexFilterTextField = MUIOutlet(this, "order-index-filter-tf", "ColumnFilterTextField");
        this.orderIndexFilterTextField.filterController = this.columnFilterController;
        this.orderIndexFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "orderIndex", null, null);

        this.tableView = MUIOutlet(this, "table-view", "UITableView");        
        this.tableView.dataSource = this;
        this.tableView.delegate = this;       
    }

    // viewWillAppear(){
    //     super.viewWillAppear();
    //     this.updateUI();
    // }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }
 
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as SyncLog;
        let cell = tableview.dequeueReusableCellWithIdentifier('SyncLogCell') as SyncLogCell;
        cell.item = item;        

        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as SyncLog;        

        let vc = SyncLogEditViewController.newInstance();
        vc.item = item;

        this.navigationController.pushViewController(vc, true);
    }

    // editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
    //     return MUITableViewCellEditingStyle.Delete;
    // }

    // commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {
    //     let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as User;

    //     if (editingStyle == MUITableViewCellEditingStyle.Delete) {
    //         this.removeUser(item);
    //     }
    // }

    protected _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value) {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        //setup the predicate format string
        let predicates = ["fileType != 1"];
        if (this.selectedAppID != null) predicates.addObject( "appID == '" + this.selectedAppID + "'" );
        if (this.selectedStatus != null) predicates.addObject( "status == " + this.selectedStatus);
        let predicateFormat = this.columnFilterController.filterPredicateFormat();
        if ( predicateFormat ) { 
            predicates.addObject( "(" + predicateFormat + ")")
        } 

        // let logs = []
        // for (let l of this.errorLogs) {
        //     logs.addObject("identifier = " + l.identifier);
        // }        
        // if (logs.length > 0) predicates.addObject(logs.join(" OR "));
        this.errorLogs = []

        let predicate = predicates.length > 0 ? MIOPredicate.predicateWithFormat(predicates.join(" AND ")) : null;
    
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("SyncLog");
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("orderIndex", false)];
        fetchRequest.predicate = predicate;
        fetchRequest.fetchLimit = 150;
       
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    private errorLogs = []
    protected reloadData(): void {
        super.reloadData();
        if (this.selectedStatus == -1) {
            this.errorLogs = this.fetchedResultsController.fetchedObjects;
        }
    }

    private showAppSelectEntityViewController(dropdownButton, entityName) {

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let predicateFormat = "placeID == " + ad.selectedIdentifier; 
        let vc = AppHelper.sharedInstance().showCustomSelectViewControllerFromView(dropdownButton, entityName, "name", predicateFormat, this, function(controller, object:MIOManagedObject){

            if (object == null) {
                this.selectedAppID = null;
                dropdownButton.title = "NO APP";
            }
            else {
                let title = object.valueForKey("name");
                let appID = object.valueForKey("identifier");
                dropdownButton.title = title;    
                this.selectedAppID = appID ? appID : null;
            }
            this.updateUI();
        }, true);                  
    }

    filterPredicateDidChange(controller:ColumnFilterController){
        this.invalidateFetch();
        //this.tableView.reloadData();
    }





}