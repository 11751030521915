/**
 * Created by miguel on 23/2/17.
 */


class OffersListViewController extends BaseTableViewController
{
    private addButton:MUIButton = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, 'add-btn','MUIButton');
        this.addButton.setAction(this, function(){
            this.addOffer();
        });

        this.tableView = MUIOutlet(this, 'table-view', 'UITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }
    
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('OfferCell') as OfferCell;        

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);    
        cell.item = item;        
        cell.selected = (item == this.selectedItem);
    
        return cell;
    }
        
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath) as Offer;
        
        let vc  = new OfferDetailViewController('offer-detail-view');
        vc.initWithResource('layout/offers/OfferDetailView.html');   
        
        vc.offer = this.selectedItem;
        this.splitViewController.showDetailViewController(vc);
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let zone = this.fetchedResultsController.objectAtIndexPath(indexPath);    

        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            this.removeOffer(zone);
        }
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
   
        let sds = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Offer", sds, null);       
    
        // if (this._searchString &&  this._searchString.length > 0)
        //     fetchRequest.predicate = MIOPredicate.predicateWithFormat('name CONTAINS ' + this._searchString);
        

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
        
    private addOffer(){

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('NEW OFFER','NEW OFFER'), MIOLocalizeString('TYPE THE NAME OF THE OFFER','TYPE THE NAME OF THE OFFER'), MUIAlertViewStyle.Default);
        
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });

        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
            comboBox.removeAllItems();
            let types = Offer.typeStrings();
            for (let index = 0; index < types.length; index++){
                let offerType = types[index];
                comboBox.addItem(offerType, index);
            }
        });

        avc.addAction( MUIAlertAction.alertActionWithTitle('OK', MUIAlertActionStyle.Default, this, function(){
            let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
            let offer = MIOEntityDescription.insertNewObjectForEntityForName("Offer", ad.managedObjectContext) as Offer;
            offer.name = avc.textFields[0].text;
            offer.type = parseInt(avc.comboBoxes[0].getSelectedItem());
            ad.managedObjectContext.save();
        }));
        
        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, null, null));
        
        this.presentViewController(avc, true);        
    }

    removeOffer(offer:Offer){
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

        moc.deleteObject(offer);
        moc.save();    
    }
}