

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class MarginProducts_ManagedObject

class MarginProducts_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: increment
    set increment(value:number) {
        this.setValueForKey(value, 'increment');
    }
    get increment():number {
        return this.valueForKey('increment');
    }
    set incrementPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'increment');
    }
    get incrementPrimitiveValue():number {
        return this.primitiveValueForKey('increment');
    }

    // Property: productFormatID
    set productFormatID(value:string) {
        this.setValueForKey(value, 'productFormatID');
    }
    get productFormatID():string {
        return this.valueForKey('productFormatID');
    }
    set productFormatIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productFormatID');
    }
    get productFormatIDPrimitiveValue():string {
        return this.primitiveValueForKey('productFormatID');
    }

    // Property: productFormatName
    set productFormatName(value:string) {
        this.setValueForKey(value, 'productFormatName');
    }
    get productFormatName():string {
        return this.valueForKey('productFormatName');
    }
    set productFormatNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productFormatName');
    }
    get productFormatNamePrimitiveValue():string {
        return this.primitiveValueForKey('productFormatName');
    }

    // Property: productID
    set productID(value:string) {
        this.setValueForKey(value, 'productID');
    }
    get productID():string {
        return this.valueForKey('productID');
    }
    set productIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productID');
    }
    get productIDPrimitiveValue():string {
        return this.primitiveValueForKey('productID');
    }

    // Property: productName
    set productName(value:string) {
        this.setValueForKey(value, 'productName');
    }
    get productName():string {
        return this.valueForKey('productName');
    }
    set productNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productName');
    }
    get productNamePrimitiveValue():string {
        return this.primitiveValueForKey('productName');
    }
    // Relationship: margin
    set margin(value:Margin) {
        this.setValueForKey(value, 'margin');
    }
    get margin():Margin {
        return this.valueForKey('margin') as Margin;
    }
}
