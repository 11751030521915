
class TagsConfigurationCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, 'name-lbl', 'MUILabel');            
    }
    
    set name(title:string){
        this.nameLabel.text = title;
    }
}
