
class PartyDetailViewController extends MUIViewController
{
    private ruleTypeComboBox:MUIComboBox = null;
    private segmentedControl:MUISegmentedControl = null;
    private saveButton:MUIButton = null;
    private addButton:MUIButton = null;
    private pageController:MUIPageController = null;

    private infoViewController:PartyDetailInfoViewController = null;
    private setupViewController:PartyDetailSetupViewController = null;
    private zoneSetupViewController:PartyDetailZoneSetupViewController = null;
    private datesViewController:PartyDetailDatedViewController = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.ruleTypeComboBox = MUIOutlet(this, "rule-type-cb", "MUIComboBox");
        this.ruleTypeComboBox.setOnChangeAction(this, function(control, index){
            this.zoneSetupViewController.ruleType = parseInt(index);
        });

        this.segmentedControl = MUIOutlet(this, "segmented-control", "MUISegmentedControl");
        this.segmentedControl.setAction(this, function(control, index){
            this.pageController.showPageAtIndex(index);
            this.addButton.setHidden(index == 0);
        });

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        });

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setHidden(true);
        this.addButton.setAction(this, this.addAction);        

        this.pageController = MUIOutlet(this, "page-controller", "MUIPageController");
                
        this.infoViewController = new PartyDetailInfoViewController("party-detail-info-view");
        this.infoViewController.initWithResource('layout/party/PartyDetailInfoView.html');
        this.pageController.addPageViewController(this.infoViewController);

        this.setupViewController = new PartyDetailSetupViewController("party-setup-view");
        this.setupViewController.initWithResource("layout/party/PartySetupView.html");
        this.pageController.addPageViewController(this.setupViewController);

        this.zoneSetupViewController = new PartyDetailZoneSetupViewController("parties-booking-zone-setup");
        this.zoneSetupViewController.initWithResource("layout/parties/PartiesBookingZoneSetupView.html");
        this.pageController.addPageViewController(this.zoneSetupViewController);

        this.datesViewController = new PartyDetailDatedViewController("party-detail-dates-view");
        this.datesViewController.initWithResource('layout/party/PartyDetailDatesView.html');   
        this.pageController.addPageViewController(this.datesViewController);
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _party:Party = null;
    set party(party:Party){
        this._party = party;
        this.updateUI();        
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._party == null) return;
        if (this.infoViewController != null) this.infoViewController.party = this._party;
        if (this.setupViewController != null) this.setupViewController.party = this._party;
        if (this.zoneSetupViewController != null) this.zoneSetupViewController.party = this._party;
        if (this.datesViewController != null) this.datesViewController.party = this._party;        
    }

    private addAction(){
        switch(this.segmentedControl.selectedSegmentedIndex){
            case 2: 
                this.addZoneRule();
                break;
            case 3:
                this.addPartyDates();
                break;
        }
    }

    private addZoneRule(){
        this.zoneSetupViewController.addRule();
    }

    private addPartyDates(){
        let vc = new SelectDateIntervalViewController("select-date-interval-view");
        vc.initWithResource("layout/base/SelectDateIntervalView.html");
        vc.delegate = this;

        this.presentViewController(vc, true);
    }
    
    datesDidSelected(controller:SelectDateIntervalViewController, dates){
        
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        for (let index = 0; index < dates.length; index++){
            let date = dates[index];
            let endDate = new Date(date.getTime());
            endDate.setDate(endDate.getDate() + 1);

            let pe = PartyEvent.insertPartyEvent(false, ad.managedObjectContext);
            pe.identifier = MIOUUID.UUID().UUIDString;
            pe.name = this._party.name;
            pe.beginDateTime = date;
            pe.endDateTime = endDate;
            pe.party = this._party;
            pe.externalReference = pe.identifier.toLocaleLowerCase();
        }
        
        ad.managedObjectContext.save();
    }

}