//
// Generated class PresetBlank
//

/// <reference path="PresetBlank_ManagedObject.ts" />

class PresetBlank extends PresetBlank_ManagedObject
{

}
