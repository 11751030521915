

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class BookingLog_ManagedObject

class BookingLog_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: action
    set action(value:string) {
        this.setValueForKey(value, 'action');
    }
    get action():string {
        return this.valueForKey('action');
    }
    set actionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'action');
    }
    get actionPrimitiveValue():string {
        return this.primitiveValueForKey('action');
    }

    // Property: bookingID
    set bookingID(value:string) {
        this.setValueForKey(value, 'bookingID');
    }
    get bookingID():string {
        return this.valueForKey('bookingID');
    }
    set bookingIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'bookingID');
    }
    get bookingIDPrimitiveValue():string {
        return this.primitiveValueForKey('bookingID');
    }

    // Property: data
    set data(value:any) {
        this.setValueForKey(value, 'data');
    }
    get data():any {
        return this.valueForKey('data');
    }
    set dataPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'data');
    }
    get dataPrimitiveValue():any {
        return this.primitiveValueForKey('data');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: process
    set process(value:boolean) {
        this.setValueForKey(value, 'process');
    }
    get process():boolean {
        return this.valueForKey('process');
    }
    set processPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'process');
    }
    get processPrimitiveValue():boolean {
        return this.primitiveValueForKey('process');
    }

    // Property: processerrors
    set processerrors(value:string) {
        this.setValueForKey(value, 'processerrors');
    }
    get processerrors():string {
        return this.valueForKey('processerrors');
    }
    set processerrorsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'processerrors');
    }
    get processerrorsPrimitiveValue():string {
        return this.primitiveValueForKey('processerrors');
    }

    // Property: userEmail
    set userEmail(value:string) {
        this.setValueForKey(value, 'userEmail');
    }
    get userEmail():string {
        return this.valueForKey('userEmail');
    }
    set userEmailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'userEmail');
    }
    get userEmailPrimitiveValue():string {
        return this.primitiveValueForKey('userEmail');
    }

    // Property: userID
    set userID(value:string) {
        this.setValueForKey(value, 'userID');
    }
    get userID():string {
        return this.valueForKey('userID');
    }
    set userIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'userID');
    }
    get userIDPrimitiveValue():string {
        return this.primitiveValueForKey('userID');
    }

    // Property: userName
    set userName(value:string) {
        this.setValueForKey(value, 'userName');
    }
    get userName():string {
        return this.valueForKey('userName');
    }
    set userNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'userName');
    }
    get userNamePrimitiveValue():string {
        return this.primitiveValueForKey('userName');
    }

    // Property: workerEmail
    set workerEmail(value:string) {
        this.setValueForKey(value, 'workerEmail');
    }
    get workerEmail():string {
        return this.valueForKey('workerEmail');
    }
    set workerEmailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerEmail');
    }
    get workerEmailPrimitiveValue():string {
        return this.primitiveValueForKey('workerEmail');
    }

    // Property: workerID
    set workerID(value:string) {
        this.setValueForKey(value, 'workerID');
    }
    get workerID():string {
        return this.valueForKey('workerID');
    }
    set workerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerID');
    }
    get workerIDPrimitiveValue():string {
        return this.primitiveValueForKey('workerID');
    }

    // Property: workerName
    set workerName(value:string) {
        this.setValueForKey(value, 'workerName');
    }
    get workerName():string {
        return this.valueForKey('workerName');
    }
    set workerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerName');
    }
    get workerNamePrimitiveValue():string {
        return this.primitiveValueForKey('workerName');
    }
}
