//
// Generated class OrderAnswer
//

/// <reference path="OrderAnswer_ManagedObject.ts" />

class OrderAnswer extends OrderAnswer_ManagedObject
{

}
