

/// <reference path="CoreEntity.ts" />

// Generated class ProductCostBalance_ManagedObject

class ProductCostBalance_ManagedObject extends CoreEntity
{

    // Property: componentPrice
    set componentPrice(value:number) {
        this.setValueForKey(value, 'componentPrice');
    }
    get componentPrice():number {
        return this.valueForKey('componentPrice');
    }
    set componentPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'componentPrice');
    }
    get componentPricePrimitiveValue():number {
        return this.primitiveValueForKey('componentPrice');
    }

    // Property: computedCost
    set computedCost(value:number) {
        this.setValueForKey(value, 'computedCost');
    }
    get computedCost():number {
        return this.valueForKey('computedCost');
    }
    set computedCostPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'computedCost');
    }
    get computedCostPrimitiveValue():number {
        return this.primitiveValueForKey('computedCost');
    }

    // Property: computedCostDate
    set computedCostDate(value:Date) {
        this.setValueForKey(value, 'computedCostDate');
    }
    get computedCostDate():Date {
        return this.valueForKey('computedCostDate');
    }
    set computedCostDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'computedCostDate');
    }
    get computedCostDatePrimitiveValue():Date {
        return this.primitiveValueForKey('computedCostDate');
    }

    // Property: factor
    set factor(value:number) {
        this.setValueForKey(value, 'factor');
    }
    get factor():number {
        return this.valueForKey('factor');
    }
    set factorPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'factor');
    }
    get factorPrimitiveValue():number {
        return this.primitiveValueForKey('factor');
    }

    // Property: level
    set level(value:number) {
        this.setValueForKey(value, 'level');
    }
    get level():number {
        return this.valueForKey('level');
    }
    set levelPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'level');
    }
    get levelPrimitiveValue():number {
        return this.primitiveValueForKey('level');
    }

    // Property: minMeasureUnitType
    set minMeasureUnitType(value:number) {
        this.setValueForKey(value, 'minMeasureUnitType');
    }
    get minMeasureUnitType():number {
        return this.valueForKey('minMeasureUnitType');
    }
    set minMeasureUnitTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minMeasureUnitType');
    }
    get minMeasureUnitTypePrimitiveValue():number {
        return this.primitiveValueForKey('minMeasureUnitType');
    }

    // Property: productContainerMeasureType
    set productContainerMeasureType(value:number) {
        this.setValueForKey(value, 'productContainerMeasureType');
    }
    get productContainerMeasureType():number {
        return this.valueForKey('productContainerMeasureType');
    }
    set productContainerMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productContainerMeasureType');
    }
    get productContainerMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('productContainerMeasureType');
    }

    // Property: productContainerQuantity
    set productContainerQuantity(value:number) {
        this.setValueForKey(value, 'productContainerQuantity');
    }
    get productContainerQuantity():number {
        return this.valueForKey('productContainerQuantity');
    }
    set productContainerQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productContainerQuantity');
    }
    get productContainerQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('productContainerQuantity');
    }

    // Property: productMeasureType
    set productMeasureType(value:number) {
        this.setValueForKey(value, 'productMeasureType');
    }
    get productMeasureType():number {
        return this.valueForKey('productMeasureType');
    }
    set productMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productMeasureType');
    }
    get productMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('productMeasureType');
    }

    // Relationship: children
    protected _children:MIOManagedObjectSet = null;
    get children():MIOManagedObjectSet {
        return this.valueForKey('children');
    }
    addChildrenObject(value:ProductCostBalance) {
        this._addObjectForKey(value, 'children');
    }
    removeChildrenObject(value:ProductCostBalance) {
        this._removeObjectForKey(value, 'children');
    }
    // Relationship: parent
    set parent(value:ProductCostBalance) {
        this.setValueForKey(value, 'parent');
    }
    get parent():ProductCostBalance {
        return this.valueForKey('parent') as ProductCostBalance;
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
    // Relationship: warehouse
    set warehouse(value:Warehouse) {
        this.setValueForKey(value, 'warehouse');
    }
    get warehouse():Warehouse {
        return this.valueForKey('warehouse') as Warehouse;
    }
}
