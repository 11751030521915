class  ProductAdminCell extends UITableViewCell
{
    private venueNameLabel:MUILabel = null;
    private categoryNameLabel:MUILabel = null;    
    private productNameLabel:MUILabel = null;    
    private priceLabel:MUILabel = null;
    private priceTextField:MUITextField = null;
    private enabledSalesSwitch:MUISwitchButton = null;
    private defaultSupplierDropdown:MUIButton = null;
    private lastSupplierPriceLabel:MUILabel = null;
    private lastSupplierNameLabel:MUILabel = null;

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;

    awakeFromHTML(){
        this.venueNameLabel = MUIOutlet(this, "venue-lbl", "MUILabel");
        this.categoryNameLabel = MUIOutlet(this, "category-lbl", "MUILabel");        
        this.productNameLabel = MUIOutlet(this, "name-lbl", "MUILabel");        
        this.priceLabel = MUIOutlet(this, "price-lbl", "MUILabel");
        this.priceTextField = MUIOutlet(this, "price-tf", "MUITextField");
        this.priceTextField.setOnChangeText(this, function(this:ProductAdminCell, control:MUITextField, value:string){
            let price = this.cf.numberFromString(value);
            this._item.price = price == null ? 0 : price;
        });
        this.enabledSalesSwitch = MUIOutlet(this, "enabled-sales-sw", "MUISwitchButton");
        this.enabledSalesSwitch.setOnChangeValue(this, function(this:ProductAdminCell, control:MUISwitchButton, value:boolean) {             
            this._item.isEnableForSell = value;
        });
        this.defaultSupplierDropdown = MUIOutlet(this, "default-supplier-dd", "MUIButton");
        this.defaultSupplierDropdown.setAction( this, function( this:ProductAdminCell ) {
            AppHelper.sharedInstance().showSelectSupplierViewControllerFromView( this.defaultSupplierDropdown, null, true, this, function( this:ProductAdminCell, controller:SelectViewController, supplier:Supplier ){
                this._item.defaultSupplier = supplier;
                this._item.defaultSupplierName = supplier?.name;
                this.defaultSupplierDropdown.title = supplier?.name;
                MUICoreLayerRemoveStyle(this.defaultSupplierDropdown.layer, "light");
            } ); 
        });

        this.lastSupplierPriceLabel = MUIOutlet(this, "last-supplier-price-lbl", "MUILabel");
        this.lastSupplierNameLabel = MUIOutlet(this, "last-supplier-name-lbl", "MUILabel");        

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private _item:ProductPlace = null;
    setItem (item:ProductPlace, placeName:string){
        this._item = item;

        this.venueNameLabel.text = placeName;
        this.categoryNameLabel.text = item.product.category.name;
        this.productNameLabel.text = item.product.productNameDescription();
        this.priceLabel.text = this.cf.stringFromNumber(item.product.price);
        this.priceTextField.text = this.cf.stringFromNumber(item.price);
        this.enabledSalesSwitch.on = item.isEnableForSell;        
        this.lastSupplierPriceLabel.text = this.cf.stringFromNumber(item.lastSupplierProductPrice);
        this.lastSupplierNameLabel.text = item.lastSupplierName;
        this.defaultSupplierDropdown.title = item.defaultSupplierName;

        if (item.defaultSupplierName == null) {
            this.defaultSupplierDropdown.title = item.product.defaultSupplierName;
            MUICoreLayerAddStyle(this.defaultSupplierDropdown.layer, "light");
        }
        
    }    
}