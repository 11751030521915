//
// Generated class IntegratorConfig
//

/// <reference path="IntegratorConfig_ManagedObject.ts" />

class IntegratorConfig extends IntegratorConfig_ManagedObject
{

}
