/**
 * Created by miguel on 21/4/17.
 */


enum StockFilter 
{
    StockFilterAll,    
    StockFilterNoSale,
    StockFilterSale
}

enum StockWarehouseFilter
{
    AllWarehouse,
    DefaultWarehouse,
    SelectedWarehouse
}

class StockViewController extends EntityListViewController
{
    private warehouseButton:MUIButton = null;
    private segmentedControl:MUISegmentedControl = null;    
    private exportButton:MUIButton = null;

    private reportView:MUIView = null;
    private reportViewController:ReportViewController = null;
    private filters = null;

    private nameColumnTextField:ColumnFilterTextField = null;
    private minColumnTextField:ColumnFilterTextField = null;
    private maxColumnTextField:ColumnFilterTextField = null;
    private priceColumnTextField:ColumnFilterTextField = null;
    private quantityColumnTextField:ColumnFilterTextField = null;
    private containersColumnTextField:ColumnFilterTextField = null;

    public data = [];           

    private filter = null;
    private _warehouse:Warehouse = null;
    private warehouseFilter = StockWarehouseFilter.AllWarehouse;
    private _withoutExistences:boolean = false;    

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    private filterController:ColumnFilterController = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.warehouseButton = MUIOutlet(this, 'sv_nb_central_warehouse_btn','MUIButton');
        this.warehouseButton.setAction(this, function(){            
            this.showWarehouses();
        });        
        
        this.segmentedControl = MUIOutlet(this, "sv_segmented_control", "MUISegmentedControl");
        this.segmentedControl.setAction(this, function (control, index) {
            this.filter = index;
            this.updateUI();
        });

        this.exportButton = MUIOutlet(this, 'sv_nb_export_btn','MUIButton');
        this.exportButton.setAction(this, function(){
           let ad = MUIWebApplication.sharedInstance().delegate;
           let ws = ad.webService;
           ws.exportStockExistences(ad.selectedPlace, this, function(code, json){
           });
        });

        this.reportView = MUIOutlet(this, 'report-view', 'MUIView');

        let vc = new ReportViewController("report-detail-view");
        vc.initWithResource("layout/reports/ReportDetailView.html");
        vc.title = MIOLocalizeString("STOCK", "STOCK");
        vc.reportScriptPath = "python/stock_summary.py";          
        //vc.filterValues = this.filterValues;
        vc.showNavigationBar = false;
                    
        this.reportViewController = vc;
        this.reportView.addSubview(this.reportViewController.view);
        this.addChildViewController(vc);
                  
        /*
        this.filterController = new ColumnFilterController();
        this.filterController.initWithDelegate(this);

        this.nameColumnTextField = MUIOutlet(this, "name-filter-tf", "ColumnFilterTextField");
        this.nameColumnTextField.filterController = this.filterController;
        this.nameColumnTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "name", null, null);

        this.minColumnTextField = MUIOutlet(this, "min-filter-tf", "ColumnFilterTextField");
        this.minColumnTextField.filterController = this.filterController;
        this.minColumnTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "productMin", null, null);

        this.maxColumnTextField = MUIOutlet(this, "max-filter-tf", "ColumnFilterTextField");
        this.maxColumnTextField.filterController = this.filterController;
        this.maxColumnTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "productMax", null, null);

        this.priceColumnTextField = MUIOutlet(this, "price-filter-tf", "ColumnFilterTextField");
        this.priceColumnTextField.filterController = this.filterController;
        this.priceColumnTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "productCostPrice", null, null);        

        this.quantityColumnTextField = MUIOutlet(this, "quantity-filter-tf", "ColumnFilterTextField");
        this.quantityColumnTextField.filterController = this.filterController;
        this.quantityColumnTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "productQuantity", null, null);        

        this.tableView = this.tableView = MUIOutlet(this, 'sav_tableview', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;            

        this.updateUI();
        */
    }
    
    private updateUI(){
        // this.fetchedResultsController = null;
        // this.tableView.reloadData();

        // filter isavailable

        this.filters = {};
        if (this.warehouseFilter == StockWarehouseFilter.SelectedWarehouse) this.filters["id_warehouse"] = this._warehouse.identifier;
        else if (this.warehouseFilter == StockWarehouseFilter.DefaultWarehouse) this.filters["id_warehouse"] = null;

        this.reportViewController.filterValues = this.filters;
        this.reportViewController.reloadData();
    }

    private showWarehouses(){
                
        let vc = new WarehouseViewController("warehouse_view");
        vc.initWithResource("layout/modals/warehouse/WarehouseView.html");
        vc.modalPresentationStyle = MUIModalPresentationStyle.Popover;

        let pc = vc.popoverPresentationController;
        pc.sourceRect = this.warehouseButton.frame;
        pc.sourceView = this.warehouseButton;
        
        vc.product = null;
        vc.delegate = this;
        
        this.presentViewController(vc, true);
    }

    public warehouseDidChange(controller, warehouse:Warehouse, defaultWarehouseSelected:boolean) {
        if (defaultWarehouseSelected == true){
            this.warehouseFilter = StockWarehouseFilter.DefaultWarehouse;
            this._warehouse = null;
            this.warehouseButton.title = MIOLocalizeString("_DEFAULT_WAREHOUSE_", "_DEFAULT_WAREHOUSE_");
        }
        else if (warehouse == null) {
            this.warehouseButton.title = MIOLocalizeString("_ALL_WAREHOUSES_", "_ALL_WAREHOUSES_");
            this._warehouse = null;
            this.warehouseFilter = StockWarehouseFilter.AllWarehouse;
        }
        else{
            this.warehouseButton.title = warehouse.name;
            this._warehouse = warehouse;
            this.warehouseFilter = StockWarehouseFilter.SelectedWarehouse;
        }
                
        this.updateUI();
    }
    
    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('WarehouseProductCell') as WarehouseProductCell;
    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        
        if(this.warehouseFilter == StockWarehouseFilter.SelectedWarehouse){
            if(this._warehouse == null) throw new Error('Error, warehouse needed');
            cell.warehouse = this._warehouse.identifier; 
        }
        else{
            cell.warehouse = this.warehouseFilter;
        }
        
        cell.item = item;
    
        //cell.delegate = this;

        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
        cell.selectionStyle = MUITableViewCellSelectionStyle.None;
    
        return cell;
    }
        
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
    }
    
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        ad.managedObjectContext.removeAllObjectsForEntityName("StockAnnotationView");
        ad.managedObjectContext.save();

        let filter = [];
        
        if (this.warehouseFilter == StockWarehouseFilter.DefaultWarehouse) //TODO: Temporal hasta queda filtrar por null
            filter.push('warehouse.identifier = null');
        else if (this.warehouseFilter == StockWarehouseFilter.SelectedWarehouse)
            filter.push('warehouse.identifier = ' + this._warehouse.identifier);

        if (this.filter == StockFilter.StockFilterSale)
            filter.push('isAvailable = true');
        else if(this.filter == StockFilter.StockFilterNoSale)
            filter.push('isAvailable != true');

        if (this._withoutExistences == true){
            filter.push('totalQuantity <= 0');
        }

        let filterPredicateFormat = this.filterController.filterPredicateFormat();
        if (filterPredicateFormat != null){
            filter.push("(" + filterPredicateFormat + ")");
        }

        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('StockAnnotationView');

        // TODO: Add product to relations prefetch to obtain the full product from the server (we need the costPrice later)
        //fetchRequest.relationshipKeyPathsForPrefetching = ['product'];  
     
        fetchRequest.sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('name', true)];
        
        if(filter.length >0) {         
            fetchRequest.predicate = MIOPredicate.predicateWithFormat(filter.join(' and '));
        }

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
            
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    filterPredicateDidChange(controller:ColumnFilterController){
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

}