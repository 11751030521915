


/// <reference path="BookingsCalendarViewController.ts" />
/// <reference path="BookingsDayListViewController.ts" />

enum BookingsResultsType{
    Days,
    TimeRange,
    Zones,
    Bookings
}

class BookingsViewController extends MUIViewController
{
    private contentViewController:MUISplitViewController = null;

    private bookingsCalendarViewController:BookingsCalendarViewController = null;
    private bookingsDayListViewController:BookingsDayListViewController = null;

    private appDelegate:AppDelegate = MUIWebApplication.sharedInstance().delegate;

    viewDidLoad() {
        super.viewDidLoad();

        this.bookingsCalendarViewController = new BookingsCalendarViewController("bookings-calendar-list-view");
        this.bookingsCalendarViewController.initWithResource("layout/bookings/bookings/BookingsCalendarListView.html");

        let bcnc = new MUINavigationController();
        bcnc.initWithRootViewController(this.bookingsCalendarViewController);        

        this.bookingsDayListViewController = new BookingsDayListViewController("bookings-day-list-view");
        this.bookingsDayListViewController.initWithResource("layout/bookings/bookings/BookingsDayListView.html");

        let bdlnc = new MUINavigationController();
        bdlnc.initWithRootViewController(this.bookingsDayListViewController);

        this.contentViewController = new MUISplitViewController();
        this.contentViewController.init();

        this.contentViewController.setMasterViewController(bcnc);
        this.contentViewController.setDetailViewController(bdlnc);

        this.addChildViewController(this.contentViewController);
        this.view.addSubview(this.contentViewController.view);
    }
}