/// <reference path="../../AppHelper.ts" />


enum BookingListStatus {
    BookingListReserved,
    BookingListAssigned,
    BookingListRequest,
    BookingListPending,
    BookingListAll
}

class BookingsDayListViewController extends EntityListViewController {
    
    private zoneFilterComboBox: MUIComboBox = null;
    private groupedFilterComboBox: MUIComboBox = null;

    private segmentedControl: MUISegmentedControl = null;        

    private mapButton: MUIButton = null;
    private zoneButton: MUIButton = null;
    private exportButton: MUIButton = null;

    private selectedDayLabel: MUILabel = null;

    private dayConfiguration: BookingDay = null;    
    private _filterStatus: BookingListStatus = null;
    private selectedDateString: string = null;
    private selectedGroupIndex = 0;

    private sdf = MUIWebApplication.sharedInstance().delegate.serverDateFormatter;
    private df: MIODateFormatter = MUIWebApplication.sharedInstance().delegate.longDateFormatter;
    
    viewDidLoad() {
        super.viewDidLoad();

        this.groupedFilterComboBox = MUIOutlet(this, "group-filter-cb", "MUIComboBox");
        this.groupedFilterComboBox.setOnChangeAction(this, function (control, index) {
            this.selectedGroupIndex = parseInt(index);
            this.invalidateFetch();
            this.tableView.reloadData();
        });

        this.segmentedControl = MUIOutlet(this, "segmented-control", "MUISegmentedControl");
        this.segmentedControl.setAction(this, function (control, index) {
            this.filterStatus = index;
        });

        // this.mapButton = MUIOutlet(this, 'map-btn', 'MUIButton');
        //this.mapButton.setHidden(true);

        // this.zoneButton = MUIOutlet(this, 'zones_btn', 'MUIButton');
        // this.zoneButton.setHidden(true);

        this.exportButton = MUIOutlet(this, "export-btn", "MUIButton");
        this.exportButton.setAction(this, this.exportAction);

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.selectedDayLabel = MUIOutlet(this, 'date-lbl', 'MUILabel');

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.filterStatus = BookingListStatus.BookingListReserved;
        this.date = MIODateToday();        

        //this.fillZoneComboBox(this.zonesFetchedResultsController.resultObjects);

        MIONotificationCenter.defaultCenter().addObserver(this, 'BookingDayDidChange', function (notification:MIONotification) {
            //this.dayConfiguration = notification.object;
            this.date = notification.object;
        });

        MIONotificationCenter.defaultCenter().addObserver(this, 'AddNewBookingNotification', function (notification:MIONotification) {
            //this.dayConfiguration = notification.object;
            this.showBookingDetail(null);
        });        
    }

    viewDidAppear(animated?){
        super.viewDidAppear(animated);
        MIONotificationCenter.defaultCenter().addObserver(this, 'ShowBookingDetail', function (notification:MIONotification) {
            let booking = notification.object as Booking;
            this.showBookingDetail(booking);
        });
    }

    viewWillDisappear(animated?){
        super.viewWillDisappear(animated);
        MIONotificationCenter.defaultCenter().removeObserver(this, 'ShowBookingDetail');
    }

    set filterStatus(value) {
        this._filterStatus = value;
        this.invalidateFetch();
    }

    set date(value) {

        let ds = this.sdf.stringFromDate(value);
        if (ds == this.selectedDateString) return;

        //this.navigationController.popToRootViewController(true);
        this.selectedDateString = this.sdf.stringFromDate(value);
        // this.selectedDayLabel.text = this.df.stringFromDate(value);
        this.invalidateFetch();
    }

    titleForHeaderInSection(tableView, section) {        

        // Calculate pax
        let sec = this.fetchedResultsController.sections[section];
        let count = sec.numberOfObjects();

        let booking = this.fetchedResultsController.objectAtIndexPath(MIOIndexPath.indexForRowInSection(0, section)) as Booking;

        let pax = 0;
        for (let index = 0; index < count; index++) {
            let ip = MIOIndexPath.indexForRowInSection(index, section);
            let b: Booking = this.fetchedResultsController.objectAtIndexPath(ip);
            pax += b.pax;
        }

        switch (this.selectedGroupIndex){
            case 0:
            return ( booking.hour ? booking.hour.substr(0, 5) : MIOLocalizeString("NO HOUR", "NO HOUR") ) + " - ( total: " + pax + " pax )";

            case 1:
            return (booking.bookingZoneName ? booking.bookingZoneName : MIOLocalizeString("NO ZONE", "NO ZONE")) + " - ( total: " + pax + " pax )";

            case 2:
            return booking.pax + " pax - ( total: " + pax + " pax )";
        }

        return null;
    }

    cellAtIndexPath(tableview: UITableView, indexPath: MIOIndexPath) {
        let cell: BookingsDayCell = tableview.dequeueReusableCellWithIdentifier("BookingsDayCell") as BookingsDayCell;        
        let booking:Booking = this.fetchedResultsController.objectAtIndexPath(indexPath);

        cell.item = booking;

        return cell;
    }

    didSelectCellAtIndexPath(tableView: UITableView, indexPath: MIOIndexPath) {
        let b: Booking = this.fetchedResultsController.objectAtIndexPath(indexPath);
        this.showBookingDetail(b);    
        tableView.deselectRowAtIndexPath(indexPath, true);
    }

    //
    // Fetched results controller
    //

    get fetchRequest() {

        let filterString = 'day == "' + this.selectedDateString + '"';

        if (this._filterStatus == BookingListStatus.BookingListRequest)
            filterString += " AND status == " + BookingStatus.BookingRequest;
        else if (this._filterStatus == BookingListStatus.BookingListAssigned)
            filterString += " AND status == " + BookingStatus.BookingAssigned;
        else if (this._filterStatus == BookingListStatus.BookingListReserved)
            filterString += ' AND (status == ' + BookingStatus.BookingReserved + ' OR status == ' + BookingStatus.BookingConfirmed + ' OR status == ' + BookingStatus.BookingFinished + " OR status == " + BookingStatus.BookingNoShow + " OR status == " + BookingStatus.BookingShow + " OR status == " + BookingStatus.BookingSitting + ')';
        else if (this._filterStatus == BookingListStatus.BookingListPending)
            filterString += " AND (status == " + BookingStatus.BookingRequest + ' OR status == ' + BookingStatus.BookingWaiting + ')';

        if (this.searchString != null) {
            filterString += "clientName CONTAINS '" + this.searchString + "'"
            filterString += " OR client.name CONTAINS '" + this.searchString + "'";
            filterString += " OR clientPhone CONTAINS '" + this.searchString + "'";
            filterString += " OR client.phone.number CONTAINS '" + this.searchString + "'";
            filterString += " OR clientEmail CONTAINS '" + this.searchString + "'";
            filterString += " OR client.email CONTAINS '" + this.searchString + "'";
        }
        
        let sortDescriptors = null;
        switch(this.selectedGroupIndex){
            case 0: 
            sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("hour", true),
                MIOSortDescriptor.sortDescriptorWithKey("bookingName", true)];
            break;

            case 1: 
            sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("bookingZoneName", true),
                MIOSortDescriptor.sortDescriptorWithKey("bookingName", true)];
            break;

            case 2: 
            sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("pax", true),
                MIOSortDescriptor.sortDescriptorWithKey("bookingName", true)];
            break;
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Booking", sortDescriptors, filterString);
        fetchRequest.relationshipKeyPathsForPrefetching = ['client.address', 'client.mobilePhone', 'client.phone', "group", "locations"];

        return fetchRequest;
    }

    get sectionKeyPath(){
        switch(this.selectedGroupIndex){
            case 0: 
            return "hour";

            case 1:
            return "zoneName";

            case 2:
            return "pax";
        }

        return null;
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        // Setup data
        let ad = MUIWebApplication.sharedInstance().delegate;

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(this.fetchRequest, ad.managedObjectContext, this.sectionKeyPath);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller: MIOFetchedResultsController) {
        if (controller == this.fetchedResultsController) {
            this.reloadData();
        }
        // else if (controller == this.zonesFetchedResultsController){
        //     this.fillZoneComboBox(controller.resultObjects);
        // }
    }

    private _bookingDetailViewController: BookingDetailViewController = null;
    get bookingDetailViewController() {
        if (this._bookingDetailViewController == null) {
            this._bookingDetailViewController = new BookingDetailViewController("booking-detail-view");
            this._bookingDetailViewController.initWithResource("layout/bookings/bookings/BookingsDetailView.html");
        }

        return this._bookingDetailViewController;
    }

    private showBookingDetail(booking:Booking){
        this.bookingDetailViewController.booking = booking;
        this.bookingDetailViewController.dayConfiguration = this.dayConfiguration;
        this.navigationController.pushViewController(this.bookingDetailViewController);
    }

    private exportAction(){
        // MIOLog("Hour; Pax; Name; Zone; Deposit; Comments;");
        // for (let i = 0; i < this.fetchedResultsController.fetchedObjects.length; i++) {
        //     let b = this.fetchedResultsController.fetchedObjects[i] as Booking;
        //     MIOLog(b.hour + ";" + b.pax + ";" + (b.bookingName != null ? b.bookingName : b.clientName) + ";" + b.bookingZoneName + ";" + (b.deposit != null ? b.deposit : "") + ";" + (b.comments != null ? b.comments : "") + ";");
        // }

        let report = new MIOReport();
        report.initWithTitle("Reservas");
        let table = report.addTable("Today");
        table.addColumn("Hour", "hour");
        table.addColumn("Pax", "pax");
        table.addColumn("Name", "name");
        table.addColumn("Zone", "zone");
        table.addColumn("Deposit", "deposit");
        table.addColumn("Min fee", "minfee");
        table.addColumn("Phone", "phone");
        table.addColumn("Email", "email");        
        table.addColumn("Comments", "comments");

        for (let i = 0; i < this.fetchedResultsController.fetchedObjects.length; i++) {
            let b = this.fetchedResultsController.fetchedObjects[i] as Booking;
            table.newRow();

            table.addCellItemForKey("hour", b.hour);
            table.addCellItemForKey("pax", b.pax);
            table.addCellItemForKey("name", b.bookingName != null ? b.bookingName : b.clientName);
            table.addCellItemForKey("zone", b.bookingZoneName);
            table.addCellItemForKey("deposit", b.deposit != null && b.deposit > 0 ? b.deposit : null);
            table.addCellItemForKey("minfee", b.minSpend != null && b.minSpend > 0 ? b.minSpend : null);
            table.addCellItemForKey("phone", b.bookingPhone ?? b.client.phone?.number);
            table.addCellItemForKey("email", b.bookingEmail ?? b.client.email);
            table.addCellItemForKey("comments", b.comments);
        }

        let data = report.render();
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let date = new Date();
        let report_date = ad.serverDateTimeFormatter.stringFromDate(date);
        let date_interval = ad.dateTimeFormatter.stringFromDate(date);
        ReportHelper.sharedInstance().export2XLS("Reservas " + report_date + ".xlsx", "Reservas", date_interval, data);
    }
}
