

class LegalTaxViewController extends MUIViewController
{

    static newInstance() : LegalTaxViewController {
        let vc = new LegalTaxViewController('legal-tax-view');
        vc.initWithResource('layout/taxes/LegalTaxView.html');

        return vc;
    }

    private saveButton:MUIButton = null;
    private addButton:MUIButton = null;
    private tableView:UITableView = null;
    
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function(){
           this.addLegalTax();
        });

        this.saveButton = MUIOutlet(this, 'save-btn', 'MUIButton');
        this.saveButton.setAction(this, function(){
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
            moc.save();
        });

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(animate?) {
        super.viewWillAppear(animate);        
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;
      
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }
    
    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('LegalTaxCell') as LegalTaxCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as LegalTax;
        cell.item = item;
        cell.selectionStyle = UITableViewCellSelectionStyle.None;
            
        return cell;
    }
        
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        // let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as LegalTax;
        // let vc = new VATDetailViewController("vat-detail-view");
        // vc.initWithResource("layout/taxes/VATDetailView.html");
        // vc.item = item;

        // this.navigationController.pushViewController(vc, true);
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
        return UITableViewCellEditingStyle.Delete;
    }
    
    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Tax;    

        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            DBHelper.deleteObjectFromMainContext(item, true);
        }
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("LegalTax", sortDescriptors, null);
        fetchRequest.relationshipKeyPathsForPrefetching = ['LegalTaxType'];       
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    private addLegalTax(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("NEW LEGAL TAX","NEW LEGAL TAX"), MIOLocalizeString("CHOOSE A NAME","CHOOSE A NAME"), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });
        
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("AMOUNT","AMOUNT"));
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            textField.formatter = ad.currencyFormatter;
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("ADD","ADD"), MUIAlertActionStyle.Default, this, function(){

            let tempName =  avc.textFields[0].text;
            let tempAmount = avc.textFields[1].text;
            let name = tempName.trim();
            let amount = this.cf.numberFromString(tempAmount.trim());


            if(name.length > 0 && amount != null){
                let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
                let legalTax = MIOEntityDescription.insertNewObjectForEntityForName("LegalTax", moc) as LegalTax;
                legalTax.identifier = MIOUUID.UUID().UUIDString;
                legalTax.name = name;
                legalTax.value = amount / 100;
                moc.save()
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString("ERROR", "ERROR"), MIOLocalizeString("CHECK NAME AND TAX QUANTITY","CHECK NAME AND TAX QUANTITY"), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Cancel, this, function(){}));
                
                this.presentViewController(error, true);
            }
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL","CANCEL"), MUIAlertActionStyle.Cancel, this, function(){}));
        
        this.presentViewController(avc, true);
    }
}
