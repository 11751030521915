
class LicensesDetailTokenDataSource extends MIOObject
{
    private tableView:UITableView = null;
    
    initWithTableView( tableView:UITableView ) {
        this.tableView = tableView;    
    }

    private license:License = null;
    set item(item:License) {
        this.license = item;
        this.tableView.reloadData();
    }

    numberOfSections(tableview:UITableView){
        if (this.license == null) return 0;
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("TokenCell") as LicenseDetailTokenCell;
 
        let item = this.fetchedResultsController.objectAtIndexPath( indexPath ) as LicenseToken;
        cell.item = item;
        
        return cell;
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {   
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {        

        if ( editingStyle == MUITableViewCellEditingStyle.Delete ) {
            let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
            DBHelper.deleteObjectFromMainContext( item, true );
        }        
    } 

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let sds = [MIOSortDescriptor.sortDescriptorWithKey("startDate", true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName( "LicenseToken", sds, "license.identifier == " + this.license.identifier );
        fetchRequest.relationshipKeyPathsForPrefetching = [ "license" ];
        // fetchRequest.fetchLimit = 100;        

        // if (this.searchString != null) {
        //     let predicateFormat = "name CONTAINS '" + this.searchString + "'";
        //     fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);
        // }        

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }

}

class LicenseDetailTokenCell extends UITableViewCell
{
    // delegate:LicenseDetailInfoCellDelegate = null;

    private startDateTextField: MUITextField = null;
    private startDateButton:MUIButton = null;

    private endDateTextField: MUITextField = null;
    private endDateButton:MUIButton = null;

    private statusCombobox:MUIComboBox = null;
    private typeDropdown:DropdownButton = null;
    private offerDropdown:DropdownButton = null;
    private priceTextField:MUITextField = null;
    private discountTextField:MUITextField = null;
    private totalTextField:MUITextField = null;
    private resellerFeeTextField:MUITextField = null;
    private resellerFeeAmountLabel:MUITextField = null;
    private profitLabel:MUILabel = null;
    
    private df = (MUIWebApplication.sharedInstance().delegate as AppDelegate ).dateFormatter;

    awakeFromHTML(): void {
        this.startDateTextField = MUIOutlet(this, "start-date-tf", "MUITextField");
        this.startDateTextField.formatter = this.df;
        this.startDateTextField.setOnChangeText( this, function(this:LicenseDetailTokenCell, control:MUITextField, value:string){            
            this.token.startDate = this.df.dateFromString( value );
        });

        this.startDateButton = MUIOutlet( this, "start-date-btn", "MUIButton" );
        this.startDateButton.tag = 1;
        this.startDateButton.setAction( this, function( this:LicenseDetailTokenCell) {
            this.showDatePickerController( this.startDateButton );
        });

        this.endDateTextField = MUIOutlet(this, "end-date-tf", "MUITextField");
        this.endDateTextField.formatter = this.df;
        this.endDateTextField.setOnChangeText( this, function(this:LicenseDetailTokenCell, control:MUITextField, value:string){            
            this.token.endDate = this.df.dateFromString( value );
        });

        this.endDateButton = MUIOutlet( this, "start-date-btn", "MUIButton" );
        this.endDateButton.tag = 1;
        this.endDateButton.setAction( this, function( this:LicenseDetailTokenCell) {
            this.showDatePickerController( this.endDateButton );
        });


        // this.statusCombobox = MUIOutlet(this, "status-cb", "MUIComboBox");
        // this.statusCombobox.setOnChangeAction(this, function(this:LicenseDetailInfoCell, value:string){            
        //     this.license.active = (value == "1");
        // });

        // this.typeDropdown = DropdownButton.outlet(this, "license-type-dd", (control:MUIButton) => {
        //     AppHelper.sharedInstance().showSelectLicenseTypeViewControllerFromView(this.typeDropdown, this.license.type, this, function(this:LicenseDetailInfoCell, controller:SelectViewController, object:LicenseType){
        //         this.typeDropdown.title = object?.name;
        //         this.license.type = object;
        //     });
        // });

        // this.nextDateTextField = MUIOutlet(this, "next-renewal-date-tf", "MUITextField");        
        // this.nextDateTextField.setOnChangeText( this, function(this:LicenseDetailInfoCell, control:MUITextField, value:string ){            
        //     this.license.nextRenewalDate = value.trim().length == 0 ? null : this.df.dateFromString( value );
        // });

        // this.nextDateButton = MUIOutlet( this, "next-renewal-date-btn", "MUIButton" );
        // this.nextDateButton.tag = 2;
        // this.nextDateButton.setAction( this, function( this:LicenseDetailInfoCell) {
        //     this.showDatePickerController( this.nextDateButton );
        // });

        // this.offerDropdown = MUIOutlet(this, "offer-dd", "DropdownButton");

        // this.priceTextField = MUIOutlet( this, "price-tf", "MUITextField" );
        // this.priceTextField.setOnChangeText(this, function(this:LicenseDetailInfoCell, control:MUITextField, value:string ) {
        //     let total = (MUIWebApplication.sharedInstance().delegate as AppDelegate).currencyFormatter.numberFromString(value);
        //     this.delegate?.didChangePrice(this.license, total);
        // });

        // this.discountTextField = MUIOutlet(this, "discount-tf", "MUITextField");
        // this.discountTextField.setOnChangeText(this, function(this:LicenseDetailInfoCell, control:MUITextField, value:string){
        //     this.license.discountFormat = value.length > 0 ? value.trim() : null;
        //     // this.delegate?.didChangeDiscount(this.license, total);            
        // });

        // this.totalTextField = MUIOutlet( this, "total-tf", "MUITextField" );
        // this.totalTextField.setOnChangeText(this, function(this:LicenseDetailInfoCell, control:MUITextField, value:string){
        //     let total = (MUIWebApplication.sharedInstance().delegate as AppDelegate).currencyFormatter.numberFromString(value);
        //     this.delegate?.didChangeTotal(this.license, total);
        // });

        // this.resellerFeeTextField = MUIOutlet(this, "reseller-fee-tf", "MUITextField");
        // this.resellerFeeTextField.setOnChangeText(this, function(this:LicenseDetailInfoCell, control:MUITextField, value:string){
        //     this.license.distributorFeeFormat = value.length > 0 ? value.trim() : null;
        // });

        // this.resellerFeeAmountLabel = MUIOutlet(this, "reseller-fee-lbl", "MUILabel");

        // this.profitLabel = MUIOutlet( this, "dl-profit-lbl", "MUILabel" );

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private token:LicenseToken = null;
    set item(item:LicenseToken) {
        this.token = item;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        
        this.startDateTextField.text = this.df.stringFromDate( item.startDate );
        this.endDateTextField.text = this.df.stringFromDate( item.endDate );
        // this.nextDateTextField.text = item.nextRenewalDate == null ? "-" : this.df.stringFromDate( item.nextRenewalDate );
        // this.typeDropdown.title = item.type?.name;
        // this.priceTextField.text = ad.currencyFormatter.stringFromNumber( item.price );
        // this.discountTextField.text = item.discountFormat;
        // this.totalTextField.text = ad.currencyFormatter.stringFromNumber( item.total );
        // this.resellerFeeTextField.text = item.distributorFeeFormat;
        // this.resellerFeeAmountLabel.text = ad.currencyFormatter.stringFromNumber( item.distributorFeeValue );


        // this.profitLabel.text = ad.currencyFormatter.stringFromNumber( item.total - item.distributorFeeValue );
    }

    private showDatePickerController( button: MUIButton ){
        let dp = new MUIDatePickerController();
        dp.init();        
        dp.delegate = this;
        dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;
        dp.tag = button.tag;

        let pc = dp.popoverPresentationController;
        pc.sourceRect = button.frame;
        pc.sourceView = button;

        AppHelper.sharedInstance().presentViewController(dp, true);
    }

    didSelectDate(controller:MUIDatePickerController, date:Date){
        switch (controller.tag) {
            case 1: 
                // this.license.startDate = date;
                this.startDateTextField.text = this.df.stringFromDate( date );
            break;

            case 2: 
                // this.license.nextRenewalDate = date;
                this.endDateTextField.text = this.df.stringFromDate( date );
            break;

        }
    }

}
