//
// Generated class BusinessLicense
//

/// <reference path="BusinessLicense_ManagedObject.ts" />

class BusinessLicense extends BusinessLicense_ManagedObject
{

}
