/**
 * Created by crisan on 7/12/16.
 */



class LicenseCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;

    awakeFromHTML()
    {
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        
        this.separatorStyle = UITableViewCellSeparatorStyle.None;
    }

    set item(i:BusinessLicense)
    {
        this.nameLabel.text = i.name;
        if (i.active == false) {
            MUICoreLayerAddStyle(this.nameLabel.layer, "alert");
        }
        else {
            MUICoreLayerRemoveStyle(this.nameLabel.layer, "alert");
        }
    }

}