//
// Generated class CronJob
//

/// <reference path="CronJob_ManagedObject.ts" />

class CronJob extends CronJob_ManagedObject
{

}
