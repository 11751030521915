class InternalReturnOrderListViewController extends NoteListViewController {

    //variables
    type = StockNoteType.InternalReturnOrder;
    private detailViewController: InternalReturnOrderDetailViewController = null;
    private _filters = null;


    //set up table
    cellAtIndexPath(tableview, indexPath: MIOIndexPath) {
        //give cell structur with a .html
        let cell = tableview.dequeueReusableCellWithIdentifier('ReturnOrderCell') as ReturnOrderCell;
        //add the content from the fetch    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        cell.item = item;
        cell.selected = this.selectedNote == item ? true : false;

        return cell;
    }

    //showSelectedItem() iniciates the detail view if not already iniciated
    protected showSelectedItem() {
        if (!this.detailViewController) {
            this.detailViewController = new InternalReturnOrderDetailViewController('internal-return-order-detail-view');
            this.detailViewController.initWithResource('layout/returnorder/InternalReturnOrderDetailView.html');
        }
        this.detailViewController.note = this.selectedNote;
        this.splitViewController.showDetailViewController(this.detailViewController);
    }

    //showNoItemSelected() 
    protected showNoItemSelected() {
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("InternalReturnOrderNoSelectedItem", NoItemSelectedViewControllerType.InternalReturnOrder);
        this.splitViewController.showDetailViewController(vc);
    }

    //FETCH 
    set fetchedResultsController(value) {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
        this._fetchedResultsController = value;
    }

    get fetchedResultsController() {
        //returns current fetchedResultController if exists
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        //iniciate AD
        let ad = MUIWebApplication.sharedInstance().delegate;

        //sort querey by documentID/creation date
        let sortDescriptors = null;
        if (this.selectedStatus == StockNoteStatus.Processed) {
            sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("creationDate", false)];
        } else {
            sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("documentID", false)];
        }   

        //specify the predicate (filter)
        let predicateFormat = "type = " + this.type + " AND status == " + this.selectedStatus;
        if (ad.selectedIdentifierType == "place") predicateFormat += " AND placeID == '" + ad.selectedIdentifier + "'";

        //include search in the predicate
        if (this.searchString != null) {
            predicateFormat += " AND (documentID CONTAINS '" + this.searchString + "' OR internalOrder.documentID CONTAINS '" + this.searchString + "')";
        }

        //???
        if (this.filterPredicateFormat != null) {
            predicateFormat += " AND " + this.filterPredicateFormat;
        }

        //create the fetch
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("StockNote", sortDescriptors, predicateFormat);
        fetchRequest.fetchLimit = 30;
        fetchRequest.relationshipKeyPathsForPrefetching = ['internalOrder'];

        //create fetch controller
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller: MIOFetchedResultsController) {
        this.tableView.reloadData();
    }

    //set up filters - ???
    get filters() {
        if (this._filters != null) return this._filters;

        this._filters = [FilterOption.filterWithTitle("FromDate", "From day", "validationDate", FilterFieldType.Date, null, FilterControllerComparatorType.MajorOrEqualComparator, null, "00:00:00"),
            FilterOption.filterWithTitle("ToDate", "To day", "validationDate", FilterFieldType.Date, null, FilterControllerComparatorType.MinorOrEqualComparator, null, "23:59:59"),
            FilterOption.filterWithFetchRequest("Origin", "From warehouse", "originWarehouse.identifier", FilterFieldType.DropDown, "Warehouse", "name", "identifier", MIOLocalizeString("SELECT ORIGIN WAREHOUSE","Select origin warehouse")),
            FilterOption.filterWithFetchRequest("Destination", "To warehouse", "destinationWarehouse.identifier", FilterFieldType.DropDown, "Warehouse", "name", "identifier", MIOLocalizeString("SELECT DESTINATION WAREHOUSE","Select destination warehouse"))
        ];

        return this._filters;
    }

}