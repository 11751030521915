class DBClientsCoder extends DBXLSXCoder
{
    protected mainEntity() {
        return { entity: "Client", predicateFormat: "deletedAt == null", relations:["address", "phone", "mobilePhone", "agent", "rate"] };
    }

    columns() : any[] {
        let cols = [
            { title: "REFERENCE", width: "10%", align: "center" },
            { title: "CLIENT ID", width: "20%", align: "center" },
            { title: "CLIENT NAME", width: "20%", align: "center" },
            { title: "BUSINESS NAME", width: "20%", align: "center" },
            { title: "BUSINESS ID", width: "10%", align: "center" },
            { title: "PHONE", width: "10%", align: "center" },
            { title: "MOBILE", width: "10%", align: "center" },
            { title: "ADDRESS", width: "20%", align: "center" },
            { title: "CITY", width: "10%", align: "center" },
            { title: "PROVINCE OR STATE", width: "10%", align: "center" },
            { title: "POSTAL CODE", width: "10%", align: "center" },
            { title: "COUNTRY ISO2", width: "10%", align: "center" },
            { title: "COMMENTS", width: "10%", align: "center" },
            { title: "RATE NAME", width: "10%", align: "center" },
            { title: "AGENT", width: "10%", align: "center" },
            { title: "ENTRY DATE", width: "10%", align: "center" },
            { title: "LEAVE DATE", width: "10%", align: "center" },
            { title: "LEAVING REASONS", width: "10%", align: "center" }
        ]
        return cols;
    }

    aditionalImportEntities(){
        return [            
            { entity: "Contact", predicateFormat: "deletedAt == null" },
            { entity: "Rate", predicateFormat: "deletedAt == null" },
            { entity: "Country", predicateFormat: null }
        ]
    }

    protected importRow(row){
        
        let reference        = row["REFERENCE"];
        let clientID         = row["CLIENT ID"];
        let name             = row["CLIENT NAME"];
        let businessName     = row["BUSINESS NAME"];
        let businessID       = row["BUSINESS ID"];
        let address          = row["ADDRESS"];
        let city             = row["CITY"];
        let postalCode       = row["POSTAL CODE"];
        let state            = row["PROVINCE OR STATE"];
        let countryISO2      = row["COUNTRY ISO2"];
        let comments         = row["COMMENTS"];
        let agentName        = row["AGENT"];
        let entryDate        = row["ENTRY DATE"];   
        let leaveDate        = row["LEAVE DATE"];
        let leavingReasons   = row["LEAVING REASONS"];
        let rateName         = row["RATE NAME"];
        let phone            = row["PHONE"];
        let mobile           = row["MOBILE"];

        if (name.trim().length == 0) return;

        let agent = this.queryEntityByField("Contact", "name", agentName) as Contact;
        let rate = this.queryEntityByField("Rate", "name", rateName) as Rate;

        let country = this.queryEntityByField("Country", "iso2", countryISO2) as Country;
        if (country == null) {
            country = AppHelper.sharedInstance().defaultCountry;
        }
               
        let client:Client = null;
        if ( reference != null ) client = this.queryEntityByField("Client", "reference", reference) as Client;
        if ( client == null ) client = this.queryEntityByIDOrName("Client", clientID, name) as Client;
        if (client == null) { 
            client = MIOEntityDescription.insertNewObjectForEntityForName("Client", DBHelper.mainManagedObjectContext) as Client;
            client.identifier = this.parseOrCreateIdentifier(clientID)

            let addr = MIOEntityDescription.insertNewObjectForEntityForName("Address", DBHelper.mainManagedObjectContext) as Address;
            client.address = addr;

            let p = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", DBHelper.mainManagedObjectContext) as PhoneNumber;
            client.phone = p;

            let m = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", DBHelper.mainManagedObjectContext) as PhoneNumber;
            client.mobilePhone = m;
        }

        client.reference = reference;
        client.name = name;
        client.businessName = businessName;
        client.document = businessID;
        client.phone.number = phone;
        client.mobilePhone.number = mobile;
        client.address.address1 = address;
        client.address.city = city;
        client.address.state = state;
        client.address.postalCode = postalCode;
        client.address.country = country?.name;
        client.address.countryISOa2 = country?.iso2;
        client.address.countryISOa3 = country?.iso3;        
        client.comments = comments;
        client.entryDate = this.ad.dateTimeFormatter.dateFromString(entryDate);
        client.leaveDate = this.ad.dateTimeFormatter.dateFromString(leaveDate);
        client.leavingReasons = leavingReasons;    
        client.rate = rate;
        client.agent = agent;

        this.appendObject(client);
        MIOLog("ADDING CLIENT: " + this.rowIndex + "/" + this.rows.length + ": " + name);
    }

    protected exportTitle() : string { return "Clients"; }
                
    protected exportSortDescriptors() { 
        return [MIOSortDescriptor.sortDescriptorWithKey("name", false)]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let client = object as Client;

        let item = {
            "REFERENCE"             : client.reference,
            "CLIENT NAME"           : client.name,
            "BUSINESS NAME"         : client.businessName,
            "BUSINESS ID"           : client.document,
            "ADDRESS"               : client.address?.address1,
            "CITY"                  : client.address?.city,
            "PROVINCE OR STATE"     : client.address?.state,
            "POSTAL CODE"           : client.address?.postalCode,            
            "COUNTRY ISO2"          : client.address?.countryISOa2,
            "COMMENTS"              : client.comments,
            "AGENT"                 : client.agent?.name,
            "ENTRY DATE"            : this.ad.dateTimeFormatter.stringFromDate(client.entryDate),
            "LEAVE DATE"            : this.ad.dateTimeFormatter.stringFromDate(client.leaveDate),
            "LEAVING REASONS"       : client.leavingReasons,
            "RATE NAME"             : client.rate?.name,
            "PHONE"                 : client.phone?.number,
            "MOBILE"                : client.mobilePhone?.number
        }

        return item;
    }
}