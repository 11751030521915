class DBBackupToolCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    private beginDateLabel:MUILabel = null;
    private endDateLabel:MUILabel = null;
    private backupButton:MUIButton = null;
    private recoveryButton:MUIButton = null;    

    public delegate = null;

    awakeFromHTML(){
        
        this.beginDateLabel = MUIOutlet(this, 'date-begin-lbl', 'MUILabel');
        this.endDateLabel = MUIOutlet(this, 'date-end-lbl', 'MUILabel');
        this.nameLabel = MUIOutlet(this, 'session-lbl', 'MUILabel');        
        this.backupButton = MUIOutlet(this, 'backup-btn','MUIButton');
        this.backupButton.setAction(this, function(){
           if(this.delegate != null && typeof this.delegate.backupSessions === "function"){
                this.delegate.backupSessions([this._item]);
           }
        });
        
        this.recoveryButton = MUIOutlet(this, 'recovery-btn','MUIButton');
        this.recoveryButton.setAction(this, function(){
            if(this.delegate != null && typeof this.delegate.recoverySessions === "function"){
                 this.delegate.recoverySessions([this._item]);
            }
        });    

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }    

    private _item:CashDeskSession = null;
    set item(i:CashDeskSession){
        this._item = i;

        let dtf = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter;    

        this.nameLabel.text = i.name;
        this.beginDateLabel.text = dtf.stringFromDate(i.beginDate);
        this.endDateLabel.text = dtf.stringFromDate(i.endDate);
        // this.backupButton.setEnabled(!i.isBackup);
        // this.recoveryButton.setEnabled(i.isBackup);
    }
}
