/**
 * Created by miguel on 13/2/17.
 */




class AddNewCategoryTaxCell extends MUITableViewCell
{
    nameLabel:MUILabel = null;

    private _separator = null;

    awakeFromHTML()
    {
        this.nameLabel = MUIOutlet(this, "anctv_tv_cell_lbl", "MUILabel");
        this._separator = MUIOutlet(this, "anctv_tv_separator", "MUIView");
    }

    /*setSelected(value)
    {
        super.setSelected(value);

        if (value == true)
            this._separator.setBackgroundRGBColor(0, 191, 191);
        else
            this._separator.setBackgroundRGBColor(237, 237, 242);
    }*/

    set item(i)
    {
        this.nameLabel.text = i.name;
    }

}