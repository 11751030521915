//
// Generated class Contact
//

/// <reference path="Contact_ManagedObject.ts" />

class Contact extends Contact_ManagedObject
{

}
