


class CashDeskReportViewController extends MUIViewController {

    protected loadingView:MUIView = null;
    private sectionComboBox: MUIButton = null;
    //private sectionComboBox: MUIComboBox = null;
    private exportButton:MUIButton = null;

    private filterTableView: MUITableView = null;
    protected reportTableView: MUIReportTableView = null;

    groupBy = null;
    where = null;
    dateFrom: Date = null;
    dateTo: Date = null;

    protected items = [];
    protected totals = {};

    protected ad: AppDelegate = MUIWebApplication.sharedInstance().delegate;

    viewDidLoad() {
        super.viewDidLoad();

        this.loadingView = MUIOutlet(this, 'cdsv_activity-indicator', 'MUIView');        

        this.sectionComboBox = MUIOutlet(this, 'cdsv_classify_cb', 'MUIButton');
        this.sectionComboBox.setTitle(MIOLocalizeString("BACK","Back"));
        this.sectionComboBox.setAction(this, function(){
            this.navigationController.popViewController(true);
        });

        // this.sectionComboBox = MUIOutlet(this, 'cdsv_classify_cb', 'MUIComboBox');
        // this.sectionComboBox.setOnChangeAction(this, function (control, value) {

        //     if (value == 0) this.loadCategoryReport();
        //     else if (value == 1) this.loadProductReport();
        //     else if (value == 2) this.loadWorkerReport();
        //     else if (value == 5) this.loadInvitationsConceptReport();
        //     else if (value == 6) this.loadInvitationsNameReport();
        // });

        this.exportButton = MUIOutlet(this, 'cdsv_export_btn', 'MUIButton');
        this.exportButton.setAction(this, function(){
            this.export();
        });

        this.reportTableView = MUIOutlet(this, 'cdsv_report_tableview', 'MUIReportTableView');
        this.reportTableView.dataSource = this;
        this.reportTableView.delegate = this;
    }

    viewWillAppear(animated?) {
        super.viewWillAppear(animated);

        if (this.where == null)
            this.sectionComboBox.setHidden(true);
        else
            this.sectionComboBox.setHidden(false);

        //this.items = [];        
    }

    numberOfRows(reportTableView: MUIReportTableView) {

        let count = this.items.length;

        if (this.columnSubtotalsIndexes() != null) count++;
        
        return count;
    }

    cellAtIndexPath(reportTableView: MUIReportTableView, column: MUIReportTableViewColumn, indexPath: MIOIndexPath) {

        let cell: MUIReportTableViewCell = reportTableView.dequeueReusableCellWithIdentifier("ReportLabelCell");
        var item = null;        
        if (indexPath.row < this.items.length){
            item = this.items[indexPath.row];
        }
        else {
            item = this.totals;
        }        
        this.configureCellAtIndexPath(cell, column, indexPath, item);             
        return cell;
    }

    configureCellAtIndexPath(cell: MUIReportTableViewCell, column: MUIReportTableViewColumn, indexPath: MIOIndexPath, item) {

        var text = "";
        if (column.serverName != null) {            
            text = this.textAtIndexPath(column, indexPath, item);
            if (column.formatter != null) {
                if (column.formatter instanceof MIODateFormatter) {                    
                    let value = this.ad.serverDateTimeFormatter.dateFromString(text);
                    text = column.formatter.stringForObjectValue(value);
                }
                else{
                    text = column.formatter.stringForObjectValue(text);
                }
            }
        }
            
        cell.label.text = text;            
        cell.label.setTextAlignment(column.alignment);        
    }

    textAtIndexPath(column: MUIReportTableViewColumn, indexPath: MIOIndexPath, item){

        var text = item[column.serverName];
        return text;
    }

    didSelectCellAtIndexPath(reportTableView, indexPath) {

        console.log("TOUCH: " + indexPath.row);
    }

    sortDescriptorsDidChange(reportTableView, column:MUIReportTableViewColumn){

        if (column.serverName == null) return;

        if (column.ascending == true) {
            column.ascending = false;
            this.items = _MIOSortDescriptorSortObjects(this.items, [MIOSortDescriptor.sortDescriptorWithKey(column.serverName, true)]);
        }
        else {
            column.ascending = true;
            this.items = _MIOSortDescriptorSortObjects(this.items, [MIOSortDescriptor.sortDescriptorWithKey(column.serverName, false)]);            
        }

        this.reportTableView.reloadData();
    }

    //
    // Predicate parser
    //

    private parsePredictates(predicates) {

        var result = [];

        for (var count = 0; count < predicates.length; count++) {
            var o = predicates[count];

            if (o instanceof MIOPredicateGroup) {
                let group = o as MIOPredicateGroup;
                let i = {};
                i["type"] = "group";
                i["values"] = this.parsePredictates(group.predicates);
                result.push(i);
            }
            else if (o instanceof MIOPredicateItem) {
                //result = o.evaluateObject(object);
                let item = o as MIOPredicateItem;
                let i = {};
                i["type"] = "item";
                this.transformPredicateItem(i, item);
                result.push(i);
            }
            else if (o instanceof MIOPredicateOperator) {
                let op = o as MIOPredicateOperator;
                let i = {};
                i["type"] = "operator";
                i["value"] = this.transfromPredicateOperator(op.type);
                result.push(i)
            }
        }

        return result;
    }

    private transformPredicateItem(i, item) {

        let value = item.value;
        let cmp = item.comparator;

        i["key"] = item.key;

        if (cmp.Equal && value == null) {
            i["comparator"] = "is null";
        }
        else if (cmp.Distinct && value == null) {
            i["comparator"] = "not null";
        }
        else {
            i["comparator"] = this.transfromPredicateComparator(item.comparator);
            i["value"] = item.value;
        }
    }

    private transfromPredicateComparator(cmp): string {

        switch (cmp) {

            case MIOPredicateComparatorType.Equal:
                return "=";

            case MIOPredicateComparatorType.Less:
                return "<";

            case MIOPredicateComparatorType.LessOrEqual:
                return "<=";

            case MIOPredicateComparatorType.Greater:
                return ">";

            case MIOPredicateComparatorType.GreaterOrEqual:
                return ">=";

            case MIOPredicateComparatorType.Distinct:
                return "!=";

            case MIOPredicateComparatorType.Contains:
                return "like";

            case MIOPredicateComparatorType.NotContains:
                return "not like";
        }

        return "";
    }

    private transfromPredicateOperator(op): string {

        switch (op) {

            case MIOPredicateOperatorType.AND:
                return "and";

            case MIOPredicateOperatorType.OR:
                return "or";
        }

        return "";
    }

    //
    // Query
    //

    protected executeQuery(serverPath, select, where, groupBy, orderBy) {

        this.loadingView.setHidden(false);

        let ad: AppDelegate = MUIWebApplication.sharedInstance().delegate;
        let ws: WebService = ad.webService;

        let from = ad.serverDateFormatter.stringFromDate(this.dateFrom) + " 00:00:00";
        let to = ad.serverDateFormatter.stringFromDate(this.dateTo) + " 23:59:59";

        let w = where;
        if (where == null)
            w = "id_account = '00000301-0000-0000-0000-1111111111' AND sessionbegindate >= '" + from + "' AND sessionbegindate <= '" + to + "'";
        else
            w = "id_account = '00000301-0000-0000-0000-1111111111' AND sessionbegindate >= '" + from + "' AND sessionbegindate <= '" + to + "' AND (" + where + ")";

        var body = {};
        body["select"] = select;
        let predicate = MIOPredicate.predicateWithFormat(w);
        body["where"] = this.parsePredictates(predicate.predicateGroup.predicates);
        body["groupby"] = groupBy;
        body["orderby"] = orderBy;
/*
        ws.reportQuery(ad.selectedIdentifier, ad.selectedIdentifierType, serverPath, body, this, function (code, json) {

            this.loadingView.setHidden(true);

            if (code == 200) {
                this.items = json["data"];
                this.calculateTotals();
                this.reportTableView.reloadData();
            }
            else throw new Error("CashDeskReportViewController: " + code + ".Something happend in the server!");
        });*/
    }

    calculateTotals(){

        var indexes = this.columnSubtotalsIndexes();
        if (indexes == null) return;

        this.totals = {};

        for (var row = 0; row < this.items.length; row++){            
            for (var col = 0; col < indexes.length; col++){        

                let index = indexes[col];
                let column = this.reportTableView.columns[index];
                let item = this.items[row];

                var total = parseFloat(this.totals[column.serverName]);
                if (isNaN(total)) total = 0;
                var value = parseFloat(item[column.serverName]);
                if (isNaN(value)) value = 0;
                var t = total + value;
                this.totals[column.serverName] = t;
            }
        }                
    }

    columnSubtotalsIndexes(){
        return null;
    }

    export(){
        
        let ad: AppDelegate = MUIWebApplication.sharedInstance().delegate;
        let ws: WebService = ad.webService;

        var data = [];
        for (var row = 0; row < this.items.length; row++){        
            var i = {};
            for (var col = 0; col < this.reportTableView.columns.length; col++){
                let column:MUIReportTableViewColumn = this.reportTableView.columns[col];
                let item = this.items[row];
                let ip = MIOIndexPath.indexForColumnInRowAndSection(col, row, 0);
                let value = this.textAtIndexPath(column, ip, item);
                i[column.title] = value;                
            }
            data.push(i);
        }

        let body = {"filename":"Report.xls", "data":data};
        ws.exportFile(ad.selectedIdentifier, ad.selectedIdentifierType, body, this, function(code, json){
            if (code == 200){

            }
        });
    }

}