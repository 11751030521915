
class ProductAttributeTypeCell extends UITableViewCell
{
    private imageView:MUIImageView = null;
    private nameLabel:MUILabel = null;  
    private attributeLabel:MUILabel = null;

    awakeFromHTML(){
        this.imageView = MUIOutlet(this, "imgage-view", "MUIImageView");
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.attributeLabel = MUIOutlet(this, "attribute-lbl", "MUILabel");
    }
    
    private _productAttributeType:ProductAttributeType = null;
    set item (item:ProductAttributeType){
        if (item == null) return;

        this._productAttributeType = item;
        this.nameLabel.text = item.name;
        // this.attributeLabel.text = item.productAttributes.

    }
    
}