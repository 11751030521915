//
// Generated class MenuProductLine
//

/// <reference path="MenuProductLine_ManagedObject.ts" />

class MenuProductLine extends MenuProductLine_ManagedObject
{

}
