
class StockTemplateLineCell extends UITableViewCell
{
    protected nameLabel:MUILabel = null;
    
    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
    }
    
    set item (item:StockTemplateItem){
    }
}