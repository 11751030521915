
class ReportBuilderFileCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;
    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
    }

    set item (file:ReportItem) {
        this.nameLabel.text = file.name;
    }
}