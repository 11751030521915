

/// <reference path="InvoiceLine.ts" />

// Generated class PurchaseInvoiceLine_ManagedObject

class PurchaseInvoiceLine_ManagedObject extends InvoiceLine
{

    // Property: expenditureLedgerAccountName
    set expenditureLedgerAccountName(value:string) {
        this.setValueForKey(value, 'expenditureLedgerAccountName');
    }
    get expenditureLedgerAccountName():string {
        return this.valueForKey('expenditureLedgerAccountName');
    }
    set expenditureLedgerAccountNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'expenditureLedgerAccountName');
    }
    get expenditureLedgerAccountNamePrimitiveValue():string {
        return this.primitiveValueForKey('expenditureLedgerAccountName');
    }
    // Relationship: expenditureLedgerAccount
    set expenditureLedgerAccount(value:LedgerAccount) {
        this.setValueForKey(value, 'expenditureLedgerAccount');
    }
    get expenditureLedgerAccount():LedgerAccount {
        return this.valueForKey('expenditureLedgerAccount') as LedgerAccount;
    }
}
