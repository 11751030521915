class DeliveryRoutesListViewController extends EntityListViewController
{    
    
    private addButton:MUIButton = null;    
        
    viewDidLoad(){
        super.viewDidLoad();
        
        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function (){
            this.showAddDeliveryRoute();
        });

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        MIONotificationCenter.defaultCenter().addObserver(this, "DeliveryRouteNameDidChange", function(notification:MIONotification){
            this.updateUI();
        });
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
    }

    get entityName(){
        return 'DeliveryRoutes';
    }

    private updateUI() {
        if (this.viewIsLoaded == false) return;

        this.fetchedResultsController = null;
        this.tableView.reloadData();
     }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell:DeliveryRoutesListCell = tableview.dequeueReusableCellWithIdentifier("DeliveryRoutesListCell");
        let item:DeliveryRoute = this.fetchedResultsController.objectAtIndexPath(indexPath) as DeliveryRoute;
        cell.item = item;            
        
        return cell;
    }
    
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        let selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath);
        this.showItemDetail(selectedItem);
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
        let fetchRequest:MIOFetchRequest = null;
        let sortDescriptors = null; 
        let predicate = null;

        fetchRequest = DBHelper.listFetchRequestWithEntityName("DeliveryRoute", sortDescriptors, predicate);
        fetchRequest.relationshipKeyPathsForPrefetching = ['postalCodes']; //Test relationship with "deliveryPostalCode"

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;        
    }

    private showItemDetail(item:DeliveryRoute){     
        if (item == null) return;
        
        let vc = new DeliveryRoutesDetailViewController("delivery-routes-detail-view");
        vc.initWithResource("layout/deliveryroutes/DeliveryRoutesDetailView.html");
        vc.item = item;    
            
        this.navigationController.splitViewController.showDetailViewController(vc);
    }

    private showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Delivery Routes", NoItemSelectedViewControllerType.DeliveryRoutes);
        this.navigationController.splitViewController.showDetailViewController(vc);
    }

    private showAddDeliveryRoute() {
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('DELIVERY ROUTE',"DELIVERY ROUTE"), MIOLocalizeString('ADD NEW DELIVERY ROUTE ',"ADD NEW DELIVERY ROUTE "), MUIAlertViewStyle.Default);    

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
            textField.setPlaceholderText(MIOLocalizeString("NAME", "NAME"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD',"ADD"), MUIAlertActionStyle.Default, this, function(){                        
            let value = avc.textFields[0].text;
            
            //add new entity postalCode
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
            let newPostalCode = MIOEntityDescription.insertNewObjectForEntityForName("DeliveryRoute", moc) as DeliveryRoute;
            newPostalCode.identifier = MIOUUID.UUID().UUIDString;
            newPostalCode.name = value;

            DBHelper.saveMainContext();
            this.updateUI();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL',"CANCEL"), MUIAlertActionStyle.Cancel, null, null));
        this.presentViewController(avc, true); 
    }

}
