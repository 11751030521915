//
// Generated class IntegratorMapping
//

/// <reference path="IntegratorMapping_ManagedObject.ts" />

class IntegratorMapping extends IntegratorMapping_ManagedObject
{

}
