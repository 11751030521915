/**
 * Created by miguel on 22/2/17.
 */


class InvoiceCell extends UITableViewCell
{
    private documentIDLabel:MUILabel = null;    
    private referenceLabel:MUILabel = null;
    private nameLabel:MUILabel = null;
    private totalLabel:MUILabel = null;    
    private nextDocumentLabel:MUILabel = null;

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;

    awakeFromHTML(){
        this.documentIDLabel = MUIOutlet(this, "document-id-lbl", "MUILabel"); 
        this.referenceLabel = MUIOutlet(this, "reference-lbl", "MUILabel");
        this.nameLabel = MUIOutlet(this, "client-name-lbl", "MUILabel");
        this.totalLabel = MUIOutlet(this, "amount-lbl", "MUILabel");               
        // this.nextDocumentLabel = MUIOutlet(this, "next-doc-lbl", "MUILabel");                
    }

    set item(item:Invoice){
        this.nameLabel.text = item.legalEntity.name;
        
        this.documentIDLabel.text = item.documentNumber;        
        this.totalLabel.text = this.cf.stringFromNumber(item.totalAmount);        

        this.referenceLabel.text = null;
        if (item instanceof PurchaseInvoice) {
            this.referenceLabel.text = item.externalReference;
        }        

        // this.nextDocumentLabel.text = item.nextDocument?.documentNumber;

        if (item.status == 0){
            MUICoreLayerAddStyle(this.documentIDLabel.layer, "alert");
        }
    }

}