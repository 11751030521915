

/// <reference path="Printer.ts" />

// Generated class BluetoothPrinter_ManagedObject

class BluetoothPrinter_ManagedObject extends Printer
{

    // Property: deviceName
    set deviceName(value:string) {
        this.setValueForKey(value, 'deviceName');
    }
    get deviceName():string {
        return this.valueForKey('deviceName');
    }
    set deviceNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deviceName');
    }
    get deviceNamePrimitiveValue():string {
        return this.primitiveValueForKey('deviceName');
    }

    // Property: portName
    set portName(value:string) {
        this.setValueForKey(value, 'portName');
    }
    get portName():string {
        return this.valueForKey('portName');
    }
    set portNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'portName');
    }
    get portNamePrimitiveValue():string {
        return this.primitiveValueForKey('portName');
    }

    // Property: starIOConnectionMode
    set starIOConnectionMode(value:number) {
        this.setValueForKey(value, 'starIOConnectionMode');
    }
    get starIOConnectionMode():number {
        return this.valueForKey('starIOConnectionMode');
    }
    set starIOConnectionModePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'starIOConnectionMode');
    }
    get starIOConnectionModePrimitiveValue():number {
        return this.primitiveValueForKey('starIOConnectionMode');
    }
}
