


class VinculatedCompanyCell extends MUITableViewCell
{
    nameLabel = null;
    identifier = null;
    
    private _separator = null;
    awakeFromHTML()
    {
        this.nameLabel = MUIOutlet(this, 'cltv_tv_cell_lbl', 'MUILabel');
        
        this._separator = MUIOutlet(this, 'cltv_tv_separator1', 'MUIView');
    }
   setSelected(value)
    {
        super.setSelected(value);
        if (value == true)
            this._separator.setBackgroundRGBColor(0, 191, 191);
        else
            this._separator.setBackgroundRGBColor(237, 237, 242);
    }
    set item(i)
    {
       
        this.nameLabel.text = i.name;
        this.identifier = i.identifier;
    }
}
