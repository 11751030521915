class DBProductFormatAndProductModifierCategoryCoder extends DBXLSXCoder
{
    protected mainEntity() {
        return { entity: "ProductModifierCategory", predicateFormat: "deletedAt == null", relations:["productFormats.format"] };
    }

    columns() : any[] {
        let cols = [
            { title: "MODIFIER CATEGORY ID", width: "10%", align: "center" },
            { title: "MODIFIER CATEGORY NAME", width: "10%", align: "center" },
            { title: "PRODUCT FORMAT ID", width: "20%", align: "center" },
            { title: "PRODUCT FORMAT NAME", width: "20%", align: "center" },
        ]
        return cols;
    }

    aditionalImportEntities(){
        return [            
            { entity: "ProductFormat", predicateFormat: "deletedAt == null" },
            { entity: "Format", predicateFormat: "deletedAt == null" }
        ]
    }

    protected importRow(row:any){
        
        let mcID        = row["MODIFIER CATEGORY ID"];
        let mcName      = row["MODIFIER CATEGORY NAME"];
        let pfID   = row["PRODUCT FORMAT ID"];
        let pfName = row["PRODUCT FORMAT NAME"];

        let mc = this.queryEntityByField("ProductModifierCategory", "identifier", mcID) as ProductModifierCategory;
        if (mc == null) return;

        let pf = this.queryEntityByField("ProductFormat", "identifier", pfID) as ProductFormat;
        if (pf == null) return;

        mc.addProductFormatsObject(pf);
        
        MIOLog("ADDING PRODUCT FORMAT: " + this.rowIndex + "/" + this.rows.length + ": " + mcName + " -> " + pfName );
    }

    protected exportTitle() : string { return "Product Format and Product Modifier Categories"; }
                
    protected exportSortDescriptors() { 
        return [
            MIOSortDescriptor.sortDescriptorWithKey("name", false)
        ]; 
    }

    protected parseObjects(objects:MIOManagedObject[]) : any[] { 
        let items = [];
        for (let index = 0; index < objects.length; index++) {
            let obj = objects[index] as ProductModifierCategory;
            for (let pi = 0; pi < obj.productFormats.count; pi++) {
                let p = obj.productFormats.objectAtIndex(pi);
                let item = this.parse(obj, p);
                items.addObject(item);                    
            }
        }

        return items;
    }


    protected parse(mc:ProductModifierCategory, pf:ProductFormat): any {
        let item = {
            "MODIFIER CATEGORY ID": mc.identifier,
            "MODIFIER CATEGORY NAME": mc.name,
            "PRODUCT FORMAT ID": pf.identifier,
            "PRODUCT FORMAT NAME": pf.format.name
        }

        return item;
    }
}