

/// <reference path="TicketLine.ts" />

// Generated class CheffLine_ManagedObject

class CheffLine_ManagedObject extends TicketLine
{

    // Property: bulkQuantity
    set bulkQuantity(value:number) {
        this.setValueForKey(value, 'bulkQuantity');
    }
    get bulkQuantity():number {
        return this.valueForKey('bulkQuantity');
    }
    set bulkQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'bulkQuantity');
    }
    get bulkQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('bulkQuantity');
    }

    // Property: comments
    set comments(value:string) {
        this.setValueForKey(value, 'comments');
    }
    get comments():string {
        return this.valueForKey('comments');
    }
    set commentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'comments');
    }
    get commentsPrimitiveValue():string {
        return this.primitiveValueForKey('comments');
    }

    // Property: inProcessDate
    set inProcessDate(value:Date) {
        this.setValueForKey(value, 'inProcessDate');
    }
    get inProcessDate():Date {
        return this.valueForKey('inProcessDate');
    }
    set inProcessDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'inProcessDate');
    }
    get inProcessDatePrimitiveValue():Date {
        return this.primitiveValueForKey('inProcessDate');
    }

    // Property: kitchenStatus
    set kitchenStatus(value:number) {
        this.setValueForKey(value, 'kitchenStatus');
    }
    get kitchenStatus():number {
        return this.valueForKey('kitchenStatus');
    }
    set kitchenStatusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'kitchenStatus');
    }
    get kitchenStatusPrimitiveValue():number {
        return this.primitiveValueForKey('kitchenStatus');
    }

    // Property: readyDate
    set readyDate(value:Date) {
        this.setValueForKey(value, 'readyDate');
    }
    get readyDate():Date {
        return this.valueForKey('readyDate');
    }
    set readyDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'readyDate');
    }
    get readyDatePrimitiveValue():Date {
        return this.primitiveValueForKey('readyDate');
    }

    // Property: sendToKitchenDate
    set sendToKitchenDate(value:Date) {
        this.setValueForKey(value, 'sendToKitchenDate');
    }
    get sendToKitchenDate():Date {
        return this.valueForKey('sendToKitchenDate');
    }
    set sendToKitchenDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'sendToKitchenDate');
    }
    get sendToKitchenDatePrimitiveValue():Date {
        return this.primitiveValueForKey('sendToKitchenDate');
    }

    // Property: sendToKitchenWorkerID
    set sendToKitchenWorkerID(value:string) {
        this.setValueForKey(value, 'sendToKitchenWorkerID');
    }
    get sendToKitchenWorkerID():string {
        return this.valueForKey('sendToKitchenWorkerID');
    }
    set sendToKitchenWorkerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sendToKitchenWorkerID');
    }
    get sendToKitchenWorkerIDPrimitiveValue():string {
        return this.primitiveValueForKey('sendToKitchenWorkerID');
    }

    // Property: servedDate
    set servedDate(value:Date) {
        this.setValueForKey(value, 'servedDate');
    }
    get servedDate():Date {
        return this.valueForKey('servedDate');
    }
    set servedDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'servedDate');
    }
    get servedDatePrimitiveValue():Date {
        return this.primitiveValueForKey('servedDate');
    }

    // Property: waiterStatus
    set waiterStatus(value:number) {
        this.setValueForKey(value, 'waiterStatus');
    }
    get waiterStatus():number {
        return this.valueForKey('waiterStatus');
    }
    set waiterStatusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'waiterStatus');
    }
    get waiterStatusPrimitiveValue():number {
        return this.primitiveValueForKey('waiterStatus');
    }

    // Property: warehouseID
    set warehouseID(value:string) {
        this.setValueForKey(value, 'warehouseID');
    }
    get warehouseID():string {
        return this.valueForKey('warehouseID');
    }
    set warehouseIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'warehouseID');
    }
    get warehouseIDPrimitiveValue():string {
        return this.primitiveValueForKey('warehouseID');
    }

    // Relationship: modifierLines
    protected _modifierLines:MIOManagedObjectSet = null;
    get modifierLines():MIOManagedObjectSet {
        return this.valueForKey('modifierLines');
    }
    addModifierLinesObject(value:ModifierLine) {
        this._addObjectForKey(value, 'modifierLines');
    }
    removeModifierLinesObject(value:ModifierLine) {
        this._removeObjectForKey(value, 'modifierLines');
    }
    // Relationship: productFormat
    set productFormat(value:ProductFormat) {
        this.setValueForKey(value, 'productFormat');
    }
    get productFormat():ProductFormat {
        return this.valueForKey('productFormat') as ProductFormat;
    }
    // Relationship: tag
    set tag(value:CategoryTag) {
        this.setValueForKey(value, 'tag');
    }
    get tag():CategoryTag {
        return this.valueForKey('tag') as CategoryTag;
    }
}
