


class CompanyListViewController extends MUIViewController
{
    private _backButton = null;
    private _tableView = null;

    private _fetchedResultsController = null;

    public delegate = null;

    viewDidLoad(){
        super.viewDidLoad();

        this._backButton = MUIOutlet(this, 'cltv_nb_back_btn', 'MUIButton');
        this._backButton.setAction(this, function(){
            this.navigationController.popViewController(true);
        });

        this._tableView = MUIOutlet(this, 'cltv_c1_tableview', 'MUITableView');
        this._tableView.dataSource = this;
        this._tableView.delegate = this;
        this._tableView.reloadData();        
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section)
    {
        var sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath)
    {
        var cell = tableview.dequeueReusableCellWithIdentifier('VinculatedCompanyCell');
        //cell.setAccessoryType(MUITableViewCellAccessoryType.Checkmark);

        var item = this.fetchedResultsController.objectAtIndexPath(indexPath);
    
        cell.item = item;
    
        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
    
        return cell;
    }
    
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath)
    {
        var obj = this.fetchedResultsController.objectAtIndexPath(indexPath);
    
        if(this.delegate != null)
            this.delegate.companyDidChange(obj);
        this.navigationController.popViewController(true);
    
    }
    
    set fetchedResultsController(value)
    {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController()
    {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        var ad = MUIWebApplication.sharedInstance().delegate;
    
        var fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('Company');
    
        fetchRequest.sortDescriptors = [ /*MIOSortDescriptor.sortDescriptorWithKey('categoryName', true),*/
                                            MIOSortDescriptor.sortDescriptorWithKey('name', true)];
    
    
        var fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller)
    {
        this._tableView.reloadData();
    }
}