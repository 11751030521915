//
// Generated class ConsumptionCostBalance
//

/// <reference path="ConsumptionCostBalance_ManagedObject.ts" />

class ConsumptionCostBalance extends ConsumptionCostBalance_ManagedObject
{

}
