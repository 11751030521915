/**
 * Created by miguel on 23/2/17.
 */


/// <reference path="LapsopayPlaceListViewController.ts" />


class LapsopayViewController extends MUIViewController
{
    private _masterSplitController = null;
    private _detailSplitController = null;
    private _lapsopayHeaderViewController = null;
    private _lapsopayBodyViewController = null;

    viewDidLoad()
    {
        super.viewDidLoad();
        this._masterSplitController = new MUISplitViewController("lapsopaymaster_split_controller");
        this._masterSplitController.init();
        //
        this.view.addSubview(this._masterSplitController.view);
        this.addChildViewController(this._masterSplitController);
        //
        this._lapsopayHeaderViewController = new LapsopayPlaceListViewController("lapso_pay_list_view");
        this._lapsopayHeaderViewController.initWithResource("layout/external/lapsopay/LapsoPayListView.html");

        // this._lapsopayBodyViewController = new MUIViewController("lapso_pay_detail_view");
        // this._lapsopayBodyViewController.initWithResource("layout/external/lapsopay/LapsoPayDetailView.html");

        this._lapsopayBodyViewController = new MUIViewController("lapsopay_place_no_select_view");
        this._lapsopayBodyViewController.initWithResource("layout/external/lapsopay/LapsoPayPlaceNoSelectView.html");

        this._masterSplitController.setMasterViewController(this._lapsopayHeaderViewController);
        this._masterSplitController.setDetailViewController(this._lapsopayBodyViewController);
    }
}