//
// Generated class BookingDay
//

/// <reference path="BookingDay_ManagedObject.ts" />

enum BookingDayOccupationType{
    Empty,
    HalfFull,
    Full
}
class BookingDay extends BookingDay_ManagedObject
{    
/*    totalOccupation(){
        let occupation = this.occupation();
        return occupation["TOTAL"];
    }

    occupationByStatus(status:BookingStatus){
        let occupation = this.occupation();
        let value = occupation[status];
        if (value == null) return 0;

        return value;
    }

    private _occupation = null;
    private occupation(){
        if (this._occupation != null) return this._occupation;

        let occupation = {};
        let total = 0;
        for (let index = 0; index < this.bookings.count; index++){
            let b:Booking = this.bookings.objectAtIndex(index);
            let pax = b.pax;            
            let sumPax = occupation[b.status];
            if (sumPax == null) sumPax = 0;
            sumPax += pax;
            occupation[b.status] = sumPax;

            if (b.status == BookingStatus.BookingAssigned || b.status == BookingStatus.BookingReserved || b.status == BookingStatus.BookingConfirmed){
                total += b.pax;
            }
        }

        occupation["TOTAL"] = total;

        this._occupation = occupation;
        return this._occupation;
    }

    updateOccupation(){
        this._occupation = null;
    }*/
}
