//
// Generated class BookingZoneConfiguration
//

/// <reference path="BookingZoneConfiguration_ManagedObject.ts" />

class BookingZoneConfiguration extends BookingZoneConfiguration_ManagedObject
{

}
