

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class AccountingJournalEntry_ManagedObject

class AccountingJournalEntry_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: journalIndex
    set journalIndex(value:number) {
        this.setValueForKey(value, 'journalIndex');
    }
    get journalIndex():number {
        return this.valueForKey('journalIndex');
    }
    set journalIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'journalIndex');
    }
    get journalIndexPrimitiveValue():number {
        return this.primitiveValueForKey('journalIndex');
    }

    // Property: locked
    set locked(value:boolean) {
        this.setValueForKey(value, 'locked');
    }
    get locked():boolean {
        return this.valueForKey('locked');
    }
    set lockedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'locked');
    }
    get lockedPrimitiveValue():boolean {
        return this.primitiveValueForKey('locked');
    }
    // Relationship: bankMovement
    set bankMovement(value:BankMovement) {
        this.setValueForKey(value, 'bankMovement');
    }
    get bankMovement():BankMovement {
        return this.valueForKey('bankMovement') as BankMovement;
    }

    // Relationship: entries
    protected _entries:MIOManagedObjectSet = null;
    get entries():MIOManagedObjectSet {
        return this.valueForKey('entries');
    }
    addEntriesObject(value:AccountingEntry) {
        this._addObjectForKey(value, 'entries');
    }
    removeEntriesObject(value:AccountingEntry) {
        this._removeObjectForKey(value, 'entries');
    }
}
