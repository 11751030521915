
class ProductPresetListViewController extends EntityListViewController
{

    static newInstance() : ProductPresetListViewController {
        let vc = new ProductPresetListViewController("product-presets-list-view");
        vc.initWithResource("layout/productpresets/ProductPresetsListView.html");
        return vc;
    }

    private addButton:MUIButton = null;
    private settingsButton:MUIButton = null;
    private searchTextField:MUITextField = null;

    private searchString:string = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function(){
           this.addPreset();
        });

        this.searchTextField = MUIOutlet(this, 'search-bar', 'MUITextField');
        this.searchTextField.setOnChangeText(this, function(control:MUITextField, value:string){            
            this.searchString = value.trim().length > 0 ? value.trim() : null;                
            
            this.fetchedResultsController = null;            
            this.tableView.reloadData();
        });

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);

        this.updateUI();

        // MIONotificationCenter.defaultCenter().addObserver(this, "UpdateTimeRangePresetList", function(note:MIONotification){
        //     this.setFetchedResultsController(null);
        //     this.tableView.reloadData();
        // });
    }

    updateUI(){
        if (this.viewIsLoaded == false) return;

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }
    
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let cell = tableView.dequeueReusableCellWithIdentifier("ProductPresetsListCell") as ProductPresetsListCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Preset;
        cell.item = item;
        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath) as Preset;
        this.showItemDetail(selectedItem);
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) { 
        return UITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Preset;
        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            DBHelper.deleteObjectFromMainContext(item, true);
        }
    }

    private showItemDetail(item:Preset){     
        if (item == null) return;

        let vc = new ProductPresetsDetailViewController("product-presets-detail-view");
        vc.initWithResource("layout/productpresets/ProductPresetsDetailView.html");
        vc.item = item;    

        let nc = new MUINavigationController();
        nc.initWithRootViewController(vc);
            
        this.splitViewController.showDetailViewController(nc);
    }

    get entityName(){
        return 'Preset';
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
        
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)]; 
        let predicate = this.searchString != null ? "name contains '" + this.searchString + "'" : null;
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Preset", sortDescriptors, predicate);
        //fetchRequest.relationshipKeyPathsForPrefetching = ["rootItems.presetCategory", "rootItems.preset"];
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }
    
    private addPreset() {

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('ADD NEW PRESET','ADD NEW PRESET'), MIOLocalizeString('CHOOSE A NAME','CHOOSE A NAME'), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD','ADD'), MUIAlertActionStyle.Default, this, function(){
            let nameTF =  avc.textFields[0];
            let name = nameTF.text.trim();

            if(name.length > 0){
                let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
                let newPreset = MIOEntityDescription.insertNewObjectForEntityForName("Preset", moc) as Preset;
                newPreset.identifier = MIOUUID.UUID().UUIDString;
                newPreset.name = name;
                newPreset.type = PresetType.PresetTypeProducts;
                moc.save()
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('CHECK NAME','CHECK NAME'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','DONE'), MUIAlertActionStyle.Cancel, this, function(){}));
                this.presentViewController(error, true);
            }
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Cancel, this, function(){})); 
        this.presentViewController(avc, true);
    }


}