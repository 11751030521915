

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class IntegratorMappingForeignEntity_ManagedObject

class IntegratorMappingForeignEntity_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Relationship: mappings
    protected _mappings:MIOManagedObjectSet = null;
    get mappings():MIOManagedObjectSet {
        return this.valueForKey('mappings');
    }
    addMappingsObject(value:IntegratorMapping) {
        this._addObjectForKey(value, 'mappings');
    }
    removeMappingsObject(value:IntegratorMapping) {
        this._removeObjectForKey(value, 'mappings');
    }
}
