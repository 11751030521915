//
// Generated class ProductModifierCategory
//

/// <reference path="ProductModifierCategory_ManagedObject.ts" />

enum ProductModifierCategoryType {
    Manual = 0,
    Category = 1,
    Product = 2    
}

class ProductModifierCategory extends ProductModifierCategory_ManagedObject
{

    get relatedCategory(){
        if (this.category) return this.category;

        return null;
    }

}
