

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class AdyenBusinessLicense_ManagedObject

class AdyenBusinessLicense_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: legalEntityReference
    set legalEntityReference(value:string) {
        this.setValueForKey(value, 'legalEntityReference');
    }
    get legalEntityReference():string {
        return this.valueForKey('legalEntityReference');
    }
    set legalEntityReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalEntityReference');
    }
    get legalEntityReferencePrimitiveValue():string {
        return this.primitiveValueForKey('legalEntityReference');
    }
    // Relationship: business
    set business(value:BusinessLicense) {
        this.setValueForKey(value, 'business');
    }
    get business():BusinessLicense {
        return this.valueForKey('business') as BusinessLicense;
    }
}
