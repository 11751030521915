/**
 * Created by miguel on 1/2/17.
 */

class AddNewClientViewController extends MUIViewController
{
    delegate = null;
    completionBlock = null;
    
    private titleLabel:MUILabel = null;

    private nameTextField:MUITextField = null;
    private emailTextField:MUITextField = null;
    private documentTextField:MUITextField = null;
    private address1TextField:MUITextField = null;
    private address2TextField:MUITextField = null;
    private cityTextField:MUITextField = null;
    private stateTextField:MUITextField = null;
    private countryDropdown:MUIButton = null;
    private postalCodeTextField:MUITextField = null;
    private phoneTextField:MUITextField = null;
    private mobilePhoneTextField:MUITextField = null;

    private cancelButton:MUIButton = null;
    private saveButton:MUIButton = null;
    private imageButton:MUIButton = null;

    get preferredContentSize(){
        return new MIOSize(500, 510);
    }

    viewDidLoad(){
        super.viewDidLoad();

        this.cancelButton = MUIOutlet(this, 'ancv_nb_cancel_btn', 'MUIButton');
        this.cancelButton.setAction(this, function (){
            this.dissmiss();
        });

        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");

        this.saveButton = MUIOutlet(this, 'ancv_nb_ok_btn', 'MUIButton');
        this.saveButton.setAction(this, function (){
            if (this._client == null) this.addNewClient();
            else {
                DBHelper.saveMainContext();
                this.dissmiss();
            }
        });

        this.imageButton = MUIOutlet(this, 'ancv_c1_client_image', 'MUIButton');
        this.imageButton.setAction(this, function (){
            console.log('open image file');
        });

        this.nameTextField = MUIOutlet(this, "ancv_c2_client_text", "MUITextField");
        this.nameTextField.setOnChangeText(this, function(control, value:string){
            if (this._client == null) return;
            let v = value.length > 0 ? value : null;
            this._client.name = v; 
        });

        this.emailTextField = MUIOutlet(this, "ancv_c2_email_text", "MUITextField");
        this.emailTextField.setOnChangeText(this, function(control, value:string){
            if (this._client == null) return;
            let v = value.length > 0 ? value : null;
            this._client.email = v;
        });

        this.documentTextField = MUIOutlet(this, "ancv_c2_dni_text", "MUITextField");
        this.documentTextField.setOnChangeText(this, function(control, value:string){
            if (this._client == null) return;
            let v = value.length > 0 ? value : null;
            this._client.document = v;
        });

        this.phoneTextField = MUIOutlet(this, "ancv_c2_phone_number_text", "MUITextField");
        this.phoneTextField.setOnChangeText(this, function(control, value:string){
            if (this._client == null) return;
            let v = value.length > 0 ? value : null;
            this._client.phone.number = v;
        });

        this.mobilePhoneTextField = MUIOutlet(this, "ancv_c2_mobile_number_text", "MUITextField");
        this.mobilePhoneTextField.setOnChangeText(this, function(control, value:string){
            if (this._client == null) return;
            let v = value.length > 0 ? value : null;
            this._client.mobilePhone.number = v;
        });

        this.address1TextField = MUIOutlet(this, "ancv_c2_address_text", "MUITextField");       
        this.address1TextField.setOnChangeText(this, function(control, value:string){
            if (this._client == null) return;
            let v = value.length > 0 ? value : null;
            this._client.address.address1 = v;
        });
        
        this.cityTextField = MUIOutlet(this, "ancv_c2_city_text", "MUITextField");
        this.cityTextField.setOnChangeText(this, function(control, value:string){
            if (this._client == null) return;
            let v = value.length > 0 ? value : null;
            this._client.address.city = v;
        });

        this.stateTextField = MUIOutlet(this, "ancv_c2_state_text", "MUITextField");
        this.stateTextField.setOnChangeText(this, function(control, value:string){
            if (this._client == null) return;
            let v = value.length > 0 ? value : null;
            this._client.address.state = v;
        });

        this.postalCodeTextField = MUIOutlet(this, "ancv_c2_postal_code_text", "MUITextField");
        this.postalCodeTextField.setOnChangeText(this, function(control, value:string){
            if (this._client == null) return;
            let v = value.length > 0 ? value : null;
            this._client.address.postalCode = v;
        });

        this.countryDropdown = MUIOutlet(this, "country-dd", "MUIButton");
        this.countryDropdown.setAction(this, function(){
            AppHelper.sharedInstance().showSelectCountryViewControllerFromView(this.countryDropdown, null, this, function(controller:SelectEntityViewController, country:Country){                
                this.country = country;
                this.countryDropdown.title = country.name;                

                if (this._client != null) {
                    this._client.address.country = country.name;
                    // this._client.address.countryName = country.name;
                    this._client.address.countryISOA2 = country.iso2;
                    this._client.address.countryISOA3 = country.iso3;                    
                }
            });
        });   
                     
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private _name:string = null;
    set name(name:string){
        this._name = name;
    }

    private _client:Client = null;
    set client (client:Client){
        this._client = client;

        DBHelper.queryObjectsWithCompletion("Client", null, MIOPredicate.predicateWithFormat("identifier == " + client.identifier), ["address", "mobilePhone", "phone"], this, function(objects){
            this.updateUI();
        });

        this.updateUI();
    }
    
    private updateUI(){
        if (this.viewIsLoaded == false) return;

        //If called from invoices, must change text
        if(this.navigationController != null) {
            this.cancelButton.setTitle(MIOLocalizeString('BACK', 'BACK'));
            this.cancelButton.layer.classList.add('back_button');
        }
        
        if (this._client == null) {
            this.titleLabel.text = MIOLocalizeString("ADD NEW CLIENT", "ADD NEW CLIENT");
            this.nameTextField.text = this._name;
            this.emailTextField.text = null;
            this.documentTextField.text = null;
            this.phoneTextField.text = null;
            this.mobilePhoneTextField.text = null;
            this.address1TextField.text = null;
            this.cityTextField.text = null;
            this.stateTextField.text = null;        
            this.postalCodeTextField.text = null;
            this.countryDropdown.title = AppHelper.sharedInstance().defaultCountry.name;
            this.country = AppHelper.sharedInstance().defaultCountry;
        }
        else {
            this.titleLabel.text = MIOLocalizeString("EDIT CLIENT", "EDIT CLIENT");
            this.nameTextField.text = this._client.name;
            this.emailTextField.text = this._client.email;
            this.documentTextField.text = this._client.document;
            this.phoneTextField.text = this._client.phone.number;
            this.mobilePhoneTextField.text = this._client.mobilePhone.number;
            this.address1TextField.text = this._client.address.address1;
            this.cityTextField.text = this._client.address.city;
            this.stateTextField.text = this._client.address.state;        
            this.postalCodeTextField.text = this._client.address.postalCode;
            this.countryDropdown.title = getCountryName( this._client.address.countryISOa2 ) ; // .countryName;
            this.country = DBHelper.queryObjectFromMainContext("Country", MIOPredicate.predicateWithFormat("iso2 == " + this._client.address.countryISOa2));
        }

    }

    private country:Country = null;
    private addNewClient(){

        if (this.country == null) {
            AppHelper.showErrorMessage(this, "Country missing", "You need to select at leat the country.");
            return;
        }

        DBHelper.checkClient(this.nameTextField.text.trim(), this, function(result:boolean){
            if (result == true) this.createClient();
            else AppHelper.showErrorMessage(this, MIOLocalizeString("CLIENT ERROR ALERT", "CLIENT ERROR ALERT"), "Couldn't create a client. Please check the client doesn't exist yet.");
        });

    }

    private createClient(){
        let client = DBHelper.createClient();
        
        client.name = this.nameTextField.text.trim();
        client.email = this.emailTextField.text.trim();
        client.document = this.documentTextField.text.trim();        
        
        client.address.address1 = this.address1TextField.text.trim();
        client.address.city = this.cityTextField.text.trim();
        client.address.state = this.stateTextField.text.trim();
        client.address.postalCode = this.postalCodeTextField.text.trim();
        // client.address.countryName = this.country.name;
        client.address.country = this.country.name;
        client.address.countryISOa2 = this.country.iso2;
        client.address.countryISOa3 = this.country.iso3;
        
        client.phone.number = this.phoneTextField.text.trim();
        client.mobilePhone.number = this.mobilePhoneTextField.text.trim();
        
        DBHelper.saveMainContext();
        
        if(this.delegate != null && typeof this.delegate.clientDidInserted === "function")
            this.delegate.clientDidInserted(client);            

        if (this.completionBlock != null) this.completionBlock(this, client);

        this.dissmiss();
    }

    private dissmiss(){
        //if called from invoices, must behave as a navigation view
        if(this.navigationController != null) {
            this.navigationController.popViewController();
        }
        else
            this.dismissViewController(true);         
    }    
}