

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Tax_ManagedObject

class Tax_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: isDefault
    set isDefault(value:boolean) {
        this.setValueForKey(value, 'isDefault');
    }
    get isDefault():boolean {
        return this.valueForKey('isDefault');
    }
    set isDefaultPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isDefault');
    }
    get isDefaultPrimitiveValue():boolean {
        return this.primitiveValueForKey('isDefault');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: purchaseLedgerAccountName
    set purchaseLedgerAccountName(value:string) {
        this.setValueForKey(value, 'purchaseLedgerAccountName');
    }
    get purchaseLedgerAccountName():string {
        return this.valueForKey('purchaseLedgerAccountName');
    }
    set purchaseLedgerAccountNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'purchaseLedgerAccountName');
    }
    get purchaseLedgerAccountNamePrimitiveValue():string {
        return this.primitiveValueForKey('purchaseLedgerAccountName');
    }

    // Property: salesLedgerAccountName
    set salesLedgerAccountName(value:string) {
        this.setValueForKey(value, 'salesLedgerAccountName');
    }
    get salesLedgerAccountName():string {
        return this.valueForKey('salesLedgerAccountName');
    }
    set salesLedgerAccountNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'salesLedgerAccountName');
    }
    get salesLedgerAccountNamePrimitiveValue():string {
        return this.primitiveValueForKey('salesLedgerAccountName');
    }

    // Property: taxQuantity
    set taxQuantity(value:number) {
        this.setValueForKey(value, 'taxQuantity');
    }
    get taxQuantity():number {
        return this.valueForKey('taxQuantity');
    }
    set taxQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'taxQuantity');
    }
    get taxQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('taxQuantity');
    }

    // Relationship: categories
    protected _categories:MIOManagedObjectSet = null;
    get categories():MIOManagedObjectSet {
        return this.valueForKey('categories');
    }
    addCategoriesObject(value:ProductCategory) {
        this._addObjectForKey(value, 'categories');
    }
    removeCategoriesObject(value:ProductCategory) {
        this._removeObjectForKey(value, 'categories');
    }

    // Relationship: menuRates
    protected _menuRates:MIOManagedObjectSet = null;
    get menuRates():MIOManagedObjectSet {
        return this.valueForKey('menuRates');
    }
    addMenuRatesObject(value:MenuRate) {
        this._addObjectForKey(value, 'menuRates');
    }
    removeMenuRatesObject(value:MenuRate) {
        this._removeObjectForKey(value, 'menuRates');
    }

    // Relationship: menus
    protected _menus:MIOManagedObjectSet = null;
    get menus():MIOManagedObjectSet {
        return this.valueForKey('menus');
    }
    addMenusObject(value:Menu) {
        this._addObjectForKey(value, 'menus');
    }
    removeMenusObject(value:Menu) {
        this._removeObjectForKey(value, 'menus');
    }

    // Relationship: modifiers
    protected _modifiers:MIOManagedObjectSet = null;
    get modifiers():MIOManagedObjectSet {
        return this.valueForKey('modifiers');
    }
    addModifiersObject(value:ProductModifier) {
        this._addObjectForKey(value, 'modifiers');
    }
    removeModifiersObject(value:ProductModifier) {
        this._removeObjectForKey(value, 'modifiers');
    }

    // Relationship: productFormatRates
    protected _productFormatRates:MIOManagedObjectSet = null;
    get productFormatRates():MIOManagedObjectSet {
        return this.valueForKey('productFormatRates');
    }
    addProductFormatRatesObject(value:ProductFormatRate) {
        this._addObjectForKey(value, 'productFormatRates');
    }
    removeProductFormatRatesObject(value:ProductFormatRate) {
        this._removeObjectForKey(value, 'productFormatRates');
    }

    // Relationship: productModifierRates
    protected _productModifierRates:MIOManagedObjectSet = null;
    get productModifierRates():MIOManagedObjectSet {
        return this.valueForKey('productModifierRates');
    }
    addProductModifierRatesObject(value:ProductModifierRate) {
        this._addObjectForKey(value, 'productModifierRates');
    }
    removeProductModifierRatesObject(value:ProductModifierRate) {
        this._removeObjectForKey(value, 'productModifierRates');
    }

    // Relationship: productRates
    protected _productRates:MIOManagedObjectSet = null;
    get productRates():MIOManagedObjectSet {
        return this.valueForKey('productRates');
    }
    addProductRatesObject(value:ProductRate) {
        this._addObjectForKey(value, 'productRates');
    }
    removeProductRatesObject(value:ProductRate) {
        this._removeObjectForKey(value, 'productRates');
    }

    // Relationship: products
    protected _products:MIOManagedObjectSet = null;
    get products():MIOManagedObjectSet {
        return this.valueForKey('products');
    }
    addProductsObject(value:Product) {
        this._addObjectForKey(value, 'products');
    }
    removeProductsObject(value:Product) {
        this._removeObjectForKey(value, 'products');
    }

    // Relationship: productsBuy
    protected _productsBuy:MIOManagedObjectSet = null;
    get productsBuy():MIOManagedObjectSet {
        return this.valueForKey('productsBuy');
    }
    addProductsBuyObject(value:Product) {
        this._addObjectForKey(value, 'productsBuy');
    }
    removeProductsBuyObject(value:Product) {
        this._removeObjectForKey(value, 'productsBuy');
    }
    // Relationship: purchaseLedgerAccount
    set purchaseLedgerAccount(value:LedgerAccount) {
        this.setValueForKey(value, 'purchaseLedgerAccount');
    }
    get purchaseLedgerAccount():LedgerAccount {
        return this.valueForKey('purchaseLedgerAccount') as LedgerAccount;
    }
    // Relationship: salesLedgerAccount
    set salesLedgerAccount(value:LedgerAccount) {
        this.setValueForKey(value, 'salesLedgerAccount');
    }
    get salesLedgerAccount():LedgerAccount {
        return this.valueForKey('salesLedgerAccount') as LedgerAccount;
    }

    // Relationship: stockCategories
    protected _stockCategories:MIOManagedObjectSet = null;
    get stockCategories():MIOManagedObjectSet {
        return this.valueForKey('stockCategories');
    }
    addStockCategoriesObject(value:StockCategory) {
        this._addObjectForKey(value, 'stockCategories');
    }
    removeStockCategoriesObject(value:StockCategory) {
        this._removeObjectForKey(value, 'stockCategories');
    }

    // Relationship: subTaxes
    protected _subTaxes:MIOManagedObjectSet = null;
    get subTaxes():MIOManagedObjectSet {
        return this.valueForKey('subTaxes');
    }
    addSubTaxesObject(value:SubTax) {
        this._addObjectForKey(value, 'subTaxes');
    }
    removeSubTaxesObject(value:SubTax) {
        this._removeObjectForKey(value, 'subTaxes');
    }

    // Relationship: ticketLines
    protected _ticketLines:MIOManagedObjectSet = null;
    get ticketLines():MIOManagedObjectSet {
        return this.valueForKey('ticketLines');
    }
    addTicketLinesObject(value:TicketLine) {
        this._addObjectForKey(value, 'ticketLines');
    }
    removeTicketLinesObject(value:TicketLine) {
        this._removeObjectForKey(value, 'ticketLines');
    }
}
