

/// <reference path="NumberSerialSequence.ts" />

// Generated class AccountingSequence_ManagedObject

class AccountingSequence_ManagedObject extends NumberSerialSequence
{

    // Property: conditions
    set conditions(value:string) {
        this.setValueForKey(value, 'conditions');
    }
    get conditions():string {
        return this.valueForKey('conditions');
    }
    set conditionsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'conditions');
    }
    get conditionsPrimitiveValue():string {
        return this.primitiveValueForKey('conditions');
    }

    // Property: ledgerAccountName
    set ledgerAccountName(value:string) {
        this.setValueForKey(value, 'ledgerAccountName');
    }
    get ledgerAccountName():string {
        return this.valueForKey('ledgerAccountName');
    }
    set ledgerAccountNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ledgerAccountName');
    }
    get ledgerAccountNamePrimitiveValue():string {
        return this.primitiveValueForKey('ledgerAccountName');
    }
    // Relationship: ledgerAccount
    set ledgerAccount(value:LedgerAccount) {
        this.setValueForKey(value, 'ledgerAccount');
    }
    get ledgerAccount():LedgerAccount {
        return this.valueForKey('ledgerAccount') as LedgerAccount;
    }
}
