
class DBFormatRatesCoder extends DBXLSXCoder
{
    columns() : any[] {
        let cols = [
            { title: "FORMAT RATE ID", width: "20%", align: "center" },
            { title: "PRODUCT FORMAT ID", width: "20%", align: "center" },
            { title: "PRODUCT ID", width: "20%", align: "center" },
            { title: "PRODUCT NAME", width: "20%", align: "center" },
            { title: "FORMAT ID", width: "20%", align: "center" },
            { title: "FORMAT NAME", width: "20%", align: "center" },
            { title: "TAX ID", width: "20%", align: "center" },
            { title: "TAX NAME", width: "20%", align: "center" },
            { title: "RATE ID", width: "10%", align: "center" },
            { title: "RATE NAME", width: "30%", align: "center" },
            { title: "PRICE", width: "10%", align: "center", formatter: this.ad.currencyFormatter }
        ]
        return cols;
    }

    protected mainEntity() {
        return { entity: "ProductFormatRate", 
                 predicateFormat: "deletedAt == null && productFormat.deletedAt == null && productFormat.format.deletedAt == null && productFormat.product.deletedAt == null", 
                 relations:["rate", "tax", "productFormat.product", "productFormat.format"]
        };
    }

    aditionalImportEntities(){
        return [
            { entity: "Format", predicateFormat: "deletedAt == null" },
            { entity: "Product", predicateFormat: "deletedAt == null" },
            { entity: "Tax", predicateFormat: "deletedAt == null" },            
            { entity: "ProductFormat", predicateFormat: "deletedAt == null" },
            { entity: "Rate", predicateFormat: "deletedAt == null" }
        ]
    }

    protected importRow(row:any){
        
        let formatRateID     = row["FORMAT RATE ID"];
        let productFormatID  = row["PRODUCT FORMAT ID"];
        let productName      = row["PRODUCT NAME"];
        let formatName       = row["FORMAT NAME"];
        let rateID           = row["RATE ID"];
        let rateName         = row["RATE NAME"];
        let taxID            = row["TAX ID"];
        let taxName          = row["TAX NAME"];
        let price            = row["PRICE"];
        
        let rate = this.queryEntityByIDOrName("Rate", rateID, rateName) as Rate;
        if (rate == null) {
            return;
        }

        let pf = this.queryEntityByField("ProductFormat", "identifier", productFormatID);
        if (pf == null) {
            return;
        }

        let tax = this.queryEntityByIDOrName("Tax", taxID, taxName) as Tax;
        
        let productFormatRate = this.queryEntityByField( "ProductFormatRate", "identifier", formatRateID ) as ProductFormatRate;
        if (productFormatRate == null) {
            productFormatRate = MIOEntityDescription.insertNewObjectForEntityForName("ProductFormatRate", DBHelper.mainManagedObjectContext) as ProductFormatRate;
            productFormatRate.identifier = this.parseOrCreateIdentifier(formatRateID);                        
        }

        productFormatRate.productFormat = pf;
        productFormatRate.rate = rate;
        productFormatRate.price = price
        productFormatRate.tax = tax;        

        this.appendObject(productFormatRate);
        MIOLog("ADDING PRODUCT FORMAT RATE: " + this.rowIndex + "/" + this.rows.length + ": " + formatName + " - " + productName);
    } 

    protected exportTitle() : string { return "Product Format Rates"; }
             
    protected exportSortDescriptors() { 
        return [MIOSortDescriptor.sortDescriptorWithKey("productFormat.product.name", true), 
                MIOSortDescriptor.sortDescriptorWithKey("productFormat.format.name", true)]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let pfr = object as ProductFormatRate;

        let item = {
            "FORMAT RATE ID"    : pfr.identifier,
            "PRODUCT FORMAT ID" : pfr.productFormat.identifier,
            "PRODUCT ID"        : pfr.productFormat.product.identifier,
            "PRODUCT NAME"      : pfr.productFormat.product.name,
            "FORMAT ID"         : pfr.productFormat.format.identifier,
            "FORMAT NAME"       : pfr.productFormat.format.name,
            "RATE ID"           : pfr.rate.identifier,
            "RATE NAME"         : pfr.rate.name,            
            "TAX ID"            : pfr.tax?.identifier,
            "TAX NAME"          : pfr.tax?.name,
            "PRICE"             : pfr.price
        }

        return item;
    }
}