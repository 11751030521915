//
// Generated class StockNote
//

/// <reference path="StockNote_ManagedObject.ts" />

enum StockNoteType
{
        InventoryNote,          // 0
        CustomInputNote,        // 1
        CustomOutputNote,       // 2
        InternalInputNote,      // 3
        InternalOutputNote,     // 4
        InternalOrder,          // 5
        InternalOrderResponse,  // 6
        MovementNote,           // 7
        DeliveryNote,           // 8
        SupplierNote,           // 9
        SupplierOrder,          // 10
        LowStockOrder,          // 11
        ProductionNote,         // 12
        DeliveryOrder,          // 13
        InternalReturnOrder,    // 14
        SupplierReturnNote,     // 15
        BuyOrder,               // 16
        
        WarehouseOrder,         // 17
        WarehouseInputNote,     // 18
        WarehouseDeliveryNote   // 19

}

enum StockNoteStatus
{
    None,
    Pending,
    Processed,
    Unprocessed
}

class StockNote extends StockNote_ManagedObject
{
    static sectionIDFromStockNote(note:StockNote) : string {
        switch(note.type) {
            case StockNoteType.InventoryNote:          return SectionViewControllerType.InventoryNote;
            case StockNoteType.CustomInputNote:        return SectionViewControllerType.InputNote;
            case StockNoteType.CustomOutputNote:       return SectionViewControllerType.OutputNote;
            // case StockNoteType.InternalInputNote:      return SectionViewControllerType.InventoryNote
            // case StockNoteType.InternalOutputNote:     return SectionViewControllerType.InventoryNote
            // case StockNoteType.InternalOrder:          return SectionViewControllerType.InventoryNote
            // case StockNoteType.InternalOrderResponse:  return SectionViewControllerType.InventoryNote
            case StockNoteType.MovementNote:           return SectionViewControllerType.MovementNote;
            case StockNoteType.DeliveryNote:           return SectionViewControllerType.DeliveryNote;
            case StockNoteType.SupplierNote:           return SectionViewControllerType.SupplierNote;
            case StockNoteType.SupplierOrder:          return SectionViewControllerType.SupplierOrder;
            case StockNoteType.LowStockOrder:          return SectionViewControllerType.LowStockOrder;
            // case StockNoteType.ProductionNote:         return SectionViewControllerType.InventoryNote
            case StockNoteType.DeliveryOrder:          return SectionViewControllerType.DeliveryOrder;
            // case StockNoteType.InternalReturnOrder:    return SectionViewControllerType.InventoryNote
            // case StockNoteType.SupplierReturnNote:     return SectionViewControllerType.InventoryNote
            case StockNoteType.BuyOrder:               return SectionViewControllerType.BuyOrder;
            case StockNoteType.WarehouseOrder:         return SectionViewControllerType.WarehouseOrder;
            case StockNoteType.WarehouseDeliveryNote:  return SectionViewControllerType.WarehouseDelivery;
            case StockNoteType.WarehouseInputNote:     return SectionViewControllerType.WarehouseInput;

        }

        return null;
    }

    get statusString():string{
        switch(this.status){
            case StockNoteStatus.None:
            return MIOLocalizeString("NONE", "None");

            case StockNoteStatus.Pending:
            return MIOLocalizeString("PENDING", "Pending");

            case StockNoteStatus.Processed:
            return MIOLocalizeString("PROCESSED", "Processed");
        }

        return null;
    }
}
