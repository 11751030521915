class GlobalActionsViewController extends MUIViewController
{  
    private backButton:MUIButton = null;
    private tableView:MUITableView = null;

    public delegate = null;
    public preferredContentSize = new MIOSize(350, 700);

    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.backButton.setAction(this, function (){            
            this.dismissViewController(true);
        });

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(){
        super.viewWillAppear();

        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        // this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView){
        //sections: Global actions over multiple categories, Global actions over products
        return 2;
    }

    private sectionRows = [12, 4]; //number of rows for each section
    numberOfRowsInSection(tableView:UITableView, section:number){
        return this.sectionRows[section];
    }

    private sectionTitles = [ MIOLocalizeString("GLOBAL ACTIONS OVER MULTIPLE CATEGORIES","GLOBAL ACTIONS OVER MULTIPLE CATEGORIES"),
                              MIOLocalizeString("GLOBAL ACTIONS OVER PRODUCTS","GLOBAL ACTIONS OVER PRODUCTS") ];
    
    titleForHeaderInSection(tableView:UITableView, section:number) {          
        return this.sectionTitles[section];
    }

    private categoryActions = [ 
                            MIOLocalizeString("PRODUCT SOURCES","PRODUCT SOURCES"),
                            MIOLocalizeString("ELABORATION CENTER","ELABORATION CENTER"),
                            MIOLocalizeString("WAREHOUSES","WAREHOUSES"),
                            MIOLocalizeString("MODIFIERS","MODIFIERS"),
                            MIOLocalizeString("KITCHEN ORDER","KITCHEN ORDER"),
                            MIOLocalizeString("TAXES","TAXES"),
                            MIOLocalizeString("TAGS","TAGS"),
                            MIOLocalizeString("FORMATS","FORMATS"),
                            MIOLocalizeString("STOCK","STOCK"),
                            MIOLocalizeString("PRICE GROUP","PRICE GROUP"),
                            MIOLocalizeString("BARCODES","BARCODES"),
                            MIOLocalizeString("STOCK CATEGORY","STOCK CATEGORY")
                            ];
    
    private productActionKeys = [
                                MIOLocalizeString("SEASONAL PRODUCTS","SEASONAL PRODUCTS"),
                                MIOLocalizeString("INACTIVE PRODUCTS (NOT FOR SALE)","INACTIVE PRODUCTS (NOT FOR SALE)"),
                                MIOLocalizeString("PRICE GROUP","PRICE GROUP"),
                                MIOLocalizeString("PRINT PRODUCT BARCODES","PRINT PRODUCT BARCODES"),
                                MIOLocalizeString("SET DEFAULT WAREHOUSE","SET DEFAULT WAREHOUSE")
                            ];

    private productActions = {
                            "SEASONAL PRODUCTS" : null,
                            "INACTIVE PRODUCTS (NOT FOR SALE)" : null,
                            "PRICE GROUP": null,
                            "PRINT PRODUCT BARCODES": null,
                            "SET DEFAULT WAREHOUSE": () => { return }
    };
    

                                                 
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){        

        let cell:any = tableView.dequeueReusableCellWithIdentifier("GlobalActionsLabelCell") as GlobalActionsLabelCell;
        //let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Configuration;
        
        switch (indexPath.section){

            case 0: cell.name =  MIOLocalizeString(this.categoryActions[indexPath.row], this.categoryActions[indexPath.row]); break;
            case 1: cell.name =  MIOLocalizeString(this.productActions[indexPath.row], this.productActions[indexPath.row]); break;   
        }

        return cell;
    }   

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){ 

        switch (indexPath.section){
            // First section
            case 0:
                switch (indexPath.row){

                    case 0:
                        //Source
                        let sgavc = new SourceGlobalActionsViewController('source-global-actions-view');
                        sgavc.initWithResource("layout/globalactions/SourceGlobalActionsView.html");
                        this.navigationController.pushViewController(sgavc, true);                    
                    break;
                          
                    case 1:
                        //Ellaboration center
                        let ecgavc = new EllaborationCenterGlobalActionsViewController('ellaboration-center-global-actions-view');
                        ecgavc.initWithResource("layout/globalactions/EllaborationCenterGlobalActionsView.html");
                        this.navigationController.pushViewController(ecgavc, true); 
                    break;   
                    
                    case 2:
                        //Warehouse
                        let wgavc = new WarehouseGlobalActionsViewController('warehouse-global-actions-view');
                        wgavc.initWithResource("layout/globalactions/WarehouseGlobalActionsView.html");
                        this.navigationController.pushViewController(wgavc, true); 
                    break; 

                    case 3:
                        //Modifiers
                        let mgavc = new ModifierGlobalActionsViewController('modifier-global-actions-view');
                        mgavc.initWithResource("layout/globalactions/ModifierGlobalActionsView.html");
                        this.navigationController.pushViewController(mgavc, true); 
                    break; 

                    case 4:
                        //Kitchen Order
                        let kogavc = new KitchenOrderGlobalActionsViewController('kitchen-order-global-actions-view');
                        kogavc.initWithResource("layout/globalactions/KitchenOrderGlobalActionsView.html");
                        this.navigationController.pushViewController(kogavc, true);
                    break; 

                    case 5:
                        //Taxes
                        let tgavc = new TaxesGlobalActionsViewController('taxes-global-actions-view');
                        tgavc.initWithResource("layout/globalactions/TaxesGlobalActionsView.html");
                        this.navigationController.pushViewController(tgavc, true);
                    break; 

                    case 6:
                        //Product Tags
                        let ptagavc = new ProductTagsGlobalActionsViewController('product-tags-global-actions-view');
                        ptagavc.initWithResource("layout/globalactions/ProductTagsGlobalActionsView.html");
                        this.navigationController.pushViewController(ptagavc, true);
                    break; 

                    case 7:
                        //Formats
                        let fgavc = new FormatsGlobalActionsViewController('formats-global-actions-view');
                        fgavc.initWithResource("layout/globalactions/FormatsGlobalActionsView.html");
                        this.navigationController.pushViewController(fgavc, true);
                    break; 

                    case 8:
                        //Stock
                        let stgavc = new StockConfigurationGlobalActionsViewController('stock-configuration-global-actions-view');
                        stgavc.initWithResource("layout/globalactions/StockConfigurationGlobalActionsView.html");
                        this.navigationController.pushViewController(stgavc, true);
                    break; 
                    
                    case 9:
                        //Price Group
                        let pgavc = new PriceGroupGlobalActionsViewController('price-group-global-actions-view');
                        pgavc.initWithResource("layout/globalactions/PriceGroupGlobalActionsView.html");
                        this.navigationController.pushViewController(pgavc, true);  
                    break; 

                    case 10:
                        //Barcodes
                        let bgavc = new BarcodesGlobalActionsViewController('barcodes-global-actions-view');
                        bgavc.initWithResource("layout/globalactions/BarcodesGlobalActionsView.html");
                        this.navigationController.pushViewController(bgavc, true);
                    break; 

                    case 11:
                        // Stock Category
                        // let scgavc = new StockCategoryGlobalActionsViewController('stock-category-global-actions-view');
                        // scgavc.initWithResource("layout/globalactions/StockCategoryGlobalActionsView.html");
                        // this.navigationController.pushViewController(scgavc, true);
                    break; 

                }
            break;
            //Second section    
            case 1:
                switch (indexPath.row){

                    case 0:                                           
                    break;

                    case 1:
                    break;   
                    
                    case 2:
                    break; 

                    case 3:
                    break; 

                }
            break;
        }
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }

}
