class EditConsumptionProfileListViewController extends BaseTableViewController
{

    static newInstance() : EditConsumptionProfileListViewController {
        let vc = new EditConsumptionProfileListViewController("edit-consumption-profile-list-view");
        vc.initWithResource("layout/consumptionprofile/EditConsumptionProfileListView.html");
        return vc;
    }
    
    protected closeButton:MUIButton = null;
    protected addButton:MUIButton = null;
    protected tableView:UITableView = null;
    protected selectedItem = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.closeButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.closeButton.setAction(this, function (){            
            this.dismissViewController(true);
        });

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function (){            
            this.addConsumptionProfile();
        });

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;   
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;
        this.fetchedResultsController = null;
        this.tableView.reloadData();            
    }
    
    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
        let cell = tableView.dequeueReusableCellWithIdentifier('ConsumptionProfileListCell') as ConsumptionProfileListCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ConsumptionProfile;
        cell.title = item.name;
        cell.accessoryType = UITableViewCellAccessoryType.None;

        return cell;
    }
    
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath) as ConsumptionProfile;
        this.showSelectedConsumptionProfile(this.selectedItem);
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("ConsumptionProfile");
        fetchRequest.sortDescriptors = null;
        fetchRequest.predicate = null; // MIOPredicate.predicateWithFormat("rate.identifier == " + this._rate.identifier);
        fetchRequest.relationshipKeyPathsForPrefetching = ['ellaborationCenter'];
                
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    private addConsumptionProfile() {

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('NEW CONSUMPTION PROFILE','NEW CONSUMPTION PROFILE'), MIOLocalizeString('CHOOSE A NAME','CHOOSE A NAME'), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD','ADD'), MUIAlertActionStyle.Default, this, function(){
            let nameTF =  avc.textFields[0];
            let name = nameTF.text.trim();

            if(name.length > 0){
                let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
                let newConsumProfile = MIOEntityDescription.insertNewObjectForEntityForName("ConsumptionProfile", moc) as ConsumptionProfile;
                newConsumProfile.identifier = MIOUUID.UUID().UUIDString;
                newConsumProfile.name = name;

                moc.save();
                this.updateUI();
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('INVALID NAME','INVALID NAME'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, this, function(){}));
                
                this.presentViewController(error, true);          
            }
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Cancel, this, function(){}));
        
        this.presentViewController(avc, true);


    }

    private detailViewController:EditConsumptionProfileDetailViewController = null;
    private showSelectedConsumptionProfile(consumptionProfile:ConsumptionProfile){

        if(this.detailViewController == null) {
            this.detailViewController  = new EditConsumptionProfileDetailViewController("edit-consumption-profile-detail-view");
            this.detailViewController.initWithResource("layout/consumptionprofile/EditConsumptionProfileDetailView.html");
        }

        this.detailViewController.item = consumptionProfile;
        this.splitViewController.showDetailViewController(this.detailViewController);        
    }

}