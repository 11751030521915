//
// Generated class AdyenPaymentEntityPaymethod
//

/// <reference path="AdyenPaymentEntityPaymethod_ManagedObject.ts" />

class AdyenPaymentEntityPaymethod extends AdyenPaymentEntityPaymethod_ManagedObject
{

}
