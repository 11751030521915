//
// Generated class LicenseInvoiceLine
//

/// <reference path="LicenseInvoiceLine_ManagedObject.ts" />

class LicenseInvoiceLine extends LicenseInvoiceLine_ManagedObject
{

}
