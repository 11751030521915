//
// Generated class SalesAnnotation
//

/// <reference path="SalesAnnotation_ManagedObject.ts" />

class SalesAnnotation extends SalesAnnotation_ManagedObject
{

}
