/// <reference path="../base/BaseTableViewController.ts" />
/// <reference path="StockTemplateDetailViewController.ts" />

class StockTemplateListViewController extends BaseTableViewController 
{
    private addButton:MUIButton = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, this.createNewInvetoryTemplateAction);
        
        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('StockTemplateCell') as StockTemplateCell;
    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as StockTemplate;
        cell.item = item;           
        
        cell.selected = this.selectedItem == item ? true : false;

        return cell;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath) as StockTemplate;
        this.showSelectedItem();
    }

    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {        

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            let item = this.fetchedResultsController.objectAtIndexPath(indexPath);    
            if (item == this.selectedItem) {
                this.selectedItem = null;
                this.showNoItemSelected();
            }
            DBHelper.deleteObjectFromMainContext(item, true);
        }
    }  
    
    set fetchedResultsController(value){
        this.setFetchedResultsController(value);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

        let predicateFormat = null;
        if(this.searchString != null) {
            predicateFormat += "name CONTAINS '" + this.searchString + "'";
		}
		
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("StockTemplate", sortDescriptors, predicateFormat);
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }

    private detailViewController:StockTemplateDetailViewController = null;    
    protected showSelectedItem(){        
        let vc = StockTemplateDetailViewController.newInstance();
        vc.item = this.selectedItem;
        this.splitViewController.showDetailViewController(vc);        
    }

    protected showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("StockTemplate", NoItemSelectedViewControllerType.StockTemplate);
        this.splitViewController.showDetailViewController(vc);
    }

    private createNewInvetoryTemplateAction(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let it = MIOEntityDescription.insertNewObjectForEntityForName("StockTemplate", ad.managedObjectContext) as StockTemplate;
        it.name = MIOLocalizeString("STOCK TEMPLATE", "STOCK TEMPLATE");
        it.type = 0xffff;
        this.selectedItem = it;
        DBHelper.saveMainContext();

        this.showSelectedItem();
    }
    
}