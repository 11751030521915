

// Generated class BookingLocationType_ManagedObject

class BookingLocationType_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: title
    set title(value:string) {
        this.setValueForKey(value, 'title');
    }
    get title():string {
        return this.valueForKey('title');
    }
    set titlePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'title');
    }
    get titlePrimitiveValue():string {
        return this.primitiveValueForKey('title');
    }

    // Property: minpax
    set minpax(value:number) {
        this.setValueForKey(value, 'minpax');
    }
    get minpax():number {
        return this.valueForKey('minpax');
    }
    set minpaxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minpax');
    }
    get minpaxPrimitiveValue():number {
        return this.primitiveValueForKey('minpax');
    }

    // Property: maxpax
    set maxpax(value:number) {
        this.setValueForKey(value, 'maxpax');
    }
    get maxpax():number {
        return this.valueForKey('maxpax');
    }
    set maxpaxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'maxpax');
    }
    get maxpaxPrimitiveValue():number {
        return this.primitiveValueForKey('maxpax');
    }

    // Property: typelimit
    set typelimit(value:number) {
        this.setValueForKey(value, 'typelimit');
    }
    get typelimit():number {
        return this.valueForKey('typelimit');
    }
    set typelimitPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'typelimit');
    }
    get typelimitPrimitiveValue():number {
        return this.primitiveValueForKey('typelimit');
    }

    // Property: current
    set current(value:number) {
        this.setValueForKey(value, 'current');
    }
    get current():number {
        return this.valueForKey('current');
    }
    set currentPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'current');
    }
    get currentPrimitiveValue():number {
        return this.primitiveValueForKey('current');
    }

    // Property: priceType
    set priceType(value:number) {
        this.setValueForKey(value, 'priceType');
    }
    get priceType():number {
        return this.valueForKey('priceType');
    }
    set priceTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'priceType');
    }
    get priceTypePrimitiveValue():number {
        return this.primitiveValueForKey('priceType');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: createdAt
    set createdAt(value:Date) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():Date {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():Date {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:Date) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():Date {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:Date) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():Date {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: relationDay
    set relationDay(value:RelationBookingDay) {
        this.setValueForKey(value, 'relationDay');
    }
    get relationDay():RelationBookingDay {
        return this.valueForKey('relationDay') as RelationBookingDay;
    }

    // Relationship: priceRules
    protected _priceRules:MIOManagedObjectSet = null;
    get priceRules():MIOManagedObjectSet {
        return this.valueForKey('priceRules');
    }
    addPriceRulesObject(value:BookingLocationPriceRule) {
        this._addObjectForKey(value, 'priceRules');
    }
    removePriceRulesObject(value:BookingLocationPriceRule) {
        this._removeObjectForKey(value, 'priceRules');
    }
}
