class DBToolResultCell extends MUIReportTableViewCell
{
    private valueLabel:MUILabel = null;

    awakeFromHTML(){        
        this.valueLabel = MUIOutlet(this, 'value-lbl', 'MUILabel');
    }

    set value(value){
        this.valueLabel.text = value;
    }
}
