//
// Generated class DeliveryOrderLine
//

/// <reference path="DeliveryOrderLine_ManagedObject.ts" />

class DeliveryOrderLine extends DeliveryOrderLine_ManagedObject
{

}
