/// <reference path="WebService.ts" />

interface WebService
{    
    generateInvoice( business: BusinessLicense, chargeToDistributor: boolean, date:Date, serial:NumberSerialSequence, completion: any ) : void ;
    createTokensAndInstallments( business: BusinessLicense, date:Date, completion: any ) : void ;
    updateTokensPrice( business: BusinessLicense, date:Date, completion: any ) : void ;
    createInvoiceFromInstallment( installment: PaymentInstallment, completion: any ) : void ;
    updateInvoiceFromInstallment( installment: PaymentInstallment, completion: any ) : void ;
    createTokenFromLicense( license: License, date:Date, completion: any ) : void ;
    createTokensFromBusiness( business: BusinessLicense, date:Date, completion: any ) : void ;
    // updateBusinessInstallment( business: BusinessLicense, date:Date, completion: any ) : void ;    
    regenerateInvoice( invoiceNumber:string, business: BusinessLicense, tokenDate:Date, completion: any ) : void ;
    createReclaimInvoice( doc:Invoice, amount:number, completion:any ) : void ;
    sendLicenseInvoice( doc:Invoice, completion:any ) : void ;
    duplicateInstallment( installment:PaymentInstallment, date:Date, completion:any ) : void ;
    generateSEPAFileFromInstallments( installments:PaymentInstallment[], type:string, account:BankAccount, dueDate:string, filename:string, completion:any ) : void;
}


WebService.prototype.generateInvoice = function( business: BusinessLicense, chargeToDistributor: boolean, date:Date, serial:NumberSerialSequence, completion: any ) : void
{
    let body = {}
    body["BusinessID"] = business.identifier;
    body["ChargeToDistributor"] = chargeToDistributor;
    body["Date"] = ( MUIWebApplication.sharedInstance().delegate as AppDelegate ).serverDateTimeFormatter.stringFromDate( date );
    //body["Date"] = (new MIOISO8601DateFormatter()).stringForObjectValue(date);
    
    if ( serial != null ) body["NumberSerialID"] = serial.identifier;

    const url = MIOURL.urlWithString(this.apiURL + "/entity/" + this.scheme + "/License/generate-invoice");
    let urlRequest = new MWSJSONRequest();
    urlRequest.initWithURL(url, body, "POST");
    urlRequest.execute(this, function(code:number, json:any) { 
        completion(json["data"], json["error"]);
    });

}

WebService.prototype.createTokensAndInstallments = function( business: BusinessLicense, date:Date, completion: any ) : void
{
    let d = (MUIWebApplication.sharedInstance().delegate as AppDelegate).serverDateFormatter.stringFromDate( date );

    let body = {}
    body["BusinessID"] = business.identifier;
    body["Date"] = d;

    const url = MIOURL.urlWithString(this.apiURL + "/entity/" + this.scheme + "/License/create-business-tokens-installments");
    let urlRequest = new MWSJSONRequest();
    urlRequest.initWithURL(url, body, "POST");
    urlRequest.execute(this, function(code:number, json:any) { 
        completion(json["data"], json["error"]);
    });
}

WebService.prototype.updateTokensPrice = function( business: BusinessLicense, date:Date, completion: any ) : void
{
    let d = (MUIWebApplication.sharedInstance().delegate as AppDelegate).serverDateFormatter.stringFromDate( date );

    let body = {}
    body["BusinessID"] = business.identifier;
    body["Date"] = d;

    const url = MIOURL.urlWithString(this.apiURL + "/entity/" + this.scheme + "/License/update-business-tokens-price");
    let urlRequest = new MWSJSONRequest();
    urlRequest.initWithURL(url, body, "POST");
    urlRequest.execute(this, function(code:number, json:any) { 
        completion(json["data"], json["error"]);
    });
}

WebService.prototype.createInvoiceFromInstallment = function( installment: PaymentInstallment, completion: any ) : void
{
    const url = MIOURL.urlWithString(this.apiURL + "/entity/" + this.scheme + "/License/create-invoice-from-installment/" + installment.identifier );
    let urlRequest = new MWSJSONRequest();
    urlRequest.initWithURL(url, null, "GET");
    urlRequest.execute(this, function(code:number, json:any) { 
        completion(json["data"], json["error"]);
    });
}

WebService.prototype.updateInvoiceFromInstallment = function( installment: PaymentInstallment, completion: any ) : void
{
    const url = MIOURL.urlWithString(this.apiURL + "/entity/" + this.scheme + "/License/update-invoice-from-installment/" + installment.identifier );
    let urlRequest = new MWSJSONRequest();
    urlRequest.initWithURL(url, null, "GET");
    urlRequest.execute(this, function(code:number, json:any) { 
        completion(json["data"], json["error"]);
    });
}


WebService.prototype.createTokenFromLicense = function ( license: License, date:Date, completion: any ) : void
{
    let d = (MUIWebApplication.sharedInstance().delegate as AppDelegate).serverDateFormatter.stringFromDate( date );
    
    const url = MIOURL.urlWithString( this.apiURL + "/entity/" + this.scheme + "/License/create-token-from-license/" + license.identifier + "/" + d );
    let urlRequest = new MWSJSONRequest();
    urlRequest.initWithURL(url, null, "GET");
    urlRequest.execute(this, function(code:number, json:any) { 
        completion(json["data"], json["error"]);
    });
}

WebService.prototype.createTokensFromBusiness = function ( business: BusinessLicense, date:Date, completion: any ) : void
{
    let d = (MUIWebApplication.sharedInstance().delegate as AppDelegate).serverDateFormatter.stringFromDate( date );
    
    const url = MIOURL.urlWithString( this.apiURL + "/entity/" + this.scheme + "/License/create-tokens-from-business/" + business.identifier + "/" + d );
    let urlRequest = new MWSJSONRequest();
    urlRequest.initWithURL(url, null, "GET");
    urlRequest.execute(this, function(code:number, json:any) { 
        completion(json["data"], json["error"]);
    });
}


// WebService.prototype.updateBusinessInstallment = function( business: BusinessLicense, date:Date, completion: any ) : void
// {
//     let d = (MUIWebApplication.sharedInstance().delegate as AppDelegate).serverDateFormatter.stringFromDate( date );

//     let body = {}
//     body["BusinessID"] = business.identifier;
//     body["Date"] = d;

//     const url = MIOURL.urlWithString(this.apiURL + "/entity/" + this.scheme + "/License/update-business-installment");
//     let urlRequest = new MWSJSONRequest();
//     urlRequest.initWithURL(url, body, "POST");
//     urlRequest.execute(this, function(code:number, json:any) { 
//         completion(json["data"], json["error"]);
//     });
// }

WebService.prototype.regenerateInvoice = function( invoiceNumber:string, business: BusinessLicense, tokenDate:Date, completion: any ) : void
{
    let body = {
        "BusinessID": business.identifier,
        "InvoiceNumber": invoiceNumber,
        "Date": ( MUIWebApplication.sharedInstance().delegate as AppDelegate ).serverDateFormatter.stringFromDate( tokenDate )
    }

    const url = this.toolURL + "/schema/" + this.scheme + "/License/re-generate-invoice";
    let r = new DLRequest( url, body, "POST" );
    r.execute(completion);
}

WebService.prototype.createReclaimInvoice = function( doc:Invoice, amount:number, completion:any ) {
    
    if (this.lock_doc_id.containsObject(doc.identifier)) return;
    this.lock_doc_id.addObject(doc.identifier);        

    const url_str = this.apiURL + "/entity/" + this.scheme + "/License/generate-reclaim-invoice"
    let r = new DLRequest ( url_str, { 
        "DocumentID": doc.identifier,
        "ReclaimAmount": amount 
    }, "POST" );
    
    r.execute( (json:any, error:string) => {
        this.lock_doc_id.removeObject(doc.identifier);

        if (error != null) {
            AppHelper.showErrorMessage( null, MIOLocalizeString("ERROR", "ERROR"), error );
        }
        else {
            let doc_number = json["DocumentNumber"];
            let msg = MIOLocalizeString( "VOID INVOICE CREATED SUCCESFULLY", "VOID INVOICE CREATED SUCCESFULLY");
            AppHelper.showInfoMessage( null, MIOLocalizeString("INFO", "INFO"), doc_number + " " + msg );
        }

        completion(json, error);
    });
}

WebService.prototype.sendLicenseInvoice = function( doc:Invoice, completion:any ) {
    const url_str = this.toolURL + "/schema/" + this.scheme + "/License/send-email-invoice/" + doc.identifier;
    
    let r = new DLRequest ( url_str );    
    r.execute( (json:any, error:string) => {
        this.lock_doc_id.removeObject(doc.identifier);

        if (error != null) {
            AppHelper.showErrorMessage( null, MIOLocalizeString("ERROR", "ERROR"), error );
        }
        else {            
            let msg = MIOLocalizeString( "INVOICE SENT SUCCESFULLY", "INVOICE SENT SUCCESFULLY");
            AppHelper.showInfoMessage( null, MIOLocalizeString("INFO", "INFO"), msg );
        }

        if (completion) completion(json, error);
    });
   
}
WebService.prototype.duplicateInstallment = function( installment:PaymentInstallment, date:Date, completion:any ) {
    const url_str = this.apiURL + "/entity/" + this.scheme + "/PaymentInstallment/duplicate-installment/" + installment.identifier;
    
    let body = { "Date": date };

    let r = new DLRequest ( url_str, body, "POST" );
    r.execute( (json:any, error:string) => {
        
        if (error != null) {
            AppHelper.showErrorMessage( null, MIOLocalizeString("ERROR", "ERROR"), error );
        }
        else {            
            let msg = MIOLocalizeString( "INSTALLMENT CREATED SUCCESFULLY", "INSTALLMENT CREATED SUCCESFULLY");
            AppHelper.showInfoMessage( null, MIOLocalizeString("INFO", "INFO"), msg );
        }

        if (completion) completion(json, error);
    });
   
}

WebService.prototype.generateSEPAFileFromInstallments = function( installments:PaymentInstallment[], type:string, account:BankAccount, dueDate:string, filename:string, completion:any ) {
    
    let cmd = new WebServiceCommand(this.toolURL, "/accounting/schema/" + this.scheme + "/sepa/generate-from-installment-by-ids", true, null, filename);
    cmd.method = "POST";

    cmd.params = {
        "InstallmentIDs": installments.map ( (item:PaymentInstallment) => { return item.identifier; }),
        "DueDate": dueDate,
        "BankAccountID" : account.identifier,
        "SEPAType": type
    }

    cmd.execute(this, function (code:number, data:any) {
        //TODO: Show error
        if (code != 200 ) {
            AppHelper.showErrorMessage(null, "ERROR", "COULDN'T DOWNLOAD PDF. PLEASE CHECK IF YOU HAVE AN ACTIVE TEMPLATE");
        }
    });   
   
}