//
// Generated class ProductModifierPlace
//

/// <reference path="ProductModifierPlace_ManagedObject.ts" />

class ProductModifierPlace extends ProductModifierPlace_ManagedObject
{

}
