/**
 * Created by miguel on 31/1/17.
 */
/*function roundToTwo(num) {    
    return +(Math.round(num + "e+2")  + "e-2");
}*/

function isInt(value) {
  return !isNaN(value) && 
         Number(value) == value && 
         !isNaN(parseInt(value, 10));
}

enum MeasureType 
{
    Unit,
    Mass,
    Volume
}

enum MeasureUnitType 
{
    Percentage = -100,
    Container = -1,
    InputFormat = -2,
    None = 0,
    Unit = 1,
    
    MassKilogram = 2,      
    MassGram = 3,
    MassMilligram = 10,
    
    VolumeLitre = 4,            // Galon        1 0004      1 0010
    VolumeCentilitre = 5,
    VolumeMilliliter = 9,
    
    LengthMillimeter = 11,
    LengthMeter = 6,
    LengthCentimeter = 7,
    LengthInch = 8    
}

/*
stuct conversion_unit_type {
    factor:
    factor_unit_type:
}

stuct unit_type {
    quantity: number 
    measure: KG
    conversions: [conversion_unit_type]
}

struct container_units : unit_type {
    containerName:string,        
    container_unit_type: unit_type // 75 cl

    quantity = 24
    measure = Container    
}
*/

class MeasureUnits {

    public static isMeasureUnit(measureUnit)
    {
        if(!isInt(measureUnit)) return false;

        switch(parseInt(measureUnit)){
            case MeasureUnitType.Unit:
            case MeasureUnitType.MassKilogram:
            case MeasureUnitType.MassGram:
            case MeasureUnitType.VolumeLitre:
            case MeasureUnitType.VolumeCentilitre:
            case MeasureUnitType.Container:
                return true;
            default:
                return false;    
        }
    }

    public static measureType(measureUnitType:MeasureUnitType) : MeasureType
    {
        switch(measureUnitType){
            case MeasureUnitType.Unit:
                return MeasureType.Unit;

            case MeasureUnitType.MassGram:
            case MeasureUnitType.MassKilogram:
                return MeasureType.Mass;

            case MeasureUnitType.VolumeCentilitre:
            case MeasureUnitType.VolumeLitre:
                return MeasureType.Volume;            
        }

        // Default case
        return MeasureType.Unit;
    }

    public static measureUnitsFromType(measureType:MeasureType) : MeasureUnitType[]{

        switch (measureType){
            case MeasureType.Unit:
                return [MeasureUnitType.Unit];

            case MeasureType.Mass:
                return [MeasureUnitType.MassKilogram, MeasureUnitType.MassGram];

            case MeasureType.Volume:
                return [MeasureUnitType.VolumeLitre, MeasureUnitType.VolumeCentilitre, MeasureUnitType.VolumeMilliliter];
        }

        // Default case
        return [MeasureUnitType.Unit];
    }

    static measureUnitsFromUnitType(unitType:MeasureUnitType){

        // HACK: Sometimes we get and string.
        if (typeof unitType === "string") unitType = parseInt(unitType);

        let array = [];

        switch (unitType){
            case MeasureUnitType.Unit:
                array.addObject(MeasureUnitType.Unit);
                break;

            case MeasureUnitType.MassGram:
            case MeasureUnitType.MassKilogram:
                array.addObject(MeasureUnitType.MassKilogram);
                array.addObject(MeasureUnitType.MassGram);
                break;

            case MeasureUnitType.VolumeCentilitre:
            case MeasureUnitType.VolumeLitre:
            case MeasureUnitType.VolumeMilliliter:
                array.addObject(MeasureUnitType.VolumeLitre);
                array.addObject(MeasureUnitType.VolumeCentilitre);
                array.addObject(MeasureUnitType.VolumeMilliliter);
                break;

            default:
                array.addObject(MeasureUnitType.Unit);
                break;
        }        
        
        return  array;
    }

    public static toString(id){
        
        switch (parseInt(id)) {
            case MeasureUnitType.Percentage: return '%';     //MeasureUnitTypePercentage
            case MeasureUnitType.Container: return MIOLocalizeString("CONT", "Cont");  //MeasureUnitTypeContainer
            case MeasureUnitType.None: return '';      //kMeasureUnitTypeNone
            case MeasureUnitType.Unit: return 'U';     //MeasureUnitTypeUni
            case MeasureUnitType.MassKilogram: return 'Kg';    //MeasureUnitTypeMassKilogram
            case MeasureUnitType.MassGram: return 'gr';    //MeasureUnitTypeMassGram
            case MeasureUnitType.VolumeLitre: return 'L';     //MeasureUnitTypeVolumeLitre
            case MeasureUnitType.VolumeCentilitre: return 'cl';    //MeasureUnitTypeVolumeCentilitre
            case MeasureUnitType.VolumeMilliliter: return 'ml';    
            case MeasureUnitType.LengthMeter: return 'm';    
            case MeasureUnitType.LengthCentimeter: return 'cm';
            case MeasureUnitType.LengthInch: return 'in';
    
            default: return '';
        }
    }

    public static description(id){
        switch (parseInt(id)){
            case MeasureUnitType.Percentage:
                return 'Porcentaje';
            case MeasureUnitType.Container:
                return 'Contenedores';
            case MeasureUnitType.None:
                return '';
            case MeasureUnitType.Unit:
                return 'Unidades';
            case MeasureUnitType.MassKilogram:
                return 'Kilogramos';
            case MeasureUnitType.MassGram:
                return 'Gramos';
            case MeasureUnitType.VolumeLitre:
                return 'Litros';
            case MeasureUnitType.VolumeCentilitre:
                return 'Centílitros';
            case MeasureUnitType.VolumeMilliliter:
                return 'Milimetros';
    
            default:
                return '';
        }
    }

    static measureUnitTypeString(type:MeasureUnitType) {
        switch (type) {
        case MeasureUnitType.Percentage: return '%';
        case MeasureUnitType.Container: return MIOLocalizeString("CONT", "Cont");
        case MeasureUnitType.None: return '';
        case MeasureUnitType.Unit: return 'U';
        case MeasureUnitType.MassKilogram: return 'Kg';
        case MeasureUnitType.MassGram: return 'g';
        case MeasureUnitType.VolumeLitre: return 'L';
        case MeasureUnitType.VolumeCentilitre: return 'cl';
        case MeasureUnitType.VolumeMilliliter: return 'ml';    
        case MeasureUnitType.LengthMeter: return 'm';    
        case MeasureUnitType.LengthCentimeter: return 'cm';
        case MeasureUnitType.LengthInch: return 'in';

        default: return '';
        }
    }

    static measureUnitFromString(value:any) {
        if (value == null) return MeasureUnitType.None;
        let v = (typeof value === "string") ? (value as string).toLowerCase() : value;

        switch( value.toLowerCase() ){
            case "ml":
            case "mililiter":
            case "9":
            case 9:
            return MeasureUnitType.VolumeMilliliter;

            case "cl":
            case "centiliter":
            case "5":
            case 5:
            return MeasureUnitType.VolumeCentilitre;

            case "l":
            case "liter":
            case "4":
            case 4:
            return MeasureUnitType.VolumeLitre;

            case "g":
            case "grams":
            case "3":
            case 3:
            return MeasureUnitType.MassGram;    

            case "kg":
            case "kilograms":
            case "2":
            case 2:
            return MeasureUnitType.MassKilogram;    

            case "cont":
            case "container":
            case "-1":
            case -1:
            return MeasureUnitType.Container; 
            
            default: return MeasureUnitType.Unit;
        }
    }

    static convertToMinimumUnits(quantity:number, meassureUnit:MeasureUnitType, productContainerMeasureUnit:MeasureUnitType, productContainerQuantity:number) {
        if (meassureUnit == 1) return [quantity, meassureUnit];
        
        let mu = meassureUnit == -1 ? productContainerMeasureUnit : meassureUnit;
        let pq = (meassureUnit == -1 && productContainerQuantity > 0) ? productContainerQuantity : 1;
        if (mu == MeasureUnitType.MassKilogram || mu == MeasureUnitType.MassGram) {
            let factor = MeasureUnits.meassureFactor(mu, MeasureUnitType.MassGram);
            return [ quantity * pq * factor, MeasureUnitType.MassGram ];
        }
        if (   mu == MeasureUnitType.VolumeCentilitre 
            || mu == MeasureUnitType.VolumeLitre
            || mu == MeasureUnitType.VolumeMilliliter) {
            let factor = MeasureUnits.meassureFactor(mu, MeasureUnitType.VolumeCentilitre);
            return [ quantity * pq * factor, MeasureUnitType.VolumeCentilitre ];
            }

        return [0, MeasureUnitType.None];
    }

    static meassureFactor(fromMeassureUnit:MeasureUnitType, toMeassureUnit:MeasureUnitType){
        if (toMeassureUnit == null) return 1;
        if (toMeassureUnit == 0) return 1;
        if (fromMeassureUnit == toMeassureUnit) return 1;
        if (fromMeassureUnit == MeasureUnitType.MassKilogram && toMeassureUnit == MeasureUnitType.MassGram) return 1000;
        if (fromMeassureUnit == MeasureUnitType.MassGram && toMeassureUnit == MeasureUnitType.MassKilogram) return 0.001;
        if (fromMeassureUnit == MeasureUnitType.VolumeLitre && toMeassureUnit == MeasureUnitType.VolumeCentilitre) return 100;
        if (fromMeassureUnit == MeasureUnitType.VolumeCentilitre && toMeassureUnit == MeasureUnitType.VolumeLitre) return 0.01;
        if (fromMeassureUnit == MeasureUnitType.VolumeLitre && toMeassureUnit == MeasureUnitType.VolumeMilliliter) return 1000;
        if (fromMeassureUnit == MeasureUnitType.VolumeMilliliter && toMeassureUnit == MeasureUnitType.VolumeLitre) return 0.001;
        if (fromMeassureUnit == MeasureUnitType.VolumeCentilitre && toMeassureUnit == MeasureUnitType.VolumeMilliliter) return 10;
        if (fromMeassureUnit == MeasureUnitType.VolumeMilliliter && toMeassureUnit == MeasureUnitType.VolumeCentilitre) return 0.1;
        
        return 1;        
    }

    static minUnits(meassureUnit:MeasureUnitType){
        if (meassureUnit == null) return 0;
        switch (meassureUnit) {
            case MeasureUnitType.None: return MeasureUnitType.None;
            case MeasureUnitType.Unit: return MeasureUnitType.Unit;
            case MeasureUnitType.Container: return MeasureUnitType.Container;
            case MeasureUnitType.MassKilogram: return MeasureUnitType.MassGram;
            case MeasureUnitType.MassGram: return MeasureUnitType.MassGram;
            case MeasureUnitType.VolumeLitre: return MeasureUnitType.VolumeCentilitre;
            case MeasureUnitType.VolumeCentilitre: return MeasureUnitType.VolumeCentilitre;
            case MeasureUnitType.VolumeMilliliter: return MeasureUnitType.VolumeCentilitre;
        }

        return MeasureUnitType.None;
    }


}

