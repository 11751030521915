

/// <reference path="PrintProfileConfiguration.ts" />

// Generated class ZonePrintProfileConfiguration_ManagedObject

class ZonePrintProfileConfiguration_ManagedObject extends PrintProfileConfiguration
{

    // Property: locationCategoryName
    set locationCategoryName(value:string) {
        this.setValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryName():string {
        return this.valueForKey('locationCategoryName');
    }
    set locationCategoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationCategoryName');
    }
    // Relationship: locationCategory
    set locationCategory(value:LocationCategory) {
        this.setValueForKey(value, 'locationCategory');
    }
    get locationCategory():LocationCategory {
        return this.valueForKey('locationCategory') as LocationCategory;
    }
}
