//
// Generated class UserReportOption
//

/// <reference path="UserReportOption_ManagedObject.ts" />

class UserReportOption extends UserReportOption_ManagedObject
{

}
