//
// Generated class PayMethod
//

/// <reference path="PayMethod_ManagedObject.ts" />

enum PayMethodType
{
    DualLink      = -1, // Special by Dual Link
    Custom        = 0, // Create by user
    Cash          = 1, 
    Card          = 2, 
    LapsoPay      = 3, 
    PayThunder    = 4, 
    Redsys        = 5, 
    Bytefactory   = 6, 
    SumUp         = 7, 
    Hotel         = 8, 
    CashMachine   = 9, 
    WaiterApp     = 10,
    RedsysOnline  = 11, // RedSys online
    PaypalOnline  = 12, // Paypal online
    StripeOnline  = 13, // Stripe online
    AdyenTerminal = 14, // Adyen Terminal (tested with terminal v400m)
    CGP           = 15, // Comercia Global Payments
    BankTransfer  = 16, 
    SEPA          = 17, // SEPA 19.14 / 19.41
    AdyenOnline   = 18 // For web only    
}

class PayMethod extends PayMethod_ManagedObject
{
    static typeString( type:number ) {
        switch ( type ) {
            case PayMethodType.DualLink        : return MIOLocalizeString("DUAL LINK", "DUAL LINK"); break;
            case PayMethodType.Custom          : return MIOLocalizeString("Custom", "Custom"); break;
            case PayMethodType.Cash            : return MIOLocalizeString("CASH", "CASH"); break;
            case PayMethodType.Card            : return MIOLocalizeString("CARD", "CARD"); break;
            case PayMethodType.LapsoPay        : return MIOLocalizeString("LAPSO PAY", "LAPSO PAY"); break;
            case PayMethodType.PayThunder      : return MIOLocalizeString("PAY THUNDER", "PAY THUNDER"); break;
            case PayMethodType.Redsys          : return MIOLocalizeString("REDSYS", "REDSYS"); break;
            case PayMethodType.Bytefactory     : return MIOLocalizeString("BYTE FACTORY", "BYTE FACTORY"); break;
            case PayMethodType.SumUp           : return MIOLocalizeString("SUM UP", "SUM UP"); break;
            case PayMethodType.Hotel           : return MIOLocalizeString("HOTEL", "HOTEL"); break;
            case PayMethodType.CashMachine     : return MIOLocalizeString("CASH MACHINE", "CASH MACHINE"); break;
            case PayMethodType.WaiterApp       : return MIOLocalizeString("WAITER APP", "WAITER APP"); break;
            case PayMethodType.RedsysOnline    : return MIOLocalizeString("REDSYS ONLINE", "REDSYS ONLINE"); break;
            case PayMethodType.PaypalOnline    : return MIOLocalizeString("PAYPAL ONLINE", "PAYPAL ONLINE"); break;
            case PayMethodType.StripeOnline    : return MIOLocalizeString("STRIPE ONLINE", "STRIPE ONLINE"); break;
            case PayMethodType.AdyenTerminal   : return MIOLocalizeString("ADYGEN PINPAD", "ADYGEN PINPAD"); break;
            case PayMethodType.CGP             : return MIOLocalizeString("COMERCIA GLOBAL PAYMENTS", "COMERCIA GLOBAL PAYMENTS"); break;
            case PayMethodType.BankTransfer    : return MIOLocalizeString("BANK TRANSFER", "BANK TRANSFER"); break;
            case PayMethodType.SEPA            : return MIOLocalizeString("SEPA", "SEPA"); break;
            case PayMethodType.AdyenOnline     : return MIOLocalizeString("ADYEN ONLINE", "ADYEN ONLINE"); break;
        }
    }

    static supportedTypes() {
        return [ 
            PayMethodType.Custom,
            PayMethodType.Cash,
            PayMethodType.Card,            
            PayMethodType.Hotel,
            PayMethodType.CashMachine,
            PayMethodType.WaiterApp,
            PayMethodType.Redsys,
            PayMethodType.RedsysOnline,
            PayMethodType.AdyenTerminal,
            PayMethodType.BankTransfer,
            PayMethodType.SEPA
        ];
    }

    typeString(){ return PayMethod.typeString(this.type); }

    subtypeStringWithValue(subType:string):string{
        if (this.type != PayMethodType.Redsys) return subType;

        // Redsys conversion
        let value = null;
        switch (subType){
            case "1": value = "VISA"; break;
            case "2": value = "MASTERCARD"; break;
            case "8": value = "AMEX"; break;

            default:
                value = "Unkown (" + subType + ")";
                break;
        }

        return value;
    }

    
}
