//
// Generated class DBMappingEntity
//

/// <reference path="DBMappingEntity_ManagedObject.ts" />

class DBMappingEntity extends DBMappingEntity_ManagedObject
{
    
}
