//
// Generated class LocalizedName
//

/// <reference path="LocalizedName_ManagedObject.ts" />

class LocalizedName extends LocalizedName_ManagedObject
{

}
