

class BookingsZonesDaySetupViewController extends MUIViewController
{
    private saveButton:MUIButton = null;
    private dayLabel:MUILabel = null;
    private calendarButton:MUIButton = null;
    private massButton:MUIButton = null;
    private tableView:MUITableView = null;
    private rulesTableView:MUITableView = null;

    private addRuleButton:MUIButton = null;
    private locTypeDataSource:BookingZoneDayLocationTypeDataSource = null;

    private dayString:string = null;
    private sdf:MIODateFormatter = MUIWebApplication.sharedInstance().delegate.serverDateFormatter;
    private ldf:MIODateFormatter = MUIWebApplication.sharedInstance().delegate.longDateFormatter;

    viewDidLoad(){
        super.viewDidLoad();

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(control){
            let moc:MIOManagedObjectContext = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
            moc.save();
        });

        this.dayLabel = MUIOutlet(this, "day-lbl", "MUILabel");

        this.calendarButton = MUIOutlet(this, "calendar-btn", "MUIButton");
        this.calendarButton.setAction(this, function(){
            let dp = new MUIDatePickerController();
            dp.init();        
            dp.delegate = this;
            dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

            let pc = dp.popoverPresentationController;
            pc.sourceRect = this.calendarButton.frame;
            pc.sourceView = this.calendarButton;

            this.presentViewController(dp, true);
        });

        this.massButton = MUIOutlet(this, "mass-btn", "MUIButton");
        this.massButton.setAction(this, function(){

            let vc = new SetZoneDaysSetupViewController("set-zone-days-setup-view");
            vc.initWithResource("layout/bookings/zones/SetZoneDaysSetupView.html");
            vc.delegate = this;

            this.presentViewController(vc, true);
        });
            
        this.tableView = MUIOutlet(this,"tableview", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        let today = MIODateToday();
        this.dayString = this.sdf.stringFromDate(today);

        this.dayLabel.text = this.ldf.stringFromDate(today);

        this.rulesTableView = MUIOutlet(this, "rules-table-view", "MUITableView");
        this.locTypeDataSource = new BookingZoneDayLocationTypeDataSource();
        this.locTypeDataSource.init();
        this.locTypeDataSource.tableView = this.rulesTableView;
        
        this.rulesTableView.dataSource = this.locTypeDataSource;
        this.rulesTableView.delegate = this.locTypeDataSource;

        this.addRuleButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addRuleButton.setAction(this, function(){
            this.addLocationType();
        });

        this.reloadZones();
    }

    numberOfSections(tableview){
        return this.zonesFetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.zonesFetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){

        let zone:BookingZone = this.zonesFetchedResultsController.objectAtIndexPath(indexPath);

        let cell = null;
        if (zone.parent == null){
            cell = tableview.dequeueReusableCellWithIdentifier('ZoneCell') as BookingsZoneDaySetupCell;            
            cell.zone = zone;
            cell.selectionStyle = MUITableViewCellSelectionStyle.None;
        }
        else {
            cell = tableview.dequeueReusableCellWithIdentifier('SubZoneCell') as BookingsZoneDaySetupSubCell;                    
            let dayZone = this.dayZones[zone.identifier]
            cell.setItems(zone, dayZone, this.dayString);

            cell.selected = this.selectedZone == zone ? true : false;
        }                                

        return cell;
    }

    private selectedZone:BookingZone = null;
    private selectedDayZone:RelationBookingDay = null;
    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        let zone:BookingZone = this.zonesFetchedResultsController.objectAtIndexPath(indexPath);
        let dayZone = this.dayZones[zone.identifier];
        
        this.selectedZone = zone;
        this.selectedDayZone = dayZone;
        
        this.locTypeDataSource.relationZoneDay = dayZone;
    }
    
    private reloadZones(){        
        this.selectedZone = null;
        this.locTypeDataSource.relationZoneDay = null;
        let zones = this.zonesFetchedResultsController.resultObjects;
        if (zones.length > 0){
            this.dayZonesFetchedResultsController = null;            
            let dayZones = this.dayZonesFetchedResultsController.resultObjects;
            this.mapDayZones(dayZones);
            this.tableView.reloadData();
        }
    }

    private _zonesFetchedResultsController:MIOFetchedResultsController = null;
    set zonesFetchedResultsController(value){
        if (value == null && this._zonesFetchedResultsController != null)
            this._zonesFetchedResultsController.delegate = null;
    
        this._zonesFetchedResultsController = value;
    }

    get zonesFetchedResultsController(){
        if (this._zonesFetchedResultsController != null)
            return this._zonesFetchedResultsController;
    
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('indexPathString', true)];        
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("BookingZone", sortDescriptors, null);
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._zonesFetchedResultsController = fetchedResultsController;
    
        return this._zonesFetchedResultsController;
    }
    
    private _dayZonesFetchedResultsController:MIOFetchedResultsController = null;
    set dayZonesFetchedResultsController(value){
        if (value == null && this._dayZonesFetchedResultsController != null)
            this._dayZonesFetchedResultsController.delegate = null;
    
        this._dayZonesFetchedResultsController = value;
    }

    get dayZonesFetchedResultsController(){
        if (this._dayZonesFetchedResultsController != null){
            return this._dayZonesFetchedResultsController;
        }        

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        
        let predicate = "day == '" + this.dayString + "'";
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("RelationBookingDay", null, predicate);
        fetchRequest.relationshipKeyPathsForPrefetching = ["locationTypes.priceRules"];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._dayZonesFetchedResultsController = fetchedResultsController;

        return this._dayZonesFetchedResultsController;
    }

    controllerDidChangeContent(controller){

        if (controller == this.zonesFetchedResultsController){
            this.dayZonesFetchedResultsController = null;   
            let zones = this.dayZonesFetchedResultsController.resultObjects;
            this.tableView.reloadData();            
        }
        else if (controller == this.dayZonesFetchedResultsController){
            this.mapDayZones(controller.resultObjects);
            this.tableView.reloadData();        
        }        
    }

    private dayZones = {}
    private mapDayZones(dayZones){
        this.dayZones = {};
        for (let index = 0; index < dayZones.length; index++){
            let dz:RelationBookingDay = dayZones[index];
            this.dayZones[dz.zoneID] = dz;
        }
    }

    didSelectDate(datePickerController, date){
        this.dayLabel.text = this.ldf.stringFromDate(date);
        this.dayString = this.sdf.stringFromDate(date);
        this.reloadZones();
    }

    setZonesBetweenDates(controller:SetZoneDaysSetupViewController, beginDate:Date, endDate:Date, weekdays){
        this.copyZones(beginDate, endDate, weekdays);
        //this.getLocationTypes(beginDate, endDate, weekdays);
    }

/*    private getLocationTypes(beginDate:Date, endDate:Date, weekdays){

        let dayZones = this.dayZonesFetchedResultsController.fetchedObjects;

        let filters = [];
        for (let index = 0; index < dayZones.length; index++){
            let dz = dayZones[index] as RelationBookingDay;
            filters.addObject("relationDay.identifier == '" + dz.identifier + "'");
        }

        let predicateFormat = filters.join(" OR ");
        
        let request = DBHelper.listFetchRequestWithEntityName("BookingLocationType", null, predicateFormat);
        request.relationshipKeyPathsForPrefetching = ["priceRules"];

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        let wps:MWSPersistentStore = ad.webPersistentStore;
        wps.fetchObjects(request, ad.managedObjectContext, this, function(objects){
            // We have the day zones. Get the LocationType
            this.copyZones(objects, beginDate, endDate, weekdays);
        });

    }*/

    private copyZones(beginDate:Date, endDate:Date, weekdays){
        let currentDate = new Date(beginDate.getTime());

        while (currentDate < endDate){
            currentDate.setDate(currentDate.getDate() + 1);
            
            if (this.canApplyToDate(currentDate, weekdays) == false) continue;

            let dayString = this.sdf.stringFromDate(currentDate);
            if (dayString == this.dayString) continue;
            this.copyZonesToDate(dayString);
        }
    }

    private canApplyToDate(date:Date, weekdays){
        let day = date.getDay();

        switch (day) {
            case 0:
                return ((weekdays & WeekDays.Sunday) > 0);

            case 1:
                return ((weekdays & WeekDays.Monday) > 0);

            case 2:
                return ((weekdays & WeekDays.Tuesdays) > 0);

            case 3:
                return ((weekdays & WeekDays.Wednesday) > 0);

            case 4:
                return ((weekdays & WeekDays.Thursday) > 0);

            case 5:
                return ((weekdays & WeekDays.Friday) > 0);

            case 6:
                return ((weekdays & WeekDays.Saturday) > 0);
                
        }

        return false;
    }

    private copyZonesToDate(dayString){        

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        let wps:MWSPersistentStore = ad.webPersistentStore;

        let currentDayZones = this.dayZonesFetchedResultsController.fetchedObjects;

        let request = MIOFetchRequest.fetchRequestWithEntityName("RelationBookingDay");
        request.predicate = MIOPredicate.predicateWithFormat("day == '" + dayString + "'");
        wps.fetchObjects(request, ad.managedObjectContext, this, function(objects){
            this.addOrUpdateZones(currentDayZones, objects, dayString);
        });
    }

    private addOrUpdateZones(fromDayZones, dayZones, dayString){

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        // remove all zones
        for (let index = 0; index < dayZones.length; index++){
            let dz = dayZones[index] as RelationBookingDay;
            ad.managedObjectContext.deleteObject(dz);
        }
        ad.managedObjectContext.save();

        for (let index = 0; index < fromDayZones.length; index++){
            let z = fromDayZones[index] as RelationBookingDay;

            let nz = MIOEntityDescription.insertNewObjectForEntityForName("RelationBookingDay", ad.managedObjectContext) as RelationBookingDay;
            nz.day = dayString;
            nz.zoneID = z.zoneID;
            nz.price = z.price;
            nz.chargePrice = z.chargePrice;
            nz.maxPax = z.maxPax; 
            
            for (let typeIndex = 0; typeIndex < z.locationTypes.count; typeIndex++){
                let lt = z.locationTypes.objectAtIndex(typeIndex) as BookingLocationType;
                
                let nlt = MIOEntityDescription.insertNewObjectForEntityForName("BookingLocationType", ad.managedObjectContext) as BookingLocationType;
                nlt.title = lt.title;
                nlt.minpax = lt.minpax;
                nlt.maxpax = lt.maxpax;
                nlt.typelimit = lt.typelimit;
                //nlt.current = lt.current;
                nlt.priceType = lt.priceType;
                nlt.price = lt.price;
                nlt.relationDay = nz;
                nz.addLocationTypesObject(nlt);

                ad.managedObjectContext.save();
                
                for (let ruleIndex = 0; ruleIndex < lt.priceRules.count; ruleIndex++){
                    let rule = lt.priceRules.objectAtIndex(ruleIndex) as BookingLocationPriceRule;

                    let newRule = MIOEntityDescription.insertNewObjectForEntityForName("BookingLocationPriceRule", ad.managedObjectContext) as BookingLocationPriceRule;
                    newRule.minpax = rule.minpax;
                    newRule.maxpax = rule.maxpax;
                    newRule.priceType = rule.priceType;
                    newRule.price = rule.price;
                    newRule.locationType = nlt;                    

                    nlt.addPriceRulesObject(newRule);
                }
                
            }
        }

        ad.managedObjectContext.save();        
    }

    private addLocationType(){

        if (this.selectedZone != null) {
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            let locType = MIOEntityDescription.insertNewObjectForEntityForName("BookingLocationType", ad.managedObjectContext) as BookingLocationType;
            if (this.selectedDayZone == null) {
                this.selectedDayZone = MIOEntityDescription.insertNewObjectForEntityForName("RelationBookingDay", ad.managedObjectContext) as RelationBookingDay;
                this.selectedDayZone.zoneID = this.selectedZone.identifier;
                this.selectedDayZone.day = this.dayString;
                // this.selectedDayZone.price = this.selectedZone.price;
                // this.selectedDayZone.chargePrice = this.selectedZone.chargePrice;
                // this.selectedDayZone.maxPax = this.selectedZone.maxPax;                    
            }
            locType.relationDay = this.selectedDayZone;  
            this.selectedDayZone.addLocationTypesObject(locType);
            ad.managedObjectContext.save();

            // Force to reload the rule tableview
            this.locTypeDataSource.relationZoneDay = this.selectedDayZone;
        }
    }
}