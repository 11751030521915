

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class CashDeskCurrency_ManagedObject

class CashDeskCurrency_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: currencyID
    set currencyID(value:string) {
        this.setValueForKey(value, 'currencyID');
    }
    get currencyID():string {
        return this.valueForKey('currencyID');
    }
    set currencyIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'currencyID');
    }
    get currencyIDPrimitiveValue():string {
        return this.primitiveValueForKey('currencyID');
    }

    // Property: defaultFloat
    set defaultFloat(value:number) {
        this.setValueForKey(value, 'defaultFloat');
    }
    get defaultFloat():number {
        return this.valueForKey('defaultFloat');
    }
    set defaultFloatPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'defaultFloat');
    }
    get defaultFloatPrimitiveValue():number {
        return this.primitiveValueForKey('defaultFloat');
    }

    // Property: nextSessionMoney
    set nextSessionMoney(value:number) {
        this.setValueForKey(value, 'nextSessionMoney');
    }
    get nextSessionMoney():number {
        return this.valueForKey('nextSessionMoney');
    }
    set nextSessionMoneyPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'nextSessionMoney');
    }
    get nextSessionMoneyPrimitiveValue():number {
        return this.primitiveValueForKey('nextSessionMoney');
    }
    // Relationship: cashDesk
    set cashDesk(value:CashDesk) {
        this.setValueForKey(value, 'cashDesk');
    }
    get cashDesk():CashDesk {
        return this.valueForKey('cashDesk') as CashDesk;
    }
}
