//
// Generated class CustomLine
//

/// <reference path="CustomLine_ManagedObject.ts" />

class CustomLine extends CustomLine_ManagedObject
{

}
