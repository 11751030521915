

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class InvitationLine_ManagedObject

class InvitationLine_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: sessionDate
    set sessionDate(value:string) {
        this.setValueForKey(value, 'sessionDate');
    }
    get sessionDate():string {
        return this.valueForKey('sessionDate');
    }
    set sessionDatePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sessionDate');
    }
    get sessionDatePrimitiveValue():string {
        return this.primitiveValueForKey('sessionDate');
    }

    // Property: sessionTime
    set sessionTime(value:string) {
        this.setValueForKey(value, 'sessionTime');
    }
    get sessionTime():string {
        return this.valueForKey('sessionTime');
    }
    set sessionTimePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sessionTime');
    }
    get sessionTimePrimitiveValue():string {
        return this.primitiveValueForKey('sessionTime');
    }

    // Property: todayMaxValue
    set todayMaxValue(value:number) {
        this.setValueForKey(value, 'todayMaxValue');
    }
    get todayMaxValue():number {
        return this.valueForKey('todayMaxValue');
    }
    set todayMaxValuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'todayMaxValue');
    }
    get todayMaxValuePrimitiveValue():number {
        return this.primitiveValueForKey('todayMaxValue');
    }

    // Property: value
    set value(value:number) {
        this.setValueForKey(value, 'value');
    }
    get value():number {
        return this.valueForKey('value');
    }
    set valuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'value');
    }
    get valuePrimitiveValue():number {
        return this.primitiveValueForKey('value');
    }
    // Relationship: economicEntity
    set economicEntity(value:EconomicEntity) {
        this.setValueForKey(value, 'economicEntity');
    }
    get economicEntity():EconomicEntity {
        return this.valueForKey('economicEntity') as EconomicEntity;
    }
    // Relationship: event
    set event(value:VenueEvent) {
        this.setValueForKey(value, 'event');
    }
    get event():VenueEvent {
        return this.valueForKey('event') as VenueEvent;
    }
}
