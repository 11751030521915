
class DBSyncTransactionCell extends UITableViewCell
{
    delegate = null;

    private dateLabel:MUILabel = null;
    private referenceLabel:MUILabel = null;
    private conceptLabel:MUILabel = null;
    private statusLabel:MUILabel = null;
    private syncButton:MUIButton = null;    

    awakeFromHTML(){
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");
        this.referenceLabel = MUIOutlet(this, "ref-lbl", "MUILabel");
        this.conceptLabel = MUIOutlet(this, "concept-lbl", "MUILabel");
        this.statusLabel = MUIOutlet(this, "status-lbl", "MUILabel");
        
        // this.syncButton = MUIOutlet(this, "sync-btn", "MUIButton");
        // this.syncButton.setAction(this, function(){
        //     this.sync();
        // });
    }

    private _item:DBSyncTransaction = null;
    set item(item:DBSyncTransaction){              
        this._item = item;

        // let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        // this.dateLabel.text = ad.dateFormatter.stringFromDate(item.date);        
        // this.referenceLabel.text = null;
        this.conceptLabel.text = item.concept;
        
        // if (item.transactionID == null) {
        //     this.statusLabel.text = null;            
        //     this.syncButton.hidden = false;
        //     MUICoreLayerRemoveStyle(this.dateLabel.layer, "active");
        //     MUICoreLayerRemoveStyle(this.conceptLabel.layer, "active");
        //     MUICoreLayerRemoveStyle(this.statusLabel.layer, "active");            

        // }
        // else {            
        //     this.statusLabel.text = MIOLocalizeString("ADDED TO TRANSACTION", "ADDED TO TRANSACTION");
        //     this.syncButton.hidden = true;
        //     MUICoreLayerAddStyle(this.dateLabel.layer, "active");
        //     MUICoreLayerAddStyle(this.conceptLabel.layer, "active");
        //     MUICoreLayerAddStyle(this.statusLabel.layer, "active");
        // }        
        
        // this.statusLabel.text = item.statusString();
        // this.errorLabel.text = item.error;
        
        // if (item.status == IntegratorsSyncStatus.NoSync && item.error == null) {
        //     this.infoLabel.text = null;
        //     this.infoLabel.layer.title = null;
        //     this.syncButton.hidden = false;
        //     this.syncDateLabel.text = null;
        // }
        // else if (item.status == IntegratorsSyncStatus.NoSync && item.error != null) {
        //     if (item.data != null) {
        //         let txt = JSON.stringify(item.data);
        //         this.infoLabel.text = "more info";
        //         this.infoLabel.layer.title = txt;            
        //     }
        //     else {
        //         this.infoLabel.text = null;
        //         this.infoLabel.layer.title = null;    
        //     }
        //     this.syncButton.hidden = false;
        //     let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;            
        //     this.syncDateLabel.text = item.date != null ? ad.dateTimeFormatter.stringFromDate(item.date) : null;
        // }
        // else {
        //     this.infoLabel.text = item.data;
        //     this.infoLabel.layer.title = null;
        //     this.syncButton.hidden = true;
        //     let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;            
        //     this.syncDateLabel.text = item.date != null ? ad.dateTimeFormatter.stringFromDate(item.date) : null;
        // }      

        // if (item.error != null) {
        //     MUICoreLayerAddStyle(this.idLabel.layer, "alert");
        //     MUICoreLayerAddStyle(this.conceptLabel.layer, "alert");
        //     MUICoreLayerAddStyle(this.statusLabel.layer, "alert");
        //     MUICoreLayerAddStyle(this.errorLabel.layer, "alert");
        //     MUICoreLayerAddStyle(this.infoLabel.layer, "alert");
        //     MUICoreLayerAddStyle(this.syncDateLabel.layer, "alert");
        // }
        // else {
        //     MUICoreLayerRemoveStyle(this.idLabel.layer, "alert");
        //     MUICoreLayerRemoveStyle(this.conceptLabel.layer, "alert");
        //     MUICoreLayerRemoveStyle(this.statusLabel.layer, "alert");
        //     MUICoreLayerRemoveStyle(this.errorLabel.layer, "alert");
        //     MUICoreLayerRemoveStyle(this.infoLabel.layer, "alert");
        //     MUICoreLayerRemoveStyle(this.syncDateLabel.layer, "alert");
        // }
    }

    private sync(){
        //this.delegate.syncItemFromCell(this._item, this);
    }

}
