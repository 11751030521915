

class BookingsDayCell extends UITableViewCell 
{ 
    private hourLabel:MUILabel = null;    
    private hourInLabel:MUILabel = null;    
    private nameLabel:MUILabel = null;    
    private phoneLabel:MUILabel = null;
    private emailLabel:MUILabel = null;
	private paxLabel:MUILabel = null;
	private attendedLabel:MUILabel = null;
    private zonesLabel:MUILabel = null;
    private payedLabel:MUILabel = null;
    private showButton:MUIButton = null;

    awakeFromHTML() {
        this.hourLabel = MUIOutlet(this, "hour-lbl", "MUILabel");
        this.hourInLabel = MUIOutlet(this, "hour-in-lbl", "MUILabel");
        this.nameLabel = MUIOutlet(this, 'name-lbl','MUILabel');
        this.phoneLabel = MUIOutlet(this, "phone-lbl", "MUILabel");
        this.emailLabel = MUIOutlet(this, "email-lbl", "MUILabel");
		this.paxLabel = MUIOutlet(this, 'pax-lbl','MUILabel');
		this.attendedLabel = MUIOutlet(this, 'attended-lbl', "MUILabel");
        this.zonesLabel = MUIOutlet(this, "zone-lbl", "MUILabel");
        this.payedLabel = MUIOutlet(this, "prepay-lbl", "MUILabel");
        this.showButton = MUIOutlet(this, "show-btn", "MUIButton");
        this.showButton.setAction(this, this.showAction);
    }

    private booking:Booking = null;
    set item(i:Booking) {
        this.booking = i;

        if (this.booking.status == BookingStatus.BookingConfirmed || this.booking.status == BookingStatus.BookingReserved) MUICoreLayerRemoveStyle(this.showButton.layer, "no-visible");
        else MUICoreLayerAddStyle(this.showButton.layer, "no-visible");

        let tf = MUIWebApplication.sharedInstance().delegate.timeFormatter as MIODateFormatter;

        this.hourLabel.text = i.hour != null ? i.hour.substr(0,5) : null;
        this.hourInLabel.text = i.dateShow != null ? tf.stringFromDate(i.dateShow).substr(0, 5) : null;

        if (i.externalReference?.length > 0){
            this.nameLabel.text = ( i.bookingName ?? i.clientName ) + " - " + i.externalReference;
        }
        else{
            this.nameLabel.text = (i.bookingName != null) ? i.bookingName : i.clientName;
        }
        
        let phone = i.client ? (i.client.mobilePhone ? i.client.mobilePhone.number : null) : null;
        let email = i.client ? i.client.email : null;

        this.phoneLabel.text = i.clientPhone ? i.clientPhone : phone;
        this.emailLabel.text = i.clientEmail ? i.clientEmail : email;

		this.paxLabel.text = i.pax.toString();
		this.attendedLabel.text = i.paxAttend?.toString();
        
        this.zonesLabel.text = i.bookingZoneName;
        for (let index = 0; index < this.booking.locations.length; index++){
            let bl = this.booking.locations.objectAtIndex(index) as Booking_BookingLocation;
            this.zonesLabel.text += " " + bl.locationCategoryName + " " + bl.locationName;
        }                

        
        let cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;
        
        this.payedLabel.text = i.deposit > 0 ? cf.stringFromNumber(i.deposit) : null;
                
        // if (i.relationZones.length < 2) MUICoreLayerRemoveStyle(this.multipleZoneView.layer, "multiple-zone-icon");
        // else MUICoreLayerAddStyle(this.multipleZoneView.layer, "multiple-zone-icon");                        

        let [r,g,b] = Booking.colorForStatus(i.status);
        this.nameLabel.setTextRGBColor(r, g, b);
        this.paxLabel.setTextRGBColor(r, g, b);            
    }

    private showAction(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle("Show", "Fill up the number of pax", MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.placeholderText = MIOLocalizeString("NUMBER OF PAX", "Number of PAX");
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Default, this, function(){
            let tf = avc.textFields[0] as MUITextField;
            this.setShowStatus(tf.text);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "Cancel"), MUIAlertActionStyle.Cancel, null, null));

        AppHelper.sharedInstance().presentViewController(avc, true);
    }   

    private setShowStatus(value:string){
        let pax = parseInt(value);
        if (pax == null) {
            AppHelper.showErrorMessage(null, "Error", "The value is invalid");
            return;
        }

        this.booking.status = BookingStatus.BookingShow;
        this.booking.paxAttend = pax;

        DBHelper.saveMainContext();
    }
    

}
