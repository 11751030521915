

/// <reference path="DBDocument.ts" />

// Generated class Invoice_ManagedObject

class Invoice_ManagedObject extends DBDocument
{

    // Property: paymentInstallmentTemplateName
    set paymentInstallmentTemplateName(value:string) {
        this.setValueForKey(value, 'paymentInstallmentTemplateName');
    }
    get paymentInstallmentTemplateName():string {
        return this.valueForKey('paymentInstallmentTemplateName');
    }
    set paymentInstallmentTemplateNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'paymentInstallmentTemplateName');
    }
    get paymentInstallmentTemplateNamePrimitiveValue():string {
        return this.primitiveValueForKey('paymentInstallmentTemplateName');
    }

    // Property: taxesAmount
    set taxesAmount(value:number) {
        this.setValueForKey(value, 'taxesAmount');
    }
    get taxesAmount():number {
        return this.valueForKey('taxesAmount');
    }
    set taxesAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'taxesAmount');
    }
    get taxesAmountPrimitiveValue():number {
        return this.primitiveValueForKey('taxesAmount');
    }

    // Property: vatAmount
    set vatAmount(value:number) {
        this.setValueForKey(value, 'vatAmount');
    }
    get vatAmount():number {
        return this.valueForKey('vatAmount');
    }
    set vatAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'vatAmount');
    }
    get vatAmountPrimitiveValue():number {
        return this.primitiveValueForKey('vatAmount');
    }

    // Relationship: paymentInstallments
    protected _paymentInstallments:MIOManagedObjectSet = null;
    get paymentInstallments():MIOManagedObjectSet {
        return this.valueForKey('paymentInstallments');
    }
    addPaymentInstallmentsObject(value:LegalEntityPaymentInstallment) {
        this._addObjectForKey(value, 'paymentInstallments');
    }
    removePaymentInstallmentsObject(value:LegalEntityPaymentInstallment) {
        this._removeObjectForKey(value, 'paymentInstallments');
    }
    // Relationship: paymentInstallmentTemplate
    set paymentInstallmentTemplate(value:PaymentInstallmentTemplate) {
        this.setValueForKey(value, 'paymentInstallmentTemplate');
    }
    get paymentInstallmentTemplate():PaymentInstallmentTemplate {
        return this.valueForKey('paymentInstallmentTemplate') as PaymentInstallmentTemplate;
    }
    // Relationship: payMethod
    set payMethod(value:PayMethod) {
        this.setValueForKey(value, 'payMethod');
    }
    get payMethod():PayMethod {
        return this.valueForKey('payMethod') as PayMethod;
    }

    // Relationship: taxes
    protected _taxes:MIOManagedObjectSet = null;
    get taxes():MIOManagedObjectSet {
        return this.valueForKey('taxes');
    }
    addTaxesObject(value:Tax) {
        this._addObjectForKey(value, 'taxes');
    }
    removeTaxesObject(value:Tax) {
        this._removeObjectForKey(value, 'taxes');
    }
}
