
class DBToolResultsViewController extends MUIViewController
{
    script = null;
    keysAndValues = null;

    private backButton:MUIButton = null;
//    this.activityIndicator.setHidden(true);                                
    //private outlineView:MUIOutlineView = null;
    private reportTableView:MUIReportTableView;
    private deleteButton:MUIButton = null;
    
    private filterController:ColumnFilterController = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.filterController = new ColumnFilterController();
        this.filterController.initWithDelegate(this);

        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");
        this.backButton.setAction(this, function(control){
            this.navigationController.popViewController(true);
        });

        this.reportTableView = MUIOutlet(this, "report-tableview", "MUIReportTableView");
        this.reportTableView.dataSource = this;
        this.reportTableView.delegate = this;

        this.deleteButton = MUIOutlet(this, "delete-btn", "MUIButton");
        this.deleteButton.setAction(this, function(){
            this.applyAction("delete");
        });
    }    

    viewDidAppear(animated?){
        super.viewDidAppear(animated);
        this.downloadData();
        //this.executeScript();
    }

    private items = [];
    private filterItems = [];
    private downloadData(){

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        let ws:WebService = ad.webService;

        if (this.script == null) return;

        //this.activityIndicator.setHidden(true);                                

        ws.executeScript(this.script, this.keysAndValues , this, function(code, result){

            if (code == 200 && result == true) {
                ws.downloadScriptResults(this.script, this, function(code2, objects){
                    if (code2 == 200) {
                        this.items = objects;
                        this.filterItems = objects;
                        this.reloadFilterItems();                        
                    }
                });
            }
            //this.activityIndicator.setHidden(true);                                
        });

    }

    numberOfRows(reportTableView: MUIReportTableView) {
        let count = this.filterItems.length;
        return count;
    }

    cellAtIndexPath(reportTableView: MUIReportTableView, column: MUIReportTableViewColumn, indexPath: MIOIndexPath) {

        if (indexPath.column == 0) {
            let cell = reportTableView.dequeueReusableCellWithIdentifier("SelectCell") as DBToolResultSelectCell;
            let item = this.filterItems[indexPath.row];
            cell.item = item;
            return cell;
        }
        else {
            let cell: DBToolResultCell = reportTableView.dequeueReusableCellWithIdentifier("ResultCell");
            let item = this.filterItems[indexPath.row];
            cell.value = item[column.title];
            return cell;
        }
    }

    private reloadFilterItems(){
        this.reportTableView.removeAllColumns();

        let item = this.filterItems[0];
        // Add columns        
        this.reportTableView.addColumn(MUIReportTableViewColumn.labelColumnWithTitle("", "30", 30, "center", ""));

        for (let key in item) {
            let col = this.reportTableView.addColumn(MUIReportTableViewColumn.labelColumnWithTitle(key, "200", 200, "left", key)) as MUIReportTableViewColumn;
            let colFilter = col.columnHeaderView() as ReportColumnHeader;
            colFilter.columnFilterTextField.filterController = this.filterController;
            colFilter.columnFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, key, null, null);
        }

        this.reportTableView.reloadData();
    }

    private applyAction(action){
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        let ws:WebService = ad.webService;

        if (this.script == null) return;
        
        // Review 
        let excludeIDs = false;
        let values = [];
        for (let index = 0; index < this.items.length; index++){
            let item = this.items[index];
            if (item["selected"] == false) {
                excludeIDs = true;
                continue;
            }

            values.push(item["id"]);
        }

        if (excludeIDs == false) values = null;

        ws.applyFunctionToScriptResults(this.script, action, values, this, function(code, result){
            if (code == 200) {
                let avc = new MUIAlertViewController();
                avc.initWithTitle('Scripts results message', 'The action was successful.', MUIAlertViewStyle.Default);
                avc.addAction(MUIAlertAction.alertActionWithTitle('OK', MUIAlertActionStyle.Default, null, null));
                
                this.presentViewController(avc, true);
            }
            else {
                let avc = new MUIAlertViewController();
                avc.initWithTitle('Scripts results message', 'Something was wrong.', MUIAlertViewStyle.Default);
                avc.addAction(MUIAlertAction.alertActionWithTitle('OK', MUIAlertActionStyle.Default, null, null));
                
                this.presentViewController(avc, true);
            }
        });
    }

    private executeScript(){

        //this.workersScript();

        // let ss = DLScriptingServer.sharedInstance();

        // ss.executeScript(this.script, this, function(objects){
        //     this.filterItems = objects;
        //     this.reloadFilterItems();                        
        // });
    }
/*
    private async workersScript(){

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let departments = await this.queryObjects("Deparments", null);
        MIOLog(departments);
    }

    private async queryObjects(entityName:string, predicateFormat:string){        

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        let wps:MWSPersistentStore = ad.webPersistentStore;
        let moc:MIOManagedObjectContext = ad.managedObjectContext;
        
        let request = MIOFetchRequest.fetchRequestWithEntityName(entityName);

        if (predicateFormat != null) request.predicate = MIOPredicate.predicateWithFormat(predicateFormat);

        // if (includeEntities != null)
        //     request.relationshipKeyPathsForPrefetching = includeEntities;

        wps.fetchObjects(request, moc, this, function(objects){
            return objects;
        });        
    }*/

    filterPredicateDidChange(controller:ColumnFilterController){
        let filterPredicateFormat = this.filterController.filterPredicateFormat();
        if (filterPredicateFormat != null) {
            this.filterItems = _MIOPredicateFilterObjects(this.items, MIOPredicate.predicateWithFormat(filterPredicateFormat));
        }
        else {
            this.filterItems = this.items;
        }
        this.reportTableView.reloadData();  
    }

}