

/// <reference path="RuleKit.ts" />

// Generated class TimeRangePreset_ManagedObject

class TimeRangePreset_ManagedObject extends RuleKit
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Relationship: timeRangeGroups
    protected _timeRangeGroups:MIOManagedObjectSet = null;
    get timeRangeGroups():MIOManagedObjectSet {
        return this.valueForKey('timeRangeGroups');
    }
    addTimeRangeGroupsObject(value:TimeRangeGroup) {
        this._addObjectForKey(value, 'timeRangeGroups');
    }
    removeTimeRangeGroupsObject(value:TimeRangeGroup) {
        this._removeObjectForKey(value, 'timeRangeGroups');
    }

    // Relationship: timeRangePresetConfigurations
    protected _timeRangePresetConfigurations:MIOManagedObjectSet = null;
    get timeRangePresetConfigurations():MIOManagedObjectSet {
        return this.valueForKey('timeRangePresetConfigurations');
    }
    addTimeRangePresetConfigurationsObject(value:TimeRangePresetConfiguration) {
        this._addObjectForKey(value, 'timeRangePresetConfigurations');
    }
    removeTimeRangePresetConfigurationsObject(value:TimeRangePresetConfiguration) {
        this._removeObjectForKey(value, 'timeRangePresetConfigurations');
    }
}
