

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class SubTax_ManagedObject

class SubTax_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: isExcluded
    set isExcluded(value:boolean) {
        this.setValueForKey(value, 'isExcluded');
    }
    get isExcluded():boolean {
        return this.valueForKey('isExcluded');
    }
    set isExcludedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isExcluded');
    }
    get isExcludedPrimitiveValue():boolean {
        return this.primitiveValueForKey('isExcluded');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: parentSubtaxesFactor
    set parentSubtaxesFactor(value:number) {
        this.setValueForKey(value, 'parentSubtaxesFactor');
    }
    get parentSubtaxesFactor():number {
        return this.valueForKey('parentSubtaxesFactor');
    }
    set parentSubtaxesFactorPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'parentSubtaxesFactor');
    }
    get parentSubtaxesFactorPrimitiveValue():number {
        return this.primitiveValueForKey('parentSubtaxesFactor');
    }

    // Property: showInTicket
    set showInTicket(value:boolean) {
        this.setValueForKey(value, 'showInTicket');
    }
    get showInTicket():boolean {
        return this.valueForKey('showInTicket');
    }
    set showInTicketPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'showInTicket');
    }
    get showInTicketPrimitiveValue():boolean {
        return this.primitiveValueForKey('showInTicket');
    }

    // Property: taxQuantity
    set taxQuantity(value:number) {
        this.setValueForKey(value, 'taxQuantity');
    }
    get taxQuantity():number {
        return this.valueForKey('taxQuantity');
    }
    set taxQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'taxQuantity');
    }
    get taxQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('taxQuantity');
    }

    // Relationship: childSubtaxes
    protected _childSubtaxes:MIOManagedObjectSet = null;
    get childSubtaxes():MIOManagedObjectSet {
        return this.valueForKey('childSubtaxes');
    }
    addChildSubtaxesObject(value:SubTax) {
        this._addObjectForKey(value, 'childSubtaxes');
    }
    removeChildSubtaxesObject(value:SubTax) {
        this._removeObjectForKey(value, 'childSubtaxes');
    }

    // Relationship: parentSubtaxes
    protected _parentSubtaxes:MIOManagedObjectSet = null;
    get parentSubtaxes():MIOManagedObjectSet {
        return this.valueForKey('parentSubtaxes');
    }
    addParentSubtaxesObject(value:SubTax) {
        this._addObjectForKey(value, 'parentSubtaxes');
    }
    removeParentSubtaxesObject(value:SubTax) {
        this._removeObjectForKey(value, 'parentSubtaxes');
    }
    // Relationship: tax
    set tax(value:Tax) {
        this.setValueForKey(value, 'tax');
    }
    get tax():Tax {
        return this.valueForKey('tax') as Tax;
    }
}
