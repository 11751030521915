//
// Generated class FavouriteProduct
//

/// <reference path="FavouriteProduct_ManagedObject.ts" />

class FavouriteProduct extends FavouriteProduct_ManagedObject
{

}
