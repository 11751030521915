//
// Generated class ProductExistence
//

/// <reference path="ProductExistence_ManagedObject.ts" />

class ProductExistence extends ProductExistence_ManagedObject
{

}
