

/// <reference path="StockCoreAnnotation.ts" />

// Generated class StockConsumptionAnnotation_ManagedObject

class StockConsumptionAnnotation_ManagedObject extends StockCoreAnnotation
{

    // Property: cashDeskName
    set cashDeskName(value:string) {
        this.setValueForKey(value, 'cashDeskName');
    }
    get cashDeskName():string {
        return this.valueForKey('cashDeskName');
    }
    set cashDeskNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'cashDeskName');
    }
    get cashDeskNamePrimitiveValue():string {
        return this.primitiveValueForKey('cashDeskName');
    }

    // Property: categoryName
    set categoryName(value:string) {
        this.setValueForKey(value, 'categoryName');
    }
    get categoryName():string {
        return this.valueForKey('categoryName');
    }
    set categoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'categoryName');
    }
    get categoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('categoryName');
    }

    // Property: categoryTags
    set categoryTags(value:string) {
        this.setValueForKey(value, 'categoryTags');
    }
    get categoryTags():string {
        return this.valueForKey('categoryTags');
    }
    set categoryTagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'categoryTags');
    }
    get categoryTagsPrimitiveValue():string {
        return this.primitiveValueForKey('categoryTags');
    }

    // Property: computedCost
    set computedCost(value:number) {
        this.setValueForKey(value, 'computedCost');
    }
    get computedCost():number {
        return this.valueForKey('computedCost');
    }
    set computedCostPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'computedCost');
    }
    get computedCostPrimitiveValue():number {
        return this.primitiveValueForKey('computedCost');
    }

    // Property: computedTotalLineCost
    set computedTotalLineCost(value:number) {
        this.setValueForKey(value, 'computedTotalLineCost');
    }
    get computedTotalLineCost():number {
        return this.valueForKey('computedTotalLineCost');
    }
    set computedTotalLineCostPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'computedTotalLineCost');
    }
    get computedTotalLineCostPrimitiveValue():number {
        return this.primitiveValueForKey('computedTotalLineCost');
    }

    // Property: destinationName
    set destinationName(value:string) {
        this.setValueForKey(value, 'destinationName');
    }
    get destinationName():string {
        return this.valueForKey('destinationName');
    }
    set destinationNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'destinationName');
    }
    get destinationNamePrimitiveValue():string {
        return this.primitiveValueForKey('destinationName');
    }

    // Property: factor
    set factor(value:number) {
        this.setValueForKey(value, 'factor');
    }
    get factor():number {
        return this.valueForKey('factor');
    }
    set factorPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'factor');
    }
    get factorPrimitiveValue():number {
        return this.primitiveValueForKey('factor');
    }

    // Property: locationCategoryName
    set locationCategoryName(value:string) {
        this.setValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryName():string {
        return this.valueForKey('locationCategoryName');
    }
    set locationCategoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationCategoryName');
    }

    // Property: locationName
    set locationName(value:string) {
        this.setValueForKey(value, 'locationName');
    }
    get locationName():string {
        return this.valueForKey('locationName');
    }
    set locationNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationName');
    }
    get locationNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationName');
    }

    // Property: minConsumptionQuantity
    set minConsumptionQuantity(value:number) {
        this.setValueForKey(value, 'minConsumptionQuantity');
    }
    get minConsumptionQuantity():number {
        return this.valueForKey('minConsumptionQuantity');
    }
    set minConsumptionQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minConsumptionQuantity');
    }
    get minConsumptionQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('minConsumptionQuantity');
    }

    // Property: minLossQuantity
    set minLossQuantity(value:number) {
        this.setValueForKey(value, 'minLossQuantity');
    }
    get minLossQuantity():number {
        return this.valueForKey('minLossQuantity');
    }
    set minLossQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minLossQuantity');
    }
    get minLossQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('minLossQuantity');
    }

    // Property: originName
    set originName(value:string) {
        this.setValueForKey(value, 'originName');
    }
    get originName():string {
        return this.valueForKey('originName');
    }
    set originNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'originName');
    }
    get originNamePrimitiveValue():string {
        return this.primitiveValueForKey('originName');
    }

    // Property: productElaborationType
    set productElaborationType(value:number) {
        this.setValueForKey(value, 'productElaborationType');
    }
    get productElaborationType():number {
        return this.valueForKey('productElaborationType');
    }
    set productElaborationTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productElaborationType');
    }
    get productElaborationTypePrimitiveValue():number {
        return this.primitiveValueForKey('productElaborationType');
    }

    // Property: productTags
    set productTags(value:string) {
        this.setValueForKey(value, 'productTags');
    }
    get productTags():string {
        return this.valueForKey('productTags');
    }
    set productTagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productTags');
    }
    get productTagsPrimitiveValue():string {
        return this.primitiveValueForKey('productTags');
    }

    // Property: sourceItemID
    set sourceItemID(value:string) {
        this.setValueForKey(value, 'sourceItemID');
    }
    get sourceItemID():string {
        return this.valueForKey('sourceItemID');
    }
    set sourceItemIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceItemID');
    }
    get sourceItemIDPrimitiveValue():string {
        return this.primitiveValueForKey('sourceItemID');
    }

    // Property: subType
    set subType(value:number) {
        this.setValueForKey(value, 'subType');
    }
    get subType():number {
        return this.valueForKey('subType');
    }
    set subTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'subType');
    }
    get subTypePrimitiveValue():number {
        return this.primitiveValueForKey('subType');
    }

    // Property: totalConsumed
    set totalConsumed(value:number) {
        this.setValueForKey(value, 'totalConsumed');
    }
    get totalConsumed():number {
        return this.valueForKey('totalConsumed');
    }
    set totalConsumedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalConsumed');
    }
    get totalConsumedPrimitiveValue():number {
        return this.primitiveValueForKey('totalConsumed');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Relationship: annotations
    protected _annotations:MIOManagedObjectSet = null;
    get annotations():MIOManagedObjectSet {
        return this.valueForKey('annotations');
    }
    addAnnotationsObject(value:AccountAnnotation) {
        this._addObjectForKey(value, 'annotations');
    }
    removeAnnotationsObject(value:AccountAnnotation) {
        this._removeObjectForKey(value, 'annotations');
    }
    // Relationship: cashDesk
    set cashDesk(value:CashDesk) {
        this.setValueForKey(value, 'cashDesk');
    }
    get cashDesk():CashDesk {
        return this.valueForKey('cashDesk') as CashDesk;
    }
    // Relationship: category
    set category(value:ProductCategory) {
        this.setValueForKey(value, 'category');
    }
    get category():ProductCategory {
        return this.valueForKey('category') as ProductCategory;
    }

    // Relationship: children
    protected _children:MIOManagedObjectSet = null;
    get children():MIOManagedObjectSet {
        return this.valueForKey('children');
    }
    addChildrenObject(value:StockConsumptionAnnotation) {
        this._addObjectForKey(value, 'children');
    }
    removeChildrenObject(value:StockConsumptionAnnotation) {
        this._removeObjectForKey(value, 'children');
    }
    // Relationship: format
    set format(value:Format) {
        this.setValueForKey(value, 'format');
    }
    get format():Format {
        return this.valueForKey('format') as Format;
    }
    // Relationship: location
    set location(value:Location) {
        this.setValueForKey(value, 'location');
    }
    get location():Location {
        return this.valueForKey('location') as Location;
    }
    // Relationship: locationCategory
    set locationCategory(value:LocationCategory) {
        this.setValueForKey(value, 'locationCategory');
    }
    get locationCategory():LocationCategory {
        return this.valueForKey('locationCategory') as LocationCategory;
    }
    // Relationship: modifier
    set modifier(value:ProductModifier) {
        this.setValueForKey(value, 'modifier');
    }
    get modifier():ProductModifier {
        return this.valueForKey('modifier') as ProductModifier;
    }
    // Relationship: parent
    set parent(value:StockConsumptionAnnotation) {
        this.setValueForKey(value, 'parent');
    }
    get parent():StockConsumptionAnnotation {
        return this.valueForKey('parent') as StockConsumptionAnnotation;
    }
    // Relationship: salesAnnotation
    set salesAnnotation(value:SalesAnnotation) {
        this.setValueForKey(value, 'salesAnnotation');
    }
    get salesAnnotation():SalesAnnotation {
        return this.valueForKey('salesAnnotation') as SalesAnnotation;
    }
}
