

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class PaymentAction_ManagedObject

class PaymentAction_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: allRequired
    set allRequired(value:boolean) {
        this.setValueForKey(value, 'allRequired');
    }
    get allRequired():boolean {
        return this.valueForKey('allRequired');
    }
    set allRequiredPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'allRequired');
    }
    get allRequiredPrimitiveValue():boolean {
        return this.primitiveValueForKey('allRequired');
    }

    // Property: expirationDate
    set expirationDate(value:Date) {
        this.setValueForKey(value, 'expirationDate');
    }
    get expirationDate():Date {
        return this.valueForKey('expirationDate');
    }
    set expirationDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'expirationDate');
    }
    get expirationDatePrimitiveValue():Date {
        return this.primitiveValueForKey('expirationDate');
    }

    // Property: expirationTime
    set expirationTime(value:number) {
        this.setValueForKey(value, 'expirationTime');
    }
    get expirationTime():number {
        return this.valueForKey('expirationTime');
    }
    set expirationTimePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'expirationTime');
    }
    get expirationTimePrimitiveValue():number {
        return this.primitiveValueForKey('expirationTime');
    }

    // Property: isActive
    set isActive(value:boolean) {
        this.setValueForKey(value, 'isActive');
    }
    get isActive():boolean {
        return this.valueForKey('isActive');
    }
    set isActivePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isActive');
    }
    get isActivePrimitiveValue():boolean {
        return this.primitiveValueForKey('isActive');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Relationship: intervals
    protected _intervals:MIOManagedObjectSet = null;
    get intervals():MIOManagedObjectSet {
        return this.valueForKey('intervals');
    }
    addIntervalsObject(value:PaymentActionInterval) {
        this._addObjectForKey(value, 'intervals');
    }
    removeIntervalsObject(value:PaymentActionInterval) {
        this._removeObjectForKey(value, 'intervals');
    }

    // Relationship: productCombinations
    protected _productCombinations:MIOManagedObjectSet = null;
    get productCombinations():MIOManagedObjectSet {
        return this.valueForKey('productCombinations');
    }
    addProductCombinationsObject(value:ProductCombination) {
        this._addObjectForKey(value, 'productCombinations');
    }
    removeProductCombinationsObject(value:ProductCombination) {
        this._removeObjectForKey(value, 'productCombinations');
    }
}
