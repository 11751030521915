//
// Generated class ClientAccountBalance
//

/// <reference path="ClientAccountBalance_ManagedObject.ts" />

class ClientAccountBalance extends ClientAccountBalance_ManagedObject
{

}
