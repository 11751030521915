

class InputNoteDetailViewController extends NoteDetailViewController
{
    static newInstance() : InputNoteDetailViewController {
        let vc = new InputNoteDetailViewController("input-note-detail-view");
        vc.initWithResource("layout/input_notes/InputNoteDetailView.html");
        return vc;
    }

    private noEditHeaderView:OutputNoteDetailHeaderView = null;
    private editHeaderView:OutputNoteDetailHeaderView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.noEditHeaderView = MUIOutlet(this, "no-edit-header-view", "InputNoteDetailHeaderView");
        this.editHeaderView = MUIOutlet(this, "edit-header-view", "InputNoteDetailHeaderView");

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();
    }

    protected updateUI(){
        this.editHeaderView.note = this.stockNote;
        this.noEditHeaderView.note = this.stockNote;
        this.editHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? true : false;
        this.noEditHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? false : true;

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    titleForHeaderInSection(tableView:UITableView, section:number) {
        let item = this.fetchedResultsController.objectAtIndexPath(MIOIndexPath.indexForRowInSection(0, section)) as InventoryNoteLine;
        return item.productStockCategoryName;
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [
            MIOSortDescriptor.sortDescriptorWithKey('product.stockCategory.name', true), 
            MIOSortDescriptor.sortDescriptorWithKey('product.name', true)
        ];

        let predicateFormat = 'note.identifier == ' + this.stockNote.identifier;
        if (this.searchString != null) {
            predicateFormat += " AND productName CONTAINS '" + this.searchString + "'";
        }
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("CustomInputNoteLine", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["inputFormat", "product", "product.stockCategory", "product.defaultWarehouse"];
        fetchRequest.fetchLimit = 100;

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "productStockCategoryName");
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }    

    // controllerDidChangeContent(controller:MIOFetchedResultsController){
    //     super.controllerDidChangeContent(controller);
    //     let [base] = this.computeTotals();
    //     this.totalLabel.text = this.cf.stringFromNumber(base);
    // }

    protected printStockNote(){
        let exporter = new DBInputNoteLinesCoder();
        exporter.stockNoteID = this.stockNote.identifier;
        exporter.export();
        // ad.webService.exportStockNotePDF(this.stockNote.identifier, null, null);
    }

    overrideWarehouse() : Warehouse {
        return this.stockNote.destinationWarehouse;
    }

}