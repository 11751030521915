class DBExportController
{
    protected ad: AppDelegate = MUIWebApplication.sharedInstance().delegate;

    protected title() : string { return "DBEXPORT"; }
    protected headers() : any[] { return []; }
    protected columns(): string[] { return []; }

    protected entityName() { return null; }
    protected predicate() { return null }
    protected sortDescriptors() { return null }    
    protected relations(): string[] { return [] }

    protected parseObjects(objects:MIOManagedObject[]) : any[] { return []; }

    protected startColumn():number { return 0; }

    protected headerTitleStyle() { return null; }
    protected headerValueStyle() { return null; }

    protected tableHeaderStyle(columnIndex:number) { return null; }
    protected tableValueStyle(columnIndex:number) { return null; }

    protected tableSectionStyle() { return null; }

    protected columnWidths() { return null; }
    protected rowHeight() { return null; }

    export() {
        DBHelper.queryObjectsWithCompletion(this.entityName(), this.sortDescriptors(), this.predicate(), this.relations(), this, function(this:DBExportController, objects:MIOManagedObject[]) {            
            this.exportObjects(_MIOSortDescriptorSortObjects(objects, this.sortDescriptors()));
        });
    }
    
    protected exportObjects(objects:MIOManagedObject[]){
        
        let data = this.parseObjects(objects);
        let columns = this.columns();
        let colsWidth = [];

        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("Sheet");
        let ws_data = [["", "", ""]];                       
        let ws = XLSX.utils.aoa_to_sheet(ws_data);
        wb.Sheets["Sheet"] = ws;
        
        let cellRowIndex = 2;
        let cellColIndex = this.startColumn();

        // Add headers
        let headers = this.headers();
        for (let headerIndex = 0; headerIndex < headers.length; headerIndex++) {
            let h = headers[headerIndex];
            let title = h["Title"];
            let value = h["Value"];
            let cellID = this.cellid_by_index(cellColIndex) + cellRowIndex;
            this.add_cell_to_sheet(ws, cellID, title, "string", this.headerTitleStyle());
            cellColIndex++;            
            cellID = this.cellid_by_index(cellColIndex) + cellRowIndex;
            this.add_cell_to_sheet(ws, cellID, value, "string", this.headerValueStyle());
            cellColIndex = this.startColumn();
            cellRowIndex++;
        }
        
        cellRowIndex++;
        for (let colIndex = 0; colIndex < columns.length; colIndex++) {
            let title = columns[colIndex];
            let cellID = this.cellid_by_index(cellColIndex) + cellRowIndex;
            this.add_cell_to_sheet(ws, cellID, title, "string", this.tableHeaderStyle(colIndex));
            if (title.length > colsWidth[colIndex]) colsWidth[colIndex] = title.length + 2;            
            cellColIndex++;
        }
        cellColIndex = this.startColumn();
        cellRowIndex++;

        if(!ws['!rows']) ws['!rows'] = [];
        for (let rowIndex = 0; rowIndex < data.length; rowIndex++){
            if (this.rowHeight() != null) ws['!rows'][cellRowIndex - 1] = {hpt: this.rowHeight()};
            let row = data[rowIndex];
            if (row["__RowType"] == "section"){
                let cellID = this.cellid_by_index(0) + cellRowIndex;
                let title = row["Title"];
                this.add_cell_to_sheet(ws, cellID , title, "string", this.tableSectionStyle());
                cellRowIndex++;
                continue;
            }
            for (let colIndex = 0; colIndex < columns.length; colIndex++) {
                let key = columns[colIndex];
                let value = row[key];
                let cellID = this.cellid_by_index(cellColIndex) + cellRowIndex;
                cellColIndex++;
                if (value == null) continue;
                this.add_cell_to_sheet(ws, cellID , value, "string", this.tableValueStyle(colIndex));
                if (value.length > colsWidth[colIndex]) colsWidth[colIndex] = value.length + 2;
            }            
            cellColIndex = this.startColumn();
            cellRowIndex++;            
        }
        
        /// Auto width in every column
        if(!ws['!cols']) ws['!cols'] = [];
        for (let index = 0; index < this.startColumn(); index++){
            ws['!cols'][index] = {wch: 2};
        }        

        let columnWidths = this.columnWidths();
        if (columnWidths != null ) {
            for (let index = 0; index < columnWidths.length; index++){
                let w = columnWidths[index];
                ws['!cols'][this.startColumn() + index] = { wch: w };
            }            
        }
        else {
            // Automatic
            for (let index = 0; index < colsWidth.length; index++){
                ws['!cols'][index] = { wch: colsWidth[index] };
            }        
        }    

        let fullFileName = "dl-" + this.title() + ".xlsx";        
        XLSX.writeFile(wb, fullFileName, {cellStyles: true});
    }

    private cellid_by_index(colIndex):string{
        let startIndex = "A".charCodeAt(0);
        if (colIndex < 26) return String.fromCharCode(startIndex + colIndex);

        let times = (colIndex / 26) | 0;
        let m = colIndex % 26;

        let colID:string = "";
        for (let index = 0; index < times; index++){
            colID += "A";
        }

        colID += String.fromCharCode(startIndex + m);
        return colID;
    }

    private add_cell_to_sheet(worksheet, address, value, type?, style?) {
        /* cell object */        
        let cell = {t:'?', v: (value != null) ? value : ""};        
    
        /* assign type */        
        if (type == null) {
            if(typeof value == "string") cell.t = 's'; // string
            else if(typeof value == "number") cell.t = 'n'; // number
            else if(value === true || value === false) cell.t = 'b'; // boolean
            else if(value instanceof Date) cell.t = 'd';
            else throw new Error("cannot store value");
        }
        else {
            if(type == "string") cell.t = 's'; // string
            else if(type == "date") {                                 
                if (value.length > 1){
                    let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
                    let date = ad.dateFormatter.dateFromString(value);
                    cell["v"] = date.getTime();// ad.dateFormatter.stringFromDate(date);                    
                    cell.t = 'd';
                }                                
            }
            else if(type == "number") cell.t = 'n'; // number            
            else if(type == "currency") {
                cell.t = 'n'; // number
                //TODO: Add currency format
            }            
        }
    
        /* add to worksheet, overwriting a cell if it exists */
        worksheet[address] = cell;

        if (style != null) {            
            worksheet[address].s = style;
        }
    
        /* find the cell range */
        let range = XLSX.utils.decode_range(worksheet['!ref']);
        let addr = XLSX.utils.decode_cell(address);
    
        /* extend the range to include the new cell */
        if(range.s.c > addr.c) range.s.c = addr.c;
        if(range.s.r > addr.r) range.s.r = addr.r;
        if(range.e.c < addr.c) range.e.c = addr.c;
        if(range.e.r < addr.r) range.e.r = addr.r;
    
        /* update range */
        worksheet['!ref'] = XLSX.utils.encode_range(range);
    }
    
}