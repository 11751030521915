
class InputNoteListViewController extends NoteListViewController
{    
    static newInstance() : InputNoteListViewController {
        let vc = new InputNoteListViewController("input-note-list-view");
        vc.initWithResource("layout/input_notes/InputNoteListView.html");
        return vc;
    }

    protected get sectionControllerID():string {
        return SectionViewControllerType.InputNote;
    }

    protected noteStatusForSegmentedIndex(index){
        if (index == 1) return StockNoteStatus.Processed;        
        return StockNoteStatus.None;
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('InputNoteCell') as InputNoteCell;    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);    
        
        cell.item = item;
        cell.selected = this.selectedNote === item ? true : false;

        return cell;
    }
            
    set fetchedResultsController(value){
        this.setFetchedResultsController(value);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("documentID", false)];
        if (this.selectedStatus == StockNoteStatus.Processed) {
            sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("creationDate", false)];
        }                                                            
                
        let predicateFormat = this.selectedStatus != 0 ? "status == " + this.selectedStatus : "status == 0 OR status == 3";
        predicateFormat += " AND placeID == '" + ad.selectedIdentifier + "'";
        if(this.searchString != null) {
            predicateFormat += " AND documentID CONTAINS '" + this.searchString + "'";
		}
		
		if (this.filterPredicateFormat != null) {
            predicateFormat += " AND " + this.filterPredicateFormat;
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("CustomInputNote", sortDescriptors, predicateFormat);
        fetchRequest.fetchLimit = 50;
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
		
	private _filters = null;
    get filters(){
        if (this._filters != null) return this._filters;

		this._filters = [FilterOption.filterWithTitle("FromDate", "From day", "validationDate", FilterFieldType.Date, null, FilterControllerComparatorType.MajorOrEqualComparator, null, "00:00:00"),
		FilterOption.filterWithTitle("ToDate", "To day", "validationDate", FilterFieldType.Date, null, FilterControllerComparatorType.MinorOrEqualComparator, null, "23:59:59"),
		FilterOption.filterWithFetchRequest("DestinationConcept", "Concept", "destinationConcept.identifier", FilterFieldType.DropDown, "StockCustomConcept", "name", "identifier", MIOLocalizeString("SELECT CONCEPT","Select Concept"))];

        return this._filters;
    }
    
    protected showSelectedItem(){
        let vc = InputNoteDetailViewController.newInstance();        
        vc.note = this.selectedNote;
        this.splitViewController.showDetailViewController(vc);
    }

    protected showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("OutputNote", NoItemSelectedViewControllerType.OutputNote);
        this.splitViewController.showDetailViewController(vc);
    }

    protected showAddNoteViewController(){

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('ADD INPUT NOTE', 'ADD INPUT NOTE'), MIOLocalizeString('CHOOSE A CONCEPT AND DESTINATION WAREHOUSE', 'CHOOSE A CONCEPT AND DESTINATION WAREHOUSE'), MUIAlertViewStyle.Default);
        
        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
             
            DBHelper.sharedInstance().addObserverForEntity(comboBox, 'StockCustomConcept', null, null, null, function (objects:StockCustomConcept[]) {
                comboBox.removeAllItems();                
                for (let i = 0; i < objects.length; i++)
                    comboBox.addItem(objects[i].name, i);
            });
        });

        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
        
            DBHelper.sharedInstance().addObserverForEntity(comboBox, 'Warehouse', null, null, null, function (objects:Warehouse[]) {
                comboBox.removeAllItems();
                for (let i = 0; i < objects.length; i++)
                    comboBox.addItem(objects[i].name, i);
            });
        });

        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
            DBHelper.sharedInstance().addObserverForEntity(avc, "StockTemplate", null, null, null, function (objects) {
                comboBox.removeAllItems();
                comboBox.addItem(MIOLocalizeString("NO TEMPLATE", "NO TEMPLATE"), -1);
                let sortObjects = _MIOSortDescriptorSortObjects(objects, [MIOSortDescriptor.sortDescriptorWithKey("name", true)]);
                for (let i = 0; i < sortObjects.length; i++){
                    let id = sortObjects[i].identifier;
                    comboBox.addItem(sortObjects[i].name, i);
                }
            });  
        });

        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("DONE", "DONE"), MUIAlertActionStyle.Default, this, function(this:InputNoteListViewController){
            let moc: MIOManagedObjectContext = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

            let conceptComboBox:MUIComboBox = avc.comboBoxes[0];
            let conceptIndex = conceptComboBox.getSelectedItem();

            let warehouseComboBox:MUIComboBox = avc.comboBoxes[1];
            let warehouseIndex = warehouseComboBox.getSelectedItem();
                        
            let concept = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc.comboBoxes[0], conceptIndex, 'StockCustomConcept');
            let warehouse = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc.comboBoxes[1], warehouseIndex, 'Warehouse');

            let templateIndex = avc.comboBoxes[2].getSelectedItem();
            let template = templateIndex == '-1' ? null : DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, templateIndex, 'StockTemplate');                      

            this.addInputNote(concept, warehouse, template);
            
        }));
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));
        
        avc.addCompletionHandler(this, function (this:OutputNoteListViewController) {
            // Release the observers
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc.comboBoxes[0], 'StockCustomConcept');
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc.comboBoxes[1], 'Warehouse');            
        });
        this.presentViewController(avc, true);
    }

    private addInputNote(concept:StockCustomConcept, warehouse:Warehouse, template:StockTemplate){
        DBHelper.createInputNote(concept, warehouse, template, (note:SupplierOrder) => {
            this.selectedNote = note;
            DBHelper.saveMainContext();
            this.showSelectedItem();            
        });
   }

}