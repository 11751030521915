class DBSyncViewController extends BaseTableViewController
{
    private entitiesComboBox:MUIComboBox = null;
    
    private segmentedControl:MUISegmentedControl = null;
    
    private calendarButton:MUIButton = null;
    private actionButton:MUIButton = null;
    
    private referenceColumnTextField:ColumnFilterTextField = null;
    private conceptColumnTextField:ColumnFilterTextField = null;
	private statusColumnTextField:ColumnFilterTextField = null;    
	private validationDateColumnTextField:ColumnFilterTextField = null;    

    private entityHeaderView:DBSynEntityHeaderView = null;

    private filterController:ColumnFilterController = null;

    private entitiesDataSource:DBSyncEntitiesDataSource = null;
    private queueDataSource:DBSyncEntitiesQueueDataSource = null;
    private transactionDataSource:DBSyncTransactionsDataSource = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.entitiesComboBox = MUIOutlet(this, "sync-entities-cb", "MUIComboBox");
        this.entitiesComboBox.setOnChangeAction(this, function(this:DBSyncViewController, control, value){
            this.entityDidChange(parseInt(value));
        });

        this.segmentedControl = MUIOutlet(this, "segmented-control", "MUISegmentedControl");
        this.segmentedControl.setAction(this, this.changeDataSourceAction);

        this.calendarButton = MUIOutlet(this, "calendar-btn", "MUIButton");
        this.calendarButton.setAction(this, function(this:DBSyncViewController){
            let ah:AppHelper = AppHelper.sharedInstance();
            ah.presentDateSelectionViewController(this);
        });

        this.actionButton = MUIOutlet(this, "action-btn", "MUIButton");
        this.actionButton.setAction(this, function(this:DBSyncViewController){
            this.showActions();
        });

        this.filterController = new ColumnFilterController();
        this.filterController.initWithDelegate(this);

        this.referenceColumnTextField = MUIOutlet(this, "reference-col-filter", "ColumnFilterTextField");
        this.referenceColumnTextField.filterController = this.filterController;
        this.referenceColumnTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "referenceID", null, null);

        this.conceptColumnTextField = MUIOutlet(this, "concept-col-filter", "ColumnFilterTextField");
        this.conceptColumnTextField.filterController = this.filterController;
        this.conceptColumnTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "concept", null, null);

        this.statusColumnTextField = MUIOutlet(this, "status-col-filter", "ColumnFilterTextField");
        this.statusColumnTextField.filterController = this.filterController;
        this.statusColumnTextField.setFilterEnumValues({"nosync":0, "synced":1});
		this.statusColumnTextField.setOnFilterChange(ColumnFilterTextFieldType.Enum, "status", null, null);
		
		this.validationDateColumnTextField = MUIOutlet(this, "validation-date-col-filter", "ColumnFilterTextField");
        this.validationDateColumnTextField.filterController = this.filterController;
        this.validationDateColumnTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "date", null, null);

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.entityHeaderView = MUIOutlet(this, "entity-header-view", "DBSynEntityHeaderView");

        this.entitiesDataSource = new DBSyncEntitiesDataSource();
        this.entitiesDataSource.initWithTableView(this.tableView, this.entityHeaderView);

        this.queueDataSource = new DBSyncEntitiesQueueDataSource();
        this.queueDataSource.initWithTableView(this.tableView, this.entityHeaderView);        

        this.transactionDataSource = new DBSyncTransactionsDataSource();
        this.transactionDataSource.initWithTableView(this.tableView, this.entityHeaderView);

        this.tableView.dataSource = this.entitiesDataSource;
        this.tableView.delegate = this.entitiesDataSource;        
        
        this.updateEntitiesComboBox(this.configFetchedResultsController.fetchedObjects);

        // MIONotificationCenter.defaultCenter().addObserver(this, "IntegratorSyncedLine", function(note){
        //     this.invalidateData();
        // });

        MIONotificationCenter.defaultCenter().addObserver(this, "DBSyncEntityDidChange", function(note){
            this.invalidateData();
        });
    }

    protected invalidateData(){
        this.entitiesDataSource.invalidateData();
        this.transactionDataSource.invalidateData();
        this.queueDataSource.invalidateData();
        super.invalidateData();        
    }    

    /*
    viewForHeaderInSection(tableview, section){
            
        let h = new MUIView();
        h.init();
        h.layer.background = "";
        h.layer.style.position= "absolute";
        h.setHeight(23);

        let title = new MUILabel();
        title.init();
        title.layer.style.position = "absolute";
        title.setX(8);
        title.setWidth(500);
        h.addSubview(title);

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate

        let ip = MIOIndexPath.indexForRowInSection(0, section);
        let item = this.fetchedResultsController.objectAtIndexPath(ip) as DBSyncEntity;
        title.text = " " + ad.longDateTimeFormatter.stringFromDate(item.integratorDate) + " # " + item.integratorReferenceConcept;        

        return h;
    } */


    private changeDataSourceAction(sender){
        switch(this.segmentedControl.selectedSegmentedIndex){
            case 0:
                this.actionButton.hidden = true;
                this.tableView.dataSource = this.entitiesDataSource;
                this.tableView.delegate = this.entitiesDataSource;                
                break;

            case 1:
                this.actionButton.hidden = false;
                this.tableView.dataSource = this.queueDataSource;
                this.tableView.delegate = this.queueDataSource;  
                this.queueDataSource.invalidateData();
                break;    

            case 2:
                this.actionButton.hidden = true;
                this.tableView.dataSource = this.transactionDataSource;
                this.tableView.delegate = this.transactionDataSource;        
                this.transactionDataSource.invalidateData();
                break;                
        }

        this.tableView.reloadData();
    }

    private _configFetchedResultsController:MIOFetchedResultsController = null;
    get configFetchedResultsController(){
        if (this._configFetchedResultsController != null) return this._configFetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;        
    
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("IntegratorConfig", sortDescriptors, null);

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._configFetchedResultsController = fetchedResultsController;   
        return this._configFetchedResultsController;
    }
    
    controllerDidChangeContent(controller){        
        this.updateEntitiesComboBox(controller.fetchedObjects);
    }

    private updateEntitiesComboBox(objects){
        this.entitiesComboBox.removeAllItems();
        this.entitiesComboBox.addItem("None", -1);        
        
        for (let index = 0; index < objects.length; index++){
            let cfg = objects[index] as IntegratorConfig;
            this.entitiesComboBox.addItem(cfg.name + ":" + cfg.queueEntityName, index);
        }
    }

    private integrator:string = null;
    private entityName:string = null;
    private group = false;
        
    private entityDidChange(index){
        if (index == -1) return;        

        let ip = MIOIndexPath.indexForRowInSection(index, 0);
        let cfg = this._configFetchedResultsController.objectAtIndexPath(ip) as IntegratorConfig;

        this.integrator = cfg.integrator;
        this.entityName = cfg.queueEntityName;
        
        this.entitiesDataSource.setConfigValues(cfg);
        this.queueDataSource.setConfigValues(cfg);
        this.transactionDataSource.setConfigValues(cfg);

        //this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    filterPredicateDidChange(controller:ColumnFilterController){
        this.invalidateData();
    }

    private dateTimeFrom:string = null;
    private dateTimeTo:string = null;
    datesDidSelect(dateTimeFrom, dateTimeTo) {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this.dateTimeFrom = ad.serverDateTimeFormatter.stringFromDate(dateTimeFrom);
        this.dateTimeTo = ad.serverDateTimeFormatter.stringFromDate(dateTimeTo);
        this.entitiesDataSource.dateFrom = this.dateTimeFrom;
        this.entitiesDataSource.dateTo = this.dateTimeTo;
        this.transactionDataSource.dateFrom = this.dateTimeFrom;
        this.transactionDataSource.dateTo = this.dateTimeTo;
        this.invalidateData();
    }

    private showActions(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ACTIONS", "ACTIONS"), MIOLocalizeString("CHOOSE AN ACTION", "CHOOSE AN ACTION"), MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("SYNC ALL QUEUED ITEMS", "SYNC ALL QUEUED ITEMS"), MUIAlertActionStyle.Default, this, function(this:DBSyncViewController){            
            this.syncAllQueuedItems();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private showSyncConfirmation(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("SYNC ACTION", "SYNC ACTION"), MIOLocalizeString("DO YOU WANT TO SYNC ALL LINES?", "DO YOU WANT TO SYNC ALL LINES?"), MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle("OK", MUIAlertActionStyle.Default, this, function(this:DBSyncViewController){
            this.syncAllQueuedItems();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle("Cancel", MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private syncAllQueuedItems(){

        const transacionID = MIOUUID.UUID().UUIDString;
        const items = _MIOPredicateFilterObjects(this.queueDataSource.fetchedResultsController.fetchedObjects, MIOPredicate.predicateWithFormat("integratorStatus == 2 AND integratorTransactionID == null")) as IntegratorSyncEntityQueue[];
        for(let i of items) {
            i.integratorSync.integratorTransactionID = transacionID;
        }

        DBHelper.saveMainContext();
    }

}