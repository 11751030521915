//
// Generated class AppConfiguration
//

/// <reference path="AppConfiguration_ManagedObject.ts" />

class AppConfiguration extends AppConfiguration_ManagedObject
{
    valueFromString(value:string) {
        
        if (this.key == "ActiveStoryBoards") {
            let values = value.split(",");
            let a = []
            for (let v of values){
                a.addObject( parseInt(v) );
            }
            this.value = a;
        }
        else if (value.toLowerCase() == "true") this.value = true;
        else if (value.toLowerCase() == "false") this.value = false;
        else {
            let n = parseInt(value);
            if (isNaN(n) == false) this.value = n;
            else {
                this.value = value;
            }
        }
    }
}
