//
// Generated class AccountingEntry
//

/// <reference path="AccountingEntry_ManagedObject.ts" />

class AccountingEntry extends AccountingEntry_ManagedObject
{
    setAmount( value:number, ledgerAccount:LedgerAccount )
    {        
        let type:LedgerAccountType = ledgerAccount.type;

        if (value == null || value == 0) {
            this.debitAmount = 0;
            this.creditAmount = 0;
        }
        else if (value < 0) {
            this.debitAmount   = type == LedgerAccountType.debit ? 0 : Math.abs(value);
            this.creditAmount = type == LedgerAccountType.debit ? Math.abs(value) : 0;
        }
        else if ( value > 0 ) {
            this.debitAmount   = type == LedgerAccountType.debit  ? value : 0;
            this.creditAmount = type == LedgerAccountType.debit ? 0 : value;
        }
    }
}
