
class PartyListViewController extends BaseTableViewController
{
    private addButton:MUIButton = null;    

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function(){
            this.addParty();
        });

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let cell = tableView.dequeueReusableCellWithIdentifier("PartyCell") as PartyCell;        

        let item:Party = this.fetchedResultsController.objectAtIndexPath(indexPath);
        cell.item = item;

        cell.selected = item === this.selectedItem ? true : false;

        return cell;
    }
     
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath) as Party;
        this.showPartyDetailViewController();
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("Party");            
        fetchRequest.sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey("title", true)];
                
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    private addParty(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let party = MIOEntityDescription.insertNewObjectForEntityForName("Party", ad.managedObjectContext) as Party;
        party.name = "New party";
        party.descriptionInfo = "New party description";
        ad.managedObjectContext.save();        

        this.selectedItem = party;

        this.showPartyDetailViewController();
    }
    
    private showPartyDetailViewController(){
                
        let vc = new PartyDetailViewController('party-detail-view');
        vc.initWithResource('layout/party/PartyDetailView.html');           
        vc.party = this.selectedItem;
        this.splitViewController.showDetailViewController(vc);   
    }
}