

class InstallmentsViewController extends MUIViewController
{

    static newInstance() : InstallmentsViewController {
        let vc = new InstallmentsViewController("installments-view");
        vc.initWithResource("layout/installments/InstallmentsView.html");
        return vc;
    }

    private tableView:UITableView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.tableView = MUIOutlet(this, 'table-view', 'UITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(animated:boolean){
        super.viewWillAppear();
        this.updateUI();
    }

    // private _onlineOrder:OnlineOrder = null;
    // set onlineOrder(order:OnlineOrder) {
    //     this._onlineOrder = order;
    //     this.updateUI()
    // }

    protected updateUI(){        
        if (this.viewIsLoaded == false) return;        
        
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }
    
    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {
        let cell = tableView.dequeueReusableCellWithIdentifier("InstallmentsViewCell") as InstallmentsViewCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as PaymentInstallment;
        cell.item = item;
        return cell;
    } 

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
        let sortDescriptors = null;
        let predicateFormat = null;
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("PaymentInstallment", sortDescriptors, predicateFormat);
        //fetchRequest.relationshipKeyPathsForPrefetching = ["product", "tax", "product.productPlace", "modifier"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }    

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();        
    }

    filterPredicateDidChange(controller:ColumnFilterController){
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }
}