class DeliveryRoutesDetailViewController extends BaseTableViewController {
    
    private saveButton:MUIButton = null;
    private optionsButton:MUIButton = null;

    private routeNameTextField:MUITextField = null;
    private deliveryFeeTextField:MUITextField = null;
    private deliveryFeeISLessThanTextField:MUITextField = null;
    private minDeliveryTextField:MUITextField = null;
    private minFreeDeliveryTextField:MUITextField = null;
    private addPostalCodeButton: MUIButton = null;

    private in = MUIWebApplication.sharedInstance().delegate.integerFormatter as MIONumberFormatter;

    viewDidLoad(){
        super.viewDidLoad();

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
            MIONotificationCenter.defaultCenter().postNotification("DeliveryRouteNameDidChange", null);
        });

        this.optionsButton = MUIOutlet(this, "options-btn", "MUIButton");
        this.optionsButton.setAction( this, function() {
            this.showOptions();
        });

        this.routeNameTextField = MUIOutlet(this, 'route-name-tf', 'MUITextField');
        this.routeNameTextField.setOnChangeText(this, function(control, value:string){
            this._deliveryRoute.name = value;
        })

        this.deliveryFeeTextField = MUIOutlet(this, 'delivery-fee-tf', 'MUITextField');
        this.deliveryFeeTextField.setOnChangeText(this, function(control, value:string){
            this._deliveryRoute.deliveryFee = value;
        })

        this.deliveryFeeISLessThanTextField = MUIOutlet(this, 'delivery-fee-less-than-tf', 'MUITextField');
        this.deliveryFeeISLessThanTextField.setOnChangeText(this, function(control, value:string){
            this._deliveryRoute.deliveryFeeIsLessThanMin = value;
        })

        this.minDeliveryTextField = MUIOutlet(this, 'min-delivery-tf', 'MUITextField');
        this.minDeliveryTextField.setOnChangeText(this, function(control, value:string){
            this._deliveryRoute.minDeliveryAmount = value;
        })

        this.minFreeDeliveryTextField = MUIOutlet(this, 'min-free-delivery-tf', 'MUITextField');
        this.minFreeDeliveryTextField.setOnChangeText(this, function(control, value:string){
            this._deliveryRoute.minFreeDeliveryAmount = value;
        })

        this.addPostalCodeButton = MUIOutlet(this, 'add-code-btn', 'MUIButton');
        this.addPostalCodeButton.setAction(this, function (){
            this.addPostalCodeAction();
        });

        this.tableView = MUIOutlet(this,"table-view","UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        

    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private _deliveryRoute:DeliveryRoute = null;
    set item(item:DeliveryRoute){        
        this._deliveryRoute = item;
        this.updateUI();
    } 

    private updateUI() {
        if (this.viewIsLoaded == false || this._deliveryRoute == null) return;

        this.routeNameTextField.text = this._deliveryRoute.name;
        this.deliveryFeeTextField.text = this._deliveryRoute.deliveryFee;
        this.deliveryFeeISLessThanTextField.text = this._deliveryRoute.deliveryFeeIfLessThanMin;
        this.minDeliveryTextField.text = this._deliveryRoute.minDeliveryAmount;
        this.minFreeDeliveryTextField.text = this._deliveryRoute.minFreeDeliveryAmount;

        this.fetchedResultsController = null;
        this.tableView.reloadData();
     }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell:DeliveryRoutesDetailCell = tableview.dequeueReusableCellWithIdentifier("DeliveryRoutesDetailCell");
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as DeliveryPostalCode;
        cell.item = item;            
        return cell;
    }
    
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        //let selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath);
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {         
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {        
        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            let item = this.fetchedResultsController.objectAtIndexPath(indexPath);    
            DBHelper.deleteObjectFromMainContext(item, true); 
        }
    } 

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
        let fetchRequest:MIOFetchRequest = null;
        let sortDescriptors = null;
        let predicate = 'deliveryRoute.identifier == ' + this._deliveryRoute.identifier;
        // let predicate = null;

        fetchRequest = DBHelper.listFetchRequestWithEntityName("DeliveryPostalCode", sortDescriptors, predicate);
        fetchRequest.relationshipKeyPathsForPrefetching = ['deliveryRoute']; //Test relationship with "deliveryRoute"

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;        
    }

    private addPostalCodeAction(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('POSTAL CODE',"POSTAL CODE"), (MIOLocalizeString('ADD POSTAL CODE TO ',"ADD POSTAL CODE TO ") + this._deliveryRoute.name), MUIAlertViewStyle.Default);    

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
            textField.setPlaceholderText(MIOLocalizeString("000000", "000000"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD',"ADD"), MUIAlertActionStyle.Default, this, function(){                        
            let value = avc.textFields[0].text;

            let isDuplicate = false;
            let objects = this.fetchedResultsController.fetchedObjects;
            for (var i = 0; i<objects.count; i++) {
                if(objects[i].postalCode == value) {
                    isDuplicate = true;
                }
            }
            if (isDuplicate) {
                this.ShowDuplicateNotice();
            } else {
                //add new entity postalCode
                let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
                let newPostalCode = MIOEntityDescription.insertNewObjectForEntityForName("DeliveryPostalCode", moc) as DeliveryPostalCode;
                newPostalCode.identifier = MIOUUID.UUID().UUIDString;
                newPostalCode.postalCode = value;
                newPostalCode.deliveryRoute = this._deliveryRoute;

                DBHelper.saveMainContext();
                this.updateUI();
            }
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL',"CANCEL"), MUIAlertActionStyle.Cancel, null, null));
        this.presentViewController(avc, true); 
     }

     private ShowDuplicateNotice(newModifierType){
        let notice = new MUIAlertViewController();
        notice.initWithTitle(MIOLocalizeString('NOTICE', 'NOTICE'), MIOLocalizeString('POSTAL CODE ALREADY EXISTS','POSTAL CODE ALREADY EXISTS'), MUIAlertViewStyle.Default);
        notice.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK',"OK"), MUIAlertActionStyle.Default, this, function(){                        

        }));
        this.presentViewController(notice, true);
    }

    private showOptions(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('ACTIONS','ACITONS'), MIOLocalizeString('OPTIONS','OPTIONS'), MUIAlertViewStyle.Default);
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("DELETE", "DELETE"), MUIAlertActionStyle.Destructive, this, function(){            
            DBHelper.deleteObjectFromMainContext(this._deliveryRoute, true);
        }));
 
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "cancel"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }
        
}