
class CashDeskSessionDocumentListViewController extends MUIViewController
{    
    private documentTextField:ColumnFilterTextField = null;
    private locationTextField:ColumnFilterTextField = null;
	private totalTextField:ColumnFilterTextField = null;
	private nameTextField:ColumnFilterTextField = null;

    private tableView:UITableView = null;
    private segmentedControl:MUISegmentedControl = null;    
    private ticketTableView:UITableView = null;
        
    private documentDataSource:CashDeskSessionTicketLinesDataSource = null;
    private transactionDataSource:CashDeskSessionTicketTransactionsDataSource = null;

    private filterController:ColumnFilterController = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.filterController = new ColumnFilterController();
        this.filterController.initWithDelegate(this);

        this.documentTextField = MUIOutlet(this, "document-filter-tf", "ColumnFilterTextField");
        this.documentTextField.filterController = this.filterController;
		this.documentTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "legalDocumentID", null, null);
		
		this.nameTextField = MUIOutlet(this, "name-filter-tf", "ColumnFilterTextField");
		this.nameTextField.filterController = this.filterController;
		this.nameTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "ticketLegalEntityName", null, null);

        this.locationTextField = MUIOutlet(this, "location-filter-tf", "ColumnFilterTextField");
        this.locationTextField.filterController = this.filterController;
        this.locationTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "locationCategoryName", null, null);

        this.totalTextField = MUIOutlet(this, "total-filter-tf", "ColumnFilterTextField");
        this.totalTextField.filterController = this.filterController;
        this.totalTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "totalPrice", null, null);

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.segmentedControl = MUIOutlet(this, "segmented-control", "MUISegmentedControl");
        this.segmentedControl.setAction(this, function(control, index){
            this.reloadDocumentData();
        });

        this.ticketTableView = MUIOutlet(this, "ticket-table-view", "MUITableView");
        
        this.documentDataSource = new CashDeskSessionTicketLinesDataSource();
        this.documentDataSource.initWithTableView(this.ticketTableView);

        this.transactionDataSource = new CashDeskSessionTicketTransactionsDataSource();
        this.transactionDataSource.initWithTableView(this.ticketTableView);
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _session:CashDeskSession = null;
    set session(i:CashDeskSession){
        this._session = i;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._session == null) return;

        this.transactionDataSource.session = this._session;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('DocumentCell') as CashDeskSessionDocumentCell;
    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ArchivedDocument;    
        cell.item = item;     
    
        return cell;
    }
        
    private selectedDocument:ArchivedDocument = null;
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ArchivedDocument;    
        this.selectedDocument = item;
        this.reloadDocumentData();
    }
    
    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
                
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('date', false)];        
        let predicateFormat = "legalDocumentID != null AND cashDeskSession.identifier == " + this._session.identifier;
        
        let filterPredicateFormat = this.filterController.filterPredicateFormat();
        if (filterPredicateFormat != null){
            predicateFormat += " AND (" + filterPredicateFormat + ")";
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName('ArchivedDocument', sortDescriptors, predicateFormat);        

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    private reloadDocumentData(){
        if (this.selectedDocument == null) return;

        let index = this.segmentedControl.selectedSegmentedIndex;
        if (index == 0) {
            this.ticketTableView.dataSource = this.documentDataSource;
            this.documentDataSource.item = this.selectedDocument;
        }
        else if (index == 1){
            this.ticketTableView.dataSource = this.transactionDataSource;
            this.transactionDataSource.item = this.selectedDocument;
        }

    }

    filterPredicateDidChange(controller:ColumnFilterController){
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }


}