//
// Generated class LicenseProduct
//

/// <reference path="LicenseProduct_ManagedObject.ts" />

class LicenseProduct extends LicenseProduct_ManagedObject
{

}
