

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class BookingZone_ManagedObject

class BookingZone_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: defaultCapacity
    set defaultCapacity(value:number) {
        this.setValueForKey(value, 'defaultCapacity');
    }
    get defaultCapacity():number {
        return this.valueForKey('defaultCapacity');
    }
    set defaultCapacityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'defaultCapacity');
    }
    get defaultCapacityPrimitiveValue():number {
        return this.primitiveValueForKey('defaultCapacity');
    }

    // Property: defaultDuration
    set defaultDuration(value:number) {
        this.setValueForKey(value, 'defaultDuration');
    }
    get defaultDuration():number {
        return this.valueForKey('defaultDuration');
    }
    set defaultDurationPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'defaultDuration');
    }
    get defaultDurationPrimitiveValue():number {
        return this.primitiveValueForKey('defaultDuration');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: showQuantity
    set showQuantity(value:boolean) {
        this.setValueForKey(value, 'showQuantity');
    }
    get showQuantity():boolean {
        return this.valueForKey('showQuantity');
    }
    set showQuantityPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'showQuantity');
    }
    get showQuantityPrimitiveValue():boolean {
        return this.primitiveValueForKey('showQuantity');
    }

    // Property: templatePrefix
    set templatePrefix(value:string) {
        this.setValueForKey(value, 'templatePrefix');
    }
    get templatePrefix():string {
        return this.valueForKey('templatePrefix');
    }
    set templatePrefixPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'templatePrefix');
    }
    get templatePrefixPrimitiveValue():string {
        return this.primitiveValueForKey('templatePrefix');
    }

    // Relationship: bookingZoneConfigurations
    protected _bookingZoneConfigurations:MIOManagedObjectSet = null;
    get bookingZoneConfigurations():MIOManagedObjectSet {
        return this.valueForKey('bookingZoneConfigurations');
    }
    addBookingZoneConfigurationsObject(value:BookingZoneConfiguration) {
        this._addObjectForKey(value, 'bookingZoneConfigurations');
    }
    removeBookingZoneConfigurationsObject(value:BookingZoneConfiguration) {
        this._removeObjectForKey(value, 'bookingZoneConfigurations');
    }
    // Relationship: parent
    set parent(value:BookingZone) {
        this.setValueForKey(value, 'parent');
    }
    get parent():BookingZone {
        return this.valueForKey('parent') as BookingZone;
    }

    // Relationship: subZones
    protected _subZones:MIOManagedObjectSet = null;
    get subZones():MIOManagedObjectSet {
        return this.valueForKey('subZones');
    }
    addSubZonesObject(value:BookingZone) {
        this._addObjectForKey(value, 'subZones');
    }
    removeSubZonesObject(value:BookingZone) {
        this._removeObjectForKey(value, 'subZones');
    }
}
