
class WarehouseOrderListCell extends UITableViewCell
{
    private documentLabel:MUILabel = null;
    private dateLabel:MUILabel = null;
    private sourceLabel:MUILabel = null;    
    private destinationLabel:MUILabel = null;

    awakeFromHTML(){
        this.documentLabel = MUIOutlet(this, 'document-lbl', 'MUILabel');
        this.dateLabel = MUIOutlet(this, 'date-lbl', 'MUILabel');        
        this.sourceLabel = MUIOutlet(this, 'source-lbl', 'MUILabel');  
        this.destinationLabel = MUIOutlet(this, "destination-lbl", "MUILabel");
    }

    set item(item:WarehouseOrder){
        let dtf = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter;

        this.documentLabel.text = item.documentID;        
        this.dateLabel.text = dtf.stringFromDate(item.stockDate);
        this.sourceLabel.text = item.originName;
        this.destinationLabel.text = item.destinationName;
    }
}
