/**
 * Created by godshadow on 22/08/16.
 */



enum TransactionType
{
    CreditCardRegister,
    ManualPayment,
    CreditCardPaymentStep1,
    CreditCardPaymentStep2
}

class BookingTransactionAddViewController extends MUIViewController
{
    type = TransactionType.ManualPayment;
    booking = null;
    amount = 0;

    private _saveButton = null;
    private _closeButton = null;

    private _amountTextField = null;

    private _webView = null;

    viewDidLoad()
    {
        super.viewDidLoad();
/*
        if (this.type == TransactionType.ManualPayment)
        {
            this._amountTextField = MUIOutlet(this, "btmv_amount", "MUITextField");
            this._saveButton = MUIOutlet(this, "btmv_save_button", "MUIButton");
            this._closeButton = MUIOutlet(this, "btmv_close_button", "MUIButton");
            this._closeButton.setAction(this, function () {

                MUIWebApplication.sharedInstance().endSheetViewController();
            });
        }
        else if (this.type == TransactionType.CreditCardRegister)
        {
            this._webView = MUIOutlet(this, "btcv_webview", "MUIWebView");
            this._closeButton = MUIOutlet(this, "btcv_close_button", "MUIButton");
            this._closeButton.setAction(this, function () {

                MUIWebApplication.sharedInstance().endSheetViewController();
            });

            var url='http://www.dual-link.es/webapps/pasarela/generaPAY.php?b=' + this.booking.identifier + '&r=' + this.booking.paymentToken + '&i='+ this.amount +'&t=3';
            this._webView.setURL(url);
        }
        else if (this.type == TransactionType.CreditCardPaymentStep1)
        {
            this._amountTextField = MUIOutlet(this, "btmv_amount", "MUITextField");
            this._saveButton = MUIOutlet(this, "btmv_save_button", "MUIButton");
            this._saveButton.setHidden(true);
            this._closeButton = MUIOutlet(this, "btmv_close_button", "MUIButton");
            this._closeButton.setTitle("Next");
            this._closeButton.setAction(this, function () {

                var vc = new BookingTransactionAddViewController("booking_transaction_credit_view");
                vc.type = TransactionType.CreditCardPaymentStep2;
                vc.booking = this.booking;
                vc.amount = this._amountTextField.text;
                vc.initWithResource("layout/BookingTransactionCreditView.html");

                this.navigationController.pushViewController(vc, true);
            });
        }
        else if (this.type == TransactionType.CreditCardPaymentStep2)
        {
            this._webView = MUIOutlet(this, "btcv_webview", "MUIWebView");
            this._closeButton = MUIOutlet(this, "btcv_close_button", "MUIButton");
            this._closeButton.setAction(this, function () {

                MUIWebApplication.sharedInstance().endSheetViewController();
            });

            var url='http://www.dual-link.es/webapps/pasarela/generaPAY.php?b=' + this.booking.identifier + '&r=' + this.booking.paymentToken + '&i='+ this.amount +'&t=1';
            this._webView.setURL(url);
        }
        */
    }

    get contentSize()
    {
        if (this.type == TransactionType.ManualPayment)
            return new MIOSize(250, 85);
        else if (this.type == TransactionType.CreditCardRegister)
            return new MIOSize(700, 600);
        else if (this.type == TransactionType.CreditCardPaymentStep1)
            return new MIOSize(250, 85);
        else if (this.type == TransactionType.CreditCardPaymentStep2)
            return new MIOSize(700, 600);

        // Default
        return new MIOSize(320, 200);
    }
}
