

class BookingsCalendarViewController extends BaseViewController
{    
    private addButton:MUIButton = null;

    private calendarView:MUICalendarView = null
    private calendarDayStatusComboBox:MUIComboBox = null;
    private tableView:MUITableView = null;

    private sdf:MIODateFormatter = MUIWebApplication.sharedInstance().delegate.serverDateFormatter;
    private df = MUIWebApplication.sharedInstance().delegate.dateFormatter;
    private selectedDayString:string  = null;
    private selectedDay:Date = null;

    private appDelegate:AppDelegate = MUIWebApplication.sharedInstance().delegate;

    viewDidLoad() {
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function(this:BookingsCalendarViewController) {
            this.showClientSearchViewController();            
            //MIONotificationCenter.defaultCenter().postNotification("AddNewBookingNotification", null);
        });

        this.calendarDayStatusComboBox = MUIOutlet(this, 'bclv_day_status','MUIComboBox');
        this.initCalendarDayStatus();

        this.calendarView = MUIOutlet(this, "calendar-view", "MUICalendarView");
        this.calendarView.dataSource = this;
        this.calendarView.delegate = this;

        let today = MIODateToday();
        this.calendarView.setMonthAndYear(MIODateGetMonthFromDate(today), MIODateGetYearFromDate(today));

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        
        this.selectDay(today);
        this.calendarView.selectDayCellAtDate(today);

        // this.selectedDay = MIODateToday();
        // this.selectedDayString = this.sdf.stringFromDate(this.selectedDay);

        // let days = this.calendarFetchedResultsController.resultObjects;
        // this.mapDays(days);   

        this.tableView.reloadData();
        //this.selectDay(this.selectedDay);
        //this.showBookingsForDate();
    }

    private initCalendarDayStatus(){
        this.calendarDayStatusComboBox.removeAllItems();
        this.calendarDayStatusComboBox.addItem(MIOLocalizeString('OPEN', 'open'), 'open');
        this.calendarDayStatusComboBox.addItem(MIOLocalizeString('CLOSED', 'closed'), 'closed');
        this.calendarDayStatusComboBox.addItem(MIOLocalizeString('RESERVED','Reserved'), 'reserved');
        this.calendarDayStatusComboBox.addItem(MIOLocalizeString('BLOCKED','Blocked'), 'blocked');
        this.calendarDayStatusComboBox.selectItem('open');        
    }

    private showBookingsForDate(){
        //this.parentViewController.parent.detailViewController.rootViewController.date = this.date;
        MIONotificationCenter.defaultCenter().postNotification("BookingDayDidChange", this.selectedDay);
    }

    private showClientSearchViewController(){
        
        let vc = new BookingsClientSearchViewController("bookings_client_search_view");
        vc.initWithResource("layout/bookings/bookings/BookingsClientSearchView.html");
        vc.delegate = this;

        let nc = new MUINavigationController('bookking_search_nav');
        nc.initWithRootViewController(vc);

        this.presentViewController(nc, true);
    }

    didSelectDayCellAtDate(calendar:MUICalendarView, date) {            
        this.selectDay(date);
        //this.parentViewController.parent.detailViewController.popToRootViewController(true);
    }

    // Calendar fetch
    private bookingDays = {};
    private _calendarFetchedResultsController:MIOFetchedResultsController = null;
    set calendarFetchedResultsController(value:MIOFetchedResultsController){
        if (value == null && this._calendarFetchedResultsController != null)
        this._calendarFetchedResultsController.delegate = null;

        this._calendarFetchedResultsController = value;
    }

    get calendarFetchedResultsController(){
        if (this._calendarFetchedResultsController != null)
            return this._calendarFetchedResultsController;
        
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('identifier', true)];        
        
        let firstDay = MIODateGetFirstDateOfTheMonth(this.selectedDay.getMonth(), this.selectedDay.getFullYear());
        let lastDay = MIODateGetLastDateOfTheMonth(this.selectedDay.getMonth(), this.selectedDay.getFullYear());
        
        let predicate = "identifier >= '" + MIODateGetDateString(firstDay) + "' AND identifier <= '" + MIODateGetDateString(lastDay) + "'";
    
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("BookingDay", sortDescriptors, predicate);
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, this.appDelegate.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._calendarFetchedResultsController = fetchedResultsController;
        return this._calendarFetchedResultsController;
    }

    numberOfSections(tableview){
        return this.zonesFetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview:MUITableView, section){
        var sec = this.zonesFetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath){        
        let zone:BookingZone = this.zonesFetchedResultsController.objectAtIndexPath(indexPath);

        let cell:BookingCalendarZoneCell = (zone.parent == null) ? 
                tableview.dequeueReusableCellWithIdentifier('BookingCalendarZoneCell') as BookingCalendarZoneCell:
                tableview.dequeueReusableCellWithIdentifier('BookingCalendarSubZoneCell') as BookingCalendarZoneCell;
        
        let day = this.bookingDays[this.selectedDayString];
        let zoneInfo = this.bookingsZonesInfo[zone.identifier];
        cell.setZoneInfo(zoneInfo, zone);
        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
        cell.selectionStyle = MUITableViewCellSelectionStyle.None;
        
        return cell;
    }

    get entityName(){
        return 'BookingZone';
    }

    canSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        return false;
    }

    private bookingZonesMaxPAX = null;
    private _zonesFetchedResultsController:MIOFetchedResultsController = null;
    get zonesFetchedResultsController(){
        if (this._zonesFetchedResultsController != null)
            return this._zonesFetchedResultsController;        

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('indexPathString', true)];                                
    
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("BookingZone", sortDescriptors, null);
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, this.appDelegate.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._zonesFetchedResultsController = fetchedResultsController;

        return this._zonesFetchedResultsController;
    }

    private bookingsStatusInfo = {};
    private _bookingFetchedResultsController:MIOFetchedResultsController = null;
    get bookingFetchedResultsController(){
        if (this._bookingFetchedResultsController != null)
            return this._bookingFetchedResultsController;        

        // let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('name', true),
        //                         MIOSortDescriptor.sortDescriptorWithKey('clientName', true)];
    
        let predicate = "day == '" + this.selectedDayString + "'";

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Booking", null, predicate);
        fetchRequest.relationshipKeyPathsForPrefetching = ['client'];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, this.appDelegate.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._bookingFetchedResultsController = fetchedResultsController;
        return this._bookingFetchedResultsController;
    }  
    
    private resetBookingFetchedResultsController(){
        if (this._bookingFetchedResultsController != null)
            this._bookingFetchedResultsController.delegate = null;

        this._bookingFetchedResultsController = null;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController) {        
        // if (controller === this.calendarFetchedResultsController) {
        //     // this.mapDays(controller.resultObjects);  
        //     // this.updateSelectedDayStatus();          
        //     // this.tableView.reloadData();
        // }
        if (controller === this.zonesFetchedResultsController){            
            this.mapZones(controller.resultObjects);
            this.resetBookingFetchedResultsController();
            let bookings = this.bookingFetchedResultsController.resultObjects;
            this.mapBookings(bookings);
            this.tableView.reloadData();
        }
        // else if (controller === this.bookingFetchedResultsController){
        //     this.mapZones(this.zonesFetchedResultsController.resultObjects);
        //     this.mapBookings(controller.resultObjects);
        //     this.tableView.reloadData();
        //     //this.showBookingsForDate();            
        // }
    }

    //
    // Custom
    //

    private bookingsZonesInfo = {};
    private mapZones(zones){
        this.bookingsZonesInfo = {};
        for (let index = 0; index < zones.count; index++){
            let z:BookingZone = zones[index];

            let zoneInfo = this.bookingsZonesInfo[z.identifier];
            if (zoneInfo == null) {
                zoneInfo = {};
            }
            zoneInfo["Name"] = z.name;            
            zoneInfo["MaxPAX"] = 0; //z.maxPax;
            zoneInfo["PAX"] = 0;
            zoneInfo["RequestPAX"] = 0;

            // Add max Pax to parent
            // if (z.parent != null){
            //     zoneInfo["ParentZoneID"] = z.parent.identifier;
            // }
            // else {
            //     let maxpax = 0;
            //     for (let i = 0; i < z.subZones.count; i++){
            //         let sz:BookingZone = z.subZones.objectAtIndex(i);
            //         maxpax += sz.maxPax;
            //     }
            //     zoneInfo["MaxPAX"] = maxpax;
            // }

            this.bookingsZonesInfo[z.identifier] = zoneInfo;
        }
    }

    private mapDays(days){
        for (let index = 0; index < days.count; index++){
            let day = days[index];
            this.mapDay(day);
        }        
    }

    private mapDay(day:BookingDay){        
        let dayString = day.identifier;
        this.bookingDays[dayString] = day;
        //day.updateOccupation();
        this.updateCellAtDay(dayString, day);        
    }

    private updateCellAtDay(dayString, day:BookingDay){

        let date = this.sdf.dateFromString(dayString + " 00:00:00");
        let cell = this.calendarView.cellDayAtDate(date);
        //if (cell != null) this.configureCellWithDate(cell, date, day);
    }

    private updateSelectedDayStatus(){
        // let day:BookingDay = this.bookingDays[this.selectedDayString];
        // if (day == null) return;

        // this.calendarDayStatusComboBox.selectItem(day.status);
    }

    private selectDay(date){
                
        this.selectedDay = date;
        this.selectedDayString = this.sdf.stringFromDate(date);

        this.updateSelectedDayStatus();

        this.resetBookingFetchedResultsController();
        this.mapZones(this.zonesFetchedResultsController.resultObjects);
        this.mapBookings(this.bookingFetchedResultsController.resultObjects);
        this.tableView.reloadData();

        this.showBookingsForDate();
    }

    private mapBookings(bookings){
        if (this.bookingsZonesInfo == null) return;

        for (let index = 0; index < bookings.count; index++){
            let b:Booking = bookings[index];

            if (b.status != BookingStatus.BookingRequest 
                && b.status != BookingStatus.BookingAssigned 
                && b.status != BookingStatus.BookingReserved
                && b.status != BookingStatus.BookingConfirmed
                && b.status != BookingStatus.BookingFinished
                && b.status != BookingStatus.BookingNoShow
                && b.status != BookingStatus.BookingShow){
                    continue;
            }

            let zoneInfo = this.bookingsZonesInfo[b.bookingZoneID];
            if (zoneInfo == null) continue;

            let parentZoneInfo = null;
            let parentZoneID = zoneInfo["ParentZoneID"];                
            if (parentZoneID != null) parentZoneInfo = this.bookingsZonesInfo[parentZoneID];

            if (b.status == BookingStatus.BookingRequest) {
                let pax = zoneInfo["RequestPAX"];
                pax += b.pax;
                zoneInfo["RequestPAX"] = pax;

                if (parentZoneInfo != null) {
                    let ppax = parentZoneInfo["RequestPAX"];     
                    ppax += b.pax;
                    parentZoneInfo["RequestPAX"] = ppax;
                }
            }
            else if (b.status == BookingStatus.BookingAssigned 
                || b.status == BookingStatus.BookingReserved
                || b.status == BookingStatus.BookingConfirmed
                || b.status == BookingStatus.BookingFinished
                || b.status == BookingStatus.BookingNoShow
                || b.status == BookingStatus.BookingShow
                || b.status == BookingStatus.BookingSitting){

                let pax = zoneInfo["PAX"];
                pax += b.pax;
                zoneInfo["PAX"] = pax;                    

                if (parentZoneInfo != null) {                    
                    let ppax = parentZoneInfo["PAX"];    
                    ppax += b.pax;
                    parentZoneInfo["PAX"] = ppax;                    
                }    
            }
        }        

    }

    bookingDidSelected(booking:Booking){
        MIONotificationCenter.defaultCenter().postNotification("ShowBookingDetail", booking);
    }

    clientDidSelected(client:Client){
        
        if (client == null) {
            AppHelper.showErrorMessage(this, "ERROR", "NO SELECTED CLIENT");
            return;            
        }

        const moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        let booking:Booking = MIOEntityDescription.insertNewObjectForEntityForName("Booking", moc) as Booking;        

        booking.client = client;
        booking.bookingName = client.name;
        booking.date = this.sdf.dateFromString(this.selectedDayString);
        booking.day = this.selectedDayString;
        booking.clientName = client.name;
        booking.clientEmail = client.email;
        booking.clientPhone =  client.mobilePhone ? client.mobilePhone.number : null;
        booking.duration = SettingsHelper.sharedInstance().config["booking-default-duration"] ?? 120;

        booking.status = BookingStatus.BookingRequest;
        booking.businessAreaID = AppHelper.sharedInstance().defaultBusinessArea.identifier;

        moc.save();

        MIONotificationCenter.defaultCenter().postNotification("ShowBookingDetail", booking);
    }

    protected workspaceDidChange(){
        this.calendarFetchedResultsController = null;
        let days = this.calendarFetchedResultsController.resultObjects;
        this.mapDays(days);
    }
}

