

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class PresetGroup_ManagedObject

class PresetGroup_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: needPermissionToChangePreset
    set needPermissionToChangePreset(value:boolean) {
        this.setValueForKey(value, 'needPermissionToChangePreset');
    }
    get needPermissionToChangePreset():boolean {
        return this.valueForKey('needPermissionToChangePreset');
    }
    set needPermissionToChangePresetPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'needPermissionToChangePreset');
    }
    get needPermissionToChangePresetPrimitiveValue():boolean {
        return this.primitiveValueForKey('needPermissionToChangePreset');
    }

    // Relationship: favouritePresetRelations
    protected _favouritePresetRelations:MIOManagedObjectSet = null;
    get favouritePresetRelations():MIOManagedObjectSet {
        return this.valueForKey('favouritePresetRelations');
    }
    addFavouritePresetRelationsObject(value:PresetRelation) {
        this._addObjectForKey(value, 'favouritePresetRelations');
    }
    removeFavouritePresetRelationsObject(value:PresetRelation) {
        this._removeObjectForKey(value, 'favouritePresetRelations');
    }

    // Relationship: menuPresetRelations
    protected _menuPresetRelations:MIOManagedObjectSet = null;
    get menuPresetRelations():MIOManagedObjectSet {
        return this.valueForKey('menuPresetRelations');
    }
    addMenuPresetRelationsObject(value:PresetRelation) {
        this._addObjectForKey(value, 'menuPresetRelations');
    }
    removeMenuPresetRelationsObject(value:PresetRelation) {
        this._removeObjectForKey(value, 'menuPresetRelations');
    }

    // Relationship: productPresetRelations
    protected _productPresetRelations:MIOManagedObjectSet = null;
    get productPresetRelations():MIOManagedObjectSet {
        return this.valueForKey('productPresetRelations');
    }
    addProductPresetRelationsObject(value:PresetRelation) {
        this._addObjectForKey(value, 'productPresetRelations');
    }
    removeProductPresetRelationsObject(value:PresetRelation) {
        this._removeObjectForKey(value, 'productPresetRelations');
    }
}
