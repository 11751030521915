
class PaymentEntityCell extends UITableViewCell
{
    private nameTextField:MUITextField = null;
    private typeLabel:MUILabel = null;

    awakeFromHTML(){
        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.nameTextField.setOnChangeText(this, function(textField:MUITextField, value:string) {
            this._item.name = value;
        });

        this.typeLabel = MUIOutlet(this, "type-lbl", "MUILabel");        

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    } 
    
    protected _item:PaymentEntity = null;
    setItem(item:PaymentEntity){        
        this._item = item;
        this.nameTextField.text = item.name;
        if (this.typeLabel) {
            let type_string = PaymentEntity.typeStringForType(item.paymentType);
            this.typeLabel.text =  (item instanceof ProxyPaymentEntity) ? "Proxy:" +  type_string : type_string;
        }
    }

    set item(item:PaymentEntity) {
        this.setItem(item);
    }

}