
class PresetItemDetailCell extends MUICollectionViewCell
{
    private nameLabel:MUILabel = null;
    private settingsButton:MUIButton = null;
    private imageView:MUIImageView = null;

    awakeFromHTML()
    {
        this.nameLabel = MUIOutlet(this, "product-lbl", "MUILabel");
        this.settingsButton = MUIOutlet(this, "product-settings-btn", "MUIButton");
        this.settingsButton.setAction(this, function (control, index){
            this.showCellSettingsPreset();
        })
        this.imageView = MUIOutlet(this, 'product-img','MUIImageView');
    }

    private _presetItem:PresetItem = null;
    set item(i:PresetItem)
    {
        //SWITCH between PresetCategory, PresetProduct, PresetMenu

        if (!i) return;

        this._presetItem = i;

        if (i instanceof PresetProduct) {
            let p = i as PresetProduct;
            if(p.product?.name)
                this.nameLabel.text = p.product.name;

        } 
        else if ( i instanceof PresetMenu) {
            this.nameLabel.text = "Menu " + i.orderIndex;
        } 
        else if (i instanceof PresetCategory) {
            let c = i as PresetCategory;
            this.nameLabel.text = c.name;
        } 
        else if (i instanceof PresetBlank) {

            this.nameLabel.text = "Blank " + i.orderIndex;

        } else if (i instanceof PresetItem) {

            this.nameLabel.text = "PresetItem " + i.orderIndex;
            
        } else {
            //should not get here.
            console.error("Didn't select any preset object");
        }


        // switch(i.className){
        //     case 'PresetProduct':
        //         this.nameLabel.text = "Product" + i.orderIndex;
        //     break;

        //     case 'PresetCategory':
        //         this.nameLabel.text = "Category" + i.orderIndex;
        //     break;

        //     case PresetMenu:
        //         this.nameLabel.text = "Menu" + i.orderIndex;
        //     break;

        //     case PresetItem:
        //         this.nameLabel.text = "PresetItem" + i.orderIndex;
        //     break;
        // }

        //more cell properties here
        // if (i.orderIndex){
        //     this.nameLabel.text = i.orderIndex;
        // }
        
        if (i.presetCategory ? (i.presetCategory.image ? true: false) : false) {
            this.imageView.setImage(i.presetCategory.image);
        }
        
    }

    private showCellSettingsPreset() {
       
        
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('PRESET OPTIONS',"PRESET OPTIONS"), MIOLocalizeString('CHOOSE AN ACTION',"CHOOSE AN ACTION"), MUIAlertViewStyle.Default);    

        // avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
        //     textField.setPlaceholderText(MIOLocalizeString("NAME", "NAME"));
        // });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('MOVE PRESET','MOVE PRESET'), MUIAlertActionStyle.Default, this, function(){                        
           
            alert("move action");

        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DELETE PRESET','DELETE PRESET'), MUIAlertActionStyle.Cancel, this, function(){                        
            
            if (this._presetItem)
                DBHelper.deleteObjectFromMainContext(this._presetItem, true);

        }));


        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL',"CANCEL"), MUIAlertActionStyle.Cancel, null, null));
        AppHelper.sharedInstance().presentViewController( avc, true );

    }

}