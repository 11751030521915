class OnlinePresetsConfigurationsViewController extends MUIViewController
{        
    private backButton:MUIButton = null;
    private titleLabel:MUILabel = null;
    private addButton:MUIButton = null;
    private businessAreaButton:MUIButton = null;
    private tableView:UITableView = null;


    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");
        this.backButton.setAction(this, function(control){
            this.navigationController.popViewController(true);
        });

        this.titleLabel = MUIOutlet(this, "section-title-lbl", "MUILabel");

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function(){
            this.showAddOnlineConfigurationsViewController();
        });

        this.businessAreaButton = MUIOutlet(this, "business-area-btn", "MUIButton");
        this.businessAreaButton.setAction(this, function(){
            alert("Business area button");
        });

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(animate?) {
        super.viewWillAppear(animate);        
        this.updateUI();
    }

    private _configurationType:TimeRangePresetConfigurationType = null;
    set configurationType (item:TimeRangePresetConfigurationType){
        this._configurationType = item;
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        if(this._configurationType == TimeRangePresetConfigurationType.Takeaway) {
            this.titleLabel.text = MIOLocalizeString("TAKEAWAY PRESET CONFIGURATIONS","TAKEAWAY PRESET CONFIGURATIONS");

        } else if(this._configurationType == TimeRangePresetConfigurationType.Delivery) {
            this.titleLabel.text = MIOLocalizeString("DELIVERY PRESET CONFIGURATIONS","DELIVERY PRESET CONFIGURATIONS");
        }

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    titleForHeaderInSection(tableView:UITableView, section:number) {}
    
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){        

        let cell = tableView.dequeueReusableCellWithIdentifier("OnlinePresetConfigCell") as OnlinePresetConfigCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as any;   //change any to the proper type                   
        cell.item = item;
        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){      
        //cell selection
    }

    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            this.removeTimeRangePresetConfiguration(item);
        }
    }

    private _fetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let fetchRequest = null;
        if(this._configurationType == TimeRangePresetConfigurationType.Takeaway) {
            fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('TakeAwayTimeRangePresetConfiguration');
        } else if(this._configurationType == TimeRangePresetConfigurationType.Delivery) {
            fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('DeliveryTimeRangePresetConfiguration');
        }
    
        //fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('section', true)];
        fetchRequest.predicate = MIOPredicate.predicateWithFormat("deletedAt == null");
        fetchRequest.relationshipKeyPathsForPrefetching = ["timeRangePreset", "businessArea"];
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }

    private addOnlineConfigurationsViewController = null;
    private showAddOnlineConfigurationsViewController() {
        if(this.addOnlineConfigurationsViewController == null) {
            this.addOnlineConfigurationsViewController = new AddOnlineConfigurationsViewController('add-online-configuration-view');            
            this.addOnlineConfigurationsViewController.initWithResource('layout/onlinesettings/AddOnlineConfigurationView.html');
            this.addOnlineConfigurationsViewController.delegate = this;
            this.addOnlineConfigurationsViewController.configurationType = this._configurationType;
        }
        this.presentViewController(this.addOnlineConfigurationsViewController, true);
    }

    private removeTimeRangePresetConfiguration(item:TimeRangePresetConfiguration) {
        let moc:MIOManagedObjectContext = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        moc.deleteObject(item);           
        moc.save();        
    }

}