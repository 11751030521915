//
// Generated class MapLocationCategory
//

/// <reference path="MapLocationCategory_ManagedObject.ts" />

class MapLocationCategory extends MapLocationCategory_ManagedObject
{

}
