

/// <reference path="Loan.ts" />

// Generated class LegalEntityLoan_ManagedObject

class LegalEntityLoan_ManagedObject extends Loan
{

    // Property: legalEntityName
    set legalEntityName(value:string) {
        this.setValueForKey(value, 'legalEntityName');
    }
    get legalEntityName():string {
        return this.valueForKey('legalEntityName');
    }
    set legalEntityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalEntityName');
    }
    get legalEntityNamePrimitiveValue():string {
        return this.primitiveValueForKey('legalEntityName');
    }
    // Relationship: legalEntity
    set legalEntity(value:LegalEntity) {
        this.setValueForKey(value, 'legalEntity');
    }
    get legalEntity():LegalEntity {
        return this.valueForKey('legalEntity') as LegalEntity;
    }
}
