//
// Generated class ArchivedTicket
//

/// <reference path="ArchivedTicket_ManagedObject.ts" />

class ArchivedTicket extends ArchivedTicket_ManagedObject
{
    get zoneString():string {
        let zones = []
        if (this.locationCategoryName != null) zones.addObject(this.locationCategoryName);
        if (this.locationName != null) zones.addObject(this.locationName);

        return zones.length > 0 ? zones.join(" ") : null;
    }
}
