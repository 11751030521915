

/// <reference path="EmployeePayrollConcept.ts" />

// Generated class EmployeeSalaryConcept_ManagedObject

class EmployeeSalaryConcept_ManagedObject extends EmployeePayrollConcept
{

    // Property: amount
    set amount(value:number) {
        this.setValueForKey(value, 'amount');
    }
    get amount():number {
        return this.valueForKey('amount');
    }
    set amountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'amount');
    }
    get amountPrimitiveValue():number {
        return this.primitiveValueForKey('amount');
    }
    // Relationship: reconcileTransaction
    set reconcileTransaction(value:SalaryReconcileTransaction) {
        this.setValueForKey(value, 'reconcileTransaction');
    }
    get reconcileTransaction():SalaryReconcileTransaction {
        return this.valueForKey('reconcileTransaction') as SalaryReconcileTransaction;
    }
}
