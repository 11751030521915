//
// Generated class DBSyncTransaction
//

/// <reference path="DBSyncTransaction_ManagedObject.ts" />

class DBSyncTransaction extends DBSyncTransaction_ManagedObject
{

}
