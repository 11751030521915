

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ProductCategory_ManagedObject

class ProductCategory_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: grouping
    set grouping(value:number) {
        this.setValueForKey(value, 'grouping');
    }
    get grouping():number {
        return this.valueForKey('grouping');
    }
    set groupingPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'grouping');
    }
    get groupingPrimitiveValue():number {
        return this.primitiveValueForKey('grouping');
    }

    // Property: imageID
    set imageID(value:string) {
        this.setValueForKey(value, 'imageID');
    }
    get imageID():string {
        return this.valueForKey('imageID');
    }
    set imageIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'imageID');
    }
    get imageIDPrimitiveValue():string {
        return this.primitiveValueForKey('imageID');
    }

    // Property: invitationValue
    set invitationValue(value:number) {
        this.setValueForKey(value, 'invitationValue');
    }
    get invitationValue():number {
        return this.valueForKey('invitationValue');
    }
    set invitationValuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'invitationValue');
    }
    get invitationValuePrimitiveValue():number {
        return this.primitiveValueForKey('invitationValue');
    }

    // Property: isAvailable
    set isAvailable(value:boolean) {
        this.setValueForKey(value, 'isAvailable');
    }
    get isAvailable():boolean {
        return this.valueForKey('isAvailable');
    }
    set isAvailablePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isAvailable');
    }
    get isAvailablePrimitiveValue():boolean {
        return this.primitiveValueForKey('isAvailable');
    }

    // Property: kitchenColor
    set kitchenColor(value:number) {
        this.setValueForKey(value, 'kitchenColor');
    }
    get kitchenColor():number {
        return this.valueForKey('kitchenColor');
    }
    set kitchenColorPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'kitchenColor');
    }
    get kitchenColorPrimitiveValue():number {
        return this.primitiveValueForKey('kitchenColor');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: showInBar
    set showInBar(value:boolean) {
        this.setValueForKey(value, 'showInBar');
    }
    get showInBar():boolean {
        return this.valueForKey('showInBar');
    }
    set showInBarPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'showInBar');
    }
    get showInBarPrimitiveValue():boolean {
        return this.primitiveValueForKey('showInBar');
    }

    // Property: showInOnline
    set showInOnline(value:boolean) {
        this.setValueForKey(value, 'showInOnline');
    }
    get showInOnline():boolean {
        return this.valueForKey('showInOnline');
    }
    set showInOnlinePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'showInOnline');
    }
    get showInOnlinePrimitiveValue():boolean {
        return this.primitiveValueForKey('showInOnline');
    }

    // Property: showInTables
    set showInTables(value:boolean) {
        this.setValueForKey(value, 'showInTables');
    }
    get showInTables():boolean {
        return this.valueForKey('showInTables');
    }
    set showInTablesPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'showInTables');
    }
    get showInTablesPrimitiveValue():boolean {
        return this.primitiveValueForKey('showInTables');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }
    // Relationship: image
    set image(value:DBImage) {
        this.setValueForKey(value, 'image');
    }
    get image():DBImage {
        return this.valueForKey('image') as DBImage;
    }

    // Relationship: productModifierCategories
    protected _productModifierCategories:MIOManagedObjectSet = null;
    get productModifierCategories():MIOManagedObjectSet {
        return this.valueForKey('productModifierCategories');
    }
    addProductModifierCategoriesObject(value:ProductModifierCategory) {
        this._addObjectForKey(value, 'productModifierCategories');
    }
    removeProductModifierCategoriesObject(value:ProductModifierCategory) {
        this._removeObjectForKey(value, 'productModifierCategories');
    }

    // Relationship: products
    protected _products:MIOManagedObjectSet = null;
    get products():MIOManagedObjectSet {
        return this.valueForKey('products');
    }
    addProductsObject(value:Product) {
        this._addObjectForKey(value, 'products');
    }
    removeProductsObject(value:Product) {
        this._removeObjectForKey(value, 'products');
    }

    // Relationship: subCategories
    protected _subCategories:MIOManagedObjectSet = null;
    get subCategories():MIOManagedObjectSet {
        return this.valueForKey('subCategories');
    }
    addSubCategoriesObject(value:ProductCategory) {
        this._addObjectForKey(value, 'subCategories');
    }
    removeSubCategoriesObject(value:ProductCategory) {
        this._removeObjectForKey(value, 'subCategories');
    }
    // Relationship: superCategory
    set superCategory(value:ProductCategory) {
        this.setValueForKey(value, 'superCategory');
    }
    get superCategory():ProductCategory {
        return this.valueForKey('superCategory') as ProductCategory;
    }
    // Relationship: tag
    set tag(value:CategoryTag) {
        this.setValueForKey(value, 'tag');
    }
    get tag():CategoryTag {
        return this.valueForKey('tag') as CategoryTag;
    }
    // Relationship: tax
    set tax(value:Tax) {
        this.setValueForKey(value, 'tax');
    }
    get tax():Tax {
        return this.valueForKey('tax') as Tax;
    }
}
