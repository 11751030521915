//
// Generated class CashOnDeliveryPaymentEntity
//

/// <reference path="CashOnDeliveryPaymentEntity_ManagedObject.ts" />

class CashOnDeliveryPaymentEntity extends CashOnDeliveryPaymentEntity_ManagedObject
{

}
