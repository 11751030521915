

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class OfferProduct_ManagedObject

class OfferProduct_ManagedObject extends CoreEntityUpdatedBy
{
    // Relationship: category
    set category(value:ProductCategory) {
        this.setValueForKey(value, 'category');
    }
    get category():ProductCategory {
        return this.valueForKey('category') as ProductCategory;
    }
    // Relationship: format
    set format(value:Format) {
        this.setValueForKey(value, 'format');
    }
    get format():Format {
        return this.valueForKey('format') as Format;
    }
    // Relationship: menu
    set menu(value:Menu) {
        this.setValueForKey(value, 'menu');
    }
    get menu():Menu {
        return this.valueForKey('menu') as Menu;
    }
    // Relationship: offer
    set offer(value:Offer) {
        this.setValueForKey(value, 'offer');
    }
    get offer():Offer {
        return this.valueForKey('offer') as Offer;
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
}
