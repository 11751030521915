
/// <reference path="../external/Weekdays.ts" />


class PlaceSettingFirstDayCell extends MUITableViewCell
{
    private weekdaysComboBox:MUIComboBox = null;
    
    private _separator = null;
    private _item = null;

    awakeFromHTML()
    {
        this.weekdaysComboBox = MUIOutlet(this, 'psv_weekdays_cb', 'MUIComboBox');
        this.weekdaysComboBox.setOnChangeAction(this,function(combobox, value)
        {
            this._item.setValueForKey('firstDayOfWeek',value == '-1' ? null : parseInt(value));
        });
        this._separator = MUIOutlet(this, 'psv_firstday_separator', 'MUIView');
    }



    set item(i)
    {
        this._item = i;

        this.weekdaysComboBox.removeAllItems();
        if(this._item != null && this._item.firstDayOfWeek == null){
            this.weekdaysComboBox.addItem(MIOLocalizeString('SELECT','Select...'), -1);
        }
        this.weekdaysComboBox.addItem(MIOLocalizeString('MONDAY','Monday'), 0);
        this.weekdaysComboBox.addItem(MIOLocalizeString('SUNDAY','Sunday'), 1);
        
        if(this._item != null) 
            this.weekdaysComboBox.selectItem(this._item.firstDayOfWeek);
    }
}
