/// <reference path="WebService.ts" />

interface WebService
{    
    nullifyDocument( doc:ArchivedDocument, completion:any ) : void;
    createInvoiceFromTicket( docID:string, client:Client, completion:any ) : void;
    changeClientInvoice(invoice:ArchivedInvoice, client:Client, completion:any) : void;
    createInvoiceFromMultipleTickets(tickets:ArchivedTicket[], client:Client, date:Date, numberSeries:NumberSerialSequence, nullifyNumberSeries:NumberSerialSequence, completion:any) : void;
    changeArchivedDocumentClient(doc:ArchivedDocument, client:Client, completion:any) : void;
}

WebService.prototype.nullifyDocument = function( doc:ArchivedDocument, completion:any ) {
    if ( this.lock_doc_id.containsObject( doc.identifier ) ) return;
    this.lock_doc_id.addObject( doc.identifier );

    let en = doc instanceof ArchivedInvoice ? "ArchivedInvoice": "ArchivedTicket";
    const url = MIOURL.urlWithString(this.apiURL + "/entity/" + this.scheme + "/" + en + "/" + doc.identifier + "/nullify");
    let urlRequest = new MWSJSONRequest();
    urlRequest.initWithURL(url, {"recoverStock": false}, "POST");
    urlRequest.execute(this, function(code:number, json:any){
        this.lock_doc_id.removeObject(doc.identifier);
        if ( code == 200 && ( json["status"] == 0 || json["status"]?.toLowerCase() == "ok") ) completion(true, json, null);
        else completion(false, json, json["error"]);
    });
}


WebService.prototype.createInvoiceFromTicket = function( docID:string, client:Client, completion:any ) {
    if ( this.lock_doc_id.containsObject(docID) ) return;
    this.lock_doc_id.addObject(docID);

    const url = this.apiURL + "/entity/" + this.scheme + "/ArchivedTicket/" + docID + "/generate-invoice";
    let r = new DLRequest( url, { "clientID": client.identifier }, "POST" );
    r.execute( (json:any, error:string) => {
        this.lock_doc_id.removeObject(docID);
        completion(json, error);
    });
}

WebService.prototype.createInvoiceFromMultipleTickets = function(tickets:ArchivedTicket[], client:Client, date:Date, numberSeries:NumberSerialSequence, nullifyNumberSeries:NumberSerialSequence, completion:any) {
    
    for (let t of tickets) {
        if ( this.lock_doc_id.containsObject(t.identifier) ) return;
    }
    
    for (let t of tickets) { this.lock_doc_id.addObject(t.identifier); }

    const url = this.apiURL + "/entity/" + this.scheme + "/ArchivedInvoice/generate-invoice-from-multiple-tickets";        
    let body = { "clientID": client.identifier, "tickets" : [] };
    for ( let t of tickets ) {
        body["tickets"].push (t.identifier);
    }
    if (date != null ) {
        body[ "date" ] = (MUIWebApplication.sharedInstance().delegate as AppDelegate).serverDateTimeFormatter.stringFromDate( date );
    }
    if ( numberSeries != null ) {
        body[ "numberSeriesID" ] = numberSeries.identifier;
    }
    if ( nullifyNumberSeries != null ) {
        body[ "nullifyNumberSeriesID" ] = nullifyNumberSeries.identifier;
    }
    
    let r = new DLRequest( url, body, "POST" );
    r.execute( (json:any, error:string) => {
        for (let t of tickets) { this.lock_doc_id.addObject(t.identifier); }        
         completion(json, error);
    });

}    

WebService.prototype.changeClientInvoice = function( invoice:ArchivedInvoice, client:Client, completion:any ) {
    const url = this.apiURL + "/entity/" + this.scheme + "/ArchivedInvoice/" + invoice.identifier + "/change-client/" + client.identifier;
    const r = new DLRequest(url);
    r.execute(completion);
}

WebService.prototype.changeArchivedDocumentClient = function( doc:ArchivedDocument, client:Client, completion:any ) {
    const url = this.apiURL + "/entity/" + this.scheme + "/ArchivedDocument/" + doc.identifier + "/change-client/" + client.identifier;
    const r = new DLRequest(url);
    r.execute(completion);
}