

/// <reference path="ProductDetailGeneralViewController.ts" />
/// <reference path="../AppHelper.ts" />
/// <reference path="../base/EntityListViewController.ts" />


class ProductSupplierViewController extends EntityListViewController
{        
    private defaultSupplierDropdown:MUIButton = null;

    private cf:MIONumberFormatter = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    private moc:MIOManagedObjectContext = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

    viewDidLoad(){
        super.viewDidLoad();        

        this.defaultSupplierDropdown = MUIOutlet( this, "default-supplier-dd", "MUIButton" );
        this.defaultSupplierDropdown.setAction( this, function( this:ProductSupplierViewController ) {
            AppHelper.sharedInstance().showSelectSupplierViewControllerFromView( this.defaultSupplierDropdown, null, true, this, function( this:ProductSupplierViewController, controller:SelectViewController, supplier:Supplier ){
                this._product.defaultSupplier = supplier;
                this._product.defaultSupplierName = supplier?.name;
                this.defaultSupplierDropdown.title = supplier?.name;
            } ); 
        });

        this.searchTextField = MUIOutlet(this, 'search-bar','MUITextField');
        this.setupSearchTextField();
        
        this.tableView = MUIOutlet(this, 'table-view', 'UITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();        
    }

    private _product:Product = null;
    set product( value:Product ){
        this._product = value;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._product == null) return;

        this.defaultSupplierDropdown.title = this._product.defaultSupplierName;

        this.needsReloadData();
    }

    numberOfSections(tableView:UITableView){
        let sections = this.fetchedResultsController.sections.length;        
        return sections + 1;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        if (section == this.fetchedResultsController.sections.length) return 1;

        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){        
        let cell:ProductSupplierCell = null;
        
        if (indexPath.section == this.fetchedResultsController.sections.length) {            
            cell = tableView.dequeueReusableCellWithIdentifier('AddCell') as ProductSupplierCell;            
            cell.product = this._product;
            return cell;
        }
        
        cell = tableView.dequeueReusableCellWithIdentifier('EditCell') as ProductSupplierCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as SupplierProduct;
    
        cell.product = this._product;
        cell.item = item;        

        return cell;
    }
        
    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {
        if (indexPath.section == this.fetchedResultsController.sections.length) {
            return UITableViewCellEditingStyle.Insert;
        }
        
        return UITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        
        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            let supplierProduct = this.fetchedResultsController.objectAtIndexPath(indexPath);
            this.removeSupplierProduct(supplierProduct);
        }
    }
        
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('supplier.name', true)];
        let filterString = 'product.identifier == ' + this._product.identifier;

        if(this.searchString != null)
            filterString += " AND supplier.name CONTAINS '" + this.searchString + "'";

        let fetchRequest = DBHelper.listFetchRequestWithEntityName('SupplierProduct', sortDescriptors, filterString);
        fetchRequest.relationshipKeyPathsForPrefetching = ['supplier'];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    controllerDidChangeObject(controller: MIOFetchedResultsController, object: any, indexPath: MIOIndexPath, changeType: MIOFetchedResultsChangeType, newIndexPath: MIOIndexPath)  {

        switch(changeType){
            case MIOFetchedResultsChangeType.Insert:
            this.tableView.insertRowsAtIndexPaths([newIndexPath], UITableViewRowAnimation.Automatic);
            break;

            case MIOFetchedResultsChangeType.Delete:
            this.tableView.deleteRowsAtIndexPaths([indexPath], UITableViewRowAnimation.Automatic);
            break;
        }

    }

    controllerDidChangeContent(controller){
        // this.tableView.reloadData();
    }


    private removeSupplierProduct(supplierProduct:SupplierProduct){
        DBHelper.deleteObjectFromMainContext(supplierProduct, true);        
    }
    
}
