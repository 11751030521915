
class BankAccountDetailViewController extends BaseTableViewController
{
    static newInstance() : BankAccountDetailViewController {
        let vc = new BankAccountDetailViewController("bank-account-detail-view");
        vc.initWithResource("layout/bankaccount/BankAccountDetailView.html")
        return vc;
    }
    
    private backButton:MUIButton = null;
    private saveButton:MUIButton = null;
    private importButton:MUIButton = null;
    
    private titleLabel:MUILabel = null;
    private ledgerAccountDropdown:MUIButton = null;
    private totalLabel:MUILabel = null;
    
    private accountingEntryContainer:MUIView = null;
    private accountingEntryViewController:AccountingEntryViewController = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet( this, "back-btn", "MUIButton");
        this.backButton.setAction( this, function(this:BankAccountDetailViewController) { 
            this.navigationController.popViewController(true);
        } );

        this.importButton = MUIOutlet(this, "import-btn", "MUIButton");
        this.importButton.setAction(this, function(this:BankAccountDetailViewController){
            this.importNorma43();
        });

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function() {
            DBHelper.saveMainContext();
        })

        this.titleLabel = MUIOutlet(this, "account-number-lbl", "MUILabel");

        this.ledgerAccountDropdown = MUIOutlet(this, "ledger-account-dd", "MUIButton");
        this.ledgerAccountDropdown.setAction(this, function(this:BankAccountDetailViewController){
            AppHelper.sharedInstance().showSelectLedgerAccountViewControllerFromView(this.ledgerAccountDropdown, this.account.ledgerAccount, null, false, this.account.info?.name, this, this.updateLedgerAccount);
        });


        this.totalLabel = MUIOutlet(this, "total-lbl", "MUILabel");

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.accountingEntryContainer = MUIOutlet(this, "accounting-entries-container", "MUIView");

        // this.accountingEntryViewController = AccountingEntryViewController.newInstance();
        // this.addChildViewController(this.accountingEntryViewController);
        // this.accountingEntryContainer.addSubview(this.accountingEntryViewController.view);
    }    

    viewWillAppear(){
        super.viewWillAppear();
        this.updateUI();
    }

    private account = null
    set item(item:BankAccount){
        this.account = item;
        this.updateUI();
    }

    private updateUI()
    {
        if (this.viewIsLoaded == false || this.account == null) return;

        let ad = MUIWebApplication.sharedInstance().delegate;

        this.titleLabel.text = this.account.info?.number;
        this.ledgerAccountDropdown.title = this.account.ledgerAccountName;
        this.totalLabel.text = ad.currencyFormatter.stringFromNumber(this.account.balance);

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    titleForHeaderInSection(tableView:UITableView, section:number) {
        let ip = MIOIndexPath.indexForRowInSection(0, section);
        let item = this.fetchedResultsController.objectAtIndexPath(ip) as BankMovement;

        let yy = MIODateGetYearFromDate ( item.date );
        let mm = MIODateGetStringForMonth ( MIODateGetMonthFromDate( item.date ) );

        return mm + " " + yy;
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){                                
        let cell = tableView.dequeueReusableCellWithIdentifier('BankMovementCell') as BankMovementCell;        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as BankMovement;
        
        cell.item = item;

        return cell;            
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let mv = this.fetchedResultsController.objectAtIndexPath(indexPath) as BankMovement;
        this.selectedItem = mv;
        // this.accountingDataSource.bankMovement = mv;
    }

    // private detailViewController:BankAccountDetailViewController = null;
    // didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){        
    //     if (this.detailViewController == null) {
    //         this.detailViewController = new BankAccountDetailViewController("bank-account-detail-view");
    //         this.detailViewController.initWithResource("layout/bankaccount/BankAccountDetailView.html");
    //     }

    //     this.navigationController.splitViewController.showDetailViewController(this.detailViewController);
    // }
    
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [
            MIOSortDescriptor.sortDescriptorWithKey('date', false),
            MIOSortDescriptor.sortDescriptorWithKey('orderIndex', false)
        ];
        
        let predicateFormat = "bankAccount.identifier == " + this.account.identifier;
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("BankMovement", sortDescriptors, predicateFormat);
        // fetchRequest.relationshipKeyPathsForPrefetching = ["bankAccount"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "monthString");
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    private showActions(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ACTIONS","ACTIONS"), MIOLocalizeString("CHOOSE AN ACTION","CHOOSE AN ACTION"), MUIAlertViewStyle.Default);
 
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("SEND PAYMENT LINK","SEND PAYMENT LINK"), MUIAlertActionStyle.Default, this, function(){
            this.askForEmail();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("MAKE TRANSFER","MAKE TRANSFER"), MUIAlertActionStyle.Default, this, function(){
            this.askForBankAccount();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("MOVE TO ANOTHER ACCOUNT","MOVE TO ANOTHER ACCOUNT"), MUIAlertActionStyle.Default, this, function(){
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("IMPORT NORMA 43","IMPORT NORMA 43"), MUIAlertActionStyle.Default, this, function(this:BankAccountDetailViewController){
            this.importNorma43();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL","CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }
    
    private askForEmail(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("INFO","INFO"), MIOLocalizeString("FILL THE REQUIRED INFO","FILL THE REQUIRED INFO"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("EMAIL","EMAIL"));
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });
        
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("AMOUNT","AMOUNT"));
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("CONCEPT","CONCEPT"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("SEND","SEND"), MUIAlertActionStyle.Default, this, function(){
            let email = avc.textFields[0].text;
            let name = avc.textFields[1].text;
            let amount = avc.textFields[2].text;
            let concept = avc.textFields[3].text;

            this.sendPaymentLink(email, name, parseFloat(amount), concept);            
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL","CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private askForBankAccount(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("INFO","INFO"), MIOLocalizeString("FILL THE REQUIRED INFO","FILL THE REQUIRED INFO"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("IBAN","IBAN"));
        });
        
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("AMOUNT","AMOUNT"));
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("CONCEPT","CONCEPT"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("SEND","SEND"), MUIAlertActionStyle.Default, this, function(){
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL","CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }    

    private sendPaymentLink(email:string, name:string, amount, concept:string){

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.sendPaymentLink(email, name, amount, concept, null, this, function (code, data){
            if (code == 200) {
                AppHelper.showInfoMessage(this, MIOLocalizeString("INFO","INFO"), MIOLocalizeString("PAYMENT LINK SENT","PAYMENT LINK SENT"));
            }
            else {
                AppHelper.showErrorMessage(this, MIOLocalizeString("ERROR","ERROR"), MIOLocalizeString("ERROR SENDING EMAIL. PLEASE TRY AGAIN LATER","ERROR SENDING EMAIL. PLEASE TRY AGAIN LATER"));
            }
        });        
    }

    private importNorma43(){        
        let panel = MUIOpenPanel.openPanel();
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        panel.beginSheetModalForWindow(ad.window, this, function(this:BankAccountDetailViewController, result:number){
            if (result == MIOFileHandlingPanel.OKButton) {
               let file = panel.files[0];
               // Open the document.
               this.parseNorma43(file);
            }       
         });
    }

    private parseNorma43(file){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.importBankMovements(file, (result:boolean) => {
            MIOLog("BANK KK");
        });
    }

    private updateLedgerAccount(controller:SelectViewController, account:LedgerAccount){
        this.account.ledgerAccount = account;
        this.account.ledgerAccountName = account?.description;
        this.ledgerAccountDropdown.title = account?.description;
    }

}