
class SerialSequenceCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    private prefixTextField:MUITextField = null;
    private nextLabel:MUITextField = null;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, 'name-lbl', 'MUILabel');   
        this.prefixTextField = MUIOutlet(this, 'prefix-tf', 'MUITextField');
        this.prefixTextField.setOnChangeText(this, function(this:SerialSequenceCell, control:MUITextField, value:string){
            this.serialSequence.prefix = value;
        });
        this.nextLabel = MUIOutlet(this, 'next-lbl', 'MUILabel');

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }
    
    private serialSequence:NumberSerialSequence = null;
    set item(i:NumberSerialSequence){
        this.serialSequence = i;
        this.nameLabel.text = i.name;
        this.prefixTextField.text = i.prefix;
        this.nextLabel.text = i.sequenceNumber;
    }
}
