//
// Generated class Task
//

/// <reference path="Task_ManagedObject.ts" />

class Task extends Task_ManagedObject
{

}
