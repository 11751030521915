

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Contact_ManagedObject

class Contact_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: comments
    set comments(value:string) {
        this.setValueForKey(value, 'comments');
    }
    get comments():string {
        return this.valueForKey('comments');
    }
    set commentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'comments');
    }
    get commentsPrimitiveValue():string {
        return this.primitiveValueForKey('comments');
    }

    // Property: email
    set email(value:string) {
        this.setValueForKey(value, 'email');
    }
    get email():string {
        return this.valueForKey('email');
    }
    set emailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'email');
    }
    get emailPrimitiveValue():string {
        return this.primitiveValueForKey('email');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: phone
    set phone(value:string) {
        this.setValueForKey(value, 'phone');
    }
    get phone():string {
        return this.valueForKey('phone');
    }
    set phonePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'phone');
    }
    get phonePrimitiveValue():string {
        return this.primitiveValueForKey('phone');
    }

    // Property: phoneExtension
    set phoneExtension(value:string) {
        this.setValueForKey(value, 'phoneExtension');
    }
    get phoneExtension():string {
        return this.valueForKey('phoneExtension');
    }
    set phoneExtensionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'phoneExtension');
    }
    get phoneExtensionPrimitiveValue():string {
        return this.primitiveValueForKey('phoneExtension');
    }

    // Property: title
    set title(value:string) {
        this.setValueForKey(value, 'title');
    }
    get title():string {
        return this.valueForKey('title');
    }
    set titlePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'title');
    }
    get titlePrimitiveValue():string {
        return this.primitiveValueForKey('title');
    }
    // Relationship: legalEntity
    set legalEntity(value:LegalEntity) {
        this.setValueForKey(value, 'legalEntity');
    }
    get legalEntity():LegalEntity {
        return this.valueForKey('legalEntity') as LegalEntity;
    }
}
