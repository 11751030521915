//
// Generated class StockNoteLine
//

/// <reference path="StockNoteLine_ManagedObject.ts" />

// enum StockNoteLineType
// {     
//     NoteLine,
//     InventoryNoteLine,
//     InputLine,
//     OutputLine,
//     OutputOrderLine,
//     DeliveryNoteLine,
//     SupplierNoteLine,
//     LowStockOrderLine,
//     DiscountLine,
//     ExtraLine,

// }

enum LockedStatus 
{
    none         = 0,
    price        = 1 << 0,
    productPrice = 1 << 1,
    discount     = 1 << 2,
    total        = 1 << 3 
}

class StockNoteLine extends StockNoteLine_ManagedObject
{
    static stockTaxFromProduct(product:Product):Tax {
        if (product.taxBuy != null) return product.taxBuy;
        if (product.tax != null) return product.tax;
        // if (product.category != null && product.category.tax != null) return product.category.tax;
        return null;
    }

    static stockTaxStringFromProduct(product:Product):string{
        let tax = StockNoteLine.stockTaxFromProduct(product);
        return tax ? tax.name : null;
    }

    stockTax():Tax {
        if (this.tax != null) return this.tax;
        return StockNoteLine.stockTaxFromProduct(this.product);
    }

    stockTaxString():string{
        if (this.taxName != null) return this.taxName;
        let str = StockNoteLine.stockTaxStringFromProduct(this.product);
        if (str != null) return str;
        return MIOLocalizeString("NO TAX", "NO TAX");
    } 

    // static calculateTotalsFromQuantity( quantity:number, price: number, discount:number, total:number, price_locked:boolean, total_locked:boolean) : [number, number, number]
    // {
    //     if (total_locked == false) {
    //         total = (quantity * price) + discount;            
    //     }
    //     else if (price_locked == true){
    //         discount = total - (quantity * price);
    //     }
    //     else {
    //         price = (total + discount) / quantity;
    //     }

    //     return [price, total, discount];

    // }

    // static calculateTotalsFromPrice( price: number,  quantity:number, discount:number, total:number, total_locked:boolean) : [number, number]
    // {                
    //     if (total_locked == false) {
    //         total = (quantity * price) + discount;
    //     }
    //     else {
    //         discount = total - (quantity * price);
    //     }        

    //     return [total, discount];
    // }

    // static calculateTotalsFromDiscount( discount: number,  quantity:number, price:number, total:number, total_locked:boolean) : [number, number]
    // {                
    //     if (total_locked == true) {
    //         price = (total + discount) / quantity;            
    //     }
    //     else {
    //         total = (quantity * price) + discount;
    //     }

    //     return [price, total];
    // }

    // static calculateTotalsFromTotal( total: number, quantity:number, price:number, discount:number, price_locked:boolean) : [number, number]
    // {                
    //     if (price_locked == true){
    //         discount = total - (quantity * price);
    //     }
    //     else {
    //         price = (total + discount) / quantity; // discount is a negative value
    //     }

    //     return [price, discount];
    // }

    static calculateDiscountFromTotal(total: number, discountFormat:string) : number {
        if (discountFormat == null || discountFormat?.length == 0) return 0;
        let discount = 0;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        if ( discountFormat.hasSuffix("%") == false ) {
            discount = ad.percentNumberFormatter.numberFromString( discountFormat );
        }
        else {            
            let v = ad.percentNumberFormatter.numberFromString( discountFormat );
            discount = ( v * total ) / 100;
        }

        return discount;
    }

    //
    // Locked status: price, product price, discount, total
    // 

    static calculateValues( quantity: number, price: number, productQuantity:number, productPrice: number, discountFormat: string, total: number, lockStatus:LockedStatus) {

        let p = price;
        let npp = productPrice;
        let gpp = productPrice;
        let d = 0;
        let t = total;

        if ( lockStatus == LockedStatus.none ) {
            t = quantity * price;
            // npp = t / productQuantity;
            d = StockNoteLine.calculateDiscountFromTotal(t, discountFormat);
            t = t - d;
            gpp = t / productQuantity;
        }
        else if ( lockStatus == (LockedStatus.price) ) {
            t = quantity * price;
            npp = t / productQuantity; 
            d = StockNoteLine.calculateDiscountFromTotal(t, discountFormat);            
            t = t - d;
            gpp = t / productQuantity;
        }
        else if ( lockStatus == (LockedStatus.productPrice) ) { 
            p = ( productQuantity / quantity ) * npp;
            t = quantity * p;
            d = StockNoteLine.calculateDiscountFromTotal(t, discountFormat);
            t = t - d;            
            gpp = t / productQuantity;
        }
        else if ( lockStatus == (LockedStatus.total) ) {
            d = StockNoteLine.calculateDiscountFromTotal(t, discountFormat);
            npp = t / productQuantity;
            p = t - d;
            gpp = (t - d) / productQuantity;
        }
        else if ( lockStatus == (LockedStatus.price | LockedStatus.total) ) {
            d = t - (p * quantity);
            gpp = t / productQuantity;
        }
        else if ( lockStatus == (LockedStatus.productPrice | LockedStatus.total) ) {
            d = t - p;
            gpp = t / productQuantity;
        }

        return [p, npp, d, t, gpp];
    }

    get productStockCategoryName(): string {
        if (this.product.stockCategory != null) return this.product.stockCategory.name;
        return null;
    }

}
