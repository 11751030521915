

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class SourceMessage_ManagedObject

class SourceMessage_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: idIn
    set idIn(value:number) {
        this.setValueForKey(value, 'idIn');
    }
    get idIn():number {
        return this.valueForKey('idIn');
    }
    set idInPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'idIn');
    }
    get idInPrimitiveValue():number {
        return this.primitiveValueForKey('idIn');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: text
    set text(value:string) {
        this.setValueForKey(value, 'text');
    }
    get text():string {
        return this.valueForKey('text');
    }
    set textPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'text');
    }
    get textPrimitiveValue():string {
        return this.primitiveValueForKey('text');
    }
}
