//
// Generated class LeaveReasonLog
//

/// <reference path="LeaveReasonLog_ManagedObject.ts" />

class LeaveReasonLog extends LeaveReasonLog_ManagedObject
{

}
