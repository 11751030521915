

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Interval_ManagedObject

class Interval_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: beginHour
    set beginHour(value:number) {
        this.setValueForKey(value, 'beginHour');
    }
    get beginHour():number {
        return this.valueForKey('beginHour');
    }
    set beginHourPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'beginHour');
    }
    get beginHourPrimitiveValue():number {
        return this.primitiveValueForKey('beginHour');
    }

    // Property: beginMinute
    set beginMinute(value:number) {
        this.setValueForKey(value, 'beginMinute');
    }
    get beginMinute():number {
        return this.valueForKey('beginMinute');
    }
    set beginMinutePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'beginMinute');
    }
    get beginMinutePrimitiveValue():number {
        return this.primitiveValueForKey('beginMinute');
    }

    // Property: day
    set day(value:number) {
        this.setValueForKey(value, 'day');
    }
    get day():number {
        return this.valueForKey('day');
    }
    set dayPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'day');
    }
    get dayPrimitiveValue():number {
        return this.primitiveValueForKey('day');
    }

    // Property: endHour
    set endHour(value:number) {
        this.setValueForKey(value, 'endHour');
    }
    get endHour():number {
        return this.valueForKey('endHour');
    }
    set endHourPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'endHour');
    }
    get endHourPrimitiveValue():number {
        return this.primitiveValueForKey('endHour');
    }

    // Property: endMinute
    set endMinute(value:number) {
        this.setValueForKey(value, 'endMinute');
    }
    get endMinute():number {
        return this.valueForKey('endMinute');
    }
    set endMinutePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'endMinute');
    }
    get endMinutePrimitiveValue():number {
        return this.primitiveValueForKey('endMinute');
    }
}
