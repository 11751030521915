

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Offer_ManagedObject

class Offer_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: excludeOfferProducts
    set excludeOfferProducts(value:boolean) {
        this.setValueForKey(value, 'excludeOfferProducts');
    }
    get excludeOfferProducts():boolean {
        return this.valueForKey('excludeOfferProducts');
    }
    set excludeOfferProductsPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'excludeOfferProducts');
    }
    get excludeOfferProductsPrimitiveValue():boolean {
        return this.primitiveValueForKey('excludeOfferProducts');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: rateID
    set rateID(value:string) {
        this.setValueForKey(value, 'rateID');
    }
    get rateID():string {
        return this.valueForKey('rateID');
    }
    set rateIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'rateID');
    }
    get rateIDPrimitiveValue():string {
        return this.primitiveValueForKey('rateID');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: value
    set value(value:number) {
        this.setValueForKey(value, 'value');
    }
    get value():number {
        return this.valueForKey('value');
    }
    set valuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'value');
    }
    get valuePrimitiveValue():number {
        return this.primitiveValueForKey('value');
    }

    // Relationship: generatePromotionalCodes
    protected _generatePromotionalCodes:MIOManagedObjectSet = null;
    get generatePromotionalCodes():MIOManagedObjectSet {
        return this.valueForKey('generatePromotionalCodes');
    }
    addGeneratePromotionalCodesObject(value:GeneratePromotionalCode) {
        this._addObjectForKey(value, 'generatePromotionalCodes');
    }
    removeGeneratePromotionalCodesObject(value:GeneratePromotionalCode) {
        this._removeObjectForKey(value, 'generatePromotionalCodes');
    }

    // Relationship: offerProducts
    protected _offerProducts:MIOManagedObjectSet = null;
    get offerProducts():MIOManagedObjectSet {
        return this.valueForKey('offerProducts');
    }
    addOfferProductsObject(value:OfferProduct) {
        this._addObjectForKey(value, 'offerProducts');
    }
    removeOfferProductsObject(value:OfferProduct) {
        this._removeObjectForKey(value, 'offerProducts');
    }
}
