//
// Generated class MenuName
//

/// <reference path="MenuName_ManagedObject.ts" />

class MenuName extends MenuName_ManagedObject
{

}
