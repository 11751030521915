class SelectCategoryGlobalActionsViewController extends MUIViewController
{  
    private backButton:MUIButton = null;
    private applyButton:MUIButton = null;
    private tableView:UITableView = null;
    private selectedCategory:ProductCategory = null;
    private selectedCategories:ProductCategory[] = []; 

    public delegate = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");
        this.backButton.setAction(this, function (){            
            this.navigationController.popViewController(true);
        });
        
        this.applyButton = MUIOutlet(this, "apply-btn", "MUIButton");
        this.applyButton.setAction(this, function (){
            MIONotificationCenter.defaultCenter().postNotification("globalActionsDidSelectCategory", this.selectedCategories);
            this.navigationController.popViewController(true);
        });

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(){
        super.viewWillAppear();

        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableView:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects() + 1;
    }
                                              
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let cell = tableView.dequeueReusableCellWithIdentifier("GlobalActionsLabelCell") as GlobalActionsLabelCell;
        if (indexPath.row == 0){
            cell.name = MIOLocalizeString("ALL CATEGORIES", "ALL CATEGORIES");
            cell.setAccessoryType(UITableViewCellAccessoryType.Checkmark);
        } else {
            let ip = MIOIndexPath.indexForRowInSection(indexPath.row-1, indexPath.section);
            let item = this.fetchedResultsController.objectAtIndexPath(ip) as ProductSource;   
            cell.setAccessoryType(UITableViewCellAccessoryType.None);                   
            cell.item = item;
        }
        cell.selectionStyle = UITableViewCellSelectionStyle.None;
        return cell;
    }   

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){ 
        if (indexPath.row == 0){
            // select and reset
            this.tableView.cellAtIndexPath(indexPath).setAccessoryType(MUITableViewCellAccessoryType.Checkmark);
            this.selectedCategories = [];
            // deselect all other options
            var len = this.fetchedResultsController.fetchedObjects.length;
            for (let i = 1; i <= len; i++){ 
                let ip = MIOIndexPath.indexForRowInSection(i, indexPath.section);
                this.tableView.cellAtIndexPath(ip).setAccessoryType(MUITableViewCellAccessoryType.None);
            }

        } else {
            // uncheck first option
            let ip = MIOIndexPath.indexForRowInSection(0, indexPath.section);
            this.tableView.cellAtIndexPath(ip).setAccessoryType(MUITableViewCellAccessoryType.None);

            ip = MIOIndexPath.indexForRowInSection(indexPath.row - 1, indexPath.section);
            this.selectedCategory = this.fetchedResultsController.objectAtIndexPath(ip);

            if (!this.selectedCategories.containsObject(this.selectedCategory)) {         
                this.tableView.cellAtIndexPath(indexPath).setAccessoryType(MUITableViewCellAccessoryType.Checkmark);        
                this.selectedCategories.addObject(this.selectedCategory);

            } else {           
                indexPath = MIOIndexPath.indexForRowInSection(indexPath.row, indexPath.section);   
                this.tableView.cellAtIndexPath(indexPath).setAccessoryType(MUITableViewCellAccessoryType.None);
                this.selectedCategories.removeObject(this.selectedCategory);

                if (this.selectedCategories.length == 0) {
                    ip = MIOIndexPath.indexForRowInSection(0, indexPath.section);
                    this.tableView.cellAtIndexPath(ip).setAccessoryType(MUITableViewCellAccessoryType.Checkmark);
                }
            } 
        }
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        var ad = MUIWebApplication.sharedInstance().delegate;
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('name', true)];
    
        var fetchRequest = DBHelper.listFetchRequestWithEntityName('ProductCategory', sortDescriptors, null);
        var fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }

}