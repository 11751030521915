
class CashDeskSessionCountSummaryViewController extends MUIViewController
{
    private cashDeskNameLabel:MUILabel = null;
    private sessionDateLabel:MUILabel = null;
    private sessionDateButton:MUIButton = null;

    private tableView:UITableView = null;

    private estimatedLabel:MUILabel = null;
    private estimatedTipsLabel:MUILabel = null;
    private countedLabel:MUILabel = null;
    private countedTipsLabel:MUILabel = null;

    private ldtf:MIODateFormatter = MUIWebApplication.sharedInstance().delegate.longDateTimeFormatter;
    private cf:MIONumberFormatter = MUIWebApplication.sharedInstance().delegate.currencyFormatter;

    viewDidLoad(){
        super.viewDidLoad();

        this.cashDeskNameLabel = MUIOutlet(this, "cashdesk-name-lbl", "MUILabel");
        this.sessionDateLabel = MUIOutlet(this, "session-date-lbl", "MUILabel");
        this.sessionDateButton = MUIOutlet(this, "session-date-btn", "MUIButton");
        this.sessionDateButton.setAction(this, function(){
            this.changeBeginSessionDate();
        });

        this.tableView = MUIOutlet(this, 'tableview', "UITableView");
        this.tableView.dataSource = this;            

        this.estimatedLabel = MUIOutlet(this, "total-estimated-lbl", "MUILabel");
        this.estimatedTipsLabel = MUIOutlet(this, "total-estimated-tips-lbl", "MUILabel");
        this.countedLabel = MUIOutlet(this, "total-counted-lbl", "MUILabel");
        this.countedTipsLabel = MUIOutlet(this, "total-counted-tips-lbl", "MUILabel");
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _session:CashDeskSession = null;
    get session() {return this._session;}
    set session(value:CashDeskSession) {
        if (this._session == value) return;
        this._session = value;        
        this.downloaded = false;
        this.downloading = false;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._session == null) return;
        this.cashDeskNameLabel.text = this.session.name;
        this.sessionDateLabel.text = this.ldtf.stringFromDate(this.session.serverSessionBeginDate);        
        this.downloadItems();
    }
    
    private items = [];
    private downloading = false;
    private downloaded = false;

    private counted_mapping = {};
    
    private downloadItems(){
        if (this.session == null) return;

        if (this.downloading == true || this.downloaded == true) return;  
        this.downloading = true;      

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        let ws:WebService = ad.webService;

        DBHelper.queryObjects(ad.managedObjectContext, "PayMethod", MIOPredicate.predicateWithFormat("deletedAt = null") ); 

        ws.getPayMethodsFromSessionID(this.session.identifier, this, function(this:CashDeskSessionCountSummaryViewController, code, json){
            this.downloading = false;      
            this.downloaded = true;
            if (code == 200) {
                this.items = json["data"];
                this.calculateTotals();
                this.tableView.reloadData();
            }
        });
    }

    //
    // Tableview datasource
    //

    numberOfSections(tableview:UITableView) {
        return 1;
    }

    numberOfRowsInSection(tableview:UITableView, section:number) {
        return this.items.length;
    }
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath) {         
        let cell = tableview.dequeueReusableCellWithIdentifier("CashDeskSessionCountCell") as CashDeskSessionCountCell;

        let pm = this.items[indexPath.row];
        cell.setItem(pm);

        return cell;
    }

    private totals = {};
    private calculateTotals(){

        let estimated = 0;
        let estimatedTips = 0;
        let counted = 0;
        let countedTips = 0;

        for (let index = 0; index < this.items.length; index++){
            let i = this.items[index];

            let money = 0;
            if (i["estimatedMoney"] != null){
                money = parseFloat(i["estimatedMoney"]);
            }
            else {
                i["estimatedMoney"] = 0;
            }
            
            let tips = 0;
            if (i["estimatedTips"] != null) {
                tips = parseFloat(i["estimatedTips"]);
            }
            else {
                i["estimatedTips"] = 0;
            }
            
            estimated += money;
            estimatedTips += tips;

            let countMoney = 0;
            if (i["countedMoney"] != null) {
                countMoney = parseFloat(i["countedMoney"]);
            }
            else {
                i["countedMoney"] = money;
                countMoney = money;
            }

            let countTips = 0;
            if (i["countedTips"] != null){
                countTips = parseFloat(i["countedTips"]);
            }
            else {
                i["countedTips"] = tips;
                countTips = tips;
            }

            counted += countMoney;
            countedTips += countTips;
        }        

        this.totals["estimated"] = estimated;
        this.totals["estimatedTips"] = estimatedTips;
        this.totals["counted"] = counted;
        this.totals["countedTips"] = countedTips;

        this.updateTotals();
    }

    private updateTotals(){

        this.estimatedLabel.text = this.cf.stringFromNumber(this.totals["estimated"]);
        this.estimatedTipsLabel.text = this.cf.stringFromNumber(this.totals["estimatedTips"]);
        this.countedLabel.text = this.cf.stringFromNumber(this.totals["counted"]);
        this.countedTipsLabel.text = this.cf.stringFromNumber(this.totals["countedTips"]);
    }

    closeSession(target, completion){

        let values = [];

        for (let index = 0; index < this.items.length; index++){
            let pm = this.items[index];

            let item = {};
            item["paymethodID"] = pm["paymethodID"];
            item["paymethodName"] = pm["paymethodName"];
            item["estimatedMoney"] = pm["estimatedMoney"];
            item["estimatedTips"] = pm["estimatedTips"];                
            item["countedMoney"] = pm["countedMoney"];
            item["countedTips"] = pm["countedTips"];

            values.push(item);
        }

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        let ws:WebService = ad.webService;
        
        ws.closeSession(this.session.identifier, values, this, function(code:number, json:any){
                if (code != 200 ) AppHelper.showErrorMessage(this,'Close session error', 'Something went wrong trying to close session. Please try again later.');
                else this.navigationController.popViewController(true);
                
                if (target != null && completion != null) completion.call(target);
            });

        // });        
    }

    private changeBeginSessionDate(){
        let dp = new MUIDatePickerController();
        dp.init();        
        dp.delegate = this;
        dp.tag = 1;
        dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

        let pc = dp.popoverPresentationController;
        pc.sourceRect = this.sessionDateButton.frame;
        pc.sourceView = this.sessionDateButton;

        this.presentViewController(dp, true);
    }

    didSelectDate(datePickerController:MUIDatePickerController, date:Date){

        let avc = new MUIAlertViewController();
        avc.initWithTitle('Change session date', 'You are gonna change the date of the session. Are you sure?', MUIAlertViewStyle.Default);
        avc.addAction(MUIAlertAction.alertActionWithTitle('OK', MUIAlertActionStyle.Default, this, function(){
            this.changeSessionDate(date);
        }));    
        avc.addAction(MUIAlertAction.alertActionWithTitle('Cancel', MUIAlertActionStyle.Cancel, null, null));    
        this.presentViewController(avc, true);

    }

    private changeSessionDate(date:Date){
        // Change the time to the same time that session started
        let begin_date = MIODateGetDateString(date) + " " + MIODateGetTimeString( this.session.beginDate );
        let end_date = MIODateGetString( this.session.endDate );

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.changeCashDeskSessionDate(this.session.identifier, begin_date, end_date, this, function(code, data){
            if (code == 200 && data["status"] == "OK") {
                this.sessionDateLabel.text = this.ldtf.stringFromDate(date);
            }
            else {
                AppHelper.showErrorMessage(this, 'Change date session error', 'Something went wrong. Please try again later.');
            }
        });
    }
}