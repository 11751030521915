
class WineCellarProductCell extends UITableViewCell
{
    private refLabel:MUILabel = null;
    private nameLabel:MUILabel = null; 

    private columnView:WineCellarProductCellColumn = null;
    private columns:WineCellarProductCellColumn[] = []

    wineCellars: WineCellar[] = WineCellarProductsViewController.wineCellars;

    awakeFromHTML(){
        this.refLabel = MUIOutlet(this, "ref-lbl", "MUILabel");
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.columnView = MUIOutlet(this, "repeat-wine-cellar-dd", "WineCellarProductCellColumn");
        this.columnView.hidden = true;

        // Create the columns we need to display        
        for (const wc of this.wineCellars){
            let c = this.columnView.copy();
            c.hidden = false;
            this.addSubview(c);

            this.columns.addObject(c);
        }
    }
    
    private _product:Product = null;
    set item (item:Product){        
        this._product = item;

        this.refLabel.text = item.reference;
        this.nameLabel.text = item.name;

        let cellar_components = this._product.wineCellarHash.split("#");
        for (let i = 0; i < this.columns.length; i++){
            let c = this.columns[i];            
            c.title = cellar_components[i] ?? null;
        }
    }    

    
}

class WineCellarProductCellColumn extends MUIView
{
    private dropDown:MUIButton = null;

    initWithLayer(layer, owner, options?){
        super.initWithLayer(layer, owner, options);
        this.dropDown = MUIOutlet(this, "drop-down", "MUIButton");
    }

    set title(value:string){
        this.dropDown.title = value;
    }

}