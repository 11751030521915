
class ApplicationListViewController extends BaseTableViewController
{
    static newInstance():ApplicationListViewController {
        let vc = new ApplicationListViewController("application-view");
        vc.initWithResource("layout/app-configuration/ApplicationView.html");
        return vc;
    }

    viewDidLoad(){
        super.viewDidLoad();

        // this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        // this.setupSearchTextField();

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        
    }
    
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Application;
        let cell = tableview.dequeueReusableCellWithIdentifier("AppCell") as AppCell;
        
        cell.item = item;
        cell.selected = (this.selectedItem == item);
    
        return cell;
    }
            
    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Application;
        this.selectedItem = item;

        let vc = AppConfigurationViewController.newInstance();
        vc.item = item;
        this.splitViewController.showDetailViewController(vc);
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let sd = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];
        let predicateFormat = "type == 1";

        if (this.searchString != null) {
            predicateFormat += " AND name CONTAINS '" + this.searchString + "'";
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Application", sd, predicateFormat);                
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }


}