
class StockCategoryViewController extends BaseTableViewController
{
    static newInstance() {
        let vc = new StockCategoryViewController('stock-category-view');
        vc.initWithResource('layout/stock_category/StockCategoryView.html');
        return vc;
    }

    get title(){
        return MIOLocalizeString("STOCK CATEGORY","STOCK CATEGORY");
    }

    viewDidLoad(){
        super.viewDidLoad();

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;    
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as StockCategory;

        let cell = tableView.dequeueReusableCellWithIdentifier("StockCategoryCell") as StockCategoryCell;
        cell.item = item;

        return cell;
    }    
    
    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {   
        if (this.noteStatus == StockNoteStatus.Processed || this.noteStatus == StockNoteStatus.Pending)
            return MUITableViewCellEditingStyle.None;
        
        if (indexPath.section == this.fetchedResultsController.sections.length) {
            return MUITableViewCellEditingStyle.Insert;
        }
        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {        

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            let item = this.fetchedResultsController.objectAtIndexPath(indexPath);    
            DBHelper.deleteObjectFromMainContext( item, true );
        }
    }  

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;        
        let fetchRequest = DBHelper.listFetchRequestWithEntityName( "StockCategory", [MIOSortDescriptor.sortDescriptorWithKey("namePath", true)], null);

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;

        return this._fetchedResultsController;
    }

    addButtonDidPress(){

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("STOCK CATEGORY","STOCK CATEGORY"), MIOLocalizeString("CHOOSE A NAME", "CHOOSE A NAME"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
           textField.setPlaceholderText(MIOLocalizeString("NAME", "NAME"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("ADD", "ADD"), MUIAlertActionStyle.Default, this, function(){
            let name = avc.textFields[0].text;
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            let d = MIOEntityDescription.insertNewObjectForEntityForName("StockCategory", ad.managedObjectContext) as StockCategory;
            d.name = name;                        
            ad.managedObjectContext.save();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, null, null));        

        this.presentViewController(avc, true);
    }

    removeItem(item:MIOManagedObject){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.managedObjectContext.deleteObject(item);
        ad.managedObjectContext.save();        
    }

}