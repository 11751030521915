
class AppConfigurationCell extends UITableViewCell
{
    private titleLabel:MUILabel = null;    
    private valueTextField:MUITextField = null;

    awakeFromHTML(){
        this.titleLabel = MUIOutlet( this, "key-lbl", "MUILabel");        
        this.valueTextField = MUIOutlet( this, "value-tf", "MUITextField" );
        this.valueTextField.setOnChangeText( this, function (this:AppConfigurationCell, control:MUITextField, value:string) {
            this.cfg.valueFromString( value );
        });
    }

    private cfg:AppConfiguration = null;
    set item (item:AppConfiguration){
        this.cfg = item;
        this.titleLabel.text = item.key;
        this.valueTextField.text = item.value;
    }
}