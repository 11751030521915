
class DistributorInfoViewController extends MUIViewController
{
    public static newInstance() : DistributorInfoViewController {
        let vc = new DistributorInfoViewController("distributor-info-view");
        vc.initWithResource("layout/distributors/DistributorInfoView.html");
        return vc;
    }

    private nameTextField:MUITextField = null;
    private emailTextField:MUITextField = null;
    private documentTextField:MUITextField = null;
    private address1TextField:MUITextField = null;    
    private cityTextField:MUITextField = null;
    private stateTextField:MUITextField = null;
    private countryDropdown:MUIButton = null;
    private postalCodeTextField:MUITextField = null;
    private phoneTextField:MUITextField = null;
    private mobilePhoneTextField:MUITextField = null;

    private agentTableView:UITableView = null;

    private vatTypeComboBox:MUIComboBox = null;
    private tagView:TagView = null;
    
    private salesLedgerAccountDropdown:MUIButton = null;
    private purchaseLedgerAccountDropdown:MUIButton = null;
    // private accountTextField:MUITextField = null;

    private moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;    
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;

    viewDidLoad(){
        super.viewDidLoad();

        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.nameTextField.setOnChangeText(this, function(textfield, value) {
            this._distributor.name = value;            
        });

        this.emailTextField = MUIOutlet(this, "email-tf", "MUITextField");
        this.emailTextField.setOnChangeText(this, function(textfield, value) {
            this._distributor.email = value;
        });

        this.documentTextField = MUIOutlet(this, "documentid-tf", "MUITextField");
        this.documentTextField.setOnChangeText(this, function(textfield, value) {
            this._distributor.document = value;
        });

        this.address1TextField = MUIOutlet(this, "address-tf", "MUITextField");
        this.address1TextField.setOnChangeText(this, function(textfield, value) {
            this.address.address1 = value;            
        });

        this.cityTextField = MUIOutlet(this, "city-tf", "MUITextField");
        this.cityTextField.setOnChangeText(this, function(textfield, value) {
            this.address.city = value;
        });

        this.stateTextField = MUIOutlet(this, "state-tf", "MUITextField");
        this.stateTextField.setOnChangeText(this, function(textfield, value) {
            this.address.state = value;
        });

        this.countryDropdown = MUIOutlet(this, "country-dd", "MUIButton");
        this.countryDropdown.setAction(this, function(){
            AppHelper.sharedInstance().showSelectCountryViewControllerFromView(this.countryDropdown, null, this, function(this:DistributorInfoViewController, controller:SelectEntityViewController, country:Country){
                this.address.country = country.name;                
                this.address.countryISOa2 = country.iso2;
                this.address.countryISOa3 = country.iso3;
                this.countryDropdown.title = country.name;
            });
        });   

        this.postalCodeTextField = MUIOutlet(this, "postal-code-tf", "MUITextField");
        this.postalCodeTextField.setOnChangeText(this, function(textfield, value) {
            this.address.postalCode = value;
        });

        this.phoneTextField = MUIOutlet(this, "phone-tf", "MUITextField");
        this.phoneTextField.setOnChangeText(this, function(textfield, value) {
            this.phone.number = value;
        });

        this.mobilePhoneTextField = MUIOutlet(this, "mobile-tf", "MUITextField");
        this.mobilePhoneTextField.setOnChangeText(this, function(textfield, value) {
            this.mobilePhone.number = value;
        });

        this.agentTableView = MUIOutlet(this, "agents-table-view", "UITableView");   
        this.agentTableView.dataSource = this;     
        
        this.vatTypeComboBox = MUIOutlet( this, "vat-type-cb", "MUIComboBox" );
        this.vatTypeComboBox.setOnChangeAction( this, function(this:DistributorInfoViewController, control:MUIComboBox, value:string) {
            this._distributor.taxRegimeType = parseInt( value );
        });

        this.salesLedgerAccountDropdown = MUIOutlet(this, "sales-ledger-account-dd", "MUIButton");
        this.salesLedgerAccountDropdown.setAction(this, function(this:DistributorInfoViewController){
            AppHelper.sharedInstance().showSelectLedgerAccountViewControllerFromView(this.salesLedgerAccountDropdown, this._distributor.salesLedgerAccount, null, false, this._distributor.name, this, this.updateSalesLedgerAccount);
        });

        this.purchaseLedgerAccountDropdown = MUIOutlet(this, "purchase-ledger-account-dd", "MUIButton");
        this.purchaseLedgerAccountDropdown.setAction(this, function(this:DistributorInfoViewController){
            AppHelper.sharedInstance().showSelectLedgerAccountViewControllerFromView(this.purchaseLedgerAccountDropdown, this._distributor.purchaseLedgerAccount, null, false, this._distributor.name, this, this.updatePurchaseLedgerAccount);
        });


        this.tagView = MUIOutlet(this, 'tag-view', 'TagView');
        this.tagView.delegate = this;

        // this.accountTextField = MUIOutlet(this, "account-number-tf", "MUITextField");
        // this.accountTextField.setOnChangeText(this, function(this:DistributorInfoViewController, textfield:MUITextField, value:string) {
        //     let v = value.trim().length == 0 ? null : value;
        //     this._distributor.accountNumber = v;
        // });

    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private tags  = [];
    private _distributor:Distributor = null;
    set distributor(value:Distributor){
        this._distributor = value;
        this.tags = value.tags == null ? [] : value.tags.split(',');
        this.updateUI();
    }

    private updateUI(){        
        if (this.viewIsLoaded == false || this._distributor == null) return;

        this.nameTextField.text = this._distributor.name;
        this.emailTextField.text = this._distributor.email;
        this.documentTextField.text = this._distributor.document;

        this.updateAddress();
        this.phoneTextField.text = this.phone.number;
        this.mobilePhoneTextField.text = this.mobilePhone.number;
        
        this.vatTypeComboBox.selectItem( this._distributor.taxRegimeType );
        // this.accountTextField.text = this._distributor.accountNumber;

        let predicate = MIOPredicate.predicateWithFormat("type = " + TagType.Distributor);
        DBHelper.queryObjectsWithCompletion("Tag", null, predicate, [], this, function(objects:Tag[]){
            this.reloadTags(objects);
        });                

        this.salesLedgerAccountDropdown.title = this._distributor.salesLedgerAccountName;
        this.purchaseLedgerAccountDropdown.title = this._distributor.purchaseLedgerAccountName;

        this.fetchedResultsController = null;
        this.agentTableView.reloadData();
    }

    private updateAddress(){
        this.address1TextField.text = this.address.address1;
        //this.address2TextField.text = address.address2;
        this.countryDropdown.title = getCountryName( this.address.countryISOa2 ); // countryName;
        this.cityTextField.text = this.address.city;
        this.stateTextField.text = this.address.state;
        this.postalCodeTextField.text = this.address.postalCode;
    }


    get address(){
        if(this._distributor.address == null){
            this._distributor.address = MIOEntityDescription.insertNewObjectForEntityForName("Address", this.moc) as Address;
            this._distributor.address.identifier = MIOUUID.UUID().UUIDString;
        }
        return this._distributor.address;
    }

    get phone(){
        if(this._distributor.phone == null){
            this._distributor.phone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", this.moc) as PhoneNumber;
            this._distributor.phone.identifier = MIOUUID.UUID().UUIDString;
        }
        return this._distributor.phone;
    }

    get mobilePhone(){
        if(this._distributor.mobilePhone == null){
            this._distributor.mobilePhone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", this.moc) as PhoneNumber;
            this._distributor.mobilePhone.identifier = MIOUUID.UUID().UUIDString;
        }
        return this._distributor.mobilePhone;
    }

    // TAGS

    private reloadTags(tags){
        this.tagView.removeAll();
        for (let index = 0; index < tags.length; index++){
            let tag = tags[index] as Tag;
            this.tagView.addTag(tag.name);
            if (this.tags.containsObject(tag.name)) {
                this.tagView.selectTagAtIndex(index);
            }
        }
    }

    didSelectTagAtIndex(index, name:string){        
        let i = this.tags.indexOfObject(name);
        if (i < 0){
            this.tags.addObject(name);            
        }
        else {
            this.tags.removeObject(name);
        }

        this._distributor.tags = this.tags.join(',');    
    }

    // AGENTS TABLE VIEW

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {
        let cell = tableView.dequeueReusableCellWithIdentifier("DistributorAgentCell") as DistributorAgentCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Contact;

        cell.item = item;

        return cell;

    }    

    protected _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value:MIOFetchedResultsController){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController() : MIOFetchedResultsController {
        if (this._fetchedResultsController != null)
        return this._fetchedResultsController;

    let ad = MUIWebApplication.sharedInstance().delegate;

    let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];
    let predicateFormat = "legalEntity.identifier == " + this._distributor.identifier;
    let fetchRequest = DBHelper.listFetchRequestWithEntityName("Contact", sortDescriptors, predicateFormat);
    // fetchRequest.relationshipKeyPathsForPrefetching = [""];
    
    let fetchedResultsController = new MIOFetchedResultsController();
    fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
    fetchedResultsController.delegate = this;

    fetchedResultsController.performFetch();

    this._fetchedResultsController = fetchedResultsController;    
    return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        this.agentTableView.reloadData();
    }

    private updateSalesLedgerAccount(controller:SelectViewController, account:LedgerAccount){
        this._distributor.salesLedgerAccount = account;
        this._distributor.salesLedgerAccountName = account?.description;
        this.salesLedgerAccountDropdown.title = account?.description;
    }

    private updatePurchaseLedgerAccount(controller:SelectViewController, account:LedgerAccount){
        this._distributor.purchaseLedgerAccount = account;
        this._distributor.purchaseLedgerAccountName = account?.description;
        this.purchaseLedgerAccountDropdown.title = account?.description;
    }

}