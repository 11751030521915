//
// Generated class PaymentDevice
//

/// <reference path="PaymentDevice_ManagedObject.ts" />

class PaymentDevice extends PaymentDevice_ManagedObject
{

}
