

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Address_ManagedObject

class Address_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: address1
    set address1(value:string) {
        this.setValueForKey(value, 'address1');
    }
    get address1():string {
        return this.valueForKey('address1');
    }
    set address1PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'address1');
    }
    get address1PrimitiveValue():string {
        return this.primitiveValueForKey('address1');
    }

    // Property: address2
    set address2(value:string) {
        this.setValueForKey(value, 'address2');
    }
    get address2():string {
        return this.valueForKey('address2');
    }
    set address2PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'address2');
    }
    get address2PrimitiveValue():string {
        return this.primitiveValueForKey('address2');
    }

    // Property: city
    set city(value:string) {
        this.setValueForKey(value, 'city');
    }
    get city():string {
        return this.valueForKey('city');
    }
    set cityPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'city');
    }
    get cityPrimitiveValue():string {
        return this.primitiveValueForKey('city');
    }

    // Property: country
    set country(value:string) {
        this.setValueForKey(value, 'country');
    }
    get country():string {
        return this.valueForKey('country');
    }
    set countryPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'country');
    }
    get countryPrimitiveValue():string {
        return this.primitiveValueForKey('country');
    }

    // Property: countryCode
    set countryCode(value:number) {
        this.setValueForKey(value, 'countryCode');
    }
    get countryCode():number {
        return this.valueForKey('countryCode');
    }
    set countryCodePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'countryCode');
    }
    get countryCodePrimitiveValue():number {
        return this.primitiveValueForKey('countryCode');
    }

    // Property: countryISOa2
    set countryISOa2(value:string) {
        this.setValueForKey(value, 'countryISOa2');
    }
    get countryISOa2():string {
        return this.valueForKey('countryISOa2');
    }
    set countryISOa2PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'countryISOa2');
    }
    get countryISOa2PrimitiveValue():string {
        return this.primitiveValueForKey('countryISOa2');
    }

    // Property: countryISOa3
    set countryISOa3(value:string) {
        this.setValueForKey(value, 'countryISOa3');
    }
    get countryISOa3():string {
        return this.valueForKey('countryISOa3');
    }
    set countryISOa3PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'countryISOa3');
    }
    get countryISOa3PrimitiveValue():string {
        return this.primitiveValueForKey('countryISOa3');
    }

    // Property: postalCode
    set postalCode(value:string) {
        this.setValueForKey(value, 'postalCode');
    }
    get postalCode():string {
        return this.valueForKey('postalCode');
    }
    set postalCodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'postalCode');
    }
    get postalCodePrimitiveValue():string {
        return this.primitiveValueForKey('postalCode');
    }

    // Property: state
    set state(value:string) {
        this.setValueForKey(value, 'state');
    }
    get state():string {
        return this.valueForKey('state');
    }
    set statePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'state');
    }
    get statePrimitiveValue():string {
        return this.primitiveValueForKey('state');
    }

    // Property: title
    set title(value:string) {
        this.setValueForKey(value, 'title');
    }
    get title():string {
        return this.valueForKey('title');
    }
    set titlePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'title');
    }
    get titlePrimitiveValue():string {
        return this.primitiveValueForKey('title');
    }
    // Relationship: company
    set company(value:Company) {
        this.setValueForKey(value, 'company');
    }
    get company():Company {
        return this.valueForKey('company') as Company;
    }
    // Relationship: legalEntityAddress
    set legalEntityAddress(value:LegalEntity) {
        this.setValueForKey(value, 'legalEntityAddress');
    }
    get legalEntityAddress():LegalEntity {
        return this.valueForKey('legalEntityAddress') as LegalEntity;
    }
    // Relationship: order
    set order(value:Order) {
        this.setValueForKey(value, 'order');
    }
    get order():Order {
        return this.valueForKey('order') as Order;
    }
    // Relationship: warehouse
    set warehouse(value:Warehouse) {
        this.setValueForKey(value, 'warehouse');
    }
    get warehouse():Warehouse {
        return this.valueForKey('warehouse') as Warehouse;
    }
}
