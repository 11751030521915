

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class AppArea_ManagedObject

class AppArea_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: areaID
    set areaID(value:number) {
        this.setValueForKey(value, 'areaID');
    }
    get areaID():number {
        return this.valueForKey('areaID');
    }
    set areaIDPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'areaID');
    }
    get areaIDPrimitiveValue():number {
        return this.primitiveValueForKey('areaID');
    }

    // Property: businessAreaID
    set businessAreaID(value:string) {
        this.setValueForKey(value, 'businessAreaID');
    }
    get businessAreaID():string {
        return this.valueForKey('businessAreaID');
    }
    set businessAreaIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'businessAreaID');
    }
    get businessAreaIDPrimitiveValue():string {
        return this.primitiveValueForKey('businessAreaID');
    }

    // Property: consumptionProfileID
    set consumptionProfileID(value:string) {
        this.setValueForKey(value, 'consumptionProfileID');
    }
    get consumptionProfileID():string {
        return this.valueForKey('consumptionProfileID');
    }
    set consumptionProfileIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'consumptionProfileID');
    }
    get consumptionProfileIDPrimitiveValue():string {
        return this.primitiveValueForKey('consumptionProfileID');
    }

    // Property: isEnabled
    set isEnabled(value:boolean) {
        this.setValueForKey(value, 'isEnabled');
    }
    get isEnabled():boolean {
        return this.valueForKey('isEnabled');
    }
    set isEnabledPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isEnabled');
    }
    get isEnabledPrimitiveValue():boolean {
        return this.primitiveValueForKey('isEnabled');
    }

    // Property: presetGroupID
    set presetGroupID(value:string) {
        this.setValueForKey(value, 'presetGroupID');
    }
    get presetGroupID():string {
        return this.valueForKey('presetGroupID');
    }
    set presetGroupIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'presetGroupID');
    }
    get presetGroupIDPrimitiveValue():string {
        return this.primitiveValueForKey('presetGroupID');
    }
}
