//
// Generated class ProductName
//

/// <reference path="ProductName_ManagedObject.ts" />

class ProductName extends ProductName_ManagedObject
{

}
