class LeaveReasonTypeViewController extends SingleEditViewController
{    
    static newInstance() : LeaveReasonTypeViewController 
    {
        let vc = new LeaveReasonTypeViewController("single-edit-view");
        vc.initWithResource("layout/base/SingleEditView.html");
        return vc;
    }

    get title(){
        return MIOLocalizeString("LEAVE REASON TYPE","LEAVE REASON TYPE");
    }

    fetchEntity(){
        return "LeaveReasonType"; 
    }

    sortDescriptors(){
        return [MIOSortDescriptor.sortDescriptorWithKey("title", true)];
    }

    configureCellAtIndexPath(cell:SingleEditCell, indexPath:MIOIndexPath, item:MIOManagedObject){
        let d = item as LeaveReasonType;
        cell.setItem(d, "title");
    }

    addButtonDidPress(){

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("NEW TYPE", "NEW TYPE"), MIOLocalizeString("CHOOSE A TITLE", "CHOOSE A TITLE"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
           textField.setPlaceholderText(MIOLocalizeString("TITLE", "TITLE"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("ADD", "ADD"), MUIAlertActionStyle.Default, this, function(){
            let name = avc.textFields[0].text;
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            let d = MIOEntityDescription.insertNewObjectForEntityForName(this.fetchEntity(), ad.managedObjectContext) as LeaveReasonType;
            d.title = name;
            
            ad.managedObjectContext.save();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    removeItem(item:MIOManagedObject){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.managedObjectContext.deleteObject(item);
        ad.managedObjectContext.save();        
    }
}