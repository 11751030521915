const kGluten = 1;
const kCrustaceans = 2;
const kEggs = 4;
const kFish = 8;
const kPeanuts = 16;
const kSoya = 32;
const kMilk = 64;
const kNuts =128;
const kCelery = 256;
const kMustard = 512;
const kSesame = 1024;
const kSulphates = 2048;
const kMollusk = 4096;
const kLupins = 8192;

class ProductAllergiesViewController extends MUIViewController
{
    private glutenButton:MUIButton = null;
    private nutsButton:MUIButton = null;
    private crustaceansButton:MUIButton = null;
    private celeryButton:MUIButton = null;
    private eggsButton:MUIButton = null;
    private mustardButton:MUIButton = null;
    private fishButton:MUIButton = null;
    private sesameButton:MUIButton = null;
    private peanutsButton:MUIButton = null;
    private sulphatesButton:MUIButton = null;
    private soyaButton:MUIButton = null;
    private molluskButton:MUIButton = null;
    private milkButton:MUIButton = null;
    private lupinsButton:MUIButton = null;


    viewDidLoad(){
        super.viewDidLoad();

        this.glutenButton = MUIOutlet(this, 'pinv_b3_product_type_gluten_btn', 'MUIButton');
        this.glutenButton.type = MUIButtonType.PushOnPushOff;        
        this.glutenButton.setAction(this, function (){
            this._product.allergens =  this._product.allergens ^ kGluten;
        });

        this.nutsButton = MUIOutlet(this, 'pinv_b3_product_type_nuts_btn', 'MUIButton');
        this.nutsButton.type = MUIButtonType.PushOnPushOff;
        this.nutsButton.setAction(this, function () {
            this._product.allergens = this._product.allergens ^ kNuts;            
        });

        this.crustaceansButton = MUIOutlet(this, 'pinv_b3_product_type_crustaceans_btn', 'MUIButton');
        this.crustaceansButton.type = MUIButtonType.PushOnPushOff;
        this.crustaceansButton.setAction(this, function () {
            this._product.allergens = this._product.allergens ^ kCrustaceans;            
        });

        this.celeryButton = MUIOutlet(this, 'pinv_b3_product_type_celery_btn', 'MUIButton');
        this.celeryButton.type = MUIButtonType.PushOnPushOff;        
        this.celeryButton.setAction(this, function () {
            this._product.allergens = this._product.allergens ^ kCelery;            
        });

        this.eggsButton = MUIOutlet(this, 'pinv_b3_product_type_eggs_btn', 'MUIButton');
        this.eggsButton.type = MUIButtonType.PushOnPushOff;        
        this.eggsButton.setAction(this, function () {
            this._product.allergens = this._product.allergens ^ kEggs;            
        });

        this.mustardButton = MUIOutlet(this, 'pinv_b3_product_type_mustard_btn', 'MUIButton');
        this.mustardButton.type = MUIButtonType.PushOnPushOff;        
        this.mustardButton.setAction(this, function () {
            this._product.allergens = this._product.allergens ^ kMustard;            
        });
         
        this.fishButton = MUIOutlet(this, 'pinv_b3_product_type_fish_btn', 'MUIButton');
        this.fishButton.type = MUIButtonType.PushOnPushOff;        
        this.fishButton.setAction(this, function () {
            this._product.allergens = this._product.allergens ^ kFish;            
        });

        this.sesameButton = MUIOutlet(this, 'pinv_b3_product_type_sesame_btn', 'MUIButton');
        this.sesameButton.type = MUIButtonType.PushOnPushOff;        
        this.sesameButton.setAction(this, function () {
            this._product.allergens = this._product.allergens ^ kSesame;            
        });

        this.peanutsButton = MUIOutlet(this, 'pinv_b3_product_type_peanuts_btn', 'MUIButton');
        this.peanutsButton.type = MUIButtonType.PushOnPushOff;        
        this.peanutsButton.setAction(this, function () {
            this._product.allergens = this._product.allergens ^ kPeanuts;            
        });

        this.sulphatesButton = MUIOutlet(this, 'pinv_b3_product_type_sulphates_btn', 'MUIButton');
        this.sulphatesButton.type = MUIButtonType.PushOnPushOff;        
        this.sulphatesButton.setAction(this, function () {
            this._product.allergens = this._product.allergens ^ kSulphates;            
        });

        this.soyaButton = MUIOutlet(this, 'pinv_b3_product_type_soya_btn', 'MUIButton');
        this.soyaButton.type = MUIButtonType.PushOnPushOff;
        this.soyaButton.setAction(this, function () {
            this._product.allergens = this._product.allergens ^ kSoya;            
        });

        this.molluskButton = MUIOutlet(this, 'pinv_b3_product_type_mollusk_btn', 'MUIButton');
        this.molluskButton.type = MUIButtonType.PushOnPushOff;
        this.molluskButton.setSelected(false);
        this.molluskButton.setAction(this, function () {
            this._product.allergens = this._product.allergens ^ kMollusk;
            MIONotificationCenter.defaultCenter().postNotification("ProductChangeValue", null);
        });

        this.milkButton = MUIOutlet(this, 'pinv_b3_product_type_milk_btn', 'MUIButton');
        this.milkButton.type = MUIButtonType.PushOnPushOff;
        this.milkButton.setSelected(false);
        this.milkButton.setAction(this, function () { 
            this._product.allergens = this._product.allergens ^ kMilk;            
        });

        this.lupinsButton = MUIOutlet(this, 'pinv_b3_product_type_lupins_btn', 'MUIButton');
        this.lupinsButton.type = MUIButtonType.PushOnPushOff;
        this.lupinsButton.setSelected(false);
        this.lupinsButton.setAction(this, function () {
            this._product.allergens = this._product.allergens ^ kLupins;            
        });
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _product:Product = null;
    set product(product:Product) {
        this._product = product;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._product == null) return;

        let allergies = this._product.getAllergies(this._product.allergens);
       
        this.glutenButton.setSelected(allergies.indexOf('Gluten') >= 0);
        this.nutsButton.setSelected(allergies.indexOf('Nuts') >= 0);
        this.crustaceansButton.setSelected(allergies.indexOf('Crustaceans') >= 0);
        this.celeryButton.setSelected(allergies.indexOf('Celery') >= 0);
        this.eggsButton.setSelected(allergies.indexOf('Eggs') >= 0);
        this.mustardButton.setSelected(allergies.indexOf('Mustard') >= 0);
        this.fishButton.setSelected(allergies.indexOf('Fish') >= 0);
        this.sesameButton.setSelected(allergies.indexOf('Sesame') >= 0);
        this.peanutsButton.setSelected(allergies.indexOf('Peanuts') >= 0);
        this.sulphatesButton.setSelected(allergies.indexOf('Sulphates') >= 0);
        this.soyaButton.setSelected(allergies.indexOf('Soya') >= 0);
        this.molluskButton.setSelected(allergies.indexOf('Mollusk') >= 0);
        this.milkButton.setSelected(allergies.indexOf('Milk') >= 0);
        this.lupinsButton.setSelected(allergies.indexOf('Lupins') >= 0);

    }
}