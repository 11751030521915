//
// Generated class InternalOrderResponse
//

/// <reference path="InternalOrderResponse_ManagedObject.ts" />

class InternalOrderResponse extends InternalOrderResponse_ManagedObject
{

}
