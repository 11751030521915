/**
 * Created by miguelsl on 30/12/16.
 */


class ProductDetailIngredientsViewController extends EntityListViewController
{    
    private enableElaborationTypeView:MUIView = null;
    private changeElaborationTypeButton:MUIButton = null;

    private sellPriceLabel:MUILabel = null;
    private costPriceLabel:MUILabel = null;
    private profitLabel:MUILabel = null;

    private componentsRatioTextField:MUITextField = null;
    private additionalCostsTextField:MUITextField = null;
    private fixedCostTextField:MUITextField = null;  
    private costTypeComboBox:MUIComboBox = null;

    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;        

    viewDidLoad(){
        super.viewDidLoad();                

        this.enableElaborationTypeView = MUIOutlet(this, "page-cover", "MUIView");
        this.changeElaborationTypeButton = MUIOutlet(this, "change-elaboration-type-btn", "MUIButton");
        this.changeElaborationTypeButton.setAction(this, function(){
            AppHelper.sharedInstance().showElaborationTypeViewController(this, this.changeElaborationTypeButton, this._product);
        });
        
        this.sellPriceLabel = MUIOutlet(this, "sell-price-lbl", "MUILabel");
        this.costPriceLabel = MUIOutlet(this, "cost-price-lbl", "MUILabel");
        this.profitLabel = MUIOutlet(this, "profit-lbl", "MUILabel");

        this.componentsRatioTextField = MUIOutlet(this, "components-ratio-tf", "MUITextField");
        this.componentsRatioTextField.setOnChangeText(this, function(this:ProductDetailIngredientsViewController, control:MUITextField, value:string){
            let ration = value.trim() == "" ? 1 : this.nf.numberFromString( value );
            this._product.componentsRation = ration;
        });

        this.additionalCostsTextField = MUIOutlet(this, "aditional-cost-tf", "MUITextField");
        this.additionalCostsTextField.formatter = this.cf;
        this.additionalCostsTextField.setOnChangeText(this, function(textfield, value) {
            let p = this.nf.numberFromString(value);                            
            this.updateAdditionalCosts(p);
        });        

        this.fixedCostTextField = MUIOutlet(this, "fixed-cost-tf", "MUITextField");
        this.fixedCostTextField.formatter = this.cf;
        this.fixedCostTextField.setOnChangeText(this, function(textfield, value) {
            let price = this.cf.numberFromString(value);
            this.updateCostPrice(price);
        });        

        this.costTypeComboBox = MUIOutlet(this, "cost-type-cb", "MUIComboBox");
        this.costTypeComboBox.setOnChangeAction(this, function(control, value){
            this._product.costType = parseInt(value);
            this.updateProductCostFromServer();
        });

        this.tableView = MUIOutlet(this,"table-view","UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private _product:Product = null;
    set product(value){
        this._product = value;
        this.updateUI();
    }

    protected canReloadData(){
        if (this.viewIsLoaded == false || this._product == null) return false;
        return true;                
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._product == null) return;                
                
        this.enableElaborationTypeView.hidden = this._product.stockElaborationType == StockElaborationType.None ? false : true;

        this.sellPriceLabel.text = "-";
        this.costPriceLabel.text = "-";
        this.profitLabel.text = "-";
        
        this.componentsRatioTextField.text = this.nf.stringFromNumber( this._product.componentsRation );
        this.additionalCostsTextField.text = this.cf.stringFromNumber(this._product.additionalProductCosts);
        this.fixedCostTextField.text = this.cf.stringFromNumber(this._product.costProductPrice);
        this.costTypeComboBox.selectItem(this._product.costType);

        this.fetchedResultsController = null;
        this.tableView.reloadData();

        this.updateProductCost();
    }

    private _timer:MIOTimer = null;
    private updateCostPrice(costProductPrice:number){
        this._product.costProductPrice = costProductPrice;
        this._product.costPrice = DBHelper.calculateMinumCostFromProductPrice(costProductPrice, this._product);

        // this.updateProductCost();
        
        if (this._timer != null) this._timer.invalidate();        
        this._timer = MIOTimer.scheduledTimerWithTimeInterval(1, false, this, function(timer:MIOTimer){
            this.updateProductCostFromServer();
        });
    }

    private updateAdditionalCosts(additionalProductCosts:number){        
        this._product.additionalProductCosts = additionalProductCosts;
        this.updateProductCost();
    }
    
    private updateProductCost(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let price = this._product.price;
        let cost = this._product.computedCost;

        if (price > 0) this.sellPriceLabel.text = ad.currencyFormatter.stringFromNumber(price);
        if (cost > 0) this.costPriceLabel.text = ad.longCurrencyFormatter.stringFromNumber(cost);

        if (price > 0 && cost > 0){
            let profit = this._product.price - cost;
            this.profitLabel.text = ad.currencyFormatter.stringFromNumber(profit);
        }           
    }

    elaborationTypeDidUpdate(){
        this.updateUI();
    }

    // private updateCostFromComponents(){
    //     let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        
    //     let price = this._product.price;        
    //     let cost = this.calculateCostFromComponents();
    //     if (price > 0) this.sellPriceLabel.text = ad.currencyFormatter.stringFromNumber(price);
    //     if (cost > 0) this.costPriceLabel.text = ad.currencyFormatter.stringFromNumber(cost);
    //     if (price > 0 && cost > 0){
    //         let profit = this._product.price - cost;
    //         this.profitLabel.text = ad.currencyFormatter.stringFromNumber(profit);
    //     }
    // }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length + 1;
    }

    numberOfRowsInSection(tableview, section){
        if (section == this.fetchedResultsController.sections.count) return 1;

        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){

        let cell = null;
        if (indexPath.section == this.fetchedResultsController.sections.count) {
            cell = tableview.dequeueReusableCellWithIdentifier("AddCell") as IngredientCell;
            cell.parentProduct = this._product;
            return cell;
        }        
        
        cell = tableview.dequeueReusableCellWithIdentifier("EditCell") as IngredientCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Component;
        cell.parentProduct = this._product;
        cell.item = item;

        return cell;
    }

    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        if (indexPath.section == this.fetchedResultsController.sections.count) {
            return MUITableViewCellEditingStyle.Insert;
        }
        
        return MUITableViewCellEditingStyle.Delete;
    }
    
    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {        
        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            let ingredient = this.fetchedResultsController.objectAtIndexPath(indexPath) as Component;
            this.removeComponent(ingredient);
        }
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        let predicateFormat = "parent.identifier == " + this._product.identifier; 
        
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Component", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["parent", "children.costBalance"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller){
        this.tableView.reloadData();
        //this.updateCostFromComponents();
        this.updateProductCost();

        // set cell focus 
        let ip = MIOIndexPath.indexForRowInSection(0, this.fetchedResultsController.sections.length);
        let cell = this.tableView.cellAtIndexPath(ip) as IngredientCell;
        if (cell != null) cell.becameFirstResponder();
    }

    private removeComponent(component:Component){
        let ad = MUIWebApplication.sharedInstance().delegate;
        this._product.removeComponentsObject(component);
        ad.managedObjectContext.deleteObject(component);
        ad.managedObjectContext.save();
    }

    private updateProductCostFromServer(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.updateProductCost(this._product, (json, error) => {
            DBHelper.queryObjectWithCompletion("Product", null, MIOPredicate.predicateWithFormat("identifier == " + this._product.identifier), ad.managedObjectContext, this, (product:Product) => {
                this.updateProductCost();
            });
        });
    }

}