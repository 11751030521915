/**
 * Created by godshadow on 28/06/16.
 */

class GuestViewController extends BaseTableViewController
{
    private dateButton:MUIButton = null;    
    private loadActivityIndicator:MUIActivityIndicatorView = null;
    private saveActivityIndicator:MUIActivityIndicatorView = null;
    private addLineView:MUIView = null;
    private containerView:MUIView = null;
    private importButton:MUIButton = null;
    private exportButton:MUIButton = null;
    private progressView:MUIView = null; 
    private addButton:MUIButton    = null //Show add view for mobile
       

    private selectedDayString:string = null;
    private sources = null;

    private filterComboBox:MUIComboBox = null;

    private newNameTextField:MUITextField = null;
    private newPAXTextField:MUITextField = null;
    private newListComboBox:MUIComboBox = null;
    private newSourceComboBox:MUIComboBox = null;
    private newAddButton:MUIButton = null;

    private timer:MIOTimer = null;    
    private _client:Client = null;
    private date:Date = null;
    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;
    private sdtf = MUIWebApplication.sharedInstance().delegate.serverDateTimeFormatter;
    private sdf = MUIWebApplication.sharedInstance().delegate.serverDateFormatter;

    private df = null;
    private listCache = {};
    private angvc = null;

    viewDidLoad(){
        super.viewDidLoad();
        
        this.df = new MIODateFormatter();
        this.df.init();
        this.df.dateStyle = MIODateFormatterStyle.FullStyle;
        this.df.timeStyle = MIODateFormatterStyle.NoStyle;
        this.date = MIODateToday();
        this.selectedDayString = this.sdf.stringFromDate(this.date);
        
        this.dateButton = MUIOutlet(this, 'date-btn', 'MUIButton');
        this.dateButton.title = this.df.stringFromDate(this.date);
        this.dateButton.setAction(this, function(){

            let dp = new MUIDatePickerController();
            dp.init();
            dp.delegate = this;

            dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

            let pc = dp.popoverPresentationController;
            pc.sourceRect = this.dateButton.frame;
            pc.sourceView = this.dateButton;

            this.presentViewController(dp, true);
        });
    
        this.searchTextField = MUIOutlet(this, 'search-bar','MUITextField');
        this.setupSearchTextField();

        if (MIOCoreIsPhone() == false) {

            this.addLineView = MUIOutlet(this, 'add-line-cell','MUIView');
            this.containerView = MUIOutlet(this, 'gv_container3','MUIView');
            
            this.exportButton = MUIOutlet(this, 'export-btn','MUIButton');
            this.exportButton.setAction(this, function() {
                this.exportGuest();
            });
            
            this.importButton = MUIOutlet(this, 'import-btn','MUIButton');
            this.importButton.setAction(this, function() {
                this.openFile();
            });
            this.newNameTextField = MUIOutlet(this, 'name-tf', 'MUITextField');
            this.newNameTextField.setOnChangeText(this, function(textfield, value){
                this.showSelectClientView(value);
            });

            this.newNameTextField.setOnKeyPress(this, function(textfield, key) {
                
                let test = 0;
                //Catch tab key press
                if(key == 9 && this._client == null) {
                    
                    if (this.timer != null) this.timer.invalidate();
                    if(this.filterClientVC != null && this.filterClientVC.isPresented) 
                        this.filterClientVC.dismissViewController(true);   
                } 
                if(key == 9 && this._client != null) {
                    if(this._client.name.trim() != this.newNameTextField.text.trim())
                        this._client = null;
                        this.filterClientVC.dismissViewController(true);
                }
            });

            this.newPAXTextField = MUIOutlet(this, 'pax-tf','MUITextField');
            this.newPAXTextField.formatter = this.nf;

            // this.newListComboBox = MUIOutlet(this, 'guest-list-dd','MUIComboBox');
            // this.newListComboBox.setOnChangeAction(this, function(value)
            // {
            //    // if(value == -1) this._addNewGuestList();            
            // });

            // this.newSourceComboBox = MUIOutlet(this, 'source-dd','MUIComboBox');
            // this.newSourceComboBox.setOnChangeAction(this, function(value)
            // {
            //     if(value == -1) this.addNewSource();
            // });

            this.newAddButton = MUIOutlet(this, 'add-btn','MUIButton');
            this.newAddButton.setAction(this, function(){
                this.addNewLine();
            });
        }
        else
        {
            this.addButton = MUIOutlet(this, 'add-btn','MUIButton');
            this.addButton.setAction(this, function()
            {
               this.showAddView();
            });
        }
        this.tableView = MUIOutlet(this, 'table-view', 'UITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        this.tableView.reloadData();
    }
    
    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        // DBHelper.sharedInstance().addObserverForEntity(this, "BookingSource", null, null, null, function (objects) {
        //     this.fillBookingSourcesCombobox(this.newSourceComboBox, objects);
        // });

        // DBHelper.sharedInstance().addObserverForEntity(this, "BookingGuestList", null, null, null, function (objects) {
        //     this.fillListComboBox(this.newListComboBox, objects);
        // });
        // DBHelper.sharedInstance().setObserverPredicateForEntity(this, "BookingGuestList", MIOPredicate.predicateWithFormat('activeFrom <= "' + this.selectedDayString + '" and activeTo >= "' + this.selectedDayString + '"'));

    }

    viewWillDisappear(animated?) {
        super.viewWillDisappear(animated);
        // DBHelper.sharedInstance().removeObserverForChangesInEntity(this, "BookingSource");
        // DBHelper.sharedInstance().removeObserverForChangesInEntity(this, "BookingGuestList");
    }

    set client(value){
        this._client = value;
    }

    private fillBookingSourcesCombobox(comboBox, objects){
        comboBox.removeAllItems();
        comboBox.addItem(MIOLocalizeString('SOURCE','Source...'), -2);
        comboBox.addItem(MIOLocalizeString('NEW','New...'), -1);

        for (var i = 0; i < objects.length; i++) {
            let source: BookingSource = objects[i];
            comboBox.addItem(source.name, i);
        }
    }

    private fillListComboBox(comboBox, objects){
        comboBox.removeAllItems();

        for (var i = 0; i < objects.length; i++) {
            let item: BookingGuestList = objects[i];
            comboBox.addItem(item.name, i);
        }
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell:GuestCell = tableview.dequeueReusableCellWithIdentifier("GuestCell");

        let g:BookingGuest = this.fetchedResultsController.objectAtIndexPath(indexPath);

        cell.item = g;
        cell.editing = (g.day >= MIODateGetDateString(new Date()));
        cell.selectionStyle = MUITableViewCellSelectionStyle.None;
        
        return cell;
    }

    get fetchedResultsController()
    {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('activeFrom', true)];
        //var fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("BookingGuest");

        let filter = [];
        
        filter.push('day == "' + this.sdf.stringFromDate(this.date) + '"');
        
        if (this.searchString?.length > 0) {
            filter.push('name CONTAINS "' + this.searchString + '"'); // OR guestListName CONTAINS "' +  this.searchString + '" OR sourceName CONTAINS "' +  this.searchString + '")');
        }
        
        let filterString = null;
        if(filter.length > 0) {
            filterString = filter.join(' AND ');
          //  fetchRequest.predicate = MIOPredicate.predicateWithFormat(filterString);
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName('BookingGuest', sortDescriptors, filterString);
        fetchRequest.relationshipKeyPathsForPrefetching = ["guestList", "source", "recommendation"];
        fetchRequest.fetchLimit = 100;

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, moc);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;

        return this._fetchedResultsController;
    }

    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }
    
    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {

        let guest = this.fetchedResultsController.objectAtIndexPath(indexPath);    

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            this.removeGuest(guest);
        }
    }

    private removeGuest(guest){
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        
        if(this.saveActivityIndicator) this.saveActivityIndicator.setHidden(false);
        moc.deleteObject(guest);
        moc.save();
        if(this.saveActivityIndicator) this.saveActivityIndicator.setHidden(true);
        
    }

    private addNewLine(){
        let errorString = null;
        let list = this.newListComboBox.getSelectedItem();
        let source = this.newSourceComboBox.getSelectedItem();
        let pax = this.nf.numberFromString(this.newPAXTextField.text);

        if(list == -1 || list == -2) {
            errorString = 'Error, must select a list';
        }
        if(source == -1 || source == -2) {
            errorString = 'Error, must select a source';
        }
        if(pax <= 0 ){
            errorString = 'Error, check PAX';
        }
        if(this._client == null){
            errorString = 'Error, check client';
        }
        if(errorString){
            var avc = new MUIAlertViewController();
            avc.initWithTitle(MIOLocalizeString('ERROR', 'Error'), errorString, MUIAlertViewStyle.Default);
            avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, this, function(){}));
            this.presentViewController(avc, true);
            return false;
        }
        
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
       
        if(this.saveActivityIndicator) this.saveActivityIndicator.setHidden(false);
        
        let bookingGuest:BookingGuest = MIOEntityDescription.insertNewObjectForEntityForName("BookingGuest", moc) as BookingGuest;
        bookingGuest.identifier = MIOUUID.UUID().UUIDString;
        bookingGuest.client = this._client;
        bookingGuest.name = this._client.name;
        bookingGuest.date = this.date;
    
        bookingGuest.source =   DBHelper.sharedInstance().objectAtIndexForEntityObserver(this, source, 'BookingSource');
        bookingGuest.guestList = DBHelper.sharedInstance().objectAtIndexForEntityObserver(this, list, 'BookingGuestList');
        bookingGuest.pax = pax | 0;
        bookingGuest.status = 0;
        moc.save();
           
        this._client = null;
        this.newNameTextField.text = '';
        this.newPAXTextField.text = '';
        this.newListComboBox.selectItem(-2);
        this.newSourceComboBox.selectItem(-2);

        if(this.saveActivityIndicator) this.saveActivityIndicator.setHidden(true);

    }

    private addNewSource(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('NEW SOURCE','New Source'), MIOLocalizeString('CHOOSE A NAME','Choose a name '), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){

            textField.setPlaceholderText(MIOLocalizeString('NAME',"Name"));
        });
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','Done'), MUIAlertActionStyle.Default, this, function(){
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
            let nameTF =  avc.textFields[0];
            let name = nameTF.text;
            if(name.length > 0)
            {
                var bookingSource:BookingSource = MIOEntityDescription.insertNewObjectForEntityForName("BookingSource", moc) as BookingSource;
                bookingSource.identifier = MIOUUID.UUID().UUIDString;
                bookingSource.name = name;
                
                if(this.saveActivityIndicator) this.saveActivityIndicator.setHidden(false);
                moc.save();
                if(this.saveActivityIndicator) this.saveActivityIndicator.setHidden(true);
                
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'Error'), MIOLocalizeString('MUST WRITE A NAME FOR SOURCE','Must write a name for source '), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, this, function(){}));
                
                this.presentViewController(error, true);
            }
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, this, function(){}));
        
        this.presentViewController(avc, true);
    }

    private showSelectClientView(value)
    {
        if(this.timer == null) {

            this.timer =  MIOTimer.scheduledTimerWithTimeInterval(500, false, this, function(timer) 
            {
                this.timer = null;
                if(!this.filterClientVC) {
                    // this.filterClientVC = new SelectClientViewController('select_client_view');
                    // this.filterClientVC.initWithResource('layout/modals/selectclient/SelectClientView.html');
                    // this.filterClientVC.modalPresentationStyle = MUIModalPresentationStyle.Popover;
                
                    // var filterClientVC = this.filterClientVC.popoverPresentationController;
                    // filterClientVC.sourceRect = this.newNameTextField.frame;
                    // filterClientVC.sourceView = this.newNameTextField;
                    // this.filterClientVC.mode = ClientSearchMode.Client;
                    // this.filterClientVC.delegate = this;                                    
                }                                
                
                this.filterClientVC.searchString = this.newNameTextField.text;
                
                 if (!this.filterClientVC.isPresented)
                    this.presentViewController(this.filterClientVC, true);                                          
            });
        }
    }

    clientDidSelected(client)
    {
            this.client = client;
            this.newNameTextField.text = client.name;
            this.newNameTextField.becomeFirstResponder();
    }

    private exportGuest()
    {
        let ad = MUIWebApplication.sharedInstance().delegate;
        let ws = ad.webService;
        ws.exportBookingGuest(ad.selectedPlace , this.selectedDayString, this, function(code, json){
        
        });
    }

    private showAddView()
    {
        if(!this.angvc) {
            this.angvc = new AddGuestViewController('guest_new_view');
            this.angvc.delegate = this;
            this.angvc.date = this.date;
            this.angvc.initWithResource('layout/bookings/guest/GuestAddView_phone.html');  
        }
        else
            this.angvc.date = this.date;

        this.navigationController.pushViewController(this.angvc, true);
        
    }

    didSelectDate(datePickerController, date)
    {
        this.date = date;
        let show = (MIODateGetDateString(date) < MIODateGetDateString(new Date()));
        if(this.addButton) this.addButton.setHidden(show);
        if(this.addLineView) this.addLineView.setHidden(show);
        if(this.containerView)
            if(show) this.containerView.layer.style.top = "88px";
            else this.containerView.layer.style.top = "176px";
                

        
        this.selectedDayString = this.sdf.stringFromDate(date);
        this.dateButton.title = this.df.stringFromDate(date);
        this.setFetchedResultsController( null );
        this.tableView.reloadData();
        // this.needsReloadData();
        //DBHelper.sharedInstance().setObserverPredicateForEntity(this, "BookingGuestList", MIOPredicate.predicateWithFormat('activeFrom <= "' + this.selectedDayString + '" and activeTo >= "' + this.selectedDayString + '"'));

        //this.downloadBookingGuest();
        //this.downloadBookingGuestList();
    }

    openFile(){
        this.importButton.setEnabled(false); 

        let panel = MUIOpenPanel.openPanel();
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        panel.beginSheetModalForWindow(ad.window, this, function(this:GuestViewController, result:number){
            this.importButton.setEnabled(true); 
            if (result == MIOFileHandlingPanel.OKButton) {
               let file = panel.files[0];
               // Open the document.
               this.parseFileItems(file);
            }       
         });
    }
    
    private importer:DBBookingGuestCoder = null;
    parseFileItems(file) {        

        this.importer = new DBBookingGuestCoder();
        this.importer.init();

         this.importer.parseFile(file, (items) => {
            this.importer.importItems(items);
        });        
    }
}
