

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Booking_BookingModifier_ManagedObject

class Booking_BookingModifier_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: bookingModifierID
    set bookingModifierID(value:string) {
        this.setValueForKey(value, 'bookingModifierID');
    }
    get bookingModifierID():string {
        return this.valueForKey('bookingModifierID');
    }
    set bookingModifierIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'bookingModifierID');
    }
    get bookingModifierIDPrimitiveValue():string {
        return this.primitiveValueForKey('bookingModifierID');
    }

    // Property: quantity
    set quantity(value:number) {
        this.setValueForKey(value, 'quantity');
    }
    get quantity():number {
        return this.valueForKey('quantity');
    }
    set quantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'quantity');
    }
    get quantityPrimitiveValue():number {
        return this.primitiveValueForKey('quantity');
    }
    // Relationship: booking
    set booking(value:Booking) {
        this.setValueForKey(value, 'booking');
    }
    get booking():Booking {
        return this.valueForKey('booking') as Booking;
    }
}
