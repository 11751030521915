//
// Generated class Offer
//

/// <reference path="Offer_ManagedObject.ts" />

enum OfferType {
    Money,
    Discount,
    Rate,
    InviteProductHigh,
    InviteProductLow,
    FreeSpecificProduct,
    FreeProducts
};

class Offer extends Offer_ManagedObject
{
    static typeString(type){
        switch(type){
            case OfferType.Money:
            return MIOLocalizeString("MONEY", "MONEY");

            case OfferType.Discount:
            return MIOLocalizeString("DISCOUNT", "DISCOUNT");

            case OfferType.Rate:
            return MIOLocalizeString("RATE", "RATE");

            case OfferType.InviteProductHigh:
            return MIOLocalizeString("INVITED PRODUCT HIGH", "INVITED PRODUCT HIGH");

            case OfferType.InviteProductLow:
            return MIOLocalizeString("INVITED PRODUCT LOW", "INVITED PRODUCT LOW");

            case OfferType.FreeSpecificProduct:
            return MIOLocalizeString("FREE SPECIFIC PRODUCT", "FREE SPECIFIC PRODUCT");            

            case OfferType.FreeProducts:
            return MIOLocalizeString("FREE PRODUCTS", "FREE PRODUCTS");            
        }

        return null;
    }

    static _typeStrings = null;
    static typeStrings(){
        if (this._typeStrings != null) return this._typeStrings;

        let array = [];
        array.addObject(MIOLocalizeString("MONEY", "MONEY"));
        array.addObject(MIOLocalizeString("DISCOUNT", "DISCOUNT"));
        array.addObject(MIOLocalizeString("RATE", "RATE"));
        array.addObject(MIOLocalizeString("INVITED PRODUCT HIGH", "INVITED PRODUCT HIGH"));
        array.addObject(MIOLocalizeString("INVITED PRODUCT LOW", "INVITED PRODUCT LOW"));
        array.addObject(MIOLocalizeString("FREE SPECIFIC PRODUCT", "FREE SPECIFIC PRODUCT"));
        array.addObject(MIOLocalizeString("FREE PRODUCTS", "FREE PRODUCTS"));

        this._typeStrings = array;
        return array;
    }

    get typeString(){
        return Offer.typeString(this.type);
    }
}
