//
// Generated class CashDeskSession
//

/// <reference path="CashDeskSession_ManagedObject.ts" />

class CashDeskSession extends CashDeskSession_ManagedObject
{
    get name(){
        if (this.cashDesk == null) return "MANAGER CASHDESK";
        else return this.cashDesk.name;
    }

    get deviceString(){
        if (this.placeID == null) return this.name;

        let place = AppHelper.sharedInstance().placesByReferenceID[this.placeID];
        if (place == null) return this.name;
        
        return place.name + " - " + this.name;
    }
}
