//
// Generated class ProductModifierName
//

/// <reference path="ProductModifierName_ManagedObject.ts" />

class ProductModifierName extends ProductModifierName_ManagedObject
{

}
