
class AppConfigurationViewController extends MUIViewController
{    
    static newInstance():AppConfigurationViewController {
        let vc = new AppConfigurationViewController("app-configuration-view");
        vc.initWithResource("layout/app-configuration/AppConfigurationView.html");
        return vc;
    }

    private exportButton:MUIButton = null;
    private importButton:MUIButton = null;
    private saveButton:MUIButton = null;

    private tableView:UITableView = null;

    private searchString:string = null;

    viewDidLoad(){
        this.exportButton = MUIOutlet(this, "export-file-btn", "MUIButton");
        this.exportButton.setAction(this, this.exportFile);

        this.importButton = MUIOutlet(this, "import-file-btn", "MUIButton");
        this.importButton.setAction(this, this.importFile);

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction( this, function() {
            DBHelper.saveMainContext();
        })

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private app:Application = null;
    set item(item:Application) {
        this.app = item;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this.app == null) return;

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.count;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){                                
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as AppConfiguration;
        let cell = tableview.dequeueReusableCellWithIdentifier("AppConfigurationCell") as AppConfigurationCell;
        
        cell.item = item;
    
        return cell;
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;

    set fetchedResultsController (value:MIOFetchedResultsController) {
        if (value == null && this._fetchedResultsController != null)
        this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let sd = [MIOSortDescriptor.sortDescriptorWithKey('key', true)];    
        let predicateFormat = "appID = " + this.app.identifier;

        if (this.searchString != null) {
            predicateFormat = "AND key CONTAINS '" + this.searchString + "'";
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("AppConfiguration", sd, predicateFormat);        
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        this.tableView.reloadData();
    }

    private exportFile(){
        let exporter = new DBAppConfigurationCoder();
        exporter.init();

        exporter.appID = this.app.identifier;

        exporter.export();
    }

    private importFile(){
        this.importButton.setEnabled(false); 
    
        let panel = MUIOpenPanel.openPanel();
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
    
        panel.beginSheetModalForWindow(ad.window, this, function(this:AppConfigurationViewController, result:number){
            if (result == MIOFileHandlingPanel.OKButton) {
                let file = panel.files[0];
                // Open the document.
                this.import(file);
            }       
        });    
    }

    private import(file){
        let importer = new DBAppConfigurationCoder();
        importer.init();

        importer.appID = this.app.identifier;

        importer.parseFile(file, (items) => {
            importer.importItems(items);
            this.importButton.setEnabled(true);
            this.tableView.reloadData();    
        });  
    }
}