//
// Generated class ProductMenu
//

/// <reference path="ProductMenu_ManagedObject.ts" />

class ProductMenu extends ProductMenu_ManagedObject
{
    get categoryOrderIndex() : string {
        return String( this.menuCategory.orderIndex ) + "." + this.menuCategory.name;
    }
}
