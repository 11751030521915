
class BookingZoneDayLocationTypeDataSource extends MIOObject
{
    tableView:MUITableView = null;
    
    private _relationZoneDay:RelationBookingDay = null;
    set relationZoneDay(day:RelationBookingDay){
        this._relationZoneDay = day;        
        this.fetchedResultsController = null;
        if (day == null) {
            this.items = [];
        }
        else {            
            let objects = this.fetchedResultsController.fetchedObjects;
            this.mapRules(objects);    
        }
        this.tableView.reloadData();
    }

    numberOfSections(tableview:MUITableView){
        // if (this._relationZoneDay == null) return 1;
        
        // return this.fetchedResultsController.sections.length;
        return 1;
    }
    
    numberOfRowsInSection(tableview:MUITableView, section){
        if (this._relationZoneDay == null) return 0;
        
        // let sec = this.fetchedResultsController.sections[section];
        // return sec.numberOfObjects();

        return this.items.length;
    }
    
    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath){        
        let cell = null;

        //let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as BookingLocationType;
        let item = this.items[indexPath.row];
        if (item["Type"] == "table"){
            cell = tableview.dequeueReusableCellWithIdentifier('LocTypeCell') as BookingZoneDayLocationTypeCell;
            cell.item = item["Obj"];
        }
        else if (item["Type"] == "rule"){
            cell = tableview.dequeueReusableCellWithIdentifier('LocTypeRuleCell') as BookingZoneDayLocationTypeRuleCell;
            cell.item = item["Obj"];
        }
        
        return cell;        
    }

    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        //let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as BookingLocationType;
        let item = this.items[indexPath.row];

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            this.removeItem(item);
        }
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null){
            return this._fetchedResultsController;
        }        

        if (this._relationZoneDay == null) return null;

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        
        let predicate = "relationDay.identifier == '" + this._relationZoneDay.identifier + "'";
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("BookingLocationType", null, predicate);
        fetchRequest.relationshipKeyPathsForPrefetching = ["priceRules"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){        
        this.mapRules(controller.resultObjects);
        this.tableView.reloadData();
    }

    private removeItem(item){

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        
        if (item["Type"] == "rule") {
            let rule = item["Obj"] as BookingLocationPriceRule;
            ad.managedObjectContext.deleteObject(rule);
        }
        else if (item["Type"] == "table") {
            let table = item["Obj"] as BookingLocationType;                        
            for (let index = 0; index < table.priceRules.count; index++){
                let rule = table.priceRules.objectAtIndex(index);
                ad.managedObjectContext.deleteObject(rule);
            }
            ad.managedObjectContext.deleteObject(table);
        }
        
        ad.managedObjectContext.save();
    }

    private items = [];
    private mapRules(objects){

        let items = [];
        for (let index = 0; index < objects.length; index++){
            let table = objects[index] as BookingLocationType;

            let parentItem = {};
            parentItem["Type"] = "table";
            parentItem["Obj"] = table;
            items.addObject(parentItem);

            for (let count = 0; count < table.priceRules.count; count++){
                let rule = table.priceRules.objectAtIndex(count);
                let item = {};
                item["Type"] = "rule";
                item["Obj"] = rule;

                items.addObject(item);
            }
        }

        this.items = items;
    }

}