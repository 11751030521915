

// Generated class ReportFilter_ManagedObject

class ReportFilter_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: title
    set title(value:string) {
        this.setValueForKey(value, 'title');
    }
    get title():string {
        return this.valueForKey('title');
    }
    set titlePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'title');
    }
    get titlePrimitiveValue():string {
        return this.primitiveValueForKey('title');
    }

    // Property: key
    set key(value:string) {
        this.setValueForKey(value, 'key');
    }
    get key():string {
        return this.valueForKey('key');
    }
    set keyPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'key');
    }
    get keyPrimitiveValue():string {
        return this.primitiveValueForKey('key');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: entityName
    set entityName(value:string) {
        this.setValueForKey(value, 'entityName');
    }
    get entityName():string {
        return this.valueForKey('entityName');
    }
    set entityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'entityName');
    }
    get entityNamePrimitiveValue():string {
        return this.primitiveValueForKey('entityName');
    }

    // Property: entityTitleKey
    set entityTitleKey(value:string) {
        this.setValueForKey(value, 'entityTitleKey');
    }
    get entityTitleKey():string {
        return this.valueForKey('entityTitleKey');
    }
    set entityTitleKeyPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'entityTitleKey');
    }
    get entityTitleKeyPrimitiveValue():string {
        return this.primitiveValueForKey('entityTitleKey');
    }

    // Property: entityValueKey
    set entityValueKey(value:string) {
        this.setValueForKey(value, 'entityValueKey');
    }
    get entityValueKey():string {
        return this.valueForKey('entityValueKey');
    }
    set entityValueKeyPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'entityValueKey');
    }
    get entityValueKeyPrimitiveValue():string {
        return this.primitiveValueForKey('entityValueKey');
    }

    // Property: entityPredicateFormat
    set entityPredicateFormat(value:string) {
        this.setValueForKey(value, 'entityPredicateFormat');
    }
    get entityPredicateFormat():string {
        return this.valueForKey('entityPredicateFormat');
    }
    set entityPredicateFormatPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'entityPredicateFormat');
    }
    get entityPredicateFormatPrimitiveValue():string {
        return this.primitiveValueForKey('entityPredicateFormat');
    }

    // Property: values
    set values(value:string) {
        this.setValueForKey(value, 'values');
    }
    get values():string {
        return this.valueForKey('values');
    }
    set valuesPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'values');
    }
    get valuesPrimitiveValue():string {
        return this.primitiveValueForKey('values');
    }

    // Property: allowMultipleSelection
    set allowMultipleSelection(value:boolean) {
        this.setValueForKey(value, 'allowMultipleSelection');
    }
    get allowMultipleSelection():boolean {
        return this.valueForKey('allowMultipleSelection');
    }
    set allowMultipleSelectionPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'allowMultipleSelection');
    }
    get allowMultipleSelectionPrimitiveValue():boolean {
        return this.primitiveValueForKey('allowMultipleSelection');
    }
    // Relationship: report
    set report(value:Report) {
        this.setValueForKey(value, 'report');
    }
    get report():Report {
        return this.valueForKey('report') as Report;
    }
}
