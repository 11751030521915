//
// Generated class SalesComponentAnnotation
//

/// <reference path="SalesComponentAnnotation_ManagedObject.ts" />

class SalesComponentAnnotation extends SalesComponentAnnotation_ManagedObject
{

}
