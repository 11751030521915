

class ProductAttributeConfigDetailViewController extends MUIViewController
{

    static newInstance() : ProductAttributeConfigDetailViewController {
        let vc = new ProductAttributeConfigDetailViewController('product-attribute-config-detail-view');
        vc.initWithResource('layout/productattributetype/ProductAttributeConfigDetailView.html');
        return vc;
    }

    private backButton:MUIButton = null;
    private titleLabel:MUILabel = null;
    private addButton:MUIButton = null;
    private tableView:UITableView = null;

    delegate = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, 'back-btn', 'MUIButton');
        this.backButton.setAction(this, function (){
            this.navigationController.popViewController(true);
        });

        this.titleLabel = MUIOutlet(this, 'title-lbl', 'MUILabel');

        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function(){
            this.addProductAttribute();
        });

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(animate?) {
        super.viewWillAppear(animate);        
        this.updateUI();
    }

    viewWillDisappear(animated?: boolean): void {
        super.viewWillDisappear(animated);
    }

    private _selectedProductAttributeType:ProductAttributeType = null;
    set item (item:ProductAttributeType) {
        if (item == null) return;

        this._selectedProductAttributeType = item;
    }

    private updateUI(){
        if (this.viewIsLoaded == false && this._selectedProductAttributeType == null) return;
      
        this.titleLabel.text = MIOLocalizeString("PRODUCT ATTRIBUTES FOR ","SELECT ATTRIBUTES FOR ") + this._selectedProductAttributeType.name;

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }
    
    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('ProductAttributeConfigDetailCell') as ProductAttributeConfigDetailCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ProductAttribute;
        cell.item = item;
        cell.selectionStyle = UITableViewCellSelectionStyle.None; 
        return cell;
    }
        
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        // no cell interaction
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
        return UITableViewCellEditingStyle.Delete;
    }
    
    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ProductAttribute;

        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            DBHelper.deleteObjectFromMainContext(item, true);
        }
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
        
        let ad = MUIWebApplication.sharedInstance().delegate;
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('orderindex', true),
                                MIOSortDescriptor.sortDescriptorWithKey('name', true) ];
        let predicateFormat = 'productAttributeType.identifier == ' + this._selectedProductAttributeType.identifier;
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("ProductAttribute", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["productAttributeType"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;            
        
        fetchedResultsController.performFetch();
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    
    private addProductAttribute(){

        var avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('NEW PRODUCT ATTRIBUTE','NEW PRODUCT ATTRIBUTE'), MIOLocalizeString('CHOOSE A NAME','CHOOSE A NAME '), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD','ADD'), MUIAlertActionStyle.Default, this, function(){
            let nameTF =  avc.textFields[0];
            let name = nameTF.text.trim();

            if(name.length > 0){
                let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
                let newObject = MIOEntityDescription.insertNewObjectForEntityForName("ProductAttribute", moc) as ProductAttribute;
                newObject.identifier = MIOUUID.UUID().UUIDString;
                newObject.name = name;
                newObject.productAttributeType = this._selectedProductAttributeType;
                moc.save();
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('CHECK NAME','CHECK NAME'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CLOSE','CLOSE'), MUIAlertActionStyle.Cancel, this, function(){}));
                
                this.presentViewController(error, true);
            }
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, this, function(){}));
        
        this.presentViewController(avc, true);
    }
}
