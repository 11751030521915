

class StockNoteCell extends UITableViewCell
{
    protected documentIDLabel:MUILabel = null;  
    protected documentDateLabel:MUILabel = null;  
    protected titleLabel:MUILabel = null;

    awakeFromHTML(){
        this.documentIDLabel = MUIOutlet(this, "document-id-lbl", "MUILabel");
        this.documentDateLabel = MUIOutlet(this, "date-lbl", "MUILabel");
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");

        this.separatorStyle = UITableViewCellSeparatorStyle.None;
    }

    setItem (item:StockNote){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this.documentIDLabel.text = item.documentID;
        this.documentDateLabel.text = ad.dateFormatter.stringFromDate(item.stockDate);        
    }

    setTitle(title:string){
        this.titleLabel.text = title;
    }
}