class ClientBankInfoCell extends UITableViewCell
{
    private titleTextField:MUITextField = null;
    private accountTextField:MUITextField = null;
    private codeTextField:MUITextField = null;

    awakeFromHTML(): void {
     
        this.titleTextField = MUIOutlet(this, "bank-account-name-tf", "MUITextField");
        this.titleTextField.setOnChangeText(this, function(this:ClientBankInfoCell, control:MUITextField, value:string){
            this.bankInfo.title = value;
        });

        this.accountTextField = MUIOutlet(this, "bank-account-number-tf", "MUITextField");
        this.accountTextField.setOnChangeText(this, function(this:ClientBankInfoCell, control:MUITextField, value:string){
            this.bankInfo.number = value;
        });
        
        this.codeTextField = MUIOutlet(this, "bank-account-identifier-tf", "MUITextField");
        this.codeTextField.setOnChangeText(this, function(this:ClientBankInfoCell, control:MUITextField, value:string) {
            this.bankInfo.bankCode = value.length > 0 ? value.trim() : null;
        });

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private bankInfo:LegalEntityBankAccountInfo = null;
    set item(item:LegalEntityBankAccountInfo) {
        this.bankInfo = item;

        this.titleTextField.text = item.title;
        this.accountTextField.text = item.number;
        this.codeTextField.text = item.bankCode;
    }
}