//
// Generated class LegalEntityBankAccountInfo
//

/// <reference path="LegalEntityBankAccountInfo_ManagedObject.ts" />

class LegalEntityBankAccountInfo extends LegalEntityBankAccountInfo_ManagedObject
{

}
