
class CashDeskSessionTicketTransactionCell extends MUITableViewCell
{
    private paymethodIcon:MUIImageView = null;
    private paymethodLabel:MUILabel = null;
    private amountLabel:MUILabel = null;

    awakeFromHTML(){
        this.paymethodIcon = MUIOutlet(this, "icon-view", "MUIImageView")
        this.paymethodLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.amountLabel = MUIOutlet(this, "total-lbl", "MUILabel");
    }

    set item (item:CashDeskLine){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        this.paymethodIcon.setImage(null);
        this.paymethodLabel.text = null;

        this.paymethodLabel.text = item.typeString();
        
        if (item.payMethod != null) {
            if (item.payMethod.image != null) this.paymethodIcon.setImage(item.payMethod.image.url);
            this.paymethodLabel.text = item.payMethodName;
        }
        else if (item.paymentType == 2) {            
            this.paymethodLabel.text = MIOLocalizeString("CUSTOMER ACCOUNT", "CUSTOMER ACCOUNT");
        }
        else {
            this.paymethodLabel.text = item.typeString();
        }

        this.paymethodLabel.text = this.paymethodLabel.text + " " + ad.dateTimeFormatter.stringFromDate( item.date );
        this.amountLabel.text = ad.currencyFormatter.stringFromNumber(item.amount);
    }
}