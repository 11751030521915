/**
 * Created by miguel on 10/2/17.
 */




enum SectionType
{
    None ,
    SectionBar,
    SectionTable,
    SectionOnline
}

class CategorySectionViewController extends MUIViewController
{
    static newInstace() : CategorySectionViewController {
        let vc = new CategorySectionViewController("category-section-view");
        vc.initWithResource("layout/productcategory/CategorySectionView.html");
        return vc;
    }


    private options = [
        {type:SectionType.SectionBar,       name : MIOLocalizeString("BAR" , "BAR"), selected : true},
        {type:SectionType.SectionTable,     name : MIOLocalizeString("TABLES" , "TABLES"), selected : true},
        {type:SectionType.SectionOnline,    name : MIOLocalizeString("ONLINE ORDERS" , "ONLINE ORDERS"), selected : true},
    ];

    private tableView:UITableView = null;    

    viewDidLoad()
    {
        super.viewDidLoad();

        this.tableView = MUIOutlet(this, 'table-view', 'UITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        this.tableView.reloadData();
    }

    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private updateUI() {
        if (this.viewIsLoaded == false || this._category == null) return;
        this.tableView.reloadData();
    }

    private isSectionEnabled(section:number):boolean
    {
        let enabled:boolean = false;

        switch (section) {
           
            case SectionType.SectionBar:
                enabled = this._category.showInBar;
                break;
            case SectionType.SectionTable:
                enabled = this._category.showInTables;
                break;
            case SectionType.SectionOnline:
                enabled = this._category.showInOnline;
                break;
        }

        return enabled;
    }

    private toggleSection(section:number)
    {
        switch (section) {
            
            case SectionType.SectionBar:
                this._category.showInBar = !this._category.showInBar;
                break;
            case SectionType.SectionTable:
                this._category.showInTables = !this._category.showInTables;
                break;
            case SectionType.SectionOnline:
                this._category.showInOnline = !this._category.showInOnline;
                break;
        }
    }

    private _category:ProductCategory = null;
    set category(value:ProductCategory)
    {
        this._category = value;
        this.updateUI();
    }

    numberOfSections(tableview) { return 1; }
    numberOfRowsInSection(tableview, section) { return this.options.length }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath)
    {
        let cell = tableview.dequeueReusableCellWithIdentifier("CategorySectionCell") as CategorySectionCell;

        let item = this.options[indexPath.row];

        cell.item = item;
        cell.accessoryType = this.isSectionEnabled(item.type) ? UITableViewCellAccessoryType.Checkmark : UITableViewCellAccessoryType.None;

        return cell;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath)
    {
        let item = this.options[indexPath.row];

        this.toggleSection(item.type);
        let cell = this.tableView.cellAtIndexPath(indexPath);
        if (cell != null) {
            cell.accessoryType = this.isSectionEnabled(item.type) ? UITableViewCellAccessoryType.Checkmark : UITableViewCellAccessoryType.None;
        }         
    }
}