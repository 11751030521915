

class CashDeskSessionSummaryViewController extends MUIViewController
{       
    private statusBar:MUIView = null;
    private appLabel:MUILabel = null;
    private openDateLabel:MUILabel = null;
    private closeDateLabel:MUILabel = null;
    private openWorkerLabel:MUILabel = null;
    private closeWorkerLabel:MUILabel = null;
    private sessionDateLabel:MUILabel = null;
 
    private reportView:MUIView = null;
    reportViewController:ReportViewController = null;
 
    private dtf = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter;
    private ldf = MUIWebApplication.sharedInstance().delegate.longDateFormatter;

    viewDidLoad(){
        super.viewDidLoad();

        this.statusBar = MUIOutlet(this, "status-view", "MUIView");        

        this.appLabel = MUIOutlet(this, "app-lbl", "MUILabel");
        this.openWorkerLabel = MUIOutlet(this, 'cdssv_open_worker_lbl','MUILabel');
        this.openDateLabel = MUIOutlet(this, 'cdssv_open_date_lbl','MUILabel');
        this.closeWorkerLabel = MUIOutlet(this, 'cdssv_close_worker_lbl','MUILabel');
        this.closeDateLabel = MUIOutlet(this, 'cdssv_close_date_lbl','MUILabel');
        this.sessionDateLabel = MUIOutlet(this, 'session-date-lbl', "MUILabel");

        this.reportView = MUIOutlet(this, 'report-view', 'MUIView');

        let vc = new ReportViewController("report-detail-view");
        vc.initWithResource("layout/reports/ReportDetailView.html");
        vc.title = MIOLocalizeString("CASHDESK SESSION SUMMARY", "CASHDESK SESSION SUMMARY");
        vc.reportScriptPath = "python/cashdesk_section_session_summary.py";          
        vc.filterValues = this.filterValues;
        vc.showNavigationBar = false;
                    
        this.reportViewController = vc;
        this.reportView.addSubview(this.reportViewController.view);
        this.addChildViewController(vc);
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private filterValues = null;
    private _session:CashDeskSession = null;
    set session(value:CashDeskSession){
        this._session = value;
        this.filterValues = {"id_session": value.identifier};
        if (this.reportViewController != null) this.reportViewController.filterValues = this.filterValues;
        this.updateUI();
    }

    private updateUI(){
        if(this.viewIsLoaded == false || this._session == null) return;
        
        this.appLabel.text = this._session.name;
        this.openDateLabel.text = this.dtf.stringFromDate(this._session.beginDate);
        this.closeDateLabel.text = this.dtf.stringFromDate(this._session.endDate);
        this.openWorkerLabel.text = this._session.beginWorkerName;
        this.closeWorkerLabel.text = this._session.endWorkerName;
        this.sessionDateLabel.text = this.ldf.stringFromDate(this._session.serverSessionBeginDate);

        if (this._session.isOpen == true) {
            MUICoreLayerRemoveStyle(this.statusBar.layer, "alert");
            MUICoreLayerAddStyle(this.statusBar.layer, "active");
        }
        else if (this._session.isCashDeskCounted == false) {
            MUICoreLayerRemoveStyle(this.statusBar.layer, "active");
            MUICoreLayerAddStyle(this.statusBar.layer, "alert");
        }
        else {
            MUICoreLayerRemoveStyle(this.statusBar.layer, "alert");
            MUICoreLayerRemoveStyle(this.statusBar.layer, "active");
        }
    }
}