

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class IntegratorLog_ManagedObject

class IntegratorLog_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: syncID
    set syncID(value:number) {
        this.setValueForKey(value, 'syncID');
    }
    get syncID():number {
        return this.valueForKey('syncID');
    }
    set syncIDPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'syncID');
    }
    get syncIDPrimitiveValue():number {
        return this.primitiveValueForKey('syncID');
    }

    // Relationship: integratorSyncs
    protected _integratorSyncs:MIOManagedObjectSet = null;
    get integratorSyncs():MIOManagedObjectSet {
        return this.valueForKey('integratorSyncs');
    }
    addIntegratorSyncsObject(value:IntegratorSync) {
        this._addObjectForKey(value, 'integratorSyncs');
    }
    removeIntegratorSyncsObject(value:IntegratorSync) {
        this._removeObjectForKey(value, 'integratorSyncs');
    }
}
