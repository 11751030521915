

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class MemoLog_ManagedObject

class MemoLog_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: message
    set message(value:string) {
        this.setValueForKey(value, 'message');
    }
    get message():string {
        return this.valueForKey('message');
    }
    set messagePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'message');
    }
    get messagePrimitiveValue():string {
        return this.primitiveValueForKey('message');
    }

    // Property: userName
    set userName(value:string) {
        this.setValueForKey(value, 'userName');
    }
    get userName():string {
        return this.valueForKey('userName');
    }
    set userNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'userName');
    }
    get userNamePrimitiveValue():string {
        return this.primitiveValueForKey('userName');
    }
    // Relationship: user
    set user(value:User) {
        this.setValueForKey(value, 'user');
    }
    get user():User {
        return this.valueForKey('user') as User;
    }
}
