//
// Generated class OnlineOrderPaymentTransaction
//

/// <reference path="OnlineOrderPaymentTransaction_ManagedObject.ts" />

class OnlineOrderPaymentTransaction extends OnlineOrderPaymentTransaction_ManagedObject
{

}
