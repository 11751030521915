
class AnonymousCardCell extends UITableViewCell
{
    private codeTextField:MUITextField = null;
    private expirationDateTextField:MUITextField = null;
    private amountLabel:MUILabel = null;
    private remainingLabel:MUILabel = null;

    private dtf = (MUIWebApplication.sharedInstance().delegate as AppDelegate).dateTimeFormatter;

    awakeFromHTML(){
        this.codeTextField = MUIOutlet(this, "code-tf", "MUITextField");
        this.codeTextField.setOnChangeText(this, function(control, value){
            this.card.barcode = value;
        });

        this.expirationDateTextField = MUIOutlet(this, "expiration-date-tf", "MUITextField");
        this.expirationDateTextField.setOnChangeText(this, function(this:AnonymousCardCell, control,value) {            
            this.card.expirationDate = value.length > 0 ? this.dtf.dateFromString(value) : null;
        });

        this.amountLabel = MUIOutlet(this, "amount-lbl", "MUILabel");
        this.remainingLabel = MUIOutlet(this, "remaining-lbl", "MUILabel");

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private card:Card = null;
    set item(value:Card){
        this.card = value;
        // this.nameLabel.text = value.name;
        this.codeTextField.text = value.barcode;
        this.expirationDateTextField.text = value.expirationDate != null ? this.dtf.stringFromDate(value.expirationDate) : null        
    }
}