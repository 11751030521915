//
// Generated class PriceGroup
//

/// <reference path="PriceGroup_ManagedObject.ts" />

class PriceGroup extends PriceGroup_ManagedObject
{

}
