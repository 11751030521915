class OnlinePresetConfigCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    private editButton:MUIButton = null;
    private rulesButton:MUIButton = null;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "config-name-lbl", "MUILabel");

        this.editButton = MUIOutlet(this, 'edit-btn','MUIButton');
        this.editButton.setAction(this, function(button, value){
            this.showAddOnlineConfigurationsViewController();
        });

        this.rulesButton = MUIOutlet(this, 'rules-btn','MUIButton');
        this.rulesButton.setAction(this, function(button, value){
            alert("Rules button");
        });  

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private _item:Configuration = null;
    set item (item:Configuration){
        this._item = item;  
        
        this.nameLabel.text = this._item.name;
    }

    private addOnlineConfigurationsViewController = null;
    private showAddOnlineConfigurationsViewController() {
        if(this.addOnlineConfigurationsViewController == null) {
            this.addOnlineConfigurationsViewController = new AddOnlineConfigurationsViewController('add-online-configuration-view');            
            this.addOnlineConfigurationsViewController.initWithResource('layout/onlinesettings/AddOnlineConfigurationView.html');
            this.addOnlineConfigurationsViewController.delegate = this;
            this.addOnlineConfigurationsViewController.item = this._item;
        }
        AppHelper.sharedInstance().presentViewController(this.addOnlineConfigurationsViewController, true);
    }

}