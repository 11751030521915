/**
 * Created by miguel on 23/2/17.
 */


/// <reference path="LapsopayLocationListViewController.ts" />


class LapsopayPlaceListViewController extends MUIViewController
{
    private _place = null;
    private _tableView = null;
    private _fetchedResultsController = null;
    private _searchTextField = null;
    private _searchString = null;

    private _lpdvc = null;

    viewDidLoad()
    {
        super.viewDidLoad();

        this._searchTextField = MUIOutlet(this, 'lplv_tv_search_bar', 'MUITextField');
        this._searchTextField.setOnChangeText(this, function (textfield, value) {

            clearTimeout(this.delayTimer);
            var instance = this;

            instance.delayTimer = setTimeout(function() {

                instance.fetchedResultsController = null;

                if (value.length > 0)
                    instance._searchString = value;
                else
                    instance._searchString = null;

                instance._tableView.reloadData();

            }, 500, instance);
        });

        this._tableView = MUIOutlet(this, 'lplv_tableview', 'MUITableView');
        this._tableView.dataSource = this;
        this._tableView.delegate = this;

        this._downloadPlaces();
    }

    private _downloadPlaces()
    {
        var ws = MUIWebApplication.sharedInstance().delegate.webService;

        ws.getLapsoPlaces(this, function(error, result) {
            this._tableView.reloadData();
        });
    }

    numberOfSections(tableview)
    {
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview, section)
    {
        var sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath)
    {
        var cell = tableview.dequeueReusableCellWithIdentifier("LapsopayPlaceCell");

        var item = this.fetchedResultsController.objectAtIndexPath(indexPath);

        cell.item = item;

        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;

        return cell;
    }

    canSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath)
    {
        if(this._lpdvc && this._lpdvc.hasChanges){

            if (confirm("Se perderán los cambios realizados en el place. \n¿Desea continuar?")) {

            } else {
                return false;
            }
        }

        return true;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath)
    {
        var obj = this.fetchedResultsController.objectAtIndexPath(indexPath);

        if(!this._lpdvc) {

            this._lpdvc =  new LapsopayLocationListViewController("lapso_pay_detail_view");
            this._lpdvc.initWithResource("layout/external/lapsopay/LapsoPayDetailView.html");

            //this._lpdvc = new MIONavigationController("worker_detail_nav_controller");
            //this._lpdvc.initWithRootViewController(lpdvc);
        }
        this._lpdvc.place = obj;

        this.splitViewController.showDetailViewController(this._lpdvc);

     }

     set fetchedResultsController(value)
     {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController()
    {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        var ad = MUIWebApplication.sharedInstance().delegate;

        var fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("LapsopayPlace");

        fetchRequest.sortDescriptors = [ /*MIOSortDescriptor.sortDescriptorWithKey("categoryName", true),*/
                                            MIOSortDescriptor.sortDescriptorWithKey("name", true)];



        if (this._searchString != null)
            fetchRequest.predicate = MIOPredicate.predicateWithFormat("name CONTAINS " + this._searchString);

        var fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;

        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller)
    {
        this._tableView.reloadData();
    }
}