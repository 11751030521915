
class BusinessLicensesViewController extends MUIViewController
{    
    static newInstance() : BusinessLicensesViewController {
        let vc = new BusinessLicensesViewController("");
        vc.initWithResource("");
        return vc;
    }

    private saveButton:MUIButton = null;
    private addButton:MUIButton = null;

    private tableView:UITableView = null;


    viewDidLoad(){
        super.viewDidLoad();

        // this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        // this.setupSearchTextField();

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function(){
            
        })

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        })

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;   
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }
    
    private updateUI(){
        if(this.viewIsLoaded == false) return;
        
        this.fetchedResultsController = null;
        this.tableView.reloadData();        
    }

    numberOfSections(tableview:UITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as BusinessLicense;        
        let cell = tableview.dequeueReusableCellWithIdentifier("PlaceLicenseCell") as BusinessLicenseCell;        
 
        // cell.item = item;
        
        return cell;
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
        // return UITableViewCellEditingStyle.Delete;
    }
    
    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        // let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as NumberSerialSequence;

        // if (editingStyle == UITableViewCellEditingStyle.Delete) {
        //     DBHelper.deleteObjectFromMainContext(item, true);
        // }
    }

    didSelectCellAtIndexPath(tableView,indexPath:MIOIndexPath){
        // this.businessLicense = this.fetchedResultsController.objectAtIndexPath(indexPath);
        // this.showSelectedItem(this.businessLicense);
    }
    
    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("BusinessLicense");
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        fetchRequest.fetchLimit = 100;        

        // if (this.searchString != null) {
        //     let predicateFormat = "name CONTAINS '" + this.searchString + "'";
        //     fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);
        // }        

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    // public clientDidInserted(businessLicense:BusinessLicense){
    //     this.businessLicense = businessLicense;
    //     this.tableView.reloadData();
    // }

    private showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("License", NoItemSelectedViewControllerType.License);        
        this.splitViewController.showDetailViewController(vc);
    }

    // OLD LICENSE VIEW
    
    // private backButton:MUIButton = null;            
    // private segmentedControl:MUISegmentedControl = null;
    // private addButton: MUIButton = null;

    // private searchIsActive:MUISwitchButton = null;
    // private searchIsInProgress:MUISwitchButton = null;
    // private searchIsValid:MUISwitchButton = null;
    
    // private orderApp = null;
    // private orderAppValue = true;
    // private orderAppType= null;
    // private orderAppTypeValue = true;
    // private orderInfo = null;
    // private orderInfoValue = true;
    // private orderBeginDate = null;
    // private orderBeginDateValue = true;
    // private orderEndDate = null;
    // private orderEndDateValue = true;
    // private orderType = null;
    // private orderTypeValue = true;
    // private orderAppName = null;
    // private orderAppNameValue = true;

    // private orderString = null;
    // private orderValue = true;

    
    // private sectionsPageController = null;
    // private filterStatus = 0; //filterStatus is the index of segmented, 0 is the first button (all in this case)

    // private tableView: MUITableView = null;    

    // private ad: AppDelegate = MUIWebApplication.sharedInstance().delegate;
    // private df = this.ad.dateFormatter;

    // viewDidLoad(){ 
    //     super.viewDidLoad();

    //     this.backButton = MUIOutlet(this, "back-btn", "MUIButton");        
    //     if (this.backButton) this.backButton.setAction(this, function(){
    //         if (this.splitViewController != null) {
    //             let target = this.splitViewController.displayModeButtonItem.target;
    //             let action = this.splitViewController.displayModeButtonItem.action;            
    //             action.call(target);
    //         }
    //     });        

    //     this.segmentedControl = MUIOutlet(this, "segmented-control", "MUISegmentedControl");
    //     if (this.segmentedControl != null) this.segmentedControl.setAction(this, function (control, index) {        
    //         this.filterStatus = index;            
    //         this.fetchedResultsController = null;            
    //         this.tableView.reloadData();
    //     });

    //     this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');        
    //     this.addButton.setAction(this, function (){            
    //         this.newLicenseDetail();
    //     });

    //     this.orderApp = MUIOutlet(this, "order-app-btn", "MUIButton");
    //     if (this.orderApp) this.orderApp.setAction(this,function(){
    //         this.changeOrderFilter(this,'app');
    //     });

    //     this.orderAppType = MUIOutlet(this, "order-apptype-btn", "MUIButton");
    //     if (this.orderAppType) this.orderAppType.setAction(this,function(){
    //         this.changeOrderFilter(this,'apptype');
    //     });

    //     this.orderInfo = MUIOutlet(this, "order-info-btn", "MUIButton");
    //     if (this.orderInfo) this.orderInfo.setAction(this,function(){
    //         this.changeOrderFilter(this,'info');
    //     });

    //     this.orderBeginDate = MUIOutlet(this, "order-begindate-btn", "MUIButton");
    //     if (this.orderBeginDate) this.orderBeginDate.setAction(this,function(){
    //         this.changeOrderFilter(this,'beginDate');
    //     });

    //     this.orderEndDate = MUIOutlet(this, "order-enddate-btn", "MUIButton");
    //     if (this.orderEndDate) this.orderEndDate.setAction(this,function(){
    //         this.changeOrderFilter(this,'endDate');
    //     });

    //     this.orderType = MUIOutlet(this, "order-type-btn", "MUIButton");
    //     if (this.orderType) this.orderType.setAction(this,function(){
    //         this.changeOrderFilter(this,'type');
    //     });

    //     this.orderAppName = MUIOutlet(this, "order-appname-btn", "MUIButton");
    //     if (this.orderAppName) this.orderAppName.setAction(this,function(){
    //         this.changeOrderFilter(this,'appname');
    //     });

    //     this.tableView = MUIOutlet(this,'table-view','MUITableView');
    //     this.tableView.dataSource = this;
    //     this.tableView.delegate = this;
    // }

    // viewWillAppear(animate ?){
    //     super.viewWillAppear(animate);
    //     this.backButton.hidden = MIOCoreIsPhone() == true ? false : true;
    //     this.updateUI();
    // }
    
    // private license: License = null;
    // private _businessLicense: BusinessLicense = null;
    // set businessLicense(value){
    //     this._businessLicense = value;
    //     this.updateUI();
    // }

    // private updateUI(){
    //     if(this.viewIsLoaded == false || this._businessLicense == null) return;
        
    //     this.fetchedResultsController = null;
    //     this.tableView.reloadData();        
    // }

    // numberOfSections(tableview){
    //     return this.fetchedResultsController.sections.length;
    // }

    // numberOfRowsInSection(tableview, section){
    //     let sec = this.fetchedResultsController.sections[section];        
    //     return sec.numberOfObjects();
    // }

    // cellAtIndexPath(tableview, indexPath:MIOIndexPath){                        
    //     let cell = this.tableView.dequeueReusableCellWithIdentifier('LicenseDetailCell') as LicenseDetailCell;        
    //     let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        
    //     cell.item = item;
    //     cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
        
    //     return cell;
    // }

    // didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
    //     this.license = this.fetchedResultsController.objectAtIndexPath(indexPath);
    //     this.showLicenseDetail();
    // }

    // private _fetchedResultsController:MIOFetchedResultsController = null;
    // set fetchedResultsController(value){
    //     if (value == null && this._fetchedResultsController != null)
    //         this._fetchedResultsController.delegate = null;

    //     this._fetchedResultsController = value;
    // }

    // get fetchedResultsController(){
    //     if (this._fetchedResultsController != null)
    //         return this._fetchedResultsController;

    //     let ad = MUIWebApplication.sharedInstance().delegate;
    //     let sortDescriptors = null;

    //     if(this.orderString == null){
    //         sortDescriptors = [ 
    //             MIOSortDescriptor.sortDescriptorWithKey('app', this.orderAppValue),
    //             MIOSortDescriptor.sortDescriptorWithKey('apptype', this.orderAppTypeValue),
    //             MIOSortDescriptor.sortDescriptorWithKey('info', this.orderInfoValue)
    //         ];
    //     }else{
    //         sortDescriptors = [ 
    //             MIOSortDescriptor.sortDescriptorWithKey(this.orderString, this.orderValue)
    //         ];
    //     }

    //    //get the current date to string for use in MySQL
    //    let currentDate = MIODateTodayString();

    //     let predicateFormat = 'place =' + this._businessLicense.identifier;
    //     if (this.filterStatus == 1) predicateFormat += " AND isActive == true";
    //     if (this.filterStatus == 2) predicateFormat += " AND (beginDate > '" + currentDate + "' OR endDate < '" + currentDate + "') ";
    //     if (this.filterStatus == 3) predicateFormat += " AND isActive == false";

    //     let fetchRequest = DBHelper.listFetchRequestWithEntityName('License', sortDescriptors, predicateFormat);
        
    //     let fetchedResultsController = new MIOFetchedResultsController();
    //     fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
    //     fetchedResultsController.delegate = this;
    
    //     fetchedResultsController.performFetch();
    
    //     this._fetchedResultsController = fetchedResultsController;    
    //     return this._fetchedResultsController;
    // }

    // showLicenseDetail(){
    //     let nelvc = new EditLicenseViewController('edit-license-view');
    //     nelvc.initWithResource('layout/licenses/EditLicenseView.html');
    //     nelvc.placeID = this._businessLicense.identifier;
    //     nelvc.license = this.license;
    //     nelvc.isNewLicense = false;
    //     this.presentViewController(nelvc, true);
    // }

    // newLicenseDetail(){
    //     let alvc = new EditLicenseViewController('edit-license-view');
    //     alvc.initWithResource('layout/licenses/EditLicenseView.html');
    //     alvc.placeID = this._businessLicense.identifier;
    //     let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
    //     alvc.license = MIOEntityDescription.insertNewObjectForEntityForName("License", ad.managedObjectContext);
    //     alvc.isNewLicense = true;
    //     this.presentViewController(alvc, true);
    // }

    // controllerDidChangeContent(controller) {
    //     this.tableView.reloadData();
    // }

    // private changeOrderFilter(obj,filter){
    //     clearTimeout(obj.delayTimer);
    //     var instance = obj;

    //     if(obj.orderValue == true) obj.orderValue = false;
    //     else obj.orderValue = true;

    //     obj.orderString = filter;

    //     instance.delayTimer = setTimeout(function() {

    //         instance.fetchedResultsController = null;
    //         instance.tableView.reloadData();

    //     }, 1000, instance);
    // }

}