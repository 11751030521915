

// Generated class ServerStatus_ManagedObject

class ServerStatus_ManagedObject extends MIOManagedObject
{

    // Property: appID
    set appID(value:string) {
        this.setValueForKey(value, 'appID');
    }
    get appID():string {
        return this.valueForKey('appID');
    }
    set appIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'appID');
    }
    get appIDPrimitiveValue():string {
        return this.primitiveValueForKey('appID');
    }

    // Property: createdAt
    set createdAt(value:Date) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():Date {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():Date {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: deletedAt
    set deletedAt(value:Date) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():Date {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('deletedAt');
    }

    // Property: key
    set key(value:string) {
        this.setValueForKey(value, 'key');
    }
    get key():string {
        return this.valueForKey('key');
    }
    set keyPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'key');
    }
    get keyPrimitiveValue():string {
        return this.primitiveValueForKey('key');
    }

    // Property: processing
    set processing(value:boolean) {
        this.setValueForKey(value, 'processing');
    }
    get processing():boolean {
        return this.valueForKey('processing');
    }
    set processingPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'processing');
    }
    get processingPrimitiveValue():boolean {
        return this.primitiveValueForKey('processing');
    }

    // Property: serverID
    set serverID(value:string) {
        this.setValueForKey(value, 'serverID');
    }
    get serverID():string {
        return this.valueForKey('serverID');
    }
    set serverIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'serverID');
    }
    get serverIDPrimitiveValue():string {
        return this.primitiveValueForKey('serverID');
    }

    // Property: updatedAt
    set updatedAt(value:Date) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():Date {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: value
    set value(value:number) {
        this.setValueForKey(value, 'value');
    }
    get value():number {
        return this.valueForKey('value');
    }
    set valuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'value');
    }
    get valuePrimitiveValue():number {
        return this.primitiveValueForKey('value');
    }
}
