

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ClientLicenseOffer_ManagedObject

class ClientLicenseOffer_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: applicationType
    set applicationType(value:number) {
        this.setValueForKey(value, 'applicationType');
    }
    get applicationType():number {
        return this.valueForKey('applicationType');
    }
    set applicationTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'applicationType');
    }
    get applicationTypePrimitiveValue():number {
        return this.primitiveValueForKey('applicationType');
    }

    // Property: endDate
    set endDate(value:Date) {
        this.setValueForKey(value, 'endDate');
    }
    get endDate():Date {
        return this.valueForKey('endDate');
    }
    set endDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'endDate');
    }
    get endDatePrimitiveValue():Date {
        return this.primitiveValueForKey('endDate');
    }

    // Property: startDate
    set startDate(value:Date) {
        this.setValueForKey(value, 'startDate');
    }
    get startDate():Date {
        return this.valueForKey('startDate');
    }
    set startDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'startDate');
    }
    get startDatePrimitiveValue():Date {
        return this.primitiveValueForKey('startDate');
    }
    // Relationship: client
    set client(value:Client) {
        this.setValueForKey(value, 'client');
    }
    get client():Client {
        return this.valueForKey('client') as Client;
    }
    // Relationship: offer
    set offer(value:Offer) {
        this.setValueForKey(value, 'offer');
    }
    get offer():Offer {
        return this.valueForKey('offer') as Offer;
    }
    // Relationship: rate
    set rate(value:Rate) {
        this.setValueForKey(value, 'rate');
    }
    get rate():Rate {
        return this.valueForKey('rate') as Rate;
    }
}
