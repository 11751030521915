/**
 * Created by crisan on 7/12/16.
 */

class ClientAccountCell extends UITableViewCell
{
    booking:Booking = null;
    

    private dateLabel:MUILabel = null;
    private conceptLabel:MUILabel = null;
    private bookingLabel:MUILabel = null;
    private paymethodLabel:MUILabel = null;
    private quantityLabel:MUILabel = null;
    private actionButton:MUIButton = null;
    
    private df = MUIWebApplication.sharedInstance().delegate.dateFormatter;
    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;

    awakeFromHTML(){
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");
        this.conceptLabel = MUIOutlet(this, 'concept-lbl','MUILabel');
        this.quantityLabel = MUIOutlet(this, "balance-lbl", "MUILabel");
        this.bookingLabel = MUIOutlet(this, "booking-lbl", "MUILabel");
        this.paymethodLabel = MUIOutlet(this, "paymethod-lbl", "MUILabel");
        this.quantityLabel = MUIOutlet(this, "balance-lbl", "MUILabel");
        this.actionButton = MUIOutlet(this, "action-btn", "MUIButton");
        this.actionButton.setAction(this, this.showOptions);
        this.actionButton.hidden = !this._showBookingOptions;
        
        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }    

    private line:EconomicAccountLine = null;
    set item(i:EconomicAccountLine){
        this.line = i;
        this.dateLabel.text = this.df.stringFromDate(i.date);
        this.conceptLabel.text = i.concept;
        this.bookingLabel.text = null;
        if (i.booking != null ) {
            let df = (MUIWebApplication.sharedInstance().delegate as AppDelegate).dateFormatter;
            let d = new Date(i.booking.day);
            this.bookingLabel.text = ( i.booking?.day != null ? df.stringFromDate(d) + " " : "" ) + ( i.booking.bookingName ?? i.booking.clientName );
        }
        this.quantityLabel.text = this.nf.stringFromNumber(i.value);
        this.paymethodLabel.text = i.payMethodName;
    }

    private _showBookingOptions = false;
    set showBookingOptions(value:boolean){
        this._showBookingOptions = value;
        if (this.actionButton != null) this.actionButton.hidden = !value;
    }

    private showOptions(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle( MIOLocalizeString("OPTIONS", "OPTIONS"), MIOLocalizeString("CHOOSE AN OPTION", "CHOOSE AN OPTION"), MUIAlertViewStyle.Default);

        if (this.line.booking == null) {
            avc.addAction(MUIAlertAction.alertActionWithTitle( MIOLocalizeString("LINK TO CURRENT BOOKING", "LINK TO CURRENT BOOKING"), MUIAlertActionStyle.Default, this, this.linkBooking));
        }

        if (this.line.booking != null) {
            avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("UNLINK FROM CURRENT BOOKING", "UNLINK FROM CURRENT BOOKING"), MUIAlertActionStyle.Default, this, this.unlinkBooking));
        }

        avc.addAction(MUIAlertAction.alertActionWithTitle( MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null ));

        AppHelper.sharedInstance().presentViewController(avc, true);
    }

    private linkBooking(){
        this.line.booking = this.booking;
        DBHelper.saveMainContext();
    }

    private unlinkBooking(){
        this.line.booking = null;
        DBHelper.saveMainContext();
    }

}