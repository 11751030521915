

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class PresetProductModifier_ManagedObject

class PresetProductModifier_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: modifierID
    set modifierID(value:string) {
        this.setValueForKey(value, 'modifierID');
    }
    get modifierID():string {
        return this.valueForKey('modifierID');
    }
    set modifierIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'modifierID');
    }
    get modifierIDPrimitiveValue():string {
        return this.primitiveValueForKey('modifierID');
    }

    // Property: quantity
    set quantity(value:number) {
        this.setValueForKey(value, 'quantity');
    }
    get quantity():number {
        return this.valueForKey('quantity');
    }
    set quantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'quantity');
    }
    get quantityPrimitiveValue():number {
        return this.primitiveValueForKey('quantity');
    }
    // Relationship: presetProduct
    set presetProduct(value:PresetProduct) {
        this.setValueForKey(value, 'presetProduct');
    }
    get presetProduct():PresetProduct {
        return this.valueForKey('presetProduct') as PresetProduct;
    }
}
