

/// <reference path="RuleKit.ts" />

// Generated class TimeRange_ManagedObject

class TimeRange_ManagedObject extends RuleKit
{

    // Property: duration
    set duration(value:number) {
        this.setValueForKey(value, 'duration');
    }
    get duration():number {
        return this.valueForKey('duration');
    }
    set durationPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'duration');
    }
    get durationPrimitiveValue():number {
        return this.primitiveValueForKey('duration');
    }

    // Property: groupName
    set groupName(value:string) {
        this.setValueForKey(value, 'groupName');
    }
    get groupName():string {
        return this.valueForKey('groupName');
    }
    set groupNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'groupName');
    }
    get groupNamePrimitiveValue():string {
        return this.primitiveValueForKey('groupName');
    }

    // Property: hour
    set hour(value:string) {
        this.setValueForKey(value, 'hour');
    }
    get hour():string {
        return this.valueForKey('hour');
    }
    set hourPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'hour');
    }
    get hourPrimitiveValue():string {
        return this.primitiveValueForKey('hour');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: nextDay
    set nextDay(value:boolean) {
        this.setValueForKey(value, 'nextDay');
    }
    get nextDay():boolean {
        return this.valueForKey('nextDay');
    }
    set nextDayPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'nextDay');
    }
    get nextDayPrimitiveValue():boolean {
        return this.primitiveValueForKey('nextDay');
    }

    // Property: showRange
    set showRange(value:boolean) {
        this.setValueForKey(value, 'showRange');
    }
    get showRange():boolean {
        return this.valueForKey('showRange');
    }
    set showRangePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'showRange');
    }
    get showRangePrimitiveValue():boolean {
        return this.primitiveValueForKey('showRange');
    }
    // Relationship: timeRangeGroup
    set timeRangeGroup(value:TimeRangeGroup) {
        this.setValueForKey(value, 'timeRangeGroup');
    }
    get timeRangeGroup():TimeRangeGroup {
        return this.valueForKey('timeRangeGroup') as TimeRangeGroup;
    }
}
