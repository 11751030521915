

class DBBackupToolViewController extends EntityListViewController
{   
    private actionButton:MUIButton = null;
    private filterButton:MUIButton = null;
    
    private sdtf = MUIWebApplication.sharedInstance().delegate.serverDateTimeFormatter;
    
    private beginDate:Date;
    private endDate:Date;

    viewDidLoad(){
        super.viewDidLoad();

        // this.activityIndicatorView = MUIOutlet(this, 'tv_activity-indicator','MUIActivityIndicatorView');
        // this.activityIndicatorView.setHidden(true);

        this.filterButton = MUIOutlet(this, "filter-btn", "MUIButton");
        this.filterButton.setAction(this, function(){
            AppHelper.sharedInstance().presentDateSelectionViewController(this);
        });

        this.actionButton = MUIOutlet(this, 'action-btn','MUIButton');
        this.actionButton.setAction(this, function(){
            this.showActions();
        });        

        this.tableView = MUIOutlet(this, 'tableview', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        
        this.updateUI();
    }

    private updateUI(){
        //this.companyLabel.text = MIOLocalizeString("BACKUP COMPANY", "backup company");
        //this.initActionComboBox();
    }

    private reloadTable(){
        // this.beginDate = this.df.dateFromString(this.dateFromTextField.text);
        // this.endDate = this.df.dateFromString(this.dateToTextField.text);

        if(this.beginDate >= this.endDate || !isDate(this.beginDate) || !isDate(this.endDate)){
           // AppHelper.showErrorMessage(this, 'Error', 'Check dates');
            return;
        }

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('BackupToolCell') as DBBackupToolCell;    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as CashDeskSession;
    
        cell.delegate = this;
        cell.item = item;

        // if(item.isBackup == true)
        //     cell.setAccessoryType(MUITableViewCellAccessoryType.Checkmark);
        // else 
        //     cell.setAccessoryType(MUITableViewCellAccessoryType.None);        
        
        return cell;
    }
        
    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        //let obj = this.fetchedResultsController.objectAtIndexPath(indexPath);    
    }

    get entityName(){
        return 'CashDeskSession';
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }    

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;        
    
        let sortDescriptors = [  MIOSortDescriptor.sortDescriptorWithKey('beginDate', true)];
        
        let searchString = 'isOpen != true AND beginDate >= "' + this.sdtf.stringFromDate(this.beginDate) + '" AND ' +'beginDate <= "' + this.sdtf.stringFromDate(this.endDate) +'"';
        
        let fetchRequest = DBHelper.listFetchRequestWithEntityName(this.entityName, sortDescriptors, searchString);
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    datesDidSelect(dateTimeFrom, dateTimeTo) {
        this.beginDate = dateTimeFrom;
        this.endDate = dateTimeTo;
        this.reloadTable();
    }
    
    private showActions(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ACTIONS","ACTIONS"), MIOLocalizeString("SELECT AN ACTION","SELECT AN ACTION"), MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("BACKUP ALL","BACKUP ALL"), MUIAlertActionStyle.Default, this, function(){
            this.backupSessions(this.fetchedResultsController.fetchedObjects);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("RESTORE ALL","RESTORE ALL"), MUIAlertActionStyle.Default, this, function(){
            this.recoverySessions(this.fetchedResultsController.fetchedObjects);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL","CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    backupSessions(sessions){

        let array = [];
        for (let index = 0; index < sessions.length; index++){
            let s = sessions[index] as CashDeskSession;
            array.addObject(s.identifier);
        }

        let ad = MUIWebApplication.sharedInstance().delegate;
        let ws = ad.webService;
        
        ws.backupSessions(array, this, function(code, json){
           if (code == 200) {
                AppHelper.showInfoMessage(this,MIOLocalizeString('INFO','INFO'), MIOLocalizeString('SESSION BACKUP OK','SESSION BACKUP OK'));
           }
           else{
                AppHelper.showErrorMessage(this,MIOLocalizeString('ERROR','ERROR'), MIOLocalizeString('SESSION BACKUP FAIL','SESSION BACKUP FAIL'));
           }
           this.reloadTable();
        });
    }

    recoverySessions(sessions){
        let array = [];
        for (let index = 0; index < sessions.length; index++){
            let s = sessions[index] as CashDeskSession;
            array.addObject(s.identifier);
        }

        let ad = MUIWebApplication.sharedInstance().delegate;
        let ws = ad.webService;

        ws.recoverySessions(array, this, function(code, json){
           if(code == 200) {
                AppHelper.showInfoMessage(this,MIOLocalizeString('INFO','INFO'),MIOLocalizeString('SESSION RECOVERY OK','SESSION RECOVERY OK'));
           }
           else{
                AppHelper.showErrorMessage(this,MIOLocalizeString('ERROR','ERROR'),MIOLocalizeString('SESSION RECOVERY FAIL','SESSION RECOVERY FAIL'));
           }
           this.reloadTable();
        });        
    }
}