

/// <reference path="CoreEntity.ts" />

// Generated class ProductCurrentStock_ManagedObject

class ProductCurrentStock_ManagedObject extends CoreEntity
{

    // Property: minMeasureUnitType
    set minMeasureUnitType(value:number) {
        this.setValueForKey(value, 'minMeasureUnitType');
    }
    get minMeasureUnitType():number {
        return this.valueForKey('minMeasureUnitType');
    }
    set minMeasureUnitTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minMeasureUnitType');
    }
    get minMeasureUnitTypePrimitiveValue():number {
        return this.primitiveValueForKey('minMeasureUnitType');
    }

    // Property: minTotalQuantity
    set minTotalQuantity(value:number) {
        this.setValueForKey(value, 'minTotalQuantity');
    }
    get minTotalQuantity():number {
        return this.valueForKey('minTotalQuantity');
    }
    set minTotalQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minTotalQuantity');
    }
    get minTotalQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('minTotalQuantity');
    }

    // Property: productContainerMeasureType
    set productContainerMeasureType(value:number) {
        this.setValueForKey(value, 'productContainerMeasureType');
    }
    get productContainerMeasureType():number {
        return this.valueForKey('productContainerMeasureType');
    }
    set productContainerMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productContainerMeasureType');
    }
    get productContainerMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('productContainerMeasureType');
    }

    // Property: productContainerQuantity
    set productContainerQuantity(value:number) {
        this.setValueForKey(value, 'productContainerQuantity');
    }
    get productContainerQuantity():number {
        return this.valueForKey('productContainerQuantity');
    }
    set productContainerQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productContainerQuantity');
    }
    get productContainerQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('productContainerQuantity');
    }

    // Property: productID
    set productID(value:string) {
        this.setValueForKey(value, 'productID');
    }
    get productID():string {
        return this.valueForKey('productID');
    }
    set productIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productID');
    }
    get productIDPrimitiveValue():string {
        return this.primitiveValueForKey('productID');
    }

    // Property: totalStock
    set totalStock(value:number) {
        this.setValueForKey(value, 'totalStock');
    }
    get totalStock():number {
        return this.valueForKey('totalStock');
    }
    set totalStockPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalStock');
    }
    get totalStockPrimitiveValue():number {
        return this.primitiveValueForKey('totalStock');
    }

    // Property: warehouseID
    set warehouseID(value:string) {
        this.setValueForKey(value, 'warehouseID');
    }
    get warehouseID():string {
        return this.valueForKey('warehouseID');
    }
    set warehouseIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'warehouseID');
    }
    get warehouseIDPrimitiveValue():string {
        return this.primitiveValueForKey('warehouseID');
    }
}
