//
// Generated class ProxyPaymentTransaction
//

/// <reference path="ProxyPaymentTransaction_ManagedObject.ts" />

class ProxyPaymentTransaction extends ProxyPaymentTransaction_ManagedObject
{

}
