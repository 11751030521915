class AgentListCell extends UITableViewCell
{
    private nameTextField:MUITextField = null;
    private phoneTextField:MUITextField = null;    
    private emailTextField:MUITextField = null;
    private intermediaryDropdown:MUIButton = null;
    
    awakeFromHTML(){
        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.nameTextField.setOnChangeText(this, function(this:AgentListCell, textField:MUITextField) {
            this.agent.name = textField.text;
        });
        this.phoneTextField = MUIOutlet(this, "phone-tf", "MUITextField");
        this.phoneTextField.setOnChangeText(this, function(this:AgentListCell, textField:MUITextField) {
            this.agent.phone = textField.text.length > 0 ? textField.text.trim() : null;
        });
        this.emailTextField = MUIOutlet(this, "email-tf", "MUITextField");
        this.emailTextField.setOnChangeText(this, function(this:AgentListCell, textField:MUITextField) {
            this.agent.email = textField.text.length > 0 ? textField.text.trim() : null;
        });

        this.intermediaryDropdown = MUIOutlet(this, "intermediary-dd", "MUIButton");
        this.intermediaryDropdown.setAction(this, function(this:AgentListCell){
            AppHelper.sharedInstance().showSelectIntermediaryViewControllerFromView(this.intermediaryDropdown, this.agent.intermediary, this, this.intermediaryDidSelect);
        });

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private agent:Agent = null;
    set item (item:Agent){
        this.agent = item;
        this.nameTextField.text = item.name;
        this.phoneTextField.text = item.phone;
        this.emailTextField.text = item.email; 
        this.intermediaryDropdown.title = item.intermediary?.name ?? null;
    }

    private intermediaryDidSelect(controller:SelectInputFormatViewController, intermediary:Intermediary){        
        this.intermediaryDropdown.title = intermediary?.name ?? null;
        this.agent.intermediary = intermediary;
    }

}