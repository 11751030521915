//
// Generated class PaymentEntity
//

/// <reference path="PaymentEntity_ManagedObject.ts" />

enum PaymentEntityType
{
    DualLink = -1,
    CashOnDelivery = 0,
    Proxy = 1,
    Redsys = 2,
    Stripe = 3,
    PayPal = 4,
    Adyen = 5,
}

class PaymentEntity extends PaymentEntity_ManagedObject
{
    static typeStringForType(type:PaymentEntityType):string {

        switch (type){
            case PaymentEntityType.DualLink: return "DUAL LINK";
            case PaymentEntityType.CashOnDelivery: return MIOLocalizeString("CASH ON DELIVERY", "CASH ON DELIVERY");
            case PaymentEntityType.Proxy: return MIOLocalizeString("PROXY", "PROXY");
            case PaymentEntityType.Redsys: return "Redsys";
            case PaymentEntityType.Stripe: return "Stripe";
            case PaymentEntityType.PayPal: return "PayPal";
            case PaymentEntityType.Adyen: return "Adyen";
        }
    }

    static supportedTypesForCurrentRegion() : PaymentEntityType[] {        
        return [PaymentEntityType.Adyen, PaymentEntityType.CashOnDelivery];

        /*
        let region = MIOLocale.currentLocale().countryCode;
        switch (region) {
            case "ES": 
                return [
                    // PaymentEntityType.DualLink,
                    PaymentEntityType.CashOnDelivery,
                    // PaymentEntityType.Redsys,
                    // PaymentEntityType.Stripe,
                    // PaymentEntityType.PayPal,
                    PaymentEntityType.Adyen
                ];            

            case "NL": 
                return [
                    // PaymentEntityType.DualLink,
                    PaymentEntityType.CashOnDelivery,
                    // PaymentEntityType.Redsys,
                    // PaymentEntityType.Stripe,
                    // PaymentEntityType.PayPal,
                    PaymentEntityType.Adyen
                ];            
        }                
        return [];
        */
    }

    configValues() : any {
        if (this.config == null) return {};
        return JSON.parse(this.config);
    }

    setConfigValues(values:any) {
        this.config = JSON.stringify(values);
    }
}
