/**
 * Created by miguel on 22/2/17.
 */




class EditorViewController extends MUIViewController
{
    private filesView:MUIView = null;
    private codeEditorView:MUICodeEditor = null;
    private propertiesPanel:MUIView = null;

    // private pagesViewController:MUIViewController = null;
    // private propertiesViewController = null;

    viewDidLoad()
    {
        super.viewDidLoad();

        // this.leftPanel = MUIOutlet(this, "editor_panel_left");

        // this.pagesViewController = new MUIViewController("pages_view");
        // this.pagesViewController.initWithResource("layout/editor/PagesView.html");
        // this.leftPanel.addSubview(this.pagesViewController.view);
        // this.addChildViewController(this.pagesViewController);

        // this.rightPanel = MUIOutlet(this, "editor_panel_right");        

        // this.propertiesViewController = new MUIViewController("properties_view");
        // this.propertiesViewController.initWithResource("layout/editor/PropertiesView.html");
        // this.rightPanel.addSubview(this.propertiesViewController.view);
        // this.addChildViewController(this.propertiesViewController);
        
        this.codeEditorView = MUIOutlet(this, "code-view", "MUICodeEditor");
        this.codeEditorView.language = MUICodeEditorLanguage.JSON;
    }

    private file_url:string = null;
    set fileURL(file_url:string) {
        this.file_url = file_url

        let request = MIOURLRequest.requestWithURL(MIOURL.urlWithString(file_url));
        request.httpMethod = "GET";

        let urlConnection = new MIOURLConnection();
        urlConnection.initWithRequestBlock(request, this, function (this:EditorViewController, statusCode:number, data:any, blob:any) {
            // let obj = JSON.parse(data);            
            // this.codeEditorView.text = JSON.stringify(obj, null , "\t");
            this.codeEditorView.text = data;            
        });
    }
}