

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class CashDesk_ManagedObject

class CashDesk_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: customInputNoteStockNumberSerieID
    set customInputNoteStockNumberSerieID(value:string) {
        this.setValueForKey(value, 'customInputNoteStockNumberSerieID');
    }
    get customInputNoteStockNumberSerieID():string {
        return this.valueForKey('customInputNoteStockNumberSerieID');
    }
    set customInputNoteStockNumberSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'customInputNoteStockNumberSerieID');
    }
    get customInputNoteStockNumberSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('customInputNoteStockNumberSerieID');
    }

    // Property: customOutputNoteStockNumberSerieID
    set customOutputNoteStockNumberSerieID(value:string) {
        this.setValueForKey(value, 'customOutputNoteStockNumberSerieID');
    }
    get customOutputNoteStockNumberSerieID():string {
        return this.valueForKey('customOutputNoteStockNumberSerieID');
    }
    set customOutputNoteStockNumberSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'customOutputNoteStockNumberSerieID');
    }
    get customOutputNoteStockNumberSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('customOutputNoteStockNumberSerieID');
    }

    // Property: defaultFloat
    set defaultFloat(value:number) {
        this.setValueForKey(value, 'defaultFloat');
    }
    get defaultFloat():number {
        return this.valueForKey('defaultFloat');
    }
    set defaultFloatPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'defaultFloat');
    }
    get defaultFloatPrimitiveValue():number {
        return this.primitiveValueForKey('defaultFloat');
    }

    // Property: deliveryNoteStockNumberSerieID
    set deliveryNoteStockNumberSerieID(value:string) {
        this.setValueForKey(value, 'deliveryNoteStockNumberSerieID');
    }
    get deliveryNoteStockNumberSerieID():string {
        return this.valueForKey('deliveryNoteStockNumberSerieID');
    }
    set deliveryNoteStockNumberSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deliveryNoteStockNumberSerieID');
    }
    get deliveryNoteStockNumberSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('deliveryNoteStockNumberSerieID');
    }

    // Property: deliveryOrderNumberSerieID
    set deliveryOrderNumberSerieID(value:string) {
        this.setValueForKey(value, 'deliveryOrderNumberSerieID');
    }
    get deliveryOrderNumberSerieID():string {
        return this.valueForKey('deliveryOrderNumberSerieID');
    }
    set deliveryOrderNumberSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deliveryOrderNumberSerieID');
    }
    get deliveryOrderNumberSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('deliveryOrderNumberSerieID');
    }

    // Property: idIn
    set idIn(value:number) {
        this.setValueForKey(value, 'idIn');
    }
    get idIn():number {
        return this.valueForKey('idIn');
    }
    set idInPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'idIn');
    }
    get idInPrimitiveValue():number {
        return this.primitiveValueForKey('idIn');
    }

    // Property: internalInputNoteStockNumberSerieID
    set internalInputNoteStockNumberSerieID(value:string) {
        this.setValueForKey(value, 'internalInputNoteStockNumberSerieID');
    }
    get internalInputNoteStockNumberSerieID():string {
        return this.valueForKey('internalInputNoteStockNumberSerieID');
    }
    set internalInputNoteStockNumberSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'internalInputNoteStockNumberSerieID');
    }
    get internalInputNoteStockNumberSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('internalInputNoteStockNumberSerieID');
    }

    // Property: internalOrderStockNumberSerieID
    set internalOrderStockNumberSerieID(value:string) {
        this.setValueForKey(value, 'internalOrderStockNumberSerieID');
    }
    get internalOrderStockNumberSerieID():string {
        return this.valueForKey('internalOrderStockNumberSerieID');
    }
    set internalOrderStockNumberSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'internalOrderStockNumberSerieID');
    }
    get internalOrderStockNumberSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('internalOrderStockNumberSerieID');
    }

    // Property: internalOutputNoteStockNumberSerieID
    set internalOutputNoteStockNumberSerieID(value:string) {
        this.setValueForKey(value, 'internalOutputNoteStockNumberSerieID');
    }
    get internalOutputNoteStockNumberSerieID():string {
        return this.valueForKey('internalOutputNoteStockNumberSerieID');
    }
    set internalOutputNoteStockNumberSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'internalOutputNoteStockNumberSerieID');
    }
    get internalOutputNoteStockNumberSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('internalOutputNoteStockNumberSerieID');
    }

    // Property: inventoryNoteStockNumberSerieID
    set inventoryNoteStockNumberSerieID(value:string) {
        this.setValueForKey(value, 'inventoryNoteStockNumberSerieID');
    }
    get inventoryNoteStockNumberSerieID():string {
        return this.valueForKey('inventoryNoteStockNumberSerieID');
    }
    set inventoryNoteStockNumberSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'inventoryNoteStockNumberSerieID');
    }
    get inventoryNoteStockNumberSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('inventoryNoteStockNumberSerieID');
    }

    // Property: invitationSerieID
    set invitationSerieID(value:string) {
        this.setValueForKey(value, 'invitationSerieID');
    }
    get invitationSerieID():string {
        return this.valueForKey('invitationSerieID');
    }
    set invitationSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invitationSerieID');
    }
    get invitationSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('invitationSerieID');
    }

    // Property: invoiceSerieID
    set invoiceSerieID(value:string) {
        this.setValueForKey(value, 'invoiceSerieID');
    }
    get invoiceSerieID():string {
        return this.valueForKey('invoiceSerieID');
    }
    set invoiceSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invoiceSerieID');
    }
    get invoiceSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('invoiceSerieID');
    }

    // Property: isManager
    set isManager(value:boolean) {
        this.setValueForKey(value, 'isManager');
    }
    get isManager():boolean {
        return this.valueForKey('isManager');
    }
    set isManagerPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isManager');
    }
    get isManagerPrimitiveValue():boolean {
        return this.primitiveValueForKey('isManager');
    }

    // Property: isOpen
    set isOpen(value:boolean) {
        this.setValueForKey(value, 'isOpen');
    }
    get isOpen():boolean {
        return this.valueForKey('isOpen');
    }
    set isOpenPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isOpen');
    }
    get isOpenPrimitiveValue():boolean {
        return this.primitiveValueForKey('isOpen');
    }

    // Property: lastOrderNumber
    set lastOrderNumber(value:number) {
        this.setValueForKey(value, 'lastOrderNumber');
    }
    get lastOrderNumber():number {
        return this.valueForKey('lastOrderNumber');
    }
    set lastOrderNumberPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lastOrderNumber');
    }
    get lastOrderNumberPrimitiveValue():number {
        return this.primitiveValueForKey('lastOrderNumber');
    }

    // Property: lastTicketNumber
    set lastTicketNumber(value:number) {
        this.setValueForKey(value, 'lastTicketNumber');
    }
    get lastTicketNumber():number {
        return this.valueForKey('lastTicketNumber');
    }
    set lastTicketNumberPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lastTicketNumber');
    }
    get lastTicketNumberPrimitiveValue():number {
        return this.primitiveValueForKey('lastTicketNumber');
    }

    // Property: lowOrderNumberSerieID
    set lowOrderNumberSerieID(value:string) {
        this.setValueForKey(value, 'lowOrderNumberSerieID');
    }
    get lowOrderNumberSerieID():string {
        return this.valueForKey('lowOrderNumberSerieID');
    }
    set lowOrderNumberSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'lowOrderNumberSerieID');
    }
    get lowOrderNumberSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('lowOrderNumberSerieID');
    }

    // Property: maximumCash
    set maximumCash(value:number) {
        this.setValueForKey(value, 'maximumCash');
    }
    get maximumCash():number {
        return this.valueForKey('maximumCash');
    }
    set maximumCashPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'maximumCash');
    }
    get maximumCashPrimitiveValue():number {
        return this.primitiveValueForKey('maximumCash');
    }

    // Property: money
    set money(value:number) {
        this.setValueForKey(value, 'money');
    }
    get money():number {
        return this.valueForKey('money');
    }
    set moneyPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'money');
    }
    get moneyPrimitiveValue():number {
        return this.primitiveValueForKey('money');
    }

    // Property: movementNoteStockNumberSerieID
    set movementNoteStockNumberSerieID(value:string) {
        this.setValueForKey(value, 'movementNoteStockNumberSerieID');
    }
    get movementNoteStockNumberSerieID():string {
        return this.valueForKey('movementNoteStockNumberSerieID');
    }
    set movementNoteStockNumberSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'movementNoteStockNumberSerieID');
    }
    get movementNoteStockNumberSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('movementNoteStockNumberSerieID');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: nullifyInvitationSerieID
    set nullifyInvitationSerieID(value:string) {
        this.setValueForKey(value, 'nullifyInvitationSerieID');
    }
    get nullifyInvitationSerieID():string {
        return this.valueForKey('nullifyInvitationSerieID');
    }
    set nullifyInvitationSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'nullifyInvitationSerieID');
    }
    get nullifyInvitationSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('nullifyInvitationSerieID');
    }

    // Property: nullifyInvoiceSerieID
    set nullifyInvoiceSerieID(value:string) {
        this.setValueForKey(value, 'nullifyInvoiceSerieID');
    }
    get nullifyInvoiceSerieID():string {
        return this.valueForKey('nullifyInvoiceSerieID');
    }
    set nullifyInvoiceSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'nullifyInvoiceSerieID');
    }
    get nullifyInvoiceSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('nullifyInvoiceSerieID');
    }

    // Property: nullifyTicketSerieID
    set nullifyTicketSerieID(value:string) {
        this.setValueForKey(value, 'nullifyTicketSerieID');
    }
    get nullifyTicketSerieID():string {
        return this.valueForKey('nullifyTicketSerieID');
    }
    set nullifyTicketSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'nullifyTicketSerieID');
    }
    get nullifyTicketSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('nullifyTicketSerieID');
    }

    // Property: prefix
    set prefix(value:string) {
        this.setValueForKey(value, 'prefix');
    }
    get prefix():string {
        return this.valueForKey('prefix');
    }
    set prefixPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'prefix');
    }
    get prefixPrimitiveValue():string {
        return this.primitiveValueForKey('prefix');
    }

    // Property: productionNumberSerieID
    set productionNumberSerieID(value:string) {
        this.setValueForKey(value, 'productionNumberSerieID');
    }
    get productionNumberSerieID():string {
        return this.valueForKey('productionNumberSerieID');
    }
    set productionNumberSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productionNumberSerieID');
    }
    get productionNumberSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('productionNumberSerieID');
    }

    // Property: purchaseOrderStockNumberSerieID
    set purchaseOrderStockNumberSerieID(value:string) {
        this.setValueForKey(value, 'purchaseOrderStockNumberSerieID');
    }
    get purchaseOrderStockNumberSerieID():string {
        return this.valueForKey('purchaseOrderStockNumberSerieID');
    }
    set purchaseOrderStockNumberSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'purchaseOrderStockNumberSerieID');
    }
    get purchaseOrderStockNumberSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('purchaseOrderStockNumberSerieID');
    }

    // Property: supplierNoteStockNumberSerieID
    set supplierNoteStockNumberSerieID(value:string) {
        this.setValueForKey(value, 'supplierNoteStockNumberSerieID');
    }
    get supplierNoteStockNumberSerieID():string {
        return this.valueForKey('supplierNoteStockNumberSerieID');
    }
    set supplierNoteStockNumberSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'supplierNoteStockNumberSerieID');
    }
    get supplierNoteStockNumberSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('supplierNoteStockNumberSerieID');
    }

    // Property: supplierOrderStockNumberSerieID
    set supplierOrderStockNumberSerieID(value:string) {
        this.setValueForKey(value, 'supplierOrderStockNumberSerieID');
    }
    get supplierOrderStockNumberSerieID():string {
        return this.valueForKey('supplierOrderStockNumberSerieID');
    }
    set supplierOrderStockNumberSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'supplierOrderStockNumberSerieID');
    }
    get supplierOrderStockNumberSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('supplierOrderStockNumberSerieID');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: ticketSerieID
    set ticketSerieID(value:string) {
        this.setValueForKey(value, 'ticketSerieID');
    }
    get ticketSerieID():string {
        return this.valueForKey('ticketSerieID');
    }
    set ticketSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ticketSerieID');
    }
    get ticketSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('ticketSerieID');
    }

    // Relationship: cashDeskCurrencies
    protected _cashDeskCurrencies:MIOManagedObjectSet = null;
    get cashDeskCurrencies():MIOManagedObjectSet {
        return this.valueForKey('cashDeskCurrencies');
    }
    addCashDeskCurrenciesObject(value:CashDeskCurrency) {
        this._addObjectForKey(value, 'cashDeskCurrencies');
    }
    removeCashDeskCurrenciesObject(value:CashDeskCurrency) {
        this._removeObjectForKey(value, 'cashDeskCurrencies');
    }
}
