//
// Generated class EllaborationConsumptionWarehouse
//

/// <reference path="EllaborationConsumptionWarehouse_ManagedObject.ts" />

class EllaborationConsumptionWarehouse extends EllaborationConsumptionWarehouse_ManagedObject
{

}
