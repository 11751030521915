



class AttributeCell extends MUITableViewCell
{
    nameTextField:MUITextField = null;
    _separator = null;

    private _item:ProductAttribute;
    
    private moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

    awakeFromHTML()
    {
        this.nameTextField = MUIOutlet(this, "av_tv_cell_lbl", "MUITextField");
        this.nameTextField.setOnEnterPress(this, function(textfield, value){
            this._item.name = value.trim();
            this.moc.save();
        });
        
        this._separator = MUIOutlet(this, "av_tv_separator1", "MUIView");
    }

   /* setSelected(value)
    {
        super.setSelected(value);

        if (value == true)
            this._separator.setBackgroundRGBColor(0, 191, 191);
        else
            this._separator.setBackgroundRGBColor(237, 237, 242);
    }
*/

    set item(i)
    {
        this._item = i;
        this.nameTextField.text = i.name;
    }

}