
class DualLinkAuthKit
{
    private static _sharedInstance: DualLinkAuthKit;

    private enviroment = "eu";

    static sharedInstance(): DualLinkAuthKit {

        if (DualLinkAuthKit._sharedInstance == null) {
            DualLinkAuthKit._sharedInstance = new DualLinkAuthKit();
        }

        return DualLinkAuthKit._sharedInstance;
    }

    private constructor() {
        if (DualLinkAuthKit._sharedInstance != null) {
            throw new Error("DualLinkAuthKit: Instantiation failed: Use sharedInstance() instead of new.");
        }
    }

    //
    // Auth services
    //

    authURL:string = null;
    token:string = null;

    businesses = [];  
    
    userID:string = null;

    fetchBusiness(completion:any){
        const url = MIOURL.urlWithString(this.authURL + "/business");
        let urlRequest = new MWSJSONRequest();
        urlRequest.initWithURL(url, null, "GET");
        urlRequest.setHeaderValue("Bearer " + this.token, "Authorization");
        // urlRequest.setHeaderValue("test", "DL-ENVIRONMENT");
        urlRequest.setHeaderValue(this.enviroment, "DL-ENVIRONMENT");
        urlRequest.execute(this, function(code, json){
            if (code == 200) {                
                this.businesses = json["data"];                
                this.businesses = _MIOPredicateFilterObjects(this.businesses, MIOPredicate.predicateWithFormat("db_version >= 59"));
                this.businesses = _MIOSortDescriptorSortObjects(this.businesses, [MIOSortDescriptor.sortDescriptorWithKey("name", true)]);
            }
            completion(this.businesses);
        });
    }  

    login(user:string, pass:string, completion:any) {

        // Reset
        this.userID = null;
        MIOUserDefaults.standardUserDefaults().removeValueForKey("LoginOK");
        MIOUserDefaults.standardUserDefaults().removeValueForKey("LastProductDetailPageIndex");
        MIOUserDefaults.standardUserDefaults().removeValueForKey("LastProductDetailSalesPageIndex");

        const url = MIOURL.urlWithString(this.authURL + "/token");
        let body = {
            "username": user,
            "password": pass,
            "grant_type": "password"
        }

        let urlRequest = new MWSJSONRequest();
        urlRequest.initWithURL(url, body, "POST");
        // urlRequest.setHeaderValue("test", "DL-ENVIRONMENT");
        urlRequest.setHeaderValue(this.enviroment, "DL-ENVIRONMENT");
        urlRequest.execute(this, function(code:number, json:any){
            let err = false;
            let result = false;
            if (code == 200 && json["status"].toLowerCase() == "ok") {
                // Login OK
                err = false;
                result = true;                                

                this.token = json["data"]["access_token"];
                let email = json["data"]["user"]["email"];
                this.userID = json["data"]["user"]["identifier"];

                MIOUserDefaults.standardUserDefaults().setValueForKey("LastLoginEmail", email);
                MIOUserDefaults.standardUserDefaults().setValueForKey("LoginToken", this.token);
                MIOUserDefaults.standardUserDefaults().setBooleanForKey("LoginOK", true);
            }
            else {
                // Error
                err = true;
                result = false;
                this.token = null;
                MIOUserDefaults.standardUserDefaults().removeValueForKey("LastLoginEmail");
                MIOUserDefaults.standardUserDefaults().removeValueForKey("LoginToken");
                MIOUserDefaults.standardUserDefaults().removeValueForKey("LoginOK");
            }

            // console.log(json);
            completion(err, result);
        });
    }

    loginBusiness(business:string, completion:any)     
    {
        const url = MIOURL.urlWithString(this.authURL + "/business/token");
        let body = {
            "BusinessID": business,
        }

        let urlRequest = new MWSJSONRequest();
        urlRequest.initWithURL(url, body, "POST");
        urlRequest.setHeaderValue("Bearer " + this.token, "Authorization");
        urlRequest.execute(this, function(code:number, json:any){
            let err = false;
            let result = false;
            if (code == 200 && json["status"].toLowerCase() == "ok") {
                // Login OK
                err = false;
                result = true;                                

                this.token = json["data"]["access_token"];

                MIOUserDefaults.standardUserDefaults().setValueForKey("LoginToken", this.token);
            }
            else {
                // Error
                err = true;
                result = false;
                this.token = null;
                MIOUserDefaults.standardUserDefaults().removeValueForKey("LoginToken");
            }

            // console.log(json);
            completion(err, result);
        });

    }

    signin(name:string, email:string, password:string, confirmed:string, target:any, completion:any) {
        const url = MIOURL.urlWithString(this.authURL + "/user/create");  
        let body = {
            "name": name,
            "username": email,
            "password": password,
            "password_confirmation": confirmed
        }

        let urlRequest = new MWSJSONRequest();        
        urlRequest.initWithURL(url, body, "POST");
        urlRequest.setHeaderValue(this.enviroment, "DL-ENVIRONMENT");
        urlRequest.execute(this, function(code:number, json:any){                  
            let err = false;
            let result = false;
            if (code == 200 || code == 201) {                
                err = false;
                result = true;
            }
            else {
                // Error
                err = true;
                result = false;
            }

            // console.log(json);
            completion.call(target, err, result, json);
        });
    }

    rememberPassword(email:string, language:string, target:any, completion:any) {
        let cmd = new WebServiceCommand(this.authURL, "/api/rememberpassword");
        cmd.method = "POST";
        cmd.params = {
            "lang": language,
            "email": email
        };
        cmd.execute(this, function (code, json) {

            var err = false;
            var result = false;
            if (code == 200 || code == 201) {
                // Remember OK
                err = false;
                result = true;
            }
            else {
                // Error
                err = true;
                result = false;
            }

            // console.log(json);
            completion.call(target, err, result, json);
        });
    }


    linkBusinessUser(businessID:string, name:string, email:string, completion:any){
        const url = MIOURL.urlWithString(this.authURL + "/business/" + businessID + "/add-user");
        const body = {
            // "userName": name,
            "userEmail": email
        }
        let urlRequest = new MWSJSONRequest();
        urlRequest.initWithURL(url, body, "POST");
        urlRequest.setHeaderValue("Bearer " + this.token, "Authorization");
        urlRequest.setHeaderValue(this.enviroment, "DL-ENVIRONMENT");
        urlRequest.execute(this, function(code:number, json:any){
            completion(code, json != null ? json["data"] : []);
        });
    }  

    unlinkBusinessUser(businessID:string, userID:string, completion:any){
        const url = MIOURL.urlWithString(this.authURL + "/business/" + businessID + "/remove-user/" + userID);
        let urlRequest = new MWSJSONRequest();
        urlRequest.initWithURL(url, null, "GET");
        urlRequest.setHeaderValue("Bearer " + this.token, "Authorization");
        urlRequest.setHeaderValue(this.enviroment, "DL-ENVIRONMENT");
        urlRequest.execute(this, function(code:number, json:any){
            completion(code, json != null ? json["data"] : []);
        });
    }  
   
}