


class ClientDebtCell extends MUITableViewCell
{
    delegate = null;

    private dateLabel:MUILabel = null;
    private payedDateLabel:MUILabel = null;
    private pendingLabel:MUILabel = null;
    private ticketLabel:MUILabel = null;    
    private priceLabel:MUILabel = null;
    private payButton:MUIButton = null;
    private actionButton:MUIButton = null;
    private printButton:MUIButton = null;        

    awakeFromHTML(){        
        this.dateLabel = MUIOutlet(this, 'date-lbl','MUILabel');        
        this.payedDateLabel = MUIOutlet(this, 'payed-date-lbl','MUILabel');        
        this.pendingLabel = MUIOutlet(this, 'pending-lbl','MUILabel');        
        this.priceLabel = MUIOutlet(this, 'value-lbl','MUILabel');
        this.ticketLabel = MUIOutlet(this, "ticket-lbl", "MUILabel");

        this.printButton = MUIOutlet(this, 'print-btn','MUIButton');
        this.printButton.setAction(this, function(){            
            DBHelper.queryObjectWithCompletion( "ArchivedDocument", null, MIOPredicate.predicateWithFormat( "legalDocumentID == '" + this.debtLine.legalDocumentID + "'" ), [], this, function( object:ArchivedDocument ){
                if (object == null ) { 
                    AppHelper.showErrorMessage( this, MIOLocalizeString( "ERROR", "ERROR" ), MIOLocalizeString( "SOMETHING WENT WRONG", "SOMETHING WENT WRONG" ) );
                    return;
                }
                
                PrinterHelper.sharedInstance().printArchivedDocument( object );
            } );            
        });

        this.payButton = MUIOutlet(this, 'pay-btn','MUIButton');
        this.payButton.setAction(this, function(){
           this.payDebt();
        });

        this.actionButton = MUIOutlet(this, "cell-action-btn", "MUIButton");
        this.actionButton.setAction(this, this.showActionsAlert);

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    private debtLine = null;
    set item(item:DebtLine){
        this.debtLine = item;

        let nf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;
        let df = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter as MIODateFormatter;
        
        this.dateLabel.text = df.stringFromDate(item.creationDate);
        this.payedDateLabel.text = df.stringFromDate(item.payedDate);     
        this.pendingLabel.text = nf.stringFromNumber(item.pendingValue);
        this.priceLabel.text = nf.stringFromNumber(item.debtValue);
        this.ticketLabel.text = item.legalDocumentID ? item.legalDocumentID : item.documentID;                        
        
        this.payButton.setHidden(!(item.pendingValue > 0 && item.isDiscarded == false && item.isVoid == false));
        this.actionButton.setHidden(!(item.pendingValue > 0 && item.isDiscarded == false && item.isVoid == false));

        if (item.isDiscarded == true){
            MUICoreLayerAddStyle(this.ticketLabel.layer, "alert");
            MUICoreLayerAddStyle(this.dateLabel.layer, "alert");
            MUICoreLayerAddStyle(this.payedDateLabel.layer, "alert");
            MUICoreLayerAddStyle(this.pendingLabel.layer, "alert");
            MUICoreLayerAddStyle(this.priceLabel.layer, "alert");
        }
        else {
            MUICoreLayerRemoveStyle(this.ticketLabel.layer, "alert");
            MUICoreLayerRemoveStyle(this.dateLabel.layer, "alert");
            MUICoreLayerRemoveStyle(this.payedDateLabel.layer, "alert");
            MUICoreLayerRemoveStyle(this.pendingLabel.layer, "alert");
            MUICoreLayerRemoveStyle(this.priceLabel.layer, "alert");
        }

    }

    private showActionsAlert(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle( MIOLocalizeString( "ACTIONS", "ACTIONS" ), MIOLocalizeString( "CHOOSE AN OPTION", "CHOOSE AN OPTION" ), MUIAlertViewStyle.Default );

        avc.addAction(MUIAlertAction.alertActionWithTitle( MIOLocalizeString( "DISCARD", "DISCARD" ), MUIAlertActionStyle.Destructive, this, function(){
            this.discardDebt();
        } ));

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString( "CANCEL", "CANCEL" ), MUIAlertActionStyle.Cancel, null, null ) );

        AppHelper.sharedInstance().presentViewController(avc, true);
    }

    private payDebt(){        
        if(this.delegate != null && (typeof this.delegate.showPayDebtOptions === "function"))
            this.delegate.showPayDebtOptions(this.debtLine);
    }

    private discardDebt(){
        if(this.delegate != null && (typeof this.delegate.discardDebtFromCell === "function"))
            this.delegate.discardDebtFromCell(this, this.debtLine);
    }

}