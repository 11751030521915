class QRLocationViewController extends BaseTableViewController
{
    static newInstance() : QRLocationViewController {
        let vc = new QRLocationViewController( "qr-location-view" );
        vc.initWithResource( "layout/qr_location/QRLocationView.html" );
        return vc;
    }

    private settingsButton:MUIButton = null;

    viewDidLoad(): void {
        super.viewDidLoad();

        this.sectionsButton = MUIOutlet( this, "settings-btn", "MUIButton" );
        this.sectionsButton.setAction( this, this.showOptions );

        this.tableView = MUIOutlet(  this, "table-view", "UITableView" );
        this.tableView.dataSource = this;
    }
    
    titleForHeaderInSection(tableView:UITableView, section:number) {
        let ip = MIOIndexPath.indexForRowInSection( 0, section );
        let item = this.fetchedResultsController.objectAtIndexPath( ip ) as Location;
        return item.category.name;
    }


    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath( indexPath ) as Location;

        let cell = tableView.dequeueReusableCellWithIdentifier( "QRLocationCell" ) as QRLocationCell;
        cell.item = item;

        return cell;
    }    

    get fetchedResultsController() : MIOFetchedResultsController {
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [
            MIOSortDescriptor.sortDescriptorWithKey( "category.name", true ),
            MIOSortDescriptor.sortDescriptorWithKey( "name", true )
        ];

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Location", sortDescriptors, null);
        fetchRequest.relationshipKeyPathsForPrefetching = ["category"];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "category.name");
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    private showOptions() {
        let avc = new MUIAlertViewController();
        avc.initWithTitle( MIOLocalizeString("OPTIONS", "OPTIONS"), MIOLocalizeString("SELECT AN OPTION", "SELECT AN OPTION"), MUIAlertViewStyle.Default );

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString("GENERATE QR CODES", "GENERATE QR CODES"), MUIAlertActionStyle.Default, this, function(this:QRLocationViewController){
            this.generateQR();
        } ) );

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString("EXPORT QR CODES", "EXPORT QR CODES"), MUIAlertActionStyle.Default, this, function(this:QRLocationViewController){
            this.exportQRCodes();
        } ) );

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString( "CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null ) );

        this.presentViewController( avc, true );
    }

    private generateQR() {
        for (let index = 0; index < this.fetchedResultsController.fetchedObjects.length; index++ ){
            let l = this.fetchedResultsController.fetchedObjects[ index ] as Location;
            l.qrCode = this.generate_hash( l.category.identifier + l.identifier, 6 );
        }

        DBHelper.saveMainContext();
    }

    private generate_hash(str:string, len:number) : string {

        let allowedSymbols = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        let h = "";
        for (let index = 0; index < len; index++ ) h += "0";

        for (let i = 0; i < str.length; i++) {
            let a = h.charCodeAt( i % len );
            let b = str.charCodeAt( i );
            let c = String.fromCharCode ( a ^ b );
            let index = i % len;
            h = h.substring( 0, index ) + c + h.substring( index + 1 );
        }

        for (let i = 0; i < len; i++) {
            let c = allowedSymbols[ h.charCodeAt( i ) % allowedSymbols.length ];
            h = h.substring( 0, i ) + c + h.substring( i + 1 );
        }

        return h;
    }

    private exportQRCodes()  {
        let exporter = new DBExportExportQRLocationController();
        exporter.export();
    }

}