//
// Generated class Tax
//

/// <reference path="Tax_ManagedObject.ts" />

class Tax extends Tax_ManagedObject
{

}
