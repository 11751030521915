
class AdyenHOPLinkViewController extends MUIViewController
{
    private _size = new MIOSize(750, 700);
    get preferredContentSize(){ return this._size; }

    private webView: MUIWebView = null;

    viewDidLoad() {
        super.viewDidLoad();

        this.webView = new MUIWebView();
        this.webView.init();        
        this.view.addSubview( this.webView );
        
        if (this._url) this.webView.setURL( this._url );
    }

    private _url:string = null;
    set url(url: string) {
        this._url = url;
        if (this.webView) this.webView.setURL( url );
    }
}