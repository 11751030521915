//
// Generated class Warehouse
//

/// <reference path="Warehouse_ManagedObject.ts" />

class Warehouse extends Warehouse_ManagedObject
{

}
