//
// Generated class StockCustomConcept
//

/// <reference path="StockCustomConcept_ManagedObject.ts" />

enum StockCustomConceptType 
{
    Both,
    Input,
    Ouput    
}

class StockCustomConcept extends StockCustomConcept_ManagedObject
{

}
