
class OnlineOrderListCell extends UITableViewCell
{
    private dateLabel:MUILabel = null;
    private documentIDLabel:MUILabel = null;
    private clientLabel:MUILabel = null;
    private totalLabel:MUILabel = null;

    
    awakeFromHTML(){
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");
        this.documentIDLabel = MUIOutlet(this, "doc-id-lbl", "MUILabel");
        this.clientLabel = MUIOutlet(this, "client-name-lbl", "MUILabel");
        this.totalLabel = MUIOutlet(this, "total-lbl", "MUILabel");
    }   

    isToday = false;
    set item(item:OnlineOrder) {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        if (this.dateLabel != null) this.dateLabel.text = (this.isToday ? "" : item.sessionDeliveryDay + " ") + item.sessionDeliveryTime;
        this.documentIDLabel.text = item.orderNumber.toString();
        this.totalLabel.text = ad.currencyFormatter.stringFromNumber(item.total);
        this.clientLabel.text = item.clientName;        
    }
}