//
// Generated class LegalEntityPaymentInstallment
//

/// <reference path="LegalEntityPaymentInstallment_ManagedObject.ts" />

class LegalEntityPaymentInstallment extends LegalEntityPaymentInstallment_ManagedObject
{

}
