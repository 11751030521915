

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class StockInputFormat_ManagedObject

class StockInputFormat_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: barcode
    set barcode(value:string) {
        this.setValueForKey(value, 'barcode');
    }
    get barcode():string {
        return this.valueForKey('barcode');
    }
    set barcodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'barcode');
    }
    get barcodePrimitiveValue():string {
        return this.primitiveValueForKey('barcode');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: quantity
    set quantity(value:number) {
        this.setValueForKey(value, 'quantity');
    }
    get quantity():number {
        return this.valueForKey('quantity');
    }
    set quantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'quantity');
    }
    get quantityPrimitiveValue():number {
        return this.primitiveValueForKey('quantity');
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
}
