

enum BookingSection {
    SectionHeader,
    SectionCustomerHeader,
    SectionCustomer,
    SectionZoneHeader,
    SectionZones,
    SectionAccounting,
    SectionOrigin,
    SectionComments
}

class BookingDetailGeneralViewController extends MUIViewController
{   
    private headerView:BookingDetailHeaderView = null;
    
    private zonesView:BookingDetailZoneView = null;
    private pricesView:BookingDetailPriceView = null;
    private sourcesView:BookingDetailSourceView = null;
    private customerCommentsView:BookingDetailCommentsView = null;
    private hallCommentsView:BookingDetailCommentsView = null;
    private internalCommentsView:BookingDetailCommentsView = null;
    private allergensView:BookingDetailAllergensView = null;

    private tableView:UITableView = null;            

    private zoneHeader:MUIView = null;
    private addZoneButton:MUIButton = null;    

    dayConfiguration:BookingDay = null;

    viewDidLoad() {
        super.viewDidLoad();

        this.headerView = MUIOutlet(this, "header-view", "BookingDetailHeaderView");

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        
        // this.zonesView = MUIOutlet(this, "zones-view", "BookingDetailZoneView");
        // this.pricesView = MUIOutlet(this, "prices-view", "BookingDetailPriceView");
        // this.sourcesView = MUIOutlet(this, "sources-view", "BookingDetailSourceView");
        // this.customerCommentsView = MUIOutlet(this, "customer-comments-view", "BookingDetailCommentsView");
        // this.hallCommentsView = MUIOutlet(this, "hall-comments-view", "BookingDetailCommentsView");
        // this.internalCommentsView = MUIOutlet(this, "internal-comments-view", "BookingDetailCommentsView");
        // this.allergensView = MUIOutlet(this, "allergens-view", "BookingDetailAllergensView");

        //this.headerView.times = this.timeRangeFetchedResultsController.resultObjects;
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }
 
    private bookings = [];
    private bookingGroup:BookingGroup = null;
    private _booking:Booking = null;    
    set booking(value:Booking){    
        //if (this._booking != null) this._booking.removeObserver(this, "status");        
        //if (this._booking != null) this._booking.addObserver(this, "status");

        this._booking = value;
        this.bookingGroup = value.group;
        if (this._booking.group == null) {            
            this.bookingGroup = null;
            this.bookings = [value];
            this.updateUI();
        }        
        else {
            let sds = [MIOSortDescriptor.sortDescriptorWithKey("createdAt", true)];
            let predicate = MIOPredicate.predicateWithFormat("group.identifier == " + this._booking.group.identifier + " AND status != " + BookingStatus.BookingDeleted);
            DBHelper.queryObjectsWithCompletion("Booking", sds, predicate, [], this, function(objects){
                this.bookings = objects;
                this.updateUI();                
            });
        }                
    }

    observeValueForKeyPath(keypath:string, type, object){
        if (type != "did") return;
        switch (keypath) {
            case "status":
                this.setStatusColor();
                break;
        }
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._booking == null) return;
        
        this.updateBookings();

        this.headerView.bookings = this.bookings as [Booking];
        // this.zonesView.booking = this._booking;
        // this.pricesView.booking = this._booking;
        // this.sourcesView.booking = this._booking;
        // this.customerCommentsView.setBooking(this._booking, "clientComments");
        // this.hallCommentsView.setBooking(this._booking, "comments");
        // this.internalCommentsView.setBooking(this._booking, "privateComments");
        // this.allergensView.booking = this._booking;
        //     this.setStatusColor();            
        //     this.tableView.reloadData();
        // }
        
    }    

    private updateBookings(){
        // if (this.bookingGroup == null) {            
        //     this.bookings = [this._booking];
        // }        
        // else {
        //     this.bookings = [];
        //     for (let index = 0; index < this.bookingGroup.bookings.count; index++){
        //         let b = this.bookingGroup.bookings.objectAtIndex(index);
        //         this.bookings.push(b);
        //     }                
        // }
        this.tableView.reloadData();
    }

    numberOfSections(tableview){
        return 1;
    }
    
    numberOfRowsInSection(tableview, section){
        return this.bookings.length;
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("BookingDetailCell") as BookingDetailCell;
        let booking = this.bookings[indexPath.row] as Booking;
        
        cell.item = booking;
        cell.delegate = this;

        return cell;
    }

    // viewForHeaderInSection(tableview, section){
    //     if(tableview == this.tableView){
    //         if (section == 0) return null;

    //         if (section != 2 && section != 4) {
    //             var h = new MUIView();
    //             h.init();
    //             h.layer.background = "";
    //             h.setHeight(23);
    //             return h;
    //         }
    //     }
    //     else return null;
    // }

    // cellAtIndexPath(tableview, indexPath:MIOIndexPath){
    //     let cell = null;
    //     if(tableview == this.tableView){   
            
    //         switch(indexPath.section){
    //             case BookingSection.SectionHeader:
    //                 cell = tableview.dequeueReusableCellWithIdentifier("BookingHeaderCell");
    //                 cell.times = this.timeRangeFetchedResultsController.resultObjects;
    //                 break;

    //             case BookingSection.SectionCustomerHeader:
    //                 cell = tableview.dequeueReusableCellWithIdentifier("BookingCustomerHeaderCell");
    //                 break;

    //             case BookingSection.SectionCustomer:
    //                 cell = tableview.dequeueReusableCellWithIdentifier("BookingCustomerCell");
    //                 cell.delegate = this;
    //                 break;

    //             case BookingSection.SectionZoneHeader:
    //                 cell = tableview.dequeueReusableCellWithIdentifier("BookingRelationZoneHeaderCell");
    //                 cell.selectionStyle = MUITableViewCellSelectionStyle.None;                    
    //                 break;

    //             case BookingSection.SectionZones:
    //                 cell = tableview.dequeueReusableCellWithIdentifier("BookingRelationZoneCell");
    //                 cell.selectionStyle = MUITableViewCellSelectionStyle.None;
    //                 let zone = this._booking.relationZones.objectAtIndex(indexPath.row);
    //                 cell.zone = zone;                                        
    //                 cell.editing = true;
    //                 break;

    //             case BookingSection.SectionAccounting:
    //                 cell = tableview.dequeueReusableCellWithIdentifier("BookingAccountingCell");
    //                 break;

    //             case BookingSection.SectionOrigin:
    //                 cell = tableview.dequeueReusableCellWithIdentifier("BookingOriginCell");
    //                 break;

    //             case BookingSection.SectionComments:
    //                 cell = tableview.dequeueReusableCellWithIdentifier("BookingCommentsCell");
    //                 break;
                
    //             default:
    //                 throw new Error('Error, Unknown section');
            
    //         //cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
    //         }
    //         cell.selectionStyle = MUITableViewCellSelectionStyle.None;
    //         cell.delegate = this;
    //         cell.item = this._booking;
    //     }
        
    //     return cell;
    // }

    
    private setStatusColor(){
        // switch (this._booking.status){
            
        //     case BookingStatus.BookingRequest:
        //     this.tableView.headerView.setBackgroundRGBColor(255, 142, 9);
        //     break;

        //     case BookingStatus.BookingAssigned:
        //     this.tableView.headerView.setBackgroundRGBColor(65, 93, 188);
        //     break;

        //     case BookingStatus.BookingConfirmed:
        //     this.tableView.headerView.setBackgroundRGBColor(0, 128, 64);
        //     break;

        //     case BookingStatus.BookingFinished:
        //     this.tableView.headerView.setBackgroundRGBColor(128, 237, 11);
        //     break;

        //     case BookingStatus.BookingWaiting:
        //     this.tableView.headerView.setBackgroundRGBColor(211, 190, 15);
        //     break;

        //     case BookingStatus.BookingCancelled:
        //     case BookingStatus.BookingDenied:
        //     case BookingStatus.BookingDeleted:
        //     this.tableView.headerView.setBackgroundRGBColor(200, 120, 120);
        //     break;

        //     case BookingStatus.BookingNoShow:
        //     this.tableView.headerView.setBackgroundRGBColor(167, 1, 203);
        //     break;

        //     case BookingStatus.BookingNoCC:
        //     this.tableView.headerView.setBackgroundRGBColor(237, 88, 141);
        //     break;

        //     case BookingStatus.BookingCCCanceled:
        //     this.tableView.headerView.setBackgroundRGBColor(204, 102, 0);
        //     break;

        //     default:
        //     this.tableView.headerView.setBackgroundRGBColor(0, 0, 0);
        //     break;
        // }

    }

    addNewBooking(booking:Booking){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        if (this.bookingGroup == null) {
            this.bookingGroup = MIOEntityDescription.insertNewObjectForEntityForName("BookingGroup", ad.managedObjectContext) as BookingGroup;
            this.bookingGroup.addBookingsObject(this._booking);

            this._booking.group = this.bookingGroup;
        }
        
        this.bookingGroup.addBookingsObject(booking);
        booking.group = this.bookingGroup;
        DBHelper.saveMainContext();        

        this.bookings.push(booking);

        this.updateBookings();
    }
}
