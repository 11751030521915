

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class BookingZoneConfiguration_ManagedObject

class BookingZoneConfiguration_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: capacity
    set capacity(value:number) {
        this.setValueForKey(value, 'capacity');
    }
    get capacity():number {
        return this.valueForKey('capacity');
    }
    set capacityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'capacity');
    }
    get capacityPrimitiveValue():number {
        return this.primitiveValueForKey('capacity');
    }

    // Property: paymentPeriodInMinutes
    set paymentPeriodInMinutes(value:number) {
        this.setValueForKey(value, 'paymentPeriodInMinutes');
    }
    get paymentPeriodInMinutes():number {
        return this.valueForKey('paymentPeriodInMinutes');
    }
    set paymentPeriodInMinutesPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'paymentPeriodInMinutes');
    }
    get paymentPeriodInMinutesPrimitiveValue():number {
        return this.primitiveValueForKey('paymentPeriodInMinutes');
    }
    // Relationship: bookingTimeRangePresetConfiguration
    set bookingTimeRangePresetConfiguration(value:BookingTimeRangePresetConfiguration) {
        this.setValueForKey(value, 'bookingTimeRangePresetConfiguration');
    }
    get bookingTimeRangePresetConfiguration():BookingTimeRangePresetConfiguration {
        return this.valueForKey('bookingTimeRangePresetConfiguration') as BookingTimeRangePresetConfiguration;
    }
    // Relationship: bookingZone
    set bookingZone(value:BookingZone) {
        this.setValueForKey(value, 'bookingZone');
    }
    get bookingZone():BookingZone {
        return this.valueForKey('bookingZone') as BookingZone;
    }

    // Relationship: paymentRules
    protected _paymentRules:MIOManagedObjectSet = null;
    get paymentRules():MIOManagedObjectSet {
        return this.valueForKey('paymentRules');
    }
    addPaymentRulesObject(value:RuleResult) {
        this._addObjectForKey(value, 'paymentRules');
    }
    removePaymentRulesObject(value:RuleResult) {
        this._removeObjectForKey(value, 'paymentRules');
    }
}
