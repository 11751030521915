class FormatsGlobalActionsViewController extends MUIViewController
{  
    private backButton:MUIButton = null;
    private nextButton:MUIButton = null;
    private tableView:MUITableView = null;

    public delegate = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");
        this.backButton.setAction(this, function (){            
            this.navigationController.popViewController(true);
        });
        
        this.nextButton = MUIOutlet(this, "done-btn", "MUIButton");
        this.nextButton.setAction(this, function (){            
            
        });

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(){
        super.viewWillAppear();

        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        // this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableView:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
                                              
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){        
        let cell = tableView.dequeueReusableCellWithIdentifier("GlobalActionsLabelCell") as GlobalActionsLabelCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Format;                      
        cell.item = item;
        return cell;
    }   

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){ 

        
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
        let fetchRequest:MIOFetchRequest = null;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true), MIOSortDescriptor.sortDescriptorWithKey("orderIndex", true)]; 
        let filterString = null;
        fetchRequest = DBHelper.listFetchRequestWithEntityName("ProductModifier", sortDescriptors, filterString);
        fetchRequest.relationshipKeyPathsForPrefetching = ['productModifierCategory','product', 'tax'];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }

}
