
class WorkerDataSource extends MIOObject
{
    private tableView:UITableView = null;
    initWithTableView(tableView:UITableView){
        this.tableView = tableView;
    }

    private _searchString:string = null;
    set searchString(searchString:string){
        this._searchString = searchString;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableView:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableView:UITableView, indexPath: MIOIndexPath) {
        let cell = tableView.dequeueReusableCellWithIdentifier("WorkerCell") as WorkersAdminCell;
        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);                       
        //let name = MIOLocalizeString(item.title.toUpperCase(), item.title);

        cell.item = item;
        // this link dictates the use of the check mark
        //cell.accessoryType = this._user.businessPermission.hasManagerPermision(item.bitMask) ? MUITableViewCellAccessoryType.Checkmark : MUITableViewCellAccessoryType.None;
        return cell;
    }

    // canSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
    //     let item = this.fetchedResultsController.objectAtIndexPath(indexPath);

    //     let loginEmail = MIOUserDefaults.standardUserDefaults().valueForKey("LastLoginEmail");                        
    //     if (this._user.email == loginEmail && item.bitMask == 47) return false;

    //     return true;
    // }    

     /* didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        let cell = tableView.cellAtIndexPath(indexPath) as ReportsPermissionCell;

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        
        // //if user currently has permission, remove them
        // if (this._user.businessPermission.hasManagerPermision(item.bitMask)) {
        //     // Remove
        //     this._user.businessPermission.removeManagerPermission(item.bitMask);
        //     cell.accessoryType = MUITableViewCellAccessoryType.None;
        // }
        // else {
        //     //add permission to the user.buinessPermission
        //     this._user.businessPermission.addManagerPermission(item.bitMask);
        //     cell.accessoryType = MUITableViewCellAccessoryType.Checkmark;
        // }        
    } */

    private _fetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    // get fetchedResultsController(){
    //     if (this._fetchedResultsController != null)
    //         return this._fetchedResultsController;
    
    //     let ad = MUIWebApplication.sharedInstance().delegate;
    
    //     let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('ManagerSection');
    //     fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('moduleIndex', true),
    //                                     MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true)];
    
    //     let predicateValues = [];
    //     //predicateValues.push("bitMask & " + SettingsHelper.sharedInstance().permisisons + " > 0");
                
    //     if (predicateValues.length > 0) fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateValues.join(" AND "));

    //     let fetchedResultsController = new MIOFetchedResultsController();
    //     fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "moduleIndex");
    //     fetchedResultsController.delegate = this;
    
    //     fetchedResultsController.performFetch();
    
    //     this._fetchedResultsController = fetchedResultsController;    
    //     return this._fetchedResultsController;
    // }


    get fetchedResultsController(){        
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
    
        let sd = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];

        let predicateFormat:string = null;
        if (this._searchString != null) predicateFormat = "name CONTAINS '" + this._searchString + "'";
        
        let fetchRequest:MIOFetchRequest = DBHelper.listFetchRequestWithEntityName("Employee", sd, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["department", "role"];
 
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){        
        this.tableView.reloadData();
    }
}