//
// Generated class Booking_BookingLocation
//

/// <reference path="Booking_BookingLocation_ManagedObject.ts" />

class Booking_BookingLocation extends Booking_BookingLocation_ManagedObject
{

}
