

/// <reference path="PresetItem.ts" />

// Generated class PresetMenu_ManagedObject

class PresetMenu_ManagedObject extends PresetItem
{
    // Relationship: menu
    set menu(value:Menu) {
        this.setValueForKey(value, 'menu');
    }
    get menu():Menu {
        return this.valueForKey('menu') as Menu;
    }
}
