
class DBToolResultSelectCell extends MUIReportTableViewCell
{
    private checkButton:MUICheckButton = null;

    awakeFromHTML(){        
        this.checkButton = MUIOutlet(this, 'check-btn', 'MUICheckButton');
        this.checkButton.setOnChangeValue(this, function(control, value){
            this._item["selected"] = value;
        });
    }

    private _item = null;
    set item(value){
        this._item = value;
        this.checkButton.on = value["selected"] == false ? false : true;
    }
}
