
/// <reference path="ReportFilterCell.ts" />


class ReportEnumFilterCell extends ReportFilterCell
{
    private comboBox:MUIComboBox = null;

    awakeFromHTML(): void {
        super.awakeFromHTML();

        this.comboBox = MUIOutlet(this, "values-cb", "MUIComboBox");
        this.comboBox.setOnChangeAction(this, function(control:MUIComboBox, value:string){
            this.filterItem["Value"] = value;
            this.filterItem["ValueString"] = value;
        });
        
    }
    
    setFilter(filter:any) {
        super.setFilter(filter);
        this.comboBox.removeAllItems();
        let values = filter["EnumValues"].split(",");
        for (let i = 0; i < values.length; i++){
            let value = values[i];
            let title = MIOLocalizeString(value, value);
            this.comboBox.addItem( title, value );
        }

        this.filterItem["Value"] = values[0];
        this.filterItem["ValueString"] = values[0];
    }

}