


class SupplierDetailGeneralViewController extends MUIViewController
{        
    private nameTextField:MUITextField = null;
    private emailTextField:MUITextField = null;
    private documentTextField:MUITextField = null;
    private addressTextField:MUITextField = null;
    private stateTextField:MUITextField = null;
    private countryDropdown:MUIButton = null;
    private cityTextField:MUITextField = null;
    private postalCodeTextField:MUITextField = null;
    private phoneTextField:MUITextField = null;
    private mobilePhoneTextField:MUITextField = null;  
    // private tagView:TagView = null;  
    private addContactButton:MUIButton = null;
    private contactsTableView:UITableView = null;
    
    viewDidLoad(){
        super.viewDidLoad();

        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.nameTextField.setOnChangeText(this, function(textfield, value){
            this._supplier.name = (value != null ? value.trim() : null);
        });    

        this.documentTextField = MUIOutlet(this, "documentid-tf", "MUITextField");
        this.documentTextField.setOnChangeText(this, function(textfield, value){
            this._supplier.document= (value != null ? value.trim() : null);
        });

        this.emailTextField = MUIOutlet(this, "email-tf", "MUITextField");
        this.emailTextField.setOnChangeText(this, function(textfield, value){
            this._supplier.email = (value != null ? value.trim() : null);
        });

        this.phoneTextField = MUIOutlet(this, "phone-tf", "MUITextField");
        this.phoneTextField.setOnChangeText(this, function(textfield, value){
            this.phone.number = (value != null ? value.trim() : null);
        });
        
        this.mobilePhoneTextField = MUIOutlet(this, "mobile-tf", "MUITextField");
        this.mobilePhoneTextField.setOnChangeText(this, function(textfield, value){
            this.mobilePhone.number = (value != null ? value.trim() : null);
        });

        this.postalCodeTextField = MUIOutlet(this, "postal-code-tf", "MUITextField");
        this.postalCodeTextField.setOnChangeText(this, function(textfield, value){
            this.address.postalCode = (value != null ? value.trim() : null);
        });

        this.addressTextField = MUIOutlet(this, "address-tf", "MUITextField");
        this.addressTextField.setOnChangeText(this, function(textfield, value){
            this.address.address1 = (value != null ? value.trim() : null);
        });
        
        this.stateTextField = MUIOutlet(this, "state-tf", "MUITextField");
        this.stateTextField.setOnChangeText(this, function(textfield, value){
            this.address.state = (value != null ? value.trim() : null);
        });

        this.cityTextField = MUIOutlet(this, "city-tf", "MUITextField");
        this.cityTextField.setOnChangeText(this, function(textfield, value){
            this.address.city = (value != null ? value.trim() : null);
        });

        this.countryDropdown = MUIOutlet(this, "country-dd", "MUIButton");
        this.countryDropdown.setAction(this, function(){
            AppHelper.sharedInstance().showSelectCountryViewControllerFromView(this.countryDropdown, null, this, function(controller:SelectEntityViewController, country:Country){
                this.address.country = country.name;
                this.address.countryISON = country.ison;
                this.address.countryISOA2 = country.iso2;
                this.address.countryISOA3 = country.iso3;
                this.countryDropdown.title = country.name;
            });
        });

        // this.tagView = MUIOutlet(this, "tag-view", "TagView");
        // this.tagView.delegate = this;

        this.addContactButton = MUIOutlet(this, "add-contact-btn", "MUIButton");
        this.addContactButton.setAction(this, this.addContactAction);

        this.contactsTableView = MUIOutlet(this, "contacts-table-view", "UITableView");
        this.contactsTableView.dataSource = this;
        this.contactsTableView.delegate = this;
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private supplierTags  = [];
    private _supplier:Supplier = null;
    set supplier(value:Supplier){
        this._supplier = value;
        if (value != null) this.supplierTags = value.tags == null ? [] : value.tags.split(',');
        this.updateUI();
    }
        
    private updateUI(){
        if (this.viewIsLoaded == false || this._supplier == null) return;

        this.nameTextField.text = this._supplier.name;
        this.emailTextField.text = this._supplier.email;
        this.documentTextField.text = this._supplier.document;

        this.setAddress(this.address)
        this.setPhone(this.phone);
        this.setMobilePhone(this.mobilePhone);
        this.countryDropdown.title = this.address.country;

        // let predicate = MIOPredicate.predicateWithFormat('type = ' + TagType.Supplier);
        // DBHelper.queryObjectsWithCompletion("Tag", null, predicate, [], this, function(this:SupplierDetailGeneralViewController, objects:Tag[]){
        //     this.reloadTags(objects);
        // });
        
        this.contactsTableView.reloadData();

    }
    
    private get address():Address{
        if(this._supplier.address == null){
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;    
            this._supplier.address = MIOEntityDescription.insertNewObjectForEntityForName("Address", moc) as Address;   
            DBHelper.saveMainContext();
        }
        return this._supplier.address;
    }

    private setAddress(address:Address){
        this.addressTextField.text = address != null ? address.address1 : '';
        this.stateTextField.text = address != null ? address.state : '';
        this.countryDropdown.title = address != null ?  address.country : ''; //getCountryName( address.countryISOA2 ) : '';
        this.cityTextField.text = address != null ? address.city : '';
        this.postalCodeTextField.text = address != null ? address.postalCode : '';
    }

    private get phone(){
        if(this._supplier.phone == null){
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;    
            this._supplier.phone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", moc) as PhoneNumber; 
            DBHelper.saveMainContext();
        }
        return this._supplier.phone;
    }

    private setPhone(phone){
        this.phoneTextField.text = phone != null ? phone.number : '';
    }

    private get mobilePhone(){
        if(this._supplier.mobilePhone == null){
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;    
            this._supplier.mobilePhone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", moc) as PhoneNumber;   
            DBHelper.saveMainContext();                              
        }
        return this._supplier.mobilePhone;
    } 

    private setMobilePhone(phone){
        this.mobilePhoneTextField.text = phone != null ? phone.number : '';
    }

    // private reloadTags(tags:Tag[]){
    //     this.tagView.removeAll();
    //     for (let index = 0; index < tags.length; index++){
    //         let tag = tags[index] as Tag;
    //         this.tagView.addTag(tag.name);
    //         if (tags.containsObject(tag.name)) {
    //             this.tagView.selectTagAtIndex(index);
    //         }
    //     }
    // }

    // didSelectTagAtIndex(index, name:string){        
    //     let i = this.supplierTags.indexOfObject(name);
    //     if (i < 0){
    //         this.supplierTags.addObject(name);            
    //     }
    //     else {
    //         this.supplierTags.removeObject(name);
    //     }

    //     this._supplier.tags = this.supplierTags.join(',');
    // }

    //
    // Contacts
    //

    private addContactAction(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ADD NEW CONTACT", "ADD NEW CONTACT"), MIOLocalizeString("TYPE THE NAME OF THE NEW CONTACT", "TYPE THE NAME OF THE NEW CONTACT"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
            textField.placeHolder = MIOLocalizeString("CONTACT NAME", "CONTACT NAME");
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Default, this, function(this:SupplierDetailGeneralViewController) {
            let name = avc.textFields[0].text?.trim();
            this.addContact(name);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private addContact(name:string){
        let ad = MUIWebApplication.sharedInstance().delegate;
        let contact = MIOEntityDescription.insertNewObjectForEntityForName("Contact", ad.managedObjectContext) as Contact;
        contact.name = name;
        contact.legalEntity = this._supplier;
        DBHelper.saveMainContext();
    }

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {
        let cell = tableView.dequeueReusableCellWithIdentifier("SupplierContactCell") as SupplierContactCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        cell.item = item;
        return cell;
    }    

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Contact;

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            DBHelper.deleteObjectFromMainContext(item, true);
        }
    }
    
    protected _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value:MIOFetchedResultsController){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController() : MIOFetchedResultsController {
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

        let predicateFromat = "legalEntity.identifier = " + this._supplier.identifier;
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Contact", sortDescriptors, predicateFromat);
        // fetchRequest.relationshipKeyPathsForPrefetching = ['address','phone','mobilePhone'];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        this.contactsTableView.reloadData();
    }



}
