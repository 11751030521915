

// Generated class BookingZoneOcupationRule_ManagedObject

class BookingZoneOcupationRule_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: minPax
    set minPax(value:number) {
        this.setValueForKey(value, 'minPax');
    }
    get minPax():number {
        return this.valueForKey('minPax');
    }
    set minPaxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minPax');
    }
    get minPaxPrimitiveValue():number {
        return this.primitiveValueForKey('minPax');
    }

    // Property: maxPax
    set maxPax(value:number) {
        this.setValueForKey(value, 'maxPax');
    }
    get maxPax():number {
        return this.valueForKey('maxPax');
    }
    set maxPaxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'maxPax');
    }
    get maxPaxPrimitiveValue():number {
        return this.primitiveValueForKey('maxPax');
    }

    // Property: limit
    set limit(value:number) {
        this.setValueForKey(value, 'limit');
    }
    get limit():number {
        return this.valueForKey('limit');
    }
    set limitPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'limit');
    }
    get limitPrimitiveValue():number {
        return this.primitiveValueForKey('limit');
    }

    // Property: presetID
    set presetID(value:string) {
        this.setValueForKey(value, 'presetID');
    }
    get presetID():string {
        return this.valueForKey('presetID');
    }
    set presetIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'presetID');
    }
    get presetIDPrimitiveValue():string {
        return this.primitiveValueForKey('presetID');
    }

    // Property: presetName
    set presetName(value:string) {
        this.setValueForKey(value, 'presetName');
    }
    get presetName():string {
        return this.valueForKey('presetName');
    }
    set presetNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'presetName');
    }
    get presetNamePrimitiveValue():string {
        return this.primitiveValueForKey('presetName');
    }

    // Property: channelID
    set channelID(value:string) {
        this.setValueForKey(value, 'channelID');
    }
    get channelID():string {
        return this.valueForKey('channelID');
    }
    set channelIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'channelID');
    }
    get channelIDPrimitiveValue():string {
        return this.primitiveValueForKey('channelID');
    }

    // Property: channelName
    set channelName(value:string) {
        this.setValueForKey(value, 'channelName');
    }
    get channelName():string {
        return this.valueForKey('channelName');
    }
    set channelNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'channelName');
    }
    get channelNamePrimitiveValue():string {
        return this.primitiveValueForKey('channelName');
    }

    // Property: eventID
    set eventID(value:string) {
        this.setValueForKey(value, 'eventID');
    }
    get eventID():string {
        return this.valueForKey('eventID');
    }
    set eventIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'eventID');
    }
    get eventIDPrimitiveValue():string {
        return this.primitiveValueForKey('eventID');
    }

    // Property: partyID
    set partyID(value:string) {
        this.setValueForKey(value, 'partyID');
    }
    get partyID():string {
        return this.valueForKey('partyID');
    }
    set partyIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'partyID');
    }
    get partyIDPrimitiveValue():string {
        return this.primitiveValueForKey('partyID');
    }

    // Property: createdAt
    set createdAt(value:Date) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():Date {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():Date {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:Date) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():Date {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:Date) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():Date {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: zone
    set zone(value:BookingZone) {
        this.setValueForKey(value, 'zone');
    }
    get zone():BookingZone {
        return this.valueForKey('zone') as BookingZone;
    }
}
