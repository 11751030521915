

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class LocationGroupRule_ManagedObject

class LocationGroupRule_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: maxPax
    set maxPax(value:number) {
        this.setValueForKey(value, 'maxPax');
    }
    get maxPax():number {
        return this.valueForKey('maxPax');
    }
    set maxPaxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'maxPax');
    }
    get maxPaxPrimitiveValue():number {
        return this.primitiveValueForKey('maxPax');
    }

    // Property: minPax
    set minPax(value:number) {
        this.setValueForKey(value, 'minPax');
    }
    get minPax():number {
        return this.valueForKey('minPax');
    }
    set minPaxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minPax');
    }
    get minPaxPrimitiveValue():number {
        return this.primitiveValueForKey('minPax');
    }

    // Property: priority
    set priority(value:number) {
        this.setValueForKey(value, 'priority');
    }
    get priority():number {
        return this.valueForKey('priority');
    }
    set priorityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'priority');
    }
    get priorityPrimitiveValue():number {
        return this.primitiveValueForKey('priority');
    }

    // Relationship: locations
    protected _locations:MIOManagedObjectSet = null;
    get locations():MIOManagedObjectSet {
        return this.valueForKey('locations');
    }
    addLocationsObject(value:Location) {
        this._addObjectForKey(value, 'locations');
    }
    removeLocationsObject(value:Location) {
        this._removeObjectForKey(value, 'locations');
    }
}
