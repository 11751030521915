//
// Generated class Employee
//

/// <reference path="Employee_ManagedObject.ts" />


enum EmployeePOSPermissionsType
{
    Settings              = 1,
    CashDesk              = 1 << 1,
    WorkerOrder           = 1 << 2,
    WorkerNLOrder         = 1 << 3,
    WorkerOnline          = 1 << 4,
    Deletion              = 1 << 5,
    Invitation 			  = 1 << 6,
    Accounts              = 1 << 7,
    Debts                 = 1 << 8,
    DiscountFixed         = 1 << 9,
    BlindCashDesk         = 1 << 10,

    Products              = 1 << 11,
    Clients               = 1 << 12,
    Workers               = 1 << 13,
    HistoryAndReports     = 1 << 14,
    Stock                 = 1 << 15,

    ModifyDocuments       = 1 << 16,

    Charge                = 1 << 17,

    Manager               = 1 << 18,
    Rates                 = 1 << 19,
    ManagerDelete         = 1 << 20,
    Presets               = 1 << 21,
    ManagersDashboard     = 1 << 22,
    Bookings              = 1 << 23,

    OpenCloseCashDesk     = 1 << 24, // User only can open and close the cash desk, can't view totals (as BlindCashDesk permission), excludes PermissionCashDesk and PermissionBlindCashDesk

    MoveProducts          = 1 << 25, // User can move products between different orders, move complete orders, merge table orders, move from BAR to Tables, etc.

	Print                 = 1 << 26,

	StockInternalOrders   = 1 << 27,
    StockMovements        = 1 << 28,
    StockReturnOrders     = 1 << 29,
	StockInventories      = 1 << 30,
	
    DiscountCustom        = 1 << 31

    // PermissionAll = 2^(maxPermission + 1) - 1 = 2^(26 + 1) - 1 = 134217727

    // Maximum possible permissions: 32 --> ULONG_MAX = 4294967295 ( 2^(31 + 1) - 1 )

    // Last possible permission: 1 << 31
};

enum EmployeeBookingPermissionType
{
    Visualization              = 1,
    Write                      = 1 << 1,
    WriteAll                   = 1 << 2,
    ManageEconomicAccount      = 1 << 3,
    ClientHistory              = 1 << 4,
    Logs                       = 1 << 5,
    BlackList                  = 1 << 6,
    ManageWorkers              = 1 << 7,
    POSControl                 = 1 << 8,
    SittingPlanning            = 1 << 9,
    MapEdition                 = 1 << 10,
    Settings                   = 1 << 11,
    VisualizationGuestNoLevel  = 1 << 12,
    VisualizationGuestLevel1   = 1 << 13,
    VisualizationGuestLevel2   = 1 << 14,
    VisualizationGuestLevel3   = 1 << 15,
    WriteGuestNoLevel          = 1 << 16,
    WriteGuestLevel1           = 1 << 17,
    WriteGuestLevel2           = 1 << 18,
    WriteGuestLevel3           = 1 << 19,
    ManageGuestLists           = 1 << 20,
};

class Employee extends Employee_ManagedObject
{
    static posPermisionsCount(){
        return 32;
    }

    static posPermisionAtIndex(index):EmployeePOSPermissionsType{
        return (1 << index);
    }

    static stringForPOSPermission(permission){
        let name = EmployeePOSPermissionsType[permission];
        let localizedName = ("Permission" + name).toUpperCase();
        return MIOLocalizeString(localizedName, localizedName);
    }

    hasPOSPermision(permission){
        return (this.permissions & permission) > 0;
    }

    public addPOSPermission(permission){
        this.permissions |= permission;
    }

    public removePOSPermission(permission){
        this.permissions &= ~permission;
    }

    public isManagerUser(){
        return (this.permissions & EmployeePOSPermissionsType.Manager) > 0;
    }

    static bookingPermisionsCount(){
        return (Object.keys(EmployeeBookingPermissionType).length / 2) - 2;
    }

    static bookingPermisionAtIndex(index):EmployeeBookingPermissionType{
        return (1 << index);
    }

    static stringForBookingPermission(permission){
        let name = EmployeeBookingPermissionType[permission];
        let localizedName = ("BookingPermission" + name).toUpperCase();
        return MIOLocalizeString(localizedName, localizedName);
    }

    hasBookingPermision(permission){
        return (this.bookingPermissions & permission) > 0;
    }

    public addBookingPermission(permission){
        this.bookingPermissions |= permission;
    }

    public removeBookingPermission(permission){
        this.bookingPermissions &= ~permission;
    }

    public changeRole(role:WorkerRole){
        this.role = role;

        if (role == null) return;

        // Copy permissions from role to the employee
        this.permissions = role.permissions;
        this.bookingPermissions = role.bookingPermissions;

        MIONotificationCenter.defaultCenter().postNotification("EmployeePermissionsDidChange", this);
    }

}
