//
// Generated class LegalEntityLoan
//

/// <reference path="LegalEntityLoan_ManagedObject.ts" />

class LegalEntityLoan extends LegalEntityLoan_ManagedObject
{

}
