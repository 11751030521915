

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class CoreAccounting_ManagedObject

class CoreAccounting_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: analyticAccountName
    set analyticAccountName(value:string) {
        this.setValueForKey(value, 'analyticAccountName');
    }
    get analyticAccountName():string {
        return this.valueForKey('analyticAccountName');
    }
    set analyticAccountNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'analyticAccountName');
    }
    get analyticAccountNamePrimitiveValue():string {
        return this.primitiveValueForKey('analyticAccountName');
    }

    // Property: journalEntryLocked
    set journalEntryLocked(value:boolean) {
        this.setValueForKey(value, 'journalEntryLocked');
    }
    get journalEntryLocked():boolean {
        return this.valueForKey('journalEntryLocked');
    }
    set journalEntryLockedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'journalEntryLocked');
    }
    get journalEntryLockedPrimitiveValue():boolean {
        return this.primitiveValueForKey('journalEntryLocked');
    }

    // Property: ledgerAccountComments
    set ledgerAccountComments(value:string) {
        this.setValueForKey(value, 'ledgerAccountComments');
    }
    get ledgerAccountComments():string {
        return this.valueForKey('ledgerAccountComments');
    }
    set ledgerAccountCommentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ledgerAccountComments');
    }
    get ledgerAccountCommentsPrimitiveValue():string {
        return this.primitiveValueForKey('ledgerAccountComments');
    }

    // Property: ledgerAccountName
    set ledgerAccountName(value:string) {
        this.setValueForKey(value, 'ledgerAccountName');
    }
    get ledgerAccountName():string {
        return this.valueForKey('ledgerAccountName');
    }
    set ledgerAccountNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ledgerAccountName');
    }
    get ledgerAccountNamePrimitiveValue():string {
        return this.primitiveValueForKey('ledgerAccountName');
    }
    // Relationship: analyticAccount
    set analyticAccount(value:AnalyticAccount) {
        this.setValueForKey(value, 'analyticAccount');
    }
    get analyticAccount():AnalyticAccount {
        return this.valueForKey('analyticAccount') as AnalyticAccount;
    }
    // Relationship: journalEntry
    set journalEntry(value:AccountingJournalEntry) {
        this.setValueForKey(value, 'journalEntry');
    }
    get journalEntry():AccountingJournalEntry {
        return this.valueForKey('journalEntry') as AccountingJournalEntry;
    }
    // Relationship: ledgerAccount
    set ledgerAccount(value:LedgerAccount) {
        this.setValueForKey(value, 'ledgerAccount');
    }
    get ledgerAccount():LedgerAccount {
        return this.valueForKey('ledgerAccount') as LedgerAccount;
    }
    // Relationship: ledgerAccountBalance
    set ledgerAccountBalance(value:LedgerAccountBalance) {
        this.setValueForKey(value, 'ledgerAccountBalance');
    }
    get ledgerAccountBalance():LedgerAccountBalance {
        return this.valueForKey('ledgerAccountBalance') as LedgerAccountBalance;
    }
}
