
class SalesInvoiceDetailViewController extends InvoiceDetailViewController
{
    static newInstance(): SalesInvoiceDetailViewController {
        let vc = new SalesInvoiceDetailViewController("sales-invoice-detail-view");
        vc.initWithResource("layout/invoices/SalesInvoiceDetailView.html");
        return vc;
    }

    // viewDidLoad(){
    //     super.viewDidLoad();

    //     this.editHeader = MUIOutlet(this, "edit-header-view", "SalesInvoiceDetailHeaderView");
    //     this.editHeader.setHidden(true);

    //     this.noEditHeader = MUIOutlet(this, "no-edit-header-view", "SalesInvoiceDetailHeaderView");
    //     this.noEditHeader.setHidden(false);
    // }
}