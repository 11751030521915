//
// Generated class Preset
//

/// <reference path="Preset_ManagedObject.ts" />

enum PresetType
{
    PresetTypeProducts          = 0,
    PresetTypeMenus             = 1,
    PresetTypeFavourites        = 2
}


class Preset extends Preset_ManagedObject
{

}
