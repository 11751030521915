

/// <reference path="PaymentTransaction.ts" />

// Generated class SupplierOrderPaymentTransaction_ManagedObject

class SupplierOrderPaymentTransaction_ManagedObject extends PaymentTransaction
{
    // Relationship: supplierOrder
    set supplierOrder(value:SupplierOrder) {
        this.setValueForKey(value, 'supplierOrder');
    }
    get supplierOrder():SupplierOrder {
        return this.valueForKey('supplierOrder') as SupplierOrder;
    }
}
