class WarehouseDetailCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    
    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, 'name-lbl', 'MUILabel');            
    }
    
    set item(i:WarehouseLocation){
        this.nameLabel.text = i.name; //this needs to be i.location.name
    }
}
