

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class PayMethodName_ManagedObject

class PayMethodName_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: language
    set language(value:number) {
        this.setValueForKey(value, 'language');
    }
    get language():number {
        return this.valueForKey('language');
    }
    set languagePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'language');
    }
    get languagePrimitiveValue():number {
        return this.primitiveValueForKey('language');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }
    // Relationship: payMethod
    set payMethod(value:PayMethod) {
        this.setValueForKey(value, 'payMethod');
    }
    get payMethod():PayMethod {
        return this.valueForKey('payMethod') as PayMethod;
    }
}
