//
// Generated class DBMappingEntityWarehouse
//

/// <reference path="DBMappingEntityWarehouse_ManagedObject.ts" />

class DBMappingEntityWarehouse extends DBMappingEntityWarehouse_ManagedObject
{

}
