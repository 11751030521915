
class PendingPaymentsCell extends UITableViewCell
{
    private docNumberLabel:MUILabel = null;    
    private dateLabel:MUILabel = null;
    private conceptLabel:MUILabel = null;
    private clientLabel:MUILabel = null;
    private amountLabel:MUILabel = null;
    private statusLabel:MUILabel = null;

    awakeFromHTML(): void {
        this.docNumberLabel = MUIOutlet( this, "doc-id-lbl", "MUILabel" );
        this.dateLabel = MUIOutlet( this, "date-lbl", "MUILabel" );
        this.conceptLabel = MUIOutlet( this, "concept-lbl", "MUILabel" );
        this.clientLabel = MUIOutlet( this, "client-name-lbl", "MUILabel" );
        this.amountLabel = MUIOutlet( this, "amount-lbl", "MUILabel" );
        this.statusLabel = MUIOutlet( this, "status-lbl", "MUILabel" );
    }

    private installment:PaymentInstallment = null;
    set item( item: PaymentInstallment ) {
        this.installment = item;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this.clientLabel.text = null;
        this.statusLabel.text = item.statusString();

        if (item instanceof LegalEntityPaymentInstallment) {
            this.docNumberLabel.text = item.documentNumber;
            this.clientLabel.text = item.legalEntityName;
        }
                
        this.dateLabel.text = ad.dateFormatter.stringFromDate( item.date );
        this.conceptLabel.text = item.concept;
        this.amountLabel.text = ad.currencyFormatter.stringFromNumber( item.amount );

        switch(item.status) {
            case PaymentInstallmentStatus.locked: this.add_style( "warning" ); break;
            case PaymentInstallmentStatus.unpaid: this.add_style( "alert" ); break;
        }    
    }

    private add_style(style:string) {
        MUICoreLayerAddStyle( this.clientLabel.layer, style );
        MUICoreLayerAddStyle( this.statusLabel.layer, style );
        MUICoreLayerAddStyle( this.docNumberLabel.layer, style );
        MUICoreLayerAddStyle( this.dateLabel.layer, style );
        MUICoreLayerAddStyle( this.conceptLabel.layer, style );
        MUICoreLayerAddStyle( this.amountLabel.layer, style );
    }

}

