
class PriceRuleCell extends RuleCell
{
    private minPax:MUITextField = null;
    private maxPax:MUITextField = null;
    private priceTextField:MUITextField = null;
    private priceTypeComboBox:MUIComboBox = null;
    private priceToPayTextField:MUITextField = null;
    private minspendTextField:MUITextField = null;
    private minspendTypeComboBox:MUIComboBox = null;
    private minspendToPayTextField:MUITextField = null;
    private channelComboBox:MUIComboBox = null;

    private nf = MUIWebApplication.sharedInstance().delegate.integerFormatter as MIONumberFormatter;
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;

    awakeFromHTML(){
        this.minPax = MUIOutlet(this, "min-pax-tf", "MUITextField");
        this.minPax.setOnChangeText(this, function(control, value){            
            this._item.minPax = this.nf.numberFromString(value);
        });

        this.maxPax = MUIOutlet(this, "max-pax-tf", "MUITextField");
        this.maxPax.setOnChangeText(this, function(control, value){
            this._item.maxPax = this.nf.numberFromString(value);
        });

        this.priceTextField = MUIOutlet(this, "price-tf", "MUITextField");
        this.priceTextField.setOnChangeText(this, function(control, value){
            this._item.price = this.cf.numberFromString(value);
        });

        this.priceTypeComboBox = MUIOutlet(this, "price-type-cb", "MUIComboBox");
        this.priceTypeComboBox.setOnChangeAction(this, function(control, value){
            this._item.priceType = parseInt(value);
        });

        this.priceToPayTextField = MUIOutlet(this, "price-to-charge-tf", "MUITextField");
        this.priceToPayTextField.setOnChangeText(this, function(control, value){
            let v = value.length > 0 ? value : null;
            this._item.priceAmountToPay = v;
        });

        this.minspendTextField = MUIOutlet(this, "min-spend-tf", "MUITextField");
        this.minspendTextField.setOnChangeText(this, function(control, value){
            this._item.minSpend = this.nf.numberFromString(value);
        });

        this.minspendTypeComboBox = MUIOutlet(this, "minspend-type-cb", "MUIComboBox");
        this.minspendTypeComboBox.setOnChangeAction(this, function(control, value){
            this._item.minSpendType = parseInt(value);
        });

        this.minspendToPayTextField = MUIOutlet(this, "minspend-to-charge-tf", "MUITextField");
        this.minspendToPayTextField.setOnChangeText(this, function(control, value){
            let v = value.length > 0 ? value : null;
            this._item.minSpendAmountToPay = v;
        });

        this.channelComboBox = MUIOutlet(this, "channel-cb", "MUIComboBox");
        this.setupChannelComboBox(this.channelComboBox);
        this.channelComboBox.setOnChangeAction(this, function(control, value){
            let channel = DBHelper.queryObjectFromMainContext("BookingChannel", MIOPredicate.predicateWithFormat("identifier == " + value));
            this._item.channelID = channel.identifier;
            this._item.channelName = channel.name;
        });
    
        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    private _item:BookingPriceRule = null;
    set item (item:BookingPriceRule){
        this._item = item;

        this.minPax.text = item.minPax;
        this.maxPax.text = item.maxPax;        
        this.priceTextField.text = this.cf.stringFromNumber(item.price);
        this.priceTypeComboBox.selectItem(item.priceType);
        this.priceToPayTextField.text = item.priceAmountToPay;
        this.minspendTextField.text = this.cf.stringFromNumber(item.minSpend);
        this.minspendTypeComboBox.selectItem(item.minSpendType);
        this.minspendToPayTextField.text = item.minSpendAmountToPay;
                
        let sds = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        let predicate = MIOPredicate.predicateWithFormat("deletedAt == null");
        DBHelper.queryObjectsWithCompletion("BookingChannel", sds, predicate, [], this, function(objects){
            this.fillBookingChannelComboBox(this.channelComboBox, objects, item.channelID);
        });  
    }
}