//
// Generated class EmployeeSalaryConcept
//

/// <reference path="EmployeeSalaryConcept_ManagedObject.ts" />

class EmployeeSalaryConcept extends EmployeeSalaryConcept_ManagedObject
{

}
