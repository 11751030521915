

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class IntegratorMappingView_ManagedObject

class IntegratorMappingView_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: sourceID
    set sourceID(value:string) {
        this.setValueForKey(value, 'sourceID');
    }
    get sourceID():string {
        return this.valueForKey('sourceID');
    }
    set sourceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceID');
    }
    get sourceIDPrimitiveValue():string {
        return this.primitiveValueForKey('sourceID');
    }

    // Property: sourceName
    set sourceName(value:string) {
        this.setValueForKey(value, 'sourceName');
    }
    get sourceName():string {
        return this.valueForKey('sourceName');
    }
    set sourceNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceName');
    }
    get sourceNamePrimitiveValue():string {
        return this.primitiveValueForKey('sourceName');
    }

    // Property: sourceType
    set sourceType(value:number) {
        this.setValueForKey(value, 'sourceType');
    }
    get sourceType():number {
        return this.valueForKey('sourceType');
    }
    set sourceTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'sourceType');
    }
    get sourceTypePrimitiveValue():number {
        return this.primitiveValueForKey('sourceType');
    }

    // Property: targetID
    set targetID(value:string) {
        this.setValueForKey(value, 'targetID');
    }
    get targetID():string {
        return this.valueForKey('targetID');
    }
    set targetIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'targetID');
    }
    get targetIDPrimitiveValue():string {
        return this.primitiveValueForKey('targetID');
    }

    // Property: targetName
    set targetName(value:string) {
        this.setValueForKey(value, 'targetName');
    }
    get targetName():string {
        return this.valueForKey('targetName');
    }
    set targetNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'targetName');
    }
    get targetNamePrimitiveValue():string {
        return this.primitiveValueForKey('targetName');
    }
    // Relationship: mapping
    set mapping(value:IntegratorMapping) {
        this.setValueForKey(value, 'mapping');
    }
    get mapping():IntegratorMapping {
        return this.valueForKey('mapping') as IntegratorMapping;
    }
}
