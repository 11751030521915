//
// Generated class IntegratorMappingInfo
//

/// <reference path="IntegratorMappingInfo_ManagedObject.ts" />

class IntegratorMappingInfo extends IntegratorMappingInfo_ManagedObject
{

}
