
class TimeRangeGroupSelectViewController extends MUIViewController 
{
    static newInstance():TimeRangeGroupSelectViewController {
        let vc = new TimeRangeGroupSelectViewController("time-range-group-select-view");
        vc.initWithResource("layout/timerangepreset/TimeRangeGroupSelectView.html");
        return vc;
    }

    private cancelButton:MUIButton = null;
    private addButton:MUIButton = null;
    private doneButton:MUIButton = null;
    private tableView:UITableView = null;

    public delegate = null;
    public preferredContentSize = new MIOSize(700, 600);

    viewDidLoad(){
        super.viewDidLoad();

        this.cancelButton = MUIOutlet(this, "cancel-btn", "MUIButton");
        this.cancelButton.setAction(this, function(control){
            this.dismissViewController(true);
        });

        this.doneButton = MUIOutlet(this, 'done-btn', 'MUIButton');
        this.doneButton.setAction(this, function (){     
            this.updateTimeRangeGroupsInPreset();
            this.dismissViewController(true);
        });


        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function (control, value){
            this.addNewTimeRangeGroup();
        })
        
        this.tableView = MUIOutlet(this,"table-view","UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;                
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);        
        this.updateUI();
    }

    private _timeRangePreset: TimeRangePreset = null;
    set timeRangePresetItem(item:TimeRangePreset){        
        this._timeRangePreset = item;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        this.fetchedResultsController = null;
        this.tableView.reloadData();        
    }

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableView:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let cell = tableView.dequeueReusableCellWithIdentifier("TimeRangeGroupCell") as TimeRangeGroupCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as TimeRangeGroup;
        cell.item = item;
        cell.accessoryType = UITableViewCellAccessoryType.None;

        //check if group exists in _timeRangePreset and add checkmark if it does
        for (let index = 0; index < this._timeRangePreset.timeRangeGroups.count; index++) {
            let grp = this._timeRangePreset.timeRangeGroups.objectAtIndex(index) as TimeRangeGroup;
            if (grp == item) {
                cell.accessoryType = UITableViewCellAccessoryType.Checkmark;
            }
        }

        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){ 
        let cell = tableView.cellAtIndexPath(indexPath) as TimeRangeGroupCell;
        // let selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath);
        // this.showItemDetail(selectedItem); 

        cell.accessoryType = (cell.accessoryType == UITableViewCellAccessoryType.Checkmark) ? UITableViewCellAccessoryType.None : UITableViewCellAccessoryType.Checkmark;
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {         
        return UITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as TimeRangeGroup;
        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            DBHelper.deleteObjectFromMainContext(item, true);
            this.updateUI();
        }
    }

    private _fetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("startHour", true), MIOSortDescriptor.sortDescriptorWithKey("name", true)]; 
        let predicate = null;
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("TimeRangeGroup", sortDescriptors, predicate);
        // fetchRequest.relationshipKeyPathsForPrefetching = ['timeRangePresets'];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;        
    }

    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    private addNewTimeRangeGroup() {

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('TIME RANGE','TIME RANGE'), MIOLocalizeString('CREATE NEW TIME RANGE GROUP','CREATE NEW TIME RANGE GROUP'), MUIAlertViewStyle.Default);
        
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("START TIME (EG. 9:00)","START TIME (EG. 9:00)"));
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("END TIME (EG. 22:00","END TIME (EG. 22:00"));
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("TIME INTERVAL IN MINUTES (EG. 15, 30, ETC.)","TIME INTERVAL IN MINUTES (EG. 15, 30, ETC.)"));
        });
        
        // avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
        //     textField.setPlaceholderText("Quantity");
        //     let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        //     textField.formatter = ad.numberFormatter;
        // });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','DONE'), MUIAlertActionStyle.Default, this, function(){
            
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

            //get main variables from view
            let name = avc.textFields[0].text.trim();
            let startTime = avc.textFields[1].text;
            let endTime = avc.textFields[2].text
            let interval = ad.numberFormatter.numberFromString(avc.textFields[3].text);

            //convert to date objects for comparison
            let startDate:any = ad.timeFormatter.dateFromString(startTime);
            let endDate:any = ad.timeFormatter.dateFromString(endTime);

            //check for a name
            if(!name) {
                //not valid, need name
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('PLEASE ENTER A VALID NAME','PLEASE ENTER A VALID NAME'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                this.presentViewController(error, true);
                return;
            }

            //get the interval in minutes
            let minDifference = null;
            if( endDate - startDate == 0 ){
                //not valid
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('START AND END TIME MUST BE DIFFERENT','START AND END TIME MUST BE DIFFERENT'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                this.presentViewController(error, true);
                return;
            } else if ( endDate - startDate > 0 ) {
                minDifference =  (endDate - startDate) / 60000; //millisecons to min
            } else {
                //next day
                endDate.setDate(endDate.getDate() + 1);
                minDifference =  (endDate - startDate) / 60000; //millisecons to min
            }

            //check interval validity
            if( minDifference % interval != 0 ){
                //throw some error and return
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('UNACCEPTABLE TIME INTERVAL','UNACCEPTABLE TIME INTERVAL'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                this.presentViewController(error, true);
                return;
            }

            //check for TimeRangeGroup overlaps
            let isValid = this.checkTimeRangeValidity(startDate, endDate, this._timeRangePreset.timeRangeGroups.allObjects);
            if (!isValid) {
                //alert error and return
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('TIME RANGES CANNOT OVERLAP','TIME RANGES CANNOT OVERLAP'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                this.presentViewController(error, true);
                return;
            }

            // Creat new TimeRangeGroup
            let newTimeRangeGroup = MIOEntityDescription.insertNewObjectForEntityForName("TimeRangeGroup", moc) as TimeRangeGroup;
            newTimeRangeGroup.name = name;
            newTimeRangeGroup.identifier = MIOUUID.UUID().UUIDString;
            newTimeRangeGroup.addTimeRangePresetsObject(this._timeRangePreset);
            newTimeRangeGroup.startHour = avc.textFields[1].text;
            newTimeRangeGroup.endHour = avc.textFields[2].text;
            newTimeRangeGroup.duration = avc.textFields[3].text;

            moc.save() //save added here to ensure that group is saved before ranges (workaround for error in API)

            // Creat TimeRanges
            let newTimeRange:TimeRange;
            let newDate:Date;
            for (var index = 0; index < (minDifference/interval); index++) {
                newTimeRange = MIOEntityDescription.insertNewObjectForEntityForName("TimeRange", moc) as TimeRange;
                newDate = new Date(startDate.getTime() + (interval*60000*index))
                newTimeRange.identifier =  MIOUUID.UUID().UUIDString;
                newTimeRange.hour = ad.timeFormatter.stringFromDate(newDate);
                newTimeRange.name = newTimeRange.hour;
                newTimeRange.groupName = name;
                newTimeRange.nextDay = this.sameDay(startDate, newDate);
                newTimeRange.duration = interval;
                newTimeRange.timeRangeGroup = newTimeRangeGroup;
            }

            moc.save() //see above note one saves

            this.updateUI();

        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Cancel, this, function(){}));
        
        this.presentViewController(avc, true);
    }

    //checks if two dates are in the same day
    private sameDay(d1:Date, d2:Date) {
        return !(d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate());
    }

    //checks if a time range (startDate and endDate) have overlaps with TimeRangeGroups (passed as an array of TimeRangeGroup)
    private checkTimeRangeValidity(startDate, endDate, ranges:any) {

        if (ranges == null) return true;

        let isValid = true;
        let tempStartDate = null;
        let tempEndDate = null;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        //loop through timeRanges that exist and return false if overlap
        for(var index = 0; index < ranges.length; index++) {

            tempStartDate = ad.timeFormatter.dateFromString(ranges[index].startHour);
            tempEndDate = ad.timeFormatter.dateFromString(ranges[index].endHour);

            //check for nextDay endTime
            if (tempStartDate - tempEndDate > 0) {
                tempEndDate.setDate(tempEndDate.getDate() + 1); //add a day to the end date
            }

            // CHECKS FOR POSITIVE CASES (new date ranges is completly before or after existing ranges)
            if ( !((startDate < tempStartDate && endDate < tempStartDate) || (tempEndDate < startDate && tempEndDate < endDate)) ) {
                isValid = false;
            }

            // CHECKS FOR NEGATIVE CASES (Either check for positive cases or negative)
            // if( (timetempStartDate < start && start < timetempEndDate) || (timetempStartDate < end && end < timetempEndDate) ) {
            //     isValid = false;
            // } else if (start < timetempStartDate && timetempEndDate < end) {
            //     isValid = false;
            // }
        }

        return isValid; //no overlaping cases found, return true
    }

    // private deleteTimeRangeGroup(group:TimeRangeGroup){

        //check for and delete TimeRanges found in TimeRangeGroup
        // let predicate = MIOPredicate.predicateWithFormat("timeRangeGroup.identifier == " + group.identifier);            
        // DBHelper.queryObjectsWithCompletion("TimeRange", [MIOSortDescriptor.sortDescriptorWithKey("name", true)], predicate, ["timeRangeGroup"], this, function(objects){
            
        //     for (let index = 0; index < objects.length; index++){
        //         DBHelper.deleteObjectFromMainContext(objects[index] as TimeRange, false);
        //     }

        //     DBHelper.deleteObjectFromMainContext(group as TimeRangeGroup, true)
        //     this.updateUI();
        // });
    // }

    // when select "DONE" take all selected TimeRangeGroup and add them to the selected TimeRangePreset
    private updateTimeRangeGroupsInPreset() {

        // Remove existing timeRangeGroups from timeRangePreset
        for (let i=0; i<this._timeRangePreset.timeRangeGroups.length; i++) {
            this._timeRangePreset.removeTimeRangeGroupsObject(this._timeRangePreset.timeRangeGroups[0]);
        }


        //NEED TO GO THOUGH THE TABLE CELLS, SEE WHAT IS CHECKED, AND ADD OBJECT AT IP TO _timeRangePreset

        //loop though sections
        for (let j=0; j<this.fetchedResultsController.sections.length; j++) {
            //loop though rows in section
            for (let i=0; i<this.fetchedResultsController.sections[j].numberOfObjects(); i++ ) {

                //get cell at indexPath, compare, add valid object to _timeRangePreset
                let ip = MIOIndexPath.indexForRowInSection(i,j);
                let tempCell = this.tableView.cellAtIndexPath(ip);
                if (tempCell.accessoryType == UITableViewCellAccessoryType.Checkmark) {
                    // timeRangeGroupArray.push(this.fetchedResultsController.objectAtIndexPath(ip) as TimeRangeGroup);
                    let item = this.fetchedResultsController.objectAtIndexPath(ip) as TimeRangeGroup;
                    this._timeRangePreset.addTimeRangeGroupsObject(item);
                }
            }
        }

        //save
        
   
        //add selected groups to timeRangePreset
        // for (let i=0; i<timeRangeGroupArray.length; i++) {
        //     this._timeRangePreset.addTimeRangeGroupsObject(timeRangeGroupArray[i]);
        // }
   
        //add selected groups to _TimeRangePreset
        //this._timeRangePreset.timeRangeGroups = this.fetchedResultsController.objectAtIndexPath(0); //try to add just the first object
        //alert("Add selected timeRangeGroups to this._timeRangePreset.timeRangeGroups\n(when relations work)\nthen close window and updateUI()")
            
    }

}
