

/// <reference path="CoreAccounting.ts" />

// Generated class Bank_ManagedObject

class Bank_ManagedObject extends CoreAccounting
{

    // Property: balance
    set balance(value:number) {
        this.setValueForKey(value, 'balance');
    }
    get balance():number {
        return this.valueForKey('balance');
    }
    set balancePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'balance');
    }
    get balancePrimitiveValue():number {
        return this.primitiveValueForKey('balance');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Relationship: accounts
    protected _accounts:MIOManagedObjectSet = null;
    get accounts():MIOManagedObjectSet {
        return this.valueForKey('accounts');
    }
    addAccountsObject(value:BankAccount) {
        this._addObjectForKey(value, 'accounts');
    }
    removeAccountsObject(value:BankAccount) {
        this._removeObjectForKey(value, 'accounts');
    }
}
