
class LogViewController extends BaseTableViewController
{
    private conceptFilterTextField:ColumnFilterTextField = null;
    private locationNameFilterTextField:ColumnFilterTextField = null;    
    private orderIDFilterTextField:ColumnFilterTextField = null;    
    private ticketIDFilterTextField:ColumnFilterTextField = null;    
    private LegalIDFilterTextField:ColumnFilterTextField = null;    
    private destinationLocationNameFilterTextField:ColumnFilterTextField = null;    

    private filterController:ColumnFilterController = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.filterController = new ColumnFilterController();
        this.filterController.initWithDelegate(this);

        this.conceptFilterTextField = MUIOutlet(this, "concept-tf", "ColumnFilterTextField");
        this.conceptFilterTextField.filterController = this.filterController;
        this.conceptFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "concept", null, null);

        this.locationNameFilterTextField = MUIOutlet(this, "location-name-tf", "ColumnFilterTextField");
        this.locationNameFilterTextField.filterController = this.filterController;
        this.locationNameFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "locationName", null, null);
        
        this.orderIDFilterTextField = MUIOutlet(this, "order-id-tf", "ColumnFilterTextField");
        this.orderIDFilterTextField.filterController = this.filterController;
        this.orderIDFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "orderID", null, null);        

        this.ticketIDFilterTextField = MUIOutlet(this, "ticket-id-tf", "ColumnFilterTextField");
        this.ticketIDFilterTextField.filterController = this.filterController;
        this.ticketIDFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "ticketID", null, null);        

        this.LegalIDFilterTextField = MUIOutlet(this, "ticket-legal-id-tf", "ColumnFilterTextField");
        this.LegalIDFilterTextField.filterController = this.filterController;
        this.LegalIDFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "ticketLegalID", null, null);        

        this.destinationLocationNameFilterTextField = MUIOutlet(this, "dst-location-name-tf", "ColumnFilterTextField");
        this.destinationLocationNameFilterTextField.filterController = this.filterController;
        this.destinationLocationNameFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "destinationLocationName", null, null);        

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.needsReloadData();
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("LogCell") as LogCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as LogOperation;

        cell.item = item;

        return cell;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
                    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("LogOperation");
        fetchRequest.fetchLimit = 200;
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("date", false)];

        let filterFormat = this.filterController.filterPredicateFormat();
        if (filterFormat != null) {
            fetchRequest.predicate = MIOPredicate.predicateWithFormat(filterFormat);
        }        
        
        let fetchedResultsController = new MIOFetchedResultsController();        
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    filterPredicateDidChange(controller:ColumnFilterController){
        this.invalidateFetch();
    }

}