//
// Generated class BookingModifierCategory_BookingZone
//

/// <reference path="BookingModifierCategory_BookingZone_ManagedObject.ts" />

class BookingModifierCategory_BookingZone extends BookingModifierCategory_BookingZone_ManagedObject
{

}
