class WarehouseGlobalActionsLabelCell extends UITableViewCell
{
    private titleLabel:MUILabel = null;
    private nameLabel:MUILabel = null;
    private minTextField:MUITextField = null;
    private maxTextField:MUITextField = null;
    private isDefaultSwitch:MUISwitchButton = null;

    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter; 
    
    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");

        this.minTextField = MUIOutlet(this, "min-tf", "MUITextField");
        this.minTextField.setOnChangeText(this, function(control, value){
            //add number formater
            this.productWarehouse.minQuantity = this.nf.numberFromString(value.trim()) | 0;
        }); 

        this.maxTextField = MUIOutlet(this, "max-tf", "MUITextField");
        this.maxTextField.setOnChangeText(this, function(control, value){
            this.productWarehouse.maxQuantity = this.nf.numberFromString(value.trim()) | 100;
        }); 

        this.isDefaultSwitch = MUIOutlet(this, 'switch-btn', 'MUISwitchButton');
        this.isDefaultSwitch.setOnChangeValue(this, function (value){
            this.item.isDefalut = value.on;
            //DBHelper.saveMainContext();
        });


        //this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    set title (text:string) {
        this.titleLabel.text = MIOLocalizeString(text, text);
    }

    set name (text:string) {
        this.nameLabel.text = MIOLocalizeString(text, text);
    }

    private productWarehouse:ProductWarehouse = null;
    set item (item:ProductWarehouse) {

        if (item == this.productWarehouse || !item) return;
        this.productWarehouse = item;

        this.nameLabel.text = MIOLocalizeString(item.warehouse.name, item.warehouse.name);
        this.minTextField.text = item.minQuantity;
        this.maxTextField.text = item.maxQuantity;
        this.isDefaultSwitch.setOn(item.isDefault);
    }

}