class PlaceSettingCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;
    private valueTextField:MUITextField = null;
    private switchButton:MUISwitchButton = null;
    private dropdownButton:MUIButton = null;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        
        this.valueTextField = this.setupValueTextField("value-tf");        
        this.switchButton = this.setupSwitchButton("switch-btn");                
        this.dropdownButton = this.setupDropdownButton("dropdown-btn");

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    private _item:Configuration = null;
    set item (item:Configuration){
        this._item = item;        
        this.nameLabel.text = MIOLocalizeString(item.name, item.name);

        switch (item.type){
            case ConfigurationValueType.String:
            this.valueTextField.text = item.stringValue;  
            break;

            case ConfigurationValueType.Boolean:
            this.switchButton.on = item.boolValue;
            break;

            case ConfigurationValueType.Entity:
            this.dropdownButton.title = item.relationEntityValueTitle ? item.relationEntityValueTitle
             : "";
            break;

            default:
            this.valueTextField.text = item.stringValue;
            break;
        }                
    }

    private setupValueTextField(outlet:string): MUITextField {
        let textField = MUIOutlet(this, outlet, "MUITextField");
        if (textField == null) return null;

        textField.setOnChangeText(this, function(control:MUITextField, value:string){
            this._item.stringValue = value;
        });        

        return textField;
    }
    
    private setupSwitchButton(outlet:string): MUISwitchButton {
        let button = MUIOutlet(this, outlet, "MUISwitchButton");
        if (button == null) return null;

        button.setOnChangeValue(this, function(control:MUISwitchButton, value:boolean){
            this._item.boolValue = value;
        });
        
        return button;
    }

    private setupDropdownButton(outlet:string): MUIButton {
        let button = MUIOutlet(this, outlet, "MUIButton");
        if (button == null) return;

        button.setAction(this, function() {
            this.showSelectEntityViewController();
        }); 
        
        return button;
    }

    private showSelectEntityViewController() {

        let predicateFormat = null;
        if (this._item.relationEntityName == "Application") {
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            predicateFormat = "placeID == " + ad.selectedIdentifier;    
        }

        let vc = AppHelper.sharedInstance().showCustomSelectViewControllerFromView(this.dropdownButton, this._item.relationEntityName, this._item.relationEntityTitleKey, predicateFormat, this, function(controller, object:MIOManagedObject){

            
            let title = object?.valueForKey(this._item.relationEntityTitleKey);
            let value = object?.valueForKey(this._item.relationEntityValueKey);
            
            this.dropdownButton.title = title;
            this._item.stringValue = value;
            this._item.relationEntityValueTitle = title;
        }, true );
    }


}