
class CashDeskSessionLineCell extends MUITableViewCell
{
    private dateLabel:MUILabel = null;
    private typeLabel:MUILabel = null;
    private conceptLabel:MUILabel = null;
    private documentIDLabel:MUILabel = null;
    private locationLabel:MUILabel = null;
    private payMethodButton:MUIButton = null;
    private payMethodLabel:MUILabel = null;
    private infoLabel:MUILabel = null;
    private amountLabel:MUILabel = null;
    private workerIcon:MUIView = null;
    private actionButton:MUIButton = null;

    awakeFromHTML(){
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");
        this.typeLabel = MUIOutlet(this, "type-lbl", "MUILabel");
        this.conceptLabel = MUIOutlet(this, "concept-lbl", "MUILabel");
        this.documentIDLabel = MUIOutlet(this, "document-id-lbl", "MUILabel");
        this.locationLabel = MUIOutlet(this, "location-lbl", "MUILabel");
        this.payMethodButton = MUIOutlet(this, "icon-btn", "MUIButton");
        this.payMethodLabel = MUIOutlet(this, "paymethod-lbl", "MUILabel");
        this.infoLabel = MUIOutlet(this, "info-lbl", "MUILabel");
        this.amountLabel = MUIOutlet(this, "amount-lbl", "MUILabel");
        this.workerIcon = MUIOutlet(this, "worker-icon", "MUIView");
        this.actionButton = MUIOutlet(this, "action-btn", "MUIButton");
        
        this.payMethodButton.setAction(this, function(control){
            AppHelper.sharedInstance().showSelectPaymethodViewControllerFromView(null, null, this, this.payMethodDidSelected);
        });

        this.actionButton.setAction(this, function(control){
            this.action();
        });
    }

    private _item:CashDeskLine = null;
    set item(item:CashDeskLine){
        this._item = item;

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        let tf:MIODateFormatter = ad.timeFormatter;
        let cf:MIONumberFormatter = ad.currencyFormatter;

        this.dateLabel.text = tf.stringFromDate(item.date);
        this.typeLabel.text = item.typeString();
        this.conceptLabel.text = item.concept;
        this.documentIDLabel.text = item.legalDocumentID != null ? item.legalDocumentID : item.documentID;
		this.amountLabel.text =  cf.stringFromNumber(item.amount);
		//this.infoLabel.text = item.info;

        if (item.locationCategoryName != null && item.locationName != null) 
            this.locationLabel.text = item.locationCategoryName + " " + item.locationName;        
        else if (item.locationCategoryName != null && item.locationName == null)
            this.locationLabel.text = item.locationCategoryName;        
        else if (item.locationCategoryName == null && item.locationName != null)
			this.locationLabel.text = item.locationName;
        else 
			this.locationLabel.text = null;

        this.updatePayMethod();
        this.updatePayMethodSubtype();

        this.workerIcon.layer.setAttribute("title", item.workerName);
	}
	
    private updatePayMethod(){
        this.payMethodLabel.text = null; 
        if (this._item.payMethod != null) {                        
            this.payMethodLabel.text = this._item.payMethodName; 
        }
        else if (this._item.paymentType == 2) {            
            this.payMethodLabel.text = MIOLocalizeString("CUSTOMER ACCOUNT", "CUSTOMER ACCOUNT");
        } 

        let img = this._item.payMethod?.image?.url;
        if (img != null) {
            this.payMethodButton.setImageURL( img );
            this.payMethodButton.setHidden(false);
        } else {
            this.payMethodButton.setImageURL(null);
            this.payMethodButton.setHidden(true);
        }
    }

    private updatePayMethodSubtype(){
        
        //TODO: HACK becuase the iOS send the wrong type.
        if (this._item.concept == "Offer") {
            this.infoLabel.text = null;
            return;
        }

        if (this._item.payMethod != null && this._item.payMethod.type == PayMethodType.Redsys) {
            this.infoLabel.text = this._item.payMethod.subtypeStringWithValue(this._item.payMethodSubtype);
        }
        else {
            this.infoLabel.text = this._item.payMethodSubtype;
        }

    }

    public payMethodDidSelected(controller:SelectEntityViewController, paymethod: PayMethod) {
        this._item.payMethod = paymethod;
        this._item.payMethodName = paymethod.name;
        DBHelper.saveMainContext();

        this.updatePayMethod();
    }

    private action(){

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('CASH DESK LINE', "CASH DESK LINE"), MIOLocalizeString("CHOOSE AN ACTION", "CHOOSE AN ACTION"), MUIAlertViewStyle.Default);
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CHANGE PAYMETHOD", "CHANGE PAYMETHOD"), MUIAlertActionStyle.Default, this, function(){
            AppHelper.sharedInstance().showSelectPaymethodViewControllerFromView(null, null, this, this.payMethodDidSelected);
        }));

        if (this._item.type != CashDeskOperationType.Tip) {
            avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("ADD TIP", "ADD TIP"), MUIAlertActionStyle.Default, this, function(){
                this.showAlertTip(true);
            }));    
        }
        else {
            avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("RECTIFY TIP", "RECTIFY TIP"), MUIAlertActionStyle.Default, this, function(){
                this.showAlertTip(false);
            }));    
        }

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));
        
        AppHelper.sharedInstance().presentViewController(avc, true);

    }
    
    private showAlertTip(addTip:boolean){

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ADD TIP", "ADD TIP"), MIOLocalizeString("INPUT THE TIP", "INPUT THE TIP"), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            textField.formatter = ad.currencyFormatter;            
        });
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Default, this, function(){
            let tf = avc.textFields[0];
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            if (addTip == true) {
                this.addTip(ad.currencyFormatter.numberFromString(tf.text));
            }
            else {
                this.rectifyTip(ad.currencyFormatter.numberFromString(tf.text));
            }
        }));
        
        AppHelper.sharedInstance().presentViewController(avc, true);
    }

    private addTip(amount){
        DBHelper.sharedInstance().addTipFromCashDeskLine(this._item, amount);
    }

    private rectifyTip(amount){
        DBHelper.sharedInstance().rectifyTipFromCashDeskLine(this._item, amount);
    }

}