
class ConsumptionProfileListCell extends UITableViewCell
{
    private titleLabel:MUILabel = null;  
    private typeLabel:MUILabel = null;  

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
        this.typeLabel = MUIOutlet(this, "type-lbl", "MUILabel");
    }

    set title (text:string){
        this.titleLabel.text = text;     
    }

    set item (item:Application){
        this.titleLabel.text = item.name;    
        this.typeLabel.text = item.typeString();    
    }
}