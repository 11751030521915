//
// Generated class EmployeePayrollConcept
//

/// <reference path="EmployeePayrollConcept_ManagedObject.ts" />

class EmployeePayrollConcept extends EmployeePayrollConcept_ManagedObject
{

}
