//
// Generated class PaymentIntallmentTemplate
//

/// <reference path="PaymentIntallmentTemplate_ManagedObject.ts" />

class PaymentIntallmentTemplate extends PaymentIntallmentTemplate_ManagedObject
{

}
