

/// <reference path="LegalEntity.ts" />

// Generated class Supplier_ManagedObject

class Supplier_ManagedObject extends LegalEntity
{

    // Property: emailOrders
    set emailOrders(value:string) {
        this.setValueForKey(value, 'emailOrders');
    }
    get emailOrders():string {
        return this.valueForKey('emailOrders');
    }
    set emailOrdersPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'emailOrders');
    }
    get emailOrdersPrimitiveValue():string {
        return this.primitiveValueForKey('emailOrders');
    }

    // Property: expenditureLegderAccountName
    set expenditureLegderAccountName(value:string) {
        this.setValueForKey(value, 'expenditureLegderAccountName');
    }
    get expenditureLegderAccountName():string {
        return this.valueForKey('expenditureLegderAccountName');
    }
    set expenditureLegderAccountNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'expenditureLegderAccountName');
    }
    get expenditureLegderAccountNamePrimitiveValue():string {
        return this.primitiveValueForKey('expenditureLegderAccountName');
    }

    // Property: onlineURL
    set onlineURL(value:string) {
        this.setValueForKey(value, 'onlineURL');
    }
    get onlineURL():string {
        return this.valueForKey('onlineURL');
    }
    set onlineURLPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'onlineURL');
    }
    get onlineURLPrimitiveValue():string {
        return this.primitiveValueForKey('onlineURL');
    }

    // Property: onlySupplierProducts
    set onlySupplierProducts(value:boolean) {
        this.setValueForKey(value, 'onlySupplierProducts');
    }
    get onlySupplierProducts():boolean {
        return this.valueForKey('onlySupplierProducts');
    }
    set onlySupplierProductsPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'onlySupplierProducts');
    }
    get onlySupplierProductsPrimitiveValue():boolean {
        return this.primitiveValueForKey('onlySupplierProducts');
    }
    // Relationship: expenditureLegderAccount
    set expenditureLegderAccount(value:LedgerAccount) {
        this.setValueForKey(value, 'expenditureLegderAccount');
    }
    get expenditureLegderAccount():LedgerAccount {
        return this.valueForKey('expenditureLegderAccount') as LedgerAccount;
    }

    // Relationship: supplierProducts
    protected _supplierProducts:MIOManagedObjectSet = null;
    get supplierProducts():MIOManagedObjectSet {
        return this.valueForKey('supplierProducts');
    }
    addSupplierProductsObject(value:SupplierProduct) {
        this._addObjectForKey(value, 'supplierProducts');
    }
    removeSupplierProductsObject(value:SupplierProduct) {
        this._removeObjectForKey(value, 'supplierProducts');
    }
}
