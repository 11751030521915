/**
 * Created by godshadow on 20/07/16.
 */

class BookingZonesViewController extends EntityListViewController
{
    public booking:Booking = null;

    private backButton:MUIButton = null;
    private titleLabel:MUILabel = null;

   // private tableView:MUITableView = null;

 //   private _fetchedResultsController:MIOFetchedResultsController = null;

    selectedMainZones = {};
    public delegate = null;
    
    get preferredContentSize(){
       return new MIOSize(320, 400);
    }    

    viewDidLoad(){
        super.viewDidLoad();

        //this.activityIndicatorView = MUIOutlet(this, 'tv_activity-indicator','MUIActivityIndicatorView');

        this.backButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.backButton.setAction(this, function () {
            this.dismissViewController(true);
        });

        this.titleLabel = MUIOutlet(this, "title_lbl", "MUILabel");
        this.titleLabel.setText(MIOLocalizeString('ZONES',"Zonas"));

        this.tableView = MUIOutlet(this, "tableview", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(){
        super.viewWillAppear();
        this.tableView.reloadData();
    }

    numberOfSections(tableview:UITableView){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview:UITableView, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell:BookingZoneCell = null;

        let z = this.fetchedResultsController.objectAtIndexPath(indexPath);

        if (z.parent == null) cell = tableview.dequeueReusableCellWithIdentifier("ZoneCell") as BookingZoneCell;
        else cell = tableview.dequeueReusableCellWithIdentifier("SubZoneCell") as BookingZoneCell;

        cell.zone = z;
        if (this.booking != null) {
            // let zones = this.booking.relationZones;
            // let exists = zones.filterWithPredicate(MIOPredicate.predicateWithFormat('zoneID = "' + z.identifier +'"'));

            // if (exists.length > 0) {
            //     cell.setAccessoryType(MUITableViewCellAccessoryType.Checkmark);
            //     this.selectedMainZones[z.identifier] = indexPath.row;
            // }
            // else
            // {
            //     cell.setAccessoryType(MUITableViewCellAccessoryType.None);
            // }
        }

        return cell;
    }

    canSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        let z = this.fetchedResultsController.objectAtIndexPath(indexPath);

        if (z.parent == null)
            return false;

        return true;
    }

    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        let z:BookingZone = this.fetchedResultsController.objectAtIndexPath(indexPath);
        // let zones = this.booking.relationZones;

        // let exists = zones.filterWithPredicate(MIOPredicate.predicateWithFormat('zoneID = "' + z.identifier +'"'));
        // if(exists.length == 0 && this.delegate != null && typeof this.delegate.addZone === "function")        
        // {
        //     this.delegate.addZone(z);
        //     this.dismissViewController(true);
        // }
    }

    get entityName(){
        return 'BookingZone';
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController()
    {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        var ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('indexPathString', true)];
   
        var fetchRequest = DBHelper.listFetchRequestWithEntityName(this.entityName, sortDescriptors, null);
    
        var fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;

        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

}
