//
// Generated class PlaceConfiguration
//

/// <reference path="PlaceConfiguration_ManagedObject.ts" />

class PlaceConfiguration extends PlaceConfiguration_ManagedObject
{

}
