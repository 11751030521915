

class ChangelogViewController extends BaseTableViewController {

    static newInstance() : ChangelogViewController {
        let vc = new ChangelogViewController("changelog-view");
        vc.initWithResource("layout/changelog/ChangelogView.html");
        return vc;
    }

    private filterButton:MUIButton = null;
    private fromDateTextField:MUITextField = null;
    private fromDateCalendarButton:MUIButton = null;
    private toDateTextField:MUITextField = null;
    private toDateCalendarButton:MUIButton = null;
    private entitySelectButton:MUIButton = null;
    private entityIDTextField:MUITextField = null;
    private deltasTextField:MUITextField = null;

    viewDidLoad() {
        super.viewDidLoad();

        this.filterButton = MUIOutlet(this, "filter-btn", "MUIButton");  
        this.filterButton.setAction(this, function(this, value){
            this.invalidateData();
        });

        this.fromDateTextField = MUIOutlet(this, "from-date-tf", "MUITextField");
        this.fromDateCalendarButton = MUIOutlet(this, "from-date-calendar-btn", "MUIButton");
        this.fromDateCalendarButton.setAction(this, function(control, value){
            this.showDatePickerController(this.fromDateCalendarButton);
        });

        this.toDateTextField = MUIOutlet(this, "to-date-tf", "MUITextField");
        this.toDateCalendarButton = MUIOutlet(this, "to-date-calendar-btn", "MUIButton");
        this.toDateCalendarButton.setAction(this, function(control, value){
            this.showDatePickerController(this.toDateCalendarButton);
        });

        this.entitySelectButton = MUIOutlet(this, "entity-type-dd", "MUIButton");  
        this.entitySelectButton.setAction(this, function(this, value){
            // filter
        });

        this.entityIDTextField = MUIOutlet(this, "entity-id-tf", "MUITextField");
        this.entityIDTextField.setOnChangeText(this, function(control, value:string){
            // filter
        });

        this.deltasTextField = MUIOutlet(this, "deltas-tf", "MUITextField");
        this.deltasTextField.setOnChangeText(this, function(control, value:string){
            // filter
        });

        this.tableView = MUIOutlet(this,"table-view","UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        
    }

    cellAtIndexPath(tableView: UITableView, indexPath: MIOIndexPath) {
        let cell = tableView.dequeueReusableCellWithIdentifier("ChangelogCell") as ChangelogCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Changelog;
        cell.item = item;
        return cell;
    }

    get fetchedResultsController(): MIOFetchedResultsController {
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
        let sortDescriptors = null; //[ MIOSortDescriptor.sortDescriptorWithKey('createdAt', true)];
        let predicateFormat = null;

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Changelog", sortDescriptors, predicateFormat);
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    predicateDidChange(controller:ChangelogViewController){
        this.invalidateFetch();
    }

    private showDatePickerController(calendarButton:MUIButton){
        let dp = new MUIDatePickerController();
        dp.init();        
        dp.delegate = this;
        dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

        let pc = dp.popoverPresentationController;
        pc.sourceRect = calendarButton.frame; 
        pc.sourceView = calendarButton;

        AppHelper.sharedInstance().presentViewController(dp, true);
    }

    didSelectDate(datePickerController, date:Date){

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let selectedDate = ad.dateFormatter.stringFromDate(date);

        if (datePickerController.popoverPresentationController.sourceView == this.toDateCalendarButton) {
            this.toDateTextField.text = selectedDate;
        } else {
            this.fromDateTextField.text = selectedDate;
        }


        // this.valueTextField.text = v;        
        // this._filter.valueString = v;       
        
        // if (this._filter.fieldType == FilterFieldType.Date && this._filter.defaultTimeValue != null) {
        //     this._filter.value = "'" + this.serverFormatter.stringForObjectValue(date) + " " + this._filter.defaultTimeValue + "'";
        // }
        // else {
        //     this._filter.value = "'" + this.serverFormatter.stringForObjectValue(date) + "'";
        // }                
    }

}