//
// Generated class ManagerStockAnnotation
//

/// <reference path="ManagerStockAnnotation_ManagedObject.ts" />

class ManagerStockAnnotation extends ManagerStockAnnotation_ManagedObject
{

}
