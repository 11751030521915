

class StockElaborationTypeViewController extends MUIViewController
{    
    delegate = null;

    private noElaborateButton:MUIButton = null;
    private beforeSaleButton:MUIButton = null;
    private atSaleButton:MUIButton = null;

    get preferredContentSize(){
        return new MIOSize(500, 500);
    }

    viewDidLoad(){
        super.viewDidLoad();

        this.noElaborateButton = MUIOutlet(this, "product-no-elaborate-btn", "MUIButton");
        this.noElaborateButton.tag = StockElaborationType.None;
        this.noElaborateButton.setAction(this, this.optionDidSelected);

        this.beforeSaleButton = MUIOutlet(this, "product-elaborate-before-sell-btn", "MUIButton");
        this.beforeSaleButton.tag = StockElaborationType.BeforeSale;
        this.beforeSaleButton.setAction(this, this.optionDidSelected);

        this.atSaleButton = MUIOutlet(this, "product-elaborate-at-sell-btn", "MUIButton");
        this.atSaleButton.tag = StockElaborationType.AtSale;
        this.atSaleButton.setAction(this, this.optionDidSelected);
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();        
    }

    private _product:Product = null;
    set product(value){
        this._product = value;        
        this.updateUI();        
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._product == null) return;
        MUICoreLayerRemoveStyle(this.noElaborateButton.layer, "checked");
        MUICoreLayerRemoveStyle(this.beforeSaleButton.layer, "checked");
        MUICoreLayerRemoveStyle(this.atSaleButton.layer, "checked");

        switch (this._product.stockElaborationType) {
            case StockElaborationType.None:
            MUICoreLayerAddStyle(this.noElaborateButton.layer, "checked");
            break;

            case StockElaborationType.BeforeSale:
            MUICoreLayerAddStyle(this.beforeSaleButton.layer, "checked");
            break;

            case StockElaborationType.AtSale:
            MUICoreLayerAddStyle(this.atSaleButton.layer, "checked");
            break;
        }
    }

    private optionDidSelected(button:MUIButton){
        this._product.stockElaborationType = button.tag;
        this.updateUI();
        if (this.delegate != null) this.delegate.elaborationTypeDidUpdate();
        this.dismissViewController(true);
    }

}