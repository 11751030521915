/**
 * Created by miguel on 23/2/17.
 */


/// <reference path="LapsopayViewController.ts" />

class LapsopayLoginViewController extends MUIViewController
{
    userTextfield = null;
    passTextfield = null;

    loginButton = null;
    loginAIV = null;

    viewDidLoad()
    {
        super.viewDidLoad();

        this.userTextfield = MUIOutlet(this, "lplv_user_box", "MUITextField");
        this.passTextfield = MUIOutlet(this, "lplv_pass_box", "MUITextField");

        this.loginAIV = MUIOutlet(this, "lplv_activity-indicator", "MIOActivityIndicator");
        this.loginAIV.setHidden(true);

        this.loginButton = MUIOutlet(this, "lplv_login", "MUIButton");
        this.loginButton.setAction(this, function () {

            var ws = MUIWebApplication.sharedInstance().delegate.webService;

            //TEMP
            if(this.userTextfield.text=='')
                this.userTextfield.text = 'lapsopay@dual-link.com';
            if(this.passTextfield.text == '')
                this.passTextfield.text = 'Lapsopay2017';

            ws.lapsopayLogin(this.userTextfield.text, this.passTextfield.text, this, function(error, result) {

                this.loginAIV.setHidden(true);
                this.loginButton.setEnabled(true);

                if (result == true) {

                    //LOGIN OK
                    console.log('login action OK');
                    
                    var dv = new LapsopayViewController("lapso_pay_tabbar_view");
                    dv.initWithResource("layout/external/lapsopay/LapsoPayTabbarView.html");

                    this.navigationController.pushViewController(dv);
                }
            });
        });
    }
}

