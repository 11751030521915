

/// <reference path="SalesInvoiceLine.ts" />

// Generated class LicenseInvoiceLine_ManagedObject

class LicenseInvoiceLine_ManagedObject extends SalesInvoiceLine
{

    // Property: distributorFeeAmount
    set distributorFeeAmount(value:number) {
        this.setValueForKey(value, 'distributorFeeAmount');
    }
    get distributorFeeAmount():number {
        return this.valueForKey('distributorFeeAmount');
    }
    set distributorFeeAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'distributorFeeAmount');
    }
    get distributorFeeAmountPrimitiveValue():number {
        return this.primitiveValueForKey('distributorFeeAmount');
    }

    // Relationship: tokens
    protected _tokens:MIOManagedObjectSet = null;
    get tokens():MIOManagedObjectSet {
        return this.valueForKey('tokens');
    }
    addTokensObject(value:LicenseToken) {
        this._addObjectForKey(value, 'tokens');
    }
    removeTokensObject(value:LicenseToken) {
        this._removeObjectForKey(value, 'tokens');
    }
}
