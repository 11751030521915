class DBInputNoteLinesCoder extends DBXLSXCoder
{
    stockNoteID:string = null;

    protected mainEntity(){
        return { entity: "CustomInputNoteLine", predicateFormat: "deletedAt == null and note.identifier = " + this.stockNoteID, relations: ["product", "product.stockCategory"] }
    }

    protected columns() : any[] {    
        let cols = [
            { title: "DOCUMENT ID", width: "10%", align: "center" },
            { title: "NAME", width: "10%", align: "center" },
            { title: "QUANTITY", width: "10%", align: "center", formatter: this.ad.numberFormatter },
            { title: "TOTAL", width: "10%", align: "center", formatter: this.ad.currencyFormatter },
        ]
        return cols;
    }

    // protected importRow(row:any){
        
    //     let formatID        = row["FORMAT ID"];
    //     let formatName      = row["FORMAT NAME"] as string;
    //     let invitationValue = row["INVITATION VALUE"];     

    //     if (formatName == null || formatName?.length > 0) return;

    //     let format = this.queryEntityByIDOrName("Format", formatID, formatName) as Format;
    //     if (formatID?.length > 0 && format == null){
    //         format = MIOEntityDescription.insertNewObjectForEntityForName("Format", DBHelper.mainManagedObjectContext) as Format;
    //         format.identifier = this.parseOrCreateIdentifier(formatID);
    //         format.name = formatName;  
    //         this.appendObject(format);
    //     }
        
    //     format.invitationValue = invitationValue;

    //     MIOLog("ADDING FORMAT: " + this.rowIndex + "/" + this.rows.length + ": " + formatName);
    // }

    // 
    // Export
    // 
    protected exportTitle(): string { return "input note lines"; }
    protected exportSortDescriptors() { 
        return [ 
            MIOSortDescriptor.sortDescriptorWithKey('product.stockCategory.name', true), 
            MIOSortDescriptor.sortDescriptorWithKey('product.name', true)
        ]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let line = object as CustomInputNoteLine;

        let item = {
            "DOCUMENT ID" : line.note.documentID,
            "NAME"        : line.product.name,
            "QUANTITY"    : line.quantity,
            "TOTAL"       : line.total 
        }

        return item;
    }
}