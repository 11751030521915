
class WineCellarConfigCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;  
    private typeLabel:MUILabel = null;
    private attributeTypeLabel:MUILabel = null;
    private infoButton:MUIButton = null;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.typeLabel = MUIOutlet(this, "type-lbl", "MUILabel");
        this.attributeTypeLabel = MUIOutlet(this, "attribute-type-lbl", "MUILabel");
        this.infoButton = MUIOutlet(this, "info-btn", "MUIButton");
        this.infoButton.setAction(this, this.showWineCellarInfo);
    }
    
    private _wineCellar:WineCellar = null;
    set item (item:WineCellar){
        if (item == null) return;

        this._wineCellar = item;
        this.nameLabel.text = item.name;
        this.typeLabel.text = WineCellarType[item.type];


        let predicate = MIOPredicate.predicateWithFormat("identifier = " + item.productAttributeTypeID);
        DBHelper.queryObjectWithCompletion("ProductAttributeType", null, predicate, [], this, function(object){
            if (object == null) return;
            this.attributeTypeLabel.text = object.name;
        });
        
    }

    private showWineCellarInfo(){
        let vc = WineCellarConfigDetailViewController.newInstance();
        vc.item = this._wineCellar;
        // vc.modalPresentationStyle = MUIModalPresentationStyle.Popover;
        // let pc = vc.popoverPresentationController;
        // pc.sourceRect = this.infoButton.frame;
        // pc.sourceView = this.infoButton;
    
        AppHelper.sharedInstance().presentViewController(vc, true);
    }

    
}