

class CreateAccountViewController extends MUIViewController
{
    static newInstance() : CreateAccountViewController {
        let vc = new CreateAccountViewController('create-account-view');
        vc.initWithResource("layout/createaccount/CreateAccountView.html");            
        return vc;
    }

    private cancelButton = null;
    private createButton = null;

    private nameTextField = null;
    private emailTextField = null;
    private passwordTextField = null;
    private confirmationTextField = null;

    viewDidLoad()
    {
        super.viewDidLoad();

        this.nameTextField = MUIOutlet(this, "name-tf", 'MUITextField');
        this.emailTextField = MUIOutlet(this, "email-tf", 'MUITextField');
        this.passwordTextField = MUIOutlet(this, "pass-tf", 'MUITextField');
        this.confirmationTextField = MUIOutlet(this, 'pass-confirmation-tf', 'MUITextField');

        this.cancelButton = MUIOutlet(this, 'cancel-btn','MUIButton');
        this.cancelButton.setAction(this, function(this:CreateAccountViewController){
            this.navigationController.popViewController(true);
        });

        this.createButton = MUIOutlet(this, 'create-btn', 'MUIButton');
        this.createButton.setAction(this, function(this:CreateAccountViewController) {            
            
            let auth = DualLinkAuthKit.sharedInstance();
            
            auth.signin(this.nameTextField.text, this.emailTextField.text, this.passwordTextField.text, this.confirmationTextField.text, this, function(this:CreateAccountViewController, error, result, data) {
                if(result)
                    this.showAccountOKViewController();
                else {
                    AppHelper.showErrorMessage(this, MIOLocalizeString("ACCOUNT CREATION", "ACCOUNT CREATION"), MIOLocalizeString("THERE WAS AN ERROR CREATING ACCOUNT","THERE WAS AN ERROR CREATING ACCOUNT"));
                }
            });
        });
    }

    private showAccountOKViewController() {        
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ACCOUNT CREATION", "ACCOUNT CREATION"), MIOLocalizeString("THE ACCOUNT WAS CREATED SUCCESSFULLY", "THE ACCOUNT WAS CREATED SUCCESSFULLY"), MUIAlertViewStyle.Default);
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, this, function(this:CreateAccountViewController){
            this.navigationController.popViewController(true);
        }));
        this.presentViewController(avc, true);
    }
}