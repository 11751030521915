//
// Generated class DBDocumentTitleLine
//

/// <reference path="DBDocumentTitleLine_ManagedObject.ts" />

class DBDocumentTitleLine extends DBDocumentTitleLine_ManagedObject
{

}
