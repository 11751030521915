//
// Generated class ClientPaymentIntallment
//

/// <reference path="ClientPaymentIntallment_ManagedObject.ts" />

class ClientPaymentIntallment extends ClientPaymentIntallment_ManagedObject
{

}
