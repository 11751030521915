//
// Generated class DBFilter
//

/// <reference path="DBFilter_ManagedObject.ts" />

enum DBFilterDataType{
    Boolean,
    String,
    Integer,
    Decimal,
    Date,
    Time,
    DateTime
}

enum DBFilterType{
    Optional,
    Required
}
class DBFilter extends DBFilter_ManagedObject
{

}
