class OnlineClosedDaysViewController extends MUIViewController
{        
    private backButton:MUIButton = null;
    private titleLabel:MUILabel = null;
    private addButton:MUIButton = null;
    private tableView:UITableView = null;


    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");
        this.backButton.setAction(this, function(control){
            this.navigationController.popViewController(true);
        });

        this.titleLabel = MUIOutlet(this, "section-title-lbl", "MUILabel");

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function(){
            alert("Add button");
        });

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

    }

    viewWillAppear(animate?) {
        super.viewWillAppear(animate);        
        this.updateUI();
    }

    private _sectionTitle:string = null;
    set sectionTitle (item:string){
        this._sectionTitle = item;  
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        this.titleLabel.text = this._sectionTitle;

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    titleForHeaderInSection(tableView:UITableView, section:number) {}
    
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){        

        let cell = tableView.dequeueReusableCellWithIdentifier("OnlineClosedDaysCell") as OnlineClosedDaysCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as any;   //change any to the proper type                   
        cell.item = item;
        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){      
        //cell selection
    }

    private _fetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('OnlinePreset');    
        //fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('section', true)];
        fetchRequest.predicate = MIOPredicate.predicateWithFormat("deletedAt == null");
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }

}