

enum CodeGenerateViewControllerType {
    PromotionalCode,
    BookingPassCode
}
class CodeGenerateViewController extends MUIViewController
{
    delegate = null;

    private _beginDateTextField = null;
    private _endDateTextField  = null;
    private _quantityTextField = null;
    private _generateButton    = null;
    private closeButton:MUIButton = null;
    
    private _item = null;
    private _type = null;
    
    
    private df = MUIWebApplication.sharedInstance().delegate.dateFormatter;
    private sdf = MUIWebApplication.sharedInstance().delegate.serverDateTimeFormatter;
    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;

    get preferredContentSize()
    {
        return new MIOSize(300, 300);
    }
    
    viewDidLoad()
    {
        super.viewDidLoad();
        
        this.closeButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.closeButton.setAction(this, function(){
            this.dismissViewController(true);
        });

        this._beginDateTextField = MUIOutlet(this, 'cgv_tv_star_date_text','MUITextField');
        this._beginDateTextField.formatter = this.df;
        
        this._endDateTextField = MUIOutlet(this, 'cgv_tv_end_date_text','MUITextField');
        this._endDateTextField.formatter = this.df;
        
        this._quantityTextField = MUIOutlet(this, 'cgv_tv_quantity_text','MUITextField');
        this._quantityTextField.formatter = this.nf;

        this._generateButton = MUIOutlet(this, 'cgv_generate_btn','MUIButton');
        this._generateButton.setAction(this, function()
        {
            if(this._item == null) return;

            switch(this._type){
                case CodeGenerateViewControllerType.PromotionalCode:
                    this._generatePromotionalCodes();
                    break;
                case CodeGenerateViewControllerType.BookingPassCode:
                    this._generateBookingsPassCodes();
                    break;
                default:
                    break;    
            }
        });

    }

    viewWillAppear(animate?)
    {
        this._beginDateTextField.text = null;
        this._endDateTextField.text = null;
        this._quantityTextField.text = null;
    }
    
    set type(value)
    {
        this.willChangeValue("type");
        this._type = value;
        this.didChangeValue("type");
    }
    set item(value)
    { 
        this._item = value;
    }

    private _generatePromotionalCodes()
    {
        let ad = MUIWebApplication.sharedInstance().delegate;
        let ws = ad.webService;
        let begindate = this.sdf.dateFromString(this._beginDateTextField.text.trim());
        let enddate = this.sdf.dateFromString(this._endDateTextField.text.trim());
        let quantity = this.nf.numberFromString(this._quantityTextField.text.trim());

        if(!begindate || !enddate || !quantity)
        {
                const avc = new MUIAlertViewController();
                avc.initWithTitle(MIOLocalizeString('ERROR', 'Error'), MIOLocalizeString('CHECK DATES AND QUANTITY','Check dates and quantity'), MUIAlertViewStyle.Default);
                avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, this, function(){}));
                
                this.presentViewController(avc, true);
        }
        else
        {
            ws.generatePromotionalCodesByOffer(ad.selectedPlace, this._item, begindate, enddate, quantity, this, function(code, json)
            {
                if(code == 200){
                    if(this.delegate != null)
                        this.delegate.itemsDidGenerated();
                    
                    this.dismissViewController(true);    
                }
                else 
                {
                    const avc = new MUIAlertViewController();
                    avc.initWithTitle(MIOLocalizeString('ERROR', 'Error'), json['error'], MUIAlertViewStyle.Default);
                    avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, this, function(){}));
                
                    this.presentViewController(avc, true);
                }
            });
        }
    }

    private _generateBookingsPassCodes()
    {
        let ad = MUIWebApplication.sharedInstance().delegate;
        let ws = ad.webService;

        let begindate = this.sdf.stringFromDate(this.df.dateFromString(this._beginDateTextField.text.trim()));
        let enddate = this.sdf.stringFromDate(this.df.dateFromString(this._endDateTextField.text.trim()));
        let quantity = this.nf.numberFromString(this._quantityTextField.text.trim());

        if(!begindate || !enddate || !quantity)
        {
                const avc = new MUIAlertViewController();
                avc.initWithTitle(MIOLocalizeString('ERROR', 'Error'), MIOLocalizeString('CHECK DATES AND QUANTITY','Check dates and quantity'), MUIAlertViewStyle.Default);
                avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, this, function(){}));
                
                this.presentViewController(avc, true);
        }
        else
        {
            ws.generateBkTicketsForEvent(ad.selectedPlace, this._item, begindate, enddate, quantity, this, function(code, json)
            {
                if(code == 200){
                    if(this.delegate != null)
                        this.delegate.itemsDidGenerated();
                    
                    this.dismissViewController(true);    
                }
                else {
                    const avc = new MUIAlertViewController();
                    avc.initWithTitle(MIOLocalizeString('ERROR', 'Error'), json['error'], MUIAlertViewStyle.Default);
                    avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, this, function(){}));
                    
                    this.presentViewController(avc, true);
                }
            });
        }
    }

    private  EANChecksum(value) {
        let checksum = 0;
        value = value.split('').reverse();
        for(let pos in value){
            checksum += value[pos] * (3 - 2 * (parseInt(pos) % 2));
        }
        return ((10 - (checksum % 10 )) % 10);
    }
}
