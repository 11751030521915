

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class SupportSession_ManagedObject

class SupportSession_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: closeNotes
    set closeNotes(value:string) {
        this.setValueForKey(value, 'closeNotes');
    }
    get closeNotes():string {
        return this.valueForKey('closeNotes');
    }
    set closeNotesPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'closeNotes');
    }
    get closeNotesPrimitiveValue():string {
        return this.primitiveValueForKey('closeNotes');
    }

    // Property: isClosed
    set isClosed(value:boolean) {
        this.setValueForKey(value, 'isClosed');
    }
    get isClosed():boolean {
        return this.valueForKey('isClosed');
    }
    set isClosedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isClosed');
    }
    get isClosedPrimitiveValue():boolean {
        return this.primitiveValueForKey('isClosed');
    }

    // Relationship: files
    protected _files:MIOManagedObjectSet = null;
    get files():MIOManagedObjectSet {
        return this.valueForKey('files');
    }
    addFilesObject(value:SupportFile) {
        this._addObjectForKey(value, 'files');
    }
    removeFilesObject(value:SupportFile) {
        this._removeObjectForKey(value, 'files');
    }

    // Relationship: messages
    protected _messages:MIOManagedObjectSet = null;
    get messages():MIOManagedObjectSet {
        return this.valueForKey('messages');
    }
    addMessagesObject(value:SupportChatMessage) {
        this._addObjectForKey(value, 'messages');
    }
    removeMessagesObject(value:SupportChatMessage) {
        this._removeObjectForKey(value, 'messages');
    }

    // Relationship: streams
    protected _streams:MIOManagedObjectSet = null;
    get streams():MIOManagedObjectSet {
        return this.valueForKey('streams');
    }
    addStreamsObject(value:SupportStream) {
        this._addObjectForKey(value, 'streams');
    }
    removeStreamsObject(value:SupportStream) {
        this._removeObjectForKey(value, 'streams');
    }
    // Relationship: supportTicket
    set supportTicket(value:SupportTicket) {
        this.setValueForKey(value, 'supportTicket');
    }
    get supportTicket():SupportTicket {
        return this.valueForKey('supportTicket') as SupportTicket;
    }
}
