//
// Generated class PrintProfileConfiguration
//

/// <reference path="PrintProfileConfiguration_ManagedObject.ts" />

class PrintProfileConfiguration extends PrintProfileConfiguration_ManagedObject
{

}
