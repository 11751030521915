

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ProductWarehouse_ManagedObject

class ProductWarehouse_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: isDefault
    set isDefault(value:boolean) {
        this.setValueForKey(value, 'isDefault');
    }
    get isDefault():boolean {
        return this.valueForKey('isDefault');
    }
    set isDefaultPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isDefault');
    }
    get isDefaultPrimitiveValue():boolean {
        return this.primitiveValueForKey('isDefault');
    }

    // Property: maxQuantity
    set maxQuantity(value:number) {
        this.setValueForKey(value, 'maxQuantity');
    }
    get maxQuantity():number {
        return this.valueForKey('maxQuantity');
    }
    set maxQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'maxQuantity');
    }
    get maxQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('maxQuantity');
    }

    // Property: measureUnit
    set measureUnit(value:number) {
        this.setValueForKey(value, 'measureUnit');
    }
    get measureUnit():number {
        return this.valueForKey('measureUnit');
    }
    set measureUnitPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'measureUnit');
    }
    get measureUnitPrimitiveValue():number {
        return this.primitiveValueForKey('measureUnit');
    }

    // Property: minQuantity
    set minQuantity(value:number) {
        this.setValueForKey(value, 'minQuantity');
    }
    get minQuantity():number {
        return this.valueForKey('minQuantity');
    }
    set minQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minQuantity');
    }
    get minQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('minQuantity');
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
    // Relationship: warehouse
    set warehouse(value:Warehouse) {
        this.setValueForKey(value, 'warehouse');
    }
    get warehouse():Warehouse {
        return this.valueForKey('warehouse') as Warehouse;
    }
}
