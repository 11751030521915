
class AnalyticAccountCell extends UITableViewCell
{
    private nameTextField:MUITextField = null;
    private prefixTextField:MUITextField  = null;
    private entityDropDown:MUIButton = null;

    private pnf = MUIWebApplication.sharedInstance().delegate.percentNumberFormatter as MIONumberFormatter;
    
    awakeFromHTML(){
        this.nameTextField = MUIOutlet(this, 'name-tf', 'MUITextField');
        this.nameTextField.setOnChangeText(this, function(textfield:UITextField, value){
            this._ledgerAccount.name = value.trim();
        });

        this.prefixTextField = MUIOutlet(this, 'prefix-tf', 'MUITextField');
        this.prefixTextField.setOnChangeText(this, function(textfield, value){
            this._ledgerAccount.prefix = value.trim();
        });

        this.entityDropDown = MUIOutlet(this, 'entity-dd', 'MUIButton');
        this.entityDropDown.setAction(this, function(){
            //search for entity
        });
    }
    
    private _ledgerAccount:LedgerAccount = null;
    set item(i:LedgerAccount){
        this._ledgerAccount = i;
        this.nameTextField.text = i.name;
        this.prefixTextField.text = i.prefix;
        // Entity dropdown
    }
}
