//
// Generated class FormatName
//

/// <reference path="FormatName_ManagedObject.ts" />

class FormatName extends FormatName_ManagedObject
{

}
