
class WhatsappCell extends UITableViewCell
{
    private typeTextField:MUITextField = null;
    private keyTextField:MUITextField = null;
    private statusTextField:MUITextField = null;
    private languageTextField:MUITextField = null;
    private templateTextField:MUITextField = null;
    private urlTextField:MUITextField = null;
    private examineButton:MUIButton = null;
    private activeButton:MUISwitchButton = null;
    private moreButton:MUIButton = null;    

    awakeFromHTML(){
        this.typeTextField = AppHelper.setupDataTextField(this, "type-tf", (value:string) => { this.template.type = value} );
        this.keyTextField = AppHelper.setupDataTextField(this, "key-tf", (value:string) => { this.template.key = value} );
        this.statusTextField = AppHelper.setupDataTextField(this, "status-tf", (value:string) => { this.template.status = parseInt(value) } );
        this.languageTextField = AppHelper.setupDataTextField(this, "language-tf", (value:string) => { this.template.language = value} );
        this.templateTextField = AppHelper.setupDataTextField(this, "template-tf", (value:string) => { this.template.templateName = value} );
        // this.urlTextField = AppHelper.setupDataTextField(this, "url-tf", (value:string) => { this.template.url = value} );
        this.examineButton = MUIOutlet(this, "folder-btn", "MUIButton");
        this.activeButton = MUIOutlet(this, "active-sw", "MUISwitchButton");        
        this.activeButton.setOnChangeValue(this, function(this:EmailCell, control:MUISwitchButton, value:boolean) { this.template.active = value } );

        this.moreButton = MUIOutlet(this, "more-btn", "MUIButton");
        this.moreButton.setAction(this, this.moreButtonAction);

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private template:WhatsappTemplate = null;
    set item(item:WhatsappTemplate){
        this.template = item;

        this.typeTextField.text = item.type;
        this.keyTextField.text = item.key;
        this.statusTextField.text = item.status;
        this.languageTextField.text = item.language;
        this.templateTextField.text = item.templateName;
        this.activeButton.on = item.active;
    }

    private moreButtonAction(){
        let vc = WhatsappTemplateParamsViewController.newInstance();
        vc.template = this.template;
        AppHelper.sharedInstance().presentViewController(vc, true);
    }
}