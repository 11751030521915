
class EditConsumptionProfileDetailCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;  
    private dropdownButton:MUIButton = null;

    private _ellaborationCenter:EllaborationCenter = null;
    private _ellaborationConsumptionWarehouse:EllaborationConsumptionWarehouse = null;
    private _warehouse:Warehouse = null;
    private _consumptionProfile:ConsumptionProfile = null;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.dropdownButton = this.setupDropdownButton("dropdown-btn");       
    }
    
    set ellaborationCenter (item:EllaborationCenter){
        if (item == null) return;

        this._ellaborationCenter = item;
        this.nameLabel.text = this._ellaborationCenter.name;
    }

    set consumptionProfile (item:ConsumptionProfile){
        if (item == null) return;

        this._consumptionProfile = item;
    }
    
    set ellaborationConsumptionWarehouse (item:EllaborationConsumptionWarehouse){
        if (item == null) return;

        this._ellaborationConsumptionWarehouse = item;
        if (item.warehouse) {
            this._warehouse = item.warehouse;
            this.dropdownButton.title = this._warehouse.name;
        }
    }

    private setupDropdownButton(outlet:string): MUIButton {
        let button = MUIOutlet(this, outlet, "MUIButton");
        if (button == null || button == undefined) return null;

        button.setAction(this, function() {
            this.showSelectEntityViewController();
        }); 
        
        return button;
    }

    private showSelectEntityViewController() {

        let predicateFormat = null;
        let vc = AppHelper.sharedInstance().showCustomSelectViewControllerFromView(this.dropdownButton, "Warehouse", "name", predicateFormat, this, function(controller, object:Warehouse){
            
            if (this._warehouse != object){
                this._warehouse = object;
                this.dropdownButton.title = this._warehouse.name;

                let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

                // if old exists destroy it first
                if (this._ellaborationConsumptionWarehouse) {
                    moc.deleteObject(this._ellaborationConsumptionWarehouse);
                }

                //create EllaborationConsumptionWarehouse entity
                let newEllaborationConsumptionWarehouse = MIOEntityDescription.insertNewObjectForEntityForName("EllaborationConsumptionWarehouse", moc) as EllaborationConsumptionWarehouse;
                newEllaborationConsumptionWarehouse.consumptionProfile = this._consumptionProfile;
                newEllaborationConsumptionWarehouse.ellaborationCenter = this._ellaborationCenter;
                newEllaborationConsumptionWarehouse.warehouse = this._warehouse;

                moc.save();

                this._ellaborationConsumptionWarehouse = newEllaborationConsumptionWarehouse;

            }

            //create EllaborationConsumptionWarehouse entity for this._item and selected Warehouse
            //this.moc.save();

            // let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
            // let newEllaborationConsumptionWarehouse = MIOEntityDescription.insertNewObjectForEntityForName("EllaborationConsumptionWarehouse", moc) as EllaborationConsumptionWarehouse;
            // newEllaborationConsumptionWarehouse.consumptionProfile = 
            // newEllaborationConsumptionWarehouse.ellaborationCenter = 
            // newEllaborationConsumptionWarehouse.warehouse = 

            // moc.save();
            // this.updateUI();
        });        
    
    }
}