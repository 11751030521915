

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class RoomGuest_ManagedObject

class RoomGuest_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: creditLimit
    set creditLimit(value:number) {
        this.setValueForKey(value, 'creditLimit');
    }
    get creditLimit():number {
        return this.valueForKey('creditLimit');
    }
    set creditLimitPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'creditLimit');
    }
    get creditLimitPrimitiveValue():number {
        return this.primitiveValueForKey('creditLimit');
    }

    // Property: hasCredit
    set hasCredit(value:boolean) {
        this.setValueForKey(value, 'hasCredit');
    }
    get hasCredit():boolean {
        return this.valueForKey('hasCredit');
    }
    set hasCreditPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'hasCredit');
    }
    get hasCreditPrimitiveValue():boolean {
        return this.primitiveValueForKey('hasCredit');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: referenceID
    set referenceID(value:string) {
        this.setValueForKey(value, 'referenceID');
    }
    get referenceID():string {
        return this.valueForKey('referenceID');
    }
    set referenceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'referenceID');
    }
    get referenceIDPrimitiveValue():string {
        return this.primitiveValueForKey('referenceID');
    }

    // Property: token
    set token(value:string) {
        this.setValueForKey(value, 'token');
    }
    get token():string {
        return this.valueForKey('token');
    }
    set tokenPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'token');
    }
    get tokenPrimitiveValue():string {
        return this.primitiveValueForKey('token');
    }
    // Relationship: roomBooking
    set roomBooking(value:RoomBooking) {
        this.setValueForKey(value, 'roomBooking');
    }
    get roomBooking():RoomBooking {
        return this.valueForKey('roomBooking') as RoomBooking;
    }
}
