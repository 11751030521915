
class CalendarMultipleCell extends MUICollectionViewCell
{
    value = null;
    
    private titleLabel:MUILabel = null;        

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
    }

    set title(value){
        this.titleLabel.text = value;
    }

    setSelected(value){
        super.setSelected(value);
        if (value == true){
            MUICoreLayerAddStyle(this.layer, "selected");
        }
        else {
            MUICoreLayerRemoveStyle(this.layer, "selected");
        }
    }
}
