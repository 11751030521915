

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Dashboard_ManagedObject

class Dashboard_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Relationship: items
    protected _items:MIOManagedObjectSet = null;
    get items():MIOManagedObjectSet {
        return this.valueForKey('items');
    }
    addItemsObject(value:DashBoardItem) {
        this._addObjectForKey(value, 'items');
    }
    removeItemsObject(value:DashBoardItem) {
        this._removeObjectForKey(value, 'items');
    }
}
