
class ProductRatesDetailCell extends UITableViewCell
{
    private imageView:MUIImageView = null;
    private nameLabel:MUILabel = null;
    private priceLabel:MUILabel = null;
    private taxButton:MUIButton = null;
    private specificRateTextfield:MUITextField = null;
    private formatButton:MUIButton = null;

    private timer:MIOTimer = null;

    private ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
    private cf = this.ad.currencyFormatter as MIONumberFormatter;
    private nf = this.ad.numberFormatter as MIONumberFormatter;

    awakeFromHTML()
    {
        this.imageView = MUIOutlet(this, "product-img", "MUIImageView");
        this.nameLabel = MUIOutlet(this, "rate-name-lbl", "MUILabel");
        this.priceLabel = MUIOutlet(this, "base-price-lbl", "MUILabel");

        this.taxButton = MUIOutlet(this, 'tax-btn', 'MUIButton');
        this.taxButton.setAction(this, function (){            
            this.showSelectTaxViewController(this.taxButton);
        });

        this.specificRateTextfield = MUIOutlet(this, "specific-rate-tf", "MUITextField");
        this.specificRateTextfield.formatter = this.cf;
        this.specificRateTextfield.setOnChangeText(this, function(control, value){
            if (this.timer != null) this.timer.invalidate();
            this.timer = MIOTimer.scheduledTimerWithTimeInterval(600, false, this, function(timer:MIOTimer){
                this.timer = null;

                value = value.length > 0 ? value : null;
                this.updateSpecificRate(value);
            });
        });

        this.formatButton = MUIOutlet(this, "product-format-btn", "MUIButton");
        this.formatButton.setAction(this, function(){

            let vc = new ProductFormatRatesViewController("product-format-rates-view");            
            vc.initWithResource("layout/productrates/ProductFormatRatesView.html");
            vc.delegate = this;
            vc.modalPresentationStyle = MUIModalPresentationStyle.Popover;
            vc.rate = this._rate as Rate;
            vc.item = this._product as Product;

            let pc = vc.popoverPresentationController;
            pc.sourceRect = this.formatButton.frame;
            pc.sourceView = this.formatButton;

            AppHelper.sharedInstance().showSelectViewControllerFromView(this.formatButton, vc, this, null);
        });    
    }

    private _rate:Rate = null;
    set rate(r:Rate){
        this._rate= r;
    }

    private _product:Product = null;
    set product(i:Product){
        this._product = i;
        this.imageView.setImage(i.image?.url);
        this.nameLabel.text = i.name;
        this.priceLabel.text = this.cf.stringFromNumber(i.price);
        this.formatButton.hidden = (i.productFormats?.length > 0) ? false : true;
        if(i.productRates?.allObjects.length > 0) this.loadProductRate(i);
    }

    private _productRate:ProductRate = null;
    set productRate(i:ProductRate){
        this._productRate = i;
        if(i.price) this.specificRateTextfield.text = this.cf.stringFromNumber(i.price);
        if(i.tax) this.taxButton.title = i.tax.name; 
    }

    private showSelectTaxViewController(button:MUIButton) {
        let vc = AppHelper.sharedInstance().showCustomSelectViewControllerFromView(button, 'Tax', 'name', null, this, function(controller, object:Tax){
            //returns object, test value then update Tax

            button.title = (object) ? object.valueForKey('name') : MIOLocalizeString("TAX RATE","TAX RATE");
            this.updateTax(object);

        }, true );        
    }

    private loadProductRate(item:Product) {
        if (this._productRate != null) return;

        //compares all related RATES of object in current cell to the currently selected RATE
        for (let i = 0; i < item.productRates.allObjects.length; i++) {
            if (item.productRates.allObjects[i].rate?.identifier == this._rate.identifier){
                this.productRate = item.productRates.allObjects[i];
                return; //when you find a match return, there should ONLY ever be one match
            }
        }
    }

    private updateSpecificRate(value:string){
        if (this._productRate){
            this._productRate.price = this.cf.numberFromString(value);
            this.testProductRateForDelete();
        } else {
            if (value){
                this._productRate = MIOEntityDescription.insertNewObjectForEntityForName("ProductRate", this.ad.managedObjectContext) as ProductRate;
                this._productRate.identifier = new MIOUUID().UUIDString;
                this._productRate.product = this._product;
                this._productRate.rate = this._rate;
                this._productRate.price = this.cf.numberFromString(value);
                this.ad.managedObjectContext.save();
            }
        }
    }

    private updateTax(value:Tax) {
        if (this._productRate){
            this._productRate.tax = value;
            this.testProductRateForDelete();
        } else {
            if(value){
                this._productRate = MIOEntityDescription.insertNewObjectForEntityForName("ProductRate", this.ad.managedObjectContext) as ProductRate;
                this._productRate.identifier = new MIOUUID().UUIDString;
                this._productRate.product = this._product;
                this._productRate.rate = this._rate;
                this._productRate.tax = value;
                this.ad.managedObjectContext.save();
            }
        }
    }

    private testProductRateForDelete(){
        if(!this._productRate?.price && !this._productRate?.tax){
            DBHelper.deleteObjectFromMainContext(this._productRate, true);
            this._productRate = null;
        }
    }

}