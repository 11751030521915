

/// <reference path="RuleKit.ts" />

// Generated class TimeRangeGroup_ManagedObject

class TimeRangeGroup_ManagedObject extends RuleKit
{

    // Property: duration
    set duration(value:number) {
        this.setValueForKey(value, 'duration');
    }
    get duration():number {
        return this.valueForKey('duration');
    }
    set durationPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'duration');
    }
    get durationPrimitiveValue():number {
        return this.primitiveValueForKey('duration');
    }

    // Property: endHour
    set endHour(value:string) {
        this.setValueForKey(value, 'endHour');
    }
    get endHour():string {
        return this.valueForKey('endHour');
    }
    set endHourPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'endHour');
    }
    get endHourPrimitiveValue():string {
        return this.primitiveValueForKey('endHour');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: startHour
    set startHour(value:string) {
        this.setValueForKey(value, 'startHour');
    }
    get startHour():string {
        return this.valueForKey('startHour');
    }
    set startHourPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'startHour');
    }
    get startHourPrimitiveValue():string {
        return this.primitiveValueForKey('startHour');
    }

    // Property: title
    set title(value:string) {
        this.setValueForKey(value, 'title');
    }
    get title():string {
        return this.valueForKey('title');
    }
    set titlePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'title');
    }
    get titlePrimitiveValue():string {
        return this.primitiveValueForKey('title');
    }

    // Relationship: timeRangePresets
    protected _timeRangePresets:MIOManagedObjectSet = null;
    get timeRangePresets():MIOManagedObjectSet {
        return this.valueForKey('timeRangePresets');
    }
    addTimeRangePresetsObject(value:TimeRangePreset) {
        this._addObjectForKey(value, 'timeRangePresets');
    }
    removeTimeRangePresetsObject(value:TimeRangePreset) {
        this._removeObjectForKey(value, 'timeRangePresets');
    }

    // Relationship: timeRanges
    protected _timeRanges:MIOManagedObjectSet = null;
    get timeRanges():MIOManagedObjectSet {
        return this.valueForKey('timeRanges');
    }
    addTimeRangesObject(value:TimeRange) {
        this._addObjectForKey(value, 'timeRanges');
    }
    removeTimeRangesObject(value:TimeRange) {
        this._removeObjectForKey(value, 'timeRanges');
    }
}
