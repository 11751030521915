//
// Generated class Entity
//

/// <reference path="Entity_ManagedObject.ts" />

class Entity extends Entity_ManagedObject
{

}
