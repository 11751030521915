

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class FileURL_ManagedObject

class FileURL_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: url
    set url(value:string) {
        this.setValueForKey(value, 'url');
    }
    get url():string {
        return this.valueForKey('url');
    }
    set urlPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'url');
    }
    get urlPrimitiveValue():string {
        return this.primitiveValueForKey('url');
    }
}
