

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class User_ManagedObject

class User_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: accessControl
    set accessControl(value:string) {
        this.setValueForKey(value, 'accessControl');
    }
    get accessControl():string {
        return this.valueForKey('accessControl');
    }
    set accessControlPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'accessControl');
    }
    get accessControlPrimitiveValue():string {
        return this.primitiveValueForKey('accessControl');
    }

    // Property: email
    set email(value:string) {
        this.setValueForKey(value, 'email');
    }
    get email():string {
        return this.valueForKey('email');
    }
    set emailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'email');
    }
    get emailPrimitiveValue():string {
        return this.primitiveValueForKey('email');
    }

    // Property: isAdmin
    set isAdmin(value:boolean) {
        this.setValueForKey(value, 'isAdmin');
    }
    get isAdmin():boolean {
        return this.valueForKey('isAdmin');
    }
    set isAdminPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isAdmin');
    }
    get isAdminPrimitiveValue():boolean {
        return this.primitiveValueForKey('isAdmin');
    }

    // Property: isBeta
    set isBeta(value:boolean) {
        this.setValueForKey(value, 'isBeta');
    }
    get isBeta():boolean {
        return this.valueForKey('isBeta');
    }
    set isBetaPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isBeta');
    }
    get isBetaPrimitiveValue():boolean {
        return this.primitiveValueForKey('isBeta');
    }

    // Property: isDualLink
    set isDualLink(value:boolean) {
        this.setValueForKey(value, 'isDualLink');
    }
    get isDualLink():boolean {
        return this.valueForKey('isDualLink');
    }
    set isDualLinkPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isDualLink');
    }
    get isDualLinkPrimitiveValue():boolean {
        return this.primitiveValueForKey('isDualLink');
    }

    // Property: isReseller
    set isReseller(value:boolean) {
        this.setValueForKey(value, 'isReseller');
    }
    get isReseller():boolean {
        return this.valueForKey('isReseller');
    }
    set isResellerPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isReseller');
    }
    get isResellerPrimitiveValue():boolean {
        return this.primitiveValueForKey('isReseller');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: permisions
    set permisions(value:string) {
        this.setValueForKey(value, 'permisions');
    }
    get permisions():string {
        return this.valueForKey('permisions');
    }
    set permisionsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'permisions');
    }
    get permisionsPrimitiveValue():string {
        return this.primitiveValueForKey('permisions');
    }
    // Relationship: dashboard
    set dashboard(value:Dashboard) {
        this.setValueForKey(value, 'dashboard');
    }
    get dashboard():Dashboard {
        return this.valueForKey('dashboard') as Dashboard;
    }

    // Relationship: reportAccess
    protected _reportAccess:MIOManagedObjectSet = null;
    get reportAccess():MIOManagedObjectSet {
        return this.valueForKey('reportAccess');
    }
    addReportAccessObject(value:UserReportAccess) {
        this._addObjectForKey(value, 'reportAccess');
    }
    removeReportAccessObject(value:UserReportAccess) {
        this._removeObjectForKey(value, 'reportAccess');
    }

    // Relationship: reportOptions
    protected _reportOptions:MIOManagedObjectSet = null;
    get reportOptions():MIOManagedObjectSet {
        return this.valueForKey('reportOptions');
    }
    addReportOptionsObject(value:UserReportOption) {
        this._addObjectForKey(value, 'reportOptions');
    }
    removeReportOptionsObject(value:UserReportOption) {
        this._removeObjectForKey(value, 'reportOptions');
    }
}
