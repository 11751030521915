//
// Generated class PartyImage
//

/// <reference path="PartyImage_ManagedObject.ts" />

class PartyImage extends PartyImage_ManagedObject
{
    removeFromMOC(save:boolean){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.managedObjectContext.deleteObject(this);
        if (save == true) ad.managedObjectContext.save();
    }
}
