class BookingDetailCell extends UITableViewCell
{
    delegate = null;
    
    private statusBar:MUIView = null;
    private dateTextField:MUITextField = null;
    private dateButton:MUIButton = null;
    private hourComboBox:MUIComboBox = null;
    private businessAreaComboBox: MUIComboBox = null;
    private paxTextField:MUITextField = null
    private channelComboBox: MUIComboBox = null;
    private recommendationDropdown: MUIButton = null;
    private durationComboBox:MUIComboBox = null;
    private statusComboBox:MUIComboBox = null;
    private zonesDropdown:MUIButton = null;
    private locationView:TagView = null;
    private calculatePriceLabel:MUILabel = null;
    private priceTextField:MUITextField = null;
    private calculateMinSpendLabel:MUILabel = null;
    private minSpendTextField:MUITextField = null;
    private amountToPayTextField:MUITextField = null;
    private totalAmountToPayLabel:MUILabel = null;
    private addDepositButton:MUIButton = null;
    private depositLabel:MUILabel = null;
    private customerCommentsArea:MUITextArea = null;
    private hallCommentsArea:MUITextArea = null;
    private internalCommentsArea:MUITextArea = null;
    private allergensCommentsArea:MUITextArea = null;

    private addBookingButton:MUIButton = null;

    private modifiersTableView:UITableView = null;
    
    private dateFormatter = MUIWebApplication.sharedInstance().delegate.dateFormatter as MIODateFormatter;
    private integerFormatter = MUIWebApplication.sharedInstance().delegate.integerFormatter as MIONumberFormatter;
    private currencyFormatter = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;

    awakeFromHTML(){
        this.statusBar = MUIOutlet(this, "status-bar", "MUIView");

        this.dateTextField = MUIOutlet(this, "date-tf", "MUITextField");
        this.dateButton = MUIOutlet(this, "date-btn", "MUIButton");
        this.setupDateButton(this.dateButton);

        this.hourComboBox = MUIOutlet(this, "hour-cb", "MUIComboBox");
        this.setupHourComboBox(this.hourComboBox);
        this.hourComboBox.setOnChangeAction(this, function(this:BookingDetailCell, control:MUIComboBox, value:string){
            this.booking.hour = value;
            this.updateBookingDate();
        });
        
        this.durationComboBox = MUIOutlet(this, "duration-cb", "MUIComboBox");
        this.paxTextField = MUIOutlet(this, "pax-tf", "MUITextField");
        this.paxTextField.setOnChangeText(this, function(control, value){
            this.booking.pax = this.integerFormatter.numberFromString(value);
            // this.updatePrices();
            this.calculatePrices();
        });


        this.channelComboBox = MUIOutlet(this, 'channel-cb','MUIComboBox');
        this.setupChannelComboBox(this.channelComboBox);
        this.channelComboBox.setOnChangeAction(this, function(this:BookingDetailCell, control:MUIComboBox, value:string){
            let channel = DBHelper.queryObjectFromMainContext("BookingChannel", MIOPredicate.predicateWithFormat("identifier == " + value));
            this.booking.channelID = channel.identifier;
            this.booking.channelName = channel.name;
        });

        this.recommendationDropdown = MUIOutlet(this, 'recommendation-cb','MUIButton'); 
        // this.setupRecommendationComboBox(this.recommendationComboBox);
        this.recommendationDropdown.setAction(this, function(this:BookingDetailCell){
            AppHelper.sharedInstance().showSelectBookingRecommendationViewControllerFromView(this.recommendationDropdown, this.booking.recommendation, this, function(controller, recommendation:BookingRecommendation){
                this.booking.recommendation = recommendation;
                this.booking.recommendationName = recommendation?.name;    
            });                        
        });


        this.statusComboBox = MUIOutlet(this, "status-cb", "MUIComboBox");
        AppHelper.sharedInstance().setupBookingStatusComboBox(this.statusComboBox);
        this.statusComboBox.setOnChangeAction(this, function(control:MUIComboBox, value:string){
            this.booking.status = this.integerFormatter.numberFromString(value);
            this.updateStatusBar();
        });

        this.zonesDropdown = MUIOutlet(this, "zones-dd", "MUIButton");
        this.setupZonesDropDown(this.zonesDropdown);
        this.addDepositButton = MUIOutlet(this, "add-deposit-btn", "MUIButton");
        this.addDepositButton.setAction(this, function(this:BookingDetailCell){
            this.showAddDepositAlert();
        });

        this.locationView = MUIOutlet(this, "tag-view", "TagView");

        this.businessAreaComboBox = MUIOutlet(this, "bussiness-area-cb", "MUIComboBox");
        this.setupBusinessAreaComboBox(this.businessAreaComboBox);
        this.businessAreaComboBox.setOnChangeAction(this, function(this:BookingDetailCell, control:MUIComboBox, value:string){
            let ba = DBHelper.queryObjectFromMainContext("BusinessArea", MIOPredicate.predicateWithFormat("identifier = " + value));
            this.booking.businessAreaID = ba.identifier;
        });

        this.calculatePriceLabel = MUIOutlet(this, "calculated-price-lbl", "MUILabel");
        this.priceTextField = MUIOutlet(this, "price-tf", "MUITextField");
        this.priceTextField.setOnChangeText(this, function(control, value:string){
            if (value.length > 0) {
                this.booking.price = this.currencyFormatter.numberFromString(value);
            }
            else this.booking.price = null;
            this.updateTotals();
        });

        this.calculateMinSpendLabel = MUIOutlet(this, "calculated-min-spend-lbl", "MUILabel");
        this.minSpendTextField = MUIOutlet(this, "min-spend-tf", "MUITextField");
        this.minSpendTextField.setOnChangeText(this, function(control, value:string){
            if (value.length > 0) {
                this.booking.minSpend = this.currencyFormatter.numberFromString(value);
            }
            else this.booking.minSpend = null;
            this.updateTotals();
        });

        this.amountToPayTextField = MUIOutlet(this, "amount-to-pay-tf", "MUITextField");
        this.amountToPayTextField.setOnChangeText(this, function(this:BookingDetailCell, control, value:string){
            this.booking.amountToPay = value.length > 0 ? value : null;                        
            this.updateTotals();
        });
        
        this.totalAmountToPayLabel = MUIOutlet(this, "total-lbl", "MUILabel");
        this.depositLabel = MUIOutlet(this, "deposit-lbl", "MUILabel");
        
        this.customerCommentsArea = MUIOutlet(this, "customer-comments-ta", "MUITextArea");
        this.customerCommentsArea.setOnChangeText(this, function(control, value){
            this.booking.clientComments = value;
        });

        this.hallCommentsArea = MUIOutlet(this, "hall-comments-ta", "MUITextArea");
        this.hallCommentsArea.setOnChangeText(this, function(control, value){
            this.booking.comments = value;
        });

        this.internalCommentsArea = MUIOutlet(this, "internal-comments-ta", "MUITextArea");
        this.internalCommentsArea.setOnChangeText(this, function(control, value){
            this.booking.privateComments = value;
        });
        
        this.allergensCommentsArea = MUIOutlet(this, "allergens-comments-ta", "MUITextArea");
        this.allergensCommentsArea.setOnChangeText(this, function(control, value){
            this.booking.allergens = value;
        });

        this.modifiersTableView = MUIOutlet(this, "modifiers-table-view", "UITableView");
        this.modifiersTableView.dataSource = this;   
        
        this.addBookingButton = MUIOutlet(this, "add-booking-btn", "MUIButton");
        this.addBookingButton.setAction(this, function(){
            this.addBookingAction();                        
		});
		
		this.selectionStyle = UITableViewCellSelectionStyle.None;

        // Only fill in creation
        let sds = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        let predicate = MIOPredicate.predicateWithFormat("deletedAt == null");
        DBHelper.queryObjectsWithCompletion("BookingChannel", sds, predicate, [], this, function(this:BookingDetailCell, objects){
            let c = objects.filter (x => x.identifier == this.booking.channelID );
            this.fillBookingChannelComboBox(this.channelComboBox, objects, c.length > 0 ? c[0] : null);            
        });  

    }

    private booking:Booking = null;
    set item(item:Booking){
        this.booking = item;        

        this.dateTextField.text = this.dateFormatter.stringFromDate(item.date);                                                               

        if(item.hour != null){
            let h = item.hour.substr(0,5);
            this.hourComboBox.selectItem(h);
        }
        else     
            this.hourComboBox.selectItem(-1);

        this.businessAreaComboBox.selectItem(item.businessAreaID)
        this.durationComboBox.selectItem(item.duration);
        this.paxTextField.text = item.pax;
        this.statusComboBox.selectItem(item.status);

        this.zonesDropdown.title = item.bookingZoneName ? item.bookingZoneName : MIOLocalizeString("SELECT ZONE", "SELECT ZONE");
        this.reloadModifiersTableView();
                
        this.calculatePriceLabel.text = this.calculatedPriceString();
        this.priceTextField.text = this.priceString();
        this.calculateMinSpendLabel.text = this.calculatedMinimunSpendString();        
        this.minSpendTextField.text = this.minimunSpendString();        
        this.amountToPayTextField.text = this.amountToPayString();
        this.totalAmountToPayLabel.text = this.totalAmountToPayString();
        this.depositLabel.text = this.depositString();        
        
        this.customerCommentsArea.text = item.clientComments;
        this.hallCommentsArea.text = item.comments;
        this.internalCommentsArea.text = item.privateComments;
        this.allergensCommentsArea.text = item.allergens;

        if (this.booking.channelID != null) this.channelComboBox.selectItem(this.booking.channelID);

        if (this.booking.recommendationName != null) this.recommendationDropdown.title = this.booking.recommendationName;

        this.locationView.removeAll();
        for (let index = 0; index < this.booking.locations.length; index++){
            let bl = this.booking.locations.objectAtIndex(index) as Booking_BookingLocation;
            this.locationView.addTag(bl.locationCategoryName + " " + bl.locationName);
        }                

        this.updateStatusBar();
    }

    private updateStatusBar(){
        let [r,g,b] = Booking.colorForStatus(this.booking.status);
        this.statusBar.setBackgroundRGBColor( r, g, b);
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){                
        let cell:BookingModifierCell = null;

        let modifier = this.fetchedResultsController.objectAtIndexPath(indexPath) as BookingModifier;
        if (modifier.bookingModifierCategory.incremental == false){
            cell = tableview.dequeueReusableCellWithIdentifier("ModifierCell") as BookingModifierCell;
        }
        else {
            cell = tableview.dequeueReusableCellWithIdentifier("IncrementalModifierCell") as BookingModifierCell;
        }                
        cell.item = modifier;

        return cell;
    }

    protected _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value:MIOFetchedResultsController){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("bookingModifierCategory.name", true), MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        let predicateFormat = "ANY bookingModifierCategory.bookingZones.bookingZoneID == " + this.booking.bookingZoneID;
            
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("BookingModifier", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["bookingModifierCategory.bookingZones"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "bookingModifierCategory.name");
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        this.modifiersTableView.reloadData();
    }

    private reloadModifiersTableView(){
        if (this.booking.bookingZoneID == null) return;

        this.fetchedResultsController = null;
        this.modifiersTableView.reloadData();
    }

    private setupDateButton(button:MUIButton){
        if (button == null) return;
        button.setAction(this, function(){
            let dp = new MUIDatePickerController();
            dp.init();        
            dp.delegate = this;
            dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;
    
            let pc = dp.popoverPresentationController;
            pc.sourceRect = button.frame;
            pc.sourceView = button;
    
            AppHelper.sharedInstance().presentViewController(dp, true);    
        });
    }

    didSelectDate(datePickerController:MUIDatePickerController, date:Date){                
        this.dateTextField.text = this.dateFormatter.stringFromDate(date);            
        this.booking.date = date;  
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this.booking.day = ad.serverDateFormatter.stringFromDate(date);
        this.calculatePrices();
        this.updateBookingDate();
    }

    private setupHourComboBox(comboBox:MUIComboBox){
        comboBox.removeAllItems();
        comboBox.addItem(MIOLocalizeString('SELECT HOUR', 'Select Hour...'), -1); 
        
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('nextDay', true), MIOSortDescriptor.sortDescriptorWithKey('hour', true)];                                
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("TimeRange", sortDescriptors, "showRange == true");
        let objects = ad.managedObjectContext.executeFetch(fetchRequest);

        for (let index = 0; index < objects.length; index++){
            let tr = objects[index] as TimeRange;
            comboBox.addItem(tr.name, tr.hour);
            
        }
    }

    private setupBusinessAreaComboBox(comboBox:MUIComboBox){
        comboBox.removeAllItems();
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("BusinessArea", sortDescriptors, "deletedAt = null");
        let objects = ad.managedObjectContext.executeFetch(fetchRequest);

        let dba = AppHelper.sharedInstance().defaultBusinessArea;
        comboBox.addItem(dba.name, dba.identifier);
        for (let index = 0; index < objects.length; index++){
            let ba = objects[index] as BusinessArea;
            if (ba.identifier == dba.identifier) continue;
            comboBox.addItem(ba.name, ba.identifier);
        }

        if (this.booking?.businessAreaID != null) comboBox.selectItem (this.booking.businessAreaID);
    }

    private setupZonesDropDown(dropdown:MUIButton){
        if (dropdown == null) return;

        dropdown.setAction(this, function(){
            let vc = AppHelper.sharedInstance().selectViewController("Zone", "name", false, false, null, this, true);
            AppHelper.sharedInstance().presentViewController(vc, true);    
        });
    }

    fetchRequestForController(controller:SelectEntityViewController):MIOFetchRequest{
        if (controller.identifier == "Zone"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];            
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("BookingZone", sd, null);
            let parentZone = controller.context ? controller.context["ParentZone"] : null;
            if (parentZone == null) fetchRequest.predicate = MIOPredicate.predicateWithFormat("deletedAt = null and parent == null");
            else fetchRequest.predicate = MIOPredicate.predicateWithFormat("deletedAt = null and parent.identifier == " + parentZone.identifier);            

            return fetchRequest;
        }
    }

    didSelectObjectFromSelectViewController(controller:SelectEntityViewController, item:MIOManagedObject){
        let dismiss = true;

        if (controller.identifier == "Zone"){
            let zone = item as BookingZone;
            if (zone.subZones.count > 0) {                
                let vc = AppHelper.sharedInstance().selectViewController("Zone", "name", false, false, {"ParentZone": zone}, this, true);
                controller.pushNextViewController(vc);
                dismiss = false;
            }
            else {
                this.zonesDropdown.title = zone.name;
                this.addZone(zone);
            }
        }

        return dismiss;
    }

    private addZone(zone:BookingZone){        
        this.booking.bookingZoneID = zone.identifier;
        this.booking.bookingZoneName = zone.name;

        DBHelper.saveMainContext();        
        
        this.reloadModifiersTableView();

        this.calculatePrices();
    }

    private showAddDepositAlert(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle('Add Funds', 'Add or remove funds', MUIAlertViewStyle.Default);
        
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("0");
        });

        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
            DBHelper.queryObjectsWithCompletion("PayMethod", null, MIOPredicate.predicateWithFormat("deletedAt == null"), [], this, function(objects){
                AppHelper.fillPayMethodComboBox(comboBox, objects, null);
            });
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle('OK', MUIAlertActionStyle.Default, this, function(){
            let quantity = avc.textFields[0].text;
            let cb = avc.comboBoxes[0] as MUIComboBox;
            let pmid = cb.getSelectedItem();
            let pm = DBHelper.queryObjectFromMainContext("PayMethod", MIOPredicate.predicateWithFormat("identifier == " + pmid), []) as PayMethod;            
            this.addDeposit(pm, quantity);            
        }));
        
        avc.addAction(MUIAlertAction.alertActionWithTitle('Cancel', MUIAlertActionStyle.Cancel, null,null));
        
        AppHelper.sharedInstance().presentViewController(avc, true);
    }

    private addDeposit(payMethod:PayMethod, quantity:string){
        
        if (payMethod == null) {
            AppHelper.showErrorMessage(null, "NO PAY METHOD", "YOU MUST SELECT A PAY METHOD");
            return;
        }

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let money = ad.currencyFormatter.numberFromString(quantity);

        if (money == 0) {
            AppHelper.showErrorMessage(null, "QUANTITY IS ZERO", "THE QUANTITY CAN NOT BE ZERO");
            return;
        }
        
        let dh = DBHelper.sharedInstance();
        const line_type = money > 0 ? AccountLineType.AddFunds : AccountLineType.RemoveFunds;
        dh.createEconomicLine(money, payMethod, this.booking.client, line_type, this.booking);

        // TODO: Calculate balance always not cache the amount in the db
        this.booking.deposit = this.booking.deposit == null ? money : this.booking.deposit + money;
        this.depositLabel.text = this.depositString();
        DBHelper.saveMainContext();
    }

    private priceAmountToPay = null;
    private minAmountToPay = null;
    private amountToPay = null;
    private updatePrices(){        
        // this.calculatePrices();
        
        // this.booking.calculatedPrice = 0;
        // this.booking.calculatedMinimumSpend = 0;

        // if (this.booking.bookingZoneID != null) {
        //     let zone = DBCacheHelper.sharedInstance().objectForEntityNameWithPredicate("BookingZone", MIOPredicate.predicateWithFormat("identifier == " + this.booking.bookingZoneID));
        //     for (let index = 0; index < zone.priceRules.count; index++){
        //         let pr = zone.priceRules.objectAtIndex(index) as BookingPriceRule;

        //         if (pr.minPax != null && this.booking.pax < pr.minPax) continue;
        //         if (pr.maxPax != null && this.booking.pax > pr.maxPax) continue;
                                
        //         if (pr.priceType == BookingPriceRuleType.ByPax) this.booking.calculatedPrice = (pr.price || 0) * this.booking.pax;                
        //         else this.booking.calculatedPrice = (pr.price || 0);
        //         this.priceAmountToPay = pr.priceAmountToPay;
                
        //         if (pr.minSpendType == null) this.booking.calculatedMinimumSpend = (pr.minSpend || 0) * this.booking.pax;
        //         else if (pr.minSpendType == BookingPriceRuleType.ByPax) this.booking.calculatedMinimumSpend = (pr.minSpend || 0) * this.booking.pax;                
        //         else if (pr.minSpendType == BookingPriceRuleType.ByTable) this.booking.calculatedMinimumSpend = (pr.minSpend || 0);
        //         this.minAmountToPay = pr.minSpendAmountToPay;

        //         //if (this.booking.ammountToPay != null) this.amountToPay = this.booking.ammountToPay;
        //         //this.amountToPay = null;

        //         break;
        //     }
        // }

        // this.calculatePriceLabel.text = this.calculatedPriceString();
        // this.calculateMinSpendLabel.text = this.calculatedMinimunSpendString();        
        // this.amountToPayTextField.text = this.booking.amountToPay;
        // this.updateTotals();        
    }

    private calculatedPriceString(){
        if (this.booking.calculatedPrice == null) return null;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        return ad.currencyFormatter.stringFromNumber(this.booking.calculatedPrice);        
    }

    private priceString(){
        if (this.booking.price == null) return null;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        return ad.currencyFormatter.stringFromNumber(this.booking.price);        
    }

    private calculatedMinimunSpendString(){
        if (this.booking.calculatedMinimumSpend == null) return null;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        return ad.currencyFormatter.stringFromNumber(this.booking.calculatedMinimumSpend);        
    }

    private minimunSpendString(){
        if (this.booking.minSpend == null) return null;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        return ad.currencyFormatter.stringFromNumber(this.booking.minSpend);        
    }

    private amountToPayString(){
        if (this.booking.amountToPay == null) return null;
        
        return this.booking.amountToPay;
        // let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        // return ad.currencyFormatter.stringFromNumber(this.booking.ammountToPay);        
    }

    private totalAmountToPayString(){
        if (this.booking.totalAmountToPay == null) return null;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        return ad.currencyFormatter.stringFromNumber(this.booking.totalAmountToPay);        
    }

    private depositString(){
        if (this.booking.deposit == null) return null;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        return ad.currencyFormatter.stringFromNumber(this.booking.deposit);        
    }

    private updateTotals(){
        let price = 0;
        if (this.booking.price == null) price = this.booking.calculatedPrice;
        else price = this.booking.price;

        let total = price;
        
        let minSpend = 0;
        if (this.booking.minSpend == null) minSpend = this.booking.calculatedMinimumSpend;
        else minSpend = this.booking.minSpend;

        total += minSpend;

        if (this.booking.amountToPay != null) this.booking.totalAmountToPay = this.calutateAmountFromAmountToPay(total, this.booking.amountToPay);
        else {
            let p = this.calutateAmountFromAmountToPay(price, this.priceAmountToPay);
            let ms = this.calutateAmountFromAmountToPay(minSpend, this.minAmountToPay);
            this.booking.totalAmountToPay = p + ms;
        }

        this.totalAmountToPayLabel.text = this.totalAmountToPayString();
    }
    
    private calutateAmountFromAmountToPay(amount:number, amountToPay:string):number{
        if (amountToPay == null) return amount;

        if (amountToPay.indexOf("%") == -1) {
            // Just the ammount
            return parseFloat(amountToPay);
        }
        else {
            // Calculate the percentage
            let value = amountToPay.replace("%", "");
            let factor = parseFloat(value);

            return (amount * factor) / 100;
        }
    }

    private addBookingAction(){
        let b = DBHelper.createBookingFromBooking(this.booking);
        b.date = this.booking.date;
        b.day = this.booking.day;

        if (this.delegate != null) this.delegate.addNewBooking(b);
    }

    private calculatePrices(){
        if (this.booking.bookingZoneID == null || this.booking.pax < 1) return;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.calculateBookingPrice(this.booking.day, this.booking.pax, this.booking.bookingZoneID, (json, error) => {
            if (error != null) return; 
            
            this.booking.calculatedPrice = json["Price"] || 0;
            if (this.booking.price == null) this.booking.price = this.booking.calculatedPrice;
            this.booking.calculatedMinimumSpend = json["MinSpend"] || 0;
            if (this.booking.minSpend == null) this.booking.minSpend = this.booking.calculatedMinimumSpend;
            this.booking.amountToPay = json["AmountToPay"];
            let total = 0;
            total += this.booking.price != null ? this.booking.price : this.booking.calculatedPrice;
            total += this.booking.minSpend != null ? this.booking.minSpend : this.booking.calculatedMinimumSpend;
            this.booking.totalAmountToPay = this.calutateAmountFromAmountToPay( total, this.booking.amountToPay );

            this.calculatePriceLabel.text = this.calculatedPriceString();
            this.priceTextField.text = this.priceString();
            this.calculateMinSpendLabel.text = this.calculatedMinimunSpendString();        
            this.minSpendTextField.text = this.minimunSpendString();        
            this.amountToPayTextField.text = this.amountToPayString();

            this.updateTotals();
        });
    }

    private setupChannelComboBox(comboBox:MUIComboBox){
        let selectedItem = this.item == null ? null : this.item.channelID;
        DBHelper.sharedInstance().addObserverForEntity(this, "BookingChannel", null, null, null, function(objects){
            this.fillBookingChannelComboBox(this.channelComboBox, objects, selectedItem);
        });
    }

    private fillBookingChannelComboBox(comboBox, objects, selectedChannel){
        comboBox.removeAllItems();
        comboBox.addItem(MIOLocalizeString('NO CHANNEL','NO CHANNEL'), -1);  

        for (let i = 0; i < objects.length; i++) {
            let object = objects[i] as BookingChannel;
            comboBox.addItem(object.name, object.identifier);
        }

        if (selectedChannel != null) comboBox.selectItem(selectedChannel.identifier);
        else comboBox.selectItem("-1");
    }

    private setupRecommendationComboBox(comboBox:MUIComboBox){
        let selectedItem = this.item == null ? null : this.item.recommendation?.identifier;
        DBHelper.sharedInstance().addObserverForEntity(this, "BookingRecommendation", null, [MIOSortDescriptor.sortDescriptorWithKey("name", true)], null, function(objects){
            this.fillBookingRecommendationComboBox(this.recommendationComboBox, objects, selectedItem);
        });
    }

    private fillBookingRecommendationComboBox(comboBox, objects, selectedRecommendation){
        comboBox.removeAllItems();
        comboBox.addItem(MIOLocalizeString('NO RECOMMENDATION','NO RECOMMENDATION'), -1);  

        for (let i = 0; i < objects.length; i++) {
            let object = objects[i] as BookingRecommendation;
            comboBox.addItem(object.name, object.identifier);
        }

        if (selectedRecommendation != null) comboBox.selectItem(selectedRecommendation.identifier);
        else comboBox.selectItem("-1");
    }

    private updateBookingDate(){
        let date_str = this.booking.day + " " + this.booking.hour;
        let dtf = (MUIWebApplication.sharedInstance().delegate as AppDelegate).serverDateTimeFormatter;
        this.booking.date = dtf.dateFromString(date_str);
    }

}