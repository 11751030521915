

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class AdyenPaymentEntityStore_ManagedObject

class AdyenPaymentEntityStore_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: reference
    set reference(value:string) {
        this.setValueForKey(value, 'reference');
    }
    get reference():string {
        return this.valueForKey('reference');
    }
    set referencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'reference');
    }
    get referencePrimitiveValue():string {
        return this.primitiveValueForKey('reference');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: title
    set title(value:string) {
        this.setValueForKey(value, 'title');
    }
    get title():string {
        return this.valueForKey('title');
    }
    set titlePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'title');
    }
    get titlePrimitiveValue():string {
        return this.primitiveValueForKey('title');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }
    // Relationship: paymentEntity
    set paymentEntity(value:AdyenPaymentEntity) {
        this.setValueForKey(value, 'paymentEntity');
    }
    get paymentEntity():AdyenPaymentEntity {
        return this.valueForKey('paymentEntity') as AdyenPaymentEntity;
    }

    // Relationship: paymethods
    protected _paymethods:MIOManagedObjectSet = null;
    get paymethods():MIOManagedObjectSet {
        return this.valueForKey('paymethods');
    }
    addPaymethodsObject(value:AdyenPaymentEntityPaymethod) {
        this._addObjectForKey(value, 'paymethods');
    }
    removePaymethodsObject(value:AdyenPaymentEntityPaymethod) {
        this._removeObjectForKey(value, 'paymethods');
    }
}
