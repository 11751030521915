//
// Generated class StockTemplateCategoryItem
//

/// <reference path="StockTemplateCategoryItem_ManagedObject.ts" />

class StockTemplateCategoryItem extends StockTemplateCategoryItem_ManagedObject
{
    get typeString() {
        return MIOLocalizeString("STOCK_TEMPLATE_CATEGORY_ITEM","STOCK_TEMPLATE_CATEGORY_ITEM");
    }
}
