//
// Generated class AdyenPaymentDevice
//

/// <reference path="AdyenPaymentDevice_ManagedObject.ts" />

class AdyenPaymentDevice extends AdyenPaymentDevice_ManagedObject
{

}
