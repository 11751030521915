/// <reference path="DualLinkDB.ts" />

enum LoanClassnameType
{
    bank = "BankLoan",
    loan = "Loan"
}

interface DLDB
{
    addLoan( type:LoanClassnameType, name:string, amount:number, startDate:Date, endDate:Date, reference:string, gracePeriod:number, bankAccount:BankAccount, bank:Bank ) : Loan ;
    importLoadInstallments( loan:Loan, data:any, completion:any ) : void ;
}

DLDB.prototype.addLoan = function( type:LoanClassnameType, name:string, amount:number, startDate:Date, endDate:Date, reference:string, gracePeriod:number, bankAccount:BankAccount, bank:Bank) : Loan
{
    let moc = (MUIWebApplication.sharedInstance().delegate as AppDelegate).managedObjectContext;
    let loan = MIOEntityDescription.insertNewObjectForEntityForName( type, moc ) as Loan;
    
    loan.name = name;
    loan.reference = reference;
    loan.capitalAmount = amount;
    loan.startDate = startDate;
    loan.endDate = endDate;
    loan.bankAccount = bankAccount;
    if (loan instanceof BankLoan) loan.bank = bank;
    loan.gracePeriod = gracePeriod;
        
    return loan;
}

DLDB.prototype.importLoadInstallments = function( loan:Loan, data:any, completion:any ) : void
{
    
}