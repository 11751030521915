

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ProductWarehouseLocation_ManagedObject

class ProductWarehouseLocation_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: productID
    set productID(value:string) {
        this.setValueForKey(value, 'productID');
    }
    get productID():string {
        return this.valueForKey('productID');
    }
    set productIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productID');
    }
    get productIDPrimitiveValue():string {
        return this.primitiveValueForKey('productID');
    }

    // Property: value
    set value(value:string) {
        this.setValueForKey(value, 'value');
    }
    get value():string {
        return this.valueForKey('value');
    }
    set valuePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'value');
    }
    get valuePrimitiveValue():string {
        return this.primitiveValueForKey('value');
    }

    // Property: warehouseID
    set warehouseID(value:string) {
        this.setValueForKey(value, 'warehouseID');
    }
    get warehouseID():string {
        return this.valueForKey('warehouseID');
    }
    set warehouseIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'warehouseID');
    }
    get warehouseIDPrimitiveValue():string {
        return this.primitiveValueForKey('warehouseID');
    }

    // Property: warehouseLocationID
    set warehouseLocationID(value:string) {
        this.setValueForKey(value, 'warehouseLocationID');
    }
    get warehouseLocationID():string {
        return this.valueForKey('warehouseLocationID');
    }
    set warehouseLocationIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'warehouseLocationID');
    }
    get warehouseLocationIDPrimitiveValue():string {
        return this.primitiveValueForKey('warehouseLocationID');
    }
}
