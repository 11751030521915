//
// Generated class Bank
//

/// <reference path="Bank_ManagedObject.ts" />

class Bank extends Bank_ManagedObject
{

}
