
class SelectDocumentCell extends UITableViewCell
{
    delegate = null;

    private dateLabel:MUILabel = null;
    private documentIDLabel:MUILabel = null;
    private legalNameLabel:MUILabel = null;
    private totalLabel:MUILabel = null;
    private addButton:MUIButton = null;

    awakeFromHTML(){
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");
        this.documentIDLabel = MUIOutlet(this, "document-id-lbl", "MUILabel");
        this.legalNameLabel = MUIOutlet(this, "client-name-lbl", "MUILabel");
        this.totalLabel = MUIOutlet(this, "total-lbl", "MUILabel");
        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        if (this.addButton != null) this.addButton.setAction(this, this.addAction);

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private _item:MIOManagedObject = null;
    set item (item:MIOManagedObject){
        this._item = item;
        
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        if (item instanceof ArchivedTicket) {                    
            if (this.dateLabel != null) this.dateLabel.text = ad.dateTimeFormatter.stringFromDate(item.date);
            this.documentIDLabel.text = item.legalDocumentID;
            if (this.legalNameLabel != null) this.legalNameLabel.text = item.linkedLegalEntityName;
            if (this.totalLabel != null) this.totalLabel.text = ad.currencyFormatter.stringFromNumber(item.totalPrice);
        }
        else if (item instanceof SupplierNote) {
            if (this.dateLabel != null) this.dateLabel.text = ad.dateTimeFormatter.stringFromDate(item.stockDate);
            this.documentIDLabel.text = item.externalReference ?? item.documentID;
            if (this.legalNameLabel != null) this.legalNameLabel.text = item.originName;
            if (this.totalLabel != null) this.totalLabel.text = ad.currencyFormatter.stringFromNumber(item.totalAmount);
        }
        else if (item instanceof SalesInvoice) {
            if (this.dateLabel != null) this.dateLabel.text = ad.dateTimeFormatter.stringFromDate(item.date);
            this.documentIDLabel.text = item.documentNumber;
            if (this.legalNameLabel != null) this.legalNameLabel.text = item.legalEntity.name;
            if (this.totalLabel != null) this.totalLabel.text = ad.currencyFormatter.stringFromNumber(item.totalAmount);
        }
        else if (item instanceof PurchaseInvoice) {
            if (this.dateLabel != null) this.dateLabel.text = ad.dateTimeFormatter.stringFromDate(item.date);
            this.documentIDLabel.text = item.documentNumber;
            if (this.legalNameLabel != null) this.legalNameLabel.text = item.legalEntity.name;
            if (this.totalLabel != null) this.totalLabel.text = ad.currencyFormatter.stringFromNumber(item.totalAmount);
        }
    }
    
    private addAction(){
        if (this.delegate != null) this.delegate.addDocumentFromCell(this, this._item);        
    }

    set selectedItem(value:boolean){
        this.addButton.hidden = value;
        if (value == true){
            if (this.dateLabel != null) MUICoreLayerAddStyle(this.dateLabel.layer, "light");
            if (this.documentIDLabel != null) MUICoreLayerAddStyle(this.documentIDLabel.layer, "light");
            if (this.legalNameLabel != null) MUICoreLayerAddStyle(this.legalNameLabel.layer, "light");
            if (this.totalLabel != null) MUICoreLayerAddStyle(this.totalLabel.layer, "light");
        }
        else {
            if (this.dateLabel != null) MUICoreLayerRemoveStyle(this.dateLabel.layer, "light");
            if (this.documentIDLabel != null) MUICoreLayerRemoveStyle(this.documentIDLabel.layer, "light");
            if (this.legalNameLabel != null) MUICoreLayerRemoveStyle(this.legalNameLabel.layer, "light");
            if (this.totalLabel != null) MUICoreLayerRemoveStyle(this.totalLabel.layer, "light");            
        }        

    }
}