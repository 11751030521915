//
// Generated class SupplierInvoiceLine
//

/// <reference path="SupplierInvoiceLine_ManagedObject.ts" />

class SupplierInvoiceLine extends SupplierInvoiceLine_ManagedObject
{

}
