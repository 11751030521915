

/// <reference path="RuleSet.ts" />

// Generated class RuleResult_ManagedObject

class RuleResult_ManagedObject extends RuleSet
{
    // Relationship: bookingTimeRangePresetConfiguration
    set bookingTimeRangePresetConfiguration(value:BookingTimeRangePresetConfiguration) {
        this.setValueForKey(value, 'bookingTimeRangePresetConfiguration');
    }
    get bookingTimeRangePresetConfiguration():BookingTimeRangePresetConfiguration {
        return this.valueForKey('bookingTimeRangePresetConfiguration') as BookingTimeRangePresetConfiguration;
    }
    // Relationship: bookingZoneConfiguration
    set bookingZoneConfiguration(value:BookingZoneConfiguration) {
        this.setValueForKey(value, 'bookingZoneConfiguration');
    }
    get bookingZoneConfiguration():BookingZoneConfiguration {
        return this.valueForKey('bookingZoneConfiguration') as BookingZoneConfiguration;
    }
    // Relationship: ruleKitOccupationRule
    set ruleKitOccupationRule(value:RuleKit) {
        this.setValueForKey(value, 'ruleKitOccupationRule');
    }
    get ruleKitOccupationRule():RuleKit {
        return this.valueForKey('ruleKitOccupationRule') as RuleKit;
    }
    // Relationship: ruleKitPriceRule
    set ruleKitPriceRule(value:RuleKit) {
        this.setValueForKey(value, 'ruleKitPriceRule');
    }
    get ruleKitPriceRule():RuleKit {
        return this.valueForKey('ruleKitPriceRule') as RuleKit;
    }
}
