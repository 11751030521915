



class StockAllViewController extends MUIViewController
{
    private tableView:MUITableView = null;
    private searchTextField:MUITextField = null;

    private searchString:string = null;
    private _fetchedResultsController:MIOFetchedResultsController = null;

    private _filter = null;
    private _warehouse:Warehouse = null;
    private _withoutExistences:boolean = false;

    private minMaxHeaderLabel:MUILabel = null;

    private stockDetailVC:StockDetailViewController = null;

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    private data = [];

    viewDidLoad()
    {
        super.viewDidLoad();

        
        this.searchTextField = MUIOutlet(this, 'sav_tv_search_bar','MUITextField');
        this.searchTextField.setOnChangeText(this, function(textfield, value)
        {
            clearTimeout(this.delayTimer);
            var instance = this;
            
            instance.delayTimer = setTimeout(function() {
                instance.searchString = instance.searchTextField.text;
                instance.fetchedResultsController = null;
                instance.tableView.reloadData();
            }, 500, instance);
        });
        
        this.tableView = this.tableView = MUIOutlet(this, 'sav_c2_tableview', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;    

        
        

      //  this.downloadData(this._warehouse);
    }

    /*private downloadData(warehouse)
    {
        var ad = MUIWebApplication.sharedInstance().delegate;
        var place = ad.selectedPlace;
        var ws = ad.webService;

        //this.activityIndicator.setHidden(false);
        ws.getStockExistence(place, warehouse, this, function (code, json) {
           if(code == 200) {
            
                this.updateUI();
            }
          //  this.activityIndicator.setHidden(true);
        });
    }*/

    set filter(value)
    {
        this._filter = value;
        this.updateUI();
    }

    set warehouse(value)
    {
        this._warehouse = value;
       // this.downloadData(this._warehouse);
    }

    set withoutExistences(value)
    {
        this._withoutExistences = value;
        this.updateUI();
    }

    private updateUI()
    {
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
        this.updateUI();
    }
    
    viewWillDisappear(animate?){
        super.viewWillDisappear(animate);
        this.fetchedResultsController = null;
        let moc: MIOManagedObjectContext = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        moc.save();
    }

    numberOfSections(tableview)
    {
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section)
    {
        var sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath)
    {
        var cell:WarehouseProductCell = tableview.dequeueReusableCellWithIdentifier('WarehouseProductCell');
    
        var item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        
        cell.warehouse = this._warehouse ? this._warehouse.identifier : null;

        cell.item = item;
    
        //cell.delegate = this;

        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
        cell.selectionStyle = MUITableViewCellSelectionStyle.None;
    
        return cell;
    }
    
    
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath)
    {
    }
    
    set fetchedResultsController(value)
    {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController()
    {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
        ad.managedObjectContext.removeAllObjectsForEntityName("StockAnnotationView");
        ad.managedObjectContext.save();
        
        let filter = [];
        
        if(this._warehouse == null) //TODO: Temporal hasta queda filtrar por null
            filter.push('warehouse.identifier = null');
        else if (this._warehouse != null)
            filter.push('warehouse.identifier = ' + this._warehouse.identifier);

        if(this._withoutExistences == true)
        {
            filter.push('totalQuantity <= 0');
        }
        
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('StockAnnotationView');  
        //fetchRequest.relationshipKeyPathsForPrefetching = ['product'];
     
        fetchRequest.sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('name', true)];
        
        if (this.searchString != null && this.searchString.length > 0)
                filter.push('name CONTAINS "' + this.searchString + '"');       

        if(filter.length >0)    {         
            fetchRequest.predicate = MIOPredicate.predicateWithFormat(filter.join(' and '));
        }
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
  
    controllerDidChangeContent(controller)
    {
        this.tableView.reloadData();
    }

}
