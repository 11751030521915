//
// Generated class LegalEntity
//

/// <reference path="LegalEntity_ManagedObject.ts" />

class LegalEntity extends LegalEntity_ManagedObject
{

}
