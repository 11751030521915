

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class CoreAnnotation_ManagedObject

class CoreAnnotation_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: accountPrefix
    set accountPrefix(value:string) {
        this.setValueForKey(value, 'accountPrefix');
    }
    get accountPrefix():string {
        return this.valueForKey('accountPrefix');
    }
    set accountPrefixPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'accountPrefix');
    }
    get accountPrefixPrimitiveValue():string {
        return this.primitiveValueForKey('accountPrefix');
    }

    // Property: amount
    set amount(value:number) {
        this.setValueForKey(value, 'amount');
    }
    get amount():number {
        return this.valueForKey('amount');
    }
    set amountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'amount');
    }
    get amountPrimitiveValue():number {
        return this.primitiveValueForKey('amount');
    }

    // Property: bulkFactor
    set bulkFactor(value:number) {
        this.setValueForKey(value, 'bulkFactor');
    }
    get bulkFactor():number {
        return this.valueForKey('bulkFactor');
    }
    set bulkFactorPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'bulkFactor');
    }
    get bulkFactorPrimitiveValue():number {
        return this.primitiveValueForKey('bulkFactor');
    }

    // Property: componentID
    set componentID(value:string) {
        this.setValueForKey(value, 'componentID');
    }
    get componentID():string {
        return this.valueForKey('componentID');
    }
    set componentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'componentID');
    }
    get componentIDPrimitiveValue():string {
        return this.primitiveValueForKey('componentID');
    }

    // Property: concept
    set concept(value:string) {
        this.setValueForKey(value, 'concept');
    }
    get concept():string {
        return this.valueForKey('concept');
    }
    set conceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'concept');
    }
    get conceptPrimitiveValue():string {
        return this.primitiveValueForKey('concept');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: formatName
    set formatName(value:string) {
        this.setValueForKey(value, 'formatName');
    }
    get formatName():string {
        return this.valueForKey('formatName');
    }
    set formatNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'formatName');
    }
    get formatNamePrimitiveValue():string {
        return this.primitiveValueForKey('formatName');
    }

    // Property: level
    set level(value:number) {
        this.setValueForKey(value, 'level');
    }
    get level():number {
        return this.valueForKey('level');
    }
    set levelPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'level');
    }
    get levelPrimitiveValue():number {
        return this.primitiveValueForKey('level');
    }

    // Property: menuID
    set menuID(value:string) {
        this.setValueForKey(value, 'menuID');
    }
    get menuID():string {
        return this.valueForKey('menuID');
    }
    set menuIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'menuID');
    }
    get menuIDPrimitiveValue():string {
        return this.primitiveValueForKey('menuID');
    }

    // Property: menuName
    set menuName(value:string) {
        this.setValueForKey(value, 'menuName');
    }
    get menuName():string {
        return this.valueForKey('menuName');
    }
    set menuNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'menuName');
    }
    get menuNamePrimitiveValue():string {
        return this.primitiveValueForKey('menuName');
    }

    // Property: minMeasureUnitType
    set minMeasureUnitType(value:number) {
        this.setValueForKey(value, 'minMeasureUnitType');
    }
    get minMeasureUnitType():number {
        return this.valueForKey('minMeasureUnitType');
    }
    set minMeasureUnitTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minMeasureUnitType');
    }
    get minMeasureUnitTypePrimitiveValue():number {
        return this.primitiveValueForKey('minMeasureUnitType');
    }

    // Property: productContainerMeasureType
    set productContainerMeasureType(value:number) {
        this.setValueForKey(value, 'productContainerMeasureType');
    }
    get productContainerMeasureType():number {
        return this.valueForKey('productContainerMeasureType');
    }
    set productContainerMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productContainerMeasureType');
    }
    get productContainerMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('productContainerMeasureType');
    }

    // Property: productContainerQuantity
    set productContainerQuantity(value:number) {
        this.setValueForKey(value, 'productContainerQuantity');
    }
    get productContainerQuantity():number {
        return this.valueForKey('productContainerQuantity');
    }
    set productContainerQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productContainerQuantity');
    }
    get productContainerQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('productContainerQuantity');
    }

    // Property: productID
    set productID(value:string) {
        this.setValueForKey(value, 'productID');
    }
    get productID():string {
        return this.valueForKey('productID');
    }
    set productIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productID');
    }
    get productIDPrimitiveValue():string {
        return this.primitiveValueForKey('productID');
    }

    // Property: productMeasureType
    set productMeasureType(value:number) {
        this.setValueForKey(value, 'productMeasureType');
    }
    get productMeasureType():number {
        return this.valueForKey('productMeasureType');
    }
    set productMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productMeasureType');
    }
    get productMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('productMeasureType');
    }

    // Property: productName
    set productName(value:string) {
        this.setValueForKey(value, 'productName');
    }
    get productName():string {
        return this.valueForKey('productName');
    }
    set productNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productName');
    }
    get productNamePrimitiveValue():string {
        return this.primitiveValueForKey('productName');
    }

    // Property: quantity
    set quantity(value:number) {
        this.setValueForKey(value, 'quantity');
    }
    get quantity():number {
        return this.valueForKey('quantity');
    }
    set quantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'quantity');
    }
    get quantityPrimitiveValue():number {
        return this.primitiveValueForKey('quantity');
    }

    // Property: sessionDate
    set sessionDate(value:Date) {
        this.setValueForKey(value, 'sessionDate');
    }
    get sessionDate():Date {
        return this.valueForKey('sessionDate');
    }
    set sessionDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'sessionDate');
    }
    get sessionDatePrimitiveValue():Date {
        return this.primitiveValueForKey('sessionDate');
    }

    // Property: sessionID
    set sessionID(value:string) {
        this.setValueForKey(value, 'sessionID');
    }
    get sessionID():string {
        return this.valueForKey('sessionID');
    }
    set sessionIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sessionID');
    }
    get sessionIDPrimitiveValue():string {
        return this.primitiveValueForKey('sessionID');
    }

    // Property: sourceDocumentID
    set sourceDocumentID(value:string) {
        this.setValueForKey(value, 'sourceDocumentID');
    }
    get sourceDocumentID():string {
        return this.valueForKey('sourceDocumentID');
    }
    set sourceDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceDocumentID');
    }
    get sourceDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('sourceDocumentID');
    }

    // Property: sourceDocumentLineID
    set sourceDocumentLineID(value:string) {
        this.setValueForKey(value, 'sourceDocumentLineID');
    }
    get sourceDocumentLineID():string {
        return this.valueForKey('sourceDocumentLineID');
    }
    set sourceDocumentLineIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceDocumentLineID');
    }
    get sourceDocumentLineIDPrimitiveValue():string {
        return this.primitiveValueForKey('sourceDocumentLineID');
    }

    // Property: sourceDocumentLineModifierID
    set sourceDocumentLineModifierID(value:string) {
        this.setValueForKey(value, 'sourceDocumentLineModifierID');
    }
    get sourceDocumentLineModifierID():string {
        return this.valueForKey('sourceDocumentLineModifierID');
    }
    set sourceDocumentLineModifierIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceDocumentLineModifierID');
    }
    get sourceDocumentLineModifierIDPrimitiveValue():string {
        return this.primitiveValueForKey('sourceDocumentLineModifierID');
    }

    // Property: sourceDocumentLineType
    set sourceDocumentLineType(value:number) {
        this.setValueForKey(value, 'sourceDocumentLineType');
    }
    get sourceDocumentLineType():number {
        return this.valueForKey('sourceDocumentLineType');
    }
    set sourceDocumentLineTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'sourceDocumentLineType');
    }
    get sourceDocumentLineTypePrimitiveValue():number {
        return this.primitiveValueForKey('sourceDocumentLineType');
    }

    // Property: sourceDocumentReference
    set sourceDocumentReference(value:string) {
        this.setValueForKey(value, 'sourceDocumentReference');
    }
    get sourceDocumentReference():string {
        return this.valueForKey('sourceDocumentReference');
    }
    set sourceDocumentReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceDocumentReference');
    }
    get sourceDocumentReferencePrimitiveValue():string {
        return this.primitiveValueForKey('sourceDocumentReference');
    }

    // Property: sourceLegalDocumentReference
    set sourceLegalDocumentReference(value:string) {
        this.setValueForKey(value, 'sourceLegalDocumentReference');
    }
    get sourceLegalDocumentReference():string {
        return this.valueForKey('sourceLegalDocumentReference');
    }
    set sourceLegalDocumentReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceLegalDocumentReference');
    }
    get sourceLegalDocumentReferencePrimitiveValue():string {
        return this.primitiveValueForKey('sourceLegalDocumentReference');
    }

    // Property: workerID
    set workerID(value:string) {
        this.setValueForKey(value, 'workerID');
    }
    get workerID():string {
        return this.valueForKey('workerID');
    }
    set workerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerID');
    }
    get workerIDPrimitiveValue():string {
        return this.primitiveValueForKey('workerID');
    }

    // Property: workerName
    set workerName(value:string) {
        this.setValueForKey(value, 'workerName');
    }
    get workerName():string {
        return this.valueForKey('workerName');
    }
    set workerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerName');
    }
    get workerNamePrimitiveValue():string {
        return this.primitiveValueForKey('workerName');
    }
}
