class DBUsersCoder extends DBXLSXCoder
{
    mainEntity() {
        return { entity: "User", predicateFormat: "deletedAt == null" }
    }

    columns() : any[] {
        let cols = [
            { title:"USER ID", width: "10%", align: "center" },
            { title:"USER NAME", width: "20%", align: "center" },            
            { title:"USER EMAIL", width: "20%", align: "center" },                     
            { title:"USER ACCESS CONTROL", width: "10%", align: "center" },            
            { title:"USER IS ADMIN", width: "5%", align: "center" },                  
            { title:"USER PERMISIONS", width: "20%", align: "center" },
        ]
        return cols;
    }

    protected importRow(row:any){
                
        let userID        = row["USER ID"];
        let userName      = row["USER NAME"];        
        let userEmail     = row["USER EMAIL"];
        let accessControl = row["USER ACCESS CONTROL"];
        let isAdmin       = row["USER IS ADMIN"];
        let permisions    = row["USER PERMISIONS"];

        let user = this.queryEntityByIDOrName("User", userID, userName) as User;
        if (user == null) {
            user = MIOEntityDescription.insertNewObjectForEntityForName("User", DBHelper.mainManagedObjectContext) as User;
            user.identifier = this.parseOrCreateIdentifier(userID);
            user.name = userName;
        }
            
        user.email = userEmail;
        user.accessControl = accessControl;
        user.isAdmin = this.parseBoolValue( isAdmin );
        user.permisions = permisions;

        this.appendObject(user);
        MIOLog("ADDING USER: " + this.rowIndex + "/" + this.rows.length + ": " + userName);
    }

    protected exportTitle() : string { return "User"; }

    protected exportSortDescriptors() { 
        return [MIOSortDescriptor.sortDescriptorWithKey("name", false)]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let user = object as User;

        let item = {
            "USER ID"             : user.identifier,
            "USER NAME"           : user.name,
            "USER EMAIL"          : user.email,
            "USER ACCESS CONTROL" : user.accessControl,            
            "USER IS ADMIN"       : user.isAdmin,
            "USER PERMISIONS"     : user.permisions
        }

        return item;
    }
}