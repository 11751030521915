//
// Generated class MemoLog
//

/// <reference path="MemoLog_ManagedObject.ts" />

class MemoLog extends MemoLog_ManagedObject
{

}
