

/// <reference path="Interval.ts" />

// Generated class PaymentActionInterval_ManagedObject

class PaymentActionInterval_ManagedObject extends Interval
{
    // Relationship: paymentAction
    set paymentAction(value:PaymentAction) {
        this.setValueForKey(value, 'paymentAction');
    }
    get paymentAction():PaymentAction {
        return this.valueForKey('paymentAction') as PaymentAction;
    }
}
