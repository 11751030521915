

class BookingDetailViewController extends MUIViewController
{    
    private saveButton:MUIButton = null;
    private sendButton:MUIButton = null;
    private backButton:MUIButton = null;
    private pageController:MUIPageController = null;
    
    private segmentedControl:MUISegmentedControl = null;

    private generalBookingViewController:BookingDetailGeneralViewController = null;
    private transactionsViewController:ClientAccountViewController = null;
    private logViewController = null;
    
    dayConfiguration = null;

    viewDidLoad(){

        super.viewDidLoad();

        this.backButton = MUIOutlet(this, "back_btn", "MUIButton");
        this.backButton.setAction(this, function () {
            this.navigationController.popViewController(true);
        });

        this.segmentedControl = MUIOutlet(this, 'segmented_control', 'MUISegmentedControl');
        this.segmentedControl.setAction(this, function (control, index) {
            this.pageController.showPageAtIndex(index);
        });

        this.saveButton = MUIOutlet(this, 'save_btn','MUIButton');
        this.saveButton.setAction(this, function(this:BookingDetailViewController){
           this.saveBooking(false);
        });

        this.sendButton = MUIOutlet(this, 'send_btn','MUIButton');
        this.sendButton.setAction(this, function(this:BookingDetailViewController){
           this.saveBooking(true);
        });

        this.pageController = MUIOutlet(this, "page_controller", "MUIPageController");

        this.generalBookingViewController = new BookingDetailGeneralViewController("booking-detail-general-view");
        this.generalBookingViewController.initWithResource("layout/bookings/bookings/BookingsDetailGeneralView.html");
        this.pageController.addPageViewController(this.generalBookingViewController);

        this.transactionsViewController = new ClientAccountViewController("client-account-view");
        //this.transactionsViewController.showBackButton = false;
        this.transactionsViewController.showBookingOptions = true;
        this.transactionsViewController.initWithResource("layout/clients/ClientAccountView.html");
        this.pageController.addPageViewController(this.transactionsViewController);

        this.logViewController = new BookingDetailLogsViewController("booking-detail-log-view");
        this.logViewController.initWithResource("layout/bookings/bookings/BookingDetailLogView.html");
        this.logViewController.booking = this._booking;
        this.pageController.addPageViewController(this.logViewController);
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    viewDidAppear(animated?){
        super.viewDidAppear(animated);
        MIONotificationCenter.defaultCenter().addObserver(this, "ShowBookingDetail", function(noty:MIONotification){
            let booking = noty.object;
            this.booking = booking;
        });
    }

    viewWillDisappear(animated?){
        super.viewWillDisappear(animated);
        MIONotificationCenter.defaultCenter().removeObserver(this, "ShowBookingDetail");
    }

    private _booking:Booking = null;        
    set booking(value:Booking){
        if (value == this._booking) return;
        
        if (this.segmentedControl != null) this.segmentedControl.selectSegmentedAtIndex(0);
        if (this.pageController != null) this.pageController.showPageAtIndex(0);                

        if (value == null) {
            // create new booking
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            this._booking = MIOEntityDescription.insertNewObjectForEntityForName("Booking", ad.managedObjectContext) as Booking;            
            this._booking.date = MIODateToday();            
            this._booking.day = ad.serverDateFormatter.stringFromDate(this._booking.date);    
        }
        
        this._booking = value;                
        this.updateUI();        

    }

    set filterValues(values:any){
        let identifier = values["id"];
        if (identifier == null) return;
        DBHelper.queryObjectsWithCompletion("Booking", null, MIOPredicate.predicateWithFormat("identifier == '" + identifier + "'"), ["client.address", "client.mobilePhone", "client.phone", "group", "locations" ], this, function(objects){
            if (objects.length > 0) {
                let b = objects[0] as Booking;
                this.booking = b;
            }
        });
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._booking == null) return;

        this.generalBookingViewController.booking = this._booking;
        this.logViewController.booking = this._booking;
        this.transactionsViewController.booking = this._booking;
        // this.transactionsViewController.client = this._booking.client;
    }

    private saveBooking(sendEmail:boolean){

        let check = true;
        if (this._booking.status != BookingStatus.BookingDeleted){
            if (this._booking.pax == null || this._booking.pax == 0) {
                this.showAlert("The number of pax is required");
                check = false;
            }
            else if (this._booking.hour == null){
                this.showAlert("The hour time is required");
                check = false;
            }    
        }

        if (check == false) return;

        if (sendEmail == true) this._booking.sendEmail += 1;

        DBHelper.saveMainContextWithCompletion(this, function(){
            if (sendEmail == true) {
                AppHelper.showInfoMessage(this, 'SUCCESS', 'EMAIL ADDED TO QUEUE');
            }
        });

        // DBHelper.saveMainContextWithCompletion(this, function(){
        //     if (sendEmail == true) {
        //         this.sendEmail();
        //     }    
        // });
    }

    // private sendEmail(){
    //     let ad = MUIWebApplication.sharedInstance().delegate;
    //     let ws = ad.webService;
    //     ws.sendBookingEmail(this._booking, this, function(code:number, json:any){
    //         let status = json != null ? json["status"] : null;
    //        if(code == 200 && status?.toLowerCase() == "ok") {
    //            AppHelper.showInfoMessage(this, 'Success', 'Enviado correctamente');
    //        }
    //        else{
    //            AppHelper.showErrorMessage(this, 'Error', 'Error enviando email');
    //        }
    //     });
    // }

    private showAlert(message:string){

        let avc = new MUIAlertViewController();
        avc.initWithTitle('Fields required', message, MUIAlertViewStyle.Default);
        avc.addAction(MUIAlertAction.alertActionWithTitle('OK', MUIAlertActionStyle.Default, null, null));
        this.presentViewController(avc, true);
    }
}