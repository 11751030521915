

/// <reference path="CoreEntity.ts" />

// Generated class SyncLog_ManagedObject

class SyncLog_ManagedObject extends CoreEntity
{

    // Property: appID
    set appID(value:string) {
        this.setValueForKey(value, 'appID');
    }
    get appID():string {
        return this.valueForKey('appID');
    }
    set appIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'appID');
    }
    get appIDPrimitiveValue():string {
        return this.primitiveValueForKey('appID');
    }

    // Property: appModelVersion
    set appModelVersion(value:string) {
        this.setValueForKey(value, 'appModelVersion');
    }
    get appModelVersion():string {
        return this.valueForKey('appModelVersion');
    }
    set appModelVersionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'appModelVersion');
    }
    get appModelVersionPrimitiveValue():string {
        return this.primitiveValueForKey('appModelVersion');
    }

    // Property: appType
    set appType(value:number) {
        this.setValueForKey(value, 'appType');
    }
    get appType():number {
        return this.valueForKey('appType');
    }
    set appTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'appType');
    }
    get appTypePrimitiveValue():number {
        return this.primitiveValueForKey('appType');
    }

    // Property: appVersion
    set appVersion(value:string) {
        this.setValueForKey(value, 'appVersion');
    }
    get appVersion():string {
        return this.valueForKey('appVersion');
    }
    set appVersionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'appVersion');
    }
    get appVersionPrimitiveValue():string {
        return this.primitiveValueForKey('appVersion');
    }

    // Property: changedByID
    set changedByID(value:string) {
        this.setValueForKey(value, 'changedByID');
    }
    get changedByID():string {
        return this.valueForKey('changedByID');
    }
    set changedByIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'changedByID');
    }
    get changedByIDPrimitiveValue():string {
        return this.primitiveValueForKey('changedByID');
    }

    // Property: changedByName
    set changedByName(value:string) {
        this.setValueForKey(value, 'changedByName');
    }
    get changedByName():string {
        return this.valueForKey('changedByName');
    }
    set changedByNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'changedByName');
    }
    get changedByNamePrimitiveValue():string {
        return this.primitiveValueForKey('changedByName');
    }

    // Property: error
    set error(value:string) {
        this.setValueForKey(value, 'error');
    }
    get error():string {
        return this.valueForKey('error');
    }
    set errorPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'error');
    }
    get errorPrimitiveValue():string {
        return this.primitiveValueForKey('error');
    }

    // Property: fileType
    set fileType(value:number) {
        this.setValueForKey(value, 'fileType');
    }
    get fileType():number {
        return this.valueForKey('fileType');
    }
    set fileTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'fileType');
    }
    get fileTypePrimitiveValue():number {
        return this.primitiveValueForKey('fileType');
    }

    // Property: fileURL
    set fileURL(value:string) {
        this.setValueForKey(value, 'fileURL');
    }
    get fileURL():string {
        return this.valueForKey('fileURL');
    }
    set fileURLPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'fileURL');
    }
    get fileURLPrimitiveValue():string {
        return this.primitiveValueForKey('fileURL');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: systemVersion
    set systemVersion(value:string) {
        this.setValueForKey(value, 'systemVersion');
    }
    get systemVersion():string {
        return this.valueForKey('systemVersion');
    }
    set systemVersionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'systemVersion');
    }
    get systemVersionPrimitiveValue():string {
        return this.primitiveValueForKey('systemVersion');
    }
}
