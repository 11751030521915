


class ProductSpentViewController extends MUIViewController
{
   
    private _product:Product = null;

    private closeButton:MUIButton = null;
    
    private spentForTodaySwitchButton:MUISwitchButton = null;
    private limitForTodayTextField:MUITextField = null;

    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;

    get preferredContentSize()
    {
       return new MIOSize(400, 400);
    }
    
    viewDidLoad()
    {
        super.viewDidLoad();


        this.closeButton = MUIOutlet(this, 'pspentv_nb_close_btn','MUIButton');
        this.closeButton.setAction(this, function()
        {
           let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
           moc.save(); 
           this.dismissViewController(true);
        });

        this.spentForTodaySwitchButton = MUIOutlet(this, 'pspentv_tv_switch_btn','MUISwitchButton');
        this.spentForTodaySwitchButton.setOnChangeValue(this, function (checkbox, value) 
        {
           this._product.isSpentForToday = value;
           if(!this._product.isSpentForToday){
                this._product.salesLimitForToday = null;
                this.limitForTodayTextField.text = '';
           }
        });

        this.limitForTodayTextField = MUIOutlet(this, 'pspentv_tv_limit_txt','MUITextField');
        this.limitForTodayTextField.formatter = this.nf;
        this.limitForTodayTextField.setOnChangeText(this, function(textfield, value)
        {
            let limit = this.nf.numberFromString(value);
            this._product.salesLimitForToday = limit;
        });
    }


    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
        this.updateUI();
    }
    set product(value)
    {
        this._product = value;
        this.updateUI();
    }

    private updateUI()
    {
        
        if(this._product != null && this.viewIsLoaded)
        {
            this.spentForTodaySwitchButton.setOn(this._product.isSpentForToday);
            this.limitForTodayTextField.text = this.nf.stringFromNumber(this._product.salesLimitForToday);
        }
    }
}