

/// <reference path="CoreAccounting.ts" />

// Generated class EconomicEntity_ManagedObject

class EconomicEntity_ManagedObject extends CoreAccounting
{

    // Property: automaticAction
    set automaticAction(value:number) {
        this.setValueForKey(value, 'automaticAction');
    }
    get automaticAction():number {
        return this.valueForKey('automaticAction');
    }
    set automaticActionPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'automaticAction');
    }
    get automaticActionPrimitiveValue():number {
        return this.primitiveValueForKey('automaticAction');
    }

    // Property: comments
    set comments(value:string) {
        this.setValueForKey(value, 'comments');
    }
    get comments():string {
        return this.valueForKey('comments');
    }
    set commentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'comments');
    }
    get commentsPrimitiveValue():string {
        return this.primitiveValueForKey('comments');
    }

    // Property: debtsLimit
    set debtsLimit(value:number) {
        this.setValueForKey(value, 'debtsLimit');
    }
    get debtsLimit():number {
        return this.valueForKey('debtsLimit');
    }
    set debtsLimitPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'debtsLimit');
    }
    get debtsLimitPrimitiveValue():number {
        return this.primitiveValueForKey('debtsLimit');
    }

    // Property: discount
    set discount(value:number) {
        this.setValueForKey(value, 'discount');
    }
    get discount():number {
        return this.valueForKey('discount');
    }
    set discountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'discount');
    }
    get discountPrimitiveValue():number {
        return this.primitiveValueForKey('discount');
    }

    // Property: type
    set type(value:string) {
        this.setValueForKey(value, 'type');
    }
    get type():string {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():string {
        return this.primitiveValueForKey('type');
    }

    // Relationship: debtLines
    protected _debtLines:MIOManagedObjectSet = null;
    get debtLines():MIOManagedObjectSet {
        return this.valueForKey('debtLines');
    }
    addDebtLinesObject(value:DebtLine) {
        this._addObjectForKey(value, 'debtLines');
    }
    removeDebtLinesObject(value:DebtLine) {
        this._removeObjectForKey(value, 'debtLines');
    }

    // Relationship: economicAccountLines
    protected _economicAccountLines:MIOManagedObjectSet = null;
    get economicAccountLines():MIOManagedObjectSet {
        return this.valueForKey('economicAccountLines');
    }
    addEconomicAccountLinesObject(value:EconomicAccountLine) {
        this._addObjectForKey(value, 'economicAccountLines');
    }
    removeEconomicAccountLinesObject(value:EconomicAccountLine) {
        this._removeObjectForKey(value, 'economicAccountLines');
    }

    // Relationship: invitationLines
    protected _invitationLines:MIOManagedObjectSet = null;
    get invitationLines():MIOManagedObjectSet {
        return this.valueForKey('invitationLines');
    }
    addInvitationLinesObject(value:InvitationLine) {
        this._addObjectForKey(value, 'invitationLines');
    }
    removeInvitationLinesObject(value:InvitationLine) {
        this._removeObjectForKey(value, 'invitationLines');
    }
    // Relationship: invitationRulePreset
    set invitationRulePreset(value:InvitationRulePreset) {
        this.setValueForKey(value, 'invitationRulePreset');
    }
    get invitationRulePreset():InvitationRulePreset {
        return this.valueForKey('invitationRulePreset') as InvitationRulePreset;
    }

    // Relationship: linkedTickets
    protected _linkedTickets:MIOManagedObjectSet = null;
    get linkedTickets():MIOManagedObjectSet {
        return this.valueForKey('linkedTickets');
    }
    addLinkedTicketsObject(value:Ticket) {
        this._addObjectForKey(value, 'linkedTickets');
    }
    removeLinkedTicketsObject(value:Ticket) {
        this._removeObjectForKey(value, 'linkedTickets');
    }

    // Relationship: loyaltyAccountLines
    protected _loyaltyAccountLines:MIOManagedObjectSet = null;
    get loyaltyAccountLines():MIOManagedObjectSet {
        return this.valueForKey('loyaltyAccountLines');
    }
    addLoyaltyAccountLinesObject(value:LoyaltyAccountLine) {
        this._addObjectForKey(value, 'loyaltyAccountLines');
    }
    removeLoyaltyAccountLinesObject(value:LoyaltyAccountLine) {
        this._removeObjectForKey(value, 'loyaltyAccountLines');
    }
    // Relationship: order
    set order(value:Order) {
        this.setValueForKey(value, 'order');
    }
    get order():Order {
        return this.valueForKey('order') as Order;
    }

    // Relationship: parkedLines
    protected _parkedLines:MIOManagedObjectSet = null;
    get parkedLines():MIOManagedObjectSet {
        return this.valueForKey('parkedLines');
    }
    addParkedLinesObject(value:DebtLine) {
        this._addObjectForKey(value, 'parkedLines');
    }
    removeParkedLinesObject(value:DebtLine) {
        this._removeObjectForKey(value, 'parkedLines');
    }
    // Relationship: rate
    set rate(value:Rate) {
        this.setValueForKey(value, 'rate');
    }
    get rate():Rate {
        return this.valueForKey('rate') as Rate;
    }
}
