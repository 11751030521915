

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ContainerUnits_ManagedObject

class ContainerUnits_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: displayUnit
    set displayUnit(value:number) {
        this.setValueForKey(value, 'displayUnit');
    }
    get displayUnit():number {
        return this.valueForKey('displayUnit');
    }
    set displayUnitPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'displayUnit');
    }
    get displayUnitPrimitiveValue():number {
        return this.primitiveValueForKey('displayUnit');
    }

    // Property: displayUnitType
    set displayUnitType(value:number) {
        this.setValueForKey(value, 'displayUnitType');
    }
    get displayUnitType():number {
        return this.valueForKey('displayUnitType');
    }
    set displayUnitTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'displayUnitType');
    }
    get displayUnitTypePrimitiveValue():number {
        return this.primitiveValueForKey('displayUnitType');
    }
}
