/// <reference path="DBExportController.ts" />

class DBExportExportQRLocationController extends DBExportController
{    
    private tintColor() { return 0x0B8081; }
    protected title():string { return "QR Codes"; }
    protected startColumn():number { return 0; }
    protected headerTitleStyle() { return {sz: 15, color: { rgb: this.tintColor() } }; }
    protected headerValueStyle() { return {sz: 15, bold:true, color: { rgb: this.tintColor() } }; }
    protected tableHeaderStyle(columnIndex:number) { 
        return {
            sz: 18, 
            bold:true, 
            color: { rgb: this.tintColor() }, 
            bottom: { 
                style: "medium", 
                color: { rgb: this.tintColor() }
            }
          }; 
    }
    protected tableValueStyle(columnIndex:number) { 

        if (columnIndex == 0 || columnIndex == 1) {
            return {
                sz: 14,
                alignment: { vertical: "center" },
                bottom: { 
                    style: "thin"
                },                
            }; 
        }
        else {
            return {
                sz: 14,
                alignment: { vertical: "center" },
                bottom: { 
                    style: "thin"
                },
                left: { 
                    style: "thin"
                }
            }; 
        }
    }

    protected tableSectionStyle() { return {sz: 15, bold:true } }
    protected columnWidths() { return [38, 15, 10, 16]; }
    protected rowHeight() { return 30; }

    protected headers() : any[] {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        return [
            { "Title": "QR Codes" },
            { "Title": "Date: " + MIODateTodayString() }
        ]
    }

    protected columns(): string[] {
        return [
            "NAME",
            "URL"
        ];
    }
    
    protected entityName() { return "Location"; }
    protected predicate() { return MIOPredicate.predicateWithFormat("deletedAt == null") }
    protected sortDescriptors() { 
        return [
            MIOSortDescriptor.sortDescriptorWithKey('category.name', true), 
            MIOSortDescriptor.sortDescriptorWithKey('name', true)
        ];
    }
    protected relations(): string[] {
        return ["category"];
    }

    protected parseObjects(objects:MIOManagedObject[]) : any[] { 
        let items = [];
        
        let objs = objects;// _MIOSortDescriptorSortObjects( objects, this.sortDescriptors() );

        for (let index = 0; index < objs.length; index++) {            
            let obj = objs[index] as Location;
            // if (section != obj.productStockCategoryName) {
            //     items.addObject( { "Title" : obj.productStockCategoryName, "__RowType": "section" } );
            //     section = obj.productStockCategoryName;
            // }
            let item = this.parseLine(obj);
            items.addObject(item)
        }

        return items;
    }

    private parseLine(loc:Location): any {

        let item = {
            "NAME": loc.category.name + " - " + loc.name ,
            "URL": loc.qrURLString,
        }

        return item;
    }

}