
class PartnerListViewController extends EntityListViewController
{
    private addButton:MUIButton = null;    

    viewDidLoad(){
        super.viewDidLoad();
    
        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, this.addSupplierAction);        

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        MIONotificationCenter.defaultCenter().addObserver(this, "PartnerDidDeleted", this.partnerDidDeleteNotification);

        this.needsReloadData();
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("PartnerListCell") as PartnerListCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Partner;

        cell.item = item;
        cell.selected = (this.partner && this.partner.identifier == item.identifier) ? true : false;
        
        return cell;
    }

    private partner:Partner = null;
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        this.partner = this.fetchedResultsController.objectAtIndexPath(indexPath);        
        this.showSelectedPartner();
    }

    private showSelectedPartner(){        
        let vc = new PartnerDetailViewController("partner-detail-view");
        vc.initWithResource("layout/partner/PartnerDetailView.html");

        vc.item = this.partner;

        this.splitViewController.showDetailViewController(vc);
    }

    private showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Partner", NoItemSelectedViewControllerType.Partner);
        this.splitViewController.showDetailViewController(vc);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

        let predicateString = null; //"partnerSchemaID != " + ad.selectedIdentifier;
        if (this.searchString != null && this.searchString.length > 0 )
            //predicateString +='AND name CONTAINS "' + this.searchString + '"';
            predicateString = 'name CONTAINS "' + this.searchString + '"';

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Partner", sortDescriptors, predicateString);         
        fetchRequest.relationshipKeyPathsForPrefetching = ["address", "phone", "mobilePhone", "image", "detailImage"];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    private addSupplierAction(){

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ADD NEW PATNER","ADD NEW PATNER"), MIOLocalizeString("NAME","NAME"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){ 
            textField.placeholderText = MIOLocalizeString("NAME","NAME");
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK","OK"), MUIAlertActionStyle.Default, this, function(){
            this.createPartner(avc.textFields[0].text);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL","CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    } 

    private createPartner(name:string) {
        
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let partner = MIOEntityDescription.insertNewObjectForEntityForName("Partner", ad.managedObjectContext) as Partner;
        partner.name = name;
        DBHelper.saveMainContext();

        this.partner = partner;
        this.showSelectedPartner();
    }

    private partnerDidDeleteNotification(notification:MIONotification) {
        let supplier = notification.object as Supplier;
        if (this.partner == null) return;
        if (supplier.identifier != this.partner.identifier) return;

        this.partner = null;
        this.showNoItemSelected();
    }
    
}