

class PartyImageCell extends MUICollectionViewCell
{
    private imageView:MUIImageView = null;
    private removeButton:MUIButton = null;

    awakeFromHTML(){
        this.imageView = MUIOutlet(this, "image-view", "MUIImageView");
        this.removeButton = MUIOutlet(this, "remove-btn", "MUIButton");
        this.removeButton.setAction(this, function(){
            this._item.removeFromMOC(true);
        });
    }

    private _item:PartyImage = null;
    set item(item:PartyImage){
        this._item = item;        
        this.imageView.setImage(item.urlString);
    }

    // layoutSubviews(){
    //     super.layoutSubviews();
    //     // this.imageView.setWidth(this.getWidth());
    //     // this.imageView.setHeight(this.getHeight());
    // }
}