

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class BookingGuest_ManagedObject

class BookingGuest_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: channelName
    set channelName(value:string) {
        this.setValueForKey(value, 'channelName');
    }
    get channelName():string {
        return this.valueForKey('channelName');
    }
    set channelNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'channelName');
    }
    get channelNamePrimitiveValue():string {
        return this.primitiveValueForKey('channelName');
    }

    // Property: clientName
    set clientName(value:string) {
        this.setValueForKey(value, 'clientName');
    }
    get clientName():string {
        return this.valueForKey('clientName');
    }
    set clientNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientName');
    }
    get clientNamePrimitiveValue():string {
        return this.primitiveValueForKey('clientName');
    }

    // Property: comments
    set comments(value:string) {
        this.setValueForKey(value, 'comments');
    }
    get comments():string {
        return this.valueForKey('comments');
    }
    set commentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'comments');
    }
    get commentsPrimitiveValue():string {
        return this.primitiveValueForKey('comments');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: day
    set day(value:string) {
        this.setValueForKey(value, 'day');
    }
    get day():string {
        return this.valueForKey('day');
    }
    set dayPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'day');
    }
    get dayPrimitiveValue():string {
        return this.primitiveValueForKey('day');
    }

    // Property: hour
    set hour(value:string) {
        this.setValueForKey(value, 'hour');
    }
    get hour():string {
        return this.valueForKey('hour');
    }
    set hourPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'hour');
    }
    get hourPrimitiveValue():string {
        return this.primitiveValueForKey('hour');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: pax
    set pax(value:number) {
        this.setValueForKey(value, 'pax');
    }
    get pax():number {
        return this.valueForKey('pax');
    }
    set paxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'pax');
    }
    get paxPrimitiveValue():number {
        return this.primitiveValueForKey('pax');
    }

    // Property: paxAttend
    set paxAttend(value:number) {
        this.setValueForKey(value, 'paxAttend');
    }
    get paxAttend():number {
        return this.valueForKey('paxAttend');
    }
    set paxAttendPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'paxAttend');
    }
    get paxAttendPrimitiveValue():number {
        return this.primitiveValueForKey('paxAttend');
    }

    // Property: recommendationName
    set recommendationName(value:string) {
        this.setValueForKey(value, 'recommendationName');
    }
    get recommendationName():string {
        return this.valueForKey('recommendationName');
    }
    set recommendationNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'recommendationName');
    }
    get recommendationNamePrimitiveValue():string {
        return this.primitiveValueForKey('recommendationName');
    }

    // Property: sourceName
    set sourceName(value:string) {
        this.setValueForKey(value, 'sourceName');
    }
    get sourceName():string {
        return this.valueForKey('sourceName');
    }
    set sourceNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceName');
    }
    get sourceNamePrimitiveValue():string {
        return this.primitiveValueForKey('sourceName');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: workerID
    set workerID(value:string) {
        this.setValueForKey(value, 'workerID');
    }
    get workerID():string {
        return this.valueForKey('workerID');
    }
    set workerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerID');
    }
    get workerIDPrimitiveValue():string {
        return this.primitiveValueForKey('workerID');
    }

    // Property: workerName
    set workerName(value:string) {
        this.setValueForKey(value, 'workerName');
    }
    get workerName():string {
        return this.valueForKey('workerName');
    }
    set workerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerName');
    }
    get workerNamePrimitiveValue():string {
        return this.primitiveValueForKey('workerName');
    }
    // Relationship: channel
    set channel(value:BookingChannel) {
        this.setValueForKey(value, 'channel');
    }
    get channel():BookingChannel {
        return this.valueForKey('channel') as BookingChannel;
    }
    // Relationship: client
    set client(value:Client) {
        this.setValueForKey(value, 'client');
    }
    get client():Client {
        return this.valueForKey('client') as Client;
    }
    // Relationship: guestList
    set guestList(value:BookingGuestList) {
        this.setValueForKey(value, 'guestList');
    }
    get guestList():BookingGuestList {
        return this.valueForKey('guestList') as BookingGuestList;
    }
    // Relationship: recommendation
    set recommendation(value:BookingRecommendation) {
        this.setValueForKey(value, 'recommendation');
    }
    get recommendation():BookingRecommendation {
        return this.valueForKey('recommendation') as BookingRecommendation;
    }
    // Relationship: source
    set source(value:BookingSource) {
        this.setValueForKey(value, 'source');
    }
    get source():BookingSource {
        return this.valueForKey('source') as BookingSource;
    }
}
