


class SingleEditViewController extends MUIViewController
{
    private titleLabel:MUILabel = null;

    private saveButton:MUIButton = null;
    private addButton:MUIButton = null;

    private tableView:UITableView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
        this.titleLabel.text = this.title;

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            ad.managedObjectContext.save();
        });

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, this.addButtonDidPress);

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.tableView.reloadData();
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("SingleEditCell") as SingleEditCell;

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);

        this.configureCellAtIndexPath(cell, indexPath, item);

        return cell;
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);

        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            this.removeItem(item);
            this.tableView.deleteRowsAtIndexPaths([indexPath], UITableViewRowAnimation.Automatic);
        }
    }


    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (this._fetchedResultsController != null) {
            this._fetchedResultsController.delegate = null;
        }
            
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let entityName = this.fetchEntity();
        let fetchRequest = DBHelper.listFetchRequestWithEntityName(entityName, this.sortDescriptors(), null);        

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;

        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }
    
    get title(){
        return "Single Edit View Controller";
    }

    addButtonDidPress(){

    }

    removeItem(item:MIOManagedObject){
        DBHelper.deleteObjectFromMainContext(item, false);
    }

    fetchEntity(){
        return null;
    }

    sortDescriptors(){
        return null;
    }

    configureCellAtIndexPath(cell:SingleEditCell, indexPath:MIOIndexPath, item:MIOManagedObject){

    }

    reloadData(){
        this.tableView.reloadData();
    }
}