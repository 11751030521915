//
// Generated class LogOperation
//

/// <reference path="LogOperation_ManagedObject.ts" />

enum LogOperationType 
{
    CreateObject,
    UpdateObject,
    DeleteObject,
    System,
    Movement
}

enum LogOperationSubtype 
{
    NoSubtype,
    CreateProductLine,
    CreateMenuProductLine,
    CreateMenuLine,
    CreateSeparatedMenuLine,
    CreateCustomLine,
    CreateOrder,
    CreateTicket,
    CreateCashDeskLine,
    CreateAccountLine,
    CreateDebtLine,
    DeleteTicket,
    DeleteTicketLine,
    MoveTicketLine,
    DeleteOrder,
    ErrorAlert,
    SystemUpdate,
    LocalSync,
    UserLogin,
    MergeOrder,
    ConvertChefLines,
    BossSync,
    CashDesk,
    DeleteData,
    License,
    Print,
    WallServer,
    MovementTicketLines,
    MovementOrder
}

enum LogLevelType 
{
    Log,
    Warning,
    Error
}

class LogOperation extends LogOperation_ManagedObject {
    private logOperationTypeStrings = ["CREATE OBJECT",
        "UPDATE OBJECT",
        "DELETE OBJECT",
        "SYSTEM",
        "MOVEMENT"
    ];

    get typeString(): string {
        let typeString = this.logOperationTypeStrings[this.type];
        return MIOLocalizeString(typeString, typeString);
    }

    private logOperationSubytypeStrings = ["",
        "CREATE PRODUCT LINE",
        "CREATE MENU PRODUCT LINE",
        "CREATE MENU LINE",
        "CREATE SEPARATED MENU LINE",
        "CREATE CUSTOM LINE",
        "CREATE ORDER",
        "CREATE TICKET",
        "CREATE CASHDESK LINE",
        "CREATE ACCOUNT LINE",
        "CREATE DEBT LINE",
        "DELETE TICKET",
        "DELETE TICKET LINE",
        "MOVE TICKET LINE",
        "DELETE ORDER",
        "ERROR ALERT",
        "SYSTEM UPDATE",
        "LOCAL SYNC",
        "USER LOGIN",
        "MERGE ORDER",
        "CONVERT CHEF LINES",
        "BOSS SYNC",
        "CASHDESK",
        "DELETE DATA",
        "LICENSE",
        "PRINT",
        "WALL SERVER",
        "MOVEMENT TICKET LINES",
        "MOVEMENT ORDER"
    ];

    get subTypeString(): string {
        let typeString = this.logOperationSubytypeStrings[this.subType];
        return MIOLocalizeString(typeString, typeString);
    }
}