//
// Generated class Tag
//

/// <reference path="Tag_ManagedObject.ts" />

enum TagType {
    None                 = 0,
    Ticket               = 1,
    Client               = 2,
    ProductOrMenu        = 3,
    PayMethod            = 4,
    LocationCategory     = 5,
    CashDesk             = 6,
    Worker               = 7,
    Supplier             = 8,
    Distributor          = 9,
    Invoice              = 10,
    LedgerAccount        = 11,
    BookingSource        = 12
}

class Tag extends Tag_ManagedObject
{
    static tagStringByType(type:TagType):string{
        switch (type) {
            case TagType.None: return MIOLocalizeString("NONE", "NONE");
            case TagType.Ticket: return MIOLocalizeString("RECEIPTS AND INVOICES", "RECEIPTS AND INVOICES");
            case TagType.Client: return MIOLocalizeString("CLIENTS", "CLIENTS");
            case TagType.ProductOrMenu: return MIOLocalizeString("PRODUCTS AND MENUS", "PRODUCTS AND MENUS");
            case TagType.PayMethod: return MIOLocalizeString("PAYMETHODS", "PAYMETHODS");
            case TagType.LocationCategory: return MIOLocalizeString("LOCATION CATEGORIES", "LOCATION CATEGORIES");
            case TagType.CashDesk: return MIOLocalizeString("CASHDESKS", "CASHDESKS");
            case TagType.Worker: return MIOLocalizeString("EMPLOYEES", "EMPLOYEES");
            case TagType.Supplier: return MIOLocalizeString("SUPPLIERS", "SUPPLIERS");
            case TagType.Distributor: return MIOLocalizeString("DISTRIBUTORS", "DISTRIBUTORS");
            case TagType.Invoice: return MIOLocalizeString("INVOICES", "INVOICES");
            case TagType.LedgerAccount: return MIOLocalizeString("LEDGER ACCOUNTS", "LEDGER ACCOUNTS");
            case TagType.BookingSource: return MIOLocalizeString("BOOKING SOURCE", "BOOKING SOURCE");
        }
    }

    static tagTypesCount():number {
        return Object.keys(TagType).length / 2;
    }

    typeString():string{
        return Tag.tagStringByType(this.type);
    }
}
