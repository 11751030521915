
interface DBToolFiltersViewControllerDelegate {
    didCloseDBToolFiltersViewController(controller:DBToolFiltersViewController, options);
}

class DBToolFiltersViewController extends MUIViewController
{    
    delegate:DBToolFiltersViewControllerDelegate = null;    
    filters = null;
    private keysAndValues = null;

    private closeButton:MUIButton = null;
    private doneButton:MUIButton = null;    
    private tableview:MUITableView = null;    

    viewDidLoad(){
        super.viewDidLoad();

        this.closeButton = MUIOutlet(this, 'close-btn', 'MUIButton'); 
        this.closeButton.setAction(this, function(){
            this.dismissViewController(true);
        });

        this.doneButton = MUIOutlet(this, "done-btn", "MUIButton");
        this.doneButton.setAction(this, function(){
            this.delegate.didCloseDBToolFiltersViewController(this, this.keysAndValues);
            this.dismissViewController(true);
        });
        
        this.tableview = MUIOutlet(this, 'table-view', 'MUITableView');
        this.tableview.dataSource = this;
        this.tableview.delegate = this;
    }

    viewWillAppear(){
        super.viewWillAppear();
        this.keysAndValues = {};
        this.tableview.reloadData();
    }

    numberOfSections(tableview){
        return 1;
    }
    
    numberOfRowsInSection(tableview, section){
        return this.filters.length;
    }
    
    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath){
        let filter = this.filters.objectAtIndex(indexPath.row) as DBFilter;

        let cellIdentifier = null;
        if (filter.dataType == DBFilterDataType.String || filter.dataType == DBFilterDataType.Integer || filter.dataType == DBFilterDataType.Decimal || filter.dataType == DBFilterDataType.Time){
            cellIdentifier = "ValueCell";
        }
        else if (filter.dataType == DBFilterDataType.Date || filter.dataType == DBFilterDataType.DateTime){
            cellIdentifier = "DateCell";
        }
        else {
            cellIdentifier = "BoolCell";
        }

        let cell = tableview.dequeueReusableCellWithIdentifier(cellIdentifier) as DBToolFilterCell;
        cell.setFilter(filter, this.keysAndValues);

        return cell;
    }

    get preferredContentSize(){
        return new MIOSize(320, 44 + (50 * this.filters.count));
    }
}