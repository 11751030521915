

// Generated class BookingPriceRule_ManagedObject

class BookingPriceRule_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: minPax
    set minPax(value:number) {
        this.setValueForKey(value, 'minPax');
    }
    get minPax():number {
        return this.valueForKey('minPax');
    }
    set minPaxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minPax');
    }
    get minPaxPrimitiveValue():number {
        return this.primitiveValueForKey('minPax');
    }

    // Property: maxPax
    set maxPax(value:number) {
        this.setValueForKey(value, 'maxPax');
    }
    get maxPax():number {
        return this.valueForKey('maxPax');
    }
    set maxPaxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'maxPax');
    }
    get maxPaxPrimitiveValue():number {
        return this.primitiveValueForKey('maxPax');
    }

    // Property: priceType
    set priceType(value:number) {
        this.setValueForKey(value, 'priceType');
    }
    get priceType():number {
        return this.valueForKey('priceType');
    }
    set priceTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'priceType');
    }
    get priceTypePrimitiveValue():number {
        return this.primitiveValueForKey('priceType');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: priceAmountToPay
    set priceAmountToPay(value:string) {
        this.setValueForKey(value, 'priceAmountToPay');
    }
    get priceAmountToPay():string {
        return this.valueForKey('priceAmountToPay');
    }
    set priceAmountToPayPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'priceAmountToPay');
    }
    get priceAmountToPayPrimitiveValue():string {
        return this.primitiveValueForKey('priceAmountToPay');
    }

    // Property: minSpendType
    set minSpendType(value:number) {
        this.setValueForKey(value, 'minSpendType');
    }
    get minSpendType():number {
        return this.valueForKey('minSpendType');
    }
    set minSpendTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minSpendType');
    }
    get minSpendTypePrimitiveValue():number {
        return this.primitiveValueForKey('minSpendType');
    }

    // Property: minSpend
    set minSpend(value:number) {
        this.setValueForKey(value, 'minSpend');
    }
    get minSpend():number {
        return this.valueForKey('minSpend');
    }
    set minSpendPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minSpend');
    }
    get minSpendPrimitiveValue():number {
        return this.primitiveValueForKey('minSpend');
    }

    // Property: minSpendAmountToPay
    set minSpendAmountToPay(value:string) {
        this.setValueForKey(value, 'minSpendAmountToPay');
    }
    get minSpendAmountToPay():string {
        return this.valueForKey('minSpendAmountToPay');
    }
    set minSpendAmountToPayPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'minSpendAmountToPay');
    }
    get minSpendAmountToPayPrimitiveValue():string {
        return this.primitiveValueForKey('minSpendAmountToPay');
    }

    // Property: presetID
    set presetID(value:number) {
        this.setValueForKey(value, 'presetID');
    }
    get presetID():number {
        return this.valueForKey('presetID');
    }
    set presetIDPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'presetID');
    }
    get presetIDPrimitiveValue():number {
        return this.primitiveValueForKey('presetID');
    }

    // Property: presetName
    set presetName(value:string) {
        this.setValueForKey(value, 'presetName');
    }
    get presetName():string {
        return this.valueForKey('presetName');
    }
    set presetNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'presetName');
    }
    get presetNamePrimitiveValue():string {
        return this.primitiveValueForKey('presetName');
    }

    // Property: channelID
    set channelID(value:number) {
        this.setValueForKey(value, 'channelID');
    }
    get channelID():number {
        return this.valueForKey('channelID');
    }
    set channelIDPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'channelID');
    }
    get channelIDPrimitiveValue():number {
        return this.primitiveValueForKey('channelID');
    }

    // Property: channelName
    set channelName(value:string) {
        this.setValueForKey(value, 'channelName');
    }
    get channelName():string {
        return this.valueForKey('channelName');
    }
    set channelNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'channelName');
    }
    get channelNamePrimitiveValue():string {
        return this.primitiveValueForKey('channelName');
    }

    // Property: eventID
    set eventID(value:string) {
        this.setValueForKey(value, 'eventID');
    }
    get eventID():string {
        return this.valueForKey('eventID');
    }
    set eventIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'eventID');
    }
    get eventIDPrimitiveValue():string {
        return this.primitiveValueForKey('eventID');
    }

    // Property: partyID
    set partyID(value:string) {
        this.setValueForKey(value, 'partyID');
    }
    get partyID():string {
        return this.valueForKey('partyID');
    }
    set partyIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'partyID');
    }
    get partyIDPrimitiveValue():string {
        return this.primitiveValueForKey('partyID');
    }

    // Property: createdAt
    set createdAt(value:Date) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():Date {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():Date {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:Date) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():Date {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:Date) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():Date {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: zone
    set zone(value:BookingZone) {
        this.setValueForKey(value, 'zone');
    }
    get zone():BookingZone {
        return this.valueForKey('zone') as BookingZone;
    }
}
