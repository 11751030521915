//
// Generated class LeaveReasonType
//

/// <reference path="LeaveReasonType_ManagedObject.ts" />

class LeaveReasonType extends LeaveReasonType_ManagedObject
{

}
