

/// <reference path="AccountLine.ts" />

// Generated class EconomicAccountLine_ManagedObject

class EconomicAccountLine_ManagedObject extends AccountLine
{

    // Property: currencyIdentifier
    set currencyIdentifier(value:string) {
        this.setValueForKey(value, 'currencyIdentifier');
    }
    get currencyIdentifier():string {
        return this.valueForKey('currencyIdentifier');
    }
    set currencyIdentifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'currencyIdentifier');
    }
    get currencyIdentifierPrimitiveValue():string {
        return this.primitiveValueForKey('currencyIdentifier');
    }

    // Property: currencyName
    set currencyName(value:string) {
        this.setValueForKey(value, 'currencyName');
    }
    get currencyName():string {
        return this.valueForKey('currencyName');
    }
    set currencyNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'currencyName');
    }
    get currencyNamePrimitiveValue():string {
        return this.primitiveValueForKey('currencyName');
    }

    // Property: invoiceNumber
    set invoiceNumber(value:string) {
        this.setValueForKey(value, 'invoiceNumber');
    }
    get invoiceNumber():string {
        return this.valueForKey('invoiceNumber');
    }
    set invoiceNumberPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invoiceNumber');
    }
    get invoiceNumberPrimitiveValue():string {
        return this.primitiveValueForKey('invoiceNumber');
    }

    // Property: payMethodImageName
    set payMethodImageName(value:string) {
        this.setValueForKey(value, 'payMethodImageName');
    }
    get payMethodImageName():string {
        return this.valueForKey('payMethodImageName');
    }
    set payMethodImageNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'payMethodImageName');
    }
    get payMethodImageNamePrimitiveValue():string {
        return this.primitiveValueForKey('payMethodImageName');
    }

    // Property: payMethodName
    set payMethodName(value:string) {
        this.setValueForKey(value, 'payMethodName');
    }
    get payMethodName():string {
        return this.valueForKey('payMethodName');
    }
    set payMethodNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'payMethodName');
    }
    get payMethodNamePrimitiveValue():string {
        return this.primitiveValueForKey('payMethodName');
    }

    // Property: valueOtherCurrency
    set valueOtherCurrency(value:number) {
        this.setValueForKey(value, 'valueOtherCurrency');
    }
    get valueOtherCurrency():number {
        return this.valueForKey('valueOtherCurrency');
    }
    set valueOtherCurrencyPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'valueOtherCurrency');
    }
    get valueOtherCurrencyPrimitiveValue():number {
        return this.primitiveValueForKey('valueOtherCurrency');
    }
    // Relationship: currency
    set currency(value:CashDeskCurrency) {
        this.setValueForKey(value, 'currency');
    }
    get currency():CashDeskCurrency {
        return this.valueForKey('currency') as CashDeskCurrency;
    }
    // Relationship: economicEntity
    set economicEntity(value:EconomicEntity) {
        this.setValueForKey(value, 'economicEntity');
    }
    get economicEntity():EconomicEntity {
        return this.valueForKey('economicEntity') as EconomicEntity;
    }
    // Relationship: invoice
    set invoice(value:ArchivedDocument) {
        this.setValueForKey(value, 'invoice');
    }
    get invoice():ArchivedDocument {
        return this.valueForKey('invoice') as ArchivedDocument;
    }
    // Relationship: invoiceTax
    set invoiceTax(value:Tax) {
        this.setValueForKey(value, 'invoiceTax');
    }
    get invoiceTax():Tax {
        return this.valueForKey('invoiceTax') as Tax;
    }
    // Relationship: paymentTransaction
    set paymentTransaction(value:PaymentTransaction) {
        this.setValueForKey(value, 'paymentTransaction');
    }
    get paymentTransaction():PaymentTransaction {
        return this.valueForKey('paymentTransaction') as PaymentTransaction;
    }
    // Relationship: payMethod
    set payMethod(value:PayMethod) {
        this.setValueForKey(value, 'payMethod');
    }
    get payMethod():PayMethod {
        return this.valueForKey('payMethod') as PayMethod;
    }
}
