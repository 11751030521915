//
// Generated class LicenseInvoice
//

/// <reference path="LicenseInvoice_ManagedObject.ts" />

class LicenseInvoice extends LicenseInvoice_ManagedObject
{

}
