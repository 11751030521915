
class OutputNoteListViewController extends NoteListViewController
{    
    protected get sectionControllerID():string {
        return SectionViewControllerType.OutputNote;
    }

    protected noteStatusForSegmentedIndex(index){
        if (index == 1) return StockNoteStatus.Processed;        
        return StockNoteStatus.None;
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('OutputNoteCell') as OutputNoteCell;    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);    
        
        cell.item = item;
        cell.selected = this.selectedNote === item ? true : false;

        return cell;
    }
            
    set fetchedResultsController(value){
        this.setFetchedResultsController(value);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("documentID", false)];
        if (this.selectedStatus == StockNoteStatus.Processed) {
            sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("creationDate", false)];
        }                                                            
                        
        let predicateFormat = this.selectedStatus != 0 ? "status == " + this.selectedStatus : "status == 0 OR status == 3";
        predicateFormat += " AND placeID == '" + ad.selectedIdentifier + "'";
        if(this.searchString != null) {
            predicateFormat += " AND documentID CONTAINS '" + this.searchString + "'";
		}
		
		if (this.filterPredicateFormat != null) {
            predicateFormat += " AND " + this.filterPredicateFormat;
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("CustomOutputNote", sortDescriptors, predicateFormat);
        fetchRequest.fetchLimit = 50;
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
		
	private _filters = null;
    get filters(){
        if (this._filters != null) return this._filters;

		this._filters = [FilterOption.filterWithTitle("FromDate", "From day", "validationDate", FilterFieldType.Date, null, FilterControllerComparatorType.MajorOrEqualComparator, null, "00:00:00"),
		FilterOption.filterWithTitle("ToDate", "To day", "validationDate", FilterFieldType.Date, null, FilterControllerComparatorType.MinorOrEqualComparator, null, "23:59:59"),
		FilterOption.filterWithFetchRequest("DestinationConcept", "Concept", "destinationConcept.identifier", FilterFieldType.DropDown, "StockCustomConcept", "name", "identifier", MIOLocalizeString("SELECT CONCEPT","Select Concept"))];

        return this._filters;
    }

    private outputDetailViewController:OutputNoteDetailViewController = null;
    protected showSelectedItem(){
        if(this.outputDetailViewController == null) {
            this.outputDetailViewController = new OutputNoteDetailViewController('output-note-detail-view');
            this.outputDetailViewController.initWithResource('layout/outputnote/OutputNoteDetailView.html');   
        }
        
        this.outputDetailViewController.note = this.selectedNote;
        this.splitViewController.showDetailViewController(this.outputDetailViewController);
    }

    protected showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("OutputNote", NoItemSelectedViewControllerType.OutputNote);
        this.splitViewController.showDetailViewController(vc);
    }

    protected showAddNoteViewController(){

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('ADD OUTPUT NOTE', 'ADD OUTPUT NOTE'), MIOLocalizeString('CHOOSE A ORIGIN WAREHOUSE AND CONCEPT', 'CHOOSE A ORIGIN WAREHOUSE AND CONCEPT'), MUIAlertViewStyle.Default);
        
        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
        
            DBHelper.sharedInstance().addObserverForEntity(comboBox, 'Warehouse', null, null, null, function (objects:Warehouse[]) {
                comboBox.removeAllItems();
                for (let i = 0; i < objects.length; i++)
                    comboBox.addItem(objects[i].name, i);
            });
        });
        
        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
             
            DBHelper.sharedInstance().addObserverForEntity(comboBox, 'StockCustomConcept', null, null, null, function (objects:StockCustomConcept[]) {
                comboBox.removeAllItems();                
                for (let i = 0; i < objects.length; i++)
                    comboBox.addItem(objects[i].name, i);
            });
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("DONE", "DONE"), MUIAlertActionStyle.Default, this, function(this:OutputNoteListViewController){
            let moc: MIOManagedObjectContext = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

            let originWarehouseComboBox:MUIComboBox = avc.comboBoxes[0];
            let originWarehouse = originWarehouseComboBox.getSelectedItem();

            let destinationComboBox:MUIComboBox = avc.comboBoxes[1];        
            let destinationIndex = destinationComboBox.getSelectedItem();
            
            let warehouse = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc.comboBoxes[0], originWarehouse, 'Warehouse');
            let destination = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc.comboBoxes[1], destinationIndex, 'StockCustomConcept');
            this.addOutputNote(warehouse, destination);
            
        }));
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));
        
        avc.addCompletionHandler(this, function (this:OutputNoteListViewController) {
            // Release the observers
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc.comboBoxes[0], 'Warehouse');
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc.comboBoxes[1], 'StockCustomConcept');
        });
        this.presentViewController(avc, true);
    }

    private addOutputNote(originWarehouse:Warehouse, destination:StockCustomConcept){
        DBHelper.createOutputNote(originWarehouse, destination, null, (note:SupplierOrder) => {
            this.selectedNote = note;
            DBHelper.saveMainContext();
            this.showSelectedItem();            
        });
   }

}