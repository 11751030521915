

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Ticket_ManagedObject

class Ticket_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: cachedPrices
    set cachedPrices(value:any) {
        this.setValueForKey(value, 'cachedPrices');
    }
    get cachedPrices():any {
        return this.valueForKey('cachedPrices');
    }
    set cachedPricesPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'cachedPrices');
    }
    get cachedPricesPrimitiveValue():any {
        return this.primitiveValueForKey('cachedPrices');
    }

    // Property: closeDate
    set closeDate(value:Date) {
        this.setValueForKey(value, 'closeDate');
    }
    get closeDate():Date {
        return this.valueForKey('closeDate');
    }
    set closeDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'closeDate');
    }
    get closeDatePrimitiveValue():Date {
        return this.primitiveValueForKey('closeDate');
    }

    // Property: closeWorkerID
    set closeWorkerID(value:string) {
        this.setValueForKey(value, 'closeWorkerID');
    }
    get closeWorkerID():string {
        return this.valueForKey('closeWorkerID');
    }
    set closeWorkerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'closeWorkerID');
    }
    get closeWorkerIDPrimitiveValue():string {
        return this.primitiveValueForKey('closeWorkerID');
    }

    // Property: coverAllergens
    set coverAllergens(value:any) {
        this.setValueForKey(value, 'coverAllergens');
    }
    get coverAllergens():any {
        return this.valueForKey('coverAllergens');
    }
    set coverAllergensPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'coverAllergens');
    }
    get coverAllergensPrimitiveValue():any {
        return this.primitiveValueForKey('coverAllergens');
    }

    // Property: coverComments
    set coverComments(value:any) {
        this.setValueForKey(value, 'coverComments');
    }
    get coverComments():any {
        return this.valueForKey('coverComments');
    }
    set coverCommentsPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'coverComments');
    }
    get coverCommentsPrimitiveValue():any {
        return this.primitiveValueForKey('coverComments');
    }

    // Property: coverGenders
    set coverGenders(value:any) {
        this.setValueForKey(value, 'coverGenders');
    }
    get coverGenders():any {
        return this.valueForKey('coverGenders');
    }
    set coverGendersPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'coverGenders');
    }
    get coverGendersPrimitiveValue():any {
        return this.primitiveValueForKey('coverGenders');
    }

    // Property: coverStrings
    set coverStrings(value:any) {
        this.setValueForKey(value, 'coverStrings');
    }
    get coverStrings():any {
        return this.valueForKey('coverStrings');
    }
    set coverStringsPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'coverStrings');
    }
    get coverStringsPrimitiveValue():any {
        return this.primitiveValueForKey('coverStrings');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: debtID
    set debtID(value:string) {
        this.setValueForKey(value, 'debtID');
    }
    get debtID():string {
        return this.valueForKey('debtID');
    }
    set debtIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'debtID');
    }
    get debtIDPrimitiveValue():string {
        return this.primitiveValueForKey('debtID');
    }

    // Property: doInvoice
    set doInvoice(value:boolean) {
        this.setValueForKey(value, 'doInvoice');
    }
    get doInvoice():boolean {
        return this.valueForKey('doInvoice');
    }
    set doInvoicePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'doInvoice');
    }
    get doInvoicePrimitiveValue():boolean {
        return this.primitiveValueForKey('doInvoice');
    }

    // Property: hotelReservationID
    set hotelReservationID(value:string) {
        this.setValueForKey(value, 'hotelReservationID');
    }
    get hotelReservationID():string {
        return this.valueForKey('hotelReservationID');
    }
    set hotelReservationIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'hotelReservationID');
    }
    get hotelReservationIDPrimitiveValue():string {
        return this.primitiveValueForKey('hotelReservationID');
    }

    // Property: isBlocked
    set isBlocked(value:boolean) {
        this.setValueForKey(value, 'isBlocked');
    }
    get isBlocked():boolean {
        return this.valueForKey('isBlocked');
    }
    set isBlockedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isBlocked');
    }
    get isBlockedPrimitiveValue():boolean {
        return this.primitiveValueForKey('isBlocked');
    }

    // Property: isCustom
    set isCustom(value:boolean) {
        this.setValueForKey(value, 'isCustom');
    }
    get isCustom():boolean {
        return this.valueForKey('isCustom');
    }
    set isCustomPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isCustom');
    }
    get isCustomPrimitiveValue():boolean {
        return this.primitiveValueForKey('isCustom');
    }

    // Property: isDebt
    set isDebt(value:boolean) {
        this.setValueForKey(value, 'isDebt');
    }
    get isDebt():boolean {
        return this.valueForKey('isDebt');
    }
    set isDebtPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isDebt');
    }
    get isDebtPrimitiveValue():boolean {
        return this.primitiveValueForKey('isDebt');
    }

    // Property: isParked
    set isParked(value:boolean) {
        this.setValueForKey(value, 'isParked');
    }
    get isParked():boolean {
        return this.valueForKey('isParked');
    }
    set isParkedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isParked');
    }
    get isParkedPrimitiveValue():boolean {
        return this.primitiveValueForKey('isParked');
    }

    // Property: lastPrintDate
    set lastPrintDate(value:Date) {
        this.setValueForKey(value, 'lastPrintDate');
    }
    get lastPrintDate():Date {
        return this.valueForKey('lastPrintDate');
    }
    set lastPrintDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'lastPrintDate');
    }
    get lastPrintDatePrimitiveValue():Date {
        return this.primitiveValueForKey('lastPrintDate');
    }

    // Property: legalDocumentID
    set legalDocumentID(value:string) {
        this.setValueForKey(value, 'legalDocumentID');
    }
    get legalDocumentID():string {
        return this.valueForKey('legalDocumentID');
    }
    set legalDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalDocumentID');
    }
    get legalDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('legalDocumentID');
    }

    // Property: legalGovernmentReference
    set legalGovernmentReference(value:string) {
        this.setValueForKey(value, 'legalGovernmentReference');
    }
    get legalGovernmentReference():string {
        return this.valueForKey('legalGovernmentReference');
    }
    set legalGovernmentReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalGovernmentReference');
    }
    get legalGovernmentReferencePrimitiveValue():string {
        return this.primitiveValueForKey('legalGovernmentReference');
    }

    // Property: legalGovernmentURL
    set legalGovernmentURL(value:string) {
        this.setValueForKey(value, 'legalGovernmentURL');
    }
    get legalGovernmentURL():string {
        return this.valueForKey('legalGovernmentURL');
    }
    set legalGovernmentURLPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalGovernmentURL');
    }
    get legalGovernmentURLPrimitiveValue():string {
        return this.primitiveValueForKey('legalGovernmentURL');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: numberOfPeople
    set numberOfPeople(value:number) {
        this.setValueForKey(value, 'numberOfPeople');
    }
    get numberOfPeople():number {
        return this.valueForKey('numberOfPeople');
    }
    set numberOfPeoplePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'numberOfPeople');
    }
    get numberOfPeoplePrimitiveValue():number {
        return this.primitiveValueForKey('numberOfPeople');
    }

    // Property: numberSeriesID
    set numberSeriesID(value:string) {
        this.setValueForKey(value, 'numberSeriesID');
    }
    get numberSeriesID():string {
        return this.valueForKey('numberSeriesID');
    }
    set numberSeriesIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'numberSeriesID');
    }
    get numberSeriesIDPrimitiveValue():string {
        return this.primitiveValueForKey('numberSeriesID');
    }

    // Property: numberSeriesPayMethodID
    set numberSeriesPayMethodID(value:string) {
        this.setValueForKey(value, 'numberSeriesPayMethodID');
    }
    get numberSeriesPayMethodID():string {
        return this.valueForKey('numberSeriesPayMethodID');
    }
    set numberSeriesPayMethodIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'numberSeriesPayMethodID');
    }
    get numberSeriesPayMethodIDPrimitiveValue():string {
        return this.primitiveValueForKey('numberSeriesPayMethodID');
    }

    // Property: onlinePlatformFeePercentage
    set onlinePlatformFeePercentage(value:number) {
        this.setValueForKey(value, 'onlinePlatformFeePercentage');
    }
    get onlinePlatformFeePercentage():number {
        return this.valueForKey('onlinePlatformFeePercentage');
    }
    set onlinePlatformFeePercentagePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'onlinePlatformFeePercentage');
    }
    get onlinePlatformFeePercentagePrimitiveValue():number {
        return this.primitiveValueForKey('onlinePlatformFeePercentage');
    }

    // Property: onlinePlatformID
    set onlinePlatformID(value:string) {
        this.setValueForKey(value, 'onlinePlatformID');
    }
    get onlinePlatformID():string {
        return this.valueForKey('onlinePlatformID');
    }
    set onlinePlatformIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'onlinePlatformID');
    }
    get onlinePlatformIDPrimitiveValue():string {
        return this.primitiveValueForKey('onlinePlatformID');
    }

    // Property: onlinePlatformName
    set onlinePlatformName(value:string) {
        this.setValueForKey(value, 'onlinePlatformName');
    }
    get onlinePlatformName():string {
        return this.valueForKey('onlinePlatformName');
    }
    set onlinePlatformNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'onlinePlatformName');
    }
    get onlinePlatformNamePrimitiveValue():string {
        return this.primitiveValueForKey('onlinePlatformName');
    }

    // Property: onlinePlatformNumberSeriesID
    set onlinePlatformNumberSeriesID(value:string) {
        this.setValueForKey(value, 'onlinePlatformNumberSeriesID');
    }
    get onlinePlatformNumberSeriesID():string {
        return this.valueForKey('onlinePlatformNumberSeriesID');
    }
    set onlinePlatformNumberSeriesIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'onlinePlatformNumberSeriesID');
    }
    get onlinePlatformNumberSeriesIDPrimitiveValue():string {
        return this.primitiveValueForKey('onlinePlatformNumberSeriesID');
    }

    // Property: onlinePlatformPenalty
    set onlinePlatformPenalty(value:number) {
        this.setValueForKey(value, 'onlinePlatformPenalty');
    }
    get onlinePlatformPenalty():number {
        return this.valueForKey('onlinePlatformPenalty');
    }
    set onlinePlatformPenaltyPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'onlinePlatformPenalty');
    }
    get onlinePlatformPenaltyPrimitiveValue():number {
        return this.primitiveValueForKey('onlinePlatformPenalty');
    }

    // Property: openWorkerID
    set openWorkerID(value:string) {
        this.setValueForKey(value, 'openWorkerID');
    }
    get openWorkerID():string {
        return this.valueForKey('openWorkerID');
    }
    set openWorkerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'openWorkerID');
    }
    get openWorkerIDPrimitiveValue():string {
        return this.primitiveValueForKey('openWorkerID');
    }

    // Property: openWorkerName
    set openWorkerName(value:string) {
        this.setValueForKey(value, 'openWorkerName');
    }
    get openWorkerName():string {
        return this.valueForKey('openWorkerName');
    }
    set openWorkerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'openWorkerName');
    }
    get openWorkerNamePrimitiveValue():string {
        return this.primitiveValueForKey('openWorkerName');
    }

    // Property: payedMoney
    set payedMoney(value:number) {
        this.setValueForKey(value, 'payedMoney');
    }
    get payedMoney():number {
        return this.valueForKey('payedMoney');
    }
    set payedMoneyPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'payedMoney');
    }
    get payedMoneyPrimitiveValue():number {
        return this.primitiveValueForKey('payedMoney');
    }

    // Property: payedTip
    set payedTip(value:number) {
        this.setValueForKey(value, 'payedTip');
    }
    get payedTip():number {
        return this.valueForKey('payedTip');
    }
    set payedTipPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'payedTip');
    }
    get payedTipPrimitiveValue():number {
        return this.primitiveValueForKey('payedTip');
    }

    // Property: recoveredFromDocumentID
    set recoveredFromDocumentID(value:string) {
        this.setValueForKey(value, 'recoveredFromDocumentID');
    }
    get recoveredFromDocumentID():string {
        return this.valueForKey('recoveredFromDocumentID');
    }
    set recoveredFromDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'recoveredFromDocumentID');
    }
    get recoveredFromDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('recoveredFromDocumentID');
    }

    // Property: roomName
    set roomName(value:string) {
        this.setValueForKey(value, 'roomName');
    }
    get roomName():string {
        return this.valueForKey('roomName');
    }
    set roomNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'roomName');
    }
    get roomNamePrimitiveValue():string {
        return this.primitiveValueForKey('roomName');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: ticketID
    set ticketID(value:string) {
        this.setValueForKey(value, 'ticketID');
    }
    get ticketID():string {
        return this.valueForKey('ticketID');
    }
    set ticketIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ticketID');
    }
    get ticketIDPrimitiveValue():string {
        return this.primitiveValueForKey('ticketID');
    }

    // Property: timesPrinted
    set timesPrinted(value:number) {
        this.setValueForKey(value, 'timesPrinted');
    }
    get timesPrinted():number {
        return this.valueForKey('timesPrinted');
    }
    set timesPrintedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'timesPrinted');
    }
    get timesPrintedPrimitiveValue():number {
        return this.primitiveValueForKey('timesPrinted');
    }

    // Property: transformDocumentID
    set transformDocumentID(value:string) {
        this.setValueForKey(value, 'transformDocumentID');
    }
    get transformDocumentID():string {
        return this.valueForKey('transformDocumentID');
    }
    set transformDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'transformDocumentID');
    }
    get transformDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('transformDocumentID');
    }

    // Property: vipPoints
    set vipPoints(value:number) {
        this.setValueForKey(value, 'vipPoints');
    }
    get vipPoints():number {
        return this.valueForKey('vipPoints');
    }
    set vipPointsPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'vipPoints');
    }
    get vipPointsPrimitiveValue():number {
        return this.primitiveValueForKey('vipPoints');
    }
    // Relationship: invoiceLegalEntity
    set invoiceLegalEntity(value:LegalEntity) {
        this.setValueForKey(value, 'invoiceLegalEntity');
    }
    get invoiceLegalEntity():LegalEntity {
        return this.valueForKey('invoiceLegalEntity') as LegalEntity;
    }

    // Relationship: lines
    protected _lines:MIOManagedObjectSet = null;
    get lines():MIOManagedObjectSet {
        return this.valueForKey('lines');
    }
    addLinesObject(value:TicketLine) {
        this._addObjectForKey(value, 'lines');
    }
    removeLinesObject(value:TicketLine) {
        this._removeObjectForKey(value, 'lines');
    }
    // Relationship: linkedEconomicEntity
    set linkedEconomicEntity(value:EconomicEntity) {
        this.setValueForKey(value, 'linkedEconomicEntity');
    }
    get linkedEconomicEntity():EconomicEntity {
        return this.valueForKey('linkedEconomicEntity') as EconomicEntity;
    }
    // Relationship: order
    set order(value:Order) {
        this.setValueForKey(value, 'order');
    }
    get order():Order {
        return this.valueForKey('order') as Order;
    }
    // Relationship: saleSource
    set saleSource(value:SaleSource) {
        this.setValueForKey(value, 'saleSource');
    }
    get saleSource():SaleSource {
        return this.valueForKey('saleSource') as SaleSource;
    }
    // Relationship: tip
    set tip(value:Tip) {
        this.setValueForKey(value, 'tip');
    }
    get tip():Tip {
        return this.valueForKey('tip') as Tip;
    }
}
