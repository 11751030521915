interface TableMapViewDataSource
{
    viewForBackground(tableMapView:TableMapView):MUIView;
    numberOfTables(tableMapView:TableMapView):number;
    viewAtIndex(tableMapView:TableMapView, index:number):MUIView;
}

class TableMapView extends MUIView
{
    dataSource:TableMapViewDataSource = null;

    private backgroundView:MUIView = null;    
    private locations:MUIView[] = [];

    initWithLayer(layer, owner, options?) {
        super.initWithLayer(layer, owner, options);

        // Check if we have prototypes
        if (this.layer.childNodes.length > 0) {
            for (let index = 0; index < this.layer.childNodes.length; index++) {
                let subLayer = this.layer.childNodes[index];

                if (subLayer.tagName != "DIV")
                    continue;

                if (subLayer.getAttribute("data-item-identifier") != null) {
                    this._addCellPrototypeWithLayer(subLayer, owner);
                    subLayer.style.display = "none";
                }
            }
        }
    }

    private _cellPrototypes = {};
    private _addCellPrototypeWithLayer(subLayer, owner) {
        let cellIdentifier = subLayer.getAttribute("data-item-identifier");
        let cellClassname = subLayer.getAttribute("data-class");
        if (cellClassname == null) cellClassname = "TableMapItem";

        subLayer.style.position = "absolute";
        let item = {};        
        item["class"] = cellClassname;
        item["layer"] = subLayer;
        let size = new MIOSize(subLayer.clientWidth, subLayer.clientHeight);
        if (size != null) item["size"] = size;
        // var bg = window.getComputedStyle( subLayer ,null).getPropertyValue('background-color');
        // if (bg != null) item["bg"] = bg;

        this._cellPrototypes[cellIdentifier] = item;
    }

    dequeueReusableItemWithIdentifier(identifier:string): TableMapItem {

        let cell: TableMapItem = null;

        let item = this._cellPrototypes[identifier];

        //instance creation here
        let className = item["class"];
        cell = MIOClassFromString(className);

        let layer = item["layer"];
        if (layer != null) {
            let newLayer = layer.cloneNode(true);
            newLayer.style.display = "";
            cell.initWithLayer(newLayer, this);
            cell.awakeFromHTML();
        }

        // cell.addObserver(this, "selected");
        return cell;
    }

    reloadData(){

        // Remove all views
        if (this.backgroundView != null) this.backgroundView.removeFromSuperview();
        for (let v of this.locations) v.removeFromSuperview();

        // Reload Data
        let bv:MUIView = null;
        if (typeof this.dataSource.viewForBackground === "function") bv = this.dataSource.viewForBackground(this);
        if (bv != null) {
            this.addSubview(bv);
            this.backgroundView = bv;
        }

        let tablesCount = this.dataSource.numberOfTables(this);
        for (let index = 0; index < tablesCount; index++){
            let lv = this.dataSource.viewAtIndex(this, index);
            this.addSubview(lv);
            this.locations.addObject(lv);
        }
    }


}