


class PlaceSettingIntervalsCell extends MUITableViewCell
{
    private addButton:MUIButton = null;
    
    // private _separator = null;
    private _item = null;
    awakeFromHTML()
    {
        this.addButton = MUIOutlet(this, 'psv_add_btn', 'MUIButton');
        this.addButton.setAction(this, function()
        {
           alert(MIOLocalizeString('ADD','Add'));
        });
        // this._separator = MUIOutlet(this, 'psv_intervals_separator', 'MUIView');
    }

    set item(i)
    {
        this._item = i;
    }
}
