//
// Generated class DLEvent
//

/// <reference path="DLEvent_ManagedObject.ts" />

class DLEvent extends DLEvent_ManagedObject
{
    private sdf = MUIWebApplication.sharedInstance().delegate.serverDateTimeFormatter;
    
        // Property: inventory note  date    
        /*private _dateString:string = null;
        private _date:Date = null;
        set date(value:Date) {
            this.setValue('_date', value);
            this._dateString = this.sdf.stringFromDate(value); 
        } 
        get date():Date {
            return this.getValue('_date');
        } 
    */


    //     setObject(object)
    //     {
    //         this.identifier = object['id'];
    //         this.name = object['name'];
    //         this.date = this.sdf.dateFromString(object["date"]);
    //         this.price = object['price'];
    //         this.description = object['description'];
    
    //        // this.updated_at = object['updatedAt'];
    //        // this.enddate = object['enddate'];
    //       //  this.active = object['active'];
    //        // let instance = this;
    //     }
    
    //     getObject()
    //     {
    //         var obj = {};
            
            
    //         obj['id'] = this.identifier ? this.identifier :  MIOUUID.UUID().UUIDString;
            
    //         obj['name'] = this.name;
    //         obj['date'] = this.sdf.stringFromDate(this.date);
    //         obj['price']= this.price;
    //         obj['description']= this.description;
    
    //         return obj;
    //    }

    get dateString(){
        return this.sdf.stringFromDate(this.date); 
    }
}
