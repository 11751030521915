//
// Generated class SupportTicketLog
//

/// <reference path="SupportTicketLog_ManagedObject.ts" />

class SupportTicketLog extends SupportTicketLog_ManagedObject
{

}
