class ReportFilterCell extends UITableViewCell
{
    delegate:any = null;

    protected titleLabel:MUILabel = null;

    awakeFromHTML(): void {
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
    }

    set filter(filter:any) {
        this.setFilter(filter);
    }

    protected filterItem:any = null;
    setFilter(filter:any) {
        this.filterItem = filter;
        this.titleLabel.text = MIOLocalizeString(filter["Title"],filter["Title"]); //all variables found in filter[] should be translated
    }
}