class ReportScriptListViewController extends BaseTableViewController
{
    private addButton:MUIButton = null;    

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function(){
            this.showAddNewFileAlert();
        });

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.needsReloadData();
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("FileCell") as ReportScriptCell;        

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ReportScriptItem;
        cell.item = item;

        cell.selected = item == this.selectedItem ? true : false;

        return cell;
    }
             
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath) as ReportScriptItem;
        this.showDetailViewController();
    }

    private detailViewController:ReportScriptViewController = null;
    private showDetailViewController(){
        if (this.detailViewController == null) {
            this.detailViewController = new ReportScriptViewController("report-script-view");
            this.detailViewController.initWithResource("layout/reports/ReportScriptView.html");
        }

        this.detailViewController.item = this.selectedItem;
        this.splitViewController.showDetailViewController(this.detailViewController);
    }

    private showNoItemSelectedView(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("ReportBuilder", NoItemSelectedViewControllerType.ReportBuilder);
        this.splitViewController.showDetailViewController(vc);
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("ReportScriptItem");                    
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", false)];                                
                
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    private showAddNewFileAlert(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle("Add new file", "Write the name of the script file", MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.placeholderText = "File name";
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle("OK", MUIAlertActionStyle.Default, this, function(){
            let name = avc.textFields[0].text;
            this.addFile(name);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle("Cancel", MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private addFile(filename:string){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        
        let item = MIOEntityDescription.insertNewObjectForEntityForName("ReportScriptItem", ad.managedObjectContext) as ReportScriptItem;
        item.name = filename;
        item.type = ReportItemType.Script;
        item.content = "";

        this.selectedItem = item;
        DBHelper.saveMainContext();        

        this.showDetailViewController();
    }
}
