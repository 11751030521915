class WarehouseListCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    
    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, 'name-lbl', 'MUILabel');            
    }
    
    set item(i:Warehouse){
        this.nameLabel.text = i.name;
    }
}
