/**
 * Created by miguel on 23/2/17.
 */


class LapsopayLocationListViewController extends MUIViewController
{
    private _tableView = null;
    private _place = null;
    private _fetchedResultsController = null;
    private _saveButton = null;
    private _exportButton = null;
    private _importButton = null;

    private _hasChanges = false;
    private _spinningView = null;
    private _csv = {};

    viewDidLoad()
    {
        super.viewDidLoad();

        this._tableView = MUIOutlet(this, 'lpdv_tableview', 'MUITableView');
        this._tableView.dataSource = this;
        this._tableView.delegate = this;

        this._saveButton = MUIOutlet(this, 'lpdv_nb_save_btn', 'MUIButton');
        this._saveButton.setAction(this, function ()
        {
            var len = this.fetchedResultsController.sections[0].numberOfObjects();
            var saveObjects = [];
            for(var i = 0; i < len; i++){
                var item = this.fetchedResultsController.objectAtIndexPath(MIOIndexPath.indexForRowInSection(i, 0));
                if(item.hasChanges) {
                    saveObjects.push({id: item.identifier, lapsopayid: item.lapsopayId});
                }
            }
            if(saveObjects.length > 0){

                var ad = MUIWebApplication.sharedInstance().delegate;
                ad.webService.setLapsopayLocations(this._place, saveObjects, this, function(code, result) {
                    console.log('Error: '+ code);

                    if(code == 200){
                        //ad.managedObjectContext.save();
                        this._hasChanges = false;
                        this._saveButton.setHidden(!this._hasChanges);
                    }
                    else {
                        var avc = new MUIAlertViewController();
                        avc.initWithTitle(MIOLocalizeString('ERROR', 'Error'), MIOLocalizeString('ERROR SAVING CHANGES','Error saving changes '), MUIAlertViewStyle.Default);
                        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, this, function(){}));
                        
                        this.presentViewController(avc, true);
                        
                    }
                })
            }
            else {
                this._hasChanges = false;
                this._saveButton.setHidden(!this._hasChanges);
            }
        });

        this._saveButton.setHidden(true);

        this._importButton = MUIOutlet(this, 'lpdv_nb_import_btn','MUIButton');
        this._importButton.setAction(this, function()
        {
            let self = this;
            document.getElementById('import_file').addEventListener('change', (function(instance) {
                
                return function(e){
                    console.log('Load File');
                    var file = e.target.files[0];
                    if (!file) {
                        return;
                    }
                    var formData = new FormData();
                    formData.append('test','1');
                    formData.append("import_file", e.target.files[0]);
                    let ad = MUIWebApplication.sharedInstance().delegate;
                    let ws = ad.webService;
                    ws.importLapsopayLocations(instance._place, formData, this, function(code, json){
                       if(code == 200) 
                       {
                           instance._downloadPlaceLocations();
                       }
                       else{
                           var avc = new MUIAlertViewController();
                           avc.initWithTitle(MIOLocalizeString('ERROR', 'Error'), MIOLocalizeString('AN ERROR OCURRED WHILE LOADING FILE','An Error occurred while loading file'), MUIAlertViewStyle.Default);
                           avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, this, function()
                           {
                               
                           }));
                           
                           this.presentViewController(avc, true);
                       }

                    });
                }
            })(self), false);
            document.getElementById('import_file').click();
            
        });

        this._exportButton = MUIOutlet(this, 'lpdv_nb_export_btn','MUIButton');
        this._exportButton.setAction(this, function()
        {
           let ad = MUIWebApplication.sharedInstance().delegate;
           let ws = ad.webService;
           ws.exportLapsopayLocations(this._place , this, function(code, json){
              if(code == 200) 
              {
                
              }
           });

        });

        this._spinningView = MUIOutlet(this, "lpdv_spinning", "MUIActivityIndicatorView");
        //this._spinningView.setHidden(true);

        this._downloadPlaceLocations();
        
    }

    public processData(csv) {
        var allTextLines = csv.split(/\r\n|\n/);
        var lines = [];
        for (var i=0; i<allTextLines.length; i++) {
            var data = allTextLines[i].split(',');
                var tarr = [];
                for (var j=0; j<data.length; j++) {
                    tarr.push(data[j].substring(1, data[j].length-1));
                }
                lines.push(tarr);
        }
      console.log(lines);

      return lines;
    }

    private _errorHandler(evt) {
      if(evt.target.error.name == "NotReadableError") {
          alert(MIOLocalizeString("CANT READ FILE","Can't read file !"));
      }
    }

    public exportFile(e){
        
        var file = e.target.files[0];
        if (!file) {
            return;
        }
        console.log('Create File ' + file.name);
        
    }

    set place(value)
    {
        this._place = value;
        this._downloadPlaceLocations();
    }

    get hasChanges()
    {
        return this._hasChanges;
    }

    private _downloadPlaceLocations()
    {
        var ws = MUIWebApplication.sharedInstance().delegate.webService;
        this._fetchedResultsController = null;
        this._hasChanges = false;
        if(this._spinningView)
            this._spinningView.setHidden(false);
        ws.getLapsoPlaceLocations(this._place, this, function(error, result) {
            this._csv = {};
            this._tableView.reloadData();
            if(this._spinningView)
                this._spinningView.setHidden(true);
        });
    }
    
    numberOfSections(tableview)
    {
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section)
    {
        var sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    titleForHeaderInSection(tableview, section)
    {
        var item = this.fetchedResultsController.objectAtIndexPath(MIOIndexPath.indexForRowInSection(0,section));
        return item.locationName;
    }
    cellAtIndexPath(tableview, indexPath:MIOIndexPath)
    {
        var cell = tableview.dequeueReusableCellWithIdentifier("LapsopayLocationCell");
    
        var item = this.fetchedResultsController.objectAtIndexPath(indexPath);

        cell.row = indexPath.row;
        cell.section = indexPath.section;
        cell.delegate = this;

        if((Object.keys(this._csv).length > 0)) {
            item.lapsopayId = this._csv[item.name];
        }
        
        cell.item = item;
    
        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
    
        return cell;
    }
    
    canSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath)
    {
        return false;
    }
    
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath)
    {
        var obj = this.fetchedResultsController.objectAtIndexPath(indexPath);
     }
    
    set fetchedResultsController(value)
    {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController()
    {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        var ad = MUIWebApplication.sharedInstance().delegate;
    
        var fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("LapsopayLocation");
        
        fetchRequest.sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey("locationName", true),
                                        MIOSortDescriptor.sortDescriptorWithKey("name", true)];
    
            
                
       // if (this._searchString != null)
        fetchRequest.predicate = MIOPredicate.predicateWithFormat("place == " + this._place.identifier);
    
        var fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "locationName");
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller)
    {
        this._tableView.reloadData();
    }

    cellTextDidChange(cell, value)
    {
        var item = this.fetchedResultsController.objectAtIndexPath(MIOIndexPath.indexForRowInSection(cell.row, cell.section));
        item.lapsopayId = value;
        this._hasChanges = true;
        this._saveButton.setHidden(!this._hasChanges);
    }
}