

class ProductModifierDetailCell extends MUITableViewCell
{
    private imageView:MUIImageView = null;
    private nameLabel:MUILabel = null;
    private languageButton:MUIButton = null;
    private descTextField:MUITextField = null;
    private defaultSwitchButton:MUISwitchButton = null;
    private visibleSwitchButton:MUISwitchButton = null;
    // private modifiedPriceTF:MUITextField = null;
    private modifierPriceButton:MUIButton = null;
    private taxDropDown:MUIButton = null;
    private productLimitCountTextField:MUITextField = null;
    private lossTextField:MUITextField = null;
    private lossUnitComboBox:MUIComboBox = null;
    private totalConsumptionTextField:MUITextField = null;    
    private totalUnitComboBox:MUIComboBox = null;
    

    private ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter as MIONumberFormatter;
    private if = MUIWebApplication.sharedInstance().delegate.integerFormatter as MIONumberFormatter;

    awakeFromHTML()
    {
        this.imageView = MUIOutlet(this, "product-img", "MUIImageView");

        this.nameLabel = MUIOutlet(this, 'product-name-lbl', 'MUILabel');
        this.descTextField = MUIOutlet(this, "modifier-desc", "MUITextField");
        if (this.descTextField != null) {
            this.descTextField.setOnChangeText(this, function(this:ProductModifierDetailCell, control:MUITextField, value:string){
                this.productModifier.name = value;
            })
        }

        this.languageButton = MUIOutlet(this, "item-language-btn", "MUIButton");
        if (this.languageButton) {
            this.languageButton.setAction(this, function(){
                let vc = ProductLanguageViewController.newInstance();
                vc.setItem(this.productModifier, "name");
                AppHelper.sharedInstance().presentViewController( vc, true );
            });
        }

        this.defaultSwitchButton = MUIOutlet(this, "default-switch", "MUISwitchButton");
        this.defaultSwitchButton.setOnChangeValue(this, function(this:ProductModifierDetailCell, control:MUISwitchButton, value:boolean){
            this.productModifier.isDefault = value;
        });

        this.visibleSwitchButton = MUIOutlet(this, "visible-switch", "MUISwitchButton");
        this.visibleSwitchButton.setOnChangeValue(this, function(this:ProductModifierDetailCell, control:MUISwitchButton, value:boolean){
            this.productModifier.isVisible = value;
        });

        // this.modifiedPriceTF = MUIOutlet(this, "modify-price-tf", "MUITextField");
        // this.modifiedPriceTF.setOnDidEndEditing(this, function(control, value:string){

        //     let validatedString = this.validatePriceString(value);
        //     this.modifiedPriceTF.text = validatedString;
        //     this.productModifier.priceFormat = validatedString;

        // });

        this.modifierPriceButton = MUIOutlet(this, "price-dd", "MUIButton");
        this.modifierPriceButton.setAction(this, function(this:ProductModifierDetailCell){
            //price format pop-up
            this.showAddPriceFormatViewController(this.modifierPriceButton);
        });

        this.taxDropDown = MUIOutlet(this, 'tax-dd', 'MUIButton');
        this.taxDropDown.setAction(this, function() {
            //view popup for tax
            this.showTaxSelectEntityViewController(this.taxDropDown, "Tax");
        });

        this.productLimitCountTextField = MUIOutlet(this, "consumption-tf", "MUITextField");
        this.productLimitCountTextField.formatter = this.nf;
        this.productLimitCountTextField.setOnChangeText(this, function(this:ProductModifierDetailCell, control:MUITextField, value:number){
            this.productModifier.productModifierCount = value;
        });

        
        this.lossTextField = MUIOutlet(this, 'shrinkage-tf', 'MUITextField');
        if (this.lossTextField != null){
            this.lossTextField.formatter = this.nf;
            this.lossTextField.setOnChangeText(this, function(this:ProductModifierDetailCell, control:MUITextField, value:string){
                this.lossQuantityDidChange(value);
            });
        }

        this.lossUnitComboBox = MUIOutlet(this, "loss-consumption-cb", "MUIComboBox");
        if (this.lossUnitComboBox) this.lossUnitComboBox.setOnChangeAction(this, function(combobox, value){
            this.lossUnitDidChange(value);            
        });

        this.totalConsumptionTextField = MUIOutlet(this, 'product-consumption-tf', 'MUITextField');
        if (this.totalConsumptionTextField != null){
            this.totalConsumptionTextField.formatter = this.if;
            this.totalConsumptionTextField.setOnChangeText(this, function(this:ProductModifierDetailCell, control:MUITextField, value:string){
                this.totalQuantityDidChange(value);
            });
        }

        this.totalUnitComboBox = MUIOutlet(this, "total-consumption-cb", "MUIComboBox");
        if (this.totalUnitComboBox) this.totalUnitComboBox.setOnChangeAction(this, function(combobox, value){
            this.totalUnitDidChange(value);
        });

        this.separatorStyle = MUITableViewCellSeparatorStyle.None;
    }

    private productModifier:ProductModifier = null;
    set item(item:ProductModifier){
        this.productModifier = item;

        if (this.imageView) this.imageView.setImage(item.product?.image?.url);

        if (this.descTextField != null) this.descTextField.text = this.productModifier.name;
        if (this.nameLabel != null) this.nameLabel.text = this.productModifier.name;        

        // if(this.productModifier.product != null && this.imageView != null) {
            // this.imageView.setImage(this.productModifier.product.image)
        // }

        this.defaultSwitchButton.on = this.productModifier.isDefault;
        this.visibleSwitchButton.on =this.productModifier.isVisible;

        this.modifierPriceButton.title = this.productModifier.priceFormat;

        //place DB value in price text field (not validating)
        // this.modifiedPriceTF.text = this.productModifier.priceFormat;
        // if (this.productModifier.priceFormat) {
        //     this.modifierPriceButton.title = this.productModifier.priceFormat;
        // } else {
        //     this.modifierPriceButton.title = "";
        // }
        

        //modifier price, can be +2.00, -2.00, +2,00, -2,00, null, 0.00, +10%, -10%
        // let modifierPrice = this.productModifier.priceFormat;

        // //find if it is a percentage
        // var isPercentage:boolean = false;
        // if (modifierPrice){
        //     if ( modifierPrice.indexOf('%') >= 0 ) {
        //         isPercentage = true;
        //         modifierPrice = modifierPrice.replace('%', ''); //remove % (add later)
        //     }
        // }

        // //find if it is a positive
        // var isPositive:boolean = true;
        // if (modifierPrice){
        //     modifierPrice = modifierPrice.replace(' ', ''); //remove all spaces
        //     if (modifierPrice.indexOf('+') >= 0) {
        //         modifierPrice = modifierPrice.replace('+',''); //remove + (add later)
        //     } else if (modifierPrice.indexOf('-') >= 0) {
        //         modifierPrice = modifierPrice.replace('-',''); //remove - (add later)
        //         isPositive = false;
        //     }
        // }

        // //start filtering "priceFormat"
        // if(modifierPrice && isPercentage){
        //     // price is in % format: 5%
        //     this.modifiedPriceTF.text = modifierPrice + "%";

        // } else if(modifierPrice) {
        //     //price should be written with format: +5.00
        //     modifierPrice = modifierPrice.replace(".", ","); //change all "." to "," so number from string works
        //     modifierPrice = this.nf.numberFromString(modifierPrice);
        //     modifierPrice = this.cf.stringFromNumber(modifierPrice); //add the correct currency format
            
        //     if (isPositive){
        //         modifierPrice = "+" + modifierPrice;
        //     } else {
        //         modifierPrice = "-" + modifierPrice;
        //     }
            
        //     this.modifiedPriceTF.text = modifierPrice;

        // } else {
        //     //priceFormat either does not exist or is null, write as: 0.00 or 0,00
        //     this.modifiedPriceTF.text = "+" + this.cf.stringFromNumber(0);
        // }


        this.taxDropDown.title = this.productModifier.tax?.name;

        if (this.productLimitCountTextField) this.productLimitCountTextField.text = (this.productModifier.productModifierCount != null) ? this.nf.stringFromNumber(this.productModifier.productModifierCount) : null;

        if(this.lossTextField) this.lossTextField.text = (this.productModifier.lossInProduction != null) ? this.nf.stringFromNumber(this.productModifier.lossInProduction) : null;
        AppHelper.fillProductUnitsComboBox(this.lossUnitComboBox, item.product, true, item.lossMeasureUnit);
        
        if (this.totalConsumptionTextField) this.totalConsumptionTextField.text = (this.productModifier.consumptionQuantity != null) ? this.nf.stringFromNumber(this.productModifier.consumptionQuantity) : null;
        AppHelper.fillProductUnitsComboBox(this.totalUnitComboBox, item.product, false, item.consumptionMeasureUnit);
    }

    private showAddPriceFormatViewController(button:MUIButton){

        let vc = new AddPriceFormatViewController("add-price-format-view");            
        vc.initWithResource("layout/productmodifiers/AddPriceFormatView.html");
        vc.delegate = this;
        vc.modalPresentationStyle = MUIModalPresentationStyle.Popover;

        let pc = vc.popoverPresentationController;
        pc.sourceRect = button.frame;
        pc.sourceView = button;

        vc.priceString = this.productModifier.priceFormat;

        AppHelper.sharedInstance().showSelectViewControllerFromView(button, vc, this, null);

    }

    private showTaxSelectEntityViewController(dropdownButton, entityName) {
        let predicateFormat = null;        
        let vc = AppHelper.sharedInstance().showCustomSelectViewControllerFromView(dropdownButton, entityName, "name", predicateFormat, this, function(controller, object:MIOManagedObject){

            let title = object.valueForKey("name");
            //let value = object.valueForKey("identifier");
            
            dropdownButton.title = title;
            this.productModifier.tax = object;
        });        
    }

    // private validatePriceString(value:string) {

    //     let isPositive = true;
    //     let isPercentage = false;
    //     let startSubString = 1;
    //     let firstChar = value.charAt(0);

    //     //check if negative or neither
    //     if(value.charAt(0) == "-") {
    //         isPositive = false;
    //     } else if (value.charAt(0) != "+") {
    //         startSubString = 0;
    //     }

    //     //check if percentage
    //     if(value.charAt(value.length-1) == "%"){
    //         isPercentage = true;
    //     }

    //     let subString:string;
    //     if(isPercentage){
    //         subString = value.substring(startSubString, value.length-1);
    //     } else {
    //         subString = value.substring(startSubString);
    //     }

    //     //convert string to number (this requires that the string be a valid number)
    //     subString = this.nf.numberFromString(subString);

    //     if (subString) {
    //         //convert back to string in correct format and return
    //         return (isPositive ? "+" : "-") + this.nf.stringFromNumber(subString) + (isPercentage ? "%" : "");
    //     } else {
    //         return "";
    //     }

    // }

    modifierPriceStringDidUpdate(value:string){
        if(value) {
            this.productModifier.priceFormat = value;
            this.modifierPriceButton.title = value;
        } else {
            this.productModifier.priceFormat = null;
            this.modifierPriceButton.title = "";
        }
    }

    private lossQuantityDidChange(quantity:string){
        let q = this.lossTextField.formatter.getObjectValueForString(quantity);
        this.productModifier.lossInProduction = q;        
        // this.updateTotal();
    }

    private lossUnitDidChange(value:any){
        let v = (typeof value === "string") ? parseInt(value) : value;
        this.productModifier.lossMeasureUnit = v;
        // this.updateTotal();
    }

    private totalQuantityDidChange(quantity:string){
        let q = this.totalConsumptionTextField.formatter.getObjectValueForString(quantity);
        this.productModifier.consumptionQuantity = q;
        // this.updateQuantity();
    }

    private totalUnitDidChange(value:any){
        let v = (typeof value === "string") ? parseInt(value) : value;
        this.productModifier.consumptionMeasureUnit = v;
        // this.updateTotal();
    }


}