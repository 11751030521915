//
// Generated class PaymentInstallment
//

/// <reference path="PaymentInstallment_ManagedObject.ts" />

enum PaymentInstallmentStatus
{
    invalidAmount = -1,
    pending = 0,
    locked = 1,
    paid = 2,
    returned = 3,
    void = 4,
    partialPayment = 5,
    confirmedButNotPay = 6, // This mean they say that the payment is done, but we have not received the money yet.
    unpaid = 7,
    compensated = 8
}

class PaymentInstallment extends PaymentInstallment_ManagedObject
{
    get dateString() : string {         
        return MIODateGetYearFromDate( this.date ) + "-" + MIODateGetMonthFromDate( this.date );
    }

    statusString(): string {
        switch ( this.status ) {
            case -1: return MIOLocalizeString( "INVALID AMOUNT", "INVALID AMOUNT" );
            case  0: return MIOLocalizeString( "PENDING", "PENDING" );
            case  1: return MIOLocalizeString( "LOCKED", "LOCKED" );
            case  2: return MIOLocalizeString( "PAID", "PAID" );
            case  3: return MIOLocalizeString( "RETURNED", "RETURNED" );
            case  4: return MIOLocalizeString( "VOID", "VOID" );
            case  5: return MIOLocalizeString( "PARTIAL", "PARTIAL" );
            case  6: return MIOLocalizeString( "CONFIRMED", "CONFIRMED" );
            case  7: return MIOLocalizeString( "UNPAID", "UNPAID" );
            case  8: return MIOLocalizeString( "COMPENSATED", "COMPENSATED" );
            default: return MIOLocalizeString( "UNKNOWN", "UNKNOWN" );
        }
    }
}
