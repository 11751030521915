class SearchBookingClientCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;
    private emailLabel:MUILabel = null;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, 'name-lbl', 'MUILabel');
        this.emailLabel = MUIOutlet(this, 'email-lbl', 'MUILabel');        
    }

    set item(i:Client){
        this.nameLabel.text = i.name;
        this.emailLabel.text = i.email;        
    }
}
