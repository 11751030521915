

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class UserReportAccess_ManagedObject

class UserReportAccess_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: reportID
    set reportID(value:string) {
        this.setValueForKey(value, 'reportID');
    }
    get reportID():string {
        return this.valueForKey('reportID');
    }
    set reportIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'reportID');
    }
    get reportIDPrimitiveValue():string {
        return this.primitiveValueForKey('reportID');
    }

    // Property: visible
    set visible(value:boolean) {
        this.setValueForKey(value, 'visible');
    }
    get visible():boolean {
        return this.valueForKey('visible');
    }
    set visiblePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'visible');
    }
    get visiblePrimitiveValue():boolean {
        return this.primitiveValueForKey('visible');
    }
    // Relationship: user
    set user(value:User) {
        this.setValueForKey(value, 'user');
    }
    get user():User {
        return this.valueForKey('user') as User;
    }
}
