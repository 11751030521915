

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class SupportTicketLog_ManagedObject

class SupportTicketLog_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: message
    set message(value:string) {
        this.setValueForKey(value, 'message');
    }
    get message():string {
        return this.valueForKey('message');
    }
    set messagePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'message');
    }
    get messagePrimitiveValue():string {
        return this.primitiveValueForKey('message');
    }

    // Relationship: attachments
    protected _attachments:MIOManagedObjectSet = null;
    get attachments():MIOManagedObjectSet {
        return this.valueForKey('attachments');
    }
    addAttachmentsObject(value:FileAttachment) {
        this._addObjectForKey(value, 'attachments');
    }
    removeAttachmentsObject(value:FileAttachment) {
        this._removeObjectForKey(value, 'attachments');
    }
    // Relationship: employe
    set employe(value:Employee) {
        this.setValueForKey(value, 'employe');
    }
    get employe():Employee {
        return this.valueForKey('employe') as Employee;
    }
    // Relationship: supportTicket
    set supportTicket(value:SupportTicket) {
        this.setValueForKey(value, 'supportTicket');
    }
    get supportTicket():SupportTicket {
        return this.valueForKey('supportTicket') as SupportTicket;
    }
}
