

/// <reference path="ModuleCell.ts" />


class ModuleViewController extends MUIViewController
{
    private _tableView       = null;
    
    private _fetchedResultsController = null;
    
    get preferredContentSize()
    {
        return new MIOSize(250, 442);
    }

    viewDidLoad()
    {
        super.viewDidLoad();

        this._tableView = MUIOutlet(this, 'modlv_tableview', 'MUITableView');
        this._tableView.dataSource = this;
        this._tableView.delegate = this;      

        this.reloadData();

        MIONotificationCenter.defaultCenter().addObserver(this, "WorkspaceDidChange", function(note:MIONotification){
            this.reloadData();
        });
    }

    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
        this.reloadData();
    }
    
    private reloadData(){
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        if(!ad.selectedPlace) return;    

        this.fetchedResultsController = null;
        this._tableView.reloadData();
    }

    numberOfSections(tableview:MUITableView)
    {
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview:MUITableView, section)
    {
        var sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath)
    {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        
        let cell = tableview.dequeueReusableCellWithIdentifier('ModuleCell') as ModuleCell;
        cell.item = item;
        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
    
        return cell;
    }
        
    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
    
        MIONotificationCenter.defaultCenter().postNotification('ModuleDidChange', item);
        this.dismissViewController(true);    

        //tableView.deselectCellAtIndexPath(indexPath);
    }
    
    set fetchedResultsController(value)
    {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController()
    {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
        
        var fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('Module');
    
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];
        
        var ad = MUIWebApplication.sharedInstance().delegate;        
        fetchRequest.predicate = MIOPredicate.predicateWithFormat("business == " + ad.selectedPlace.identifier);
        var fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller)
    {
        this._tableView.reloadData();
    }
}