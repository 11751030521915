/// <reference path="DBExportController.ts" />

class DBExportInventoryNoteController extends DBExportController
{    
    private tintColor() { return 0x0B8081; }
    protected title():string { return "InventoryNote " + this.inventotyNote.documentID.replace("/", "_"); }
    protected startColumn():number { return 0; }
    protected headerTitleStyle() { return {sz: 15, color: { rgb: this.tintColor() } }; }
    protected headerValueStyle() { return {sz: 15, bold:true, color: { rgb: this.tintColor() } }; }
    protected tableHeaderStyle(columnIndex:number) { 
        return {
            sz: 18, 
            bold:true, 
            color: { rgb: this.tintColor() }, 
            bottom: { 
                style: "medium", 
                color: { rgb: this.tintColor() }
            }
          }; 
    }
    protected tableValueStyle(columnIndex:number) { 

        if (columnIndex == 0 || columnIndex == 1) {
            return {
                sz: 14,
                alignment: { vertical: "center" },
                bottom: { 
                    style: "thin"
                },                
            }; 
        }
        else {
            return {
                sz: 14,
                alignment: { vertical: "center" },
                bottom: { 
                    style: "thin"
                },
                left: { 
                    style: "thin"
                }
            }; 
        }
    }

    protected tableSectionStyle() { return {sz: 15, bold:true } }
    protected columnWidths() { return [38, 15, 10, 16]; }
    protected rowHeight() { return 30; }

    protected headers() : any[] {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        return [
            { "Title": "Intentory Note: " + this.inventotyNote.documentID },
            { "Title": "Date: " + ad.dateTimeFormatter.stringFromDate(this.inventotyNote.documentDate) },
            { "Title": "Destination: " + this.inventotyNote.destinationName },
            { "Title": "Comments: " + this.inventotyNote.comments },
            { "Title": "Validation Date:", "Value": "" }
        ]
    }

    protected columns(): string[] {
        return [
            "ITEM",
            "MOU",
            "QTY",
            "COMMENTS"
        ];
    }
    
    protected entityName() { return "InventoryNoteLine"; }
    protected predicate() { return MIOPredicate.predicateWithFormat("deletedAt == null and note.identifier == " + this.inventotyNote.identifier) }
    protected sortDescriptors() { 
        return [
            MIOSortDescriptor.sortDescriptorWithKey('product.stockCategory.name', true), 
            MIOSortDescriptor.sortDescriptorWithKey('product.name', true)
        ];
    }
    protected relations(): string[] {
        return ["note", "product", "product.stockCategory"];
    }

    export() {
        // TODO: THROW ERROR
        return;
    }

    private inventotyNote:InventoryNote = null;
    exportInventoryNote(inventoryNote:InventoryNote){
        this.inventotyNote = inventoryNote;
        super.export();
        // DBHelper.queryObjectsWithCompletion(this.entityName(), this.sortDescriptors(), this.predicate(), this.relations(), this, function(this:DBExportInventoryNoteController, objects:MIOManagedObject[]) {
        //     this.parseObjects(objects);
        // });
    }


    protected parseObjects(objects:MIOManagedObject[]) : any[] { 
        let items = [];
        let section = null;
        for (let index = 0; index < objects.length; index++) {            
            let obj = objects[index] as InventoryNoteLine;
            if (section != obj.productStockCategoryName) {
                items.addObject( { "Title" : obj.productStockCategoryName, "__RowType": "section" } );
                section = obj.productStockCategoryName;
            }
            let item = this.parseLine(obj);
            items.addObject(item)
        }

        return items;
    }

    private parseLine(line:InventoryNoteLine): any {

        let item = {
            "ITEM": line.productName,
            "MOU": line.product.productMeasureString,
            "QTY": "",
            "COMMENTS": ""
        }

        return item;
    }

}