
class DBToolSQLViewController extends MUIViewController
{    
    sqlEditor:MUICodeEditor = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.sqlEditor = MUIOutlet(this, 'sql-ta', 'MUICodeEditor'); 
        this.sqlEditor.language = MUICodeEditorLanguage.SQL;
        this.updateUI();       
    }

    private _script:DBScript = null;
    set script(value:DBScript){

        this._script = value;
        this.updateUI();
    }

    private updateUI(){
        let sql = this._script == null ? "" : this._script.query;        
        if (this.sqlEditor != null) this.sqlEditor.text = sql || "";
    }
}
