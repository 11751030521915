//
// Generated class TicketLine
//

/// <reference path="TicketLine_ManagedObject.ts" />

class TicketLine extends TicketLine_ManagedObject
{

}
