

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ProductListItem_ManagedObject

class ProductListItem_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: itemType
    set itemType(value:number) {
        this.setValueForKey(value, 'itemType');
    }
    get itemType():number {
        return this.valueForKey('itemType');
    }
    set itemTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'itemType');
    }
    get itemTypePrimitiveValue():number {
        return this.primitiveValueForKey('itemType');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }
}
