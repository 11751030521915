

/// <reference path="PrintProfileConfiguration.ts" />

// Generated class DevicePrintProfileConfiguration_ManagedObject

class DevicePrintProfileConfiguration_ManagedObject extends PrintProfileConfiguration
{

    // Property: deviceUUID
    set deviceUUID(value:string) {
        this.setValueForKey(value, 'deviceUUID');
    }
    get deviceUUID():string {
        return this.valueForKey('deviceUUID');
    }
    set deviceUUIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deviceUUID');
    }
    get deviceUUIDPrimitiveValue():string {
        return this.primitiveValueForKey('deviceUUID');
    }

    // Property: isiPad
    set isiPad(value:boolean) {
        this.setValueForKey(value, 'isiPad');
    }
    get isiPad():boolean {
        return this.valueForKey('isiPad');
    }
    set isiPadPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isiPad');
    }
    get isiPadPrimitiveValue():boolean {
        return this.primitiveValueForKey('isiPad');
    }
}
