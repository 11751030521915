
class AccountSequenceCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;    
    private prefixLabel:MUILabel = null;
    private sequenceLabel:MUILabel = null;
    
    private conditionsTextField:MUITextField = null;

    awakeFromHTML()
    {        
        this.nameLabel = MUIOutlet(this, 'name-lbl', 'MUILabel');
        this.prefixLabel = MUIOutlet(this, 'prefix-lbl', 'MUILabel');        
        this.sequenceLabel = MUIOutlet(this, 'sequence-lbl', 'MUILabel');
        
        // this.conditionsTextField = MUIOutlet(this, 'conditions-tf', 'MUITextField');
        // this.conditionsTextField.setOnChangeText(this, function(this:AccountSequenceCell, control:MUITextField, value:string){
        //     this.accountSequence.conditions = value.length > 0 ? value : null;
        // });

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }
    
    private accountSequence:AccountingSequence = null;
    set item(i:AccountingSequence){
        this.accountSequence = i;
        this.nameLabel.text = i.name;
        this.prefixLabel.text = i.prefix;
        this.sequenceLabel.text = String( i.sequenceNumber );
        // this.conditionsTextField.text = i.conditions
    }
}
