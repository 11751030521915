//
// Generated class PrintProfile
//

/// <reference path="PrintProfile_ManagedObject.ts" />

class PrintProfile extends PrintProfile_ManagedObject
{

}
