
/// <reference path="../base/BaseViewController.ts" />
/// <reference path="DashboardCollectionCell.ts" />

class DashboardViewController extends BaseViewController 
{
    private voiceCommandButton:MUIButton = null;
    private printButton:MUIButton = null;
    
    private calendarButton:MUIButton = null;    

    private collectionView: MUICollectionView = null;

    private dashboardItems = [];

    viewDidLoad() {
        super.viewDidLoad();

        this.voiceCommandButton = MUIOutlet(this, "settings-btn", "MUIButton");
        this.voiceCommandButton.setAction(this, this.voiceCommandAction);
        this.voiceCommandButton.hidden = true;        

        this.printButton = MUIOutlet(this, "print-btn", "MUIButton");
        this.printButton.setAction(this, function(){
            //PrinterHelper.sharedInstance().printTest();

            // let vc = new TicketSnapShotViewController("printer-snapshot-view");
            // vc.initWithResource("layout/printersnapshot/PrinterSnapshotView.html");
            // vc.filterValues = [];
            // vc.showPreviewFromXML(this.xml);

            // this.presentViewController(vc, true);
        });

        this.calendarButton = MUIOutlet(this, 'calendar-btn', 'MUIButton');
        this.calendarButton.setAction(this, function(){            
            let ah:AppHelper = AppHelper.sharedInstance();
            ah.presentDateSelectionViewController(this);
        });
        
        this.collectionView = MUIOutlet(this, 'collection-view', 'MUICollectionView');
        this.collectionView.dataSource = this;
        this.collectionView.delegate = this;
        this.collectionView.collectionViewLayout = DashboardWidgetsLayout.dashboardLayout();

        this.loadDashboardReports();
    }

    datesDidSelect(dateTimeFrom, dateTimeTo) {
        let rh = ReportHelper.sharedInstance();
        rh.selectedDateTimeFrom = dateTimeFrom;
        rh.selectedDateTimeTo = dateTimeTo;
        this.invalidateFetch();
    }

    numberOfSections(collectionView) {
        return this.fetchedResultsController.sections.count;
    }

    numberOfItemsInSection(collectionView, section) {
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellForItemAtIndexPath(collectionView, indexPath:MIOIndexPath) {
        let item:ReportChartItem = this.fetchedResultsController.objectAtIndexPath(indexPath);

        if (item.chartType != 100){
            let cell: DashboardCollectionCell = collectionView.dequeueReusableCellWithIdentifier('DashboardCell');
            let dv = cell.dashboardView;
            if (dv == null) {
                dv = this.dashboardItems[indexPath.row];
                cell.dashboardView = dv;
            }       
            
            return cell;
        }
        else {
            let cell = collectionView.dequeueReusableCellWithIdentifier('DashboardStatusCell') as DashboardStatusCollectionCell;
            cell.item = item;
            return cell;
        }                
    }

    willDisplayCellAtIndexPath(collectionView, cell, indexPath:MIOIndexPath) {
        let c = cell;
        c.show();        
    }

    didEndDisplayingCellAtIndexPath(collectionView, cell, indexPath:MIOIndexPath) {
        let c = cell;
        c.hide();
    }

    didSelectCellAtIndexPath(collectionView, indexPath:MIOIndexPath) {        
        let cell = collectionView.cellAtIndexPath(indexPath);        
        let rvc:ReportResultsViewController = null;

        if (cell instanceof DashboardStatusCollectionCell) {
            rvc = cell.reportViewController;
        }
        else {
            let dv:DashboardCellView = cell.dashboardView;
            rvc = dv.reportViewController;
        }        

        if (rvc != null) {
            this.navigationController.pushViewController(rvc);
        }
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('ReportChartItem');
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        //fetchedResultsController.delegate = this;
        
        fetchedResultsController.performFetch();
        
        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }    


    protected reloadData(){
        // this.collectionView.reloadData();
    }    

    protected workspaceDidChange(){    
        super.workspaceDidChange();
        this.loadDashboardReports();
    }

    private loadDashboardReports(){
        /*
        let rh:ReportHelper = ReportHelper.sharedInstance();
        rh.downloadReports(this, function(reports){

            if (reports == null) return;

            for (var index = 0; index < reports.length; index++) {

                let report = reports[index];

                if ((report instanceof ReportChartItem) == false) continue;

                let dvc = new DashboardCellView();
                dvc.init();
                dvc.title = MIOLocalizeString(report.title.toUpperCase(), report.title);
                dvc.report = report;
                dvc.labelKey = report.labelKey;
                dvc.valueKey = report.valueKey;
                dvc.chartType = report.chartType;
                
                this.dashboardItems.push(dvc);                        
            }

            this.invalidateFetch();
        });
        */
    }

    //
    // Voice commands Alpha
    //
    private voiceCommandAction(){

        let vc = new VoiceRecordingViewController("voice-recording-view");
        vc.initWithResource("layout/dashboard/VoiceRecordingView.html");

        this.presentViewController(vc, true);
    }

}