/// <reference path="WhatsappParamCell.ts"/>

class WhatsappTemplateParamsViewController extends MUIViewController implements WhatsappParamCellDelegate
{
    static newInstance() : WhatsappTemplateParamsViewController {
        let vc = new WhatsappTemplateParamsViewController("whatsapp-template-params-view");
        vc.initWithResource("layout/communication_templates/WhatsappTemplateParamsView.html");
        return vc;
    }

    private closeButton:MUIButton = null;
    private addButton:MUIButton = null;
    private tableView:UITableView = null;

    get preferredContentSize() {
        return new MIOSize(400, 500);
    }
    viewDidLoad(){
        super.viewDidLoad();

        this.closeButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.closeButton.setAction(this, function(){
            let params = {};
            for (let index = 0; index < this._params.length; index++) {
                params[ String(index) ] = this._params[index];
            }
            this._template.params = params;
            DBHelper.saveMainContext();
            this.dismissViewController(true);
        });        

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, this.addParamAction);

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(animate){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private _params = [];
    private _template:WhatsappTemplate = null;
    set template(template:WhatsappTemplate){
        this._template = template;
        if (template.params != null) {
            let len = Object.keys(template.params).length;
            for (let index = 0; index < len; index++) {
                this._params.push( template.params[index] );
            }            
        }
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._template == null) return;

        this.tableView.reloadData();
    }

    numberOfSections(tableview:UITableView){
        return 1; 
    }

    numberOfRowsInSection(tableview:MUITableView, section){
        return this._params.length;
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell:UITableVewCell = tableview.dequeueReusableCellWithIdentifier("WhatsappParamCell") as WhatsappParamCell;
                
        let value = this._params[indexPath.row];
        cell.setItem(indexPath.row, value);
        cell.delegate = this;

        return cell;
    }

    private addParamAction() {        
        this._params.push( "0" );
        this.tableView.reloadData();
    }

    paramDidChangeAtIndex(index: number, value: string) {
        this._params[index] = value;
    }
    
}