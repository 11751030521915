class PurchaseEmailTemplateViewController extends MUIViewController
{
    static newInstance() : PurchaseEmailTemplateViewController {
        let vc = new PurchaseEmailTemplateViewController("purchase-email-template-view");
        vc.initWithResource("layout/purchase-email-template/PurchaseEmailTemplateView.html");
        return vc;
    }

    viewDidLoad(): void {
        
    }
}