class BookingRecommendationViewController extends SingleEditViewController
{    
    get title(){
        return MIOLocalizeString("BOOKING RECOMMENDATION CONCEPTS","BOOKING RECOMMENDATION CONCEPTS");
    }

    fetchEntity(){
        return "BookingRecommendation";
    }

    sortDescriptors(){
        return [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
    }

    configureCellAtIndexPath(cell:SingleEditCell, indexPath:MIOIndexPath, item:MIOManagedObject){
        let d = item as BookingRecommendation;
        cell.setItem(d, "name");
    }

    addButtonDidPress(){

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('NEW RECOMMENDATION CONCEPT','NEW RECOMMENDATION CONCEPT'), MIOLocalizeString('CHOOSE A NAME','CHOOSE A NAME'), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
           textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("ADD","ADD"), MUIAlertActionStyle.Default, this, function(){
            let name = avc.textFields[0].text;
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            let d = MIOEntityDescription.insertNewObjectForEntityForName(this.fetchEntity(), ad.managedObjectContext) as Department;                
            d.name = name;    
            
            ad.managedObjectContext.save();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, null, null));        

        this.presentViewController(avc, true);
    }

    removeItem(item:MIOManagedObject){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.managedObjectContext.deleteObject(item);
        ad.managedObjectContext.save();        
    }
}