
class SupplierOrderLineCell extends NoteLineCell
{            
    productDidSelect(controller:SelectEntityViewController, product:Product, supplierProduct?:SupplierProduct){
        super.productDidSelect(controller, product, supplierProduct);
        
        if (supplierProduct != null) {
            this.productNetPriceAmount = DBHelper.calculateCostFromSupplierProduct(product, supplierProduct);
            this.discountFormat = supplierProduct.discountString;
        }
        else {            
            let supplier = this.stockNote.destinationEntity as Supplier;
            [this.productNetPriceAmount, this.discountFormat] = DBHelper.costFromProductAndSupplier(product, supplier);
        }                

        this.priceAmount = this.productNetPriceAmount;
                
        this.priceTextField.enabled = true;
        this.priceTextField.text = this.cf.stringFromNumber(this.priceAmount);
    }

    enterDidPress(){
        if (this.stockNoteLine != null) return;        
        if (this.quantity == 0 || this.quantity == null) return;
                
        let line = DBHelper.createSupplierOrderLine(this.product, this.inputFormat, this.inputType, this.quantity, this.productQuantity, this.priceAmount, this.productNetPriceAmount, this.baseAmount, this.discountFormat, this.discountAmount, this.stockNote);
        if (line != null) {
            this.lineDidInserted(line);
            this.reset();
        }
    }  
}