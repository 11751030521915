

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class RuleKit_ManagedObject

class RuleKit_ManagedObject extends CoreEntityUpdatedBy
{

    // Relationship: occupationRules
    protected _occupationRules:MIOManagedObjectSet = null;
    get occupationRules():MIOManagedObjectSet {
        return this.valueForKey('occupationRules');
    }
    addOccupationRulesObject(value:RuleResult) {
        this._addObjectForKey(value, 'occupationRules');
    }
    removeOccupationRulesObject(value:RuleResult) {
        this._removeObjectForKey(value, 'occupationRules');
    }

    // Relationship: priceRules
    protected _priceRules:MIOManagedObjectSet = null;
    get priceRules():MIOManagedObjectSet {
        return this.valueForKey('priceRules');
    }
    addPriceRulesObject(value:RuleResult) {
        this._addObjectForKey(value, 'priceRules');
    }
    removePriceRulesObject(value:RuleResult) {
        this._removeObjectForKey(value, 'priceRules');
    }
}
