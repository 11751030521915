

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class PosClientDevice_ManagedObject

class PosClientDevice_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: deviceID
    set deviceID(value:number) {
        this.setValueForKey(value, 'deviceID');
    }
    get deviceID():number {
        return this.valueForKey('deviceID');
    }
    set deviceIDPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'deviceID');
    }
    get deviceIDPrimitiveValue():number {
        return this.primitiveValueForKey('deviceID');
    }

    // Property: deviceUUID
    set deviceUUID(value:string) {
        this.setValueForKey(value, 'deviceUUID');
    }
    get deviceUUID():string {
        return this.valueForKey('deviceUUID');
    }
    set deviceUUIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deviceUUID');
    }
    get deviceUUIDPrimitiveValue():string {
        return this.primitiveValueForKey('deviceUUID');
    }

    // Property: isValidated
    set isValidated(value:boolean) {
        this.setValueForKey(value, 'isValidated');
    }
    get isValidated():boolean {
        return this.valueForKey('isValidated');
    }
    set isValidatedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isValidated');
    }
    get isValidatedPrimitiveValue():boolean {
        return this.primitiveValueForKey('isValidated');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: waiterPaymentEnabled
    set waiterPaymentEnabled(value:boolean) {
        this.setValueForKey(value, 'waiterPaymentEnabled');
    }
    get waiterPaymentEnabled():boolean {
        return this.valueForKey('waiterPaymentEnabled');
    }
    set waiterPaymentEnabledPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'waiterPaymentEnabled');
    }
    get waiterPaymentEnabledPrimitiveValue():boolean {
        return this.primitiveValueForKey('waiterPaymentEnabled');
    }

    // Relationship: appConfigurations
    protected _appConfigurations:MIOManagedObjectSet = null;
    get appConfigurations():MIOManagedObjectSet {
        return this.valueForKey('appConfigurations');
    }
    addAppConfigurationsObject(value:AppConfiguration) {
        this._addObjectForKey(value, 'appConfigurations');
    }
    removeAppConfigurationsObject(value:AppConfiguration) {
        this._removeObjectForKey(value, 'appConfigurations');
    }
    // Relationship: subCashDesk
    set subCashDesk(value:SubCashDesk) {
        this.setValueForKey(value, 'subCashDesk');
    }
    get subCashDesk():SubCashDesk {
        return this.valueForKey('subCashDesk') as SubCashDesk;
    }
}
