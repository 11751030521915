//
// Generated class MessageTemplate
//

/// <reference path="MessageTemplate_ManagedObject.ts" />

class MessageTemplate extends MessageTemplate_ManagedObject
{

}
