
class CashDeskSessionTicketLineCell extends MUITableViewCell
{
    private quantityLabel:MUILabel = null;
    private productLabel:MUILabel = null;
    private totalLabel:MUILabel = null;

    awakeFromHTML(){
        this.quantityLabel = MUIOutlet(this, "quantity-lbl", "MUILabel");
        this.productLabel = MUIOutlet(this, "product-lbl", "MUILabel");
        this.totalLabel = MUIOutlet(this, "total-lbl", "MUILabel");
    }

    set item(item){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;        

        this.quantityLabel.text = item["Quantity"];
        this.productLabel.text = item["Title"];
        this.totalLabel.text = ad.currencyFormatter.stringFromNumber(item["Total"]);
    }
}