

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ProductCombination_ManagedObject

class ProductCombination_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: inQuantity
    set inQuantity(value:number) {
        this.setValueForKey(value, 'inQuantity');
    }
    get inQuantity():number {
        return this.valueForKey('inQuantity');
    }
    set inQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'inQuantity');
    }
    get inQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('inQuantity');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }
    // Relationship: category
    set category(value:ProductCategory) {
        this.setValueForKey(value, 'category');
    }
    get category():ProductCategory {
        return this.valueForKey('category') as ProductCategory;
    }
    // Relationship: format
    set format(value:Format) {
        this.setValueForKey(value, 'format');
    }
    get format():Format {
        return this.valueForKey('format') as Format;
    }
    // Relationship: menu
    set menu(value:Menu) {
        this.setValueForKey(value, 'menu');
    }
    get menu():Menu {
        return this.valueForKey('menu') as Menu;
    }
    // Relationship: paymentAction
    set paymentAction(value:PaymentAction) {
        this.setValueForKey(value, 'paymentAction');
    }
    get paymentAction():PaymentAction {
        return this.valueForKey('paymentAction') as PaymentAction;
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
}
