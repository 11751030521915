//
// Generated class Event
//

/// <reference path="Event_ManagedObject.ts" />

class Event extends Event_ManagedObject
{

}
