//
// Generated class ErrorLog
//

/// <reference path="ErrorLog_ManagedObject.ts" />

class ErrorLog extends ErrorLog_ManagedObject
{

}
