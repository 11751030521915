//
// Generated class PresetMenu
//

/// <reference path="PresetMenu_ManagedObject.ts" />

class PresetMenu extends PresetMenu_ManagedObject
{

}
