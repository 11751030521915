
class AnalyticAccountReconciliationViewController extends BaseTableViewController
{
    static newInstance(): AnalyticAccountReconciliationViewController {
        let vc = new AnalyticAccountReconciliationViewController("analytic-account-reconciliation-view");
        vc.initWithResource("layout/analytic-accounts/AnalyticAccountReconciliationView.html");

        return vc;
    }
    
    private entityComboBox:MUIComboBox = null;
    private calendarButton:MUIButton = null;
    private saveButton:MUIButton = null;

    private selectedEntityName:string = null;
    private selectedSectionRelations:string[] = [];
    private selectedSectionKeypath:string = null;
    private selectedSortDescriptors:MIOSortDescriptor[] = [];

    private _from_date_str:Date = null;
    private _to_date_str:Date = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.entityComboBox = MUIOutlet(this, "entity-type-cb", "MUIComboBox");
        this.entityComboBox.setOnChangeAction(this, function(this:LedgerAccountReconciliationViewController, control:MUIComboBox, value:string){
            switch(value) {
                case "-1": 
                this.selectedEntityName = null; 
                this.selectedSectionRelations = [];
                this.selectedSectionKeypath = null;
                this.selectedSortDescriptors = [];
                break;
                
                case "0": 
                this.selectedEntityName = "PurchaseInvoiceLine"; 
                this.selectedSectionKeypath = "documentNumber";
                this.selectedSectionRelations = ["product", "document.legalEntity"];
                this.selectedSortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("createdAt", false)];
                break;
                
                case "1": 
                this.selectedEntityName = "ClientInvoiceLine"; 
                this.selectedSectionKeypath = "documentNumber";
                this.selectedSectionRelations = ["product", "document.legalEntity"];
                this.selectedSortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("createdAt", false)];
                break;
                // case "2": this.selectedEntityName = "ArchivedInvoice"; break;
                // case "3": this.selectedEntityName = "ArchivedTicket"; break;
                case "4": 
                this.selectedEntityName = "SupplierNoteLine"; 
                this.selectedSectionKeypath = "documentNumber";
                this.selectedSectionRelations = ["product", "document.legalEntity"];
                this.selectedSortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("createdAt", false)];
                break;
                case "5": 
                this.selectedEntityName = "DeliveryNoteLine"; 
                this.selectedSectionKeypath = "documentNumber";
                this.selectedSectionRelations = [ "product", "document.legalEntity" ];
                this.selectedSortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("createdAt", false)];
                break;
                case "6": 
                this.selectedEntityName = "BankMovement";
                this.selectedSectionKeypath = "bankAccount.name";
                this.selectedSectionRelations = [ "bankAccount.info","bankAccount.bank" ];
                this.selectedSortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("date", false), MIOSortDescriptor.sortDescriptorWithKey("orderIndex", false)];
                break;
            }

            let today = MIODateToday();
            this._from_date_str = MIODateGetDateString(today) + " 00:00:00";
            this._to_date_str = MIODateGetDateString(today) + " 23:59:59";
                
            this.tableView.reloadData();
        });

        this.calendarButton = MUIOutlet(this, "calendar-btn", "MUIButton");
        this.calendarButton.setAction(this, function(){
            AppHelper.sharedInstance().presentDateSelectionViewController(null, this);
        });

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(DBHelper, DBHelper.saveMainContext);

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    numberOfSections(tableView:UITableView){        
        return this.fetchedResultsController?.sections.length ?? 0;
    }

    titleForHeaderInSection(tableView:UITableView, section:number) {
        let ip = MIOIndexPath.indexForRowInSection(0, section);
        let item = this.fetchedResultsController.objectAtIndexPath( ip );

        if (item instanceof PurchaseInvoiceLine) {
            return item.document?.documentNumber + " - " + item.document?.legalEntity?.name;
        }
        else if (item instanceof BankMovement) {
            return item.bankAccount.bank.name + " - " + item.bankAccount.info.iban;
        }        

        return null;
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);

        let cell = tableView.dequeueReusableCellWithIdentifier( "LineCell" ) as LedgerAccountReconciliationCell;
        cell.item = item;
        return cell;
    }    

    protected _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value:MIOFetchedResultsController){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        if (this.selectedEntityName == null) return null;

        let ad = MUIWebApplication.sharedInstance().delegate;
        
        let pf = null;
        if (this._from_date_str != null) {
            pf = "date >= '" + this._from_date_str + "' AND date <= '" + this._to_date_str + "'";
        }    

        let sds = this.selectedSortDescriptors;
        let fetchRequest = DBHelper.listFetchRequestWithEntityName( this.selectedEntityName, sds, pf);
        fetchRequest.relationshipKeyPathsForPrefetching = this.selectedSectionRelations;
        // fetchRequest.fetchLimit = 100;

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, this.selectedSectionKeypath);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();
        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    datesDidSelect(dateTimeFrom:Date, dateTimeTo:Date) {
        let sdtf = (MUIWebApplication.sharedInstance().delegate as AppDelegate).serverDateTimeFormatter;

        this._from_date_str = sdtf.stringForObjectValue(dateTimeFrom);
        this._to_date_str = sdtf.stringForObjectValue(dateTimeTo);

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

}