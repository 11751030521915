

class StockCategorySelectionViewController extends BaseTableViewController
{

    static newInstance() : StockCategorySelectionViewController {
        let vc = new StockCategorySelectionViewController('stock-category-selection-view');
        vc.initWithResource('layout/stockcategory/StockCategorySelectionView.html');
        return vc;
    }

    private addButton:MUIButton = null;
    private backButton:MUIButton = null;
    private titleLabel:MUILabel = null;

    noSelectionCell = false;
    delegate = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function(){
            this.addStockCategory();
        });

        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");
        this.backButton.setAction(this, function(control){
            this.navigationController.popViewController(true);
        });

        this.titleLabel = MUIOutlet(this, 'title-lbl', 'MUILabel');

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        // //use the notification for adding to winecellar dict
        // MIONotificationCenter.defaultCenter().addObserver(this, "WineCellarOptionSelected", function(notification:MIONotification){
        //     let item = notification.object;
        //     this.navigationController.popToRootViewController(true);
        //     // this.fetchedResultsController = null;
        //     // this.tableView.reloadData();
        // });
    }

    viewWillAppear(animate?) {
        super.viewWillAppear(animate);        
        this.updateUI();
    }
    
    viewWillDisappear(animated:boolean){
        super.viewWillDisappear(animated);
        //remove observer
    }

    private _selectedWineCellar:WineCellar = null;
    set setSelectedWineCellar (item:WineCellar) {
        this._selectedWineCellar = item;
    }

    private _parentStockCategoryID:string = null;
    set setParentStockCategoryID (item:string) {
        this._parentStockCategoryID = item;
    }

    parentCategory:StockCategory = null;
    set setParentStockCategory (item:StockCategory) {
        this.parentCategory = item;
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        if (this.parentCategory) {
            this.titleLabel.text = MIOLocalizeString("STOCK CATEGORY PARENT: ","STOCK CATEGORY PARENT: ") +  this.parentCategory.name;
        } else {
            this.titleLabel.text = MIOLocalizeString("STOCK CATEGORY DETAIL","STOCK CATEGORY DETAIL");
        }
      
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }
    
    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        let rows = sec.numberOfObjects();
        if (this.noSelectionCell == true) rows++;
        return rows;
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier('StockCategorySelectionCell') as StockCategorySelectionCell;
        cell.selectionStyle = UITableViewCellSelectionStyle.None;
        cell.delegate = this;

        let realIndexPath = this.noSelectionCell == false ? indexPath : MIOIndexPath.indexForRowInSection(indexPath.row - 1, indexPath.section);
        if (this.noSelectionCell == true && indexPath.row == 0) {
            cell.title = MIOLocalizeString("NO STOCK CATEGORY", "NO STOCK CATEGORY");
        } else {
            let item = this.fetchedResultsController.objectAtIndexPath(realIndexPath) as StockCategory;
            cell.item = item;
        }

        return cell;
    }
        
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        if (this.noSelectionCell == true && indexPath.row == 0) {            
            // because its a no select cell, the first cell will always be a no selection but it must be selected with button, not the cell itself

        } else if (this.noSelectionCell == true && indexPath.row > 0) {
            // let ip = MIOIndexPath.indexForRowInSection(indexPath.row - 1, indexPath.section);
            // let item = this.fetchedResultsController.objectAtIndexPath(ip) as StockCategory;
            // this.delegate.updateSelectStockCategory(this._selectedWineCellar, item);
            // this.navigationController.popViewController(true);

            let ip = MIOIndexPath.indexForRowInSection(indexPath.row - 1, indexPath.section);
            let item = this.fetchedResultsController.objectAtIndexPath(ip) as StockCategory;

            if (item) {
                let vc = StockCategorySelectionViewController.newInstance();
                vc.setParentStockCategoryID = item.identifier;
                vc.setSelectedWineCellar = this._selectedWineCellar;
                vc.parentCategory = item;
                vc.noSelectionCell = true;
                vc.delegate = this;
                this.navigationController.pushViewController(vc, true);
            }
        } else {
            // let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as StockCategory;
            // this.delegate.updateSelectStockCategory(this._selectedWineCellar, item);
            // this.navigationController.popViewController(true);             
        }




    }

    // editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
    //     return UITableViewCellEditingStyle.Delete;
    // }
    
    // commitEditingStyleForRowAtIndexPath(tableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
    //     let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as StockCategory;    

    //     if (editingStyle == UITableViewCellEditingStyle.Delete) {
    //         DBHelper.deleteObjectFromMainContext(item, true);
    //     }
    // }

    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('orderindex', true),
                                MIOSortDescriptor.sortDescriptorWithKey('name', true)
                            ];
        // let predicateFormat = ("superCategory.identifier == " + (this._parentStockCategoryID ? this._parentStockCategoryID : "null"));
        let predicateFormat = this.searchString != null ? "name CONTAINS '" + this.searchString + "' AND " + ("superCategory.identifier == " + (this._parentStockCategoryID ? this._parentStockCategoryID : "null"))    : ("superCategory.identifier == " + (this._parentStockCategoryID ? this._parentStockCategoryID : "null"));

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("StockCategory", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ['image'];       
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    private stockCategorySelected(selectedStockCategory:StockCategory){
        alert("selected " + selectedStockCategory);
    }

    
    private stockCategoryDidSelect(selectedStockCategory:StockCategory){
        this.delegate.updateSelectStockCategory(this._selectedWineCellar, selectedStockCategory);
        this.navigationController.popViewController(true)
    }

    // recursive: pass on the call and pop the view until it gets to the root contnroller
    private updateSelectStockCategory(selectedWineCellar:WineCellar, selectedStockCategory:StockCategory) {
        this.delegate.updateSelectStockCategory(selectedWineCellar, selectedStockCategory);
        this.navigationController.popViewController(true);
    }



    private addStockCategory(){

        var avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('NEW STOCK CATEGORY','NEW STOCK CATEGORY'), MIOLocalizeString('CHOOSE A NAME','CHOOSE A NAME '), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD','ADD'), MUIAlertActionStyle.Default, this, function(){
            let nameTF =  avc.textFields[0];
            let name = nameTF.text.trim();

            if(name.length > 0){
                let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
                let ws = MIOEntityDescription.insertNewObjectForEntityForName("StockCategory", moc) as StockCategory;
                ws.identifier = MIOUUID.UUID().UUIDString;
                ws.name = name;
                if (this.parentCategory) ws.superCategory = this.parentCategory;
                moc.save();
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('CHECK NAME','CHECK NAME'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CLOSE','CLOSE'), MUIAlertActionStyle.Cancel, this, function(){}));
                
                this.presentViewController(error, true);
            }
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, this, function(){}));
        
        this.presentViewController(avc, true);
    }

}
