

/// <reference path="StockTemplateItem.ts" />

// Generated class StockTemplateStockCategoryItem_ManagedObject

class StockTemplateStockCategoryItem_ManagedObject extends StockTemplateItem
{

    // Property: categoryName
    set categoryName(value:string) {
        this.setValueForKey(value, 'categoryName');
    }
    get categoryName():string {
        return this.valueForKey('categoryName');
    }
    set categoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'categoryName');
    }
    get categoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('categoryName');
    }
    // Relationship: stockCategory
    set stockCategory(value:StockCategory) {
        this.setValueForKey(value, 'stockCategory');
    }
    get stockCategory():StockCategory {
        return this.valueForKey('stockCategory') as StockCategory;
    }
}
