

class BookingSourceContactCell extends UITableViewCell
{
    private nameTextfield:MUITextField = null;
    private emailTextfield:MUITextField = null;
    private phoneTextfield:MUITextField = null;

    awakeFromHTML(){
        this.nameTextfield = MUIOutlet(this, "contact-name-tf", "MUITextField");
        this.emailTextfield = MUIOutlet(this, "contact-email-tf", "MUITextField");
        this.phoneTextfield = MUIOutlet(this, "contact-phone-tf", "MUITextField");
    }

    set item(i:BookingSource){
        this.nameTextfield.text = i.name;
        this.emailTextfield.text = i.email;
        this.phoneTextfield.text = i.phone;
    }

}