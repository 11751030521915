class EditUserInfoViewController extends MUIViewController
{  
    private backButton:MUIButton = null;
    private nameTextField:MUITextField = null;
    private emailTextField:MUITextField = null;
    private languageButton:MUIButton = null;
    private saveButton:MUIButton = null;

    public delegate = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");
        this.backButton.setAction(this, function (){            
            this.navigationController.popViewController(true);
        });

        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.nameTextField.setOnChangeText(this, function(control, value:string){
            if(value.length > 0) {
                this._user.name = value;
            }
        });

        this.emailTextField = MUIOutlet(this, "email-tf", "MUITextField");
        this.emailTextField.setOnChangeText(this, function(control, value:string){
            if(value.length > 0) {
                this._user.email = value;
            }
        });

        this.languageButton = MUIOutlet(this, "language-btn", "MUIButton");
        this.languageButton.setAction(this, function(){

        });

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
            this.navigationController.popViewController(true);
        });

    }

    viewWillAppear(){
        super.viewWillAppear();
        this.updateUI();
    }

    private _user:User = null;
    set user(user:User){
        this._user = user;
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        this.nameTextField.text = this._user.name;
        this.emailTextField.text = this._user.email;
        // this.languageButton.title = this._user.language;  //language not yet implemented in entity "User"
    }

    private languagesDidChange(controller:SelectEntityViewController, language:Language){

        //returns Array of Language (language)
        //Keys of intrest: name, identifier, iso2

        if(language[0] == undefined || language == undefined || language == null){

            this.languageButton.title = MIOLocalizeString('SELECT LANGUAGE','Select language');
            //this._user.language = null;

        }else{

            let langString:string = ""; //string to add to dropdown title
            let langArray:string = ""; //array as string to add to database
            let lang:any = language;
            let count = lang.length;

            for(let i = 0; i < count; i++) {
                let item:any = lang[i];
                if(i > 0){
                    langString += ", ";
                    langArray += ", ";
                }
                    langString += item.iso2;
                    langArray += item.identifier;
            }

            langArray = "["+langArray+"]"; //add brackets for DB insert

            this.languageButton.title = langString;
            //this._user.language = langArray;
        }
        
        this.updateUI();
    }




}
