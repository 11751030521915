//
// Generated class ReportListItem
//

/// <reference path="ReportListItem_ManagedObject.ts" />

class ReportListItem extends ReportListItem_ManagedObject
{    
    columns = [];
    subtotals = null;
    private _isVisible = false;
    get isVisible(){return this._isVisible;}

    parseJSON(item){
        super.parseJSON(item);

        this._isVisible = item["isvisible"] == true ? true : false;
        this.subtotals = item["columnssubtotals"] != null ? item["columnssubtotals"] : null;
        this.columns = [];

        let columns = item["columns"] != null ? item["columns"] : [];        

        for (var index = 0; index < columns.length; index++) {
            let column = columns[index];
            let title = column["title"];
            let width = column["width"];            
            let minWidth = column["minwidth"];
            let key = column["key"];
            let align = column["align"];
            let formatter = column["formatter"];

            let ft = null;            
            let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
            switch (formatter){

                case "integer":
                ft = ad.integerFormatter                
                break;

                case "number":
                case "float":
                ft = ad.numberFormatter;
                break;
                
                case "currency":
                ft = ad.currencyFormatter;
                break;
            }

            let col = MUIReportTableViewColumn.labelColumnWithTitle(MIOLocalizeString(title.toUpperCase(), title), width + "%", minWidth, align, key, ft, null, formatter);
            this.columns.push(col);
        }
    }
}
