class WorkersAdminCell extends UITableViewCell {
  dataSource = null;

  private nameLabel: MUILabel = null;
  private departmentsDropdown: MUIButton = null;
  private workersRoleDropdown: MUIButton = null;

  awakeFromHTML() {
    this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
    
    this.departmentsDropdown = MUIOutlet(this, "department-dd", "MUIButton");
    this.setupDepartmentsDropdown(this.departmentsDropdown);
    
    this.workersRoleDropdown = MUIOutlet(this, "role-cb", "MUIButton");
    this.setupWorkersRoleDropdown(this.workersRoleDropdown);

  }

  private _item: Employee = null;
  get item() {
    return this._item;
  }
  
  set item(item: Employee) {
    this._item = item;
    this.nameLabel.text = item.name;
    if (this.departmentsDropdown != null) this.departmentsDropdown.title = item.department != null ? item.department.name : null;
    if (this.workersRoleDropdown != null) this.workersRoleDropdown.title = item.role != null ? item.role.name : null;
  }

  private setupDepartmentsDropdown(button:MUIButton){
    if (button == null) return;

    button.setAction(this, function() {
      AppHelper.sharedInstance().showSelectDepartmentViewControllerFromView(button, this.item.department, this, function(controller: SelectEntityViewController, department: Department) {
          this.item.department = department;
          button.title = department != null ? department.name : null;
        });
    });
  }

  private setupWorkersRoleDropdown(button:MUIButton) {
    if (button == null) return;

    button.setAction(this, function() {
      AppHelper.sharedInstance().showSelectWorkerRoleViewControllerFromView(button, this.item.role, this, function( controller: SelectEntityViewController, workerRole: WorkerRole) {
          this.item.changeRole(workerRole);
          button.title = workerRole != null ? workerRole.name : null;
        });
    });
  }    

}
