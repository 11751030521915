

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class AccountingEntry_ManagedObject

class AccountingEntry_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: accountName
    set accountName(value:string) {
        this.setValueForKey(value, 'accountName');
    }
    get accountName():string {
        return this.valueForKey('accountName');
    }
    set accountNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'accountName');
    }
    get accountNamePrimitiveValue():string {
        return this.primitiveValueForKey('accountName');
    }

    // Property: accountNumber
    set accountNumber(value:string) {
        this.setValueForKey(value, 'accountNumber');
    }
    get accountNumber():string {
        return this.valueForKey('accountNumber');
    }
    set accountNumberPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'accountNumber');
    }
    get accountNumberPrimitiveValue():string {
        return this.primitiveValueForKey('accountNumber');
    }

    // Property: balance
    set balance(value:number) {
        this.setValueForKey(value, 'balance');
    }
    get balance():number {
        return this.valueForKey('balance');
    }
    set balancePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'balance');
    }
    get balancePrimitiveValue():number {
        return this.primitiveValueForKey('balance');
    }

    // Property: creditAmount
    set creditAmount(value:number) {
        this.setValueForKey(value, 'creditAmount');
    }
    get creditAmount():number {
        return this.valueForKey('creditAmount');
    }
    set creditAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'creditAmount');
    }
    get creditAmountPrimitiveValue():number {
        return this.primitiveValueForKey('creditAmount');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: debitAmount
    set debitAmount(value:number) {
        this.setValueForKey(value, 'debitAmount');
    }
    get debitAmount():number {
        return this.valueForKey('debitAmount');
    }
    set debitAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'debitAmount');
    }
    get debitAmountPrimitiveValue():number {
        return this.primitiveValueForKey('debitAmount');
    }

    // Property: reference
    set reference(value:string) {
        this.setValueForKey(value, 'reference');
    }
    get reference():string {
        return this.valueForKey('reference');
    }
    set referencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'reference');
    }
    get referencePrimitiveValue():string {
        return this.primitiveValueForKey('reference');
    }

    // Property: relationEntityID
    set relationEntityID(value:string) {
        this.setValueForKey(value, 'relationEntityID');
    }
    get relationEntityID():string {
        return this.valueForKey('relationEntityID');
    }
    set relationEntityIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'relationEntityID');
    }
    get relationEntityIDPrimitiveValue():string {
        return this.primitiveValueForKey('relationEntityID');
    }

    // Property: relationEntityType
    set relationEntityType(value:string) {
        this.setValueForKey(value, 'relationEntityType');
    }
    get relationEntityType():string {
        return this.valueForKey('relationEntityType');
    }
    set relationEntityTypePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'relationEntityType');
    }
    get relationEntityTypePrimitiveValue():string {
        return this.primitiveValueForKey('relationEntityType');
    }
    // Relationship: account
    set account(value:LedgerAccount) {
        this.setValueForKey(value, 'account');
    }
    get account():LedgerAccount {
        return this.valueForKey('account') as LedgerAccount;
    }
    // Relationship: journalEntry
    set journalEntry(value:AccountingJournalEntry) {
        this.setValueForKey(value, 'journalEntry');
    }
    get journalEntry():AccountingJournalEntry {
        return this.valueForKey('journalEntry') as AccountingJournalEntry;
    }
}
