
class CashDeskSessionOperationListViewController extends MUIViewController
{
    private conceptFilterTextField:ColumnFilterTextField = null;
    private paymethodFilterTextField:ColumnFilterTextField = null;
	private documentFilterTextField:ColumnFilterTextField = null;
	private locationFilterTextField:ColumnFilterTextField = null;
    private amountFilterTextField:ColumnFilterTextField = null;
    private tipFilterTextField:ColumnFilterTextField = null;
    private totalFilterTextField:ColumnFilterTextField = null;

    private tableView:MUITableView = null;

    private filterController:ColumnFilterController = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.filterController = new ColumnFilterController();
        this.filterController.initWithDelegate(this);

        this.conceptFilterTextField = MUIOutlet(this, "concept-filter-tf", "ColumnFilterTextField");
        this.conceptFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "concept", null, null);
        this.conceptFilterTextField.filterController = this.filterController;

        this.paymethodFilterTextField = MUIOutlet(this, "paymethod-filter-tf", "ColumnFilterTextField");
        this.paymethodFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "payMethodName", null, null);
        this.paymethodFilterTextField.filterController = this.filterController;

        this.documentFilterTextField = MUIOutlet(this, "document-filter-tf", "ColumnFilterTextField");
        this.documentFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "legalDocumentID", null, null);
		this.documentFilterTextField.filterController = this.filterController;
		
		this.locationFilterTextField = MUIOutlet(this, "location-filter-tf", "ColumnFilterTextField");
        this.locationFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "locationCategoryName", null, null);
        this.locationFilterTextField.filterController = this.filterController;

        this.amountFilterTextField = MUIOutlet(this, "amount-filter-tf", "ColumnFilterTextField");
        this.amountFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "import", null, null);
        this.amountFilterTextField.filterController = this.filterController;

        this.tipFilterTextField = MUIOutlet(this, "tip-filter-tf", "ColumnFilterTextField");
        this.tipFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "tip", null, null);
        this.tipFilterTextField.filterController = this.filterController;

        this.totalFilterTextField = MUIOutlet(this, "total-filter-tf", "ColumnFilterTextField");
        this.totalFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "total", null, null);
        this.totalFilterTextField.filterController = this.filterController;
        
        
        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _session:CashDeskSession = null;
    set session(session:CashDeskSession){
        if (session == this._session) return;
        this._session = session;   
        this.updateUI();
    }

    private updateUI(){
        if (this._session == null || this.viewIsLoaded == false) return;

        this.fetchedResultsController = null;
        this.tableView.reloadData();        
    }

    numberOfSections(tableview:MUITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview:MUITableView, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("OperationCell") as CashDeskOperationCell;
    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as CashDeskOperation;
        cell.item = item;
        // cell.delegate = this;
    
        return cell;
    }   

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('CashDeskOperation');
        fetchRequest.relationshipKeyPathsForPrefetching = ['payMethod.image'];
    
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('date', true)];

        let predicateFormat = "sessionID == " + this._session.identifier;
        let filterFormat = this.filterController.filterPredicateFormat();
        if (filterFormat != null) predicateFormat += " AND (" + filterFormat + ")";

        fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);    
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();        
    }

    filterPredicateDidChange(controller:ColumnFilterController){
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }
    
}
