

/// <reference path="StockNoteLine.ts" />

// Generated class StockNoteProductLine_ManagedObject

class StockNoteProductLine_ManagedObject extends StockNoteLine
{
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
}
