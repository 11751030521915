//
// Generated class ReportFilter
//

/// <reference path="ReportFilter_ManagedObject.ts" />

class ReportFilter extends ReportFilter_ManagedObject
{

}
