//
// Generated class TimeRange
//

/// <reference path="TimeRange_ManagedObject.ts" />

class TimeRange extends TimeRange_ManagedObject
{

}
