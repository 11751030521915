//
// Generated class LocationCategory
//

/// <reference path="LocationCategory_ManagedObject.ts" />

class LocationCategory extends LocationCategory_ManagedObject
{

}
