
class BookingsZonesSetupViewController extends EntityListViewController
{
    private saveButton:MUIButton = null;
    private addButton:MUIButton = null;

    private zone:BookingZone = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(control){
            let moc:MIOManagedObjectContext = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
            moc.save();
        });

        this.addButton = MUIOutlet(this, 'add-btn','MUIButton');
        this.addButton.setAction(this, function(){
           this.showAddZoneDialog();
        });
    
        this.tableView = MUIOutlet(this,"tableview", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(animate ?){
        super.viewWillAppear(animate);
        this.tableView.reloadData();
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);

        let cell = null;
        if (item.parent == null){
            cell = tableview.dequeueReusableCellWithIdentifier('ZoneCell') as BookingsZonesSetupCell;
            cell.delegate = this;
        }
        else {
            cell = tableview.dequeueReusableCellWithIdentifier('SubZoneCell') as BookingsZonesSetupSubCell;                    
        }
        
        cell.item = item;        
    
        cell.selectionStyle = MUITableViewCellSelectionStyle.None;
        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
        cell.editing = true;
        
        return cell;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        let obj = this.fetchedResultsController.objectAtIndexPath(indexPath);
        this.zone = obj;
    }
    
    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let zone = this.fetchedResultsController.objectAtIndexPath(indexPath);    

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            this.removeZone(zone);
        }
    }

    get entityName(){
        return 'BookingZone';
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('indexPathString', true)];

        let fetchRequest = DBHelper.listFetchRequestWithEntityName(this.entityName, sortDescriptors, null);
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    private addZoneWithName(zoneName:string, parent){
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        let zone:BookingZone = MIOEntityDescription.insertNewObjectForEntityForName("BookingZone", moc) as BookingZone;
        zone.identifier = MIOUUID.UUID().UUIDString;
        zone.name = zoneName;
        zone.parent = parent;
        moc.save();
    }

    private removeZone(zone){
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

        if (zone.subZones.count > 0) {

            let avc = new MUIAlertViewController();
            avc.initWithTitle(MIOLocalizeString('BOOKING ZONE','Booking Zone'), MIOLocalizeString("The zone has sub zones. Please delete the subzones first", "The zone has sub zones. Please delete the subzones first"), MUIAlertViewStyle.Default);
            avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','Done'), MUIAlertActionStyle.Default, null, null));

            this.presentViewController(avc, true);            
        }
        else {
            moc.deleteObject(zone);
            moc.save();    
        }

    }

    showAddZoneDialog(parent?){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('NEW BOOKING ZONE','NEW BOOKING ZONE'), MIOLocalizeString('CHOOSE A NAME','CHOOSE A NAME'), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("Name");
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD','ADD'), MUIAlertActionStyle.Default, this, function(){
            let nameTF =  avc.textFields[0];
            let name = nameTF.text.trim();
            if(name.length > 0){
                this.addZoneWithName(name, parent);
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'Error'), MIOLocalizeString('MUST A WRITE A NAME FOR ZONE', 'Must write a name for zone'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CLOSE','CLOSE'), MUIAlertActionStyle.Cancel, this, function(){}));
                
                this.presentViewController(error, true);
            }
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, this, function(){}));
        
        this.presentViewController(avc, true);
    }
}