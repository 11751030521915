//
// Generated class SupportStream
//

/// <reference path="SupportStream_ManagedObject.ts" />

class SupportStream extends SupportStream_ManagedObject
{

}
