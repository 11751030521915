

enum FilterControllerValueType
{
    String,
    Number,
    Date,
    Enum
}

enum FilterControllerComparatorType 
{
    MinorOrEqualComparator,
    MinorComparator,    
    MajorOrEqualComparator,
    MajorComparator,
    EqualComparator,
    DistinctComparator,
    Contains
}

enum FilterControllerTokenType 
{
    UUIDValue,
    BooleanValue,
    NumberValue,
    NullValue,
    StringValue,
    LongStringValue,
    
    OR,
    AND,
    
    MinorOrEqualComparator,
    MinorComparator,    
    MajorOrEqualComparator,
    MajorComparator,
    EqualComparator,
    DistinctComparator,

    Whitespace
}

function FilterComparatorStringByType(type:FilterControllerComparatorType):string{
    switch(type){
        case FilterControllerComparatorType.MinorOrEqualComparator:
            return " <= ";                
        case FilterControllerComparatorType.MinorComparator:
            return " < ";
        case FilterControllerComparatorType.MajorOrEqualComparator:
            return " >= ";
        case FilterControllerComparatorType.MajorComparator:
            return " > ";
        case FilterControllerComparatorType.EqualComparator:
            return " = ";
        case FilterControllerComparatorType.DistinctComparator:
            return " != ";
        case FilterControllerComparatorType.Contains:
            return " CONTAINS ";    
        default:
            return " = ";
    }

    return null;
}


class Filter extends MIOObject 
{
    identifier:string;
    key:string;
    value:string;
    type = FilterControllerValueType.String;
    defaultComparator:FilterControllerComparatorType = null;    
    predicateFormat:string = null;

    initWithIdentifier(identifier:string, key:string, type:FilterControllerValueType){
        super.init();
        this.identifier = identifier;
        this.key = key;
        this.type = type;
    }
}

class FilterController extends MIOObject
{    
    static newControllerWithFilters(filters){
        let fc = new FilterController();
        fc.initWithFilters(filters);

        return fc;
    }

    initWithFilters(filters){
        super.init();
        for (let index = 0; index < filters.length; index++){
            let f = filters[index];
            this.addFilter(f);
        }
    }

    private filters = {};
    addFilter(filter:Filter){        
        this.filters[filter.identifier] = filter;
    }
    
    clearFilterAtKey(identfier:string){
        let filter = this.filters[identfier];
        filter.predicatFormat = null;
    }

    parseFilterValueAtIdentifier(identfier:string, value){
        let filter = this.filters[identfier];        
        filter.predicateFormat = this.parse(value, filter.key, filter.type, FilterComparatorStringByType(filter.defaultComparator));
    }

    predicateFormat(){
        let array = [];
        for (let identifier in this.filters){
            let f = this.filters[identifier];
            if (f.value == null) continue;
            let format = this.parse(f.value, f.key, f.type, FilterComparatorStringByType(f.defaultComparator));
            array.push(format);
        }

        if (array.length == 0) return null;

        return array.join(" AND ");
    }

    private tokenizeWithFormat(format:string):MIOCoreLexer{
        
        let lexer = new MIOCoreLexer(format);
        
        lexer.addTokenType(FilterControllerTokenType.UUIDValue, /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i);
        // Symbols
        // this.lexer.addTokenType(MIOPredicateTokenType.OpenParenthesisSymbol, /^\(/);
        // this.lexer.addTokenType(MIOPredicateTokenType.CloseParenthesisSymbol, /^\)/);
        // Comparators
        lexer.addTokenType(FilterControllerTokenType.MinorOrEqualComparator, /^<=/);
        lexer.addTokenType(FilterControllerTokenType.MinorComparator, /^</);
        lexer.addTokenType(FilterControllerTokenType.MajorOrEqualComparator, /^>=/);
        lexer.addTokenType(FilterControllerTokenType.MajorComparator, /^>/);
        lexer.addTokenType(FilterControllerTokenType.EqualComparator, /^==?/);
        lexer.addTokenType(FilterControllerTokenType.DistinctComparator, /^!=/);
        // this.lexer.addTokenType(MIOPredicateTokenType.NotContainsComparator, /^not contains /i);
        // this.lexer.addTokenType(MIOPredicateTokenType.ContainsComparator, /^contains /i);
        // this.lexer.addTokenType(MIOPredicateTokenType.InComparator, /^in /i);
        // Join operators
        lexer.addTokenType(FilterControllerTokenType.AND, /^(and|&) /i);
        lexer.addTokenType(FilterControllerTokenType.OR, /^(or|\|) /i);        
        lexer.addTokenType(FilterControllerTokenType.OR, /^,/);                

        // Extra
        lexer.addTokenType(FilterControllerTokenType.Whitespace, /^\s+/);        
        lexer.ignoreTokenType(FilterControllerTokenType.Whitespace);

        // Values
        lexer.addTokenType(FilterControllerTokenType.NumberValue, /^-?\d+(?:\.\d+)?(?:e[+\-]?\d+)?/i);
        lexer.addTokenType(FilterControllerTokenType.BooleanValue, /^(true|false)/i);
        lexer.addTokenType(FilterControllerTokenType.NullValue, /^(null|nil)/i);
        lexer.addTokenType(FilterControllerTokenType.StringValue, /^[a-zA-Z0-9-_\.\/\-]+/);
        lexer.addTokenType(FilterControllerTokenType.LongStringValue, /^"([^"]*)"|^'([^']+)'/);
        
        lexer.tokenize();

        return lexer;
    }  
    
    private parse(format:string, key:string, filterType:FilterControllerValueType, defaultComparator:string){        
        let lexer = this.tokenizeWithFormat(format);

        let token = lexer.nextToken();
        let predicates = [];
        let exit = false;        

        let comparator = null;
        let value = null;
        let type = null;

        while (token != null && exit == false) {
            
            type = token.type;
            switch (token.type) {

                case FilterControllerTokenType.BooleanValue:
                case FilterControllerTokenType.NumberValue:
                case FilterControllerTokenType.NullValue:
                case FilterControllerTokenType.LongStringValue:
                case FilterControllerTokenType.StringValue:
                case FilterControllerTokenType.UUIDValue:
                    value = token.value;
                    break;

                case FilterControllerTokenType.AND:                    
                    predicates.push(this.predicateWithValues(key, value, type, filterType, comparator, defaultComparator));
                    predicates.push(" AND ");
                    value = null;
                    comparator = null;
                    break;

                case FilterControllerTokenType.OR:
                predicates.push(this.predicateWithValues(key, value, type, filterType, comparator, defaultComparator));
                    predicates.push(" OR ");
                    value = null;
                    comparator = null;
                    break;                    

                default:
                    comparator = token.value;
                    break;
            }

            if (exit == false) { 
                token = lexer.nextToken();
            }
        }

        if (value != null) predicates.push(this.predicateWithValues(key, value, type, filterType, comparator, defaultComparator));
                
        return predicates.join("");        
    }

    private predicateWithValues(key:string, value, tokenType:FilterControllerTokenType, filterType:FilterControllerValueType, comparator:string, defaultComparator:string){
        let cmp = null;

        if (comparator != null) cmp = comparator;
        else if (defaultComparator != null) cmp = defaultComparator;
        else if (filterType == FilterControllerValueType.String) cmp = " CONTAINS ";
        else cmp = " == ";
        
        let v = null;                
        if (filterType == FilterControllerValueType.String ) v = "'" + value + "'";        
        else v = value;

        return key + cmp + v;
    }
}