//
// Generated class MenuRate
//

/// <reference path="MenuRate_ManagedObject.ts" />

class MenuRate extends MenuRate_ManagedObject
{

}
