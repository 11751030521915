
class TicketSnapshotLogDataSource extends MIOObject
{
    private tableView:UITableView = null;
    initWithTableView(tableView:UITableView){
        this.tableView = tableView;
    }

    private _filterValues = null;
    private lines = null;
    private locationName = null;
    setValues(filterValues, lines, locationName:string){        
        this._filterValues = filterValues;
        this.lines = lines;        
        this.locationName = locationName;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableView:MUITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:MUITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let cell = tableView.dequeueReusableCellWithIdentifier("LogCell") as TicketSnapshotLogCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as LogOperation;
        
        cell.setItem(item, indexPath.row + 1, this.locationName, this.lines);        

        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('LogOperation');
        
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('date', true)];                                                                                

        let predicateFormat = "type == " + LogOperationType.System;        
        predicateFormat += " AND subType == " + LogOperationSubtype.Print;
        predicateFormat += " AND logLevel == " + LogLevelType.Log;
        predicateFormat += " AND sourceFile == 'PosTicketPrinter'";
        predicateFormat += " AND ticketID == '" + this._filterValues["documentID"] + "'";
        //let predicateFormat = "orderID == '" + this._filterValues["orderID"] + "'";

        fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);
        
        let fetchedResultsController = new MIOFetchedResultsController(); 
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;        

        fetchedResultsController.performFetch(); 
        
        this._fetchedResultsController = fetchedResultsController;   
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }

}