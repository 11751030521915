

/// <reference path="PaymentTransaction.ts" />

// Generated class OnlineOrderPaymentTransaction_ManagedObject

class OnlineOrderPaymentTransaction_ManagedObject extends PaymentTransaction
{
    // Relationship: onlineOrder
    set onlineOrder(value:OnlineOrder) {
        this.setValueForKey(value, 'onlineOrder');
    }
    get onlineOrder():OnlineOrder {
        return this.valueForKey('onlineOrder') as OnlineOrder;
    }
}
