

class ReportTextFilterCell extends MUITableViewCell
{
    delegate = null;
    
    private _filter = null;

    private titleLabel = null;
    private textField = null;

    awakeFromHTML()
    {
        this.titleLabel = MUIOutlet(this, "rfv_c2_lbl", "MUILabel");
        this.textField = MUIOutlet(this, "rfv_c2_text", "MUITextField");
        this.textField.setOnChangeText(this, function(control, value){

            if (this.delegate != null)
                this.delegate.didChangeSwitchCellValue(this, value, this._filter);
        });
    }

    set filter(filter){

        this._filter = filter;
        this.titleLabel.text = filter["title"] + ":";
    }
}