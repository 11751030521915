

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Order_ManagedObject

class Order_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: bookingClientID
    set bookingClientID(value:string) {
        this.setValueForKey(value, 'bookingClientID');
    }
    get bookingClientID():string {
        return this.valueForKey('bookingClientID');
    }
    set bookingClientIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'bookingClientID');
    }
    get bookingClientIDPrimitiveValue():string {
        return this.primitiveValueForKey('bookingClientID');
    }

    // Property: bookingClientName
    set bookingClientName(value:string) {
        this.setValueForKey(value, 'bookingClientName');
    }
    get bookingClientName():string {
        return this.valueForKey('bookingClientName');
    }
    set bookingClientNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'bookingClientName');
    }
    get bookingClientNamePrimitiveValue():string {
        return this.primitiveValueForKey('bookingClientName');
    }

    // Property: bookingID
    set bookingID(value:string) {
        this.setValueForKey(value, 'bookingID');
    }
    get bookingID():string {
        return this.valueForKey('bookingID');
    }
    set bookingIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'bookingID');
    }
    get bookingIDPrimitiveValue():string {
        return this.primitiveValueForKey('bookingID');
    }

    // Property: bookingMinimumFee
    set bookingMinimumFee(value:number) {
        this.setValueForKey(value, 'bookingMinimumFee');
    }
    get bookingMinimumFee():number {
        return this.valueForKey('bookingMinimumFee');
    }
    set bookingMinimumFeePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'bookingMinimumFee');
    }
    get bookingMinimumFeePrimitiveValue():number {
        return this.primitiveValueForKey('bookingMinimumFee');
    }

    // Property: changesCounter
    set changesCounter(value:number) {
        this.setValueForKey(value, 'changesCounter');
    }
    get changesCounter():number {
        return this.valueForKey('changesCounter');
    }
    set changesCounterPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'changesCounter');
    }
    get changesCounterPrimitiveValue():number {
        return this.primitiveValueForKey('changesCounter');
    }

    // Property: clientStoredCards
    set clientStoredCards(value:string) {
        this.setValueForKey(value, 'clientStoredCards');
    }
    get clientStoredCards():string {
        return this.valueForKey('clientStoredCards');
    }
    set clientStoredCardsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientStoredCards');
    }
    get clientStoredCardsPrimitiveValue():string {
        return this.primitiveValueForKey('clientStoredCards');
    }

    // Property: colorIndex
    set colorIndex(value:number) {
        this.setValueForKey(value, 'colorIndex');
    }
    get colorIndex():number {
        return this.valueForKey('colorIndex');
    }
    set colorIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'colorIndex');
    }
    get colorIndexPrimitiveValue():number {
        return this.primitiveValueForKey('colorIndex');
    }

    // Property: comments
    set comments(value:string) {
        this.setValueForKey(value, 'comments');
    }
    get comments():string {
        return this.valueForKey('comments');
    }
    set commentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'comments');
    }
    get commentsPrimitiveValue():string {
        return this.primitiveValueForKey('comments');
    }

    // Property: contactName
    set contactName(value:string) {
        this.setValueForKey(value, 'contactName');
    }
    get contactName():string {
        return this.valueForKey('contactName');
    }
    set contactNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'contactName');
    }
    get contactNamePrimitiveValue():string {
        return this.primitiveValueForKey('contactName');
    }

    // Property: createdDeviceID
    set createdDeviceID(value:string) {
        this.setValueForKey(value, 'createdDeviceID');
    }
    get createdDeviceID():string {
        return this.valueForKey('createdDeviceID');
    }
    set createdDeviceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdDeviceID');
    }
    get createdDeviceIDPrimitiveValue():string {
        return this.primitiveValueForKey('createdDeviceID');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: deliveryErrorComment
    set deliveryErrorComment(value:string) {
        this.setValueForKey(value, 'deliveryErrorComment');
    }
    get deliveryErrorComment():string {
        return this.valueForKey('deliveryErrorComment');
    }
    set deliveryErrorCommentPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deliveryErrorComment');
    }
    get deliveryErrorCommentPrimitiveValue():string {
        return this.primitiveValueForKey('deliveryErrorComment');
    }

    // Property: deliveryOrderIndex
    set deliveryOrderIndex(value:number) {
        this.setValueForKey(value, 'deliveryOrderIndex');
    }
    get deliveryOrderIndex():number {
        return this.valueForKey('deliveryOrderIndex');
    }
    set deliveryOrderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'deliveryOrderIndex');
    }
    get deliveryOrderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('deliveryOrderIndex');
    }

    // Property: deliveryReceivedByClient
    set deliveryReceivedByClient(value:boolean) {
        this.setValueForKey(value, 'deliveryReceivedByClient');
    }
    get deliveryReceivedByClient():boolean {
        return this.valueForKey('deliveryReceivedByClient');
    }
    set deliveryReceivedByClientPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'deliveryReceivedByClient');
    }
    get deliveryReceivedByClientPrimitiveValue():boolean {
        return this.primitiveValueForKey('deliveryReceivedByClient');
    }

    // Property: deliveryReceivedClientDocument
    set deliveryReceivedClientDocument(value:string) {
        this.setValueForKey(value, 'deliveryReceivedClientDocument');
    }
    get deliveryReceivedClientDocument():string {
        return this.valueForKey('deliveryReceivedClientDocument');
    }
    set deliveryReceivedClientDocumentPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deliveryReceivedClientDocument');
    }
    get deliveryReceivedClientDocumentPrimitiveValue():string {
        return this.primitiveValueForKey('deliveryReceivedClientDocument');
    }

    // Property: deliveryReceivedClientName
    set deliveryReceivedClientName(value:string) {
        this.setValueForKey(value, 'deliveryReceivedClientName');
    }
    get deliveryReceivedClientName():string {
        return this.valueForKey('deliveryReceivedClientName');
    }
    set deliveryReceivedClientNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deliveryReceivedClientName');
    }
    get deliveryReceivedClientNamePrimitiveValue():string {
        return this.primitiveValueForKey('deliveryReceivedClientName');
    }

    // Property: deliveryWorkerName
    set deliveryWorkerName(value:string) {
        this.setValueForKey(value, 'deliveryWorkerName');
    }
    get deliveryWorkerName():string {
        return this.valueForKey('deliveryWorkerName');
    }
    set deliveryWorkerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'deliveryWorkerName');
    }
    get deliveryWorkerNamePrimitiveValue():string {
        return this.primitiveValueForKey('deliveryWorkerName');
    }

    // Property: depositMoney
    set depositMoney(value:number) {
        this.setValueForKey(value, 'depositMoney');
    }
    get depositMoney():number {
        return this.valueForKey('depositMoney');
    }
    set depositMoneyPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'depositMoney');
    }
    get depositMoneyPrimitiveValue():number {
        return this.primitiveValueForKey('depositMoney');
    }

    // Property: isOnline
    set isOnline(value:boolean) {
        this.setValueForKey(value, 'isOnline');
    }
    get isOnline():boolean {
        return this.valueForKey('isOnline');
    }
    set isOnlinePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isOnline');
    }
    get isOnlinePrimitiveValue():boolean {
        return this.primitiveValueForKey('isOnline');
    }

    // Property: isParked
    set isParked(value:boolean) {
        this.setValueForKey(value, 'isParked');
    }
    get isParked():boolean {
        return this.valueForKey('isParked');
    }
    set isParkedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isParked');
    }
    get isParkedPrimitiveValue():boolean {
        return this.primitiveValueForKey('isParked');
    }

    // Property: isPayedOnline
    set isPayedOnline(value:boolean) {
        this.setValueForKey(value, 'isPayedOnline');
    }
    get isPayedOnline():boolean {
        return this.valueForKey('isPayedOnline');
    }
    set isPayedOnlinePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isPayedOnline');
    }
    get isPayedOnlinePrimitiveValue():boolean {
        return this.primitiveValueForKey('isPayedOnline');
    }

    // Property: maximumFee
    set maximumFee(value:number) {
        this.setValueForKey(value, 'maximumFee');
    }
    get maximumFee():number {
        return this.valueForKey('maximumFee');
    }
    set maximumFeePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'maximumFee');
    }
    get maximumFeePrimitiveValue():number {
        return this.primitiveValueForKey('maximumFee');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: onlineOrderStatus
    set onlineOrderStatus(value:number) {
        this.setValueForKey(value, 'onlineOrderStatus');
    }
    get onlineOrderStatus():number {
        return this.valueForKey('onlineOrderStatus');
    }
    set onlineOrderStatusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'onlineOrderStatus');
    }
    get onlineOrderStatusPrimitiveValue():number {
        return this.primitiveValueForKey('onlineOrderStatus');
    }

    // Property: onlinePlatformID
    set onlinePlatformID(value:string) {
        this.setValueForKey(value, 'onlinePlatformID');
    }
    get onlinePlatformID():string {
        return this.valueForKey('onlinePlatformID');
    }
    set onlinePlatformIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'onlinePlatformID');
    }
    get onlinePlatformIDPrimitiveValue():string {
        return this.primitiveValueForKey('onlinePlatformID');
    }

    // Property: onlinePlatformName
    set onlinePlatformName(value:string) {
        this.setValueForKey(value, 'onlinePlatformName');
    }
    get onlinePlatformName():string {
        return this.valueForKey('onlinePlatformName');
    }
    set onlinePlatformNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'onlinePlatformName');
    }
    get onlinePlatformNamePrimitiveValue():string {
        return this.primitiveValueForKey('onlinePlatformName');
    }

    // Property: orderAllergens
    set orderAllergens(value:string) {
        this.setValueForKey(value, 'orderAllergens');
    }
    get orderAllergens():string {
        return this.valueForKey('orderAllergens');
    }
    set orderAllergensPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderAllergens');
    }
    get orderAllergensPrimitiveValue():string {
        return this.primitiveValueForKey('orderAllergens');
    }

    // Property: orderID
    set orderID(value:string) {
        this.setValueForKey(value, 'orderID');
    }
    get orderID():string {
        return this.valueForKey('orderID');
    }
    set orderIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderID');
    }
    get orderIDPrimitiveValue():string {
        return this.primitiveValueForKey('orderID');
    }

    // Property: ownerWorkerID
    set ownerWorkerID(value:string) {
        this.setValueForKey(value, 'ownerWorkerID');
    }
    get ownerWorkerID():string {
        return this.valueForKey('ownerWorkerID');
    }
    set ownerWorkerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ownerWorkerID');
    }
    get ownerWorkerIDPrimitiveValue():string {
        return this.primitiveValueForKey('ownerWorkerID');
    }

    // Property: ownerWorkerName
    set ownerWorkerName(value:string) {
        this.setValueForKey(value, 'ownerWorkerName');
    }
    get ownerWorkerName():string {
        return this.valueForKey('ownerWorkerName');
    }
    set ownerWorkerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ownerWorkerName');
    }
    get ownerWorkerNamePrimitiveValue():string {
        return this.primitiveValueForKey('ownerWorkerName');
    }

    // Property: qrCode
    set qrCode(value:string) {
        this.setValueForKey(value, 'qrCode');
    }
    get qrCode():string {
        return this.valueForKey('qrCode');
    }
    set qrCodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'qrCode');
    }
    get qrCodePrimitiveValue():string {
        return this.primitiveValueForKey('qrCode');
    }

    // Property: routeID
    set routeID(value:string) {
        this.setValueForKey(value, 'routeID');
    }
    get routeID():string {
        return this.valueForKey('routeID');
    }
    set routeIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'routeID');
    }
    get routeIDPrimitiveValue():string {
        return this.primitiveValueForKey('routeID');
    }

    // Property: routeName
    set routeName(value:string) {
        this.setValueForKey(value, 'routeName');
    }
    get routeName():string {
        return this.valueForKey('routeName');
    }
    set routeNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'routeName');
    }
    get routeNamePrimitiveValue():string {
        return this.primitiveValueForKey('routeName');
    }

    // Property: sessionDeliveryDay
    set sessionDeliveryDay(value:string) {
        this.setValueForKey(value, 'sessionDeliveryDay');
    }
    get sessionDeliveryDay():string {
        return this.valueForKey('sessionDeliveryDay');
    }
    set sessionDeliveryDayPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sessionDeliveryDay');
    }
    get sessionDeliveryDayPrimitiveValue():string {
        return this.primitiveValueForKey('sessionDeliveryDay');
    }

    // Property: sessionDeliveryIsNextDay
    set sessionDeliveryIsNextDay(value:boolean) {
        this.setValueForKey(value, 'sessionDeliveryIsNextDay');
    }
    get sessionDeliveryIsNextDay():boolean {
        return this.valueForKey('sessionDeliveryIsNextDay');
    }
    set sessionDeliveryIsNextDayPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'sessionDeliveryIsNextDay');
    }
    get sessionDeliveryIsNextDayPrimitiveValue():boolean {
        return this.primitiveValueForKey('sessionDeliveryIsNextDay');
    }

    // Property: sessionDeliveryTime
    set sessionDeliveryTime(value:string) {
        this.setValueForKey(value, 'sessionDeliveryTime');
    }
    get sessionDeliveryTime():string {
        return this.valueForKey('sessionDeliveryTime');
    }
    set sessionDeliveryTimePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sessionDeliveryTime');
    }
    get sessionDeliveryTimePrimitiveValue():string {
        return this.primitiveValueForKey('sessionDeliveryTime');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: subtype
    set subtype(value:number) {
        this.setValueForKey(value, 'subtype');
    }
    get subtype():number {
        return this.valueForKey('subtype');
    }
    set subtypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'subtype');
    }
    get subtypePrimitiveValue():number {
        return this.primitiveValueForKey('subtype');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: timestamp
    set timestamp(value:number) {
        this.setValueForKey(value, 'timestamp');
    }
    get timestamp():number {
        return this.valueForKey('timestamp');
    }
    set timestampPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'timestamp');
    }
    get timestampPrimitiveValue():number {
        return this.primitiveValueForKey('timestamp');
    }

    // Property: todayOrderNumber
    set todayOrderNumber(value:number) {
        this.setValueForKey(value, 'todayOrderNumber');
    }
    get todayOrderNumber():number {
        return this.valueForKey('todayOrderNumber');
    }
    set todayOrderNumberPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'todayOrderNumber');
    }
    get todayOrderNumberPrimitiveValue():number {
        return this.primitiveValueForKey('todayOrderNumber');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }
    // Relationship: booking
    set booking(value:Booking) {
        this.setValueForKey(value, 'booking');
    }
    get booking():Booking {
        return this.valueForKey('booking') as Booking;
    }
    // Relationship: businessArea
    set businessArea(value:BusinessArea) {
        this.setValueForKey(value, 'businessArea');
    }
    get businessArea():BusinessArea {
        return this.valueForKey('businessArea') as BusinessArea;
    }
    // Relationship: deliveryAddress
    set deliveryAddress(value:Address) {
        this.setValueForKey(value, 'deliveryAddress');
    }
    get deliveryAddress():Address {
        return this.valueForKey('deliveryAddress') as Address;
    }
    // Relationship: deliveryWorker
    set deliveryWorker(value:Employee) {
        this.setValueForKey(value, 'deliveryWorker');
    }
    get deliveryWorker():Employee {
        return this.valueForKey('deliveryWorker') as Employee;
    }
    // Relationship: economicEntity
    set economicEntity(value:EconomicEntity) {
        this.setValueForKey(value, 'economicEntity');
    }
    get economicEntity():EconomicEntity {
        return this.valueForKey('economicEntity') as EconomicEntity;
    }

    // Relationship: extraLocations
    protected _extraLocations:MIOManagedObjectSet = null;
    get extraLocations():MIOManagedObjectSet {
        return this.valueForKey('extraLocations');
    }
    addExtraLocationsObject(value:Location) {
        this._addObjectForKey(value, 'extraLocations');
    }
    removeExtraLocationsObject(value:Location) {
        this._removeObjectForKey(value, 'extraLocations');
    }
    // Relationship: location
    set location(value:Location) {
        this.setValueForKey(value, 'location');
    }
    get location():Location {
        return this.valueForKey('location') as Location;
    }
    // Relationship: onlinePhoneNumber
    set onlinePhoneNumber(value:PhoneNumber) {
        this.setValueForKey(value, 'onlinePhoneNumber');
    }
    get onlinePhoneNumber():PhoneNumber {
        return this.valueForKey('onlinePhoneNumber') as PhoneNumber;
    }
    // Relationship: paymentTransaction
    set paymentTransaction(value:OrderPaymentTransaction) {
        this.setValueForKey(value, 'paymentTransaction');
    }
    get paymentTransaction():OrderPaymentTransaction {
        return this.valueForKey('paymentTransaction') as OrderPaymentTransaction;
    }

    // Relationship: tickets
    protected _tickets:MIOManagedObjectSet = null;
    get tickets():MIOManagedObjectSet {
        return this.valueForKey('tickets');
    }
    addTicketsObject(value:Ticket) {
        this._addObjectForKey(value, 'tickets');
    }
    removeTicketsObject(value:Ticket) {
        this._removeObjectForKey(value, 'tickets');
    }
}
