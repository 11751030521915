
class ReportOption
{
    reportID:string = null;
    key:string = null;
    columns:ReportColumnOption[] = [];
    columnsByKey = {};

    constructor(reportID:string, key:string) {
        this.reportID = reportID;
        this.key = key;
    }

    updateColumnValues(key:string, title:string, hidden:boolean, defaultHidden:boolean) {
        let col = this.columnsByKey[key] as ReportColumnOption;
        let user_opt = ReportView.userOptionsMapping[this.reportID + this.key + key];
        
        if (col == null) {
            col = new ReportColumnOption(key, title);
            this.columnsByKey[key] = col;
            this.columns.addObject(col);
        }
        col.userOption = user_opt;
        col.hidden = user_opt ? user_opt.hidden : (hidden ?? false);
        col.defaultHidden = defaultHidden ?? false;
    }

    isColumnHidden(key:string) {
        let col = this.columnsByKey[key] as ReportColumnOption;
        if (col == null) return false;
        return col.hidden;
    }
}
class ReportColumnOption
{
    key:string = null;  
    title:string = null;  
    hidden:boolean = false;
    defaultHidden:boolean = false;
    userOption:UserReportOption = null;

    constructor(key:string, title:string) {
        this.key = key;
        this.title = title;
    }
}

class ReportView extends MUIView
{    
    delegate = null;

    initWithLayer(layer, owner, options?){
        super.initWithLayer(layer, owner, options);

        // Check if we have prototypes
        if (this.layer.childNodes.length > 0) {
            for (let index = 0; index < this.layer.childNodes.length; index++) {
                let subLayer = this.layer.childNodes[index];

                if (subLayer.tagName != "DIV")
                    continue;

                if (subLayer.getAttribute("data-table-content") != null){
                    this.addTableContentWithLayer(subLayer);                    
                }    
                else if (subLayer.getAttribute("data-layout-column") != null) {
                    this.sectionColumnLayer = subLayer;
                    subLayer.style.display = "none";
                }    
                else if (subLayer.getAttribute("data-section-text") != null) {
                    this.sectionTextLayer = subLayer;                    
                }
                else if (subLayer.getAttribute("data-section-table") != null) {
                    this.sectionTableLayer = subLayer;
                    subLayer.style.display = "none";
                }            
                else if (subLayer.getAttribute("data-section-chart") != null) {
                    this.sectionChartLayer = subLayer;
                    subLayer.style.display = "none";
                }            
                else if (subLayer.getAttribute("data-section-header") != null) {
                    this.sectionHeaderLayer = subLayer;
                    subLayer.style.display = "none";
                }            
            }
        }        
        
    }

    private _reportID:string = null;
    set reportID(reportID:string) {
        this._reportID = reportID;
        // ReportView.userOptionsMap(reportID);
        ReportView.options = [];
        ReportView.optionsByKey = {};
    }

    get reportID():string {
        return this._reportID;
    }

    static userOptionsMapping = null;
    static userOptionsMap(reportID:string){        
        this.userOptionsMapping = {};
        // Mapping        
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let userOptions = ad.selectedUser.reportOptions.filterWithPredicate(MIOPredicate.predicateWithFormat("reportID=" + reportID));
        this.userOptionsMapping = {};

        for(let index = 0; index < userOptions.count; index++){
            let opt = userOptions.objectAtIndex(index) as UserReportOption; 
            this.userOptionsMapping[reportID + opt.reportItemKey + opt.reportColumnKey] = opt;
        }

        return this.userOptionsMapping;

    }

    private static optionsByKey = {};
    private static options = [];
    
    get reportOptions():ReportOption[] {
        return ReportView.options;
    };

    // set reportOptions(options:ReportOption[]) {
    //     ReportView.options = options;
    //     ReportView.optionsByKey = {};

    //     for (let optIndex = 0; optIndex < options.length; optIndex++) {
    //         let opt = options[optIndex];
    //         ReportView.optionsByKey[opt.key] = opt;
    //     }
    // }

    static addVisibilityItem(reportID:string, key:string) : ReportOption {
        if (ReportView.optionsByKey[key] != null) return;

        let opt = new ReportOption(reportID, key);

        ReportView.optionsByKey[key] = opt;
        ReportView.options.addObject(opt);

        return opt;
    }

    static addVisibilityColumn(reportID:string, itemKey:string, colKey:string, title:string, hidden:boolean, defaultHidden:boolean){
        let item = ReportView.optionsByKey[itemKey];
        if (item == null) {
            item = ReportView.addVisibilityItem(reportID, itemKey);
        }
        item.updateColumnValues(colKey, title, hidden, defaultHidden);
    }

    static isUserColumnHidden(itemKey:string, colKey:string) : any {
        let item = ReportView.optionsByKey[itemKey];
        if (item == null) return null;
        return item.isColumnHidden(colKey);
    }

    private sectionColumnLayer = null;
    private sectionRowLayer = null;    
    private sectionTextLayer = null;    
    private sectionTableLayer = null;
    private sectionChartLayer = null;
    private sectionHeaderLayer = null;

    private contentView:MUIView = null;
    private addTableContentWithLayer(layer){
        this.contentView = new MUIView();
        this.contentView.initWithLayer(layer, this);
    }

    clear(){
        for (let index = 0; index < this.contentView.subviews.length; index++){
            let v = this.contentView.subviews[index] as MUIView;
            try {
                v.removeFromSuperview();    
            } catch (error) {
                
            }            
        }

        // while (this.contentView.layer.firstChild) {
        //     this.contentView.layer.removeChild(this.contentView.layer.firstChild);
        // }

        this.contentView.layer.innerHTML = "";
    }

    logText(text:string){
        if (this.sectionTextLayer == null) return;
        let tv = ReportScriptLogTitleView.titleViewWithLayer(this.sectionTextLayer);        
        this.contentView.addSubview(tv);

        tv.title = text;
    }    

    logQuery(objects){        
        if (objects.length == 0) {
            this.logText("No query results");
            return;
        }

        let tv = this.newTable(null);
        //this.contentView.addSubview(tv);

        // Create columns headers
        let line = objects[0];
        tv.newHeaderRow();        
        for (let key in line){            
            tv.addColumn(key);
        }        

        for (let index = 0; index < objects.length; index++){
            line = objects[index];
            tv.newRow();
            for (let key in line){
                let value = line[key];
                tv.addCell(value);
            }
        }
    }

    logTable(table){
        let title = table["Title"];
        let tv = this.newTable(title);
        let columns = table["Columns"];
        let subTotals = table["SubTotals"];
        
        tv.newHeaderRow();
        for (let index = 0; index < columns.length; index++){
            let col = columns[index];
            if (subTotals[ col["Key"] ] != null) col["SubTotal"] = true;
            ReportView.addVisibilityColumn(this.reportID, title, col["Key"], col["Title"], col["Hidden"], col["Hidden"]);
            tv.addColumnItem(col);
        }   

        let rows = table["Rows"];

        for (let index = 0; index < rows.length; index++){
            let r = rows[index];
            let subRow = r["__sub_row__"];
            let segueIdentifier = r["__segue_identifier__"];
            let segueValues = r["__segue_values__"];
            tv.newRow(subRow, segueIdentifier, segueValues);
            for (let colIndex = 0; colIndex < columns.length; colIndex++){
                let col = columns[colIndex];
                let key = col["Key"];
                if (ReportView.isUserColumnHidden(title, key) == true) continue;
                let type = col["Type"];
                let align = col["Align"];
                let value = r[key];
                let subCell = false;
                if (r["__" + key + "__formatter__"] != null) {
                    type = r["__" + key + "__formatter__"];
                }
                tv.addCell(value, type, align, false, key);
            }
        }

        if (rows.length == 0) return;

        if (Object.keys(subTotals).length === 0) return;
        tv.newFooterRow();
        for (let colIndex = 0; colIndex < columns.length; colIndex++){            
            let col = columns[colIndex];
            let key = col["Key"];            
            let type = col["Type"];
            let align = col["Align"];            
            
            if (ReportView.isUserColumnHidden(title, key) == true) continue;

            if (colIndex == 0) {
                tv.addCell(MIOLocalizeString("TOTAL", "TOTAL"), type, align, false);
            }
            else {
                let value = subTotals[key];                
                tv.addCell(value, type, align, false);
            }            
            
        }
    }

    private logChart(chart){
        let title = chart["Title"];
        let cv = this.newChart(title);
        let ch_type = chart["ChartType"];
        if (ch_type == 100) ch_type = 0;
        cv.type = ch_type;

        let segueIdentifier = chart["__segue_identifier__"];
        let segueValues = chart["__segue_values__"];
        cv.setNextList(segueIdentifier, segueValues)

        let columns = chart["Columns"];        
        let rows = chart["Rows"];
        for (let index = 0; index < rows.length; index++){
            let r = rows[index];                                    
            cv.newRow();
            for (let colIndex = 0; colIndex < 2/*columns.length*/; colIndex++){
                let col = columns[colIndex];                
                let key = col["Key"];
                let type = col["Type"];
                let value = r[key];                
                if (r["__" + key + "__formatter__"] != null) {
                    type = r["__" + key + "__formatter__"];
                }
                cv.addCell(colIndex, value, type, true);
            }
        }

        cv.render();
    }

    private logHeader(header){
        let title = header["Title"];
        let hv = ReporHeaderView.headerWithLayer(this.sectionHeaderLayer);                

        let segueIdentifier = header["__segue_identifier__"];
        let segueValues = header["__segue_values__"];                
        hv.setNextList(segueIdentifier, segueValues);
        hv.delegate = this.delegate;
        
        if (this.currentColumnsView == null){
            this.contentView.addSubview(hv);   
        }
        else {
            this.currentColumnsView.addSubview(hv);
        }

        hv.title = title;        
        hv.value = ReportHelper.formattedValueForType(header["Value"], header["FieldType"], false);
    }
    
    parseResponse(lines){    
        ReportView.userOptionsMap(this._reportID);

        if (lines == null) {
            this.logText("Results came with empty values");
            return;
        }
        for (let index = 0; index < lines.length; index++){            
            let l = lines[index];
            if (l == null) continue; 
            let type = l["Type"];
            let value = l["Value"];
            switch(type){
                case "header":
                this.logHeader(l);
                break;

                case "chart":
                this.logChart(l);
                break;

                case "query":
                //this.logQuery(value);
                break;                

                case "table":
                this.logTable(l);
                break;

                case "begin-columns":
                this.beginColumns();
                break;

                case "end-columns":
                this.endColumns();
                break;

                case "filter":
                this.parseFilters(l);

                default:
                //this.logText(value);
                break;
            }            
        }        
    }
    
    private newTable(title:string){
        let tv = ReportTableView.tableViewWithLayer(this.sectionTableLayer);
        if (this.currentColumnsView == null){
            this.contentView.addSubview(tv);   
        }
        else {
            this.currentColumnsView.addSubview(tv);
        }

        tv.title = title;
        tv.delegate = this.delegate;
        return tv;
    }

    private currentColumnsView:ReporScriptLogColumn = null;
    private beginColumns(){
        if (MIOCoreIsPhone() == true) return;
        this.currentColumnsView = ReporScriptLogColumn.columnWithLayer(this.sectionColumnLayer);
        this.contentView.addSubview(this.currentColumnsView);
    }

    private endColumns(){        
        this.currentColumnsView = null;
    }    

    private newChart(title:string):ReportChartView{
        let cv = ReportChartView.chartViewWithLayer(this.sectionChartLayer);
        if (this.currentColumnsView == null){
            this.contentView.addSubview(cv);   
        }
        else {
            this.currentColumnsView.addSubview(cv);
        }

        cv.title = title;
        cv.delegate = this.delegate;
        return cv;
    }

    filters = [];
    private parseFilters(item:any){        
        this.filters = item["Filters"]; 
    }
}

class ReportScriptLogTitleView extends MUIView
{
    static titleViewWithLayer(layer){
        let l = layer.cloneNode(true);
        l.style.display = "";            

        let tv = new ReportScriptLogTitleView();
        tv.initWithLayer(l, this);
        tv.awakeFromHTML();

        return tv;
    }

    private titleLabel:MUILabel = null;
    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
    }

    set title (title:string){
        this.titleLabel.text = title;
    }
}

class ReporScriptLogColumn extends MUIView 
{
    static columnWithLayer(layer):ReporScriptLogColumn{
        let l = layer.cloneNode(true);
        l.style.display = "";            

        let c = new ReporScriptLogColumn();
        c.initWithLayer(l, this);
        c.awakeFromHTML();

        return c;
    }
}

class ReportTableView extends MUIView
{        
    delegate = null;    

    static tableViewWithLayer(layer):ReportTableView{
        let l = layer.cloneNode(true);        
        l.style.display = "";            

        let tv = new ReportTableView();
        tv.initWithLayer(l, this);
        tv.awakeFromHTML();
        l.innerHTML = "";

        return tv;
    }
   
    private sectionTableTitleLayer = null;
    private sectionTableHeaderRowLayer = null;
    private sectionTableHeaderColumnLayer = null;
    private sectionTableHeaderColumnFilterLayer = null;
    private sectionTableRowLayer = null;
    private sectionTableSubRowLayer = null;
    private sectionTableRowCellLayer = null;    
    private sectionTableFooterRowLayer = null;
    private filterController:ColumnFilterController = null;

    initWithLayer(layer, owner, options?){
        super.initWithLayer(layer, owner, options);

        // Check if we have prototypes
        if (this.layer.childNodes.length > 0) {
            for (let index = 0; index < this.layer.childNodes.length; index++) {
                let subLayer = this.layer.childNodes[index];

                if (subLayer.tagName != "DIV")
                    continue;

                if (subLayer.getAttribute("data-section-table-title") != null) {
                    this.sectionTableTitleLayer = subLayer;
                    subLayer.style.display = "none";
                }                
                else if (subLayer.getAttribute("data-section-table-header-row") != null) {
                    this.sectionTableHeaderRowLayer = subLayer;
                    subLayer.style.display = "none";
                }                
                else if (subLayer.getAttribute("data-section-table-header-column") != null) {
                    this.sectionTableHeaderColumnLayer = subLayer;
                    subLayer.style.display = "none";
                }                
                else if (subLayer.getAttribute("data-section-table-header-column-filter") != null) {
                    this.sectionTableHeaderColumnFilterLayer = subLayer;
                    subLayer.style.display = "none";
                }                
                else if (subLayer.getAttribute("data-section-table-row") != null) {
                    this.sectionTableRowLayer = subLayer;
                    subLayer.style.display = "none";
                }                
                else if (subLayer.getAttribute("data-section-table-subrow") != null) {
                    this.sectionTableSubRowLayer = subLayer;
                    subLayer.style.display = "none";
                }                
                else if (subLayer.getAttribute("data-section-table-row-cell") != null) {
                    this.sectionTableRowCellLayer = subLayer;
                    subLayer.style.display = "none";
                }                
                else if (subLayer.getAttribute("data-section-table-footer-row") != null) {
                    this.sectionTableFooterRowLayer = subLayer;
                    subLayer.style.display = "none";
                }                
            }
        }        
    }

    awakeFromHTML(){
        this.filterController = new ColumnFilterController();
        this.filterController.initWithDelegate(this);
    }

    private newLayer(layer){
        let newLayer = layer.cloneNode(true);
        newLayer.style.display = "";
        return newLayer;
    }    

    private _title:string = null;
    set title(title:string){
        if (title == null) return;
        this._title = title;
        if (this.sectionTableTitleLayer == null) return;

        let layer = this.newLayer(this.sectionTableTitleLayer);
        let titleView = new ReportTableTitleView();
        titleView.initWithLayer(layer, this);
        titleView.awakeFromHTML();
        
        titleView.title = title;
        this.addSubview(titleView);
    }

    get title():string {
        return this._title;
    }

    private currentRowLayer = null;    
    newHeaderRow(){
        let layer = this.newLayer(this.sectionTableHeaderRowLayer);                  
        this.layer.appendChild(layer);
        this.currentRowLayer = layer;
    }

    newHeaderColumn(title:string, align?:string, columnFilter?:ColumnFilterController, key?:string, type?:ColumnFilterTextFieldType, hidden?:boolean){
        let column:ReportTableColumnView = null;            

        if (ReportView.isUserColumnHidden(this.title, key) == true) return;

        // if (filter == true) {
            let layer = this.newLayer(this.sectionTableHeaderColumnFilterLayer);
            column = ReportTableColumnFilterView.columnFilterViewWithLayer(layer, title, align, columnFilter, key, type);
        // }
        // else {
        //     let layer = this.newLayer(this.sectionTableHeaderColumnFilterLayer);
        //     column = ReportTableColumnView.columnViewWithLayer(layer, title, align);
        // }
        
        this.currentRowLayer.appendChild(column.layer);        
    }

    columns = [];
    addColumn(title:string, key?:string, type?:string, align?:string, filter?:ColumnFilterController, hidden?:boolean){
        let col = {"Title": title, "Key": key, "Type": type, "Align": align, "ColumnFilter": filter};
        this.columns.push(col);
        this.newHeaderColumn(title, align, filter, key, null, hidden);
    }

    addColumnItem(item:any){                
        this.columns.push(item);
        let filterType = ColumnFilterTextFieldType.String
        switch(item["Type"]){
            case "number":
            case "integer": filterType = ColumnFilterTextFieldType.Number; break;                        
            case "currency": filterType = ColumnFilterTextFieldType.Currency; break;
            case "long-currency": filterType = ColumnFilterTextFieldType.Currency; break;
            case "date": filterType = ColumnFilterTextFieldType.Date; break;
            case "date-time": filterType = ColumnFilterTextFieldType.DateTime; break;
            case "time": filterType = ColumnFilterTextFieldType.Time; break;            
            default: filterType = ColumnFilterTextFieldType.String; break;
        }
        this.newHeaderColumn(item["Title"], item["Align"], this.filterController, item["Key"], filterType, item["Hidden"]);
    }

    private items = [];    
    private rowIndex = -1;
    private rowLayers = [];
    newRow(subCell?:boolean, segueIdentifier?:string, segueValues?, filtering:boolean = false){
        if (subCell == true){
            this.currentRowLayer = this.newLayer(this.sectionTableSubRowLayer);
        }
        else {
            this.currentRowLayer = this.newLayer(this.sectionTableRowLayer);
        }
        this.layer.appendChild(this.currentRowLayer);
        this.rowLayers.addObject(this.currentRowLayer);

        if (filtering == false) { 
            this.items.addObject({
                "__sub_row__": subCell,
                "__segue_identifier__": segueIdentifier,
                "__segue_values__": segueValues    
            });
            this.rowIndex++;
        }

        if (segueIdentifier == null) return;
        let instance = this;
        this.currentRowLayer.addEventListener("click", function(){
            instance.didRowClick.call(instance, segueIdentifier, segueValues);
        });        
    }
    
    addCell(value:any, type?:string, align?:string, showNull?:boolean, key?:string, filtering:boolean = false){
        if (this.currentRowLayer == null) return;
        
        let layer = this.newLayer(this.sectionTableRowCellLayer);
        if (align != null) MUICoreLayerAddStyle(layer, "align-" + align);
        if (value != null) layer.innerHTML = value;
        this.currentRowLayer.appendChild(layer);

        let v = ReportHelper.formattedValueForType(value, type, showNull == true ? true : false);
        layer.innerHTML = v;

        if (filtering == false && key != null && this.rowIndex > -1) {
            let item = new ReportTableItem();
            item.value = value;
            this.items[this.rowIndex][key] = value;
        }
    }

    newFooterRow(){
        let layer = this.newLayer(this.sectionTableFooterRowLayer);
        this.layer.appendChild(layer);
        this.currentRowLayer = layer;
        this.rowLayers.addObject(this.currentRowLayer);
    }

    private didRowClick(segueIdentifier:string, segueValues){
        if (this.delegate != null){
            this.delegate.performSegueWithIdentfier(segueIdentifier, segueValues);
        }
    }

    filterPredicateDidChange(controller:ColumnFilterController){        
        let filterPredicateFormat = this.filterController.filterPredicateFormat();
        if (filterPredicateFormat == null) {
            this.parseRows(this.items);
        }
        else {
            let filterItems = _MIOPredicateFilterObjects(this.items, MIOPredicate.predicateWithFormat(filterPredicateFormat));
            this.parseRows(filterItems);
        }
    }

    parseRows(rows:any[]){     
        
        for (let l of this.rowLayers) { this.layer.removeChild(l); }
        this.rowLayers = [];
        
        let subTotals = {}
        for (let c of this.columns) { 
            if (c["SubTotal"] == true) subTotals[c ["Key"] ] = 0; 
        }

        for (let index = 0; index < rows.length; index++){
            let r = rows[index];
            let subRow = r["__sub_row__"];
            let segueIdentifier = r["__segue_identifier__"];
            let segueValues = r["__segue_values__"];
            this.newRow(subRow, segueIdentifier, segueValues, true);
            for (let colIndex = 0; colIndex < this.columns.length; colIndex++){
                let col = this.columns[colIndex];
                let key = col["Key"];
                if (ReportView.isUserColumnHidden(this.title, key) == true) continue;
                let type = col["Type"];
                let align = col["Align"];
                let value = r[key];
                let subCell = false;
                if (r["__" + key + "__formatter__"] != null) {
                    type = r["__" + key + "__formatter__"];
                }
                this.addCell(value, type, align, false, key, true);

                if (subTotals[col["Key"]] != null) {
                    subTotals[col["Key"]] += value;
                }
            }
        }

        if (rows.length == 0) return;

        if (Object.keys(subTotals).length === 0) return;
        this.newFooterRow();
        for (let colIndex = 0; colIndex < this.columns.length; colIndex++){            
            let col = this.columns[colIndex];
            let key = col["Key"];            
            let type = col["Type"];
            let align = col["Align"];            
            if (ReportView.isUserColumnHidden(this.title, key) == true) continue;
            
            if (colIndex == 0) {
                this.addCell(MIOLocalizeString("TOTAL", "TOTAL"), type, align, false);
            }
            else {
                let value = subTotals[key];                
                this.addCell(value, type, align, false);
            }   
        }
    }
}


class ReportTableItem extends MIOObject
{
    value:any = null;
}
class ReportTableTitleView extends MUIView
{
    private titleLabel:MUILabel = null;

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
    }

    set title(title:string){
        this.titleLabel.text = MIOLocalizeString(title, title);
    }
}

class ReportTableColumnView extends MUIView
{
    static columnViewWithLayer(layer, title:string, align?:string):ReportTableColumnView {        
        let column = new ReportTableColumnView();
        column.initWithLayer(layer, this);
        column.awakeFromHTML();     
        
        if (align != null) MUICoreLayerAddStyle(layer, "text-align" + align);
        column.title = MIOLocalizeString(title, title);

        return column;
    }

    set title(title:string){
        this.layer.innerHTML = MIOLocalizeString(title, title);
    }
}

class ReportTableColumnFilterView extends ReportTableColumnView
{
    static columnFilterViewWithLayer(layer, title:string, align?:string, filter?:ColumnFilterController, key?:string, type?:ColumnFilterTextFieldType):ReportTableColumnFilterView {
        let column = new ReportTableColumnFilterView();
        column.initWithLayer(layer, this);
        column.awakeFromHTML();     
        
        if (align != null) MUICoreLayerAddStyle(layer, "text-align" + align);
        column.title = MIOLocalizeString(title, title);

        column.setFilterValues(filter, key, type);
        
        return column; 
    }

    private sortButton:MUIButton = null;
    private filterTextField:ColumnFilterTextField = null;

    awakeFromHTML(){
        this.sortButton = MUIOutlet(this, "sort-btn", "MUIButton");
        this.sortButton.setAction(this, function() {

        });
        this.filterTextField = MUIOutlet(this, "filter-tf", "ColumnFilterTextField");
    }

    set title(title:string){
        let t = MIOLocalizeString(title, title);
        this.filterTextField.setPlaceholderText( t );
        //this.filterTextField.inputLayer.style.minWidth = ( t.length * 7 ) + "px";
        this.filterTextField.layer.style.minWidth = ( t.length * 7 + 4 + 15) + "px"; //4px padding and 15px button width included
    }

    setFilterValues(controller:ColumnFilterController, key:string, type:ColumnFilterTextFieldType) {
        this.filterTextField.filterController = controller;
        this.filterTextField.setOnFilterChange(type, key, null, null);
    }
}


class ReportChartView extends MUIView
{        
    delegate = null;

    static chartViewWithLayer(layer):ReportChartView {
        let l = layer.cloneNode(true);        
        l.style.display = "";            

        let cv = new ReportChartView();
        cv.initWithLayer(l, this);
        cv.awakeFromHTML();
        //l.innerHTML = "";

        return cv;
    }

    private titleLabel:MUILabel = null;
    private chartView:MUIChartView = null;

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "chart-title-lbl", "MUILabel");
        this.chartView = MUIOutlet(this, "chart-view", "MUIChartView");

        let tapGesture = new MUITapGestureRecognizer();
        tapGesture.initWithTarget(this, this.didTap);
        this.chartView.addGestureRecognizer(tapGesture);
    }

    set title(title:string){
        this.titleLabel.text = MIOLocalizeString(title, title);
    }   
    
    type = MUIChartViewType.Bar; 

    private _segueIdentifier:string = null;
    private _segueValues:any = null;
    
    setNextList(segueIdentifier?:string, segueValues?:any) {
        this._segueIdentifier = segueIdentifier;
        this._segueValues = segueValues;
    }

    newRow(){}

    private labels = [];
    private values = [];

    addCell(colIndex, value, type?:string, showNull?:boolean){
        if (colIndex == 0) this.labels.push(value);
        else if (colIndex == 1)  this.values.push(value);
    }

    render(){
        this.chartView.labels = this.labels;
        this.chartView.values = this.values;
        this.chartView.renderWithType(this.type);
    }

    didTap(gesture:MUIGestureRecognizer){
        if (gesture.state != MUIGestureRecognizerState.Ended) return;
        if (this.delegate == null) return;
        if (this._segueIdentifier == null) return;
        this.delegate.performSegueWithIdentfier(this._segueIdentifier, this._segueValues);
    }
}

class ReporHeaderView extends MUIView 
{
    delegate = null;

    private titleLabel:MUILabel = null;
    private valueLabel:MUILabel = null;

    static headerWithLayer(layer):ReporHeaderView {
        let l = layer.cloneNode(true);
        l.style.display = "";            

        let c = new ReporHeaderView();
        c.initWithLayer(l, this);
        c.awakeFromHTML();

        return c;
    }

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "header-title-lbl", "MUILabel");
        this.valueLabel = MUIOutlet(this, "header-value-lbl", "MUILabel");

        let tapGesture = new MUITapGestureRecognizer();
        tapGesture.initWithTarget(this, this.didTap);
        this.addGestureRecognizer(tapGesture);
    }

    set title(title:string){
        this.titleLabel.text = MIOLocalizeString(title, title);
    }

    set value(value:string){
        this.valueLabel.text = value;
    }

    private _segueIdentifier:string = null;
    private _segueValues:any = null;
    
    setNextList(segueIdentifier?:string, segueValues?:any) {
        this._segueIdentifier = segueIdentifier;
        this._segueValues = segueValues;
    }

    didTap(gesture:MUIGestureRecognizer){
        if (gesture.state != MUIGestureRecognizerState.Ended) return;
        if (this.delegate == null) return;
        if (this._segueIdentifier == null) return;
        this.delegate.performSegueWithIdentfier(this._segueIdentifier, this._segueValues);
    }

}
