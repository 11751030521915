
/// <reference path="../base/BaseViewController.ts" />
/// <reference path="PresetItemDetailCell.ts" />

class ProductPresetsDetailViewController extends MUIViewController
{
    static newInstance():ProductPresetsDetailViewController {
        let vc = new ProductPresetsDetailViewController("product-presets-detail-view");
        vc.initWithResource("layout/productpresets/ProductPresetsDetailView.html");
        return vc;
    }

    private backButton:MUIButton = null;
    private titleLabel:MUILabel = null;
    private settingsButton:MUIButton = null;
    private addButton:MUIButton = null;
    private saveButton:MUIButton = null;
    private collectionView:MUICollectionView = null;

    private moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;


    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, 'back-btn', 'MUIButton');
        this.backButton.setAction(this, function (){
            this.navigationController.popViewController();
        });

        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");

        this.settingsButton = MUIOutlet(this, 'settings-btn', 'MUIButton');
        this.settingsButton.setAction(this, function (){            
            this.showSettingsPopup();
        }); 

        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function (control, index){
            this.showAddPreset();
        });

        this.saveButton = MUIOutlet(this, 'save-btn', 'MUIButton');
        this.saveButton.setAction(this, function (control, index){
            DBHelper.saveMainContext();
        });
        
        this.collectionView = MUIOutlet(this, 'collection-view', 'MUICollectionView');
        this.collectionView.dataSource = this;
        this.collectionView.delegate = this;  
        this.collectionView.collectionViewLayout.itemSize = new MIOSize(140, 180);    
        this.collectionView.calculateLayout = true;
        
        // this.minimumLineSpacing = 0;
        // this.minimumInteritemSpacing = 0;
        // let w = this.collectionView.getWidth() / 3;
        // let h = this.collectionView.getHeight() / 4;        
        // this.collectionView.collectionViewLayout.itemSize = new MIOSize(w, h);
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _preset:Preset = null;
    set preset(item:Preset){
        this._preset = item;
    }   

    private _presetCategory:PresetCategory = null;
    set presetCategory(item:PresetCategory){
        this._presetCategory = item;
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._preset == null) return;

        this.titleLabel.text = (this._presetCategory) ? this._presetCategory.name : "Preset Detail";
        //set variables from item here
        if (this._presetCategory) {
            this.backButton.layer.classList.remove('hidden');
        } else {
            this.backButton.layer.classList.add('hidden');
        }        

        this.fetchedResultsController = null;
        this.collectionView.reloadData();        
    }

    numberOfSections(collectionView:MUICollectionView) {
        return this.fetchedResultsController.sections.count;
    }

    numberOfItemsInSection(collectionView:MUICollectionView, section) {
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellForItemAtIndexPath(collectionView:MUICollectionView, indexPath:MIOIndexPath){
        let cell = collectionView.dequeueReusableCellWithIdentifier("PresetItemDetailCell") as PresetItemDetailCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as PresetItem;
        cell.item = item;        
        return cell;
    }

    didSelectCellAtIndexPath(collectionView:MUICollectionView, indexPath:MIOIndexPath){
        
        let cell = collectionView.cellAtIndexPath(indexPath) as PresetItemDetailCell;
        let object = this.fetchedResultsController.objectAtIndexPath(indexPath);

        // if ( object instanceof PresetCategory ) {

        //     let vc = new ProductPresetsDetailViewController("product-presets-detail-view");
        //     vc.initWithResource("layout/productpresets/ProductPresetsDetailView.html");
        //     vc.item = this._preset;
        //     vc.presetCategory = object;

        //     this.navigationController.pushViewController(vc);
        // }                        

        // if (object.presetCategory) {
        //     this.selectedPresetCategory = object.presetCategory;
        // }

        if (object instanceof PresetBlank) {
           // DELETE
           // CANCEL
           //alert("blank");

        } else if (object instanceof PresetProduct) {
            // EDIT
            // MOVE
            // DELETE
            // CALCEL
            //alert("product");

        } else if(object instanceof PresetCategory) {

            // OPEN
            let vc = ProductPresetsDetailViewController.newInstance();
            vc.preset = this._preset;
            vc.presetCategory = object;
            this.navigationController.pushViewController(vc);

            // EDIT
            // MOVE
            // CLONE TO PRESET "Select destination preset"
            // CANCEL
            // APPLY FORMAT
            // EMPTY
            // DELETE (redistribute order index)
            

        } else if (object instanceof PresetMenu) {
            // MOVE
            // DELETE
            // CANCEL
            //alert("menu");

        }
        
    }

    // editingStyleForRowAtIndexPath(collectionView:MUICollectionView, indexPath:MIOIndexPath) {         
    //     return MUICollectionViewCellEditingStyle.Delete;
    // }

    // commitEditingStyleForRowAtIndexPath(collectionView:MUICollectionView, editingStyle:MUICollectionViewCellEditingStyle, indexPath:MIOIndexPath) {        

    //     if (editingStyle == MUICollectionViewCellEditingStyle.Delete && this.productModifierCategory.productModifierCategoryType != ProductModifierCategoryType.Category) {
    //         let item = this.fetchedResultsController.objectAtIndexPath(indexPath);    
    //         this.removeProductModifier(item);
    //     }
    // } 

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
  
        let ad = MUIWebApplication.sharedInstance().delegate;
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey("orderIndex", true)];

        let pf = "preset.identifier == " + this._preset.identifier;        
        pf += " AND presetCategory.identifier == " + ( this._presetCategory != null ? this._presetCategory.identifier : "null" );

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("PresetItem", sortDescriptors, pf);
        fetchRequest.relationshipKeyPathsForPrefetching = ["presetCategory", "product"];  

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){        
        this.collectionView.reloadData();
    }


    //show notice functions
    private showSettingsPopup() {
        alert("Show settings popup");
    }

    private showAddPreset(){

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('ADD ITEM',"ADD ITEM"), MIOLocalizeString('CHOOSE A PRESET TYPE',"CHOOSE A PRESET TYPE"), MUIAlertViewStyle.Default);    

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD PRODUCTS PRESET',"ADD PRODUCTS PRESET"), MUIAlertActionStyle.Default, this, function(){                        
            // ADD NEW PRODUCT PRESET
            let vc = AppHelper.sharedInstance().showCustomSelectMultiViewControllerFromView(null, 'Product', 'name', null, this, function(controller, objects:any){
                if (objects.length > 0) {
                    for (let i=0; i<objects.length; i++) {
                        this.addProductPreset(objects[i]);
                    }
                    this.moc.save();
                }
            }, false );   
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD CATEGORY PRESET',"ADD CATEGORY PRESET"), MUIAlertActionStyle.Default, this, function(){                        
            // ADD NEW CATEGORY PRESET
            this.addCategoryPresetPopup();
            // save found in popup
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD CATEGORY AND PRODUCT PRESETS',"ADD CATEGORY AND PRODUCT PRESETS"), MUIAlertActionStyle.Default, this, function(){                        
            // ADD NEW PRODUCT CATEGORY PRESET
            let vc = AppHelper.sharedInstance().showCustomSelectMultiViewControllerFromView(null, 'ProductCategory', 'name', null, this, function(controller, objects:any){
                if (objects.length > 0) {
                    for (let i=0; i<objects.length; i++) {
                        this.addCategoryAndProductPreset(objects[i]);
                    }
                    this.moc.save();
                }
            }, false );   
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD MENU PRESET',"ADD MENU PRESET"), MUIAlertActionStyle.Default, this, function(){                        
            // ADD NEW MENU PRESET
            let vc = AppHelper.sharedInstance().showCustomSelectMultiViewControllerFromView(null, 'Menu', 'name', null, this, function(controller, objects:any){
                if (objects.length > 0) {
                    for (let i=0; i<objects.length; i++) {
                        this.addMenuPreset(objects[i]);
                    }
                    this.moc.save();
                }
            }, false );   
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD BLANK PRESET',"ADD BLANK PRESET"), MUIAlertActionStyle.Default, this, function(){                        
            // ADD NEW BLANK PRESET
            this.addBlankPreset();
            this.moc.save();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL',"CANCEL"), MUIAlertActionStyle.Cancel, null, null));
        this.presentViewController(avc, true);    
    }

    private addCategoryPresetPopup() {
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('ADD CATEGORY PRESET',"ADD CATEGORY PRESET"), MIOLocalizeString('CHOOSE A NAME',"CHOOSE A NAME"), MUIAlertViewStyle.Default);    
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
            textField.setPlaceholderText(MIOLocalizeString("NAME", "NAME"));
        });
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD',"ADD"), MUIAlertActionStyle.Default, this, function(){                        
            let nameTF =  avc.textFields[0];
            let name = nameTF.text.trim();
            if(name.length > 0){
                this.addCategoryPreset(name);
                this.moc.save();
            }
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL',"CANCEL"), MUIAlertActionStyle.Cancel, null, null));
        this.presentViewController(avc, true);    
    }

    private addProductPreset(product:Product){
        if (product){
            let productPreset = MIOEntityDescription.insertNewObjectForEntityForName("PresetProduct", this.moc) as PresetProduct;
            productPreset.identifier = MIOUUID.UUID().UUIDString;
            productPreset.preset = this._preset;
            productPreset.presetCategory = (this._presetCategory) ? this._presetCategory : null;
            productPreset.product = product;
            productPreset.formatID = null;
            // productPreset.orderIndex = 0;
        }
    }

    private addCategoryPreset(name:string) {
        if(name){
            let categoryPreset = MIOEntityDescription.insertNewObjectForEntityForName("PresetCategory", this.moc) as PresetCategory;
            categoryPreset.identifier = MIOUUID.UUID().UUIDString;
            categoryPreset.preset = this._preset;
            categoryPreset.presetCategory = (this._presetCategory) ? this._presetCategory : null;
            categoryPreset.name = name;
            // categoryPreset.image = null;
            // productPreset.orderIndex = 0;
        }
    }

    private addCategoryAndProductPreset(category:ProductCategory) {
        if(category){

            // Create "PresetCategory" for given category
            let categoryPreset = MIOEntityDescription.insertNewObjectForEntityForName("PresetCategory", this.moc) as PresetCategory;
            categoryPreset.identifier = MIOUUID.UUID().UUIDString;
            categoryPreset.preset = this._preset;
            categoryPreset.presetCategory = (this._presetCategory) ? this._presetCategory : null;
            //add image from category
            categoryPreset.name = category.name;

            // Get all products in given category and create "PresetProducts" for each
            let sds = [MIOSortDescriptor.sortDescriptorWithKey("orderIndex", true)];
            DBHelper.queryObjectsWithCompletion("Product", sds, MIOPredicate.predicateWithFormat("category.identifier == " + category.identifier), ["category"], this, function(objects){
                let objs = objects;

                for(let i = 0; i<objects.length;i++) {
                    let productPreset = MIOEntityDescription.insertNewObjectForEntityForName("PresetProduct", this.moc) as PresetProduct;
                    productPreset.identifier = MIOUUID.UUID().UUIDString;
                    productPreset.preset = this._preset;
                    productPreset.presetCategory = categoryPreset;
                    productPreset.product = objects[i];
                    //productPreset.formatID = 
                }

                this.moc.save();

            });    
            
            // let categoryPreset = MIOEntityDescription.insertNewObjectForEntityForName("PresetCategory", this.moc) as PresetCategory;
            // categoryPreset.identifier = MIOUUID.UUID().UUIDString;
            // categoryPreset.preset = this._preset;
            // categoryPreset.presetCategory = (this._presetCategory) ? this._presetCategory : null;
            // categoryPreset.image = null;
            // productPreset.orderIndex = 

    //         for (ProductCategory *category in categoriesArray)
    // {
    //         if (category.superCategory)
    //         {
    //             if ([categoriesArray containsObject:category.superCategory])
    //                 continue; // Should be inserted by superCategory
    //         }
            
    //         PresetCategory *presetCategory = [self createPresetCategoryAndProductsWithProductCategory:category];
    //         [self recursiveCreateSubPresetCategoriesAndProductsFromProductCategory:category presetCategory:presetCategory filteringCategoriesInArray:categoriesArray];
    //     }

        }
    }

//     - (void) recursiveCreateSubPresetCategoriesAndProductsFromProductCategory:(ProductCategory *)category presetCategory:(PresetCategory *)presetCategory filteringCategoriesInArray:(NSArray *)categoriesArray
// {
//     for (ProductCategory *subCategory in category.subCategories)
//     {
//         if ([categoriesArray containsObject:subCategory])
//         {
//             PresetCategory *presetSubcategory = [self createPresetCategoryAndProductsWithProductCategory:subCategory];
//             presetSubcategory.presetCategory = presetCategory;
//             [self recursiveCreateSubPresetCategoriesAndProductsFromProductCategory:subCategory presetCategory:presetSubcategory filteringCategoriesInArray:categoriesArray];
            
//         }
//     }
// }

private createPresetCategoryAndProductsWithProductCategory (productCategory:ProductCategory) {

    let categoryPreset = MIOEntityDescription.insertNewObjectForEntityForName("PresetCategory", this.moc) as PresetCategory;
    categoryPreset.identifier = MIOUUID.UUID().UUIDString;
    categoryPreset.preset = this._preset;
    categoryPreset.presetCategory = (this._presetCategory) ? this._presetCategory : null;
    categoryPreset.name = productCategory.name;
    // categoryPreset.image = null;
    // productPreset.orderIndex = 0;
}


// - (PresetCategory *) createPresetCategoryAndProductsWithProductCategory:(ProductCategory *)category
// {
//     PresetCategory *presetCategory = [DBHelper createNewPresetItemCategoryForPreset:self.preset name:category.name image:category.image inContext:category.managedObjectContext];
    
//     NSSet *productsSet = [category.products filteredSetUsingPredicate:[NSPredicate predicateWithFormat:@"isAvailable == YES"]];
//     NSArray *orderedArray = [productsSet sortedArrayUsingDescriptors:@[[NSSortDescriptor sortDescriptorWithKey:@"orderIndex" ascending:YES]]];
//     for (Product *product in orderedArray)
//     {
//         PresetProduct *presetProduct = [DBHelper createNewPresetItemProductForPreset:self.preset product:product inContext:mAppDelegate.adminMOC];
//         presetProduct.presetCategory = presetCategory;
//     }
    
//     return presetCategory;
// }

    private addMenuPreset(menu:Menu) {
        if(menu){
            let menuPreset = MIOEntityDescription.insertNewObjectForEntityForName("PresetMenu", this.moc) as PresetMenu;
            menuPreset.identifier = MIOUUID.UUID().UUIDString;
            menuPreset.preset = this._preset;
            menuPreset.presetCategory = (this._presetCategory) ? this._presetCategory : null;
            menuPreset.menu = menu;
            // productPreset.orderIndex = 0;
        }
    }

    private addBlankPreset() {
        let categoryPreset = MIOEntityDescription.insertNewObjectForEntityForName("PresetBlank", this.moc) as PresetBlank;
        categoryPreset.identifier = MIOUUID.UUID().UUIDString;
        categoryPreset.preset = this._preset;
        categoryPreset.presetCategory = (this._presetCategory) ? this._presetCategory : null;
        // productPreset.orderIndex = 0;
    }

}