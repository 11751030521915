//
// Generated class SMSTemplate
//

/// <reference path="SMSTemplate_ManagedObject.ts" />

class SMSTemplate extends SMSTemplate_ManagedObject
{

}
