


class WorkerDetailPlaceViewController extends MUIViewController
{
    private _worker:Employee = null;

    private tableView:MUITableView = null;
    
    viewDidLoad()
    {
        super.viewDidLoad();
    }

    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
        this.updateUI();
    }
    set worker(value)
    {
        this._worker = value;
        this.updateUI();
    }

    private updateUI()
    {
        if(this._worker != null && this.viewIsLoaded)
        {

        }
    }
}