
class VIPSourceViewController extends MUIViewController
{
    private addButton:MUIButton = null;
    private tableView:MUITableView = null;    

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function(){
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            let source = BookingSource.insertNewSource("New VIP Source", BookingSourceType.VIP, ad.managedObjectContext);
            ad.managedObjectContext.save();
        });

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.tableView.reloadData();
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell:VIPSourceCell = tableview.dequeueReusableCellWithIdentifier("SourceCell");

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as BookingSource;
        cell.item = item;        

        return cell;
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        let predicateFormat = "type == " + BookingSourceType.VIP;
        
        let fetchRequest = DBHelper.listFetchRequestWithEntityName('BookingSource', sortDescriptors, predicateFormat);

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;

        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }
}