class PlaceTagCell extends UITableViewCell
{
    private titleLabel:MUILabel = null;    

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    set item(item:any){
        this.titleLabel.text = MIOLocalizeString(item["name"], item["name"]);
    }

    private _config:ReplicaConfig = null;
    set config( item:ReplicaConfig ) {
        this._config = item;

        this.accessoryType = item != null ? UITableViewCellAccessoryType.Checkmark : UITableViewCellAccessoryType.None; 
    }   
}