

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class TicketPromotionalCodeTransactions_ManagedObject

class TicketPromotionalCodeTransactions_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: code
    set code(value:string) {
        this.setValueForKey(value, 'code');
    }
    get code():string {
        return this.valueForKey('code');
    }
    set codePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'code');
    }
    get codePrimitiveValue():string {
        return this.primitiveValueForKey('code');
    }

    // Property: lineID
    set lineID(value:string) {
        this.setValueForKey(value, 'lineID');
    }
    get lineID():string {
        return this.valueForKey('lineID');
    }
    set lineIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'lineID');
    }
    get lineIDPrimitiveValue():string {
        return this.primitiveValueForKey('lineID');
    }

    // Property: ticketID
    set ticketID(value:string) {
        this.setValueForKey(value, 'ticketID');
    }
    get ticketID():string {
        return this.valueForKey('ticketID');
    }
    set ticketIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ticketID');
    }
    get ticketIDPrimitiveValue():string {
        return this.primitiveValueForKey('ticketID');
    }
}
