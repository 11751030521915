

/// <reference path="ArchivedDocument.ts" />

// Generated class ArchivedInvoice_ManagedObject

class ArchivedInvoice_ManagedObject extends ArchivedDocument
{

    // Property: expirationDate
    set expirationDate(value:Date) {
        this.setValueForKey(value, 'expirationDate');
    }
    get expirationDate():Date {
        return this.valueForKey('expirationDate');
    }
    set expirationDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'expirationDate');
    }
    get expirationDatePrimitiveValue():Date {
        return this.primitiveValueForKey('expirationDate');
    }

    // Property: invoiceLegalEntityAddress
    set invoiceLegalEntityAddress(value:string) {
        this.setValueForKey(value, 'invoiceLegalEntityAddress');
    }
    get invoiceLegalEntityAddress():string {
        return this.valueForKey('invoiceLegalEntityAddress');
    }
    set invoiceLegalEntityAddressPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invoiceLegalEntityAddress');
    }
    get invoiceLegalEntityAddressPrimitiveValue():string {
        return this.primitiveValueForKey('invoiceLegalEntityAddress');
    }

    // Property: invoiceLegalEntityAddress2
    set invoiceLegalEntityAddress2(value:string) {
        this.setValueForKey(value, 'invoiceLegalEntityAddress2');
    }
    get invoiceLegalEntityAddress2():string {
        return this.valueForKey('invoiceLegalEntityAddress2');
    }
    set invoiceLegalEntityAddress2PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invoiceLegalEntityAddress2');
    }
    get invoiceLegalEntityAddress2PrimitiveValue():string {
        return this.primitiveValueForKey('invoiceLegalEntityAddress2');
    }

    // Property: invoiceLegalEntityDocument
    set invoiceLegalEntityDocument(value:string) {
        this.setValueForKey(value, 'invoiceLegalEntityDocument');
    }
    get invoiceLegalEntityDocument():string {
        return this.valueForKey('invoiceLegalEntityDocument');
    }
    set invoiceLegalEntityDocumentPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invoiceLegalEntityDocument');
    }
    get invoiceLegalEntityDocumentPrimitiveValue():string {
        return this.primitiveValueForKey('invoiceLegalEntityDocument');
    }

    // Property: invoiceLegalEntityID
    set invoiceLegalEntityID(value:string) {
        this.setValueForKey(value, 'invoiceLegalEntityID');
    }
    get invoiceLegalEntityID():string {
        return this.valueForKey('invoiceLegalEntityID');
    }
    set invoiceLegalEntityIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invoiceLegalEntityID');
    }
    get invoiceLegalEntityIDPrimitiveValue():string {
        return this.primitiveValueForKey('invoiceLegalEntityID');
    }

    // Property: invoiceLegalEntityName
    set invoiceLegalEntityName(value:string) {
        this.setValueForKey(value, 'invoiceLegalEntityName');
    }
    get invoiceLegalEntityName():string {
        return this.valueForKey('invoiceLegalEntityName');
    }
    set invoiceLegalEntityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invoiceLegalEntityName');
    }
    get invoiceLegalEntityNamePrimitiveValue():string {
        return this.primitiveValueForKey('invoiceLegalEntityName');
    }

    // Property: isIncomingInvoice
    set isIncomingInvoice(value:boolean) {
        this.setValueForKey(value, 'isIncomingInvoice');
    }
    get isIncomingInvoice():boolean {
        return this.valueForKey('isIncomingInvoice');
    }
    set isIncomingInvoicePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isIncomingInvoice');
    }
    get isIncomingInvoicePrimitiveValue():boolean {
        return this.primitiveValueForKey('isIncomingInvoice');
    }

    // Property: payMethodID
    set payMethodID(value:string) {
        this.setValueForKey(value, 'payMethodID');
    }
    get payMethodID():string {
        return this.valueForKey('payMethodID');
    }
    set payMethodIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'payMethodID');
    }
    get payMethodIDPrimitiveValue():string {
        return this.primitiveValueForKey('payMethodID');
    }

    // Property: payMethodName
    set payMethodName(value:string) {
        this.setValueForKey(value, 'payMethodName');
    }
    get payMethodName():string {
        return this.valueForKey('payMethodName');
    }
    set payMethodNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'payMethodName');
    }
    get payMethodNamePrimitiveValue():string {
        return this.primitiveValueForKey('payMethodName');
    }
}
