
class PurchaseOrderTemplateSubCell extends UITableViewCell
{
    private textArea:MUITextArea = null;

    awakeFromHTML(){
        this.textArea = MUIOutlet(this, "comments-ta", "MUITextArea");
        this.textArea.setOnChangeText(this, function(control:MUITextArea, value:string){
            this._item[ "Comments" ] = value.length > 0 ? value : null;
        });

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private _item:any = null;
    set item( item:any ){
        this._item = item;

        this.textArea.text = item[ "Comments" ];
    }

}