//
// Generated class Format
//

/// <reference path="Format_ManagedObject.ts" />

class Format extends Format_ManagedObject
{
    
}
