/**
 * Created by miguel on 13/2/17.
 */




class CategorySectionCell extends UITableViewCell
{
    nameLabel:MUILabel = null;

    awakeFromHTML() {
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    set item(i:any)
    {
        this.nameLabel.text = i.name;
    }

}