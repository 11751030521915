//
// Generated class MarginProducts
//

/// <reference path="MarginProducts_ManagedObject.ts" />

class MarginProducts extends MarginProducts_ManagedObject
{

}
