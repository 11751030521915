class DBProductFormatsCoder extends DBXLSXCoder
{
    protected mainEntity() {
        return { entity: "ProductFormat", predicateFormat: "deletedAt == null && product != null && product.deletedAt == null", relations:["product", "format", "product.category", "productSource", "ellaborationCenter"] };
    }

    protected columns() : any[] {    
        let cols = [            
            { title: "PRODUCT FORMAT ID", width: "10%", align: "center" },
            { title: "CATEGORY", width: "10%", align: "center" },
            { title: "PRODUCT ID", width: "10%", align: "center" },            
            { title: "PRODUCT NAME", width: "10%", align: "center" },
            { title: "FORMAT ID", width: "10%", align: "center" },            
            { title: "FORMAT NAME", width: "10%", align: "center" },                        
            { title: "CONSUMPTION QUANTITY", width: "10%", align: "center", formatter: this.ad.numberFormatter },
            { title: "CONSUMPTION MEASURE TYPE", width: "10%", align: "center" },
            { title: "LOSS QUANTITY", width: "10%", align: "center", formatter: this.ad.numberFormatter },
            { title: "LOSS MEASURE TYPE", width: "10%", align: "center" },            
            { title: "TOTAL CONSUMPTION QUANTITY", width: "10%", align: "center", formatter: this.ad.numberFormatter },
            { title: "TOTAL CONSUMPTION MEASURE TYPE", width: "10%", align: "center" },
            { title: "IS DEFAULT FORMAT", width: "10%", align: "center" },
            { title: "IS ENABLE FOR SELL", width: "10%", align: "center" },
            { title: "IS MODIFIERS REQUIRED", width: "10%", align: "center" },
            { title: "ADDITIONAL COST PRICE", width: "10%", align: "center" },
            { title: "COST PRICE", width: "10%", align: "center", formatter: this.ad.currencyFormatter },
            { title: "ORIGINAL PRICE", width: "10%", align: "center", formatter: this.ad.currencyFormatter },
            { title: "PRICE", width: "10%", align: "center", formatter: this.ad.currencyFormatter },
            { title: "GROUP ID", width: "10%", align: "center" },
            { title: "GROUP NAME", width: "10%", align: "center" },
            { title: "GROUP PRICE", width: "10%", align: "center", formatter: this.ad.currencyFormatter },
            { title: "INVITATION VALUE", width: "10%", align: "center" },
            { title: "PRODUCT SOURCE ID", width: "10%", align: "center" },
            { title: "PRODUCT SOURCE NAME", width: "10%", align: "center" },
            { title: "ELLABORATION CENTER ID", width: "10%", align: "center" },
            { title: "ELLABORATION CENTER NAME", width: "10%", align: "center" }

        ]
        return cols;
    }

    protected aditionalImportEntities(){
        return [
            { entity: "Format", predicateFormat: "deletedAt == null" },
            { entity: "Product", predicateFormat: "deletedAt == null" },
            { entity: "ProductSource", predicateFormat: "deletedAt == null" },
            { entity: "EllaborationCenter", predicateFormat: "deletedAt == null" }
        ]
    }

    protected importRow(row:any){

        let productFormatID     = row["PRODUCT FORMAT ID"];
        let productID           = row["PRODUCT ID"];
        let productName         = row["PRODUCT NAME"];
        let formatID            = row["FORMAT ID"];
        let formatName          = row["FORMAT NAME"];
        let addtionalCost       = row["ADDITIONAL COST PRICE"];
        let consumptionType     = row["CONSUMPTION MEASURE TYPE"];
        let consumptionQty      = row["CONSUMPTION QUANTITY"];
        let costPrice           = row["COST PRICE"];
        let groupID             = row["GROUP ID"];
        let groupName           = row["GROUP NAME"];
        let groupPrice          = row["GROUP PRICE"];
        let invitationValue     = row["INVITATION VALUE"];
        let isDefaultFormat     = row["IS DEFAULT FORMAT"];
        let isEnableForSell     = row["IS ENABLE FOR SELL"];
        let lossType            = row["LOSS MEASURE TYPE"];
        let lossQty             = row["LOSS QUANTITY"];
        let isModifiersRequired = row["IS MODIFIERS REQUIRED"];
        let originalPrice       = row["ORIGINAL PRICE"];
        let price               = row["PRICE"];
        let totalType           = row["TOTAL CONSUMPTION MEASURE TYPE"];
        let totalQty            = row["TOTAL CONSUMPTION QUANTITY"];
        let sourceID            = row["PRODUCT SOURCE ID"];
        let sourceName          = row["PRODUCT SOURCE NAME"];
        let centerID            = row["ELLABORATION CENTER ID"];
        let centerName          = row["ELLABORATION CENTER NAME"];
        
        let source = this.queryEntityByIDOrName("ProductSource", sourceID, sourceName);
        let center = this.queryEntityByIDOrName("EllaborationCenter", centerID, centerName);

        let format = this.queryEntityByIDOrName("Format", formatID, formatName) as Format;
        if (format == null) {
            return;
        }        

        let product = this.queryEntityByIDOrName("Product", productID, productName);
        if (product == null) {
            return;
        }        

        let pf = this.queryEntityByField("ProductFormat", "identifier", productFormatID?.toUpperCase()) as ProductFormat;        
        if (pf == null) pf = this.queryEntity("ProductFormat", "product.identifier = %@ and format.identifier = %@", product.identifier, format.identifier) as ProductFormat;
        if (pf == null) {
            pf = MIOEntityDescription.insertNewObjectForEntityForName("ProductFormat", DBHelper.mainManagedObjectContext) as ProductFormat;
            pf.identifier = this.parseOrCreateIdentifier(productFormatID);
            pf.format = format;
            pf.product = product;
            this.appendObject(pf);
        }            

        pf.consumptionMeasureType = MeasureUnits.measureUnitFromString(consumptionType);
        pf.consumptionQuantity = consumptionQty;
        pf.lossMeasureType = MeasureUnits.measureUnitFromString(lossType);
        pf.lossQuantity = lossQty;
        pf.totalConsumptionMeasureType = MeasureUnits.measureUnitFromString(totalType);
        pf.totalConsumptionQuantity = totalQty;
        pf.price = price;
        pf.originalPrice = originalPrice;
        pf.isDefaultFormat = this.parseBoolValue(isDefaultFormat);
        pf.modifiersRequired = this.parseBoolValue(isModifiersRequired);
        pf.isEnableForSell = this.parseBoolValue(isEnableForSell);
        pf.additionalCostPrice = addtionalCost;
        pf.invitationValue = invitationValue;
        pf.groupID = groupID;
        pf.groupName = groupName;
        pf.groupPrice = groupPrice;
        pf.costPrice = costPrice;

        // Validate consumptions

        if (consumptionQty == 0 && totalQty > 0) {
            pf.consumptionQuantity = lossQty - totalQty;
            pf.consumptionMeasureType = pf.totalConsumptionMeasureType;
        } 
        else if (consumptionQty > 0 && totalQty == 0) {
            pf.totalConsumptionQuantity = consumptionQty + lossQty;
            pf.totalConsumptionMeasureType = pf.consumptionMeasureType;
        }

        pf.productSource = source;
        pf.ellaborationCenter = center;        
        
        MIOLog("ADDING PRODUCT FORMAT: " + this.rowIndex + "/" + this.rows.length + ": " + productName + " - " + formatName);
    }

    // 
    // Export
    // 
    protected exportTitle(): string { return "product formats"; }

    protected exportSortDescriptors() {
        return [
            MIOSortDescriptor.sortDescriptorWithKey("product.category.name", true),
            MIOSortDescriptor.sortDescriptorWithKey("product.name", true),
            MIOSortDescriptor.sortDescriptorWithKey("format.name", true)
        ]
    }

    protected parseObject(object:MIOManagedObject): any {
        let productFormat = object as ProductFormat;

        let item = {
            "PRODUCT FORMAT ID" : productFormat.identifier,
            "CATEGORY"          : productFormat.product.category?.name,
            "PRODUCT ID"        : productFormat.product.identifier,
            "PRODUCT NAME"      : productFormat.product.name,
            "FORMAT ID"         : productFormat.format.identifier,
            "FORMAT NAME"       : productFormat.format.name,
            "ADDITIONAL COST PRICE" : productFormat.additionalCostPrice,
            "CONSUMPTION MEASURE TYPE": MeasureUnits.measureUnitTypeString(productFormat.consumptionMeasureType),
            "CONSUMPTION QUANTITY": productFormat.consumptionQuantity,
            "COST PRICE"        : productFormat.costPrice,
            "GROUP ID"          : productFormat.groupID,
            "GROUP NAME"        : productFormat.groupName,
            "GROUP PRICE"       : productFormat.groupPrice,
            "INVITATION VALUE"  : productFormat.invitationValue,
            "IS DEFAULT FORMAT" : productFormat.isDefaultFormat,
            "IS ENABLE FOR SELL" : productFormat.isEnableForSell,
            "LOSS MEASURE TYPE" : MeasureUnits.measureUnitTypeString(productFormat.lossMeasureType),
            "LOSS QUANTITY"     : productFormat.lossQuantity,
            "IS MODIFIERS REQUIRED" : productFormat.modifiersRequired,
            "ORIGINAL PRICE"    : productFormat.originalPrice,
            "PRICE"             : productFormat.price, 
            "TOTAL CONSUMPTION MEASURE TYPE" : MeasureUnits.measureUnitTypeString(productFormat.totalConsumptionMeasureType),
            "TOTAL CONSUMPTION QUANTITY" : productFormat.totalConsumptionQuantity,
            "PRODUCT SOURCE ID" : productFormat.productSource?.identifier,
            "PRODUCT SOURCE NAME" : productFormat.productSource?.name,
            "ELLABORATION CENTER ID" : productFormat.ellaborationCenter?.identifier,
            "ELLABORATION CENTER NAME" : productFormat.ellaborationCenter?.name,
            "PLACE ID" : productFormat.placeID
        }

        return item;
    }
}