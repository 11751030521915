
class DBConsumptionLogViewController extends BaseTableViewController
{
    static newInstance() : DBConsumptionLogViewController {
        let vc = new DBConsumptionLogViewController("db-consumption-log-view");
        vc.initWithResource("layout/db_consumption_log/DBConsumptionLogView.html");
        return vc;
    }
    
    private refreshButton:MUIButton = null; 

    private dateFilterTextField:ColumnFilterTextField = null; 
	private nameFilterTextField:ColumnFilterTextField = null; 
    private typeFilterTextField:ColumnFilterTextField = null;
	private quantityFilterTextField:ColumnFilterTextField = null;
    private costFilterTextField:ColumnFilterTextField = null;

    private columnFilterController:ColumnFilterController = null;

    viewDidLoad() {
        super.viewDidLoad();

        this.refreshButton = MUIOutlet(this, "refresh-btn", "MUIButton");  
        this.refreshButton.setAction(this, function(this:DBConsumptionLogViewController){
            this.invalidateData();
        });      

        this.columnFilterController = new ColumnFilterController();
        this.columnFilterController.initWithDelegate(this);

        this.dateFilterTextField = MUIOutlet(this, "date-filter-tf", "ColumnFilterTextField");
        this.dateFilterTextField.filterController = this.columnFilterController;
        this.dateFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Date, "date", null, null);

        this.nameFilterTextField = MUIOutlet(this, "parent-filter-tf", "ColumnFilterTextField");
        this.nameFilterTextField.filterController = this.columnFilterController;
        this.nameFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "parent.productName", null, null);

        this.nameFilterTextField = MUIOutlet(this, "name-filter-tf", "ColumnFilterTextField");
        this.nameFilterTextField.filterController = this.columnFilterController;
        this.nameFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "productName", null, null);

        this.typeFilterTextField = MUIOutlet(this, "type-filter-tf", "ColumnFilterTextField");
        this.typeFilterTextField.filterController = this.columnFilterController;
        this.typeFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "type", null, null);

        this.quantityFilterTextField = MUIOutlet(this, "quantity-filter-tf", "ColumnFilterTextField");
        this.quantityFilterTextField.filterController = this.columnFilterController;
        this.quantityFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "quantity", null, null);

        this.quantityFilterTextField = MUIOutlet(this, "cost-filter-tf", "ColumnFilterTextField");
        this.quantityFilterTextField.filterController = this.columnFilterController;
        this.quantityFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Number, "computedCost", null, null);

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    cellAtIndexPath(tableView: UITableView, indexPath: MIOIndexPath) {
        let cell = tableView.dequeueReusableCellWithIdentifier("ConsumptionLogCell") as DBConsumptionLogCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as StockConsumptionAnnotation;

        cell.item = item;

        return cell;
    }

    get fetchedResultsController(): MIOFetchedResultsController {
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('date', false)];
        
        let predicateFormat = this.columnFilterController.filterPredicateFormat();
        
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("StockConsumptionAnnotation", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["parent"];
        fetchRequest.fetchLimit = 300;
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    filterPredicateDidChange(controller:ColumnFilterController){
        this.invalidateFetch();
        //this.tableView.reloadData();
    }
    
}