

/// <reference path="DevicePaymentTransaction.ts" />

// Generated class AdyenDevicePaymentTransaction_ManagedObject

class AdyenDevicePaymentTransaction_ManagedObject extends DevicePaymentTransaction
{

    // Property: maskedPan
    set maskedPan(value:string) {
        this.setValueForKey(value, 'maskedPan');
    }
    get maskedPan():string {
        return this.valueForKey('maskedPan');
    }
    set maskedPanPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'maskedPan');
    }
    get maskedPanPrimitiveValue():string {
        return this.primitiveValueForKey('maskedPan');
    }

    // Property: messageCategory
    set messageCategory(value:string) {
        this.setValueForKey(value, 'messageCategory');
    }
    get messageCategory():string {
        return this.valueForKey('messageCategory');
    }
    set messageCategoryPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'messageCategory');
    }
    get messageCategoryPrimitiveValue():string {
        return this.primitiveValueForKey('messageCategory');
    }

    // Property: serviceReference
    set serviceReference(value:string) {
        this.setValueForKey(value, 'serviceReference');
    }
    get serviceReference():string {
        return this.valueForKey('serviceReference');
    }
    set serviceReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'serviceReference');
    }
    get serviceReferencePrimitiveValue():string {
        return this.primitiveValueForKey('serviceReference');
    }
}
