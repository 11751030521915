//
// Generated class Tab
//

/// <reference path="Tab_ManagedObject.ts" />

class Tab extends Tab_ManagedObject
{

}
