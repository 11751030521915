/**
 * Created by miguel on 2/2/17.
 */


class ProductFormatDetailViewController extends MUIViewController
{
    private closeButton:MUIButton = null;
    private addButton:MUIButton = null;

    private pageController:MUIPageController = null;
    private segmentedControl:MUISegmentedControl = null;

    private pfgvc:ProductFormatGeneralViewController = null;  //ProductFormatGeneralViewController
    private pfrvc:ProductFormatRateViewController = null;  //ProductFormatRate ViewController
    //private pfcvc:ProductFormatComponentViewController = null;  //ProductFormatComponentViewController
    private pfevc:ProductFormatExtraComponentViewController = null;  //ProductFormatExtraComponentViewController

    get preferredContentSize(){
        return new MIOSize(500, 600);
    }

    viewDidLoad() {
        super.viewDidLoad();

        this.closeButton = MUIOutlet(this, 'close-btn', 'MUIButton');
        this.closeButton.setAction(this, function () {    
            if(this.checkQuantities()){
                DBHelper.saveMainContext();                
                this.dismissViewController(true);                
            }
            else{
                AppHelper.showErrorMessage(this, 'Error',"Consumption quantity must be greater or equal than loss quantity.");
            }
        });        

        this.segmentedControl = MUIOutlet(this, "segmented-control", "MUISegmentedControl");
        this.segmentedControl.setAction(this, function (control, index) {
            this.pageController.showPageAtIndex(index);
        });

        // //SEGMENTED BUTTON
        this.pageController = MUIOutlet(this, "page-controller", "MUIPageController");

        this.pfgvc = new ProductFormatGeneralViewController('product-formats-general-view');
        this.pfgvc.initWithResource('layout/productformat/ProductFormatsGeneralView.html');
        this.pfgvc.navigationController = this.navigationController;
        this.pageController.addPageViewController(this.pfgvc);

        this.pfrvc = new ProductFormatRateViewController('product-formats-rates-view');
        this.pfrvc.initWithResource('layout/productformat/ProductFormatsRatesView.html');
        this.pfrvc.navigationController = this.navigationController;
        this.pageController.addPageViewController(this.pfrvc);

        // this.pfcvc = new ProductFormatComponentViewController('product_formats_component_view');
        // this.pfcvc.initWithResource('layout/productformat/ProductFormatsComponentView.html');
        // this.pfcvc.navigationController = this.navigationController;        
        // this.pageController.addPageViewController(this.pfcvc);

        // this.pfevc = new ProductFormatExtraComponentViewController('product_formats_extra_component_view');
        // this.pfevc.initWithResource('layout/productformat/ProductFormatsExtraComponentView.html');
        // this.pfevc.navigationController = this.navigationController;
        // this.pageController.addPageViewController(this.pfevc);

        //END SEGMENTED
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private _productFormat:ProductFormat = null;
    set productFormat(value){
        this._productFormat = value;
        this.updateUI();
    }

    private checkQuantities(){
        return true;
        // let productQuantity = this._productFormat.product.quantity;
        // let productMeasureUnit = this._productFormat.product.measureUnitType;

        // let consumption = this._productFormat.consumptionQuantity || 0;
        // let consumptionUnit = this._productFormat.consumptionMeasureUnit;
        // let loss = this._productFormat.lossQuantity || 0;
        // let lossUnit = this._productFormat.lossMeasureUnit;

        // return AppHelper.checkConsumptionQuantities(productQuantity, productMeasureUnit, consumption, consumptionUnit, loss, lossUnit);
    }

    private updateUI(){
        if(!(this.viewIsLoaded && this._productFormat != null)) return;

        this.pfgvc.productFormat = this._productFormat;
        this.pfrvc.productFormat = this._productFormat;
        //this.pfcvc.productFormat = this._productFormat;
        //this.pfevc.productFormat = this._productFormat;

    }

}