//
// Generated class ProductAttributeType
//

/// <reference path="ProductAttributeType_ManagedObject.ts" />

enum WineCellarType
{
    StockCategory = 0,
    ProductAttribute = 1
}  


class ProductAttributeType extends ProductAttributeType_ManagedObject
{

}
