//
// Generated class EllaborationCenter (Ellaboration with two "ll" because of database)
//

/// <reference path="EllaborationCenter_ManagedObject.ts" />

class EllaborationCenter extends EllaborationCenter_ManagedObject
{

}
