/// <reference path="../base/BaseTableViewController.ts" />

class EntityListViewController extends BaseTableViewController 
{
    protected activityIndicatorView:MUIActivityIndicatorView = null;

    viewDidAppear(animated?){
        super.viewDidAppear(animated);

        // if (this.activityIndicatorView == null) return;
        // MIONotificationCenter.defaultCenter().addObserver(this, "MWSPersistentStoreDidChangeEntityStatus", function(notification:MIONotification){

        //     let entityName = notification.object;
        //     let userInfo = notification.userInfo;

        //     if (entityName != this.entityName) return;            
        //     if (userInfo.Status == MWSPersistentStoreFetchStatus.Downloading) this.activityIndicatorView.setHidden(false);
        //     else if (userInfo.Status == MWSPersistentStoreFetchStatus.Downloaded) this.activityIndicatorView.setHidden(true);
        // });
    }

    viewWillDisappear(animated?){
        super.viewWillDisappear(animated);
        //MIONotificationCenter.defaultCenter().removeObserver(this, "MWSPersistentStoreDidChangeEntityStatus");
    }

    get entityName(){
        return null;
    }

}