//
// Generated class ReportChartItem
//

/// <reference path="ReportChartItem_ManagedObject.ts" />

declare enum ReportChartItemType {
    Bar = 0,
    HorizontalBar = 1,
    Line = 2,
    Pie = 3,
    Totals = 100
}

class ReportChartItem extends ReportChartItem_ManagedObject
{    
    chartType = MUIChartViewType.Bar;
    labelKey = null;
    valueKey = null;
    valueType = ReportItemValueType.String;
    
    parseJSON(item){
        super.parseJSON(item);

        this.chartType = item["type"];        
        this.labelKey = item["labelkey"];
        this.valueKey = item["valuekey"];
        this.valueType = item["valuetype"];
    } 
}
