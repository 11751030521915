

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class OrderAnswer_ManagedObject

class OrderAnswer_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: orderID
    set orderID(value:string) {
        this.setValueForKey(value, 'orderID');
    }
    get orderID():string {
        return this.valueForKey('orderID');
    }
    set orderIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderID');
    }
    get orderIDPrimitiveValue():string {
        return this.primitiveValueForKey('orderID');
    }

    // Property: questionID
    set questionID(value:string) {
        this.setValueForKey(value, 'questionID');
    }
    get questionID():string {
        return this.valueForKey('questionID');
    }
    set questionIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'questionID');
    }
    get questionIDPrimitiveValue():string {
        return this.primitiveValueForKey('questionID');
    }

    // Property: questionTitle
    set questionTitle(value:string) {
        this.setValueForKey(value, 'questionTitle');
    }
    get questionTitle():string {
        return this.valueForKey('questionTitle');
    }
    set questionTitlePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'questionTitle');
    }
    get questionTitlePrimitiveValue():string {
        return this.primitiveValueForKey('questionTitle');
    }

    // Property: value
    set value(value:string) {
        this.setValueForKey(value, 'value');
    }
    get value():string {
        return this.valueForKey('value');
    }
    set valuePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'value');
    }
    get valuePrimitiveValue():string {
        return this.primitiveValueForKey('value');
    }
}
