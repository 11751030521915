
/// <reference path="../base/BaseTableViewController.ts" />
/// <reference path="ProductRatesLIstCell.ts" />

class ProductRatesListViewController extends BaseTableViewController
{

    private addButton:MUIButton = null;
    private segmentedControl:MUISegmentedControl = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.segmentedControl = MUIOutlet(this, "segmented-control", "MUISegmentedControl");
        this.segmentedControl.setAction(this, this.segmentedControlDidChange);

        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function(){
           this.addNewObject();
        });

        this.searchTextField = MUIOutlet(this, 'search-bar','MUITextField');
        this.searchTextField.setOnChangeText(this, function(textfield, value){         
            this.searchString = value.length > 0 ? value : null;
            if (this.delayTimer != null) this.delayTimer.invalidate();
            this.delayTimer = MIOTimer.scheduledTimerWithTimeInterval(500, false, this, function(timer){
                this.delayTimer = null;
                this.updateUI();
            });            
        });

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    updateUI(){
        if (this.viewIsLoaded == false) return;

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }
    
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let cell = tableView.dequeueReusableCellWithIdentifier("ProductRatesListCell") as ProductRatesListCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        cell.item = item;
        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath);
        this.showItemDetail(selectedItem);
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) { 
        return UITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            DBHelper.deleteObjectFromMainContext(item, true);
        }
    }

    private showItemDetail(item:Rate){     
        if (item == null) return;

        let vc = new ProductRatesDetailViewController("product-rates-detail-view");
        vc.initWithResource("layout/productrates/ProductRatesDetailView.html");
        vc.item = item;    
            
        this.navigationController.splitViewController.showDetailViewController(vc);
    }

    set fetchedResultsController(value){
        this.setFetchedResultsController(value);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
        let entityName = this.segmentedControl.selectedSegmentedIndex == 0 ? "Rate" : "PriceGroup";        

        let predicateFormat:string = null;
        if (this.searchString != null && this.searchString.length > 0) {
            predicateFormat = 'name CONTAINS "' + this.searchString.trim() + '"';
        }  

        let sds = [MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName(entityName, sds, predicateFormat);        
        // fetchRequest.relationshipKeyPathsForPrefetching = ['image'];
                        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    private segmentedControlDidChange(control:MUISegmentedControl) {
        this.invalidateData();
    }

    private addNewObject(){
        let avc = new MUIAlertViewController();
        let entity = (this.segmentedControl.selectedSegmentedIndex == 0) ? "RATE" : "PRICE GROUP"; //must be changed if more options added
        let title =  MIOLocalizeString('NEW ' + entity, 'NEW '+ entity);
        avc.initWithTitle(title, MIOLocalizeString('CHOOSE A NAME','CHOOSE A NAME'), MUIAlertViewStyle.Default);
        
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString('NAME',"NAME"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','DONE'), MUIAlertActionStyle.Default, this, function(){
            let name =  avc.textFields[0].text.trim();
            if(name.length > 0){
                let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
                let newEntity:any = null;

                if (this.segmentedControl.selectedSegmentedIndex == 0) { //must be changed if more options added
                    newEntity = MIOEntityDescription.insertNewObjectForEntityForName("Rate", moc) as Rate;
                    newEntity.identifier = MIOUUID.UUID().UUIDString;
                    newEntity.name = name;
                } else {
                    newEntity = MIOEntityDescription.insertNewObjectForEntityForName("PriceGroup", moc) as PriceGroup;
                    newEntity.identifier = MIOUUID.UUID().UUIDString;
                    newEntity.name = name;
                }

                moc.save();
                this.invalidateData();
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('PLEASE WRITE A VALID NAME','PLEASE WRITE A VALID NAME '), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CLOSE','CLOSE'), MUIAlertActionStyle.Default, null, null));
                this.presentViewController(error, true);
            }
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, null, null));
        
        this.presentViewController(avc, true);
    }

}