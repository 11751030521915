class LoanCell extends UITableViewCell
{
    private referenceLabel:MUILabel = null;
    private nameLabel:MUILabel = null;
    private bankAccountLabel:MUILabel = null;

    awakeFromHTML(): void {
        this.referenceLabel = MUIOutlet( this, "reference-lbl", "MUILabel" );
        this.nameLabel = MUIOutlet( this, "name-lbl", "MUILabel" );
        this.bankAccountLabel = MUIOutlet( this, "bank-account-lbl", "MUILabel" );
    }

    private _loan:Loan = null;
    set loan( loan:Loan ) {
        this._loan = loan;
        
        this.referenceLabel.text = loan.reference;
        this.nameLabel.text = loan.name;
        this.bankAccountLabel.text = null;        
    }
}