

class ProductAttributeSelectionViewController extends BaseTableViewController
{

    static newInstance() : ProductAttributeSelectionViewController {
        let vc = new ProductAttributeSelectionViewController('product-attribute-selection-view');
        vc.initWithResource('layout/productattributetype/ProductAttributeSelectionView.html');
        return vc;
    }

    private backButton:MUIButton = null;
    private titleLabel:MUILabel = null;
    private addButton:MUIButton = null;

    noSelectionCell = false;
    delegate = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, 'back-btn', 'MUIButton');
        this.backButton.setAction(this, function (){
            this.navigationController.popViewController(true);
        });

        this.titleLabel = MUIOutlet(this, 'title-lbl', 'MUILabel');
        
        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function(){
            this.addProductAttribute();
        });

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();
     
        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    viewWillDisappear(animated?: boolean): void {
        super.viewWillDisappear(animated);
    }

    private _selectedWineCellar = null;
    private _selectedProductAttributeType = null;
    set item (item:WineCellar) {
        if (item == null) return;

        this._selectedWineCellar = item;

        DBHelper.queryObjectWithCompletion( "ProductAttributeType", null, MIOPredicate.predicateWithFormat( "identifier == '" + item.productAttributeTypeID + "'" ), ["ProductAttribute"], this, function(object) {
            if (object == null) return;  //why does this not find the objects????
            this._selectedProductAttributeType = object;

            this.updateUI();
        });  
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._selectedWineCellar == null) return;        

        this.titleLabel.text = MIOLocalizeString("SELECT ATTRIBUTES FOR","SELECT ATTRIBUTES FOR ") + this._selectedWineCellar.name;

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        let rows = sec.numberOfObjects();
        if (this.noSelectionCell == true) rows++;
        return rows;
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("ProductAttributeSelectCell") as ProductAttributeSelectCell;
        cell.selectionStyle = UITableViewCellSelectionStyle.None; 

        let realIndexPath = this.noSelectionCell == false ? indexPath : MIOIndexPath.indexForRowInSection(indexPath.row - 1, indexPath.section);
        if (this.noSelectionCell == true && indexPath.row == 0) {
            cell.title = "No product attribute";
        } else {
            let item = this.fetchedResultsController.objectAtIndexPath(realIndexPath) as ProductAttribute;
            cell.item = item;
        }

        return cell;
    }
        
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        if (this.noSelectionCell == true && indexPath.row == 0) {            
            this.delegate.updateSelectProductAttribute(this._selectedWineCellar, null);
            this.navigationController.popViewController(true);
        }
        else if (this.noSelectionCell == true && indexPath.row > 0) {
            let ip = MIOIndexPath.indexForRowInSection(indexPath.row - 1, indexPath.section);
            let item = this.fetchedResultsController.objectAtIndexPath(ip) as ProductAttribute;
            this.delegate.updateSelectProductAttribute(this._selectedWineCellar, item);
            this.navigationController.popViewController(true);
        }
        else {
            let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ProductAttribute;
            this.delegate.updateSelectProductAttribute(this._selectedWineCellar, item);
            this.navigationController.popViewController(true);             
        }
    }

    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
        
        let ad = MUIWebApplication.sharedInstance().delegate;
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('orderindex', true),
                                MIOSortDescriptor.sortDescriptorWithKey('name', true) ];
        let predicateFormat = this.searchString != null ? "name CONTAINS '" + this.searchString + "' AND productAttributeType.identifier == " + this._selectedWineCellar.productAttributeTypeID : "productAttributeType.identifier == " + this._selectedWineCellar.productAttributeTypeID;
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("ProductAttribute", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["productAttributeType"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;            
        
        fetchedResultsController.performFetch();
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }


    private addProductAttribute(){

        var avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('NEW PRODUCT ATTRIBUTE','NEW PRODUCT ATTRIBUTE'), MIOLocalizeString('CHOOSE A NAME','CHOOSE A NAME '), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD','ADD'), MUIAlertActionStyle.Default, this, function(){
            let nameTF =  avc.textFields[0];
            let name = nameTF.text.trim();

            if(name.length > 0){
                let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
                let newObject = MIOEntityDescription.insertNewObjectForEntityForName("ProductAttribute", moc) as ProductAttribute;
                newObject.identifier = MIOUUID.UUID().UUIDString;
                newObject.name = name;
                newObject.productAttributeType = this._selectedProductAttributeType;
                moc.save();
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('CHECK NAME','CHECK NAME'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CLOSE','CLOSE'), MUIAlertActionStyle.Cancel, this, function(){}));
                
                this.presentViewController(error, true);
            }
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, this, function(){}));
        
        this.presentViewController(avc, true);
    }

    
}

