
class DLDB
{
    private static _sharedInstance: DLDB;

    static sharedInstance(): DLDB {

        if (DLDB._sharedInstance == null) {
            DLDB._sharedInstance = new DLDB();
        }

        return DLDB._sharedInstance;
    }

    private constructor() {
        if (DLDB._sharedInstance != null) {
            throw new Error("DLDB: Instantiation failed: Use sharedInstance() instead of new.");
        }
    }
   
}