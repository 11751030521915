

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ProfileSummary_ManagedObject

class ProfileSummary_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: title
    set title(value:string) {
        this.setValueForKey(value, 'title');
    }
    get title():string {
        return this.valueForKey('title');
    }
    set titlePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'title');
    }
    get titlePrimitiveValue():string {
        return this.primitiveValueForKey('title');
    }

    // Property: titleFontStyle
    set titleFontStyle(value:number) {
        this.setValueForKey(value, 'titleFontStyle');
    }
    get titleFontStyle():number {
        return this.valueForKey('titleFontStyle');
    }
    set titleFontStylePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'titleFontStyle');
    }
    get titleFontStylePrimitiveValue():number {
        return this.primitiveValueForKey('titleFontStyle');
    }
    // Relationship: printer
    set printer(value:Printer) {
        this.setValueForKey(value, 'printer');
    }
    get printer():Printer {
        return this.valueForKey('printer') as Printer;
    }
    // Relationship: printProfile
    set printProfile(value:PrintProfile) {
        this.setValueForKey(value, 'printProfile');
    }
    get printProfile():PrintProfile {
        return this.valueForKey('printProfile') as PrintProfile;
    }

    // Relationship: profileSummarySources
    protected _profileSummarySources:MIOManagedObjectSet = null;
    get profileSummarySources():MIOManagedObjectSet {
        return this.valueForKey('profileSummarySources');
    }
    addProfileSummarySourcesObject(value:ProfileSummarySource) {
        this._addObjectForKey(value, 'profileSummarySources');
    }
    removeProfileSummarySourcesObject(value:ProfileSummarySource) {
        this._removeObjectForKey(value, 'profileSummarySources');
    }
}
