
/// <reference path="../base/EntityListViewController.ts" />
/// <reference path="../AppHelper.ts" />


class SupplierListViewController extends EntityListViewController
{
    private addButton:MUIButton = null;    
        
    private supplier:Supplier = null;

    private detailViewController = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.activityIndicatorView = MUIOutlet(this, 'slv_activity-indicator', 'MUIView');
        this.activityIndicatorView.setHidden(true);

        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, this.addSupplierAction);        

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.tableView = MUIOutlet(this,"tableview", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        MIONotificationCenter.defaultCenter().addObserver(this, "SupplierDidDeleted", this.supplierDidDeleteNotification);

        this.needsReloadData();
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("SupplierCell") as SupplierCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Supplier;

        cell.item = item;        
        cell.selected = (this.supplier && this.supplier.identifier == item.identifier) ? true : false;
        
        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        this.supplier = this.fetchedResultsController.objectAtIndexPath(indexPath);
        this.showSelectedSupplier();
    }

    private showSelectedSupplier(){        
        if(!this.detailViewController) {

            let vc = new SupplierDetailViewController("supplier-detail-view");
            vc.initWithResource("layout/supplier/SupplierDetailView.html");

            this.detailViewController = new MUINavigationController("supplier-detail-nav-controller");
            this.detailViewController.initWithRootViewController(vc);
        }

        this.detailViewController.rootViewController.supplier = this.supplier;
        this.splitViewController.showDetailViewController(this.detailViewController);        
    }

    private showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Supplier", NoItemSelectedViewControllerType.Supplier);
        this.splitViewController.showDetailViewController(vc);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

        let predicateString = null;
        if (this.searchString != null && this.searchString.length > 0 )
            predicateString ='name CONTAINS "' + this.searchString + '"';

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Supplier", sortDescriptors, predicateString);
        fetchRequest.relationshipKeyPathsForPrefetching = ['address','phone','mobilePhone'];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    private addSupplierAction(){
        let vc = new AddNewSupplierViewController('add-new-supplier-view');
        vc.initWithResource('layout/supplier/AddNewSupplierView.html');            
        vc.delegate = this;
        this.presentViewController(vc, true);
    }

    supplierWillInsert(supplier:Supplier) {
        this.supplier = supplier;
    }

    supplierDidInsert(supplier:Supplier){
        this.showSelectedSupplier();
    }

    private supplierDidDeleteNotification(notification:MIONotification) {
        let supplier = notification.object as Supplier;
        if (this.supplier == null) return;
        if (supplier.identifier != this.supplier.identifier) return;

        this.supplier = null;
        this.showNoItemSelected();
    }
}
