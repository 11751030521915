//
// Generated class MovementNoteLine
//

/// <reference path="MovementNoteLine_ManagedObject.ts" />

class MovementNoteLine extends MovementNoteLine_ManagedObject
{

}
