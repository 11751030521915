//
// Generated class WarehouseDeliveryNote
//

/// <reference path="WarehouseDeliveryNote_ManagedObject.ts" />

class WarehouseDeliveryNote extends WarehouseDeliveryNote_ManagedObject
{

}
