

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class EmployeePayrollTemplate_ManagedObject

class EmployeePayrollTemplate_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }
    // Relationship: concepts
    set concepts(value:EmployeePayrollConcept) {
        this.setValueForKey(value, 'concepts');
    }
    get concepts():EmployeePayrollConcept {
        return this.valueForKey('concepts') as EmployeePayrollConcept;
    }
}
