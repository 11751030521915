//
// Generated class LegalTaxType
//

/// <reference path="LegalTaxType_ManagedObject.ts" />

class LegalTaxType extends LegalTaxType_ManagedObject
{

}
