

/// <reference path="DBDocumentLine.ts" />

// Generated class POSDocumentLine_ManagedObject

class POSDocumentLine_ManagedObject extends DBDocumentLine
{
    // Relationship: category
    set category(value:ProductCategory) {
        this.setValueForKey(value, 'category');
    }
    get category():ProductCategory {
        return this.valueForKey('category') as ProductCategory;
    }
    // Relationship: format
    set format(value:Format) {
        this.setValueForKey(value, 'format');
    }
    get format():Format {
        return this.valueForKey('format') as Format;
    }
    // Relationship: menu
    set menu(value:Menu) {
        this.setValueForKey(value, 'menu');
    }
    get menu():Menu {
        return this.valueForKey('menu') as Menu;
    }
    // Relationship: modifier
    set modifier(value:ProductModifier) {
        this.setValueForKey(value, 'modifier');
    }
    get modifier():ProductModifier {
        return this.valueForKey('modifier') as ProductModifier;
    }
    // Relationship: modifierCategory
    set modifierCategory(value:ProductModifierCategory) {
        this.setValueForKey(value, 'modifierCategory');
    }
    get modifierCategory():ProductModifierCategory {
        return this.valueForKey('modifierCategory') as ProductModifierCategory;
    }
    // Relationship: productFormat
    set productFormat(value:ProductFormat) {
        this.setValueForKey(value, 'productFormat');
    }
    get productFormat():ProductFormat {
        return this.valueForKey('productFormat') as ProductFormat;
    }
}
