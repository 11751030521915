



class ReportResult3Cell extends MUITableViewCell
{
    nameLabel     = null;
    quantityLabel = null;
    totalLabel    = null;
   
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;

    awakeFromHTML()
    {
        this.nameLabel     = MUIOutlet(this, 'rrv_c4_name_lbl', 'MUILabel');
        this.quantityLabel = MUIOutlet(this, 'rrv_c4_label1_lbl', 'MUILabel');
        this.totalLabel    = MUIOutlet(this, 'rrv_c4_label2_lbl', 'MUILabel');
    }

    set item(i)
    {
        this.nameLabel.text     = i.workername;
        this.quantityLabel.text = i.quantity ? this.nf.stringFromNumber(parseFloat(i.quantity)) :'';
        this.totalLabel.text    = (i.money ? this.cf.stringFromNumber(parseFloat(i.money)) :'');
    }
}
