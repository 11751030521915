/**
 * Created by miguel on 1/2/17.
 */




class AddNewWorkerViewController extends MUIViewController
{
    private worker:Employee = null;
    private country:Country = null;
    
    private nameTextField:MUITextField = null;
    private emailTextField:MUITextField = null;
    private documentTextField:MUITextField = null;
    private address1TextField:MUITextField = null;
    //private address2TextField:MUITextField = null;
    private cityTextField:MUITextField = null;
    private countryDropdown:MUIButton = null;
    private postalCodeTextField:MUITextField = null;
    private phoneTextField:MUITextField = null;
    private mobilePhoneTextField:MUITextField = null;
    private passwordTextField:MUITextField = null;
    private pincodeTextField:MUITextField = null;

    private cancelButton:MUIButton = null;
    private saveButton:MUIButton = null;
    private imageView:MUIImageView = null;

    get preferredContentSize()
    {
        return new MIOSize(500, 510);
    }

    viewDidLoad() {
        super.viewDidLoad();

        this.imageView = MUIOutlet(this, 'image-view', 'MUIButton');
        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.emailTextField = MUIOutlet(this, "email-tf", "MUITextField");
        this.documentTextField = MUIOutlet(this, "document-tf", "MUITextField");
        this.passwordTextField = MUIOutlet(this, "password-tf", "MUITextField");
        this.pincodeTextField = MUIOutlet(this, "pin-tf", "MUITextField");
        this.address1TextField = MUIOutlet(this, "address-tf", "MUITextField");
        this.cityTextField = MUIOutlet(this, "city-tf", "MUITextField");
        this.countryDropdown = MUIOutlet(this, "country-dd", "MUIButton");
        this.postalCodeTextField = MUIOutlet(this, "postal-code-tf", "MUITextField");
        this.phoneTextField = MUIOutlet(this, "phone-tf", "MUITextField");
        this.mobilePhoneTextField = MUIOutlet(this, "mobile-phone-tf", "MUITextField");
        
        this.cancelButton = MUIOutlet(this, 'cancel-btn', 'MUIButton');     
        this.cancelButton.setAction(this, function ()
        {
            //if called from invoices, must behave as a navigation view
            if(this.navigationController != null) {
                this.navigationController.popViewController();
            }
            else
                this.dismissViewController(true);
        });

        this.saveButton = MUIOutlet(this, 'save-btn', 'MUIButton');
        this.saveButton.setAction(this, function (this:AddNewWorkerViewController, controller:SelectEntityViewController)
        {            
            let name = this.nameTextField.text.trim();
            this.worker = DBHelper.createEmployee(name);

            this.worker.document = this.documentTextField.text.trim();
            this.worker.email = this.emailTextField.text.trim();
            this.worker.password = this.passwordTextField.text.trim();
            this.worker.pinCode = this.pincodeTextField.text.trim();
            this.address.address1 = this.address1TextField.text.trim();
            this.address.postalCode = this.postalCodeTextField.text.trim();
            this.address.country = this.country.name;
            this.address.countryISOa2 = this.country.iso2;
            this.address.countryISOa3 = this.country.iso3;
            this.address.city = this.cityTextField.text.trim();
            this.phone.number = this.phoneTextField.text.trim();
            this.mobilePhone.number = this.mobilePhoneTextField.text.trim();

            DBHelper.saveMainContext();

            if(this.navigationController != null) {
                this.navigationController.popViewController();
            }
            else
                this.dismissViewController(true);

        });
        
        // this.imageButton.setAction(this, function ()
        // {
        //     console.log('open image file');
        // });

        this.countryDropdown.setAction(this, function(){
            AppHelper.sharedInstance().showSelectCountryViewControllerFromView(this.countryDropdown, null, this, function(controller:SelectEntityViewController, country:Country){                
                this.country = country;
                this.countryDropdown.title = country.name;

                if (this.worker != null) {
                    this.worker.address.country = country;
                    this.worker.address.countryName = country.name;
                    this.worker.address.countryISOA2 = country.iso2;
                    this.worker.address.countryISOA3 = country.iso3;
                    this.worker.address.countryISON = country.ison;
                }
            });
        });   

        this.updateUI();
    }

    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private updateUI()
    {
        this.nameTextField.text = null;
        this.emailTextField.text = null;
        this.documentTextField.text = null;
        this.address1TextField.text = null;
        this.cityTextField.text = null;
        this.countryDropdown.title = null;
        this.postalCodeTextField.text = null;
        this.phoneTextField.text = null;
        this.mobilePhoneTextField.text = null;

        this.countryDropdown.title = AppHelper.sharedInstance().defaultCountry.name;
        this.country = AppHelper.sharedInstance().defaultCountry;
    }        

    get address():Address
    {
        if(this.worker.address == null)
        {
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;    
            this.worker.address = MIOEntityDescription.insertNewObjectForEntityForName("Address", moc) as Address;
            this.worker.address.identifier = MIOUUID.UUID().UUIDString;
        }
        return this.worker.address;
    }

    get phone():PhoneNumber
    {
        if(this.worker.phone == null)
        {
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;    
            this.worker.phone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", moc) as PhoneNumber;
            this.worker.phone.identifier = MIOUUID.UUID().UUIDString;
        }
        return this.worker.phone;
    }

    get mobilePhone():PhoneNumber
    {
        if(this.worker.mobilePhone == null)
        {
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;    
            this.worker.mobilePhone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", moc) as PhoneNumber;
            this.worker.mobilePhone.identifier = MIOUUID.UUID().UUIDString;
        }
        return this.worker.mobilePhone;
    }
}