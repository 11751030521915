//
// Generated class LoyaltyAccountLine
//

/// <reference path="LoyaltyAccountLine_ManagedObject.ts" />

class LoyaltyAccountLine extends LoyaltyAccountLine_ManagedObject
{

}
