//
// Generated class ChildPlace
//

/// <reference path="ChildPlace_ManagedObject.ts" />

class ChildPlace extends ChildPlace_ManagedObject
{

}
