//
// Generated class SupplierNoteLine
//

/// <reference path="SupplierNoteLine_ManagedObject.ts" />

class SupplierNoteLine extends SupplierNoteLine_ManagedObject
{

}
