

/// <reference path="LegalEntity.ts" />

// Generated class Intermediary_ManagedObject

class Intermediary_ManagedObject extends LegalEntity
{

    // Property: active
    set active(value:boolean) {
        this.setValueForKey(value, 'active');
    }
    get active():boolean {
        return this.valueForKey('active');
    }
    set activePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'active');
    }
    get activePrimitiveValue():boolean {
        return this.primitiveValueForKey('active');
    }

    // Relationship: licenseRates
    protected _licenseRates:MIOManagedObjectSet = null;
    get licenseRates():MIOManagedObjectSet {
        return this.valueForKey('licenseRates');
    }
    addLicenseRatesObject(value:IntermediaryLicenseRate) {
        this._addObjectForKey(value, 'licenseRates');
    }
    removeLicenseRatesObject(value:IntermediaryLicenseRate) {
        this._removeObjectForKey(value, 'licenseRates');
    }
}
