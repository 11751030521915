

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class OrderQuestion_ManagedObject

class OrderQuestion_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: group
    set group(value:string) {
        this.setValueForKey(value, 'group');
    }
    get group():string {
        return this.valueForKey('group');
    }
    set groupPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'group');
    }
    get groupPrimitiveValue():string {
        return this.primitiveValueForKey('group');
    }

    // Property: isOnOff
    set isOnOff(value:boolean) {
        this.setValueForKey(value, 'isOnOff');
    }
    get isOnOff():boolean {
        return this.valueForKey('isOnOff');
    }
    set isOnOffPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isOnOff');
    }
    get isOnOffPrimitiveValue():boolean {
        return this.primitiveValueForKey('isOnOff');
    }

    // Property: optionsName
    set optionsName(value:string) {
        this.setValueForKey(value, 'optionsName');
    }
    get optionsName():string {
        return this.valueForKey('optionsName');
    }
    set optionsNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'optionsName');
    }
    get optionsNamePrimitiveValue():string {
        return this.primitiveValueForKey('optionsName');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: title
    set title(value:string) {
        this.setValueForKey(value, 'title');
    }
    get title():string {
        return this.valueForKey('title');
    }
    set titlePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'title');
    }
    get titlePrimitiveValue():string {
        return this.primitiveValueForKey('title');
    }
}
