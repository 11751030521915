//
// Generated class ProductModifierRate
//

/// <reference path="ProductModifierRate_ManagedObject.ts" />

class ProductModifierRate extends ProductModifierRate_ManagedObject
{

}
