//
// Generated class InvitationLine
//

/// <reference path="InvitationLine_ManagedObject.ts" />

class InvitationLine extends InvitationLine_ManagedObject
{

}
