
class PurchaseInvoiceListViewController extends InvoiceListViewController
{
    static newInstance():PurchaseInvoiceListViewController {
        let vc = new PurchaseInvoiceListViewController("invoice-list-view");
        vc.initWithResource("layout/invoices/InvoiceListView.html");        
        return vc;
    }

    get entityName():string { return "PurchaseInvoice"; }

    viewDidLoad() {
        super.viewDidLoad();          
        this.titleLabel.text = MIOLocalizeString("PURCHASE INVOICES", "PURCHASE INVOICES");
    }

    viewWillAppear(animate){
        super.viewWillAppear(animate);

        MIONotificationCenter.defaultCenter().addObserver(this, "ReloadAndShowPurchaseInvoiceNotification", function(this:PurchaseInvoiceListViewController, note:MIONotification){
            DBHelper.queryObjectWithCompletion("PurchaseInvoice", null, MIOPredicate.predicateWithFormat("identifier = " + note.object), [], this, function(this:PurchaseInvoiceListViewController, object:PurchaseInvoice){
                if (object == null) return;

                this.selectedItem = object;
                this.showSelectedInvoice();
                let ip = this.fetchedResultsController.indexPathForObject(object);
                this.tableView.selectRowAtIndexPath(ip, true);
            });
        });

    }

    protected showSelectedInvoice()
    {
        let vc = PurchaseInvoiceDetailViewController.newInstance();
        vc.item = this.selectedItem;
        this.splitViewController.showDetailViewController(vc);
    }

    protected addInvoice(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("OPTIONS", "OPTIONS"), MIOLocalizeString("SELECT AN OPTION", "SELECT AN OPTION"), MUIAlertViewStyle.Default);

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("QUICK INVOICE", "QUICK INVOICE"), MUIAlertActionStyle.Default, this, function(this:PurchaseInvoiceListViewController){
            this.showLegalEntitySelectionViewController( null, LegalEntityDefaultSelectionType.supplier, {"type":"quick"} );
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("MANUAL INVOICE", "MANUAL INVOICE"), MUIAlertActionStyle.Default, this, function(this:PurchaseInvoiceListViewController){
            this.showLegalEntitySelectionViewController( null, LegalEntityDefaultSelectionType.supplier, null );
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("INVOICE FROM NOTES", "INVOICE FROM NOTES"), MUIAlertActionStyle.Default, this, function(this:PurchaseInvoiceListViewController){
            let vc = SelectDocumentViewController.newInstance();
            vc.documentType = SelectDocumentViewControllerType.supplierNote;
            this.presentViewController(vc, true);
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null) );

        this.presentViewController(avc, true);
    }

    protected createNewInvoice( entity:LegalEntity, numberSequence: NumberSerialSequence, userInfo:any ) {
        if (userInfo != null && userInfo["type"] == "quick") {
            this.showQuickPurchaseInvoice(entity);
        }
        else {
            this.createNextNumber(entity, userInfo);
        }
    }
    
    protected createNextNumber( entity:LegalEntity, info: any ){
        
        DBHelper.nextDocumentNumber("PurchaseInvoice", -1, (documentID:string, documentPrefix:string, documentNumber:number, error:string) => {
            if (error != null) {
                AppHelper.showErrorMessage(this, MIOLocalizeString("ERROR", "ERROR"), error);
                return;
            }

            this.createPurchaseInvoice(entity, documentID, info);
        });
    }

    protected createPurchaseInvoice(entity:LegalEntity, documentNumber:string, info:any){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let inv = MIOEntityDescription.insertNewObjectForEntityForName( this.entityName, ad.managedObjectContext ) as Invoice;

        inv.identifier = MIOUUID.UUID().UUIDString;
        inv.documentNumber = documentNumber;
        inv.legalEntity = entity;
        //inv.legalTaxFactor = entity.legalTax != null ? entity.legalTax.value : 0;
        inv.date = new Date();        
        this.selectedItem = inv;        

        this.createInvoiceWithInfo(inv, info);

        DBHelper.saveMainContext();

        this.showSelectedInvoice();
    }

    private showQuickPurchaseInvoice(legalEntity:LegalEntity)
    {
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("QUICK INVOICE", "QUICK INVOICE"), MIOLocalizeString("FILL THE VALUES", "FILL THE VALUES"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(this:PurchaseInvoiceListViewController, textField:MUITextField) {
            textField.placeholderText = MIOLocalizeString("DOCUMENT NUMBER", "DOCUMENT NUMBER");
        });

        avc.addTextFieldWithConfigurationHandler(this, function(this:PurchaseInvoiceListViewController, textField:MUITextField){
            textField.formatter = MUIWebApplication.sharedInstance().dateFormatter;
            textField.placeholderText = MIOLocalizeString("DATE", "DATE");
        });

        avc.addTextFieldWithConfigurationHandler(this, function(this:PurchaseInvoiceListViewController, textField:MUITextField){
            textField.placeholderText = MIOLocalizeString("AMOUNT", "AMOUNT");
        });

        avc.addTextFieldWithConfigurationHandler(this, function(this:PurchaseInvoiceListViewController, textField:MUITextField){
            textField.placeholderText = MIOLocalizeString("CONCEPT", "CONCEPT");
        });

        avc.addComboBoxWithConfigurationHandler(this, function (comboBox: MUIComboBox) {
            DBHelper.sharedInstance().addObserverForEntity(avc, 'Tax', null, null, null, function (objects:Tax[]) {
                comboBox.removeAllItems();
                for (let i = 0; i < objects.length; i++)
                    comboBox.addItem(objects[i].name, i);
            });
        });

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Cancel, this, function( this:PurchaseInvoiceListViewController ){
            let reference = avc.textFields[0].text;
            let date = avc.textFields[1].text;
            let amount = avc.textFields[2].text;
            let concept = avc.textFields[3].text;
            let taxIndex = avc.comboBoxes[0].getSelectedItem();
            let tax = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, taxIndex, 'Tax') as Tax;

            this.createNextNumber(legalEntity, { "Reference": reference, 
                                                 "Date": date, 
                                                 "Amount": amount, 
                                                 "Concept": concept,
                                                 "VAT": tax
                                                } );
        } ) );
        
        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null) );

        this.presentViewController(avc, true);
    }
    
    private createInvoiceWithInfo(inv, info) 
    {
        if (info == null) return;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        if (info[ "Reference" ] != null) inv.externalReference = info[ "Reference" ];
        if (info[ "Date" ] != null) {
            let date = ad.dateFormatter.dateFromString( info[ "Date" ] + " 08:00:00" );
            inv.date = date;
        }
        
        let total = 0
        if (info[ "Amount" ] != null) {
            total = ad.currencyFormatter.numberFromString( info[ "Amount" ] );
        }
        
        let vat = info[ "VAT" ];        
        let concept = info[ "Concept" ];        

        let base = total / (1 + vat.taxQuantity);

        let line = MIOEntityDescription.insertNewObjectForEntityForName("PurchaseInvoiceLine", ad.managedObjectContext) as PurchaseInvoiceLine;
        line.document = inv;
        line.date = inv.date;
        line.quantity = 1;
        line.concept = concept;
        line.vat = vat;
        line.vatFactor = vat.taxQuantity
        line.baseAmount = base;
        line.priceAmount = base;
        line.totalAmount = total;
        line.taxesAmount = total - base;
        line.addTaxesObject(vat);

        inv.baseAmount = base;
        inv.totalAmount = total;
        inv.taxesAmount = total - base;

        inv.status = DBDocumentStatus.processed;    
    }

}