//
// Generated class BookingDayTimeRange
//

/// <reference path="BookingDayTimeRange_ManagedObject.ts" />

class BookingDayTimeRange extends BookingDayTimeRange_ManagedObject
{

}
