

// Generated class Changelog_ManagedObject

class Changelog_ManagedObject extends MIOManagedObject
{

    // Property: action
    set action(value:string) {
        this.setValueForKey(value, 'action');
    }
    get action():string {
        return this.valueForKey('action');
    }
    set actionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'action');
    }
    get actionPrimitiveValue():string {
        return this.primitiveValueForKey('action');
    }

    // Property: appID
    set appID(value:string) {
        this.setValueForKey(value, 'appID');
    }
    get appID():string {
        return this.valueForKey('appID');
    }
    set appIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'appID');
    }
    get appIDPrimitiveValue():string {
        return this.primitiveValueForKey('appID');
    }

    // Property: createdAt
    set createdAt(value:Date) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():Date {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():Date {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: delta
    set delta(value:any) {
        this.setValueForKey(value, 'delta');
    }
    get delta():any {
        return this.valueForKey('delta');
    }
    set deltaPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'delta');
    }
    get deltaPrimitiveValue():any {
        return this.primitiveValueForKey('delta');
    }

    // Property: entityID
    set entityID(value:string) {
        this.setValueForKey(value, 'entityID');
    }
    get entityID():string {
        return this.valueForKey('entityID');
    }
    set entityIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'entityID');
    }
    get entityIDPrimitiveValue():string {
        return this.primitiveValueForKey('entityID');
    }

    // Property: entityType
    set entityType(value:string) {
        this.setValueForKey(value, 'entityType');
    }
    get entityType():string {
        return this.valueForKey('entityType');
    }
    set entityTypePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'entityType');
    }
    get entityTypePrimitiveValue():string {
        return this.primitiveValueForKey('entityType');
    }

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: syncByAppID
    set syncByAppID(value:string) {
        this.setValueForKey(value, 'syncByAppID');
    }
    get syncByAppID():string {
        return this.valueForKey('syncByAppID');
    }
    set syncByAppIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'syncByAppID');
    }
    get syncByAppIDPrimitiveValue():string {
        return this.primitiveValueForKey('syncByAppID');
    }

    // Property: syncID
    set syncID(value:number) {
        this.setValueForKey(value, 'syncID');
    }
    get syncID():number {
        return this.valueForKey('syncID');
    }
    set syncIDPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'syncID');
    }
    get syncIDPrimitiveValue():number {
        return this.primitiveValueForKey('syncID');
    }

    // Property: updatedByAppID
    set updatedByAppID(value:string) {
        this.setValueForKey(value, 'updatedByAppID');
    }
    get updatedByAppID():string {
        return this.valueForKey('updatedByAppID');
    }
    set updatedByAppIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedByAppID');
    }
    get updatedByAppIDPrimitiveValue():string {
        return this.primitiveValueForKey('updatedByAppID');
    }
    // Relationship: syncLog
    set syncLog(value:SyncLog) {
        this.setValueForKey(value, 'syncLog');
    }
    get syncLog():SyncLog {
        return this.valueForKey('syncLog') as SyncLog;
    }
}
