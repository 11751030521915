//
// Generated class BookingZone
//

/// <reference path="BookingZone_ManagedObject.ts" />

class BookingZone extends BookingZone_ManagedObject
{
    get indexPathString() {        
        let ips = this.followParent(this.parent, this.name);
        return ips;
    }
        
    private followParent(parent, name) {
        if (parent == null) return name;        
        return this.followParent(parent.parent, parent.name) + "." + name;
    }

}
