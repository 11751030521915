

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class AnalyticAccount_ManagedObject

class AnalyticAccount_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: comments
    set comments(value:string) {
        this.setValueForKey(value, 'comments');
    }
    get comments():string {
        return this.valueForKey('comments');
    }
    set commentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'comments');
    }
    get commentsPrimitiveValue():string {
        return this.primitiveValueForKey('comments');
    }

    // Property: indexPath
    set indexPath(value:string) {
        this.setValueForKey(value, 'indexPath');
    }
    get indexPath():string {
        return this.valueForKey('indexPath');
    }
    set indexPathPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'indexPath');
    }
    get indexPathPrimitiveValue():string {
        return this.primitiveValueForKey('indexPath');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Relationship: children
    protected _children:MIOManagedObjectSet = null;
    get children():MIOManagedObjectSet {
        return this.valueForKey('children');
    }
    addChildrenObject(value:AnalyticAccount) {
        this._addObjectForKey(value, 'children');
    }
    removeChildrenObject(value:AnalyticAccount) {
        this._removeObjectForKey(value, 'children');
    }
    // Relationship: parent
    set parent(value:AnalyticAccount) {
        this.setValueForKey(value, 'parent');
    }
    get parent():AnalyticAccount {
        return this.valueForKey('parent') as AnalyticAccount;
    }
}
