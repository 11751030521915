

/// <reference path="PrinterLink.ts" />

// Generated class SourcePrinterLink_ManagedObject

class SourcePrinterLink_ManagedObject extends PrinterLink
{

    // Property: productSourceName
    set productSourceName(value:string) {
        this.setValueForKey(value, 'productSourceName');
    }
    get productSourceName():string {
        return this.valueForKey('productSourceName');
    }
    set productSourceNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productSourceName');
    }
    get productSourceNamePrimitiveValue():string {
        return this.primitiveValueForKey('productSourceName');
    }
    // Relationship: productSource
    set productSource(value:ProductSource) {
        this.setValueForKey(value, 'productSource');
    }
    get productSource():ProductSource {
        return this.valueForKey('productSource') as ProductSource;
    }
}
