/**
 * Created by miguel on 10/04/17.
 */

/// <reference path="OfferProduct_ManagedObject.ts" />
class OfferProduct extends OfferProduct_ManagedObject
{
    
}

