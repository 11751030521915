

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Party_ManagedObject

class Party_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: descriptionInfo
    set descriptionInfo(value:string) {
        this.setValueForKey(value, 'descriptionInfo');
    }
    get descriptionInfo():string {
        return this.valueForKey('descriptionInfo');
    }
    set descriptionInfoPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'descriptionInfo');
    }
    get descriptionInfoPrimitiveValue():string {
        return this.primitiveValueForKey('descriptionInfo');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Relationship: events
    protected _events:MIOManagedObjectSet = null;
    get events():MIOManagedObjectSet {
        return this.valueForKey('events');
    }
    addEventsObject(value:PartyEvent) {
        this._addObjectForKey(value, 'events');
    }
    removeEventsObject(value:PartyEvent) {
        this._removeObjectForKey(value, 'events');
    }

    // Relationship: images
    protected _images:MIOManagedObjectSet = null;
    get images():MIOManagedObjectSet {
        return this.valueForKey('images');
    }
    addImagesObject(value:PartyImage) {
        this._addObjectForKey(value, 'images');
    }
    removeImagesObject(value:PartyImage) {
        this._removeObjectForKey(value, 'images');
    }
    // Relationship: offer
    set offer(value:Offer) {
        this.setValueForKey(value, 'offer');
    }
    get offer():Offer {
        return this.valueForKey('offer') as Offer;
    }
}
