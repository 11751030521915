/// <reference path="MIOManagedObject+Serialization.ts" />


interface MIORelationshipDescription
{
    deserializeFromValue( value: any, moc: MIOManagedObjectContext ) : any ;
    serializeFromValue( value: any, committedValue: any, deltas?: boolean ) : any ;
    isIgnored( ) : boolean ;
}

MIORelationshipDescription.prototype.deserializeFromValue = function (value: any, moc:MIOManagedObjectContext ) : any 
{
    if (this.isToMany == true) {
        const objects = (value as string[]).map ( id => {
            MIOManagedObjectFromIdentifier(id, this.destinationEntity, moc)
        } )
        return objects;
    }
    else {
        return MIOManagedObjectFromIdentifier(value, this.destinationEntity, moc);
    }
}
    
MIORelationshipDescription.prototype.serializeFromValue = function (value: any, committedValue: any, deltas: boolean = true) : any 
{        
    if (value == null) { return null; }
    
    if (this.isToMany == false) return MIOManagedObjectIdentifierString(value);    
    
    let newValues = value as MIOManagedObjectSet;    
    let storedValues = committedValue as MIOManagedObjectSet;
    
    let intersect = storedValues.intersection(newValues);
    let addValues = newValues.subtracting(intersect).map( function(object:MIOManagedObject) { return MIOManagedObjectIdentifierString(object); } );
    let subValues = storedValues.subtracting(intersect).map( function(object:MIOManagedObject) { return MIOManagedObjectIdentifierString(object); } );
    
    return deltas ? [addValues, subValues] : storedValues.allObjects();

    // let values = [];
    // let set = value as MIOManagedObjectSet;
    // for (let index = 0; index < set.count; index++){
    //     let obj = set.objectAtIndex(index) as MIOManagedObject;
    //     values.addObject(MIOManagedObjectIdentifierString(obj));
    // }
        
    // return values;
}
    
MIORelationshipDescription.prototype.isIgnored = function ( ) : boolean
{
    if (this.userInfo == null) { return false };
    let value = this.userInfo["DBRelationIgnored"];
    if (value == null) { return false; }    
    value = (value as string).toLocaleLowerCase();
    if (value === "yes" || value === true) { return true; }
    return false;
}
