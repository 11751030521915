

class AddPriceFormatViewController extends MUIViewController
{
    delegate = null;

    private isPositive:boolean = true;
    private isPercentage:boolean = false;
    private stringBaseValue:number = null;

    private doneButton:MUIButton = null;
    private closeButton:MUIButton = null;
    private segmentedControl:MUISegmentedControl = null;
    private isPercentCheckBox:MUICheckButton = null;
    private priceTextfield:MUITextField = null;

    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;

    get preferredContentSize() {
        return new MIOSize(300, 250);
    }

    viewDidLoad(){
        super.viewDidLoad();

        this.closeButton = MUIOutlet(this, 'close-btn', 'MUIButton');
        this.closeButton.setAction(this, function (){
            this.dismissViewController(true);
        });

        this.doneButton = MUIOutlet(this, 'done-btn', 'MUIButton');
        this.doneButton.setAction(this, function (){

            let subString = "";
            if (this.stringBaseValue == null || this.stringBaseValue == "") {
                subString = null;
            } else {
                subString += this.isPositive ? "+" : "-";
                subString += this.nf.stringFromNumber(this.nf.numberFromString(this.stringBaseValue));
                subString += this.isPercentage ? "%" : "";
            }

            if (this.delegate != null)
                this.delegate.modifierPriceStringDidUpdate(subString);

            this.dismissViewController(true);
        });

        this.segmentedControl = MUIOutlet(this, 'segmented-control', 'MUISegmentedControl');
        this.segmentedControl.setAction(this, function (control, index){
            this.isPositive = index == 0 ? true : false;
        });

        this.isPercentCheckBox = MUIOutlet(this, 'ispercent-btn', 'MUICheckButton');
        this.isPercentCheckBox.setOnChangeValue(this,function (control, value){
            this.isPercentage = value;       
        });

        this.priceTextfield = MUIOutlet(this, "price-tf", "MUITextField");
        this.priceTextfield.formatter = this.cf;
        this.priceTextfield.setOnChangeText(this, function(control, value:string){
            this.stringBaseValue = value;
        });
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private _priceString:string = null;
    set priceString(value:string) {
        this._priceString = value;

        //if the value is null then just return
        if (!this._priceString) return;

        let startSubString = 1;
        let subString:string = null;

        //check for positive or negative and update UI
        if (this._priceString.charAt(0) == "-") {
            this.isPositive = false;
        } else if (value.charAt(0) != "+") {
            startSubString = 0;
        }

        //check if percentage
        if(this._priceString.charAt(this._priceString.length-1) == "%"){
            this.isPercentage = true;
        }

        //setting the base value from string
        if(this.isPercentage){
            subString = this._priceString.substring(startSubString, this._priceString.length-1);
        } else {
            subString = this._priceString.substring(startSubString);
        }

        //convert string to number (this requires that the string be a valid number)
        subString = this.nf.numberFromString(subString);
        this.stringBaseValue = this.nf.stringFromNumber(subString);

        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        //update segmented
        if (this.isPositive) {
            this.segmentedControl.selectSegmentedAtIndex(0);
        } else {
            this.segmentedControl.selectSegmentedAtIndex(1);
        }

        this.isPercentCheckBox.setOn(this.isPercentage);
        this.priceTextfield.text = this.stringBaseValue;
    }
}