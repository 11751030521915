
class SupplierNoteDetailHeader extends NoteDetailHeaderView
{
    private documentLabel:MUILabel = null;
    private creatorDocumentLabel:MUILabel = null;
    private originLabel:MUILabel = null;    

    private externalReferenceTextField:MUITextField = null;
    private externalReferenceLabel:MUILabel = null;

    private documentDateLabel:MUILabel = null;
    private validationDateLabel:MUILabel = null;

    private documentDateTextField:MUITextField = null;
    private documentDateButton:MUIButton = null;
    private validationDateTextField:MUITextField = null;
    private validationDateButton:MUIButton = null;    

    private dtf = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter as MIODateFormatter;

    awakeFromHTML(){
        super.awakeFromHTML();

        this.documentLabel = MUIOutlet(this, "document-id-lbl", "MUILabel");
        this.creatorDocumentLabel = MUIOutlet(this, "create-document-id-lbl", "MUILabel");
        this.originLabel = MUIOutlet(this, "origin-lbl", "MUILabel");   
        
        this.externalReferenceLabel = MUIOutlet(this, "external-reference-lbl", "MUILabel");
        this.externalReferenceTextField = MUIOutlet(this, "external-reference-tf", "MUITextField");
        if (this.externalReferenceTextField != null) this.externalReferenceTextField.setOnChangeText(this, function (this:SupplierNoteDetailHeader, control:MUITextField, value:string){
            this.stockNote.externalReference = value;
            this.checkSupplierNoteReference(value);
        });                

        this.documentDateLabel = MUIOutlet(this, "document-date-lbl", "MUILabel");
        this.documentDateTextField = MUIOutlet(this, "document-date-tf", "MUITextField");
        this.setupDocumentDateTextField(this.documentDateTextField);
        this.documentDateButton = MUIOutlet(this, "document-date-btn", "MUIButton");
        this.setupCalendarButton(this.documentDateButton, NoteDetailHeaderViewDateType.DocumentDate);
        
        this.validationDateLabel = MUIOutlet(this, "stock-date-lbl", "MUILabel");
        this.validationDateTextField = MUIOutlet(this, "stock-date-tf", "MUITextField");
        this.setupValidationDateTextField(this.validationDateTextField);
        this.validationDateButton = MUIOutlet(this, "stock-date-btn", "MUIButton");
        this.setupCalendarButton(this.validationDateButton, NoteDetailHeaderViewDateType.ValidationDate);
    }

    set note (item:StockNote){
        this.stockNote = item;

        this.documentLabel.text = item.documentID;
        this.creatorDocumentLabel.text = item.creatorDocumentID;
        this.originLabel.text = item.originName;        
        
        if (this.externalReferenceLabel != null) this.externalReferenceLabel.text = item.externalReference;
        if (this.externalReferenceTextField != null) this.externalReferenceTextField.text = item.externalReference;
        
        let documentDateString = this.dtf.stringFromDate(item.documentDate);
        if (this.documentDateLabel != null) this.documentDateLabel.text = documentDateString;
        if (this.documentDateTextField != null) this.documentDateTextField.text = documentDateString;

        let validationDateString = this.dtf.stringFromDate(item.stockDate);
        if (this.validationDateLabel != null) this.validationDateLabel.text = validationDateString;
        if (this.validationDateTextField != null) this.validationDateTextField.text = validationDateString;
    }

    protected dateDidChange(type:NoteDetailHeaderViewDateType, date:Date){
        switch(type){
            case NoteDetailHeaderViewDateType.DocumentDate:
            this.documentDateTextField.text = this.dtf.stringFromDate(date);
            break;

            case NoteDetailHeaderViewDateType.ValidationDate:
            this.validationDateTextField.text = this.dtf.stringFromDate(date);
            break;
        }
    }

    protected customStockNoteBeforeChangeStatus(status:number) : boolean {

        if (status != StockNoteStatus.Processed) return true;

        let ok = true;

        //if Supplier note - check for lines and that measureType != 0
        let lines = this.stockNote.lines.allObjects;
        for (let index = 0; index < lines.length; index++){
            let l = lines[index] as StockNoteLine;

            //check if measureType is 2 (no unit format)
            if (l.quantityMeasureType == 0){
                ok = false;
            }
            //check for price, product price, and total as null then set to 0 (null product price error catch)
            if (l.price == null) {
                this.stockNote.lines.allObjects[index].price = 0;
            }
            if (l.productPrice == null) {
                this.stockNote.lines.allObjects[index].productPrice = 0;
            }
            if (l.baseAmount == null) {
                this.stockNote.lines.allObjects[index].baseAmount = 0;
            }
        }

        if (ok == false) {
            AppHelper.showErrorMessage(null, "Info", "Please make sure all products have a correct unit format");
        }
        
        return ok;
    }    
    
    private checkSupplierNoteReference(value:string) {
        let v = value.trim();
        MUICoreLayerRemoveStyle(this.externalReferenceTextField.layer, "alert");
        if (v.length == 0) return;
        DBHelper.queryObjectWithCompletion("SupplierNote", null, MIOPredicate.predicateWithFormat("externalReference == '" + v + "'"), [], this, function(this:SupplierNoteDetailHeader, object:SupplierNote){
            if (object == null) return;
            if (object.identifier == this.stockNote.identifier) return;
            MUICoreLayerAddStyle(this.externalReferenceTextField.layer, "alert");   
            AppHelper.showErrorMessage(null, "ERROR", "THE SUPPLIER NOTE REFERENCE ALREADY EXIST!");
        });
    }

    protected setupWarehouseDropdown(button:MUIButton, target, completion){
        if (button == null) return;
        button.setAction(this, function(){
            AppHelper.sharedInstance().showSelectWarehouseViewControllerFromView(button, this.stockNote.overrideWarehouse, true, false, target, completion);
        });   
    }

}