class DBSupplierProductsCoder extends DBXLSXCoder
{
    protected mainEntity() {
        return { entity: "SupplierProduct", predicateFormat: "deletedAt == null", relations: ["product", "supplier"] };
    }

    columns() : any[] {
        let cols = [            
            { title : "SUPPLIER PRODUCT ID", align: "center", width: "10%" },
            { title : "SUPPLIER ID", align: "center", width: "10%" },
            { title : "SUPPLIER REFERENCE", align: "center", width: "10%" },
            { title : "SUPPLIER NAME", align: "center", width: "20%"},
            { title : "PRODUCT ID", align: "center", width: "10%" },
            { title : "PRODUCT REFERENCE", align: "center", width: "10%" },
            { title : "PRODUCT NAME", align: "center", width: "30%" },
            { title : "PRODUCT SUPPLIER REFERENCE", align: "center", width: "30%" },
            { title : "PRICE", align: "center", width: "10%", formatter: this.ad.currencyFormatter },
            { title : "DISCOUNT", align: "center", width: "5%", formatter: this.ad.percentNumberFormatter }
        ]
        return cols;
    }

    aditionalImportEntities(){
        return [
            { entity: "Supplier", predicateFormat: "deletedAt == null" },
            { entity: "Product", predicateFormat: "deletedAt == null" }            
        ]
    }

    protected importRow(row){
        
        let supplierProductID = row["SUPPLIER PRODUCT ID"];
        let supplierID        = row["SUPPLIER ID"];
        let supplierName      = row["SUPPLIER NAME"];
        let reference         = row["PRODUCT REFERENCE"];
        let productID         = row["PRODUCT ID"];
        let productName       = row["PRODUCT NAME"];
        let supplierProductRef = row["PRODUCT SUPPLIER REFERENCE"];
        let price             = row["PRICE"];
        let discount          = row["DISCOUNT"];

        let supplier = this.queryEntityByIDOrName("Supplier", supplierID, supplierName) as Supplier;
        if (supplier == null) {
            // TODO: throw an exception
            return
        }        

        let product = this.queryEntityProduct(productID, reference, productName);
        if (product == null) {
            // TODO: throw an exception
            return;
        }

        let supplierProduct:SupplierProduct = null;
        supplierProduct =  supplierProductID?.length > 0 ? this.queryEntityByField("SupplierProduct", "identifier", supplierProductID.toUpperCase()) as SupplierProduct : null;
        if (supplierProduct == null) supplierProduct = this.queryEntity("SupplierProduct", "product.identifier = " + product.identifier + " AND supplier.identifier = " + supplier.identifier) as SupplierProduct;
        if (supplierProduct == null) { 
            supplierProduct = MIOEntityDescription.insertNewObjectForEntityForName("SupplierProduct", DBHelper.mainManagedObjectContext) as SupplierProduct;
            supplierProduct.identifier = this.parseOrCreateIdentifier(supplierProductID);
            supplierProduct.product = product;            
            supplierProduct.supplier = supplier;     
            this.appendObject(supplierProduct);       
        }

        supplierProduct.supplierReference = supplierProductRef;
        supplierProduct.price = price;
        supplierProduct.discountString = this.ad.percentNumberFormatter.stringFromNumber(discount);        
        
        MIOLog("ADDING SUPPLIER PRODUCT: " + this.rowIndex + "/" + this.rows.length + ": " + supplierName + " - " + productName );
    }
    
    // 
    // Export
    // 

    protected exportTitle() : string { return "Supplier Products"; }
             
    protected exportSortDescriptors() { 
        return [MIOSortDescriptor.sortDescriptorWithKey("product.name", false)];
    }        

    protected parseObject(object:MIOManagedObject): any {
        let supplierProduct = object as SupplierProduct;

        let item = {
            "SUPPLIER PRODUCT ID"   : supplierProduct.identifier,
            "SUPPLIER REFERENCE"    : supplierProduct.supplier.reference,
            "SUPPLIER ID"           : supplierProduct.supplier.identifier,
            "SUPPLIER NAME"         : supplierProduct.supplier.name,
            "PRODUCT ID"            : supplierProduct.product.identifier,
            "PRODUCT REFERENCE"     : supplierProduct.product.reference,
            "PRODUCT NAME"          : supplierProduct.product.name,
            "PRODUCT SUPPLIER REFERENCE" : supplierProduct.supplierReference,
            "PRICE"                 : supplierProduct.price,
            "DISCOUNT"              : supplierProduct.discountString
        }

        return item;
    }
}