//
// Generated class ProductWarehouseLocation
//

/// <reference path="ProductWarehouseLocation_ManagedObject.ts" />

class ProductWarehouseLocation extends ProductWarehouseLocation_ManagedObject
{

}
