
/// <reference path="DashboardCellView.ts" />

class DashboardSalesByCashDeskCellView extends DashboardCellView 
{        
    static cashdesksCellView(): DashboardSalesByCashDeskCellView {

        var dv = new DashboardSalesByCashDeskCellView();
        dv.init();

        return dv;
    }

    init(){
        super.init();
        
        this.title = "Sales by cashdesk";
       // this.report = ReportHelper.sharedInstance().salesByCashdeskReportQuery();
        this.chartType = MUIChartViewType.HorizontalBar;
        this.labelKey = "name";
        this.valueKey = "total";
    }

    protected drawDataWithItems(items){
        var labels = [];
        var values = [];

        for (var index = 0; index < items.length; index++) {

            let item = items[index];
            let type = item["type"];
            if (type != 2 && type != -1) continue;

            var label = item[this.labelKey];
            var value = item[this.valueKey];

            labels.push(label);
            values.push(value);

            if (this.chartType == MUIChartViewType.Pie && index >= 4) break;
        }

        this.chartView.labels = labels;
        this.chartView.values = values;
        this.chartView.renderWithType(this.chartType);
    }

}
