

/// <reference path="ChildPlace.ts" />

// Generated class ProductModifierPlace_ManagedObject

class ProductModifierPlace_ManagedObject extends ChildPlace
{

    // Property: isDefault
    set isDefault(value:boolean) {
        this.setValueForKey(value, 'isDefault');
    }
    get isDefault():boolean {
        return this.valueForKey('isDefault');
    }
    set isDefaultPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isDefault');
    }
    get isDefaultPrimitiveValue():boolean {
        return this.primitiveValueForKey('isDefault');
    }

    // Property: isVisible
    set isVisible(value:boolean) {
        this.setValueForKey(value, 'isVisible');
    }
    get isVisible():boolean {
        return this.valueForKey('isVisible');
    }
    set isVisiblePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isVisible');
    }
    get isVisiblePrimitiveValue():boolean {
        return this.primitiveValueForKey('isVisible');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: priceFormat
    set priceFormat(value:string) {
        this.setValueForKey(value, 'priceFormat');
    }
    get priceFormat():string {
        return this.valueForKey('priceFormat');
    }
    set priceFormatPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'priceFormat');
    }
    get priceFormatPrimitiveValue():string {
        return this.primitiveValueForKey('priceFormat');
    }
    // Relationship: productModifier
    set productModifier(value:ProductModifier) {
        this.setValueForKey(value, 'productModifier');
    }
    get productModifier():ProductModifier {
        return this.valueForKey('productModifier') as ProductModifier;
    }
}
