
class DBMappingCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    private mappingNameLabel:MUILabel = null;
    private mappingIDLabel:MUILabel = null;
    private unlinkButton:MUIButton = null;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.mappingNameLabel = MUIOutlet(this, "mapping-name-lbl", "MUILabel");
        this.mappingIDLabel = MUIOutlet(this, "mapping-id-lbl", "MUILabel");        
        this.unlinkButton = MUIOutlet(this, "unlink-btn", "MUIButton");
        this.unlinkButton.setAction(this, this.unlinkAction);

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private _item:IntegratorMappingView = null;
    set item(item:IntegratorMappingView){   
        this._item = item;     
        this.nameLabel.text = item.sourceName;
        this.mappingNameLabel.text = item.targetName;
        this.mappingIDLabel.text = item.targetID;        

        this.unlinkButton.hidden = item.targetID != null ? false : true;
    }

    private unlinkAction(){
        // this._item.foreignEntityName = null;
        // this._item.foreignID = null;
        // this.mappingNameLabel.text = null;
        // this.mappingIDLabel.text = null;        
        // this.unlinkButton.hidden = true;
        
        // DBHelper.queryObjectsWithCompletion("DBMappingEntity", [], MIOPredicate.predicateWithFormat("identifier == " + this._item.mappingID), [], this, function(objects){
        //     if (objects.length > 0) {
        //         DBHelper.deleteObjectFromMainContext(objects[0], false);
        //         DBHelper.saveMainContextWithCompletion(this, function(){
        //             MIONotificationCenter.defaultCenter().postNotification("DBMappingItemDidChange", this);
        //         });
        //     }
        // });        
    }

}