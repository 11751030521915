//
// Generated class StockInputFormat
//

/// <reference path="StockInputFormat_ManagedObject.ts" />

class StockInputFormat extends StockInputFormat_ManagedObject
{
    
}
