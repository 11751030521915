

/// <reference path="./map/mapping.ts" />
/// <reference path="./map/sampledata.ts" />

class EventDetailMapViewController extends MUIViewController
{

    private _event:DLEvent = null;
    
    viewDidLoad()
    {
        super.viewDidLoad();
        
        setTimeout(function () {
            //DIRTY HACK!!!! REMOVE AS SOON AS YOU CAN
            const parent = document.getElementById("event-detail-map-view");
            if (parent) {
                let index = 0;
                for (let locationCategory of MapItemsMock.data) {
                    const categoryDOM = createLocationCategory(locationCategory.id, index++);
                    categoryDOM.classList.add(MapBackgroundFactory(locationCategory.backgroundtype));
                    for (let mapItem of locationCategory.locations) {
                        categoryDOM.appendChild(createLocation(MapItemFactory(mapItem)));
                    }
                    parent.appendChild(categoryDOM);
                }
                } else {
                    console.log("Map is not available yet");
                }
            }, 2000);
        }

    set event(value)
    {
        this._event = value;
        this.updateUI();
    }

    private updateUI()
    {
        if(this.viewIsLoaded && this._event != null)
        {

        }
    }
}