//
// Generated class MovementNote
//

/// <reference path="MovementNote_ManagedObject.ts" />

class MovementNote extends MovementNote_ManagedObject
{

}
