

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class PaymentInstallmentTemplate_ManagedObject

class PaymentInstallmentTemplate_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: expirationInterval
    set expirationInterval(value:string) {
        this.setValueForKey(value, 'expirationInterval');
    }
    get expirationInterval():string {
        return this.valueForKey('expirationInterval');
    }
    set expirationIntervalPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'expirationInterval');
    }
    get expirationIntervalPrimitiveValue():string {
        return this.primitiveValueForKey('expirationInterval');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: numberOfPayments
    set numberOfPayments(value:number) {
        this.setValueForKey(value, 'numberOfPayments');
    }
    get numberOfPayments():number {
        return this.valueForKey('numberOfPayments');
    }
    set numberOfPaymentsPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'numberOfPayments');
    }
    get numberOfPaymentsPrimitiveValue():number {
        return this.primitiveValueForKey('numberOfPayments');
    }
}
