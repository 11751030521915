//
// Generated class Application
//

/// <reference path="Application_ManagedObject.ts" />

enum ApplicationType 
{
    None,
    POS,
    Waiter,
    Slave,
    Kitchen,
    Booking,
    Rider
}

class Application extends Application_ManagedObject
{
    typeString():string{
        switch(this.type){
            case ApplicationType.None: return MIOLocalizeString("NONE", "NONE");
            case ApplicationType.POS: return MIOLocalizeString("POS APP", "POS APP");
            case ApplicationType.Waiter: return MIOLocalizeString("WAITER APP", "WAITER APP");
            case ApplicationType.Slave: return MIOLocalizeString("SLAVE APP", "SLAVE APP");
            case ApplicationType.Rider: return MIOLocalizeString("RIDER APP", "RIDER APP");
        }
        return null;
    }
}
