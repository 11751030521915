

/// <reference path="ArchivedDocument.ts" />

// Generated class ArchivedTicket_ManagedObject

class ArchivedTicket_ManagedObject extends ArchivedDocument
{

    // Property: bookingClientID
    set bookingClientID(value:string) {
        this.setValueForKey(value, 'bookingClientID');
    }
    get bookingClientID():string {
        return this.valueForKey('bookingClientID');
    }
    set bookingClientIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'bookingClientID');
    }
    get bookingClientIDPrimitiveValue():string {
        return this.primitiveValueForKey('bookingClientID');
    }

    // Property: bookingClientName
    set bookingClientName(value:string) {
        this.setValueForKey(value, 'bookingClientName');
    }
    get bookingClientName():string {
        return this.valueForKey('bookingClientName');
    }
    set bookingClientNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'bookingClientName');
    }
    get bookingClientNamePrimitiveValue():string {
        return this.primitiveValueForKey('bookingClientName');
    }

    // Property: bookingID
    set bookingID(value:string) {
        this.setValueForKey(value, 'bookingID');
    }
    get bookingID():string {
        return this.valueForKey('bookingID');
    }
    set bookingIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'bookingID');
    }
    get bookingIDPrimitiveValue():string {
        return this.primitiveValueForKey('bookingID');
    }

    // Property: bookingMinimumFee
    set bookingMinimumFee(value:number) {
        this.setValueForKey(value, 'bookingMinimumFee');
    }
    get bookingMinimumFee():number {
        return this.valueForKey('bookingMinimumFee');
    }
    set bookingMinimumFeePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'bookingMinimumFee');
    }
    get bookingMinimumFeePrimitiveValue():number {
        return this.primitiveValueForKey('bookingMinimumFee');
    }

    // Property: closeWorkerID
    set closeWorkerID(value:string) {
        this.setValueForKey(value, 'closeWorkerID');
    }
    get closeWorkerID():string {
        return this.valueForKey('closeWorkerID');
    }
    set closeWorkerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'closeWorkerID');
    }
    get closeWorkerIDPrimitiveValue():string {
        return this.primitiveValueForKey('closeWorkerID');
    }

    // Property: closeWorkerName
    set closeWorkerName(value:string) {
        this.setValueForKey(value, 'closeWorkerName');
    }
    get closeWorkerName():string {
        return this.valueForKey('closeWorkerName');
    }
    set closeWorkerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'closeWorkerName');
    }
    get closeWorkerNamePrimitiveValue():string {
        return this.primitiveValueForKey('closeWorkerName');
    }

    // Property: linkedLegalEntityAddress
    set linkedLegalEntityAddress(value:string) {
        this.setValueForKey(value, 'linkedLegalEntityAddress');
    }
    get linkedLegalEntityAddress():string {
        return this.valueForKey('linkedLegalEntityAddress');
    }
    set linkedLegalEntityAddressPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'linkedLegalEntityAddress');
    }
    get linkedLegalEntityAddressPrimitiveValue():string {
        return this.primitiveValueForKey('linkedLegalEntityAddress');
    }

    // Property: linkedLegalEntityAddress2
    set linkedLegalEntityAddress2(value:string) {
        this.setValueForKey(value, 'linkedLegalEntityAddress2');
    }
    get linkedLegalEntityAddress2():string {
        return this.valueForKey('linkedLegalEntityAddress2');
    }
    set linkedLegalEntityAddress2PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'linkedLegalEntityAddress2');
    }
    get linkedLegalEntityAddress2PrimitiveValue():string {
        return this.primitiveValueForKey('linkedLegalEntityAddress2');
    }

    // Property: linkedLegalEntityDocument
    set linkedLegalEntityDocument(value:string) {
        this.setValueForKey(value, 'linkedLegalEntityDocument');
    }
    get linkedLegalEntityDocument():string {
        return this.valueForKey('linkedLegalEntityDocument');
    }
    set linkedLegalEntityDocumentPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'linkedLegalEntityDocument');
    }
    get linkedLegalEntityDocumentPrimitiveValue():string {
        return this.primitiveValueForKey('linkedLegalEntityDocument');
    }

    // Property: linkedLegalEntityID
    set linkedLegalEntityID(value:string) {
        this.setValueForKey(value, 'linkedLegalEntityID');
    }
    get linkedLegalEntityID():string {
        return this.valueForKey('linkedLegalEntityID');
    }
    set linkedLegalEntityIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'linkedLegalEntityID');
    }
    get linkedLegalEntityIDPrimitiveValue():string {
        return this.primitiveValueForKey('linkedLegalEntityID');
    }

    // Property: linkedLegalEntityName
    set linkedLegalEntityName(value:string) {
        this.setValueForKey(value, 'linkedLegalEntityName');
    }
    get linkedLegalEntityName():string {
        return this.valueForKey('linkedLegalEntityName');
    }
    set linkedLegalEntityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'linkedLegalEntityName');
    }
    get linkedLegalEntityNamePrimitiveValue():string {
        return this.primitiveValueForKey('linkedLegalEntityName');
    }

    // Property: locationCategoryID
    set locationCategoryID(value:string) {
        this.setValueForKey(value, 'locationCategoryID');
    }
    get locationCategoryID():string {
        return this.valueForKey('locationCategoryID');
    }
    set locationCategoryIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationCategoryID');
    }
    get locationCategoryIDPrimitiveValue():string {
        return this.primitiveValueForKey('locationCategoryID');
    }

    // Property: locationCategoryName
    set locationCategoryName(value:string) {
        this.setValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryName():string {
        return this.valueForKey('locationCategoryName');
    }
    set locationCategoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationCategoryName');
    }

    // Property: locationID
    set locationID(value:string) {
        this.setValueForKey(value, 'locationID');
    }
    get locationID():string {
        return this.valueForKey('locationID');
    }
    set locationIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationID');
    }
    get locationIDPrimitiveValue():string {
        return this.primitiveValueForKey('locationID');
    }

    // Property: locationName
    set locationName(value:string) {
        this.setValueForKey(value, 'locationName');
    }
    get locationName():string {
        return this.valueForKey('locationName');
    }
    set locationNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationName');
    }
    get locationNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationName');
    }

    // Property: openDate
    set openDate(value:Date) {
        this.setValueForKey(value, 'openDate');
    }
    get openDate():Date {
        return this.valueForKey('openDate');
    }
    set openDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'openDate');
    }
    get openDatePrimitiveValue():Date {
        return this.primitiveValueForKey('openDate');
    }

    // Property: openWorkerID
    set openWorkerID(value:string) {
        this.setValueForKey(value, 'openWorkerID');
    }
    get openWorkerID():string {
        return this.valueForKey('openWorkerID');
    }
    set openWorkerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'openWorkerID');
    }
    get openWorkerIDPrimitiveValue():string {
        return this.primitiveValueForKey('openWorkerID');
    }

    // Property: openWorkerName
    set openWorkerName(value:string) {
        this.setValueForKey(value, 'openWorkerName');
    }
    get openWorkerName():string {
        return this.valueForKey('openWorkerName');
    }
    set openWorkerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'openWorkerName');
    }
    get openWorkerNamePrimitiveValue():string {
        return this.primitiveValueForKey('openWorkerName');
    }

    // Property: orderDeliveryErrorComment
    set orderDeliveryErrorComment(value:string) {
        this.setValueForKey(value, 'orderDeliveryErrorComment');
    }
    get orderDeliveryErrorComment():string {
        return this.valueForKey('orderDeliveryErrorComment');
    }
    set orderDeliveryErrorCommentPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderDeliveryErrorComment');
    }
    get orderDeliveryErrorCommentPrimitiveValue():string {
        return this.primitiveValueForKey('orderDeliveryErrorComment');
    }

    // Property: orderDeliveryReceivedByClient
    set orderDeliveryReceivedByClient(value:boolean) {
        this.setValueForKey(value, 'orderDeliveryReceivedByClient');
    }
    get orderDeliveryReceivedByClient():boolean {
        return this.valueForKey('orderDeliveryReceivedByClient');
    }
    set orderDeliveryReceivedByClientPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'orderDeliveryReceivedByClient');
    }
    get orderDeliveryReceivedByClientPrimitiveValue():boolean {
        return this.primitiveValueForKey('orderDeliveryReceivedByClient');
    }

    // Property: orderDeliveryReceivedClientDocument
    set orderDeliveryReceivedClientDocument(value:string) {
        this.setValueForKey(value, 'orderDeliveryReceivedClientDocument');
    }
    get orderDeliveryReceivedClientDocument():string {
        return this.valueForKey('orderDeliveryReceivedClientDocument');
    }
    set orderDeliveryReceivedClientDocumentPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderDeliveryReceivedClientDocument');
    }
    get orderDeliveryReceivedClientDocumentPrimitiveValue():string {
        return this.primitiveValueForKey('orderDeliveryReceivedClientDocument');
    }

    // Property: orderDeliveryReceivedClientName
    set orderDeliveryReceivedClientName(value:string) {
        this.setValueForKey(value, 'orderDeliveryReceivedClientName');
    }
    get orderDeliveryReceivedClientName():string {
        return this.valueForKey('orderDeliveryReceivedClientName');
    }
    set orderDeliveryReceivedClientNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderDeliveryReceivedClientName');
    }
    get orderDeliveryReceivedClientNamePrimitiveValue():string {
        return this.primitiveValueForKey('orderDeliveryReceivedClientName');
    }

    // Property: orderDeliveryWorkerID
    set orderDeliveryWorkerID(value:string) {
        this.setValueForKey(value, 'orderDeliveryWorkerID');
    }
    get orderDeliveryWorkerID():string {
        return this.valueForKey('orderDeliveryWorkerID');
    }
    set orderDeliveryWorkerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderDeliveryWorkerID');
    }
    get orderDeliveryWorkerIDPrimitiveValue():string {
        return this.primitiveValueForKey('orderDeliveryWorkerID');
    }

    // Property: orderDeliveryWorkerName
    set orderDeliveryWorkerName(value:string) {
        this.setValueForKey(value, 'orderDeliveryWorkerName');
    }
    get orderDeliveryWorkerName():string {
        return this.valueForKey('orderDeliveryWorkerName');
    }
    set orderDeliveryWorkerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderDeliveryWorkerName');
    }
    get orderDeliveryWorkerNamePrimitiveValue():string {
        return this.primitiveValueForKey('orderDeliveryWorkerName');
    }

    // Property: orderEconomicEntityID
    set orderEconomicEntityID(value:string) {
        this.setValueForKey(value, 'orderEconomicEntityID');
    }
    get orderEconomicEntityID():string {
        return this.valueForKey('orderEconomicEntityID');
    }
    set orderEconomicEntityIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderEconomicEntityID');
    }
    get orderEconomicEntityIDPrimitiveValue():string {
        return this.primitiveValueForKey('orderEconomicEntityID');
    }

    // Property: orderEconomicEntityName
    set orderEconomicEntityName(value:string) {
        this.setValueForKey(value, 'orderEconomicEntityName');
    }
    get orderEconomicEntityName():string {
        return this.valueForKey('orderEconomicEntityName');
    }
    set orderEconomicEntityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderEconomicEntityName');
    }
    get orderEconomicEntityNamePrimitiveValue():string {
        return this.primitiveValueForKey('orderEconomicEntityName');
    }

    // Property: orderRouteID
    set orderRouteID(value:string) {
        this.setValueForKey(value, 'orderRouteID');
    }
    get orderRouteID():string {
        return this.valueForKey('orderRouteID');
    }
    set orderRouteIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderRouteID');
    }
    get orderRouteIDPrimitiveValue():string {
        return this.primitiveValueForKey('orderRouteID');
    }

    // Property: orderRouteName
    set orderRouteName(value:string) {
        this.setValueForKey(value, 'orderRouteName');
    }
    get orderRouteName():string {
        return this.valueForKey('orderRouteName');
    }
    set orderRouteNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderRouteName');
    }
    get orderRouteNamePrimitiveValue():string {
        return this.primitiveValueForKey('orderRouteName');
    }

    // Property: orderSessionDeliveryDay
    set orderSessionDeliveryDay(value:string) {
        this.setValueForKey(value, 'orderSessionDeliveryDay');
    }
    get orderSessionDeliveryDay():string {
        return this.valueForKey('orderSessionDeliveryDay');
    }
    set orderSessionDeliveryDayPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderSessionDeliveryDay');
    }
    get orderSessionDeliveryDayPrimitiveValue():string {
        return this.primitiveValueForKey('orderSessionDeliveryDay');
    }

    // Property: orderSessionDeliveryIsNextDay
    set orderSessionDeliveryIsNextDay(value:boolean) {
        this.setValueForKey(value, 'orderSessionDeliveryIsNextDay');
    }
    get orderSessionDeliveryIsNextDay():boolean {
        return this.valueForKey('orderSessionDeliveryIsNextDay');
    }
    set orderSessionDeliveryIsNextDayPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'orderSessionDeliveryIsNextDay');
    }
    get orderSessionDeliveryIsNextDayPrimitiveValue():boolean {
        return this.primitiveValueForKey('orderSessionDeliveryIsNextDay');
    }

    // Property: orderSessionDeliveryTime
    set orderSessionDeliveryTime(value:string) {
        this.setValueForKey(value, 'orderSessionDeliveryTime');
    }
    get orderSessionDeliveryTime():string {
        return this.valueForKey('orderSessionDeliveryTime');
    }
    set orderSessionDeliveryTimePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderSessionDeliveryTime');
    }
    get orderSessionDeliveryTimePrimitiveValue():string {
        return this.primitiveValueForKey('orderSessionDeliveryTime');
    }

    // Property: recoveredFromDocumentID
    set recoveredFromDocumentID(value:string) {
        this.setValueForKey(value, 'recoveredFromDocumentID');
    }
    get recoveredFromDocumentID():string {
        return this.valueForKey('recoveredFromDocumentID');
    }
    set recoveredFromDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'recoveredFromDocumentID');
    }
    get recoveredFromDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('recoveredFromDocumentID');
    }

    // Property: timesPrinted
    set timesPrinted(value:number) {
        this.setValueForKey(value, 'timesPrinted');
    }
    get timesPrinted():number {
        return this.valueForKey('timesPrinted');
    }
    set timesPrintedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'timesPrinted');
    }
    get timesPrintedPrimitiveValue():number {
        return this.primitiveValueForKey('timesPrinted');
    }

    // Property: tipID
    set tipID(value:string) {
        this.setValueForKey(value, 'tipID');
    }
    get tipID():string {
        return this.valueForKey('tipID');
    }
    set tipIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tipID');
    }
    get tipIDPrimitiveValue():string {
        return this.primitiveValueForKey('tipID');
    }

    // Property: tipModifierString
    set tipModifierString(value:string) {
        this.setValueForKey(value, 'tipModifierString');
    }
    get tipModifierString():string {
        return this.valueForKey('tipModifierString');
    }
    set tipModifierStringPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tipModifierString');
    }
    get tipModifierStringPrimitiveValue():string {
        return this.primitiveValueForKey('tipModifierString');
    }

    // Property: todayOrderNumber
    set todayOrderNumber(value:number) {
        this.setValueForKey(value, 'todayOrderNumber');
    }
    get todayOrderNumber():number {
        return this.valueForKey('todayOrderNumber');
    }
    set todayOrderNumberPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'todayOrderNumber');
    }
    get todayOrderNumberPrimitiveValue():number {
        return this.primitiveValueForKey('todayOrderNumber');
    }

    // Property: transformDocumentID
    set transformDocumentID(value:string) {
        this.setValueForKey(value, 'transformDocumentID');
    }
    get transformDocumentID():string {
        return this.valueForKey('transformDocumentID');
    }
    set transformDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'transformDocumentID');
    }
    get transformDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('transformDocumentID');
    }
}
