

/// <reference path="ReconliceTransaction.ts" />

// Generated class IntallmentReconcileTransaction_ManagedObject

class IntallmentReconcileTransaction_ManagedObject extends ReconliceTransaction
{

    // Relationship: installments
    protected _installments:MIOManagedObjectSet = null;
    get installments():MIOManagedObjectSet {
        return this.valueForKey('installments');
    }
    addInstallmentsObject(value:PaymentInstallment) {
        this._addObjectForKey(value, 'installments');
    }
    removeInstallmentsObject(value:PaymentInstallment) {
        this._removeObjectForKey(value, 'installments');
    }
}
