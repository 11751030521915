//
// Generated class Dashboard
//

/// <reference path="Dashboard_ManagedObject.ts" />

class Dashboard extends Dashboard_ManagedObject
{

}
