//
// Generated class LowOrderLog
//

/// <reference path="LowOrderLog_ManagedObject.ts" />

class LowOrderLog extends LowOrderLog_ManagedObject
{

}
