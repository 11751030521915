

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ModifierLine_ManagedObject

class ModifierLine_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: quantity
    set quantity(value:number) {
        this.setValueForKey(value, 'quantity');
    }
    get quantity():number {
        return this.valueForKey('quantity');
    }
    set quantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'quantity');
    }
    get quantityPrimitiveValue():number {
        return this.primitiveValueForKey('quantity');
    }

    // Property: warehouseID
    set warehouseID(value:string) {
        this.setValueForKey(value, 'warehouseID');
    }
    get warehouseID():string {
        return this.valueForKey('warehouseID');
    }
    set warehouseIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'warehouseID');
    }
    get warehouseIDPrimitiveValue():string {
        return this.primitiveValueForKey('warehouseID');
    }
    // Relationship: cheffLine
    set cheffLine(value:CheffLine) {
        this.setValueForKey(value, 'cheffLine');
    }
    get cheffLine():CheffLine {
        return this.valueForKey('cheffLine') as CheffLine;
    }
    // Relationship: modifier
    set modifier(value:ProductModifier) {
        this.setValueForKey(value, 'modifier');
    }
    get modifier():ProductModifier {
        return this.valueForKey('modifier') as ProductModifier;
    }
}
