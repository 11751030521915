

class PaymethodListViewController extends MUIViewController 
{
    static newInstance() : PaymethodListViewController {
        let vc = new PaymethodListViewController("paymethod-list-view");
        vc.initWithResource("layout/paymethod/PaymethodListView.html");

        return vc;
    }

    private saveButton:MUIButton = null;
    private addButton:MUIButton = null;

    private tableView:UITableView = null;

    viewDidLoad() {
        super.viewDidLoad();

        // this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        // this.saveButton.setAction(this, function(){
        //     let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        //     ad.managedObjectContext.save();
        // });

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, this.addPaymentMethod);

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.tableView.reloadData();
        
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("PaymethodCell") as PaymethodCell;

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as PayMethod
        cell.item = item;

        return cell;
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);

        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            DBHelper.deleteObjectFromMainContext(item, true);
            // this.tableView.deleteRowsAtIndexPaths([indexPath], UITableViewRowAnimation.Automatic);
        }
    }


    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (this._fetchedResultsController != null) {
            this._fetchedResultsController.delegate = null;
        }
            
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let sds = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("PayMethod", sds, null);        

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;

        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    addPaymentMethod(){

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('NEW PAY METHOD','NEW PAY METHOD'), MIOLocalizeString('CHOOSE A NAME','CHOOSE A NAME'), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
           textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });

        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
            comboBox.removeAllItems();
            let types = PayMethod.supportedTypes();
            for (let i = 0; i < types.length; i++) {
                comboBox.addItem(PayMethod.typeString( types[i] ), types[i] );
            }
            comboBox.selectItem( 0 );
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("ADD","ADD"), MUIAlertActionStyle.Default, this, function(){
            let name = avc.textFields[0].text;
            let type = parseInt( avc.comboBoxes[0].getSelectedItem() );
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            let pm = MIOEntityDescription.insertNewObjectForEntityForName("PayMethod", ad.managedObjectContext) as PayMethod;
            pm.name = name;    
            pm.type = type;
            
            ad.managedObjectContext.save();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, null, null));        

        this.presentViewController(avc, true);
    }
}
