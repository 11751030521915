/**
 * Created by gds on 7/6/22.
 */


 class PartyEventDetailTicketsViewController extends MUIViewController
 {
    source:BookingSource = null;

    static newInstance() : PartyEventDetailTicketsViewController {
        let vc = new PartyEventDetailTicketsViewController("party-event-detail-tickets-view");
        vc.initWithResource("layout/party_event/PartyEventDetailTicketsView.html");
        return vc;
    }

     private tableView = null;
 
     viewDidLoad(){
         super.viewDidLoad();
 
         this.tableView = MUIOutlet(this, 'table-view', 'UITableView');
         this.tableView.dataSource = this;
         this.tableView.delegate = this;
         this._updateUI();
     }
 
     viewWillAppear(animate?){
         super.viewWillAppear(animate);
         this._updateUI();
     }
     
     private _partyEvent:PartyEvent = null;
     set event(value:PartyEvent){
         this._partyEvent = value;
         this._updateUI();
     }
 
     private _filter = 0;
     set filter (value:number){
         this._filter = value;
         if (value < 0 || value > 2) this._filter = 2;
         this._updateUI();
     }
 
     private _updateUI(){
        if(this.viewIsLoaded == false || this._partyEvent == null) return;
        this.fetchedResultsController = null;
        this.tableView.reloadData();        
     }
     
     public itemDidSelected(item){        
         var avc = new MUIAlertViewController();
         avc.initWithTitle(MIOLocalizeString('CHOOSE THE ACTION',"CHOOSE THE ACTION"), MIOLocalizeString('CHOOSE AN ACTION',"CHOOSE THE ACTION"), MUIAlertViewStyle.Default);
 
         avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('EXPORT',"EXPORT"), MUIAlertActionStyle.Default, this, function(){
             console.log("Export clicked");             
         }));
 
         this.presentViewController(avc, true);
     }
 
     numberOfSections(tableview){
         return this.fetchedResultsController.sections.length;
     }
     
     numberOfRowsInSection(tableview, section){
         let sec = this.fetchedResultsController.sections[section];
         return sec.numberOfObjects();
     }
    
     cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
         let cell = tableview.dequeueReusableCellWithIdentifier('PartyTicketCell') as PartyTicketCell;            
         
         let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
         
         cell.item = item;         
     
         return cell;
     }
       
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        let obj = this.fetchedResultsController.objectAtIndexPath(indexPath);   
    }
    
    private _fetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
    
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('name', true)];
        let predicateFormat = 'event.identifier == ' + this._partyEvent.identifier;
 
        if (this.source != null) {
            predicateFormat += " and source.identifier = " + this.source.identifier;
        }

     //    switch (this._filter) {
     //        case 0: // not consumed
     //             predicateFormat += " AND usedDate == null";
     //             break;
 
     //         case 1: // consumed
     //             predicateFormat += " AND usedDate != null";
     //             break;
     //    }
 
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("EventTicket", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["source"];
        fetchRequest.fetchLimit = 100;        
 
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    addTicketAction() {
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("GENERATE TICKETS", "GENERATE TICKETS"), MIOLocalizeString("CHOOSE QUANTITY", "CHOOSE QUANTITY"), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("PREFIX", "PREFIX"));
        });
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("START NUMBER", "START NUMBER"));
        });
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("END NUMBER", "END NUMBER"));
        });
        
        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){            
            DBHelper.sharedInstance().addObserverForEntity(avc, "BookingSource", null, null, null, function (objects) {
                comboBox.removeAllItems();                
                for (let i = 0; i < objects.length; i++){
                    let c = objects[i] as BookingSource;
                    comboBox.addItem(c.name, i);                    
                }                
            });
        });

        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){            
            DBHelper.sharedInstance().addObserverForEntity(avc, "Offer", null, null, null, function (objects) {
                comboBox.removeAllItems();                
                for (let i = 0; i < objects.length; i++){
                    let c = objects[i] as Offer;
                    comboBox.addItem(c.name, i);                    
                }
            });
        });

        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
            comboBox.removeAllItems();
            comboBox.addItem("Custom", PromotionalCodeType.Custom);
            comboBox.addItem("EAN 8", PromotionalCodeType.EAN8);
            comboBox.addItem("EAN 13", PromotionalCodeType.EAN13);

            // Set the last type code generator used.
            let value = MIOUserDefaults.standardUserDefaults().valueForKey("LastOfferTypeSelected");
            if (value == null) comboBox.selectItem(PromotionalCodeType.Custom);
            else comboBox.selectItem(value);
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle( MIOLocalizeString("GENERATE", "GENERATE"), MUIAlertActionStyle.Default, this, function(){
            let prefix:string = avc.textFields[0].text;
            let startIndex = parseInt(avc.textFields[1].text);
            let endIndex = parseInt(avc.textFields[2].text);
            let startDate =  this._partyEvent.beginDateTime; //ad.dateTimeFormatter.dateFromString(avc.textFields[3].text);
            let endDate = this._partyEvent.endDateTime; //ad.dateTimeFormatter.dateFromString(avc.textFields[4].text);
            
            let s = avc.comboBoxes[0].getSelectedItem();
            let source = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, s, "BookingSource") as BookingSource;

            let o = avc.comboBoxes[1].getSelectedItem();
            let offer = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, o, 'Offer') as Offer;

            let type = avc.comboBoxes[2].getSelectedItem();

            MIOUserDefaults.standardUserDefaults().setValueForKey("LastOfferTypeSelected", type);

            
            this.generatecodes (offer, source, parseInt(type), prefix, startIndex, endIndex, startDate, endDate);
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle( MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));
        
        this.presentViewController(avc, true);   
    }

    
    private generatecodes (offer:Offer, source:BookingSource, type:number, prefix:string, startIndex:number, endIndex:number) {
        let moc:MIOManagedObjectContext = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        
        for(let index = startIndex; index < (endIndex + 1); index++){
            let t = MIOEntityDescription.insertNewObjectForEntityForName("EventTicket", moc) as EventTicket;
            t.code = AppHelper.sharedInstance().generateCodeType(type, prefix, index);            
            t.offer = offer;
            t.source = source;
            t.beginDate = this._partyEvent.beginDateTime;
            t.endDate = this._partyEvent.endDateTime;
            t.name = "PREVENTA CALLE";
            t.event = this._partyEvent;
        }

        moc.save();

    }
 }