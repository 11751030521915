
/// <reference path="../notes/NoteDetailViewController.ts" />

class InternalReturnOrderDetailViewController extends NoteDetailViewController
{
    //variables
    // private allReceiptButton:MUIButton = null;
    private editHeaderView:InternalReturnOrderDetailHeaderView = null;
    private noEditHeaderView:InternalReturnOrderDetailHeaderView = null;    
    
    viewDidLoad(){
        super.viewDidLoad();

        // //receipt all button?????
        // this.allReceiptButton = MUIOutlet(this, "all-received-btn", "MUIButton");
        // this.allReceiptButton.setAction(this, function(){
        //     this.markAllAsReceivedAction();
        // });

        //iniciate both header views, one will be shown and the other hidden
        this.editHeaderView = MUIOutlet(this, "edit-header-view", "InternalReturnOrderDetailHeaderView");
        this.noEditHeaderView = MUIOutlet(this, "no-edit-header-view", "InternalReturnOrderDetailHeaderView");        

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();
    }

    protected updateUI(){
        
        if (this.stockNote.status == StockNoteStatus.None && this.stockNote.type == StockNoteType.InternalReturnOrder) {
            this.allowAddingLines = false;
        }
            
        // if (this.stockNote.status == StockNoteStatus.Pending && this.stockNote.type == StockNoteType.InternalOrderResponse) 
        //     this.allowAddingLines = true;
        //this.allReceiptButton.hidden = this.stockNote.status == StockNoteStatus.Pending ? false : true;
        this.editHeaderView.note = this.stockNote;
        this.noEditHeaderView.note = this.stockNote;
        this.editHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? true : false;
        this.noEditHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? false : true;                        

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    //DONT KNOW IF EDITING STYLE IS AVAILABLE IN THE NEW UITABLE CLASS
    // editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {   
    //     if (this.noteStatus == StockNoteStatus.Processed) return MUITableViewCellEditingStyle.None;
        
    //     if (indexPath.section == this.fetchedResultsController.sections.length) {
    //         return MUITableViewCellEditingStyle.Insert;
    //     }

    //     if (this.stockNote.status == StockNoteStatus.Pending && this.stockNote.type == StockNoteType.InternalOrderResponse){
    //         let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as StockNoteLine;
    //         if (item.estimatedQuantity != null && item.estimatedQuantity != 0){
    //             return MUITableViewCellEditingStyle.None;                
    //         }
    //     }
        
    //     return MUITableViewCellEditingStyle.Delete;
    // }


    set fetchedResultsController(value){
        this.setFetchedResultsController(value);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true)];

        //predicate for the item
        let predicateFormat = 'note.identifier == ' + this.stockNote.identifier;
        if (this.searchString != null) {
            predicateFormat += " AND productName CONTAINS '" + this.searchString + "'";
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("StockNoteLine", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["product.taxBuy", "product.tax", "product.stockInputFormats", "product.category", "product.supplierProducts"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }    

    private markAllAsReceivedAction(){
        let lines = this.fetchedResultsController.fetchedObjects;
        for (let index = 0; index < lines.length; index++){
            let l = lines[index] as StockNoteLine;
            l.quantity = l.estimatedQuantity;
        }
        
        DBHelper.saveMainContext();        
    }

}