
class StockCategorySelectionCell extends UITableViewCell
{
    private imageView:MUIImageView = null;
    private nameLabel:MUILabel = null;  
    private selectButton:MUIButton = null;

    noSelectionCell = false;
    delegate = null;

    awakeFromHTML(){
        this.imageView = MUIOutlet(this, "imgage-view", "MUIImageView");
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.selectButton = MUIOutlet(this, "select-btn", "MUIButton")
        this.selectButton.setAction(this, function(){
            if (this.delegate != null && (typeof this.delegate.stockCategoryDidSelect === "function")){
                this.delegate.stockCategoryDidSelect(this._stockCategory);
            }
        });

    }
    
    private _stockCategory:StockCategory = null;
    set item (item:StockCategory){
        if (item == null) return;

        this._stockCategory = item;
        this.nameLabel.text = item.name;
    }

    set title(title:string) {
        this.nameLabel.text = title;
    }
    
}