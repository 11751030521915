
/// <reference path="../stocknote/StockNoteCell.ts" />


class SupplierNoteCell extends StockNoteCell
{
    private referenceLabel:MUILabel = null;
    private creatorLabel:MUILabel = null;

    awakeFromHTML(): void {
        super.awakeFromHTML();
        
        this.referenceLabel = MUIOutlet (this, "ref-lbl", "MUILabel");
        this.creatorLabel = MUIOutlet (this, "creator-id-lbl", "MUILabel");
    }

    set item (item:StockNote){
        this.setItem(item);
        this.setTitle(item.originName);

        this.referenceLabel.text = item.externalReference;
        this.creatorLabel.text = item.creatorDocumentID;
        this.documentIDLabel.text = item.documentID;
    }
}