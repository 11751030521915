

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class OnlineOrder_ManagedObject

class OnlineOrder_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: clientAddress
    set clientAddress(value:string) {
        this.setValueForKey(value, 'clientAddress');
    }
    get clientAddress():string {
        return this.valueForKey('clientAddress');
    }
    set clientAddressPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientAddress');
    }
    get clientAddressPrimitiveValue():string {
        return this.primitiveValueForKey('clientAddress');
    }

    // Property: clientAddress2
    set clientAddress2(value:string) {
        this.setValueForKey(value, 'clientAddress2');
    }
    get clientAddress2():string {
        return this.valueForKey('clientAddress2');
    }
    set clientAddress2PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientAddress2');
    }
    get clientAddress2PrimitiveValue():string {
        return this.primitiveValueForKey('clientAddress2');
    }

    // Property: clientCity
    set clientCity(value:string) {
        this.setValueForKey(value, 'clientCity');
    }
    get clientCity():string {
        return this.valueForKey('clientCity');
    }
    set clientCityPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientCity');
    }
    get clientCityPrimitiveValue():string {
        return this.primitiveValueForKey('clientCity');
    }

    // Property: clientCountryCode
    set clientCountryCode(value:string) {
        this.setValueForKey(value, 'clientCountryCode');
    }
    get clientCountryCode():string {
        return this.valueForKey('clientCountryCode');
    }
    set clientCountryCodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientCountryCode');
    }
    get clientCountryCodePrimitiveValue():string {
        return this.primitiveValueForKey('clientCountryCode');
    }

    // Property: clientEmail
    set clientEmail(value:string) {
        this.setValueForKey(value, 'clientEmail');
    }
    get clientEmail():string {
        return this.valueForKey('clientEmail');
    }
    set clientEmailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientEmail');
    }
    get clientEmailPrimitiveValue():string {
        return this.primitiveValueForKey('clientEmail');
    }

    // Property: clientID
    set clientID(value:string) {
        this.setValueForKey(value, 'clientID');
    }
    get clientID():string {
        return this.valueForKey('clientID');
    }
    set clientIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientID');
    }
    get clientIDPrimitiveValue():string {
        return this.primitiveValueForKey('clientID');
    }

    // Property: clientName
    set clientName(value:string) {
        this.setValueForKey(value, 'clientName');
    }
    get clientName():string {
        return this.valueForKey('clientName');
    }
    set clientNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientName');
    }
    get clientNamePrimitiveValue():string {
        return this.primitiveValueForKey('clientName');
    }

    // Property: clientPhone
    set clientPhone(value:string) {
        this.setValueForKey(value, 'clientPhone');
    }
    get clientPhone():string {
        return this.valueForKey('clientPhone');
    }
    set clientPhonePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientPhone');
    }
    get clientPhonePrimitiveValue():string {
        return this.primitiveValueForKey('clientPhone');
    }

    // Property: clientPostalCode
    set clientPostalCode(value:string) {
        this.setValueForKey(value, 'clientPostalCode');
    }
    get clientPostalCode():string {
        return this.valueForKey('clientPostalCode');
    }
    set clientPostalCodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientPostalCode');
    }
    get clientPostalCodePrimitiveValue():string {
        return this.primitiveValueForKey('clientPostalCode');
    }

    // Property: clientState
    set clientState(value:string) {
        this.setValueForKey(value, 'clientState');
    }
    get clientState():string {
        return this.valueForKey('clientState');
    }
    set clientStatePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'clientState');
    }
    get clientStatePrimitiveValue():string {
        return this.primitiveValueForKey('clientState');
    }

    // Property: comments
    set comments(value:string) {
        this.setValueForKey(value, 'comments');
    }
    get comments():string {
        return this.valueForKey('comments');
    }
    set commentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'comments');
    }
    get commentsPrimitiveValue():string {
        return this.primitiveValueForKey('comments');
    }

    // Property: createdTPVOrderID
    set createdTPVOrderID(value:string) {
        this.setValueForKey(value, 'createdTPVOrderID');
    }
    get createdTPVOrderID():string {
        return this.valueForKey('createdTPVOrderID');
    }
    set createdTPVOrderIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'createdTPVOrderID');
    }
    get createdTPVOrderIDPrimitiveValue():string {
        return this.primitiveValueForKey('createdTPVOrderID');
    }

    // Property: dbID
    set dbID(value:string) {
        this.setValueForKey(value, 'dbID');
    }
    get dbID():string {
        return this.valueForKey('dbID');
    }
    set dbIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'dbID');
    }
    get dbIDPrimitiveValue():string {
        return this.primitiveValueForKey('dbID');
    }

    // Property: deliveryDate
    set deliveryDate(value:Date) {
        this.setValueForKey(value, 'deliveryDate');
    }
    get deliveryDate():Date {
        return this.valueForKey('deliveryDate');
    }
    set deliveryDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'deliveryDate');
    }
    get deliveryDatePrimitiveValue():Date {
        return this.primitiveValueForKey('deliveryDate');
    }

    // Property: deliveryFee
    set deliveryFee(value:number) {
        this.setValueForKey(value, 'deliveryFee');
    }
    get deliveryFee():number {
        return this.valueForKey('deliveryFee');
    }
    set deliveryFeePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'deliveryFee');
    }
    get deliveryFeePrimitiveValue():number {
        return this.primitiveValueForKey('deliveryFee');
    }

    // Property: externalOrderID
    set externalOrderID(value:string) {
        this.setValueForKey(value, 'externalOrderID');
    }
    get externalOrderID():string {
        return this.valueForKey('externalOrderID');
    }
    set externalOrderIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'externalOrderID');
    }
    get externalOrderIDPrimitiveValue():string {
        return this.primitiveValueForKey('externalOrderID');
    }

    // Property: externalPlatformID
    set externalPlatformID(value:string) {
        this.setValueForKey(value, 'externalPlatformID');
    }
    get externalPlatformID():string {
        return this.valueForKey('externalPlatformID');
    }
    set externalPlatformIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'externalPlatformID');
    }
    get externalPlatformIDPrimitiveValue():string {
        return this.primitiveValueForKey('externalPlatformID');
    }

    // Property: orderNumber
    set orderNumber(value:number) {
        this.setValueForKey(value, 'orderNumber');
    }
    get orderNumber():number {
        return this.valueForKey('orderNumber');
    }
    set orderNumberPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderNumber');
    }
    get orderNumberPrimitiveValue():number {
        return this.primitiveValueForKey('orderNumber');
    }

    // Property: pax
    set pax(value:number) {
        this.setValueForKey(value, 'pax');
    }
    get pax():number {
        return this.valueForKey('pax');
    }
    set paxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'pax');
    }
    get paxPrimitiveValue():number {
        return this.primitiveValueForKey('pax');
    }

    // Property: qrCode
    set qrCode(value:string) {
        this.setValueForKey(value, 'qrCode');
    }
    get qrCode():string {
        return this.valueForKey('qrCode');
    }
    set qrCodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'qrCode');
    }
    get qrCodePrimitiveValue():string {
        return this.primitiveValueForKey('qrCode');
    }

    // Property: routeDynamicPrice
    set routeDynamicPrice(value:boolean) {
        this.setValueForKey(value, 'routeDynamicPrice');
    }
    get routeDynamicPrice():boolean {
        return this.valueForKey('routeDynamicPrice');
    }
    set routeDynamicPricePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'routeDynamicPrice');
    }
    get routeDynamicPricePrimitiveValue():boolean {
        return this.primitiveValueForKey('routeDynamicPrice');
    }

    // Property: routeID
    set routeID(value:string) {
        this.setValueForKey(value, 'routeID');
    }
    get routeID():string {
        return this.valueForKey('routeID');
    }
    set routeIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'routeID');
    }
    get routeIDPrimitiveValue():string {
        return this.primitiveValueForKey('routeID');
    }

    // Property: routeName
    set routeName(value:string) {
        this.setValueForKey(value, 'routeName');
    }
    get routeName():string {
        return this.valueForKey('routeName');
    }
    set routeNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'routeName');
    }
    get routeNamePrimitiveValue():string {
        return this.primitiveValueForKey('routeName');
    }

    // Property: sessionDeliveryDay
    set sessionDeliveryDay(value:string) {
        this.setValueForKey(value, 'sessionDeliveryDay');
    }
    get sessionDeliveryDay():string {
        return this.valueForKey('sessionDeliveryDay');
    }
    set sessionDeliveryDayPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sessionDeliveryDay');
    }
    get sessionDeliveryDayPrimitiveValue():string {
        return this.primitiveValueForKey('sessionDeliveryDay');
    }

    // Property: sessionDeliveryIsNextDay
    set sessionDeliveryIsNextDay(value:boolean) {
        this.setValueForKey(value, 'sessionDeliveryIsNextDay');
    }
    get sessionDeliveryIsNextDay():boolean {
        return this.valueForKey('sessionDeliveryIsNextDay');
    }
    set sessionDeliveryIsNextDayPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'sessionDeliveryIsNextDay');
    }
    get sessionDeliveryIsNextDayPrimitiveValue():boolean {
        return this.primitiveValueForKey('sessionDeliveryIsNextDay');
    }

    // Property: sessionDeliveryTime
    set sessionDeliveryTime(value:string) {
        this.setValueForKey(value, 'sessionDeliveryTime');
    }
    get sessionDeliveryTime():string {
        return this.valueForKey('sessionDeliveryTime');
    }
    set sessionDeliveryTimePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sessionDeliveryTime');
    }
    get sessionDeliveryTimePrimitiveValue():string {
        return this.primitiveValueForKey('sessionDeliveryTime');
    }

    // Property: total
    set total(value:number) {
        this.setValueForKey(value, 'total');
    }
    get total():number {
        return this.valueForKey('total');
    }
    set totalPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'total');
    }
    get totalPrimitiveValue():number {
        return this.primitiveValueForKey('total');
    }

    // Property: transactionBeginDate
    set transactionBeginDate(value:Date) {
        this.setValueForKey(value, 'transactionBeginDate');
    }
    get transactionBeginDate():Date {
        return this.valueForKey('transactionBeginDate');
    }
    set transactionBeginDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'transactionBeginDate');
    }
    get transactionBeginDatePrimitiveValue():Date {
        return this.primitiveValueForKey('transactionBeginDate');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }
    // Relationship: businessArea
    set businessArea(value:BusinessArea) {
        this.setValueForKey(value, 'businessArea');
    }
    get businessArea():BusinessArea {
        return this.valueForKey('businessArea') as BusinessArea;
    }
    // Relationship: legalEntity
    set legalEntity(value:LegalEntity) {
        this.setValueForKey(value, 'legalEntity');
    }
    get legalEntity():LegalEntity {
        return this.valueForKey('legalEntity') as LegalEntity;
    }

    // Relationship: lines
    protected _lines:MIOManagedObjectSet = null;
    get lines():MIOManagedObjectSet {
        return this.valueForKey('lines');
    }
    addLinesObject(value:OnlineOrderLine) {
        this._addObjectForKey(value, 'lines');
    }
    removeLinesObject(value:OnlineOrderLine) {
        this._removeObjectForKey(value, 'lines');
    }
    // Relationship: paymentTransaction
    set paymentTransaction(value:OnlineOrderPaymentTransaction) {
        this.setValueForKey(value, 'paymentTransaction');
    }
    get paymentTransaction():OnlineOrderPaymentTransaction {
        return this.valueForKey('paymentTransaction') as OnlineOrderPaymentTransaction;
    }
    // Relationship: rate
    set rate(value:Rate) {
        this.setValueForKey(value, 'rate');
    }
    get rate():Rate {
        return this.valueForKey('rate') as Rate;
    }
}
