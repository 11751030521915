

// Generated class StockAnnotationView_ManagedObject

class StockAnnotationView_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: isAvailable
    set isAvailable(value:boolean) {
        this.setValueForKey(value, 'isAvailable');
    }
    get isAvailable():boolean {
        return this.valueForKey('isAvailable');
    }
    set isAvailablePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isAvailable');
    }
    get isAvailablePrimitiveValue():boolean {
        return this.primitiveValueForKey('isAvailable');
    }

    // Property: productMin
    set productMin(value:number) {
        this.setValueForKey(value, 'productMin');
    }
    get productMin():number {
        return this.valueForKey('productMin');
    }
    set productMinPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productMin');
    }
    get productMinPrimitiveValue():number {
        return this.primitiveValueForKey('productMin');
    }

    // Property: productMax
    set productMax(value:number) {
        this.setValueForKey(value, 'productMax');
    }
    get productMax():number {
        return this.valueForKey('productMax');
    }
    set productMaxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productMax');
    }
    get productMaxPrimitiveValue():number {
        return this.primitiveValueForKey('productMax');
    }

    // Property: orderedQuantity
    set orderedQuantity(value:number) {
        this.setValueForKey(value, 'orderedQuantity');
    }
    get orderedQuantity():number {
        return this.valueForKey('orderedQuantity');
    }
    set orderedQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderedQuantity');
    }
    get orderedQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('orderedQuantity');
    }

    // Property: productQuantity
    set productQuantity(value:number) {
        this.setValueForKey(value, 'productQuantity');
    }
    get productQuantity():number {
        return this.valueForKey('productQuantity');
    }
    set productQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productQuantity');
    }
    get productQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('productQuantity');
    }

    // Property: productMeasureType
    set productMeasureType(value:number) {
        this.setValueForKey(value, 'productMeasureType');
    }
    get productMeasureType():number {
        return this.valueForKey('productMeasureType');
    }
    set productMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productMeasureType');
    }
    get productMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('productMeasureType');
    }

    // Property: productContainerQuantity
    set productContainerQuantity(value:number) {
        this.setValueForKey(value, 'productContainerQuantity');
    }
    get productContainerQuantity():number {
        return this.valueForKey('productContainerQuantity');
    }
    set productContainerQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productContainerQuantity');
    }
    get productContainerQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('productContainerQuantity');
    }

    // Property: productContainerMeasureType
    set productContainerMeasureType(value:number) {
        this.setValueForKey(value, 'productContainerMeasureType');
    }
    get productContainerMeasureType():number {
        return this.valueForKey('productContainerMeasureType');
    }
    set productContainerMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productContainerMeasureType');
    }
    get productContainerMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('productContainerMeasureType');
    }

    // Property: productCostPrice
    set productCostPrice(value:number) {
        this.setValueForKey(value, 'productCostPrice');
    }
    get productCostPrice():number {
        return this.valueForKey('productCostPrice');
    }
    set productCostPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productCostPrice');
    }
    get productCostPricePrimitiveValue():number {
        return this.primitiveValueForKey('productCostPrice');
    }

    // Property: productAverageCost
    set productAverageCost(value:number) {
        this.setValueForKey(value, 'productAverageCost');
    }
    get productAverageCost():number {
        return this.valueForKey('productAverageCost');
    }
    set productAverageCostPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productAverageCost');
    }
    get productAverageCostPrimitiveValue():number {
        return this.primitiveValueForKey('productAverageCost');
    }

    // Property: productLastCost
    set productLastCost(value:number) {
        this.setValueForKey(value, 'productLastCost');
    }
    get productLastCost():number {
        return this.valueForKey('productLastCost');
    }
    set productLastCostPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productLastCost');
    }
    get productLastCostPrimitiveValue():number {
        return this.primitiveValueForKey('productLastCost');
    }

    // Property: createdAt
    set createdAt(value:Date) {
        this.setValueForKey(value, 'createdAt');
    }
    get createdAt():Date {
        return this.valueForKey('createdAt');
    }
    set createdAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'createdAt');
    }
    get createdAtPrimitiveValue():Date {
        return this.primitiveValueForKey('createdAt');
    }

    // Property: updatedAt
    set updatedAt(value:Date) {
        this.setValueForKey(value, 'updatedAt');
    }
    get updatedAt():Date {
        return this.valueForKey('updatedAt');
    }
    set updatedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'updatedAt');
    }
    get updatedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('updatedAt');
    }

    // Property: deletedAt
    set deletedAt(value:Date) {
        this.setValueForKey(value, 'deletedAt');
    }
    get deletedAt():Date {
        return this.valueForKey('deletedAt');
    }
    set deletedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'deletedAt');
    }
    get deletedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('deletedAt');
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
    // Relationship: warehouse
    set warehouse(value:Warehouse) {
        this.setValueForKey(value, 'warehouse');
    }
    get warehouse():Warehouse {
        return this.valueForKey('warehouse') as Warehouse;
    }
}
