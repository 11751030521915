//
// Generated class SeparatedMenuLine
//

/// <reference path="SeparatedMenuLine_ManagedObject.ts" />

class SeparatedMenuLine extends SeparatedMenuLine_ManagedObject
{

}
