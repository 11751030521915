
class ClientBankInfoDataSource extends MIOObject
{
    private tableView:UITableView = null;
    
    initWithTableView(tv: UITableView) {
        this.tableView = tv;
    }

    private _client:Client = null;
    set client(client:Client) {
        this._client = client;
        this.tableView.reloadData();
    }

    numberOfSections(tableview:UITableView){
        return this.fetchedResultsController?.sections.length;
    }
    
    numberOfRowsInSection(tableview:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("BankInfoCell") as ClientBankInfoCell;
    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as LegalEntityBankAccountInfo;    
        cell.item = item;        
      
        return cell;
    }
    
    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        //let archivedDocument = this.fetchedResultsController.objectAtIndexPath(indexPath) as ArchivedDocument;

    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        if (this._client == null) return null;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
    
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('title', true)];
        
        let predicateFormat = "legalEntity.identifier == " + this._client.identifier;

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("LegalEntityBankAccountInfo", sortDescriptors, predicateFormat);
        // fetchRequest.relationshipKeyPathsForPrefetching = ["source", "recommendation"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }
}