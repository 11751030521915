//
// Generated class Changelog
//

/// <reference path="Changelog_ManagedObject.ts" />

class Changelog extends Changelog_ManagedObject
{

}
