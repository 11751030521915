

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class IntegratorMappingConfig_ManagedObject

class IntegratorMappingConfig_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Relationship: configs
    protected _configs:MIOManagedObjectSet = null;
    get configs():MIOManagedObjectSet {
        return this.valueForKey('configs');
    }
    addConfigsObject(value:IntegratorConfig) {
        this._addObjectForKey(value, 'configs');
    }
    removeConfigsObject(value:IntegratorConfig) {
        this._removeObjectForKey(value, 'configs');
    }

    // Relationship: mappings
    protected _mappings:MIOManagedObjectSet = null;
    get mappings():MIOManagedObjectSet {
        return this.valueForKey('mappings');
    }
    addMappingsObject(value:IntegratorMapping) {
        this._addObjectForKey(value, 'mappings');
    }
    removeMappingsObject(value:IntegratorMapping) {
        this._removeObjectForKey(value, 'mappings');
    }

    // Relationship: targetEntities
    protected _targetEntities:MIOManagedObjectSet = null;
    get targetEntities():MIOManagedObjectSet {
        return this.valueForKey('targetEntities');
    }
    addTargetEntitiesObject(value:IntegratorMappingTargetEntity) {
        this._addObjectForKey(value, 'targetEntities');
    }
    removeTargetEntitiesObject(value:IntegratorMappingTargetEntity) {
        this._removeObjectForKey(value, 'targetEntities');
    }
}
