

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class StockTemplateItem_ManagedObject

class StockTemplateItem_ManagedObject extends CoreEntityUpdatedBy
{
    // Relationship: template
    set template(value:StockTemplate) {
        this.setValueForKey(value, 'template');
    }
    get template():StockTemplate {
        return this.valueForKey('template') as StockTemplate;
    }
}
