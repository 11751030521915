//
// Generated class DeliveryNoteLine
//

/// <reference path="DeliveryNoteLine_ManagedObject.ts" />

class DeliveryNoteLine extends DeliveryNoteLine_ManagedObject
{

}
