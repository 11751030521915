

/// <reference path="FileURL.ts" />

// Generated class SupportFile_ManagedObject

class SupportFile_ManagedObject extends FileURL
{
    // Relationship: business
    set business(value:BusinessLicense) {
        this.setValueForKey(value, 'business');
    }
    get business():BusinessLicense {
        return this.valueForKey('business') as BusinessLicense;
    }
    // Relationship: session
    set session(value:SupportSession) {
        this.setValueForKey(value, 'session');
    }
    get session():SupportSession {
        return this.valueForKey('session') as SupportSession;
    }
}
