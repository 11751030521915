

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class WorkerHoliday_ManagedObject

class WorkerHoliday_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: fromDate
    set fromDate(value:Date) {
        this.setValueForKey(value, 'fromDate');
    }
    get fromDate():Date {
        return this.valueForKey('fromDate');
    }
    set fromDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'fromDate');
    }
    get fromDatePrimitiveValue():Date {
        return this.primitiveValueForKey('fromDate');
    }

    // Property: toDate
    set toDate(value:Date) {
        this.setValueForKey(value, 'toDate');
    }
    get toDate():Date {
        return this.valueForKey('toDate');
    }
    set toDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'toDate');
    }
    get toDatePrimitiveValue():Date {
        return this.primitiveValueForKey('toDate');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }
    // Relationship: worker
    set worker(value:Employee) {
        this.setValueForKey(value, 'worker');
    }
    get worker():Employee {
        return this.valueForKey('worker') as Employee;
    }
}
