class BookingDetailZoneView extends MUIView
{
    private addButton:MUIButton = null;
    private tableView:MUITableView = null;

    
    public delegate = null;
    
    private zonesCache = {};
    private BookingZonesViewController = null;

    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;

    awakeFromHTML(){
        this.addButton = MUIOutlet(this, 'add-zone-btn', 'MUIButton');
        this.addButton.setAction(this, function(){
            this.showAddZoneViewConteroller();
        });

        this.tableView = MUIOutlet(this, "zones-table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    private _booking:Booking = null;    
    set booking(value:Booking) {
        this._booking = value;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableView:MUITableView, indexPath: MIOIndexPath) {
        let cell = tableView.dequeueReusableCellWithIdentifier("ZoneCell") as BookingRelationZoneCell;
        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as RelationBookingZone;                        
        cell.zone = item;

        return cell;
    }

    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        // let cell = tableView.cellAtIndexPath(indexPath) as UserPermissionCell;
        // let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ManagerSection;
        
        // if (this._user.businessPermission.hasManagerPermision(item.bitMask)) {
        //     // Remove
        //     this._user.businessPermission.removeManagerPermission(item.bitMask);
        //     cell.accessoryType = MUITableViewCellAccessoryType.None;
        // }
        // else {
        //     this._user.businessPermission.addManagerPermission(item.bitMask);
        //     cell.accessoryType = MUITableViewCellAccessoryType.Checkmark;
        // }        
    }

    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as RelationBookingZone;
        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            this.removeZone(item);
        }
    }

    private _fetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let sd = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];
        let predicateFormat = "booking.identifier == " + this._booking.identifier;
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("RelationBookingZone", sd, predicateFormat);                    
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "moduleIndex");
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){        
        this.tableView.reloadData();
    }

    private showAddZoneViewConteroller(){                
        let vc = new BookingZonesViewController('booking-zones-view');
        vc.initWithResource('layout/bookings/bookings/BookingZonesView.html')
        vc.delegate = this;        
        vc.booking = this._booking;
        
        AppHelper.sharedInstance().presentViewController(vc, true);
    }

    private addZone(zone:BookingZone = null){
        if(zone != null){
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
            let zoneRelation:RelationBookingZone = MIOEntityDescription.insertNewObjectForEntityForName("RelationBookingZone", moc) as RelationBookingZone;
            
            zoneRelation.identifier = MIOUUID.UUID().UUIDString;
            zoneRelation.booking = this._booking;
            zoneRelation.zoneID = zone.identifier;
            zoneRelation.name = zone.name;
            zoneRelation.pax = this._booking.totalPax;
            // zoneRelation.price = zone.price || 0;                        

            // this._booking.addRelationZonesObject(zoneRelation);
            // this._booking.updateCalculatePrice();

            moc.save();            
        }
    }

    private removeZone(zone:RelationBookingZone){                
        let moc:MIOManagedObjectContext = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        // this._booking.removeRelationZonesObject(zone);
        moc.deleteObject(zone);
        // this._booking.updateCalculatePrice();        
        moc.save();        
    }

}
