//
// Generated class CustomInputNote
//

/// <reference path="CustomInputNote_ManagedObject.ts" />

class CustomInputNote extends CustomInputNote_ManagedObject
{

}
