

/// <reference path="FileURL.ts" />

// Generated class FileAttachment_ManagedObject

class FileAttachment_ManagedObject extends FileURL
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }
}
