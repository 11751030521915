

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class RenderTemplate_ManagedObject

class RenderTemplate_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: active
    set active(value:boolean) {
        this.setValueForKey(value, 'active');
    }
    get active():boolean {
        return this.valueForKey('active');
    }
    set activePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'active');
    }
    get activePrimitiveValue():boolean {
        return this.primitiveValueForKey('active');
    }

    // Property: key
    set key(value:string) {
        this.setValueForKey(value, 'key');
    }
    get key():string {
        return this.valueForKey('key');
    }
    set keyPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'key');
    }
    get keyPrimitiveValue():string {
        return this.primitiveValueForKey('key');
    }

    // Property: language
    set language(value:string) {
        this.setValueForKey(value, 'language');
    }
    get language():string {
        return this.valueForKey('language');
    }
    set languagePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'language');
    }
    get languagePrimitiveValue():string {
        return this.primitiveValueForKey('language');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: type
    set type(value:string) {
        this.setValueForKey(value, 'type');
    }
    get type():string {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():string {
        return this.primitiveValueForKey('type');
    }
    // Relationship: businessArea
    set businessArea(value:BusinessArea) {
        this.setValueForKey(value, 'businessArea');
    }
    get businessArea():BusinessArea {
        return this.valueForKey('businessArea') as BusinessArea;
    }
}
