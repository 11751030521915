//
// Generated class BuyOrderLine
//

/// <reference path="BuyOrderLine_ManagedObject.ts" />

class BuyOrderLine extends BuyOrderLine_ManagedObject
{

}
