

/// <reference path="CommunicationTemplate.ts" />

// Generated class WhatsappTemplate_ManagedObject

class WhatsappTemplate_ManagedObject extends CommunicationTemplate
{

    // Property: params
    set params(value:any) {
        this.setValueForKey(value, 'params');
    }
    get params():any {
        return this.valueForKey('params');
    }
    set paramsPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'params');
    }
    get paramsPrimitiveValue():any {
        return this.primitiveValueForKey('params');
    }

    // Property: templateName
    set templateName(value:string) {
        this.setValueForKey(value, 'templateName');
    }
    get templateName():string {
        return this.valueForKey('templateName');
    }
    set templateNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'templateName');
    }
    get templateNamePrimitiveValue():string {
        return this.primitiveValueForKey('templateName');
    }
}
