//
// Generated class WarehouseLocation
//

/// <reference path="WarehouseLocation_ManagedObject.ts" />

class WarehouseLocation extends WarehouseLocation_ManagedObject
{

}
