

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class PaymentEntity_ManagedObject

class PaymentEntity_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: config
    set config(value:any) {
        this.setValueForKey(value, 'config');
    }
    get config():any {
        return this.valueForKey('config');
    }
    set configPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'config');
    }
    get configPrimitiveValue():any {
        return this.primitiveValueForKey('config');
    }

    // Property: environment
    set environment(value:string) {
        this.setValueForKey(value, 'environment');
    }
    get environment():string {
        return this.valueForKey('environment');
    }
    set environmentPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'environment');
    }
    get environmentPrimitiveValue():string {
        return this.primitiveValueForKey('environment');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: scope
    set scope(value:number) {
        this.setValueForKey(value, 'scope');
    }
    get scope():number {
        return this.valueForKey('scope');
    }
    set scopePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'scope');
    }
    get scopePrimitiveValue():number {
        return this.primitiveValueForKey('scope');
    }

    // Relationship: devices
    protected _devices:MIOManagedObjectSet = null;
    get devices():MIOManagedObjectSet {
        return this.valueForKey('devices');
    }
    addDevicesObject(value:PaymentDevice) {
        this._addObjectForKey(value, 'devices');
    }
    removeDevicesObject(value:PaymentDevice) {
        this._removeObjectForKey(value, 'devices');
    }
}
