


class PlaceTagsDataSource extends MIOObject
{
    private tableView:UITableView = null;
    private tags = [
        {"key": "products", "name":"PRODUCTS"}, 
        {"key":"clients", "name": "CLIENTS"}, 
        {"key":"archived_documents", "name":"ARCHIVED DOCUMENTS"}, 
        {"key":"cashdesks", "name":"CASHDESKS"}, 
        {"key":"suppliers", "name":"SUPPLIERS"}, 
        {"key":"stock_notes", "name":"STOCK NOTES"}
    ];    

    initWithTableView(tableView:UITableView){
        this.tableView = tableView;
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        this.tableView.reloadData();
    }

    private place:Place = null;
    set item( place:Place ) {   
        this.place = place;
        this.fetchedResultsController = null;
        let a = this.fetchedResultsController.fetchedObjects;
        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView){
        return 1;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        return this.tags.count;
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {
        let cell = tableView.dequeueReusableCellWithIdentifier('PlaceTagCell') as PlaceTagCell;
        let item = this.tags[indexPath.row];
        cell.item = item;

        cell.config = this.configs[ item["name"] ];

        return cell;
    }

    protected _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("tags", true)];

        // if (ad.selectedIdentifierType == "place") predicateFormat += " AND placeID == '" + ad.selectedIdentifier + "'";
        // if(this.searchString != null) {
        //     predicateFormat += " AND documentID CONTAINS '" + this.searchString + "'";
		// }
		
		// if (this.filterPredicateFormat != null) {
        //     predicateFormat += " AND " + this.filterPredicateFormat;
        // }
        let pf = "targetSchema = " + this.place.identifier;
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("ReplicaConfig", sortDescriptors, null);
        //fetchRequest.fetchLimit = 100;
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController) {        
        this.tableView.reloadData();
    }

    private configs = {};
    private map_config( objects: ReplicaConfig[]) {
        for (let c of objects) {
            this.configs[c.tags] = c;
        }
    }

}