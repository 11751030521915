/**
 * Created by miguel on 23/2/17.
 */


class EventDetailInfoViewController extends MUIViewController
{
    private titleLabel:MUILabel = null;

    private tableView:MUITableView                = null;
    private saveButton:MUIButton               = null;
    private addButton:MUIButton                = null;
    private calendarButton:MUIButton           = null;
    private dateTextField:MUITextField                = null;
    private nameTextField:MUITextField            = null;
    private priceTextField:MUITextField            = null;
    private descriptionTextArea:MUITextArea      = null;    

    private codes                    = null;
    private _fetchedResultsController:MIOFetchedResultsController = null;
    private generateVC:CodeGenerateViewController               = null;

    private df:MIODateFormatter = MUIWebApplication.sharedInstance().delegate.dateFormatter;
    private cf:MIONumberFormatter = MUIWebApplication.sharedInstance().delegate.currencyFormatter;

    viewDidLoad(){
        super.viewDidLoad();

        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
        
/*        this.priceTextField = MUIOutlet(this, 'price_tf','MUITextField');
        this.priceTextField.formatter = this.cf;
        this.priceTextField.setOnChangeText(this, function(textfield, value){
            this._event.price = this.cf.numberFromString(value)
            
        });

        this.descriptionTextArea = MUIOutlet(this, 'description_area','MUITextArea');
        this.descriptionTextArea.setOnChangeText(this, function(textfield, value){
            this._event.description = value;
            
        });

        this.nameTextField = MUIOutlet(this, 'event_name_lbl','MUITextField');
        this.nameTextField.setOnChangeText(this, function(textfield, value){
            this._event.name = value;
            
        });

        this.calendarButton = MUIOutlet(this, 'event_date_btn','MUIButton');
        this.calendarButton.setAction(this, function(){
           var dp = new MUIDatePickerController();
            dp.init();        
            dp.delegate = this;
            dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

            var pc = dp.popoverPresentationController;
            pc.sourceRect = this.calendarButton.frame;
            pc.sourceView = this.calendarButton;

            this.presentViewController(dp, true);
        });

        this.dateTextField = MUIOutlet(this, 'date_tf','MUITextField');
        this.dateTextField.setOnDidEditing(this, function(textfield, value)
        {
            let date = this.df.dateFromString(value);
            if(!isDate(date)){
                AppHelper.showErrorMessage(this, 'Error','Fecha incorrecta.');
                this.dateTextField.becombeFirstResponder();
                return;
            }
            this._event.date = date;
            
        });

        /*
        this._addButton = MUIOutlet(this, 'ecdinfov_nb_add_btn','MUIButton');
        this._addButton.setAction(this, function()
        {
            if(!this._generateVC) {
                this._generateVC = new CodeGenerateViewController('code_generate_view');
                this._generateVC.initWithResource('layout/modals/codegenerate/CodeGenerateView.html');
                this._generateVC.modalPresentationStyle = MUIModalPresentationStyle.Popover;
                
                var generateVC = this._generateVC.popoverPresentationController;
                generateVC.sourceRect = this._addButton.frame;
                generateVC.sourceView = this._addButton;
            }
            this._generateVC.type = CodeGenerateViewControllerType.BookingPassCode;
            this._generateVC.item = this._event;
            this._generateVC.delegate = this;
            if(!this._generateVC.isPresented)
                this.presentViewController(this._generateVC, true);
        });
*/
     /*   this.tableView = MUIOutlet(this, 'tableview', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        this.updateUI();
*/
        /*MIONotificationCenter.defaultCenter().addObserver(this, 'bkTicketsDidGenerated', function (notification) {
            this.downloadBkTickets();
        });*/
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }
    
    private _event:BookingEvent = null;
    set event(value:BookingEvent){
        this._event = value;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._event == null) return;
    
        this.titleLabel.text = this._event.name;
        //this.dateTextField.text = this.df.stringFromDate(this._event.date);
        //this.nameTextField.text = this._event.name;
        //this.priceTextField.text = this._event.price;
        //this.descriptionTextArea.text = this._event.description;

        // this.downloadBkTickets();
    }

   /* private downloadBkTickets()
    {
        let ad = MUIWebApplication.sharedInstance().delegate;
        let ws = ad.webService;

        ws.getBkTicketsByEvent(ad.selectedPlace, this._event, this, function(code, json){
            if(code == 200) {
                this.fetchedResultsController = null;
                this.tableView.reloadData();
            }
        });
    }
    */
    public itemDidSelected(item){
        
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('CHOOSE THE ACTION',"Choose the action"), MIOLocalizeString("CHOOSE AN ACTION","Choose an action "), MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('EXPORT',"Export"), MUIAlertActionStyle.Default, this, function(){}));
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL',"Cancel"), MUIAlertActionStyle.Default, this, function(){}));

        this.presentViewController(avc, true);
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section)
    {
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
   
    cellAtIndexPath(tableview,indexPath:MIOIndexPath){
        let cell:EventDetailCell = tableview.dequeueReusableCellWithIdentifier('EventDetailCell');
    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        
        cell.delegate = this;

        cell.item = item;
    
        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
        cell.selectionStyle = MUITableViewCellSelectionStyle.None;
        
        return cell;
    }
   
   didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
       let obj = this.fetchedResultsController.objectAtIndexPath(indexPath);   
   }
   
   set fetchedResultsController(value){
       if (value == null && this._fetchedResultsController != null)
           this._fetchedResultsController.delegate = null;
   
       this._fetchedResultsController = value;
   }
   
   get fetchedResultsController()
   {
       if (this._fetchedResultsController != null)
           return this._fetchedResultsController;
   

        var ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('beginDate', true),
        MIOSortDescriptor.sortDescriptorWithKey('endDate', true)];
    

        var fetchRequest = DBHelper.listFetchRequestWithEntityName('BkTicket', sortDescriptors, 'event.identifier == ' + this._event.identifier);
        var fetchedResultsController = new MIOFetchedResultsController();
        
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "date");
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
   
        this._fetchedResultsController = fetchedResultsController;
   
       return this._fetchedResultsController;
   }
   
   controllerDidChangeContent(controller){
       this.tableView.reloadData();
   }

   public itemsDidGenerated(){
        //MIONotificationCenter.defaultCenter().postNotification('bkTicketsDidGenerated', null);
        //this.downloadBkTickets();
    }

    didSelectDate(datePickerController, date)
    {   
        this._event.date = date;
        this.dateTextField.text =this.df.stringFromDate(date);
        
    }
}