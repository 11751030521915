//
// Generated class PhoneNumber
//

/// <reference path="PhoneNumber_ManagedObject.ts" />

class PhoneNumber extends PhoneNumber_ManagedObject
{
    static newPhoneNumber(context?:MIOManagedObjectContext) : PhoneNumber {
        
        const ctx = context == null ? (MUIWebApplication.sharedInstance().delegate as AppDelegate).managedObjectContext : context;

        let pn = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", ctx) as PhoneNumber;
        pn.identifier = MIOUUID.UUID().UUIDString;

        return pn;
    }
}
