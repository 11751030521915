//
// Generated class CashDesk
//

/// <reference path="CashDesk_ManagedObject.ts" />

class CashDesk extends CashDesk_ManagedObject
{

}
