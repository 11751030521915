enum BookingZoneDayRuleType {
    ByPax,
    ByTable
}

class BookingZoneDayLocationTypeCell extends MUITableViewCell
{
    private titleTextField:MUITextField = null;
    private currentLabel:MUILabel = null;
    private limitTextField:MUITextField = null;
    private minPAXTextField:MUITextField = null;
    private maxPAXTextField:MUITextField = null;
    private priceTypeComboBox:MUIComboBox = null;
    private priceTextField:MUITextField = null;

    private addPriceRuleButton:MUIButton = null;

    awakeFromHTML(){
        this.titleTextField = MUIOutlet(this, "title-tf", "MUITextField");
        this.titleTextField.setOnChangeText(this, function(control, value){
            this.locationType.title = value;
        });

        this.minPAXTextField = MUIOutlet(this, "min-pax-tf", "MUITextField");
        this.minPAXTextField.setOnChangeText(this, function(control, value){
            this.locationType.minpax = value;
        });

        this.maxPAXTextField = MUIOutlet(this, "max-pax-tf", "MUITextField");
        this.maxPAXTextField.setOnChangeText(this, function(control, value){
            this.locationType.maxpax = value;
        });

        this.priceTypeComboBox = MUIOutlet(this, "price-type-cb", "MUIComboBox");
        this.priceTypeComboBox.setOnChangeAction(this, function(control, value){
            this.locationType.priceType = value;
        });

        this.priceTextField = MUIOutlet(this, "price-tf", "MUITextField");
        this.priceTextField.setOnChangeText(this, function(control, value){
            this.locationType.price = value;
        });

        this.currentLabel = MUIOutlet(this, "current-pax-lbl", "MUILabel");

        this.limitTextField = MUIOutlet(this, "pax-limit-tf", "MUITextField");
        this.limitTextField.setOnChangeText(this, function(control, value){
            this.locationType.typelimit = value;
        });

        this.addPriceRuleButton = MUIOutlet(this, "add-rule-btn", "MUIButton");
        this.addPriceRuleButton.setAction(this, function(){
            this.addNewPriceRule();
        });

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    private locationType:BookingLocationType = null;
    set item(value:BookingLocationType){
        this.locationType = value;
        
        this.titleTextField.text = this.locationType.title;
        this.minPAXTextField.text = value.minpax;
        this.maxPAXTextField.text = value.maxpax;
        this.priceTypeComboBox.selectItem(value.priceType);
        this.priceTextField.text = value.price;
        // this.currentLabel.text = value.current;
        this.limitTextField.text = value.typelimit;
    }

    private addNewPriceRule(){

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate; 
        let rule = MIOEntityDescription.insertNewObjectForEntityForName("BookingLocationPriceRule", ad.managedObjectContext) as BookingLocationPriceRule;
        
        rule.locationType = this.locationType;
        this.locationType.addPriceRulesObject(rule);
        
        ad.managedObjectContext.save();
    }
}