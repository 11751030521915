



class ReportResultStockValCell extends MUITableViewCell
{
    nameLabel     = null;
    quantityLabel = null;
    baseLabel    = null;
    taxLabel   = null
   
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;


    awakeFromHTML()
    {
        this.nameLabel     = MUIOutlet(this, 'rrv_c7_name_lbl', 'MUILabel');
        this.quantityLabel = MUIOutlet(this, 'rrv_c7_label3_lbl', 'MUILabel');
        this.baseLabel     = MUIOutlet(this, 'rrv_c7_label2_lbl', 'MUILabel');
        this.taxLabel      = MUIOutlet(this, 'rrv_c7_label1_lbl','MUILabel');
    }

    set item(i)
    {
        this.nameLabel.text     = i[Object.keys(i)[0]];
        this.quantityLabel.text = (i.price ? this.nf.stringFromNumber(parseFloat(i.price)) :'0'); 
        this.baseLabel.text    = (i.quantity ? this.cf.stringFromNumber(parseFloat(i.quantity)):''); 
        this.taxLabel.text  = i.mu
    }
}
