//
// Generated class Rate
//

/// <reference path="Rate_ManagedObject.ts" />

enum RoundType {
    RoundTypeNone           = 0,
    RoundTypeNormal         = 1,
    RoundTypeUp             = 2,
    RoundTypeDown           = 3
}

class Rate extends Rate_ManagedObject
{
    
}
