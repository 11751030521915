
class MenuRatesDetailCell extends UITableViewCell
{
    private imageView:MUIImageView = null;
    private nameLabel:MUILabel = null;
    private priceLabel:MUILabel = null;
    private taxButton:MUIButton = null;
    private specificRateTextfield:MUITextField = null;

    private timer:MIOTimer = null;

    private ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;
    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter as MIONumberFormatter;

    awakeFromHTML()
    {
        this.imageView = MUIOutlet(this, "product-img", "MUIImageView");
        this.nameLabel = MUIOutlet(this, "rate-name-lbl", "MUILabel");
        this.priceLabel = MUIOutlet(this, "base-price-lbl", "MUILabel");

        this.taxButton = MUIOutlet(this, 'tax-btn', 'MUIButton');
        this.taxButton.setAction(this, function (){            
            this.showSelectTaxViewController(this.taxButton);
        });

        this.specificRateTextfield = MUIOutlet(this, "specific-rate-tf", "MUITextField");
        this.specificRateTextfield.formatter = this.cf;
        this.specificRateTextfield.setOnChangeText(this, function(control, value){
            if (this.timer != null) this.timer.invalidate();
            this.timer = MIOTimer.scheduledTimerWithTimeInterval(600, false, this, function(timer:MIOTimer){
                this.timer = null;

                value = value.length > 0 ? value : null;
                this.updateSpecificRate(value);
            });
        });
    }

    private _rate:Rate = null;
    set rate(r:Rate){
        this._rate= r;
    }

    private _menu:Menu = null;
    set menu(i:Menu){
        this._menu = i;
        this.imageView.setImage(i.image?.url);
        this.nameLabel.text = i.name;
        this.priceLabel.text = this.cf.stringFromNumber(i.price);
        if(i.menuRates?.allObjects.length > 0) this.loadMenuRate(i);
    }

    private _menuRate:MenuRate = null;
    set menuRate(i:MenuRate){
        this._menuRate = i;
        if(i.price) this.specificRateTextfield.text = this.cf.stringFromNumber(i.price);
        if(i.tax) this.taxButton.title = i.tax.name; 
    }

    private showSelectTaxViewController(button:MUIButton) {
        let vc = AppHelper.sharedInstance().showCustomSelectViewControllerFromView(button, 'Tax', 'name', null, this, function(controller, object:Tax){
            //returns object, test value then update Tax

            button.title = (object) ? object.valueForKey('name') : MIOLocalizeString("TAX RATE","TAX RATE");
            this.updateTax(object);

        }, true );        
    }

    private loadMenuRate(item:Menu) {
        if (this._menuRate != null) return;

        //compares all related RATES of object in current cell to the currently selected RATE
        for (let i = 0; i < item.menuRates.allObjects.length; i++) {
            if (item.menuRates.allObjects[i].rate.identifier == this._rate?.identifier){
                this.menuRate = item.menuRates.allObjects[i];
                return; //when you find a match return, there should ONLY ever be one match
            }
        }
    }

    private updateSpecificRate(value:string){
        if (this._menuRate){
            this._menuRate.price = this.cf.numberFromString(value);
            this.testMenuRateForDelete();
        } else {
            if (value){
                this._menuRate = MIOEntityDescription.insertNewObjectForEntityForName("MenuRate", this.ad.managedObjectContext) as MenuRate;
                this._menuRate.identifier = new MIOUUID().UUIDString;
                this._menuRate.menu = this._menu;
                this._menuRate.rate = this._rate;
                this._menuRate.price = this.cf.numberFromString(value);
                this.ad.managedObjectContext.save();
            }
        }
    }

    private updateTax(value:Tax) {
        if (this._menuRate){
            this._menuRate.tax = value;
            this.testMenuRateForDelete();
        } else {
            if(value){
                this._menuRate = MIOEntityDescription.insertNewObjectForEntityForName("MenuRate", this.ad.managedObjectContext) as MenuRate;
                this._menuRate.identifier = new MIOUUID().UUIDString;
                this._menuRate.menu = this._menu;
                this._menuRate.rate = this._rate;
                this._menuRate.tax = value;
                this.ad.managedObjectContext.save();
            }
        }
    }

    private testMenuRateForDelete(){
        if(!this._menuRate?.price && !this._menuRate?.tax){
            DBHelper.deleteObjectFromMainContext(this._menuRate, true);
            this._menuRate = null;
        }
    }

}