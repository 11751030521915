class DBStockInputFormatsCoder extends DBXLSXCoder
{
    protected mainEntity() {
        return { entity: "StockInputFormat", predicateFormat: "deletedAt == null", relations:["product"] };
    }

    protected columns() : any[] {    
        let cols = [            
            { title: "STOCK INPUT FORMAT ID", width: "10%", align: "center" },
            { title: "STOCK INPUT FORMAT NAME", width: "10%", align: "center" },
            { title: "PRODUCT ID", width: "10%", align: "center" },
            { title: "PRODUCT NAME", width: "10%", align: "center" },
            { title: "QUANTITY", width: "10%", align: "center", formatter: this.ad.numberFormatter },
            { title: "BARCODE", width: "10%", align: "center" }
        ]
        return cols;
    }

    protected aditionalImportEntities(){
        return [
            { entity: "Product", predicateFormat: "deletedAt == null" }
        ]
    }

    protected importRow(row:any){

        let stockInputFormatID  = row["STOCK INPUT FORMAT ID"];
        let stockInputFormatName  = row["STOCK INPUT FORMAT NAME"];
        let productID           = row["PRODUCT ID"];
        let productName         = row["PRODUCT NAME"];
        let quantity            = row["QUANTITY"];
        let barcode             = row["BARCODE"];
        
        let product = this.queryEntityByIDOrName("Product", productID, productName);
        if (product == null) {
            return;
        }        

        let sif = this.queryEntityByField("StockInputFormat", "identifier", stockInputFormatID) as StockInputFormat;
        if (sif == null) sif = this.queryEntity("StockInputFormat", "name = '" + productName + "' and product.identifier = " + product.identifier);
        if (sif == null) {
            sif = MIOEntityDescription.insertNewObjectForEntityForName("StockInputFormat", DBHelper.mainManagedObjectContext) as StockInputFormat;
            sif.identifier = this.parseOrCreateIdentifier(stockInputFormatID);            
            this.appendObject(sif);
        }            

        sif.name = stockInputFormatName;
        sif.product = product;
        sif.quantity = quantity;
        sif.barcode = barcode;
        
        MIOLog("ADDING STOCK INPUT FORMAT: " + this.rowIndex + "/" + this.rows.length + ": " + stockInputFormatName);
    }

    // 
    // Export
    // 
    protected exportTitle(): string { return "stock_input_formats"; }

    protected exportSortDescriptors() {
        return [            
            MIOSortDescriptor.sortDescriptorWithKey("product.name", true)
        ]
    }

    protected parseObject(object:MIOManagedObject): any {
        let inputFormat = object as StockInputFormat;

        let item = {
            "STOCK INPUT FORMAT ID": inputFormat.identifier,
            "STOCK INPUT FORMAT NAME": inputFormat.name,
            "PRODUCT ID": inputFormat.product.identifier,
            "PRODUCT NAME": inputFormat.product.name,
            "QUANTITY": inputFormat.quantity,
            "BARCODE": inputFormat.barcode
        }

        return item;
    }
}