//
// Generated class RoomZone
//

/// <reference path="RoomZone_ManagedObject.ts" />

class RoomZone extends RoomZone_ManagedObject
{

}
