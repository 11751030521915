/// <reference path="WebService.ts" />

interface WebService
{
    adyenSignUp( completion:any) : void ;
    adyenHOPLink( completion:any) : void ;
}

WebService.prototype.adyenSignUp = function( completion:any ) : void
{
    const url_str = this.paymentURL + "/adyen/schema/" + this.scheme + "/sign-up";
    let r = new DLRequest ( url_str );
    r.execute( completion );
}

WebService.prototype.adyenHOPLink = function( completion:any ) : void
{
    const url_str = this.paymentURL + "/adyen/schema/" + this.scheme + "/generate-on-board-link";
    let r = new DLRequest ( url_str );
    r.execute( completion );
}



