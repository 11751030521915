
class PartyCashDeskCell extends MUITableViewCell
{
    private titleLabel:MUILabel = null;

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    set item(item:CashDesk){
        this.titleLabel.text = item.name;
    }
}