

// Generated class LowOrderLog_ManagedObject

class LowOrderLog_ManagedObject extends MIOManagedObject
{

    // Property: appID
    set appID(value:string) {
        this.setValueForKey(value, 'appID');
    }
    get appID():string {
        return this.valueForKey('appID');
    }
    set appIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'appID');
    }
    get appIDPrimitiveValue():string {
        return this.primitiveValueForKey('appID');
    }

    // Property: createNewLowOrder
    set createNewLowOrder(value:boolean) {
        this.setValueForKey(value, 'createNewLowOrder');
    }
    get createNewLowOrder():boolean {
        return this.valueForKey('createNewLowOrder');
    }
    set createNewLowOrderPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'createNewLowOrder');
    }
    get createNewLowOrderPrimitiveValue():boolean {
        return this.primitiveValueForKey('createNewLowOrder');
    }

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: lowOrderId
    set lowOrderId(value:string) {
        this.setValueForKey(value, 'lowOrderId');
    }
    get lowOrderId():string {
        return this.valueForKey('lowOrderId');
    }
    set lowOrderIdPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'lowOrderId');
    }
    get lowOrderIdPrimitiveValue():string {
        return this.primitiveValueForKey('lowOrderId');
    }

    // Property: lowOrderLineid
    set lowOrderLineid(value:string) {
        this.setValueForKey(value, 'lowOrderLineid');
    }
    get lowOrderLineid():string {
        return this.valueForKey('lowOrderLineid');
    }
    set lowOrderLineidPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'lowOrderLineid');
    }
    get lowOrderLineidPrimitiveValue():string {
        return this.primitiveValueForKey('lowOrderLineid');
    }

    // Property: lowOrderPrQty
    set lowOrderPrQty(value:number) {
        this.setValueForKey(value, 'lowOrderPrQty');
    }
    get lowOrderPrQty():number {
        return this.valueForKey('lowOrderPrQty');
    }
    set lowOrderPrQtyPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lowOrderPrQty');
    }
    get lowOrderPrQtyPrimitiveValue():number {
        return this.primitiveValueForKey('lowOrderPrQty');
    }

    // Property: lowOrderQty
    set lowOrderQty(value:number) {
        this.setValueForKey(value, 'lowOrderQty');
    }
    get lowOrderQty():number {
        return this.valueForKey('lowOrderQty');
    }
    set lowOrderQtyPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lowOrderQty');
    }
    get lowOrderQtyPrimitiveValue():number {
        return this.primitiveValueForKey('lowOrderQty');
    }

    // Property: placeID
    set placeID(value:string) {
        this.setValueForKey(value, 'placeID');
    }
    get placeID():string {
        return this.valueForKey('placeID');
    }
    set placeIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'placeID');
    }
    get placeIDPrimitiveValue():string {
        return this.primitiveValueForKey('placeID');
    }

    // Property: productId
    set productId(value:string) {
        this.setValueForKey(value, 'productId');
    }
    get productId():string {
        return this.valueForKey('productId');
    }
    set productIdPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productId');
    }
    get productIdPrimitiveValue():string {
        return this.primitiveValueForKey('productId');
    }

    // Property: productMax
    set productMax(value:number) {
        this.setValueForKey(value, 'productMax');
    }
    get productMax():number {
        return this.valueForKey('productMax');
    }
    set productMaxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productMax');
    }
    get productMaxPrimitiveValue():number {
        return this.primitiveValueForKey('productMax');
    }

    // Property: productMin
    set productMin(value:number) {
        this.setValueForKey(value, 'productMin');
    }
    get productMin():number {
        return this.valueForKey('productMin');
    }
    set productMinPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productMin');
    }
    get productMinPrimitiveValue():number {
        return this.primitiveValueForKey('productMin');
    }

    // Property: productOrderedqty
    set productOrderedqty(value:number) {
        this.setValueForKey(value, 'productOrderedqty');
    }
    get productOrderedqty():number {
        return this.valueForKey('productOrderedqty');
    }
    set productOrderedqtyPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productOrderedqty');
    }
    get productOrderedqtyPrimitiveValue():number {
        return this.primitiveValueForKey('productOrderedqty');
    }

    // Property: stockannotationCreatedAt
    set stockannotationCreatedAt(value:Date) {
        this.setValueForKey(value, 'stockannotationCreatedAt');
    }
    get stockannotationCreatedAt():Date {
        return this.valueForKey('stockannotationCreatedAt');
    }
    set stockannotationCreatedAtPrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'stockannotationCreatedAt');
    }
    get stockannotationCreatedAtPrimitiveValue():Date {
        return this.primitiveValueForKey('stockannotationCreatedAt');
    }

    // Property: stockannotationId
    set stockannotationId(value:string) {
        this.setValueForKey(value, 'stockannotationId');
    }
    get stockannotationId():string {
        return this.valueForKey('stockannotationId');
    }
    set stockannotationIdPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'stockannotationId');
    }
    get stockannotationIdPrimitiveValue():string {
        return this.primitiveValueForKey('stockannotationId');
    }

    // Property: stockMinqty
    set stockMinqty(value:number) {
        this.setValueForKey(value, 'stockMinqty');
    }
    get stockMinqty():number {
        return this.valueForKey('stockMinqty');
    }
    set stockMinqtyPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'stockMinqty');
    }
    get stockMinqtyPrimitiveValue():number {
        return this.primitiveValueForKey('stockMinqty');
    }

    // Property: stockProductqty
    set stockProductqty(value:number) {
        this.setValueForKey(value, 'stockProductqty');
    }
    get stockProductqty():number {
        return this.valueForKey('stockProductqty');
    }
    set stockProductqtyPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'stockProductqty');
    }
    get stockProductqtyPrimitiveValue():number {
        return this.primitiveValueForKey('stockProductqty');
    }

    // Property: stockQtyBefore
    set stockQtyBefore(value:number) {
        this.setValueForKey(value, 'stockQtyBefore');
    }
    get stockQtyBefore():number {
        return this.valueForKey('stockQtyBefore');
    }
    set stockQtyBeforePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'stockQtyBefore');
    }
    get stockQtyBeforePrimitiveValue():number {
        return this.primitiveValueForKey('stockQtyBefore');
    }

    // Property: triggerType
    set triggerType(value:string) {
        this.setValueForKey(value, 'triggerType');
    }
    get triggerType():string {
        return this.valueForKey('triggerType');
    }
    set triggerTypePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'triggerType');
    }
    get triggerTypePrimitiveValue():string {
        return this.primitiveValueForKey('triggerType');
    }

    // Property: updatedByAppID
    set updatedByAppID(value:string) {
        this.setValueForKey(value, 'updatedByAppID');
    }
    get updatedByAppID():string {
        return this.valueForKey('updatedByAppID');
    }
    set updatedByAppIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'updatedByAppID');
    }
    get updatedByAppIDPrimitiveValue():string {
        return this.primitiveValueForKey('updatedByAppID');
    }
}
