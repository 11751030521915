

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class CronJob_ManagedObject

class CronJob_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: endpoint
    set endpoint(value:string) {
        this.setValueForKey(value, 'endpoint');
    }
    get endpoint():string {
        return this.valueForKey('endpoint');
    }
    set endpointPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'endpoint');
    }
    get endpointPrimitiveValue():string {
        return this.primitiveValueForKey('endpoint');
    }

    // Property: error
    set error(value:string) {
        this.setValueForKey(value, 'error');
    }
    get error():string {
        return this.valueForKey('error');
    }
    set errorPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'error');
    }
    get errorPrimitiveValue():string {
        return this.primitiveValueForKey('error');
    }

    // Property: params
    set params(value:any) {
        this.setValueForKey(value, 'params');
    }
    get params():any {
        return this.valueForKey('params');
    }
    set paramsPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'params');
    }
    get paramsPrimitiveValue():any {
        return this.primitiveValueForKey('params');
    }

    // Property: serverType
    set serverType(value:string) {
        this.setValueForKey(value, 'serverType');
    }
    get serverType():string {
        return this.valueForKey('serverType');
    }
    set serverTypePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'serverType');
    }
    get serverTypePrimitiveValue():string {
        return this.primitiveValueForKey('serverType');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: tries
    set tries(value:number) {
        this.setValueForKey(value, 'tries');
    }
    get tries():number {
        return this.valueForKey('tries');
    }
    set triesPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'tries');
    }
    get triesPrimitiveValue():number {
        return this.primitiveValueForKey('tries');
    }
}
