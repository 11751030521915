/**
 * Created by miguel on 22/2/17.
 */



class ArchivedDocumentCell extends UITableViewCell
{
    private numLabel:MUILabel = null;
    private nextDocumentLabel:MUILabel = null;
    private nameLabel:MUILabel = null;
    private priceLabel:MUILabel = null;

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;

    awakeFromHTML(){
        this.numLabel = MUIOutlet(this, "doc-id-lbl", "MUILabel");
        this.nextDocumentLabel = MUIOutlet(this, "next-doc-lbl", "MUILabel");
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.priceLabel = MUIOutlet(this, "total-lbl", "MUILabel");
        
        this.separatorStyle = UITableViewCellSeparatorStyle.None;
    }

    private _item:ArchivedDocument = null;
    set item(i:ArchivedDocument){
        this._item = i;

        if (i instanceof ArchivedTicket) {
            this.numLabel.text = i.legalDocumentID ? i.legalDocumentID : i.documentID;
            if (this.nameLabel) this.nameLabel.text = i.linkedLegalEntityName ? i.linkedLegalEntityName : MIOLocalizeString("NO CLIENT", "NO CLIENT");            
            if (this.nextDocumentLabel) this.nextDocumentLabel.text = i.nextDocument != null ? i.nextDocument.legalDocumentID : null;
        }
        else if (i instanceof ArchivedInvoice) {
            this.numLabel.text = i.legalDocumentID ? i.legalDocumentID : i.documentID;
            if (this.nameLabel) this.nameLabel.text = i.invoiceLegalEntityName;            
            if (this.nextDocumentLabel) this.nextDocumentLabel.text = i.nextDocument?.legalDocumentID;
        }
        
        let totalInvited = i.totalInvited ?? 0;
        let total = i.totalPrice ?? 0;
        
        this.priceLabel.text = total == 0 ? this.cf.stringFromNumber(totalInvited) : this.cf.stringFromNumber(total);
    }

}