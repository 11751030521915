
class ClientBookingsViewController extends MUIViewController
{
    private tableView:MUITableView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _client:Client = null;
    set client(client:Client){
        this._client = client;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._client == null) return;

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableview:MUITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview:MUITableView, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("BookingCell") as ClientBookingCell;
    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Booking;    
        cell.item = item;        
      
        return cell;
    }
    
    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        //let archivedDocument = this.fetchedResultsController.objectAtIndexPath(indexPath) as ArchivedDocument;

    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('date', true)];
        
        let predicateFormat = "client.identifier == " + this._client.identifier;

        let fetchRequest = DBHelper.listFetchRequestWithEntityName('Booking', sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["source", "recommendation"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }
}