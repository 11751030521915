//
// Generated class InventoryNote
//

/// <reference path="InventoryNote_ManagedObject.ts" />

class InventoryNote extends InventoryNote_ManagedObject
{

}
