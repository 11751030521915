class WarehouseOrderDetailHeaderView extends NoteDetailHeaderView
{
    private documentLabel:MUILabel = null;
    private referenceDocumentLabel:MUILabel = null;
    private originLabel:MUILabel = null;
    private destinationLabel:MUILabel = null;    

    private documentDateLabel:MUILabel = null;
    private documentDateTextField:MUITextField = null;
    private documentDateButton:MUIButton = null;

    private validationDateLabel:MUILabel = null;
    private validationDateTextField:MUITextField = null;
    private validationDateButton:MUIButton = null;

    private dtf = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter as MIODateFormatter;

    awakeFromHTML(){
        super.awakeFromHTML();
        
        this.documentLabel = MUIOutlet(this, "document-lbl", "MUILabel");
        this.originLabel = MUIOutlet(this, "origin-lbl", "MUILabel");
        this.destinationLabel = MUIOutlet(this, "destination-lbl", "MUILabel");                

        this.documentDateLabel = MUIOutlet(this, "document-date-lbl", "MUILabel");
        this.documentDateTextField = MUIOutlet(this, "document-date-tf", "MUITextField");
        this.setupDocumentDateTextField(this.documentDateTextField);
        this.documentDateButton = MUIOutlet(this, "document-date-btn", "MUIButton");
        this.setupCalendarButton(this.documentDateButton, NoteDetailHeaderViewDateType.DocumentDate);
        
        this.validationDateLabel = MUIOutlet(this, "stock-date-lbl", "MUILabel");
        this.validationDateTextField = MUIOutlet(this, "stock-date-tf", "MUITextField");
        this.setupValidationDateTextField(this.validationDateTextField);
        this.validationDateButton = MUIOutlet(this, "stock-date-btn", "MUIButton");
        this.setupCalendarButton(this.validationDateButton, NoteDetailHeaderViewDateType.ValidationDate);
    }

    set note (item:StockNote){
        this.stockNote = item;

        this.documentLabel.text = item.documentID;
        this.originLabel.text = item.originName;
        this.destinationLabel.text = item.destinationName;

        let dds = this.dtf.stringFromDate(item.documentDate);
        let ds = this.dtf.stringFromDate(item.stockDate);

        if (this.documentDateLabel != null) this.documentDateLabel.text = dds;
        if (this.validationDateLabel != null) this.validationDateLabel.text = ds;

        if (this.documentDateTextField != null) this.documentDateTextField.text = dds;
        if (this.validationDateTextField != null) this.validationDateTextField.text = ds;
    }

    protected dateDidChange(type:NoteDetailHeaderViewDateType, date:Date){
        switch(type){
            case NoteDetailHeaderViewDateType.DocumentDate:
            this.documentDateTextField.text = this.dtf.stringFromDate(date);
            break;

            case NoteDetailHeaderViewDateType.ValidationDate:
            this.validationDateTextField.text = this.dtf.stringFromDate(date);
            break;
        }
    }

}