class CommunicationTemplateViewController extends MUIViewController
{
    static newInstance() : CommunicationTemplateViewController {
        let vc = new CommunicationTemplateViewController("communication-template-view");
        vc.initWithResource("layout/communication_templates/CommunicationTemplateView.html");
        return vc;
    }

    private templateTypeComboBox:MUIComboBox = null;
    private addButton:MUIButton = null;
    private saveButton:MUIButton = null;
    private titleColumnLabel:MUILabel = null;
    private tableView:UITableView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.templateTypeComboBox = MUIOutlet(this, "template-type-combobox", "MUIComboBox");
        this.templateTypeComboBox.setOnChangeAction(this, this.didChangeTemplateTypeAction);

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, this.addAction);

        this.saveButton = AppHelper.setupSaveButton(this, "save-btn");

        this.titleColumnLabel = MUIOutlet(this, "title-column-lbl", "MUILabel");

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.tableView.reloadData();
    }

    private entity_name:string = "EmailTemplate";
    private didChangeTemplateTypeAction(){
        switch(this.templateTypeComboBox.getSelectedItem()){
            case "0": 
                this.entity_name = "EmailTemplate"; 
                this.titleColumnLabel.text = MIOLocalizeString("EMAIL_TEMPLATE", "EMAIL_TEMPLATE");
            break;
            case "1": 
                this.entity_name = "WhatsappTemplate"; 
                this.titleColumnLabel.text = MIOLocalizeString("WHATSAPP_TEMPLATE", "WHATSAPP_TEMPLATE");
            break;
            case "2": 
                this.entity_name = "ArchivedDocumentTemplate"; 
                this.titleColumnLabel.text = MIOLocalizeString("ARCHIVED_DOCUMENT_TEMPLATE", "ARCHIVED_DOCUMENT_TEMPLATE");
            break;
            case "3": 
                this.entity_name = "SalesInvoiceTemplate"; 
                this.titleColumnLabel.text = MIOLocalizeString("SALES_INVOICE_TEMPLATE", "SALES_INVOICE_TEMPLATE");
            break;

        }

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableview:UITableView){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview:MUITableView, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell:UITableVewCell = null;
        let item:CommunicationTemplate = null;
        
        switch(this.entity_name){
            case "EmailTemplate":
                cell = tableview.dequeueReusableCellWithIdentifier("EmailCell") as EmailCell;
                item = this.fetchedResultsController.objectAtIndexPath(indexPath) as EmailTemplate;
                cell.item = item;
                break;

            case "WhatsappTemplate":
                cell = tableview.dequeueReusableCellWithIdentifier("WhatsappCell") as WhatsappCell;
                item = this.fetchedResultsController.objectAtIndexPath(indexPath) as WhatsappTemplate;
                cell.item = item;                
                break;

            case "ArchivedDocumentTemplate":
                cell = tableview.dequeueReusableCellWithIdentifier("ArchivedDocumentTemplateCell") as ArchivedDocumentTemplateCell;
                item = this.fetchedResultsController.objectAtIndexPath(indexPath) as WhatsappTemplate;
                cell.item = item;                
                break;    

            case "SalesInvoiceTemplate":
                cell = tableview.dequeueReusableCellWithIdentifier("SalesInvoiceTemplateCell") as SalesInvoiceTemplateCell;
                item = this.fetchedResultsController.objectAtIndexPath(indexPath) as WhatsappTemplate;
                cell.item = item;                
                break;
        }

        return cell;
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
 
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
        let sds = [
            MIOSortDescriptor.sortDescriptorWithKey('status', true),
            MIOSortDescriptor.sortDescriptorWithKey('language', true), 
            MIOSortDescriptor.sortDescriptorWithKey('key', true) 
        ]

        let fetchRequest = DBHelper.listFetchRequestWithEntityName( this.entity_name, sds, null);
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    private addAction() {
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('TEMPLATES','TEMPLATES'), MIOLocalizeString('CREATE A NEW TEMPLATE','CREATE A NEW TEMPLATE'), MUIAlertViewStyle.Default);

        avc.addComboBoxWithConfigurationHandler(this, function(combobox:MUIComboBox) {
            combobox.addItem(MIOLocalizeString("ARCHIVED DOCUMENT", "ARCHIVED DOCUMENT"), 7);
            combobox.addItem(MIOLocalizeString("SUPPLIER ORDER", "SUPPLIER ORDER"), 0);
            combobox.addItem(MIOLocalizeString("SUPPLIER NOTE", "SUPPLIER NOTE"), 6);
            combobox.addItem(MIOLocalizeString("BOOKING", "BOOKING"), 1);
            combobox.addItem(MIOLocalizeString("ONLINE ORDER", "ONLINE ORDER"), 2);
            combobox.addItem(MIOLocalizeString("ONLINE ORDER VENUE", "ONLINE ORDER VENUE"), 3);
            combobox.addItem(MIOLocalizeString("ONLINE ORDER RIDER", "ONLINE ORDER RIDER"), 4);
            combobox.addItem(MIOLocalizeString("ONLINE ORDER PLATFORM", "ONLINE ORDER PLATFORM"), 5);            
        });


        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
            textField.placeholderText = MIOLocalizeString("LANGUAGE", "LANGUAGE");
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
            textField.placeholderText = MIOLocalizeString("STATUS", "STATUS");
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("ADD","ADD"), MUIAlertActionStyle.Default, this, function(){
            let typeCB = avc.comboBoxes[0] as MUIComboBox;
            let langTF = avc.textFields[0] as MUITextField;
            let statusTF = avc.textFields[1] as MUITextField;

            this.addTemplate(parseInt(typeCB.getSelectedItem()), langTF.text, statusTF.text?.length > 0 ? parseInt(statusTF.text) : 2);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, null, null));        

        this.presentViewController(avc, true);

    }

    private addTemplate(type:number, language:string, status:number){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let tpl = MIOEntityDescription.insertNewObjectForEntityForName( this.entity_name, ad.managedObjectContext) as CommunicationTemplate;
        
        switch(type) { 
            case 0:
                tpl.type = "supplier-order";
                if (tpl instanceof EmailTemplate) tpl.subject = "SUPPLIER ORDER";
                break;

            case 1:
                tpl.type = "booking";
                if (tpl instanceof EmailTemplate) tpl.subject = "BOOKING";
                break;

            case 2: 
                tpl.type = "online-order";
                if (tpl instanceof EmailTemplate) tpl.subject = "ONLINE ORDER";
                break;

            case 3:
                tpl.type = "online-order-venue";
                if (tpl instanceof EmailTemplate) tpl.subject = "ONLINE ORDER VENUE";
                break;

            case 4:
                tpl.type = "online-order-rider";
                if (tpl instanceof EmailTemplate) tpl.subject = "ONLINE ORDER RIDER";
                break;

            case 5:
                tpl.type = "online-order-platform";
                // if (tpl instanceof EmailTemplate) tpl.subject = "ONLINE ORDER PLATFORM";
                break;

            case 6:
                tpl.type = "supplier-note";
                // if (tpl instanceof EmailTemplate) tpl.subject = "SUPPLIER NOTE";
                break;

            case 7:
                tpl.type = "archived-document";
                // if (tpl instanceof EmailTemplate) tpl.subject = "ARCHIVED DOCUMENT";
                break;    
    
        }

        tpl.language = language;        
        tpl.active = true;
        if (tpl instanceof EmailTemplate) tpl.status = status;
        
        ad.managedObjectContext.save();
    }
    
}