//
// Generated class PayMethodName
//

/// <reference path="PayMethodName_ManagedObject.ts" />

class PayMethodName extends PayMethodName_ManagedObject
{

}
