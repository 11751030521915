

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Loan_ManagedObject

class Loan_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: capitalAmount
    set capitalAmount(value:number) {
        this.setValueForKey(value, 'capitalAmount');
    }
    get capitalAmount():number {
        return this.valueForKey('capitalAmount');
    }
    set capitalAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'capitalAmount');
    }
    get capitalAmountPrimitiveValue():number {
        return this.primitiveValueForKey('capitalAmount');
    }

    // Property: comments
    set comments(value:string) {
        this.setValueForKey(value, 'comments');
    }
    get comments():string {
        return this.valueForKey('comments');
    }
    set commentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'comments');
    }
    get commentsPrimitiveValue():string {
        return this.primitiveValueForKey('comments');
    }

    // Property: endDate
    set endDate(value:Date) {
        this.setValueForKey(value, 'endDate');
    }
    get endDate():Date {
        return this.valueForKey('endDate');
    }
    set endDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'endDate');
    }
    get endDatePrimitiveValue():Date {
        return this.primitiveValueForKey('endDate');
    }

    // Property: gracePeriod
    set gracePeriod(value:number) {
        this.setValueForKey(value, 'gracePeriod');
    }
    get gracePeriod():number {
        return this.valueForKey('gracePeriod');
    }
    set gracePeriodPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'gracePeriod');
    }
    get gracePeriodPrimitiveValue():number {
        return this.primitiveValueForKey('gracePeriod');
    }

    // Property: interestPercentage
    set interestPercentage(value:number) {
        this.setValueForKey(value, 'interestPercentage');
    }
    get interestPercentage():number {
        return this.valueForKey('interestPercentage');
    }
    set interestPercentagePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'interestPercentage');
    }
    get interestPercentagePrimitiveValue():number {
        return this.primitiveValueForKey('interestPercentage');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: period
    set period(value:number) {
        this.setValueForKey(value, 'period');
    }
    get period():number {
        return this.valueForKey('period');
    }
    set periodPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'period');
    }
    get periodPrimitiveValue():number {
        return this.primitiveValueForKey('period');
    }

    // Property: reference
    set reference(value:string) {
        this.setValueForKey(value, 'reference');
    }
    get reference():string {
        return this.valueForKey('reference');
    }
    set referencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'reference');
    }
    get referencePrimitiveValue():string {
        return this.primitiveValueForKey('reference');
    }

    // Property: startDate
    set startDate(value:Date) {
        this.setValueForKey(value, 'startDate');
    }
    get startDate():Date {
        return this.valueForKey('startDate');
    }
    set startDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'startDate');
    }
    get startDatePrimitiveValue():Date {
        return this.primitiveValueForKey('startDate');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }
    // Relationship: bankAccount
    set bankAccount(value:BankAccount) {
        this.setValueForKey(value, 'bankAccount');
    }
    get bankAccount():BankAccount {
        return this.valueForKey('bankAccount') as BankAccount;
    }

    // Relationship: loanInstallments
    protected _loanInstallments:MIOManagedObjectSet = null;
    get loanInstallments():MIOManagedObjectSet {
        return this.valueForKey('loanInstallments');
    }
    addLoanInstallmentsObject(value:LoanPaymentInstallment) {
        this._addObjectForKey(value, 'loanInstallments');
    }
    removeLoanInstallmentsObject(value:LoanPaymentInstallment) {
        this._removeObjectForKey(value, 'loanInstallments');
    }
}
