

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Booking_BookingService_ManagedObject

class Booking_BookingService_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: serviceID
    set serviceID(value:string) {
        this.setValueForKey(value, 'serviceID');
    }
    get serviceID():string {
        return this.valueForKey('serviceID');
    }
    set serviceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'serviceID');
    }
    get serviceIDPrimitiveValue():string {
        return this.primitiveValueForKey('serviceID');
    }

    // Property: serviceName
    set serviceName(value:string) {
        this.setValueForKey(value, 'serviceName');
    }
    get serviceName():string {
        return this.valueForKey('serviceName');
    }
    set serviceNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'serviceName');
    }
    get serviceNamePrimitiveValue():string {
        return this.primitiveValueForKey('serviceName');
    }
    // Relationship: booking
    set booking(value:Booking) {
        this.setValueForKey(value, 'booking');
    }
    get booking():Booking {
        return this.valueForKey('booking') as Booking;
    }
}
