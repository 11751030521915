
class ProgressAlertViewController extends MUIViewController
{
    static newInstance() : ProgressAlertViewController {
        let vc = new ProgressAlertViewController("progress-alert-view");
        vc.initWithResource("layout/progressalert/ProgressAlertView.html");
        return vc;
    }

    private titleLabel:MUILabel = null;
    private progressView:MUIProgressView = null;
    private cancelButton:MUIButton = null;

    get preferredContentSize(){
        return new MIOSize(304, 44 + 81);
    }

    viewDidLoad(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
        this.progressView = MUIOutlet(this, "progress-bar", "MUIProgressView");
        this.cancelButton = MUIOutlet(this, "cancel-btn", "MUIButton");

        this.view.layer.style.background = "";
        this.view.layer.style.backgroundColor = "";        
        this.modalPresentationStyle = MUIModalPresentationStyle.FormSheet;        

        this.titleLabel.text = this._title;
    }

    private _title:string = null;
    set title(title:string) {
        this._title = title;
        if (this.titleLabel == null) return;
        this.titleLabel.text = title;
    }
}