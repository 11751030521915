//
// Generated class Country
//

/// <reference path="Country_ManagedObject.ts" />

class Country extends Country_ManagedObject
{
    get localizeName():string{
        return MIOLocalizeString(this.name, this.name);
    }

}
