//
// Generated class ContainerCustom
//

/// <reference path="ContainerCustom_ManagedObject.ts" />

class ContainerCustom extends ContainerCustom_ManagedObject
{

}
