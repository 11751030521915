


/// <reference path="../../model/ReportItem.ts" />

class DashboardCellView extends MUIView 
{    
    loadingView:MUIView = null;
    chartView:MUIChartView = null;
    titleLabel:MUILabel = null;

    title:string = null;
    report = null;//:ReportItemChart = null;
    labelKey:string = null;
    valueKey:string = null;
    chartType = MUIChartViewType.Bar;

    private _reportViewController:ReportResultsViewController = null;

    initWithKeys(labelKey:string, valueKey:string){
        super.init();
        this.labelKey = labelKey;
        this.valueKey = valueKey;
    }

    reloadData(){
        this.titleLabel.text = this.title;
        this.loadingView.setHidden(false);
        ReportHelper.sharedInstance().executeReportQuery(this.report, null, this, function(code, items){
            if (code == 200) this.drawDataWithItems(items);            
            this.loadingView.setHidden(true);
        });
    }

    protected drawDataWithItems(items){

        if (items == null) return;

        let labels = [];
        let values = [];

        for (var index = 0; index < items.length; index++) {

            let item = items[index];
            let label = item[this.labelKey] != null ? item[this.labelKey] : "";
            let value = item[this.valueKey];
            value = ReportItem.parseValue(this.report.valueType, value);

            labels.push(label);
            values.push(value);

            if (this.chartType == MUIChartViewType.Pie && index >= 4) break;
        }

        this.chartView.labels = labels;
        this.chartView.values = values;
        this.chartView.renderWithType(this.chartType);
    }

    get reportViewController():ReportResultsViewController {

        if (this._reportViewController != null) return this._reportViewController;

        if (this.report.nextReportID == null) return;
        let report = ReportHelper.sharedInstance().listReportByID[this.report.nextReportID];

        if (report == null) return;

        let columns = report.columns;
        if (columns.length == 0) return null;

        let rvc = new ReportResultsViewController("report-results-view");
        rvc.initWithResource("layout/reports/ReportResultsView.html");
        rvc.backButtonHidden = false;

        rvc.report = report;
        
        for (var index = 0; index < columns.length; index++) {
            let col = columns[index];
            rvc.addColumn(col);
        }

        rvc.columnSubtotalsIndexes = report.subtotals;
        
        this._reportViewController = rvc;
        return rvc;
    }

}