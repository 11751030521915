//
// Generated class StockNoteProductLine
//

/// <reference path="StockNoteProductLine_ManagedObject.ts" />

class StockNoteProductLine extends StockNoteProductLine_ManagedObject
{

}
