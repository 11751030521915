

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Margin_ManagedObject

class Margin_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: limit
    set limit(value:number) {
        this.setValueForKey(value, 'limit');
    }
    get limit():number {
        return this.valueForKey('limit');
    }
    set limitPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'limit');
    }
    get limitPrimitiveValue():number {
        return this.primitiveValueForKey('limit');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Relationship: marginProducts
    protected _marginProducts:MIOManagedObjectSet = null;
    get marginProducts():MIOManagedObjectSet {
        return this.valueForKey('marginProducts');
    }
    addMarginProductsObject(value:MarginProducts) {
        this._addObjectForKey(value, 'marginProducts');
    }
    removeMarginProductsObject(value:MarginProducts) {
        this._removeObjectForKey(value, 'marginProducts');
    }
}
