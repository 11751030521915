

class WineCellarInfoViewController extends MUIViewController
{
    public preferredContentSize = new MIOSize(700, 500);

    private closeButton:MUIButton = null;
    private doneButton:MUIButton = null;
    private tableView:UITableView = null;

    delegate = null;
    private _tempWineCellarDict = null;
    private _tempWineCellarHash = "";

    viewDidLoad(){
        super.viewDidLoad();

        this.closeButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.closeButton.setAction(this, function() {
            this.dismissViewController(true);
        });

        this.doneButton = MUIOutlet(this, "done-btn", "MUIButton");
        this.doneButton.setAction(this, function(){

            this.updateWineCellarHash();
            this._product.wineCellarDict = this._tempWineCellarDict; 
            this._product.wineCellarHash = this._tempWineCellarHash;

            DBHelper.saveMainContext();
            this.delegate.updateUI();
            this.dismissViewController(true);
        });

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;     
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private _product:Product = null;
    set product (product:Product) {
        if (product == null) return;

        this._product = product;
        this._tempWineCellarHash = this._product.wineCellarHash;

       try {
           this._tempWineCellarDict = JSON.parse(this._product.wineCellarDict); 
       } catch (ex) {
            // console.error(ex);
           this._tempWineCellarDict = this._product.wineCellarDict;
       }
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._product == null ) return;        

        //use wineCellarDict to populate table in cellAtIndexPath
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableview:UITableView){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview:UITableView, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){        
        let cell = tableview.dequeueReusableCellWithIdentifier("WineCellarDataCell") as WineCellarDataCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as WineCellar;
        cell.item = item;

        if (this._tempWineCellarDict) {

            if (item.type == WineCellarType.ProductAttribute) {
                let productAttributeID = this._tempWineCellarDict[item.identifier];
                if (productAttributeID){
                    DBHelper.queryObjectWithCompletion("ProductAttribute", null,  MIOPredicate.predicateWithFormat("identifier == " + productAttributeID), [], this, function(object) { 
                        cell.setSelectedAttribute = object;
                    });  
                }
            } else if (item.type == WineCellarType.StockCategory) {
                let stockCategoryID = this._tempWineCellarDict[item.identifier];
                if (stockCategoryID){
                    DBHelper.queryObjectWithCompletion("StockCategory", null,  MIOPredicate.predicateWithFormat("identifier == " + stockCategoryID), [], this, function(object) { 
                        cell.setSelectedStockCategory = object;
                    });  
                }

            }
        }

        cell.selectionStyle = UITableViewCellSelectionStyle.None;            
        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as WineCellar;
        
        if (item.type == WineCellarType.StockCategory) {
            let pscvc = StockCategorySelectionViewController.newInstance();
            pscvc.setSelectedWineCellar = item;
            pscvc.noSelectionCell = true;
            pscvc.delegate = this;

            if (indexPath.row > 0) {
                //get previous winecellar of type stockcategory
                let previousIndexPath = MIOIndexPath.indexForRowInSection(indexPath.row-1, indexPath.section)
                let previousWineCellar = this.fetchedResultsController.objectAtIndexPath(previousIndexPath) as WineCellar;
                
                if (previousWineCellar.type == WineCellarType.StockCategory) {
                    let previousStockCategoryID = this._tempWineCellarDict[previousWineCellar.identifier];
                    pscvc.setParentStockCategoryID = previousStockCategoryID;

                    DBHelper.queryObjectWithCompletion("StockCategory", null,  MIOPredicate.predicateWithFormat("identifier == " + previousStockCategoryID), [], this, function(object) { 
                        pscvc.setParentStockCategory = object;
                    });  
                }
               
            }

            this.navigationController.pushViewController(pscvc, true); 

            // StockCategory *psc = [DBHelper previousStockCategoryForWineCellar:self.selectedWineCellar dict:mWineCellarDict inContex:mAppDelegate.adminMOC];
            // StockCategoriesTableViewController *newVc = (StockCategoriesTableViewController *)[[UIStoryboard storyboardWithName:@"StockCategories" bundle:[NSBundle bundleForClass:[self class]]] instantiateInitialViewController];
            // newVc.delegate = self;
            // newVc.parentStockCategory = psc;
            // [self.navigationController pushViewController:newVc animated:YES];

        } else if (item.type == WineCellarType.ProductAttribute) {
            let pasvc = ProductAttributeSelectionViewController.newInstance();
            pasvc.item = item;
            pasvc.noSelectionCell = true;
            pasvc.delegate = this;
            this.navigationController.pushViewController(pasvc, true);
        }  
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {   
        return UITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {        
        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            let item = this.fetchedResultsController.objectAtIndexPath(indexPath);    
            DBHelper.deleteObjectFromMainContext(item, true); 
        }
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
        
        let ad = MUIWebApplication.sharedInstance().delegate;
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("WineCellar", sortDescriptors, null);
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;            
        
        fetchedResultsController.performFetch();
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }

    // private _wineCellarMap = null;
    // private createDictionaryMap(dict:String[]) {
    //     this._wineCellarMap = new Map(dict);
    // }
    
    private updateSelectStockCategory(wineCellar:WineCellar, selectedStockCategory:StockCategory){
        // if dictionary doesnt exist, create it
        if (!this._tempWineCellarDict) this._tempWineCellarDict = {};

        if (wineCellar) {
            if (selectedStockCategory) {
                this._tempWineCellarDict[wineCellar.identifier] = selectedStockCategory.identifier;
            } else {
                delete this._tempWineCellarDict[wineCellar.identifier];
            }
        }        
        this.tableView.reloadData();
    }

    // - (void) stockCategoriesTableViewController:(StockCategoriesTableViewController *)controller didSelectStockCategory:(StockCategory *)stockCategory
    // {
    //     [mWineCellarDict setObject:stockCategory.serverID forKey:self.selectedWineCellar.serverID];
    //     [self.tableView reloadData];
    //     [controller.navigationController popToRootViewControllerAnimated:YES];
    // }

    private updateSelectProductAttribute(wineCellar:WineCellar, selectedProductAttribute:ProductAttribute){
        // if dictionary doesnt exist, create it
        if (!this._tempWineCellarDict) this._tempWineCellarDict = {};
        
        if (wineCellar) {
            if (selectedProductAttribute) {
                this._tempWineCellarDict[wineCellar.identifier] = selectedProductAttribute.identifier;
            } else {
                delete this._tempWineCellarDict[wineCellar.identifier];
            }
        }
        this.tableView.reloadData();
    }

    // - (void) selectProductAttributteTableViewController:(SelectProductAttributteTableViewController *)controller didSelectProductAttribute:(ProductAttribute *)productAttribute
    // {
    //     [mWineCellarDict setObject:productAttribute.serverID forKey:self.selectedWineCellar.serverID];
    //     [self.tableView reloadData];
    //     [controller.navigationController popToRootViewControllerAnimated:YES];

    // }

    private removeItemFromWineCellarDict(wineCellar:WineCellar, productAttribute:ProductAttribute){

        if(this._tempWineCellarDict) {
            if (this._tempWineCellarDict[wineCellar.identifier]) {
                delete this._tempWineCellarDict[wineCellar.identifier];
            }
        } 
    }

    private updateWineCellarHash() {
        this._tempWineCellarHash = "";
        let count = 0;
        let maxCount = Object.keys(this._tempWineCellarDict).length;

        for (let i=0; i<this.tableView.rows.length; i++) {

            if (this.tableView.rows[i]._wineCellar.type == WineCellarType.ProductAttribute && this.tableView.rows[i]._selectedProductAttribute?.name) {
                this._tempWineCellarHash += (this.tableView.rows[i]._selectedProductAttribute?.name);
            } else if (this.tableView.rows[i]._wineCellar.type == WineCellarType.StockCategory && this.tableView.rows[i]._selectedStockCategory?.name) {
                this._tempWineCellarHash += (this.tableView.rows[i]._selectedStockCategory?.name);
            }

            this._tempWineCellarHash += "#";
            count ++;

        }

        ///////////////// CREATING WINE CELLAR V.1
        // for (let i=0; i<this.tableView.rows.length; i++) {
        //     if (this.tableView.rows[i]._wineCellar.type == WineCellarType.ProductAttribute) {
        //         if (this.tableView.rows[i]._selectedProductAttribute) {
        //             this._tempWineCellarHash += (this.tableView.rows[i]._selectedProductAttribute.name);
        //             if (count < maxCount-1) {
        //                 this._tempWineCellarHash += "#";
        //                 count ++;
        //             }
        //         }
        //     } else if (this.tableView.rows[i]._wineCellar.type == WineCellarType.StockCategory) {
        //         if (this.tableView.rows[i]._selectedStockCategory) {
        //             this._tempWineCellarHash += (this.tableView.rows[i]._selectedStockCategory.name);
        //             if (count < maxCount-1) {
        //                 this._tempWineCellarHash += "#";
        //                 count ++;
        //             }
        //         } 
        //     }
        // }
    }


    // Function to test string
    private testJSON(text:any) {
        if (typeof text !== "string") {
            return false;
        }
        try {
            JSON.parse(text);
            return true;
        } catch (error) {
            return false;
        }
    }


    //NEED TO FIX HAS TO INCLUDE STOCK CATEGORY
    // private updateWineCellarHash() {
    //     if(this._product?.wineCellarDict) {

    //         let hashString = "";
    //         let dict = this._product.wineCellarDict;
    //         let productAttr = null;
    //         Object.keys(dict).forEach(function(key, index, arr) {
    //             productAttr = DBHelper.queryObjectFromMainContext("ProductAttribute", MIOPredicate.predicateWithFormat("identifier == " + dict[key]));
    //             if(arr[index + 1]) {
    //                 hashString += productAttr.name + " | ";
    //             } else {
    //                 hashString += productAttr.name;
    //             }
    //         });

    //         this._product.wineCellarHash = hashString;
    //         // product changed, so will update automatically
    //     }
    // }

    // private _tempWineCellarHash:string = "";
    // private addToWineCellarHash(name:string) {       
    //     this._tempWineCellarHash += name;
    // }

    // private clearWineCellarHash() {
    //     this._tempWineCellarHash = "";
    // }








    // THIS SIMPLY RETURNS THE NAME OF THE PRODUCTATTRIBUTE/STOCKCATEGORY GIVEN A ID
    // + (NSString*)titleForValue:(NSString*)valueID wineCellarType:(WineCellarType)type inContext:(NSManagedObjectContext*)moc
    // {
    //     NSString *title = nil;
        
    //     if(valueID.length > 0)
    //     {
    //         if(type == WineCellarTypeStockCategory)
    //         {
    //             StockCategory *stockCategory = [CoreDataHelper queryOneObjectWithEntityName:@"StockCategory" predicate:[NSPredicate predicateWithFormat:@"serverID == %@",valueID] inContext:moc];
    //             title = stockCategory.name;
    //         }
    //         else if (type == WineCellarTypeStockAttribute)
    //         {
    //             ProductAttribute *productAttribute = [CoreDataHelper queryOneObjectWithEntityName:@"ProductAttribute" predicate:[NSPredicate predicateWithFormat:@"serverID == %@",valueID] inContext:moc];
    //             title = productAttribute.name;
    //         }
    //     }
    //     return title;
    // }

    private findPreviousStockCategoryForWineCellar(wineCellar:WineCellar, wineCellarDict:any[]) {

        //get all winecellars of wineCellar.id with wineCellar.type and wineCellar.orderIndex... should only be one right?
        // NSArray *wineCellars = [CoreDataHelper queryObjectsWithEntityName:@"WineCellar" withPredicate:[NSPredicate predicateWithFormat:@"serverID != %@ && type == %@ && orderIndex < %@",wineCellar.serverID,@(WineCellarTypeStockCategory),wineCellar.orderIndex] withSortDescriptors:@[[NSSortDescriptor sortDescriptorWithKey:@"orderIndex" ascending:NO]] inContext:moc];

        // //for each of these do in order....
        // for (WineCellar *cellar in wineCellars)
        // {

        //     //get the value in Dict for this wineCellar
        //     NSString *valueID = [dict objectForKey:cellar.serverID];
                
        //     if(valueID.length > 0) //if there is a value...
        //     {

        //         // get and return the corresponding StockCategory object
        //         StockCategory *stockCategory = [CoreDataHelper queryOneObjectWithEntityName:@"StockCategory" predicate:[NSPredicate predicateWithFormat:@"serverID == %@",valueID] inContext:moc];
        //         if(stockCategory)
        //         {
        //             return stockCategory;
        //         }
        //     }
        // }

        // //if nothing found retun null, no previous stockCategory
        // return nil;
        
    }

    // + (StockCategory*)previousStockCategoryForWineCellar:(WineCellar*)wineCellar dict:(NSMutableDictionary*)dict inContex:(NSManagedObjectContext*)moc
    // {
    //     NSArray *wineCellars = [CoreDataHelper queryObjectsWithEntityName:@"WineCellar" withPredicate:[NSPredicate predicateWithFormat:@"serverID != %@ && type == %@ && orderIndex < %@",wineCellar.serverID,@(WineCellarTypeStockCategory),wineCellar.orderIndex] withSortDescriptors:@[[NSSortDescriptor sortDescriptorWithKey:@"orderIndex" ascending:NO]] inContext:moc];

    //     for (WineCellar *cellar in wineCellars)
    //     {
    //         NSString *valueID = [dict objectForKey:cellar.serverID];
            
    //         if(valueID.length > 0)
    //         {
    //             StockCategory *stockCategory = [CoreDataHelper queryOneObjectWithEntityName:@"StockCategory" predicate:[NSPredicate predicateWithFormat:@"serverID == %@",valueID] inContext:moc];
    //             if(stockCategory)
    //             {
    //                 return stockCategory;
    //             }
    //         }
    //     }
    //     return nil;
    // }

    private findLastCategorywithDict(wineCellarDict:any[]) {

        //get all WineCellars of type stockCategory in order...
        // NSArray *wineCellars = [CoreDataHelper queryObjectsWithEntityName:@"WineCellar" withPredicate:[NSPredicate predicateWithFormat:@"type == %@",@(WineCellarTypeStockCategory)] withSortDescriptors:@[[NSSortDescriptor sortDescriptorWithKey:@"orderIndex" ascending:NO]] inContext:moc];

        // //for each do...
        // for (WineCellar *cellar in wineCellars)
        // {

        //     //get this wineCellar's stockCategory ID
        //     NSString *valueID = [dict objectForKey:cellar.serverID];
            
        //     if(valueID.length > 0)
        //     {
        //         //get and return the StockCategory object that corresponds....
        //         StockCategory *stockCategory = [CoreDataHelper queryOneObjectWithEntityName:@"StockCategory" predicate:[NSPredicate predicateWithFormat:@"serverID == %@",valueID] inContext:moc];
        //         if(stockCategory)
        //         {
        //             return stockCategory;
        //         }
        //     }
        // }

        // //if nothing found return null
        // return nil;
        
    }

    // + (StockCategory*)lastCategoryWithDict:(NSMutableDictionary*)dict inContex:(NSManagedObjectContext*)moc
    // {
    //     NSArray *wineCellars = [CoreDataHelper queryObjectsWithEntityName:@"WineCellar" withPredicate:[NSPredicate predicateWithFormat:@"type == %@",@(WineCellarTypeStockCategory)] withSortDescriptors:@[[NSSortDescriptor sortDescriptorWithKey:@"orderIndex" ascending:NO]] inContext:moc];

    //     for (WineCellar *cellar in wineCellars)
    //     {
    //         NSString *valueID = [dict objectForKey:cellar.serverID];
            
    //         if(valueID.length > 0)
    //         {
    //             StockCategory *stockCategory = [CoreDataHelper queryOneObjectWithEntityName:@"StockCategory" predicate:[NSPredicate predicateWithFormat:@"serverID == %@",valueID] inContext:moc];
    //             if(stockCategory)
    //             {
    //                 return stockCategory;
    //             }
    //         }
    //     }
    //     return nil;

    // }

}