

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class MenuCategory_ManagedObject

class MenuCategory_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: image
    set image(value:string) {
        this.setValueForKey(value, 'image');
    }
    get image():string {
        return this.valueForKey('image');
    }
    set imagePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'image');
    }
    get imagePrimitiveValue():string {
        return this.primitiveValueForKey('image');
    }

    // Property: isLimited
    set isLimited(value:boolean) {
        this.setValueForKey(value, 'isLimited');
    }
    get isLimited():boolean {
        return this.valueForKey('isLimited');
    }
    set isLimitedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isLimited');
    }
    get isLimitedPrimitiveValue():boolean {
        return this.primitiveValueForKey('isLimited');
    }

    // Property: limitQuantity
    set limitQuantity(value:number) {
        this.setValueForKey(value, 'limitQuantity');
    }
    get limitQuantity():number {
        return this.valueForKey('limitQuantity');
    }
    set limitQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'limitQuantity');
    }
    get limitQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('limitQuantity');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: printInTicket
    set printInTicket(value:boolean) {
        this.setValueForKey(value, 'printInTicket');
    }
    get printInTicket():boolean {
        return this.valueForKey('printInTicket');
    }
    set printInTicketPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'printInTicket');
    }
    get printInTicketPrimitiveValue():boolean {
        return this.primitiveValueForKey('printInTicket');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }
    // Relationship: menu
    set menu(value:Menu) {
        this.setValueForKey(value, 'menu');
    }
    get menu():Menu {
        return this.valueForKey('menu') as Menu;
    }

    // Relationship: productsMenu
    protected _productsMenu:MIOManagedObjectSet = null;
    get productsMenu():MIOManagedObjectSet {
        return this.valueForKey('productsMenu');
    }
    addProductsMenuObject(value:ProductMenu) {
        this._addObjectForKey(value, 'productsMenu');
    }
    removeProductsMenuObject(value:ProductMenu) {
        this._removeObjectForKey(value, 'productsMenu');
    }
    // Relationship: tag
    set tag(value:CategoryTag) {
        this.setValueForKey(value, 'tag');
    }
    get tag():CategoryTag {
        return this.valueForKey('tag') as CategoryTag;
    }
}
