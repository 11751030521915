/**
 * Created by crisan on 7/12/16.
 */



/// <reference path="../../../model/ProductAttributeType.ts" />

class AttributeTypeCell extends MUITableViewCell
{
    private nameTextField:MUITextField = null;
    private _separator = null;

    private _item:ProductAttributeType = null;
    private moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

    awakeFromHTML()
    {
        this.nameTextField = MUIOutlet(this, "atv_tv_cell_lbl", "MUITextField"); 
        this.nameTextField.setOnEnterPress(this, function(textfield, value)
        {
            this._item.name = value.trim();
            this.moc.save();
        });
        this._separator = MUIOutlet(this, "atv_tv_separator1", "MUIView");
    }
/*
    setSelected(value)
    {
        super.setSelected(value);

        if (value == true)
            this._separator.setBackgroundRGBColor(0, 191, 191);
        else
            this._separator.setBackgroundRGBColor(237, 237, 242);
    }
*/
    set item(i)
    {
        this._item = i;
        this.nameTextField.text = i.name;
    }

}