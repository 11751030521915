
class UserPermissionCell extends UITableViewCell
{
    private titleLabel:MUILabel = null;
    private optionsButton:MUIButton = null;

    delegate = null;

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
        this.optionsButton = MUIOutlet(this, "control-options-btn", "MUIButton");
        if (this.optionsButton) this.optionsButton.setAction(this, this.optionsAction);
        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    set type(type:number) {
        this.optionsButton.hidden = type == 0 ? false : true;
    }

    set title (title:string){
        this.titleLabel.text = title;
    }

    private _sectionIndex = 0;
    set sectionIndex(sectionIndex:number) {
        this._sectionIndex = sectionIndex;
    }

    private optionsAction(){
        let vc = UserAccessControlViewController.newInstance();
        vc.sectionIndex = this._sectionIndex;
        vc.delegate = this.delegate;
        AppHelper.sharedInstance().presentPopOverViewController(vc, this.optionsButton, true);
    }
}