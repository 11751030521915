//
// Generated class CustomConcept
//

/// <reference path="CustomConcept_ManagedObject.ts" />

class CustomConcept extends CustomConcept_ManagedObject
{

}
