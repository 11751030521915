
class DBProductsCoder extends DBXLSXCoder
{
    protected mainEntity() { 
        return { entity: "Product", predicateFormat: "deletedAt == null", relations:["category.superCategory", "stockCategory", "tax", "taxBuy", "source", "defaultProductFormat", "ellaborationCenter"] };
    }

    protected columns() : any[] {    
        let cols = [
            { title: "ROOT CATEGORY ID", width: "10%", align: "center" },
            { title: "ROOT CATEGORY NAME", width: "10%", align: "center" },
            { title: "CATEGORY ID", width: "10%", align: "center" },
            { title: "CATEGORY NAME", width: "10%", align: "center" },            
            { title: "STOCK CATEGORY ID", width: "10%", align: "center" },
            { title: "STOCK CATEGORY NAME", width: "10%", align: "center" },            
            { title: "PRODUCT ID", width: "10%", align: "center" },
            { title: "REFERENCE", width: "10%", align: "center" },            
            { title: "PRODUCT NAME", width: "10%", align: "center" },
            { title: "PRODUCT KITCHEN NAME", width: "10%", align: "center" },
            { title: "PRODUCT STOCK NAME", width: "10%", align: "center" },
            { title: "PRODUCT DESCRIPTION", width: "10%", align: "center" },
            { title: "PRODUCT BRAND", width: "10%", align: "center" },
            { title: "SELL TAX NAME", width: "10%", align: "center" },
            { title: "SELL PRICE", width: "10%", align: "center", formatter: this.ad.currencyFormatter },
            { title: "BUYING TAX NAME", width: "10%", align: "center" },
            { title: "BARCODE", width:  "10%", align: "center" },
            { title: "BARCODE2", width:  "10%", align: "center" },
            { title: "KITCHEN SOURCE", width:  "10%", align: "center" },
            { title: "DEFAULT WAREHOUSE", width:  "10%", align: "center" },
            { title: "PRODUCT LONG DESCRIPTION", width:  "10%", align: "center" },
            { title: "PRODUCT TAGS", width:  "10%", align: "center" },
            { title: "MEASURE UNIT", width:  "10%", align: "center" },
            { title: "CONTAINER UNIT", width:  "10%", align: "center" },
            { title: "CONTAINER QUANTITY", width:  "10%", align: "center", formatter: this.ad.numberFormatter },
            { title: "LOSS IN STORAGE", width:  "10%", align: "center", formatter: this.ad.numberFormatter },
            { title: "ADDITIONAL COSTS FOR STORAGE", width:  "10%", align: "center", formatter: this.ad.currencyFormatter },
            { title: "FIXED COSTS", width: "10%", align: "center", formatter: this.ad.currencyFormatter },
            { title: "COST TYPE", width: "10%", align: "center" },
            { title: "ADDITIONAL FIXED COSTS", width: "10%", align: "center", formatter: this.ad.currencyFormatter },
            { title: "MINIMUM GLOBAL STOCK", width: "10%", align: "center", formatter: this.ad.numberFormatter },
            { title: "MAXIMUM GLOBAL STOCK", width: "10%", align: "center", formatter: this.ad.numberFormatter },
            { title: "ELLABORATION TYPE", width: "10%", align: "center" },
            { title: "ELLABORATION CENTER ID", width: "10%", align: "center" },
            { title: "ELLABORATION CENTER NAME", width: "10%", align: "center" },
            { title: "ENABLE STOCK", width: "10%", align: "center" },
            { title: "ENABLE SALES", width: "10%", align: "center" },
            { title: "DEFAULT FORMAT NAME", width: "10%", align: "center" },
            { title: "IS FORMAT REQUIRED", width: "10%", align: "center" }
        ]
        return cols;
    }

    protected aditionalImportEntities(){
        return [
            { entity: "ProductCategory", predicateFormat: "deletedAt == null" },
            { entity: "Tax", predicateFormat: "deletedAt == null" },
            { entity: "Format", predicateFormat: "deletedAt == null" },
            { entity: "ProductFormat", predicateFormat: "deletedAt == null" },
            { entity: "ProductSource", predicateFormat: "deletedAt == null" },
            { entity: "StockCategory", predicateFormat: "deletedAt == null" },
            { entity: "Warehouse", predicateFormat: "deletedAt == null" },
            { entity: "EllaborationCenter", predicateFormat: "deletedAt == null" }
        ]
    }

    protected importRow(row:any){
        
        let rootCategoryName    = row["ROOT CATEGORY NAME"]?.trim();
        let rootCategoryID      = row["ROOT CATEGORY ID"] as string;
        let categoryName        = row["CATEGORY NAME"]?.trim();
        let categoryID          = row["CATEGORY ID"] as string;
        let stockCategoryName   = row["STOCK CATEGORY NAME"]?.trim();
        let stockCategoryID     = row["STOCK CATEGORY ID"] as string;
        let productReference    = row["REFERENCE"];
        let productName         = row["PRODUCT NAME"]?.trim();
        let productID           = row["PRODUCT ID"];
        let productDescription  = row["PRODUCT DESCRIPTION"];
        let productLongDescription = row["PRODUCT LONG DESCRIPTION"];
        let productBrand        = row["PRODUCT BRAND"]?.trim();
        let productTags         = row["PRODUCT TAGS"];
        let barcode             = row["BARCODE"];
        let barcode2            = row["BARCODE2"];
        let sellTaxName         = row["SELL TAX NAME"]?.trim();
        let buyTaxName          = row["BUYING TAX NAME"]?.trim();
        let sellPrice           = row["SELL PRICE"];
        let mesasureUnit:string = row["MEASURE UNIT"] ?? "U";
        let containterMeasureUnit = row["CONTAINER UNIT"];
        let containerQuantity   = row["CONTAINER QUANTITY"];
        let fixedCost           = row["FIXED COSTS"];
        let costType            = row["COST TYPE"];
        let ellaborationType    = row["ELLABORATION TYPE"];
        let ellaborationCenterID    = row["ELLABORATION CENTER ID"];
        let ellaborationCenterName  = row["ELLABORATION CENTER NAME"];
        let enableStock         = row["ENABLE STOCK"];
        let enableSales         = row["ENABLE SALES"];
        let defaultFormatName   = row["DEFAULT FORMAT NAME"]?.trim();
        let isFormatRequired    = row["IS FORMAT REQUIRED"];
        let kitchenSource       = row["KITCHEN SOURCE"]?.trim();        
        let defaultWarehouse    = row["DEFAULT WAREHOUSE"]?.trim();        

        if (productName == null || productName.length == 0) return;

        let source = this.queryEntityByField("ProductSource", "name", kitchenSource) as ProductSource;
        let sellTax = this.queryEntityByField("Tax", "name", sellTaxName) as Tax;
        let buyTax = this.queryEntityByField("Tax", "name", buyTaxName) as Tax;        
        let ellaborationCenter = this.queryEntityByIDOrName("EllaborationCenter", ellaborationCenterID, ellaborationCenterName) as EllaborationCenter;
        
        let rootCategory = this.queryEntityByIDOrName("ProductCategory", rootCategoryID, rootCategoryName) as ProductCategory;
        if (rootCategoryName?.length > 0 && rootCategory == null) {
            rootCategory = MIOEntityDescription.insertNewObjectForEntityForName("ProductCategory", DBHelper.mainManagedObjectContext) as ProductCategory;
            rootCategory.identifier = this.parseOrCreateIdentifier(rootCategoryID);
            rootCategory.name = rootCategoryName;  
            rootCategory.tax = sellTax;
            rootCategory.isAvailable = true;
            rootCategory.showInBar = true;
            rootCategory.showInTables = true;
            rootCategory.showInOnline = true;
            this.appendObject(rootCategory);
        }

        let category = this.queryEntityByIDOrName("ProductCategory", categoryID, categoryName) as ProductCategory;
        if (categoryName?.length > 0 && category == null) {
            category = MIOEntityDescription.insertNewObjectForEntityForName("ProductCategory", DBHelper.mainManagedObjectContext) as ProductCategory;
            category.identifier = this.parseOrCreateIdentifier(categoryID);
            category.name = categoryName;  
            category.tax = sellTax;
            category.isAvailable = true;
            category.showInBar = true;
            category.showInTables = true;
            category.showInOnline = true;
            this.appendObject(category);
        }

        if (rootCategory != null && category != null) {
            category.superCategory = rootCategory;
        }

        let stockCategory = this.queryEntityByIDOrName("StockCategory", stockCategoryID, stockCategoryName) as StockCategory;
        if ( stockCategoryName?.length > 0 && stockCategory == null) {
            stockCategory = MIOEntityDescription.insertNewObjectForEntityForName("StockCategory", DBHelper.mainManagedObjectContext) as StockCategory;
            stockCategory.identifier = this.parseOrCreateIdentifier(stockCategoryID);
            stockCategory.name = stockCategoryName;
            this.appendObject(stockCategory);
        }
        
        let product = this.queryEntityProduct(productID, productReference, productName);
        if (product == null) { 
            product = MIOEntityDescription.insertNewObjectForEntityForName("Product", DBHelper.mainManagedObjectContext) as Product;
            product.identifier = this.parseOrCreateIdentifier(productID);
            this.appendObject(product);
        }

        product.name = productName;
        product.reference = productReference?.length > 0 ? productReference : null;

        product.info = productDescription;
        product.detailedInfo = productLongDescription;
        product.brand = productBrand;
        product.category = category != null ? category : rootCategory;
        product.stockCategory = stockCategory;

        product.price = sellPrice;
        product.tax = sellTax;
        product.taxBuy = buyTax;
        product.source = source;
        product.tags = productTags;
        product.barcode = barcode;
        product.barcode2 = barcode2;
        
        product.quantity = containerQuantity;
        let pm = MeasureUnits.measureUnitFromString(mesasureUnit);
        let cm = MeasureUnits.measureUnitFromString(containterMeasureUnit);
        product.measureUnitType = pm == MeasureUnitType.Container ? cm : pm;
        
        product.costType = Product.parseCostTypeValue(costType);
        let costPrice = fixedCost;
        product.costProductPrice = costPrice;
        product.costPrice = DBHelper.calculateMinumCostFromProductPrice(costPrice, product);

        product.stockElaborationType = Product.parseStockElabotarationTypeValue(ellaborationType);
        product.isEnableForSell = this.parseBoolValue(enableSales);
        product.isEnableForStock = this.parseBoolValue(enableStock);

        let w = this.queryEntityByField("Warehouse", "name", defaultWarehouse) as Warehouse;
        if (w == null){
            w = AppHelper.sharedInstance().defaultWarehouse;
        }

        product.defaultWarehouse = w;
        product.defaultWarehouseName = w?.name;

        product.ellaborationCenter = ellaborationCenter;

        product.formatRequired = this.parseBoolValue(isFormatRequired);

        if (defaultFormatName != null) {
            let format = this.queryEntity("Format", "name = '" + defaultFormatName + "'") as Format;
            if (format == null) {
                //TODO: Throw error
                return;
            }    

            let pf = this.queryEntity("ProductFormat", "product.identifier = " + product.identifier + " and format.identifier = " + format.identifier ) as ProductFormat;
            if (pf == null) {
                pf = MIOEntityDescription.insertNewObjectForEntityForName("ProductFormat", DBHelper.mainManagedObjectContext) as ProductFormat;
                pf.identifier = new MIOUUID().UUIDString;
                pf.product = product;
                pf.format = format;
                this.appendObject(pf);    
            }

            product.defaultProductFormat = pf;
        }                
        
        MIOLog("ADDING PRODUCT: " + this.rowIndex + "/" + this.rows.length + ": " + productName);
    }

    // 
    // Export
    // 

    protected exportTitle() : string { return "Products"; }

    protected exportSortDescriptors() { 
        return [MIOSortDescriptor.sortDescriptorWithKey("name", false)]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let product = object as Product;

        let item = {
            "ROOT CATEGORY ID"      : product.category?.superCategory?.identifier,
            "ROOT CATEGORY NAME"    : product.category?.superCategory?.name,            
            "CATEGORY ID"           : product.category?.identifier,
            "CATEGORY NAME"         : product.category?.name,            
            "STOCK CATEGORY ID"     : product.stockCategory?.identifier,
            "STOCK CATEGORY NAME"   : product.stockCategory?.name,
            "PRODUCT ID"            : product.identifier,
            "REFERENCE"             : product.reference,
            "PRODUCT NAME"          : product.name,
            "PRODUCT KITCHEN NAME"  : product.kitchenName,
            "PRODUCT STOCK NAME"    : product.stockName,
            "PRODUCT DESCRIPTION"   : product.info,
            "PRODUCT BRAND"         : product.brand,
            "SELL TAX NAME"         : product.tax?.name,
            "SELL PRICE"            : product.price,
            "BUYING TAX NAME"       : product.taxBuy?.name,
            "BARCODE"               : product.barcode,
            "BARCODE2"              : product.barcode2,
            "KITCHEN SOURCE"        : product.source?.name,
            "DEFAULT WAREHOUSE"     : product.defaultWarehouseName,
            "PRODUCT LONG DESCRIPTION": product.detailedInfo,
            "PRODUCT TAGS"          : product.tags,            
            "MEASURE UNIT"          : product.measureTypeString,
            "CONTAINER UNIT"        : product.containerMeasureTypeString,
            "CONTAINER QUANTITY"    : product.containerQuantity,
            "LOSS IN STORAGE"       : product.lossInStorage,
            "ADDITIONAL COSTS FOR STORAGE": product.additionalStorageCosts,
            "FIXED COSTS"           : product.costProductPrice,
            "COST TYPE"             : product.costType,
            "ADDITIONAL FIXED COSTS": product.additionalCosts,
            "MINIMUM GLOBAL STOCK"  : product.min,
            "MAXIMUM GLOBAL STOCK"  : product.max,
            "ELLABORATION TYPE"     : product.stockElaborationTypeString,
            "ELLABORATION CENTER ID": product.ellaborationCenter?.identifier,            
            "ELLABORATION CENTER NAME": product.ellaborationCenter?.name,
            "ENABLE STOCK"          : product.isEnableForStock,
            "ENABLE SALES"          : product.isEnableForSell,
            "DEFAULT FORMAT NAME"   : product.defaultProductFormat?.format?.name,
            "IS FORMAT REQUIRED"    : product.formatRequired,
            "PRODUCT PLACE ID"      : product.placeID,
            "CATEGORY PLACE ID"     : product.category?.placeID,
            "STOCK CATEGORY PLACE ID" : product.stockCategory?.placeID,
        }

        return item;
    }
}