

// Generated class ReportScriptFilter_ManagedObject

class ReportScriptFilter_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: key
    set key(value:string) {
        this.setValueForKey(value, 'key');
    }
    get key():string {
        return this.valueForKey('key');
    }
    set keyPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'key');
    }
    get keyPrimitiveValue():string {
        return this.primitiveValueForKey('key');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: defaultValue
    set defaultValue(value:string) {
        this.setValueForKey(value, 'defaultValue');
    }
    get defaultValue():string {
        return this.valueForKey('defaultValue');
    }
    set defaultValuePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'defaultValue');
    }
    get defaultValuePrimitiveValue():string {
        return this.primitiveValueForKey('defaultValue');
    }
}
