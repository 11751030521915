class LicenseLogCell extends UITableViewCell
{
    private dateLabel:MUILabel = null;
    private userNameLabel:MUILabel = null;
    private messageLabel:MUILabel = null;

    awakeFromHTML(): void {
        this.dateLabel = MUIOutlet( this, "date-lbl", "MUILabel" );
        this.userNameLabel = MUIOutlet( this, "name-lbl", "MUILabel" );
        this.messageLabel = MUIOutlet( this, "user-comment-lbl", "MUILabel" );
        
        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    set item( item:BusinessLog ) {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        this.dateLabel.text = ad.dateTimeFormatter.stringFromDate( item.date );
        this.userNameLabel.text = item.userName;
        this.messageLabel.text = item.message;
    }
}