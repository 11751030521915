
class LogCell extends MUITableViewCell
{
    private logLevelIcon:MUIView = null;
    private dateLabel:MUILabel = null;
    private appNameLabel:MUILabel = null;    
    private typeLabel:MUILabel = null;
    private subTypeLabel:MUILabel = null;
    private conceptLabel:MUILabel = null;
    private workerNameLabel:MUILabel = null;

    private locationNameLabel:MUILabel = null;
    private orderIDLabel:MUILabel = null;
    private ticketIDLabel:MUILabel = null;
    private ticketLegalIDLabel:MUILabel = null;

    private destinationLocationNameLabel:MUILabel = null;
    private destinationOrderIDLabel:MUILabel = null;
    private destinationTicketIDLabel:MUILabel = null;
    
    private totalDeletedLabel:MUILabel = null;
    private totalInvitedLabel:MUILabel = null;
    private totalPrice:MUILabel = null;

    awakeFromHTML(){
        this.logLevelIcon = MUIOutlet(this, "log-level-icon", "MUIView");
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");
        this.appNameLabel = MUIOutlet(this, "app-lbl", "MUILabel");
        this.typeLabel = MUIOutlet(this, "type-lbl", "MUILabel");
        this.subTypeLabel = MUIOutlet(this, "subtype-lbl", "MUILabel");
        this.conceptLabel = MUIOutlet(this, "concept-lbl", "MUILabel");
        this.workerNameLabel = MUIOutlet(this, "worker-name-lbl", "MUILabel");
        this.locationNameLabel = MUIOutlet(this, "location-name-lbl", "MUILabel");
        this.orderIDLabel = MUIOutlet(this, "order-id-lbl", "MUILabel");
        this.ticketIDLabel = MUIOutlet(this, "ticket-id-lbl", "MUILabel");
        this.ticketLegalIDLabel = MUIOutlet(this, "ticket-legal-id-lbl", "MUILabel");
        this.destinationLocationNameLabel = MUIOutlet(this, "dst-location-name-lbl", "MUILabel");
        this.destinationOrderIDLabel = MUIOutlet(this, "dst-order-id-lbl", "MUILabel");
        this.destinationTicketIDLabel = MUIOutlet(this, "dst-ticket-id-lbl", "MUILabel");
        this.totalDeletedLabel = MUIOutlet(this, "total-deleted-lbl", "MUILabel");
        this.totalInvitedLabel = MUIOutlet(this, "total-invited-lbl", "MUILabel");
        this.totalPrice = MUIOutlet(this, "total-price-lbl", "MUILabel");

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    set item(item:LogOperation){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        
        this.updateLogLevelIcon(item.logLevel);
        this.dateLabel.text = ad.serverDateTimeFormatter.stringFromDate(item.date);
        if (this.appNameLabel != null) this.appNameLabel.text = item.appName;
        if (this.typeLabel != null) this.typeLabel.text = item.typeString;
        if (this.subTypeLabel != null)this.subTypeLabel.text = item.subTypeString;
        this.conceptLabel.text = item.concept;
        if (item.employeeName != null) {
            this.workerNameLabel.text = item.employeeName;
            MUICoreLayerRemoveStyle(this.workerNameLabel.layer, "light");
        }
        else {
            this.workerNameLabel.text = "SYSTEM";
            MUICoreLayerAddStyle(this.workerNameLabel.layer, "light");
        }
        
        if (this.locationNameLabel != null) this.locationNameLabel.text = item.locationName;
        if (this.orderIDLabel != null) this.orderIDLabel.text = item.orderID;
        if (this.ticketIDLabel != null) this.ticketIDLabel.text = item.ticketID;
        if (this.ticketLegalIDLabel != null) this.ticketLegalIDLabel.text = item.ticketLegalID;
        if (this.destinationLocationNameLabel != null) this.destinationLocationNameLabel.text = item.destinationLocationName;
        if (this.destinationOrderIDLabel != null) this.destinationOrderIDLabel.text = item.destinationOrderID;
        if (this.destinationTicketIDLabel != null) this.destinationTicketIDLabel.text = item.destinationTicketID;
        if (this.totalDeletedLabel != null) this.totalDeletedLabel.text = ad.integerFormatter.stringFromNumber(item.totalDeleted);
        if (this.totalInvitedLabel != null) this.totalInvitedLabel.text = ad.integerFormatter.stringFromNumber(item.totalInvited);
        if (this.totalPrice != null) this.totalPrice.text = ad.currencyFormatter.stringFromNumber(item.totalPrice);
    }

    private updateLogLevelIcon(logLevel:LogLevelType){
        MUICoreLayerRemoveStyle(this.logLevelIcon.layer, "caution");
        MUICoreLayerRemoveStyle(this.logLevelIcon.layer, "alert");

        switch(logLevel){
            case LogLevelType.Warning:
            MUICoreLayerAddStyle(this.logLevelIcon.layer, "caution");
            break;

            case LogLevelType.Error:
            MUICoreLayerAddStyle(this.logLevelIcon.layer, "alert");
            break;
        }
    }
}