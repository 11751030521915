//
// Generated class StockTemplateItem
//

/// <reference path="StockTemplateItem_ManagedObject.ts" />

class StockTemplateItem extends StockTemplateItem_ManagedObject
{
    get typeString() {
        return MIOLocalizeString("StockTemplateItem","StockTemplateItem");
    }
}
