//
// Generated class SubTaxName
//

/// <reference path="SubTaxName_ManagedObject.ts" />

class SubTaxName extends SubTaxName_ManagedObject
{

}
