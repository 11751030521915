

/// <reference path="Intermediary.ts" />

// Generated class BookingSource_ManagedObject

class BookingSource_ManagedObject extends Intermediary
{

    // Property: feeFormat
    set feeFormat(value:string) {
        this.setValueForKey(value, 'feeFormat');
    }
    get feeFormat():string {
        return this.valueForKey('feeFormat');
    }
    set feeFormatPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'feeFormat');
    }
    get feeFormatPrimitiveValue():string {
        return this.primitiveValueForKey('feeFormat');
    }

    // Property: slots
    set slots(value:number) {
        this.setValueForKey(value, 'slots');
    }
    get slots():number {
        return this.valueForKey('slots');
    }
    set slotsPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'slots');
    }
    get slotsPrimitiveValue():number {
        return this.primitiveValueForKey('slots');
    }

    // Property: slotsMax
    set slotsMax(value:number) {
        this.setValueForKey(value, 'slotsMax');
    }
    get slotsMax():number {
        return this.valueForKey('slotsMax');
    }
    set slotsMaxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'slotsMax');
    }
    get slotsMaxPrimitiveValue():number {
        return this.primitiveValueForKey('slotsMax');
    }
}
