

class ReportSelectFilterCell extends MUITableViewCell
{
    delegate = null;
    
    private _filter = null;

    private titleLabel = null;
    private optionsComboBox = null;

    awakeFromHTML()
    {
        this.titleLabel = MUIOutlet(this, "rfv_c3_lbl", "MUILabel");
        this.optionsComboBox = MUIOutlet(this, "rfv_c3_cb", "MUIComboBox");
        this.optionsComboBox.setOnChangeAction(this, function(comboBox, value){

            if (this.delegate != null)
                this.delegate.didSelectOptionFromSelectFilterCell(this, value, this._filter);
        });
    }

    set filter(filter){

        this._filter = filter;
        this.titleLabel.text = filter["title"] + ":";

        // if (filter["multiple"] == true)
        //     this.optionsComboBox.setAllowMultipleSelection(true);

        var options = filter["valueOptions"];

        for (var index = 0; index < options.length; index++) {
            let opt = options[index];
            let name = opt["name"];
            let id = opt["id"];
            this.optionsComboBox.addItem(name, id);
        }        
    }
}