

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class InvitationRule_ManagedObject

class InvitationRule_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: fromDate
    set fromDate(value:Date) {
        this.setValueForKey(value, 'fromDate');
    }
    get fromDate():Date {
        return this.valueForKey('fromDate');
    }
    set fromDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'fromDate');
    }
    get fromDatePrimitiveValue():Date {
        return this.primitiveValueForKey('fromDate');
    }

    // Property: maxInvitationValue
    set maxInvitationValue(value:number) {
        this.setValueForKey(value, 'maxInvitationValue');
    }
    get maxInvitationValue():number {
        return this.valueForKey('maxInvitationValue');
    }
    set maxInvitationValuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'maxInvitationValue');
    }
    get maxInvitationValuePrimitiveValue():number {
        return this.primitiveValueForKey('maxInvitationValue');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: toDate
    set toDate(value:Date) {
        this.setValueForKey(value, 'toDate');
    }
    get toDate():Date {
        return this.valueForKey('toDate');
    }
    set toDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'toDate');
    }
    get toDatePrimitiveValue():Date {
        return this.primitiveValueForKey('toDate');
    }
    // Relationship: invitationRulePreset
    set invitationRulePreset(value:InvitationRulePreset) {
        this.setValueForKey(value, 'invitationRulePreset');
    }
    get invitationRulePreset():InvitationRulePreset {
        return this.valueForKey('invitationRulePreset') as InvitationRulePreset;
    }
}
