

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class AccountingType_ManagedObject

class AccountingType_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: prefix
    set prefix(value:string) {
        this.setValueForKey(value, 'prefix');
    }
    get prefix():string {
        return this.valueForKey('prefix');
    }
    set prefixPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'prefix');
    }
    get prefixPrimitiveValue():string {
        return this.primitiveValueForKey('prefix');
    }

    // Property: scope
    set scope(value:number) {
        this.setValueForKey(value, 'scope');
    }
    get scope():number {
        return this.valueForKey('scope');
    }
    set scopePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'scope');
    }
    get scopePrimitiveValue():number {
        return this.primitiveValueForKey('scope');
    }
}
