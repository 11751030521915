class LicenseDetailCell extends UITableViewCell
{
    private deviceNameLabel:MUILabel = null;
    private productLabel:MUILabel = null;    
    private activeLabel:MUILabel = null;
    private typeLabel:MUILabel = null;
    private priceLabel:MUILabel = null;
    private discountLabel:MUILabel = null; 
    private totalLabel:MUILabel = null;

    private nextDateLabel:MUILabel = null;
        
    private dtf:MIODateFormatter = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter;

    awakeFromHTML(){
        this.deviceNameLabel = MUIOutlet(this, "device-name-lbl", "MUILabel");
        this.productLabel = MUIOutlet(this, "product-lbl", "MUILabel");
        this.activeLabel = MUIOutlet(this, "active-lbl", "MUILabel");
        this.typeLabel = MUIOutlet(this, "type-lbl", "MUILabel");
        this.priceLabel = MUIOutlet( this, "price-lbl", "MUILabel");
        this.discountLabel = MUIOutlet(this, "discount-lbl", "MUILabel");
        this.totalLabel = MUIOutlet( this, "total-lbl", "MUILabel");

        this.nextDateLabel = MUIOutlet( this, "renewal-date-lbl", "MUILabel" );
    }

    set item(i:License) {
        this.deviceNameLabel.text = i.applicationName ?? MIOLocalizeString("UNKOWN", "UNKOWN");
        this.typeLabel.text = i.type?.name ?? "UNKNOWN";
        this.activeLabel.text = i.active ? "YES" : "NO";

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this.productLabel.text = i.product?.name ?? "UNKNOWN";
        this.priceLabel.text = ad.currencyFormatter.stringFromNumber( i.price );        
        if (i.discountFormat != null) this.discountLabel.text = i.discountFormat;
        else if (i.discountValue > 0) this.discountLabel.text = -i.discountValue;
        // if (i.discountFormat != null) {
        //     let d = ad.percentNumberFormatter.numberFromString( i.discountFormat );
        //     this.discountLabel.text = ad.percentNumberFormatter.stringFromNumber( d );
        // }
        this.totalLabel.text = ad.currencyFormatter.stringFromNumber( i.total );

        this.nextDateLabel.text = i.nextRenewalDate == null ? "-" : ad.dateFormatter.stringFromDate( i.nextRenewalDate );
    }
}