//
// Generated class EventTicket
//

/// <reference path="EventTicket_ManagedObject.ts" />

class EventTicket extends EventTicket_ManagedObject
{

}
