

class ReportResultsViewController extends MUIViewController {
    
    private backButton: MUIButton = null;
    private backSplitViewButton: MUIButton = null;
    private titleLabel:MUILabel = null;
    private dateLabel:MUILabel = null;
    private calendarButton:MUIButton = null;
    private filterButton:MUIButton = null;
    private exportButton:MUIButton = null;

    private reportTableView: MUIReportTableView = null;
        
    filterValues = null;
    backButtonHidden = false;
    backSplitButtonHidden = true;
    
    columns = [];
    columnSubtotalsIndexes = null;

    private items = [];
    private filterItems = null;
    private totals = {};

    private ad: AppDelegate = MUIWebApplication.sharedInstance().delegate;
    private filterController:ColumnFilterController = null;    

    viewDidLoad() {
        super.viewDidLoad();

        this.filterController = new ColumnFilterController();
        this.filterController.initWithDelegate(this);

        this.backButton = MUIOutlet(this, 'back-btn', 'MUIButton');
        this.backButton.setAction(this, function () {
            this.navigationController.popViewController(true);
        });                

        this.backSplitViewButton = MUIOutlet(this, "back-sv-btn", "MUIButton");
        this.backSplitViewButton.setAction(this, function(){
            let sc = this.parentViewController.splitViewController;
            if (sc != null) {
                let target = sc.displayModeButtonItem.target;
                let action = sc.displayModeButtonItem.action;            
                action.call(target);
            }
        });

        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");

        this.filterButton = MUIOutlet(this, 'filter-btn', 'MUIButton');
        this.filterButton.setAction(this, function () {
            this.showFilters();
        });

        this.exportButton = MUIOutlet(this, 'export-btn', 'MUIButton');
        this.exportButton.setAction(this, function(){
            this.export();
        });

        this.reportTableView = MUIOutlet(this, 'report-table-view', 'MUIReportTableView');
        this.reportTableView.dataSource = this;
        this.reportTableView.delegate = this;

        if (this.columns.length > 0) {
            for (let col = 0; col < this.columns.length; col++) {
                let c = this.columns[col];
                this.addColumnToReportView(c);
            }
        }
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _report: ReportItem = null;
    get report():ReportItem {
        return this._report;
    }
    set report(value:ReportItem){
        this._report = value;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false ||this.report == null) return;
        this.backButton.hidden = this.backButtonHidden;  
        this.backSplitViewButton.hidden = this.backSplitButtonHidden;
        this.exportButton.hidden = MIOCoreIsPhone()? true : false; 
        this.updateTitle();      
        this.updateReport();
    }

    private _updateReport = true;
    private updateReport(){
        if (this._updateReport == false) return;
        this._updateReport = false;
        this.executeQuery();
    }

    private updateTitle(){
        let title = this.report.title;
        this.titleLabel.text = MIOLocalizeString(title.toUpperCase(), title);

        let df = MUIWebApplication.sharedInstance().delegate.dateFormatter as MIODateFormatter;
        let rh = ReportHelper.sharedInstance();
        let from = df.stringFromDate(rh.selectedDateTimeFrom);
        let to = df.stringFromDate(rh.selectedDateTimeTo);
        if (from == to) {
            this.dateLabel.text = from;
        }
        else {
            this.dateLabel.text = from + " - " + to;
        }
    }

    // SETUP
    addColumn(column:MUIReportTableViewColumn){
        if (column == null) return;
        this.columns.push(column);
        
        this.addColumnToReportView(column);
    }

    private addColumnToReportView(column:MUIReportTableViewColumn){        
        if (this.reportTableView == null) return;
        this.reportTableView.addColumn(column);
        let colFilter = column.columnHeaderView() as ReportColumnHeader;
        colFilter.columnFilterTextField.filterController = this.filterController;
        let type = this.filterTypeFromColumnType(column.type);
        colFilter.columnFilterTextField.setOnFilterChange(type, column.serverName, null, null);    
        colFilter.setAction(this, this.sortDescriptorsDidChange, column);
    }

    private filterTypeFromColumnType(colType:MUIReportTableViewColumnType) {
        switch (colType) {
            case MUIReportTableViewColumnType.Number:
            return ColumnFilterTextFieldType.Number;

            case MUIReportTableViewColumnType.Date:
            return ColumnFilterTextFieldType.Date;
        }

        return ColumnFilterTextFieldType.String;
    }

    numberOfRows(reportTableView: MUIReportTableView) {
        if (this.filterItems == null) return 0;

        let count = this.filterItems.length;
        if (this.columnSubtotalsIndexes != null) count++;

        return count;
    }

    cellAtIndexPath(reportTableView: MUIReportTableView, column: MUIReportTableViewColumn, indexPath: MIOIndexPath) {
        let cell = reportTableView.dequeueReusableCellWithIdentifier("TitleCell") as ReportResultsCell;
        let item = null;
        if (indexPath.row < this.filterItems.length) {
            item = this.filterItems[indexPath.row];
            cell.bold = false;
        }
        else {
            item = this.totals;
            cell.bold = true;
        }
        this.configureCellAtIndexPath(cell, column, indexPath, item);
        return cell;
    }

    configureCellAtIndexPath(cell: ReportResultsCell, column: MUIReportTableViewColumn, indexPath: MIOIndexPath, item) {

        let text = "";
        if (column.serverName != null) {
            text = this.textAtIndexPath(column, indexPath, item);
            if (column.formatter != null) {
                if (column.formatter instanceof MIODateFormatter) {
                    let value = this.ad.serverDateTimeFormatter.dateFromString(text);
                    text = column.formatter.stringForObjectValue(value);
                }
                else {
                    text = column.formatter.stringForObjectValue(text);
                }
            }
        }

        cell.title = text;
        cell.titleAligment = column.alignment;
    }

    textAtIndexPath(column: MUIReportTableViewColumn, indexPath: MIOIndexPath, item) {

        let value = item[column.serverName];

        if (column.formatter instanceof MIONumberFormatter) {
            value = ReportItem.parseValue(ReportItemValueType.Float, value);
        }
        else if (column.formatter instanceof MIODateFormatter) {
            value = ReportItem.parseValue(ReportItemValueType.Date, value);
        }        

        return value;
    }

    didSelectCellAtIndexPath(reportTableView, indexPath:MIOIndexPath) {
        
        if (this.report.nextReportID != null) {

            let report = ReportHelper.sharedInstance().listReportByID[this.report.nextReportID];    
            if (report == null) return;
    
            let columns = report.columns;
            if (columns.length == 0) return null;
    
            let rvc = new ReportResultsViewController("report-results-view");
            rvc.initWithResource("layout/reports/ReportResultsView.html");
            rvc.backButtonHidden = false;
        
            rvc.report = report;
            
            if (this.report.nextReportFilterKeys != null) {
                // Set where class
                let item = this.filterItems[indexPath.row];

                let filterValues = {};

                for (let index = 0; index < this.report.nextReportFilterKeys.length; index++){                    
                    let key = this.report.nextReportFilterKeys[index];
                    if (key == "session_begindate" || key == "session_enddate") continue;                    
                    let value = item[key] ;
                    filterValues[key] = value;
                }
                rvc.filterValues = filterValues;
            }
            
            for (let index = 0; index < columns.length; index++) {
                let col = columns[index];
                rvc.addColumn(col);
            }
    
            rvc.columnSubtotalsIndexes = report.subtotals;

            this.navigationController.pushViewController(rvc, true);
        }
    }

    sortDescriptorsDidChange(column: MUIReportTableViewColumn) {

        if (column.serverName == null) return;

        if (column.ascending == true) {
            column.ascending = false;
            this.filterItems = _MIOSortDescriptorSortObjects(this.filterItems, [MIOSortDescriptor.sortDescriptorWithKey(column.serverName, true)]);
        }
        else {
            column.ascending = true;
            this.filterItems = _MIOSortDescriptorSortObjects(this.filterItems, [MIOSortDescriptor.sortDescriptorWithKey(column.serverName, false)]);
        }

        this.reportTableView.reloadData();
    }

    filterPredicateDidChange(controller:ColumnFilterController){
        let filterPredicateFormat = this.filterController.filterPredicateFormat();
        if (filterPredicateFormat != null) {
            this.filterItems = _MIOPredicateFilterObjects(this.items, MIOPredicate.predicateWithFormat(filterPredicateFormat));
        }
        else {
            this.filterItems = this.items;
        }
        
        this.calculateTotals();
        this.reportTableView.reloadData();  
    }

    //
    // Query
    //

    protected executeQuery() {

        //this.loadingView.setHidden(false);
        ReportHelper.sharedInstance().executeReportQuery(this.report, this.filterValues, this, function (code, items) {

            if (code == 200) {
                this.items = items;
                if (this.report.scriptID != null && items.length > 0) {
                    this.items = items[0]["Rows"];
                }
                                
                this.parseValues(this.items);
                this.filterItems = this.items;
                this.calculateTotals();
                this.reportTableView.reloadData();
            }
            //this.loadingView.setHidden(true);            
        });
    }

    // TODO: Change that in the server side!!!
    private parseValues(items){       
        if (items == null) return; 
        for (let index = 0; index < items.length; index++){
            let item = items[index];
            for (let colIndex = 0; colIndex < this.columns.length; colIndex++){
                let col = this.columns[colIndex];

                switch(col.formatterString){
                    case "integer":
                    item[col.serverName] = ReportItem.parseValue(ReportItemValueType.Integer, item[col.serverName]);
                    break;

                    case "number":
                    case "float":
                    case "currency":
                    item[col.serverName] = ReportItem.parseValue(ReportItemValueType.Float, item[col.serverName]);
                    break;

                    default:
                    item[col.serverName] = ReportItem.parseValue(ReportItemValueType.String, item[col.serverName]);
                    break;
                }                
            }   
        }
    }

    calculateTotals() {        

        let indexes = this.columnSubtotalsIndexes;
        if (indexes == null) return;

        this.totals = {};

        if (this.filterItems == null) return;        

        for (let row = 0; row < this.filterItems.length; row++) {
            for (let col = 0; col < indexes.length; col++) {

                let index = indexes[col];
                let column = this.reportTableView.columns[index];
                if (column == null) { MIOLog("ReportResultsViewController: Wrong total column index"); continue; }
                let item = this.filterItems[row];

                let total = parseFloat(this.totals[column.serverName]);
                if (isNaN(total)) total = 0;
                let value = parseFloat(item[column.serverName]);
                if (isNaN(value)) value = 0;
                let t = total + value;
                this.totals[column.serverName] = t;
            }
        }

        let firstCol = this.reportTableView.columns[0];
        if (this.totals[firstCol.serverName] == null) this.totals[firstCol.serverName] = MIOLocalizeString("TOTAL", "TOTAL");
    }

    // columnSubtotalsIndexes() {
    //     return null;
    // }

    export() {

        let ad: AppDelegate = MUIWebApplication.sharedInstance().delegate;
        let ws: WebService = ad.webService;

        let data = [];
        for (let row = 0; row < this.filterItems.length; row++) {
            let i = {};
            for (let col = 0; col < this.reportTableView.columns.length; col++) {
                let column: MUIReportTableViewColumn = this.reportTableView.columns[col];
                let item = this.filterItems[row];
                let ip = MIOIndexPath.indexForColumnInRowAndSection(col, row, 0);
                let value = this.textAtIndexPath(column, ip, item);
                i[column.title] = value;
            }
            data.push(i);
        }

        let body = { "filename": "Report.xls", "data": data };
        ws.exportFile(ad.selectedIdentifier, ad.selectedIdentifierType, body, this, function (code, json) {
            if (code == 200) {

            }
        });
    }

    protected splitInSectionsByKey(sectionKey: string, formatter?:MIOFormatter) {

        if (sectionKey == null) {
            this.filterItems = this.items;
            this.reportTableView.reloadData();
            return;
        }

        let filterItems = [];

        let sectionItem = null;
        let currentSectionKeyPathValue = null;
        for (let index = 0; index < this.items.length; index++) {
            let item = this.items[index];
            let value = item[sectionKey];
            if (formatter != null) {
                value = formatter.stringForObjectValue(value);
            }

            if (currentSectionKeyPathValue != value) {
                if (sectionItem != null) {
                    filterItems.push(sectionItem);
                }
                sectionItem = {};
                currentSectionKeyPathValue = value;
            }
            
            this.addItemValues2SectionItem(item, sectionItem);
            filterItems.push(item);
        }

        if (sectionItem != null) {
            filterItems.push(sectionItem);
        }

        this.filterItems = filterItems;
        this.reportTableView.reloadData();
    }

    addItemValues2SectionItem(item, sectionItem) {

        let indexes = this.columnSubtotalsIndexes;
        if (indexes == null) return;

        for (let col = 0; col < indexes.length; col++) {

            let index = indexes[col];
            let column = this.reportTableView.columns[index];            

            let total = parseFloat(sectionItem[column.serverName]);
            if (isNaN(total)) total = 0;
            let value = parseFloat(item[column.serverName]);
            if (isNaN(value)) value = 0;
            let t = total + value;
            sectionItem[column.serverName] = t;
        }
    }

    private showFilters(){
        AppHelper.sharedInstance().presentDateSelectionViewController(this);
    }

    datesDidSelect(dateTimeFrom, dateTimeTo) {
        let rh = ReportHelper.sharedInstance();
        rh.selectedDateTimeFrom = dateTimeFrom;
        rh.selectedDateTimeTo = dateTimeTo;   
        this.updateTitle();     
        this.executeQuery();
    }

}
