

/// <reference path="AccountBalance.ts" />

// Generated class CashDeskSessionBalance_ManagedObject

class CashDeskSessionBalance_ManagedObject extends AccountBalance
{

    // Property: collectedAmount
    set collectedAmount(value:number) {
        this.setValueForKey(value, 'collectedAmount');
    }
    get collectedAmount():number {
        return this.valueForKey('collectedAmount');
    }
    set collectedAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'collectedAmount');
    }
    get collectedAmountPrimitiveValue():number {
        return this.primitiveValueForKey('collectedAmount');
    }

    // Property: salesAmount
    set salesAmount(value:number) {
        this.setValueForKey(value, 'salesAmount');
    }
    get salesAmount():number {
        return this.valueForKey('salesAmount');
    }
    set salesAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'salesAmount');
    }
    get salesAmountPrimitiveValue():number {
        return this.primitiveValueForKey('salesAmount');
    }
}
