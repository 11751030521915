/// <reference path="../notes/NoteLineCell.ts" />


class DeliveryNoteLineCell extends NoteLineCell
{
    private orderedQuantityLabel:MUILabel = null;
    
    awakeFromHTML(){
        super.awakeFromHTML();

        this.orderedQuantityLabel = MUIOutlet(this, "ordered-quantity-lbl", "MUILabel");
    }
    
    setLine(item:StockNoteLine){
        super.setLine(item);    
        
        this.orderedQuantityLabel.text = item.estimatedQuantity ? this.nf.stringFromNumber(item.quantity) : null;
    }

    productDidSelect(controller:SelectEntityViewController, product:Product, supplierProduct:SupplierProduct){
        super.productDidSelect(controller, product, supplierProduct);

        this.productQuantityLabel.text = this.productQuantityString();
    }
    enterDidPress(){
        if (this.stockNoteLine != null) return;        
        if (this.quantity == 0 || this.quantity == null) return;
                
        let line = DBHelper.createDeliveryNoteLine(this.product, this.tax, this.inputFormat, this.inputType, this.quantity, this.productQuantity, this.priceAmount, this.productNetPriceAmount, this.totalAmount, this.discountFormat, this.discountAmount, this.warehouse, this.stockNote);
        if (line != null) {
            DBHelper.saveMainContext();
            this.reset();
        }
    }
    
    reset(){
        super.reset();
        
        // this.orderedQuantityLabel.text = null;
    }
}