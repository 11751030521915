/**
 * Created by miguel on 23/2/17.
 */



class OfferCodeCell extends UITableViewCell
{
    private codeTextField:MUITextField = null;
    private beginDateLabel:MUITextField = null;
    private endDateLabel:MUITextField = null;
    private onButton:MUISwitchButton = null;    
    //private statusView:MUIView = null;    

    delegate = null;

    awakeFromHTML(){
        this.codeTextField = MUIOutlet(this, 'code-tf', 'MUITextField');
        this.beginDateLabel = MUIOutlet(this, 'begin-date-tf', 'MUITextField');
        this.endDateLabel = MUIOutlet(this, 'end-date-tf', 'MUITextField');
        this.onButton = MUIOutlet(this, "on-btn", "MUISwitchButton");
        // this.statusView = MUIOutlet(this, "status-view", "MUIView");

        this.separatorStyle = UITableViewCellSeparatorStyle.None;
    }

    private _item:PromotionalCode = null;
    set item(i:PromotionalCode){
        this._item = i;
        this.codeTextField.text = i.code;
        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        this.beginDateLabel.text = ad.dateTimeFormatter.stringFromDate(i.beginDateTime);
        this.endDateLabel.text = ad.dateTimeFormatter.stringFromDate(i.endDateTime);
        
        // if (i.usedDate != null) {
        //     this.onButton.on = true;
        //     //MUICoreLayerAddStyle(this.statusView.layer, "green");
        // }
        // else {
        //     this.onButton.on = false;
        //     //MUICoreLayerRemoveStyle(this.statusView.layer, "green");
        // }

    }
}