


enum ReportQueryRecordType {
    String,
    Integer,
    Float,
    Currency,    
    Date
}

class ReportQueryRecord extends MIOObject
{
    type = ReportQueryRecordType.String;
    identifier:string = null;

    static recordWithIdentifier(identifier:string, type?:ReportQueryRecordType) : ReportQueryRecord {

        var r = new ReportQueryRecord();
        r.initWithIdentifier(identifier, type);

        return r;
    }

    initWithIdentifier(identifier:string, type?:ReportQueryRecordType){
        super.init();
        
        this.identifier = identifier;
        if (type != null) this.type = type;
    }

    parseValue(value){

        var v = value;

        switch (this.type){

            case ReportQueryRecordType.Integer:
                v = parseInt(value);
                break;

            case ReportQueryRecordType.Float:
            case ReportQueryRecordType.Currency:
                v = parseFloat(value);
                break;

            case ReportQueryRecordType.Date:
                v = MIODateFromString(value);
                break;
        }

        return v;
    }
}