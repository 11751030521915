

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class PriceGroup_ManagedObject

class PriceGroup_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Relationship: configurations
    protected _configurations:MIOManagedObjectSet = null;
    get configurations():MIOManagedObjectSet {
        return this.valueForKey('configurations');
    }
    addConfigurationsObject(value:PriceGroupConfiguration) {
        this._addObjectForKey(value, 'configurations');
    }
    removeConfigurationsObject(value:PriceGroupConfiguration) {
        this._removeObjectForKey(value, 'configurations');
    }
}
