//
// Generated class BookingPrescriptor
//

/// <reference path="BookingPrescriptor_ManagedObject.ts" />

class BookingPrescriptor extends BookingPrescriptor_ManagedObject
{

}
