

class SelectViewController extends MUIViewController {

    delegate = null;
    allowMultipleSelection = false;
    delayTimer = null;
        
    protected doneButton:MUIButton = null;
    protected searchTextField:MUITextField = null;
    protected tableView:MUITableView = null;

    protected searchString:string = null;
    protected _fetchedResultsController:MIOFetchedResultsController = null;

    get preferredContentSize(){
        return new MIOSize(320, 500);
    }

    viewDidLoad() {
        super.viewDidLoad();

        this.doneButton = MUIOutlet(this, "done-btn", "MUIButton");
        this.doneButton.setAction(this, this.doneAction);

        this.searchTextField = MUIOutlet(this, "sv_search_bar", "MUITextField");
        this.searchTextField.setOnChangeText(this, function(textfield, value)
        {
            // clearTimeout(this.delayTimer);
            // var instance = this;
        
            // instance.delayTimer = setTimeout(function() {
            //     instance.searchString = instance.searchTextField.text;
            //     instance.downloadData();
            // }, 500, instance);
            
        });
        
        this.tableView = MUIOutlet(this, "sv_tableview", "MUITableView");
        
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.downloadData();
    }

    numberOfSections(tableview) {
        
        if (this.fetchedResultsController == null) return 0;
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section) {
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath) {
        
        let cell = tableview.dequeueReusableCellWithIdentifier('SelectCell');
        
        this.configureSelectCellAtIndexPath(cell, indexPath);

        return cell;
    }

    // TO Override
    protected configureSelectCellAtIndexPath(cell, indexPath:MIOIndexPath) {

    }
    
    //TO Override
    protected downloadData() {

    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath) {
    
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);

        if (this.delegate != null)
            this.delegate.didSelectItemFromSelectViewController(this, item);

        this.dismissViewController(true);
    }

    resetResultController(value)
    {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    set fetchedResultsController(value) {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    // TO Override    
    get fetchedResultsController() {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
        
        return this._fetchedResultsController;
    }

    // To override
    protected doneAction(){}
}
