
class ClientAccountingViewController extends MUIViewController
{
    static newInstance() : ClientAccountingViewController
    {
        let vc = new ClientAccountingViewController("client-accounting-view");
        vc.initWithResource("layout/clients/ClientAccountingView.html");
        return vc;
    }

    private salesLedgerAccountDropdown:MUIButton = null;
    private purchaseLedgerAccountDropdown:MUIButton = null;
    //private accountNumberTextField:MUITextField = null;

    private addBankInfoButton:MUIButton = null;
    private bankInfoTable:UITableView = null;
    
    private defaultBankAccountDropdown:MUIButton = null;    
    private defaultRateDropdown:MUIButton = null;
    private defaultPaymentDropdown:MUIButton = null;
    private vatType:MUIComboBox = null;

    private bankInfoDataSource:ClientBankInfoDataSource = null;

    viewDidLoad(): void {

        this.salesLedgerAccountDropdown = MUIOutlet(this, "sales-ledger-account-dd", "MUIButton");
        this.salesLedgerAccountDropdown.setAction(this, function(this:ClientAccountingViewController){
            AppHelper.sharedInstance().showSelectLedgerAccountViewControllerFromView(this.salesLedgerAccountDropdown, this._client.salesLedgerAccount, null, false, this._client.name, this, this.updateSalesLedgerAccount);
        });

        this.purchaseLedgerAccountDropdown = MUIOutlet(this, "purchase-ledger-account-dd", "MUIButton");
        this.purchaseLedgerAccountDropdown.setAction(this, function(this:ClientAccountingViewController){
            AppHelper.sharedInstance().showSelectLedgerAccountViewControllerFromView(this.purchaseLedgerAccountDropdown, this._client.purchaseLedgerAccount, null, false, this._client.name, this, this.updatePurchaseLedgerAccount);
        });

        // this.accountNumberTextField = MUIOutlet(this, "account-number-tf", "MUITextField");
        // this.accountNumberTextField.setOnChangeText(this, function(this:ClientAccountingViewController, control:MUITextField, value:string){
        //     let v = value.trim().length > 0 ? value : null;
        //     this._client.accountNumber = v;
        // });
        
        this.defaultBankAccountDropdown = MUIOutlet(this, "default-bank-dd", "MUIButton");
        this.defaultBankAccountDropdown.setAction(this, function(this:ClientAccountingViewController){
            AppHelper.sharedInstance().showSelectBankInfoViewControllerFromView(this.defaultBankAccountDropdown, this._client, this, function(this:ClientAccountingViewController, controller:any, object:LegalEntityBankAccountInfo){
                this._client.defaultBankAccount = object;
                this._client.defaultBankAccountName = object?.title;
                this.defaultBankAccountDropdown.title = object?.title;
            });
        });

        this.defaultRateDropdown = MUIOutlet(this, "default-rate-dd", "MUIButton");
        this.defaultRateDropdown.setAction(this, function(this:ClientAccountingViewController) {
            AppHelper.sharedInstance().showSelectRateViewControllerFromView(this.defaultRateDropdown, this._client.defaultRate, this, function(this:ClientAccountingViewController, controller:any, object:Rate){
                this._client.defaultRate = object;
                this._client.defaultRateName = object?.name;
                this.defaultRateDropdown.title = object?.name;
            });
        });

        this.defaultPaymentDropdown = MUIOutlet(this, "default-payment-dd", "MUIButton");
        this.defaultPaymentDropdown.setAction(this, function(this:ClientAccountingViewController) {
            AppHelper.sharedInstance().showSelectPaymethodViewControllerFromView(this.defaultPaymentDropdown, this._client.defaultPayMethod, this, function(this:ClientAccountingViewController, controller:any, object:PayMethod){
                this._client.defaultPayMethod = object;
                this._client.defaultPayMethodName = object?.name;
                this.defaultPaymentDropdown.title = object?.name;
            });
        });


        this.vatType = MUIOutlet( this, "vat-type-cb", "MUIComboBox" );
        this.vatType.setOnChangeAction( this, function(this:ClientAccountingViewController, control:MUIComboBox, value:string) {
            this._client.taxRegimeType = parseInt( value );
        });

        this.addBankInfoButton = MUIOutlet(this, "bank-account-add-btn", "MUIButton");
        this.addBankInfoButton.setAction( this, this.addBankAccountAction);

        this.bankInfoTable = MUIOutlet(this, "bank-accounts-table-view", "UITableView");
        this.bankInfoDataSource = new ClientBankInfoDataSource();
        this.bankInfoDataSource.initWithTableView( this.bankInfoTable );

        this.bankInfoTable.dataSource = this.bankInfoDataSource;
        this.bankInfoTable.delegate = this.bankInfoDataSource;
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _client:Client = null;
    set client(client:Client){
        this._client = client;
        this.updateUI();
    }
    
    private updateUI(){
        if (this.viewIsLoaded == false || this._client == null) return;
        
        this.bankInfoDataSource.client = this._client;

        this.salesLedgerAccountDropdown.title = this._client.salesLedgerAccountName;
        this.purchaseLedgerAccountDropdown.title = this._client.purchaseLedgerAccountName;
        // this.accountNumberTextField.text = this._client.accountNumber;

        this.defaultBankAccountDropdown.title = this._client.defaultBankAccount?.title;
        this.defaultRateDropdown.title = this._client.defaultRateName;
        this.defaultPaymentDropdown.title = this._client.defaultPayMethodName;

        this.vatType.selectItem( this._client.taxRegimeType );
    }

    private addBankAccountAction(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ACTIONS","ACTIONS"), MIOLocalizeString("CHOOSE AN ACTION", "CHOOSE AN ACTION"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler( this, function(control:MUITextField) {
            control.placeholderText = MIOLocalizeString("TITLE", "TITLE");  
        });

        avc.addTextFieldWithConfigurationHandler( this, function(control:MUITextField) {
            control.placeholderText = MIOLocalizeString("ACCOUNT", "ACCOUNT");  
        });

        avc.addTextFieldWithConfigurationHandler( this, function(control:MUITextField) {
            control.placeholderText = MIOLocalizeString("BANK CODE", "BANK CODE");  
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Cancel, this, function(this:ClientAccountingViewController){
            let title = avc.textFields[0].text;
            let account = avc.textFields[1].text;
            let code = avc.textFields[2].text;

            this.createBankAccount(title.trim(), account.trim(), code.trim());

        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        AppHelper.sharedInstance().presentViewController(avc, true);

    }

    private createBankAccount(title:string, account:string, code:string){
        if (title.length == 0 || account.length == 0 ) {
            AppHelper.showErrorMessage(this, "Error creating", "Title or account can not be empty");
            return;
        }

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let info = MIOEntityDescription.insertNewObjectForEntityForName( "LegalEntityBankAccountInfo", ad.managedObjectContext) as LegalEntityBankAccountInfo;
        info.title = title;
        info.iban = account;
        info.number = account;
        info.bankCode = code.length > 0 ? code : null;
        info.legalEntity = this._client;


        DBHelper.saveMainContext();
    }

    private updateSalesLedgerAccount(controller:SelectViewController, account:LedgerAccount){
        this._client.salesLedgerAccount = account;
        this._client.salesLedgerAccountName = account?.description;
        this.salesLedgerAccountDropdown.title = account?.description;
    }

    private updatePurchaseLedgerAccount(controller:SelectViewController, account:LedgerAccount){
        this._client.purchaseLedgerAccount = account;
        this._client.purchaseLedgerAccountName = account?.description;
        this.purchaseLedgerAccountDropdown.title = account?.description;
    }


}