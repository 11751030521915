
class WorkerLoginCell extends MUITableViewCell
{

    private dateLabel:MUILabel = null;
    private descLabel:MUILabel = null;


    awakeFromHTML(){
        this.descLabel = MUIOutlet(this, "desc-lbl", "MUILabel");
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");

        //need more here
        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    set item(item:LogOperation){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
      
        this.dateLabel.text =  ad.dateTimeFormatter.stringFromDate(item.date);
        this.descLabel.text = MIOLocalizeString(item.concept.toUpperCase(), item.concept);
    }

    
}