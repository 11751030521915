


class DistributorDetailViewController extends MUIViewController
{
    public static newInstance() : DistributorDetailViewController {
        let vc = new DistributorDetailViewController("distributor-detail-view");
        vc.initWithResource("layout/distributors/DistributorDetailView.html");
        return vc;
    }

    private debtFilterComboBox:MUIComboBox = null;
    private segmentedControl:MUISegmentedControl = null;    
    private saveButton:MUIButton = null;
    private optionsButton:MUIButton = null;    
    private sectionsPageController:MUIPageController = null;

    private infoViewController:DistributorInfoViewController = null;
    // private debtViewController:DistributorDebtViewController = null;
    // private ticketVC:ClientDocumentViewController = null;
    // private invoiceVC:ClientDocumentViewController = null;
    // private bookingsVC:ClientBookingsViewController = null;
    // private accountVC:ClientAccountViewController = null;
    // private loyaltyVC:ClientLoyaltyViewController = null;

    viewDidLoad(){
        super.viewDidLoad();
        
        this.debtFilterComboBox = MUIOutlet(this, "debt-filter-cb", "MUIComboBox");
        this.debtFilterComboBox.setHidden(true);
        this.debtFilterComboBox.setOnChangeAction(this, function(control, value){
            // this.debtVC.filter = parseInt(value);
        });
        
        //SEGMENTED BUTTON
        this.segmentedControl = MUIOutlet(this, 'segmented-ctl', 'MUISegmentedControl');
        this.segmentedControl.setAction(this, function (control:MUISegmentedControl, index:number) {
            this.sectionsPageController.showPageAtIndex(index);
            this.debtFilterComboBox.hidden = index == 1 ? false : true;
        });

        this.saveButton = MUIOutlet(this, 'save-btn','MUIButton');
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        });

        this.optionsButton = MUIOutlet(this, 'options-btn','MUIButton');
        //this.optionsButton.setHidden(true);
        this.optionsButton.setAction(this, this.showClientActions);
        
        this.sectionsPageController = MUIOutlet(this, 'page-controller', 'MUIPageController');
        this.infoViewController = DistributorInfoViewController.newInstance();        
        this.sectionsPageController.addPageViewController(this.infoViewController);
       
        // this.debtVC = new ClientDebtViewController("client-debt-view");
        // this.debtVC.initWithResource("layout/clients/ClientDebtView.html");
        // this.debtVC.navigationController = this.navigationController;
        // this.sectionsPageController.addPageViewController(this.debtVC);

        // this.ticketVC = new ClientDocumentViewController("client-document-view");
        // this.ticketVC.initWithResource("layout/clients/ClientDocumentView.html");
        // this.ticketVC.type = ClientDocumentViewControllerType.Tickets;
        // this.ticketVC.navigationController = this.navigationController;
        // this.sectionsPageController.addPageViewController(this.ticketVC);
       
        // this.invoiceVC = new ClientDocumentViewController("client-document-view");
        // this.invoiceVC.initWithResource("layout/clients/ClientDocumentView.html");
        // this.invoiceVC.type = ClientDocumentViewControllerType.Invoices;
        // this.invoiceVC.navigationController = this.navigationController;        
        // this.sectionsPageController.addPageViewController(this.invoiceVC);
       
        // this.accountVC = new ClientAccountViewController('client-account-view');
        // this.accountVC.initWithResource('layout/clients/ClientAccountView.html');
        // this.accountVC.navigationController = this.navigationController;  
        // this.sectionsPageController.addPageViewController(this.accountVC);         
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI(); 
    }

    private _distributor:Distributor = null;
    set distributor(value:Distributor){
        this._distributor = value;
        this.updateUI();
    }
     
    private updateUI(){
        if(this.viewIsLoaded == false || this._distributor == null) return;

        this.infoViewController.distributor = this._distributor;
        // this.debtVC.client = this._client;        
        // this.ticketVC.client = this._client;
        // this.invoiceVC.client = this._client;
        // this.accountVC.client = this._client;
     }

     private showClientActions(){

        // if (this.segmentedControl.selectedSegmentedIndex == 1){
        //     this.debtVC.showOptions();
        //     return;
        //  }
         
         let avc = new MUIAlertViewController();
         avc.initWithTitle(MIOLocalizeString('ACTIONS','ACITONS'), MIOLocalizeString('OPTIONS','OPTIONS'), MUIAlertViewStyle.Default);
         
         avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("DELETE", "DELETE"), MUIAlertActionStyle.Destructive, this, function(){            
             DBHelper.deleteObjectFromMainContext(this._distributor, true);
         }));
  
         avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "cancel"), MUIAlertActionStyle.Cancel, null, null));
 
         this.presentViewController(avc, true);

     }
     
}