

/// <reference path="StockTemplateItem.ts" />

// Generated class StockTemplateCategoryItem_ManagedObject

class StockTemplateCategoryItem_ManagedObject extends StockTemplateItem
{

    // Property: categoryName
    set categoryName(value:string) {
        this.setValueForKey(value, 'categoryName');
    }
    get categoryName():string {
        return this.valueForKey('categoryName');
    }
    set categoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'categoryName');
    }
    get categoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('categoryName');
    }
    // Relationship: category
    set category(value:ProductCategory) {
        this.setValueForKey(value, 'category');
    }
    get category():ProductCategory {
        return this.valueForKey('category') as ProductCategory;
    }
}
