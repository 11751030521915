//
// Generated class TaxName
//

/// <reference path="TaxName_ManagedObject.ts" />

class TaxName extends TaxName_ManagedObject
{

}
