//
// Generated class ExpenseReconcileTransaction
//

/// <reference path="ExpenseReconcileTransaction_ManagedObject.ts" />

class ExpenseReconcileTransaction extends ExpenseReconcileTransaction_ManagedObject
{

}
