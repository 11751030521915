
class LegalTaxTypeCell extends UITableViewCell
{
    private nameTextfield:MUITextField = null;
    private ledgerAccountButton:MUIButton = null;
    
    awakeFromHTML(){
        this.nameTextfield = MUIOutlet(this, 'name-tf', 'MUITextField');
        this.nameTextfield.setOnChangeText(this, function(textfield, value){
            this._legalTaxType.name = value.trim();
        });

        this.ledgerAccountButton = MUIOutlet(this, 'ledger-account-dd', 'MUIButton');
        this.ledgerAccountButton.setAction(this, function() {
            this.showSelectEntityViewController();
        }); 
    }
    
    private _legalTaxType:LegalTaxType = null;
    set item(i:LegalTaxType){
        this._legalTaxType = i;
        this.nameTextfield.text = i.name;
        this.ledgerAccountButton.title = (i.ledgerAccount) ? i.ledgerAccount.name : MIOLocalizeString('SELECT', 'SELECT');
    }

    private showSelectEntityViewController() {

        let predicateFormat = null;      
        let vc = AppHelper.sharedInstance().showCustomSelectViewControllerFromView(this.ledgerAccountButton, "LedgerAccount", "name", predicateFormat, this, function(controller, object:MIOManagedObject){

            if (object) {
                this.ledgerAccountButton.title = object.valueForKey('name');
                this._legalTaxType.ledgerAccount = object;
            } else {
                this.ledgerAccountButton.title = MIOLocalizeString("SELECT","SELECT");
                this._legalTaxType.ledgerAccount = null;
            }

        }, true );      
    }
}
