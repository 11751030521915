


class InventoryNoteSelectedViewController extends MUIViewController {

    private processButton:MUIButton = null;

    private selectedIds = [];

    private summaryView:InventoryNoteSummaryViewController = null;
    
    viewDidLoad()
    {
        super.viewDidLoad();

        this.processButton = MUIOutlet(this, 'isv_procces_btn','MUIButton');
        this.processButton.setAction(this, function()
        {
           if(!this.summaryView)
           {
               this.summaryView = new InventoryNoteSummaryViewController('inventory-summary-view');
               this.summaryView.initWithResource("layout/inventorynote/InventorySummaryView.html");
           }
           this.summaryView.selectedIds = this.selectedIds;
           this.navigationController.pushViewController(this.summaryView, true);
        });

        MIONotificationCenter.defaultCenter().addObserver(this, 'selectedInventoryNotesChanged', function (notification) {
           this.selectedIds = notification.object;
        });
    }

    
}