class ChangelogCell extends UITableViewCell
{
    private createdAtLabel:MUILabel = null;
    private actionLabel:MUILabel = null;
    private workerLabel:MUILabel = null;
    private entityTypeLabel:MUILabel = null;
    private entityIDLabel:MUILabel = null;
    private deltaLabel:MUILabel = null;

    awakeFromHTML() {
        this.createdAtLabel = MUIOutlet(this, "created-at-lbl", "MUILabel");
        this.actionLabel = MUIOutlet(this, "action-lbl", "MUILabel");
        this.workerLabel = MUIOutlet(this, "worker-lbl", "MUILabel");
        this.entityTypeLabel = MUIOutlet(this, "entity-type-lbl", "MUILabel");
        this.entityIDLabel = MUIOutlet(this, "entity-id-lbl", "MUILabel");
        this.deltaLabel = MUIOutlet( this, "delta-lbl", "MUILabel");        
    }

    set item(item:Changelog) {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        this.createdAtLabel.text = ad.dateTimeFormatter.stringFromDate(item.createdAt);
        this.actionLabel.text = item.action;
        this.workerLabel.text = ""; //item.worker not available in DataModel
        this.entityTypeLabel.text = item.entityType;
        this.entityIDLabel.text = item.entityID;
        this.deltaLabel.text = item.delta;
    }

}