
class BaseViewController extends MUIViewController
{
    protected sectionsButton:MUIButton = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.sectionsButton = MUIOutlet(this, "sections-btn", "MUIButton");
        if (this.sectionsButton != null) this.sectionsButton.setAction(this, function(){
            AppHelper.sharedInstance().presentSectionViewController(this);        
        });

        MIONotificationCenter.defaultCenter().addObserver(this, "WorkspaceDidChange", function(notification:MIONotification){
            this.workspaceDidChange();
        });
    }

    private viewIsVisible = false;
    viewWillAppear(animated?:boolean){
        super.viewWillAppear(animated);
        this.viewIsVisible = true;
        this.needsReloadData();
    }

    viewWillDisappear(animated?:boolean){
        super.viewWillDisappear(animated);
        this.viewIsVisible = false;
    }

    protected dataNeedsReload = true;
    protected invalidateData(){        
        this.invalidateFetch();        
    }

    protected invalidateFetch(){
        this.dataNeedsReload = true; 
        this.setFetchedResultsController(null);
        this.needsReloadData();
    }
        
    protected needsReloadData(){        
        if (this.viewIsVisible == false) return;
        if (this.dataNeedsReload == false) return;

        if (this.canReloadData()) {
            this.reloadData();
            this.dataNeedsReload = false;        
        }
        else {
            this.dataNeedsReload = true;        
        }
    }

    protected canReloadData(){
        return true;
    }

    protected reloadData(){}

    //
    // MIOFetchedResultsController delegate
    //

    protected _fetchedResultsController:MIOFetchedResultsController = null;
    setFetchedResultsController(value:MIOFetchedResultsController){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    set fetchedResultsController(value:MIOFetchedResultsController){
        this.setFetchedResultsController(value);
    }

    get fetchedResultsController() : MIOFetchedResultsController {
        return null;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        this.reloadData();
    }

    protected workspaceDidChange(){
        this.invalidateData();
    };
    

}