

class BookingLogDataCell extends MUITableViewCell
{
    keyLabel:MUILabel = null;
    valueLabel:MUILabel = null;

    awakeFromHTML(){
        this.keyLabel = MUIOutlet(this, "key-lbl", "MUILabel");
        this.valueLabel = MUIOutlet(this, "value-lbl", "MUILabel");
    }
}