//
// Generated class ArchivedInvoice
//

/// <reference path="ArchivedInvoice_ManagedObject.ts" />

class ArchivedInvoice extends ArchivedInvoice_ManagedObject
{

}
