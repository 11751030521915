//
// Generated class ClientLoyaltyBalance
//

/// <reference path="ClientLoyaltyBalance_ManagedObject.ts" />

class ClientLoyaltyBalance extends ClientLoyaltyBalance_ManagedObject
{

}
