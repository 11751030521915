

/// <reference path="PaymentTransaction.ts" />

// Generated class OnlineBookingPaymentTransaction_ManagedObject

class OnlineBookingPaymentTransaction_ManagedObject extends PaymentTransaction
{

    // Property: nextStatusOnFailure
    set nextStatusOnFailure(value:number) {
        this.setValueForKey(value, 'nextStatusOnFailure');
    }
    get nextStatusOnFailure():number {
        return this.valueForKey('nextStatusOnFailure');
    }
    set nextStatusOnFailurePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'nextStatusOnFailure');
    }
    get nextStatusOnFailurePrimitiveValue():number {
        return this.primitiveValueForKey('nextStatusOnFailure');
    }

    // Property: nextStatusOnSuccess
    set nextStatusOnSuccess(value:number) {
        this.setValueForKey(value, 'nextStatusOnSuccess');
    }
    get nextStatusOnSuccess():number {
        return this.valueForKey('nextStatusOnSuccess');
    }
    set nextStatusOnSuccessPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'nextStatusOnSuccess');
    }
    get nextStatusOnSuccessPrimitiveValue():number {
        return this.primitiveValueForKey('nextStatusOnSuccess');
    }
    // Relationship: booking
    set booking(value:Booking) {
        this.setValueForKey(value, 'booking');
    }
    get booking():Booking {
        return this.valueForKey('booking') as Booking;
    }
}
