//
// Generated class LicenseFeeInvoiceLine
//

/// <reference path="LicenseFeeInvoiceLine_ManagedObject.ts" />

class LicenseFeeInvoiceLine extends LicenseFeeInvoiceLine_ManagedObject
{

}
