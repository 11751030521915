//
// Generated class WorkerSession
//

/// <reference path="WorkerSession_ManagedObject.ts" />

class WorkerSession extends WorkerSession_ManagedObject
{

}
