//
// Generated class LoadEconomicAccount
//

/// <reference path="LoadEconomicAccount_ManagedObject.ts" />

class LoadEconomicAccount extends LoadEconomicAccount_ManagedObject
{

}
