

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ProxyPaymentEntityPlatform_ManagedObject

class ProxyPaymentEntityPlatform_ManagedObject extends CoreEntityUpdatedBy
{
    // Relationship: proxyPaymentEntities
    set proxyPaymentEntities(value:ProxyPaymentEntity) {
        this.setValueForKey(value, 'proxyPaymentEntities');
    }
    get proxyPaymentEntities():ProxyPaymentEntity {
        return this.valueForKey('proxyPaymentEntities') as ProxyPaymentEntity;
    }
}
