//
// Generated class PaymentActionInterval
//

/// <reference path="PaymentActionInterval_ManagedObject.ts" />

class PaymentActionInterval extends PaymentActionInterval_ManagedObject
{

}
