

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class LicenseType_ManagedObject

class LicenseType_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: rateName
    set rateName(value:string) {
        this.setValueForKey(value, 'rateName');
    }
    get rateName():string {
        return this.valueForKey('rateName');
    }
    set rateNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'rateName');
    }
    get rateNamePrimitiveValue():string {
        return this.primitiveValueForKey('rateName');
    }

    // Property: timePeriod
    set timePeriod(value:number) {
        this.setValueForKey(value, 'timePeriod');
    }
    get timePeriod():number {
        return this.valueForKey('timePeriod');
    }
    set timePeriodPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'timePeriod');
    }
    get timePeriodPrimitiveValue():number {
        return this.primitiveValueForKey('timePeriod');
    }
    // Relationship: rate
    set rate(value:Rate) {
        this.setValueForKey(value, 'rate');
    }
    get rate():Rate {
        return this.valueForKey('rate') as Rate;
    }
}
