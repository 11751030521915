

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class StockAnnotationSummary_ManagedObject

class StockAnnotationSummary_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: minMeasureQuantity
    set minMeasureQuantity(value:number) {
        this.setValueForKey(value, 'minMeasureQuantity');
    }
    get minMeasureQuantity():number {
        return this.valueForKey('minMeasureQuantity');
    }
    set minMeasureQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minMeasureQuantity');
    }
    get minMeasureQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('minMeasureQuantity');
    }

    // Property: minMeasureType
    set minMeasureType(value:number) {
        this.setValueForKey(value, 'minMeasureType');
    }
    get minMeasureType():number {
        return this.valueForKey('minMeasureType');
    }
    set minMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minMeasureType');
    }
    get minMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('minMeasureType');
    }
    // Relationship: productID
    set productID(value:Product) {
        this.setValueForKey(value, 'productID');
    }
    get productID():Product {
        return this.valueForKey('productID') as Product;
    }
    // Relationship: warehouseID
    set warehouseID(value:Warehouse) {
        this.setValueForKey(value, 'warehouseID');
    }
    get warehouseID():Warehouse {
        return this.valueForKey('warehouseID') as Warehouse;
    }
}
