//
// Generated class ProductRate
//

/// <reference path="ProductRate_ManagedObject.ts" />

class ProductRate extends ProductRate_ManagedObject
{

}
