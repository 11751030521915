//
// Generated class FileContent
//

/// <reference path="FileContent_ManagedObject.ts" />

class FileContent extends FileContent_ManagedObject
{

}
