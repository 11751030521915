


class ProductCategoryListViewController extends EntityListViewController
{
    parentCategoryID = null;
    parentCategoryName = null;
    category:ProductCategory = null;    
    
    private addButton:MUIButton = null;            
        
    viewDidLoad(){
        super.viewDidLoad();
        
        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function (this:ProductCategoryListViewController){
            AppHelper.sharedInstance().showAddProductCategory( null, (category:ProductCategory) => {
                this.category = category;
                this.showItemDetail();
             });    
        });

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.tableView = MUIOutlet(this,"table-view","UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        

        MIONotificationCenter.defaultCenter().addObserver(this, "CategoryWillRemoveNotification", function (this:ProductCategoryListViewController, notification:MIONotification) {
            let category = notification.object as ProductCategory;
            if (category.identifier == this.selectedItem.identifier) {
                this.selectedItem = null;
                this.showNoItemSelected();
            }
        });                
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("ProductCategoryCell") as ProductCategoryCell;

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ProductCategory;
        cell.item = item;                    

        cell.selected = (this.selectedItem != null && this.selectedItem.identifier == item.identifier)        
        return cell;
    }
    
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath);
        this.showItemDetail();
    }

    private showItemDetail(){        
        let vc = ProductCategoryDetailViewController.newInstance();
        vc.item = this.selectedItem;    
        this.splitViewController.showDetailViewController(vc);
    }

    private showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("ProductCategory", NoItemSelectedViewControllerType.ProductCategory);
        this.splitViewController.showDetailViewController(vc);
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let sortDescriptors =  [MIOSortDescriptor.sortDescriptorWithKey("namePath", true)];
    
        let predicate = [];
        let filterString = null;
        
        if (this.searchString != null) predicate.push('name CONTAINS "' + this.searchString + '"');
        
        if (predicate.length > 0) filterString = predicate.join(' and ');

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("ProductCategory", sortDescriptors, filterString);
        fetchRequest.relationshipKeyPathsForPrefetching = ["image"];
        
        let fetchedResultsController = new MIOFetchedResultsController();        
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;        
    }
}

