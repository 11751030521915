//
// Generated class BusinessArea
//

/// <reference path="BusinessArea_ManagedObject.ts" />

class BusinessArea extends BusinessArea_ManagedObject
{

}
