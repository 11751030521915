//
// Generated class PurchaseInvoice
//

/// <reference path="PurchaseInvoice_ManagedObject.ts" />

class PurchaseInvoice extends PurchaseInvoice_ManagedObject
{

}
