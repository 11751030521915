
/// <reference path="DBImportController.ts" />


class DBImportComponentsController extends DBImportController
{
/*
    columnTitles() : string[] {
        let cols = [
            "PARENT PRODUCT NAME",
            "PRODUCT NAME",                 //INGREDIENT / COMPONENT
            "CONSUMPTION MEASURE UNIT",
            "CONSUMTION QUANTITY",
            "LOSS MEASURE UNIT",
            "LOSS QUANTITY",
            "TOTAL MEASURE UNIT",
            "TOTAL QUANTITY",
        ]
        return cols;
    }

    columns() : MUIReportTableViewColumn[] {
        
        let columns = [
            MUIReportTableViewColumn.labelColumnWithTitle(MIOLocalizeString("PARENT PRODUCT NAME", "PARENT PRODUCT NAME"), "10%", 100, "center", "PARENT PRODUCT NAME"),
            MUIReportTableViewColumn.labelColumnWithTitle(MIOLocalizeString("PRODUCT NAME", "PRODUCT NAME"), "20%", 100, "center", "PRODUCT NAME"),
            MUIReportTableViewColumn.labelColumnWithTitle(MIOLocalizeString("CONSUMTION MEASURE UNIT", "CONSUMTION MEASURE UNIT"), "20%", 100, "center", "CONSUMTION MEASURE UNIT"),
            MUIReportTableViewColumn.labelColumnWithTitle(MIOLocalizeString("CONSUMTION QUANTITY", "CONSUMTION QUANTITY"), "10%", 100, "center", "CONSUMTION QUANTITY"),
            MUIReportTableViewColumn.labelColumnWithTitle(MIOLocalizeString("LOSS MEASURE UNIT", "LOSS MEASURE UNIT"), "20%", 100, "center", "LOSS MEASURE UNIT"),
            MUIReportTableViewColumn.labelColumnWithTitle(MIOLocalizeString("LOSS QUANTITY", "LOSS QUANTITY"), "10%", 100, "center", "LOSS QUANTITY"),
            MUIReportTableViewColumn.labelColumnWithTitle(MIOLocalizeString("TOTAL MEASURE UNIT", "TOTAL MEASURE UNIT"), "10%", 100, "center", "TOTAL MEASURE UNIT"),
            MUIReportTableViewColumn.labelColumnWithTitle(MIOLocalizeString("TOTAL QUANTITY", "TOTAL QUANTITY"), "10%", 100, "center", "TOTAL QUANTITY"),
        ];

        return columns;
    }

    private clients:Client[] = null;
    private agents:Agent[] = null;  
    private countries:Country[] = null;  
    importItems(items){
        super.importItems(items);
        DBHelper.queryObjectsWithCompletion("Client", null, MIOPredicate.predicateWithFormat("deletedAt == null"), [], this, function(clients){
            this.clients = clients;
            DBHelper.queryObjectsWithCompletion("Agent", null, MIOPredicate.predicateWithFormat("deletedAt == null"), [], this, function(agents){
                this.agents = agents;                
                DBHelper.queryObjectsWithCompletion("Country", null, null, [], this, function(countries){
                    this.countries = countries;
                    this.rowIndex = 0;
                    this.parseNextRow();                
                });
            });                        
        });        
    }

    protected importRow(row){
        
        let reference        = row["REFERENCE"];
        let name             = row["CLIENT NAME"];
        let businessName     = row["BUSINESS NAME"];
        let businessID       = row["BUSINESS ID"];
        let address          = row["ADDRESS"];
        let city             = row["CITY"];
        let postalCode       = row["POSTAL CODE"];        
        let state            = row["PROVINCE OR STATE"];
        let countryISO2      = row["COUNTRY ISO2"];
        let comments         = row["COMMENTS"];
        let agentName        = row["AGENT"];
        let entryDate        = row["ENTRY DATE"];        
        let leaveDate        = row["LEAVE DATE"];
        let leavingReasons   = row["LEAVING REASONS"];

        let agent:Agent = null;
        let agent_array = _MIOPredicateFilterObjects(this.agents, MIOPredicate.predicateWithFormat("name = '" + agentName + "'"));
        if (agent_array.length > 0) {
            agent = agent_array[0];
        }

        let country:Country = null;
        let country_array = _MIOPredicateFilterObjects(this.countries, MIOPredicate.predicateWithFormat("iso2 = '" + countryISO2 + "'"));
        if (country_array.length > 0) {
            country = country_array[0];
        }

        let client:Client = null;
        let client_array = [];
        if (reference != null) {
            client_array = _MIOPredicateFilterObjects(this.clients, MIOPredicate.predicateWithFormat("reference = \"" + reference + "\""));
            if (client_array.length > 0) {
                client = client_array[0];
            }
        } 

        if (client == null) {
            client_array = _MIOPredicateFilterObjects(this.clients, MIOPredicate.predicateWithFormat("name = \"" + name + "\""));
            if (client_array.length > 0) {
                client = client_array[0];
            }
        }        
        
        if (client == null) { 
            client = MIOEntityDescription.insertNewObjectForEntityForName("Client", DBHelper.mainManagedObjectContext) as Client;
            client.identifier = new MIOUUID().UUIDString;

            let addr = MIOEntityDescription.insertNewObjectForEntityForName("Address", DBHelper.mainManagedObjectContext) as Address;
            client.address = addr;
        }

        client.reference = reference;
        client.name = name;
        client.businessName = businessName;
        client.document = businessID;
        client.address.address1 = address;
        client.address.city = city;
        client.address.state = state;
        client.address.postalCode = postalCode;
        client.address.country = country?.name;
        client.address.countryISOa2 = country?.iso2;
        client.address.countryISOa3 = country?.iso3;        
        client.comments = comments;
        client.entryDate = this.ad.dateTimeFormatter.dateFromString(entryDate);
        client.leavingDate = this.ad.dateTimeFormatter.dateFromString(leaveDate);
        client.leavingReasons = leavingReasons;        

        this.clients.addObject(client);
        MIOLog("ADDING CLIENT: " + this.rowIndex + "/" + this.rows.length + ": " + name);
    }*/
}
