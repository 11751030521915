
class TableMapViewController extends BaseViewController implements TableMapViewDataSource
{
    private locationCategoryDropdown:MUIButton = null;
    private titleLabel:MUILabel = null;
    private tableMapView:TableMapView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.locationCategoryDropdown = MUIOutlet(this, "zone-dd", "MUIButton");
        this.locationCategoryDropdown.setAction(this, function(this:TableMapViewController){
            AppHelper.sharedInstance().showSelectLocationCateogryViewControllerFromView(this.locationCategoryDropdown, null, this, this.categoryDidSelected);
        });

        this.titleLabel = MUIOutlet(this, "map-name-lbl", "MUILabel");

        this.tableMapView = MUIOutlet(this, "table-map", "TableMapView");
        this.tableMapView.dataSource = this;
    }

    private selectedCategory:LocationCategory = null;
    private categoryDidSelected(controller:SelectEntityViewController, category:LocationCategory){
        this.locationCategoryDropdown.title = category.name;
        this.titleLabel.text = category.name;
        this.selectedCategory = category;
        this.invalidateData();
    }

    protected canReloadData():boolean{ 
        return (this.selectedCategory != null);
    }

    get fetchedResultsController() : MIOFetchedResultsController {
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let predicateFormat = "category.identifier == " + this.selectedCategory.identifier;
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Location", null, predicateFormat);        
        fetchRequest.relationshipKeyPathsForPrefetching = ["category"];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    protected reloadData(){
        this.tableMapView.reloadData();
    }

    viewForBackground(tableMapView:TableMapView):MUIView {
        let type = this.selectedCategory.backgroundType;
        let url = TableMapBackgroundTypeURL(type);

        let iv = new MUIImageView();
        iv.initWithFrame(MIORect.rectWithValues(0, 0, tableMapView.getWidth(), tableMapView.getHeight()));
        iv.setImage(url);

        return iv;
    }

    numberOfTables(tableMapView:TableMapView):number {
        let info = this.fetchedResultsController.sections[0] as MIOFetchSection;
        return info.numberOfObjects();
    }
    
    viewAtIndex(tableMapView:TableMapView, index:number):MUIView {

        let v = tableMapView.dequeueReusableItemWithIdentifier("TableMapItem");

        let ip = MIOIndexPath.indexForRowInSection(index, 0);
        let l = this.fetchedResultsController.objectAtIndexPath(ip);

        v.location = l;

        let tapGesture = new MUITapGestureRecognizer();
        tapGesture.initWithTarget(this, this.tableMapItemDidTap);
        v.addGestureRecognizer(tapGesture);

        return v;
    }

    tableMapItemDidTap(gesture:MUIGestureRecognizer){
        if (gesture.state != MUIGestureRecognizerState.Ended) return;
        let item = gesture.view as TableMapItem;

        item.toggleSelected();
    }

}