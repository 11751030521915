

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class DashBoardItem_ManagedObject

class DashBoardItem_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: keys
    set keys(value:string) {
        this.setValueForKey(value, 'keys');
    }
    get keys():string {
        return this.valueForKey('keys');
    }
    set keysPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'keys');
    }
    get keysPrimitiveValue():string {
        return this.primitiveValueForKey('keys');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: title
    set title(value:string) {
        this.setValueForKey(value, 'title');
    }
    get title():string {
        return this.valueForKey('title');
    }
    set titlePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'title');
    }
    get titlePrimitiveValue():string {
        return this.primitiveValueForKey('title');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }
    // Relationship: report
    set report(value:Report) {
        this.setValueForKey(value, 'report');
    }
    get report():Report {
        return this.valueForKey('report') as Report;
    }
}
