

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class PlaceConfiguration_ManagedObject

class PlaceConfiguration_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: limitMoneyForInvoiceRequired
    set limitMoneyForInvoiceRequired(value:number) {
        this.setValueForKey(value, 'limitMoneyForInvoiceRequired');
    }
    get limitMoneyForInvoiceRequired():number {
        return this.valueForKey('limitMoneyForInvoiceRequired');
    }
    set limitMoneyForInvoiceRequiredPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'limitMoneyForInvoiceRequired');
    }
    get limitMoneyForInvoiceRequiredPrimitiveValue():number {
        return this.primitiveValueForKey('limitMoneyForInvoiceRequired');
    }

    // Property: needBMPermissionForDeletion
    set needBMPermissionForDeletion(value:boolean) {
        this.setValueForKey(value, 'needBMPermissionForDeletion');
    }
    get needBMPermissionForDeletion():boolean {
        return this.valueForKey('needBMPermissionForDeletion');
    }
    set needBMPermissionForDeletionPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'needBMPermissionForDeletion');
    }
    get needBMPermissionForDeletionPrimitiveValue():boolean {
        return this.primitiveValueForKey('needBMPermissionForDeletion');
    }
}
