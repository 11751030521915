//
// Generated class SourcePrinterLink
//

/// <reference path="SourcePrinterLink_ManagedObject.ts" />

class SourcePrinterLink extends SourcePrinterLink_ManagedObject
{

}
