class ReportListCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    
    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, 'name-lbl', 'MUILabel');
    }

    set item (item:LocalizedName){
        this.nameLabel.text = item.name;
    }
}
