//
// Generated class BookingLocationType
//

/// <reference path="BookingLocationType_ManagedObject.ts" />

class BookingLocationType extends BookingLocationType_ManagedObject
{

}
