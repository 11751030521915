//
// Generated class AccountingJournalEntry
//

/// <reference path="AccountingJournalEntry_ManagedObject.ts" />

class AccountingJournalEntry extends AccountingJournalEntry_ManagedObject
{

}
