//
// Generated class CashDeskOperation
//

/// <reference path="CashDeskOperation_ManagedObject.ts" />

class CashDeskOperation extends CashDeskOperation_ManagedObject
{

}
