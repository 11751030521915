
class BookingCustomerHeaderCell extends MUITableViewCell
{
    private selectCustomerButton:MUIButton = null;
    awakeFromHTML(){
        this.selectCustomerButton = MUIOutlet(this, "select-customer-btn", "MUIButton");
        this.selectCustomerButton.setAction(this, function(){
            this.showSelectCustomerViewController();
        });
    }

    private _booking:Booking = null;
    set item(booking:Booking){
        this._booking = booking;
    }

    private showSelectCustomerViewController(){
        let vc = AppHelper.sharedInstance().selectViewController("Client", "name", false, false, null, this, false);
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.window.rootViewController.presentViewController(vc, true);
    }

    //
    // Select entity view controller
    //

    fetchRequestForController(controller:SelectEntityViewController):MIOFetchRequest{

        if (controller.identifier == "Client"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Client", sd, null);
            fetchRequest.fetchLimit = 50;
            fetchRequest.relationshipKeyPathsForPrefetching = ["address", "phone", "mobilePhone"];

            return fetchRequest;
        }

        return null;
    }

    didSelectObjectFromSelectViewController(controller:SelectEntityViewController, item:MIOManagedObject){        
        
        if (controller.identifier == "Client") {
            let client = item as Client;
            //this._booking.clientID = client.identifier;
            this._booking.client = client;

            controller.dismissViewController(true);            
        }
    }
    
}