

/// <reference path="InvitationRule.ts" />

// Generated class EventInvitationRule_ManagedObject

class EventInvitationRule_ManagedObject extends InvitationRule
{
    // Relationship: event
    set event(value:VenueEvent) {
        this.setValueForKey(value, 'event');
    }
    get event():VenueEvent {
        return this.valueForKey('event') as VenueEvent;
    }
}
