
enum ColumnFilterTextFieldType
{
    String,
    Number,
    Currency,
    Date,
    Time,
    DateTime,
	Enum,
	Boolean
}

enum ColumnFilterTokenType 
{
    BooleanValue,
    NumberValue,
    NullValue,
    StringValue,
    LongStringValue,
    
    OR,
    AND,
    
    MinorOrEqualComparator,
    MinorComparator,    
    MajorOrEqualComparator,
    MajorComparator,
    EqualComparator,
    DistinctComparator,

    Whitespace
}

class ColumnFilterTextField extends MUITextField
{
    filterController:ColumnFilterController = null;

    private _filterType = ColumnFilterTextFieldType.String;
    filterKey:string = null;    

    private filterTarget = null;
    private filterCompletion = null;
    
    setOnFilterChange(type:ColumnFilterTextFieldType, columnKey:string, target, completion){
        this.filterType = type;
        this.filterKey = columnKey;
        this.filterTarget = target;
        this.filterCompletion = completion;

        this.setupTextFieldEvents();
    }

    set filterType(type:ColumnFilterTextFieldType){
        this._filterType = type;

        switch(type){
            // case ColumnFilterTextFieldType.Currency: this.formatter = (MUIWebApplication.sharedInstance().delegate as AppDelegate).currencyFormatter; break;
            // case ColumnFilterTextFieldType.Number: this.formatter = (MUIWebApplication.sharedInstance().delegate as AppDelegate).numberFormatter; break;
            // case ColumnFilterTextFieldType.Date: this.formatter = (MUIWebApplication.sharedInstance().delegate as AppDelegate).dateFormatter; break;
            // case ColumnFilterTextFieldType.DateTime: this.formatter = (MUIWebApplication.sharedInstance().delegate as AppDelegate).dateTimeFormatter; break;
            // case ColumnFilterTextFieldType.Time: this.formatter = (MUIWebApplication.sharedInstance().delegate as AppDelegate).timeFormatter; break;
        }
    }

    private enumValues = null;
    setFilterEnumValues(values){
        this.enumValues = values;
    }

    private isTextFieldSetupDone = false;
    private isValueChanged = false;
    private setupTextFieldEvents(){
        if (this.isTextFieldSetupDone == true) return;

        this.setOnChangeText(this, function(control, value){
            this.isValueChanged = true;
        });

        this.setOnEnterPress(this, function(control){
            this.resignFirstResponder();
        });

        this.setOnDidEndEditing(this, function(control){
            if (this.isValueChanged == true) this.evaluateFormat(control.text);
            this.isValueChanged = false;
        });

        this.isTextFieldSetupDone = true;
    }

    private filterFormat:string = null;
    private evaluateFormat(format:string){

        if (format.length == 0){
            this.filterFormat = null;
        }
        else {
            this.parse(format);
        }

        this.notifyEvent();
    }

    private notifyEvent(){
        if (this.filterController != null) {
            this.filterController.changeFilterPredicateForKey(this.filterKey, this.filterFormat);
        }
        if (this.filterCompletion != null && this.filterTarget != null){
            this.filterCompletion.call(this.filterTarget, this, this.filterFormat);
        }
    }

    private lexer:MIOCoreLexer = null;
    private tokenizeWithFormat(format:string){
        
        this.lexer = new MIOCoreLexer(format);
        
        // Symbols
        // this.lexer.addTokenType(MIOPredicateTokenType.OpenParenthesisSymbol, /^\(/);
        // this.lexer.addTokenType(MIOPredicateTokenType.CloseParenthesisSymbol, /^\)/);
        // Comparators
        this.lexer.addTokenType(ColumnFilterTokenType.MinorOrEqualComparator, /^<=/);
        this.lexer.addTokenType(ColumnFilterTokenType.MinorComparator, /^</);
        this.lexer.addTokenType(ColumnFilterTokenType.MajorOrEqualComparator, /^>=/);
        this.lexer.addTokenType(ColumnFilterTokenType.MajorComparator, /^>/);
        this.lexer.addTokenType(ColumnFilterTokenType.EqualComparator, /^==?/);
        this.lexer.addTokenType(ColumnFilterTokenType.DistinctComparator, /^!=/);
        // this.lexer.addTokenType(MIOPredicateTokenType.NotContainsComparator, /^not contains /i);
        // this.lexer.addTokenType(MIOPredicateTokenType.ContainsComparator, /^contains /i);
        // this.lexer.addTokenType(MIOPredicateTokenType.InComparator, /^in /i);

        // Extra
        this.lexer.addTokenType(ColumnFilterTokenType.Whitespace, /^\s+/);      
        this.lexer.ignoreTokenType(ColumnFilterTokenType.Whitespace);
        
        // Join operators
        this.lexer.addTokenType(ColumnFilterTokenType.AND, /^(and|&) /i);
        this.lexer.addTokenType(ColumnFilterTokenType.OR, /^(or|\|) /i);

        // Values
        switch (this._filterType){
            case ColumnFilterTextFieldType.String:
                this.lexer.addTokenType(ColumnFilterTokenType.OR, /^,/);
                this.lexer.addTokenType(ColumnFilterTokenType.StringValue, /^[^<=>!,\s&|"']+/i);
                break;

            case ColumnFilterTextFieldType.Number:
            case ColumnFilterTextFieldType.Currency:
                this.lexer.addTokenType(ColumnFilterTokenType.NumberValue, /^[^<=>!\s&|"']+/i);
                break;
    
            case ColumnFilterTextFieldType.Boolean:
                this.lexer.addTokenType(ColumnFilterTokenType.BooleanValue, /^(true|false)\b/i);
                break;

            default:
                MIOLog("TOKEN NOT IMPLEMENTED");
                break;
        }
        // this.lexer.addTokenType(ColumnFilterTokenType.NumberValue, /^-?\d+(?:\.\d+)?(?:e[+\-]?\d+)?/i);
        // this.lexer.addTokenType(ColumnFilterTokenType.BooleanValue, /^(true|false)/i);
        // this.lexer.addTokenType(ColumnFilterTokenType.NullValue, /^(null|nil)/i);
        // this.lexer.addTokenType(ColumnFilterTokenType.StringValue, /^[a-zA-Z0-9-_\,\.\/]*/);
        this.lexer.addTokenType(ColumnFilterTokenType.LongStringValue, /^"([^"]*)"|^'([^']*)'/);
        
        this.lexer.tokenize();
    }    

    private parse(format:string){

        console.log("**** Start column filter format parser")
        console.log(format);
        console.log("****")
        
        this.tokenizeWithFormat(format);

        let token = this.lexer.nextToken();
        let predicates = [];
        let exit = false;        

        let comparator = null;
        let value = null;
        let type = null;

        while (token != null && exit == false) {
            
            type = token.type;
            switch (token.type) {

                case ColumnFilterTokenType.NumberValue:
                    if (this._filterType == ColumnFilterTextFieldType.Number) {
                        value = MUIWebApplication.sharedInstance().delegate.numberFormatter.getObjectValueForString(token.value);
                    }
                    else if (this._filterType == ColumnFilterTextFieldType.Currency) {
                        value = MUIWebApplication.sharedInstance().delegate.currencyFormatter.getObjectValueForString(token.value);
                    }
                    else value = token.value;
                    break;

                case ColumnFilterTokenType.BooleanValue:                
                case ColumnFilterTokenType.NullValue:
                case ColumnFilterTokenType.LongStringValue:
                case ColumnFilterTokenType.StringValue:
                    value = token.value;
                    break;

                case ColumnFilterTokenType.AND:                    
                    predicates.push(this.predicateWithValues(value, token.type, comparator));
                    predicates.push(" AND ");
                    value = null;
                    comparator = null;
                    break;

                case ColumnFilterTokenType.OR:
                predicates.push(this.predicateWithValues(value, token.type, comparator));
                    predicates.push(" OR ");
                    value = null;
                    comparator = null;
                    break;                    

                default:
                    comparator = token.value;
                    break;
            }

            if (exit == false) { 
                token = this.lexer.nextToken();
            }
        }

        if (value != null) predicates.push(this.predicateWithValues(value, type, comparator));
        
        this.filterFormat = predicates.join("");

        console.log("**** End column filter format parser")
    }

    private predicateWithValues(value, valueType:ColumnFilterTokenType, comparator:string){
        if (comparator != null) return this.filterKey + comparator + value;

        let fmt = null;
        let aux_value = null;
        switch(this._filterType){
            case ColumnFilterTextFieldType.String:
                if (value == ColumnFilterTokenType.LongStringValue) fmt = this.filterKey + " CONTAINS " + value;
                else fmt = this.filterKey + " CONTAINS '" + value + "'";
                break;

            case ColumnFilterTextFieldType.Number:
                if (value == ColumnFilterTokenType.LongStringValue) fmt = this.filterKey + " = " + value;
                else fmt = this.filterKey + " = '" + value + "'";
                break;

            case ColumnFilterTextFieldType.Date: 
                aux_value = this.formatter.getObjectValueForString(value);
                fmt = this.filterKey + " >= '" + (MUIWebApplication.sharedInstance().delegate as AppDelegate).serverDateFormatter.stringForObjectValue(aux_value) + "'";
                break;

            case ColumnFilterTextFieldType.DateTime:
                aux_value = this.formatter.getObjectValueForString(value);
                fmt = this.filterKey + " >= '" + (MUIWebApplication.sharedInstance().delegate as AppDelegate).serverDateTimeFormatter.stringForObjectValue(aux_value) + "'";
                break;
    
            case ColumnFilterTextFieldType.Time:
                let d = this.formatter.getObjectValueForString(value);
                fmt = this.filterKey + " >= '" + (MUIWebApplication.sharedInstance().delegate as AppDelegate).serverTimeFormatter.stringForObjectValue(aux_value) + "'";
                break;
    
            case ColumnFilterTextFieldType.Enum:
                let v = this.enumValues[value.toLowerCase()];
                fmt = this.filterKey + " = " + v;
                break;

            default:
                fmt = this.filterKey + " = " + value;
                break;
        }

        return fmt;
    }

}