//
// Generated class WineCellar
//

/// <reference path="WineCellar_ManagedObject.ts" />

class WineCellar extends WineCellar_ManagedObject
{

}
