
class WineCellarDataCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;  
    private dataLabel:MUILabel = null;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.dataLabel = MUIOutlet(this, "data-lbl", "MUILabel");
    }
    
    private _wineCellar:WineCellar = null;
    set item (item:WineCellar){
        if (item == null) return;

        this._wineCellar = item;
        this.nameLabel.text = item.name;
    }

    private _selectedProductAttribute = null;
    set setSelectedAttribute (value:ProductAttribute){
        this._selectedProductAttribute = value;
        this.dataLabel.text = value.name;
    }

    private _selectedStockCategory = null;
    set setSelectedStockCategory (value:StockCategory){
        this._selectedStockCategory = value;
        this.dataLabel.text = value.name;
    }

    
}