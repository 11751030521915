//
// Generated class Location
//

/// <reference path="Location_ManagedObject.ts" />

enum MapItemType
{
    Table                = 0,
    Stool                = 4,
    BarStandTable        = 5,
    RoundedTable         = 17,
    VipSofa              = 18,
    VipTable             = 19,
    HostelcoTable        = 21,
    HeartStool           = 25,
    HeartBrownTable      = 26,
    HeartGrayTable       = 27,
    HeartRedTable        = 28,
    BeachBedBamboo       = 29,
    BeachBed             = 30,
    BeachGazeboBamboo    = 31,
    BeachGazebo          = 32,
    BeachHammock         = 33,
    BeachHammockTriple   = 34,
    BeachHammockVIP      = 35,
    BeachTable           = 36,
    BeachHammockXL       = 38,
    BeachHammockDouble   = 39    
}

class Location extends Location_ManagedObject
{
    static occupiableTypes = [
        MapItemType.Table,
        MapItemType.Stool,
        MapItemType.BarStandTable,
        MapItemType.RoundedTable,
        MapItemType.VipSofa,
        MapItemType.VipTable,
        MapItemType.HostelcoTable,
        MapItemType.HeartStool,
        MapItemType.HeartBrownTable,
        MapItemType.HeartGrayTable,
        MapItemType.HeartRedTable,
        MapItemType.BeachBedBamboo,
        MapItemType.BeachBed,
        MapItemType.BeachGazeboBamboo,
        MapItemType.BeachGazebo,
        MapItemType.BeachHammock,
        MapItemType.BeachHammockXL,
        MapItemType.BeachHammockDouble,
        MapItemType.BeachHammockTriple,
        MapItemType.BeachHammockVIP,
        MapItemType.BeachTable
    ]

    get fullName () : string 
    {
        let str = ""
        str += this.category ? this.category.name : "ZONE INVALID";
        str += " / " + this.name;
        return str;
    }

    get qrURLString() : string {
        if (this.qrCode != null ) {
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;        
            return "https://webapps.dual-link.com/" + ad.selectedIdentifier.toUpperCase() + "/?qr=" + this.qrCode;
        }

        return null;
    }

}
