//
// Generated class ProfileSummarySource
//

/// <reference path="ProfileSummarySource_ManagedObject.ts" />

class ProfileSummarySource extends ProfileSummarySource_ManagedObject
{

}
