//
// Generated class IntegratorSAPStockNoteLineQueue
//

/// <reference path="IntegratorSAPStockNoteLineQueue_ManagedObject.ts" />

class IntegratorSAPStockNoteLineQueue extends IntegratorSAPStockNoteLineQueue_ManagedObject
{

}
