

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class WorkSession_ManagedObject

class WorkSession_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: beginDate
    set beginDate(value:Date) {
        this.setValueForKey(value, 'beginDate');
    }
    get beginDate():Date {
        return this.valueForKey('beginDate');
    }
    set beginDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'beginDate');
    }
    get beginDatePrimitiveValue():Date {
        return this.primitiveValueForKey('beginDate');
    }

    // Property: endDate
    set endDate(value:Date) {
        this.setValueForKey(value, 'endDate');
    }
    get endDate():Date {
        return this.valueForKey('endDate');
    }
    set endDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'endDate');
    }
    get endDatePrimitiveValue():Date {
        return this.primitiveValueForKey('endDate');
    }
    // Relationship: worker
    set worker(value:Employee) {
        this.setValueForKey(value, 'worker');
    }
    get worker():Employee {
        return this.valueForKey('worker') as Employee;
    }
}
