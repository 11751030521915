

/// <reference path="ChildPlace.ts" />

// Generated class ProductFormatPlace_ManagedObject

class ProductFormatPlace_ManagedObject extends ChildPlace
{

    // Property: isDefaultFormat
    set isDefaultFormat(value:boolean) {
        this.setValueForKey(value, 'isDefaultFormat');
    }
    get isDefaultFormat():boolean {
        return this.valueForKey('isDefaultFormat');
    }
    set isDefaultFormatPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isDefaultFormat');
    }
    get isDefaultFormatPrimitiveValue():boolean {
        return this.primitiveValueForKey('isDefaultFormat');
    }

    // Property: isEnableForSell
    set isEnableForSell(value:boolean) {
        this.setValueForKey(value, 'isEnableForSell');
    }
    get isEnableForSell():boolean {
        return this.valueForKey('isEnableForSell');
    }
    set isEnableForSellPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isEnableForSell');
    }
    get isEnableForSellPrimitiveValue():boolean {
        return this.primitiveValueForKey('isEnableForSell');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }
    // Relationship: productFormat
    set productFormat(value:ProductFormat) {
        this.setValueForKey(value, 'productFormat');
    }
    get productFormat():ProductFormat {
        return this.valueForKey('productFormat') as ProductFormat;
    }
}
