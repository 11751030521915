

class CashDeskTotalCell extends MUITableViewCell
{
    private dateLabel:MUILabel = null;
    private totalLabel:MUILabel = null;

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    private df = MUIWebApplication.sharedInstance().delegate.dateFormatter;

    awakeFromHTML(){ 
        this.totalLabel = MUIOutlet(this, 'cdlv_section_total_lbl', 'MUILabel');
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");
    }
    
    set item(i){
        this.totalLabel.text = this.cf.stringFromNumber(i.total);
        let rh = ReportHelper.sharedInstance();
        let dayFrom = MIODateGetDateString(rh.selectedDateTimeFrom);
        let dayTo = MIODateGetDateString(rh.selectedDateTimeTo);

        if (dayFrom == dayTo) {
            this.dateLabel.text = this.df.stringFromDate(rh.selectedDateTimeFrom);
        }
        else {
            this.dateLabel.text = this.df.stringFromDate(rh.selectedDateTimeFrom) + ' - ' + this.df.stringFromDate(rh.selectedDateTimeTo);
        }
    }
}
