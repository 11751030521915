
class DropdownButton extends MUIButton
{
    static outlet(target:any, elementID:string, onClickBlock:any) : DropdownButton {
        let control = MUIOutlet(target, elementID, "MUIButton");
        if (onClickBlock != null) control.setAction(this, function(this:DropdownButton){
            onClickBlock(this);
        });
        return control;
    }
}