//
// Generated class MaturityPayment
//

/// <reference path="MaturityPayment_ManagedObject.ts" />

class MaturityPayment extends MaturityPayment_ManagedObject
{

}
