
class ClientBookingCell extends MUITableViewCell
{
    private dateLabel:MUILabel = null;
    private paxLabel:MUILabel = null;
    private statusLabel:MUILabel = null;    
    private sourceLabel:MUILabel = null;
    private recommendedLabel:MUILabel = null;
    private zoneNameLabel:MUILabel = null;
    private depositLabel:MUILabel = null;

    awakeFromHTML(){
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");
        this.paxLabel = MUIOutlet(this, "pax-lbl", "MUILabel");
        this.statusLabel = MUIOutlet(this, "status-lbl", "MUILabel");
        this.sourceLabel = MUIOutlet(this, "intermediary-lbl", "MUILabel");
        this.recommendedLabel = MUIOutlet(this, "recommended-lbl", "MUILabel");
        this.zoneNameLabel = MUIOutlet(this, "zone-lbl", "MUILabel");
        this.depositLabel = MUIOutlet(this, "deposit-lbl", "MUILabel");

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    private booking:Booking = null;
    set item(item:Booking){
        this.booking = item;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let sdf = ad.serverDateFormatter;
        let ldf = ad.longDateFormatter;
        let cf = ad.currencyFormatter;
        let nf = ad.integerFormatter;

        let date = sdf.dateFromString(item.day);
        this.dateLabel.text = ldf.stringFromDate(date);
        this.paxLabel.text = nf.stringFromNumber(item.pax);
        this.statusLabel.text = item.statusString;
        this.sourceLabel.text = item.source ? item.source.name : null;
        //if no bookingRecommendation then check for prescriptorName (outdated save format - historic)
        this.recommendedLabel.text = item.recommendation ? item.recommendation.name : (item.prescriptorName);
        //if no bookingRecommendation then check for zoneName (outdated save format - historic)
        this.zoneNameLabel.text = item.bookingZoneName;
        this.depositLabel.text = cf.stringFromNumber(item.deposit);
    }
}