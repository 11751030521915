

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class BookingModifierCategory_ManagedObject

class BookingModifierCategory_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: incremental
    set incremental(value:boolean) {
        this.setValueForKey(value, 'incremental');
    }
    get incremental():boolean {
        return this.valueForKey('incremental');
    }
    set incrementalPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'incremental');
    }
    get incrementalPrimitiveValue():boolean {
        return this.primitiveValueForKey('incremental');
    }

    // Property: limitQuantity
    set limitQuantity(value:number) {
        this.setValueForKey(value, 'limitQuantity');
    }
    get limitQuantity():number {
        return this.valueForKey('limitQuantity');
    }
    set limitQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'limitQuantity');
    }
    get limitQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('limitQuantity');
    }

    // Property: multipleSelection
    set multipleSelection(value:boolean) {
        this.setValueForKey(value, 'multipleSelection');
    }
    get multipleSelection():boolean {
        return this.valueForKey('multipleSelection');
    }
    set multipleSelectionPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'multipleSelection');
    }
    get multipleSelectionPrimitiveValue():boolean {
        return this.primitiveValueForKey('multipleSelection');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Relationship: bookingModifiers
    protected _bookingModifiers:MIOManagedObjectSet = null;
    get bookingModifiers():MIOManagedObjectSet {
        return this.valueForKey('bookingModifiers');
    }
    addBookingModifiersObject(value:BookingModifier) {
        this._addObjectForKey(value, 'bookingModifiers');
    }
    removeBookingModifiersObject(value:BookingModifier) {
        this._removeObjectForKey(value, 'bookingModifiers');
    }

    // Relationship: bookingZones
    protected _bookingZones:MIOManagedObjectSet = null;
    get bookingZones():MIOManagedObjectSet {
        return this.valueForKey('bookingZones');
    }
    addBookingZonesObject(value:BookingModifierCategory_BookingZone) {
        this._addObjectForKey(value, 'bookingZones');
    }
    removeBookingZonesObject(value:BookingModifierCategory_BookingZone) {
        this._removeObjectForKey(value, 'bookingZones');
    }
}
