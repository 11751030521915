//
// Generated class GeneratePromotionalCode
//

/// <reference path="GeneratePromotionalCode_ManagedObject.ts" />

class GeneratePromotionalCode extends GeneratePromotionalCode_ManagedObject
{

}
