//
// Generated class FileAttachment
//

/// <reference path="FileAttachment_ManagedObject.ts" />

class FileAttachment extends FileAttachment_ManagedObject
{

}
