

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class PaymentIntallment_ManagedObject

class PaymentIntallment_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: amount
    set amount(value:number) {
        this.setValueForKey(value, 'amount');
    }
    get amount():number {
        return this.valueForKey('amount');
    }
    set amountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'amount');
    }
    get amountPrimitiveValue():number {
        return this.primitiveValueForKey('amount');
    }

    // Property: dueDate
    set dueDate(value:Date) {
        this.setValueForKey(value, 'dueDate');
    }
    get dueDate():Date {
        return this.valueForKey('dueDate');
    }
    set dueDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'dueDate');
    }
    get dueDatePrimitiveValue():Date {
        return this.primitiveValueForKey('dueDate');
    }

    // Property: operationID
    set operationID(value:string) {
        this.setValueForKey(value, 'operationID');
    }
    get operationID():string {
        return this.valueForKey('operationID');
    }
    set operationIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'operationID');
    }
    get operationIDPrimitiveValue():string {
        return this.primitiveValueForKey('operationID');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: paymentDate
    set paymentDate(value:Date) {
        this.setValueForKey(value, 'paymentDate');
    }
    get paymentDate():Date {
        return this.valueForKey('paymentDate');
    }
    set paymentDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'paymentDate');
    }
    get paymentDatePrimitiveValue():Date {
        return this.primitiveValueForKey('paymentDate');
    }
    // Relationship: paymentEntity
    set paymentEntity(value:PaymentEntity) {
        this.setValueForKey(value, 'paymentEntity');
    }
    get paymentEntity():PaymentEntity {
        return this.valueForKey('paymentEntity') as PaymentEntity;
    }
}
