//
// Generated class SupportSession
//

/// <reference path="SupportSession_ManagedObject.ts" />

class SupportSession extends SupportSession_ManagedObject
{

}
