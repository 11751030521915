class CardRulesDataSource extends MIOObject
{
    private tableView:MUITableView = null;
    initWithTableView(tableView:MUITableView){
        this.tableView = tableView;
    }

    private _card:Card = null;
    set card(card:Card){
        this._card = card;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath){
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as OfferRule;
        let cell = tableview.dequeueReusableCellWithIdentifier("RuleCell") as CardRuleCell;
        
        cell.item = item;
    
        return cell;
    }
        
    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
    }

    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as OfferRule;

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            this.removeCardRule(item);
        }
    }


    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('OfferRule');        
        
        //fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];
        fetchRequest.predicate = MIOPredicate.predicateWithFormat("economicEntity.identifier == " + this._card.identifier);
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    private removeCardRule(rule:OfferRule){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.managedObjectContext.deleteObject(rule);
        ad.managedObjectContext.save();
    }

}