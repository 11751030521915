class EditUserProfileViewController extends MUIViewController
{  
    private closeButton:MUIButton = null;
    private editPasswordButton:MUIButton = null;
    private editUserInfoButton:MUIButton = null;
    private userNameLabel:MUILabel = null;
    private versionLabel:MUILabel = null;

    public delegate = null;
    public preferredContentSize = new MIOSize(275, 375);

    viewDidLoad(){
        super.viewDidLoad();

        this.closeButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.closeButton.setAction(this, function (){            
            this.dismissViewController(true);
        });

        this.editPasswordButton = MUIOutlet(this, "edit-password-btn", "MUIButton");
        this.editPasswordButton.setAction(this, function(){
            let eupvc = new EditUserPasswordViewController('edit-user-password-view');
            eupvc.initWithResource("layout/edituserprofile/EditUserPasswordView.html");
            eupvc.user = this._user;
            this.navigationController.pushViewController(eupvc, true); 
        });

        this.editUserInfoButton = MUIOutlet(this, "edit-info-btn", "MUIButton");
        this.editUserInfoButton.setAction(this, function(){
            let euivc = new EditUserInfoViewController('edit-user-info-view');
            euivc.initWithResource("layout/edituserprofile/EditUserInfoView.html");
            euivc.user = this._user;
            this.navigationController.pushViewController(euivc, true); 
        });

        this.userNameLabel = MUIOutlet(this, "name-lbl", "MUILabel");

        let data = MIOBundle.mainBundle().pathForResourceOfType("app", "plist");
        let config = MIOPropertyListSerialization.propertyListWithData(data, 0, 0, null);
        this.versionLabel = MUIOutlet(this, "version-lbl", "MUILabel");
        this.versionLabel.text = config["Version"];

    }

    viewWillAppear(){
        super.viewWillAppear();
        this.updateUI();
    }

    private _user:User = null;
    private updateUI(){
        if (this.viewIsLoaded == false) return;

        // let userID = DualLinkAuthKit.sharedInstance().userID;
        // DBHelper.queryObjectsWithCompletion("User", null, MIOPredicate.predicateWithFormat("identifier == " + userID), [], this, function(objects){
        //     this._user = objects[0] as User;
        // });

        this._user = MUIWebApplication.sharedInstance().delegate.selectedUser;

        if (this.userNameLabel != null) this.userNameLabel.text = this._user.name;

    }




}
