/**
 * Created by miguelsl on 30/12/16.
 */



class ProductDetailSubproductsViewController extends MUIViewController
{
    private _product:Product = null;

    private subproductsEnabledSwitchButton:MUISwitchButton = null;
    private allowSaleSwitchButton:MUISwitchButton = null;
    //private infoButton:MUIButton = null;

    viewDidLoad()
    {
        super.viewDidLoad();

        this.subproductsEnabledSwitchButton = MUIOutlet(this, 'pspiv_enabled_switch_btn', 'MUISwitchButton');
        this.subproductsEnabledSwitchButton.setOnChangeValue(this, function (control, value) {
            this._product.isSubproductsEnabled = value;
            MIONotificationCenter.defaultCenter().postNotification("ProductChangeValue", value);
        });

        this.allowSaleSwitchButton = MUIOutlet(this, 'pspiv_allow_sale_switch_btn', 'MUISwitchButton');
        this.allowSaleSwitchButton.setOnChangeValue(this, function (control, value) {
            this._product.allowSubproductSale = value;
            MIONotificationCenter.defaultCenter().postNotification("ProductChangeValue", value);
        });

        // this.infoButton = MUIOutlet(this, 'pspiv_b1_info_btn','MUIButton');
        // this.infoButton.setAction(this, function()
        // {
           
        // });

    }

    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);

        this._updateUI();
    }

    set product(value)
    {
        this._product = value;

        this._updateUI();
    }

    private _updateUI()
    {
        if (this._product == null) return;
        if (this.viewIsLoaded == false) return;

        this.subproductsEnabledSwitchButton.setOn(this._product.isSubproductsEnabled);
        this.allowSaleSwitchButton.setOn(this._product.allowSubproductSale);
    
    }
}