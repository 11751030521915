class DBEllaborationCentersCoder extends DBXLSXCoder
{
    mainEntity() {
        return { entity: "EllaborationCenter", predicateFormat: "deletedAt == null" }
    }

    columns() : any[] {
        let cols = [
            { title:"ELLABORATION CENTER ID", width: "10%", align: "center" },
            { title:"ELLABORATION CENTER NAME", width: "10%", align: "center" }
        ]
        return cols;
    }

    protected importRow(row:any){
                
        let identifier = row["ELLABORATION CENTER ID"];
        let name       = row["ELLABORATION CENTER NAME"];                
        
        let center = this.queryEntityByIDOrName("EllaborationCenter", identifier, name) as EllaborationCenter;
        if (center == null) {
            center = MIOEntityDescription.insertNewObjectForEntityForName("EllaborationCenter", DBHelper.mainManagedObjectContext) as EllaborationCenter;
            center.identifier = this.parseOrCreateIdentifier(identifier);            
            this.appendObject(center);
        }
            
        center.name = name;        
        
        MIOLog("ADDING ELLABORATION CENTER: " + this.rowIndex + "/" + this.rows.length + ": " + name);
    }

    protected exportTitle() : string { return "Ellaboration Center"; }

    protected exportSortDescriptors() { 
        return [MIOSortDescriptor.sortDescriptorWithKey("name", false)]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let ctr = object as EllaborationCenter;

        let item = {
            "ELLABORATION CENTER ID"   : ctr.identifier,
            "ELLABORATION CENTER NAME" : ctr.name
        }

        return item;
    }
}