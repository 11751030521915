

class DBToolScriptViewController extends MUIViewController
{
    scriptEditor:MUICodeEditor = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.scriptEditor = MUIOutlet(this, 'script-ta', 'MUICodeEditor'); 
        this.scriptEditor.language = MUICodeEditorLanguage.Javascript;
        this.updateUI();       
    }

    private _script:DBScript = null;
    set script(value:DBScript){
        this._script = value;
        this.updateUI();
    }

    private updateUI(){                
        let code = this._script == null ? "" : this._script.code;
        if (this.scriptEditor != null) this.scriptEditor.text = code || ""; 
    }

}