//
// Generated class PartyEvent
//

/// <reference path="PartyEvent_ManagedObject.ts" />

class PartyEvent extends PartyEvent_ManagedObject
{
    static insertPartyEvent(save:boolean, context?:MIOManagedObjectContext):PartyEvent {

        let moc = context ? context : MUIWebApplication.sharedInstance().delegate.managedObjectContext as MIOManagedObjectContext;
        let object = MIOEntityDescription.insertNewObjectForEntityForName("PartyEvent", moc) as PartyEvent;

        if (save == true) moc.save();
        return object;
    }
}
