//
// Generated class BookingModifierCategory
//

/// <reference path="BookingModifierCategory_ManagedObject.ts" />

class BookingModifierCategory extends BookingModifierCategory_ManagedObject
{

}
