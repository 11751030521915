
class ReportFilterView extends MUIView
{
    delegate = null;

    private filterDropdownLayer = null;

    initWithLayer(layer, owner, options?){
        super.initWithLayer(layer, owner, options);

        // Check if we have prototypes
        if (this.layer.childNodes.length > 0) {
            for (let index = 0; index < this.layer.childNodes.length; index++) {
                let subLayer = this.layer.childNodes[index];

                if (subLayer.tagName != "DIV")
                    continue;

                if (subLayer.getAttribute("data-filter-dropdown") != null) {
                    this.filterDropdownLayer = subLayer;
                    subLayer.style.display = "none";
                }                
            }
        }
    }


}