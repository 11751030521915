//
// Generated class FilterTicketLine
//

/// <reference path="FilterTicketLine_ManagedObject.ts" />

class FilterTicketLine extends FilterTicketLine_ManagedObject
{
    identifier  = null;
    id_group  = null;

    date = null;
    status = null;

    id_product  = null;
    productname = null;
    tax = null;
    taxname     = null;
    workername = null;
    tags = null;
    discount = null;
    locationname = null;
    locationcategoryname = null;
    totalpricebase = null;
    totaltaxes = null;
    totalinvited = null;
    totaldiscount = null;
    totalprice = null;
    totaldeleted = null;
    inprocessseconds = null;
    id_app = null;
    nullify = null;
    sendtokitchenseconds = null;
    modifiedtype = null;
    nullifiedby = null;
    archivedticketlinesgroup = null;
    orderindex  = null;

    modifiers = [];

    setObject(object, order ,moc)
    {
        var group = object['group'];
        this.identifier  = object['id'];
        this.id_group  = group.identifier;

        this.date = object['date'];
        this.status = object['status'];
        this.tags = object['tags'];
        this.tax = object['tax'];
        this.taxname = object['taxname'];
        this.workername = object['workername'];   
        this.discount = object['discount'];
        this.locationname = object['locationname'];
        this.locationcategoryname = object['locationcategoryname'];
        this.totalpricebase = object['totalpricebase'];
        this.totaltaxes = object['totaltaxes'];
        this.totalinvited = object['totalinvited'];
        this.totaldiscount = object['totaldiscount'];
        this.totalprice = object['totalprice'];
        this.totaldeleted = object['totaldeleted'];
        this.inprocessseconds = object['inprocessseconds'];
        this.id_app = object['id_app'];
        this.nullify = object['nullify'];
        this.sendtokitchenseconds = object['sendtokitchenseconds'];
        this.modifiedtype = object['modifiedtype'];
        this.nullifiedby = object['nullifiedby'];
        this.archivedticketlinesgroup = object['archivedticketlinesgroup'];
        this.orderindex  = object['orderindex'] || order;

        this.modifiers = object['archivedTicketLineModifiers'] || [];
        
    }
    
     getObject()
    {
        var object = {};
        
        if(this.identifier)
            object['id'] = this.identifier;

        object['date'] = this.date;
        object['status'] = this.status;
        object['tags'] = this.tags;
        object['tax'] = this.tax;
        object['taxname'] = this.taxname;
        object['workername'] = this.workername;
        object['orderindex'] = this.orderindex;
        object['totalpricebase'] = this.totalpricebase;
        object['totaltaxes'] = this.totaltaxes;
        object['totalprice'] = this.totalprice;
        object['totaldiscount'] = this.totaldiscount;
        object['totalinvited'] = this.totalinvited;
        object['totaldeleted'] = this.totaldeleted;
        object['discount'] = this.discount;
        object['locationname'] = this.locationname;
        object['locationcategoryname'] = this.locationcategoryname;
        object['totalpricebase'] = this.totalpricebase;
        object['totaltaxes'] = this.totaltaxes;
        object['totalinvited'] = this.totalinvited;
        object['totaldiscount'] = this.totaldiscount;
        object['totalprice'] = this.totalprice;
        object['totaldeleted'] = this.totaldeleted;
        object['inprocessseconds'] = this.inprocessseconds;
        object['id_app'] = this.id_app;
        object['nullify'] = this.nullify;
        object['sendtokitchenseconds'] = this.sendtokitchenseconds;
        object['modifiedtype'] = this.modifiedtype;
        object['nullifiedby'] = this.nullifiedby;
        object['archivedticketlinesgroup'] = this.archivedticketlinesgroup;
        object['modifiers'] = this.modifiers;
    
        return object;
   }
}
