
class SupplierNoteListViewController extends NoteListViewController
{
    protected get sectionControllerID():string {
        return SectionViewControllerType.SupplierNote;
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("SupplierNoteCell") as SupplierNoteCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as SupplierNote;

        cell.item = item;     
        cell.selected = this.selectedNote == item ? true : false;   

        return cell;
    }

    set fetchedResultsController(value){
        this.setFetchedResultsController(value);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("documentID", false)];
        if (this.selectedStatus == StockNoteStatus.Processed) {
            sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("creationDate", false)];
        }
        
        let predicateFormat = this.selectedStatus != 0 ? "status == " + this.selectedStatus : "status == 0 OR status == 3";
        predicateFormat += " AND placeID == '" + ad.selectedIdentifier + "'";
        if(this.searchString != null) {
            predicateFormat += " AND (documentID CONTAINS '" + this.searchString + "' OR externalReference CONTAINS '" + this.searchString + "' OR creatorDocumentID CONTAINS '" + this.searchString + "')";            
		}
		
		if (this.filterPredicateFormat != null) {
            predicateFormat += " AND " + this.filterPredicateFormat;
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("SupplierNote", sortDescriptors, predicateFormat);
        fetchRequest.fetchLimit = 50;
        fetchRequest.relationshipKeyPathsForPrefetching = ["originEntity"];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }
	
	private _filters = null;
    get filters(){
        if (this._filters != null) return this._filters;

		this._filters = [FilterOption.filterWithTitle("FromDate", "From day", "stockDate", FilterFieldType.Date, null, FilterControllerComparatorType.MajorOrEqualComparator, null, "00:00:00"),
		FilterOption.filterWithTitle("ToDate", "To day", "stockDate", FilterFieldType.Date, null, FilterControllerComparatorType.MinorOrEqualComparator, null, "23:59:59"),
		FilterOption.filterWithFetchRequest("Destination", "Supplier", "originEntity.identifier", FilterFieldType.DropDown, "Supplier", "name", "identifier", MIOLocalizeString("SELECT SUPPLIER","Select Supplier"))];

        return this._filters;
    }
    
    private detailViewController:SupplierNoteDetailViewController = null;
    protected showSelectedItem(){        
        
        if(this.detailViewController == null) {
            this.detailViewController = new SupplierNoteDetailViewController("supplier-note-detail-view");
            this.detailViewController.initWithResource("layout/suppliernote/SupplierNoteDetailView.html");
        }

        this.detailViewController.note = this.selectedNote;
        this.splitViewController.showDetailViewController(this.detailViewController);        
    }

    protected showNoItemSelected(){        
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("SupplierNote", NoItemSelectedViewControllerType.SupplierNote);
        this.splitViewController.showDetailViewController(vc);        
    }
    
    // protected showAddNoteViewController(){
    //     let vc = AppHelper.sharedInstance().selectViewController("Supplier", "name", false, false, null, this, false) as SelectEntityViewController;
    //     vc.allowSearch = true;
    //     vc.allowAddEntity = true;
    //     vc.addTarget = this;
    //     vc.addBlock = this.addSupplier;        
    //     this.presentViewController(vc, true);
    // }

    protected showAddNoteViewController(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ADD SUPPLIER NOTE", "ADD SUPPLIER NOTE"), null, MUIAlertViewStyle.Default);
        
        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
            DBHelper.sharedInstance().addObserverForEntity(avc, "Supplier", null, null, null, function (objects) {
                comboBox.removeAllItems();
                let sortObjects = _MIOSortDescriptorSortObjects(objects, [MIOSortDescriptor.sortDescriptorWithKey("name", true)]);
                for (let i = 0; i < sortObjects.length; i++){
                    let id = sortObjects[i].identifier;
                    comboBox.addItem(sortObjects[i].name, i);
                }
            });  
        });

        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
            DBHelper.sharedInstance().addObserverForEntity(avc, "StockTemplate", null, null, null, function (objects) {
                comboBox.removeAllItems();
                comboBox.addItem(MIOLocalizeString("NO TEMPLATE", "NO TEMPLATE"), -1);
                let sortObjects = _MIOSortDescriptorSortObjects(objects, [MIOSortDescriptor.sortDescriptorWithKey("name", true)]);
                for (let i = 0; i < sortObjects.length; i++){
                    // let id = sortObjects[i].identifier;
                    comboBox.addItem(sortObjects[i].name, i);
                }
            });  
        });

        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
        
            DBHelper.sharedInstance().addObserverForEntity(avc, 'Warehouse', null, null, null, function (objects:Warehouse[]) {
                comboBox.removeAllItems();
                comboBox.addItem(MIOLocalizeString("PRODUCT DEFAULT", "PRODUCT DEFAULT"), -1);
                for (let i = 0; i < objects.length; i++)
                    comboBox.addItem(objects[i].name, i);
            });
        });
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("DONE", "DONE"), MUIAlertActionStyle.Default, this, function(this:SupplierNoteListViewController){            
            let supplierIndex = avc.comboBoxes[0].getSelectedItem();
            let templateIndex = avc.comboBoxes[1].getSelectedItem();
            let template = templateIndex == '-1' ? null : DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, templateIndex, 'StockTemplate');
            let supplier = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, supplierIndex, 'Supplier');
                      
            let warehouseIndex = avc.comboBoxes[2].getSelectedItem();
            let warehouse = warehouseIndex == '-1' ? null : DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, warehouseIndex, 'Warehouse');

            this.createSupplierNote(supplier, template, warehouse);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));
        avc.addCompletionHandler(this, function () {
            // Release the observers
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, 'StockTemplate');
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, 'Supplier');
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, 'warehouse');
        });
        this.presentViewController(avc, true);
    }

    //
    // Select entity view controller
    //

    fetchRequestForController(controller:SelectEntityViewController):MIOFetchRequest{

        if (controller.identifier == "Supplier"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Supplier", sd, null);
            fetchRequest.fetchLimit = 50;            

            return fetchRequest;
        }

        return null;
    }

    didSelectObjectFromSelectViewController(controller:SelectEntityViewController, item:MIOManagedObject){        
        
        let dismiss = true;

        if (controller.identifier == "Supplier") {
            this.createSupplierNote(item as Supplier, null, null);
        }

        return dismiss;
    }

    createSupplierNote(supplier:Supplier,template:StockTemplate, warehouse:Warehouse){
        DBHelper.createSupplierNote(supplier, template, (note:SupplierNote) => {
            this.selectedNote = note;
            this.selectedNote.overrideWarehouse = warehouse;
            this.selectedNote.overrideWarehouseName = warehouse?.name;
            DBHelper.saveMainContext();
            this.showSelectedItem();
        });
    }

    private addSupplier(supplier:Supplier, warehouse:Warehouse){
        let vc = new AddNewSupplierViewController('add-new-supplier-view');
        vc.initWithResource('layout/supplier/AddNewSupplierView.html');
        vc.delegate = this;
        this.presentViewController(vc, true);
    }

    supplierDidInserted(supplier:Supplier){
        this.createSupplierNote(supplier, null, null);
    }

}