class LicenseDetailInfoCell extends UITableViewCell
{
    delegate:LicenseDetailInfoCellDelegate = null;

    private productLabel:MUILabel = null;
    private startDateTextField: MUITextField = null;
    private startDateButton:MUIButton = null;
    private expirationDateTextField: MUITextField = null;
    private nextDateButton: MUIButton = null;
    private nextDateTextField: MUITextField = null;
    private expirationDateButton: MUIButton = null;
    private statusCombobox:MUIComboBox = null;
    private typeDropdown:DropdownButton = null;
    private offerDropdown:DropdownButton = null;
    private priceTextField:MUITextField = null;
    private discountTextField:MUITextField = null;
    private totalTextField:MUITextField = null;
    private resellerFeeTextField:MUITextField = null;
    private resellerFeeAmountLabel:MUITextField = null;
    private profitLabel:MUILabel = null;
    
    private df = (MUIWebApplication.sharedInstance().delegate as AppDelegate ).dateFormatter;
    private cf = (MUIWebApplication.sharedInstance().delegate as AppDelegate ).currencyFormatter;

    awakeFromHTML(): void {
        this.productLabel = MUIOutlet( this, "product-lbl", "MUILabel" );

        this.startDateTextField = MUIOutlet(this, "start-date-tf", "MUITextField");
        this.startDateTextField.formatter = this.df;
        this.startDateTextField.setOnChangeText( this, function(this:LicenseDetailInfoCell, control:MUITextField, value:string){            
            this.license.startDate = this.df.dateFromString( value );
        });

        this.startDateButton = MUIOutlet( this, "start-date-btn", "MUIButton" );
        this.startDateButton.tag = 1;
        this.startDateButton.setAction( this, function( this:LicenseDetailInfoCell) {
            this.showDatePickerController( this.startDateButton );
        });
        
        this.statusCombobox = MUIOutlet(this, "status-cb", "MUIComboBox");
        this.statusCombobox.setOnChangeAction(this, function(this:LicenseDetailInfoCell, control:MUIComboBox, value:string){
            this.license.active = (value == "1");
            this.license.deactivationDate = this.license.active ? MIODateToday() : null;
        });

        this.typeDropdown = DropdownButton.outlet(this, "license-type-dd", (control:MUIButton) => {
            AppHelper.sharedInstance().showSelectLicenseTypeViewControllerFromView(this.typeDropdown, this.license.type, this, function(this:LicenseDetailInfoCell, controller:SelectViewController, object:LicenseType){
                this.typeDropdown.title = object?.name;
                this.license.type = object;
            });
        });

        this.expirationDateTextField = MUIOutlet(this, "expiration-date-tf", "MUITextField");
        this.expirationDateTextField.setOnChangeText( this, function(this:LicenseDetailInfoCell, control:MUITextField, value:string ){
            this.license.expirationDate = value.trim().length == 0 ? null : this.df.dateFromString( value );
        });

        this.expirationDateButton = MUIOutlet( this, "expiration-date-btn", "MUIButton" );
        this.expirationDateButton.tag = 3;
        this.expirationDateButton.setAction( this, function( this:LicenseDetailInfoCell) {
            this.showDatePickerController( this.expirationDateButton );
        });

        this.nextDateTextField = MUIOutlet(this, "next-renewal-date-tf", "MUITextField");        
        this.nextDateTextField.setOnChangeText( this, function(this:LicenseDetailInfoCell, control:MUITextField, value:string ){            
            this.license.nextRenewalDate = value.trim().length == 0 ? null : this.df.dateFromString( value );
        });

        this.nextDateButton = MUIOutlet( this, "next-renewal-date-btn", "MUIButton" );
        this.nextDateButton.tag = 2;
        this.nextDateButton.setAction( this, function( this:LicenseDetailInfoCell) {
            this.showDatePickerController( this.nextDateButton );
        });

        this.offerDropdown = MUIOutlet(this, "offer-dd", "DropdownButton");

        this.priceTextField = MUIOutlet( this, "price-tf", "MUITextField" );
        this.priceTextField.setOnChangeText(this, function(this:LicenseDetailInfoCell, control:MUITextField, value:string ) {
            let price = (MUIWebApplication.sharedInstance().delegate as AppDelegate).currencyFormatter.numberFromString(value);            
            this.delegate?.didChangePrice(this.license, price);
            this.totalTextField.text = this.cf.stringFromNumber( this.license.total );
        });

        this.discountTextField = MUIOutlet(this, "discount-tf", "MUITextField");
        this.discountTextField.setOnChangeText(this, function(this:LicenseDetailInfoCell, control:MUITextField, value:string){
            let discount = value.length > 0 ? value.trim() : null;
            this.delegate?.didChangeDiscount(this.license, discount);
            this.totalTextField.text = this.cf.stringFromNumber( this.license.total );
        });

        this.totalTextField = MUIOutlet( this, "total-tf", "MUITextField" );
        this.totalTextField.setOnChangeText(this, function(this:LicenseDetailInfoCell, control:MUITextField, value:string){
            let total = (MUIWebApplication.sharedInstance().delegate as AppDelegate).currencyFormatter.numberFromString(value);
            this.delegate?.didChangeTotal(this.license, total);
        });

        this.resellerFeeTextField = MUIOutlet(this, "reseller-fee-tf", "MUITextField");
        this.resellerFeeTextField.setOnChangeText(this, function(this:LicenseDetailInfoCell, control:MUITextField, value:string){
            this.delegate?.didChangeFee(this.license, value.length > 0 ? value.trim() : null);
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            this.resellerFeeAmountLabel.text = ad.currencyFormatter.stringFromNumber( this.license.distributorFeeValue );
            this.profitLabel.text = ad.currencyFormatter.stringFromNumber( this.license.total - this.license.distributorFeeValue );    
        });

        this.resellerFeeAmountLabel = MUIOutlet(this, "reseller-fee-lbl", "MUILabel");

        this.profitLabel = MUIOutlet( this, "dl-profit-lbl", "MUILabel" );

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private license:License = null;
    set item(item:License) {
        this.license = item;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        
        this.productLabel.text = item.product?.name ?? "UNKNOWN";
        this.startDateTextField.text = this.df.stringFromDate( item.startDate );
        this.expirationDateTextField.text = item.expirationDate == null ? "-" : this.df.stringFromDate( item.expirationDate );
        this.nextDateTextField.text = item.nextRenewalDate == null ? "-" : this.df.stringFromDate( item.nextRenewalDate );
        this.statusCombobox.selectItem( item.active ? "1" : "0" );
        this.typeDropdown.title = item.type?.name;
        this.priceTextField.text = ad.currencyFormatter.stringFromNumber( item.price );
        this.discountTextField.text = item.discountFormat;
        this.totalTextField.text = ad.currencyFormatter.stringFromNumber( item.total );
        this.resellerFeeTextField.text = item.distributorFeeFormat;
        this.resellerFeeAmountLabel.text = ad.currencyFormatter.stringFromNumber( item.distributorFeeValue );
        this.profitLabel.text = ad.currencyFormatter.stringFromNumber( item.total - item.distributorFeeValue );
    }

    private showDatePickerController( button: MUIButton ){
        let dp = new MUIDatePickerController();
        dp.init();        
        dp.delegate = this;
        dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;
        dp.tag = button.tag;

        let pc = dp.popoverPresentationController;
        pc.sourceRect = button.frame;
        pc.sourceView = button;

        AppHelper.sharedInstance().presentViewController(dp, true);
    }

    didSelectDate(controller:MUIDatePickerController, date:Date){
        switch (controller.tag) {
            case 1: 
                this.license.startDate = date;
                this.startDateTextField.text = this.df.stringFromDate( date );
            break;

            case 2: 
                this.license.nextRenewalDate = date;
                this.nextDateTextField.text = this.df.stringFromDate( date );
            break;

            case 3: 
            this.license.expirationDate = date;
            this.expirationDateTextField.text = this.df.stringFromDate( date );
            break;

        }
    }

}