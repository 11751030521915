class BarcodesGlobalActionsViewController extends MUIViewController
{  
    private backButton:MUIButton = null;
    private nextButton:MUIButton = null;
    private tableView:MUITableView = null;

    public delegate = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");
        this.backButton.setAction(this, function (){            
            this.navigationController.popViewController(true);
        });
        
        this.nextButton = MUIOutlet(this, "next-btn", "MUIButton");
        this.nextButton.setAction(this, function (){            
            
        });

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(){
        super.viewWillAppear();

        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        // this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView){
        return 2;
    }

    numberOfRowsInSection(tableView:UITableView, section:number){
        return 3;
    }

    private sectionTitles = [MIOLocalizeString("PRODUCT BARCODE 1","PRODUCT BARCODE 1"),
                            MIOLocalizeString("PRODUCT BARCODE 2","PRODUCT BARCODE 2")];
    titleForHeaderInSection(tableView:UITableView, section:number) {          
        return this.sectionTitles[section];
    }
                                              
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){        
        let cell = tableView.dequeueReusableCellWithIdentifier("GlobalActionsSwitchCell") as GlobalActionsSwitchCell;
        // let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ProductModifier;                      
        cell.name = "barcode";
        return cell;
    }   

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){ 

        
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        var ad = MUIWebApplication.sharedInstance().delegate;
        
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('name', true)];
    
        var fetchRequest = DBHelper.listFetchRequestWithEntityName('ProductModifier', sortDescriptors, null);
        var fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;

        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }

}
