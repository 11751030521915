//
// Generated class AdyenPaymentEntityStore
//

/// <reference path="AdyenPaymentEntityStore_ManagedObject.ts" />

class AdyenPaymentEntityStore extends AdyenPaymentEntityStore_ManagedObject
{

}
