/**
 * Created by miguel on 15/3/17.
 */
 

/// <reference path="CashDeskReportViewController.ts" />


class CashDeskDetailViewController extends MUIViewController
{    
    private filterComboBox:MUIComboBox = null;
    private segmentedControl:MUISegmentedControl = null; 
    private visibilityButton:MUIButton = null;
    private exportButton:MUIButton = null;
    private sectionsPageController:MUIPageController = null;

    private summaryViewController:ReportViewController = null;    
    private sessionsViewController:CashDeskSessionListViewController = null;
   
    viewDidLoad(){
        super.viewDidLoad();

        this.filterComboBox = MUIOutlet(this, "filter-type-cb", "MUIComboBox");
        this.filterComboBox.hidden = true;
        this.filterComboBox.setOnChangeAction(this, function(this:CashDeskDetailViewController, control:MUISegmentedControl, value:string){
            let index = parseInt(value);
            this.sessionsViewController.filter = index;
        });                
        
        this.segmentedControl = MUIOutlet(this, 'segmented-control', 'MUISegmentedControl');
        this.segmentedControl.setAction(this, function (control, index){
            this.exportButton.hidden = (index != 0);
            this.visibilityButton.hidden = (index != 0);
            this.filterComboBox.setHidden(index != 1);
            this.sectionsPageController.showPageAtIndex(index);
        });        

        this.visibilityButton = MUIOutlet(this, "visibility-btn", "MUIButton");
        this.visibilityButton.setAction(this, function(this:CashDeskDetailViewController){
            this.summaryViewController.visibilityOptionsAction(this.visibilityButton);
        }); 

        this.exportButton = MUIOutlet(this, 'export-btn', 'MUIButton');
        this.exportButton.setHidden(false);
        this.exportButton.setAction(this, function(textfield, value){
            this.exportCashDeskSummary();
        });

        this.sectionsPageController = MUIOutlet(this, 'page-controller', 'MUIPageController');

        this.summaryViewController = new ReportViewController("report-detail-view");
        this.summaryViewController.initWithResource("layout/reports/ReportDetailView.html");
        //this.newSummaryViewController.title = MIOLocalizeString("OPERATION SUMMARY", "OPERATION SUMMARY");
        //this._operationSummaryViewController.reportID = "C1E32789-9434-41C2-8364-2D6D1CF951DD";
        this.summaryViewController.reportScriptPath = "python/cashdesk_section_summary.py";
        this.summaryViewController.title = MIOLocalizeString("CASHDESK SESSIONS SUMMARY", "CASHDESK SESSIONS SUMMARY");
        this.summaryViewController.showNavigationBar = false;  
        this.sectionsPageController.addPageViewController(this.summaryViewController);   

        this.sessionsViewController = new CashDeskSessionListViewController('cash-desk-sessions-list-view');
        this.sessionsViewController.initWithResource('layout/cashdesk/CashDeskSessionsListView.html');
        this.sectionsPageController.addPageViewController(this.sessionsViewController);
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _cashDesk:CashDesk = null;
    private mode = CalendarMode.Day;
    set cashDesk(cashDesk:CashDesk) {
        this._cashDesk = cashDesk;        
        this.updateUI();
    }

    private updateUI(){
        if(this.viewIsLoaded == false) return;
        
        let rh = ReportHelper.sharedInstance();
        let filters = {};
        if (this._cashDesk != null) {
            filters["cash_desk_id"] = this._cashDesk.identifier;
        }        

        this.summaryViewController.filterValues = filters;
        this.summaryViewController.reloadData();
        this.sessionsViewController.setItems(this._cashDesk, rh.selectedDateTimeFrom, rh.selectedDateTimeTo, this.mode);                
    }

    private exportCashDeskSummary() {
        this.summaryViewController.exportReport();
    }
}