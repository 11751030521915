//
// Generated class RoomGuest
//

/// <reference path="RoomGuest_ManagedObject.ts" />

class RoomGuest extends RoomGuest_ManagedObject
{

}
