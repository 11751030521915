
class TicketTotalLineCell extends UITableViewCell
{
    private totalLabel:MUILabel = null;

    awakeFromHTML(){
        this.totalLabel = MUIOutlet(this, "total-lbl", "MUILabel");
    }

    set item(item:string){
        this.totalLabel.text = item;
    }
}