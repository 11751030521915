//
// Generated class ContainerUnits
//

/// <reference path="ContainerUnits_ManagedObject.ts" />

class ContainerUnits extends ContainerUnits_ManagedObject
{

}
