//
// Generated class Migrate
//

/// <reference path="Migrate_ManagedObject.ts" />

class Migrate extends Migrate_ManagedObject
{

}
