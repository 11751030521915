class BookingDetailSourceView extends MUIView
{
    delegate = null;

    private sourceComboBox:MUIComboBox = null;
    private channelComboBox:MUIComboBox = null;    
    private recommendationComboBox:MUIComboBox = null;
        
    private moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

    awakeFromHTML(){        
        this.sourceComboBox = MUIOutlet(this, 'bdv_source_combobox','MUIComboBox');
        this.sourceComboBox.setOnChangeAction(this, function(combobox, value){
            let source:BookingSource = null;

            if(value == "-2"){
                this.showAddSourceView();
            }
            else if(value != "-1"){
                source = DBHelper.sharedInstance().objectAtIndexForEntityObserver(this, value, 'BookingSource');
                if(source == null) throw new Error('Error, no source');
            }

            this._item.bookingSource = source;
           
            this._item.sourceEmail = source ? source.email : null;
            this._item.sourcePhone = source ? source.phone : null;
            this.sourceEmailTextField.text = this._item.sourceEmail;
            this.sourcePhoneTextField.text = this._item.sourcePhone;            
        })

        this.channelComboBox = MUIOutlet(this, 'bdv_canal_combobox','MUIComboBox');
        this.channelComboBox.setOnChangeAction(this, function(combobox, value){            
            this._item.channelID = value != -1 ? value : null;
        });

        this.recommendationComboBox = MUIOutlet(this, 'recommendation-cb','MUIComboBox');
        this.recommendationComboBox.setOnChangeAction(this, function(control, value){
            this._item.recommendationID = value != -1 ? value : null;
        });        

    }
    
    private _item :Booking;
    set booking(i:Booking){
        if (this._item != null) {
            DBHelper.sharedInstance().removeObserverForChangesInEntity(this, "BookingSource");
            DBHelper.sharedInstance().removeObserverForChangesInEntity(this, "BookingChannel");
            DBHelper.sharedInstance().removeObserverForChangesInEntity(this, "BookingRecommendation");
        }
        
        this._item = i;
        
        DBHelper.sharedInstance().addObserverForEntity(this, "BookingSource", null, [MIOSortDescriptor.sortDescriptorWithKey("name", true)], null, function(objects){
           this.fillBookingSourceComboBox(this.sourceComboBox, objects, this._item.bookingSource);
        });        
        
        DBHelper.sharedInstance().addObserverForEntity(this, "BookingChannel", null, null, null, function(objects){
            this.fillBookingChannelComboBox(this.channelComboBox, objects, this._item.channelID);
        });

        DBHelper.sharedInstance().addObserverForEntity(this, "BookingRecommendation", null, [MIOSortDescriptor.sortDescriptorWithKey("name", true)], null, function(objects){
            this.fillBookingRecommendationComboBox(this.recommendationComboBox, objects, this._item.recommendationID);
        });
    }

    private fillBookingSourceComboBox(comboBox, objects, selectedSource){
        comboBox.removeAllItems();
        comboBox.addItem(MIOLocalizeString('NO SOURCE','Source...'), -1);    
        comboBox.addItem(MIOLocalizeString('NEW SOURCE','New Source'), -2);

        for (var i = 0; i < objects.length; i++) {
            let object: BookingSource = objects[i];
            comboBox.addItem(object.name, i);

            if (selectedSource === object) {
                comboBox.selectItem(i);
            }
        }
    }

    private fillBookingChannelComboBox(comboBox, objects, selectedChannelID){
        comboBox.removeAllItems();
        comboBox.addItem(MIOLocalizeString('NO CHANNEL','Channel...'), -1);  

        for (let i = 0; i < objects.length; i++) {
            let object: BookingChannel =    objects[i];
            comboBox.addItem(object.name, object.identifier);
        }

        if (selectedChannelID != null) {
            comboBox.selectItem(selectedChannelID);
        }
    }

    private fillBookingRecommendationComboBox(comboBox, objects, selectedRecommendationID){
        comboBox.removeAllItems();
        comboBox.addItem(MIOLocalizeString('NO RECOMMENDATION','Recomendation...'), -1);  

        for (let i = 0; i < objects.length; i++) {
            let object = objects[i] as BookingRecommendation;
            comboBox.addItem(object.name, object.identifier);
        }

        if (selectedRecommendationID != null) {
            comboBox.selectItem(selectedRecommendationID);
        }
    }

    private showAddSourceView(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('NEW SOURCE','New Source'), MIOLocalizeString('CHOOSE A NAME','Choose a name '), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){

            textField.setPlaceholderText(MIOLocalizeString('NAME',"Name"));
        });
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','Done'), MUIAlertActionStyle.Default, this, function(){
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
            let nameTF =  avc.textFields[0];
            let name = nameTF.text;
            if(name.length > 0){
                let bookingSource:BookingSource = MIOEntityDescription.insertNewObjectForEntityForName("BookingSource", moc) as BookingSource;
                bookingSource.identifier = MIOUUID.UUID().UUIDString;
                bookingSource.name = name;
                
                if(this.saveActivityIndicator) this.saveActivityIndicator.setHidden(false);
                moc.save();
                this._item.bookingSource = bookingSource;
                if(this.saveActivityIndicator) this.saveActivityIndicator.setHidden(true);
                
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'Error'), MIOLocalizeString('MUST WRITE A NAME FOR SOURCE','Must write a name for source '), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, null, null));
                
                this.presentViewController(error, true);
            }
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Default, null, null));
        
        this.delegate.presentViewController(avc, true);
    }
}
