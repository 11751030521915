

/// <reference path="ContainerUnits.ts" />

// Generated class ContainerCustom_ManagedObject

class ContainerCustom_ManagedObject extends ContainerUnits
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: numItems
    set numItems(value:number) {
        this.setValueForKey(value, 'numItems');
    }
    get numItems():number {
        return this.valueForKey('numItems');
    }
    set numItemsPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'numItems');
    }
    get numItemsPrimitiveValue():number {
        return this.primitiveValueForKey('numItems');
    }
    // Relationship: child
    set child(value:ContainerUnits) {
        this.setValueForKey(value, 'child');
    }
    get child():ContainerUnits {
        return this.valueForKey('child') as ContainerUnits;
    }
}
