
class BookingZoneDayLocationTypeRuleCell extends MUITableViewCell
{
    private minPAXTextField:MUITextField = null;
    private maxPAXTextField:MUITextField = null;
    private priceTypeComboBox:MUIComboBox = null;
    private priceTextField:MUITextField = null;

    awakeFromHTML(){

        this.minPAXTextField = MUIOutlet(this, "min-pax-tf", "MUITextField");
        this.minPAXTextField.setOnChangeText(this, function(control, value){
            this.locationTypePriceRule.minpax = value;
        });

        this.maxPAXTextField = MUIOutlet(this, "max-pax-tf", "MUITextField");
        this.maxPAXTextField.setOnChangeText(this, function(control, value){
            this.locationTypePriceRule.maxpax = value;
        });

        this.priceTypeComboBox = MUIOutlet(this, "price-type-cb", "MUIComboBox");
        this.priceTypeComboBox.setOnChangeAction(this, function(control, value){
            this.locationTypePriceRule.priceType = value;
        });

        this.priceTextField = MUIOutlet(this, "price-tf", "MUITextField");
        this.priceTextField.setOnChangeText(this, function(control, value){
            this.locationTypePriceRule.price = value;
        });

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }

    private locationTypePriceRule:BookingLocationTypePriceRule = null;
    set item(value:BookingLocationTypePriceRule){
        this.locationTypePriceRule = value;
        
        this.minPAXTextField.text = value.minpax;
        this.maxPAXTextField.text = value.maxpax;
        this.priceTypeComboBox.selectItem(value.priceType);
        this.priceTextField.text = value.price;
    }
}