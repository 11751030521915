

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class SubCashDesk_ManagedObject

class SubCashDesk_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: cashDrawerIndex
    set cashDrawerIndex(value:number) {
        this.setValueForKey(value, 'cashDrawerIndex');
    }
    get cashDrawerIndex():number {
        return this.valueForKey('cashDrawerIndex');
    }
    set cashDrawerIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'cashDrawerIndex');
    }
    get cashDrawerIndexPrimitiveValue():number {
        return this.primitiveValueForKey('cashDrawerIndex');
    }

    // Property: defaultFloat
    set defaultFloat(value:number) {
        this.setValueForKey(value, 'defaultFloat');
    }
    get defaultFloat():number {
        return this.valueForKey('defaultFloat');
    }
    set defaultFloatPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'defaultFloat');
    }
    get defaultFloatPrimitiveValue():number {
        return this.primitiveValueForKey('defaultFloat');
    }

    // Property: idIn
    set idIn(value:number) {
        this.setValueForKey(value, 'idIn');
    }
    get idIn():number {
        return this.valueForKey('idIn');
    }
    set idInPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'idIn');
    }
    get idInPrimitiveValue():number {
        return this.primitiveValueForKey('idIn');
    }

    // Property: isOpen
    set isOpen(value:boolean) {
        this.setValueForKey(value, 'isOpen');
    }
    get isOpen():boolean {
        return this.valueForKey('isOpen');
    }
    set isOpenPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isOpen');
    }
    get isOpenPrimitiveValue():boolean {
        return this.primitiveValueForKey('isOpen');
    }

    // Property: maximumCash
    set maximumCash(value:number) {
        this.setValueForKey(value, 'maximumCash');
    }
    get maximumCash():number {
        return this.valueForKey('maximumCash');
    }
    set maximumCashPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'maximumCash');
    }
    get maximumCashPrimitiveValue():number {
        return this.primitiveValueForKey('maximumCash');
    }

    // Property: money
    set money(value:number) {
        this.setValueForKey(value, 'money');
    }
    get money():number {
        return this.valueForKey('money');
    }
    set moneyPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'money');
    }
    get moneyPrimitiveValue():number {
        return this.primitiveValueForKey('money');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: password
    set password(value:string) {
        this.setValueForKey(value, 'password');
    }
    get password():string {
        return this.valueForKey('password');
    }
    set passwordPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'password');
    }
    get passwordPrimitiveValue():string {
        return this.primitiveValueForKey('password');
    }

    // Property: prefix
    set prefix(value:string) {
        this.setValueForKey(value, 'prefix');
    }
    get prefix():string {
        return this.valueForKey('prefix');
    }
    set prefixPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'prefix');
    }
    get prefixPrimitiveValue():string {
        return this.primitiveValueForKey('prefix');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }
    // Relationship: posClientDevice
    set posClientDevice(value:PosClientDevice) {
        this.setValueForKey(value, 'posClientDevice');
    }
    get posClientDevice():PosClientDevice {
        return this.valueForKey('posClientDevice') as PosClientDevice;
    }

    // Relationship: workers
    protected _workers:MIOManagedObjectSet = null;
    get workers():MIOManagedObjectSet {
        return this.valueForKey('workers');
    }
    addWorkersObject(value:Employee) {
        this._addObjectForKey(value, 'workers');
    }
    removeWorkersObject(value:Employee) {
        this._removeObjectForKey(value, 'workers');
    }
}
