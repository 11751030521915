

class PartyEventDetailViewController extends MUIViewController
{
    static newInstance() : PartyEventDetailViewController {
        let vc = new PartyEventDetailViewController("party-event-detail-view");
        vc.initWithResource("layout/party_event/PartyEventDetailView.html");
        return vc;
    }

    private sourceComboBox:MUIComboBox = null;
    private segmentedControl:MUISegmentedControl = null;
    private saveButton:MUIButton = null; 
    private addButton:MUIButton = null;   

    private pageController:MUIPageController = null;
    
    private infoViewController:PartyEventDetailInfoViewController = null;
    private ticketsViewController:PartyEventDetailTicketsViewController = null;
    // private actionVC:EventDetailActionViewController = null;    
    // private zoneSetupViewController:PartyDetailZoneSetupViewController = null;
    // private tablemapVC:EventDetailMapViewController = null;
    // private codesVC:OfferCodeDetailViewController = null;
    

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    private df = MUIWebApplication.sharedInstance().delegate.dateFormatter;

    viewDidLoad(){
        super.viewDidLoad();

        this.sourceComboBox = MUIOutlet(this, "channels-cb", "MUIComboBox");
        this.sourceComboBox.hidden = true;
        this.sourceComboBox.setOnChangeAction(this, this.setSourceAction);
        
        this.segmentedControl = MUIOutlet(this, 'segmented-control', 'MUISegmentedControl');
        this.segmentedControl.setAction(this, function (control, index) { 
            this.sourceComboBox.hidden = index == 1 ? false : true;
            this.addButton.hidden = index == 0 ? true : false;
            this.pageController.showPageAtIndex(index);
        });

        this.saveButton = MUIOutlet(this, 'save-btn','MUIButton');
        this.saveButton.setAction(this, function(){
           DBHelper.saveMainContext();
        });   
        
        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.hidden = true;
        this.addButton.setAction(this, this.addButtonAction);

        this.pageController = MUIOutlet(this, 'page-controller', 'MUIPageController');

        this.infoViewController = PartyEventDetailInfoViewController.newInstance();
        this.pageController.addPageViewController(this.infoViewController);
       
        // this.actionVC = new EventDetailActionViewController("event-detail-action-view");
        // this.actionVC.initWithResource("layout/event/EventDetailActionView.html");
        // //this.eventActionVC.navigationController = this.navigationController;        
        // this.pageController.addPageViewController(this.actionVC);

        // this.zoneSetupViewController = new PartyDetailZoneSetupViewController("parties-booking-zone-setup");
        // this.zoneSetupViewController.initWithResource("layout/parties/PartiesBookingZoneSetupView.html");
        // this.pageController.addPageViewController(this.zoneSetupViewController);

        // this.tablemapVC = new EventDetailMapViewController("event-detail-map-view");
        // this.tablemapVC.initWithResource("layout/event/EventDetailMapView.html");
        // //this.eventMapVC.navigationController = this.navigationController;        
        // this.pageController.addPageViewController(this.tablemapVC);

        // this.codesVC = new OfferCodeDetailViewController("offer-code-detail-view");
        // this.codesVC.initWithResource("layout/offers/OfferCodeDetailView.html");
        // this.pageController.addPageViewController(this.codesVC);

        this.ticketsViewController = PartyEventDetailTicketsViewController.newInstance();        
        this.pageController.addPageViewController(this.ticketsViewController);
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI(); 
    }

    private _event:PartyEvent = null;
    set event(value:PartyEvent){
        this._event = value;
        this.updateUI();
    }

    private updateUI(){
        if(this.viewIsLoaded == false || this._event == null) return;

        this.infoViewController.event = this._event;
        this.ticketsViewController.event = this._event;

        DBHelper.queryObjectsWithCompletion("BookingSource", [MIOSortDescriptor.sortDescriptorWithKey("name", true)], MIOPredicate.predicateWithFormat("deletedAt = null"), [], this, function(this:PartyEventDetailViewController, objects:BookingSource[]){
            this.sourceComboBox.removeAllItems();
            for (let s of objects) {
                this.sourceComboBox.addItem(s.name, s.identifier);
            }
        });
     } 

     private setSourceAction(control, index){
        let source = DBHelper.queryObjectFromMainContext("BookingSource", MIOPredicate.predicateWithFormat("identifier = " + this.sourceComboBox.getSelectedItem()));
        this.ticketsViewController.source = source;         
     }

     private addButtonAction(){
         switch(this.segmentedControl.selectedSegmentedIndex) {
             case 1: this.ticketsViewController.addTicketAction();
         }
     }

}