//
// Generated class TaxModifierAnnotation
//

/// <reference path="TaxModifierAnnotation_ManagedObject.ts" />

class TaxModifierAnnotation extends TaxModifierAnnotation_ManagedObject
{

}
