
class TimeRangeGroupDetailViewController extends EntityListViewController {

    private backButton:MUIButton = null;
    private titleLabel:MUILabel = null;
    // private addButton:MUIButton = null;
    private saveButton:MUIButton = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");
        this.backButton.setAction(this, function(control){
            this.navigationController.popViewController(true);
        });

        this.titleLabel = MUIOutlet(this,'title-lbl', 'MUILabel');

        // this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        // this.addButton.setAction(this, function (){            
        //     this.showAddTimeRange();
        // });

        this.saveButton = MUIOutlet(this, 'save-btn', 'MUIButton');
        this.saveButton.setAction(this, function (){            
            DBHelper.saveMainContextWithCompletion(this, function(){
                this.updateUI();
            });
        });
        
        this.tableView = MUIOutlet(this,"table-view","UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;                
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        
        this.updateUI();
    }

    private _timeRangeGroup: TimeRangeGroup = null;
    set item(item:TimeRangeGroup){        
        this._timeRangeGroup = item;
    }   

    private updateUI(){
        if (this._timeRangeGroup == null) return;
        if (this.viewIsLoaded == false) return;

        this.titleLabel.text = MIOLocalizeString('TIME RANGE FOR: ', 'TIME RANGE FOR: ') + this._timeRangeGroup.name;

        this.fetchedResultsController = null;
        this.tableView.reloadData();        
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell:TimeRangeCell = tableview.dequeueReusableCellWithIdentifier("TimeRangeCell") as TimeRangeCell;
        let item:TimeRange = this.fetchedResultsController.objectAtIndexPath(indexPath) as TimeRange;
        cell.item = item;
        return cell;
    }

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){ 
        //this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath); 
        // let selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath);
        // this.showItemDetail(selectedItem); 
    }

    // editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {         
    //     if (indexPath.row == 0) return MUITableViewCellEditingStyle.Delete;
    //     let count:any = this.fetchedResultsController.fetchedObjects.count;
    //     if (indexPath.row == count - 1) return MUITableViewCellEditingStyle.Delete;
    //     if (indexPath.row == 0) return MUITableViewCellEditingStyle.None;        
    // }

    // commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {        

    //     if (editingStyle == UITableViewCellEditingStyle.Delete ) {            
    //         this.removeTimeRange(indexPath);
    //     }
    // }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
        let fetchRequest:MIOFetchRequest = null;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("nextDay", true), MIOSortDescriptor.sortDescriptorWithKey("hour", true)]; 
        let predicate = "timeRangeGroup.identifier == '" + this._timeRangeGroup.identifier + "'";
        //let predicate = null;
        fetchRequest = DBHelper.listFetchRequestWithEntityName("TimeRange", sortDescriptors, predicate);
        fetchRequest.relationshipKeyPathsForPrefetching = ['timeRangeGroup'];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;        
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){        
        this.tableView.reloadData();
    }

    //show notice functions
    private showAddTimeRange() {

        let duration = this._timeRangeGroup.duration;
        
        let fix_date_string = MIODateGetDateString(new Date()) + " " + this._timeRangeGroup.endHour;
        let fix_date = MIODateFromString(fix_date_string);
        fix_date = new Date(fix_date.getTime() - (duration * 60000));
        let group_end_hour = MIODateGetTimeString(fix_date); 

        let last_end_hour = MIODateGetTimeString(new Date(fix_date.getTime() + ( (duration * 2) * 60000)) );

        
        let dateString = MIODateGetDateString(new Date()) + " " + group_end_hour;
        let date = MIODateFromString(dateString);
        let new_date = new Date(date.getTime() + duration * 60000);        
        let hour = MIODateGetTimeString(new_date);
        let next_day = MIODateGetDateString(date) == MIODateGetDateString(new_date) ? false : true;


        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let tr = MIOEntityDescription.insertNewObjectForEntityForName("TimeRange", ad.managedObjectContext) as TimeRange;

        tr.timeRangeGroup = this._timeRangeGroup;
        tr.groupName = this._timeRangeGroup.name;
        tr.name = hour;
        tr.hour = hour;
        tr.duration = duration;
        tr.nextDay = next_day;
                
        this._timeRangeGroup.endHour = last_end_hour;
        this._timeRangeGroup.addTimeRangesObject(tr);

        DBHelper.saveMainContext();
    }

    private removeTimeRange(indexPath:MIOIndexPath) {
        let count = this.fetchedResultsController.fetchedObjects.length - 1;
        if (indexPath.row == 0) {
            let i = this.fetchedResultsController.objectAtIndexPath(MIOIndexPath.indexForRowInSection(1, indexPath.section)) as TimeRange;
            this._timeRangeGroup.startHour = i.hour;
        }
        else if (indexPath.row == count) {
            let i = this.fetchedResultsController.objectAtIndexPath(MIOIndexPath.indexForRowInSection(count - 1, indexPath.section)) as TimeRange;
            this._timeRangeGroup.startHour = i.hour;
        }

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as TimeRange;
        DBHelper.deleteObjectFromMainContext(item, false);        

        DBHelper.saveMainContext();
    }

}
