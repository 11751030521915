

/// <reference path="DBImage.ts" />

// Generated class PartyImage_ManagedObject

class PartyImage_ManagedObject extends DBImage
{
    // Relationship: party
    set party(value:Party) {
        this.setValueForKey(value, 'party');
    }
    get party():Party {
        return this.valueForKey('party') as Party;
    }
}
