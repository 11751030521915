//
// Generated class ClientListItem
//

/// <reference path="ClientListItem_ManagedObject.ts" />

class ClientListItem extends ClientListItem_ManagedObject
{

}
