

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class EmployeePayrollConcept_ManagedObject

class EmployeePayrollConcept_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: expression
    set expression(value:string) {
        this.setValueForKey(value, 'expression');
    }
    get expression():string {
        return this.valueForKey('expression');
    }
    set expressionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'expression');
    }
    get expressionPrimitiveValue():string {
        return this.primitiveValueForKey('expression');
    }

    // Property: isEditable
    set isEditable(value:boolean) {
        this.setValueForKey(value, 'isEditable');
    }
    get isEditable():boolean {
        return this.valueForKey('isEditable');
    }
    set isEditablePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isEditable');
    }
    get isEditablePrimitiveValue():boolean {
        return this.primitiveValueForKey('isEditable');
    }

    // Property: key
    set key(value:string) {
        this.setValueForKey(value, 'key');
    }
    get key():string {
        return this.valueForKey('key');
    }
    set keyPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'key');
    }
    get keyPrimitiveValue():string {
        return this.primitiveValueForKey('key');
    }

    // Property: reconciliation
    set reconciliation(value:boolean) {
        this.setValueForKey(value, 'reconciliation');
    }
    get reconciliation():boolean {
        return this.valueForKey('reconciliation');
    }
    set reconciliationPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'reconciliation');
    }
    get reconciliationPrimitiveValue():boolean {
        return this.primitiveValueForKey('reconciliation');
    }

    // Property: title
    set title(value:string) {
        this.setValueForKey(value, 'title');
    }
    get title():string {
        return this.valueForKey('title');
    }
    set titlePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'title');
    }
    get titlePrimitiveValue():string {
        return this.primitiveValueForKey('title');
    }

    // Property: valueType
    set valueType(value:number) {
        this.setValueForKey(value, 'valueType');
    }
    get valueType():number {
        return this.valueForKey('valueType');
    }
    set valueTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'valueType');
    }
    get valueTypePrimitiveValue():number {
        return this.primitiveValueForKey('valueType');
    }
    // Relationship: taxType
    set taxType(value:LegalTaxType) {
        this.setValueForKey(value, 'taxType');
    }
    get taxType():LegalTaxType {
        return this.valueForKey('taxType') as LegalTaxType;
    }
}
