class EditConsumptionProfileDetailViewController extends MUIViewController
{
    private tableView:UITableView = null;
    private selectedItem = null;

    delegate = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;   
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);

        this.updateUI();
    }

    private _consumptionProfile:ConsumptionProfile = null;
    private _ellaborationConsumptionWarehouse:EllaborationConsumptionWarehouse[] = null;
    set item(value:ConsumptionProfile){
        this._consumptionProfile = value;

        if (this._consumptionProfile != null) {
            // query what you need here
            let relationships = ["consumptionProfile", "ellaborationCenter", "warehouse"];
            DBHelper.queryObjectsWithCompletion("EllaborationConsumptionWarehouse", null, MIOPredicate.predicateWithFormat("consumptionProfile == " + this._consumptionProfile.identifier), relationships, this, function(objects){
                this._ellaborationConsumptionWarehouse = objects;
                this.updateUI();
            });   
        }

            


        // let predicate = null;
        // if (productCategories.length > 0) {
        //     // setup predicate for various categories
        //     let predicateString = "category.identifier = '" + productCategories[0].identifier + "'";
        //     for (let i = 1; i < productCategories.length; i++) {
        //         predicateString += " OR category.identifier = '" + productCategories[i].identifier + "'"
        //     }
        //     predicate = MIOPredicate.predicateWithFormat(predicateString);
        // } 

        // DBHelper.queryObjectsWithCompletion("Product", sortDescriptors, predicate, ["category", "productFormats"], this, function(objects){
        
        //     if (objects.length <= 0){
        //         let msg = new MUIAlertViewController();
        //         msg.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('NO OBJECTS TO CHANGE FOUND','NO OBJECTS TO CHANGE FOUND'), MUIAlertViewStyle.Default);
        //         msg.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
        //         this.presentViewController(msg, true);
        //         return;
        //     }



    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._consumptionProfile == null) return;

        this.fetchedResultsController = null;
        this.tableView.reloadData();            
    }

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
        let cell = tableView.dequeueReusableCellWithIdentifier('EditConsumptionProfileDetailCell') as EditConsumptionProfileDetailCell;        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as EllaborationCenter;
        
        cell.ellaborationCenter = item;
        cell.consumptionProfile = this._consumptionProfile;

        for (let i = 0; i < this._ellaborationConsumptionWarehouse.length; i++){
            if(this._ellaborationConsumptionWarehouse[i].ellaborationCenter == item){
                cell.ellaborationConsumptionWarehouse = this._ellaborationConsumptionWarehouse[i];
            }
        }

        cell.selectionStyle = UITableViewCellSelectionStyle.None;
        return cell;
    }
    
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        // this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath) as ConsumptionProfile;
        // this.showSelectedConsumptionProfile(this.selectedItem);
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("EllaborationCenter");
        fetchRequest.sortDescriptors = null;
        fetchRequest.predicate = null; // MIOPredicate.predicateWithFormat("rate.identifier == " + this._rate.identifier);
        //fetchRequest.relationshipKeyPathsForPrefetching = ['consumptionProfile'];
                
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }

}