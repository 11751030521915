/**
 * Used layout from SettingsFormatSetupViewController
 */

class KitchenOrderSetupViewController extends SingleEditViewController
{
    get title(){
        return MIOLocalizeString("KITCHEN ORDER","KITCHEN ORDER");
    }

    fetchEntity(){
        return "CategoryTag";
    }

    configureCellAtIndexPath(cell:SingleEditCell, indexPath:MIOIndexPath, item:MIOManagedObject){
        let d = item as CategoryTag;
        cell.setItem(d, "name");
    }

    addButtonDidPress(){

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('NEW CATEGORY TAG','NEW CATEGORY TAG'), MIOLocalizeString('CHOOSE A NAME','CHOOSE A NAME'), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD','ADD'), MUIAlertActionStyle.Default, this, function(){
            let nameTF =  avc.textFields[0];
            let name = nameTF.text.trim();

            if(name.length > 0){
                let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
                var categoryTag = MIOEntityDescription.insertNewObjectForEntityForName("CategoryTag", moc) as CategoryTag;
                categoryTag.identifier = MIOUUID.UUID().UUIDString;
                categoryTag.name = name;
                moc.save();
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('CHECK NAME','CHECK NAME'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CLOSE','CLOSE'), MUIAlertActionStyle.Cancel, this, function(){}));
                
                this.presentViewController(error, true);
            }
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Cancel, this, function(){}));
        
        this.presentViewController(avc, true);
    }
    
}
