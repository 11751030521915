

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Service_ManagedObject

class Service_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: colorB
    set colorB(value:number) {
        this.setValueForKey(value, 'colorB');
    }
    get colorB():number {
        return this.valueForKey('colorB');
    }
    set colorBPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'colorB');
    }
    get colorBPrimitiveValue():number {
        return this.primitiveValueForKey('colorB');
    }

    // Property: colorG
    set colorG(value:number) {
        this.setValueForKey(value, 'colorG');
    }
    get colorG():number {
        return this.valueForKey('colorG');
    }
    set colorGPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'colorG');
    }
    get colorGPrimitiveValue():number {
        return this.primitiveValueForKey('colorG');
    }

    // Property: colorR
    set colorR(value:number) {
        this.setValueForKey(value, 'colorR');
    }
    get colorR():number {
        return this.valueForKey('colorR');
    }
    set colorRPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'colorR');
    }
    get colorRPrimitiveValue():number {
        return this.primitiveValueForKey('colorR');
    }

    // Property: duration
    set duration(value:number) {
        this.setValueForKey(value, 'duration');
    }
    get duration():number {
        return this.valueForKey('duration');
    }
    set durationPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'duration');
    }
    get durationPrimitiveValue():number {
        return this.primitiveValueForKey('duration');
    }

    // Property: info
    set info(value:string) {
        this.setValueForKey(value, 'info');
    }
    get info():string {
        return this.valueForKey('info');
    }
    set infoPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'info');
    }
    get infoPrimitiveValue():string {
        return this.primitiveValueForKey('info');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: noteTemplate
    set noteTemplate(value:string) {
        this.setValueForKey(value, 'noteTemplate');
    }
    get noteTemplate():string {
        return this.valueForKey('noteTemplate');
    }
    set noteTemplatePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'noteTemplate');
    }
    get noteTemplatePrimitiveValue():string {
        return this.primitiveValueForKey('noteTemplate');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Relationship: automaticProducts
    protected _automaticProducts:MIOManagedObjectSet = null;
    get automaticProducts():MIOManagedObjectSet {
        return this.valueForKey('automaticProducts');
    }
    addAutomaticProductsObject(value:LocationCategoryAutoProduct) {
        this._addObjectForKey(value, 'automaticProducts');
    }
    removeAutomaticProductsObject(value:LocationCategoryAutoProduct) {
        this._removeObjectForKey(value, 'automaticProducts');
    }
    // Relationship: category
    set category(value:ServiceCategory) {
        this.setValueForKey(value, 'category');
    }
    get category():ServiceCategory {
        return this.valueForKey('category') as ServiceCategory;
    }
}
