//
// Generated class ClientLicenseOffer
//

/// <reference path="ClientLicenseOffer_ManagedObject.ts" />

class ClientLicenseOffer extends ClientLicenseOffer_ManagedObject
{

}
