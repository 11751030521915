//
// Generated class DBDocumentLine
//

/// <reference path="DBDocumentLine_ManagedObject.ts" />

class DBDocumentLine extends DBDocumentLine_ManagedObject
{
    get parentPath():string {
        if (this.parent != null) return this.parent.parentPath + "." + this.concept;
        return this.concept;
    }
}
