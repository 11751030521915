
/// <reference path="StockTemplateLineCell.ts" />

class StockTemplateProductLineCell extends StockTemplateLineCell
{
    delegate = null;

    private productTextField:MUITextField = null;
    
    awakeFromHTML(){
        super.awakeFromHTML();
        this.productTextField = MUIOutlet(this, "product-name-tf", "MUITextField");
        this.setupProductTextField(this.productTextField, this, this.productDidSelect);

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }
    
    private _template:StockTemplate = null;
    set template(template:StockTemplate){
        this._template = template;
    }

    set item (item:StockTemplateProductItem){
        if (this.nameLabel != null) this.nameLabel.text = item.product.name;
    }    

    protected setupProductTextField(textField:MUITextField, target, completion){
        if (textField == null) return;        
        textField.setOnChangeText(this, function(control, value:string){
                AppHelper.sharedInstance().showSelectStockProductViewControllerFromView(textField, value, target, completion, null, null);
            }
        );
    }

    productDidSelect(controller:SelectEntityViewController, product:Product){
        this.product = product;
        this.productName = product.stockName ? product.stockName : product.name;
        
        this.productTextField.text = this.productNameString();                
        this.enterDidPress();
    }

    private product = null;
    private productName = null;
    setProduct(product:Product){
        this.product = product;
        this.productName = product.stockName != null ? product.stockName : product.name;        
    }

    enterDidPress(){            
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let line = MIOEntityDescription.insertNewObjectForEntityForName("StockTemplateProductItem", ad.managedObjectContext) as StockTemplateProductItem; 
        line.product = this.product;
        line.productName = this.productName;
        line.template = this._template;
        
        if (line != null) {
            this.lineDidInsert(line);
            this.reset();
        }
    }

    protected lineDidInsert(line:StockTemplateItem){
        if (this.delegate != null && typeof this.delegate.lineDidInsert === "function"){
            this.delegate.lineDidInsert(this, line);
        }
    }

    reset(){
        this.product = null;        
        this.productName = null;
        
        if (this.productTextField != null) this.productTextField.text = null;
        if (this.nameLabel != null) this.nameLabel.text = null;
    }

    protected productNameString(){        
        return this.productName;        
    }

}