
interface MIOManagedObject
{    
    identifierString: string;
    entityReferenceID: string;
    serializeChangedValues(): { [key: string]:any };
    deserializeFromValues(values?: { [key: string] : any }) : void;    
    indentPathForKey( attributeKey: string, relationKey: string ): [string, number];
}

function MIOManagedObjectFromIdentifier (identifier: string, entity: MIOEntityDescription, moc: MIOManagedObjectContext) : MIOManagedObject 
{
    let req = MIOFetchRequest.fetchRequestWithEntityName(entity.name);
    req.predicate = MIOPredicate.predicateWithFormat("identifier = '" + identifier + "'");
    let results = moc.executeFetch(req);
    
    if (results.count > 0) { return results.firstObject; }
    
    let obj = MIOEntityDescription.insertNewObjectForEntityForName(entity.name, moc);
    obj.setValueForKey( identifier, "identifier");
    
    return obj;
}

function MIOManagedObjectIdentifierString(obj:any):string
{
    if (obj instanceof MIOManagedObject) {
        let mo = obj as MIOManagedObject;
        return mo.valueForKey("identifier");
    } 
    else if (obj instanceof MIOManagedObjectID) {
        let objID = obj as MIOManagedObjectID;
        return objID._getReferenceObject();
    }
}

Object.defineProperty(MIOManagedObject, "identifierString", {
    get: function () {
        return MIOManagedObjectIdentifierString(this);
    },
    enumerable: true,
    configurable: true
})

Object.defineProperty(MIOManagedObject, "entityReferenceID", {
    get: function () {
        return this.entity.name + "://" + MIOManagedObjectIdentifierString(this);
    },
    enumerable: true,
    configurable: true
})


MIOManagedObject.prototype.serializeChangedValues = function() : { [key: string]: any }
{
    const changes = this.changedValues;
    let serializedChanges:{ [key: string] : any } = {};
    
    if (changes.count == 0) { return serializedChanges; }
    
    for (let key in changes) {
        const value = changes[key];
        
        const property = this.entity.propertiesByName[key];
        if (property == null) { continue; }
        
        if (property instanceof MIOAttributeDescription) {
            const attr = property as MIOAttributeDescription;
            serializedChanges[attr.serverName] = attr.serializeFromValue(value);
        }
        else if (property instanceof MIORelationshipDescription) {
            const rel = property as MIORelationshipDescription;
            if (!rel.isIgnored()) {
                let cv = this["_" + key]; //HACK: please remove it
                if (cv == null) { cv = MIOManagedObjectSet._setWithManagedObject(this, rel); }
                serializedChanges[rel.serverName] = rel.serializeFromValue(value, cv);
            }
        }
    }
    
    return serializedChanges;
}
    
MIOManagedObject.prototype.deserializeFromValues = function(values?: { [key: string] : any }) : void
{
    if (values == null) { return; }
    
    for (let key in values) {
        const value = values[key];

        const property = this.entity.propertiesByName[key];
        if (property == null) { continue; }
        
        if (property instanceof MIOAttributeDescription) {
            const attr = property as MIOAttributeDescription;
            let v = attr.deserializeFromValue( value );
            this.setValueForKey(v, key);
        }
    }
}

MIOManagedObject.prototype.indentPathForKey = function( attributeKey: string, relationKey: string ) : [string, number]
{
    let path = this.valueForKey( attributeKey );

    let level = 0;
    let parent = this.valueForKey( relationKey );
    while (parent != null) {
        level += 1;
        let [p, l] = parent.indentPathForKey( attributeKey, relationKey );
        path = p + "." + path;
        parent = parent.valueForKey( relationKey );
    }

    return [path, level];
}

