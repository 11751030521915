//
// Generated class FilterTicket
//

/// <reference path="FilterTicket_ManagedObject.ts" />

class FilterTicket extends FilterTicket_ManagedObject
{
    identifier           = null;
    num                  = null;
    id_receipt           = null;
    closed               = null;
    receiptname          = null;
    receiptphone         = null;
    receiptaddress       = null;
    receiptaddress2      = null;
    receiptdocument      = null;
    receiptid            = null;
    modifiedbyuser       = null;
    tags                 = null;
    date                 = null;
    price                = null;
    locationcategoryname = null;
    locationname         = null;
    numberofpeople       = null;
    opendate             = null;
    ordertype            = null;
    totalpricebase       = null;
    totaltaxes           = null;
    totalprice           = null;
    totaldiscount        = null;
    totalinvited         = null;
    totaldeleted         = null;
    nextdocument         = null;
    sessionbegindate     = null;
    groups:MIOSet = null;
    
    private df = MUIWebApplication.sharedInstance().delegate.dateFormatter;
    private sdtf = MUIWebApplication.sharedInstance().delegate.serverDateTimeFormatter;

    setObject(object, moc)
    {
        this.identifier           = object['id'];
        this.num                  = object['documentid'];
        this.id_receipt           = object['idreceipt'];
        this.receiptname          = object['linkedclientname'];
        this.receiptphone         = object['linkedclientphone'];
        this.receiptaddress       = object["linkedclientaddress"];
        this.receiptaddress2      = object["linkedclientaddress2"];
        this.receiptdocument      = object["linkedclientdocument"];
        this.receiptid              = object["linkedclientid"];
        this.locationcategoryname = object["locationcategoryname"];
        this.locationname         = object["locationname"];
        this.numberofpeople       = object["numberofpeople"];
        this.modifiedbyuser       = object["modifiedbyuser"];
        this.opendate             = object["opendate"];
        this.ordertype            = object["ordertype"];
        this.closed               = object['closed'];
        this.tags                 = object['tags'];
        this.price                = object['price'];
        this.date                 = object['date'];
        this.totalpricebase       = object['totalpricebase'];
        this.totaltaxes           = object['totaltaxes'];
        this.totalprice           = object['totalprice'];
        this.totaldiscount        = object['totaldiscount'];
        this.totalinvited         = object['totalinvited'];
        this.totaldeleted         = object['totaldeleted'];
        this.nextdocument         = object['nextdocument'];
        
        this.groups = MIOSet.set();

        if(object['archivedTicketLinesGroup']){
            var instance = this;
            var groups = object["archivedTicketLinesGroup"] || [];
            groups.forEach(function (group, index) {
                var p = MIOEntityDescription.insertNewObjectForEntityForName("FilterTicketGroupLine", moc) as FilterTicketGroupLine;
                group['ticket'] = instance;
                p.setObject(group, index, moc);
                instance.groups.addObject(p);
            }, instance);
        }
    }
}
