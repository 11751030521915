
class DBLocationCoder extends DBXLSXCoder
{
    protected mainEntity() { 
        return { entity: "Location", predicateFormat: "deletedAt == null", relations:["category"] };
    }

    columns() : any[] {
        let cols = [
            { title: "LOCATION CATEGORY ID", width: "30%", align: "center" },
            { title: "LOCATION CATEGORY NAME", width: "30%", align: "center" },
            { title: "LOCATION ID", width: "30%", align: "center" },
            { title: "LOCATION NAME", width: "30%", align: "center" },
            { title: "TYPE", width: "30%", align: "center", formatter: this.ad.integerFormatter },
            { title: "ANGLE", width: "30%", align: "center", formatter: this.ad.numberFormatter },
            { title: "IS TAKEN", width: "30%", align: "center" },
            { title: "X", width: "30%", align: "center", formatter: this.ad.numberFormatter },
            { title: "Y", width: "30%", align: "center", formatter: this.ad.numberFormatter },
            { title: "TAGS", width: "30%", align: "center" },
        ]
        return cols;
    }

    protected aditionalImportEntities(){
        return [
            { entity: "LocationCategory", predicateFormat: "deletedAt == null" }
        ]
    }


    protected importRow(row:any){
                
        let categoryID    = row["LOCATION CATEGORY ID"]
        let categoryName  = row["LOCATION CATEGORY NAME"];
        let identifier    = row["LOCATION ID"];
        let name          = row["LOCATION NAME"];
        let type          = row["TYPE"];
        let isTaken       = row["IS TAKEN"];
        let angle         = row["ANGLE"];
        let x             = row["X"];
        let y             = row["Y"];
        let tags          = row["TAGS"];

        let category = this.queryEntityByIDOrName("LocationCategory", categoryID, categoryName) as LocationCategory;
        if (category == null) {
            return;
        }

        let loc = this.queryEntityByField("Location", "identifier", name) as Location;
        if (loc == null) {
            loc = MIOEntityDescription.insertNewObjectForEntityForName("Location", DBHelper.mainManagedObjectContext) as Location;
            loc.identifier = this.parseOrCreateIdentifier(identifier);
            this.appendObject(loc);
        }
        
        loc.name = name;
        loc.type = type;
        loc.isOccupied = this.parseBoolValue(isTaken);
        loc.angle = angle;
        loc.x = x;
        loc.y = y;
        loc.tags = tags;
        loc.category = category;
        
        MIOLog("ADDING LOCATION: " + this.rowIndex + "/" + this.rows.length + ": " + name);
    }

    protected exportTitle() : string { return "Locations"; }
                
    protected exportSortDescriptors() { 
        return [MIOSortDescriptor.sortDescriptorWithKey("name", false)]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let loc = object as Location;
        let item = {
            "LOCATION CATEGORY ID": loc.category.identifier,
            "LOCATION CATEGORY NAME": loc.category.name,
            "LOCATION ID": loc.identifier,
            "LOCATION NAME": loc.name,
            "TYPE": loc.type,
            "IS TAKEN": loc.isOccupied,
            "ANGLE": loc.angle,
            "X": loc.x,
            "Y": loc.y,
            "TAGS": loc.tags
            }

        return item;
    }
}