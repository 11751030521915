
enum RuleType
{
    Occupation,
    Price
}

class PartyDetailZoneSetupViewController extends MUIViewController
{    
    private zonesTableView:MUITableView = null;
    private ruleOccupationHeader:MUIView = null;
    private rulePriceHeader:MUIView = null;
    private rulesTableView:MUITableView = null;

    private zonesDataSource:PartyZonesSetupDataSource = null;
    private rulesOccupationDataSource:PartyOcupationRulesDataSource = null; 
    private rulesPriceDataSource:PartyPriceRulesDataSource = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.zonesTableView = MUIOutlet(this, "zones-table-view", "MUITableView");
        this.zonesDataSource = new PartyZonesSetupDataSource();
        this.zonesDataSource.initWithTableView(this.zonesTableView, this);
        this.zonesTableView.dataSource = this.zonesDataSource;
        this.zonesTableView.delegate = this.zonesDataSource;
        
        this.zonesTableView.reloadData();

        this.ruleOccupationHeader = MUIOutlet(this, "rule-occupation-header", "MUIView");
        this.rulePriceHeader = MUIOutlet(this, "rule-price-header", "MUIView");
        this.rulePriceHeader.hidden = true;

        this.rulesTableView = MUIOutlet(this, "rules-table-view", "MUITableView");
        this.rulesOccupationDataSource = new PartyOcupationRulesDataSource();
        this.rulesOccupationDataSource.initWithTableView(this.rulesTableView, this);
        this.rulesTableView.dataSource = this.rulesOccupationDataSource;        

        this.rulesPriceDataSource = new PartyPriceRulesDataSource();
        this.rulesPriceDataSource.initWithTableView(this.rulesTableView, this);

        this.rulesTableView.delegate = this;
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }
    
    private selectedEventID:string = null;
    set event(event:BookingEvent){
        this.selectedEventID = event.identifier;
        this.updateUI();       
    }

    private selectedPartyID:string = null;
    set party(party:Party){
        this.selectedPartyID = party.identifier;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this.selectedZone == null) return;

        if (this.selectedRuleType == RuleType.Occupation) this.rulesOccupationDataSource.setItems(this.selectedZone, this.selectedEventID, this.selectedPartyID);        
        else if (this.selectedRuleType == RuleType.Price) this.rulesPriceDataSource.setItems(this.selectedZone, this.selectedEventID, this.selectedPartyID);        
    }


    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let item = tableView.dataSource.fetchedResultsController.objectAtIndexPath(indexPath);

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            DBHelper.deleteObjectFromMainContext(item, true);
        }
    }

    private selectedRuleType = RuleType.Occupation;
    addRule(){
        if (this.selectedZone == null) return;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        
        if (this.selectedRuleType == RuleType.Occupation){
            let rule = MIOEntityDescription.insertNewObjectForEntityForName("BookingZoneOcupationRule", ad.managedObjectContext) as BookingZoneOcupationRule;
            rule.partyID = this.selectedPartyID;
            rule.eventID = this.selectedEventID;
            rule.zone = this.selectedZone;
        }
        else if (this.selectedRuleType == RuleType.Price){
            let rule = MIOEntityDescription.insertNewObjectForEntityForName("BookingPriceRule", ad.managedObjectContext) as BookingZoneOcupationRule;
            rule.partyID = this.selectedPartyID;
            rule.eventID = this.selectedEventID;
            rule.zone = this.selectedZone;
        }

        ad.managedObjectContext.save();
    }

    set ruleType(type:RuleType){
        this.selectedRuleType = type;
        switch(type){
            case RuleType.Occupation:
                this.ruleOccupationHeader.hidden = false;
                this.rulePriceHeader.hidden = true;
                this.rulesTableView.dataSource = this.rulesOccupationDataSource;
                this.updateUI();
                break;                 

            case RuleType.Price:
                this.rulePriceHeader.hidden = false;                
                this.ruleOccupationHeader.hidden = true;
                this.rulesTableView.dataSource = this.rulesPriceDataSource;
                this.updateUI();
                break;
        }
    }

    private selectedZone:BookingZone = null;   
    didSelectZone(zone:BookingZone){
        this.selectedZone = zone;  
        this.updateUI();
    }

}