class  ProductFormatAdminCell extends UITableViewCell
{
    private venueNameLabel:MUILabel = null;
    private categoryNameLabel:MUILabel = null;    
    private productNameLabel:MUILabel = null;    
    private enabledSalesSwitch:MUISwitchButton = null;

    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;

    awakeFromHTML(){
        this.venueNameLabel = MUIOutlet(this, "venue-lbl", "MUILabel");
        this.categoryNameLabel = MUIOutlet(this, "category-lbl", "MUILabel");        
        this.productNameLabel = MUIOutlet(this, "name-lbl", "MUILabel");        
        this.enabledSalesSwitch = MUIOutlet(this, "enabled-sales-sw", "MUISwitchButton");
        this.enabledSalesSwitch.setOnChangeValue(this, function(this:ProductAdminCell, control:MUISwitchButton, value:boolean) {             
        });

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private _item:ProductFormatPlace = null;
    setItem (item:ProductFormatPlace, placeName:string){
        this._item = item;

        this.venueNameLabel.text = placeName;
        this.categoryNameLabel.text = item.productFormat.product.category.name;
        this.productNameLabel.text = item.productFormat.product.productNameDescription();
        this.enabledSalesSwitch.on = item.isDefaultFormat;
        
    }    
}