//
// Generated class BookingLocationTypePriceRule
//

/// <reference path="BookingLocationTypePriceRule_ManagedObject.ts" />

class BookingLocationTypePriceRule extends BookingLocationTypePriceRule_ManagedObject
{

}
