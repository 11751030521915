/// <reference path="StockTemplateLineCell.ts" />


class StockTemplateDetailViewController extends BaseTableViewController
{
    static newInstance() : StockTemplateDetailViewController{
        let vc = new StockTemplateDetailViewController('stock-template-detail-view');        
        vc.initWithResource('layout/stock_template/StockTemplateDetailView.html');
        return vc;
    }

    private addCategoryButton:MUIButton = null;
    private saveButton:MUIButton = null;
    private titleTextField:MUITextField = null;
    private productTextField:MUITextField = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.addCategoryButton = MUIOutlet(this, "add-category-btn", "MUIButton");
        this.addCategoryButton.setAction(this, this.showAddCategory);

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        });

        this.titleTextField = MUIOutlet(this, "title-tf", "MUITextField");
        this.titleTextField.setOnChangeText(this, function(textField:MUITextField, value:string){
            let v = value.length > 0 ? value : null;
            this.template.name = v;
        });       

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;   
        this.tableView.delegate = this;  
        
        this.productTextField = MUIOutlet(this, "product-name-add-tf", "MUITextField");
        this.setupProductTextField(this.productTextField, this, this.productDidSelect);
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private template:StockTemplate = null;
    set item(item:StockTemplate){
        this.template = item;
        this.updateUI();
    }   

    private updateUI(){
        if (this.viewIsLoaded == false || this.template == null) return;

        this.titleTextField.text = this.template.name;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableview:UITableView){
        let sections = this.fetchedResultsController.sections.length;
        return sections;
    }
    
    numberOfRowsInSection(tableview:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    titleForHeaderInSection(tableView:UITableView, section:number){        
        let item = this.fetchedResultsController.objectAtIndexPath(MIOIndexPath.indexForRowInSection(0,section)) as StockTemplateItem;
        return item.typeString;
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell:StockTemplateProductLineCell |StockTemplateCategoryLineCell = null;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath);

        if (item instanceof StockTemplateProductItem) {
            cell = tableview.dequeueReusableCellWithIdentifier('EditLineCell') as StockTemplateProductLineCell;
            cell.item = item;
            cell.delegate = this;  
            cell.template = this.template;      
        }
        else if (item instanceof StockTemplateCategoryItem || item instanceof StockTemplateStockCategoryItem) {
            cell = tableview.dequeueReusableCellWithIdentifier('CategoryLineCell') as StockTemplateCategoryLineCell;
            cell.item = item;
        }
                        
        return cell;
    }
    
    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {
        if (indexPath.section == this.fetchedResultsController.sections.length) {
            return MUITableViewCellEditingStyle.Insert;
        }
        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {        

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            let item = this.fetchedResultsController.objectAtIndexPath(indexPath);    
            this.removeProductLine(item);
        }
    }  
        
    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        this.reloadData();
        this.mapReferences(controller.fetchedObjects);
        if (this.addedNewLine == true) {
            //this.tableView.scrollToBottom(false);
            this.addedNewLine = false;
        }
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('typeString', true)];    
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("StockTemplateItem", sortDescriptors, "template.identifier == " + this.template.identifier);
        fetchRequest.relationshipKeyPathsForPrefetching = ["product.taxBuy", "product.tax", "product.stockInputFormats", "product.supplierProducts", "category", "stockCategory"];        

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "typeString");
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    // protected addedNewLine = false;
    // lineDidInsert(cell:StockTemplateLineCell, line:StockTemplateItem){
    //     this.addedNewLine = true;
    //     DBHelper.saveMainContext();
    // }

    private removeProductLine(line:StockTemplateItem){
        DBHelper.deleteObjectFromMainContext(line, true);
    }

    private showAddCategory(){
        // AppHelper.sharedInstance().showSelectCategoryViewControllerFromView(this.addCategoryButton, null, this, function(controller:SelectEntityViewController, category:ProductCategory){
        //     this.addCategory(category);
        // }, null, null);

        AppHelper.sharedInstance().showSelectCategoriesViewControllerFromView(this.addCategoryButton, (stockCategory:StockCategory, salesCategory:ProductCategory) => {
            this.addCategory(stockCategory, salesCategory);
        } );
    }

    private addCategory(stockCategory:StockCategory, category:ProductCategory) {
        if (category != null) this.addSalesCategory(category);
        else if (stockCategory != null) this.addStockCategory(stockCategory);
    }

    private addSalesCategory(category:ProductCategory) {
        if (this.itemExists(category)) {
            AppHelper.showAlert(this, "ADD ITEM", "YOU CAN NOT ADD THE CATEGORY BECAUSE IS ALREADY IN THE TEMPLATE");
            return;
        }
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let line = MIOEntityDescription.insertNewObjectForEntityForName("StockTemplateCategoryItem", ad.managedObjectContext) as StockTemplateCategoryItem; 
        line.category = category;
        line.template = this.template;

        DBHelper.saveMainContext();
    }

    private addStockCategory(category:StockCategory) {
        if (this.itemExists(category)) {
            AppHelper.showAlert(this, "ADD ITEM", "YOU CAN NOT ADD THE CATEGORY BECAUSE IS ALREADY IN THE TEMPLATE");
            return;
        }
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let line = MIOEntityDescription.insertNewObjectForEntityForName("StockTemplateStockCategoryItem", ad.managedObjectContext) as StockTemplateStockCategoryItem; 
        line.stockCategory = category;
        line.template = this.template;

        DBHelper.saveMainContext();
    }

    // ADD PRODUCT

    protected setupProductTextField(textField:MUITextField, target, completion){
        if (textField == null) return;        
        textField.setOnChangeText(this, function(control, value:string){
                AppHelper.sharedInstance().showSelectStockProductViewControllerFromView(textField, value, target, completion, null, null);
            }
        );
    }

    private selectedProduct:Product = null;
    productDidSelect(controller:SelectEntityViewController, product:Product){
        if (this.itemExists(product)){
            AppHelper.showAlert(this, "ADD ITEM", "YOU CAN NOT ADD THE PRODUCT BECAUSE IS ALREADY IN THE TEMPLATE");
            return;
        }
        this.selectedProduct = product;
        
        this.productTextField.text = this.selectedProduct.name;
        this.enterDidPress();
    }

    protected addedNewLine = false;
    enterDidPress(){            
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let line = MIOEntityDescription.insertNewObjectForEntityForName("StockTemplateProductItem", ad.managedObjectContext) as StockTemplateProductItem; 
        line.identifier = new MIOUUID().UUIDString;
        line.product = this.selectedProduct;
        line.productName = this.selectedProduct.name;
        line.template = this.template;

        this.addedNewLine = true;
        DBHelper.saveMainContext();
        this.reset();
    }

    reset(){
        this.selectedProduct = null;                
        this.productTextField.text = null;        
    }

    private itemsReferences = new MIOSet();
    private mapReferences (objects:StockTemplateItem[]) {

        this.itemsReferences = new MIOSet();
        for (let o of objects) {
            if (o instanceof StockTemplateProductItem) {
                let ref = "Product" + "://" + o.product.identifier.toUpperCase();
                this.itemsReferences.addObject(ref);
            }
            else if (o instanceof StockTemplateCategoryItem){
                let ref = "ProductCategory" + "://" + o.category.identifier.toUpperCase();
                this.itemsReferences.addObject(ref);                
            }
        }
    }

    private itemExists(object: StockCategory | ProductCategory | Product) : boolean {
        let ref = object.entity.name + "://" + object.valueForKey("identifier").toUpperCase();
        return this.itemsReferences.containsObject(ref);
    }


}