

/// <reference path="LegalEntity.ts" />

// Generated class Partner_ManagedObject

class Partner_ManagedObject extends LegalEntity
{

    // Property: enabled
    set enabled(value:boolean) {
        this.setValueForKey(value, 'enabled');
    }
    get enabled():boolean {
        return this.valueForKey('enabled');
    }
    set enabledPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'enabled');
    }
    get enabledPrimitiveValue():boolean {
        return this.primitiveValueForKey('enabled');
    }

    // Property: googleMapURL
    set googleMapURL(value:string) {
        this.setValueForKey(value, 'googleMapURL');
    }
    get googleMapURL():string {
        return this.valueForKey('googleMapURL');
    }
    set googleMapURLPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'googleMapURL');
    }
    get googleMapURLPrimitiveValue():string {
        return this.primitiveValueForKey('googleMapURL');
    }

    // Property: offer1
    set offer1(value:string) {
        this.setValueForKey(value, 'offer1');
    }
    get offer1():string {
        return this.valueForKey('offer1');
    }
    set offer1PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'offer1');
    }
    get offer1PrimitiveValue():string {
        return this.primitiveValueForKey('offer1');
    }

    // Property: offer2
    set offer2(value:string) {
        this.setValueForKey(value, 'offer2');
    }
    get offer2():string {
        return this.valueForKey('offer2');
    }
    set offer2PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'offer2');
    }
    get offer2PrimitiveValue():string {
        return this.primitiveValueForKey('offer2');
    }

    // Property: offer3
    set offer3(value:string) {
        this.setValueForKey(value, 'offer3');
    }
    get offer3():string {
        return this.valueForKey('offer3');
    }
    set offer3PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'offer3');
    }
    get offer3PrimitiveValue():string {
        return this.primitiveValueForKey('offer3');
    }

    // Property: offer4
    set offer4(value:string) {
        this.setValueForKey(value, 'offer4');
    }
    get offer4():string {
        return this.valueForKey('offer4');
    }
    set offer4PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'offer4');
    }
    get offer4PrimitiveValue():string {
        return this.primitiveValueForKey('offer4');
    }

    // Property: parterDescription
    set parterDescription(value:string) {
        this.setValueForKey(value, 'parterDescription');
    }
    get parterDescription():string {
        return this.valueForKey('parterDescription');
    }
    set parterDescriptionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'parterDescription');
    }
    get parterDescriptionPrimitiveValue():string {
        return this.primitiveValueForKey('parterDescription');
    }

    // Property: partnerBankAccount
    set partnerBankAccount(value:string) {
        this.setValueForKey(value, 'partnerBankAccount');
    }
    get partnerBankAccount():string {
        return this.valueForKey('partnerBankAccount');
    }
    set partnerBankAccountPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'partnerBankAccount');
    }
    get partnerBankAccountPrimitiveValue():string {
        return this.primitiveValueForKey('partnerBankAccount');
    }

    // Property: partnerPlaceID
    set partnerPlaceID(value:string) {
        this.setValueForKey(value, 'partnerPlaceID');
    }
    get partnerPlaceID():string {
        return this.valueForKey('partnerPlaceID');
    }
    set partnerPlaceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'partnerPlaceID');
    }
    get partnerPlaceIDPrimitiveValue():string {
        return this.primitiveValueForKey('partnerPlaceID');
    }

    // Property: partnerSchemaID
    set partnerSchemaID(value:string) {
        this.setValueForKey(value, 'partnerSchemaID');
    }
    get partnerSchemaID():string {
        return this.valueForKey('partnerSchemaID');
    }
    set partnerSchemaIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'partnerSchemaID');
    }
    get partnerSchemaIDPrimitiveValue():string {
        return this.primitiveValueForKey('partnerSchemaID');
    }

    // Property: promotion
    set promotion(value:string) {
        this.setValueForKey(value, 'promotion');
    }
    get promotion():string {
        return this.valueForKey('promotion');
    }
    set promotionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'promotion');
    }
    get promotionPrimitiveValue():string {
        return this.primitiveValueForKey('promotion');
    }

    // Property: urlInstagram
    set urlInstagram(value:string) {
        this.setValueForKey(value, 'urlInstagram');
    }
    get urlInstagram():string {
        return this.valueForKey('urlInstagram');
    }
    set urlInstagramPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'urlInstagram');
    }
    get urlInstagramPrimitiveValue():string {
        return this.primitiveValueForKey('urlInstagram');
    }

    // Property: urlTFacebook
    set urlTFacebook(value:string) {
        this.setValueForKey(value, 'urlTFacebook');
    }
    get urlTFacebook():string {
        return this.valueForKey('urlTFacebook');
    }
    set urlTFacebookPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'urlTFacebook');
    }
    get urlTFacebookPrimitiveValue():string {
        return this.primitiveValueForKey('urlTFacebook');
    }

    // Property: urlTwitter
    set urlTwitter(value:string) {
        this.setValueForKey(value, 'urlTwitter');
    }
    get urlTwitter():string {
        return this.valueForKey('urlTwitter');
    }
    set urlTwitterPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'urlTwitter');
    }
    get urlTwitterPrimitiveValue():string {
        return this.primitiveValueForKey('urlTwitter');
    }

    // Property: urlYouTube
    set urlYouTube(value:string) {
        this.setValueForKey(value, 'urlYouTube');
    }
    get urlYouTube():string {
        return this.valueForKey('urlYouTube');
    }
    set urlYouTubePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'urlYouTube');
    }
    get urlYouTubePrimitiveValue():string {
        return this.primitiveValueForKey('urlYouTube');
    }

    // Relationship: children
    protected _children:MIOManagedObjectSet = null;
    get children():MIOManagedObjectSet {
        return this.valueForKey('children');
    }
    addChildrenObject(value:Partner) {
        this._addObjectForKey(value, 'children');
    }
    removeChildrenObject(value:Partner) {
        this._removeObjectForKey(value, 'children');
    }
    // Relationship: deliveryPresetConfiguration
    set deliveryPresetConfiguration(value:DeliveryTimeRangePresetConfiguration) {
        this.setValueForKey(value, 'deliveryPresetConfiguration');
    }
    get deliveryPresetConfiguration():DeliveryTimeRangePresetConfiguration {
        return this.valueForKey('deliveryPresetConfiguration') as DeliveryTimeRangePresetConfiguration;
    }
    // Relationship: detailImage
    set detailImage(value:DBImage) {
        this.setValueForKey(value, 'detailImage');
    }
    get detailImage():DBImage {
        return this.valueForKey('detailImage') as DBImage;
    }
    // Relationship: logo
    set logo(value:DBImage) {
        this.setValueForKey(value, 'logo');
    }
    get logo():DBImage {
        return this.valueForKey('logo') as DBImage;
    }
    // Relationship: parent
    set parent(value:Partner) {
        this.setValueForKey(value, 'parent');
    }
    get parent():Partner {
        return this.valueForKey('parent') as Partner;
    }
    // Relationship: takeawayPresetConfiguration
    set takeawayPresetConfiguration(value:TakeAwayTimeRangePresetConfiguration) {
        this.setValueForKey(value, 'takeawayPresetConfiguration');
    }
    get takeawayPresetConfiguration():TakeAwayTimeRangePresetConfiguration {
        return this.valueForKey('takeawayPresetConfiguration') as TakeAwayTimeRangePresetConfiguration;
    }
}
