

/// <reference path="VenueEvent.ts" />

// Generated class PartyEvent_ManagedObject

class PartyEvent_ManagedObject extends VenueEvent
{

    // Property: externalReference
    set externalReference(value:string) {
        this.setValueForKey(value, 'externalReference');
    }
    get externalReference():string {
        return this.valueForKey('externalReference');
    }
    set externalReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'externalReference');
    }
    get externalReferencePrimitiveValue():string {
        return this.primitiveValueForKey('externalReference');
    }
    // Relationship: image
    set image(value:DBImage) {
        this.setValueForKey(value, 'image');
    }
    get image():DBImage {
        return this.valueForKey('image') as DBImage;
    }
    // Relationship: party
    set party(value:Party) {
        this.setValueForKey(value, 'party');
    }
    get party():Party {
        return this.valueForKey('party') as Party;
    }
}
