//
// Generated class StockCostAnnotation
//

/// <reference path="StockCostAnnotation_ManagedObject.ts" />

class StockCostAnnotation extends StockCostAnnotation_ManagedObject
{

}
