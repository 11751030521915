//
// Generated class CashDeskBalance
//

/// <reference path="CashDeskBalance_ManagedObject.ts" />

class CashDeskBalance extends CashDeskBalance_ManagedObject
{

}
