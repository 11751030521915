class BookingCustomerCell extends MUITableViewCell
{    
    delegate = null;

    private nameTextField:MUITextField = null;
    private mobileTextField:MUITextField = null;
    private phoneTextField:MUITextField = null;
    private emailTextField:MUITextField = null;
    private postalCodeTextField:MUITextField = null;
    private cityTextField:MUITextField = null;
    private countryDropDown:MUIButton = null;

    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;

    awakeFromHTML(){
        
        this.nameTextField = MUIOutlet(this, "client-name-tf", "MUITextField"); 
        this.nameTextField.setOnChangeText(this, function(control, value){
            this.booking.client.name = value;
        }); 
            
        this.mobileTextField = MUIOutlet(this, 'bdv_mobile_text','MUITextField');
        this.mobileTextField.setOnChangeText(this, function(textfield, value){
            this.setPhoneNumber("mobilePhone", (value.length > 0 ) ? value.trim() : null);
        });
        
        this.phoneTextField = MUIOutlet(this, 'bdv_phone_text','MUITextField');
        this.phoneTextField.setOnChangeText(this, function(textfield, value){
            this.setPhoneNumber("phone", (value.length > 0 ) ? value.trim() : null);
        });

        this.emailTextField = MUIOutlet(this, 'bdv_email_text','MUITextField');
        this.emailTextField.setOnChangeText(this, function(textfield, value){
            this.booking.client.email = (value.length > 0 ) ? value.trim() : null;
        });

        this.postalCodeTextField = MUIOutlet(this, 'bdv_postal_code_text','MUITextField');
        this.postalCodeTextField.setOnChangeText(this, function(textfield, value){
            this.booking.client.address.postalcode = (value.length > 0 ) ? value.trim() : null;
        });        

        this.cityTextField = MUIOutlet(this, 'bdv_city_text','MUITextField');
        this.cityTextField.setOnChangeText(this, function(textfield, value){
            this.booking.client.address.city = (value.length > 0 ) ? value.trim() : null;
        });        

        this.countryDropDown = MUIOutlet(this, 'bdv_country_combobox','MUIButton');
        this.countryDropDown.setAction(this, function(comboBox, value){            
            AppHelper.sharedInstance().showSelectCountryViewControllerFromView(this.countryDropDown, null, this, function(controller:SelectEntityViewController, country:Country){                
                // this.booking.client.address.countryName = country.name;
                this.booking.client.address.country = country.name;
                this.booking.client.address.countryISOA2 = country.iso2;
                this.booking.client.address.countryISOA3 = country.iso3;
                this.booking.client.address.countryISON = country.ison;        
                this.countryDropDown.title = country.name;
            });
        });        
    }

    private booking:Booking = null;
    set item(booking:Booking){
        if (this.booking == booking) return;

        if (this.booking != null) this.booking.removeObserver(this, "client");
        this.booking = booking;
        if (this.booking != null) this.booking.addObserver(this, "client");
                
        this.nameTextField.text = null;
        this.mobileTextField.text = null;
        this.phoneTextField.text = null;
        this.emailTextField.text = null;
        this.postalCodeTextField.text = null;
        this.cityTextField.text = null;

        this.updateClientInfo();
    }

    public observeValueForKeyPath(keypath:string, type, object){
        if (type != "did") return;
        switch (keypath) {
            case "client":
                this.updateClientInfo();
                break;
        }
    }

    private updateClientInfo(){

        if (this.booking.client == null) {
            this.showClientNullAlert();
            return;
        }

        this.nameTextField.text = this.booking.client.name;
        this.emailTextField.text = this.booking.client.email;
        if (this.booking.client.mobilePhone != null) this.mobileTextField.text = this.booking.client.mobilePhone.number;
        if (this.booking.client.phone != null) this.phoneTextField.text = this.booking.client.phone.number;

        if (this.booking.client.address == null) {            
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;   
            let address = MIOEntityDescription.insertNewObjectForEntityForName("Address", ad.managedObjectContext) as Address;
            this.booking.client.address = address;
            //this.booking.client.address = address;
            ad.managedObjectContext.save();
        }
        else {
            this.postalCodeTextField.text = this.booking.client.address.postalCode;
            this.cityTextField.text = this.booking.client.address.city;
            this.countryDropDown.title = getCountryName( this.booking.client.address.countryISOa2 ) ; // .countryName;
        }
    }
    
    private setPhoneNumber(key:string, value:string){
        let obj = this.booking.client.valueForKey(key) as PhoneNumber;
        if (obj == null) {
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            obj = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", ad.managedObjectContext) as PhoneNumber;         
            this.booking.client.setValueForKey(obj, key);
        }
        obj.number = value;
    }    

    private showClientNullAlert(){
        
        let avc = new MUIAlertViewController();
        avc.initWithTitle('Warning: The booking has no client linked.', 'Please select the client or create one for this booking.', MUIAlertViewStyle.Default);
        avc.addAction(MUIAlertAction.alertActionWithTitle('OK', MUIAlertActionStyle.Default, null, null));
        
        this.delegate.presentViewController(avc, true);
    }
}
