/**
 * Created by crisan on 7/12/16.
 */




class ClientVIPPointsCell extends MUITableViewCell
{
    private dateLabel:MUILabel = null;
    private conceptLabel:MUILabel = null;
    private quantityLabel:MUILabel = null;
    private separator:MUIView = null;

    private df = MUIWebApplication.sharedInstance().delegate.dateFormatter;
    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;

    awakeFromHTML()
    {
        this.dateLabel = MUIOutlet(this, "cvpv_tv_balance_date", "MUILabel");
        this.quantityLabel = MUIOutlet(this, "cvpv_tv_balance_lbl", "MUILabel");
        this.conceptLabel = MUIOutlet(this, 'cvpv_tv_concept_lbl','MUILabel');
        this.separator = MUIOutlet(this, "cvpv_tv_separator1", "MUIView");
        this.separatorStyle = MUITableViewCellSeparatorStyle.None;
    }

    setSelected(value)
    {
        super.setSelected(value);

        if (value == true)
            this.separator.setBackgroundRGBColor(0, 191, 191);
        else
            this.separator.setBackgroundRGBColor(237, 237, 242);
    }

    set item(i:LoyaltyAccountLine)
    {
        this.dateLabel.text = this.df.stringFromDate(i.date);
        this.quantityLabel.text = this.nf.stringFromNumber(i.value);
        this.conceptLabel.text = i.concept;
    }

}