
class VIPCardAddCell extends MUITableViewCell
{
    delegate = null;
    newEmailCell = false;

    private emailTextField:MUITextField = null;
    awakeFromHTML(){
        this.emailTextField = MUIOutlet(this, "email-tf", "MUITextField");
        this.emailTextField.setOnEnterPress(this, function(){
            if (this.newEmailCell == true)
                this.delegate.addedEmail(this, this.emailTextField.text);
        });

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }   

    set email(value){
        this.emailTextField.text = value;
    }
}