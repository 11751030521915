//
// Generated class PresetItem
//

/// <reference path="PresetItem_ManagedObject.ts" />

class PresetItem extends PresetItem_ManagedObject
{

}
