
class NoteDetailViewController extends EntityListViewController implements NoteLineCellDelegate
{	
	showBackButton = false;

	protected backButton:MUIButton = null;

    protected saveButton:MUIButton = null;
    protected printButton:MUIButton = null;
    protected actionButton:MUIButton = null;

    protected baseLabel:MUILabel = null;
    protected taxLabel:MUILabel = null;
    protected totalLabel:MUILabel = null;

    protected commentsTextField:MUITextField = null;

    protected topAddLineView:NoteLineCell = null;
    protected bottomAddLineView:NoteLineCell = null;
    
    protected addLineView:NoteLineCell = null;

    noteStatus = StockNoteStatus.None;  
    protected allowAddingLines = false;

    //protected removeButton:MUIButton = null;
    protected base = 0;
    protected taxes = 0;    
    protected total = 0;    
    protected discount = 0;
    
    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, 'back-btn','MUIButton');
        if (this.backButton != null) this.backButton.setAction(this, function(){
            this.navigationController.popViewController(true);
        });

        this.saveButton = MUIOutlet(this, 'save-btn', 'MUIButton');
        this.saveButton.setAction(this, this.saveAction);

        this.printButton = MUIOutlet(this, "print-btn", "MUIButton");
        if (this.printButton != null) this.printButton.setAction(this, function(this:NoteDetailViewController){
            this.printStockNote();
        });

        this.actionButton = MUIOutlet(this, "action-btn", "MUIButton");
        this.actionButton.setAction(this, function(this:NoteDetailViewController){
            this.showActions();
        });

        this.baseLabel = MUIOutlet(this, "base-lbl", "MUILabel");
        this.taxLabel = MUIOutlet(this, "tax-lbl", "MUILabel");
        this.totalLabel = MUIOutlet(this, "total-lbl", "MUILabel");

        this.tableView = MUIOutlet(this, 'table-view', 'UITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;          
        
        this.commentsTextField = MUIOutlet(this, "comments-tf", "MUITextField");
        if (this.commentsTextField) this.commentsTextField.setOnChangeText(this, function(control, value){
            this.stockNote.comments = value;
        });

        this.topAddLineView = MUIOutlet(this, "top-add-line");
        if (this.topAddLineView) {
            this.topAddLineView.delegate = this;
            this.topAddLineView.hidden = true;
        }

        this.bottomAddLineView = MUIOutlet(this, "bottom-add-line");
        if (this.bottomAddLineView) {
            this.bottomAddLineView.delegate = this;
            this.bottomAddLineView.hidden = true;
        }

        this.addLineView = SettingsHelper.sharedInstance().configStockUIAddLineTop ? this.topAddLineView : this.bottomAddLineView;


        MIONotificationCenter.defaultCenter().addObserver(this, "StockNoteDidProcess", function(notification:MIONotification){
            let note = notification.object as StockNote;
            if (note.identifier == this.stockNote.identifier) this.note = this.stockNote;
        });

        //this.removeButton = MUIOutlet(this, "remove-btn", "MUIButton");
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        if (this.backButton){
            this.backButton.setHidden(!this.showBackButton);
        }
        this._updateUI();
    }

    protected stockNote:StockNote = null;
    set note(note:StockNote) {
        this.stockNote = note;
        this.noteStatus = note.status;
        this.total = note.totalAmount;
        this.taxes = note.taxesAmount;
        this.base = note.baseAmount;
        this.discount = note.discountAmount;
        // this.allowAddingLines = this.noteStatus == StockNoteStatus.None;
        this._updateUI();
	}
	
	set filterValues(values:any){
        let identifier = values["id"];
        if (identifier == null) return;
        DBHelper.queryObjectsWithCompletion("StockNote", null, MIOPredicate.predicateWithFormat("identifier == '" + identifier + "'"), [], this, function(objects){
            if (objects.length > 0) {
                let note = objects[0];
                this.note = note;
            }
        });
    }

    protected canReloadData(){
        if (this.viewIsLoaded == false || this.stockNote == null) return false;
        return true;
    }

    private _updateUI(){
        if (this.canReloadData() == false) return;
        
        if (this.stockNote.status == StockNoteStatus.Processed) {
            this.saveButton.setHidden(true);            
            if (this.printButton)this.printButton.setHidden(false);
        }
        else {
            this.saveButton.setHidden(false);            
            if (this.printButton)this.printButton.setHidden(true);
        }        

        let actions = this.customActionsForStatus(this.stockNote.status);
        this.actionButton.hidden = actions.length > 0? false : true;

        if (this.commentsTextField) this.commentsTextField.text = this.stockNote.comments;
        
        if (this.addLineView) {
            this.addLineView.note = this.stockNote;
            this.addLineView.hidden = this.stockNote.status == StockNoteStatus.Processed;
        }

        //temporary fix until setAccessoryType(MUITableViewCellAccessoryType.None) works again
        // if (this.noteStatus == StockNoteStatus.Pending){
        //     MUICoreLayerAddStyle(this.removeButton.layer, "no-visible");
        // }

        this.updateUI();

        this.updateTotals();
    }

    protected updateUI(){}

    numberOfSections(tableview){
        let sections = this.fetchedResultsController.sections.length;
        if (this.allowAddingLines == true) sections++;
        return sections;
    }
    
    numberOfRowsInSection(tableview, section){
        if (section == this.fetchedResultsController.sections.length) return 1;

        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell:NoteLineCell = null;
                
        if (indexPath.section == this.fetchedResultsController.sections.length) {
            cell = tableview.dequeueReusableCellWithIdentifier('AddLineCell') as NoteLineCell;
            cell.note = this.stockNote;
            cell.delegate = this;
            return cell;
        }

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as StockNoteLine;
        if (this.noteStatus == StockNoteStatus.Processed){
            cell = tableview.dequeueReusableCellWithIdentifier('LineCell') as NoteLineCell;
            cell.delegate = this;
        }
        else {
            cell = tableview.dequeueReusableCellWithIdentifier('EditLineCell') as NoteLineCell;
            cell.delegate = this;
        }                                            
        
        cell.note = this.stockNote;
        cell.line = item;
                
        return cell;
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {   
        if (this.noteStatus == StockNoteStatus.Processed) return MUITableViewCellEditingStyle.None;
        // TODO: Check if the manager has access to remove lines
        let sectionID = StockNote.sectionIDFromStockNote( this.stockNote );
        if (sectionID == null) return;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let section = DBHelper.queryObject(ad.managedObjectContext, "ManagerSection", MIOPredicate.predicateWithFormat("identifier = " + sectionID)) as ManagerSection;

        if ( ad.selectedUser.checkAccessControlFlag(section.bitPosition, UserAccessControlBit.process) == true && this.noteStatus == StockNoteStatus.Pending ) {
            return MUITableViewCellEditingStyle.Delete;
        }
        
        if (indexPath.section == this.fetchedResultsController.sections.length) {
            return MUITableViewCellEditingStyle.Insert;
        }
        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {        

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            let noteline = this.fetchedResultsController.objectAtIndexPath(indexPath);    
            this.removeNoteLine(noteline);
        }
    }  
    
    // Note Line delegate 

    protected addedNewLine = false;
    lineDidInserted(cell:NoteLineCell, line:StockNoteLine){
        this.addedNewLine = true;        
        DBHelper.saveMainContext();
        this.computeTotals();
        
        if (this.addLineView) this.addLineView.becomeFirstResponder();
    }

    lineDidUpdate( line:StockNoteLine ) {
        // DBHelper.saveMainContext();
        this.computeTotals(line);
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        this.reloadData();
        if (this.addedNewLine == true) {
            //this.tableView.scrollToBottom(false);
            this.addedNewLine = false;
            // set cell focus 
            let ip = MIOIndexPath.indexForRowInSection(0, this.fetchedResultsController.sections.length);
            let cell = this.tableView.cellAtIndexPath(ip) as NoteLineCell;
            if (cell != null) cell.becomeFirstResponder();
        }
    }


    protected removeNoteLine(line:StockNoteLine){
        DBHelper.deleteObjectFromMainContext(line, false);        
        DBHelper.saveMainContext();
        this.computeTotals();
    }

    protected showActions(){
        let actions = this.customActionsForStatus(this.stockNote.status);
        if (actions.length == 0) return;

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('ACTIONS', 'ACTIONS'), MIOLocalizeString('CHOOSE AN ACTION','CHOOSE AN ACTION'), MUIAlertViewStyle.Default);
           
        for (let index = 0; index < actions.length; index++){
            let item = actions[index];
            let type = item["Type"];
            let action = item["Action"];

            if (type == "Action") {
                avc.addAction(action);
            }
        }
                
        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL', 'CANCEL'), MUIAlertActionStyle.Cancel, null, null));
       
        this.presentViewController(avc, true);
    }

    protected customActionsForStatus(status:StockNoteStatus) : any[]{
        
        let actions = [];

        if (status == StockNoteStatus.None || status == StockNoteStatus.Pending || status == StockNoteStatus.Unprocessed){
            let deleteItem = {"Type": "Action", "Action": this.deleteAction};
            actions.addObject(deleteItem);
        }
        // else if (status == StockNoteStatus.Processed){
        //     let syncItem = {"Type": "Action", "Action" : this.syncAction};
        //     actions.addObject(syncItem);
        // }

        return actions;
    }

    protected get deleteAction(){
        return MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DELETE', 'DELETE'), MUIAlertActionStyle.Destructive, this, function(){
            DBHelper.removeNote(this.stockNote);
        });   
    }

    // protected get syncAction(){
        // return MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD TO SYNC QUEUE', 'ADD TO SYNC QUEUE'), MUIAlertActionStyle.Default, this, function(){
        //     let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        //     ad.webService.syncAddToQueue(this.stockNote.identifier, "StockNote", this, function(code, data){
        //         if (code == 200) {
        //             AppHelper.showInfoMessage(null, MIOLocalizeString("ADDED TO SYNC QUEUE","ADDED TO SYNC QUEUE"), MIOLocalizeString("THE ITEM WAS ADDED SUCCEFULLY","THE ITEM WAS ADDED SUCCEFULLY"));
        //             MIONotificationCenter.defaultCenter().postNotification("DBSyncEntityDidChange", null);
        //         }
        //         else AppHelper.showErrorMessage(null, MIOLocalizeString("ERROR","ERROR"), MIOLocalizeString("SOMETHING WENT WRONG. TRY AGAIN LATER","SOMETHING WENT WRONG. TRY AGAIN LATER"));
        //     });
        // });
    // }

    private observeChanges(oldNote:StockNote, newNote:StockNote){

        if (oldNote != null) oldNote.removeObserver(this, "isFault");
        if (newNote != null) newNote.addObserver(this, "isFault");
    }

    observeValueForKeyPath(keyPath:string, type:string, object, ctx) {
        if (type != "did") return;

        if (this.stockNote.isFault == false) return;

        this._updateUI();
    }

    protected saveAction() {
        this.stockNote.totalAmount = this.total;
        this.stockNote.taxesAmount = this.taxes;
        this.stockNote.baseAmount = this.base;

        DBHelper.saveMainContext();
    }

    protected printStockNote(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;        
        ad.webService.downloadPDF(this.stockNote);
    }

    protected cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;
    protected computeTotals(line?:StockNoteLine) : [number, number, number, number] {
        this.base = 0;
        this.taxes = 0;
        this.discount = 0;
        this.total = 0;

        let lines = this.stockNote.lines.allObjects;
        if (lines.length == 0) return [0, 0, 0, 0];

        let hasToBeUpdate = false;
        for (let index = 0; index < lines.length; index++){
            let l = this.stockNote.lines.objectAtIndex(index);
            if (l.entity.name == "StockNoteLine" && l.isFault == true) continue;
            // HACK del Gordo!!
            // typesript copia por valor, por lo que el objeto es difrente
            if (line != null && l.identifier == line.identifier) {
                l.baseAmount = line.baseAmount;
                l.taxAmount = line.taxAmount;
                l.discountAmount = line.discountAmount;
                l.totalAmount = line.totalAmount;                
            }
            
            this.base += l.baseAmount;
            this.taxes += l.taxAmount;
            this.discount += l.discountAmount;
            this.total += l.totalAmount;         
            
            hasToBeUpdate = true;
        }

        if (hasToBeUpdate == false) return [0, 0, 0, 0];
        this.stockNote.baseAmount = this.base;
        this.stockNote.taxesAmount = this.taxes;        
        this.stockNote.totalAmount = this.total;
        this.stockNote.discountAmount = this.discount;

        this.updateTotals();

        return [this.base, this.taxes, this.total, this.discount];
    }

    protected updateTotals(){
    
        if (this.baseLabel) this.baseLabel.text = this.cf.stringFromNumber(this.base);
        if (this.taxLabel) this.taxLabel.text = this.cf.stringFromNumber(this.taxes);
        if (this.totalLabel) this.totalLabel.text = this.cf.stringFromNumber(this.total);
    }

}