

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ClientAction_ManagedObject

class ClientAction_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: accountType
    set accountType(value:number) {
        this.setValueForKey(value, 'accountType');
    }
    get accountType():number {
        return this.valueForKey('accountType');
    }
    set accountTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'accountType');
    }
    get accountTypePrimitiveValue():number {
        return this.primitiveValueForKey('accountType');
    }

    // Property: executionType
    set executionType(value:number) {
        this.setValueForKey(value, 'executionType');
    }
    get executionType():number {
        return this.valueForKey('executionType');
    }
    set executionTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'executionType');
    }
    get executionTypePrimitiveValue():number {
        return this.primitiveValueForKey('executionType');
    }

    // Property: modifierString
    set modifierString(value:string) {
        this.setValueForKey(value, 'modifierString');
    }
    get modifierString():string {
        return this.valueForKey('modifierString');
    }
    set modifierStringPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'modifierString');
    }
    get modifierStringPrimitiveValue():string {
        return this.primitiveValueForKey('modifierString');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }
}
