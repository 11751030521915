
class CashDeskSessionTicketTransactionsDataSource extends MIOObject
{
    private tableView:UITableView|UITableView = null;
    initWithTableView(tableView:UITableView){
        super.init();
        this.tableView = tableView;   
    }

    session:CashDeskSession = null;

    private archivedDocument:ArchivedDocument = null;
    set item(item:ArchivedDocument){
        this.archivedDocument = item;
        this.fetchedResultsController = null;
        this.tableView.reloadData();        
    }

    numberOfSections(tableview:MUITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview:MUITableView, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("TransactionCell") as CashDeskSessionTicketTransactionCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as CashDeskLine;

        cell.item = item;

        return cell;
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('CashDeskLine');
        fetchRequest.relationshipKeyPathsForPrefetching = ["checkPoint.session","payMethod.image"];
    
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('date', false)];                                                
        
        // if (this.checkPointsIDs != null) {                 
        //     this.checkPointsIDs += " AND type != 16"; //CashDeskOperationTax
        //     this.checkPointsIDs += " AND type != 17"; //CashDeskOperationTaxBase
        //     this.checkPointsIDs += " AND type != 18"; //CashDeskOperationDiscount
        //     this.checkPointsIDs += " AND type != 19"; //CashDeskOperationPeople
    
        //     let predicateFormat = this.checkPointsIDs + " AND documentID == '" + this.archivedDocument.documentID + "'";
        //     fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);    
        // }
        
        let predicates = [];
        predicates.addObject("documentID == '" + this.archivedDocument.documentID + "'");
        predicates.addObject("checkPoint.session.identifier == " + this.session.identifier);
        predicates.addObject("type != 16"); //CashDeskOperationTax
        predicates.addObject("type != 17"); //CashDeskOperationTaxBase
        predicates.addObject("type != 18"); //CashDeskOperationDiscount
        predicates.addObject("type != 19"); //CashDeskOperationPeople
        
        let predicateFormat = predicates.join(" AND ");
        fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }    

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }
}