
/// <reference path="MainViewController.ts" />

class DesktopViewController extends MainViewController 
{
    private topbarView:MUIView = null;
    private workspaceIconView:MUIView = null;
    private workspaceButton:MUIButton = null;
    private errorIcon:MUIView = null;
    private cautionIcon:MUIView = null;
    private activityIndicator:MUIActivityIndicatorView = null;
    private userProfileButton:MUIButton = null;
    private userNameLabel:MUILabel = null;

    viewDidLoad(){
        super.viewDidLoad();   
        
        this.topbarView = MUIOutlet(this, "topbar-view", "MUIView");
        this.workspaceIconView = MUIOutlet(this, "workspace-icon", "MUIView");

        this.workspaceButton = MUIOutlet(this, "workspace-btn", "MUIButton");        
        this.workspaceButton.setAction(this, function(){
            AppHelper.sharedInstance().presentWorkspaceViewController(this);
        });

        this.activityIndicator = MUIOutlet(this, "sync-spinning", "MUIActivityIndicatorView");

        this.errorIcon = MUIOutlet(this, "error-icon", "MUIView");
        this.errorIcon.setHidden(true);

        this.cautionIcon = MUIOutlet(this, "caution-icon", "MUIView");
        this.cautionIcon.setHidden(true);

        this.activityIndicator = MUIOutlet(this, "sync-spinning", "MUIView");
        this.activityIndicator.setHidden(true);

        this.userProfileButton = this.setupEditProfileButton("user-profile-btn"); 

        let data = MIOBundle.mainBundle().pathForResourceOfType("app", "plist");
        let config = MIOPropertyListSerialization.propertyListWithData(data, 0, 0, null);
        this.userNameLabel = MUIOutlet(this, "version-lbl", "MUILabel");
        this.userNameLabel.text = (MUIWebApplication.sharedInstance().delegate as AppDelegate).selectedUser?.name;

        MIONotificationCenter.defaultCenter().addObserver(this, "WebServiceCommandBegin", this.webServiceCommandBeginNotification);
        MIONotificationCenter.defaultCenter().addObserver(this, "WebServiceCommandEnd", this.webServiceCommandEndNotification);

        MIONotificationCenter.defaultCenter().addObserver(this, "MWSRequestSentFetch", this.webServiceCommandBeginNotification);
        MIONotificationCenter.defaultCenter().addObserver(this, "MWSRequestReceivedFetch", this.webServiceCommandEndNotification);        

        MIONotificationCenter.defaultCenter().addObserver(this, "WebServiceCommandError", this.webServiceCommandErrorNotification);                
        MIONotificationCenter.defaultCenter().addObserver(this, "MWSRequestFetchError", this.webServiceCommandErrorNotification);

        MIONotificationCenter.defaultCenter().addObserver(this, "MWSPersistentStoreError", function(note:MIONotification){
            this.errorIcon.setHidden(false);
            MUICoreLayerAddStyle(this.topbarView.layer, "red");
        });   
        
        MIONotificationCenter.defaultCenter().addObserver(this, "MWSPersistentStoreSaveOperationError", function(note:MIONotification){
            this.errorIcon.setHidden(false);
            MUICoreLayerAddStyle(this.topbarView.layer, "red");

            // Show
            this.showPersistentErrorAlert(note.object);
        });                           
    }

    private showPersistentErrorAlert(transaction:string) {
        let avc = new MUIAlertViewController();
        avc.initWithTitle( MIOLocalizeString("CONNECTION ERROR", "CONNECTION ERROR"), MIOLocalizeString("CHOOSE AN OPTION TO PROCCED", "CHOOSE AN OPTION TO PROCCED"), MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle( MIOLocalizeString("TRY AGAIN", "TRY AGAIN"), MUIAlertActionStyle.Default, this, function() {
            this.errorIcon.setHidden(true);
            MUICoreLayerRemoveStyle(this.topbarView.layer, "red");    

            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            ad.webPersistentStore.checkTransactions(ad.selectedIdentifier, null);
        }));

        // avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("IGNORE CHANGES", "IGNORE CHANGES"), MUIAlertActionStyle.Destructive, this, function() {
        //     this.errorIcon.setHidden(true);
        //     MUICoreLayerRemoveStyle(this.topbarView.layer, "red");    

        //     let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        //     ad.webPersistentStore.deleteTransaction(ad.selectedIdentifier, transaction, () => {
        //         // TODO: Show message with the result
        //     });
        // }));

        avc.addAction(MUIAlertAction.alertActionWithTitle( MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null ));

        this.presentViewController(avc, true);
    }

    private executingCommands = 0;
    private webServiceCommandBeginNotification(notification:MIONotification){
        if (this.executingCommands == 0) this.activityIndicator.startAnimating();
        this.executingCommands++;
    }

    private webServiceCommandEndNotification(notification:MIONotification){
        this.executingCommands--;
        if (this.executingCommands == 0) this.activityIndicator.stopAnimating();
        
        if (this.executingCommands < 0) {
            this.executingCommands = 0;
            MIOLog("Warning: Finishing more web commands that were started");
        }
    }

    private webServiceCommandErrorNotification(notification:MIONotification){        
        let userInfo = notification.userInfo;
        if (userInfo != null && userInfo["Type"] == MWSRequestType.Save) {
            this.errorIcon.setHidden(false);
            MUICoreLayerAddStyle(this.topbarView.layer, "red");
        }
        else {
            //this.cautionIcon.setHidden(false);
        }        
    }

    protected workspaceDidChange(business:Business){
        this.workspaceButton.setTitle('');
        this.workspaceIconView.layer.classList.remove(['place_icon_on', 'company_icon_on']);

        if (business != null) {
            if (business.type == BusinessType.Company) {
                this.workspaceIconView.layer.classList.remove('place_icon_on');
                this.workspaceIconView.layer.classList.add('company_icon_on');
            }
            else if (business.type == BusinessType.Place) {
                this.workspaceIconView.layer.classList.remove('company_icon_on');
                this.workspaceIconView.layer.classList.add('place_icon_on');
            }

            this.workspaceButton.setTitle(business.name);
            this.userNameLabel.text = (MUIWebApplication.sharedInstance().delegate as AppDelegate).selectedUser?.name;
        }        
    }

    private setupEditProfileButton(outlet:string): MUIButton {
        let button = MUIOutlet(this, outlet, "MUIButton");
        if (button == null || button == undefined) return null;

        button.setAction(this, function() {
            AppHelper.sharedInstance().presentEditProfileViewController(this);
        }); 
        
        return button;
    }

}