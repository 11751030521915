//
// Generated class IntallmentReconcileTransaction
//

/// <reference path="IntallmentReconcileTransaction_ManagedObject.ts" />

class IntallmentReconcileTransaction extends IntallmentReconcileTransaction_ManagedObject
{

}
