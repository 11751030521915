//
// Generated class BookingZoneOcupationRule
//

/// <reference path="BookingZoneOcupationRule_ManagedObject.ts" />

class BookingZoneOcupationRule extends BookingZoneOcupationRule_ManagedObject
{

}
