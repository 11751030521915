//
// Generated class BookingLog
//

/// <reference path="BookingLog_ManagedObject.ts" />

class BookingLog extends BookingLog_ManagedObject
{

}
