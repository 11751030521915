

class StockConsumptionsDetailViewController extends MUIViewController
{
    viewDidLoad()
    {
        super.viewDidLoad();    
    }

    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
        
    }
}