class BookingDetailCommentsView extends MUIView
{
    private commentsTextArea:MUITextArea = null;

    awakeFromHTML(){        
        this.commentsTextArea = MUIOutlet(this, 'comments-ta','MUITextArea');
        this.commentsTextArea.setOnChangeText(this, function(textarea, value){
            let v = value.trim().length > 0 ? value.trim() : null;
            this._item.setValueForKey(v, this.key);
        });
    }
    
    private key:string = null;
    private _item:Booking = null;
    setBooking(i:Booking, key:string){
        this._item = i;
        this.key = key;
        let value = i.valueForKey(key);
        this.commentsTextArea.text = value ? value : '';
    }
}
