//
// Generated class InternalOutputNote
//

/// <reference path="InternalOutputNote_ManagedObject.ts" />

class InternalOutputNote extends InternalOutputNote_ManagedObject
{

}
