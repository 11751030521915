

/// <reference path="PaymentTransaction.ts" />

// Generated class OrderPaymentTransaction_ManagedObject

class OrderPaymentTransaction_ManagedObject extends PaymentTransaction
{
    // Relationship: order
    set order(value:Order) {
        this.setValueForKey(value, 'order');
    }
    get order():Order {
        return this.valueForKey('order') as Order;
    }
}
