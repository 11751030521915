
class BankCell extends MUITableViewCell
{
    private titleLabel:MUILabel = null;
    private totalLabel:MUILabel = null;

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
        this.totalLabel = MUIOutlet(this, "total-lbl", "MUILabel");
    }

    set item (item:Bank){
        this.titleLabel.text = item.name;
        this.totalLabel.text = null;
    }
}