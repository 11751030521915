

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class PresetItem_ManagedObject

class PresetItem_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }
    // Relationship: preset
    set preset(value:Preset) {
        this.setValueForKey(value, 'preset');
    }
    get preset():Preset {
        return this.valueForKey('preset') as Preset;
    }
    // Relationship: presetCategory
    set presetCategory(value:PresetCategory) {
        this.setValueForKey(value, 'presetCategory');
    }
    get presetCategory():PresetCategory {
        return this.valueForKey('presetCategory') as PresetCategory;
    }
}
