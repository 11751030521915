class SalesInvoiceLineCell extends InvoiceLineCell
{
    get entityName() : string { return "SalesInvoiceLine"; }
    
    protected productDidSelect( controller:SelectEntityViewController, product:Product, supplierProduct?:SupplierProduct )
    {
        super.productDidSelect(controller, product, supplierProduct);
        
        this.vat = product.tax ?? product.category.tax;
        this.taxDropDown.title = this.vat?.name;
    }    
}