
class StockCustomConceptViewController extends SingleEditViewController
{    
    static newInstance() {
        let vc = new StockCustomConceptViewController('single-edit-view');
        vc.initWithResource('layout/base/SingleEditView.html');
        return vc;
    }

    get title(){
        return MIOLocalizeString("STOCK CUSTOM CONCEPTS","STOCK CUSTOM CONCEPTS");
    }

    fetchEntity(){
        return "StockCustomConcept";
    }

    sortDescriptors(){
        return [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
    }

    configureCellAtIndexPath(cell:SingleEditCell, indexPath:MIOIndexPath, item:MIOManagedObject){
        let d = item as StockCustomConcept;
        cell.setItem(d, "name");
    }
    
    addButtonDidPress(){

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("NEW CUSTOM CONCEPT","NEW CUSTOM CONCEPT"), MIOLocalizeString("CHOOSE A NAME", "CHOOSE A NAME"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
           textField.setPlaceholderText(MIOLocalizeString("NAME", "NAME"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("ADD", "ADD"), MUIAlertActionStyle.Default, this, function(){
            let name = avc.textFields[0].text;
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            let d = MIOEntityDescription.insertNewObjectForEntityForName(this.fetchEntity(), ad.managedObjectContext) as StockCustomConcept;
            d.name = name;
            d.type = 0xfff;
            
            ad.managedObjectContext.save();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, null, null));        

        this.presentViewController(avc, true);
    }

    removeItem(item:MIOManagedObject){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.managedObjectContext.deleteObject(item);
        ad.managedObjectContext.save();        
    }

    
}