//
// Generated class InvitationRulePreset
//

/// <reference path="InvitationRulePreset_ManagedObject.ts" />

class InvitationRulePreset extends InvitationRulePreset_ManagedObject
{

}
