//
// Generated class TimeRangePreset
//

/// <reference path="TimeRangePreset_ManagedObject.ts" />

enum TimeRangePresetType {
    Normal = 0,
    Holiday = 1
};

class TimeRangePreset extends TimeRangePreset_ManagedObject
{

}
