/**
 * Created by crisan on 7/12/16.
 */



/// <reference path="../ModifierPrice/ModifierPriceViewController.ts" />


class ModifierCell extends MUITableViewCell
{
    nameLabel:MUILabel = null;
    visibleCheckButton:MUISwitchButton = null;
    modifierButton:MUIButton = null;
    rateButton:MUIButton = null;
    
    mpvc:ModifierPriceViewController = null;

    awakeFromHTML()
    {
        this.nameLabel = MUIOutlet(this, "mv_tv_product_text", "MUITextField");
        this.visibleCheckButton = MUIOutlet(this, 'mv_tv_switch_btn', 'MUISwitchButton');

        this.modifierButton = MUIOutlet(this, 'mv_tv_modifier_btn', 'MUIButton');
        this.modifierButton.setAction(this, function () {
            console.log('Modifier Button');
            if(this.mpvc == null) {

                this.mpvc = new ModifierPriceViewController('modifier_price_view');
                this.mpvc.initWithResource('layout/modals/modifierprice/ModifierPriceView.html');
            }

            this.presentViewController(this.mpvc, true);
        });

        this.rateButton = MUIOutlet(this, 'mv_tv_taxation_btn', 'MUIButton');
        this.rateButton.setAction(this, function () {
           console.log('Tax Button');
        });

        this.separatorStyle = MUITableViewCellSeparatorStyle.None;
    }

    set item(i)
    {
        this.nameLabel.text = i.name;
    }

}