

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ReconliceTransaction_ManagedObject

class ReconliceTransaction_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: cleared
    set cleared(value:boolean) {
        this.setValueForKey(value, 'cleared');
    }
    get cleared():boolean {
        return this.valueForKey('cleared');
    }
    set clearedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'cleared');
    }
    get clearedPrimitiveValue():boolean {
        return this.primitiveValueForKey('cleared');
    }
    // Relationship: movement
    set movement(value:BankMovement) {
        this.setValueForKey(value, 'movement');
    }
    get movement():BankMovement {
        return this.valueForKey('movement') as BankMovement;
    }
}
