//
// Generated class DBMappingEntityProduct
//

/// <reference path="DBMappingEntityProduct_ManagedObject.ts" />

class DBMappingEntityProduct extends DBMappingEntityProduct_ManagedObject
{

}
