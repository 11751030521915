/**
 * Created by crisan on 7/12/16.
 */



class CompanyLicenseCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;

    private _separator = null;
    
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;

    awakeFromHTML()
    {
        this.nameLabel = MUIOutlet(this, "llv_tv_companylicense_name_lbl", "MUILabel");

        this._separator = MUIOutlet(this, "llv_tv_companylicense_separator", "MUIView");

    }

    set item(i)
    {
        this.nameLabel.text = i.name;
        
    }

}