
class NoteListViewController extends MasterListViewController
{
    protected segmentedControl:MUISegmentedControl = null;
    protected addButton:MUIButton = null;        
    protected filterButton:MUIButton = null;

    protected selectedStatus = 0; 

    viewDidLoad(){
        super.viewDidLoad();

        this.segmentedControl = MUIOutlet(this, 'segmented-control', 'MUISegmentedControl');
        if (this.segmentedControl != null) this.segmentedControl.setAction(this, this.segmentedIndexDidChange);        

        this.addButton = MUIOutlet(this, 'add-btn','MUIButton');
        if (this.addButton != null) this.addButton.setAction(this, function(){          
            this.showAddNoteViewController();
        });

        this.searchTextField = MUIOutlet(this, 'search-bar','MUITextField');
        this.setupSearchTextField();

        this.filterButton = MUIOutlet(this, "filter-btn", "MUIButton");   
        if (this.filterButton != null) this.filterButton.setAction(this, function(){
            AppHelper.sharedInstance().showFilterViewController(this, this.filters, this.filtersDidChange);
        });

        this.tableView = MUIOutlet(this, 'table-view', 'UITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;         

        MIONotificationCenter.defaultCenter().addObserver(this, "StockNoteDidDelete", function(this:NoteListViewController, note:MIONotification){
            let item = note.object as StockNote;
            this.noteDidDelete(item);
        });
    }

    viewWillAppear(animated?: boolean): void {
        super.viewWillAppear(animated);
        this.checkAccessControl();
    }

    protected get filters(){
        return [];
    }

    protected filterPredicateFormat:string = null;
    protected filtersDidChange(filtersPredicateFormat){        

        if(filtersPredicateFormat != null) {
            MUICoreLayerAddStyle(this.filterButton.layer, 'filter');
            MUICoreLayerRemoveStyle(this.filterButton.layer, 'filter-off');
        } else {
            MUICoreLayerRemoveStyle(this.filterButton.layer, 'filter');
            MUICoreLayerAddStyle(this.filterButton.layer, 'filter-off');
        }

        this.filterPredicateFormat = filtersPredicateFormat;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    get entityName(){
        return 'StockNote';
    }   

    protected noteStatusForSegmentedIndex(index){
        return index;        
    }
    
    protected segmentedIndexDidChange(control, index){   
        this.selectedStatus = this.noteStatusForSegmentedIndex(index);  
        if (this.addButton != null) this.addButton.hidden = index == 0 ? false : true;
        this.deselectNote();        
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }
    
    protected selectedNote:StockNote = null;
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        this.selectedNote = this.fetchedResultsController.objectAtIndexPath(indexPath) as StockNote;
        this.showSelectedItem();
    }

    protected deselectNote(){
        if (this.selectedNote == null) return;
        this.selectedNote = null;
        this.showNoItemSelected();
    }
    
    private noteDidDelete(note:StockNote){
        if (this.selectedNote === note) this.showNoItemSelected();
    }

    protected showAddNoteViewController(){}    
    protected showSelectedItem(){}

    protected get sectionControllerID():string {
        return null;
    }
    
    protected checkAccessControl(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;        
        let user = ad.selectedUser;

        let sectionID = this.sectionControllerID;
        if (sectionID == null) return;

        let section = DBHelper.queryObject(ad.managedObjectContext, "ManagerSection", MIOPredicate.predicateWithFormat("identifier = " + sectionID)) as ManagerSection;

        if (user.checkAccessControlFlag(section.bitPosition, UserAccessControlBit.create) == false) {
            this.addButton.hidden = true;
            let s = this.segmentedControl.segmentedItems[0] as MUIView;
            s.hidden = true;
        }
    }

}