class WorkspaceViewController extends MUIViewController
{    
    static newInstance(): WorkspaceViewController 
    {
        let vc = new WorkspaceViewController("workspace-list-view");
        vc.initWithResource("layout/workspace/WorkspaceListView.html");
        return vc;
    }

    private backButton:MUIButton = null;
    private closeButton:MUIButton = null;
    private addButton:MUIButton = null;
    private searchTextField:MUITextField = null;
    private tableView:UITableView = null;
            
    private searchString:string = null;   
    private searchTimer:MIOTimer = null;           
    
    get preferredContentSize(){
        return new MIOSize(400, 400);
    }

    viewDidLoad(){
        super.viewDidLoad();        

        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");        
        this.backButton.setAction(this, function(){
            this.navigationController.popViewController(true);
        });        

        this.closeButton = MUIOutlet(this, 'close-btn', 'MUIButton');        
        this.closeButton.setAction(this, function(){
            this.dismissViewController(true);
        });

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.hidden = MIOCoreIsPhone() ? true : false;
        this.addButton.setAction(this, function(){
            this.showOptionsAlertView();
        });                

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.searchTextField.setOnChangeText(this, function (this:WorkspaceViewController, textfield:MUITextField, value:string) {            
            this.searchString = value.length > 0 ? value : null;
            this.updateBusinesses();
        });
        
        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.updateBusinesses();

    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);

        this.backButton.hidden = this.isModal == true ? true : false;
        this.closeButton.hidden = this.isModal == true ? false : true;
 
        this.selectedItem = null;
    }

    businesses = [];

    private _isModal = true;
    set isModal(value:boolean) {
        this._isModal = value;        
    }

    get isModal():boolean{
        return this._isModal;
    }

    numberOfSections(tableview){
        // return this.fetchedResultsController.sections.length;
        return 1;
    }
    
    numberOfRowsInSection(tableview, section){
        // let sec = this.fetchedResultsController.sections[section];
        // return sec.numberOfObjects();
        return this.businesses.count;
    }
    
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = null;
    
        let item = this.businesses[indexPath.row];
        
        if ( item["parent_level"] == 0 && item["hasChildren"] == false) {
            cell = tableview.dequeueReusableCellWithIdentifier('OnlyPlaceCell') as BusinessCell;
        } else if ( item["parent_level"] == 0 && item["hasChildren"] == true) {
            cell = tableview.dequeueReusableCellWithIdentifier('CompanyCell') as BusinessCell;
        } else {
            cell = tableview.dequeueReusableCellWithIdentifier('PlaceCell') as BusinessCell;
        }
        
        cell.item = item;                
        // let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Business;        
        // if(item.type == BusinessType.Company)
        //     cell = tableview.dequeueReusableCellWithIdentifier('CompanyCell') as BusinessCell;
        // else     
        //     cell = tableview.dequeueReusableCellWithIdentifier('PlaceCell') as BusinessCell;
        
        // cell.item = item;
    
        return cell;
    }    
    
    private selectedItem = null;
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){        
        // let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Business;
        let item = this.businesses[indexPath.row];
        this.selectedItem = item;

        DualLinkAuthKit.sharedInstance().loginBusiness (item["id"], (error:string, result:boolean) => {
            if (result == true) {                            
                let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;        
                ad.managedObjectContext.reset();
                ad.setBusiness(item, this, function(){
                    this.dismissViewController(true);
                    this.tableView.deleteRowsAtIndexPaths([indexPath], true);
                });            
            }
        });
    }

    accessoryButtonTappedForRowWithIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
                
        // let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Business;
        
        // let vc = new BusinessViewController('add-place-co-view');            
        // vc.initWithResource('layout/workspace/AddPlaceCoView.html');                        
        // vc.item = item;
            
        // this.navigationController.pushViewController(vc, true);
    }  

    private updateBusinesses(){
        let bs = DualLinkAuthKit.sharedInstance().businesses;        

        // map
        let business_by_id = {};
        for (let b of bs) {
            business_by_id[ b[ "id" ] ] = b;
        }

        for (let b of bs) {
            let [path, level] = this.parent_path( b, business_by_id );
            b["parent_path"] = path;
            b["parent_level"] = level;
            b["hasChildren"] = this.has_children(b, business_by_id) ? true : false;
        }

        this.businesses = this.searchString == null ? bs : _MIOPredicateFilterObjects(bs, MIOPredicate.predicateWithFormat("name contains '" +  this.searchString + "'"));        
        //this.businesses = _MIOSortDescriptorSortObjects( this.businesses, [ MIOSortDescriptor.sortDescriptorWithKey( this.searchString != null ? "name" : "parent_path", true ) ] );
        this.businesses = _MIOSortDescriptorSortObjects( this.businesses, [ MIOSortDescriptor.sortDescriptorWithKey("parent_path", true ), MIOSortDescriptor.sortDescriptorWithKey("name", true ) ] );
        this.tableView.reloadData();
    }

    private parent_path(item:any, businesses:any) : [path:string, level:number] {
        if (item ["parent"] == null) return [ item["name"], 0 ];

        let b = businesses[ item ["parent"] ];
        if ( b == null ) return [ item["name"], 0 ];

        let [path, level] = this.parent_path(b, businesses);
        return [ path + "." + item[ "name" ], level + 1 ];        
    }

    private has_children(item:any, businesses:any) {
        
        // let l = Object.keys(businesses).length;

        for ( let b in businesses) {
            if (businesses[b] == item) continue;

            if (businesses[b].parent == item.id) return true;
            
        }

        // for (const [key, value] of Object.entries(businesses)) {
        //     if (value === item) continue;
        //     if (value.parent == item.id) return true;
        // }


        // for (let i=0; i<l; i++) {
        //     let b = businesses[i];
        //     if (b === item) continue;
        //     let p = b.parent;
        //     let i = item["id"];
        //     if (p == i) return true;
        // }

        return false;
    }
    

    // get fetchRequest(){
        
    //     let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('Business');    
    //     fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('indexPath', true)];            

    //     if (this.searchString != null)
    //         fetchRequest.predicate = MIOPredicate.predicateWithFormat('name CONTAINS "' + this.searchString + '"');

    //     return fetchRequest;
    // }

    // private _fetchedResultsController = null;
    // set fetchedResultsController(value){
    //     if (value == null && this._fetchedResultsController != null)
    //         this._fetchedResultsController.delegate = null;
    
    //     this._fetchedResultsController = value;
    // }
    
    // get fetchedResultsController(){
    //     if (this._fetchedResultsController != null) return this._fetchedResultsController;    
        
    //     let ad = MUIWebApplication.sharedInstance().delegate;
        
    //     let fetchedResultsController = new MIOFetchedResultsController();
    //     fetchedResultsController.initWithFetchRequest(this.fetchRequest, ad.managedObjectContext, null);
    //     fetchedResultsController.delegate = this;
    
    //     fetchedResultsController.performFetch();
    
    //     this._fetchedResultsController = fetchedResultsController;    
    //     return this._fetchedResultsController;
    // }
    
    // controllerDidChangeContent(controller){
    //     this.tableView.reloadData();
    // }

    // Options alert view method

    private showOptionsAlertView(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("CHOOSE THE TYPE", "CHOOSE THE TYPE"), MIOLocalizeString("SELECT THE TYPE TO CREATE A PLACE OR COMPANY", "SELECT THE TYPE TO CREATE A PLACE OR COMPANY"), MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString( "COMPANY", "COMPANY" ), MUIAlertActionStyle.Default, this, function(){
            let vc = new BusinessViewController('add-place-co-view');
            vc.initWithResource('layout/workspace/AddPlaceCoView.html');
            
            vc.mode = BusinessType.Company;
            //vc.delegate = this;
            this.navigationController.pushViewController(vc, true);    
            
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('PLACE',"Place"), MUIAlertActionStyle.Default, this, function(){
            let vc = new BusinessViewController('add-place-co-view');
            vc.initWithResource('layout/workspace/AddPlaceCoView.html');

            vc.mode = BusinessType.Place;
            //this._addvc.delegate = this;
            this.navigationController.pushViewController(vc, true);    
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle("Cancel", MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }
    
}

