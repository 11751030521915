//
// Generated class SalesModifierAnnotation
//

/// <reference path="SalesModifierAnnotation_ManagedObject.ts" />

class SalesModifierAnnotation extends SalesModifierAnnotation_ManagedObject
{

}
