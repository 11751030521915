

/// <reference path="PaymentTransaction.ts" />

// Generated class ProxyPaymentTransaction_ManagedObject

class ProxyPaymentTransaction_ManagedObject extends PaymentTransaction
{

    // Property: commissionRevenue
    set commissionRevenue(value:number) {
        this.setValueForKey(value, 'commissionRevenue');
    }
    get commissionRevenue():number {
        return this.valueForKey('commissionRevenue');
    }
    set commissionRevenuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'commissionRevenue');
    }
    get commissionRevenuePrimitiveValue():number {
        return this.primitiveValueForKey('commissionRevenue');
    }

    // Property: schema
    set schema(value:string) {
        this.setValueForKey(value, 'schema');
    }
    get schema():string {
        return this.valueForKey('schema');
    }
    set schemaPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'schema');
    }
    get schemaPrimitiveValue():string {
        return this.primitiveValueForKey('schema');
    }

    // Property: schemaAppID
    set schemaAppID(value:string) {
        this.setValueForKey(value, 'schemaAppID');
    }
    get schemaAppID():string {
        return this.valueForKey('schemaAppID');
    }
    set schemaAppIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'schemaAppID');
    }
    get schemaAppIDPrimitiveValue():string {
        return this.primitiveValueForKey('schemaAppID');
    }

    // Property: schemaAppSource
    set schemaAppSource(value:string) {
        this.setValueForKey(value, 'schemaAppSource');
    }
    get schemaAppSource():string {
        return this.valueForKey('schemaAppSource');
    }
    set schemaAppSourcePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'schemaAppSource');
    }
    get schemaAppSourcePrimitiveValue():string {
        return this.primitiveValueForKey('schemaAppSource');
    }

    // Property: schemaBusinessAreaID
    set schemaBusinessAreaID(value:string) {
        this.setValueForKey(value, 'schemaBusinessAreaID');
    }
    get schemaBusinessAreaID():string {
        return this.valueForKey('schemaBusinessAreaID');
    }
    set schemaBusinessAreaIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'schemaBusinessAreaID');
    }
    get schemaBusinessAreaIDPrimitiveValue():string {
        return this.primitiveValueForKey('schemaBusinessAreaID');
    }

    // Property: schemaDocumentNumber
    set schemaDocumentNumber(value:string) {
        this.setValueForKey(value, 'schemaDocumentNumber');
    }
    get schemaDocumentNumber():string {
        return this.valueForKey('schemaDocumentNumber');
    }
    set schemaDocumentNumberPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'schemaDocumentNumber');
    }
    get schemaDocumentNumberPrimitiveValue():string {
        return this.primitiveValueForKey('schemaDocumentNumber');
    }

    // Property: schemaPlaceID
    set schemaPlaceID(value:string) {
        this.setValueForKey(value, 'schemaPlaceID');
    }
    get schemaPlaceID():string {
        return this.valueForKey('schemaPlaceID');
    }
    set schemaPlaceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'schemaPlaceID');
    }
    get schemaPlaceIDPrimitiveValue():string {
        return this.primitiveValueForKey('schemaPlaceID');
    }

    // Property: schemaTransactionID
    set schemaTransactionID(value:string) {
        this.setValueForKey(value, 'schemaTransactionID');
    }
    get schemaTransactionID():string {
        return this.valueForKey('schemaTransactionID');
    }
    set schemaTransactionIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'schemaTransactionID');
    }
    get schemaTransactionIDPrimitiveValue():string {
        return this.primitiveValueForKey('schemaTransactionID');
    }

    // Property: webHook
    set webHook(value:string) {
        this.setValueForKey(value, 'webHook');
    }
    get webHook():string {
        return this.valueForKey('webHook');
    }
    set webHookPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'webHook');
    }
    get webHookPrimitiveValue():string {
        return this.primitiveValueForKey('webHook');
    }
}
