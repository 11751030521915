//
// Generated class ClientPaymentInstallment
//

/// <reference path="ClientPaymentInstallment_ManagedObject.ts" />

class ClientPaymentInstallment extends ClientPaymentInstallment_ManagedObject
{

}
