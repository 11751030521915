class SectionCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
    }

    set item (item:ManagerSection){
        this.nameLabel.text = MIOLocalizeString(item.name, item.name);

        if (item.isBeta)
            MUICoreLayerAddStyle(this.nameLabel.layer, "beta");
    }
}