

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class PresetRelation_ManagedObject

class PresetRelation_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: isDefault
    set isDefault(value:boolean) {
        this.setValueForKey(value, 'isDefault');
    }
    get isDefault():boolean {
        return this.valueForKey('isDefault');
    }
    set isDefaultPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isDefault');
    }
    get isDefaultPrimitiveValue():boolean {
        return this.primitiveValueForKey('isDefault');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }
    // Relationship: favouritePresetGroup
    set favouritePresetGroup(value:PresetGroup) {
        this.setValueForKey(value, 'favouritePresetGroup');
    }
    get favouritePresetGroup():PresetGroup {
        return this.valueForKey('favouritePresetGroup') as PresetGroup;
    }
    // Relationship: menuPresetGroup
    set menuPresetGroup(value:PresetGroup) {
        this.setValueForKey(value, 'menuPresetGroup');
    }
    get menuPresetGroup():PresetGroup {
        return this.valueForKey('menuPresetGroup') as PresetGroup;
    }
    // Relationship: preset
    set preset(value:Preset) {
        this.setValueForKey(value, 'preset');
    }
    get preset():Preset {
        return this.valueForKey('preset') as Preset;
    }
    // Relationship: productPresetGroup
    set productPresetGroup(value:PresetGroup) {
        this.setValueForKey(value, 'productPresetGroup');
    }
    get productPresetGroup():PresetGroup {
        return this.valueForKey('productPresetGroup') as PresetGroup;
    }
}
