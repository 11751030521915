interface WebService
{
    updateProductCost( product:Product, completion:any) : void ;
}

WebService.prototype.updateProductCost = function( product:Product, completion:any ) : void
{
    const url_str = this.apiURL + "/entity/" + this.scheme + "/update-product-cost/" + product.identifier;
    let body = { "CostType": product.costType };
    if (product.costProductPrice != null) {
        body["CostProductPrice"] = product.costProductPrice;
     }
    let r = new DLRequest ( url_str, body, "POST" );
    r.execute( completion );
}


