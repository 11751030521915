
class AdyenDetailViewController extends MUIViewController
{
    static newInstance() : AdyenDetailViewController {
        let vc = new AdyenDetailViewController("payment-entity-adyen-detail-view");
        vc.initWithResource("layout/paymententity/PaymentEntityAdyenDetailView.html");
        return vc;
    }

    private _size = new MIOSize(750, 700);
    get preferredContentSize(){ return this._size; }

    private closeButton:MUIButton = null;
    private saveButton:MUIButton = null;
    
    private legalEntityNameLabel:MUILabel = null;
    private tableView:UITableView = null;    

    viewDidLoad(){
        super.viewDidLoad();

        this.closeButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.closeButton.setAction(this, function(){
            this.dismissViewController(true);
        });

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        });

        this.legalEntityNameLabel = MUIOutlet(this, "entity-name-lbl", "MUILabel");        

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
    }

    viewWillAppear(animate){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private _company:Company = null;
    private _payment_entity:AdyenPaymentEntity = null;
    set paymentEntity(paymentEntity:AdyenPaymentEntity){
        this._payment_entity = paymentEntity;
        DBHelper.queryObjectWithCompletion("Company", null, MIOPredicate.predicateWithFormat( "deletedAt = null" ),["address", "phone"], this, function(this:AdyenDetailViewController, company:Company){
            this._company = company;
            this.updateUI();
        });
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._payment_entity == null || this._company == null) return;
        
        this.legalEntityNameLabel.text = this._company.name;        
        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView){
        return 6;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        switch (section) {
            case 4: return 2;
            default: return 1;
        }
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {
        let cell:AdyenDetailCell = null;
        switch (indexPath.section) {
            case 0:
                cell = tableView.dequeueReusableCellWithIdentifier("AdyenLegalEntityDetailCell") as AdyenLegalEntityDetailCell;
                cell.company = this._company;
                cell.paymentEntity = this._payment_entity;
                return cell;

            case 1:
                cell = tableView.dequeueReusableCellWithIdentifier("AdyenAccountHolderDetailCell") as AdyenAccountHolderDetailCell;
                cell.company = this._company;
                cell.paymentEntity = this._payment_entity;
                return cell;

            case 2:
                cell = tableView.dequeueReusableCellWithIdentifier("AdyenBuinessLineDetailCell") as AdyenBuinessLineDetailCell;
                cell.company = this._company;
                cell.paymentEntity = this._payment_entity;
                return cell;    

            case 3:
                cell = tableView.dequeueReusableCellWithIdentifier("AdyenBalanceDetailCell") as AdyenBalanceDetailCell;
                cell.company = this._company;
                cell.paymentEntity = this._payment_entity;
                return cell;    

            case 4:
                cell = tableView.dequeueReusableCellWithIdentifier("AdyenStoreDetailCell") as AdyenStoreDetailCell;
                cell.company = this._company;
                cell.paymentEntity = this._payment_entity;
                return cell;

            case 5:
                cell = tableView.dequeueReusableCellWithIdentifier("AdyenActionsDetailCell") as AdyenActionsDetailCell;
                cell.company = this._company;
                cell.paymentEntity = this._payment_entity;
                return cell;            
        }
    }
}
