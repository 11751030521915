

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class DBImage_ManagedObject

class DBImage_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: height
    set height(value:number) {
        this.setValueForKey(value, 'height');
    }
    get height():number {
        return this.valueForKey('height');
    }
    set heightPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'height');
    }
    get heightPrimitiveValue():number {
        return this.primitiveValueForKey('height');
    }

    // Property: resized
    set resized(value:number) {
        this.setValueForKey(value, 'resized');
    }
    get resized():number {
        return this.valueForKey('resized');
    }
    set resizedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'resized');
    }
    get resizedPrimitiveValue():number {
        return this.primitiveValueForKey('resized');
    }

    // Property: url
    set url(value:string) {
        this.setValueForKey(value, 'url');
    }
    get url():string {
        return this.valueForKey('url');
    }
    set urlPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'url');
    }
    get urlPrimitiveValue():string {
        return this.primitiveValueForKey('url');
    }

    // Property: width
    set width(value:number) {
        this.setValueForKey(value, 'width');
    }
    get width():number {
        return this.valueForKey('width');
    }
    set widthPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'width');
    }
    get widthPrimitiveValue():number {
        return this.primitiveValueForKey('width');
    }
}
