
/// <reference path="ReportFilterCell.ts" />


class ReportEntityFilterCell extends ReportFilterCell
{
    private dropdown:MUIButton = null;

    awakeFromHTML(): void {
        super.awakeFromHTML();

        this.dropdown = MUIOutlet(this, "dd-cell-btn", "MUIButton");
        this.setupDropdown(this.dropdown);
    }
    
    setFilter(filter:any) {
        super.setFilter(filter);
        this.dropdown.title = filter["ValueString"];
    }

    private setupDropdown(button:MUIButton){
        if (button == null) return;

        button.setAction(this, function(){

            let allowNoSelectionCell = true;
            let allowMultipleSelection = false;

            // if (this._filter.allowMultipleSelection){
            //     allowNoSelectionCell = true;
            //     allowMultipleSelection = true;    
            // }

			let vc = AppHelper.sharedInstance().selectViewController("Dropdown", this.filterItem["EntityTitleKey"], allowNoSelectionCell, allowMultipleSelection, null, this) as SelectEntityViewController;
			vc.allowSearch = true;
            vc.modalPresentationStyle = MUIModalPresentationStyle.Popover;

            let pc = vc.popoverPresentationController;
            pc.sourceRect = button.frame;
            pc.sourceView = button;

            AppHelper.sharedInstance().presentViewController(vc, true);
        });        
    }

    fetchRequestForController(controller:SelectEntityViewController): MIOFetchRequest {

        let sd = [MIOSortDescriptor.sortDescriptorWithKey(this.filterItem["EntityTitleKey"], true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName(this.filterItem["EntityName"], sd, null);
        return fetchRequest;
    }

    didSelectObjectFromSelectViewController(controller:SelectEntityViewController, item:MIOManagedObject){
        if (item != null) {
            let value = item.valueForKey(this.filterItem["EntityValueKey"]);
            let title = item.valueForKey(this.filterItem["EntityTitleKey"]);
            this.dropdown.title = title;
            this.filterItem["Value"] = value;
            this.filterItem["ValueString"] = title;
        }
        else {
            this.dropdown.title = MIOLocalizeString("NONE", "NONE");
            this.filterItem["Value"] = null;
            this.filterItem["ValueString"] = null;
        }
        
        return true;
    }  

}