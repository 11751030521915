

// Generated class ReportScriptItem_ManagedObject

class ReportScriptItem_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: content
    set content(value:string) {
        this.setValueForKey(value, 'content');
    }
    get content():string {
        return this.valueForKey('content');
    }
    set contentPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'content');
    }
    get contentPrimitiveValue():string {
        return this.primitiveValueForKey('content');
    }
    // Relationship: parent
    set parent(value:ReportScriptItem) {
        this.setValueForKey(value, 'parent');
    }
    get parent():ReportScriptItem {
        return this.valueForKey('parent') as ReportScriptItem;
    }

    // Relationship: filters
    protected _filters:MIOManagedObjectSet = null;
    get filters():MIOManagedObjectSet {
        return this.valueForKey('filters');
    }
    addFiltersObject(value:ReportScriptFilter) {
        this._addObjectForKey(value, 'filters');
    }
    removeFiltersObject(value:ReportScriptFilter) {
        this._removeObjectForKey(value, 'filters');
    }
}
