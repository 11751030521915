//
// Generated class AccountLine
//

/// <reference path="AccountLine_ManagedObject.ts" />

enum AccountLineType{
    None = 0,
    AddFunds = 1,
    RemoveFunds = 2,
    Payment = 3,
    Nullify = 4,
    Refund = 5,
}

class AccountLine extends AccountLine_ManagedObject
{

}
