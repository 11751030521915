//
// Generated class PaymentInstallmentTemplate
//

/// <reference path="PaymentInstallmentTemplate_ManagedObject.ts" />

class PaymentInstallmentTemplate extends PaymentInstallmentTemplate_ManagedObject
{

}
