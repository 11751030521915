
class ContactCell extends UITableViewCell
{
    private nameTextField:MUITextField = null;
    private emailTextField:MUITextField = null;
    private phoneTextField:MUITextField = null;

    awakeFromHTML(){
        this.nameTextField = MUIOutlet(this, "contact-name-tf", "MUITextField");
        this.nameTextField.setOnChangeText(this, function(this:ContactCell, control:MUITextField, value:string){
            this.contact.name = value;
        });

        this.emailTextField = MUIOutlet(this, "contact-email-tf", "MUITextField");
        this.emailTextField.setOnChangeText(this, function(this:ContactCell, control:MUITextField, value:string){
            this.contact.email = value.length > 0 ? value : null;
        });

        this.phoneTextField = MUIOutlet(this, "contact-phone-tf", "MUITextField");
        this.phoneTextField.setOnChangeText(this, function(this:ContactCell, control:MUITextField, value:string){
            this.contact.phone = value.length > 0 ? value : null;
        });

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private contact:Contact = null;
    set item (item:Contact) {
        this.contact = item;
        this.nameTextField.text = item.name;
        this.emailTextField.text = item.email;
        this.phoneTextField.text = item.phone;
    }
}