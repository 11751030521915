//
// Generated class WarehouseDeliveryNoteLine
//

/// <reference path="WarehouseDeliveryNoteLine_ManagedObject.ts" />

class WarehouseDeliveryNoteLine extends WarehouseDeliveryNoteLine_ManagedObject
{

}
