

/// <reference path="CoreAccounting.ts" />

// Generated class DBDocumentLine_ManagedObject

class DBDocumentLine_ManagedObject extends CoreAccounting
{

    // Property: comments
    set comments(value:string) {
        this.setValueForKey(value, 'comments');
    }
    get comments():string {
        return this.valueForKey('comments');
    }
    set commentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'comments');
    }
    get commentsPrimitiveValue():string {
        return this.primitiveValueForKey('comments');
    }

    // Property: concept
    set concept(value:string) {
        this.setValueForKey(value, 'concept');
    }
    get concept():string {
        return this.valueForKey('concept');
    }
    set conceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'concept');
    }
    get conceptPrimitiveValue():string {
        return this.primitiveValueForKey('concept');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: quantity
    set quantity(value:number) {
        this.setValueForKey(value, 'quantity');
    }
    get quantity():number {
        return this.valueForKey('quantity');
    }
    set quantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'quantity');
    }
    get quantityPrimitiveValue():number {
        return this.primitiveValueForKey('quantity');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }
    // Relationship: businessArea
    set businessArea(value:BusinessArea) {
        this.setValueForKey(value, 'businessArea');
    }
    get businessArea():BusinessArea {
        return this.valueForKey('businessArea') as BusinessArea;
    }
    // Relationship: document
    set document(value:DBDocument) {
        this.setValueForKey(value, 'document');
    }
    get document():DBDocument {
        return this.valueForKey('document') as DBDocument;
    }

    // Relationship: lines
    protected _lines:MIOManagedObjectSet = null;
    get lines():MIOManagedObjectSet {
        return this.valueForKey('lines');
    }
    addLinesObject(value:DBDocumentLine) {
        this._addObjectForKey(value, 'lines');
    }
    removeLinesObject(value:DBDocumentLine) {
        this._removeObjectForKey(value, 'lines');
    }
    // Relationship: nullifyLine
    set nullifyLine(value:DBDocumentLine) {
        this.setValueForKey(value, 'nullifyLine');
    }
    get nullifyLine():DBDocumentLine {
        return this.valueForKey('nullifyLine') as DBDocumentLine;
    }
    // Relationship: nullifyLineBy
    set nullifyLineBy(value:DBDocumentLine) {
        this.setValueForKey(value, 'nullifyLineBy');
    }
    get nullifyLineBy():DBDocumentLine {
        return this.valueForKey('nullifyLineBy') as DBDocumentLine;
    }
    // Relationship: parent
    set parent(value:DBDocumentLine) {
        this.setValueForKey(value, 'parent');
    }
    get parent():DBDocumentLine {
        return this.valueForKey('parent') as DBDocumentLine;
    }
    // Relationship: sourceLine
    set sourceLine(value:DBDocumentLine) {
        this.setValueForKey(value, 'sourceLine');
    }
    get sourceLine():DBDocumentLine {
        return this.valueForKey('sourceLine') as DBDocumentLine;
    }
    // Relationship: worker
    set worker(value:Employee) {
        this.setValueForKey(value, 'worker');
    }
    get worker():Employee {
        return this.valueForKey('worker') as Employee;
    }
}
