//
// Generated class Report
//

/// <reference path="Report_ManagedObject.ts" />

class Report extends Report_ManagedObject
{

}
