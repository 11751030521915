//
// Generated class ProductWarehouse
//

/// <reference path="ProductWarehouse_ManagedObject.ts" />

class ProductWarehouse extends ProductWarehouse_ManagedObject
{

}
