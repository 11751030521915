//
// Generated class Expense
//

/// <reference path="Expense_ManagedObject.ts" />

class Expense extends Expense_ManagedObject
{

}
