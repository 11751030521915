
/// <reference path="DashboardCellView.ts" />

class DashboardSalesPerHourCellView extends DashboardCellView 
{    
    static hoursCellView(): DashboardSalesPerHourCellView {
        
        var dv = new DashboardSalesPerHourCellView();
        dv.init();

        return dv;
    }
            
    init(){
        super.init();

        this.title = "Sales per hour";
       // this.report = ReportHelper.sharedInstance().salesPerHourReportQuery();
        this.chartType = MUIChartViewType.Line;
        this.labelKey = "title";
        this.valueKey = "value";
    }

}