


enum DashboardCellType {

    None,
    SalesComparationDay,
    SalesByHour,
    TopProducts,
    TopWorkers
}

class DashboardCollectionCell extends MUICollectionViewCell {

    private _dashboardView:DashboardCellView = null;

    private loadingView: MUIView = null;
    private titleLabel: MUILabel = null;
    private chartView: MUIChartView = null;

    awakeFromHTML() {

        this.loadingView = MUIOutlet(this, 'dbv_activity-indicator', 'MUIView');
        this.loadingView.setHidden(false);

        this.titleLabel = MUIOutlet(this, 'dbv_title_lbl', 'MUILabel');
        this.chartView = MUIOutlet(this, 'dbv_chart_content', 'MUIChartView');
    }

    get dashboardView() {return this._dashboardView;}
    set dashboardView(view){
        if (this._dashboardView != null){
            this._dashboardView.removeFromSuperview();
        }
                
        this._dashboardView = view;
        if (view == null) return;

        this._dashboardView.loadingView = this.loadingView;        
        this._dashboardView.chartView = this.chartView;
        this._dashboardView.titleLabel = this.titleLabel;

        this.addSubview(view);                
    }

    show(){
        if (this._dashboardView == null) return;
        this._dashboardView.reloadData();
    }

    hide() {

    }
}