
class ProductRatesRuleViewController extends MUIViewController
{
    private saveButton:MUIButton = null;
    private errorMsgLabel:MUILabel = null;
    private amountTextfield:MUITextField = null;
    private percentSwitch:MUISwitchButton = null;
    private removeButton:MUIButton = null;
    private modifyStringValue:string = null;

    private moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
    private nf:MIONumberFormatter = MUIWebApplication.sharedInstance().delegate.MIONumberFormatter;
    delegate = null;
    
    public preferredContentSize = new MIOSize(300, 230);

    viewDidLoad(){
        super.viewDidLoad();

        this.saveButton = MUIOutlet(this, 'save-btn', 'MUIButton');             
        this.saveButton.setAction(this, this.saveModifyStringValue);

        this.errorMsgLabel = MUIOutlet(this, 'error-msg-lbl', 'MUILabel');

        this.amountTextfield = MUIOutlet(this, 'amount-tf', 'MUITextField');
        this.amountTextfield.setOnChangeText(this, function(textfield, value){
            this.modifyStringValue = value.trim();
        });

        this.percentSwitch = MUIOutlet(this, "isPercent-switch-btn", "MUISwitchButton");

        this.removeButton = MUIOutlet(this, "remove-btn", "MUIButton");
        this.removeButton.setAction(this, function(){
            this._rate.rule = null;
            this.moc.save();
            this.dismissViewController(true);
            this.delegate.updateUI();
        })
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    private _rate:Rate = null;
    set item(item:Rate){
        this._rate = item;
        this.updateUI();
    }   

    private updateUI(){
        if (this.viewIsLoaded == false || this._rate == null) return;

        //set variables from item here
        if(this._rate.rule && this._rate.rule.length > 0){
            let rateString:string = this._rate.rule.trim();
            if(rateString.charAt(rateString.length-1) == "%"){
                this.percentSwitch.on = true;
                this.modifyStringValue = rateString.slice(0, -1); //remove the percentage
            } else {
                this.percentSwitch.on = false;
                this.modifyStringValue = rateString;
            }
            this.amountTextfield.text = this.modifyStringValue;
        }
    }

    private saveModifyStringValue(){

        // if first character is not + add a +
        if(this.modifyStringValue.charAt(0) != "+" && this.modifyStringValue.charAt(0) != "-")
            this.modifyStringValue = "+" + this.modifyStringValue;

        // replace all commas with dots to save in DB
        //this.modifyStringValue = this.modifyStringValue.replaceAll(',', '.');

        // check that string is valid then save
        if(this.modifyStringValue.length > 0 && this.validateNumber(this.modifyStringValue)) {
            this._rate.rule = this.modifyStringValue + ((this.percentSwitch.on) ? "%" : "");
            this.moc.save();
            this.dismissViewController(true);
            this.delegate.updateUI();
        } else {
            this.updateUI();
            this.errorMsgLabel.text = MIOLocalizeString("PLEASE IMPUT A VALID NUMBER", "PLEASE IMPUT A VALID NUMBER");
        }
    }

    private validateNumber(value:string){
        // check that number is valid (postive or negative decimal number)
        // regix string also found in HTML
        const rgex = new RegExp(/^[+-]?[0-9]\d*(\.\d+)?$/);

        if(value.length == 0)
            return false;
                
        if(!rgex.test(value))
            return false;
        
        return true;
    }

}