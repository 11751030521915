/// <reference path="../notes/NoteDetailViewController.ts" />



class DeliveryNoteDetailViewController extends NoteDetailViewController
{
    private noEditHeaderView:DeliveryNoteDetailHeader = null;
    private editHeaderView:DeliveryNoteDetailHeader = null;   
    
    viewDidLoad(){
        super.viewDidLoad();

        this.noEditHeaderView = MUIOutlet(this, "no-edit-header-view", "DeliveryNoteDetailHeader");
        this.editHeaderView = MUIOutlet(this, "edit-header-view", "DeliveryNoteDetailHeader");

        this.baseLabel = MUIOutlet(this, "base-lbl", "MUILabel");
        this.taxLabel = MUIOutlet(this, "tax-lbl", "MUILabel");
        this.totalLabel = MUIOutlet(this, "total-lbl", "MUILabel");
    }

    protected updateUI(){        
        this.editHeaderView.note = this.stockNote;
        this.noEditHeaderView.note = this.stockNote;
        this.editHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? true : false;
        this.noEditHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? false : true;

        // this.baseLabel.text = "-";
        // this.taxLabel.text = "-";
        this.totalLabel.text = "-";

        this.fetchedResultsController = null;
        this.tableView.reloadData();                

        this.updateTotal();
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true)];
        let predicateFormat = 'note.identifier == ' + this.stockNote.identifier;
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("DeliveryNoteLine", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["tax", "inputFormat", "product.defaultWarehouse"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){
        super.controllerDidChangeContent(controller);
        this.updateTotal();
    } 

    private updateTotal(){
        let [base, taxes, total] = this.computeTotals();

        // this.baseLabel.text = this.cf.stringFromNumber(this.base);
        // this.taxLabel.text = this.cf.stringFromNumber(this.taxes);
        this.totalLabel.text = this.cf.stringFromNumber(this.total);
    }
}