//
// Generated class JobTask
//

/// <reference path="JobTask_ManagedObject.ts" />

class JobTask extends JobTask_ManagedObject
{

}
