//
// Generated class Department
//

/// <reference path="Department_ManagedObject.ts" />

class Department extends Department_ManagedObject
{

}
