

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class RoomZone_ManagedObject

class RoomZone_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }
    // Relationship: parent
    set parent(value:RoomZone) {
        this.setValueForKey(value, 'parent');
    }
    get parent():RoomZone {
        return this.valueForKey('parent') as RoomZone;
    }
    // Relationship: rooms
    set rooms(value:Room) {
        this.setValueForKey(value, 'rooms');
    }
    get rooms():Room {
        return this.valueForKey('rooms') as Room;
    }

    // Relationship: subZones
    protected _subZones:MIOManagedObjectSet = null;
    get subZones():MIOManagedObjectSet {
        return this.valueForKey('subZones');
    }
    addSubZonesObject(value:RoomZone) {
        this._addObjectForKey(value, 'subZones');
    }
    removeSubZonesObject(value:RoomZone) {
        this._removeObjectForKey(value, 'subZones');
    }
}
