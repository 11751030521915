/**
 * Created by godshadow on 04/08/16.
 */


class WarehouseSetupViewController extends SingleEditViewController
{
    get title(){
        return MIOLocalizeString("WAREHOUSES","WAREHOUSES");
    }

    fetchEntity(){
        return "Warehouse";
    }  

    sortDescriptors(){
        return [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
    }

    configureCellAtIndexPath(cell:SingleEditCell, indexPath:MIOIndexPath, item:MIOManagedObject){
        let d = item as Department;
        cell.setItem(d, "name");
    }

    addButtonDidPress(){
        this.addWarehouse();
    }

    removeItem(item:MIOManagedObject){
        DBHelper.deleteObjectFromMainContext(item, true);
    }
    
    private addWarehouse(){
        var avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('NEW WAREHOUSE','NEW WAREHOUSE'), MIOLocalizeString('CHOOSE A NAME','CHOOSE A NAME '), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD','ADD'), MUIAlertActionStyle.Default, this, function(){
            let nameTF =  avc.textFields[0];
            let name = nameTF.text.trim();

            if(name.length > 0){
                let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
                this.warehouse = MIOEntityDescription.insertNewObjectForEntityForName("Warehouse", moc) as Warehouse;
                this.warehouse.name = name;
                moc.save();
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'Error'), MIOLocalizeString('CHECK NAME','Check name'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CLOSE','CLOSE'), MUIAlertActionStyle.Cancel, this, function(){}));
                
                this.presentViewController(error, true);
            }
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, this, function(){}));
        
        this.presentViewController(avc, true);
    }
}
