class SourceGlobalActionsViewController extends MUIViewController
{  
    private backButton:MUIButton = null;
    private nextButton:MUIButton = null;
    private applyToComboBox:MUIComboBox = null;
    private tableView:MUITableView = null;

    private selectedProductSource:ProductSource = null;
    private selectedFormat:Format = null;

    public delegate = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");
        this.backButton.setAction(this, function (){    
            MIONotificationCenter.defaultCenter().removeObserver(this, "globalActionsDidSelectCategory");        
            this.navigationController.popViewController(true);
        });
        
        this.nextButton = MUIOutlet(this, "next-btn", "MUIButton");
        this.nextButton.setAction(this, function (){

            if (this.selectedProductSource){
                let sgavc = new SelectCategoryGlobalActionsViewController('select-category-global-actions-view');
                sgavc.initWithResource("layout/globalactions/SelectCategoryGlobalActionsView.html");
                this.navigationController.pushViewController(sgavc, true);   
            } else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ALERT', 'ALERT'), MIOLocalizeString('PLEASE MAKE SELECTION','PLEASE MAKE SELECTION'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                this.presentViewController(error, true);
            }
        });

        this.applyToComboBox = MUIOutlet(this, 'apply-to-cb','MUIComboBox');
        this.setupComboBox(this.applyToComboBox);

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        //CAUTION: be sure to remove the observer to avoid repeat observers
        MIONotificationCenter.defaultCenter().addObserver(this, "globalActionsDidSelectCategory", function(notification:MIONotification){
            let categories = notification.object as ProductCategory[];
            this.applySourceToFormatForCategory(this.selectedProductSource, this.selectedFormat, categories);
        }); 
    }

    viewWillAppear(){
        super.viewWillAppear();
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        this.selectedProductSource = null;
        this.selectedFormat = null;
        this.applyToComboBox.selectItem(MIOLocalizeString("BASE PRODUCT","BASE PRODUCT")); 

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableView:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
                                              
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){        
        let cell = tableView.dequeueReusableCellWithIdentifier("GlobalActionsLabelCell") as GlobalActionsLabelCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ProductSource;                      
        cell.item = item;
        return cell;
    }   

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){ 
        this.selectedProductSource = this.fetchedResultsController.objectAtIndexPath(indexPath);
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        var ad = MUIWebApplication.sharedInstance().delegate;
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('name', true)];
    
        var fetchRequest = DBHelper.listFetchRequestWithEntityName('ProductSource', sortDescriptors, null);
        var fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }


    private setupComboBox(comboBox:MUIComboBox){

        let availableFormats = null;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        comboBox.removeAllItems();

        comboBox.addItem(MIOLocalizeString("BASE PRODUCT","BASE PRODUCT"));
        let sortDescriptors = MIOSortDescriptor.sortDescriptorWithKey('name', true);  
        DBHelper.queryObjectsWithCompletion("Format", sortDescriptors, null, [], this, function(objects){
            availableFormats = objects; 
            for (let index = 0; index < objects.length; index++){
                let tr = objects[index] as Format;
                comboBox.addItem(tr.name);
            }
        });

        comboBox.setOnChangeAction(this, function(control, index){            
            let formatName = comboBox.getSelectedItem();
            this.selectedFormat = null;
            // try to find the matching format, if no format then it is null
            for (let i = 0; i < availableFormats.length; i++){
                if (formatName == availableFormats[i].name) {
                    this.selectedFormat = availableFormats[i];
                    break;
                }
            }
        });
    }

    private applySourceToFormatForCategory(productSource:ProductSource, format:Format, selectedProductCategories:ProductCategory[]){

        //if productCategory is null, then ALL CATEGORIES is selected
        //if format is null, then ALL PRODUCTS is selected

        let predicateString = "";
        let predicate = null;
        let sortDescriptors = MIOSortDescriptor.sortDescriptorWithKey('name', true); 
        let msg = null;
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

        if (!format){    // if no format selected, make changes on base product

            if (selectedProductCategories.length > 0) {     
                predicateString += "(";
                for (let i = 0; i < selectedProductCategories.length; i++) {
                    if(i != 0) predicateString += " OR ";
                    predicateString += "category.identifier = '" + selectedProductCategories[i].identifier + "'";
                }
                predicateString += ")";
                predicate = MIOPredicate.predicateWithFormat(predicateString);
            } 

            DBHelper.queryObjectsWithCompletion("Product", sortDescriptors, predicate, ["category", "source", "productFormats"], this, function(objects){
            
                // if (objects.length <= 0){
                //     msg = new MUIAlertViewController();
                //     msg.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('NO OBJECTS TO CHANGE FOUND','NO OBJECTS TO CHANGE FOUND'), MUIAlertViewStyle.Default);
                //     msg.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                //     this.presentViewController(msg, true);
                //     return;
                // }

                for (let index = 0; index < objects.length; index++){
                    objects[index].source = productSource;
                }

                // save changes then give confirmation message
                moc.save();

                // msg = new MUIAlertViewController();
                // msg.initWithTitle(MIOLocalizeString('UPDATED', 'UPDATED'), productSource.name + MIOLocalizeString(' APPLIED TO PRODUCTS IN ',' APPLIED TO PRODUCTS IN ') + productCategory.name, MUIAlertViewStyle.Default);
                // msg.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                // this.presentViewController(msg, true);
            });

        } else {     // if format selected, get productFormats

            if (selectedProductCategories.length > 0) {     
                predicateString += "(";
                for (let i = 0; i < selectedProductCategories.length; i++) {
                    if(i != 0) predicateString += " OR ";
                    predicateString += "product.category.identifier = '" + selectedProductCategories[i].identifier + "'";
                }
                predicateString += ")";
            }

            if(selectedProductCategories.length > 0) predicateString += " AND "; 
            predicateString += "format.identifier = '" + format.identifier + "'"; 
            predicate = MIOPredicate.predicateWithFormat(predicateString);

            DBHelper.queryObjectsWithCompletion("ProductFormat", sortDescriptors, predicate, ["product", "product.category", "format"], this, function(objects){
            
                // if (objects.length <= 0){
                //     msg = new MUIAlertViewController();
                //     msg.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('NO OBJECTS TO CHANGE FOUND','NO OBJECTS TO CHANGE FOUND'), MUIAlertViewStyle.Default);
                //     msg.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                //     this.presentViewController(msg, true);
                //     return;
                // }

                for (let index = 0; index < objects.length; index++){
                    objects[index].productSource = productSource;
                }

                // save changes then give confirmation message
                moc.save();

                // msg = new MUIAlertViewController();
                // msg.initWithTitle(MIOLocalizeString('UPDATED', 'UPDATED'), productSource.name + MIOLocalizeString(' APPLIED TO ',' APPLIED TO ') + format.name + MIOLocalizeString(' IN ',' IN ') + productCategory.name, MUIAlertViewStyle.Default);
                // msg.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                // this.presentViewController(msg, true);
            });
        }
    
    }
}