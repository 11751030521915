//
// Generated class RateName
//

/// <reference path="RateName_ManagedObject.ts" />

class RateName extends RateName_ManagedObject
{

}
