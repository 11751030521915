
class PendingPaymentsViewController extends BaseTableViewController
{
    static newInstance() : PendingPaymentsViewController
    {
        let vc = new PendingPaymentsViewController( "pending-payments-view" );
        vc.initWithResource( "layout/pendingpayments/PendingPaymentsView.html" );
        return vc;
    }

    private columnFilterController:ColumnFilterController = null;
	    
	private dateFilterTextField:ColumnFilterTextField = null;
    private documentFilterTextField:ColumnFilterTextField = null;
	private conceptFilterTextField:ColumnFilterTextField = null;
	private amountFilterTextField:ColumnFilterTextField = null;

    private detailTableView:UITableView = null;
    private logDataSource:BusinessLicenseLogDataSource = null;

    viewDidLoad(): void {
        super.viewDidLoad();

        this.columnFilterController = new ColumnFilterController();
		this.columnFilterController.initWithDelegate(this);

        this.dateFilterTextField = MUIOutlet(this, "date-tf", "ColumnFilterTextField");
		this.dateFilterTextField.filterController = this.columnFilterController;
		this.dateFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Date, "date", null, null);

        this.documentFilterTextField = MUIOutlet(this, "document-tf", "ColumnFilterTextField");
		this.documentFilterTextField.filterController = this.columnFilterController;
		this.documentFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "documentNumber", null, null);

        this.conceptFilterTextField = MUIOutlet(this, "concept-tf", "ColumnFilterTextField");
		this.conceptFilterTextField.filterController = this.columnFilterController;
		this.conceptFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "concept", null, null);

        this.amountFilterTextField = MUIOutlet(this, "amount-tf", "ColumnFilterTextField");
		this.amountFilterTextField.filterController = this.columnFilterController;
		this.amountFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Currency, "amount", null, null);

        this.tableView = MUIOutlet( this, "table-view", "UITableView" );
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.detailTableView = MUIOutlet(this, "detail-table-view", "UITableView");
        this.logDataSource = new BusinessLicenseLogDataSource();
        this.logDataSource.initWithTableView( this.detailTableView );
    }
 
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath( indexPath ) as PaymentInstallment;
                
        let cell = tableView.dequeueReusableCellWithIdentifier( "PendingPaymentsCell" ) as PendingPaymentsCell;
        cell.item = item;

        return cell;
    }    

    didSelectCellAtIndexPath(tableView,indexPath:MIOIndexPath){
        this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath) as PaymentInstallment;
        // this.showSelectedItem(this.businessLicense);

        if ( this.selectedItem instanceof LicensePaymentInstallment ){
            this.logDataSource.item = this.selectedItem.business; 
            this.detailTableView.dataSource = this.logDataSource;
            this.detailTableView.delegate = this.logDataSource;
            this.logDataSource.fetchedResultsController = null;

            this.detailTableView.reloadData();
        }
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [ 
            MIOSortDescriptor.sortDescriptorWithKey( "date", true ),
            MIOSortDescriptor.sortDescriptorWithKey( "concept", true ) 
        ];

        let predicateFormat = "deletedAt = null";
        predicateFormat += "and ( status = " + PaymentInstallmentStatus.pending;
        predicateFormat += "   or status = " + PaymentInstallmentStatus.locked;
        predicateFormat += "   or status = " + PaymentInstallmentStatus.unpaid;
        predicateFormat += ")"

        let filterPredicateFormat = this.columnFilterController.filterPredicateFormat();
        if (filterPredicateFormat != null){
            predicateFormat += " AND (" + filterPredicateFormat + ")";
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName( "LegalEntityPaymentInstallment", sortDescriptors, predicateFormat );
        fetchRequest.relationshipKeyPathsForPrefetching = [ "paymentMethod", "invoice" ];
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "monthString" );
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    filterPredicateDidChange(controller:ColumnFilterController){        
        this._fetchedResultsController = null;
        this.tableView.reloadData();        
    }

}