//
// Generated class Intermediary
//

/// <reference path="Intermediary_ManagedObject.ts" />

class Intermediary extends Intermediary_ManagedObject
{

}
