

// Generated class LogOperation_ManagedObject

class LogOperation_ManagedObject extends MIOManagedObject
{

    // Property: identifier
    set identifier(value:string) {
        this.setValueForKey(value, 'identifier');
    }
    get identifier():string {
        return this.valueForKey('identifier');
    }
    set identifierPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'identifier');
    }
    get identifierPrimitiveValue():string {
        return this.primitiveValueForKey('identifier');
    }

    // Property: logLevel
    set logLevel(value:number) {
        this.setValueForKey(value, 'logLevel');
    }
    get logLevel():number {
        return this.valueForKey('logLevel');
    }
    set logLevelPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'logLevel');
    }
    get logLevelPrimitiveValue():number {
        return this.primitiveValueForKey('logLevel');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: appName
    set appName(value:string) {
        this.setValueForKey(value, 'appName');
    }
    get appName():string {
        return this.valueForKey('appName');
    }
    set appNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'appName');
    }
    get appNamePrimitiveValue():string {
        return this.primitiveValueForKey('appName');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: subType
    set subType(value:number) {
        this.setValueForKey(value, 'subType');
    }
    get subType():number {
        return this.valueForKey('subType');
    }
    set subTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'subType');
    }
    get subTypePrimitiveValue():number {
        return this.primitiveValueForKey('subType');
    }

    // Property: concept
    set concept(value:string) {
        this.setValueForKey(value, 'concept');
    }
    get concept():string {
        return this.valueForKey('concept');
    }
    set conceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'concept');
    }
    get conceptPrimitiveValue():string {
        return this.primitiveValueForKey('concept');
    }

    // Property: employeeName
    set employeeName(value:string) {
        this.setValueForKey(value, 'employeeName');
    }
    get employeeName():string {
        return this.valueForKey('employeeName');
    }
    set employeeNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'employeeName');
    }
    get employeeNamePrimitiveValue():string {
        return this.primitiveValueForKey('employeeName');
    }

    // Property: workerID
    set workerID(value:string) {
        this.setValueForKey(value, 'workerID');
    }
    get workerID():string {
        return this.valueForKey('workerID');
    }
    set workerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerID');
    }
    get workerIDPrimitiveValue():string {
        return this.primitiveValueForKey('workerID');
    }

    // Property: sourceFile
    set sourceFile(value:string) {
        this.setValueForKey(value, 'sourceFile');
    }
    get sourceFile():string {
        return this.valueForKey('sourceFile');
    }
    set sourceFilePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceFile');
    }
    get sourceFilePrimitiveValue():string {
        return this.primitiveValueForKey('sourceFile');
    }

    // Property: infoXML
    set infoXML(value:string) {
        this.setValueForKey(value, 'infoXML');
    }
    get infoXML():string {
        return this.valueForKey('infoXML');
    }
    set infoXMLPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'infoXML');
    }
    get infoXMLPrimitiveValue():string {
        return this.primitiveValueForKey('infoXML');
    }

    // Property: locationName
    set locationName(value:string) {
        this.setValueForKey(value, 'locationName');
    }
    get locationName():string {
        return this.valueForKey('locationName');
    }
    set locationNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationName');
    }
    get locationNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationName');
    }

    // Property: orderID
    set orderID(value:string) {
        this.setValueForKey(value, 'orderID');
    }
    get orderID():string {
        return this.valueForKey('orderID');
    }
    set orderIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderID');
    }
    get orderIDPrimitiveValue():string {
        return this.primitiveValueForKey('orderID');
    }

    // Property: ticketID
    set ticketID(value:string) {
        this.setValueForKey(value, 'ticketID');
    }
    get ticketID():string {
        return this.valueForKey('ticketID');
    }
    set ticketIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ticketID');
    }
    get ticketIDPrimitiveValue():string {
        return this.primitiveValueForKey('ticketID');
    }

    // Property: ticketLegalID
    set ticketLegalID(value:string) {
        this.setValueForKey(value, 'ticketLegalID');
    }
    get ticketLegalID():string {
        return this.valueForKey('ticketLegalID');
    }
    set ticketLegalIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ticketLegalID');
    }
    get ticketLegalIDPrimitiveValue():string {
        return this.primitiveValueForKey('ticketLegalID');
    }

    // Property: destinationLocationName
    set destinationLocationName(value:string) {
        this.setValueForKey(value, 'destinationLocationName');
    }
    get destinationLocationName():string {
        return this.valueForKey('destinationLocationName');
    }
    set destinationLocationNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'destinationLocationName');
    }
    get destinationLocationNamePrimitiveValue():string {
        return this.primitiveValueForKey('destinationLocationName');
    }

    // Property: destinationOrderID
    set destinationOrderID(value:string) {
        this.setValueForKey(value, 'destinationOrderID');
    }
    get destinationOrderID():string {
        return this.valueForKey('destinationOrderID');
    }
    set destinationOrderIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'destinationOrderID');
    }
    get destinationOrderIDPrimitiveValue():string {
        return this.primitiveValueForKey('destinationOrderID');
    }

    // Property: destinationTicketID
    set destinationTicketID(value:string) {
        this.setValueForKey(value, 'destinationTicketID');
    }
    get destinationTicketID():string {
        return this.valueForKey('destinationTicketID');
    }
    set destinationTicketIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'destinationTicketID');
    }
    get destinationTicketIDPrimitiveValue():string {
        return this.primitiveValueForKey('destinationTicketID');
    }

    // Property: totalDeleted
    set totalDeleted(value:number) {
        this.setValueForKey(value, 'totalDeleted');
    }
    get totalDeleted():number {
        return this.valueForKey('totalDeleted');
    }
    set totalDeletedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalDeleted');
    }
    get totalDeletedPrimitiveValue():number {
        return this.primitiveValueForKey('totalDeleted');
    }

    // Property: totalInvited
    set totalInvited(value:number) {
        this.setValueForKey(value, 'totalInvited');
    }
    get totalInvited():number {
        return this.valueForKey('totalInvited');
    }
    set totalInvitedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalInvited');
    }
    get totalInvitedPrimitiveValue():number {
        return this.primitiveValueForKey('totalInvited');
    }

    // Property: totalPrice
    set totalPrice(value:number) {
        this.setValueForKey(value, 'totalPrice');
    }
    get totalPrice():number {
        return this.valueForKey('totalPrice');
    }
    set totalPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalPrice');
    }
    get totalPricePrimitiveValue():number {
        return this.primitiveValueForKey('totalPrice');
    }
}
