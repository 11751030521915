

/// <reference path="PresetItem.ts" />

// Generated class PresetCategory_ManagedObject

class PresetCategory_ManagedObject extends PresetItem
{

    // Property: imageID
    set imageID(value:string) {
        this.setValueForKey(value, 'imageID');
    }
    get imageID():string {
        return this.valueForKey('imageID');
    }
    set imageIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'imageID');
    }
    get imageIDPrimitiveValue():string {
        return this.primitiveValueForKey('imageID');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }
    // Relationship: image
    set image(value:DBImage) {
        this.setValueForKey(value, 'image');
    }
    get image():DBImage {
        return this.valueForKey('image') as DBImage;
    }

    // Relationship: presetItems
    protected _presetItems:MIOManagedObjectSet = null;
    get presetItems():MIOManagedObjectSet {
        return this.valueForKey('presetItems');
    }
    addPresetItemsObject(value:PresetItem) {
        this._addObjectForKey(value, 'presetItems');
    }
    removePresetItemsObject(value:PresetItem) {
        this._removeObjectForKey(value, 'presetItems');
    }
}
