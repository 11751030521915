//
// Generated class Loan
//

/// <reference path="Loan_ManagedObject.ts" />

class Loan extends Loan_ManagedObject
{

}
