//
// Generated class Component
//

/// <reference path="Component_ManagedObject.ts" />

class Component extends Component_ManagedObject
{

}
