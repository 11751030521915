

/// <reference path="PrinterLink.ts" />

// Generated class TicketPrinterLink_ManagedObject

class TicketPrinterLink_ManagedObject extends PrinterLink
{

    // Property: isInvoice
    set isInvoice(value:boolean) {
        this.setValueForKey(value, 'isInvoice');
    }
    get isInvoice():boolean {
        return this.valueForKey('isInvoice');
    }
    set isInvoicePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isInvoice');
    }
    get isInvoicePrimitiveValue():boolean {
        return this.primitiveValueForKey('isInvoice');
    }

    // Property: isOnline
    set isOnline(value:boolean) {
        this.setValueForKey(value, 'isOnline');
    }
    get isOnline():boolean {
        return this.valueForKey('isOnline');
    }
    set isOnlinePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isOnline');
    }
    get isOnlinePrimitiveValue():boolean {
        return this.primitiveValueForKey('isOnline');
    }

    // Property: isStock
    set isStock(value:boolean) {
        this.setValueForKey(value, 'isStock');
    }
    get isStock():boolean {
        return this.valueForKey('isStock');
    }
    set isStockPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isStock');
    }
    get isStockPrimitiveValue():boolean {
        return this.primitiveValueForKey('isStock');
    }
}
