

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Report_ManagedObject

class Report_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: path
    set path(value:string) {
        this.setValueForKey(value, 'path');
    }
    get path():string {
        return this.valueForKey('path');
    }
    set pathPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'path');
    }
    get pathPrimitiveValue():string {
        return this.primitiveValueForKey('path');
    }

    // Property: scriptType
    set scriptType(value:string) {
        this.setValueForKey(value, 'scriptType');
    }
    get scriptType():string {
        return this.valueForKey('scriptType');
    }
    set scriptTypePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'scriptType');
    }
    get scriptTypePrimitiveValue():string {
        return this.primitiveValueForKey('scriptType');
    }

    // Property: section
    set section(value:string) {
        this.setValueForKey(value, 'section');
    }
    get section():string {
        return this.valueForKey('section');
    }
    set sectionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'section');
    }
    get sectionPrimitiveValue():string {
        return this.primitiveValueForKey('section');
    }

    // Property: visible
    set visible(value:boolean) {
        this.setValueForKey(value, 'visible');
    }
    get visible():boolean {
        return this.valueForKey('visible');
    }
    set visiblePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'visible');
    }
    get visiblePrimitiveValue():boolean {
        return this.primitiveValueForKey('visible');
    }

    // Relationship: localizedNames
    protected _localizedNames:MIOManagedObjectSet = null;
    get localizedNames():MIOManagedObjectSet {
        return this.valueForKey('localizedNames');
    }
    addLocalizedNamesObject(value:LocalizedName) {
        this._addObjectForKey(value, 'localizedNames');
    }
    removeLocalizedNamesObject(value:LocalizedName) {
        this._removeObjectForKey(value, 'localizedNames');
    }
}
