//
// Generated class ConsumptionProfile
//

/// <reference path="ConsumptionProfile_ManagedObject.ts" />

class ConsumptionProfile extends ConsumptionProfile_ManagedObject
{

}
