//
// Generated class DashBoardItem
//

/// <reference path="DashBoardItem_ManagedObject.ts" />

class DashBoardItem extends DashBoardItem_ManagedObject
{

}
