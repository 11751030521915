


class ClientsViewController extends MUIViewController
{

    private _splitController = null;

    private _clientsListViewController = null;
    private _clientDetailViewController = null;

    viewDidLoad(){
        super.viewDidLoad();

        this._splitController = new MUISplitViewController("clients_split_controller");
        this._splitController.init();
        this.view.addSubview(this._splitController.view);
        this.addChildViewController(this._splitController);

        this._clientsListViewController = new ClientsListViewController("clients-list-view");
        this._clientsListViewController.initWithResource("layout/clients/ClientsListView.html");

        this._clientDetailViewController = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Client", NoItemSelectedViewControllerType.Client);        

        this._splitController.setMasterViewController(this._clientsListViewController);
        this._splitController.setDetailViewController(this._clientDetailViewController);

    }

}