//
// Generated class OrderQuestion
//

/// <reference path="OrderQuestion_ManagedObject.ts" />

class OrderQuestion extends OrderQuestion_ManagedObject
{

}
