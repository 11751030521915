interface NoteLineCellDelegate 
{
    overrideWarehouse() : Warehouse
}

class NoteLineCell extends UITableViewCell
{
    delegate:NoteLineCellDelegate = null;

    protected productTextField:MUITextField = null;
    protected productLabel:MUILabel = null;
    protected conceptSubtextLabel:MUILabel = null;
    protected infoButton: MUIButton = null;
           
    protected inputFormatDropdown:MUIButton = null;
    protected inputFormatLabel:MUILabel = null;
    protected inputFormatProductLabel:MUILabel = null;
    protected quantityTextField:MUITextField = null;
    protected quantityLabel:MUILabel = null;
    protected priceTextField:MUITextField = null;
    protected priceLabel:MUILabel = null;      
    protected productQuantityLabel:MUILabel = null;  
    protected productPriceTextField:MUITextField = null;
    protected productPriceLabel:MUILabel = null;
    protected productGrossPriceLabel:MUILabel = null;
    protected discountFormatTextField:MUITextField = null;
    protected discountFormatLabel:MUILabel = null;    
    protected discountAmountLabel:MUILabel = null;
    protected totalTextField:MUITextField = null;
    protected totalLabel:MUILabel = null;



    protected nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;
    protected cf = MUIWebApplication.sharedInstance().delegate.longCurrencyFormatter;
    protected pnf = (MUIWebApplication.sharedInstance().delegate as AppDelegate).percentNumberFormatter;
    
    protected stockNote:StockNote = null;
    protected stockNoteLine:StockNoteLine = null;
    protected product:Product = null;
    protected productName:string = null;
    protected productMeasureType = MeasureUnitType.None;
    protected productContainerMeasureType = MeasureUnitType.None;
    protected productContainerQuantity = 0;
    protected tax:Tax = null;
    protected warehouse:Warehouse = null;
    protected warehouseName:string = null;
    protected inputType:MeasureUnitType = MeasureUnitType.None;
    protected inputFormat:StockInputFormat = null;
    protected quantity = 0;
    protected productQuantity = 0;
    protected minMeasureQuantity = 0;
    protected minMeasureUnitType = MeasureUnitType.None;
    protected priceAmount = 0;
    protected productNetPriceAmount = 0;
    protected productGrossPriceAmount = 0;
    protected discountFormat = null;
    protected discountAmount = 0;
    protected productDiscountAmount = 0;
    protected baseAmount = 0;
    protected taxesAmount = 0;
    protected totalAmount = 0;
    protected comments = null;

    protected lockStatus = LockedStatus.none;
    
    awakeFromHTML(){
        this.productTextField = MUIOutlet(this, 'product-tf', 'MUITextField');
        this.setupProductTextField(this.productTextField, this, this.productDidSelect);
        this.productLabel = MUIOutlet(this, "product-lbl", "MUILabel"); 
        this.conceptSubtextLabel = MUIOutlet(this, "concept-subtext-lbl", "MUILabel");
        this.infoButton = MUIOutlet(this, "info-icon-btn", "MUIButton");
        this.setupInfoButton(this.infoButton);

        this.inputFormatDropdown = MUIOutlet(this, "input-format-dd", "MUIButton");
        this.setupInputFormatDropdown(this.inputFormatDropdown);
        this.inputFormatLabel = MUIOutlet(this, "input-format-lbl", "MUILabel");
    
        this.inputFormatProductLabel = MUIOutlet(this, "input-unit-lbl", "MUILabel");

        this.quantityTextField = MUIOutlet(this, 'quantity-tf', 'MUITextField');   
        this.setupQuantityTextField(this.quantityTextField);        
        this.quantityLabel = MUIOutlet(this, "quantity-lbl", "MUILabel");

        this.productQuantityLabel = MUIOutlet(this, "product-quantity-lbl", "MUILabel");

        this.priceTextField = MUIOutlet(this, "price-tf", "MUITextField");
        this.setupPriceTextField(this.priceTextField);
        this.priceLabel = MUIOutlet(this, "price-lbl", "MUILabel");

        this.productPriceTextField = MUIOutlet(this, "product-price-tf", "MUITextField");
        this.setupProductPriceTextField(this.productPriceTextField);
        this.productPriceLabel = MUIOutlet(this, "product-price-lbl", "MUILabel");
        this.productGrossPriceLabel = MUIOutlet(this, "product-gross-price-lbl", "MUILabel");
        
        this.discountFormatTextField = MUIOutlet(this, "discount-tf", "MUITextField");        
        this.setupDiscountTextField(this.discountFormatTextField);
        this.discountFormatLabel = MUIOutlet(this, "discount-lbl", "MUILabel");

        this.discountAmountLabel = MUIOutlet(this, 'discount-amount-lbl', "MUILabel");        

        this.totalTextField = MUIOutlet(this, "total-tf", "MUITextField");
        this.setupTotalTextField(this.totalTextField);
        this.totalLabel = MUIOutlet(this, "total-lbl", "MUILabel");        

        this.reset();
        
        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    protected productDidSelect(controller:SelectEntityViewController, product:Product, supplierProduct?:SupplierProduct){
        this.reset();
        this.product = product;
        this.inputType = this.product.measureType;
        this.inputFormat = null;
        this.productMeasureType = this.product.measureType;
        this.productContainerMeasureType = this.product.containerMeasureType;
        this.productContainerQuantity = this.product.containerQuantity;

        this.tax = DBHelper.stockTaxFromProduct(product);
        if (typeof this.delegate.overrideWarehouse === "function") { 
            this.warehouse = this.delegate?.overrideWarehouse();
        }
        if ( this.warehouse == null ) {
            this.warehouse = product.defaultWarehouse ?? AppHelper.sharedInstance().defaultWarehouse;
        }

        // UI
        this.productTextField.text = this.product.productNameDescription();
        if (this.conceptSubtextLabel) this.conceptSubtextLabel.text = this.conceptSubtextString();

        this.inputFormatDropdown.enabled = true;
        this.inputFormatDropdown.title = product.defaultInputFormatString();

        if (this.inputFormatProductLabel) this.inputFormatProductLabel.text = this.inputFormatProductString();

        this.quantityTextField.enabled = true;
        this.quantityTextField.becomeFirstResponder();

        if (this.productQuantityLabel) this.productQuantityLabel.text = this.productQuantityString();

        if (this.priceTextField) {
            this.priceTextField.enabled = true;
            this.priceTextField.text = this.cf.stringFromNumber(this.priceAmount);
        }

        if (this.productPriceTextField) { 
            this.productPriceTextField.enabled = true;
            this.productPriceTextField.text = this.productNetPriceString();
        }        

        if (this.discountFormatTextField) {
            this.discountFormatTextField.enabled = true;        
            this.discountFormatTextField.text = this.discountFormat;
        }

        if (this.totalTextField) {
            this.totalTextField.enabled = true;
            this.totalTextField.text = this.basePriceString();
        }
    }

    set line(line:StockNoteLine){
        this.setLine(line);
    }
    
    protected reset(){
        this.lockStatus = LockedStatus.none;

        this.product = null;
        this.productContainerMeasureType = MeasureUnitType.None;
        this.productContainerQuantity = 0;
        this.tax = null;
        this.warehouse = null;
        this.inputFormat = null;
        this.inputType = MeasureUnitType.None;
        this.quantity = 0;
        this.productQuantity = 0;
        this.priceAmount = 0;
        this.productNetPriceAmount = 0;
        this.productGrossPriceAmount = 0;
        this.discountAmount = 0;        
        this.totalAmount = 0;
        this.comments = null;

        if (this.productTextField != null) this.productTextField.text = null;        
        if (this.conceptSubtextLabel != null) this.conceptSubtextLabel.text = null;
        if (this.infoButton) this.infoButton.hidden = false;

        if (this.inputFormatDropdown != null) {
            this.inputFormatDropdown.title = null;
            this.inputFormatDropdown.enabled = false;            
        }        

        if (this.inputFormatProductLabel != null) this.inputFormatProductLabel.text = null;

        if (this.quantityTextField != null) {
            this.quantityTextField.text = null;
            this.quantityTextField.enabled = false;
        }        

        if (this.productQuantityLabel) this.productQuantityLabel.text = null;

        if (this.priceTextField != null) {
            this.priceTextField.text = null;
            this.priceTextField.enabled = false;
        }

        if (this.productPriceLabel) this.productPriceLabel.text = null;
        if (this.productPriceTextField != null) {
            this.productPriceTextField.text = null;
            this.productPriceTextField.enabled = false;
        }
        

        if (this.discountFormatLabel) this.discountFormatLabel.text = null;
        if (this.discountFormatTextField != null) {
            this.discountFormatTextField.text = null;
            this.discountFormatTextField.enabled = false;
        }

        if (this.discountAmountLabel) this.discountAmountLabel.text = null;

        if (this.totalTextField != null) {
            this.totalTextField.text = null;
            this.totalTextField.enabled = false;
        }
    }

    setLine(line:StockNoteLine){
        this.reset();
        this.lockStatus = LockedStatus.none;        
        this.stockNoteLine = line;
        if (line instanceof StockNoteProductLine) this.product = line.product;
        this.productName = line.productName;
        this.productMeasureType = line.productMeasureType;
        this.productContainerMeasureType = line.productContainerMeasureType;
        this.productContainerQuantity = line.productContainerQuantity;
        this.tax = line.tax;
        this.inputFormat = line.inputFormat;
        this.inputType = line.quantityMeasureType;
        this.quantity = line.quantity;
        this.productQuantity = line.productQuantity;
        // this.measureQuantity = line.measureQuantity != null ? line.measureQuantity : line.productQuantity;
        this.priceAmount = line.price;
        this.productNetPriceAmount = line.productPrice;        
        this.discountFormat = line.discountString;
        this.discountAmount = line.discountAmount ?? 0;
        this.productGrossPriceAmount = line.baseAmount / line.productQuantity;
        this.baseAmount = line.baseAmount;
        this.taxesAmount = line.taxAmount;
        this.totalAmount = line.totalAmount;
        this.warehouse = line.warehouse;
        this.warehouseName = line.warehouse?.name;
        this.comments = line.comments;

        if (this.priceAmount == null) this.priceAmount = line.estimatedPrice;
        if (this.productNetPriceAmount == null) this.productNetPriceAmount = line.estimatedProductPrice;

        if (this.product) this.productLabel.text = this.product.productNameDescription();
        if (this.conceptSubtextLabel) this.conceptSubtextLabel.text = this.conceptSubtextString();
        if (this.infoButton) this.infoButton.hidden = ( line.note.status == StockNoteStatus.Processed )

        if (this.inputFormatLabel != null) this.inputFormatLabel.text = this.inputFormatString();
        if (this.inputFormatDropdown != null) {
            this.inputFormatDropdown.title = this.inputFormatString();
            this.inputFormatDropdown.enabled = true;
        }
        
        if (this.inputFormatProductLabel != null) this.inputFormatProductLabel.text = this.inputFormatProductString();

        let q = this.nf.stringFromNumber(line.quantity);
        if (this.quantityLabel != null) this.quantityLabel.text = q;
        if (this.quantityTextField != null) {
            this.quantityTextField.text = q;
            this.quantityTextField.enabled = true;
        }        

        if (this.productQuantityLabel != null) this.productQuantityLabel.text = this.productQuantityString();
        
        let p = this.cf.stringFromNumber(line.price);
        if (this.priceLabel != null) this.priceLabel.text = p;
        if (this.priceTextField != null) {
            this.priceTextField.text = p;
            this.priceTextField.enabled = true;
        }
                
        if (this.productPriceTextField) { 
            this.productPriceTextField.text = this.productNetPriceString();
            this.productPriceTextField.enabled = true;
        }

        if (this.productPriceLabel) this.productPriceLabel.text = this.productNetPriceString();
        if (this.productGrossPriceLabel) this.productGrossPriceLabel.text = this.productGrossPriceString();

        if (this.discountFormatLabel != null) this.discountFormatLabel.text = this.discountFormat ?? this.discountAmount;
        if (this.discountFormatTextField != null) {
            this.discountFormatTextField.text = this.discountFormat ?? this.discountAmount;
            this.discountFormatTextField.enabled = true;
        }

        if (this.discountAmountLabel) this.discountAmountLabel.text = this.cf.stringFromNumber(this.discountAmount);
        
        if (this.totalTextField != null) {
            this.totalTextField.text = this.basePriceString();
            this.totalTextField.enabled = true;
        }

        if (this.totalLabel != null) this.totalLabel.text = this.basePriceString();
    }

    set note(note:StockNote){
        this.stockNote = note;
    }    

    private setupInfoButton(button:MUIButton){
        if (button == null) return;         
        button.setAction(this, function(this:NoteLineCell) {
            if (this.stockNote.status == 2) return;
            this.showLinePropertiesAlertView();
        });
    }

    private showLinePropertiesAlertView(){

        let warehouse = this.warehouse;
        let tax = this.tax;
        let comments = this.comments;

        let avc = new MUIAlertViewController();
        avc.initWithTitle( MIOLocalizeString("PRODUCT OPTIONS", "PRODUCT OPTIONS"), null, MUIAlertViewStyle.Default);

        avc.addComboBoxWithConfigurationHandler( this, function(comboBox:MUIComboBox){
            DBHelper.sharedInstance().addObserverForEntity(avc, 'Tax', null, null, null, function (objects) {
                comboBox.removeAllItems();
                let selectedIndex = -1;
                for (let i = 0; i < objects.length; i++) {
                    let t = objects[i] as Tax;
                    comboBox.addItem(t.name, i);
                    if (tax == t) selectedIndex = i;
                }
                if (selectedIndex > -1) comboBox.selectItem(selectedIndex); 
            });            
        });

        avc.addComboBoxWithConfigurationHandler( this, function(comboBox:MUIComboBox){
            DBHelper.sharedInstance().addObserverForEntity(avc, 'Warehouse', null, null, null, function (objects:Warehouse[]) {
                comboBox.removeAllItems();
                let selectedIndex = -1;
                for (let i = 0; i < objects.length; i++) {
                    let wh = objects[i];
                    comboBox.addItem(wh.name, i);
                    if (wh == warehouse) selectedIndex = i;
                }
                if (selectedIndex != null) comboBox.selectItem(selectedIndex);
            });
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.placeholderText = MIOLocalizeString("COMMENTS", "COMMENTS");
            textField.text = comments;
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("APPLY", "APPLY"), MUIAlertActionStyle.Default, this, function(this:NoteLineCell){
            
            let taxComboBox: MUIComboBox = avc.comboBoxes[0];
            let taxIndex = taxComboBox.getSelectedItem();
            let t = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, taxIndex, 'Tax') as Tax;
            
            let whComboBox: MUIComboBox = avc.comboBoxes[1];
            let whIndex = whComboBox.getSelectedItem();
            let wh = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, whIndex, 'Warehouse') as Warehouse;

            let c = avc.textFields[0].text;

            this.tax = t;            
            this.warehouse = wh;            
            this.warehouseName = wh?.name;
            this.comments = c;

            this.updateStockLine();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        avc.addCompletionHandler(this, function () {
            // Release the observers            
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, 'Tax');
        });

        AppHelper.sharedInstance().presentViewController(avc, true);

    }

    private searchTimer:MIOTimer = null;
    private searchString:string = null;
    protected setupProductTextField(textField:MUITextField, target, completion){
        if (textField == null) return;        
        textField.setOnChangeText(this, function(control, value:string){
            this.searchString = value;
            if (this.searchTimer != null) this.searchTimer.invalidate();
            this.searchTimer = MIOTimer.scheduledTimerWithTimeInterval(500, false, this, function(timer:MIOTimer){
                this.searchProductTimerFired(textField, this.searchString, target, completion);
                this.searchTimer = null;
            });
        });
    }

    private searchProductTimerFired(textField:MUITextField, value:string, target, completion){
        let supplier:Supplier = null;
        if (this.stockNote.type == StockNoteType.SupplierOrder && (this.stockNote.destinationEntity instanceof Supplier) == true) {
            supplier = this.stockNote.destinationEntity as Supplier;
        }
        else if (this.stockNote.type == StockNoteType.SupplierNote && (this.stockNote.originEntity instanceof Supplier) == true) {
            supplier = this.stockNote.originEntity as Supplier;                
        }

        if (supplier != null && supplier.onlySupplierProducts == true){
            let context = {"Supplier": supplier, "Target": target, "Completion": completion};
            AppHelper.sharedInstance().showSelectSupplierProductViewControllerFromView(textField, value, this, this.supplierProductDidSelected, this, this.addProduct, context);
        }
        else {
            AppHelper.sharedInstance().showSelectStockProductViewControllerFromView(textField, value, target, completion, this, this.addProduct);
        }
        
    }

    private supplierProductDidSelected(controller:SelectEntityViewController, supplierProduct:SupplierProduct){
        let context = controller.context || {};
        let target = context["Target"];
        let completion = context["Completion"];
        if (target == null || completion == null) return;
        completion.call(target, controller, supplierProduct.product, supplierProduct);
    }

    private addProduct(value:string){
        AppHelper.sharedInstance().showAddStockProductAlertViewController(value, null, this, function(product:Product){
            if (product != null) this.productDidSelect(null, product);
        });
    }
    
    protected setupInputFormatDropdown(button:MUIButton){
        if (button == null) return;
        button.setAction(this, function(){
            AppHelper.sharedInstance().showSelectInputFormatViewControllerFromView(button, this.product, this.productMeasureType, this.productContainerMeasureType, this.productContainerQuantity, this, this.inputFormatDidSelect);
        });
    }

    protected inputFormatDidSelect(controller:SelectInputFormatViewController, inputFormat:StockInputFormat, inputType:MeasureUnitType){
        this.inputFormat = inputFormat;
        this.inputType = inputType;

        [this.productQuantity, this.productNetPriceAmount] = DBHelper.calculateQuantityFromStockLine(this.quantity, this.priceAmount, this.inputFormat, this.inputType, this.productMeasureType, this.productContainerMeasureType, this.productContainerQuantity);

        [this.priceAmount, this.productNetPriceAmount, this.discountAmount, this.baseAmount, this.productGrossPriceAmount] = StockNoteLine.calculateValues(this.quantity, this.priceAmount, this.productQuantity, this.productNetPriceAmount, this.discountFormat, this.baseAmount, this.lockStatus);

        [this.minMeasureQuantity, this.minMeasureUnitType] = MeasureUnits.convertToMinimumUnits(this.productQuantity, this.productMeasureType, this.productContainerMeasureType, this.productContainerQuantity);

        this.updateStockLine();
        if (this.delegate != null && typeof this.delegate.totalDidChange === "function") this.delegate.totalDidChange();

        // UI
        if (this.inputFormatDropdown) this.inputFormatDropdown.title = this.inputFormatString();
        if (this.inputFormatLabel) this.inputFormatLabel.text = this.inputFormatString();

        if (this.inputFormatProductLabel != null) this.inputFormatProductLabel.text = this.inputFormatProductString();

        if (this.productQuantityLabel) this.productQuantityLabel.text = this.productQuantityString();
        if (this.priceTextField) this.priceTextField.text = this.cf.stringFromNumber(this.priceAmount);
        if (this.productPriceLabel) this.productPriceLabel.text = this.productNetPriceString();
        if (this.productGrossPriceLabel) this.productGrossPriceLabel.text = this.productGrossPriceString();
        if (this.totalTextField) this.totalTextField.text = this.basePriceString();
        if (this.totalLabel) this.totalLabel.text = this.basePriceString();

    }

    protected setupTextField(textField:MUITextField, completion:any){
        if (textField == null) return;

        textField.formatter = this.pnf;
        textField.setOnChangeText(this, function(textfield:MUITextField, value:string){              
            let v:any = value.length > 0 ? value : null;
            if (textField.formatter != null) {
                v = textField.formatter.getObjectValueForString(v);
            }
            completion(v, value);
        });

        textField.setOnBeginEditing(this, function(control:MUITextField, value:string){
            control.selectAll(null);
        });

        textField.setOnEnterPress(this, function(control:MUITextField){
            control.resignFirstResponder();
            this.enterDidPress()
        });
    }

    protected setupQuantityTextField(textField:MUITextField){
        if (textField == null) return;
        textField.formatter = this.nf; 
        this.setupTextField(textField, (value:any) => { this.quantityDidChange(value); } );
    }

    protected quantityDidChange(quantity:number){
        this.quantity = quantity;
        [this.productQuantity, this.productNetPriceAmount] = DBHelper.calculateQuantityFromStockLine(this.quantity, this.priceAmount, this.inputFormat, this.inputType, this.productMeasureType, this.productContainerMeasureType, this.productContainerQuantity);

        [this.priceAmount, this.productNetPriceAmount, this.discountAmount, this.baseAmount, this.productGrossPriceAmount] = StockNoteLine.calculateValues(this.quantity, this.priceAmount, this.productQuantity, this.productNetPriceAmount, this.discountFormat, this.baseAmount, this.lockStatus);
        
        [this.minMeasureQuantity, this.minMeasureUnitType] = MeasureUnits.convertToMinimumUnits(this.productQuantity, this.productMeasureType, this.productContainerMeasureType, this.productContainerQuantity);
        this.updateStockLine();
        if (this.delegate != null && typeof this.delegate.totalDidChange === "function") this.delegate.totalDidChange();

        // UI
        if (this.productQuantityLabel) this.productQuantityLabel.text = this.productQuantityString();
        if (this.priceTextField) this.priceTextField.text = this.cf.stringFromNumber(this.priceAmount);
        if (this.productPriceTextField) this.productPriceTextField.text = this.productNetPriceString();
        if (this.productGrossPriceLabel) this.productGrossPriceLabel.text = this.productGrossPriceString();
        if (this.discountFormatTextField) this.discountFormatTextField.text = this.discountFormat ?? this.discountAmount;
        if (this.discountAmountLabel) this.discountAmountLabel.text = this.cf.stringFromNumber(this.discountAmount);
        if (this.totalTextField) this.totalTextField.text = this.basePriceString();
        if (this.totalLabel) this.totalLabel.text = this.basePriceString();
    }

    protected setupPriceTextField(textField:MUITextField){
        if (textField == null) return;
        textField.formatter = this.cf;
        this.setupTextField(textField, (value:any) => { this.priceDidChange(value); } );
    }
    
    protected priceDidChange(price:number){        
        this.lockStatus &= ~LockedStatus.price;
        this.lockStatus &= ~LockedStatus.productPrice;
        if (price != null) {
            this.priceAmount = price;
            this.lockStatus |= LockedStatus.price;
            [this.priceAmount, this.productNetPriceAmount, this.discountAmount, this.baseAmount, this.productGrossPriceAmount] = StockNoteLine.calculateValues(this.quantity, this.priceAmount, this.productQuantity, this.productNetPriceAmount, this.discountFormat, this.baseAmount, this.lockStatus);            
        }        
        else {
            this.priceAmount = 0;
            this.lockStatus |= LockedStatus.price;
            [this.priceAmount, this.productNetPriceAmount, this.discountAmount, this.baseAmount, this.productGrossPriceAmount] = StockNoteLine.calculateValues(this.quantity, this.priceAmount, this.productQuantity, this.productNetPriceAmount, this.discountFormat, this.baseAmount, this.lockStatus);            
        }
        this.updateStockLine();
        if (this.delegate != null && typeof this.delegate.totalDidChange === "function") this.delegate.totalDidChange();

        // UI
        if (this.productPriceTextField) this.productPriceTextField.text = this.productNetPriceString();
        if (this.productPriceLabel != null) this.productPriceLabel.text = this.productNetPriceString();
        if (this.productGrossPriceLabel) this.productGrossPriceLabel.text = this.productGrossPriceString();
        if (this.discountFormatTextField) this.discountFormatTextField.text = this.discountFormat ?? this.discountAmount;
        if (this.discountAmountLabel) this.discountAmountLabel.text = this.cf.stringFromNumber(this.discountAmount);
        if (this.totalTextField) this.totalTextField.text = this.basePriceString();
        if (this.totalLabel) this.totalLabel.text = this.basePriceString();
    }

    protected setupProductPriceTextField(textField:MUITextField){
        if (textField == null) return;
        textField.formatter = this.cf;
        this.setupTextField(textField, (value:any) => { this.productPriceDidChange(value); } );
    }
    
    protected productPriceDidChange(price:number){        
        this.lockStatus &= ~LockedStatus.price;
        this.lockStatus &= ~LockedStatus.productPrice;
        if (price != null) {
            this.productNetPriceAmount = price;
            this.lockStatus |= LockedStatus.productPrice;
            [this.priceAmount, this.productNetPriceAmount, this.discountAmount, this.baseAmount, this.productGrossPriceAmount] = StockNoteLine.calculateValues(this.quantity, this.priceAmount, this.productQuantity, this.productNetPriceAmount, this.discountFormat, this.baseAmount, this.lockStatus);
        }
        else {
            this.productNetPriceAmount = 0;            
            [this.priceAmount, this.productNetPriceAmount, this.discountAmount, this.baseAmount, this.productGrossPriceAmount] = StockNoteLine.calculateValues(this.quantity, this.priceAmount, this.productQuantity, this.productNetPriceAmount, this.discountFormat, this.baseAmount, this.lockStatus);
        }
        this.updateStockLine();
        if (this.delegate != null && typeof this.delegate.totalDidChange === "function") this.delegate.totalDidChange();

        // UI
        if (this.priceTextField) this.priceTextField.text = this.cf.stringFromNumber(this.priceAmount);        
        if (this.productGrossPriceLabel) this.productGrossPriceLabel.text = this.productGrossPriceString();
        if (this.discountFormatTextField) this.discountFormatTextField.text = this.discountFormat ?? this.discountAmount;
        if (this.discountAmountLabel) this.discountAmountLabel.text = this.cf.stringFromNumber(this.discountAmount);
        if (this.totalTextField) this.totalTextField.text = this.basePriceString();
    }

    protected setupDiscountTextField(textField:MUITextField){
        if (textField == null) return;
        textField.formatter = this.pnf;
        this.setupTextField(textField, (value:any, originalValue:string) => { this.discountDidChange(value, originalValue); } );
    }

    protected discountDidChange(discount:number, discountFormat:string){
        this.discountFormat = discountFormat;
        this.discountAmount = 0;        
        [this.priceAmount, this.productNetPriceAmount, this.discountAmount, this.baseAmount, this.productGrossPriceAmount] = StockNoteLine.calculateValues(this.quantity, this.priceAmount, this.productQuantity, this.productNetPriceAmount, this.discountFormat, this.baseAmount, this.lockStatus);        
        this.updateStockLine();
        if (this.delegate != null && typeof this.delegate.totalDidChange === "function") this.delegate.totalDidChange();

        // UI
        if (this.productGrossPriceLabel) this.productGrossPriceLabel.text = this.cf.stringFromNumber(this.productGrossPriceAmount);
        if (this.priceTextField) this.priceTextField.text = this.cf.stringFromNumber(this.priceAmount);
        if (this.productGrossPriceLabel) this.productGrossPriceLabel.text = this.productGrossPriceString();
        if (this.discountAmountLabel) this.discountAmountLabel.text = this.cf.stringFromNumber(this.discountAmount);
        if (this.totalTextField) this.totalTextField.text = this.basePriceString();
        if (this.totalLabel) this.totalLabel.text = this.basePriceString();
    }

    protected setupTotalTextField(textField:MUITextField){
        if (textField == null) return;
        textField.formatter = this.cf;
        this.setupTextField(textField, (value:any) => { this.totalDidChange(value); } );
    }

    protected totalDidChange(total:number){        
        this.lockStatus &= ~LockedStatus.total;
        if (total != null) {
            this.baseAmount = total;
            this.lockStatus |= LockedStatus.total;
            [this.priceAmount, this.productNetPriceAmount, this.discountAmount, this.baseAmount, this.productGrossPriceAmount] = StockNoteLine.calculateValues(this.quantity, this.priceAmount, this.productQuantity, this.productNetPriceAmount, this.discountFormat, this.baseAmount, this.lockStatus);            
        }  
        else {
            this.baseAmount = 0;            
            [this.priceAmount, this.productNetPriceAmount, this.discountAmount, this.baseAmount, this.productGrossPriceAmount] = StockNoteLine.calculateValues(this.quantity, this.priceAmount, this.productQuantity, this.productNetPriceAmount, this.discountFormat, this.baseAmount, this.lockStatus);            
        }      
        this.updateStockLine();
        if (this.delegate != null && typeof this.delegate.totalDidChange === "function") this.delegate.totalDidChange();

        // UI
        if (this.priceTextField) this.priceTextField.text = this.cf.stringFromNumber(this.priceAmount);
        if (this.productPriceLabel != null) this.productPriceLabel.text = this.productNetPriceString();
        if (this.productGrossPriceLabel) this.productGrossPriceLabel.text = this.productGrossPriceString();
        if (this.discountFormatTextField) this.discountFormatTextField.text = this.discountFormat ?? this.discountAmount;
        if (this.discountAmountLabel) this.discountAmountLabel.text = this.cf.stringFromNumber(this.discountAmount);        
    }

    protected enterDidPress(){
    }

    protected lineDidInserted(line:StockNoteLine){
        if (this.delegate != null && typeof this.delegate.lineDidInserted === "function"){
            this.delegate.lineDidInserted(this, line);
        }
    }

    protected inputFormatProductString():string{
        if (this.inputFormat != null) {
            return this.product.productMeasureString + " x " + this.inputFormat.quantity;
        } 
        else{
            return this.product.productMeasureString;
        }
    }

    protected inputFormatString():string{
        return this.product?.inputFormatDescription(this.inputType, this.inputFormat);
    }

    protected productQuantityString(){
        if (this.productMeasureType == MeasureUnitType.Container){
            return "(" + this.nf.stringFromNumber(this.productQuantity * this.productContainerQuantity) + " " + MeasureUnits.toString(this.productContainerMeasureType) + ") <strong>" + this.nf.stringFromNumber(this.productQuantity) + "</strong>";
        }
        else {
            return "<strong>" + this.nf.stringFromNumber(this.productQuantity) + "</strong>";
        }
    }

    protected productNetPriceString(){
        return this.cf.stringFromNumber(this.productNetPriceAmount);
    }

    protected productGrossPriceString(){
        return this.cf.stringFromNumber(this.productGrossPriceAmount);
    }

    protected basePriceString(){        
        return this.cf.stringFromNumber(this.baseAmount);
    }

    protected totalPriceString(){        
        return this.cf.stringFromNumber(this.totalAmount);
    }

    protected warehouseNameString(){        
        let name = this.warehouse != null ? this.warehouseName : MIOLocalizeString("DEFFAULT WAREHOUSE", "DEFFAULT WAREHOUSE");
        return name;        
    }

    protected conceptSubtextString() : string {
        if (this.stockNoteLine == null) return null;
        return this.stockNoteLine.stockTaxString() + " - " + this.warehouseNameString() + (this.stockNoteLine.comments ? " - " + this.stockNoteLine.comments : "");
    }

    //
    // Lines Updates
    //

    updateStockLine(){
        if (this.stockNoteLine == null) return;
        
        this.stockNoteLine.tax = this.tax;
        this.stockNoteLine.taxName = this.tax ? this.tax.name : null;
        this.stockNoteLine.taxQuantity = this.tax ? this.tax.taxQuantity : 0;

        this.stockNoteLine.warehouse = this.warehouse;
        this.stockNoteLine.warehouseName = this.warehouseName;
        
        this.stockNoteLine.inputFormat = this.inputFormat;    // Caja 24 U
        this.stockNoteLine.inputFormatQuantity = this.inputFormat?.quantity; // 24
                
        this.stockNoteLine.quantity = this.quantity;  // 12 caja de 24 u
        this.stockNoteLine.quantityMeasureType = this.inputType;  // Unidades -2
        this.stockNoteLine.price = this.priceAmount;
                        
        this.stockNoteLine.discountString = this.discountFormat;
        this.stockNoteLine.discountAmount = this.discountAmount;
        
        this.stockNoteLine.baseAmount = this.baseAmount;
        this.stockNoteLine.taxAmount = this.baseAmount * this.stockNoteLine.taxQuantity;        
        this.stockNoteLine.totalAmount = this.baseAmount + this.stockNoteLine.taxAmount;

        this.stockNoteLine.productQuantity = this.productQuantity;
        this.stockNoteLine.productPrice = this.productNetPriceAmount;        

        this.stockNoteLine.minMeasureQuantity = this.minMeasureQuantity;
        this.stockNoteLine.minMeasureType = this.minMeasureUnitType;  
        this.stockNoteLine.comments = this.comments;

        if (this.delegate != null && typeof this.delegate.lineDidUpdate === "function") this.delegate.lineDidUpdate( this.stockNoteLine );
    }

    //
    // Delegate 
    //

    // private totalWillChange(total:number){
    //     if (this.delegate == null || typeof this.delegate.baseAmountWillChange !== "function") return;
    //     this.delegate.baseAmountWillChange(total);
    // }

    // private totalDidChange(total:number){
    //     if (this.delegate == null || typeof this.delegate.baseAmountDidChange !== "function") return;
    //     this.delegate.baseAmountDidChange(total);
    // }

    // private taxWillChange(tax:Tax){
    //     if (this.delegate == null || typeof this.delegate.taxWillChange !== "function") return;
    //     this.delegate.taxWillChange(tax);
    // }

    // private taxDidChange(tax:Tax){
    //     if (this.delegate == null || typeof this.delegate.taxDidChange !== "function") return;
    //     this.delegate.taxDidChange(tax);
    // }

    // private updateTotals() {
    //     this.totalWillChange(this.totalPrice);

    //     let factor = 1;        
    //     this.productPrice = this.inputFormat == null ? this.price :  this.price / this.inputFormat.quantity;        
    //     [this.productQuantity, this.measureQuantity, factor] = DBHelper.calculateQuantityFromStockLine(this.quantity, this.inputFormat, this.inputType, this.productMeasureType, this.productContainerMeasureType, this.productContainerQuantity);
    //     if (factor != 1) this.productPrice = this.productPrice * factor;
    //     [this.totalPrice, this.discountValue] = DBHelper.calculateTotalFromStockLine(this.quantity, this.price, this.discount);

    //     this.updateStockLine();

    //     this.totalDidChange(this.totalPrice);
    // }

    //
    // FIRST RESPONDER
    //
    becomeFirstResponder() {
        if(this.productTextField != null) this.productTextField.becomeFirstResponder();
    }
}
