class DistributorListViewController extends EntityListViewController
{
    public static newInstance() : DistributorListViewController 
    {
        let vc = new DistributorListViewController("distributor-list-view");
        vc.initWithResource("layout/distributors/DistributorListView.html");

        return vc;
    } 

    private addButton:MUIButton = null;     

    viewDidLoad()
    {
        super.viewDidLoad();
        
        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function (this:DistributorListViewController){
            this.showAddDistributorAlert();
        });

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();
       
        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }
    
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath)
    {
        let cell = tableview.dequeueReusableCellWithIdentifier("DistributorListCell") as DistributorListCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Distributor;

        cell.item = item;
        cell.selected = (this.selectedItem?.identifier == item.identifier);

        return cell;
    }
            
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath)
    {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Distributor;
        this.selectedItem = item;
        let vc = DistributorDetailViewController.newInstance();
        vc.distributor = item;
        this.splitViewController.showDetailViewController(vc);
    }
    
    set fetchedResultsController(value)
    {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController()
    {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        const ad = MUIWebApplication.sharedInstance().delegate;    
        const sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('name', false)];
    
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Distributor", sortDescriptors, null);
        fetchRequest.relationshipKeyPathsForPrefetching = ["address", "phone", "mobilePhone"];
        
        const fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }   

    private showAddDistributorAlert()
    {
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ADD NEW DISTRIBUTOR","ADD NEW DISTRIBUTOR"), MIOLocalizeString("TYPE THE NAME","TYPE THE NAME"), MUIAlertViewStyle.Default);
        
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK","OK"), MUIAlertActionStyle.Default, this, function(this:DistributorListViewController){
            let name = avc.textFields[0].text;
            this.addDistributor(name);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL","CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private addDistributor(name:string)
    {
        if (name.length == 0) {
            AppHelper.showErrorMessage(this, MIOLocalizeString("ERROR CREATING DISTRIBUTOR","ERROR CREATING DISTRIBUTOR"), MIOLocalizeString("THE NAME IS INVALID. TRY ANTOHER NAME.","THE NAME IS INVALID. TRY ANTOHER NAME."));
            return;
        }

        this.selectedItem = DBHelper.createDistributor(name);
        DBHelper.saveMainContext();
    }
 
}