

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class StockNoteLine_ManagedObject

class StockNoteLine_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: baseAmount
    set baseAmount(value:number) {
        this.setValueForKey(value, 'baseAmount');
    }
    get baseAmount():number {
        return this.valueForKey('baseAmount');
    }
    set baseAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'baseAmount');
    }
    get baseAmountPrimitiveValue():number {
        return this.primitiveValueForKey('baseAmount');
    }

    // Property: comments
    set comments(value:string) {
        this.setValueForKey(value, 'comments');
    }
    get comments():string {
        return this.valueForKey('comments');
    }
    set commentsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'comments');
    }
    get commentsPrimitiveValue():string {
        return this.primitiveValueForKey('comments');
    }

    // Property: discountAmount
    set discountAmount(value:number) {
        this.setValueForKey(value, 'discountAmount');
    }
    get discountAmount():number {
        return this.valueForKey('discountAmount');
    }
    set discountAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'discountAmount');
    }
    get discountAmountPrimitiveValue():number {
        return this.primitiveValueForKey('discountAmount');
    }

    // Property: discountString
    set discountString(value:string) {
        this.setValueForKey(value, 'discountString');
    }
    get discountString():string {
        return this.valueForKey('discountString');
    }
    set discountStringPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'discountString');
    }
    get discountStringPrimitiveValue():string {
        return this.primitiveValueForKey('discountString');
    }

    // Property: estimatedPrice
    set estimatedPrice(value:number) {
        this.setValueForKey(value, 'estimatedPrice');
    }
    get estimatedPrice():number {
        return this.valueForKey('estimatedPrice');
    }
    set estimatedPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'estimatedPrice');
    }
    get estimatedPricePrimitiveValue():number {
        return this.primitiveValueForKey('estimatedPrice');
    }

    // Property: estimatedProductPrice
    set estimatedProductPrice(value:number) {
        this.setValueForKey(value, 'estimatedProductPrice');
    }
    get estimatedProductPrice():number {
        return this.valueForKey('estimatedProductPrice');
    }
    set estimatedProductPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'estimatedProductPrice');
    }
    get estimatedProductPricePrimitiveValue():number {
        return this.primitiveValueForKey('estimatedProductPrice');
    }

    // Property: estimatedProductQuantity
    set estimatedProductQuantity(value:number) {
        this.setValueForKey(value, 'estimatedProductQuantity');
    }
    get estimatedProductQuantity():number {
        return this.valueForKey('estimatedProductQuantity');
    }
    set estimatedProductQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'estimatedProductQuantity');
    }
    get estimatedProductQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('estimatedProductQuantity');
    }

    // Property: estimatedQuantity
    set estimatedQuantity(value:number) {
        this.setValueForKey(value, 'estimatedQuantity');
    }
    get estimatedQuantity():number {
        return this.valueForKey('estimatedQuantity');
    }
    set estimatedQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'estimatedQuantity');
    }
    get estimatedQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('estimatedQuantity');
    }

    // Property: inputFormatName
    set inputFormatName(value:string) {
        this.setValueForKey(value, 'inputFormatName');
    }
    get inputFormatName():string {
        return this.valueForKey('inputFormatName');
    }
    set inputFormatNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'inputFormatName');
    }
    get inputFormatNamePrimitiveValue():string {
        return this.primitiveValueForKey('inputFormatName');
    }

    // Property: inputFormatQuantity
    set inputFormatQuantity(value:number) {
        this.setValueForKey(value, 'inputFormatQuantity');
    }
    get inputFormatQuantity():number {
        return this.valueForKey('inputFormatQuantity');
    }
    set inputFormatQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'inputFormatQuantity');
    }
    get inputFormatQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('inputFormatQuantity');
    }

    // Property: legalEntityName
    set legalEntityName(value:string) {
        this.setValueForKey(value, 'legalEntityName');
    }
    get legalEntityName():string {
        return this.valueForKey('legalEntityName');
    }
    set legalEntityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalEntityName');
    }
    get legalEntityNamePrimitiveValue():string {
        return this.primitiveValueForKey('legalEntityName');
    }

    // Property: measurePrice
    set measurePrice(value:number) {
        this.setValueForKey(value, 'measurePrice');
    }
    get measurePrice():number {
        return this.valueForKey('measurePrice');
    }
    set measurePricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'measurePrice');
    }
    get measurePricePrimitiveValue():number {
        return this.primitiveValueForKey('measurePrice');
    }

    // Property: minMeasureQuantity
    set minMeasureQuantity(value:number) {
        this.setValueForKey(value, 'minMeasureQuantity');
    }
    get minMeasureQuantity():number {
        return this.valueForKey('minMeasureQuantity');
    }
    set minMeasureQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minMeasureQuantity');
    }
    get minMeasureQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('minMeasureQuantity');
    }

    // Property: minMeasureType
    set minMeasureType(value:number) {
        this.setValueForKey(value, 'minMeasureType');
    }
    get minMeasureType():number {
        return this.valueForKey('minMeasureType');
    }
    set minMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'minMeasureType');
    }
    get minMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('minMeasureType');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: productContainerMeasureType
    set productContainerMeasureType(value:number) {
        this.setValueForKey(value, 'productContainerMeasureType');
    }
    get productContainerMeasureType():number {
        return this.valueForKey('productContainerMeasureType');
    }
    set productContainerMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productContainerMeasureType');
    }
    get productContainerMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('productContainerMeasureType');
    }

    // Property: productContainerQuantity
    set productContainerQuantity(value:number) {
        this.setValueForKey(value, 'productContainerQuantity');
    }
    get productContainerQuantity():number {
        return this.valueForKey('productContainerQuantity');
    }
    set productContainerQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productContainerQuantity');
    }
    get productContainerQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('productContainerQuantity');
    }

    // Property: productMeasureType
    set productMeasureType(value:number) {
        this.setValueForKey(value, 'productMeasureType');
    }
    get productMeasureType():number {
        return this.valueForKey('productMeasureType');
    }
    set productMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productMeasureType');
    }
    get productMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('productMeasureType');
    }

    // Property: productName
    set productName(value:string) {
        this.setValueForKey(value, 'productName');
    }
    get productName():string {
        return this.valueForKey('productName');
    }
    set productNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productName');
    }
    get productNamePrimitiveValue():string {
        return this.primitiveValueForKey('productName');
    }

    // Property: productPrice
    set productPrice(value:number) {
        this.setValueForKey(value, 'productPrice');
    }
    get productPrice():number {
        return this.valueForKey('productPrice');
    }
    set productPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productPrice');
    }
    get productPricePrimitiveValue():number {
        return this.primitiveValueForKey('productPrice');
    }

    // Property: productQuantity
    set productQuantity(value:number) {
        this.setValueForKey(value, 'productQuantity');
    }
    get productQuantity():number {
        return this.valueForKey('productQuantity');
    }
    set productQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'productQuantity');
    }
    get productQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('productQuantity');
    }

    // Property: productReference
    set productReference(value:string) {
        this.setValueForKey(value, 'productReference');
    }
    get productReference():string {
        return this.valueForKey('productReference');
    }
    set productReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productReference');
    }
    get productReferencePrimitiveValue():string {
        return this.primitiveValueForKey('productReference');
    }

    // Property: quantity
    set quantity(value:number) {
        this.setValueForKey(value, 'quantity');
    }
    get quantity():number {
        return this.valueForKey('quantity');
    }
    set quantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'quantity');
    }
    get quantityPrimitiveValue():number {
        return this.primitiveValueForKey('quantity');
    }

    // Property: quantityMeasureType
    set quantityMeasureType(value:number) {
        this.setValueForKey(value, 'quantityMeasureType');
    }
    get quantityMeasureType():number {
        return this.valueForKey('quantityMeasureType');
    }
    set quantityMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'quantityMeasureType');
    }
    get quantityMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('quantityMeasureType');
    }

    // Property: taxAmount
    set taxAmount(value:number) {
        this.setValueForKey(value, 'taxAmount');
    }
    get taxAmount():number {
        return this.valueForKey('taxAmount');
    }
    set taxAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'taxAmount');
    }
    get taxAmountPrimitiveValue():number {
        return this.primitiveValueForKey('taxAmount');
    }

    // Property: taxName
    set taxName(value:string) {
        this.setValueForKey(value, 'taxName');
    }
    get taxName():string {
        return this.valueForKey('taxName');
    }
    set taxNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'taxName');
    }
    get taxNamePrimitiveValue():string {
        return this.primitiveValueForKey('taxName');
    }

    // Property: taxQuantity
    set taxQuantity(value:number) {
        this.setValueForKey(value, 'taxQuantity');
    }
    get taxQuantity():number {
        return this.valueForKey('taxQuantity');
    }
    set taxQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'taxQuantity');
    }
    get taxQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('taxQuantity');
    }

    // Property: totalAmount
    set totalAmount(value:number) {
        this.setValueForKey(value, 'totalAmount');
    }
    get totalAmount():number {
        return this.valueForKey('totalAmount');
    }
    set totalAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalAmount');
    }
    get totalAmountPrimitiveValue():number {
        return this.primitiveValueForKey('totalAmount');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: warehouseName
    set warehouseName(value:string) {
        this.setValueForKey(value, 'warehouseName');
    }
    get warehouseName():string {
        return this.valueForKey('warehouseName');
    }
    set warehouseNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'warehouseName');
    }
    get warehouseNamePrimitiveValue():string {
        return this.primitiveValueForKey('warehouseName');
    }
    // Relationship: inputFormat
    set inputFormat(value:StockInputFormat) {
        this.setValueForKey(value, 'inputFormat');
    }
    get inputFormat():StockInputFormat {
        return this.valueForKey('inputFormat') as StockInputFormat;
    }
    // Relationship: legalEntity
    set legalEntity(value:LegalEntity) {
        this.setValueForKey(value, 'legalEntity');
    }
    get legalEntity():LegalEntity {
        return this.valueForKey('legalEntity') as LegalEntity;
    }

    // Relationship: lines
    protected _lines:MIOManagedObjectSet = null;
    get lines():MIOManagedObjectSet {
        return this.valueForKey('lines');
    }
    addLinesObject(value:StockNoteLine) {
        this._addObjectForKey(value, 'lines');
    }
    removeLinesObject(value:StockNoteLine) {
        this._removeObjectForKey(value, 'lines');
    }
    // Relationship: note
    set note(value:StockNote) {
        this.setValueForKey(value, 'note');
    }
    get note():StockNote {
        return this.valueForKey('note') as StockNote;
    }
    // Relationship: parent
    set parent(value:StockNoteLine) {
        this.setValueForKey(value, 'parent');
    }
    get parent():StockNoteLine {
        return this.valueForKey('parent') as StockNoteLine;
    }
    // Relationship: tax
    set tax(value:Tax) {
        this.setValueForKey(value, 'tax');
    }
    get tax():Tax {
        return this.valueForKey('tax') as Tax;
    }
    // Relationship: warehouse
    set warehouse(value:Warehouse) {
        this.setValueForKey(value, 'warehouse');
    }
    get warehouse():Warehouse {
        return this.valueForKey('warehouse') as Warehouse;
    }
}
