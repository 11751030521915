//
// Generated class TimeRangeGroup
//

/// <reference path="TimeRangeGroup_ManagedObject.ts" />

enum TimeRangeGroupType {
    TakeAway = 0,
    Delivery = 1,
    AtVenue = 3
};

class TimeRangeGroup extends TimeRangeGroup_ManagedObject
{

}
