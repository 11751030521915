

/// <reference path="TicketLine.ts" />

// Generated class MenuLine_ManagedObject

class MenuLine_ManagedObject extends TicketLine
{
    // Relationship: menu
    set menu(value:Menu) {
        this.setValueForKey(value, 'menu');
    }
    get menu():Menu {
        return this.valueForKey('menu') as Menu;
    }

    // Relationship: menuProductLines
    protected _menuProductLines:MIOManagedObjectSet = null;
    get menuProductLines():MIOManagedObjectSet {
        return this.valueForKey('menuProductLines');
    }
    addMenuProductLinesObject(value:MenuProductLine) {
        this._addObjectForKey(value, 'menuProductLines');
    }
    removeMenuProductLinesObject(value:MenuProductLine) {
        this._removeObjectForKey(value, 'menuProductLines');
    }

    // Relationship: separatedMenuLines
    protected _separatedMenuLines:MIOManagedObjectSet = null;
    get separatedMenuLines():MIOManagedObjectSet {
        return this.valueForKey('separatedMenuLines');
    }
    addSeparatedMenuLinesObject(value:SeparatedMenuLine) {
        this._addObjectForKey(value, 'separatedMenuLines');
    }
    removeSeparatedMenuLinesObject(value:SeparatedMenuLine) {
        this._removeObjectForKey(value, 'separatedMenuLines');
    }
}
