

/// <reference path="WorkerDetailViewController.ts" />
/// <reference path="AddNewWorkerViewController.ts" />

class WorkerListViewController extends BaseTableViewController
{    
    private addButton:MUIButton = null;
    
    private wdvc:MUINavigationController = null;    

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function (){            
            let vc = new AddNewWorkerViewController('add-new-worker-view');
            vc.initWithResource('layout/workers/AddNewWorkerView.html');
            this.presentViewController(vc, true);
        });

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.tableView = MUIOutlet(this,"table-view","UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;                

        this.tableView.reloadData();
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("WorkerCell") as WorkerCell;        

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Employee;
        cell.item = item;        

        return cell;
    }

    private worker:Employee = null;
    didSelectCellAtIndexPath(tableView,indexPath:MIOIndexPath){
        let worker:Employee = this.fetchedResultsController.objectAtIndexPath(indexPath);

        if(!this.wdvc) {

            let wdvc = new WorkerDetailViewController("worker-detail-view");
            wdvc.initWithResource("layout/workers/WorkerDetailView.html");

            this.wdvc = new MUINavigationController("worker_detail_nav_controller");
            this.wdvc.initWithRootViewController(wdvc);
        }

        this.splitViewController.showDetailViewController(this.wdvc);
        this.wdvc.rootViewController.workerID = worker.identifier;
        this.worker = worker;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let sds = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

        let predicateFormat:string = null;
        if (this.searchString != null)
            predicateFormat = "name CONTAINS '" + this.searchString + "'";
            
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Employee", sds, predicateFormat);

        let frc = new MIOFetchedResultsController();
        frc.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        frc.delegate = this;

        this._fetchedResultsController = frc;
        this._fetchedResultsController.performFetch();
        
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller){
        this.tableView.reloadData(); 
	}
}
