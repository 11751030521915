
/// <reference path="../external/Timezones.ts" />


class PlaceSettingTimezoneCell extends MUITableViewCell
{
    private timezoneComboBox:MUIComboBox = null;
    
    private _separator = null;
    private _item = null;
    awakeFromHTML()
    {
        this.timezoneComboBox = MUIOutlet(this, 'psv_timezone_cb', 'MUIComboBox');
        this.timezoneComboBox.setOnChangeAction(this,function(combobox, value)
        {
            this._item.setValueForKey('timezone',value == null ? null : parseInt(value));
        });
        this._separator = MUIOutlet(this, 'psv_timezone_separator', 'MUIView');
    }

    set item(i)
    {
        this._item = i;
        this.timezoneComboBox.removeAllItems();
        if(this._item != null && this._item.timezone == null){
            this.timezoneComboBox.addItem(MIOLocalizeString('SELECT','Select...'), null);
        }
        timezones.forEach(element => {
            this.timezoneComboBox.addItem(element.text, element.offset);
        });
        if(this._item != null) 
            this.timezoneComboBox.selectItem(this._item.timezone);
    }
}
