

class ReportSwitchFilterCell extends MUITableViewCell
{
    delegate = null;
    
    private _filter = null;

    private titleLabel = null;
    private switchButton = null;

    awakeFromHTML()
    {
        this.titleLabel = MUIOutlet(this, "rfv_c4_lbl", "MUILabel");
        this.switchButton = MUIOutlet(this, "rfv_c4_switch_btn", "MUISwitchButton");
        this.switchButton.setOnChangeValue(this, function(control, value){

            if (this.delegate != null)
                this.delegate.didChangeSwitchCellValue(this, value, this._filter);
        });
    }

    set filter(filter){

        this._filter = filter;
        this.titleLabel.text = filter["title"] + ":";
    }
}