//
// Generated class SupplierInvoice
//

/// <reference path="SupplierInvoice_ManagedObject.ts" />

class SupplierInvoice extends SupplierInvoice_ManagedObject
{

}
