class AddOnlineConfigurationCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel"); 

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private _item:Configuration = null;
    set item (item:Configuration){
        this._item = item;
        
        //set variables
        this.nameLabel.text = this._item.name;
    }

}