

/// <reference path="CheffLine.ts" />

// Generated class ProductLine_ManagedObject

class ProductLine_ManagedObject extends CheffLine
{

    // Property: existenceID
    set existenceID(value:string) {
        this.setValueForKey(value, 'existenceID');
    }
    get existenceID():string {
        return this.valueForKey('existenceID');
    }
    set existenceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'existenceID');
    }
    get existenceIDPrimitiveValue():string {
        return this.primitiveValueForKey('existenceID');
    }

    // Relationship: childProductLines
    protected _childProductLines:MIOManagedObjectSet = null;
    get childProductLines():MIOManagedObjectSet {
        return this.valueForKey('childProductLines');
    }
    addChildProductLinesObject(value:ProductLine) {
        this._addObjectForKey(value, 'childProductLines');
    }
    removeChildProductLinesObject(value:ProductLine) {
        this._removeObjectForKey(value, 'childProductLines');
    }
    // Relationship: parentProductLine
    set parentProductLine(value:ProductLine) {
        this.setValueForKey(value, 'parentProductLine');
    }
    get parentProductLine():ProductLine {
        return this.valueForKey('parentProductLine') as ProductLine;
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
}
