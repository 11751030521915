//
// Generated class AccountingType
//

/// <reference path="AccountingType_ManagedObject.ts" />

class AccountingType extends AccountingType_ManagedObject
{

}
