class DBLedgerAccountsCoder extends DBXLSXCoder
{
    mainEntity() {
        return { entity: "LedgerAccount", predicateFormat: "deletedAt == null" }
    }

    columns() : any[] {
        let cols = [
            { title:"ACCOUNT", width: "10%", align: "center" },
            { title:"TITLE", width: "10%", align: "center" }
        ]
        return cols;
    }

    protected importRow(row:any){
                
        let prefix = row["ACCOUNT"];
        let name = row["TITLE"];        

        if (prefix == null) { return; }

        // let tax = this.queryEntityByIDOrName("Tax", taxID, taxName) as Tax;
        // if (tax == null) {
        //     tax = MIOEntityDescription.insertNewObjectForEntityForName("Tax", DBHelper.mainManagedObjectContext) as Tax;
        //     tax.identifier = this.parseOrCreateIdentifier(taxID);
        //     tax.name = taxName;
        // }
            
        // tax.taxQuantity = taxValue;

        // this.appendObject(tax);
        // MIOLog("ADDING TAX: " + this.rowIndex + "/" + this.rows.length + ": " + taxName);
    }

    protected exportTitle() : string { return "LedgerAccount"; }

    protected exportSortDescriptors() { 
        return [MIOSortDescriptor.sortDescriptorWithKey("prefix", true), MIOSortDescriptor.sortDescriptorWithKey("name", true)]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let acc = object as LedgerAccount;

        let item = {
            "ACCOUNT" : acc.prefix,
            "TITLE"   : acc.name
        }

        return item;
    }
}