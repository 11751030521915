

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class FixedDiscount_ManagedObject

class FixedDiscount_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: discount
    set discount(value:number) {
        this.setValueForKey(value, 'discount');
    }
    get discount():number {
        return this.valueForKey('discount');
    }
    set discountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'discount');
    }
    get discountPrimitiveValue():number {
        return this.primitiveValueForKey('discount');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }
}
