//
// Generated class DeliveryOrder
//

/// <reference path="DeliveryOrder_ManagedObject.ts" />

class DeliveryOrder extends DeliveryOrder_ManagedObject
{

}
