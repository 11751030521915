//
// Generated class ProxyPaymentEntityPlatform
//

/// <reference path="ProxyPaymentEntityPlatform_ManagedObject.ts" />

class ProxyPaymentEntityPlatform extends ProxyPaymentEntityPlatform_ManagedObject
{

}
