/**
 * Created by godshadow on 21/07/16.
 */



    /// <reference path="BookingTransactionAddViewController.ts" />

class BookingTransactionsViewController extends MUIViewController
{
    backButton = null;
    titleLabel = null;
    addButton = null;
    tableView = null;

    booking = null;

    private _fetchedResultsController = null;

    viewDidLoad()
    {
        super.viewDidLoad();

        this.backButton = MUIOutlet(this,"btv_back_button", "MUIButton");
        this.backButton.setAction(this, function () {

            this.navigationController.popViewController();
        });

        this.titleLabel = MUIOutlet(this, "btv_title_label", 'MUILabel');
        this.titleLabel.text = MIOLocalizeString("TRANSACTIONS", "Transactions");

        this.addButton = MUIOutlet("btv_add_button", "MUIPopUpButton");
        this.addButton.addMenuItemWithTitle("Credit card register");
        this.addButton.addMenuItemWithTitle("Manual payment");
        this.addButton.addMenuItemWithTitle("Credit card payement");
        this.addButton.setMenuAction(this, function (control, index) {

            var vc = null;
            if (index == 0) {

                vc = new BookingTransactionAddViewController("booking_transaction_credit_view");
                vc.type = TransactionType.CreditCardRegister;
                vc.initWithResource("layout/BookingTransactionCreditView.html");
            }
            else if (index == 1){

                vc = new BookingTransactionAddViewController("booking_transaction_manual_view");
                vc.type = TransactionType.ManualPayment;
                vc.initWithResource("layout/BookingTransactionManualView.html");
            }
            else if (index == 2)
            {
                vc = new BookingTransactionAddViewController("booking_transaction_manual_view");
                vc.type = TransactionType.CreditCardPaymentStep1;
                vc.initWithResource("layout/BookingTransactionManualView.html");
            }

            vc.booking = this.booking;

            var navVC = new MUINavigationController("booking_transacion_add_nav_controller");
            navVC.initWithRootViewController(vc);

           // MUIWebApplication.sharedInstance().beginSheetViewController(navVC);
        });

        this.tableView = MUIOutlet(this, "btv_tableview", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        this.tableView.reloadData();
    }

    viewWillAppear()
    {
        super.viewWillAppear();

        if (this.booking.transactions == null)
        {
            var ws = MUIWebApplication.sharedInstance().delegate.webService;
            ws.downloadBookingTransactions(this.booking, this, function () {

                this.tableView.reloadData();
            });
        }
    }

    numberOfSections(tableview)
    {
        return 1;
    }

    numberOfRowsInSection(tableview, section)
    {
        if (this.booking.transactions == null)
            return 0;

        return this.booking.transactions.length;
    }

    cellAtIndexPath(tableview, row, section)
    {
        var cell = new MUITableViewCell();
        cell.init();

        var t = this.booking.transactions[row];

        var date = new Date(t.dateString);
        var day = MIODateGetStringForDay(date.getDay());
        var month = MIODateGetStringForMonth(date.getMonth());
        var ds = `${MIOLocalizeString(day, day)}, ${date.getDate()} ${MIOLocalizeString("OF", "of")} ${MIOLocalizeString(month, month)}`;

        ds += " (" + t.dateString.substring(t.dateString.length - 8) + ")";

        var dateLabel = new MUILabel("transaction_date_label");
        dateLabel.init();

        dateLabel.layer.style.top = "0px";
        dateLabel.layer.style.left = "10px";
        dateLabel.layer.style.right = "10px";
        dateLabel.layer.style.height = "12px";
        dateLabel.setFontSize(12);
        dateLabel.setTextRGBColor(163, 161, 168);
        dateLabel.text = ds;
        cell.addSubview(dateLabel);

        var messageLabel = new MUILabel("transaction_message_label");
        messageLabel.init();
        messageLabel.layer.style.top = "19px";
        messageLabel.layer.style.left = "10px";
        messageLabel.layer.style.right = "65px";
        messageLabel.layer.style.height = "22px";
        messageLabel.setFontSize(18);
        messageLabel.text = t.typeString();
        cell.addSubview(messageLabel);

        var userLabel = new MUILabel("transaction_amount_label");
        userLabel.init();
        userLabel.layer.style.left = "";
        userLabel.layer.style.top = "19px";
        userLabel.layer.style.width = "60px";
        userLabel.layer.style.right = "10px";
        userLabel.layer.style.height = "22px";
        userLabel.setFontSize(18);
        userLabel.setTextAlignment("right");
        userLabel.setTextRGBColor(163, 161, 168);
        if (t.type > 0)
            userLabel.text = t.amount;
        cell.addSubview(userLabel);

        return cell;
    }

    heightForRowAtIndexPath(tableview, index, section)
    {
        return 45;
    }

}
