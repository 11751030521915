//
// Generated class PresetProduct
//

/// <reference path="PresetProduct_ManagedObject.ts" />

class PresetProduct extends PresetProduct_ManagedObject
{

}
