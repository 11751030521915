//
// Generated class IntegratorConfigTags
//

/// <reference path="IntegratorConfigTags_ManagedObject.ts" />

class IntegratorConfigTags extends IntegratorConfigTags_ManagedObject
{

}
