

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class InvitationRulePreset_ManagedObject

class InvitationRulePreset_ManagedObject extends CoreEntityUpdatedBy
{
    // Relationship: economicEntity
    set economicEntity(value:EconomicEntity) {
        this.setValueForKey(value, 'economicEntity');
    }
    get economicEntity():EconomicEntity {
        return this.valueForKey('economicEntity') as EconomicEntity;
    }

    // Relationship: rules
    protected _rules:MIOManagedObjectSet = null;
    get rules():MIOManagedObjectSet {
        return this.valueForKey('rules');
    }
    addRulesObject(value:InvitationRule) {
        this._addObjectForKey(value, 'rules');
    }
    removeRulesObject(value:InvitationRule) {
        this._removeObjectForKey(value, 'rules');
    }
}
