//
// Generated class IntegratorLog
//

/// <reference path="IntegratorLog_ManagedObject.ts" />

class IntegratorLog extends IntegratorLog_ManagedObject
{

}
