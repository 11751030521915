

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class CategoryTag_ManagedObject

class CategoryTag_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: kitchenOrderPrintOption
    set kitchenOrderPrintOption(value:number) {
        this.setValueForKey(value, 'kitchenOrderPrintOption');
    }
    get kitchenOrderPrintOption():number {
        return this.valueForKey('kitchenOrderPrintOption');
    }
    set kitchenOrderPrintOptionPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'kitchenOrderPrintOption');
    }
    get kitchenOrderPrintOptionPrimitiveValue():number {
        return this.primitiveValueForKey('kitchenOrderPrintOption');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Relationship: categories
    protected _categories:MIOManagedObjectSet = null;
    get categories():MIOManagedObjectSet {
        return this.valueForKey('categories');
    }
    addCategoriesObject(value:ProductCategory) {
        this._addObjectForKey(value, 'categories');
    }
    removeCategoriesObject(value:ProductCategory) {
        this._removeObjectForKey(value, 'categories');
    }

    // Relationship: cheffLines
    protected _cheffLines:MIOManagedObjectSet = null;
    get cheffLines():MIOManagedObjectSet {
        return this.valueForKey('cheffLines');
    }
    addCheffLinesObject(value:CheffLine) {
        this._addObjectForKey(value, 'cheffLines');
    }
    removeCheffLinesObject(value:CheffLine) {
        this._removeObjectForKey(value, 'cheffLines');
    }

    // Relationship: menuCategories
    protected _menuCategories:MIOManagedObjectSet = null;
    get menuCategories():MIOManagedObjectSet {
        return this.valueForKey('menuCategories');
    }
    addMenuCategoriesObject(value:MenuCategory) {
        this._addObjectForKey(value, 'menuCategories');
    }
    removeMenuCategoriesObject(value:MenuCategory) {
        this._removeObjectForKey(value, 'menuCategories');
    }
}
