//
// Generated class DevicePaymentTransaction
//

/// <reference path="DevicePaymentTransaction_ManagedObject.ts" />

class DevicePaymentTransaction extends DevicePaymentTransaction_ManagedObject
{

}
