

class ProductWarehouseInfoViewController extends MUIViewController
{
    public preferredContentSize = new MIOSize(700, 500);

    private closeButton:MUIButton = null;
    private addWarehouseButton:MUIButton = null;
    private saveButton:MUIButton = null;
    private tableView:UITableView = null;

    // private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;
    // private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;      

    viewDidLoad(){
        super.viewDidLoad();

        this.closeButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.closeButton.setAction(this, function() {
            this.dismissViewController(true);
        });

        this.addWarehouseButton = MUIOutlet(this, "add-warehouse-btn", "MUIButton");
        this.addWarehouseButton.setAction(this, function(){
            AppHelper.sharedInstance().showSelectWarehouseViewControllerFromView(this.addWarehouseButton, null, false, true, this, this.addProductWarehouses);            
        });

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        });

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;     
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    viewDidDisappear(animated?: any): void {
        super.viewDidDisappear(animated);
        if (this._product != null) this._product.removeObserver(this, "stockCategory");
    }

    private _product:Product = null;
    set product(product:Product){
        this._product = product;
        this.updateUI();
    }

    // observeValueForKeyPath(keypath, type, object){
    //     if (type != "did") return;

    //     switch (keypath) {

    //         case "stockCategory":                    
    //             this.stockCategoryButton.setTitle(this._product.stockCategory ? this._product.stockCategory.name : null);
    //             break;    
    //     }
    // }

    private updateUI(){
        if (this.viewIsLoaded == false || this._product == null) return;        


        

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableview:UITableView){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview:UITableView, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){        
        let cell = tableview.dequeueReusableCellWithIdentifier("ProductWarehouseStockCell") as ProductWarehouseStockCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ProductWarehouse;
        cell.item = item;
        cell.selectionStyle = UITableViewCellSelectionStyle.None;            
        return cell;
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {   
        return UITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {        
        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            let item = this.fetchedResultsController.objectAtIndexPath(indexPath);    
            DBHelper.deleteObjectFromMainContext(item, true); 
        }
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
        
        let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        let predicateFormat = 'product.identifier = ' + this._product.identifier;
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("ProductWarehouse", sd, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["warehouse"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;            
        
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }


    private warehouseDidChange(controller:SelectEntityViewController, warehouse:Warehouse){
        if (warehouse == null) {
            this._product.defaultWarehouse = null;
            this._product.defaultWarehouseName = null;
            this.defaultWarehouseDropdown.title = MIOLocalizeString("_DEFAULT_WAREHOUSE_", "_DEFAULT_WAREHOUSE_");
        }
        else {
            this._product.defaultWarehouse = warehouse;
            this._product.defaultWarehouseName = warehouse.name;
            this.defaultWarehouseDropdown.title = warehouse.name;    
        }
    }

    // get ProductSupportRequiredViewController(){
    //     if(!this.srovc) {
    //         this.srovc = new ProductSupportRequiredViewController('supported_required_view');
    //         this.srovc.initWithResource("layout/modals/productsupportrequired/SupportedRequiredView.html");
    //     }

    //     this.srovc.product = this._product;
    //     return this.srovc;
    // }

    // private showWarehouseSettingsView(){        
    //     let wsvc = new WarehouseSettingsViewController('warehouse-settings-view');
    //     wsvc.initWithResource("layout/modals/warehousesettings/WarehouseSettingsView.html");
    //     this.wsvc = new MUINavigationController('product_warehouses_nav_controller');
    //     this.wsvc.initWithRootViewController(wsvc);
        
    //     this.wsvc.rootViewController.delegate = this;
    //     this.wsvc.rootViewController.product = this._product;

    //     this.presentViewController(this.wsvc, true);
    // }    

    private addProductWarehouses(controller, warehouses){

        for (let index = 0; index < warehouses.length; index++){
            let wh = warehouses[index];
            let predicate = MIOPredicate.predicateWithFormat("warehouse.identifier == " + wh.identifier);
            let contains = _MIOPredicateFilterObjects(this.fetchedResultsController.fetchedObjects, predicate);
            if (contains.length > 0) continue;
            if (this.fetchedResultsController.fetchedObjects.containsObject(wh) == true) continue;

            // Create product warehouse
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;            
            let pw = MIOEntityDescription.insertNewObjectForEntityForName("ProductWarehouse", ad.managedObjectContext) as ProductWarehouse;            
            pw.product = this._product;
            pw.warehouse = wh;
            this._product.addProductWarehousesObject(pw);              
        }

        DBHelper.saveMainContext();
        this.updateUI();

    }



}