

/// <reference path="LegalEntity.ts" />

// Generated class OnlinePlatform_ManagedObject

class OnlinePlatform_ManagedObject extends LegalEntity
{

    // Property: automaticallyAddToDebt
    set automaticallyAddToDebt(value:boolean) {
        this.setValueForKey(value, 'automaticallyAddToDebt');
    }
    get automaticallyAddToDebt():boolean {
        return this.valueForKey('automaticallyAddToDebt');
    }
    set automaticallyAddToDebtPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'automaticallyAddToDebt');
    }
    get automaticallyAddToDebtPrimitiveValue():boolean {
        return this.primitiveValueForKey('automaticallyAddToDebt');
    }

    // Property: defaultFeePercentage
    set defaultFeePercentage(value:number) {
        this.setValueForKey(value, 'defaultFeePercentage');
    }
    get defaultFeePercentage():number {
        return this.valueForKey('defaultFeePercentage');
    }
    set defaultFeePercentagePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'defaultFeePercentage');
    }
    get defaultFeePercentagePrimitiveValue():number {
        return this.primitiveValueForKey('defaultFeePercentage');
    }

    // Property: feeOfBasePrice
    set feeOfBasePrice(value:boolean) {
        this.setValueForKey(value, 'feeOfBasePrice');
    }
    get feeOfBasePrice():boolean {
        return this.valueForKey('feeOfBasePrice');
    }
    set feeOfBasePricePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'feeOfBasePrice');
    }
    get feeOfBasePricePrimitiveValue():boolean {
        return this.primitiveValueForKey('feeOfBasePrice');
    }

    // Property: invoiceSerieID
    set invoiceSerieID(value:string) {
        this.setValueForKey(value, 'invoiceSerieID');
    }
    get invoiceSerieID():string {
        return this.valueForKey('invoiceSerieID');
    }
    set invoiceSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'invoiceSerieID');
    }
    get invoiceSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('invoiceSerieID');
    }

    // Property: nullifyInvoiceSerieID
    set nullifyInvoiceSerieID(value:string) {
        this.setValueForKey(value, 'nullifyInvoiceSerieID');
    }
    get nullifyInvoiceSerieID():string {
        return this.valueForKey('nullifyInvoiceSerieID');
    }
    set nullifyInvoiceSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'nullifyInvoiceSerieID');
    }
    get nullifyInvoiceSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('nullifyInvoiceSerieID');
    }

    // Property: nullifyTicketSerieID
    set nullifyTicketSerieID(value:string) {
        this.setValueForKey(value, 'nullifyTicketSerieID');
    }
    get nullifyTicketSerieID():string {
        return this.valueForKey('nullifyTicketSerieID');
    }
    set nullifyTicketSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'nullifyTicketSerieID');
    }
    get nullifyTicketSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('nullifyTicketSerieID');
    }

    // Property: platformType
    set platformType(value:number) {
        this.setValueForKey(value, 'platformType');
    }
    get platformType():number {
        return this.valueForKey('platformType');
    }
    set platformTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'platformType');
    }
    get platformTypePrimitiveValue():number {
        return this.primitiveValueForKey('platformType');
    }

    // Property: ticketSerieID
    set ticketSerieID(value:string) {
        this.setValueForKey(value, 'ticketSerieID');
    }
    get ticketSerieID():string {
        return this.valueForKey('ticketSerieID');
    }
    set ticketSerieIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'ticketSerieID');
    }
    get ticketSerieIDPrimitiveValue():string {
        return this.primitiveValueForKey('ticketSerieID');
    }

    // Property: useDifferentSeries
    set useDifferentSeries(value:boolean) {
        this.setValueForKey(value, 'useDifferentSeries');
    }
    get useDifferentSeries():boolean {
        return this.valueForKey('useDifferentSeries');
    }
    set useDifferentSeriesPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'useDifferentSeries');
    }
    get useDifferentSeriesPrimitiveValue():boolean {
        return this.primitiveValueForKey('useDifferentSeries');
    }
}
