/**
 * Created by miguel on 22/2/17.
 */

class ArchivedDocumentDetailViewController extends MUIViewController
{
    static newInstance():ArchivedDocumentDetailViewController {
        let vc = new ArchivedDocumentDetailViewController("archived-document-detail-view");
        vc.initWithResource("layout/archived_document/ArchivedDocumentDetailView.html");
        return vc;
    }

    showBackButton = false;
    
    private backButton:MUIButton = null;

    private actionButton:MUIButton = null;
    private exportButton:MUIButton = null;
    private printButton:MUIButton = null;

    private numLabel:MUILabel = null;
    private dateLabel:MUILabel = null;
    private clientNameLabel:MUILabel = null;    
    private paxLabel:MUILabel = null;
    private numItemsLabel:MUILabel = null;    
    private zoneLabel:MUILabel = null;
    private workerOpenLabel:MUILabel = null;
    private workerCloseLabel:MUILabel = null;
    private dateOpenLabel:MUILabel = null;
    private dateCloseLabel:MUILabel = null;

    private preTicketTitleLabel:MUILabel = null;
    private preTicketNumLabel:MUILabel = null;
    private nextDocumentLabel:MUILabel = null;    
    private nextDocumentButton:MUIButton = null;    

    private linesTableView:UITableView = null;
    private transactionsTableView:UITableView = null;    

    private totalDiscountLabel:MUILabel = null;
    private invitedLabel:MUILabel = null;
    private totalBaseLabel:MUILabel = null;
    private totalTaxLabel:MUILabel = null;
    private totalLabel:MUILabel = null;

    private clientInfoButton:MUIButton = null;

    private transactionsDataSource:CashDeskSessionTicketTransactionsDataSource = null;

    private df = MUIWebApplication.sharedInstance().delegate.longDateTimeFormatter;
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter as MIONumberFormatter;
    private ldt = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter;

    private itemCount:number = 0;

    viewDidLoad(){
        super.viewDidLoad();
    
        this.backButton = MUIOutlet(this, 'tdv_nb_back_btn','MUIButton');
        this.backButton.setAction(this, function(){
            this.navigationController.popViewController(true);
        });

        this.actionButton = MUIOutlet(this, "action-btn", "MUIButton");
        this.actionButton.setAction(this, function(this:ArchivedDocumentDetailViewController, control, value){
            this.showActions();
        });

        this.exportButton = MUIOutlet(this, 'export-btn','MUIButton');
        this.exportButton.hidden = true;
        this.exportButton.setAction(this, function(){
            // AppHelper.sharedInstance().exportTicket(this._ticket);
        });

        this.printButton = MUIOutlet(this, 'print-btn','MUIButton');
        this.printButton.setAction(this, function(){
            PrinterHelper.sharedInstance().printArchivedDocument(this._ticket);
        });

        this.clientInfoButton = MUIOutlet(this, 'client-info-btn','MUIButton');
        this.clientInfoButton.setAction(this, function(){
            console.log("client Button activated");
            this.showClientInfoPopover();
        });

        this.numLabel = MUIOutlet(this, 'tdv_c1_ticket_num_lbl', 'MUILabel');
        this.dateLabel = MUIOutlet(this, 'tdv_c1_date_lbl','MUILabel');
        this.clientNameLabel = MUIOutlet(this, 'tdv_c1_client_name_lbl','MUILabel');        
        this.paxLabel = MUIOutlet(this, "pax-lbl", "MUILabel");
        this.numItemsLabel = MUIOutlet(this, 'num-items-lbl', 'MUILabel');
        this.zoneLabel = MUIOutlet(this, "zone-lbl", "MUILabel");
        this.workerOpenLabel = MUIOutlet(this, "worker-open-lbl", "MUILabel");
        this.workerCloseLabel = MUIOutlet(this, "worker-close-lbl", "MUILabel");
        this.dateOpenLabel = MUIOutlet(this, 'date-open-lbl', 'MUILabel');
        this.dateCloseLabel = MUIOutlet(this, 'date-close-lbl', 'MUILabel');

        this.preTicketTitleLabel = MUIOutlet(this, "pre-ticket-title-lbl", "MUILabel");
        this.preTicketNumLabel = MUIOutlet(this, "pre-ticket-value-lbl", "MUILabel");
        this.nextDocumentLabel = MUIOutlet(this, "next-doc-title-lbl", "MUILabel");
        this.nextDocumentButton = MUIOutlet(this, "next-doc-btn", "MUIButton");

        this.linesTableView = MUIOutlet(this, 'lines-table-view', 'UITableView');
        this.linesTableView.dataSource = this;
        this.linesTableView.delegate = this;

        // this.transactionsTableView = MUIOutlet("transactions-table-view", "UITableView");
        // this.transactionsDataSource = new CashDeskSessionTicketTransactionsDataSource();
        // this.transactionsDataSource.initWithTableView(this.transactionsTableView);
        // this.transactionsTableView.dataSource = this.transactionsDataSource;
        
        this.totalBaseLabel = MUIOutlet(this, 'tdv_rb_base_lbl','MUILabel');
        this.totalTaxLabel = MUIOutlet(this, 'tdv_rb_tax_lbl','MUILabel');
        this.totalLabel = MUIOutlet(this, 'tdv_rb_total_lbl','MUILabel');
        this.invitedLabel = MUIOutlet(this, 'tdv_rb_invited_lbl','MUILabel');
        this.totalDiscountLabel = MUIOutlet(this, 'tdv_rb_discount_lbl', 'MUILabel');
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.backButton.setHidden(!this.showBackButton);
        this.updateUI();
    }

    private _ticket:ArchivedDocument = null;
    set ticket(value:ArchivedDocument){
        this._ticket = value;
        this.updateUI();
    }

    set filterValues(values:any){
        let identifier = values["id"];
        if (identifier == null) return;
        this.reloadTicket(identifier);
    }

    private updateUI(){
        if(!this.viewIsLoaded || this._ticket == null) return;
        
        this.dateLabel.text = this.df.stringFromDate(this._ticket.date);        
        this.numLabel.text = this._ticket.legalDocumentID;
        if (this._ticket instanceof ArchivedTicket) {
            this.clientNameLabel.text = this._ticket.linkedLegalEntityName;
            this.zoneLabel.text = this._ticket.zoneString;
            this.dateOpenLabel.text = this.ldt.stringFromDate(this._ticket.openDate);
        }
        else if (this._ticket instanceof ArchivedInvoice) {
            this.clientNameLabel.text = this._ticket.invoiceLegalEntityName;
            this.zoneLabel.text = null;
            this.dateOpenLabel.text = this.ldt.stringFromDate(this._ticket.date);
        }
        
        this.paxLabel.text = this._ticket.numberOfPeople ? String(this._ticket.numberOfPeople) : null;            
        this.workerOpenLabel.text = this._ticket.beginWorkerName;
        this.workerCloseLabel.text = this._ticket.endWorkerName;
        
        this.dateCloseLabel.text = this.ldt.stringFromDate(this._ticket.date);
                
        if (this._ticket.documentID != null && this._ticket.documentID != this._ticket.legalDocumentID) {
            this.preTicketTitleLabel.setHidden(false);
            this.preTicketNumLabel.setHidden(false);
            this.preTicketNumLabel.text = this._ticket.documentID;
        }
        else {
            this.preTicketTitleLabel.setHidden(true);
            this.preTicketNumLabel.setHidden(true);
            this.preTicketNumLabel.text = null;            
        }

        if (this._ticket.nextDocument != null) {
            this.nextDocumentButton.setHidden(false);
            this.nextDocumentLabel.setHidden(false);
            this.nextDocumentButton.title = this._ticket.nextDocument.legalDocumentID;
        }
        else {
            this.nextDocumentButton.title = null;
            this.nextDocumentButton.setHidden(true);
            this.nextDocumentLabel.setHidden(true);
        }

        this.totalDiscountLabel.text = this.cf.stringFromNumber(this._ticket.totalDiscount || 0);
        this.invitedLabel.text = this.cf.stringFromNumber(this._ticket.totalInvited || 0);
        this.totalBaseLabel.text = this.cf.stringFromNumber(this._ticket.totalPriceBase || 0);
        this.totalTaxLabel.text = this.cf.stringFromNumber(this._ticket.totalTaxes || 0);
        this.totalLabel.text = this.cf.stringFromNumber(this._ticket.totalPrice || 0);

        this.fetchedResultsController = null;
        this.mapLines(this.fetchedResultsController.fetchedObjects);
        //this.numItemsLabel.text = this.countTotal(this.fetchedResultsController.fetchedObjects);
        this.numItemsLabel.text = String(this.itemCount);
        this.linesTableView.reloadData();

          //this.numItemsLabel.text = this.nf.stringFromNumber(   );
        //   let countTotal = 0;
        //   let testCount = this.fetchedResultsController.resultObjects.length;
        //   for (var index = 0; index < testCount; index++) {
        //       countTotal += this.fetchedResultsController.objectAtIndexPath(index).quantity;
        //   }
        //   this.numItemsLabel.text = this.nf.stringFromNumber(countTotal);
    }

    private reloadTicket(identifier:string){

        //this is called to update all parts of the manager related to the changes made (updates listView)
        let predicate = MIOPredicate.predicateWithFormat('identifier = ' + identifier);
        let relationships = ["nextDocument"];
        DBHelper.queryObjectsWithCompletion("ArchivedDocument", null, predicate, relationships, this, function(objects){
            if (objects.length > 0) {
                let ticket = objects[0];
                this.ticket = ticket;
            }
        });
    }

    numberOfSections(tableview){
        return 1;
        //return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        // let sec = this.fetchedResultsController.sections[section];
        // return sec.numberOfObjects();
        return this.lines.count;
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let item = this.lines[indexPath.row];        
        
        let cell = tableview.dequeueReusableCellWithIdentifier(item["Type"] == "MODIFIER" ? "ModifierCell" : "DocumentDetailCell") as ArchivedDocumentDetailCell;
        //let item:ArchivedTicketLinesGroup = this.fetchedResultsController.objectAtIndexPath(indexPath);    
        
        cell.item = item;
        
        return cell;
    }
    

    private _fetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
        
        if(this._ticket == null) return null;

        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('ArchivedTicketLinesGroup');    
        fetchRequest.sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('parent', true),
                                         MIOSortDescriptor.sortDescriptorWithKey('orderindex', true)
                                       ];    
        fetchRequest.relationshipKeyPathsForPrefetching = ["archivedTicketLines.modifiers"];
        
        fetchRequest.predicate = MIOPredicate.predicateWithFormat('archivedDocument.identifier == ' + this._ticket.identifier);
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.mapLines(controller.fetchedObjects);
        this.linesTableView.reloadData();

        //this.numItemsLabel.text = this.countTotal(this.fetchedResultsController.fetchedObjects);
        this.numItemsLabel.text = String(this.itemCount);

    }

    private lines = [];
    private mapLines(linesGroup:ArchivedTicketLinesGroup[]){

        this.itemCount = 0;
        this.lines = [];

        // for (let index = 0; index < linesGroup.length; index++){
        //     let grp = linesGroup[index];
        //     if ( grp.archivedTicketLines.count > 1 ) continue;
        //     let l = grp.archivedTicketLines.objectAtIndex(0);
        //     if (l.modifiedType == ArchivedLineModifiedType.visualGroup) continue;
        //     visual_lines[grp.identifier] = [];
        // }
        
        for (let index = 0; index < linesGroup.length; index++){
            let grp = linesGroup[index];
            if (grp.parent != null) continue;
            if (grp.menuName != null && grp.menuName != grp.productName && grp.totalPrice == 0) continue;                        
            this.createLineFromGroup(grp);
        }

        this.lines = _MIOSortDescriptorSortObjects(this.lines, [MIOSortDescriptor.sortDescriptorWithKey("IndexPath", true)]);
    }

    private countTotal(linesGroup){
        let count = 0;
        for (let index = 0; index < linesGroup.length; index++){
            let grp = linesGroup[index];
            let testcount = grp.lines.count;
            count += testcount;
        }
        return count;
    }

    private createLineFromGroup(group:ArchivedTicketLinesGroup, style:string = null, prefix:string = null, parentIndexPath:string = null){
        let line = {};
        let modifier_lines = []
        
        let product_name = group.menuName != null && group.menuName != group.productName ? " • " + group.productName : group.productName

        line["Type"] = "LINE";
        line["Title"] = product_name;
        line["UnitPrice"] = group.productPrice;
        line["TaxName"] = group.taxName;
        if (style != null) line["Style"] = style;
        if (prefix != null) line["Prefix"] = prefix;
        
        let index_product_name = group.menuName != null && group.menuName != group.productName ? group.menuName + "." + group.productName : group.productName
        let index_path = parentIndexPath == null ? index_product_name : parentIndexPath + "." + index_product_name
        line["IndexPath"] = index_path


        let quantity = 0;
        let invited = 0;
        let discount = 0;
        let total = 0;
        let linesCount = 0;

        for (let index = 0; index < group.archivedTicketLines.count; index++){
            let l = group.archivedTicketLines.objectAtIndex(index) as ArchivedTicketLine;

            if (l.modifiedType == 5) line["Style"] = "title";

            if ( (this._ticket.modifiedType == 1 && l.modifiedType == 1 && l.nullifiedBy != null) || l.status == 4) continue;
                        
            linesCount++;
            quantity++;
            discount += l.totalDiscount;
            invited += l.totalInvited;
            total += l.totalPrice;

             for (let modifier_index = 0; modifier_index < l.modifiers.count; modifier_index++) {
                let m = l.modifiers.objectAtIndex(modifier_index);
                if (m.totalPrice > 0 || m.productID != null ) {
                    let ml = {};
                    ml["Type"] = "MODIFIER";
                    ml["Title"] = "+ " + m.name;
                    ml["Quantity"] = this._ticket.modifiedType == 1 ? -m.quantity : m.quantity;
                    ml["UnitPrice"] = m.totalPrice;
                    ml["TaxName"] = m.taxName;
                    if (style != null) line["Style"] = style;
                    ml["IndexPath"] = index_path + "." + m.name;
                    modifier_lines.push(ml);
                } 
             }
        }        

        if (group.bulkQuantity != null) {
            line["BulkQuantity"] = group.bulkQuantity;
        }
        
        line["Quantity"] = this._ticket.modifiedType == 1 ? -quantity : quantity;  
        // Avoid count visual lines      
        if (line["Style"] != "title") this.itemCount = this.itemCount + quantity;

		line["Total"] = total;
		line["TotalDiscount"] = discount;
		line["TotalInvited"] = invited;
        
        if (linesCount == 0 ) return;

        this.lines.addObject(line);
        if (modifier_lines.length > 0) {
            for (let m of modifier_lines) {
                this.lines.addObject(m);
            }
        }

        // this.lines = _MIOSortDescriptorSortObjects(this.lines, [MIOSortDescriptor.sortDescriptorWithKey("Type", true), MIOSortDescriptor.sortDescriptorWithKey("Title", true)]);

        for (let index = 0; index < group.childs.count; index++){
            let child = group.childs.objectAtIndex(index);
            this.createLineFromGroup(child, "subline", "* ", index_path);
        }
    }

    private showActions(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('CHOOSE_THE_ACTION', 'CHOOSE_THE_ACTION'), null, MUIAlertViewStyle.Default);
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CHANGE_CLIENT','CHANGE_CLIENT'), MUIAlertActionStyle.Default, this, function(this:ArchivedDocumentDetailViewController){
			this.changeClient();
        }));

        if (this._ticket instanceof ArchivedTicket) {
        
            if (this._ticket.linkedLegalEntityID != null) {
                avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('REMOVE_CLIENT', 'REMOVE_CLIENT'), MUIAlertActionStyle.Default, this, function(this:ArchivedDocumentDetailViewController){
                    this.unlinkClient(this._ticket as ArchivedTicket);
                }));    
            }
            
            avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CHANGE_PAX', 'CHANGE_PAX'), MUIAlertActionStyle.Default, this, function(){
                this.showChangePAXAlert();
            }));

            if (this._ticket.nextDocument == null) {
                avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CREATE_INVOICE_FROM_TICKET', 'CREATE_INVOICE_FROM_TICKET'), MUIAlertActionStyle.Default, this, function(){
                    this.createInvoice();
                }));
            }
        }

        //modifiedType 1 for a ticket that has already been nullified, 0 if not
        if(this._ticket.modifiedType == 0 && this._ticket.nextDocument == null)
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('NULLIFY', 'NULLIFY'), MUIAlertActionStyle.Destructive, this, function(this:ArchivedDocumentDetailViewController){
            this.nullifyDoc();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL', 'CANCEL'), MUIAlertActionStyle.Cancel, null, null));
        
        this.presentViewController(avc, true);
    }

    //add action to nullify a receipt (ticket)
    private nullifyDoc(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.nullifyDocument(this._ticket, (result:boolean, json:any, error:string) => {
            if (result){
                AppHelper.showAlert(this, MIOLocalizeString('INFORMATION', 'INFORMATION'), MIOLocalizeString('TICKET_NULLIFY_SUCCESS', 'TICKET_NULLIFY_SUCCESS'));
                this.reloadTicket(this._ticket.identifier);
            }
            else {
                AppHelper.showAlert(this, MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('TICKET_NULLIFY_ERROR', 'TICKET_NULLIFY_ERROR'));
            }
        });
    }

    private changeClient(){
        let vc = AppHelper.sharedInstance().selectViewController("AddClient", "name", false, false, null, this, null) as SelectEntityViewController;
        vc.allowSearch = true;
        this.presentViewController(vc, true);
    }

    private unlinkClient(receipt:ArchivedTicket){
        
        receipt.linkedLegalEntityID = null;
        receipt.linkedLegalEntityName = null;
        receipt.linkedLegalEntityAddress = null;
        receipt.linkedLegalEntityAddress2 = null;
        receipt.linkedLegalEntityDocument = null;        
        
        DBHelper.saveMainContext();
        this.updateUI();
    }

    private showChangePAXAlert(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('TICKET_CHANGE_PAX', 'TICKET_CHANGE_PAX'), null, MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            textField.formatter = ad.numberFormatter;
            textField.placeholderText = this._ticket.numberOfPeople;
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle('OK', MUIAlertActionStyle.Default, this, function(){
            let tf = avc.textFields[0];
            if (tf.text == null) return;
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            let pax = ad.numberFormatter.numberFromString(tf.text);
            this.changePax(pax);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL', 'CANCEL'), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private changePax(pax:number){
        //this._ticket.numberOfPeople = pax;  //need to use python scripts to update

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.updateTicketPAX(this._ticket.identifier, pax, this, function(code, data){
            if (code == 200) {
                AppHelper.showInfoMessage(null, MIOLocalizeString("PAX_UPDATED", "PAX_UPDATED"), MIOLocalizeString("THE_DOCUMENT_WAS_UPDATED_SUCCESSFULLY", "THE_DOCUMENT_WAS_UPDATED_SUCCESSFULLY"));
                //MIONotificationCenter.defaultCenter().postNotification("DBSyncEntityDidChange", null);
            }
            else AppHelper.showErrorMessage(null, MIOLocalizeString( "ERROR", "ERROR"), MIOLocalizeString("SOMETHING_WENT_WROMG_TRY_AGIN_LATER", "SOMETHING_WENT_WROMG_TRY_AGIN_LATER"));
        });

        DBHelper.saveMainContext();
        this.updateUI();
    }

    private createInvoice(){
        let vc = AppHelper.sharedInstance().selectViewController("CreateInovice", "name", false, false, null, this, null) as SelectEntityViewController;
        vc.allowSearch = true;
        this.presentViewController(vc, true);
    }

    //
    // SelectEntityViewControllerDelegate
    //

    fetchRequestForController(controller:SelectEntityViewController):MIOFetchRequest{

        if (controller.identifier == "AddClient"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Client", sd, null);
            fetchRequest.fetchLimit = 50;
            fetchRequest.relationshipKeyPathsForPrefetching = ['address', 'mobilePhone', 'phone'];

            return fetchRequest;
        }
        else if (controller.identifier == "CreateInovice"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Client", sd, null);
            fetchRequest.fetchLimit = 50;
            fetchRequest.relationshipKeyPathsForPrefetching = ['address', 'mobilePhone', 'phone'];

            return fetchRequest;
        }

        return null;
    }

    didSelectObjectFromSelectViewController(controller:SelectEntityViewController, item:MIOManagedObject){
        
        let dismiss = true;

        if (controller.identifier == "AddClient"){

            let client = item as Client;
            if (this._ticket instanceof ArchivedTicket) {
                // this._ticket.linkedLegalEntityID = client.identifier;
                // this._ticket.linkedLegalEntityName = client.name;
                // this._ticket.linkedLegalEntityDocument = client.document;
                // this._ticket.linkedLegalEntityAddress = client.address != null ? client.address.address1 : null;
                // this._ticket.linkedLegalEntityAddress2 = client.address != null ? client.address.address2 : null;

                // DBHelper.saveMainContext();
                // this.updateUI();
                
                let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
                ad.webService.changeArchivedDocumentClient(this._ticket, client, (result:boolean, json:any, error:string)=> {
                    if (result) {
                        this.reloadTicket(this._ticket.identifier);
                    }
                    else {
                        AppHelper.showErrorMessage(null, MIOLocalizeString("CHANGE_CUSTOMER_ERROR", "CHANGE_CUSTOMER_ERROR"), MIOLocalizeString("CHECK_ID_ALL_THE_REQUIRED_INFO_FROM_A_CLIENT_EXISTS", "CHECK_ID_ALL_THE_REQUIRED_INFO_FROM_A_CLIENT_EXISTS"));
                    }
                });
    
            }
            else if (this._ticket instanceof ArchivedInvoice) {
                this.changeClientInvoice(client);
            }
        }
        else if (controller.identifier == "CreateInovice"){
            let client = item as Client;
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            ad.webService.createInvoiceFromTicket(this._ticket.identifier, client, (json:any, error:string)=> {
                if (error == null) {
                    this.reloadTicket(this._ticket.identifier);
                    AppHelper.showErrorMessage(null, MIOLocalizeString("INVOICE_OPERATION", "INVOICE_OPERATION"), MIOLocalizeString("THE_INVOICE_WAS_CREATED_SUCESSFULLY", "THE_INVOICE_WAS_CREATED_SUCESSFULLY"));
                }
                else {
                    AppHelper.showErrorMessage(null, MIOLocalizeString("INVOICE_ERROR", "INVOICE_ERROR"), MIOLocalizeString("CHECK_ID_ALL_THE_REQUIRED_INFO_FROM_A_CLIENT_EXISTS", "CHECK_ID_ALL_THE_REQUIRED_INFO_FROM_A_CLIENT_EXISTS"));
                }
            });
        }

        return dismiss;
    }

    private changeClientInvoice(client:Client){
        let type = SettingsHelper.sharedInstance().invoiceCustomerChangeType;

        switch (type) {
            case InvoiceCustomerChangeType.forbidden    : this.changeClientCreatingNewInvoice(client); break;
            case InvoiceCustomerChangeType.allow        : this.changeClientInvoiceInfo(client)       ; break;
            case InvoiceCustomerChangeType.beforePosted :
                //TODO: Validate if the invoice is already posted
                this.changeClientInvoiceInfo(client);
        }
    }

    private changeClientCreatingNewInvoice(client:Client) {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.changeClientInvoice(this._ticket as ArchivedInvoice, client, (json, error) => {
            if (error != null) {
                AppHelper.showErrorMessage( this, MIOLocalizeString( "COULD_NOT_CHANGE_THE_CLIENT", "COULD_NOT_CHANGE_THE_CLIENT" ), error );
                return;
            }

            let ids = json["ids"];
            //NOTE: pf = look for all objects (ArchivedDocument) in column "identifier" that correspond with all ID's found in the array (ids)
            let pf = "identifier in ('" + ids.join("','") + "')";
            DBHelper.queryObjectsWithCompletion("ArchivedDocument", null, MIOPredicate.predicateWithFormat(pf), [], this, function() {
                this.updateUI();
            });
        });
    }

    private changeClientInvoiceInfo(client:Client) {
        let inv = this._ticket as ArchivedInvoice;

        inv.invoiceLegalEntityID = client.identifier;
        inv.invoiceLegalEntityName = client.name;
        inv.invoiceLegalEntityDocument = client.document;
        inv.invoiceLegalEntityAddress = client.address.address1;
        inv.invoiceLegalEntityAddress2 = client.address.address2;

        DBHelper.saveMainContext();
    }


    private showClientInfoPopover(){
        let vc = ClientInfoPopoverViewController.newInstance();
        vc.delegate = this;

        vc.setClientID =    (this._ticket instanceof ArchivedInvoice) ?  this._ticket.invoiceLegalEntityID :
                            ((this._ticket instanceof ArchivedTicket) ? this._ticket.linkedLegalEntityID : null);

        this.presentViewController(vc, true);
    }


}