class OnlineSettingCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    private descLabel:MUILabel = null;
    private valueTextField:MUITextField = null;
    private colorValueTextField:MUITextField = null;
    private colorSelector:MUIView = null;
    private switchButton:MUISwitchButton = null;
    private dropdownButton:MUIButton = null;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.descLabel = MUIOutlet(this, "desc-lbl", "MUILabel");
        this.valueTextField = this.setupValueTextField("value-tf");
        this.colorSelector = MUIOutlet(this, "color-selector", "MUIView");
        this.colorValueTextField = this.setupColorValueTextField("color-value-tf");
        this.switchButton = this.setupSwitchButton("switch-btn");
        this.dropdownButton = this.setupDropdownButton("dropdown-btn");

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private _name:string = null;
    set setName (text:string) {
        this._name = text;
        this.nameLabel.text = MIOLocalizeString(text, text);
    }

    set setNameUnavailable (value) {
        if(value) this.nameLabel.setTextRGBColor(175, 175, 189);
    }

    private _desc:string = null;
    set setDesc (text:string) {
        this._desc = text;
        this.descLabel.text = MIOLocalizeString(text, text);
    }

    private _item:Configuration = null;
    set item (item:Configuration){
        this._item = item;
        if (item == null) return;

        //Choose action by "type" cell items manually assigned in OnlineSettingView
        switch (item.type){

            case ConfigurationValueType.String:
                if (this.valueTextField) {
                    this.valueTextField.text = item.stringValue;
                } else if (this.colorValueTextField) {
                    this.colorValueTextField.text = item.stringValue;
                    this.setColorField();
                }
            break;

            case ConfigurationValueType.Boolean:
                this.switchButton.on = item.boolValue;
            break;

            case ConfigurationValueType.Entity:
                this.dropdownButton.title = item.relationEntityValueTitle ? item.relationEntityValueTitle : MIOLocalizeString("NO SELECTED", "NO SELECTED");
            break;

            default:
                if (this.valueTextField) this.valueTextField.text = item.stringValue;
            break;
        }                
    }

    private setupValueTextField(outlet:string): MUITextField {
        let textField = MUIOutlet(this, outlet, "MUITextField");
        if (textField == null || textField == undefined) return null;

        textField.setOnChangeText(this, function(control:MUITextField, value:string){
            this._item.stringValue = value;         
        });        

        return textField;
    }
    
    private setupColorValueTextField(outlet:string): MUITextField {
        let textField = MUIOutlet(this, outlet, "MUITextField");
        if (textField == null || textField == undefined) return null;

        textField.setOnChangeText(this, function(control:MUITextField, value:string){
            this._item.stringValue = textField.text;
            this.setColorField();
        });        

        return textField;
    }

    private setColorField() {
        if (this.colorValueTextField.text.length == 7){
            let hexColorValue = this.colorValueTextField.text;
            let RGBColorValue = this.hexToRgb(hexColorValue);
            this.colorSelector.setBackgroundRGBColor(RGBColorValue.r, RGBColorValue.g, RGBColorValue.b);
        } else {
            this.colorSelector.setBackgroundRGBColor(255, 255, 255);
        }
    }
    
    private setupSwitchButton(outlet:string): MUISwitchButton {
        let button = MUIOutlet(this, outlet, "MUISwitchButton");
        if (button == null || button == undefined) return null;

        button.setOnChangeValue(this, function(control:MUISwitchButton, value:boolean){
            this._item.boolValue = value;
            this._item.value = value?'true':'false';
            this._item.stringValue = value?'true':'false';
        });
        
        return button;
    }

    private setupDropdownButton(outlet:string): MUIButton {
        let button = MUIOutlet(this, outlet, "MUIButton");
        if (button == null || button == undefined) return null;

        button.setAction(this, function() {
            this.showSelectEntityViewController();
        }); 
        
        return button;
    }

    private showSelectEntityViewController() {

        let predicateFormat = null;
        if (this._item.relationEntityName == "Application") {
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            predicateFormat = "placeID == " + ad.selectedIdentifier;
        }
        
        let vc = AppHelper.sharedInstance().showCustomSelectViewControllerFromView(this.dropdownButton, this._item.relationEntityName, this._item.relationEntityTitleKey, predicateFormat, this, function(controller, object:MIOManagedObject){

            let title = object.valueForKey(this._item.relationEntityTitleKey);
            let value = object.valueForKey(this._item.relationEntityValueKey);
            
            this.dropdownButton.title = title;
            this._item.stringValue = value;
            this._item.relationEntityValueTitle = title;
        });        
    }

    private hexToRgb(hex:string) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
    }

}