//
// Generated class ReplicaConfig
//

/// <reference path="ReplicaConfig_ManagedObject.ts" />

class ReplicaConfig extends ReplicaConfig_ManagedObject
{

}
