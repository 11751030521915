
class ProductPresetsGroupListCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;

    awakeFromHTML()
    {
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        //this.separatorStyle = MUITableViewCellSeparatorStyle.None;
    }

    set item(i:any)
    {
        this.nameLabel.text = i.name;
    }

}