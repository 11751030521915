//
// Generated class TakeAwayTimeRangePresetConfiguration
//

/// <reference path="TakeAwayTimeRangePresetConfiguration_ManagedObject.ts" />

class TakeAwayTimeRangePresetConfiguration extends TakeAwayTimeRangePresetConfiguration_ManagedObject
{

}
