//
// Generated class AppArea
//

/// <reference path="AppArea_ManagedObject.ts" />

enum AppAreaID 
{
    AppAreaIDNLOrders           = 1,
    AppAreaIDLOrders            = 2,
    AppAreaIDOnlineOrders       = 3,
    AppAreaIDAccountOrders      = 4,
    AppAreaIDTabOrders          = 5,
    AppAreaIDKiosk              = 6,
    AppAreaIDStock              = 7,
    AppAreaIDBookingOrders      = 8,
    AppAreaIDCashdesk           = 100,
    AppAreaIDAdministration     = 1000
}

class AppArea extends AppArea_ManagedObject
{

}
