

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class SupportStream_ManagedObject

class SupportStream_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: friendlyCode
    set friendlyCode(value:string) {
        this.setValueForKey(value, 'friendlyCode');
    }
    get friendlyCode():string {
        return this.valueForKey('friendlyCode');
    }
    set friendlyCodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'friendlyCode');
    }
    get friendlyCodePrimitiveValue():string {
        return this.primitiveValueForKey('friendlyCode');
    }

    // Property: title
    set title(value:string) {
        this.setValueForKey(value, 'title');
    }
    get title():string {
        return this.valueForKey('title');
    }
    set titlePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'title');
    }
    get titlePrimitiveValue():string {
        return this.primitiveValueForKey('title');
    }

    // Property: urlCode
    set urlCode(value:string) {
        this.setValueForKey(value, 'urlCode');
    }
    get urlCode():string {
        return this.valueForKey('urlCode');
    }
    set urlCodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'urlCode');
    }
    get urlCodePrimitiveValue():string {
        return this.primitiveValueForKey('urlCode');
    }
    // Relationship: session
    set session(value:SupportSession) {
        this.setValueForKey(value, 'session');
    }
    get session():SupportSession {
        return this.valueForKey('session') as SupportSession;
    }
}
