class WorkersAdminBookingDataSource extends MIOObject
{    
    private _tableView:UITableView = null;
    initWithTableView(tableView:UITableView){
        super.init();
        this._tableView = tableView;
    }
    
    private _employee:Employee = null;
    get employee(){return this._employee;}
    set employee(value:Employee) {
        this._employee = value;
        this._workerRole = null;  
        this._tableView.reloadData();      
    }

    private _workerRole:WorkerRole = null;
    get role(){return this._workerRole;}
    set role(value:WorkerRole){
        this._workerRole = value;
        this._employee = null;
        this._tableView.reloadData();
    }

    numberOfRowsInSection(tableView:MUITableView, section) {
        let rows = Employee.bookingPermisionsCount();
        return rows;
    }

    cellAtIndexPath(tableView:UITableView, indexPath: MIOIndexPath) {
        let cell = tableView.dequeueReusableCellWithIdentifier("PermissionCell") as WorkerPermissionCell;        
        
        if (this.employee != null) this.configureEmployeeCellAtIndexPath(cell, indexPath);
        else if (this.role != null) this.configureWorkerRolCellAtIndexPath(cell, indexPath);

        return cell;
    }

    private configureEmployeeCellAtIndexPath(cell:WorkerPermissionCell, indexPath: MIOIndexPath) {
        
        let permission = Employee.bookingPermisionAtIndex(indexPath.row);
        
        cell.title = Employee.stringForBookingPermission(permission);
        cell.accessoryType = this.employee.hasBookingPermision(permission) ? UITableViewCellAccessoryType.Checkmark : UITableViewCellAccessoryType.None;
    }

    private configureWorkerRolCellAtIndexPath(cell:WorkerPermissionCell, indexPath: MIOIndexPath) {
        
        let permission = Employee.bookingPermisionAtIndex(indexPath.row);
        
        cell.title = Employee.stringForBookingPermission(permission);
        cell.accessoryType = this.role.hasBookingPermision(permission) ? UITableViewCellAccessoryType.Checkmark : UITableViewCellAccessoryType.None;
    }

    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        
        let cell = tableView.cellAtIndexPath(indexPath) as WorkerPermissionCell;
        let permission = Employee.bookingPermisionAtIndex(indexPath.row);

        if (this.employee != null && this.employee.hasBookingPermision(permission) == true){
            this.employee.removeBookingPermission(permission);
            cell.accessoryType = UITableViewCellAccessoryType.None;                
        }
        else if (this.employee != null && this.employee.hasBookingPermision(permission) == false){
            this.employee.addBookingPermission(permission);
            cell.accessoryType = UITableViewCellAccessoryType.Checkmark;
        }
        if (this.role != null && this.role.hasBookingPermision(permission) == true){
            this.role.removeBookingPermission(permission);
            cell.accessoryType = UITableViewCellAccessoryType.None;                
        }
        else if (this.role != null && this.role.hasBookingPermision(permission) == false){
            this.role.addBookingPermission(permission);
            cell.accessoryType = UITableViewCellAccessoryType.Checkmark;
        }     

    }
}