

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ProductSource_ManagedObject

class ProductSource_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: autoSendOnQuickPay
    set autoSendOnQuickPay(value:boolean) {
        this.setValueForKey(value, 'autoSendOnQuickPay');
    }
    get autoSendOnQuickPay():boolean {
        return this.valueForKey('autoSendOnQuickPay');
    }
    set autoSendOnQuickPayPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'autoSendOnQuickPay');
    }
    get autoSendOnQuickPayPrimitiveValue():boolean {
        return this.primitiveValueForKey('autoSendOnQuickPay');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Relationship: productFormats
    protected _productFormats:MIOManagedObjectSet = null;
    get productFormats():MIOManagedObjectSet {
        return this.valueForKey('productFormats');
    }
    addProductFormatsObject(value:ProductFormat) {
        this._addObjectForKey(value, 'productFormats');
    }
    removeProductFormatsObject(value:ProductFormat) {
        this._removeObjectForKey(value, 'productFormats');
    }

    // Relationship: products
    protected _products:MIOManagedObjectSet = null;
    get products():MIOManagedObjectSet {
        return this.valueForKey('products');
    }
    addProductsObject(value:Product) {
        this._addObjectForKey(value, 'products');
    }
    removeProductsObject(value:Product) {
        this._removeObjectForKey(value, 'products');
    }
}
