

/// <reference path="StockNote.ts" />

// Generated class InternalOrderResponse_ManagedObject

class InternalOrderResponse_ManagedObject extends StockNote
{
    // Relationship: internalOrder
    set internalOrder(value:InternalOrder) {
        this.setValueForKey(value, 'internalOrder');
    }
    get internalOrder():InternalOrder {
        return this.valueForKey('internalOrder') as InternalOrder;
    }
}
