//
// Generated class WorkSession
//

/// <reference path="WorkSession_ManagedObject.ts" />

class WorkSession extends WorkSession_ManagedObject
{
    get workerTime(){
        let beginDate = this.beginDate != null ? this.beginDate : MIODateNow();
        let endDate = this.endDate != null ? this.endDate : MIODateNow();

        let ms = endDate.getTime() - beginDate.getTime();
        return ms;
    }

    get workedHours(){
        let ms = this.workerTime;        

        let hours = ms / 3600000;
        return hours;
    }

    get workedSeconds(){
        let ms = this.workerTime;

        let seconds = ms / 1000;
        return seconds;
    }

    get workerHoursDate(){
        let ms = this.workerTime;        
        let time = new Date(ms);

        let hrs = time.getTimezoneOffset() / 60;
        time.setHours(time.getHours() + hrs);

        return time;
    }

}
