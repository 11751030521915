//
// Generated class AdyenBusinessLicense
//

/// <reference path="AdyenBusinessLicense_ManagedObject.ts" />

class AdyenBusinessLicense extends AdyenBusinessLicense_ManagedObject
{

}
