

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Tip_ManagedObject

class Tip_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: modifierString
    set modifierString(value:string) {
        this.setValueForKey(value, 'modifierString');
    }
    get modifierString():string {
        return this.valueForKey('modifierString');
    }
    set modifierStringPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'modifierString');
    }
    get modifierStringPrimitiveValue():string {
        return this.primitiveValueForKey('modifierString');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Relationship: tickets
    protected _tickets:MIOManagedObjectSet = null;
    get tickets():MIOManagedObjectSet {
        return this.valueForKey('tickets');
    }
    addTicketsObject(value:Ticket) {
        this._addObjectForKey(value, 'tickets');
    }
    removeTicketsObject(value:Ticket) {
        this._removeObjectForKey(value, 'tickets');
    }

    // Relationship: tipConfigurations
    protected _tipConfigurations:MIOManagedObjectSet = null;
    get tipConfigurations():MIOManagedObjectSet {
        return this.valueForKey('tipConfigurations');
    }
    addTipConfigurationsObject(value:TipConfiguration) {
        this._addObjectForKey(value, 'tipConfigurations');
    }
    removeTipConfigurationsObject(value:TipConfiguration) {
        this._removeObjectForKey(value, 'tipConfigurations');
    }
}
