

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class IntermediaryLicenseRate_ManagedObject

class IntermediaryLicenseRate_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: feeFormat
    set feeFormat(value:string) {
        this.setValueForKey(value, 'feeFormat');
    }
    get feeFormat():string {
        return this.valueForKey('feeFormat');
    }
    set feeFormatPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'feeFormat');
    }
    get feeFormatPrimitiveValue():string {
        return this.primitiveValueForKey('feeFormat');
    }
    // Relationship: intermediary
    set intermediary(value:Intermediary) {
        this.setValueForKey(value, 'intermediary');
    }
    get intermediary():Intermediary {
        return this.valueForKey('intermediary') as Intermediary;
    }
    // Relationship: rate
    set rate(value:Rate) {
        this.setValueForKey(value, 'rate');
    }
    get rate():Rate {
        return this.valueForKey('rate') as Rate;
    }
}
