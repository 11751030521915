

/// <reference path="ChildPlace.ts" />

// Generated class ProductPlace_ManagedObject

class ProductPlace_ManagedObject extends ChildPlace
{

    // Property: defaultSupplierName
    set defaultSupplierName(value:string) {
        this.setValueForKey(value, 'defaultSupplierName');
    }
    get defaultSupplierName():string {
        return this.valueForKey('defaultSupplierName');
    }
    set defaultSupplierNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'defaultSupplierName');
    }
    get defaultSupplierNamePrimitiveValue():string {
        return this.primitiveValueForKey('defaultSupplierName');
    }

    // Property: isEnableForSell
    set isEnableForSell(value:boolean) {
        this.setValueForKey(value, 'isEnableForSell');
    }
    get isEnableForSell():boolean {
        return this.valueForKey('isEnableForSell');
    }
    set isEnableForSellPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isEnableForSell');
    }
    get isEnableForSellPrimitiveValue():boolean {
        return this.primitiveValueForKey('isEnableForSell');
    }

    // Property: lastSupplierName
    set lastSupplierName(value:string) {
        this.setValueForKey(value, 'lastSupplierName');
    }
    get lastSupplierName():string {
        return this.valueForKey('lastSupplierName');
    }
    set lastSupplierNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'lastSupplierName');
    }
    get lastSupplierNamePrimitiveValue():string {
        return this.primitiveValueForKey('lastSupplierName');
    }

    // Property: lastSupplierPriceDate
    set lastSupplierPriceDate(value:Date) {
        this.setValueForKey(value, 'lastSupplierPriceDate');
    }
    get lastSupplierPriceDate():Date {
        return this.valueForKey('lastSupplierPriceDate');
    }
    set lastSupplierPriceDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'lastSupplierPriceDate');
    }
    get lastSupplierPriceDatePrimitiveValue():Date {
        return this.primitiveValueForKey('lastSupplierPriceDate');
    }

    // Property: lastSupplierProductPrice
    set lastSupplierProductPrice(value:number) {
        this.setValueForKey(value, 'lastSupplierProductPrice');
    }
    get lastSupplierProductPrice():number {
        return this.valueForKey('lastSupplierProductPrice');
    }
    set lastSupplierProductPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lastSupplierProductPrice');
    }
    get lastSupplierProductPricePrimitiveValue():number {
        return this.primitiveValueForKey('lastSupplierProductPrice');
    }

    // Property: modifiersRequired
    set modifiersRequired(value:boolean) {
        this.setValueForKey(value, 'modifiersRequired');
    }
    get modifiersRequired():boolean {
        return this.valueForKey('modifiersRequired');
    }
    set modifiersRequiredPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'modifiersRequired');
    }
    get modifiersRequiredPrimitiveValue():boolean {
        return this.primitiveValueForKey('modifiersRequired');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }
    // Relationship: defaultSupplier
    set defaultSupplier(value:Supplier) {
        this.setValueForKey(value, 'defaultSupplier');
    }
    get defaultSupplier():Supplier {
        return this.valueForKey('defaultSupplier') as Supplier;
    }
    // Relationship: lastSupplier
    set lastSupplier(value:Supplier) {
        this.setValueForKey(value, 'lastSupplier');
    }
    get lastSupplier():Supplier {
        return this.valueForKey('lastSupplier') as Supplier;
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
    // Relationship: source
    set source(value:ProductSource) {
        this.setValueForKey(value, 'source');
    }
    get source():ProductSource {
        return this.valueForKey('source') as ProductSource;
    }
}
