

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class BookingPrescriptor_ManagedObject

class BookingPrescriptor_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: contactName
    set contactName(value:string) {
        this.setValueForKey(value, 'contactName');
    }
    get contactName():string {
        return this.valueForKey('contactName');
    }
    set contactNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'contactName');
    }
    get contactNamePrimitiveValue():string {
        return this.primitiveValueForKey('contactName');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: phone
    set phone(value:string) {
        this.setValueForKey(value, 'phone');
    }
    get phone():string {
        return this.valueForKey('phone');
    }
    set phonePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'phone');
    }
    get phonePrimitiveValue():string {
        return this.primitiveValueForKey('phone');
    }
}
