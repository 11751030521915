//
// Generated class PosClientDevice
//

/// <reference path="PosClientDevice_ManagedObject.ts" />

class PosClientDevice extends PosClientDevice_ManagedObject
{

}
