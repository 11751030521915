//
// Generated class EmployeeLocation
//

/// <reference path="EmployeeLocation_ManagedObject.ts" />

class EmployeeLocation extends EmployeeLocation_ManagedObject
{

}
