//
// Generated class IntegratorSync
//

/// <reference path="IntegratorSync_ManagedObject.ts" />
 
enum IntegratorSyncStatus
{
    None,
    InQueue,
    Synced
}

class IntegratorSync extends IntegratorSync_ManagedObject
{

}
