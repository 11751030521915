

class PartyZonesSetupDataSource extends MIOObject
{
    private delegate = null;
    private tableView:MUITableView = null;    

    initWithTableView(tableView:MUITableView, delegate:any){
        this.tableView = tableView;
        this.delegate = delegate;
    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as BookingZone;

        let cell = null;
        if (item.parent == null) {
            cell = tableview.dequeueReusableCellWithIdentifier('ZoneCell') as BookingZoneCell;            
        }
        else {
            cell = tableview.dequeueReusableCellWithIdentifier('SubZoneCell') as BookingZoneCell;
        }

        cell.zone = item;        
            
        return cell;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        if (this.delegate == null) return;

        let zone = this.fetchedResultsController.objectAtIndexPath(indexPath);
        this.delegate.didSelectZone(zone);
    }
    
    // editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
    //     return MUITableViewCellEditingStyle.Delete;
    // }

    // commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {
    //     let zone = this.fetchedResultsController.objectAtIndexPath(indexPath);    

    //     if (editingStyle == MUITableViewCellEditingStyle.Delete) {
    //         this.removeZone(zone);
    //     }
    // }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('indexPathString', true)];

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("BookingZone", sortDescriptors, null);
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }   
}