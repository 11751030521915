class AdyenDetailCell extends UITableViewCell
{
    protected retryButton:MUIButton = null;
    protected statusIcon:MUIView = null;

    awakeFromHTML(){
        this.retryButton = MUIOutlet(this, "cell-retry-btn", "MUIButton");
        this.statusIcon = MUIOutlet(this, "cell-status-icon", "MUIView");
        this.selectionStyle = UITableViewCellSelectionStyle.None;
        this._update_ui();
    }

    protected _company:Company = null;
    set company(company:Company){
        this._company = company;
        this._update_ui();
    }

    protected _payment_entity:AdyenPaymentEntity = null;
    protected _payment_config:any = null;
    set paymentEntity(paymentEntity:AdyenPaymentEntity){
        this._payment_entity = paymentEntity;
        this._payment_config = paymentEntity.config ?? {};
        
        // DEFAULTS
        if ( this._payment_config["AccountHolderReference"] == null ) this._payment_config["AccountHolderReference"] = this._company.name + " Account Holder";
        if ( this._payment_config["AccountHolderDescription"] == null ) this._payment_config["AccountHolderDescription"] = this._company.name + " Account Holder";
        if ( this._payment_config["BusinessLineIndustryCode"] == null ) this._payment_config["BusinessLineIndustryCode"] = "722511"; // For restaurants
        if ( this._payment_config["BusinessLineWeb"] == null ) this._payment_config["BusinessLineWeb"] = "https://www.dual-link.com";
        if ( this._payment_config["BusinessLineDescription"] == null ) this._payment_config["BusinessLineDescription"] = "Funds from " + this._company.name;
        if ( this._payment_config["BalanceAccountReference"] == null ) this._payment_config["BalanceAccountReference"] = "BA-" + this._company.name.replace(/\s/gi, "-");
        if ( this._payment_config["BalanceAccountDescription"] == null ) this._payment_config["BalanceAccountDescription"] = "BA for " + this._company.name.replace(/\s/gi, "-");
        if ( this._payment_config["StoreStatement"] == null ) this._payment_config["StoreStatement"] = this._company.name.slice(0, 21);
        if ( this._payment_config["ECOMStoreDescription"] == null ) this._payment_config["ECOMStoreDescription"] = "ECOM Store for " + this._company.name;
        if ( this._payment_config["ECOMStoreReference"] == null ) this._payment_config["ECOMStoreReference"] = "ecom-" + this._company.name.replace(/\s/gi, "-");
        if ( this._payment_config["POSStoreDescription"] == null ) this._payment_config["POSStoreDescription"] = "POS Store for " + this._company.name;
        if ( this._payment_config["POSStoreReference"] == null ) this._payment_config["POSStoreReference"] = "pos-" + this._company.name.replace(/\s/gi, "-");
                                
        this._payment_entity.config = this._payment_config;
        this._update_ui();
    }

    protected _update_ui(){
        if (this._payment_entity == null || this._company == null) return;
        this.updateUI();
    }

    protected updateUI(){}

    set ready(value:boolean){
        if (value == true) {
            this.retryButton.hidden = true;
            MUICoreLayerAddStyle( this.statusIcon.layer, "active" );
            MUICoreLayerRemoveStyle( this.statusIcon.layer, "alert" );
        }
        else {
            this.retryButton.hidden = false;
            MUICoreLayerAddStyle( this.statusIcon.layer, "alert" );
            MUICoreLayerRemoveStyle( this.statusIcon.layer, "active" );
        }
    }
}


class AdyenLegalEntityDetailCell extends AdyenDetailCell
{
    private legalDocumentLabel:MUILabel = null;
    private vatDocumentLabel:MUILabel = null;
    private legalTypeComboBox:MUIComboBox = null;

    private addressLabel:MUILabel = null;
    private cityLabel:MUILabel = null;
    private postalCodeLabel:MUILabel = null;
    private countryLabel:MUILabel = null;

    private venueAddressTextField:MUITextField = null;
    private venueCityTextField:MUITextField = null;
    private venuePostalCodeTextField:MUITextField = null;
    private venueCountryLabel:MUILabel = null;

    awakeFromHTML()
    {
        super.awakeFromHTML();
        this.legalDocumentLabel = MUIOutlet(this, "registration-number-tf", "MUILabel");
        this.vatDocumentLabel = MUIOutlet(this, "vat-number-tf", "MUILabel");
        this.legalTypeComboBox = MUIOutlet(this, "legal-entity-type-cb", "MUIComboBox");
        this.legalTypeComboBox.setOnChangeAction(this, function(this:AdyenLegalEntityDetailCell, control:MUIComboBox, value:string){
            this._payment_entity.companyType = parseInt(value);
        });

        this.addressLabel = MUIOutlet(this, "address-lbl", "MUILabel");
        this.cityLabel = MUIOutlet(this, "city-lbl", "MUILabel");
        this.postalCodeLabel = MUIOutlet(this, "postal-code-lbl", "MUILabel");
        this.countryLabel = MUIOutlet(this, "country-lbl", "MUILabel");

        this.venueAddressTextField = MUIOutlet(this, "venue-address-tf", "MUITextField");
        this.venueCityTextField = MUIOutlet(this, "venue-city-tf", "MUITextField");
        this.venuePostalCodeTextField = MUIOutlet(this, "venue-postal-code-tf", "MUITextField");
        this.venueCountryLabel = MUIOutlet(this, "venue-country-tf", "MUILabel");

        this.venueAddressTextField.enabled = false;
        this.venueCityTextField.enabled = false;
        this.venuePostalCodeTextField.enabled = false;
        this.venueCountryLabel.enabled = false;
    }

    protected updateUI(): void 
    {        
        this.legalDocumentLabel.text = this._company.document;
        this.vatDocumentLabel.text = this._company.address.countryISOa2 + this._company.document;
        this.legalTypeComboBox.selectItem( this._payment_entity.companyType );         

        this.addressLabel.text = this._company.address.address1;
        this.cityLabel.text = this._company.address.city;
        this.postalCodeLabel.text = this._company.address.postalCode;
        this.countryLabel.text = this._company.address.countryISOa2;

        this.venueAddressTextField.text = this._company.address.address1;
        this.venueCityTextField.text = this._company.address.city;
        this.venuePostalCodeTextField.text = this._company.address.postalCode;
        this.venueCountryLabel.text = this._company.address.countryISOa2;

        let is_created = ( this._payment_config[ "LegalEntityID" ] != null );
        if ( is_created ) {
            this.legalTypeComboBox.enabled = false;
            this.ready = true;
        }
    }

}

class AdyenAccountHolderDetailCell extends AdyenDetailCell
{
    private legalEntityIDLabel:MUILabel = null;
    private accountHolderIDLabel:MUILabel = null;
    private timeZoneLabel:MUILabel = null;
    private referenceTextField:MUITextField = null;
    private referenceLabel:MUILabel = null;
    private descriptionLabel:MUILabel = null;    
    private descriptionTextField:MUITextField = null;    

    awakeFromHTML()
    {
        super.awakeFromHTML();
        this.legalEntityIDLabel = MUIOutlet(this, "legal-entity-id-lbl", "MUILabel");
        this.accountHolderIDLabel = MUIOutlet(this, "account-holder-id-lbl", "MUILabel");
        this.timeZoneLabel = MUIOutlet(this, "time-zone-lbl", "MUILabel");
        this.referenceLabel = MUIOutlet(this, "reference-lbl", "MUILabel");
        this.referenceTextField = MUIOutlet(this, "reference-tf", "MUITextField");        
        this.descriptionLabel = MUIOutlet(this, "description-lbl", "MUILabel");
        this.descriptionTextField = MUIOutlet(this, "description-tf", "MUITextField");        
    }

    protected updateUI(): void 
    {
        this.legalEntityIDLabel.text = this._payment_entity.config != null ? this._payment_entity.config[ "LegalEntityID" ] : null;
        this.accountHolderIDLabel.text = this._payment_entity.config != null ? this._payment_entity.config[ "AccountHolderID" ] : null;
        this.timeZoneLabel.text = SettingsHelper.sharedInstance().config[ "timezone" ];
        this.referenceTextField.text = this._payment_config[ "AccountHolderReference" ];
        this.referenceTextField.setOnChangeText(this, function(this:AdyenAccountHolderDetailCell, control:MUITextField, value:string){
            this._payment_config[ "AccountHolderReference" ] = value;
        });
        this.referenceLabel.text = this._payment_config[ "AccountHolderReference" ];
        this.descriptionTextField.text = this._payment_config[ "AccountHolderDescription" ];
        this.descriptionTextField.setOnChangeText(this, function(this:AdyenAccountHolderDetailCell, control:MUITextField, value:string){
            this._payment_config[ "AccountHolderDescription" ] = value;
        });
        this.descriptionLabel.text = this._payment_config[ "AccountHolderDescription" ];

        let is_created = ( this._payment_entity.config != null && this._payment_entity.config[ "AccountHolderID" ] != null );
        this.ready = is_created;

        this.referenceTextField.hidden = is_created;
        this.referenceLabel.hidden = !is_created;
        this.descriptionTextField.hidden = is_created;
        this.descriptionLabel.hidden = !is_created;        
    }
}

class AdyenBuinessLineDetailCell extends AdyenDetailCell
{
    private businessLineIDLabel:MUILabel = null;
    private industryCodeTextField:MUITextField = null;
    private industryCodeLabel:MUILabel = null;
    private webTextField:MUITextField = null;
    private webLabel:MUILabel = null;
    private descriptionTextField:MUITextField = null;
    private descriptionLabel:MUILabel = null;

    awakeFromHTML()
    {
        super.awakeFromHTML();
        this.businessLineIDLabel = MUIOutlet(this, "business-line-id-lbl", "MUILabel");
        this.industryCodeTextField = MUIOutlet(this, "industry-code-tf", "MUITextField");
        this.industryCodeLabel = MUIOutlet(this, "industry-code-lbl", "MUILabel");
        this.webTextField = MUIOutlet(this, "web-tf", "MUITextField");        
        this.webLabel = MUIOutlet(this, "web-lbl", "MUILabel");
        this.descriptionTextField = MUIOutlet(this, "description-tf", "MUITextField");
        this.descriptionLabel = MUIOutlet(this, "description-lbl", "MUILabel");        
    }

    protected updateUI(): void {
        this.businessLineIDLabel.text = this._payment_config[ "BusinessLineID" ];

        this.industryCodeTextField.text = this._payment_config[ "BusinessLineIndustryCode" ];
        this.industryCodeTextField.setOnChangeText(this, function(this:AdyenBuinessLineDetailCell, control:MUITextField, value:string){
            this._payment_config[ "BusinessLineIndustryCode" ] = value;
        });
        this.industryCodeLabel.text = this._payment_config[ "BusinessLineIndustryCode" ];                

        this.webTextField.text = this._payment_config[ "BusinessLineWeb" ];
        this.webTextField.setOnChangeText(this, function(this:AdyenBuinessLineDetailCell, control:MUITextField, value:string){
            this._payment_config[ "BusinessLineWeb" ] = value;
        });
        this.webLabel.text = this._payment_config[ "BusinessLineWeb" ];
        
        this.descriptionTextField.text = this._payment_config[ "BusinessLineDescription" ];
        this.descriptionTextField.setOnChangeText(this, function(this:AdyenBuinessLineDetailCell, control:MUITextField, value:string){
            this._payment_config[ "BusinessLineDescription" ] = value;
        });
        this.descriptionLabel.text = this._payment_config[ "BusinessLineDescription" ];

        let is_created = ( this._payment_config[ "BusinessLineID" ] != null );
        this.ready = is_created;        

        this.industryCodeTextField.hidden = is_created;
        this.industryCodeLabel.hidden = !is_created;
        this.webTextField.hidden = is_created;
        this.webLabel.hidden = !is_created;
        this.descriptionTextField.hidden = is_created;
        this.descriptionLabel.hidden = !is_created;
    }
}

class AdyenBalanceDetailCell extends AdyenDetailCell
{
    private balanceIDLabel:MUILabel = null;
    private referenceTextField:MUITextField = null;
    private referenceLabel:MUILabel = null;
    private descriptionTextField:MUITextField = null;
    private descriptionLabel:MUILabel = null;

    awakeFromHTML()
    {
        super.awakeFromHTML();
        this.balanceIDLabel = MUIOutlet(this, "balance-id-lbl", "MUILabel");
        this.referenceTextField = MUIOutlet(this, "reference-tf", "MUITextField");
        this.referenceLabel = MUIOutlet(this, "reference-lbl", "MUILabel");        
        this.descriptionTextField = MUIOutlet(this, "description-tf", "MUITextField");
        this.descriptionLabel = MUIOutlet(this, "description-lbl", "MUILabel");        
    }

    protected updateUI(): void {
        this.balanceIDLabel.text = this._payment_config[ "BalanceAccountID" ];

        this.referenceTextField.text = this._payment_config[ "BalanceAccountReference" ];
        this.referenceTextField.setOnChangeText(this, function(this:AdyenBalanceDetailCell, control:MUITextField, value:string){
            this._payment_config[ "BalanceAccountReference" ] = value;
        });
        this.referenceLabel.text = this._payment_config[ "BalanceAccountReference" ];

        this.descriptionTextField.text = this._payment_config[ "BalanceAccountDescription" ];
        this.descriptionTextField.setOnChangeText(this, function(this:AdyenBalanceDetailCell, control:MUITextField, value:string){
            this._payment_config[ "BalanceAccountDescription" ] = value;
        });
        this.descriptionLabel.text = this._payment_config[ "BalanceAccountDescription" ];

        let is_created = ( this._payment_config[ "BalanceAccountID" ] != null );
        this.ready = is_created;

        this.referenceTextField.hidden = is_created;
        this.referenceLabel.hidden = !is_created;
        this.descriptionTextField.hidden = is_created;
        this.descriptionLabel.hidden = !is_created;
    }
}

enum StoreType
{
    ECOM,
    POS
}

class AdyenStoreDetailCell extends AdyenDetailCell
{    
    type: StoreType;

    private storeIDLabel:MUILabel = null;
    private storeTypeLabel:MUILabel = null;
    private referenceTextField:MUITextField = null;
    private referenceLabel:MUILabel = null;
    private descriptionTextField:MUITextField = null;
    private descriptionLabel:MUILabel = null;
    private statementTextField:MUITextField = null;
    private statementLabel:MUILabel = null;    

    awakeFromHTML()
    {
        super.awakeFromHTML();
        this.storeIDLabel = MUIOutlet(this, "store-id-lbl", "MUILabel");
        this.storeTypeLabel = MUIOutlet(this, "store-type-lbl", "MUILabel");
        this.referenceTextField = MUIOutlet(this, "reference-tf", "MUITextField");
        this.referenceLabel = MUIOutlet(this, "reference-lbl", "MUILabel");
        this.descriptionTextField = MUIOutlet(this, "description-tf", "MUITextField");
        this.descriptionLabel = MUIOutlet(this, "description-lbl", "MUILabel");
        this.statementTextField = MUIOutlet(this, "statement-tf", "MUITextField");
        this.statementLabel = MUIOutlet(this, "statement-lbl", "MUILabel");
    }

    protected updateUI(): void {
        let store_id = this.type == StoreType.ECOM ? this._payment_config[ "ECOMStoreID" ] : this._payment_config[ "POSStoreID" ]

        this.storeIDLabel.text = store_id;
        this.storeTypeLabel.text = this.type == StoreType.ECOM ? "E-Commerce" : "Point of Sale";
        this.referenceTextField.text = this.type == StoreType.ECOM ? this._payment_config[ "ECOMStoreReference" ] : this._payment_config[ "POSStoreReference" ];
        this.referenceTextField.setOnChangeText(this, function(this:AdyenStoreDetailCell, control:MUITextField, value:string){
            if ( this.type == StoreType.ECOM ) this._payment_config[ "ECOMStoreReference" ] = value;
            else this._payment_config[ "POSStoreReference" ] = value;
        });
        this.referenceLabel.text = this.type == StoreType.ECOM ? this._payment_config[ "ECOMStoreReference" ] : this._payment_config[ "POSStoreReference" ];

        this.descriptionTextField.text = this.type == StoreType.ECOM ? this._payment_config[ "ECOMStoreDescription" ] : this._payment_config[ "POSStoreDescription" ];
        this.descriptionTextField.setOnChangeText(this, function(this:AdyenStoreDetailCell, control:MUITextField, value:string){
            if ( this.type == StoreType.ECOM ) this._payment_config[ "ECOMStoreDescription" ] = value;
            else this._payment_config[ "POSStoreDescription" ] = value;
        });
        this.descriptionLabel.text = this.type == StoreType.ECOM ? this._payment_config[ "ECOMStoreDescription" ] : this._payment_config[ "POSStoreDescription" ];

        this.statementTextField.text = this._payment_config[ "StoreStatement" ];
        this.statementLabel.text = this._payment_config[ "StoreStatement" ];
        
        let is_created = ( store_id != null );
        this.ready = is_created;

        this.referenceTextField.hidden = is_created;
        this.referenceLabel.hidden = !is_created;
        this.descriptionTextField.hidden = is_created;
        this.descriptionLabel.hidden = !is_created;
        this.statementTextField.hidden = is_created;
        this.statementLabel.hidden = !is_created;
    }
}

class AdyenActionsDetailCell extends AdyenDetailCell
{
    private signUpButton:MUIButton = null;    
    private reviewLinkButton:MUIButton = null;    


    awakeFromHTML(): void {
        this.reviewLinkButton = MUIOutlet(this, "review-link-btn", "MUIButton");
        this.reviewLinkButton.setAction(this, this.reviewLinkAction);

        this.signUpButton = MUIOutlet(this, "sign-up-btn", "MUIButton");
        this.signUpButton.setAction(this, this.signUp);
    }

    protected updateUI(): void {
/*        if (this._payment_config["ECOMStoreID"] != null && this._payment_config["POSStoreID"]) {
            this.signUpButton.enabled = false;
            this.reviewLinkButton.enabled = true;
        }
        else {
            this.signUpButton.enabled = true;
            this.reviewLinkButton.enabled = false;
        }*/
    }

    private signUp(){
        this.signUpButton.enabled = false;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this._payment_entity.config = this._payment_config;
        DBHelper.saveMainContextWithCompletion( this, function(this:AdyenActionsDetailCell, error){
            ad.webService.adyenSignUp( this._payment_config, (error) => {
                this.signUpButton.enabled = true;
                if (error != null) {
                    AppHelper.sharedInstance().showError(error);
                    return;
                }

                this.signUpButton.enabled = false;
                // this.reviewLinkButton.enabled = true;
            });
        });
    }

    private reviewLinkAction()
    {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.adyenHOPLink( (json, error) => {
            const url = json["url"];
            // let vc = new AdyenHOPLinkViewController();
            // vc.init();
            // vc.url = url;
            // AppHelper.sharedInstance().presentViewController( vc, true );
            window.open(url,'_blank');
        });
    }
}