

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class AdyenPaymentEntityPaymethod_ManagedObject

class AdyenPaymentEntityPaymethod_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: creationOrderIndex
    set creationOrderIndex(value:number) {
        this.setValueForKey(value, 'creationOrderIndex');
    }
    get creationOrderIndex():number {
        return this.valueForKey('creationOrderIndex');
    }
    set creationOrderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'creationOrderIndex');
    }
    get creationOrderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('creationOrderIndex');
    }

    // Property: enabled
    set enabled(value:boolean) {
        this.setValueForKey(value, 'enabled');
    }
    get enabled():boolean {
        return this.valueForKey('enabled');
    }
    set enabledPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'enabled');
    }
    get enabledPrimitiveValue():boolean {
        return this.primitiveValueForKey('enabled');
    }

    // Property: reference
    set reference(value:string) {
        this.setValueForKey(value, 'reference');
    }
    get reference():string {
        return this.valueForKey('reference');
    }
    set referencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'reference');
    }
    get referencePrimitiveValue():string {
        return this.primitiveValueForKey('reference');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: type
    set type(value:string) {
        this.setValueForKey(value, 'type');
    }
    get type():string {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():string {
        return this.primitiveValueForKey('type');
    }
    // Relationship: store
    set store(value:AdyenPaymentEntityStore) {
        this.setValueForKey(value, 'store');
    }
    get store():AdyenPaymentEntityStore {
        return this.valueForKey('store') as AdyenPaymentEntityStore;
    }
}
