//
// Generated class ClientAction
//

/// <reference path="ClientAction_ManagedObject.ts" />

class ClientAction extends ClientAction_ManagedObject
{

}
