


class MenuListViewController extends EntityListViewController
{
    static newInstance() : MenuListViewController
    {
        let vc = new MenuListViewController("menus-list-view");
        vc.initWithResource("layout/menus/MenusListView.html");
        return vc;
    }

    parentCategoryID = null;
    parentCategoryName = null;
    category:ProductCategory = null;    
    
    private addButton:MUIButton = null;
    private backButton:MUIButton = null;
    private titleLabel:MUILabel = null;      
        
    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, 'back-btn', 'MUIButton');
        this.backButton.setAction(this, function (){
            this.navigationController.popViewController(true);
        });

        this.titleLabel = MUIOutlet(this, 'title-lbl', 'MUILabel');
        
        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function (){
            //add Menu
        });

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.tableView = MUIOutlet(this,"table-view","UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        

        MIONotificationCenter.defaultCenter().addObserver(this, "workspaceDidChange", function (notification) {
            this.navigationController.popToRootViewController(false);
        });                
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.backButton.setHidden(true);
        // this.sectionsButton.setHidden(this.parentCategoryID ? true : false);
        // this.titleLabel.setHidden(this.parentCategoryID == null ? true : false);
        // if(this.parentCategoryName) this.titleLabel.text = this.parentCategoryName;
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        
        let cell:MenuListCell = tableview.dequeueReusableCellWithIdentifier("MenuListCell") as MenuListCell;
        let item:ProductModifierCategory = this.fetchedResultsController.objectAtIndexPath(indexPath) as ProductModifierCategory;        
        
        cell.item = item;            
        cell.selected = (this.selectedItem != null && this.selectedItem.identifier == item.identifier);
        
        return cell;
    }
    
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath);
        this.showItemDetail(selectedItem);
    }

    private showItemDetail(item:Menu){     
        if (item == null) return;
        
        let vc = MenuDetailViewController.newInstance();
        vc.item = item;

        this.navigationController.splitViewController.showDetailViewController(vc);
    }

    private showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Product modifiers", NoItemSelectedViewControllerType.ProductModifiers);
        this.navigationController.splitViewController.showDetailViewController(vc);
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
        
        let predicate_format = this.searchString?.length > 0 ? "name CONTAINS '"+ this.searchString + "'" : null;
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true), MIOSortDescriptor.sortDescriptorWithKey("orderIndex", true)]; 

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Menu", sortDescriptors, predicate_format);
        // fetchRequest.relationshipKeyPathsForPrefetching = ['tax'];                

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;        
    }
}

