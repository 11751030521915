/**
 * Created by miogds on 7/11/21.
 */

 class DistributorListCell extends UITableViewCell
 {
     private nameLabel:MUILabel = null;
     private accountLabel:MUILabel = null;
     private debtLabel:MUILabel = null;        
 
     awakeFromHTML(){
         this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
         this.accountLabel = MUIOutlet(this, "account-lbl", "MUILabel");
         this.debtLabel = MUIOutlet(this, 'debt-lbl','MUILabel'); 
     }
 
     set item(item:Distributor){
         this.nameLabel.text = item.name;                      
         
         let cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter; 
        //  this.accountLabel.text = item.accountBalance != null ? cf.stringFromNumber(item.accountBalance.amount) : cf.stringFromNumber(0);        
        //  this.debtLabel.text = (item.debtBalance != null && item.debtBalance.amount > 0) ? cf.stringFromNumber(item.debtBalance.amount) : null;
     }
 
 }