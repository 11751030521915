//
// Generated class MenuLine
//

/// <reference path="MenuLine_ManagedObject.ts" />

class MenuLine extends MenuLine_ManagedObject
{

}
