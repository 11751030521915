


/// <reference path="../Attribute/AttributeViewController.ts" />


class AttributeTypeViewController extends MUIViewController
{
    private _fetchedResultsController:MIOFetchedResultsController = null;
    private tableView:MUITableView = null;
    private searchTextField:MUITextField = null;
    private searchString:string = null;
    private titleLabel:MUILabel = null;
    private closeButton:MUIButton = null;
    private addButton:MUIButton = null;

    private avc:AttributeViewController = null;

    get preferredContentSize()
    {
        return new MIOSize(320, 500);
    }

    viewDidLoad()
    {
        super.viewDidLoad();

        this.tableView = MUIOutlet(this,"atv_c1_tableview","MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
        this.tableView.reloadData();
        this.titleLabel = MUIOutlet(this,"atv_nb_title_lbl","MUILabel");
        this.titleLabel.text = MIOLocalizeString("ATTRIBUTE TYPE", "Attribute type");

        this.closeButton = MUIOutlet(this, 'atv_nb_close_btn', 'MUIButton');
        this.closeButton.setAction(this, function () {

            this.navigationController.dismissViewController(true);

        });

        this.addButton = MUIOutlet(this, 'atv_nb_add_btn', 'MUIButton');
        this.addButton.setAction(this, function () {

            this.addAttributeType();

        });

        this.searchTextField = MUIOutlet(this,"atv_tv_search_bar", "MUITextField", "SearchType");
        this.searchTextField.setOnChangeText(this, function(textfield, value){

            clearTimeout(this.delayTimer);
            var instance = this;
            
            instance.delayTimer = setTimeout(function() {
              
                instance.searchString = instance.searchTextField.text;
                if(instance.searchString  != null && instance.searchString.length == 0)
                {
                    instance.searchString = null;
                }
                instance.fetchedResultsController = null;
                instance.tableView.reloadData();
               
            }, 500, instance);
        });
    }


    viewWillAppear(animate ?)
    {
        super.viewWillAppear(animate);
        this.closeButton.setHidden(true);
        this.tableView.reloadData();
    }
    
    private addAttributeType()
    {
        var avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('NEW ATTRIBUTE TYPE','NEW ATTRIBUTE TYPE'), MIOLocalizeString('CHOOSE A NAME','Choose a name '), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){

            textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD','ADD'), MUIAlertActionStyle.Default, this, function()
        {
            let nameTF =  avc.textFields[0];
            let name = nameTF.text.trim();

            if(name.length > 0)
            {
                let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
                var at:ProductAttributeType = MIOEntityDescription.insertNewObjectForEntityForName("ProductAttributeType", moc) as ProductAttributeType;
                at.identifier = MIOUUID.UUID().UUIDString;
                at.name = name;
                at.orderIndex = 1;
                moc.save();
                //this.attribute = at;
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'Error'), MIOLocalizeString('CHECK NAME','Check name.'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CLOSE','CLOSE'), MUIAlertActionStyle.Cancel, this, function(){}));
                
                this.presentViewController(error, true);
            }
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, this, function(){}));
        
        this.presentViewController(avc, true);
    }

    private removeAttributeType(at)
    {
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        moc.deleteObject(at);
        moc.save();
    }

    numberOfSections(tableview)
    {
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview, section)
    {
        var sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath)
    {
        var cell:AttributeTypeCell = tableview.dequeueReusableCellWithIdentifier("AttributeTypeCell");

        var item = this.fetchedResultsController.objectAtIndexPath(indexPath);

        cell.item = item;
        cell.editing = true;
        cell.setAccessoryType(MUITableViewCellAccessoryType.DetailDisclosureButton);
        
       //( cell.setAccessoryType(MUITableViewCellAccessoryType.Detail);

        return cell;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath)
    {

        var attributetype = this.fetchedResultsController.objectAtIndexPath(indexPath);

        if(this.avc == null) {
            this.avc = new AttributeViewController("attribute_view");
            this.avc.initWithResource("layout/modals/attribute/AttributeView.html");
        }

        this.avc.attributeType = attributetype;
        this.navigationController.pushViewController(this.avc, true);
    }

    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        
        var at = this.fetchedResultsController.objectAtIndexPath(indexPath);    

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            this.removeAttributeType(at);
        }
    }

    set fetchedResultsController(value)
    {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController()
    {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        var ad = MUIWebApplication.sharedInstance().delegate;

        var fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("ProductAttributeType");
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

        if (this.searchString != null)
            fetchRequest.predicate = MIOPredicate.predicateWithFormat("name CONTAINS \"" + this.searchString + "\"");

        var fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "name");
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;

        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller)
    {
        this.tableView.reloadData();
    }
}