

/// <reference path="SalesInvoice.ts" />

// Generated class LicenseInvoice_ManagedObject

class LicenseInvoice_ManagedObject extends SalesInvoice
{

    // Property: businessName
    set businessName(value:string) {
        this.setValueForKey(value, 'businessName');
    }
    get businessName():string {
        return this.valueForKey('businessName');
    }
    set businessNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'businessName');
    }
    get businessNamePrimitiveValue():string {
        return this.primitiveValueForKey('businessName');
    }

    // Property: distributorName
    set distributorName(value:string) {
        this.setValueForKey(value, 'distributorName');
    }
    get distributorName():string {
        return this.valueForKey('distributorName');
    }
    set distributorNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'distributorName');
    }
    get distributorNamePrimitiveValue():string {
        return this.primitiveValueForKey('distributorName');
    }

    // Property: totalDistributorFeeAmount
    set totalDistributorFeeAmount(value:number) {
        this.setValueForKey(value, 'totalDistributorFeeAmount');
    }
    get totalDistributorFeeAmount():number {
        return this.valueForKey('totalDistributorFeeAmount');
    }
    set totalDistributorFeeAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalDistributorFeeAmount');
    }
    get totalDistributorFeeAmountPrimitiveValue():number {
        return this.primitiveValueForKey('totalDistributorFeeAmount');
    }
    // Relationship: business
    set business(value:BusinessLicense) {
        this.setValueForKey(value, 'business');
    }
    get business():BusinessLicense {
        return this.valueForKey('business') as BusinessLicense;
    }
    // Relationship: distributor
    set distributor(value:Distributor) {
        this.setValueForKey(value, 'distributor');
    }
    get distributor():Distributor {
        return this.valueForKey('distributor') as Distributor;
    }
}
