

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Channel_ManagedObject

class Channel_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Relationship: timeRangePresetConfigurations
    protected _timeRangePresetConfigurations:MIOManagedObjectSet = null;
    get timeRangePresetConfigurations():MIOManagedObjectSet {
        return this.valueForKey('timeRangePresetConfigurations');
    }
    addTimeRangePresetConfigurationsObject(value:TimeRangePresetConfiguration) {
        this._addObjectForKey(value, 'timeRangePresetConfigurations');
    }
    removeTimeRangePresetConfigurationsObject(value:TimeRangePresetConfiguration) {
        this._removeObjectForKey(value, 'timeRangePresetConfigurations');
    }
}
