//
// Generated class CoreEntity
//

/// <reference path="CoreEntity_ManagedObject.ts" />

class CoreEntity extends CoreEntity_ManagedObject
{

}
