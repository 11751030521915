

class ProductListViewController extends BaseTableViewController
{
    static newInstance() : ProductListViewController { 
        let vc = new ProductListViewController("products-list-view");
        vc.initWithResource("layout/products/ProductsListView.html");
        return vc;
    }

    parentCategory:ProductCategory | StockCategory = null;
    productType = ProductType.Sales;

    private backButton:MUIButton = null;
    private titleLabel:MUILabel = null;
    private segmentedControl:MUISegmentedControl = null;
    private massiveButton:MUIButton = null;
    private addButton:MUIButton = null;    
    
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;

    viewDidLoad(){
        super.viewDidLoad();
        
        this.backButton = MUIOutlet(this, 'back-btn', 'MUIButton');
        this.backButton.setAction(this, function (){
            this.navigationController.popViewController(true);
        });

        this.titleLabel = MUIOutlet(this, 'title-lbl', 'MUILabel');
        this.titleLabel.hidden = true;

        this.segmentedControl = MUIOutlet(this, "segmented-control", "MUISegmentedControl");
        this.segmentedControl.hidden = true;
        this.segmentedControl.setAction(this, function(control, index){
            MIOUserDefaults.standardUserDefaults().removeValueForKey("LastProductDetailSalesPageIndex");
            this.productType = index + 1;
            this.invalidateData();
        });

        this.massiveButton = MUIOutlet(this, "massive-btn", "MUIButton");
        this.massiveButton.setAction(this, this.massiveOperationAction);

        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function (this:ProductListViewController){
            this.showOptionsAlertView();
        });

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;          

        // MIONotificationCenter.defaultCenter().addObserver(this, "WorkspaceDidChange", function (notification) {
        //     this.navigationController.popToRootViewController(false);
        // });

        // MIONotificationCenter.defaultCenter().addObserver(this, 'ProductDidDeleted', function (this:ProductListViewController, notification:MIONotification) {
        //     let item = notification.object as Product;
        //     if (this.selectedItem == item) {
        //         this.selectedItem = null;
        //         this.showNoItemSelected();
        //     }

        //     // if (this.selectedItem == item.itemID) {                
        //     //     DBHelper.deleteObjectFromMainContext(item, true);
        //     //     this.selectedItem = null;
        //     //     this.selectedProductItem = null;                
        //     //     this.showNoItemSelected();
        //     //     this.invalidateData();
        //     // }
        // });
        
        // MIONotificationCenter.defaultCenter().addObserver(this, 'ItemDidChange', function (notification) {
        //     this.invalidateData();
        // });    
        
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        
        this.sectionsButton.setHidden(this.parentCategory ? true : false);
        this.backButton.setHidden(this.parentCategory ? false : true);
        this.segmentedControl.setHidden(this.parentCategory == null ? false : true);
        this.titleLabel.setHidden(this.parentCategory == null ? true : false);

        this.titleLabel.text = this.parentCategory?.name;

        this.invalidateFetch();
        
        MIONotificationCenter.defaultCenter().addObserver(this, "WorkspaceDidChange", function (notification) {
            this.navigationController.popToRootViewController(false);
        });

        MIONotificationCenter.defaultCenter().addObserver(this, 'ProductDidDeleted', function (this:ProductListViewController, notification:MIONotification) {
            let item = notification.object as Product;
            if (this.selectedItem == item) {
                this.selectedItem = null;
                this.showNoItemSelected();
            }
        });
    }

    viewWillDisappear(animated?: boolean): void {
        super.viewWillDisappear(animated);

        MIONotificationCenter.defaultCenter().removeObserver(this, "WorkspaceDidChange");
        MIONotificationCenter.defaultCenter().removeObserver(this, "ProductDidDeleted");
    }

    numberOfSections(tableView:UITableView){
        return 1;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        return this.listItems.count;
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("ProductCell") as ProductCell;

        cell.productType = this.productType;

        let item = this.listItems[indexPath.row] as MIOManagedObject;
        cell.item = item;

        cell.accessoryType = (item instanceof ProductCategory || item instanceof StockCategory) ? UITableViewCellAccessoryType.DisclosureIndicator : UITableViewCellAccessoryType.None;
        cell.selected = (this.selectedItem == item);
        
        return cell;
    }
        
    private selectedProductItem:ProductListItem = null;
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let item = this.listItems[indexPath.row];
        this.selectedItem = item;

        this.showItemDetail(this.selectedItem);
    }

    private showItemDetail(item:MIOManagedObject){
        if (item == null) return;

        if (item instanceof ProductCategory || item instanceof StockCategory) {
            let vc = ProductListViewController.newInstance();
            vc.parentCategory = item;
            vc.productType = this.productType;
                        
            this.navigationController.pushViewController(vc, true);
        }
        else if (item instanceof Product) {
            let vc = ProductDetailViewController.newInstance();
            vc.productType = this.productType;
            vc.item = item;

            this.navigationController.splitViewController.showDetailViewController(vc);
        }
    }

    private showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Product", NoItemSelectedViewControllerType.Product);
        this.navigationController.splitViewController.showDetailViewController(vc);
    }

    private resetFetchedResultsControllers(){
        if (this._productFetchedResultsController != null ) this._productFetchedResultsController.delegate = null;
        if (this._salesCategoryFetchedResultsController != null) this._salesCategoryFetchedResultsController.delegate = null;
        if (this._stockCategoryFetchedResultsController != null) this._stockCategoryFetchedResultsController.delegate = null;

        this._productFetchedResultsController = null;
        this._salesCategoryFetchedResultsController = null;
        this._stockCategoryFetchedResultsController = null;
    }

    private _productFetchedResultsController: MIOFetchedResultsController = null;
    get productFetchedResultsController() {
        if (this._productFetchedResultsController != null) return this._productFetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
        
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("Product");        
        fetchRequest.relationshipKeyPathsForPrefetching = [
        //                                                     'category',
        //                                                     "stockCategory",
        //                                                     'source',
        //                                                     'productPlace',
        //                                                     // 'productRates',
        //                                                     'defaultProductFormat.format',
                                                            "image",
        //                                                     "costBalance",
                                                        ];
        let predicate = ["deletedAt = null"];

        if (this.searchString == null){            
            if (this.productType == ProductType.Sales){
                predicate.push("category.identifier == " + this.parentCategory.identifier);
            }                
            else if (this.productType == ProductType.Stock){                
                predicate.push("stockCategory.identifier == " + this.parentCategory.identifier);
            }
            else {
                predicate.push("category == null");
                predicate.push("stockCategory == null");
            }            
            fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        }
        else {
            predicate.push("(name CONTAINS \"" + this.searchString + "\" OR stockName CONTAINS \"" + this.searchString + "\")");
            fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        }
        
        if(predicate.length > 0)
            fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicate.join(' and '));

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._productFetchedResultsController = fetchedResultsController;
        return this._productFetchedResultsController;        
    }

    private _salesCategoryFetchedResultsController: MIOFetchedResultsController = null;
    get salesCategoryFetchedResultsController() {
        if (this._salesCategoryFetchedResultsController != null) return this._salesCategoryFetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
        
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("ProductCategory");
        fetchRequest.relationshipKeyPathsForPrefetching = [ "image" ];

        let predicate = ["deletedAt = null"];

        if (this.searchString == null){            
            if (this.productType == ProductType.Sales || this.productType == ProductType.Stock){
                predicate.push("superCategory.identifier == " + (this.parentCategory ? this.parentCategory.identifier : "null"));
            }                
            else {
                predicate.push("category == null");
                predicate.push("stockCategory == null");
            }            
            fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        }
        else {
            predicate.push("(name CONTAINS \"" + this.searchString + "\"");
            fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        }
        
        if(predicate.length > 0)
            fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicate.join(' and '));

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._salesCategoryFetchedResultsController = fetchedResultsController;
        return this._salesCategoryFetchedResultsController;        
    }

    private _stockCategoryFetchedResultsController: MIOFetchedResultsController = null;
    get stockCategoryFetchedResultsController() {
        if (this._stockCategoryFetchedResultsController != null) return this._stockCategoryFetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;
        
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("StockCategory");
        fetchRequest.relationshipKeyPathsForPrefetching = [ "image"];

        let predicate = ["deletedAt = null"];

        if (this.searchString == null){            
            if (this.productType == ProductType.Sales || this.productType == ProductType.Stock){
                predicate.push("superCategory.identifier == " + (this.parentCategory ? this.parentCategory.identifier : "null"));
            }                
            else {
                predicate.push("category == null");
                predicate.push("stockCategory == null");
            }            
            fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        }
        else {
            predicate.push("(name CONTAINS \"" + this.searchString + "\"");
            fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        }
        
        if(predicate.length > 0)
            fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicate.join(' and '));

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._stockCategoryFetchedResultsController = fetchedResultsController;
        return this._stockCategoryFetchedResultsController;        
    }

    // private showDetail = false;
    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        // super.controllerDidChangeContent(controller);
        // if (this.showDetail == true) this.showItemDetail(this.selectedProductItem);
        // this.showDetail = false;
        this.mapListItems();
    }
    
    protected invalidateFetch() {
        this.resetFetchedResultsControllers();
        this.mapListItems();
    }
    
    private listItems = [];
    private listItemsSet = new MIOSet();
    private mapListItems() {
        this.listItems = [];
        this.listItemsSet = new MIOSet();

        if (this.productType == ProductType.Sales) {
            let objs = this.salesCategoryFetchedResultsController.fetchedObjects;
            for (let o of objs) this.listItemsSet.addObject(o);
            
            if (this.parentCategory != null || this.searchString != null) {
                objs = this.productFetchedResultsController.fetchedObjects;
                for (let o of objs) this.listItemsSet.addObject(o);
            }            
        }        
        else if (this.productType == ProductType.Stock) {
            let objs = this.stockCategoryFetchedResultsController.fetchedObjects;
            for (let o of objs) this.listItemsSet.addObject(o);

            if (this.parentCategory != null || this.searchString != null) {
            objs = this.productFetchedResultsController.fetchedObjects;
                for (let o of objs) this.listItemsSet.addObject(o);
            }
        }    
        else {
            let objs = this.productFetchedResultsController.fetchedObjects;
            for (let o of objs) this.listItemsSet.addObject(o);
        }

        this.listItems = _MIOSortDescriptorSortObjects(this.listItemsSet.allObjects, [MIOSortDescriptor.sortDescriptorWithKey("name", true)]);
        this.tableView.reloadData();
    }
    
    private showOptionsAlertView(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("CHOOSE THE TYPE","CHOOSE THE TYPE"), MIOLocalizeString("SELECT THE TYPE TO CREATE A CATEGORY OR PRODUCT", "SELECT THE TYPE TO CREATE A CATEGORY OR PRODUCT"), MUIAlertViewStyle.Default);

		let title = this.productType == ProductType.Stock ? MIOLocalizeString("STOCK CATEGORY", "STOCK CATEGORY") : MIOLocalizeString("CATEGORY","CATEGORY");
        avc.addAction(MUIAlertAction.alertActionWithTitle(title, MUIAlertActionStyle.Default, this, function(this:ProductListViewController){                        
            this.showAddCategory();
        }));

        if (this.parentCategory != null) {
            avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("PRODUCT", "PRODUCT"), MUIAlertActionStyle.Default, this, function(this:ProductListViewController){
                this.showAddProductAlert();
            }));

            if ( ( MUIWebApplication.sharedInstance().delegate as AppDelegate ).dlOptionEnabled ) {
                avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("LICENSE", "LICENSE"), MUIAlertActionStyle.Default, this, function(this:ProductListViewController){
                    this.showAddProductAlert( "LicenseProduct" );
                }));    
            }

        }

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private showAddCategory(){

        if (this.productType == ProductType.Sales) {
            AppHelper.sharedInstance().showAddProductCategory(this.parentCategory as ProductCategory, null);
        }
        else {

            let avc = new MUIAlertViewController();
            avc.initWithTitle(MIOLocalizeString('ADD CATEGORY NAME',"ADD CATEGORY NAME"), MIOLocalizeString('CREATE A STOCK CATEGORY',"CREATE A STOCK CATEGORY"), MUIAlertViewStyle.Default);    

            avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
                textField.setPlaceholderText(MIOLocalizeString("NAME", "NAME"));
            });

            avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Default, this, function(this:ProductListViewController){                        
                let name = avc.textFields[0].text;
                DBHelper.createStockCategory(name, this.parentCategory as StockCategory);
            }));

            avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));
            this.presentViewController(avc, true);    
        }
    }

    private showAddProductAlert(classname:string = "Product"){

        if (this.productType == ProductType.Sales) {
            AppHelper.sharedInstance().showAddSalesProductAlertViewController(null, this.parentCategory as ProductCategory, classname, (product:Product) =>{ this.selectProduct(null, product); } );
        }
        else if (this.productType == ProductType.Stock){
            AppHelper.sharedInstance().showAddStockProductAlertViewController(null, this.parentCategory as StockCategory, this, this.selectProduct );
        }
    }

    private selectProduct(controller:any, product:Product) {
        if (product == null) return;
        this.selectedItem = product;
        let index = this.listItems.indexOfObject(product);
        if (index > -1) {
            let cell = this.tableView.cellAtIndexPath(MIOIndexPath.indexForRowInSection(index, 0));
            cell.selected = true;
            this.showItemDetail(this.selectedItem);
        }
    }

    // private massiveOperationAction(){
    //     let avc = new MUIAlertViewController();
    //     avc.initWithTitle(MIOLocalizeString("MASSIVE OPERATION", "MASSIVE OPERATION"), MIOLocalizeString("SELECT THE OPERATION","SELECT THE OPERATION"), MUIAlertViewStyle.Default);

    //     avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("COST TYPE", "COST TYPE"), MUIAlertActionStyle.Default, this, function(){
    //         this.changeCostType();
    //     }));

    //     avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

    //     this.presentViewController(avc, true);
    // }

    private massiveOperationAction() {
        AppHelper.sharedInstance().showGlobalActionsViewControllerFromView(this, this, null);            
    }

    private changeCostType(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("MASSIVE OPERATION", "MASSIVE OPERATION"), MIOLocalizeString("SELECT COST TYPE TO APPLY", "SELECT COST TYPE TO APPLY"), MUIAlertViewStyle.Default);

        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
            comboBox.removeAllItems();
            comboBox.addItem(MIOLocalizeString("LAST PRICE", "LAST PRICE"), 0);
            comboBox.addItem(MIOLocalizeString("AVERAGE", "AVERAGE"), 1);
            comboBox.addItem(MIOLocalizeString("FIXED COST", "FIXED COST"), 2);
            comboBox.addItem(MIOLocalizeString("COMPONENTS", "COMPONENTS"), 3);
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK", "OK"), MUIAlertActionStyle.Default, this, function(){
            let type = avc.comboBoxes[0].getSelectedItem();
            this.applyCostType(parseInt(type));
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private applyCostType(costType:number){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        ad.webService.changeCostPriceMassively(costType, null, this, function(code, data){
            if (code == 200) {
                AppHelper.showInfoMessage(this, "OPERATION INFO", "OPERATION WAS SUCCESFULL");
                MIONotificationCenter.defaultCenter().postNotification("ProductReloadNotification", null);
            }
            else AppHelper.showErrorMessage(this, "OPERATION ERROR", "THE OPERATION COULDN'T EXECUTE. TRY AGAIN LATER");
        })
    }
}

