
class ProductModifiersDetailViewController extends MUIViewController 
{
    static newInstance() : ProductModifiersDetailViewController
    {
        let vc = new ProductModifiersDetailViewController("product-modifiers-detail-view");
        vc.initWithResource("layout/productmodifiers/ProductModifiersDetailView.html");
        return vc;
    }

    private saveButton:MUIButton = null;
    private settingsButton:MUIButton = null;
    private addButton:MUIButton = null;
    private nameTextfield:MUITextField = null;
    private languageButton:MUIButton = null;
    private segmentedControl:MUISegmentedControl = null;
    private categoryButton:MUIButton = null; 
    private multiSelectSwitch:MUISwitchButton = null;
    private requiredSwitch:MUISwitchButton = null;
    private incrementalSwitch:MUISwitchButton = null;
    private allowNegSwitch:MUISwitchButton = null;
    private ignoreModifierSourceSwitch:MUISwitchButton = null;
    private isAbsorbentSwitch:MUISwitchButton = null;
    private limitQuantityTF:MUITextField = null;
    private tableView:MUITableView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.saveButton = MUIOutlet(this, 'save-btn', 'MUIButton');
        this.saveButton.setAction(this, function (){            
            DBHelper.saveMainContext();
        });

        this.settingsButton = MUIOutlet(this, 'settings-btn', 'MUIButton');
        this.settingsButton.setAction(this, function (){            
            this.showSettingsPopup();
        }); 

        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function (this:ProductModifiersDetailViewController, control, index){
            if (this.productModifierCategory.productModifierCategoryType == ProductModifierCategoryType.Manual) {
                this.showAddManualProductModifier();
            } else if (this.productModifierCategory.productModifierCategoryType == ProductModifierCategoryType.Product) {
                this.showAddProductModifierFromProduct();
            }
        })

        this.nameTextfield = MUIOutlet(this, 'name-tf', 'MUITextField');
        this.nameTextfield.setOnChangeText(this, function(control, value:string){
            this.productModifierCategory.name = value;
        })

        this.languageButton = MUIOutlet(this, "language-btn", "MUIButton");
        this.languageButton.setAction(this, function(){
            let vc = ProductLanguageViewController.newInstance();
            vc.setItem(this.productModifierCategory, "name");
            this.presentViewController( vc, true );
        });

        this.segmentedControl = MUIOutlet(this, 'segmented-control', 'MUISegmentedControl');
        this.segmentedControl.setAction(this, function (this:ProductModifiersDetailViewController, control:MUISegmentedControl, index:number){            
            this.showChangeProductCategoryType(index);            
        });

        this.categoryButton = MUIOutlet(this, 'category-dd', 'MUIButton');
        this.categoryButton.setAction(this, function() {

            if(this.fetchedResultsController.fetchedObjects.length == 0 ){ //if there are NO modifiers
                this.showCategorySelectEntityViewController(this.categoryButton, "ProductCategory");                
            } else {
                let avc = new MUIAlertViewController();
                avc.initWithTitle(MIOLocalizeString('NOTICE',"NOTICE"), MIOLocalizeString('CURRENT MODIFIERS WILL BE RESET IF YOU CHANGE CATEGORY',"CURRENT MODIFIERS WILL BE RESET IF YOU CHANGE CATEGORY"), MUIAlertViewStyle.Default);    
                avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK',"OK"), MUIAlertActionStyle.Default, this, function(){                        
                    //remove what is already there
                    this.removeAllProductModifiers();
                    this.showCategorySelectEntityViewController(this.categoryButton, "ProductCategory");
                }));
                avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL',"CANCEL"), MUIAlertActionStyle.Cancel, null, null));
                this.presentViewController(avc, true); 
            }
        });

        this.multiSelectSwitch = MUIOutlet(this, "multi-select-switch", "MUISwitchButton");
        this.multiSelectSwitch.setOnChangeValue(this, function(control, value:boolean){
            this.productModifierCategory.multipleSelection = value;
        });

        this.requiredSwitch = MUIOutlet(this, "required-switch", "MUISwitchButton");
        this.requiredSwitch.setOnChangeValue(this, function(control, value:boolean){
            this.productModifierCategory.isRequired = value;
        });

        this.incrementalSwitch = MUIOutlet(this, "incremental-switch", "MUISwitchButton");
        this.incrementalSwitch.setOnChangeValue(this, function(control, value:boolean){
            this.productModifierCategory.incremental = value;
        });

        this.allowNegSwitch = MUIOutlet(this, "allow-negative-switch", "MUISwitchButton");
        this.allowNegSwitch.setOnChangeValue(this, function(control, value:boolean){
            this.productModifierCategory.allowNegatives = value;
        });

        this.ignoreModifierSourceSwitch = MUIOutlet(this, 'ignore-destination-switch', 'MUISwitchButton');
        this.ignoreModifierSourceSwitch.setOnChangeValue(this, function(control, value:boolean){
            this.productModifierCategory.ignoreModifierSource = value;
        })
        
        this.isAbsorbentSwitch = MUIOutlet(this, 'absorbent-switch', 'MUISwitchButton');
        this.isAbsorbentSwitch.setOnChangeValue(this, function(control, value:boolean){
            this.productModifierCategory.isAbsorbent = value;
        })

        this.limitQuantityTF = MUIOutlet(this, 'limit-TF', 'MUITextField');
        this.limitQuantityTF.setOnChangeText(this, function(control, value:number){
            this.productModifierCategory.limitQuantity = value;
        })
        
        this.tableView = MUIOutlet(this,"table-view","UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;                
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private productModifierCategory:ProductModifierCategory = null;
    set item(item:ProductModifierCategory){        
        this.productModifierCategory = item;
        this.updateUI();
    }   

    private updateUI(){
        if (this.productModifierCategory == null) return;
        if (this.viewIsLoaded == false) return;

        this.nameTextfield.text = this.productModifierCategory.name;
        this.multiSelectSwitch.on = this.productModifierCategory.multipleSelection;
        this.requiredSwitch.on = this.productModifierCategory.isRequired;
        this.incrementalSwitch.on = this.productModifierCategory.incremental;
        this.allowNegSwitch.on = this.productModifierCategory.allowNegatives;
        this.ignoreModifierSourceSwitch.on = this.productModifierCategory.ignoreModifierSource;
        this.isAbsorbentSwitch.on = this.productModifierCategory.isAbsorbent;
        this.limitQuantityTF.text = this.productModifierCategory.limitQuantity ? this.productModifierCategory.limitQuantity : null;

        this.updateCategoryTypeUI();

        this._fetchedResultsController = null;
        this.tableView.reloadData();        
    }

    private updateCategoryTypeUI(){
        this.categoryButton.enabled = (this.productModifierCategory.productModifierCategoryType == ProductModifierCategoryType.Category) ? true: false;
        this.addButton.enabled = (this.productModifierCategory.productModifierCategoryType == ProductModifierCategoryType.Category) ? false: true;

        this.productModifierCategory.productModifierCategoryType = (this.productModifierCategory.productModifierCategoryType == null) ? 0 : this.productModifierCategory.productModifierCategoryType;
        this.segmentedControl.selectSegmentedAtIndex(this.productModifierCategory.productModifierCategoryType);
        
        this.categoryButton.enabled = (this.productModifierCategory.productModifierCategoryType == ProductModifierCategoryType.Category) ? true: false;
        this.addButton.enabled = (this.productModifierCategory.productModifierCategoryType == ProductModifierCategoryType.Category) ? false: true;
        if(this.productModifierCategory.productModifierCategoryType == ProductModifierCategoryType.Category && this.productModifierCategory.category != null) {
            this.categoryButton.title = this.productModifierCategory.category.name;
        } else {
            this.categoryButton.title = MIOLocalizeString("SELECT CATEGORY", "SELECT CATEGORY");
        }

    }

    numberOfSections(tableview){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview, section){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){

        const cell_name =
            { [ProductModifierCategoryType.Manual  ] : "ProductModifierManualCell"
            , [ProductModifierCategoryType.Category] : "ProductModifierCategoryCell"
            , [ProductModifierCategoryType.Product ] : "ProductModifierProductCell"
            } ;

        let cell = tableview.dequeueReusableCellWithIdentifier( cell_name[ this.productModifierCategory.productModifierCategoryType ] ) as ProductModifierDetailCell;

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ProductModifier;
        cell.item = item; //ERROR: the related Product is not being sent
        cell.selectionStyle = UITableViewCellSelectionStyle.None;
        return cell;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        //this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath);   
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {         
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {        

        if (editingStyle == UITableViewCellEditingStyle.Delete && this.productModifierCategory.productModifierCategoryType != ProductModifierCategoryType.Category) {
            let item = this.fetchedResultsController.objectAtIndexPath(indexPath);    
            this.removeProductModifier(item);
        }
    } 

    private _fetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;        

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true), MIOSortDescriptor.sortDescriptorWithKey("orderIndex", true)]; 
        let predicateFormat = "productModifierCategory.identifier == " + this.productModifierCategory.identifier;
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("ProductModifier", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ['product', 'tax', 'product.image'];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;        
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){        
        this.tableView.reloadData();
    }

    //show notice functions
    private showSettingsPopup() {
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('ACTIONS', 'ACTIONS'), MIOLocalizeString('CHOOSE AN ACTION', 'CHOOSE AN ACTION'), MUIAlertViewStyle.Default);
        
        if (this.productModifierCategory.productModifierCategoryType == ProductModifierCategoryType.Category) {
            avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString('REFRESH CATEGORY ITEMS', 'REFRESH CATEGORY ITEMS'), MUIAlertActionStyle.Default, this, function(this:ProductModifiersDetailViewController){
                this.refreshProductModifiersFromCategory(this.productModifierCategory);
            }));
        }
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DELETE MODIFIER CATEGORY', 'DELETE MODIFIER CATEGORY'), MUIAlertActionStyle.Destructive, this, function(){
            
            this.removeAllProductModifiers();

            if(this._fetchedResultsController.resultObjects.length == 0) {
                this.removeProductModifierCategory(this.productModifierCategory);
            }

            //pop current detail view controller
            
        }));
                
        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL', 'CANCEL'), MUIAlertActionStyle.Cancel, null, null));
       
        this.presentViewController(avc, true);
    }

    private showCategorySelectEntityViewController(dropdownButton, entityName) {

        AppHelper.sharedInstance().showSelectCategoryViewControllerFromView(dropdownButton, null, this, function(this:ProductModifiersDetailViewController, controller, category:ProductCategory){

            if (category == null) return;

            dropdownButton.title = category.name;
            this.productModifierCategory.category = category;

            this.createProductModifiersFromCategory(category, this.productModifierCategory);
        }, null, null);
    }

    private showAddManualProductModifier(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('ADD PRODUCT MODIFIER',"ADD PRODUCT MODIFIER"), MIOLocalizeString('CREATE A PRODUCT MODIFIER',"CREATE A PRODUCT MODIFIER"), MUIAlertViewStyle.Default);    

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
            textField.setPlaceholderText(MIOLocalizeString("NAME", "NAME"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK',"OK"), MUIAlertActionStyle.Default, this, function(this:ProductModifiersDetailViewController){                        
            let name = avc.textFields[0].text;
            this.createProductModifier(name, this.productModifierCategory);
            DBHelper.saveMainContext();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL',"CANCEL"), MUIAlertActionStyle.Cancel, null, null));
        this.presentViewController(avc, true);    
    }

    private showAddProductModifierFromProduct() {

        AppHelper.sharedInstance().showSelectProductViewControllerFromView(this.addButton, null, {}, this, function(this:ProductModifiersDetailViewController, controller:SelectViewController, product:Product) {
            this.createProductModifierFromProduct(product, this.productModifierCategory);
            DBHelper.saveMainContext();
        }, null, null, true);
    }

    private showChangeProductCategoryType(newModifierType){
            let notice = new MUIAlertViewController();
            notice.initWithTitle(MIOLocalizeString('NOTICE', 'NOTICE'), MIOLocalizeString('CURRENT MODIFIERS WILL BE RESET IF YOU CHANGE MODIFIER CATEGORY TYPE','CURRENT MODIFIERS WILL BE RESET IF YOU CHANGE MODIFIER CATEGORY TYPE'), MUIAlertViewStyle.Default);
            notice.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK',"OK"), MUIAlertActionStyle.Default, this, function(this:ProductModifiersDetailViewController){                        
                
                this.removeAllProductModifiers(); // Saves main context
                this.productModifierCategory.productModifierCategoryType = newModifierType;
                this.productModifierCategory.category = null;         
                this.categoryButton.title = null;
                
                this.updateCategoryTypeUI();

            }));
            notice.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));
            this.presentViewController(notice, true);
    }
      
    //generation functions
    private createProductModifier(name:string, productModifierCategory:ProductModifierCategory){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let productModifier = MIOEntityDescription.insertNewObjectForEntityForName("ProductModifier", ad.managedObjectContext) as ProductModifier;
        productModifier.identifier = MIOUUID.UUID().UUIDString;
        productModifier.productModifierCategory = productModifierCategory;
        productModifier.name = name;
        productModifier.priceFormat = null;
        productModifier.isVisible = true; 
        productModifierCategory.addProductModifiersObject(productModifier);

        return productModifier;
    }

    private createProductModifierFromProduct(product:Product, productModifierCategory:ProductModifierCategory){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let productModifier = MIOEntityDescription.insertNewObjectForEntityForName("ProductModifier", ad.managedObjectContext) as ProductModifier;
        productModifier.identifier = MIOUUID.UUID().UUIDString;
        productModifier.productModifierCategory = productModifierCategory;
        productModifier.product = product;
        productModifier.name = product.name;       
        productModifier.productModifierCount = 1;
        productModifier.isVisible = true; 
        productModifier.priceFormat = null;
        productModifier.lossMeasureUnit = product.measureUnitType;
        productModifier.consumptionMeasureUnit = product.measureUnitType;
        // productModifierCategory.addProductModifiersObject(productModifier);

        return productModifier;
    }

    private createProductModifiersFromCategory(category:ProductCategory, productModifierCategory:ProductModifierCategory) {
        let sds = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        let predicate = MIOPredicate.predicateWithFormat("category == " + category.identifier + " AND deletedAt = null");
        DBHelper.queryObjectsWithCompletion("Product", sds, predicate, [], this, function(this:ProductModifiersDetailViewController, objects:Product[]){
            for(let index = 0; index < objects.length; index++){
                this.createProductModifierFromProduct(objects[index], productModifierCategory);
            }
            DBHelper.saveMainContext();
        });  
    }


    private refreshProductModifiersFromCategory(productModifierCategory:ProductModifierCategory) {

        let productIDs = this.fetchedResultsController.fetchedObjects.map( function(p:ProductModifier) { return p.product.identifier; } );

        let sds = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        let predicate = MIOPredicate.predicateWithFormat("category == " + this.productModifierCategory.category.identifier + " AND deletedAt = null");
        DBHelper.queryObjectsWithCompletion("Product", sds, predicate, [], this, function(this:ProductModifiersDetailViewController, objects:Product[]){
            for(let p of objects){
                if (productIDs.containsObject( p.identifier) ) continue;
                this.createProductModifierFromProduct(p, productModifierCategory);
            }
            DBHelper.saveMainContext();
        });  
    }


    //removal functions - saveMainContext after function
    private removeProductModifier(productModifier:ProductModifier, save:boolean = true){
        let pmc = productModifier.productModifierCategory;
        pmc.removeProductModifiersObject(productModifier);        
        DBHelper.deleteObjectFromMainContext(productModifier, save);
    }

    private removeAllProductModifiers(){            

        for(let index = 0; index < this.fetchedResultsController.fetchedObjects.length; index ++ ){
            const ip = MIOIndexPath.indexForRowInSection(index, 0);
            let pm = this.fetchedResultsController.objectAtIndexPath(ip);
            this.removeProductModifier(pm, false);
        }
        DBHelper.saveMainContext();
    }

    private removeProductModifierCategory(productModifierCategory:ProductModifierCategory){
        DBHelper.deleteObjectFromMainContext(productModifierCategory, false);
        DBHelper.saveMainContextWithCompletion(this, function(){
            MIONotificationCenter.defaultCenter().postNotification('ProductModifierDidDeleted', null);                 
        });        
    }

}
