


class EventDetailActionViewController extends MUIViewController
{       
    viewDidLoad(){
        super.viewDidLoad();
    }

    private _event:BookingEvent = null;
    set event(value:BookingEvent){
        this._event = value;
        this.updateUI();
    }

    private updateUI(){
        if(this.viewIsLoaded && this._event != null)
        {

        }
    }
}