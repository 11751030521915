/**
 * Created by miguel on 22/2/17.
 */


class InvoiceDetailViewController extends MUIViewController
{
    static newInstance(): InvoiceDetailViewController {
        let vc = new InvoiceDetailViewController("invoice-detail-view");
        vc.initWithResource("layout/invoices/InvoiceDetailView.html");
        return vc;
    }

    private printButton:MUIButton = null; 
    private actionButton:MUIButton = null;    
    private saveButton:MUIButton = null;

    protected noEditHeader:InvoiceDetailHeaderView = null;
    protected editHeader:InvoiceDetailHeaderView = null;
    
    protected topAddLineView:InvoiceLineCell = null;
    protected bottomAddLineView:InvoiceLineCell = null;
    
    protected addLineView:InvoiceLineCell = null;

    private tableView:UITableView = null;
    
    private commentsTextField: MUITextField   = null;
    private totalBaseLabel:MUILabel           = null;
    private totalTaxLabel:MUILabel            = null;
    private totalLegalTaxLabel:MUILabel       = null;
    private totalLabel:MUILabel               = null;

    private ad = MUIWebApplication.sharedInstance().delegate;

    private df:MIODateFormatter = this.ad.dateFormatter;
    private cf:MIONumberFormatter = this.ad.currencyFormatter;
    private nf:MIONumberFormatter = this.ad.numberFormatter;
    
    
    viewDidLoad(){
        super.viewDidLoad();

        this.printButton = MUIOutlet(this, "print-btn", "MUIButton");
        this.printButton.setAction(this, function(this:InvoiceDetailViewController){
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;        
            ad.webService.downloadPDF(this.invoice);
        });

        this.actionButton = MUIOutlet(this, "action-btn", "MUIButton");
        this.actionButton.setAction(this, function(this:InvoiceDetailViewController){
            this.showActions();
        });

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, DBHelper.saveMainContext);
        
        this.editHeader = MUIOutlet(this, "edit-header-view" );
        this.noEditHeader = MUIOutlet(this, "no-edit-header-view" );

        this.commentsTextField = MUIOutlet( this, "comments-tf", "MUITextField" );
        this.commentsTextField.setOnChangeText( this, function(this:InvoiceDetailViewController, control:MUITextField, value:string) {
            this.invoice.comments = value.trim().length == 0 ? null : value.trim();
        });

        this.tableView             = MUIOutlet(this, 'table-view', 'UITableView');
        this.tableView.dataSource  = this;
        this.tableView.delegate    = this;
        
        this.totalBaseLabel = MUIOutlet(this, 'total-base-lbl','MUILabel');
        this.totalTaxLabel = MUIOutlet(this, 'total-tax-lbl','MUILabel');
        this.totalLegalTaxLabel = MUIOutlet(this, 'total-legal-tax-lbl','MUILabel');
        this.totalLabel = MUIOutlet(this, 'total-amount-lbl','MUILabel');

        this.topAddLineView = MUIOutlet(this, "top-add-line");
        if (this.topAddLineView) {
            this.topAddLineView.delegate = this;
            this.topAddLineView.hidden = true;
        }

        this.bottomAddLineView = MUIOutlet(this, "bottom-add-line");
        if (this.bottomAddLineView) {
            this.bottomAddLineView.delegate = this;
            this.bottomAddLineView.hidden = true;
        }

        this.addLineView = SettingsHelper.sharedInstance().configStockUIAddLineTop ? this.topAddLineView : this.bottomAddLineView;

    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);                
        this.updateUI();
    }
    
    private invoice:Invoice = null;    
    set item(value:Invoice){
        if (this.invoice != null) this.invoice.removeObserver(this, "status");
        this.invoice = value;   
        if (this.invoice != null) this.invoice.addObserver(this, "status", null);
        this.updateUI();
    }

    private ldtf = MUIWebApplication.sharedInstance().delegate.longDateTimeFormatter as MIODateFormatter;
    private updateUI(){
        if(!this.viewIsLoaded || this.invoice == null) return;

        this.editHeader.invoice = this.invoice;
        this.editHeader.hidden = (this.invoice.status == DBDocumentStatus.processed);
        this.noEditHeader.invoice = this.invoice;
        this.noEditHeader.hidden = (this.invoice.status != DBDocumentStatus.processed);

        this.commentsTextField.text = this.invoice.comments;

        this.updateTotals();

        this.fetchedResultsController = null;
        this.tableView.reloadData();    
        
        if (this.addLineView) {
            this.addLineView.invoice = this.invoice;
            this.addLineView.hidden = !(this.invoice.status == DBDocumentStatus.none);
        }
    }

    observeValueForKeyPath(keypath:string, type:string, object:any){

        if (type == "did") {
            switch (keypath) {
                case "status": this.updateUI(); break;
            }
        }
    }

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    // titleForHeaderInSection(tableView:UITableView, section:number) {
    //     let ip = MIOIndexPath.indexForRowInSection(0, section);
    //     let item = this.fetchedResultsController.objectAtIndexPath(ip); 
    //     if (item.wasCloned == true) return MIOLocalizeString("Previous documents".toUpperCase(), "Previous documents")
    //     else if(item.wasCloned == false) return MIOLocalizeString("Current documents".toUpperCase(), "Current documents")
    //     else return "";
    // }
    
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let item = this.fetchedResultsController.objectAtIndexPath( indexPath ) as DBDocumentLine;

        let cell:InvoiceLineCell = null;
        if ( item instanceof DBDocumentTitleLine ) {
            cell = tableview.dequeueReusableCellWithIdentifier( 'TitleLineCell' ) as InvoiceLineCell;
        }
        // else if ( item instanceof InvoiceLine ) {
        else {
            let cellIdentifier = this.invoice.status == DBDocumentStatus.none ? "EditLineCell" : "LineCell"
            cell = tableview.dequeueReusableCellWithIdentifier( cellIdentifier ) as InvoiceLineCell;
            cell.delegate = this;
        }
                
        cell.item = item;              
                    
        return cell;
    }

    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }
    
    commitEditingStyleForRowAtIndexPath(tableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {

        let line = this.fetchedResultsController.objectAtIndexPath(indexPath);    

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            DBHelper.deleteObjectFromMainContext(line, true);
            this.lineDidRemove(line);
        }
    }
    private _fetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let sds = [ MIOSortDescriptor.sortDescriptorWithKey('parentPath', true) ];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("DBDocumentLine", sds, "document.identifier == " + this.invoice.identifier );
        fetchRequest.relationshipKeyPathsForPrefetching = ["tax", "legalTax", "product"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();   
        
        // Calculate
        this.updateTotals();
    }

    private showActions(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('CHOOSE AN ACTION', 'CHOOSE AN ACTION'), null, MUIAlertViewStyle.Default);

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD PAYMENT', 'ADD PAYMENT'), MUIAlertActionStyle.Default, this, function(this:InvoiceDetailViewController){
            // MIONotificationCenter.defaultCenter().postNotification("InvoiceWillDelete", this.invoice);
            // DBHelper.deleteObjectFromMainContext(this.invoice, true);            
        }));    

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CHANGE CLIENT','CHANGE CLIENT'), MUIAlertActionStyle.Default, this, function(this:InvoiceDetailViewController){
			this.showClientSelectionViewController();
        }));

        if ((MUIWebApplication.sharedInstance().delegate as AppDelegate).selectedUser.isAdmin) {

            avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DELETE INVOICE', 'DELETE INVOICE'), MUIAlertActionStyle.Destructive, this, function(this:InvoiceDetailViewController){
                MIONotificationCenter.defaultCenter().postNotification("InvoiceWillDelete", this.invoice);
                DBHelper.deleteObjectFromMainContext(this.invoice, true);                
            }));    

            if (this.invoice instanceof LicenseInvoice) {
                avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CHANGE FEE AMOUNT', 'CHANGE FEE AMOUNT'), MUIAlertActionStyle.Default, this, function(this:InvoiceDetailViewController){
                    this.showFeeAlert();
                }));    
            }
        }            
        // modifiedType 1 for a ticket that has already been nullified, 0 if not
        // if(this._ticket.modifiedType == 0 && this._ticket.nextDocument == null)
        // avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('NULLIFY', 'NULLIFY'), MUIAlertActionStyle.Destructive, this, function(this:ArchivedDocumentDetailViewController){
        //     this.nullifyDoc();
        // }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('RECALCULATE INVOICE', 'RECALCULATE INVOICE'), MUIAlertActionStyle.Default, this, function(this:InvoiceDetailViewController){
            this.re_calculate_lines();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('VOID INVOICE', 'VOID INVOICE'), MUIAlertActionStyle.Destructive, this, function(this:InvoiceDetailViewController){            
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            ad.webService.nullifyInvoice( this.invoice, (data:any, error:string) => {
                //TODO: Show next invoice number in the UI
            });
        }));    

        avc.addAction( MUIAlertAction.alertActionWithTitle(MIOLocalizeString("SEND","SEND"), MUIAlertActionStyle.Default, this, function(this:InvoiceDetailViewController){
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            ad.webService.sendLicenseInvoice( this.invoice, null );
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL', 'CANCEL'), MUIAlertActionStyle.Cancel, null, null));
        
        this.presentViewController(avc, true);
    }

    private showFeeAlert(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('CHANGE FEE AMOUNT', 'CHANGE FEE AMOUNT'), null, MUIAlertViewStyle.Default);
        
        avc.addTextFieldWithConfigurationHandler(this, function(this:InvoiceDetailViewController, textField:MUITextField){
            textField.placeholder = MIOLocalizeString('AMOUNT', 'AMOUNT');
            // textField.keyboardType = MUITextFieldKeyboardType.NumberPad;
            textField.text = this.nf.stringFromNumber(this.invoice.totalDistributorFeeAmount);
        });
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK', 'OK'), MUIAlertActionStyle.Default, this, function(this:InvoiceDetailViewController){
            let amount = avc.textFields[0].text;
            this.invoice.totalDistributorFeeAmount = this.nf.numberFromString(amount);
            DBHelper.saveMainContext();
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL', 'CANCEL'), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    //
    // Line delegate
    //
    lineDidRemove(line:PurchaseInvoiceLine) {
        this.calculateTotals();
    }

    lineDidInserted(line:PurchaseInvoiceLine) {        
        this.calculateTotals();
    }

    lineDidUpdate(line:PurchaseInvoiceLine) {
        this.calculateTotals();
    }

    // Calculate totals
    private calculateTotals() {
        let total = 0;
        let tax = 0;
        let legal_tax = 0;
        let discount = 0;
        let base = 0;

        for (let i =0; i < this.fetchedResultsController.fetchedObjects.count; i++){
            let l = this.fetchedResultsController.fetchedObjects.objectAtIndex(i);
            if ( l instanceof InvoiceLine) {
                // base += l.baseAmount;
                base += l.baseAmount
                tax += l.vatAmount;
                legal_tax += l.legalTaxAmount;
                discount += l.discountAmount;
                total += l.totalAmount;                
            }
        }

        this.invoice.baseAmount = base;        
        this.invoice.vatAmount = tax;
        this.invoice.taxesAmount = legal_tax;
        this.invoice.discountAmount = discount;
        this.invoice.totalAmount = total;

        this.updateTotals();
    }

    updateTotals(){
        this.totalBaseLabel.text = this.cf.stringFromNumber(this.invoice.baseAmount);
        this.totalTaxLabel.text  = this.cf.stringFromNumber(this.invoice.vatAmount);
        this.totalLabel.text     = this.cf.stringFromNumber(this.invoice.totalAmount);
        this.totalLegalTaxLabel.text = this.cf.stringFromNumber(this.invoice.taxesAmount);
    }

    private re_calculate_lines(){
        let total = 0;
        let vat = 0;
        let legal_tax = 0;
        let discount = 0;
        let base = 0;

        for (let i =0; i < this.fetchedResultsController.fetchedObjects.count; i++){
            let l = this.fetchedResultsController.fetchedObjects.objectAtIndex(i);
            if ( l instanceof InvoiceLine ) {
                // base += l.baseAmount;
                l.baseAmount = ( l.priceAmount * l.quantity ) - l.discountAmount;
                l.vatAmount = l.baseAmount * (l.vat?.taxQuantity ?? 0 );
                if (l.legalTax != null) {
                    l.legalTaxFactor = l.baseAmount * l.legalTax.value;
                }                
                l.totalAmount = l.baseAmount + l.vatAmount - l.legalTaxAmount;

                base      += l.baseAmount;
                vat       += l.vatAmount;
                legal_tax += l.legalTaxAmount;
                discount  += l.discountAmount;
                total     += l.totalAmount;
            }
        }

        this.invoice.baseAmount     = base;        
        this.invoice.vatAmount      = vat;
        this.invoice.taxesAmount = legal_tax;
        this.invoice.discountAmount = discount;
        this.invoice.totalAmount    = total;
        
        DBHelper.saveMainContext();

        this.updateUI();
    }    

    protected showClientSelectionViewController() {
        let vc = LegalEntitySelectionViewController.newInstance();
        vc.completionBlock = (item:LegalEntity) => {
            this.changeClient( item );
        };
        
        this.presentViewController(vc, true);
    }

    private changeClient( client:LegalEntity ){
        this.invoice.legalEntity = client;
        DBHelper.saveMainContext();
        this.updateUI();
    }

}