
class SelectedDocumentDataSource extends MIOObject
{
    delegate = null;
    documents = [];

    numberOfSections(tableView:UITableView){
        return 1;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        return this.documents.length;
    }
    
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let doc = this.documents[indexPath.row] as MIOManagedObject;

        let cell = tableView.dequeueReusableCellWithIdentifier('DocumentCell') as SelectedDocumentCell;
        cell.item = doc;           
            
        return cell;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        //let obj = this.fetchedResultsController.objectAtIndexPath(indexPath);
        //this.zone = obj;
    }
    
    editingStyleForRowAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath) {        
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView:MUITableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let doc = this.documents[indexPath.row] as MIOManagedObject;

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            if (this.delegate != null) this.delegate.removeSelectedDocument(doc);
        }
    }

}