interface WebService
{
    downloadPDF( object: MIOManagedObject ) : void ;
}

WebService.prototype.downloadPDF = function( object:MIOManagedObject ) : void
{     
    let type = null;
    let filename = null;
    let identifier = object.valueForKey("identifier");

    if (object instanceof SupplierOrder) {            
        type = "supplier-order";
        filename = object.valueForKey("documentID") + " " + (object as StockNote).destinationName + " " + MIODateTodayString() + ".pdf";
    }
    else if (object instanceof SupplierNote) {            
        type = "supplier-note";
        filename = object.valueForKey("documentID") + " " + (object as StockNote).originName + " " + MIODateTodayString() + ".pdf";
    }
    else if (object instanceof MovementNote) {            
        type = "movement-note";
        filename = object.valueForKey("documentID") + " " + (object as StockNote).destinationName + " " + MIODateTodayString() + ".pdf";
    }
    else if (object instanceof BuyOrder) {            
        type = "purchase-order";
        filename = object.valueForKey("documentID") + MIODateTodayString() + ".pdf";
    }
    else if (object instanceof ArchivedDocument) {            
        type = "archived-document";
        filename = (object as ArchivedDocument).legalDocumentID + ".pdf";
    }       
    else if (object instanceof SalesInvoice) {
        type = "sales-invoice";
        filename = (object as SalesInvoice).documentNumber + ".pdf";
    } 
    
    if (type == null) { return }

    let cmd = new WebServiceCommand(this.templateURL, "/schema/" + this.scheme + "/file-template/render/" + type + "/" + identifier + "/pdf", true, null, filename);
    cmd.method = "GET";

    cmd.execute(this, function (code:number, data:any) {
        //TODO: Show error
        if (code != 200 ) {
            AppHelper.showErrorMessage(null, "ERROR", "COULDN'T DOWNLOAD PDF. PLEASE CHECK IF YOU HAVE AN ACTIVE TEMPLATE");
        }
    });   
}