//
// Generated class RenderTemplate
//

/// <reference path="RenderTemplate_ManagedObject.ts" />

class RenderTemplate extends RenderTemplate_ManagedObject
{

}
