
class LedgerAccountReconciliationCell extends UITableViewCell
{
    private dateLabel:MUILabel = null;
    private conceptLabel:MUILabel = null;
    private subConceptLabel:MUILabel = null;
    private amountLabel:MUILabel = null;

    private accountNumberTextField:MUITextField = null;
    private analyticAccountDropdown:MUIButton = null;
    private commentsTextField:MUITextField = null;    
    
    awakeFromHTML(){
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");
        this.conceptLabel = MUIOutlet(this, "concept-lbl", "MUILabel");
        this.subConceptLabel = MUIOutlet(this, "sub-concept-lbl", "MUILabel");
        this.amountLabel = MUIOutlet(this, "amount-lbl", "MUILabel");
        // this.accountNumberTextField = MUIOutlet(this, "account-tf", "MUITextField");
        // this.accountNumberTextField.setOnChangeText(this, function(this:LedgerAccountReconciliationCell, control:MUITextField, value:string){
        //     this.showSelectedLedgerAccountViewController( value.length > 0 ? value : null, true );
        // });

        // this.analyticAccountDropdown = MUIOutlet(this, "analytic-btn", "MUIButton");
        // this.analyticAccountDropdown.setAction(this, function(){
        //     // this.showSelectedLedgerAccountViewController( null, false );
        // });

        // this.commentsTextField = MUIOutlet(this, "comments-tf", "MUITextField");
        // this.commentsTextField.setOnChangeText(this, function(this:LedgerAccountReconciliationCell, control:MUITextField, value:string){
        //     this._item.accountComments = value.trim().length > 0 ? value : null;
        // });

        // this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private _item:CoreAccounting = null;
    set item(item:CoreAccounting) {
        this._item = item;

        this.subConceptLabel.text = null;

        let df = (MUIWebApplication.sharedInstance().delegate as AppDelegate).dateTimeFormatter;
        let cf = (MUIWebApplication.sharedInstance().delegate as AppDelegate).currencyFormatter;

        if (item instanceof SalesInvoiceLine) {
            this.dateLabel.text = df.stringFromDate( item.date );
            this.conceptLabel.text = item.concept;
            this.amountLabel.text = cf.stringFromNumber( item.totalAmount );
        }
        else if (item instanceof PurchaseInvoiceLine) {
            this.dateLabel.text = df.stringFromDate( item.date );
            this.conceptLabel.text = item.concept;
            this.amountLabel.text = cf.stringFromNumber( item.totalAmount );
        }
        else if (item instanceof BankMovement) {
            this.dateLabel.text = df.stringFromDate( item.date );
            this.conceptLabel.text = item.concept;
            this.subConceptLabel.text = item.typeDescription;
            this.amountLabel.text = cf.stringFromNumber( item.amount );
            // this.ledgerAccountDropdown.title = item.ledgerAccountName;            
        }
        
        // this.accountNumberTextField.text = item.accountNumber;
        // this.ledgerAccountDropdown.title = item.ledgerAccountName;
        // this.commentsTextField.text = item.accountComments;

        if ( item.journalEntryLocked == false ) {
            MUICoreLayerAddStyle(this.dateLabel.layer, "alert");
            MUICoreLayerAddStyle(this.conceptLabel.layer, "alert");
            MUICoreLayerAddStyle(this.subConceptLabel.layer, "alert");
            MUICoreLayerAddStyle(this.amountLabel.layer, "alert");
        }
        else if (item.ledgerAccountBalance.amount < -0.001 || item.ledgerAccountBalance.amount > 0.001) {
            MUICoreLayerAddStyle(this.dateLabel.layer, "warning");
            MUICoreLayerAddStyle(this.conceptLabel.layer, "warning");
            MUICoreLayerAddStyle(this.subConceptLabel.layer, "warning");
            MUICoreLayerAddStyle(this.amountLabel.layer, "warning");
        }
    }

    /*
    private showSelectedLedgerAccountViewController( searchString:string, hideSearchBar:boolean = false){        
        AppHelper.sharedInstance().showSelectLedgerAccountViewControllerFromView(this.ledgerAccountDropdown, null, searchString, hideSearchBar, this, this.updateLedgerAccount);
    }

    private updateLedgerAccount(controller:SelectViewController, account:LedgerAccount){
        if (this._item instanceof PurchaseInvoiceLine) {
            this._item.expenditureLedgerAccount = account;
            this._item.expenditureLedgerAccountName = account?.name;
        }

        this._item.ledgerAccount = account;
        this._item.ledgerAccountName = account?.name;
        this._item.accountNumber = account?.prefix;

        // this._product.expenditureLedgerAccount = account;
        // this._product.expenditureLedgerAccountName = account?.description;
        this.ledgerAccountDropdown.title = account?.description;
        this.accountNumberTextField.text = account?.prefix;

        if (account != null) {
            MUICoreLayerRemoveStyle(this.dateLabel.layer, "alert");
            MUICoreLayerRemoveStyle(this.conceptLabel.layer, "alert");
            MUICoreLayerRemoveStyle(this.amountLabel.layer, "alert");            
        }
    }
        */

}