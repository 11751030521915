//
// Generated class POSDocumentLine
//

/// <reference path="POSDocumentLine_ManagedObject.ts" />

class POSDocumentLine extends POSDocumentLine_ManagedObject
{

}
