

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class SubTaxName_ManagedObject

class SubTaxName_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: language
    set language(value:number) {
        this.setValueForKey(value, 'language');
    }
    get language():number {
        return this.valueForKey('language');
    }
    set languagePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'language');
    }
    get languagePrimitiveValue():number {
        return this.primitiveValueForKey('language');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }
    // Relationship: subtax
    set subtax(value:SubTax) {
        this.setValueForKey(value, 'subtax');
    }
    get subtax():SubTax {
        return this.valueForKey('subtax') as SubTax;
    }
}
