class ModifierGlobalActionsViewController extends MUIViewController
{  
    private backButton:MUIButton = null;
    private nextButton:MUIButton = null;
    private requireModifiers:MUISwitchButton = null;
    private removeOldModifiers:MUISwitchButton = null;
    private tableView:MUITableView = null;

    private selectedProductModifierCategory:ProductModifierCategory = null;
    private selectedProductModifierCategories:ProductModifierCategory[] = [];  
    private isRequired:boolean = false;
    private removeOld:boolean = false;

    public delegate = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, "back-btn", "MUIButton");
        this.backButton.setAction(this, function (){           
            MIONotificationCenter.defaultCenter().removeObserver(this, "globalActionsDidSelectCategory");  
            this.navigationController.popViewController(true);
        });
         
        this.nextButton = MUIOutlet(this, "next-btn", "MUIButton");
        this.nextButton.setAction(this, function (){
            if (this.selectedProductModifierCategories.length > 0){
                let sgavc = new SelectCategoryGlobalActionsViewController('select-category-global-actions-view');
                sgavc.initWithResource("layout/globalactions/SelectCategoryGlobalActionsView.html");
                this.navigationController.pushViewController(sgavc, true);   
            } else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('NO MODIFIER CATEGORY SELECTED', 'NO MODIFIER CATEGORY SELECTED'), MIOLocalizeString('PLEASE MAKE SELECTION','PLEASE MAKE SELECTION'), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                this.presentViewController(error, true);
            }
        });

        this.requireModifiers = MUIOutlet(this, 'require-switch-btn', 'MUISwitchButton');
        this.requireModifiers.setOnChangeValue(this, function (value){
            this.isRequired = value.on;
        });

        this.removeOldModifiers = MUIOutlet(this, 'remove-switch-btn', 'MUISwitchButton');
        this.removeOldModifiers.setOnChangeValue(this, function (value){
            this.removeOld = value.on;
        });

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        //CAUTION: be sure to remove the observer to avoid repeat observers
        MIONotificationCenter.defaultCenter().addObserver(this, "globalActionsDidSelectCategory", function(notification:MIONotification){
            let category = notification.object as ProductCategory;
            this.applyModifiers(this.selectedProductModifierCategories, this.isRequired, this.removeOld, category);
        }); 
    }

    viewWillAppear(){
        super.viewWillAppear();
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        this.isRequired = false;
        this.removeOld = false;
        this.selectedProductModifierCategories = []; 

        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableView:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
                                              
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){        
        let cell = tableView.dequeueReusableCellWithIdentifier("GlobalActionsLabelCell") as GlobalActionsLabelCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ProductModifierCategory;                      
        cell.item = item;
        cell.setAccessoryType(UITableViewCellAccessoryType.None);
        cell.selectionStyle = UITableViewCellSelectionStyle.None;
        return cell;
    }   

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){ 
        let pmc = this.fetchedResultsController.objectAtIndexPath(indexPath) as ProductModifierCategory;
        if(!this.selectedProductModifierCategories.containsObject(pmc)) {                                   
            this.tableView.cellAtIndexPath(indexPath).setAccessoryType(MUITableViewCellAccessoryType.Checkmark);        
            this.selectedProductModifierCategories.addObject(pmc);
        }
        else {            
            this.tableView.cellAtIndexPath(indexPath).setAccessoryType(MUITableViewCellAccessoryType.None);
            this.selectedProductModifierCategories.removeObject(pmc);
        }  
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('name', true)];    
        let fetchRequest = DBHelper.listFetchRequestWithEntityName('ProductModifierCategory', sortDescriptors, null);

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller:MIOFetchedResultsController){
        this.tableView.reloadData();
    }

    private applyModifiers(selectedProductModifierCategories, isRequired:boolean, removeOld:boolean, productCategories:ProductCategory[]) {
       
        let sortDescriptors = MIOSortDescriptor.sortDescriptorWithKey('name', true); 
        let didFindObjects:boolean = false;
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;

        let predicate = null;
        if (productCategories.length > 0) {
            // setup predicate for various categories
            let predicateString = "category.identifier = '" + productCategories[0].identifier + "'";
            for (let i = 1; i < productCategories.length; i++) {
                predicateString += " OR category.identifier = '" + productCategories[i].identifier + "'"
            }
            predicate = MIOPredicate.predicateWithFormat(predicateString);
        } 

        DBHelper.queryObjectsWithCompletion("Product", sortDescriptors, predicate, ["category", "productFormats"], this, function(objects){
        
            if (objects.length <= 0){
                let msg = new MUIAlertViewController();
                msg.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('NO OBJECTS TO CHANGE FOUND','NO OBJECTS TO CHANGE FOUND'), MUIAlertViewStyle.Default);
                msg.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
                this.presentViewController(msg, true);
                return;
            }

            let selectedProduct = null;
            let pmc = null;
            let len = null; 

            for (let index = 0; index < objects.length; index++){

                selectedProduct = objects[index];

                // isRequired option
                selectedProduct.modifiersRequired = (isRequired) ? true : false;                    
               
                // removeOld modifiers option
                if (removeOld){ 
                    len = selectedProduct.productModifiersCategories.length;
                    pmc = selectedProduct.productModifiersCategories.allObjects;
                    
                    for (let i = 0; i < len; i++){                        
                        selectedProduct.removeProductModifiersCategoriesObject(pmc[i]);
                    }
                }

                selectedProductModifierCategories.forEach(function (value) {
                    selectedProduct.addProductModifiersCategoriesObject(value); 
                });
            }

            //Save the new state of Products
            moc.save();
        });

    }

}