//
// Generated class Supplier
//

/// <reference path="Supplier_ManagedObject.ts" />

class Supplier extends Supplier_ManagedObject
{

}
