
class SupplierAccountingViewController extends MUIViewController
{        
    static newInstance() : SupplierAccountingViewController {
        let vc = new SupplierAccountingViewController("supplier-accounting-view");
        vc.initWithResource("layout/supplier/SupplierAccountingView.html");
        return vc;
    }

    private extraTaxDropdown:MUIButton = null;
    
    private salesLedgerAccountDropdown:MUIButton = null;
    private purchaseLedgerAccountDropdown:MUIButton = null;
    // private accountNumberTextField:MUITextField = null;
    private expenditureAccountDropdown:MUIButton = null;

    private defaultBankAccountDropdown:MUIButton = null;    
    private defaultRateDropdown:MUIButton = null;
    private defaultPaymentDropdown:MUIButton = null;
    private vatType:MUIComboBox = null;


    private tags:TagView = null;

    viewDidLoad(): void 
    {
        this.salesLedgerAccountDropdown = MUIOutlet(this, "sales-ledger-account-dd", "MUIButton");
        this.salesLedgerAccountDropdown.setAction(this, function(this:SupplierAccountingViewController){
            AppHelper.sharedInstance().showSelectLedgerAccountViewControllerFromView(this.salesLedgerAccountDropdown, this._supplier.salesLedgerAccount, null, false, this._supplier.name, this, this.updateSalesLedgerAccount);
        });

        this.purchaseLedgerAccountDropdown = MUIOutlet(this, "purchase-ledger-account-dd", "MUIButton");
        this.purchaseLedgerAccountDropdown.setAction(this, function(this:SupplierAccountingViewController){
            AppHelper.sharedInstance().showSelectLedgerAccountViewControllerFromView(this.purchaseLedgerAccountDropdown, this._supplier.purchaseLedgerAccount, null, false, this._supplier.name, this, this.updatePurchaseLedgerAccount);
        });

        // this.accountNumberTextField = MUIOutlet(this, "account-number-tf", "MUITextField");
        // this.accountNumberTextField.setOnChangeText(this, function(this:SupplierAccountingViewController, control:MUITextField, value:string){
        //     this._supplier.accountNumber = value.length > 0 ? value : null;
        // });

        this.expenditureAccountDropdown = MUIOutlet(this, "expenditure-ledger-account-dd", "MUIButton");
        this.expenditureAccountDropdown.setAction(this, function(this:SupplierAccountingViewController){
            AppHelper.sharedInstance().showSelectLedgerAccountViewControllerFromView(this.expenditureAccountDropdown, this._supplier.expenditureLegderAccount, null, false, this, this.updateExpenditureLedgerAccount);
        });

        this.defaultBankAccountDropdown = MUIOutlet(this, "default-bank-dd", "MUIButton");
        this.defaultBankAccountDropdown.setAction(this, function(this:SupplierAccountingViewController){
            AppHelper.sharedInstance().showSelectBankInfoViewControllerFromView(this.defaultBankAccountDropdown, this._supplier, this, function(this:SupplierAccountingViewController, controller:any, object:LegalEntityBankAccountInfo){
                this._supplier.defaultBankAccount = object;
                this._supplier.defaultBankAccountName = object?.title;
                this.defaultBankAccountDropdown.title = object?.title;
            });
        });

        this.defaultRateDropdown = MUIOutlet(this, "default-rate-dd", "MUIButton");
        this.defaultRateDropdown.setAction(this, function(this:SupplierAccountingViewController) {
            AppHelper.sharedInstance().showSelectRateViewControllerFromView(this.defaultRateDropdown, this._supplier.defaultRate, this, function(this:SupplierAccountingViewController, controller:any, object:Rate){
                this._supplier.defaultRate = object;
                this._supplier.defaultRateName = object?.name;
                this.defaultRateDropdown.title = object?.name;
            });
        });

        this.defaultPaymentDropdown = MUIOutlet(this, "default-payment-dd", "MUIButton");
        this.defaultPaymentDropdown.setAction(this, function(this:SupplierAccountingViewController) {
            AppHelper.sharedInstance().showSelectPaymethodViewControllerFromView(this.defaultPaymentDropdown, this._supplier.defaultPayMethod, this, function(this:SupplierAccountingViewController, controller:any, object:PayMethod){
                this._supplier.defaultPayMethod = object;
                this._supplier.defaultPayMethodName = object?.name;
                this.defaultPaymentDropdown.title = object?.name;
            });
        });

        this.vatType = MUIOutlet( this, "vat-type-cb", "MUIComboBox" );
        this.vatType.setOnChangeAction( this, function(this:SupplierAccountingViewController, control:MUIComboBox, value:string) {
            this._supplier.taxRegimeType = parseInt( value );
        });

        this.extraTaxDropdown = MUIOutlet(this, "extra-tax-dd", "MUIButton");
        this.extraTaxDropdown.setAction(this, function(){
            AppHelper.sharedInstance().showSelectLegalEntityTaxViewControllerFromView(this.extraTaxDropdown, this._supplier.LegalEntityTax, this, this.updateLegalEntityTax);
        });


    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);        
        this.updateUI();
    }

    private _supplier:Supplier = null;
    set supplier(value:Supplier){
        this._supplier = value;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._supplier == null) return;
        
        this.extraTaxDropdown.title = this._supplier.legalTaxName;
        this.salesLedgerAccountDropdown.title = this._supplier.salesLedgerAccountName;
        this.purchaseLedgerAccountDropdown.title = this._supplier.purchaseLedgerAccountName;
        // this.accountNumberTextField.text = this._supplier.accountNumber;        
        this.expenditureAccountDropdown.title = this._supplier.expenditureLegderAccountName;     
        
        this.defaultBankAccountDropdown.title = this._supplier.defaultBankAccount?.title;
        this.defaultRateDropdown.title = this._supplier.defaultRateName;
        this.defaultPaymentDropdown.title = this._supplier.defaultPayMethodName;

        this.vatType.selectItem( this._supplier.taxRegimeType );

    }

    private updateSalesLedgerAccount(controller:SelectViewController, account:LedgerAccount){
        this._supplier.salesLedgerAccount = account;
        this._supplier.salesLedgerAccountName = account?.description;
        this.salesLedgerAccountDropdown.title = account?.description;
    }

    private updatePurchaseLedgerAccount(controller:SelectViewController, account:LedgerAccount){
        this._supplier.purchaseLedgerAccount = account;
        this._supplier.purchaseLedgerAccountName = account?.description;
        this.purchaseLedgerAccountDropdown.title = account?.description;
    }

    private updateExpenditureLedgerAccount(controller:SelectViewController, account:LedgerAccount){
        this._supplier.expenditureLegderAccount = account;
        this._supplier.expenditureLegderAccountName = account?.description;
        this.expenditureAccountDropdown.title = account?.description;
    }


    private updateLegalEntityTax(controller:SelectViewController, tax:LegalTax) {
        this._supplier.legalTax = tax;
        this._supplier.legalTaxName = tax?.name;
        this.extraTaxDropdown.title = tax?.name;
    }

}