

class SelectCell extends UITableViewCell
{    
    private titleLabel:MUILabel = null;  

    awakeFromHTML() {
        this.titleLabel = MUIOutlet(this, 'title-lbl', 'MUILabel');
        this.separatorStyle = UITableViewCellSeparatorStyle.None;
    }

    set title(title:string) {
         this.titleLabel.text = title;
    }
}
