//
// Generated class PresetGroup
//

/// <reference path="PresetGroup_ManagedObject.ts" />

class PresetGroup extends PresetGroup_ManagedObject
{

}
