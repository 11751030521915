//
// Generated class PaymentAction
//

/// <reference path="PaymentAction_ManagedObject.ts" />

class PaymentAction extends PaymentAction_ManagedObject
{

}
