class MovementNoteCell extends UITableViewCell
{
    private originLabel:MUILabel      = null;
    private destinationLabel:MUILabel = null;
    private dateLabel:MUILabel        = null;  
    private documentIDLabel:MUILabel = null;     
    
    awakeFromHTML(){
        this.documentIDLabel = MUIOutlet(this, "document-id-lbl", "MUILabel");
        this.dateLabel = MUIOutlet(this, 'date-lbl', 'MUILabel');
        this.originLabel = MUIOutlet(this, 'origin-lbl','MUILabel');
        this.destinationLabel = MUIOutlet(this, 'destination-lbl','MUILabel');                
    }

    set item(item:StockNote){                        
        this.originLabel.text = item.originName;
        this.destinationLabel.text = item.destinationName ? item.destinationName : MIOLocalizeString("_DEFAULT_WAREHOUSE_", "_DEFAULT_WAREHOUSE_");
        //this.documentIDLabel.text = item.documentID;
        if (item.creatorNote != null) {
            this.documentIDLabel.text = "<strong>" + item.documentID + "</strong> <small>(" + item.creatorNote.documentID + ")</small>";
        }
        else {
            this.documentIDLabel.text = item.documentID;
        }

        let df = MUIWebApplication.sharedInstance().delegate.dateFormatter;
        this.dateLabel.text = df.stringFromDate(item.validationDate);
    }
}
