
class TaxCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    private quantityLabel:MUILabel = null;
    private salesLedgerAccountDropdown:MUIButton = null;
    private purchaseLedgerAccountDropdown:MUIButton = null;

    private pnf = MUIWebApplication.sharedInstance().delegate.percentNumberFormatter as MIONumberFormatter;
    
    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, 'name-lbl', 'MUILabel');        
        this.quantityLabel = MUIOutlet(this, 'value-lbl', 'MUILabel');
        this.salesLedgerAccountDropdown = MUIOutlet(this, "sales-ledger-account-dd", "MUIButton");
        this.salesLedgerAccountDropdown.setAction(this, function(this:TaxCell){
            AppHelper.sharedInstance().showSelectLedgerAccountViewControllerFromView(this.salesLedgerAccountDropdown, this._tax.salesLedgerAccount, null, false, this._tax.name, this, this.updateSalesLedgerAccount);
        });

        this.purchaseLedgerAccountDropdown = MUIOutlet(this, "purchase-ledger-account-dd", "MUIButton");
        this.purchaseLedgerAccountDropdown.setAction(this, function(this:TaxCell){
            AppHelper.sharedInstance().showSelectLedgerAccountViewControllerFromView(this.purchaseLedgerAccountDropdown, this._tax.purchaseLedgerAccount, null, false, this._tax.name, this, this.updatePurchaseLedgerAccount);
        });

    }
    
    private _tax:Tax = null;
    set item(i:Tax){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;        
        
        this._tax = i;
        this.nameLabel.text = i.name + (i.placeID != null && i.placeID.toLowerCase() != ad.selectedIdentifier.toLowerCase() ? " 🔒" : "" );        
        let tax = i.taxQuantity * 100;
        this.quantityLabel.text = this.pnf.stringFromNumber(tax);

        this.salesLedgerAccountDropdown.title = i.salesLedgerAccountName;
        this.purchaseLedgerAccountDropdown.title = i.purchaseLedgerAccountName;
    }

    private updateSalesLedgerAccount(controller:SelectViewController, account:LedgerAccount){
        this._tax.salesLedgerAccount = account;
        this._tax.salesLedgerAccountName = account?.description;
        this.salesLedgerAccountDropdown.title = account?.description;
    }

    private updatePurchaseLedgerAccount(controller:SelectViewController, account:LedgerAccount){
        this._tax.purchaseLedgerAccount = account;
        this._tax.purchaseLedgerAccountName = account?.description;
        this.purchaseLedgerAccountDropdown.title = account?.description;
    }

}
