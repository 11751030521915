//
// Generated class ProfileSummary
//

/// <reference path="ProfileSummary_ManagedObject.ts" />

class ProfileSummary extends ProfileSummary_ManagedObject
{

}
