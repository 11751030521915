

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ProfileSummarySource_ManagedObject

class ProfileSummarySource_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: fontStyle
    set fontStyle(value:number) {
        this.setValueForKey(value, 'fontStyle');
    }
    get fontStyle():number {
        return this.valueForKey('fontStyle');
    }
    set fontStylePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'fontStyle');
    }
    get fontStylePrimitiveValue():number {
        return this.primitiveValueForKey('fontStyle');
    }

    // Property: productSourceName
    set productSourceName(value:string) {
        this.setValueForKey(value, 'productSourceName');
    }
    get productSourceName():string {
        return this.valueForKey('productSourceName');
    }
    set productSourceNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'productSourceName');
    }
    get productSourceNamePrimitiveValue():string {
        return this.primitiveValueForKey('productSourceName');
    }
    // Relationship: productSource
    set productSource(value:ProductSource) {
        this.setValueForKey(value, 'productSource');
    }
    get productSource():ProductSource {
        return this.valueForKey('productSource') as ProductSource;
    }
    // Relationship: profileSummary
    set profileSummary(value:ProfileSummary) {
        this.setValueForKey(value, 'profileSummary');
    }
    get profileSummary():ProfileSummary {
        return this.valueForKey('profileSummary') as ProfileSummary;
    }
}
