//
// Generated class DBMappingEntityCeco
//

/// <reference path="DBMappingEntityCeco_ManagedObject.ts" />

class DBMappingEntityCeco extends DBMappingEntityCeco_ManagedObject
{

}
