
class StockCategoryCell extends UITableViewCell
{
    private nameTextField:MUITextArea = null;
    private purchaseLedgerAccountDropdown:MUIButton = null;

    awakeFromHTML(){
        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.nameTextField.setOnChangeText(this, function(this:StockCategoryCell, control:MUITextField, value:string){
            this.category.name = value.trim();
        });

        this.purchaseLedgerAccountDropdown = MUIOutlet( this, "ledger-account-dd", "MUIButton");
        this.purchaseLedgerAccountDropdown.setAction(this, function(this:StockCategoryCell){
            AppHelper.sharedInstance().showSelectLedgerAccountViewControllerFromView(this.purchaseLedgerAccountDropdown, this.category.purchaseLedgerAccount, this, function(this:StockCategoryCell, controller:SelectEntityViewController, item:LedgerAccount){
                this.category.purchaseLedgerAccount = item;
                this.category.purchaseLedgerAccountName = item?.name;
                this.purchaseLedgerAccountDropdown.title = item?.name;
            });
        });
    }

    private category:StockCategory = null;
    set item(value:StockCategory){
        this.category = value;
        this.nameTextField.text = value.name;
        this.purchaseLedgerAccountDropdown.title = value.purchaseLedgerAccountName ?? null;

        this.nameTextField.layer.style.paddingLeft = ( this.category.indentLevel * 20 ) + "px";
    }
}