//
// Generated class WorkerRole
//

/// <reference path="WorkerRole_ManagedObject.ts" />

class WorkerRole extends WorkerRole_ManagedObject
{
    hasPOSPermision(permission){
        return (this.permissions & permission) > 0;
    }

    addPOSPermission(permission){
        this.permissions |= permission;
    }

    removePOSPermission(permission){
        this.permissions &= ~permission;
    }

    hasBookingPermision(permission){
        return (this.bookingPermissions & permission) > 0;
    }

    public addBookingPermission(permission){
        this.bookingPermissions |= permission;
    }

    public removeBookingPermission(permission){
        this.bookingPermissions &= ~permission;
    }

}
