//
// Generated class InvoiceLine
//

/// <reference path="InvoiceLine_ManagedObject.ts" />

class InvoiceLine extends InvoiceLine_ManagedObject
{
    get documentNumber():string {
        return this.document?.documentNumber;
    }
}
