//
// Generated class Tip
//

/// <reference path="Tip_ManagedObject.ts" />

class Tip extends Tip_ManagedObject
{

}
