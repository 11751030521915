

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class BusinessLicense_ManagedObject

class BusinessLicense_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: active
    set active(value:boolean) {
        this.setValueForKey(value, 'active');
    }
    get active():boolean {
        return this.valueForKey('active');
    }
    set activePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'active');
    }
    get activePrimitiveValue():boolean {
        return this.primitiveValueForKey('active');
    }

    // Property: chargeToDistributor
    set chargeToDistributor(value:boolean) {
        this.setValueForKey(value, 'chargeToDistributor');
    }
    get chargeToDistributor():boolean {
        return this.valueForKey('chargeToDistributor');
    }
    set chargeToDistributorPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'chargeToDistributor');
    }
    get chargeToDistributorPrimitiveValue():boolean {
        return this.primitiveValueForKey('chargeToDistributor');
    }

    // Property: dbSchema
    set dbSchema(value:string) {
        this.setValueForKey(value, 'dbSchema');
    }
    get dbSchema():string {
        return this.valueForKey('dbSchema');
    }
    set dbSchemaPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'dbSchema');
    }
    get dbSchemaPrimitiveValue():string {
        return this.primitiveValueForKey('dbSchema');
    }

    // Property: dbVersion
    set dbVersion(value:number) {
        this.setValueForKey(value, 'dbVersion');
    }
    get dbVersion():number {
        return this.valueForKey('dbVersion');
    }
    set dbVersionPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'dbVersion');
    }
    get dbVersionPrimitiveValue():number {
        return this.primitiveValueForKey('dbVersion');
    }

    // Property: distributorAmount
    set distributorAmount(value:number) {
        this.setValueForKey(value, 'distributorAmount');
    }
    get distributorAmount():number {
        return this.valueForKey('distributorAmount');
    }
    set distributorAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'distributorAmount');
    }
    get distributorAmountPrimitiveValue():number {
        return this.primitiveValueForKey('distributorAmount');
    }

    // Property: distributorName
    set distributorName(value:string) {
        this.setValueForKey(value, 'distributorName');
    }
    get distributorName():string {
        return this.valueForKey('distributorName');
    }
    set distributorNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'distributorName');
    }
    get distributorNamePrimitiveValue():string {
        return this.primitiveValueForKey('distributorName');
    }

    // Property: leaveAuthorizationBy
    set leaveAuthorizationBy(value:string) {
        this.setValueForKey(value, 'leaveAuthorizationBy');
    }
    get leaveAuthorizationBy():string {
        return this.valueForKey('leaveAuthorizationBy');
    }
    set leaveAuthorizationByPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'leaveAuthorizationBy');
    }
    get leaveAuthorizationByPrimitiveValue():string {
        return this.primitiveValueForKey('leaveAuthorizationBy');
    }

    // Property: leaveDate
    set leaveDate(value:Date) {
        this.setValueForKey(value, 'leaveDate');
    }
    get leaveDate():Date {
        return this.valueForKey('leaveDate');
    }
    set leaveDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'leaveDate');
    }
    get leaveDatePrimitiveValue():Date {
        return this.primitiveValueForKey('leaveDate');
    }

    // Property: leaveReason
    set leaveReason(value:string) {
        this.setValueForKey(value, 'leaveReason');
    }
    get leaveReason():string {
        return this.valueForKey('leaveReason');
    }
    set leaveReasonPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'leaveReason');
    }
    get leaveReasonPrimitiveValue():string {
        return this.primitiveValueForKey('leaveReason');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: totalAmount
    set totalAmount(value:number) {
        this.setValueForKey(value, 'totalAmount');
    }
    get totalAmount():number {
        return this.valueForKey('totalAmount');
    }
    set totalAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalAmount');
    }
    get totalAmountPrimitiveValue():number {
        return this.primitiveValueForKey('totalAmount');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }
    // Relationship: adyen
    set adyen(value:AdyenBusinessLicense) {
        this.setValueForKey(value, 'adyen');
    }
    get adyen():AdyenBusinessLicense {
        return this.valueForKey('adyen') as AdyenBusinessLicense;
    }
    // Relationship: bankAccountInfo
    set bankAccountInfo(value:BankAccountInfo) {
        this.setValueForKey(value, 'bankAccountInfo');
    }
    get bankAccountInfo():BankAccountInfo {
        return this.valueForKey('bankAccountInfo') as BankAccountInfo;
    }

    // Relationship: children
    protected _children:MIOManagedObjectSet = null;
    get children():MIOManagedObjectSet {
        return this.valueForKey('children');
    }
    addChildrenObject(value:BusinessLicense) {
        this._addObjectForKey(value, 'children');
    }
    removeChildrenObject(value:BusinessLicense) {
        this._removeObjectForKey(value, 'children');
    }
    // Relationship: client
    set client(value:Client) {
        this.setValueForKey(value, 'client');
    }
    get client():Client {
        return this.valueForKey('client') as Client;
    }
    // Relationship: distributor
    set distributor(value:Distributor) {
        this.setValueForKey(value, 'distributor');
    }
    get distributor():Distributor {
        return this.valueForKey('distributor') as Distributor;
    }
    // Relationship: leaveReasonType
    set leaveReasonType(value:LeaveReasonType) {
        this.setValueForKey(value, 'leaveReasonType');
    }
    get leaveReasonType():LeaveReasonType {
        return this.valueForKey('leaveReasonType') as LeaveReasonType;
    }

    // Relationship: licenses
    protected _licenses:MIOManagedObjectSet = null;
    get licenses():MIOManagedObjectSet {
        return this.valueForKey('licenses');
    }
    addLicensesObject(value:License) {
        this._addObjectForKey(value, 'licenses');
    }
    removeLicensesObject(value:License) {
        this._removeObjectForKey(value, 'licenses');
    }

    // Relationship: logs
    protected _logs:MIOManagedObjectSet = null;
    get logs():MIOManagedObjectSet {
        return this.valueForKey('logs');
    }
    addLogsObject(value:BusinessLog) {
        this._addObjectForKey(value, 'logs');
    }
    removeLogsObject(value:BusinessLog) {
        this._removeObjectForKey(value, 'logs');
    }
    // Relationship: parent
    set parent(value:BusinessLicense) {
        this.setValueForKey(value, 'parent');
    }
    get parent():BusinessLicense {
        return this.valueForKey('parent') as BusinessLicense;
    }
    // Relationship: payMethod
    set payMethod(value:PayMethod) {
        this.setValueForKey(value, 'payMethod');
    }
    get payMethod():PayMethod {
        return this.valueForKey('payMethod') as PayMethod;
    }
}
