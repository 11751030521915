
/// <reference path="../../AppHelper.ts" />

class BookingIntermediaryListViewController extends BaseTableViewController
{
    static newInstance() {
        let vc = new BookingIntermediaryListViewController("booking-intermediary-list-view");
        vc.initWithResource("layout/bookings/intermediary/BookingIntermediaryListView.html")
        return vc;
    }

    private addButton:MUIButton = null;    

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this,"add-btn", "MUIButton");
        this.addButton.setAction(this, function(){
            this.addBookingSource();
        });

        this.searchTextField = MUIOutlet(this,"search-bar", "MUITextField", "SearchType");
        this.setupSearchTextField();

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("BookingIntermediaryListCell") as BookingIntermediaryListCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as BookingSource;
        
        cell.item = item;
        cell.selected = this.selectedItem == item ? true : false;

        return cell;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        let selectedBookingSource = this.fetchedResultsController.objectAtIndexPath(indexPath);

        let vc = BookingIntermediaryDetailViewController.newInstance();
        vc.bookingSource = selectedBookingSource;
        this.splitViewController.showDetailViewController(vc);
    }

    private showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("BookingSource", NoItemSelectedViewControllerType.BookingSource)        
        this.splitViewController.showDetailViewController(vc);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        // Setup data
        let ad = MUIWebApplication.sharedInstance().delegate;
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];

        let predicateFormat = null
        if (this.searchString != null)
            predicateFormat = 'name CONTAINS "' + this.searchString + '"';

        //NOTE: in database BookingSource is the object which is BookingIntermediary
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("BookingSource", sortDescriptors, predicateFormat);    
        fetchRequest.relationshipKeyPathsForPrefetching = ["phone", "address"];
        
        this._fetchedResultsController = new MIOFetchedResultsController();
        this._fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        this._fetchedResultsController.delegate = this;

        this._fetchedResultsController.performFetch();
        return this._fetchedResultsController;
    }

    private addBookingSource(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('BOOKING INTERMEDIARY','BOOKING INTERMEDIARY'), MIOLocalizeString('CHOOSE A NAME','CHOOSE A NAME'), MUIAlertViewStyle.Default);
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','DONE'), MUIAlertActionStyle.Default, this, function(){
            let name = avc.textFields[0].text.trim();
            if(name.length > 0){
                BookingSource.insertNewSource(name);
            }
            else {
                let error = new MUIAlertViewController();
                error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('MUST WRITE A NAME FOR INTERMEDIARY','MUST WRITE A NAME FOR INTERMEDIARY '), MUIAlertViewStyle.Default);
                error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, this, function(){}));
                
                this.presentViewController(error, true);
            }
        }));
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, this, function(){}));
        
        this.presentViewController(avc, true);
    }
}
