//
// Generated class FreeProduct
//

/// <reference path="FreeProduct_ManagedObject.ts" />

class FreeProduct extends FreeProduct_ManagedObject
{

}
