
enum MapItemFloorType
{
    type1     = 0,  // baldosa
    type2     = 1,  // grass
    type3     = 2,  // wood
    heart     = 3,  // Heart Ibiza floor
    beach     = 4,  // Beach
    beachUD   = 5,  // Beach upside down
}

function TableMapBackgroundTypeURL(type:number):string
{
    let image_name:string = null;
    switch (type)
    {
        case MapItemFloorType.type1:    image_name = "background-tiles";    break;
        case MapItemFloorType.type2:    image_name = "background-grass";    break;            
        case MapItemFloorType.type3:    image_name = "background-wood";     break;            
        case MapItemFloorType.heart:    image_name = "background-wood2";    break;        
        case MapItemFloorType.beach:    image_name = "background-beach";    break;            
        case MapItemFloorType.beachUD:  image_name = "background-beach2";   break;
    }

    return "images/map/backgrounds/" + image_name + ".png";
}


enum MapItemType
{
    table                = 0,
    plant                = 1,    // Decoration
    horizontalBar        = 2,    // Decoration
    verticalBar          = 3,    // Decoration
    stool                = 4,
    barStandTable        = 5,
    barStandLargePlant   = 6,    // Decoration
    pool                 = 7,    // Decoration
    tableSoccer          = 8,    // Decoration
    piano                = 9,    // Decoration
    game                 = 10,   // Decoration
    cigar                = 11,   // Decoration
    slotMachine          = 12,   // Decoration
    stair                = 13,   // Decoration
    sofa1                = 14,   // Decoration
    sofa2                = 15,   // Decoration
    sofa3                = 16,   // Decoration
    roundedTable         = 17,
    vipSofa              = 18,
    vipTable             = 19,
    vipJacuzzi           = 20,   // Decoration
    hostelcoTable        = 21,
    hostelcoCenter       = 22,   // Decoration
    hostelcoLight        = 23,   // Decoration
    hostelcoLight2       = 24,   // Decoration
    heartStool           = 25,
    heartBrownTable      = 26,
    heartGrayTable       = 27,
    heartRedTable        = 28,
    beachBedBamboo       = 29,
    beachBed             = 30,
    beachGazeboBamboo    = 31,
    beachGazebo          = 32,
    beachHammock         = 33,
    beachHammockTriple   = 34,
    beachHammockVIP      = 35,
    beachTable           = 36,
    beachUmbrella        = 37,   // Decoration
    beachHammockXL       = 38,
    beachHammockDouble   = 39,
    sndLamp              = 40,   // Domotics
    sndPlug              = 41,   // Domotics
    sndThermostat        = 42    // Domotics
}


function TableMapLocationTypeURL(type:number):string{
    let image_name:string = null;
    switch (type)
    {
        case MapItemType.table:             image_name = "table-wood-empty";    break;
        case MapItemType.plant:             image_name = "planta";    break;     
        case MapItemType.horizontalBar:     image_name = "bar-wood"; break;
        case MapItemType.verticalBar:       image_name = "barravertical"; break;
        case MapItemType.stool:             image_name = "stool-wood-empty"; break;
        case MapItemType.barStandTable:     image_name = "stand-horecava/mesa-sin-stand"; break;
        case MapItemType.barStandLargePlant: image_name = "stand-horecava/planta-larga"; break;
        case MapItemType.pool:              image_name = "pool"; break;
        case MapItemType.tableSoccer:       image_name = "futbolin"; break;
        case MapItemType.piano:             image_name = "piano"; break;
        case MapItemType.game:              image_name = "arcade"; break;
        case MapItemType.cigar:             image_name = "tobacco"; break;
        case MapItemType.slotMachine:       image_name = "slotmachine"; break;
        case MapItemType.stair:             image_name = "stairs"; break;
        case MapItemType.sofa1:             image_name = "sofa1"; break;
        case MapItemType.sofa2:             image_name = "sofa2"; break;
        case MapItemType.sofa3:             image_name = "sofa3"; break;
        case MapItemType.roundedTable:      image_name = "rounded-table-1"; break;
        case MapItemType.vipSofa:           image_name = "sofa-vip-empty"; break;
        case MapItemType.vipTable:          image_name = "table-vip-empty"; break;
        case MapItemType.vipJacuzzi:        image_name = "jacuzzi"; break;
        case MapItemType.hostelcoTable:     image_name = "stand-hostelco/mesahostelco"; break;
        case MapItemType.hostelcoCenter:    image_name = "stand-hostelco/barra-h"; break;
        case MapItemType.hostelcoLight:     image_name = "stand-hostelco/luz"; break;
        case MapItemType.hostelcoLight2:    image_name = "stand-hostelco/farola"; break;
        case MapItemType.heartStool:        image_name = "heart-ibiza/stool-empty"; break;
        case MapItemType.heartBrownTable:   image_name = "heart-ibiza/table-brown-empty"; break;
        case MapItemType.heartGrayTable:    image_name = "heart-ibiza/table-gray-empty"; break;
        case MapItemType.heartRedTable:     image_name = "heart-ibiza/table-red-empty"; break;
        case MapItemType.beachBedBamboo:    image_name = "beach-elements/beach-bed-bamboo-empty"; break;
        case MapItemType.beachBed:          image_name = "beach-elements/beach-bed-empty"; break;
        case MapItemType.beachGazeboBamboo: image_name = "beach-elements/beach-gazebo-bamboo-empty"; break;
        case MapItemType.beachGazebo:       image_name = "beach-elements/beach-gazebo-empty"; break;
        case MapItemType.beachHammock:      image_name = "beach-elements/hammock-empty"; break;
        case MapItemType.beachHammockTriple: image_name = "beach-elements/beach-hammock-triple-empty"; break;
        case MapItemType.beachHammockVIP:   image_name = "beach-elements/beach-hammcock-vip-empty"; break;
        case MapItemType.beachTable:        image_name = "beach-elements/beach-table-empty"; break;
        case MapItemType.beachUmbrella:     image_name = "beach-elements/beach-umbrella"; break;
        case MapItemType.beachHammockXL:    image_name = "beach-elements/hammock-xl-empty"; break;
        case MapItemType.beachHammockDouble: image_name = "beach-elements/hammock-double-empty"; break;
        case MapItemType.sndLamp:           image_name = "lantern"; break;
        case MapItemType.sndPlug:           image_name = ""; break;
        case MapItemType.sndThermostat:     image_name = ""; break;
    
    }

    return "images/map/" + image_name + ".png";
}

function TableMapOccupiableTypes():MapItemType[]
{
    return [
        MapItemType.table,
        MapItemType.stool,
        MapItemType.barStandTable,
        MapItemType.roundedTable,
        MapItemType.vipSofa,
        MapItemType.vipTable,
        MapItemType.hostelcoTable,
        MapItemType.heartStool,
        MapItemType.heartBrownTable,
        MapItemType.heartGrayTable,
        MapItemType.heartRedTable,
        MapItemType.beachBedBamboo,
        MapItemType.beachBed,
        MapItemType.beachGazeboBamboo,
        MapItemType.beachGazebo,
        MapItemType.beachHammock,
        MapItemType.beachHammockXL,
        MapItemType.beachHammockDouble,
        MapItemType.beachHammockTriple,
        MapItemType.beachHammockVIP,
        MapItemType.beachTable
    ];
}

function TableMapIsOcupiable(type:MapItemType):boolean {

    let types = TableMapOccupiableTypes();
    return ( types.containsObject(type) );
}
