

/// <reference path="EconomicEntity.ts" />

// Generated class LegalEntity_ManagedObject

class LegalEntity_ManagedObject extends EconomicEntity
{

    // Property: aliasName
    set aliasName(value:string) {
        this.setValueForKey(value, 'aliasName');
    }
    get aliasName():string {
        return this.valueForKey('aliasName');
    }
    set aliasNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'aliasName');
    }
    get aliasNamePrimitiveValue():string {
        return this.primitiveValueForKey('aliasName');
    }

    // Property: businessName
    set businessName(value:string) {
        this.setValueForKey(value, 'businessName');
    }
    get businessName():string {
        return this.valueForKey('businessName');
    }
    set businessNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'businessName');
    }
    get businessNamePrimitiveValue():string {
        return this.primitiveValueForKey('businessName');
    }

    // Property: defaultBankAccountName
    set defaultBankAccountName(value:string) {
        this.setValueForKey(value, 'defaultBankAccountName');
    }
    get defaultBankAccountName():string {
        return this.valueForKey('defaultBankAccountName');
    }
    set defaultBankAccountNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'defaultBankAccountName');
    }
    get defaultBankAccountNamePrimitiveValue():string {
        return this.primitiveValueForKey('defaultBankAccountName');
    }

    // Property: defaultPayMethodName
    set defaultPayMethodName(value:string) {
        this.setValueForKey(value, 'defaultPayMethodName');
    }
    get defaultPayMethodName():string {
        return this.valueForKey('defaultPayMethodName');
    }
    set defaultPayMethodNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'defaultPayMethodName');
    }
    get defaultPayMethodNamePrimitiveValue():string {
        return this.primitiveValueForKey('defaultPayMethodName');
    }

    // Property: defaultRateName
    set defaultRateName(value:string) {
        this.setValueForKey(value, 'defaultRateName');
    }
    get defaultRateName():string {
        return this.valueForKey('defaultRateName');
    }
    set defaultRateNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'defaultRateName');
    }
    get defaultRateNamePrimitiveValue():string {
        return this.primitiveValueForKey('defaultRateName');
    }

    // Property: document
    set document(value:string) {
        this.setValueForKey(value, 'document');
    }
    get document():string {
        return this.valueForKey('document');
    }
    set documentPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'document');
    }
    get documentPrimitiveValue():string {
        return this.primitiveValueForKey('document');
    }

    // Property: email
    set email(value:string) {
        this.setValueForKey(value, 'email');
    }
    get email():string {
        return this.valueForKey('email');
    }
    set emailPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'email');
    }
    get emailPrimitiveValue():string {
        return this.primitiveValueForKey('email');
    }

    // Property: idIn
    set idIn(value:number) {
        this.setValueForKey(value, 'idIn');
    }
    get idIn():number {
        return this.valueForKey('idIn');
    }
    set idInPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'idIn');
    }
    get idInPrimitiveValue():number {
        return this.primitiveValueForKey('idIn');
    }

    // Property: imageID
    set imageID(value:string) {
        this.setValueForKey(value, 'imageID');
    }
    get imageID():string {
        return this.valueForKey('imageID');
    }
    set imageIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'imageID');
    }
    get imageIDPrimitiveValue():string {
        return this.primitiveValueForKey('imageID');
    }

    // Property: legalTaxName
    set legalTaxName(value:string) {
        this.setValueForKey(value, 'legalTaxName');
    }
    get legalTaxName():string {
        return this.valueForKey('legalTaxName');
    }
    set legalTaxNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalTaxName');
    }
    get legalTaxNamePrimitiveValue():string {
        return this.primitiveValueForKey('legalTaxName');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: purchaseLedgerAccountName
    set purchaseLedgerAccountName(value:string) {
        this.setValueForKey(value, 'purchaseLedgerAccountName');
    }
    get purchaseLedgerAccountName():string {
        return this.valueForKey('purchaseLedgerAccountName');
    }
    set purchaseLedgerAccountNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'purchaseLedgerAccountName');
    }
    get purchaseLedgerAccountNamePrimitiveValue():string {
        return this.primitiveValueForKey('purchaseLedgerAccountName');
    }

    // Property: reference
    set reference(value:string) {
        this.setValueForKey(value, 'reference');
    }
    get reference():string {
        return this.valueForKey('reference');
    }
    set referencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'reference');
    }
    get referencePrimitiveValue():string {
        return this.primitiveValueForKey('reference');
    }

    // Property: salesLedgerAccountName
    set salesLedgerAccountName(value:string) {
        this.setValueForKey(value, 'salesLedgerAccountName');
    }
    get salesLedgerAccountName():string {
        return this.valueForKey('salesLedgerAccountName');
    }
    set salesLedgerAccountNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'salesLedgerAccountName');
    }
    get salesLedgerAccountNamePrimitiveValue():string {
        return this.primitiveValueForKey('salesLedgerAccountName');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: taxRegimeType
    set taxRegimeType(value:number) {
        this.setValueForKey(value, 'taxRegimeType');
    }
    get taxRegimeType():number {
        return this.valueForKey('taxRegimeType');
    }
    set taxRegimeTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'taxRegimeType');
    }
    get taxRegimeTypePrimitiveValue():number {
        return this.primitiveValueForKey('taxRegimeType');
    }

    // Relationship: additionalAddresses
    protected _additionalAddresses:MIOManagedObjectSet = null;
    get additionalAddresses():MIOManagedObjectSet {
        return this.valueForKey('additionalAddresses');
    }
    addAdditionalAddressesObject(value:Address) {
        this._addObjectForKey(value, 'additionalAddresses');
    }
    removeAdditionalAddressesObject(value:Address) {
        this._removeObjectForKey(value, 'additionalAddresses');
    }
    // Relationship: address
    set address(value:Address) {
        this.setValueForKey(value, 'address');
    }
    get address():Address {
        return this.valueForKey('address') as Address;
    }

    // Relationship: bankAccounts
    protected _bankAccounts:MIOManagedObjectSet = null;
    get bankAccounts():MIOManagedObjectSet {
        return this.valueForKey('bankAccounts');
    }
    addBankAccountsObject(value:LegalEntityBankAccountInfo) {
        this._addObjectForKey(value, 'bankAccounts');
    }
    removeBankAccountsObject(value:LegalEntityBankAccountInfo) {
        this._removeObjectForKey(value, 'bankAccounts');
    }

    // Relationship: cards
    protected _cards:MIOManagedObjectSet = null;
    get cards():MIOManagedObjectSet {
        return this.valueForKey('cards');
    }
    addCardsObject(value:Card) {
        this._addObjectForKey(value, 'cards');
    }
    removeCardsObject(value:Card) {
        this._removeObjectForKey(value, 'cards');
    }

    // Relationship: contacts
    protected _contacts:MIOManagedObjectSet = null;
    get contacts():MIOManagedObjectSet {
        return this.valueForKey('contacts');
    }
    addContactsObject(value:Contact) {
        this._addObjectForKey(value, 'contacts');
    }
    removeContactsObject(value:Contact) {
        this._removeObjectForKey(value, 'contacts');
    }
    // Relationship: defaultBankAccount
    set defaultBankAccount(value:LegalEntityBankAccountInfo) {
        this.setValueForKey(value, 'defaultBankAccount');
    }
    get defaultBankAccount():LegalEntityBankAccountInfo {
        return this.valueForKey('defaultBankAccount') as LegalEntityBankAccountInfo;
    }
    // Relationship: defaultPayMethod
    set defaultPayMethod(value:PayMethod) {
        this.setValueForKey(value, 'defaultPayMethod');
    }
    get defaultPayMethod():PayMethod {
        return this.valueForKey('defaultPayMethod') as PayMethod;
    }
    // Relationship: defaultRate
    set defaultRate(value:Rate) {
        this.setValueForKey(value, 'defaultRate');
    }
    get defaultRate():Rate {
        return this.valueForKey('defaultRate') as Rate;
    }
    // Relationship: image
    set image(value:DBImage) {
        this.setValueForKey(value, 'image');
    }
    get image():DBImage {
        return this.valueForKey('image') as DBImage;
    }

    // Relationship: invoiceTickets
    protected _invoiceTickets:MIOManagedObjectSet = null;
    get invoiceTickets():MIOManagedObjectSet {
        return this.valueForKey('invoiceTickets');
    }
    addInvoiceTicketsObject(value:Ticket) {
        this._addObjectForKey(value, 'invoiceTickets');
    }
    removeInvoiceTicketsObject(value:Ticket) {
        this._removeObjectForKey(value, 'invoiceTickets');
    }
    // Relationship: legalTax
    set legalTax(value:LegalTax) {
        this.setValueForKey(value, 'legalTax');
    }
    get legalTax():LegalTax {
        return this.valueForKey('legalTax') as LegalTax;
    }
    // Relationship: mobilePhone
    set mobilePhone(value:PhoneNumber) {
        this.setValueForKey(value, 'mobilePhone');
    }
    get mobilePhone():PhoneNumber {
        return this.valueForKey('mobilePhone') as PhoneNumber;
    }
    // Relationship: onlineOrder
    set onlineOrder(value:OnlineOrder) {
        this.setValueForKey(value, 'onlineOrder');
    }
    get onlineOrder():OnlineOrder {
        return this.valueForKey('onlineOrder') as OnlineOrder;
    }
    // Relationship: phone
    set phone(value:PhoneNumber) {
        this.setValueForKey(value, 'phone');
    }
    get phone():PhoneNumber {
        return this.valueForKey('phone') as PhoneNumber;
    }
    // Relationship: purchaseLedgerAccount
    set purchaseLedgerAccount(value:LedgerAccount) {
        this.setValueForKey(value, 'purchaseLedgerAccount');
    }
    get purchaseLedgerAccount():LedgerAccount {
        return this.valueForKey('purchaseLedgerAccount') as LedgerAccount;
    }
    // Relationship: salesLedgerAccount
    set salesLedgerAccount(value:LedgerAccount) {
        this.setValueForKey(value, 'salesLedgerAccount');
    }
    get salesLedgerAccount():LedgerAccount {
        return this.valueForKey('salesLedgerAccount') as LedgerAccount;
    }
}
