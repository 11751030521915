/**
 * Created by miguel on 15/2/18.
 */

class DBToolViewController extends BaseViewController
{
    private scriptsComboBox:MUIButton = null;
    private segmentedControl:MUISegmentedControl = null;
    private runButton:MUIButton = null;    
    private saveButton:MUIButton = null;    

    private pageController:MUIPageController = null;
    
    private summaryViewController:DBToolSummaryViewController = null;    
    private sqlViewController:DBToolSQLViewController = null;
    private scriptViewController:DBToolScriptViewController = null;
    

    viewDidLoad(){
        super.viewDidLoad();

        this.scriptsComboBox = MUIOutlet(this, 'script-cb','MUIButton');
        this.scriptsComboBox.setAction(this, function(){
            let vc = AppHelper.sharedInstance().selectViewController("DBScript", "name", false, false, null, this, false);
            vc.modalPresentationStyle = MUIModalPresentationStyle.Popover;
                
            let pc = vc.popoverPresentationController;
            pc.sourceRect = this.scriptsComboBox.frame;
            pc.sourceView = this.scriptsComboBox;        

            this.presentViewController(vc, true);
        });

        
        //     if (value >= 0) {
        //         let item = this.fetchedResultsController.objectAtIndexPath(MIOIndexPath.indexForRowInSection(value, 0));
        //         this.setScript(item);
        //     }
        //     else {
        //         // Create new script
        //         let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
        //         let script = MIOEntityDescription.insertNewObjectForEntityForName("DBScript", ad.managedObjectContext) as DBScript;
        //         script.name = "New script";
        //         this.setScript(script);
        //     }
        // });

        this.segmentedControl = MUIOutlet(this, 'segmented', 'MUISegmentedControl');
        this.segmentedControl.setAction(this, function(control, index){
            this.pageController.showPageAtIndex(index);
        });

        this.runButton = MUIOutlet(this, 'run-btn', 'MUIButton');
        this.runButton.setAction(this, function(button){            
            if (this.script == null) return;

            if (this.script.filters.count > 0) {
                this.showFiltersViewController();
            }
            else {
                this.showResultsViewController();                
            }            
        });

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(control){
            if (this.script == null) return;

            let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
            if (this.sqlViewController.sqlEditor != null) this.script.query = this.sqlViewController.sqlEditor.text;
            if (this.scriptViewController.scriptEditor != null) this.script.code = this.scriptViewController.scriptEditor.text;
            ad.managedObjectContext.save();
        });

        this.pageController = MUIOutlet(this, 'page-controller', 'MUIPageController');

        this.summaryViewController = new DBToolSummaryViewController("db-tool-summary-view");
        this.summaryViewController.initWithResource("layout/dbtool/DBToolSummaryView.html");
        this.pageController.addPageViewController(this.summaryViewController);

        this.sqlViewController = new DBToolSQLViewController("db-tool-sql-view");
        this.sqlViewController.initWithResource("layout/dbtool/DBToolSQLView.html");
        this.pageController.addPageViewController(this.sqlViewController);

        this.scriptViewController = new DBToolScriptViewController("db-tool-script-view");
        this.scriptViewController.initWithResource("layout/dbtool/DBToolScriptView.html");
        this.pageController.addPageViewController(this.scriptViewController);
    }

    private script:DBScript = null;
    private setScript(script:DBScript){

        this.script = script;

        this.summaryViewController.script = script;
        this.sqlViewController.script = script;
        this.scriptViewController.script = script;

        this.runButton.setEnabled(script ? true : false);
    }     

    private showFiltersViewController(){
        let vc = new DBToolFiltersViewController("db-tool-filters-view");
        vc.initWithResource("layout/dbtool/DBToolFiltersView.html");
        vc.delegate = this;
        vc.filters = this.script.filters;

        this.presentViewController(vc, true);
    }

    private showResultsViewController(){
        let vc = new DBToolResultsViewController("dbtool-results-view");
        vc.initWithResource("layout/dbtool/DBToolResultsView.html");
        vc.script = this.script;

        this.navigationController.pushViewController(vc);
    }

    didCloseDBToolFiltersViewController(controller:DBToolFiltersViewController, keysAndValues){
        let vc = new DBToolResultsViewController("dbtool-results-view");
        vc.initWithResource("layout/dbtool/DBToolResultsView.html");
        vc.script = this.script;
        vc.keysAndValues = keysAndValues;

        this.navigationController.pushViewController(vc, true);
    }

    //
    // Select entity view controller
    //

    fetchRequestForController(controller:SelectEntityViewController):MIOFetchRequest{

        if (controller.identifier == "DBScript"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

            let fetchRequest = DBHelper.listFetchRequestWithEntityName("DBScript", sd, null);
            fetchRequest.relationshipKeyPathsForPrefetching = ["filters"];

            return fetchRequest;
        }

        return null;
    }

    didSelectObjectFromSelectViewController(controller:SelectEntityViewController, item:MIOManagedObject){        
        let dismiss = true;

        if (controller.identifier == "DBScript") {
            this.setScript(item as DBScript);
        }

        return dismiss;
    }
}