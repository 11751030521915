class ProductWarehouseStockCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    private minTextField:MUITextField = null;
    private maxTextField:MUITextField = null;
    private defaultSwitchButton:MUISwitchButton = null;

    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter as MIONumberFormatter;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "warehouse-name-lbl", "MUILabel");

        this.minTextField = MUIOutlet(this, "warehouse-min-tf", "MUITextField");
        this.minTextField.setOnChangeText(this, function(control, value){
            let q = this.nf.numberFromString(value);
            this._productWarehouse.minQuantity = q;
        });

        this.maxTextField = MUIOutlet(this, "warehouse-max-tf", "MUITextField");        
        this.maxTextField.setOnChangeText(this, function(control, value){            
            let q = this.nf.numberFromString(value);
            this._productWarehouse.maxQuantity = q;
        });

        // this.defaultSwitchButton = MUIOutlet(this, 'default-switch','MUISwitchButton');
        // this.defaultSwitchButton.setOnChangeValue(this, function(control, value){
        //     this._productWarehouse.isDefault = value;
        // });  
    }

    private _productWarehouse:ProductWarehouse = null;
    set item(item:ProductWarehouse){
        this._productWarehouse = item;        

        if (item.warehouse == null) {
            this.nameLabel.text = MIOLocalizeString("WAREHOUSE DELETED", "WAREHOUSE DELETED");
        } else {
            this.nameLabel.text = item.warehouse.name;
            this.minTextField.text = item.minQuantity;
            this.maxTextField.text = item.maxQuantity;
            // this.defaultSwitchButton.on = item.isDefault;
        }
    }    
}