
/// <reference path="../notes/NoteDetailViewController.ts" />

class BuyOrderDetailViewController extends NoteDetailViewController
{
    private editHeaderView:BuyOrderDetailHeaderView = null;
    private noEditHeaderView:BuyOrderDetailHeaderView = null;

    viewDidLoad(){
        super.viewDidLoad();
 
        this.editHeaderView = MUIOutlet(this, "edit-header-view", "BuyOrderDetailHeaderView");            
        this.noEditHeaderView = MUIOutlet(this, "no-edit-header-view", "BuyOrderDetailHeaderView");

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();
    }

    protected updateUI(){        
        this.editHeaderView.note = this.stockNote;
        this.noEditHeaderView.note = this.stockNote;
        this.editHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? true : false;
        this.noEditHeaderView.hidden = this.stockNote.status == StockNoteStatus.Processed ? false : true;

        this.invalidateFetch();
    }

    // titleForHeaderInSection(tableView:UITableView, section:number){
    //     if (section == this.fetchedResultsController.sections.length) return null;

    //     let ip = MIOIndexPath.indexForRowInSection(0, section);
    //     let item = this.fetchedResultsController.objectAtIndexPath(ip) as StockNoteLine;

    //     return item.product?.stockCategory?.name;
    // }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors:MIOSortDescriptor[] = [];
        if (this.stockNote.status != StockNoteStatus.None) {
            sortDescriptors.push( MIOSortDescriptor.sortDescriptorWithKey('legalEntityName', true) );
            sortDescriptors.push( MIOSortDescriptor.sortDescriptorWithKey('productName', true) );
        }
        sortDescriptors.push( MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true) );
        
        let predicateFormat = 'note.identifier == ' + this.stockNote.identifier;
        if (this.searchString != null) {
            predicateFormat += " AND productName CONTAINS '" + this.searchString + "'";
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("BuyOrderLine", sortDescriptors, predicateFormat);
        fetchRequest.relationshipKeyPathsForPrefetching = ["inputFormat", "tax", "product", "legalEntity", "product.defaultWarehouse"];
        
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }   
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){
        super.controllerDidChangeContent(controller);
        let [base, tax, total] = this.computeTotals();
        this.totalLabel.text = this.cf.stringFromNumber(total);
    }
}