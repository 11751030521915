

/// <reference path="CoreAccounting.ts" />

// Generated class BankAccount_ManagedObject

class BankAccount_ManagedObject extends CoreAccounting
{

    // Property: balance
    set balance(value:number) {
        this.setValueForKey(value, 'balance');
    }
    get balance():number {
        return this.valueForKey('balance');
    }
    set balancePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'balance');
    }
    get balancePrimitiveValue():number {
        return this.primitiveValueForKey('balance');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }
    // Relationship: bank
    set bank(value:Bank) {
        this.setValueForKey(value, 'bank');
    }
    get bank():Bank {
        return this.valueForKey('bank') as Bank;
    }
    // Relationship: info
    set info(value:BankAccountInfo) {
        this.setValueForKey(value, 'info');
    }
    get info():BankAccountInfo {
        return this.valueForKey('info') as BankAccountInfo;
    }
}
