

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ConsumptionProfile_ManagedObject

class ConsumptionProfile_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Relationship: ellaborationConsumptionWarehouses
    protected _ellaborationConsumptionWarehouses:MIOManagedObjectSet = null;
    get ellaborationConsumptionWarehouses():MIOManagedObjectSet {
        return this.valueForKey('ellaborationConsumptionWarehouses');
    }
    addEllaborationConsumptionWarehousesObject(value:EllaborationConsumptionWarehouse) {
        this._addObjectForKey(value, 'ellaborationConsumptionWarehouses');
    }
    removeEllaborationConsumptionWarehousesObject(value:EllaborationConsumptionWarehouse) {
        this._removeObjectForKey(value, 'ellaborationConsumptionWarehouses');
    }

    // Relationship: locationCategories
    protected _locationCategories:MIOManagedObjectSet = null;
    get locationCategories():MIOManagedObjectSet {
        return this.valueForKey('locationCategories');
    }
    addLocationCategoriesObject(value:LocationCategory) {
        this._addObjectForKey(value, 'locationCategories');
    }
    removeLocationCategoriesObject(value:LocationCategory) {
        this._removeObjectForKey(value, 'locationCategories');
    }
}
