

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class BookingGroup_ManagedObject

class BookingGroup_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: confirmationEmail
    set confirmationEmail(value:boolean) {
        this.setValueForKey(value, 'confirmationEmail');
    }
    get confirmationEmail():boolean {
        return this.valueForKey('confirmationEmail');
    }
    set confirmationEmailPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'confirmationEmail');
    }
    get confirmationEmailPrimitiveValue():boolean {
        return this.primitiveValueForKey('confirmationEmail');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Relationship: bookings
    protected _bookings:MIOManagedObjectSet = null;
    get bookings():MIOManagedObjectSet {
        return this.valueForKey('bookings');
    }
    addBookingsObject(value:Booking) {
        this._addObjectForKey(value, 'bookings');
    }
    removeBookingsObject(value:Booking) {
        this._removeObjectForKey(value, 'bookings');
    }
}
