
class AccountSerialSequenceViewController extends MUIViewController
{
    static newInstance() : AccountSerialSequenceViewController {
        let vc = new AccountSerialSequenceViewController("account-serial-sequence-view");
        vc.initWithResource("layout/serialsequence/AccountSerialSequenceView.html");
        return vc;
    }

    private addButton:MUIButton = null;
    private saveButton:MUIButton = null;
    private tableView:UITableView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function(){
            this.addNewSerialSequence();
        })

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        })

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.tableView.reloadData();
    }  

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let cell = tableView.dequeueReusableCellWithIdentifier('AccountSequenceCell') as AccountSequenceCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as AccountingSequence;
        cell.item = item;
            
        return cell;
    }        

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {        
        return UITableViewCellEditingStyle.Delete;
    }
    
    commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:UITableViewCellEditingStyle, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as NumberSerialSequence;

        if (editingStyle == UITableViewCellEditingStyle.Delete) {
            DBHelper.deleteObjectFromMainContext(item, true);
        }
    }

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
        
        let filterString = null; //'appID == null';
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('name', true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName('AccountingSequence', sortDescriptors, filterString);
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }

    private addNewSerialSequence(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('SERIAL SEQUENCE','SERIAL SEQUENCE'), MIOLocalizeString('ADD NEW SERIAL SEQUENCE','ADD NEW SERIAL SEQUENCE'), MUIAlertViewStyle.Default);
        
        avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
            DBHelper.sharedInstance().addObserverForEntity(avc, 'LedgerAccount', null, null, null, function (objects:LedgerAccount[]){
                comboBox.removeAllItems();
                for (let i = 0; i < objects.length; i++)
                    comboBox.addItem(objects[i].prefix + " - " + objects[i].name, i);
            });
        });
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('DONE','DONE'), MUIAlertActionStyle.Default, this, function(){            
            let account_index = parseInt(avc.comboBoxes[0].getSelectedItem());
            
            let account = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, account_index, 'LedgerAccount');
    
            this.addAccountingSequence(account);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Cancel, this, function(){}));
        
        avc.addCompletionHandler(this, function () {
            // Release the observers
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, 'LedgerAccount');            
        });

        this.presentViewController(avc, true);
    };

    private addAccountingSequence( account:LedgerAccount )
    {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let moc = ad.managedObjectContext;

        // create new serialSequence
        let newSerialSequence = MIOEntityDescription.insertNewObjectForEntityForName("AccountingSequence", moc) as AccountingSequence;
        newSerialSequence.identifier = MIOUUID.UUID().UUIDString;        
                
        newSerialSequence.name = account.description;
        newSerialSequence.entityNameType = "LedgerAccount";
        newSerialSequence.entityType = 0;
        newSerialSequence.ledgerAccount = account;
        newSerialSequence.ledgerAccountName = account.name;
        newSerialSequence.prefix = account.prefix;

        moc.save();
    }
}