
enum PromotionalCodeType
{
    Custom,
    EAN8,
    EAN13
}   

class AppHelper extends MIOObject
{
    // userID = null;
    // userEmail = null;
    // userName = null;

    placesByReferenceID = {};  
    defaultCountry:Country = null;   
    defaultWarehouse:Warehouse = null;
    defaultBusinessArea:BusinessArea = null;
    defaultVAT:Tax = null;
    aditionalLanguages:string[] = [];
    
    private static _sharedInstance: AppHelper = null;
    static sharedInstance(): AppHelper {

        if (this._sharedInstance == null) {
            this._sharedInstance = new AppHelper();
            this._sharedInstance.init();
        }

        return this._sharedInstance;
    }
    
    static fillApplicationComboBox(comboBox: MUIComboBox, objects, selectedApplicationID?) {

        comboBox.removeAllItems();
        comboBox.addItem(MIOLocalizeString('NO APP SELECTED','No App Selected'),'NULL');

        let selected = 'NULL';

        for (let i = 0; i < objects.length; i++) {
            let application:Application = objects[i];
            comboBox.addItem(application.name, application.identifier);

            if (selectedApplicationID === application.identifier) {
                selected = application.identifier;
            }
        }

        comboBox.selectItem(selected);
    }

    static fillPayMethodComboBox(comboBox: MUIComboBox, objects, selectedPayMethod?:PayMethod) {

        comboBox.removeAllItems();
        if (selectedPayMethod == null) {
            comboBox.addItem(MIOLocalizeString('SELECT','Select...'), "-1");
        }        

        for (let i = 0; i < objects.length; i++) {
            let payMethod = objects[i] as PayMethod;
            comboBox.addItem(payMethod.name, payMethod.identifier);
        }
        
        if (selectedPayMethod != null) comboBox.selectItem(selectedPayMethod.identifier);        
    }

    static fillTaxComboBox(comboBox: MUIComboBox, objects, selectedTax?) {

        comboBox.removeAllItems();

        for (let i = 0; i < objects.length; i++) {
            let tax: Tax = objects[i];
            comboBox.addItem(tax.name, i);

            if (selectedTax === tax) {
                comboBox.selectItem(i);
            }                
        }
    }

    static fillSupplierProductComboBox(comboBox: MUIComboBox, product:Product, objects, allowOtherSupplier = false, selectedSupplier?) {

        comboBox.removeAllItems();
        let cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
        
        let defaultSupplierIndex = null;
        let selectedSupplierIndex = null;
        
        for (let i = 0; i < objects.length; i++) {
            let supplierProduct:SupplierProduct = objects[i];
            let supplier:Supplier = supplierProduct.supplier;
            let supplierName = supplier.name;
            
            if(supplierProduct.isDefault) defaultSupplierIndex = i;
            
            supplierName += ' ('+ cf.stringFromNumber(supplierProduct.computedLastPrice) + ')';
               
            comboBox.addItem(supplierName, i ); 
            if(selectedSupplier != null && selectedSupplier.identifier == supplier.identifier)
                selectedSupplierIndex = i;
        }

        if (allowOtherSupplier)
            comboBox.addItem(MIOLocalizeString('OTHER', 'Other'), 'other');
        
        if (selectedSupplierIndex != null) {
            comboBox.selectItem(selectedSupplierIndex); 
        }
        else if(defaultSupplierIndex != null)
        {
            comboBox.selectItem(defaultSupplierIndex); 
        }
        else
        {
            comboBox.selectItem(0); 
        }
    }
    

    static fillUnitComboBox(comboBox: MUIComboBox, product, objects, allowNewProductFormat = true, selectedUnit?) {

        comboBox.removeAllItems();
        let defaultUnit = MeasureUnitType.None;

        // if (product) {
        //     let measureType = product.measureType;
        //     if (product.isContainer) {
        //         let label = 'Cont (' +
        //             product.quantity +
        //             ' ' +
        //             MeasureUnits.toString(product.measureUnitType) +
        //             ')';

        //         comboBox.addItem(label, MeasureUnitType.Container);
        //         defaultUnit = MeasureUnitType.Container;
        //     }

        //     switch (measureType) {
        //         case MeasureType.MeasureTypeUnit:
        //             comboBox.addItem('U', MeasureUnitType.Unit);
        //             break;
        //         case MeasureType.MeasureTypeVolume:
        //             comboBox.addItem('L', MeasureUnitType.VolumeLitre);
        //             comboBox.addItem('cl', MeasureUnitType.VolumeCentilitre);
        //             break;
        //         case MeasureType.MeasureTypeMass:
        //             comboBox.addItem('Kg', MeasureUnitType.MassKilogram);
        //             comboBox.addItem('gr', MeasureUnitType.MassGram);
        //             break;
        //     }

        //     defaultUnit = product.quantity > 0 ? MeasureUnitType.Container : product.measureUnitType;

        //     for (let i = 0; i < objects.length; i++) {
        //         let unit = objects[i];
        //         comboBox.addItem(unit.name, i + 100); // Le sumo 100 para que no colisione con las unidades de medida.

        //         if (unit === selectedUnit) {
        //             comboBox.selectItem(i + 100); 
        //         }                
        //     }

        //     if (selectedUnit == null) {
        //         comboBox.selectItem(0); 
        //     }  
        //     else if (selectedUnit < 100) {
        //         comboBox.selectItem(selectedUnit); 
        //     }               

        //     if (allowNewProductFormat)
        //         comboBox.addItem(MIOLocalizeString('ADD NEW','Add new...'), 'new');
        // }
        
    }

    static fillProductUnitsComboBox(comboBox:MUIComboBox, product:Product, allowPercentage?:boolean, selected?:any){         
        if (comboBox == null) return; 
        comboBox.removeAllItems();        
        let types = product.isContainer == true ? MeasureUnits.measureUnitsFromUnitType(product.containerMeasureType) : MeasureUnits.measureUnitsFromUnitType(product.measureType);
        for (let index = 0; index < types.length; index++){
            let t = types[index];
            comboBox.addItem(MeasureUnits.toString(t), t);            
        }

        if (product.isContainer == true) comboBox.addItem(MeasureUnits.toString(MeasureUnitType.Container), MeasureUnitType.Container);
        if (allowPercentage == true) comboBox.addItem(MeasureUnits.toString(MeasureUnitType.Percentage), MeasureUnitType.Percentage);

        comboBox.selectItem(selected != null ? selected : (product.measureType != MeasureUnitType.Container ? product.measureType : product.containerMeasureType));
    }


    static initMeasureComboBox(comboBox:MUIComboBox, product:Product, defaultUnit:any, withPercentage:boolean = false)
    {
        comboBox.removeAllItems();
        let type = product.measureType;

        // switch(type)
        // {
        //     case MeasureType.Unit:
        //     comboBox.addItem(MeasureUnits.toString(MeasureUnitType.Unit), MeasureUnitType.Unit);
           
        //     break;

        //     case MeasureType.MeasureTypeMass:
        //     comboBox.addItem(MeasureUnits.toString(MeasureUnitType.MassGram),  MeasureUnitType.MassGram);
        //     comboBox.addItem(MeasureUnits.toString(MeasureUnitType.MassKilogram), MeasureUnitType.MassKilogram);
           
        //     break;

        //     case MeasureType.MeasureTypeVolume:
            
        //     comboBox.addItem(MeasureUnits.toString(MeasureUnitType.VolumeCentilitre), MeasureUnitType.VolumeCentilitre);
        //     comboBox.addItem(MeasureUnits.toString(MeasureUnitType.VolumeLitre), MeasureUnitType.VolumeLitre);
            
        //     break;
        // }

        // if(product.quantity > 0)
        // {
        //     let label = MeasureUnits.toString(MeasureUnitType.Container);
        //     label += ' (' + product.quantity + ' ' + MeasureUnits.toString(product.measureUnitType) + ')' ;
        //     comboBox.addItem(label, MeasureUnitType.Container);
        // }
        // if(withPercentage)
        //     comboBox.addItem(MeasureUnits.toString(MeasureUnitType.Percentage), MeasureUnitType.Percentage);

        // if (defaultUnit != null) {
        //     comboBox.selectItem(defaultUnit);
        // }
    }

    static selectUnitItem(observer, comboBox: MUIComboBox, selectedUnit)
    {
        if (selectedUnit == null) {
            comboBox.selectItem(0); 
        }  
        else if (selectedUnit < 100) {
            comboBox.selectItem(selectedUnit); 
        } 
        else { 
            
            let index =  DBHelper.sharedInstance().indexOfObjectForEntity(observer, 'StockInputFormat', selectedUnit);
            comboBox.selectItem(index + 100); 
        }
    }

    static selectTaxItem(observer, comboBox: MUIComboBox, selectedTax)
    {    
        let index =  DBHelper.sharedInstance().indexOfObjectForEntity(observer, 'Tax', selectedTax);
        comboBox.selectItem(index);    
    }
    
    static showErrorMessage(parent, title, message){
        AppHelper.showAlert(parent, title, message);
    }

    static showInfoMessage(parent, title, message){
        AppHelper.showAlert(parent, title, message);
    }

    static showAlert(parent, title, message){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString(title, title), MIOLocalizeString(message, message), MUIAlertViewStyle.Default);
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, null, null));
        
        if (parent != null) {
            parent.presentViewController(avc, true);
        }
        else {
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            ad.window.rootViewController.presentViewController(avc, true);
        }        
    }


    static checkConsumptionQuantities(productQuantity, productMeasureUnit, consumption, consumptionUnit, loss, lossUnit)
    {        
        if(consumptionUnit == lossUnit)
        {
            return (consumption - loss) >= 0;
        }
        
        if(consumptionUnit == MeasureUnitType.Container)
        {
            consumption = consumption * productQuantity;
            consumptionUnit = productMeasureUnit;
        }
        if(lossUnit == MeasureUnitType.Container)
        {
            loss = loss * productQuantity;
            lossUnit = productMeasureUnit;
        }
        
        if(consumptionUnit == MeasureUnitType.MassKilogram && lossUnit == MeasureUnitType.MassGram)
        {
            return (consumption - (loss / 1000))  >= 0;
        }
        if(consumptionUnit == MeasureUnitType.MassGram && lossUnit == MeasureUnitType.MassKilogram)
        {
            return (consumption - (loss * 1000))  >= 0;
        }
        if(consumptionUnit == MeasureUnitType.VolumeLitre && lossUnit == MeasureUnitType.VolumeCentilitre)
        {
            return (consumption - (loss / 100))  >= 0;
        }
        if(consumptionUnit == MeasureUnitType.VolumeCentilitre && lossUnit == MeasureUnitType.VolumeLitre)
        {
            return (consumption - (loss * 100))  >= 0;
        }
        if(lossUnit == MeasureUnitType.Percentage)
            return loss <= 100 && loss >= 0;
    }

    constructor() {
        super();
        if (AppHelper._sharedInstance) {
            throw new Error("AppHelper: Instantiation failed: Use sharedInstance() instead of new.");
        }
    }

    resetViewControllers(){
        this.emptyViewControllers = {};        
        this.dateSelectionViewController = null;
        this.selectCountryViewController = null;        
        this.selectStockProductViewController = null;
    }

    dismissAllPopovers(){

        if (this.dateSelectionViewController != null && this.dateSelectionViewController.isPresented) {
            this.dateSelectionViewController.dismissViewController(true);
        }
        
        if ( this.editUserProfileViewController != null && this.editUserProfileViewController.isPresented) {
            this.editUserProfileViewController.dismissViewController(true);
        }

        if ( this.globalActionsViewController != null && this.globalActionsViewController.isPresented) {
            this.globalActionsViewController.dismissViewController(true);
        }

        if (this.filterViewController != null && this.filterViewController.isPresented) {
            this.filterViewController.dismissViewController(true);
        }
    }

    // View Controllers
    presentWorkspaceViewController(fromVC:MUIViewController) 
    {    
        let vc = WorkspaceViewController.newInstance()
        
        let nc = new MUINavigationController('workspace_nav_controller');
        nc.initWithRootViewController(vc);

        fromVC.presentViewController(nc, true);
    }

    presentSectionViewController(fromVC:MUIViewController){
        let vc = SectionHelper.sharedInstance().sectionsViewController();        
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.window.rootViewController.presentViewController(vc, true);
    }

    private dateSelectionViewController:CalendarViewController = null;
    presentDateSelectionViewController(fromVC:MUIViewController, delegate?){

        if (this.dateSelectionViewController == null) {
            this.dateSelectionViewController = new CalendarViewController('calendar-view');
            this.dateSelectionViewController.initWithResource('layout/calendar/CalendarView.html');
        }
        
        if (delegate == null) this.dateSelectionViewController.delegate = fromVC;
        else this.dateSelectionViewController.delegate = delegate;
        
        if (this.dateSelectionViewController.isPresented) {
            this.dateSelectionViewController.dismissViewController(true);
        } else {
            this.dismissAllPopovers();
            if (fromVC != null) fromVC.presentViewController(this.dateSelectionViewController, true);
            else this.presentViewController(this.dateSelectionViewController, true);
        }
    }

    private editUserProfileViewController = null;
    presentEditProfileViewController(fromVC:MUIViewController, delegate?) {

        if (this.editUserProfileViewController == null) {
            let vc = new EditUserProfileViewController("edit-user-profile-view");
            vc.initWithResource("layout/edituserprofile/EditUserProfileView.html");
            
            this.editUserProfileViewController = new MUINavigationController('edit_user_nav_controller');
            this.editUserProfileViewController.initWithRootViewController(vc);
        }
        
        if (delegate == null) this.editUserProfileViewController.delegate = fromVC;
        else this.editUserProfileViewController.delegate = delegate;
        
        if (this.editUserProfileViewController.isPresented) {
            this.editUserProfileViewController.dismissViewController(true);
        } else {
            this.dismissAllPopovers();
            if (fromVC != null) fromVC.presentViewController(this.editUserProfileViewController, true);
            else this.presentViewController(this.editUserProfileViewController, true);
        }
    }

    generateCodes(type:PromotionalCodeType, prefix:string, startIndex:number, endIndex:number) : any[] {
        let codes = []
        for(let index = startIndex; index < (endIndex + 1); index++){
            let code = this.generateCodeType(type, prefix, index);
            codes.addObject(code);
        }
        return codes;
    }

    generateCodesForOffer(offer:Offer, type:PromotionalCodeType, prefix, startIndex, endIndex, startDate:Date, endDate:Date){
        
        let moc:MIOManagedObjectContext = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        for(let index = startIndex; index < (endIndex + 1); index++){
            let promotionalCode = MIOEntityDescription.insertNewObjectForEntityForName("PromotionalCode", moc) as PromotionalCode;
            promotionalCode.code = this.generateCodeType(type, prefix, index);
            promotionalCode.codePrefix = prefix;
            promotionalCode.offer = offer;
            promotionalCode.beginDateTime= startDate;
            promotionalCode.endDateTime = endDate;
            promotionalCode.maxNumberOfUses = 1;
            promotionalCode.codeNumber = index;
        }

        moc.save();
    }

    generateCodeType(type:PromotionalCodeType, prefix, number){
        let code = null;

        switch(type) {
            case PromotionalCodeType.EAN8:
                code = this.eanCode(8, prefix, number);
                break;

            case PromotionalCodeType.EAN13:
                code = this.eanCode(13, prefix, number);
                break;

            case PromotionalCodeType.Custom:
                code = this.customCode(prefix, number);
        }

        return code;
    }

    customCode(prefix, number):string{
        if (number == null) return null;
        let value = number.toString();
        let code = "";
        if (prefix != null) code += prefix;

        let count = 13 - value.length - code.length;
        for (let index = 0; index < count; index++){
            code += "0";
        }
        code += value;

        return code;
    }

    eanCode(eanType, prefix:string, number):string{
        if (number == null) return null;
        let value = number.toString();
        let code = "";
        if (prefix != null) code += prefix;

        let count = (eanType - 1) - value.length - code.length;
        for (let index = 0; index < count; index++){
            code += "0";
        }
        code += value;
        code += this.eanChecksum(code);
        return code;
    }

    private eanChecksum(value) {
        let checksum = 0;
        value = value.split('').reverse();
        for(let pos = 0; pos < value.length; pos++){            
            checksum += value[pos] * (3 - 2 * (pos % 2));
        }
        return ((10 - (checksum % 10 )) % 10);
    }

    selectViewController(identifier:string, titleKey:string, noSelectionCell:boolean, allowMultipleSelection:boolean, context, delegate, navController?){
        
        let vc = new SelectEntityViewController('select-view');
        vc.initWithResource('layout/base/SelectView.html');
        vc.identifier = identifier;
        vc.delegate = delegate;
        vc.titleKey = titleKey;
        vc.noSelectionCell = noSelectionCell;
        vc.allowMultipleSelection = allowMultipleSelection;
        vc.context = context;
        vc.allowSearch = true;

        if (navController == true){
            let nc = new MUINavigationController();
            nc.initWithRootViewController(vc);
            return nc;    
        }

        return vc;
    }

    private emptyViewControllers = {};
    emptyViewControllerForIndentifier(identifier:string, type:NoItemSelectedViewControllerType):NoItemSelectedViewController {
        let evc = this.emptyViewControllers[identifier] as NoItemSelectedViewController;
        if (evc != null) return evc;

        evc = new NoItemSelectedViewController("no-item-selected-view");
        evc.initWithResource("layout/base/NoItemSelectedView.html");
        evc.type = type;
        this.emptyViewControllers[identifier] = evc;

        return evc;
    }
    
    presentViewController(vc:MUIViewController, animate:boolean){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let rvc = ad.window.rootViewController;

        rvc.presentViewController(vc, animate);
    }

    presentPopOverViewController(vc:MUIViewController, view:MUIView, animate:boolean){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let rvc = ad.window.rootViewController;

        vc.modalPresentationStyle = MUIModalPresentationStyle.Popover;            

        let pc = vc.popoverPresentationController;
        pc.sourceRect = view.frame;
        pc.sourceView = view;   

        rvc.presentViewController(vc, animate);
    }

    //
    // Alerts
    //

    private filterViewController:FilterViewController = null;
    showFilterViewController(fromVC:MUIViewController, filters, completion){

        if (this.filterViewController == null) {
            this.filterViewController = new FilterViewController("filter-view");
            this.filterViewController.initWithResource("layout/filter/FilterView.html");
        }

        this.filterViewController.filters = filters;
        this.filterViewController.setCompletionBlock(fromVC, completion);

        if (this.filterViewController.isPresented) {
            this.filterViewController.dismissViewController(true);
        } else {
            this.dismissAllPopovers();
            if (fromVC != null) fromVC.presentViewController(this.filterViewController, true);
            else this.presentViewController(this.filterViewController, true);
        }
    }

    showAddBookingSourceAlert(target, completion){
        let avc = new MUIAlertViewController();
        avc.initWithTitle("New source", "Create new source", MUIAlertViewStyle.Default);
        
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("Name");
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("Phone");
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("Email");
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle('OK', MUIAlertActionStyle.Default, this, function(){
            let name = avc.textFields[0].text;
            let phone = avc.textFields[1].text;
            let email = avc.textFields[2].text;
                        
            let source = BookingSource.insertNewSource(name, phone, email);
            
            if (target != null && completion != null) completion.call(target, source);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Cancel', MUIAlertActionStyle.Cancel, null, null));                

        this.presentViewController(avc, true);
    }
        
    private popoverView:MUIView = null;
    private popoverIsVisible = false;
    private selectCompletion = null;
    private selectTarget = null;
    private popoverDismissBlock = null;
    
    showSelectViewControllerFromView(view:MUIView, vc:MUIViewController, target:any, completion:any, dissmissCompletion?:any): MUIPopoverPresentationController {

        this.selectCompletion = completion;
        this.selectTarget = target;
        this.popoverDismissBlock = dissmissCompletion;

        if (view != null) {        
            vc.modalPresentationStyle = MUIModalPresentationStyle.Popover;            

            let pc = vc.popoverPresentationController;
            pc.sourceRect = view.frame;
            pc.sourceView = view;   
            pc.delegate = this;
            
            this.popoverView = view;        

            if (this.popoverIsVisible == false) {
                this.popoverIsVisible = true;
                this.presentViewController(vc, true);
            }

            return pc;
        }
        else{
            this.presentViewController(vc, true);
        }

        return null;
    }

    private customSelectEntityName:string = null;            
    private customSelectEntityPredicateFormat:string = null;
    private customSelectEntitySortDescriptors:[MIOSortDescriptor] = null;

    showCustomSelectViewControllerFromView(view:MUIView, entityName:string, titleKey:string, predicateFormat:string, target, completion, noSelectionCell?:boolean){

        this.customSelectEntityName = entityName;                
        this.customSelectEntityPredicateFormat = predicateFormat;
        this.customSelectEntitySortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey(titleKey, true)];    

        let vc = this.selectViewController("CUSTOM", titleKey, false, false, null, this) as SelectEntityViewController;
        vc.allowSearch = true;
        vc.noSelectionCell = noSelectionCell ? noSelectionCell : false;
        this.selectCompletion = completion;
        this.selectTarget = target;

        this.showSelectViewControllerFromView(view, vc, target, completion);
    }

    showCustomSelectMultiViewControllerFromView(view:MUIView, entityName:string, titleKey:string, predicateFormat:string, target, completion, noSelectionCell?:boolean){

        this.customSelectEntityName = entityName;                
        this.customSelectEntityPredicateFormat = predicateFormat;
        this.customSelectEntitySortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey(titleKey, true)];    

        let vc = this.selectViewController("CUSTOM", titleKey, false, true, null, this) as SelectEntityViewController;
        vc.allowSearch = true;
        vc.noSelectionCell = noSelectionCell ? noSelectionCell : false;
        this.selectCompletion = completion;
        this.selectTarget = target;

        this.showSelectViewControllerFromView(view, vc, target, completion);
    }

    private selectCountryViewController:SelectEntityViewController = null;
    showSelectCountryViewControllerFromView(view:MUIView, country:Country, target:any, completion:any){
        if (this.selectCountryViewController == null) {
            let vc = this.selectViewController("Country", "name", false, false, null, this) as SelectEntityViewController;
            vc.allowSearch = true;
            this.selectCountryViewController = vc;
        }

        this.selectCountryViewController.selectedObject = country;
        this.showSelectViewControllerFromView(view, this.selectCountryViewController, target, completion);
	}
		
    showSelectDepartmentViewControllerFromView(view:MUIView, department:Department, target:any, completion:any){
    
        let vc = this.selectViewController("Department", "name", false, false, null, this) as SelectEntityViewController;
        vc.allowSearch = true;        
        vc.selectedObject = department;
        this.showSelectViewControllerFromView(view, vc, target, completion);
    }
    
    showSelectWorkerRoleViewControllerFromView(view:MUIView, workerRole:WorkerRole, target:any, completion:any){

        let vc = this.selectViewController("WorkerRole", "name", false, false, null, this) as SelectEntityViewController;
        vc.allowSearch = true;

        vc.selectedObject = workerRole;
        this.showSelectViewControllerFromView(view, vc, target, completion);
    }
    
    showSelectLanguageViewControllerFromView(view:MUIView, language:Language, allowNoSelection:boolean, allowMultipleSelection:boolean, target, completion){
                
        let vc = this.selectViewController("Language", "name", allowNoSelection, allowMultipleSelection, null, this) as SelectEntityViewController;
        vc.allowSearch = true;        

        vc.selectedObject = language;
        this.showSelectViewControllerFromView(view, vc, target, completion);
    }

    showSelectClientViewControllerFromView(view:MUIView, value:string, showSearchBar:boolean, target, completion, addTarget, addBlock){

        let vc = this.selectViewController("Client", "name", false, false, null, this) as SelectEntityViewController;
        vc.allowSearch = showSearchBar;
        vc.allowAddEntity = true;
        vc.addTarget = addTarget;
        vc.addBlock = addBlock;

        vc.searchString = value;

        this.showSelectViewControllerFromView(view, vc, target, completion);
    }

    
    showSelectPaymethodViewControllerFromView(view:MUIView, method:PayMethod, target, completion){
        let vc = this.selectViewController("PayMethod", "name", false, false, null, this) as SelectEntityViewController;                        
        vc.selectedObject = method;
        
        this.showSelectViewControllerFromView(view, vc, target, completion);
    }

    
    showSelectSupplierViewControllerFromView(view:MUIView, value:string, showSearchBar:boolean, target, completion){
    
        let vc = this.selectViewController("Supplier", "name", true, false, null, this) as SelectEntityViewController;
        vc.allowSearch = showSearchBar;
        vc.searchString = value;

        this.showSelectViewControllerFromView(view, vc, target, completion);
    }
    
    private selectProductViewController:SelectEntityViewController = null;
    showSelectProductViewControllerFromView(view:MUIView, value:string, context:any, target, completion, addTarget, addBlock, allow_search:boolean = false){

        if ( this.selectProductViewController == null) {
            let vc = this.selectViewController("Product", "name", false, false, context, this) as SelectEntityViewController;
            vc.allowAddEntity = addTarget ? true : false;
            vc.addTarget = addTarget;
            vc.addBlock = addBlock;
            vc.allowSearch = allow_search;                        
            //this.selectProductViewController.searchFormat = "name CONTAINS '%s' OR stockName CONTAINS '%s' OR barcode CONTAINS '%s' OR barcode2 CONTAINS '%s' OR ANY supplierProducts.supplierReference CONTAINS '%s'";
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            vc.searchFormat = "name CONTAINS '%s' OR stockName CONTAINS '%s' OR barcode CONTAINS '%s' OR barcode2 CONTAINS '%s'";
            this.selectProductViewController = vc;
        }
    
        this.selectProductViewController.searchString = value;

        this.showSelectViewControllerFromView(view, this.selectProductViewController, target, completion);
    }

    private selectStockProductViewController:SelectEntityViewController = null;
    showSelectStockProductViewControllerFromView(view:MUIView, value:string, target, completion, addTarget, addBlock){
        if (this.selectStockProductViewController == null) {
            let vc = this.selectViewController("StockProduct", "stockName", false, false, null, this) as SelectEntityViewController;
            vc.allowAddEntity = addTarget ? true : false;
            vc.addTarget = addTarget;
            vc.addBlock = addBlock;
            vc.defaultKey = "name";
            this.selectStockProductViewController = vc;
            this.selectStockProductViewController.searchFormat = "name CONTAINS '%s' OR stockName CONTAINS '%s' OR barcode CONTAINS '%s' OR barcode2 CONTAINS '%s' OR ANY supplierProducts.supplierReference CONTAINS '%s'";
            //this.selectStockProductViewController.searchFormat = "name CONTAINS '%s' OR stockName CONTAINS '%s' OR barcode CONTAINS '%s' OR barcode2 CONTAINS '%s'";
        }
                
        this.selectStockProductViewController.searchString = value;

        this.showSelectViewControllerFromView(view, this.selectStockProductViewController, target, completion);
    }

    dismissSelectStockProductViewController() {
        if (this.selectStockProductViewController != null) this.selectStockProductViewController.dismissViewController(true);
    }
    
    showSelectCategoryViewControllerFromView(view:MUIView, selectedCategory:ProductCategory, target, completion, addTarget, addBlock){
    
        let vc = this.selectViewController("ProductCategory", "name", false, false, null, this) as SelectEntityViewController;
        vc.allowAddEntity = addTarget ? true : false;

        vc.addTarget = addTarget;
        vc.addBlock = addBlock;
        vc.selectedObject = selectedCategory;
                        
        this.showSelectViewControllerFromView(view, vc, target, completion);
    }
    
    showSelectStockCategoryViewControllerFromView(view:MUIView, selectedCategory:StockCategory, target, completion, addTarget, addBlock){

        let vc = this.selectViewController("StockCategory", "name", false, false, null, this) as SelectEntityViewController;
        vc.allowAddEntity = addTarget ? true : false;
        
        vc.addTarget = addTarget;
        vc.addBlock = addBlock;
        vc.selectedObject = selectedCategory;
                        
        this.showSelectViewControllerFromView(view, vc, target, completion);
    }
    
    showSelectCategoriesViewControllerFromView(view:MUIView, completion:any){
        let vc = CategorySelectionViewController.newInstance();
        vc.completionBlock = completion;
        this.presentPopOverViewController(vc, view, true);
    }
    
    showSelectSupplierProductViewControllerFromView(view:MUIView, value:string, target:any, completion:any, addTarget:any, addBlock:any, context?:any){
        
        let vc = this.selectViewController("SupplierProduct", "product.name", false, false, null, this) as SelectEntityViewController;
        vc.allowAddEntity = true;
        vc.addTarget = addTarget;
        vc.addBlock = addBlock;
        vc.searchFormat = "product.name CONTAINS '%s' OR product.stockName CONTAINS '%s' OR product.barcode CONTAINS '%s' OR product.barcode2 CONTAINS '%s' OR supplierReference CONTAINS '%s'";        
        
        vc.searchString = value;
        vc.context = context;

        this.showSelectViewControllerFromView(view, vc, target, completion);
    }

    showSelectTaxViewControllerFromView(view:MUIView, product:Product, tax:Tax, target, completion){
    
        let vc = this.selectViewController("Tax", "name", false, false, null, this) as SelectEntityViewController;
        
        vc.selectedObject = tax;

        this.showSelectViewControllerFromView(view, vc, target, completion);
    }
    
    
    showSelectMeassureUnitViewControllerFromView(view:MUIView, type:MeasureUnitType, showContainers, target, completion){
    
        let vc = new SelectMeassureViewController("select-view");
        vc.initWithResource("layout/base/SelectView.html");

        vc.setType(type);
        vc.showContainers = showContainers;
        vc.target = target;
        vc.completion = completion;

        this.showSelectViewControllerFromView(view, vc, target, completion);
    }
    
    showSelectInputFormatViewControllerFromView(view:MUIView, product:Product, measureType:MeasureUnitType, containerMeasureType:MeasureUnitType, containerQuantity:any, target:any, completion:any){
        
        let vc = new SelectInputFormatViewController("select-view");
        vc.initWithResource("layout/base/SelectView.html");

        vc.setMeasureTypes(product, measureType, containerMeasureType, containerQuantity);

        vc.target = target;
        vc.completion = completion;

        this.showSelectViewControllerFromView(view, vc, target, completion);
    }

    showSelectStockInputFormatViewControllerFromView(view:MUIView, product:Product, completion:any) {
        let vc = this.selectViewController( "StockInputFormat", "name", true, false, null, this) as SelectEntityViewController;        

        vc.context = { "ProductID": product.identifier };

        this.showSelectViewControllerFromView(view, vc, this, function( this:AppHelper, controller:any, value:MIOManagedObject){
            completion( controller, value as StockInputFormat )
        });
    }
    
    showSelectWarehouseViewControllerFromView(view:MUIView, warehouse:Warehouse, allowNoSelection:boolean, allowMultipleSelection:boolean, target, completion){
    
        let vc = this.selectViewController("Warehouse", "name", allowNoSelection, allowMultipleSelection, null, this) as SelectEntityViewController;

        vc.selectedObject = warehouse;

        this.showSelectViewControllerFromView(view, vc, target, completion);
    }
    
    showSelectLocationCateogryViewControllerFromView(view:MUIView, category:LocationCategory, target:any, completion:any, addBlock:any){        
        let vc = this.selectViewController("LocationCategory", "name", false, false, null, this) as SelectEntityViewController;        
        vc.selectedObject = category;
        vc.allowAddEntity = addBlock ? true : false;
        vc.addTarget = target;
        vc.addBlock = addBlock;

        this.showSelectViewControllerFromView(view, vc, target, completion);
    }
    
    showSelectIntermediaryViewControllerFromView(view:MUIView, intermediary:Intermediary, target, completion){
    
        let vc = this.selectViewController("Intermediary", "name", true, false, null, this) as SelectEntityViewController;

        vc.selectedObject = intermediary;
        this.showSelectViewControllerFromView(view, vc, target, completion);
    }
    
    private _ledger_account_select_vc:SelectEntityViewController = null;
    showSelectLedgerAccountViewControllerFromView(view:MUIView, account:LedgerAccount, searchString:string, hideSearchBar:boolean, addTitleValue:string, target:any, completion:any) {
        
        if (this._ledger_account_select_vc == null) {
            this._ledger_account_select_vc = this.selectViewController("LedgerAccount", "description", true, false, null, this) as SelectEntityViewController;
            this._ledger_account_select_vc.searchFormat = "prefix CONTAINS '%s' OR name CONTAINS '%s'";
            this._ledger_account_select_vc.selectedObject = account;
            this._ledger_account_select_vc.allowSearch = !hideSearchBar;
            
            this._ledger_account_select_vc.allowAddEntity = true;
            this._ledger_account_select_vc.addTarget = this;
            this._ledger_account_select_vc.addBlock = function(this:AppHelper){
                AppHelper.sharedInstance().showAddLedgerAccountAlert(null, addTitleValue, (newAccount:LedgerAccount) => {
                    if (completion != null) completion.call(target, this._ledger_account_select_vc, newAccount);
                });
            };
        }
                        
        this._ledger_account_select_vc.searchString = searchString?.length > 0 ? searchString : null;
        this.showSelectViewControllerFromView(view, this._ledger_account_select_vc, target, completion, () => {
            this._ledger_account_select_vc = null;
        });     
    }
    
    showSelectLegalEntityTaxViewControllerFromView(view:MUIView, tax:LegalTax, target:any, completion:any){
        
        let vc = this.selectViewController("LegalTax", "name", true, false, null, this) as SelectEntityViewController;

        vc.selectedObject = tax;
        this.showSelectViewControllerFromView(view, vc, target, completion);
    }

    
    showSelectMaturityPaymentViewControllerFromView(view:MUIView, maturity:MaturityPayment, target:any, completion:any){
    
        let vc = this.selectViewController("MaturityPayment", "name", true, false, null, this) as SelectEntityViewController;

        vc.selectedObject = maturity;
        this.showSelectViewControllerFromView(view, vc, target, completion);
    }
    
    showSelectBookingRecommendationViewControllerFromView(view:MUIView, recommendation:BookingRecommendation, target:any, completion:any){
        let vc = this.selectViewController("BookingRecommendation", "name", true, false, null, this) as SelectEntityViewController;            
        
        vc.selectedObject = recommendation;
        this.showSelectViewControllerFromView(view, vc, target, completion);
    }
    
    showSelectRateViewControllerFromView(view:MUIView, rate:Rate, target:any, completion:any){
        let vc = this.selectViewController("Rate", "name", true, false, null, this) as SelectEntityViewController;

        vc.selectedObject = rate;
        this.showSelectViewControllerFromView(view, vc, target, completion);
    }
    
    showSelectDistributorViewControllerFromView(view:MUIView, distributor:Distributor, target:any, completion:any){
        let vc = this.selectViewController("Distributor", "name", true, false, null, this) as SelectEntityViewController;

        vc.selectedObject = distributor;
        this.showSelectViewControllerFromView(view, vc, target, completion);
    }
    
    showSelectBankInfoViewControllerFromView(view:MUIView, legalEntity:LegalEntity, target:any, completion:any){        
        let vc = this.selectViewController("LegalEntityBankAccountInfo", "title", true, false, {}, this) as SelectEntityViewController;

        vc.context["LegalEntityID"] = legalEntity.identifier;
        vc.selectedObject = legalEntity.defaultBankAccount;
        this.showSelectViewControllerFromView(view, vc, target, completion);
    }

    showSelectBusinessViewControllerFromView(view:MUIView, target:any, completion:any){        
        let vc = this.selectViewController( "BusinessLicense", "name", true, false, {}, this) as SelectEntityViewController;
        this.showSelectViewControllerFromView(view, vc, target, completion);
    }

    showSelectLicenseTypeViewControllerFromView(view:MUIView, type:LicenseType, target:any, completion:any){
        let vc = this.selectViewController( "LicenseType", "name", true, false, {}, this) as SelectEntityViewController;
        vc.selectedObject = type;
        this.showSelectViewControllerFromView(view, vc, target, completion);
    }

    showSelectLeaveReasonTypeViewControllerFromView(view:MUIView, type:LeaveReasonType, target:any, completion:any){
        let vc = this.selectViewController( "LeaveReasonType", "title", false, false, {}, this) as SelectEntityViewController;
        vc.selectedObject = type;
        this.showSelectViewControllerFromView(view, vc, target, completion);
    }

    private globalActionsViewController = null;
    showGlobalActionsViewControllerFromView(fromVC:MUIViewController, delegate?, completion?){
        
        if (this.globalActionsViewController == null){
            let vc = new GlobalActionsViewController("global-actions-view") as GlobalActionsViewController;
            vc.initWithResource("layout/globalactions/GlobalActionsView.html");

            this.globalActionsViewController = new MUINavigationController('global_actions_nav_controller');
            this.globalActionsViewController.initWithRootViewController(vc);
        }

        if (delegate == null) this.globalActionsViewController.delegate = fromVC;
        else this.globalActionsViewController.delegate = delegate;

        if (this.globalActionsViewController.isPresented) {
            this.globalActionsViewController.dismissViewController(true);
        } else {
            this.dismissAllPopovers();
            if (fromVC != null) fromVC.presentViewController(this.globalActionsViewController, true);
            else this.presentViewController(this.globalActionsViewController, true);
        }
    }

    popoverPresentationControllerDidDismissPopover(popoverPresentationController:MUIPopoverPresentationController){
        this.popoverIsVisible = false;
        if ( this.popoverDismissBlock != null ) this.popoverDismissBlock();
        this.popoverDismissBlock = null;
    }

    fetchRequestForController(controller:SelectEntityViewController):MIOFetchRequest{

        if (controller.identifier == "CUSTOM"){            
            let fetchRequest = DBHelper.listFetchRequestWithEntityName(this.customSelectEntityName, this.customSelectEntitySortDescriptors, this.customSelectEntityPredicateFormat);
            return fetchRequest;
		}
        else if (controller.identifier == "Country"){       
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("Country"); //DBHelper.listFetchRequestWithEntityName("Country", sd, null);
            fetchRequest.sortDescriptors = sd;
            return fetchRequest;
		}
		else if (controller.identifier == "Department"){       
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Department", sd, null);
            return fetchRequest;
        }
        else if (controller.identifier == "WorkerRole"){       
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("WorkerRole", sd, null);
            return fetchRequest;
        }
        else if (controller.identifier == "Language"){       
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Language", sd, null);
            return fetchRequest;
        }
        else if (controller.identifier == "Client"){            
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Client", sd, null);
            fetchRequest.fetchLimit = 150;
            return fetchRequest;
        }
        else if (controller.identifier == "Warehouse"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Warehouse", sd, null);            
            return fetchRequest;
        }
        else if (controller.identifier == "Tax"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Tax", sd, null);            
            return fetchRequest;
        }
        else if (controller.identifier == "PayMethod"){            
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("PayMethod", sd, null);            
            return fetchRequest;
        }
        else if (controller.identifier == "Supplier"){            
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Supplier", sd, null);
            fetchRequest.relationshipKeyPathsForPrefetching = ["supplierProducts.product"];
            return fetchRequest;
        }
        else if (controller.identifier == "Product"){            
            let sd = [];
            let sectionNameKeyPath = controller.context != null ? controller.context["SectionNameKeyPath"] : null;
            if (sectionNameKeyPath != null) sd.addObject(MIOSortDescriptor.sortDescriptorWithKey(sectionNameKeyPath, true));
            sd.addObject(MIOSortDescriptor.sortDescriptorWithKey("name", true));

            let predicateFormat = null;
            const ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;            

            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Product", sd, null);
            fetchRequest.relationshipKeyPathsForPrefetching = ["stockInputFormats", "supplierProducts", "costBalance"];
            fetchRequest.fetchLimit = 10;
            return fetchRequest;
        }
        else if (controller.identifier == "StockProduct"){            
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let predicateFormat = "isEnableForStock == true";
            const ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            //if (ad.selectedIdentifierType == "place") predicateFormat += " AND placeID == '" + ad.selectedIdentifier + "'";
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Product", sd, predicateFormat);                        
            fetchRequest.relationshipKeyPathsForPrefetching = ["tax", "taxBuy", "category.tax", "stockInputFormats", "supplierProducts.supplier", "costBalance"];
            fetchRequest.fetchLimit = 10;
            return fetchRequest;
        }
        if (controller.identifier == "ProductCategory"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("ProductCategory", sd, null);
            return fetchRequest;
        }
        if (controller.identifier == "StockCategory"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("StockCategory", sd, null);
            return fetchRequest;
        }
        else if (controller.identifier == "SupplierProduct"){            
            let predicateFormat = ""; //"stockElaborationType != " + StockElaborationType.AtSale;
            let supplier = controller.context ? controller.context["Supplier"] : null;
            if (supplier != null) predicateFormat += "supplier.identifier == " + supplier.identifier;
            const ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            if (ad.selectedIdentifierType == "place") predicateFormat += " AND placeID == '" + ad.selectedIdentifier + "'";
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("product.name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("SupplierProduct", sd, predicateFormat);
            fetchRequest.relationshipKeyPathsForPrefetching = ["product.tax", "product.taxBuy", "product.category.tax", "product.stockInputFormats", "product.costBalance", "supplier"];
            return fetchRequest;
        }
        else if (controller.identifier == "InputFormat"){
            let productID = controller.context["ProductID"];
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("StockInputFormat", sd, "product.identifier == " + productID);            
            return fetchRequest;
        }
        else if (controller.identifier == "StockInputFormat"){
            let productID = controller.context["ProductID"];
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("StockInputFormat", sd, "product.identifier == " + productID);            
            return fetchRequest;
        }
        else if (controller.identifier == "LocationCategory"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("LocationCategory", sd, null);            
            return fetchRequest;
        }        
        else if ( controller.identifier == "Intermediary" ){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Intermediary", sd, null);            
            return fetchRequest;
        }        

        else if ( controller.identifier == "LedgerAccount" ){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("prefix", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("LedgerAccount", sd, null);            
            return fetchRequest;
        }        
        else if ( controller.identifier == "LegalTax" ){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("LegalTax", sd, null);            
            return fetchRequest;
        }        
        else if ( controller.identifier == "MaturityPayment" ){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("MaturityPayment", sd, null);
            return fetchRequest;
        }        
        else if ( controller.identifier == "BookingRecommendation" ){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("BookingRecommendation", sd, null);
            return fetchRequest;
        }        
        else if ( controller.identifier == "Rate" ){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Rate", sd, null);
            return fetchRequest;
        }        
        else if ( controller.identifier == "Distributor" ){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Distributor", sd, null);
            return fetchRequest;
        }        
        else if ( controller.identifier == "LegalEntityBankAccountInfo" ){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("title", true)];
            let pf = "legalEntity.identifier == " + controller.context["LegalEntityID"];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("LegalEntityBankAccountInfo", sd, pf);            
            return fetchRequest;
        }                
        else if ( controller.identifier == "BusinessLicense" ){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("BusinessLicense", sd, null);            
            return fetchRequest;
        }                
        else if ( controller.identifier == "LicenseType" ){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("LicenseType", sd, null);            
            return fetchRequest;
        }                
        else if ( controller.identifier == "LeaveReasonType" ){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("title", true)];
            let fetchRequest = DBHelper.listFetchRequestWithEntityName("LeaveReasonType", sd, null);
            return fetchRequest;
        }                

        return null;
    }

    didSelectObjectFromSelectViewController(controller:SelectEntityViewController, item:MIOManagedObject){
        let dismiss = true;

        if (controller.identifier == "CUSTOM") {
            let object = item as MIOManagedObject;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, object);
        }
        else if (controller.identifier == "Country") {
            let country = item as Country;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, country);
        }
        else if (controller.identifier == "Language") {
            let language = item as Language;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, language);
		}
		else if (controller.identifier == "Department") {
            let department = item as Department;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, department);
        }
		else if (controller.identifier == "WorkerRole") {
            let role = item as WorkerRole;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, role);
        }
        else if (controller.identifier == "Client") {
            let client = item as Client;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, client);
        }
        else if (controller.identifier == "Warehouse") {
            let wh = item as Warehouse;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, wh);
        }
        else if (controller.identifier == "Supplier") {
            let supplier = item as Supplier;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, supplier);
        }
        else if (controller.identifier == "Product") {
            let product = item as Product;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, product);
        }
        else if (controller.identifier == "StockProduct") {
            let product = item as Product;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, product);
        }
        else if (controller.identifier == "ProductCategory") {
            let category = item as ProductCategory;            
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, category);            
        }
        else if (controller.identifier == "StockCategory") {
            let category = item as StockCategory;            
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, category);            
        }
        else if (controller.identifier == "SupplierProduct") {
            let supplierProduct = item as SupplierProduct;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, supplierProduct);
        }
        else if (controller.identifier == "Tax") {
            let tax = item as Tax;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, tax);
        }
        else if (controller.identifier == "PayMethod") {
            let pm = item as PayMethod;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, pm);
        }
        else if (controller.identifier == "LocationCategory") {
            let lc = item as LocationCategory;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, lc);
        }
        else if (controller.identifier == "Intermediary") {
            let i = item as Intermediary;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, i);
        }

        else if (controller.identifier == "LedgerAccount") {
            let i = item as LedgerAccount;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, i);
        }
        else if (controller.identifier == "LegalTax") {
            let i = item as LegalTax;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, i);
        }
        else if (controller.identifier == "MaturityPayment") {
            let i = item as MaturityPayment;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, i);
        }
        else if (controller.identifier == "BookingRecommendation") {
            let i = item as BookingRecommendation;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, i);
        }
        else if (controller.identifier == "Rate") {
            let i = item as Rate;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, i);
        }
        else if (controller.identifier == "Distributor") {
            let i = item as Distributor;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, i);
        }
        else if (controller.identifier == "LegalEntityBankAccountInfo") {
            let i = item as LegalEntityBankAccountInfo;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, i);
        }
        else if (controller.identifier == "StockInputFormat" ) {
            let i = item as StockInputFormat;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, i);
        }
        else if (controller.identifier == "BusinessLicense" ) {
            let i = item as BusinessLicense;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, i);
        }
        else if (controller.identifier == "LicenseType" ) {
            let i = item as LicenseType;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, i);
        }
        else if (controller.identifier == "LeaveReasonType" ) {
            let i = item as LeaveReasonType;
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, i);
        }

        return dismiss;
    }

    didSelectObjectsFromSelectViewController(controller:SelectEntityViewController, items:MIOSet):boolean{
        let dismiss = true;
        
        if (controller.identifier == "Warehouse") {            
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, items);
        }
        if (controller.identifier == "Language") {            
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, items);
        }
        if (controller.identifier == "CUSTOM") {            
            if (this.selectCompletion != null && this.selectTarget != null) this.selectCompletion.call(this.selectTarget, controller, items);
        }

        return dismiss;
    }

    showAddInputFormatAlertViewController(product:Product, productID?:string, target?, completion?){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("INPUT FORMATS","INPUT FORMATS"), MIOLocalizeString("ADD NEW INPUT FORMAT", "ADD NEW INPUT FORMAT"), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.placeholderText = MIOLocalizeString("NAME", "NAME");
        });

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.placeholderText = MIOLocalizeString("QUANTITY", "QUANTITY");
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("OK","OK"), MUIAlertActionStyle.Default, this, function(){
            let name = avc.textFields[0].text;
            let quantity = avc.textFields[1].text;
            
            if (name.length == 0) { 
                AppHelper.showErrorMessage(null, MIOLocalizeString("ERROR", "ERROR"), MIOLocalizeString("YOU NEED TO TYPE THE NAME", "YOU NEED TO TYPE THE NAME"));
                return;
            }
            
            if (quantity.length == 0) {
                AppHelper.showErrorMessage(null, MIOLocalizeString("ERROR", "ERROR"), MIOLocalizeString("YOU NEED TO TYPE THE QUANTITY", "YOU NEED TO TYPE THE QUANTITY"));
                return;                
            }

            let inputFormat = DBHelper.createInputFormat(name, quantity, product, productID);
            DBHelper.saveMainContext();

            if (target != null && completion != null) completion.call(target, inputFormat);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    showAddProductCategory(parentCategory:ProductCategory, completion:any) {

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("ADD CATEGORY", "ADD CATEGORY"), null, MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
            textField.placeHolder = MIOLocalizeString("CATEGORY NAME", "CATEGORY NAME");
        });

        avc.addComboBoxWithConfigurationHandler(this, function (comboBox: MUIComboBox) {
            DBHelper.sharedInstance().addObserverForEntity(avc, 'Tax', null, null, null, function (objects) {
                comboBox.removeAllItems();
                for (let i = 0; i < objects.length; i++)
                    comboBox.addItem(objects[i].name, i);
            });
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("ADD", "ADD"), MUIAlertActionStyle.Default, this, function(){
            
            let name = avc.textFields[0].text;

            let taxComboBox: MUIComboBox = avc.comboBoxes[0];
            let taxIndex = taxComboBox.getSelectedItem();
            let tax = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, taxIndex, 'Tax') as Tax;
            
            DBHelper.createSalesCategory(name, tax, parentCategory, completion);

        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL", "Cancel"), MUIAlertActionStyle.Cancel, null, null));

        avc.addCompletionHandler(this, function () {
            // Release the observers            
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, 'Tax');
        });

        AppHelper.sharedInstance().presentViewController(avc, true);
    }
    

    showAddSalesProductAlertViewController(name:string, selectedCategory:ProductCategory, classname:string, completion?:any) {

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('ADD NEW PRODUCT', 'ADD NEW PRODUCT'), MIOLocalizeString('CONFIG','CONFIG'), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function (textField: MUITextField) {
            textField.setPlaceholderText(MIOLocalizeString('PRODUCT',"PRODUCT"));          
            textField.text = name != null ? name.trim() : null;
        });

        avc.addComboBoxWithConfigurationHandler(this, function (comboBox: MUIComboBox) {
            DBHelper.sharedInstance().addObserverForEntity(avc, 'Tax', null, null, null, function (objects) {
                comboBox.removeAllItems();
                for (let i = 0; i < objects.length; i++)
                    comboBox.addItem(objects[i].name, i);
            });
        });

        avc.addTextFieldWithConfigurationHandler(this, function (textField: MUITextField) {

            textField.setPlaceholderText(MIOLocalizeString('PRICE', "PRICE"));
            let ad: AppDelegate = MUIWebApplication.sharedInstance().delegate;
            textField.formatter = ad.currencyFormatter;
        });

        avc.addComboBoxWithConfigurationHandler(this, function (comboBox: MUIComboBox) {

            DBHelper.sharedInstance().addObserverForEntity(avc, 'ProductCategory', null, null, null, function (objects) {
                comboBox.removeAllItems();
                let selectedIndex = 0;
                for (let i = 0; i < objects.length; i++){
                    let c = objects[i] as ProductCategory;
                    comboBox.addItem(c.name, i);
                    if (selectedCategory != null && selectedCategory.identifier == c.identifier) selectedIndex = i;
                }
                comboBox.selectItem(selectedIndex);
            });
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD','ADD'), MUIAlertActionStyle.Default, this, function () {

            let productName = avc.textFields[0].text;

            let taxComboBox: MUIComboBox = avc.comboBoxes[0];
            let taxIndex = taxComboBox.getSelectedItem();
            let tax = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, taxIndex, 'Tax') as Tax;

            let priceTextField = avc.textFields[1] as MUITextField;
            let ad: AppDelegate = MUIWebApplication.sharedInstance().delegate;            
            let price = ad.currencyFormatter.numberFromString(priceTextField.text);

            let categoryComboBox: MUIComboBox = avc.comboBoxes[1];
            let categoryIndex = categoryComboBox.getSelectedItem();            
            let category = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, categoryIndex, 'ProductCategory') as ProductCategory;

            if (productName.length == 0) {AppHelper.showErrorMessage(null, "ERROR", "PRODUCT NAME IS NULL"); return; }
            if (tax == null) {AppHelper.showErrorMessage(null, "ERROR", "TAX IS NULL"); return; }
            if (category == null) {AppHelper.showErrorMessage(null, "ERROR", "CATEGORY IS NULL"); return; }

            DBHelper.createSalesProduct(productName, tax, price, category, classname, completion);
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, this, function () {
            if (completion != null) {
                completion(null);
            }
        }));

        avc.addCompletionHandler(this, function () {
            // Release the observers
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, 'ProductCategory');
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, 'Tax');
        });

        AppHelper.sharedInstance().presentViewController(avc, true);
    }


    showAddStockProductAlertViewController(name:string, selectedCategory:StockCategory, target?, completion?) {

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('ADD NEW PRODUCT','ADD NEW PRODUCT'), MIOLocalizeString('CONFIG','CONFIG'), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function (textField: MUITextField) {
            textField.setPlaceholderText(MIOLocalizeString('Product',"Product"));          
            textField.text = name != null ? name.trim() : null;
        });

        avc.addComboBoxWithConfigurationHandler(this, function (comboBox: MUIComboBox) {
            DBHelper.sharedInstance().addObserverForEntity(avc, 'Tax', null, null, null, function (objects) {
                comboBox.removeAllItems();
                for (let i = 0; i < objects.length; i++)
                    comboBox.addItem(objects[i].name, i);
            });
        });

        avc.addComboBoxWithConfigurationHandler(this, function (comboBox: MUIComboBox) {

            DBHelper.sharedInstance().addObserverForEntity(avc, 'StockCategory', null, null, null, function (objects) {
                comboBox.removeAllItems();
                let selectedIndex = 0;
                for (let i = 0; i < objects.length; i++){
                    let c = objects[i] as StockCategory;
                    comboBox.addItem(c.name, i);
                    if (selectedCategory != null && selectedCategory.identifier == c.identifier) selectedIndex = i;
                }
                comboBox.selectItem(selectedIndex);
            });
        });

        avc.addComboBoxWithConfigurationHandler(this, function (comboBox: MUIComboBox) {

            DBHelper.sharedInstance().addObserverForEntity(avc, 'Warehouse', null, null, null, function (objects) {
                comboBox.removeAllItems();                
                for (let i = 0; i < objects.length; i++) {
                    comboBox.addItem(objects[i].name, i);
                    if (objects[i].identifier == AppHelper.sharedInstance().defaultWarehouse.identifier){
                        comboBox.selectItem(i);
                    }
                }
            });            
        });

        avc.addComboBoxWithConfigurationHandler(this, function (comboBox: MUIComboBox) {            
            comboBox.addItem('U', MeasureUnitType.Unit);
            comboBox.addItem('Kg', MeasureUnitType.MassKilogram);
            comboBox.addItem('gr', MeasureUnitType.MassGram);
            comboBox.addItem('L', MeasureUnitType.VolumeLitre);
            comboBox.addItem('cl', MeasureUnitType.VolumeCentilitre);
            comboBox.addItem('Container', MeasureUnitType.Container);
            comboBox.selectItem(1);
        });

        avc.addComboBoxWithConfigurationHandler(this, function (comboBox: MUIComboBox) {            
            comboBox.addItem('U', MeasureUnitType.Unit);
            comboBox.addItem('Kg', MeasureUnitType.MassKilogram);
            comboBox.addItem('gr', MeasureUnitType.MassGram);
            comboBox.addItem('L', MeasureUnitType.VolumeLitre);
            comboBox.addItem('cl', MeasureUnitType.VolumeCentilitre);            
            comboBox.selectItem(1);
        });

        avc.addTextFieldWithConfigurationHandler(this, function (textField: MUITextField) {

            textField.setPlaceholderText(MIOLocalizeString("QUANTITY","QUANTITY"));
            let ad: AppDelegate = MUIWebApplication.sharedInstance().delegate;
            textField.formatter = ad.numberFormatter;
        });

        avc.addTextFieldWithConfigurationHandler(this, function (textField: MUITextField) {
            textField.setPlaceholderText(MIOLocalizeString("SUPPLIER REF","SUPPLIER REF"));
        });


        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD','ADD'), MUIAlertActionStyle.Default, this, function () {
            
            name = avc.textFields[0].text;

            let taxComboBox: MUIComboBox = avc.comboBoxes[0];
            let taxIndex = taxComboBox.getSelectedItem();
            let tax = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, taxIndex, 'Tax') as Tax;

            let categoryComboBox: MUIComboBox = avc.comboBoxes[1];
            let categoryIndex = categoryComboBox.getSelectedItem();            
            let stockCategory = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, categoryIndex, 'StockCategory') as StockCategory;            
            
            let warehouseComboBox: MUIComboBox = avc.comboBoxes[2];
            let defaultWarehouse = warehouseComboBox.getSelectedItem();

            let wh = DBHelper.sharedInstance().objectAtIndexForEntityObserver(avc, defaultWarehouse, 'Warehouse');

            let measureTypeComboBox: MUIComboBox = avc.comboBoxes[3];
            let containerMeasureTypeComboBox: MUIComboBox = avc.comboBoxes[4];
            let measureType = measureTypeComboBox.getSelectedItem();
            let containerMeasureType = containerMeasureTypeComboBox.getSelectedItem();

            let quantityTF = avc.textFields[1];
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            let quantity = ad.numberFormatter.numberFromString(quantityTF.text);
            if (isNaN(quantity) || quantity == 0) quantity = null;
             
            DBHelper.createStockProduct(name, tax, stockCategory, wh, measureType, containerMeasureType, quantity, target, completion);

        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, this, function () {
            if (completion != null) {
                completion.call(target, null);
            }
        }));

        avc.addCompletionHandler(this, function () {
            // Release the observers
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, 'StockCategory');
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, 'Warehouse');
            DBHelper.sharedInstance().removeObserverForChangesInEntity(avc, 'Tax');
        });

        AppHelper.sharedInstance().presentViewController(avc, true);
    }

    showElaborationTypeViewController(fromVC:MUIViewController, fromView:MUIView, product:Product){
        let vc = new StockElaborationTypeViewController("stock-configuration-view");
        vc.initWithResource("layout/stock/StockConfigurationView.html");
        vc.delegate = fromVC;
        vc.product = product;
        
        vc.modalPresentationStyle = MUIModalPresentationStyle.Popover;
        let pc = vc.popoverPresentationController;
        pc.sourceRect = fromView.frame;
        pc.sourceView = fromView;

        fromVC.presentViewController(vc, true);
    }

    //
    // Bookings
    //

    setupBookingStatusComboBox(comboBox:MUIComboBox){
        comboBox.removeAllItems();
        
        comboBox.addItem(MIOLocalizeString('REQUEST', 'REQUEST'), BookingStatus.BookingRequest);
        comboBox.addItem(MIOLocalizeString('ASSIGNED', 'ASSIGNED'),    BookingStatus.BookingAssigned);
        comboBox.addItem(MIOLocalizeString('RESERVED', 'RESERVED'),    BookingStatus.BookingReserved);
        comboBox.addItem(MIOLocalizeString('CONFIRMED', 'CONFIRMED'),    BookingStatus.BookingConfirmed);
        comboBox.addItem(MIOLocalizeString('FINISHED', 'FINISHED'),    BookingStatus.BookingFinished);
        comboBox.addItem(MIOLocalizeString('WAITING', 'WAITING'),    BookingStatus.BookingWaiting);
        comboBox.addItem(MIOLocalizeString('CANCELLED', 'CANCELLED'),    BookingStatus.BookingCancelled);
        comboBox.addItem(MIOLocalizeString('DENIED', 'DENIED'),    BookingStatus.BookingDenied);
        comboBox.addItem(MIOLocalizeString('NOSHOW', 'NOSHOW'),    BookingStatus.BookingNoShow);
        comboBox.addItem(MIOLocalizeString('NOCC', 'NOCC'),     BookingStatus.BookingNoCC);
        comboBox.addItem(MIOLocalizeString('DELETED', 'DELETED'),    BookingStatus.BookingDeleted);
        comboBox.addItem(MIOLocalizeString('CCCANCELED', 'CCCANCELED'),    BookingStatus.BookingCCCanceled);
        comboBox.addItem(MIOLocalizeString('SHOW', 'SHOW'),    BookingStatus.BookingShow);
        comboBox.addItem(MIOLocalizeString('SITTING', 'SITTING'),    BookingStatus.BookingSitting); 
    }

    // 
    // Integrators
    //

    showIntegratorsAlert(){
        //TODO:
    }

    //
    // Images
    //

    showImageSelectorPanel(context:any, completion:any){
        let panel = MUIOpenPanel.openPanel();
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        panel.beginSheetModalForWindow(ad.window, this, function(this:AppHelper, result:number){
            if (result == MIOFileHandlingPanel.OKButton) {
               let file = panel.files[0];               
               let ws = ad.webService;
       
               ws.uploadImage(file, context, (code:number, data:any, imageID:string, ctx:any) => {
                   if (code != 200){
                        AppHelper.showErrorMessage(this, "Error", "Couldn't upload image. Please try again later.");
                        return;
                   }

                   let url = data["url"];                
                   
                   // Create DBImage
                   let img = MIOEntityDescription.insertNewObjectForEntityForName("DBImage", ad.managedObjectContext) as DBImage;
                   img.identifier = imageID;
                   img.url = url;

                   completion( img, ctx );
               });       
            }       
         });      

    }

    static setupDataTextField(target:any, outlet:string, setValueBlock:any) : MUITextField{
    
        let tf = MUIOutlet(target, outlet, "MUITextField");
        if (tf == null) return null;

        tf.setOnChangeText(this, function(this:AppHelper, control:MUITextField, value:string) {
            let v = value.length > 0 ? value : null;
            setValueBlock(v);
        });

        return tf;
    }

    static setupActionButton(target:any, outlet:string, actionBlock:any) : MUIButton {
        let btn = MUIOutlet(target, outlet, "MUIButton") as MUIButton;
        if (btn == null) return null;

        btn.setAction(this, function() { 
            actionBlock();
        });

        return btn;
    }

    static setupSaveButton(target:any, outlet:string) : MUIButton {
        return AppHelper.setupActionButton(target, outlet, () => { DBHelper.saveMainContext(); } );
    }


}
