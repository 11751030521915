
class VIPCardCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;
    private emailLabel:MUILabel = null;
    
    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.emailLabel = MUIOutlet(this, "email-lbl", "MUILabel");

        this.selectionStyle = MUITableViewCellSelectionStyle.None;
    }   

    set item(value:VIPCard){
        this.nameLabel.text = value.name;
        this.emailLabel.text = value.email;
    }
}