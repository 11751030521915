
class ArchivedDocumentTemplateCell extends UITableViewCell
{
    private typeTextField:MUITextField = null;
    private keyTextField:MUITextField = null;
    private statusTextField:MUITextField = null;
    private languageTextField:MUITextField = null;
    private subjectTextField:MUITextField = null;
    private urlTextField:MUITextField = null;    
    private examineButton:MUIButton = null;
    private activeButton:MUISwitchButton = null;    

    awakeFromHTML(){
        this.typeTextField = AppHelper.setupDataTextField(this, "type-tf", (value:string) => { this.emailTemplate.type = value} );
        this.keyTextField = AppHelper.setupDataTextField(this, "key-tf", (value:string) => { this.emailTemplate.key = value} );
        this.statusTextField = AppHelper.setupDataTextField(this, "status-tf", (value:string) => { this.emailTemplate.status = parseInt(value) } );
        this.languageTextField = AppHelper.setupDataTextField(this, "language-tf", (value:string) => { this.emailTemplate.language = value} );
        this.subjectTextField = AppHelper.setupDataTextField(this, "subject-tf", (value:string) => { this.emailTemplate.subject = value} );
        this.urlTextField = AppHelper.setupDataTextField(this, "url-tf", (value:string) => { this.emailTemplate.url = value} );
        this.examineButton = MUIOutlet(this, "folder-btn", "MUIButton");
        this.activeButton = MUIOutlet(this, "active-sw", "MUISwitchButton");        
        this.activeButton.setOnChangeValue(this, function(this:EmailCell, control:MUISwitchButton, value:boolean) { this.emailTemplate.active = value } );

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private emailTemplate:EmailTemplate = null;
    set item(item:EmailTemplate){
        this.emailTemplate = item;

        this.typeTextField.text = item.type;
        this.keyTextField.text = item.key;
        this.statusTextField.text = item.status;
        this.languageTextField.text = item.language;
        this.subjectTextField.text = item.subject;
        this.urlTextField.text = item.url;
        this.activeButton.on = item.active;
    }
}