/**
 * Created by miguel on 10/2/17.
 */



/// <reference path="../external/MeasureUnits.ts" />


class AddNewProductUnitViewController extends MUIViewController
{
    private unitButton:MUIButton        = null;
    private kilogramButton:MUIButton     = null;
    private gramButton:MUIButton         = null;
    private litreButton:MUIButton        = null;
    private centButton:MUIButton         = null;
    private unitLabel:MUILabel         = null;
    private quantityTextField:MUITextField = null;

    private buttons = [];

    private selected = -1;
    private _product:Product = null;

    private nf:MIONumberFormatter = MUIWebApplication.sharedInstance().delegate.numberFormatter;

    viewDidLoad()
    {
        super.viewDidLoad();

        this.unitLabel = MUIOutlet(this, 'anpuv_c1_unit_lbl','MUILabel');
        this.quantityTextField = MUIOutlet(this, 'anpuv_c1_quantity_text','MUITextField');
        this.quantityTextField.formatter = this.nf;
        this.quantityTextField.setOnChangeText(this, function(textfield, value)
        {
           this._product.quantity = this.nf.numberFromString(value);
           //MIONotificationCenter.defaultCenter().postNotification("AddNewProductMeasureQuantityDidChange", value);
        });

        this.unitButton = MUIOutlet(this, 'anpuv_tv_unit_cell', 'MUIButton');
        this.unitButton.setAction(this, function ()
        {
         //   MIONotificationCenter.defaultCenter().postNotification("AddNewProductMeasureUnitDidChange", MeasureUnitType.Unit);
            this.toggleButtons(MeasureUnitType.Unit);
        });
        this.buttons[MeasureUnitType.Unit] = this.unitButton;

        this.kilogramButton = MUIOutlet(this, 'anpuv_tv_kilogram_cell', 'MUIButton');
        this.kilogramButton.setAction(this, function ()
        {
          //  MIONotificationCenter.defaultCenter().postNotification("AddNewProductMeasureUnitDidChange", MeasureUnitType.MassKilogram);
            this.toggleButtons(MeasureUnitType.MassKilogram);
        });
        this.buttons[MeasureUnitType.MassKilogram] = this.kilogramButton;

        this.gramButton = MUIOutlet(this, 'anpuv_tv_gram_cell', 'MUIButton');
        this.gramButton.setAction(this, function ()
        {
          //  MIONotificationCenter.defaultCenter().postNotification("AddNewProductMeasureUnitDidChange", MeasureUnitType.MassGram);
            this.toggleButtons(MeasureUnitType.MassGram);
        });
        this.buttons[MeasureUnitType.MassGram] = this.gramButton;

        this.litreButton = MUIOutlet(this, 'anpuv_tv_litre_cell', 'MUIButton');
        this.litreButton.setAction(this, function ()
        {
           // MIONotificationCenter.defaultCenter().postNotification("AddNewProductMeasureUnitDidChange", MeasureUnitType.VolumeLitre);
            this.toggleButtons(MeasureUnitType.VolumeLitre);
        });
        this.buttons[MeasureUnitType.VolumeLitre] = this.litreButton;

        this.centButton = MUIOutlet(this, 'anpuv_tv_centilitre_cell', 'MUIButton');
        this.centButton.setAction(this, function ()
        {
           // MIONotificationCenter.defaultCenter().postNotification("AddNewProductMeasureUnitDidChange", MeasureUnitType.VolumeCentilitre);
            this.toggleButtons(MeasureUnitType.VolumeCentilitre);
        });
        this.buttons[MeasureUnitType.VolumeCentilitre] = this.centButton;

        this.clear();
    }

    public clearQuantity()
    {
        if(this.quantityTextField)
            this.quantityTextField.text = null;
    }

    public clear()
    {
        if(this.quantityTextField)
            this.quantityTextField.text = null;
        //this.toggleButtons(MeasureUnitType.Unit);
        this.toggleButtons(this._product.measureUnitType);
    }

    set product(value)
    {
        this._product = value;
        this.clear();
    }

    private toggleButtons(unit)
    {
        this._product.measureUnitType = unit;

        this.buttons.forEach(function (value, index) {
            if(index == unit) {
                value.layer.classList.add('tableviewcell_accessory_checkmark');
                
                if(this.unitLabel)
                    this.unitLabel.text = MeasureUnits.toString(unit);
            }
            else {
                value.layer.classList.remove('tableviewcell_accessory_checkmark');
            }
        }, this);
    }
}