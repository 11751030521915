

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class BankAccountInfo_ManagedObject

class BankAccountInfo_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: bankCode
    set bankCode(value:string) {
        this.setValueForKey(value, 'bankCode');
    }
    get bankCode():string {
        return this.valueForKey('bankCode');
    }
    set bankCodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'bankCode');
    }
    get bankCodePrimitiveValue():string {
        return this.primitiveValueForKey('bankCode');
    }

    // Property: iban
    set iban(value:string) {
        this.setValueForKey(value, 'iban');
    }
    get iban():string {
        return this.valueForKey('iban');
    }
    set ibanPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'iban');
    }
    get ibanPrimitiveValue():string {
        return this.primitiveValueForKey('iban');
    }

    // Property: number
    set number(value:string) {
        this.setValueForKey(value, 'number');
    }
    get number():string {
        return this.valueForKey('number');
    }
    set numberPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'number');
    }
    get numberPrimitiveValue():string {
        return this.primitiveValueForKey('number');
    }

    // Property: title
    set title(value:string) {
        this.setValueForKey(value, 'title');
    }
    get title():string {
        return this.valueForKey('title');
    }
    set titlePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'title');
    }
    get titlePrimitiveValue():string {
        return this.primitiveValueForKey('title');
    }
}
