
class DBCoder extends MIOObject
{
    static newCoder():DBCoder {
        let coder = new DBCoder();
        coder.init();
        return coder;
    }

    // COMMON
    protected ad: AppDelegate = MUIWebApplication.sharedInstance().delegate;
    
    protected mainEntity() { return {}; }
    protected columns(): any[] { return []; }

    // IMPORT    
    parseFile(file:any, completion:any) { completion(); }
    protected aditionalImportEntities() { return []; }
    protected importRow(row:any) {}
    
    reportColumns() : MUIReportTableViewColumn[] {
        let reportColumns = [];
        let cols = this.columns();
        for (let index = 0; index < cols.length; index++) {
            let c = cols[index];
            let title = c["title"];
            let width = c["width"];
            let align = c["align"];
            let formatter = c["formatter"];
            let localziedTitle = MIOLocalizeString(title, title);
            let rc = MUIReportTableViewColumn.labelColumnWithTitle(localziedTitle, width, 100, align, title, formatter);
            reportColumns.addObject(rc);
        }
        
        return reportColumns;
    }
    
    private entityCache = {};
    protected queryEntity(entityName:string, predicateFormat:string, ...args: any[]){
        let objects = this.entityCache[entityName];
        if (objects == null || objects.length == 0) return null;
        if (predicateFormat == null) return objects[0];

        let objs = _MIOPredicateFilterObjects(objects, MIOPredicate.predicateWithFormat(predicateFormat, ...args));
        if (objs.length == 0) return null;

        return objs[0];
    }

    protected queryEntityByField(entityName:string, field:string, value:any, comparator:string = "=") : any {
        if (value == null) return null;
        let predicateFormat = field + comparator + "%@";
        return this.queryEntity(entityName, predicateFormat, value);
    }

    protected queryEntityByIDOrName(entityName:string, identifier:string, name:string) : any {
        let object:MIOManagedObject = null;
        if ( identifier?.length > 0 ) { return this.queryEntity(entityName, "identifier = %@", identifier.toUpperCase()); }
        if ( name?.length > 0 ) object = this.queryEntity(entityName, "name = %@", name);
        return object;
    }


    protected queryEntityProduct(identifier:string, reference:string, name:string) : Product {
        let product:Product = null;
        if ( reference != null ) product = this.queryEntity("Product", "reference = %@", reference) as Product;
        if ( product == null ) product = this.queryEntityByIDOrName ("Product", identifier, name) as Product;
        return product;
    }

    protected appendObject(obj:MIOManagedObject) {
        let entity_name = obj.entity.name;
        let objects = this.entityCache[entity_name];
        if (objects == null) {
            objects = [];
        }

        objects.push(obj);
        this.entityCache[entity_name] = objects;        
    }

    private progressView = ProgressAlertViewController.newInstance();
    private entitiesCount = 0;
    importItems(items:any[]){                
        this.progressView.title = MIOLocalizeString("IMPORTING...", "IMPORTING...");
        AppHelper.sharedInstance().presentViewController(this.progressView, true);

        this.rows = items;

        let query_entities = [];
        query_entities.push(this.mainEntity());
        let additional_entities = this.aditionalImportEntities();
        for(let e of additional_entities) query_entities.push(e);
        this.entitiesCount = query_entities.length;

        for (let index = 0; index < query_entities.length; index++){
            let item = query_entities[index];
            let entity_name = item["entity"];
            if (entity_name == null) continue;
            let pf = item["predicateFormat"];
            let rels = item["relations"] ?? [];

            let predicateFormat = pf != null ? MIOPredicate.predicateWithFormat(pf) : null;
            DBHelper.queryObjectsWithCompletion(entity_name, null, predicateFormat, rels, this, function(this:DBCoder, objs:any[], error:string) {
                if (error != null) {
                    this.progressView.dismissViewController(true);
                    AppHelper.showErrorMessage(null, "FETCH ERROR", "THE FETCH OPERATION FAIL");                    
                    return;
                }
                
                this.entityCache[entity_name] = objs;
                this.entitiesCount--;
                if (this.entitiesCount == 0) {
                    this.rowIndex = 0;
                    this.parseNextRow();        
                }
            });
        }
    }

    protected rows = null;
    protected rowIndex = 0;
    protected parseNextRow() {
        
        for (let index = this.rowIndex; index < this.rows.length; index++ ){
            this.rowIndex = index;
            this.progressView.title = MIOLocalizeString("IMPORTING...", "IMPORTING...") + " " + index + "/" + this.rows.length;
            let row = this.rows[index];            
            this.importRow(row);
        }

        DBHelper.saveMainContextWithCompletion(this, function() {
            this.progressView.dismissViewController(true);
            AppHelper.showInfoMessage(null, "DBIMPORT", "THE IMPORT OF THE ITEMS IS DONE");
        });
    }
    
    parseBoolValue(value:any):boolean {
        if (value == null) return false;

        let result = false;        
        if (typeof value === 'string') {
            if (value.toLocaleLowerCase() == "1") result = true;
            else if (value.toLocaleLowerCase() == "true") result = true;
            else if (value.toLocaleLowerCase() == "yes") result = true;
        }
        else if (typeof value === 'number') {
            if (value == 1) result = true;
        }

        return result;
    }

    parseDateValue(value:any):Date {
        if (value instanceof Date) return value;
        if (typeof value === 'string') {
            let d = this.ad.dateFormatter.dateFromString(value);
            return d;
        }
        return null;
    }

    parseOrCreateIdentifier(value:string) {
        return value?.length > 0 ? value.toUpperCase() : new MIOUUID().UUIDString;
    }

    
    // EXPORT

    protected exportTitle() : string { return "DBEXPORT"; }
    protected exportSortDescriptors() { return null }    
    
    export() {
        let e = this.mainEntity();
        let entity_name = e["entity"]
        let predicate = MIOPredicate.predicateWithFormat(e["predicateFormat"]);
        let rels = e["relations"] ? e["relations"] : [];
        DBHelper.queryObjectsWithCompletion(entity_name, this.exportSortDescriptors(), predicate, rels, this, function(this:DBCoder, objects:MIOManagedObject[]) {
            let obs = _MIOSortDescriptorSortObjects(objects, this.exportSortDescriptors());
            this.exportObjects(obs);
        });
    }

    protected parseObjects(objects:MIOManagedObject[]) : any[] { 
        let items = [];
        for (let index = 0; index < objects.length; index++) {
            let obj = objects[index];
            let item = this.parseObject(obj);
            if (item != null) items.addObject(item);
        }

        return items;
    }

    protected parseObject(object:MIOManagedObject): any { }

    protected exportObjects(objects:MIOManagedObject[]){ }

}