
class BankAccountCell extends UITableViewCell
{
    private numberLabel:MUILabel = null;
    private nameLabel:MUILabel = null;
    private totalLabel:MUILabel = null;


    awakeFromHTML(){
        this.numberLabel = MUIOutlet(this, "number-lbl", "MUILabel");
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.totalLabel = MUIOutlet(this, "total-lbl", "MUILabel");
    }

    set item (item:BankAccount){
        this.numberLabel.text = item.info?.number;
        this.nameLabel.text = item.info?.title;
        this.totalLabel.text = "0";
    }

}