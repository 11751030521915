class EventBudgetCell extends MUITableViewCell
{
    private dateLabel = null;
    private priceLabel = null;
    private separator = null;

    private df:MIODateFormatter = MUIWebApplication.sharedInstance().delegate.dateFormatter;
    private cf:MIONumberFormatter = MUIWebApplication.sharedInstance().delegate.currencyFormatter;

    awakeFromHTML()
    {
        this.dateLabel = MUIOutlet(this, 'date_label', 'MUILabel');
        this.priceLabel = MUIOutlet(this, 'price_label', 'MUILabel');
        this.separator = MUIOutlet(this, 'separator', 'MUIView');
    }
    
    setSelected(value)
    {
        super.setSelected(value);
        if (value == true)
            this.separator.setBackgroundRGBColor(123, 186, 159);
        else
            this.separator.setBackgroundRGBColor(233, 233, 233);
    }

    set item(item:any)
    {
        this.dateLabel.text = this.df.stringFromDate(item.date);
        this.priceLabel.text = this.cf.stringFromNumber(item.totalPrice);
    }
}
