

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class IntegratorConfig_ManagedObject

class IntegratorConfig_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: automaticGrouping
    set automaticGrouping(value:string) {
        this.setValueForKey(value, 'automaticGrouping');
    }
    get automaticGrouping():string {
        return this.valueForKey('automaticGrouping');
    }
    set automaticGroupingPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'automaticGrouping');
    }
    get automaticGroupingPrimitiveValue():string {
        return this.primitiveValueForKey('automaticGrouping');
    }

    // Property: columns
    set columns(value:any) {
        this.setValueForKey(value, 'columns');
    }
    get columns():any {
        return this.valueForKey('columns');
    }
    set columnsPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'columns');
    }
    get columnsPrimitiveValue():any {
        return this.primitiveValueForKey('columns');
    }

    // Property: configValues
    set configValues(value:any) {
        this.setValueForKey(value, 'configValues');
    }
    get configValues():any {
        return this.valueForKey('configValues');
    }
    set configValuesPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'configValues');
    }
    get configValuesPrimitiveValue():any {
        return this.primitiveValueForKey('configValues');
    }

    // Property: lockSequenceNumber
    set lockSequenceNumber(value:number) {
        this.setValueForKey(value, 'lockSequenceNumber');
    }
    get lockSequenceNumber():number {
        return this.valueForKey('lockSequenceNumber');
    }
    set lockSequenceNumberPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lockSequenceNumber');
    }
    get lockSequenceNumberPrimitiveValue():number {
        return this.primitiveValueForKey('lockSequenceNumber');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: queueEntityName
    set queueEntityName(value:string) {
        this.setValueForKey(value, 'queueEntityName');
    }
    get queueEntityName():string {
        return this.valueForKey('queueEntityName');
    }
    set queueEntityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'queueEntityName');
    }
    get queueEntityNamePrimitiveValue():string {
        return this.primitiveValueForKey('queueEntityName');
    }

    // Property: sourceEntityName
    set sourceEntityName(value:string) {
        this.setValueForKey(value, 'sourceEntityName');
    }
    get sourceEntityName():string {
        return this.valueForKey('sourceEntityName');
    }
    set sourceEntityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceEntityName');
    }
    get sourceEntityNamePrimitiveValue():string {
        return this.primitiveValueForKey('sourceEntityName');
    }

    // Property: sourceEntityType
    set sourceEntityType(value:number) {
        this.setValueForKey(value, 'sourceEntityType');
    }
    get sourceEntityType():number {
        return this.valueForKey('sourceEntityType');
    }
    set sourceEntityTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'sourceEntityType');
    }
    get sourceEntityTypePrimitiveValue():number {
        return this.primitiveValueForKey('sourceEntityType');
    }

    // Property: targetEntityName
    set targetEntityName(value:string) {
        this.setValueForKey(value, 'targetEntityName');
    }
    get targetEntityName():string {
        return this.valueForKey('targetEntityName');
    }
    set targetEntityNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'targetEntityName');
    }
    get targetEntityNamePrimitiveValue():string {
        return this.primitiveValueForKey('targetEntityName');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }
    // Relationship: integrator
    set integrator(value:Integrator) {
        this.setValueForKey(value, 'integrator');
    }
    get integrator():Integrator {
        return this.valueForKey('integrator') as Integrator;
    }
    // Relationship: mappingInfo
    set mappingInfo(value:IntegratorMappingInfo) {
        this.setValueForKey(value, 'mappingInfo');
    }
    get mappingInfo():IntegratorMappingInfo {
        return this.valueForKey('mappingInfo') as IntegratorMappingInfo;
    }
}
