
class InputNoteLineCell extends NoteLineCell
{
        
    enterDidPress(){
        if (this.stockNoteLine != null) return;        
        if (this.quantity == null) return;
                
        let line = DBHelper.createInputNoteLine(this.product, this.inputFormat, this.inputType, this.quantity, this.productQuantity, this.priceAmount, this.productNetPriceAmount, this.baseAmount, this.warehouse, this.stockNote);
        if (line != null) {            
            this.lineDidInserted(line);
            this.reset();
        }
    }
    
}