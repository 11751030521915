//
// Generated class BankMovement
//

/// <reference path="BankMovement_ManagedObject.ts" />

class BankMovement extends BankMovement_ManagedObject
{
    get monthString():string {
        let yy = MIODateGetYearFromDate ( this.date );
        let mm = MIODateGetMonthFromDate( this.date )
        return String(yy) + "-" + String(mm);
    }
}
