
class WarehouseOrderLineCell extends NoteLineCell
{   
    enterDidPress(){
        if (this.stockNoteLine != null) return;        
        if (this.quantity == 0 || this.quantity == null) return;
                
        let line = DBHelper.createWarehouseOrderLine(this.product, this.inputFormat, this.inputType, this.quantity, this.productQuantity, this.stockNote.destinationWarehouse, this.stockNote);
        if (line != null) {
            this.lineDidInserted(line);
            this.reset();
        }
    }      

}
