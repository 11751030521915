//
// Generated class BookingGroup
//

/// <reference path="BookingGroup_ManagedObject.ts" />

class BookingGroup extends BookingGroup_ManagedObject
{

}
