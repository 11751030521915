//
// Generated class CoreAccounting
//

/// <reference path="CoreAccounting_ManagedObject.ts" />

class CoreAccounting extends CoreAccounting_ManagedObject
{

}
