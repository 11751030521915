
/// <reference path="DBXLSXCoder.ts" />


class DBInvoiceHeadersCoder extends DBXLSXCoder
{
    protected mainEntity(): {} {
        return { entity: "Invoice", predicateFormat: "deletedAt == null", relations: ["legalEntity", "tags"] };
    }

    protected columns() : any[] {
        let cols = [
            { title: "INVOICE NUMBER", width: "10%", align: "center" },
            { title: "DATE", width: "10%", align: "center" },
            { title: "CLIENT REFERENCE", width: "20%", align: "center" },
            { title: "CLIENT NAME", width: "20%", align: "center" },
            { title: "CLIENT DOCUMENT ID", width: "10%", align: "center" },
            { title: "BASE AMOUNT", width: "10%", align: "center", formatter: this.ad.currencyFormatter },
            { title: "TAX AMOUNT", width: "10%", align: "center", formatter: this.ad.currencyFormatter },
            { title: "DISCOUNT AMOUNT", width: "10%", align: "center", formatter: this.ad.currencyFormatter },
            { title: "TOTAL AMOUNT", width: "10%", align: "center", formatter: this.ad.currencyFormatter },
            { title: "PHONE", width: "10%", align: "center" },
            { title: "MOBILE", width: "10%", align: "center" },
            { title: "ADDRESS", width: "20%", align: "center" },
            { title: "CITY", width: "10%", align: "center" },
            { title: "PROVINCE OR STATE", width: "10%", align: "center" },
            { title: "POSTAL CODE", width: "10%", align: "center" },
            { title: "COUNTRY ISO2", width: "10%", align: "center" },
            { title: "COMMENTS", width: "10%", align: "center" },
            { title: "TAG", width: "10%", align: "center" },
        ]
        return cols;
    }

    protected aditionalImportEntities(){
        return [            
            { entity: "Tag", predicateFormat: "type == " + TagType.Invoice },
            { entity: "Client", predicateFormat: "deletedAt == null" },            
            { entity: "Country", predicateFormat: null }
        ]
    }

    protected importRow(row:any){
        
        let invoiceNumber    = row["INVOICE NUMBER"];
        let dateValue        = row["DATE"];
        let clientReference  = row["CLIENT REFERENCE"];
        let clientName       = row["CLIENT NAME"];
        let clientDocID      = row["CLIENT DOCUMENT ID"];
        let base             = row["BASE AMOUNT"];
        let tax              = row["TAX AMOUNT"];
        let total            = row["TOTAL AMOUNT"];
        let discount         = row["DISCOUNT AMOUNT"];
        let address          = row["ADDRESS"];
        let city             = row["CITY"];
        let postalCode       = row["POSTAL CODE"];
        let state            = row["PROVINCE OR STATE"];
        let countryISO2      = row["COUNTRY ISO2"];
        let comments         = row["COMMENTS"];
        let phone            = row["PHONE"];
        let mobile           = row["MOBILE"];
        let tagName          = row["TAG"];

        let date = this.parseDateValue(dateValue);
        if (date == null) {
            // Throw error
            return;
        }

        let country = this.queryEntityByField("Country", "iso2", countryISO2) as Country;
        if (country == null) {
            country = AppHelper.sharedInstance().defaultCountry;
        }
               
        let client:Client = null;
        if ( clientReference != null ) client = this.queryEntityByField("Client", "reference", clientReference) as Client;
        if ( client == null ) client = this.queryEntityByField("Client", "name", clientName) as Client;
        if ( client == null ) { 
            //TODO: Throw error
            return;
            // client = MIOEntityDescription.insertNewObjectForEntityForName("Client", DBHelper.mainManagedObjectContext) as Client;
            // client.identifier = new MIOUUID().UUIDString;

            // let addr = MIOEntityDescription.insertNewObjectForEntityForName("Address", DBHelper.mainManagedObjectContext) as Address;
            // client.address = addr;

            // let p = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", DBHelper.mainManagedObjectContext) as PhoneNumber;
            // client.phone = p;

            // let m = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", DBHelper.mainManagedObjectContext) as PhoneNumber;
            // client.mobilePhone = m;
            // client.reference = reference;
            // client.name = name;
            // client.businessName = businessName;
            // client.document = businessID;
            // client.phone.number = phone;
            // client.mobilePhone.number = mobile;
            // client.address.address1 = address;
            // client.address.city = city;
            // client.address.state = state;
            // client.address.postalCode = postalCode;
            // client.address.country = country?.name;
            // client.address.countryISOa2 = country?.iso2;
            // client.address.countryISOa3 = country?.iso3;        
            // client.comments = comments;
            // client.entryDate = this.ad.dateTimeFormatter.dateFromString(entryDate);
            // client.leaveDate = this.ad.dateTimeFormatter.dateFromString(leaveDate);
            // client.leavingReasons = leavingReasons;    
            // client.rate = rate;
            // client.agent = agent;
            
            // this.appendObject(client);    
        }

        
        let tag = this.queryEntityByField("Tag", "name", tagName) as Tag;
        if (tagName != null && tag == null) { 
            tag = MIOEntityDescription.insertNewObjectForEntityForName("Tag", DBHelper.mainManagedObjectContext) as Tag;
            tag.identifier = new MIOUUID().UUIDString;

            tag.name = tagName;
            tag.type = TagType.Invoice;
            this.appendObject(tag);
        }

        let invoice = this.queryEntityByField("Invoice", "invoiceNumber", invoiceNumber) as Invoice;
        if (invoice == null) { 
            invoice = MIOEntityDescription.insertNewObjectForEntityForName("Invoice", DBHelper.mainManagedObjectContext) as Invoice;
            invoice.identifier = new MIOUUID().UUIDString;
            invoice.documentNumber = invoiceNumber;
            this.appendObject(invoice);
        }
        
        invoice.date = date;
        invoice.legalEntity = client;
        invoice.baseAmount = base ?? 0;
        invoice.taxAmount = tax ?? 0;
        invoice.totalAmount = total ?? 0;
        invoice.discountAmount = discount ?? 0;
        invoice.tags = tagName;
        

        MIOLog("ADDING INVOICE: " + this.rowIndex + "/" + this.rows.length + ": " + invoiceNumber);
    }   
}