

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class LocationCategoryAutoProduct_ManagedObject

class LocationCategoryAutoProduct_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: byTable
    set byTable(value:boolean) {
        this.setValueForKey(value, 'byTable');
    }
    get byTable():boolean {
        return this.valueForKey('byTable');
    }
    set byTablePrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'byTable');
    }
    get byTablePrimitiveValue():boolean {
        return this.primitiveValueForKey('byTable');
    }

    // Property: formatID
    set formatID(value:string) {
        this.setValueForKey(value, 'formatID');
    }
    get formatID():string {
        return this.valueForKey('formatID');
    }
    set formatIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'formatID');
    }
    get formatIDPrimitiveValue():string {
        return this.primitiveValueForKey('formatID');
    }

    // Property: quantity
    set quantity(value:number) {
        this.setValueForKey(value, 'quantity');
    }
    get quantity():number {
        return this.valueForKey('quantity');
    }
    set quantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'quantity');
    }
    get quantityPrimitiveValue():number {
        return this.primitiveValueForKey('quantity');
    }

    // Property: quantityOfDinners
    set quantityOfDinners(value:number) {
        this.setValueForKey(value, 'quantityOfDinners');
    }
    get quantityOfDinners():number {
        return this.valueForKey('quantityOfDinners');
    }
    set quantityOfDinnersPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'quantityOfDinners');
    }
    get quantityOfDinnersPrimitiveValue():number {
        return this.primitiveValueForKey('quantityOfDinners');
    }
    // Relationship: locationCategory
    set locationCategory(value:LocationCategory) {
        this.setValueForKey(value, 'locationCategory');
    }
    get locationCategory():LocationCategory {
        return this.valueForKey('locationCategory') as LocationCategory;
    }
    // Relationship: product
    set product(value:Product) {
        this.setValueForKey(value, 'product');
    }
    get product():Product {
        return this.valueForKey('product') as Product;
    }
    // Relationship: service
    set service(value:Service) {
        this.setValueForKey(value, 'service');
    }
    get service():Service {
        return this.valueForKey('service') as Service;
    }
}
