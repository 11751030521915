

/// <reference path="CoreAccounting.ts" />

// Generated class BankMovement_ManagedObject

class BankMovement_ManagedObject extends CoreAccounting
{

    // Property: amount
    set amount(value:number) {
        this.setValueForKey(value, 'amount');
    }
    get amount():number {
        return this.valueForKey('amount');
    }
    set amountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'amount');
    }
    get amountPrimitiveValue():number {
        return this.primitiveValueForKey('amount');
    }

    // Property: balance
    set balance(value:number) {
        this.setValueForKey(value, 'balance');
    }
    get balance():number {
        return this.valueForKey('balance');
    }
    set balancePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'balance');
    }
    get balancePrimitiveValue():number {
        return this.primitiveValueForKey('balance');
    }

    // Property: bankBranch
    set bankBranch(value:string) {
        this.setValueForKey(value, 'bankBranch');
    }
    get bankBranch():string {
        return this.valueForKey('bankBranch');
    }
    set bankBranchPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'bankBranch');
    }
    get bankBranchPrimitiveValue():string {
        return this.primitiveValueForKey('bankBranch');
    }

    // Property: beneficiaryAccountNumber
    set beneficiaryAccountNumber(value:string) {
        this.setValueForKey(value, 'beneficiaryAccountNumber');
    }
    get beneficiaryAccountNumber():string {
        return this.valueForKey('beneficiaryAccountNumber');
    }
    set beneficiaryAccountNumberPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'beneficiaryAccountNumber');
    }
    get beneficiaryAccountNumberPrimitiveValue():string {
        return this.primitiveValueForKey('beneficiaryAccountNumber');
    }

    // Property: beneficiaryIBAN
    set beneficiaryIBAN(value:string) {
        this.setValueForKey(value, 'beneficiaryIBAN');
    }
    get beneficiaryIBAN():string {
        return this.valueForKey('beneficiaryIBAN');
    }
    set beneficiaryIBANPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'beneficiaryIBAN');
    }
    get beneficiaryIBANPrimitiveValue():string {
        return this.primitiveValueForKey('beneficiaryIBAN');
    }

    // Property: beneficiaryName
    set beneficiaryName(value:string) {
        this.setValueForKey(value, 'beneficiaryName');
    }
    get beneficiaryName():string {
        return this.valueForKey('beneficiaryName');
    }
    set beneficiaryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'beneficiaryName');
    }
    get beneficiaryNamePrimitiveValue():string {
        return this.primitiveValueForKey('beneficiaryName');
    }

    // Property: concept
    set concept(value:string) {
        this.setValueForKey(value, 'concept');
    }
    get concept():string {
        return this.valueForKey('concept');
    }
    set conceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'concept');
    }
    get conceptPrimitiveValue():string {
        return this.primitiveValueForKey('concept');
    }

    // Property: dataCode
    set dataCode(value:number) {
        this.setValueForKey(value, 'dataCode');
    }
    get dataCode():number {
        return this.valueForKey('dataCode');
    }
    set dataCodePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'dataCode');
    }
    get dataCodePrimitiveValue():number {
        return this.primitiveValueForKey('dataCode');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: documentNumber
    set documentNumber(value:string) {
        this.setValueForKey(value, 'documentNumber');
    }
    get documentNumber():string {
        return this.valueForKey('documentNumber');
    }
    set documentNumberPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentNumber');
    }
    get documentNumberPrimitiveValue():string {
        return this.primitiveValueForKey('documentNumber');
    }

    // Property: dueDate
    set dueDate(value:Date) {
        this.setValueForKey(value, 'dueDate');
    }
    get dueDate():Date {
        return this.valueForKey('dueDate');
    }
    set dueDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'dueDate');
    }
    get dueDatePrimitiveValue():Date {
        return this.primitiveValueForKey('dueDate');
    }

    // Property: exchangeAmount
    set exchangeAmount(value:number) {
        this.setValueForKey(value, 'exchangeAmount');
    }
    get exchangeAmount():number {
        return this.valueForKey('exchangeAmount');
    }
    set exchangeAmountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'exchangeAmount');
    }
    get exchangeAmountPrimitiveValue():number {
        return this.primitiveValueForKey('exchangeAmount');
    }

    // Property: exchangeCurrency
    set exchangeCurrency(value:string) {
        this.setValueForKey(value, 'exchangeCurrency');
    }
    get exchangeCurrency():string {
        return this.valueForKey('exchangeCurrency');
    }
    set exchangeCurrencyPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'exchangeCurrency');
    }
    get exchangeCurrencyPrimitiveValue():string {
        return this.primitiveValueForKey('exchangeCurrency');
    }

    // Property: exchangeRate
    set exchangeRate(value:number) {
        this.setValueForKey(value, 'exchangeRate');
    }
    get exchangeRate():number {
        return this.valueForKey('exchangeRate');
    }
    set exchangeRatePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'exchangeRate');
    }
    get exchangeRatePrimitiveValue():number {
        return this.primitiveValueForKey('exchangeRate');
    }

    // Property: fee
    set fee(value:number) {
        this.setValueForKey(value, 'fee');
    }
    get fee():number {
        return this.valueForKey('fee');
    }
    set feePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'fee');
    }
    get feePrimitiveValue():number {
        return this.primitiveValueForKey('fee');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: reference1
    set reference1(value:string) {
        this.setValueForKey(value, 'reference1');
    }
    get reference1():string {
        return this.valueForKey('reference1');
    }
    set reference1PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'reference1');
    }
    get reference1PrimitiveValue():string {
        return this.primitiveValueForKey('reference1');
    }

    // Property: reference2
    set reference2(value:string) {
        this.setValueForKey(value, 'reference2');
    }
    get reference2():string {
        return this.valueForKey('reference2');
    }
    set reference2PrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'reference2');
    }
    get reference2PrimitiveValue():string {
        return this.primitiveValueForKey('reference2');
    }

    // Property: referenceID
    set referenceID(value:string) {
        this.setValueForKey(value, 'referenceID');
    }
    get referenceID():string {
        return this.valueForKey('referenceID');
    }
    set referenceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'referenceID');
    }
    get referenceIDPrimitiveValue():string {
        return this.primitiveValueForKey('referenceID');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }

    // Property: typeDescription
    set typeDescription(value:string) {
        this.setValueForKey(value, 'typeDescription');
    }
    get typeDescription():string {
        return this.valueForKey('typeDescription');
    }
    set typeDescriptionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'typeDescription');
    }
    get typeDescriptionPrimitiveValue():string {
        return this.primitiveValueForKey('typeDescription');
    }

    // Property: userInfo
    set userInfo(value:any) {
        this.setValueForKey(value, 'userInfo');
    }
    get userInfo():any {
        return this.valueForKey('userInfo');
    }
    set userInfoPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'userInfo');
    }
    get userInfoPrimitiveValue():any {
        return this.primitiveValueForKey('userInfo');
    }
    // Relationship: bankAccount
    set bankAccount(value:BankAccount) {
        this.setValueForKey(value, 'bankAccount');
    }
    get bankAccount():BankAccount {
        return this.valueForKey('bankAccount') as BankAccount;
    }
}
