//
// Generated class PaymentTransaction
//

/// <reference path="PaymentTransaction_ManagedObject.ts" />

class PaymentTransaction extends PaymentTransaction_ManagedObject
{

}
