//
// Generated class ReportScriptItem
//

/// <reference path="ReportScriptItem_ManagedObject.ts" />

enum ReportScriptItemType
{
    None,
    SQL,
    PHP,
    Python,
    Javascript,
    TypeScript,
    Swift
}

class ReportScriptItem extends ReportScriptItem_ManagedObject
{
    scriptTypeFromExtensionName(){
        let ext = this.name.pathExtension().toLowerCase();
        switch(ext){
            case "sql":
            return ReportScriptItemType.SQL;

            case "php":
            return ReportScriptItemType.PHP;

            case "py":
            return ReportScriptItemType.Python;

            case "js":
            return ReportScriptItemType.Javascript;

            case "ts":
            return ReportScriptItemType.TypeScript;

            case "swift":
            return ReportScriptItemType.Swift;
        }

        return ReportScriptItemType.None;
    }
}
