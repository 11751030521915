

/// <reference path="TicketLine.ts" />

// Generated class SeparatedMenuLine_ManagedObject

class SeparatedMenuLine_ManagedObject extends TicketLine
{
    // Relationship: menuLine
    set menuLine(value:MenuLine) {
        this.setValueForKey(value, 'menuLine');
    }
    get menuLine():MenuLine {
        return this.valueForKey('menuLine') as MenuLine;
    }

    // Relationship: menuProductLines
    protected _menuProductLines:MIOManagedObjectSet = null;
    get menuProductLines():MIOManagedObjectSet {
        return this.valueForKey('menuProductLines');
    }
    addMenuProductLinesObject(value:MenuProductLine) {
        this._addObjectForKey(value, 'menuProductLines');
    }
    removeMenuProductLinesObject(value:MenuProductLine) {
        this._removeObjectForKey(value, 'menuProductLines');
    }
}
