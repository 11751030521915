/// <reference path="AppHelper.ts" />

interface AppHelper
{
    showAddLedgerAccountAlert( vc:MUIViewController, name:string, completion:any ) : void ;
}

AppHelper.prototype.showAddLedgerAccountAlert = function( vc:MUIViewController, nameValue:string = null, completion:any = null) : void
{
    let avc = new MUIAlertViewController();
    avc.initWithTitle(MIOLocalizeString('NEW LEDGER ACCOUNT','NEW LEDGER ACCOUNT'), MIOLocalizeString('CHOOSE A NAME','CHOOSE A NAME'), MUIAlertViewStyle.Default);
    avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
        if (nameValue != null) textField.text = nameValue;
        else textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
    });
    
    avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){        
        textField.setPlaceholderText(MIOLocalizeString("PREFIX","PREFIX"));
    });

    avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD','ADD'), MUIAlertActionStyle.Default, this, function(){
        let nameTF = avc.textFields[0];
        let name = nameTF.text.trim();

        let prefixTF = avc.textFields[1];
        let prefix = prefixTF.text.trim();

        if (name.length > 0 && prefix.length > 0) {
            let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
            let newLedgerAccount = MIOEntityDescription.insertNewObjectForEntityForName("LedgerAccount", moc) as LedgerAccount;
            newLedgerAccount.identifier = MIOUUID.UUID().UUIDString;
            newLedgerAccount.name = name;
            newLedgerAccount.normalizedAccountPrefix = prefix;
            moc.save();
            if (completion) completion(newLedgerAccount);
        }
        else {
            let error = new MUIAlertViewController();
            error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('CHECK NAME AND PREFIX','CHECK NAME AND PREFIX'), MUIAlertViewStyle.Default);
            error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Cancel, this, function(){}));
            
            this.presentViewController(error, true);
        }
    }));

    avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Cancel, this, function(){}));
    
    if (vc != null) {
        vc.presentViewController(avc, true);
    }
    else {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.window.rootViewController.presentViewController(avc, true);
    }        

}
