//
// Generated class IntegratorMappingView
//

/// <reference path="IntegratorMappingView_ManagedObject.ts" />

class IntegratorMappingView extends IntegratorMappingView_ManagedObject
{

}
