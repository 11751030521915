

class UserAccessCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    private emailLabel:MUILabel = null;
    private workerLabel:MUILabel = null;    

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.emailLabel = MUIOutlet(this, "email-lbl", "MUILabel");
        this.workerLabel = MUIOutlet(this, "worker-lbl", "MUILabel");
    }

    set item (item:User){
        this.nameLabel.text = item.name;
        this.emailLabel.text = item.email;
        this.workerLabel.text = null;

        // if (item.permisions.worker != null) {
        //     this.workerLabel.text = item.permisions.worker.name;
        // }
    }
}