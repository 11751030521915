//
// Generated class ArchivedTicketLine
//

/// <reference path="ArchivedTicketLine_ManagedObject.ts" />

enum ArchivedLineModifiedType
{
   none                      = 0,
   nullify                   = 1,
   refound                   = 2,
   refoundAndPoints          = 3,
   newLine                   = 4,
   visualGroup               = 5,
   refoundAndEconomicAccount = 6
}

class ArchivedTicketLine extends ArchivedTicketLine_ManagedObject
{

}
