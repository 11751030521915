

/// <reference path="CoreAnnotation.ts" />

// Generated class AccountAnnotation_ManagedObject

class AccountAnnotation_ManagedObject extends CoreAnnotation
{

    // Property: businessAreaID
    set businessAreaID(value:string) {
        this.setValueForKey(value, 'businessAreaID');
    }
    get businessAreaID():string {
        return this.valueForKey('businessAreaID');
    }
    set businessAreaIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'businessAreaID');
    }
    get businessAreaIDPrimitiveValue():string {
        return this.primitiveValueForKey('businessAreaID');
    }

    // Property: businessAreaName
    set businessAreaName(value:string) {
        this.setValueForKey(value, 'businessAreaName');
    }
    get businessAreaName():string {
        return this.valueForKey('businessAreaName');
    }
    set businessAreaNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'businessAreaName');
    }
    get businessAreaNamePrimitiveValue():string {
        return this.primitiveValueForKey('businessAreaName');
    }

    // Property: categoryTags
    set categoryTags(value:string) {
        this.setValueForKey(value, 'categoryTags');
    }
    get categoryTags():string {
        return this.valueForKey('categoryTags');
    }
    set categoryTagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'categoryTags');
    }
    get categoryTagsPrimitiveValue():string {
        return this.primitiveValueForKey('categoryTags');
    }

    // Property: closeLocationCategoryID
    set closeLocationCategoryID(value:string) {
        this.setValueForKey(value, 'closeLocationCategoryID');
    }
    get closeLocationCategoryID():string {
        return this.valueForKey('closeLocationCategoryID');
    }
    set closeLocationCategoryIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'closeLocationCategoryID');
    }
    get closeLocationCategoryIDPrimitiveValue():string {
        return this.primitiveValueForKey('closeLocationCategoryID');
    }

    // Property: closeLocationCategoryName
    set closeLocationCategoryName(value:string) {
        this.setValueForKey(value, 'closeLocationCategoryName');
    }
    get closeLocationCategoryName():string {
        return this.valueForKey('closeLocationCategoryName');
    }
    set closeLocationCategoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'closeLocationCategoryName');
    }
    get closeLocationCategoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('closeLocationCategoryName');
    }

    // Property: closeLocationID
    set closeLocationID(value:string) {
        this.setValueForKey(value, 'closeLocationID');
    }
    get closeLocationID():string {
        return this.valueForKey('closeLocationID');
    }
    set closeLocationIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'closeLocationID');
    }
    get closeLocationIDPrimitiveValue():string {
        return this.primitiveValueForKey('closeLocationID');
    }

    // Property: closeLocationName
    set closeLocationName(value:string) {
        this.setValueForKey(value, 'closeLocationName');
    }
    get closeLocationName():string {
        return this.valueForKey('closeLocationName');
    }
    set closeLocationNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'closeLocationName');
    }
    get closeLocationNamePrimitiveValue():string {
        return this.primitiveValueForKey('closeLocationName');
    }

    // Property: closeWorkerID
    set closeWorkerID(value:string) {
        this.setValueForKey(value, 'closeWorkerID');
    }
    get closeWorkerID():string {
        return this.valueForKey('closeWorkerID');
    }
    set closeWorkerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'closeWorkerID');
    }
    get closeWorkerIDPrimitiveValue():string {
        return this.primitiveValueForKey('closeWorkerID');
    }

    // Property: closeWorkerName
    set closeWorkerName(value:string) {
        this.setValueForKey(value, 'closeWorkerName');
    }
    get closeWorkerName():string {
        return this.valueForKey('closeWorkerName');
    }
    set closeWorkerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'closeWorkerName');
    }
    get closeWorkerNamePrimitiveValue():string {
        return this.primitiveValueForKey('closeWorkerName');
    }

    // Property: locationCategoryID
    set locationCategoryID(value:string) {
        this.setValueForKey(value, 'locationCategoryID');
    }
    get locationCategoryID():string {
        return this.valueForKey('locationCategoryID');
    }
    set locationCategoryIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationCategoryID');
    }
    get locationCategoryIDPrimitiveValue():string {
        return this.primitiveValueForKey('locationCategoryID');
    }

    // Property: locationCategoryName
    set locationCategoryName(value:string) {
        this.setValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryName():string {
        return this.valueForKey('locationCategoryName');
    }
    set locationCategoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationCategoryName');
    }

    // Property: locationID
    set locationID(value:string) {
        this.setValueForKey(value, 'locationID');
    }
    get locationID():string {
        return this.valueForKey('locationID');
    }
    set locationIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationID');
    }
    get locationIDPrimitiveValue():string {
        return this.primitiveValueForKey('locationID');
    }

    // Property: locationName
    set locationName(value:string) {
        this.setValueForKey(value, 'locationName');
    }
    get locationName():string {
        return this.valueForKey('locationName');
    }
    set locationNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationName');
    }
    get locationNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationName');
    }

    // Property: numberSeriesID
    set numberSeriesID(value:string) {
        this.setValueForKey(value, 'numberSeriesID');
    }
    get numberSeriesID():string {
        return this.valueForKey('numberSeriesID');
    }
    set numberSeriesIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'numberSeriesID');
    }
    get numberSeriesIDPrimitiveValue():string {
        return this.primitiveValueForKey('numberSeriesID');
    }

    // Property: numberSeriesPrefix
    set numberSeriesPrefix(value:string) {
        this.setValueForKey(value, 'numberSeriesPrefix');
    }
    get numberSeriesPrefix():string {
        return this.valueForKey('numberSeriesPrefix');
    }
    set numberSeriesPrefixPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'numberSeriesPrefix');
    }
    get numberSeriesPrefixPrimitiveValue():string {
        return this.primitiveValueForKey('numberSeriesPrefix');
    }

    // Property: openLocationCategoryID
    set openLocationCategoryID(value:string) {
        this.setValueForKey(value, 'openLocationCategoryID');
    }
    get openLocationCategoryID():string {
        return this.valueForKey('openLocationCategoryID');
    }
    set openLocationCategoryIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'openLocationCategoryID');
    }
    get openLocationCategoryIDPrimitiveValue():string {
        return this.primitiveValueForKey('openLocationCategoryID');
    }

    // Property: openLocationCategoryName
    set openLocationCategoryName(value:string) {
        this.setValueForKey(value, 'openLocationCategoryName');
    }
    get openLocationCategoryName():string {
        return this.valueForKey('openLocationCategoryName');
    }
    set openLocationCategoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'openLocationCategoryName');
    }
    get openLocationCategoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('openLocationCategoryName');
    }

    // Property: openLocationID
    set openLocationID(value:string) {
        this.setValueForKey(value, 'openLocationID');
    }
    get openLocationID():string {
        return this.valueForKey('openLocationID');
    }
    set openLocationIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'openLocationID');
    }
    get openLocationIDPrimitiveValue():string {
        return this.primitiveValueForKey('openLocationID');
    }

    // Property: openLocationName
    set openLocationName(value:string) {
        this.setValueForKey(value, 'openLocationName');
    }
    get openLocationName():string {
        return this.valueForKey('openLocationName');
    }
    set openLocationNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'openLocationName');
    }
    get openLocationNamePrimitiveValue():string {
        return this.primitiveValueForKey('openLocationName');
    }

    // Property: openWorkerID
    set openWorkerID(value:string) {
        this.setValueForKey(value, 'openWorkerID');
    }
    get openWorkerID():string {
        return this.valueForKey('openWorkerID');
    }
    set openWorkerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'openWorkerID');
    }
    get openWorkerIDPrimitiveValue():string {
        return this.primitiveValueForKey('openWorkerID');
    }

    // Property: openWorkerName
    set openWorkerName(value:string) {
        this.setValueForKey(value, 'openWorkerName');
    }
    get openWorkerName():string {
        return this.valueForKey('openWorkerName');
    }
    set openWorkerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'openWorkerName');
    }
    get openWorkerNamePrimitiveValue():string {
        return this.primitiveValueForKey('openWorkerName');
    }

    // Property: rateID
    set rateID(value:string) {
        this.setValueForKey(value, 'rateID');
    }
    get rateID():string {
        return this.valueForKey('rateID');
    }
    set rateIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'rateID');
    }
    get rateIDPrimitiveValue():string {
        return this.primitiveValueForKey('rateID');
    }

    // Property: rateName
    set rateName(value:string) {
        this.setValueForKey(value, 'rateName');
    }
    get rateName():string {
        return this.valueForKey('rateName');
    }
    set rateNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'rateName');
    }
    get rateNamePrimitiveValue():string {
        return this.primitiveValueForKey('rateName');
    }

    // Property: salesCategoryID
    set salesCategoryID(value:string) {
        this.setValueForKey(value, 'salesCategoryID');
    }
    get salesCategoryID():string {
        return this.valueForKey('salesCategoryID');
    }
    set salesCategoryIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'salesCategoryID');
    }
    get salesCategoryIDPrimitiveValue():string {
        return this.primitiveValueForKey('salesCategoryID');
    }

    // Property: salesCategoryName
    set salesCategoryName(value:string) {
        this.setValueForKey(value, 'salesCategoryName');
    }
    get salesCategoryName():string {
        return this.valueForKey('salesCategoryName');
    }
    set salesCategoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'salesCategoryName');
    }
    get salesCategoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('salesCategoryName');
    }

    // Relationship: children
    protected _children:MIOManagedObjectSet = null;
    get children():MIOManagedObjectSet {
        return this.valueForKey('children');
    }
    addChildrenObject(value:AccountAnnotation) {
        this._addObjectForKey(value, 'children');
    }
    removeChildrenObject(value:AccountAnnotation) {
        this._removeObjectForKey(value, 'children');
    }
    // Relationship: parent
    set parent(value:AccountAnnotation) {
        this.setValueForKey(value, 'parent');
    }
    get parent():AccountAnnotation {
        return this.valueForKey('parent') as AccountAnnotation;
    }
}
