//
// Generated class PurchaseInvoiceLine
//

/// <reference path="PurchaseInvoiceLine_ManagedObject.ts" />

class PurchaseInvoiceLine extends PurchaseInvoiceLine_ManagedObject
{

}
