/**
 * Created by miguel on 31/1/17.
 */



class ClientVIPPointsViewController extends MUIViewController
{

    private _client:Client = null;

    //private _vipLines = [];

    private tableView:MUITableView = null;

    private backButton:MUIButton = null;
    private addButton:MUIButton = null;
    private removeButton:MUIButton = null;
    private quantityTextField:MUITextField = null;
    private totalLabel:MUILabel = null;
    
    private _fetchedResultsController:MIOFetchedResultsController = null;
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;

    viewDidLoad()
    {
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, 'cvpv_nb_back_btn', 'MUIButton');
        this.backButton.setAction(this, function () {

            this.navigationController.popViewController(true);
        });

        this.addButton = MUIOutlet(this, 'cvpv_b1_add_points_btn', 'MUIButton');
        this.addButton.setAction(this, function () {

            let value = this.cf.numberFromString(this.quantityTextField.text);
            if(!isNaN(value) && value != 0)
                this.createLoyaltyLine(value)
        });

        this.removeButton = MUIOutlet(this, 'cvpv_b1_remove_points_btn', 'MUIButton');
        this.removeButton.setAction(this, function () {

            let value = this.cf.numberFromString(this.quantityTextField.text);
            if(!isNaN(value) && value != 0)
                this.createLoyaltyLine(-value)
    
        });

        this.quantityTextField = MUIOutlet(this, 'cvpv_b1_add_btn', 'MUITextField');
        this.quantityTextField.formatter = this.cf;
        
        this.totalLabel = MUIOutlet(this, 'cvpv_b1_total_lbl', 'MUILabel');

        this.tableView = MUIOutlet(this, 'cvpv_b1_tableview','MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.updateUI();

    }

    private createLoyaltyLine(value)
    {
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        
        var loyaltyLine:LoyaltyAccountLine = MIOEntityDescription.insertNewObjectForEntityForName("LoyaltyAccountLine", moc) as LoyaltyAccountLine;
        loyaltyLine.identifier = MIOUUID.UUID().UUIDString;
        loyaltyLine.economicEntity = this._client;
        loyaltyLine.concept = (value < 0 ? 'Remove ':'Add ') + ' points to ' + this._client.name;
        loyaltyLine.value = value;
        loyaltyLine.date = new Date()
        this._client.addLoyaltyAccountLinesObject(loyaltyLine);        
        
        this.totalLabel.text = this.cf.stringFromNumber(this._client.loyaltyBalance + value);
        this.quantityTextField.text = null;

        moc.save();
    }

    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
        this.updateUI();
    }

    set client(value)
    {
        this._client = value;
        this.updateUI();
    }

    private updateUI()
    {
        if(!this.viewIsLoaded || this._client == null) return;

        this.quantityTextField.text = '';
        this.fetchedResultsController = null;
        this.totalLabel.text = this.cf.stringFromNumber(this._client.loyaltyBalance);
        this.tableView.reloadData();
    }

    numberOfSections(tableview)
    {
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section)
    {
        var sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview:MUITableView, indexPath:MIOIndexPath)
    {
        var cell:ClientVIPPointsCell = tableview.dequeueReusableCellWithIdentifier('ClientVIPPointsCell') as ClientVIPPointsCell;
    
        var item = this.fetchedResultsController.objectAtIndexPath(indexPath);
    
        cell.item = item;
    
        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
    
        return cell;
    }
    
    canSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath)
    {
        return false;
    }
    
    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath)
    {
       
    }
    
    set fetchedResultsController(value)
    {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController()
    {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        var ad = MUIWebApplication.sharedInstance().delegate;
    
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('date', false)];
    
        var fetchRequest = DBHelper.listFetchRequestWithEntityName('LoyaltyAccountLine', sortDescriptors, 'client.identifier == ' + this._client.identifier);
        var fetchedResultsController = new MIOFetchedResultsController();

        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller)
    {
        this.tableView.reloadData();
    }

}