/**
 * Created by miguel on 10/2/17.
 */




class AddNewCategoryTaxViewController extends MUIViewController
{
    private _category:ProductCategory = null;
    private _fetchedResultsController:MIOFetchedResultsController = null;

    private tableView:MUITableView = null;

    private selectedRow = -1;
    private selectedSection = -1;

    viewDidLoad()
    {
        super.viewDidLoad();

        this.tableView = MUIOutlet(this, 'anctv_c1_tableview', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
        
        if(this._category != null)
        {
            this.fetchedResultsController = null;
            this.tableView.reloadData();
        }
    }

    public clear()
    {
        this.selectedRow = -1;
        this.selectedSection = -1;
        if(this.tableView && this._category != null){
            this.fetchedResultsController = null;
            this.tableView.reloadData();
        }
    }

    set category(value)
    {
        this._category = value;
        this.clear();
    }

    numberOfSections(tableview)
    {
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview, section)
    {
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath)
    {
        let cell:AddNewCategoryTaxCell = tableview.dequeueReusableCellWithIdentifier("AddNewCategoryTaxCell") as AddNewCategoryTaxCell;

        let item:Tax = this.fetchedResultsController.objectAtIndexPath(indexPath);

        cell.item = item;
        
        cell.selectionStyle = MUITableViewCellSelectionStyle.None;

        if(this._category.tax == item ) {
            this.selectedRow = indexPath.row; 
            this.selectedSection = indexPath.section;
            cell.setAccessoryType(MUITableViewCellAccessoryType.Checkmark);    
        }
        else
            cell.setAccessoryType(MUITableViewCellAccessoryType.None); 

        return cell;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath)
    {
        let tax = this.fetchedResultsController.objectAtIndexPath(indexPath) as Tax;

        if(this.selectedSection != -1 && this.selectedRow != -1){
            this.tableView.cellAtIndexPath(MIOIndexPath.indexForRowInSection(this.selectedRow, this.selectedSection)).setAccessoryType(MUITableViewCellAccessoryType.None);    
        }
        this.selectedRow = indexPath.row;
        this.selectedSection = indexPath.section;

        this.tableView.cellAtIndexPath(indexPath).setAccessoryType(MUITableViewCellAccessoryType.Checkmark);    

        this._category.tax = tax;
        tax.addCategoriesObject(this._category);
    }

    set fetchedResultsController(value)
    {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController()
    {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;


        var ad = MUIWebApplication.sharedInstance().delegate;
        
        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey('name', true)];
    
        var fetchRequest = DBHelper.listFetchRequestWithEntityName('Tax', sortDescriptors, null);
        var fetchedResultsController = new MIOFetchedResultsController();
        
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;

        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller)
    {
        this.tableView.reloadData();
    }
}
