


class ProductModifiersListViewController extends EntityListViewController
{
    static newInstance() : ProductModifiersListViewController {
        let vc = new ProductModifiersListViewController("product-modifiers-list-view");
        vc.initWithResource("layout/productmodifiers/ProductModifiersListView.html");
        return vc;
     }

    parentCategoryID = null;
    parentCategoryName = null;
    category:ProductCategory = null;    
    
    private addButton:MUIButton = null;
    private backButton:MUIButton = null;
    private titleLabel:MUILabel = null;      
        
    viewDidLoad(){
        super.viewDidLoad();

        this.backButton = MUIOutlet(this, 'back-btn', 'MUIButton');
        this.backButton.setAction(this, function (){
            this.navigationController.popViewController(true);
        });

        this.titleLabel = MUIOutlet(this, 'title-lbl', 'MUILabel');
        
        this.addButton = MUIOutlet(this, 'add-btn', 'MUIButton');
        this.addButton.setAction(this, function (){
            this.showAddProductModifierCategory();
        });

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.tableView = MUIOutlet(this,"table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;        

        MIONotificationCenter.defaultCenter().addObserver(this, "workspaceDidChange", function (notification) {
            this.navigationController.popToRootViewController(false);
        });

        MIONotificationCenter.defaultCenter().addObserver(this, 'ProductModifierDidDeleted', function (notification:MIONotification) {            
            let item = notification.object;
            // if (this.selectedItem == item.itemID) {                
            //     // DBHelper.deleteObjectFromMainContext(item, true);
            //     // this.selectedItem = null;
            //     // this.selectedProductItem = null;                
            //     // this.showNoItemSelected();
            //     // this.invalidateData();
            // }
            this.showNoItemSelected();
        });
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.backButton.setHidden(true);
        // this.sectionsButton.setHidden(this.parentCategoryID ? true : false);
        // this.titleLabel.setHidden(this.parentCategoryID == null ? true : false);
        // if(this.parentCategoryName) this.titleLabel.text = this.parentCategoryName;
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        
        let cell = tableview.dequeueReusableCellWithIdentifier("ProductModifierCategoryCell") as ProductModifierCategoryCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ProductModifierCategory;
        cell.item = item;            

        //for cell selectiond
        cell.selected = this.selectedItem != null && this.selectedItem.identifier == item.identifier ? true : false;

        return cell;
    }
    
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){
        let selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath);
        this.showItemDetail(selectedItem);
    }

    private showModifierList(item:ProductModifierCategory){
        if (item == null) return;

        let vc = ProductModifiersListViewController.newInstance();        
        vc.parentCategoryID = item.identifier;
        vc.parentCategoryName = item.name;
        this.navigationController.pushViewController(vc, true);
    }

    private showItemDetail(item:ProductModifierCategory){     
        if (item == null) return;
        
        let vc = ProductModifiersDetailViewController.newInstance();
        vc.item = item;    
            
        this.navigationController.splitViewController.showDetailViewController(vc);
    }

    private showNoItemSelected(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Product modifiers", NoItemSelectedViewControllerType.ProductModifiers);
        this.navigationController.splitViewController.showDetailViewController(vc);
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let fetchRequest:MIOFetchRequest = null;
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true), MIOSortDescriptor.sortDescriptorWithKey("orderIndex", true)]; 

        fetchRequest = DBHelper.listFetchRequestWithEntityName("ProductModifierCategory", sortDescriptors, null);
        fetchRequest.relationshipKeyPathsForPrefetching = ['category'];
                
        // if (this.searchString != null) predicate.push('name CONTAINS "'+ this.searchString + '"');

        // if (this.searchString == null){            
        //     if (this.productType == ProductType.Sales){
        //         predicate.push("parentCategoryID == " + (this.parentCategoryID ? this.parentCategoryID : "null"));
        //         predicate.push("(type == " + ProductListItemType.ProductCategory + " OR type == " + ProductListItemType.Product + ")");
        //     }                
        //     else if (this.productType == ProductType.Stock){                
        //         predicate.push("parentStockCategoryID == " + (this.parentCategoryID ? this.parentCategoryID : "null"));                                
        //         if (this.parentCategoryID == null) {
        //             predicate.push("type == " + ProductListItemType.StockCategory);
        //         }
        //         else {                    
        //             predicate.push("type != " + ProductListItemType.ProductCategory);
        //         }                                                     
        //     }
        //     else {
        //         predicate.push("parentCategoryID == null");
        //         predicate.push("parentStockCategoryID == null");
        //         predicate.push("type == " + ProductListItemType.Product);
        //     }            
        // }
        
        //if(predicate.length > 0) filterString = predicate.join(' and ');

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;        
    }

    private showAddProductModifierCategory(){

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('ADD PRODUCT MODIFIER CATEGORY',"ADD PRODUCT MODIFIER CATEGORY"), MIOLocalizeString('CREATE A PRODUCT MODIFIER CATAGORY',"CREATE A PRODUCT MODIFIER CATAGORY"), MUIAlertViewStyle.Default);    

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
            textField.setPlaceholderText(MIOLocalizeString("NAME", "NAME"));
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK',"OK"), MUIAlertActionStyle.Default, this, function(){                        
            let name = avc.textFields[0].text;
            this.createProductModifierCategory(name);
            DBHelper.saveMainContext();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL',"CANCEL"), MUIAlertActionStyle.Cancel, null, null));
        this.presentViewController(avc, true);    
    }
      
    private createProductModifierCategory(name:string):ProductModifierCategory{
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let productModifierCategory = MIOEntityDescription.insertNewObjectForEntityForName("ProductModifierCategory", ad.managedObjectContext) as ProductModifierCategory;
        productModifierCategory.identifier = MIOUUID.UUID().UUIDString;
        productModifierCategory.name = name;
        productModifierCategory.productModifierCategoryType = ProductModifierCategoryType.Manual;

        return productModifierCategory;
    }

    
}

