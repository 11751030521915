

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ReportSettingsMenu_ManagedObject

class ReportSettingsMenu_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: config
    set config(value:any) {
        this.setValueForKey(value, 'config');
    }
    get config():any {
        return this.valueForKey('config');
    }
    set configPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'config');
    }
    get configPrimitiveValue():any {
        return this.primitiveValueForKey('config');
    }
}
