

/// <reference path="ReconliceTransaction.ts" />

// Generated class ExpenseReconcileTransaction_ManagedObject

class ExpenseReconcileTransaction_ManagedObject extends ReconliceTransaction
{

    // Relationship: expense
    protected _expense:MIOManagedObjectSet = null;
    get expense():MIOManagedObjectSet {
        return this.valueForKey('expense');
    }
    addExpenseObject(value:Expense) {
        this._addObjectForKey(value, 'expense');
    }
    removeExpenseObject(value:Expense) {
        this._removeObjectForKey(value, 'expense');
    }
}
