

// Generated class SyncLogByApp_ManagedObject

class SyncLogByApp_ManagedObject extends MIOManagedObject
{

    // Property: appID
    set appID(value:string) {
        this.setValueForKey(value, 'appID');
    }
    get appID():string {
        return this.valueForKey('appID');
    }
    set appIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'appID');
    }
    get appIDPrimitiveValue():string {
        return this.primitiveValueForKey('appID');
    }

    // Property: lastSyncID
    set lastSyncID(value:number) {
        this.setValueForKey(value, 'lastSyncID');
    }
    get lastSyncID():number {
        return this.valueForKey('lastSyncID');
    }
    set lastSyncIDPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lastSyncID');
    }
    get lastSyncIDPrimitiveValue():number {
        return this.primitiveValueForKey('lastSyncID');
    }

    // Property: syncID
    set syncID(value:number) {
        this.setValueForKey(value, 'syncID');
    }
    get syncID():number {
        return this.valueForKey('syncID');
    }
    set syncIDPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'syncID');
    }
    get syncIDPrimitiveValue():number {
        return this.primitiveValueForKey('syncID');
    }
}
