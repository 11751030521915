
class VIPCardAddViewController extends MUIViewController
{
    delegate = null;

    private closeButton:MUIButton = null;
    private sendButton:MUIButton = null;
    private tableView:MUITableView = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.closeButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.closeButton.setAction(this, function(){
            this.dismissViewController(true);
        });        

        this.sendButton = MUIOutlet(this, "send-btn", "MUIButton");
        this.sendButton.setAction(this, function(){
            if (this.emails.count > 0) {
                this.delegate.sendVIPCardEmails(this.emails);
                this.dismissViewController(true);
            }
        });

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.tableView.reloadData();
    }

    private emails = [];
    numberOfSections(tableview){
        return 1;
    }

    numberOfRowsInSection(tableview, section){
        return this.emails.length + 1;
    }

    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell:VIPCardAddCell = tableview.dequeueReusableCellWithIdentifier("AddCell");        
        cell.delegate = this;

        if (indexPath.row < this.emails.count){
            cell.email = this.emails[indexPath.row];
            cell.newEmailCell = false;
        }        
        else {
            cell.email = null;
            cell.newEmailCell = true;
        }
        
        return cell;
    }

    addedEmail(cell:VIPCardAddCell, email:string){
        this.emails.addObject(email);
        this.tableView.reloadData();
    }
}