class CashDeskTransactionCell extends MUITableViewCell
{
    private dateLabel:MUILabel = null;
    private conceptLabel:MUILabel = null;
    private referenceLabel:MUILabel = null;
    private payMethodLabel:MUILabel = null;
    private priceLabel:MUILabel = null;

    private _item:CashDeskLine = null;

    awakeFromHTML()
    {
        this.dateLabel = MUIOutlet(this, 'cdtv_tv_cdc_date_lbl', 'MUILabel');
        this.conceptLabel = MUIOutlet(this, 'cdtv_tv_cdc_concept_lbl','MUILabel');
        this.referenceLabel = MUIOutlet(this, 'cdtv_tv_cdc_reference_lbl','MUILabel');
        this.payMethodLabel = MUIOutlet(this, 'cdtv_tv_cdc_pay_metod_lbl','MUILabel');
        this.priceLabel = MUIOutlet(this, 'cdtv_tv_cdc_price_lbl','MUILabel');
    }

    set item(item)
    {
        this._item = item;
    }
}
