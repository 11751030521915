

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ArchivedDocument_ManagedObject

class ArchivedDocument_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: beginWorkerID
    set beginWorkerID(value:string) {
        this.setValueForKey(value, 'beginWorkerID');
    }
    get beginWorkerID():string {
        return this.valueForKey('beginWorkerID');
    }
    set beginWorkerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'beginWorkerID');
    }
    get beginWorkerIDPrimitiveValue():string {
        return this.primitiveValueForKey('beginWorkerID');
    }

    // Property: beginWorkerName
    set beginWorkerName(value:string) {
        this.setValueForKey(value, 'beginWorkerName');
    }
    get beginWorkerName():string {
        return this.valueForKey('beginWorkerName');
    }
    set beginWorkerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'beginWorkerName');
    }
    get beginWorkerNamePrimitiveValue():string {
        return this.primitiveValueForKey('beginWorkerName');
    }

    // Property: closed
    set closed(value:boolean) {
        this.setValueForKey(value, 'closed');
    }
    get closed():boolean {
        return this.valueForKey('closed');
    }
    set closedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'closed');
    }
    get closedPrimitiveValue():boolean {
        return this.primitiveValueForKey('closed');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: documentID
    set documentID(value:string) {
        this.setValueForKey(value, 'documentID');
    }
    get documentID():string {
        return this.valueForKey('documentID');
    }
    set documentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentID');
    }
    get documentIDPrimitiveValue():string {
        return this.primitiveValueForKey('documentID');
    }

    // Property: endWorkerID
    set endWorkerID(value:string) {
        this.setValueForKey(value, 'endWorkerID');
    }
    get endWorkerID():string {
        return this.valueForKey('endWorkerID');
    }
    set endWorkerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'endWorkerID');
    }
    get endWorkerIDPrimitiveValue():string {
        return this.primitiveValueForKey('endWorkerID');
    }

    // Property: endWorkerName
    set endWorkerName(value:string) {
        this.setValueForKey(value, 'endWorkerName');
    }
    get endWorkerName():string {
        return this.valueForKey('endWorkerName');
    }
    set endWorkerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'endWorkerName');
    }
    get endWorkerNamePrimitiveValue():string {
        return this.primitiveValueForKey('endWorkerName');
    }

    // Property: hotelReservationID
    set hotelReservationID(value:string) {
        this.setValueForKey(value, 'hotelReservationID');
    }
    get hotelReservationID():string {
        return this.valueForKey('hotelReservationID');
    }
    set hotelReservationIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'hotelReservationID');
    }
    get hotelReservationIDPrimitiveValue():string {
        return this.primitiveValueForKey('hotelReservationID');
    }

    // Property: legalDate
    set legalDate(value:Date) {
        this.setValueForKey(value, 'legalDate');
    }
    get legalDate():Date {
        return this.valueForKey('legalDate');
    }
    set legalDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'legalDate');
    }
    get legalDatePrimitiveValue():Date {
        return this.primitiveValueForKey('legalDate');
    }

    // Property: legalDocumentID
    set legalDocumentID(value:string) {
        this.setValueForKey(value, 'legalDocumentID');
    }
    get legalDocumentID():string {
        return this.valueForKey('legalDocumentID');
    }
    set legalDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalDocumentID');
    }
    get legalDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('legalDocumentID');
    }

    // Property: legalGovernmentReference
    set legalGovernmentReference(value:string) {
        this.setValueForKey(value, 'legalGovernmentReference');
    }
    get legalGovernmentReference():string {
        return this.valueForKey('legalGovernmentReference');
    }
    set legalGovernmentReferencePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalGovernmentReference');
    }
    get legalGovernmentReferencePrimitiveValue():string {
        return this.primitiveValueForKey('legalGovernmentReference');
    }

    // Property: legalGovernmentURL
    set legalGovernmentURL(value:string) {
        this.setValueForKey(value, 'legalGovernmentURL');
    }
    get legalGovernmentURL():string {
        return this.valueForKey('legalGovernmentURL');
    }
    set legalGovernmentURLPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalGovernmentURL');
    }
    get legalGovernmentURLPrimitiveValue():string {
        return this.primitiveValueForKey('legalGovernmentURL');
    }

    // Property: modifiedByUser
    set modifiedByUser(value:boolean) {
        this.setValueForKey(value, 'modifiedByUser');
    }
    get modifiedByUser():boolean {
        return this.valueForKey('modifiedByUser');
    }
    set modifiedByUserPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'modifiedByUser');
    }
    get modifiedByUserPrimitiveValue():boolean {
        return this.primitiveValueForKey('modifiedByUser');
    }

    // Property: modifiedType
    set modifiedType(value:number) {
        this.setValueForKey(value, 'modifiedType');
    }
    get modifiedType():number {
        return this.valueForKey('modifiedType');
    }
    set modifiedTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'modifiedType');
    }
    get modifiedTypePrimitiveValue():number {
        return this.primitiveValueForKey('modifiedType');
    }

    // Property: numberOfPeople
    set numberOfPeople(value:number) {
        this.setValueForKey(value, 'numberOfPeople');
    }
    get numberOfPeople():number {
        return this.valueForKey('numberOfPeople');
    }
    set numberOfPeoplePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'numberOfPeople');
    }
    get numberOfPeoplePrimitiveValue():number {
        return this.primitiveValueForKey('numberOfPeople');
    }

    // Property: numberSeriesID
    set numberSeriesID(value:string) {
        this.setValueForKey(value, 'numberSeriesID');
    }
    get numberSeriesID():string {
        return this.valueForKey('numberSeriesID');
    }
    set numberSeriesIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'numberSeriesID');
    }
    get numberSeriesIDPrimitiveValue():string {
        return this.primitiveValueForKey('numberSeriesID');
    }

    // Property: numberSeriesPayMethodID
    set numberSeriesPayMethodID(value:string) {
        this.setValueForKey(value, 'numberSeriesPayMethodID');
    }
    get numberSeriesPayMethodID():string {
        return this.valueForKey('numberSeriesPayMethodID');
    }
    set numberSeriesPayMethodIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'numberSeriesPayMethodID');
    }
    get numberSeriesPayMethodIDPrimitiveValue():string {
        return this.primitiveValueForKey('numberSeriesPayMethodID');
    }

    // Property: onlinePlatformFeePercentage
    set onlinePlatformFeePercentage(value:number) {
        this.setValueForKey(value, 'onlinePlatformFeePercentage');
    }
    get onlinePlatformFeePercentage():number {
        return this.valueForKey('onlinePlatformFeePercentage');
    }
    set onlinePlatformFeePercentagePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'onlinePlatformFeePercentage');
    }
    get onlinePlatformFeePercentagePrimitiveValue():number {
        return this.primitiveValueForKey('onlinePlatformFeePercentage');
    }

    // Property: onlinePlatformID
    set onlinePlatformID(value:string) {
        this.setValueForKey(value, 'onlinePlatformID');
    }
    get onlinePlatformID():string {
        return this.valueForKey('onlinePlatformID');
    }
    set onlinePlatformIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'onlinePlatformID');
    }
    get onlinePlatformIDPrimitiveValue():string {
        return this.primitiveValueForKey('onlinePlatformID');
    }

    // Property: onlinePlatformName
    set onlinePlatformName(value:string) {
        this.setValueForKey(value, 'onlinePlatformName');
    }
    get onlinePlatformName():string {
        return this.valueForKey('onlinePlatformName');
    }
    set onlinePlatformNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'onlinePlatformName');
    }
    get onlinePlatformNamePrimitiveValue():string {
        return this.primitiveValueForKey('onlinePlatformName');
    }

    // Property: onlinePlatformPenalty
    set onlinePlatformPenalty(value:number) {
        this.setValueForKey(value, 'onlinePlatformPenalty');
    }
    get onlinePlatformPenalty():number {
        return this.valueForKey('onlinePlatformPenalty');
    }
    set onlinePlatformPenaltyPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'onlinePlatformPenalty');
    }
    get onlinePlatformPenaltyPrimitiveValue():number {
        return this.primitiveValueForKey('onlinePlatformPenalty');
    }

    // Property: onlinePlatformTotalFee
    set onlinePlatformTotalFee(value:number) {
        this.setValueForKey(value, 'onlinePlatformTotalFee');
    }
    get onlinePlatformTotalFee():number {
        return this.valueForKey('onlinePlatformTotalFee');
    }
    set onlinePlatformTotalFeePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'onlinePlatformTotalFee');
    }
    get onlinePlatformTotalFeePrimitiveValue():number {
        return this.primitiveValueForKey('onlinePlatformTotalFee');
    }

    // Property: orderID
    set orderID(value:string) {
        this.setValueForKey(value, 'orderID');
    }
    get orderID():string {
        return this.valueForKey('orderID');
    }
    set orderIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderID');
    }
    get orderIDPrimitiveValue():string {
        return this.primitiveValueForKey('orderID');
    }

    // Property: orderName
    set orderName(value:string) {
        this.setValueForKey(value, 'orderName');
    }
    get orderName():string {
        return this.valueForKey('orderName');
    }
    set orderNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'orderName');
    }
    get orderNamePrimitiveValue():string {
        return this.primitiveValueForKey('orderName');
    }

    // Property: orderSubtype
    set orderSubtype(value:number) {
        this.setValueForKey(value, 'orderSubtype');
    }
    get orderSubtype():number {
        return this.valueForKey('orderSubtype');
    }
    set orderSubtypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderSubtype');
    }
    get orderSubtypePrimitiveValue():number {
        return this.primitiveValueForKey('orderSubtype');
    }

    // Property: orderType
    set orderType(value:number) {
        this.setValueForKey(value, 'orderType');
    }
    get orderType():number {
        return this.valueForKey('orderType');
    }
    set orderTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderType');
    }
    get orderTypePrimitiveValue():number {
        return this.primitiveValueForKey('orderType');
    }

    // Property: roomName
    set roomName(value:string) {
        this.setValueForKey(value, 'roomName');
    }
    get roomName():string {
        return this.valueForKey('roomName');
    }
    set roomNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'roomName');
    }
    get roomNamePrimitiveValue():string {
        return this.primitiveValueForKey('roomName');
    }

    // Property: saleSourceID
    set saleSourceID(value:string) {
        this.setValueForKey(value, 'saleSourceID');
    }
    get saleSourceID():string {
        return this.valueForKey('saleSourceID');
    }
    set saleSourceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'saleSourceID');
    }
    get saleSourceIDPrimitiveValue():string {
        return this.primitiveValueForKey('saleSourceID');
    }

    // Property: saleSourceName
    set saleSourceName(value:string) {
        this.setValueForKey(value, 'saleSourceName');
    }
    get saleSourceName():string {
        return this.valueForKey('saleSourceName');
    }
    set saleSourceNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'saleSourceName');
    }
    get saleSourceNamePrimitiveValue():string {
        return this.primitiveValueForKey('saleSourceName');
    }

    // Property: sessionBeginDate
    set sessionBeginDate(value:Date) {
        this.setValueForKey(value, 'sessionBeginDate');
    }
    get sessionBeginDate():Date {
        return this.valueForKey('sessionBeginDate');
    }
    set sessionBeginDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'sessionBeginDate');
    }
    get sessionBeginDatePrimitiveValue():Date {
        return this.primitiveValueForKey('sessionBeginDate');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: taxesDict
    set taxesDict(value:any) {
        this.setValueForKey(value, 'taxesDict');
    }
    get taxesDict():any {
        return this.valueForKey('taxesDict');
    }
    set taxesDictPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'taxesDict');
    }
    get taxesDictPrimitiveValue():any {
        return this.primitiveValueForKey('taxesDict');
    }

    // Property: totalDeleted
    set totalDeleted(value:number) {
        this.setValueForKey(value, 'totalDeleted');
    }
    get totalDeleted():number {
        return this.valueForKey('totalDeleted');
    }
    set totalDeletedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalDeleted');
    }
    get totalDeletedPrimitiveValue():number {
        return this.primitiveValueForKey('totalDeleted');
    }

    // Property: totalDiscount
    set totalDiscount(value:number) {
        this.setValueForKey(value, 'totalDiscount');
    }
    get totalDiscount():number {
        return this.valueForKey('totalDiscount');
    }
    set totalDiscountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalDiscount');
    }
    get totalDiscountPrimitiveValue():number {
        return this.primitiveValueForKey('totalDiscount');
    }

    // Property: totalInvited
    set totalInvited(value:number) {
        this.setValueForKey(value, 'totalInvited');
    }
    get totalInvited():number {
        return this.valueForKey('totalInvited');
    }
    set totalInvitedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalInvited');
    }
    get totalInvitedPrimitiveValue():number {
        return this.primitiveValueForKey('totalInvited');
    }

    // Property: totalPrice
    set totalPrice(value:number) {
        this.setValueForKey(value, 'totalPrice');
    }
    get totalPrice():number {
        return this.valueForKey('totalPrice');
    }
    set totalPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalPrice');
    }
    get totalPricePrimitiveValue():number {
        return this.primitiveValueForKey('totalPrice');
    }

    // Property: totalPriceBase
    set totalPriceBase(value:number) {
        this.setValueForKey(value, 'totalPriceBase');
    }
    get totalPriceBase():number {
        return this.valueForKey('totalPriceBase');
    }
    set totalPriceBasePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalPriceBase');
    }
    get totalPriceBasePrimitiveValue():number {
        return this.primitiveValueForKey('totalPriceBase');
    }

    // Property: totalTaxes
    set totalTaxes(value:number) {
        this.setValueForKey(value, 'totalTaxes');
    }
    get totalTaxes():number {
        return this.valueForKey('totalTaxes');
    }
    set totalTaxesPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalTaxes');
    }
    get totalTaxesPrimitiveValue():number {
        return this.primitiveValueForKey('totalTaxes');
    }

    // Relationship: archivedTicketLinesGroups
    protected _archivedTicketLinesGroups:MIOManagedObjectSet = null;
    get archivedTicketLinesGroups():MIOManagedObjectSet {
        return this.valueForKey('archivedTicketLinesGroups');
    }
    addArchivedTicketLinesGroupsObject(value:ArchivedTicketLinesGroup) {
        this._addObjectForKey(value, 'archivedTicketLinesGroups');
    }
    removeArchivedTicketLinesGroupsObject(value:ArchivedTicketLinesGroup) {
        this._removeObjectForKey(value, 'archivedTicketLinesGroups');
    }
    // Relationship: cashDeskSession
    set cashDeskSession(value:CashDeskSession) {
        this.setValueForKey(value, 'cashDeskSession');
    }
    get cashDeskSession():CashDeskSession {
        return this.valueForKey('cashDeskSession') as CashDeskSession;
    }
    // Relationship: nextDocument
    set nextDocument(value:ArchivedDocument) {
        this.setValueForKey(value, 'nextDocument');
    }
    get nextDocument():ArchivedDocument {
        return this.valueForKey('nextDocument') as ArchivedDocument;
    }

    // Relationship: previousDocuments
    protected _previousDocuments:MIOManagedObjectSet = null;
    get previousDocuments():MIOManagedObjectSet {
        return this.valueForKey('previousDocuments');
    }
    addPreviousDocumentsObject(value:ArchivedDocument) {
        this._addObjectForKey(value, 'previousDocuments');
    }
    removePreviousDocumentsObject(value:ArchivedDocument) {
        this._removeObjectForKey(value, 'previousDocuments');
    }
}
