
class SetupCell extends UITableViewCell
{
    private titleLabel:MUILabel = null;

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
    }

    set title(value:string){
        this.titleLabel.text = MIOLocalizeString(value, value);
    }
}