//
// Generated class BookingRecommendation
//

/// <reference path="BookingRecommendation_ManagedObject.ts" />

class BookingRecommendation extends BookingRecommendation_ManagedObject
{

}
