//
// Generated class FavouriteProductModifier
//

/// <reference path="FavouriteProductModifier_ManagedObject.ts" />

class FavouriteProductModifier extends FavouriteProductModifier_ManagedObject
{

}
