/**
 * Created by crisan on 7/12/16.
 */

class WorkerCell extends UITableViewCell 
{
    nameLabel:MUILabel = null;
    emailLabel:MUILabel = null;

    awakeFromHTML() {
        this.nameLabel = MUIOutlet(this, "wlv_tv_workers_name_lbl", "MUILabel");
        this.emailLabel = MUIOutlet(this, "wlv_tv_workers_email_lbl", "MUILabel");        

        this.separatorStyle = UITableViewCellSeparatorStyle.None;
    }

    set item(i:Employee)
    {
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;        
        
        this.nameLabel.text = i.name + (i.placeID != null && i.placeID.toLowerCase() != ad.selectedIdentifier.toLowerCase() ? " 🔒" : "" );
        this.emailLabel.text = i.email;
    }
}