interface ReportFilterViewControllerDelegate
{
    applyFilters(filters:any[]) : void;
}

class ReportFilterViewController extends MUIViewController
{
    delegate:ReportFilterViewControllerDelegate = null

    static newInstance() {
        let vc = new ReportFilterViewController("report-filter-view");
        vc.initWithResource("layout/reports/ReportFilterView.html")
        return vc;
    }    

    private closeButton:MUIButton = null;
    private applyButton:MUIButton = null;
    private tableView:MUITableView = null;

    private selectedFilter = null;
    private df = MUIWebApplication.sharedInstance().delegate.dateFormatter;
    private sdf = MUIWebApplication.sharedInstance().delegate.serverDateFormatter;
    private sdtf = MUIWebApplication.sharedInstance().delegate.serverDateTimeFormatter;

    get preferredContentSize(): MIOSize {
        return new MIOSize(320, 500);
    }

    viewDidLoad(){
        super.viewDidLoad();

        this.closeButton = MUIOutlet(this, "close-btn", "MUIButton");
        this.closeButton.setAction(this, function(this:ReportFilterViewController){
            this.dismissViewController(true);
        });

        this.applyButton = MUIOutlet(this, "apply-btn", "MUIButton");
        this.applyButton.setAction(this, function(this:ReportFilterViewController){
            this.delegate.applyFilters(this.filters);
            this.dismissViewController(true);
        });

        this.tableView = MUIOutlet(this, "table-view", "UITableView");

        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private filters:any[] = null;
    set reportFilters(filters:any[]){
        this.filters = filters;
        this.updateUI();
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this.filters == null) return;

        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView) {
        return 1;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number) {
        return this.filters.length;
    }
    
    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath)
    {
        let filter = this.filters[indexPath.row];
        let cell:ReportFilterCell = null;

        switch(filter["Type"]) {
            case "calendar": cell = tableView.dequeueReusableCellWithIdentifier('CalendarFilterCell') as ReportFilterCell; break;
            case "date"    : cell = tableView.dequeueReusableCellWithIdentifier('DateTimeFilterCell') as ReportFilterCell; break;
            case "time"    : cell = tableView.dequeueReusableCellWithIdentifier('TimeFilterCell'    ) as ReportFilterCell; break;
            case "datetime": cell = tableView.dequeueReusableCellWithIdentifier('DateTimeFilterCell') as ReportFilterCell; break;
            case "enum"    : cell = tableView.dequeueReusableCellWithIdentifier('SelectFilterCell'  ) as ReportFilterCell; break;
            case "string"  : cell = tableView.dequeueReusableCellWithIdentifier('TextFilterCell'    ) as ReportFilterCell; break;
            case "boolean" : cell = tableView.dequeueReusableCellWithIdentifier('SwitchFilterCell'  ) as ReportFilterCell; break;
            case "entity"  : cell = tableView.dequeueReusableCellWithIdentifier('EntityFilterCell'  ) as ReportFilterCell; break;
        }

        cell.delegate = this;
        cell.filter = filter;
    
        cell.selectionStyle = UITableViewCellSelectionStyle.None;        

        return cell;
    }

    selectDateFromDateFilterCell(cell, control, filter){

        this.selectedFilter = filter;

        let dp = new MUIDatePickerController();
        dp.init();        
        dp.delegate = this;

        dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

        let pc = dp.popoverPresentationController;
        pc.sourceRect = control.frame;
        pc.sourceView = control;

        this.presentViewController(dp, true);
    }

    // Only happends with date filter cells
    didSelectDate(datePickerController, date)
    {
        if (this.selectedFilter == null) return;

        let index = this.filters.indexOf(this.selectedFilter);
        if (index < 0) return;

        let cell = this.tableView.cellAtIndexPath(MIOIndexPath.indexForRowInSection(index, 0));
        if (cell != null) {
            cell.date = date;
        }

        this.selectedFilter = null;
    }

    didChangeDateTimeValue(cell, date, filter) {
        
        this.sdtf.timeStyle = MIODateFormatterStyle.LongStyle;
        filter["value"] = this.sdtf.stringFromDate(date);

        // this.checkQuery();        
    }

    didChangeTimeValue(cell, date, filter) {
        
        filter["value"] = MIODateGetTimeString(date)+':00';

        // this.checkQuery();        
    }

    didSelectOptionFromSelectFilterCell(cell, value, filter){

        if (filter['type'] == "[string]")
            filter["value"] = [value];
        else 
            filter["value"] = value;
        // this.checkQuery();
    }

    didChangeSwitchCellValue(cell, value, filter){

        filter["value"] = value;
        // this.checkQuery();
    }

    // private checkQuery(){

    //     var query = {}
    //     var filters = {};

    //     query["reportid"] = this.report["id"];
    //     query["filters"] = filters;

    //     for (var index = 0; index < this.filters.length; index++){

    //         var f = this.filters[index];
            
    //         let key = f["title"];
    //         let value = f["value"];
    //         if (f["required"] == true && value == null && f["type"] == "boolean") 
    //             value = false;
                        
    //         if (f["required"] == true && value == null) return;
    //         if (value == null) continue;        

    //         filters[key] = value;
    //     }

    //     this.query = query;
    //     this.filterButton.setEnabled(true);
    // }

    // didSelectCellAtIndexPath(tableView, row, section)
    // {
        // var category = this.reports[section];
        // var reports = category["Reports"];

        // // var vc = new ReportResultsViewController("report_results_view");
        // // vc.initWithResource("layout/reports/ReportResultsView.html");      
        // // vc.report =  reports[row]; 
        // // this._report = reports[row];
        // // this.splitViewController.showDetailViewController(vc);

        // var vc = new ReportFilterViewController("report_filter_view");
        // vc.initWithResource("layout/reports/ReportFilterView.html");
        // vc.report =  reports[row]; 
        // this.splitViewController.showDetailViewController(vc);        
 //   }

}