
class ConsumptionProfileDetailCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;  
    private dropdownButton:MUIButton = null;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.dropdownButton = this.setupDropdownButton("dropdown-btn");       
    }

    private _item:any = null;
    set item (item:any){
        if (item == null) return;

        this._item = item;

        if (item instanceof LocationCategory){
            //For LocationCategory entity
            this.nameLabel.text = this._item.name;
            this.dropdownButton.title = this._item.consumptionProfile.name;

        } else if (item instanceof AppArea) {
            //For AppArea entity
            let id = this._item.areaID; //should this be a relationship???
            this.nameLabel.text = AppAreaID[id];
            this.dropdownButton.title = this._item.consumptionProfileID;
            
        } else if (item instanceof EllaborationCenter) {
            //For EllaborationCenter entity
            this.nameLabel.text = this._item.name;
        }
    }

    private setupDropdownButton(outlet:string): MUIButton {
        let button = MUIOutlet(this, outlet, "MUIButton");
        if (button == null || button == undefined) return null;

        button.setAction(this, function() {
            this.showSelectEntityViewController();
        }); 
        
        return button;
    }

    private showSelectEntityViewController() {

        if (this._item instanceof LocationCategory || this._item instanceof AppArea){

            let predicateFormat = null;
            let vc = AppHelper.sharedInstance().showCustomSelectViewControllerFromView(this.dropdownButton, "ConsumptionProfile", "name", predicateFormat, this, function(controller, object:ConsumptionProfile){
                let value = object;            
                this.dropdownButton.title = value.name;

                if(this._item instanceof LocationCategory) this._item.consumptionProfile = value;
                if(this._item instanceof AppArea) this._item.consumptionProfileID = value.identifier;
            });        
        }
    }
}