//
// Generated class WarehouseInputNote
//

/// <reference path="WarehouseInputNote_ManagedObject.ts" />

class WarehouseInputNote extends WarehouseInputNote_ManagedObject
{

}
