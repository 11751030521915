//
// Generated class ReportScriptFilter
//

/// <reference path="ReportScriptFilter_ManagedObject.ts" />

class ReportScriptFilter extends ReportScriptFilter_ManagedObject
{

}
