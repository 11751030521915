

/// <reference path="PaymentEntity.ts" />

// Generated class ProxyPaymentEntity_ManagedObject

class ProxyPaymentEntity_ManagedObject extends PaymentEntity
{

    // Property: sourcePaymentEntityID
    set sourcePaymentEntityID(value:string) {
        this.setValueForKey(value, 'sourcePaymentEntityID');
    }
    get sourcePaymentEntityID():string {
        return this.valueForKey('sourcePaymentEntityID');
    }
    set sourcePaymentEntityIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourcePaymentEntityID');
    }
    get sourcePaymentEntityIDPrimitiveValue():string {
        return this.primitiveValueForKey('sourcePaymentEntityID');
    }

    // Property: sourcePlaceID
    set sourcePlaceID(value:string) {
        this.setValueForKey(value, 'sourcePlaceID');
    }
    get sourcePlaceID():string {
        return this.valueForKey('sourcePlaceID');
    }
    set sourcePlaceIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourcePlaceID');
    }
    get sourcePlaceIDPrimitiveValue():string {
        return this.primitiveValueForKey('sourcePlaceID');
    }

    // Property: sourceSchemaID
    set sourceSchemaID(value:string) {
        this.setValueForKey(value, 'sourceSchemaID');
    }
    get sourceSchemaID():string {
        return this.valueForKey('sourceSchemaID');
    }
    set sourceSchemaIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sourceSchemaID');
    }
    get sourceSchemaIDPrimitiveValue():string {
        return this.primitiveValueForKey('sourceSchemaID');
    }
}
