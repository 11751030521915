
class BookingDetailHeaderView extends MUIView
{
    delegate = null;    
    
    private clientNameLabel:MUILabel = null; 
    private clientPhoneLabel:MUILabel = null;
    private clientEmailLabel:MUILabel = null;
    private clientLanguageLabel:MUILabel = null;

    private titleTextField:MUITextField = null; 
    private externalRefTextField:MUITextField = null;
    private phoneTextField:MUITextField = null;
    private emailTextField:MUITextField = null;
    private languageDropdown:MUIButton = null;

    private sourceComboBox:MUIComboBox = null;
    private sourceEmailLabel:MUILabel = null;
    private sourcePhoneLabel:MUILabel = null;
        
    private ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
    private df:MIODateFormatter = this.ad.dateFormatter;
    private dtf:MIODateFormatter = this.ad.dateTimeFormatter;
    private sdf:MIODateFormatter = this.ad.serverDateFormatter;
    private nf:MIONumberFormatter = this.ad.numberFormatter;
    private if:MIONumberFormatter = this.ad.integerFormatter;
    private tf:MIODateFormatter = this.ad.timeFormatter;
    
    awakeFromHTML() {
        
        this.clientNameLabel = MUIOutlet(this, 'client-name-lbl','MUILabel');
        this.clientPhoneLabel = MUIOutlet(this, "client-phone-lbl", "MUILabel");
        this.clientEmailLabel = MUIOutlet(this, "client-email-lbl", "MUILabel");
        this.clientLanguageLabel = MUIOutlet(this, "client-language-lbl", "MUILabel");

        this.titleTextField = MUIOutlet(this, 'title-tf','MUITextField');
        this.titleTextField.setOnChangeText(this, function(this:BookingDetailHeaderView, control, value){
            this.item.bookingName = value;
            this.updateBookings();
        });

        this.externalRefTextField = MUIOutlet(this, "reference-tf", "MUITextField");
        this.externalRefTextField.setOnChangeText(this, function(control, value){
            this.item.externalReference = value;
            this.updateBookings();
        });
        
        this.emailTextField = MUIOutlet(this, "email-tf", "MUITextField");
        this.emailTextField.setOnChangeText(this, function(this:BookingDetailHeaderView, control, value){
            this.item.bookingEmail = value;
            this.updateBookings();
        });    
        
        this.phoneTextField = MUIOutlet(this, "phone-tf", "MUITextField");
        this.phoneTextField.setOnChangeText(this, function(this:BookingDetailHeaderView, contol, value){
            this.item.bookingPhone = value;
            this.updateBookings();
        });

        this.languageDropdown = MUIOutlet(this, "languages-btn", "MUIButton");
        this.languageDropdown.setAction(this, function(){
            AppHelper.sharedInstance().showSelectLanguageViewControllerFromView(this.languageDropdown, null, true, true, this, this.languagesDidChange);
        });

        // this.countryDropdown = MUIOutlet(this, "languages-btn", "MUIButton");
        // this.countryDropdown.setAction(this, function(){
        //     AppHelper.sharedInstance().showSelectCountryViewControllerFromView(this.countryDropdown, null, this, function(controller:SelectEntityViewController, country:Country){
        //         this.countryDropdown.title = country.name;
        //     });
        // });
        
        
        this.sourceComboBox = MUIOutlet(this, "source-cb",'MUIComboBox');
        this.setupSourceComboBox(this.sourceComboBox);
        
        this.sourceEmailLabel = MUIOutlet(this, "source-email-lbl", "MUILabel");
        this.sourcePhoneLabel = MUIOutlet(this, "source-phone-lbl", "MUILabel");

        // this.channelComboBox = MUIOutlet(this, 'channel-cb','MUIComboBox');
        // this.setupChannelComboBox(this.channelComboBox);
        // this.channelComboBox.setOnChangeAction(this, function(control, value){
        //     let channel = DBHelper.queryObjectFromMainContext("BookingChannel", MIOPredicate.predicateWithFormat("identifier == " + value));
        //     this.item.channelID = channel.identifier;
        //     this.item.channelName = channel.name;
        //     this.updateBookings();
        // });
        
        // this.recommendationComboBox = MUIOutlet(this, 'recommendation-cb','MUIComboBox'); 
        // this.setupRecommendationComboBox(this.recommendationComboBox);
        // this.recommendationComboBox.setOnChangeAction(this, function(this:BookingDetailHeaderView, control, value){
        //     let recomendation = DBHelper.queryObjectFromMainContext("BookingRecommendation", MIOPredicate.predicateWithFormat("identifier == " + value));
        //     this.item.recommendation = recomendation;
        //     this.item.recommendationName = recomendation?.name;
        //     this.updateBookings();
        // });


        // this.walkInCheckBox = MUIOutlet(this, "walk-in-cb", "MUICheckButton");
        // this.walkInCheckBox.setOnChangeValue(this, function(control, value){
        //     this._item.isWalkin = value;
        // });

        /*this.statusComboBox = MUIOutlet(this, 'status-cb','MUIComboBox');
        this.statusComboBox.setOnChangeAction(this, function(comboBox, value){
            this._item.status = parseInt(value);
        });        

     */       
    
    }

    private item:Booking = null;
    private items:[Booking] = null;
    set bookings(items:[Booking]){
        this.items = items;
        this.item = items[0];

        this.clientNameLabel.text = this.item.client?.name;
        this.clientPhoneLabel.text = this.item.client?.mobilePhone?.number ?? this.item.client?.phone?.number;
        this.clientEmailLabel.text = this.item.client?.email;
        this.clientLanguageLabel.text = this.item.language;

        this.titleTextField.text = this.item.bookingName ?? this.item.client?.name;
        this.externalRefTextField.text = this.item.externalReference;
        this.phoneTextField.text = this.item.bookingPhone;
        this.emailTextField.text = this.item.bookingEmail;
        
        this.sourceComboBox.selectItem("-1");
        this.sourceEmailLabel.text = null;
        this.sourcePhoneLabel.text = null;

        let sds = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        let predicate = MIOPredicate.predicateWithFormat("deletedAt == null");
        DBHelper.queryObjectsWithCompletion("BookingSource", sds, predicate, [], this, function(this:BookingDetailHeaderView, objects:BookingSource[]){
            this.fillBookingSourceComboBox(this.sourceComboBox, objects, this.item.source);
            this.sourceEmailLabel.text = this.item.source?.email;            
            this.sourcePhoneLabel.text = this.item.source?.phone?.number;
        });  

        // sds = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        // predicate = MIOPredicate.predicateWithFormat("deletedAt == null");
        // DBHelper.queryObjectsWithCompletion("BookingChannel", sds, predicate, [], this, function(this:BookingDetailHeaderView, objects){
        //     let c = objects.filter (x => x.identifier == this.item.channelID );
        //     this.fillBookingChannelComboBox(this.channelComboBox, objects, c.length > 0 ? c[0] : null);            
        // });  

        // if (this.item.channelID != null) this.channelComboBox.selectItem(this.item.channelID);

        // sds = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        // predicate = MIOPredicate.predicateWithFormat("deletedAt == null");
        // DBHelper.queryObjectsWithCompletion("BookingRecommendation", sds, predicate, [], this, function(this:BookingDetailHeaderView, objects){
        //     this.fillBookingRecommendationComboBox(this.recommendationComboBox, objects, this.item.recommendation);
        // });  

        // if (this.item.recommendation != null ) this.recommendationComboBox.selectItem(this.item.recommendation.identifier);

        
        
        //need to convert spoken languages to string of iso2 "en, en, ar"
        this.convertLanguages(this.item.spokenLanguages);

        // this.initStatusComboBox();        

        // //this.walkInCheckBox.setOn(item.isWalkin);

        // let date = this.sdf.dateFromString(item.day + " 00:00:00");
        // this.dateTextField.text = this.df.stringFromDate(date);        
        // let index = this.durationInterval.indexOfObject(item.duration);
        // if (index < 0) this.durationComboBox.selectItem(7);
        // else this.durationComboBox.selectItem(index);

        // this.paxTextFied.text = this.if.stringFromNumber(item.pax);
        // this.invitedPaxTextField.text = this.if.stringFromNumber(item.paxInvited);                
    }


    private fillLanguageComboBox(comboBox:MUIComboBox, selectedItem){
        comboBox.removeAllItems();
        comboBox.addItem(MIOLocalizeString('SELECT','SELECT'), -1);

        let langs = SettingsHelper.sharedInstance().templateLanguages;
        for (let index = 0; index < langs.length; index++){
            let l = langs[index];
            comboBox.addItem(l.name, l.identifier);
        }        
               
       if (selectedItem != null) comboBox.selectItem(selectedItem);
    }


    addNewTimeRange(){

        let avc = new MUIAlertViewController();
        avc.initWithTitle('Create new time interval', 'Hour interval ', MUIAlertViewStyle.Default);
        
        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
            textField.setPlaceholderText("Hour");
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle('OK', MUIAlertActionStyle.Default, this, function(){
            let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;
            let timeRange = MIOEntityDescription.insertNewObjectForEntityForName("BookingDayTimeRange", ad.managedObjectContext) as BookingDayTimeRange;
            timeRange.name = avc.textFields[0].text;
            timeRange.time = avc.textFields[0].text;

            ad.managedObjectContext.save();
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle('Cancel', MUIAlertActionStyle.Cancel, null, null));                

        this.delegate.presentViewController(avc, true);
    }

    private setupSourceComboBox(comboBox:MUIComboBox){
        let selectedItem = this.item == null ? null : this.item.source;
                
        comboBox.setOnChangeAction(this, function(this:BookingDetailHeaderView, control:MUIComboBox, value:any){            
            if (value == "-1") {
                this.sourceEmailLabel.text = null;                
                this.sourcePhoneLabel.text = null;                
                this.item.source = null;            
                this.updateBookings();
            }
            else if (value == "-2"){
                AppHelper.sharedInstance().showAddBookingSourceAlert(this, function(this:BookingDetailHeaderView, source:BookingSource){   
                    let sds = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
                    let predicateFormat = MIOPredicate.predicateWithFormat("deletedAt == null");
                    let sources = DBHelper.queryObjectsFromMainContext("BookingSource", sds, predicateFormat);  
                    this.fillBookingSourceComboBox(comboBox, sources, source);        
                    this.sourceEmailLabel.text = source.email;
                    this.sourcePhoneLabel.text = source.phone?.number;
                    this.item.source = source;
                    this.updateBookings();
                });
            }
            else {
                let source = DBHelper.queryObjectFromMainContext("BookingSource", MIOPredicate.predicateWithFormat("identifier == " + value));  
                this.sourceEmailLabel.text = source.email;                
                this.sourcePhoneLabel.text = source.phone?.number;
                this.item.source = source;  
                //add source ID and name to booking object
                this.item.source = source;
                this.item.sourceName = source.name;
                this.updateBookings();
            }
        });
    }

    private fillBookingSourceComboBox(comboBox, objects, selectedSource){
        comboBox.removeAllItems();
        comboBox.addItem(MIOLocalizeString('NO SOURCE','NO SOURCE'), -1);    
        comboBox.addItem(MIOLocalizeString('NEW SOURCE','NEW SOURCE'), -2);

        for (let i = 0; i < objects.length; i++) {
            let object = objects[i] as BookingSource;
            comboBox.addItem(object.name, object.identifier);
        }

        if (selectedSource != null) comboBox.selectItem(selectedSource.identifier);
        else comboBox.selectItem("-1");
    }

    private setupChannelComboBox(comboBox:MUIComboBox){
        let selectedItem = this.item == null ? null : this.item.channelID;
        DBHelper.sharedInstance().addObserverForEntity(this, "BookingChannel", null, null, null, function(objects){
            this.fillBookingChannelComboBox(this.channelComboBox, objects, selectedItem);
        });
    }

    private fillBookingChannelComboBox(comboBox, objects, selectedChannel){
        comboBox.removeAllItems();
        comboBox.addItem(MIOLocalizeString('NO CHANNEL','NO CHANNEL'), -1);  

        for (let i = 0; i < objects.length; i++) {
            let object = objects[i] as BookingChannel;
            comboBox.addItem(object.name, object.identifier);
        }

        if (selectedChannel != null) comboBox.selectItem(selectedChannel.identifier);
        else comboBox.selectItem("-1");
    }

    private convertLanguages(lang:string){

        if(lang != null || lang != undefined){

            //make string into object
            let langObj = JSON.parse(lang);

            let sds = [MIOSortDescriptor.sortDescriptorWithKey("identifier", true)];
            let predicate = MIOPredicate.predicateWithFormat("deletedAt == null");
            DBHelper.queryObjectsWithCompletion("Language", sds, predicate, [], this, function(objects){
                
                let langString:string = "";
                for(let index = 0; index < langObj.length; index++){

                    //add spacing
                    if(index > 0){
                        langString += ", ";
                    }
                    
                    //loops once through objects and identifies one numberID and gives you the ISO2 code
                    for(let i = 0; i < objects.length; i++) {
                        if(objects[i].identifier == langObj[index]){
                            langString += objects[i].iso2;
                        }
                    }
                }
                
                this.languageDropdown.title = langString;
            });
        } else {
            this.languageDropdown.title = MIOLocalizeString('SELECT LANGUAGE','SELECT LANGUAGE');
        }

    }

    private fillLanguageDropdown(button:MUIButton, objects, selectedLanguages){

        //alert("Filllanguage" + this.item.spokenLanguages);
        

        for (let i = 0; i < objects.length; i++) {
            let object = objects[i] as Language;
            console.log(object);
            button.title = object.name;
        }

        // if (selectedChannel != null) comboBox.selectItem(selectedChannel.identifier);
        // else comboBox.selectItem("-1");
    }

    private languagesDidChange(controller:SelectEntityViewController, language:Language){

        //returns Array of Language (language)
        //Keys of intrest: name, identifier, iso2

        if(language[0] == undefined || language == undefined || language == null){

            this.languageDropdown.title = MIOLocalizeString('SELECT LANGUAGE','Select language');
            this.item.spokenLanguages = null;

        }else{

            let langString:string = ""; //string to add to dropdown title
            let langArray:string = ""; //array as string to add to database
            let lang:any = language;
            let count = lang.length;

            for(let i = 0; i < count; i++) {
                let item:any = lang[i];
                if(i > 0){
                    langString += ", ";
                    langArray += ", ";
                }
                    langString += item.iso2;
                    langArray += item.identifier;
            }

            langArray = "["+langArray+"]"; //add brackets for DB insert

            this.languageDropdown.title = langString;
            this.item.spokenLanguages = langArray;
        }
        
        this.updateBookings();
    }

    private setupRecommendationComboBox(comboBox:MUIComboBox){
        let selectedItem = this.item == null ? null : this.item.recommendation?.identifier;
        DBHelper.sharedInstance().addObserverForEntity(this, "BookingRecommendation", null, [MIOSortDescriptor.sortDescriptorWithKey("name", true)], null, function(objects){
            this.fillBookingRecommendationComboBox(this.recommendationComboBox, objects, selectedItem);
        });
    }

    private fillBookingRecommendationComboBox(comboBox, objects, selectedRecommendation){
        comboBox.removeAllItems();
        comboBox.addItem(MIOLocalizeString('NO RECOMMENDATION','NO RECOMMENDATION'), -1);  

        for (let i = 0; i < objects.length; i++) {
            let object = objects[i] as BookingRecommendation;
            comboBox.addItem(object.name, object.identifier);
        }

        if (selectedRecommendation != null) comboBox.selectItem(selectedRecommendation.identifier);
        else comboBox.selectItem("-1");
    }

    private updateBookings(){ 
        for (let index = 1; index < this.items.length; index++){
            //what does this line do?
            let booking = this.items[index] as Booking;
            booking.externalReference = this.item.externalReference;
            booking.source = this.item.source;
            booking.channelID = this.item.channelID;
            booking.channelName = this.item.channelName;
            booking.prescriptor = this.item.prescriptor;
            booking.prescriptorName = this.item.prescriptorName;
            booking.language = this.item.language;
            booking.spokenLanguages = this.item.spokenLanguages;
        }       
    }

}
