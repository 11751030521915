/// <reference path="../archived_document/SelectDocumentViewController.ts" />

class WorkerLoginViewController extends MUIViewController {

    /*====== VARIABLES ======*/
    private calendarButton:MUIButton = null;
    private calendarTextfield:MUITextField = null;
    private tableView:MUITableView = null;
	private timeRangeLabel:ColumnFilterTextField = null;
    private loginColumnTextField:ColumnFilterTextField = null;
    private timeColumnTextField:ColumnFilterTextField = null;

	private filterController:ColumnFilterController = null;

    private sdt = MUIWebApplication.sharedInstance().delegate.serverDateTimeFormatter;
    
    /*====== VIEW DID LOAD ======*/
    viewDidLoad(){
		super.viewDidLoad();

		this.filterController = new ColumnFilterController();
        this.filterController.initWithDelegate(this);             

        this.calendarButton = MUIOutlet(this, "calendar-btn", "MUIButton");
        this.calendarButton.setAction(this, function(){
            AppHelper.sharedInstance().presentDateSelectionViewController(this);
        });

        this.calendarTextfield = MUIOutlet(this, "calendar-tf", "MUITextField");

        this.loginColumnTextField = MUIOutlet(this, "login-filter-tf", "ColumnFilterTextField");
        this.loginColumnTextField.filterController = this.filterController;
        this.loginColumnTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "concept", null, null);
        
        this.timeColumnTextField = MUIOutlet(this, "time-filter-tf", "ColumnFilterTextField");
        this.timeColumnTextField.filterController = this.filterController;
		this.timeColumnTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "date", null, null);

        this.tableView = MUIOutlet(this, "table-view", "MUITableView");
        this.tableView.dataSource = this;
		this.tableView.delegate = this; 		
    }

    /*====== VIEW WILL APPEAR ======*/
    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    /*====== UPDATE CLASSES ======*/
    private updateUI(){
        if (this.viewIsLoaded == false || this._worker == null) return;               
        this.fetchedResultsController = null;
        this.tableView.reloadData();                
        //this.updateDateRange();
    }

    /*====== SET WORKER ======*/
    private _worker:Employee = null;
    set worker(worker:Employee){
        this._worker = worker;
        this.updateUI();
    }

    /*====== TABLEVIEW ======*/
    numberOfSections(tableView:MUITableView){
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:MUITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("WorkerLoginCell") as WorkerLoginCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as LogOperation;
        cell.item = item;

        return cell;
    }

    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath){}

    /*====== FetchedResultsController ======*/
    protected _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value:MIOFetchedResultsController){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
        this._fetchedResultsController = value;
    }

    get fetchedResultsController() : MIOFetchedResultsController {
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("LogOperation");
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("date", false)];                

        let predicateFormat = "employeeName == '" + this._worker.name + "'";
        predicateFormat += " AND type = 3 AND subType = 18";

        if (this.dateTimeFrom != null && this.dateTimeTo != null) {
            predicateFormat += " AND (date >= '" + this.dateTimeFrom + "' AND date <= '" + this.dateTimeTo + "')";
		}
		let filterPredicateFormat = this.filterController.filterPredicateFormat();
        if (filterPredicateFormat != null){
            predicateFormat += " AND (" + filterPredicateFormat + ")";
        }

        fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateFormat);
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    private dateTimeFrom:string = null;
    private dateTimeTo:string = null;    
    datesDidSelect(dateTimeFrom, dateTimeTo) {

        this.dateTimeFrom = MIODateGetDateString(dateTimeFrom);
        this.dateTimeTo = MIODateGetDateString(dateTimeTo);

        if (this.dateTimeFrom == this.dateTimeTo){
            this.calendarTextfield.text = this.dateTimeFrom;
        } else {
            this.calendarTextfield.text = this.dateTimeFrom + "  ->  " + this.dateTimeTo;
        }

        this.dateTimeFrom += " 00:00:00";
        this.dateTimeTo += " 23:59:59";
        this.updateUI()
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController) {
        this.tableView.reloadData();
    }
	
	filterPredicateDidChange(controller:ColumnFilterController){
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    

}