
class PartyCell extends UITableViewCell
{
    private titleLabel:MUILabel = null;

    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
    }

    set item(item:Party){
        this.titleLabel.text = item.name;
    }
}