
/// <reference path="../notes/NoteListViewController.ts" />


class DeliveryOrderListViewController extends NoteListViewController
{
    static newInstance() : DeliveryOrderListViewController {
        let vc = new DeliveryOrderListViewController("delivery-order-list-view");
        vc.initWithResource("layout/deliveryorder/DeliveryOrderListView.html");
        return vc;
    }

    protected get sectionControllerID():string {
        return SectionViewControllerType.DeliveryOrder;
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("DeliveryOrderCell") as DeliveryOrderCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as StockNote;

        cell.item = item;     
        cell.selected = this.selectedNote == item ? true : false;   

        return cell;
    }

    set fetchedResultsController(value){
        this.setFetchedResultsController(value);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("documentID", false)];
        if (this.selectedStatus == StockNoteStatus.Processed) {
            sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("stockDate", false)];
        } 

        let predicateFormat = "status == " + this.selectedStatus;
        if (ad.selectedIdentifierType == "place") predicateFormat += " AND placeID == '" + ad.selectedIdentifier + "'";
        if(this.searchString != null) {
            predicateFormat += " AND documentID CONTAINS '" + this.searchString + "'";
		}
		
		if (this.filterPredicateFormat != null) {
            predicateFormat += " AND " + this.filterPredicateFormat;
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("DeliveryOrder", sortDescriptors, predicateFormat);
        fetchRequest.fetchLimit = 50;
        fetchRequest.relationshipKeyPathsForPrefetching = ["destinationEntity"];

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller){
        this.tableView.reloadData(); 
	}
	
	private _filters = null;
    get filters(){
        if (this._filters != null) return this._filters;

		this._filters = [FilterOption.filterWithTitle("FromDate", "From day", "stockDate", FilterFieldType.Date, null, FilterControllerComparatorType.MajorOrEqualComparator, null, "00:00:00"),
		FilterOption.filterWithTitle("ToDate", "To day", "stockDate", FilterFieldType.Date, null, FilterControllerComparatorType.MinorOrEqualComparator, null, "23:59:59"),
		FilterOption.filterWithFetchRequest("DestinationEntity", "Client", "destinationEntity.identifier", FilterFieldType.DropDown, "Client", "name", "identifier", MIOLocalizeString("SELECT CLIENT","Select Client"))];

        return this._filters;
    }
        
    protected showSelectedItem(){                
        let vc = DeliveryOrderDetailViewController.newInstance();
        vc.note = this.selectedNote;
        this.splitViewController.showDetailViewController(vc);
    }

    protected showNoItemSelected(){        
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("DeliveryNote", NoItemSelectedViewControllerType.DeliveryOrder);
        this.splitViewController.showDetailViewController(vc);        
    }
    
    protected showAddNoteViewController(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("SELECT TYPE","SELECT TYPE"), MIOLocalizeString("SELECT DESTINATION TYPE","SELECT DESTINATION TYPE"), MUIAlertViewStyle.Default);
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CLIENT","CLIENT"), MUIAlertActionStyle.Default, this, function(){
            this.showAddClientViewController();
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("SUPPLIER","SUPPLIER"), MUIAlertActionStyle.Default, this, function(){
            this.showAddSupplierViewController();
        }));
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString("CANCEL","CANCEL"), MUIAlertActionStyle.Cancel, null, null));

        this.presentViewController(avc, true);
    }

    private showAddClientViewController(){
        let vc = AppHelper.sharedInstance().selectViewController("Client", "name", false, false, null, this, false) as SelectEntityViewController;
        vc.allowSearch = true;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this.presentViewController(vc, true);
    }

    private showAddSupplierViewController(){
        let vc = AppHelper.sharedInstance().selectViewController("Supplier", "name", false, false, null, this, false) as SelectEntityViewController;
        vc.allowSearch = true;
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this.presentViewController(vc, true);
    }

    //
    // Select entity view controller
    //

    fetchRequestForController(controller:SelectEntityViewController):MIOFetchRequest{

        if (controller.identifier == "Client"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Client", sd, null);
            fetchRequest.fetchLimit = 50;            

            return fetchRequest;
        }
        else if (controller.identifier == "Supplier"){
            let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

            let fetchRequest = DBHelper.listFetchRequestWithEntityName("Supplier", sd, null);
            fetchRequest.fetchLimit = 50;            

            return fetchRequest;
        }


        return null;
    }

    didSelectObjectFromSelectViewController(controller:SelectEntityViewController, item:MIOManagedObject){        
        
        let dismiss = true;

        if (controller.identifier == "Client") {
            let client = item as Client;
            DBHelper.createDeliveryNote(client, (note:StockNote) => {
                this.selectedNote = note;
                DBHelper.saveMainContext();
                this.showSelectedItem();    
            });
        }
        else if (controller.identifier == "Supplier") {
            let supplier = item as Supplier;
            DBHelper.createDeliveryNote(supplier, (note:StockNote) => {
                this.selectedNote = note;
                DBHelper.saveMainContext();
                this.showSelectedItem();
            });
        }

        return dismiss;
    }
}