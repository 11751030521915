
class ProductFormatRatesViewController extends BaseViewController
{
    private closeButton:MUIButton = null;
    private tableView: UITableView = null;

    delegate = null;

    public preferredContentSize = new MIOSize(500, 500);

    viewDidLoad(){
        super.viewDidLoad();

        this.closeButton = MUIOutlet(this, 'close-btn', 'MUIButton')             
        this.closeButton.setAction(this, function(){
            this.dismissViewController(true);
        });

        this.tableView = MUIOutlet(this, 'table-view', 'UITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;  
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);
        this.updateUI();
    }

    // private _productFormats:ProductFormat[] = null;
    // set item(item:ProductFormat[]){
    //     this._productFormats = item;

    //     this.updateUI();
    // }   

    private _product:Product = null;
    set item(item:Product){
        this._product = item;

        this.updateUI();
    }  

    private _rate:Rate = null;
    set rate(rate:Rate){
        this._rate = rate;

        this.updateUI();
    }

    
    private productFormatRateArray: ProductFormatRate[] = null;

    private updateUI(){
        if (this.viewIsLoaded == false || this._product == null || this._rate == null) return;

        let predicate = MIOPredicate.predicateWithFormat('rate.identifier = ' + this._rate.identifier);
        DBHelper.queryObjectsWithCompletion("ProductFormatRate", null, predicate, ["productFormat"], this, function(objects){
            
            this.productFormatRateArray = objects; //might be zero


            //set variables from item here
            this.fetchedResultsController = null;
            this.tableView.reloadData(); 
        });

        
    }

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableview:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let cell = tableView.dequeueReusableCellWithIdentifier("ProductFormatRatesCell") as ProductFormatRatesCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ProductFormat;
        cell.item = item;
        cell.rate = this._rate;
        cell.selectionStyle = UITableViewCellSelectionStyle.None;

        this.productFormatRateArray.forEach(function(obj){
            if (obj.productFormat.identifier == item.identifier) {
                cell.productFormatRate = obj;
            }
        });

        // for(let i=0; i< this._productFormats.length; i++) {

        //     //Error // why does this not work???
        //     let pf = this._productFormats[i];
        //     if(pf.identifier == item.identifier)
        //         cell.productFormat = pf;
        // } 

        return cell;
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName("ProductFormat");
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('name', true)];
        fetchRequest.predicate = MIOPredicate.predicateWithFormat("product.identifier == " + this._product.identifier + " AND deletedAt == null");
        fetchRequest.relationshipKeyPathsForPrefetching = ["product", "format", "productFormatRates"];
              
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller){
        this.tableView.reloadData();
    }


}