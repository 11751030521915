

class BookingIntermediaryListCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
    }

    set item(i:BookingSource){
        this.nameLabel.text = i.name;
    }

}