//
// Generated class AdyenDevicePaymentTransaction
//

/// <reference path="AdyenDevicePaymentTransaction_ManagedObject.ts" />

class AdyenDevicePaymentTransaction extends AdyenDevicePaymentTransaction_ManagedObject
{

}
