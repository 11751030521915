
class CashDeskSessionLineTitleCell extends MUITableViewCell
{
    private titleLabel:MUILabel = null;
    
    awakeFromHTML(){
        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");
    }

    set title(value:string){
        this.titleLabel.text = value;
    }
}