//
// Generated class EmployeePayroll
//

/// <reference path="EmployeePayroll_ManagedObject.ts" />

class EmployeePayroll extends EmployeePayroll_ManagedObject
{

}
