//
// Generated class Partner
//

/// <reference path="Partner_ManagedObject.ts" />

class Partner extends Partner_ManagedObject
{

}
