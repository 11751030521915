/**
 * Created by miguelsl on 30/12/16.
 */


class ProductDetailInformationViewController extends MUIViewController
{
    private shortdescriptionTextField:MUITextField = null;
    private languageButton:MUIButton = null;

    private detaildescriptionTextArea:MUITextArea = null;
    private componentsdescriptionTextArea:MUITextArea = null;
    private tagView:TagView = null;
    private ledgerAccountDropdown:MUIButton = null;
    private accountNumberTextField:MUITextField = null;

    private salesAccountDropdown:MUIButton = null;
    private purchaseAccountDropdown:MUIButton = null;
    
    private extraimagesCollectionView:MUICollectionView = null;            

    viewDidLoad(){
        super.viewDidLoad();

        this.shortdescriptionTextField = MUIOutlet(this, 'pinv_b1_short_description_textfield', 'MUITextField');
        this.shortdescriptionTextField.setOnChangeText(this, function (textfield, value){
            this._product.info = value;
            MIONotificationCenter.defaultCenter().postNotification("ProductChangeValue", value);
        });

        this.languageButton = MUIOutlet(this, "language-btn", "MUIButton");
        this.languageButton.setAction(this, function(){
            let vc = ProductLanguageViewController.newInstance();
            vc.modalSize = new MIOSize(800, 400);
            vc.setItem(this._product, "info");
            
            this.presentViewController( vc, true );
        });


        this.detaildescriptionTextArea = MUIOutlet(this, 'pinv_b1_detail_description_textarea', 'MUITextArea');
        this.detaildescriptionTextArea.setOnChangeText(this, function (textarea, value){
            this._product.detailedInfo = value;
            MIONotificationCenter.defaultCenter().postNotification("ProductChangeValue", value);
        });

        this.componentsdescriptionTextArea = MUIOutlet(this, 'pinv_b1_components_description_textarea', 'MUITextArea');
        this.componentsdescriptionTextArea.setOnChangeText(this, function (textarea, value){
            this._product.componentsInfo = value;
            MIONotificationCenter.defaultCenter().postNotification("ProductChangeValue", value);
        })

       this.tagView = MUIOutlet(this, 'tag-view', 'TagView');
       this.tagView.delegate = this;

        this.ledgerAccountDropdown = MUIOutlet(this, "ledger-account-dd", "MUIButton");
        this.ledgerAccountDropdown.setAction(this, function(){
            AppHelper.sharedInstance().showSelectLedgerAccountViewControllerFromView(this.ledgerAccountDropdown, this._product.ledgerAccount, this, this.updateLedgerAccount);
        });

        this.accountNumberTextField = MUIOutlet(this, "account-number-tf", "MUITextField");
        this.accountNumberTextField.setOnChangeText(this, function(this:ProductDetailInformationViewController, control:MUITextField, value:string){
            this._product.accountNumber = value.length > 0 ? value : null;
        });

        this.salesAccountDropdown = MUIOutlet(this, "sales-ledger-account-dd", "MUIButton");
        this.salesAccountDropdown.setAction(this, function(this:ProductDetailInformationViewController){            
            AppHelper.sharedInstance().showSelectLedgerAccountViewControllerFromView(this.salesAccountDropdown, this._product.salesLedgerAccount, null, false, this._product.name, this, this.updateSalesLedgerAccount);
        });

        this.purchaseAccountDropdown = MUIOutlet(this, "purchase-ledger-account-dd", "MUIButton");
        this.purchaseAccountDropdown.setAction(this, function(this:ProductDetailInformationViewController){
            AppHelper.sharedInstance().showSelectLedgerAccountViewControllerFromView(this.purchaseAccountDropdown, this._product.purchaseLedgerAccount, null, false, this._product.name, this, this.updatePurchaseLedgerAccount);
        });

    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private productTags = null;
    private _product:Product = null;
    set product(value){
        this._product = value;
        this.productTags = (this._product.tags == null || this._product.tags?.length == 0) ? [] : this._product.tags.split(',');
        this.updateUI();
    }

    private updateUI(){
        if (this._product == null) return;
        if (this.viewIsLoaded == false) return;

        this.shortdescriptionTextField.text = this._product.info ;
        this.detaildescriptionTextArea.text = this._product.detailedInfo;
        this.componentsdescriptionTextArea.text = this._product.componentsInfo;
        // this.ledgerAccountDropdown.title = this._product.ledger;
        // this.accountNumberTextField.text = this._product.accountNumber;
        this.salesAccountDropdown.title = this._product.salesLedgerAccountName;
        this.purchaseAccountDropdown.title = this._product.purchaseLedgerAccountName;

        let predicate = MIOPredicate.predicateWithFormat('type = ' + TagType.ProductOrMenu + " and deletedAt = null");
        DBHelper.queryObjectsWithCompletion("Tag", null, predicate, [], this, function(objects){
            this.reloadTags(objects);
        });                
    }

    private reloadTags(tags){
        this.tagView.removeAll();
        let sorted_tags = _MIOSortDescriptorSortObjects(tags, [MIOSortDescriptor.sortDescriptorWithKey("name", true)]);
        for (let index = 0; index < sorted_tags.length; index++){
            let tag = sorted_tags[index] as Tag;
            this.tagView.addTag(tag.name);
            if (this.productTags.containsObject(tag.name)) {
                this.tagView.selectTagAtIndex(index);
            }
        }
    }

    didSelectTagAtIndex(index, name:string){        
        let i = this.productTags.indexOfObject(name);
        if (i < 0){
            this.productTags.addObject(name);            
        }
        else {
            this.productTags.removeObject(name);
        }

        let array = []
        for (let c of this.productTags) {
            if (c == null || c.length == 0) continue;
            array.push (c);
        }

        this._product.tags = array.length > 0 ? array.join(',') : null;
    }

    private updateLedgerAccount(controller:SelectViewController, account:LedgerAccount){
        // this._product.ledgerAccount = account;
        // this._product.ledgerAccountName = account?.description;
        // this.ledgerAccountDropdown.title = account?.description;
    }

    private updateSalesLedgerAccount(controller:SelectViewController, account:LedgerAccount){
        this._product.salesLedgerAccount = account;
        this._product.salesLedgerAccountName = account?.description;
        this.salesAccountDropdown.title = account?.description;
    }

    private updatePurchaseLedgerAccount(controller:SelectViewController, account:LedgerAccount){
        this._product.purchaseLedgerAccount = account;
        this._product.purchaseLedgerAccountName = account?.description;
        this.purchaseAccountDropdown.title = account?.description;
    }

}