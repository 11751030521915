

class DLScriptingServer extends MIOObject
{ 
    private static _sharedInstance:DLScriptingServer = null;    
    public static sharedInstance():DLScriptingServer {
        if (DLScriptingServer._sharedInstance != null) {
            return DLScriptingServer._sharedInstance;
        }

        let instance = new DLScriptingServer();
        instance.init();
        
        DLScriptingServer._sharedInstance = instance;
        return DLScriptingServer._sharedInstance;
    }
    
    private webSocketURLString = "ws://dl-websocket.eu-central-1.elasticbeanstalk.com";
    init(){        
        if (DLScriptingServer._sharedInstance != null){
            throw new Error("DLScriptingServer: Instantiation failed. Use sharedInstance() instead of new.");
        }
        super.init();        
    }

    private _isConnected = null;
    get isConnected() {return this._isConnected;}

    private token:string = null;
    private identifier:string = null;
    private identifierType:string = null;

    private webSocket:MNKWebSocket = null;    
    login(token:string, identifier:string, identifierType:string){
        this.token = token;
        this.identifier = identifier;
        this.identifierType = identifierType;

        if (this.webSocket == null){
             //this.webSocket = MNKWebSocket.webSocketWithURLString(this.webSocketURLString, this);
        }        
    }

    private scriptItem = {};
    executeScript(script:DBScript, target, completion){
        let requestID = MIOUUID.UUID().UUIDString;
        let message = {
            "messageType": "executeScript",
            "deviceID" : MIOCoreGetDeviceUUID(),
            "script": script.code,
            "sql": script.query,
            "requestID" : requestID                         
        }
        
        let si = {};
        si["message"] = message;
        si["target"] = target;
        si["completion"] = completion;
        this.scriptItem[requestID] = si;

        let data = JSON.stringify(message)
        this.webSocket.send(data);
    }

    webSocketDidOpenConnection(webSocket:MNKWebSocket){
        MIOLog("Connection OK");
        let message = {
            "messageType": "openConnection",
            "deviceID" : MIOCoreGetDeviceUUID(),
            "accessToken": this.token,
            "identifier": this.identifier,
            "identifierType": this.identifierType
        }        

        let data = JSON.stringify(message)
        this.webSocket.send(data);
    }

    //
    // WebSocket delgate
    //

    webSocketDidCloseConnection(webSocket:MNKWebSocket){
        MIOLog("Connection Close");
    }

    webSocketDidFailConnection(webSocket:MNKWebSocket){
        MIOLog("Connection FAIL");
    }

    webSocketDidReceiveMessage(webSocket:MNKWebSocket, data:string){
        this.checkMessageType(data);
    }

    private checkMessageType(data){

        let msg = data["messageType"];

        switch (msg){
            case "openConnection":
                this.checkOpenConnecitonMessage(data["data"]);
                break;
                
            case "executeScript":
                this.checkExecuteScript(data["data"], data["requestID"]);
                break;
        }
    }

    private checkOpenConnecitonMessage(message){
        if (message == "CONNECTION_ESTABLISHED") {
            this._isConnected = DLRemoteNotificationServerStatus.ConnectionEstablished;            
        }
    }

    private checkExecuteScript(items, requestID:string){
        let si = this.scriptItem[requestID];
        let target = si["target"];
        let completion = si["completion"];
        if (target != null && completion != null) completion.call(target, items);
    }
}