

class ReportDateTimeFilterCell extends ReportFilterCell
{
    delegate = null;
    
    private dateTextField:MUITextField = null;
    private calendarButton:MUIButton = null;

    private df = MUIWebApplication.sharedInstance().delegate.dateFormatter;
    private dtf = MUIWebApplication.sharedInstance().delegate.dateTimeFormatter;
    private tf = MUIWebApplication.sharedInstance().delegate.timeFormatter;

    awakeFromHTML()
    {
        super.awakeFromHTML();
        
        this.dateTextField = MUIOutlet(this, "date-tf", "MUITextField");
        this.dateTextField.setOnChangeText(this, this.didChangeDate);
        
        this.calendarButton = MUIOutlet(this, "calendar-btn", "MUIButton");
        if (this.calendarButton) this.calendarButton.setAction(this, this.showDatePickerController);
    }

    private formatter:MIODateFormatter = null;
    setFilter(filter:any) {
        super.setFilter(filter);
        this.dateTextField.text = this.filterItem["Value"];
        
        switch (this.filterItem["Type"]) {
            case "time": this.formatter = this.tf; break;
            case "date": this.formatter = this.df; break;
            case "datetime": this.formatter = this.dtf; break;
            default: this.formatter = this.dtf; break;
        }

        this.dateTextField.formatter = this.formatter;
        this.dateTextField.text = this.filterItem["ValueString"];
    }

    private showDatePickerController(){
        let dp = new MUIDatePickerController();
        dp.init();        
        dp.delegate = this;
        dp.modalPresentationStyle = MUIModalPresentationStyle.Popover;

        let pc = dp.popoverPresentationController;
        pc.sourceRect = this.calendarButton.frame;
        pc.sourceView = this.calendarButton;

        AppHelper.sharedInstance().presentViewController(dp, true);
    }

    didSelectDate(datePickerController:MUIDatePickerController, date:Date){
        let v = this.formatter.stringForObjectValue(date);
        this.dateTextField.text = v;
        this.filterItem["ValueString"] = v;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this.filterItem["Value"] = ad.serverDateTimeFormatter.stringFromDate(date);
    }

    didChangeDate(control:MUITextField, value:string){                
        this.filterItem["ValueString"] = value;

        let date = this.formatter.getObjectValueForString(value);
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        this.filterItem["Value"] = ad.serverDateTimeFormatter.stringFromDate(date);
    }

}