

/// <reference path="TicketLine.ts" />

// Generated class CustomLine_ManagedObject

class CustomLine_ManagedObject extends TicketLine
{

    // Property: concept
    set concept(value:string) {
        this.setValueForKey(value, 'concept');
    }
    get concept():string {
        return this.valueForKey('concept');
    }
    set conceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'concept');
    }
    get conceptPrimitiveValue():string {
        return this.primitiveValueForKey('concept');
    }

    // Property: customTaxQuantity
    set customTaxQuantity(value:number) {
        this.setValueForKey(value, 'customTaxQuantity');
    }
    get customTaxQuantity():number {
        return this.valueForKey('customTaxQuantity');
    }
    set customTaxQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'customTaxQuantity');
    }
    get customTaxQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('customTaxQuantity');
    }

    // Property: price
    set price(value:number) {
        this.setValueForKey(value, 'price');
    }
    get price():number {
        return this.valueForKey('price');
    }
    set pricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'price');
    }
    get pricePrimitiveValue():number {
        return this.primitiveValueForKey('price');
    }

    // Property: quantity
    set quantity(value:number) {
        this.setValueForKey(value, 'quantity');
    }
    get quantity():number {
        return this.valueForKey('quantity');
    }
    set quantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'quantity');
    }
    get quantityPrimitiveValue():number {
        return this.primitiveValueForKey('quantity');
    }
}
