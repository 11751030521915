//
// Generated class User
//

/// <reference path="User_ManagedObject.ts" />

enum UserAccessType
{
    DashBoard,
    Reports,
    Logs,
    Licenses,
    CashDesk,
    Tickets,
    IssuedInvoices,
    Client,
    Worker,
    WorkerAdmin,
    UserAccess,
    Cards,
    Product,
    Category,
    ProductAdmin,
    Bookings,
    NoShow,
    Guests,
    GuestLists,
    BookingSources,
    Events,
    Parties,
    Supplier,
    Stock,
    LowStockOrder,
    InventoryNote,
    MovementNote,
    OutputNote,
    SupplierOrder,
    SupplierNote,
    DeliveryNote,
    StockOrderOutNote,
    StockOrderInNote,
    Settings,
    DBBackup,
    DBTool,
    DBMapping,
    DBSync,
    DBSyncLog,
    WarehouseOrder,
    WarehouseDelivery,
    WarehouseInput
}     


enum UserAccessControlBit
{
    none    = 1 << 0,
    view    = 1 << 1,

    create  = 1 << 2,
    delete  = 1 << 3,
    update  = 1 << 4,

    process = 1 << 5,
    unprocess = 1 << 6
}

class User extends User_ManagedObject
{
    hasManagerPermision(bitOffset){   
        this.checkLength();
        let value = this.permisions[bitOffset];
        if (value == "1") {
            return true;
        }

        return false;
    }

    addManagerPermission(bitOffset){
        this.checkLength();
        this.permisions = this.setCharAt(this.permisions, bitOffset, "1");
    }

    removeManagerPermission(bitOffset){
        this.checkLength();
        this.permisions = this.setCharAt(this.permisions, bitOffset, "0");
    }

    setAllPermissions(){
        this.permisions = "";
        for (let index = 0; index < SectionHelper.sectionCount; index++){
            this.permisions += "1";
        }
    }

    unsetAllPermissions(){
        this.permisions = "";
        for (let index = 0; index < SectionHelper.sectionCount; index++){
            this.permisions += "0";
        }
    }
    
    private checkLength(){
        if (this.permisions.length == SectionHelper.sectionCount) return;

        for (let index = this.permisions.length; index < SectionHelper.sectionCount; index++){
            this.permisions += "0";
        }
    }

    private setCharAt(str, index, chr) {        
        return (index > 0 ? str.substr(0, index): "") + chr + (index < (str.length - 1) ? str.substr(index + 1) : "");
    }

    checkAccessControlFlag(section:number, flags:number) : boolean {
        let bytes = this.accessControlFlags();
        let b = bytes[section];
        return (b & flags) > 0;
    }

    toggleAccessControlFlag(section:number, flag:number) : number {
        let bytes = this.accessControlFlags();
        let b = bytes[section];
        b = (b & flag) > 0 ? (b & ~flag) : (b | flag);
        bytes[section] = b;
        this.setAccessControlFlags(bytes);
        return b;
    }

    setAccessControlSectionFlags(section:number, flags:number) {
        let bytes = this.accessControlFlags();
        bytes[section] = flags;
        this.setAccessControlFlags(bytes);
    }

    setAccessControlFlags(bytes:Uint8Array) {        
        this.accessControl = base64js.fromByteArray(bytes);
    }

    private static allFlags = UserAccessControlBit.view | UserAccessControlBit.create | UserAccessControlBit.update | UserAccessControlBit.delete | UserAccessControlBit.process | UserAccessControlBit.unprocess;
    accessControlFlags() {
        let bytes = null;
        
        if (this.accessControl == null) {
            bytes = new Uint8Array(SectionHelper.sectionCount);            
            for (let i = 0; i < SectionHelper.sectionCount; i++) bytes[i] = User.allFlags;
        }
        else {
            bytes = base64js.toByteArray(this.accessControl);
            if (bytes.length < SectionHelper.sectionCount) {
                let aux = new Uint8Array(SectionHelper.sectionCount);
                for (let i = 0; i < bytes.length; i++) aux[i] = bytes[i];
                for (let i = bytes.length; i < SectionHelper.sectionCount; i++) aux[i] = User.allFlags;
                bytes = aux;
                this.setAccessControlFlags(bytes);
            }
        }
        
        return bytes;
    }

    hiddenReportIDs() : string[] {
        let reports = this.reportAccess.filterWithPredicate( MIOPredicate.predicateWithFormat("visible == false") );
        return reports.map ( ( access : UserReportAccess ) => { return access.reportID } );
    }

    hasReportID( identifier: string ) : boolean {
        let access = this.reportAccess.filterWithPredicate(  MIOPredicate.predicateWithFormat( "visible == false and reportID == " + identifier ) );        
        return access.length > 0 ? false : true;
    }


    removeReportID( identifier: string ) {
        let access = this.reportAccess.filterWithPredicate(  MIOPredicate.predicateWithFormat( "reportID == " + identifier ) ) as UserReportAccess[];
                
        if (access.length == 0) {
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            let ra = MIOEntityDescription.insertNewObjectForEntityForName( "UserReportAccess", ad.managedObjectContext) as UserReportAccess;
            ra.reportID = identifier;
            ra.user = this;
            // this.addReportAccessObject(ra);
        }

        access = this.reportAccess.filterWithPredicate(  MIOPredicate.predicateWithFormat( "reportID == " + identifier ) ) as UserReportAccess[];
        if ( access.length == 0 ) {
            // Throw error
        } 
        access[0].visible = false;
    }

    addReportID( identifier: string ) {
        let access = this.reportAccess.filterWithPredicate(  MIOPredicate.predicateWithFormat( "reportID == " + identifier ) );
        if ( access.length == 0) return;

        access[0].visible = true;
    }

}
