
/// <reference path="DBSynEntityHeaderView.ts" />


class DBSyncEntitiesDataSource extends MIOObject
{
    dateFrom:string = null;
    dateTo:string = null;

    private tableView:UITableView = null;    
    private headerView:DBSynEntityHeaderView = null;

    initWithTableView(tableView:UITableView, headerView:DBSynEntityHeaderView){
        this.tableView = tableView;
        this.headerView = headerView;
        this.headerView.hidden = true;
    }    
    
    private config:IntegratorConfig = null; 
    // private entityName:string = null;
    // private integrator:string = null;
    // endpoint:string = null;

    setConfigValues(config:IntegratorConfig){
        this.config = config;
        // this.entityName = entityName;
        // this.integrator = integrator;
        this.invalidateData();
    }

    invalidateData(){
        this.fetchedResultsController = null;
    }

    numberOfSections(tableView:UITableView){
        if (this.fetchedResultsController == null) return 0;

        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableView:UITableView, section:number){
        if (this.fetchedResultsController == null) return 0;

        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    viewForHeaderInSection(tableview:UITableView, section){
        if (this.headerView == null) return null;

        let h = this.headerView.copy() as DBSynEntityHeaderView;
        h.awakeFromHTML();
        h.hidden = false;

        let item = this.fetchedResultsController.objectAtIndexPath(MIOIndexPath.indexForRowInSection(0, section)) as DBSyncEntity;

        h.item = item;

        // let h = new MUIView();
        // h.init();
        // h.layer.background = "";
        // h.layer.style.position= "absolute";
        // h.setHeight(23);

        // let title = new MUILabel();
        // title.init();
        // title.layer.style.position = "absolute";
        // title.setX(8);
        // title.setWidth(500);
        // h.addSubview(title);

        // let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate

        // let ip = MIOIndexPath.indexForRowInSection(0, section);
        // let item = this.fetchedResultsController.objectAtIndexPath(ip) as IntegratorsSync;
        // title.text = " " + ad.longDateTimeFormatter.stringFromDate(item.date) + " # " + item.referenceConcept;        

        return h;
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("SyncCell") as DBSyncEntityCell;
        cell.syncType = DBSyncEntityCellType.entity;
        cell.delegate = this;

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as IntegratorSyncEntityQueue;
        cell.item = item;        

        return cell;
    }    

    private _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value:MIOFetchedResultsController){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;

        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){                
        if (this._fetchedResultsController != null) return this._fetchedResultsController;            
        // if (this.entityName == null || this.integrator == null) return null;
        if (this.config == null) return null;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
    
        //let entityName = this.endpoint != null ? this.endpoint : this.entityName;
        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("integratorDate", false)];
        // let predicateFormat = "type == 2 AND integratorEnv == 'PRO' AND integratorDate >= '" + this.dateFrom + "' AND integratorDate <= '" + this.dateTo + "'";
        let predicateFormat = "integratorConfig.identifier == '" + this.config.identifier + "' integratorStatus == 0";
        // if (ad.selectedIdentifierType == "place") predicateFormat += " AND placeID == " + ad.selectedIdentifier;

        let fetchRequest = DBHelper.listFetchRequestWithEntityName(this.config.queueEntityName, null, predicateFormat);
        //let fetchRequest = DBHelper.listFetchRequestWithEntityName("DBSyncEntity", sortDescriptors, predicateFormat);
        fetchRequest.fetchLimit = 100;
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    controllerDidChangeContent(controller){
        this.tableView.reloadData();        
    }
    
    private syncItems = [];
    addSyncItemFromCell(cell:DBSyncEntityCell, item:IntegratorSync){
        this.syncItems.addObject(item.identifier);
    }

    // syncItemFromCell(cell:DBSyncEntityCell, item:DBSyncEntity){
    //     let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

    //     if (item.integratorSync == null) {
    //         let sync = MIOEntityDescription.insertNewObjectForEntityForName("IntegratorSync", ad.managedObjectContext) as IntegratorSync;  
    //         sync.placeID = ad.selectedIdentifier;
    //         // sync.integrator = this.integrator;
    //         // sync.enviroment = "PRO";
    //         // sync.referenceEntityID = item.identifier;
    //         // sync.referenceEntityName = this.entityName;

    //         item.integratorSync = sync;
    //         DBHelper.saveMainContextWithCompletion(this, function(){
    //             let ip = this.tableVfsyncSelectedItemsiew.indexPathForCell(cell);
    //             this.tableView.reloadRowsAtIndexPaths([ip], true);                   
    //         });
    //     }
    // }

    syncSelectedItems(){
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.addItemsToSyncQueye(this.config, this.syncItems, (code, json) => { 
            if (code == 200) {
                this.tableView.reloadData();
            }
        });
        
    }

}