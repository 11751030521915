class DBWorkersCoder extends DBXLSXCoder
{
    mainEntity() {
        return { entity: "Employee", predicateFormat: "deletedAt == null" }
    }

    columns() : any[] {
        let cols = [
            { title:"WORKER ID", width: "10%", align: "center" },
            { title:"WORKER NAME", width: "10%", align: "center" },
            { title:"PIN CODE", width: "10%", align: "center" },
            { title:"PASSWORD", width: "10%", align: "center" },
            { title:"PERMISSIONS", width: "10%", align: "center" },
            { title:"ROLE ID", width: "20%", align: "center" },
            { title:"ROLE NAME", width: "20%", align: "center" },
            { title:"DEPARTMENT ID", width: "20%", align: "center" },
            { title:"DEPARTMENT NAME", width: "20%", align: "center" },
            { title:"PHONE", width: "10%", align: "center" },
            { title:"MOBILE", width: "10%", align: "center" },
            { title:"EMAIL", width: "10%", align: "center" },
            { title:"ADDRESS", width: "10%", align: "center" },
            { title:"STATE", width: "10%", align: "center" },
            { title:"CITY", width: "10%", align: "center" },
            { title:"POSTAL CODE", width: "10%", align: "center" },
            { title:"COUNTRY", width: "10%", align: "center" }
        ]
        return cols;
    }

    protected aditionalImportEntities() { 
        return [
            { entity: "Department", predicateFormat: "deletedAt == null" },
            { entity: "WorkerRole", predicateFormat: "deletedAt == null" }
        ]; 
    }

    protected importRow(row:any){
                
        let workerID       = row["WORKER ID"];
        let workerName     = row["WORKER NAME"];        
        let phone          = row["PHONE"];
        let mobile         = row["MOBILE"];
        let email          = row["EMAIL"];
        let address        = row["ADDRESS"];
        let state          = row["STATE"];
        let city           = row["CITY"];
        let postalCode     = row["POSTAL CODE"];
        let country        = row["COUNTRY"];
        let roleID         = row["ROLE ID"];
        let roleName       = row["ROLE NAME"];
        let departmentID   = row["DEPARTMENT ID"];
        let departmentName = row["DEPARTMENT NAME"];
        let pinCode        = row["PIN CODE"];
        let password       = row["PASSWORD"];
        let permisions     = row["PERMISSIONS"];
 
        if (workerName == null) { return; }

        let department = this.queryEntityByIDOrName("Department", departmentID, departmentName) as Department;
        if (departmentName?.length > 0 && department == null) {
            return;
        }

        let role = this.queryEntityByIDOrName("WorkerRole", roleID, roleName) as WorkerRole;
        if (roleName?.length > 0 && role == null) {
            return;
        }

        let employee = this.queryEntityByIDOrName("Employee", workerID, workerName) as Employee;
        if (employee == null) {        
            employee = MIOEntityDescription.insertNewObjectForEntityForName("Employee", DBHelper.mainManagedObjectContext) as Employee;
            employee.identifier = this.parseOrCreateIdentifier(workerID);
            this.appendObject(employee);
        }  
        
        employee.name = workerName;

        if (employee.address == null) {
            let addr = MIOEntityDescription.insertNewObjectForEntityForName("Address", DBHelper.mainManagedObjectContext) as Address;
            employee.address = addr;
        }

        if (employee.phone == null) {
            let p = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", DBHelper.mainManagedObjectContext) as PhoneNumber;
            employee.phone = p;
        }

        if (employee.mobilePhone == null) {
            let m = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", DBHelper.mainManagedObjectContext) as PhoneNumber;
            employee.mobilePhone = m;
        }

        employee.phone.number = phone;
        
        employee.mobilePhone.number = mobile;
        employee.email = email;
        employee.address.address1 = address;    
        employee.address.state = state;
        employee.address.city = city;
        employee.address.postalCode = postalCode;
        employee.address.country = country;
        employee.role = role;
        employee.department = department;
        employee.pinCode = pinCode?.trim().length > 0 ? pinCode : null;
        employee.password = password?.trim().length > 0 ? password : null;
        employee.permissions = permisions;
        
        MIOLog("ADDING WORKER: " + this.rowIndex + "/" + this.rows.length + ": " + workerName);
    }

    protected exportTitle() : string { return "Employees"; }

    protected exportSortDescriptors() { 
        return [MIOSortDescriptor.sortDescriptorWithKey("name", false)]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let employee = object as Employee;

        let item = {
            "WORKER ID": employee.identifier,
            "WORKER NAME": employee.name,
            "PHONE": employee.phone?.number,
            "MOBILE": employee.mobilePhone?.number,
            "EMAIL": employee.email,
            "ADDRESS": employee.address?.address1,
            "STATE": employee.address?.state,
            "CITY": employee.address?.city,
            "POSTAL CODE": employee.address?.postalCode,
            "COUNTRY": employee.address?.countryISOa2,
            "ROLE ID": employee.role?.identifier,
            "ROLE NAME": employee.role?.name,
            "DEPARTMENT ID": employee.department?.identifier,
            "DEPARTMENT NAME": employee.department?.name,
            "PIN CODE": employee.pinCode,
            "PASSWORD": employee.password,
            "PERMISSIONS": employee.permissions

        }

        return item;
    }
}