
class ManagerPermissionsDataSource extends MIOObject
{
    private tableView:UITableView = null;
    initWithTableView(tableView:UITableView){
        this.tableView = tableView;
    }

    private _user:User = null;
    get user(){return this._user;}
    set user(value:User) {
        this._user = value;
        this.tableView.reloadData();
    }

    numberOfSections(tableView:UITableView){
        return this.fetchedResultsController.sections.length;
    }

    numberOfRowsInSection(tableView:UITableView, section:number){
        let sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }

    titleForHeaderInSection(tableView:MUITableView, section:number) {
        let ip = MIOIndexPath.indexForRowInSection(0, section);
        let item = this.fetchedResultsController.objectAtIndexPath(ip) as ManagerSection;
        if (item.module == null) return "";
        return MIOLocalizeString(item.module, item.module);
    }

    cellAtIndexPath(tableView:UITableView, indexPath: MIOIndexPath) {
        let cell = tableView.dequeueReusableCellWithIdentifier("UserPermissionCell") as UserPermissionCell;
        
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ManagerSection;                        
        let title = MIOLocalizeString(item.name, item.name);

        cell.title = title
        cell.sectionIndex = item.bitPosition;
        cell.accessoryType = this._user.hasManagerPermision(item.bitPosition) ? UITableViewCellAccessoryType.Checkmark : UITableViewCellAccessoryType.None;
        
        cell.delegate = this;

        return cell;
    }

    canSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ManagerSection;

        let loginEmail = MIOUserDefaults.standardUserDefaults().valueForKey("LastLoginEmail");                        
        if (this._user.email == loginEmail && item.bitPosition == 47) return false;

        return true;
    }    

    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let cell = tableView.cellAtIndexPath(indexPath) as UserPermissionCell;

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ManagerSection;
        
        if (this._user.hasManagerPermision(item.bitPosition)) {
            // Remove
            this._user.removeManagerPermission(item.bitPosition);
            cell.accessoryType = UITableViewCellAccessoryType.None;
        }
        else {
            this._user.addManagerPermission(item.bitPosition);
            cell.accessoryType = UITableViewCellAccessoryType.Checkmark;
        }        
    }

    private _fetchedResultsController = null;
    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('ManagerSection');
        fetchRequest.sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey('moduleIndex', true),
                                        MIOSortDescriptor.sortDescriptorWithKey('orderIndex', true)];
    
        let predicateValues = [];
        //predicateValues.push("bitMask & " + SettingsHelper.sharedInstance().permisisons + " > 0");
                
        if (predicateValues.length > 0) fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateValues.join(" AND "));

        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "moduleIndex");
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller:MIOFetchedResultsController){        
        this.tableView.reloadData();
    }
}