/// <reference path="DualLinkDB.ts" />


class CoreDataBlock extends MIOObject
{
    public appVersion: string = null;
    public modelVersion: string = null;
    public systemVersion: string = null;
    public appType: string = null;
    public changedByID:string = null;
    public changedByName:string = null;

    public transactionID: MIOUUID = null;
    public date: Date = null;
    public transactions: CoreDataBlockLine[] = null;
    
    // Reference is like entityName://entityID
    initWithAppVersion( appVersion: string, modelVersion: string, appType:string, systemVersion:string, changedByID:string, changedByName:string, transactions: CoreDataBlockLine[] ) {
        this.appVersion = appVersion;
        this.modelVersion = modelVersion;
        this.appType = appType;
        this.systemVersion = systemVersion;
        this.changedByID = changedByID;
        this.changedByName = changedByName;

        this.transactionID = new MIOUUID();
        this.date = new Date();
        this.transactions = transactions;
    }    
}

class CoreDataBlockLine  extends MIOObject
{
    public action: string = null;
    public entityName: string = null;
    public entityID: string = null;
    public version: number = null;
    public changes:{ [key: string]: number} = null;
    
    // Reference is like entityName://entityID
    public get entityRef() : string { return this.entityName + "://" + this.entityID; }
    
    
    initWithEntityReference( entityReference: string, action: string, version:number, changes:{[key:string]:any} ) {
        let parts       = entityReference.split("://");
        this.action     = action;
        this.entityName = parts[ 0 ];
        this.entityID   = parts[ 1 ];
        this.version    = version;
        this.changes    = changes;
    }

    initWithEntityID( entityID: string, entityName:string, action: string, version:number, changes:{[key:string]:any} ) {
        this.action     = action;
        this.entityName = entityName;
        this.entityID   = entityID;
        this.version    = version;
        this.changes    = changes;
    }


    serialize ( ) : {[key:string]:any} {
        return { "action": this.action, "entity": this.entityRef, "values": this.changes }
    }
}


function DLDB_SerializeCoreDataChanges(insertObjects:MIOManagedObject[], updateObjects:MIOManagedObject[], deleteObjects:MIOManagedObject[]) : CoreDataBlockLine[]
{
    let objects:CoreDataBlockLine[] = [];
        
    objects.addObject(DLDB_SerializeManagedObjects(insertObjects, "INSERT"));
    objects.addObject(DLDB_SerializeManagedObjects(updateObjects, "UPDATE"));
    objects.addObject(DLDB_SerializeManagedObjects(deleteObjects, "DELETE"));

    return objects;
}

function DLDB_SerializeManagedObjects(objects:MIOManagedObject[], action: string): CoreDataBlockLine[] 
{        
    let items:CoreDataBlockLine[] = [];
    
    for (const obj of objects) {
        let values = obj.serializeChangedValues();
        if (values.count == 0) { continue; }
        items.addObject( new CoreDataBlockLine()).initWithAction(action, obj.entityReferenceID, 0, values );
    }
        
    return items;
}
