/**
 * Created by erwinschneider on 30/12/16.
 */


/// <reference path="../stock/StockElaborationTypeViewController.ts" />


class ProductDetailStockViewController extends MUIViewController
{
    private enableStockView:MUIView = null;
    private enableStockButton:MUIButton = null;

    private isEnableForStockButton:MUISwitchButton = null;

    private purchaseTaxButton:MUIButton = null;    
    private stockBlockButton:MUIButton = null;

    private stockCategoryButton:MUIButton = null;    

    private measureTypeDropdown:MUIButton = null;
    private containerMeasureTypeDropdown:MUIButton = null;
    private containerQuantityTextField:MUITextField = null;
    
    private mainBarcodeTextField:MUITextField = null;
    private secondaryBarcodeTextField:MUITextField = null;
    
    private addInputFormatButton:MUIButton = null;
    private inputFormatTableView:MUITableView = null;

    private lossInStorageTextField:MUITextField = null;    
    private additionalStorageCostTextField:MUITextField = null;    

    private weightEmptyTextField:MUITextField = null;
    private weightFullTextField:MUITextField = null;   

    private minStockTextField:MUITextField = null;
    private maxStockTextField:MUITextField = null;   
    
    private defaultWarehouseDropdown:MUIButton = null; 
    private warehouseInfoButton:MUIButton = null;   
    private warehouseTableView:MUITableView = null;
    private addWarehouseButton:MUIButton = null;        

    private defaultInputFormatDropdown:MUIButton = null;

    private inputFormatDataSource:ProductInputFormatDataSource = null;
    private warehouseDataSource:ProductWarehouseDataSource = null;

    private wineCellarInfoButton:MUIButton = null;
    private wineCellarLabel:MUILabel = null;

    private nf = MUIWebApplication.sharedInstance().delegate.numberFormatter;
    private cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter;        

    viewDidLoad(){
        super.viewDidLoad();

        this.enableStockView = MUIOutlet(this, "page-cover", "MUIView");
        
        this.enableStockButton = MUIOutlet(this, "enable-stock-btn", "MUIButton");        
        this.enableStockButton.setAction(this, function(){
            this.showEnableStockAlert();
        });

        this.isEnableForStockButton = MUIOutlet(this, "enable-stock-sb", "MUISwitchButton");
        this.isEnableForStockButton.setOnChangeValue(this, function(this:ProductDetailStockViewController){
            this.showDisableStockAlert();
        });

        this.purchaseTaxButton = MUIOutlet(this, 'purchase-tax-btn','MUIButton');
        this.purchaseTaxButton.setAction(this, function(){
            AppHelper.sharedInstance().showSelectTaxViewControllerFromView(this.purchaseTaxButton, this._product, this._product.taxBuy, this, this.taxDidChange);
        });

        this.stockBlockButton = MUIOutlet(this, "stock-block-type-dd", "MUIButton");
        this.stockBlockButton.setAction(this, function(){
            let vc = new StockBlockTypeViewController("stock-block-view");
            vc.initWithResource("layout/stock/StockBlockType.html");
            vc.delegate = this;
            vc.product = this._product;
            
            vc.modalPresentationStyle = MUIModalPresentationStyle.Popover;
            let pc = vc.popoverPresentationController;
            pc.sourceRect = this.stockBlockButton.frame;
            pc.sourceView = this.stockBlockButton;

            this.presentViewController(vc, true);
        });

        this.stockCategoryButton = MUIOutlet(this, "stock-category-dd", "MUIButton");
        this.stockCategoryButton.setAction(this, function(){
            AppHelper.sharedInstance().showSelectStockCategoryViewControllerFromView(this.categoryButton, this._product.stockCategory, this, function(controller, category:StockCategory){
                this._product.stockCategory = category;
                this.stockCategoryButton.title = category ? category.name : null;
            }, null, null);    
        });
        
        this.measureTypeDropdown = MUIOutlet(this, "measure-type-dd", "MUIButton");
        this.measureTypeDropdown.setAction(this, function(){            
            AppHelper.sharedInstance().showSelectMeassureUnitViewControllerFromView(this.measureTypeDropdown, this._product.measureType, true, this, this.inputUnitTypeDidChange);
        });

        this.containerMeasureTypeDropdown = MUIOutlet(this, "container-meassure-type-dd", "MUIButton");
        this.containerMeasureTypeDropdown.setAction(this, function () {
            AppHelper.sharedInstance().showSelectMeassureUnitViewControllerFromView(this.containerMeasureTypeDropdown, this._product.containerMeasureType, false, this, this.meassureUnitDidChange);
        });

        this.containerQuantityTextField = MUIOutlet(this, "container-quantity-tf", "MUITextField");
        this.containerQuantityTextField.setOnChangeText(this, function(control, value){
            let q = this.nf.numberFromString(value);
            this._product.quantity = q;
        });   
        
        this.defaultInputFormatDropdown = MUIOutlet( this, "default-input-format-dd", "MUIButton" );
        this.defaultInputFormatDropdown.setAction( this, function( this: ProductDetailStockViewController ){
            AppHelper.sharedInstance().showSelectStockInputFormatViewControllerFromView( this.defaultInputFormatDropdown, this._product, (controller:SelectViewController, value:StockInputFormat) => {
                this._product.defaultInputFormat = value;
                this._product.defaultInputFormatName = value?.name;
                this.defaultInputFormatDropdown.title = value?.name;
            });
        });

        this.addInputFormatButton = MUIOutlet(this, "add-input-format-btn", "MUIButton");
        this.addInputFormatButton.setAction(this, function(){
            AppHelper.sharedInstance().showAddInputFormatAlertViewController(this._product);
        });

        this.inputFormatTableView = MUIOutlet(this, "input-format-table-view", "MUITableView");
        this.inputFormatDataSource = new ProductInputFormatDataSource();
        this.inputFormatDataSource.initWithTableView(this.inputFormatTableView);
        this.inputFormatTableView.dataSource = this.inputFormatDataSource;
        this.inputFormatTableView.delegate = this.inputFormatDataSource;

        this.lossInStorageTextField = MUIOutlet(this, 'loss-in-storage-tf', 'MUITextField');     
        this.lossInStorageTextField.formatter = this.nf;   
        this.lossInStorageTextField.setOnChangeText(this, function(textfield, value) {                        
            this._product.lossInStorage = value;
        });

        this.additionalStorageCostTextField = MUIOutlet(this, "additional-cost-storage-tf", "MUITextField");
        this.additionalStorageCostTextField.formatter = this.nf;
        this.additionalStorageCostTextField.setOnChangeText(this, function(control, value) {
            let q = this.nf.numberFromString(value);
            this._product.additionalStorageCosts = q;
        });

        this.weightEmptyTextField = MUIOutlet(this, "weight-empty-tf", "MUITextField");
        this.weightEmptyTextField.formatter = this.nf;
        this.weightEmptyTextField.setOnChangeText(this, function(control, value){
            let q = this.nf.numberFromString(value);
            this._product.weightEmpty = q;
        });
        
        this.weightFullTextField = MUIOutlet(this, "weight-full-tf", "MUITextField");
        this.weightFullTextField.formatter = this.nf;
        this.weightFullTextField.setOnChangeText(this, function(control, value){
            let q = this.nf.numberFromString(value);
            this._product.weightFull = q;
        });    
        
        this.minStockTextField = MUIOutlet(this, "min-stock-tf", "MUITextField");
        this.minStockTextField.formatter = this.nf;
        this.minStockTextField.setOnChangeText(this, function(this:ProductDetailStockViewController, control:MUITextField, value){
            let q = this.nf.numberFromString(value);
            this._product.min = q;
        });

        this.maxStockTextField = MUIOutlet(this, "max-stock-tf", "MUITextField");
        this.maxStockTextField.formatter = this.nf;
        this.maxStockTextField.setOnChangeText(this, function(this:ProductDetailStockViewController, control:MUITextField, value){
            let q = this.nf.numberFromString(value);
            this._product.max = q;
        });

        this.mainBarcodeTextField = MUIOutlet(this, "main-barcode-tf", "MUITextField");
        this.mainBarcodeTextField.setOnChangeText(this, function(textfield, value) {            
            this._product.barcode = value;                            
        });

        this.secondaryBarcodeTextField = MUIOutlet(this, "secondary-barcode-tf", "MUITextField");
        this.secondaryBarcodeTextField.setOnChangeText(this, function(textfield, value) {            
            this._product.barcode2 = value;                        
        });

        this.defaultWarehouseDropdown = MUIOutlet(this, "default-warehouse-dd", "MUIButton");
        this.defaultWarehouseDropdown.setAction(this, function(){
            AppHelper.sharedInstance().showSelectWarehouseViewControllerFromView(this.defaultWarehouseDropdown, this._product.defaultWarehouse, false, false, this, this.warehouseDidChange);
        });

        // this.warehouseInfoButton = MUIOutlet(this, "warehouses-btn", "MUIButton");
        // this.warehouseInfoButton.setAction(this, function(){
        //     let vc = new ProductWarehouseInfoViewController("product-stock-warehouse-view");
        //     vc.initWithResource("layout/products/ProductStockWarehouseView.html");
        //     vc.product = this._product;
        //     // vc.modalPresentationStyle = MUIModalPresentationStyle.Custom;
        //     // let pc = vc.presentationController;
        //     // // pc.sourceRect = this.warehouseInfoButton.frame;
        //     // // pc.sourceView = this.warehouseInfoButton;
        //     this.presentViewController(vc, true);
        // });

        this.wineCellarLabel = MUIOutlet(this, "winecellar-lbl", "MUILabel");

        this.wineCellarInfoButton = MUIOutlet(this, "winecellar-info-btn", "MUIButton");
        this.wineCellarInfoButton.setAction(this, function() {
                let vc = new WineCellarInfoViewController("wine-cellar-info-view");
                vc.initWithResource("layout/winecellar/WineCellarInfoView.html");
                vc.delegate = this;
                vc.product = this._product;

                let nc = new MUINavigationController('wineCellar_nav_controller');
                nc.initWithRootViewController(vc);
                this.presentViewController(nc, true);
        });

        // this.wineCellarDropDown = MUIOutlet(this, "winecellar-dd", "MUIButton");
        // this.wineCellarDropDown.setAction(this, function() {
        //         this.showSelectWineCellarEntityViewController(this.wineCellarDropDown);
        // });

        this.addWarehouseButton = MUIOutlet(this, "add-warehouse-btn", "MUIButton");
        this.addWarehouseButton.setAction(this, function(){
            AppHelper.sharedInstance().showSelectWarehouseViewControllerFromView(this.addWarehouseButton, null, false, true, this, this.addProductWarehouses);            
        });

        this.warehouseTableView = MUIOutlet(this, "warehouse-table-view", "MUITableView");
        this.warehouseDataSource = new ProductWarehouseDataSource();
        this.warehouseDataSource.initWithTableView(this.warehouseTableView);
        this.warehouseTableView.dataSource = this.warehouseDataSource;
        this.warehouseTableView.delegate = this.warehouseDataSource;   

    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    viewDidDisappear(animated?: any): void {
        super.viewDidDisappear(animated);
        if (this._product != null) this._product.removeObserver(this, "stockCategory");
    }

    private _product:Product = null;
    set product( value:Product ){
        if (this._product != null){
            this._product.removeObserver(this, "stockCategory");
        }
        this._product = value;
        if (this._product != null){
            this._product.addObserver(this, "stockCategory");
        }
        this.updateUI();
    }

    observeValueForKeyPath(keypath, type, object){
        if (type != "did") return;

        switch (keypath) {

            case "stockCategory":                    
                this.stockCategoryButton.setTitle(this._product.stockCategory ? this._product.stockCategory.name : null);
                break;    
        }
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._product == null) return;        

        this.enableStockView.hidden = this._product.isEnableForStock;
        this.isEnableForStockButton.on = this._product.isEnableForStock;

        let taxBuy = this._product.taxBuy;
        this.purchaseTaxButton.setTitle(taxBuy ? taxBuy.name : 'Purchase Tax');
        
        this.stockBlockButton.title = this._product.stockBlockTypeString;     

        let category = this._product.stockCategory;
        this.stockCategoryButton.setTitle(category ? category.name : '');    

        this.measureTypeDropdown.title = MeasureUnits.toString(this._product.measureType);
        if (this._product.isContainer) {
            this.containerMeasureTypeDropdown.title = MeasureUnits.toString(this._product.containerMeasureType);
            this.containerMeasureTypeDropdown.enabled = true;
            this.containerQuantityTextField.text = this._product.containerQuantity ? this.nf.stringFromNumber(this._product.containerQuantity) : null;
            this.containerQuantityTextField.enabled = true;
        }
        else {
            this.containerMeasureTypeDropdown.title = "-";
            this.containerMeasureTypeDropdown.enabled = false;
            this.containerQuantityTextField.text = "-";
            this.containerQuantityTextField.enabled = false;
        }

        this.mainBarcodeTextField.text = this._product.barcode;
        this.secondaryBarcodeTextField.text = this._product.barcode2;

        this.defaultInputFormatDropdown.title = this._product.defaultInputFormatName;
        this.inputFormatDataSource.product = this._product;
        this.inputFormatTableView.reloadData();                
                
        this.lossInStorageTextField.text = this.nf.stringFromNumber(this._product.lossInStorage);
        this.additionalStorageCostTextField.text = this.nf.stringFromNumber(this._product.additionalStorageCosts);

        this.weightEmptyTextField.text = this.nf.stringFromNumber(this._product.weightEmpty);
        this.weightFullTextField.text = this.nf.stringFromNumber(this._product.weightFull);

        this.minStockTextField.text = this.nf.stringFromNumber(this._product.min);
        this.maxStockTextField.text = this.nf.stringFromNumber(this._product.max);

        this.defaultWarehouseDropdown.title = this._product.defaultWarehouse?.name;

        this.wineCellarLabel.text = this.wineCellarLabelFromHash(this._product.wineCellarHash);


        let warehouseName = this._product.defaultWarehouseName ? this._product.defaultWarehouseName : null;
        this.defaultWarehouseDropdown.title = warehouseName;

        this.warehouseDataSource.product = this._product;
        this.warehouseTableView.reloadData();
    }

    public taxDidChange(controller:SelectEntityViewController, tax:Tax){
        this._product.taxBuy = tax;
        this.purchaseTaxButton.title = tax.name;        
    }

    stockBlockTypeDidUpdate(){
        this.stockBlockButton.title = this._product.stockBlockTypeString;
    }

    private inputUnitTypeDidChange(inputType:MeasureUnitType){
        this._product.stockInputUnitType = inputType;
        this.measureTypeDropdown.title = MeasureUnits.toString(inputType);

        if (inputType != MeasureUnitType.Container) {
            this._product.measureUnitType = inputType;
            this._product.quantity = null;
            this.containerMeasureTypeDropdown.title = "-";
            this.containerMeasureTypeDropdown.enabled = false;
            this.containerQuantityTextField.text = null;
            this.containerQuantityTextField.enabled = false;
        }
        else {            
            this.containerMeasureTypeDropdown.enabled = true;
            this.containerQuantityTextField.enabled = true;
        }
    }

    private meassureUnitDidChange(measureUnit:MeasureUnitType){
        this._product.measureUnitType = measureUnit;
        this.containerMeasureTypeDropdown.title = MeasureUnits.toString(measureUnit);
    }

    private warehouseDidChange(controller:SelectEntityViewController, warehouse:Warehouse){
        if (warehouse == null) {
            this._product.defaultWarehouse = null;
            this._product.defaultWarehouseName = null;
            this.defaultWarehouseDropdown.title = MIOLocalizeString("_DEFAULT_WAREHOUSE_", "_DEFAULT_WAREHOUSE_");
        }
        else {
            this._product.defaultWarehouse = warehouse;
            this._product.defaultWarehouseName = warehouse.name;
            this.defaultWarehouseDropdown.title = warehouse.name;    
        }
    }

    // get ProductSupportRequiredViewController(){
    //     if(!this.srovc) {
    //         this.srovc = new ProductSupportRequiredViewController('supported_required_view');
    //         this.srovc.initWithResource("layout/modals/productsupportrequired/SupportedRequiredView.html");
    //     }

    //     this.srovc.product = this._product;
    //     return this.srovc;
    // }

    private showWarehouseSettingsView(){        
        let wsvc = new WarehouseSettingsViewController('warehouse-settings-view');
        wsvc.initWithResource("layout/modals/warehousesettings/WarehouseSettingsView.html");
        this.wsvc = new MUINavigationController('product_warehouses_nav_controller');
        this.wsvc.initWithRootViewController(wsvc);
        
        this.wsvc.rootViewController.delegate = this;
        this.wsvc.rootViewController.product = this._product;

        this.presentViewController(this.wsvc, true);
    }    

    private addProductWarehouses(controller, warehouses){

        for (let index = 0; index < warehouses.length; index++){
            let wh = warehouses[index];

            // check to see if ProductWarehouse for Warehouse exists
            let predicate = MIOPredicate.predicateWithFormat("warehouse.identifier == " + wh.identifier);
            let contains = _MIOPredicateFilterObjects(this.warehouseDataSource.fetchedResultsController.fetchedObjects, predicate);
            if (contains.length > 0) continue;


            if (this.warehouseDataSource.fetchedResultsController.fetchedObjects.containsObject(wh) == true) continue;

            // Create product warehouse
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;            
            let pw:ProductWarehouse = MIOEntityDescription.insertNewObjectForEntityForName("ProductWarehouse", ad.managedObjectContext) as ProductWarehouse;            
            pw.product = this._product;
            pw.warehouse = wh;
            this._product.addProductWarehousesObject(pw);              
        }

        DBHelper.saveMainContext();

    }

    private showEnableStockAlert(){
        if (this._product == null) return;

        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("WARNING","WARNING"), MIOLocalizeString("ARE YOU SURE YOU WANT TO ENABLE STOCK SUPPORT?","ARE YOU SURE YOU WANT TO ENABLE STOCK SUPPORT?"), MUIAlertViewStyle.Default);
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('YES, ENABLE IT', 'YES, ENABLE IT'), MUIAlertActionStyle.Default, this, function(){
            this._product.isEnableForStock = true;
            this.enableStockView.hidden = true;
            this.isEnableForStockButton.on = true;
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL', 'CANCEL'), MUIAlertActionStyle.Cancel, this, function(){
            this.isEnableForStockButton.on = false;
        }));

        this.presentViewController(avc, true);
    }

    private showDisableStockAlert(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString("WARNING","WARNING"), MIOLocalizeString("ARE YOU SURE YOU WANT TO DISABLE STOCK SUPPORT?","ARE YOU SURE YOU WANT TO DISABLE STOCK SUPPORT?"), MUIAlertViewStyle.Default);
        
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('YES', 'YES'), MUIAlertActionStyle.Default, this, function(){
            this._product.isEnableForStock = false;
            this.enableStockView.hidden = false;
            this.isEnableForStockButton.on = false;
        }));

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL', 'CANCEL'), MUIAlertActionStyle.Cancel, this, function(){
            this.isEnableForStockButton.on = true;
        }));

        this.presentViewController(avc, true);
    }   

    // private showSelectWineCellarEntityViewController(button:MUIButton){

    //     let predicateFormat = null;      
    //     let vc = AppHelper.sharedInstance().showCustomSelectViewControllerFromView(button, "WineCellar", "name", predicateFormat, this, function(controller, object:MIOManagedObject){

    //         if (object) {
    //             button.title = object.valueForKey('name');
    //             this._product. = object;
    //         } else {
    //             this.ledgerAccountButton.title = MIOLocalizeString("SELECT","SELECT");
    //             this._legalTaxType.ledgerAccount = null;
    //         }

    //     }, true );      
    // }

    private wineCellarLabelFromHash(hash:string|null) {
        if (hash == null) return null;
        return hash.replace(/\#/g, " | ");
    }
}