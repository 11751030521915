
interface MIOAttributeDescription
{
    deserializeFromValue( value?: any ) : any ;
    serializeFromValue( value?: any ) : any ;
}


MIOAttributeDescription.prototype.deserializeFromValue = function( value?: any ) : any
{
    if (value == null) {
        if (this.isOptional) { return null }        
        throw Error("AttributeDescriptionValueFromError.isNotOptional(" + this.name + ")");
    }
    
    switch ( this.attributeType ) {
        case MIOAttributeType.Undefined: break;


        // case .decimalAttributeType:
        //     return MIOCoreDecimalValue( value, nil )
//                if let asString  = value! as? String     { return Decimal(string: asString) }
//                if let asDecimal = value! as? Decimal    { return asDecimal }
//                if let asDouble  = value! as? Double     { return Decimal(floatLiteral: asDouble) }
//                if let asInt     = value! as? Int        { return Decimal(integerLiteral: asInt) }


        // case .booleanAttributeType:
        //         if "\(type( of: value! ))" == "__NSCFBoolean" || (value! is Bool) { return value! as! Bool }
        //         if value! is Int { return (value! as! Int) != 0 }

        case MIOAttributeType.Date:
            if (value instanceof Date) { return value; }
            return (new MIOISO8601DateFormatter()).dateFromString(value);
                
        // case .UUIDAttributeType:
        //         if value! is UUID   { return value! as! UUID }
        //         if value! is String {
        //         let ret = UUID( uuidString: value! as! String )
                    
        //         if ret != nil { return ret! }
        //         }

        case MIOAttributeType.Transformable:
            if (value instanceof String) { return JSON.parse( value as string ); }
            return 

        default: return value;
    }

    return null;
}
    
MIOAttributeDescription.prototype.serializeFromValue = function (value?: any) : any 
{        
    if (value == null) { return null; }
                    
    switch (this.attributeType) 
    {
        // case .UUIDAttributeType:
        // return (value as! UUID).uuidString
        
        case MIOAttributeType.Date:
            return (new MIOISO8601DateFormatter()).stringForObjectValue(value);
                    
//        case .decimalAttributeType:
//            return (value as! Decimal).doubleValue
                
        case MIOAttributeType.Transformable:
            return JSON.stringify (value);

        default:
            return value
    }
    
    // TODO: THROW ERROR WHEN VALUES IS NULL AND IS NOT OPTIONAL
}
