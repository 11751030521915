

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class NumberSerialSequence_ManagedObject

class NumberSerialSequence_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: entityNameType
    set entityNameType(value:string) {
        this.setValueForKey(value, 'entityNameType');
    }
    get entityNameType():string {
        return this.valueForKey('entityNameType');
    }
    set entityNameTypePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'entityNameType');
    }
    get entityNameTypePrimitiveValue():string {
        return this.primitiveValueForKey('entityNameType');
    }

    // Property: entityType
    set entityType(value:number) {
        this.setValueForKey(value, 'entityType');
    }
    get entityType():number {
        return this.valueForKey('entityType');
    }
    set entityTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'entityType');
    }
    get entityTypePrimitiveValue():number {
        return this.primitiveValueForKey('entityType');
    }

    // Property: initialValue
    set initialValue(value:number) {
        this.setValueForKey(value, 'initialValue');
    }
    get initialValue():number {
        return this.valueForKey('initialValue');
    }
    set initialValuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'initialValue');
    }
    get initialValuePrimitiveValue():number {
        return this.primitiveValueForKey('initialValue');
    }

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: orderIndex
    set orderIndex(value:number) {
        this.setValueForKey(value, 'orderIndex');
    }
    get orderIndex():number {
        return this.valueForKey('orderIndex');
    }
    set orderIndexPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'orderIndex');
    }
    get orderIndexPrimitiveValue():number {
        return this.primitiveValueForKey('orderIndex');
    }

    // Property: padding
    set padding(value:number) {
        this.setValueForKey(value, 'padding');
    }
    get padding():number {
        return this.valueForKey('padding');
    }
    set paddingPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'padding');
    }
    get paddingPrimitiveValue():number {
        return this.primitiveValueForKey('padding');
    }

    // Property: prefix
    set prefix(value:string) {
        this.setValueForKey(value, 'prefix');
    }
    get prefix():string {
        return this.valueForKey('prefix');
    }
    set prefixPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'prefix');
    }
    get prefixPrimitiveValue():string {
        return this.primitiveValueForKey('prefix');
    }

    // Property: sequenceNumber
    set sequenceNumber(value:number) {
        this.setValueForKey(value, 'sequenceNumber');
    }
    get sequenceNumber():number {
        return this.valueForKey('sequenceNumber');
    }
    set sequenceNumberPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'sequenceNumber');
    }
    get sequenceNumberPrimitiveValue():number {
        return this.primitiveValueForKey('sequenceNumber');
    }
    // Relationship: mainSequence
    set mainSequence(value:NumberSerialSequence) {
        this.setValueForKey(value, 'mainSequence');
    }
    get mainSequence():NumberSerialSequence {
        return this.valueForKey('mainSequence') as NumberSerialSequence;
    }
    // Relationship: voidSequence
    set voidSequence(value:NumberSerialSequence) {
        this.setValueForKey(value, 'voidSequence');
    }
    get voidSequence():NumberSerialSequence {
        return this.valueForKey('voidSequence') as NumberSerialSequence;
    }
}
