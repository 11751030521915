/**
 * Created by crisan on 12/12/16.
 */

/// <reference path="DebtLine_ManagedObject.ts" />

class DebtLine extends DebtLine_ManagedObject
{     
    get pendingValue():number
    {
        let payedValue = this.payedValue || 0;
        return this.debtValue - payedValue;
    }

}