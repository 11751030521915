//
// Generated class RoomBooking
//

/// <reference path="RoomBooking_ManagedObject.ts" />

class RoomBooking extends RoomBooking_ManagedObject
{

}
