class BankMovementCell extends UITableViewCell
{
    private typeLabel:MUILabel = null;
    private dateLabel:MUILabel = null;
    private conceptLabel:MUILabel = null;
    private conceptTypelLabel:MUILabel = null;
    private amountLabel:MUILabel = null;
    private balanceLabel:MUILabel = null;
    private reconcileStatus:MUILabel = null;
    private reconcileButton:MUIButton = null;

    awakeFromHTML(){
        this.typeLabel = MUIOutlet(this, "type-lbl", "MUILabel");
        this.dateLabel = MUIOutlet(this, "date-lbl", "MUILabel");
        this.conceptLabel = MUIOutlet(this, "concept-lbl", "MUILabel");
        this.conceptTypelLabel = MUIOutlet(this, "concept-type-lbl", "MUILabel");
        this.amountLabel = MUIOutlet(this, "amount-lbl", "MUILabel");
        this.balanceLabel = MUIOutlet(this, "balance-lbl", "MUILabel");
        this.reconcileStatus = MUIOutlet(this, "status-lbl", "MUILabel");        
        this.reconcileButton = MUIOutlet(this, "reconcile-btn", "MUIButton");

        // this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private bankMovement:BankMovement = null;
    set item(item:BankMovement){
        this.bankMovement = item;

        let cf = MUIWebApplication.sharedInstance().delegate.currencyFormatter as MIONumberFormatter;
        let df = MUIWebApplication.sharedInstance().delegate.dateFormatter as MIODateFormatter;

        this.typeLabel.text = item.amount < 0 ? "&#8599;" : "&#8600;"
        this.dateLabel.text = df.stringFromDate(item.date);
        this.conceptLabel.text = item.concept;
        this.conceptTypelLabel.text = [ ( item.typeDescription ?? "" ), ( item.reference1 ?? "" ) ].filter( (item) => { return item.length > 0 ? true : false } ).join(" - ");
        this.amountLabel.text = cf.stringFromNumber(item.amount);
        this.balanceLabel.text = cf.stringFromNumber(item.balance);
        this.reconcileStatus.text = null;

        if (item.amount > 0) {
            MUICoreLayerAddStyle(this.typeLabel.layer, "active");
            MUICoreLayerAddStyle(this.dateLabel.layer, "active");
            MUICoreLayerAddStyle(this.conceptLabel.layer, "active");
            MUICoreLayerAddStyle(this.amountLabel.layer, "active");
            MUICoreLayerAddStyle(this.balanceLabel.layer, "active");
        }
        else if (item.amount < 0) {
            MUICoreLayerAddStyle(this.typeLabel.layer, "alert");
            MUICoreLayerAddStyle(this.dateLabel.layer, "alert");
            MUICoreLayerAddStyle(this.conceptLabel.layer, "alert");
            MUICoreLayerAddStyle(this.amountLabel.layer, "alert");
            MUICoreLayerAddStyle(this.balanceLabel.layer, "alert");
        }
        else {
            MUICoreLayerRemoveStyle(this.typeLabel.layer, "alert");
            MUICoreLayerRemoveStyle(this.dateLabel.layer, "alert");
            MUICoreLayerRemoveStyle(this.conceptLabel.layer, "alert");
            MUICoreLayerRemoveStyle(this.amountLabel.layer, "alert");
            MUICoreLayerRemoveStyle(this.balanceLabel.layer, "alert");
            MUICoreLayerRemoveStyle(this.typeLabel.layer, "active");
            MUICoreLayerRemoveStyle(this.dateLabel.layer, "active");
            MUICoreLayerRemoveStyle(this.conceptLabel.layer, "active");
            MUICoreLayerRemoveStyle(this.amountLabel.layer, "active");
            MUICoreLayerRemoveStyle(this.balanceLabel.layer, "active");
        }
    }

    private setupDropdown(button:MUIButton){
        if (button == null) return;

        button.setAction(this, function(){

            let allowNoSelectionCell = true;
            let allowMultipleSelection = false;

			let vc = AppHelper.sharedInstance().selectViewController("Dropdown", "name", allowNoSelectionCell, allowMultipleSelection, null, this) as SelectEntityViewController;
			vc.allowSearch = true;
            vc.modalPresentationStyle = MUIModalPresentationStyle.Popover;

            let pc = vc.popoverPresentationController;
            pc.sourceRect = button.frame;
            pc.sourceView = button;

            AppHelper.sharedInstance().presentViewController(vc, true);
        });        
    }

    fetchRequestForController(controller:SelectEntityViewController): MIOFetchRequest {

        let sd = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Tag", sd, "type == " + TagType.LedgerAccount);
        return fetchRequest;
    }

    didSelectObjectFromSelectViewController(controller:SelectEntityViewController, item:Tag){
        if (item != null) {
            this.tagDropdown.title = item.name;
            this.bankMovement.tag = item;
            this.bankMovement.tagName = item.name;
        }
        else {
            this.tagDropdown.title = MIOLocalizeString("SELECT TAG", "SELECT TAG");
            this.bankMovement.tag = null;
            this.bankMovement.tagName = null;
        }
        
        return true;
    } 
}