//
// Generated class AccountBalance
//

/// <reference path="AccountBalance_ManagedObject.ts" />

class AccountBalance extends AccountBalance_ManagedObject
{

}
