//
// Generated class Booking_BookingWorker
//

/// <reference path="Booking_BookingWorker_ManagedObject.ts" />

class Booking_BookingWorker extends Booking_BookingWorker_ManagedObject
{

}
