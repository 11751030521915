
class PaymentInstallmentViewController extends BaseTableViewController
{
    static newInstance() : PaymentInstallmentViewController
    {
        let vc = new PaymentInstallmentViewController( "payment-installments-view" );
        vc.initWithResource( "layout/paymentinstallments/PaymentInstallmentsView.html" );
        return vc;
    }

    private refreshButton:MUIButton = null;
    private calendarButton:MUIButton = null;
    private saveButton:MUIButton = null;
    private settingsButton:MUIButton = null;

    private beginDateString:string;
    private endDateString:string

    private columnFilterController:ColumnFilterController = null;
	
    private documentFilterTextField:ColumnFilterTextField = null;
	private dateFilterTextField:ColumnFilterTextField = null;
	private conceptFilterTextField:ColumnFilterTextField = null;
	private amountFilterTextField:ColumnFilterTextField = null;

    viewDidLoad(): void {
        super.viewDidLoad();

        this.columnFilterController = new ColumnFilterController();
		this.columnFilterController.initWithDelegate(this);

        this.documentFilterTextField = MUIOutlet(this, "document-cf-tf", "ColumnFilterTextField");
		this.documentFilterTextField.filterController = this.columnFilterController;
		this.documentFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "documentNumber", null, null);

        this.dateFilterTextField = MUIOutlet(this, "date-cf-tf", "ColumnFilterTextField");
		this.dateFilterTextField.filterController = this.columnFilterController;
		this.dateFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Date, "date", null, null);

        this.conceptFilterTextField = MUIOutlet(this, "concept-cf-tf", "ColumnFilterTextField");
		this.conceptFilterTextField.filterController = this.columnFilterController;
		this.conceptFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.String, "concept", null, null);

        this.amountFilterTextField = MUIOutlet(this, "amount-cf-tf", "ColumnFilterTextField");
		this.amountFilterTextField.filterController = this.columnFilterController;
		this.amountFilterTextField.setOnFilterChange(ColumnFilterTextFieldType.Currency, "amount", null, null);

        this.refreshButton = MUIOutlet( this, "refresh-btn", "MUIButton" );
        this.refreshButton.setAction( this, function(this:PaymentInstallmentViewController) {
            this.invalidateFetch();
        });

        this.calendarButton = MUIOutlet(this, 'calendar-btn', 'MUIButton');
        this.calendarButton.setAction(this, function(){            
            let ah:AppHelper = AppHelper.sharedInstance();
            ah.presentDateSelectionViewController(this);
        });

        this.saveButton = MUIOutlet( this, "save-btn", "MUIButton" );
        this.saveButton.setAction( this, function(){
            DBHelper.saveMainContext();
        } );

        // this.sectionsButton = MUIOutlet( this, "settings-btn", "MUIButton" );
        // this.sectionsButton.setAction( this, function(this:PaymentInstallmentViewController) {

        // });

        this.tableView = MUIOutlet( this, "table-view", "UITableView" );
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        let today = MIODateToday();        
        let m = MIODateGetMonthFromDate( today );
        let y = MIODateGetYearFromDate( today );
        let d = MIODateGetLastDayOfTheMonth( m, y );      
        let month = ( m + 1 ).toString();
        this.beginDateString = y + "-" + (month[1]?month:"0" + month[0]) + "-01 00:00:00";
        this.endDateString = y + "-" + (month[1]?month:"0" + month[0]) + "-" + d + " 23:59:59";
    }
    
    datesDidSelect(dateTimeFrom:Date, dateTimeTo:Date) {        
        this.beginDateString = MIODateGetString(dateTimeFrom);
        this.endDateString = MIODateGetString(dateTimeTo);
        this.invalidateFetch();
    }

    titleForHeaderInSection(tableView:MUITableView, section:number) {        
        let ip = MIOIndexPath.indexForRowInSection(0, section);
        let item = this.fetchedResultsController.objectAtIndexPath(ip) as PaymentInstallment;

        return MIODateGetStringForMonth( item.date.getMonth() );
    }

    cellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath( indexPath ) as PaymentInstallment;
                
        let cell = tableView.dequeueReusableCellWithIdentifier( "PaymentInstallmentCell" ) as InstallmentCell;
        cell.item = item;

        return cell;
    }    


    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey("date", true) ];
    
        let predicateFormat = "date >= '" + this.beginDateString + "' AND date <= '" + this.endDateString + "'";
        
        let filterPredicateFormat = this.columnFilterController.filterPredicateFormat();
        if (filterPredicateFormat != null){
            predicateFormat += " AND (" + filterPredicateFormat + ")";
        }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName( "LegalEntityPaymentInstallment", sortDescriptors, predicateFormat );
        fetchRequest.relationshipKeyPathsForPrefetching = [ "paymentMethod", "invoice" ];
    
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "monthString" );
        fetchedResultsController.delegate = this;

        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    filterPredicateDidChange(controller:ColumnFilterController){        
        this._fetchedResultsController = null;
        this.tableView.reloadData();        
    }

}