

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class CashDeskSession_ManagedObject

class CashDeskSession_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: beginDate
    set beginDate(value:Date) {
        this.setValueForKey(value, 'beginDate');
    }
    get beginDate():Date {
        return this.valueForKey('beginDate');
    }
    set beginDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'beginDate');
    }
    get beginDatePrimitiveValue():Date {
        return this.primitiveValueForKey('beginDate');
    }

    // Property: beginWorkerID
    set beginWorkerID(value:string) {
        this.setValueForKey(value, 'beginWorkerID');
    }
    get beginWorkerID():string {
        return this.valueForKey('beginWorkerID');
    }
    set beginWorkerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'beginWorkerID');
    }
    get beginWorkerIDPrimitiveValue():string {
        return this.primitiveValueForKey('beginWorkerID');
    }

    // Property: beginWorkerName
    set beginWorkerName(value:string) {
        this.setValueForKey(value, 'beginWorkerName');
    }
    get beginWorkerName():string {
        return this.valueForKey('beginWorkerName');
    }
    set beginWorkerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'beginWorkerName');
    }
    get beginWorkerNamePrimitiveValue():string {
        return this.primitiveValueForKey('beginWorkerName');
    }

    // Property: blindClosed
    set blindClosed(value:boolean) {
        this.setValueForKey(value, 'blindClosed');
    }
    get blindClosed():boolean {
        return this.valueForKey('blindClosed');
    }
    set blindClosedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'blindClosed');
    }
    get blindClosedPrimitiveValue():boolean {
        return this.primitiveValueForKey('blindClosed');
    }

    // Property: endDate
    set endDate(value:Date) {
        this.setValueForKey(value, 'endDate');
    }
    get endDate():Date {
        return this.valueForKey('endDate');
    }
    set endDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'endDate');
    }
    get endDatePrimitiveValue():Date {
        return this.primitiveValueForKey('endDate');
    }

    // Property: endWorkerID
    set endWorkerID(value:string) {
        this.setValueForKey(value, 'endWorkerID');
    }
    get endWorkerID():string {
        return this.valueForKey('endWorkerID');
    }
    set endWorkerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'endWorkerID');
    }
    get endWorkerIDPrimitiveValue():string {
        return this.primitiveValueForKey('endWorkerID');
    }

    // Property: endWorkerName
    set endWorkerName(value:string) {
        this.setValueForKey(value, 'endWorkerName');
    }
    get endWorkerName():string {
        return this.valueForKey('endWorkerName');
    }
    set endWorkerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'endWorkerName');
    }
    get endWorkerNamePrimitiveValue():string {
        return this.primitiveValueForKey('endWorkerName');
    }

    // Property: idIn
    set idIn(value:number) {
        this.setValueForKey(value, 'idIn');
    }
    get idIn():number {
        return this.valueForKey('idIn');
    }
    set idInPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'idIn');
    }
    get idInPrimitiveValue():number {
        return this.primitiveValueForKey('idIn');
    }

    // Property: inbalance
    set inbalance(value:number) {
        this.setValueForKey(value, 'inbalance');
    }
    get inbalance():number {
        return this.valueForKey('inbalance');
    }
    set inbalancePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'inbalance');
    }
    get inbalancePrimitiveValue():number {
        return this.primitiveValueForKey('inbalance');
    }

    // Property: isBlind
    set isBlind(value:boolean) {
        this.setValueForKey(value, 'isBlind');
    }
    get isBlind():boolean {
        return this.valueForKey('isBlind');
    }
    set isBlindPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isBlind');
    }
    get isBlindPrimitiveValue():boolean {
        return this.primitiveValueForKey('isBlind');
    }

    // Property: isCashDeskCounted
    set isCashDeskCounted(value:boolean) {
        this.setValueForKey(value, 'isCashDeskCounted');
    }
    get isCashDeskCounted():boolean {
        return this.valueForKey('isCashDeskCounted');
    }
    set isCashDeskCountedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isCashDeskCounted');
    }
    get isCashDeskCountedPrimitiveValue():boolean {
        return this.primitiveValueForKey('isCashDeskCounted');
    }

    // Property: isManager
    set isManager(value:boolean) {
        this.setValueForKey(value, 'isManager');
    }
    get isManager():boolean {
        return this.valueForKey('isManager');
    }
    set isManagerPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isManager');
    }
    get isManagerPrimitiveValue():boolean {
        return this.primitiveValueForKey('isManager');
    }

    // Property: isOpen
    set isOpen(value:boolean) {
        this.setValueForKey(value, 'isOpen');
    }
    get isOpen():boolean {
        return this.valueForKey('isOpen');
    }
    set isOpenPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isOpen');
    }
    get isOpenPrimitiveValue():boolean {
        return this.primitiveValueForKey('isOpen');
    }

    // Property: serverSessionBeginDate
    set serverSessionBeginDate(value:Date) {
        this.setValueForKey(value, 'serverSessionBeginDate');
    }
    get serverSessionBeginDate():Date {
        return this.valueForKey('serverSessionBeginDate');
    }
    set serverSessionBeginDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'serverSessionBeginDate');
    }
    get serverSessionBeginDatePrimitiveValue():Date {
        return this.primitiveValueForKey('serverSessionBeginDate');
    }

    // Property: serverSessionID
    set serverSessionID(value:string) {
        this.setValueForKey(value, 'serverSessionID');
    }
    get serverSessionID():string {
        return this.valueForKey('serverSessionID');
    }
    set serverSessionIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'serverSessionID');
    }
    get serverSessionIDPrimitiveValue():string {
        return this.primitiveValueForKey('serverSessionID');
    }

    // Property: serverSessionIntervalID
    set serverSessionIntervalID(value:string) {
        this.setValueForKey(value, 'serverSessionIntervalID');
    }
    get serverSessionIntervalID():string {
        return this.valueForKey('serverSessionIntervalID');
    }
    set serverSessionIntervalIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'serverSessionIntervalID');
    }
    get serverSessionIntervalIDPrimitiveValue():string {
        return this.primitiveValueForKey('serverSessionIntervalID');
    }

    // Property: serverSessionName
    set serverSessionName(value:string) {
        this.setValueForKey(value, 'serverSessionName');
    }
    get serverSessionName():string {
        return this.valueForKey('serverSessionName');
    }
    set serverSessionNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'serverSessionName');
    }
    get serverSessionNamePrimitiveValue():string {
        return this.primitiveValueForKey('serverSessionName');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }
    // Relationship: cashDesk
    set cashDesk(value:CashDesk) {
        this.setValueForKey(value, 'cashDesk');
    }
    get cashDesk():CashDesk {
        return this.valueForKey('cashDesk') as CashDesk;
    }

    // Relationship: cashDeskLines
    protected _cashDeskLines:MIOManagedObjectSet = null;
    get cashDeskLines():MIOManagedObjectSet {
        return this.valueForKey('cashDeskLines');
    }
    addCashDeskLinesObject(value:CashDeskLine) {
        this._addObjectForKey(value, 'cashDeskLines');
    }
    removeCashDeskLinesObject(value:CashDeskLine) {
        this._removeObjectForKey(value, 'cashDeskLines');
    }

    // Relationship: checkPoints
    protected _checkPoints:MIOManagedObjectSet = null;
    get checkPoints():MIOManagedObjectSet {
        return this.valueForKey('checkPoints');
    }
    addCheckPointsObject(value:CheckPoint) {
        this._addObjectForKey(value, 'checkPoints');
    }
    removeCheckPointsObject(value:CheckPoint) {
        this._removeObjectForKey(value, 'checkPoints');
    }
    // Relationship: sessionBalance
    set sessionBalance(value:CashDeskSessionBalance) {
        this.setValueForKey(value, 'sessionBalance');
    }
    get sessionBalance():CashDeskSessionBalance {
        return this.valueForKey('sessionBalance') as CashDeskSessionBalance;
    }
}
