


class BookingsZonesSetupSubCell extends MUITableViewCell
{
    private nameTextField:MUITextField = null;
    private priceTextField:MUITextField = null;
    private chargePriceTextField:MUITextField = null;
    private paxTextField:MUITextField = null;    

    private nf:MIONumberFormatter = MUIWebApplication.sharedInstance().delegate.numberFormatter ;
    private cf:MIONumberFormatter = MUIWebApplication.sharedInstance().delegate.currencyFormatter;

    awakeFromHTML(){
        this.nameTextField = MUIOutlet(this, 'name-tf', 'MUITextField');
        this.nameTextField.setOnChangeText(this, function(textfield, value){
            this.item.name = value;
        });

        this.priceTextField = MUIOutlet(this, 'price-tf', 'MUITextField');
        this.priceTextField.formatter = this.cf;
        this.priceTextField.setOnChangeText(this, function(textfield, value){
            this.item.price = this.cf.numberFromString(value) | 0;
        });

        this.chargePriceTextField = MUIOutlet(this, "charge-price-tf", "MUITextField");
        this.chargePriceTextField.setOnChangeText(this, function(textfield, value){
            this.item.chargePrice = value; //this.cf.numberFromString(value) | 0;
        });

        this.paxTextField = MUIOutlet(this, 'max-pax-tf', 'MUITextField');
        this.paxTextField.formatter = this.nf;
        this.paxTextField.setOnChangeText(this, function(textfield, value){
            this.item.maxPax = this.nf.numberFromString(value) | 0;
        });        
    }

    private _item:BookingZone = null;
    get item () {return this._item;}
    set item(i:BookingZone){
        this._item = i;
        this.nameTextField.text = i.name;
        // this.priceTextField.text = this.nf.stringFromNumber(i.price);
        // this.chargePriceTextField.text = i.chargePrice; //this.nf.stringFromNumber(i.chargePrice);
        // this.paxTextField.text = this.nf.stringFromNumber(i.maxPax);
    }
}
