//
// Generated class CommunicationTemplate
//

/// <reference path="CommunicationTemplate_ManagedObject.ts" />

class CommunicationTemplate extends CommunicationTemplate_ManagedObject
{

}
