class WorkerAdminViewController extends BaseTableViewController
{
    private addButton:MUIButton = null;
    private saveButton:MUIButton = null;
    private actionButton:MUIButton = null;    
    private segmentedControl:MUISegmentedControl = null;
    private segmentedControlSection:MUISegmentedControl = null;

	private permissionsTableView:UITableView = null;

    private posPermissionsDataSource:WorkersAdminPOSDataSource = null;    
    private bookingPermissionsDataSource:WorkersAdminBookingDataSource = null;

    private workerDataSource:WorkerDataSource = null;
    private workerRoleDataSource:WorkerRoleDataSource = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, this.addAction);
        this.addButton.hidden = true;

        this.saveButton = MUIOutlet(this, "save-btn", "MUIButton");
        this.saveButton.setAction(this, function(){
            DBHelper.saveMainContext();
        });        

        this.actionButton = MUIOutlet(this, "action-btn", "MUIButton");
        this.actionButton.setAction(this, function(){
            this.showActions();
        });

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();

        this.segmentedControlSection = MUIOutlet(this, "segmented-control-section", "MUISegmentedControl");
        this.segmentedControlSection.setAction(this, function(control, index){
            this.changeSectionDataSource();
		}); 

        this.tableView = MUIOutlet(this, "worker-table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.workerDataSource = new WorkerDataSource();
        this.workerDataSource.initWithTableView(this.tableView);
        this.tableView.dataSource = this.workerDataSource;

        this.workerRoleDataSource = new WorkerRoleDataSource();
        this.workerRoleDataSource.initWithTableView(this.tableView);

        this.needsReloadData();
        
        this.segmentedControl = MUIOutlet(this, "segmented-control", "MUISegmentedControl");
        this.segmentedControl.setAction(this, function(control, index){
            this.changeDataSource();
        }); 

        this.permissionsTableView = MUIOutlet(this, "permissions-table-view", "UITableView");        

        this.posPermissionsDataSource = new WorkersAdminPOSDataSource();
        this.posPermissionsDataSource.initWithTableView(this.permissionsTableView);

        this.bookingPermissionsDataSource = new WorkersAdminBookingDataSource();
        this.bookingPermissionsDataSource.initWithTableView(this.permissionsTableView);

        MIONotificationCenter.defaultCenter().addObserver(this, "EmployeePermissionsDidChange", function(notification:MIONotification) {
            let employee = notification.object as Employee;
            this.updateEmployeePermissions(employee);
        });

        //this.registerWorkers();
    }    

    didSelectCellAtIndexPath(tableView:MUITableView, indexPath:MIOIndexPath){
        this.selectedItem = this.tableView.dataSource.fetchedResultsController.objectAtIndexPath(indexPath);
        this.changeDataSource();
    }

    editingStyleForRowAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath) {   
        return MUITableViewCellEditingStyle.Delete;
    }

    commitEditingStyleForRowAtIndexPath(tableView:UITableView, editingStyle:MUITableViewCellEditingStyle, indexPath:MIOIndexPath) {        

        if (editingStyle == MUITableViewCellEditingStyle.Delete) {
            let role = this.fetchedResultsController.objectAtIndexPath(indexPath);
            DBHelper.deleteObjectFromMainContext(role, true);
        }
    }      

    private updateEmployeePermissions(employee:Employee){
        if (this.selectedItem != employee) return;

        this.permissionsTableView.reloadData();
    }

    invalidateFetch() {
        super.invalidateFetch();
        this.workerDataSource.searchString = this.searchString;
    }
    
    private showActions(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('ACTIONS','ACTIONS'), MIOLocalizeString('CHOOSE AN ACTION','CHOOSE AN ACTION'), MUIAlertViewStyle.Default);
        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('IMPORT WORKERS FROM EXCEL (CSI)','Import workers from Excel (CSI)'), MUIAlertActionStyle.Default, this, function(){
            this.openPanel();
        }));

        // avc.addAction(MUIAlertAction.alertActionWithTitle('Test', MUIAlertActionStyle.Default, this, function(){
        //     //this.deleteall();
        //     // let item = {};
        //     // item["codigoCSI"] = "1234";
        //     // item["nombre"] = "TEST";
        //     // item["ExtraData"] = "EXTRA DATA";
        //     // this.importEmployee(item);

        //     //this.getDeparments();
        //     this.getCSIWorkers();
        // }));

        // avc.addAction(MUIAlertAction.alertActionWithTitle('Test2', MUIAlertActionStyle.Default, this, function(){
        //     this.getCards();
        // }));


        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','Cancel'), MUIAlertActionStyle.Cancel, null, null));

        
        this.presentViewController(avc, true);
    }

    private changeDataSource(){
        if (this.selectedItem == null) return;
        
        switch (this.segmentedControl.selectedSegmentedIndex){
            case 0:
            this.permissionsTableView.dataSource = this.posPermissionsDataSource;
            this.permissionsTableView.delegate = this.posPermissionsDataSource;  
            this.posPermissionsDataSource.employee = this.segmentedControlSection.selectedSegmentedIndex == 0 ? this.selectedItem : null;
            this.posPermissionsDataSource.role = this.segmentedControlSection.selectedSegmentedIndex == 1 ? this.selectedItem : null;
            break;
            
            case 1:
            this.permissionsTableView.dataSource = this.bookingPermissionsDataSource;
            this.permissionsTableView.delegate = this.bookingPermissionsDataSource;
            this.bookingPermissionsDataSource.employee = this.selectedItem;            
            break;
        }
        this.permissionsTableView.reloadData();
    }

    private changeSectionDataSource() {

        switch (this.segmentedControlSection.selectedSegmentedIndex){
            case 0: //workers
                this.tableView.dataSource = this.workerDataSource;
                this.addButton.hidden = true;                          
            break;
            
            case 1: //permission by role
                this.tableView.dataSource = this.workerRoleDataSource;
                this.addButton.hidden = false;
            break;
        }
        this.tableView.reloadData();
    }

    private addAction(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle(MIOLocalizeString('ADD ROLE','ADD ROLE'), MIOLocalizeString('ADD ROLE NAME','ADD ROLE NAME'), MUIAlertViewStyle.Default);

        avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField) {
            textField.placeHolder = MIOLocalizeString("ROLE NAME", "ROLE NAME");
        });

        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('OK','OK'), MUIAlertActionStyle.Default, this, function(this:WorkerAdminViewController){
            let name = avc.textFields[0].text;
            this.addRole(name);
        }));


        avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, null, null));

        
        this.presentViewController(avc, true);
    }

    private addRole(name:string){
        if (name == null || name?.length == 0){
            AppHelper.showErrorMessage(this, "ERROR IN ROLE", "THE ROLES NEEDS TO HAVE A NAME.");
            return;
        }

        let moc = (MUIWebApplication.sharedInstance().delegate as AppDelegate).managedObjectContext;

        let role = MIOEntityDescription.insertNewObjectForEntityForName("WorkerRole", moc) as WorkerRole;
        role.name = name;

        moc.save();
    }

    //
    //
    //
    //
    // FIX: Imnport workers only PACHA. remove from here
    //
    //

    /*
    private openPanel(){
        let panel = MUIOpenPanel.openPanel();
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        panel.beginSheetModalForWindow(ad.window, this, function(result:number){
            if (result == MIOFileHandlingPanel.OKButton) {
               let file = panel.files[0];
               // Open  the document.
                this.importWorkersCSI(file);
            }       
         });      
    }


    private importWorkersCSI(file){
        let reader = new FileReader();

        let instance = this;
        reader.onload = function(e) {
            let data = reader.result;
            let workbook = XLSX.read(data, {
                type: 'binary'
            });
        
            workbook.SheetNames.forEach(function(sheetName) {
                // Here is your object
                let worksheet = workbook.Sheets[sheetName];                
                let result = [];
                let row;
                let rowNum;
                let colNum;
                let range = XLSX.utils.decode_range(worksheet['!ref']);
                for(rowNum = range.s.r; rowNum <= range.e.r; rowNum++){
                   row = [];
                    for(colNum=range.s.c; colNum<=range.e.c; colNum++){
                       let nextCell = worksheet[
                          XLSX.utils.encode_cell({r: rowNum, c: colNum})
                       ];
                       if( typeof nextCell === 'undefined' ){
                          row.push(void 0);
                       } else row.push(nextCell.w);
                    }
                    result.push(row);
                }                
                instance.importRows.call(instance, result);
            })
        };

        reader.onerror = function(ex) {
            console.log(ex);
        };
      
        reader.readAsBinaryString(file);      
    }

    private importRows(rows){
        let isProductRow = false;

        let items = [];

        for (let rowIndex = 1; rowIndex < rows.length; rowIndex++){
            let row = rows[rowIndex];                        
            let item = this.parseRow(row);
            items.push(item);
        }
        
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        ad.webService.importWorkersCSI(items, this, function(code, data){

        });        
    }

    private parseRow(row){

        let item = {};
        
        let name = row[0];
        let department = row[1];
        let email = row[4];
        let document = row[8];
        let card = AppHelper.sharedInstance().eanCode(PromotionalCodeType.EAN8, null, row[11]);
        let isActive = row[13];
        let csiCode = row[16];
        let place = row[18];
        

        item["name"] = name;
        item["department"] = department;
        if (email.length > 0) item["email"] = email;
        if (document.length > 0) item["document"] = document;
        item["isavailable"] = isActive == "SI" ? true : false;
        item["integratorid"] = csiCode;
        if (place == "3") item["externalplaceid"] = place;
        if (card != null) item["cardcode"] = card;
        
        return item;                
    }


    private registerWorkers(){
        DLRemoteNotificationServer.sharedInstance().addObserver(this, "WorkersFromCSI", function(data){
            this.totalImported++;
            //this.importWorkers(data);
            this.importEmployee(data);
        });
    }

    //private isImporting = false;
    private totalImported = 0;
    private getWorkers(){           
        // this.getDeparments(this, function(departments){
            this.totalImported = 0;
            DLRemoteNotificationServer.sharedInstance().postNotification("GetWorkersFromCSI", "SELECT * FROM vw_trabajadores ORDER BY nombre");    
        // });        
    }

    private importEmployee(item){

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let map = MIOEntityDescription.insertNewObjectForEntityForName("DBMappingEntity", ad.managedObjectContext) as DBMappingEntity;
        map.name = item["nombre"];
        map.foreignName = item["nombre"];
        map.foreignID = item["codigoCSI"];
        map.enviroment = "PRO";
        map.integrator = "CSI";
        map.integratorEntity = "WORKERS";
        map.extra = item;

        ad.managedObjectContext.save();

        // LOG
        let department = item["departamento"];
        let place = item["centroTrabajo"];

        MIOLog(this.totalImported + ": CSI WORKER DATA: " + name + ", " +  department + ", " + place);
    }
    
    private departments = {};
    private getDeparments(){
        DBHelper.queryObjectsWithCompletion("Department", null, null, null, this, function(objects){
            this.departments = {};
            for (let index = 0; index < objects.length; index++){
                let d = objects[index] as Department;
                this.departments[d.name] = d;
            }           
            
            this.getCSIWorkers();
        });
    }

    private workers = [];
    private getCSIWorkers(){

        let predicate = "integrator == 'CSI' AND enviroment == 'PRO' AND integratorEntity == 'WORKERS'";
        DBHelper.queryObjectsWithCompletion("DBMappingEntity", null, MIOPredicate.predicateWithFormat(predicate), null, this, function(objects){
            this.workers = objects;
            this.workerCount = 0;
            // for (let index = 0; index < objects.length; index++){
            //     let me = objects[index] as DBMappingEntity;
            //     //this.workers.push(me.extra);
            //     this.importWorkers(me.extra);
            // }

            this.delayWorker();
        });
    }

    private workerCount = 0;
    private workerTimer:MIOTimer = null;

    private delayWorker(){
        if (this.workerCount < this.workers.length) {                    
            let me = this.workers[this.workerCount] as DBMappingEntity;
            this.importWorkers(me.extra);
            this.workerCount++;

            this.workerTimer = MIOTimer.scheduledTimerWithTimeInterval(500, false, this, function(timer){
                this.delayWorker();
            });
        }
    }

    private cards = [];
    private getCards(){
        DBHelper.queryObjectsWithCompletion("Cards", null, null, ['offerRules'], this, function(objects){
            this.cards = [];
            for (let index = 0; index < objects.length; index++){
                let c = objects[index] as Card;
                //this.workers.push(me.extra);
                this.checkCard(c);
            }
        });
    }

    private importWorkers(item){
        
        let name = item["nombre"];
        let department = item["departamento"];
        let place = item["centroTrabajo"];

        MIOLog(this.totalImported + ": CSI WORKER DATA: " + name + ", " +  department + ", " + place);

        if (place == 3 || place == 4) {
            MIOLog("CARD: WORKER");  
            //this.checkWorkerCard(item);
            this.insertWorkerCard(item);

        } else {
            MIOLog("CARD: EXTERNAL");
            //this.checkExternalCard(item);
            this.insertExternalCard(item);
        }
        
    }

    private objectWithPredicateFormat(predicateFormat:string){
        let objs = _MIOPredicateFilterObjects(this.fetchedResultsController.fetchedObjects, MIOPredicate.predicateWithFormat(predicateFormat));
        if (objs != null && objs.length > 0) return objs[0];

        return null;
    }

    private checkWorkerCard(item){
        let active = item["activo"] == "SI" ? true : false;
        let name = item["nombre"];
        let CSICode = item["codigoCSI"];

        let obj = null;
        if (CSICode != null) {
            obj = this.objectWithPredicateFormat("syncID == '" + CSICode + "'");
            if (obj != null) {
                this.updateWorkerCard(obj, item);
                return;
            }    
        }

        obj = this.objectWithPredicateFormat("name == '" + name + "'");
        if (obj != null) {
            this.updateWorkerCard(obj, item);
            return;
        }

        this.insertWorkerCard(item);
    }

    private updateWorkerCard(card:Card, item){

        let active = item["activo"] == "SI" ? true : false;
        let department = item["departamento"];
        let jobTitle = item["puestoTrabajo"];
        let place = item["centroTrabajo"];
        let CSICode = item["codigoCSI"];
        let code1 = item["codigoTarjeta"]; // CHECK EAN
        let code2 = item["codigoPulsera"];
        
        card.syncID = CSICode;
        card.barcode = AppHelper.sharedInstance().eanCode(8, null, code1);
        //card.barcode2 = code2;                   

        DBHelper.saveMainContext();

        MIOLog("CARD: WORKER: UPDATED");  
    }

    private insertWorkerCard(item){
        
        let active = item["activo"] == "SI" ? true : false;
        let name = item["nombre"];
        let department = item["departamento"];
        let jobTitle = item["puestoTrabajo"];
        let place = item["centroTrabajo"];
        let CSICode = item["codigoCSI"];
        let code1 = item["codigoTarjeta"]; // CHECK EAN
        let code2 = item["codigoPulsera"];

        if (active == false) return;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let card = MIOEntityDescription.insertNewObjectForEntityForName("Card", ad.managedObjectContext) as Card;

        card.name = name;
        card.syncID = CSICode;
        card.cardType = CardType.None;
        card.barcode = AppHelper.sharedInstance().eanCode(8, null, code1);        
        card.externalPlaceID = place;

        let destinoID = "8DA2ED45-13C7-438D-ADDC-9F6A6F51B5D0";
        let hotelID = "FD357C3B-B710-48EF-B149-87B908A1F855";
        if (place == 3) { 
            // DESTINO
            this.createInternalOfferForPlace(destinoID, card);
            this.createExternalOfferForPlace(hotelID, card);            
        }
        else if (place == 4) {
            // PACHA
            this.createInternalOfferForPlace(hotelID, card);
            this.createExternalOfferForPlace(destinoID, card);
        }

        ad.managedObjectContext.save();   

        MIOLog("CARD: WORKER: INSERTED");
    }

    private checkExternalCard(item){
        let name = item["nombre"];
        let CSICode = item["codigoCSI"];

        let obj = null;
        if (CSICode != null) {
            obj = this.objectWithPredicateFormat("syncID == " + CSICode);
            if (obj != null) {
                this.updateWorkerCard(obj, item);
                return;
            }    
        }

        obj = this.objectWithPredicateFormat('name == "' + name + '"');
        if (obj != null) {
            this.updateExternalCard(obj, item);
            return;
        }

        this.insertExternalCard(item);
    }

    private updateExternalCard(card:Card, item){

        let active = item["activo"] == "SI" ? true : false;
        let department = item["departamento"];
        let jobTitle = item["puestoTrabajo"];
        let place = item["centroTrabajo"];
        let CSICode = item["codigoCSI"];
        let code1 = item["codigoTarjeta"]; // CHECK EAN
        let code2 = item["codigoPulsera"];
        
        if (active == true) {
            card.syncID = CSICode;
            card.barcode = AppHelper.sharedInstance().eanCode(8, null, code1);
            MIOLog("CARD: EXTERNAL: UPDATE");
        }
        else {
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            ad.managedObjectContext.deleteObject(card);
            MIOLog("CARD: EXTERNAL: DELETED");
        }

        DBHelper.saveMainContext();        
    }

    private insertExternalCard(item){

        let active = item["activo"] == "SI" ? true : false;
        let name = item["nombre"];
        let department = item["departamento"];
        let jobTitle = item["puestoTrabajo"];
        let place = item["centroTrabajo"];
        let CSICode = item["codigoCSI"];
        let code1 = item["codigoTarjeta"]; // CHECK EAN
        let code2 = item["codigoPulsera"];

        if (active == false) return;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        let card = MIOEntityDescription.insertNewObjectForEntityForName("Card", ad.managedObjectContext) as Card;

        card.name = name;
        card.syncID = CSICode;        
        card.cardType = CardType.External;
        card.barcode = AppHelper.sharedInstance().eanCode(8, null, code1);        
        card.externalPlaceID = place;

        let destinoID = "8DA2ED45-13C7-438D-ADDC-9F6A6F51B5D0";
        let hotelID = "FD357C3B-B710-48EF-B149-87B908A1F855";

        this.createExternalOfferForPlace(destinoID, card);
        this.createExternalOfferForPlace(hotelID, card);

        ad.managedObjectContext.save();

        MIOLog("CARD: EXTERNAL: INSERTED");
    }

    private createExternalOfferForPlace(placeID:string, card:Card){

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        // Create offers
        let rule = MIOEntityDescription.insertNewObjectForEntityForName("OfferRule", ad.managedObjectContext) as OfferRule;
        rule.conditionType = OfferRuleConditionType.IsPartyActive;
        rule.conditionValue = "false";
        rule.discount = 30;
        rule.invitationLimitInSession = 0;
        rule.economicEntity = card;
        rule.placeID = placeID;
        card.addOfferRulesObject(rule);

        let rule2 = MIOEntityDescription.insertNewObjectForEntityForName("OfferRule", ad.managedObjectContext) as OfferRule;
        rule2.conditionType = OfferRuleConditionType.IsPartyActive;
        rule2.conditionValue = "true";
        rule2.discount = 0;
        rule2.invitationLimitInSession = 2;
        rule2.economicEntity = card;
        rule2.placeID = placeID;
        card.addOfferRulesObject(rule2);
    }

    private createInternalOfferForPlace(placeID:string, card:Card){

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
        // Create offers
        let rule = MIOEntityDescription.insertNewObjectForEntityForName("OfferRule", ad.managedObjectContext) as OfferRule;
        rule.conditionType = OfferRuleConditionType.IsPartyActive;
        rule.conditionValue = "false";
        rule.discount = 30;
        rule.invitationLimitInSession = 2;
        rule.economicEntity = card;
        rule.placeID = placeID;
        card.addOfferRulesObject(rule);

        let rule2 = MIOEntityDescription.insertNewObjectForEntityForName("OfferRule", ad.managedObjectContext) as OfferRule;
        rule2.conditionType = OfferRuleConditionType.IsPartyActive;
        rule2.conditionValue = "true";
        rule2.discount = 0;
        rule2.invitationLimitInSession = 2;
        rule2.economicEntity = card;
        rule2.placeID = placeID;
        card.addOfferRulesObject(rule2);
    }
    */

}
