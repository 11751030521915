
class SubTaxCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    private quantityLabel:MUILabel = null;
    private showOnTicketSwitch:MUISwitchButton = null;
    private applyOn:MUILabel = null;

    private pnf = MUIWebApplication.sharedInstance().delegate.percentNumberFormatter as MIONumberFormatter;
    
    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, 'subtax-name-lbl', 'MUILabel');        
        this.quantityLabel = MUIOutlet(this, 'subtax-value-lbl', 'MUILabel');
        this.showOnTicketSwitch = MUIOutlet(this, 'show-on-ticket-sw', 'MUISwitchButton');
        this.showOnTicketSwitch.setOnChangeValue(this, function(control, value:boolean){
            this._item.showInTicket = value;
        });
        this.applyOn = MUIOutlet(this, 'apply-on-lbl', 'MUILabel');


        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }
    
    private _item:SubTax = null;
    set item(item:SubTax){
        this._item = item;
        this.nameLabel.text = item.name;
        let tax = item.taxQuantity * 100;
        this.quantityLabel.text = this.pnf.stringFromNumber(tax);
        this.showOnTicketSwitch.on = item.showInTicket;
        this.applyOn.text = '';
    }
}
