class DBBookingsCoder extends DBXLSXCoder
{
    appID:string = null;

    mainEntity() {
        return { entity: "Booking", predicateFormat: "deletedAt == null" }
    }

    columns() : any[] {
        let cols = [
            { title:"BOOKING GROUP ID", width: "10%", align: "center" },
            { title:"BOOKING ID", width: "10%", align: "center" },
            { title:"BOOKING NAME", width: "10%", align: "center" },
            { title:"CLIENT ID", width: "10%", align: "center" },
            { title:"CLIENT NAME", width: "10%", align: "center" },
            { title:"CHANNEL ID", width: "10%", align: "center" },
            { title:"CHANNEL NAME", width: "10%", align: "center" },
            { title:"SOURCE ID", width: "10%", align: "center" },
            { title:"SOURCE NAME", width: "10%", align: "center" },
            { title:"SOURCE EMAIL", width: "10%", align: "center" },
            { title:"SOURCE PHONE", width: "10%", align: "center" },
            { title:"STATUS", width: "10%", align: "center", formatter: this.ad.integerFormatter},
            { title:"DATE", width: "10%", align: "center" },
            { title:"DAY", width: "10%", align: "center" },
            { title:"HOUR", width: "10%", align: "center" },
            { title:"COMMENTS", width: "10%", align: "center" },
            { title:"CLIENT COMMENTS", width: "10%", align: "center" },
            { title:"PRIVATE COMMENTS", width: "10%", align: "center" },
            { title:"CREATED DATE", width: "10%", align: "center" },
            { title:"PAX", width: "10%", align: "center", formatter: this.ad.integerFormatter },
            { title:"PAX ATTENDED", width: "10%", align: "center", formatter: this.ad.integerFormatter },
            { title:"PAX INVITED", width: "10%", align: "center", formatter: this.ad.integerFormatter },
            { title:"COMMENTS", width: "10%", align: "center" },
            { title:"MINIMUM FEE", width: "10%", align: "center", formatter: this.ad.currencyFormatter},
            { title:"PRICE", width: "10%", align: "center", formatter: this.ad.currencyFormatter },
            { title:"IS WALKING", width: "10%", align: "center" },
            { title:"CLIENT NAME", width: "10%", align: "center" },
            { title:"CLIENT PHONE 1", width: "10%", align: "center" },
            { title:"CLIENT PHONE 2", width: "10%", align: "center" },
            { title:"CLIENT EMAIL", width: "10%", align: "center" },
            { title:"CLIENT COUNTRY", width: "10%", align: "center" },
            { title:"CLIENT POSTAL CODE", width: "10%", align: "center" },
            { title:"EXTERNAL REFERENCE", width: "10%", align: "center" },
            { title:"ZONE ID", width: "10%", align: "center" },
            { title:"ZONE NAME", width: "10%", align: "center" },
            { title:"DURATION", width: "10%", align: "center", formatter: this.ad.integerFormatter },
            { title:"RECOMENDATION ID", width: "10%", align: "center" },
            { title:"RECOMENDATION NAME", width: "10%", align: "center" }
        ]
        return cols;
    }

    aditionalImportEntities(){
        return [            
            { entity: "BookingGroup", predicateFormat: "deletedAt == null" },
            { entity: "Client", predicateFormat: "deletedAt == null", relations: ["address", "phone", "mobilePhone"] },
            { entity: "BookingChannel", predicateFormat: "deletedAt == null" },
            { entity: "BookingSource", predicateFormat: "deletedAt == null" },
            { entity: "BookingRecommendation", predicateFormat: "deletedAt == null" },
            { entity: "BookingZone", predicateFormat: "deletedAt == null" }
        ]
    }


    protected importRow(row:any){

        let bookingGroupID = row["BOOKING GROUP ID"];
        let bookingID = row["BOOKING ID"];
        let bookingName = row["BOOKING NAME"];
        let clientID = row["CLIENT ID"];
        let clientName = row["CLIENT NAME"];
        let channelID = row["CHANNEL ID"];
        let channelName = row["CHANNEL NAME"];
        let sourceID = row["SOURCE ID"];
        let sourceName = row["SOURCE NAME"];
        let sourceEmail = row["SOURCE EMAIL"];
        let sourcePhone = row["SOURCE PHONE"];
        let status = row["STATUS"];
        let date = row["DATE"];
        let day = row["DAY"];
        let hour = row["HOUR"];
        let comments = row["COMMENTS"];
        let clientComments = row["CLIENT COMMENTS"];
        let privateComments = row["PRIVATE COMMENTS"];
        let createdDate = row["CREATED DATE"];
        let pax = row["PAX"];
        let paxAttended = row["PAX ATTENDED"];
        let paxInvited = row["PAX INVITED"];        
        let minFee = row["MINIMUM FEE"];
        let price = row["PRICE"];
        let isWalking = row["IS WALKING"];
        let clientPhone1 = row["CLIENT PHONE 1"];
        let clientPhone2 = row["CLIENT PHONE 2"];
        let clientEmail = row["CLIENT EMAIL"];
        let clientCountry = row["CLIENT COUNTRY"];
        let clientPostalCode = row["CLIENT POSTAL CODE"];
        let extenalReference = row["EXTERNAL REFERENCE"];
        let zoneID = row["ZONE ID"];
        let zoneName = row["ZONE NAME"];
        let duration = row["DURATION"];
        let recommendationID = row["RECOMENDATION ID"];
        let recommendationName = row["RECOMENDATION NAME"];
        
        if (clientName == null) return;

        let client = this.queryEntityByIDOrName("Client", clientID, clientName) as Client;
        if (client == null) {
            client = MIOEntityDescription.insertNewObjectForEntityForName("Client", this.ad.managedObjectContext) as Client;
            client.identifier = this.parseOrCreateIdentifier(clientID);
            client.name = clientName;
            client.email = clientEmail;
            if (clientPhone1 != null) {
                client.mobilePhone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", this.ad.managedObjectContext) as PhoneNumber;
                client.mobilePhone.number = clientPhone1;
            }
            if (clientPhone2 != null) {
                client.phone = MIOEntityDescription.insertNewObjectForEntityForName("PhoneNumber", this.ad.managedObjectContext) as PhoneNumber;
                client.phone.number = clientPhone2;
            }
            this.appendObject(client);
        }

        if (channelID == "-1") channelID = null; 

        let channel = this.queryEntityByIDOrName("BookingChannel", channelID, channelName) as BookingChannel;
        if (channel == null && channelName != null) {
            channel = MIOEntityDescription.insertNewObjectForEntityForName("BookingChannel", this.ad.managedObjectContext) as BookingChannel;
            channel.identifier = this.parseOrCreateIdentifier(channelID);
            channel.name = channelName;            
            this.appendObject(channel);
        }

        let source = this.queryEntityByIDOrName("BookingSource", sourceID, sourceName) as BookingSource;
        if (source == null && sourceName != null) {
            source = MIOEntityDescription.insertNewObjectForEntityForName("BookingSource", this.ad.managedObjectContext) as BookingSource;
            source.identifier = this.parseOrCreateIdentifier(sourceID);
            this.appendObject(source);
        }
        
        if (source != null && source.name == null) source.name = sourceName;
        if (source != null && source.email == null) source.email = sourceEmail;
        if (source != null && source.phone == null)  source.phone = sourcePhone;

        let recomendation = this.queryEntityByField("BookingRecommendation", "identifier", bookingGroupID) as BookingRecommendation;
        if (recomendation == null && recommendationName != null) {
            recomendation = MIOEntityDescription.insertNewObjectForEntityForName("BookingRecommendation", this.ad.managedObjectContext) as BookingRecommendation;
            recomendation.identifier = this.parseOrCreateIdentifier(recommendationID);
            recomendation.name = recommendationName;
            this.appendObject(recomendation);
        }
                    
        let booking_group = this.queryEntityByField("BookingGroup", "identifier", bookingGroupID) as BookingGroup;
        if (booking_group == null && bookingGroupID != null) {
            booking_group = MIOEntityDescription.insertNewObjectForEntityForName("BookingGroup", this.ad.managedObjectContext) as BookingGroup;
            booking_group.identifier = this.parseOrCreateIdentifier(bookingGroupID);
            this.appendObject(booking_group);
        }

        let zone = this.queryEntityByIDOrName("BookingZone", zoneID, zoneName) as BookingZone;        

        let booking = this.queryEntityByField("Booking", "identifier", bookingID) as Booking;        
        if (booking == null) booking = this.queryEntity("Booking", "clientName == '" + clientName + "' and day == '" + day + "' and hour == '" + hour + "'") as Booking;
        if (booking == null) {
            booking = MIOEntityDescription.insertNewObjectForEntityForName("Booking", DBHelper.mainManagedObjectContext) as Booking;
            booking.identifier = this.parseOrCreateIdentifier(bookingID);
            this.appendObject(booking);
        }

        booking.group = booking_group;
        booking.bookingName = bookingName?.length > 0 ? bookingName : clientName;
        booking.client = client;
        booking.clientName = client.name;
        booking.clientEmail = client.email;
        booking.clientPhone = client.mobilePhone?.number;
        booking.clientPhone2 = client.phone?.number;        
        booking.status = status;
        booking.date = this.ad.dateTimeFormatter.dateFromString( date );
        booking.day = day;
        booking.hour = hour;
        booking.pax = pax;
        booking.paxAttend = paxAttended ?? pax;
        booking.paxInvited = paxInvited ?? 0;
        booking.minSpend = minFee;
        booking.price = price;
        booking.comments = comments;
        booking.privateComments = privateComments;
        booking.clientComments = clientComments;
        let created_at = this.ad.dateTimeFormatter.dateFromString( createdDate );
        if (created_at != null) booking.createdAt = created_at;
        booking.isWalkIn = this.parseBoolValue( isWalking );
        booking.duration = duration;
        booking.externalReference = extenalReference;
        booking.bookingZoneID = zone?.identifier;
        booking.bookingZoneName = zone?.name;        

        MIOLog("BOOKING: " + this.rowIndex + "/" + this.rows.length + ": " + booking.bookingName);
    }

    protected exportTitle() : string { return "Booking"; }

    protected exportSortDescriptors() { 
        return [
            MIOSortDescriptor.sortDescriptorWithKey("day", false),
            MIOSortDescriptor.sortDescriptorWithKey("hour", false)
        ]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let b = object as Booking;

        let item = {
            "BOKING ID": b.identifier,
        };

        return item;
    }
}