//
// Generated class ProductPlace
//

/// <reference path="ProductPlace_ManagedObject.ts" />

class ProductPlace extends ProductPlace_ManagedObject
{

}
