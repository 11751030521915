 

class StockDetailViewController extends MUIViewController
{

    private _sectionsPageController  = null;
    private _segmentedControl        = null;

    private _productNameLabel        = null;
    private _inputLabel              = null;
    private _minPriceLabel           = null;
    private _maxPriceLabel           = null;
    private _averagePriceLabel       = null;
    private _containersLabel         = null;
    private _totalLabel              = null;
    private _subproductButton        = null;

    private _backButton              = null;

    private _stockExistencesDetail   = null;
    // private _stockConsumptionsDetail = null;

    private _product:Product        = null;
    private _warehouse      = null;

    private cf:MIONumberFormatter = MUIWebApplication.sharedInstance().delegate.currencyFormatter;
    
    viewDidLoad()
    {

        super.viewDidLoad();

        this._sectionsPageController = MUIOutlet(this, "stock_detail_page_controller", "MUIPageController");

        this._stockExistencesDetail = new StockExistencesDetailViewController("stock-existences-detail-view");
        this._stockExistencesDetail.initWithResource("layout/stock/StockExistencesDetailView.html");

        this._sectionsPageController.addPageViewController(this._stockExistencesDetail);

        // this._stockConsumptionsDetail = new MUIViewController("stock_consumptions_detail_view");
        // this._stockConsumptionsDetail.initWithResource("layout/stock/StockConsumptionsDetailView.html");

        // this._sectionsPageController.addPageViewController(this._stockConsumptionsDetail);

        this._segmentedControl = MUIOutlet(this, "sdv_segmented_control", "MUISegmentedControl");
        this._segmentedControl.setAction(this, function (control, index) {

            this._sectionsPageController.showPageAtIndex(index);

            
        });
        
        this._backButton = MUIOutlet(this, 'sdv_nb_back_btn', 'MUIButton');
        this._backButton.setAction(this, function () {

            this.navigationController.popViewController(true);
        });

        this._productNameLabel  = MUIOutlet(this, 'sdv_pb_product_text','MUILabel');
        this._inputLabel        = MUIOutlet(this, 'sdv_pb_inputs_text','MUILabel');
        this._minPriceLabel     = MUIOutlet(this, 'sdv_pb_min_price_text','MUILabel');
        this._maxPriceLabel     = MUIOutlet(this, 'sdv_pb_max_price_text','MUILabel');
        this._averagePriceLabel = MUIOutlet(this, 'sdv_pb_average_price_text','MUILabel');
        this._containersLabel   = MUIOutlet(this, 'sdv_pb_containers_text','MUILabel');
        this._totalLabel        = MUIOutlet(this, 'sdv_pb_total_produc_text','MUILabel');
        this._subproductButton  = MUIOutlet(this, 'sdv_pb_subproduct_btn','MUIButton');

        this._updateUI();
    }

    set warehouse(value)
    {
        this._warehouse = value;  
    }
    set product(value)
    {
        this._product = value;
        this._updateUI()
    }

    private _updateUI()
    {
        if(this.viewIsLoaded && this._product != null)
        {
            this._stockExistencesDetail.warehouse = this._warehouse;
            this._stockExistencesDetail.product = this._product;

            this._productNameLabel.text        = this._product.valueForKey('name');
            let min = null;
            let max = null;
            let inContainers = false;

            for(let i = 0; i < this._product.productWarehouses.length; i++)
            {   
                let warehouse = this._product.productWarehouses.objectAtIndex(i);

                if(this._warehouse != null && this._warehouse != warehouse.identifier) continue;
                if(warehouse.measureunit == MeasureUnitType.Container)
                    inContainers = true;
            }


            let totalcost = 0.0;
            let totalexistences = 0.0;
            let totalWarehouses = 0;
            let minPrice = null;
            let maxPrice = null;

            // for(let i = 0; i < this._product.stockexistences.length; i++)
            // {
            //     let input = this._product.stockexistences[i];
            //     if(this._warehouse != null && this._warehouse != input.warehouse) continue;
            //     let cost = 0 + input.unitcost;
            //     if(minPrice == null || minPrice > cost) minPrice = cost;
            //     if(maxPrice == null || maxPrice < cost) maxPrice = cost;

            //     totalcost += cost;
            //     totalWarehouses++;
            
            //     totalexistences += parseFloat(input.quantity);
            // }

           
            if(inContainers) 
            {
                this._containersLabel.text = totalexistences / this._product.quantity;
            }
            else this._containersLabel.text = '-';

            //this._inputLabel.text              = this._product.stockexistences.length;
            this._minPriceLabel.text           = minPrice;
            this._maxPriceLabel.text           = maxPrice;
            this._averagePriceLabel.text       = (totalWarehouses > 0 ? (totalcost / totalWarehouses) : 0) + ' / ' + MeasureUnits.toString(this._product.measureUnitType);
            //this._containersLabel.text         = this._product.getValue('');
            //this._totalLabel.text              = totalexistences + ' ' + MeasureUnits.toString(this._product.measureunittype);
            
        }
    }
}