class PurchaseInvoiceLineCell extends InvoiceLineCell
{
    get entityName() : string { return "PurchaseInvoiceLine"; }

    protected productDidSelect(controller:SelectEntityViewController, product:Product, supplierProduct?:SupplierProduct)
    {
        super.productDidSelect(controller, product, supplierProduct);
        
        this.vat = DBHelper.stockTaxFromProduct(product);
        this.taxDropDown.title = this.vat?.name;
    }    
}