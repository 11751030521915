class DBMappingEditCell extends MUITableViewCell
{
    private nameLabel:MUILabel = null;
    private mappingNametextField:MUITextField = null;
    private mappingIDTextField:MUITextField = null;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.mappingNametextField = MUIOutlet(this, "mapping-name-tf", "MUITextField");
        this.mappingIDTextField = MUIOutlet(this, "mapping-id-tf", "MUITextField");
    }

    set item(item:DBMappingEntity){        
        this.nameLabel.text = item.ownName
        this.mappingNametextField.text = item.foreignName;
        this.mappingIDTextField.text = item.foreignID;
    }
    
}