

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class CashDeskOperation_ManagedObject

class CashDeskOperation_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: concept
    set concept(value:string) {
        this.setValueForKey(value, 'concept');
    }
    get concept():string {
        return this.valueForKey('concept');
    }
    set conceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'concept');
    }
    get conceptPrimitiveValue():string {
        return this.primitiveValueForKey('concept');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: documentID
    set documentID(value:string) {
        this.setValueForKey(value, 'documentID');
    }
    get documentID():string {
        return this.valueForKey('documentID');
    }
    set documentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'documentID');
    }
    get documentIDPrimitiveValue():string {
        return this.primitiveValueForKey('documentID');
    }

    // Property: legalDocumentID
    set legalDocumentID(value:string) {
        this.setValueForKey(value, 'legalDocumentID');
    }
    get legalDocumentID():string {
        return this.valueForKey('legalDocumentID');
    }
    set legalDocumentIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'legalDocumentID');
    }
    get legalDocumentIDPrimitiveValue():string {
        return this.primitiveValueForKey('legalDocumentID');
    }

    // Property: locationCategoryName
    set locationCategoryName(value:string) {
        this.setValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryName():string {
        return this.valueForKey('locationCategoryName');
    }
    set locationCategoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationCategoryName');
    }

    // Property: locationName
    set locationName(value:string) {
        this.setValueForKey(value, 'locationName');
    }
    get locationName():string {
        return this.valueForKey('locationName');
    }
    set locationNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationName');
    }
    get locationNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationName');
    }

    // Property: payMethodName
    set payMethodName(value:string) {
        this.setValueForKey(value, 'payMethodName');
    }
    get payMethodName():string {
        return this.valueForKey('payMethodName');
    }
    set payMethodNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'payMethodName');
    }
    get payMethodNamePrimitiveValue():string {
        return this.primitiveValueForKey('payMethodName');
    }

    // Property: sessionID
    set sessionID(value:string) {
        this.setValueForKey(value, 'sessionID');
    }
    get sessionID():string {
        return this.valueForKey('sessionID');
    }
    set sessionIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sessionID');
    }
    get sessionIDPrimitiveValue():string {
        return this.primitiveValueForKey('sessionID');
    }

    // Property: tip
    set tip(value:number) {
        this.setValueForKey(value, 'tip');
    }
    get tip():number {
        return this.valueForKey('tip');
    }
    set tipPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'tip');
    }
    get tipPrimitiveValue():number {
        return this.primitiveValueForKey('tip');
    }

    // Property: total
    set total(value:number) {
        this.setValueForKey(value, 'total');
    }
    get total():number {
        return this.valueForKey('total');
    }
    set totalPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'total');
    }
    get totalPrimitiveValue():number {
        return this.primitiveValueForKey('total');
    }

    // Property: workerName
    set workerName(value:string) {
        this.setValueForKey(value, 'workerName');
    }
    get workerName():string {
        return this.valueForKey('workerName');
    }
    set workerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerName');
    }
    get workerNamePrimitiveValue():string {
        return this.primitiveValueForKey('workerName');
    }
    // Relationship: payMethod
    set payMethod(value:PayMethod) {
        this.setValueForKey(value, 'payMethod');
    }
    get payMethod():PayMethod {
        return this.valueForKey('payMethod') as PayMethod;
    }
    // Relationship: worker
    set worker(value:Employee) {
        this.setValueForKey(value, 'worker');
    }
    get worker():Employee {
        return this.valueForKey('worker') as Employee;
    }
}
