
class PlaceListViewController extends EntityListViewController
{
    private addButton:MUIButton = null;
    private tagsTableView:UITableView = null;
    
    private replicaDataSource:ReplicaConfigDataSource = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, this.addPlaceAction);

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.delegate = this;
        this.tableView.dataSource = this;

        this.tagsTableView = MUIOutlet(this, "tags-table-view", "UITableView");
        this.replicaDataSource = new ReplicaConfigDataSource();
        this.replicaDataSource.initWithTableView(this.tagsTableView);
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        
        let cell = tableview.dequeueReusableCellWithIdentifier('PlaceListCell') as PlaceListCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Place;
        
        cell.item = item;
        return cell;
    }

    didSelectCellAtIndexPath( tableView:UITableView, indexPath: MIOIndexPath ) {
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Place;
        this.replicaDataSource.item = item;
    }
            
    set fetchedResultsController(value){
        this.setFetchedResultsController(value);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];

        // if (ad.selectedIdentifierType == "place") predicateFormat += " AND placeID == '" + ad.selectedIdentifier + "'";
        // if(this.searchString != null) {
        //     predicateFormat += " AND documentID CONTAINS '" + this.searchString + "'";
		// }
		
		// if (this.filterPredicateFormat != null) {
        //     predicateFormat += " AND " + this.filterPredicateFormat;
        // }

        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Place", sortDescriptors, null);
        fetchRequest.fetchLimit = 100;
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    private addPlaceAction(){
        let vc = new AddChildPlaceViewController("add-child-place-view");
        vc.initWithResource("layout/places/AddChildPlaceView.html");

        this.presentViewController(vc, true);
    }
}