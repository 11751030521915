

/// <reference path="CommunicationTemplate.ts" />

// Generated class EmailTemplate_ManagedObject

class EmailTemplate_ManagedObject extends CommunicationTemplate
{

    // Property: subject
    set subject(value:string) {
        this.setValueForKey(value, 'subject');
    }
    get subject():string {
        return this.valueForKey('subject');
    }
    set subjectPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'subject');
    }
    get subjectPrimitiveValue():string {
        return this.primitiveValueForKey('subject');
    }

    // Property: url
    set url(value:string) {
        this.setValueForKey(value, 'url');
    }
    get url():string {
        return this.valueForKey('url');
    }
    set urlPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'url');
    }
    get urlPrimitiveValue():string {
        return this.primitiveValueForKey('url');
    }
}
