/**
 * Created by miguel on 15/2/17.
 */




class WarehouseSettingsViewController extends MUIViewController
{
    private addButton:MUIButton = null;
    private backButton:MUIButton = null;

    private tableView:MUITableView = null;
    private _fetchedResultsController:MIOFetchedResultsController = null;

    //private _productWarehouses = [];
    private whvc:WarehouseViewController = null;
    private _product:Product = null;

    get preferredContentSize()
    {
        return new MIOSize(600, 500);
    }

    viewDidLoad()
    {
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, 'wsv_nb_add_btn', 'MUIButton');
        this.addButton.setAction(this, function (){
            this.navigationController.pushViewController(this.warehouseViewController, true);
        });

        this.backButton = MUIOutlet(this, 'wsv_nb_close_btn', 'MUIButton');
        this.backButton.setAction(this, function ()
        {
            this.saveWarehouses();
        });

        this.tableView = MUIOutlet(this, 'wsv_c1_tableview', 'MUITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.updateUI();

    }
    
    private updateUI()
    {
        if(this._product == null) return;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
    }

    set product(value)
    {
        this._product = value;
    }

    get product()
    {
        return this._product;
    }

    numberOfSections(tableview)
    {
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section)
    {
        var sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath)
    {
        var cell:WarehouseSettingsCell = tableview.dequeueReusableCellWithIdentifier('WarehouseSettingsCell') as WarehouseSettingsCell;
    
        var item = this.fetchedResultsController.objectAtIndexPath(indexPath);
        
        cell.delegate = this;
        cell.item = item;
        
        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
        cell.selectionStyle = MUITableViewCellSelectionStyle.None;
        
        return cell;
    }
    
    set fetchedResultsController(value)
    {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController()
    {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    

        var ad = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];
    
        var fetchRequest = DBHelper.listFetchRequestWithEntityName('ProductWarehouse', sortDescriptors, 'product.identifier == ' + this._product.identifier);
        fetchRequest.relationshipKeyPathsForPrefetching = ['warehouse'];
        var fetchedResultsController = new MIOFetchedResultsController();
        
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this; 
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller)
    {
        this.tableView.reloadData();
    }

    get warehouseViewController()
    {
        var whvc  = new WarehouseViewController('warehouse_view');
        whvc.initWithResource('layout/modals/warehouse/WarehouseView.html');

        whvc.product = this._product;
        whvc.delegate = this;
        
        return whvc;
    }

    public selectedWarehouses(selectedWarehouses)
    {   
        let ad = MUIWebApplication.sharedInstance().delegate;

        for(let warehouse in selectedWarehouses){

            let fetched = this._product.productWarehouses.filterWithPredicate(MIOPredicate.predicateWithFormat("warehouse.identifier == " + warehouse));

            if(selectedWarehouses[warehouse].selected == true)
            {
                if(fetched.length == 0) {
                    var p:ProductWarehouse = MIOEntityDescription.insertNewObjectForEntityForName("ProductWarehouse", ad.managedObjectContext) as ProductWarehouse;
                    p.identifier = MIOUUID.UUID().UUIDString;
                    p.product = this._product;
                    p.isDefault = false;
                    p.measureUnit = this._product.measureUnitType;
                    p.warehouse = selectedWarehouses[warehouse].warehouse;
                    this._product.addProductWarehousesObject(p);              
                    //ad.managedObjectContext.save();
                }
            }
            else
            {
                if(fetched.length > 0) {
                    for(let i=0; i< fetched.length; i++) {
                        ad.managedObjectContext.deleteObject(fetched.objectAtIndex(i));
                        this._product.removeProductWarehousesObject(fetched.objectAtIndex(i));
                    }      
                }
            }
        }

        let defaultWarehouse = this._product.productWarehouses.filterWithPredicate(MIOPredicate.predicateWithFormat('isDefault = true'));
        if(defaultWarehouse.length == 0 && this._product.productWarehouses.length > 0)
        {
            let pw:ProductWarehouse =  this._product.productWarehouses.objectAtIndex(0);
            pw.isDefault = true;
        }    
    
        ad.managedObjectContext.save();
    }



    private saveWarehouses()
    {
        let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
        moc.save();
        this.dismissViewController(true);
    }
}