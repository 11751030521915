

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class ArchivedTicketLine_ManagedObject

class ArchivedTicketLine_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: areaID
    set areaID(value:number) {
        this.setValueForKey(value, 'areaID');
    }
    get areaID():number {
        return this.valueForKey('areaID');
    }
    set areaIDPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'areaID');
    }
    get areaIDPrimitiveValue():number {
        return this.primitiveValueForKey('areaID');
    }

    // Property: categoryTagID
    set categoryTagID(value:string) {
        this.setValueForKey(value, 'categoryTagID');
    }
    get categoryTagID():string {
        return this.valueForKey('categoryTagID');
    }
    set categoryTagIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'categoryTagID');
    }
    get categoryTagIDPrimitiveValue():string {
        return this.primitiveValueForKey('categoryTagID');
    }

    // Property: categoryTagName
    set categoryTagName(value:string) {
        this.setValueForKey(value, 'categoryTagName');
    }
    get categoryTagName():string {
        return this.valueForKey('categoryTagName');
    }
    set categoryTagNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'categoryTagName');
    }
    get categoryTagNamePrimitiveValue():string {
        return this.primitiveValueForKey('categoryTagName');
    }

    // Property: cover
    set cover(value:string) {
        this.setValueForKey(value, 'cover');
    }
    get cover():string {
        return this.valueForKey('cover');
    }
    set coverPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'cover');
    }
    get coverPrimitiveValue():string {
        return this.primitiveValueForKey('cover');
    }

    // Property: customConcept
    set customConcept(value:string) {
        this.setValueForKey(value, 'customConcept');
    }
    get customConcept():string {
        return this.valueForKey('customConcept');
    }
    set customConceptPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'customConcept');
    }
    get customConceptPrimitiveValue():string {
        return this.primitiveValueForKey('customConcept');
    }

    // Property: customConceptID
    set customConceptID(value:string) {
        this.setValueForKey(value, 'customConceptID');
    }
    get customConceptID():string {
        return this.valueForKey('customConceptID');
    }
    set customConceptIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'customConceptID');
    }
    get customConceptIDPrimitiveValue():string {
        return this.primitiveValueForKey('customConceptID');
    }

    // Property: customDescription
    set customDescription(value:string) {
        this.setValueForKey(value, 'customDescription');
    }
    get customDescription():string {
        return this.valueForKey('customDescription');
    }
    set customDescriptionPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'customDescription');
    }
    get customDescriptionPrimitiveValue():string {
        return this.primitiveValueForKey('customDescription');
    }

    // Property: date
    set date(value:Date) {
        this.setValueForKey(value, 'date');
    }
    get date():Date {
        return this.valueForKey('date');
    }
    set datePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'date');
    }
    get datePrimitiveValue():Date {
        return this.primitiveValueForKey('date');
    }

    // Property: discount
    set discount(value:number) {
        this.setValueForKey(value, 'discount');
    }
    get discount():number {
        return this.valueForKey('discount');
    }
    set discountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'discount');
    }
    get discountPrimitiveValue():number {
        return this.primitiveValueForKey('discount');
    }

    // Property: discountDate
    set discountDate(value:Date) {
        this.setValueForKey(value, 'discountDate');
    }
    get discountDate():Date {
        return this.valueForKey('discountDate');
    }
    set discountDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'discountDate');
    }
    get discountDatePrimitiveValue():Date {
        return this.primitiveValueForKey('discountDate');
    }

    // Property: discountWorkerID
    set discountWorkerID(value:string) {
        this.setValueForKey(value, 'discountWorkerID');
    }
    get discountWorkerID():string {
        return this.valueForKey('discountWorkerID');
    }
    set discountWorkerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'discountWorkerID');
    }
    get discountWorkerIDPrimitiveValue():string {
        return this.primitiveValueForKey('discountWorkerID');
    }

    // Property: discountWorkerName
    set discountWorkerName(value:string) {
        this.setValueForKey(value, 'discountWorkerName');
    }
    get discountWorkerName():string {
        return this.valueForKey('discountWorkerName');
    }
    set discountWorkerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'discountWorkerName');
    }
    get discountWorkerNamePrimitiveValue():string {
        return this.primitiveValueForKey('discountWorkerName');
    }

    // Property: eventID
    set eventID(value:string) {
        this.setValueForKey(value, 'eventID');
    }
    get eventID():string {
        return this.valueForKey('eventID');
    }
    set eventIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'eventID');
    }
    get eventIDPrimitiveValue():string {
        return this.primitiveValueForKey('eventID');
    }

    // Property: externalInfo
    set externalInfo(value:string) {
        this.setValueForKey(value, 'externalInfo');
    }
    get externalInfo():string {
        return this.valueForKey('externalInfo');
    }
    set externalInfoPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'externalInfo');
    }
    get externalInfoPrimitiveValue():string {
        return this.primitiveValueForKey('externalInfo');
    }

    // Property: inProcessDate
    set inProcessDate(value:Date) {
        this.setValueForKey(value, 'inProcessDate');
    }
    get inProcessDate():Date {
        return this.valueForKey('inProcessDate');
    }
    set inProcessDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'inProcessDate');
    }
    get inProcessDatePrimitiveValue():Date {
        return this.primitiveValueForKey('inProcessDate');
    }

    // Property: inProcessSeconds
    set inProcessSeconds(value:number) {
        this.setValueForKey(value, 'inProcessSeconds');
    }
    get inProcessSeconds():number {
        return this.valueForKey('inProcessSeconds');
    }
    set inProcessSecondsPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'inProcessSeconds');
    }
    get inProcessSecondsPrimitiveValue():number {
        return this.primitiveValueForKey('inProcessSeconds');
    }

    // Property: invitationValue
    set invitationValue(value:number) {
        this.setValueForKey(value, 'invitationValue');
    }
    get invitationValue():number {
        return this.valueForKey('invitationValue');
    }
    set invitationValuePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'invitationValue');
    }
    get invitationValuePrimitiveValue():number {
        return this.primitiveValueForKey('invitationValue');
    }

    // Property: isGift
    set isGift(value:boolean) {
        this.setValueForKey(value, 'isGift');
    }
    get isGift():boolean {
        return this.valueForKey('isGift');
    }
    set isGiftPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'isGift');
    }
    get isGiftPrimitiveValue():boolean {
        return this.primitiveValueForKey('isGift');
    }

    // Property: locationCategoryID
    set locationCategoryID(value:string) {
        this.setValueForKey(value, 'locationCategoryID');
    }
    get locationCategoryID():string {
        return this.valueForKey('locationCategoryID');
    }
    set locationCategoryIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationCategoryID');
    }
    get locationCategoryIDPrimitiveValue():string {
        return this.primitiveValueForKey('locationCategoryID');
    }

    // Property: locationCategoryName
    set locationCategoryName(value:string) {
        this.setValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryName():string {
        return this.valueForKey('locationCategoryName');
    }
    set locationCategoryNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationCategoryName');
    }
    get locationCategoryNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationCategoryName');
    }

    // Property: locationID
    set locationID(value:string) {
        this.setValueForKey(value, 'locationID');
    }
    get locationID():string {
        return this.valueForKey('locationID');
    }
    set locationIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationID');
    }
    get locationIDPrimitiveValue():string {
        return this.primitiveValueForKey('locationID');
    }

    // Property: locationName
    set locationName(value:string) {
        this.setValueForKey(value, 'locationName');
    }
    get locationName():string {
        return this.valueForKey('locationName');
    }
    set locationNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'locationName');
    }
    get locationNamePrimitiveValue():string {
        return this.primitiveValueForKey('locationName');
    }

    // Property: modifiedType
    set modifiedType(value:number) {
        this.setValueForKey(value, 'modifiedType');
    }
    get modifiedType():number {
        return this.valueForKey('modifiedType');
    }
    set modifiedTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'modifiedType');
    }
    get modifiedTypePrimitiveValue():number {
        return this.primitiveValueForKey('modifiedType');
    }

    // Property: readyDate
    set readyDate(value:Date) {
        this.setValueForKey(value, 'readyDate');
    }
    get readyDate():Date {
        return this.valueForKey('readyDate');
    }
    set readyDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'readyDate');
    }
    get readyDatePrimitiveValue():Date {
        return this.primitiveValueForKey('readyDate');
    }

    // Property: responsibleCardCode
    set responsibleCardCode(value:string) {
        this.setValueForKey(value, 'responsibleCardCode');
    }
    get responsibleCardCode():string {
        return this.valueForKey('responsibleCardCode');
    }
    set responsibleCardCodePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'responsibleCardCode');
    }
    get responsibleCardCodePrimitiveValue():string {
        return this.primitiveValueForKey('responsibleCardCode');
    }

    // Property: responsibleID
    set responsibleID(value:string) {
        this.setValueForKey(value, 'responsibleID');
    }
    get responsibleID():string {
        return this.valueForKey('responsibleID');
    }
    set responsibleIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'responsibleID');
    }
    get responsibleIDPrimitiveValue():string {
        return this.primitiveValueForKey('responsibleID');
    }

    // Property: responsibleName
    set responsibleName(value:string) {
        this.setValueForKey(value, 'responsibleName');
    }
    get responsibleName():string {
        return this.valueForKey('responsibleName');
    }
    set responsibleNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'responsibleName');
    }
    get responsibleNamePrimitiveValue():string {
        return this.primitiveValueForKey('responsibleName');
    }

    // Property: responsibleType
    set responsibleType(value:number) {
        this.setValueForKey(value, 'responsibleType');
    }
    get responsibleType():number {
        return this.valueForKey('responsibleType');
    }
    set responsibleTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'responsibleType');
    }
    get responsibleTypePrimitiveValue():number {
        return this.primitiveValueForKey('responsibleType');
    }

    // Property: sendToKitchenDate
    set sendToKitchenDate(value:Date) {
        this.setValueForKey(value, 'sendToKitchenDate');
    }
    get sendToKitchenDate():Date {
        return this.valueForKey('sendToKitchenDate');
    }
    set sendToKitchenDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'sendToKitchenDate');
    }
    get sendToKitchenDatePrimitiveValue():Date {
        return this.primitiveValueForKey('sendToKitchenDate');
    }

    // Property: sendToKitchenSeconds
    set sendToKitchenSeconds(value:number) {
        this.setValueForKey(value, 'sendToKitchenSeconds');
    }
    get sendToKitchenSeconds():number {
        return this.valueForKey('sendToKitchenSeconds');
    }
    set sendToKitchenSecondsPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'sendToKitchenSeconds');
    }
    get sendToKitchenSecondsPrimitiveValue():number {
        return this.primitiveValueForKey('sendToKitchenSeconds');
    }

    // Property: sendToKitchenWorkerID
    set sendToKitchenWorkerID(value:string) {
        this.setValueForKey(value, 'sendToKitchenWorkerID');
    }
    get sendToKitchenWorkerID():string {
        return this.valueForKey('sendToKitchenWorkerID');
    }
    set sendToKitchenWorkerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sendToKitchenWorkerID');
    }
    get sendToKitchenWorkerIDPrimitiveValue():string {
        return this.primitiveValueForKey('sendToKitchenWorkerID');
    }

    // Property: sendToKitchenWorkerName
    set sendToKitchenWorkerName(value:string) {
        this.setValueForKey(value, 'sendToKitchenWorkerName');
    }
    get sendToKitchenWorkerName():string {
        return this.valueForKey('sendToKitchenWorkerName');
    }
    set sendToKitchenWorkerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'sendToKitchenWorkerName');
    }
    get sendToKitchenWorkerNamePrimitiveValue():string {
        return this.primitiveValueForKey('sendToKitchenWorkerName');
    }

    // Property: servedDate
    set servedDate(value:Date) {
        this.setValueForKey(value, 'servedDate');
    }
    get servedDate():Date {
        return this.valueForKey('servedDate');
    }
    set servedDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'servedDate');
    }
    get servedDatePrimitiveValue():Date {
        return this.primitiveValueForKey('servedDate');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Property: statusDate
    set statusDate(value:Date) {
        this.setValueForKey(value, 'statusDate');
    }
    get statusDate():Date {
        return this.valueForKey('statusDate');
    }
    set statusDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'statusDate');
    }
    get statusDatePrimitiveValue():Date {
        return this.primitiveValueForKey('statusDate');
    }

    // Property: statusWorkerID
    set statusWorkerID(value:string) {
        this.setValueForKey(value, 'statusWorkerID');
    }
    get statusWorkerID():string {
        return this.valueForKey('statusWorkerID');
    }
    set statusWorkerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'statusWorkerID');
    }
    get statusWorkerIDPrimitiveValue():string {
        return this.primitiveValueForKey('statusWorkerID');
    }

    // Property: statusWorkerName
    set statusWorkerName(value:string) {
        this.setValueForKey(value, 'statusWorkerName');
    }
    get statusWorkerName():string {
        return this.valueForKey('statusWorkerName');
    }
    set statusWorkerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'statusWorkerName');
    }
    get statusWorkerNamePrimitiveValue():string {
        return this.primitiveValueForKey('statusWorkerName');
    }

    // Property: subtaxesDict
    set subtaxesDict(value:any) {
        this.setValueForKey(value, 'subtaxesDict');
    }
    get subtaxesDict():any {
        return this.valueForKey('subtaxesDict');
    }
    set subtaxesDictPrimitiveValue(value:any) {
        this.setPrimitiveValueForKey(value, 'subtaxesDict');
    }
    get subtaxesDictPrimitiveValue():any {
        return this.primitiveValueForKey('subtaxesDict');
    }

    // Property: tags
    set tags(value:string) {
        this.setValueForKey(value, 'tags');
    }
    get tags():string {
        return this.valueForKey('tags');
    }
    set tagsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'tags');
    }
    get tagsPrimitiveValue():string {
        return this.primitiveValueForKey('tags');
    }

    // Property: tax
    set tax(value:number) {
        this.setValueForKey(value, 'tax');
    }
    get tax():number {
        return this.valueForKey('tax');
    }
    set taxPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'tax');
    }
    get taxPrimitiveValue():number {
        return this.primitiveValueForKey('tax');
    }

    // Property: taxID
    set taxID(value:string) {
        this.setValueForKey(value, 'taxID');
    }
    get taxID():string {
        return this.valueForKey('taxID');
    }
    set taxIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'taxID');
    }
    get taxIDPrimitiveValue():string {
        return this.primitiveValueForKey('taxID');
    }

    // Property: taxName
    set taxName(value:string) {
        this.setValueForKey(value, 'taxName');
    }
    get taxName():string {
        return this.valueForKey('taxName');
    }
    set taxNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'taxName');
    }
    get taxNamePrimitiveValue():string {
        return this.primitiveValueForKey('taxName');
    }

    // Property: totalDeleted
    set totalDeleted(value:number) {
        this.setValueForKey(value, 'totalDeleted');
    }
    get totalDeleted():number {
        return this.valueForKey('totalDeleted');
    }
    set totalDeletedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalDeleted');
    }
    get totalDeletedPrimitiveValue():number {
        return this.primitiveValueForKey('totalDeleted');
    }

    // Property: totalDiscount
    set totalDiscount(value:number) {
        this.setValueForKey(value, 'totalDiscount');
    }
    get totalDiscount():number {
        return this.valueForKey('totalDiscount');
    }
    set totalDiscountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalDiscount');
    }
    get totalDiscountPrimitiveValue():number {
        return this.primitiveValueForKey('totalDiscount');
    }

    // Property: totalInvited
    set totalInvited(value:number) {
        this.setValueForKey(value, 'totalInvited');
    }
    get totalInvited():number {
        return this.valueForKey('totalInvited');
    }
    set totalInvitedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalInvited');
    }
    get totalInvitedPrimitiveValue():number {
        return this.primitiveValueForKey('totalInvited');
    }

    // Property: totalLineDiscount
    set totalLineDiscount(value:number) {
        this.setValueForKey(value, 'totalLineDiscount');
    }
    get totalLineDiscount():number {
        return this.valueForKey('totalLineDiscount');
    }
    set totalLineDiscountPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalLineDiscount');
    }
    get totalLineDiscountPrimitiveValue():number {
        return this.primitiveValueForKey('totalLineDiscount');
    }

    // Property: totalLineInvited
    set totalLineInvited(value:number) {
        this.setValueForKey(value, 'totalLineInvited');
    }
    get totalLineInvited():number {
        return this.valueForKey('totalLineInvited');
    }
    set totalLineInvitedPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalLineInvited');
    }
    get totalLineInvitedPrimitiveValue():number {
        return this.primitiveValueForKey('totalLineInvited');
    }

    // Property: totalLinePrice
    set totalLinePrice(value:number) {
        this.setValueForKey(value, 'totalLinePrice');
    }
    get totalLinePrice():number {
        return this.valueForKey('totalLinePrice');
    }
    set totalLinePricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalLinePrice');
    }
    get totalLinePricePrimitiveValue():number {
        return this.primitiveValueForKey('totalLinePrice');
    }

    // Property: totalLinePriceBase
    set totalLinePriceBase(value:number) {
        this.setValueForKey(value, 'totalLinePriceBase');
    }
    get totalLinePriceBase():number {
        return this.valueForKey('totalLinePriceBase');
    }
    set totalLinePriceBasePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalLinePriceBase');
    }
    get totalLinePriceBasePrimitiveValue():number {
        return this.primitiveValueForKey('totalLinePriceBase');
    }

    // Property: totalLineTaxes
    set totalLineTaxes(value:number) {
        this.setValueForKey(value, 'totalLineTaxes');
    }
    get totalLineTaxes():number {
        return this.valueForKey('totalLineTaxes');
    }
    set totalLineTaxesPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalLineTaxes');
    }
    get totalLineTaxesPrimitiveValue():number {
        return this.primitiveValueForKey('totalLineTaxes');
    }

    // Property: totalLineTip
    set totalLineTip(value:number) {
        this.setValueForKey(value, 'totalLineTip');
    }
    get totalLineTip():number {
        return this.valueForKey('totalLineTip');
    }
    set totalLineTipPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalLineTip');
    }
    get totalLineTipPrimitiveValue():number {
        return this.primitiveValueForKey('totalLineTip');
    }

    // Property: totalPrice
    set totalPrice(value:number) {
        this.setValueForKey(value, 'totalPrice');
    }
    get totalPrice():number {
        return this.valueForKey('totalPrice');
    }
    set totalPricePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalPrice');
    }
    get totalPricePrimitiveValue():number {
        return this.primitiveValueForKey('totalPrice');
    }

    // Property: totalPriceBase
    set totalPriceBase(value:number) {
        this.setValueForKey(value, 'totalPriceBase');
    }
    get totalPriceBase():number {
        return this.valueForKey('totalPriceBase');
    }
    set totalPriceBasePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalPriceBase');
    }
    get totalPriceBasePrimitiveValue():number {
        return this.primitiveValueForKey('totalPriceBase');
    }

    // Property: totalTaxes
    set totalTaxes(value:number) {
        this.setValueForKey(value, 'totalTaxes');
    }
    get totalTaxes():number {
        return this.valueForKey('totalTaxes');
    }
    set totalTaxesPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalTaxes');
    }
    get totalTaxesPrimitiveValue():number {
        return this.primitiveValueForKey('totalTaxes');
    }

    // Property: totalTip
    set totalTip(value:number) {
        this.setValueForKey(value, 'totalTip');
    }
    get totalTip():number {
        return this.valueForKey('totalTip');
    }
    set totalTipPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalTip');
    }
    get totalTipPrimitiveValue():number {
        return this.primitiveValueForKey('totalTip');
    }

    // Property: usedCoupons
    set usedCoupons(value:string) {
        this.setValueForKey(value, 'usedCoupons');
    }
    get usedCoupons():string {
        return this.valueForKey('usedCoupons');
    }
    set usedCouponsPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'usedCoupons');
    }
    get usedCouponsPrimitiveValue():string {
        return this.primitiveValueForKey('usedCoupons');
    }

    // Property: warehouseID
    set warehouseID(value:string) {
        this.setValueForKey(value, 'warehouseID');
    }
    get warehouseID():string {
        return this.valueForKey('warehouseID');
    }
    set warehouseIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'warehouseID');
    }
    get warehouseIDPrimitiveValue():string {
        return this.primitiveValueForKey('warehouseID');
    }

    // Property: wasConsumed
    set wasConsumed(value:boolean) {
        this.setValueForKey(value, 'wasConsumed');
    }
    get wasConsumed():boolean {
        return this.valueForKey('wasConsumed');
    }
    set wasConsumedPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'wasConsumed');
    }
    get wasConsumedPrimitiveValue():boolean {
        return this.primitiveValueForKey('wasConsumed');
    }

    // Property: waTransactionID
    set waTransactionID(value:string) {
        this.setValueForKey(value, 'waTransactionID');
    }
    get waTransactionID():string {
        return this.valueForKey('waTransactionID');
    }
    set waTransactionIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'waTransactionID');
    }
    get waTransactionIDPrimitiveValue():string {
        return this.primitiveValueForKey('waTransactionID');
    }

    // Property: workerID
    set workerID(value:string) {
        this.setValueForKey(value, 'workerID');
    }
    get workerID():string {
        return this.valueForKey('workerID');
    }
    set workerIDPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerID');
    }
    get workerIDPrimitiveValue():string {
        return this.primitiveValueForKey('workerID');
    }

    // Property: workerName
    set workerName(value:string) {
        this.setValueForKey(value, 'workerName');
    }
    get workerName():string {
        return this.valueForKey('workerName');
    }
    set workerNamePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'workerName');
    }
    get workerNamePrimitiveValue():string {
        return this.primitiveValueForKey('workerName');
    }
    // Relationship: archivedTicketLinesGroup
    set archivedTicketLinesGroup(value:ArchivedTicketLinesGroup) {
        this.setValueForKey(value, 'archivedTicketLinesGroup');
    }
    get archivedTicketLinesGroup():ArchivedTicketLinesGroup {
        return this.valueForKey('archivedTicketLinesGroup') as ArchivedTicketLinesGroup;
    }

    // Relationship: modifiers
    protected _modifiers:MIOManagedObjectSet = null;
    get modifiers():MIOManagedObjectSet {
        return this.valueForKey('modifiers');
    }
    addModifiersObject(value:ArchivedTicketLineModifier) {
        this._addObjectForKey(value, 'modifiers');
    }
    removeModifiersObject(value:ArchivedTicketLineModifier) {
        this._removeObjectForKey(value, 'modifiers');
    }
    // Relationship: nullifiedBy
    set nullifiedBy(value:ArchivedTicketLine) {
        this.setValueForKey(value, 'nullifiedBy');
    }
    get nullifiedBy():ArchivedTicketLine {
        return this.valueForKey('nullifiedBy') as ArchivedTicketLine;
    }
    // Relationship: nullify
    set nullify(value:ArchivedTicketLine) {
        this.setValueForKey(value, 'nullify');
    }
    get nullify():ArchivedTicketLine {
        return this.valueForKey('nullify') as ArchivedTicketLine;
    }
}
