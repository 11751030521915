

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Component_ManagedObject

class Component_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: computedCost
    set computedCost(value:number) {
        this.setValueForKey(value, 'computedCost');
    }
    get computedCost():number {
        return this.valueForKey('computedCost');
    }
    set computedCostPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'computedCost');
    }
    get computedCostPrimitiveValue():number {
        return this.primitiveValueForKey('computedCost');
    }

    // Property: computedCostDate
    set computedCostDate(value:Date) {
        this.setValueForKey(value, 'computedCostDate');
    }
    get computedCostDate():Date {
        return this.valueForKey('computedCostDate');
    }
    set computedCostDatePrimitiveValue(value:Date) {
        this.setPrimitiveValueForKey(value, 'computedCostDate');
    }
    get computedCostDatePrimitiveValue():Date {
        return this.primitiveValueForKey('computedCostDate');
    }

    // Property: computedCostFactor
    set computedCostFactor(value:number) {
        this.setValueForKey(value, 'computedCostFactor');
    }
    get computedCostFactor():number {
        return this.valueForKey('computedCostFactor');
    }
    set computedCostFactorPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'computedCostFactor');
    }
    get computedCostFactorPrimitiveValue():number {
        return this.primitiveValueForKey('computedCostFactor');
    }

    // Property: computedCostRule
    set computedCostRule(value:number) {
        this.setValueForKey(value, 'computedCostRule');
    }
    get computedCostRule():number {
        return this.valueForKey('computedCostRule');
    }
    set computedCostRulePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'computedCostRule');
    }
    get computedCostRulePrimitiveValue():number {
        return this.primitiveValueForKey('computedCostRule');
    }

    // Property: computedMinCost
    set computedMinCost(value:number) {
        this.setValueForKey(value, 'computedMinCost');
    }
    get computedMinCost():number {
        return this.valueForKey('computedMinCost');
    }
    set computedMinCostPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'computedMinCost');
    }
    get computedMinCostPrimitiveValue():number {
        return this.primitiveValueForKey('computedMinCost');
    }

    // Property: consumptionMeasureType
    set consumptionMeasureType(value:number) {
        this.setValueForKey(value, 'consumptionMeasureType');
    }
    get consumptionMeasureType():number {
        return this.valueForKey('consumptionMeasureType');
    }
    set consumptionMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'consumptionMeasureType');
    }
    get consumptionMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('consumptionMeasureType');
    }

    // Property: consumptionQuantity
    set consumptionQuantity(value:number) {
        this.setValueForKey(value, 'consumptionQuantity');
    }
    get consumptionQuantity():number {
        return this.valueForKey('consumptionQuantity');
    }
    set consumptionQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'consumptionQuantity');
    }
    get consumptionQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('consumptionQuantity');
    }

    // Property: costPercentage
    set costPercentage(value:number) {
        this.setValueForKey(value, 'costPercentage');
    }
    get costPercentage():number {
        return this.valueForKey('costPercentage');
    }
    set costPercentagePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'costPercentage');
    }
    get costPercentagePrimitiveValue():number {
        return this.primitiveValueForKey('costPercentage');
    }

    // Property: ignoreBulk
    set ignoreBulk(value:boolean) {
        this.setValueForKey(value, 'ignoreBulk');
    }
    get ignoreBulk():boolean {
        return this.valueForKey('ignoreBulk');
    }
    set ignoreBulkPrimitiveValue(value:boolean) {
        this.setPrimitiveValueForKey(value, 'ignoreBulk');
    }
    get ignoreBulkPrimitiveValue():boolean {
        return this.primitiveValueForKey('ignoreBulk');
    }

    // Property: lossMeasureType
    set lossMeasureType(value:number) {
        this.setValueForKey(value, 'lossMeasureType');
    }
    get lossMeasureType():number {
        return this.valueForKey('lossMeasureType');
    }
    set lossMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lossMeasureType');
    }
    get lossMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('lossMeasureType');
    }

    // Property: lossQuantity
    set lossQuantity(value:number) {
        this.setValueForKey(value, 'lossQuantity');
    }
    get lossQuantity():number {
        return this.valueForKey('lossQuantity');
    }
    set lossQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'lossQuantity');
    }
    get lossQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('lossQuantity');
    }

    // Property: totalMeasureType
    set totalMeasureType(value:number) {
        this.setValueForKey(value, 'totalMeasureType');
    }
    get totalMeasureType():number {
        return this.valueForKey('totalMeasureType');
    }
    set totalMeasureTypePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalMeasureType');
    }
    get totalMeasureTypePrimitiveValue():number {
        return this.primitiveValueForKey('totalMeasureType');
    }

    // Property: totalQuantity
    set totalQuantity(value:number) {
        this.setValueForKey(value, 'totalQuantity');
    }
    get totalQuantity():number {
        return this.valueForKey('totalQuantity');
    }
    set totalQuantityPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'totalQuantity');
    }
    get totalQuantityPrimitiveValue():number {
        return this.primitiveValueForKey('totalQuantity');
    }
    // Relationship: children
    set children(value:Product) {
        this.setValueForKey(value, 'children');
    }
    get children():Product {
        return this.valueForKey('children') as Product;
    }
    // Relationship: parent
    set parent(value:Product) {
        this.setValueForKey(value, 'parent');
    }
    get parent():Product {
        return this.valueForKey('parent') as Product;
    }
    // Relationship: productFormatCustom
    set productFormatCustom(value:ProductFormat) {
        this.setValueForKey(value, 'productFormatCustom');
    }
    get productFormatCustom():ProductFormat {
        return this.valueForKey('productFormatCustom') as ProductFormat;
    }
    // Relationship: productFormatExtra
    set productFormatExtra(value:ProductFormat) {
        this.setValueForKey(value, 'productFormatExtra');
    }
    get productFormatExtra():ProductFormat {
        return this.valueForKey('productFormatExtra') as ProductFormat;
    }
    // Relationship: warehouse
    set warehouse(value:Warehouse) {
        this.setValueForKey(value, 'warehouse');
    }
    get warehouse():Warehouse {
        return this.valueForKey('warehouse') as Warehouse;
    }
}
