

/// <reference path="ClientDetailViewController.ts" />
/// <reference path="ClientDebtViewController.ts" />
/// <reference path="ClientDataViewController.ts" />
/// <reference path="AddNewClientViewController.ts" />


class ClientsListViewController extends EntityListViewController
{        
    private addButton:MUIButton = null;     

    viewDidLoad(){
        super.viewDidLoad();
        
        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function (){
            this.showAddClientViewController();
        });

        this.searchTextField = MUIOutlet(this, "search-bar", "MUITextField");
        this.setupSearchTextField();
       
        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }
    
    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("ClientCell") as ClientCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as Client;

        cell.item = item;
        cell.selected = (this.selectedItem?.identifier == item.identifier);

        return cell;
    }

    didSelectCellAtIndexPath(tableView,indexPath:MIOIndexPath){
        this.selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath) as Client;
        this.showSelectedClient(this.selectedItem);
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;

        let ad:AppDelegate = MUIWebApplication.sharedInstance().delegate;

        let sortDescriptors = [MIOSortDescriptor.sortDescriptorWithKey("name", true)];    
        let predicateFormat = this.searchString != null ? "name CONTAINS '" + this.searchString + "'" + " OR document CONTAINS '" + this.searchString + "'" : null;
            
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("Client", sortDescriptors, predicateFormat);
        fetchRequest.fetchLimit = 50;
        fetchRequest.relationshipKeyPathsForPrefetching = ["accountBalance", "loyaltyBalance", "debtBalance"];
        
        let fetchedResultsController = new MIOFetchedResultsController();        
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;
    }

    private showAddClientViewController(){
        
        let vc = new AddNewClientViewController('add-new-client-view');            
        vc.initWithResource('layout/clients/AddNewClientView.html');
        vc.delegate = this;
        
        this.presentViewController(vc, true);
    }
    
    public clientDidInserted(client:Client){
        //this.client = client;
        this.tableView.reloadData();
    }

    private showNoItemSelected(){        
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Client", NoItemSelectedViewControllerType.Client);
        this.splitViewController.showDetailViewController(vc);
    }
    
    private showSelectedClient(client:Client){
        let vc  = new ClientDetailViewController("clients-detail-view");
        vc.initWithResource("layout/clients/ClientsDetailView.html");        
        vc.client = client;
        this.splitViewController.showDetailViewController(vc);        
    }
}
