

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class Task_ManagedObject

class Task_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: status
    set status(value:number) {
        this.setValueForKey(value, 'status');
    }
    get status():number {
        return this.valueForKey('status');
    }
    set statusPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'status');
    }
    get statusPrimitiveValue():number {
        return this.primitiveValueForKey('status');
    }

    // Relationship: assignedTo
    protected _assignedTo:MIOManagedObjectSet = null;
    get assignedTo():MIOManagedObjectSet {
        return this.valueForKey('assignedTo');
    }
    addAssignedToObject(value:LegalEntity) {
        this._addObjectForKey(value, 'assignedTo');
    }
    removeAssignedToObject(value:LegalEntity) {
        this._removeObjectForKey(value, 'assignedTo');
    }

    // Relationship: children
    protected _children:MIOManagedObjectSet = null;
    get children():MIOManagedObjectSet {
        return this.valueForKey('children');
    }
    addChildrenObject(value:Task) {
        this._addObjectForKey(value, 'children');
    }
    removeChildrenObject(value:Task) {
        this._removeObjectForKey(value, 'children');
    }

    // Relationship: departments
    protected _departments:MIOManagedObjectSet = null;
    get departments():MIOManagedObjectSet {
        return this.valueForKey('departments');
    }
    addDepartmentsObject(value:Department) {
        this._addObjectForKey(value, 'departments');
    }
    removeDepartmentsObject(value:Department) {
        this._removeObjectForKey(value, 'departments');
    }
    // Relationship: parent
    set parent(value:Task) {
        this.setValueForKey(value, 'parent');
    }
    get parent():Task {
        return this.valueForKey('parent') as Task;
    }
}
