/**
 * Created by miguel on 23/2/17.
 */


class OfferCell extends UITableViewCell
{
    private nameLabel:MUILabel = null;
    private typeLabel:MUILabel = null;
        
    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, 'name-lbl', 'MUILabel');
        this.typeLabel = MUIOutlet(this, "type-lbl", "MUILabel");
        this.separatorStyle = UITableViewCellSeparatorStyle.None;
    }
    
    private _offer:Offer;
    set item(i:Offer){
        this._offer = i;
        this.nameLabel.text = i.name;
        this.typeLabel.text = i.typeString;
    }
}
