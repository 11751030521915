//
// Generated class Integrator
//

/// <reference path="Integrator_ManagedObject.ts" />

class Integrator extends Integrator_ManagedObject
{

}
