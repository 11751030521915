//
// Generated class ProductAttribute
//

/// <reference path="ProductAttribute_ManagedObject.ts" />

class ProductAttribute extends ProductAttribute_ManagedObject
{

}
