//
// Generated class LoanPaymentIntallment
//

/// <reference path="LoanPaymentIntallment_ManagedObject.ts" />

class LoanPaymentIntallment extends LoanPaymentIntallment_ManagedObject
{

}
