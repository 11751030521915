//
// Generated class TicketPromotionalCodeTransactions
//

/// <reference path="TicketPromotionalCodeTransactions_ManagedObject.ts" />

class TicketPromotionalCodeTransactions extends TicketPromotionalCodeTransactions_ManagedObject
{

}
