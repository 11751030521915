//
// Generated class SyncLogByApp
//

/// <reference path="SyncLogByApp_ManagedObject.ts" />

class SyncLogByApp extends SyncLogByApp_ManagedObject
{

}
