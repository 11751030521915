class DBAppConfigurationCoder extends DBXLSXCoder
{
    appID:string = null;

    mainEntity() {
        return { entity: "AppConfiguration", predicateFormat: "deletedAt == null and appID = '" + this.appID + "'" }
    }

    columns() : any[] {
        let cols = [
            { title:"ID", width: "10%", align: "center" },
            { title:"KEY", width: "10%", align: "center" },
            { title:"VALUE", width: "10%", align: "center" }
        ]
        return cols;
    }

    private skip_keys = ["rfid_reader_host", "barcode_reader_host"];

    protected importRow(row:any){
                
        let cfgID = row["ID"];
        let key = row["KEY"];
        let value = row["VALUE"] as string;
        
        if (this.skip_keys.containsObject(key)) return;
        
        
        let cfg = this.queryEntityByField("AppConfiguration", "identifier", cfgID) as AppConfiguration;
        if (cfg == null) cfg = this.queryEntity("AppConfiguration", "key = '" + key + "' and appID = '" + this.appID + "'") as AppConfiguration;
        if (cfg == null) {
            cfg = MIOEntityDescription.insertNewObjectForEntityForName("AppConfiguration", DBHelper.mainManagedObjectContext) as AppConfiguration;
            cfg.identifier = this.parseOrCreateIdentifier(cfgID);
            this.appendObject(cfg);
        }
                    
        cfg.key = key;        
        cfg.appID = this.appID;
        cfg.valueFromString( value );

        // if (key == "ActiveStoryBoards") {
        //     let values = value.split(",");
        //     let a = []
        //     for (let v of values){
        //         a.addObject( parseInt(v) );
        //     }
        //     cfg.value = a;
        // }
        // else if (value.toLowerCase() == "true") cfg.value = true;
        // else if (value.toLowerCase() == "false") cfg.value = false;
        // else {
        //     let n = parseInt(value);
        //     if (isNaN(n) == false) cfg.value = n;            
        //     else {
        //         cfg.value = value;
        //     }
        // }
                
        MIOLog("ADDING APP CONFIGURATION: " + this.rowIndex + "/" + this.rows.length + ": " + key);
    }

    protected exportTitle() : string { return "App configuration"; }

    protected exportSortDescriptors() { 
        return [MIOSortDescriptor.sortDescriptorWithKey("key", false)]; 
    }

    protected parseObject(object:MIOManagedObject): any {
        let cfg = object as AppConfiguration;

        if (this.skip_keys.containsObject(cfg.key)) return null;

        let item = {
            "ID": cfg.identifier,
            "KEY": cfg.key,
            "VALUE": cfg.value,
        };

        return item;
    }
}