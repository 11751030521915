

/// <reference path="Loan.ts" />

// Generated class BankLoan_ManagedObject

class BankLoan_ManagedObject extends Loan
{
    // Relationship: bank
    set bank(value:Bank) {
        this.setValueForKey(value, 'bank');
    }
    get bank():Bank {
        return this.valueForKey('bank') as Bank;
    }
}
