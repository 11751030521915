class LicenseTypeCell extends UITableViewCell
{
    private nameTextField:MUITextField = null;
    private rateDropDown:MUIButton = null;
    private periodTextField:MUITextField = null;

    awakeFromHTML(): void {
        this.nameTextField = MUIOutlet( this, "name-tf", "MUITextField" );
        this.nameTextField.setOnChangeText( this, function( this:LicenseTypeCell, control:MUITextField, value:string) {
            this.licenseType.name = value;
        });

        this.rateDropDown = MUIOutlet( this, "rate-dd", "MUIButton" );
        this.rateDropDown.setAction( this, function( this:LicenseTypeCell, control:MUIButton) {
            AppHelper.sharedInstance().showSelectRateViewControllerFromView( this.rateDropDown, null, this, function(this:LicenseTypeCell, controller:any, object:Rate){
                this.licenseType.rate = object;
                this.licenseType.rateName = object?.name;
                this.rateDropDown.title = object?.name;
            });
        });

        this.periodTextField = MUIOutlet( this, "period-tf", "MUITextField" );
        this.periodTextField.setOnChangeText( this, function( this:LicenseTypeCell, control:MUITextField, value:string) {
            let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;
            this.licenseType.timePeriod = ad.integerFormatter.numberFromString( value ) ?? 1;
        });
    }

    private licenseType:LicenseType = null;
    set item( item:LicenseType ) {
        this.licenseType = item;

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        this.nameTextField.text = item.name;
        this.rateDropDown.title = item.rateName ?? MIOLocalizeString( "NO RATE", "NO RATE");
        this.periodTextField.text = ad.integerFormatter.stringFromNumber( item.timePeriod );
    }
}