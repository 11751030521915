//
// Generated class StockTemplateProductItem
//

/// <reference path="StockTemplateProductItem_ManagedObject.ts" />

class StockTemplateProductItem extends StockTemplateProductItem_ManagedObject
{
    get typeString() {
        return MIOLocalizeString("STOCK_TEMPLATE_PRODUCT_ITEM","STOCK_TEMPLATE_PRODUCT_ITEM");
    }
}
