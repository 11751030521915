
class SupportTicketListViewController extends BaseTableViewController
{
    private addButton:MUIButton = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function(){
            AppHelper.sharedInstance().showSelectBusinessViewControllerFromView( this.addButton, this, function(this:SupportTicketListViewController, controller:any, object:BusinessLicense) {

            });
        });

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;
    }

    cellAtIndexPath(tableView: UITableView, indexPath: MIOIndexPath) {
        let item = this.fetchedResultsController.objectAtIndexPath( indexPath ) as SupportTicket;
        let cell = tableView.dequeueReusableCellWithIdentifier( "SupportTicketCell" ) as SupportTicketCell;

        cell.item = item;

        return cell;
    }
        
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as SupportTicket;
    
        let vc = SupportTicketDetailViewController.newInstance();
        this.splitViewController.showDetailViewController( vc );
    }

    set fetchedResultsController(value){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }

    get fetchedResultsController(){
        if (this._fetchedResultsController != null) return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let sds = [ MIOSortDescriptor.sortDescriptorWithKey("date", true) ];
        let fetchRequest = DBHelper.listFetchRequestWithEntityName("SupportTicket", sds, "status = 0");
            
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;    
        return this._fetchedResultsController;
    }

    private createNewTicket( business:BusinessLicense ){

        let ad = MUIWebApplication.sharedInstance().delegate as AppDelegate;

        let ticket = MIOEntityDescription.insertNewObjectForEntityForName( "SupportTicket", ad.managedObjectContext ) as SupportTicket;
        ticket.business = business;
        ticket.title = MIOLocalizeString( "NEW TICKET", "NEW TICKET" );
        // ticket.client = business.client;

        DBHelper.saveMainContext();

    }
}