



class InventoryNoteSummaryViewController extends MUIViewController {

    private _backButton = null;
    private _processButton = null;

    private _tableView = null;
    private _selectedIds = [];
    private _fetchedResultsController = null;

    viewDidLoad()
    {
        super.viewDidLoad();

        this._backButton = MUIOutlet(this, 'isv_nb_back_btn','MUIButton');
        this._backButton.setAction(this, function()
        {
           this.navigationController.popViewController(true);
        });
        this._processButton = MUIOutlet(this, 'isv_nb_process_btn','MUIButton');

        this._tableView = MUIOutlet(this, 'sedv_tableview', 'MUITableView');
        this._tableView.dataSource = this;
        this._tableView.delegate = this;
        
        MIONotificationCenter.defaultCenter().addObserver(this, 'selectedInventoryNotesChanged', function (notification) {
            this._selectedIds = notification.object;
            this.fetchedResultsController = null;
        
            this._tableView.reloadData();
        });
        this._updateUI();
    }

    set selectedIds(value)
    {
        this._selectedIds = value;
        this._updateUI();
    }

    private _updateUI()
    {
        if(!this.selectedIds || !this.viewIsLoaded ) return;
        
        this.fetchedResultsController = null;
        this._tableView.reloadData();

    }
    viewWillAppear(animate?)
    {
        super.viewWillAppear(animate);
        this.fetchedResultsController = null;
        
        this._tableView.reloadData();
    }

    numberOfSections(tableview)
    {
        return this.fetchedResultsController.sections.length;
    }
    
    numberOfRowsInSection(tableview, section)
    {
        var sec = this.fetchedResultsController.sections[section];
        return sec.numberOfObjects();
    }
    
    cellAtIndexPath(tableview, indexPath:MIOIndexPath)
    {
        var cell = tableview.dequeueReusableCellWithIdentifier('SummaryNoteLineCell');
    
        var item = this.fetchedResultsController.objectAtIndexPath(indexPath);
    
        cell.item = item;
    
        cell.separatorStyle = MUITableViewCellSeparatorStyle.None;
        cell.selectionStyle = MUITableViewCellSelectionStyle.None;
    
        return cell;
    }
    
    canSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath)
    {
        return true;
    }
    
    didSelectCellAtIndexPath(tableView, indexPath:MIOIndexPath)
    {
        var obj = this.fetchedResultsController.objectAtIndexPath(indexPath);    
    }
    
    set fetchedResultsController(value)
    {
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController()
    {
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        var ad = MUIWebApplication.sharedInstance().delegate;
    
        var fetchRequest = MIOFetchRequest.fetchRequestWithEntityName('InventoryNoteLine');
    
        fetchRequest.sortDescriptors = [ /*MIOSortDescriptor.sortDescriptorWithKey('categoryName', true),*/
                                            MIOSortDescriptor.sortDescriptorWithKey('productName', true)];
        
        let filters = [];
        for(let i = 0; i < this._selectedIds.length; i++)
        {
            filters.push('note == ' + this._selectedIds[i]);
        }

        //if (this._searchString != null)
        let filterString = filters.join(' OR ');
        fetchRequest.predicate = MIOPredicate.predicateWithFormat(filterString);
    
        var fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }
    
    controllerDidChangeContent(controller)
    {
        this._tableView.reloadData();
    }
}
