//
// Generated class Order
//

/// <reference path="Order_ManagedObject.ts" />

class Order extends Order_ManagedObject
{

}
