/**
 * Created by miguel on 22/2/17.
 */

enum ArchivedDocumentType 
{
    receipt,
    invoice
}

class ArchivedDocumentListViewController extends BaseTableViewController
{            
    documentType = ArchivedDocumentType.receipt;

    static newInstance():ArchivedDocumentListViewController {
        let vc = new ArchivedDocumentListViewController("archived-document-list-view");
        vc.initWithResource("layout/archived_document/ArchivedDocumentListView.html");
        return vc;
    }

    private titleLabel:MUILabel = null;
	private filterButton:MUIButton = null;   
    private addButton:MUIButton = null;        

    viewDidLoad(){
        super.viewDidLoad();

        this.titleLabel = MUIOutlet(this, "title-lbl", "MUILabel");

        this.addButton = MUIOutlet(this, "add-btn", "MUIButton");
        this.addButton.setAction(this, function(this:ArchivedDocumentListViewController){
            let vc = SelectDocumentViewController.newInstance();
            this.presentViewController(vc, true);    
        });

        this.filterButton = MUIOutlet(this, "filter-btn", "MUIButton");
		this.filterButton.setAction(this, function() {
            AppHelper.sharedInstance().showFilterViewController(this, this.filters, this.filtersDidChange);             
		});

        this.searchTextField = MUIOutlet(this, 'search-bar', 'MUITextField');
        this.setupSearchTextField();
    
        this.tableView = MUIOutlet(this, 'table-view', 'UITableView');
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.updateUI();
    }

    viewWillAppear(animated?){
        super.viewWillAppear(animated);

        MIONotificationCenter.defaultCenter().addObserver(this, "UpdateArchivedDocumentList", function(note:MIONotification){
            this.setFetchedResultsController(null);
            this.tableView.reloadData();
        });

        if (this.documentType == ArchivedDocumentType.invoice) MIONotificationCenter.defaultCenter().addObserver(this, "ReloadAndShowPOSInvoiceNotification", function(this:ArchivedDocumentListViewController, note:MIONotification){
            DBHelper.queryObjectWithCompletion("ArchivedInvoice", null, MIOPredicate.predicateWithFormat("identifier = " + note.object), [], this, function(this:ArchivedDocumentListViewController, object:ArchivedInvoice){
                if (object == null) return;

                this.selectedItem = object;
                this.showSelectedItemDetail();
                let ip = this.fetchedResultsController.indexPathForObject(object);
                this.tableView.selectRowAtIndexPath(ip, true);
            });
        });
        
        this.updateUI();
    }

    viewWillDisappear(animated?){
        super.viewWillDisappear(animated);
        MIONotificationCenter.defaultCenter().removeObserver(this, "UpdateArchivedDocumentList");
        if (this.documentType == ArchivedDocumentType.invoice) MIONotificationCenter.defaultCenter().removeObserver(this, "ReloadAndShowInvoiceNotification");
    }

    private updateUI(){
        if (this.viewIsLoaded == false) return;

        this.titleLabel.text = this.documentType == ArchivedDocumentType.receipt ? MIOLocalizeString("RECEIPTS", "RECEIPTS") : MIOLocalizeString("INVOICES", "INVOICES");
        this.addButton.hidden = (this.documentType == ArchivedDocumentType.receipt);

        this.needsReloadData();        
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){
        let cell = tableview.dequeueReusableCellWithIdentifier("ArchivedDocumentCell") as ArchivedDocumentCell; 

        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as ArchivedDocument;
        cell.item = item;        

        cell.selected = (this.selectedItem?.identifier == item.identifier);

        return cell;
    }
    
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let obj = this.fetchedResultsController.objectAtIndexPath(indexPath);
        this.selectedItem = obj;
        this.showSelectedItemDetail();
    }

    private showSelectedItemDetail(){
        let vc = ArchivedDocumentDetailViewController.newInstance();
        vc.ticket = this.selectedItem;

        this.splitViewController.showDetailViewController(vc);
    }

    private showNoItemSelectedView(){
        let vc = AppHelper.sharedInstance().emptyViewControllerForIndentifier("Ticket", NoItemSelectedViewControllerType.Ticket);
        this.splitViewController.showDetailViewController(vc);
	}
	
	protected _fetchedResultsController:MIOFetchedResultsController = null;
    set fetchedResultsController(value:MIOFetchedResultsController){
        if (value == null && this._fetchedResultsController != null)
            this._fetchedResultsController.delegate = null;
    
        this._fetchedResultsController = value;
    }
    
    get fetchedResultsController(){
        if (this._fetchedResultsController != null)
            return this._fetchedResultsController;
    
        let ad = MUIWebApplication.sharedInstance().delegate;
    
        let fetchRequest = MIOFetchRequest.fetchRequestWithEntityName(this.documentType == ArchivedDocumentType.receipt ? "ArchivedTicket": "ArchivedInvoice");
        fetchRequest.fetchLimit = 50;
        fetchRequest.relationshipKeyPathsForPrefetching = ["nextDocument"];
    
        let predicateString = "legalDocumentID != null";
        if (this.searchString != null) {
            predicateString += " AND (legalDocumentID CONTAINS '" + this.searchString + "' OR documentID CONTAINS '" + this.searchString + "')";
        }
        if (this.dateTimeFrom != null && this.dateTimeTo != null) {
            predicateString += " AND (sessionBeginDate >= '" + this.dateTimeFrom + "' AND sessionBeginDate <= '" + this.dateTimeTo + "')";
		}
		if (this.filterPredicateFormat != null) {
            predicateString += " AND " + this.filterPredicateFormat;
        }

        fetchRequest.sortDescriptors = [ MIOSortDescriptor.sortDescriptorWithKey("date", false), 
                                         MIOSortDescriptor.sortDescriptorWithKey("legalDocumentID", true)];
        if (predicateString != null)
            fetchRequest.predicate = MIOPredicate.predicateWithFormat(predicateString);
                
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, null);
        fetchedResultsController.delegate = this;
    
        fetchedResultsController.performFetch();
    
        this._fetchedResultsController = fetchedResultsController;
    
        return this._fetchedResultsController;
    }

    private dateTimeFrom:string = null;
    private dateTimeTo:string = null;    
    datesDidSelect(dateTimeFrom, dateTimeTo) {
        this.dateTimeFrom = MIODateGetDateString(dateTimeFrom) + " 00:00:00";
        this.dateTimeTo = MIODateGetDateString(dateTimeTo) + " 23:59:59";
        
        this.showNoItemSelectedView();
        this.invalidateData();
	}
	
	private _filters = null;
    get filters(){
        if (this._filters != null) {
            return this._filters;
        } else {
            this._filters = [
                FilterOption.filterWithTitle("FromDate", "From day", "date", FilterFieldType.Date, null, FilterControllerComparatorType.MajorOrEqualComparator, null, "00:00:00"),
                FilterOption.filterWithTitle("ToDate", "To day", "date", FilterFieldType.Date, null, FilterControllerComparatorType.MinorOrEqualComparator, null, "23:59:59"),
                FilterOption.filterWithTitle("Amount", "Amount", "totalPrice", FilterFieldType.Number, null, null),                
                FilterOption.filterWithFetchRequest("Client", "Client", this.documentType == ArchivedDocumentType.receipt ? "linkedLegalEntityID" : "invoiceLegalEntityID", FilterFieldType.DropDown, "Client", "name", "identifier", MIOLocalizeString('SELECT CLIENT','SELECT CLIENT'))
            ];

            if ( this.documentType == ArchivedDocumentType.receipt ) {
                let pf = "type in [" + Location.occupiableTypes + "]" 
                this._filters.addObject( FilterOption.filterWithFetchRequest("Location", "Location", "locationID", FilterFieldType.DropDown, "Location", "fullName", "identifier", MIOLocalizeString('SELECT LOCATION','SELECT LOCATION'), pf, null,["category"] ) )
            }

            return this._filters;
        }
    }

    protected filterPredicateFormat:string = null;
    protected filtersDidChange(filtersPredicateFormat){
        
        if(filtersPredicateFormat != null) {
            MUICoreLayerAddStyle(this.filterButton.layer, 'filter');
            MUICoreLayerRemoveStyle(this.filterButton.layer, 'filter-off');
        } else {
            MUICoreLayerRemoveStyle(this.filterButton.layer, 'filter');
            MUICoreLayerAddStyle(this.filterButton.layer, 'filter-off');
        }

        this.filterPredicateFormat = filtersPredicateFormat;
        this.fetchedResultsController = null;
        this.tableView.reloadData();
	}
	
}