/// <reference path="PaymentEntityCell.ts" />


class DLPaymentEntityCell extends PaymentEntityCell
{    
    private bankAccountTextField:MUITextField = null;
    private bankCodeTextField:MUITextField = null;
    
    awakeFromHTML(){     
        super.awakeFromHTML();

        this.bankAccountTextField = MUIOutlet(this, "bank-account-tf", "MUITextField");
        this.bankAccountTextField.setOnChangeText(this, function(textField:MUITextField, value:string) {
            this._config["BANK_ACCOUNT"] = value.trim().length > 0 ? value : null;
            this._item.setConfigValues(this._config);
        });
        
        this.bankCodeTextField = MUIOutlet(this, "bank-code-tf", "MUITextField");
        this.bankCodeTextField.setOnChangeText(this, function(textField:MUITextField, value:string) {
            this._config["BANK_CODE"] = value.trim().length > 0 ? value : null;
            this._item.setConfigValues(this._config);
        });        
    } 
    
    private _config = null;
    set item(item:PaymentEntity){
        this.setItem(item); 
                
        this._config = item.configValues();
        this.bankAccountTextField.text = this._config["BANK_ACCOUNT"];
        this.bankCodeTextField.text = this._config["BANK_CODE"];
    }

}