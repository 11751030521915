
class ProductRatesDetailHeaderCell extends UITableViewCell
{
    private titleLabel:MUILabel = null;
    private settingsButton:MUIButton = null;

    awakeFromHTML()
    {
        this.titleLabel = MUIOutlet(this, "header-lbl", "MUILabel");
        
        this.settingsButton = MUIOutlet(this, 'settings-btn', 'MUIButton');
        this.settingsButton.setAction(this, function (){            
            this.showSettingsPopup();
        }); 

        //this.separatorStyle = MUITableViewCellSeparatorStyle.None;
    }

    set item(i:any)
    {
        this.titleLabel.text = i.name;
    }

    private showSettingsPopup() {
        alert ("Header cell settings popup");
    }

}