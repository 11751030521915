

/// <reference path="CoreEntityUpdatedBy.ts" />

// Generated class NumberSeries_ManagedObject

class NumberSeries_ManagedObject extends CoreEntityUpdatedBy
{

    // Property: name
    set name(value:string) {
        this.setValueForKey(value, 'name');
    }
    get name():string {
        return this.valueForKey('name');
    }
    set namePrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'name');
    }
    get namePrimitiveValue():string {
        return this.primitiveValueForKey('name');
    }

    // Property: nextNumber
    set nextNumber(value:number) {
        this.setValueForKey(value, 'nextNumber');
    }
    get nextNumber():number {
        return this.valueForKey('nextNumber');
    }
    set nextNumberPrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'nextNumber');
    }
    get nextNumberPrimitiveValue():number {
        return this.primitiveValueForKey('nextNumber');
    }

    // Property: prefix
    set prefix(value:string) {
        this.setValueForKey(value, 'prefix');
    }
    get prefix():string {
        return this.valueForKey('prefix');
    }
    set prefixPrimitiveValue(value:string) {
        this.setPrimitiveValueForKey(value, 'prefix');
    }
    get prefixPrimitiveValue():string {
        return this.primitiveValueForKey('prefix');
    }

    // Property: type
    set type(value:number) {
        this.setValueForKey(value, 'type');
    }
    get type():number {
        return this.valueForKey('type');
    }
    set typePrimitiveValue(value:number) {
        this.setPrimitiveValueForKey(value, 'type');
    }
    get typePrimitiveValue():number {
        return this.primitiveValueForKey('type');
    }
}
