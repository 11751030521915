interface PurchaseOrderTemplateCellDelegate
{
    addComments( cell:PurchaseOrderTemplateCell, item:any );
}



class PurchaseOrderTemplateCell extends UITableViewCell
{
    delegate:PurchaseOrderTemplateCellDelegate = null;

    private nameLabel:MUILabel = null;
    private addCommentsButton:MUIButton = null;

    awakeFromHTML(){
        this.nameLabel = MUIOutlet(this, "name-lbl", "MUILabel");
        this.addCommentsButton = MUIOutlet(this, "add-comments-btn", "MUIButton");
        this.addCommentsButton.setAction(this, function(){
            this.delegate?.addComments(this, this._item);
        });

        this.selectionStyle = UITableViewCellSelectionStyle.None;
    }

    private _item:any = null;
    set item( item:any ){
        this._item = item;

        this.nameLabel.text = item[ "Name" ];
        this.addCommentsButton.hidden = item[ "HasComments" ] == true;
    }
}