

class WineCellarConfigDetailViewController extends MUIViewController
{

    static newInstance() : WineCellarConfigDetailViewController {
        let vc = new WineCellarConfigDetailViewController('wine-cellar-config-detail-view');
        vc.initWithResource('layout/winecellar/WineCellarConfigDetailView.html');
        return vc;
    }

    public preferredContentSize = new MIOSize(400, 400);

    private closeButton:MUIButton = null;
    private doneButton:MUIButton = null;
    private nameTextfield:MUITextField = null;
    private indexTextField:MUITextField = null;
    private indexLabel:MUILabel = null;
    private typeLabel:MUILabel = null;
    private attributeButton:MUIButton = null;
    private _selectedProductAttributeType:ProductAttributeType = null;
    
    public delegate = null;

    viewDidLoad(){
        super.viewDidLoad();

        this.closeButton = MUIOutlet(this, 'close-btn', 'MUIButton');
        this.closeButton.setAction(this, function(){
            this.dismissViewController(true);
        });

        this.doneButton = MUIOutlet(this, 'done-btn', 'MUIButton');
        this.doneButton.setAction(this, function() {
            DBHelper.saveMainContext();
            this.dismissViewController(true);
        })

        this.nameTextfield = MUIOutlet(this, "name-tf", "MUITextField");
        this.nameTextfield.setOnChangeText(this, function(control, value){
            this._wineCellar.name = value;
        });

        this.typeLabel = MUIOutlet(this, "type-lbl", "MUILabel");
        this.indexLabel = MUIOutlet(this, "index-lbl", "MUILabel");

        this.attributeButton = MUIOutlet(this, "attribute-btn", "MUIButton");
        this.attributeButton.setAction(this, function(){
            let vc = AppHelper.sharedInstance().showCustomSelectViewControllerFromView(this.attributeButton, 'ProductAttributeType', 'name', null, this, function(controller, object:ProductAttributeType){
                if (object) {
                    this.attributeButton.title = object.valueForKey('name');
                    this._wineCellar.productAttributeTypeID = object.identifier;
                    this._selectedProductAttributeType = object;
                }
            }, true );        
        });

    }

    viewWillAppear(animate?) {
        super.viewWillAppear(animate);        
        this.updateUI();
    }

    private _wineCellar:WineCellar = null;
    set item (item:WineCellar) {
        this._wineCellar = item;
    }

    private updateUI(){
        if (this.viewIsLoaded == false || this._wineCellar == null) return;
      
        this.nameTextfield.text = this._wineCellar.name;
        this.indexLabel.text = this._wineCellar.orderIndex;
        this.typeLabel.text = WineCellarType[this._wineCellar.type];

        if (this._wineCellar.type == WineCellarType.StockCategory) {

            
            this.attributeButton.enabled = false;
        } else if (this._wineCellar.type == WineCellarType.ProductAttribute) {
            
            if (this._wineCellar.productAttributeTypeID) {

                //this call is not working for some reason
                let predicate = MIOPredicate.predicateWithFormat("identifier = '" + this._wineCellar.productAttributeTypeID + "'");
                DBHelper.queryObjectWithCompletion("ProductAttributeType", null, predicate, [], this, function(this, object:ProductAttributeType){
                    if (object == null) return;
    
                    this.attributeButton.title = object.name;
                    this._selectedProductAttributeType = object;
                });
                 
            } else {
                this.attributeButton.title = MIOLocalizeString("SELECT", "SELECT");
            }
            
        }
    }

    
    // private addWineCellar(){

    //     var avc = new MUIAlertViewController();
    //     avc.initWithTitle(MIOLocalizeString('NEW WINE CELLAR','NEW WINE CELLAR'), MIOLocalizeString('CHOOSE A NAME','CHOOSE A NAME '), MUIAlertViewStyle.Default);
    //     avc.addTextFieldWithConfigurationHandler(this, function(textField:MUITextField){
    //         textField.setPlaceholderText(MIOLocalizeString("NAME","NAME"));
    //     });
    //     avc.addComboBoxWithConfigurationHandler(this, function(comboBox:MUIComboBox){
    //         comboBox.removeAllItems();
    //         comboBox.addItem(MIOLocalizeString("CATEGORY STOCK", "CATEGORY STOCK"), WineCellarType.StockCategory);
    //         comboBox.addItem(MIOLocalizeString("ATTRIBUTE", "ATTRIBUTE"), WineCellarType.ProductAttribute);                      
    //     });

    //     avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('ADD','ADD'), MUIAlertActionStyle.Default, this, function(){
    //         let nameTF =  avc.textFields[0];
    //         let name = nameTF.text.trim();
    //         let type = avc.comboBoxes[0].getSelectedItem();

    //         if(name.length > 0){
    //             let moc = MUIWebApplication.sharedInstance().delegate.managedObjectContext;
    //             let ws = MIOEntityDescription.insertNewObjectForEntityForName("WineCellar", moc) as WineCellar;
    //             ws.identifier = MIOUUID.UUID().UUIDString;
    //             ws.name = name;
    //             ws.type = type;
    //             moc.save();
    //         }
    //         else {
    //             let error = new MUIAlertViewController();
    //             error.initWithTitle(MIOLocalizeString('ERROR', 'ERROR'), MIOLocalizeString('CHECK NAME','CHECK NAME'), MUIAlertViewStyle.Default);
    //             error.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CLOSE','CLOSE'), MUIAlertActionStyle.Cancel, this, function(){}));
                
    //             this.presentViewController(error, true);
    //         }
    //     }));
    //     avc.addAction(MUIAlertAction.alertActionWithTitle(MIOLocalizeString('CANCEL','CANCEL'), MUIAlertActionStyle.Cancel, this, function(){}));
        
    //     this.presentViewController(avc, true);
    // }
}
