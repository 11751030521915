//
// Generated class CoreEntityUpdatedBy
//

/// <reference path="CoreEntityUpdatedBy_ManagedObject.ts" />

class CoreEntityUpdatedBy extends CoreEntityUpdatedBy_ManagedObject
{

}
