//
// Generated class AnalyticAccount
//

/// <reference path="AnalyticAccount_ManagedObject.ts" />

class AnalyticAccount extends AnalyticAccount_ManagedObject
{

}
