
class MenuDetailViewController extends BaseTableViewController 
{

    static newInstance() : MenuDetailViewController {

        let vc = new MenuDetailViewController("menus-detail-view");
        vc.initWithResource("layout/menus/MenusDetailView.html");
        
        return vc;
    }

    private optionsButton: MUIButton = null;
    private saveButton:MUIButton = null;

    private imageView: MUIImageView = null;
    private nameTextField: MUITextField = null;
    private languageButton:MUIButton = null;
    private priceTextField: MUITextField = null;    
    private taxDropDown:MUIButton = null;
    private absorbentSwitch:MUISwitchButton = null;

    private cf:MIONumberFormatter = MUIWebApplication.sharedInstance().delegate.currencyFormatter;

    viewDidLoad(){
        super.viewDidLoad();

        this.saveButton = MUIOutlet( this, "save-btn", "MUIButton" );
        this.saveButton.setAction( this, function() {
            DBHelper.saveMainContext();
        });

        this.optionsButton = MUIOutlet( this, "options-btn", "MUIButton" );
        this.optionsButton.setAction( this, this.showActions );

        this.nameTextField = MUIOutlet(this, "name-tf", "MUITextField");
        this.nameTextField.setOnChangeText(this, function(this:MenuDetailViewController, control:MUITextField, value:string) {
            let v = value.trim().length > 0 ? value.trim() : null;
            if ( v == null ) {
                AppHelper.showErrorMessage( this, MIOLocalizeString( "INVALID VALUE", "INVALID VALUE"), MIOLocalizeString("NAME MUST HAVE A VALUE", "NAME MUST HAVE A VALUE"));
                return;
            }
            this._menu.name = v;
        });

        this.languageButton = MUIOutlet(this, "language-btn", "MUIButton");
        this.languageButton.setAction(this, function(){
            let vc = ProductLanguageViewController.newInstance(); 
            vc.setItem(this._menu, "name");
            
            this.presentViewController( vc, true );
        });


        this.priceTextField = MUIOutlet(this, "price-tf", "MUITextField");

        this.imageView = MUIOutlet(this, 'menu-img','MUIImageView');

        this.taxDropDown = MUIOutlet(this, 'tax-btn', 'MUIButton');
        this.taxDropDown.setAction(this, function() {
            this.showTaxSelectEntityViewController(this.taxDropDown, "Tax"); //view popup for tax
        });
    
        this.absorbentSwitch = MUIOutlet(this, 'absorbent-switch', 'MUISwitchButton');
        this.absorbentSwitch.setOnChangeValue(this, function(this:MenuDetailViewController, control:MUISwitchButton, value:boolean){
            this._menu.isAbsorbent = value;
        });

        this.tableView = MUIOutlet(this, "table-view", "UITableView");
        this.tableView.dataSource = this;
        this.tableView.delegate = this;

        this.updateUI();
    }

    viewWillAppear(animate?){
        super.viewWillAppear(animate);
        this.updateUI();
    }

    private _menu:Menu;
    set item(item:Menu){        
        this._menu = item;
        this.updateUI();
    }   

    private updateUI(){
        if (this._menu == null) return;
        if (this.viewIsLoaded == false) return;    

        this.nameTextField.text = this._menu.name;
        this.taxDropDown.title = this._menu.tax.name;
        this.priceTextField.text = this._menu.price;
        this.absorbentSwitch.on = this._menu.isAbsorbent;

    }

    titleForHeaderInSection(tableView:UITableView, section:number) {
        let ip = MIOIndexPath.indexForRowInSection( 0, section );
        let item = this.fetchedResultsController.objectAtIndexPath( ip ) as ProductMenu;

        return item.menuCategory.name;
    }

    cellAtIndexPath(tableview:UITableView, indexPath:MIOIndexPath){  
        let cell = tableview.dequeueReusableCellWithIdentifier("MenuDetailCell") as MenuDetailCell;
        let item = this.fetchedResultsController.objectAtIndexPath(indexPath) as MenuCategory;
        cell.item = item;            
        
        return cell;
    }
    
    didSelectCellAtIndexPath(tableView:UITableView, indexPath:MIOIndexPath){
        let selectedItem = this.fetchedResultsController.objectAtIndexPath(indexPath);
    }

    get fetchedResultsController() {
        if (this._fetchedResultsController != null) return this._fetchedResultsController;

        let ad = MUIWebApplication.sharedInstance().delegate;

        let fetchRequest:MIOFetchRequest = null;
        let sortDescriptors = [
            MIOSortDescriptor.sortDescriptorWithKey("categoryOrderIndex", true), 
            MIOSortDescriptor.sortDescriptorWithKey("product.name", true)
        ];

        fetchRequest = DBHelper.listFetchRequestWithEntityName( "ProductMenu", sortDescriptors, "menuCategory.menu.identifier = " + this._menu.identifier );
        fetchRequest.relationshipKeyPathsForPrefetching = ["menuCategory", "product"];
        
        let fetchedResultsController = new MIOFetchedResultsController();
        fetchedResultsController.initWithFetchRequest(fetchRequest, ad.managedObjectContext, "categoryOrderIndex");
        fetchedResultsController.delegate = this;
        fetchedResultsController.performFetch();

        this._fetchedResultsController = fetchedResultsController;
        return this._fetchedResultsController;        
    }


    private showTaxSelectEntityViewController(dropdownButton, entityName) {
        let predicateFormat = null;        
        let vc = AppHelper.sharedInstance().showCustomSelectViewControllerFromView(dropdownButton, entityName, "name", predicateFormat, this, function(controller, object:MIOManagedObject){
            let title = object.valueForKey("name");
            //let value = object.valueForKey("identifier");
            this.taxDropDown.title = title;
            this._menu.tax = object;
        });        
    }

    private showActions(){
        let avc = new MUIAlertViewController();
        avc.initWithTitle( MIOLocalizeString("ACTIONS", "ACTIONS"), MIOLocalizeString( "CHOOSE AN ACTION", "CHOOSE AN ACTION" ), MUIAlertViewStyle.Default );

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString("DELETE MENU", "DELETE MENU"), MUIAlertActionStyle.Destructive, this, function(this:MenuDetailViewController) {
            this.showDeleteConfirmation();
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString( "CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null) );

        this.presentViewController( avc, true );
    }

    private showDeleteConfirmation() {
        let avc = new MUIAlertViewController();
        avc.initWithTitle( MIOLocalizeString("WARNING", "WARNING"), MIOLocalizeString( "ARE YOU SURE?", "ARE YOU SURE?" ), MUIAlertViewStyle.Default );

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString("DELETE", "DELETE"), MUIAlertActionStyle.Destructive, this, function(this:MenuDetailViewController) {
            DBHelper.deleteObjectFromMainContext( this._menu, true );
        }));

        avc.addAction( MUIAlertAction.alertActionWithTitle( MIOLocalizeString( "CANCEL", "CANCEL"), MUIAlertActionStyle.Cancel, null, null) );

        this.presentViewController( avc, true );
    }

}
